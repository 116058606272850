/**
 * Created by AC12396 on 1/22/2019.
 */

export const AETNA_PAYMENTS = {
  displayPaystub: true,
  pageInfo: {
    totalResults: '33',
    offSet: '0',
    itemsOnPage: '33',
    firstItemOnPage: '1',
    lastItemOnPage: '33',
    paging: [{
      offSet: '0',
      name: '1',
      current: 'true'
    }]
  },
  // 'futurePaymentList':[],
  futurePaymentList: [{
    checkDate: '8/22/2018',
    payPeriodFrom: '1/7/2019',
    payPeriodThrough: '1/7/2019',
    netPayAmount: '$14,002.4',
    deliveryMethod: 'Check',
    payeeName: 'RORY MATTHEWS-SMITH',
    payeeAddress1: '6576 CABOT DRIVE',
    payeeAddress2: '126 N BATTERY STREET',
    payeeAddress3: ' ',
    payeeCity: 'HOUSTON',
    payeeState: 'TX',
    payeeZip: '77021',
    claimNum: '1559897',
    checkNum: '1000057',
    numDaysPaid: '0',
    totalWithholdings: '$0.00',
    totalGrossBenefit: '$14,005.00',
    totalTaxes: '$0.00',
    totalDeductions: '$2.56',
    listItemIndex: 0,
    eobNum: '0613089',
    ssn: '465059576',
    emplId: '00894055',
    company: '8TD',
    paygroup: 'SIW',
    payEndDate: '2018-08-19T00:00:00',
    offcycle: 'N',
    pageNum: '5',
    lineNum: '7',
    sepchk: '0',
    offsets: [{
      Description: 'LTD Premium After-Tax',
      Amount: '$2.56',
      PayPeriod: '3'
    }],
    taxes: [{
      Description: 'STD Premium After-Tax',
      Current: '$3.56',
      YTD: '$7.68'
    }],
    beforeTaxDeductions: [{
      Description: 'LTD Premium After-Tax',
      Current: '$4.56',
      YTD: '$7.68'
    }],
    afterTaxDeductions: [{
      Description: 'LTD Premium After-Tax',
      Current: '$5.56',
      YTD: '$7.68'
    }],
    totalYTDTaxes: '$0.00',
    totalCurrentTaxes: '$0.00',
    totalCurrAfterTaxDeducations: '$2.56',
    totalYTDAfterTaxDeducations: '$7.68',
    totalCurrBeforeTaxDeducations: '$0.00',
    totalYTDBeforeTaxDeducations: '$0.00',
    totalOffsets: '$0.00',
    federalTaxGrossCurr: '$14,005.00',
    grossPayMinusTaxesCurr: '$14,005.00',
    federalTaxGrossYTD: '$14,005.00',
    grossPayMinusTaxesYTD: '$14,005.00',
    accountNumber: '1234567897683'
  }],
  // 'paymentList':[],
  paymentList: [
    {
      accountNumber: '45854',
      afterTaxDeductions: [],
      bankCd: '011900571',
      beforeTaxDeductions: [],
      checkDate: '11/17/2021',
      checkNum: '1000199',
      claimAdjAmount: '0',
      claimNum: '2089898',
      company: 'NTB',
      deliveryMethod: 'EFT',
      emplId: '00765953',
      eobNum: '2081580',
      federalTaxGrossCurr: '$4.00',
      federalTaxGrossYTD: '$4.00',
      grossPayMinusTaxesCurr: '$4.00',
      grossPayMinusTaxesYTD: '$4.00',
      lineNum: '6',
      listItemIndex: 0,
      netPayAmount: '$4.00',
      numDaysPaid: '6',
      offcycle: 'N',
      offsets: [],
      pageNum: '5',
      payEndDate: '2021-11-15T00:00:00',
      payPeriodFrom: '7/8/2021',
      payPeriodThrough: '7/13/2021',
      payeeAddress1: '1 HARTFORD PLAZA',
      payeeAddress2: ' ',
      payeeAddress3: ' ',
      payeeCity: 'SIMSBURY',
      payeeName: 'THERESA WILKINAY',
      payeeState: 'CA',
      payeeZip: '06070',
      paygroup: 'PIW',
      sepchk: '0',
      ssn: null,
      taxes: [
        {
          Current: '$0.00',
          Description: 'Fed Withholdng',
          YTD: '$0.00',
        },
        {
          Current: '$0.00',
          Description: 'CA Withholdng',
          YTD: '$0.00',
        }
      ],
      totalCurrAfterTaxDeducations: '$0.00',
      totalCurrBeforeTaxDeducations: '$0.00',
      totalCurrentTaxes: '$0.00',
      totalDeductions: '$0.00',
      totalGrossBenefit: '$4.00',
      totalOffsets: '$0.00',
      totalTaxes: '$0.00',
      totalWithholdings: '$0.00',
      totalYTDAfterTaxDeducations: '$0.00',
      totalYTDBeforeTaxDeducations: '$0.00',
      totalYTDTaxes: '$0.00'
    }
  ],
  responseCode: '001',
  responseReason: 'success'
}

