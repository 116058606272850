import {AfterViewInit, Component, OnDestroy, OnInit, Output} from '@angular/core';
import {ProductService} from '../../shared/services/product.service';
import {ActivatedRoute, ParamMap} from '@angular/router';
import {Location} from '@angular/common';
import {StorageManagementService} from '../../shared/services/storage-management.service';
import {AuthStateService} from '../../shared/services/auth-state.service';
import {ContentService} from '../../shared/services/content.service';
import {LeaveDynatraceAction} from '../../shared/utils/leaveDynatraceAction';
import {AppLabelConstants} from '../../shared/constants/appLabel.constants';
import {SessionConstants} from '../../shared/constants/session.constants';
import {AppConstants, AppLeaveConst} from '../../shared/constants/app.constants';
import {CustomEventService} from '../../shared/services/tealium/custom-event.service';
import {AetnaContactsService} from '../../shared/services/aetnaContacts.service';
import {RelatedClaims} from '@shared/models/relatedClaims.model';


@Component({
  selector: 'product-info',
  templateUrl: 'product-info.component.html',
  styleUrls: ['product-info.component.scss']
})
export class ProductInfoComponent extends LeaveDynatraceAction implements OnInit, AfterViewInit, OnDestroy {
  public appLabelConstants = AppLabelConstants;
  public productInfo;
  public id;
  public isLogin;
  public currentUser;
  public productSelected;
  public aetnaServiceSubscription;
  public coverage;
  @Output() pageLabel = AppLabelConstants.PRODUCT_INFO_PAGES;
  private benefitLabel: string;
  private pageId1: string;
  private owscContentPreLoginProduct;
  private cxrUrl: string;
  public isStartAClaimAvailable = false;
  public actionText: string;
  public actionLink: string;
  public actionType: string;
  private disclosure: string;
  public routeLocation: string = null;
  public isCommonquestionsAvailable = true;
  public appConstants = {...AppConstants, ...AppLeaveConst};
  public curClaim: RelatedClaims;
  private disabilityClaimContact: string = '';
  isStartANewClaimPostAuthDisabled: boolean = false;
  isBeginNewClaimDisabled: boolean=false;

  constructor(private productService: ProductService,
              private route: ActivatedRoute,
              private _location: Location,
              private authStateService: AuthStateService,
              private aetnaContactsService: AetnaContactsService,
              private storageManagementService: StorageManagementService,
              private contentService: ContentService,
              private customEventService: CustomEventService) {
    super(storageManagementService);
  }



  ngOnInit(): void {
    this.isBeginNewClaimDisabled = this.storageManagementService.getSessionData(AppConstants.IS_PRE_AUTH_INTAKE_DISABLED);
    this.isStartANewClaimPostAuthDisabled = this.storageManagementService.getSessionData(AppConstants.IS_START_A_NEW_CLAIM_POST_AUTH_DISABLED);
    this.isLogin = this.authStateService.getLoginState();
   // this.storageManagementService.setLoginState(this.isLogin);
    //TODO: 49 Commneted by Ashish Need to check the usage
    //this.productInfo = this.productService.getProducts(null);
    //this.id = this.route.snapshot.params['id'];
    //this.productSelected = this.productService.getProductsById(this.route.snapshot.params['id']);
    //this.coverage=this.route.snapshot.params['id'];
    //OWCS service Integration
    //this.pageId1 = this.contentService.getPageId('PreAuthenticated Product Page');
    //if (this.pageId1) {
    //  this.contentService.getOWCSResponse(this.pageId1, 'PreAuthenticated_Product_Page').subscribe(
    //    val => {
    //      if (val) {
    //        this.owscContentPreLoginProduct = val;
    //        if (!this.isLogin) {
    //          if (this.owscContentPreLoginProduct.data.Pre_auth_22_Body) {
    //            this.benefitLabel = this.owscContentPreLoginProduct.data.Pre_auth_22_Body.value;
    //          }
    //        }
    //      }
    //    }, (error) => {
    //    });
    //}
    this.route.paramMap
      .subscribe(
      (params: ParamMap) => {
        this.id = parseInt(params.get('id'), 10) ;
        // productId is used when person clicks exit from leave intake flow i.e to track from which product page leave flow was initiated
        this.storageManagementService.setSessionData(SessionConstants.PRODUCT_NAVIGATION, this.id);
        this.productSelected = this.productService.getProductsById(this.id);
        const appData = JSON.parse(this.storageManagementService.retrieveSessionObject(SessionConstants.APP_DATA)).appData;
        const userData = appData ? appData.userData ? appData.userData : null : null;
        const personId = userData ? userData.personIds ? userData.personIds[0] : null : null;
        const sourceSystemCd = userData ? userData.sourcePartyDetailsList ? userData.sourcePartyDetailsList[0].srcSystemCd : null : null;
        if ( sourceSystemCd === AppConstants.SRC_SYS_CD_8  && this.isLogin ) {
          if ( personId ) {
            this.aetnaServiceSubscription = this.aetnaContactsService.getAetnaDisabilityClaimRelatedContact(personId).subscribe(contact => {
              if (contact) {
                if (contact.aetnaContactDetails && contact.aetnaContactDetails.phone) {
                  const caseSpecificNumber = AppLabelConstants.phoneNumberFormat(contact.aetnaContactDetails.phone);
                  this.productSelected.description = this.productSelected.description.replace(AppConstants.PHONE_NUMBER_REGEX_EXP, caseSpecificNumber);
                } else {
                    // if no claim exist use the pre login disability contact
                    this.productSelected.description = this.productSelected.description.replace(AppConstants.PHONE_NUMBER_REGEX_EXP, AppLabelConstants.DEFAULT_CLAIM_CONTACT_NO);
                }
              }
            });
          }
        } else {
          if (this.storageManagementService.getSessionData(SessionConstants.DISABILLITY_CLAIM_CONTACT)) {
            // if claim exist use the claim/office specific phone no
            this.disabilityClaimContact = this.storageManagementService.getSessionData(SessionConstants.DISABILLITY_CLAIM_CONTACT);
            this.productSelected.description = this.productSelected.description.replace(AppConstants.PHONE_NUMBER_REGEX_EXP, this.disabilityClaimContact);
          } else {
            // if no claim exist use the pre login disability contact
            this.disabilityClaimContact = AppLabelConstants.DEFAULT_CLAIM_CONTACT_NO;
            this.productSelected.description = this.productSelected.description.replace(AppConstants.PHONE_NUMBER_REGEX_EXP, this.disabilityClaimContact);
          }
        }
        //Set the Product label for selected product
        if (this.productSelected) {
          this.disclosure = this.productSelected.disclosure;
          this.productService.productLabelFromPrdInfo.next(this.productSelected.benefitsOfferBy.toString());
        }
        if (this.productSelected && this.productSelected.name) {
          let currPage = this.productSelected.name.replace(/[\s]/g, '');
          //Maternity without Leave='CO_66' PreLogin
          if (this.productSelected.prd_key === AppConstants.CO_66 && !this.isLogin ) {
            currPage = AppConstants.PRE_MAT_WITHOUT_LEAVE;
          } else if (this.productSelected.prd_key === AppConstants.CO_66 && this.isLogin) {
            currPage = AppConstants.POST_MAT_WITHOUT_LEAVE
          } else if ( this.productSelected.prd_key === AppConstants.CO_30) {
            currPage = AppConstants.MATERNITY_WITH_LEAVE;
          }
          this.storageManagementService.setAppData( SessionConstants.CURRENT_PAGE  , currPage );
          this.setupActionButton();
        }
        }
    );


    if (this.isLogin) {
      this.currentUser = this.storageManagementService.getAppData(SessionConstants.USER_DATA);
    }
    if (this.productSelected && this.productSelected.name) {
      this.setupActionButton();
    }
  }

  ngAfterViewInit() {
    if (this.productSelected.prdKey === 'Prd_5') {
      setTimeout(() => {
        if (document.getElementsByClassName('paraProduct')) {
          const fetchByClass = document.getElementsByClassName('paraProduct');
          if (document.getElementsByTagName('a')) {
            const fetchByHref = fetchByClass[0].getElementsByTagName('a')[0];
            const setById = fetchByHref.setAttribute('id', 'resourceCenter');
            document.getElementById('resourceCenter').addEventListener('click', ev => this.trackResourceCenter(this.productSelected.prdKey, fetchByHref));
          }
        }
      }, 1000);
    }
  }

  /*
  * @param id - product id for PFL
  * @param url - PFL product page url
  * */
  trackResourceCenter(id, url) {
    if (this.productSelected.prdKey === 'Prd_5') {
      this.customEventService.linkClick('resource center', url.href);
    }
  }

  /*goBack() {
    this._location.back();
  }*/
/*
  getState() {
    return this.authStateService.getLoginState();
  }*/
  /***********************
   * Date : 01/30/18
   * Author : DB
   * Purpose : US13434: Start A claim Buttons
   */
  setupActionButton() {
    if ( this.isLogin ) {
      this.postLoginNavigation();
    } else {
      this.preLoginNavigation();
    }
  }

  preLoginNavigation() {
    this.cxrUrl = this.productService.getCXRUrl();
    const fileId = '1444635955642';
    switch ( this.productSelected.name ) {
      case AppConstants.MATERNITY_WITHOUT_LEAVE:
      case AppConstants.MATERNITY_WITH_LEAVE_2:
        case AppConstants.MATERNITY_CC:
      case AppConstants.PLANNED_SURGERY:
      case AppConstants.SHORT_TERM_DISABILITY:
      case AppConstants.LEAVE_OF_ABS:
      case AppConstants.PAID_FAMILY_LEAVE:
      case AppConstants.LONG_TERM_DISABILITY:
        this.isStartAClaimAvailable = true;
        this.actionLink = AppLabelConstants.URL_CLAIM_WIZARD_1;
        this.actionText = AppConstants.START_A_CLAIM;
        this.actionType = AppConstants.URL;
        break;
      case AppConstants.ACCIDENT:
      case AppConstants.CRITICAL_ILLNESS:
      case AppConstants.HOSPITAL_INDEMNITY:
        this.isStartAClaimAvailable = true;
        this.actionLink = `${this.cxrUrl}cm/files/${fileId}.pdf`;
        this.actionText = AppConstants.DOWNLOAD_PDF;
        this.actionType = AppConstants.DOWNLOAD;
        break;
      case AppConstants.ADA_ACCOMODATION:
        this.isStartAClaimAvailable = false;
        break;
        default:
    }
  }

  postLoginNavigation() {
    this.cxrUrl = this.productService.getCXRUrl();
    const fileId = AppConstants.FILE_ID_PF;
    const thaaSupported = (this.storageManagementService.getAppData(SessionConstants.CASE_DATA) && this.storageManagementService.getAppData(SessionConstants.CASE_DATA).hasThaaClaims) ?
      this.storageManagementService.getAppData(SessionConstants.CASE_DATA) && this.storageManagementService.getAppData(SessionConstants.CASE_DATA).hasThaaClaims : false;

    switch ( this.productSelected.name ) {
      case AppConstants.MATERNITY_WITHOUT_LEAVE:
      case AppConstants.MATERNITY_WITH_LEAVE_2:
      case AppConstants.MATERNITY_CC:
      case AppConstants.PLANNED_SURGERY:
      case AppConstants.SHORT_TERM_DISABILITY:
      case AppConstants.PAID_FAMILY_LEAVE:
      case AppConstants.LONG_TERM_DISABILITY:
        this.isStartAClaimAvailable = false;
        break;
      case AppConstants.ACCIDENT:
      case AppConstants.CRITICAL_ILLNESS:
      case AppConstants.HOSPITAL_INDEMNITY:
        this.isStartAClaimAvailable = true;
        this.actionLink = thaaSupported ? AppLabelConstants.URL_SELECT_REASON : `${this.cxrUrl}cm/files/${fileId}.pdf`; // if the default case is AA supported than navigate to leave intake flow
        this.actionText = AppConstants.DOWNLOAD_PDF;
        this.actionType = thaaSupported ? AppConstants.URL : AppConstants.DOWNLOAD;
        this.routeLocation = thaaSupported ? AppLabelConstants.URL_HOME : null;
        break;
      case AppConstants.LEAVE_OF_ABS:
        this.isStartAClaimAvailable = true;
        this.actionLink = thaaSupported ? AppLabelConstants.URL_SELECT_REASON : this.getSSOLink();
        this.actionText = AppConstants.START_A_CLAIM;
        this.actionType = thaaSupported ? AppConstants.URL : AppConstants.NEW_TAB;
        this.routeLocation = thaaSupported ? AppLabelConstants.URL_HOME  : null;
        break;
      case AppConstants.ADA_ACCOMODATION:
        this.isStartAClaimAvailable = false;
        break;
      default:
    }
  }

  getSSOLink() {
    const SSOURLs = this.storageManagementService.getAppData(SessionConstants.LEAVE_SSO_URL);
    const defaultCaseId = JSON.parse(this.storageManagementService.retrieveSessionObject(SessionConstants.APP_DATA)).appData.caseData.defaultCaseId;
    const leaveEntry = SSOURLs ? SSOURLs.filter((res) => (res.claimEventId === defaultCaseId)) : [];
    const SSOURL = (leaveEntry.length > 0) ? leaveEntry[0].url : '';
    return SSOURL;
  }

  ngOnDestroy(): void {
    if ( this.aetnaServiceSubscription ) {
      this.aetnaServiceSubscription.unsubscribe();
    }
  }
}
