
import { IIntakeContentJsonKey } from '../models/intake-content.model';
import {ILookupKeyObject  } from '../models/intake-content.model';
import { LookUp_JSON_CONSTANTS } from './intake-owcs.constants';
//update visibility will be true if it is not companyQ is false
//visibility should be update to true based on the company question available for a given page
export const Intake_Content_JSON: IIntakeContentJsonKey = {
  SANLS1_14_Body: {
    registeredId: '43',
    questionOWCSKey: 'SANLS1_14_Body',
    requestParamKey: 'startDate',
    mbQuestionDescription: 'What is or will be your first day absent from work?',
    visibility: true,
    companyQ: false
  },
  SANLS1_14A_Body: {
    registeredId: '43',
    questionOWCSKey: 'SANLS1_14A_Body',
    requestParamKey: 'startDate',
    mbQuestionDescription: 'What is or will be your first day out of work?',
    visibility: false,
    companyQ: true
  },
  SANLS1_18_Body: {
    registeredId: '8',
    questionOWCSKey: 'SANLS1_18_Body',
    requestParamKey: 'absenceReason',
    mbQuestionDescription: 'What\'s the reason for your absence?',
    visibility: true,
    companyQ: false
  },
  SANLS1_17_Body: {
    registeredId: '42',
    questionOWCSKey: 'SANLS1_17_Body',
    mbQuestionDescription: 'What is or will be your first day absent from work?',
    requestParamKey: 'DateNotSelected',
    visibility: true,
    companyQ: false
  },
  SANLS1_20_Body: {
    registeredId: '1668',
    questionOWCSKey: 'SANLS1_20_Body',
    mbQuestionDescription: 'Where are you on your parenthood journey?',
    requestParamKey: 'maternityDelivery',
    visibility: true,
    companyQ: false
  },
  SANLS1_19_Body: {
    registeredId: '',
    questionOWCSKey: 'SANLS1_19_Body',
    requestParamKey: 'contactUsToolTip',
    mbQuestionDescription: '<div>To submit a claim for something that\'s not on this list,<a href="/contactUs">Contact Us</a>.</div>',
    visibility: true,
    companyQ: false
  },
  SANLS1_25_Body: {
    registeredId: '',
    questionOWCSKey: 'SANLS1_25_Body',
    requestParamKey: 'FDAQuestionToolTip',
    mbQuestionDescription: '<div>This should be the next day you were scheduled to work after your last day at work.</div>',
    visibility: true,
    companyQ: false
  },
  NI_168_Body: {
    registeredId: '1513',
    questionOWCSKey: 'NI_168_Body',
    requestParamKey: '',
    mbQuestionDescription: 'Is your number still (###) ###-####?',
    visibility: true,
    companyQ: false
  },
  NI_150_Body: {
    registeredId: '1513',
    questionOWCSKey: 'NI_150_Body',
    requestParamKey: '',
    mbQuestionDescription: 'What is your mobile phone number?',
    visibility: true,
    companyQ: false
  },
  NI_161_Body: {
    registeredId: '',
    questionOWCSKey: 'NI_161_Body',
    requestParamKey: '',
    mbQuestionDescription: 'That\'s not quite right. Make sure you enter the full ten-digit phone number with area code.',
    visibility: true,
    companyQ: false
  },
  NI_170_Body: {
    registeredId: '',
    questionOWCSKey: 'NI_170_Body',
    requestParamKey: '',
    mbQuestionDescription: 'Enter your mobile phone number',
    visibility: true,
    companyQ: false
  },
  NI_171A_Body: {
    registeredId: '',
    questionOWCSKey: 'NI_171A_Body',
    requestParamKey: '',
    mbQuestionDescription: 'We couldn\'t find a city with that ZIP code. Please try again, or enter the city and state instead.',
    visibility: true,
    companyQ: false
  },
  NI_171B_Body: {
    registeredId: '',
    questionOWCSKey: 'NI_171B_Body',
    requestParamKey: '',
    mbQuestionDescription: 'Enter the full five-digit ZIP code.',
    visibility: true,
    companyQ: false
  },
  NI_153_Body: {
    registeredId: '',
    questionOWCSKey: 'NI_153_Body',
    requestParamKey: '',
    mbQuestionDescription: 'I don’t have one',
    visibility: true,
    companyQ: false
  },
  NI_154_Body: {
    registeredId: '1022',
    questionOWCSKey: 'NI_154_Body',
    requestParamKey: '',
    mbQuestionDescription: 'What is the best phone number to reach you at?',
    visibility: true,
    companyQ: false
  },
  NI_155_Body: {
    registeredId: '',
    questionOWCSKey: 'NI_155_Body',
    requestParamKey: '',
    mbQuestionDescription: 'Make sure this is a number where we can talk to you, not just a family member or friend. If we have to share sensitive/private information about your claim, we can’t share it with someone else.',
    visibility: true,
    companyQ: false
  },
  NI_111_Body: {
    registeredId: '600673',
    questionOWCSKey: 'NI_111_Body',
    requestParamKey: '',
    mbQuestionDescription: 'Is it okay to leave you a voicemail? Voicemails may contain confidential information about your claim.',
    visibility: true,
    companyQ: false
  },
  NI_113_Body: {
    registeredId: '1526',
    questionOWCSKey: 'NI_113_Body',
    requestParamKey: '',
    mbQuestionDescription: 'Do you plan to receive mail at a temporary address while you\'re out?',
    visibility: true,
    companyQ: false
  },
  NI_114_Body: {
    registeredId: '',
    questionOWCSKey: 'NI_114_Body',
    requestParamKey: '',
    mbQuestionDescription: 'For example, if you\'re staying with a family member while you recover.',
    visibility: true,
    companyQ: false
  },
  NI_116_Body: {
    registeredId: '',
    questionOWCSKey: 'NI_116_Body',
    requestParamKey: '',
    mbQuestionDescription: 'Where will you be staying?',
    visibility: true,
    companyQ: false
  },
  NI_117_Body: {
    registeredId: '1535',
    questionOWCSKey: 'NI_117_Body',
    requestParamKey: '',
    mbQuestionDescription: 'Start Date',
    visibility: true,
    companyQ: false
  },
  NI_119_Body: {
    registeredId: '1538',
    questionOWCSKey: 'NI_119_Body',
    requestParamKey: '',
    mbQuestionDescription: 'End Date',
    visibility: true,
    companyQ: false
  },
  NI_121_Body: {
    registeredId: '1527',
    questionOWCSKey: ' NI_121_Body',
    requestParamKey: '',
    mbQuestionDescription: 'Address 1',
    visibility: true,
    companyQ: false
  },
  NI_123_Body: {
    registeredId: '94682',
    questionOWCSKey: 'NI_123_Body',
    requestParamKey: '',
    mbQuestionDescription: 'Address 2 (Optional)',
    visibility: true,
    companyQ: false
  },
  NI_125_Body: {
    registeredId: '1530',
    questionOWCSKey: 'NI_125_Body',
    requestParamKey: '',
    mbQuestionDescription: 'Zip Code',
    visibility: true,
    companyQ: false
  },
  NI_127_Body: {
    registeredId: '1528',
    questionOWCSKey: 'NI_127_Body',
    requestParamKey: '',
    mbQuestionDescription: 'City',
    visibility: true,
    companyQ: false
  },
  NI_129_Body: {
    registeredId: '1529',
    questionOWCSKey: 'NI_129_Body',
    requestParamKey: '',
    mbQuestionDescription: 'State/Location',
    visibility: true,
    companyQ: false
  },
  NI_700_Body: {
    registeredId: '',
    questionOWCSKey: 'NI_700_Body',
    requestParamKey: '',
    mbQuestionDescription: 'Step # of #',
    visibility: true,
    companyQ: false
  },
  NI_701_Body: {
    registeredId: '',
    questionOWCSKey: 'NI_701_Body',
    requestParamKey: '',
    mbQuestionDescription: 'About Your Delivery',
    visibility: true,
    companyQ: false
  },
  NI_703_Body: {
    registeredId: '',
    questionOWCSKey: 'NI_703_Body',
    requestParamKey: '',
    mbQuestionDescription: 'Tell us a little about your pregnancy and your delivery plans.',
    visibility: true,
    companyQ: false
  },
  NI_704_Body: {
    registeredId: '',
    questionOWCSKey: 'NI_704_Body',
    requestParamKey: '',
    mbQuestionDescription: 'Tell us a little about your delivery.',
    visibility: true,
    companyQ: false
  },
  NI_705_Body: {
    registeredId: '',
    questionOWCSKey: 'NI_705_Body',
    requestParamKey: '',
    mbQuestionDescription: 'What is your expected due date?',
    visibility: true,
    companyQ: false
  },
  NI_708_Body: {
    registeredId: '',
    questionOWCSKey: 'NI_708_Body',
    requestParamKey: '',
    mbQuestionDescription: 'Have you had a complication?',
    visibility: true,
    companyQ: false
  },
  NI_709_Body: {
    registeredId: '',
    questionOWCSKey: 'NI_709_Body',
    requestParamKey: '',
    mbQuestionDescription: 'Yes',
    visibility: true,
    companyQ: false
  },
   NI_710_Body: {
    registeredId: '',
    questionOWCSKey: 'NI_710_Body',
    requestParamKey: '',
    mbQuestionDescription: 'No',
    visibility: true,
    companyQ: false
  },
  NI_711_Body: {
    registeredId: '',
    questionOWCSKey: 'NI_711_Body',
    requestParamKey: '',
    mbQuestionDescription: 'Do you plan to deliver in the hospital?',
    visibility: true,
    companyQ: false
  },
  NI_255_Body: {
    registeredId: '',
    questionOWCSKey: 'NI_255_Body',
    requestParamKey: '',
    mbQuestionDescription: '<div>Find Your Healthcare Provider</div>',
    visibility: true,
    companyQ: false
  },
  NI_235_Body: {
    registeredId: '',
    questionOWCSKey: 'NI_255_Body',
    requestParamKey: '',
    mbQuestionDescription: '<div>For the most accurate results, fill in all the information you know about your provider.</div>',
    visibility: true,
    companyQ: false
  },
  NI_236_Body: {
    registeredId: '',
    questionOWCSKey: 'NI_236_Body',
    requestParamKey: '',
    mbQuestionDescription: '<div>If you&#39;re not sure how to spell their name, enter at least the firt two letters and an asterisk (ex: Jo*).</div>',
    visibility: true,
    companyQ: false
  },
  NI_260_Body: {
    registeredId: '',
    questionOWCSKey: 'NI_260_Body',
    requestParamKey: '',
    mbQuestionDescription: '<div>Last Name</div>',
    visibility: true,
    companyQ: false
  },
  NI_263_Body: {
    registeredId: '',
    questionOWCSKey: 'NI_263_Body',
    requestParamKey: '',
    mbQuestionDescription: '<div>Enter at least 2 characters of the Last Name.</div>',
    visibility: true,
    companyQ: false
  },
  NI_256_Body: {
    registeredId: '',
    questionOWCSKey: 'NI_256_Body',
    requestParamKey: '',
    mbQuestionDescription: '<div>First Name</div>',
    visibility: true,
    companyQ: false
  },
  NI_259_Body: {
    registeredId: '',
    questionOWCSKey: 'NI_259_Body',
    requestParamKey: '',
    mbQuestionDescription: '<div>Enter at least 2 characters of the First Name.</div>',
    visibility: true,
    companyQ: false
  },
  NI_264_Body: {
    registeredId: '',
    questionOWCSKey: 'NI_264_Body',
    requestParamKey: '',
    mbQuestionDescription: '<div>City</div',
    visibility: true,
    companyQ: false
  },
  NI_267_Body: {
    registeredId: '',
    questionOWCSKey:  'NI_267_Body',
    requestParamKey: '',
    mbQuestionDescription: '<div>Enter at least 2 characters of the City.</div>',
    visibility: true,
    companyQ: false
  },
  NI_268_Body: {
    registeredId: '',
    questionOWCSKey: 'NI_268_Body',
    requestParamKey: '',
    mbQuestionDescription: '<div>Location</div>',
    visibility: true,
    companyQ: false
  },
  NI_271_Body: {
    registeredId: '',
    questionOWCSKey: 'NI_271_Body',
    requestParamKey: '',
    mbQuestionDescription: '<div>Please choose a location.</div>',
    visibility: true,
    companyQ: false
  },
  NI_275_Body: {
    registeredId: '',
    questionOWCSKey: 'NI_275_Body',
    requestParamKey: '',
    mbQuestionDescription: '<div>Make sure you enter the full 5-digit ZIP code.</div>',
    visibility: true,
    companyQ: false
  },
  NI_272_Body: {
    registeredId: '',
    questionOWCSKey: 'NI_272_Body',
    requestParamKey: '',
    mbQuestionDescription: '<div>ZIP Code (Optional)</div>',
    visibility: true,
    companyQ: false
  },
  NI_276_Body: {
    registeredId: '',
    questionOWCSKey: 'NI_276_Body',
    requestParamKey: '',
    mbQuestionDescription: '<div>We found {Dynamic_Count} providers that match your search.</div>',
    visibility: true,
    companyQ: false
  },
  NI_278_Body: {
    registeredId: '',
    questionOWCSKey: 'NI_278_Body',
    requestParamKey: '',
    mbQuestionDescription: '<div>Provider Name and Address</div>',
    visibility: true,
    companyQ: false
  },
  NI_284_Body: {
    registeredId: '',
    questionOWCSKey: 'NI_284_Body',
    requestParamKey: '',
    mbQuestionDescription: '<div>If your provider isn&#39;t here, you can enter their information manually.</div>',
    visibility: true,
    companyQ: false
  },
  NI_286_Body: {
    registeredId: '',
    questionOWCSKey: 'NI_286_Body',
    requestParamKey: '',
    mbQuestionDescription: '<div>Enter Provider Info</div>',
    visibility: true,
    companyQ: false
  },
  NI_277_Body: {
    registeredId: '',
    questionOWCSKey: 'NI_277_Body',
    requestParamKey: '',
    mbQuestionDescription: '<div>More than 200 providers match your search. Enter more information for better results, or you can add your provider manually.</div>',
    visibility: true,
    companyQ: false
  },
  NI_715_Body: {
    registeredId: '',
    questionOWCSKey: 'NI_715_Body',
    requestParamKey: '',
    mbQuestionDescription: 'What complication do you have?',
    visibility: true,
    companyQ: false
  },
  NI_716_Body: {
    registeredId: '',
    questionOWCSKey: 'NI_716_Body',
    requestParamKey: '',
    mbQuestionDescription: 'If you have more than one, choose the most serious one.',
    visibility: true,
    companyQ: false
  },
  NI_717_Body: {
    registeredId: 'O60.00',
    questionOWCSKey: 'NI_717_Body',
    requestParamKey: '',
    mbQuestionDescription: 'Early labor but no delivery',
    visibility: true,
    companyQ: false
  },
  NI_718_Body: {
    registeredId: 'O15.00',
    questionOWCSKey: 'NI_718_Body',
    requestParamKey: '',
    mbQuestionDescription: 'Eclampsia',
    visibility: true,
    companyQ: false
  },
  NI_719_Body: {
    registeredId: 'O24.419',
    questionOWCSKey: 'NI_719_Body',
    requestParamKey: '',
    mbQuestionDescription: 'Gestational Diabetes (Became diabetic during pregnancy)',
    visibility: true,
    companyQ: false
  },
  NI_720_Body: {
    registeredId: 'O30.90',
    questionOWCSKey: 'NI_720_Body',
    requestParamKey: '',
    mbQuestionDescription: 'Pregnant with multiples',
    visibility: true,
    companyQ: false
  },
  NI_721_Body: {
    registeredId: 'O20.0',
    questionOWCSKey: 'NI_721_Body',
    requestParamKey: '',
    mbQuestionDescription: 'Spotting, cramping or other miscarriage symptoms',
    visibility: true,
    companyQ: false
  },
  NI_722_Body: {
    registeredId: 'O24.019',
    questionOWCSKey: 'NI_722_Body',
    requestParamKey: '',
    mbQuestionDescription: 'Type 1 Diabetes (Diabetic before pregnancy)',
    visibility: true,
    companyQ: false
  },
  NI_726_Body: {
    registeredId: '',
    questionOWCSKey: 'NI_726_Body',
    requestParamKey: '',
    mbQuestionDescription: 'Has your healthcare provider told you that this may be a high-risk pregnancy?',
    visibility: true,
    companyQ: false
  },
  NI_727_Body: {
    registeredId: '',
    questionOWCSKey: 'NI_727_Body',
    requestParamKey: '',
    mbQuestionDescription: 'Your pregnancy may be high-risk even if you don\'t have any complications right now. Risk can be based on things like age, medical history or other factors',
    visibility: true,
    companyQ: false
  },
  NI_728_Body: {
    registeredId: '',
    questionOWCSKey: 'NI_728_Body',
    requestParamKey: '',
    mbQuestionDescription: 'Yes',
    visibility: true,
    companyQ: false
  },
  NI_729_Body: {
    registeredId: '',
    questionOWCSKey: 'NI_729_Body',
    requestParamKey: '',
    mbQuestionDescription: 'No',
    visibility: true,
    companyQ: false
  },
  NI_730_Body: {
    registeredId: '',
    questionOWCSKey: 'NI_730_Body',
    requestParamKey: '',
    mbQuestionDescription: 'Are you having more than one baby?',
    visibility: true,
    companyQ: false
  },
  NI_731_Body: {
    registeredId: '',
    questionOWCSKey: 'NI_731_Body',
    requestParamKey: '',
    mbQuestionDescription: 'Yes',
    visibility: true,
    companyQ: false
  },
  NI_732_Body: {
    registeredId: '',
    questionOWCSKey: 'NI_732_Body',
    requestParamKey: '',
    mbQuestionDescription: 'No',
    visibility: true,
    companyQ: false
  },
   NI_734_Body: {
    registeredId: '',
    questionOWCSKey: 'NI_734_Body',
    requestParamKey: '',
    mbQuestionDescription: 'What did the doctor say your risks are?',
    visibility: true,
    companyQ: false
  },
    NI_735_Body: {
    registeredId: '',
    questionOWCSKey: 'NI_735_Body',
    requestParamKey: '',
    mbQuestionDescription: 'Do you expect to be admitted for more than 24 hours?',
    visibility: true,
    companyQ: false
  },
    NI_736_Body: {
    registeredId: '',
    questionOWCSKey: 'NI_736_Body',
    requestParamKey: '',
    mbQuestionDescription: 'Yes',
    visibility: true,
    companyQ: false
  },
    NI_737_Body: {
    registeredId: '',
    questionOWCSKey: 'NI_737_Body',
    requestParamKey: '',
    mbQuestionDescription: 'No',
    visibility: true,
    companyQ: false
  },
   NI_738_Body: {
    registeredId: '',
    questionOWCSKey: 'NI_738_Body',
    requestParamKey: '',
    mbQuestionDescription: 'Which hospital?',
    visibility: true,
    companyQ: false
  },
   NI_740_Body: {
    registeredId: '',
    questionOWCSKey: 'NI_740_Body',
    requestParamKey: '',
    mbQuestionDescription: 'Admission Date (Optional)',
    visibility: true,
    companyQ: false
  },
   NI_742_Body: {
    registeredId: '',
    questionOWCSKey: 'NI_742_Body',
    requestParamKey: '',
    mbQuestionDescription: 'Discharge Date (Optional)',
    visibility: true,
    companyQ: false
  },
   NI_744_Body: {
    registeredId: '',
    questionOWCSKey: 'NI_744_Body',
    requestParamKey: '',
    mbQuestionDescription: 'What was your delivery date?',
    visibility: true,
    companyQ: false
  },
   NI_747_Body: {
    registeredId: '',
    questionOWCSKey: 'NI_747_Body',
    requestParamKey: '',
    mbQuestionDescription: 'How did you deliver?',
    visibility: true,
    companyQ: false
  },
   NI_750_Body: {
    registeredId: '',
    questionOWCSKey: 'NI_750_Body',
    requestParamKey: '',
    mbQuestionDescription: 'Vaginal',
    visibility: true,
    companyQ: false
  },
   NI_751_Body: {
    registeredId: '',
    questionOWCSKey: 'NI_751_Body',
    requestParamKey: '',
    mbQuestionDescription: 'C-Section',
    visibility: true,
    companyQ: false
  },
   NI_752_Body: {
    registeredId: '',
    questionOWCSKey: 'NI_752_Body',
    requestParamKey: '',
    mbQuestionDescription: 'Were you admitted to the hospital?',
    visibility: true,
    companyQ: false
  },
   NI_753_Body: {
    registeredId: '',
    questionOWCSKey: 'NI_753_Body',
    requestParamKey: '',
    mbQuestionDescription: 'Yes',
    visibility: true,
    companyQ: false
  },
   NI_754_Body: {
    registeredId: '',
    questionOWCSKey: 'NI_754_Body',
    requestParamKey: '',
    mbQuestionDescription: 'No',
    visibility: true,
    companyQ: false
  },
  NI_755_Body: {
    registeredId: '',
    questionOWCSKey: 'NI_755_Body',
    requestParamKey: '',
    mbQuestionDescription: 'Were you admitted for more than 24 hours?',
    visibility: true,
    companyQ: false
  },
  NI_756_Body: {
    registeredId: '',
    questionOWCSKey: 'NI_756_Body',
    requestParamKey: '',
    mbQuestionDescription: 'Yes',
    visibility: true,
    companyQ: false
  },
  NI_757_Body: {
    registeredId: '',
    questionOWCSKey: 'NI_757_Body',
    requestParamKey: '',
    mbQuestionDescription: 'No',
    visibility: true,
    companyQ: false
  },
  NI_758_Body: {
    registeredId: '',
    questionOWCSKey: 'NI_758_Body',
    requestParamKey: '',
    mbQuestionDescription: 'Which hospital?',
    visibility: true,
    companyQ: false
  },
  NI_760_Body: {
    registeredId: '',
    questionOWCSKey: 'NI_760_Body',
    requestParamKey: '',
    mbQuestionDescription: 'Admission Date',
    visibility: true,
    companyQ: false
  },
  NI_762_Body: {
    registeredId: '',
    questionOWCSKey: 'NI_762_Body',
    requestParamKey: '',
    mbQuestionDescription: 'Discharge Date (Optional)',
    visibility: true,
    companyQ: false
  },
   NI_804A_Body: {
    registeredId: '',
    questionOWCSKey: 'NI_804A_Body',
    requestParamKey: '',
    mbQuestionDescription: 'We only need to know the OBGYN you’re seeing for this pregnancy. You don’t need to add your primary care doctor or other providers, but you can if you want to.',
    visibility: true,
    companyQ: false
  },
  NI_836_Body: {
    registeredId: '',
    questionOWCSKey: 'NI_836_Body',
    requestParamKey: '',
    mbQuestionDescription: 'Are you employed elsewhere?',
    visibility: true,
    companyQ: false
  },
  NI_837_Body: {
    registeredId: '',
    questionOWCSKey: 'NI_837_Body',
    requestParamKey: '',
    mbQuestionDescription: 'Yes',
    visibility: true,
    companyQ: false
  },
  NI_838_Body: {
    registeredId: '',
    questionOWCSKey: 'NI_838_Body',
    requestParamKey: '',
    mbQuestionDescription: 'No',
    visibility: true,
    companyQ: false
  },
   NI_850_Body: {
    registeredId: '',
    questionOWCSKey: 'NI_850_Body',
    requestParamKey: '',
    mbQuestionDescription: 'Alright, what is your estimated return to work date?',
    visibility: true,
    companyQ: false
  },
  NI_861_Body: {
    registeredId: '',
    questionOWCSKey: 'NI_861_Body',
    requestParamKey: '',
    mbQuestionDescription: 'Alright, what is your estimated return to work date?',
    visibility: true,
    companyQ: false
  },
 NI_542_Body: {
  registeredId: '',
  questionOWCSKey: 'NI_542_Body',
  requestParamKey: '',
  mbQuestionDescription: 'What was your last day at work?',
  visibility: true,
  companyQ: false

 },
   NI_545_Body: {
    registeredId: '',
    questionOWCSKey: 'NI_545_Body',
    requestParamKey: '',
    mbQuestionDescription: 'Was your first day absent a full day or a partial day?',
    visibility: true,
    companyQ: false
   },
    NI_546_Body: {
    registeredId: '',
    questionOWCSKey: 'NI_546_Body',
    requestParamKey: '',
    mbQuestionDescription: 'Full Day',
    visibility: true,
    companyQ: false
    },
     NI_547_Body: {
      registeredId: '',
      questionOWCSKey: 'NI_547_Body',
      requestParamKey: '',
      mbQuestionDescription: 'Full Day',
      visibility: true,
      companyQ: false
     },
    NI_548_Body : {
      registeredId: '',
      questionOWCSKey: 'NI_548_Body',
      requestParamKey: '',
      mbQuestionDescription: 'Have you returned to work?',
      visibility: true,
      companyQ: false
    },
    NI_549_Body : {
      registeredId: '',
      questionOWCSKey: 'NI_549_Body ',
      requestParamKey: '',
      mbQuestionDescription: 'Yes',
      visibility: true,
      companyQ: false
    },
    NI_550_Body : {
      registeredId: '',
      questionOWCSKey: 'NI_550_Body',
      requestParamKey: '',
      mbQuestionDescription: 'No',
      visibility: true,
      companyQ: false
    },
     NI_563_Body : {
      registeredId: '',
      questionOWCSKey: 'NI_563_Body',
      requestParamKey: '',
      mbQuestionDescription: 'What day did you return to work?',
      visibility: true,
      companyQ: false
     },
    NI_511_Body : {
      registeredId: '',
      questionOWCSKey: 'NI_511_Body',
      requestParamKey: '',
      mbQuestionDescription: '<div>It looks like there&#39;s a gap between your last day at work and your first day absent.</div>',
      visibility: true,
      companyQ: false
    },
    NI_512_Body: {
      registeredId: '',
      questionOWCSKey: ' NI_512_Body',
      requestParamKey: '',
      mbQuestionDescription: '<div>Choose the reason that best explains why.</div>',
      visibility: true,
      companyQ: false
    },
    NI_515_Body: {
      registeredId: '',
      questionOWCSKey: 'NI_515_Body',
      requestParamKey: '',
      mbQuestionDescription: '<div>Not scheduled to work/Normal days off</div>',
      visibility: true,
      companyQ: false
    },
    NI_516_Body: {
      registeredId: '',
      questionOWCSKey: 'NI_516_Body',
      requestParamKey: '',
      mbQuestionDescription: '<div>PTO/Vacation/Holiday/Personal/Sick Time</div>',
      visibility: true,
      companyQ: false
    },
    NI_517_Body: {
      registeredId: '',
      questionOWCSKey: 'NI_517_Body',
      requestParamKey: '',
      mbQuestionDescription: '<div>FML/Leave of absence</div>',
      visibility: true,
      companyQ: false
    },
    NI_518_Body: {
      registeredId: '',
      questionOWCSKey: 'NI_518_Body',
      requestParamKey: '',
      mbQuestionDescription: '<div>Lay-Off</div>',
      visibility: true,
      companyQ: false
    },
    NI_519_Body: {
      registeredId: '',
      questionOWCSKey: 'NI_519_Body',
      requestParamKey: '',
      mbQuestionDescription: '<div>Other</div>',
      visibility: true,
      companyQ: false
    },
    NI_551_Body: {
      registeredId: 'NI_551_Body',
      questionOWCSKey: '',
      requestParamKey: '',
      mbQuestionDescription: 'Are you using or applying for any of these additional income benefits? ',
      visibility: true,
      companyQ: false
    },
    NI_552_Body: {
      registeredId: '608733',
      lookUpId: 'PTO/Vacation',
      questionOWCSKey: '',
      requestParamKey: '',
      mbQuestionDescription: 'Are you using or applying for any of these additional income benefits? ',
      visibility: true,
      companyQ: false
    },
    NI_553_Body: {
      registeredId: '608733',
      lookUpId: 'Salary Continuation',
      questionOWCSKey: '',
      requestParamKey: '',
      mbQuestionDescription: 'Are you using or applying for any of these additional income benefits? ',
      visibility: true,
      companyQ: false
    },
    NI_554_Body: {
      registeredId: '608733',
      lookUpId: 'Sick Pay',
      questionOWCSKey: '',
      requestParamKey: '',
      mbQuestionDescription: 'Are you using or applying for any of these additional income benefits? ',
      visibility: true,
      companyQ: false
    },
    NI_555_Body: {
      registeredId: '608733',
      lookUpId: 'State Disability',
      questionOWCSKey: '',
      requestParamKey: '',
      mbQuestionDescription: 'Are you using or applying for any of these additional income benefits? ',
      visibility: true,
      companyQ: false
    },
    NI_556_Body: {
      registeredId: '608733',
      lookUpId: 'Other',
      questionOWCSKey: '',
      requestParamKey: '',
      mbQuestionDescription: 'Are you using or applying for any of these additional income benefits? ',
      visibility: true,
      companyQ: false
    },
    NI_557_Body: {
      registeredId: '83161',
      lookUpId: '1002',
      questionOWCSKey: '',
      requestParamKey: '',
      mbQuestionDescription: 'Are you using or applying for any of these additional income benefits? ',
      visibility: true,
      companyQ: false
    },

    NI_560_Body: {
      registeredId: 'NI_560_Body',
      questionOWCSKey: '',
      requestParamKey: '',
      mbQuestionDescription: '<div>Will you continue to work partial days?</div>',
      visibility: true,
      companyQ: false
    },
    //about your absence
    NI_538A_Body: {
      registeredId: 'NI_538A_Body',
      questionOWCSKey: '',
      requestParamKey: '',
      mbQuestionDescription: '<div>Some examples might be: income from another employer, an active Workers&#39; Compensation claim, or personal injury protection under your auto policy.</div>',
      visibility: true,
      companyQ: false
    },
    NI_538_Body: {
      registeredId: 'NI_538_Body',
      questionOWCSKey: '',
      requestParamKey: '',
      mbQuestionDescription: '<div>What other income are you getting?</div>',
      visibility: true,
      companyQ: false
    },
    NI_530_Body: {
      registeredId: 'NI_530_Body',
      questionOWCSKey: '',
      requestParamKey: '',
      mbQuestionDescription: '<div>Are you receiving or applying to receive any additional income benefits?</div>',
      visibility: true,
      companyQ: false
    },
    NI_523_Body: {
      registeredId: 'NI_523_Body',
      questionOWCSKey: '',
      requestParamKey: '',
      mbQuestionDescription: '<div>Full Day</div>',
      visibility: true,
      companyQ: false
    },
    NI_524_Body: {
      registeredId: 'NI_524_Body',
      questionOWCSKey: '',
      requestParamKey: '',
      mbQuestionDescription: '<div>Partial Day</div>',
      visibility: true,
      companyQ: false
    },
    NI_522_Body: {
      registeredId: 'NI_522_Body',
      questionOWCSKey: '',
      requestParamKey: '',
      mbQuestionDescription: '<div>Will your first day absent be a full day or a partial day?</div>',
      visibility: true,
      companyQ: false
    },
    NI_508_Body: {
      registeredId: 'NI_508_Body',
      questionOWCSKey: '',
      requestParamKey: '',
      mbQuestionDescription: '<div>If you don&#39;t know, that&#39;s okay. You can give an estimate.</div>',
      visibility: true,
      companyQ: false
    },
    NI_507_Body: {
      registeredId: 'NI_507_Body',
      questionOWCSKey: '',
      requestParamKey: '',
      mbQuestionDescription: '<div>What was or will be your last day at work?</div>',
      visibility: true,
      companyQ: false
    },
    NI_539_Body: {
      registeredId: 'NI_539_Body',
      questionOWCSKey: '',
      requestParamKey: '',
      mbQuestionDescription: '<div>Will you continue to work partial days?</div>',
      visibility: true,
      companyQ: false
    },
    NI_844_Body: {
      registeredId: 'NI_844_Body',
      questionOWCSKey: '',
      requestParamKey: '',
      mbQuestionDescription: 'Has your doctor estimated when you should be able to return to work after delivery?',
      visibility: true,
      companyQ: false
    },
    NI_828_Body: {
      registeredId: 'NI_828_Body',
      questionOWCSKey: '',
      requestParamKey: '',
      mbQuestionDescription: 'How many hours and minutes did you work on that day?',
      visibility: true,
      companyQ: false
    },
    NI_833_Body: {
      registeredId: 'NI_833_Body',
      questionOWCSKey: '',
      requestParamKey: '',
      mbQuestionDescription: 'How many hours and minutes will you work on that day?',
      visibility: true,
      companyQ: false
    },
    NI_829_Body: {
      registeredId: 'NI_829_Body',
      questionOWCSKey: '',
      requestParamKey: '',
      mbQuestionDescription: 'Select hours (even if 00)',
      visibility: true,
      companyQ: false
    },
    NI_834_Body: {
      registeredId: 'NI_834_Body',
      questionOWCSKey: '',
      requestParamKey: '',
      mbQuestionDescription: 'Select hours (even if 00)',
      visibility: true,
      companyQ: false
    },
    NI_870_Body: {
      registeredId: 'NI_870_Body',
      questionOWCSKey: '',
      requestParamKey: '',
      mbQuestionDescription: 'Hours',
      visibility: true,
      companyQ: false
    },
    NI_831_Body: {
      registeredId: 'NI_831_Body',
      questionOWCSKey: '',
      requestParamKey: '',
      mbQuestionDescription: 'Must select hours',
      visibility: true,
      companyQ: false
    },
    NI_830_Body: {
      registeredId: 'NI_830_Body',
      questionOWCSKey: '',
      requestParamKey: '',
      mbQuestionDescription: 'Select minutes (even if 00)',
      visibility: true,
      companyQ: false
    },
    NI_871_Body: {
      registeredId: 'NI_871_Body',
      questionOWCSKey: '',
      requestParamKey: '',
      mbQuestionDescription: 'Minutes',
      visibility: true,
      companyQ: false
    },
    NI_832_Body: {
      registeredId: 'NI_832_Body',
      questionOWCSKey: '',
      requestParamKey: '',
      mbQuestionDescription: 'Must select minutes',
      visibility: true,
      companyQ: false
    },
    NI_917A_Body: {
      registeredId: '1666',
      lookUpId: '206001',
      questionOWCSKey: 'Paid Time Off',
      requestParamKey: '',
      mbQuestionDescription: 'Are you using or applying for any of these additional income benefits? ',
      visibility: true,
      companyQ: false
    },
    NI_917B_Body: {
      registeredId: '1666',
      lookUpId: '206002',
      questionOWCSKey: 'Paid Sick Time',
      requestParamKey: '',
      mbQuestionDescription: 'Are you using or applying for any of these additional income benefits? ',
      visibility: true,
      companyQ: false
    },
    NI_917C_Body: {
      registeredId: '1666',
      lookUpId: '206003',
      questionOWCSKey: 'Paid Vacation Time',
      requestParamKey: '',
      mbQuestionDescription: 'Are you using or applying for any of these additional income benefits? ',
      visibility: true,
      companyQ: false
    },
    NI_917D_Body: {
      registeredId: '1666',
      lookUpId: '206004',
      questionOWCSKey: ',Other:',
      requestParamKey: '',
      mbQuestionDescription: 'Are you using or applying for any of these additional income benefits? ',
      visibility: true,
      companyQ: false
    },
    NI_917E_Body: {
      registeredId: '1666',
      lookUpId: '206004',
      questionOWCSKey: 'Employee selected "None"',
      requestParamKey: '',
      mbQuestionDescription: 'Are you using or applying for any of these additional income benefits? ',
      visibility: true,
      companyQ: false
    },

}

export const INTAKE_LOOKUP_JSON: ILookupKeyObject = {
  SANLS1_14A_Body: {
      registeredId: '43',
      mbAnswerDescription: 'FDA Date',
      questionOWCSKey: 'SANLS1_14A_Body',
      mbQuestionDescription: 'What is or will be your first day out of work?',
      thaaQuestionDescription: 'What is or will be your first day absent from work or the start date or your accommodation',
      requestParamKey: 'startDate',
      type: LookUp_JSON_CONSTANTS.QUESTION
  },
  SANLS1_14_Body: {
    registeredId: '43',
    mbAnswerDescription: 'FDA Date',
    questionOWCSKey: 'SANLS1_14_Body',
    mbQuestionDescription: 'What is or will be your first day absent from work?',
    thaaQuestionDescription: 'What is or will be your first day absent from work or the start date or your accommodation',
    requestParamKey: 'startDate',
    type: LookUp_JSON_CONSTANTS.QUESTION
},
SANLS1_17_Body: {
  registeredId: '42',
  mbAnswerDescription: 'I don\'t know',
  questionOWCSKey: 'SANLS1_17_Body',
  mbQuestionDescription: 'What is or will be your first day absent from work?',
  thaaQuestionDescription: 'What is or will be your first day absent from work or the start date or your accommodation',
  requestParamKey: 'DateNotSelected',
  type: LookUp_JSON_CONSTANTS.QUESTION
},

SANLS1_20_Body: {
  registeredId: '1668',
  mbAnswerDescription: 'Parenthood journey',
  questionOWCSKey: 'SANLS1_20_Body',
  mbQuestionDescription: 'Where are you on your parenthood journey?',
  thaaQuestionDescription: 'Have you already delivered',
  requestParamKey: 'maternityDelivery',
  type: LookUp_JSON_CONSTANTS.QUESTION
},

}
