/**
 * Created by AC12323 on 9/27/2017.
 */

/**
 * Created by : AC12394
 * Date : 12-11-2017
 * Reason : Documentum Service Response has changed
 */
export class ClaimDocument {
  DocumentCreationDate: string;
  SourceCreationDate: string;
  DocumentType: string;
  DocumentFileName: string;
  DocumentId: string;
  HIGDocumentIdentifier: string;

  documentControlNumber: string;
  directory: string;
  path: string;
  size: number;
}

export class ClaimDocumentSearchResponse {
  documents: ClaimDocument[];
}
