/**
 * Created by AC12212 on 2/24/2018.
 */
export class profileSync {
  status: string;
  code: string;


  constructor() {
    this.status = 'Success';
    this.code = '200';

  }
}
