/**
 * Created by ACC22025 on 07/08/2019.
 */
export const MOCK_LEAVE_REASON = {
  availableReason:    [
    {
      Reason_KeyId: '15',
      DisplayReason: 'ADA accommodation'
    },
    {
      Reason_KeyId: '1',
      DisplayReason: 'Employee\'s own illness'
    }, {
      Reason_KeyId: '2',
      DisplayReason: 'Maternity'
    }, {
      Reason_KeyId: '3',
      DisplayReason: 'Care of a Family Member'
    }, {
      Reason_KeyId: '4',
      DisplayReason: 'Bonding/Adoption/Foster Care'
    }, {
      Reason_KeyId: '5',
      DisplayReason: 'Other'
    }
  ],
  responseCode: '001',
  responseReason: 'Success'
}
