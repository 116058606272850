import { Injectable } from '@angular/core';
import { catchError, map } from 'rxjs/operators';
import { Observable, throwError } from 'rxjs';
import { IfetchCompanyParm } from '../models/intake-content.model';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { ResponseErrorHandlerService } from '@app/shared/services/response-error-handler.service';
import { env } from '@env/environment-loader';
import { AppLabelConstants } from '@app/shared/constants/appLabel.constants';

@Injectable({
  providedIn: 'root'
})
export class IsparmEnabledService {

  constructor(private errorHandler: ResponseErrorHandlerService,
              private httpClient: HttpClient) {
  }

  getIsParmEnabledDetails(personId: string, companyParmsCode: string): Observable<IfetchCompanyParm> {
    if (personId && companyParmsCode) {
      return this.httpClient.get(env.thaaWKABPortalService.isParmEnabled + '&personID=' + personId + '&companyParmsCode=' + companyParmsCode,
        {
          headers: new HttpHeaders()
            .set(AppLabelConstants.CONTENT_TYPE_KEY, AppLabelConstants.APPLICATION_KEY)
        }).pipe(
        map(
          (response: IfetchCompanyParm) => {
           if (!this.errorHandler.isServiceResponseHasError(response)) {
              return response;
            } else {
              throw response;
            }
          }
        ), catchError((error: any) => this.handleError(error)),
      );
    } else {
      return this.errorHandler.handleError('Parm enabled API failed ');
    }
  }
  handleError(error: Response) {
    return throwError(error);
  }
}
