import {Component, OnInit} from '@angular/core';
import {Router} from '@angular/router';
import {ContentService} from '../../shared/services/content.service';
import {AppLabelConstants} from '../../shared/constants/appLabel.constants';
import {AppConstants} from '../../shared/constants/app.constants';

@Component({
  selector: 'app-transaction-error',
  templateUrl: './transaction-error.component.html',
  styleUrls: ['./transaction-error.component.scss']
})
export class TransactionErrorComponent implements OnInit {
  public errorImgPath: string = AppLabelConstants.ERROR_SVG2;
  public pageTitle: string = AppLabelConstants.SYSTEM_DOWN;
  private pageId: string;
  private owcsContent;
  public buttonLabel: string = AppLabelConstants.RETURN_TO_HOMEPAGE;

  public errorLbl: string;  //'This is embarrassing.'
  public errorText: string;  /** 'We’re Sorry. Something went wrong on our end. ' +
    'We’re working to fix the problem. You can try again later, ' +
    'or call 1-877-778-1383 Option 5 M-F 8AM-8PM ET if the problem persists.'; **/
  constructor(private router: Router,
              private contentService: ContentService) { }


  ngOnInit() {
    this.pageId = this.contentService.getPageId(AppConstants.FAILED_TRANSACTION_ERROR_PG);
    if (this.pageId) {
      this.contentService.getOWCSResponse(this.pageId, AppConstants.FAILED_TRANSACTION_ERROR_PG_U).subscribe(
        val => {
          if (val) {
            this.owcsContent = val;
            if (this.owcsContent && this.owcsContent.data && this.owcsContent.data.FTEP_03_Body && this.owcsContent.data.FTEP_02_Body) {
              this.errorLbl = this.owcsContent.data.FTEP_02_Body.value;
              this.errorText = this.owcsContent.data.FTEP_03_Body.value;
            }
          }
        }
      );
    }
  }

  backToHome() {
    this.router.navigate([AppLabelConstants.URL_HOME]);
  }
}
