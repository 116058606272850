/**
 * Created by AC12394 on 10/6/2018.
 */
export class Feature {
  id: string;
  title: string;
  message_type: string;
  message_close_type: string;
  start_date: string;
  end_date: string;
  value: string;
  isTurnedOff?: boolean;
}

export class FeatureTurnOnOffResponse {
  message: Feature[];
}

export class FeatureList {
  name: string;
  pageTitle: string;
}

export const FEATURELISTS: FeatureList[] = [
  {
    name: 'directDeposit',
    pageTitle: 'Direct Deposit'
  },
  {
    name: 'communicationPreferences',
    pageTitle: 'Communication Preferences'
  },
  {
    name: 'aboutYou',
    pageTitle: 'About You'
  },
  {
    name: 'documentUpload',
    pageTitle: 'Document Upload'
  },
  {
    name: 'password',
    pageTitle: 'Password'
  }
  ,
  {
    name: 'yourAccount',
    pageTitle: 'Your Account'
  }
];
