import { Injectable } from "@angular/core";
import { BehaviorSubject, Subject } from "rxjs";

@Injectable()
export class ModalService {
  private currentModal: string;

  private modalClosed = new Subject();
  private modalOpenedSource = new BehaviorSubject<string>("cancelModal");
  private isOpen: boolean = false;

  modalClosed$ = this.modalClosed.asObservable();
  modalOpened$ = this.modalOpenedSource.asObservable();

  get flagOpen(): boolean {
    return this.isOpen;
  }

  set flagOpen(isOpen) {
    this.isOpen = isOpen;
  }

  closeModal(modalName: string): void {
    this.isOpen = false;
    this.modalClosed.next(modalName);
  }

  openModal(modalName: string): void {
    this.isOpen = true;
    this.currentModal = modalName;
    this.modalOpenedSource.next(modalName);
  }

  getActiveModal(): string {
    return this.currentModal;
  }

  closeActiveModal(): void {
    this.closeModal(this.currentModal);
  }

  clear(): void {
    this.modalOpenedSource.complete();
    this.modalClosed.complete();
    this.modalClosed = new Subject();
    this.modalOpenedSource = new BehaviorSubject("");
    this.modalClosed$ = this.modalClosed.asObservable();
    this.modalOpened$ = this.modalOpenedSource.asObservable();
  }
}
