import {IReasonResponse} from '@app/cxr/modules/intake/shared/models/intake-content.model';

export const MOCK_ABSENT_REASON_LIST: IReasonResponse = {

  ReasonList: [

    {

      ReasonName: 'ADA accommodation',

      LookupId: 7009,

      KeyId: '12'

    },

    {

      ReasonName: 'Bonding, adoption, or foster care',

      LookupId: 7003,

      KeyId: '15'

    },

    {

      ReasonName: 'Caring for someone',

      LookupId: 7002,

      KeyId: '17'

    },

    {

      ReasonName: 'My own illness or injury',

      LookupId: 7001,

      KeyId: '13'

    },

    {

      ReasonName: 'Pregnancy/Maternity',

      LookupId: 7004,

      KeyId: '11'

    },



  ],

  responseCode: '001',

  responseReason: 'Success'

};
