/**
 * Created by AC12394 on 2/20/2018.
 */
export class Provision {
  caseId: number;
  coverageCategoryCode: string;
  coverageTypeCode: string;
  fundingMethodCode: string;
  provisionId: string;
  provisionDescription: string;
  classNumber: string;
  classDescription: string;
  provisionOptionId: string;
  provisionOptionValue: string;
  selectionNumber: string;
  provisionOptionTerminationDate: string;
  provisionOptionEffectiveDate: string;
}

export class ProvisionResponse {
  errorList: any[];
  provisionResponseList: Provision[];
}
