import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {ModalService} from '../../../../shared/services/modal.service';
import {AppLabelConstants} from '../../../../shared/constants/appLabel.constants';


@Component({
  selector: 'cleanse-address',
  templateUrl: './cleanse-address.component.html',
  styleUrls: ['./cleanse-address.component.scss']
})
export class CleanseAddressComponent implements OnInit {

  /*@Output() private ifChangesSaved = new EventEmitter<boolean>();
  @Output() private  ifChangeDone  =new EventEmitter<boolean>();*/
  @Input() providedAddress;
  @Input() suggestedAddress;
  @Output() useSuggestedAddressCheck = new EventEmitter<boolean>();
  @Input() userformValues;
  @Input() addressMsg;
  public modalTitle = '';
  public appLabelConstant = AppLabelConstants;

  constructor( private modalService: ModalService
  ) {
  }

  ngOnInit() {
  }

  clickClose() {
    this.modalService.closeActiveModal();
  }

  useSuggestedAddress(check) {
    /*this.editComponent.getProfileAndUpdate(this.userformValues);*/
  }



}
