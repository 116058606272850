import { Intake_Constants } from "../constants/intake.constants";

export function convertTo24Hour(hours: string, unit: string): number {
  let convertedHours: string;
  convertedHours = hours;
  if (hours === Intake_Constants.TIME_12_HOUR_VAL) {
    convertedHours = Intake_Constants.TIME_MINIMUM_VAL;
  }
  if (unit === Intake_Constants.TIME_PM_UNIT) {
    convertedHours = (parseInt(convertedHours, 10) + 12).toString();
  }
  return parseInt(convertedHours, 10);
}
