export const MOCK_CPT_DETAILS = {
  'cptDetail': null,
  'responseCode': '002',
  'responseReason': 'Person ID does not exist'
}
export const MOCK_CPT = {
  'cptDetail':'0,4546,59410,VAGINAL DELIVERY ONLY (W/WO EPISIOTOMY &/OR FORCEPS); W/POSTPARTUM CARE,D,340,07/10/2023#',
  'responseCode': '001',
  'responseReason': 'success'
}
