import {ClaimDetailsALT} from '../shared/models/claimDetails.model';

export const SORTED_BENEFITS = [
  //WD STAT 12/15/2018 No conc leave
  {
    claimEventId: '100020',
    personId: '099',
    sourceSystemId: 'AA',
    concurrentLeaveIDs: [],
    claimType: 'Short Term Disability - Statutory',
    claimSubType: 'State Short Term Disability',
    coverageType: 'Intermittent',
    coverageCategory: 'WD',
    disabilityDate: '12/15/2018',
    effectiveDate: '12/15/2018',
    authorizedEndDate: '12/31/2018',
    claimStatus: 'Approved',
    claimStatusCode: '02',
    claimOnlineStatus: 'Claim Approved',
    claimOnlineStatusCode: '04',
    claimOnlineSubStatus: 'Benefit Payments Complete',
    claimOnlineSubStatusCode: '04',
    extensionStatus: null,
    extensionStatusCode: null,
    extensionSubStatus: 'Missing Info',
    extensionSubStatusCode: null,
    claimOfficeNumber: null,
    claimOfficeId: '16',
    caseId: '471538',
    claimProcessDate: '12/15/2018',
    claimEffectiveDate: '12/15/2018',
    reason: 'N/A',
    physicianStatementDate: '12/15/2018',
    employerStatementDate: '12/15/2018',
    employeeStatementDate: '12/15/2018',
    estimatedSurgeryDate: null,
    estimatedDeliveryDate: null,
    lastDayWork: '12/15/2018',
    asmIndicator: 'A',
    confirmedLastDayWorkDate: '12/15/2018',
    confirmedSurgeryDate: '12/15/2018',
    confirmedDeliveryDate: null,
    encIndicator: false,
    encSurgeryIndicator: false,
    fundingMethod: 'FI',
    coveragePlanNumber: '2',
    taskOpen: true,
    missingInfo: [
      {
        mInfoCode: '03',
        mInfoDesc: 'Physician',
        requiredByDate: null,
        mInfoReqInfo: [
          {
            mInfoReqInfoCd: '20',
            mInfoReqInfoDesc: 'Attending Physician\'s Statement - Progress Reprot for Mental Health',
            requestDate: '12/07/2018',
            displayValue: 'Attending Physician\'s Statement',
            chatInd: false,
            callInd: false,
            uploadInd: true,
            detailInd: true,
            formType: 'generic',
            listPhysicain: [
              {
                physicianName: 'YOUR PHYSICIAN',
                fromDataRange: null,
                toDataRange: null
              }
            ]
          }
        ],
        gracePeriod: false
      }
    ],
    claimProgress: 'In Payment',
    extensionRequestDate: '12/12/2018',
    babyDeathIndicator: null,
    recentClaimIndicator: true
  },
  //GROUP 1:
  [
    //LTD 11/29/2018 No conc leave
    {
      claimEventId: '100012',
      sourceSystemId: 'DCS',
      concurrentLeaveIDs: [],
      claimType: 'Long Term Disability',
      claimSubType: 'Long Term Disability',
      coverageType: 'N/A',
      coverageCategory: 'LTD',
      disabilityDate: '11/29/2018',
      effectiveDate: null,
      authorizedEndDate: null,
      claimStatus: 'First Notice',
      claimStatusCode: '08',
      claimOnlineStatus: null,
      claimOnlineStatusCode: null,
      claimOnlineSubStatus: null,
      claimOnlineSubStatusCode: null,
      extensionStatus: null,
      extensionStatusCode: null,
      extensionSubStatus: null,
      extensionSubStatusCode: null,
      claimOfficeNumber: null,
      claimOfficeId: '733',
      caseId: '471538',
      claimProcessDate: '11/28/2018',
      claimEffectiveDate: '11/28/2018',
      reason: 'N/A',
      physicianStatementDate: null,
      employerStatementDate: null,
      employeeStatementDate: null,
      estimatedSurgeryDate: null,
      estimatedDeliveryDate: null,
      lastDayWork: null,
      asmIndicator: null,
      confirmedLastDayWorkDate: null,
      confirmedSurgeryDate: null,
      confirmedDeliveryDate: null,
      encIndicator: false,
      encSurgeryIndicator: false,
      fundingMethod: 'N/A',
      coveragePlanNumber: null,
      taskOpen: false,
      missingInfo: null,
      claimProgress: null,
      extensionRequestDate: null,
      babyDeathIndicator: null,
      recentClaimIndicator: true
    },
    //LIFE 11/27/2018 conc leave 1000004
    {
      claimEventId: '100014',
      sourceSystemId: 'AA',
      concurrentLeaveIDs: [],
      claimType: 'Premium Waiver - Life Voluntary',
      claimSubType: 'Premium Waiver (Voluntary Life)',
      coverageType: 'VOL',
      coverageCategory: 'LIFE',
      disabilityDate: '11/27/2018',
      effectiveDate: '11/27/2018',
      authorizedEndDate: null,
      claimStatus: 'Approved',
      claimStatusCode: '02',
      claimOnlineStatus: null,
      claimOnlineStatusCode: null,
      claimOnlineSubStatus: null,
      claimOnlineSubStatusCode: null,
      extensionStatus: null,
      extensionStatusCode: null,
      extensionSubStatus: null,
      extensionSubStatusCode: null,
      claimOfficeNumber: null,
      claimOfficeId: '5',
      caseId: '764185',
      claimProcessDate: '11/27/2018',
      claimEffectiveDate: '11/27/2018',
      reason: 'N/A',
      physicianStatementDate: '12/29/2014',
      employerStatementDate: '12/29/2014',
      employeeStatementDate: '08/06/2015',
      estimatedSurgeryDate: null,
      estimatedDeliveryDate: null,
      lastDayWork: '03/11/2015',
      asmIndicator: null,
      confirmedLastDayWorkDate: null,
      confirmedSurgeryDate: null,
      confirmedDeliveryDate: null,
      encIndicator: false,
      encSurgeryIndicator: false,
      fundingMethod: 'FI',
      coveragePlanNumber: '2',
      taskOpen: false,
      missingInfo: null,
      claimProgress: null,
      extensionRequestDate: null,
      babyDeathIndicator: null,
      recentClaimIndicator: false
    },
    //WD NST 11/28/2018 No conc leave
    {
      claimEventId: '100013',
      sourceSystemId: 'DCS',
      concurrentLeaveIDs: [],
      claimType: 'Short Term Disability',
      claimSubType: 'Short Term Disability',
      coverageType: 'NST',
      coverageCategory: 'WD',
      disabilityDate: '11/28/2018',
      effectiveDate: '11/28/2018',
      authorizedEndDate: null,
      claimStatus: 'Pending',
      claimStatusCode: '01',
      claimOnlineStatus: 'Awaiting Disability',
      claimOnlineStatusCode: '02',
      claimOnlineSubStatus: 'Awaiting Disability',
      claimOnlineSubStatusCode: '02',
      extensionStatus: null,
      extensionStatusCode: null,
      extensionSubStatus: null,
      extensionSubStatusCode: null,
      claimOfficeNumber: null,
      claimOfficeId: '733',
      caseId: '471538',
      claimProcessDate: '11/28/2018',
      claimEffectiveDate: '11/28/2018',
      reason: 'N/A',
      physicianStatementDate: null,
      employerStatementDate: null,
      employeeStatementDate: '11/28/2018',
      estimatedSurgeryDate: null,
      estimatedDeliveryDate: null,
      lastDayWork: '06/23/2018',
      asmIndicator: 'A',
      confirmedLastDayWorkDate: null,
      confirmedSurgeryDate: null,
      confirmedDeliveryDate: null,
      encIndicator: false,
      encSurgeryIndicator: false,
      fundingMethod: 'FI',
      coveragePlanNumber: '1',
      taskOpen: false,
      missingInfo: null,
      claimProgress: 'Awaiting Disability',
      extensionRequestDate: null,
      babyDeathIndicator: null,
      recentClaimIndicator: true
    },
    //WD SUPP 11/30/2018 conc leave 1000001
    {
      claimEventId: '100011',
      sourceSystemId: 'DCS',
      concurrentLeaveIDs: ['100001'],
      claimType: 'Short Term Disability- Supplemental Statutory',
      claimSubType: 'Supplemental State Surgery',
      coverageType: 'SUPP',
      coverageCategory: 'WD',
      disabilityDate: '11/30/2018',
      effectiveDate: '11/30/2018',
      authorizedEndDate: '12/31/2018',
      claimStatus: 'Terminate',
      claimStatusCode: '04',
      claimOnlineStatus: 'Claim Closed',
      claimOnlineStatusCode: '05',
      claimOnlineSubStatus: 'Claim Closed',
      claimOnlineSubStatusCode: '08',
      extensionStatus: null,
      extensionStatusCode: null,
      extensionSubStatus: 'Missing Info',
      extensionSubStatusCode: null,
      claimOfficeNumber: null,
      claimOfficeId: '16',
      caseId: '978301',
      claimProcessDate: '10/10/2016',
      claimEffectiveDate: '05/27/2016',
      reason: 'RELEASED TO RETURN TO WORK',
      physicianStatementDate: '05/02/2016',
      employerStatementDate: '05/04/2016',
      employeeStatementDate: '04/25/2016',
      estimatedSurgeryDate: '04/27/2016',
      estimatedDeliveryDate: null,
      lastDayWork: '04/26/2016',
      asmIndicator: 'S',
      confirmedLastDayWorkDate: '04/26/2016',
      confirmedSurgeryDate: '04/27/2016',
      confirmedDeliveryDate: null,
      encIndicator: true,
      encSurgeryIndicator: false,
      fundingMethod: 'FI',
      coveragePlanNumber: '1',
      taskOpen: true,
      missingInfo: null,
      claimProgress: 'Claim Closed',
      extensionRequestDate: '01/01/2019',
      babyDeathIndicator: null,
      recentClaimIndicator: false
    },
    //WD STAT 12/01/2018 No conc leave
    {
      claimEventId: '17131480',
      sourceSystemId: 'DCS',
      concurrentLeaveIDs: null,
      claimType: 'Short Term Disability',
      claimSubType: 'Short Term Disability',
      coverageType: 'NST',
      coverageCategory: 'WD',
      disabilityDate: '01/05/2021',
      effectiveDate: '01/12/2021',
      authorizedEndDate: '01/29/2021',
      claimStatus: 'Terminate',
      claimStatusCode: '04',
      claimOnlineStatus: 'Claim Closed',
      claimOnlineStatusCode: '05',
      claimOnlineSubStatus: 'Claim Closed',
      claimOnlineSubStatusCode: '08',
      extensionStatus: 'Extension Under Review',
      extensionStatusCode: '1',
      extensionSubStatus: 'Missing Info',
      extensionSubStatusCode: '1',
      claimOfficeNumber: null,
      claimOfficeId: '733',
      caseId: '52353',
      claimProcessDate: '02/04/2021',
      claimEffectiveDate: '01/30/2021',
      reason: 'NO LONGER MEETS DEFINITION OF DISABILITY',
      physicianStatementDate: '02/02/2021',
      employerStatementDate: '01/26/2021',
      employeeStatementDate: '01/26/2021',
      estimatedSurgeryDate: null,
      estimatedDeliveryDate: null,
      lastDayWork: '01/04/2021',
      asmIndicator: 'S',
      confirmedLastDayWorkDate: '01/04/2021',
      confirmedSurgeryDate: null,
      confirmedDeliveryDate: null,
      encIndicator: false,
      encSurgeryIndicator: false,
      fundingMethod: 'FI',
      coveragePlanNumber: '1',
      taskOpen: true,
      missingInfo: [
        {
          mInfoCode: '03',
          mInfoDesc: 'Physician',
          requiredByDate: null,
          mInfoReqInfo: [
            {
              mInfoReqInfoCd: '19',
              mInfoReqInfoDesc: 'Attending Physician\'s Statement - Progress Report',
              requestDate: '02/05/2021',
              displayValue: 'Attending Physician\'s Statement',
              chatInd: false,
              callInd: false,
              uploadInd: true,
              detailInd: true,
              formType: 'generic',
              listPhysicain: [
                {
                  physicianName: 'YOUR PHYSICIAN',
                  fromDataRange: null,
                  toDataRange: null
                }
              ]
            }
          ],
          gracePeriod: false
        }
      ],
      claimProgress: 'Claim Closed',
      extensionRequestDate: '04/02/2021',
      babyDeathIndicator: null,
      recentClaimIndicator: true
    },
    //Concurrent to WD SUPP 11/30/2018
    {
      leaveId: '100001',
      leaveStartDate: '09/01/2018',
      leaveEndDate: '09/31/2018',
      leaveStatus: 'Open',
      leaveReason: `Day-Care Activity`,
      caseId: '200000',
      personId: '300000',
      reedLeaveId: null,
      leaveProcessDate: '09/02/2018',
      leaveType: 'Standalone',
      leaveSubType: 'Continuous',
      leaveEditDate: '09/28/2018',
      insuredFirstName: 'James',
      insuredLastName: 'Thomas',
      leaveFirstName: 'Mary',
      leaveLastName: 'Thomas',
      leavePlanChangedDetected: true
    },
    //Concurrent to LIFE 11/27/2018
    {
      leaveId: '100004',
      leaveStartDate: '10/01/2018',
      leaveEndDate: '10/02/2018',
      leaveStatus: 'Open',
      leaveReason: `Disability`,
      caseId: '456789',
      personId: '123456',
      reedLeaveId: null,
      firstName: 'Mary'
    },
  ],
  [
    //WD SUPP 11/15/2018 conc leave 100002
    {
      claimEventId: '100021',
      sourceSystemId: 'DCS',
      concurrentLeaveIDs: ['100002'],
      claimType: 'Short Term Disability- Supplemental Statutory',
      claimSubType: 'Supplemental State Surgery',
      coverageType: 'SUPP',
      coverageCategory: 'WD',
      disabilityDate: '11/15/2018',
      effectiveDate: '11/15/2018',
      authorizedEndDate: '11/25/2019',
      claimStatus: 'Terminate',
      claimStatusCode: '04',
      claimOnlineStatus: 'Claim Closed',
      claimOnlineStatusCode: '05',
      claimOnlineSubStatus: 'Claim Closed',
      claimOnlineSubStatusCode: '08',
      extensionStatus: null,
      extensionStatusCode: null,
      extensionSubStatus: 'Missing Info',
      extensionSubStatusCode: null,
      claimOfficeNumber: null,
      claimOfficeId: '16',
      caseId: '978301',
      claimProcessDate: '11/15/2018',
      claimEffectiveDate: '11/15/2018',
      reason: 'RELEASED TO RETURN TO WORK',
      physicianStatementDate: '11/15/2018',
      employerStatementDate: '11/15/2018',
      employeeStatementDate: '11/15/2018',
      estimatedSurgeryDate: '11/15/2018',
      estimatedDeliveryDate: null,
      lastDayWork: '11/15/2018',
      asmIndicator: 'S',
      confirmedLastDayWorkDate: '11/15/2018',
      confirmedSurgeryDate: '11/15/2018',
      confirmedDeliveryDate: null,
      encIndicator: true,
      encSurgeryIndicator: false,
      fundingMethod: 'FI',
      coveragePlanNumber: '1',
      taskOpen: true,
      missingInfo: null,
      claimProgress: 'Claim Closed',
      extensionRequestDate: '01/01/2019',
      babyDeathIndicator: null,
      recentClaimIndicator: false
    },
    //Concurrent to WD SUPP 11/15/2018
    {
      leaveId: '100002',
      leaveStartDate: '11/01/2018',
      leaveEndDate: '11/05/2018',
      leaveStatus: 'Pending',
      leaveReason: `Employee's Own Illness`,
      caseId: '456789',
      personId: '123456',
      reedLeaveId: null,
      firstName: 'Mary'
    },
  ],
  //LTD 10/15/2018 No conc leave
  {
    claimEventId: '100023',
    sourceSystemId: 'DCS',
    concurrentLeaveIDs: [],
    claimType: 'Long Term Disability',
    claimSubType: 'Long Term Disability',
    coverageType: 'N/A',
    coverageCategory: 'LTD',
    disabilityDate: '10/15/2018',
    effectiveDate: null,
    authorizedEndDate: null,
    claimStatus: 'First Notice',
    claimStatusCode: '08',
    claimOnlineStatus: null,
    claimOnlineStatusCode: null,
    claimOnlineSubStatus: null,
    claimOnlineSubStatusCode: null,
    extensionStatus: null,
    extensionStatusCode: null,
    extensionSubStatus: null,
    extensionSubStatusCode: null,
    claimOfficeNumber: null,
    claimOfficeId: '733',
    caseId: '471538',
    claimProcessDate: '10/15/2018',
    claimEffectiveDate: '10/15/2018',
    reason: 'N/A',
    physicianStatementDate: null,
    employerStatementDate: null,
    employeeStatementDate: null,
    estimatedSurgeryDate: null,
    estimatedDeliveryDate: null,
    lastDayWork: null,
    asmIndicator: null,
    confirmedLastDayWorkDate: null,
    confirmedSurgeryDate: null,
    confirmedDeliveryDate: null,
    encIndicator: false,
    encSurgeryIndicator: false,
    fundingMethod: 'N/A',
    coveragePlanNumber: null,
    taskOpen: false,
    missingInfo: null,
    claimProgress: null,
    extensionRequestDate: null,
    babyDeathIndicator: null,
    recentClaimIndicator: true
  },
  //standalone with closed status
  {
    leaveId: '100033',
    leaveStartDate: '10/06/2018',
    leaveEndDate: '10/07/2018',
    leaveStatus: 'Closed',
    leaveReason: `Active Duty Training`,
    caseId: '456789',
    personId: '123456',
    reedLeaveId: null,
    firstName: 'Mary'
  },
  //Standalone 10/05/2018
  {
    leaveId: '100003',
    leaveStartDate: '10/01/2018',
    leaveEndDate: '10/03/2018',
    leaveStatus: 'Open',
    leaveReason: `Active Duty Training`,
    caseId: '456789',
    personId: '123456',
    reedLeaveId: null,
    firstName: 'Mary'
  },
  //standalone with cancelled status
  {
    leaveId: '100023',
    leaveStartDate: '09/25/2018',
    leaveEndDate: '09/26/2018',
    leaveStatus: 'Cancelled',
    leaveReason: `Active Duty Training`,
    caseId: '456789',
    personId: '123456',
    reedLeaveId: null,
    firstName: 'Mary'
  },
  //LIFE 09/15/2018 No conc leave
  {
    claimEventId: '100022',
    sourceSystemId: 'DCS',
    concurrentLeaveIDs: [],
    claimType: 'Premium Waiver - Life Voluntary',
    claimSubType: 'Premium Waiver (Voluntary Life)',
    coverageType: 'VOL',
    coverageCategory: 'LIFE',
    disabilityDate: '09/15/2018',
    effectiveDate: '09/15/2018',
    authorizedEndDate: null,
    claimStatus: 'Approved',
    claimStatusCode: '02',
    claimOnlineStatus: null,
    claimOnlineStatusCode: null,
    claimOnlineSubStatus: null,
    claimOnlineSubStatusCode: null,
    extensionStatus: null,
    extensionStatusCode: null,
    extensionSubStatus: null,
    extensionSubStatusCode: null,
    claimOfficeNumber: null,
    claimOfficeId: '5',
    caseId: '764185',
    claimProcessDate: '09/15/2018',
    claimEffectiveDate: '09/15/2018',
    reason: 'N/A',
    physicianStatementDate: '09/15/2018',
    employerStatementDate: '09/15/2018',
    employeeStatementDate: '09/15/2018',
    estimatedSurgeryDate: null,
    estimatedDeliveryDate: null,
    lastDayWork: '09/15/2018',
    asmIndicator: null,
    confirmedLastDayWorkDate: null,
    confirmedSurgeryDate: null,
    confirmedDeliveryDate: null,
    encIndicator: false,
    encSurgeryIndicator: false,
    fundingMethod: 'FI',
    coveragePlanNumber: '2',
    taskOpen: false,
    missingInfo: null,
    claimProgress: null,
    extensionRequestDate: null,
    babyDeathIndicator: null,
    recentClaimIndicator: false
  },
  [
    //LTD 09/12/2018 No conc leave
    {
      claimEventId: '100018',
      sourceSystemId: 'DCS',
      concurrentLeaveIDs: [],
      claimType: 'Long Term Disability',
      claimSubType: 'Long Term Disability',
      coverageType: 'N/A',
      coverageCategory: 'LTD',
      disabilityDate: '09/12/2018',
      effectiveDate: null,
      authorizedEndDate: null,
      claimStatus: 'First Notice',
      claimStatusCode: '08',
      claimOnlineStatus: null,
      claimOnlineStatusCode: null,
      claimOnlineSubStatus: null,
      claimOnlineSubStatusCode: null,
      extensionStatus: null,
      extensionStatusCode: null,
      extensionSubStatus: null,
      extensionSubStatusCode: null,
      claimOfficeNumber: null,
      claimOfficeId: '733',
      caseId: '471538',
      claimProcessDate: '11/28/2018',
      claimEffectiveDate: '11/28/2018',
      reason: 'N/A',
      physicianStatementDate: null,
      employerStatementDate: null,
      employeeStatementDate: null,
      estimatedSurgeryDate: null,
      estimatedDeliveryDate: null,
      lastDayWork: null,
      asmIndicator: null,
      confirmedLastDayWorkDate: null,
      confirmedSurgeryDate: null,
      confirmedDeliveryDate: null,
      encIndicator: false,
      encSurgeryIndicator: false,
      fundingMethod: 'N/A',
      coveragePlanNumber: null,
      taskOpen: false,
      missingInfo: null,
      claimProgress: null,
      extensionRequestDate: null,
      babyDeathIndicator: null,
      recentClaimIndicator: true
    },
    //LIFE 09/15/2018 No conc leave
    {
      claimEventId: '100015',
      sourceSystemId: 'DCS',
      concurrentLeaveIDs: [],
      claimType: 'Premium Waiver - Life Voluntary',
      claimSubType: 'Premium Waiver (Voluntary Life)',
      coverageType: 'VOL',
      coverageCategory: 'LIFE',
      disabilityDate: '09/15/2018',
      effectiveDate: '09/15/2018',
      authorizedEndDate: null,
      claimStatus: 'Approved',
      claimStatusCode: '02',
      claimOnlineStatus: null,
      claimOnlineStatusCode: null,
      claimOnlineSubStatus: null,
      claimOnlineSubStatusCode: null,
      extensionStatus: null,
      extensionStatusCode: null,
      extensionSubStatus: null,
      extensionSubStatusCode: null,
      claimOfficeNumber: null,
      claimOfficeId: '5',
      caseId: '764185',
      claimProcessDate: '09/15/2018',
      claimEffectiveDate: '09/15/2018',
      reason: 'N/A',
      physicianStatementDate: '12/29/2014',
      employerStatementDate: '12/29/2014',
      employeeStatementDate: '08/06/2015',
      estimatedSurgeryDate: null,
      estimatedDeliveryDate: null,
      lastDayWork: '03/11/2015',
      asmIndicator: null,
      confirmedLastDayWorkDate: null,
      confirmedSurgeryDate: null,
      confirmedDeliveryDate: null,
      encIndicator: false,
      encSurgeryIndicator: false,
      fundingMethod: 'FI',
      coveragePlanNumber: '2',
      taskOpen: false,
      missingInfo: null,
      claimProgress: null,
      extensionRequestDate: null,
      babyDeathIndicator: null,
      recentClaimIndicator: false
    },
    //WD SUPP 09/14/2018 No conc leave
    {
      claimEventId: '100016',
      sourceSystemId: 'DCS',
      concurrentLeaveIDs: [],
      claimType: 'Short Term Disability- Supplemental Statutory',
      claimSubType: 'Supplemental State Surgery',
      coverageType: 'SUPP',
      coverageCategory: 'WD',
      disabilityDate: '09/14/2018',
      effectiveDate: '09/14/2018',
      authorizedEndDate: '09/14/2018',
      claimStatus: 'Terminate',
      claimStatusCode: '04',
      claimOnlineStatus: 'Claim Closed',
      claimOnlineStatusCode: '05',
      claimOnlineSubStatus: 'Claim Closed',
      claimOnlineSubStatusCode: '08',
      extensionStatus: null,
      extensionStatusCode: null,
      extensionSubStatus: 'Missing Info',
      extensionSubStatusCode: null,
      claimOfficeNumber: null,
      claimOfficeId: '16',
      caseId: '978301',
      claimProcessDate: '10/10/2016',
      claimEffectiveDate: '05/27/2016',
      reason: 'RELEASED TO RETURN TO WORK',
      physicianStatementDate: '05/02/2016',
      employerStatementDate: '05/04/2016',
      employeeStatementDate: '04/25/2016',
      estimatedSurgeryDate: '04/27/2016',
      estimatedDeliveryDate: null,
      lastDayWork: '04/26/2016',
      asmIndicator: 'S',
      confirmedLastDayWorkDate: '04/26/2016',
      confirmedSurgeryDate: '04/27/2016',
      confirmedDeliveryDate: null,
      encIndicator: true,
      encSurgeryIndicator: false,
      fundingMethod: 'FI',
      coveragePlanNumber: '1',
      taskOpen: true,
      missingInfo: null,
      claimProgress: 'Claim Closed',
      extensionRequestDate: '01/01/2019',
      babyDeathIndicator: null,
      recentClaimIndicator: false
    },
    //WD SUPP 09/13/2018 No conc leave
    {
      claimEventId: '100017',
      sourceSystemId: 'DCS',
      concurrentLeaveIDs: [],
      claimType: 'Short Term Disability- Supplemental Statutory',
      claimSubType: 'Supplemental State Surgery',
      coverageType: 'SUPP',
      coverageCategory: 'WD',
      disabilityDate: '09/13/2018',
      effectiveDate: '09/13/2018',
      authorizedEndDate: '09/13/2018',
      claimStatus: 'Terminate',
      claimStatusCode: '04',
      claimOnlineStatus: 'Claim Closed',
      claimOnlineStatusCode: '05',
      claimOnlineSubStatus: 'Claim Closed',
      claimOnlineSubStatusCode: '08',
      extensionStatus: null,
      extensionStatusCode: null,
      extensionSubStatus: 'Missing Info',
      extensionSubStatusCode: null,
      claimOfficeNumber: null,
      claimOfficeId: '16',
      caseId: '978301',
      claimProcessDate: '10/10/2016',
      claimEffectiveDate: '05/27/2016',
      reason: 'RELEASED TO RETURN TO WORK',
      physicianStatementDate: '05/02/2016',
      employerStatementDate: '05/04/2016',
      employeeStatementDate: '04/25/2016',
      estimatedSurgeryDate: '04/27/2016',
      estimatedDeliveryDate: null,
      lastDayWork: '04/26/2016',
      asmIndicator: 'S',
      confirmedLastDayWorkDate: '04/26/2016',
      confirmedSurgeryDate: '04/27/2016',
      confirmedDeliveryDate: null,
      encIndicator: true,
      encSurgeryIndicator: false,
      fundingMethod: 'FI',
      coveragePlanNumber: '1',
      taskOpen: true,
      missingInfo: null,
      claimProgress: 'Claim Closed',
      extensionRequestDate: '01/01/2019',
      babyDeathIndicator: null,
      recentClaimIndicator: false
    },
  ],
  //Standalone 09/01/2018
  {
    leaveId: '100005',
    leaveStartDate: '09/01/2018',
    leaveEndDate: '09/24/2018',
    leaveStatus: 'Open',
    leaveReason: `Day-Care Activity`,
    caseId: '456789',
    personId: '123456',
    reedLeaveId: null,
    firstName: 'Mary'
  },
  //Standalone 09/01/2018
  {
    leaveId: '100006',
    leaveStartDate: '09/01/2018',
    leaveEndDate: '09/31/2018',
    leaveStatus: 'Open',
    leaveReason: `Day-Care Activity`,
    caseId: '456789',
    personId: '123456',
    reedLeaveId: null,
    firstName: 'Mary'
  },
];

export const SORTED_BENEFITS_HISTORY = [
  //GROUP 1:
  [
    //LTD 11/29/2018 No conc leave
    {
      claimEventId: '100012',
      sourceSystemId: 'AA',
      concurrentLeaveIDs: [],
      claimType: 'Long Term Disability',
      claimSubType: 'Long Term Disability',
      coverageType: 'N/A',
      coverageCategory: 'LTD',
      disabilityDate: '11/29/2018',
      effectiveDate: null,
      authorizedEndDate: null,
      claimStatus: 'First Notice',
      claimStatusCode: '08',
      claimOnlineStatus: null,
      claimOnlineStatusCode: null,
      claimOnlineSubStatus: null,
      claimOnlineSubStatusCode: null,
      extensionStatus: null,
      extensionStatusCode: null,
      extensionSubStatus: null,
      extensionSubStatusCode: null,
      claimOfficeNumber: null,
      claimOfficeId: '733',
      caseId: '471538',
      claimProcessDate: '11/28/2018',
      claimEffectiveDate: '11/28/2018',
      reason: 'N/A',
      physicianStatementDate: null,
      employerStatementDate: null,
      employeeStatementDate: null,
      estimatedSurgeryDate: null,
      estimatedDeliveryDate: null,
      lastDayWork: null,
      asmIndicator: null,
      confirmedLastDayWorkDate: null,
      confirmedSurgeryDate: null,
      confirmedDeliveryDate: null,
      encIndicator: false,
      encSurgeryIndicator: false,
      fundingMethod: 'N/A',
      coveragePlanNumber: null,
      taskOpen: false,
      missingInfo: null,
      claimProgress: null,
      extensionRequestDate: null,
      babyDeathIndicator: null,
      recentClaimIndicator: true
    },
    //LIFE 11/27/2018 conc leave 1000004
    {
      claimEventId: '100013',
      sourceSystemId: 'DCS',
      concurrentLeaveIDs: [],
      claimType: 'Premium Waiver - Life Voluntary',
      claimSubType: 'Premium Waiver (Voluntary Life)',
      coverageType: 'VOL',
      coverageCategory: 'LIFE',
      disabilityDate: '11/27/2018',
      effectiveDate: '11/27/2018',
      authorizedEndDate: null,
      claimStatus: 'Approved',
      claimStatusCode: '02',
      claimOnlineStatus: null,
      claimOnlineStatusCode: null,
      claimOnlineSubStatus: null,
      claimOnlineSubStatusCode: null,
      extensionStatus: null,
      extensionStatusCode: null,
      extensionSubStatus: null,
      extensionSubStatusCode: null,
      claimOfficeNumber: null,
      claimOfficeId: '5',
      caseId: '764185',
      claimProcessDate: '11/27/2018',
      claimEffectiveDate: '11/27/2018',
      reason: 'N/A',
      physicianStatementDate: '12/29/2014',
      employerStatementDate: '12/29/2014',
      employeeStatementDate: '08/06/2015',
      estimatedSurgeryDate: null,
      estimatedDeliveryDate: null,
      lastDayWork: '03/11/2015',
      asmIndicator: null,
      confirmedLastDayWorkDate: null,
      confirmedSurgeryDate: null,
      confirmedDeliveryDate: null,
      encIndicator: false,
      encSurgeryIndicator: false,
      fundingMethod: 'FI',
      coveragePlanNumber: '2',
      taskOpen: false,
      missingInfo: null,
      claimProgress: null,
      extensionRequestDate: null,
      babyDeathIndicator: null,
      recentClaimIndicator: false
    },
    //WD NST 11/28/2018 No conc leave
    {
      claimEventId: '100014',
      sourceSystemId: 'AA',
      leaveReason: 'Bond with Child',
      leaveFirstName: 'James',
      leaveLastName: 'Wetch',
      concurrentLeaveIDs: [],
      claimType: 'Short Term Disability',
      claimSubType: 'Short Term Disability',
      coverageType: 'NST',
      coverageCategory: 'PFL',
      disabilityDate: '11/28/2018',
      effectiveDate: '11/28/2018',
      authorizedEndDate: null,
      claimStatus: 'Pending',
      claimStatusCode: '01',
      claimOnlineStatus: 'Awaiting Disability',
      claimOnlineStatusCode: '02',
      claimOnlineSubStatus: 'Awaiting Disability',
      claimOnlineSubStatusCode: '02',
      extensionStatus: null,
      extensionStatusCode: null,
      extensionSubStatus: null,
      extensionSubStatusCode: null,
      claimOfficeNumber: null,
      claimOfficeId: '733',
      caseId: '471538',
      claimProcessDate: '11/28/2018',
      claimEffectiveDate: '11/28/2018',
      reason: 'N/A',
      physicianStatementDate: null,
      employerStatementDate: null,
      employeeStatementDate: '11/28/2018',
      estimatedSurgeryDate: null,
      estimatedDeliveryDate: null,
      lastDayWork: '06/23/2018',
      asmIndicator: 'A',
      confirmedLastDayWorkDate: null,
      confirmedSurgeryDate: null,
      confirmedDeliveryDate: null,
      encIndicator: false,
      encSurgeryIndicator: false,
      fundingMethod: 'FI',
      coveragePlanNumber: '1',
      taskOpen: false,
      missingInfo: null,
      claimProgress: 'Awaiting Disability',
      extensionRequestDate: null,
      babyDeathIndicator: null,
      recentClaimIndicator: true
    },
    //WD SUPP 11/30/2018 conc leave 1000001
    {
      claimEventId: '100015',
      sourceSystemId: 'AA',
      leaveReason: 'Bond with Child',
      leaveFirstName: 'James',
      leaveLastName: 'Wetch',
      concurrentLeaveIDs: ['100001'],
      claimType: 'Short Term Disability- Supplemental Statutory',
      claimSubType: 'Supplemental State Surgery',
      coverageType: 'SUPP',
      coverageCategory: 'WD',
      disabilityDate: '11/30/2018',
      effectiveDate: '11/30/2018',
      authorizedEndDate: '12/31/2018',
      claimStatus: 'Terminate',
      claimStatusCode: '04',
      claimOnlineStatus: 'Claim Closed',
      claimOnlineStatusCode: '05',
      claimOnlineSubStatus: 'Claim Closed',
      claimOnlineSubStatusCode: '08',
      extensionStatus: null,
      extensionStatusCode: null,
      extensionSubStatus: 'Missing Info',
      extensionSubStatusCode: null,
      claimOfficeNumber: null,
      claimOfficeId: '16',
      caseId: '978301',
      claimProcessDate: '10/10/2016',
      claimEffectiveDate: '05/27/2016',
      reason: 'RELEASED TO RETURN TO WORK',
      physicianStatementDate: '05/02/2016',
      employerStatementDate: '05/04/2016',
      employeeStatementDate: '04/25/2016',
      estimatedSurgeryDate: '04/27/2016',
      estimatedDeliveryDate: null,
      lastDayWork: '04/26/2016',
      asmIndicator: 'S',
      confirmedLastDayWorkDate: '04/26/2016',
      confirmedSurgeryDate: '04/27/2016',
      confirmedDeliveryDate: null,
      encIndicator: true,
      encSurgeryIndicator: false,
      fundingMethod: 'FI',
      coveragePlanNumber: '1',
      taskOpen: true,
      missingInfo: null,
      claimProgress: 'Claim Closed',
      extensionRequestDate: '01/01/2019',
      babyDeathIndicator: null,
      recentClaimIndicator: false
    },
    //Concurrent to WD SUPP 11/30/2018
    {
      leaveId: '100001',
      leaveStartDate: '09/01/2018',
      leaveEndDate: '09/31/2018',
      leaveStatus: 'Open',
      leaveReason: 'Employee\'s Own Illness',
      caseId: '200000',
      personId: '300000',
      reedLeaveId: null,
      leaveProcessDate: '09/02/2018',
      leaveType: 'Standalone',
      leaveSubType: 'Continuous',
      leaveEditDate: '09/28/2018',
      insuredFirstName: 'James',
      insuredLastName: 'Thomas',
      leaveFirstName: 'Mary',
      leaveLastName: 'Thomas',
      leavePlanChangedDetected: true
    },
    //Concurrent to LIFE 11/27/2018
    {
      leaveId: '100004',
      leaveStartDate: '10/01/2018',
      leaveEndDate: '10/02/2018',
      leaveStatus: 'Closed',
      leaveReason: `Disability`,
      caseId: '456789',
      personId: '123456',
      reedLeaveId: null,
      firstName: 'Mary'
    },
  ],
  [
    //WD SUPP 11/15/2018 conc leave 100002
    {
      claimEventId: '100016',
      sourceSystemId: 'DCS',
      concurrentLeaveIDs: ['100002'],
      claimType: 'Short Term Disability- Supplemental Statutory',
      claimSubType: 'Supplemental State Surgery',
      coverageType: 'SUPP',
      coverageCategory: 'WD',
      disabilityDate: '11/15/2018',
      effectiveDate: '11/15/2018',
      authorizedEndDate: '11/25/2019',
      claimStatus: 'Terminate',
      claimStatusCode: '04',
      claimOnlineStatus: 'Claim Closed',
      claimOnlineStatusCode: '05',
      claimOnlineSubStatus: 'Claim Closed',
      claimOnlineSubStatusCode: '08',
      extensionStatus: null,
      extensionStatusCode: null,
      extensionSubStatus: 'Missing Info',
      extensionSubStatusCode: null,
      claimOfficeNumber: null,
      claimOfficeId: '16',
      caseId: '978301',
      claimProcessDate: '11/15/2018',
      claimEffectiveDate: '11/15/2018',
      reason: 'RELEASED TO RETURN TO WORK',
      physicianStatementDate: '11/15/2018',
      employerStatementDate: '11/15/2018',
      employeeStatementDate: '11/15/2018',
      estimatedSurgeryDate: '11/15/2018',
      estimatedDeliveryDate: null,
      lastDayWork: '11/15/2018',
      asmIndicator: 'S',
      confirmedLastDayWorkDate: '11/15/2018',
      confirmedSurgeryDate: '11/15/2018',
      confirmedDeliveryDate: null,
      encIndicator: true,
      encSurgeryIndicator: false,
      fundingMethod: 'FI',
      coveragePlanNumber: '1',
      taskOpen: true,
      missingInfo: null,
      claimProgress: 'Claim Closed',
      extensionRequestDate: '01/01/2019',
      babyDeathIndicator: null,
      recentClaimIndicator: false
    },
    //Concurrent to WD SUPP 11/15/2018
    {
      leaveId: '100002',
      leaveStartDate: '11/01/2018',
      leaveEndDate: '11/05/2018',
      leaveStatus: 'Closed',
      leaveReason: `Employee's Own Illness`,
      caseId: '456789',
      personId: '123456',
      reedLeaveId: null,
      firstName: 'Mary'
    },
  ],
  //LTD 10/15/2018 No conc leave
  {
    claimEventId: '100017',
    sourceSystemId: 'DCS',
    concurrentLeaveIDs: [],
    claimType: 'Long Term Disability',
    claimSubType: 'Long Term Disability',
    coverageType: 'N/A',
    coverageCategory: 'LTD',
    disabilityDate: '10/15/2018',
    effectiveDate: null,
    authorizedEndDate: null,
    claimStatus: 'First Notice',
    claimStatusCode: '08',
    claimOnlineStatus: null,
    claimOnlineStatusCode: null,
    claimOnlineSubStatus: null,
    claimOnlineSubStatusCode: null,
    extensionStatus: null,
    extensionStatusCode: null,
    extensionSubStatus: null,
    extensionSubStatusCode: null,
    claimOfficeNumber: null,
    claimOfficeId: '733',
    caseId: '471538',
    claimProcessDate: '10/15/2018',
    claimEffectiveDate: '10/15/2018',
    reason: 'N/A',
    physicianStatementDate: null,
    employerStatementDate: null,
    employeeStatementDate: null,
    estimatedSurgeryDate: null,
    estimatedDeliveryDate: null,
    lastDayWork: null,
    asmIndicator: null,
    confirmedLastDayWorkDate: null,
    confirmedSurgeryDate: null,
    confirmedDeliveryDate: null,
    encIndicator: false,
    encSurgeryIndicator: false,
    fundingMethod: 'N/A',
    coveragePlanNumber: null,
    taskOpen: false,
    missingInfo: null,
    claimProgress: null,
    extensionRequestDate: null,
    babyDeathIndicator: null,
    recentClaimIndicator: true
  },
  {
    claimEventId: '100111',
    sourceSystemId: 'AA',
    leaveReason: 'Bond with Child',
    leaveFirstName: 'James',
    leaveLastName: 'Welch',
    concurrentLeaveIDs: [],
    claimType: 'Paid Family Leave',
    claimSubType: 'NY Paid Family Leave',
    coverageType: 'NST',
    coverageCategory: 'PFL',
    disabilityDate: '11/28/2018',
    effectiveDate: '11/28/2018',
    authorizedEndDate: '11/28/2018',
    claimStatus: 'Pending',
    claimStatusCode: '01',
    claimOnlineStatus: 'Awaiting Disability',
    claimOnlineStatusCode: '02',
    claimOnlineSubStatus: 'Awaiting Disability',
    claimOnlineSubStatusCode: '02',
    extensionStatus: null,
    extensionStatusCode: null,
    extensionSubStatus: null,
    extensionSubStatusCode: null,
    claimOfficeNumber: null,
    claimOfficeId: '733',
    caseId: '471538',
    claimProcessDate: '11/28/2018',
    claimEffectiveDate: '11/28/2018',
    reason: 'N/A',
    physicianStatementDate: null,
    employerStatementDate: null,
    employeeStatementDate: '11/28/2018',
    estimatedSurgeryDate: null,
    estimatedDeliveryDate: null,
    lastDayWork: '06/23/2018',
    asmIndicator: 'A',
    confirmedLastDayWorkDate: null,
    confirmedSurgeryDate: null,
    confirmedDeliveryDate: null,
    encIndicator: false,
    encSurgeryIndicator: false,
    fundingMethod: 'FI',
    coveragePlanNumber: '1',
    taskOpen: false,
    missingInfo: null,
    claimProgress: 'Awaiting Disability',
    extensionRequestDate: null,
    babyDeathIndicator: null,
    recentClaimIndicator: true,
    relationship: 'Son'
  },
  //standalone with closed status
  {
    leaveId: '100033',
    leaveStartDate: '10/06/2018',
    leaveEndDate: null,
    leaveStatus: 'Closed',
    leaveReason: `Active Duty Training`,
    caseId: '456789',
    personId: '123456',
    reedLeaveId: null,
    firstName: 'Mary'
  },
  //Standalone 10/05/2018
  {
    leaveId: '100003',
    leaveStartDate: '10/03/2018',
    leaveEndDate: '10/05/2018',
    leaveStatus: 'Cancelled',
    leaveReason: `Active Duty Training`,
    caseId: '456789',
    personId: '123456',
    reedLeaveId: null,
    firstName: 'Mary'
  },
  //standalone with cancelled status
  {
    leaveId: '200013',
    leaveStartDate: '10/01/2018',
    leaveEndDate: '10/02/2018',
    leaveStatus: 'Cancelled',
    leaveReason: `Active Duty Training`,
    caseId: '456789',
    personId: '123456',
    reedLeaveId: null,
    firstName: 'Mary'
  },
  {
    leaveId: '100001',
    leaveStartDate: '12/01/2018',
    leaveEndDate: '12/31/2018',
    leaveStatus: 'Closed',
    leaveReason: `Employee's Own Illness`,
    caseId: '456789',
    personId: '123456',
    reedLeaveId: null,
    firstName: 'Mary'
  },
  //LIFE 09/15/2018 No conc leave
  {
    claimEventId: '100018',
    sourceSystemId: 'DCS',
    concurrentLeaveIDs: [],
    claimType: 'Premium Waiver - Life Voluntary',
    claimSubType: 'Premium Waiver (Voluntary Life)',
    coverageType: 'VOL',
    coverageCategory: 'LIFE',
    disabilityDate: '09/15/2018',
    effectiveDate: '09/15/2018',
    authorizedEndDate: null,
    claimStatus: 'Approved',
    claimStatusCode: '02',
    claimOnlineStatus: null,
    claimOnlineStatusCode: null,
    claimOnlineSubStatus: null,
    claimOnlineSubStatusCode: null,
    extensionStatus: null,
    extensionStatusCode: null,
    extensionSubStatus: null,
    extensionSubStatusCode: null,
    claimOfficeNumber: null,
    claimOfficeId: '5',
    caseId: '764185',
    claimProcessDate: '09/15/2018',
    claimEffectiveDate: '09/15/2018',
    reason: 'N/A',
    physicianStatementDate: '09/15/2018',
    employerStatementDate: '09/15/2018',
    employeeStatementDate: '09/15/2018',
    estimatedSurgeryDate: null,
    estimatedDeliveryDate: null,
    lastDayWork: '09/15/2018',
    asmIndicator: null,
    confirmedLastDayWorkDate: null,
    confirmedSurgeryDate: null,
    confirmedDeliveryDate: null,
    encIndicator: false,
    encSurgeryIndicator: false,
    fundingMethod: 'FI',
    coveragePlanNumber: '2',
    taskOpen: false,
    missingInfo: null,
    claimProgress: null,
    extensionRequestDate: null,
    babyDeathIndicator: null,
    recentClaimIndicator: false
  },
  [
    //LTD 09/12/2018 No conc leave
    {
      claimEventId: '100019',
      sourceSystemId: 'DCS',
      concurrentLeaveIDs: [],
      claimType: 'Long Term Disability',
      claimSubType: 'Long Term Disability',
      coverageType: 'N/A',
      coverageCategory: 'LTD',
      disabilityDate: '09/12/2018',
      effectiveDate: null,
      authorizedEndDate: null,
      claimStatus: 'First Notice',
      claimStatusCode: '08',
      claimOnlineStatus: null,
      claimOnlineStatusCode: null,
      claimOnlineSubStatus: null,
      claimOnlineSubStatusCode: null,
      extensionStatus: null,
      extensionStatusCode: null,
      extensionSubStatus: null,
      extensionSubStatusCode: null,
      claimOfficeNumber: null,
      claimOfficeId: '733',
      caseId: '471538',
      claimProcessDate: '11/28/2018',
      claimEffectiveDate: '11/28/2018',
      reason: 'N/A',
      physicianStatementDate: null,
      employerStatementDate: null,
      employeeStatementDate: null,
      estimatedSurgeryDate: null,
      estimatedDeliveryDate: null,
      lastDayWork: null,
      asmIndicator: null,
      confirmedLastDayWorkDate: null,
      confirmedSurgeryDate: null,
      confirmedDeliveryDate: null,
      encIndicator: false,
      encSurgeryIndicator: false,
      fundingMethod: 'N/A',
      coveragePlanNumber: null,
      taskOpen: false,
      missingInfo: null,
      claimProgress: null,
      extensionRequestDate: null,
      babyDeathIndicator: null,
      recentClaimIndicator: true
    },
    //LIFE 09/15/2018 No conc leave
    {
      claimEventId: '100020',
      sourceSystemId: 'DCS',
      concurrentLeaveIDs: [],
      claimType: 'Premium Waiver - Life Voluntary',
      claimSubType: 'Premium Waiver (Voluntary Life)',
      coverageType: 'VOL',
      coverageCategory: 'LIFE',
      disabilityDate: '09/15/2018',
      effectiveDate: '09/15/2018',
      authorizedEndDate: null,
      claimStatus: 'Approved',
      claimStatusCode: '02',
      claimOnlineStatus: null,
      claimOnlineStatusCode: null,
      claimOnlineSubStatus: null,
      claimOnlineSubStatusCode: null,
      extensionStatus: null,
      extensionStatusCode: null,
      extensionSubStatus: null,
      extensionSubStatusCode: null,
      claimOfficeNumber: null,
      claimOfficeId: '5',
      caseId: '764185',
      claimProcessDate: '09/15/2018',
      claimEffectiveDate: '09/15/2018',
      reason: 'N/A',
      physicianStatementDate: '12/29/2014',
      employerStatementDate: '12/29/2014',
      employeeStatementDate: '08/06/2015',
      estimatedSurgeryDate: null,
      estimatedDeliveryDate: null,
      lastDayWork: '03/11/2015',
      asmIndicator: null,
      confirmedLastDayWorkDate: null,
      confirmedSurgeryDate: null,
      confirmedDeliveryDate: null,
      encIndicator: false,
      encSurgeryIndicator: false,
      fundingMethod: 'FI',
      coveragePlanNumber: '2',
      taskOpen: false,
      missingInfo: null,
      claimProgress: null,
      extensionRequestDate: null,
      babyDeathIndicator: null,
      recentClaimIndicator: false
    },
    //WD SUPP 09/14/2018 No conc leave
    {
      claimEventId: '100021',
      sourceSystemId: 'DCS',
      concurrentLeaveIDs: [],
      claimType: 'Short Term Disability- Supplemental Statutory',
      claimSubType: 'Supplemental State Surgery',
      coverageType: 'SUPP',
      coverageCategory: 'WD',
      disabilityDate: '09/14/2018',
      effectiveDate: '09/14/2018',
      authorizedEndDate: '09/14/2018',
      claimStatus: 'Terminate',
      claimStatusCode: '04',
      claimOnlineStatus: 'Claim Closed',
      claimOnlineStatusCode: '05',
      claimOnlineSubStatus: 'Claim Closed',
      claimOnlineSubStatusCode: '08',
      extensionStatus: null,
      extensionStatusCode: null,
      extensionSubStatus: 'Missing Info',
      extensionSubStatusCode: null,
      claimOfficeNumber: null,
      claimOfficeId: '16',
      caseId: '978301',
      claimProcessDate: '10/10/2016',
      claimEffectiveDate: '05/27/2016',
      reason: 'RELEASED TO RETURN TO WORK',
      physicianStatementDate: '05/02/2016',
      employerStatementDate: '05/04/2016',
      employeeStatementDate: '04/25/2016',
      estimatedSurgeryDate: '04/27/2016',
      estimatedDeliveryDate: null,
      lastDayWork: '04/26/2016',
      asmIndicator: 'S',
      confirmedLastDayWorkDate: '04/26/2016',
      confirmedSurgeryDate: '04/27/2016',
      confirmedDeliveryDate: null,
      encIndicator: true,
      encSurgeryIndicator: false,
      fundingMethod: 'FI',
      coveragePlanNumber: '1',
      taskOpen: true,
      missingInfo: null,
      claimProgress: 'Claim Closed',
      extensionRequestDate: '01/01/2019',
      babyDeathIndicator: null,
      recentClaimIndicator: false
    },
    //WD SUPP 09/13/2018 No conc leave
    {
      claimEventId: '100022',
      sourceSystemId: 'DCS',
      concurrentLeaveIDs: [],
      claimType: 'Short Term Disability- Supplemental Statutory',
      claimSubType: 'Supplemental State Surgery',
      coverageType: 'SUPP',
      coverageCategory: 'WD',
      disabilityDate: '09/13/2018',
      effectiveDate: '09/13/2018',
      authorizedEndDate: '09/13/2018',
      claimStatus: 'Terminate',
      claimStatusCode: '04',
      claimOnlineStatus: 'Claim Closed',
      claimOnlineStatusCode: '05',
      claimOnlineSubStatus: 'Claim Closed',
      claimOnlineSubStatusCode: '08',
      extensionStatus: null,
      extensionStatusCode: null,
      extensionSubStatus: 'Missing Info',
      extensionSubStatusCode: null,
      claimOfficeNumber: null,
      claimOfficeId: '16',
      caseId: '978301',
      claimProcessDate: '10/10/2016',
      claimEffectiveDate: '05/27/2016',
      reason: 'RELEASED TO RETURN TO WORK',
      physicianStatementDate: '05/02/2016',
      employerStatementDate: '05/04/2016',
      employeeStatementDate: '04/25/2016',
      estimatedSurgeryDate: '04/27/2016',
      estimatedDeliveryDate: null,
      lastDayWork: '04/26/2016',
      asmIndicator: 'S',
      confirmedLastDayWorkDate: '04/26/2016',
      confirmedSurgeryDate: '04/27/2016',
      confirmedDeliveryDate: null,
      encIndicator: true,
      encSurgeryIndicator: false,
      fundingMethod: 'FI',
      coveragePlanNumber: '1',
      taskOpen: true,
      missingInfo: null,
      claimProgress: 'Claim Closed',
      extensionRequestDate: '01/01/2019',
      babyDeathIndicator: null,
      recentClaimIndicator: false
    },
  ],
  //Standalone 09/01/2018
  {
    leaveId: '100051',
    leaveStartDate: '09/01/2018',
    leaveEndDate: '09/31/2018',
    leaveStatus: 'Cancelled',
    leaveReason: `Day-Care Activity`,
    caseId: '456789',
    personId: '123456',
    reedLeaveId: null,
    firstName: null
  },
  //Standalone 09/01/2018
  {
    leaveId: '100006',
    leaveStartDate: '09/01/2018',
    leaveEndDate: '09/31/2018',
    leaveStatus: 'Closed',
    leaveReason: `Day-Care Activity`,
    caseId: '456789',
    personId: '123456',
    reedLeaveId: null,
    firstName: 'Mary'
  },
  //standalone with cancellled status
  {
    leaveId: '200033',
    leaveStartDate: '10/06/2018',
    leaveEndDate: '10/06/2018',
    leaveStatus: 'Cancelled',
    leaveReason: `Active Duty Training`,
    caseId: '456789',
    personId: '123456',
    reedLeaveId: null,
    firstName: 'Mary'
  },
  //standalone with closed status
  {
    leaveId: '300033',
    leaveStartDate: '10/04/2018',
    leaveEndDate: '10/05/2018',
    leaveStatus: 'Closed',
    leaveReason: `Active Duty Training`,
    caseId: '456789',
    personId: '123456',
    reedLeaveId: null,
    firstName: 'Mary'
  }
];

export const MOCK_GB_LEAVE_SCHEDULE_DETAIL = [
  {
    startDate: 'Dec 30, 2018',
    endDate: '1/31/2019',
    status: 'Approved',
    type: 'Continuous'
  },
  {
    startDate: 'Jan 03, 2019',
    endDate: 'Jan 20, 2019',
    status: 'Approved',
    type: 'Reduced Schedule'
  },
  {
    startDate: 'Jan 31, 2019',
    endDate: 'Feb 20, 2019',
    status: 'Pend',
    type: 'Reduced Schedule'
  },
  {
    startDate: 'Feb 20, 2019',
    endDate: 'Feb 25, 2019',
    status: 'Denied',
    type: 'Intermittent'
  },
  {
    startDate: 'Mar 20, 2019',
    endDate: 'Mar 25, 2019',
    status: 'Cancelled',
    type: 'Intermittent'
  },
  {
    startDate: 'Apr 20, 2019',
    endDate: 'Apr 25, 2019',
    status: 'Administrative Cancel',
    type: 'Intermittent'
  },
];

export const GB_MOCK_ORCHESTRATION = {
  errorList: null,
  userId: null,
  sessionIdentifier: null,
  claimOverview: {
    nonRelatedClaims: [
      {
        claimEventId: '111111',
        claimType: 'Short Term Disability',
        claimSubType: 'Maternity',
        coverageType: 'NST',
        coverageCategory: 'WD',
        disabilityDate: '03/10/2020',
        effectiveDate: null,
        authorizedEndDate: null,
        claimStatus: 'Pend',
        claimStatusCode: '0',
        claimOnlineStatus: 'Under Review by Claim Handler',
        claimOnlineStatusCode: '08',
        claimOnlineSubStatus: 'In Adjudication',
        claimOnlineSubStatusCode: '12',
        extensionStatus: null,
        extensionStatusCode: null,
        extensionSubStatus: null,
        extensionSubStatusCode: null,
        claimOfficeNumber: null,
        claimOfficeId: null,
        caseId: '1099157',
        claimProcessDate: '03/25/2020',
        claimEffectiveDate: null,
        reason: 'New Claim',
        physicianStatementDate: null,
        employerStatementDate: null,
        employeeStatementDate: null,
        estimatedSurgeryDate: null,
        estimatedDeliveryDate: '03/8/2021',
        lastDayWork: '03/09/2020',
        asmIndicator: 'M',
        confirmedLastDayWorkDate: null,
        confirmedSurgeryDate: null,
        confirmedDeliveryDate: null,
        encIndicator: false,
        encSurgeryIndicator: false,
        fundingMethod: null,
        coveragePlanNumber: null,
        taskOpen: false,
        missingInfo: [],
        claimProgress: 'Under Review',
        extensionRequestDate: null,
        babyDeathIndicator: null,
        recentClaimIndicator: false,
        semiMonthlyPaymentIndicator: null,
        concurrentLeaveIDs: [],
        sourceSystemId: 'AA',
        personId: '7052337',
        relatedClaims: [{
          relatedClaimList: [],
          hasRelatedClaims: false
        }],
        rtwStatusCode: '42001',
        oowReason: 'Pregnancy',
        relationship: null
      },
      {
        missingInfo: [
          {
            mInfoCode: '02',
            mInfoDesc: 'Employer',
            requiredByDate: '12/12/2019',
            mInfoReqInfo: [{
              mInfoReqInfoCd: '12',
              mInfoReqInfoDesc: '',
              requestDate: '4/9/2019',
              displayValue: 'Salary Continuation, PTO, or Sick Time Details',
              chatInd: false,
              callInd: false,
              uploadInd: false,
              detailInd: true,
              formType: 'generic',
              listPhysicain: [{
                physicianName: 'Dr.ABC',
                fromDataRange: '3/21/2019',
                toDataRange: ''
              }]
            },
              {
                mInfoReqInfoCd: '13',
                mInfoReqInfoDesc: '',
                requestDate: '4/9/2019',
                displayValue: 'Salary Continuation, PTO, or Sick Time Details',
                chatInd: false,
                callInd: false,
                uploadInd: false,
                detailInd: true,
                formType: 'generic',
                listPhysicain: [{
                  physicianName: 'Dr.nbh',
                  fromDataRange: '3/21/2019',
                  toDataRange: ''
                },
                  {
                    physicianName: 'Dr.ABC',
                    fromDataRange: '3/21/2019',
                    toDataRange: ''
                  }]
              }],
            gracePeriod: false
          },
          {
            mInfoCode: '01',
            mInfoDesc: 'Employee',
            requiredByDate: '12/12/2019',
            mInfoReqInfo: [
              {
                mInfoReqInfoCd: '25',
                mInfoReqInfoDesc: '',
                requestDate: '3/21/2019',
                displayValue: 'Authorization Form',
                chatInd: false,
                callInd: false ,
                uploadInd: true,
                detailInd: true,
                formType: 'generic',
                listPhysicain: [{
                  physicianName: 'Dr.XYZ',
                  fromDataRange: '3/21/2019',
                  toDataRange: ''
                },
                  {
                    physicianName: 'Dr.ABC',
                    fromDataRange: '3/21/2019',
                    toDataRange: '4/20/2019'
                  }]
              },
              {
                mInfoReqInfoCd: '04',
                mInfoReqInfoDesc: '',
                requestDate: '3/21/2019',
                displayValue: 'Gap Information',
                chatInd: false,
                callInd: true ,
                uploadInd: false,
                detailInd: true,
                formType: 'generic',
                listPhysicain: [{
                  physicianName: 'Dr.XYZ',
                  fromDataRange: '3/21/2019',
                  toDataRange: ''
                },
                  {
                    physicianName: 'Dr.ABC',
                    fromDataRange: '3/21/2019',
                    toDataRange: '4/20/2019'
                  }]
              },
              {
                mInfoReqInfoCd: '05',
                mInfoReqInfoDesc: '',
                requestDate: '3/21/2019',
                displayValue: 'Work and Educational History',
                chatInd: true,
                callInd: true ,
                uploadInd: false,
                detailInd: false,
                formType: 'generic',
                listPhysicain: [{
                  physicianName: 'Dr.XYZ',
                  fromDataRange: '3/21/2019',
                  toDataRange: ''
                },
                  {
                    physicianName: 'Dr.ABC',
                    fromDataRange: '3/21/2019',
                    toDataRange: '4/20/2019'
                  }]
              },
              {
                mInfoReqInfoCd: '03',
                mInfoReqInfoDesc: '',
                requestDate: '3/21/2019',
                displayValue: 'Workers Compensation Denial Letter',
                chatInd: false,
                callInd: false ,
                uploadInd: true,
                detailInd: false,
                formType: 'generic',
                listPhysicain: [{
                  physicianName: 'Dr.XYZ',
                  fromDataRange: '3/21/2019',
                  toDataRange: ''
                },
                  {
                    physicianName: 'Dr.ABC',
                    fromDataRange: '3/21/2019',
                    toDataRange: '4/20/2019'
                  }]
              }],
            gracePeriod: false
          },
          {
            mInfoCode: '03',
            mInfoDesc: 'Physician',
            requiredByDate: '12/12/2019',
            mInfoReqInfo: [
              {
                mInfoReqInfoCd: '08',
                mInfoReqInfoDesc: '',
                requestDate: '3/21/2019',
                displayValue: 'Cause of Disabling Condition',
                chatInd: false,
                callInd: false,
                uploadInd: true,
                detailInd: true,
                formType: 'generic',
                listPhysicain: [{
                  physicianName: 'Dr. A Smithson',
                  fromDataRange: '3/27/2019',
                  toDataRange: '7/16/2019'
                }]
              },
              {
                mInfoReqInfoCd: '07',
                mInfoReqInfoDesc: '',
                requestDate: '3/21/2019',
                displayValue: 'Cause of Disabling Condition',
                chatInd: false,
                callInd: true,
                uploadInd: false,
                detailInd: false,
                formType: 'generic',
                listPhysicain: [{
                  physicianName: 'Dr. D Hernandez',
                  fromDataRange: '3/26/2019',
                  toDataRange: null
                }]
              },
              {
                mInfoReqInfoCd: '07',
                mInfoReqInfoDesc: '',
                requestDate: '3/21/2019',
                displayValue: 'Cause of Disabling Condition',
                chatInd: false,
                callInd: false,
                uploadInd: false,
                detailInd: false,
                formType: 'generic',
                listPhysicain: [{
                  physicianName: 'Dr. D Hernandez',
                  fromDataRange: '3/26/2019',
                  toDataRange: null
                }]
              },
              {
                mInfoReqInfoCd: '05',
                mInfoReqInfoDesc: '',
                requestDate: '3/21/2019',
                displayValue: 'Doctor’s Note',
                chatInd: false,
                callInd: false,
                uploadInd: true,
                detailInd: false,
                formType: 'generic',
                listPhysicain: [{
                  physicianName: 'Dr. D Parnavi',
                  fromDataRange: '11/24/2018',
                  toDataRange: '2/09/2018'
                }]
              }
            ],
            gracePeriod: false
          }],
        claimEventId: '1234',
        claimType: 'Short Term Disability',
        claimSubType: 'Maternity',
        coverageType: 'NST',
        coverageCategory: 'WD',
        disabilityDate: '03/10/2020',
        effectiveDate: null,
        authorizedEndDate: null,
        claimStatus: 'Pend',
        claimStatusCode: '0',
        claimOnlineStatus: 'Under Review by Claim Handler',
        claimOnlineStatusCode: '08',
        claimOnlineSubStatus: 'In Adjudication',
        claimOnlineSubStatusCode: '12',
        extensionStatus: null,
        extensionStatusCode: null,
        extensionSubStatus: null,
        extensionSubStatusCode: null,
        claimOfficeNumber: null,
        claimOfficeId: null,
        caseId: '1099157',
        claimProcessDate: '03/25/2020',
        claimEffectiveDate: null,
        reason: 'New Claim',
        physicianStatementDate: null,
        employerStatementDate: null,
        employeeStatementDate: null,
        estimatedSurgeryDate: null,
        estimatedDeliveryDate: '04/01/2020',
        lastDayWork: '03/09/2020',
        asmIndicator: 'M',
        confirmedLastDayWorkDate: '03/09/2020',
        confirmedSurgeryDate: null,
        confirmedDeliveryDate: null,
        encIndicator: false,
        encSurgeryIndicator: false,
        fundingMethod: null,
        coveragePlanNumber: null,
        taskOpen: false,
        claimProgress: 'Under Review',
        extensionRequestDate: null,
        babyDeathIndicator: null,
        recentClaimIndicator: false,
        semiMonthlyPaymentIndicator: null,
        concurrentLeaveIDs: [],
        sourceSystemId: 'DCS',
        personId: '7052337',
        relatedClaims: [{
          relatedClaimList: [],
          hasRelatedClaims: false
        }],
        rtwStatusCode: '42001',
        oowReason: 'Pregnancy',
        relationship: null
      },
      {
        LTDmissingInfo: true,
        claimEventId: '1603470',
        claimType: 'Long Term Disability',
        claimSubType: 'Long Term Disability',
        coverageType: null,
        coverageCategory: 'LTD',
        disabilityDate: '06/02/2020',
        effectiveDate: '06/30/2021',
        authorizedEndDate: null,
        claimStatus: 'Pending',
        claimStatusCode: '01',
        claimOnlineStatus: null,
        claimOnlineStatusCode: null,
        claimOnlineSubStatus: null,
        claimOnlineSubStatusCode: null,
        extensionStatus: null,
        extensionStatusCode: null,
        extensionSubStatus: null,
        extensionSubStatusCode: null,
        claimOfficeNumber: null,
        claimOfficeId: null,
        caseId: '1513734',
        claimProcessDate: '06/24/2021',
        claimEffectiveDate: '01/22/2021',
        reason: 'New Claim',
        physicianStatementDate: null,
        employerStatementDate: null,
        employeeStatementDate: null,
        estimatedSurgeryDate: null,
        estimatedDeliveryDate: null,
        lastDayWork: '06/01/2020',
        asmIndicator: 'A',
        confirmedLastDayWorkDate: null,
        confirmedSurgeryDate: null,
        confirmedDeliveryDate: null,
        encIndicator: false,
        encSurgeryIndicator: false,
        fundingMethod: null,
        coveragePlanNumber: null,
        taskOpen: false,
        missingInfo: [
          {
            mInfoCode: '01',
            mInfoDesc: null,
            requiredByDate: null,
            mInfoReqInfo: [
              {
                mInfoReqInfoCd: '01',
                mInfoReqInfoDesc: null,
                requestDate: null,
                displayValue: 'Medical History Form',
                chatInd: false,
                callInd: false,
                uploadInd: true,
                detailInd: false,
                formType: 'generic',
                listPhysicain: null
              }
            ],
            gracePeriod: false
          },
          {
            mInfoCode: '10023364',
            mInfoDesc: null,
            requiredByDate: null,
            mInfoReqInfo: [
              {
                mInfoReqInfoCd: '63704',
                mInfoReqInfoDesc: null,
                requestDate: null,
                displayValue: 'Employer Statement',
                chatInd: false,
                callInd: false,
                uploadInd: false,
                detailInd: false,
                formType: null,
                listPhysicain: null
              }
            ],
            gracePeriod: false
          },
          {
            mInfoCode: '10023362',
            mInfoDesc: null,
            requiredByDate: null,
            mInfoReqInfo: [
              {
                mInfoReqInfoCd: '63705',
                mInfoReqInfoDesc: null,
                requestDate: null,
                displayValue: 'Doctor Statement',
                chatInd: false,
                callInd: false,
                uploadInd: false,
                detailInd: false,
                formType: null,
                listPhysicain: null
              }
            ],
            gracePeriod: false
          }
        ],
        claimProgress: 'Claim Here',
        extensionRequestDate: null,
        babyDeathIndicator: null,
        recentClaimIndicator: false,
        semiMonthlyPaymentIndicator: null,
        concurrentLeaveIDs: [

        ],
        sourceSystemId: 'DCS',
        personId: '8681254',
        relatedClaims: [
          {
            relatedClaimList: [

            ],
            hasRelatedClaims: false
          }
        ],
        rtwStatusCode: '42001',
        oowReason: 'Employee\'s own illness',
        form: '02'
      },
      {
        claimEventId:  '1568988',
        claimType:  'Short Term Disability',
        claimSubType:  ' Maternity',
        coverageType:  'NST',
        coverageCategory:  'WD',
        disabilityDate:  '03/09/2020',
        effectiveDate:  null,
        authorizedEndDate:  '03/09/2020',
        claimStatus:  'Open',
        claimStatusCode:  '0',
        claimOnlineStatus:  'Claim Closed',
        claimOnlineStatusCode:  '05',
        claimOnlineSubStatus:  'Claim Closed',
        claimOnlineSubStatusCode:  '08',
        extensionStatus:  'Extension Under Review',
        extensionStatusCode:  '1',
        extensionSubStatus:  'Missing Information',
        extensionSubStatusCode:  '1',
        claimOfficeNumber:  null,
        claimOfficeId:  null,
        caseId:  '"1099157"',
        form: '01',
        claimProcessDate:  '05/05/2020',
        claimEffectiveDate:  null,
        physicianStatementDate:  '03/13/2020',
        employerStatementDate:  '03/09/2020',
        employeeStatementDate:  '03/09/2020',
        estimatedSurgeryDate:  '05/05/2020',
        estimatedDeliveryDate:  '05/22/2020',
        lastDayWork:  '03/06/2020',
        asmIndicator:  'M',
        confirmedLastDayWorkDate:  '03/06/2020',
        confirmedSurgeryDate:  '05/05/2020',
        confirmedDeliveryDate:  null,
        encIndicator:  false,
        encSurgeryIndicator:  false,
        fundingMethod:  'ATP',
        coveragePlanNumber:  null,
        taskOpen:  false,
        missingInfo: [
          {
            mInfoCode: '10023364',
            mInfoDesc: 'Employer',
            requiredByDate: '',
            mInfoReqInfo: [{
              mInfoReqInfoCd: '12',
              mInfoReqInfoDesc: '',
              requestDate: '4/9/2019',
              displayValue: 'Salary Continuation, PTO, or Sick Time Details',
              chatInd: false,
              callInd: false,
              uploadInd: false,
              detailInd: true,
              formType: 'generic',
              listPhysicain: [{
                physicianName: 'Dr.ABC',
                fromDataRange: '3/21/2019',
                toDataRange: ''
              }]
            }],
            gracePeriod: false
          },
          // {
          //   'mInfoCode': '10023366',
          //   'mInfoDesc': 'Employee',
          //   'requiredByDate': '',
          //   'mInfoReqInfo': [{
          //     'mInfoReqInfoCd': '01',
          //     'mInfoReqInfoDesc': '',
          //     'requestDate': '3/21/2019',
          //     'displayValue': 'THAA form',
          //     'chatInd': false,
          //     'callInd': false,
          //     'uploadInd': true,
          //     'detailInd': false,
          //     'formType': 'generic',
          //     'listPhysicain': [{
          //       'physicianName': 'Dr.XYZ',
          //       'fromDataRange': '3/21/2019',
          //       'toDataRange': ''
          //     }]
          //   }],
          //   'gracePeriod': false
          // },
          // {
          //   'mInfoCode': '10023362',
          //   'mInfoDesc': 'Physician',
          //   'requiredByDate': '',
          //   'mInfoReqInfo': [ {
          //     'mInfoReqInfoCd': '07',
          //     'mInfoReqInfoDesc': '',
          //     'requestDate': '3/21/2019',
          //     'displayValue': 'Cause of Disabling Condition',
          //     'chatInd': false,
          //     'callInd': false,
          //     'uploadInd': false,
          //     'detailInd': true,
          //     'formType': 'generic',
          //     'listPhysicain': [{
          //       'physicianName': 'test',
          //       'fromDataRange': '3/21/2019',
          //       'toDataRange': '3/22/2019'
          //     }]
          //   }],
          //   'gracePeriod': false
          // }
          ],
        recentlyResolvedItems: [
          {
            mInfoCode: '10023364',
            mInfoDesc: 'Employee',
            requiredByDate: '',
            mInfoReqInfo: [{
              mInfoReqInfoCd: '12',
              recentlyInfoReqInfoDesc: 'Description',
              requestDate: '4/9/2019',
              displayValue: 'Salary Continuation, PTO, or Sick Time Details',
              detailInd: true,
              formType: 'generic',
              listPhysicain: [{
                physicianName: 'Dr.ABC',
                fromDataRange: '3/21/2019',
                toDataRange: ''
              }]
            }],
            gracePeriod: false
          }
        ],
        claimProgress:  'Claim Closed',
        extensionRequestDate:  '5/11/2020',
        babyDeathIndicator:  null,
        recentClaimIndicator:  false,
        concurrentLeaveIDs: ['1337585'],
        orangeIconMissingInfo: true,
        sourceSystemId:  'AA',
        physicianStatmentDate: '03/09/2020',
        reason: 'Client Request',
        personId:  '8485524',
       // 'extensionUIMissStatusIcon': true,
        relatedClaims: [{
          relatedClaimList: [],
          hasRelatedClaims:  false
        }],
        rtwStatusCode: '42002',
        oowReason: 'Pregnancy',
        relationship: null,
        payeeType: null
      },
      {
        claimEventId:  '1568999',
        claimType:  'Short Term Disability',
        claimSubType:  ' Maternity',
        coverageType:  'NST',
        coverageCategory:  'WD',
        disabilityDate:  '03/09/2020',
        effectiveDate:  null,
        authorizedEndDate:  '03/09/2020',
        claimStatus:  'Open',
        claimStatusCode:  '0',
        claimOnlineStatus:  'Claim Closed',
        claimOnlineStatusCode:  '05',
        claimOnlineSubStatus:  'Claim Closed',
        claimOnlineSubStatusCode:  '08',
        extensionStatus:  'Extension Approval',
        extensionStatusCode:  '2',
        extensionSubStatus:  'Partial Approval',
        extensionSubStatusCode:  '4',
        claimOfficeNumber:  null,
        claimOfficeId:  null,
        caseId:  '"1099157"',
        form: '01',
        claimProcessDate:  '05/05/2020',
        claimEffectiveDate:  null,
        physicianStatementDate:  '03/13/2020',
        employerStatementDate:  '03/09/2020',
        employeeStatementDate:  '03/09/2020',
        estimatedSurgeryDate:  '05/05/2020',
        estimatedDeliveryDate:  '05/22/2020',
        lastDayWork:  '03/06/2020',
        asmIndicator:  'M',
        confirmedLastDayWorkDate:  '03/06/2020',
        confirmedSurgeryDate:  '05/05/2020',
        confirmedDeliveryDate:  null,
        encIndicator:  false,
        encSurgeryIndicator:  false,
        fundingMethod:  'ATP',
        coveragePlanNumber:  null,
        taskOpen:  false,
        missingInfo: [
          {
            mInfoCode: '10023364',
            mInfoDesc: 'Employer',
            requiredByDate: '',
            mInfoReqInfo: [{
              mInfoReqInfoCd: '12',
              mInfoReqInfoDesc: '',
              requestDate: '4/9/2019',
              displayValue: 'Salary Continuation, PTO, or Sick Time Details',
              chatInd: false,
              callInd: false,
              uploadInd: false,
              detailInd: true,
              formType: 'generic',
              listPhysicain: [{
                physicianName: 'Dr.ABC',
                fromDataRange: '3/21/2019',
                toDataRange: ''
              }]
            }],
            gracePeriod: false
          }, {
            mInfoCode: '10023366',
            mInfoDesc: 'Employee',
            requiredByDate: '',
            mInfoReqInfo: [{
              mInfoReqInfoCd: '01',
              mInfoReqInfoDesc: '',
              requestDate: '3/21/2019',
              displayValue: 'THAA form',
              chatInd: false,
              callInd: false,
              uploadInd: true,
              detailInd: false,
              formType: 'generic',
              listPhysicain: [{
                physicianName: 'Dr.XYZ',
                fromDataRange: '3/21/2019',
                toDataRange: ''
              }]
            }],
            gracePeriod: false
          }, {
            mInfoCode: '10023362',
            mInfoDesc: 'Physician',
            requiredByDate: '',
            mInfoReqInfo: [ {
              mInfoReqInfoCd: '07',
              mInfoReqInfoDesc: '',
              requestDate: '3/21/2019',
              displayValue: 'Cause of Disabling Condition',
              chatInd: false,
              callInd: false,
              uploadInd: false,
              detailInd: true,
              formType: 'generic',
              listPhysicain: [{
                physicianName: 'test',
                fromDataRange: '3/21/2019',
                toDataRange: '3/22/2019'
              }]
            }],
            gracePeriod: false
          }],
        recentlyResolvedItems: [
          {
            mInfoCode: '10023364',
            mInfoDesc: 'Employee',
            requiredByDate: '',
            mInfoReqInfo: [{
              mInfoReqInfoCd: '12',
              recentlyInfoReqInfoDesc: 'Description',
              requestDate: '4/9/2019',
              displayValue: 'Salary Continuation, PTO, or Sick Time Details',
              detailInd: true,
              formType: 'generic',
              listPhysicain: [{
                physicianName: 'Dr.ABC',
                fromDataRange: '3/21/2019',
                toDataRange: ''
              }]
            }],
            gracePeriod: false
          }
        ],
        claimProgress:  'Claim Closed',
        extensionRequestDate:  '5/11/2020',
        babyDeathIndicator:  null,
        recentClaimIndicator:  false,
        concurrentLeaveIDs: ['1337585'],
        orangeIconMissingInfo: true,
        sourceSystemId:  'AA',
        physicianStatmentDate: '03/09/2020',
        reason: 'Client Request',
        personId:  '8485524',
       // 'extensionUIMissStatusIcon': true,
        relatedClaims: [{
          relatedClaimList: [],
          hasRelatedClaims:  false
        }],
        rtwStatusCode: '42002',
        oowReason: 'Pregnancy',
        relationship: null,
        payeeType: null
      },
      {
        claimEventId:  '1567000',
        claimType:  'Short Term Disability',
        claimSubType:  ' Maternity',
        coverageType:  'NST',
        coverageCategory:  'WD',
        disabilityDate:  '03/09/2020',
        effectiveDate:  null,
        authorizedEndDate:  '03/09/2020',
        claimStatus:  'Open',
        claimStatusCode:  '0',
        claimOnlineStatus:  'Claim Approved',
        claimOnlineStatusCode:  '04',
        claimOnlineSubStatus:  'Payment In Progress',
        claimOnlineSubStatusCode:  '06',
        extensionStatus:  'Extension Under Review',
        extensionStatusCode:  '1',
        extensionSubStatus:  'Missing Information',
        extensionSubStatusCode:  '1',
        claimOfficeNumber:  null,
        claimOfficeId:  null,
        caseId:  '"1099157"',
        form: '01',
        claimProcessDate:  '05/05/2020',
        claimEffectiveDate:  null,
        physicianStatementDate:  '03/13/2020',
        employerStatementDate:  '03/09/2020',
        employeeStatementDate:  '03/09/2020',
        estimatedSurgeryDate:  '05/05/2020',
        estimatedDeliveryDate:  '05/22/2020',
        lastDayWork:  '03/06/2020',
        asmIndicator:  'M',
        confirmedLastDayWorkDate:  '03/06/2020',
        confirmedSurgeryDate:  '05/05/2020',
        confirmedDeliveryDate:  null,
        encIndicator:  false,
        encSurgeryIndicator:  false,
        fundingMethod:  'ATP',
        coveragePlanNumber:  null,
        taskOpen:  false,
        missingInfo: [
          {
            mInfoCode: '10023364',
            mInfoDesc: 'Employer',
            requiredByDate: '',
            mInfoReqInfo: [{
              mInfoReqInfoCd: '12',
              mInfoReqInfoDesc: '',
              requestDate: '4/9/2019',
              displayValue: 'Salary Continuation, PTO, or Sick Time Details',
              chatInd: false,
              callInd: false,
              uploadInd: false,
              detailInd: true,
              formType: 'generic',
              listPhysicain: [{
                physicianName: 'Dr.ABC',
                fromDataRange: '3/21/2019',
                toDataRange: ''
              }]
            }],
            gracePeriod: false
          }, {
            mInfoCode: '10023366',
            mInfoDesc: 'Employee',
            requiredByDate: '',
            mInfoReqInfo: [{
              mInfoReqInfoCd: '01',
              mInfoReqInfoDesc: '',
              requestDate: '3/21/2019',
              displayValue: 'THAA form',
              chatInd: false,
              callInd: false,
              uploadInd: true,
              detailInd: false,
              formType: 'generic',
              listPhysicain: [{
                physicianName: 'Dr.XYZ',
                fromDataRange: '3/21/2019',
                toDataRange: ''
              }]
            }],
            gracePeriod: false
          }, {
            mInfoCode: '10023362',
            mInfoDesc: 'Physician',
            requiredByDate: '',
            mInfoReqInfo: [ {
              mInfoReqInfoCd: '07',
              mInfoReqInfoDesc: '',
              requestDate: '3/21/2019',
              displayValue: 'Cause of Disabling Condition',
              chatInd: false,
              callInd: false,
              uploadInd: false,
              detailInd: true,
              formType: 'generic',
              listPhysicain: [{
                physicianName: 'test',
                fromDataRange: '3/21/2019',
                toDataRange: '3/22/2019'
              }]
            }],
            gracePeriod: false
          }],
        recentlyResolvedItems: [
          {
            mInfoCode: '10023364',
            mInfoDesc: 'Employee',
            requiredByDate: '',
            mInfoReqInfo: [{
              mInfoReqInfoCd: '12',
              recentlyInfoReqInfoDesc: 'Description',
              requestDate: '4/9/2019',
              displayValue: 'Salary Continuation, PTO, or Sick Time Details',
              detailInd: true,
              formType: 'generic',
              listPhysicain: [{
                physicianName: 'Dr.ABC',
                fromDataRange: '3/21/2019',
                toDataRange: ''
              }]
            }],
            gracePeriod: false
          }
        ],
        claimProgress:  'Claim Closed',
        extensionRequestDate:  null,
        babyDeathIndicator:  null,
        recentClaimIndicator:  false,
        concurrentLeaveIDs: ['1337585'],
        orangeIconMissingInfo: true,
        sourceSystemId:  'AA',
        physicianStatmentDate: '03/09/2020',
        reason: 'Client Request',
        personId:  '8485524',
     //   'extensionUIMissStatusIcon': true,
        relatedClaims: [{
          relatedClaimList: [],
          hasRelatedClaims:  false
        }],
        rtwStatusCode: '42002',
        oowReason: 'Pregnancy',
        relationship: null,
        payeeType: null
      },
      {
        claimEventId:  '1567005',
        claimType:  'Long Term Disability',
        claimSubType:  'Long Term Disability',
        coverageType:  'BAS',
        coverageCategory:  'LTD',
        disabilityDate:  '12/01/2020',
        effectiveDate:  '12/01/2020',
        authorizedEndDate:  null,
        claimStatus:  'Denied',
        claimStatusCode:  '03',
        claimOnlineStatus:  null,
        claimOnlineStatusCode:  null,
        claimOnlineSubStatus:  null,
        claimOnlineSubStatusCode:  null,
        extensionStatus:  null,
        extensionStatusCode:  null,
        extensionSubStatus:  null,
        extensionSubStatusCode:  null,
        claimOfficeNumber:  null,
        claimOfficeId:  null,
        caseId:  '"1099157"',
        form: '01',
        claimProcessDate:  '05/05/2020',
        claimEffectiveDate:  null,
        physicianStatementDate:  '03/13/2020',
        employerStatementDate:  '03/09/2020',
        employeeStatementDate:  '03/09/2020',
        estimatedSurgeryDate:  '05/05/2020',
        estimatedDeliveryDate:  '05/22/2020',
        lastDayWork:  '03/06/2020',
        asmIndicator:  'M',
        confirmedLastDayWorkDate:  '03/06/2020',
        confirmedSurgeryDate:  '05/05/2020',
        confirmedDeliveryDate:  null,
        encIndicator:  false,
        encSurgeryIndicator:  false,
        fundingMethod:  'ATP',
        coveragePlanNumber:  null,
        taskOpen:  false,
        missingInfo: [
          {
            mInfoCode: '10023364',
            mInfoDesc: 'Employer',
            requiredByDate: '',
            mInfoReqInfo: [{
              mInfoReqInfoCd: '12',
              mInfoReqInfoDesc: '',
              requestDate: '4/9/2019',
              displayValue: 'Salary Continuation, PTO, or Sick Time Details',
              chatInd: false,
              callInd: false,
              uploadInd: false,
              detailInd: true,
              formType: 'generic',
              listPhysicain: [{
                physicianName: 'Dr.ABC',
                fromDataRange: '3/21/2019',
                toDataRange: ''
              }]
            }],
            gracePeriod: false
          }, {
            mInfoCode: '10023366',
            mInfoDesc: 'Employee',
            requiredByDate: '',
            mInfoReqInfo: [{
              mInfoReqInfoCd: '01',
              mInfoReqInfoDesc: '',
              requestDate: '3/21/2019',
              displayValue: 'THAA form',
              chatInd: false,
              callInd: false,
              uploadInd: true,
              detailInd: false,
              formType: 'generic',
              listPhysicain: [{
                physicianName: 'Dr.XYZ',
                fromDataRange: '3/21/2019',
                toDataRange: ''
              }]
            }],
            gracePeriod: false
          }, {
            mInfoCode: '10023362',
            mInfoDesc: 'Physician',
            requiredByDate: '',
            mInfoReqInfo: [ {
              mInfoReqInfoCd: '07',
              mInfoReqInfoDesc: '',
              requestDate: '3/21/2019',
              displayValue: 'Cause of Disabling Condition',
              chatInd: false,
              callInd: false,
              uploadInd: false,
              detailInd: true,
              formType: 'generic',
              listPhysicain: [{
                physicianName: 'test',
                fromDataRange: '3/21/2019',
                toDataRange: '3/22/2019'
              }]
            }],
            gracePeriod: false
          }],
        recentlyResolvedItems: [
          {
            mInfoCode: '10023364',
            mInfoDesc: 'Employee',
            requiredByDate: '',
            mInfoReqInfo: [{
              mInfoReqInfoCd: '12',
              recentlyInfoReqInfoDesc: 'Description',
              requestDate: '4/9/2019',
              displayValue: 'Salary Continuation, PTO, or Sick Time Details',
              detailInd: true,
              formType: 'generic',
              listPhysicain: [{
                physicianName: 'Dr.ABC',
                fromDataRange: '3/21/2019',
                toDataRange: ''
              }]
            }],
            gracePeriod: false
          }
        ],
        claimProgress:  null,
        extensionRequestDate:  null,
        babyDeathIndicator:  null,
        recentClaimIndicator:  false,
        concurrentLeaveIDs: ['1337585'],
        orangeIconMissingInfo: true,
        sourceSystemId:  'DCS',
        physicianStatmentDate: '03/09/2020',
        reason: 'Client Request',
        personId:  '8485524',
    //    'extensionUIMissStatusIcon': true,
        relatedClaims: [{
          relatedClaimList: [],
          hasRelatedClaims:  false
        }],
        rtwStatusCode: null,
        oowReason: null,
        relationship: null,
        payeeType: null
      },
      // {
      //   'claimEventId':  '1568989',
      //   'claimType':  'Short Term Disability',
      //   'claimSubType':  ' Maternity',
      //   'coverageType':  'NST',
      //   'coverageCategory':  'WD',
      //   'disabilityDate':  '03/09/2020',
      //   'effectiveDate':  null,
      //   'authorizedEndDate':  '03/09/2020',
      //   'claimStatus':  'Open',
      //   'claimStatusCode':  '0',
      //   'claimOnlineStatus':  'Claim Closed',
      //   'claimOnlineStatusCode':  '05',
      //   'claimOnlineSubStatus':  'Claim Closed',
      //   'claimOnlineSubStatusCode':  '08',
      //   'extensionStatus':  'Extension Closed',
      //   'extensionStatusCode':  '4',
      //   'extensionSubStatus':  'Missing Information',
      //   'extensionSubStatusCode':  '1',
      //   'claimOfficeNumber':  null,
      //   'claimOfficeId':  null,
      //   'caseId':  '"1099157"',
      //   'claimProcessDate':  '05/05/2020',
      //   'claimEffectiveDate':  null,
      //   'physicianStatementDate':  '03/13/2020',
      //   'employerStatementDate':  '03/09/2020',
      //   'employeeStatementDate':  '03/09/2020',
      //   'estimatedSurgeryDate':  '05/05/2020',
      //   'estimatedDeliveryDate':  '05/22/2020',
      //   'lastDayWork':  '03/06/2020',
      //   'asmIndicator':  'M',
      //   'confirmedLastDayWorkDate':  '03/06/2020',
      //   'confirmedSurgeryDate':  '05/05/2020',
      //   'confirmedDeliveryDate':  null,
      //   'encIndicator':  false,
      //   'encSurgeryIndicator':  false,
      //   'fundingMethod':  'ATP',
      //   'coveragePlanNumber':  null,
      //   'taskOpen':  false,
      //   'missingInfo': [
      //     {
      //       'mInfoCode': '10023364',
      //       'mInfoDesc': 'Employer',
      //       'requiredByDate': '',
      //       'mInfoReqInfo': [{
      //         'mInfoReqInfoCd': '12',
      //         'mInfoReqInfoDesc': '',
      //         'requestDate': '4/9/2019',
      //         'displayValue': 'Salary Continuation, PTO, or Sick Time Details',
      //         'chatInd': false,
      //         'callInd': false,
      //         'uploadInd': false,
      //         'detailInd': true,
      //         'formType': 'generic',
      //         'listPhysicain': [{
      //           'physicianName': 'Dr.ABC',
      //           'fromDataRange': '3/21/2019',
      //           'toDataRange': ''
      //         }]
      //       }],
      //       'gracePeriod': false
      //     }, {
      //       'mInfoCode': '10023366',
      //       'mInfoDesc': 'Employee',
      //       'requiredByDate': '',
      //       'mInfoReqInfo': [{
      //         'mInfoReqInfoCd': '01',
      //         'mInfoReqInfoDesc': '',
      //         'requestDate': '3/21/2019',
      //         'displayValue': 'THAA form',
      //         'chatInd': false,
      //         'callInd': false,
      //         'uploadInd': true,
      //         'detailInd': false,
      //         'formType': 'generic',
      //         'listPhysicain': [{
      //           'physicianName': 'Dr.XYZ',
      //           'fromDataRange': '3/21/2019',
      //           'toDataRange': ''
      //         }]
      //       }],
      //       'gracePeriod': false
      //     }, {
      //       'mInfoCode': '10023362',
      //       'mInfoDesc': 'Physician',
      //       'requiredByDate': '',
      //       'mInfoReqInfo': [ {
      //         'mInfoReqInfoCd': '07',
      //         'mInfoReqInfoDesc': '',
      //         'requestDate': '3/21/2019',
      //         'displayValue': 'Cause of Disabling Condition',
      //         'chatInd': false,
      //         'callInd': false,
      //         'uploadInd': false,
      //         'detailInd': true,
      //         'formType': 'generic',
      //         'listPhysicain': [{
      //           'physicianName': 'test',
      //           'fromDataRange': '3/21/2019',
      //           'toDataRange': '3/22/2019'
      //         }]
      //       }],
      //       'gracePeriod': false
      //     }],
      //   'recentlyResolvedItems': [
      //     {
      //       'mInfoCode': '10023364',
      //       'mInfoDesc': 'Employee',
      //       'requiredByDate': '',
      //       'mInfoReqInfo': [{
      //         'mInfoReqInfoCd': '12',
      //         'recentlyInfoReqInfoDesc': 'Description',
      //         'requestDate': '4/9/2019',
      //         'displayValue': 'Salary Continuation, PTO, or Sick Time Details',
      //         'detailInd': true,
      //         'formType': 'generic',
      //         'listPhysicain': [{
      //           'physicianName': 'Dr.ABC',
      //           'fromDataRange': '3/21/2019',
      //           'toDataRange': ''
      //         }]
      //       }],
      //       'gracePeriod': false
      //     }
      //   ],
      //   'claimProgress':  'In Payment',
      //   'extensionRequestDate':  '5/11/2020',
      //   'babyDeathIndicator':  null,
      //   'recentClaimIndicator':  false,
      //   'concurrentLeaveIDs': ['1337585'],
      //   'orangeIconMissingInfo': true,
      //   'sourceSystemId':  'AA',
      //   'physicianStatmentDate': '03/09/2020',
      //   'reason': 'Client Request',
      //   'personId':  '8485524',
      //   'relatedClaims': [{
      //     'relatedClaimList': [],
      //     'hasRelatedClaims':  false
      //   }],
      //   'rtwStatusCode': '42002',
      //   'oowReason': 'Pregnancy',
      //   'relationship': null,
      //   'payeeType': null
      // },
      {
        claimEventId:  '12',
        claimType:  'Short Term Disability',
        claimSubType:  ' Maternity',
        coverageType:  'NST',
        coverageCategory:  'WD',
        disabilityDate:  '03/09/2020',
        effectiveDate:  null,
        authorizedEndDate:  '03/09/2020',
        claimStatus:  'Open',
        claimStatusCode:  '0',
        claimOnlineStatus:  'Claim Closed',
        claimOnlineStatusCode:  '05',
        claimOnlineSubStatus:  'Missing Information',
        claimOnlineSubStatusCode:  '09',
        extensionStatus:  'Extension Closed',
        extensionStatusCode:  '4',
        extensionSubStatus:  'Missing Information',
        extensionSubStatusCode:  '1',
        claimOfficeNumber:  null,
        claimOfficeId:  null,
        caseId:  '"1099157"',
        claimProcessDate:  '05/05/2020',
        claimEffectiveDate:  null,
        physicianStatementDate:  '03/13/2020',
        employerStatementDate:  '03/09/2020',
        employeeStatementDate:  '03/09/2020',
        estimatedSurgeryDate:  '05/05/2020',
        estimatedDeliveryDate:  '05/22/2020',
        lastDayWork:  '03/06/2020',
        asmIndicator:  'M',
        confirmedLastDayWorkDate:  '03/06/2020',
        confirmedSurgeryDate:  '05/05/2020',
        confirmedDeliveryDate:  null,
        encIndicator:  false,
        encSurgeryIndicator:  false,
        fundingMethod:  'ATP',
        coveragePlanNumber:  null,
        taskOpen:  false,
        missingInfo: [
          {
            mInfoCode: '02',
            mInfoDesc: 'Employer',
            requiredByDate: '',
            mInfoReqInfo: [{
              mInfoReqInfoCd: '12',
              mInfoReqInfoDesc: '',
              requestDate: '4/9/2019',
              displayValue: 'Salary Continuation, PTO, or Sick Time Details',
              chatInd: false,
              callInd: false,
              uploadInd: false,
              detailInd: true,
              formType: 'generic',
              listPhysicain: [{
                physicianName: 'Dr.ABC',
                fromDataRange: '3/21/2019',
                toDataRange: ''
              }]
            }],
            gracePeriod: false
          },
          // {
          //   'mInfoCode': '10023366',
          //   'mInfoDesc': 'Employee',
          //   'requiredByDate': '',
          //   'mInfoReqInfo': [{
          //     'mInfoReqInfoCd': '01',
          //     'mInfoReqInfoDesc': '',
          //     'requestDate': '3/21/2019',
          //     'displayValue': 'THAA form',
          //     'chatInd': false,
          //     'callInd': false,
          //     'uploadInd': true,
          //     'detailInd': false,
          //     'formType': 'generic',
          //     'listPhysicain': [{
          //       'physicianName': 'Dr.XYZ',
          //       'fromDataRange': '3/21/2019',
          //       'toDataRange': ''
          //     }]
          //   }],
          //   'gracePeriod': false
          // },
          // {
          //   'mInfoCode': '03',
          //   'mInfoDesc': 'Physician',
          //   'requiredByDate': '',
          //   'mInfoReqInfo': [ {
          //     'mInfoReqInfoCd': '07',
          //     'mInfoReqInfoDesc': '',
          //     'requestDate': '3/21/2019',
          //     'displayValue': 'Cause of Disabling Condition',
          //     'chatInd': false,
          //     'callInd': false,
          //     'uploadInd': false,
          //     'detailInd': true,
          //     'formType': 'generic',
          //     'listPhysicain': [{
          //       'physicianName': 'test',
          //       'fromDataRange': '3/21/2019',
          //       'toDataRange': '3/22/2019'
          //     }]
          //   }],
          //   'gracePeriod': false
          // }
          ],
        recentlyResolvedItems: [
          {
            mInfoCode: '10023364',
            mInfoDesc: 'Employee',
            requiredByDate: '',
            mInfoReqInfo: [{
              mInfoReqInfoCd: '12',
              recentlyInfoReqInfoDesc: 'Description',
              requestDate: '4/9/2019',
              displayValue: 'Salary Continuation, PTO, or Sick Time Details',
              detailInd: true,
              formType: 'generic',
              listPhysicain: [{
                physicianName: 'Dr.ABC',
                fromDataRange: '3/21/2019',
                toDataRange: ''
              }]
            }],
            gracePeriod: false
          }
        ],
        claimProgress:  'In Payment',
        extensionRequestDate:  '5/11/2020',
        babyDeathIndicator:  null,
        recentClaimIndicator:  false,
        concurrentLeaveIDs: ['1337585'],
        orangeIconMissingInfo: true,
        sourceSystemId:  'AA',
        physicianStatmentDate: '03/09/2020',
        reason: 'Client Request',
        personId:  '8485524',
        relatedClaims: [{
          relatedClaimList: [],
          hasRelatedClaims:  false
        }],
        rtwStatusCode: '42002',
        oowReason: 'Pregnancy',
        relationship: null,
        payeeType: null
      },
      {
        claimEventId: '16718810',
        claimType: 'Short Term Disability',
        claimSubType: 'Maternity',
        coverageType: 'NST',
        coverageCategory: 'WD',
        disabilityDate: '06/10/2020',
        effectiveDate: null,
        authorizedEndDate: null,
        claimStatus: 'Paperless Intake',
        claimStatusCode: '05',
        claimOnlineStatus: 'Awaiting Delivery',
        claimOnlineStatusCode: '01',
        claimOnlineSubStatus: 'Awaiting Delivery',
        claimOnlineSubStatusCode: '01',
        extensionStatus: null,
        extensionStatusCode: null,
        extensionSubStatus: null,
        extensionSubStatusCode: null,
        claimOfficeNumber: null,
        claimOfficeId: '733',
        caseId: '471538',
        claimProcessDate: '04/07/2020',
        claimEffectiveDate: '04/07/2020',
        reason: 'N/A',
        physicianStatementDate: null,
        employerStatementDate: null,
        employeeStatementDate: '04/07/2020',
        estimatedSurgeryDate: null,
        estimatedDeliveryDate: '06/30/2020',
        lastDayWork: '06/10/2020',
        asmIndicator: 'M',
        confirmedLastDayWorkDate: null,
        confirmedSurgeryDate: null,
        confirmedDeliveryDate: null,
        encIndicator: true,
        encSurgeryIndicator: false,
        fundingMethod: 'FI',
        coveragePlanNumber: '1',
        taskOpen: false,
        missingInfo: null,
        claimProgress: 'Awaiting Delivery',
        extensionRequestDate: null,
        babyDeathIndicator: null,
        recentClaimIndicator: true,
        semiMonthlyPaymentIndicator: 'N',
        concurrentLeaveIDs: null,
        sourceSystemId: 'AA',
        personId: null,
        relatedClaims: null,
        rtwStatusCode: null,
        oowReason: null,
        form: null,
        payeeType: null
      },
      {
        claimEventId: '1234567',
        sourceSystemId:  'AA',
        claimType: 'Short Term Disability- Supplemental Statutory',
        claimSubType: 'Supplemental State Surgery',
        coverageType: 'SUPP',
        coverageCategory: 'LTD',
        disabilityDate: '04/15/2018',
        effectiveDate: null,
        authorizedEndDate: null,
        claimStatus: 'Paperless Intake',
        claimStatusCode: '05',
        claimOnlineStatus: 'Awaiting Surgery Details',
        claimOnlineStatusCode: '04',
        claimOnlineSubStatus: 'Awaiting Surgery Details',
        claimOnlineSubStatusCode: '03',
        claimOfficeFaxNumber: null,
        extensionStatus: null,
        extensionStatusCode: null,
        extensionSubStatus: null,
        extensionSubStatusCode: null,
        claimOfficeNumber: null,
        claimOfficeId: '733',
        caseId: '978301',
        claimProcessDate: '04/10/2018',
        claimEffectiveDate: '04/10/2018',
        reason: 'N/A',
        physicianStatementDate: '04/10/2018',
        employerStatementDate: null,
        employeeStatementDate: '04/10/2018',
        estimatedSurgeryDate: '02/11/2018',
        estimatedDeliveryDate: null,
        lastDayWork: '04/12/2018',
        asmIndicator: 'A',
        confirmedLastDayWorkDate: '01/07/2018',
        confirmedSurgeryDate: '02/11/2018',
        confirmedDeliveryDate: null,
        encIndicator: true,
        encSurgeryIndicator: true,
        fundingMethod: 'FI',
        coveragePlanNumber: '1',
        taskOpen: false,
        missingInfo: null,
        claimProgress: 'Awaiting Surgery',
        extensionRequestDate: '11/15/2018',
        babyDeathIndicator: null,
        recentClaimIndicator: true,
        personId: '123456'
      },
      {
        claimEventId: '1561084',
        claimType: 'Short Term Disability',
        claimSubType: 'Surgery',
        coverageType: 'NST',
        coverageCategory: 'WD',
        disabilityDate: '04/02/2020',
        effectiveDate: null,
        authorizedEndDate: null,
        claimStatus: 'Pend',
        claimStatusCode: '0',
        claimOnlineStatus: 'Under Review by Claim Handler',
        claimOnlineStatusCode: '08',
        claimOnlineSubStatus: 'In Adjudication',
        claimOnlineSubStatusCode: '12',
        extensionStatus: null,
        extensionStatusCode: null,
        extensionSubStatus: null,
        extensionSubStatusCode: null,
        claimOfficeNumber: null,
        claimOfficeId: null,
        caseId: '1099157',
        claimProcessDate: '03/31/2020',
        claimEffectiveDate: null,
        reason: 'Additional Medical',
        physicianStatementDate: null,
        employerStatementDate: null,
        employeeStatementDate: '02/24/2020',
        estimatedSurgeryDate: '04/07/2020',
        estimatedDeliveryDate: null,
        lastDayWork: '04/01/2020',
        asmIndicator: 'A',
        confirmedLastDayWorkDate: '04/01/2020',
        confirmedSurgeryDate: '04/07/2020',
        confirmedDeliveryDate: null,
        encIndicator: false,
        encSurgeryIndicator: false,
        fundingMethod: null,
        coveragePlanNumber: null,
        taskOpen: false,
        missingInfo: [],
        claimProgress: 'Under Review',
        extensionRequestDate: null,
        babyDeathIndicator: null,
        recentClaimIndicator: false,
        semiMonthlyPaymentIndicator: null,
        concurrentLeaveIDs: ['1561085'],
        sourceSystemId: 'AA',
        personId: '7055402',
        relatedClaims: [            {
          relatedClaimList: [],
          hasRelatedClaims: false
        }],
        rtwStatusCode: '42001',
        oowReason: 'Employee\'s own illness',
        relationship: null
      },
      {
        claimEventId: '1561059',
        claimType: 'Short Term Disability',
        claimSubType: 'Surgery',
        coverageType: 'NST',
        coverageCategory: 'WD',
        disabilityDate: '03/18/2020',
        effectiveDate: null,
        authorizedEndDate: null,
        claimStatus: 'Pend',
        claimStatusCode: '0',
        claimOnlineStatus: 'Under Review by Claim Handler',
        claimOnlineStatusCode: '08',
        claimOnlineSubStatus: 'In Adjudication',
        claimOnlineSubStatusCode: '12',
        extensionStatus: null,
        extensionStatusCode: null,
        extensionSubStatus: null,
        extensionSubStatusCode: null,
        claimOfficeNumber: null,
        claimOfficeId: null,
        caseId: '1099157',
        claimProcessDate: '03/31/2020',
        claimEffectiveDate: null,
        reason: 'New Claim',
        physicianStatementDate: null,
        employerStatementDate: null,
        employeeStatementDate: '02/24/2020',
        estimatedSurgeryDate: '04/03/2020',
        estimatedDeliveryDate: null,
        lastDayWork: '03/18/2020',
        asmIndicator: 'A',
        confirmedLastDayWorkDate: '03/18/2020',
        confirmedSurgeryDate: '04/03/2020',
        confirmedDeliveryDate: null,
        encIndicator: false,
        encSurgeryIndicator: false,
        fundingMethod: null,
        coveragePlanNumber: null,
        taskOpen: false,
        missingInfo: [],
        claimProgress: 'Under Review',
        extensionRequestDate: null,
        babyDeathIndicator: null,
        recentClaimIndicator: false,
        semiMonthlyPaymentIndicator: null,
        concurrentLeaveIDs: [],
        sourceSystemId: 'AA',
        personId: '7055402',
        relatedClaims: [            {
          relatedClaimList: [],
          hasRelatedClaims: false
        }],
        rtwStatusCode: '42001',
        oowReason: 'Employee\'s own illness',
        relationship: null
      }
      , {
        claimEventId: '1339436',
        claimType: 'Paid Family Leave',
        claimSubType: 'NY Paid Family Leave',
        coverageType: null,
        coverageCategory: 'PFL',
        disabilityDate: '08/01/2019',
        effectiveDate: null,
        authorizedEndDate: null,
        claimStatus: 'Open',
        claimStatusCode: '0',
        claimOnlineStatus: 'Awaiting Paid Family Leave',
        claimOnlineStatusCode: '09',
        claimOnlineSubStatus: 'Awaiting Paid Family Leave',
        claimOnlineSubStatusCode: '14',
        extensionStatus: null,
        extensionStatusCode: null,
        extensionSubStatus: null,
        extensionSubStatusCode: null,
        claimOfficeNumber: null,
        claimOfficeId: null,
        caseId: '0839213',
        isLeaveApplicable: true,
        claimProcessDate: '06/27/2019',
        claimEffectiveDate: null,
        reason: 'Certification',
        oowReason: 'Care of Family Member',
        physicianStatementDate: null,
        employerStatementDate: null,
        employeeStatementDate: '06/27/2019',
        estimatedSurgeryDate: null,
        estimatedDeliveryDate: null,
        lastDayWork: '07/24/2019',
        asmIndicator: 'P',
        confirmedLastDayWorkDate: null,
        confirmedSurgeryDate: null,
        confirmedDeliveryDate: null,
        encIndicator: true,
        encSurgeryIndicator: false,
        fundingMethod: 'ATP wCalc',
        coveragePlanNumber: null,
        taskOpen: false,
        missingInfo: [{
          mInfoCode: '02',
          mInfoDesc: 'Employer',
          requiredByDate: '',
          mInfoReqInfo: [{
            mInfoReqInfoCd: '',
            mInfoReqInfoDesc: '',
            requestDate: '7/1/2019',
            displayValue: 'Eligibility or Earnings Information',
            chatInd: false,
            callInd: false,
            uploadInd: false,
            detailInd: false,
            formType: 'generic',
            listPhysicain: [{
              physicianName: 'eqrew',
              fromDataRange: '7/2/2019',
              toDataRange: '7/3/2019'
            }]
          }],
          gracePeriod: false
        }],
        claimProgress: null,
        extensionRequestDate: null,
        babyDeathIndicator: null,
        recentClaimIndicator: false,
        concurrentLeaveIDs: ['200002'],
        sourceSystemId: 'DCS',
        personId: '8487380',
        relatedClaims: [{
          relatedClaimList: [{
            LinkedSTAT: null,
            LinkedSTD: null,
            LinkedLTD: null,
            LinkedABL: null,
            LinkedPFL: '1339437',
            LinkedCPFL: '1339437',
            LinkedB: null,
            LinkedP: null,
            LinkedSTDB: null,
            LinkedPW: null
          }],
          hasRelatedClaims: true
        }]
      },
      {
        claimEventId:  '1568978',
        claimType:  'Short Term Disability',
        claimSubType:  ' Maternity',
        coverageType:  'NST',
        coverageCategory:  'WD',
        disabilityDate:  '03/09/2020',
        effectiveDate:  null,
        authorizedEndDate:  '03/09/2020',
        claimStatus:  'Open',
        claimStatusCode:  '0',
        claimOnlineStatus: 'Under Review by Claim Handler',
        claimOnlineStatusCode: '08',
        claimOnlineSubStatus: 'Missing Information',
        claimOnlineSubStatusCode: '13',
        extensionStatus:  'Extension Under Review',
        extensionStatusCode:  '1',
        extensionSubStatus:  'Missing Information',
        extensionSubStatusCode:  '3',
        claimOfficeNumber:  null,
        claimOfficeId:  null,
        caseId:  '"1099157"',
        claimProcessDate:  '05/05/2020',
        claimEffectiveDate:  null,
        physicianStatementDate:  '03/13/2020',
        employerStatementDate:  '03/09/2020',
        employeeStatementDate:  '03/09/2020',
        estimatedSurgeryDate:  '05/05/2020',
        estimatedDeliveryDate:  '05/22/2020',
        lastDayWork:  '03/06/2020',
        asmIndicator:  'M',
        confirmedLastDayWorkDate:  '03/06/2020',
        confirmedSurgeryDate:  '05/05/2020',
        confirmedDeliveryDate:  null,
        encIndicator:  false,
        encSurgeryIndicator:  false,
        fundingMethod:  'ATP',
        sourceSystemId:  'DCS',
        coveragePlanNumber:  null,
        taskOpen:  false,
        missingInfo: [
          {
            mInfoCode: '02',
            mInfoDesc: 'Employer',
            requiredByDate: '',
            mInfoReqInfo: [{
              mInfoReqInfoCd: '12',
              mInfoReqInfoDesc: '',
              requestDate: '4/9/2019',
              displayValue: 'Salary Continuation, PTO, or Sick Time Details',
              chatInd: false,
              callInd: false,
              uploadInd: false,
              detailInd: true,
              formType: 'generic',
              listPhysicain: [{
                physicianName: 'Dr.ABC',
                fromDataRange: '3/21/2019',
                toDataRange: ''
              }]
            }],
            gracePeriod: false
          }, {
            mInfoCode: '01',
            mInfoDesc: 'Employee',
            requiredByDate: '',
            mInfoReqInfo: [{
              mInfoReqInfoCd: '01',
              mInfoReqInfoDesc: '',
              requestDate: '3/21/2019',
              displayValue: 'Medical History Form',
              chatInd: false,
              callInd: false,
              uploadInd: true,
              detailInd: false,
              formType: 'generic',
              listPhysicain: [{
                physicianName: 'Dr.XYZ',
                fromDataRange: '3/21/2019',
                toDataRange: ''
              }]
            }],
            gracePeriod: false
          }, {
            mInfoCode: '03',
            mInfoDesc: 'Physician',
            requiredByDate: '',
            mInfoReqInfo: [ {
              mInfoReqInfoCd: '07',
              mInfoReqInfoDesc: '',
              requestDate: '3/21/2019',
              displayValue: 'Cause of Disabling Condition',
              chatInd: false,
              callInd: false,
              uploadInd: false,
              detailInd: true,
              formType: 'generic',
              listPhysicain: [{
                physicianName: 'test',
                fromDataRange: '3/21/2019',
                toDataRange: '3/22/2019'
              }]
            }],
            gracePeriod: false
          }],
        claimProgress:  'In Payment',
        extensionRequestDate:  '5/11/2020',
        babyDeathIndicator:  null,
        recentClaimIndicator:  false,
        concurrentLeaveIDs: ['1337585'],
        orangeIconMissingInfo: true,
        physicianStatmentDate: '03/09/2020',
        reason: 'Client Request',
        personId:  '8485524',
        relatedClaims: [{
          relatedClaimList: [],
          hasRelatedClaims:  false
        }],
        rtwStatusCode: '42002',
        oowReason: 'Pregnancy',
        relationship: null,
        payeeType: null
      },
      {
        claimEventId:  '1337586',
        claimType:  'Short Term Disability',
        claimSubType:  ' Disability',
        coverageType:  'NST',
        coverageCategory:  'WD',
        disabilityDate:  '03/20/2021',
        effectiveDate:  null,
        authorizedEndDate:  null,
        claimStatus:  'Pend',
        claimStatusCode:  '0',
        claimOnlineStatus:  'Awaiting Disability',
        claimOnlineStatusCode:  '02',
        claimOnlineSubStatus:  'Awaiting Disability',
        claimOnlineSubStatusCode:  '02',
        extensionStatus:  null,
        extensionStatusCode:  null,
        extensionSubStatus:  null,
        extensionSubStatusCode:  null,
        claimOfficeNumber:  null,
        claimOfficeId:  null,
        caseId:  '0631121',
        claimProcessDate:  '05/14/2019',
        claimEffectiveDate:  null,
        reason:  'New Claim',
        oowReason: null,
        physicianStatementDate:  null,
        employerStatementDate:  null,
        employeeStatementDate:  '05/14/2019',
        estimatedSurgeryDate:  null,
        estimatedDeliveryDate:  null,
        lastDayWork:  '02/26/2021',
        asmIndicator:  'A',
        confirmedLastDayWorkDate:  null,
        confirmedSurgeryDate:  null,
        confirmedDeliveryDate:  null,
        encIndicator:  true,
        encSurgeryIndicator:  false,
        fundingMethod:  null,
        coveragePlanNumber:  null,
        taskOpen:  false,
        missingInfo: [],
        claimProgress:  'Awaiting Disability',
        extensionRequestDate:  null,
        babyDeathIndicator:  null,
        recentClaimIndicator:  false,
        concurrentLeaveIDs: ['1337585'],
        sourceSystemId:  'AA',
        personId:  '8485524',
        relatedClaims: [{
          relatedClaimList: [],
          hasRelatedClaims:  false
        }],
        rtwStatusCode:  null
      },
      {
        claimEventId:  '1337587',
        claimType:  'Premium Waiver',
        claimSubType:  ' Premium Waiver',
        coverageType:  '',
        coverageCategory:  'PW',
        disabilityDate:  '01/20/2021',
        effectiveDate:  null,
        authorizedEndDate:  null,
        claimStatus:  'Terminate',
        claimStatusCode:  '773503',
        claimOnlineStatus:  null,
        claimOnlineStatusCode:  null,
        claimOnlineSubStatus:  null,
        claimOnlineSubStatusCode:  null,
        extensionStatus:  null,
        extensionStatusCode:  null,
        extensionSubStatus:  null,
        extensionSubStatusCode:  null,
        claimOfficeNumber:  null,
        claimOfficeId:  null,
        caseId:  '0631121',
        claimProcessDate:  '03/14/2021',
        claimEffectiveDate:  null,
        reason:  'New Claim',
        oowReason: '',
        physicianStatementDate:  null,
        employerStatementDate:  null,
        employeeStatementDate:  null,
        estimatedSurgeryDate:  null,
        estimatedDeliveryDate:  null,
        lastDayWork:  '',
        asmIndicator:  null,
        confirmedLastDayWorkDate:  null,
        confirmedSurgeryDate:  null,
        confirmedDeliveryDate:  null,
        encIndicator:  false,
        encSurgeryIndicator:  false,
        fundingMethod:  null,
        coveragePlanNumber:  null,
        taskOpen:  false,
        missingInfo: [],
        claimProgress:  null,
        extensionRequestDate:  null,
        babyDeathIndicator:  null,
        recentClaimIndicator:  false,
        sourceSystemId:  'AA',
        personId:  '8485524',
        relatedClaims: [{
          relatedClaimList: [],
          hasRelatedClaims:  false
        }],
        rtwStatusCode:  null
      },
      {
        claimEventId:  '1568977',
        claimType:  'Short Term Disability',
        claimSubType:  ' Maternity',
        coverageType:  'NST',
        coverageCategory:  'WD',
        disabilityDate:  '03/09/2020',
        effectiveDate:  null,
        authorizedEndDate:  '03/09/2020',
        claimStatus:  'Open',
        claimStatusCode:  '0',
        claimOnlineStatus:  'Claim Approved',
        claimOnlineStatusCode:  '04',
        claimOnlineSubStatus:  'Payments in Progress',
        claimOnlineSubStatusCode:  '06',
        extensionStatus:  'Extension Under Review',
        extensionStatusCode:  '1',
        extensionSubStatus:  'Missing Information',
        extensionSubStatusCode:  '3',
        claimOfficeNumber:  null,
        claimOfficeId:  null,
        caseId:  '"1099157"',
        claimProcessDate:  '05/05/2020',
        claimEffectiveDate:  null,
        physicianStatementDate:  '03/13/2020',
        employerStatementDate:  '03/09/2020',
        employeeStatementDate:  '03/09/2020',
        estimatedSurgeryDate:  '05/05/2020',
        estimatedDeliveryDate:  '05/22/2020',
        lastDayWork:  '03/06/2020',
        asmIndicator:  'M',
        confirmedLastDayWorkDate:  '03/06/2020',
        confirmedSurgeryDate:  '05/05/2020',
        confirmedDeliveryDate:  null,
        encIndicator:  false,
        encSurgeryIndicator:  false,
        fundingMethod:  'ATP',
        coveragePlanNumber:  null,
        taskOpen:  false,
        missingInfo: [
          {
            mInfoCode: '10023364',
            mInfoDesc: 'Employer',
            requiredByDate: '',
            mInfoReqInfo: [{
              mInfoReqInfoCd: '12',
              mInfoReqInfoDesc: '',
              requestDate: '4/9/2019',
              displayValue: 'Salary Continuation, PTO, or Sick Time Details',
              chatInd: false,
              callInd: false,
              uploadInd: false,
              detailInd: true,
              formType: 'generic',
              listPhysicain: [{
                physicianName: 'Dr.ABC',
                fromDataRange: '3/21/2019',
                toDataRange: ''
              }]
            }],
            gracePeriod: false
          }, {
            mInfoCode: '10023366',
            mInfoDesc: 'Employee',
            requiredByDate: '',
            mInfoReqInfo: [{
              mInfoReqInfoCd: '01',
              mInfoReqInfoDesc: '',
              requestDate: '3/21/2019',
              displayValue: 'Thaa Form',
              chatInd: false,
              callInd: false,
              uploadInd: true,
              detailInd: false,
              formType: 'generic',
              listPhysicain: [{
                physicianName: 'Dr.XYZ',
                fromDataRange: '3/21/2019',
                toDataRange: ''
              }]
            }],
            gracePeriod: false
          }, {
            mInfoCode: '10023362',
            mInfoDesc: 'Physician',
            requiredByDate: '',
            mInfoReqInfo: [ {
              mInfoReqInfoCd: '07',
              mInfoReqInfoDesc: '',
              requestDate: '3/21/2019',
              displayValue: 'Cause of Disabling Condition',
              chatInd: false,
              callInd: false,
              uploadInd: false,
              detailInd: true,
              formType: 'generic',
              listPhysicain: [{
                physicianName: 'test',
                fromDataRange: '3/21/2019',
                toDataRange: '3/22/2019'
              }]
            }],
            gracePeriod: false
          }],
        claimProgress:  'In Payment',
        extensionRequestDate:  '5/11/2020',
        babyDeathIndicator:  null,
        recentClaimIndicator:  false,
        concurrentLeaveIDs: ['1337585'],
        orangeIconMissingInfo: true,
        sourceSystemId:  'AA',
        physicianStatmentDate: '03/09/2020',
        reason: 'Client Request',
        personId:  '8485524',
        relatedClaims: [{
          relatedClaimList: [],
          hasRelatedClaims:  false
        }],
        rtwStatusCode: '42002',
        oowReason: 'Pregnancy',
        relationship: null,
        payeeType: null
      },
      {
        claimEventId:  '1337587',
        claimType:  'Short Term Disability',
        claimSubType:  ' Disability',
        coverageType:  'NST',
        coverageCategory:  'WD',
        disabilityDate:  '02/27//2020',
        effectiveDate:  null,
        authorizedEndDate:  null,
        claimStatus:  'Pend',
        claimStatusCode:  '0',
        claimOnlineStatus:  'Awaiting Disability',
        claimOnlineStatusCode:  '02',
        claimOnlineSubStatus:  'Awaiting Disability',
        claimOnlineSubStatusCode:  '02',
        extensionStatus:  null,
        extensionStatusCode:  null,
        extensionSubStatus:  null,
        extensionSubStatusCode:  null,
        claimOfficeNumber:  null,
        claimOfficeId:  null,
        caseId:  '0631121',
        claimProcessDate:  '05/14/2019',
        claimEffectiveDate:  null,
        reason:  'New Claim',
        oowReason: null,
        physicianStatementDate:  null,
        employerStatementDate:  null,
        employeeStatementDate:  '05/14/2019',
        estimatedSurgeryDate:  null,
        estimatedDeliveryDate:  '07/21/2019',
        lastDayWork:  '05/21/2020',
        asmIndicator:  'A',
        confirmedLastDayWorkDate:  null,
        confirmedSurgeryDate:  null,
        confirmedDeliveryDate:  null,
        encIndicator:  true,
        encSurgeryIndicator:  false,
        fundingMethod:  null,
        coveragePlanNumber:  null,
        taskOpen:  false,
        missingInfo: [],
        claimProgress:  'Awaiting Disability',
        extensionRequestDate:  null,
        babyDeathIndicator:  null,
        recentClaimIndicator:  false,
        concurrentLeaveIDs: ['1337585'],
        sourceSystemId:  'DCS',
        personId:  '8485524',
        relatedClaims: [{
          relatedClaimList: [],
          hasRelatedClaims:  false
        }],
        rtwStatusCode:  null
      },
      {
        LTDmissingInfo: false,
        asmIndicator: 'P',
        authorizedEndDate: '8/1/2019',
        babyDeathIndicator: null,
        caseId: '0839213',
        claimEffectiveDate: null,
        claimEventId: '1448104',
        claimOfficeId: null,
        claimOfficeNumber: null,
        claimOnlineStatus: 'CLaim denied',
        claimOnlineStatusCode: '06',
        claimOnlineSubStatus: 'In Adjudication',
        claimOnlineSubStatusCode: '10',
        claimProcessDate: '06/06/2019',
        claimProgress: null,
        claimStatus: 'Open',
        claimStatusCode: '0',
        claimSubType: 'WA Paid Family Leave',
        claimType: 'Paid Family Leave',
        concurrentLeaveIDs: ['1338103'],
        confirmedDeliveryDate: null,
        confirmedLastDayWorkDate: '7/2/2019',
        confirmedSurgeryDate: null,
        coverageCategory: 'PFL',
        coveragePlanNumber: null,
        coverageType: null,
        disabilityDate: '06/13/2019',
        effectiveDate: null,
        employeeStatementDate: '05/29/2019',
        employerStatementDate: null,
        encIndicator: false,
        encSurgeryIndicator: false,
        estimatedDeliveryDate: null,
        estimatedSurgeryDate: null,
        extensionRequestDate: null,
        extensionStatus: null,
        extensionStatusCode: null,
        extensionSubStatus: null,
        extensionSubStatusCode: null,
        fundingMethod: 'ATP wCalc',
        lastDayWork: '06/29/2019',
        missingInfo: [],
        payeeType: null,
        personId: '8486784',
        physicianStatementDate: null,
        reason: 'Certification',
        oowReason: null,
        recentClaimIndicator: false,
        relatedClaims: [{relatedClaimList: [], hasRelatedClaims: false}],
        sourceSystemId: 'AA',
        taskOpen: false
      },
      {
        claimEventId: '1651199',
        claimType: 'Long Term Disability',
        claimSubType: 'Long Term Disability',
        coverageType: null,
        coverageCategory: 'LTD',
        disabilityDate: '01/09/2020',
        effectiveDate: null,
        authorizedEndDate: null,
        claimStatus: 'Pend',
        claimStatusCode: '0',
        claimOnlineStatus: null,
        claimOnlineStatusCode: null,
        claimOnlineSubStatus: null,
        claimOnlineSubStatusCode: null,
        extensionStatus: null,
        extensionStatusCode: null,
        extensionSubStatus: null,
        extensionSubStatusCode: null,
        claimOfficeNumber: null,
        claimOfficeId: null,
        caseId: '0516115',
        claimProcessDate: '12/02/2020',
        claimEffectiveDate: null,
        reason: 'New Claim',
        physicianStatementDate: '07/28/2020',
        employerStatementDate: '07/28/2020',
        employeeStatementDate: '07/28/2020',
        estimatedSurgeryDate: null,
        estimatedDeliveryDate: null,
        lastDayWork: '01/08/2020',
        asmIndicator: 'A',
        confirmedLastDayWorkDate: '01/08/2020',
        confirmedSurgeryDate: null,
        confirmedDeliveryDate: null,
        encIndicator: false,
        encSurgeryIndicator: false,
        fundingMethod: 'FI',
        coveragePlanNumber: null,
        taskOpen: false,
        missingInfo: [
          {
            mInfoCode: '10023364',
            mInfoDesc: 'Employer',
            requiredByDate: '',
            mInfoReqInfo: [{
              mInfoReqInfoCd: '12',
              mInfoReqInfoDesc: '',
              requestDate: '4/9/2019',
              displayValue: 'Salary Continuation, PTO, or Sick Time Details',
              chatInd: false,
              callInd: false,
              uploadInd: false,
              detailInd: true,
              formType: 'generic',
              listPhysicain: [{
                physicianName: 'Dr.ABC',
                fromDataRange: '3/21/2019',
                toDataRange: ''
              }]
            }],
            gracePeriod: false
          }, {
            mInfoCode: '10023366',
            mInfoDesc: 'Employee',
            requiredByDate: '',
            mInfoReqInfo: [{
              mInfoReqInfoCd: '01',
              mInfoReqInfoDesc: '',
              requestDate: '3/21/2019',
              displayValue: 'Thaa Form',
              chatInd: false,
              callInd: false,
              uploadInd: true,
              detailInd: false,
              formType: 'generic',
              listPhysicain: [{
                physicianName: 'Dr.XYZ',
                fromDataRange: '3/21/2019',
                toDataRange: ''
              }]
            }],
            gracePeriod: false
          }, {
            mInfoCode: '10023362',
            mInfoDesc: 'Physician',
            requiredByDate: '',
            mInfoReqInfo: [ {
              mInfoReqInfoCd: '07',
              mInfoReqInfoDesc: '',
              requestDate: '3/21/2019',
              displayValue: 'Cause of Disabling Condition',
              chatInd: false,
              callInd: false,
              uploadInd: false,
              detailInd: true,
              formType: 'generic',
              listPhysicain: [{
                physicianName: 'test',
                fromDataRange: '3/21/2019',
                toDataRange: '3/22/2019'
              }]
            }],
            gracePeriod: false
          }],
        claimProgress: null,
        extensionRequestDate: null,
        babyDeathIndicator: null,
        recentClaimIndicator: false,
        semiMonthlyPaymentIndicator: null,
        concurrentLeaveIDs: [
        ],
        sourceSystemId: 'AA',
        personId: '8678298',
        relatedClaims: [
          {
            relatedClaimList: [
            ],
            hasRelatedClaims: false
          }
        ],
        rtwStatusCode: '42001',
        oowReason: 'Employee\'s own illness',
        form: '02',
        leaveFirstName: '',
        leaveLastName: '',
        leaveReason: '',
        relationship: null,
        insuredFirstName: 'WILMA',
        insuredLastName: 'KAZZZRNS'
      },
      {
        LTDmissingInfo: false,
        asmIndicator: 'P',
        authorizedEndDate: '7/1/2019',
        babyDeathIndicator: null,
        caseId: '0839213',
        claimEffectiveDate: null,
        claimEventId: '1448103',
        claimOfficeId: null,
        claimOfficeNumber: null,
        claimOnlineStatus: 'CLaim Approved',
        claimOnlineStatusCode: '04',
        claimOnlineSubStatus: 'Payment Complete',
        claimOnlineSubStatusCode: '04',
        claimProcessDate: '06/06/2019',
        claimProgress: null,
        claimStatus: 'Open',
        claimStatusCode: '0',
        claimSubType: 'NY Paid Family Leave',
        claimType: 'Paid Family Leave',
        concurrentLeaveIDs: ['1338103'],
        confirmedDeliveryDate: null,
        confirmedLastDayWorkDate: '7/2/2019',
        confirmedSurgeryDate: null,
        coverageCategory: 'PFL',
        coveragePlanNumber: null,
        coverageType: null,
        disabilityDate: '06/13/2019',
        effectiveDate: null,
        employeeStatementDate: '05/29/2019',
        employerStatementDate: null,
        encIndicator: false,
        encSurgeryIndicator: false,
        estimatedDeliveryDate: null,
        estimatedSurgeryDate: null,
        extensionRequestDate: null,
        extensionStatus: null,
        extensionStatusCode: null,
        extensionSubStatus: null,
        extensionSubStatusCode: null,
        fundingMethod: 'ATP wCalc',
        lastDayWork: '06/29/2019',
        missingInfo: [],
        payeeType: null,
        personId: '8486784',
        physicianStatementDate: null,
        reason: 'Certification',
        oowReason: null,
        recentClaimIndicator: false,
        relatedClaims: [{relatedClaimList: [], hasRelatedClaims: false}],
        sourceSystemId: 'AA',
        taskOpen: false
      },
      {
        LTDmissingInfo: false,
        asmIndicator: 'P',
        authorizedEndDate: '7/1/2019',
        babyDeathIndicator: null,
        caseId: '0839213',
        claimEffectiveDate: null,
        claimEventId: '1338102',
        claimOfficeId: null,
        claimOfficeNumber: null,
        claimOnlineStatus: 'Under Review by Claim Handler',
        claimOnlineStatusCode: '08',
        claimOnlineSubStatus: 'In Adjudication',
        claimOnlineSubStatusCode: '12',
        claimProcessDate: '06/06/2019',
        claimProgress: null,
        claimStatus: 'Open',
        claimStatusCode: '0',
        claimSubType: 'CA Paid Family Leave',
        claimType: 'Paid Family Leave',
        concurrentLeaveIDs: ['1338103'],
        confirmedDeliveryDate: null,
        confirmedLastDayWorkDate: '7/2/2019',
        confirmedSurgeryDate: null,
        coverageCategory: 'PFL',
        coveragePlanNumber: null,
        coverageType: null,
        disabilityDate: '06/13/2019',
        effectiveDate: null,
        employeeStatementDate: '05/29/2019',
        employerStatementDate: null,
        encIndicator: false,
        encSurgeryIndicator: false,
        estimatedDeliveryDate: null,
        estimatedSurgeryDate: null,
        extensionRequestDate: null,
        extensionStatus: null,
        extensionStatusCode: null,
        extensionSubStatus: null,
        extensionSubStatusCode: null,
        fundingMethod: 'ATP wCalc',
        lastDayWork: '06/29/2019',
        missingInfo: [],
        payeeType: null,
        personId: '8486784',
        physicianStatementDate: null,
        reason: 'Certification',
        oowReason: null,
        recentClaimIndicator: false,
        relatedClaims: [{relatedClaimList: [], hasRelatedClaims: false}],
        sourceSystemId: 'AA',
        taskOpen: false
      },
      {
        claimEventId: '2166233',
        claimType: 'Paid Family Leave',
        claimSubType: 'NY Paid Family Leave',
        coverageType: null,
        coverageCategory: 'PFL',
        disabilityDate: '02/02/2021',
        effectiveDate: null,
        authorizedEndDate: '02/09/2021',
        claimStatus: 'Open',
        claimStatusCode: '0',
        claimOnlineStatus: 'Claim Approved',
        claimOnlineStatusCode: '04',
        claimOnlineSubStatus: 'Payments in Progress',
        claimOnlineSubStatusCode: '04',
        extensionStatus: null,
        extensionStatusCode: null,
        extensionSubStatus: null,
        extensionSubStatusCode: null,
        claimOfficeNumber: null,
        claimOfficeId: null,
        caseId: '0516115',
        claimProcessDate: '03/19/2021',
        claimEffectiveDate: null,
        reason: 'Administrative',
        physicianStatementDate: null,
        employerStatementDate: null,
        employeeStatementDate: '03/18/2021',
        estimatedSurgeryDate: null,
        estimatedDeliveryDate: null,
        lastDayWork: '02/01/2021',
        asmIndicator: 'P',
        confirmedLastDayWorkDate: '02/01/2021',
        confirmedSurgeryDate: null,
        confirmedDeliveryDate: null,
        encIndicator: false,
        encSurgeryIndicator: false,
        fundingMethod: 'FI',
        coveragePlanNumber: null,
        taskOpen: false,
        missingInfo: [
          {
            mInfoCode: '10023364',
            mInfoDesc: 'Employer',
            requiredByDate: '',
            mInfoReqInfo: [{
              mInfoReqInfoCd: '12',
              mInfoReqInfoDesc: '',
              requestDate: '4/9/2019',
              displayValue: 'Salary Continuation, PTO, or Sick Time Details',
              chatInd: false,
              callInd: false,
              uploadInd: false,
              detailInd: true,
              formType: 'generic',
              listPhysicain: [{
                physicianName: 'Dr.ABC',
                fromDataRange: '3/21/2019',
                toDataRange: ''
              }]
            }],
            gracePeriod: false
          }, {
            mInfoCode: '10023366',
            mInfoDesc: 'Employee',
            requiredByDate: '',
            mInfoReqInfo: [{
              mInfoReqInfoCd: '01',
              mInfoReqInfoDesc: '',
              requestDate: '3/21/2019',
              displayValue: 'Thaa Form',
              chatInd: false,
              callInd: false,
              uploadInd: true,
              detailInd: false,
              formType: 'generic',
              listPhysicain: [{
                physicianName: 'Dr.XYZ',
                fromDataRange: '3/21/2019',
                toDataRange: ''
              }]
            }],
            gracePeriod: false
          }, {
            mInfoCode: '10023362',
            mInfoDesc: 'Physician',
            requiredByDate: '',
            mInfoReqInfo: [ {
              mInfoReqInfoCd: '07',
              mInfoReqInfoDesc: '',
              requestDate: '3/21/2019',
              displayValue: 'Cause of Disabling Condition',
              chatInd: false,
              callInd: false,
              uploadInd: false,
              detailInd: true,
              formType: 'generic',
              listPhysicain: [{
                physicianName: 'test',
                fromDataRange: '3/21/2019',
                toDataRange: '3/22/2019'
              }]
            }],
            gracePeriod: false
          }],
        claimProgress: 'In Payment',
        extensionRequestDate: null,
        babyDeathIndicator: null,
        recentClaimIndicator: false,
        semiMonthlyPaymentIndicator: null,
        concurrentLeaveIDs: [
          '2166234'
        ],
        sourceSystemId: 'AA',
        personId: '11173359',
        relatedClaims: [
          {
            relatedClaimList: [
            ],
            hasRelatedClaims: false
          }
        ],
        rtwStatusCode: '42002',
        oowReason: 'Bonding/Adoption/Foster Care',
        form: '02'
      },
      {
        LTDmissingInfo: false,
        asmIndicator: 'P',
        confirmReturnToWork: '1',
        authorizedEndDate: '03/17/2021',
        babyDeathIndicator: null,
        caseId: '0516115',
        claimEffectiveDate: null,
        claimEventId: '1122334',
        claimOfficeId: null,
        claimOfficeNumber: null,
        claimOnlineStatus: 'Claim Approved',
        claimOnlineStatusCode: '04',
        claimOnlineSubStatus: 'In Payment',
        claimOnlineSubStatusCode: '06',
        claimProcessDate: '03/01/2021',
        claimProgress: 'Claim Approved',
        claimStatus: 'Open',
        claimStatusCode: '0',
        claimSubType: 'CA Paid Family Leave',
        claimType: 'Paid Family Leave',
        concurrentLeaveIDs: [],
        confirmedDeliveryDate: null,
        confirmedLastDayWorkDate: '02/28/2021',
        confirmedSurgeryDate: null,
        coverageCategory: 'PFL',
        coveragePlanNumber: null,
        coverageType: null,
        disabilityDate: '03/13/2021',
        effectiveDate: null,
        employeeStatementDate: '12/29/2020',
        employerStatementDate: null,
        encIndicator: false,
        encSurgeryIndicator: false,
        estimatedDeliveryDate: null,
        estimatedSurgeryDate: null,
        extensionRequestDate: null,
        extensionStatus: null,
        extensionStatusCode: null,
        extensionSubStatus: null,
        extensionSubStatusCode: null,
        fundingMethod: 'ATP wCalc',
        insuredFirstName: 'Danny',
        insuredLastName: 'Welch',
        leaveFirstName: '',
        leaveLastName: '',
        leaveReason: 'Care of Family Member',
        lastDayWork: '03/15/2020',
        missingInfo: [],
        payeeType: null,
        personId: '7469010',
        physicianStatementDate: null,
        reason: 'Certification',
        relationship: 'Son',
        oowReason: null,
        recentClaimIndicator: false,
        relatedClaims: [{relatedClaimList: [], hasRelatedClaims: false}],
        sourceSystemId: 'AA',
        taskOpen: false
      },
      {
        asmIndicator: 'P',
        authorizedEndDate: null,
        babyDeathIndicator: null,
        caseId: '0516115',
        claimEffectiveDate: null,
        claimEventId: '1799733',
        claimOfficeId: null,
        claimOfficeNumber: null,
        claimOnlineStatus: 'Claim Denied',
        claimOnlineStatusCode: '06',
        claimOnlineSubStatus: 'Claim Denied',
        claimOnlineSubStatusCode: '10',
        claimProcessDate: '01/25/2021',
        claimProgress: 'Claim Denied',
        claimStatus: 'Closed',
        claimStatusCode: '0',
        claimSubType: 'NY Paid Family Leave',
        claimType: 'Paid Family Leave',
        concurrentLeaveIDs: ['1799734'],
        confirmedDeliveryDate: null,
        confirmedLastDayWorkDate: '01/04/2021',
        confirmedSurgeryDate: null,
        coverageCategory: 'PFL',
        coveragePlanNumber: null,
        coverageType: null,
        disabilityDate: '01/05/2021',
        effectiveDate: null,
        employeeStatementDate: '01/25/2021',
        employerStatementDate: null,
        encIndicator: false,
        encSurgeryIndicator: false,
        estimatedDeliveryDate: null,
        estimatedSurgeryDate: null,
        extensionRequestDate: null,
        extensionStatus: null,
        extensionStatusCode: null,
        extensionSubStatus: null,
        extensionSubStatusCode: null,
        form: '02',
        fundingMethod: 'ATP wCalc',
        insuredFirstName: 'Danny',
        insuredLastName: 'Welch',
        leaveFirstName: 'Thomas',
        leaveLastName: 'Welch',
        leaveReason: 'Bond with New Child',
        lastDayWork: '01/04/2021',
        missingInfo: [],
        oowReason: null,
        personId: '7469010',
        physicianStatementDate: null,
        reason: 'Return to Work',
        recentClaimIndicator: false,
        relatedClaims: [{relatedClaimList: [], hasRelatedClaims: false}],
        relationship: 'Son',
        hasRelatedClaims: false,
        relatedClaimList: [],
        rtwStatusCode: '42004',
        semiMonthlyPaymentIndicator: null,
        sourceSystemId: 'AA',
        taskOpen: false
      },
      {
        asmIndicator: 'P',
        authorizedEndDate: null,
        babyDeathIndicator: null,
        caseId: '0516115',
        claimEffectiveDate: null,
        claimEventId: '1122330',
        claimOfficeId: null,
        claimOfficeNumber: null,
        claimOnlineStatus: 'Claim Denied',
        claimOnlineStatusCode: '06',
        claimOnlineSubStatus: 'Claim Denied',
        claimOnlineSubStatusCode: '10',
        claimProcessDate: '01/25/2021',
        claimProgress: 'Claim Denied',
        claimStatus: 'Closed',
        claimStatusCode: '0',
        claimSubType: 'WA Paid Family Leave',
        claimType: 'Paid Family Leave',
        concurrentLeaveIDs: ['1799734'],
        confirmedDeliveryDate: null,
        confirmedLastDayWorkDate: '01/04/2021',
        confirmedSurgeryDate: null,
        coverageCategory: 'PFL',
        coveragePlanNumber: null,
        coverageType: null,
        disabilityDate: '01/05/2021',
        effectiveDate: null,
        employeeStatementDate: '01/25/2021',
        employerStatementDate: null,
        encIndicator: false,
        encSurgeryIndicator: false,
        estimatedDeliveryDate: null,
        estimatedSurgeryDate: null,
        extensionRequestDate: null,
        extensionStatus: null,
        extensionStatusCode: null,
        extensionSubStatus: null,
        extensionSubStatusCode: null,
        form: '02',
        fundingMethod: 'ATP wCalc',
        insuredFirstName: '',
        insuredLastName: '',
        leaveFirstName: '',
        leaveLastName: '',
        leaveReason: 'Bond with New Child',
        lastDayWork: '01/04/2021',
        missingInfo: [],
        oowReason: null,
        personId: '7469010',
        physicianStatementDate: null,
        reason: 'Return to Work',
        recentClaimIndicator: false,
        relatedClaims: [{relatedClaimList: [], hasRelatedClaims: false}],
        relationship: '',
        hasRelatedClaims: false,
        relatedClaimList: [],
        rtwStatusCode: '42004',
        semiMonthlyPaymentIndicator: null,
        sourceSystemId: 'AA',
        taskOpen: false
      },
      {
        asmIndicator: 'P',
        authorizedEndDate: null,
        babyDeathIndicator: null,
        caseId: '0516115',
        claimEffectiveDate: null,
        claimEventId: '1800423',
        claimOfficeId: null,
        claimOfficeNumber: null,
        claimOnlineStatus: 'Claim Closed',
        claimOnlineStatusCode: '05',
        claimOnlineSubStatus: 'Claim Closed',
        claimOnlineSubStatusCode: '08',
        claimProcessDate: '01/27/2021',
        claimProgress: 'Claim Closed',
        claimStatus: 'Closed',
        claimStatusCode: '0',
        claimSubType: 'Company Paid Family Leave',
        claimType: 'Paid Family Leave',
        concurrentLeaveIDs: ['1800424'],
        confirmedDeliveryDate: null,
        confirmedLastDayWorkDate: '12/21/2020',
        confirmedSurgeryDate: null,
        coverageCategory: 'PFL',
        coveragePlanNumber: null,
        coverageType: null,
        disabilityDate: '12/12/2020',
        effectiveDate: null,
        employeeStatementDate: '01/27/2021',
        employerStatementDate: null,
        encIndicator: false,
        encSurgeryIndicator: false,
        estimatedDeliveryDate: null,
        estimatedSurgeryDate: null,
        extensionRequestDate: null,
        extensionStatus: null,
        extensionStatusCode: null,
        extensionSubStatus: null,
        extensionSubStatusCode: null,
        form: '02',
        fundingMethod: 'FI',
        insuredFirstName: 'Danny',
        insuredLastName: 'Welch',
        leaveFirstName: 'James',
        leaveLastName: 'Welch',
        leaveReason: 'Bond with New Child',
        lastDayWork: '12/21/2021',
        missingInfo: [],
        oowReason: null,
        personId: '7469010',
        physicianStatementDate: null,
        reason: 'Administrative',
        recentClaimIndicator: false,
        relatedClaims: [{relatedClaimList: [], hasRelatedClaims: false}],
        relationship: 'Son',
        hasRelatedClaims: false,
        relatedClaimList: [],
        rtwStatusCode: '42003',
        semiMonthlyPaymentIndicator: null,
        sourceSystemId: 'AA',
        taskOpen: false
      },
      {
        claimEventId: '1122337',
        claimType: 'Short Term Disability',
        confirmReturnToWork: '1',
        claimSubType: 'State-Mandated Disability/Maternity',
        coverageType: 'STAT',
        coverageCategory: 'WD',
        disabilityDate: '01/19/2021',
        effectiveDate: null,
        authorizedEndDate: '01/19/2021',
        claimStatus: 'Pend',
        claimStatusCode: '0',
        claimOnlineStatus: 'Claim Approved',
        claimOnlineStatusCode: '04',
        claimOnlineSubStatus: 'In Payment',
        claimOnlineSubStatusCode: '04',
        extensionStatus: null,
        extensionStatusCode: null,
        extensionSubStatus: null,
        extensionSubStatusCode: null,
        insuredFirstName: 'Danny',
        insuredLastName: 'Welch',
        claimOfficeNumber: null,
        claimOfficeId: null,
        caseId: '0516115',
        claimProcessDate: '02/05/2021',
        claimEffectiveDate: null,
        reason: 'Additional Medical',
        physicianStatementDate: null,
        employerStatementDate: null,
        employeeStatementDate: '02/05/2021',
        estimatedSurgeryDate: null,
        estimatedDeliveryDate: null,
        lastDayWork: '01/18/2021',
        asmIndicator: 'A',
        confirmedLastDayWorkDate: '01/18/2021',
        confirmedSurgeryDate: null,
        confirmedDeliveryDate: null,
        encIndicator: false,
        encSurgeryIndicator: false,
        fundingMethod: 'ATP',
        coveragePlanNumber: null,
        taskOpen: false,
        missingInfo: [],
        claimProgress: 'Claim Approved',
        extensionRequestDate: null,
        babyDeathIndicator: null,
        recentClaimIndicator: false,
        semiMonthlyPaymentIndicator: null,
        concurrentLeaveIDs: [],
        sourceSystemId: 'AA',
        personId: '7470234',
        relatedClaims: [],
        rtwStatusCode: '42001',
        oowReason: 'Employee\'s own illness',
        relationship: null
      },
      {
        claimEventId: '2136414',
        claimType: 'Paid Family Leave',
        claimSubType: 'San Francisco Paid Family Leave',
        coverageType: null,
        coverageCategory: 'PFL',
        disabilityDate: '02/02/2021',
        effectiveDate: null,
        authorizedEndDate: '02/04/2021',
        claimStatus: 'Pend',
        claimStatusCode: '0',
        claimOnlineStatus: 'Under Review by Claim Handler',
        claimOnlineStatusCode: '08',
        claimOnlineSubStatus: 'In Adjudication',
        claimOnlineSubStatusCode: '12',
        extensionStatus: null,
        extensionStatusCode: null,
        extensionSubStatus: null,
        extensionSubStatusCode: null,
        claimOfficeNumber: null,
        claimOfficeId: null,
        caseId: '0516115',
        claimProcessDate: '03/17/2021',
        claimEffectiveDate: null,
        reason: 'Employee',
        physicianStatementDate: null,
        employerStatementDate: null,
        employeeStatementDate: '03/05/2021',
        estimatedSurgeryDate: null,
        estimatedDeliveryDate: null,
        lastDayWork: '02/01/2021',
        asmIndicator: 'P',
        confirmedLastDayWorkDate: '02/01/2021',
        confirmedSurgeryDate: null,
        confirmedDeliveryDate: null,
        encIndicator: false,
        encSurgeryIndicator: false,
        fundingMethod: 'FI',
        coveragePlanNumber: null,
        taskOpen: false,
        missingInfo: [
        ],
        claimProgress: 'Under Review',
        extensionRequestDate: null,
        babyDeathIndicator: null,
        recentClaimIndicator: false,
        semiMonthlyPaymentIndicator: null,
        concurrentLeaveIDs: [
          '2136415'
        ],
        sourceSystemId: 'AA',
        personId: '11165071',
        relatedClaims: [
          {
            relatedClaimList: [
            ],
            hasRelatedClaims: false
          }
        ],
        rtwStatusCode: '42001',
        oowReason: 'Bonding/Adoption/Foster Care',
        form: '02',
        leaveFirstName: '',
        leaveLastName: '',
        leaveReason: 'Bond with new child',
        relationship: 'Newborn',
        insuredFirstName: 'DOROTHY',
        insuredLastName: 'WALLACE'
      },
      {
        claimEventId: '1122353',
        claimType: 'Paid Family Leave',
        claimSubType: 'NY PAid Family Leave',
        coverageType: null,
        confirmReturnToWork: '1',
        coverageCategory: 'PFL',
        disabilityDate: '01/19/2021',
        effectiveDate: null,
        authorizedEndDate: '03/17/2021',
        claimStatus: 'Pend',
        claimStatusCode: '0',
        claimOnlineStatus: 'Under Review by Claim Handler',
        claimOnlineStatusCode: '08',
        claimOnlineSubStatus: 'In Adjudication',
        claimOnlineSubStatusCode: '12',
        extensionStatus: null,
        extensionStatusCode: null,
        extensionSubStatus: null,
        extensionSubStatusCode: null,
        insuredFirstName: 'Danny',
        insuredLastName: 'Welch',
        claimOfficeNumber: null,
        claimOfficeId: null,
        caseId: '0516115',
        claimProcessDate: '02/05/2021',
        claimEffectiveDate: null,
        reason: 'Additional Medical',
        physicianStatementDate: null,
        employerStatementDate: null,
        employeeStatementDate: '02/05/2021',
        estimatedSurgeryDate: null,
        estimatedDeliveryDate: null,
        lastDayWork: '01/18/2021',
        asmIndicator: 'A',
        confirmedLastDayWorkDate: '01/18/2021',
        confirmedSurgeryDate: null,
        confirmedDeliveryDate: null,
        encIndicator: false,
        encSurgeryIndicator: false,
        fundingMethod: 'ATP',
        coveragePlanNumber: null,
        taskOpen: false,
        missingInfo: [],
        claimProgress: 'Under Review',
        extensionRequestDate: null,
        babyDeathIndicator: null,
        recentClaimIndicator: false,
        semiMonthlyPaymentIndicator: null,
        concurrentLeaveIDs: [],
        sourceSystemId: 'AA',
        personId: '7470234',
        relatedClaims: [],
        rtwStatusCode: '42001',
        oowReason: 'Employee\'s own illness',
        relationship: null
      },
      {
        claimEventId: '1122338',
        claimType: 'Short Term Disability',
        claimSubType: 'State-Mandated Disability/Surgery',
        coverageType: 'STAT',
        confirmReturnToWork: '2',
        coverageCategory: 'WD',
        disabilityDate: '01/19/2021',
        effectiveDate: null,
        authorizedEndDate: '03/17/2021',
        claimStatus: 'Pend',
        claimStatusCode: '0',
        claimOnlineStatus: 'Under Review by Claim Handler',
        claimOnlineStatusCode: '08',
        claimOnlineSubStatus: 'In Adjudication',
        claimOnlineSubStatusCode: '12',
        extensionStatus: null,
        extensionStatusCode: null,
        extensionSubStatus: null,
        extensionSubStatusCode: null,
        insuredFirstName: 'Danny',
        insuredLastName: 'Welch',
        claimOfficeNumber: null,
        claimOfficeId: null,
        caseId: '0516115',
        claimProcessDate: '02/05/2021',
        claimEffectiveDate: null,
        reason: 'Additional Medical',
        physicianStatementDate: null,
        employerStatementDate: null,
        employeeStatementDate: '02/05/2021',
        estimatedSurgeryDate: null,
        estimatedDeliveryDate: null,
        lastDayWork: '01/18/2021',
        asmIndicator: 'A',
        confirmedLastDayWorkDate: '01/18/2021',
        confirmedSurgeryDate: null,
        confirmedDeliveryDate: null,
        encIndicator: false,
        encSurgeryIndicator: false,
        fundingMethod: 'ATP',
        coveragePlanNumber: null,
        taskOpen: false,
        missingInfo: [],
        claimProgress: 'Under Review',
        extensionRequestDate: null,
        babyDeathIndicator: null,
        recentClaimIndicator: false,
        semiMonthlyPaymentIndicator: null,
        concurrentLeaveIDs: [],
        sourceSystemId: 'AA',
        personId: '7470234',
        relatedClaims: [],
        rtwStatusCode: '42001',
        oowReason: 'Employee\'s own illness',
        relationship: null
      },
      {
        claimEventId: '1122339',
        claimType: 'Short Term Disability',
        confirmReturnToWork: '1',
        claimSubType: 'State-Mandated Disability',
        coverageType: 'STAT',
        coverageCategory: 'WD',
        disabilityDate: '04/19/2021',
        effectiveDate: null,
        authorizedEndDate: '04/18/2021',
        claimStatus: 'Pend',
        claimStatusCode: '0',
        claimOnlineStatus: 'Under Review by Claim Handler',
        claimOnlineStatusCode: '08',
        claimOnlineSubStatus: 'In Adjudication',
        claimOnlineSubStatusCode: '12',
        extensionStatus: null,
        extensionStatusCode: null,
        extensionSubStatus: null,
        extensionSubStatusCode: null,
        insuredFirstName: 'Danny',
        insuredLastName: 'Welch',
        claimOfficeNumber: null,
        claimOfficeId: null,
        caseId: '0516115',
        claimProcessDate: '04/05/2021',
        claimEffectiveDate: null,
        reason: 'Additional Medical',
        physicianStatementDate: null,
        employerStatementDate: null,
        employeeStatementDate: '04/05/2021',
        estimatedSurgeryDate: null,
        estimatedDeliveryDate: null,
        lastDayWork: '04/18/2021',
        asmIndicator: 'A',
        confirmedLastDayWorkDate: '04/18/2021',
        confirmedSurgeryDate: null,
        confirmedDeliveryDate: null,
        encIndicator: false,
        encSurgeryIndicator: false,
        fundingMethod: 'ATP',
        coveragePlanNumber: null,
        taskOpen: false,
        missingInfo: [
          {
            mInfoCode: '10023362',
            mInfoDesc: null,
            requiredByDate: null,
            mInfoReqInfo: [
              {
                mInfoReqInfoCd: '3308',
                mInfoReqInfoDesc: null,
                requestDate: null,
                displayValue: 'MEDICAL TWO',
                chatInd: false,
                callInd: false,
                uploadInd: false,
                detailInd: false,
                formType: null,
                listPhysicain: null
              }
            ],
            gracePeriod: false
          },
          {
            mInfoCode: '10023364',
            mInfoDesc: null,
            requiredByDate: null,
            mInfoReqInfo: [
              {
                mInfoReqInfoCd: '3305',
                mInfoReqInfoDesc: null,
                requestDate: null,
                displayValue: 'EMPLOYER ONE',
                chatInd: false,
                callInd: false,
                uploadInd: false,
                detailInd: false,
                formType: null,
                listPhysicain: null
              }
            ],
            gracePeriod: false
          },
          {
            mInfoCode: '10023366',
            mInfoDesc: null,
            requiredByDate: null,
            mInfoReqInfo: [
              {
                mInfoReqInfoCd: '3303',
                mInfoReqInfoDesc: null,
                requestDate: null,
                displayValue: 'EMPLOYEE ONE',
                chatInd: false,
                callInd: false,
                uploadInd: false,
                detailInd: false,
                formType: null,
                listPhysicain: null
              }
            ],
            gracePeriod: false
          },
          {
            mInfoCode: '10023366',
            mInfoDesc: null,
            requiredByDate: null,
            mInfoReqInfo: [
              {
                mInfoReqInfoCd: '3304',
                mInfoReqInfoDesc: null,
                requestDate: null,
                displayValue: 'EMPLOYEE TWO',
                chatInd: false,
                callInd: false,
                uploadInd: false,
                detailInd: false,
                formType: null,
                listPhysicain: null
              }
            ],
            gracePeriod: false
          },
          {
            mInfoCode: '10023362',
            mInfoDesc: null,
            requiredByDate: null,
            mInfoReqInfo: [
              {
                mInfoReqInfoCd: '3307',
                mInfoReqInfoDesc: null,
                requestDate: null,
                displayValue: 'MEDICAL ONE',
                chatInd: false,
                callInd: false,
                uploadInd: false,
                detailInd: false,
                formType: null,
                listPhysicain: null
              }
            ],
            gracePeriod: false
          },
          {
            mInfoCode: '10023364',
            mInfoDesc: null,
            requiredByDate: null,
            mInfoReqInfo: [
              {
                mInfoReqInfoCd: '3306',
                mInfoReqInfoDesc: null,
                requestDate: null,
                displayValue: 'EMPLOYER TWO',
                chatInd: false,
                callInd: false,
                uploadInd: false,
                detailInd: false,
                formType: null,
                listPhysicain: null
              }
            ],
            gracePeriod: false
          }
        ],
        claimProgress: 'Under Review',
        extensionRequestDate: null,
        babyDeathIndicator: null,
        recentClaimIndicator: false,
        semiMonthlyPaymentIndicator: null,
        concurrentLeaveIDs: [],
        sourceSystemId: 'AA',
        personId: '7470234',
        relatedClaims: [],
        rtwStatusCode: '42001',
        oowReason: 'Employee\'s own illness',
        relationship: null
      },
      {
        asmIndicator: 'P',
        authorizedEndDate: '12/18/2020',
        babyDeathIndicator: null,
        caseId: '0516115',
        claimEffectiveDate: null,
        claimEventId: '1800411',
        claimOfficeId: null,
        claimOfficeNumber: null,
        claimOnlineStatus: 'Claim Approved',
        claimOnlineStatusCode: '04',
        claimOnlineSubStatus: 'Payments In Progress',
        claimOnlineSubStatusCode: '06',
        claimProcessDate: '01/27/2021',
        claimProgress: 'Claim Approved',
        claimStatus: 'Open',
        claimStatusCode: '0',
        claimSubType: 'San Francisco Paid Family Leave',
        claimType: 'Paid Family Leave',
        concurrentLeaveIDs: ['1800412'],
        confirmReturnToWork: '1',
        confirmedDeliveryDate: null,
        confirmedLastDayWorkDate: '12/14/2020',
        confirmedSurgeryDate: null,
        coverageCategory: 'PFL',
        coveragePlanNumber: null,
        coverageType: null,
        disabilityDate: '12/15/2020',
        effectiveDate: null,
        employeeStatementDate: '01/27/2021',
        employerStatementDate: null,
        encIndicator: false,
        encSurgeryIndicator: false,
        estimatedDeliveryDate: null,
        estimatedSurgeryDate: null,
        extensionRequestDate: null,
        extensionStatus: null,
        extensionStatusCode: null,
        extensionSubStatus: null,
        extensionSubStatusCode: null,
        form: '02',
        fundingMethod: 'FI',
        insuredFirstName: 'Danny',
        insuredLastName: 'Welch',
        leaveFirstName: 'Thomas',
        leaveLastName: 'Welch',
        leaveReason: 'Care of Family Member',
        lastDayWork: '12/14/2021',
        missingInfo: [],
        oowReason: null,
        personId: '7469010',
        physicianStatementDate: null,
        reason: 'Bonding',
        recentClaimIndicator: false,
        relatedClaims: [{relatedClaimList: [], hasRelatedClaims: false}],
        relationship: 'Son',
        hasRelatedClaims: false,
        relatedClaimList: [],
        rtwStatusCode: '42002',
        semiMonthlyPaymentIndicator: null,
        sourceSystemId: 'AA',
        taskOpen: false
      },
      {
        claimEventId: '1803143',
        claimType: 'Short Term Disability',
        claimSubType: 'Short Term Disability',
        coverageType: 'NST',
        coverageCategory: 'WD',
        disabilityDate: '01/05/2021',
        effectiveDate: null,
        authorizedEndDate: null,
        claimStatus: 'Pend',
        claimStatusCode: '0',
        claimOnlineStatus: 'Under Review by Claim Handler',
        claimOnlineStatusCode: '08',
        claimOnlineSubStatus: 'In Adjudication',
        claimOnlineSubStatusCode: '12',
        extensionStatus: null,
        extensionStatusCode: null,
        extensionSubStatus: null,
        extensionSubStatusCode: null,
        claimOfficeNumber: null,
        claimOfficeId: null,
        caseId: '0516115',
        claimProcessDate: '02/03/2021',
        claimEffectiveDate: null,
        reason: 'New Claim',
        physicianStatementDate: '02/03/2021',
        employerStatementDate: null,
        employeeStatementDate: '02/03/2021',
        estimatedSurgeryDate: null,
        estimatedDeliveryDate: null,
        lastDayWork: '01/04/2021',
        asmIndicator: 'A',
        confirmedLastDayWorkDate: '01/04/2021',
        confirmedSurgeryDate: null,
        confirmedDeliveryDate: null,
        encIndicator: false,
        encSurgeryIndicator: false,
        fundingMethod: null,
        coveragePlanNumber: null,
        taskOpen: false,
        missingInfo: [
        ],
        claimProgress: 'Under Review',
        extensionRequestDate: null,
        babyDeathIndicator: null,
        recentClaimIndicator: false,
        semiMonthlyPaymentIndicator: null,
        concurrentLeaveIDs: [
          '1803145'
        ],
        sourceSystemId: 'AA',
        personId: '7470231',
        relatedClaims: [
          {
            relatedClaimList: [
              {
                LinkedSTAT: '1803144',
                LinkedSTD: null,
                LinkedLTD: null,
                LinkedABL: null,
                LinkedPFL: null,
                LinkedCPFL: null,
                LinkedB: null,
                LinkedP: null,
                LinkedSTDB: null,
                LinkedPW: null
              }
            ],
            hasRelatedClaims: true
          }
        ],
        rtwStatusCode: '42001',
        oowReason: 'Employee\'s own illness',
        form: '02',
        leaveFirstName: '',
        leaveLastName: '',
        leaveReason: '',
        relationship: null
      },
      {
        claimEventId: '1803144',
        claimType: 'Short Term Disability',
        claimSubType: 'State-Mandated Disability',
        confirmReturnToWork: '1',
        coverageType: 'STAT',
        coverageCategory: 'WD',
        disabilityDate: '01/05/2021',
        effectiveDate: null,
        authorizedEndDate: null,
        claimStatus: 'Pend',
        claimStatusCode: '0',
        claimOnlineStatus: 'Under Review by Claim Handler',
        claimOnlineStatusCode: '08',
        claimOnlineSubStatus: 'In Adjudication',
        claimOnlineSubStatusCode: '12',
        extensionStatus: null,
        extensionStatusCode: null,
        extensionSubStatus: null,
        extensionSubStatusCode: null,
        claimOfficeNumber: null,
        claimOfficeId: null,
        caseId: '0516115',
        claimProcessDate: '02/03/2021',
        claimEffectiveDate: null,
        reason: 'New Claim',
        physicianStatementDate: '02/03/2021',
        employerStatementDate: null,
        employeeStatementDate: '02/03/2021',
        estimatedSurgeryDate: null,
        estimatedDeliveryDate: null,
        lastDayWork: '01/04/2021',
        asmIndicator: 'A',
        confirmedLastDayWorkDate: '01/04/2021',
        confirmedSurgeryDate: null,
        confirmedDeliveryDate: null,
        encIndicator: false,
        encSurgeryIndicator: false,
        fundingMethod: null,
        coveragePlanNumber: null,
        taskOpen: false,
        missingInfo: [
        ],
        claimProgress: 'Under Review',
        extensionRequestDate: null,
        babyDeathIndicator: null,
        recentClaimIndicator: false,
        semiMonthlyPaymentIndicator: null,
        concurrentLeaveIDs: [
          '1803145'
        ],
        sourceSystemId: 'AA',
        personId: '7470231',
        relatedClaims: [
          {
            relatedClaimList: [
              {
                LinkedSTAT: null,
                LinkedSTD: '1803143',
                LinkedLTD: null,
                LinkedABL: null,
                LinkedPFL: null,
                LinkedCPFL: null,
                LinkedB: null,
                LinkedP: null,
                LinkedSTDB: null,
                LinkedPW: null
              }
            ],
            hasRelatedClaims: true
          }
        ],
        rtwStatusCode: '42001',
        oowReason: 'Employee\'s own illness',
        form: '02',
        leaveFirstName: '',
        leaveLastName: '',
        leaveReason: '',
        relationship: null
      },
      {
        claimEventId: '2136481',
        claimType: 'Paid Family Leave',
        claimSubType: 'NY Paid Family Leave',
        coverageType: null,
        coverageCategory: 'PFL',
        disabilityDate: '02/02/2021',
        effectiveDate: null,
        authorizedEndDate: '02/15/2021',
        claimStatus: 'Closed',
        claimStatusCode: '0',
        claimOnlineStatus: 'Claim Closed',
        claimOnlineStatusCode: '05',
        claimOnlineSubStatus: 'Claim Closed',
        claimOnlineSubStatusCode: '08',
        extensionStatus: null,
        extensionStatusCode: null,
        extensionSubStatus: null,
        extensionSubStatusCode: null,
        claimOfficeNumber: null,
        claimOfficeId: null,
        caseId: '0516115',
        claimProcessDate: '03/05/2021',
        claimEffectiveDate: null,
        reason: 'Administrative',
        physicianStatementDate: null,
        employerStatementDate: null,
        employeeStatementDate: '03/05/2021',
        estimatedSurgeryDate: null,
        estimatedDeliveryDate: null,
        lastDayWork: '02/01/2021',
        asmIndicator: 'P',
        confirmedLastDayWorkDate: '02/01/2021',
        confirmedSurgeryDate: null,
        confirmedDeliveryDate: null,
        encIndicator: false,
        encSurgeryIndicator: false,
        fundingMethod: 'FI',
        coveragePlanNumber: null,
        taskOpen: false,
        missingInfo: [
          {
            mInfoCode: '10023362',
            mInfoDesc: null,
            requiredByDate: null,
            mInfoReqInfo: [
              {
                mInfoReqInfoCd: '3308',
                mInfoReqInfoDesc: null,
                requestDate: null,
                displayValue: 'MEDICAL TWO',
                chatInd: false,
                callInd: false,
                uploadInd: false,
                detailInd: false,
                formType: null,
                listPhysicain: null
              }
            ],
            gracePeriod: false
          },
          {
            mInfoCode: '10023364',
            mInfoDesc: null,
            requiredByDate: null,
            mInfoReqInfo: [
              {
                mInfoReqInfoCd: '3305',
                mInfoReqInfoDesc: null,
                requestDate: null,
                displayValue: 'EMPLOYER ONE',
                chatInd: false,
                callInd: false,
                uploadInd: false,
                detailInd: false,
                formType: null,
                listPhysicain: null
              }
            ],
            gracePeriod: false
          },
          {
            mInfoCode: '10023366',
            mInfoDesc: null,
            requiredByDate: null,
            mInfoReqInfo: [
              {
                mInfoReqInfoCd: '3303',
                mInfoReqInfoDesc: null,
                requestDate: null,
                displayValue: 'EMPLOYEE ONE',
                chatInd: false,
                callInd: false,
                uploadInd: false,
                detailInd: false,
                formType: null,
                listPhysicain: null
              }
            ],
            gracePeriod: false
          },
          {
            mInfoCode: '10023366',
            mInfoDesc: null,
            requiredByDate: null,
            mInfoReqInfo: [
              {
                mInfoReqInfoCd: '3304',
                mInfoReqInfoDesc: null,
                requestDate: null,
                displayValue: 'EMPLOYEE TWO',
                chatInd: false,
                callInd: false,
                uploadInd: false,
                detailInd: false,
                formType: null,
                listPhysicain: null
              }
            ],
            gracePeriod: false
          },
          {
            mInfoCode: '10023362',
            mInfoDesc: null,
            requiredByDate: null,
            mInfoReqInfo: [
              {
                mInfoReqInfoCd: '3307',
                mInfoReqInfoDesc: null,
                requestDate: null,
                displayValue: 'MEDICAL ONE',
                chatInd: false,
                callInd: false,
                uploadInd: false,
                detailInd: false,
                formType: null,
                listPhysicain: null
              }
            ],
            gracePeriod: false
          },
          {
            mInfoCode: '10023364',
            mInfoDesc: null,
            requiredByDate: null,
            mInfoReqInfo: [
              {
                mInfoReqInfoCd: '3306',
                mInfoReqInfoDesc: null,
                requestDate: null,
                displayValue: 'EMPLOYER TWO',
                chatInd: false,
                callInd: false,
                uploadInd: false,
                detailInd: false,
                formType: null,
                listPhysicain: null
              }
            ],
            gracePeriod: false
          }
        ],
        claimProgress: 'Claim Closed',
        extensionRequestDate: null,
        babyDeathIndicator: null,
        recentClaimIndicator: false,
        semiMonthlyPaymentIndicator: null,
        concurrentLeaveIDs: [
          '2136482'
        ],
        sourceSystemId: 'AA',
        personId: '11165073',
        relatedClaims: [
          {
            relatedClaimList: [
            ],
            hasRelatedClaims: false
          }
        ],
        rtwStatusCode: '42003',
        oowReason: 'Bonding/Adoption/Foster Care',
        form: '02'
      },
      {
        claimEventId: '2136346',
        claimType: 'Paid Family Leave',
        claimSubType: 'NY Paid Family Leave',
        coverageType: null,
        coverageCategory: 'PFL',
        disabilityDate: '02/02/2021',
        effectiveDate: null,
        authorizedEndDate: null,
        claimStatus: 'Closed',
        claimStatusCode: '0',
        claimOnlineStatus: 'Claim Denied',
        claimOnlineStatusCode: '06',
        claimOnlineSubStatus: 'Claim Denied',
        claimOnlineSubStatusCode: '10',
        extensionStatus: null,
        extensionStatusCode: null,
        extensionSubStatus: null,
        extensionSubStatusCode: null,
        claimOfficeNumber: null,
        claimOfficeId: null,
        caseId: '0516115',
        claimProcessDate: '03/05/2021',
        claimEffectiveDate: null,
        reason: 'Administrative',
        physicianStatementDate: null,
        employerStatementDate: null,
        employeeStatementDate: '03/05/2021',
        estimatedSurgeryDate: null,
        estimatedDeliveryDate: null,
        lastDayWork: '02/01/2021',
        asmIndicator: 'P',
        confirmedLastDayWorkDate: '02/01/2021',
        confirmedSurgeryDate: null,
        confirmedDeliveryDate: null,
        encIndicator: false,
        encSurgeryIndicator: false,
        fundingMethod: 'FI',
        coveragePlanNumber: null,
        taskOpen: false,
        missingInfo: [
          {
            mInfoCode: '10023364',
            mInfoDesc: null,
            requiredByDate: null,
            mInfoReqInfo: [
              {
                mInfoReqInfoCd: '63843',
                mInfoReqInfoDesc: null,
                requestDate: null,
                displayValue: 'Hours Worked Confirmation',
                chatInd: false,
                callInd: false,
                uploadInd: false,
                detailInd: false,
                formType: null,
                listPhysicain: null
              }
            ],
            gracePeriod: false
          },
          {
            mInfoCode: '10023364',
            mInfoDesc: null,
            requiredByDate: null,
            mInfoReqInfo: [
              {
                mInfoReqInfoCd: '63842',
                mInfoReqInfoDesc: null,
                requestDate: null,
                displayValue: 'Employment Confirmation',
                chatInd: false,
                callInd: false,
                uploadInd: false,
                detailInd: false,
                formType: null,
                listPhysicain: null
              }
            ],
            gracePeriod: false
          },
          {
            mInfoCode: '10023362',
            mInfoDesc: null,
            requiredByDate: null,
            mInfoReqInfo: [
              {
                mInfoReqInfoCd: '63828',
                mInfoReqInfoDesc: null,
                requestDate: null,
                displayValue: 'Clarification Reply',
                chatInd: false,
                callInd: false,
                uploadInd: false,
                detailInd: false,
                formType: null,
                listPhysicain: null
              }
            ],
            gracePeriod: false
          },
          {
            mInfoCode: '10023366',
            mInfoDesc: null,
            requiredByDate: null,
            mInfoReqInfo: [
              {
                mInfoReqInfoCd: '63832',
                mInfoReqInfoDesc: null,
                requestDate: null,
                displayValue: 'Workers\' Compensation Denial Letter',
                chatInd: false,
                callInd: false,
                uploadInd: false,
                detailInd: false,
                formType: null,
                listPhysicain: null
              }
            ],
            gracePeriod: false
          },
          {
            mInfoCode: '10023366',
            mInfoDesc: null,
            requiredByDate: null,
            mInfoReqInfo: [
              {
                mInfoReqInfoCd: '63831',
                mInfoReqInfoDesc: null,
                requestDate: null,
                displayValue: 'Doctor\'s Contact Information',
                chatInd: false,
                callInd: false,
                uploadInd: false,
                detailInd: false,
                formType: null,
                listPhysicain: null
              }
            ],
            gracePeriod: false
          },
          {
            mInfoCode: '10023362',
            mInfoDesc: null,
            requiredByDate: null,
            mInfoReqInfo: [
              {
                mInfoReqInfoCd: '63834',
                mInfoReqInfoDesc: null,
                requestDate: null,
                displayValue: 'Cause of Disabling Condition',
                chatInd: false,
                callInd: false,
                uploadInd: false,
                detailInd: false,
                formType: null,
                listPhysicain: null
              }
            ],
            gracePeriod: false
          }
        ],
        claimProgress: 'Claim Denied',
        extensionRequestDate: null,
        babyDeathIndicator: null,
        recentClaimIndicator: false,
        semiMonthlyPaymentIndicator: null,
        concurrentLeaveIDs: [
          '2136347'
        ],
        sourceSystemId: 'AA',
        personId: '11165067',
        relatedClaims: [
          {
            relatedClaimList: [
            ],
            hasRelatedClaims: false
          }
        ],
        rtwStatusCode: '42004',
        oowReason: 'Care of a Family Member',
        form: '02'
      },
      {
        claimEventId: '2136374',
        claimType: 'Paid Family Leave',
        claimSubType: 'San Francisco Paid Family Leave',
        coverageType: null,
        coverageCategory: 'PFL',
        confirmReturnToWork: '1',
        disabilityDate: '02/02/2021',
        effectiveDate: null,
        authorizedEndDate: null,
        claimStatus: 'Pend',
        claimStatusCode: '0',
        claimOnlineStatus: 'Under Review by Claim Handler',
        claimOnlineStatusCode: '08',
        claimOnlineSubStatus: 'In Adjudication',
        claimOnlineSubStatusCode: '12',
        extensionStatus: null,
        extensionStatusCode: null,
        extensionSubStatus: null,
        extensionSubStatusCode: null,
        claimOfficeNumber: null,
        claimOfficeId: null,
        caseId: '0516115',
        claimProcessDate: '03/17/2021',
        claimEffectiveDate: null,
        reason: 'Employee',
        physicianStatementDate: null,
        employerStatementDate: null,
        employeeStatementDate: '03/05/2021',
        estimatedSurgeryDate: null,
        estimatedDeliveryDate: null,
        lastDayWork: '02/01/2021',
        asmIndicator: 'P',
        confirmedLastDayWorkDate: '02/01/2021',
        confirmedSurgeryDate: null,
        confirmedDeliveryDate: null,
        encIndicator: false,
        encSurgeryIndicator: false,
        fundingMethod: 'FI',
        coveragePlanNumber: null,
        taskOpen: false,
        missingInfo: [
          {
            mInfoCode: '10023362',
            mInfoDesc: null,
            requiredByDate: null,
            mInfoReqInfo: [
              {
                mInfoReqInfoCd: '3308',
                mInfoReqInfoDesc: null,
                requestDate: null,
                displayValue: 'MEDICAL TWO',
                chatInd: false,
                callInd: false,
                uploadInd: false,
                detailInd: false,
                formType: null,
                listPhysicain: null
              }
            ],
            gracePeriod: false
          },
          {
            mInfoCode: '10023366',
            mInfoDesc: null,
            requiredByDate: null,
            mInfoReqInfo: [
              {
                mInfoReqInfoCd: '3304',
                mInfoReqInfoDesc: null,
                requestDate: null,
                displayValue: 'EMPLOYEE TWO',
                chatInd: false,
                callInd: false,
                uploadInd: false,
                detailInd: false,
                formType: null,
                listPhysicain: null
              }
            ],
            gracePeriod: false
          },
          {
            mInfoCode: '10023364',
            mInfoDesc: null,
            requiredByDate: null,
            mInfoReqInfo: [
              {
                mInfoReqInfoCd: '3305',
                mInfoReqInfoDesc: null,
                requestDate: null,
                displayValue: 'EMPLOYER ONE',
                chatInd: false,
                callInd: false,
                uploadInd: false,
                detailInd: false,
                formType: null,
                listPhysicain: null
              }
            ],
            gracePeriod: false
          },
          {
            mInfoCode: '10023366',
            mInfoDesc: null,
            requiredByDate: null,
            mInfoReqInfo: [
              {
                mInfoReqInfoCd: '3303',
                mInfoReqInfoDesc: null,
                requestDate: null,
                displayValue: 'EMPLOYEE ONE',
                chatInd: false,
                callInd: false,
                uploadInd: false,
                detailInd: false,
                formType: null,
                listPhysicain: null
              }
            ],
            gracePeriod: false
          },
          {
            mInfoCode: '10023364',
            mInfoDesc: null,
            requiredByDate: null,
            mInfoReqInfo: [
              {
                mInfoReqInfoCd: '3306',
                mInfoReqInfoDesc: null,
                requestDate: null,
                displayValue: 'EMPLOYER TWO',
                chatInd: false,
                callInd: false,
                uploadInd: false,
                detailInd: false,
                formType: null,
                listPhysicain: null
              }
            ],
            gracePeriod: false
          },
          {
            mInfoCode: '10023362',
            mInfoDesc: null,
            requiredByDate: null,
            mInfoReqInfo: [
              {
                mInfoReqInfoCd: '3307',
                mInfoReqInfoDesc: null,
                requestDate: null,
                displayValue: 'MEDICAL ONE',
                chatInd: false,
                callInd: false,
                uploadInd: false,
                detailInd: false,
                formType: null,
                listPhysicain: null
              }
            ],
            gracePeriod: false
          }
        ],
        claimProgress: 'Under Review',
        extensionRequestDate: null,
        babyDeathIndicator: null,
        recentClaimIndicator: false,
        semiMonthlyPaymentIndicator: null,
        concurrentLeaveIDs: [
          '2136375'
        ],
        sourceSystemId: 'AA',
        personId: '11165068',
        relatedClaims: [
          {
            relatedClaimList: [
            ],
            hasRelatedClaims: false
          }
        ],
        rtwStatusCode: '42001',
        oowReason: 'Bonding/Adoption/Foster Care',
        form: '02'
      },
      {
        claimEventId: '1596782',
        claimType: 'Paid Family Leave',
        claimSubType: 'NY Paid Family Leave',
        coverageType: null,
        coverageCategory: 'PFL',
        disabilityDate: '03/04/2020',
        effectiveDate: null,
        authorizedEndDate: '04/10/2020',
        claimStatus: 'Open',
        claimStatusCode: '0',
        claimOnlineStatus: 'Claim Approved',
        claimOnlineStatusCode: '04',
        claimOnlineSubStatus: 'Benefit Payments Complete',
        claimOnlineSubStatusCode: '04',
        extensionStatus: null,
        extensionStatusCode: null,
        extensionSubStatus: null,
        extensionSubStatusCode: null,
        claimOfficeNumber: null,
        claimOfficeId: null,
        caseId: '0516115',
        claimProcessDate: '02/17/2021',
        claimEffectiveDate: null,
        reason: 'Re-Cert',
        physicianStatementDate: '06/25/2020',
        employerStatementDate: '06/25/2020',
        employeeStatementDate: '06/18/2020',
        estimatedSurgeryDate: null,
        estimatedDeliveryDate: null,
        lastDayWork: '03/03/2020',
        asmIndicator: 'P',
        confirmedLastDayWorkDate: '03/03/2020',
        confirmedSurgeryDate: null,
        confirmedDeliveryDate: null,
        leaveType: 'Paid Family Leave',
        leaveSubType: 'continuous',
        encIndicator: false,
        encSurgeryIndicator: false,
        fundingMethod: 'FI',
        coveragePlanNumber: null,
        taskOpen: false,
        missingInfo: [
          {
            mInfoCode: '10023362',
            mInfoDesc: null,
            requiredByDate: null,
            mInfoReqInfo: [
              {
                mInfoReqInfoCd: '62983',
                mInfoReqInfoDesc: null,
                requestDate: null,
                displayValue: 'Electronic Funds Transfer (EFT) Request',
                chatInd: false,
                callInd: false,
                uploadInd: false,
                detailInd: false,
                formType: null,
                listPhysicain: null
              }
            ],
            gracePeriod: false
          },
          {
            mInfoCode: '10023362',
            mInfoDesc: null,
            requiredByDate: null,
            mInfoReqInfo: [
              {
                mInfoReqInfoCd: '62983',
                mInfoReqInfoDesc: null,
                requestDate: null,
                displayValue: 'Electronic Funds Transfer (EFT) Request',
                chatInd: false,
                callInd: false,
                uploadInd: false,
                detailInd: false,
                formType: null,
                listPhysicain: null
              }
            ],
            gracePeriod: false
          },
          {
            mInfoCode: '10023362',
            mInfoDesc: null,
            requiredByDate: null,
            mInfoReqInfo: [
              {
                mInfoReqInfoCd: '62983',
                mInfoReqInfoDesc: null,
                requestDate: null,
                displayValue: 'Electronic Funds Transfer (EFT) Request',
                chatInd: false,
                callInd: false,
                uploadInd: false,
                detailInd: false,
                formType: null,
                listPhysicain: null
              }
            ],
            gracePeriod: false
          },
          {
            mInfoCode: '10023362',
            mInfoDesc: null,
            requiredByDate: null,
            mInfoReqInfo: [
              {
                mInfoReqInfoCd: '62983',
                mInfoReqInfoDesc: null,
                requestDate: null,
                displayValue: 'Electronic Funds Transfer (EFT) Request',
                chatInd: false,
                callInd: false,
                uploadInd: false,
                detailInd: false,
                formType: null,
                listPhysicain: null
              }
            ],
            gracePeriod: false
          }
        ],
        claimProgress: 'In Payment',
        extensionRequestDate: null,
        babyDeathIndicator: null,
        recentClaimIndicator: false,
        semiMonthlyPaymentIndicator: null,
        concurrentLeaveIDs: [
        ],
        sourceSystemId: 'AA',
        personId: '8678298',
        relatedClaims: [
          {
            relatedClaimList: [
              {
                LinkedSTAT: null,
                LinkedSTD: '1596781',
                LinkedLTD: null,
                LinkedABL: null,
                LinkedPFL: null,
                LinkedCPFL: null,
                LinkedB: null,
                LinkedP: null,
                LinkedSTDB: null,
                LinkedPW: null
              }
            ],
            hasRelatedClaims: true
          }
        ],
        rtwStatusCode: '42002',
        oowReason: 'Employee\'s own illness',
        form: '02'
      },
      {
        claimEventId: '2155233',
        claimType: 'Paid Family Leave',
        claimSubType: 'NY PAid Family Leave',
        coverageType: null,
        coverageCategory: 'PFL',
        disabilityDate: '03/02/2021',
        effectiveDate: null,
        authorizedEndDate: '03/09/2021',
        claimStatus: 'Open',
        claimStatusCode: '0',
        claimOnlineStatus: 'Claim Approved',
        claimOnlineStatusCode: '04',
        claimOnlineSubStatus: 'Payments in Progress',
        claimOnlineSubStatusCode: '06',
        extensionStatus: null,
        extensionStatusCode: null,
        extensionSubStatus: null,
        extensionSubStatusCode: null,
        claimOfficeNumber: null,
        claimOfficeId: null,
        caseId: '0516115',
        claimProcessDate: '04/06/2021',
        claimEffectiveDate: null,
        reason: 'Disability Supported',
        physicianStatementDate: '04/06/2021',
        employerStatementDate: '03/30/2021',
        employeeStatementDate: '04/06/2021',
        estimatedSurgeryDate: null,
        estimatedDeliveryDate: null,
        lastDayWork: '03/01/2021',
        asmIndicator: 'P',
        confirmedLastDayWorkDate: '03/01/2021',
        confirmedSurgeryDate: null,
        confirmedDeliveryDate: null,
        encIndicator: false,
        encSurgeryIndicator: false,
        fundingMethod: 'FI',
        coveragePlanNumber: null,
        taskOpen: false,
        missingInfo: [
        ],
        claimProgress: 'In Payment',
        extensionRequestDate: null,
        babyDeathIndicator: null,
        recentClaimIndicator: false,
        semiMonthlyPaymentIndicator: null,
        concurrentLeaveIDs: [
          '2216116'
        ],
        sourceSystemId: 'AA',
        personId: '11188176',
        relatedClaims: [
          {
            relatedClaimList: [
            ],
            hasRelatedClaims: false
          }
        ],
        rtwStatusCode: '42002',
        oowReason: 'Employee\'s own illness',
        form: '02'
      },

      {
        claimEventId: '2153647',
        claimType: 'Paid Family Leave',
        claimSubType: 'NY PAid Family Leave',
        coverageType: null,
        coverageCategory: 'PFL',
        disabilityDate: '02/02/2021',
        effectiveDate: null,
        authorizedEndDate: '02/09/2021',
        claimStatus: 'Open',
        claimStatusCode: '0',
        claimOnlineStatus: 'Claim Approved',
        claimOnlineStatusCode: '04',
        claimOnlineSubStatus: 'Benefit Payments Complete',
        claimOnlineSubStatusCode: '04',
        extensionStatus: 'Extension Under Review',
        extensionStatusCode: '1',
        extensionSubStatus: 'Missing Information',
        extensionSubStatusCode: '1',
        claimOfficeNumber: null,
        claimOfficeId: null,
        caseId: '0516115',
        claimProcessDate: '03/11/2021',
        claimEffectiveDate: null,
        reason: 'Disability Supported',
        physicianStatementDate: '03/09/2021',
        employerStatementDate: '02/28/2021',
        employeeStatementDate: '03/10/2021',
        estimatedSurgeryDate: null,
        estimatedDeliveryDate: null,
        lastDayWork: '02/01/2021',
        asmIndicator: 'A',
        confirmedLastDayWorkDate: '02/01/2021',
        confirmedSurgeryDate: null,
        confirmedDeliveryDate: null,
        encIndicator: false,
        encSurgeryIndicator: false,
        fundingMethod: 'FI',
        coveragePlanNumber: null,
        taskOpen: false,
        missingInfo: [
        ],
        claimProgress: 'In Payment',
        extensionRequestDate: '02/03/2021',
        babyDeathIndicator: null,
        recentClaimIndicator: false,
        semiMonthlyPaymentIndicator: null,
        concurrentLeaveIDs: [
          '2153648'
        ],
        sourceSystemId: 'AA',
        personId: '11169741',
        relatedClaims: [
          {
            relatedClaimList: [
            ],
            hasRelatedClaims: false
          }
        ],
        rtwStatusCode: '42002',
        oowReason: 'Employee\'s own illness',
        form: '02'
      },
      {
        claimEventId: '9898989',
        claimType: 'Paid Family Leave',
        claimSubType: 'NY PAid Family Leave',
        coverageType: null,
        coverageCategory: 'PFL',
        disabilityDate: '02/02/2021',
        effectiveDate: null,
        authorizedEndDate: '02/09/2021',
        claimStatus: 'Open',
        claimStatusCode: '0',
        claimOnlineStatus: 'Claim Approved',
        claimOnlineStatusCode: '04',
        claimOnlineSubStatus: 'Benefit Payments Complete',
        claimOnlineSubStatusCode: '06',
        extensionStatus: 'Extension Under Review',
        extensionStatusCode: '4',
        extensionSubStatus: 'Missing Information',
        extensionSubStatusCode: '1',
        claimOfficeNumber: null,
        claimOfficeId: null,
        caseId: '0516115',
        claimProcessDate: '03/11/2021',
        claimEffectiveDate: null,
        reason: 'Disability Supported',
        physicianStatementDate: '03/09/2021',
        employerStatementDate: '02/28/2021',
        employeeStatementDate: '03/10/2021',
        estimatedSurgeryDate: null,
        estimatedDeliveryDate: null,
        lastDayWork: '02/01/2021',
        asmIndicator: 'A',
        confirmedLastDayWorkDate: '02/01/2021',
        confirmedSurgeryDate: null,
        confirmedDeliveryDate: null,
        encIndicator: false,
        encSurgeryIndicator: false,
        fundingMethod: 'FI',
        coveragePlanNumber: null,
        taskOpen: false,
        missingInfo: [
        ],
        claimProgress: 'In Payment',
        extensionRequestDate: '02/03/2021',
        babyDeathIndicator: null,
        recentClaimIndicator: false,
        semiMonthlyPaymentIndicator: null,
        concurrentLeaveIDs: [
          '2153648'
        ],
        sourceSystemId: 'AA',
        personId: '11169741',
        relatedClaims: [
          {
            relatedClaimList: [
            ],
            hasRelatedClaims: false
          }
        ],
        rtwStatusCode: '42002',
        oowReason: 'Employee\'s own illness',
        form: '02'
      },
      {
        claimEventId: '2159712',
        claimType: 'Paid Family Leave',
        claimSubType: 'CA Paid Family Leave',
        coverageType: null,
        coverageCategory: 'PFL',
        disabilityDate: '02/02/2021',
        effectiveDate: null,
        authorizedEndDate: '02/09/2021',
        claimStatus: 'Closed',
        claimStatusCode: '0',
        claimOnlineStatus: 'Claim Closed',
        claimOnlineStatusCode: '05',
        claimOnlineSubStatus: 'Claim Closed',
        claimOnlineSubStatusCode: '08',
        extensionStatus: 'Extension Closed',
        extensionStatusCode: '1',
        extensionSubStatus: 'Missing Information',
      //  'extensionUIMissStatusIcon': true,
        extensionSubStatusCode: '1',
        claimOfficeNumber: null,
        claimOfficeId: null,
        caseId: '0516115',
        claimProcessDate: '03/11/2021',
        claimEffectiveDate: null,
        reason: 'Withdrawn',
        physicianStatementDate: '03/09/2021',
        employerStatementDate: '03/01/2021',
        employeeStatementDate: '03/10/2021',
        estimatedSurgeryDate: null,
        estimatedDeliveryDate: null,
        lastDayWork: '02/01/2021',
        asmIndicator: 'P',
        confirmedLastDayWorkDate: '02/01/2021',
        confirmedSurgeryDate: null,
        confirmedDeliveryDate: null,
        encIndicator: false,
        encSurgeryIndicator: false,
        fundingMethod: 'FI',
        coveragePlanNumber: null,
        taskOpen: false,
        missingInfo: [
        ],
        claimProgress: 'Claim Closed',
        extensionRequestDate: '04/01/2021',
        babyDeathIndicator: null,
        recentClaimIndicator: false,
        semiMonthlyPaymentIndicator: null,
        concurrentLeaveIDs: [
          '2153713'
        ],
        sourceSystemId: 'AA',
        personId: '11169742',
        relatedClaims: [
          {
            relatedClaimList: [
            ],
            hasRelatedClaims: false
          }
        ],
        rtwStatusCode: '42003',
        oowReason: 'Employee\'s own illness',
        form: '02'
      },
      {
        claimEventId: '2159717',
        claimType: 'Paid Family Leave',
        claimSubType: 'CA Paid Family Leave',
        coverageType: null,
        coverageCategory: 'PFL',
        disabilityDate: '02/02/2021',
        effectiveDate: null,
        authorizedEndDate: '02/09/2021',
        claimStatus: 'Closed',
        claimStatusCode: '0',
        claimOnlineStatus: 'Claim Closed',
        claimOnlineStatusCode: '05',
        claimOnlineSubStatus: 'Claim Closed',
        claimOnlineSubStatusCode: '08',
        extensionStatus: 'Extension Closed',
        extensionStatusCode: '4',
        extensionSubStatus: 'Missing Information',
        extensionSubStatusCode: '1',
        claimOfficeNumber: null,
        claimOfficeId: null,
        caseId: '0516115',
        claimProcessDate: '03/11/2021',
        claimEffectiveDate: null,
        reason: 'Withdrawn',
        physicianStatementDate: '03/09/2021',
        employerStatementDate: '03/01/2021',
        employeeStatementDate: '03/10/2021',
        estimatedSurgeryDate: null,
        estimatedDeliveryDate: null,
        lastDayWork: '02/01/2021',
        asmIndicator: 'P',
        confirmedLastDayWorkDate: '02/01/2021',
        confirmedSurgeryDate: null,
        confirmedDeliveryDate: null,
        encIndicator: false,
        encSurgeryIndicator: false,
        fundingMethod: 'FI',
        coveragePlanNumber: null,
        taskOpen: false,
        missingInfo: [
        ],
        claimProgress: 'Claim Closed',
        extensionRequestDate: '04/01/2021',
        babyDeathIndicator: null,
        recentClaimIndicator: false,
        semiMonthlyPaymentIndicator: null,
        concurrentLeaveIDs: [
          '2153713'
        ],
        sourceSystemId: 'AA',
        personId: '11169742',
        relatedClaims: [
          {
            relatedClaimList: [
            ],
            hasRelatedClaims: false
          }
        ],
        rtwStatusCode: '42003',
        oowReason: 'Employee\'s own illness',
        form: '02'
      },
      {
        claimEventId: '2153744',
        claimType: 'Short Term Disability',
        claimSubType: 'Short Term Disability',
        coverageType: 'NST',
        coverageCategory: 'WD',
        disabilityDate: '02/02/2021',
        effectiveDate: null,
        authorizedEndDate: '02/12/2021',
        claimStatus: 'Open',
        claimStatusCode: '0',
        claimOnlineStatus: 'Claim Approved',
        claimOnlineStatusCode: '04',
        claimOnlineSubStatus: 'Payments in Progress',
        claimOnlineSubStatusCode: '04',
        extensionStatus: 'Extension Approved',
        extensionStatusCode: '4',
        extensionSubStatus: 'Partial Approval',
        extensionSubStatusCode: '8',
        claimOfficeNumber: null,
        claimOfficeId: null,
        caseId: '0516115',
        claimProcessDate: '03/11/2021',
        claimEffectiveDate: null,
        reason: 'Re-Cert',
        physicianStatementDate: '03/09/2021',
        employerStatementDate: '03/01/2021',
        employeeStatementDate: '03/10/2021',
        estimatedSurgeryDate: null,
        estimatedDeliveryDate: null,
        lastDayWork: '02/01/2021',
        asmIndicator: 'A',
        confirmedLastDayWorkDate: '02/01/2021',
        confirmedSurgeryDate: null,
        confirmedDeliveryDate: null,
        encIndicator: false,
        encSurgeryIndicator: false,
        fundingMethod: 'FI',
        coveragePlanNumber: null,
        taskOpen: false,
        missingInfo: [
          {
            mInfoCode: '10023362',
            mInfoDesc: null,
            requiredByDate: null,
            mInfoReqInfo: [
              {
                mInfoReqInfoCd: '3308',
                mInfoReqInfoDesc: null,
                requestDate: null,
                displayValue: 'MEDICAL TWO',
                chatInd: false,
                callInd: false,
                uploadInd: false,
                detailInd: false,
                formType: null,
                listPhysicain: null
              }
            ],
            gracePeriod: false
          },
          {
            mInfoCode: '10023364',
            mInfoDesc: null,
            requiredByDate: null,
            mInfoReqInfo: [
              {
                mInfoReqInfoCd: '3305',
                mInfoReqInfoDesc: null,
                requestDate: null,
                displayValue: 'EMPLOYER ONE',
                chatInd: false,
                callInd: false,
                uploadInd: false,
                detailInd: false,
                formType: null,
                listPhysicain: null
              }
            ],
            gracePeriod: false
          },
          {
            mInfoCode: '10023366',
            mInfoDesc: null,
            requiredByDate: null,
            mInfoReqInfo: [
              {
                mInfoReqInfoCd: '3303',
                mInfoReqInfoDesc: null,
                requestDate: null,
                displayValue: 'EMPLOYEE ONE',
                chatInd: false,
                callInd: false,
                uploadInd: false,
                detailInd: false,
                formType: null,
                listPhysicain: null
              }
            ],
            gracePeriod: false
          },
          {
            mInfoCode: '10023366',
            mInfoDesc: null,
            requiredByDate: null,
            mInfoReqInfo: [
              {
                mInfoReqInfoCd: '3304',
                mInfoReqInfoDesc: null,
                requestDate: null,
                displayValue: 'EMPLOYEE TWO',
                chatInd: false,
                callInd: false,
                uploadInd: false,
                detailInd: false,
                formType: null,
                listPhysicain: null
              }
            ],
            gracePeriod: false
          },
          {
            mInfoCode: '10023362',
            mInfoDesc: null,
            requiredByDate: null,
            mInfoReqInfo: [
              {
                mInfoReqInfoCd: '3307',
                mInfoReqInfoDesc: null,
                requestDate: null,
                displayValue: 'MEDICAL ONE',
                chatInd: false,
                callInd: false,
                uploadInd: false,
                detailInd: false,
                formType: null,
                listPhysicain: null
              }
            ],
            gracePeriod: false
          },
          {
            mInfoCode: '10023364',
            mInfoDesc: null,
            requiredByDate: null,
            mInfoReqInfo: [
              {
                mInfoReqInfoCd: '3306',
                mInfoReqInfoDesc: null,
                requestDate: null,
                displayValue: 'EMPLOYER TWO',
                chatInd: false,
                callInd: false,
                uploadInd: false,
                detailInd: false,
                formType: null,
                listPhysicain: null
              }
            ],
            gracePeriod: false
          }
        ],
        claimProgress: 'In Payment',
        extensionRequestDate: null,
        babyDeathIndicator: null,
        recentClaimIndicator: false,
        semiMonthlyPaymentIndicator: null,
        concurrentLeaveIDs: [
          '2153745'
        ],
        sourceSystemId: 'AA',
        personId: '11169743',
        relatedClaims: [
          {
            relatedClaimList: [
            ],
            hasRelatedClaims: false
          }
        ],
        rtwStatusCode: '42002',
        oowReason: 'Employee\'s own illness',
        form: '02'
      },
      {
        claimEventId: '2153810',
        claimType: 'Paid Family Leave',
        claimSubType: 'NY Paid Family Leave',
        coverageType: null,
        coverageCategory: 'PFL',
        disabilityDate: '02/02/2021',
        effectiveDate: null,
        authorizedEndDate: '02/12/2021',
        claimStatus: 'Open',
        claimStatusCode: '0',
        claimOnlineStatus: 'Claim Approved',
        claimOnlineStatusCode: '04',
        claimOnlineSubStatus: 'Payments in Progress',
        claimOnlineSubStatusCode: '04',
        extensionStatus: 'Extension Approved',
        extensionStatusCode: '4',
        extensionSubStatus: 'Partial Approval',
        extensionSubStatusCode: '8',
        claimOfficeNumber: null,
        claimOfficeId: null,
        caseId: '0516115',
        claimProcessDate: '03/11/2021',
        claimEffectiveDate: null,
        reason: 'Re-Cert',
        physicianStatementDate: '03/09/2021',
        employerStatementDate: '03/01/2021',
        employeeStatementDate: '03/10/2021',
        estimatedSurgeryDate: null,
        estimatedDeliveryDate: null,
        lastDayWork: '02/01/2021',
        asmIndicator: 'P',
        confirmedLastDayWorkDate: '02/01/2021',
        confirmedSurgeryDate: null,
        confirmedDeliveryDate: null,
        encIndicator: false,
        encSurgeryIndicator: false,
        fundingMethod: 'FI',
        coveragePlanNumber: null,
        taskOpen: false,
        missingInfo: [
          {
            mInfoCode: '10023362',
            mInfoDesc: null,
            requiredByDate: null,
            mInfoReqInfo: [
              {
                mInfoReqInfoCd: '3308',
                mInfoReqInfoDesc: null,
                requestDate: null,
                displayValue: 'MEDICAL TWO',
                chatInd: false,
                callInd: false,
                uploadInd: false,
                detailInd: false,
                formType: null,
                listPhysicain: null
              }
            ],
            gracePeriod: false
          },
          {
            mInfoCode: '10023364',
            mInfoDesc: null,
            requiredByDate: null,
            mInfoReqInfo: [
              {
                mInfoReqInfoCd: '3305',
                mInfoReqInfoDesc: null,
                requestDate: null,
                displayValue: 'EMPLOYER ONE',
                chatInd: false,
                callInd: false,
                uploadInd: false,
                detailInd: false,
                formType: null,
                listPhysicain: null
              }
            ],
            gracePeriod: false
          },
          {
            mInfoCode: '10023366',
            mInfoDesc: null,
            requiredByDate: null,
            mInfoReqInfo: [
              {
                mInfoReqInfoCd: '3303',
                mInfoReqInfoDesc: null,
                requestDate: null,
                displayValue: 'EMPLOYEE ONE',
                chatInd: false,
                callInd: false,
                uploadInd: false,
                detailInd: false,
                formType: null,
                listPhysicain: null
              }
            ],
            gracePeriod: false
          },
          {
            mInfoCode: '10023366',
            mInfoDesc: null,
            requiredByDate: null,
            mInfoReqInfo: [
              {
                mInfoReqInfoCd: '3304',
                mInfoReqInfoDesc: null,
                requestDate: null,
                displayValue: 'EMPLOYEE TWO',
                chatInd: false,
                callInd: false,
                uploadInd: false,
                detailInd: false,
                formType: null,
                listPhysicain: null
              }
            ],
            gracePeriod: false
          },
          {
            mInfoCode: '10023362',
            mInfoDesc: null,
            requiredByDate: null,
            mInfoReqInfo: [
              {
                mInfoReqInfoCd: '3307',
                mInfoReqInfoDesc: null,
                requestDate: null,
                displayValue: 'MEDICAL ONE',
                chatInd: false,
                callInd: false,
                uploadInd: false,
                detailInd: false,
                formType: null,
                listPhysicain: null
              }
            ],
            gracePeriod: false
          },
          {
            mInfoCode: '10023364',
            mInfoDesc: null,
            requiredByDate: null,
            mInfoReqInfo: [
              {
                mInfoReqInfoCd: '3306',
                mInfoReqInfoDesc: null,
                requestDate: null,
                displayValue: 'EMPLOYER TWO',
                chatInd: false,
                callInd: false,
                uploadInd: false,
                detailInd: false,
                formType: null,
                listPhysicain: null
              }
            ],
            gracePeriod: false
          }
        ],
        claimProgress: 'In Payment',
        extensionRequestDate: null,
        babyDeathIndicator: null,
        recentClaimIndicator: false,
        semiMonthlyPaymentIndicator: null,
        concurrentLeaveIDs: [
          '2153745'
        ],
        sourceSystemId: 'AA',
        personId: '11169743',
        relatedClaims: [
          {
            relatedClaimList: [
            ],
            hasRelatedClaims: false
          }
        ],
        rtwStatusCode: '42002',
        oowReason: 'Employee\'s own illness',
        form: '02'
      },
      {
        claimEventId: '2153799',
        claimType: 'Paid Family Leave',
        claimSubType: 'CA Paid Family Leave',
        coverageType: null,
        coverageCategory: 'PFL',
        disabilityDate: '02/02/2021',
        effectiveDate: null,
        authorizedEndDate: '02/12/2021',
        claimStatus: 'Open',
        claimStatusCode: '0',
        claimOnlineStatus: 'Claim Approved',
        claimOnlineStatusCode: '04',
        claimOnlineSubStatus: 'Payments in Progress',
        claimOnlineSubStatusCode: '06',
        extensionStatus: 'Extension Approved',
        extensionStatusCode: '2',
        extensionSubStatus: 'Partial Approval',
        extensionSubStatusCode: '4',
        claimOfficeNumber: null,
        claimOfficeId: null,
        caseId: '0516115',
        claimProcessDate: '03/11/2021',
        claimEffectiveDate: null,
        reason: 'Re-Cert',
        physicianStatementDate: '03/09/2021',
        employerStatementDate: '03/01/2021',
        employeeStatementDate: '03/10/2021',
        estimatedSurgeryDate: null,
        estimatedDeliveryDate: null,
        lastDayWork: '02/01/2021',
        asmIndicator: 'P',
        confirmedLastDayWorkDate: '02/01/2021',
        confirmedSurgeryDate: null,
        confirmedDeliveryDate: null,
        encIndicator: false,
        encSurgeryIndicator: false,
        fundingMethod: 'FI',
        coveragePlanNumber: null,
        taskOpen: false,
        missingInfo: [
        ],
        claimProgress: 'In Payment',
        extensionRequestDate: null,
        babyDeathIndicator: null,
        recentClaimIndicator: false,
        semiMonthlyPaymentIndicator: null,
        concurrentLeaveIDs: [
          '2153745'
        ],
        sourceSystemId: 'AA',
        personId: '11169743',
        relatedClaims: [
          {
            relatedClaimList: [
            ],
            hasRelatedClaims: false
          }
        ],
        rtwStatusCode: '42002',
        oowReason: 'Employee\'s own illness',
        form: '02'
      },
      {
        claimEventId: '2153712',
        claimType: 'Short Term Disability',
        claimSubType: 'Short Term Disability',
        coverageType: 'NST',
        coverageCategory: 'WD',
        disabilityDate: '02/02/2021',
        effectiveDate: null,
        authorizedEndDate: '02/09/2021',
        claimStatus: 'Closed',
        claimStatusCode: '0',
        claimOnlineStatus: 'Claim Closed',
        claimOnlineStatusCode: '05',
        claimOnlineSubStatus: 'Claim Closed',
        claimOnlineSubStatusCode: '08',
        extensionStatus: 'Extension Closed',
        extensionStatusCode: '4',
        extensionSubStatus: 'Missing Information',
        extensionSubStatusCode: '8',
        claimOfficeNumber: null,
        claimOfficeId: null,
        caseId: '0516115',
        claimProcessDate: '03/11/2021',
        claimEffectiveDate: null,
        reason: 'Withdrawn',
        physicianStatementDate: '03/09/2021',
        employerStatementDate: '03/01/2021',
        employeeStatementDate: '03/10/2021',
        estimatedSurgeryDate: null,
        estimatedDeliveryDate: null,
        lastDayWork: '02/01/2021',
        asmIndicator: 'P',
        confirmedLastDayWorkDate: '02/01/2021',
        confirmedSurgeryDate: null,
        confirmedDeliveryDate: null,
        encIndicator: false,
        encSurgeryIndicator: false,
        fundingMethod: 'FI',
        coveragePlanNumber: null,
        taskOpen: false,
        missingInfo: [
        ],
        claimProgress: 'Claim Closed',
        extensionRequestDate: null,
        babyDeathIndicator: null,
        recentClaimIndicator: false,
        semiMonthlyPaymentIndicator: null,
        concurrentLeaveIDs: [
          '2153713'
        ],
        sourceSystemId: 'AA',
        personId: '11169742',
        relatedClaims: [
          {
            relatedClaimList: [
            ],
            hasRelatedClaims: false
          }
        ],
        rtwStatusCode: '42003',
        oowReason: 'Employee\'s own illness',
        form: '02'
      },

      {claimEventId: '2231928',
        claimType: 'Paid Family Leave',
        claimSubType: 'NY Paid Family Leave',
        coverageType: null,
        coverageCategory: 'PFL',
        disabilityDate: '03/02/2021',
        effectiveDate: null,
        authorizedEndDate: '03/09/2021',
        claimStatus: 'Open',
        claimStatusCode: '0',
        claimOnlineStatus: 'Claim Approved',
        claimOnlineStatusCode: '04',
        claimOnlineSubStatus: 'Payments in Progress',
        claimOnlineSubStatusCode: '06',
        extensionStatus: 'Extension Under Review',
        extensionStatusCode: '1',
        extensionSubStatus: 'In Adjudication',
        extensionSubStatusCode: '2',
        claimOfficeNumber: null,
        claimOfficeId: null,
        caseId: '0516115',
        claimProcessDate: '04/07/2021',
        claimEffectiveDate: null,
        reason: 'Disability Supported',
        physicianStatementDate: '04/03/2021',
        employerStatementDate: '03/30/2021',
        employeeStatementDate: '04/07/2021',
        estimatedSurgeryDate: null,
        estimatedDeliveryDate: null,
        lastDayWork: '03/01/2021',
        asmIndicator: 'A',
        confirmedLastDayWorkDate: '03/01/2021',
        confirmedSurgeryDate: null,
        confirmedDeliveryDate: null,
        encIndicator: false,
        encSurgeryIndicator: false,
        fundingMethod: 'FI',
        coveragePlanNumber: null,
        taskOpen: false,
        missingInfo: [
        ],
        claimProgress: 'In Payment',
        extensionRequestDate: null,
        babyDeathIndicator: null,
        recentClaimIndicator: false,
        semiMonthlyPaymentIndicator: null,
        concurrentLeaveIDs: [
          '2221929'
        ],
        sourceSystemId: 'AA',
        personId: '11189718',
        relatedClaims: [
          {
            relatedClaimList: [
            ],
            hasRelatedClaims: false
          }
        ],
        rtwStatusCode: '42002',
        oowReason: 'Employee\'s own illness',
        form: '02'
      },
      {claimEventId: '2221928',
  claimType: 'Short Term Disability',
  claimSubType: 'Short Term Disability',
  coverageType: 'NST',
  coverageCategory: 'WD',
  disabilityDate: '03/02/2021',
  effectiveDate: null,
  authorizedEndDate: '03/09/2021',
  claimStatus: 'Open',
  claimStatusCode: '0',
  claimOnlineStatus: 'Claim Approved',
  claimOnlineStatusCode: '04',
  claimOnlineSubStatus: 'Payments in Progress',
  claimOnlineSubStatusCode: '06',
  extensionStatus: 'Extension Under Review',
  extensionStatusCode: '1',
  extensionSubStatus: 'In Adjudication',
  extensionSubStatusCode: '2',
  claimOfficeNumber: null,
  claimOfficeId: null,
  caseId: '0516115',
  claimProcessDate: '04/07/2021',
  claimEffectiveDate: null,
  reason: 'Disability Supported',
  physicianStatementDate: '04/03/2021',
  employerStatementDate: '03/30/2021',
  employeeStatementDate: '04/07/2021',
  estimatedSurgeryDate: null,
  estimatedDeliveryDate: null,
  lastDayWork: '03/01/2021',
  asmIndicator: 'A',
  confirmedLastDayWorkDate: '03/01/2021',
  confirmedSurgeryDate: null,
  confirmedDeliveryDate: null,
  encIndicator: false,
  encSurgeryIndicator: false,
  fundingMethod: 'FI',
  coveragePlanNumber: null,
  taskOpen: false,
  missingInfo: [
],
  claimProgress: 'In Payment',
  extensionRequestDate: null,
  babyDeathIndicator: null,
  recentClaimIndicator: false,
  semiMonthlyPaymentIndicator: null,
  concurrentLeaveIDs: [
  '2221929'
],
  sourceSystemId: 'AA',
  personId: '11189718',
  relatedClaims: [
  {
    relatedClaimList: [
    ],
    hasRelatedClaims: false
  }
],
  rtwStatusCode: '42002',
  oowReason: 'Employee\'s own illness',
  form: '02'
},
      {
        claimEventId: '2031285',
        claimType: 'Paid Family Leave',
        claimSubType: 'CA Paid Family Leave',
        coverageType: null,
        coverageCategory: 'PFL',
        disabilityDate: '01/06/2021',
        effectiveDate: null,
        authorizedEndDate: '01/26/2021',
        claimStatus: 'Open',
        claimStatusCode: '0',
        claimOnlineStatus: 'Claim Approved',
        claimOnlineStatusCode: '04',
        claimOnlineSubStatus: 'Payments in Progress',
        claimOnlineSubStatusCode: '06',
        extensionStatus: 'Extension Under Review',
        extensionStatusCode: '1',
        extensionSubStatus: 'Missing Information',
        extensionSubStatusCode: '1',
        claimOfficeNumber: null,
        claimOfficeId: null,
        caseId: '0516115',
        claimProcessDate: '02/03/2021',
        claimEffectiveDate: null,
        reason: 'Disability Supported',
        physicianStatementDate: '01/22/2021',
        employerStatementDate: '01/22/2021',
        employeeStatementDate: '01/21/2021',
        estimatedSurgeryDate: null,
        estimatedDeliveryDate: null,
        lastDayWork: '01/05/2021',
        asmIndicator: 'P',
        confirmedLastDayWorkDate: '01/05/2021',
        confirmedSurgeryDate: null,
        confirmedDeliveryDate: null,
        encIndicator: false,
        encSurgeryIndicator: false,
        fundingMethod: 'FI',
        coveragePlanNumber: null,
        taskOpen: false,
        missingInfo: [
        ],
        claimProgress: 'In Payment',
        extensionRequestDate: '04/08/2021',
        babyDeathIndicator: null,
        recentClaimIndicator: false,
        semiMonthlyPaymentIndicator: null,
        concurrentLeaveIDs: [
          '2030287'
        ],
        sourceSystemId: 'AA',
        personId: '11129506',
        relatedClaims: [
          {
            relatedClaimList: [
              {
                LinkedSTAT: '2030286',
                LinkedSTD: null,
                LinkedLTD: null,
                LinkedABL: null,
                LinkedPFL: null,
                LinkedCPFL: null,
                LinkedB: null,
                LinkedP: null,
                LinkedSTDB: null,
                LinkedPW: null
              }
            ],
            hasRelatedClaims: true
          }
        ],
        rtwStatusCode: '42002',
        oowReason: 'Employee\'s own illness',
        form: '02'
      },
      {
        claimEventId: '2030285',
        claimType: 'Short Term Disability',
        claimSubType: 'Short Term Disability',
        coverageType: 'NST',
        coverageCategory: 'WD',
        disabilityDate: '01/06/2021',
        effectiveDate: null,
        authorizedEndDate: '01/26/2021',
        claimStatus: 'Open',
        claimStatusCode: '0',
        claimOnlineStatus: 'Claim Approved',
        claimOnlineStatusCode: '04',
        claimOnlineSubStatus: 'Payments in Progress',
        claimOnlineSubStatusCode: '06',
        extensionStatus: 'Extension Under Review',
        extensionStatusCode: '1',
        extensionSubStatus: 'Missing Information',
        extensionSubStatusCode: '1',
        claimOfficeNumber: null,
        claimOfficeId: null,
        caseId: '0516115',
        claimProcessDate: '02/03/2021',
        claimEffectiveDate: null,
        reason: 'Disability Supported',
        physicianStatementDate: '01/22/2021',
        employerStatementDate: '01/22/2021',
        employeeStatementDate: '01/21/2021',
        estimatedSurgeryDate: null,
        estimatedDeliveryDate: null,
        lastDayWork: '01/05/2021',
        asmIndicator: 'A',
        confirmedLastDayWorkDate: '01/05/2021',
        confirmedSurgeryDate: null,
        confirmedDeliveryDate: null,
        encIndicator: false,
        encSurgeryIndicator: false,
        fundingMethod: 'FI',
        coveragePlanNumber: null,
        taskOpen: false,
        missingInfo: [
        ],
        claimProgress: 'In Payment',
        extensionRequestDate: '04/08/2021',
        babyDeathIndicator: null,
        recentClaimIndicator: false,
        semiMonthlyPaymentIndicator: null,
        concurrentLeaveIDs: [
          '2030287'
        ],
        sourceSystemId: 'AA',
        personId: '11129506',
        relatedClaims: [
          {
            relatedClaimList: [
              {
                LinkedSTAT: '2030286',
                LinkedSTD: null,
                LinkedLTD: null,
                LinkedABL: null,
                LinkedPFL: null,
                LinkedCPFL: null,
                LinkedB: null,
                LinkedP: null,
                LinkedSTDB: null,
                LinkedPW: null
              }
            ],
            hasRelatedClaims: true
          }
        ],
        rtwStatusCode: '42002',
        oowReason: 'Employee\'s own illness',
        form: '02'
      },
      {
        claimEventId: '2196825',
        claimType: 'Paid Family Leave',
        claimSubType: 'NY Paid Family Leave',
        coverageType: null,
        coverageCategory: 'PFL',
        disabilityDate: '02/09/2021',
        effectiveDate: null,
        authorizedEndDate: '02/11/2021',
        claimStatus: 'Open',
        claimStatusCode: '0',
        claimOnlineStatus: 'Claim Approved',
        claimOnlineStatusCode: '04',
        claimOnlineSubStatus: 'Benefit Payments Complete',
        claimOnlineSubStatusCode: '04',
        extensionStatus: 'Extension Under Review',
        extensionStatusCode: '1',
        extensionSubStatus: 'Missing Information',
        extensionSubStatusCode: '8',
        claimOfficeNumber: null,
        claimOfficeId: null,
        caseId: '0516115',
        claimProcessDate: '05/12/2021',
        claimEffectiveDate: null,
        reason: 'Administrative',
        physicianStatementDate: null,
        employerStatementDate: null,
        employeeStatementDate: '03/30/2021',
        estimatedSurgeryDate: null,
        estimatedDeliveryDate: null,
        lastDayWork: '01/18/2021',
        asmIndicator: 'P',
        confirmedLastDayWorkDate: '01/18/2021',
        confirmedSurgeryDate: null,
        confirmedDeliveryDate: null,
        encIndicator: false,
        encSurgeryIndicator: false,
        fundingMethod: 'FI',
        coveragePlanNumber: null,
        taskOpen: false,
        missingInfo: [
          {
            mInfoCode: '10023366',
            mInfoDesc: null,
            requiredByDate: null,
            mInfoReqInfo: [
              {
                mInfoReqInfoCd: '63833',
                mInfoReqInfoDesc: null,
                requestDate: null,
                displayValue: 'Gap Information',
                chatInd: false,
                callInd: false,
                uploadInd: false,
                detailInd: false,
                formType: null,
                listPhysicain: null
              }
            ],
            alertItemSource: null,
            alertId: null,
            alertTitle: null,
            gracePeriod: false
          },
          {
            mInfoCode: '10023362',
            mInfoDesc: null,
            requiredByDate: null,
            mInfoReqInfo: [
              {
                mInfoReqInfoCd: '63834',
                mInfoReqInfoDesc: null,
                requestDate: null,
                displayValue: 'Cause of Disabling Condition',
                chatInd: false,
                callInd: false,
                uploadInd: false,
                detailInd: false,
                formType: null,
                listPhysicain: null
              }
            ],
            alertItemSource: null,
            alertId: null,
            alertTitle: null,
            gracePeriod: false
          },
          {
            mInfoCode: '10023366',
            mInfoDesc: null,
            requiredByDate: null,
            mInfoReqInfo: [
              {
                mInfoReqInfoCd: '63832',
                mInfoReqInfoDesc: null,
                requestDate: null,
                displayValue: 'Workers\' Compensation Denial Letter',
                chatInd: false,
                callInd: false,
                uploadInd: false,
                detailInd: false,
                formType: null,
                listPhysicain: null
              }
            ],
            alertItemSource: null,
            alertId: null,
            alertTitle: null,
            gracePeriod: false
          },
          {
            mInfoCode: '10023362',
            mInfoDesc: null,
            requiredByDate: null,
            mInfoReqInfo: [
              {
                mInfoReqInfoCd: '63828',
                mInfoReqInfoDesc: null,
                requestDate: null,
                displayValue: 'Clarification Reply',
                chatInd: false,
                callInd: false,
                uploadInd: false,
                detailInd: false,
                formType: null,
                listPhysicain: null
              }
            ],
            alertItemSource: null,
            alertId: null,
            alertTitle: null,
            gracePeriod: false
          }
        ],
        claimProgress: 'In Payment',
        extensionRequestDate: null,
        babyDeathIndicator: null,
        recentClaimIndicator: false,
        semiMonthlyPaymentIndicator: null,
        concurrentLeaveIDs: [
          '2196826'
        ],
        sourceSystemId: 'AA',
        personId: '11170577',
        relatedClaims: [
          {
            relatedClaimList: [
            ],
            hasRelatedClaims: false
          }
        ],
        rtwStatusCode: '42002',
        oowReason: 'Bonding/Adoption/Foster Care',
        form: '02',
        leaveFirstName: 'sarah',
        leaveLastName: 'ali',
        leaveReason: 'Bond with a New Child',
        relationship: 'Newborn',
        insuredFirstName: 'PHIL',
        insuredLastName: 'DYER',
        payeeType: null
      },
      {
        claimEventId: '2156947',
        claimType: 'Paid Family Leave',
        claimSubType: 'NY Paid Family Leave',
        coverageType: null,
        coverageCategory: 'PFL',
        disabilityDate: '02/02/2021',
        effectiveDate: null,
        authorizedEndDate: '02/09/2021',
        claimStatus: 'Open',
        claimStatusCode: '0',
        claimOnlineStatus: 'Claim Approved',
        claimOnlineStatusCode: '04',
        claimOnlineSubStatus: 'Benefit Payments Complete',
        claimOnlineSubStatusCode: '04',
        extensionStatus: 'Extension Under Review',
        extensionStatusCode: '1',
        extensionSubStatus: 'Missing Information',
        extensionSubStatusCode: '1',
        claimOfficeNumber: null,
        claimOfficeId: null,
        caseId: '0516115',
        claimProcessDate: '05/12/2021',
        claimEffectiveDate: null,
        reason: 'Administrative',
        physicianStatementDate: null,
        employerStatementDate: null,
        employeeStatementDate: '03/12/2021',
        estimatedSurgeryDate: null,
        estimatedDeliveryDate: null,
        lastDayWork: '02/01/2021',
        asmIndicator: 'P',
        confirmedLastDayWorkDate: '02/01/2021',
        confirmedSurgeryDate: null,
        confirmedDeliveryDate: null,
        encIndicator: false,
        encSurgeryIndicator: false,
        fundingMethod: 'FI',
        coveragePlanNumber: null,
        taskOpen: false,
        missingInfo: [
        ],
        claimProgress: 'In Payment',
        extensionRequestDate: null,
        babyDeathIndicator: null,
        recentClaimIndicator: false,
        semiMonthlyPaymentIndicator: null,
        concurrentLeaveIDs: [
          '2156948'
        ],
        sourceSystemId: 'AA',
        personId: '11170577',
        relatedClaims: [
          {
            relatedClaimList: [
            ],
            hasRelatedClaims: false
          }
        ],
        rtwStatusCode: '42002',
        oowReason: 'Bonding/Adoption/Foster Care',
        form: '02',
        leaveFirstName: '',
        leaveLastName: '',
        leaveReason: 'Bond with a New Child',
        relationship: 'Newborn',
        insuredFirstName: 'PHIL',
        insuredLastName: 'DYER',
        payeeType: null
      },
      {
        claimEventId: '2158814',
        claimType: 'Paid Family Leave',
        claimSubType: 'NY Paid Family Leave',
        coverageType: null,
        coverageCategory: 'PFL',
        disabilityDate: '02/02/2021',
        effectiveDate: null,
        authorizedEndDate: '02/09/2021',
        claimStatus: 'Open',
        claimStatusCode: '0',
        claimOnlineStatus: 'Claim Approved',
        claimOnlineStatusCode: '04',
        claimOnlineSubStatus: 'Benefit Payments Complete',
        claimOnlineSubStatusCode: '04',
        extensionStatus: 'Extension Under Review',
        extensionStatusCode: '1',
        extensionSubStatus: 'Missing Information',
        extensionSubStatusCode: '1',
        claimOfficeNumber: null,
        claimOfficeId: null,
        caseId: '0516115',
        claimProcessDate: '03/31/2021',
        claimEffectiveDate: null,
        reason: 'Administrative',
        physicianStatementDate: null,
        employerStatementDate: null,
        employeeStatementDate: '03/15/2021',
        estimatedSurgeryDate: null,
        estimatedDeliveryDate: null,
        lastDayWork: '02/01/2021',
        asmIndicator: 'P',
        confirmedLastDayWorkDate: '02/01/2021',
        confirmedSurgeryDate: null,
        confirmedDeliveryDate: null,
        encIndicator: false,
        encSurgeryIndicator: false,
        fundingMethod: 'FI',
        coveragePlanNumber: null,
        taskOpen: false,
        missingInfo: [
          {
            mInfoCode: '10023364',
            mInfoDesc: null,
            requiredByDate: null,
            mInfoReqInfo: [
              {
                mInfoReqInfoCd: '3306',
                mInfoReqInfoDesc: null,
                requestDate: null,
                displayValue: 'EMPLOYER TWO',
                chatInd: false,
                callInd: false,
                uploadInd: false,
                detailInd: false,
                formType: null,
                listPhysicain: null
              }
            ],
            alertItemSource: null,
            alertId: null,
            alertTitle: null,
            gracePeriod: false
          },
          {
            mInfoCode: '10023364',
            mInfoDesc: null,
            requiredByDate: null,
            mInfoReqInfo: [
              {
                mInfoReqInfoCd: '3305',
                mInfoReqInfoDesc: null,
                requestDate: null,
                displayValue: 'EMPLOYER ONE',
                chatInd: false,
                callInd: false,
                uploadInd: false,
                detailInd: false,
                formType: null,
                listPhysicain: null
              }
            ],
            alertItemSource: null,
            alertId: null,
            alertTitle: null,
            gracePeriod: false
          },
          {
            mInfoCode: '10023366',
            mInfoDesc: null,
            requiredByDate: null,
            mInfoReqInfo: [
              {
                mInfoReqInfoCd: '3304',
                mInfoReqInfoDesc: null,
                requestDate: null,
                displayValue: 'EMPLOYEE TWO',
                chatInd: false,
                callInd: false,
                uploadInd: false,
                detailInd: false,
                formType: null,
                listPhysicain: null
              }
            ],
            alertItemSource: null,
            alertId: null,
            alertTitle: null,
            gracePeriod: false
          },
          {
            mInfoCode: '10023362',
            mInfoDesc: null,
            requiredByDate: null,
            mInfoReqInfo: [
              {
                mInfoReqInfoCd: '3308',
                mInfoReqInfoDesc: null,
                requestDate: null,
                displayValue: 'MEDICAL TWO',
                chatInd: false,
                callInd: false,
                uploadInd: false,
                detailInd: false,
                formType: null,
                listPhysicain: null
              }
            ],
            alertItemSource: null,
            alertId: null,
            alertTitle: null,
            gracePeriod: false
          },
          {
            mInfoCode: '10023362',
            mInfoDesc: null,
            requiredByDate: null,
            mInfoReqInfo: [
              {
                mInfoReqInfoCd: '3307',
                mInfoReqInfoDesc: null,
                requestDate: null,
                displayValue: 'MEDICAL ONE',
                chatInd: false,
                callInd: false,
                uploadInd: false,
                detailInd: false,
                formType: null,
                listPhysicain: null
              }
            ],
            alertItemSource: null,
            alertId: null,
            alertTitle: null,
            gracePeriod: false
          },
          {
            mInfoCode: '10023366',
            mInfoDesc: null,
            requiredByDate: null,
            mInfoReqInfo: [
              {
                mInfoReqInfoCd: '3303',
                mInfoReqInfoDesc: null,
                requestDate: null,
                displayValue: 'EMPLOYEE ONE',
                chatInd: false,
                callInd: false,
                uploadInd: false,
                detailInd: false,
                formType: null,
                listPhysicain: null
              }
            ],
            alertItemSource: null,
            alertId: null,
            alertTitle: null,
            gracePeriod: false
          }
        ],
        claimProgress: 'In Payment',
        extensionRequestDate: '04/08/2021',
        babyDeathIndicator: null,
        recentClaimIndicator: false,
        semiMonthlyPaymentIndicator: null,
        concurrentLeaveIDs: [
          '2158815'
        ],
        sourceSystemId: 'AA',
        personId: '11171276',
        relatedClaims: [
          {
            relatedClaimList: [
            ],
            hasRelatedClaims: false
          }
        ],
        rtwStatusCode: '42002',
        oowReason: 'Bonding/Adoption/Foster Care',
        form: '02',
        leaveFirstName: 'will',
        leaveLastName: 'smith',
        leaveReason: 'Bond with a New Child',
        relationship: 'Newborn',
        insuredFirstName: 'CHARLES',
        insuredLastName: 'POOLE'
      },
      {
        claimEventId: '2216115',
        claimType: 'Short Term Disability',
        claimSubType: 'Short Term Disability',
        coverageType: 'NST',
        coverageCategory: 'WD',
        disabilityDate: '03/02/2021',
        effectiveDate: null,
        authorizedEndDate: '03/09/2021',
        claimStatus: 'Open',
        confirmReturnToWork: '1',
        claimStatusCode: '0',
        claimOnlineStatus: 'Claim Approved',
        claimOnlineStatusCode: '04',
        claimOnlineSubStatus: 'Payments in Progress',
        claimOnlineSubStatusCode: '06',
        extensionStatus: null,
        extensionStatusCode: null,
        extensionSubStatus: null,
        extensionSubStatusCode: null,
        claimOfficeNumber: null,
        claimOfficeId: null,
        caseId: '0516115',
        claimProcessDate: '04/06/2021',
        claimEffectiveDate: null,
        reason: 'Disability Supported',
        physicianStatementDate: '04/06/2021',
        employerStatementDate: '03/30/2021',
        employeeStatementDate: '04/06/2021',
        estimatedSurgeryDate: null,
        estimatedDeliveryDate: null,
        lastDayWork: '03/01/2021',
        asmIndicator: 'A',
        confirmedLastDayWorkDate: '03/01/2021',
        confirmedSurgeryDate: null,
        confirmedDeliveryDate: null,
        encIndicator: false,
        encSurgeryIndicator: false,
        fundingMethod: 'FI',
        coveragePlanNumber: null,
        taskOpen: false,
        missingInfo: [
        ],
        claimProgress: 'In Payment',
        extensionRequestDate: null,
        babyDeathIndicator: null,
        recentClaimIndicator: false,
        semiMonthlyPaymentIndicator: null,
        concurrentLeaveIDs: [
          '2216116'
        ],
        sourceSystemId: 'AA',
        personId: '11188176',
        relatedClaims: [
          {
            relatedClaimList: [
            ],
            hasRelatedClaims: false
          }
        ],
        rtwStatusCode: '42002',
        oowReason: 'Employee\'s own illness',
        form: '02'
      },

      {
        claimEventId: '1799799',
        claimType: 'Paid Family Leave',
        claimSubType: 'CA Paid Family Leave',
        confirmReturnToWork: '1',
        coverageType: null,
        coverageCategory: 'PFL',
        disabilityDate: '01/05/2021',
        effectiveDate: null,
        authorizedEndDate: null,
        claimStatus: 'Closed',
        claimStatusCode: '0',
        claimOnlineStatus: 'Claim Closed',
        claimOnlineStatusCode: '05',
        claimOnlineSubStatus: 'Claim Closed',
        claimOnlineSubStatusCode: '09',
        extensionStatus: null,
        extensionStatusCode: null,
        extensionSubStatus: null,
        extensionSubStatusCode: null,
        claimOfficeNumber: null,
        claimOfficeId: null,
        caseId: '0516115',
        claimProcessDate: '01/25/2021',
        claimEffectiveDate: null,
        reason: 'Return to Work',
        physicianStatementDate: null,
        employerStatementDate: null,
        employeeStatementDate: '01/25/2021',
        estimatedSurgeryDate: null,
        estimatedDeliveryDate: null,
        lastDayWork: '01/04/2021',
        asmIndicator: 'P',
        confirmedLastDayWorkDate: '01/04/2021',
        confirmedSurgeryDate: null,
        confirmedDeliveryDate: null,
        encIndicator: false,
        encSurgeryIndicator: false,
        fundingMethod: 'ATP wCalc',
        coveragePlanNumber: null,
        taskOpen: false,
        missingInfo: [
        ],
        claimProgress: 'Claim Closed',
        extensionRequestDate: null,
        babyDeathIndicator: null,
        recentClaimIndicator: false,
        semiMonthlyPaymentIndicator: null,
        concurrentLeaveIDs: [
          '1888889'
        ],
        sourceSystemId: 'AA',
        personId: '7469010',
        relatedClaims: [
          {
            relatedClaimList: [
            ],
            hasRelatedClaims: false
          }
        ],
        rtwStatusCode: '42004',
        oowReason: 'Bonding/Adoption/Foster Care',
        form: '02',
        leaveFirstName: '',
        leaveLastName: '',
        leaveReason: '',
        relationship: null
      },
      {
        claimEventId: '2294574',
        claimType: 'Paid Family Leave',
        claimSubType: 'NY Paid Family Leave',
        coverageType: null,
        confirmReturnToWork: '1',
        coverageCategory: 'PFL',
        disabilityDate: '04/27/2021',
        effectiveDate: null,
        authorizedEndDate: '05/19/2021',
        claimStatus: 'Open',
        claimStatusCode: '0',
        claimOnlineStatus: 'Claim Approved',
        claimOnlineStatusCode: '04',
        claimOnlineSubStatus: 'Payments in Progress',
        claimOnlineSubStatusCode: '06',
        extensionStatus: null,
        extensionStatusCode: null,
        extensionSubStatus: null,
        extensionSubStatusCode: null,
        claimOfficeNumber: null,
        claimOfficeId: null,
        caseId: '1572104',
        claimProcessDate: '05/18/2021',
        claimEffectiveDate: null,
        reason: 'Certified',
        physicianStatementDate: null,
        employerStatementDate: null,
        employeeStatementDate: '05/04/2021',
        estimatedSurgeryDate: null,
        estimatedDeliveryDate: null,
        lastDayWork: '04/26/2021',
        asmIndicator: 'P',
        confirmedLastDayWorkDate: '04/26/2021',
        confirmedSurgeryDate: null,
        confirmedDeliveryDate: null,
        encIndicator: false,
        encSurgeryIndicator: false,
        fundingMethod: 'FI',
        coveragePlanNumber: null,
        taskOpen: false,
        missingInfo: [
        ],
        claimProgress: 'In Payment',
        extensionRequestDate: null,
        babyDeathIndicator: null,
        recentClaimIndicator: false,
        semiMonthlyPaymentIndicator: null,
        concurrentLeaveIDs: [
        ],
        sourceSystemId: 'AA',
        personId: '11212241',
        relatedClaims: [
          {
            relatedClaimList: [
            ],
            hasRelatedClaims: false
          }
        ],
        rtwStatusCode: '42002',
        oowReason: 'Caregiver for Servicemember',
        form: '02',
        leaveFirstName: 'Dean',
        leaveLastName: 'Peralta',
        leaveReason: 'Military Exigency',
        relationship: 'Parent',
        insuredFirstName: 'JAKE',
        insuredLastName: 'PERALTA'
      },
      {
        LTDmissingInfo: false,
        asmIndicator: 'P',
        authorizedEndDate: '03/22/2021',
        babyDeathIndicator: null,
        caseId: '0839213',
        claimEffectiveDate: null,
        claimEventId: '1122335',
        confirmReturnToWork: '1',
        claimOfficeId: null,
        claimOfficeNumber: null,
        claimOnlineStatus: 'Claim Closed',
        claimOnlineStatusCode: '05',
        claimOnlineSubStatus: 'Claim Closed',
        claimOnlineSubStatusCode: '08',
        claimProcessDate: '03/12/2021',
        claimProgress: 'Claim Closed',
        claimStatus: null,
        claimStatusCode: null,
        claimSubType: 'WA Paid Family Leave',
        claimType: 'Paid Family Leave',
        concurrentLeaveIDs: [],
        confirmedDeliveryDate: null,
        confirmedLastDayWorkDate: '03/18/2021',
        confirmedSurgeryDate: null,
        coverageCategory: 'PFL',
        coveragePlanNumber: null,
        coverageType: null,
        disabilityDate: '03/18/2021',
        effectiveDate: null,
        employeeStatementDate: '03/10/2021',
        employerStatementDate: null,
        encIndicator: false,
        encSurgeryIndicator: false,
        estimatedDeliveryDate: null,
        estimatedSurgeryDate: null,
        extensionRequestDate: null,
        extensionStatus: null,
        extensionStatusCode: null,
        extensionSubStatus: null,
        extensionSubStatusCode: null,
        fundingMethod: 'ATP wCalc',
        insuredFirstName: 'Danny',
        insuredLastName: 'Welch',
        leaveFirstName: 'Jerry',
        leaveLastName: 'Welch',
        leaveReason: 'Military Family Exigency',
        lastDayWork: '03/18/2021',
        missingInfo: [],
        payeeType: null,
        personId: '8486784',
        physicianStatementDate: null,
        reason: 'Certification',
        relationship: 'Son',
        oowReason: null,
        recentClaimIndicator: false,
        relatedClaims: [{relatedClaimList: [], hasRelatedClaims: false}],
        sourceSystemId: 'AA',
        taskOpen: false
      },
      {
        LTDmissingInfo: false,
        claimEventId: '17131480',
        sourceSystemId: 'DCS',
        concurrentLeaveIDs: null,
        claimType: 'Short Term Disability',
        claimSubType: 'Short Term Disability',
        coverageType: 'NST',
        coverageCategory: 'WD',
        disabilityDate: '01/05/2021',
        effectiveDate: '01/12/2021',
        authorizedEndDate: '01/29/2021',
        claimStatus: 'Terminate',
        claimStatusCode: '04',
        claimOnlineStatus: 'Claim Closed',
        claimOnlineStatusCode: '05',
        claimOnlineSubStatus: 'Claim Closed',
        claimOnlineSubStatusCode: '08',
        extensionStatus: 'Extension Under Review',
        extensionStatusCode: '1',
        extensionSubStatus: 'Missing Info',
        extensionSubStatusCode: '1',
        claimOfficeNumber: null,
        claimOfficeId: '733',
        caseId: '52353',
        claimProcessDate: '02/04/2021',
        claimEffectiveDate: '01/30/2021',
        reason: 'NO LONGER MEETS DEFINITION OF DISABILITY',
        physicianStatementDate: '02/02/2021',
        employerStatementDate: '01/26/2021',
        employeeStatementDate: '01/26/2021',
        estimatedSurgeryDate: null,
        estimatedDeliveryDate: null,
        lastDayWork: '01/04/2021',
        asmIndicator: 'S',
        confirmedLastDayWorkDate: '01/04/2021',
        confirmedSurgeryDate: null,
        confirmedDeliveryDate: null,
        encIndicator: false,
        encSurgeryIndicator: false,
        fundingMethod: 'FI',
        coveragePlanNumber: '1',
        taskOpen: true,
        missingInfo: [
          {
            mInfoCode: '03',
            mInfoDesc: 'Physician',
            requiredByDate: null,
            mInfoReqInfo: [
              {
                mInfoReqInfoCd: '19',
                mInfoReqInfoDesc: 'Attending Physician\'s Statement - Progress Report',
                requestDate: '02/05/2021',
                displayValue: 'Attending Physician\'s Statement',
                chatInd: false,
                callInd: false,
                uploadInd: true,
                detailInd: true,
                formType: 'generic',
                listPhysicain: [
                  {
                    physicianName: 'YOUR PHYSICIAN',
                    fromDataRange: null,
                    toDataRange: null
                  }
                ]
              }
            ],
            gracePeriod: false
          }
        ],
        claimProgress: 'Claim Closed',
        extensionRequestDate: '04/02/2021',
        babyDeathIndicator: null,
        recentClaimIndicator: true,
        insuredFirstName: 'Danny',
        insuredLastName: 'Welch',
        leaveFirstName: 'Thomas',
        leaveLastName: 'Welch',
        leaveReason: 'Bond with New Child',
        payeeType: null,
        personId: '8486784',
        relationship: 'Son',
        oowReason: null,
        relatedClaims: [{relatedClaimList: [], hasRelatedClaims: false}],
      },
      {
        claimEventId: '1335096',
        claimType: 'Paid Family Leave',
        claimSubType: 'CA Paid Family Leave',
        coverageType: null,
        coverageCategory: 'PFL',
        disabilityDate: '06/13/2019',
        effectiveDate: null,
        authorizedEndDate: '06/13/2020',
        claimStatus: 'Open',
        claimStatusCode: '0',
        claimOnlineStatus: 'Under Review by Claim Handler',
        claimOnlineStatusCode: '08',
        claimOnlineSubStatus: 'Missing Information',
        claimOnlineSubStatusCode: '13',
        extensionStatus: null,
        extensionStatusCode: null,
        extensionSubStatus: null,
        extensionSubStatusCode: null,
        claimOfficeNumber: null,
        claimOfficeId: null,
        caseId: '0839213',
        claimProcessDate: '06/06/2019',
        claimEffectiveDate: null,
        reason: 'Certification',
        oowReason: null,
        physicianStatementDate: null,
        employerStatementDate: null,
        employeeStatementDate: '05/29/2019',
        estimatedSurgeryDate: null,
        estimatedDeliveryDate: null,
        lastDayWork: '06/21/2019',
        asmIndicator: 'P',
        confirmedLastDayWorkDate: '7/2/2019',
        confirmedSurgeryDate: null,
        confirmedDeliveryDate: null,
        encIndicator: false,
        encSurgeryIndicator: false,
        fundingMethod: 'ATP wCalc',
        coveragePlanNumber: null,
        taskOpen: false,
        missingInfo: [
          {
            mInfoCode: '02',
            mInfoDesc: 'Employer',
            requiredByDate: '',
            mInfoReqInfo: [{
              mInfoReqInfoCd: '12',
              mInfoReqInfoDesc: '',
              requestDate: '4/9/2019',
              displayValue: 'Salary Continuation, PTO, or Sick Time Details',
              chatInd: false,
              callInd: false,
              uploadInd: false,
              detailInd: true,
              formType: 'generic',
              listPhysicain: [{
                physicianName: 'Dr.ABC',
                fromDataRange: '3/21/2019',
                toDataRange: ''
              }]
            }],
            gracePeriod: false
          }, {
            mInfoCode: '01',
            mInfoDesc: 'Employee',
            requiredByDate: '',
            mInfoReqInfo: [{
              mInfoReqInfoCd: '01',
              mInfoReqInfoDesc: '',
              requestDate: '3/21/2019',
              displayValue: 'Medical History Form',
              chatInd: false,
              callInd: false,
              uploadInd: true,
              detailInd: false,
              formType: 'generic',
              listPhysicain: [{
                physicianName: 'Dr.XYZ',
                fromDataRange: '3/21/2019',
                toDataRange: ''
              }]
            }],
            gracePeriod: false
          }, {
            mInfoCode: '03',
            mInfoDesc: 'Physician',
            requiredByDate: '',
            mInfoReqInfo: [ {
              mInfoReqInfoCd: '07',
              mInfoReqInfoDesc: '',
              requestDate: '3/21/2019',
              displayValue: 'Cause of Disabling Condition',
              chatInd: false,
              callInd: false,
              uploadInd: false,
              detailInd: true,
              formType: 'generic',
              listPhysicain: [{
                physicianName: 'test',
                fromDataRange: '3/21/2019',
                toDataRange: '3/22/2019'
              }]
            }],
            gracePeriod: false
          }],
        claimProgress: null,
        extensionRequestDate: null,
        babyDeathIndicator: null,
        recentClaimIndicator: false,
        concurrentLeaveIDs: ['1338103'],
        sourceSystemId: 'DCS',
        personId: '8486784',
        relatedClaims: [{
          relatedClaimList: [],
          hasRelatedClaims: false
        }]
      },
      {
        claimEventId: '131283',
        claimType: 'Short Term Disability',
        claimSubType: 'Short Term Disability',
        coverageType: 'N/A',
        coverageCategory: 'WD',
        disabilityDate: '04/22/2019',
        effectiveDate: null,
        authorizedEndDate: null,
        claimStatus: 'First Notice',
        claimStatusCode: '08',
        claimOnlineStatus: 'Claim Submitted',
        claimOnlineStatusCode: '03',
        claimOnlineSubStatus: 'Claim Submitted',
        claimOnlineSubStatusCode: '03',
        extensionStatus: null,
        extensionStatusCode: null,
        extensionSubStatus: null,
        extensionSubStatusCode: null,
        claimOfficeNumber: null,
        claimOfficeId: '17',
        caseId: '1114412',
        claimProcessDate: '02/18/2019',
        claimEffectiveDate: '02/18/2019',
        reason: 'N/A',
        oowReason: null,
        physicianStatementDate: null,
        employerStatementDate: null,
        employeeStatementDate: null,
        estimatedSurgeryDate: '02/18/2019',
        estimatedDeliveryDate: null,
        lastDayWork: null,
        asmIndicator: 'A',
        confirmedLastDayWorkDate: null,
        confirmedSurgeryDate: null,
        confirmedDeliveryDate: null,
        encIndicator: true,
        encSurgeryIndicator: true,
        fundingMethod: 'N/A',
        coveragePlanNumber: null,
        taskOpen: false,
        missingInfo: null,
        claimProgress: 'Claim Submitted',
        extensionRequestDate: null,
        babyDeathIndicator: null,
        recentClaimIndicator: true,
        concurrentLeaveIDs: null,
        sourceSystemId: 'DCS',
        personId: null,
        relatedClaims: [
          {
            relatedClaimList: [
              {
                LinkedSTAT: null,
                LinkedSTD: null,
                LinkedLTD: null,
                LinkedABL: null,
                LinkedPFL: null,
                LinkedCPFL: null,
                LinkedB: null,
                LinkedP: null,
                LinkedSTDB: null,
                LinkedPW: null
              }
            ],
            hasRelatedClaims: false
          }
        ]
      },
      {
        claimEventId: '131284',
        claimType: 'Short Term Disability',
        claimSubType: 'Short Term Disability',
        coverageType: 'N/A',
        coverageCategory: 'WD',
        disabilityDate: '01/22/2019',
        effectiveDate: null,
        authorizedEndDate: null,
        claimStatus: 'First Notice',
        claimStatusCode: '08',
        claimOnlineStatus: 'Claim Submitted',
        claimOnlineStatusCode: '07',
        claimOnlineSubStatus: 'Claim Submitted',
        claimOnlineSubStatusCode: '11',
        extensionStatus: null,
        extensionStatusCode: null,
        extensionSubStatus: null,
        extensionSubStatusCode: null,
        claimOfficeNumber: null,
        claimOfficeId: '17',
        caseId: '1114412',
        claimProcessDate: '02/18/2019',
        claimEffectiveDate: '02/18/2019',
        reason: 'N/A',
        oowReason: null,
        physicianStatementDate: null,
        employerStatementDate: null,
        employeeStatementDate: null,
        estimatedSurgeryDate: null,
        estimatedDeliveryDate: null,
        lastDayWork: null,
        asmIndicator: null,
        confirmedLastDayWorkDate: null,
        confirmedSurgeryDate: null,
        confirmedDeliveryDate: null,
        encIndicator: false,
        encSurgeryIndicator: false,
        fundingMethod: 'N/A',
        coveragePlanNumber: null,
        taskOpen: false,
        missingInfo: null,
        claimProgress: 'Claim Submitted',
        extensionRequestDate: null,
        babyDeathIndicator: null,
        recentClaimIndicator: true,
        concurrentLeaveIDs: null,
        sourceSystemId: 'DCS',
        personId: null,
        relatedClaims: [
          {
            relatedClaimList: [
              {
                LinkedSTAT: null,
                LinkedSTD: null,
                LinkedLTD: null,
                LinkedABL: null,
                LinkedPFL: null,
                LinkedCPFL: null,
                LinkedB: null,
                LinkedP: null,
                LinkedSTDB: null,
                LinkedPW: null
              }
            ],
            hasRelatedClaims: false
          }
        ]
      },
      {
        claimEventId: '700001',
        claimType: 'Short Term Disability',
        claimSubType: 'Short Term Disability',
        coverageType: 'N/A',
        coverageCategory: 'WD',
        disabilityDate: '05/03/2019',
        effectiveDate: null,
        authorizedEndDate: null,
        claimStatus: 'First Notice',
        claimStatusCode: '08',
        claimOnlineStatus: 'Claim Submitted',
        claimOnlineStatusCode: '07',
        claimOnlineSubStatus: 'Claim Submitted',
        claimOnlineSubStatusCode: '11',
        extensionStatus: null,
        extensionStatusCode: null,
        extensionSubStatus: null,
        extensionSubStatusCode: null,
        claimOfficeNumber: null,
        claimOfficeId: '17',
        caseId: '1114412',
        claimProcessDate: '02/18/2019',
        claimEffectiveDate: '02/18/2019',
        reason: 'N/A',
        oowReason: null,
        physicianStatementDate: null,
        employerStatementDate: null,
        employeeStatementDate: null,
        estimatedSurgeryDate: null,
        estimatedDeliveryDate: null,
        lastDayWork: null,
        asmIndicator: null,
        confirmedLastDayWorkDate: null,
        confirmedSurgeryDate: null,
        confirmedDeliveryDate: null,
        encIndicator: false,
        encSurgeryIndicator: false,
        fundingMethod: 'N/A',
        coveragePlanNumber: null,
        taskOpen: false,
        missingInfo: null,
        claimProgress: 'Claim Submitted',
        extensionRequestDate: null,
        babyDeathIndicator: null,
        recentClaimIndicator: true,
        concurrentLeaveIDs: [],
        sourceSystemId: 'DCS',
        personId: null,
        relatedClaims: [
          {
            relatedClaimList: [
              {
                LinkedSTAT: null,
                LinkedSTD: null,
                LinkedLTD: null,
                LinkedABL: null,
                LinkedPFL: null,
                LinkedCPFL: null,
                LinkedB: null,
                LinkedP: null,
                LinkedSTDB: null,
                LinkedPW: null
              }
            ],
            hasRelatedClaims: false
          }
        ]
      },
      {
        claimEventId: '100011',
        claimType: 'Short Term Disability',
        claimSubType: 'Short Term Disability',
        coverageType: 'N/A',
        coverageCategory: 'WD',
        disabilityDate: '05/03/2019',
        effectiveDate: null,
        authorizedEndDate: null,
        claimStatus: 'First Notice',
        claimStatusCode: '08',
        claimOnlineStatus: 'Claim Submitted',
        claimOnlineStatusCode: '07',
        claimOnlineSubStatus: 'Claim Submitted',
        claimOnlineSubStatusCode: '11',
        extensionStatus: null,
        extensionStatusCode: null,
        extensionSubStatus: null,
        extensionSubStatusCode: null,
        claimOfficeNumber: null,
        claimOfficeId: '17',
        caseId: '1114412',
        claimProcessDate: '02/18/2019',
        claimEffectiveDate: '02/18/2019',
        reason: 'N/A',
        oowReason: null,
        physicianStatementDate: null,
        employerStatementDate: null,
        employeeStatementDate: null,
        estimatedSurgeryDate: null,
        estimatedDeliveryDate: null,
        lastDayWork: null,
        asmIndicator: null,
        confirmedLastDayWorkDate: null,
        confirmedSurgeryDate: null,
        confirmedDeliveryDate: null,
        encIndicator: false,
        encSurgeryIndicator: false,
        fundingMethod: 'N/A',
        coveragePlanNumber: null,
        taskOpen: false,
        missingInfo: null,
        claimProgress: 'Claim Submitted',
        extensionRequestDate: null,
        babyDeathIndicator: null,
        recentClaimIndicator: true,
        concurrentLeaveIDs: null,
        sourceSystemId: 'AA',
        personId: null,
        relatedClaims: [
          {
            relatedClaimList: [
              {
                LinkedSTAT: null,
                LinkedSTD: null,
                LinkedLTD: null,
                LinkedABL: null,
                LinkedPFL: null,
                LinkedCPFL: null,
                LinkedB: null,
                LinkedP: null,
                LinkedSTDB: null,
                LinkedPW: null
              }
            ],
            hasRelatedClaims: false
          }
        ]
      },
      {
        claimEventId: '100012',
        claimType: 'Short Term Disability',
        claimSubType: 'Short Term Disability',
        coverageType: 'N/A',
        coverageCategory: 'WD',
        disabilityDate: '06/13/2019',
        effectiveDate: null,
        authorizedEndDate: '06/13/2020',
        claimStatus: 'Open',
        claimStatusCode: '0',
        claimOnlineStatus: 'Under Review by Claim Handler',
        claimOnlineStatusCode: '08',
        claimOnlineSubStatus: 'Missing Information',
        claimOnlineSubStatusCode: '13',
        extensionStatus: null,
        extensionStatusCode: null,
        extensionSubStatus: null,
        extensionSubStatusCode: null,
        claimOfficeNumber: null,
        claimOfficeId: null,
        caseId: '0839213',
        claimProcessDate: '06/06/2019',
        claimEffectiveDate: null,
        reason: 'Certification',
        oowReason: null,
        physicianStatementDate: null,
        employerStatementDate: null,
        employeeStatementDate: '05/29/2019',
        estimatedSurgeryDate: null,
        estimatedDeliveryDate: null,
        lastDayWork: '06/21/2019',
        asmIndicator: 'P',
        confirmedLastDayWorkDate: '7/2/2019',
        confirmedSurgeryDate: null,
        confirmedDeliveryDate: null,
        encIndicator: false,
        encSurgeryIndicator: false,
        fundingMethod: 'ATP wCalc',
        coveragePlanNumber: null,
        taskOpen: false,
        missingInfo: [
          {
            mInfoCode: '02',
            mInfoDesc: 'Employer',
            requiredByDate: '',
            mInfoReqInfo: [{
              mInfoReqInfoCd: '12',
              mInfoReqInfoDesc: '',
              requestDate: '4/9/2019',
              displayValue: 'Salary Continuation, PTO, or Sick Time Details',
              chatInd: false,
              callInd: false,
              uploadInd: false,
              detailInd: true,
              formType: 'generic',
              listPhysicain: [{
                physicianName: 'Dr.ABC',
                fromDataRange: '3/21/2019',
                toDataRange: ''
              }]
            }],
            gracePeriod: false
          }, {
            mInfoCode: '01',
            mInfoDesc: 'Employee',
            requiredByDate: '',
            mInfoReqInfo: [{
              mInfoReqInfoCd: '01',
              mInfoReqInfoDesc: '',
              requestDate: '3/21/2019',
              displayValue: 'Medical History Form',
              chatInd: false,
              callInd: false,
              uploadInd: true,
              detailInd: false,
              formType: 'generic',
              listPhysicain: [{
                physicianName: 'Dr.XYZ',
                fromDataRange: '3/21/2019',
                toDataRange: ''
              }]
            }],
            gracePeriod: false
          }, {
            mInfoCode: '03',
            mInfoDesc: 'Physician',
            requiredByDate: '',
            mInfoReqInfo: [ {
              mInfoReqInfoCd: '07',
              mInfoReqInfoDesc: '',
              requestDate: '3/21/2019',
              displayValue: 'Cause of Disabling Condition',
              chatInd: false,
              callInd: false,
              uploadInd: false,
              detailInd: true,
              formType: 'generic',
              listPhysicain: [{
                physicianName: 'test',
                fromDataRange: '3/21/2019',
                toDataRange: '3/22/2019'
              }]
            }],
            gracePeriod: false
          }],
        sourceSystemId: 'AA',
        personId: '7055402',
        relatedClaims: [{
          relatedClaimList: [{
            LinkedSTAT: null,
            LinkedSTD: '1561057',
            LinkedLTD: null,
            LinkedABL: null,
            LinkedPFL: null,
            LinkedCPFL: null,
            LinkedB: null,
            LinkedP: null,
            LinkedSTDB: null,
            LinkedPW: null
          }],
          hasRelatedClaims: true
        }],
        rtwStatusCode: '42001',
        relationship: null
      }],
    relatedClaims: [
      {
        claimEventId: '1999999',
        claimType: 'Short Term Disability',
        claimSubType: 'State-Mandated Disability/Surgery',
        coverageType: 'STAT',
        coverageCategory: 'WD',
        disabilityDate: '04/02/2020',
        effectiveDate: null,
        authorizedEndDate: null,
        claimStatus: 'Pend',
        claimStatusCode: '0',
        claimOnlineStatus: 'Awaiting Surgery Details',
        claimOnlineStatusCode: '03',
        claimOnlineSubStatus: 'Awaiting Surgery Details',
        claimOnlineSubStatusCode: '03',
        extensionStatus: null,
        extensionStatusCode: null,
        extensionSubStatus: null,
        extensionSubStatusCode: null,
        claimOfficeNumber: null,
        claimOfficeId: null,
        caseId: '1099157',
        claimProcessDate: '03/31/2020',
        claimEffectiveDate: null,
        reason: 'Additional Medical',
        physicianStatementDate: null,
        employerStatementDate: null,
        employeeStatementDate: '02/24/2020',
        estimatedSurgeryDate: '05/8/2020', //Need to apply check for time
        estimatedDeliveryDate: null,
        lastDayWork: '04/02/2020',
        asmIndicator: 'A',
        confirmedLastDayWorkDate: '04/02/2020',
        confirmedSurgeryDate: null,
        confirmedDeliveryDate: null,
        encIndicator: false,
        encSurgeryIndicator: false,
        fundingMethod: 'ATP',
        coveragePlanNumber: null,
        taskOpen: false,
        missingInfo: [],
        claimProgress: 'Awaiting Surgery',
        extensionRequestDate: null,
        babyDeathIndicator: null,
        recentClaimIndicator: false,
        semiMonthlyPaymentIndicator: null,
        concurrentLeaveIDs: ['1561060'],
        sourceSystemId: 'AA',
        personId: '7055402',
        relatedClaims: [{
          relatedClaimList: [{
            LinkedSTAT: null,
            LinkedSTD: '1561057',
            LinkedLTD: null,
            LinkedABL: null,
            LinkedPFL: null,
            LinkedCPFL: null,
            LinkedB: null,
            LinkedP: null,
            LinkedSTDB: null,
            LinkedPW: null
          }],
          hasRelatedClaims: true
        }],
        rtwStatusCode: '42001',
        oowReason: 'Employee\'s own illness',
        relationship: null
      }, {
        claimEventId: '1561058',
        claimType: 'Short Term Disability',
        claimSubType: 'State-Mandated Disability/Surgery',
        coverageType: 'STAT',
        coverageCategory: 'WD',
        disabilityDate: '04/02/2020',
        effectiveDate: null,
        authorizedEndDate: null,
        claimStatus: 'Pend',
        claimStatusCode: '0',
        claimOnlineStatus: 'Awaiting Surgery Details',
        claimOnlineStatusCode: '05',
        claimOnlineSubStatus: 'Awaiting Surgery Details',
        claimOnlineSubStatusCode: '08',
        extensionStatus: null,
        extensionStatusCode: null,
        extensionSubStatus: null,
        extensionSubStatusCode: null,
        claimOfficeNumber: null,
        claimOfficeId: null,
        caseId: '1099157',
        claimProcessDate: '03/31/2020',
        claimEffectiveDate: null,
        reason: 'Additional Medical',
        physicianStatementDate: null,
        employerStatementDate: null,
        employeeStatementDate: '02/24/2020',
        estimatedSurgeryDate: '04/03/2020',
        estimatedDeliveryDate: null,
        lastDayWork: '04/02/2020',
        asmIndicator: 'A',
        confirmedLastDayWorkDate: '04/02/2020',
        confirmedSurgeryDate:  null,
        confirmedDeliveryDate: null,
        encIndicator: false,
        encSurgeryIndicator: false,
        fundingMethod: 'ATP',
        coveragePlanNumber: null,
        taskOpen: false,
        missingInfo: [],
        claimProgress: 'Awaiting Surgery',
        extensionRequestDate: null,
        babyDeathIndicator: null,
        recentClaimIndicator: false,
        semiMonthlyPaymentIndicator: null,
        concurrentLeaveIDs: ['1561060'],
        sourceSystemId: 'AA',
        personId: '7055402',
        relatedClaims: [            {
          relatedClaimList: [               {
            LinkedSTAT: null,
            LinkedSTD: '1561057',
            LinkedLTD: null,
            LinkedABL: null,
            LinkedPFL: null,
            LinkedCPFL: null,
            LinkedB: null,
            LinkedP: null,
            LinkedSTDB: null,
            LinkedPW: null
          }],
          hasRelatedClaims: true
        }],
        rtwStatusCode: '42001',
        oowReason: 'Employee\'s own illness',
        relationship: null
      },
      {
        claimEventId: '1561057',
        claimType: 'Short Term Disability',
        claimSubType: 'Surgery',
        coverageType: 'NST',
        coverageCategory: 'WD',
        disabilityDate: '03/18/2020',
        effectiveDate: null,
        authorizedEndDate: null,
        claimStatus: 'Pend',
        claimStatusCode: '0',
        claimOnlineStatus: 'Under Review by Claim Handler',
        claimOnlineStatusCode: '08',
        claimOnlineSubStatus: 'In Adjudication',
        claimOnlineSubStatusCode: '12',
        extensionStatus: null,
        extensionStatusCode: null,
        extensionSubStatus: null,
        extensionSubStatusCode: null,
        claimOfficeNumber: null,
        claimOfficeId: null,
        caseId: '1099157',
        claimProcessDate: '03/31/2020',
        claimEffectiveDate: null,
        reason: 'New Claim',
        physicianStatementDate: null,
        employerStatementDate: null,
        employeeStatementDate: '02/24/2020',
        estimatedSurgeryDate: '04/03/2020',
        estimatedDeliveryDate: null,
        lastDayWork: '03/18/2020',
        asmIndicator: 'A',
        confirmedLastDayWorkDate: '03/18/2020',
        confirmedSurgeryDate: '04/03/2020',
        confirmedDeliveryDate: null,
        encIndicator: false,
        encSurgeryIndicator: false,
        fundingMethod: null,
        coveragePlanNumber: null,
        taskOpen: false,
        missingInfo: [],
        claimProgress: 'Under Review',
        extensionRequestDate: null,
        babyDeathIndicator: null,
        recentClaimIndicator: false,
        semiMonthlyPaymentIndicator: null,
        concurrentLeaveIDs: ['1561060'],
        sourceSystemId: 'AA',
        personId: '7055402',
        relatedClaims: [            {
          relatedClaimList: [               {
            LinkedSTAT: '1561058',
            LinkedSTD: null,
            LinkedLTD: null,
            LinkedABL: null,
            LinkedPFL: null,
            LinkedCPFL: null,
            LinkedB: null,
            LinkedP: null,
            LinkedSTDB: '1561059',
            LinkedPW: null
          }],
          hasRelatedClaims: true
        }],
        rtwStatusCode: '42001',
        oowReason: 'Employee\'s own illness',
        relationship: null
      }, {
        claimEventId: '700002',
        claimType: 'Long Term Disability',
        claimSubType: 'Long Term Disability',
        coverageType: 'N/A',
        coverageCategory: 'LTD',
        disabilityDate: '03/01/2020',
        effectiveDate: null,
        authorizedEndDate: null,
        claimStatus: 'First Notice',
        claimStatusCode: '08',
        claimOnlineStatus: 'Claim Submitted',
        claimOnlineStatusCode: null,
        claimOnlineSubStatus: 'Claim Submitted',
        claimOnlineSubStatusCode: null,
        extensionStatus: null,
        extensionStatusCode: null,
        extensionSubStatus: null,
        extensionSubStatusCode: null,
        claimOfficeNumber: null,
        claimOfficeId: '17',
        caseId: '1114412',
        claimProcessDate: '02/18/2019',
        claimEffectiveDate: '02/18/2019',
        reason: 'N/A',
        oowReason: null,
        physicianStatementDate: null,
        employerStatementDate: null,
        employeeStatementDate: null,
        estimatedSurgeryDate: null,
        estimatedDeliveryDate: null,
        lastDayWork: null,
        asmIndicator: null,
        confirmedLastDayWorkDate: null,
        confirmedSurgeryDate: null,
        confirmedDeliveryDate: null,
        encIndicator: false,
        encSurgeryIndicator: false,
        fundingMethod: 'N/A',
        coveragePlanNumber: null,
        taskOpen: false,
        missingInfo: [],
        claimProgress: 'Claim Submitted',
        extensionRequestDate: null,
        babyDeathIndicator: null,
        recentClaimIndicator: true,
        concurrentLeaveIDs: [],
        sourceSystemId: 'AA',
        personId: null,
        rtwStatusCode : '0',
        relatedClaims: [
          {
            relatedClaimList: [
              {
                LinkedSTAT: null,
                LinkedSTD: null,
                LinkedLTD: null,
                LinkedABL: null,
                LinkedPFL: null,
                LinkedCPFL: null,
                LinkedB: null,
                LinkedP: null,
                LinkedSTDB: null,
                LinkedPW: null
              }
            ],
            hasRelatedClaims: false
          }
        ]
      },
      {
        claimEventId: '700003',
        claimType: 'Short Term Disability',
        claimSubType: 'Short Term Disability',
        coverageType: 'N/A',
        coverageCategory: 'WD',
        disabilityDate: '05/15/2019',
        effectiveDate: null,
        authorizedEndDate: null,
        claimStatus: 'First Notice',
        claimStatusCode: '08',
        claimOnlineStatus: 'Claim Submitted',
        claimOnlineStatusCode: '03',
        claimOnlineSubStatus: 'Claim Submitted',
        claimOnlineSubStatusCode: '03',
        extensionStatus: null,
        extensionStatusCode: null,
        extensionSubStatus: null,
        extensionSubStatusCode: null,
        claimOfficeNumber: null,
        claimOfficeId: '17',
        caseId: '1114412',
        claimProcessDate: '02/18/2019',
        claimEffectiveDate: '02/18/2019',
        reason: 'N/A',
        oowReason: null,
        physicianStatementDate: null,
        employerStatementDate: null,
        employeeStatementDate: null,
        estimatedSurgeryDate: '02/18/2019',
        estimatedDeliveryDate: null,
        lastDayWork: null,
        asmIndicator: 'A',
        confirmedLastDayWorkDate: null,
        confirmedSurgeryDate: null,
        confirmedDeliveryDate: null,
        encIndicator: true,
        encSurgeryIndicator: true,
        fundingMethod: 'N/A',
        coveragePlanNumber: null,
        taskOpen: false,
        missingInfo: null,
        claimProgress: 'Claim Submitted',
        extensionRequestDate: null,
        babyDeathIndicator: null,
        recentClaimIndicator: true,
        concurrentLeaveIDs: [],
        sourceSystemId: 'DCS',
        rtwStatusCode : '0',
        personId: null,
        relatedClaims: [
          {
            relatedClaimList: [
              {
                LinkedSTAT: null,
                LinkedSTD: null,
                LinkedLTD: null,
                LinkedABL: null,
                LinkedPFL: null,
                LinkedCPFL: null,
                LinkedB: null,
                LinkedP: null,
                LinkedSTDB: null,
                LinkedPW: null
              }
            ],
            hasRelatedClaims: false
          }
        ]
      },
      {
        claimEventId: '100001',
        claimType: 'Short Term Disability',
        claimSubType: 'Short Term Disability',
        coverageType: null,
        coverageCategory: 'STD',
        disabilityDate: '02/05/2019',
        effectiveDate: null,
        authorizedEndDate: '3/6/2019',
        claimStatus: 'Open',
        claimStatusCode: '0',
        claimOnlineStatus: 'CLAIM APPROVED',
        claimOnlineStatusCode: '04',
        claimOnlineSubStatus: 'PAYMENTS IN PROGRESS',
        claimOnlineSubStatusCode: '06',
        extensionStatus: null,
        extensionStatusCode: null,
        extensionSubStatus: null,
        extensionSubStatusCode: null,
        claimOfficeNumber: null,
        claimOfficeId: null,
        caseId: '0429387',
        claimProcessDate: '02/05/2019',
        claimEffectiveDate: null,
        reason: 'Administrative',
        oowReason: null,
        physicianStatementDate: '0',
        employerStatementDate: '0',
        employeeStatementDate: '0',
        estimatedSurgeryDate: '',
        estimatedDeliveryDate: '',
        lastDayWork: '02/04/2019',
        asmIndicator: 'A',
        confirmedLastDayWorkDate: '02/04/2019',
        confirmedSurgeryDate: '',
        confirmedDeliveryDate: '',
        encIndicator: false,
        encSurgeryIndicator: false,
        fundingMethod: 'FI',
        coveragePlanNumber: null,
        taskOpen: false,
        missingInfo: [],
        claimProgress: 'Claim Submitted',
        extensionRequestDate: null,
        babyDeathIndicator: null,
        recentClaimIndicator: false,
        rtwStatusCode : '0',
        concurrentLeaveIDs: [
          '200001'
        ],
        sourceSystemId: 'AA',
        personId: '6252599',
        relatedClaims: [
          {
            relatedClaimList: [
              {
                LinkedLTD: null,
                LinkedABL: null,
                ParentLTD: null,
                LinkedB: null,
                LinkedP: null,
                LinkedPW: null,
                LinkedPFL: null,
                LinkedCPFL: null,
                LinkedSTD: '100002',
                LinkedSTDB: null,
                LinkedSTAT: '100010',
              }
            ],
            hasRelatedClaims: true
          }
        ]
      },
      {
        claimEventId: '100002',
        claimType: 'Short Term Disability',
        claimSubType: 'Short Term Disability',
        coverageType: null,
        coverageCategory: 'WD',
        disabilityDate: '02/05/2019',
        effectiveDate: null,
        authorizedEndDate: '3/6/2019',
        claimStatus: 'Open',
        claimStatusCode: '0',
        claimOnlineStatus: 'CLAIM APPROVED',
        claimOnlineStatusCode: '04',
        claimOnlineSubStatus: 'PAYMENTS IN PROGRESS',
        claimOnlineSubStatusCode: '06',
        extensionStatus: null,
        extensionStatusCode: null,
        extensionSubStatus: null,
        extensionSubStatusCode: null,
        claimOfficeNumber: null,
        claimOfficeId: null,
        caseId: '0429387',
        claimProcessDate: '02/05/2019',
        claimEffectiveDate: null,
        reason: 'Administrative',
        oowReason: null,
        physicianStatementDate: '0',
        employerStatementDate: '0',
        employeeStatementDate: '0',
        estimatedSurgeryDate: '',
        estimatedDeliveryDate: '',
        lastDayWork: '02/04/2019',
        asmIndicator: 'A',
        confirmedLastDayWorkDate: '02/04/2019',
        confirmedSurgeryDate: '',
        confirmedDeliveryDate: '',
        encIndicator: false,
        encSurgeryIndicator: false,
        fundingMethod: 'FI',
        coveragePlanNumber: null,
        taskOpen: false,
        missingInfo: [],
        claimProgress: 'Claim Submitted',
        extensionRequestDate: null,
        babyDeathIndicator: null,
        recentClaimIndicator: false,
        rtwStatusCode : '0',
        concurrentLeaveIDs: [
          '200012'
        ],
        sourceSystemId: 'AA',
        personId: '6252599',
        relatedClaims: [
          {
            relatedClaimList: [
              {
                LinkedLTD: null,
                LinkedABL: null,
                ParentLTD: null,
                LinkedB: null,
                LinkedP: null,
                LinkedPW: null,
                LinkedPFL: null,
                LinkedCPFL: null,
                LinkedSTD: '100004',
                LinkedSTDB: null,
                LinkedSTAT: '100001',
              }
            ],
            hasRelatedClaims: true
          }
        ]
      },
      {
        claimEventId: '100003',
        claimType: 'Long Term Disability',
        claimSubType: 'Long Term Disability',
        coverageType: null,
        coverageCategory: 'LTD',
        disabilityDate: '02/27/2019',
        effectiveDate: null,
        authorizedEndDate: '02/27/2019',
        claimStatus: 'Open',
        claimStatusCode: '0',
        claimOnlineStatus: null,
        claimOnlineStatusCode: null,
        claimOnlineSubStatus: null,
        claimOnlineSubStatusCode: null,
        extensionStatus: null,
        extensionStatusCode: null,
        extensionSubStatus: null,
        extensionSubStatusCode: null,
        claimOfficeNumber: null,
        claimOfficeId: null,
        caseId: '0429387',
        claimProcessDate: null,
        claimEffectiveDate: null,
        reason: 'Medical',
        oowReason: null,
        physicianStatementDate: '0',
        employerStatementDate: '0',
        employeeStatementDate: '0',
        estimatedSurgeryDate: '',
        estimatedDeliveryDate: '',
        lastDayWork: '02/26/2019',
        asmIndicator: 'A',
        confirmedLastDayWorkDate: '02/26/2019',
        confirmedSurgeryDate: '',
        confirmedDeliveryDate: '',
        encIndicator: false,
        encSurgeryIndicator: false,
        fundingMethod: 'FI',
        coveragePlanNumber: null,
        taskOpen: false,
        missingInfo: [],
        claimProgress: 'Claim Submitted',
        extensionRequestDate: null,
        babyDeathIndicator: null,
        recentClaimIndicator: false,
        concurrentLeaveIDs: [],
        sourceSystemId: 'AA',
        personId: '6252599',
        rtwStatusCode : '42001',
        relatedClaims: [
          {
            relatedClaimList: [
              {
                LinkedLTD: null,
                LinkedABL: null,
                ParentLTD: null,
                LinkedB: null,
                LinkedP: null,
                LinkedPW: null,
                LinkedPFL: null,
                LinkedCPFL: null,
                LinkedSTD: null,
                LinkedSTDB: null,
                LinkedSTAT: '100009',
              }
            ],
            hasRelatedClaims: true
          }
        ]
      },
      {
        claimEventId: '100004',
        claimType: 'Premium Waiver',
        claimSubType: 'Premium Waiver',
        coverageType: null,
        coverageCategory: 'PW',
        disabilityDate: '02/05/2019',
        effectiveDate: null,
        authorizedEndDate: '3/6/2019',
        claimStatus: 'Open',
        claimStatusCode: '0',
        claimOnlineStatus: 'CLAIM APPROVED',
        claimOnlineStatusCode: '04',
        claimOnlineSubStatus: 'PAYMENTS IN PROGRESS',
        claimOnlineSubStatusCode: '06',
        extensionStatus: null,
        extensionStatusCode: null,
        extensionSubStatus: null,
        extensionSubStatusCode: null,
        claimOfficeNumber: null,
        claimOfficeId: null,
        caseId: '0429387',
        claimProcessDate: '02/05/2019',
        claimEffectiveDate: null,
        reason: 'Administrative',
        oowReason: null,
        physicianStatementDate: '0',
        employerStatementDate: '0',
        employeeStatementDate: '0',
        estimatedSurgeryDate: '',
        estimatedDeliveryDate: '',
        lastDayWork: '02/04/2019',
        asmIndicator: 'A',
        confirmedLastDayWorkDate: '02/04/2019',
        confirmedSurgeryDate: '',
        confirmedDeliveryDate: '',
        encIndicator: false,
        encSurgeryIndicator: false,
        fundingMethod: 'FI',
        coveragePlanNumber: null,
        taskOpen: false,
        missingInfo: [],
        claimProgress: 'Claim Submitted',
        extensionRequestDate: null,
        babyDeathIndicator: null,
        recentClaimIndicator: false,
        rtwStatusCode : '0',
        concurrentLeaveIDs: [
          '200004'
        ],
        sourceSystemId: 'AA',
        personId: '6252599',
        relatedClaims: [
          {
            relatedClaimList: [
              {
                LinkedLTD: null,
                LinkedABL: null,
                ParentLTD: null,
                LinkedB: null,
                LinkedP: null,
                LinkedPW: null,
                LinkedPFL: null,
                LinkedCPFL: '100002',
                LinkedSTD: null,
                LinkedSTDB: null,
                LinkedSTAT: '100007',
              }
            ],
            hasRelatedClaims: true
          }
        ]
      },
      {
        claimEventId: '100005',
        claimType: 'Long Term Disability',
        claimSubType: 'Long Term Disability',
        coverageType: null,
        coverageCategory: 'LTD',
        disabilityDate: '02/05/2019',
        effectiveDate: null,
        authorizedEndDate: '3/6/2019',
        claimStatus: 'Open',
        claimStatusCode: '0',
        claimOnlineStatus: 'CLAIM APPROVED',
        claimOnlineStatusCode: '04',
        claimOnlineSubStatus: 'PAYMENTS IN PROGRESS',
        claimOnlineSubStatusCode: '06',
        extensionStatus: null,
        extensionStatusCode: null,
        extensionSubStatus: null,
        extensionSubStatusCode: null,
        claimOfficeNumber: null,
        claimOfficeId: null,
        caseId: '0429387',
        claimProcessDate: '02/05/2019',
        claimEffectiveDate: null,
        reason: 'Administrative',
        physicianStatementDate: '0',
        employerStatementDate: '0',
        employeeStatementDate: '0',
        estimatedSurgeryDate: '',
        estimatedDeliveryDate: '',
        lastDayWork: '02/04/2019',
        asmIndicator: 'A',
        confirmedLastDayWorkDate: '02/04/2019',
        confirmedSurgeryDate: '',
        confirmedDeliveryDate: '',
        encIndicator: false,
        encSurgeryIndicator: false,
        fundingMethod: 'FI',
        coveragePlanNumber: null,
        taskOpen: false,
        missingInfo: [],
        claimProgress: 'Claim Submitted',
        extensionRequestDate: null,
        babyDeathIndicator: null,
        recentClaimIndicator: false,
        concurrentLeaveIDs: [],
        sourceSystemId: 'AA',
        personId: '6252599',
        rtwStatusCode : '42001',
        relatedClaims: [
          {
            relatedClaimList: [
              {
                LinkedLTD: null,
                LinkedABL: null,
                ParentLTD: null,
                LinkedB: null,
                LinkedP: null,
                LinkedPW: null,
                LinkedPFL: null,
                LinkedCPFL: null,
                LinkedSTD: null,
                LinkedSTDB: null,
                LinkedSTAT: '100006',
              }
            ],
            hasRelatedClaims: true
          }
        ]
      },
      {
        claimEventId: '100006',
        claimType: 'Short Term Disability',
        claimSubType: 'Short Term Disability',
        coverageType: null,
        coverageCategory: 'STD',
        disabilityDate: '02/02/2017',
        effectiveDate: null,
        authorizedEndDate: null,
        claimStatus: 'Pend',
        claimStatusCode: '0',
        claimOnlineStatus: 'AWAITING DELIVERY',
        claimOnlineStatusCode: '01',
        claimOnlineSubStatus: 'AWAITING DELIVERY',
        claimOnlineSubStatusCode: '01',
        extensionStatus: 'AWAITING DELIVERY',
        extensionStatusCode: '01',
        extensionSubStatus: 'AWAITING SURGERY DETAILS',
        extensionSubStatusCode: '03',
        claimOfficeNumber: null,
        claimOfficeId: null,
        caseId: '0429387',
        claimProcessDate: '02/02/2017',
        claimEffectiveDate: null,
        reason: 'New Claim',
        oowReason: null,
        physicianStatementDate: '0',
        employerStatementDate: '0',
        employeeStatementDate: '0',
        estimatedSurgeryDate: '',
        estimatedDeliveryDate: '',
        lastDayWork: '02/01/2017',
        asmIndicator: 'A',
        confirmedLastDayWorkDate: '02/01/2017',
        confirmedSurgeryDate: '',
        confirmedDeliveryDate: '',
        encIndicator: false,
        encSurgeryIndicator: false,
        fundingMethod: '',
        coveragePlanNumber: null,
        taskOpen: false,
        missingInfo: [],
        claimProgress: 'Claim Submitted',
        extensionRequestDate: null,
        babyDeathIndicator: null,
        recentClaimIndicator: false,
        concurrentLeaveIDs: ['200001'],
        sourceSystemId: 'AA',
        personId: '6252599',
        relatedClaims: [
          {
            relatedClaimList: [
              {
                LinkedLTD: null,
                LinkedABL: null,
                ParentLTD: null,
                LinkedB: null,
                LinkedP: null,
                LinkedPW: null,
                LinkedPFL: null,
                LinkedCPFL: null,
                LinkedSTD: null,
                LinkedSTDB: '100010',
                LinkedSTAT: '100005',
              }
            ],
            hasRelatedClaims: true
          }
        ]
      },
      {
        claimEventId: '100007',
        claimType: 'Short Term Disability',
        claimSubType: 'Short Term Disability',
        coverageType: null,
        coverageCategory: 'STD',
        disabilityDate: '02/02/2017',
        effectiveDate: null,
        authorizedEndDate: null,
        claimStatus: 'Approved',
        claimStatusCode: '0',
        claimOnlineStatus: null,
        claimOnlineStatusCode: null,
        claimOnlineSubStatus: null,
        claimOnlineSubStatusCode: null,
        extensionStatus: null,
        extensionStatusCode: null,
        extensionSubStatus: null,
        extensionSubStatusCode: null,
        claimOfficeNumber: null,
        claimOfficeId: null,
        caseId: '0429387',
        claimProcessDate: '02/02/2017',
        claimEffectiveDate: null,
        reason: 'New Claim',
        oowReason: null,
        physicianStatementDate: '0',
        employerStatementDate: '0',
        employeeStatementDate: '0',
        estimatedSurgeryDate: '',
        estimatedDeliveryDate: '',
        lastDayWork: '02/01/2017',
        asmIndicator: 'A',
        confirmedLastDayWorkDate: '02/01/2017',
        confirmedSurgeryDate: '',
        confirmedDeliveryDate: '',
        encIndicator: false,
        encSurgeryIndicator: false,
        fundingMethod: 'FI',
        coveragePlanNumber: null,
        taskOpen: false,
        missingInfo: [],
        claimProgress: 'Claim Approved',
        extensionRequestDate: null,
        babyDeathIndicator: null,
        recentClaimIndicator: false,
        concurrentLeaveIDs: ['200004'],
        sourceSystemId: 'AA',
        personId: '6252599',
        relatedClaims: [
          {
            relatedClaimList: [
              {
                LinkedLTD: null,
                LinkedABL: null,
                ParentLTD: null,
                LinkedB: null,
                LinkedP: null,
                LinkedPW: null,
                LinkedPFL: null,
                LinkedCPFL: null,
                LinkedSTD: null,
                LinkedSTDB: null,
                LinkedSTAT: '100004',
              }
            ],
            hasRelatedClaims: true
          }
        ]
      },
      {
        claimEventId: '100008',
        claimType: 'Short Term Disability',
        claimSubType: 'Short Term Disability',
        coverageType: null,
        coverageCategory: 'WD',
        disabilityDate: '02/07/2011',
        effectiveDate: null,
        authorizedEndDate: '12/12/2019',
        claimStatus: 'Pend',
        claimStatusCode: '0',
        claimOnlineStatus: 'claim approved',
        claimOnlineStatusCode: null,
        claimOnlineSubStatus: null,
        claimOnlineSubStatusCode: null,
        extensionStatus: null,
        extensionStatusCode: '02',
        extensionSubStatus: null,
        extensionSubStatusCode: '04',
        claimOfficeNumber: null,
        claimOfficeId: null,
        caseId: '0429387',
        claimProcessDate: '02/07/2011',
        claimEffectiveDate: null,
        reason: 'New Claim',
        oowReason: null,
        physicianStatementDate: '0',
        employerStatementDate: '0',
        employeeStatementDate: '0',
        estimatedSurgeryDate: '',
        estimatedDeliveryDate: '',
        lastDayWork: '02/04/2011',
        asmIndicator: 'A',
        confirmedLastDayWorkDate: '02/04/2011',
        confirmedSurgeryDate: '',
        confirmedDeliveryDate: '',
        encIndicator: false,
        encSurgeryIndicator: false,
        fundingMethod: '',
        coveragePlanNumber: null,
        taskOpen: false,
        missingInfo: [],
        claimProgress: 'Claim Submitted',
        extensionRequestDate: '11/12/2019',
        babyDeathIndicator: null,
        recentClaimIndicator: false,
        concurrentLeaveIDs: [],
        sourceSystemId: 'AA',
        personId: '6252599',
        relatedClaims: [
          {
            relatedClaimList: [
              {
                LinkedLTD: null,
                LinkedABL: null,
                ParentLTD: null,
                LinkedB: null,
                LinkedP: null,
                LinkedPW: null,
                LinkedPFL: null,
                LinkedCPFL: null,
                LinkedSTD: null,
                LinkedSTDB: null,
                LinkedSTAT: '100009',
              }
            ],
            hasRelatedClaims: true
          }
        ]
      },
      {
        claimEventId: '100009',
        claimType: 'Premium Waiver',
        claimSubType: 'Premium Waiver',
        coverageType: null,
        coverageCategory: 'PW',
        disabilityDate: '02/07/2011',
        effectiveDate: null,
        authorizedEndDate: null,
        claimStatus: 'Pend',
        claimStatusCode: '0',
        claimOnlineStatus: null,
        claimOnlineStatusCode: null,
        claimOnlineSubStatus: null,
        claimOnlineSubStatusCode: null,
        extensionStatus: null,
        extensionStatusCode: null,
        extensionSubStatus: null,
        extensionSubStatusCode: null,
        claimOfficeNumber: null,
        claimOfficeId: null,
        caseId: '0429387',
        claimProcessDate: '02/07/2011',
        claimEffectiveDate: null,
        reason: 'New Claim',
        oowReason: null,
        physicianStatementDate: '0',
        employerStatementDate: '0',
        employeeStatementDate: '0',
        estimatedSurgeryDate: '',
        estimatedDeliveryDate: '',
        lastDayWork: '02/04/2011',
        asmIndicator: 'A',
        confirmedLastDayWorkDate: '02/04/2011',
        confirmedSurgeryDate: '',
        confirmedDeliveryDate: '',
        encIndicator: false,
        encSurgeryIndicator: false,
        fundingMethod: '',
        coveragePlanNumber: null,
        taskOpen: false,
        missingInfo: [],
        claimProgress: 'Claim Submitted',
        extensionRequestDate: null,
        babyDeathIndicator: null,
        recentClaimIndicator: false,
        concurrentLeaveIDs: ['200009'],
        sourceSystemId: 'AA',
        personId: '6252599',
        relatedClaims: [
          {
            relatedClaimList: [
              {
                LinkedLTD: null,
                LinkedABL: null,
                ParentLTD: null,
                LinkedB: null,
                LinkedP: null,
                LinkedPW: null,
                LinkedPFL: null,
                LinkedCPFL: null,
                LinkedSTD: null,
                LinkedSTDB: '100008',
                LinkedSTAT: '100003',
              }
            ],
            hasRelatedClaims: true
          }
        ]
      },
      {
        claimEventId: '100010',
        claimType: 'Long Term Disability',
        claimSubType: 'Long Term Disability',
        coverageType: null,
        coverageCategory: 'LTD',
        disabilityDate: '08/09/2016',
        effectiveDate: null,
        authorizedEndDate: null,
        claimStatus: 'Pend',
        claimStatusCode: '0',
        claimOnlineStatus: null,
        claimOnlineStatusCode: null,
        claimOnlineSubStatus: null,
        claimOnlineSubStatusCode: null,
        extensionStatus: null,
        extensionStatusCode: null,
        extensionSubStatus: null,
        extensionSubStatusCode: null,
        claimOfficeNumber: null,
        claimOfficeId: null,
        caseId: '0429387',
        claimProcessDate: '08/09/2016',
        claimEffectiveDate: null,
        reason: 'New Claim',
        oowReason: null,
        physicianStatementDate: '0',
        employerStatementDate: '0',
        employeeStatementDate: '0',
        estimatedSurgeryDate: '',
        estimatedDeliveryDate: '',
        lastDayWork: '08/08/2016',
        asmIndicator: 'A',
        confirmedLastDayWorkDate: '08/08/2016',
        confirmedSurgeryDate: '',
        confirmedDeliveryDate: '',
        encIndicator: false,
        encSurgeryIndicator: false,
        fundingMethod: '',
        coveragePlanNumber: null,
        taskOpen: false,
        missingInfo: [],
        claimProgress: 'Claim Submitted',
        extensionRequestDate: null,
        babyDeathIndicator: null,
        recentClaimIndicator: false,
        concurrentLeaveIDs: [],
        sourceSystemId: 'AA',
        personId: '6252599',
        relatedClaims: [
          {
            relatedClaimList: [
              {
                LinkedLTD: null,
                LinkedABL: null,
                ParentLTD: null,
                LinkedB: null,
                LinkedP: null,
                LinkedPW: null,
                LinkedPFL: null,
                LinkedCPFL: null,
                LinkedSTD: null,
                LinkedSTDB: '100006',
                LinkedSTAT: '100001',
              }
            ],
            hasRelatedClaims: true
          }
        ]
      }
    ],
    leaveClaims: [
      {
        leaveId: '1654587',
        leaveStartDate: '07/27/2020',
        leaveEndDate: '07/28/2020',
        leaveStatus: 'Open',
        leaveReason: 'Care of a family member ',
        caseId: '1383846',
        personId: '7064953',
        reedLeaveId: '0000',
        leaveProcessDate: '07/21/2020',
        leaveType: 'Leave of Absence',
        leaveSubType: 'ReducedSchedule',
        leaveEstimatedEndDate: '',
        leaveEditDate: null,
        insuredFirstName: 'HYZZZKGJZXXW',
        insuredLastName: 'XXZZZKGIPDMC',
        leaveFirstName: 'Test',
        leaveLastName: '',
        missingInfo: [
          {
            mInfoCode: '10023364',
            mInfoDesc: 'Employer',
            requiredByDate: '',
            mInfoReqInfo: [{
              mInfoReqInfoCd: '12',
              mInfoReqInfoDesc: '',
              requestDate: '4/9/2019',
              displayValue: 'Salary Continuation, PTO, or Sick Time Details',
              chatInd: false,
              callInd: false,
              uploadInd: false,
              detailInd: true,
              formType: 'generic',
              listPhysicain: [{
                physicianName: 'Dr.ABC',
                fromDataRange: '3/21/2019',
                toDataRange: ''
              }]
            }],
            gracePeriod: false
          }, {
            mInfoCode: '10023366',
            mInfoDesc: 'Employee',
            requiredByDate: '',
            mInfoReqInfo: [{
              mInfoReqInfoCd: '01',
              mInfoReqInfoDesc: '',
              requestDate: '3/21/2019',
              displayValue: 'Leave Thaa Form Example',
              chatInd: false,
              callInd: false,
              uploadInd: true,
              detailInd: false,
              formType: 'generic',
              listPhysicain: [{
                physicianName: 'Dr.XYZ',
                fromDataRange: '3/21/2019',
                toDataRange: ''
              }]
            }],
            gracePeriod: false
          }, {
            mInfoCode: '10023362',
            mInfoDesc: 'Physician',
            requiredByDate: '',
            mInfoReqInfo: [ {
              mInfoReqInfoCd: '07',
              mInfoReqInfoDesc: '',
              requestDate: '3/21/2019',
              displayValue: 'Cause of Disabling Condition',
              chatInd: false,
              callInd: false,
              uploadInd: false,
              detailInd: true,
              formType: 'generic',
              listPhysicain: [{
                physicianName: 'test',
                fromDataRange: '3/21/2019',
                toDataRange: '3/22/2019'
              }]
            }],
            gracePeriod: false
          }],
        claimProgress: null,
        extensionRequestDate: null,
        babyDeathIndicator: null,
        recentClaimIndicator: false,
        concurrentLeaveIDs: ['1338103'],
        sourceSystemId: 'AA',
        relatedClaims: [{
          relatedClaimList: [],
          hasRelatedClaims: false
        }],
        absenceDetails: [
          {
            requestId: '1654588',
            status: 'PENDED',
            statusId: '3',
            requestStartDate: '07/27/2020',
            requestEndDate: '07/28/2020',
            denialReason: '',
            leavePlan: [
              {
                planId: '1',
                planName: 'FEDERAL',
                startDate: '07/27/2020',
                endDate: '07/28/2020',
                continuity: 'Reduced Schedule',
                lastChangeDate: '07/21/2020',
                changeAction: 'PENDED',
                changeActionId: '3',
                requestActionId: '938787115',
                leavePlanName: 'Federal Family and Medical Leave Act (FMLA)',
                leavePlanType: 'FEDERAL',
                leavePlanDescription: 'under California',
                denialReason: ''
              },
              {
                planId: '5',
                planName: 'STATE',
                startDate: '07/27/2020',
                endDate: '07/28/2020',
                continuity: 'Reduced Schedule',
                lastChangeDate: '07/21/2020',
                changeAction: 'PENDED',
                changeActionId: '3',
                requestActionId: '938787117',
                leavePlanName: 'California Family Rights Act (CFRA)',
                leavePlanType: 'STATE',
                leavePlanDescription: 'Under the California Family Rights Act',
                denialReason: ''
              }
            ],
            AccommodationDescription: '',
            AccommodationsubCategory: '',
            substatus: 'Awaiting certification',
            substatusId: '27',
            AccommodationType: ''
          }
        ],
        certRequestList: [
        ],
        leaveISMedical: '0',
        leaveRequestStartDate: '07/27/2020',
        relationship: 'Aunt'
      },
      {
        leaveId: '1569086',
        leaveStartDate: '07/01/2020',
        leaveEndDate: '07/01/2020',
        leaveStatus: 'Open',
        leaveReason: 'My Illness/Health Condition',
        caseId: '5409607',
        confirmReturnToWork: '1',
        personId: '8654901',
        reedLeaveId: '0000',
        leaveProcessDate: '07/02/2020',
        leaveType: 'Leave of Absence',
        leaveSubType: 'Reduced',
        leaveEstimatedEndDate: '',
        leaveEditDate: null,
        insuredFirstName: 'AUDREY',
        insuredLastName: 'WELCH',
        leaveFirstName: '',
        leaveLastName: '',
        absenceDetails: [
          {
            requestId: '1569087',
            status: 'APPROVED',
            statusId: '1',
            requestStartDate: '07/01/2020',
            requestEndDate: '07/01/2020',
            denialReason: '',
            leavePlan: [
              {
                planId: '1',
                planName: 'FEDERAL',
                startDate: '07/01/2020',
                endDate: '07/01/2020',
                continuity: 'Intermittent',
                lastChangeDate: '07/02/2020',
                changeAction: 'APPROVED',
                changeActionId: '1',
                requestActionId: '866391086',
                leavePlanName: 'Federal Family and Medical Leave Act (FMLA)',
                leavePlanType: 'FEDERAL',
                leavePlanDescription: 'Under',
                denialReason: ''
              },
              {
                planId: '5',
                planName: 'STATE',
                startDate: '07/01/2020',
                endDate: '07/01/2020',
                continuity: 'Intermittent',
                lastChangeDate: '07/02/2020',
                changeAction: 'APPROVED',
                changeActionId: '1',
                requestActionId: '866391087',
                leavePlanName: 'California Family Rights Act (CFRA)',
                leavePlanType: 'STATE',
                leavePlanDescription: 'Under the California',
                denialReason: ''
              }
            ],
            AccommodationDescription: '',
            AccommodationsubCategory: '',
            substatus: 'Accepted',
            substatusId: '1',
            AccommodationType: ''
          }
        ],
        certRequestList: [
          {
            certId: '803715',
            certPeriodStartDate: '05/26/2020',
            certPeriodEndDate: '07/01/2020',
            certStatus: 'Accepted'
          },
          {
            certId: '805967',
            certPeriodStartDate: '05/26/2020',
            certPeriodEndDate: '07/01/2020',
            certStatus: 'Not Certified'
          },
          {
            certId: '805968',
            certPeriodStartDate: '05/26/2020',
            certPeriodEndDate: '07/01/2020',
            certStatus: 'Accepted'
          },
          {
            certId: '805972',
            certPeriodStartDate: '05/26/2020',
            certPeriodEndDate: '07/01/2020',
            certStatus: 'Not Certified'
          },
          {
            certId: '798854',
            certPeriodStartDate: '07/01/2020',
            certPeriodEndDate: '06/30/2021',
            certStatus: 'Accepted'
          },
          {
            certId: '798855',
            certPeriodStartDate: '07/01/2020',
            certPeriodEndDate: '06/30/2021',
            certStatus: 'Accepted'
          },
          {
            certId: '802930',
            certPeriodStartDate: '07/01/2020',
            certPeriodEndDate: '06/30/2021',
            certStatus: 'Not Certified'
          },
          {
            certId: '802931',
            certPeriodStartDate: '07/01/2020',
            certPeriodEndDate: '06/30/2021',
            certStatus: 'Not Certified'
          }
        ],
        leaveISMedical: '0',
        leaveRequestStartDate: '07/01/2020',
        relationship: 'Employee'
      },
      {
        leaveId: '1345842',
        leaveStartDate: '10/10/2019',
        leaveEndDate: '10/11/2019',
        leaveStatus: 'Open',
        leaveReason: 'Bond with New Child',
        caseId: '0737469',
        personId: '8489427',
        reedLeaveId: '0000',
        leaveProcessDate: '10/17/2019',
        leaveType: 'Leave of Absence',
        leaveSubType: 'Continuous',
        leaveEstimatedEndDate: '',
        leaveEditDate: null,
        insuredFirstName: null,
        insuredLastName: null,
        sourceSystemId: 'AA',
        leaveFirstName: '',
        leaveLastName: '',
        missingInfo: [
          {
            mInfoCode: '02',
            mInfoDesc: 'Employer',
            requiredByDate: '',
            mInfoReqInfo: [{
              mInfoReqInfoCd: '12',
              mInfoReqInfoDesc: '',
              requestDate: '4/9/2019',
              displayValue: 'Salary Continuation, PTO, or Sick Time Details',
              chatInd: false,
              callInd: false,
              uploadInd: false,
              detailInd: true,
              formType: 'generic',
              listPhysicain: [{
                physicianName: 'Dr.ABC',
                fromDataRange: '3/21/2019',
                toDataRange: ''
              }]
            }],
            gracePeriod: false
          }, {
            mInfoCode: '01',
            mInfoDesc: 'Employee',
            requiredByDate: '',
            mInfoReqInfo: [{
              mInfoReqInfoCd: '01',
              mInfoReqInfoDesc: '',
              requestDate: '3/21/2019',
              displayValue: 'Medical History Form',
              chatInd: false,
              callInd: false,
              uploadInd: true,
              detailInd: false,
              formType: 'generic',
              listPhysicain: [{
                physicianName: 'Dr.XYZ',
                fromDataRange: '3/21/2019',
                toDataRange: ''
              }]
            }],
            gracePeriod: false
          }, {
            mInfoCode: '03',
            mInfoDesc: 'Physician',
            requiredByDate: '',
            mInfoReqInfo: [ {
              mInfoReqInfoCd: '07',
              mInfoReqInfoDesc: '',
              requestDate: '3/21/2019',
              displayValue: 'Cause of Disabling Condition',
              chatInd: false,
              callInd: false,
              uploadInd: false,
              detailInd: true,
              formType: 'generic',
              listPhysicain: [{
                physicianName: 'test',
                fromDataRange: '3/21/2019',
                toDataRange: '3/22/2019'
              }]
            }],
            gracePeriod: false
          }],
        absenceDetails: [
          {
            requestId: '1345843',
            status: 'PENDED',
            statusId: '3',
            requestStartDate: '10/10/2019',
            denialReason: '',
            leavePlan: [
              {
                planId: '1',
                planName: 'FEDERAL',
                startDate: '10/11/2019',
                endDate: '10/11/2019',
                continuity: 'Reduced Schedule',
                lastChangeDate: '10/17/2019',
                changeAction: 'PENDED',
                changeActionId: '3',
                requestActionId: '839698086',
                leavePlanName: 'Federal Family and Medical Leave Act (FMLA)',
                leavePlanType: 'FEDERAL',
                leavePlanDescription: 'Under <span class="scayt-misspell" data-scayt_word="FMLA" data-scaytid="1">FMLA</span>, eligible employees can take up to 12 weeks of unpaid leave in a 12-month period for the following reasons:\r\n<ul>\r\n\t<li>\r\n\t\tBecause you have a serious health condition that keeps you from doing your job\r\n\t</li>\r\n\t<li>\r\n\t\tCaring for a child, spouse, or parent with a serious health condition\r\n\t</li>\r\n\t<li>\r\n\t\tBirth of a child\r\n\t</li>\r\n\t<li>\r\n\t\tPlacement of a child for adoption or foster care\r\n\t</li>\r\n\t<li>\r\n\t\tTo deal with certain arrangements when your spouse, son, daughter, or parent is serving in the military and deployed to a foreign country\r\n\t</li>\r\n</ul>\r\nA &ldquo;child&rdquo; includes a biological child, adopted child, or foster child, as well as a child you&rsquo;re acting in a parent-like role for. You can take <span class="scayt-misspell" data-scayt_word="FMLA" data-scaytid="5">FMLA</span> to care for a child with a serious health condition as long as they&rsquo;re younger than 18 years old. You may be able to take <span class="scayt-misspell" data-scayt_word="FMLA" data-scaytid="9">FMLA</span> to care for a child 18 or older, if they have a disability that makes them unable to care for themselves.<br />\r\n<br />\r\nIf you&#39;re caring for a covered service member with a serious injury or illness and you&rsquo;re that person&#39;s spouse, son, daughter, parent or next of kin, you can take up to 26 weeks of unpaid leave in a 12-month period.<br />\r\n<br />\r\nYou should tell us and your employer that you&#39;ll be out of work 30 days in advance. If you don&#39;t know that far in advance, let both of us know as soon as possible.',
                denialReason: ''
              },
              {
                planId: '1',
                planName: 'FEDERAL',
                startDate: '10/10/2019',
                endDate: '10/10/2019',
                continuity: 'Reduced Schedule',
                lastChangeDate: '10/17/2019',
                changeAction: 'PENDED',
                changeActionId: '3',
                requestActionId: '839698085',
                leavePlanName: 'Federal Family and Medical Leave Act (FMLA)',
                leavePlanType: 'FEDERAL',
                leavePlanDescription: 'Under <span class="scayt-misspell" data-scayt_word="FMLA" data-scaytid="1">FMLA</span>, eligible employees can take up to 12 weeks of unpaid leave in a 12-month period for the following reasons:\r\n<ul>\r\n\t<li>\r\n\t\tBecause you have a serious health condition that keeps you from doing your job\r\n\t</li>\r\n\t<li>\r\n\t\tCaring for a child, spouse, or parent with a serious health condition\r\n\t</li>\r\n\t<li>\r\n\t\tBirth of a child\r\n\t</li>\r\n\t<li>\r\n\t\tPlacement of a child for adoption or foster care\r\n\t</li>\r\n\t<li>\r\n\t\tTo deal with certain arrangements when your spouse, son, daughter, or parent is serving in the military and deployed to a foreign country\r\n\t</li>\r\n</ul>\r\nA &ldquo;child&rdquo; includes a biological child, adopted child, or foster child, as well as a child you&rsquo;re acting in a parent-like role for. You can take <span class="scayt-misspell" data-scayt_word="FMLA" data-scaytid="5">FMLA</span> to care for a child with a serious health condition as long as they&rsquo;re younger than 18 years old. You may be able to take <span class="scayt-misspell" data-scayt_word="FMLA" data-scaytid="9">FMLA</span> to care for a child 18 or older, if they have a disability that makes them unable to care for themselves.<br />\r\n<br />\r\nIf you&#39;re caring for a covered service member with a serious injury or illness and you&rsquo;re that person&#39;s spouse, son, daughter, parent or next of kin, you can take up to 26 weeks of unpaid leave in a 12-month period.<br />\r\n<br />\r\nYou should tell us and your employer that you&#39;ll be out of work 30 days in advance. If you don&#39;t know that far in advance, let both of us know as soon as possible.',
                denialReason: ''
              },
              {
                planId: '5',
                planName: 'STATE',
                startDate: '10/11/2019',
                endDate: '10/11/2019',
                continuity: 'Reduced Schedule',
                lastChangeDate: '10/17/2019',
                changeAction: 'PENDED',
                changeActionId: '3',
                requestActionId: '839698088',
                leavePlanName: 'California Family Rights Act (CFRA)',
                leavePlanType: 'STATE',
                leavePlanDescription: 'Under the California Family Rights Act (<span class="scayt-misspell" data-scayt_word="CFRA" data-scaytid="1">CFRA</span>), an employer who employs more than 50 employees within 75 miles of the work site where the employee is employed, cannot refuse to grant this leave to an employee who has more than 12 months of service with the employer, and who has at least 1,250 hours of service during the previous 12 month period, to take up to a total of 12 work weeks of unpaid leave in a 12 month period for Family care and medical leave. &quot;Family care and medical leave&quot; means the following:\r\n<ol type="a">\r\n <li>\r\n  Leave for reason of the birth, adoption, or foster care placement of the employee&#39;s child, or for the serious health condition of a child of the employee.</li>\r\n <li>\r\n  Leave to care for a parent, a spouse or registered domestic partner who has a serious health condition.</li>\r\n <li>\r\n  Leave because of an employee&#39;s own serious health condition (excluding leave taken for disability caused by pregnancy, childbirth or related medical conditions). This <span class="scayt-misspell" data-scayt_word="CFRA" data-scaytid="3">CFRA</span> leave may run consecutive to leave afforded under the California Pregnancy Disability Leave (<span class="scayt-misspell" data-scayt_word="PDL" data-scaytid="5">PDL</span>) and concurrent with other applicable Federal <span class="scayt-misspell" data-scayt_word="FMLA" data-scaytid="7">FMLA</span> leave. Your job restoration rights and your rights to maintenance of health benefits under this state law are similar to Federal <span class="scayt-misspell" data-scayt_word="FMLA" data-scaytid="9">FMLA</span>.</li>\r\n</ol>\r\n',
                denialReason: ''
              },
              {
                planId: '5',
                planName: 'STATE',
                startDate: '10/10/2019',
                endDate: '10/10/2019',
                continuity: 'Reduced Schedule',
                lastChangeDate: '10/17/2019',
                changeAction: 'PENDED',
                changeActionId: '3',
                requestActionId: '839698087',
                leavePlanName: 'California Family Rights Act (CFRA)',
                leavePlanType: 'STATE',
                leavePlanDescription: 'Under the California Family Rights Act (<span class="scayt-misspell" data-scayt_word="CFRA" data-scaytid="1">CFRA</span>), an employer who employs more than 50 employees within 75 miles of the work site where the employee is employed, cannot refuse to grant this leave to an employee who has more than 12 months of service with the employer, and who has at least 1,250 hours of service during the previous 12 month period, to take up to a total of 12 work weeks of unpaid leave in a 12 month period for Family care and medical leave. &quot;Family care and medical leave&quot; means the following:\r\n<ol type="a">\r\n <li>\r\n  Leave for reason of the birth, adoption, or foster care placement of the employee&#39;s child, or for the serious health condition of a child of the employee.</li>\r\n <li>\r\n  Leave to care for a parent, a spouse or registered domestic partner who has a serious health condition.</li>\r\n <li>\r\n  Leave because of an employee&#39;s own serious health condition (excluding leave taken for disability caused by pregnancy, childbirth or related medical conditions). This <span class="scayt-misspell" data-scayt_word="CFRA" data-scaytid="3">CFRA</span> leave may run consecutive to leave afforded under the California Pregnancy Disability Leave (<span class="scayt-misspell" data-scayt_word="PDL" data-scaytid="5">PDL</span>) and concurrent with other applicable Federal <span class="scayt-misspell" data-scayt_word="FMLA" data-scaytid="7">FMLA</span> leave. Your job restoration rights and your rights to maintenance of health benefits under this state law are similar to Federal <span class="scayt-misspell" data-scayt_word="FMLA" data-scaytid="9">FMLA</span>.</li>\r\n</ol>\r\n',
                denialReason: ''
              }
            ],
            substatus: 'Accommodation Review - at work',
            substatusId: '139',
            AccommodationDescription: 'Job Restrictions and/or Limitations',
            AccommodationsubCategory: 'Reach above shoulder',
            AccommodationType: 'Permanent',
            requestEndDate: '11/09/2019',
          }
        ],
        certRequestList: [],
        leaveISMedical: '0',
        leaveRequestStartDate: '10/10/2019'
      },
      {
        leaveId: '228095',
        leaveStartDate: '05/07/2021',
        leaveEndDate: '05/13/2021',
        leaveStatus: 'Open',
        leaveReason: 'Disability Accommodations',
        caseId: '0660696',
        personId: '11213685',
        reedLeaveId: '0000',
        leaveProcessDate: '05/05/2021',
        leaveType: 'ADA Accommodation',
        leaveSubType: 'Continuous',
        leaveEstimatedEndDate: '',
        leaveEditDate: null,
        insuredFirstName: 'STEPH',
        insuredLastName: 'VARNEY',
        leaveFirstName: '',
        leaveLastName: '',
        absenceDetails: [
          {
            requestId: '2298807',
            status: 'APPROVED',
            statusId: '1',
            requestStartDate: '05/07/2021',
            requestEndDate: '05/13/2021',
            denialReason: '',
            leavePlan: [
              {
                planId: '2506',
                planName: 'FEDERAL',
                startDate: '05/07/2021',
                endDate: '05/13/2021',
                continuity: 'Continuous',
                lastChangeDate: '05/05/2021',
                changeAction: 'APPROVED',
                changeActionId: '1',
                requestActionId: '894296696',
                leavePlanName: 'ADA Accommodation',
                leavePlanType: 'FEDERAL',
                leavePlanDescription: 'Under the Reasonable Accommodation provisions of the ADA, eligibility for an accommodation will be considered if you have a substantially limiting impairment or record of such an impairment as defined by the ADA.',
                denialReason: ''}
            ],
            AccommodationDescription: '',
            AccommodationsubCategory: '',
            substatus: 'At Work',
            substatusId: '137',
            AccommodationType: ''
          }
        ],
        certRequestList: [
        ],
        leaveISMedical: '0',
        leaveRequestStartDate: '05/07/2021',
        relationship: 'Employee',
        confirmReturnToWork: null,
        missingInfo: [
        ]
      },
      {
        leaveId: '2301639',
        leaveStartDate: '05/24/2021',
        leaveEndDate: '05/28/2021',
        leaveStatus: 'Open',
        leaveReason: 'Disability Accommodations',
        caseId: '0660696',
        personId: '11214500',
        reedLeaveId: '0000',
        leaveProcessDate: '05/07/2021',
        leaveType: 'ADA Accommodation',
        leaveSubType: 'Intermittent',
        leaveEstimatedEndDate: '',
        leaveEditDate: null,
        insuredFirstName: 'PONY',
        insuredLastName: 'VARNEY',
        leaveFirstName: '',
        leaveLastName: '',
        absenceDetails: [
          {
            requestId: '2301648',
            status: 'PENDED',
            statusId: '3',
            requestStartDate: '05/26/2021',
            requestEndDate: '05/28/2021',
            denialReason: '',
            leavePlan: [
              {
                planId: '2506',
                planName: 'FEDERAL',
                startDate: '05/28/2021',
                endDate: '05/28/2021',
                continuity: 'Intermittent',
                lastChangeDate: '05/07/2021',
                changeAction: 'PENDED',
                changeActionId: '3',
                requestActionId: '894784229',
                leavePlanName: 'ADA Accommodation',
                leavePlanType: 'FEDERAL',
                leavePlanDescription: 'Under the Reasonable Accommodation provisions of the ADA, eligibility for an accommodation will be considered if you have a substantially limiting impairment or record of such an impairment as defined by the ADA.',
                denialReason: ''
              },
              {
                planId: '2506',
                planName: 'FEDERAL',
                startDate: '05/27/2021',
                endDate: '05/27/2021',
                continuity: 'Intermittent',
                lastChangeDate: '05/07/2021',
                changeAction: 'PENDED',
                changeActionId: '3',
                requestActionId: '894784228',
                leavePlanName: 'ADA Accommodation',
                leavePlanType: 'FEDERAL',
                leavePlanDescription: 'Under the Reasonable Accommodation provisions of the ADA, eligibility for an accommodation will be considered if you have a substantially limiting impairment or record of such an impairment as defined by the ADA.',
                denialReason: ''
              },
              {
                planId: '2506',
                planName: 'FEDERAL',
                startDate: '05/26/2021',
                endDate: '05/26/2021',
                continuity: 'Intermittent',
                lastChangeDate: '05/07/2021',
                changeAction: 'PENDED',
                changeActionId: '3',
                requestActionId: '894784227',
                leavePlanName: 'ADA Accommodation',
                leavePlanType: 'FEDERAL',
                leavePlanDescription: 'Under the Reasonable Accommodation provisions of the ADA, eligibility for an accommodation will be considered if you have a substantially limiting impairment or record of such an impairment as defined by the ADA.',
                denialReason: ''
              }
            ],
            AccommodationDescription: 'Leave',
            AccommodationsubCategory: '',
            substatus: 'Not At Work',
            substatusId: '138',
            AccommodationType: 'Temporary'
          },
          {
            requestId: '2301641',
            status: 'PENDED',
            statusId: '3',
            requestStartDate: '05/24/2021',
            requestEndDate: '05/24/2021',
            denialReason: '',
            leavePlan: [
              {
                planId: '2506',
                planName: 'FEDERAL',
                startDate: '05/24/2021',
                endDate: '05/24/2021',
                continuity: 'Intermittent',
                lastChangeDate: '05/07/2021',
                changeAction: 'PENDED',
                changeActionId: '3',
                requestActionId: '894784226',
                leavePlanName: 'ADA Accommodation',
                leavePlanType: 'FEDERAL',
                leavePlanDescription: 'Under the Reasonable Accommodation provisions of the ADA, eligibility for an accommodation will be considered if you have a substantially limiting impairment or record of such an impairment as defined by the ADA.',
                denialReason: ''
              }
            ],
            AccommodationDescription: 'Leave',
            AccommodationsubCategory: '',
            substatus: 'Not At Work',
            substatusId: '138',
            AccommodationType: 'Temporary'
          }
        ],
        certRequestList: [
        ],
        leaveISMedical: '0',
        leaveRequestStartDate: '05/24/2021',
        relationship: 'Employee',
        confirmReturnToWork: null,
        missingInfo: [
        ],
        linkedSTD: false
      },
      {
        leaveId: '2301680',
        leaveStartDate: '05/14/2021',
        leaveEndDate: '06/18/2021',
        leaveStatus: 'Open',
        leaveReason: 'Disability Accommodations',
        caseId: '0660696',
        personId: '11214500',
        reedLeaveId: '0000',
        leaveProcessDate: '05/07/2021',
        leaveType: 'ADA Accommodation',
        leaveSubType: 'Continuous',
        leaveEstimatedEndDate: '',
        leaveEditDate: null,
        insuredFirstName: 'PONY',
        insuredLastName: 'VARNEY',
        leaveFirstName: '',
        leaveLastName: '',
        absenceDetails: [
          {
            requestId: '2301681',
            status: 'PENDED',
            statusId: '3',
            requestStartDate: '05/14/2021',
            requestEndDate: '06/18/2021',
            denialReason: '',
            leavePlan: [
              {
                planId: '2506',
                planName: 'FEDERAL',
                startDate: '05/14/2021',
                endDate: '06/18/2021',
                continuity: 'Continuous',
                lastChangeDate: '05/07/2021',
                changeAction: 'PENDED',
                changeActionId: '3',
                requestActionId: '894784225',
                leavePlanName: 'ADA Accommodation',
                leavePlanType: 'FEDERAL',
                leavePlanDescription: 'Under the Reasonable Accommodation provisions of the ADA, eligibility for an accommodation will be considered if you have a substantially limiting impairment or record of such an impairment as defined by the ADA.',
                denialReason: ''
              }
            ],
            AccommodationDescription: '',
            AccommodationsubCategory: '',
            substatus: 'At Work',
            substatusId: '139',
            AccommodationType: ''
          }
        ],
        certRequestList: [
        ],
        leaveISMedical: '0',
        leaveRequestStartDate: '05/14/2021',
        relationship: 'Employee',
        confirmReturnToWork: null,
        missingInfo: [
        ],
        linkedSTD: false
      },
      {
        leaveId: '2301519',
        leaveStartDate: '05/10/2021',
        leaveEndDate: '05/12/2021',
        leaveStatus: 'Open',
        leaveReason: 'Disability Accommodations',
        caseId: '0660696',
        personId: '11214500',
        reedLeaveId: '0000',
        leaveProcessDate: '05/07/2021',
        leaveType: 'ADA Accommodation',
        leaveSubType: 'Reduced Schedule',
        leaveEstimatedEndDate: '',
        leaveEditDate: null,
        insuredFirstName: 'PONY',
        insuredLastName: 'VARNEY',
        leaveFirstName: '',
        leaveLastName: '',
        absenceDetails: [
          {
            requestId: '2301578',
            status: 'DENIED',
            statusId: '2',
            requestStartDate: '05/12/2021',
            requestEndDate: '05/12/2021',
            denialReason: 'Your request for accommodation has been denied.',
            leavePlan: [
              {
                planId: '2506',
                planName: 'FEDERAL',
                startDate: '05/12/2021',
                endDate: '05/12/2021',
                continuity: 'Continuous',
                lastChangeDate: '05/07/2021',
                changeAction: 'DENIED',
                changeActionId: '2',
                requestActionId: '894784224',
                leavePlanName: 'ADA Accommodation',
                leavePlanType: 'FEDERAL',
                leavePlanDescription: 'Under the Reasonable Accommodation provisions of the ADA, eligibility for an accommodation will be considered if you have a substantially limiting impairment or record of such an impairment as defined by the ADA.',
                denialReason: 'Your request for accommodation has been denied.'
              }
            ],
            AccommodationDescription: 'Ergonomic Equipment',
            AccommodationsubCategory: 'Sit/Stand Desk',
            substatus: 'At Work',
            substatusId: '141',
            AccommodationType: 'Permanent'
          },
          {
            requestId: '2301521',
            status: 'APPROVED',
            statusId: '1',
            requestStartDate: '05/10/2021',
            requestEndDate: '05/10/2021',
            denialReason: '',
            leavePlan: [
              {
                planId: '2506',
                planName: 'FEDERAL',
                startDate: '05/10/2021',
                endDate: '05/10/2021',
                continuity: 'Continuous',
                lastChangeDate: '05/07/2021',
                changeAction: 'APPROVED',
                changeActionId: '1',
                requestActionId: '894784223',
                leavePlanName: 'ADA Accommodation',
                leavePlanType: 'FEDERAL',
                leavePlanDescription: 'Under the Reasonable Accommodation provisions of the ADA, eligibility for an accommodation will be considered if you have a substantially limiting impairment or record of such an impairment as defined by the ADA.',
                denialReason: ''
              }
            ],
            AccommodationDescription: 'Ergonomic Equipment',
            AccommodationsubCategory: 'Sit/Stand Desk',
            substatus: 'At Work',
            substatusId: '137',
            AccommodationType: 'Permanent'
          }
        ],
        certRequestList: [
        ],
        leaveISMedical: '0',
        leaveRequestStartDate: '05/10/2021',
        relationship: 'Employee',
        confirmReturnToWork: null,
        missingInfo: [
        ],
        linkedSTD: false
      },
      {
        leaveId: '2301657',
        leaveStartDate: '05/07/2021',
        leaveEndDate: '05/27/2021',
        leaveStatus: 'Open',
        leaveReason: 'Disability Accommodations',
        caseId: '0660696',
        personId: '11214500',
        reedLeaveId: '0000',
        leaveProcessDate: '05/07/2021',
        leaveType: 'ADA Accommodation',
        leaveSubType: 'Continuous',
        leaveEstimatedEndDate: '',
        leaveEditDate: null,
        insuredFirstName: 'PONY',
        insuredLastName: 'VARNEY',
        leaveFirstName: '',
        leaveLastName: '',
        absenceDetails: [
          {
            requestId: '2301658',
            status: 'PENDED',
            statusId: '3',
            requestStartDate: '05/07/2021',
            requestEndDate: '05/27/2021',
            denialReason: '',
            leavePlan: [
              {
                planId: '2506',
                planName: 'FEDERAL',
                startDate: '05/07/2021',
                endDate: '05/27/2021',
                continuity: 'Continuous',
                lastChangeDate: '05/07/2021',
                changeAction: 'PENDED',
                changeActionId: '3',
                requestActionId: '894784222',
                leavePlanName: 'ADA Accommodation',
                leavePlanType: 'FEDERAL',
                leavePlanDescription: 'Under the Reasonable Accommodation provisions of the ADA, eligibility for an accommodation will be considered if you have a substantially limiting impairment or record of such an impairment as defined by the ADA.',
                denialReason: ''
              }
            ],
            AccommodationDescription: '',
            AccommodationsubCategory: '',
            substatus: 'At Work',
            substatusId: '139',
            AccommodationType: ''
          }
        ],
        certRequestList: [
        ],
        leaveISMedical: '0',
        leaveRequestStartDate: '05/07/2021',
        relationship: 'Employee',
        confirmReturnToWork: null,
        missingInfo: [
        ],
        linkedSTD: false
      },
      {
        leaveId: '1345689',
        leaveStartDate: '10/08/2019',
        leaveEndDate: '10/08/2019',
        leaveStatus: 'Open',
        leaveReason: 'Disability Accommodations',
        caseId: '0737469',
        personId: '8489427',
        reedLeaveId: '0000',
        leaveProcessDate: '10/15/2019',
        leaveType: 'ADA Accommodation',
        leaveSubType: 'Continuous',
        leaveEstimatedEndDate: '',
        leaveEditDate: null,
        insuredFirstName: null,
        insuredLastName: null,
        leaveFirstName: '',
        leaveLastName: '',
        absenceDetails: [
          {
            requestId: '1345700',
            status: 'PENDED',
            statusId: '3',
            substatus: 'Accommodation Review - at work',
            substatusId: '139',
            AccommodationDescription: 'Job Restrictions and/or Limitations',
            AccommodationsubCategory: 'Reach above shoulder',
            AccommodationType: '',
            requestEndDate: '11/09/2019',
            requestStartDate: '10/08/2019',
            denialReason: '',
            leavePlan: [
              {
                planId: '2506',
                planName: 'FEDERAL',
                startDate: '10/08/2019',
                endDate: '10/08/2019',
                continuity: 'Continuous',
                lastChangeDate: '10/15/2019',
                changeAction: 'PENDED',
                changeActionId: '3',
                requestActionId: '839697941',
                leavePlanName: 'ADA Accommodation',
                leavePlanType: 'FEDERAL',
                leavePlanDescription: 'Under the Reasonable Accommodation provisions of the ADA, eligibility for an accommodation will be considered if you have a substantially limiting impairment or record of such an impairment as defined by the ADA.',
                denialReason: ''
              }
            ]
          },
          {
            requestId: '1345700',
            status: 'PENDED',
            statusId: '3',
            substatus: 'Accommodation Review - at work',
            substatusId: '139',
            AccommodationDescription: 'Job Restrictions and/or Limitations',
            AccommodationsubCategory: 'Reach above shoulder',
            AccommodationType: '',
            requestEndDate: '11/09/2019',
            requestStartDate: '10/09/2019',
            denialReason: '',
            leavePlan: [
              {
                planId: '2506',
                planName: 'FEDERAL',
                startDate: '10/08/2019',
                endDate: '10/08/2019',
                continuity: 'Continuous',
                lastChangeDate: '10/15/2019',
                changeAction: 'PENDED',
                changeActionId: '3',
                requestActionId: '839697941',
                leavePlanName: 'ADA Accommodation',
                leavePlanType: 'FEDERAL',
                leavePlanDescription: 'Under the Reasonable Accommodation provisions of the ADA, eligibility for an accommodation will be considered if you have a substantially limiting impairment or record of such an impairment as defined by the ADA.',
                denialReason: ''
              }
            ]
          },
          {
            requestId: '1345700',
            status: 'Approved',
            statusId: '1',
            substatus: 'Accommodation Review - at work',
            substatusId: '139',
            AccommodationDescription: 'Leave',
            AccommodationsubCategory: 'Reach above shoulder',
            AccommodationType: '',
            requestEndDate: '11/09/2019',
            requestStartDate: '09/08/2019',
            denialReason: '',
            leavePlan: [
              {
                planId: '2506',
                planName: 'FEDERAL',
                startDate: '10/08/2019',
                endDate: '10/08/2019',
                continuity: 'Continuous',
                lastChangeDate: '10/15/2019',
                changeAction: 'PENDED',
                changeActionId: '3',
                requestActionId: '839697941',
                leavePlanName: 'ADA Accommodation',
                leavePlanType: 'FEDERAL',
                leavePlanDescription: 'Under the Reasonable Accommodation provisions of the ADA, eligibility for an accommodation will be considered if you have a substantially limiting impairment or record of such an impairment as defined by the ADA.',
                denialReason: ''
              }
            ]
          },
          {
            requestId: '1345700',
            status: 'denied',
            statusId: '2',
            substatus: 'Accommodation Review - at work',
            substatusId: '139',
            AccommodationDescription: 'New Restrictions and/or Limitations',
            AccommodationsubCategory: 'Reach above shoulder',
            AccommodationType: '',
            requestEndDate: '11/09/2019',
            requestStartDate: '09/08/2019',
            denialReason: '',
            leavePlan: [
              {
                planId: '2506',
                planName: 'FEDERAL',
                startDate: '10/08/2019',
                endDate: '10/08/2019',
                continuity: 'Continuous',
                lastChangeDate: '10/15/2019',
                changeAction: 'PENDED',
                changeActionId: '3',
                requestActionId: '839697941',
                leavePlanName: 'ADA Accommodation',
                leavePlanType: 'FEDERAL',
                leavePlanDescription: 'Under the Reasonable Accommodation provisions of the ADA, eligibility for an accommodation will be considered if you have a substantially limiting impairment or record of such an impairment as defined by the ADA.',
                denialReason: ''
              }
            ]
          },
          {
            requestId: '1345700',
            status: '',
            statusId: '2',
            substatus: 'Accommodation Review - not at work',
            substatusId: '140',
            AccommodationDescription: 'New Restrictions and/or Limitations',
            AccommodationsubCategory: 'Reach above shoulder',
            AccommodationType: '',
            requestEndDate: '11/09/2019',
            requestStartDate: '08/11/2019',
            denialReason: '',
            leavePlan: [
              {
                planId: '2506',
                planName: 'FEDERAL',
                startDate: '10/08/2019',
                endDate: '10/08/2019',
                continuity: 'Continuous',
                lastChangeDate: '10/15/2019',
                changeAction: 'PENDED',
                changeActionId: '3',
                requestActionId: '839697941',
                leavePlanName: 'ADA Accommodation',
                leavePlanType: 'FEDERAL',
                leavePlanDescription: 'Under the Reasonable Accommodation provisions of the ADA, eligibility for an accommodation will be considered if you have a substantially limiting impairment or record of such an impairment as defined by the ADA.',
                denialReason: ''
              }
            ]
          },
          {
            requestId: '1345700',
            status: '',
            statusId: '2',
            substatus: 'Accommodation Review - not at work',
            substatusId: '140',
            AccommodationDescription: 'New Restrictions and/or Limitations',
            AccommodationsubCategory: 'Reach above shoulder',
            AccommodationType: '',
            requestEndDate: '11/09/2019',
            requestStartDate: '08/10/2019',
            denialReason: '',
            leavePlan: [
              {
                planId: '2506',
                planName: 'FEDERAL',
                startDate: '10/08/2019',
                endDate: '10/08/2019',
                continuity: 'Continuous',
                lastChangeDate: '10/15/2019',
                changeAction: 'PENDED',
                changeActionId: '3',
                requestActionId: '839697941',
                leavePlanName: 'ADA Accommodation',
                leavePlanType: 'FEDERAL',
                leavePlanDescription: 'Under the Reasonable Accommodation provisions of the ADA, eligibility for an accommodation will be considered if you have a substantially limiting impairment or record of such an impairment as defined by the ADA.',
                denialReason: ''
              }
            ]
          },
          {
            requestId: '1345700',
            status: 'Approved',
            statusId: '1',
            substatus: 'Accommodation Review - at work',
            substatusId: '139',
            AccommodationDescription: 'New Restrictions and/or Limitations',
            AccommodationsubCategory: 'Reach above shoulder',
            AccommodationType: 'Permanent',
            requestEndDate: '',
            requestStartDate: '10/08/2019',
            denialReason: '',
            leavePlan: [
              {
                planId: '2506',
                planName: 'FEDERAL',
                startDate: '10/08/2019',
                endDate: '10/08/2019',
                continuity: 'Continuous',
                lastChangeDate: '10/15/2019',
                changeAction: 'PENDED',
                changeActionId: '3',
                requestActionId: '839697941',
                leavePlanName: 'ADA Accommodation',
                leavePlanType: 'FEDERAL',
                leavePlanDescription: 'Under the Reasonable Accommodation provisions of the ADA, eligibility for an accommodation will be considered if you have a substantially limiting impairment or record of such an impairment as defined by the ADA.',
                denialReason: ''
              }
            ]
          },
          {
            requestId: '1345700',
            status: 'Approved',
            statusId: '1',
            substatus: 'Accommodation Review - at work',
            substatusId: '139',
            AccommodationDescription: 'Leave',
            AccommodationsubCategory: 'Reach above shoulder',
            AccommodationType: '',
            requestEndDate: '11/09/2019',
            requestStartDate: '10/12/2019',
            denialReason: '',
            leavePlan: [
              {
                planId: '2506',
                planName: 'FEDERAL',
                startDate: '10/08/2019',
                endDate: '10/08/2019',
                continuity: 'Continuous',
                lastChangeDate: '10/15/2019',
                changeAction: 'PENDED',
                changeActionId: '3',
                requestActionId: '839697941',
                leavePlanName: 'ADA Accommodation',
                leavePlanType: 'FEDERAL',
                leavePlanDescription: 'Under the Reasonable Accommodation provisions of the ADA, eligibility for an accommodation will be considered if you have a substantially limiting impairment or record of such an impairment as defined by the ADA.',
                denialReason: ''
              }
            ]
          },
          {
            requestId: '1345700',
            status: 'Approved',
            statusId: '3',
            substatus: 'Accommodation Review - at work',
            substatusId: '139',
            AccommodationDescription: 'Aob Restrictions and/or Limitations',
            AccommodationsubCategory: 'Reach above shoulder',
            AccommodationType: 'Permanent',
            requestEndDate: '',
            requestStartDate: '07/08/2019',
            denialReason: '',
            leavePlan: [
              {
                planId: '2506',
                planName: 'FEDERAL',
                startDate: '10/08/2019',
                endDate: '10/08/2019',
                continuity: 'Continuous',
                lastChangeDate: '10/15/2019',
                changeAction: 'PENDED',
                changeActionId: '3',
                requestActionId: '839697941',
                leavePlanName: 'ADA Accommodation',
                leavePlanType: 'FEDERAL',
                leavePlanDescription: 'Under the Reasonable Accommodation provisions of the ADA, eligibility for an accommodation will be considered if you have a substantially limiting impairment or record of such an impairment as defined by the ADA.',
                denialReason: ''
              }
            ]
          }
        ],
        certRequestList: [],
        leaveISMedical: '0',
        leaveRequestStartDate: '07/08/2019'
      },
      {
        leaveId: '1799734',
        leaveStartDate: '01/05/2021',
        leaveEndDate: '01/10/2021',
        leaveStatus: 'Open',
        leaveReason: 'Bond with new child ',
        caseId: '0516115',
        personId: '7469010',
        reedLeaveId: '0000',
        leaveProcessDate: '01/29/2021',
        leaveType: 'Leave of Absence',
        leaveSubType: 'Intermittent',
        leaveEstimatedEndDate: '',
        leaveEditDate: null,
        insuredFirstName: 'AMAN',
        insuredLastName: 'TIWARI',
        leaveFirstName: '',
        leaveLastName: '',
        absenceDetails: [
          {
            requestId: '1799735',
            status: 'PENDED',
            statusId: '3',
            requestStartDate: '01/05/2021',
            requestEndDate: '01/10/2021',
            denialReason: '',
            leavePlan: [
              {
                planId: '1',
                planName: 'FEDERAL',
                startDate: '01/05/2021',
                endDate: '01/10/2021',
                continuity: 'Continuous',
                lastChangeDate: '01/29/2021',
                changeAction: 'DENIED',
                changeActionId: '2',
                requestActionId: '954951638',
                leavePlanName: 'Federal Family and Medical Leave Act (FMLA)',
                leavePlanType: 'FEDERAL',
                leavePlanDescription: '',
                denialReason: 'You haven\'t worked enough hours to qualify.'
              },
            ],
            AccommodationDescription: '',
            AccommodationsubCategory: '',
            substatus: 'DOB/DOP Missing',
            substatusId: '147',
            AccommodationType: ''
          }
        ],
        certRequestList: [
        ],
        leaveISMedical: '0',
        leaveRequestStartDate: '01/05/2021',
        relationship: 'Newborn',
        confirmReturnToWork: null,
        missingInfo: [
        ]
      },
      {
        leaveId: '1803145',
        leaveStartDate: '01/05/2021',
        leaveEndDate: '01/11/2021',
        leaveStatus: 'Open',
        leaveReason: 'Employee`s own health condition',
        caseId: '0516115',
        personId: '7470231',
        reedLeaveId: '0000',
        leaveProcessDate: '02/03/2021',
        leaveType: 'Leave of Absence',
        leaveSubType: 'Continuous',
        leaveEstimatedEndDate: '',
        leaveEditDate: null,
        insuredFirstName: 'RICHARD',
        insuredLastName: 'COLEMAN',
        leaveFirstName: '',
        leaveLastName: '',
        absenceDetails: [
          {
            requestId: '1803146',
            status: 'PENDED',
            statusId: '3',
            requestStartDate: '01/05/2021',
            requestEndDate: '01/11/2021',
            denialReason: '',
            leavePlan: [
              {
                planId: '1',
                planName: 'FEDERAL',
                startDate: '01/05/2021',
                endDate: '01/11/2021',
                continuity: 'Continuous',
                lastChangeDate: '02/03/2021',
                changeAction: 'DENIED',
                changeActionId: '2',
                requestActionId: '955743776',
                leavePlanName: 'Federal Family and Medical Leave Act (FMLA)',
                leavePlanType: 'FEDERAL',
                leavePlanDescription: 'Under <span class="scayt-misspell" data-scayt_word="FMLA" data-scaytid="1">FMLA</span>, eligible employees can take up to 12 weeks of unpaid leave in a 12-month period for the following reasons:\r\n<ul>\r\n\t<li>\r\n\t\tBecause you have a serious health condition that keeps you from doing your job\r\n\t</li>\r\n\t<li>\r\n\t\tCaring for a child, spouse, or parent with a serious health condition\r\n\t</li>\r\n\t<li>\r\n\t\tBirth of a child\r\n\t</li>\r\n\t<li>\r\n\t\tPlacement of a child for adoption or foster care\r\n\t</li>\r\n\t<li>\r\n\t\tTo deal with certain arrangements when your spouse, son, daughter, or parent is serving in the military and deployed to a foreign country\r\n\t</li>\r\n</ul>\r\nA “child” includes a biological child, adopted child, or foster child, as well as a child you’re acting in a parent-like role for. You can take <span class="scayt-misspell" data-scayt_word="FMLA" data-scaytid="5">FMLA</span> to care for a child with a serious health condition as long as they’re younger than 18 years old. You may be able to take <span class="scayt-misspell" data-scayt_word="FMLA" data-scaytid="9">FMLA</span> to care for a child 18 or older, if they have a disability that makes them unable to care for themselves.<br />\r\n<br />\r\nIf you\'re caring for a covered service member with a serious injury or illness and you’re that person\'s spouse, son, daughter, parent or next of kin, you can take up to 26 weeks of unpaid leave in a 12-month period.<br />\r\n<br />\r\nYou should tell us and your employer that you\'ll be out of work 30 days in advance. If you don\'t know that far in advance, let both of us know as soon as possible.',
                denialReason: 'You haven\'t worked enough hours to qualify.'
              },
              {
                planId: '18985',
                planName: 'FEDERAL',
                startDate: '01/05/2021',
                endDate: '01/11/2021',
                continuity: 'Continuous',
                lastChangeDate: '02/03/2021',
                changeAction: 'PENDED',
                changeActionId: '3',
                requestActionId: '955743777',
                leavePlanName: 'Green UAT LOS Federal Test',
                leavePlanType: 'FEDERAL',
                leavePlanDescription: 'green team',
                denialReason: ''
              }
            ],
            AccommodationDescription: '',
            AccommodationsubCategory: '',
            substatus: 'Awaiting certification',
            substatusId: '27',
            AccommodationType: ''
          }
        ],
        certRequestList: [
          {
            certId: '437366',
            certPeriodStartDate: '01/05/2021',
            certPeriodEndDate: '01/11/2021',
            certStatus: 'Not Yet Received'
          }
        ],
        leaveISMedical: '0',
        leaveRequestStartDate: '01/05/2021',
        relationship: 'Employee',
        confirmReturnToWork: null,
        missingInfo: [
        ]
      },
      {
        leaveId: '1888889',
        leaveStartDate: '01/05/2021',
        leaveEndDate: '01/10/2021',
        leaveStatus: 'Open',
        leaveReason: 'Bond with new child ',
        caseId: '0516115',
        personId: '7469010',
        reedLeaveId: '0000',
        leaveProcessDate: '01/29/2021',
        leaveType: 'Leave of Absence',
        leaveSubType: 'Continuous',
        leaveEstimatedEndDate: '',
        leaveEditDate: null,
        insuredFirstName: 'AMAN',
        insuredLastName: 'TIWARI',
        leaveFirstName: '',
        leaveLastName: '',
        absenceDetails: [
          {
            requestId: '1799735',
            status: 'PENDED',
            statusId: '3',
            requestStartDate: '01/05/2021',
            requestEndDate: '01/10/2021',
            denialReason: '',
            leavePlan: [
              {
                planId: '1',
                planName: 'FEDERAL',
                startDate: '01/05/2021',
                endDate: '01/10/2021',
                continuity: 'Continuous',
                lastChangeDate: '01/29/2021',
                changeAction: 'DENIED',
                changeActionId: '2',
                requestActionId: '954951638',
                leavePlanName: 'Federal Family and Medical Leave Act (FMLA)',
                leavePlanType: 'FEDERAL',
                leavePlanDescription: '',
                denialReason: 'You haven\'t worked enough hours to qualify.'
              },
            ],
            AccommodationDescription: '',
            AccommodationsubCategory: '',
            substatus: 'DOB/DOP Missing',
            substatusId: '147',
            AccommodationType: ''
          }
        ],
        certRequestList: [
        ],
        leaveISMedical: '0',
        leaveRequestStartDate: '01/05/2021',
        relationship: 'Newborn',
        confirmReturnToWork: null,
        missingInfo: [
        ]
      },

      {
        leaveId: '1338103',
        leaveStartDate: '10/08/2019',
        leaveEndDate: '10/08/2019',
        leaveStatus: 'Open',
        leaveReason: 'Disability Accommodations',
        caseId: '0737469',
        personId: '8489427',
        reedLeaveId: '0000',
        leaveProcessDate: '10/15/2019',
        leaveType: 'ADA Accommodation',
        leaveSubType: 'intermittent',
        leaveEstimatedEndDate: '',
        leaveEditDate: null,
        insuredFirstName: null,
        insuredLastName: null,
        leaveFirstName: '',
        leaveLastName: '',
        absenceDetails: [
          {
            requestId: '1345700',
            status: 'PENDED',
            statusId: '3',
            substatus: 'Accommodation Review - at work',
            substatusId: '139',
            AccommodationDescription: 'Job Restrictions and/or Limitations',
            AccommodationsubCategory: 'Reach above shoulder',
            AccommodationType: 'Permanent',
            requestEndDate: '11/09/2019',
            requestStartDate: '08/08/2019',
            denialReason: '',
            leavePlan: [
              {
                planId: '2506',
                planName: 'FEDERAL',
                startDate: '10/08/2019',
                endDate: '10/08/2019',
                continuity: 'Continuous',
                lastChangeDate: '10/15/2019',
                changeAction: 'PENDED',
                changeActionId: '3',
                requestActionId: '839697941',
                leavePlanName: 'ADA Accommodation',
                leavePlanType: 'FEDERAL',
                leavePlanDescription: 'Under the Reasonable Accommodation provisions of the ADA, eligibility for an accommodation will be considered if you have a substantially limiting impairment or record of such an impairment as defined by the ADA.',
                denialReason: ''
              }
            ]
          }
        ],
        certRequestList: [],
        leaveISMedical: '0',
        leaveRequestStartDate: '08/08/2019'
      },
      {
        leaveId: '1345699',
        leaveStartDate: '10/08/2019',
        leaveEndDate: '10/08/2019',
        leaveStatus: 'Open',
        leaveReason: 'Disability Accommodations',
        caseId: '0737469',
        personId: '8489427',
        reedLeaveId: '0000',
        leaveProcessDate: '10/15/2019',
        leaveType: 'ADA Accommodation',
        leaveSubType: 'Continuous',
        leaveEstimatedEndDate: '',
        leaveEditDate: null,
        insuredFirstName: null,
        insuredLastName: null,
        leaveFirstName: '',
        leaveLastName: '',
        absenceDetails: [
          {
            requestId: '1345700',
            status: 'PENDED',
            statusId: '3',
            substatus: 'not at work',
            substatusId: '140',
            AccommodationDescription: 'Ergonomic Equipment',
            AccommodationsubCategory: 'Back brace',
            requestStartDate: '09/08/2019',
            AccommodationType: 'Permanent',
            requestEndDate: '11/09/2019',
            denialReason: '',
            leavePlan: [
              {
                planId: '2506',
                planName: 'FEDERAL',
                startDate: '10/08/2019',
                endDate: '10/08/2019',
                continuity: 'Continuous',
                lastChangeDate: '10/15/2019',
                changeAction: 'PENDED',
                changeActionId: '3',
                requestActionId: '839697941',
                leavePlanName: 'ADA Accommodation',
                leavePlanType: 'FEDERAL',
                leavePlanDescription: 'Under the Reasonable Accommodation provisions of the ADA, eligibility for an accommodation will be considered if you have a substantially limiting impairment or record of such an impairment as defined by the ADA.',
                denialReason: ''
              }
            ]
          }
        ],
        certRequestList: [],
        leaveISMedical: '0',
        leaveRequestStartDate: '09/08/2019'
      },
      {
        leaveId: '1345625',
        leaveStartDate: '10/01/2019',
        leaveEndDate: '10/03/2019',
        leaveStatus: 'Open',
        leaveReason: 'Disability Accommodations',
        caseId: '0737469',
        personId: '8489427',
        reedLeaveId: '0000',
        leaveProcessDate: '10/15/2019',
        leaveType: 'ADA Accommodation',
        leaveSubType: 'Continuous',
        leaveEstimatedEndDate: '',
        leaveEditDate: null,
        insuredFirstName: null,
        insuredLastName: null,
        leaveFirstName: '',
        leaveLastName: '',
        absenceDetails: [
          {
            requestId: '1345686',
            status: 'approved',
            statusId: '1',
            substatus: 'Accommodation Review - at work',
            substatusId: '139',
            AccommodationDescription: 'Ergonomic Equipment',
            AccommodationsubCategory: 'Back brace',
            requestStartDate: '10/01/2019',
            AccommodationType: 'temporary',
            requestEndDate: '11/09/2019',
            denialReason: 'Plan exhausted',
            leavePlan: [
              {
                planId: '2506',
                planName: 'FEDERAL',
                startDate: '10/01/2019',
                endDate: '10/03/2019',
                continuity: 'Continuous',
                lastChangeDate: '10/15/2019',
                changeAction: 'PENDED',
                changeActionId: '3',
                requestActionId: '839697940',
                leavePlanName: 'ADA Accommodation',
                leavePlanType: 'FEDERAL',
                leavePlanDescription: 'Under the Reasonable Accommodation provisions of the ADA, eligibility for an accommodation will be considered if you have a substantially limiting impairment or record of such an impairment as defined by the ADA.',
                denialReason: ''
              }
            ]
          },
          {
            requestId: '1345687',
            status: 'DENIED',
            statusId: '2',
            substatus: 'Accommodation Review - at work',
            substatusId: '139',
            AccommodationDescription: 'Equipment',
            AccommodationsubCategory: 'Floor Mat',
            requestStartDate: '10/01/2019',
            AccommodationType: 'Permanent',
            requestEndDate: '11/07/2019',
            denialReason: 'Plan not available',
            leavePlan: [
              {
                planId: '2506',
                planName: 'FEDERAL',
                startDate: '10/01/2019',
                endDate: '10/03/2019',
                continuity: 'Continuous',
                lastChangeDate: '10/15/2019',
                changeAction: 'PENDED',
                changeActionId: '3',
                requestActionId: '839697940',
                leavePlanName: 'ADA Accommodation',
                leavePlanType: 'FEDERAL',
                leavePlanDescription: 'Under the Reasonable Accommodation provisions of the ADA, eligibility for an accommodation will be considered if you have a substantially limiting impairment or record of such an impairment as defined by the ADA.',
                denialReason: ''
              }
            ]
          },
          {
            requestId: '1345689',
            status: 'DENIED',
            statusId: '2',
            substatus: 'Accommodation Review - at work',
            substatusId: '139',
            AccommodationDescription: 'Equipment',
            AccommodationsubCategory: 'Floor Mat',
            requestStartDate: '10/01/2019',
            AccommodationType: 'temporary',
            requestEndDate: '11/08/2019',
            denialReason: 'Plan not available',
            leavePlan: [
              {
                planId: '2506',
                planName: 'FEDERAL',
                startDate: '10/01/2019',
                endDate: '10/03/2019',
                continuity: 'Continuous',
                lastChangeDate: '10/15/2019',
                changeAction: 'PENDED',
                changeActionId: '3',
                requestActionId: '839697940',
                leavePlanName: 'ADA Accommodation',
                leavePlanType: 'FEDERAL',
                leavePlanDescription: 'Under the Reasonable Accommodation provisions of the ADA, eligibility for an accommodation will be considered if you have a substantially limiting impairment or record of such an impairment as defined by the ADA.',
                denialReason: ''
              }
            ]
          },
          {
            requestId: '1345690',
            status: 'DENIED',
            statusId: '2',
            substatus: 'Accommodation Review - at work',
            substatusId: '139',
            AccommodationDescription: 'Ergonomic Equipment',
            AccommodationsubCategory: 'Back brace',
            requestStartDate: '10/01/2019',
            AccommodationType: 'temporary',
            requestEndDate: '11/08/2019',
            denialReason: 'Plan not available',
            leavePlan: [
              {
                planId: '2506',
                planName: 'FEDERAL',
                startDate: '10/01/2019',
                endDate: '10/03/2019',
                continuity: 'Continuous',
                lastChangeDate: '10/15/2019',
                changeAction: 'PENDED',
                changeActionId: '3',
                requestActionId: '839697940',
                leavePlanName: 'ADA Accommodation',
                leavePlanType: 'FEDERAL',
                leavePlanDescription: 'Under the Reasonable Accommodation provisions of the ADA, eligibility for an accommodation will be considered if you have a substantially limiting impairment or record of such an impairment as defined by the ADA.',
                denialReason: ''
              }
            ]
          },
          {
            requestId: '1345690',
            status: 'DENIED',
            statusId: '2',
            substatus: 'Accommodation Review - at work',
            substatusId: '139',
            AccommodationDescription: 'Job restrictions',
            AccommodationsubCategory: 'Floor Mat',
            requestStartDate: '10/01/2019',
            AccommodationType: 'permanent',
            requestEndDate: '11/08/2019',
            denialReason: 'Plan not available',
            leavePlan: [
              {
                planId: '2506',
                planName: 'FEDERAL',
                startDate: '10/01/2019',
                endDate: '10/03/2019',
                continuity: 'Continuous',
                lastChangeDate: '10/15/2019',
                changeAction: 'PENDED',
                changeActionId: '3',
                requestActionId: '839697940',
                leavePlanName: 'ADA Accommodation',
                leavePlanType: 'FEDERAL',
                leavePlanDescription: 'Under the Reasonable Accommodation provisions of the ADA, eligibility for an accommodation will be considered if you have a substantially limiting impairment or record of such an impairment as defined by the ADA.',
                denialReason: ''
              }
            ]
          },
          {
            requestId: '1345690',
            status: 'DENIED',
            statusId: '2',
            substatus: 'Accommodation Review - at work',
            substatusId: '139',
            AccommodationDescription: 'Equipment',
            AccommodationsubCategory: 'Floor Mat',
            requestStartDate: '10/01/2019',
            AccommodationType: 'temporary',
            requestEndDate: '11/19/2019',
            denialReason: 'Plan not available',
            leavePlan: [
              {
                planId: '2506',
                planName: 'FEDERAL',
                startDate: '10/01/2019',
                endDate: '10/03/2019',
                continuity: 'Continuous',
                lastChangeDate: '10/15/2019',
                changeAction: 'PENDED',
                changeActionId: '3',
                requestActionId: '839697940',
                leavePlanName: 'ADA Accommodation',
                leavePlanType: 'FEDERAL',
                leavePlanDescription: 'Under the Reasonable Accommodation provisions of the ADA, eligibility for an accommodation will be considered if you have a substantially limiting impairment or record of such an impairment as defined by the ADA.',
                denialReason: ''
              }
            ]
          }
        ],
        certRequestList: [],
        leaveISMedical: '0',
        leaveRequestStartDate: '10/01/2019'
      },
      //Concurrent to WD SUPP 11/30/2018
      {
        leaveId: '100001',
        leaveStartDate: '09/01/2018',
        leaveEndDate: '09/30/2018',
        leaveStatus: 'Cancelled',
        leaveReason: `Day-Care Activity`,
        caseId: '200000',
        personId: '300000',
        reedLeaveId: null,
        leaveProcessDate: '09/02/2018',
        leaveType: 'Concurrent',
        leaveSubType: 'intermittent',
        leaveEditDate: '09/28/2018',
        insuredFirstName: 'James',
        insuredLastName: 'Thomas',
        leaveFirstName: 'mary',
        leaveLastName: 'Thomas',
        leavePlanChangedDetected: true,
        leaveRequestStartDate: '09/23/2018',
        leaveISMedical : 0,
        certRequestList : [
          {
            certPeriodStartDate: '09/01/2018',
            certPeriodEndDate: '09/05/2018',
            status: 'Pend',
            continuity : 'Intermittent'
          },
          {
            certPeriodStartDate: '09/08/2018',
            certPeriodEndDate: '09/14/2018',
            status: 'Pend',
            continuity : 'reduced schedule'
          },
          {
            certPeriodStartDate: '09/15/2018',
            certPeriodEndDate: '09/21/2018',
            continuity: 'reduced schedule',
            status: 'Pend'
          },
          {
            certPeriodStartDate: '09/22/2018',
            certPeriodEndDate: '09/30/2018',
            status: 'Pend',
            continuity : 'continuous'
          }
        ],
        leaveRequestList: [
          {
            type: '',
            continuity: 'reduced schedule',
            startTime: '09/01/2018',
            endTime: '09/30/2018',
            status: 'Pend'
          }
        ],
        absenceDetails: [
          {
            leavePlan: [
              {
                planId: '1',
                planName: 'FEDERAL',
                startDate: '09/23/2018',
                endDate: '09/23/2018',
                continuity: 'Intermittent',
                lastChangeDate: '09/23/2015',
                changeAction: 'PENDED',
                changeActionId: '3',
                requestActionId: '41019622',
                leavePlanName: 'Federal Family and Medical Leave Act (FMLA)',
                leavePlanType: 'FEDERAL',
                leavePlanDescription: '',
                denialReason: ''
              },
              {
                planId: '5',
                planName: 'STATE',
                startDate: '09/24/2018',
                endDate: '09/24/2018',
                continuity: 'Intermittent',
                lastChangeDate: '09/23/2015',
                changeAction: 'DENIED',
                changeActionId: '3',
                requestActionId: '41019623',
                leavePlanName: 'California Family Rights Act (CFRA)',
                leavePlanType: 'STATE',
                leavePlanDescription: '',
                denialReason: ''
              }
            ],
            requestId: '1023473',
            status: 'PENDED',
            statusId: '3',
            requestStartDate: '9/23/2015',
            denialReason: ''
          }
        ]
      },
      //Concurrent to WD SUPP 11/15/2018
      {
        leaveId: '200001',
        leaveStartDate: '12/01/2018',
        leaveEndDate: null,
        leaveStatus: 'Open',
        leaveReason: `My Own Illness`,
        caseId: '456789',
        personId: '123456',
        reedLeaveId: null,
        leaveProcessDate : '09/02/2018',
        leaveType : 'Standalone',
        leaveSubType : 'Continuous',
        leaveEditDate : '09/28/2018',
        insuredFirstName : 'James',
        insuredLastName : 'Thomas',
        leaveFirstName : 'Mary',
        leaveLastName : 'Thomas',
        certRequestList : [],
        leaveRequestList: [],
        leavePlanChangedDetected: true,
        leaveRequestStartDate: '12/01/2018',
        absenceDetails: [{
          requestId: '1339069',
          status: 'APPROVED',
          statusId: '1',
          requestStartDate: '04/01/2019',
          denialReason: '',
          leavePlan: [{
            planId: '1',
            planName: 'FEDERAL',
            startDate: '08/11/2019',
            endDate: '08/11/2019',
            continuity: 'Intermittent',
            lastChangeDate: '06/18/2019',
            changeAction: 'APPROVED',
            changeActionId: '1',
            requestActionId: '833652424',
            leavePlanName: 'Federal Family and Medical Leave Act (FMLA)',
            leavePlanType: 'FEDERAL',
            leavePlanDescription: 'Under <span class=\'scayt-misspell\' data-scayt_word=\'FMLA\' data-scaytid=\'1\'>FMLA</span>, eligible employees can take up to 12 weeks of unpaid leave in a 12-month period for the following reasons:\r\n<ul>\r\n\t<li>\r\n\t\tBecause you have a serious health condition that keeps you from doing your job\r\n\t</li>\r\n\t<li>\r\n\t\tCaring for a child, spouse, or parent with a serious health condition\r\n\t</li>\r\n\t<li>\r\n\t\tBirth of a child\r\n\t</li>\r\n\t<li>\r\n\t\tPlacement of a child for adoption or foster care\r\n\t</li>\r\n\t<li>\r\n\t\tTo deal with certain arrangements when your spouse, son, daughter, or parent is serving in the military and deployed to a foreign country\r\n\t</li>\r\n</ul>\r\nA &ldquo;child&rdquo; includes a biological child, adopted child, or foster child, as well as a child you&rsquo;re acting in a parent-like role for. You can take <span class=\'scayt-misspell\' data-scayt_word=\'FMLA\' data-scaytid=\'5\'>FMLA</span> to care for a child with a serious health condition as long as they&rsquo;re younger than 18 years old. You may be able to take <span class=\'scayt-misspell\' data-scayt_word=\'FMLA\' data-scaytid=\'9\'>FMLA</span> to care for a child 18 or older, if they have a disability that makes them unable to care for themselves.<br />\r\n<br />\r\nIf you&#39;re caring for a covered service member with a serious injury or illness and you&rsquo;re that person&#39;s spouse, son, daughter, parent or next of kin, you can take up to 26 weeks of unpaid leave in a 12-month period.<br />\r\n<br />\r\nYou should tell us and your employer that you&#39;ll be out of work 30 days in advance. If you don&#39;t know that far in advance, let both of us know as soon as possible.',
            denialReason: ''
          }, {
            planId: '5',
            planName: 'STATE',
            startDate: '08/10/2019',
            endDate: '08/10/2019',
            continuity: 'Intermittent',
            lastChangeDate: '06/18/2019',
            changeAction: 'APPROVED',
            changeActionId: '1',
            requestActionId: '833652425',
            leavePlanName: 'California Family Rights Act (CFRA)',
            leavePlanType: 'STATE',
            leavePlanDescription: 'Under the California Family Rights Act (<span class=\'scayt-misspell\' data-scayt_word=\'CFRA\' data-scaytid=\'1\'>CFRA</span>), an employer who employs more than 50 employees within 75 miles of the work site where the employee is employed, cannot refuse to grant this leave to an employee who has more than 12 months of service with the employer, and who has at least 1,250 hours of service during the previous 12 month period, to take up to a total of 12 work weeks of unpaid leave in a 12 month period for Family care and medical leave. &quot;Family care and medical leave&quot; means the following:\r\n<ol type=\'a\'>\r\n <li>\r\n  Leave for reason of the birth, adoption, or foster care placement of the employee&#39;s child, or for the serious health condition of a child of the employee.</li>\r\n <li>\r\n  Leave to care for a parent, a spouse or registered domestic partner who has a serious health condition.</li>\r\n <li>\r\n  Leave because of an employee&#39;s own serious health condition (excluding leave taken for disability caused by pregnancy, childbirth or related medical conditions). This <span class=\'scayt-misspell\' data-scayt_word=\'CFRA\' data-scaytid=\'3\'>CFRA</span> leave may run consecutive to leave afforded under the California Pregnancy Disability Leave (<span class=\'scayt-misspell\' data-scayt_word=\'PDL\' data-scaytid=\'5\'>PDL</span>) and concurrent with other applicable Federal <span class=\'scayt-misspell\' data-scayt_word=\'FMLA\' data-scaytid=\'7\'>FMLA</span> leave. Your job restoration rights and your rights to maintenance of health benefits under this state law are similar to Federal <span class=\'scayt-misspell\' data-scayt_word=\'FMLA\' data-scaytid=\'9\'>FMLA</span>.</li>\r\n</ol>\r\n',
            denialReason: ''
          }, {
            planId: '256',
            planName: 'COMPANY',
            startDate: '08/11/2019',
            endDate: '08/10/2019',
            continuity: 'Intermittent',
            lastChangeDate: '06/18/2019',
            changeAction: 'APPROVED',
            changeActionId: '1',
            requestActionId: '833652426',
            leavePlanName: 'Takeda Company FMLA',
            leavePlanType: 'COMPANY',
            leavePlanDescription: 'Except as explained below, you have a right under the FMLA for up to 12 weeks of unpaid leave in a 12-month period for the care of a spouse, child, or parent, domestic partner, or for your own serious illness. Your health benefits will be maintained during any period of approved unpaid leave under the same conditions as if you continued to work, and are subject to your continued employee contributions. You may be reinstated to the same or an equivalent job with the same pay, benefits, and terms and conditions of employment on your return from leave.',
            denialReason: ''
          }]
        }, {
          requestId: '1339072',
          status: 'APPROVED',
          statusId: '1',
          requestStartDate: '04/03/2019',
          denialReason: '',
          leavePlan: [{
            planId: '1',
            planName: 'FEDERAL',
            startDate: '08/10/2019',
            endDate: '08/10/2019',
            continuity: 'Intermittent',
            lastChangeDate: '06/18/2019',
            changeAction: 'APPROVED',
            changeActionId: '1',
            requestActionId: '833652433',
            leavePlanName: 'Federal Family and Medical Leave Act (FMLA)',
            leavePlanType: 'FEDERAL',
            leavePlanDescription: 'Under <span class=\'scayt-misspell\' data-scayt_word=\'FMLA\' data-scaytid=\'1\'>FMLA</span>, eligible employees can take up to 12 weeks of unpaid leave in a 12-month period for the following reasons:\r\n<ul>\r\n\t<li>\r\n\t\tBecause you have a serious health condition that keeps you from doing your job\r\n\t</li>\r\n\t<li>\r\n\t\tCaring for a child, spouse, or parent with a serious health condition\r\n\t</li>\r\n\t<li>\r\n\t\tBirth of a child\r\n\t</li>\r\n\t<li>\r\n\t\tPlacement of a child for adoption or foster care\r\n\t</li>\r\n\t<li>\r\n\t\tTo deal with certain arrangements when your spouse, son, daughter, or parent is serving in the military and deployed to a foreign country\r\n\t</li>\r\n</ul>\r\nA &ldquo;child&rdquo; includes a biological child, adopted child, or foster child, as well as a child you&rsquo;re acting in a parent-like role for. You can take <span class=\'scayt-misspell\' data-scayt_word=\'FMLA\' data-scaytid=\'5\'>FMLA</span> to care for a child with a serious health condition as long as they&rsquo;re younger than 18 years old. You may be able to take <span class=\'scayt-misspell\' data-scayt_word=\'FMLA\' data-scaytid=\'9\'>FMLA</span> to care for a child 18 or older, if they have a disability that makes them unable to care for themselves.<br />\r\n<br />\r\nIf you&#39;re caring for a covered service member with a serious injury or illness and you&rsquo;re that person&#39;s spouse, son, daughter, parent or next of kin, you can take up to 26 weeks of unpaid leave in a 12-month period.<br />\r\n<br />\r\nYou should tell us and your employer that you&#39;ll be out of work 30 days in advance. If you don&#39;t know that far in advance, let both of us know as soon as possible.',
            denialReason: ''
          }, {
            planId: '5',
            planName: 'STATE',
            startDate: '08/09/2019',
            endDate: '08/09/2019',
            continuity: 'Intermittent',
            lastChangeDate: '06/18/2019',
            changeAction: 'APPROVED',
            changeActionId: '1',
            requestActionId: '833652434',
            leavePlanName: 'California Family Rights Act (CFRA)',
            leavePlanType: 'STATE',
            leavePlanDescription: 'Under the California Family Rights Act (<span class=\'scayt-misspell\' data-scayt_word=\'CFRA\' data-scaytid=\'1\'>CFRA</span>), an employer who employs more than 50 employees within 75 miles of the work site where the employee is employed, cannot refuse to grant this leave to an employee who has more than 12 months of service with the employer, and who has at least 1,250 hours of service during the previous 12 month period, to take up to a total of 12 work weeks of unpaid leave in a 12 month period for Family care and medical leave. &quot;Family care and medical leave&quot; means the following:\r\n<ol type=\'a\'>\r\n <li>\r\n  Leave for reason of the birth, adoption, or foster care placement of the employee&#39;s child, or for the serious health condition of a child of the employee.</li>\r\n <li>\r\n  Leave to care for a parent, a spouse or registered domestic partner who has a serious health condition.</li>\r\n <li>\r\n  Leave because of an employee&#39;s own serious health condition (excluding leave taken for disability caused by pregnancy, childbirth or related medical conditions). This <span class=\'scayt-misspell\' data-scayt_word=\'CFRA\' data-scaytid=\'3\'>CFRA</span> leave may run consecutive to leave afforded under the California Pregnancy Disability Leave (<span class=\'scayt-misspell\' data-scayt_word=\'PDL\' data-scaytid=\'5\'>PDL</span>) and concurrent with other applicable Federal <span class=\'scayt-misspell\' data-scayt_word=\'FMLA\' data-scaytid=\'7\'>FMLA</span> leave. Your job restoration rights and your rights to maintenance of health benefits under this state law are similar to Federal <span class=\'scayt-misspell\' data-scayt_word=\'FMLA\' data-scaytid=\'9\'>FMLA</span>.</li>\r\n</ol>\r\n',
            denialReason: ''
          }, {
            planId: '256',
            planName: 'COMPANY',
            startDate: '08/09/2019',
            endDate: '08/09/2019',
            continuity: 'Intermittent',
            lastChangeDate: '06/18/2019',
            changeAction: 'APPROVED',
            changeActionId: '1',
            requestActionId: '833652435',
            leavePlanName: 'Takeda Company FMLA',
            leavePlanType: 'COMPANY',
            leavePlanDescription: 'Except as explained below, you have a right under the FMLA for up to 12 weeks of unpaid leave in a 12-month period for the care of a spouse, child, or parent, domestic partner, or for your own serious illness. Your health benefits will be maintained during any period of approved unpaid leave under the same conditions as if you continued to work, and are subject to your continued employee contributions. You may be reinstated to the same or an equivalent job with the same pay, benefits, and terms and conditions of employment on your return from leave.',
            denialReason: ''
          }]
        }, {
          requestId: '1339073',
          status: 'APPROVED',
          statusId: '1',
          requestStartDate: '04/08/2019',
          denialReason: '',
          leavePlan: [{
            planId: '1',
            planName: 'FEDERAL',
            startDate: '08/09/2019',
            endDate: '08/09/2019',
            continuity: 'Intermittent',
            lastChangeDate: '06/18/2019',
            changeAction: 'APPROVED',
            changeActionId: '1',
            requestActionId: '833652442',
            leavePlanName: 'Federal Family and Medical Leave Act (FMLA)',
            leavePlanType: 'FEDERAL',
            leavePlanDescription: 'Under <span class=\'scayt-misspell\' data-scayt_word=\'FMLA\' data-scaytid=\'1\'>FMLA</span>, eligible employees can take up to 12 weeks of unpaid leave in a 12-month period for the following reasons:\r\n<ul>\r\n\t<li>\r\n\t\tBecause you have a serious health condition that keeps you from doing your job\r\n\t</li>\r\n\t<li>\r\n\t\tCaring for a child, spouse, or parent with a serious health condition\r\n\t</li>\r\n\t<li>\r\n\t\tBirth of a child\r\n\t</li>\r\n\t<li>\r\n\t\tPlacement of a child for adoption or foster care\r\n\t</li>\r\n\t<li>\r\n\t\tTo deal with certain arrangements when your spouse, son, daughter, or parent is serving in the military and deployed to a foreign country\r\n\t</li>\r\n</ul>\r\nA &ldquo;child&rdquo; includes a biological child, adopted child, or foster child, as well as a child you&rsquo;re acting in a parent-like role for. You can take <span class=\'scayt-misspell\' data-scayt_word=\'FMLA\' data-scaytid=\'5\'>FMLA</span> to care for a child with a serious health condition as long as they&rsquo;re younger than 18 years old. You may be able to take <span class=\'scayt-misspell\' data-scayt_word=\'FMLA\' data-scaytid=\'9\'>FMLA</span> to care for a child 18 or older, if they have a disability that makes them unable to care for themselves.<br />\r\n<br />\r\nIf you&#39;re caring for a covered service member with a serious injury or illness and you&rsquo;re that person&#39;s spouse, son, daughter, parent or next of kin, you can take up to 26 weeks of unpaid leave in a 12-month period.<br />\r\n<br />\r\nYou should tell us and your employer that you&#39;ll be out of work 30 days in advance. If you don&#39;t know that far in advance, let both of us know as soon as possible.',
            denialReason: ''
          }, {
            planId: '5',
            planName: 'STATE',
            startDate: '08/08/2019',
            endDate: '08/08/2019',
            continuity: 'Intermittent',
            lastChangeDate: '06/18/2019',
            changeAction: 'APPROVED',
            changeActionId: '1',
            requestActionId: '833652443',
            leavePlanName: 'California Family Rights Act (CFRA)',
            leavePlanType: 'STATE',
            leavePlanDescription: 'Under the California Family Rights Act (<span class=\'scayt-misspell\' data-scayt_word=\'CFRA\' data-scaytid=\'1\'>CFRA</span>), an employer who employs more than 50 employees within 75 miles of the work site where the employee is employed, cannot refuse to grant this leave to an employee who has more than 12 months of service with the employer, and who has at least 1,250 hours of service during the previous 12 month period, to take up to a total of 12 work weeks of unpaid leave in a 12 month period for Family care and medical leave. &quot;Family care and medical leave&quot; means the following:\r\n<ol type=\'a\'>\r\n <li>\r\n  Leave for reason of the birth, adoption, or foster care placement of the employee&#39;s child, or for the serious health condition of a child of the employee.</li>\r\n <li>\r\n  Leave to care for a parent, a spouse or registered domestic partner who has a serious health condition.</li>\r\n <li>\r\n  Leave because of an employee&#39;s own serious health condition (excluding leave taken for disability caused by pregnancy, childbirth or related medical conditions). This <span class=\'scayt-misspell\' data-scayt_word=\'CFRA\' data-scaytid=\'3\'>CFRA</span> leave may run consecutive to leave afforded under the California Pregnancy Disability Leave (<span class=\'scayt-misspell\' data-scayt_word=\'PDL\' data-scaytid=\'5\'>PDL</span>) and concurrent with other applicable Federal <span class=\'scayt-misspell\' data-scayt_word=\'FMLA\' data-scaytid=\'7\'>FMLA</span> leave. Your job restoration rights and your rights to maintenance of health benefits under this state law are similar to Federal <span class=\'scayt-misspell\' data-scayt_word=\'FMLA\' data-scaytid=\'9\'>FMLA</span>.</li>\r\n</ol>\r\n',
            denialReason: ''
          }, {
            planId: '256',
            planName: 'COMPANY',
            startDate: '08/08/2019',
            endDate: '08/08/2019',
            continuity: 'Intermittent',
            lastChangeDate: '06/18/2019',
            changeAction: 'APPROVED',
            changeActionId: '1',
            requestActionId: '833652444',
            leavePlanName: 'Takeda Company FMLA',
            leavePlanType: 'COMPANY',
            leavePlanDescription: 'Except as explained below, you have a right under the FMLA for up to 12 weeks of unpaid leave in a 12-month period for the care of a spouse, child, or parent, domestic partner, or for your own serious illness. Your health benefits will be maintained during any period of approved unpaid leave under the same conditions as if you continued to work, and are subject to your continued employee contributions. You may be reinstated to the same or an equivalent job with the same pay, benefits, and terms and conditions of employment on your return from leave.',
            denialReason: ''
          }]
        }, {
          requestId: '1339074',
          status: 'APPROVED',
          statusId: '1',
          requestStartDate: '04/10/2019',
          denialReason: '',
          leavePlan: [{
            planId: '1',
            planName: 'FEDERAL',
            startDate: '08/08/2019',
            endDate: '08/08/2019',
            continuity: 'Intermittent',
            lastChangeDate: '06/18/2019',
            changeAction: 'APPROVED',
            changeActionId: '1',
            requestActionId: '833652451',
            leavePlanName: 'Federal Family and Medical Leave Act (FMLA)',
            leavePlanType: 'FEDERAL',
            leavePlanDescription: 'Under <span class=\'scayt-misspell\' data-scayt_word=\'FMLA\' data-scaytid=\'1\'>FMLA</span>, eligible employees can take up to 12 weeks of unpaid leave in a 12-month period for the following reasons:\r\n<ul>\r\n\t<li>\r\n\t\tBecause you have a serious health condition that keeps you from doing your job\r\n\t</li>\r\n\t<li>\r\n\t\tCaring for a child, spouse, or parent with a serious health condition\r\n\t</li>\r\n\t<li>\r\n\t\tBirth of a child\r\n\t</li>\r\n\t<li>\r\n\t\tPlacement of a child for adoption or foster care\r\n\t</li>\r\n\t<li>\r\n\t\tTo deal with certain arrangements when your spouse, son, daughter, or parent is serving in the military and deployed to a foreign country\r\n\t</li>\r\n</ul>\r\nA &ldquo;child&rdquo; includes a biological child, adopted child, or foster child, as well as a child you&rsquo;re acting in a parent-like role for. You can take <span class=\'scayt-misspell\' data-scayt_word=\'FMLA\' data-scaytid=\'5\'>FMLA</span> to care for a child with a serious health condition as long as they&rsquo;re younger than 18 years old. You may be able to take <span class=\'scayt-misspell\' data-scayt_word=\'FMLA\' data-scaytid=\'9\'>FMLA</span> to care for a child 18 or older, if they have a disability that makes them unable to care for themselves.<br />\r\n<br />\r\nIf you&#39;re caring for a covered service member with a serious injury or illness and you&rsquo;re that person&#39;s spouse, son, daughter, parent or next of kin, you can take up to 26 weeks of unpaid leave in a 12-month period.<br />\r\n<br />\r\nYou should tell us and your employer that you&#39;ll be out of work 30 days in advance. If you don&#39;t know that far in advance, let both of us know as soon as possible.',
            denialReason: ''
          }, {
            planId: '5',
            planName: 'STATE',
            startDate: '08/08/2019',
            endDate: '08/08/2019',
            continuity: 'Intermittent',
            lastChangeDate: '06/18/2019',
            changeAction: 'APPROVED',
            changeActionId: '1',
            requestActionId: '833652452',
            leavePlanName: 'California Family Rights Act (CFRA)',
            leavePlanType: 'STATE',
            leavePlanDescription: 'Under the California Family Rights Act (<span class=\'scayt-misspell\' data-scayt_word=\'CFRA\' data-scaytid=\'1\'>CFRA</span>), an employer who employs more than 50 employees within 75 miles of the work site where the employee is employed, cannot refuse to grant this leave to an employee who has more than 12 months of service with the employer, and who has at least 1,250 hours of service during the previous 12 month period, to take up to a total of 12 work weeks of unpaid leave in a 12 month period for Family care and medical leave. &quot;Family care and medical leave&quot; means the following:\r\n<ol type=\'a\'>\r\n <li>\r\n  Leave for reason of the birth, adoption, or foster care placement of the employee&#39;s child, or for the serious health condition of a child of the employee.</li>\r\n <li>\r\n  Leave to care for a parent, a spouse or registered domestic partner who has a serious health condition.</li>\r\n <li>\r\n  Leave because of an employee&#39;s own serious health condition (excluding leave taken for disability caused by pregnancy, childbirth or related medical conditions). This <span class=\'scayt-misspell\' data-scayt_word=\'CFRA\' data-scaytid=\'3\'>CFRA</span> leave may run consecutive to leave afforded under the California Pregnancy Disability Leave (<span class=\'scayt-misspell\' data-scayt_word=\'PDL\' data-scaytid=\'5\'>PDL</span>) and concurrent with other applicable Federal <span class=\'scayt-misspell\' data-scayt_word=\'FMLA\' data-scaytid=\'7\'>FMLA</span> leave. Your job restoration rights and your rights to maintenance of health benefits under this state law are similar to Federal <span class=\'scayt-misspell\' data-scayt_word=\'FMLA\' data-scaytid=\'9\'>FMLA</span>.</li>\r\n</ol>\r\n',
            denialReason: ''
          }, {
            planId: '256',
            planName: 'COMPANY',
            startDate: '08/08/2019',
            endDate: '08/08/2019',
            continuity: 'Intermittent',
            lastChangeDate: '06/18/2019',
            changeAction: 'APPROVED',
            changeActionId: '1',
            requestActionId: '833652453',
            leavePlanName: 'Takeda Company FMLA',
            leavePlanType: 'COMPANY',
            leavePlanDescription: 'Except as explained below, you have a right under the FMLA for up to 12 weeks of unpaid leave in a 12-month period for the care of a spouse, child, or parent, domestic partner, or for your own serious illness. Your health benefits will be maintained during any period of approved unpaid leave under the same conditions as if you continued to work, and are subject to your continued employee contributions. You may be reinstated to the same or an equivalent job with the same pay, benefits, and terms and conditions of employment on your return from leave.',
            denialReason: ''
          }]
        }, {
          requestId: '1339075',
          status: 'APPROVED',
          statusId: '1',
          requestStartDate: '04/15/2019',
          denialReason: '',
          leavePlan: [{
            planId: '1',
            planName: 'FEDERAL',
            startDate: '08/07/2019',
            endDate: '08/07/2019',
            continuity: 'Intermittent',
            lastChangeDate: '06/18/2019',
            changeAction: 'APPROVED',
            changeActionId: '1',
            requestActionId: '833652460',
            leavePlanName: 'Federal Family and Medical Leave Act (FMLA)',
            leavePlanType: 'FEDERAL',
            leavePlanDescription: 'Under <span class=\'scayt-misspell\' data-scayt_word=\'FMLA\' data-scaytid=\'1\'>FMLA</span>, eligible employees can take up to 12 weeks of unpaid leave in a 12-month period for the following reasons:\r\n<ul>\r\n\t<li>\r\n\t\tBecause you have a serious health condition that keeps you from doing your job\r\n\t</li>\r\n\t<li>\r\n\t\tCaring for a child, spouse, or parent with a serious health condition\r\n\t</li>\r\n\t<li>\r\n\t\tBirth of a child\r\n\t</li>\r\n\t<li>\r\n\t\tPlacement of a child for adoption or foster care\r\n\t</li>\r\n\t<li>\r\n\t\tTo deal with certain arrangements when your spouse, son, daughter, or parent is serving in the military and deployed to a foreign country\r\n\t</li>\r\n</ul>\r\nA &ldquo;child&rdquo; includes a biological child, adopted child, or foster child, as well as a child you&rsquo;re acting in a parent-like role for. You can take <span class=\'scayt-misspell\' data-scayt_word=\'FMLA\' data-scaytid=\'5\'>FMLA</span> to care for a child with a serious health condition as long as they&rsquo;re younger than 18 years old. You may be able to take <span class=\'scayt-misspell\' data-scayt_word=\'FMLA\' data-scaytid=\'9\'>FMLA</span> to care for a child 18 or older, if they have a disability that makes them unable to care for themselves.<br />\r\n<br />\r\nIf you&#39;re caring for a covered service member with a serious injury or illness and you&rsquo;re that person&#39;s spouse, son, daughter, parent or next of kin, you can take up to 26 weeks of unpaid leave in a 12-month period.<br />\r\n<br />\r\nYou should tell us and your employer that you&#39;ll be out of work 30 days in advance. If you don&#39;t know that far in advance, let both of us know as soon as possible.',
            denialReason: ''
          }, {
            planId: '5',
            planName: 'STATE',
            startDate: '08/07/2019',
            endDate: '08/07/2019',
            continuity: 'Intermittent',
            lastChangeDate: '06/18/2019',
            changeAction: 'APPROVED',
            changeActionId: '1',
            requestActionId: '833652461',
            leavePlanName: 'California Family Rights Act (CFRA)',
            leavePlanType: 'STATE',
            leavePlanDescription: 'Under the California Family Rights Act (<span class=\'scayt-misspell\' data-scayt_word=\'CFRA\' data-scaytid=\'1\'>CFRA</span>), an employer who employs more than 50 employees within 75 miles of the work site where the employee is employed, cannot refuse to grant this leave to an employee who has more than 12 months of service with the employer, and who has at least 1,250 hours of service during the previous 12 month period, to take up to a total of 12 work weeks of unpaid leave in a 12 month period for Family care and medical leave. &quot;Family care and medical leave&quot; means the following:\r\n<ol type=\'a\'>\r\n <li>\r\n  Leave for reason of the birth, adoption, or foster care placement of the employee&#39;s child, or for the serious health condition of a child of the employee.</li>\r\n <li>\r\n  Leave to care for a parent, a spouse or registered domestic partner who has a serious health condition.</li>\r\n <li>\r\n  Leave because of an employee&#39;s own serious health condition (excluding leave taken for disability caused by pregnancy, childbirth or related medical conditions). This <span class=\'scayt-misspell\' data-scayt_word=\'CFRA\' data-scaytid=\'3\'>CFRA</span> leave may run consecutive to leave afforded under the California Pregnancy Disability Leave (<span class=\'scayt-misspell\' data-scayt_word=\'PDL\' data-scaytid=\'5\'>PDL</span>) and concurrent with other applicable Federal <span class=\'scayt-misspell\' data-scayt_word=\'FMLA\' data-scaytid=\'7\'>FMLA</span> leave. Your job restoration rights and your rights to maintenance of health benefits under this state law are similar to Federal <span class=\'scayt-misspell\' data-scayt_word=\'FMLA\' data-scaytid=\'9\'>FMLA</span>.</li>\r\n</ol>\r\n',
            denialReason: ''
          }, {
            planId: '256',
            planName: 'COMPANY',
            startDate: '08/07/2019',
            endDate: '08/07/2019',
            continuity: 'Intermittent',
            lastChangeDate: '06/18/2019',
            changeAction: 'APPROVED',
            changeActionId: '1',
            requestActionId: '833652462',
            leavePlanName: 'Takeda Company FMLA',
            leavePlanType: 'COMPANY',
            leavePlanDescription: 'Except as explained below, you have a right under the FMLA for up to 12 weeks of unpaid leave in a 12-month period for the care of a spouse, child, or parent, domestic partner, or for your own serious illness. Your health benefits will be maintained during any period of approved unpaid leave under the same conditions as if you continued to work, and are subject to your continued employee contributions. You may be reinstated to the same or an equivalent job with the same pay, benefits, and terms and conditions of employment on your return from leave.',
            denialReason: ''
          }]
        }, {
          requestId: '1339076',
          status: 'APPROVED',
          statusId: '1',
          requestStartDate: '04/17/2019',
          denialReason: '',
          leavePlan: [{
            planId: '1',
            planName: 'FEDERAL',
            startDate: '08/06/2019',
            endDate: '08/06/2019',
            continuity: 'Intermittent',
            lastChangeDate: '06/18/2019',
            changeAction: 'APPROVED',
            changeActionId: '1',
            requestActionId: '833652469',
            leavePlanName: 'Federal Family and Medical Leave Act (FMLA)',
            leavePlanType: 'FEDERAL',
            leavePlanDescription: 'Under <span class=\'scayt-misspell\' data-scayt_word=\'FMLA\' data-scaytid=\'1\'>FMLA</span>, eligible employees can take up to 12 weeks of unpaid leave in a 12-month period for the following reasons:\r\n<ul>\r\n\t<li>\r\n\t\tBecause you have a serious health condition that keeps you from doing your job\r\n\t</li>\r\n\t<li>\r\n\t\tCaring for a child, spouse, or parent with a serious health condition\r\n\t</li>\r\n\t<li>\r\n\t\tBirth of a child\r\n\t</li>\r\n\t<li>\r\n\t\tPlacement of a child for adoption or foster care\r\n\t</li>\r\n\t<li>\r\n\t\tTo deal with certain arrangements when your spouse, son, daughter, or parent is serving in the military and deployed to a foreign country\r\n\t</li>\r\n</ul>\r\nA &ldquo;child&rdquo; includes a biological child, adopted child, or foster child, as well as a child you&rsquo;re acting in a parent-like role for. You can take <span class=\'scayt-misspell\' data-scayt_word=\'FMLA\' data-scaytid=\'5\'>FMLA</span> to care for a child with a serious health condition as long as they&rsquo;re younger than 18 years old. You may be able to take <span class=\'scayt-misspell\' data-scayt_word=\'FMLA\' data-scaytid=\'9\'>FMLA</span> to care for a child 18 or older, if they have a disability that makes them unable to care for themselves.<br />\r\n<br />\r\nIf you&#39;re caring for a covered service member with a serious injury or illness and you&rsquo;re that person&#39;s spouse, son, daughter, parent or next of kin, you can take up to 26 weeks of unpaid leave in a 12-month period.<br />\r\n<br />\r\nYou should tell us and your employer that you&#39;ll be out of work 30 days in advance. If you don&#39;t know that far in advance, let both of us know as soon as possible.',
            denialReason: ''
          }, {
            planId: '5',
            planName: 'STATE',
            startDate: '08/06/2019',
            endDate: '08/06/2019',
            continuity: 'Intermittent',
            lastChangeDate: '06/18/2019',
            changeAction: 'APPROVED',
            changeActionId: '1',
            requestActionId: '833652470',
            leavePlanName: 'California Family Rights Act (CFRA)',
            leavePlanType: 'STATE',
            leavePlanDescription: 'Under the California Family Rights Act (<span class=\'scayt-misspell\' data-scayt_word=\'CFRA\' data-scaytid=\'1\'>CFRA</span>), an employer who employs more than 50 employees within 75 miles of the work site where the employee is employed, cannot refuse to grant this leave to an employee who has more than 12 months of service with the employer, and who has at least 1,250 hours of service during the previous 12 month period, to take up to a total of 12 work weeks of unpaid leave in a 12 month period for Family care and medical leave. &quot;Family care and medical leave&quot; means the following:\r\n<ol type=\'a\'>\r\n <li>\r\n  Leave for reason of the birth, adoption, or foster care placement of the employee&#39;s child, or for the serious health condition of a child of the employee.</li>\r\n <li>\r\n  Leave to care for a parent, a spouse or registered domestic partner who has a serious health condition.</li>\r\n <li>\r\n  Leave because of an employee&#39;s own serious health condition (excluding leave taken for disability caused by pregnancy, childbirth or related medical conditions). This <span class=\'scayt-misspell\' data-scayt_word=\'CFRA\' data-scaytid=\'3\'>CFRA</span> leave may run consecutive to leave afforded under the California Pregnancy Disability Leave (<span class=\'scayt-misspell\' data-scayt_word=\'PDL\' data-scaytid=\'5\'>PDL</span>) and concurrent with other applicable Federal <span class=\'scayt-misspell\' data-scayt_word=\'FMLA\' data-scaytid=\'7\'>FMLA</span> leave. Your job restoration rights and your rights to maintenance of health benefits under this state law are similar to Federal <span class=\'scayt-misspell\' data-scayt_word=\'FMLA\' data-scaytid=\'9\'>FMLA</span>.</li>\r\n</ol>\r\n',
            denialReason: ''
          }, {
            planId: '256',
            planName: 'COMPANY',
            startDate: '08/06/2019',
            endDate: '08/06/2019',
            continuity: 'Intermittent',
            lastChangeDate: '06/18/2019',
            changeAction: 'APPROVED',
            changeActionId: '1',
            requestActionId: '833652471',
            leavePlanName: 'Takeda Company FMLA',
            leavePlanType: 'COMPANY',
            leavePlanDescription: 'Except as explained below, you have a right under the FMLA for up to 12 weeks of unpaid leave in a 12-month period for the care of a spouse, child, or parent, domestic partner, or for your own serious illness. Your health benefits will be maintained during any period of approved unpaid leave under the same conditions as if you continued to work, and are subject to your continued employee contributions. You may be reinstated to the same or an equivalent job with the same pay, benefits, and terms and conditions of employment on your return from leave.',
            denialReason: ''
          }]
        }, {
          requestId: '1339077',
          status: 'APPROVED',
          statusId: '1',
          requestStartDate: '04/22/2019',
          denialReason: '',
          leavePlan: [{
            planId: '1',
            planName: 'FEDERAL',
            startDate: '08/05/2019',
            endDate: '08/05/2019',
            continuity: 'Intermittent',
            lastChangeDate: '06/18/2019',
            changeAction: 'APPROVED',
            changeActionId: '1',
            requestActionId: '833652478',
            leavePlanName: 'Federal Family and Medical Leave Act (FMLA)',
            leavePlanType: 'FEDERAL',
            leavePlanDescription: 'Under <span class=\'scayt-misspell\' data-scayt_word=\'FMLA\' data-scaytid=\'1\'>FMLA</span>, eligible employees can take up to 12 weeks of unpaid leave in a 12-month period for the following reasons:\r\n<ul>\r\n\t<li>\r\n\t\tBecause you have a serious health condition that keeps you from doing your job\r\n\t</li>\r\n\t<li>\r\n\t\tCaring for a child, spouse, or parent with a serious health condition\r\n\t</li>\r\n\t<li>\r\n\t\tBirth of a child\r\n\t</li>\r\n\t<li>\r\n\t\tPlacement of a child for adoption or foster care\r\n\t</li>\r\n\t<li>\r\n\t\tTo deal with certain arrangements when your spouse, son, daughter, or parent is serving in the military and deployed to a foreign country\r\n\t</li>\r\n</ul>\r\nA &ldquo;child&rdquo; includes a biological child, adopted child, or foster child, as well as a child you&rsquo;re acting in a parent-like role for. You can take <span class=\'scayt-misspell\' data-scayt_word=\'FMLA\' data-scaytid=\'5\'>FMLA</span> to care for a child with a serious health condition as long as they&rsquo;re younger than 18 years old. You may be able to take <span class=\'scayt-misspell\' data-scayt_word=\'FMLA\' data-scaytid=\'9\'>FMLA</span> to care for a child 18 or older, if they have a disability that makes them unable to care for themselves.<br />\r\n<br />\r\nIf you&#39;re caring for a covered service member with a serious injury or illness and you&rsquo;re that person&#39;s spouse, son, daughter, parent or next of kin, you can take up to 26 weeks of unpaid leave in a 12-month period.<br />\r\n<br />\r\nYou should tell us and your employer that you&#39;ll be out of work 30 days in advance. If you don&#39;t know that far in advance, let both of us know as soon as possible.',
            denialReason: ''
          }, {
            planId: '5',
            planName: 'STATE',
            startDate: '08/05/2019',
            endDate: '08/05/2019',
            continuity: 'Intermittent',
            lastChangeDate: '06/18/2019',
            changeAction: 'APPROVED',
            changeActionId: '1',
            requestActionId: '833652479',
            leavePlanName: 'California Family Rights Act (CFRA)',
            leavePlanType: 'STATE',
            leavePlanDescription: 'Under the California Family Rights Act (<span class=\'scayt-misspell\' data-scayt_word=\'CFRA\' data-scaytid=\'1\'>CFRA</span>), an employer who employs more than 50 employees within 75 miles of the work site where the employee is employed, cannot refuse to grant this leave to an employee who has more than 12 months of service with the employer, and who has at least 1,250 hours of service during the previous 12 month period, to take up to a total of 12 work weeks of unpaid leave in a 12 month period for Family care and medical leave. &quot;Family care and medical leave&quot; means the following:\r\n<ol type=\'a\'>\r\n <li>\r\n  Leave for reason of the birth, adoption, or foster care placement of the employee&#39;s child, or for the serious health condition of a child of the employee.</li>\r\n <li>\r\n  Leave to care for a parent, a spouse or registered domestic partner who has a serious health condition.</li>\r\n <li>\r\n  Leave because of an employee&#39;s own serious health condition (excluding leave taken for disability caused by pregnancy, childbirth or related medical conditions). This <span class=\'scayt-misspell\' data-scayt_word=\'CFRA\' data-scaytid=\'3\'>CFRA</span> leave may run consecutive to leave afforded under the California Pregnancy Disability Leave (<span class=\'scayt-misspell\' data-scayt_word=\'PDL\' data-scaytid=\'5\'>PDL</span>) and concurrent with other applicable Federal <span class=\'scayt-misspell\' data-scayt_word=\'FMLA\' data-scaytid=\'7\'>FMLA</span> leave. Your job restoration rights and your rights to maintenance of health benefits under this state law are similar to Federal <span class=\'scayt-misspell\' data-scayt_word=\'FMLA\' data-scaytid=\'9\'>FMLA</span>.</li>\r\n</ol>\r\n',
            denialReason: ''
          }, {
            planId: '256',
            planName: 'COMPANY',
            startDate: '08/05/2019',
            endDate: '08/05/2019',
            continuity: 'Intermittent',
            lastChangeDate: '06/18/2019',
            changeAction: 'APPROVED',
            changeActionId: '1',
            requestActionId: '833652480',
            leavePlanName: 'Takeda Company FMLA',
            leavePlanType: 'COMPANY',
            leavePlanDescription: 'Except as explained below, you have a right under the FMLA for up to 12 weeks of unpaid leave in a 12-month period for the care of a spouse, child, or parent, domestic partner, or for your own serious illness. Your health benefits will be maintained during any period of approved unpaid leave under the same conditions as if you continued to work, and are subject to your continued employee contributions. You may be reinstated to the same or an equivalent job with the same pay, benefits, and terms and conditions of employment on your return from leave.',
            denialReason: ''
          }]
        }, {
          requestId: '1339078',
          status: 'APPROVED',
          statusId: '1',
          requestStartDate: '04/25/2019',
          denialReason: '',
          leavePlan: [{
            planId: '1',
            planName: 'FEDERAL',
            startDate: '08/04/2019',
            endDate: '08/04/2019',
            continuity: 'Intermittent',
            lastChangeDate: '06/18/2019',
            changeAction: 'APPROVED',
            changeActionId: '1',
            requestActionId: '833652487',
            leavePlanName: 'Federal Family and Medical Leave Act (FMLA)',
            leavePlanType: 'FEDERAL',
            leavePlanDescription: 'Under <span class=\'scayt-misspell\' data-scayt_word=\'FMLA\' data-scaytid=\'1\'>FMLA</span>, eligible employees can take up to 12 weeks of unpaid leave in a 12-month period for the following reasons:\r\n<ul>\r\n\t<li>\r\n\t\tBecause you have a serious health condition that keeps you from doing your job\r\n\t</li>\r\n\t<li>\r\n\t\tCaring for a child, spouse, or parent with a serious health condition\r\n\t</li>\r\n\t<li>\r\n\t\tBirth of a child\r\n\t</li>\r\n\t<li>\r\n\t\tPlacement of a child for adoption or foster care\r\n\t</li>\r\n\t<li>\r\n\t\tTo deal with certain arrangements when your spouse, son, daughter, or parent is serving in the military and deployed to a foreign country\r\n\t</li>\r\n</ul>\r\nA &ldquo;child&rdquo; includes a biological child, adopted child, or foster child, as well as a child you&rsquo;re acting in a parent-like role for. You can take <span class=\'scayt-misspell\' data-scayt_word=\'FMLA\' data-scaytid=\'5\'>FMLA</span> to care for a child with a serious health condition as long as they&rsquo;re younger than 18 years old. You may be able to take <span class=\'scayt-misspell\' data-scayt_word=\'FMLA\' data-scaytid=\'9\'>FMLA</span> to care for a child 18 or older, if they have a disability that makes them unable to care for themselves.<br />\r\n<br />\r\nIf you&#39;re caring for a covered service member with a serious injury or illness and you&rsquo;re that person&#39;s spouse, son, daughter, parent or next of kin, you can take up to 26 weeks of unpaid leave in a 12-month period.<br />\r\n<br />\r\nYou should tell us and your employer that you&#39;ll be out of work 30 days in advance. If you don&#39;t know that far in advance, let both of us know as soon as possible.',
            denialReason: ''
          }, {
            planId: '5',
            planName: 'STATE',
            startDate: '08/04/2019',
            endDate: '08/04/2019',
            continuity: 'Intermittent',
            lastChangeDate: '06/18/2019',
            changeAction: 'APPROVED',
            changeActionId: '1',
            requestActionId: '833652488',
            leavePlanName: 'California Family Rights Act (CFRA)',
            leavePlanType: 'STATE',
            leavePlanDescription: 'Under the California Family Rights Act (<span class=\'scayt-misspell\' data-scayt_word=\'CFRA\' data-scaytid=\'1\'>CFRA</span>), an employer who employs more than 50 employees within 75 miles of the work site where the employee is employed, cannot refuse to grant this leave to an employee who has more than 12 months of service with the employer, and who has at least 1,250 hours of service during the previous 12 month period, to take up to a total of 12 work weeks of unpaid leave in a 12 month period for Family care and medical leave. &quot;Family care and medical leave&quot; means the following:\r\n<ol type=\'a\'>\r\n <li>\r\n  Leave for reason of the birth, adoption, or foster care placement of the employee&#39;s child, or for the serious health condition of a child of the employee.</li>\r\n <li>\r\n  Leave to care for a parent, a spouse or registered domestic partner who has a serious health condition.</li>\r\n <li>\r\n  Leave because of an employee&#39;s own serious health condition (excluding leave taken for disability caused by pregnancy, childbirth or related medical conditions). This <span class=\'scayt-misspell\' data-scayt_word=\'CFRA\' data-scaytid=\'3\'>CFRA</span> leave may run consecutive to leave afforded under the California Pregnancy Disability Leave (<span class=\'scayt-misspell\' data-scayt_word=\'PDL\' data-scaytid=\'5\'>PDL</span>) and concurrent with other applicable Federal <span class=\'scayt-misspell\' data-scayt_word=\'FMLA\' data-scaytid=\'7\'>FMLA</span> leave. Your job restoration rights and your rights to maintenance of health benefits under this state law are similar to Federal <span class=\'scayt-misspell\' data-scayt_word=\'FMLA\' data-scaytid=\'9\'>FMLA</span>.</li>\r\n</ol>\r\n',
            denialReason: ''
          }, {
            planId: '256',
            planName: 'COMPANY',
            startDate: '08/04/2019',
            endDate: '08/04/2019',
            continuity: 'Intermittent',
            lastChangeDate: '06/18/2019',
            changeAction: 'APPROVED',
            changeActionId: '1',
            requestActionId: '833652489',
            leavePlanName: 'Takeda Company FMLA',
            leavePlanType: 'COMPANY',
            leavePlanDescription: 'Except as explained below, you have a right under the FMLA for up to 12 weeks of unpaid leave in a 12-month period for the care of a spouse, child, or parent, domestic partner, or for your own serious illness. Your health benefits will be maintained during any period of approved unpaid leave under the same conditions as if you continued to work, and are subject to your continued employee contributions. You may be reinstated to the same or an equivalent job with the same pay, benefits, and terms and conditions of employment on your return from leave.',
            denialReason: ''
          }]
        }, {
          requestId: '1339079',
          status: 'APPROVED',
          statusId: '1',
          requestStartDate: '04/19/2019',
          denialReason: '',
          leavePlan: [{
            planId: '1',
            planName: 'FEDERAL',
            startDate: '08/03/2019',
            endDate: '08/03/2019',
            continuity: 'Intermittent',
            lastChangeDate: '06/18/2019',
            changeAction: 'APPROVED',
            changeActionId: '1',
            requestActionId: '833652496',
            leavePlanName: 'Federal Family and Medical Leave Act (FMLA)',
            leavePlanType: 'FEDERAL',
            leavePlanDescription: 'Under <span class=\'scayt-misspell\' data-scayt_word=\'FMLA\' data-scaytid=\'1\'>FMLA</span>, eligible employees can take up to 12 weeks of unpaid leave in a 12-month period for the following reasons:\r\n<ul>\r\n\t<li>\r\n\t\tBecause you have a serious health condition that keeps you from doing your job\r\n\t</li>\r\n\t<li>\r\n\t\tCaring for a child, spouse, or parent with a serious health condition\r\n\t</li>\r\n\t<li>\r\n\t\tBirth of a child\r\n\t</li>\r\n\t<li>\r\n\t\tPlacement of a child for adoption or foster care\r\n\t</li>\r\n\t<li>\r\n\t\tTo deal with certain arrangements when your spouse, son, daughter, or parent is serving in the military and deployed to a foreign country\r\n\t</li>\r\n</ul>\r\nA &ldquo;child&rdquo; includes a biological child, adopted child, or foster child, as well as a child you&rsquo;re acting in a parent-like role for. You can take <span class=\'scayt-misspell\' data-scayt_word=\'FMLA\' data-scaytid=\'5\'>FMLA</span> to care for a child with a serious health condition as long as they&rsquo;re younger than 18 years old. You may be able to take <span class=\'scayt-misspell\' data-scayt_word=\'FMLA\' data-scaytid=\'9\'>FMLA</span> to care for a child 18 or older, if they have a disability that makes them unable to care for themselves.<br />\r\n<br />\r\nIf you&#39;re caring for a covered service member with a serious injury or illness and you&rsquo;re that person&#39;s spouse, son, daughter, parent or next of kin, you can take up to 26 weeks of unpaid leave in a 12-month period.<br />\r\n<br />\r\nYou should tell us and your employer that you&#39;ll be out of work 30 days in advance. If you don&#39;t know that far in advance, let both of us know as soon as possible.',
            denialReason: ''
          }, {
            planId: '5',
            planName: 'STATE',
            startDate: '08/03/2019',
            endDate: '08/03/2019',
            continuity: 'Intermittent',
            lastChangeDate: '06/18/2019',
            changeAction: 'APPROVED',
            changeActionId: '1',
            requestActionId: '833652497',
            leavePlanName: 'California Family Rights Act (CFRA)',
            leavePlanType: 'STATE',
            leavePlanDescription: 'Under the California Family Rights Act (<span class=\'scayt-misspell\' data-scayt_word=\'CFRA\' data-scaytid=\'1\'>CFRA</span>), an employer who employs more than 50 employees within 75 miles of the work site where the employee is employed, cannot refuse to grant this leave to an employee who has more than 12 months of service with the employer, and who has at least 1,250 hours of service during the previous 12 month period, to take up to a total of 12 work weeks of unpaid leave in a 12 month period for Family care and medical leave. &quot;Family care and medical leave&quot; means the following:\r\n<ol type=\'a\'>\r\n <li>\r\n  Leave for reason of the birth, adoption, or foster care placement of the employee&#39;s child, or for the serious health condition of a child of the employee.</li>\r\n <li>\r\n  Leave to care for a parent, a spouse or registered domestic partner who has a serious health condition.</li>\r\n <li>\r\n  Leave because of an employee&#39;s own serious health condition (excluding leave taken for disability caused by pregnancy, childbirth or related medical conditions). This <span class=\'scayt-misspell\' data-scayt_word=\'CFRA\' data-scaytid=\'3\'>CFRA</span> leave may run consecutive to leave afforded under the California Pregnancy Disability Leave (<span class=\'scayt-misspell\' data-scayt_word=\'PDL\' data-scaytid=\'5\'>PDL</span>) and concurrent with other applicable Federal <span class=\'scayt-misspell\' data-scayt_word=\'FMLA\' data-scaytid=\'7\'>FMLA</span> leave. Your job restoration rights and your rights to maintenance of health benefits under this state law are similar to Federal <span class=\'scayt-misspell\' data-scayt_word=\'FMLA\' data-scaytid=\'9\'>FMLA</span>.</li>\r\n</ol>\r\n',
            denialReason: ''
          }, {
            planId: '256',
            planName: 'COMPANY',
            startDate: '08/03/2019',
            endDate: '08/03/2019',
            continuity: 'Intermittent',
            lastChangeDate: '06/18/2019',
            changeAction: 'APPROVED',
            changeActionId: '1',
            requestActionId: '833652498',
            leavePlanName: 'Takeda Company FMLA',
            leavePlanType: 'COMPANY',
            leavePlanDescription: 'Except as explained below, you have a right under the FMLA for up to 12 weeks of unpaid leave in a 12-month period for the care of a spouse, child, or parent, domestic partner, or for your own serious illness. Your health benefits will be maintained during any period of approved unpaid leave under the same conditions as if you continued to work, and are subject to your continued employee contributions. You may be reinstated to the same or an equivalent job with the same pay, benefits, and terms and conditions of employment on your return from leave.',
            denialReason: ''
          }]
        }, {
          requestId: '1340913',
          status: 'PENDED',
          statusId: '3',
          requestStartDate: '07/10/2019',
          denialReason: '',
          leavePlan: [{
            planId: '1',
            planName: 'FEDERAL',
            startDate: '08/02/2019',
            endDate: '08/02/2019',
            continuity: 'Intermittent',
            lastChangeDate: '07/16/2019',
            changeAction: 'PENDED',
            changeActionId: '3',
            requestActionId: '837902178',
            leavePlanName: 'Federal Family and Medical Leave Act (FMLA)',
            leavePlanType: 'FEDERAL',
            leavePlanDescription: 'Under <span class=\'scayt-misspell\' data-scayt_word=\'FMLA\' data-scaytid=\'1\'>FMLA</span>, eligible employees can take up to 12 weeks of unpaid leave in a 12-month period for the following reasons:\r\n<ul>\r\n\t<li>\r\n\t\tBecause you have a serious health condition that keeps you from doing your job\r\n\t</li>\r\n\t<li>\r\n\t\tCaring for a child, spouse, or parent with a serious health condition\r\n\t</li>\r\n\t<li>\r\n\t\tBirth of a child\r\n\t</li>\r\n\t<li>\r\n\t\tPlacement of a child for adoption or foster care\r\n\t</li>\r\n\t<li>\r\n\t\tTo deal with certain arrangements when your spouse, son, daughter, or parent is serving in the military and deployed to a foreign country\r\n\t</li>\r\n</ul>\r\nA &ldquo;child&rdquo; includes a biological child, adopted child, or foster child, as well as a child you&rsquo;re acting in a parent-like role for. You can take <span class=\'scayt-misspell\' data-scayt_word=\'FMLA\' data-scaytid=\'5\'>FMLA</span> to care for a child with a serious health condition as long as they&rsquo;re younger than 18 years old. You may be able to take <span class=\'scayt-misspell\' data-scayt_word=\'FMLA\' data-scaytid=\'9\'>FMLA</span> to care for a child 18 or older, if they have a disability that makes them unable to care for themselves.<br />\r\n<br />\r\nIf you&#39;re caring for a covered service member with a serious injury or illness and you&rsquo;re that person&#39;s spouse, son, daughter, parent or next of kin, you can take up to 26 weeks of unpaid leave in a 12-month period.<br />\r\n<br />\r\nYou should tell us and your employer that you&#39;ll be out of work 30 days in advance. If you don&#39;t know that far in advance, let both of us know as soon as possible.',
            denialReason: ''
          }, {
            planId: '5',
            planName: 'STATE',
            startDate: '08/02/2019',
            endDate: '08/02/2019',
            continuity: 'Intermittent',
            lastChangeDate: '07/16/2019',
            changeAction: 'PENDED',
            changeActionId: '3',
            requestActionId: '837902181',
            leavePlanName: 'California Family Rights Act (CFRA)',
            leavePlanType: 'STATE',
            leavePlanDescription: 'Under the California Family Rights Act (<span class=\'scayt-misspell\' data-scayt_word=\'CFRA\' data-scaytid=\'1\'>CFRA</span>), an employer who employs more than 50 employees within 75 miles of the work site where the employee is employed, cannot refuse to grant this leave to an employee who has more than 12 months of service with the employer, and who has at least 1,250 hours of service during the previous 12 month period, to take up to a total of 12 work weeks of unpaid leave in a 12 month period for Family care and medical leave. &quot;Family care and medical leave&quot; means the following:\r\n<ol type=\'a\'>\r\n <li>\r\n  Leave for reason of the birth, adoption, or foster care placement of the employee&#39;s child, or for the serious health condition of a child of the employee.</li>\r\n <li>\r\n  Leave to care for a parent, a spouse or registered domestic partner who has a serious health condition.</li>\r\n <li>\r\n  Leave because of an employee&#39;s own serious health condition (excluding leave taken for disability caused by pregnancy, childbirth or related medical conditions). This <span class=\'scayt-misspell\' data-scayt_word=\'CFRA\' data-scaytid=\'3\'>CFRA</span> leave may run consecutive to leave afforded under the California Pregnancy Disability Leave (<span class=\'scayt-misspell\' data-scayt_word=\'PDL\' data-scaytid=\'5\'>PDL</span>) and concurrent with other applicable Federal <span class=\'scayt-misspell\' data-scayt_word=\'FMLA\' data-scaytid=\'7\'>FMLA</span> leave. Your job restoration rights and your rights to maintenance of health benefits under this state law are similar to Federal <span class=\'scayt-misspell\' data-scayt_word=\'FMLA\' data-scaytid=\'9\'>FMLA</span>.</li>\r\n</ol>\r\n',
            denialReason: ''
          }, {
            planId: '256',
            planName: 'COMPANY',
            startDate: '08/02/2019',
            endDate: '08/02/2019',
            continuity: 'Intermittent',
            lastChangeDate: '07/16/2019',
            changeAction: 'PENDED',
            changeActionId: '3',
            requestActionId: '837902184',
            leavePlanName: 'Takeda Company FMLA',
            leavePlanType: 'COMPANY',
            leavePlanDescription: 'Except as explained below, you have a right under the FMLA for up to 12 weeks of unpaid leave in a 12-month period for the care of a spouse, child, or parent, domestic partner, or for your own serious illness. Your health benefits will be maintained during any period of approved unpaid leave under the same conditions as if you continued to work, and are subject to your continued employee contributions. You may be reinstated to the same or an equivalent job with the same pay, benefits, and terms and conditions of employment on your return from leave.',
            denialReason: ''
          }]
        }]
      },
      //Standalone 10/05/2018
      {
        leaveId: '300001',
        leaveStartDate: '12/01/2018',
        leaveEndDate: '12/18/2018',
        leaveStatus: 'Open',
        leaveReason: `My Illness/Health Condition`,
        caseId: '456789',
        personId: '123456',
        reedLeaveId: null,
        leaveProcessDate: '09/02/2018',
        leaveType: 'Standalone',
        leaveSubType: 'Intermittent',
        leaveEditDate: '09/28/2018',
        insuredFirstName: 'Mary',
        insuredLastName: 'Thomas',
        leaveFirstName: null,
        leaveLastName: null,
        leavePlanChangedDetected: true,
        medicalCategory: 1,
        leaveRequestStartDate: '12/01/2018',
        certRequestList : [
          {
            certPeriodStartDate: '12/01/2018',
            certPeriodEndDate: '12/18/2018',
            status: 'Pend',
            continuity : 'reduced schedule'
          }
        ],
        leaveRequestList: [
          {
            type: '',
            continuity: 'reduced schedule',
            startTime: '12/01/2018',
            endTime: '12/18/2018',
            status: 'Pend'
          }
        ],
        absenceDetails: [
          {
            leavePlan: [
              {
                planId: '1',
                planName: 'FEDERAL',
                startDate: '12/01/2018',
                endDate: '12/05/2018',
                continuity: 'Intermittent',
                lastChangeDate: '09/23/2015',
                changeAction: 'APPROVED',
                changeActionId: '3',
                requestActionId: '41019622',
                leavePlanName: 'Federal Family and Medical Leave Act (FMLA)',
                leavePlanType: 'FEDERAL',
                leavePlanDescription: '',
                denialReason: ''
              },
              {
                planId: '5',
                planName: 'STATE',
                startDate: '09/23/2018',
                endDate: '09/23/2018',
                continuity: 'Intermittent',
                lastChangeDate: '09/23/2018',
                changeAction: 'Cancelled',
                changeActionId: '3',
                requestActionId: '41019623',
                leavePlanName: 'California Family Rights Act (CFRA)',
                leavePlanType: 'STATE',
                leavePlanDescription: '',
                denialReason: ''
              }
            ],
            requestId: '1023473',
            status: 'APPROVED',
            statusId: '3',
            requestStartDate: '12/01/2018',
            denialReason: ''
          }
        ]
      },
      {
        leaveId: '2222222',
        leaveStartDate: '05/05/2020',
        leaveEndDate: '06/24/2020',
        leaveStatus: 'Open',
        leaveReason: 'Pregnancy/childbirth ',
        caseId: '0516115',
        personId: '8683940',
        reedLeaveId: '0000',
        leaveProcessDate: '06/26/2020',
        leaveType: 'Standalone',
        leaveSubType: 'reducedschedule',
        leaveEstimatedEndDate: '',
        leaveEditDate: null,
        insuredFirstName: 'JANET',
        insuredLastName: 'ZAZZZCHARY',
        leaveFirstName: '',
        leaveLastName: '',
        confirmReturnToWork: '1',
        absenceDetails: [
          {
            requestId: '1608777',
            status: 'APPROVED',
            statusId: '1',
            requestStartDate: '05/05/2020',
            requestEndDate: '06/24/2020',
            denialReason: '',
            leavePlan: [
              {
                planId: '1',
                planName: 'FEDERAL',
                startDate: '05/05/2020',
                endDate: '06/24/2020',
                continuity: 'Continuous',
                lastChangeDate: '06/26/2020',
                changeAction: 'APPROVED',
                changeActionId: '1',
                requestActionId: '868217324',
                leavePlanName: 'Federal Family and Medical Leave Act (FMLA)',
                leavePlanType: 'FEDERAL',
                leavePlanDescription: 'Under <span class="scayt-misspell" data-scayt_word="FMLA" data-scaytid="1">FMLA</span>, eligible employees can take up to 12 weeks of unpaid leave in a 12-month period for the following reasons:\r\n<ul>\r\n\t<li>\r\n\t\tBecause you have a serious health condition that keeps you from doing your job\r\n\t</li>\r\n\t<li>\r\n\t\tCaring for a child, spouse, or parent with a serious health condition\r\n\t</li>\r\n\t<li>\r\n\t\tBirth of a child\r\n\t</li>\r\n\t<li>\r\n\t\tPlacement of a child for adoption or foster care\r\n\t</li>\r\n\t<li>\r\n\t\tTo deal with certain arrangements when your spouse, son, daughter, or parent is serving in the military and deployed to a foreign country\r\n\t</li>\r\n</ul>\r\nA “child” includes a biological child, adopted child, or foster child, as well as a child you’re acting in a parent-like role for. You can take <span class="scayt-misspell" data-scayt_word="FMLA" data-scaytid="5">FMLA</span> to care for a child with a serious health condition as long as they’re younger than 18 years old. You may be able to take <span class="scayt-misspell" data-scayt_word="FMLA" data-scaytid="9">FMLA</span> to care for a child 18 or older, if they have a disability that makes them unable to care for themselves.<br />\r\n<br />\r\nIf you\'re caring for a covered service member with a serious injury or illness and you’re that person\'s spouse, son, daughter, parent or next of kin, you can take up to 26 weeks of unpaid leave in a 12-month period.<br />\r\n<br />\r\nYou should tell us and your employer that you\'ll be out of work 30 days in advance. If you don\'t know that far in advance, let both of us know as soon as possible.',
                denialReason: ''
              }
            ],
            AccommodationDescription: '',
            AccommodationsubCategory: '',
            substatus: 'Accepted',
            substatusId: '1',
            AccommodationType: ''
          }
        ],
        certRequestList: [
          {
            certId: '810982',
            certPeriodStartDate: '05/05/2020',
            certPeriodEndDate: '06/16/2020',
            certStatus: 'Accepted'
          },
          {
            certId: '810942',
            certPeriodStartDate: '07/06/2020',
            certPeriodEndDate: '07/24/2020',
            certStatus: 'Accepted'
          }
        ],
        leaveISMedical: '0',
        leaveRequestStartDate: '05/05/2020',
        relationship: 'Employee'
      }, {
        leaveId: '1608776',
        leaveStartDate: '05/05/2020',
        leaveEndDate: '06/24/2020',
        leaveStatus: 'Open',
        leaveReason: 'Pregnancy/childbirth ',
        caseId: '0516115',
        personId: '8683940',
        reedLeaveId: '0000',
        leaveProcessDate: '06/26/2020',
        leaveType: 'Leave of Absence',
        leaveSubType: 'Continuous',
        leaveEstimatedEndDate: '',
        leaveEditDate: null,
        insuredFirstName: 'JANET',
        insuredLastName: 'ZAZZZCHARY',
        leaveFirstName: '',
        leaveLastName: '',
        absenceDetails: [
          {
            requestId: '1608777',
            status: 'APPROVED',
            statusId: '1',
            requestStartDate: '05/05/2020',
            requestEndDate: '06/24/2020',
            denialReason: '',
            leavePlan: [
              {
                planId: '1',
                planName: 'FEDERAL',
                startDate: '05/05/2020',
                endDate: '06/24/2020',
                continuity: 'Continuous',
                lastChangeDate: '06/26/2020',
                changeAction: 'APPROVED',
                changeActionId: '1',
                requestActionId: '868217324',
                leavePlanName: 'Federal Family and Medical Leave Act (FMLA)',
                leavePlanType: 'FEDERAL',
                leavePlanDescription: 'Under <span class="scayt-misspell" data-scayt_word="FMLA" data-scaytid="1">FMLA</span>, eligible employees can take up to 12 weeks of unpaid leave in a 12-month period for the following reasons:\r\n<ul>\r\n\t<li>\r\n\t\tBecause you have a serious health condition that keeps you from doing your job\r\n\t</li>\r\n\t<li>\r\n\t\tCaring for a child, spouse, or parent with a serious health condition\r\n\t</li>\r\n\t<li>\r\n\t\tBirth of a child\r\n\t</li>\r\n\t<li>\r\n\t\tPlacement of a child for adoption or foster care\r\n\t</li>\r\n\t<li>\r\n\t\tTo deal with certain arrangements when your spouse, son, daughter, or parent is serving in the military and deployed to a foreign country\r\n\t</li>\r\n</ul>\r\nA “child” includes a biological child, adopted child, or foster child, as well as a child you’re acting in a parent-like role for. You can take <span class="scayt-misspell" data-scayt_word="FMLA" data-scaytid="5">FMLA</span> to care for a child with a serious health condition as long as they’re younger than 18 years old. You may be able to take <span class="scayt-misspell" data-scayt_word="FMLA" data-scaytid="9">FMLA</span> to care for a child 18 or older, if they have a disability that makes them unable to care for themselves.<br />\r\n<br />\r\nIf you\'re caring for a covered service member with a serious injury or illness and you’re that person\'s spouse, son, daughter, parent or next of kin, you can take up to 26 weeks of unpaid leave in a 12-month period.<br />\r\n<br />\r\nYou should tell us and your employer that you\'ll be out of work 30 days in advance. If you don\'t know that far in advance, let both of us know as soon as possible.',
                denialReason: ''
              }
            ],
            AccommodationDescription: '',
            AccommodationsubCategory: '',
            substatus: 'Accepted',
            substatusId: '1',
            AccommodationType: ''
          }
        ],
        certRequestList: [
          {
            certId: '810982',
            certPeriodStartDate: '05/05/2020',
            certPeriodEndDate: '06/16/2020',
            certStatus: 'Accepted/STD'
          },
          {
            certId: '810942',
            certPeriodStartDate: '05/05/2020',
            certPeriodEndDate: '06/24/2020',
            certStatus: 'Not Yet Received'
          }
        ],
        leaveISMedical: '0',
        leaveRequestStartDate: '05/05/2020',
        relationship: 'Employee',
        confirmReturnToWork: '1'
      },
      {
        leaveId: '300121',
        leaveStartDate: '12/01/2018',
        leaveEndDate: '12/18/2018',
        leaveStatus: 'Open',
        leaveReason: `My Illness/Health Condition`,
        caseId: '456789',
        personId: '123456',
        reedLeaveId: null,
        leaveProcessDate: '09/02/2018',
        leaveType: 'Standalone',
        leaveSubType: 'Continuous',
        leaveEditDate: '09/28/2018',
        insuredFirstName: 'Mary',
        insuredLastName: 'Thomas',
        leaveFirstName: null,
        leaveLastName: null,
        leavePlanChangedDetected: true,
        medicalCategory: 1,
        leaveRequestStartDate: '12/01/2018',
        certRequestList : [
          {
            certPeriodStartDate: '12/01/2018',
            certPeriodEndDate: '12/18/2018',
            certStatus: 'Accepted',
            continuity : 'reduced schedule'
          },
          {
            certPeriodStartDate: '07/01/2020',
            certPeriodEndDate: '07/10/2020',
            certStatus: 'Accepted',
            continuity : 'reduced schedule'
          }
        ],
        leaveRequestList: [
          {
            type: '',
            continuity: 'reduced schedule',
            startTime: '12/01/2018',
            endTime: '12/18/2018',
            status: 'Pend'
          }
        ],
        absenceDetails: [
          {
            leavePlan: [
              {
                planId: '1',
                planName: 'FEDERAL',
                startDate: '12/01/2018',
                endDate: '12/05/2018',
                continuity: 'Intermittent',
                lastChangeDate: '09/23/2015',
                changeAction: 'APPROVED',
                changeActionId: '3',
                requestActionId: '41019622',
                leavePlanName: 'Federal Family and Medical Leave Act (FMLA)',
                leavePlanType: 'FEDERAL',
                leavePlanDescription: '',
                denialReason: ''
              },
              {
                planId: '5',
                planName: 'STATE',
                startDate: '09/23/2018',
                endDate: '09/23/2018',
                continuity: 'Intermittent',
                lastChangeDate: '09/23/2018',
                changeAction: 'Cancelled',
                changeActionId: '3',
                requestActionId: '41019623',
                leavePlanName: 'California Family Rights Act (CFRA)',
                leavePlanType: 'STATE',
                leavePlanDescription: '',
                denialReason: ''
              }
            ],
            requestId: '1023473',
            status: 'APPROVED',
            statusId: '3',
            requestStartDate: '12/01/2018',
            denialReason: ''
          }
        ]
      },
      {
        leaveId: '1233731',
        leaveStartDate: '12/01/2018',
        leaveEndDate: '12/18/2018',
        leaveStatus: 'Open',
        leaveReason: `Relocation (For Me or My Spouse)`,
        caseId: '456789',
        personId: '123456',
        reedLeaveId: null,
        leaveProcessDate: '09/02/2018',
        leaveType: 'Standalone',
        leaveSubType: 'Intermittent',
        leaveEditDate: '09/28/2018',
        insuredFirstName: 'Mary',
        insuredLastName: 'Thomas',
        leaveFirstName: null,
        leaveLastName: null,
        leavePlanChangedDetected: true,
        leaveRequestStartDate: '12/01/2018',
        leaveISMedical : 1,
        certRequestList : [
          {
            certPeriodStartDate: '12/01/2018',
            certPeriodEndDate: '12/18/2018',
            status: 'Pend',
            continuity : 'reduced schedule'
          }
        ],
        leaveRequestList: [
          {
            type: '',
            continuity: 'reduced schedule',
            startTime: '12/01/2018',
            endTime: '12/18/2018',
            status: 'Pend'
          }
        ],
        absenceDetails: [
          {
            leavePlan: [
              {
                planId: '1',
                planName: 'FEDERAL',
                startDate: '12/01/2018',
                endDate: '12/05/2018',
                continuity: 'Intermittent',
                lastChangeDate: '09/23/2015',
                changeAction: 'APPROVED',
                changeActionId: '3',
                requestActionId: '41019622',
                leavePlanName: 'Federal Family and Medical Leave Act (FMLA)',
                leavePlanType: 'FEDERAL',
                leavePlanDescription: '',
                denialReason: ''
              },
              {
                planId: '5',
                planName: 'STATE',
                startDate: '09/23/2018',
                endDate: '09/23/2018',
                continuity: 'Intermittent',
                lastChangeDate: '09/23/2018',
                changeAction: 'DENIED',
                changeActionId: '3',
                requestActionId: '41019623',
                leavePlanName: 'California Family Rights Act (CFRA)',
                leavePlanType: 'STATE',
                leavePlanDescription: '',
                denialReason: ''
              }
            ],
            requestId: '1023473',
            status: 'APPROVED',
            statusId: '3',
            requestStartDate: '12/01/2018',
            denialReason: ''
          }
        ]
      },
      //Concurrent to LIFE 11/27/2018
      {
        leaveId: '200003',
        leaveStartDate: '12/01/2018',
        leaveEndDate: null,
        leaveStatus: 'Open',
        leaveReason: ` Urgent Personal Matter Involving My or My Family’s Well-Being`,
        caseId: '456789',
        personId: '123456',
        reedLeaveId: null,
        leaveProcessDate: '09/02/2018',
        leaveType: 'Standalone',
        leaveSubType: 'Continuous',
        leaveEditDate: '09/28/2018',
        insuredFirstName: 'Mary',
        insuredLastName: 'Thomas',
        leaveFirstName: null,
        leaveLastName: null,
        leavePlanChangedDetected: true,
        certRequestList : [],
        leaveRequestStartDate: '12/07/2018',
        leaveRequestList: [
          {
            type: '',
            continuity: 'reduced schedule',
            startTime: '12/01/2018',
            endTime: null,
            status: 'inactive'
          }
        ],
        absenceDetails: [
          {
            leavePlan: [
              {
                planId: '1',
                planName: 'FEDERAL',
                startDate: '12/07/2018',
                endDate: '12/07/2018',
                continuity: 'Continuous',
                lastChangeDate: '09/23/2015',
                changeAction: 'PENDED',
                changeActionId: '3',
                requestActionId: '41019622',
                leavePlanName: 'Federal Family and Medical Leave Act (FMLA)',
                leavePlanType: 'FEDERAL',
                leavePlanDescription: '',
                denialReason: ''
              },
              {
                planId: '5',
                planName: 'STATE',
                startDate: '09/23/2018',
                endDate: '09/23/2018',
                continuity: 'Continuous',
                lastChangeDate: '09/23/2018',
                changeAction: 'Cancelled',
                changeActionId: '3',
                requestActionId: '41019623',
                leavePlanName: 'California Family Rights Act (CFRA)',
                leavePlanType: 'STATE',
                leavePlanDescription: '',
                denialReason: ''
              }
            ],
            requestId: '1023473',
            status: 'PENDED',
            statusId: '3',
            requestStartDate: '12/07/2018',
            denialReason: ''
          }
        ]
      },
      {
        leaveId: '200004',
        leaveStartDate: '12/01/2018',
        leaveEndDate: null,
        leaveStatus: 'Open',
        leaveReason: `Relocation (For Me or My Spouse)`,
        caseId: '456789',
        personId: '123456',
        reedLeaveId: null,
        leaveProcessDate: '09/02/2018',
        leaveType: 'Leave of Absence',
        leaveSubType: 'intermittent',
        leaveEditDate: '09/28/2018',
        insuredFirstName: 'Mary',
        insuredLastName: 'Thomas',
        leaveFirstName: null,
        leaveLastName: null,
        leaveISMedical: '1',
        leavePlanChangedDetected: true,
        leaveRequestStartDate: '12/15/2018',
        certRequestList : [ ],
        leaveRequestList: [
          {
            type: '',
            continuity: 'reduced schedule',
            startTime: '12/01/2018',
            endTime: '12/03/2018',
            status: 'denied'
          },
          {
            type: '',
            continuity: 'reduced schedule',
            startTime: '12/04/2018',
            endTime: '12/05/2018',
            status: 'cancelled'
          },
          {
            type: '',
            continuity: 'intermittent',
            startTime: '12/06/2018',
            endTime: '12/08/2018',
            status: 'approved'
          }
        ],
        absenceDetails: [
          {
            leavePlan: [
              {
                planId: '1',
                planName: 'FEDERAL',
                startDate: '12/15/2018',
                endDate: '12/15/2018',
                continuity: 'Continuous',
                lastChangeDate: '09/23/2015',
                changeAction: 'PENDED',
                changeActionId: '3',
                requestActionId: '41019622',
                leavePlanName: 'Federal Family and Medical Leave Act (FMLA)',
                leavePlanType: 'FEDERAL',
                leavePlanDescription: 'Federal Family and Medical Leave Act (FMLA) except as explained below, eligible employees have a right to leave under the FMLA for up to 12 weeks of unpaid leave in a 12-month period if:  they are unable to perform the functions of their job due to their own serious health condition; for the care of their spouse, son, daughter or parent with a serious health condition; or for birth and care of their newborn child; placement of a son or daughter with the employee for adoption or foster care; because of a qualifying exigency arising out of the employee\'s spouse, son, daughter or parent on active duty or call to order to active duty in support of a contingency operation; or 26 weeks of unpaid leave to care for a covered servicemember with a serious injury or illness, if the employee is the spouse, son, daughter, parent or next of kin of the servicemember.',
                denialReason: ''
              },
              {
                planId: '1',
                planName: 'FEDERAL',
                startDate: '12/15/2018',
                endDate: '12/15/2018',
                continuity: 'Continuous',
                lastChangeDate: '09/23/2015',
                changeAction: 'PENDED',
                changeActionId: '3',
                requestActionId: '41019622',
                leavePlanName: 'Federal Family and Medical Leave Act (FMLA)',
                leavePlanType: 'FEDERAL',
                leavePlanDescription: 'Federal Family and Medical Leave Act (FMLA) except as explained below, eligible employees have a right to leave under the FMLA for up to 12 weeks of unpaid leave in a 12-month period if:  they are unable to perform the functions of their job due to their own serious health condition; for the care of their spouse, son, daughter or parent with a serious health condition; or for birth and care of their newborn child; placement of a son or daughter with the employee for adoption or foster care; because of a qualifying exigency arising out of the employee\'s spouse, son, daughter or parent on active duty or call to order to active duty in support of a contingency operation; or 26 weeks of unpaid leave to care for a covered servicemember with a serious injury or illness, if the employee is the spouse, son, daughter, parent or next of kin of the servicemember.',
                denialReason: ''
              },
              {
                planId: '1',
                planName: 'FEDERAL',
                startDate: '12/15/2018',
                endDate: '12/15/2018',
                continuity: 'Continuous',
                lastChangeDate: '09/23/2015',
                changeAction: 'PENDED',
                changeActionId: '3',
                requestActionId: '41019622',
                leavePlanName: 'Federal Family and Medical Leave Act (FMLA)',
                leavePlanType: 'FEDERAL',
                leavePlanDescription: 'Federal Family and Medical Leave Act (FMLA) except as explained below, eligible employees have a right to leave under the FMLA for up to 12 weeks of unpaid leave in a 12-month period if:  they are unable to perform the functions of their job due to their own serious health condition; for the care of their spouse, son, daughter or parent with a serious health condition; or for birth and care of their newborn child; placement of a son or daughter with the employee for adoption or foster care; because of a qualifying exigency arising out of the employee\'s spouse, son, daughter or parent on active duty or call to order to active duty in support of a contingency operation; or 26 weeks of unpaid leave to care for a covered servicemember with a serious injury or illness, if the employee is the spouse, son, daughter, parent or next of kin of the servicemember.',
                denialReason: ''
              },
              {
                planId: '1',
                planName: 'FEDERAL',
                startDate: '12/15/2018',
                endDate: '12/15/2018',
                continuity: 'Continuous',
                lastChangeDate: '09/23/2015',
                changeAction: 'PENDED',
                changeActionId: '3',
                requestActionId: '41019622',
                leavePlanName: 'Federal Family and Medical Leave Act (FMLA)',
                leavePlanType: 'FEDERAL',
                leavePlanDescription: 'Federal Family and Medical Leave Act (FMLA) except as explained below, eligible employees have a right to leave under the FMLA for up to 12 weeks of unpaid leave in a 12-month period if:  they are unable to perform the functions of their job due to their own serious health condition; for the care of their spouse, son, daughter or parent with a serious health condition; or for birth and care of their newborn child; placement of a son or daughter with the employee for adoption or foster care; because of a qualifying exigency arising out of the employee\'s spouse, son, daughter or parent on active duty or call to order to active duty in support of a contingency operation; or 26 weeks of unpaid leave to care for a covered servicemember with a serious injury or illness, if the employee is the spouse, son, daughter, parent or next of kin of the servicemember.',
                denialReason: ''
              },
              {
                planId: '1',
                planName: 'FEDERAL',
                startDate: '12/15/2018',
                endDate: '12/15/2018',
                continuity: 'Continuous',
                lastChangeDate: '09/23/2015',
                changeAction: 'PENDED',
                changeActionId: '3',
                requestActionId: '41019622',
                leavePlanName: 'Federal Family and Medical Leave Act (FMLA)',
                leavePlanType: 'FEDERAL',
                leavePlanDescription: 'Federal Family and Medical Leave Act (FMLA) except as explained below, eligible employees have a right to leave under the FMLA for up to 12 weeks of unpaid leave in a 12-month period if:  they are unable to perform the functions of their job due to their own serious health condition; for the care of their spouse, son, daughter or parent with a serious health condition; or for birth and care of their newborn child; placement of a son or daughter with the employee for adoption or foster care; because of a qualifying exigency arising out of the employee\'s spouse, son, daughter or parent on active duty or call to order to active duty in support of a contingency operation; or 26 weeks of unpaid leave to care for a covered servicemember with a serious injury or illness, if the employee is the spouse, son, daughter, parent or next of kin of the servicemember.',
                denialReason: ''
              },
              {
                planId: '1',
                planName: 'FEDERAL',
                startDate: '12/15/2018',
                endDate: '12/15/2018',
                continuity: 'Continuous',
                lastChangeDate: '09/23/2015',
                changeAction: 'PENDED',
                changeActionId: '3',
                requestActionId: '41019622',
                leavePlanName: 'Federal Family and Medical Leave Act (FMLA)',
                leavePlanType: 'FEDERAL',
                leavePlanDescription: 'Federal Family and Medical Leave Act (FMLA) except as explained below, eligible employees have a right to leave under the FMLA for up to 12 weeks of unpaid leave in a 12-month period if:  they are unable to perform the functions of their job due to their own serious health condition; for the care of their spouse, son, daughter or parent with a serious health condition; or for birth and care of their newborn child; placement of a son or daughter with the employee for adoption or foster care; because of a qualifying exigency arising out of the employee\'s spouse, son, daughter or parent on active duty or call to order to active duty in support of a contingency operation; or 26 weeks of unpaid leave to care for a covered servicemember with a serious injury or illness, if the employee is the spouse, son, daughter, parent or next of kin of the servicemember.',
                denialReason: ''
              },
              {
                planId: '1',
                planName: 'FEDERAL',
                startDate: '12/15/2018',
                endDate: '12/15/2018',
                continuity: 'Continuous',
                lastChangeDate: '09/23/2015',
                changeAction: 'PENDED',
                changeActionId: '3',
                requestActionId: '41019622',
                leavePlanName: 'Federal Family and Medical Leave Act (FMLA)',
                leavePlanType: 'FEDERAL',
                leavePlanDescription: 'Federal Family and Medical Leave Act (FMLA) except as explained below, eligible employees have a right to leave under the FMLA for up to 12 weeks of unpaid leave in a 12-month period if:  they are unable to perform the functions of their job due to their own serious health condition; for the care of their spouse, son, daughter or parent with a serious health condition; or for birth and care of their newborn child; placement of a son or daughter with the employee for adoption or foster care; because of a qualifying exigency arising out of the employee\'s spouse, son, daughter or parent on active duty or call to order to active duty in support of a contingency operation; or 26 weeks of unpaid leave to care for a covered servicemember with a serious injury or illness, if the employee is the spouse, son, daughter, parent or next of kin of the servicemember.',
                denialReason: ''
              },
              {
                planId: '5',
                planName: 'STATE',
                startDate: '12/18/2018',
                endDate: '12/18/2018',
                continuity: 'Continuous',
                lastChangeDate: '09/23/2018',
                changeAction: 'DENIED',
                changeActionId: '3',
                requestActionId: '41019623',
                leavePlanName: 'California Family Rights Act (CFRA)',
                leavePlanType: 'STATE',
                leavePlanDescription: '',
                denialReason: ''
              },
              {
                planId: '5',
                planName: 'STATE',
                startDate: '12/18/2018',
                endDate: '12/18/2018',
                continuity: 'Continuous',
                lastChangeDate: '09/23/2018',
                changeAction: 'DENIED',
                changeActionId: '3',
                requestActionId: '41019623',
                leavePlanName: 'California Family Rights Act (CFRA)',
                leavePlanType: 'STATE',
                leavePlanDescription: '',
                denialReason: ''
              },
              {
                planId: '5',
                planName: 'STATE',
                startDate: '12/18/2018',
                endDate: '12/18/2018',
                continuity: 'Continuous',
                lastChangeDate: '09/23/2018',
                changeAction: 'DENIED',
                changeActionId: '3',
                requestActionId: '41019623',
                leavePlanName: 'California Family Rights Act (CFRA)',
                leavePlanType: 'STATE',
                leavePlanDescription: '',
                denialReason: ''
              },
              {
                planId: '5',
                planName: 'STATE',
                startDate: '12/18/2018',
                endDate: '12/18/2018',
                continuity: 'Continuous',
                lastChangeDate: '09/23/2018',
                changeAction: 'DENIED',
                changeActionId: '3',
                requestActionId: '41019623',
                leavePlanName: 'California Family Rights Act (CFRA)',
                leavePlanType: 'STATE',
                leavePlanDescription: '',
                denialReason: ''
              }

            ],
            requestId: '1023473',
            status: 'PENDED',
            statusId: '3',
            requestStartDate: '12/18/2018',
            denialReason: ''
          },
          {
            leavePlan: [
              {
                planId: '1',
                planName: 'FEDERAL',
                startDate: '12/16/2018',
                endDate: '12/16/2018',
                continuity: 'Continuous',
                lastChangeDate: '09/23/2015',
                changeAction: 'PENDED',
                changeActionId: '3',
                requestActionId: '41019622',
                leavePlanName: 'Federal Family and Medical Leave Act (FMLA)',
                leavePlanType: 'FEDERAL',
                leavePlanDescription: '',
                denialReason: ''
              },
              {
                planId: '5',
                planName: 'STATE',
                startDate: '12/18/2018',
                endDate: '12/18/2018',
                continuity: 'Continuous',
                lastChangeDate: '09/23/2018',
                changeAction: 'PENDED',
                changeActionId: '3',
                requestActionId: '41019623',
                leavePlanName: 'California Family Rights Act (CFRA)',
                leavePlanType: 'STATE',
                leavePlanDescription: '',
                denialReason: ''
              }
            ],
            requestId: '1023473',
            status: 'PENDED',
            statusId: '3',
            requestStartDate: '12/16/2018',
            denialReason: ''
          }
        ]
      },
      //Standalone 09/01/2018
      {
        leaveId: '100011',
        leaveStartDate: '12/01/2018',
        leaveEndDate: '12/06/2018',
        leaveStatus: 'open',
        leaveReason: `My Illness/Health Condition`,
        caseId: '456789',
        personId: '123456',
        reedLeaveId: null,
        leaveProcessDate: '09/02/2018',
        leaveType: 'Concurrent',
        leaveSubType: 'Reduced Schedule',
        leaveEditDate: '09/28/2018',
        insuredFirstName: 'James',
        insuredLastName: 'Junior',
        leaveFirstName: null,
        leaveLastName: null,
        leavePlanChangedDetected: false,
        leaveRequestStartDate: '12/04/2018',
        certRequestList : [],
        leaveRequestList: [
          {
            type: '',
            continuity: 'reduced schedule',
            startTime: '12/04/2018',
            endTime: '12/05/2018',
            status: 'Administrative Cancel'
          },
          {
            type: '',
            continuity: 'reduced schedule',
            startTime: '12/08/2018',
            endTime: null,
            status: 'Pend'
          }
        ],
        absenceDetails: [
          {
            leavePlan: [
              {
                planId: '1',
                planName: 'FEDERAL',
                startDate: '12/03/2018',
                endDate: '12/03/2018',
                continuity: 'Reduced Schedule',
                lastChangeDate: '09/23/2015',
                changeAction: 'CANCELLED',
                changeActionId: '3',
                requestActionId: '41019622',
                leavePlanName: 'Federal Family and Medical Leave Act (FMLA)',
                leavePlanType: 'FEDERAL',
                leavePlanDescription: '',
                denialReason: ''
              },
              {
                planId: '5',
                planName: 'STATE',
                startDate: '12/04/2018',
                endDate: '12/04/2018',
                continuity: 'Reduced Schedule',
                lastChangeDate: '09/23/2018',
                changeAction: 'APPROVED',
                changeActionId: '3',
                requestActionId: '41019623',
                leavePlanName: 'California Family Rights Act (CFRA)',
                leavePlanType: 'STATE',
                leavePlanDescription: '',
                denialReason: ''
              }
            ],
            requestId: '1023473',
            status: 'APPROVED',
            statusId: '3',
            requestStartDate: '12/04/2018',
            denialReason: ''
          },
          {
            leavePlan: [
              {
                planId: '1',
                planName: 'FEDERAL',
                startDate: '12/06/2018',
                endDate: '12/06/2018',
                continuity: 'Reduced Schedule',
                lastChangeDate: '09/23/2015',
                changeAction: 'CANCELLED',
                changeActionId: '3',
                requestActionId: '41019622',
                leavePlanName: 'Federal Family and Medical Leave Act (FMLA)',
                leavePlanType: 'FEDERAL',
                leavePlanDescription: '',
                denialReason: ''
              }
            ],
            requestId: '1023473',
            status: 'CANCELLED',
            statusId: '3',
            requestStartDate: '12/06/2018',
            denialReason: ''
          }
        ]
      },
//Standalone 09/01/2018
      {
        leaveId: '100081',
        leaveStartDate : '09/01/2018',
        leaveEndDate: '09/31/2018',
        leaveStatus: 'Open',
        leaveReason : `Day-Care Activity`,
        caseId : '200000',
        personId : '300000',
        reedLeaveId: null,
        leaveProcessDate : '09/02/2018',
        leaveType : 'Standalone',
        leaveSubType : 'Continuous',
        leaveEditDate : '09/28/2018',
        insuredFirstName : 'James',
        insuredLastName : 'Thomas',
        leaveFirstName : 'Mary',
        leaveLastName : 'Thomas',
        certRequestList : [],
        leaveRequestList: [],
        leaveRequestStartDate: '09/04/2018',
        leavePlanChangedDetected: true,
        absenceDetails: [
          {
            leavePlan: [
              {
                planId: '1',
                planName: 'FEDERAL',
                startDate: '09/03/2018',
                endDate: '09/03/2018',
                continuity: 'Continuous',
                lastChangeDate: '09/23/2015',
                changeAction: 'CANCELLED',
                changeActionId: '3',
                requestActionId: '41019622',
                leavePlanName: 'Federal Family and Medical Leave Act (FMLA)',
                leavePlanType: 'FEDERAL',
                leavePlanDescription: '',
                denialReason: ''
              },
              {
                planId: '5',
                planName: 'STATE',
                startDate: '09/04/2018',
                endDate: '09/04/2018',
                continuity: 'Continuous',
                lastChangeDate: '09/23/2018',
                changeAction: 'PENDED',
                changeActionId: '3',
                requestActionId: '41019623',
                leavePlanName: 'California Family Rights Act (CFRA)',
                leavePlanType: 'STATE',
                leavePlanDescription: '',
                denialReason: ''
              }
            ],
            requestId: '1023473',
            status: 'PENDED',
            statusId: '3',
            requestStartDate: '09/04/2018',
            denialReason: ''
          }
        ]
      },
      {
        leaveId: '100082',
        leaveStartDate : '09/01/2018',
        leaveEndDate: '09/31/2018',
        leaveStatus: 'Open',
        leaveReason : `Day-Care Activity`,
        caseId : '200000',
        personId : '300000',
        reedLeaveId: null,
        leaveProcessDate : '09/02/2018',
        leaveType : 'Standalone',
        leaveSubType : 'Continuous',
        leaveEditDate : '09/28/2018',
        insuredFirstName : 'James',
        insuredLastName : 'Thomas',
        leaveFirstName : 'Mary',
        leaveLastName : 'Thomas',
        certRequestList : [],
        leaveRequestList: [],
        leavePlanChangedDetected: true,
        leaveRequestStartDate: '09/11/2018',
        absenceDetails: [
          {
            leavePlan: [
              {
                planId: '1',
                planName: 'FEDERAL',
                startDate: '09/11/2018',
                endDate: '09/11/2018',
                continuity: 'Continuous',
                lastChangeDate: '09/23/2015',
                changeAction: 'PENDED',
                changeActionId: '3',
                requestActionId: '41019622',
                leavePlanName: 'Federal Family and Medical Leave Act (FMLA)',
                leavePlanType: 'FEDERAL',
                leavePlanDescription: '',
                denialReason: ''
              }
            ],
            requestId: '1023473',
            status: 'PENDED',
            statusId: '3',
            requestStartDate: '09/11/2018',
            denialReason: ''
          }
        ]
      },

      //standalone with cancellled status
      {
        leaveId: '100083',
        leaveStartDate : '09/01/2018',
        leaveEndDate: '09/31/2018',
        leaveStatus: 'Open',
        leaveReason : `Day-Care Activity`,
        caseId : '200000',
        personId : '300000',
        reedLeaveId: null,
        leaveProcessDate : '09/02/2018',
        leaveType : 'Standalone',
        leaveSubType : 'Continuous',
        leaveEditDate : '09/28/2018',
        insuredFirstName : 'James',
        insuredLastName : 'Thomas',
        leaveFirstName : 'Mary',
        leaveLastName : 'Thomas',
        certRequestList : [],
        leaveRequestList: [],
        leavePlanChangedDetected: true,
        leaveRequestStartDate: '09/08/2018',
        absenceDetails: [
          {
            leavePlan: [
              {
                planId: '1',
                planName: 'FEDERAL',
                startDate: '09/08/2018',
                endDate: '09/08/2018',
                continuity: 'Continuous',
                lastChangeDate: '09/23/2015',
                changeAction: 'APPROVED',
                changeActionId: '3',
                requestActionId: '41019622',
                leavePlanName: 'Federal Family and Medical Leave Act (FMLA)',
                leavePlanType: 'FEDERAL',
                leavePlanDescription: '',
                denialReason: ''
              },
              {
                planId: '5',
                planName: 'STATE',
                startDate: '09/09/2018',
                endDate: '09/14/2018',
                continuity: 'Continuous',
                lastChangeDate: '09/23/2018',
                changeAction: 'PENDED',
                changeActionId: '3',
                requestActionId: '41019623',
                leavePlanName: 'California Family Rights Act (CFRA)',
                leavePlanType: 'STATE',
                leavePlanDescription: '',
                denialReason: ''
              }
            ],
            requestId: '1023473',
            status: 'APPROVED',
            statusId: '3',
            requestStartDate: '09/08/2018',
            denialReason: ''
          }
        ]
      },
      //standalone with closed status
      {
        leaveId: '100084',
        leaveStartDate : '09/01/2018',
        leaveEndDate: '09/31/2018',
        leaveStatus: 'Open',
        leaveReason : `Day-Care Activity`,
        caseId : '200000',
        personId : '300000',
        reedLeaveId: null,
        leaveProcessDate : '09/02/2018',
        leaveType : 'Standalone',
        leaveSubType : 'Continuous',
        leaveEditDate : '09/28/2018',
        insuredFirstName : 'James',
        insuredLastName : 'Thomas',
        leaveFirstName : 'Mary',
        leaveLastName : 'Thomas',
        certRequestList : [],
        leaveRequestList: [],
        leavePlanChangedDetected: false,
        leaveRequestStartDate: '09/04/2018',
        absenceDetails: [
          {
            leavePlan: [
              {
                planId: '1',
                planName: 'FEDERAL',
                startDate: '09/04/2018',
                endDate: '09/04/2018',
                continuity: 'Continuous',
                lastChangeDate: '09/23/2015',
                changeAction: 'PENDED',
                changeActionId: '3',
                requestActionId: '41019622',
                leavePlanName: 'Federal Family and Medical Leave Act (FMLA)',
                leavePlanType: 'FEDERAL',
                leavePlanDescription: '',
                denialReason: ''
              }
            ],
            requestId: '1023473',
            status: 'PENDED',
            statusId: '3',
            requestStartDate: '09/04/2018',
            denialReason: ''
          }
        ]
      },
      {
        leaveId: '200009',
        leaveStartDate: '12/01/2018',
        leaveEndDate: null,
        leaveStatus: 'Open',
        leaveReason: ` Urgent Personal Matter Involving My or My Family’s Well-Being`,
        caseId: '456789',
        personId: '123456',
        reedLeaveId: null,
        leaveProcessDate: '09/02/2018',
        leaveType: 'Standalone',
        leaveSubType: 'Continuous',
        leaveEditDate: '09/28/2018',
        insuredFirstName: 'Mary',
        insuredLastName: 'Thomas',
        leaveFirstName: null,
        leaveLastName: null,
        leavePlanChangedDetected: true,
        certRequestList : [],
        leaveRequestStartDate: '12/04/2018',
        leaveRequestList: [
          {
            type: '',
            continuity: 'reduced schedule',
            startTime: '12/01/2018',
            endTime: null,
            status: 'inactive'
          }
        ],
        absenceDetails: [
          {
            leavePlan: [
              {
                planId: '1',
                planName: 'FEDERAL',
                startDate: '12/04/2018',
                endDate: '12/07/2018',
                continuity: 'Continuous',
                lastChangeDate: '09/23/2015',
                changeAction: 'DENIED',
                changeActionId: '3',
                requestActionId: '41019622',
                leavePlanName: 'Federal Family and Medical Leave Act (FMLA)',
                leavePlanType: 'FEDERAL',
                leavePlanDescription: '',
                denialReason: ''
              }
            ],
            requestId: '1023473',
            status: 'PENDED',
            statusId: '3',
            requestStartDate: '12/04/2018',
            denialReason: ''
          }
        ]
      },
      {
        leaveId: '200012',
        leaveStartDate: '12/01/2018',
        leaveEndDate: null,
        leaveStatus: 'Open',
        leaveReason: ` Urgent Personal Matter Involving My or My Family’s Well-Being`,
        caseId: '456789',
        personId: '123456',
        reedLeaveId: null,
        leaveProcessDate: '09/02/2018',
        leaveType: 'Standalone',
        leaveSubType: 'Continuous',
        leaveEditDate: '09/28/2018',
        insuredFirstName: 'Mary',
        insuredLastName: 'Thomas',
        leaveFirstName: null,
        leaveLastName: null,
        leavePlanChangedDetected: true,
        certRequestList : [],
        leaveRequestStartDate: '12/04/2018',
        leaveRequestList: [
          {
            type: '',
            continuity: 'reduced schedule',
            startTime: '12/01/2018',
            endTime: null,
            status: 'inactive'
          }
        ],
        absenceDetails: [
          {
            leavePlan: [
              {
                planId: '1',
                planName: 'FEDERAL',
                startDate: '12/04/2018',
                endDate: '12/07/2018',
                continuity: 'Continuous',
                lastChangeDate: '09/23/2015',
                changeAction: 'DENIED',
                changeActionId: '3',
                requestActionId: '41019622',
                leavePlanName: 'Federal Family and Medical Leave Act (FMLA)',
                leavePlanType: 'FEDERAL',
                leavePlanDescription: '',
                denialReason: ''
              }
            ],
            requestId: '1023473',
            status: 'PENDED',
            statusId: '3',
            requestStartDate: '12/04/2018',
            denialReason: ''
          }
        ]
      },
      {
        absenceDetails: [{
          AccommodationDescription: 'Workplace Modification',
          AccommodationType: '',
          AccommodationsubCategory: 'Change in job materials or chemicals ',
          denialReason: '',
          leavePlan: [{
            changeAction: 'APPROVED',
            changeActionId: '1',
            continuity: 'Continuous',
            denialReason: '',
            endDate: '04/16/2021',
            lastChangeDate: '05/12/2021',
            leavePlanDescription: 'Under the Reasonable Accommodation provisions of the ADA, eligibility for an accommodation will be considered if you have a substantially limiting impairment or record of such an impairment as defined by the ADA.',
            leavePlanName: 'ADA Accommodation',
            leavePlanType: 'FEDERAL',
            planId: '2506',
            planName: 'FEDERAL',
            requestActionId: '896093451',
          }],
          startDate: '03/26/2021',
          requestEndDate: '04/16/2021',
          requestId: '2305305',
          requestStartDate: '03/26/2021',
          status: 'APPROVED',
          statusId: '1',
          substatus: 'At Work',
          substatusId: '136',
        }],
        caseId: '0660696',
        certRequestList: [{
          certId: '1041495',
          certPeriodEndDate: '05/18/2021',
          certPeriodStartDate: '04/18/2021',
          certStatus: 'Accommodated',
          continuity: 'Continuous'
        },
          {
            certId: '1041496',
            certPeriodEndDate: '04/18/2021',
            certPeriodStartDate: '03/26/2021',
            certStatus: 'Cancelled',
            continuity: 'Continuous'
          },
          {
            certId: '1041497',
            certPeriodEndDate: '05/16/2021',
            certPeriodStartDate: '04/19/2021',
            certStatus: 'Not Accommodated',
            continuity: 'Intermittent'
          },
          {
            certId: '1041499',
            certPeriodEndDate: '06/16/2021',
            certPeriodStartDate: '05/17/2021',
            certStatus: 'Under Review',
            continuity: 'Reduced Schedule'
          }],
        confirmReturnToWork: null,
        insuredFirstName: 'TROY',
        insuredLastName: 'POSEY',
        leaveEditDate: null,
        leaveEndDate: '04/16/2021',
        leaveEstimatedEndDate: '',
        leaveFirstName: '',
        leaveISMedical: '0',
        leaveId: '2305304',
        leaveLastName: '',
        leaveProcessDate: '05/12/2021',
        leaveReason: 'Disability Accommodations',
        leaveRequestStartDate: '03/26/2021',
        leaveStartDate: '03/26/2021',
        leaveStatus: 'Open',
        leaveSubType: 'Continuous',
        leaveType: 'ADA Accommodation',
        missingInfo: [],
        personId: '11215761',
        reedLeaveId: '0000',
        relationship: 'Employee'
      }
    ]
  },
  claimHistory: {
    disabilityClaims: [
      // LIFE 10/25/2018 No conc leave100034
      {
        claimEventId: '100028',
        sourceSystemId: 'AA',
        rtwStatusCode : '42001',
        concurrentLeaveIDs: ['300033'],
        claimType: 'Premium Waiver - Life Voluntary',
        claimSubType: 'Premium Waiver - Life Voluntary',
        coverageType: 'VOL',
        coverageCategory: 'PW',
        disabilityDate: '09/15/2018',
        effectiveDate: '09/15/2018',
        authorizedEndDate: null,
        claimStatus: 'Denied',
        claimStatusCode: '03',
        claimOnlineStatus: null,
        claimOnlineStatusCode: null,
        claimOnlineSubStatus: null,
        claimOnlineSubStatusCode: null,
        extensionStatus: null,
        extensionStatusCode: null,
        extensionSubStatus: null,
        extensionSubStatusCode: null,
        claimOfficeNumber: null,
        claimOfficeId: '5',
        caseId: '764185',
        claimProcessDate: '09/15/2018',
        claimEffectiveDate: '09/15/2018',
        reason: 'N/A',
        physicianStatementDate: '09/15/2018',
        employerStatementDate: '09/15/2018',
        employeeStatementDate: '09/15/2018',
        estimatedSurgeryDate: null,
        estimatedDeliveryDate: null,
        lastDayWork: '09/15/2018',
        asmIndicator: 'S',
        confirmedLastDayWorkDate: null,
        confirmedSurgeryDate: null,
        confirmedDeliveryDate: null,
        encIndicator: false,
        encSurgeryIndicator: false,
        fundingMethod: 'FI',
        coveragePlanNumber: '2',
        taskOpen: false,
        missingInfo: null,
        claimProgress: null,
        extensionRequestDate: null,
        babyDeathIndicator: null,
        recentClaimIndicator: false
      },
      //WD SUPP 09/25/2018 conc leave 100002
      {
        claimEventId: '200043',
        sourceSystemId: 'AA',
        rtwStatusCode : '42002',
        concurrentLeaveIDs: ['100002'],
        claimType: 'Premium Waiver - Life Voluntary',
        claimSubType: 'Premium Waiver (Voluntary Life)',
        coverageType: 'VOL',
        coverageCategory: 'PW',
        disabilityDate: '09/25/2018',
        effectiveDate: '09/15/2018',
        authorizedEndDate: null,
        claimStatus: 'Denied',
        claimStatusCode: '03',
        claimOnlineStatus: null,
        claimOnlineStatusCode: null,
        claimOnlineSubStatus: null,
        claimOnlineSubStatusCode: null,
        extensionStatus: null,
        extensionStatusCode: null,
        extensionSubStatus: null,
        extensionSubStatusCode: null,
        claimOfficeNumber: null,
        claimOfficeId: '5',
        caseId: '764185',
        claimProcessDate: '09/15/2018',
        claimEffectiveDate: '09/15/2018',
        reason: 'N/A',
        physicianStatementDate: '12/29/2014',
        employerStatementDate: '12/29/2014',
        employeeStatementDate: '08/06/2015',
        estimatedSurgeryDate: null,
        estimatedDeliveryDate: null,
        lastDayWork: '03/11/2015',
        asmIndicator: 'S',
        confirmedLastDayWorkDate: null,
        confirmedSurgeryDate: null,
        confirmedDeliveryDate: null,
        encIndicator: false,
        encSurgeryIndicator: false,
        fundingMethod: 'FI',
        coveragePlanNumber: '2',
        taskOpen: false,
        missingInfo: null,
        claimProgress: null,
        extensionRequestDate: null,
        babyDeathIndicator: null,
        recentClaimIndicator: false
      },

      //LTD 09/15/2018 No conc leave
      /* {
         'claimEventId': '100035',
         'sourceSystemId':  'AA',
         'rtwStatusCode' : null,
         'concurrentLeaveIDs': [],
         'claimType': 'Long Term Disability',
         'claimSubType': 'Long Term Disability',
         'coverageType': 'N/A',
         'coverageCategory': 'LTD',
         'disabilityDate': '09/15/2018',
         'effectiveDate': null,
         'authorizedEndDate': null,
         'claimStatus': 'Denied',
         'claimStatusCode': '03',
         'claimOnlineStatus': null,
         'claimOnlineStatusCode': null,
         'claimOnlineSubStatus': null,
         'claimOnlineSubStatusCode': null,
         'extensionStatus': null,
         'extensionStatusCode': null,
         'extensionSubStatus': null,
         'extensionSubStatusCode': null,
         'claimOfficeNumber': null,
         'claimOfficeId': '733',
         'caseId': '471538',
         'claimProcessDate': '10/15/2018',
         'claimEffectiveDate': '10/15/2018',
         'reason': 'N/A',
         'physicianStatementDate': null,
         'employerStatementDate': null,
         'employeeStatementDate': null,
         'estimatedSurgeryDate': null,
         'estimatedDeliveryDate': null,
         'lastDayWork': null,
         'asmIndicator': 'S',
         'confirmedLastDayWorkDate': null,
         'confirmedSurgeryDate': null,
         'confirmedDeliveryDate': null,
         'encIndicator': false,
         'encSurgeryIndicator': false,
         'fundingMethod': 'N/A',
         'coveragePlanNumber': null,
         'taskOpen': false,
         'missingInfo': null,
         'claimProgress': null,
         'extensionRequestDate': null,
         'babyDeathIndicator': null,
         'recentClaimIndicator': true
       },
     */  //WD SUPP 09/14/2018 conc leave 100009
      {
        claimEventId: '100030',
        sourceSystemId: 'DCS',
        rtwStatusCode : null,
        concurrentLeaveIDs: ['100009'],
        claimType: 'Long Term Disability',
        claimSubType: 'Long Term Disability',
        coverageType: 'ABIL',
        coverageCategory: 'LTD',
        disabilityDate: '06/16/2016',
        effectiveDate: '12/16/2016',
        authorizedEndDate: null,
        claimStatus: 'Denied',
        claimStatusCode: '03',
        claimOnlineStatus: null,
        claimOnlineStatusCode: null,
        claimOnlineSubStatus: null,
        claimOnlineSubStatusCode: null,
        extensionStatus: null,
        extensionStatusCode: null,
        extensionSubStatus: null,
        extensionSubStatusCode: null,
        claimOfficeNumber: null,
        claimOfficeId: '16',
        caseId: '1095021',
        claimProcessDate: '10/11/2016',
        claimEffectiveDate: '10/11/2016',
        reason: 'LACK OF EMPLOYEE INFORMATION',
        physicianStatementDate: '09/01/2016',
        employerStatementDate: '09/01/2016',
        employeeStatementDate: null,
        estimatedSurgeryDate: null,
        estimatedDeliveryDate: null,
        lastDayWork: '06/09/2016',
        asmIndicator: 'S',
        confirmedLastDayWorkDate: null,
        confirmedSurgeryDate: null,
        confirmedDeliveryDate: null,
        encIndicator: false,
        encSurgeryIndicator: false,
        fundingMethod: 'FI',
        coveragePlanNumber: '1',
        taskOpen: false,
        missingInfo: null,
        claimProgress: null,
        extensionRequestDate: null,
        babyDeathIndicator: null,
        recentClaimIndicator: false
      },
      {
        claimEventId: '16724866',
        claimType: 'Short Term Disability',
        claimSubType: 'Short Term Disability',
        coverageType: 'NST',
        coverageCategory: 'WD',
        disabilityDate: '06/05/2020',
        effectiveDate: '06/19/2020',
        authorizedEndDate: null,
        claimStatus: 'Denied',
        claimStatusCode: '03',
        claimOnlineStatus: 'Claim Closed',
        claimOnlineStatusCode: '05',
        claimOnlineSubStatus: 'Missing Information',
        claimOnlineSubStatusCode: '09',
        extensionStatus: null,
        extensionStatusCode: null,
        extensionSubStatus: null,
        extensionSubStatusCode: null,
        claimOfficeNumber: null,
        claimOfficeId: '733',
        caseId: '471538',
        claimProcessDate: '01/21/2021',
        claimEffectiveDate: '01/21/2021',
        reason: 'DENIED - OTHER',
        physicianStatementDate: '03/29/2020',
        employerStatementDate: '03/29/2020',
        employeeStatementDate: '03/29/2020',
        estimatedSurgeryDate: null,
        estimatedDeliveryDate: null,
        lastDayWork: '06/05/2020',
        asmIndicator: 'A',
        confirmedLastDayWorkDate: '06/05/2020',
        confirmedSurgeryDate: null,
        confirmedDeliveryDate: null,
        encIndicator: false,
        encSurgeryIndicator: false,
        fundingMethod: 'FI',
        coveragePlanNumber: '1',
        taskOpen: false,
        missingInfo: [
          {
            mInfoCode: '01',
            mInfoDesc: 'Employee',
            requiredByDate: null,
            mInfoReqInfo: [
              {
                mInfoReqInfoCd: '26',
                mInfoReqInfoDesc: 'LTD Payment Option Form Fully Insured',
                requestDate: '06/05/2020',
                displayValue: 'Payment Option Form',
                chatInd: false,
                callInd: false,
                uploadInd: true,
                detailInd: false,
                formType: 'generic',
                listPhysicain: [
                  {
                    physicianName: null,
                    fromDataRange: null,
                    toDataRange: null
                  }
                ]
              }
            ],
            alertItemSource: null,
            alertId: null,
            alertTitle: null,
            gracePeriod: false
          },
          {
            mInfoCode: '03',
            mInfoDesc: 'Physician',
            requiredByDate: null,
            mInfoReqInfo: [
              {
                mInfoReqInfoCd: '30',
                mInfoReqInfoDesc: 'Doctor\'s Note',
                requestDate: '06/10/2020',
                displayValue: 'Doctor\'s Note',
                chatInd: false,
                callInd: false,
                uploadInd: true,
                detailInd: true,
                formType: 'generic',
                listPhysicain: [
                  {
                    physicianName: 'Test',
                    fromDataRange: null,
                    toDataRange: null
                  }
                ]
              }
            ],
            alertItemSource: null,
            alertId: null,
            alertTitle: null,
            gracePeriod: false
          }
        ],
        claimProgress: 'Claim Closed',
        extensionRequestDate: null,
        babyDeathIndicator: null,
        recentClaimIndicator: false,
        semiMonthlyPaymentIndicator: 'N',
        concurrentLeaveIDs: null,
        sourceSystemId: 'DCS',
        personId: null,
        relatedClaims: null,
        rtwStatusCode: null,
        oowReason: null,
        form: null,
        leaveFirstName: null,
        leaveLastName: null,
        leaveReason: null,
        relationship: null,
        insuredFirstName: null,
        insuredLastName: null
      },
      {
        claimEventId: '16724866',
        claimType: 'Short Term Disability',
        claimSubType: 'Short Term Disability',
        coverageType: 'NST',
        coverageCategory: 'WD',
        disabilityDate: '06/05/2020',
        effectiveDate: '06/19/2020',
        authorizedEndDate: null,
        claimStatus: 'Denied',
        claimStatusCode: '03',
        claimOnlineStatus: 'Claim Closed',
        claimOnlineStatusCode: '05',
        claimOnlineSubStatus: 'Missing Information',
        claimOnlineSubStatusCode: '09',
        extensionStatus: null,
        extensionStatusCode: null,
        extensionSubStatus: null,
        extensionSubStatusCode: null,
        claimOfficeNumber: null,
        claimOfficeId: '733',
        caseId: '471538',
        claimProcessDate: '01/21/2021',
        claimEffectiveDate: '01/21/2021',
        reason: 'DENIED - OTHER',
        physicianStatementDate: '03/29/2020',
        employerStatementDate: '03/29/2020',
        employeeStatementDate: '03/29/2020',
        estimatedSurgeryDate: null,
        estimatedDeliveryDate: null,
        lastDayWork: '06/05/2020',
        asmIndicator: 'A',
        confirmedLastDayWorkDate: '06/05/2020',
        confirmedSurgeryDate: null,
        confirmedDeliveryDate: null,
        encIndicator: false,
        encSurgeryIndicator: false,
        fundingMethod: 'FI',
        coveragePlanNumber: '1',
        taskOpen: false,
        missingInfo: [
          {
            mInfoCode: '01',
            mInfoDesc: 'Employee',
            requiredByDate: null,
            mInfoReqInfo: [
              {
                mInfoReqInfoCd: '26',
                mInfoReqInfoDesc: 'LTD Payment Option Form Fully Insured',
                requestDate: '06/05/2020',
                displayValue: 'Payment Option Form',
                chatInd: false,
                callInd: false,
                uploadInd: true,
                detailInd: false,
                formType: 'generic',
                listPhysicain: [
                  {
                    physicianName: null,
                    fromDataRange: null,
                    toDataRange: null
                  }
                ]
              }
            ],
            alertItemSource: null,
            alertId: null,
            alertTitle: null,
            gracePeriod: false
          },
          {
            mInfoCode: '03',
            mInfoDesc: 'Physician',
            requiredByDate: null,
            mInfoReqInfo: [
              {
                mInfoReqInfoCd: '30',
                mInfoReqInfoDesc: 'Doctor\'s Note',
                requestDate: '06/10/2020',
                displayValue: 'Doctor\'s Note',
                chatInd: false,
                callInd: false,
                uploadInd: true,
                detailInd: true,
                formType: 'generic',
                listPhysicain: [
                  {
                    physicianName: 'Test',
                    fromDataRange: null,
                    toDataRange: null
                  }
                ]
              }
            ],
            alertItemSource: null,
            alertId: null,
            alertTitle: null,
            gracePeriod: false
          }
        ],
        claimProgress: 'Claim Closed',
        extensionRequestDate: null,
        babyDeathIndicator: null,
        recentClaimIndicator: false,
        semiMonthlyPaymentIndicator: 'N',
        concurrentLeaveIDs: null,
        sourceSystemId: 'DCS',
        personId: null,
        relatedClaims: null,
        rtwStatusCode: null,
        oowReason: null,
        form: null,
        leaveFirstName: null,
        leaveLastName: null,
        leaveReason: null,
        relationship: null,
        insuredFirstName: null,
        insuredLastName: null
      },
      //WD SUPP 09/13/2018 conc leave 100009
      {
        claimEventId: '100031',
        sourceSystemId: 'DCS',
        rtwStatusCode : null,
        concurrentLeaveIDs: ['300033'],
        claimType: 'Long Term Disability',
        claimSubType: 'Long Term Disability',
        coverageType: 'ABIL',
        coverageCategory: 'LTD',
        disabilityDate: '09/12/2018',
        effectiveDate: '12/16/2016',
        authorizedEndDate: null,
        claimStatus: 'Denied',
        claimStatusCode: '03',
        claimOnlineStatus: null,
        claimOnlineStatusCode: null,
        claimOnlineSubStatus: null,
        claimOnlineSubStatusCode: null,
        extensionStatus: null,
        extensionStatusCode: null,
        extensionSubStatus: null,
        extensionSubStatusCode: null,
        claimOfficeNumber: null,
        claimOfficeId: '16',
        caseId: '1095021',
        claimProcessDate: '10/11/2016',
        claimEffectiveDate: '10/11/2016',
        reason: 'LACK OF EMPLOYEE INFORMATION',
        physicianStatementDate: '09/01/2016',
        employerStatementDate: '09/01/2016',
        employeeStatementDate: null,
        estimatedSurgeryDate: null,
        estimatedDeliveryDate: null,
        lastDayWork: '06/09/2016',
        asmIndicator: 'S',
        confirmedLastDayWorkDate: null,
        confirmedSurgeryDate: null,
        confirmedDeliveryDate: null,
        encIndicator: false,
        encSurgeryIndicator: false,
        fundingMethod: 'FI',
        coveragePlanNumber: '1',
        taskOpen: false,
        missingInfo: null,
        claimProgress: null,
        extensionRequestDate: null,
        babyDeathIndicator: null,
        recentClaimIndicator: false
      },
      //WD SUPP 11/30/2018 no conc leave
      {
        claimEventId: '100024',
        sourceSystemId: 'AA',
        rtwStatusCode : null,
        concurrentLeaveIDs: ['100009'],
        claimType: 'Short Term Disability- Supplemental Statutory',
        claimSubType: 'Supplemental State Surgery',
        coverageType: 'SUPP',
        coverageCategory: 'WD',
        disabilityDate: '11/30/2018',
        effectiveDate: '11/30/2018',
        authorizedEndDate: '11/30/2018',
        claimStatus: null,
        claimStatusCode: '04',
        claimOnlineStatus: 'Claim Closed',
        claimOnlineStatusCode: '04',
        claimOnlineSubStatus: 'Claim Closed',
        claimOnlineSubStatusCode: '05',
        extensionStatus: null,
        extensionStatusCode: '1',
        extensionSubStatus: 'Missing Info',
        extensionSubStatusCode: null,
        claimOfficeNumber: null,
        claimOfficeId: '16',
        caseId: '978301',
        claimProcessDate: '10/10/2016',
        claimEffectiveDate: '05/27/2016',
        reason: 'RELEASED TO RETURN TO WORK',
        physicianStatementDate: '05/02/2016',
        employerStatementDate: '05/04/2016',
        employeeStatementDate: '04/25/2016',
        estimatedSurgeryDate: '04/27/2016',
        estimatedDeliveryDate: null,
        lastDayWork: '04/26/2016',
        asmIndicator: 'S',
        confirmedLastDayWorkDate: '04/26/2016',
        confirmedSurgeryDate: '04/27/2016',
        confirmedDeliveryDate: null,
        encIndicator: true,
        encSurgeryIndicator: false,
        fundingMethod: 'FI',
        coveragePlanNumber: '1',
        taskOpen: true,
        missingInfo: null,
        claimProgress: 'Claim Closed',
        extensionRequestDate: '01/01/2019',
        babyDeathIndicator: null,
        recentClaimIndicator: false
      },
      {
        claimEventId: '100025',
        sourceSystemId: 'AA',
        insuredFirstName: 'James',
        relationship: 'Son',
        leaveFirstName: 'Jerry',
        leaveLastName: 'Welch',
        leaveReason: 'Bond With New Child',
        rtwStatusCode : null,
        concurrentLeaveIDs: ['100009'],
        claimType: 'Paid Famil Leave',
        claimSubType: 'WA Paid Family Leave',
        coverageType: 'SUPP',
        coverageCategory: 'PFL',
        disabilityDate: '11/30/2018',
        effectiveDate: '11/30/2018',
        authorizedEndDate: '11/30/2018',
        claimStatus: null,
        claimStatusCode: '04',
        claimOnlineStatus: 'Claim Denied',
        claimOnlineStatusCode: '06',
        claimOnlineSubStatus: 'Claim Denied',
        claimOnlineSubStatusCode: '08',
        extensionStatus: null,
        extensionStatusCode: '1',
        extensionSubStatus: 'Missing Info',
        extensionSubStatusCode: null,
        claimOfficeNumber: null,
        claimOfficeId: '16',
        caseId: '978301',
        claimProcessDate: '10/10/2016',
        claimEffectiveDate: '05/27/2016',
        reason: 'RELEASED TO RETURN TO WORK',
        physicianStatementDate: '05/02/2016',
        employerStatementDate: '05/04/2016',
        employeeStatementDate: '04/25/2016',
        estimatedSurgeryDate: '04/27/2016',
        estimatedDeliveryDate: null,
        lastDayWork: '04/26/2016',
        asmIndicator: 'S',
        confirmedLastDayWorkDate: '04/26/2016',
        confirmedSurgeryDate: '04/27/2016',
        confirmedDeliveryDate: null,
        encIndicator: true,
        encSurgeryIndicator: false,
        fundingMethod: 'FI',
        coveragePlanNumber: '1',
        taskOpen: true,
        missingInfo: null,
        claimProgress: 'Claim Denied',
        extensionRequestDate: '01/01/2019',
        babyDeathIndicator: null,
        recentClaimIndicator: false,
        oowReason: 'RELEASED',
      },

      //STAT 09/15/2018  conc leave 300033
      {
        claimEventId: '100032',
        sourceSystemId: 'DCS',
        rtwStatusCode : null,
        concurrentLeaveIDs: ['100009'],
        claimType: 'Short Term Disability- Supplemental Statutory',
        claimSubType: 'Supplemental State Surgery',
        coverageType: 'SUPP',
        coverageCategory: 'WD',
        disabilityDate: '09/14/2018',
        effectiveDate: '09/14/2018',
        authorizedEndDate: '12/31/2018',
        claimStatus: 'Terminate',
        claimStatusCode: '04',
        claimOnlineStatus: 'Claim Closed',
        claimOnlineStatusCode: '04',
        claimOnlineSubStatus: 'Claim Closed',
        claimOnlineSubStatusCode: '05',
        extensionStatus: null,
        extensionStatusCode: 1,
        extensionSubStatus: 'Missing Info',
        extensionSubStatusCode: null,
        claimOfficeNumber: null,
        claimOfficeId: '16',
        caseId: '978301',
        claimProcessDate: '10/10/2016',
        claimEffectiveDate: '05/27/2016',
        reason: 'RELEASED TO RETURN TO WORK',
        physicianStatementDate: '05/02/2016',
        employerStatementDate: '05/04/2016',
        employeeStatementDate: '04/25/2016',
        estimatedSurgeryDate: '04/27/2016',
        estimatedDeliveryDate: null,
        lastDayWork: '04/26/2016',
        asmIndicator: 'S',
        confirmedLastDayWorkDate: '04/26/2016',
        confirmedSurgeryDate: '04/27/2016',
        confirmedDeliveryDate: null,
        encIndicator: true,
        encSurgeryIndicator: false,
        fundingMethod: 'FI',
        coveragePlanNumber: '1',
        taskOpen: true,
        missingInfo: null,
        claimProgress: 'Claim Closed',
        extensionRequestDate: '01/01/2019',
        babyDeathIndicator: null,
        recentClaimIndicator: false
      },
      {
        claimEventId: '2166096',
        claimType: 'Short Term Disability',
        claimSubType: 'Short Term Disability',
        coverageType: 'NST',
        coverageCategory: 'WD',
        disabilityDate: '02/02/2021',
        effectiveDate: null,
        authorizedEndDate: null,
        claimStatus: 'Closed',
        claimStatusCode: '0',
        claimOnlineStatus: 'Claim Denied',
        claimOnlineStatusCode: '06',
        claimOnlineSubStatus: 'Claim Denied',
        claimOnlineSubStatusCode: '10',
        extensionStatus: null,
        extensionStatusCode: null,
        extensionSubStatus: null,
        extensionSubStatusCode: null,
        claimOfficeNumber: null,
        claimOfficeId: null,
        caseId: '0516115',
        claimProcessDate: '11/08/2019',
        claimEffectiveDate: null,
        reason: 'Administrative',
        physicianStatementDate: '03/18/2021',
        employerStatementDate: null,
        employeeStatementDate: '03/18/2021',
        estimatedSurgeryDate: null,
        estimatedDeliveryDate: null,
        lastDayWork: '02/01/2021',
        asmIndicator: 'A',
        confirmedLastDayWorkDate: '02/01/2021',
        confirmedSurgeryDate: null,
        confirmedDeliveryDate: null,
        encIndicator: false,
        encSurgeryIndicator: false,
        fundingMethod: 'FI',
        coveragePlanNumber: null,
        taskOpen: false,
        missingInfo: [],
        claimProgress: 'Claim Denied',
        extensionRequestDate: null,
        babyDeathIndicator: null,
        recentClaimIndicator: false,
        semiMonthlyPaymentIndicator: null,
        concurrentLeaveIDs: ['2166098'],
        sourceSystemId: 'AA',
        personId: '11173357',
        relatedClaims: [{relatedClaimList: [{LinkedSTAT: '2166097',
            LinkedSTD: null,
            LinkedLTD: null,
            LinkedABL: null,
            LinkedPFL: null,
            LinkedCPFL: null,
            LinkedB: null,
            LinkedP: null,
            LinkedSTDB: null,
            LinkedPW: null}],
          hasRelatedClaims: true}],
        rtwStatusCode: '42004',
        oowReason: 'Employee\'s own illness',
        form: '02',
        leaveFirstName: '',
        leaveLastName: '',
        leaveReason: '',
        relationship: null,
        insuredFirstName: 'BLAKE',
        insuredLastName: 'AY'
      },
      {
        claimEventId: '2166097',
        claimType: 'Short Term Disability',
        claimSubType: 'State-Mandated Disability',
        coverageType: 'STAT',
        coverageCategory: 'WD',
        disabilityDate: '02/02/2021',
        effectiveDate: null,
        authorizedEndDate: null,
        claimStatus: 'Closed',
        claimStatusCode: '0',
        claimOnlineStatus: 'Claim Denied',
        claimOnlineStatusCode: '06',
        claimOnlineSubStatus: 'Claim Denied',
        claimOnlineSubStatusCode: '10',
        extensionStatus: null,
        extensionStatusCode: null,
        extensionSubStatus: null,
        extensionSubStatusCode: null,
        claimOfficeNumber: null,
        claimOfficeId: null,
        caseId: '0516115',
        claimProcessDate: '11/08/2019',
        claimEffectiveDate: null,
        reason: 'Administrative',
        physicianStatementDate: '03/18/2021',
        employerStatementDate: '03/03/2021',
        employeeStatementDate: '03/18/2021',
        estimatedSurgeryDate: null,
        estimatedDeliveryDate: null,
        lastDayWork: '02/01/2021',
        asmIndicator: 'A',
        confirmedLastDayWorkDate: '02/01/2021',
        confirmedSurgeryDate: null,
        confirmedDeliveryDate: null,
        encIndicator: false,
        encSurgeryIndicator: false,
        fundingMethod: 'FI',
        coveragePlanNumber: null,
        taskOpen: false,
        missingInfo: [],
        claimProgress: 'Claim Denied',
        extensionRequestDate: null,
        babyDeathIndicator: null,
        recentClaimIndicator: false,
        semiMonthlyPaymentIndicator: null,
        concurrentLeaveIDs: ['2166098'],
        sourceSystemId: 'AA',
        personId: '11173357',
        relatedClaims: [{relatedClaimList: [{LinkedSTAT: null,
            LinkedSTD: '2166096',
            LinkedLTD: null,
            LinkedABL: null,
            LinkedPFL: null,
            LinkedCPFL: null,
            LinkedB: null,
            LinkedP: null,
            LinkedSTDB: null,
            LinkedPW: null}],
          hasRelatedClaims: true}],
        rtwStatusCode: '42004',
        oowReason: 'Employee\'s own illness',
        form: '02',
        leaveFirstName: '',
        leaveLastName: '',
        leaveReason: '',
        relationship: null,
        insuredFirstName: 'BLAKE',
        insuredLastName: 'AY'
      },
      {
        claimEventId: '2166099',
        claimType: 'Short Term Disability',
        claimSubType: 'State-Mandated Disability',
        coverageType: 'STAT',
        coverageCategory: 'WD',
        disabilityDate: '02/02/2021',
        effectiveDate: null,
        authorizedEndDate: null,
        claimStatus: 'Closed',
        claimStatusCode: '0',
        claimOnlineStatus: 'Claim Denied',
        claimOnlineStatusCode: '06',
        claimOnlineSubStatus: 'Claim Denied',
        claimOnlineSubStatusCode: '10',
        extensionStatus: null,
        extensionStatusCode: null,
        extensionSubStatus: null,
        extensionSubStatusCode: null,
        claimOfficeNumber: null,
        claimOfficeId: null,
        caseId: '0516115',
        claimProcessDate: '11/08/2019',
        claimEffectiveDate: null,
        reason: 'Administrative',
        physicianStatementDate: '03/18/2021',
        employerStatementDate: '03/03/2021',
        employeeStatementDate: '03/18/2021',
        estimatedSurgeryDate: null,
        estimatedDeliveryDate: null,
        lastDayWork: '02/01/2021',
        asmIndicator: 'A',
        confirmedLastDayWorkDate: '02/01/2021',
        confirmedSurgeryDate: null,
        confirmedDeliveryDate: null,
        encIndicator: false,
        encSurgeryIndicator: false,
        fundingMethod: 'FI',
        coveragePlanNumber: null,
        taskOpen: false,
        missingInfo: [],
        claimProgress: 'Claim Denied',
        extensionRequestDate: null,
        babyDeathIndicator: null,
        recentClaimIndicator: false,
        semiMonthlyPaymentIndicator: null,
        concurrentLeaveIDs: ['2166098'],
        sourceSystemId: 'AA',
        personId: '11173357',
        relatedClaims: [{relatedClaimList: [{LinkedSTAT: '2166097',
            LinkedSTD: null,
            LinkedLTD: null,
            LinkedABL: null,
            LinkedPFL: null,
            LinkedCPFL: null,
            LinkedB: null,
            LinkedP: null,
            LinkedSTDB: null,
            LinkedPW: null}],
          hasRelatedClaims: true}],
        rtwStatusCode: '42004',
        oowReason: 'Employee\'s own illness',
        form: '02',
        leaveFirstName: '',
        leaveLastName: '',
        leaveReason: '',
        relationship: null,
        insuredFirstName: 'BLAKE',
        insuredLastName: 'AY'
      },
      {
        claimEventId: '2166090',
        claimType: 'Short Term Disability',
        claimSubType: 'State-Mandated Disability',
        coverageType: 'STAT',
        coverageCategory: 'WD',
        disabilityDate: '02/02/2021',
        effectiveDate: null,
        authorizedEndDate: null,
        claimStatus: 'Closed',
        claimStatusCode: '0',
        claimOnlineStatus: 'Claim Denied',
        claimOnlineStatusCode: '06',
        claimOnlineSubStatus: 'Claim Denied',
        claimOnlineSubStatusCode: '10',
        extensionStatus: null,
        extensionStatusCode: null,
        extensionSubStatus: null,
        extensionSubStatusCode: null,
        claimOfficeNumber: null,
        claimOfficeId: null,
        caseId: '0516115',
        claimProcessDate: '11/08/2019',
        claimEffectiveDate: null,
        reason: 'Administrative',
        physicianStatementDate: '03/18/2021',
        employerStatementDate: '03/03/2021',
        employeeStatementDate: '03/18/2021',
        estimatedSurgeryDate: null,
        estimatedDeliveryDate: null,
        lastDayWork: '02/01/2021',
        asmIndicator: 'A',
        confirmedLastDayWorkDate: '02/01/2021',
        confirmedSurgeryDate: null,
        confirmedDeliveryDate: null,
        encIndicator: false,
        encSurgeryIndicator: false,
        fundingMethod: 'FI',
        coveragePlanNumber: null,
        taskOpen: false,
        missingInfo: [],
        claimProgress: 'Claim Denied',
        extensionRequestDate: null,
        babyDeathIndicator: null,
        recentClaimIndicator: false,
        semiMonthlyPaymentIndicator: null,
        concurrentLeaveIDs: ['2166098'],
        sourceSystemId: 'AA',
        personId: '11173357',
        relatedClaims: [{relatedClaimList: [{LinkedSTAT: null,
            LinkedSTD: null,
            LinkedLTD: null,
            LinkedABL: null,
            LinkedPFL: null,
            LinkedCPFL: null,
            LinkedB: null,
            LinkedP: null,
            LinkedSTDB: null,
            LinkedPW: null}],
          hasRelatedClaims: true}],
        rtwStatusCode: '42004',
        oowReason: 'Employee\'s own illness',
        form: '02',
        leaveFirstName: '',
        leaveLastName: '',
        leaveReason: '',
        relationship: null,
        insuredFirstName: 'BLAKE',
        insuredLastName: 'AY'
      }

    ],
    leaveClaims: [
      {
        leaveId: '2166098',
        leaveStartDate: '02/02/2021',
        leaveEndDate: '02/08/2021',
        leaveStatus: 'Closed',
        leaveReason: 'My Illness/Health Condition',
        caseId: '0516115',
        personId: '11173357',
        reedLeaveId: '0000',
        leaveProcessDate: '02/02/2021',
        leaveType: 'Leave of Absence',
        leaveSubType: 'Continuous',
        leaveEstimatedEndDate: '',
        leaveEditDate: null,
        insuredFirstName: 'BLAKE',
        insuredLastName: 'AY',
        leaveFirstName: '',
        leaveLastName: '',
        absenceDetails: [
          {
            requestId: '2166099',
            status: 'APPROVED',
            statusId: '1',
            requestStartDate: '02/02/2021',
            requestEndDate: '02/08/2021',
            denialReason: '',
            leavePlan: [
              {
                planId: '1',
                planName: 'FEDERAL',
                startDate: '02/02/2021',
                endDate: '02/08/2021',
                continuity: 'Continuous',
                lastChangeDate: '06/08/2021',
                changeAction: 'APPROVED',
                changeActionId: '1',
                requestActionId: '891755117',
                leavePlanName: 'Federal Family and Medical Leave Act (FMLA)',
                leavePlanType: 'FEDERAL',
                leavePlanDescription: 'Under <span class=\'scayt-misspell\' data-scayt_word=\'FMLA\' data-scaytid=\'1\'>FMLA</span>, eligible employees can take up to 12 weeks of unpaid leave in a 12-month period for the following reasons: \r\n<ul>\r\n\t<li>\r\n\t\tBecause you have a serious health condition that keeps you from doing your job\r\n\t</li>\r\n\t<li>\r\n\t\tCaring for a child, spouse, or parent with a serious health condition\r\n\t</li>\r\n\t<li>\r\n\t\tBirth of a child\r\n\t</li>\r\n\t<li>\r\n\t\tPlacement of a child for adoption or foster care\r\n\t</li>\r\n\t<li>\r\n\t\tTo deal with certain arrangements when your spouse, son, daughter, or parent is serving in the military and deployed to a foreign country\r\n\t</li>\r\n</ul>\r\nA &ldquo;child&rdquo; includes a biological child, adopted child, or foster child, as well as a child you&rsquo;re acting in a parent-like role for. You can take <span class=\'scayt-misspell\' data-scayt_word=\'FMLA\' data-scaytid=\'5\'>FMLA</span> to care for a child with a serious health condition as long as they&rsquo;re younger than 18 years old. You may be able to take <span class=\'scayt-misspell\' data-scayt_word=\'FMLA\' data-scaytid=\'9\'>FMLA</span> to care for a child 18 or older, if they have a disability that makes them unable to care for themselves.<br />\r\n<br />\r\nIf you&#39;re caring for a covered service member with a serious injury or illness and you&rsquo;re that person&#39;s spouse, son, daughter, parent or next of kin, you can take up to 26 weeks of unpaid leave in a 12-month period.<br />\r\n<br />\r\nYou should tell us and your employer that you&#39;ll be out of work 30 days in advance. If you don&#39;t know that far in advance, let both of us know as soon as possible.', denialReason: ''
              },
              {
                planId: '19964',
                planName: 'COMPANY',
                startDate: '02/02/2021',
                endDate: '02/08/2021',
                continuity: 'Continuous',
                lastChangeDate: '06/08/2021',
                changeAction: 'APPROVED',
                changeActionId: '1',
                requestActionId: '891755121',
                leavePlanName: 'Northern Trust Company Test Medical Leave',
                leavePlanType: 'COMPANY',
                leavePlanDescription: 'Test',
                denialReason: ''
              },
              {
                planId: '19966',
                planName: 'COMPANY',
                startDate: '02/02/2021',
                endDate: '02/08/2021',
                continuity: 'Continuous',
                lastChangeDate: '06/08/2021',
                changeAction: 'APPROVED',
                changeActionId: '1',
                requestActionId: '891755120',
                leavePlanName: 'Northern Trust Company Test Medical Leave 2',
                leavePlanType: 'COMPANY',
                leavePlanDescription: 'Test',
                denialReason: ''
              }],
            AccommodationDescription: '',
            AccommodationsubCategory: '',
            substatus: 'Accepted',
            substatusId: '1',
            AccommodationType: ''
          }],
        certRequestList: [
          {
            certId: '1010380',
            certPeriodStartDate: '02/02/2021',
            certPeriodEndDate: '02/08/2021',
            certStatus: 'Not Yet Received'
          }],
        leaveISMedical: '0',
        leaveRequestStartDate: '02/02/2021',
        relationship: 'Employee',
        confirmReturnToWork: null,
        missingInfo: []
      },
      {
        leaveId: '888881',
        leaveStartDate: '10/08/2019',
        leaveEndDate: '10/08/2019',
        leaveStatus: 'Open',
        leaveReason: 'Disability Accommodations',
        caseId: '0737469',
        personId: '8489427',
        reedLeaveId: '0000',
        leaveProcessDate: '10/15/2019',
        leaveType: 'ADA Accommodation',
        leaveSubType: 'Continuous',
        leaveEstimatedEndDate: '',
        leaveEditDate: null,
        insuredFirstName: null,
        insuredLastName: null,
        leaveFirstName: '',
        leaveLastName: '',
        absenceDetails: [
          {
            requestId: '1345700',
            status: 'PENDED',
            statusId: '3',
            substatus: 'Accommodation Review - at work',
            substatusId: '139',
            AccommodationDescription: 'Job Restrictions and/or Limitations',
            AccommodationsubCategory: 'Reach above shoulder',
            AccommodationType: '',
            requestEndDate: '11/09/2019',
            requestStartDate: '10/08/2019',
            denialReason: '',
            leavePlan: [
              {
                planId: '2506',
                planName: 'FEDERAL',
                startDate: '10/08/2019',
                endDate: '10/08/2019',
                continuity: 'Continuous',
                lastChangeDate: '10/15/2019',
                changeAction: 'PENDED',
                changeActionId: '3',
                requestActionId: '839697941',
                leavePlanName: 'ADA Accommodation',
                leavePlanType: 'FEDERAL',
                leavePlanDescription: 'Under the Reasonable Accommodation provisions of the ADA, eligibility for an accommodation will be considered if you have a substantially limiting impairment or record of such an impairment as defined by the ADA.',
                denialReason: ''
              }
            ]
          },
          {
            requestId: '1345700',
            status: 'PENDED',
            statusId: '3',
            substatus: 'Accommodation Review - at work',
            substatusId: '139',
            AccommodationDescription: 'Job Restrictions and/or Limitations',
            AccommodationsubCategory: 'Reach above shoulder',
            AccommodationType: '',
            requestEndDate: '11/09/2019',
            requestStartDate: '10/09/2019',
            denialReason: '',
            leavePlan: [
              {
                planId: '2506',
                planName: 'FEDERAL',
                startDate: '10/08/2019',
                endDate: '10/08/2019',
                continuity: 'Continuous',
                lastChangeDate: '10/15/2019',
                changeAction: 'PENDED',
                changeActionId: '3',
                requestActionId: '839697941',
                leavePlanName: 'ADA Accommodation',
                leavePlanType: 'FEDERAL',
                leavePlanDescription: 'Under the Reasonable Accommodation provisions of the ADA, eligibility for an accommodation will be considered if you have a substantially limiting impairment or record of such an impairment as defined by the ADA.',
                denialReason: ''
              }
            ]
          },
          {
            requestId: '1345700',
            status: 'Approved',
            statusId: '1',
            substatus: 'Accommodation Review - at work',
            substatusId: '139',
            AccommodationDescription: 'Leave',
            AccommodationsubCategory: 'Reach above shoulder',
            AccommodationType: '',
            requestEndDate: '11/09/2019',
            requestStartDate: '09/08/2019',
            denialReason: '',
            leavePlan: [
              {
                planId: '2506',
                planName: 'FEDERAL',
                startDate: '10/08/2019',
                endDate: '10/08/2019',
                continuity: 'Continuous',
                lastChangeDate: '10/15/2019',
                changeAction: 'PENDED',
                changeActionId: '3',
                requestActionId: '839697941',
                leavePlanName: 'ADA Accommodation',
                leavePlanType: 'FEDERAL',
                leavePlanDescription: 'Under the Reasonable Accommodation provisions of the ADA, eligibility for an accommodation will be considered if you have a substantially limiting impairment or record of such an impairment as defined by the ADA.',
                denialReason: ''
              }
            ]
          },
          {
            requestId: '1345700',
            status: 'denied',
            statusId: '2',
            substatus: 'Accommodation Review - at work',
            substatusId: '139',
            AccommodationDescription: 'New Restrictions and/or Limitations',
            AccommodationsubCategory: 'Reach above shoulder',
            AccommodationType: '',
            requestEndDate: '11/09/2019',
            requestStartDate: '09/08/2019',
            denialReason: '',
            leavePlan: [
              {
                planId: '2506',
                planName: 'FEDERAL',
                startDate: '10/08/2019',
                endDate: '10/08/2019',
                continuity: 'Continuous',
                lastChangeDate: '10/15/2019',
                changeAction: 'PENDED',
                changeActionId: '3',
                requestActionId: '839697941',
                leavePlanName: 'ADA Accommodation',
                leavePlanType: 'FEDERAL',
                leavePlanDescription: 'Under the Reasonable Accommodation provisions of the ADA, eligibility for an accommodation will be considered if you have a substantially limiting impairment or record of such an impairment as defined by the ADA.',
                denialReason: ''
              }
            ]
          },
          {
            requestId: '1345700',
            status: '',
            statusId: '2',
            substatus: 'Accommodation Review - not at work',
            substatusId: '140',
            AccommodationDescription: 'New Restrictions and/or Limitations',
            AccommodationsubCategory: 'Reach above shoulder',
            AccommodationType: '',
            requestEndDate: '11/09/2019',
            requestStartDate: '08/11/2019',
            denialReason: '',
            leavePlan: [
              {
                planId: '2506',
                planName: 'FEDERAL',
                startDate: '10/08/2019',
                endDate: '10/08/2019',
                continuity: 'Continuous',
                lastChangeDate: '10/15/2019',
                changeAction: 'PENDED',
                changeActionId: '3',
                requestActionId: '839697941',
                leavePlanName: 'ADA Accommodation',
                leavePlanType: 'FEDERAL',
                leavePlanDescription: 'Under the Reasonable Accommodation provisions of the ADA, eligibility for an accommodation will be considered if you have a substantially limiting impairment or record of such an impairment as defined by the ADA.',
                denialReason: ''
              }
            ]
          },
          {
            requestId: '1345700',
            status: '',
            statusId: '2',
            substatus: 'Accommodation Review - not at work',
            substatusId: '140',
            AccommodationDescription: 'New Restrictions and/or Limitations',
            AccommodationsubCategory: 'Reach above shoulder',
            AccommodationType: '',
            requestEndDate: '11/09/2019',
            requestStartDate: '08/10/2019',
            denialReason: '',
            leavePlan: [
              {
                planId: '2506',
                planName: 'FEDERAL',
                startDate: '10/08/2019',
                endDate: '10/08/2019',
                continuity: 'Continuous',
                lastChangeDate: '10/15/2019',
                changeAction: 'PENDED',
                changeActionId: '3',
                requestActionId: '839697941',
                leavePlanName: 'ADA Accommodation',
                leavePlanType: 'FEDERAL',
                leavePlanDescription: 'Under the Reasonable Accommodation provisions of the ADA, eligibility for an accommodation will be considered if you have a substantially limiting impairment or record of such an impairment as defined by the ADA.',
                denialReason: ''
              }
            ]
          },
          {
            requestId: '1345700',
            status: 'Approved',
            statusId: '1',
            substatus: 'Accommodation Review - at work',
            substatusId: '139',
            AccommodationDescription: 'New Restrictions and/or Limitations',
            AccommodationsubCategory: 'Reach above shoulder',
            AccommodationType: 'Permanent',
            requestEndDate: '',
            requestStartDate: '10/08/2019',
            denialReason: '',
            leavePlan: [
              {
                planId: '2506',
                planName: 'FEDERAL',
                startDate: '10/08/2019',
                endDate: '10/08/2019',
                continuity: 'Continuous',
                lastChangeDate: '10/15/2019',
                changeAction: 'PENDED',
                changeActionId: '3',
                requestActionId: '839697941',
                leavePlanName: 'ADA Accommodation',
                leavePlanType: 'FEDERAL',
                leavePlanDescription: 'Under the Reasonable Accommodation provisions of the ADA, eligibility for an accommodation will be considered if you have a substantially limiting impairment or record of such an impairment as defined by the ADA.',
                denialReason: ''
              }
            ]
          },
          {
            requestId: '1345700',
            status: 'Approved',
            statusId: '1',
            substatus: 'Accommodation Review - at work',
            substatusId: '139',
            AccommodationDescription: 'Leave',
            AccommodationsubCategory: 'Reach above shoulder',
            AccommodationType: '',
            requestEndDate: '11/09/2019',
            requestStartDate: '10/12/2019',
            denialReason: '',
            leavePlan: [
              {
                planId: '2506',
                planName: 'FEDERAL',
                startDate: '10/08/2019',
                endDate: '10/08/2019',
                continuity: 'Continuous',
                lastChangeDate: '10/15/2019',
                changeAction: 'PENDED',
                changeActionId: '3',
                requestActionId: '839697941',
                leavePlanName: 'ADA Accommodation',
                leavePlanType: 'FEDERAL',
                leavePlanDescription: 'Under the Reasonable Accommodation provisions of the ADA, eligibility for an accommodation will be considered if you have a substantially limiting impairment or record of such an impairment as defined by the ADA.',
                denialReason: ''
              }
            ]
          },
          {
            requestId: '1345700',
            status: 'Approved',
            statusId: '3',
            substatus: 'Accommodation Review - at work',
            substatusId: '139',
            AccommodationDescription: 'Aob Restrictions and/or Limitations',
            AccommodationsubCategory: 'Reach above shoulder',
            AccommodationType: 'Permanent',
            requestEndDate: '',
            requestStartDate: '07/08/2019',
            denialReason: '',
            leavePlan: [
              {
                planId: '2506',
                planName: 'FEDERAL',
                startDate: '10/08/2019',
                endDate: '10/08/2019',
                continuity: 'Continuous',
                lastChangeDate: '10/15/2019',
                changeAction: 'PENDED',
                changeActionId: '3',
                requestActionId: '839697941',
                leavePlanName: 'ADA Accommodation',
                leavePlanType: 'FEDERAL',
                leavePlanDescription: 'Under the Reasonable Accommodation provisions of the ADA, eligibility for an accommodation will be considered if you have a substantially limiting impairment or record of such an impairment as defined by the ADA.',
                denialReason: ''
              }
            ]
          }
        ],
        certRequestList: [],
        leaveISMedical: '0',
        leaveRequestStartDate: '07/08/2019'
      },
      {
        leaveId: '888888',
        leaveStartDate: '05/07/2021',
        leaveEndDate: '05/13/2021',
        leaveStatus: 'Open',
        leaveReason: 'Disability Accommodations',
        caseId: '0660696',
        personId: '11213685',
        reedLeaveId: '0000',
        leaveProcessDate: '05/05/2021',
        leaveType: 'ADA Accommodation',
        leaveSubType: 'Continuous',
        leaveEstimatedEndDate: '',
        leaveEditDate: null,
        insuredFirstName: 'STEPH',
        insuredLastName: 'VARNEY',
        leaveFirstName: '',
        leaveLastName: '',
        absenceDetails: [
          {
            requestId: '2298807',
            status: 'APPROVED',
            statusId: '1',
            requestStartDate: '05/07/2021',
            requestEndDate: '05/13/2021',
            denialReason: '',
            leavePlan: [
              {
                planId: '2506',
                planName: 'FEDERAL',
                startDate: '05/07/2021',
                endDate: '05/13/2021',
                continuity: 'Continuous',
                lastChangeDate: '05/05/2021',
                changeAction: 'APPROVED',
                changeActionId: '1',
                requestActionId: '894296696',
                leavePlanName: 'ADA Accommodation',
                leavePlanType: 'FEDERAL',
                leavePlanDescription: 'Under the Reasonable Accommodation provisions of the ADA, eligibility for an accommodation will be considered if you have a substantially limiting impairment or record of such an impairment as defined by the ADA.',
                denialReason: ''}
            ],
            AccommodationDescription: '',
            AccommodationsubCategory: '',
            substatus: 'At Work',
            substatusId: '137',
            AccommodationType: ''
          }
        ],
        certRequestList: [
        ],
        leaveISMedical: '0',
        leaveRequestStartDate: '05/07/2021',
        relationship: 'Employee',
        confirmReturnToWork: null,
        missingInfo: [
        ]
      },
      {
        leaveId: '1345685',
        leaveStartDate: '10/01/2019',
        leaveEndDate: '10/03/2019',
        leaveStatus: 'Closed',
        leaveReason: 'Disability Accommodations',
        caseId: '0737469',
        personId: '8489427',
        reedLeaveId: '0000',
        leaveProcessDate: '10/15/2019',
        leaveType: 'ADA Accommodation',
        leaveSubType: 'Continuous',
        leaveEstimatedEndDate: '',
        leaveEditDate: null,
        insuredFirstName: null,
        insuredLastName: null,
        leaveFirstName: '',
        leaveLastName: '',
        absenceDetails: [
          {
            requestId: '1345686',
            status: 'PENDED',
            statusId: '3',
            substatus: 'Accommodation Review - at work',
            substatusId: '139',
            AccommodationDescription: ['Ergonomic Equipment'],
            AccommodationsubCategory: ['Back brace'],
            requestStartDate: '10/01/2019',
            AccommodationType: 'Permanent',
            requestEndDate: '11/09/2019',
            denialReason: 'Plan exhausted',
            leavePlan: [
              {
                planId: '2506',
                planName: 'FEDERAL',
                startDate: '10/01/2019',
                endDate: '10/03/2019',
                continuity: 'Continuous',
                lastChangeDate: '10/15/2019',
                changeAction: 'PENDED',
                changeActionId: '3',
                requestActionId: '839697940',
                leavePlanName: 'ADA Accommodation',
                leavePlanType: 'FEDERAL',
                leavePlanDescription: 'Under the Reasonable Accommodation provisions of the ADA, eligibility for an accommodation will be considered if you have a substantially limiting impairment or record of such an impairment as defined by the ADA.',
                denialReason: ''
              }
            ]
          }
        ],
        certRequestList: [],
        leaveISMedical: '0',
        leaveRequestStartDate: '10/01/2019'
      },
      //conc with closed status 06/10/2018
      {
        leaveId: '300033',
        leaveStartDate: '09/01/2018',
        leaveEndDate: '09/30/2018',
        leaveStatus: 'Closed',
        leaveReason: `My Illness/Health Condition`,
        caseId: '200000',
        personId: '300000',
        reedLeaveId: null,
        leaveProcessDate: '09/02/2018',
        leaveType: 'Concurrent',
        leaveSubType: 'Continuous',
        leaveEditDate: '09/28/2018',
        insuredFirstName: 'James',
        insuredLastName: 'Thomas',
        leaveFirstName: null,
        leaveLastName: 'Thomas',
        leavePlanChangedDetected: false,
        leaveRequestStartDate: '09/03/2018',
        certRequestList : [
          {
            certPeriodStartDate: '09/01/2018',
            certPeriodEndDate: '09/05/2018',
            status: 'Pend',
            continuity : 'Intermittent'
          },
          {
            certPeriodStartDate: '09/06/2018',
            certPeriodEndDate: '09/14/2018',
            status: 'Pend',
            continuity : 'reduced schedule'
          },
          {
            certPeriodStartDate: '09/15/2018',
            certPeriodEndDate: '09/21/2018',
            continuity: 'reduced schedule',
            status: 'Pend'
          },
          {
            certPeriodStartDate: '09/22/2018',
            certPeriodEndDate: '09/30/2018',
            status: 'Pend',
            continuity : 'continuous'
          }
        ],
        leaveRequestList: [
          {
            type: '',
            continuity: 'reduced schedule',
            startTime: '12/04/2018',
            endTime: '12/01/2018',
            status: 'Pend'
          },
          {
            type: '',
            continuity: 'reduced schedule',
            startTime: '12/05/2018',
            endTime: null,
            status: 'Pend'
          }
        ],
        absenceDetails: [
          {
            leavePlan: [
              {
                planId: '1',
                planName: 'FEDERAL',
                startDate: '09/03/2018',
                endDate: '09/03/2018',
                continuity: 'Continuous',
                lastChangeDate: '09/23/2015',
                changeAction: 'DENIED',
                changeActionId: '3',
                requestActionId: '41019622',
                leavePlanName: 'Federal Family and Medical Leave Act (FMLA)',
                leavePlanType: 'FEDERAL',
                leavePlanDescription: '',
                denialReason: ''
              }
            ],
            requestId: '1023473',
            status: 'DENIED',
            statusId: '3',
            requestStartDate: '09/03/2018',
            denialReason: ''
          }
        ]
      },
      //Concurrent to WD SUPP 11/25/2018
      {
        leaveId: '100002',
        leaveStartDate: '09/01/2018',
        leaveEndDate: '09/30/2018',
        leaveStatus: 'Closed',
        leaveReason: `My Illness/Health Condition`,
        caseId: '200000',
        personId: '300000',
        reedLeaveId: null,
        leaveProcessDate: '09/02/2018',
        leaveType: 'Concurrent',
        leaveSubType: 'Continuous',
        leaveEditDate: '09/28/2018',
        insuredFirstName: 'James',
        insuredLastName: 'Thomas',
        leaveFirstName: 'Mary',
        leaveLastName: 'Thomas',
        leavePlanChangedDetected: false,
        leaveRequestStartDate: '09/04/2018',
        certRequestList : [
          {
            certPeriodStartDate: '09/01/2018',
            certPeriodEndDate: '09/30/2018',
            status: 'Pend',
            continuity : 'continuous'
          }
        ],
        leaveRequestList: [
          {
            type: '',
            continuity: 'reduced schedule',
            startTime: '12/01/2018',
            endTime: '12/04/2018',
            status: 'Pend'
          },
          {
            type: '',
            continuity: 'reduced schedule',
            startTime: '12/05/2018',
            endTime: null,
            status: 'Pend'
          }],
        absenceDetails: [
          {
            leavePlan: [
              {
                planId: '1',
                planName: 'FEDERAL',
                startDate: '09/04/2018',
                endDate: '09/05/2018',
                continuity: 'Continuous',
                lastChangeDate: '09/23/2015',
                changeAction: 'APPROVED',
                changeActionId: '3',
                requestActionId: '41019622',
                leavePlanName: 'Federal Family and Medical Leave Act (FMLA)',
                leavePlanType: 'FEDERAL',
                leavePlanDescription: '',
                denialReason: ''
              }
            ],
            requestId: '1023473',
            status: 'APPROVED',
            statusId: '3',
            requestStartDate: '09/03/2018',
            denialReason: ''
          }
        ]
      },
      //standalone with cancellled status 10/25/2018
      {
        leaveId: '200033',
        leaveStartDate: '10/25/2018',
        leaveEndDate: '10/30/2018',
        leaveStatus: 'Closed',
        leaveReason: ` Pregnancy/Childbirth`,
        caseId: '200000',
        personId: '300000',
        reedLeaveId: null,
        leaveProcessDate: '09/02/2018',
        leaveType: 'Standalone',
        leaveSubType: 'Continuous',
        leaveEditDate: '09/28/2018',
        insuredFirstName: 'Mary',
        insuredLastName: 'Thomas',
        leaveFirstName: null,
        leaveLastName: 'Thomas',
        leavePlanChangedDetected: false,
        leaveRequestStartDate: '10/25/2018',
        certRequestList : [],
        leaveRequestList: [
          {
            type: '',
            continuity: 'reduced schedule',
            startTime: '10/25/2018',
            endTime: '10/27/2018',
            status: 'Pend'
          },
          {
            type: '',
            continuity: 'reduced schedule',
            startTime: '10/28/2018',
            endTime: '10/30/2018',
            status: 'Pend'
          }],
        absenceDetails: [
          {
            leavePlan: [
              {
                planId: '1',
                planName: 'FEDERAL',
                startDate: '10/25/2018',
                endDate: '10/25/2018',
                continuity: 'Continuous',
                lastChangeDate: '09/23/2015',
                changeAction: 'APPROVED',
                changeActionId: '3',
                requestActionId: '41019622',
                leavePlanName: 'Federal Family and Medical Leave Act (FMLA)',
                leavePlanType: 'FEDERAL',
                leavePlanDescription: '',
                denialReason: ''
              }
            ],
            requestId: '1023473',
            status: 'APPROVED',
            statusId: '3',
            requestStartDate: '10/25/2018',
            denialReason: ''
          }
        ]
      },
      //Standalone 09/01/2018
      {
        leaveId: '100001',
        leaveStartDate: '12/01/2018',
        leaveEndDate: null,
        leaveStatus: 'Cancelled',
        leaveReason: `Urgent Personal Matter Involving My or My Family’s Well-Being`,
        caseId: '200000',
        personId: '300000',
        reedLeaveId: null,
        leaveProcessDate: '09/02/2018',
        leaveType: 'Standalone',
        leaveSubType: 'Continuous',
        leaveEditDate: '09/28/2018',
        insuredFirstName: 'James',
        insuredLastName: 'Thomas',
        leaveFirstName: 'James',
        leaveLastName: 'Thomas',
        leavePlanChangedDetected: false,
        leaveRequestStartDate: '12/04/2018',
        certRequestList : [],
        leaveRequestList: [
          {
            type: '',
            continuity: 'reduced schedule',
            startTime: '12/01/2018',
            endTime: '12/04/2018',
            status: 'Pend'
          },
          {
            type: '',
            continuity: 'reduced schedule',
            startTime: '12/05/2018',
            endTime: null,
            status: 'Pend'
          }
        ],
        absenceDetails: [
          {
            leavePlan: [
              {
                planId: '1',
                planName: 'FEDERAL',
                startDate: '12/04/2018',
                endDate: '12/04/2018',
                continuity: 'Continuous',
                lastChangeDate: '09/23/2015',
                changeAction: 'PENDED',
                changeActionId: '3',
                requestActionId: '41019622',
                leavePlanName: 'Federal Family and Medical Leave Act (FMLA)',
                leavePlanType: 'FEDERAL',
                leavePlanDescription: '',
                denialReason: ''
              }
            ],
            requestId: '1023473',
            status: 'PENDED',
            statusId: '3',
            requestStartDate: '12/04/2018',
            denialReason: ''
          }
        ]
      },
      {
        leaveId: '100006',
        leaveStartDate: '12/01/2018',
        leaveEndDate: null,
        leaveStatus: 'Closed',
        leaveReason: `Day-Care Activity`,
        caseId: '200000',
        personId: '300000',
        reedLeaveId: null,
        leaveProcessDate: '09/02/2018',
        leaveType: 'Standalone',
        leaveSubType: 'Continuous',
        leaveEditDate: '09/28/2018',
        insuredFirstName: 'James',
        insuredLastName: 'Thomas',
        leaveFirstName: 'James',
        leaveLastName: 'Thomas',
        leavePlanChangedDetected: false,
        leaveRequestStartDate: '12/05/2018',
        certRequestList : [
          {
            certPeriodStartDate: '12/01/2018',
            certPeriodEndDate: '12/08/2018',
            status: 'Pend',
            continuity : 'Intermittent'
          },
          {
            certPeriodStartDate: '12/10/2018',
            certPeriodEndDate: '12/19/2018',
            status: 'Pend',
            continuity : 'reduced schedule'
          },
          {
            certPeriodStartDate: '12/20/2018',
            certPeriodEndDate: '12/27/2018',
            continuity: 'reduced schedule',
            status: 'Pend'
          },
          {
            certPeriodStartDate: '12/28/2018',
            certPeriodEndDate: '12/30/2018',
            status: 'Pend',
            continuity : 'continuous'
          }],
        leaveRequestList: [
          {
            type: '',
            continuity: 'reduced schedule',
            startTime: '12/01/2018',
            endTime: '12/04/2018',
            status: 'Pend'
          },
          {
            type: '',
            continuity: 'reduced schedule',
            startTime: '12/05/2018',
            endTime: null,
            status: 'Pend'
          }
        ],
        absenceDetails: [
          {
            leavePlan: [
              {
                planId: '1',
                planName: 'FEDERAL',
                startDate: '12/05/2018',
                endDate: '12/05/2018',
                continuity: 'Continuous',
                lastChangeDate: '09/23/2015',
                changeAction: 'APPROVED',
                changeActionId: '3',
                requestActionId: '41019622',
                leavePlanName: 'Federal Family and Medical Leave Act (FMLA)',
                leavePlanType: 'FEDERAL',
                leavePlanDescription: '',
                denialReason: ''
              }
            ],
            requestId: '1023473',
            status: 'APPROVED',
            statusId: '3',
            requestStartDate: '12/05/2018',
            denialReason: ''
          }
        ]
      },

      {
        leaveId: '100009',
        leaveStartDate: '09/01/2018',
        leaveEndDate: '09/30/2018',
        leaveStatus: 'cancelled',
        leaveReason: `Day-Care Activity`,
        caseId: '200000',
        personId: '300000',
        reedLeaveId: null,
        leaveProcessDate: '09/02/2018',
        leaveType: 'Concurrent',
        leaveSubType: 'Continuous',
        leaveEditDate: '09/28/2018',
        insuredFirstName: 'James',
        insuredLastName: 'Thomas',
        leaveFirstName: null,
        leaveLastName: 'Thomas',
        leavePlanChangedDetected: false,
        leaveRequestStartDate: '09/06/2018',
        certRequestList : [],
        leaveRequestList: [
          {
            type: '',
            continuity: 'reduced schedule',
            startTime: '09/01/2018',
            endTime: '09/10/2018',
            status: 'Pend'
          },
          {
            type: '',
            continuity: 'reduced schedule',
            startTime: '09/11/2018',
            endTime: '09/30/2018',
            status: 'Pend'
          }
        ],
        absenceDetails: [
          {
            leavePlan: [
              {
                planId: '1',
                planName: 'FEDERAL',
                startDate: '09/06/2018',
                endDate: '09/06/2018',
                continuity: 'Continuous',
                lastChangeDate: '09/23/2015',
                changeAction: 'DENIED',
                changeActionId: '3',
                requestActionId: '41019622',
                leavePlanName: 'Federal Family and Medical Leave Act (FMLA)',
                leavePlanType: 'FEDERAL',
                leavePlanDescription: '',
                denialReason: ''
              }
            ],
            requestId: '1023473',
            status: 'DENIED',
            statusId: '3',
            requestStartDate: '09/06/2018',
            denialReason: ''
          }
        ]
      }
    ]
  }
};

export const CLAIMS = {
  errorList: null,
  userId: null,
  sessionIdentifier: null,
  claimOverview: {
    nonRelatedClaims: [
      {
        claimEventId: '1234567',
        sourceSystemId:  'AA',
        claimType: 'Short Term Disability- Supplemental Statutory',
        claimSubType: 'Supplemental State Surgery',
        coverageType: 'SUPP',
        coverageCategory: 'LTD',
        disabilityDate: '04/15/2018',
        effectiveDate: null,
        authorizedEndDate: null,
        claimStatus: 'Paperless Intake',
        claimStatusCode: '05',
        claimOnlineStatus: 'Awaiting Surgery Details',
        claimOnlineStatusCode: '04',
        claimOnlineSubStatus: 'Awaiting Surgery Details',
        claimOnlineSubStatusCode: '03',
        extensionStatus: null,
        extensionStatusCode: null,
        extensionSubStatus: null,
        extensionSubStatusCode: null,
        claimOfficeNumber: null,
        claimOfficeId: '733',
        caseId: '978301',
        claimProcessDate: '04/10/2018',
        claimEffectiveDate: '04/10/2018',
        reason: 'N/A',
        physicianStatementDate: '04/10/2018',
        employerStatementDate: null,
        employeeStatementDate: '04/10/2018',
        estimatedSurgeryDate: '02/11/2018',
        estimatedDeliveryDate: null,
        lastDayWork: '04/12/2018',
        asmIndicator: 'A',
        confirmedLastDayWorkDate: '01/07/2018',
        confirmedSurgeryDate: '02/11/2018',
        confirmedDeliveryDate: null,
        encIndicator: true,
        encSurgeryIndicator: true,
        fundingMethod: 'FI',
        coveragePlanNumber: '1',
        taskOpen: false,
        missingInfo: null,
        claimProgress: 'Awaiting Surgery',
        extensionRequestDate: '11/15/2018',
        babyDeathIndicator: null,
        recentClaimIndicator: true,
        personId: '123456'
      },
      {
        claimEventId: '16007150',
        sourceSystemId: 'DCS',
        claimType: 'Short Term Disability- Supplemental Statutory',
        claimSubType: 'Supplemental State Surgery',
        coverageType: 'SUPP',
        coverageCategory: 'WD',
        disabilityDate: '04/15/2018',
        effectiveDate: null,
        authorizedEndDate: null,
        claimStatus: 'Paperless Intake',
        claimStatusCode: '05',
        claimOnlineStatus: 'Awaiting Surgery Details',
        claimOnlineStatusCode: '03',
        claimOnlineSubStatus: 'Awaiting Surgery Details',
        claimOnlineSubStatusCode: '03',
        extensionStatus: null,
        extensionStatusCode: null,
        extensionSubStatus: null,
        extensionSubStatusCode: null,
        claimOfficeNumber: null,
        claimOfficeId: '733',
        caseId: '978301',
        claimProcessDate: '04/10/2018',
        claimEffectiveDate: '04/10/2018',
        reason: 'N/A',
        physicianStatementDate: '04/10/2018',
        employerStatementDate: null,
        employeeStatementDate: '04/10/2018',
        estimatedSurgeryDate: '02/11/2018',
        estimatedDeliveryDate: null,
        lastDayWork: '04/12/2018',
        asmIndicator: 'A',
        confirmedLastDayWorkDate: '01/07/2018',
        confirmedSurgeryDate: '02/11/2018',
        confirmedDeliveryDate: null,
        encIndicator: true,
        encSurgeryIndicator: true,
        fundingMethod: 'FI',
        coveragePlanNumber: '1',
        taskOpen: false,
        missingInfo: null,
        claimProgress: 'Awaiting Surgery',
        extensionRequestDate: '11/15/2018',
        babyDeathIndicator: null,
        recentClaimIndicator: true,
        personId: '123456'
      },
      {
        claimEventId: '13101049',
        sourceSystemId:  'AA',
        claimType: 'Long Term Disability',
        claimSubType: 'Long Term Disability',
        coverageType: 'BRTM',
        coverageCategory: 'LTD',
        disabilityDate: '01/06/2015',
        effectiveDate: '01/03/2016',
        authorizedEndDate: '11/18/2018',
        claimStatus: 'Pending',
        claimStatusCode: '01',
        claimOnlineStatus: 'Awaiting Delivery',
        claimOnlineStatusCode: '01',
        claimOnlineSubStatus: 'Awaiting Delivery',
        claimOnlineSubStatusCode: '01',
        extensionStatus: null,
        extensionStatusCode: null,
        extensionSubStatus: null,
        extensionSubStatusCode: null,
        claimOfficeNumber: null,
        claimOfficeId: '700',
        caseId: '273917',
        claimProcessDate: '05/06/1985',
        claimEffectiveDate: '03/02/2016',
        reason: 'PROCESS PREMIUM WAIVER BENEFIT',
        physicianStatementDate: '03/01/2016',
        employerStatementDate: '03/01/2016',
        employeeStatementDate: '03/01/2016',
        estimatedSurgeryDate: '11/27/2017',
        estimatedDeliveryDate: null,
        lastDayWork: '01/06/2015',
        asmIndicator: 'A',
        confirmedLastDayWorkDate: null,
        confirmedSurgeryDate: null,
        confirmedDeliveryDate: null,
        encIndicator: false,
        encSurgeryIndicator: false,
        fundingMethod: 'FI',
        coveragePlanNumber: '1',
        taskOpen: false,
        missingInfo: null,
        claimProgress: 'Awaiting Delivery',
        extensionRequestDate: '12/16/2018',
        babyDeathIndicator: null,
        recentClaimIndicator: false,
        personId: '123456'
      },
      {
        claimEventId: '13101051',
        sourceSystemId: 'AA',
        claimType: 'Long Term Disability',
        claimSubType: 'Long Term Disability',
        coverageType: 'BRTM',
        coverageCategory: 'LTD',
        disabilityDate: '01/06/2015',
        effectiveDate: '01/03/2016',
        authorizedEndDate: '11/18/2018',
        claimStatus: 'Pending',
        claimStatusCode: '01',
        claimOnlineStatus: 'Awaiting Delivery',
        claimOnlineStatusCode: '01',
        claimOnlineSubStatus: 'Awaiting Delivery',
        claimOnlineSubStatusCode: '01',
        extensionStatus: null,
        extensionStatusCode: null,
        extensionSubStatus: null,
        extensionSubStatusCode: null,
        claimOfficeNumber: null,
        claimOfficeId: '700',
        caseId: '273919',
        claimProcessDate: '05/06/1985',
        claimEffectiveDate: '03/02/2016',
        reason: 'PROCESS PREMIUM WAIVER BENEFIT',
        physicianStatementDate: '03/01/2016',
        employerStatementDate: '03/01/2016',
        employeeStatementDate: '03/01/2016',
        estimatedSurgeryDate: '11/27/2017',
        estimatedDeliveryDate: null,
        lastDayWork: '01/06/2015',
        asmIndicator: 'A',
        confirmedLastDayWorkDate: null,
        confirmedSurgeryDate: null,
        confirmedDeliveryDate: null,
        encIndicator: false,
        encSurgeryIndicator: false,
        fundingMethod: 'FI',
        coveragePlanNumber: '1',
        taskOpen: false,
        missingInfo: null,
        claimProgress: 'Awaiting Delivery',
        extensionRequestDate: '12/16/2018',
        babyDeathIndicator: null,
        recentClaimIndicator: false,
        personId: '123456'
      },
      {
        claimEventId: '13101061',
        sourceSystemId: 'DCS',
        claimType: 'Long Term Disability',
        claimSubType: 'Long Term Disability',
        coverageType: 'BRTM',
        coverageCategory: 'LTD',
        disabilityDate: '01/06/2015',
        effectiveDate: '01/03/2016',
        authorizedEndDate: '11/18/2018',
        claimStatus: 'Pending',
        claimStatusCode: '01',
        claimOnlineStatus: 'Awaiting Delivery',
        claimOnlineStatusCode: '01',
        claimOnlineSubStatus: 'Awaiting Delivery',
        claimOnlineSubStatusCode: '01',
        extensionStatus: null,
        extensionStatusCode: null,
        extensionSubStatus: null,
        extensionSubStatusCode: null,
        claimOfficeNumber: null,
        claimOfficeId: '700',
        caseId: '273919',
        claimProcessDate: '05/06/1985',
        claimEffectiveDate: '03/02/2016',
        reason: 'PROCESS PREMIUM WAIVER BENEFIT',
        physicianStatementDate: '03/01/2016',
        employerStatementDate: '03/01/2016',
        employeeStatementDate: '03/01/2016',
        estimatedSurgeryDate: '11/27/2017',
        estimatedDeliveryDate: null,
        lastDayWork: '01/06/2015',
        asmIndicator: 'A',
        confirmedLastDayWorkDate: null,
        confirmedSurgeryDate: null,
        confirmedDeliveryDate: null,
        encIndicator: false,
        encSurgeryIndicator: false,
        fundingMethod: 'FI',
        coveragePlanNumber: '1',
        taskOpen: false,
        missingInfo: null,
        claimProgress: 'Awaiting Delivery',
        extensionRequestDate: '12/16/2018',
        babyDeathIndicator: null,
        recentClaimIndicator: false,
        personId: '123456'
      },
      {
        claimEventId: '13101071',
        sourceSystemId: 'AA',
        claimType: 'Long Term Disability',
        claimSubType: 'Long Term Disability',
        coverageType: 'BRTM',
        coverageCategory: 'LTD',
        disabilityDate: '01/06/2015',
        effectiveDate: '01/03/2016',
        authorizedEndDate: '11/18/2018',
        claimStatus: 'Pending',
        claimStatusCode: '01',
        claimOnlineStatus: 'Awaiting Delivery',
        claimOnlineStatusCode: '01',
        claimOnlineSubStatus: 'Awaiting Delivery',
        claimOnlineSubStatusCode: '01',
        extensionStatus: null,
        extensionStatusCode: null,
        extensionSubStatus: null,
        extensionSubStatusCode: null,
        claimOfficeNumber: null,
        claimOfficeId: '700',
        caseId: '273919',
        claimProcessDate: '05/06/1985',
        claimEffectiveDate: '03/02/2016',
        reason: 'PROCESS PREMIUM WAIVER BENEFIT',
        physicianStatementDate: '03/01/2016',
        employerStatementDate: '03/01/2016',
        employeeStatementDate: '03/01/2016',
        estimatedSurgeryDate: '11/27/2017',
        estimatedDeliveryDate: null,
        lastDayWork: '01/06/2015',
        asmIndicator: 'A',
        confirmedLastDayWorkDate: null,
        confirmedSurgeryDate: null,
        confirmedDeliveryDate: null,
        encIndicator: false,
        encSurgeryIndicator: false,
        fundingMethod: 'FI',
        coveragePlanNumber: '1',
        taskOpen: false,
        missingInfo: null,
        claimProgress: 'Awaiting Delivery',
        extensionRequestDate: '12/16/2018',
        babyDeathIndicator: null,
        recentClaimIndicator: false,
        personId: '123456'
      }
    ],
    relatedClaims: []
  }
};

export const CLAIMSALT: ClaimDetailsALT[] = [

  {
    claimOverview: {
      nonRelatedClaims: [
        {
          claimEventId: '100041',
          sourceSystemId: 'DCS',
          claimType: 'Your Scheduled Surgery Claim',
          claimSubType: 'Your Scheduled Surgery Claim',
          coverageType: 'BTRM',
          coverageCategory: 'PFL',
          disabilityDate: '01/06/2015',
          effectiveDate: '01/0/2016',
          authorizedEndDate: '01/03/2016',
          claimStatus: 'Pending',
          claimStatusCode: '01',
          claimOnlineStatus: 'Awaiting Surgery',
          claimOnlineStatusCode: '04',
          claimOnlineSubStatus: 'Awaiting Surgery',
          claimOnlineSubStatusCode: '08',
          extensionStatus: null,
          extensionSubStatus: null,
          extensionRequestDate: '',
          extensionSubStatusCode: null,
          extensionStatusCode: 1,
          claimOfficeNumber: null,
          claimOfficeId: '700',
          caseId: '273917',
          claimAso: false,
          caseAso: false,
          claimOfficeFaxNumber: '8664115613',
          claimProcessDate: '02/03/2016',
          claimEffectiveDate: '02/03/2016',
          reason: 'N/A',
          physicianStatementDate: '01/03/2016',
          employerStatementDate: '01/03/2016',
          employeeStatementDate: '01/03/2016',
          estimatedSurgeryDate: '01/08/2018',
          estimatedDeliveryDate: null,
          lastDayWork: null,
          asmIndicator: 'M',
          confirmedLastDayWorkDate: '01/03/2016',
          confirmedSurgeryDate: null,
          confirmedDeliveryDate: '01/03/2016',
          encIndicator: false,
          encSurgeryIndicator: false,
          fundingMethod: 'FI',
          coveragePlanNumber: '1',
          /*  employeeGroupName: null,*/
          missingInfo: null,
          claimProgress: '',
          extensionApprovedThroughDate: '',
          extensionDecisionDate: '',
          babyDeathIndicator: null,
          recentClaimIndicator: true,
          personId: '123456'
        },
      ],
      relatedClaims: []
    },
    claimsHistory: ''
  }
];
