import {Directive} from '@angular/core';
import {AbstractControl, NG_VALIDATORS, Validator} from '@angular/forms';

@Directive({
  selector: '[validatedrodownRequired]',
  providers: [{provide: NG_VALIDATORS, useExisting: ValidatedrodownRequired, multi: true}]
})
export class ValidatedrodownRequired implements Validator {
  validate(control: AbstractControl) {
    if (control.value && control.value === 'notSelected') {
      return {validatedrodownRequired: true};
    }
    return null;
  }
}
