import {Component, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import {SecondaryDateDropdownComponent} from '../../common/molecules/secondary-date-dropdown/secondary-date-dropdown.component';
import {AppConstants} from '../../../shared/constants/app.constants';
import {AppLabelConstants} from '../../../shared/constants/appLabel.constants';
import {SessionConstants} from '../../../shared/constants/session.constants';

@Component({
  selector: 'app-surgery-common-quetions',
  templateUrl: 'surgery-common-quetions.component.html',
  styleUrls: ['surgery-common-quetions.component.scss']
})
export class SurgeryCommonQuetionsComponent implements OnInit {
  @ViewChild(SecondaryDateDropdownComponent, { static: false }) DateLabelValue: SecondaryDateDropdownComponent;
  @Input() passSelectedValue;
  @Input() clearForm: boolean;
  @Input() Question_7;
  @Input() Question_8;
  @Input() Question_9;
  @Input() Question_10;
  @Output() callLastDayCTA: EventEmitter<object> = new EventEmitter<object>();
  @Output() radioBtnValueEmitter: EventEmitter<boolean> =  new EventEmitter<boolean>();
  @Output() hospitalAdmittedDateEmitter: EventEmitter<boolean> =  new EventEmitter<boolean>();
  public appConstant = AppConstants;
  public appLabelConstant = AppLabelConstants;
  public exactdate;
  public handleErrorLable = false;
  public showButton = true;
  getDropdownValue;
  getDropdownValueOptional;

  constructor() {}

  ngOnInit() {}

  getDateFromComponent() {
    const getFirstValue = sessionStorage.getItem(SessionConstants.GET_FIRST_VALUE);
    if (getFirstValue) {
      this.DateLabelValue.getDate();
      this.validateMessages();
    }
    this.DateLabelValue.getDate();
  }
  isHospitalDateValidFn() {
    if (this.getDropdownValue && this.getDropdownValue.month !== AppConstants.FORM_VALUE_MONTH && this.getDropdownValue.day !== AppConstants.FORM_VALUE_DAY) {
      this.hospitalAdmittedDateEmitter.emit(true);
    } else {
      this.hospitalAdmittedDateEmitter.emit(false);
    }
 }

  validateDateOnSubmit() {
    if (this.DateLabelValue) {
      if (this.getDropdownValue === undefined || (this.getDropdownValue && (this.getDropdownValue.month === AppConstants.FORM_VALUE_MONTH  || this.getDropdownValue.day === AppConstants.FORM_VALUE_DAY))) {
        this.DateLabelValue.showDateErrorMessage = false;
      } else {
        this.DateLabelValue.showDateErrorMessage = true;
      }
    }
  }

  validateMessages() {
    if (this.getDropdownValue && this.getDropdownValue.month
      && this.getDropdownValue.year
      && this.getDropdownValue.day === AppConstants.FORM_VALUE_DAY) {
      this.handleErrorLable = true;
    } else {
      this.handleErrorLable = false;
    }
  }

  extendThroughSubmitbtn() {
    this.validateMessages();
    this.validateDateOnSubmit();
    if (this.exactdate === 'Yes') {
      this.DateLabelValue.getDate();
    }

    this.getEmit();
  }

  getEmit() {
    if (this.getDropdownValue && this.getDropdownValue.month
      && this.getDropdownValue.year
      && this.getDropdownValue.day !== AppConstants.FORM_VALUE_DAY) {
      this.callLastDayCTA.emit({
        flag : 'true',
        date : this.getDropdownValue,
        exactvalue: this.exactdate,
        optionalDate: this.getDropdownValueOptional
      });
      this.handleErrorLable = false;
      this.showButton = false;
    } else {
      if (this.exactdate === 'Yes') {
        this.handleErrorLable = true;
      } else { //in case no is selected
        this.callLastDayCTA.emit({
          flag : 'false',
          date : null,
          exactvalue: this.exactdate,
          optionalDate: null
        });
      }
    }
  }

  onRadioClick() {
     // this.clearForm = true
    if (this.getDropdownValue && this.getDropdownValue.day) {
      this.getDropdownValue.day = AppConstants.FORM_VALUE_DAY;
    }
    this.radioBtnValueEmitter.emit(true);
  }
}

