import {Component, ElementRef, Input, OnInit, ViewChild} from '@angular/core';

@Component({
  selector: 'app-donut-chart',
  templateUrl: './donut-chart.component.html',
  styleUrls: ['./donut-chart.component.scss']
})
export class DonutChartComponent implements OnInit {

  @ViewChild('donut', { static: true }) donut: ElementRef;
  @Input() data;
  @Input() options;

  private ctx;

  constructor() { }

  ngOnInit() {
    this.ctx = this.initCanvas();
    this.drawChart(this.ctx, this.data, this.options);
  }

  initCanvas() {
    const el = this.donut.nativeElement
    el.width = this.options.chartWidth;
    el.height = this.options.chartHeight;
    return el.getContext('2d');
  }

  drawChart(ctx, data, options) {
    let total = 0;
    let colorIndex = 0;
    let startAngle = Math.PI;
    let sliceAngle;
    let sliceColor;
    const sliceBorderWidth = options.sliceBorderWidth;
    const sliceBorderColor = options.sliceBorderColor;
    const centerX = this.donut.nativeElement.width / 2;
    const centerY = this.donut.nativeElement.width / 2;
    const radius = Math.min(centerX, centerY);

    for (const i in data) {
      if (data.hasOwnProperty(i)) {
        total += data[i];
      }
    }

    for (const i in data) {
      if (data.hasOwnProperty(i)) {
        sliceAngle = 2 * Math.PI * data[i] / total;
        sliceColor = options.sliceFillColors[colorIndex % options.sliceFillColors.length];

        if ( sliceBorderWidth ) {
          //background slice to have a border
          this.drawPieSlice(ctx, centerX, centerY, radius, startAngle - sliceBorderWidth, startAngle + sliceAngle + sliceBorderWidth, sliceBorderColor);
        }

        //actual colored slice
        this.drawPieSlice(ctx, centerX, centerY, radius, startAngle + sliceBorderWidth, startAngle + sliceAngle - sliceBorderWidth, sliceColor);
        startAngle += sliceAngle;
        colorIndex++;
      }
    }
    //draw a donut hole
    if (options.donutHoleSize) {
      this.drawPieSlice(ctx, centerX, centerY, options.donutHoleSize * radius, 0, 2 * Math.PI, 'white');
    }
    //draw text inside the chart
    if (options.insideTextHeader || options.insideTextSubheader) {
      this.drawTextInChart(ctx, centerX, centerY, options.insideTextHeader, options.insideTextSubheader);
    }
  }

  drawPieSlice(ctx, centerX, centerY, radius, startAngle, endAngle, color ) {
    ctx.fillStyle = color;
    ctx.beginPath();
    ctx.moveTo(centerX, centerY);
    ctx.arc(centerX, centerY, radius, startAngle, endAngle);
    ctx.closePath();
    ctx.fill();
  }

  drawTextInChart(ctx, centerX, centerY, headerText, subheaderText) {
    ctx.fillStyle = '#484848';
    ctx.textAlign = 'center';
    ctx.font = 'bold 18px HCo Gotham SSm';
    ctx.fillText(headerText, centerX, centerY);
    ctx.font = '14px HCo Gotham SSm';
    ctx.textBaseline = 'top';
    ctx.fillText(subheaderText, centerX, centerY + 4);
  }
}
