import {Component, OnInit} from '@angular/core';
import {User} from '../../../../shared/models/user.model';
import {CustomEventService} from '../../../../shared/services/tealium/custom-event.service';
import {StorageManagementService} from '../../../../shared/services/storage-management.service';
import {AppLabelConstants} from '../../../../shared/constants/appLabel.constants';
import {SessionConstants} from '../../../../shared/constants/session.constants';
import {AppConstants} from '../../../../shared/constants/app.constants';

@Component({
  selector: 'app-claim-handler',
  templateUrl: './claim-handler.component.html',
  styleUrls: ['./claim-handler.component.scss']
})
export class ClaimHandlerComponent implements OnInit {

  claimHandlerName: string;
  claimHandlerTitle: string;
  claimHandlerHeader: string;
  fADAMobileNumber: string;
  mobileNumber: string;
  lblPhone = 'Phone';
  extension: string;
  claimEventId = '';
  officeId = '';
  serviceError: boolean;
  public currentUser: User;
  public currClaim;
  public currPage;
  public currSourceSystem;
  public appConstant = AppConstants;
  public claimRepDetails;
  public extensionLabel = AppLabelConstants.EXTENSION;
  public dcsHandlerInfo;
  private claimHandlerInfo;

  constructor(private storageManagementService: StorageManagementService,
    private customEventService: CustomEventService) {}


  ngOnInit() {
    this.currPage = this.storageManagementService.getSessionData(SessionConstants.CURRENT_PAGE);
    this.claimHandlerTitle = AppLabelConstants.UR_CLAIMS_REP;
    this.claimHandlerHeader = AppLabelConstants.WANNA_TALK_ABT_ONE_OF_UR_CLAIM;
    this.currClaim = this.storageManagementService.getSessionData(SessionConstants.CUR_CLAIM);
    if (this.currPage !== AppLabelConstants.URL_HOME && this.currClaim) {
      this.currSourceSystem = this.currClaim.sourceSystemId;
    } else {
      this.currSourceSystem = this.storageManagementService.getSessionData(SessionConstants.DEFAULT_CASE);
    };
    if (this.storageManagementService.getSessionData(SessionConstants.IS_CLAIM_REP_INFO_SET)) {
      this.setClaimHandlerInfo();
    } else {
      this.storageManagementService.isClaimRepInfoSet.subscribe(
        (isClaimRepInfoSet: boolean) => {
          if (isClaimRepInfoSet) {
            this.storageManagementService.setSessionData(SessionConstants.IS_CLAIM_REP_INFO_SET, true);
            this.setClaimHandlerInfo();
          }
        }
      );
    }
  }

  trackClaimHandlerContact(documentName, documentCategory) {
    this.customEventService.trackMissingInfoDocumentCategory(documentName, documentCategory);
  }

  public setClaimHandlerInfo() {
    // this.claimHandlerInfo = this.storageManagementService.getSessionData('claimHanlderInfo');
    // this.claimHandlerName = this.claimHandlerInfo && this.claimHandlerInfo.claimHanlerName ? this.claimHandlerInfo.claimHanlerName : null;
    // this.mobileNumber = this.claimHandlerInfo && this.claimHandlerInfo.claimHanlderOfficeNumber ? this.claimHandlerInfo.claimHanlderOfficeNumber : null;
    this.claimHandlerName = this.storageManagementService.getSessionData(SessionConstants.CLAIM_REP_NAME);
    this.fADAMobileNumber = this.storageManagementService.getSessionData(SessionConstants.THAA_FOUNDATIONAL_ADA_MOBILE);
   // this.mobileNumber = AppLabelConstants.phoneNumberFormat(this.storageManagementService.getSessionData(SessionConstants.DISABILLITY_CLAIM_CONTACT));
    if (this.currSourceSystem === this.appConstant.SOURCE_SYS_ID_DCS) {
      this.mobileNumber = AppLabelConstants.phoneNumberFormat(this.storageManagementService.getSessionData(SessionConstants.DISABILLITY_CLAIM_CONTACT));
      if (this.storageManagementService.getSessionData(SessionConstants.CLAIM_HANDLER_INFO) && this.mobileNumber) {
        this.dcsHandlerInfo = this.storageManagementService.getSessionData(SessionConstants.CLAIM_HANDLER_INFO);
        this.extension = this.dcsHandlerInfo.claimHandlerExt ? this.dcsHandlerInfo.claimHandlerExt : '';
      }
    } else {
      if (this.storageManagementService.getSessionData(SessionConstants.CLAIM_MOBILE)) {
        this.mobileNumber = this.storageManagementService.getSessionData(SessionConstants.CLAIM_MOBILE);
      }
      if (this.storageManagementService.getSessionData(SessionConstants.CLAIM_EXTENSION)) {
        this.extension = this.storageManagementService.getSessionData(SessionConstants.CLAIM_EXTENSION);
      }
    }
  };
}
