import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {FormControl, FormGroup} from '@angular/forms';
import {AppLeaveIntakeConstants} from '../../../../leave-claim-intake-flow/leave-intake.constants';
import {AppLabelConstants} from '../../../../../shared/constants/appLabel.constants';
import {CustomEventService} from '../../../../../shared/services/tealium/custom-event.service';
import {IMultiDropdownInput, IMultiDropdownInputTitles} from '@shared/models/multi-dropdown.model';
import { INTAKE_OWCS_IDS } from '@app/cxr/modules/intake/shared/JSON/intake-owcs.constants';

@Component({
  selector: 'app-hour-min-unit-multi-dropdown',
  templateUrl: './hour-min-unit-multi-dropdown.component.html',
  styleUrls: ['./hour-min-unit-multi-dropdown.component.scss']
})
export class HourMinUnitMultiDropdownComponent implements OnInit {
  @Input() timeLabel: string;
  @Input() absenceSectionBoolean: boolean = false;
  @Input() partialContinuousBoolean: boolean = false;
  @Input() required: boolean = false;
  @Input() invalidTime: boolean = false;
  @Input() disableTime: boolean = false;
  @Input() selectedTime;
  @Input() requiredText;
  @Input() errorMessage: string = AppLeaveIntakeConstants.PLEASE_ANS;
  @Input() disableDefaultvalue?: boolean;
  @Input() hourDefaultValue: string = AppLabelConstants.HH_LABEL;
  @Input() minDefaultValue: string = AppLabelConstants.MM_LABEL ;
  @Input() amPmDefaultValue: string = AppLabelConstants.AM_TEXT;
  @Input() multiDropdownIds: IMultiDropdownInput = {
    labelId: 'ques-input-margin',
    hourId: 'hour',
    minId: 'minute',
    unitId: 'unit'
  };
  @Input() multiDropdownTitles: IMultiDropdownInputTitles;
  @Input() owcsContentMap: Map<string, string> = new Map();
  @Output() hourMinUnitValue = new EventEmitter();
  public leaveIntakeConstant = AppLeaveIntakeConstants;
  public appLabelConstant = AppLabelConstants;
  public minuteOptions = [];
  public hourOptions = [];
  public unitOptions = [];
  public hourLabel = this.appLabelConstant.HH_HOUR_TEXT;
  public minuteLabel = this.appLabelConstant.MM_MIN_TEXT;
  public unitLabel = this.appLabelConstant.UNIT_TEXT;
  public hourMinUnitForm;
  public showError: boolean = false;
  hoursTextLabel: string;
  unitsTextLabel: string;
  minutesTextLabel: string;

  constructor(private customEventService: CustomEventService) { }

  ngOnInit() {
    this.hoursTextLabel = this.owcsContentMap?.get(INTAKE_OWCS_IDS.IE_029_Body);
    this.minutesTextLabel = this.owcsContentMap?.get(INTAKE_OWCS_IDS.IE_030_Body);
    this.unitsTextLabel = this.owcsContentMap?.get(INTAKE_OWCS_IDS.IE_030A_Body);
    this.createAndSetTimeForm();
  }

  createAndSetTimeForm() {
    if ( !this.selectedTime ) {
      this.selectedTime = {
        [this.hourLabel]: this.hourDefaultValue,
        [this.minuteLabel]: this.minDefaultValue,
        [this.unitLabel]: this.amPmDefaultValue
      };
    }

    const formControls = {};
    for ( const field of Object.keys(this.selectedTime) ) {
      formControls[field] = new FormControl(this.selectedTime[field]);
    }
    this.hourMinUnitForm = new FormGroup(formControls);
      this.hourDefaultValue = this.requiredText ? this.appLabelConstant.REQUIRED : this.appLabelConstant.MM_LABEL;
        this.minDefaultValue = this.requiredText ? this.appLabelConstant.REQUIRED : this.appLabelConstant.HH_LABEL;
          this.unitOptions = (this.requiredText ? this.amPmDefaultValue === this.appLabelConstant.REQUIRED: this.amPmDefaultValue === this.appLabelConstant.AMPAM_TEXT) ? [this.appLabelConstant.AM_TEXT, this.appLabelConstant.PM_TEXT] : [this.appLabelConstant.PM_TEXT];

    for (let i = 0; i < 60; i++) {
      this.minuteOptions[i] = (i < 10 ? '0' + `${i}` : `${i}`);
    }
    for (let i = 0; i < 12; i++) {
      this.hourOptions[i] = (i < 9 ? '0' + `${i + 1}` : `${i + 1 }`);
    }
  }

  /**
   * function to populate the hour field in the 'selectedTime' object
   *
   * @param hourValue
   */
  setHoursSelected(hourValue) {
    const selectedHour = (this.hourMinUnitForm.controls[this.hourLabel].value) ? this.hourMinUnitForm.controls[this.hourLabel].value : this.hourDefaultValue;
    if ( selectedHour ) {
      this.selectedTime[this.hourLabel] = selectedHour.trim();
      this.hourMinUnitValue.emit(this.selectedTime);
      this.customEventService.trackDropdown(this.timeLabel + ' - (' + this.appLabelConstant.HOURS_TEXT + ')', this.selectedTime[this.hourLabel]);
    }
  }

  /**
   * function to populate the minute field in the 'selectedTime' object
   *
   * @param minuteValue
   */
  setMinutesSelected(minuteValue) {
    const selectedMinute = (this.hourMinUnitForm.controls[this.minuteLabel].value) ? this.hourMinUnitForm.controls[this.minuteLabel].value : this.minDefaultValue;
    if ( selectedMinute ) {
      this.selectedTime[this.minuteLabel] = selectedMinute.trim();
      this.hourMinUnitValue.emit(this.selectedTime);
      this.customEventService.trackDropdown(this.timeLabel + ' - (' + this.appLabelConstant.MINUTES_TEXT + ')', this.selectedTime[this.minuteLabel]);
    }
  }

  /**
   * function to populate the unit field in the 'selectedTime' object
   *
   * @param unitValue
   */
  setUnitSelected(unitValue) {
    const selectedUnit = (this.hourMinUnitForm.controls[this.unitLabel].value) ? this.hourMinUnitForm.controls[this.unitLabel].value : unitValue;
    if ( selectedUnit ) {
      this.selectedTime[this.unitLabel] = selectedUnit.trim();
      this.hourMinUnitValue.emit(this.selectedTime);
      this.customEventService.trackDropdown(this.timeLabel, this.selectedTime[this.unitLabel]);
    }
  }

  /**
   * function to validate the time selected by user
   */
  validateSelectedTime() {
    if ( this.required ) {
      if ( this.selectedTime[this.hourLabel].toLowerCase() === this.appLabelConstant.HH_LABEL.toLowerCase()
        || this.selectedTime[this.minuteLabel].toLowerCase() === this.appLabelConstant.MM_LABEL.toLowerCase()
        || this.invalidTime
      ) {
        this.showError = true;
      } else if ( this.selectedTime[this.hourLabel] && this.selectedTime[this.minuteLabel] && this.selectedTime[this.unitLabel] && !this.invalidTime ) {
        this.showError = false;
      }
    }
  }

  /**
   * to remove error on focus of any dropdown
   */
  removeError() {
    this.showError = this.showError ? !this.showError : this.showError;
  }
}
