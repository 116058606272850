export const MOCK_INTAKE_TOGGLE = {
  toggleList: [
    {
      toggle: "Maternity",
      state: true,
    },
    {
      toggle: "Bonding",
      state: true,
    },
    {
      toggle: "Loa New Claim Required",
      state: true,
    },
    {
      toggle: "Full Bonding Intake",
      state: true,
    },
  ],
  responseCode: "001",
  responseReason: "success",
};
