import {Directive, HostListener, Input} from '@angular/core';

/**
 * Created by AC12394 on 9/18/2017.
 */

@Directive({
  selector: '[routerLink]',
})
export class RouterLinkStubDirective {
  navigatedTo: any = null;
  @Input() linkParams: any;
  @HostListener('click') onClick() {
    this.navigatedTo = this.linkParams;
  }
}
