import { Faq, FAQAsset, FAQResponse, Feedback } from '../models/faq.model';
import { Injectable } from '@angular/core';
import { InternalFaqService } from './internal-faq.service';
import { StorageManagementService } from './storage-management.service';
import { SessionConstants } from '../constants/session.constants';
import { AppConstants } from '../constants/app.constants';
import { NavigationEnd, Router } from '@angular/router';


@Injectable()
export class FaqCommonService {
  public FAQs: Faq[] = [];
  public faqResponse: FAQResponse;
  public internalFaq: FAQResponse;
  private futurePaymentFaqIds = [
    'QID_250',
    'QID_251',
    'QID_252',
    'QID_255',
    'QID_256',
    'QID_257',
    'QID_267'
  ];
  constructor(
    private internalFaqService: InternalFaqService,
    private storageService: StorageManagementService,
    private router: Router
  ) {}
  public blazeSuccess = (faqResponse: FAQResponse) => {
    this.FAQs = [];
    this.faqResponse = faqResponse;
    if (
      this.faqResponse &&
      this.faqResponse.data &&
      this.faqResponse.data.length > 0
    ) {
      this.faqResponse.data.forEach((faqAsset: FAQAsset) => {
        const feedback: Feedback = new Feedback();
        const faq = new Faq();
        faq.question = faqAsset.question;
        faq.answer = faqAsset.answer;
        faq.id = faqAsset.key;
        faq.feedback = feedback;
        this.FAQs.push(faq);
      });
    }
    const appData = JSON.parse(
      this.storageService.retrieveSessionObject(SessionConstants.APP_DATA)
    );

    const currentPage: string = this.router.url;
    const previousPage = this.storageService.getSessionData(SessionConstants.PREVIOUS_URL);

    if (
      (currentPage && (currentPage.toLowerCase().indexOf(AppConstants.FUTURE_PAYMENTS_SM) !== -1) || (previousPage && (previousPage.toLowerCase().indexOf(AppConstants.FUTURE_PAYMENTS_SM) !== -1)) || this.futurePaymentFaqIds.indexOf(currentPage.toUpperCase()) !== -1)
    ) {
      this.FAQs = this.internalFaqService.addAdditionalQuestions(this.FAQs);
    }
    return this.FAQs;
  };

  public blazeError = (error) => {
    //console.log('***Blaze Error :( :( ***');
    //console.error(error);
  };
}
