import {GbBase} from './gb-base';

export class GbMudi extends GbBase {

  getUrls() {
    return {
      getCoverages: this.getBaseUrl() + 'gb-mudi-service/mudi/v1/coverages?' + this.getClientId('auth'), //'http://lad1jbmhd2001.thehartford.com:20300/gb-mudi-service/mudi/v1/getcoverages',
      geVettingPartyRefs: this.getBaseUrl() + 'gb-mudi-service/mudi/v1/vettingPartyRef?' + this.getClientId('auth'), //will be removed
      getUserDetailsURL: this.getBaseUrl() + 'gb-mudi-service/mudi/v1/user?' + this.getClientId('auth'),
      getUpdateSiteWideTCURL: this.getBaseUrl() + 'gb-mudi-service/mudi/v1/termsAndConditions?' + this.getClientId('auth'),
      verifyTextingTermsAndConditionsURL: this.getBaseUrl() + 'gb-mudi-service/mudi/v1/verifyTextingTermsAndConditions?' + this.getClientId('auth'),
      UpadateTextingTermsAndConditionsURL: this.getBaseUrl() + 'gb-mudi-service/mudi/v1/updateTextingTermsAndConditions?' + this.getClientId('auth'),
      UpdateEmailAddrURL: this.getBaseUrl() + 'gb-mudi-service/mudi/v1/emailAddress?' + this.getClientId('auth'),
      //self Linking
      getLinkTypeURL:  this.getBaseUrl() + 'gb-mudi-service/mudi/v1/linkType?' + this.getClientId('auth'),
      phaAvailabilityURL:  this.getBaseUrl() + 'gb-mudi-service/mudi/v1/checkPHAAvailability?' + this.getClientId('auth'),
      updateNotificationLog:  this.getBaseUrl() + 'gb-mudi-service/mudi/v1/notificationLogPreferences?' + this.getClientId('auth'),
    }
  }
}

