import { Injectable } from '@angular/core';
import {ResponseErrorHandlerService} from '@shared/services/response-error-handler.service';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {env} from '@env/environment-loader';
import {AppLabelConstants} from '@shared/constants/appLabel.constants';
import { Observable, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { IPflTransition } from '../models/intake-content.model';
//import { throwError } from 'rxjs/internal/observable/throwError';

@Injectable()
export class PFLTransitionService {

  constructor(private errorHandler: ResponseErrorHandlerService,
              private httpClient: HttpClient) {
  }

  public pflTransitionFunction( personId: string): Observable<IPflTransition>  {
    return this.httpClient.get(env.thaaWKABPortalService.pflbenefits + '&personId=' + personId,
      {
        headers: new HttpHeaders()
          .set(AppLabelConstants.CONTENT_TYPE_KEY, AppLabelConstants.APPLICATION_KEY)
      }).pipe(map(
      (response: IPflTransition) => {
        if (!this.errorHandler.isServiceResponseHasError(response)) {
          return response;
        } else {
          throw response;
        }
      }
    ), catchError((error: any) => this.handleError(error)));
  }

  handleError(error: Response) {
    return throwError(error);
  }

}
