import {Component, Input, OnInit} from '@angular/core';
import {Router} from '@angular/router';
import {ContentService} from '../../../shared/services/content.service';
import {AppLabelConstants} from '../../../shared/constants/appLabel.constants';
import {SessionConstants} from '../../../shared/constants/session.constants';
import {AppConstants} from '../../../shared/constants/app.constants';
import {StorageManagementService} from '../../../shared/services/storage-management.service';
import {ModalService} from '../../../shared/services/modal.service';

@Component({
  selector: 'app-confirm-maternity-model',
  templateUrl: 'confirm-maternity-model.component.html',
  styleUrls: ['confirm-maternity-model.component.scss']
})
export class ConfirmMaternityModelComponent implements OnInit {
  @Input() currentClaim;
  @Input() hideButton;
  @Input() confirmedDeliveryDate;
  @Input() confirmedLastDayWorkDate;
  @Input() babyDeathIndicator;
  @Input() maternity_Action_Message = '';
   returnhome;
   returnThanks;
   pageId: string;
   onlinesubstatus: string;
  public hideMaternityModel = true;
   hideLDWButton = true;
  public claimType = '';
  public claimStatus = '';
   estimatedDeliveryDate;
   servicedate;
  public claimOnlineStatusCode;
  public claimOnlineSubStatusCode;
   confirmDeliveryBlock = false;
  public appConstsnts = AppConstants;
  public appLabelConstant = AppLabelConstants;

  constructor(private router: Router, private contentService: ContentService,
              private storageManagementService: StorageManagementService, private modalService: ModalService) {
  }

  ngOnInit() {
    this.returnhome = sessionStorage.getItem(SessionConstants.RETURN_HOME);
    this.returnThanks = sessionStorage.getItem(SessionConstants.RETURN_THANKS);
    this.maternityModelCheck();
    this.claimType = this.currentClaim.claimSubType;
    this.claimStatus = this.currentClaim.claimOnlineStatus;
    this.storageManagementService.setSessionData(SessionConstants.CUR_CLAIM, this.currentClaim);
  }

  maternityModelCheck() {

    let timeDiff = null;

    if (this.currentClaim) {
      this.babyDeathIndicator = this.currentClaim.babyDeathIndicator;
      this.confirmedDeliveryDate = this.currentClaim.confirmedDeliveryDate;
      this.confirmedLastDayWorkDate = this.currentClaim.confirmedLastDayWorkDate;
      this.estimatedDeliveryDate = this.currentClaim.estimatedDeliveryDate;
      this.claimOnlineStatusCode = this.currentClaim.claimOnlineStatusCode;
      this.claimOnlineSubStatusCode = this.currentClaim.claimOnlineSubStatusCode;

      if (this.estimatedDeliveryDate) {
      this.estimatedDeliveryDate = this.estimatedDeliveryDate.replace(/^(\d{1,2}\/)(\d{1,2}\/)(\d{4})$/, '$2$1$3');
      const currentdate = new Date();
      this.servicedate = new Date(this.estimatedDeliveryDate.split('/')[2], this.estimatedDeliveryDate.split('/')[1] - 1, this.estimatedDeliveryDate.split('/')[0]);
      timeDiff = currentdate.getTime() - this.servicedate.getTime();
      }
      const diffDays = timeDiff / (1000 * 3600 * 24);
      if (diffDays > -14) {
       // this.confirmDeliveryBlock = true;
        if ((this.claimOnlineStatusCode !== AppConstants.COS_AWAITING_DELIVERY_CD_01) && (this.claimOnlineSubStatusCode !== AppConstants.COSS_AWAITING_DELIVERY_CD_01) && this.confirmedDeliveryDate === null) {
          this.currentClaim.orangeIconMissingInfo = true;
          this.currentClaim.showProgressIcon = false;
        } else if ((this.claimOnlineStatusCode !== AppConstants.COS_AWAITING_DELIVERY_CD_01) && (this.claimOnlineSubStatusCode !== AppConstants.COSS_AWAITING_DELIVERY_CD_01) && this.confirmedDeliveryDate !== null) {
          this.currentClaim.orangeIconMissingInfo = false;
          this.currentClaim.showProgressIcon = true;
        }
      } else {
          this.currentClaim.showProgressIcon = true;
      }
    }
    if (this.confirmedDeliveryDate && this.confirmedLastDayWorkDate) {
      this.hideMaternityModel = false;
    } else if (this.confirmedLastDayWorkDate && !this.confirmedDeliveryDate && this.babyDeathIndicator) {
      this.hideMaternityModel = false;
    } else if (this.confirmedLastDayWorkDate && !this.confirmedDeliveryDate) {
      this.hideLDWButton = false;
    } else if (!this.confirmedDeliveryDate && !this.confirmedLastDayWorkDate) {
      this.hideLDWButton = true;
      this.hideMaternityModel = true;
    } else if (!this.confirmedDeliveryDate && !this.confirmedLastDayWorkDate && !this.hideButton) {
      this.hideLDWButton = false;
      this.hideMaternityModel = true;
    }
  }

  naviagteToDelivery() {
    this.storageManagementService.setSessionObject(SessionConstants.CUR_CLAIM, this.currentClaim);
    this.storageManagementService.setSessionData(SessionConstants.COV_CATEGORY, this.currentClaim.coverageCategory);
    if (this.currentClaim.sourceSystemId === AppConstants.SOURCE_SYS_ID_DCS) {
      this.router.navigate([AppLabelConstants.URL_CONFIRM_DELIVERY])
    } else {
      this.router.navigate([AppLabelConstants.URL_CONFIRM_DELIVERY_THAA]);
    }
  }

  openStillWorkingModal() {
    this.modalService.openModal(AppConstants.STILL_WORKING_MODAL);
  }

 naToLdw() {
if (this.currentClaim && this.currentClaim.sourceSystemId === this.appConstsnts.SOURCE_SYS_ID_DCS) {
  this.storageManagementService.setSessionData(SessionConstants.COV_CATEGORY, this.currentClaim.coverageCategory);
  this.storageManagementService.setSessionObject(SessionConstants.CUR_CLAIM, this.currentClaim);
  this.router.navigate([AppLabelConstants.URL_LAST_DAY_WORKED])
} else if (this.currentClaim && this.currentClaim.sourceSystemId === this.appConstsnts.SOURCE_SYS_ID_AA) {
  this.router.navigate([AppLabelConstants.URL_LAST_DAY_WORKED_THAA])
}
}
}
