/**
 * Created by AC12487 on 2/22/2018.
 */
export const leaveLibraryForm = {
  id: '1444626432199',
  name: 'Leave Library Form Page',
  forms:    [
  {
    id: '1444636394297',
    key: 'FL_08_Body',
    displayName: 'GB_THAW - FL_08_Form',
    description: 'Certification of Health Care Provider - Family Member\'s Serious Health Condition (LC-7445)',
    generic:          {
      title: 'Family_Health_Condition-7445',
      url: '/cm/files/1444636391401.pdf',
      uniqueFileName: 'Family_Health_Condition-7445',
      description: '',
      customFileName: '',
      keyword: ''
    },
    onshore:          {
      title: '',
      url: '',
      uniqueFileName: '',
      description: '',
      customFileName: '',
      keyword: ''
    },
    leave:          {
      title: '',
      url: '',
      uniqueFileName: '',
      description: '',
      customFileName: '',
      keyword: ''
    },
    parentId: '1444626432199'
  },
  {
    id: '1444636397093',
    key: 'FL_24_Body',
    displayName: 'GB_THAW - FL_24_Form',
    description: 'Certification for Serious Injury or Illness of a Veteran for Military Caregiver Leave (LC-7589)',
    generic:          {
      title: '',
      url: '',
      uniqueFileName: '',
      description: '',
      customFileName: '',
      keyword: ''
    },
    onshore:          {
      title: '',
      url: '',
      uniqueFileName: '',
      description: '',
      customFileName: '',
      keyword: ''
    },
    leave:          {
      title: 'hart_lc7589_hart_ser_ill_mil_vet_5',
      url: '/cm/files/1444636392676.pdf',
      uniqueFileName: 'hart_lc7589_hart_ser_ill_mil_vet_5',
      description: '',
      customFileName: '',
      keyword: ''
    },
    parentId: '1444626432199'
  },
  {
    id: '1444636396230',
    key: 'FL_14_Body',
    displayName: 'GB_THAW - FL_14_Form',
    description: 'ADA Medical Assessment Form (LC-7630)',
    generic:          {
      title: 'ADA_Medical_Assessment-7630',
      url: '/cm/files/1444636391976.pdf',
      uniqueFileName: 'ADA_Medical_Assessment-7630',
      description: '',
      customFileName: '',
      keyword: ''
    },
    onshore:          {
      title: '',
      url: '',
      uniqueFileName: '',
      description: '',
      customFileName: '',
      keyword: ''
    },
    leave:          {
      title: '',
      url: '',
      uniqueFileName: '',
      description: '',
      customFileName: '',
      keyword: ''
    },
    parentId: '1444626432199'
  },
  {
    id: '1444636397005',
    key: 'FL_23_Body',
    displayName: 'GB_THAW - FL_23_Form',
    description: 'Certification for Serious Injury or Illness of a Veteran for Military Caregiver Leave (LC-7589)',
    generic:          {
      title: 'lc7589_lms_ser_ill_mil_vet',
      url: '/cm/files/1444636392576.pdf',
      uniqueFileName: 'lc7589_lms_ser_ill_mil_vet',
      description: '',
      customFileName: '',
      keyword: ''
    },
    onshore:          {
      title: '',
      url: '',
      uniqueFileName: '',
      description: '',
      customFileName: '',
      keyword: ''
    },
    leave:          {
      title: '',
      url: '',
      uniqueFileName: '',
      description: '',
      customFileName: '',
      keyword: ''
    },
    parentId: '1444626432199'
  },
  {
    id: '1444636396644',
    key: 'FL_17_Body',
    displayName: 'GB_THAW - FL_17_Form',
    description: 'CA Pregnancy Disability Leave Certification of Health Care Provider (LC-7587)',
    generic:          {
      title: 'lc7587_ee_cert_hcp_lms',
      url: '/cm/files/1444636392176.pdf',
      uniqueFileName: 'lc7587_ee_cert_hcp_lms',
      description: '',
      customFileName: '',
      keyword: ''
    },
    onshore:          {
      title: '',
      url: '',
      uniqueFileName: '',
      description: '',
      customFileName: '',
      keyword: ''
    },
    leave:          {
      title: '',
      url: '',
      uniqueFileName: '',
      description: '',
      customFileName: '',
      keyword: ''
    },
    parentId: '1444626432199'
  },
  {
    id: '1444636396772',
    key: 'FL_20_Body',
    displayName: 'GB_THAW - FL_20_Form',
    description: 'Certification of Qualifying Exigency For Military Family Leave (LC-7447)',
    generic:          {
      title: 'Qualifying_Exigency-7447',
      url: '/cm/files/1444636392376.pdf',
      uniqueFileName: 'Qualifying_Exigency-7447',
      description: '',
      customFileName: '',
      keyword: ''
    },
    onshore:          {
      title: '',
      url: '',
      uniqueFileName: '',
      description: '',
      customFileName: '',
      keyword: ''
    },
    leave:          {
      title: '',
      url: '',
      uniqueFileName: '',
      description: '',
      customFileName: '',
      keyword: ''
    },
    parentId: '1444626432199'
  },
  {
    id: '1444636397271',
    key: 'FL_27_Body',
    displayName: 'GB_THAW - FL_27_Form',
    description: 'Certification for Serious Injury or Illness of a Service member For Military Family Leave (LC-7448)',
    generic:          {
      title: '',
      url: '',
      uniqueFileName: '',
      description: '',
      customFileName: '',
      keyword: ''
    },
    onshore:          {
      title: '',
      url: '',
      uniqueFileName: '',
      description: '',
      customFileName: '',
      keyword: ''
    },
    leave:          {
      title: 'hart_lc7448_lms_ser_ill_mil_fam_lv_5',
      url: '/cm/files/1444636392876.pdf',
      uniqueFileName: 'hart_lc7448_lms_ser_ill_mil_fam_lv_5',
      description: '',
      customFileName: '',
      keyword: ''
    },
    parentId: '1444626432199'
  },
  {
    id: '1444636396919',
    key: 'FL_21_Body',
    displayName: 'GB_THAW - FL_21_Form',
    description: 'Certification of Qualifying Exigency For Military Family Leave (LC-7447)',
    generic:          {
      title: '',
      url: '',
      uniqueFileName: '',
      description: '',
      customFileName: '',
      keyword: ''
    },
    onshore:          {
      title: '',
      url: '',
      uniqueFileName: '',
      description: '',
      customFileName: '',
      keyword: ''
    },
    leave:          {
      title: 'hart_lc7447_hart_crt_qual_mil_exi_4',
      url: '/cm/files/1444636392476.pdf',
      uniqueFileName: 'hart_lc7447_hart_crt_qual_mil_exi_4',
      description: '',
      customFileName: '',
      keyword: ''
    },
    parentId: '1444626432199'
  },
  {
    id: '1444636390427',
    key: 'FL_05_Body',
    displayName: 'GB_THAW - FL_05_Form',
    description: 'Certification of Health Care Provider - Employee\'s Serious Health Condition (LC-7446)',
    generic:          {
      title: 'Employee_Health_Condition-7446',
      url: '/cm/files/1444636390451.pdf',
      uniqueFileName: 'Employee_Health_Condition-7446',
      description: '',
      customFileName: '',
      keyword: ''
    },
    onshore:          {
      title: '',
      url: '',
      uniqueFileName: '',
      description: '',
      customFileName: '',
      keyword: ''
    },
    leave:          {
      title: '',
      url: '',
      uniqueFileName: '',
      description: '',
      customFileName: '',
      keyword: ''
    },
    parentId: '1444626432199'
  },
  {
    id: '1444636396153',
    key: 'FL_12_Body',
    displayName: 'GB_THAW - FL_12_Form',
    description: 'Certification of Health Care Provider for Family Member\'s Serious Health Condition (LC 7675)',
    generic:          {
      title: 'cfra-chcp-famshc - 7675',
      url: '/cm/files/1444636391876.pdf',
      uniqueFileName: 'cfra-chcp-famshc - 7675',
      description: '',
      customFileName: '',
      keyword: ''
    },
    onshore:          {
      title: '',
      url: '',
      uniqueFileName: '',
      description: '',
      customFileName: '',
      keyword: ''
    },
    leave:          {
      title: '',
      url: '',
      uniqueFileName: '',
      description: '',
      customFileName: '',
      keyword: ''
    },
    parentId: '1444626432199'
  },
  {
    id: '1444636394511',
    key: 'FL_09_Body',
    displayName: 'GB_THAW - FL_09_Form',
    description: 'Certification of Health Care Provider - Family Member\'s Serious Health Condition (LC-7445)',
    generic:          {
      title: '',
      url: '',
      uniqueFileName: '',
      description: '',
      customFileName: '',
      keyword: ''
    },
    onshore:          {
      title: '',
      url: '',
      uniqueFileName: '',
      description: '',
      customFileName: '',
      keyword: ''
    },
    leave:          {
      title: 'hart_lc7445_fam_mem_srs_hlth_cond_lms_7',
      url: '/cm/files/1444636391676.pdf',
      uniqueFileName: 'hart_lc7445_fam_mem_srs_hlth_cond_lms_7',
      description: '',
      customFileName: '',
      keyword: ''
    },
    parentId: '1444626432199'
  },
  {
    id: '1444636393750',
    key: 'FL_06_Body',
    displayName: 'GB_THAW - FL_06_Form',
    description: 'Certification of Health Care Provider - Employee\'s Serious Health Condition (LC-7446)',
    generic:          {
      title: '',
      url: '',
      uniqueFileName: '',
      description: '',
      customFileName: '',
      keyword: ''
    },
    onshore:          {
      title: '',
      url: '',
      uniqueFileName: '',
      description: '',
      customFileName: '',
      keyword: ''
    },
    leave:          {
      title: 'hart_lc7446_ee_ser_hlth_cond_lms_7',
      url: '/cm/files/1444636391040.pdf',
      uniqueFileName: 'hart_lc7446_ee_ser_hlth_cond_lms_7',
      description: '',
      customFileName: '',
      keyword: ''
    },
    parentId: '1444626432199'
  },
  {
    id: '1444636397183',
    key: 'FL_26_Body',
    displayName: 'GB_THAW - FL_26_Form',
    description: 'Certification for Serious Injury or Illness of a Service member For Military Family Leave (LC-7448)',
    generic:          {
      title: 'lc7448_lms_ser_ill_mil_fam_lv',
      url: '/cm/files/1444636392776.pdf',
      uniqueFileName: 'lc7448_lms_ser_ill_mil_fam_lv',
      description: '',
      customFileName: '',
      keyword: ''
    },
    onshore:          {
      title: '',
      url: '',
      uniqueFileName: '',
      description: '',
      customFileName: '',
      keyword: ''
    },
    leave:          {
      title: '',
      url: '',
      uniqueFileName: '',
      description: '',
      customFileName: '',
      keyword: ''
    },
    parentId: '1444626432199'
  },
  {
    id: '1444636396321',
    key: 'FL_15_Body',
    displayName: 'GB_THAW - FL_15_Form',
    description: 'ADA Medical Assessment Form (LC-7630)',
    generic:          {
      title: 'ADA_Medical_Assessment-7630',
      url: '/cm/files/1444636392162.pdf',
      uniqueFileName: 'ADA_Medical_Assessment-7630',
      description: '',
      customFileName: '',
      keyword: ''
    },
    onshore:          {
      title: '',
      url: '',
      uniqueFileName: '',
      description: '',
      customFileName: '',
      keyword: ''
    },
    leave:          {
      title: '',
      url: '',
      uniqueFileName: '',
      description: '',
      customFileName: '',
      keyword: ''
    },
    parentId: '1444626432199'
  },
  {
    id: '1444636396066',
    key: 'FL_11_Body',
    displayName: 'GB_THAW - FL_11_Form',
    description: 'Certification of Health Care Provider for Family Member\'s Serious Health Condition (LC 7675)',
    generic:          {
      title: 'cfra-chcp-famshc - 7675',
      url: '/cm/files/1444636391776.pdf',
      uniqueFileName: 'cfra-chcp-famshc - 7675',
      description: '',
      customFileName: '',
      keyword: ''
    },
    onshore:          {
      title: '',
      url: '',
      uniqueFileName: '',
      description: '',
      customFileName: '',
      keyword: ''
    },
    leave:          {
      title: '',
      url: '',
      uniqueFileName: '',
      description: '',
      customFileName: '',
      keyword: ''
    },
    parentId: '1444626432199'
  },
  {
    id: '1444636396724',
    key: 'FL_18_Body',
    displayName: 'GB_THAW - FL_18_Form',
    description: 'CA Pregnancy Disability Leave Certification of Health Care Provider (LC-7587)',
    generic:          {
      title: '',
      url: '',
      uniqueFileName: '',
      description: '',
      customFileName: '',
      keyword: ''
    },
    onshore:          {
      title: '',
      url: '',
      uniqueFileName: '',
      description: '',
      customFileName: '',
      keyword: ''
    },
    leave:          {
      title: 'hart_lc7587_ee_cert_hcp_lms_5',
      url: '/cm/files/1444636392276.pdf',
      uniqueFileName: 'hart_lc7587_ee_cert_hcp_lms_5',
      description: '',
      customFileName: '',
      keyword: ''
    },
    parentId: '1444626432199'
  }
]
};
