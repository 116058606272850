/**
 * This is the major model of all the document related objects
 * We will be able to have all the interfacs/classes necessary to make the document processes work
 * We will also be able to house the static properties for max sizes/file types as necessary
 * */

import {ClaimDocument} from './claim-document.model';
import {AppLabelConstants} from '@shared/constants/appLabel.constants';
import {ErrorConstants} from '@shared/constants/error.constants';

/**
 * Constants to be used for documents throughout the app
 */
export const FILE_LIMITS = {
  process: 'uploading',
  individual: 10485760,
  min: 0,
  merged: 52428800,
  bulk: 262144000,
  allowedDCSFiles: ['jpg', 'bmp', 'txt', 'pdf', 'rtf', 'gif', 'tif', 'tiff', 'jpeg', 'png', 'doc', 'docx', 'xls', 'xlsx', 'ppt', 'pptx'] ,
  mergeableFiles: ['jpeg', 'jpg', 'gif', 'png', 'bmp', 'tif', 'tiff', 'pdf'],
  allowedTHAAFiles: ['jpg', 'bmp', 'pdf', 'gif', 'tif', 'tiff', 'jpeg', 'png']
};

const previewImages: string[] = [
  'pdf'
];

/**
 * The extended file object that holds the original file but gives us additional functionality
 * We can store the reason the file is invalid
 * We can respond if the held file is also qualifying to merge
 */
export class ModularFile  {
  private file: File;
  private errorType: string[];
  private reason: string[];
  public encodedContent: string;
  public enclosedFileExt: string;
  private previewImage: string;

  constructor(file: File, errorType: string[], encodedContent?: string) {
    this.file = file;
    this.errorType = errorType;
    const spli = file.name.split('.');
    this.enclosedFileExt = '.' + spli[spli.length - 1];
    if (encodedContent) {
      this.encodedContent = encodedContent;
    }
    this.setPreviewIcon(spli[spli.length - 1]);
  }

  private setPreviewIcon(ext: string) {
    if (previewImages.lastIndexOf(ext) !== -1) {
      this.previewImage = 'pdf';
    } else {
      this.previewImage = 'image';
    }
  }

  getPreviewImage(): string {
    return this.previewImage;
  }

  getErrorTypes(): string[] {
    return this.errorType;
  }

  getFile(): File {
    return this.file;
  }

  getReason(): string [] {
    if (this.errorType && this.errorType.length > 0) {
      this.reason = [];
      for (let i = 0; i < (this.errorType.length > 1 ? this.errorType.length - 1 : this.errorType.length); i++) {
        if (this.errorType[i].toLowerCase() === 'size') {
          this.reason.push(ErrorConstants.MAXIMUM_FILE_SIZE_EXCEEDED_ERROR);
        } else if (this.errorType[i].toLowerCase() === 'min') {
          this.reason.push(ErrorConstants.PROBLEM_SELECTING_FILE_ERROR);
        } else if (this.errorType[i].toLowerCase() === 'type') {
          this.reason.push(ErrorConstants.INVALID_FILE_FORMAT_ERROR);
        } else if (this.errorType[i].toLowerCase() === 'virus') {
          this.reason.push(ErrorConstants.MALICIOUS_CONTENT_ERROR_MSG);
        } else if (this.errorType[i].toLowerCase() === 'NotTHAAtype'.toLowerCase()) {
          this.reason.push(ErrorConstants.UNSUPPORTED_MICROSOFT_FILE_FORMAT);
        } else if (this.errorType[i].toLowerCase() === 'txt'.toLowerCase()) {
          this.reason.push(ErrorConstants.INVALID_TXT_FILE_MSG);
        } else if (this.errorType[i].toLowerCase() === 'rtf'.toLowerCase()) {
          this.reason.push(ErrorConstants.INVALID_RTF_FILE_MSG);
        }
      }
      return this.reason;
    } else {
      return [];
    }
  }

  isValid(): boolean {
    return (this.errorType === null || this.errorType.length === 0);
  }

  isMergeable(): boolean {
    return FILE_LIMITS.mergeableFiles.lastIndexOf((this.file.name.substr(this.file.name.lastIndexOf('.') + 1)).toLowerCase()) !== -1;
  }
}

/**
 *  This stores the files we are going to be uploading and gives us easy functions to add/remove/get
 */
export class UploadingFiles {
  private modFiles: ModularFile[];

  constructor(modFiles: ModularFile[]) {
    if (modFiles === null) {
      this.modFiles = [];
    } else {
      this.modFiles = modFiles;
    }
  }

  addFile(file: File) {
    this.modFiles.push(new ModularFile(file, null));
  }

  addInvalidFile(file: File, errorType: string[]) {
    this.modFiles.push(new ModularFile(file, errorType));
  }

  getValidFiles(): ModularFile[] {
    return this.modFiles.filter(function(e) {
      return e.isValid();
    });
  }

  getInvalidFiles(): ModularFile[] {
    return this.modFiles.filter(function(e) {
      return !(e.isValid());
    });
  }

  isAFileInvalid(): boolean {
    const inValidFile = this.modFiles.find(function(e) {
      return !(e.isValid());
    });
    //console.log("invalid file");
    return !(inValidFile === undefined || inValidFile === null);
  }

  getAllFiles(): ModularFile[] {
    return this.modFiles;
  }

  resetFiles() {
    this.modFiles = [];
  }
}

export interface DocumentumSearch extends Response {
  documents: ClaimDocument[]
}


export class DocumentInfo {
  docId: string;
  docName: string;
  date: string;

  constructor(docId: string, docName: string, date: string) {
    this.docId = docId;
    this.docName = docName;
    this.date = date;
  }
}
