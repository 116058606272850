export const MOCK_CONTINUOUS_RAE = {
  SummaryTimeList : [

    {
      SrNo: 0,
      TransactionNumber: '123654',
      DatesofAbsence: null,
      Hours: null,
      Minutes: null,
      AbsenceType: null,
      response: 'Success',
      responseCode: '001'
    }

  ],
  responseCode: '001',
  responseReason: 'success'
}

