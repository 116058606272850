import {AfterViewInit, Directive, ElementRef} from '@angular/core';
import {TealiumDataService} from '../services/tealium/tealium-data.service';
import {SessionService} from '../services/tealium/session.service';
import {Subject, Subscription } from 'rxjs';
import {debounceTime } from 'rxjs/operators';
import {CustomEventService} from '../services/tealium/custom-event.service';

@Directive({
  selector: '[data-dl], [attr.data-dl]',
})
export class LinkDirective implements AfterViewInit {
  eventInfo: ElementRef;
  inputValueChanged: Subject<any> = new Subject<any>();
  sub: Subscription = null;

  constructor(private customEventService: CustomEventService, private tealiumdatasvc: TealiumDataService, el: ElementRef, private sessionService: SessionService) {
    this.eventInfo = el;
  }
  //Setting up event data based on types of events
  trackEvent(elementAttrs, event) {
    let eventID = '';
    let eventvalue = '';
    let isHukInput = false;
    //specific scenarios which should not be trackedyolo
    let dontTrack = false;
    try {
      const element = event.target;
      let elemType = event.target.localName;
      if (elemType === 'input') {
        /*catch case where type is tel, this is needed for logic
          on the payment page but raises issues in tealium*/
        if (element.type === 'tel') {
          elemType = 'Input Field Click - (text)';
        } else {
          elemType = 'Input Field Click - (' + element.type + ')';
        }
        eventID = element.id;
        eventvalue = element.value;
      }
      if (elemType === 'a' || elemType === 'div') {
        elemType = 'Link Click';
        eventID = element.textContent.trim();
        eventvalue = element.href || window.location.href;
      }
      if (elemType === 'button') {
        elemType = 'Button Click';
        eventID = element.id;
        eventvalue = element.textContent.trim();
      }
      if (elemType === 'img') {
        elemType = 'Image Click';
        eventID = element.id;
        eventvalue = element.alt;
      }
      if (elemType === 'select') {
        elemType = 'Select Click - (' + element.type + ')';
        eventID = element.id;
        if (element.type === 'select-multiple') {
          for (let i = 0; i < element.options.length; i++) {
            if (element.options[i].selected) {
              eventvalue += element.options[i].textContent.trim() + '|';
            }
          }
        } else {
          eventvalue = (element.options[element.selectedIndex]).textContent.trim();
        }

      }
      if (elemType === 'huk-button') {
        elemType = 'Button Click';
        eventID = element.hukid;
        eventvalue = (element.text)?.trim();
      }
      if (elemType === 'huk-input-field') {
        isHukInput = true;
        elemType = 'Input Field Click - (text)';
        eventID = element.hukid;
        eventvalue = top.location.href;
        const inputClasses = element.getAttribute('class');
        if (inputClasses?.includes('ng-pristine') || inputClasses?.includes('ng-invalid')) {
          dontTrack = false;
        }
      }
      if (elemType === 'huk-select') {
        elemType = 'Select Click - (select-one)';
        eventID = element.hukid;
        eventvalue = (element?.shadowRoot?.activeElement?.value)?.trim() || (element.value)?.trim();
      }
      if (elemType === 'huk-radio-button' || elemType === 'huk-radio') {
        elemType = 'Input Field Click - (radio)';
        eventID = (element.grouplabel)?.replace(/\s/g, '-');
        eventvalue = (element.value)?.trim();
      }
      if (elemType === 'huk-checkbox') {
        elemType = 'Input Field Click - (checkbox)';
        eventID = (element.label)?.replace(/\s/g, '-');
        eventvalue = element.value ? 'true' : 'false';
      }
      if(elemType === 'huk-datepicker'){
        elemType = 'Input Field Click - (text)';
        eventID = element.hukid ?? (element.label)?.replace(/\s/g, '-');
        eventvalue = (element.value)?.trim();
      }
      if (elemType === 'huk-tabs') {
        elemType = 'Link Click';
        eventID = element.activeTabId;
        eventvalue = top.location.href;
      }
      if (elementAttrs.event_type && elementAttrs.event_type === 'Social Media') {
        eventvalue = element.getAttribute('data-name');
        elemType = 'Button Click';
        eventID = element.id;
      }
      if (elementAttrs.event_id && elementAttrs.event_id !== '') {
        eventID = elementAttrs.event_id;
      }
      if (elementAttrs.event_value && elementAttrs.event_value !== '') {
        eventvalue = elementAttrs.event_value;
      }
      if (elementAttrs.event_type && elementAttrs.event_type !== '' && elementAttrs.event_type !== 'Banner Click' && elementAttrs.event_type !== 'radio' && elementAttrs.event_type !== 'Header Logo' && elementAttrs.event_type !== 'Social Media') {
        elemType = elementAttrs.event_type;
      }
      if (elementAttrs.event_type && elementAttrs.event_type === 'Header Logo') {
        eventvalue = element.getAttribute('data-v');
        elemType = 'Button Click';
        eventID = 'headerLogo';
      }
      if (elementAttrs.event_type && elementAttrs.event_type === 'Banner Click') {
        this.customEventService.customEvent('media alpha - click', 'Media Alpha', 'Banner Click', 'bannerClick', 'True');
        dontTrack = true;
      }
      //radio button case
     /* if(!isNullOrUndefined(elementAttrs.event_type) && elementAttrs.event_type === 'radio'){*/
      if (elementAttrs.event_type === 'radio') {
        eventID = element.id;
        eventID = eventID.replace(/[0-9]/g, '');
        eventID = eventID.replace(/_/g, '');
        elemType = 'Input Field Click - (radio)';
        if (event.target.localName === 'div') {
          eventvalue = event.target.children[0].innerHTML;
        } else if (event.target.localName === 'span') {
          eventvalue = event.target.innerHTML;
        }
        if (eventvalue === '') {
          dontTrack = true;
        }
      }
      const data = {
          event_parent: 'Page Tag',
          event_type: elemType,
          event_id: eventID,
          event_value: eventvalue,
       };
      //Don't track secondary/noisy click events that have no id or valueddd
      // if(eventID ==='' && eventvalue === ''){
      //   dontTrack = true;
      // }
      if (isHukInput) {
        if (this.sub === null) {
          this.sub = this.inputValueChanged.pipe(debounceTime(3000)).subscribe((val) => {
            this.tealiumdatasvc._appTrack(data, element, elementAttrs);
            this.sub.unsubscribe();
            this.sub = null;
          });
        }
        this.inputValueChanged.next('');
      } else {
        if (!dontTrack) {
          this.tealiumdatasvc._appTrack(data, element, elementAttrs);
        }
      }
    } catch (e) {
      //console.log('Tealium error : ', e.message);
    }

  }

  ngAfterViewInit() {
    //Capturing data-dl  event value
    let dl_eventValue = this.eventInfo?.nativeElement?.attributes['data-dl']?.nodeValue;
    if (dl_eventValue) {
      dl_eventValue = JSON.parse(this.eventInfo.nativeElement.attributes['data-dl'].nodeValue)?.event;
      //adding event listener to handle automatic events with data-dl
      this.eventInfo.nativeElement.addEventListener(dl_eventValue, this.onEventDetect.bind(this));
    }
  }

  onEventDetect(event) {
    // if you click on another element within an anchor tag, this will fire from the inner element, which won't have a data-dl
    let taggedObj = event.target || event.target;
    while (taggedObj.attributes['data-dl'] === undefined) {
      taggedObj = taggedObj.parentNode;
      if (taggedObj === undefined) {
        return;
      }
    }
    const dataAttr = JSON.parse(taggedObj.attributes['data-dl'].nodeValue);
    if (dataAttr.event) {
      this.trackEvent(dataAttr, event);
    }
  }
}




