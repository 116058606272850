/**
 * Created by AC12256 on 12/15/2017.
 */
import {Injectable} from '@angular/core';
import {systemDownAlert} from '../models/systemDownAlert.model';





@Injectable()

export class SysDownAlertMessageService {


  constructor() { }

  getAlertMsgs(isSysDown: boolean): systemDownAlert[] {
    /*if (env.mocking) {
      return SYSALERTMOCK ;
     }*/
    return null;
  }
}
