import {IContactInfo} from '@app/cxr/modules/intake/shared/models/contact-Info.model';

export const MOCK_INTAKE_CONTACT_INFO: IContactInfo = {
  contactInfo:
    {
      firstName: 'Jay',
      middleInitial: 'Q',
      lastName: 'Cutler',
      suffix: 'Jr',
      addressOne: 'Dummy Address One',
      addressTwo: '',
      city: 'Vernon',
      state: 'CT',
      stateName: 'Connecticut',
      zipCode: '06023'
    }
  ,
  responseCode: '001',
  responseReason: 'Success'
};
