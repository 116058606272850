/**
 * Created by AC12326 on 8/8/2017.
 */
export const CONTACTS = [
]

export const DisabilityClaimContacts = {
  disabilityClaimOfficeContacts:    [
    {
      claimEventId: '100023',
      officeId: '16',
      phoneNumber: '1-866-945-7781',
      fax: '1-877-431-8901',
      address: 'The Hartford,PO Box 14303,Lexington, KY 40512-4303'
    },
    {
      claimEventId: '100063',
      officeId: '16',
      phoneNumber: '1-866-945-7781',
      fax: '1-877-431-8901',
      address: 'The Hartford,PO Box 14303,Lexington, KY 40512-4303'
    },
    {
      claimEventId: '100024',
      officeId: '16',
      phoneNumber: '1-800-549-6514',
      fax: '1-877-454-7217',
      address: 'The Hartford,PO Box 14305,Lexington, KY  40512-4305'
    },
    {
      claimEventId: '100025',
      officeId: '733',
      phoneNumber: '1-866-945-7781',
      fax: '1-877-431-8901',
      address: 'The Hartford,PO Box 14303,Lexington, KY 40512-4303'
    },
    {
      claimEventId: '100027',
      officeId: '5',
      phoneNumber: '1-866-945-7781',
      fax: '1-877-431-8901',
      address: 'The Hartford,PO Box 14303,Lexington, KY 40512-4303'
    },
    {
      claimEventId: '100028',
      officeId: '5',
      phoneNumber: '1-800-549-6514',
      fax: '1-877-454-7217',
      address: 'The Hartford,PO Box 14305,Lexington, KY  40512-4305'
    },
    {
      claimEventId: '100032',
      officeId: '16',
      phoneNumber: '1-866-945-7781',
      fax: '1-877-431-8901',
      address: 'The Hartford,PO Box 14303,Lexington, KY 40512-4303'
    },
    {
      claimEventId: '100030',
      officeId: '16',
      phoneNumber: '1-866-945-7781',
      fax: '1-877-431-8901',
      address: 'The Hartford,PO Box 14303,Lexington, KY 40512-4303'
    },
    {
      claimEventId: '100031',
      officeId: '733',
      phoneNumber: '1-866-945-7781',
      fax: '1-877-431-8901',
      address: 'The Hartford,PO Box 14303,Lexington, KY 40512-4303'
    },
    {
      claimEventId: '100032',
      officeId: '16',
      phoneNumber: '1-866-945-7781',
      fax: '1-877-431-8901',
      address: 'The Hartford,PO Box 14303,Lexington, KY 40512-4303'
    },
    {
      claimEventId: '200043',
      officeId: '5',
      phoneNumber: '1-866-945-7781',
      fax: '1-877-431-8901',
      address: 'The Hartford,PO Box 14303,Lexington, KY 40512-4303'
    },
    {
      claimEventId: '100034',
      officeId: '5',
      phoneNumber: '1-866-945-7781',
      fax: '1-877-431-8901',
      address: 'The Hartford,PO Box 14303,Lexington, KY 40512-4303'
    },
    {
      claimEventId: '100035',
      officeId: '733',
      phoneNumber: '1-866-945-7781',
      fax: '1-877-431-8901',
      address: 'The Hartford,PO Box 14303,Lexington, KY 40512-4303'
    }
  ]
}
