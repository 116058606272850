import {IMyDpOptions} from 'mydatepicker';

export class AppLeaveConst {
  /** This section is mainly for LEAVE related constants */
  /** constants added by manish for leaves 9/18/2019*/

  /** Leave status start */
  static LEAVE_OPEN = 'open';
  static LEAVE_CLOSED = 'closed';
  static LEAVE_CANCELLED = 'cancelled';
  static NAV_TO = 'navTO';
  static WELCOME_MESSAGE = 'welcomeMessage';
  /** Leave status end */

  /** Leave Card Status */
  static LEAVE_STATUS_OPEN = 'Leave Open';
  static Leave_STATUS_CLOSED = 'Leave Closed';
  static LEAVE_STATUS_CANCELLED = 'Leave Cancelled';
  static NULLCHECK = null;
  /** Leave Card status */

  /** Leave Plan status start */
  static APPROVED = 'approved';
  static PENDED = 'pended';
  static DENIED =  'denied';
  static CANCELLED = 'cancelled';
  static ADMIN_CANCELLED = 'administrative cancel';
  static INACTIVE =  'inactive';
  static CLOSED = 'closed';
  /** Leave Plan status end */

  /**  SOURCE_SYS_ID*/
  static SOURCE_SYS_ID_DCS = 'DCS';
  static SOURCE_SYS_ID_AA = 'AA';

  /**  SOURCE_SYS_ID*/
  /**  CLAIM TYPES*/
  static EEOWN_ILLNESS = 'EEOwnIllness';
  static MATERNITY = 'MATERNITY';
  static CARE_FAMILY_MENBER = 'CareFamilyMember';
  static BONDING = 'Bonding';
  static OTHER = 'Other';
  static OTHERS = 'Other(s)';
  static ADA_ACCOMMODATION = 'DisabilityAccomodations';
  static LEAVE = 'Leave';
  static N0_PAPERWORK_RECEIVED = 'No Paperwork received';

  /**  CLAIM TYPES*/

  /** ADA leave*/
  static ADA = 'ADA';
  static NOT_AT_WORK = 'not at work';
  static ADA_TYPE = 'ADA Accommodation';
  static NUM_ACCOMMODATIONS = 'Accommodations'
  static ADASubReason = 'Unspecified Accommodation';
  static UPDATE_ACCOMODATION = 'Update Accommodation';
  static SEE_FULL_DETAILS = 'See Full Details';
  static FULL_DETAILS_ACCOMMODATION = 'See Full Details and Update Accommodation';
  static MANAGE_MY_ACCOMODATION = 'Manage My<br>Accommodation';
  static MUL_ACCOMMODATION = 'Multiple Accommodations';
  static TO_REQUEST_YOUR_NEW_CLAIM = 'To request a new accommodation,start your claim online'
  /** Leave Plan status ID */
  static APPROVED_ID = '1';
  static PENDED_ID = '3';
  static DENIED_ID = '2';

  static CARE_OF_FAMILY_MEMEBR = 'care of a family member';
}
export class AppConstants {

  /** Feature Toggle Constants */
  static IS_UPDATE_PROFILE_DISABLED = 'IsUpdateProfileDisabled';
  static IS_PAYMENT_DISABLED = 'IsPaymentsDisabled';
  static IS_START_A_NEW_CLAIM_POST_AUTH_DISABLED = 'IsNewClaimDisabledPostAuth';
  static IS_MISSING_INFO_DISABLED = 'IsMissingInfoDisabled';
  static IS_ADD_TIME_DISABLED = 'IsAddTimeDisabled';
  static IS_UPLOAD_DISABLED = 'IsDocumentUploadDisabled'
  static IS_PRE_AUTH_INTAKE_DISABLED = 'IsNewClaimDisabledPreAuth';
  static IS_RECAPTCHA_DISABLED = 'IsRecaptchaDisabledNewClaimPreAuth';
  static IS_ABSENCE_PLANNING_MATERNITY ="AbsencePlanningMaternity";
  static IS_NON_LOA_CALC_DISABLED = "LeaveDetailsFlowOldLoaCalc"


  /** This section contains constants releated to CLAIMS */

  /** constant of leave used in claim components*/
  static LEAVE_CANCELLED = 'cancelled';
  static INTERMITTENT = 'intermittent';


  /** constant of leave used in claim components */
  static NEW_DIS_DATE_HEADER = '10023800';
  static DIS_LAST_DAY_HEADER = '10023798';
  static CON_DELIVERY_HEADER = '10023790';
  static LAST_DAY_WORKED_HEADER = '10023792';
  static CON_SURGERY_HEADER = '10023794';
  static NEW_SURGERY_HEADER = '10023796';
  /**  CLAIM_ONLINE_STATUS AND CLAIM_ONLINE_STATUS_CODES*/
  static COS_AWAITING_DELIVERY = 'awaiting delivery';
  static COS_AWAITING_DELIVERY_CD_01 = '01';
  static COS_AWAITING_DISABILITY = 'awaiting disability';
  static COS_AWAITING_DISABILITY_CD_02 = '02';
  static COS_AWAITING_SURGERY_DETAILS = 'awaiting surgery';
  static COS_AWAITING_SURGERY_DETAILS2 = 'awaiting surgery details';
  static COS_AWAITING_SURGERY_DETAILS_CD_03 = '03';
  static COS_CLAIM_APPROVED = 'claim approved';
  static COS_CLAIM_APPROVED_CD_04 = '04';
  static COS_CLAIM_CLOSED = 'claim closed';
  static COS_CLAIM_SUBMITTED = 'claim submitted';
  static COS_CLOSED = 'closed';
  static COS_CLAIM_CLOSED_CD_05 = '05';
  static COS_CLAIM_DENIED = 'claim denied';
  static COS_CLAIM_DENIED_CD_06 = '06';
  static COS_CLAIM_RECEIVED = 'claim received';
  static COS_CLAIM_RECEIVED_CD_07 = '07';
  static COS_UNDER_REVIEW_BY_CLAIM_HANDLER = 'under review by claim handler';
  static COS_UNDER_REVIEW = 'under review';
  static COS_UNDER_REVIEW_BY_CLAIM_HANDLER_CD_08 = '08';
  static COS_AWAITING_PAID_FAMILY_LEAVE = 'awaiting paid family leave';
  static COS_AWAITING_PAID_FAMILY_LEAVE_CD_09 = '09';
  static COS_REP_WILL_REACH_OUT = 'rep will reach out';
  static COS_CD_16 = '16';
  static COS_CLAIM_CANCELLED_CD_00 = null;
  static REG_EXP_QUOTE = '&rsquo;';

  /**  CLAIM_ONLINE_STATUS AND CLAIM_ONLINE_STATUS_CODES*/

  /**  CLAIM_ONLINE_SUB_STATUS AND CLAIM_ONLINE_SUB_STATUS_CODES*/
  static COSS_AWAITING_DELIVERY = 'awaiting delivery';
  static COSS_AWAITING_DELIVERY_CD_01 = '01';
  static COSS_AWAITING_DISABILITY = 'awaiting disability';
  static COSS_AWAITING_DISABILITY_CD_02 = '02';
  static COSS_AWAITING_SURGERY_DETAILS = 'awaiting surgery';
  static COSS_AWAITING_SURGERY_DETAILS_CD_03 = '03';
  static COSS_BENEFIT_PAYMENTS_COMPLETE = 'benefit payments complete';
  static COSS_BENEFIT_PAYMENTS_COMPLETE_CD_04 = '04';
  static COSS_LAST_PAYMENT_SCHEDULED = 'last payment scheduled';
  static COSS_LAST_PAYMENT_SCHEDULED_CD_05 = '05';
  static COSS_PAYMENTS_IN_PROGRESS = 'payments in progress';
  static COSS_PAYMENTS_IN_PROGRESS_CD_06 = '06';
  static COSS_PAYMENTS_NOT_YET_STARTED = 'payments not yet started';
  static COSS_PAYMENTS_NOT_YET_STARTED_CD_07 = '07';
  static COSS_CLAIM_CLOSED = 'claim closed';
  static COSS_CLAIM_CLOSED_CD_08 = '08';
  static COSS_MISSING_INFORMATION_09 = 'missing information';
  static COSS_MISSING_INFORMATION_CD_09 = '09';
  static COSS_CLAIM_DENIED = 'claim denied';
  static COSS_CLAIM_DENIED_CD_10 = '10';
  static COSS_CLAIM_RECEIVED = 'claim received';
  static COSS_CLAIM_RECEIVED_CD_11 = '11';
  static COSS_IN_ADJUDICATION = 'in adjudication';
  static COSS_IN_ADJUDICATION_CD_12 = '12';
  static COSS_MISSING_INFORMATION_13 = 'missing information';
  static COSS_MISSING_INFORMATION_CD_13 = '13';
  static COSS_NYPFL_CLAIM_CD_14 = '14';
  static COSS_AWAITING_PAID_FAMILY_LEAVE = 'awaiting paid family leave';
  static COSS_AWAITING_PAID_FAMILY_LEAVE_CD_14 = '14';
  static COSS_CD_16 = '16';
  /**  CLAIM_ONLINE_SUB_STATUS AND CLAIM_ONLINE_SUB_STATUS_CODES*/

  /**  MISSING_INFO_SECT_DESC AND MISS_INFO_SECTCD*/
  static EMPLOYEE = 'employee';
  static EMPLOYEE_CD_01 = '01';
  static EMPLOYEE_CD_10023366 = '10023366';

  static EMPLOYER = 'employer';
  static EMPLOYER_CD_02 = '02';
  static EMPLOYER_CD_10023364 = '10023364';

  static PHYSICIAN = 'physician';
  static DOCTOR = 'doctor';
  static PHYSICIAN_CD_03 = '03';
  static PHYSICIAN_CD_10023362 = '10023362';
  /**  MISSING_INFO_SECT_DESC AND MISS_INFO_SECTCD*/

  /**  EXTENSION_OVRALL_STAT_DESC AND EXT_OVRALL_STAT_CD*/
  static EXTENSION_UNDER_REVIEW = 'extension under review';
  static EXTENSION_UNDER_REVIEW_CD_01 = '01';
  static EXTENSION_UNDER_REVIEW_CD_1 = '1';

  static EXTENSION_APPROVED = 'extension approved';
  static EXTENSION_APPROVED_CD_02 = '02';
  static EXTENSION_APPROVED_CD_2 = '2';

  static EXTENSION_DENIED = 'extension denied';
  static EXTENSION_DENIED_CD_03 = '03';
  static EXTENSION_DENIED_CD_3 = '3';

  static EXTENSION_CLOSED = 'extension closed';
  static EXTENSION_CLOSED_CD_04 = '04';
  static EXTENSION_CLOSED_CD_4 = '4';

  static EXTENSION_REQUESTED = 'extension requested';
  static EXTENSION_REQUESTED_CD_05 = '05';
  static EXTENSION_REQUESTED_CD_5 = '5';
  /**  EXTENSION_OVRALL_STAT_DESC AND EXT_OVRALL_STAT_CD*/

  /**  EXTENSION_DTLL_STAT_DESC AND EXT_DTLL_STAT_CD*/
  static EXT_MISSING_INFORMATION = 'missing information';
  static EXT_MISSING_INFO_CD_01 = '01';
  static EXT_MISSING_INFO_CD_1 = '1';

  static EXT_IN_ADJUDICATION = 'in adjudication';
  static EXT_IN_ADJUDICATION_CD_02 = '02';
  static EXT_IN_ADJUDICATION_CD_2 = '2';

  static EXT_UNDER_REVIEW_BY_A_NURSE = 'under review by nurse';
  static EXT_UNDER_REVIEW_BY_A_NURSE_CD_03 = '03';
  static EXT_UNDER_REVIEW_BY_A_NURSE_CD_3 = '3';

  static EXT_PARTIAL_APPROVAL = 'partial approval';
  static EXT_PARTIAL_APPROVAL_CD_04 = '04';
  static EXT_PARTIAL_APPROVAL_CD_4 = '4';

  static EXT_FULL_APPROVAL = 'full approval';
  static EXT_FULL_APPROVAL_CD_05 = '05';
  static EXT_FULL_APPROVAL_CD_5 = '5';

  static EXT_EXTENSION_DENIED = 'extension denied';
  static EXT_EXTENSION_DENIED_CD_06 = '06';
  static EXT_EXTENSION_DENIED_CD_6 = '6';

  static EXT_EXTENSION_REQUESTED = 'extension requested';
  static EXT_EXTENSION_REQUESTED_CD = '07';

  static EXT_EXTENSION_MISSING_INFO = 'missing info';
  static EXT_EXTENSION_MISSING_INFO_CD_08 = '08';
  static EXT_EXTENSION_MISSING_INFO_CD_8 = '8';

  /**  EXTENSION_DTLL_STAT_DESC AND EXT_DTLL_STAT_CD*/

  /** CLAIM_STATUS_AND_CLAIM_STATUS_CODES*/
  static CS_PENDING = 'pending';
  static CS_PENDING_CD_01 = '01';
  static CS_PENDING_CD_00 = '00';
  static CS_APPROVED = 'approved';
  static CS_APPROVED_CD_02 = '02';

  static CS_DENIED = 'denied';
  static CS_DENIED_CD_03 = '03';

  static CS_TERMINATE = 'terminated';
  static CS_TERMINATE_CD_04 = '04';

  static CS_REOPEN_TERMINATED = 'reopen terminated';
  static CS_REOPEN_TERMINATED_CD_05 = '05';

  static CS_PAPERLESS_INTAKE = 'paperless intake';
  static CS_PAPERLESS_INTAKE_CD_06 = '06';

  static CS_REOPENED_IN_ERROR = 'reopened in error';
  static CS_REOPENED_IN_ERROR_CD_07 = '07';

  static CS_FIRST_NOTICE = 'first notice';
  static CS_FIRST_NOTICE_CD_08 = '08';
  /** CLAIM_STATUS_AND_CLAIM_STATUS_CODES*/

  /**  THAA_CLAIM_STATUS_AND CODES*/
  static AA_CS_PEND = 'pend';
  static AA_CS_PEND_CD_773500 = '773500';

  static AA_CS_APPROVED = 'Approved';
  static AA_CS_APPROVED_CD_773501 = '773501';

  static AA_CS_DENIED = 'denied';
  static AA_CS_DENIED_CD_773502 = '773502';

  static AA_CS_TERMINATE = 'terminate';
  static AA_CS_TERMINATE_CD_773503 = '773503';

  static AA_CS_TRANSITIONED = 'transitioned';
  static AA_CS_TRANSITIONED_CD_ = '773504';

  static AA_CS_CANCELLED = 'cancelled';
  static AA_CS_CANCELLED_CD_773505 = '773505';

  static AA_CS_CLOSURE = 'closure';
  static AA_CS_CLOSURE_CD_773506 = '773506';
  /**  THAA_CLAIM_STATUS_AND CODES*/

  /**  RTW_STATUS_CODE*/
  static RTWS_CD_42001 = '42001';
  static RTWS_CD_42002 = '42002';
  static RTWS_CD_42003 = '42003';
  static RTWS_CD_42004 = '42004';
  static RTWS_CD_42006 = '42006';
  /**  RTW_STATUS_CODE*/

  /**  SOURCE_SYS_ID*/
  static SOURCE_SYS_ID_DCS = 'DCS';
  static SOURCE_SYS_ID_AA = 'AA';
  static SOURCE_SYS_CD_DCS = 1;
  static SOURCE_SYS_CD_DCS_ELE = 2;
  static SOURCE_SYS_CD_AA = 8;
  /**  SOURCE_SYS_ID*/

  /**  COVERAGE_CATEGORY*/
  static LTD = 'LTD';
  static WD = 'WD';
  static WD_NST = 'NST';
  static WD_STAT = 'STAT';
  static WD_SUPP = 'SUPP';
  static STD = 'STD';
  static PFL = 'PFL';
  static LIFE = 'LIFE';
  static PWD = 'PW';
  static FLEX = 'FLEX';
  /**  COVERAGE_CATEGORY*/
  /*Date label */
static LAST_DATE_WORKED = 'Last Date Worked';
static APP_THROUGH_DATE = 'Approved Through Date';
static DECISION_DATE = 'Decision Date';
  /*Date label */

  /**  ASM_INDICATOR*/
  static ASMINDICATOR_A = 'A';
  static ASMINDICATOR_S = 'S';
  static ASMINDICATOR_M = 'M';
  static ASMINDICATOR_P = 'P';
  /**  ASM_INDICATOR*/

  /**  COVERAGE_TYPE*/
  static NST = 'NST';
  static STAT = 'STAT';
  static SUPP = 'SUPP';
  static CORE = 'CORE';
  static BUYUP = 'BUYUP';

  /**  COVERAGE_TYPE*/

  /**  OTHER_CONSTANTS*/
  static ENABLE = 'Enable';
  static DISABLE = 'Disable';
  /**  OTHER_CONSTANTS*/
  /** CLAIMS REASON START */
  static ENTERED_IN_ERROR = 'ENTERED IN ERROR';
  /** CLAIMS REASON END */


  /** IMAGE PATHS FOR ALL THE FILES*/
  static  OPEN_STATUS_ICON = '../assets/images/icons/green-check.svg';
  static LEAVEOFABSENCE = 'leave-of-absence.svg';
  static CLAIM_BLUE_OUTLINE = '../assets/images/icons/claims_blue_outline.svg';

  /** IMAGE PATHS FOR ALL THE FILES ENDS HERE*/


  /**  CLAIM_DETAILS*/
  static URL_CLAIM_DETAILS = '/claimDetails';
  static URL_CLAIMdETAILS_MISSINGINFO = '/claimDetails?scrollTo=missingInfoHeader';
  static URL_LEAVEdETAILS_MISSINGINFO = '/leave-details/leaveDetails?scrollTo=missingInfoHeader';
  static MISSINGINFO_CLAIM = 'MissingInfoClaim';
  static MISSINGINFO_LEAVE = 'MissingInfoLeave';
  static INDICATOR_YES = 'YES';
  static INDICATOR_NO = 'NO';
  static IND_TRUE = 'true';
  static IND_FALSE = 'false';
  static INDICATOR_1001 = '1001'; // yes
  static INDICATOR_1002 = '1002'; // no
  static CLAIM_DETAILS = 'Claim Details';
  static YOUR_VIDEOS = 'Your Videos';
  static BENEFIT_BREAKDOWN = 'Benefit Breakdown';
  static PROVISION_ID_806 = '806';
  static PROVISION_OPTION_ID_2 = '2';
  static PROVISION_OPTION_ID_3 = '3';
  static PROVISION_OPTION_ID_4 = '4';
  static ERROR = 'Error';
  static SURGER_DATE = '[Sugery date]';
  static DISABILITY_DATE = '(Disability date)';
  static URL_REQUEST_EXTENSION = '/requestExtension';
  static URL_REQUEST_EXTENSION_THAA = '/request-extension';
  static URL_INTERACTIVE_REQUEST_EXTENSION_THAA = '/ire';
  static STATUS_CODE = 1;
  static CLAIM_PAYMENT_LABEL_ID = 'claimPaymentLabelId';
  static LTD_WHITE_SVG = 'LTD_white.svg';
  static CLAIMS_WHITE_SVG = 'claims_white.svg';
  static MATERNITY_WHITE_SVG = 'maternity_white.svg';
  static STD_WHITE_SVG = 'STD_white.svg';
  static SURGERY_WHITE_SVG = 'surgery_white.svg';
  static URL_REQUEST_TIME_OFF = '/leave-details/requestTimeOff';
  static RECENTLY_RESOLVED_LINK = '/claimDetails/recentlyResolved';
  static RECENTLY_RESOLVED_LINK_THAA = '/claimDetails/recentlyResolvedInfo';
  static RECENTLY_RESOLVED_LINK_LEAVE = '/leave-details/recentlyResolvedInfo';
  static PFL_BLUE_BG_SVG = 'PFL-icon-blue-bg.svg';
  static URL_CLAIM_RTW = '/claimDetails/claimReturnToWork';
  static URL_CLAIM_RTW_CONFIRM = '/claimDetails/claimSuccessReturnToWork';
  static URL_REQUEST_EXTN_THAA = 'request-extension';
  static PATH_A = 'pathA';    // expected rtw date path : selected when the user is returning to work on the expected date
  static PATH_B = 'pathB';    // rtw date preponed path : selected when the user changes the rtw date to a date < = current approved through date + 1
  static PATH_C = 'pathC';    // rtw extension path     : selected when the user selects an rtw date > current approved through date + 1
  static REPORTED_RTW_CHANGE_DATE = 'Yes, Change My Date';
  static WHATS_THIS_POPOVER_TEXT = 'What\'s this?';
  static RESTRICTION_REASON = 'Restriction Reason';
  static New_Accommodation_Details = 'New Accommodation Details';

  /**  CLAIM_DETAILS*/

  /** CLAIM PAYMENTS START */
  static PAYMENTS_EFT = 'EFT';
  static PAYMENT_METHOD = 'Check';
  static BACK_LINK_TO_SINGLE_PAYMENT = '/claimDetails/singlePayment';
  static YOUR_PAYMENT_DETAILS_PAGE_ID = 'Your Payment Details';
  static YOUR_PAYMENT_DETAILS_OWCS = 'Your_Payment_Details';
  /** CLAIM PAYMENTS END */

  /*claim denied timeline start*/
  static MATERNITY_CLAIM = 'maternity claim';
  static SURGERY_CLAIM = 'surgery claim';
  static AWAIT_SURG_DET_CONFIRM = 'Awaiting Surgery Details Confirmation';
  static AWAITING_DELIVERY = 'Awaiting Delivery';
  static AWAITING_DISABILITY = 'Awaiting Disability';

  /*claim denied timeline ends*/

  /*claim overview claim denied start*/
  static CLOSED_CLAIM = 'Claim Closed';
  static CLAIM_DENIED = 'Claim Denied';
  static CLAIM_REVIEW = 'Claim Review';
  static CLAIM_SUBMITTED = 'Claim Submitted';
  static CLAIM_APPROVED = 'Claim Approved';
  static COS_REP_REACH_OUT = 'Rep Will Reach Out';
  static AWAITING_SURGERY = 'Awaiting Surgery';
  static AWAIT_PAID_FAMILY_LEAVE = 'Awaiting Paid Family Leave';
  static CLAIM_STATUS_CLOSED = 'closed';
  /*claim overview claim denied ends*/

  /*claim status header start*/
  static BTRM = 'BTRM';
  static BDEP = 'BDEP';
  static SDEP = 'SDEP';
  static STRM = 'STRM';
  static VOL = 'VOL';
  static PREMIUM_WAIVER_BTL = 'Premium Waiver (Basic Term Life)';
  static PREMIUM_WAIVER_BDL = 'Premium Waiver (Basic Dependent Life)';
  static PREMIUM_WAIVER_SDL = 'Premium Waiver (Supplemental Dependent Life)';
  static PREMIUN_WAIVER_SL = 'Premium Waiver (Supplemental Life)';
  static PREMIUM_WAIVER_VOL = 'Premium Waiver (Voluntary Life)';
  /*Claim status header end*/


  /*claim wizard post login start*/
  static PAGE_NAME_CLAIMWIZPOSTLGN = 'Claims Wiz - Post login';
  static REF_HELPER_TEXT = 'sectionInfo';
  static SELECTION_RETURN_LINK = 'sectionReturnLink';
  static NOT_SELECTED_TEXT = 'notSelected';
  static URL_CLAIMWIZ_RECOMM = 'claim-wizard/recommendation';
  /*claim wizard post login end*/

  /*claim wizard pre login start*/
  static PAGE_NAME_CLAIMWIZPRELOGN = 'Claims Wiz - Pre Login';
  /*claim wizard post login end*/

  /*claim wizard reccom start*/
  static VOL_PRODUCT_CRITICALILL  = 'CRITICAL_ILLNESS';
  static VOL_PRODUCT_HOSPIDEM = 'HOSPITAL_INDEMNITY';
  static VOL_PRODUCT_ACCIDENT = 'ACCIDENT';
  static CAPFL = 'CAPFL';
  static NYPFL = 'NYPFL';
  static NYPFL_PAPER = 'NYPFL_PAPER';
  static NYPFL_PAPERLESS = 'NYPFL_PAPERLESS';
  static LBO = 'LBO';
  static PW = 'PW';
  static INSURANCE = 'INSURANCE';
  static LETSCHAT = 'LETSCHAT';
  static LONG_TERM_TXT = 'Long Term';
  static LIFE_INSURANCE = 'Life Insurance';
  static LEAVE_TEXT = 'LEAVE';
  /*claim wizard recomm end*/

/*cobranding start*/
  static PAGE_NAME_CO_BRANDING = 'Co Branding';
  static PAGE_NAME_COBRANDING = 'Co_Branding';
  static CASE_ID_164477 = '164477';
  static CASE_ID_839295 = '839295';
  static CASE_ID_400055 = '400055';
  static CASE_ID_1030933 = '1030933';
  static CASE_ID_694205 = '694205';
  static CASE_ID_516644 = '516644';
  static CASE_ID_561724 = '561724';
  static CASE_ID_481706 = '481706';
  static CASE_ID_487970 = '487970';
  static CASE_ID_33220  =  '33220';
  static CASE_ID_875199 = '875199';
  static CASE_ID_1036267 = '1036267';
  static CASE_ID_821532 = '821532' ;
  static CASE_ID_1169842 = '1169842' ;
  static CASE_ID_1225118 = '1225118';
  static CASE_ID_498852 = '498852';
  static CASE_ID_463726 = '463726';
  static CASE_ID_1213556 = '1213556';

  /*co branding end*/
/**  CLAIM_TIMELINE*/
  static REQ_SUBMITTED = 'Request Submitted';
  static AWAITING_MILITARY_LEAVE = 'Awaiting Military Leave';
  static UNDER_REVIEW = 'Under Review';
  static APPROVED_C = 'Approved';
  static IN_PAYMENT = 'In Payment';
  static TL_CLOSED = 'Closed';
  static AWAITING_CHILD_BONDING = 'Awaiting Child Bonding';
  static AWAITING_CARE_OF_A_FAMILY_MEMBER = 'Awaiting Care of a Family Member';
  static PENDING_APPROVAL = 'Pending Approval';
  static AWAITING_PFL = 'Awaiting Paid Family Leave';
  static CLAIM_DECISION = 'Claim Decision';
  static INADJSUR_MSG = 'Thanks for sending the information we asked for. We\'ll typically get back to you within 5 business days with a decision, or if we need anything else.';
  static CLAIM_REVIEW_SMALL = 'claim review';
  static CLAIM_DETAILS_U = 'Claim_Details';
  static MILIARY = 'military';
  static CHILD_BONDING = 'child bonding';
  static CARE_OF_A_FAMILY_MEMBER = 'care of a family member';
  static CARE_OF_FAMILY_MEMBER = 'care of family member';
  static SCROLL_TO = 'scrollTo';
  static MISSING_INFO_HEADER = 'missingInfoHeader';
  static BOND_WITH_NEW_CHILD = 'bond with new child';
  static BOND_WITH_A_NEW_CHILD = 'bond with a new child';
/**  CLAIM_TIMELINE*/

/*claim status comp start*/
  static PAGE_NAME_CLAIM_OVERVIEW = 'Claims Overview';
  static PAGE_NAME_CLAIM_OVERVIEW_1 = 'Claims_Overview';
  static MISSING_INFO = 'Missing Information';
  static STATUS_CODE_404 = '404';
  static STATUS_CODE_403 = '403';
  static STATUS_CODE_400 = '400';
  static STATUS_CODE_500 = '500';
  /*claim status comp end*/

  /*contact comp start*/
  static CASE_ID_11148 = '11148';
  static PAGE_NAME_CONTACT_US = 'Contact Us';
  static PAGE_NAME_CONTACT_US_1 = 'Contact_Us';
  /*contact comp end*/

  /** CLAIM_CARD*/
  static CLAIM_OVERVIEW = 'Claims Overview';
  static CLAIM_OVERVIEW_U = 'Claims_Overview';
  static FUTURE_PAYMENTS = 'Future Payments'; //Change to false before PROD build
  /** CLAIM_CARD*/

  /** MSG_RIBBON*/
  static TRUE = 'true';
  static CLOSE_TIMEOUT = 10000;
  /** MSG_RIBBON*/

  /**  MISSING_INFO_DETAILS*/
  static ONSHORE = 'onshore';
  static GENERIC = 'generic';
  /**  MISSING_INFO_DETAILS*/

  /*confirm delivery comp start*/
  static PAGE_NAME_CONFIRM_DELIVERY = 'Confirm Delivery';
  static PAGE_NAME_CONFIRM_DELIVERY_1 = 'Confirm_Delivery';
  static DELIVERY = 'delivery';
  static STILL_WORKING_MODAL = 'stillWorking';
  /*confirm delivery comp end*/

  /*THAA confirm delivery comp start*/
  static PAGE_NAME_THAA_CONFIRM_DELIVERY = 'THAA Confirm Delivery';
  static PAGE_NAME_THAA_CONFIRM_DELIVERY_1 = 'THAA_Confirm_Delivery';
  /*THAA confirm delivery comp end*/
  /*Thank you comp start*/
  static PAGE_NAME_LAST_DAY_WORK_CONFIRM = 'Last Day Work Confirm';
  static PAGE_NAME_LAST_DAY_WORK_CONFRIM_1 = 'Last_Day_Work_Confirm';
  static PAGE_NAME_LAST_DAY_WORKED_THAA_CONFIRM = 'THAA Last Day Worked Confirm';
  static PAGE_NAME_LAST_DAY_WORKED_THAA_CONFRIM_1 = 'THAA_Last_Day_Worked_Confirm';
  /*Thank you comp end*/
  /*delivery cong comp start*/
  static PAGE_NAME_CONFIRM_DELIVERY_CONFIRM = 'Confirm Delivery Confirm';
  static PAGE_NAME_CONFIRM_DELIVERY_CONFIRM_1 = 'Confirm_Delivery_Confirm';
  /*delivery congrats comp end*/


  /*thaa delivery cong comp start*/
  static PAGE_NAME_THAA_CONFIRM_DELIVERY_CONFIRM = 'THAA Confirm Delivery Confirm';
  static PAGE_NAME_THAA_CONFIRM_DELIVERY_CONFIRM_1 = 'THAA_Confirm_Delivery_Confirm';
  /*thaa delivery congrats comp end*/
  /*last day work comp start*/
  static PAGE_NAME_LAST_DAY_WORKED = 'Last Day Worked';
  static PAGE_NAME_LAST_DAY_WORKED_1 = 'Last_Day_Worked';
  static PAGE_NAME_LAST_DAY_WORKED_THAA = 'THAA Last Day Worked';
  static PAGE_NAME_LAST_DAY_WORKED_THAA_1 = 'THAA_Last_Day_Worked';
  /*last day work comp end*/

  /**  CONFIRM_DETAILS_PAGE*/
  static CONFIRM_DISABILITY_DETAILS = 'Confirm Disability Details';
  static CONFIRM_DISABILITY_DETAILS_U = 'Confirm_Disability_Details';
  static THAA_CONFIRM_DISABILITY = 'THAA Confirm Disability';
  static THAA_CONFIRM_DISABILITY_U = 'THAA_Confirm_Disability_U';
  static THAA_CONFIRM_DELIVERY = 'THAA Confirm Delivery';
  static THAA_CONFIRM_DELIVERY_U = 'THAA_Confirm_Delivery_U';
  static CLAIM_VIEW = 'ClaimView';
  static CLAIMDETAILS = 'ClaimDetails';
  static Tax_Form_Print = 'taxFormPrintprof';
  static EXEMPT_TAX_FORM = 'ExemptTaxForms';
  /**  CONFIRM_DETAILS_PAGE*/

  /**  DIS_THANK_U*/
  static CONFIRM_DIS_CONFIRM = 'Confirm Disability Confirm';
  static CONFIRM_DIS_CONFIRM_U = 'Confirm_Disability_Confirm';
  /**  DIS_THANK_U*/

  /**  DISABILITY_LAST_DAY_SECTION*/
  static IS_DIS_LDW = 'isDisabilityLDW';
  static HOURS = 'Hours';
  static CAPHOURS = 'hours';
  static HOUR = 'hour';
  static MINUTES = 'minutes';
  static MINUTE = 'minute';
  static STATUS_CD_0_STR = '0';
  static STATUS_CD_0 = 0;
  static DISABILITY = 'disability';
  static CANCEL_MODAL = 'cancelModal';
  /**  DISABILITY_LAST_DAY_SECTION*/

  /**  NEW_DIS_DT_PG*/
  static NEW_DIS_DATE = 'New Disability Date';
  static NEW_DIS_DATE_U = 'New_Disability_Date';
  /**  NEW_DIS_DT_PG*/

  /** CONFIRM SURGERY START */
  static SURGERY_MESSAGE_BLOCK = 'Glad to see you\'re so on top of things! We can\'t collect this information yet, but make sure to come back a little closer to your surgery date to confirm. If you\'re currently out of work, please give us a call!';
  static SURGERY = 'surgery';
  static SURGERY_CANCEL_MODAL = 'SurCancel';
  static FORM_VALUE_DAY = 'Day';
  static FORM_VALUE_MONTH = 'Month';
  static CONFIRM_SURGERY = '/surgery/confirmSurgery';
  static ROUTE_TO_HOME = '/home';
  static SURGERY_LAST_DAY_WORK = '/surgery/surgeryLastDayWork';
  static CONFIRM_SURGERY_FLOW_THAA = '/surgery/confirmSurgeryFlow';
  static NEW_SURGERY_DATE = '/surgery/newSurgeryDate';
  /** CONFIRM SURGERY END */

  /** DIRECT DEPOSIT START */
  static DIRECT_DEPOSIT_MODIFY_PAGE_ID = 'Direct Deposit Modify';
  static DIRECT_DEPOSIT_MODIFY_OWCS = 'Direct_Deposite_Modify';
  /** DIRECT DEPOSIT END */
  /*home comp start*/
  static SRC_PARTY_NAME_THAA = 'THAA';
  static SRC_PARTY_NAME_DCSE = 'DCS Eligibility';
  static SRC_PARTY_NAME_DCS = 'DCS Claims';
  static SRC_SYS_CD_8 = 8;
  static ACTIVE_INDICATOR_1 = '1';
  static SRC_SYS_CD_3 = 3;
  static PAGE_NAME_OWCS_ERR_MSG = 'OWCS Error Msg Page';
  /*home comp end*/

  /**  EMPLOYEE-BENEFIT-REGISTRATION*/
  static N = 'N';
  static FALSE = 'false';
  static EMPLOYEE_BENEFIT_REG = 'Employee Benefits Registration';
  static EMPLOYEE_BENEFIT_REG_U = 'Employee_Benefits_Registration';
  static LINK_TYPE_CD_2 = 2;
  static LINK_TYPE_CD_1 = 1;
  static SRC_SYS_CD_2 = 2;
  static SRC_SYS_CD_1 = 1;
  /**  EMPLOYEE-BENEFIT-REGISTRATION*/

  /**  ERROR_COMPONENT*/
  static PAGE_NOT_FOUND = 'Page Not Found';
  /**  ERROR_COMPONENT*/

  /** CUSTOM ERROR */
  static CUSTOM_ERROR = 'New Intake';
  static CUSTOM_ERROR_1 = 'New_Intake';
  /** CUSTOM ERROR */

  /** LOGIN*/
  static PRELOGIN = 'preLogin';
  static IS_REG_DISABLED = 'isRegisterDisabled';
  static LOGIN = 'login';
  static AUTO = 'auto';
  static IS_FORCED_EMPLOYER = 'isForcedEmployer';
  static LOGIN_ERROR = 'Login Error';
  static LOGIN_ERROR_U = 'Login_Error';
  /** LOGIN*/

  /** LOGIN_ERROR*/
  static Y = 'Y';
  static LEP_06_BODY = 'LEP_06_Body';
  static LEP_07_BODY = 'LEP_07_Body';
  static LEP_03_BODY = 'LEP_03_Body';
  static LEP_04_BODY = 'LEP_04_Body';
  /** LOGIN_ERROR*/

  /** CLAIM DOCUMENT START */
 static DOCUMENT_UPLOAD_PAGE_ID = 'Document Upload Confirmation Page';
 static DOCUMENT_UPLOAD_OWCS = 'Document_Upload_Confirmation_Page';
 static DOCUMENT_UPLOAD = 'Document Upload'
 static EXTENSION_PDF = 'pdf';
 static EXTENSION_DOC = 'doc';
 static EXTENSION_DOCX = 'docx';
 static EXTENSION_TXT = 'txt';
 static EXTENSION_XLS = 'XLS';
 static EXTENSION_XLSX = 'XLSX';
 static EXTENSION_PPT = 'PPT';
 static EXTENSION_PNG = 'png';
 static EXTENSION_JPG = 'jpg';
 static EXTENSION_JPEG = 'jpeg';
 static EXTENSION_RTF = 'rtf';
 static EXTENSION_PPTX = 'pptx';
 static EXTENSION_TIF = 'tif';
 static EXTENSION_TIFF = 'tiff';
 static EXTENSION_GIF = 'gif';
 static EXTENSION_BMP = 'bmp';
  /** CLAIM DOCUMENT END */

  /**  RECENTLY_RESOLVED_COMPONENT*/
  static RECENTLY_RESOLVED_ITEMS = 'Recently Resolved Items';
  static RECENTLY_RESOLVED_ITEMS_U = 'Recently_Resolved_Items';
  static INFORMATION_FROM_YOU = 'informationFromYou';
  static INFORMATION_FROM_DR = 'informationFromDr';
  static INFORMATION_FROM_ER = 'informationFromEr';
  /**  RECENTLY_RESOLVED_COMPONENT*/

  /** NOTIFICATION_REGISTRATION*/
  static ABOUT_YOU = 'aboutYou';
  static DCST = 'DCST';
  static EMAIL = 'email';
  static SMS = 'SMS';
  static DCOT = 'DCOT';
  static DCPT = 'DCPT';
  static EMAIL_LG = 'Email';
  static NOTE_PREF_SET_F = 'F';
  static NOTIFICATIONS = 'Notifications';
  static NOTIFICATIONS_U = 'Notifications_U';
  static MOBILE_LABEL = 'Mobile Number';
  static EMAIL_LABEL = 'Email Id';
  static EMAIL_ERROR_REQUIRED = 'RequiredEmail';
  static MOBILE_ERROR_REQUIRED = 'RequiredMobile';
  /** NOTIFICATION_REGISTRATION*/

  /**  POPUP_COMM_PREF*/
  static TERMS_NOT_AGREED = 'termsNotAgreed';
  static TEXT_TCS = 'textTcs';
  /**  POPUP_COMM_PREF*/

  /**  POPUP_NOTIFICATION_REG*/
  static MOBILE_NO = 'mobileno';
  static NOTIFICATION_INTERCEPT = 'Notification Intercept';
  static NOTIFICATION_INTERCEPT_U = 'Notification_Intercept';
  /**  POPUP_NOTIFICATION_REG*/

  /**  PRODUCT_INFO*/
  static CO_66 = 'CO_66';
  static CO_30 = 'CO_30';
  static CO_ADA = 'CO_ADA';
  static PRE_MAT_WITHOUT_LEAVE = 'PreMaternitywithoutLeave';
  static POST_MAT_WITHOUT_LEAVE = 'PostMaternitywithoutLeave';
  static MATERNITY_WITH_LEAVE = 'MaternitywithLeave';
  static MATERNITY_WITHOUT_LEAVE = 'Maternity without Leave';
  static MATERNITY_WITH_LEAVE_2 = 'Maternity with Leave';
  static MATERNITY_CC = 'Maternity';
  static PLANNED_SURGERY = 'Planned Surgery';
  static SHORT_TERM_DISABILITY =  'Short Term Disability';
  static LEAVE_OF_ABS = 'Leave Of Absence';
  static PAID_FAMILY_LEAVE_TYPE = 'Paid Family Leave';
  static PAID_FAMILY_LEAVE = 'Paid Family and Medical Leave';
  static LONG_TERM_DISABILITY =  'Long Term Disability';
  static START_A_CLAIM = 'Start a Claim';
  static URL = 'url';
  static ACCIDENT = 'Accident';
  static CRITICAL_ILLNESS = 'Critical Illness';
  static HOSPITAL_INDEMNITY = 'Hospital Indemnity';
  static FILE_ID_PF = '1444635955642';
  static DOWNLOAD_PDF = 'Download PDF';
  static DOWNLOAD = 'download';
  static NEW_TAB = 'newTab';
  static PREMIUM_WAIVER = 'Premium Waiver';
  static ADA_ACCOMODATION = 'ADA Accommodation';
  static ADD_TIME_RADIO_NO = 'addMoreTimeRadioNo';
  static ADD_TIME_RADIO_YES = 'addMoreTimeRadioYes';
  static CONFIRM_ADA_END_DATE = 'Confirm Accommodation End Date';
  static EXTEND_ADA_END_DATE = 'Extend End Date For Accommodation';
  static ACCOMMODATION_TYPE_SAME = 'accommodationTypeSame';
  static ACCOMMODATION_TYPE_NEW = 'accommodationTypeNew';
  static ACCOMMODATION_SAME = 'Same Thing';
  static ACCOMMODATION_NEW = 'Something New';
  static TIMESTAMP = 'timestamp';
  static ADA_ENDDATE_HEADER = '10038500';
  static ADA_NEWACCOMMDATION_HEADER = '10038502';
  static ADA_EXTENSION_HEADER = '10038501';
  static YES_LABEL = 'Yes';
  static NO_LABEL = 'No';
  static ACCOMMODATION_NEW_QUES = 'Please provide some details about what you need';
  /**  PRODUCT_INFO*/

  /**  PRODUCT_INFO*/
  static PRE_AUTH_PROD_PAGE = 'PreAuthenticated Product Page';
  static PRE_AUTH_PROD_PAGE_U = 'PreAuthenticated_Product_Page';
  static POST_AUTH_PROD_PAGE = 'Post Authenticated Product Page';
  static POST_AUTH_PROD_PAGE_U = 'Post_Authenticated_Product_Page';
  /**  PRODUCT_INFO*/

  /**  PRODUCT_LIST_POST_LOGIN*/
  static IS_OVERVIEW_LOADED = 'isOverViewLoaded';
  /**  PRODUCT_LIST_POST_LOGIN*/

  /**  PRODUCT_LIST_PRE_LOGIN*/
  static PRD_10 = 'Prd_10';
  static CIAM_LOGIN = 'CIAMLogin';
  static CIAM_REGISTER = 'CIAMRegister';
  /**  PRODUCT_LIST_PRE_LOGIN*/

  /*edit comp start*/
  static CC_CANADA = 'CANADA';
  static PAGE_NAME_ABOUT_YOU = 'About You';
  static PAGE_NAME_ABOUT_YOU_1 = 'About_You';
  /*edit comp end*/
  /*profile comp start*/
  static SECTION_TITLE = 'sectionTitle';
  /*profile comp end*/


  /** PHA COVERAGE CATEGORY START */
  static PHA_LIFE = 'LIFE';
  static PHA_WD = 'WD';
  static PHA_LTD = 'LTD';
  /** PHA COVERAGE CATEGORY END */

  /** PHA COVERAGE TYPE START */
  static PHA_VOL = 'VOL';
  static PHA_BTRM = 'BTRM';
  static PHA_STRM = 'STRM';
  static PHA_SDEP = 'SDEP';
  static PHA_VSTD = 'VSTD';
  static PHA_VLTD = 'VLTD';
  static PHA_NST = 'NST';
  static PHA_ABIL = 'ABIL';
  /** PHA COVERAGE TYPE END */

  /** PHA COVERAGE STATUS START */
  static PHA_PENDING = 'PENDING';
  static PHA_TRANSFER = 'TRANSFER';
  static PHA_OPEN = 'OPEN';
  static PHA_APPROVE = 'APPROVE';
  static PHA_DENIED = 'DENIED';
  static PHA_DECLINE = 'DECLINE';
  static PHA_WITHDRAW = 'WITHDRAW';
  static PHA_CLOSED = 'CLOSED';
  static PHA_INELIGIBLE = 'INELIGIBLE';
  static PHA_INELIGIBLE_EXCEPTION = 'INELIGIBLE*';
  static PHA_INCOMPLETE = 'INCOMPLETE';
  static PHA_INCOMPLETE_EXCEPTION = 'INCOMPLETE*';
  static PHA_WITHDRAW_NO_EVIDENCE = 'WITHDRAW - EVIDENCE NOT NEEDED';
  static PHA_WITHDRAW_NO_EVIDENCE_EXCEPTION = 'WITHDRAW - EVIDENCE NOT NEEDED*';
  /** PHA COVERAGE STATUS END */

  static WEEKS = 'Weeks';
  static MONTHS = 'Months';
  static DAYS = 'Days';
  /** SELF LINKING START */
  static CONFIRM_INSURED_PAGE_ID = 'Confirm Insured ID';
  static CONFIRM_INSURED_OWCS = 'Confirm_Insured_ID';
  static CONFIRM_ELIGIBILITY_PAGE_ID = 'Confirm Eligibility ID';
  static SELF_LINKING_INSURED_ID = 'insuredId';
  static ELIGIBILITY_ID = 'eligibilityId';
  /** SELF LINKING END */

  /**  SECURITY_STATEMENT*/
  static SECURITY_STATEMENT = 'Security Statement';
  static SECURITY_STATEMENT_U = 'Security_Statement';
  /**  SECURITY_STATEMENT*/

  /**  SITE_WIDE_TNC*/
  static SITE_WIDE_TNC = 'Site wide T&C';
  static SITE_WIDE_TNC_U = 'Site_wide_TandC';
  /**  SITE_WIDE_TNC*/

  /**  TEXT_TCS*/
  static TEXTING_TNC = 'Texting T&C';
  static TEXTING_TNC_U = 'Texting_TandC';
  /**  TEXT_TCS*/

  /** UPDATED-TCS-ALERT-MODAL*/
  static SITEWIDE_TNC = 'siteWidetermsAndConditions';
  /** UPDATED-TCS-ALERT-MODAL*/

  /**  TRANSACTION_ERROR*/
  static FAILED_TRANSACTION_ERROR_PG = 'Failed Transaction Error Page';
  static FAILED_TRANSACTION_ERROR_PG_U = 'Failed_Transaction_Error_Page';
  /**  TRANSACTION_ERROR*/

  /**  LEAVE FORM*/
  static FORM_LIBRARY = 'Form Library';
  static SEE_FORMS = 'See Forms';
  static LEAVE_FORM_MSG = 'Need to fill out a medical form? Check here to see your available forms.';
  static LEAVE_FORM_URL = '/leaveFormLibrary';
  static LEAVE_FORM_LIST = 'Download the form(s) you need from the list below. To return completed information, follow the instructions on each form.';
  static LEAVE_SELECTED_DATE_HEADER = 'leaveAbsenceRequestid';
  /**  LEAVE FORM*/

  /**  ENROLL_DEPOSIT*/
  static DIRECT_DEPOSIT = 'Direct Deposit';
  static DIRECT_DEPOSIT_LANDING = 'Direct Deposit Landing';
  static DIRECT_DEPOSIT_LANDING_U = 'Direct_Deposite_Landing';
  static CD_50001 = 50001;
  static CD_50002 = 50002;
  static CD_50003 = 50003;

  static DIRECT_DEPOSIT_TNC = 'Direct Deposit T&C';
  static DIRECT_DEPOSIT_TNC_U = 'Direct_Deposite_TandC';
  static ENROLL_DEPOSIT = 'enrolldeposit';
  static DIRECT_DEPOSIT_VALIDATE = 'Direct Deposit Validate' ;
  static DIRECT_DEPOSIT_VALIDATE_U = 'Direct_Deposite_Validate' ;
  static TERMS_N_CONDITION_EFT = 'termsAndCondtionEFT';
  static SAVING = 'Saving';
  static CHECKING = 'Checking';
  static DEPOSIT = 'deposit';
  static IS_AUTH_USER = 'isAuthorsedUser';

  static DIRECT_DEPOSIT_ENROLL = 'Direct Deposit Enroll' ;
  static DIRECT_DEPOSIT_ENROLL_U = 'Direct_Deposite_Enroll';

  static ACCOUNT_NUMBER = 'Account Number';
  static CD_30 = '30';
  static ENROLL = 'ENROLL';

  static DIRECT_DEPOSIT_CONFIRM = 'Direct Deposit Confirm';
  static DIRECT_DEPOSIT_CONFIRM_U = 'Direct_Deposite_Confirm';
  /**  ENROLL_DEPOSIT*/

  /**  PROMPT_DIRECT_DEPOSIT*/
  static ENROLL_DIRECT_DEPOSIT_SM = 'enroll_direct_deposit';
  /**  PROMPT_DIRECT_DEPOSIT*/

  /**  EMPLOYEE_SELECT*/
  static EMPLOYER_SELECTION = 'Employer Selection';
  static EMPLOYER_LG = 'Employer';
  static PROFILE_EMPLOYER = 'Profile Employer';
  static PROFILE_EMPLOYER_U = 'Profile_Employer';
  static SUCCESS_EXC = 'Success!';
  /**  EMPLOYEE_SELECT*/

  /**  RESET_PASSWORD*/
  static ACTIVE = 'active';
  /**  RESET_PASSWORD*/

  /**  SERVICES*/
  static LOCAL = 'local';
  static LOCAL_QA = 'local-qa';
  static TIMEOUT = 'Timeout';

  static LOG_OUT = 'Log out';
  static CHANNEL_ID_1 = '1';
  static CHANNEL_ID_2 = '2';
  static CHANNEL_ID_3 = '3';
  static CHANNEL_ID_4 = '4';
  static CLAIM_DETAILS_SM = 'claimdetails';
  static LEAVE_PLAN_SM = 'leaveplan';
  static LEAVE_DATAILS_SM = 'leavedetails';
  static FUTURE_PAYMENTS_SM = 'futurepayments';
  static FAQ_SM = 'faq';
  static CLAIMANT = 'CLAIMANT';
  static FI = 'FI';
  static ASO = 'ASO';
  static CANCELLED_LG = 'CANCELLED';
  static PAPERLESS_PAYSTUB = 'paperlessPaystub';
  static RES_CODE_001 = '001';
  static ALL_CLAIM_PAYMENTS = 'allClaimPayments';
  /**  SERVICES*/
  static SEMI_MNTH_PAGE_ID = 'Semi-Monthly Payment Schedule';
  static PAYMENT_SCH = 'Payment Schedule';
  static PAYMENT_SCH_THAA = 'THAA Payment Schedule';
  static NEW_BORN_DATE =  'MNewBornDate';
  static CURRENT_DATE = 'Current date';

  /**surgery-last day section  */
  static IS_LWD_PRESENT = 'isLDWPresent';
  static PROD_ENV_NAME = 'prod';

  /**Future Payment section */
  static BENEFTIS_BREAKDOWN = 'Benefits Breakdown'

  /** Feature Toggle */

  static FT_20210814_F10871: boolean = true;  //Future Payments
  static FT_20210814_F10471: boolean = true; //Missing Info final Follow-up
  static SSO_REALM = '/partners';


  static ADA_REQUEST_STATUS = [
    {
      status_id: 1,
      status: ['approved', 'accommodated']
    },
    {
      status_id: 2,
      status: ['pended', 'under review', 'accommodation certification review', 'pending accommodation certification', 'pending employer review', 'pending clinical review']
    },
    {
      status_id: 3,
      status: ['denied', 'not accommodated']
    }
  ];
  static REGEX_EXP = /(<([^>]+)>)/ig;
  static RESET = 'reset';
  // static REGEX_EXP = '/(<([^>]+)>)/ig';
  static PHONE_NUMBER_REGEX_EXP = /\[number\]/g;
  static REPORTED_RTW_MODAL = 'reportedRTWModal';
  static EXTENSION_DATE_CHANGED = 'extensionDateChanged';
  static RTW_DATE_CHANGED = 'rtwDateChanged';
  static MAX_CERT_END_DATE = '[Max Cert End Date of all Accepted Cert Rows]';
  static ACCOMMODATION_TYPE_AND_SUBTYPE = '[Accommodation_Type_Subtype]';
  static ACCOMMODATION_CONFIRMED_DATE_ANSWER = '&lt;&lt;Q1A Answer&gt;&gt;';
  static ACCOMMODATION_EXTEND_DATE_ANSWER = '[Q1A Answer]';
  static ACCOMMODATION_CONTACT_NUMBER = '[Number]';
  static NBSP = '&nbsp;';
  static START_NEW_CLAIM = 'start a new claim.';
  static YOU_WILL_NEED_CONSTANT = 'you&#39;ll';
  static YOU_WILL_NEED_CONSTANT_VALUE = 'you\'ll';

  static DATE_PICKER_OPTIONS: IMyDpOptions = {
    dateFormat: 'mm/dd/yyyy',
    selectorHeight: 'auto',
    dayLabels: {su: 'Su', mo: 'Mo', tu: 'Tu', we: 'We', th: 'Th', fr: 'Fr', sa: 'Sa'},
    showTodayBtn: false,
    firstDayOfWeek: 'su',
    monthSelector: false,
    yearSelector: false,
    minYear: 1970,
    showClearDateBtn: false,
    editableDateField: false,
    openSelectorOnInputClick: true,
    sunHighlight: false,
    markCurrentDay: false,
    height: '42px',
    componentDisabled: false,
  };

  /** input length specific constants */
  static TEXTAREA_MAX_LENGTH = 400;
  static TEXTAREA_MIN_LENGTH = 1;
  static TEXTAREA_LENGTH = 200;

  /** Switch Claims */
  static SWITCH_CLAIMS_MODAL_NAME = 'switch_claims_modal';
  static SWITCH_CLAIMS_MODAL_TITLE = 'Switch Claim';
  static SWITCH_CLAIMS_ID = 'ID';


  static DEFAULT = 'default';
  static ENGLISH_LABEL = 'English';
  static ENGLISH_CAPTION_SOURCE = 'en';

  static SPANISH_LABEL = 'Spanish';
  static SPANISH_CAPTION_SOURCE = 'sp';


  /** Product Forms */

  static PRODUCT_FORM_URL = 'formLibrary';
  static EXPAND_ALL = 'Expand All';
  static COLLAPSE_ALL = 'Collapse All';

  static ID_LENGTH_LIMIT = 7;

   /** Feature Toggle */
   static IS_TAX_FORM_DISABLED = 'IsTaxFormsDisabled';
   static IS_PREFRENCE_DISABLED = 'IsPreferencesDisabled';

   static RECAPTCHA_NOT_VALID_MESSAGE = 'Recaptcha validation not success.'

  /** Page Names*/

  static SYS_MESSAGE_PAGE_NAME: string = "System Messages";
  static SYS_MESSAGE_DOWN_PAGE_NAME: string = "System Down Messaging";
  static FAQ_PAGE_NAME: string = "FAQ Page";
}
