
import {Observable, of} from 'rxjs';

/**
 * Created by AC12393 on 8/7/2017.
 */
import {Injectable} from '@angular/core';
import {Profile} from '../models/profile.model';

@Injectable()
export class ProfileService {
  getProfile(prtyRefid: string): Observable<Profile> {
      const override = true; //todo remove when proper service is in place
    prtyRefid = '00001';
     /* if (env.mocking || override) {
         return of((PROFILE.filter((profile) => { if (profile.partyrefId === prtyRefid) { return profile }}))[0]);
      } else {*/

     /* }*/
     return of(null);
  }

  getUserProfile(partyrefId: string): Profile[] {
    //return PROFILE.filter((profile) => {if (profile.partyrefId === partyrefId) { return profile }});
    return [];
  }

}

