/**
 * Created by AC12396 on 9/19/2019.
 */

export class AppLabelConstants {
  /** TODO : Need to add in OWCS  START **/
  static MISSING_UI_MSG =
    "We’ll fax your doctor for information to support your request. It’s a good idea to check in with them in about a week. If they didn’t get our request, you can give them this form. Make sure they fill it out completely—that’ll lower the chances that we’ll need to ask follow-up questions.";
  static UR_ADJ_MSG =
    "We're reviewing your extension request. We'll let you know if we need anything else, or when we make a decision.";
  static ADD_WEEK = "Add a week to This Schedule";
  static LEARN_MORE = "Learn More - ";
  static UPLOAD_HERE = "Upload it here - ";
  static DOWNLOADING_FORM = "Downloading the form - ";
  static UPLOADING_HERE = "Uploading it here - ";
  static UPLOAD_COMPLETED_VERSION = "Upload the completed version - ";
  static DOWNLOAD_FORM = "download the form - ";
  static UPLOAD_DONE = "upload when you're done - ";
  /** TODO : Need to add in OWCS  END **/

  //  prod support email constants
  static SENDER_EMAIL_ADDRESS = "noreplyGB@thehartford.com";
  static RECIPIENT_EMAIL_ADDRESS_PROD =
    "GBITAAPL@thehartford.com, GBITAADigitalMPS@thehartford.com, GBITMPSDigital@thehartford.com, cassandra.wathen@thehartford.com";
  static RECIPIENT_EMAIL_ADDRESS_NON_PROD =
    "TeamMustang@thehartford.com, cassandra.wathen@thehartford.com";
  static EMAIL_SUBJECT = "Overpayment Recovery Information Failed to Save";
  static EMAIL_BODY = `The employee attempted to submit an overpayment recovery from MyBenefits. THAA was unable to save the Tempus response as outlined below and/or creation of the audit task within the claim. Please investigate to see if the table updates failed as well as the audit task or if the table updates were successful and the audit task failed to create and take the appropriate steps to remediate. \n\n The request to the Overpayment/Submit API is below: \n`;
  static PROD_ENV = "prod";
  //  prod support email constants

  /** Leave Higlabel */

  static NULLCHECK = null;
  static UNKNOWN_CHECK = "Unknown";
  static NEW_DIS_DATE_QUESTION = "What's your new disability date?";
  static DOC_MSG =
    "Now, tell us how we can get in touch with your doctor. If you have more than one, list the one you're working with most for this disability. This is so we can contact them directly if we need more information for your claim.";
  static REQUEST_EXT_SUCCESS_MSG =
    "Before we can make a decision, we’ll need updated medical information. We’ll contact your doctor in the next few business days. Or, you can download the form we need and give it to them directly. Make sure it’s filled out completely—that’ll lower the chances that we’ll need to ask follow-up questions.";
  static REQUEST_EXT_SUCCESS_MSG_PART2 =
    "Once we have what we need, we'll usually be in touch within 5 business days.";
  static REQUEST_EXT_SUCCESS_MSG_HEADER = "Success! Your extension request has been started.";
  /** leave plan and leave status */
  static CANCELLED_LABEL = "cancelled";
  static ADMIN_CANCELLED_LABEL = "administrative cancel";
  static INACTIVE_LABEL = "inactive";
  static CLOSED_LABEL = "closed";
  static PENDED_LABEL = "pended";
  static DENIED_LABEL = "denied";
  static APPROVED_LABEL = "approved";
  static CONTENT_TYPE_KEY = "Content-Type";
  static APPLICATION_KEY = "application/json";
  static DAY_TEXT = "Day";
  static MONTH_TEXT = "Month";
  static HOURS_TEXT = "Hours";
  static MINUTES_TEXT = "Minutes";
  static REQ_APPROVED_LABEL = "Approved";
  static REQ_NOT_APPROVED_LABEL = "Not Approved";
  static REQ_ACCOMMODATE = "Accommodated";
  static REQ_NOT_ACCOMMODATE = "Not Accommodated";
  static DENIED_SUBSTATUS =
    "You didn’t report this absence request within the required time frame.";
  static POLICY_NOT_FOUND = "policyNumberNotFound";
  static REQ_PENDED = "Pended";
  static REQ_UNDER_REVIEW_LABEL = "Under Review";
  static PENDING_ACCOMMODATION_CERTIFICATION = "Pending Accommodation Certification";
  static ACCOMMODATION_CERTIFICATION_REVIEW = "Accommodation Certification Review";
  /** leave type */
  static REDUCED_SCHEDULE_LABEL = "Reduced Schedule";
  static SCHEDULE_TYPE_LABEL = "Schedule Type";
  static SCHEDULE_TYPE = "Schedule Type:";
  static EDIT_WORK_SCHEDULE_LABEL = "Edit Work Schedule";
  static LENGTH_OF_ROTATION = "Length of Rotation";
  static INTERMITTENT = "Intermittent";

  /** leave details page and leave card*/
  static LEAVE_OF_ABSENCE = "Leave of Absence";
  static START_DATE = "Start Date";
  static UPLOAD = "Upload";
  static REQUEST_TIME_OFF = "/leave-details/requestTimeOff";
  static ADA_UPDATE_ACCOMMODATION = "/leave-details/adaUpdateAccommodation";
  static ADA_UPDATE_ACCOMMODATION_CONFIRMATION = "/leave-details/updateAccommodationConfirmation";
  static ADA_TIMELINE_DETAILS = "adaTimelineDetails";
  static TIME_REQUESTED = "Here's all the time you've requested for your accommodation.";
  /** leave request calendar page */
  static SHOW_ABSENCES_FOR_ALL = "Show absences for all leaves";
  static FILTER_BY_STATUS = "Filter by Status";
  static CHECKED = "checked";
  static REQUEST_AN_EXTEN = "Request an Extension";
  static LEAVE_REQUEST_AN_EXT = "THAA Leave Request an Extension";
  static LEAVE_REQUEST_AN_EXT_UN = "THAA_Leave_Request_an_Extension";
  static LEAVE_RETURN_TO_WORK = "Return to Work";
  static LEAVE_RETURN_TO_WORK_UN = "Return_to_Work";
  static DISABILITY_RETURN_TO_WORK = "Disability Return to Work";
  static DISABILITY_RETURN_TO_WORK_UN = "Disability_Return_to_Work";
  static DISABILITY_RETURN_TO_WORK_CONFIRM = "Disability Return to Work Confirm";
  static RETURN_TO_WORK_CONFIRM = "Return to Work Confirm";
  static CONFIRM_RETURN_TO_WORK = "Confirm Return to Work";
  static EXPECTED_RTW = "(Expected_RTW)";
  static ADA = "ADA";
  static ADA_PROVISIONID = "3026";
  static ADA_PROVISION_OPTION_ID = "3";
  static DISPLAY_ADA_TIMELINE = "displayAdaTimeline";
  static ADA_TIMELINE_LEAVE_ID = "adaTimelineLeaveId";
  static ADA_TIMELINE_TRIGGERED = "adaTimelineTriggered";
  static SHOW_INTAKE = "showIntake";
  static FULL_DESCRIPTION = "Full Description";
  static SHOW_LESS = "Show Less";

  /** ERROR CODE */
  static DP_TIME_OUT_ERROR_CODE = "0x01130006";

  /** MODAL */
  static CANCEL_MODAL = "cancelModal";
  static CANCEL_MODAL_TITLE = "Are you sure you want to exit?";
  static CANCEL_MODAL_PARA = "If you made any changes, they won't be saved.";
  static SUR_CANCEL_MODAL_NAME = "SurCancel";
  static SAVE_MODAL = "saveModal";
  static BEFORE_YOU_CONTINUE_MODAL = "Before you continue...";
  static MISSING_DOCUMENT_MODAL = "missingDocumentUpload";
  static SELF_LINKING_MODAL = "selfLinkingModal";
  static CONFIRM_MODAL = "confirmModal";
  static MAIL_MY_PAYMENT_MODAL = "MailMyPayment";
  static SAVE_CHECK_MODAL = "savCheck";
  static PLAN_BAL_LEAVE_MODAL = "planBalnceModal";
  static PLAN_BAL_NO_DESC_MODAL = "planBalNoDescModal";
  static DELETE_MODAL = "deleteModal";
  static SURE_MODAL = "sureModal";
  static NON_WORKDAY_MODAL = "nonWorkdayModal";
  static CANCEL_EDIT_SCHEDULE_MODAL = "cancelEditScheduleModal";
  static EDIT_CANCEL_BTN_TEXT = "Yes, Cancel";
  static RETURN_TO_PAGE = "No, Return to Page";
  static UNAVIALABLE_WORKDAY_MODAL = "unavailableWorkdayModal";
  static NON_WORKDAY_MODAL_IF_YES = "nonWorkdayModalIfYes";
  static PARTIAL_ABSENCE_MODAL = "partialAbsenceModal";
  static OVERTIME_MODAL = "overTimeModal";
  static WEEKDAYS = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];
  static SAVE_MODAL_NAME = "saveModalName";
  /** MODAL */

  /** BUTTON TEXT */
  static GOBACK_BUTTON_TEXT = "Go Back";
  static EXIT_BTN_TEXT = "Exit without saving changes";
  static CANCEL_BTN_TEXT = "Cancel";
  static CLEAR_BTN_TEXT = "clear";
  static CLAIM_WIZAED_TEXT = "cancelClaimWizard";
  static NEXT_BTN_TEXT = "Next";
  static GOTIT_BTN_TEXT = "Okay got it";
  static DONT_KNOW_LABEL = "I dont know";
  static RETURN_HOME = "Return Home";
  static FINISH_SUBMIT_TEXT = "Finish and Submit";
  static SUBMIT_TEXT = "Submit";
  static START_TEXT = "Start";
  static SAVE_TEXT = "Save";
  static SAVE_CONTINUE = "Save and Continue";
  static CONTINUE = "Continue";
  static PLEASE_SELECT = "Please Select";
  static GO_BACK_AND_START_CLAIM_TEXT = "Go Back and Start Claim";
  static KEEP_SIGNED = "Keep Me Signed In";
  static START_EXTENSION = "Start Extension Request";

  static TIME_OUT_WARNING_MESSAGE = "Are You Still There?";
  static TIME_OUT_WARNING_MESSAGE_EXTENDED =
    "To protect your account, we’ll sign you out if you’re still inactive in the next 5 minutes.";

  static CONFIRM = "Confirm";
  static LOG_OUT_BTN_TXT = "Log out";
  static CHANGE_TEXT = "If you made any changes, they won't be saved";
  /** BUTTON TEXT */

  /** BUTTON ID */
  static CHANGE_FDA_BTN = "changefirstdayabsentbtn";
  static CHANGE_WORK_SCHEDULE = "changeworkschedulebtn";
  static CONTINUE_BTN = "continuebtn";
  static GO_BACK_BTN = "goBackbtn";
  static REPORT_MANDATED_OT = "reportMandatedOT";
  /** BUTTON ID */

  /** Leave-Details */
  static REPORT_MISSED_HOUR = "Report Missed Work Hours";
  static CONTACT_US_TO_REPORT_AN_ABSENCE = "Contact Us to Report an Absence";
  static LEAVEREQUEST = "Leave Requests";
  static LEAVE_REQUEST = "Leave Request";
  static LEAVEDATESREQUESTED =
    "Here are the leave dates you’ve requested, as well as your status for each one.";
  static LEAVEDETAILS = "Leave Details";
  static BENEFIT_DETAILS = "Benefit Details";
  static ADA_DETAILS = "ADA Details";
  static MULTIPLE_ACC = "Multiple Accommodations";
  static ACCOMMODATION_UPDATE = "Accommodation Update";
  static ACCOMMODATION_UPDATE_CONFIRMATION = "Accommodation Update Confirmation";
  static ACCOMMODATION_UPDATE_CONFIRM = "Accommodation Update Confirm";

  /** leave request calendar page and tile view */
  static CHECK_UNDER_REVIEW = "underReview";
  static STATUS = "Status";
  static LEAVE_TYPE = "Leave Type";
  static TIME_OFF = "Time Off";
  static LEAVE = "Leave";
  static LEAVE_HRS_USED = "Leave Hours Used";
  static START_TIME = "Start Time:";
  static REASON = "Reason";
  static MINUTE = "&nbsp;minute";
  static MINUTES = "&nbsp;minutes";
  static YOR_RE_ELIGIBLE = "You’re eligible";
  static FOR_ONE_PLAN =
    "for at least one leave plan. We’re still reviewing your request, and we’ll let you know when we make a decision.";
  static WE_RE_CHECKING = "We’re checking on your eligibility.";
  static WE_WILL_LET_YOU_KNOW = "We'll let you know when we make a decision.";
  static WHAT_DOES_THIS_MEAN = "What does this mean?";
  static ADDITIONAL_PLAN = "See additional leave plan details";
  static PLEASE_SELECT_DATE = "Select the date on the calendar that you want to see details for.";
  static NO_LEAVE_ACTIVITY = "You have no leave activity for the selected date.";
  static IS_ELIGIBLE =
    "We’re still collecting information to see if you’re eligible for the leave you applied for. We need to review some information before we can make a decision.";
  static You_MEET_REQ =
    "You meet the requirements for at least one type of leave plan. But being eligible doesn’t mean you’re automatically approved. We still need to review some information before we can make a decision.";
  static HOUR_LABEL = "&nbsp;hour&nbsp;";
  static HOURS_LABEL = "&nbsp;hours&nbsp;";
  static SELECTED_DATE = "Selected Date:";
  static REMAINING_TIME = "remaining across all claims";

  /** for freq and duration page */
  static ABSENCE_MSG =
    "Here’s the time you can take off for your leave. It’s broken down into two categories: the number of absences you can take in a given period of time, and the amount of time you can take for each absence.";
  static ABSENCE_AS = "Absences as";
  static MEDICALLY_NEEDED = "medically needed";
  static ABSENCES = "absences";
  static HOURS_AS = "Hours as";
  static PER_ABSENCE = "per absence";
  static FLARE_UNSCHEDULED = "Flare-ups";
  static SCHEDULED_APP = "Appointments";

  /** Leave plan page and parent component */
  static LEARN_PLAN = "Learn more about this plan";
  static DONT_HAVE_DESCRIPTION =
    "We don’t have a description yet for this leave plan. To learn more,";
  static SEE_MORE_REQ = "See More Requests";
  static NO_DESC_TITLE = "We’re sorry.";
  static LEAVE_MODAL = "leaveModal";

  /** Leave Plan Balance */
  static APPROVED_LEAVE_LABEL = "Approved Leave";
  static LEAVE_BALANCES = "Leave Balances";
  /* update as part of US74239*/
  static LIST_OF_APPROVED_LEAVES =
    "Below you’ll see a list of your approved leaves. This includes the time you’ve already taken. It doesn’t include the time you’ve been approved to use in the future.";
  static TIME_ALREADY_TAKEN =
    "This includes the time you’ve already taken. It doesn’t include the time you’ve been approved to use in the future.";
  static CLICCK_HERE_LABEL = "click here";
  static AS_OF = "(As of";
  /**  VIEW LETTERS */
  static NO_LETTER_MSG = "There are no letters for this claim.";
  static NO_LETTERS_MSG_AA = "There are no letters for this claim at this time.";
  static SEE_MORE_LETTERS = "See More Letters";
  static MORE_LETTERS_LOCATION = "/moreLetters";
  static ANALYTICS_LETTER = " LETTERS";
  static VIEW_LETTER_LABEL = "View Letter";
  static BACK = "Back";
  static SITE_BACK = "Site Back";

  /**  VIEW LETTERS */
  /**  MORE LETTERS */
  static LETTERS_LABEL = "Letters";
  static DATE_ADDED_LABEL = "Date Added";
  static DOCUMENT_LABEL = "Document";
  /**  MORE LETTERS */
  /** VIEW VIDEOS */
  static APPROVAL_VIDEO_USECASE_NAME = "ClaimApproval";
  static DAY1_VIDEO_USECASE_NAME = "ClaimIntro";
  static CONCURRENT_VIDEO_USECASE_NAME = "ClaimConcurrent";
  static SEE_MORE_VIDEOS = "See More Videos";
  static MORE_VIDEOS_LOCATION = "/moreVideos";
  /** schedule call link */
  static URL_SCHEDULE_CALL_EDIT = "schedule-appointment/scheduleCallEdit";
  static SCHEDULE_CALL = "Schedule A Call";
  static SCHEDULE_A_CALL_LABEL = "Schedule a call";
  static SCHEDULE_CALL_ID = "ScheduleCallId";
  static PAGE_TAG = "Page Tag";
  static BUTTON_CLICK = "Button Click";

  /** Benefit Breakdown */

  static BENEFIT_BREAKDOWN = "Benefits Breakdown";
  static BENEFIT_BREAKDOWN_UN = "Benefits_Breakdown";
  static TIER = "tier";
  static PERCENTAGE = "percentage";
  static FLAT = "flat";
  static PB = "pb";
  static daysPaid = "[Payment Period/Days Paid]";
  static MAX_BENEFIT_VALUE = "[MaxBenefitValue]";

  /** Interactive Request Extension */
  static INTERACTIVE_REQUEST_EXTENSION = "THAA Interactive Extension";
  static INTERACTIVE_REQUEST_EXTENSION_UN = "THAA_Interactive_Extension";
  static CALCULATED_DATE = "[calculated date]";
  static EXTENSION_DESC = "You've requested an extension through ";
  static DIAGNOSIS_DESC = ". Next, let's go over your diagnosis.";

  /** ADA request component */
  static DATE_RANGE = "Date Range";
  static DESCRIPTION = "Description";
  static SEE_MORE = "See More";
  static URL_ADA_REQUESTS = "/leave-details/adaDetails";
  static ADA_REQUESTS = "ADA Request";
  static AT_WORK_LABEL = "At Work";
  static NOT_AT_WORK_LABEL = "Not At Work";
  static ACCOMMODATION_DETAIL = "Accommodation Detail";
  static ADA_DENIAL_REASON = "Your denial letter has details about why this wasn't approved.";

  /** IMAGE PATHS FOR ALL THE FILES*/
  static LEAVEOFABSENCE = "leave-of-absence.svg";
  static ADA_ACCOMODATION_ICON = "ada-accommodation-icon.svg";
  static GREEN_CHECK_ICON = "../assets/images/icons/green-check.svg";
  static ORANGE_ALERT_LG_ICON = "./assets/images/icons/orange-alert-lg.svg";
  static BLUE_EXCLAMATION_ICON = "./assets/images/icons/blue-information.svg";
  static RED_EXCLAMATION_ICON = "./assets/images/icons/red-information.svg";
  static LIGHTBULB_ICON = "./assets/images/icons/Lightbulb-5.svg";
  static TOOLTIP_ICON = "./assets/images/icons/Information.svg";
  static DENIED_ICON = "../assets/images/icons/denied-Icon.svg";
  static UNDER_REVIEW_DARKER = "../assets/images/icons/underReviewDarker.svg";
  static DENIED_CIRCLE = "../assets/images/icons/deniedCircle.svg";
  static REVIEW_ICON = "../assets/images/icons/review-Icon.svg";
  static QUE_ICON = "/assets/images/icons/Question.svg";
  static WHITE_CLOSE = "/assets/images/icons/white-x close.svg";
  static GRAY_CARET_ICON = "../../assets/images/icons/right-caret-gray.svg";
  static CALL_SVG = "../../../../../../assets/images/icons/call.svg";
  static MISSING_PAGE_SVG = "../../../../../assets/images/icons/missing-page.svg";
  static GREEN_DOTHALO_ICON = "./assets/images/icons/greendot-halo.svg";
  static GREEN_CHECK_ICON_1 = "./assets/images/icons/green-check.png";
  static ORANGE_ALERT_ICON = "./../../../assets/images/icons/orange-alert-lg.svg";
  static NOT_AUTH_ICON = "/assets/images/icons/not-authorized.svg";
  static PHONE_ICON = "/assets/images/icons/phone.svg";
  static ALERT_ICON = "/assets/images/icons/alert-icon.svg";
  static WARNING_ICON = "/assets/images/icons/warning-icon.svg";
  static SUCCESS_ICON = "/assets/images/icons/success-icon.svg";
  static INFO_ICON = "/assets/images/icons/information-icon.svg";
  static CLOSE_HARTFORD_GRAY_ICON = "/assets/images/icons/close-hartfordgray.svg";
  static DOWNLOAD_FORM_SVG = "/assets/images/icons/Download-Form.svg";
  static PAYMENT_ARROW_SVG = "../../../../../assets/images/icons/payment-arrow.svg";
  static CLOSE_HARTFORD_GRAY_SVG = "../assets/images/icons/close-hartfordgray.svg";
  static PROFILE_COMPLETE_PNG = "./assets/images/icons/profile-complete.png";
  static STATUS_IN_PROGRESS_ICON_SVG = "./assets/images/icons/status-in-progress-icon.svg";
  static STATUS_TO_BE_DONE_ICON_SVG = "./assets/images/icons/status-to-be-done-icon.svg";
  static STATUS_DENIED_ICON_SVG = "./assets/images/icons/status-denied-icon.svg";
  static STATUS_COMPLETE_ICON_SVG = "./assets/images/icons/status-complete-icon.svg";
  static SUCCESS_CHECKMARK_SVG = "./assets/images/icons/success-checkmark.svg";
  static WHITE_X_CLOSE_SVG = "./assets/images/icons/white-x close.svg";
  static PROFILE_COMPLETE = "./assets/images/icons/profile-complete.png";
  static BENEFIT_ICON_URL = "../../../../assets/images/icons/newclaim.png";
  static LEFT_CARET_ICON = "../../../../assets/images/icons/left-caret-14h%20blue.png";
  static RIGHT_CARET_ICON = "../../../../assets/images/icons/right-caret-14h%20blue.png";
  static MATERNITY_SVG = "../assets/images/icons/maternity.svg";
  static ERROR_SVG = "/assets/images/icons/error.svg";
  static CLAIMS_SVG = "/assets/images/icons/claims.svg";
  static DOCUMENT_RIGHT_CARET_ICON = "/assets/images/icons/right-caret-gray.svg";
  static PHA_SVG = "/assets/images/icons/pha.svg";
  static PHA_GREEN_CHECK = "./../../../assets/images/icons/green-check.svg";
  static GREEN_CHECK_PNG = "../../../../assets/images/icons/green-check.png";
  static LINKING_PROFILE_COMPLETE = "../../../../../assets/images/icons/profile-complete.png";
  static PROFILE_COMPLETE_PNG2 = "../assets/images/icons/profile-complete.png";
  static ERROR_SVG2 = "../../../assets/images/icons/error.svg";
  static CHECK_IMAGE_PNG = "../../../../../../assets/images/icons/Check_Image.png";
  static CLAIM_WHITE_SVG = "/assets/images/icons/claims_white.svg";
  static RIGHT_CARET_WHITE_SVG = "../../../../../../assets/images/icons/right-caret-white.svg";
  static CTA_RIGHT_ARROW_SVG = "/assets/images/icons/cta-right arrow.svg";
  static PDF_ICON = "/assets/images/icons/pdf-icon.svg";
  static BLUE_NOTIFICATION_BELL = "/assets/images/icons/notification-bell-blue.svg";
  static CUSTOM_ERROR_ICON = "assets/images/icons/something-went-wrong.svg";
  static STD_ELIGIBILITY_ICON = "assets/images/icons/Need-to-call.svg";

  /** IMAGE PATHS FOR ALL THE FILES ENDS HERE*/

  static APP_INFO = "App Info";
  static BUILD_NUMBER = "Build Number:";
  static APPLY = "Apply";
  static SEE_DETAILS = "See Details";

  /** Claim card Labels start */
  static SURGERY_DATE_LABEL = "Estimated Surgery Date";
  static DELIVERY_DATE_LABEL = "Expected Date Delivery";
  static CLAIM_UNDER_REVIEW_MESSAGE =
    "Your Claim Request has been received. We are working to team you up with your Hartford Claims Representative";
  static CLAIM_CLOSED_MESSAGE =
    "Your feedback is valuable to us. Please fill out the survey below.";
  static EXTENSION_UNDER_REVIEW = "Extension Under Review";
  static EXTENSION_CLOSED = "Extension Closed";
  static EXTENSION_DENIED = "Extension Denied";
  static EXTENSION_APPROVED = "Extension Approved";
  static EXTENSION_STATUS = "Extension Status";
  static EXTENSION_REQUESTED = "Extension Requested";

  /** Claim card Labels end */

  /**  CLAIM_DETAILS*/
  static CLAIM_DETAILS = "Claim Details";
  static REPOT_ABSENCE_LBL = "Reporting an Absence?";
  static REPOT_ABSENCE_MSG =
    " to report an absence. You can tell us about absences you've already had, or times you expect to be out in the future.";
  static REPOT_ABSENCE_SUBMIT_MSG =
    "You can tell us about absences you’ve already had, or when you expect to be out in the future.";
  static ID = "ID";
  static CLAIM_CLOSED = "Claim Closed";
  static REQUEST_AN_EXT = "Request An Extension";
  static REQUEST_AN_EXT_THAA = "Request an Extension";
  static INTERACTIVE_REQUEST_AN_EXT_THAA = "Request an Extension";
  static REQUEST_AN_EXT_UN = "Request_An_Extension";
  static THAA_REQUEST_AN_EXT = "THAA Request An Extension";
  static THAA_REQUEST_AN_EXT_UN = "THAA_Request_An_Extension";
  static INTERACTIVE_THAA_REQUEST_AN_EXT_UN = "THAA_Interactive_Request_An_Extension";
  static EXACT = "I have an exact date.";
  static ESTIMATE = "I need to estimate.";
  static FULL = "Full";
  static PARTIAL = "Partial";
  static CLAIM_PROCESS = "Claims Process";
  static SUBMIT_YOUR_ABS_DTLS = "Submit Your Absence Details";
  static RECENTLY_RESOLVED_LABEL = "See Recently Resolved Items";
  static MORE_DETAILS = "MORE DETAILS";
  static LESS_DETAILS = "LESS DETAILS";
  static APPROVED_START_DATE = "Approved Start Date";
  static REASON_FOR_EXTENSION = "reason for extension request";
  static EXACT_DATE = "I have an exact date";
  static ESTIMATE_DATE = "I need to estimate";
  static APPROVED_THROUGH_DATE = "Your current approved-through date";
  static EXTENSION_MESSAGE =
    "Please enter the end date for your extension request. If you don't have an exact date, you can select the number of days or weeks you expect you'll need.";

  /**  CLAIM_DETAILS*/

  /** CLAIM HISTORY start*/
  static BENEFITS_HISTORY = "Benefits History";
  static DATA_AVAILABLE = "Data not available for insured ID";
  /** CLAIM HISTORY end*/

  /** CLAIM PAYMENTS START */
  static PAYMENT_ACCOUNT = "Payment Account";
  static PAYMENT_AMOUNT = "Payment Amount";
  static AMOUNT = "Amount";
  static PAYMENT_DATE = "Payment Date";
  static PAYMENT_METHOD = "Payment Method";
  static MAILING_ADDRESS = "Mailing Address";
  static EDIT_INFORMATION = "Edit Information";
  static ENROLL_IN_DIRECT_DEPOSIT = "Enroll in Direct Deposit";
  static PAYMENT_SCHEDULE = "Payment Schedule";
  static SEE_PAYMENT_SCHEDULE = "See Payment Schedule";
  static SEE_MORE_PAYMENTS = "See More Payments";
  static YOUR_DOCUMENTS = "Your Documents";
  static LEAVE_DETAILS_YOUR_DOCUMENTS = "Your Documents and Videos";
  static CLAIM_DETAILS_YOUR_DOCUMENTS = "Your Documents and Videos";
  static YOUR_DOCUMENTS_HEADER = "yourDocuments";
  static FILE_UPLOAD = "Upload";
  static FILE_PASSWORD_PROTECTED_MESSAGE =
    "Please make sure the file(s) you upload aren't password-protected. We can't open password-protected files, so sending them may delay your claim.";
  static FILE_SIZE_UPLOAD_MESSAGE = "Maximum File size is 10 MB.";
  static LINK_TO_SEE_MORE_PAYMENTS = "/claimDetails/seeMorePayments";
  static LINK_TO_SEE_CLAIM_DETAILS = "/claimDetails";
  static LINK_TO_PAYMENT_SCHEDULE = "/claimDetails/paymentSchedule";
  static LINK_TO_SEE_MORE_FUTURE_PAYMENTS = "/claimDetails/futurePayments";
  static DIRECT_DEPOSIT = "Direct Deposit";
  static CHECK = "Check";
  static ADDRESS = "Address";
  static OFFSETS = "Offsets";
  static CLAIM_ADJUSTMENTS = "Claim Adjustments";
  static ASSIGNED_PAYMENTS = "Assigned Payments";
  static BENEFIT_DEDUCTIONS = "Benefit Deductions";
  static FUTURE_PAYMENT = "Benefits Breakdown";
  static PAYMENT_DETAILS = "Payment Details";
  static PAYMENT = "Payment";
  static PAYEE = "Payee";
  static TYPE = "Type";
  static GROSS = "Gross";
  static TAX_DEDUCTIONS = "Tax Deductions";
  static PREVIOUS_PAYMENTS = "Previous Payments";
  static FOLLOWING_PAYMENTS = "Following Payments";
  static PAY_STUB = "Pay Stub";
  static PAY_STUB_PDF = "PDF";
  static HARTFORD_LOGO_ID = "hartfordLogo";
  static HARTFORD_LOGO_LINK_ID = "hartfordLogoLinkId";
  /** CLAIM PAYMENTS END */

  /** CLAIM HISTORY START */
  static PAYMENT_HISTORY = "Payment History";
  /** CLAIM HISTORY END */

  /** CLAIM_TIMELINE*/
  static CLAIM_APPROVED = "Claim Approved";
  static AWAITING_DELIVERY = "Awaiting Delivery";
  static AWAITING_SURGERY = "Awaiting Surgery";
  static AWAITING_DISABILITY = "Awaiting Disability";
  static AWAITING_PAID_FAMILY_LEAVE = "Awaiting Paid Family Leave";
  static CLAIM_REVIEW = "Claim Review";
  static AWAITING_MILITARY_LEAVE = "Awaiting Military Leave";

  /** CLAIM_TIMELINE*/

  /**TIMELINE STEP*/
  static STATUS_COMPLETE = "COMPLETED";
  static STATUS_DENIED = "DENIED";
  static STATUS_CURRENT = "CURRENT";
  static STATUS_TO_BE_DONE = "TO BE DONE";

  /**REGULAR FONT*/
  static STATUS_FONT_SIZE_DEFAULT = "smaller";
  static STATUS_FONT_COLOR_DEFAULT = "gray-hartford";
  static STATUS_FONT_WEIGHT_DEFAULT = "book";

  /*claim activity history starts*/
  static BG_COLOR_CLASS = "custome_Class";
  static GET_FOOTER_1 = "footer1";
  static GET_FOOTER = "footer";
  static URL_CLAIM_ACTIVITY = "/claimDetails/claimActivity";
  static CLAIM_ACTIVITY_LABEL = "Your Claim Activity";
  static DATE_LABEL = "Date";
  static ACTIVITY_LABEL = "Activity";
  static CATEGORY = "Category";
  static NO_RECENT_ACTIVITY_DISPLAY_LABEL = "No Recent Activity to Display";
  static SEE_MORE_ACTIVITIES = "See More Activities";
  static IDE_ELEMENT = "ideElement";
  /*claim activity history ens*/

  /*claim denied display start*/
  static CLAIM_DENIED_LABEL = "Claim Denied";
  /*claim denied display end*/

  /*claim denied timeline start*/
  static CLAIM_SUBMITTED_LABEL = "Claim Submitted";
  static UNDER_REVIEW_LABEL = "Under Review";

  /*claim denied timeline end*/

  /*claim wizard error start*/
  static RIGHT_TYPE_CLAIM_TITLE = "Find the Right Type of Claim";
  static TITLE_1_WER = "Your employer doesn't allow for you to submit claims online. ";
  static TITLE_2_WER = "Desktop Browser Recommended";
  static TITLE_3_WER = "Let's chat instead.";
  static DES_1_WER =
    "For information on how to file a claim, contact your employer or human resources administrator.";
  static DES_2_WER =
    "It looks like you're using a mobile device to manage your  claim. For the best experience, we recommend using your desktop browser. Hit Go Back to return to the previous page.";
  static DES_3_WER =
    "Sometimes it can be tough to figure out exactly what benefit you need. Call us at 1-800-549-6514 for help finding the right claim for you.";
  static TIME_OFF_NEED = "What kind of time off do you need?";
  static URL_HOME = "/home";
  static URL_INTAKE = "/intake";
  static WELCOME_MESSAGE = "Welcome, ";
  static DELIVER_MESSAGE =
    "Please come back and let us know once you deliver your little one. If you have questions before then,\n" +
    "    please feel free to reach out to your claims representative.";
  static CONFIRM_TEXT = "Please confirm the details of your last day worked below.";
  //static HOURS_TEXT = 'Please enter your hours worked.'
  static URL_LOGIN = "/login";
  static URL_CLAIM_WIZ_ERR = "claim-wizard/error";
  static NAV_TO = "navTO";
  /*claim wizard error end*/

  static URL_CUSTOMERROR = "/custom-error";
  static URL_STDELIGIBILITY = "/custom-std-eligibility-check";

  /*claim wizard post login start*/
  static UNABLE_CASE = "unable";
  static MOBILE_CASE = "mobile";
  static CALLUS_CASE = "callus";
  static CLAIMWIZ_TEXT =
    "Next, Answer a few quick questions to help us find the right benefit for you.";
  /*claim wizard post login end*/

  /*claim wizard pre login start*/
  static POLICY_NUMBER_LABEL = "Policy Number";
  static POLICY_NUMBER_LABEL_TEXT = "policy-number";
  static CANCEL_CLAIM_TEXT = "cancelNewClaim";
  static URL_THAA = "/thaa-modal";
  static URL_CLAIMWIZ_ERR = "/claim-wizard/error";
  static URL_CLAIMWIZ_QUES = "/claim-wizard/questions";
  static DATE_INFO_TEXT = "This date should come after the first one you entered.";
  static DELIVERY_TEXT = "Please select your delivery type.";
  static LAST_DAY_AT_WORK = "Your last day at work must be on or before your delivery date";
  static DELIVERY_INFO_TEXT = "Last Day Work Is Greater Than The Date Of Delivery";
  static CLAIMWIZ_PRETEXT =
    "To start, enter your policy number. We'll use it to see which coverages are available to you.";
  /*claim wizard pre login end*/

  /*claim wizard recomm start*/
  static FILE_A_CLAIM_TEXT = "Learn more before you file a claim";
  static DIFF_CLAIM_TEXT = "Pick a different claim instead.";
  static URL_PRD_8 = "/productInfo/8";
  static URL_PRD_9 = "/productInfo/9";
  static URL_PRD_7 = "/productInfo/7";
  static URL_PRD_6 = "/productInfo/6";
  static URL_PRD_5 = "/productInfo/5";
  static URL_PRD_4 = "/productInfo/4";
  static URL_PRD_3 = "/productInfo/3";
  static PRODUCT_LINK_TEXT = "productLink";
  static PRODUCT_CANCEL_TEXT = "product-cancel";
  static VOL_TITLE =
    "You're also enrolled for the coverages below. Click any icon to learn more or start filing a claim.";
  static MANAGE_LEAVE = "Manage My Leave";
  static NEW_TAB = "newTab";
  static LOG_IN = "Log In";
  static EXTERNAL = "external";
  static URL_RECOMM = "url";
  static DOWNLOAD_PDF = "Download PDF";
  static DOWNLAOD_TEXT = "download";
  static START_MY_CLAIM_TXT = "Start My Claim";
  /*claim wizard recomm end*/

  /*claim status comp start*/
  static URL_SELECT_REASON = "/leave-intake/selectReason";
  static URL_START_CLAIM = "/intake";
  static MSG_AUTO_CLS_TEXT = "true";
  static BEGIN_NEW_CLAIM_TEXT = "Begin a New Claim";
  static YOUR_BENEFITS_TEXT = "Your Benefits";
  static START_NEW_CLAIM_TEXT = "Start a New Claim";

  /*claim status comp end*/

  /*common question comp start*/
  static SEE_MORE_LINK = "See More";
  static LEFT_ALIGN_TEXT = "left";
  static PRODUCT_INFO_STRNG = "productInfo";
  static URL_FAQ = "/faq/";
  static URL_FAQ_1 = "/faq";

  /*common quest comp end*/
  /*contact-us comp start*/
  static NEDD_HELP_TXT = "Need Help?";
  static CONTACT_US_TXT = "Contact Us";
  static CONTACT_LOGO_NAME = "contact";
  static URL_CONTACT_US = "/contactUs";
  static URL_CLAIM_DETAILS = "/claimDetails";
  static URL_CLAIM_DETAILS_1 = "claimDetails";
  static URL_LEAVE_DETAILS = "/leave-details/leaveDetails";
  static URL_OLD_CALC_DETAILS = "/leave-detail/loaDetails";
  static HOME_TEXT = "home";
  static URL_PHA_DETAILS = "/phaoverview/phadetails";
  static URL_SINGLE_PAYMENT = "/claimDetails/singlePayment";
  static URL_LEAVE_PLAN = "/leave-details/leavePlan";
  static URL_LEAVE_PLAN_DETAILS = "/leave-details/leavePlanDetails";
  static URL_LOA_LEAVE_PLAN_DETAILS = "/leave-details/loaPlanDetails";

  /*conatct-us  comp end*/

  /*conatct comp start*/
  static PHONE_TEXT = "Phone";
  static INSURED_ID_LABEL = "Insured ID";
  /*static ADDRESS_TEXT = 'Address';*/
  /*conatcat comp end*/
  /*date label val comp start*/
  static APPROVED_THRGH_DATE_TEXT = "Approved Through Date";
  static APPROVED_THRGH_DATE_TEXT_THAA = "Your current approved through date";
  static GO_TO_DAY_ABS = "Go To Day One Absence";
  /*date label val comp end*/

  /** CARD_CAROUSAL*/
  static ANIMATION_DUR = "250ms ease-in";
  static I_AM_A_CLAIM_CARD = "I am A Claim Card";
  /** CARD_CAROUSAL*/

  /** CLAIM_HANDLER*/
  static UR_CLAIMS_REP = "Your Claims Representative";
  static WANNA_TALK_ABT_ONE_OF_UR_CLAIM = "Want to talk about one of your claims?";
  /** CLAIM_HANDLER*/

  /** CLAIM_REP_INFO*/
  static URL_SCHEDULE_CALL = "/schedule-appointment/scheduleCall";
  static CLAIM_REP_BOX = "claim-rep-box";
  static CLAIM_REP_INFOBOX = "claim-rep-infobox";
  static EXTENSION = "Extension: ";
  static CALL_UR_REP = "Call Your Representative";
  /** CLAIM_REP_INFO*/

  /** FAQ*/
  static COMMON_QUE = "Common Questions";
  static WAS_THIS_ANS_HELPFUL = "Was this answer helpful ?";
  static PLUS_ICON = "../../../../../assets/images/icons/plus.svg";
  static MINUS_ICON = "../../../../../assets/images/icons/minus.svg";
  static THUMBS_UP_ICON = "../../../../../assets/images/icons/thumbs-up.svg";
  static THUMBS_DOWN_ICON = "../../../../../assets/images/icons/thumbs-down.svg";
  static HIGHLIGHTED_THUMBS_UP_ICON = "../../../../../assets/images/icons/thumbs-up-highlight.svg";
  static HIGHLIGHTED_THUMBS_DOWN_ICON =
    "../../../../../assets/images/icons/thumbs-down-highlight.svg";
  static PLUS_ADD_ICON = "../../../../../assets/images/icons/icon-add-function.svg";
  /** FAQ*/

  /** FEATURE_DOWN*/
  static ERROR = "Error";
  static WE_R_MAKING_UPDATES = "We're making updates!";
  static RETURN_TO_HOME = "Return To Home";
  static PLEASE_TRY_AGAIN_LATER = "Please try again later";
  /** FEATURE_DOWN*/

  /** MSG_RIBBON*/
  static ERROR_SM = "error";
  static ERROR_LG = "Error";
  static WARNING_SM = "warning";
  static WARNING_LG = "Warning";
  static SUCCESS_SM = "success";
  static SUCCESS_LG = "Success";
  static INFO_SM = "info";
  static DANGER_LG = "Danger";
  static INFORMATION_LG = "Information";
  static RED_SM = "red";
  static ORANGE_SM = "orange";
  static GREEN_SM = "green";
  static BLUE_SM = "blue";
  /** MSG_RIBBON*/

  /**  MISSING_INFO_DETAILS*/
  static MISSING_INFORMATION = "Missing Information";
  static REQUIRED_BY = "required by";
  static UPLOAD_MISSING_DOCS = "Upload Missing Documents";
  static UPLOAD_MISSING_DOC_MODAL = "uploadMissingDocumentModal";
  static UPLOAD_MISSING_THAA_DOC_MODAL = "uploadMissingDocumentThaaModal";
  static EXPAND = " - expand";
  static COLLAPSE = " - collapse";
  static DOWNLOAD = " - download";
  static PAPERLESS_INTAKE = "paperless intake";
  static DETAILS = "Details";
  static PLUS_SVG = "assets/images/icons/plus.svg";
  static MINUS_SVG = "assets/images/icons/minus.svg";
  static SOURCE = "Source";
  /**  MISSING_INFO_DETAILS*/

  /** NEXT_PAYMENT_BLOCK*/
  static MOST_RECENT_PAYMENT = "Most Recent Payment";
  static MOST_RECENT_PAYMENT_COLON = "Most Recent Payment: ";
  static NEXT_PAYMENT = "Next Payment";
  static NEXT_PAYMENT_COLON = "Next Payment: ";
  static CHECK_SPACE = "Check ";
  static ACCT = "Acct.";
  /** NEXT_PAYMENT_BLOCK*/

  /**  SAVE_MSG_BANNER*/
  static UR_CHANGES_HAVE_BEEN_SAVED = "Your changes have been saved.";
  /**  SAVE_MSG_BANNER*/

  /**  SECONDARY_MSG_RIBBON*/
  static SORRY = "Sorry...";
  static SOMETHING_WRONG_WITH_REQ = "It looks like there is something wrong with your request";
  static BOLD = "bold";
  static LARGER = "larger";
  /**  SECONDARY_MSG_RIBBON*/

  /**  CONFIRM_DETAILS_PAGE*/
  static CONFIRM_DISABILITY = "Confirm Disability";
  static CONFIRM_PAID_FAMILY_LEAVE = "Confirm Paid Family Leave";
  static THANKS_FOR_CONFIRMING =
    "Thanks for confirming the start date for your paid family leave. We need some additional information before we let your claims representative know.";
  /**  CONFIRM_DETAILS_PAGE*/

  /**  CONFIRM_DISABILITY_MODEL*/
  static URL_CONFIRM_DIS_DETAIL = "/disability/confirmDisabilityDetail";
  static URL_NEW_DIS_DATE = "/disability/newDisabilityDate";
  static URL_NEW_DIS_DATE_THAA = "/disability/new-Disability-Date";
  static NO_I_HAVE_DIFF_DATE = "No, I have a different date";
  static DCS_MSG_PART_ONE = "We noticed your disability date has passed. Is";
  static DCS_MSG_PART_TWO = "still the right date?";
  static THAA_MSG_PART_ONE = "It looks like your disability date is";
  static THAA_MSG_PART_TWO = "Is this still right?";
  static PFL_MSG_PART_ONE = "We noticed the start date for your paid family leave has passed. Is";
  static PFL_MSG_PART_TWO = "still the right date?";
  /**  CONFIRM_DISABILITY_MODEL*/

  /**  DISABILITY_LAST_DAY_SECTION*/
  static CONFIRM_DIS_DETAILS = "Confirm Disability Details";
  static URL_DIS_THANK_U = "/disability/disabilityThankYou";
  static PLEASE_ENTER_VALID_DATE = "Please enter a valid date.";
  static LAST_DAY_WRK_DT_MUST_BE_ON_OR_BEFORE =
    "Your last date worked must be on or before your disability date.";
  static RETURN_WRK_DT_MUST_BE_ON_OR_BEFORE =
    "Your return to work date must be on or before your last day at work date.";
  static PLEASE_ENTER_UR_HRS_WRKD = "Please enter your hours worked.";
  static URL_CONFIRM_SURGERY = "/surgery/confirmSurgery";
  static REASON_OUT = [
    {
      personalTime: "Personal time",
    },
    { personalTime: "Sick time" },
    { personalTime: "Not scheduled to work" },
    { personalTime: "Already on a leave of absence" },
    { personalTime: "Laid off" },
    { personalTime: "Other" },
  ];
  /**  DISABILITY_LAST_DAY_SECTION*/

  /**  DISABILITY_THANK_YOU_SECTION*/
  static DCS_THANK_YOU_PART_ONE = "Your claims representative";
  static DCS_THANK_YOU_PART_TWO =
    "will review the details you just provided, and should be in touch in the next 5 business days.";
  static THAA_THANK_YOU_PART_ONE = "Thanks! Your claims representative";
  static THAA_THANK_YOU_PART_TWO =
    "will review the details you just provided. They should be in touch in the next 5 business days.";

  /**  DISABILITY_THANK_YOU_SECTION*/

  /**  NEW_DISABILITY_DATE_PAGE*/
  static NEW_DIS_DATE = "New Disability Date";
  static NEW_DIS_DATE_THAA_THANKS =
    "Thanks for letting us know that your disability date has changed.";
  static NEW_DIS_DATE_THAA_INFORM =
    " We'll need to inform your Hartford Claims Representative of your new disability date.";
  static NEW_DIS_DATE_THAA = "What is your new disability date?";
  static NEW_DIS_DATE_ERROR = "Please enter your new disability date";
  static NEW_START_DATE_FOR_UR_PFL = "New Start Date for Your Paid Family Leave";
  /**  NEW_DISABILITY_DATE_PAGE*/

  /*confirm delivery comp start*/
  static Num_CHILDREN_DELIVER_QUES = "How many children did you deliver?";
  static PICKED_NAME_YET_LINKNAME = "I haven't picked a name yet";
  static PICKED_NAMES_YET_LINKNAME = "I haven't picked names yet";

  static NOT_YET_NAMED_BABY = "Not Yet Named";
  static WHEN_WAS_LABEL = "When was";
  static WHEN_WERE_LABEL = "When were";
  static YOUR_CHILD_LABEL = "your child";
  static BORN_TEXT = "born?";
  static THROUGH_TEXT = "Through";
  static DAY_TXT = "Day";
  static MONTH_TXT = "Month";
  static HOW_YOU_DELIVERY_QUES = "How did you deliver?";
  static HOURS_TXT = "Hours";
  static NO_TXT = "NO";
  static YES_TXT = "Yes";
  static YES = "yes";
  static DATE_OF_BIRTH = "dateOfBirth";
  static NO_TXT_1 = "No";
  static LAST_WORK_DATE = "lastWorkDate";
  static C_SECTION_DELTYPE = "C-Section";
  static VAGINAL_DELTYPE = "Vaginal";
  static WHEN_QUES = "When?";
  static DAY_TXT_1 = "day";
  static HOURS_WORKED = "hoursWorked";
  static NORMAL_DELIVERY = "Normal Delivery";
  static URL_DELIVERY_CONGRATS = "/delivery/deliveryCongrats";
  static DELIVERY = "delivery";
  static WELCOMED_TEXT = "Have you welcomed your little one yet?";
  static HOURS_WORK_LAST_DAY_QUES = "How many hours did you work on your last day?";
  static STOPPED_WORKING_TXT = "No, but I've stopped working";
  static STILL_WORKING_TXT = "No, and I'm still working";
  static NOT_READY = "We're Not Ready For You Yet!";
  static NOT_READY_DESC =
    "After you deliver, we’ll need some information about your delivery and last days at work. It looks like you’re not at that point yet. Please come back after you’ve delivered, or if you stop working before then.";
  static RETURN_TO_OVERVIEW = "Return to Overview";
  /*confirm delivery comp end*/
  /*confirm maternity start*/
  static URL_CONFIRM_DELIVERY = "/delivery/confirmDelivery";
  static URL_CONFIRM_DELIVERY_THAA = "/delivery/deliveryConfirmation";
  static URL_LAST_DAY_WORKED = "/delivery/last-day-worked";
  static URL_LAST_DAY_WORKED_THAA = "/delivery/last-day-worked-delivery";
  static URL_DELIVERY_THAA_CONGRATS = "/delivery/deliveryCongratulation";

  static MATERNITY_CLAIM_START = "Maternity Claim Start";
  // static CHECKBOX = 'I don\'t know';
  /*confirm maternity end*/
  /*thank you comp start*/
  static CONFIRM_DELIVERY_TXT = "Confirm Delivery";
  /*thank you comp end*/
  /*last day work comp start*/
  static LAST_DAY_WORKED = "Last Day Worked";
  static URL_THANK_YOU = "/delivery/thankyou";
  static URL_THANK_YOU_THAA = "/delivery/thank-you";
  /*last day work comp end*/
  /*multiple confirm del start*/
  static CHILD_DIFF_DAYS_LINKNAME = "My children were born on different days";
  static NEWBORN_NAMES_QUE = "What are your newborns' names?";
  static NEWBORN_NAME_QUES_1 = "What are you newborn's names?";
  /*static LAST_DAY_WORK = 'lastDayWorked';*/
  /*static DELIVERY_TYPE = 'deliveryType';*/
  /*multiple confirm del end*/

  /** CONFIRM SURGERY START */
  static CONFIRM_SURGERY = "Confirm Surgery";
  static SURGERY_DATE_MESSAGE = "Thanks for letting us know that your surgery date has changed.";
  static NEW_SURGERY_DATE_LABEL = "New Surgery Date";
  static LAST_DAY_WORK_MESSAGE = "Your last date worked must be on or before your surgery date.";
  static CONFIRM_MESSAGE = "Thanks for confirming your surgery date";
  static CONFIRM_SURGERY_CONFIRM_PAGE_ID = "Confirm Surgery Confirm";
  static CONFIRM_SURGERY_CONFIRM_OWCS = "Confirm_Surgery_Confirm";
  static CONFIRM_SURGERY_DETAILS_PAGE_ID = "Confirm Surgery Details";
  static CONFIRM_SURGERY_DETAILS_OWCS = "Confirm_Surgery_Details";
  static THAA_CONFIRM_SURGERY_PAGE_ID = "THAA Confirm Surgery";
  static THAA_CONFIRM_SURGERY_OWCS = "THAA Confirm Surgery";
  /** CONFIRM SURGERY END */

  /** DIRECT DEPOSIT START */
  static ROUTING_NUMBER = "Routing Number";
  static BANK_NAME = "Bank Name";
  static ACCOUNT_TYPE = "Account Type";
  static ACCOUNT_NUMBER = "Account Number";
  static PARAGRAPH_FIRST =
    "It looks like we have out of sync payment information. Confirm whether following is correct.";
  static EDIT_INFORMATION_LINK = "I need to edit my information";
  static VIEW_EFT_INFO_URL = "myAccount/viewEftInfo";
  static DEPOSIT_METHOD_MODIFY = "MODIFY";
  static TERMS_AND_CONDITIONS = "termsAndConditions";
  static PAPERLESS_STATEMENT = "Paperless Statement";
  static TRUE_LABEL = "true";
  static PREFERENCE_VALUE_ON = "On";
  static PREFERENCE_VALUE_OFF = "Off";
  static DIRECT_DEPOSIT_LABEL = "Direct Deposit – Do not mail my pay stub";
  static DIRECT_DEPOSIT_TRUE = "Do not mail my pay stub _True";
  static DIRECT_DEPOSIT_FALSE = "Do not mail my pay stub _False";
  /** DIRECT DEPOSIT END */

  /*home comp start*/
  static SEE_CLAIM_HISTORY = "See Benefits History";
  static URL_CLAIM_HISTORY = "/ClaimHistory";
  static URL_CLAIM_WIZARD_1 = "/claim-wizard";
  static WELCOME_MESSAGE_TEXT = "Welcome,";
  static UPDATE_TCS_ALERT_MODAL = "update-tcs-alert-modal";
  static SESSION_TIME_OUT_MODAL = "SessionTimeOut";

  static URL_AMAZON_HOME = "/amazon/home";
  static WARNING_TXT = "Warning";
  static CLAIM_OVERVIEW = "claimOverview";
  static PRE_LOGIN = "preLogin";
  /*home comp end*/

  /**  EMPLOYEE-BENEFIT-REGISTRATION*/
  static EMPLOYEE_BENEFIT_REG = "Employee Benefits Registration";
  static URL_SITE_WIDE_TnC = "siteWidetermsAndConditions";
  static URL_TH_LEGAL_NOTICE = "https://www.thehartford.com/legal-notice";
  static URL_TH_ONLINE_PRIVACY_POLICY = "https://www.thehartford.com/online-privacy-policy";
  static URL_HOME_CHANNELID_1 = "/home?channelId=1";
  static FIRST_NAME = "First Name:";
  static LAST_NAME = "Last Name:";
  static IVE_RD_N_AGREE_TO_ALL = "I have read and agree to all";
  static SITE_WIDE_TnC = "SiteWide Terms and Conditions.";
  static CONDITION_AGREE_MSG =
    "I accept and agree to abide by all of the terms and conditions set forth herein, as well as the terms and conditions in the";
  /**  EMPLOYEE-BENEFIT-REGISTRATION*/

  /**  ERROR_COMPONENT*/
  static PAGE_NOT_FOUND = "Page Not Found";
  static WE_CANT_FIND_D_PAGE_UR_LOOKIN_FOR = `We can't find the page you're looking for.`;
  static WE_R_SORRY_FOR_INCONVENIENCE = `We're sorry for the inconvenience.`;
  static RETURN_TO_HOME_PG_MSG = `Return to Home Page`;
  /**  ERROR_COMPONENT*/

  /**  FEEDBACK_CIAM*/
  static FEEDBACK_MSG =
    "Thanks for your feedback! Close this tab to return to the page you were just on.";
  /**  FEEDBACK_CIAM*/

  /**  FIRST_NOTIFICATION*/
  static IT_LOOKS_LIKE_MAT_CLAIM_IS_RIGHT_FOR_U = "It looks like Maternity Claim is right for you.";
  static U_CAN_START_FILING_UR_CLAIM = `You can start filling your claim by clicking below.Please be aware that you'll be taken to a different website to file`;
  static START_A_CLAIM = "Start a Claim";

  /**  FIRST_NOTIFICATION*/

  /**  LOGIN*/
  static URL_START_NEW_CLAIM = "./startNewClaim";
  static SYSTEM_ALERT_MODAL = "SystemAlert";
  static URL_CLAIM_WIZARD = "/claim-wizard/";
  static DONT_HAVE_ACC_REG_NOW = `Don't have an account? Register Now`;
  static START_NOW = "Start Now";
  static THAT_DIDNT_WORK = `'That didn\'t work'`;
  /**  LOGIN*/
  /*cleanse address comp start*/
  static CLEANSE_ADDRESS =
    "The address you entered does not match our systems. Did you mean the following address:";
  static NEW_ADDRESS_BTN_TEXT = "Use new address";
  static ORIGINAL_ADDRESS_BTN_TXT = "Use original address";
  /*cleanse addresscomp end*/
  /*edit comp start*/
  static FIRST_NAME_EDIT = "First Name";
  static LAST_NAME_EDIT = "Last Name";
  static EMAIL = "Email";
  static MOBILE_NUMBER = "Mobile Number";
  static COUNTRY = "Country";
  static ZIPCODE = "Zip Code";
  static CITY = "City";
  static STATE = "State";
  static URL_MYACC_PROFILE = "/myAccount/profile";
  static CHANGES_HAVE_SAVED = "Your changes have been saved";
  static URL_MYACC_PROFILE_1 = "myAccount/profile";
  static TEXT_TCS = "textTcs";
  /*edit comp end*/

  /** CLAIM DOCUMENT START */
  static NO_DOCUMENTS_FOUND = "No Documents Found";
  static CLAIM_DOCUMENTS_URL = "/claimDocuments";
  static CLAIM_DOCUMENTS_URL_1 = "claimDocuments";
  static DOCUMENT_ACTIVE_TEXT = "Downloading your file";
  static SEE_MORE_DOCUMENTS = "See More Documents";
  static ALL_DOCUMENTS = "allDocuments";
  static LEAVE_DETAIL_PAGE_URL = "leave-details/leaveDetails";
  static RETURN_TO_BENEFIT_DETAIL_PAGE = "Return to Benefit Details Page";
  static RETURN_TO_DOCUMENTS_PAGE = "Return to Your Documents Page";
  static LEAVE_DETAILS_URL = "leaveDetails";
  static DOCUMENT_UPLOAD = "Document Upload";
  static DOCUMENT_UPLOAD_PAGE_NAME = "Document_Upload";
  static UPLOADING = "Uploading";
  static DOCUMENT_CONFIRMATION_PAGE_URL = "/uploadDocumentWizard/documentConfirmPage";
  static TRADE_MARK = "The Hartford. All rights reserved";
  static SELECT_MORE_DOCUMENTS = "Select more documents";
  static UPLOAD_DOCUMENTS = "Upload Documents";
  static UPLOAD_LBL = "Upload";
  static REASONS = "reasons";
  static MISSING_INFO_MESSAGE =
    "We noticed we're missing some information. If you're uploading anything on the list below, make sure to check it off.";
  static DOCUMENT_LIST_URL = "/uploadDocumentWizard/documentList";
  static SUPPORTED_FORMAT_MSG =
    "We accept documents in PDF, TIF and other image formats (including mobile phone photos)";
  static WE_LABEL = "We";
  static DONT_NOT_LABEL = "&nbsp;do not&nbsp;";
  static NOT_SUPPORTED_FORMATS =
    "accept Microsoft (e.g., Word, Excel, Powerpoint) or password-protected files.";
  static PASSWORD_PROTECTED = "accept password-protected files.";
  static DOCUMENT_UPLOAD_MAX_FILE_SIZE_MSG = "Maximum upload size is 10MB per file.";
  /** CLAIM DOCUMENT END */

  /**  RECENTLY_RESOLVED_COMPONENT*/
  static RECENTLY_RESOLVED_ITEMS = "Recently Resolved Items";
  static FROM_YOU = "From You";
  static FROM_YOUR_DOCTOR = "From Your Doctor";
  static FROM_YOUR_EMPLOYER = "From Your Employer";
  static DATES = "Date(s)";
  static EMPLOYER_TXT = "Employer";
  /**  RECENTLY_RESOLVED_COMPONENT*/

  /**  NOTIFICATION_REGISTRATION*/
  static NOTIFICATIONS = "Notifications";
  static COMM_PREF = "Communication Preferences";
  static UPDATE_COMM_PREFF = "Update your communication preferences";
  //static STAY_ON_TOP_OF_UR_CLAIM_STATUS_MSG = 'Stay on top of your claims status and payments with notifications by text, email, or both.';
  static STAY_ON_TOP_OF_UR_CLAIM_STATUS_MSG =
    "Let us know how to contact you with claims status and payment updates";
  static GET_NOTIFIED_WHEN_PAYMENT_ISSUED = "Get notified when a payment is issued";
  static SKELETON_CONTAINER = "skeleton-container";
  static BEFORE_U_CONTINUE = "Before you continue…";
  static TURN_OFF_NOTIFICATIONS = "Turn off notifications?";
  static SAVE_NO_MOB_MODAL = "saveNoMobModal";
  static URL_UPDATE_ERROR = "/update-error";
  static URL_CALL_US = "/call-us";
  static GET_NOTIFIED_ABT_CLAIM_EVENT =
    "Get notified about payments and status changes on your claim.";
  static CHANGE_SETTINGS = "You can change these settings anytime in your account preferences.";
  static EMAIL_NOTIFICATIONS = "Email Notifications";
  static TEXT_NOTIFICATIONS = "Text Notifications";
  static GOOD_TO_KNOW_MSG =
    "Good to know: Because direct deposit funds can take up to two business days to appear in your bank account, funds might not be immediately available when you get this notification.";
  static TEXT_NOTIFICATIONS_AA = "Text Messages";
  static EMAIL_NOTIFICATIONS_AA = "Email/Go Paperless";
  static EMAIL_PLACEHOLDER = "[email_address]";
  static MOBILE_PLACEHOLDER = "(___) ___-____";
  static DIRECT_DEPOSIT_STATUS_CODE = "942";
  static TAX_FORM_STATUS_CODE = "943";
  static TAX_FORM_ERROR_MESSAGE =
    "<div>It looks like we couldn&rsquo;t save the change to your <strong>tax form preference</strong>. Please refresh the page and try again. If you made other changes on this page, those changes were saved.</div>";
  static REVIEW_TERMS_AND_COND = "Review Terms & Conditions";
  static TAX_FORMS_GO_PAPERLESS = "Tax forms – Go Paperless";
  static TAX_FORMS_GO_PAPERLESS_TRUE = "Tax forms – Go Paperless_True";
  static TAX_FORMS_GO_PAPERLESS_FALSE = "Tax forms – Go Paperless_False";
  static COMM_TAX_FORM = "Communication Preferences_Tax forms – Go Paperless";
  /**  NOTIFICATION_REGISTRATION*/

  /**  POPUP_COMM_PREF*/
  static U_R_ADDING_TEXT_NOTIFICATION_MSG = `You're adding text notifications, but we don't have your mobile number saved. Please enter it below.`;
  static WE_WILL_SEND_TEXT_MSG = `We'll send a text to make sure we have it right.`;
  static IF_U_EVER_NEED_MSG =
    "If you ever need to update your mobile number, you can do so on the About You page.";
  static TnC = "Terms and Conditions";
  static SAVE_CHANGES = "Save Changes";
  static SAVE_CHANGES_EFT = "Save changes";
  static ACCEPT_TnC = "Accept Terms and Conditions";
  static MAIL_MY_TAX_FORMS = "Mail my tax forms";
  static TAX_FORM_AGREEMENT = "Electronic Tax Form Agreement";
  static TAX_FORM_MODAL = "TaxFormModel";

  /**  POPUP_COMM_PREF*/

  /**  POPUP_COMM_PREF-WITH-MOBILE*/
  static CHANGING_UR_TXT_PREF_MSG =
    "Changing your text preferences means that you will need to opt in to the terms & conditions once again.";
  static R_U_SURE_U_WANT_MSG = "Are you sure you want to change your text preferences?";
  /**  POPUP_COMM_PREF-WITH-MOBILE*/

  /** PAYMENT SCHEDULE START */
  static EFT_LABEL = "Electronic Funds (EFT) Date";
  static CHECK_LABEL = "Check Issue Date";
  static ISSUE_LABEL = "Issue Date";
  static MONTH = "Month";
  /** PAYMENT SCHEDULE END */

  /**  POPUP_NOTIFICATION_REG*/
  //static PLEASE_ENTER_OR_VERIFY_UR_INFO = 'Please enter or verify your information below.';
  static PLEASE_ENTER_OR_VERIFY_UR_INFO =
    "Here's the information we have on file for you. If something's not right, please update it.";
  static WE_WILL_SEND_A_TXT_MSG = "We’ll send a text to make sure we have it right.";
  static U_DIDNT_CHECK_THE_BOXES_MSG =
    "You didn’t check the boxes, so that means we won’t send you notifications by email or text. Click “Save Changes” to save your settings, or click “Cancel” to go back and make changes.";
  static REMEMBER_U_CAN_CHANGE_UR_PREF =
    "Remember: You can change your preferences any time by going to your profile.";
  static EMAIL_ADDR = "Email Address";
  static U_HAVE_ENTERED_A_MOB_NO_ERROR_MSG =
    "You have entered a Mobile Number that we already have on record.";
  static U_CAN_CHANGE_INFO_ANYTIME =
    "You can change this information any time in the Communication Preferences page in your profile.";
  static TEXTING_TnC = "Texting Terms and Conditions.";
  /**  POPUP_NOTIFICATION_REG*/

  /**  PRODUCT_INFO*/
  static PRODUCT_INFO_PAGES = "Product info Pages";
  static YOUR_EMPLOYER_MIGHT_OFFER_MSG =
    "*Your employer might offer benefits other than what you see here. If you have questions about the full range of what is  available to you, contact your employer or human resources administrator.";
  static BENEFITS_COMMONLY_OFFERED_BY_HF = "Benefits Commonly Offered by The Hartford*";
  /**  PRODUCT_INFO*/
  /** PHA ACCESS POINT START */
  static PHA_OVERVIEW_URL = "phaoverview";
  static CONFIRM_ELG_URL = "confirmELG";
  static PERSONAL_HEALTH_STATEMENTS = "Personal Health Statements (PHS)";
  static VIEW_PHA_STATUS = "View PHS Status";
  static PHA_DETAILS_TITLE = "PHS Details";
  static EROLL_RELATIONSHIP = "SPOUSE";
  static COVERAGE_APPROVED = "Coverage Approved";
  static COVERAGE_DENIED = "Coverage Denied";
  static APPLICATION_WITHDRAWN = "Application Withdrawn";
  static APPLICATION_CLOSED = "Application Closed - Missing Information";
  /** PHA ACCESS POINT END */

  /** PHA COVERAGE CATEGORY TITLE START */
  static PHA_VOLUNTARY_LIFE = "Voluntary Life";
  static PHA_BASIC_LIFE = "Basic Life";
  static PHA_SUPPLEMENTAL_DEPENDENT_LIFE = "Supplemental Dependent Life";
  static PHA_VOLUNTARY_SHORT_TERM_DISABILITY = "Voluntary Short Term Disability";
  static PHA_VOLUNTARY_LONG_TERM_DISABILITY = " Voluntary Long Term Disability";
  static MIME_TYPE_PDF = "application/pdf";
  static MIME_TYPE_RTF = "application/rtf";
  static MIME_TYPE_WORD = "application/msword";
  static MIME_TYPE_PPT = "application/vnd.ms-powerpoint";
  static MIME_TYPE_XLS = "application/vnd.ms-excel";
  static MIME_TYPE_XLXS = "vnd.openxmlformats-officedocument.spreadsheetml.sheet";
  static MIME_TYPE_PPTX =
    "application/vnd.openxmlformats-officedocument.presentationml.presentation";
  static MIME_TYPE_DOCX = "application/vnd.openxmlformats-officedocument.wordprocessingml.document";
  static MIME_TYPE_TXT = "text/plain";
  static MIME_TYPE_PNG = "image/png";
  static MIME_TYPE_TIF = "image/tiff";
  static MIME_TYPE_TIFF = "image/tiff";
  static MIME_TYPE_JPG = "image/jpeg";
  static MIME_TYPE_JPEG = "image/jpeg";
  static MIME_TYPE_GIF = "image/gif";
  static MIME_TYPE_BMP = "image/jpeg";
  static MIME_TYPE_OCTET = "application/octet-stream";

  /** PHA COVERAGE CATEGORY TITLE END */

  /** PHA OVERVIEW DETAILS START */
  static SEE_PHS_DETAILS = "See PHS Details";
  static COVERAGE_TYPE = "Coverage Type";
  static APPLICATION_RECEIVED = "Application Received";
  static STATUS_LAST_UPDATED = "Status Last Updated";
  static ENROLLEE_REL = "Enrollee Relationship";
  /** PHA OVERVIEW DETAILS END */

  /**  PRODUCT_LIST_POST_LOGIN*/
  static DEFAULT_CLAIM_CONTACT_NO = "1-800-549-6514";
  /**  PRODUCT_LIST_POST_LOGIN*/

  /** PROFILE_ERROR*/
  static PROFILE_UNAVAILABLE = "Profile Unavailable";
  static LETS_TALK_BY_PHONE_INSTEAD = "Let’s talk by phone instead";
  static WE_R_HAVING_TROUBLE_WITH_THIS_PAGE_MSG =
    "We’re having trouble with this page. If you’d like to change your email, phone number or password, please call us at 1-877-778-1383. We’re available weekdays from 8AM-8PM ET";
  /** PROFILE_ERROR*/

  /**  RELATED_LEAVE_REQ*/
  static URL_CONFIRM_ELG = "/confirmELG";
  static SEE_LEAVE_DETAILS = "See Leave Details";
  /**  RELATED_LEAVE_REQ*/
  /*profile comp start*/
  static EDIT_CONTACT_INFO = "Edit Contact Information";
  static EDIT_INFO = "Edit";
  static PASSWORD = "Password";
  static EDIT_PWD = "Edit Password";
  static YOUR_ACCOUNT = "Your Account";
  static URL_MYACCOUNT = "/myAccount";
  static ABOUT_YOU = "About You";
  static LOGIN_INFO = "Login Information";
  static URL_EDIT_ACC = "myAccount/edit";
  /*profile comp end*/
  /**/
  static WEL_YOUR_PROFILE = "Welcome to your profile.";
  static SELCECT_ON_MENU_LEFT = "Please make a selection from the menu on the left to continue.";
  static ACCOUNT_TEXT = "Account";
  static PROFILE_TEXT = "Profile";
  static PAYMENTS_LIST = "paymentsList";
  static PAYMENTS_LIST_DCS = "paymentsListDcs";
  /**/

  /** REQUEST EXTENSION START */
  static EXTENSION_CLOSED_MSG =
    "Unfortunately, your extension has been closed because we didn't get all the information we needed to make a decision. You can re-open your extension by calling";
  static GO_TO_MISSING_INFO = "Go to Missing Information";
  static REQUEST_AN_EXTENSION_CONFIRM = "Request An Extension Confirm";
  static REQUEST_AN_EXTENSION_CONFIRM_U = "Request_An_Extension_Confirm";
  static REQUEST_AN_EXTENSION_THAA_CONFIRM = "THAA Request An Extension Confirm";
  static REQUEST_AN_EXTENSION_THAA_CONFIRM_U = "THAA_Request_An_Extension_Confirm";
  static SUCCESS_REQUEST_EXTENSION_URL = "/successrequestextension";
  static SUCCESS_REQUEST_EXTENSION_THAA_URL = "/success-request-extension";
  static DOCTORS_NAME = "Doctor's Name";
  static DOC_FAX_NUMBER = "Doctor's Fax Number";
  static EXTENSION_DATE_TEXT = "extensionDate";
  static CURRENT_APPROVED = "Your current approved-through date:";
  static EXTENSION_THRU = "You've requested an extension through";
  static CHECKBOX = "I don't know";
  static DOCNAME = "Doctor's Name";
  static DOCTEL = "Doctor's phone number";
  static DOCFAX = "Doctor's fax number";
  static CURRENT_THRU_DATE = "Your claim is currently approved through";
  static REQUEST_EXT_THRU = "You've requested an extension through";
  static REQUEST_REASON = "What's the reason for your extension request?";
  static LAST_VISIT = "When was your last office visit?";
  static NEXT_VISIT = "When is your next office visit?";
  static RTW = "When do you plan to return to work?";
  static downloadLink = "[Download_Link]";
  static download_link = "this form";
  static phoneNumber = "[phone]";
  static APS_Initial_Report = "/cm/files/1444695728854.pdf";
  static APS_Mental_Health = "/cm/files/1444695730027.pdf";
  static ERR_MSG_OTHER = "Please enter a reason for your extension";
  static dropDownDisplayValues = [
    {
      DisplayId: "Current hospitalization or in-patient treatment",
      DisplayName: "I'm still in the hospital or receiving in-patient treatment",
    },
    {
      DisplayId: "New condition, unrelated to original",
      DisplayName: "I have a new condition",
    },
    {
      DisplayId: "Same condition, same treatment continues",
      DisplayName:
        "I’m still getting my current treatment for my condition, and can’t go back to work yet",
    },
    {
      DisplayId: "Same condition, new non-surgical treatment",
      DisplayName:
        "I’m getting a new non-surgical treatment for my condition, and can’t go back to work yet",
    },
    {
      DisplayId: "Same condition, new surgical treatment",
      DisplayName:
        "I’m getting a new surgical treatment for my condition, and can’t go back to work yet",
    },
    {
      DisplayId: "Released with restrictions, employer unable to accommodate",
      DisplayName: "I was released with restrictions, but my employer can’t accommodate them",
    },
    {
      DisplayId: "Return to work to be assessed at next appointment",
      DisplayName: "I haven’t been released to return to work yet",
    },
    {
      DisplayId: "Other",
      DisplayName: "Other",
    },
  ];

  /** REQUEST EXTENSION END */

  /**  SITE_WIDE_TNC*/
  static IMP_PLEASE_READ_AGREEMENT_CAREFULLY =
    "IMPORTANT – PLEASE READ THIS ENTIRE AGREEMENT CAREFULLY";
  static CLOSE = "Close";
  static PRINT = "Print";
  /**  SITE_WIDE_TNC*/

  /**  TEXT_TCS*/
  static THE_HFG_BENEFIT_DIS_N_LEAVE =
    "The Hartford Group Benefits Disability and Leave Status SMS (Text) Alerts";
  /**  TEXT_TCS*/

  /** UPDATED-TCS-ALERT-MODAL*/
  static WE_VE_UPDATED_TNC_MSG = `Please read and accept the terms and conditions to continue to the site.`;
  static THE_TERMS_OF_SERVICE_MUST_BE_ACCP_MSG =
    "The Terms of Service must be accepted before submitting this transaction.";
  static IF_U_PREFER_NT_TO_ACCEPT_MSG = `If you prefer not to accept these terms and conditions, you won't be able to receive text notifications. You can still sign up for notifications via email.`;

  /** UPDATED-TCS-ALERT-MODAL*/

  /**  THAA_REDIRECT_MODAL*/
  static THAA_MODAL = "thaaModal";
  static URL_AA_PORTAL =
    "https://abilityadvantage.thehartford.com/Portal/NewPortalUserRegistration.aspx";
  static SELF = "_self";
  static U_R_MOVING_WELCOME_TO_HFAA_MSG =
    "You’re Moving! Welcome to The Hartford’s Ability Advantage";
  static KEEPING_TRACK_OF_UR_BENEFITS_MSG =
    'Keeping track of your benefits just got easier. Now you can view your claim details, report new claims and more, all in one place with The Hartford’s Ability Advantage. It’s quick and easy to set up your account—just hit "Continue" to get started.';
  /**  THAA_REDIRECT_MODAL*/

  /**  TO_REMOVE_BLAZE*/
  static URL_BLAZE = "https://api-esbint.thehartford.com/gbrules/testDP";
  /**  TO_REMOVE_BLAZE*/

  /**  TRANSACTION_ERROR*/
  static SYSTEM_DOWN = "System Down";
  static RETURN_TO_HOMEPAGE = "Return to Homepage";
  /**  TRANSACTION_ERROR*/

  /** SELF LINKING START */
  static CONFIRM_INSURED_ID = "Confirm Insured ID";
  static CONFIRM_RECORD_ID = "Confirm Record ID";
  static SELF_LINKING_URL = "/selfLinking";
  static SELF_LINKING_FAILED_URL = "selfLinkingFailed";
  static EDIT_EFT_INFO_URL = "/myAccount/editEftInfo";
  static DEPOSIT_URL = "/myAccount/deposit";
  static UNDERWRITING_TITLE = "Underwriting";
  static RECORD_TITLE = "Record";
  static INSURED_TITLE = "Insured";
  static UNDERWRITING_TITLE_ID = "Please enter your Underwriting ID";
  static RECORD_TITLE_ID = "Please enter your Record ID";
  static ENTER_INSURED_ID = "Please enter your Insured ID";
  /** SELF LINKING END */

  /**  ENROLL_DEPOSIT*/
  static URL_MYACC_ENRDEP = "/myAccount/enrolldeposit";
  static ENROLL_NOW = "Enroll Now";
  static ENROLL_IN_DD = "Enroll in direct-deposit";
  static AUTH_USER = "Authorized User";
  static R_THERE_ANY_AUTHORIZED_USERS_MSG = "Are there any authorized users on this Account?";
  static CONFIRM_N_ENROLL = "Confirm and Enroll";
  static IF_U_MADE_ANY_CHANGES_THEY_WONT_BE_SAVED = `If you made any changes, they won't be saved.`;
  static URL_MYACC_EDIT_DEPOSIT = "/myAccount/editdeposit";
  static REFER_TO_A_CHECK_FROM_UR_BANK_MSG =
    "Refer to a check from your bank or credit union for your routing and account numbers.";
  static URL_MYACC_ENROLL_CONFIRM = "/myAccount/enrollconfirm";
  static URL_MY_ACCOUNT_VIEWEFT = "/myAccount/viewEftInfo";
  static DONE = "Done";
  /**  ENROLL_DEPOSIT*/

  /**  PROMPT_DIRECT_DEPOSIT*/
  static ENROLL_IN_DD_TO_RECEIVE = "Enroll in Direct Deposit to receive";
  static UR_FUNDS_AS_SOON_AS = "your funds as soon as they're ";
  static UR_FUNDS_AS_SOON_AS_AVAILABLE = "your funds as soon as they're  available.";
  static ENROLL = "Enroll";
  static REMIND_ME_LATER = "Remind me later";
  static DONT_SHOW_ME_THIS_AGAIN = `Don't show me this again`;
  static EDIT_LG = "Edit";
  /**  PROMPT_DIRECT_DEPOSIT*/

  /**  EMPLOYER_SELECT*/
  static LOG_OUT = "Log Out";
  static NOTIFICATIONS_SM = "notifications";
  /**  EMPLOYER_SELECT*/

  /**  NOTIFICATIONS*/
  static CHANGE_THESE = "You can change these preferences anytime";
  static HERE_IS_THE_CONTACT_INFO_MSG = `Here's the contact information we have on file for you:`;
  static EMAIL_COLLON = "Email:";
  static MOBILE_PHONE_COLLON = "Mobile Phone:";
  static GET_NOTIFIED_ABT_CHANGES_MSG =
    "Get notified about changes to your claim or extension status.";
  static HERE_IS_THE_CONTACT_COMM_PREF_MSG = `Here's the contact information we have on file for the Communication Preferences:`;
  static GET_NOTIFIED_WHEN_A_PAYMENT_IS_ISSUED = "Get notified when a payment is issued.";
  static EMAIL_THAA = "Email Address";
  static MOBILE_PHONE_THAA = "Mobile Number";
  /**  NOTIFICATIONS*/

  /**  RESET_PASSWORD*/
  static CHANGE_UR_PASSWORD_USING_THE_FIELDS_BELOW = "Change your password using the fields below.";
  static UR_PASSWORD_UPDATED_SUCCESSFULLY = "Your Password Updated Successfully";
  static CURRENT_PASSWORD = "Current Password";
  static NEW_PASSWORD = "New Password";
  static CONFIRM_PASSWORD = "Confirm Password";
  static UR_PASSWORD_MUST_CONTAIN_THE_FOLLOWING = "Your password must contain the following:";
  static EIGHT_OR_MORE_CHARS = "8 or more characters";
  static THREE_OF_THE_FOLLOWING = "3 of the following";
  static UPPERCASE_LETTER = "Uppercase letter";
  static NUMBER = "Number";
  static LOWERCASE_LETTER = "Lowercase letter";
  static SPECIAL_CHAR = "Special character";

  static URL_MY_ACC_NO_SLASH = "myAccount";
  static CANCEL_PASSWORD_MODAL = "cancelModal";
  static YOU_WILL_LOSE_ANY_UNSAVED_CHANGED = "You will lose any unsaved changes";
  static EXIT_AND_LOSE_CHANGES = "Exit and lose changes";
  static URL_MYACC_EMPLOYERS = "/myAccount/employers";
  static URL_LOGIN_ERROR = "/login-error";
  static URL_EMPLOYEE_BNR = "/EmployeeBNR";
  static URL_NOTIFICATION_REG = "/notificationRegistration";
  static EMPLOYEE_BENEFITS_THE_HARTFORD = "Employee Benefits | The Hartford";
  static URL_LEAVE_DETAILS_TIME_OFF_ERROR = "/leave-details/timeOffError";
  static URL_LEAVE_DETAILS_REQUST_REVIEW = "/leave-details/requestReview";
  /**  RESET_PASSWORD*/

  /** file upload button */
  static UPLOAD_DOC_WIZARD = "/uploadDocumentWizard";
  static SIZE_ERROR = "size";
  static MIN_ERROR = "min";
  static TXT_FORMAT = "txt";
  static RTF_FORMAT = "rtf";
  static NOT_THAA_TYPE = "NotTHAAtype";
  static TYPE_ERROR = "type";
  static CONTACT_US_TXTDETAIL = "Contact us";
  /*confirm eft modal component*/
  static PARA2_CONFIRMEFT =
    "Want to stop getting payments via direct deposit? Contact your claim rep for help.";
  static DD_CONFIRM_TXT = "Confirm info correct";
  static DD_EFT_CONFIRM_TXT = "This Is Correct";
  static CONFIRM_EFT_MODAL = "Confirm EFT Modal";
  static CONFIRM_EFT_MODAL_U = "Confirm_EFT_Modal";
  /*mail popup component*/
  static ZIPCODE_MAILPOPUP = "ZipCode";

  /*Profile component*/
  static PROFILE_MESSAGE = "You have successfully updated your contact information";
  static PROFILE_PWDMSG = "You have successfully updated your Password";
  /*notifications About you section*/
  static PROFILE_LINK_TEXT = "You can update your contact information on the ";
  static PROFILE_LINK_TEXTCONTD = "About You";
  static PAYMENT_SCHD_SEMIMNTH = "Payment Schedule (Semi-Monthly)";
  static PAYMENT_SCHD_MNTH = "Payment Schedule (Monthly)";
  static PAYMENT_SCHD_THAA = "Payment Schedule";
  static MPS_PREFIX = "MPS_";
  static THAA_MPS_PREFIX = "THAA_MPS_";
  static LABEL_TXT = "_Label";
  static SMPS_PREFIX = "SMPS_";
  /*PHA detaisl comp*/
  static PHA_STMT = "Personal Health Statement ";
  static GUARANT_AMT = "Guaranteed Amount";
  static UNDERWRITE_ID = "Underwriting ID";
  static EMPLOYEE_NAME = "Employee Name";
  static DECISION_LETTER = "Decision Letter";
  /*related leave req comp*/
  static LEAVE_HOURS_ENTER_MESSAGE = "Please tell us how many hours you’re taking off.";
  static LEAVE_ABSENCE_START_MESSAGE =
    "Let us know when your absence started, or when it will start if it hasn’t yet.";
  static REL_LEAVE_REQ = "Related Leave Request";
  static CONFIRM_ELG_LINK_NAME = "Where can I find this information?";
  static URL_PHA_OVERVIEW = "/phaoverview";
  static COVERAGE_AMT = "Coverage Amount";
  static MANAGE_MY_LEAVE = "Manage my leave";
  static PLS_ANS_SUBMIT = "Please answer before submitting.";
  static LOGO_DIRECT_SVG = "assets/images/logos/Logo-Direct-72x72.svg";
  static SECURITY_STMT_PATH = "/securitystmt";
  static SIDE_WIDE_PATH = "/siteWidetermsAndConditions";
  static TEXT_TCS_PATH = "/textTcs";
  static TERMS_COND_PATH = "/termsAndConditions";
  static SELF_LINK_SUCCESS_PATH = "/selfLinkingSuccess";
  static LEFT_CARET_BLUE_SVG = "assets/images/icons/left-caret-blue.svg";
  static REQUEST_REVIEW_URL = "/leave-details/requestReview";
  static LEAVE_SPECIFIC_QUE_URL = "/leave-intake/leaveSpecificQues";
  static REGULAR_WORK_SCHEDULE_EDIT = "/leave-intake/regularWorkScheduleEdit";
  static REGULAR_WORK_SCHEDULE_CREATE = "/leave-intake/regularWorkScheduleCreate";
  static EXTENSION_LBL = "extension";
  static THROUGH_LBL = "Through";
  static EMP_SUMMARY = "Employee Summary ";
  static TAX_TOKEN = "gbtaxformtoken";
  static TAX_TOKEN_HEADER = "GBTaxFormToken";

  static NOT_SELECTED = null;
  static RADIO_ERROR = "Please make a selection";
  static DATE_FIELD_ERROR = "Please make a selection";
  static I_DONT_KNOW_LABEL = "I don't know";
  static PAST_SURGERY_DATE_ERROR = "Please enter a date on or before your surgery date";
  static LAST_DAY_WORK_ERROR = "Please enter your last day at work";
  static AMPAM_TEXT = "AM/PM";
  static REQUIRED = "Required";
  static AM_TEXT = "AM";
  static PM_TEXT = "PM";
  static HH_LABEL = "HH";
  static MM_LABEL = "MM";
  static HH_TEXT = "hours";
  static HH_HOUR_TEXT = "hour";
  static HOURS_OT_LABEL = "Hours:";
  static MINUTES_OT_LABEL = "Minutes:";
  static UNITS_OT_LABEL = "AM/PM:";
  static MIN_TEXT = "min";
  static MINS_TEXT = "mins";
  static MM_MIN_TEXT = "minute";
  static UNIT_TEXT = "unit";
  static UNITS_TEXT = "AM/PM";
  static OTHER_REASON = "Other Reason";
  static YES_QUE_7 = "YesQue7";
  static NO_QUE_7 = "NoQue7";
  static YES_QUE_17 = "YesQue17";
  static NO_QUE_17 = "NoQue17";
  static DONT_KNOW = "I dont't know";
  static FORM_CONTROLS_OVERALL = [
    "babyName",
    "stayHosp",
    "reasonStopWork",
    "childBornDate",
    "childrenBornDate1",
    "childrenBornDate2",
    "workUptoDeliver",
    "ldwDatePicker",
    "typeOfWorkInDay",
    "dischargeDatePicker",
    "hospitalAmitDatePicker",
    "firstDayAbsWork",
    "gapQuestionLDW",
    "enterReasonRTW",
    "bondIndicator",
    "bondingTimeDatePicker",
    "planToRTWDatePicker",
    "absenceStartDateHour",
    "absenceStartDateMin",
    "absenceStartDateUnit",
    "hoursScheduledInWorkHour",
    "hoursScheduledInWorkMin",
    "hoursWorkedInADayHours",
    "hoursWorkedInADayMin",
  ];

  static SCREEN_READER_MAX_MSG =
    "Based on how your plan calculates it, your benefit is $[amount]. However, that amount is more than the maximum benefit that your plan allows. We’ve changed your benefit to the plan maximum of $[amount]. Your actual estimated payment amount may be different if you have offsets, deductions or taxes.";
  static SCREEN_READER_PRORATEDMAX_MSG =
    "Based on how your plan calculates it, your benefit is $[amount]. However, that amount is more than the maximum benefit that your plan allows. Your maximum benefit is also being pro-rated, since benefits were only payable for [X] days in this payment period. Your pro-rated benefit is $[amount]. Your actual estimated payment amount may be different if you have offsets, deductions or taxes.";
  static SCREEN_READER_PRORATED_MSG =
    "Based on how your plan calculates it, your benefit is $[amount]. However, we pro-rated this amount since benefits were only payable for [X] days in this payment period. Your pro-rated benefit is $[amount]. Your actual estimated payment amount may be different if you have offsets, deductions or taxes.";
  static SCREEN_READER_MIN_MSG =
    "Your benefit has fallen below the plan minimum. We've automatically bumped you back up to the minimum benefit amount.";
  static SCREEN_READER_PLUS = "this adds ";
  static SCREEN_READER_MINUS = "this takes away ";
  static DOLLARS = " dollars";
  static DEDUCT = "DEDUCT";
  static ADD = "ADD";

  static ADA_PROVISION_ID = 3026;
  static THAA_ENHANCED_ADA_PROVISION_OPTION_ID = 3;
  static THAA_FUNDAMENTAL_ADA_PROVISION_OPTION_ID = 2;

  /**YourDocumentsURL */
  static YOUR_DOCUMENTS_URL = "/leave-details/leaveDetails?scrollTo=yourDocuments";

  // ViewPsvPage Disclaimer Label
  static VIEW_PSV_DISCLAIMER_LABEL =
    "Disclaimer: Time stamp skips are normal as only content personalized to your claim will play.";
  static PSV_GA_SOURCE = "MyBenefits";
  static PSV_PLAYER_ID = "PsvPlayer";

  static UP_CARET = "/assets/images/icons/caret-up-blue.svg";
  static DOWN_CARET = "/assets/images/icons/caret-down.svg";

  static ACCORDION_OPEN = "Accordion Open";
  static ACCORDION_CLOSED = "Accordion Closed";

  static COMMUNICATION_PREFERENCE = "/myAccount/communicationsPreferences";
  static PAYMENTS_YOU_OWE = "/overPayment/paymentsYouOwe";
  static MAKE_PAYMENT = "/overPayment/makePayment";
  static SELECT_PAYMENT_AMOUNT = "/overPayment/selectPaymentAmount";
  static TEST_HEADER = "testheader";
  static PAYMENT_YOU_OWE_LABEL = "Payments You Owe";
  static PAYMENTS = "Payments";

  /* For logging */
  static LOG_LEVEL_ERROR = "ERROR";
  static LOG_LEVEL_INFO = "INFO";
  static LOG_LEVEL_WARNING = "WARNING";
  static LOG_LEVEL_DEBUG = "DEBUG";
  static UNKNOWN_ERROR = "Unknown error";
  static CONTENT_FAILURE_MESSAGE = "Error occurred when invoking content service call for page: ";

  /* all claim payment */
  static ALL_PAYMENTS_LINK = "/allPayments/allClaimPayments";
  static ALL_PAYMENTS_LABEL = "All Payments";
  static ALL_PAYMENT_HISTORY_PAGE_LABEL = "All Payment History";
  static HISTORY_PAYMENT_LINK = "Go To Payment History";
  static ALL_PAYMENTS_HISTORY_LINK = "/allPayments/allClaimPaymentsHistory";
  static ALL_PAYMENTS_HISTORY_LABEL = "Payment History";
  static PAYMENTS_PDF_LABEL = "PDF";
  static PAYSTUB_LINK_SCREEN_READER_TEXT =
    "Download Paystub PDF, this link will prompt download or open in a new tab.";
  static PAYSTUB_POPUP_SCREEN_READER_TEXT = "View Paystub, this link will open in a modal window.";
  static APS_FORM_SCREEN_READER_TEXT =
    "Download Attending Physician's Statement, this link may prompt download.";
  static SCREEN_READER_NEW_MODAL_WINDOW_TEXT = ", this link will open in a modal window.";
  static SCREEN_READER_DOWNLOAD_PROMPT_TEXT =
    ", this link will prompt download or open in a new tab.";
  static SCREEN_READER_SAME_TAB_TEXT = ", this link will navigate you to the form.";
  static PAYMENTS_COUNT_TEXT = "Showing XX of YY Payments";
  static COLLAPSE_PAYMENTS = "Collapse";
  static PAYMENTS_25_COUNT_BUTTON_TEXT = "Next 25 Payments";
  static PAYMENTS_PER_SET = 25;
  static NOT_ON_FILE = "Not on file";
  static PAYMENTS_50_COUNT = 50;
  static LOADING_LABEL = "Loading";
  static REASON_FOR_YOUR_ABSENCE = "Reason for your absence";
  static ESTIMATED_FUTURE_PAYMENTS = "Estimated Future Payments";
  static EMBEDDED_JS_CONSTANT = "/JS";
  static EMBEDDED_JAVASCRIPT_CONSTANT = "/JavaScript";
  static CLAIM_NOT_AVAILABLE = "claimNotAvailableTile";
  static DIFFERENT_CHILD = "differentChild";
  static MATERNITY_CLAIM: string;
  static phoneNumberFormat(phoneNo, withoutExtraSpace?: boolean) {
    if (phoneNo && phoneNo.trim() && phoneNo.length >= 10) {
      const phNoValue =
        phoneNo.slice(0, 1) === "1" || phoneNo.slice(0, 2) === "(1"
          ? phoneNo.slice(1, phoneNo.length)
          : phoneNo.slice(0, phoneNo.length);
      const phNo = phNoValue.replace(/\D/g, "");
      if (withoutExtraSpace) {
        return (phoneNo =
          "(" + phNo.slice(0, 3) + ") " + phNo.slice(3, 6) + "-" + phNo.slice(6, 10));
      }
      return (phoneNo =
        "(" + phNo.slice(0, 3) + ")  " + phNo.slice(3, 6) + "-" + phNo.slice(6, 10));
    } else {
      return phoneNo;
    }
  }
}
