import {Component, Input, OnInit} from '@angular/core';
import {ruleJson} from '../../../../../mock-data/RuleJson';
import {StorageManagementService} from '../../../../../shared/services/storage-management.service';
import {RelatedClaims} from '../../../../../shared/models/relatedClaims.model';
import {AppLabelConstants} from '../../../../../shared/constants/appLabel.constants';
import {SessionConstants} from '../../../../../shared/constants/session.constants';
import {AppConstants} from '../../../../../shared/constants/app.constants';

@Component({
  selector: 'app-date-label-value',
  templateUrl: 'date-label-value.component.html',
  styleUrls: ['date-label-value.component.scss']
})
export class DateLabelValueComponent implements OnInit {
  @Input() userClaim: RelatedClaims;
  @Input() ClaimId: string;
   dateLabel: string;
   dateValue: string;
   coverageCategoryPFL: string;
  // leaveId: ClaimDetails[];
   showForOnlyPflClaims = false;
  blazeOverViewRule = [];
  blazeOverViewRulefilterRecords = [];
  public appConstants = AppConstants;
  constructor( private storageManagementService: StorageManagementService) { }

  ngOnInit() {
    if (this.userClaim.coverageCategory === AppConstants.PFL && this.userClaim.sourceSystemId === 'AA') {
      this.businessRulePFL();
    } else {
      this.businessRules();
    }

  }
  businessRules() {
       for (const rulejson of ruleJson) {

//console.log("rulejson", rulejson);          //Rule Json

          /*Rule 1
           * Claim ID (CO_500) has a A/S/M  Indicator (CO_517) equals  M
           * and (OVRALL_ONLN_STAT_CD)(CO_503) equals '01'
           * and  (DTL_ONLN_STAT_CD)(CO_553) = '01'
           * then Date Label (CO_05) = Estimated Delivery Date
           * */
          if ((this.userClaim.asmIndicator === AppConstants.ASMINDICATOR_M && this.userClaim.claimOnlineStatusCode === AppConstants.COS_AWAITING_DELIVERY_CD_01 && this.userClaim.claimOnlineSubStatusCode === AppConstants.COSS_AWAITING_DELIVERY_CD_01) &&
            (this.userClaim.asmIndicator === rulejson.asmIndicator &&
              this.userClaim.claimOnlineStatusCode === rulejson.claimOnlineStatusCode) &&
            (this.userClaim.claimOnlineSubStatusCode === rulejson.claimOnlineSubStatusCode)) {
            this.dateLabel = rulejson.dateLabel;
            this.dateValue = this.userClaim.estimatedDeliveryDate;

            /* Note blazeOverViewRule array is uesd to push the date label and date value in empty array*/
            this.blazeOverViewRule.push(
              {
                id: this.userClaim.claimEventId,
                dateLabel: this.dateLabel,
                date: this.dateValue
              }
            );
          } else if ((this.userClaim.asmIndicator === AppConstants.ASMINDICATOR_M && this.userClaim.claimOnlineStatusCode === AppConstants.COS_UNDER_REVIEW_BY_CLAIM_HANDLER_CD_08) &&
            (this.userClaim.asmIndicator === rulejson.asmIndicator &&
              this.userClaim.claimOnlineStatusCode === rulejson.claimOnlineStatusCode) &&
            ((this.userClaim.confirmedLastDayWorkDate !== null && rulejson.confirmedLastDayWorkDate !== null) &&
              (this.userClaim.confirmedDeliveryDate !== null && rulejson.confirmedDeliveryDate !== null))
            && (this.userClaim.babyDeathIndicator === null && rulejson.babyDeathIndicator === null)
          ) {
            this.dateLabel = rulejson.dateLabel;
            this.dateValue = this.userClaim.confirmedDeliveryDate;

            /* Note blazeOverViewRule array is uesd to push the date label and date value in empty array*/
            this.blazeOverViewRule.push(
              {
                id: this.userClaim.claimEventId,
                dateLabel: this.dateLabel,
                date: this.dateValue
              }
            );
          } else if ((this.userClaim.asmIndicator === AppConstants.ASMINDICATOR_M && this.userClaim.claimOnlineStatusCode === AppConstants.COS_UNDER_REVIEW_BY_CLAIM_HANDLER_CD_08) &&
            (this.userClaim.asmIndicator === rulejson.asmIndicator &&
              this.userClaim.claimOnlineStatusCode === rulejson.claimOnlineStatusCode) &&
            ((this.userClaim.confirmedLastDayWorkDate !== null && rulejson.confirmedLastDayWorkDate !== null) &&
              (this.userClaim.confirmedDeliveryDate === null && rulejson.confirmedDeliveryDate === null))
            && (this.userClaim.babyDeathIndicator === null && rulejson.babyDeathIndicator === null)
          ) {

            this.dateLabel = rulejson.dateLabel;
            this.dateValue = this.userClaim.estimatedDeliveryDate;
            /* Note blazeOverViewRule array is uesd to push the date label and date value in empty array*/
            this.blazeOverViewRule.push(
              {
                id: this.userClaim.claimEventId,
                dateLabel: this.dateLabel,
                date: this.dateValue
              }
            );
          } else if ((this.userClaim.asmIndicator === AppConstants.ASMINDICATOR_M && this.userClaim.claimOnlineStatusCode === AppConstants.COS_CLAIM_APPROVED_CD_04) &&
            (this.userClaim.asmIndicator === rulejson.asmIndicator && this.userClaim.claimOnlineStatusCode === rulejson.claimOnlineStatusCode) &&
            (this.userClaim.confirmedLastDayWorkDate !== null && rulejson.confirmedLastDayWorkDate !== null)
            && (this.userClaim.babyDeathIndicator === null && rulejson.babyDeathIndicator === null)
          ) {
            this.dateLabel = rulejson.dateLabel;
            this.dateValue = this.userClaim.authorizedEndDate;
            /* Note blazeOverViewRule array is uesd to push the date label and date value in empty array*/
            this.blazeOverViewRule.push(
              {
                id: this.userClaim.claimEventId,
                dateLabel: this.dateLabel,
                date: this.dateValue
              }
            );
          } else if ((this.userClaim.asmIndicator === AppConstants.ASMINDICATOR_A || this.userClaim.asmIndicator === AppConstants.ASMINDICATOR_S) && (this.userClaim.claimOnlineStatusCode === AppConstants.COS_AWAITING_SURGERY_DETAILS_CD_03) &&
            (this.userClaim.asmIndicator === rulejson.asmIndicator && this.userClaim.claimOnlineStatusCode === rulejson.claimOnlineStatusCode) &&
            (this.userClaim.estimatedSurgeryDate !== null && rulejson.estimatedSurgeryDate !== null)
            && ((this.userClaim.sourceSystemId === AppConstants.SOURCE_SYS_ID_DCS && (this.userClaim.encIndicator === true || this.userClaim.encSurgeryIndicator === true)) || this.userClaim.sourceSystemId === AppConstants.SOURCE_SYS_ID_AA)
          ) {

            this.dateLabel = rulejson.dateLabel;
            this.dateValue = this.userClaim.estimatedSurgeryDate;
            /* Note blazeOverViewRule array is uesd to push the date label and date value in empty array*/
            this.blazeOverViewRule.push(
              {
                id: this.userClaim.claimEventId,
                dateLabel: this.dateLabel,
                date: this.dateValue
              }
            );
          } else if (
            (this.userClaim.claimOnlineStatusCode === AppConstants.COS_UNDER_REVIEW_BY_CLAIM_HANDLER_CD_08
              && (this.userClaim.asmIndicator === AppConstants.ASMINDICATOR_A || this.userClaim.asmIndicator === AppConstants.ASMINDICATOR_S || this.userClaim.encSurgeryIndicator === true)) &&
            (this.userClaim.asmIndicator === rulejson.asmIndicator &&
              this.userClaim.claimOnlineStatusCode === rulejson.claimOnlineStatusCode) &&
            this.userClaim.estimatedSurgeryDate !== null && rulejson.estimatedSurgeryDate !== null &&
            this.userClaim.confirmedSurgeryDate !== null && rulejson.confirmedSurgeryDate !== null
          ) {


            this.dateLabel = rulejson.dateLabel;
            this.dateValue = this.userClaim.confirmedSurgeryDate;
            /* Note blazeOverViewRule array is uesd to push the date label and date value in empty array*/
            this.blazeOverViewRule.push(
              {
                id: this.userClaim.claimEventId,
                dateLabel: this.dateLabel,
                date: this.dateValue
              }
            );
          } else if (
            (this.userClaim.claimOnlineStatusCode === AppConstants.COS_UNDER_REVIEW_BY_CLAIM_HANDLER_CD_08
              && (this.userClaim.asmIndicator === AppConstants.ASMINDICATOR_A || this.userClaim.asmIndicator === AppConstants.ASMINDICATOR_S || this.userClaim.encSurgeryIndicator === true))
            &&
            (this.userClaim.asmIndicator === rulejson.asmIndicator &&
              this.userClaim.claimOnlineStatusCode === rulejson.claimOnlineStatusCode && this.userClaim.estimatedSurgeryDate !== null
              && rulejson.estimatedSurgeryDate !== null) &&
            (this.userClaim.confirmedSurgeryDate === null && rulejson.confirmedSurgeryDate === null) &&
            (this.userClaim.confirmedLastDayWorkDate !== null && rulejson.confirmedLastDayWorkDate !== null)
          ) {
            this.dateLabel = rulejson.dateLabel;
            this.dateValue = this.userClaim.estimatedSurgeryDate;
            /* Note blazeOverViewRule array is uesd to push the date label and date value in empty array*/
            this.blazeOverViewRule.push(
              {
                id: this.userClaim.claimEventId,
                dateLabel: this.dateLabel,
                date: this.dateValue
              }
            );
          } else if (this.userClaim.claimOnlineStatusCode === AppConstants.COS_CLAIM_APPROVED_CD_04
            && (this.userClaim.asmIndicator === AppConstants.ASMINDICATOR_A || this.userClaim.asmIndicator === AppConstants.ASMINDICATOR_S || this.userClaim.encSurgeryIndicator === true)
            && (this.userClaim.asmIndicator === rulejson.asmIndicator && this.userClaim.claimOnlineStatusCode === rulejson.claimOnlineStatusCode)
            && (this.userClaim.confirmedLastDayWorkDate !== null && rulejson.confirmedLastDayWorkDate !== null)
            && (this.userClaim.estimatedSurgeryDate !== null && rulejson.estimatedSurgeryDate !== null)

          ) {
            this.dateLabel = rulejson.dateLabel;
            this.dateValue = this.userClaim.authorizedEndDate;
            /* Note blazeOverViewRule array is uesd to push the date label and date value in empty array*/
            this.blazeOverViewRule.push(
              {
                id: this.userClaim.claimEventId,
                dateLabel: this.dateLabel,
                date: this.dateValue
              }
            );
          } else if (this.userClaim.claimOnlineStatusCode === AppConstants.COS_AWAITING_DISABILITY_CD_02 && this.userClaim.claimOnlineSubStatusCode === AppConstants.COSS_AWAITING_DISABILITY_CD_02
            && (this.userClaim.asmIndicator === AppConstants.ASMINDICATOR_A || this.userClaim.asmIndicator === AppConstants.ASMINDICATOR_S)
            && (this.userClaim.estimatedSurgeryDate === null && rulejson.estimatedSurgeryDate === null)
            && (this.userClaim.asmIndicator === rulejson.asmIndicator && this.userClaim.claimOnlineStatusCode === rulejson.claimOnlineStatusCode)
            && (this.userClaim.claimOnlineSubStatusCode === rulejson.claimOnlineSubStatusCode)

          ) {
            this.dateLabel = rulejson.dateLabel;
            this.dateValue = this.userClaim.lastDayWork;
            /* Note blazeOverViewRule array is uesd to push the date label and date value in empty array*/
            this.blazeOverViewRule.push(
              {
                id: this.userClaim.claimEventId,
                dateLabel: this.dateLabel,
                date: this.dateValue
              }
            );
          } else if (this.userClaim.claimOnlineStatusCode === AppConstants.COS_UNDER_REVIEW_BY_CLAIM_HANDLER_CD_08
            && (this.userClaim.asmIndicator === AppConstants.ASMINDICATOR_A || this.userClaim.asmIndicator === AppConstants.ASMINDICATOR_S)
            && (this.userClaim.asmIndicator === rulejson.asmIndicator && this.userClaim.claimOnlineStatusCode === rulejson.claimOnlineStatusCode)
            && (this.userClaim.confirmedLastDayWorkDate !== null && rulejson.confirmedLastDayWorkDate !== null)
            && (this.userClaim.estimatedSurgeryDate === null && rulejson.estimatedSurgeryDate === null)

          ) {
            this.dateLabel = rulejson.dateLabel;
            this.dateValue = this.userClaim.confirmedLastDayWorkDate;
            /* Note blazeOverViewRule array is uesd to push the date label and date value in empty array*/
            this.blazeOverViewRule.push(
              {
                id: this.userClaim.claimEventId,
                dateLabel: this.dateLabel,
                date: this.dateValue
              }
            );
          } else if (this.userClaim.claimOnlineStatusCode === AppConstants.COS_CLAIM_APPROVED_CD_04
            && (this.userClaim.asmIndicator === AppConstants.ASMINDICATOR_A || this.userClaim.asmIndicator === AppConstants.ASMINDICATOR_S)
            && (this.userClaim.estimatedSurgeryDate === null && rulejson.estimatedSurgeryDate === null)
            && (this.userClaim.asmIndicator === rulejson.asmIndicator && this.userClaim.claimOnlineStatusCode === rulejson.claimOnlineStatusCode)
            && (this.userClaim.confirmedLastDayWorkDate !== null && rulejson.confirmedLastDayWorkDate !== null)
            && (this.userClaim.disabilityDate !== null && rulejson.disabilityDate !== null)
          ) {
            this.dateLabel = rulejson.dateLabel;
            this.dateValue = this.userClaim.authorizedEndDate;
            /* Note blazeOverViewRule array is uesd to push the date label and date value in empty array*/
            this.blazeOverViewRule.push(
              {
                id: this.userClaim.claimEventId,
                dateLabel: this.dateLabel,
                date: this.dateValue
              }
            );
          } else if (
            (this.userClaim.coverageCategory && this.userClaim.coverageCategory.toUpperCase() === AppConstants.LIFE)
            && ((this.userClaim.coverageType && this.userClaim.coverageType.toUpperCase() === AppConstants.VOL) || (this.userClaim.coverageType && this.userClaim.coverageType.toUpperCase() === AppConstants.BTRM) || (this.userClaim.coverageType && this.userClaim.coverageType.toUpperCase() === AppConstants.BDEP) || (this.userClaim.coverageType && this.userClaim.coverageType.toUpperCase() === AppConstants.SDEP) || (this.userClaim.coverageType && this.userClaim.coverageType.toUpperCase() === AppConstants.STRM))
            && (this.userClaim.coverageCategory === rulejson.coverageCategory)
            && (this.userClaim.disabilityDate !== null && rulejson.disabilityDate !== null)
          ) {

            this.dateLabel = rulejson.dateLabel;
            this.dateValue = this.userClaim.disabilityDate;
            /* Note blazeOverViewRule array is uesd to push the date label and date value in empty array*/
            this.blazeOverViewRule.push(
              {
                id: this.userClaim.claimEventId,
                dateLabel: this.dateLabel,
                date: this.dateValue
              }
            );
          } else if (
            (this.userClaim.disabilityDate !== null && this.userClaim.coverageCategory && this.userClaim.coverageCategory.toUpperCase() === AppConstants.LTD)
            && (this.userClaim.coverageCategory === rulejson.coverageCategory)
            && (this.userClaim.disabilityDate !== null && rulejson.disabilityDate !== null)
          ) {

            this.dateLabel = rulejson.dateLabel;
            this.dateValue = this.userClaim.disabilityDate;
            /* Note blazeOverViewRule array is uesd to push the date label and date value in empty array*/
            this.blazeOverViewRule.push(
              {
                id: this.userClaim.claimEventId,
                dateLabel: this.dateLabel,
                date: this.dateValue
              }
            );
          } else if (
            (this.userClaim.disabilityDate !== null && this.userClaim.coverageCategory && this.userClaim.coverageCategory.toUpperCase() === AppConstants.PW)
            && (this.userClaim.coverageCategory === rulejson.coverageCategory)
            && (this.userClaim.disabilityDate !== null && rulejson.disabilityDate !== null)
          ) {

            this.dateLabel = rulejson.dateLabel;
            this.dateValue = this.userClaim.disabilityDate;
            /* Note blazeOverViewRule array is uesd to push the date label and date value in empty array*/
            this.blazeOverViewRule.push(
              {
                id: this.userClaim.claimEventId,
                dateLabel: this.dateLabel,
                date: this.dateValue
              }
            );
          } else if (
            (
              (this.userClaim.claimOnlineStatus && this.userClaim.claimOnlineStatus.toLowerCase() === AppConstants.COS_CLAIM_CLOSED && this.userClaim.claimOnlineSubStatus && this.userClaim.claimOnlineSubStatus.toLowerCase() === AppConstants.COS_CLAIM_CLOSED) &&
              (this.userClaim.claimOnlineStatus === rulejson.claimOnlineStatus && this.userClaim.claimOnlineSubStatus === rulejson.claimOnlineSubStatus)
            )
            && ((
              (this.userClaim.extensionStatus === null && this.userClaim.extensionSubStatus === null) &&
              (this.userClaim.extensionStatus === rulejson.extensionStatus && this.userClaim.extensionSubStatus === rulejson.extensionSubStatus)
            ) || (this.userClaim.extensionStatus !== null && this.userClaim.extensionSubStatus !== null))
          ) {
            this.dateLabel = AppLabelConstants.APPROVED_THRGH_DATE_TEXT;
            this.dateValue = this.userClaim.authorizedEndDate;
            //this.blazeOverViewRule =[]; // To remove array value
            /* Note blazeOverViewRule array is uesd to push the date label and date value in empty array*/
            this.blazeOverViewRule.push(
              {
                id: this.userClaim.claimEventId,
                dateLabel: this.dateLabel,
                date: this.dateValue
              });

          } else if ( // need to check
            ((this.userClaim.babyDeathIndicator === null && rulejson.babyDeathIndicator === null) && this.userClaim.asmIndicator === AppConstants.ASMINDICATOR_M)
            && (this.userClaim.claimOnlineStatusCode === AppConstants.COS_CLAIM_CLOSED_CD_05 && this.userClaim.claimOnlineSubStatusCode === AppConstants.COSS_MISSING_INFORMATION_CD_09)
            && ((this.userClaim.claimOnlineStatus === rulejson.claimOnlineStatus && this.userClaim.claimOnlineSubStatus === rulejson.claimOnlineSubStatus)
            && (this.userClaim.estimatedDeliveryDate !== null)
            && (this.userClaim.confirmedDeliveryDate === null && rulejson.confirmedDeliveryDate === null)
            && (this.userClaim.claimOnlineStatusCode === rulejson.claimOnlineStatusCode && this.userClaim.claimOnlineSubStatusCode === rulejson.claimOnlineSubStatusCode))
          ) {
            this.dateLabel = rulejson.dateLabel; //Estimated Delivery Date
            this.dateValue = this.userClaim.estimatedDeliveryDate;
            /* Note blazeOverViewRule array is uesd to push the date label and date value in empty array*/
            this.blazeOverViewRule.push(
              {
                id: this.userClaim.claimEventId,
                dateLabel: this.dateLabel,
                date: this.dateValue
              });
          } else if ( // need to check
            ((this.userClaim.babyDeathIndicator === null && rulejson.babyDeathIndicator === null) && this.userClaim.asmIndicator === AppConstants.ASMINDICATOR_M)
            && (this.userClaim.claimOnlineStatusCode === AppConstants.COS_CLAIM_CLOSED_CD_05 && this.userClaim.claimOnlineSubStatusCode === AppConstants.COSS_MISSING_INFORMATION_CD_09)
            && ((this.userClaim.claimOnlineStatus === rulejson.claimOnlineStatus && this.userClaim.claimOnlineSubStatus === rulejson.claimOnlineSubStatus)
            && (this.userClaim.estimatedDeliveryDate !== null)
            && (this.userClaim.confirmedDeliveryDate !== null && rulejson.confirmedDeliveryDate !== null)
            && (this.userClaim.claimOnlineStatusCode === rulejson.claimOnlineStatusCode && this.userClaim.claimOnlineSubStatusCode === rulejson.claimOnlineSubStatusCode))
          ) {
            this.dateLabel = rulejson.dateLabel; //Delivery Date
            this.dateValue = this.userClaim.confirmedDeliveryDate;
            /* Note blazeOverViewRule array is uesd to push the date label and date value in empty array*/
            this.blazeOverViewRule.push(
              {
                id: this.userClaim.claimEventId,
                dateLabel: this.dateLabel,
                date: this.dateValue
              });
          } else if (
            (this.userClaim.claimOnlineStatusCode === AppConstants.COS_UNDER_REVIEW_BY_CLAIM_HANDLER_CD_08 && this.userClaim.claimOnlineSubStatusCode === AppConstants.COSS_MISSING_INFORMATION_CD_13
              && this.userClaim.asmIndicator === AppConstants.ASMINDICATOR_M)
            && ((this.userClaim.claimOnlineStatusCode === rulejson.claimOnlineStatusCode
            && this.userClaim.claimOnlineSubStatusCode === rulejson.claimOnlineSubStatusCode))
            && (this.userClaim.babyDeathIndicator !== null && rulejson.babyDeathIndicator !== null)
            && (this.userClaim.confirmedLastDayWorkDate !== null && rulejson.confirmedLastDayWorkDate !== null)
            && (this.userClaim.confirmedDeliveryDate === null && rulejson.confirmedDeliveryDate === null)
          ) {
            this.dateLabel = rulejson.dateLabel;
            this.dateValue = this.userClaim.disabilityDate;
            /* Note blazeOverViewRule array is uesd to push the date label and date value in empty array*/
            this.blazeOverViewRule.push(
              {
                id: this.userClaim.claimEventId,
                dateLabel: this.dateLabel,
                date: this.dateValue
              });
          } else if (
            (this.userClaim.claimOnlineStatusCode === AppConstants.COS_UNDER_REVIEW_BY_CLAIM_HANDLER_CD_08 && this.userClaim.claimOnlineSubStatusCode === AppConstants.COSS_IN_ADJUDICATION_CD_12
              && this.userClaim.asmIndicator === AppConstants.ASMINDICATOR_M)
            && ((this.userClaim.claimOnlineStatusCode === rulejson.claimOnlineStatusCode
            && this.userClaim.claimOnlineSubStatusCode === rulejson.claimOnlineSubStatusCode))
            && (this.userClaim.babyDeathIndicator !== null && rulejson.babyDeathIndicator !== null)
            && (this.userClaim.confirmedLastDayWorkDate !== null && rulejson.confirmedLastDayWorkDate !== null)
            && (this.userClaim.confirmedDeliveryDate === null && rulejson.confirmedDeliveryDate === null)
          ) {
            this.dateLabel = rulejson.dateLabel;
            this.dateValue = this.userClaim.disabilityDate;
            /* Note blazeOverViewRule array is uesd to push the date label and date value in empty array*/
            this.blazeOverViewRule.push(
              {
                id: this.userClaim.claimEventId,
                dateLabel: this.dateLabel,
                date: this.dateValue
              });
          } else if (
            (this.userClaim.claimOnlineStatusCode === AppConstants.COS_CLAIM_APPROVED_CD_04
              && this.userClaim.asmIndicator === AppConstants.ASMINDICATOR_M)
            && (this.userClaim.claimOnlineStatusCode === rulejson.claimOnlineStatusCode)
            && (this.userClaim.babyDeathIndicator !== null && rulejson.babyDeathIndicator !== null)
            && (this.userClaim.confirmedLastDayWorkDate !== null && rulejson.confirmedLastDayWorkDate !== null)
            && (this.userClaim.confirmedDeliveryDate === null && rulejson.confirmedDeliveryDate === null)
          ) {
            this.dateLabel = rulejson.dateLabel;
            this.dateValue = this.userClaim.disabilityDate;
            /* Note blazeOverViewRule array is uesd to push the date label and date value in empty array*/
            this.blazeOverViewRule.push(
              {
                id: this.userClaim.claimEventId,
                dateLabel: this.dateLabel,
                date: this.dateValue
              });
          } else if (
            (this.userClaim.claimOnlineStatusCode === AppConstants.COS_CLAIM_CLOSED_CD_05 && this.userClaim.claimOnlineSubStatusCode === AppConstants.COSS_MISSING_INFORMATION_CD_09
              && this.userClaim.asmIndicator === AppConstants.ASMINDICATOR_M)
            && (this.userClaim.claimOnlineStatusCode === rulejson.claimOnlineStatusCode
            && this.userClaim.claimOnlineSubStatusCode === rulejson.claimOnlineSubStatusCode)
            && (this.userClaim.babyDeathIndicator !== null && rulejson.babyDeathIndicator !== null)
            && (this.userClaim.confirmedLastDayWorkDate !== null && rulejson.confirmedLastDayWorkDate !== null)
            && (this.userClaim.confirmedDeliveryDate === null && rulejson.confirmedDeliveryDate === null)
          ) {
            this.dateLabel = rulejson.dateLabel;
            this.dateValue = this.userClaim.disabilityDate;
            /* Note blazeOverViewRule array is uesd to push the date label and date value in empty array*/
            this.blazeOverViewRule.push(
              {
                id: this.userClaim.claimEventId,
                dateLabel: this.dateLabel,
                date: this.dateValue
              });
          } else if (
            (this.userClaim.claimOnlineStatusCode === AppConstants.COS_CLAIM_CLOSED_CD_05 && this.userClaim.claimOnlineSubStatusCode === AppConstants.COSS_CLAIM_CLOSED_CD_08
              && this.userClaim.asmIndicator === AppConstants.ASMINDICATOR_M)
            && (this.userClaim.claimOnlineStatusCode === rulejson.claimOnlineStatusCode
            && this.userClaim.claimOnlineSubStatusCode === rulejson.claimOnlineSubStatusCode)
            && (this.userClaim.babyDeathIndicator !== null && rulejson.babyDeathIndicator !== null)
            && (this.userClaim.confirmedLastDayWorkDate !== null && rulejson.confirmedLastDayWorkDate !== null)
            && (this.userClaim.confirmedDeliveryDate === null && rulejson.confirmedDeliveryDate === null)
          ) {
            this.dateLabel = rulejson.dateLabel;
            this.dateValue = this.userClaim.disabilityDate;
            /* Note blazeOverViewRule array is uesd to push the date label and date value in empty array*/
            this.blazeOverViewRule.push(
              {
                id: this.userClaim.claimEventId,
                dateLabel: this.dateLabel,
                date: this.dateValue
              });
          } else if (
            (this.userClaim.claimOnlineStatusCode === AppConstants.COS_UNDER_REVIEW_BY_CLAIM_HANDLER_CD_08
              && this.userClaim.asmIndicator === AppConstants.ASMINDICATOR_M)
            && (this.userClaim.claimOnlineStatusCode === rulejson.claimOnlineStatusCode)
            && (this.userClaim.babyDeathIndicator !== null && rulejson.babyDeathIndicator !== null)
            && (this.userClaim.confirmedLastDayWorkDate !== null && rulejson.confirmedLastDayWorkDate !== null)
          ) {
            this.dateLabel = rulejson.dateLabel;
            this.dateValue = this.userClaim.disabilityDate;
            /* Note blazeOverViewRule array is uesd to push the date label and date value in empty array*/
            this.blazeOverViewRule.push(
              {
                id: this.userClaim.claimEventId,
                dateLabel: this.dateLabel,
                date: this.dateValue
              });
          } else if (
            (this.userClaim.claimOnlineStatusCode === AppConstants.COS_CLAIM_CLOSED_CD_05 && this.userClaim.claimOnlineSubStatusCode === AppConstants.COSS_MISSING_INFORMATION_CD_13
              && this.userClaim.asmIndicator === AppConstants.ASMINDICATOR_M)
            && (this.userClaim.claimOnlineStatusCode === rulejson.claimOnlineStatusCode
            && this.userClaim.claimOnlineSubStatusCode === rulejson.claimOnlineSubStatusCode)
            && (this.userClaim.babyDeathIndicator === null && rulejson.babyDeathIndicator === null)
            && (this.userClaim.confirmedLastDayWorkDate !== null && rulejson.confirmedLastDayWorkDate !== null)
            && (this.userClaim.confirmedDeliveryDate === null && rulejson.confirmedDeliveryDate === null)
          ) {
            this.dateLabel = rulejson.dateLabel;
            this.dateValue = this.userClaim.estimatedDeliveryDate;
            /* Note blazeOverViewRule array is uesd to push the date label and date value in empty array*/
            this.blazeOverViewRule.push(
              {
                id: this.userClaim.claimEventId,
                dateLabel: this.dateLabel,
                date: this.dateValue
              });
          } else if (
            (this.userClaim.claimOnlineStatusCode === AppConstants.COS_CLAIM_CLOSED_CD_05 && this.userClaim.claimOnlineSubStatusCode === AppConstants.COSS_MISSING_INFORMATION_CD_13
              && this.userClaim.asmIndicator === AppConstants.ASMINDICATOR_M)
            && (this.userClaim.claimOnlineStatusCode === rulejson.claimOnlineStatusCode
            && this.userClaim.claimOnlineSubStatusCode === rulejson.claimOnlineSubStatusCode)
            && (this.userClaim.babyDeathIndicator !== null && rulejson.babyDeathIndicator !== null)
            && (this.userClaim.confirmedLastDayWorkDate !== null && rulejson.confirmedLastDayWorkDate !== null)
          ) {
            this.dateLabel = rulejson.dateLabel;
            this.dateValue = this.userClaim.disabilityDate;
            /* Note blazeOverViewRule array is uesd to push the date label and date value in empty array*/
            this.blazeOverViewRule.push(
              {
                id: this.userClaim.claimEventId,
                dateLabel: this.dateLabel,
                date: this.dateValue
              });
          } else if (
            (this.userClaim.claimOnlineStatusCode === AppConstants.COS_CLAIM_CLOSED_CD_05 && this.userClaim.claimOnlineSubStatusCode === AppConstants.COSS_MISSING_INFORMATION_CD_13
              && this.userClaim.asmIndicator === AppConstants.ASMINDICATOR_M)
            && (this.userClaim.claimOnlineStatusCode === rulejson.claimOnlineStatusCode
            && this.userClaim.claimOnlineSubStatusCode === rulejson.claimOnlineSubStatusCode)
            && (this.userClaim.confirmedLastDayWorkDate !== null && rulejson.confirmedLastDayWorkDate !== null)
            && (this.userClaim.confirmedDeliveryDate !== null && rulejson.confirmedDeliveryDate !== null)
            && (this.userClaim.babyDeathIndicator === null && rulejson.babyDeathIndicator === null)
          ) {
            this.dateLabel = rulejson.dateLabel;
            this.dateValue = this.userClaim.confirmedDeliveryDate;
            /* Note blazeOverViewRule array is uesd to push the date label and date value in empty array*/
            this.blazeOverViewRule.push(
              {
                id: this.userClaim.claimEventId,
                dateLabel: this.dateLabel,
                date: this.dateValue
              });
          } else if (
            (this.userClaim.claimOnlineStatusCode === AppConstants.COS_CLAIM_DENIED_CD_06)
            && (this.userClaim.claimOnlineStatusCode === rulejson.claimOnlineStatusCode)
          ) {
            this.dateLabel = rulejson.dateLabel;
            this.dateValue = this.userClaim.claimProcessDate;
            /* Note blazeOverViewRule array is uesd to push the date label and date value in empty array*/
            this.blazeOverViewRule.push(
              {
                id: this.userClaim.claimEventId,
                dateLabel: this.dateLabel,
                date: this.dateValue
              });
          } else if (
            (this.userClaim.claimOnlineStatusCode === AppConstants.COS_CLAIM_RECEIVED_CD_07 && this.userClaim.claimOnlineSubStatusCode === AppConstants.COSS_CLAIM_RECEIVED_CD_11)
            && (this.userClaim.claimOnlineStatusCode === rulejson.claimOnlineStatusCode)
            && (this.userClaim.claimOnlineSubStatusCode === rulejson.claimOnlineSubStatusCode)
          ) {
            this.dateLabel = rulejson.dateLabel;
            this.dateValue = this.userClaim.disabilityDate;
            /* Note blazeOverViewRule array is uesd to push the date label and date value in empty array*/
            this.blazeOverViewRule.push(
              {
                id: this.userClaim.claimEventId,
                dateLabel: this.dateLabel,
                date: this.dateValue
              });
          } else if (
            (this.userClaim.claimOnlineStatusCode === AppConstants.COS_CLAIM_CLOSED_CD_05 && this.userClaim.claimOnlineSubStatusCode === AppConstants.COSS_MISSING_INFORMATION_CD_13)
            && (this.userClaim.asmIndicator === AppConstants.ASMINDICATOR_A || this.userClaim.asmIndicator === AppConstants.ASMINDICATOR_S)
            && (this.userClaim.asmIndicator === rulejson.asmIndicator)
            && (this.userClaim.claimOnlineStatusCode === rulejson.claimOnlineStatusCode)
            && (this.userClaim.claimOnlineSubStatusCode === rulejson.claimOnlineSubStatusCode)
            && (this.userClaim.confirmedLastDayWorkDate !== null && rulejson.confirmedLastDayWorkDate !== null)
            && (this.userClaim.confirmedSurgeryDate === null && rulejson.confirmedSurgeryDate === null)
            && (this.userClaim.estimatedSurgeryDate !== null && rulejson.estimatedSurgeryDate !== null)
          ) {
            this.dateLabel = rulejson.dateLabel;
            this.dateValue = this.userClaim.estimatedSurgeryDate;
            /* Note blazeOverViewRule array is uesd to push the date label and date value in empty array*/
            this.blazeOverViewRule.push(
              {
                id: this.userClaim.claimEventId,
                dateLabel: this.dateLabel,
                date: this.dateValue
              });
          } else if (
            (this.userClaim.claimOnlineStatusCode === AppConstants.COS_CLAIM_CLOSED_CD_05 && this.userClaim.claimOnlineSubStatusCode === AppConstants.COSS_MISSING_INFORMATION_CD_13)
            && (this.userClaim.asmIndicator === AppConstants.ASMINDICATOR_A || this.userClaim.asmIndicator === AppConstants.ASMINDICATOR_S)
            && (this.userClaim.asmIndicator === rulejson.asmIndicator && this.userClaim.encSurgeryIndicator === true)
            && (this.userClaim.claimOnlineStatusCode === rulejson.claimOnlineStatusCode)
            && (this.userClaim.claimOnlineSubStatusCode === rulejson.claimOnlineSubStatusCode)
            && (this.userClaim.confirmedLastDayWorkDate !== null && rulejson.confirmedLastDayWorkDate !== null)
            && (this.userClaim.confirmedSurgeryDate !== null && rulejson.confirmedSurgeryDate !== null)
            && (this.userClaim.estimatedSurgeryDate !== null && rulejson.estimatedSurgeryDate !== null)
          ) {
            this.dateLabel = rulejson.dateLabel;
            this.dateValue = this.userClaim.confirmedSurgeryDate;
            /* Note blazeOverViewRule array is uesd to push the date label and date value in empty array*/
            this.blazeOverViewRule.push(
              {
                id: this.userClaim.claimEventId,
                dateLabel: this.dateLabel,
                date: this.dateValue
              });
          } else if (
            (this.userClaim.claimOnlineStatusCode === AppConstants.COS_CLAIM_APPROVED_CD_04 && this.userClaim.claimOnlineSubStatusCode === AppConstants.COSS_MISSING_INFORMATION_CD_13)
            && (this.userClaim.asmIndicator === AppConstants.ASMINDICATOR_A || this.userClaim.asmIndicator === AppConstants.ASMINDICATOR_S)
            && (this.userClaim.asmIndicator === rulejson.asmIndicator)
            && (this.userClaim.claimOnlineStatusCode === rulejson.claimOnlineStatusCode)
            && (this.userClaim.claimOnlineSubStatusCode === rulejson.claimOnlineSubStatusCode)
            && (this.userClaim.confirmedLastDayWorkDate !== null && rulejson.confirmedLastDayWorkDate !== null)
            && (this.userClaim.estimatedSurgeryDate === null && rulejson.estimatedSurgeryDate === null)
          ) {
            this.dateLabel = rulejson.dateLabel;
            this.dateValue = this.userClaim.lastDayWork;
            /* Note blazeOverViewRule array is uesd to push the date label and date value in empty array*/
            this.blazeOverViewRule.push(
              {
                id: this.userClaim.claimEventId,
                dateLabel: this.dateLabel,
                date: this.dateValue
              });
          } else if (
            (this.userClaim.claimOnlineStatusCode === AppConstants.COS_CLAIM_APPROVED_CD_04 && this.userClaim.claimOnlineSubStatusCode !== AppConstants.COSS_MISSING_INFORMATION_CD_13)
            && (this.userClaim.asmIndicator === AppConstants.ASMINDICATOR_A || this.userClaim.asmIndicator === AppConstants.ASMINDICATOR_S)
            && (this.userClaim.asmIndicator === rulejson.asmIndicator)
            && (this.userClaim.claimOnlineStatusCode === rulejson.claimOnlineStatusCode)
            && (this.userClaim.confirmedLastDayWorkDate !== null && rulejson.confirmedLastDayWorkDate !== null)
            && (this.userClaim.estimatedSurgeryDate === null && rulejson.estimatedSurgeryDate === null)
          ) {
            this.dateLabel = rulejson.dateLabel;
            this.dateValue = this.userClaim.authorizedEndDate;
            /* Note blazeOverViewRule array is uesd to push the date label and date value in empty array*/
            this.blazeOverViewRule.push(
              {
                id: this.userClaim.claimEventId,
                dateLabel: this.dateLabel,
                date: this.dateValue
              });
          } else if (
            (this.userClaim.claimOnlineStatusCode === AppConstants.COS_UNDER_REVIEW_BY_CLAIM_HANDLER_CD_08 && this.userClaim.claimOnlineSubStatusCode === AppConstants.COSS_IN_ADJUDICATION_CD_12)
            && (this.userClaim.asmIndicator === AppConstants.ASMINDICATOR_M)
            && (this.userClaim.asmIndicator === rulejson.asmIndicator)
            && (this.userClaim.claimOnlineStatusCode === rulejson.claimOnlineStatusCode)
            && (this.userClaim.claimOnlineSubStatusCode === rulejson.claimOnlineSubStatusCode)
            && (this.userClaim.confirmedDeliveryDate === null && rulejson.confirmedDeliveryDate === null)
            && (this.userClaim.babyDeathIndicator === null && rulejson.babyDeathIndicator === null)
          ) {
            this.dateLabel = rulejson.dateLabel;
            this.dateValue = this.userClaim.estimatedDeliveryDate;
            /* Note blazeOverViewRule array is uesd to push the date label and date value in empty array*/
            this.blazeOverViewRule.push(
              {
                id: this.userClaim.claimEventId,
                dateLabel: this.dateLabel,
                date: this.dateValue
              });
          } else if (
            (this.userClaim.claimOnlineStatusCode === AppConstants.COS_CLAIM_CLOSED_CD_05 && this.userClaim.claimOnlineSubStatusCode === AppConstants.COSS_MISSING_INFORMATION_CD_09)
            && (this.userClaim.sourceSystemId === AppConstants.SOURCE_SYS_ID_DCS && rulejson.sourceSystemId && rulejson.sourceSystemId === AppConstants.SOURCE_SYS_ID_DCS)
            && (this.userClaim.asmIndicator === AppConstants.ASMINDICATOR_A || this.userClaim.asmIndicator === AppConstants.ASMINDICATOR_S)
            && (this.userClaim.asmIndicator === rulejson.asmIndicator)
            && (this.userClaim.claimOnlineStatusCode === rulejson.claimOnlineStatusCode)
            && (this.userClaim.claimOnlineSubStatusCode === rulejson.claimOnlineSubStatusCode)
            && (this.userClaim.babyDeathIndicator === null && rulejson.babyDeathIndicator === null)
          ) {
            this.dateLabel = rulejson.dateLabel;
            this.dateValue = this.userClaim.confirmedLastDayWorkDate;
            /* Note blazeOverViewRule array is uesd to push the date label and date value in empty array*/
            this.blazeOverViewRule.push(
              {
                id: this.userClaim.claimEventId,
                dateLabel: this.dateLabel,
                date: this.dateValue
              });
          } else if (
            (this.userClaim.claimOnlineStatusCode === AppConstants.COS_CLAIM_CLOSED_CD_05 && this.userClaim.claimOnlineSubStatusCode === AppConstants.COSS_MISSING_INFORMATION_CD_09)
            && (this.userClaim.sourceSystemId === AppConstants.SOURCE_SYS_ID_AA && rulejson.sourceSystemId && rulejson.sourceSystemId === AppConstants.SOURCE_SYS_ID_AA)
            && (this.userClaim.estimatedSurgeryDate !== null && rulejson.estimatedSurgeryDate !== null)
            && (this.userClaim.asmIndicator === AppConstants.ASMINDICATOR_A || this.userClaim.asmIndicator === AppConstants.ASMINDICATOR_S)
            && (this.userClaim.asmIndicator === rulejson.asmIndicator)
            && (this.userClaim.confirmedSurgeryDate === null && rulejson.confirmedSurgeryDate === null)
            && (this.userClaim.claimOnlineStatusCode === rulejson.claimOnlineStatusCode)
            && (this.userClaim.claimOnlineSubStatusCode === rulejson.claimOnlineSubStatusCode)
            && (this.userClaim.babyDeathIndicator === null && rulejson.babyDeathIndicator === null)
          ) {
            this.dateLabel = rulejson.dateLabel; //'Estimated Surgery Date';
            this.dateValue = this.userClaim.estimatedSurgeryDate;
            /* Note blazeOverViewRule array is uesd to push the date label and date value in empty array*/
            this.blazeOverViewRule.push(
              {
                id: this.userClaim.claimEventId,
                dateLabel: this.dateLabel,
                date: this.dateValue
              });
          } else if (
            (this.userClaim.claimOnlineStatusCode === AppConstants.COS_CLAIM_CLOSED_CD_05 && this.userClaim.claimOnlineSubStatusCode === AppConstants.COSS_MISSING_INFORMATION_CD_09)
            && (this.userClaim.sourceSystemId === AppConstants.SOURCE_SYS_ID_AA && rulejson.sourceSystemId && rulejson.sourceSystemId === AppConstants.SOURCE_SYS_ID_AA)
            && (this.userClaim.estimatedSurgeryDate !== null && rulejson.estimatedSurgeryDate !== null)
            && (this.userClaim.asmIndicator === AppConstants.ASMINDICATOR_A || this.userClaim.asmIndicator === AppConstants.ASMINDICATOR_S)
            && (this.userClaim.asmIndicator === rulejson.asmIndicator)
            && (this.userClaim.confirmedSurgeryDate !== null && rulejson.confirmedSurgeryDate !== null)
            && (this.userClaim.claimOnlineStatusCode === rulejson.claimOnlineStatusCode)
            && (this.userClaim.claimOnlineSubStatusCode === rulejson.claimOnlineSubStatusCode)
            && (this.userClaim.babyDeathIndicator === null && rulejson.babyDeathIndicator === null)
          ) {
            this.dateLabel = rulejson.dateLabel; //'Surgery Date';
            this.dateValue = this.userClaim.confirmedSurgeryDate;
            /* Note blazeOverViewRule array is uesd to push the date label and date value in empty array*/
            this.blazeOverViewRule.push(
              {
                id: this.userClaim.claimEventId,
                dateLabel: this.dateLabel,
                date: this.dateValue
              });
          } else if (
            (this.userClaim.claimOnlineStatusCode === AppConstants.COS_CLAIM_CLOSED_CD_05 && this.userClaim.claimOnlineSubStatusCode === AppConstants.COSS_MISSING_INFORMATION_CD_09)
            && (this.userClaim.sourceSystemId === AppConstants.SOURCE_SYS_ID_AA && rulejson.sourceSystemId && rulejson.sourceSystemId === AppConstants.SOURCE_SYS_ID_AA)
            && (this.userClaim.estimatedSurgeryDate === null && rulejson.estimatedSurgeryDate === null)
            && (this.userClaim.asmIndicator === AppConstants.ASMINDICATOR_A || this.userClaim.asmIndicator === AppConstants.ASMINDICATOR_S)
            && (this.userClaim.asmIndicator === rulejson.asmIndicator)
            && (this.userClaim.claimOnlineStatusCode === rulejson.claimOnlineStatusCode)
            && (this.userClaim.claimOnlineSubStatusCode === rulejson.claimOnlineSubStatusCode)
            && (this.userClaim.babyDeathIndicator === null && rulejson.babyDeathIndicator === null)
          ) {
            this.dateLabel = rulejson.dateLabel; //'Last Date Worked';
            this.dateValue = this.userClaim.confirmedLastDayWorkDate;
            /* Note blazeOverViewRule array is uesd to push the date label and date value in empty array*/
            this.blazeOverViewRule.push(
              {
                id: this.userClaim.claimEventId,
                dateLabel: this.dateLabel,
                date: this.dateValue
              });
          } else if (
            (this.userClaim.claimOnlineStatusCode === AppConstants.COS_AWAITING_SURGERY_DETAILS_CD_03  && this.userClaim.claimOnlineSubStatusCode === AppConstants.COSS_AWAITING_SURGERY_DETAILS_CD_03  && this.userClaim.coverageCategory && this.userClaim.coverageCategory.toUpperCase() === AppConstants.PFL)
            && ( this.userClaim.asmIndicator === AppConstants.ASMINDICATOR_P)
            && (this.userClaim.asmIndicator === rulejson.asmIndicator)
            && (this.userClaim.claimOnlineStatusCode === rulejson.claimOnlineStatusCode)
            && (this.userClaim.claimOnlineSubStatusCode === rulejson.claimOnlineSubStatusCode)
            && (this.userClaim.babyDeathIndicator === null && rulejson.babyDeathIndicator === null)
          ) {
            this.dateLabel = rulejson.dateLabel;
            this.dateValue = this.userClaim.authorizedEndDate;
            this.coverageCategoryPFL = this.userClaim.coverageCategory;
            //this.showForOnlyPflClaims = true;
            /* Note blazeOverViewRule array is uesd to push the date label and date value in empty array*/
            this.blazeOverViewRule.push(
              { id: this.userClaim.claimEventId,
                dateLabel: this.dateLabel,
                date: this.dateValue,
                category: this.coverageCategoryPFL
              });
          } else if (
            (this.userClaim.claimOnlineStatusCode === AppConstants.COS_CLAIM_APPROVED_CD_04  && this.userClaim.claimOnlineSubStatusCode === AppConstants.COSS_BENEFIT_PAYMENTS_COMPLETE_CD_04 && this.userClaim.coverageCategory && this.userClaim.coverageCategory.toUpperCase() === AppConstants.PFL)
            && ( this.userClaim.asmIndicator === AppConstants.ASMINDICATOR_P)
            && (this.userClaim.asmIndicator === rulejson.asmIndicator)
            && (this.userClaim.claimOnlineStatusCode === rulejson.claimOnlineStatusCode)
            && (this.userClaim.claimOnlineSubStatusCode === rulejson.claimOnlineSubStatusCode)
            && (this.userClaim.babyDeathIndicator === null && rulejson.babyDeathIndicator === null)
          ) {
            this.dateLabel = rulejson.dateLabel;
            this.dateValue = this.userClaim.authorizedEndDate;
            this.coverageCategoryPFL = this.userClaim.coverageCategory;
            /* Note blazeOverViewRule array is uesd to push the date label and date value in empty array*/
            this.blazeOverViewRule.push(
              { id: this.userClaim.claimEventId,
                dateLabel: this.dateLabel,
                date: this.dateValue,
                category: this.coverageCategoryPFL
              });
          } else if (
            (this.userClaim.claimOnlineStatusCode === AppConstants.COS_UNDER_REVIEW_BY_CLAIM_HANDLER_CD_08  && this.userClaim.claimOnlineSubStatusCode === AppConstants.COSS_CLAIM_CLOSED_CD_08)
            && ( this.userClaim.asmIndicator === AppConstants.ASMINDICATOR_P)
            && (this.userClaim.asmIndicator === rulejson.asmIndicator)
            && (this.userClaim.claimOnlineStatusCode === rulejson.claimOnlineStatusCode)
            && (this.userClaim.claimOnlineSubStatusCode === rulejson.claimOnlineSubStatusCode)
            && (this.userClaim.babyDeathIndicator === null && rulejson.babyDeathIndicator === null)
          ) {
            this.dateLabel = rulejson.dateLabel;
            this.dateValue = this.userClaim.authorizedEndDate;
            this.coverageCategoryPFL = this.userClaim.coverageCategory;
            /* Note blazeOverViewRule array is uesd to push the date label and date value in empty array*/
            this.blazeOverViewRule.push(
              { id: this.userClaim.claimEventId,
                dateLabel: this.dateLabel,
                date: this.dateValue,
                category: this.coverageCategoryPFL
              });
          } else if (
            (this.userClaim.claimOnlineStatusCode === AppConstants.COS_UNDER_REVIEW_BY_CLAIM_HANDLER_CD_08  && this.userClaim.claimOnlineSubStatusCode === AppConstants.COSS_MISSING_INFORMATION_CD_13 && this.userClaim.coverageCategory && this.userClaim.coverageCategory.toUpperCase() === AppConstants.PFL)
            && ( this.userClaim.asmIndicator === AppConstants.ASMINDICATOR_P)
            && (this.userClaim.asmIndicator === rulejson.asmIndicator)
            && (this.userClaim.claimOnlineStatusCode === rulejson.claimOnlineStatusCode)
            && (this.userClaim.claimOnlineSubStatusCode === rulejson.claimOnlineSubStatusCode)
            && (this.userClaim.babyDeathIndicator === null && rulejson.babyDeathIndicator === null)
          ) {
            this.dateLabel = rulejson.dateLabel;
            this.dateValue = this.userClaim.lastDayWork;
            this.coverageCategoryPFL = this.userClaim.coverageCategory;
            /* Note blazeOverViewRule array is uesd to push the date label and date value in empty array*/
            this.blazeOverViewRule.push(
              { id: this.userClaim.claimEventId,
                dateLabel: this.dateLabel,
                date: this.dateValue,
                category: this.coverageCategoryPFL
              });
          } else if (
            (this.userClaim.claimOnlineStatusCode === AppConstants.COS_AWAITING_DISABILITY_CD_02  && this.userClaim.claimOnlineSubStatusCode === AppConstants.COSS_AWAITING_DISABILITY_CD_02 && this.userClaim.coverageCategory && this.userClaim.coverageCategory.toUpperCase() === AppConstants.PFL)
            && ( this.userClaim.asmIndicator === AppConstants.ASMINDICATOR_P)
            && (this.userClaim.asmIndicator === rulejson.asmIndicator)
            && (this.userClaim.claimOnlineStatusCode === rulejson.claimOnlineStatusCode)
            && (this.userClaim.claimOnlineSubStatusCode === rulejson.claimOnlineSubStatusCode)
            && (this.userClaim.babyDeathIndicator === null && rulejson.babyDeathIndicator === null)
            && (this.userClaim.coverageCategory === rulejson.coverageCategory)
          ) {
            this.dateLabel = rulejson.dateLabel;
            this.dateValue = this.userClaim.authorizedEndDate;
            this.coverageCategoryPFL = this.userClaim.coverageCategory;
            /* Note blazeOverViewRule array is uesd to push the date label and date value in empty array*/
            this.blazeOverViewRule.push(
              { id: this.userClaim.claimEventId,
                dateLabel: this.dateLabel,
                date: this.dateValue,
                category: this.coverageCategoryPFL
              });
          } else if (
            (this.userClaim.claimOnlineStatusCode === AppConstants.COS_CD_16  && this.userClaim.claimOnlineSubStatusCode === AppConstants.COSS_CD_16 && this.userClaim.coverageCategory && this.userClaim.coverageCategory.toUpperCase() === AppConstants.PFL)
            && ( this.userClaim.asmIndicator === AppConstants.ASMINDICATOR_P)
            && (this.userClaim.asmIndicator === rulejson.asmIndicator)
            && (this.userClaim.claimOnlineStatusCode === rulejson.claimOnlineStatusCode)
            && (this.userClaim.claimOnlineSubStatusCode === rulejson.claimOnlineSubStatusCode)
            && (this.userClaim.babyDeathIndicator === null && rulejson.babyDeathIndicator === null)
            && (this.userClaim.coverageCategory === rulejson.coverageCategory)
          ) {
            this.dateLabel = rulejson.dateLabel;
            this.dateValue = this.userClaim.authorizedEndDate;
            this.coverageCategoryPFL = this.userClaim.coverageCategory;
            /* Note blazeOverViewRule array is uesd to push the date label and date value in empty array*/
            this.blazeOverViewRule.push(
              { id: this.userClaim.claimEventId,
                dateLabel: this.dateLabel,
                date: this.dateValue,
                category: this.coverageCategoryPFL
              });
          } else if (
            (this.userClaim.claimOnlineStatusCode === AppConstants.COS_AWAITING_PAID_FAMILY_LEAVE_CD_09  && this.userClaim.claimOnlineSubStatusCode === AppConstants.COSS_NYPFL_CLAIM_CD_14 && this.userClaim.coverageCategory && this.userClaim.coverageCategory.toUpperCase() === AppConstants.PFL)
            && ( this.userClaim.asmIndicator === AppConstants.ASMINDICATOR_P)
            && (this.userClaim.asmIndicator === rulejson.asmIndicator)
            && (this.userClaim.claimOnlineStatusCode === rulejson.claimOnlineStatusCode)
            && (this.userClaim.claimOnlineSubStatusCode === rulejson.claimOnlineSubStatusCode)
            && (this.userClaim.babyDeathIndicator === null && rulejson.babyDeathIndicator === null)
            && (this.userClaim.coverageCategory === rulejson.coverageCategory)
          ) {
            this.dateLabel = rulejson.dateLabel;
            this.dateValue = this.userClaim.lastDayWork;
            this.coverageCategoryPFL = this.userClaim.coverageCategory;
            /* Note blazeOverViewRule array is uesd to push the date label and date value in empty array*/
            this.blazeOverViewRule.push(
              { id: this.userClaim.claimEventId,
                dateLabel: this.dateLabel,
                date: this.dateValue,
                category: this.coverageCategoryPFL
              });
          } else if (
            (this.userClaim.claimOnlineStatusCode === AppConstants.COS_UNDER_REVIEW_BY_CLAIM_HANDLER_CD_08  && this.userClaim.claimOnlineSubStatusCode === AppConstants.COSS_IN_ADJUDICATION_CD_12 && this.userClaim.coverageCategory && this.userClaim.coverageCategory.toUpperCase() === AppConstants.PFL)
            && ( this.userClaim.asmIndicator === AppConstants.ASMINDICATOR_P)
            && (this.userClaim.asmIndicator === rulejson.asmIndicator)
            && (this.userClaim.claimOnlineStatusCode === rulejson.claimOnlineStatusCode)
            && (this.userClaim.claimOnlineSubStatusCode === rulejson.claimOnlineSubStatusCode)
            && (this.userClaim.babyDeathIndicator === null && rulejson.babyDeathIndicator === null)
            && (this.userClaim.coverageCategory === rulejson.coverageCategory)
          ) {
            this.dateLabel = rulejson.dateLabel;
            this.dateValue = this.userClaim.lastDayWork;
            this.coverageCategoryPFL = this.userClaim.coverageCategory;
            /* Note blazeOverViewRule array is uesd to push the date label and date value in empty array*/
            this.blazeOverViewRule.push(
              { id: this.userClaim.claimEventId,
                dateLabel: this.dateLabel,
                date: this.dateValue,
                category: this.coverageCategoryPFL
              });
          }
        }

    /*Filter Duplicate records */
    this.blazeOverViewRulefilterRecords = this.blazeOverViewRule.filter(function(filterRecords) {
      const key = filterRecords.id + '|' + filterRecords.date;
      if (!this[key]) {
        this[key] = true;
        return true;
      }
    }, Object.create(null));

  }

  businessRulePFL() {
    if (this.userClaim.claimOnlineStatusCode && (this.userClaim.claimOnlineStatusCode === AppConstants.COS_UNDER_REVIEW_BY_CLAIM_HANDLER_CD_08)) {
      this.dateLabel = this.appConstants.LAST_DATE_WORKED;
      this.dateValue = this.userClaim.lastDayWork;
    } else if (this.userClaim.claimOnlineStatusCode && ((this.userClaim.claimOnlineStatusCode === AppConstants.COS_CLAIM_APPROVED_CD_04)  || (this.userClaim.claimOnlineStatusCode === AppConstants.COS_CLAIM_CLOSED_CD_05))) {
      this.dateLabel = this.appConstants.APP_THROUGH_DATE;
      this.dateValue = this.userClaim.authorizedEndDate;
    } else if (this.userClaim.claimOnlineStatusCode  && this.userClaim.claimOnlineStatusCode === AppConstants.COS_CLAIM_DENIED_CD_06) {
      this.dateLabel = this.appConstants.DECISION_DATE;
      this.dateValue = this.userClaim.claimProcessDate;
    }
  }
  claimEventId(claimEventId) {
    if (this.userClaim.coverageCategory !== AppConstants.PFL) {
      for (const rule of this.blazeOverViewRulefilterRecords) {
        if (claimEventId === rule.id) {
          this.storageManagementService.setSessionObject(SessionConstants.UI_RULE_LABEL, rule.dateLabel);
          this.storageManagementService.setSessionObject(SessionConstants.UI_RULE_DATE, rule.date);
        }
      }
    } else {
      this.storageManagementService.setSessionObject(SessionConstants.UI_RULE_LABEL, this.dateLabel);
      this.storageManagementService.setSessionObject(SessionConstants.UI_RULE_DATE, this.dateValue);
    }
  }

  getClaimEventId(claimEventId) {
    this.claimEventId(claimEventId);
  }

}
