import {Component, OnInit} from '@angular/core';
import {LetterListService} from '../../../shared/services/letter-list.service';
import {LetterClaimDetails} from '../../../shared/models/letterList.model';
import {DocumentInfo} from '../../../shared/models/document.model';
import {LetterImageCodeService} from '../../../shared/services/letter-image-code.service';
import {StorageManagementService} from '../../../shared/services/storage-management.service';
import {AppLabelConstants} from '../../../shared/constants/appLabel.constants';
import {SessionConstants} from '../../../shared/constants/session.constants';
import { IOverpaymentClaimDetails } from '@app/shared/models/overpaymentClaimDetails';
import { CustomEventService } from '@app/shared/services/tealium/custom-event.service';

/**
 * More Letters Page Component
 */
@Component({
  selector: 'app-more-letters-page',
  templateUrl: './more-letters-page.component.html',
  styleUrls: ['./more-letters-page.component.scss']
})
export class MoreLettersPageComponent implements OnInit {
  public pageLabel = AppLabelConstants.LETTERS_LABEL;
  public letterList: DocumentInfo[] = [] ;
  public personId: string;
  public appLabelConstants = AppLabelConstants;
  public previousUrl;
  overpaymentClaimDetails: IOverpaymentClaimDetails;

  /**
   * @param letterListService
   * @param storageManagementService
   * @param letterImageCodeService
   * @param customEventService
   */
  constructor(private letterListService: LetterListService,
              private storageManagementService: StorageManagementService,
              private letterImageCodeService: LetterImageCodeService,
              private customEventService: CustomEventService) { }

  ngOnInit() {
    this.previousUrl = this.storageManagementService.getSessionData(SessionConstants.PREVIOUS_URL);
    if (!this.previousUrl.includes('overPayment')) {
      this.storageManagementService.removeAttrFromSessionData(SessionConstants.OVERPAYMENT_CLAIM_DETAILS);
    }
    this.overpaymentClaimDetails = this.storageManagementService.getSessionData(SessionConstants.OVERPAYMENT_CLAIM_DETAILS);
    const userData = this.storageManagementService.getAppData(SessionConstants.USER_DATA);
    const personIds = userData ? userData.personIds : [];
    if (personIds && personIds.length > 0) {
      this.personId = personIds[0];
    }
    const letterList: LetterClaimDetails[] = this.storageManagementService.getSessionData('letters');
    if (letterList && letterList.length > 0) {
      letterList.forEach(item => {
        this.letterList.push(new DocumentInfo(item.letterId, item.letterName, item.createDate));
      });
    }
  }

  /**
   * To get letter user clicked on
   *
   * @param doc
   */
  viewLetter(doc) {
    if (this.overpaymentClaimDetails) {
      this.customEventService.customEvent(this.appLabelConstants.VIEW_LETTER_LABEL, this.appLabelConstants.PAGE_TAG, this.appLabelConstants.BUTTON_CLICK, doc.docName + ' - ' + doc.date, this.overpaymentClaimDetails.claimId + ' - ' + this.overpaymentClaimDetails.claimSubType + this.appLabelConstants.ANALYTICS_LETTER);
    }
    this.letterImageCodeService.getLetterImageService(this.personId, doc.docId).subscribe(
      (data) => {
        if (data && data.imgStream) {
          const mimeType = 'application/pdf';
          const res = this.letterImageCodeService.b64toBlob(data.imgStream, mimeType, 512);
          const blob = new Blob([res], {type: mimeType});
          const fileObjectURL = URL.createObjectURL(blob);

          if (window.navigator.appVersion.toString().indexOf('.NET') > 0) { // for IE browser
            const nav = (window.navigator as any);
            if (nav.msSaveOrOpenBlob) {
              nav.msSaveOrOpenBlob(blob, fileObjectURL);
            }
          } else {
            const link = document.createElement('a');
            link.href = fileObjectURL;
            link.download = 'Letter ' + doc.docId;
            link.click();
          }
        }
      }
    );
  }
}
