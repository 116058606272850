import {AfterViewInit, Component, OnInit} from '@angular/core';
import {ReferenceHelperService} from '../../shared/services/reference-helper.service';
import {ContentService} from '../../shared/services/content.service';
import {StorageManagementService} from '../../shared/services/storage-management.service';
import {Router} from '@angular/router';
import {LeaveDynatraceAction} from '../../shared/utils/leaveDynatraceAction';
import {AppLabelConstants} from '../../shared/constants/appLabel.constants';
import {AppConstants} from '../../shared/constants/app.constants';
import {SessionConstants} from '../../shared/constants/session.constants';

@Component({
  selector: 'app-login-error',
  templateUrl: './login-error.component.html',
  styleUrls: ['./login-error.component.scss']
})
export class LoginErrorComponent extends LeaveDynatraceAction implements OnInit, AfterViewInit {
  public pageTitle: string;
  public appLabelConstants = AppLabelConstants;
  public image: string;
  public title: string;
  public description: string;


  private pageId: string;
  private OWCSResponse;
  private caseData;
  constructor(
    private referenceHelper: ReferenceHelperService,
    private contentService: ContentService,
    private storageManagementService: StorageManagementService,
    private router: Router
  ) {
    super(storageManagementService);
  }

  ngOnInit() {
    this.referenceHelper.setReferenceOption(AppConstants.IS_FORCED_EMPLOYER, AppConstants.TRUE);
    this.caseData =  this.storageManagementService.getAppData(SessionConstants.CASE_DATA);

    this.integrateOWCS();

  }

  ngAfterViewInit() {
    super.ngAfterViewInit();
  }

  integrateOWCS() {
    this.pageId = this.contentService.getPageId(AppConstants.LOGIN_ERROR);
    if(this.pageId) {
      this.contentService.getOWCSResponse(this.pageId, AppConstants.LOGIN_ERROR_U).subscribe(
        res => {
          this.OWCSResponse = res;
          this.initElements();
        }
      )
    }
  }

  initElements() {
    if ( this.caseData.ssoOnlyCaseIndicator.toUpperCase() === AppConstants.Y && this.caseData.excludedCaseIndicator.toUpperCase() === AppConstants.N ) {
      this.image = AppLabelConstants.NOT_AUTH_ICON;
      this.initWithOWCS( AppConstants.LEP_06_BODY, AppConstants.LEP_07_BODY);
    } else if ( this.caseData.ssoOnlyCaseIndicator.toUpperCase() === AppConstants.N && this.caseData.excludedCaseIndicator.toUpperCase() === AppConstants.Y ) {
      this.image = AppLabelConstants.ERROR_SVG;
      this.initWithOWCS( AppConstants.LEP_03_BODY, AppConstants.LEP_04_BODY);
    } else if ( this.caseData.ssoOnlyCaseIndicator.toUpperCase() === AppConstants.Y && this.caseData.excludedCaseIndicator.toUpperCase() === AppConstants.Y ) {
      this.image = AppLabelConstants.NOT_AUTH_ICON;
      this.initWithOWCS( AppConstants.LEP_06_BODY, AppConstants.LEP_07_BODY);
    }
  }

  initWithOWCS( titleKey: string, descriptionKey: string ) {
    this.title = this.OWCSResponse.data[titleKey].value;
    this.description = this.OWCSResponse.data[descriptionKey].value;
  }

  returnHome() {
    this.router.navigate([AppLabelConstants.URL_HOME]);
  }
}
