import {IHealthProviderDetail, IHealthProviderList} from '@app/cxr/modules/intake/shared/models/health-provider.model';
export const MOCK_INTAKE_HEALTH_PROVIDER: IHealthProviderList = {
    count: 1,
    GetDetail: [
        {
            number: 1841618667,
            firstName: 'Jay',
            lastName: 'Cutler',
            name: 'Cutler Jay',
            address1: '525 East St',
            address2: 'Intervention radiology',
            city: 'New York',
            state: 'NY',
            postalCode: '10065',
            telephoneNumber: '202-415-0142',
            faxNumber: null
          }
    ],
  responseCode: '001',
  responseReason: 'Success'
};

export const MOCK_HEALTH_PROVIDER_DETAIL: IHealthProviderDetail = {
  number: 12345,
  firstName: 'JO',
  lastName: 'JO',
  name: 'JO',
  address1: 'new york',
  address2: 'new york',
  city: 'new york',
  state: 'new york',
  postalCode: '10203',
  telephoneNumber: '1234567890',
  faxNumber: '1234567890'
}

export const MOCK_HEALTH_PROVIDER_DETAIL_ADDED: IHealthProviderDetail[]= [{
  number: 12345,
  firstName: 'JO',
  lastName: 'JO',
  name: 'JO',
  address1: 'new york',
  address2: 'new york',
  city: 'new york',
  state: 'new york',
  postalCode: '10203',
  telephoneNumber: '1234567890',
  faxNumber: '1234567890',
  isAdded: true
}]
