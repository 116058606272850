import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';


@Component({
  selector: 'secondary-dropdown',
  templateUrl: 'secondary-dropdown.component.html',
  styleUrls: ['secondary-dropdown.component.scss']
})
export class SecondaryDropdownComponent implements OnInit {
  @Input() dropdownList: object[];
  @Input() optionDefaultvalue: string;
  @Input() dropdownOpen: boolean;
  @Output() dropdownIndex = new EventEmitter<string>();
  @Output() selectdatepicker = new EventEmitter<string>();
  @Input()  dataset = [];
  @Input() title: string = '';
  date: any = {};

  constructor() {


  }
  ngOnInit() {
   /* if (this.optionDefaultvalue === 'year'){
      this.date.Selecteddate = this.dataset[1];
    }
    if (this.optionDefaultvalue === 'month'){
      this.date.Selecteddate = this.dataset[0];
    }
    */
    if (this.optionDefaultvalue) {
      this.date.Selecteddate = this.dataset[0];
    }
  }
  toggleArrow() {

    this.dropdownOpen = !this.dropdownOpen;
  }

  /***********************
   * Date : 12/09/17
   * Auther :Ac12323/ Ac12295
   * Modified By:
   * Modified date :
   * Purpose : To User service integration according to partyrefId
   */

  /*selectedVal(elem){
    this.dropdownIndex.emit((parseInt(elem.selectedIndex)-1).toString());
  }*/

  emit(dateval?) {

   // alert(new Date(Date.parse(this.date.Selecteddate +" 1, 2012")).getMonth()+1);
    this.selectdatepicker.emit(this.date.Selecteddate);
  }

}
