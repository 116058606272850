/**
 * Created by AC12410 on 12/15/2017.
 */
/**
 * Created by AC12410 on 12/15/2017.
 */

export const SiteMapMock = {
  status: 200,
  data: {
    sitemap: [
      {
        pagename: 'Direct Deposit TandC',
        id: '1444617197790',
        key: ''
      },
      {
        pagename: 'New Disability Date Confirm',
        id: '1444617247006',
        key: ''
      },
      {
        pagename: 'New Disability Date',
        id: '1444617246829',
        key: ''
      },
      {
        pagename: 'Document Upload Confirmation Page',
        id: '1444617197197',
        key: ''
      },
      {
        pagename: 'Direct Deposit Validate',
        id: '1444617197623',
        key: ''
      },
      {
        pagename: 'T&C Verification Modal',
        id: '1444615948831',
        key: ''
      },
      {
        pagename: 'Confirm Disability Confirm',
        id: '1444617197024',
        key: ''
      },
      {pagename: 'Confirm EFT Modal', id: '1444617192560', key: ''},
      {pagename: 'THAA Confirm Delivery', id: '1444693050230', key: ''},
      {pagename: 'THAA Confirm Delivery Confirm', id: '1444693050236', key: ''},
      {pagename: 'THAA Confirm Disability', id: '1444693049970', key: ''},
      {pagename: 'THAA Confirm Disability Confirm', id: '1444693049989', key: ''},
      {pagename: 'THAA Confirm Surgery', id: '1444693050224', key: ''},
      {pagename: 'THAA Confirm Surgery Confirm', id: '1444693050218', key: ''},
      {pagename: 'THAA Last Day Worked', id: '1444693050242', key: ''},
      {pagename: 'THAA Last Day Worked Confirm', id: '1444693050248', key: ''},
      {pagename: 'THAA New Disability Date', id: '1444693049983', key: ''},
      {pagename: 'THAA New Disability Date Confirm', id: '1444693054225', key: ''},
      {pagename: 'THAA New Surgery Date', id: '1444693050210', key: ''},
      {pagename: 'THAA New Surgery Date Confirm', id: '1444693049996', key: ''},
      {pagename: 'THAA Request An Extension', id: '1444693050254', key: ''},
      {pagename: 'THAA Request An Extension Confirm', id: '1444693050260', key: ''},
      {pagename: 'THAA Leave Request an Extension Confirm', id: '1444693050261', key: ''},
      {pagename: 'Notifications', id: '1444694913393', key: ''},
      {pagename: 'Notification Intercept', id: '1444635690922', key: ''},
      {pagename: 'Work Schedule', id: '1444702662663', key: ''},
      {pagename: 'Return to Work', id: '1444702662664', key: ''},
      {pagename: 'Disability Return to Work', id: '1444709420966', key: ''},
      {pagename: 'Disability Return to Work Confirm', id: '1444616420980', key: ''},
      {pagename: 'Benefits Breakdown', id: '1444711053754', key: ''},
      {pagename: 'Failed Transaction Error Page', id: '1444635731211', key: ''},
      {pagename: 'THAA Interactive Extension', id: '1444717782311', key: ''},
      {pagename: 'Start a New Leave AA Step 1', id: '1444667731219', key: ''},
      {pagename: 'New Intake', id: '1616641807054', key: ''},
      {
        pagename: 'Payment Schedule',
        id: '1444646008833',
        key: ''
      },
      {
        pagename: 'Confirm Disability Details',
        id: '1444617197002',
        key: ''
      },
      {
        pagename: 'Your Claim Payments',
        id: '1444616045004',
        key: ''
      },
      {
        pagename: 'Post Authenticated Product Page',
        id: '1444617270810',
        key: ''
      },
      {pagename: 'THAA Leave Request an Extension', id: '1444699139591', key: ''},
      {
        pagename: 'Request An Extension Confirm',
        id: '1444617180435',
        key: ''
      },
      {
        pagename: 'Confirm Delivery Confirm',
        id: '1444617192094',
        key: ''
      },
      {
        pagename: 'Direct Deposit Enroll',
        id: '1444617197271',
        key: ''
      },
      {
        pagename: 'Recently Resolved Items',
        id: '1444616046003',
        key: ''
      },
      {
        pagename: 'Direct Deposit Confirm',
        id: '1444617197752',
        key: ''
      },
      {
        pagename: 'About You',
        id: '1444615948890',
        key: ''
      },
      {
        pagename: 'FAQ Page',
        id: '1444617241635',
        key: ''
      },
      {
        pagename: 'Pre Authenticated Product Page',
        id: '1444615947991',
        key: ''
      },
      {
        pagename: 'Confirm Delivery',
        id: '1444617157624',
        key: ''
      },
      {
        pagename: 'Demo FAQ page',
        id: '1444617612783',
        key: ''
      },
      {
        pagename: 'Document Upload',
        id: '1444617197181',
        key: ''
      },
      {
        pagename: 'Start a New Claim',
        id: '1444617197801',
        key: ''
      },
      {
        pagename: 'Direct Deposit Modify',
        id: '1444617197731',
        key: ''
      },
      {
        pagename: 'Contact Us',
        id: '1444615966899',
        key: ''
      },
      {
        pagename: 'New Surgery Date Confirm',
        id: '1444617196422',
        key: ''
      },
      {
        pagename: 'Texting T&C',
        id: '1444615945379',
        key: ''
      },
      {
        pagename: 'New Surgery Date',
        id: '1444617192434',
        key: ''
      },
      {
        pagename: 'Co Branding',
        id: '1444617371048',
        key: ''
      },
      {
        pagename: 'Security Statement',
        id: '1444615837454',
        key: ''
      },
      {
        pagename: 'Last Day Worked',
        id: '1444616045669',
        key: ''
      },
      {
        pagename: 'Confirm Surgery Details',
        id: '1444617192121',
        key: ''
      },
      {
        pagename: 'Claims Overview',
        id: '1444615948948',
        key: ''
      },
      {
        pagename: 'Site wide T&C',
        id: '1444615948755',
        key: ''
      },
      {
        pagename: 'Your Payment Details',
        id: '1444616045303',
        key: ''
      },
      {
        pagename: 'Claim Details',
        id: '1444615948971',
        key: ''
      },
      {
        pagename: 'Request An Extension',
        id: '1444617180207',
        key: ''
      },
      {
        pagename: 'Last Day Work Confirm',
        id: '1444616046356',
        key: ''
      },
      {
        pagename: 'Direct Deposit Landing',
        id: '1444615948924',
        key: ''
      },
      {
        pagename: 'Confirm Surgery Confirm',
        id: '1444617192348',
        key: ''
      },
      {
        pagename: 'Employer Select',
        id: '0000000000000',
        key: ''
      },
      {
        pagename: 'Claims Wiz - Pre Login',
        id: '1444634951959',
        key: ''
      },
      {
        pagename: 'Claims Wiz - Post login',
        id: '1444635064600',
        key: ''
      },
      {
        pagename: 'Request Time Off Confirm',
        id: '1444656917082',
        key: ''
      },
      {
        pagename: 'Report Missed Work Hours',
        id: '110000000000',
        key: ''
      },
      {
        pagename: 'Leave Failed Transaction Error',
        id: '1444658430876',
        key: ''
      },
      {
        pagename: 'Start a New Leave AA Step 2',
        id: '1444661301246',
        key: ''
      },
      {
        pagename: 'Request Time Off Partial Fail',
        id: '1444662610233',
        key: ''
      },
      {
        pagename: 'OWCS Error Msg Page',
        id: '1444658670940',
        key: ''
      },
      {
        pagename: 'Start a New Leave AA Step 4',
        id: '1444661290545',
        key: ''
      },
      {
        pagename: 'Start a New Leave AA Step 1',
        id: '1444667731357',
        key: ''
      },
      {
        pagename: 'Leave Plans',
        id: '1444658341366',
        key: ''
      },
      {
        pagename: 'Unable to Submit Claims Online',
        id: '1444658341398',
        key: ''
      },
      {
        pagename: 'Benefits Breakdown',
        id: '1444711053754',
        key: ''
      },
      {
        pagename: 'Accommodation Update',
        id: '1444717203155',
        key: ''
      },
      {
        pagename: 'Accommodation Update Confirm',
        id: '1444717211715',
        key: ''
      },
      {
        pagename: 'ADA Start a Claim',
        id: '1444718224351',
        key: ''
      },
      {
        pagename: 'All Payments',
        id: '1616638211398',
        key: ''
      },
      {
        pagename: 'All Payment History',
        id: '1616638211421',
        key: ''
      },
      {
        id: '1616641946596',
        key: '',
        pagename: 'Payments You Owe'
      },
      {
        id: '1616641946673',
        key: '',
        pagename: 'Payment Submission'
      },
      {
        id: '1616641946738',
        key: '',
        pagename: 'Payment Submission Confirm'
      }
    ]
  }
};
