

export const TAX_FORM_MOCKDATA = {
  taxDetails:    {
    TaxForms: [{
      FormId: 84797665,
      Type: 'W-2',
      TaxYear: 2019,
      FileName: null,
      AccountNumber: '',
      CustomerId: '',
      Address1: '25 Friendly circle',
      Address2: '',
      City: 'Austin',
      State: 'TX',
      Country: 'US',
      ZipCode: '78723',
      Group: 'GB-800',
      GroupId: 0,
      FirstName: 'John',
      LastName: 'Black',
      MiddleName: 'X',
      RecipientID: 80443829,
      PrintIndicator: 0,
      PdfViewerLink: null,
      TIN: null,
      HoldForm: false,
      OrgType: 1001
    },
      {
        FormId: 84797666,
        Type: '1099-R',
        TaxYear: 2019,
        FileName: null,
        AccountNumber: '',
        CustomerId: '',
        Address1: '25 Friendly circle',
        Address2: '',
        City: 'Austin',
        State: 'TX',
        Country: 'US',
        ZipCode: '78723',
        Group: 'GB-800',
        GroupId: 0,
        FirstName: 'John',
        LastName: 'Black',
        MiddleName: 'X',
        RecipientID: 80443829,
        PrintIndicator: 0,
        PdfViewerLink: null,
        TIN: null,
        HoldForm: false,
        OrgType: 1001
      },
      {
        FormId: 84797667,
        Type: '1099-Misc',
        TaxYear: 2019,
        FileName: null,
        AccountNumber: '',
        CustomerId: '',
        Address1: '25 Friendly circle',
        Address2: '',
        City: 'Austin',
        State: 'TX',
        Country: 'US',
        ZipCode: '78723',
        Group: 'GB-800',
        GroupId: 0,
        FirstName: 'John',
        LastName: 'Black',
        MiddleName: 'X',
        RecipientID: 80443829,
        PrintIndicator: 0,
        PdfViewerLink: null,
        TIN: null,
        HoldForm: false,
        OrgType: 1001
      },
      {
        FormId: 84797668,
        Type: '1099-R',
        TaxYear: 2018,
        FileName: null,
        AccountNumber: '',
        CustomerId: '',
        Address1: '25 Friendly circle',
        Address2: '',
        City: 'Austin',
        State: 'TX',
        Country: 'US',
        ZipCode: '78723',
        Group: 'GB-800',
        GroupId: 0,
        FirstName: 'John',
        LastName: 'Black',
        MiddleName: 'X',
        RecipientID: 80443829,
        PrintIndicator: 0,
        PdfViewerLink: null,
        TIN: null,
        HoldForm: false,
        OrgType: 1001
      }, {
        FormId: 84797669,
        Type: '1099-Int',
        TaxYear: 2018,
        FileName: null,
        AccountNumber: '',
        CustomerId: '',
        Address1: '25 Friendly circle',
        Address2: '',
        City: 'Austin',
        State: 'TX',
        Country: 'US',
        ZipCode: '78723',
        Group: 'GB-800',
        GroupId: 0,
        FirstName: 'John',
        LastName: 'Black',
        MiddleName: 'X',
        RecipientID: 80443829,
        PrintIndicator: 0,
        PdfViewerLink: null,
        TIN: null,
        HoldForm: false,
        OrgType: 1001
      }, {
        FormId: 84797670,
        Type: '499R-2/W-2PR',
        TaxYear: 2017,
        FileName: null,
        AccountNumber: '',
        CustomerId: '',
        Address1: '25 Friendly circle',
        Address2: '',
        City: 'Austin',
        State: 'TX',
        Country: 'US',
        ZipCode: '78723',
        Group: 'GB-800',
        GroupId: 0,
        FirstName: 'John',
        LastName: 'Black',
        MiddleName: 'X',
        RecipientID: 80443829,
        PrintIndicator: 0,
        PdfViewerLink: null,
        TIN: null,
        HoldForm: false,
        OrgType: 1001
      }],
      ExemptTaxForms: [
        {
          FormId: 84797670,
          Type: 'W-2',
          TaxYear: 2017,
          FileName: null,
          AccountNumber: '',
          CustomerId: '',
          Address1: '25 Friendly circle',
          Address2: '',
          City: 'Austin',
          State: 'TX',
          Country: 'US',
          ZipCode: '78723',
          Group: 'GB-800',
          GroupId: 0,
          FirstName: 'John',
          LastName: 'Black',
          MiddleName: 'X',
          RecipientID: 80443829,
          PrintIndicator: 0,
          PdfViewerLink: null,
          TIN: null,
          HoldForm: false,
          OrgType: 1001
        }
      ],
    HasExemptTaxForms: true,
    TIN: '010777111',
    HeldMessage: '',
    AllowFormsView: true,
    Success: true,
    Message: ''
  },
  responseCode: '001',
  responseReason: 'success'
};

export const TAX_FORMS_MOCKDATA = {
  taxDetails:    {
      ExemptTaxForms: [
        {
          FormId: 84797670,
          Type: 'W-2',
          TaxYear: 2017,
          FileName: null,
          AccountNumber: '',
          CustomerId: '',
          Address1: '25 Friendly circle',
          Address2: '',
          City: 'Austin',
          State: 'TX',
          Country: 'US',
          ZipCode: '78723',
          Group: 'GB-800',
          GroupId: 0,
          FirstName: 'John',
          LastName: 'Black',
          MiddleName: 'X',
          RecipientID: 80443829,
          PrintIndicator: 0,
          PdfViewerLink: null,
          TIN: null,
          HoldForm: false,
          OrgType: 1001
        }
      ],
    HasExemptTaxForms: true,
    TIN: '010777111',
    HeldMessage: '',
    AllowFormsView: true,
    Success: true,
    Message: ''
  },
  responseCode: '001',
  responseReason: 'success'
};
