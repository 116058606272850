import {Injectable} from '@angular/core';
import {Subject} from 'rxjs';
import {MessageRibbon} from '../models/message-ribbon.model';

@Injectable()
export class MessageRibbonService {

  private ribbonClosed = new Subject();
  private ribbonOpenedSource = new Subject();

  ribbonClosed$ = this.ribbonClosed.asObservable();
  ribbonOpened$ = this.ribbonOpenedSource.asObservable();

  /**
   * Adds a new message to the queue
   *
   * @param messageType sets the type of message to be displayed. (Info, error, success, warning)
   * @param messageContent sets the body content of the message displayed */
  addMessage(messageType: string, messageContent: string, messageHeader?: string, messageAutoClose?: string): string {
    const newMessage: MessageRibbon = new MessageRibbon();
    const currentTimeStamp = new Date().getTime().toString();
    newMessage.messageType = messageType;
    newMessage.messageContent = messageContent;
    newMessage.messageHeader = messageHeader ? messageHeader : null;
    newMessage.messageAutoClose = messageAutoClose ? messageAutoClose : 'false';
    newMessage.messageName = currentTimeStamp;
    this.ribbonOpenedSource.next(newMessage);
    return currentTimeStamp;
  }

  /**
   * Removes the selected ribbon
   *
   * @param ribbonName the desired ribbon to remove. */
  removeRibbon(ribbonName: string) {
    this.ribbonClosed.next(ribbonName);
  }
}
