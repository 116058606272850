import {Injectable} from '@angular/core';
import {TealiumDataService} from './tealium-data.service';
import {Location} from '@angular/common';
import {env} from '../../../../environments/environment-loader';
import { AppConstants } from '@app/shared/constants/app.constants';

declare let window: any;

@Injectable()
export class TealiumUtagService {
  script_src = '';
  script_src_sync = '';
  script_src_c2e = '';
  config: any;
  locationurl: Location;


  // Typically set "noview" flag (no first page automatic view event) to true for Single Page Apps (SPAs)
  constructor(private tealiumDataSvc: TealiumDataService, private location: Location) {
    //window.utag_cfg_ovrd = { noview : true };
    window.utag_data = {};
    this.locationurl = location;
  }

  // Generic script loader with callback
  getScript( src: string, callback: () => void ) {
    const d = document;
    const o = { callback: callback || function() {} };
    let s;

    if ( typeof src === 'undefined' ) {
      return;
    }

    s = d.createElement('script'); s.language = 'javascript'; s.type = 'text/javascript'; s.async = 1; s.charset = 'utf-8'; s.src = src;
    if ( typeof o.callback === 'function' ) {
      if ( d.addEventListener ) {
        s.addEventListener('load', function() {
          o.callback()
        }, false);
      } else {
        // old IE support
        s.onreadystatechange = function() {
          if (this.readyState === 'complete' || this.readyState === 'loaded') {
            this.onreadystatechange = null; o.callback()
          }
        };
      }
    }
    const t = d.getElementsByTagName('script')[0];
    t.parentNode.insertBefore(s, t);
  }

  // Todo: Adjust env logic to use Angular features and/or include more environments
  // Config settings used to build the path to the utag.js file
  setConfig() {
    const host: string = window.location.hostname;
    let environmentVal: string = env.locationurl;
    const envName: string = env.envName;
    environmentVal = 'dev';
    this.script_src_c2e = 'https://ts0.hfdstatic.com/c2e/ee/cxr/js/atg.js';

    switch (envName) {
      case AppConstants.LOCAL:
        environmentVal = 'dev';
        this.script_src_c2e = 'https://ts0.hfdstatic.com/c2e/ee/cxr/js/atg.js';
        break;
      case AppConstants.LOCAL_QA:
        environmentVal = 'qa';
        this.script_src_c2e = 'https://ts0.hfdstatic.com/c2e/ee/cxr/js/atg.js';
        break;
      case 'qa':
        environmentVal = 'qa';
        this.script_src_c2e = 'https://ts0.hfdstatic.com/c2e/ee/cxr/js/atg.js';
        break;
      case 'lqa2':
        environmentVal = 'qa';
        this.script_src_c2e = 'https://ts0.hfdstatic.com/c2e/ee/cxr/js/atg.js';
        break;
      case 'lqa':
        environmentVal = 'qa';
        this.script_src_c2e = 'https://ts0.hfdstatic.com/c2e/ee/cxr/js/atg.js';
        break;
      case 'lqa3':
        environmentVal = 'qa';
        this.script_src_c2e = 'https://ts0.hfdstatic.com/c2e/ee/cxr/js/atg.js';
        break;
      case 'ltd':
        environmentVal = 'dev';
        this.script_src_c2e = 'https://ts0.hfdstatic.com/c2e/ee/cxr/js/atg.js';
        break;
      case 'ltd2':
        environmentVal = 'dev';
        this.script_src_c2e = 'https://ts0.hfdstatic.com/c2e/ee/cxr/js/atg.js';
        break;
      case 'ltd3':
        environmentVal = 'dev';
        this.script_src_c2e = 'https://ts0.hfdstatic.com/c2e/ee/cxr/js/atg.js';
        break;
      case 'prod':
        environmentVal = 'prod';
        this.script_src_c2e = 'https://s0.hfdstatic.com/c2e/ee/cxr/js/atg.js';
        break;
      default:
        environmentVal = '';
        this.script_src_c2e = '';
        break;
    }
    // Determine environmentVal based on app host name
 /*   if (host.toLowerCase().includes('ltd') || host.toLowerCase().includes('ltt')) {
      environmentVal = 'dev';
      this.script_src_c2e = 'https://ts0.hfdstatic.com/c2e/ee/cxr/js/atg.js';
    } else if (host.toLowerCase().includes('lqa' ) || host.toLowerCase().includes('qa' )) {
      environmentVal = 'qa';
      this.script_src_c2e = 'https://ts0.hfdstatic.com/c2e/ee/cxr/js/atg.js';
    } else if (host.toLowerCase().includes('mybenefits' )) {
      environmentVal = 'prod';
      this.script_src_c2e = 'https://s0.hfdstatic.com/c2e/ee/cxr/js/atg.js';
    }*/
    /*
     else if(host.toLowerCase().includes(env.LTI || env.INT)) {
     environmentVal = env.DEV;
     }

     else if(host.toLowerCase().includes(env.PROD_QUOTE)) {
     environmentVal = env.PROD;
     }
     */
    this.config = {
      site_name: 'GB-CXR',
      account: 'thehartford',
      profile : 'mybenefits-cxr',
      environment: environmentVal,
      locationurl: this.locationurl.path()  // This is also not being used for tealium , we are using it tealium-data.service.ts
    };
    //if ( this.config.account !== undefined && this.config.profile !== undefined && this.config.environment !== undefined ) {
    this.script_src_sync = env.gbTealium.TEALIUM_UTAG_URI + this.config.account + '/' + this.config.profile + '/' + this.config.environment + '/utag.sync.js';
    this.script_src = env.gbTealium.TEALIUM_UTAG_URI + this.config.account + '/' + this.config.profile + '/' + this.config.environment + '/utag.js';
    // this.script_src_sync = env.TEALIUM_UTAG_URI ;
    // this.script_src = 'https://tags.tiqcdn.com/utag/thehartford/mybenefits-cxr/dev/utag.js';
    // }
    // this.script_src = 'https://tags.tiqcdn.com/utag/thehartford/mybenefits-cxr/dev/utag.js';
  }

  // Data layer is optional set of key/value pairs
  track(tealium_event: string, data?: any) {
    if ( this.script_src === '' ) {
      //console.log('Tealium config not set.');
      return;
    }
    //this.getScript( this.script_src_c2e, function() {});
    if ( window.utag === undefined ) {
      this.getScript( this.script_src_sync, function() {});
      this.getScript( this.script_src, function() {
        setTimeout(() => {
          window.utag?.track( tealium_event, data )
        }, 3000) ;
      });
    } else {
      window.utag?.track( tealium_event, data );
    }
  }


  view() {
    setTimeout(() => {
      this.setConfig();
      const data = this.tealiumDataSvc.setDataLayer(this.config);
      Object.keys(data).map((key) => {
        window.utag_data[key] = data[key]
      });
      //console.log('DATA LAYER:', window.utag_data);
      this.track('view', data);
    }, 5000);
  }
}


