import {AfterViewInit, Component, OnInit} from '@angular/core';
import {Router} from '@angular/router';
import {LeaveDynatraceAction} from '../../shared/utils/leaveDynatraceAction';
import {StorageManagementService} from '../../shared/services/storage-management.service';
import {AppLabelConstants} from '../../shared/constants/appLabel.constants';

@Component({
  selector: 'app-profile-error',
  templateUrl: './profile-error.component.html',
  styleUrls: ['./profile-error.component.scss']
})
export class ProfileErrorComponent extends LeaveDynatraceAction implements OnInit, AfterViewInit {
  public pageReturnLink: string;
  public pageTitle: string;
  public appLabelConstants = AppLabelConstants;

  public image: string;
  public title: string;
  public description: string;
  constructor(
    private router: Router,
    private storageManagementService: StorageManagementService,
  ) {
    super(storageManagementService);
  }

  ngOnInit() {
    this.pageReturnLink = AppLabelConstants.URL_HOME;
    this.pageTitle = AppLabelConstants.PROFILE_UNAVAILABLE;
    this.image = AppLabelConstants.PHONE_ICON;
    this.title = AppLabelConstants.LETS_TALK_BY_PHONE_INSTEAD;
    this.description = AppLabelConstants.WE_R_HAVING_TROUBLE_WITH_THIS_PAGE_MSG;
  }

  returnHome() {
    this.router.navigate([AppLabelConstants.URL_HOME]);
  }
}
