import {IStateResponse} from '@shared/models/stateList.model';

export const MOCK_STATE_LIST: IStateResponse = {
  stateList: [
    {
      stateCode: 'CT',
      stateDescription: 'Connecticut',
      stateId: 0,
      responseCode: '001',
      responseReason: 'Success'
    },
    {
      stateCode: 'VT',
      stateDescription: 'Vermont',
      stateId: 1,
      responseCode: '001',
      responseReason: 'Success'
    },
  ],
}
