import { Component, Input, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { IntakeSessionConstants } from "@app/cxr/modules/intake/shared/constants/intakeSession.constants";
import { TaxFormConstants } from "@app/cxr/tax-form/tax-form.constants";
import { AppConstants } from "@shared/constants/app.constants";
import { Subscription } from "rxjs";
import { AppLabelConstants } from "../../../../shared/constants/appLabel.constants";
import { SessionConstants } from "../../../../shared/constants/session.constants";
import { AuthStateService } from "../../../../shared/services/auth-state.service";
import { ReferenceHelperService } from "../../../../shared/services/reference-helper.service";
import { ResetPasswordService } from "../../../../shared/services/reset-password.service";
import { ResponseErrorHandlerService } from "../../../../shared/services/response-error-handler.service";
import { StorageManagementService } from "../../../../shared/services/storage-management.service";

@Component({
  selector: "app-header",
  templateUrl: "header.component.html",
  styleUrls: ["header.component.scss"],
})
export class HeaderComponent implements OnInit {
  @Input() pageTitle: string;
  displayPaymentLink: boolean = false;
  isPaymentDisabled: boolean = false;
  public hideLinks: boolean = true;
  public hideProfile: boolean = false;
  public hideHeader: boolean = false;
  public impersonationType: string;
  public disableLogo = false;
  public isOverViewCompleted: boolean = false;
  public profileUrl;
  public isForcedCaseSelectPage = false;
  public appLabelConstants = AppLabelConstants;
  public taxFormConstants = TaxFormConstants;
  private subscription: Subscription;
  private currUser;

  constructor(
    private authStateService: AuthStateService,
    private router: Router,
    private storageManagement: StorageManagementService,
    private referenceHelper: ReferenceHelperService,
    private errorHandlerService: ResponseErrorHandlerService,
    private resetPasswordService: ResetPasswordService,
  ) {}

  ngOnInit() {
    const curURL = window.location.pathname;
    if (this.storageManagement.getSessionData("isOverviewLoaded") === true) {
      this.referenceHelper.setReferenceOption("isOverViewLoaded", "true");
      this.referenceHelper.setReferenceOption("loginState", "true");
      this.setProfileLink();
      this.hideLinks = false;
      this.hideProfile = false;
      this.paymentLinkDisplayLogic();
      const isOverpaymentEligible = this.storageManagement.getSessionData(
        SessionConstants.IS_OVERPAYMENT_ELIGIBLE,
      );
      this.isOverViewCompleted = true;
    }
    if (curURL === "/login") {
      this.referenceHelper.setReferenceOption("loginState", "false");
    }
    if (curURL === "/psv") {
      const loginStateJSON = JSON.parse(
        this.storageManagement.retrieveSessionObject(SessionConstants.LOGIN_STATE),
      );
      this.hideHeader = loginStateJSON ? loginStateJSON.loginState : false;
      this.hideLinks = true;
      this.disableLogo = true;
    }
    this.subscription = this.referenceHelper.reference.subscribe((val) => {
      this.isForcedCaseSelectPage =
        val.get("isForcedEmployer") && JSON.parse(val.get("isForcedEmployer"));
      if (val.get("isOverViewLoaded") && JSON.parse(val.get("isOverViewLoaded"))) {
        this.setProfileLink();
        this.isOverViewCompleted = true;
        this.storageManagement.setSessionData(SessionConstants.IS_OVERVIEW_LOADED, true);
        this.paymentLinkDisplayLogic();
      }
      if (val.get("loginState") && JSON.parse(val.get("loginState"))) {
        this.hideLinks = false;
        this.hideProfile = false;
      } else if (val.get("loginState") && !JSON.parse(val.get("loginState"))) {
        this.hideLinks = true;
      }
    });

    if (
      window.location.pathname === AppLabelConstants.SECURITY_STMT_PATH ||
      window.location.pathname === AppLabelConstants.SIDE_WIDE_PATH ||
      window.location.pathname === AppLabelConstants.TEXT_TCS_PATH ||
      window.location.pathname === AppLabelConstants.TERMS_COND_PATH
    ) {
      this.hideLinks = true;
      this.disableLogo = true;
    } else if (
      window.location.pathname === AppLabelConstants.SELF_LINKING_URL ||
      window.location.pathname === AppLabelConstants.SELF_LINK_SUCCESS_PATH ||
      window.location.pathname === AppLabelConstants.URL_CONFIRM_ELG
    ) {
      this.hideProfile = true;
      this.disableLogo = true;
      this.hideLinks = false;
    } else if (window.location.pathname === AppLabelConstants.URL_NOTIFICATION_REG) {
      this.hideLinks = false;
      this.hideProfile = true;
      this.isOverViewCompleted = false;
      this.disableLogo = true;
    }
    this.impersonationType = this.storageManagement.retrieveSessionObject(
      SessionConstants.IMPERSONATION_TYPE,
    )
      ? JSON.parse(
          this.storageManagement.retrieveSessionObject(SessionConstants.IMPERSONATION_TYPE),
        ).impersonationType
      : null;
  }

  getState() {
    return this.authStateService.getLoginState();
  }

  logOut() {
    this.authStateService.logout();
  }

  setProfileLink() {
    this.currUser = JSON.parse(
      this.storageManagement.retrieveSessionObject(SessionConstants.APP_DATA),
    ).appData.userData;
    /*this.profileUrl = (this.currUser.insuredId || this.currUser.eligibilityId) ? '/myAccount' : '/profile-error';*/
    let profileDataCIAM;
    const profileCiamData = this.storageManagement.getAppData(SessionConstants.PROFILE_DATA_CIAM);
    if (profileCiamData && Object.keys(profileCiamData).length > 0) {
      this.profileUrl = this.appLabelConstants.URL_MYACCOUNT;
    } else {
      this.resetPasswordService.getTokenId().subscribe(
        (data) => {
          profileDataCIAM = data;
          this.storageManagement.setAppData(SessionConstants.PROFILE_DATA_CIAM, data);
        },
        (error) => {
          // console.log('Error in getWeakProfile--' + JSON.stringify(error));
          this.profileUrl = this.appLabelConstants.URL_MYACCOUNT;
        },
        () => {
          this.profileUrl = this.appLabelConstants.URL_MYACCOUNT;
        },
      );
    }
  }

  /*
   * payment link displays when toggle state for allClaimPayments is true and thaa user has at least 1 payment
   * */
  paymentLinkDisplayLogic() {
    this.isPaymentDisabled = this.storageManagement.getSessionData(
      AppConstants.IS_PAYMENT_DISABLED,
    );
    const toggleList = this.storageManagement.getSessionData(SessionConstants.TOGGLE_LIST);
    let paymentApplicable = this.storageManagement.getSessionData(
      SessionConstants.DISPLAY_PAY_STUB,
    );
    paymentApplicable = paymentApplicable !== null && paymentApplicable !== undefined;
    if (paymentApplicable && toggleList && toggleList.length > 0) {
      const toggleIndex = toggleList.findIndex(
        (toggles) => toggles.toggle.toLowerCase() === AppConstants.ALL_CLAIM_PAYMENTS.toLowerCase(),
      );
      if (toggleIndex > -1) {
        this.displayPaymentLink = toggleList[toggleIndex].state && paymentApplicable;
      }
    }
  }

  logoClick() {
    const checkLOA = this.storageManagement?.getIntakeSessionData(
      IntakeSessionConstants.LOA_INDICATOR,
    );
    if (!this.disableLogo) {
      if (this.getState()) {
        if (
          this.storageManagement.retrieveSessionObject(IntakeSessionConstants.INTAKE_SESSION_DATA)
        ) {
          this.storageManagement.removeSessionObject(IntakeSessionConstants.INTAKE_SESSION_DATA);
        }
        if (
          this.storageManagement.getSessionData(SessionConstants.IS_WORK_SCHEDULE_SUBMITTED) &&
          checkLOA
        ) {
          this.storageManagement.removeAttrFromSessionData(
            SessionConstants.IS_WORK_SCHEDULE_SUBMITTED,
          );
        }
        if (
          this.storageManagement.getSessionData(SessionConstants.REGULAR_WORK_SCHEDULE_SET) &&
          checkLOA
        ) {
          this.storageManagement.removeAttrFromSessionData(
            SessionConstants.REGULAR_WORK_SCHEDULE_SET,
          );
        }
        this.router.navigate([AppLabelConstants.URL_HOME]);
      } else {
        this.router.navigate([AppLabelConstants.URL_LOGIN]);
      }
    }
  }

  navigateToProfile() {
    const checkLOA = this.storageManagement?.getIntakeSessionData(
      IntakeSessionConstants.LOA_INDICATOR,
    );
    this.storageManagement.setSessionData(this.taxFormConstants.FROM_TAX_FORM, false);
    if (this.storageManagement.retrieveSessionObject(IntakeSessionConstants.INTAKE_SESSION_DATA)) {
      this.storageManagement.removeSessionObject(IntakeSessionConstants.INTAKE_SESSION_DATA);
    }
    if (
      this.storageManagement.getSessionData(SessionConstants.IS_WORK_SCHEDULE_SUBMITTED) &&
      checkLOA
    ) {
      this.storageManagement.removeAttrFromSessionData(SessionConstants.IS_WORK_SCHEDULE_SUBMITTED);
    }
    if (
      this.storageManagement.getSessionData(SessionConstants.REGULAR_WORK_SCHEDULE_SET) &&
      checkLOA
    ) {
      this.storageManagement.removeAttrFromSessionData(SessionConstants.REGULAR_WORK_SCHEDULE_SET);
    }
    this.router.navigate([AppLabelConstants.URL_MYACCOUNT]);
  }
}
