import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
  name: 'lineBreakFormat'
})
export class LineBreakFormatPipe implements PipeTransform {

  transform(value: any, args?: any): any {
    return value.replace(/,/g, ',<br>');
  }

}
