export class TaxFormConstants {
  /** Tax Forms - widget Section */
  static TAX_FORM = 'Tax Forms';
  static GET_TAX_FORMS = 'Get Your Tax Forms';
  static TAX_WIDGET_INFO = 'Need tax forms? You can download yours right away.';
  /** Tax Forms - widget Section */

  /** Tax Forms -  TAX FORMS LIST Section */
  static TAX_FORM_HELP_TEXT = 'Here are your available tax forms from the last three years. You can view and download any you’d like.';
  static TAX_FORM_CONTACT_TEXT = 'If you have questions about your tax forms, call us at [phoneNumber].';
  static LOADING_LABEL = 'Loading';
  static YEAR_LABEL = 'Year';
  static FORM_LABEL = 'Forms';
  static THAA = 'THAA';
  static DCS = 'DCS';
  static TAX_FORM_LIST = 'Tax Form Link';
  static PHONE_NUMBER_PLACEHOLDER = '[phoneNumber]';
  static TAXFORM_PHONE_NUMBER = 'taxFormPhoneNumber';
  static TAXFORM_PHONE_NUMBER_VALUE = '1-800-293-8607';
  /** Tax Forms -  TAX FORMS LIST Section */

  /** Tax Forms - NO TAX FORMS Section */
  static NO_FORMS_TITLE = 'You don\'t have any tax forms';
  static NO_FORMS_MSG = 'If you have questions about your tax forms, call us at [phoneNumber].';
  static NO_FORMS_MSG_MOB = 'If you have questions about your tax forms, call us at [phoneNumber].';
  /** Tax Forms - NO TAX FORMS Section */

  /** Tax Forms -  TAX FORMS ERROR Section */
  static FORMS_ERROR_TITLE = 'We can\'t show your tax forms';
  /** Tax Forms -  TAX FORMS ERROR Section */

  /** IMAGE PATHS FOR ALL THE FILES*/
  static TAX_FORM_ICON = '/assets/images/icons/taxForm.svg';
  /** IMAGE PATHS FOR ALL THE FILES ENDS HERE*/

  /** BUTTON TEXT */
  static RETURN_HOME = 'Return Home';
  /** BUTTON TEXT */

  /** BACK NAVIGATION */
  static BACK_LINK = '/home';
  static NO_TAX_FORM = '/taxForms/noTaxForms';
  static TAX_FORMS = '/taxForms';
  static FROM_TAX_FORM = 'FromTaxForms'
  /** BACK NAVIGATION */
}
