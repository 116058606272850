import {AfterViewInit, Component, OnInit} from '@angular/core';
import {Router} from '@angular/router';
import {LeaveDynatraceAction} from '../../shared/utils/leaveDynatraceAction';
import {StorageManagementService} from '../../shared/services/storage-management.service';
import {AppConstants} from '../../shared/constants/app.constants';
import {AppLabelConstants} from '../../shared/constants/appLabel.constants';
import {SessionConstants} from '../../shared/constants/session.constants';

@Component({
  selector: 'app-pha-access-point',
  templateUrl: 'pha-access-point.component.html',
  styleUrls: ['pha-access-point.component.scss']
})
export class PhaAccessPointComponent extends LeaveDynatraceAction implements OnInit, AfterViewInit {
  public appConstant = AppConstants;
  public appLabelConstant = AppLabelConstants;
  private isPulseWeak = false;
  public jsonBOM;
  private pulseID: number;
  constructor(private router: Router, private storageManagementService: StorageManagementService) {
    super(storageManagementService);
  }

  ngOnInit() {

    this.jsonBOM = JSON.parse(this.storageManagementService.retrieveSessionObject(SessionConstants.APP_DATA)).appData;
    if (this.jsonBOM.userData.sourcePartyDetailsList) {
      for (let i = 0; i < this.jsonBOM.userData.sourcePartyDetailsList.length; i++) {
        if (this.jsonBOM.userData.sourcePartyDetailsList[i].srcSystemCd === AppConstants.SRC_SYS_CD_3 && this.jsonBOM.userData.sourcePartyDetailsList[i].linkTypeCd === AppConstants.LINK_TYPE_CD_2) {
          this.storageManagementService.setSessionData(SessionConstants.PULSE_ID, this.jsonBOM.userData.sourcePartyDetailsList[i].srcSysId.toString());
          this.pulseID = this.jsonBOM.userData.sourcePartyDetailsList[i].srcSysId;
        }
      }
    }



      if (this.pulseID) {
        this.isPulseWeak = true;
      } else {
        this.isPulseWeak = false;
      }

  }

  ngAfterViewInit() {
    super.ngAfterViewInit();
  }

  navigateToPhaOverView() {
    if (!this.isPulseWeak) {
       this.router.navigate([AppLabelConstants.PHA_OVERVIEW_URL]);
    } else {
      this.router.navigate([AppLabelConstants.CONFIRM_ELG_URL]);
    }
  }
}
