
import {throwError as observableThrowError} from 'rxjs/internal/observable/throwError';
import {Observable} from 'rxjs';

import {catchError, map} from 'rxjs/operators';
import {Injectable} from '@angular/core';
import {ResponseErrorHandlerService} from './response-error-handler.service';
import {HttpClient, HttpHeaders, HttpResponse} from '@angular/common/http';
import {env} from '../../../environments/environment-loader';

import {Router} from '@angular/router';
import {AppLabelConstants} from '../constants/appLabel.constants';
import {StorageManagementService} from './storage-management.service';
import {SessionConstants} from '@shared/constants/session.constants';
import {AppConstants} from '@shared/constants/app.constants';

@Injectable()
export class TaxFormService {
public taxToken;
isFeatureToggleOn: boolean = false;
    constructor( private errorHandler: ResponseErrorHandlerService, private storageManagementService: StorageManagementService,
               private httpClient: HttpClient, private router: Router) { }

  getTaxForms(caseId: string , personId: string, insuredId: string): Observable<any> {
    return this.httpClient.get<HttpResponse<any>>(env.thaaWKABPortalService.taxFormURL + '&caseId=' + caseId + (personId ? '&personId=' + personId : insuredId ? '&insuredId=' + insuredId : ''),
      {
        headers: new HttpHeaders()
          .set(AppLabelConstants.CONTENT_TYPE_KEY, AppLabelConstants.APPLICATION_KEY),
           observe: 'response'
      }).pipe(
      map(
        (response: HttpResponse<any>) => {
          this.taxToken = response.headers.get(AppLabelConstants.TAX_TOKEN_HEADER);
          if (!this.errorHandler.isServiceResponseHasError(response)) {
            return response.body;
          } else {
            throw response;
          }
        }
      ), catchError((error: any) => {
        this.router.navigate(['/taxForms/taxFormsError']);
        return this.errorHandler.handleError(error);
      }), );

  }
  getTaxFormPDF(personID, formId, formType, insuredID, Group): Observable<any> {
    const toggleList = this.storageManagementService.getSessionData(SessionConstants.TOGGLE_LIST);
    if (toggleList && toggleList.length > 0) {
      const toggleIndex = toggleList.findIndex(toggles => toggles.toggle.toLowerCase() === AppConstants.Tax_Form_Print.toLowerCase());
      if (toggleIndex > -1) {
        this.isFeatureToggleOn = toggleList[toggleIndex].state;
      }

    }
    interface RequestType {
      FormType: string;
      insuredId: string;
      personId: string;
      FormId: string;
      Group?: string;
    }
    const request: RequestType = {
      personId: personID,
      FormId: formId,
      FormType: formType,
      insuredId: insuredID
    };
    if (this.isFeatureToggleOn) {
      request['Group'] = Group;
    }
    return this.httpClient.post(env.thaaWKABPortalService.getTaxFormPDFURL, JSON.stringify(request), {
      headers: new HttpHeaders()
        .set(AppLabelConstants.CONTENT_TYPE_KEY, AppLabelConstants.APPLICATION_KEY)
        .append(AppLabelConstants.TAX_TOKEN_HEADER, (this.taxToken ? this.taxToken : ''))

    }).pipe(map(
      (response: Response) => {
        if (!this.errorHandler.isServiceResponseHasError(response)) {
          return response;
        } else {
          throw response;
        }
      }
    ), catchError((error: any) => this.errorHandler.handleError(error)), );

  }

  getTaxFormPhoneNumber(personId): Observable<any> {
    return this.httpClient.get<HttpResponse<any>>(env.thaaWKABPortalService.getTaxFormPhoneNumber + '&personId=' + personId,
      {
        headers: new HttpHeaders()
          .set(AppLabelConstants.CONTENT_TYPE_KEY, AppLabelConstants.APPLICATION_KEY),
        observe: 'response'
      }).pipe(
      map(
        (response: HttpResponse<any>) => {
          if (!this.errorHandler.isServiceResponseHasError(response)) {
            return response.body;
          } else {
            throw response;
          }
        }
      ), catchError((error: any) => this.errorHandler.handleError(error)), );

  }

  public b64toBlob(b64Data, contentType, sliceSize) {
    contentType = contentType || '';
    sliceSize = sliceSize || 512;

    const byteCharacters = atob(b64Data);
    const  byteArrays = [];

    for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
      const slice = byteCharacters.slice(offset, offset + sliceSize);

      const byteNumbers = new Array(slice.length);
      for (let i = 0; i < slice.length; i++) {
        byteNumbers[i] = slice.charCodeAt(i);
      }

      const byteArray = new Uint8Array(byteNumbers);

      byteArrays.push(byteArray);
    }

    const blob = new Blob(byteArrays, {type: contentType});
    return blob;
  }

  private handleError(error: Response) {
    return observableThrowError(error);
  }
}
