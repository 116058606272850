import {AfterViewInit, Component, EventEmitter, Input, OnChanges, OnInit, Output} from '@angular/core';
import {ControlValueAccessor, FormControl} from '@angular/forms';

@Component({
  selector: 'element-control',
  templateUrl: 'element-control.component.html'
})
export class ElementControlComponent implements OnInit, ControlValueAccessor, AfterViewInit, OnChanges {

  /* Parameters */
  @Input() controls: FormControl = new FormControl();
  @Input() errorCondition: boolean;
  @Output() blurCondition = new EventEmitter<boolean>();
  @Output() focusCondition = new EventEmitter<boolean>();
  @Output() changeCondition = new EventEmitter<boolean>();
  public focused;
  private innerValue: any = '';
  private errors;
  private blurred;

  constructor() {}

  ngOnInit() {}
  ngAfterViewInit() {}
  ngOnChanges() {}


  onError(errorList: object[]) {
    this.errors = errorList;
  }

  onFocus(focused?: boolean) {
    this.focused = focused;
    this.focusCondition.emit(true);
  }

  hasChanged(changed?: boolean) {
    this.changeCondition.emit(true);
  }

  onBlur(blurred?: boolean) {
    this.blurred = true;
    this.blurCondition.emit(true);
  }

  //propagate changes into the custom form control
  propagateChange = (_: any) => { }

  //From ControlValueAccessor interface
  writeValue(value: any) {
    this.innerValue = value;
  }

  //From ControlValueAccessor interface
  registerOnChange(fn: any) {
    this.propagateChange = fn;
  }
  //From ControlValueAccessor interface
  registerOnTouched(fn: any) {

  }

}
