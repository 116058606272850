import {GbBase} from './gb-base';

export class GbPha extends GbBase {

  getUrls() {
    return {
      phaOverviewURL: this.getBaseUrl() + 'gb-pha-service/pha/v1/coverage-overview?' + this.getClientId('auth'),
      phaDetailsURL : this.getBaseUrl() + 'gb-pha-service/pha/v1/coverage-details?' + this.getClientId('auth'),
    }
  }
}

