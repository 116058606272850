import {AfterViewInit, Component, OnInit} from '@angular/core';
import {Router} from '@angular/router';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {StorageManagementService} from '../../../../shared/services/storage-management.service';
import {ModalService} from '../../../../shared/services/modal.service';
import {SelfLinkingService} from '../../../../shared/services/self-linking.service';
import {ContentService} from '../../../../shared/services/content.service';
import {LeaveDynatraceAction} from '../../../../shared/utils/leaveDynatraceAction';
import {CustomEventService} from '../../../../shared/services/tealium/custom-event.service';
import {ErrorMessagingService} from '../../../../shared/services/error-messaging.service';
import {PersonService} from '../../../../shared/services/people.service';
import {User} from '../../../../shared/models/user.model';
import {AuthStateService} from '../../../../shared/services/auth-state.service';
import {AppConstants} from '../../../../shared/constants/app.constants';
import {AppLabelConstants} from '../../../../shared/constants/appLabel.constants';
import {SessionConstants} from '../../../../shared/constants/session.constants';

@Component({
  selector: 'app-confirm-elg',
  templateUrl: './confirm-elg.component.html',
  styleUrls: ['./confirm-elg.component.scss']
})
export class ConfirmELGComponent extends LeaveDynatraceAction implements OnInit, AfterViewInit {
  public appConstant = AppConstants;
  public appLabelConstant = AppLabelConstants;
  public pagetitle: string = 'Insured';
  DCSEligibilityForm: FormGroup;
  public lblID: string = 'Please enter your Insured ID';
  private count = 0;
  private eligibilityId: number;
  public textInfo; //='We need to clear some things up. It seems like we hae multiple records for you in our system.';
  public path: string;
  private partyRefId = '';
  private JsonBOM;
  private srcPartyId = '';
  private pulseID: string;
  private formEligibilityID;
  private pageId;
  private pageId1;
  private claimEventId;
  private owcsContent;
  public Popup_Title = '';
  private profileId: string;
  public user: User;

  constructor(
              private router: Router,
              private modalService: ModalService,
              private formBuilder: FormBuilder,
              private storageManagementService: StorageManagementService,
              private selfLinkingService: SelfLinkingService,
              private contentService: ContentService,
              private customEventService: CustomEventService,
              private authStateService: AuthStateService,
              private errorList: ErrorMessagingService,
              private personService: PersonService) {
    super(storageManagementService);
  }


  ngOnInit() {


    //OWCS Integration
    this.pageId = this.contentService.getPageId(AppConstants.CONFIRM_INSURED_PAGE_ID);
    if (this.pageId) {
      this.populateContent(this.pageId);
    } else {
      this.contentService.getSiteMap().subscribe(
        val => {
          if (val) {
            const siteMapResponse = val;
            this.storageManagementService.setSessionData(SessionConstants.SITE_MAP_DATA, siteMapResponse);
            this.pageId1 = this.contentService.getPageId(AppConstants.CONFIRM_INSURED_PAGE_ID);
            this.populateContent(this.pageId1);
          }
        }, (error) => {
        });
    }
    this.JsonBOM = JSON.parse(this.storageManagementService.retrieveSessionObject(SessionConstants.APP_DATA)).appData.userData;
    this.path = this.storageManagementService.getSessionData(SessionConstants.SELF_LINKING_PATH);
    this.partyRefId = this.JsonBOM.partyRefId;
    this.profileId = this.JsonBOM.profileId;
    this.pulseID = this.storageManagementService.getSessionData(SessionConstants.PULSE_ID);
    this.eligibilityId = this.storageManagementService.getSessionData(SessionConstants.ELIGIBILITY_ID);

    if (this.eligibilityId) {
      this.pagetitle = AppLabelConstants.RECORD_TITLE;
      this.lblID = AppLabelConstants.RECORD_TITLE_ID;
      if (this.JsonBOM && this.JsonBOM.sourcePartyDetailsList) {
        for (let i = 0; i < this.JsonBOM.sourcePartyDetailsList.length; i++) {
          if ( this.JsonBOM.sourcePartyDetailsList[i].srcSystemCd === AppConstants.SRC_SYS_CD_2) {
            this.srcPartyId = this.JsonBOM.sourcePartyDetailsList[i].partyId;
          }
        }
      }
    }
    if (this.pulseID) {
      this.pagetitle = AppLabelConstants.UNDERWRITING_TITLE;
      this.lblID = AppLabelConstants.UNDERWRITING_TITLE_ID;
      if (this.JsonBOM && this.JsonBOM.sourcePartyDetailsList) {
        for (let i = 0; i < this.JsonBOM.sourcePartyDetailsList.length; i++) {
          if ( this.JsonBOM.sourcePartyDetailsList[i].srcSystemCd === AppConstants.SRC_SYS_CD_3) {
            this.srcPartyId = this.JsonBOM.sourcePartyDetailsList[i].partyId;
          }
        }
      }
    }
    this.DCSEligibilityForm = this.formBuilder.group(
      {
        eligibilityId: ['', [Validators.required]]
      });
  }
  ngAfterViewInit() {
    super.ngAfterViewInit();
  }
  onLink() {
    this.modalService.openModal(AppLabelConstants.SELF_LINKING_MODAL);
  }

  validateEligibilityId(): boolean {
    this.formEligibilityID = this.DCSEligibilityForm.get(AppConstants.ELIGIBILITY_ID);
    if (this.formEligibilityID.value !== null && this.formEligibilityID.value !== undefined) {
      if (this.eligibilityId) {
        if (this.formEligibilityID.value !== this.eligibilityId) {
          this.count = this.count + 1;
          if (this.count > 0 && this.count < 3) {
            this.DCSEligibilityForm.controls[AppConstants.ELIGIBILITY_ID].setErrors({incorrectInsuredId: true});
            this.storageManagementService.setSessionData(SessionConstants.SELF_LINKING_PATH, AppLabelConstants.CONFIRM_ELG_URL);
            return false;

          } else if (this.count >= 3 ) {
            this.DCSEligibilityForm.controls[AppConstants.ELIGIBILITY_ID].setErrors({incorrectInsuredId: true});
            this.router.navigate([AppLabelConstants.SELF_LINKING_FAILED_URL]);
            this.storageManagementService.setSessionData(SessionConstants.SELF_LINKING_PATH, AppLabelConstants.CONFIRM_ELG_URL);
            return false;

          } else {
            this.DCSEligibilityForm.controls[AppConstants.ELIGIBILITY_ID].setErrors({incorrectInsuredId: true});
            this.storageManagementService.setSessionData(SessionConstants.SELF_LINKING_PATH, AppLabelConstants.CONFIRM_ELG_URL);
            return false;
          }
        } else if (this.formEligibilityID.value === this.eligibilityId) {
          this.storageManagementService.setSessionData(SessionConstants.SELF_LINKING_PATH, AppLabelConstants.CONFIRM_ELG_URL);

          return true;
        }
      } else if (this.pulseID) {
        if (this.formEligibilityID.value !== this.pulseID) {
          this.count = this.count + 1;
          if (this.count > 0 && this.count < 3) {
            this.DCSEligibilityForm.controls[AppConstants.ELIGIBILITY_ID].setErrors({incorrectInsuredId: true});
            this.storageManagementService.setSessionData(SessionConstants.SELF_LINKING_PATH, AppLabelConstants.CONFIRM_ELG_URL);
            return false;

          } else if (this.count >= 3 ) {
            this.DCSEligibilityForm.controls[AppConstants.ELIGIBILITY_ID].setErrors({incorrectInsuredId: true});
            this.router.navigate([AppLabelConstants.SELF_LINKING_FAILED_URL]);
            this.storageManagementService.setSessionData(SessionConstants.SELF_LINKING_PATH, AppLabelConstants.CONFIRM_ELG_URL);
            return false;

          } else {
            this.DCSEligibilityForm.controls[AppConstants.ELIGIBILITY_ID].setErrors({incorrectInsuredId: true});
            this.storageManagementService.setSessionData(SessionConstants.SELF_LINKING_PATH, AppLabelConstants.CONFIRM_ELG_URL);
            return false;
          }
        } else if (this.formEligibilityID.value === this.pulseID) {
          this.storageManagementService.setSessionData(SessionConstants.SELF_LINKING_PATH, AppLabelConstants.PHA_OVERVIEW_URL);

          return true;
        }
      }


    }
  }

  onSubmit() {
    if (this.validateEligibilityId()) {
      this.selfLinkingService.getLinkType(this.partyRefId, this.srcPartyId, '1', 'Converting Weak to Strong', this.profileId)
        .subscribe(
          val => {
          if (val) {
            if (val.spErrorCode === 0 || val.spErrorCode === '0') {
              this.authStateService.forceAuthorizationTokenReset().subscribe(
                auth => {
                  if (auth) {
                    this.personService.getUserDetailsMudi(this.partyRefId).subscribe(
                      data => {
                        if (data) {
                          this.user = data;
                          this.user.partyRefId = this.partyRefId;
                          this.storageManagementService.setAppData(SessionConstants.USER_DATA, this.user);
                          this.navToreed();

                          if (this.eligibilityId) {
                            this.storageManagementService.removeSessionObject(SessionConstants.ELIGIBILITY_ID);
                            this.router.navigate([AppLabelConstants.URL_HOME]);

                          } else if (this.pulseID) {
                            this.storageManagementService.removeSessionObject(SessionConstants.PULSE_ID);
                            this.router.navigate([AppLabelConstants.URL_PHA_OVERVIEW]);
                          }
                        }
                      }
                    );
                  }
                }
              );
            }
          }
        },
        (error) => {
          console.log('Error' + error);
        }
      );
    }

  }

  cancelClick() {
    this.router.navigate([this.path]);
  }

  populateContent(pageId) {
    this.contentService.getOWCSResponse(pageId, AppConstants.CONFIRM_INSURED_OWCS).subscribe(
      OWCSResponse => {
        if (OWCSResponse) {
          this.owcsContent = OWCSResponse;
          if (this.owcsContent.data.Confirm_Insured_ID_02_Body) {
            this.textInfo = this.owcsContent.data.Confirm_Insured_ID_02_Body.value;
          }
          if (this.owcsContent.data.Confirm_Insured_ID_09_Body) {
            this.Popup_Title = this.owcsContent.data.Confirm_Insured_ID_09_Body.value;
          }
          /*if(this.owcsContent.data.Confirm_Insured_ID_11_Body){
            this.popup_Body = this.owcsContent.data.Confirm_Insured_ID_11_Body.value;
          }*/
        }
      }, (error) => {
        //console.error(error);
      });
  }

  tagErrors(elementId, labelName, errMsg) {
    if (errMsg) {
      if (Object.keys(errMsg) && this.errorList.myErrors['' + Object.keys(errMsg)]) {
        const errorMsg = this.errorList.myErrors['' + Object.keys(errMsg)];

        if (errorMsg === this.errorList.myErrors.required) {
          this.customEventService.formFieldValidationErrors(['' + elementId], ['' + labelName + errorMsg]);
        } else {
          this.customEventService.formFieldValidationErrors(['' + elementId], ['' + errorMsg]);

        }
      }
    }
  }

  navToreed() {

    this.claimEventId = this.storageManagementService.getSessionData(SessionConstants.CLAIM_EVENT_ID);
    const caseData = this.storageManagementService.getAppData(SessionConstants.CASE_DATA);
    if (this.storageManagementService.getAppData(SessionConstants.LEAVE_SSO_URL)) {
      let leaveList = this.storageManagementService.getAppData(SessionConstants.LEAVE_SSO_URL);
      leaveList = leaveList ? leaveList.filter((res) => (res.claimEventId === this.claimEventId)) : [];
      if (leaveList.length > 0) {
        window.open(leaveList[0].url);
      } else {

       const userData = this.JsonBOM.sourcePartyDetailsList;
       let eliId = -1;
        for (let i = 0; i < userData.length; i++) {
          if (userData[i].srcSystemCd === 2) { //DCS Eligibility
            eliId = i;
          }
        }
       if ( eliId > -1) {

         let leaveList1 = this.storageManagementService.getAppData(SessionConstants.LEAVE_SSO_URL);
         leaveList1 = leaveList1 ? leaveList1.filter((res) => (res.claimEventId === caseData.defaultCaseId)) : [];
         if (leaveList1.length > 0) {
           window.open(leaveList1[0].url);
         }
       }
      }
    }
  }
}
