
import {catchError, map} from 'rxjs/operators';
/**
 * Created by AC12394 on 8/7/2017.
 */

import {Injectable} from '@angular/core';
import {Faq} from '../models/faq.model';
import {env} from '../../../environments/environment-loader';
import {Observable} from 'rxjs';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {ResponseErrorHandlerService} from './response-error-handler.service';
import {AppLabelConstants} from '../constants/appLabel.constants';

@Injectable()
export class FaqService {

  private FAQs: Faq[] = [];
  private prevPage: string;

  constructor(private errorHandler: ResponseErrorHandlerService,
              private httpClient: HttpClient) {
  }


  setPrevPage(prevPage: string) {
    this.prevPage = prevPage;
  }

  getPrevPage() {
    return this.prevPage;
  }

  setFAQs(faqs: Faq[]) {
    this.FAQs = faqs;
  }

  getFAQs() {
    return this.FAQs;
  }

  //OWCS Integration

  getSiteMap(): Observable<any> {
    return this.httpClient.get(env.gbContent.siteMapUrl,
      {
        headers: new HttpHeaders()
          .set(AppLabelConstants.CONTENT_TYPE_KEY, AppLabelConstants.APPLICATION_KEY)
      }).pipe(
      map(
      (response: Response) => {
        if (!this.errorHandler.isServiceResponseHasError(response)) {
          return response;
        } else {
          throw response;
        }
      }
    ), catchError((error: any) => this.errorHandler.handleError(error)), );

  }
}
