/**
 * Created by Chaitanya on 8/17/2017.
 */

export const ClaimHandlerMockContent = {
  errorList: null,
  userId: '123456',
  sessionIdentifier: '1572ca48-63fd-4975-8622-72fdb34e3254',
  firstName: 'ALEXUS',
  lastName: 'BRAMWELL',
  phoneExtension: '2305909',
  officeId: '733'

}


