import {CUSTOM_ELEMENTS_SCHEMA, NgModule} from '@angular/core';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {CommonModule} from '@angular/common';
import {SharedModule} from '../../shared/shared.module';
import {RouterModule} from '@angular/router';
import {AppRoutingMaintenanceModule} from './maintenance-routing.module';
import {CompanionComponent} from './companion/companion.component';
import {SampleFormComponent} from './sample-form/sample-form.component';
import {HTTP_INTERCEPTORS} from '@angular/common/http';
import {AppModule} from '../../app.module';
import {AppComponent} from '../../app.component';
import {MaintenanceServiceInterceptor} from './mocking/MaintenanceServiceInterceptor';
import {FakeService} from './mocking/fake.service';
import {MockLandingComponent} from './mock-landing/mockLanding.component';
import {AuthStateDevService} from './services/auth-state-dev.service';
import {MyDatePickerModule} from 'mydatepicker';


@NgModule({
  imports: [
    CommonModule,
    AppRoutingMaintenanceModule,
    AppModule,
    FormsModule,
    SharedModule,
    ReactiveFormsModule,
    MyDatePickerModule,

    RouterModule
  ],
  declarations: [
    CompanionComponent,
    SampleFormComponent,
    MockLandingComponent
  ],
  providers: [
    FakeService,
    AuthStateDevService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: MaintenanceServiceInterceptor,
      multi: true,
    }
  ],
  bootstrap: [AppComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})

export class MaintanenceModule {}
