
import {catchError, map} from 'rxjs/operators';
import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {ResponseErrorHandlerService} from './response-error-handler.service';
import {env} from '../../../environments/environment-loader';
import {Observable} from 'rxjs';
import {AppLabelConstants} from '../constants/appLabel.constants';

@Injectable()
export class CIIFeatureService {
  constructor(private httpClient: HttpClient,
              private errorHandler: ResponseErrorHandlerService) {
  }
  featureServiceCall(personId): Observable<any>  {
    const header = {
      headers: new HttpHeaders()
        .set('Content-Type', 'application/json').set(AppLabelConstants.CONTENT_TYPE_KEY, AppLabelConstants.APPLICATION_KEY)
    };

    return this.httpClient.get(env.gbLeaves.getCIIFeatureFlags + '&personid=' + personId , header ).pipe(map(
      (response) => {
        if (!this.errorHandler.isServiceResponseHasError(response)) {
          return response;
        } else {
          throw response;
        }
      }
    ), catchError((error: any) => this.errorHandler.handleError(error)), );
  }
}
