/**
 * Created by AC12256 on 2/12/2018.
 */
export const MockPhysician = [
  {
    physicianName: 'ABC',
    fromDataRange: '12/12/2019',
    toDataRange: '12/22/2019'
  },
  {
    physicianName: 'XYZ',
    fromDataRange: '12/12/2019',
    toDataRange: null
  }
];
