import {Component, Input, OnInit} from '@angular/core';
import {ProductService} from '../../shared/services/product.service';
import {BehaviorSubject} from 'rxjs';
import {env} from '../../../environments/environment-loader';
import {AppConstants} from '../../shared/constants/app.constants';
import {ContentService} from '../../shared/services/content.service';


@Component({
  selector: 'app-product-list-pre-login',
  templateUrl: './product-list-pre-login.component.html',
  styleUrls: ['./product-list-pre-login.component.scss']
})
export class ProductListPreLoginComponent implements OnInit {
  public productListPreLogin;
  private perRow: number;
  private _data = new BehaviorSubject({});
  private ciamLoginLocation = '';
  private  ciamRegisterLocation = '';
  public pageIdPreAuth;
  @Input()
  set data(value) {
    this._data.next(value);
  }
  get data() {
    return this._data.getValue();
  }

  constructor(private productService: ProductService,
              private contentService: ContentService) { }

  ngOnInit() {
    this.pageIdPreAuth = this.contentService.getPageId('PreAuthenticated Product Page');
    if (this.data !== undefined && this.data !== null) {
      this._data
        .subscribe(x => {
          // Set the Product description for all applicable product
          this.populateProductDesc(this.data);
        });
    } else {
      if (this.pageIdPreAuth) {
        this.contentService.getOWCSResponse(this.pageIdPreAuth, 'PreAuthenticated_Product_Page').subscribe(
          val => {
            if (val) {
              this.populateProductDesc(val);
            }
          }, (error) => {
          });
      }
    }
    this.ciamLoginLocation = env.gbCiamCommonUtil.CIAMLoginURL;
    this.ciamRegisterLocation = env.gbCiamCommonUtil.CIAMRegisterURL;
    this.productListPreLogin = this.productService.getPreLogInProduct();
    if (this.productListPreLogin) {
      this.productListPreLogin = this.productListPreLogin.filter((data) => data.prdKey !== 'Prd_10');
    }
    if (this.productListPreLogin && this.productListPreLogin.length > 6) {
      this.determineRows(this.productListPreLogin.length, 1);
    }
    if (this.productListPreLogin && this.productListPreLogin.length > 0) {
      this.productService.isProductExist.next(true);
    } else {
      this.productService.isProductExist.next(false);
    }
  }

  populateProductDesc(data) {
    try {
      if (data && data.data) {
        if (data.data.Pre_auth_05_Body) {
          this.productListPreLogin[0].description = data.data.Pre_auth_05_Body.value;
        }
        if (data.data.Pre_auth_08_Body) {
          this.productListPreLogin[1].description = data.data.Pre_auth_08_Body.value;
        }
        if (data.data.Pre_auth_11_Body) {
          this.productListPreLogin[2].description = data.data.Pre_auth_11_Body.value;
        }
        if (data.data.Pre_auth_14_Body) {
          //Leave of Absence

          this.productListPreLogin[3].description = data.data.Pre_auth_14_Body.value;
          this.productListPreLogin[3].description = this.productListPreLogin[3].description.replace(AppConstants.CIAM_LOGIN, this.ciamLoginLocation);
          this.productListPreLogin[3].description = this.productListPreLogin[3].description.replace(AppConstants.CIAM_REGISTER, this.ciamRegisterLocation);
        }
        if (data.data.Pre_auth_17_Body) {
          this.productListPreLogin[4].description = data.data.Pre_auth_17_Body.value;
        }
        if (data.data.Pre_auth_20_Body) {
          this.productListPreLogin[5].description = data.data.Pre_auth_20_Body.value;
        }
        if (data.data.Pre_auth_49_Body) {
          this.productListPreLogin[6].description = data.data.Pre_auth_49_Body.value;
        }
        if (data.data.Pre_auth_52_Body) {
          this.productListPreLogin[7].description = data.data.Pre_auth_52_Body.value;
        }
        if (data.data.Pre_auth_55_Body) {
          this.productListPreLogin[8].description = data.data.Pre_auth_55_Body.value;
        }
        if (data.data.Pre_auth_66_Body) {
          this.productListPreLogin[9].description = data.data.Pre_auth_66_Body.value;
        }
        // disclosure for products
        if (data.data.Pre_auth_41_Body) {
          this.productListPreLogin[0].disclosure = data.data.Pre_auth_41_Body.value;
        }
        if (data.data.Pre_auth_42_Body) {
          this.productListPreLogin[1].disclosure = data.data.Pre_auth_42_Body.value;
        }
        if (data.data.Pre_auth_43_Body) {
          this.productListPreLogin[2].disclosure = data.data.Pre_auth_43_Body.value;
        }
        if (data.data.Pre_auth_44_Body) {
          this.productListPreLogin[3].disclosure = data.data.Pre_auth_44_Body.value;
        }
        if (data.data.Pre_auth_45_Body) {
          this.productListPreLogin[4].disclosure = data.data.Pre_auth_45_Body.value;
        }
        if (data.data.Pre_auth_46_Body) {
          this.productListPreLogin[5].disclosure = data.data.Pre_auth_46_Body.value;
        }
        if (data.data.Pre_auth_56_Body) {
          this.productListPreLogin[6].disclosure = data.data.Pre_auth_56_Body.value;
        }
        if (data.data.Pre_auth_57_Body) {
          this.productListPreLogin[7].disclosure = data.data.Pre_auth_57_Body.value;
        }
        if (data.data.Pre_auth_58_Body) {
          this.productListPreLogin[8].disclosure = data.data.Pre_auth_58_Body.value;
        }
        if (data.data.Pre_auth_67_Body) {
          this.productListPreLogin[9].disclosure = data.data.Pre_auth_67_Body.value;
        }
      }
    } catch (error) {
    }
  }

  determineRows(items: number, setRows: number): void {
    if (Math.ceil(items / setRows) > 6) {
      this.determineRows(items, (setRows + 1));
    } else {
      this.perRow = (Math.ceil(items / setRows));
    }
  }
}
