
import {Observable, of} from 'rxjs';
import {mergeMap, map} from 'rxjs/operators';
import {Injectable} from '@angular/core';
import {PersonService} from './people.service';
import {ActivatedRoute, Router} from '@angular/router';
import {StorageManagementService} from './storage-management.service';
import {User} from '../models/user.model';
import {SelfLinkingService} from './self-linking.service';
import {ssoRegistrationService} from './sso-registration.service';
import {LoginRouting} from '../models/loginRouting.model';
import {BlazeService} from './blazeRule.service';
import {NotificationRegistrationService} from './notification-registration.service';
import {CaseAssoc} from '../models/case.model';
import {SessionConstants} from '../constants/session.constants';
import {AppConstants} from '../constants/app.constants';
import {AppLabelConstants} from '../constants/appLabel.constants';
import {VanityUrlsUtility} from '../utils/vanity-urls.utils';
import {PaymentsHistoryService} from './claim-payment-history.service';
import {ProvisionResponse} from '../models/Provision.model';
import {DepositService} from './enrolldeposit.service';

@Injectable()
export class LogonService {
  public user: User;
  public case: CaseAssoc;
  private sourcePartyDetailsList: any = {};
  private loginRoute: LoginRouting;
  private partyRefId: string;
  private channelId: string;
  private appData: any = {};
  private profileInfo: any = {};
  public isDefaultCaseThaa: boolean = false;
  public defaultLinkTypeCd;
  public defaultSrcSysCd;
  private weakLinkExists: boolean = false;
  private caseResponse: any = {};
  private provisioOptions: ProvisionResponse;

  constructor(private personService: PersonService,
              private storageManagementService: StorageManagementService,
              private notificationRegistrationService: NotificationRegistrationService,
              private selfLinkingService: SelfLinkingService,
              private router: Router,
              private blazeService: BlazeService,
              private ssoRegistration: ssoRegistrationService,
              private activatedRoute: ActivatedRoute,
              private paymentHistoryService: PaymentsHistoryService,
              private depositService: DepositService,
  ) {
  }

  //TODO : Need to remove 2/3 parameter from here added to test CIAM flow
  validateLogin(landingPage: string): Observable<LoginRouting> {
    //reset the login route if set earlier.
    /*
     * Line 38 is required as we are logout from the appication/landing to home 2nd time  this.login route store
     * the previous value so its not navigating to home/required page.
     * */
    this.loginRoute = new LoginRouting(false, null);
    this.partyRefId = this.storageManagementService.getSessionData(SessionConstants.SELECTED_PATRY_REF) ? this.storageManagementService.getSessionData('selectedPrtyRef') : null;
    this.storageManagementService.setAppData(SessionConstants.LOGIN_STATUS, true);
    //this.storageManagementService.setSessionData(SessionConstants.IS_NOTIFICATION_REG, false);
    /**
     * Check where the channelId is being set in the URL
     *
     * @type {any}
     */
      //const channelId = this.activatedRoute.snapshot.queryParams['channelId'];

    const channelId = this.storageManagementService.getSessionData(SessionConstants.CHANNEL_ID) || this.activatedRoute.snapshot.queryParams['channelId'];
    return this.channelIdRouting(channelId).pipe(
      map(
        data => {
          if (data) {
            this.partyRefId = data;
          }
          //what do here
        },
        (error) => {
          console.error(`channelIdRouting Services Error in loginToApp()`, error);
        }
      ), mergeMap(res => this.setUserDetails(this.partyRefId, landingPage).pipe(map(
        (data: LoginRouting) => data
      ))), );

  }

  channelIdRouting(channelId): Observable<any> {
    if (!channelId ||
      (channelId &&
        (parseInt(channelId, 10) === 3 || parseInt(channelId, 10) === 2 || parseInt(channelId, 10) === 4)
      )
    ) { //No Channel Id
      return this.personService.getUserCaseIdInfo(this.partyRefId).pipe(map(
        res => {
          if (res && res.cases && res.cases.length > 0) {
            this.storageManagementService.setAppData( SessionConstants.CASE_DATA , res);
            if (this.blazeService.getIsJSONBOMInvalidated()) {
              this.blazeService.isCasesDataSetInSession.next(true);
            }
            /* if (res.hasThaa === true) {
              this.loginRoute = new LoginRouting(true, AppLabelConstants.URL_THAA);
            } else */ if (this.hasValidCaseId(res)) {
              if (this.needsCaseIdSetup(res)) {
                this.storageManagementService.setAppData(SessionConstants.FORCED_CASE_SELECT_FLAG, true);
                this.loginRoute = new LoginRouting(true, AppLabelConstants.URL_MYACC_EMPLOYERS);
              } else {
                this.partyRefId = res.defaultPartyRefId;

                if (VanityUrlsUtility.isVanityUrlNecessary(res.defaultCaseId)) {
                  this.vanityUrlCheck(res.defaultCaseId);
                }
              }
            } else {
              this.loginRoute = new LoginRouting(true, AppLabelConstants.URL_LOGIN_ERROR);
            }
          } else {  //cases array would be empty when user has single THAA case which has future effective date
            this.loginRoute = new LoginRouting(true, AppLabelConstants.URL_CALL_US);
          }
        }, err => {
          console.error(`getCaseIdInfo Services Error in loginToApp()`, err);
        }));
    } else if ( (parseInt(channelId, 10) === 1) ) { //Channel id = 1
      /* we need to remember channel id =1 for the whole session */
      this.storageManagementService.setSessionData(SessionConstants.CHANNEL_ID, 1);
      return this.personService.getUserCaseIdInfo(this.partyRefId).pipe(map (  res => {
          if (res && res.cases && res.cases.length > 0) {
            this.caseResponse = res;
          }
        },
        err => {
          console.error(`getCaseIdInfo Services Error in loginToApp()`, err);
        }), mergeMap( resp => this.depositService.getPolicyAdminProvisions(this.caseResponse.defaultCaseId, 942)), map(
             (policyAdminProvisionsOptionResponse: ProvisionResponse) => {
              this.provisioOptions = policyAdminProvisionsOptionResponse;
              if (this.provisioOptions.provisionResponseList[0].provisionId === SessionConstants.PROVISIONSID && (this.provisioOptions.provisionResponseList[0].provisionOptionId !== SessionConstants.PROVISIONSOPTIONSIDONE)) {
                this.storageManagementService.setSessionData(SessionConstants.SSO_PARTY_REF_ID, this.caseResponse.defaultPartyRefId);
                this.storageManagementService.setAppData(SessionConstants.CASE_DATA, this.caseResponse);
                const newRegInd = this.storageManagementService.getSessionData(SessionConstants.SSO_NEW_REG_IND);
                if (newRegInd && newRegInd === AppConstants.Y) {
                  this.loginRoute = new LoginRouting(true, AppLabelConstants.URL_EMPLOYEE_BNR);
                  this.router.navigate([AppLabelConstants.URL_EMPLOYEE_BNR]);
                } else {
                  this.partyRefId = this.caseResponse.defaultPartyRefId;
                }
              }
             },
            (error) => {
              console.log(' error in option api call ' + error);
            }), );
    }
  }
  getExistingForgeRockToken(cookie, key) {
    let bodyFrToken; let frToken;
    if (cookie) {
      bodyFrToken = cookie.split(';').find(x => x.includes(key));
      if (bodyFrToken) {
        frToken = bodyFrToken.split('=')[1];
      }
    }
    return frToken;
  }
  getExistingForgeRockTokens(cookie, key) {
    let bodyFrToken; let frToken;
    if (cookie) {
      bodyFrToken = cookie.split(';').find(x => x.includes(key));
      if (bodyFrToken) {
        frToken = bodyFrToken.split('=')[1].split('"')[1];
      }
    }
    return frToken;
  }

  setUserDetails(prtyRef: string, landingPage: string): Observable<LoginRouting> {
    if (this.loginRoute && this.loginRoute.reRouting() === true) {
      return of(this.loginRoute);

    }

    return this.personService.getUserDetailsMudi(prtyRef).pipe(map(
      data => {
        const provisioOptions = this.storageManagementService.getAppData(SessionConstants.PROVISIONSOPTIONS); ;
       if ( provisioOptions && provisioOptions.provisionResponseList[0].provisionId && provisioOptions.provisionResponseList[0].provisionOptionId ) {
         if ((provisioOptions.provisionResponseList[0].provisionId === SessionConstants.PROVISIONSID && provisioOptions.provisionResponseList[0].provisionOptionId === SessionConstants.PROVISIONSOPTIONSID)) {
          this.storageManagementService.setDefaultCaseDataForSSO(data.caseId, prtyRef);
    }
}

        this.user = data;
        const personIdsInMudi: string[] = [];
        data.sourcePartyDetailsList.forEach((userId)=>{
          if(userId.srcSystemCd === 8) {
            personIdsInMudi.push(userId.srcSysId);
          }
        })
        this.storageManagementService.setAppData(SessionConstants.PERSON_IDS_IN_MUDI, personIdsInMudi);
        this.user.partyRefId = prtyRef;
        this.getCaseIds();

        if (this.user.sourcePartyDetailsList) {
          this.sourcePartyDetailsList = this.user.sourcePartyDetailsList
            .filter((res) => (res.srcSystemCd === AppConstants.SRC_SYS_CD_1 || res.srcSystemCd === AppConstants.SRC_SYS_CD_2 || res.srcSystemCd === AppConstants.SRC_SYS_CD_8)); //DCS claims
        }

        /* =================================================================================
         =================================================================================
         =================================================================================
         THIS WHOLE LOOP HAS TO BE REDONE AFTER DISCUSSION WITH BUSINESS
         =================================================================================
         =================================================================================
         =================================================================================*/
        this.weakLinkExists = false;
        for (let i = 0; i < this.sourcePartyDetailsList.length; i++) {
          /* Self Linking
           *
           * Currently this is where the self linking check will go.
           * It is unknown if this will get moved elsewhere.
           *
           * */
          if (this.sourcePartyDetailsList[i].linkTypeCd === AppConstants.LINK_TYPE_CD_2 && this.sourcePartyDetailsList[i].srcSystemCd === AppConstants.SRC_SYS_CD_1) { //weak DCS
            this.user.srcSysId = this.user.insuredId;
            this.weakLinkExists = true;
          } else if (this.sourcePartyDetailsList[i].linkTypeCd === AppConstants.LINK_TYPE_CD_1 && this.sourcePartyDetailsList[i].srcSystemCd === AppConstants.SRC_SYS_CD_1) {
            this.user.insuredId = this.sourcePartyDetailsList[i].srcSysId;
            this.user.srcSysId = this.user.insuredId;
          } else if (this.sourcePartyDetailsList[i].srcSystemCd === AppConstants.SRC_SYS_CD_2 && this.sourcePartyDetailsList[i].linkTypeCd === AppConstants.LINK_TYPE_CD_1)  {
            this.user.eligibilityId = this.sourcePartyDetailsList[i].srcSysId;
          } else if (this.sourcePartyDetailsList[i].linkTypeCd === AppConstants.LINK_TYPE_CD_2 && this.sourcePartyDetailsList[i].srcSystemCd === AppConstants.SRC_SYS_CD_8) { //weak DCS
            this.user.srcSysId = this.sourcePartyDetailsList[i].srcSysId;
            this.weakLinkExists = true;
          }

        }
        if (this.weakLinkExists) { //weak THAA or DCS or both
          this.loginRoute = new LoginRouting(true, AppLabelConstants.SELF_LINKING_URL);
        }
      }, (error) => of(new LoginRouting(true, AppLabelConstants.URL_LOGIN))
    ), mergeMap(res => {
      if (this.sourcePartyDetailsList[0] && this.sourcePartyDetailsList[0].srcSystemCd !== AppConstants.SRC_SYS_CD_8 && this.sourcePartyDetailsList[0].activeCase) {
        return this.personService.getUserProfile('4', (
          (this.user.insuredId && this.user.insuredId.length > 0) ? this.user.insuredId : this.user.eligibilityId),
        this.user.caseId).pipe(map(data => {
          if (data && !(this.storageManagementService.getAppData(SessionConstants.CASE_DATA) && this.storageManagementService.getAppData(SessionConstants.CASE_DATA).ssoOnlyCaseIndicator === AppConstants.Y)) {
            this.storageManagementService.setAppData(SessionConstants.PROFILE_DETAILS, data);
            this.profileInfo = data;
            for (let i = 0; i < this.sourcePartyDetailsList.length; i++) {
              if (this.sourcePartyDetailsList[i].linkTypeCd === AppConstants.LINK_TYPE_CD_2 && (this.sourcePartyDetailsList[i].srcSystemCd === AppConstants.SRC_SYS_CD_1)) { //weak DCS
                this.loginRoute = new LoginRouting(true, AppLabelConstants.SELF_LINKING_URL);
                this.storageManagementService.setAppData(SessionConstants.USER_DATA, this.user);
                return this.loginRoute;
              }
            }
            const channelId = this.storageManagementService.getSessionData(SessionConstants.CHANNEL_ID) || this.activatedRoute.snapshot.queryParams['channelId'];

            if ((((this.user.notePrefSetInd === AppConstants.NOTE_PREF_SET_F || this.user.notePrefSetInd === null)) ||
              (parseInt(this.activatedRoute.snapshot.queryParams['channelId'], 10) === 2)) &&
              (!this.storageManagementService.getSessionData(SessionConstants.IS_NOTIFICATION_REG)) &&
              (!this.notificationRegistrationService.getIsNotificationInterceptorSkipped())) {
              this.user.srcSysId = this.user.insuredId;
              this.storageManagementService.setAppData(SessionConstants.USER_DATA, this.user);
              /** commenting line 255 as per requirement in US156463 **/
              // this.loginRoute = new LoginRouting(true, AppLabelConstants.URL_NOTIFICATION_REG);
              return this.loginRoute;
            }

            this.user.srcSysId = this.user.insuredId;
            this.storageManagementService.setAppData(SessionConstants.USER_DATA, this.user);
            this.loginRoute = new LoginRouting(false, null);
            this.setLeaveSSOURL();
            return this.loginRoute;
          } else {
            this.storageManagementService.setAppData(SessionConstants.USER_DATA, this.user);
          }
        },
        err => {
        }
      ))
      } else {
      if (this.sourcePartyDetailsList[0] && this.sourcePartyDetailsList[0].linkTypeCd === AppConstants.LINK_TYPE_CD_2  && this.sourcePartyDetailsList[0].srcSystemCd === AppConstants.SRC_SYS_CD_8) { //weak THAA
        this.storageManagementService.setAppData(SessionConstants.USER_DATA, this.user);
        return of(new LoginRouting(true, AppLabelConstants.SELF_LINKING_URL))
      } else if (this.user.caseId && this.user.personIds[0]) {
        return this.notificationRegistrationService.isThaaNotification(this.user.caseId, this.user.personIds[0]).pipe(
          map(response => {
            this.storageManagementService.setAppData(SessionConstants.USER_DATA, this.user);
            if (response) {
             /* for (let i = 0; i < this.sourcePartyDetailsList.length; i++) {
                if (this.sourcePartyDetailsList[i].linkTypeCd === AppConstants.LINK_TYPE_CD_2  && this.sourcePartyDetailsList[i].srcSystemCd === AppConstants.SRC_SYS_CD_8) { //weak THAA
                  this.loginRoute = new LoginRouting(true, AppLabelConstants.SELF_LINKING_URL);
                  this.storageManagementService.setAppData(SessionConstants.USER_DATA, this.user);
                  return this.loginRoute;
                }
              }*/
              this.storageManagementService.setSessionData(SessionConstants.THAA_PREFERENCE, response);
              /** commenting lines 288-291 as per requirement in US156463 **/
              // if (this.isNotificationThaa(response)) {
              //  this.loginRoute = new LoginRouting(true, AppLabelConstants.URL_NOTIFICATION_REG);
              // }
              return this.loginRoute;
            }
          }, () => of(new LoginRouting(true, AppLabelConstants.URL_LOGIN))))
      }
    }
      this.storageManagementService.setAppData(SessionConstants.USER_DATA, this.user);
      return of(this.loginRoute);
    }), )
  };
    /*.mergeMap(resp => {
        if (resp && this.isDefaultCaseThaa) {
      return this.notificationRegistrationService.isThaaNotification(this.user.caseId, this.user.personIds[0])
        .map(datas => {
          this.storageManagementService.setAppData(SessionConstants.USER_DATA, this.user);
          if (datas) {
            if (this.isNotificationThaa(datas)) {
              this.loginRoute = new LoginRouting(true, AppLabelConstants.URL_NOTIFICATION_REG);
            }
            //this.setLeaveSSOURL();
            return this.loginRoute;
          }
        }, () => {
          return of(new LoginRouting(true, AppLabelConstants.URL_LOGIN));
        })
    } else {
          this.storageManagementService.setAppData(SessionConstants.USER_DATA, this.user);
          //this.loginRoute = new LoginRouting(false, null);
          //this.setLeaveSSOURL();
          return of(this.loginRoute);
        }
      });*/
/*  }*/


  isNotificationThaa(IsThaaNotificationData) {
    if (!IsThaaNotificationData.preferenceDetails.bypassPaperlessClaims && IsThaaNotificationData.preferenceDetails.mbPortal && !IsThaaNotificationData.preferenceDetails.userPreferenceSet) {
      return true;
    } else if (IsThaaNotificationData.preferenceDetails.bypassPaperlessClaims && !IsThaaNotificationData.preferenceDetails.mbPortal && !IsThaaNotificationData.preferenceDetails.userPreferenceSet) {
      return false;
    } else if (!IsThaaNotificationData.preferenceDetails.bypassPaperlessClaims && !IsThaaNotificationData.preferenceDetails.mbPortal && IsThaaNotificationData.preferenceDetails.userPreferenceSet) {
      return false;
    } else if (!IsThaaNotificationData.preferenceDetails.bypassPaperlessClaims && IsThaaNotificationData.preferenceDetails.mbPortal && IsThaaNotificationData.preferenceDetails.userPreferenceSet) {
      return false;
    } else if (!IsThaaNotificationData.preferenceDetails.bypassPaperlessClaims && !IsThaaNotificationData.preferenceDetails.mbPortal && !IsThaaNotificationData.preferenceDetails.userPreferenceSet) {
      return true;
    }
    return false;
  }

  setLeaveSSOURL() {
    const caseData = this.storageManagementService.getAppData(SessionConstants.CASE_DATA);
    if ( caseData && caseData.defaultCaseId ) {
      this.selfLinkingService.getLeaveSSO(caseData.defaultCaseId).subscribe(
        (res: any ) => {
          this.storageManagementService.setLeaveSSOURL(res.url , caseData.defaultCaseId );
        }
      );
    }
  }

  hasValidCaseId(caseData) {
    return (caseData && Object.keys(caseData).length > 0 &&
      caseData.excludedCaseIndicator !== 'Y' && (!caseData.errorList || !(caseData.errorList[0].errorCode))
    );
  }

  needsCaseIdSetup(caseData) {
    return caseData && (caseData.defaultCaseId === null || caseData.newCaseIndicator === AppConstants.Y);
  }


  getCaseIds() {
    let personId;
    const personIds: string[] = [];
    const cases = this.storageManagementService.getAppData(SessionConstants.CASE_DATA);
    const allowThaaUser = (this.storageManagementService.getSessionData(SessionConstants.ALLOW_THAA_USER) === false ? false : true);
    for ( let i = 0; i < cases.cases.length; i++) {
      for (let j = 0; j < cases.cases[i].sourcePartyDetailsList.length; j++) {
        if ((cases.cases[i].sourcePartyDetailsList[j].srcSystemCd === AppConstants.SRC_SYS_CD_8) && (allowThaaUser)) {
          personId = cases.cases[i].sourcePartyDetailsList[j].srcSysId;
          cases.cases[i].defaultCase ?  personIds.unshift(personId) : personIds.push(personId);
          this.isDefaultCaseThaa = true;
        } else  if ( cases.cases[i].sourcePartyDetailsList[j].srcSystemCd === AppConstants.SRC_SYS_CD_1) {
          this.user.insuredId = cases.cases[i].sourcePartyDetailsList[j].srcSysId;
          this.user.srcSysId = this.user.insuredId;
        } else if (cases.cases[i].sourcePartyDetailsList[j].srcSystemCd === AppConstants.SRC_SYS_CD_2) {
          this.user.eligibilityId = cases.cases[i].sourcePartyDetailsList[j].srcSysId;
        }
      }
    }
    this.user.personIds = personIds;
  }

  vanityUrlCheck(caseId) {
    window.open(VanityUrlsUtility.getVanityUrl(caseId, this.router.url), '_self');
  }

}
