
import {catchError, map} from 'rxjs/operators';
import {EventEmitter, Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {ResponseErrorHandlerService} from './response-error-handler.service';
import {env} from '../../../environments/environment-loader';
import {IntermittentAbsenceResponse} from 'app/shared/models/intermittentAbsences.model';
import {IMyCalendarViewChanged, IMyDateModel} from 'mydatepicker';
import {Observable} from 'rxjs';
import {SessionConstants} from '../constants/session.constants';
import {AppLabelConstants} from '../constants/appLabel.constants';


@Injectable()
export class LeaveService {
  isShowAllRequests = new EventEmitter<boolean>();
  monthChanged = new EventEmitter<IMyCalendarViewChanged>();
  dateChanged = new EventEmitter<IMyDateModel>();
  statusUpdated = new EventEmitter();
  constructor(private httpClient: HttpClient,
              private errorHandler: ResponseErrorHandlerService) {
  }
  getLeaveSchedule(leaveId: string, personId: string): Observable<any>  {
    const header = {
      headers: new HttpHeaders()
        .set(AppLabelConstants.CONTENT_TYPE_KEY, AppLabelConstants.APPLICATION_KEY)
    };

    return this.httpClient.get(env.gbLeaves.leaveSchedule + '&personId=' + personId + '&yearspan=1', header).pipe(map(
      (response: IntermittentAbsenceResponse) => {
        if (!this.errorHandler.isServiceResponseHasError(response)) {
          return response.leaveList;
        } else {
          throw response;
        }
      }
    ), catchError((error: any) => this.errorHandler.handleError(error)), );
  }
  getRequestExtensionLeave(formData , leaveType): Observable <any> {
    const headers = new Headers({'Content-Type': 'application/json'});
    const request = formData;
    const getRAE = (leaveType === 'continuous') ? env.gbLeaves.getRAELeaveContinuous : env.gbLeaves.getRAELeaveReducedSchedule;
    return this.httpClient.post(getRAE, request,
      {
        headers: new HttpHeaders()
          .set(AppLabelConstants.CONTENT_TYPE_KEY, AppLabelConstants.APPLICATION_KEY)
      }).pipe(
      map(
        (response: Response) => {
          if (!this.errorHandler.isServiceResponseHasError(response)) {

            return response;
          } else {

            throw response;
          }
        }
      ), catchError((error: any) => this.errorHandler.handleThaaErrorForSubmit(error)), );
    //}
  }


  getLeaveConfirmation(request): Observable<any> {
    return this.httpClient.post(env.gbLeaves.leaveConfirmationApi, request,
      {
        headers: new HttpHeaders()
          .set(AppLabelConstants.CONTENT_TYPE_KEY, AppLabelConstants.APPLICATION_KEY)
      }).pipe(
      map(
        (response: Response) => {
          if (!this.errorHandler.isServiceResponseHasError(response)) {
            return response;
          } else {
            throw response;
          }
        }
      ), catchError((error: any) => this.errorHandler.handleThaaErrorForSubmit(error)), );
  }

  getClaimConfirmation(request): Observable<any> {
    return this.httpClient.post(env.thaaWKABPortalService.thaaConfirmationURL, request,
      {
        headers: new HttpHeaders()
          .set(AppLabelConstants.CONTENT_TYPE_KEY, AppLabelConstants.APPLICATION_KEY)
      }).pipe(
      map(
        (response: Response) => {
          if (!this.errorHandler.isServiceResponseHasError(response)) {
            return response;
          } else {
            throw response;
          }
        }
      ), catchError((error: any) => this.errorHandler.handleThaaErrorForSubmit(error)), );
  }

  getADAClaimActivities(personId: string, leaveId: string): Observable<any> {

    return this.httpClient.get(env.gbLeaves.getADAClaimActivityURL + '&personId=' + personId + '&leaveId=' + leaveId,
      {
        headers: new HttpHeaders()
          .set(AppLabelConstants.CONTENT_TYPE_KEY, AppLabelConstants.APPLICATION_KEY)
      }).pipe(
      map(
        (response: Response) => {
          if (!this.errorHandler.isServiceResponseHasError(response)) {
            return response;
          } else {
            throw response;
          }
        }
      ),   catchError((error: any) => this.errorHandler.handleError(error)), );

  }
  getADAUpdateAccommodationSubmit(request): Observable<any> {
    return this.httpClient.post(env.thaaWKABPortalService.thaaADAUpdateAccommodationSubmitURL, request,
      {
        headers: new HttpHeaders()
          .set(AppLabelConstants.CONTENT_TYPE_KEY, AppLabelConstants.APPLICATION_KEY)
      }).pipe(
      map(
        (response: Response) => {
          if (!this.errorHandler.isServiceResponseHasError(response)) {
            return response;
          } else {
            throw response;
          }
        }
      ), catchError((error: any) => this.errorHandler.handleThaaErrorForSubmit(error)), );
  }
}
