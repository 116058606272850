import { Component, Input, OnInit } from '@angular/core';
import { ClaimServiceService } from '@shared/services/claim-service.service';
import { StorageManagementService } from '@shared/services/storage-management.service';
import { Subject } from 'rxjs';
import { Router } from '@angular/router';
import { takeUntil } from 'rxjs/operators';
import { OverpaymentSessionConstants } from '@app/cxr/overpayment/shared/constants/overpayment-session.constants';
import { OverpaymentRouteConstants } from '@app/cxr/overpayment/shared/constants/overpayment-route.constants';
import { OverpaymentIconConstants } from '@app/cxr/overpayment/shared/constants/overpayment-icon.constants';
import { MessageRibbonService } from '@app/shared/services/message-ribbon.service';
import { OverpaymentLabelsConstants } from '@app/cxr/overpayment/shared/constants/overpayment-labels.constants';
import { CustomEventService } from '@app/shared/services/tealium/custom-event.service';
import { AppConstants } from '@app/shared/constants/app.constants';

@Component({
  selector: 'overpayment-alert-section',
  templateUrl: './overpayment-alert-section.component.html',
  styleUrls: ['./overpayment-alert-section.component.scss']
})
export class OverpaymentAlertSectionComponent implements OnInit {
  @Input() overPaymentMessage: string = '';
  @Input() overPaymentLink: string = '';
  @Input() set showCloseButton(data) {
    this.showCloseBtn = JSON.parse(data);
  }
  appConst = {
    ...OverpaymentSessionConstants,
    ...OverpaymentRouteConstants,
    ...OverpaymentIconConstants,
    ...OverpaymentLabelsConstants
  };
  showCloseBtn: boolean = false;
  personIds: string[] = [];
  _destroy$: Subject<void> = new Subject();
  analyticsPageName: string = '';
  isPaymentDisabled: boolean = false;

  constructor(
    private claimService: ClaimServiceService,
    private storageManagementService: StorageManagementService,
    private messageRibbon: MessageRibbonService,
    private customEventService: CustomEventService,
    private router: Router) { }

  ngOnInit() {
    this.isPaymentDisabled = this.storageManagementService.getSessionData(AppConstants.IS_PAYMENT_DISABLED);
    const appData = this.storageManagementService.retrieveSessionObject(this.appConst.APP_DATA) ?
      JSON.parse(this.storageManagementService.retrieveSessionObject(this.appConst.APP_DATA)).appData : '';
    this.personIds = (appData.userData && appData.userData.personIds && appData.userData.personIds.length > 0 ? appData.userData.personIds : []);
    if (this.router.url === this.appConst.ALL_CLAIM_URL) {
      this.analyticsPageName = this.appConst.OVERPAYMENT_ALLCLAIM_MSG;
    } else {
      this.analyticsPageName  = this.overPaymentMessage.replace( /(<([^>]+)>)/ig, '');
    }
  }

  getOverPayments() {
    this.customEventService.customEvent(this.appConst.PAYMENT_YOU_OWE_LABEL, this.appConst.ANALYTICS_PAGE_TAG, this.appConst.ANALYTICS_LINK_CLICK, this.appConst.PAYMENT_YOU_OWE_LABEL, this.analyticsPageName);
    if (this.personIds && this.personIds.length > 0) {
      this.claimService.getOverpayments(this.personIds).pipe(takeUntil(this._destroy$)).subscribe(res => {
        if (res) {
          if (res.responseCode === '001') {
            this.navigateToOverpayment();
          } else {
            this.messageRibbon.addMessage(this.appConst.ERROR_SM, this.appConst.WARNING_MESSAGE, this.appConst.ERROR_TITLE, this.appConst.TRUE);
          }

        }
      })
    }
  }

  navigateToOverpayment() {
    const currentUrl = this.router.url;
    this.storageManagementService.setSessionData(this.appConst.PREVIOUS_OVERPAYMENT_URL, currentUrl);
    this.router.navigate([this.appConst.PAYMENTS_YOU_OWE]);
  }

  closeAlert() {
    this.customEventService.customEvent(this.appConst.ANALYTICS_MODEL_CLOSE, this.appConst.ANALYTICS_PAGE_TAG, this.appConst.ANALYTICS_LINK_CLICK, this.appConst.ANALYTICS_MODEL_CLOSE, this.analyticsPageName);
    this.storageManagementService.setSessionData(this.appConst.HIDE_OVERPAYMENT_ALERT, 'true');
  }
  getAnalyticsLabel(message) {
  return  message.replace( /(<([^>]+)>)/ig, '');

  }
}

