import {Component, OnInit} from '@angular/core';
import {ModalService} from '../../../../shared/services/modal.service';
import {ContentService} from '../../../../shared/services/content.service';
import {StorageManagementService} from '../../../../shared/services/storage-management.service';
import {AppConstants} from '../../../../shared/constants/app.constants';
import {AppLabelConstants} from '../../../../shared/constants/appLabel.constants';
import {SessionConstants} from '../../../../shared/constants/session.constants';
import { SelfLinkingService } from 'app/shared/services/self-linking.service';

@Component({
  selector: 'app-self-linking-modal',
  templateUrl: './self-linking-modal.component.html',
  styleUrls: ['./self-linking-modal.component.scss']
})
export class SelfLinkingModalComponent implements OnInit {
  public appConstant = AppConstants;
  public appLabelConstant = AppLabelConstants;
  private pageId;
  private owcsContent;
  private pageId1;
  private modalTitle = '';
  public paragraph_1 = '';
  private eligibilityId: number;
  private pulseID: string;
  public JsonBOM;
  public personId = '';
  constructor(private modalService: ModalService,
              private contentService: ContentService,
              private sessionManagementService: StorageManagementService,
              private selfLinkingService: SelfLinkingService) { }

  ngOnInit() {
    //OWCS Integration
    this.pageId = this.contentService.getPageId(AppConstants.CONFIRM_INSURED_PAGE_ID);
    if (this.pageId) {
      this.populateContent(this.pageId);
    } else {
      this.contentService.getSiteMap().subscribe(
        val => {
          if (val) {
            const siteMapResponse = val;
            this.sessionManagementService.setSessionData(SessionConstants.SITE_MAP_DATA, siteMapResponse);
            this.pageId1 = this.contentService.getPageId(AppConstants.CONFIRM_INSURED_PAGE_ID);
            this.populateContent(this.pageId1);
          }
        }, (error) => {
        });
    }

    this.personId = this.selfLinkingService.personId;
  }

  clickClose() {
    this.modalService.closeModal(AppLabelConstants.SELF_LINKING_MODAL);
  }

  populateContent(pageId) {

    this.pulseID = this.sessionManagementService.getSessionData(SessionConstants.PULSE_ID);
    this.eligibilityId = this.sessionManagementService.getSessionData(SessionConstants.ELIGIBILITY_ID);

     if (this.pulseID || this.eligibilityId) {
      this.pageId1 = this.contentService.getPageId(AppConstants.CONFIRM_ELIGIBILITY_PAGE_ID);
      this.contentService.getOWCSResponse(this.pageId1, AppConstants.CONFIRM_INSURED_OWCS).subscribe(
          OWCSResponse => {
          if (OWCSResponse) {
            this.owcsContent = OWCSResponse;
            if (this.owcsContent.data.Confirm_Eligibility_ID_09_Body) {
              this.modalTitle = this.owcsContent.data.Confirm_Eligibility_ID_09_Body.value;
            }
            if (this.owcsContent.data.Confirm_Eligibility_ID_11_Body) {
              this.paragraph_1 = this.owcsContent.data.Confirm_Eligibility_ID_11_Body.value;
              if (this.pulseID) {
                this.paragraph_1 = this.paragraph_1.replace('&lt;ID&gt;', 'underwriting ID');
              }
              if (this.eligibilityId) {
                this.paragraph_1 = this.paragraph_1.replace('&lt;ID&gt;', 'record ID');
              }
            }
          }
        }, (error) => {
          //console.error(error);
        });
    } else {
       this.contentService.getOWCSResponse(pageId, AppConstants.CONFIRM_INSURED_OWCS).subscribe(
           OWCSResponse => {
           if (OWCSResponse) {
             this.owcsContent = OWCSResponse;
             if (this.owcsContent.data.Confirm_Insured_ID_09_Body) {
               this.modalTitle = this.owcsContent.data.Confirm_Insured_ID_09_Body.value;
             }
             if (this.owcsContent.data) {
              this.paragraph_1 = this.personId ?
              (this.owcsContent.data.Confirm_Insured_ID_11A_Body.value ? this.owcsContent.data.Confirm_Insured_ID_11A_Body.value : '') :
              (this.owcsContent.data.Confirm_Insured_ID_11_Body.value ? this.owcsContent.data.Confirm_Insured_ID_11_Body.value : '');
             }
           }
         }, (error) => {
           //console.error(error);
         });
     }

  }
}
