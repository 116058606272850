
import {catchError, map} from 'rxjs/operators';
/**
 * Created by AC12256 on 12/5/2017.
 */
import {Injectable} from '@angular/core';
import {BlazeRule} from '../models/blazeRules.model';

import {ReplaySubject, Observable} from 'rxjs';

import {env} from '../../../environments/environment-loader';


import {StorageManagementService} from './storage-management.service';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {ResponseErrorHandlerService} from './response-error-handler.service';
import {FAQResponse} from '../models/faq.model';
import {RelatedClaims} from '../models/relatedClaims.model';
import {PayeeType} from '../models/Payment.model';
import {AuthStateService} from './auth-state.service';
import {BlazeResClaims} from '../models/blazeResClaims.model';
import {SessionConstants} from '../constants/session.constants';
import {AppConstants} from '../constants/app.constants';


export const blazeResClaims: BlazeResClaims = {
  requestedFaqIds: [
    'QID_80',
    'QID_81',
    'QID_82',
    'QID_83',
    'QID_84',
    'QID_85',
    'QID_86',
    'QID_87',
    'QID_88',
    'QID_89',
    'QID_90',
    'QID_91',
    'QID_92',
    'QID_93',
    'QID_94',
    'QID_95',
    'QID_96',
    'QID_97'
  ]
};

export const BLAZERULE: BlazeRule = {
  blazeRuleRes: blazeResClaims
};

@Injectable()
export class BlazeService {
  private top = -1;
  private curPagesStack: string[] = [];
  private curPage: string;
  private isJSONBOMInvalidated: boolean = false;
  public isCasesDataSetInSession: ReplaySubject<boolean> = new ReplaySubject<boolean>(1);

  constructor(
              private storageService: StorageManagementService,
              private errorHandler: ResponseErrorHandlerService,
              private http: HttpClient,
              private authStateService: AuthStateService
            ) {
  }




  public ifBlazeNeedsToBeCalled(curPage: string): boolean {

    this.curPage = curPage;

    if (this.top === -1) {
      this.top++;
      this.curPagesStack[this.top] = this.curPage;
      return true;
    } else {
      if (this.top === 0) {
        if (this.curPagesStack[this.top] === curPage) {
          return this.getIsJSONBOMInvalidated();
        } else {
          this.curPagesStack[this.top] = curPage;
          return true;
        }
      }
    }
  }

  public setIsJSONBOMInvalidated(isInvalidate: boolean) {
    this.isJSONBOMInvalidated = isInvalidate;
  }

  public getIsJSONBOMInvalidated(): boolean {
    return this.isJSONBOMInvalidated;
  }

  getBlazeDetails(): BlazeRule {
    return BLAZERULE ;
  }





  getFAQBlazeRule(): Observable<FAQResponse> {

      const appData = JSON.parse(this.storageService.retrieveSessionObject(SessionConstants.APP_DATA));
      if (appData && appData.currentPage && appData.currentPage.toLowerCase() === AppConstants.CLAIM_DETAILS_SM ) {
        appData.appData = this.transformJSONBOM(appData.appData);
      }
      if (appData && appData.appData.currentPage && (appData.appData.currentPage.toLowerCase() === AppConstants.LEAVE_PLAN_SM ||
          appData.appData.currentPage.toLowerCase() === AppConstants.LEAVE_DATAILS_SM)) {
           appData.appData = this.setJSONBOMForLeaves(appData.appData);
     }
      const httpOptions = {
        headers: new HttpHeaders({
          'Content-Type': 'application/json'
        })
      };
      let pageId =this.getPageId(AppConstants.FAQ_PAGE_NAME);
      let faqUrl = this.authStateService.getLoginState() ?
        env.gbCxrRules.faqRules_URL + pageId:
        env.gbCxrRules.faqRules_unauth_URL + pageId
      return this.http.post<FAQResponse>(
        (faqUrl),
        JSON.stringify(appData),
        httpOptions).pipe(
        map((blazeResponse: FAQResponse) => {
          if ((blazeResponse && blazeResponse.data.length > 0) && (!this.errorHandler.isServiceResponseHasError(blazeResponse))) {
            return blazeResponse;
          } else {
            throw blazeResponse;
          }
        }),
        catchError((error: any) => this.errorHandler.handleError(error)), );

  }

  getPageId(pageName: string): any {
    if (this.storageService.getSessionData(SessionConstants.SITE_MAP_DATA)) {
      const siteMapResponse = this.storageService.getSessionData(SessionConstants.SITE_MAP_DATA);
      if (siteMapResponse && siteMapResponse.data && siteMapResponse.data.sitemap && siteMapResponse.data.sitemap.length > 0) {
        let siteMapData = siteMapResponse.data.sitemap;
        siteMapData = siteMapData.filter(data => data.pagename === pageName);
        return ( siteMapData[0] ) ? siteMapData[0].id : {};
      }
    }
  }

  transformJSONBOM(appData) {

    const curClaim: RelatedClaims = JSON.parse(this.storageService.retrieveSessionObject(SessionConstants.CUR_CLAIM)).curClaim;
    if (appData && appData.userClaims !== {} && appData.userClaims.claimOverview) {
      const relatedClaims: RelatedClaims[] = appData.userClaims.claimOverview.relatedClaims;
      const nonRelatedClaims: RelatedClaims[] = appData.userClaims.claimOverview.nonRelatedClaims;
      let relatedClaimsIndex = -1;
      let nonRelatedClaimsIndex = -1;

      if (relatedClaims && relatedClaims.length > 0) {
        relatedClaimsIndex = relatedClaims.findIndex((relatedClaimElem: RelatedClaims) => relatedClaimElem.claimEventId === curClaim.claimEventId);
        if (relatedClaimsIndex !== -1) {
          appData.userClaims.claimOverview.relatedClaims = [curClaim];
          appData.userClaims.claimOverview.nonRelatedClaims = [];
        } else {
          appData.userClaims.claimOverview.relatedClaims = [];
          if (nonRelatedClaims && nonRelatedClaims.length > 0) {
            nonRelatedClaimsIndex = nonRelatedClaims.findIndex((nonRelatedClaimElem: RelatedClaims) => nonRelatedClaimElem.claimEventId === curClaim.claimEventId);
            if (nonRelatedClaimsIndex !== -1) {
            appData.userClaims.claimOverview.nonRelatedClaims = [curClaim];
            } else {
              appData.userClaims.claimOverview.nonRelatedClaims = [];
            }
          }
        }
      }
    }
    return appData;
  }
  //to set curLeave in appData to for FAQ service to show common questions on leave plan page
  setJSONBOMForLeaves(appData) {
    const leaveData =  this.storageService.getSessionData(SessionConstants.CURR_LEAVE);
      appData.userClaims.claimOverview.relatedClaims = [];
      appData.userClaims.claimOverview.nonRelatedClaims = [];
      appData.userClaims.claimHistory.disabilityClaims = [];

      let leaveClaims =  appData.userClaims.claimOverview.leaveClaims;
      let leaveClaimIndex = -1;

      if (leaveClaims && leaveClaims.length > 0) {
        leaveClaimIndex = leaveClaims.findIndex((entry) => entry.leaveId === leaveData.leaveId);
        if (leaveClaimIndex !== -1) {
          appData.userClaims.claimOverview.leaveClaims = [leaveData];
          appData.userClaims.claimHistory.leaveClaims = [];
        }
      }
      if (leaveClaimIndex === -1) {
        leaveClaims =  appData.userClaims.claimHistory.leaveClaims;
        leaveClaimIndex = -1;
        if (leaveClaims && leaveClaims.length > 0) {
          leaveClaimIndex = leaveClaims.findIndex((entry) => entry.leaveId === leaveData.leaveId);
          if (leaveClaimIndex !== -1) {
            appData.userClaims.claimOverview.leaveClaims = [];
            appData.userClaims.claimHistory.leaveClaims = [leaveData];
          }
        }
      }
    return appData;
  }

  addPayeeTypeInUserClaims(payeeTypeList: PayeeType[]) {
    const appData = JSON.parse(this.storageService.retrieveSessionObject(SessionConstants.APP_DATA)).appData;
    if (appData && appData.userClaims !== {} && appData.userClaims.claimOverview) {
      const relatedClaims: RelatedClaims[] = appData.userClaims.claimOverview.relatedClaims;
      const nonRelatedClaims: RelatedClaims[] = appData.userClaims.claimOverview.nonRelatedClaims;

      if (relatedClaims && relatedClaims.length > 0) {
        for (let i = 0; i < relatedClaims.length; i++) {
          const claimEventId: string = relatedClaims[i].claimEventId;
          const filteredPayeeType: PayeeType[] = payeeTypeList.filter(
            (payeeType: PayeeType) =>
              payeeType.claimEventId === claimEventId
          );
          if (filteredPayeeType && filteredPayeeType.length === 1) {
            appData.userClaims.claimOverview.relatedClaims[i].payeeType = filteredPayeeType[0].payeeType;
          } else {
            appData.userClaims.claimOverview.relatedClaims[i].payeeType = null;
          }
        }
      }

      if (nonRelatedClaims && nonRelatedClaims.length > 0) {
        for (let i = 0; i < nonRelatedClaims.length; i++) {
          const claimEventId: string = nonRelatedClaims[i].claimEventId;
          const filteredPayeeType: PayeeType[] = payeeTypeList.filter(
            (payeeType: PayeeType) =>
            payeeType.claimEventId === claimEventId
          );
          if (filteredPayeeType && filteredPayeeType.length === 1) {
            appData.userClaims.claimOverview.nonRelatedClaims[i].payeeType = filteredPayeeType[0].payeeType;
          } else {
            appData.userClaims.claimOverview.nonRelatedClaims[i].payeeType = null;
          }
        }
      }
      this.storageService.setSessionObject(SessionConstants.APP_DATA, appData);
    }
  }
}
