
import {Observable, throwError} from 'rxjs';
import {catchError, map} from 'rxjs/operators';
import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {AppLabelConstants} from '@app/shared/constants/appLabel.constants';
import {ResponseErrorHandlerService} from '@shared/services/response-error-handler.service';
import {env} from '@env/environment-loader';
import { IAdditionalEmployment} from '@app/cxr/modules/intake/shared/models/intake-content.model';
import {StorageManagementService} from '@shared/services/storage-management.service';
import {of} from 'rxjs/index';
import {IntakeLabelConstants} from '@app/cxr/modules/intake/shared/constants/intake-label.constants';

@Injectable()
export class IntakeAdditionalEmploymentService {

  constructor(private errorHandler: ResponseErrorHandlerService,
              private httpClient: HttpClient,
              private storageManagementService: StorageManagementService) {
  }

  getAdditionalEmploymentsAPI(personId: string, fdaDate: string): Observable<IAdditionalEmployment> {
        if (personId && fdaDate) {
          return this.httpClient.get(env.thaaWKABPortalService.getAdditionalEmployment + '&personID=' + personId + '&FDA=' + fdaDate,
            {
              headers: new HttpHeaders()
                .set(AppLabelConstants.CONTENT_TYPE_KEY, AppLabelConstants.APPLICATION_KEY)
            }).pipe(
            map(
              (response: IAdditionalEmployment) => {
                if (!this.errorHandler.isServiceResponseHasError(response)) {
                  this.storageManagementService.setIntakeSessionData(IntakeLabelConstants.ADDITIONAL_EMPLOYMENT, response);
                  return response;
                } else {
                  throw response;
                }
              }
            ), catchError((error: any) => this.handleError(error)),
          );
        } else {
          return this.errorHandler.handleError('Employment Details API failed ');
        }

  }
  handleError(error: Response) {
    return throwError(error);
  }
}
