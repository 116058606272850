import {GbBase} from './gb-base';

export class GbSsoRegistration extends GbBase {

  getUrls() {
    return {
      ssoRegistrationURL: this.getBaseUrl() + 'gb-sso-registration-service/registration/v1/ssoRegistration?' + this.getClientId('auth'),
    }
  }
}
