export class INTAKE_ROUTES {
  static INTAKE_HOME = '/home'
  static INTAKE_THINGS_YOU_WILL_NEED = 'intake/intake-things-you-will-need';
  static INTAKE_THINGS_YOU_WILL_NEED_CLEAR = '/intake/intake-things-you-will-need';
  static INTAKE_FLOW_THINGS = '/intake-things-you-will-need';
  static INTAKE_THINGS_YOU_WILL_NEED_BONDING= 'intake/intake-things-you-will-need-bonding';
  static INTAKE_EXISTING_CLAIM= 'intake/existing-claims';

  static INTAKE_FLOW = '/intake/newIntake';
  static CONTACT_INFO_STEP = '/intake/newIntake/contact-info';
  static CONTACT_INFO_STEP_BONDING = '/intake/newIntake/contact-info-bonding';
  static HEALTHCARE_PROVIDER_INFO_STEP = '/intake/newIntake/healthcare-provider-info';
  static ABOUT_YOUR_ABSENCE = '/intake/newIntake/about-your-absence';
  static ABOUT_YOUR_DELIVERY = '/intake/newIntake/about-your-delivery';
  static NOTIFICATION_PREFERENCES = '/intake/newIntake/notification-preferences';
  static EDIT_CONTACT_INFO = '/intake/newIntake/edit-contact-info';
  static HEALTHCARE_PROVIDER = '/intake/newIntake/healthcare-provider';
  static ADD_HEALTHCARE_PROVIDER = '/intake/newIntake/addHealthcareProvider';
  static RELEASE_RECORDS_AUTH = '/intake/newIntake/releaseRecordsAuthorization';
  static FDA_PAGE = '/intake';
  static WORK_SCHEDULE = '/intake/newIntake/work-schedule-bonding';
  static TRANSITION_WORK_SCHEDULE = '/intake/transitionIntake/work-schedule-bonding';
  static WORK_SCHEDULE_EXTENSION = '/intake/extension/work-schedule-bonding';
  static CONFIRMATION = '/intake/newIntake/confirmation';
  static TAKE_LEAVE = '/intake/newIntake/taking-leave-bonding';
  static TAKE_LEAVE_EXTENSION = '/intake/extension/taking-leave-bonding';
  static WORKSCHEDULE_EDIT = '/intake/newIntake/edit-work-schedule';
  static WORKSCHEDULE_EDIT_EXTENSION = '/intake/extension/edit-work-schedule';
  static EMPLOYMENT_DETAILS = '/intake/newIntake/employment-questions';
  static EMPLOYMENT_DETAILS_LOA = '/intake/newIntake/loa/employment-questions';
  static INTAKE_ERROR = 'intake/intake-custom-error';
  static CONFIRMATION_PAGE = '/intake/newIntake/confirmation-page';
  static INTAKE_THINGS_YOU_WILL_NEED_LOA = '/intake/newIntake/loa/intake-things-you-will-need';
  static CONTACT_INFO_STEP_LOA = '/intake/newIntake/loa/contact-info';
  static EDIT_CONTACT_INFO_LOA = '/intake/newIntake/loa/edit-contact-info';
  static INTAKE_FLOW_LOA = '/intake/newIntake/loa';
  static HEALTHCARE_PROVIDER_LOA = '/intake/newIntake/loa/healthcare-provider';
  static HEALTHCARE_PROVIDER_INFO_STEP_LOA = '/intake/newIntake/loa/healthcare-provider-info';
  static ADD_HEALTHCARE_PROVIDER_LOA = '/intake/newIntake/loa/addHealthcareProvider'
  static ABOUT_YOUR_ABSENCE_LOA = '/intake/newIntake/loa/about-your-absence';
  static ABOUT_YOUR_DELIVERY_LOA = '/intake/newIntake/loa/about-your-delivery';
  static NOTIFICATION_PREFERENCES_LOA = '/intake/newIntake/loa/notification-preferences';
  static RELEASE_RECORDS_AUTH_LOA = '/intake/newIntake/loa/releaseRecordsAuthorization';
  static WORK_SCHEDULE_LOA = '/intake/newIntake/loa/work-schedule';
  static WORK_SCHEDULE_EDIT_LOA = '/intake/newIntake/loa/edit-work-schedule';
  static CONFIRMATION_PAGE_LOA = '/intake/newIntake/loa/confirmation';
  static ABOUT_YOUR_BONDING = '/intake/newIntake/about-your-bonding';
}
