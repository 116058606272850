import {GbBase} from './gb-base';

export class GbLog extends GbBase {

  getUrls() {
    return {
      getSessionUrl:        this.getBaseUrl() + 'gb-log-service/log/v1/Log?' + this.getClientId('auth'),
      getSessionUrlUnauth:  this.getBaseUrl() + 'uws/gb-log-service/log/v1/Log?' + this.getClientId('auth'),
    }
  }
}

