import {Component, OnInit} from '@angular/core';

@Component({
  selector: 'app-leave-balance-chart',
  templateUrl: './leave-balance-chart.component.html',
  styleUrls: ['./leave-balance-chart.component.scss']
})
export class LeaveBalanceChartComponent implements OnInit {
  public data;
  public options;
  public totalLabel;
  public reviewLabel;
  constructor() { }

  ngOnInit() {
    this.initData();
  }

  initData() {
    const available = this.convertToMiliseconds(42, 'hour') + this.convertToMiliseconds(15, 'minute');
          const underReview = this.convertToMiliseconds(10, 'hour');
          const used = this.convertToMiliseconds(280, 'hour') - this.convertToMiliseconds(42, 'hour') - this.convertToMiliseconds(10, 'hour');
    this.data = {
      Available : available,
      'Under Review': underReview,
      Used: used
    };
    this.options = {
      chartWidth: 200,
      chartHeight: 200,
      donutHoleSize: 0.65,
      sliceBorderWidth: 0.02,
      sliceBorderColor: 'white',
      sliceFillColors: ['#018852', '#3A5A78', '#CCCCCC'],
      insideTextHeader : '42h 15m',
      insideTextSubheader: 'available'
    };
    this.totalLabel = `${280}h total`;
    this.reviewLabel = `${10}h under review`;
  }

  convertToMiliseconds(amount, type) {
    switch (type) {
      case 'minute':
        return amount * 60 * 1000;
      case 'hour':
        return amount * 60 * 60 * 1000;
      default:
        return;
    }
  }
}
