import {Component, OnInit} from '@angular/core';
import {Router} from '@angular/router';
import {ModalService} from '../../../../../shared/services/modal.service';
import {StorageManagementService} from '../../../../../shared/services/storage-management.service';
import {Person} from '../../../../../shared/models/person.model';
import {Profile} from '../../../../../shared/models/profile.model';
import {AppConstants} from '../../../../../shared/constants/app.constants';
import {AppLabelConstants} from '../../../../../shared/constants/appLabel.constants';
import {SessionConstants} from '../../../../../shared/constants/session.constants';

@Component({
  selector: 'app-mail-payment-popup',
  templateUrl: 'mail-payment-popup.component.html',
  styleUrls: ['mail-payment-popup.component.scss'],

})
export class MailPaymentPopupComponent implements OnInit {
  public appConstant = AppConstants;
  public appLabelConstant = AppLabelConstants;
  public id;
  public employee: Person;
  public curProfile: Profile;
  public addressLine1: string;
  public addressLine2: string;
  public city: string;
  public state: string;
  public zipcode: string;
  public edit = 'Edit';
  public _this = this; // reference to current scope for calling a fn()
  // from within subscribe of any service method
  public userDetails;
  public failureMessage = '';
  public pageId: string;
  public owscContent;
  public para1 = '';
  public para2 = '';
  public owcsContentData;

  constructor(private router: Router,
              private modalService: ModalService,
              private storageManagementService: StorageManagementService
  ) { }


  ngOnInit() {
    this.employee = this.storageManagementService.getAppData(SessionConstants.USER_DATA);
    sessionStorage.setItem(SessionConstants.GO_BACK_FROM_PROFILE_TO, '');
    this.fetchMailingAddress();
  }
  fetchMailingAddress(): void {
  }
}
