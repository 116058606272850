
import {catchError, map} from 'rxjs/operators';
import {Injectable} from '@angular/core';
import {HttpClient, HttpErrorResponse, HttpHeaders} from '@angular/common/http';
import {ResponseErrorHandlerService} from './response-error-handler.service';
import {env} from '../../../environments/environment-loader';

import {AppLabelConstants} from '../constants/appLabel.constants';
import {BehaviorSubject} from 'rxjs';
import { AdaIntermittentAbsenceResponse } from '../models/adaIntermittentAbsence.model';
import {IEmpWorkScheduleResponse} from '@app/cxr/modules/intake/shared/models/work-schedule-details.model';
import { ILeaveDetails } from '../models/getCoverages.model';


@Injectable()
export class LeaveDetailsService {
  public certData: BehaviorSubject<boolean> = new BehaviorSubject(false);
  isTimeLineDetailsSet: BehaviorSubject<boolean> = new BehaviorSubject(false);
  constructor(private httpClient: HttpClient,
              private errorHandler: ResponseErrorHandlerService) {
  }
  /**
   * This function is used to submit the intermittent timeOff from timeOff screen
   */
  public submitIntermittentTimeOff(data) {
    const header = {
      headers: new HttpHeaders()
        .set(AppLabelConstants.CONTENT_TYPE_KEY, AppLabelConstants.APPLICATION_KEY)
    };
    const request: string = JSON.stringify(data);
    return this.httpClient.post(env.gbLeaves.intermittentLeaveSchedule, request, header).pipe(map(
      (response: Response) => {
        if (!this.errorHandler.isServiceResponseHasError(response)) {
          return response;
        } else {
          throw response;
        }
      }
    )).pipe(catchError((err: any) => {
      const error = err.error;
      if (error && error['DataPower Service Name'] && error['DataPower Error Code']
        && error['DataPower Error Code'] === AppLabelConstants.DP_TIME_OUT_ERROR_CODE) {
        this.errorHandler.handleTimeOutErrorForLeaveRequest();
      } else {
        return this.errorHandler.handleErrorForSubmit(error);
      }
    }));
  };

  /**
   *
   * @param leaveId
   * @param personId
   * In this UI is calling the GetDisabilityClaimDetails API of THAA
   */
  getLeaveDetails(leaveId: string, personId: string) {
    const header = {
      headers: new HttpHeaders()
        .set(AppLabelConstants.CONTENT_TYPE_KEY, AppLabelConstants.APPLICATION_KEY)
    };

    return this.httpClient.get(env.gbLeaves.getleaveDetailsList  + '&leaveId=' + leaveId + '&personId=' + personId, header).pipe(map(
      (response: ILeaveDetails) => {
        if (!this.errorHandler.isServiceResponseHasError(response)) {
          return response;
        } else {
          throw response;
        }
      }
    ), catchError((error: any) => this.errorHandler.handleError(error)), );
  };

  /**
   * This function is used to get work schedule based on user input
   *
   * @param personId and DOA
   */
  public getWorkScheduleForDate(personId: string, DOA?: string, endDate?: string ) {
    let request = '&personId=' + personId;
    if (DOA) {
      request = request + '&DOA=' + DOA;
    }
    if (endDate) {
      request = request +  '&endDate=' + endDate;
    }
    return this.httpClient.get(env.gbLeaves.workScheduleApi + request,
      {
        headers: new HttpHeaders()
          .set(AppLabelConstants.CONTENT_TYPE_KEY, AppLabelConstants.APPLICATION_KEY)
      }).pipe(map(
      (response) => {
        if (!this.errorHandler.isServiceResponseHasError(response)) {
          return response;
        } else {
          throw response;
        }
      }
    ), catchError((error: any) => this.errorHandler.handleError(error)), );
  };


  /**
   * This function is used to get regular work schedule based on user input
   *
   * @param personID and FDA
   * @param fda
   */
  public getEmployeeWorkSchedule(personID: string, fda: string) {
    const request: string = JSON.stringify({
      personId: personID,
      FDA: fda
    });
    return this.httpClient.post(env.gbLeaves.workScheduleEmployeeApi , request,
      {
        headers: new HttpHeaders()
          .set(AppLabelConstants.CONTENT_TYPE_KEY, AppLabelConstants.APPLICATION_KEY)
      }).pipe(map(
      (response: IEmpWorkScheduleResponse) => {
        if (!this.errorHandler.isServiceResponseHasError(response)) {
          return response;
        } else {
          throw response;
        }
      }
    ), catchError((error: any) => this.errorHandler.handleError(error)), );
  };
  /**
   * This function is used to get CompanyClaimAcceptanceDate
   * which is used in time-off-request component in cae intermittent leave
   *
   * @param personId
   */
  public getCompanyClaimAcceptanceDate(personId: string ) {
    return this.httpClient.get(env.gbLeaves.getCompanyDetails + '&personId=' + personId,
      {
        headers: new HttpHeaders()
          .set(AppLabelConstants.CONTENT_TYPE_KEY, AppLabelConstants.APPLICATION_KEY)
      }).pipe(map(
      (response: Response) => {
        if (!this.errorHandler.isServiceResponseHasError(response)) {
          return response;
        } else {
          throw response;
        }
      }
    ), catchError((error: any) => this.errorHandler.handleError(error)), );
  };


/**
 * This function is used to get plan Balances data
 * which is used in leave-plan-balances component to show benefit balances on leave details page
 *
 * @param personId
 */
  public getPlanBalances(personId: string, leaveId: string ) {
  return this.httpClient.get(env.gbLeaves.leavePlanBalance + '&personId=' + personId + '&leaveId=' + leaveId,
    {
      headers: new HttpHeaders()
        .set(AppLabelConstants.CONTENT_TYPE_KEY, AppLabelConstants.APPLICATION_KEY)
    }).pipe(map(
    (response: Response) => {
      if (!this.errorHandler.isServiceResponseHasError(response)) {
        return response;
      } else {
        throw response;
      }
    }
  ), catchError((error: any) => this.errorHandler.handleError(error)), );
};

  public displayADAUpdateAccommodation(personId: string, leaveId: string) {
    return this.httpClient.get(env.thaaWKABPortalService.thaaADAUpdateAccommodation + '&personId=' + personId + '&leaveId=' + leaveId,
      {
        headers: new HttpHeaders()
          .set(AppLabelConstants.CONTENT_TYPE_KEY, AppLabelConstants.APPLICATION_KEY)
      }).pipe(map(
      (response: Response) => {
        if (!this.errorHandler.isServiceResponseHasError(response)) {
          return response;
        } else {
          throw response;
        }
      }
    ), catchError((error: any) => this.errorHandler.handleErrorThaa(error)), );
  }

  public viewADAIntermittentRequestDetail(personId: string, leaveId: string, certId: string[]) {
    return this.httpClient.get(env.thaaWKABPortalService.thaaADAIntermittentRequestDetailView + '&personId=' + personId + '&leaveId=' + leaveId + '&certId=' + certId,
       {
         headers: new HttpHeaders()
           .set(AppLabelConstants.CONTENT_TYPE_KEY, AppLabelConstants.APPLICATION_KEY)
       }).pipe(map(
       (response: AdaIntermittentAbsenceResponse) => {
         if (!this.errorHandler.isServiceResponseHasError(response)) {
           return response;
         } else {
           throw response;
         }
       }
     ), catchError((error: any) => this.errorHandler.handleErrorThaa(error)), );
   }

  /**
   * This function is used to get ada timeline flag as true/false based on user input
   *
   * @param personId
   */
  public getAdaTimeline(personId: string) {
    return this.httpClient.get(env.gbLeaves.adaTimeline + '&personId=' + personId,
      {
        headers: new HttpHeaders()
          .set(AppLabelConstants.CONTENT_TYPE_KEY, AppLabelConstants.APPLICATION_KEY)
      }).pipe(map(
      (response: Response) => {
        if (!this.errorHandler.isServiceResponseHasError(response)) {
          return response;
        } else {
          throw response;
        }
      }
    ), catchError((error: any) => this.errorHandler.handleError(error)), );
  };

  public getAdaTimelineDetails(personId: string, leaveId: string) {
    return this.httpClient.get(env.thaaWKABPortalService.adaTimelineDetails + '&personId=' + personId + '&leaveId=' + leaveId,
      {
        headers: new HttpHeaders()
          .set(AppLabelConstants.CONTENT_TYPE_KEY, AppLabelConstants.APPLICATION_KEY)
      }).pipe(map(
      (response: Response) => {
        if (!this.errorHandler.isServiceResponseHasError(response)) {
          return response;
        } else {
          throw response;
        }
      }
    ), catchError((error: any) => this.errorHandler.handleError(error)), );
  };
}
