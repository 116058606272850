
import {catchError, map} from 'rxjs/operators';
import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {ResponseErrorHandlerService} from './response-error-handler.service';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {env} from '../../../environments/environment-loader';

import {AppLabelConstants} from '../constants/appLabel.constants';

@Injectable()
export class LetterImageCodeService {

  constructor( private httpClient: HttpClient,
               private errorHandler: ResponseErrorHandlerService) {
  }

  getLetterImageService(personId, letterId): Observable<any> {
    return this.httpClient.get(env.thaaWKABPortalService.getLetterImageURL + '&personid=' + personId + '&letterId=' + letterId, {

      headers: new HttpHeaders()
        .set(AppLabelConstants.CONTENT_TYPE_KEY, AppLabelConstants.APPLICATION_KEY)
    }).pipe(map(
      (response: Response) => {
        if (!this.errorHandler.isServiceResponseHasError(response)) {
          return response;
        } else {
          throw response;
        }
      }
    ), catchError((error: any) => this.errorHandler.handleError(error)), );

  }

  public b64toBlob(b64Data, contentType, sliceSize) {
  contentType = contentType || '';
  sliceSize = sliceSize || 512;

  const byteCharacters = atob(b64Data);
  const  byteArrays = [];

  for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
    const slice = byteCharacters.slice(offset, offset + sliceSize);

    const byteNumbers = new Array(slice.length);
    for (let i = 0; i < slice.length; i++) {
      byteNumbers[i] = slice.charCodeAt(i);
    }

    const byteArray = new Uint8Array(byteNumbers);

    byteArrays.push(byteArray);
  }

  const blob = new Blob(byteArrays, {type: contentType});
  return blob;
}
}
