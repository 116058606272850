import {Component, OnInit} from '@angular/core';
import {ModalService} from '../../shared/services/modal.service';
import {AppLabelConstants} from '../../shared/constants/appLabel.constants';

@Component({
  selector: 'app-system-alert',
  templateUrl: 'system-alert.component.html',
  styleUrls: ['system-alert.component.scss']
})
export class SystemAlertComponent implements OnInit {
  public appLabelConstants = AppLabelConstants;

  constructor(private modalService: ModalService) { }

  ngOnInit() {
  }
  backToLogin() {
    this.modalService.closeModal(AppLabelConstants.SYSTEM_ALERT_MODAL);
  }
}
