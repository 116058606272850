
import {catchError, map} from 'rxjs/operators';
import {Injectable} from '@angular/core';

import {Observable} from 'rxjs';
import {env} from '../../../environments/environment-loader';


import {ResponseErrorHandlerService} from './response-error-handler.service';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {AppLabelConstants} from '../constants/appLabel.constants';

@Injectable()
export class AetnaApsFormsService {
  constructor( private errorHandler: ResponseErrorHandlerService,
               private httpClient: HttpClient) { }

  thaaGetAPS(personId: string, claimId: string): Observable<any> {
    // const request: string = JSON.stringify(
    //   {
    //     'personId': personId,
    //     'claimId' : claimId
    //   }
    // );

    return this.httpClient.get(env.thaaWKABPortalService.thaaGetAPSURL + '&personid=' + personId + '&claimId=' + claimId, {
        headers: new HttpHeaders()
          .set(AppLabelConstants.CONTENT_TYPE_KEY, AppLabelConstants.APPLICATION_KEY)
      }).pipe(
      map(
        (response: Response) => {
          if (!this.errorHandler.isServiceResponseHasError(response)) {
            return response;
          } else {
            throw response;
          }
        }
      ),   catchError((error: any) => this.errorHandler.handleError(error)), );

  }

  public b64toBlob(b64Data, contentType, sliceSize) {
    contentType = contentType || '';
    sliceSize = sliceSize || 512;
    const byteCharacters = atob(b64Data);
    const  byteArrays = [];

    for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
      const slice = byteCharacters.slice(offset, offset + sliceSize);

      const byteNumbers = new Array(slice.length);
      for (let i = 0; i < slice.length; i++) {
        byteNumbers[i] = slice.charCodeAt(i);
      }

      const byteArray = new Uint8Array(byteNumbers);
      byteArrays.push(byteArray);
    }

    const blob = new Blob(byteArrays, {type: contentType});
    return blob;
  }

  /*
  * download pdf from base64 code
  * @param letterBytes - base64 code
  * */
  downloadPaystubPDF(fileName, letterBytes) {
    const fileType = AppLabelConstants.MIME_TYPE_PDF;
    const res = this.b64toBlob(letterBytes, fileType, 512);
    const blob = new Blob([res], {type: fileType});
    const formObjectURL = URL.createObjectURL(blob);

    if (window.navigator.appVersion.toString().indexOf('.NET') > 0) { // for IE browser
      const nav = (window.navigator as any);
      if (nav.msSaveOrOpenBlob) {
        nav.msSaveOrOpenBlob(blob, `${fileName}.pdf`);
      }
    } else {
      const link = document.createElement('a');
      link.href = formObjectURL;
      link.download = 'Paystub ' + fileName;
      link.click();
    }
  }
}
