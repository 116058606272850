import {OWCSMissingItemsResponse} from '../shared/models/missing-info.model';

/**
 * Created by AC12394 on 2/24/2018.
 */

export const MISSING_ITEMS_OWCS_RESPONSE_MOCK: OWCSMissingItemsResponse = {
  id: '1444626431877',
  name: 'Missing Info Form Page',
  forms: [
    {
      id: '1444636838747',
      key: 'CD_651',
      displayName: 'Kaiser Permanent HIPAA Authorization',
      description: 'Gives us permission to request documents, like medical records, for your claim file.',
      generic: {
        title: 'ca_auth_disclosure_PHI',
        url: '/cm/files/1444636846429.pdf',
        uniqueFileName: 'ca_auth_disclosure_PHI',
        description: '',
        customFileName: '',
        keyword: ''
      },
      onshore: {
        title: 'osh_kaiser_perm_hipaa_auth_lc-7487',
        url: '/cm/files/1444636846685.pdf',
        uniqueFileName: 'osh_kaiser_perm_hipaa_auth_lc-7487',
        description: '',
        customFileName: '',
        keyword: ''
      },
      leave: {
        title: 'hart_kais_hippa_auth_lc7487',
        url: '/cm/files/1444636846795.pdf',
        uniqueFileName: 'hart_kais_hippa_auth_lc7487',
        description: '',
        customFileName: '',
        keyword: ''
      },
      parentId: '1444626431877'
    },
    {
      id: '1444636840803',
      key: 'CD_736',
      displayName: 'Last Date Worked Confirmation',
      description: 'Confirmation of the last day you worked',
      generic: {
        title: '',
        url: '',
        uniqueFileName: '',
        description: '',
        customFileName: '',
        keyword: ''
      },
      onshore: {
        title: '',
        url: '',
        uniqueFileName: '',
        description: '',
        customFileName: '',
        keyword: ''
      },
      leave: {
        title: '',
        url: '',
        uniqueFileName: '',
        description: '',
        customFileName: '',
        keyword: ''
      },
      parentId: '1444626431877'
    },
    {
      id: '1444636665456',
      key: 'CD_616',
      displayName: 'Authorization Form',
      description: 'Gives us permission to request documents, like medical records, for your claim file',
      generic: {
        title: 'hipaa_aso_provider_auth_lc7361r_3',
        url: '/cm/files/1444636666757.pdf',
        uniqueFileName: 'hipaa_aso_provider_auth_lc7361r_3',
        description: '',
        customFileName: '',
        keyword: ''
      },
      onshore: {
        title: '',
        url: '',
        uniqueFileName: '',
        description: '',
        customFileName: '',
        keyword: ''
      },
      leave: {
        title: 'hart_hipaa_aso_provider_auth_lc7361r_2',
        url: '/cm/files/1444636666789.pdf',
        uniqueFileName: 'hart_hipaa_aso_provider_auth_lc7361r_2',
        description: '',
        customFileName: '',
        keyword: ''
      },
      parentId: '1444626431877'
    },
    {
      id: '1444636839379',
      key: 'CD_690',
      displayName: 'Cause of Disabling Condition',
      description: 'Clarification of how and where you were injured or otherwise became disabled',
      generic: {
        title: '',
        url: '',
        uniqueFileName: '',
        description: '',
        customFileName: '',
        keyword: ''
      },
      onshore: {
        title: '',
        url: '',
        uniqueFileName: '',
        description: '',
        customFileName: '',
        keyword: ''
      },
      leave: {
        title: '',
        url: '',
        uniqueFileName: '',
        description: '',
        customFileName: '',
        keyword: ''
      },
      parentId: '1444626431877'
    },
    {
      id: '1444636841292',
      key: 'RRI_600',
      displayName: 'Other Income Benefits',
      description: 'Information about other benefits you might have (state benefits, worker\'s compensation, no-fault benefits)',
      generic: {
        title: '',
        url: '',
        uniqueFileName: '',
        description: '',
        customFileName: '',
        keyword: ''
      },
      onshore: {
        title: '',
        url: '',
        uniqueFileName: '',
        description: '',
        customFileName: '',
        keyword: ''
      },
      leave: {
        title: '',
        url: '',
        uniqueFileName: '',
        description: '',
        customFileName: '',
        keyword: ''
      },
      parentId: '1444626431877'
    },
    {
      id: '1444636844108',
      key: 'RRI_667',
      displayName: 'Work and Educational History',
      description: 'Use this form to provide information about your work experience and personal background so we can assess what other occupations you might be capable of performing.',
      generic: {
        title: '',
        url: '',
        uniqueFileName: '',
        description: '',
        customFileName: '',
        keyword: ''
      },
      onshore: {
        title: '',
        url: '',
        uniqueFileName: '',
        description: '',
        customFileName: '',
        keyword: ''
      },
      leave: {
        title: '',
        url: '',
        uniqueFileName: '',
        description: '',
        customFileName: '',
        keyword: ''
      },
      parentId: '1444626431877'
    },
    {
      id: '1444636665583',
      key: 'CD_627',
      displayName: 'Payment Option Form',
      description: 'Allows you to specify how you want us to handle your LTD payment when you might be able to receive income benefits from another source. You can choose to get paid in full up front and potentially create an overpayment that you\'d have to pay us back later. Or, we can estimate the amount you\'ll get from other sources and reduce payments accordingly.',
      generic: {
        title: 'lc-6436_ltd_pmnt_op_rbrs_ag_fi_6436_3',
        url: '/cm/files/1444636667771.pdf',
        uniqueFileName: 'lc-6436_ltd_pmnt_op_rbrs_ag_fi_6436_3',
        description: '',
        customFileName: '',
        keyword: ''
      },
      onshore: {
        title: 'osh_ltd_pay_op_rmbrs_agree_lc-6436',
        url: '/cm/files/1444636667889.pdf',
        uniqueFileName: 'osh_ltd_pay_op_rmbrs_agree_lc-6436',
        description: '',
        customFileName: '',
        keyword: ''
      },
      leave: {
        title: 'hart_lc-7106_ltd_pmnt_op_rbrs_ag_aso_2',
        url: '/cm/files/1444636668007.pdf',
        uniqueFileName: 'hart_lc-7106_ltd_pmnt_op_rbrs_ag_aso_2',
        description: '',
        customFileName: '',
        keyword: ''
      },
      parentId: '1444626431877'
    },
    {
      id: '1444636859011',
      key: 'RRI_671',
      displayName: 'Attending Physician\'s Statement',
      description: 'Statement from attending physician',
      generic: {
        title: '',
        url: '',
        uniqueFileName: '',
        description: '',
        customFileName: '',
        keyword: ''
      },
      onshore: {
        title: '',
        url: '',
        uniqueFileName: '',
        description: '',
        customFileName: '',
        keyword: ''
      },
      leave: {
        title: '',
        url: '',
        uniqueFileName: '',
        description: '',
        customFileName: '',
        keyword: ''
      },
      parentId: '1444626431877'
    },
    {
      id: '1444636839259',
      key: 'CD_683',
      displayName: 'Attending Physician\'s Statement',
      description: 'Statement from attending physician',
      generic: {
        title: 'aps_progress_report_mental_health_lc-7406_6',
        url: '/cm/files/1444636851412.pdf',
        uniqueFileName: 'aps_progress_report_mental_health_lc-7406_6',
        description: '',
        customFileName: '',
        keyword: ''
      },
      onshore: {
        title: 'osh_aps_prgs_rpt_mh_lc-7406',
        url: '/cm/files/1444636851538.pdf',
        uniqueFileName: 'osh_aps_prgs_rpt_mh_lc-7406',
        description: '',
        customFileName: '',
        keyword: ''
      },
      leave: {
        title: 'hart_aps_progress_report_mental_health_lc-7406',
        url: '/cm/files/1444636851664.pdf',
        uniqueFileName: 'hart_aps_progress_report_mental_health_lc-7406',
        description: '',
        customFileName: '',
        keyword: ''
      },
      parentId: '1444626431877'
    },
    {
      id: '1444636839911',
      key: 'CD_714',
      displayName: 'Visual Functional Evaluation',
      description: 'Your doctor uses this to tell us about your visual capabilities and limitations.',
      generic: {
        title: 'visual_functional_evaluation_lc7396-5',
        url: '/cm/files/1444636854153.pdf',
        uniqueFileName: 'visual_functional_evaluation_lc7396-5',
        description: '',
        customFileName: '',
        keyword: ''
      },
      onshore: {
        title: 'osh_visual_functional_eval_lc-7396',
        url: '/cm/files/1444636854867.pdf',
        uniqueFileName: 'osh_visual_functional_eval_lc-7396',
        description: '',
        customFileName: '',
        keyword: ''
      },
      leave: {
        title: 'hart_visual_functional_evaluation_lc7396-5',
        url: '/cm/files/1444636855348.pdf',
        uniqueFileName: 'hart_visual_functional_evaluation_lc7396-5',
        description: '',
        customFileName: '',
        keyword: ''
      },
      parentId: '1444626431877'
    },
    {
      id: '1444636860044',
      key: 'RRI_690',
      displayName: 'Cause of Disabling Condition',
      description: 'Clarification of how and where you were injured or otherwise became disabled',
      generic: {
        title: '',
        url: '',
        uniqueFileName: '',
        description: '',
        customFileName: '',
        keyword: ''
      },
      onshore: {
        title: '',
        url: '',
        uniqueFileName: '',
        description: '',
        customFileName: '',
        keyword: ''
      },
      leave: {
        title: '',
        url: '',
        uniqueFileName: '',
        description: '',
        customFileName: '',
        keyword: ''
      },
      parentId: '1444626431877'
    },
    {
      id: '1444636839139',
      key: 'CD_671',
      displayName: 'Attending Physician\'s Statement',
      description: 'Statement from attending physician',
      generic: {
        title: 'aps_initial_report_lc-7135_9',
        url: '/cm/files/1444636849225.pdf',
        uniqueFileName: 'aps_initial_report_lc-7135_9',
        description: '',
        customFileName: '',
        keyword: ''
      },
      onshore: {
        title: 'osh_aps_init_rpt_lc-7135',
        url: '/cm/files/1444636849345.pdf',
        uniqueFileName: 'osh_aps_init_rpt_lc-7135',
        description: '',
        customFileName: '',
        keyword: ''
      },
      leave: {
        title: 'hart_aps_initial_report_lc-7135_4',
        url: '/cm/files/1444636849465.pdf',
        uniqueFileName: 'hart_aps_initial_report_lc-7135_4',
        description: '',
        customFileName: '',
        keyword: ''
      },
      parentId: '1444626431877'
    },
    {
      id: '1444636866305',
      key: 'RRI_742',
      displayName: 'Job Description',
      description: 'Job description for your role',
      generic: {
        title: '',
        url: '',
        uniqueFileName: '',
        description: '',
        customFileName: '',
        keyword: ''
      },
      onshore: {
        title: '',
        url: '',
        uniqueFileName: '',
        description: '',
        customFileName: '',
        keyword: ''
      },
      leave: {
        title: '',
        url: '',
        uniqueFileName: '',
        description: '',
        customFileName: '',
        keyword: ''
      },
      parentId: '1444626431877'
    },
    {
      id: '1444636838909',
      key: 'CD_655',
      displayName: 'LTD Payment/Reimbursement Agreement for Social Security Benefits',
      description: 'Allows you to specify how you want us to handle your LTD payment when you might be able to receive income benefits from Social Security. You can choose to get paid in full up front and potentially create an overpayment that you\'d have to pay us back later. Or, we can estimate the amount you\'ll get from other sources and reduce payments accordingly.',
      generic: {
        title: 'ltd_pay_opt_reimb_agree_ss_-_lc7644',
        url: '/cm/files/1444636847059.pdf',
        uniqueFileName: 'ltd_pay_opt_reimb_agree_ss_-_lc7644',
        description: '',
        customFileName: '',
        keyword: ''
      },
      onshore: {
        title: '',
        url: '',
        uniqueFileName: '',
        description: '',
        customFileName: '',
        keyword: ''
      },
      leave: {
        title: '',
        url: '',
        uniqueFileName: '',
        description: '',
        customFileName: '',
        keyword: ''
      },
      parentId: '1444626431877'
    },
    {
      id: '1444636865414',
      key: 'RRI_730',
      displayName: 'Wage Details',
      description: 'These are "offsets", or alternate sources of income. If you receive any of these while you\'re on disability, it may reduce the amount you\'re eligible to receive under The Hartford\'s plan.',
      generic: {
        title: '',
        url: '',
        uniqueFileName: '',
        description: '',
        customFileName: '',
        keyword: ''
      },
      onshore: {
        title: '',
        url: '',
        uniqueFileName: '',
        description: '',
        customFileName: '',
        keyword: ''
      },
      leave: {
        title: '',
        url: '',
        uniqueFileName: '',
        description: '',
        customFileName: '',
        keyword: ''
      },
      parentId: '1444626431877'
    },
    {
      id: '1444636864524',
      key: 'RRI_718',
      displayName: 'Eligibility Confirmation',
      description: 'Confirmation from your employer that you\'re enrolled for STD coverage, as well as some basic information about things like your coverage dates, job description, and last day of work',
      generic: {
        title: '',
        url: '/cm/files/1444636667242.pdf',
        uniqueFileName: '',
        description: '',
        customFileName: '',
        keyword: ''
      },
      onshore: {
        title: '',
        url: '',
        uniqueFileName: '',
        description: '',
        customFileName: '',
        keyword: ''
      },
      leave: {
        title: '',
        url: '',
        uniqueFileName: '',
        description: '',
        customFileName: '',
        keyword: ''
      },
      parentId: '1444626431877'
    },
    {
      id: '1444636864130',
      key: 'RRI_706',
      displayName: 'Doctor\'s Activity Level Report',
      description: 'Your doctor will use this to specify your physical abilities, restrictions, and limitations.',
      generic: {
        title: '',
        url: '',
        uniqueFileName: '',
        description: '',
        customFileName: '',
        keyword: ''
      },
      onshore: {
        title: '',
        url: '',
        uniqueFileName: '',
        description: '',
        customFileName: '',
        keyword: ''
      },
      leave: {
        title: '',
        url: '',
        uniqueFileName: '',
        description: '',
        customFileName: '',
        keyword: ''
      },
      parentId: '1444626431877'
    },
    {
      id: '1444636839537',
      key: 'CD_696',
      displayName: 'Restrictions & Limitations',
      description: 'Doctor recommendations for your restrictions and limitations',
      generic: {
        title: '',
        url: '',
        uniqueFileName: '',
        description: '',
        customFileName: '',
        keyword: ''
      },
      onshore: {
        title: '',
        url: '',
        uniqueFileName: '',
        description: '',
        customFileName: '',
        keyword: ''
      },
      leave: {
        title: '',
        url: '',
        uniqueFileName: '',
        description: '',
        customFileName: '',
        keyword: ''
      },
      parentId: '1444626431877'
    },
    {
      id: '1444636840701',
      key: 'CD_733',
      displayName: 'Employment Confirmation',
      description: 'Confirmation of your date of hire or coverage effective date',
      generic: {
        title: '',
        url: '',
        uniqueFileName: '',
        description: '',
        customFileName: '',
        keyword: ''
      },
      onshore: {
        title: '',
        url: '',
        uniqueFileName: '',
        description: '',
        customFileName: '',
        keyword: ''
      },
      leave: {
        title: '',
        url: '',
        uniqueFileName: '',
        description: '',
        customFileName: '',
        keyword: ''
      },
      parentId: '1444626431877'
    },
    {
      id: '1444636665351',
      key: 'CD_609',
      displayName: 'Doctor’s Contact Information',
      description: 'Your doctor\'s contact information',
      generic: {
        title: '',
        url: '',
        uniqueFileName: '',
        description: '',
        customFileName: '',
        keyword: ''
      },
      onshore: {
        title: '',
        url: '',
        uniqueFileName: '',
        description: '',
        customFileName: '',
        keyword: ''
      },
      leave: {
        title: '',
        url: '',
        uniqueFileName: '',
        description: '',
        customFileName: '',
        keyword: ''
      },
      parentId: '1444626431877'
    },
    {
      id: '1444636840175',
      key: 'CD_721',
      displayName: 'Eligibility Confirmation',
      description: 'Confirmation from your employer that you\'re enrolled for STD coverage, as well as some basic information about things like your coverage dates, job description, and last day of work',
      generic: {
        title: '',
        url: '/cm/files/1444636667242.pdf',
        uniqueFileName: '',
        description: '',
        customFileName: '',
        keyword: ''
      },
      onshore: {
        title: '',
        url: '',
        uniqueFileName: '',
        description: '',
        customFileName: '',
        keyword: ''
      },
      leave: {
        title: '',
        url: '',
        uniqueFileName: '',
        description: '',
        customFileName: '',
        keyword: ''
      },
      parentId: '1444626431877'
    },
    {
      id: '1444636665486',
      key: 'CD_620',
      displayName: 'Medical History Form',
      description: 'Use this form to list the doctors and/or hospitals you\'ve visited for evaluation and treatment for your disabling condition.',
      generic: {
        title: 'hipaa_aso_provider_auth_lc7361r_3 (1)',
        url: '/cm/files/1444636667242.pdf',
        uniqueFileName: 'hipaa_aso_provider_auth_lc7361r_3 (1)',
        description: '',
        customFileName: '',
        keyword: ''
      },
      onshore: {
        title: '',
        url: '',
        uniqueFileName: '',
        description: '',
        customFileName: '',
        keyword: ''
      },
      leave: {
        title: 'hart_hipaa_aso_provider_auth_lc7361r_2 (1)',
        url: '/cm/files/1444636667362.pdf',
        uniqueFileName: 'hart_hipaa_aso_provider_auth_lc7361r_2 (1)',
        description: '',
        customFileName: '',
        keyword: ''
      },
      parentId: '1444626431877'
    },
    {
      id: '1444636842486',
      key: 'RRI_627',
      displayName: 'Payment Option Form',
      description: 'Allows you to specify how you want us to handle your LTD payment when you might be able to receive income benefits from another source. You can choose to get paid in full up front and potentially create an overpayment that you\'d have to pay us back later. Or, we can estimate the amount you\'ll get from other sources and reduce payments accordingly.',
      generic: {
        title: '',
        url: '',
        uniqueFileName: '',
        description: '',
        customFileName: '',
        keyword: ''
      },
      onshore: {
        title: '',
        url: '',
        uniqueFileName: '',
        description: '',
        customFileName: '',
        keyword: ''
      },
      leave: {
        title: '',
        url: '',
        uniqueFileName: '',
        description: '',
        customFileName: '',
        keyword: ''
      },
      parentId: '1444626431877'
    },
    {
      id: '1444636842234',
      key: 'RRI_624',
      displayName: 'Workers Compensation Denial Letter',
      description: 'If you\'re receiving Workers\' Compensation, it may reduce the amount you\'re eligible to receive under The Hartford\'s plan. This letter explains what your Workers\' Compensation carrier will or will not not cover.',
      generic: {
        title: '',
        url: '',
        uniqueFileName: '',
        description: '',
        customFileName: '',
        keyword: ''
      },
      onshore: {
        title: '',
        url: '',
        uniqueFileName: '',
        description: '',
        customFileName: '',
        keyword: ''
      },
      leave: {
        title: '',
        url: '',
        uniqueFileName: '',
        description: '',
        customFileName: '',
        keyword: ''
      },
      parentId: '1444626431877'
    },
    {
      id: '1444636859178',
      key: 'RRI_675',
      displayName: 'Attending Physician\'s Statement',
      description: 'Statement from attending physician',
      generic: {
        title: '',
        url: '',
        uniqueFileName: '',
        description: '',
        customFileName: '',
        keyword: ''
      },
      onshore: {
        title: '',
        url: '',
        uniqueFileName: '',
        description: '',
        customFileName: '',
        keyword: ''
      },
      leave: {
        title: '',
        url: '',
        uniqueFileName: '',
        description: '',
        customFileName: '',
        keyword: ''
      },
      parentId: '1444626431877'
    },
    {
      id: '1444636838664',
      key: 'CD_639',
      displayName: 'Accidental Injury Questionnaire',
      description: 'This form is used when your disability may relate to an accident where you\'d receive payments from a third party. Examples include liability claims, Workers\' Compensation, and auto accidents.',
      generic: {
        title: 'lc-7495_accident_inj_quest-9',
        url: '/cm/files/1444636844427.pdf',
        uniqueFileName: 'lc-7495_accident_inj_quest-9',
        description: '',
        customFileName: '',
        keyword: ''
      },
      onshore: {
        title: 'osh_acc_inj_questionnaire_lc-7495',
        url: '/cm/files/1444636844531.pdf',
        uniqueFileName: 'osh_acc_inj_questionnaire_lc-7495',
        description: '',
        customFileName: '',
        keyword: ''
      },
      leave: {
        title: 'hart_lc-7495_accident_inj_quest-9',
        url: '/cm/files/1444636844635.pdf',
        uniqueFileName: 'hart_lc-7495_accident_inj_quest-9',
        description: '',
        customFileName: '',
        keyword: ''
      },
      parentId: '1444626431877'
    },
    {
      id: '1444636839303',
      key: 'CD_687',
      displayName: 'Medical Records',
      description: 'Medical records from a doctor\'s office, clinic, or hospital',
      generic: {
        title: '',
        url: '',
        uniqueFileName: '',
        description: '',
        customFileName: '',
        keyword: ''
      },
      onshore: {
        title: '',
        url: '',
        uniqueFileName: '',
        description: '',
        customFileName: '',
        keyword: ''
      },
      leave: {
        title: '',
        url: '',
        uniqueFileName: '',
        description: '',
        customFileName: '',
        keyword: ''
      },
      parentId: '1444626431877'
    },
    {
      id: '1444636842112',
      key: 'RRI_620',
      displayName: 'Medical History Form',
      description: 'Use this form to list the doctors and/or hospitals you\'ve visited for evaluation and treatment for your disabling condition.',
      generic: {
        title: '',
        url: '',
        uniqueFileName: '',
        description: '',
        customFileName: '',
        keyword: ''
      },
      onshore: {
        title: '',
        url: '',
        uniqueFileName: '',
        description: '',
        customFileName: '',
        keyword: ''
      },
      leave: {
        title: '',
        url: '',
        uniqueFileName: '',
        description: '',
        customFileName: '',
        keyword: ''
      },
      parentId: '1444626431877'
    },
    {
      id: '1444636865690',
      key: 'RRI_733',
      displayName: 'Employment Confirmation',
      description: 'Confirmation of your date of hire or coverage effective date',
      generic: {
        title: '',
        url: '',
        uniqueFileName: '',
        description: '',
        customFileName: '',
        keyword: ''
      },
      onshore: {
        title: '',
        url: '',
        uniqueFileName: '',
        description: '',
        customFileName: '',
        keyword: ''
      },
      leave: {
        title: '',
        url: '',
        uniqueFileName: '',
        description: '',
        customFileName: '',
        keyword: ''
      },
      parentId: '1444626431877'
    },
    {
      id: '1444636843380',
      key: 'RRI_651',
      displayName: 'Kaiser Permanent HIPAA Authorization',
      description: 'Gives us permission to request documents, like medical records, for your claim file.',
      generic: {
        title: '',
        url: '',
        uniqueFileName: '',
        description: '',
        customFileName: '',
        keyword: ''
      },
      onshore: {
        title: '',
        url: '',
        uniqueFileName: '',
        description: '',
        customFileName: '',
        keyword: ''
      },
      leave: {
        title: '',
        url: '',
        uniqueFileName: '',
        description: '',
        customFileName: '',
        keyword: ''
      },
      parentId: '1444626431877'
    },
    {
      id: '1444636841992',
      key: 'RRI_616',
      displayName: 'Authorization Form',
      description: 'Gives us permission to request documents, like medical records, for your claim file',
      generic: {
        title: '',
        url: '',
        uniqueFileName: '',
        description: '',
        customFileName: '',
        keyword: ''
      },
      onshore: {
        title: '',
        url: '',
        uniqueFileName: '',
        description: '',
        customFileName: '',
        keyword: ''
      },
      leave: {
        title: '',
        url: '',
        uniqueFileName: '',
        description: '',
        customFileName: '',
        keyword: ''
      },
      parentId: '1444626431877'
    },
    {
      id: '1444636665519',
      key: 'CD_624',
      displayName: 'Workers Compensation Denial Letter',
      description: 'If you\'re receiving Workers\' Compensation, it may reduce the amount you\'re eligible to receive under The Hartford\'s plan. This letter explains what your Workers\' Compensation carrier will or will not not cover.',
      generic: {
        title: '',
        url: '',
        uniqueFileName: '',
        description: '',
        customFileName: '',
        keyword: ''
      },
      onshore: {
        title: '',
        url: '',
        uniqueFileName: '',
        description: '',
        customFileName: '',
        keyword: ''
      },
      leave: {
        title: '',
        url: '',
        uniqueFileName: '',
        description: '',
        customFileName: '',
        keyword: ''
      },
      parentId: '1444626431877'
    },
    {
      id: '1444636838939',
      key: 'CD_659',
      displayName: 'Retirement Pension Questionnaire Form',
      description: 'This form is used to verify the sources of pension income you\'re getting, or may be eligible to get in the future.',
      generic: {
        title: 'lc-7443_retire_pen_lc7443-8',
        url: '/cm/files/1444636847328.pdf',
        uniqueFileName: 'lc-7443_retire_pen_lc7443-8',
        description: '',
        customFileName: '',
        keyword: ''
      },
      onshore: {
        title: 'osh_rtrmnt_pen_quest_lc-7443',
        url: '/cm/files/1444636847608.pdf',
        uniqueFileName: 'osh_rtrmnt_pen_quest_lc-7443',
        description: '',
        customFileName: '',
        keyword: ''
      },
      leave: {
        title: 'hart_pens_quest_lc7474aso_8',
        url: '/cm/files/1444636847736.pdf',
        uniqueFileName: 'hart_pens_quest_lc7474aso_8',
        description: '',
        customFileName: '',
        keyword: ''
      },
      parentId: '1444626431877'
    },
    {
      id: '1444636840601',
      key: 'CD_730',
      displayName: 'Wage Details',
      description: 'These are "offsets", or alternate sources of income. If you receive any of these while you\'re on disability, it may reduce the amount you\'re eligible to receive under The Hartford\'s plan.',
      generic: {
        title: '',
        url: '',
        uniqueFileName: '',
        description: '',
        customFileName: '',
        keyword: ''
      },
      onshore: {
        title: '',
        url: '',
        uniqueFileName: '',
        description: '',
        customFileName: '',
        keyword: ''
      },
      leave: {
        title: '',
        url: '',
        uniqueFileName: '',
        description: '',
        customFileName: '',
        keyword: ''
      },
      parentId: '1444626431877'
    },
    {
      id: '1444636839703',
      key: 'CD_706',
      displayName: 'Doctor\'s Activity Level Report',
      description: 'Your doctor will use this to specify your physical abilities, restrictions, and limitations.',
      generic: {
        title: 'provider_activity_level_report_lc-7665',
        url: '/cm/files/1444636851967.pdf',
        uniqueFileName: 'provider_activity_level_report_lc-7665',
        description: '',
        customFileName: '',
        keyword: ''
      },
      onshore: {
        title: 'osh_prov_act_lv_rpt_lc-7665',
        url: '/cm/files/1444636852095.pdf',
        uniqueFileName: 'osh_prov_act_lv_rpt_lc-7665',
        description: '',
        customFileName: '',
        keyword: ''
      },
      leave: {
        title: 'hart_provider_activity_level_report_lc-7665',
        url: '/cm/files/1444636852223.pdf',
        uniqueFileName: 'hart_provider_activity_level_report_lc-7665',
        description: '',
        customFileName: '',
        keyword: ''
      },
      parentId: '1444626431877'
    },
    {
      id: '1444636668442',
      key: 'CD_635',
      displayName: 'Income Benefits Questionnaire',
      description: 'This is your portion of the Long Term Disability application.',
      generic: {
        title: 'lc7117_ltd_incben_quest_fi26',
        url: '/cm/files/1444636668625.pdf',
        uniqueFileName: 'lc7117_ltd_incben_quest_fi26',
        description: '',
        customFileName: '',
        keyword: ''
      },
      onshore: {
        title: 'osh_ltd_ibq_lc-7117',
        url: '/cm/files/1444636668745.pdf',
        uniqueFileName: 'osh_ltd_ibq_lc-7117',
        description: '',
        customFileName: '',
        keyword: ''
      },
      leave: {
        title: 'hart_lc7126_ltd_incben_quest_aso_lc7126_21',
        url: '/cm/files/1444636669000.pdf',
        uniqueFileName: 'hart_lc7126_ltd_incben_quest_aso_lc7126_21',
        description: '',
        customFileName: '',
        keyword: ''
      },
      parentId: '1444626431877'
    },
    {
      id: '1444636841874',
      key: 'RRI_612',
      displayName: 'Authorization Form',
      description: 'Gives us permission to request documents, like medical records, for your claim file',
      generic: {
        title: '',
        url: '',
        uniqueFileName: '',
        description: '',
        customFileName: '',
        keyword: ''
      },
      onshore: {
        title: '',
        url: '',
        uniqueFileName: '',
        description: '',
        customFileName: '',
        keyword: ''
      },
      leave: {
        title: '',
        url: '',
        uniqueFileName: '',
        description: '',
        customFileName: '',
        keyword: ''
      },
      parentId: '1444626431877'
    },
    {
      id: '1444636839177',
      key: 'CD_675',
      displayName: 'Attending Physician\'s Statement',
      description: 'Statement from attending physician',
      generic: {
        title: 'aps_initial_report_mental_health_lc-7592_10',
        url: '/cm/files/1444636849751.pdf',
        uniqueFileName: 'aps_initial_report_mental_health_lc-7592_10',
        description: '',
        customFileName: '',
        keyword: ''
      },
      onshore: {
        title: 'osh_aps_init_rpt_mh_lc-7592',
        url: '/cm/files/1444636850042.pdf',
        uniqueFileName: 'osh_aps_init_rpt_mh_lc-7592',
        description: '',
        customFileName: '',
        keyword: ''
      },
      leave: {
        title: 'hart_aps_initial_report_mental_health_lc-7592_9',
        url: '/cm/files/1444636850178.pdf',
        uniqueFileName: 'hart_aps_initial_report_mental_health_lc-7592_9',
        description: '',
        customFileName: '',
        keyword: ''
      },
      parentId: '1444626431877'
    },
    {
      id: '1444636842768',
      key: 'RRI_639',
      displayName: 'Accidental Injury Questionnaire',
      description: 'This form is used when your disability may relate to an accident where you\'d receive payments from a third party. Examples include liability claims, Workers\' Compensation, and auto accidents.',
      generic: {
        title: '',
        url: '',
        uniqueFileName: '',
        description: '',
        customFileName: '',
        keyword: ''
      },
      onshore: {
        title: '',
        url: '',
        uniqueFileName: '',
        description: '',
        customFileName: '',
        keyword: ''
      },
      leave: {
        title: '',
        url: '',
        uniqueFileName: '',
        description: '',
        customFileName: '',
        keyword: ''
      },
      parentId: '1444626431877'
    },
    {
      id: '1444636859452',
      key: 'RRI_679',
      displayName: 'Attending Physician\'s Statement',
      description: 'Statement from attending physician',
      generic: {
        title: '',
        url: '',
        uniqueFileName: '',
        description: '',
        customFileName: '',
        keyword: ''
      },
      onshore: {
        title: '',
        url: '',
        uniqueFileName: '',
        description: '',
        customFileName: '',
        keyword: ''
      },
      leave: {
        title: '',
        url: '',
        uniqueFileName: '',
        description: '',
        customFileName: '',
        keyword: ''
      },
      parentId: '1444626431877'
    },
    {
      id: '1444636859847',
      key: 'RRI_687',
      displayName: 'Medical Records',
      description: 'Medical records from a doctor\'s office, clinic, or hospital',
      generic: {
        title: '',
        url: '',
        uniqueFileName: '',
        description: '',
        customFileName: '',
        keyword: ''
      },
      onshore: {
        title: '',
        url: '',
        uniqueFileName: '',
        description: '',
        customFileName: '',
        keyword: ''
      },
      leave: {
        title: '',
        url: '',
        uniqueFileName: '',
        description: '',
        customFileName: '',
        keyword: ''
      },
      parentId: '1444626431877'
    },
    {
      id: '1444636665130',
      key: 'CD_603',
      displayName: 'Cause of Disabling Condition',
      description: 'Information about how and where you became disabled.',
      generic: {
        title: '',
        url: '',
        uniqueFileName: '',
        description: '',
        customFileName: '',
        keyword: ''
      },
      onshore: {
        title: '',
        url: '',
        uniqueFileName: '',
        description: '',
        customFileName: '',
        keyword: ''
      },
      leave: {
        title: '',
        url: '',
        uniqueFileName: '',
        description: '',
        customFileName: '',
        keyword: ''
      },
      parentId: '1444626431877'
    },
    {
      id: '1444636842640',
      key: 'RRI_635',
      displayName: 'Income Benefits Questionnaire',
      description: 'This is your portion of the Long Term Disability application.',
      generic: {
        title: '',
        url: '',
        uniqueFileName: '',
        description: '',
        customFileName: '',
        keyword: ''
      },
      onshore: {
        title: '',
        url: '',
        uniqueFileName: '',
        description: '',
        customFileName: '',
        keyword: ''
      },
      leave: {
        title: '',
        url: '',
        uniqueFileName: '',
        description: '',
        customFileName: '',
        keyword: ''
      },
      parentId: '1444626431877'
    },
    {
      id: '1444636842898',
      key: 'RRI_643',
      displayName: 'Authorization to Obtain and Disclose Information',
      description: 'Gives us permission to request documents, like medical records, for your claim file',
      generic: {
        title: '',
        url: '',
        uniqueFileName: '',
        description: '',
        customFileName: '',
        keyword: ''
      },
      onshore: {
        title: '',
        url: '',
        uniqueFileName: '',
        description: '',
        customFileName: '',
        keyword: ''
      },
      leave: {
        title: '',
        url: '',
        uniqueFileName: '',
        description: '',
        customFileName: '',
        keyword: ''
      },
      parentId: '1444626431877'
    },
    {
      id: '1444636841758',
      key: 'RRI_609',
      displayName: 'Doctor’s Contact Information',
      description: 'Your doctor\'s contact information',
      generic: {
        title: '',
        url: '',
        uniqueFileName: '',
        description: '',
        customFileName: '',
        keyword: ''
      },
      onshore: {
        title: '',
        url: '',
        uniqueFileName: '',
        description: '',
        customFileName: '',
        keyword: ''
      },
      leave: {
        title: '',
        url: '',
        uniqueFileName: '',
        description: '',
        customFileName: '',
        keyword: ''
      },
      parentId: '1444626431877'
    },
    {
      id: '1444636840083',
      key: 'CD_718',
      displayName: 'Eligibility Confirmation',
      description: 'Confirmation from your employer that you\'re enrolled for STD coverage, as well as some basic information about things like your coverage dates, job description, and last day of work',
      generic: {
        title: '',
        url: '/cm/files/1444636667242.pdf',
        uniqueFileName: '',
        description: '',
        customFileName: '',
        keyword: ''
      },
      onshore: {
        title: '',
        url: '',
        uniqueFileName: '',
        description: '',
        customFileName: '',
        keyword: ''
      },
      leave: {
        title: '',
        url: '',
        uniqueFileName: '',
        description: '',
        customFileName: '',
        keyword: ''
      },
      parentId: '1444626431877'
    },
    {
      id: '1444636866081',
      key: 'RRI_739',
      displayName: 'Physical Demands Analysis Form',
      description: 'Information about the physical demands of your job, like how much time you spend sitting vs. standing at work, or whether you need to lift heavy objects',
      generic: {
        title: '',
        url: '',
        uniqueFileName: '',
        description: '',
        customFileName: '',
        keyword: ''
      },
      onshore: {
        title: '',
        url: '',
        uniqueFileName: '',
        description: '',
        customFileName: '',
        keyword: ''
      },
      leave: {
        title: '',
        url: '',
        uniqueFileName: '',
        description: '',
        customFileName: '',
        keyword: ''
      },
      parentId: '1444626431877'
    },
    {
      id: '1444636864692',
      key: 'RRI_724',
      displayName: 'Gap Information',
      description: 'Information about how you were covered, if there was some time between your last day of work and the start date of your disability',
      generic: {
        title: '',
        url: '',
        uniqueFileName: '',
        description: '',
        customFileName: '',
        keyword: ''
      },
      onshore: {
        title: '',
        url: '',
        uniqueFileName: '',
        description: '',
        customFileName: '',
        keyword: ''
      },
      leave: {
        title: '',
        url: '',
        uniqueFileName: '',
        description: '',
        customFileName: '',
        keyword: ''
      },
      parentId: '1444626431877'
    },
    {
      id: '1444636860753',
      key: 'RRI_693',
      displayName: 'Clarification Reply',
      description: 'We send this letter to your doctor when we need more information to understand their records or prior statements.',
      generic: {
        title: '',
        url: '',
        uniqueFileName: '',
        description: '',
        customFileName: '',
        keyword: ''
      },
      onshore: {
        title: '',
        url: '',
        uniqueFileName: '',
        description: '',
        customFileName: '',
        keyword: ''
      },
      leave: {
        title: '',
        url: '',
        uniqueFileName: '',
        description: '',
        customFileName: '',
        keyword: ''
      },
      parentId: '1444626431877'
    },
    {
      id: '1444636864442',
      key: 'RRI_714',
      displayName: 'Visual Functional Evaluation',
      description: 'Your doctor uses this to tell us about your visual capabilities and limitations.',
      generic: {
        title: '',
        url: '',
        uniqueFileName: '',
        description: '',
        customFileName: '',
        keyword: ''
      },
      onshore: {
        title: '',
        url: '',
        uniqueFileName: '',
        description: '',
        customFileName: '',
        keyword: ''
      },
      leave: {
        title: '',
        url: '',
        uniqueFileName: '',
        description: '',
        customFileName: '',
        keyword: ''
      },
      parentId: '1444626431877'
    },
    {
      id: '1444636839457',
      key: 'CD_693',
      displayName: 'Clarification Reply',
      description: 'We send this letter to your doctor when we need more information to understand their records or prior statements.',
      generic: {
        title: '',
        url: '',
        uniqueFileName: '',
        description: '',
        customFileName: '',
        keyword: ''
      },
      onshore: {
        title: '',
        url: '',
        uniqueFileName: '',
        description: '',
        customFileName: '',
        keyword: ''
      },
      leave: {
        title: '',
        url: '',
        uniqueFileName: '',
        description: '',
        customFileName: '',
        keyword: ''
      },
      parentId: '1444626431877'
    },
    {
      id: '1444636839069',
      key: 'CD_663',
      displayName: 'VA Authorization Request to Release Medical Records',
      description: 'Gives us permission to request documents, like your medical records, from Veteran\'s Administration hospitals and clinics.',
      generic: {
        title: 'vaauth',
        url: '/cm/files/1444636848001.pdf',
        uniqueFileName: 'vaauth',
        description: '',
        customFileName: '',
        keyword: ''
      },
      onshore: {
        title: 'vaauth (1)',
        url: '/cm/files/1444636848117.pdf',
        uniqueFileName: 'vaauth (1)',
        description: '',
        customFileName: '',
        keyword: ''
      },
      leave: {
        title: 'vaauth (2)',
        url: '/cm/files/1444636848233.pdf',
        uniqueFileName: 'vaauth (2)',
        description: '',
        customFileName: '',
        keyword: ''
      },
      parentId: '1444626431877'
    },
    {
      id: '1444636838697',
      key: 'CD_643',
      displayName: 'Authorization to Obtain and Disclose Information',
      description: 'Gives us permission to request documents, like medical records, for your claim file',
      generic: {
        title: 'auth_questionnaire_lc7411_3',
        url: '/cm/files/1444636845334.pdf',
        uniqueFileName: 'auth_questionnaire_lc7411_3',
        description: '',
        customFileName: '',
        keyword: ''
      },
      onshore: {
        title: 'osh_auth_ob-disc_ltd-std_lc-7411',
        url: '/cm/files/1444636845440.pdf',
        uniqueFileName: 'osh_auth_ob-disc_ltd-std_lc-7411',
        description: '',
        customFileName: '',
        keyword: ''
      },
      leave: {
        title: 'hart_auth_questionnaire_lc7411_3',
        url: '/cm/files/1444636845546.pdf',
        uniqueFileName: 'hart_auth_questionnaire_lc7411_3',
        description: '',
        customFileName: '',
        keyword: ''
      },
      parentId: '1444626431877'
    },
    {
      id: '1444636843030',
      key: 'RRI_647',
      displayName: 'Check-In Questionnaire',
      description: 'Allows us to collect periodic information from you about your condition',
      generic: {
        title: '',
        url: '',
        uniqueFileName: '',
        description: '',
        customFileName: '',
        keyword: ''
      },
      onshore: {
        title: '',
        url: '',
        uniqueFileName: '',
        description: '',
        customFileName: '',
        keyword: ''
      },
      leave: {
        title: '',
        url: '',
        uniqueFileName: '',
        description: '',
        customFileName: '',
        keyword: ''
      },
      parentId: '1444626431877'
    },
    {
      id: '1444636665419',
      key: 'CD_612',
      displayName: 'Authorization Form',
      description: 'Gives us permission to request documents, like medical records, for your claim file',
      generic: {
        title: 'hipaa_fi_auth_508_lc7360_3',
        url: '/cm/files/1444636665745.pdf',
        uniqueFileName: 'hipaa_fi_auth_508_lc7360_3',
        description: '',
        customFileName: '',
        keyword: ''
      },
      onshore: {
        title: 'osh_hipaa_auth_lc-7360',
        url: '/cm/files/1444636665868.pdf',
        uniqueFileName: 'osh_hipaa_auth_lc-7360',
        description: '',
        customFileName: '',
        keyword: ''
      },
      leave: {
        title: '',
        url: '',
        uniqueFileName: '',
        description: '',
        customFileName: '',
        keyword: ''
      },
      parentId: '1444626431877'
    },
    {
      id: '1444636841644',
      key: 'RRI_606',
      displayName: 'Gap Information',
      description: 'Information about how you were covered, if there was some time between your last day of work and the start date of your disability.',
      generic: {
        title: '',
        url: '',
        uniqueFileName: '',
        description: '',
        customFileName: '',
        keyword: ''
      },
      onshore: {
        title: '',
        url: '',
        uniqueFileName: '',
        description: '',
        customFileName: '',
        keyword: ''
      },
      leave: {
        title: '',
        url: '',
        uniqueFileName: '',
        description: '',
        customFileName: '',
        keyword: ''
      },
      parentId: '1444626431877'
    },
    {
      id: '1444636864207',
      key: 'RRI_710',
      displayName: 'Doctor\'s Statement: Physical Abilities',
      description: 'Your doctor will use this to specify your physical abilities, restrictions, and limitations.',
      generic: {
        title: '',
        url: '',
        uniqueFileName: '',
        description: '',
        customFileName: '',
        keyword: ''
      },
      onshore: {
        title: '',
        url: '',
        uniqueFileName: '',
        description: '',
        customFileName: '',
        keyword: ''
      },
      leave: {
        title: '',
        url: '',
        uniqueFileName: '',
        description: '',
        customFileName: '',
        keyword: ''
      },
      parentId: '1444626431877'
    },
    {
      id: '1444636840365',
      key: 'CD_727',
      displayName: 'Hours Worked Confirmation',
      description: 'Confirmation of the hours you worked on your last day (i.e. time cards)',
      generic: {
        title: '',
        url: '',
        uniqueFileName: '',
        description: '',
        customFileName: '',
        keyword: ''
      },
      onshore: {
        title: '',
        url: '',
        uniqueFileName: '',
        description: '',
        customFileName: '',
        keyword: ''
      },
      leave: {
        title: '',
        url: '',
        uniqueFileName: '',
        description: '',
        customFileName: '',
        keyword: ''
      },
      parentId: '1444626431877'
    },
    {
      id: '1444636839217',
      key: 'CD_679',
      displayName: 'Attending Physician\'s Statement',
      description: 'Statement from attending physician',
      generic: {
        title: 'aps_progress_report_lc-7137_10',
        url: '/cm/files/1444636850719.pdf',
        uniqueFileName: 'aps_progress_report_lc-7137_10',
        description: '',
        customFileName: '',
        keyword: ''
      },
      onshore: {
        title: 'osh_aps_prgs_rpt_lc-7137',
        url: '/cm/files/1444636850844.pdf',
        uniqueFileName: 'osh_aps_prgs_rpt_lc-7137',
        description: '',
        customFileName: '',
        keyword: ''
      },
      leave: {
        title: 'hart_aps_progress_report_lc-7137_6',
        url: '/cm/files/1444636850968.pdf',
        uniqueFileName: 'hart_aps_progress_report_lc-7137_6',
        description: '',
        customFileName: '',
        keyword: ''
      },
      parentId: '1444626431877'
    },
    {
      id: '1444636859749',
      key: 'RRI_683',
      displayName: 'Attending Physician\'s Statement',
      description: 'Statement from attending physician',
      generic: {
        title: '',
        url: '',
        uniqueFileName: '',
        description: '',
        customFileName: '',
        keyword: ''
      },
      onshore: {
        title: '',
        url: '',
        uniqueFileName: '',
        description: '',
        customFileName: '',
        keyword: ''
      },
      leave: {
        title: '',
        url: '',
        uniqueFileName: '',
        description: '',
        customFileName: '',
        keyword: ''
      },
      parentId: '1444626431877'
    },
    {
      id: '1444636843826',
      key: 'RRI_659',
      displayName: 'Retirement Pension Questionnaire Form',
      description: 'This form is used to verify the sources of pension income you\'re getting, or may be eligible to get in the future.',
      generic: {
        title: '',
        url: '',
        uniqueFileName: '',
        description: '',
        customFileName: '',
        keyword: ''
      },
      onshore: {
        title: '',
        url: '',
        uniqueFileName: '',
        description: '',
        customFileName: '',
        keyword: ''
      },
      leave: {
        title: '',
        url: '',
        uniqueFileName: '',
        description: '',
        customFileName: '',
        keyword: ''
      },
      parentId: '1444626431877'
    },
    {
      id: '1444636843688',
      key: 'RRI_655',
      displayName: 'LTD Payment/Reimbursement Agreement for Social Security Benefits',
      description: 'Allows you to specify how you want us to handle your LTD payment when you might be able to receive income benefits from Social Security. You can choose to get paid in full up front and potentially create an overpayment that you\'d have to pay us back later. Or, we can estimate the amount you\'ll get from other sources and reduce payments accordingly.',
      generic: {
        title: '',
        url: '',
        uniqueFileName: '',
        description: '',
        customFileName: '',
        keyword: ''
      },
      onshore: {
        title: '',
        url: '',
        uniqueFileName: '',
        description: '',
        customFileName: '',
        keyword: ''
      },
      leave: {
        title: '',
        url: '',
        uniqueFileName: '',
        description: '',
        customFileName: '',
        keyword: ''
      },
      parentId: '1444626431877'
    },
    {
      id: '1444636863962',
      key: 'RRI_703',
      displayName: 'Doctor\'s Note',
      description: 'A note from your doctor to support the extra time that you\'ve requested',
      generic: {
        title: '',
        url: '',
        uniqueFileName: '',
        description: '',
        customFileName: '',
        keyword: ''
      },
      onshore: {
        title: '',
        url: '',
        uniqueFileName: '',
        description: '',
        customFileName: '',
        keyword: ''
      },
      leave: {
        title: '',
        url: '',
        uniqueFileName: '',
        description: '',
        customFileName: '',
        keyword: ''
      },
      parentId: '1444626431877'
    },
    {
      id: '1444636841402',
      key: 'RRI_603',
      displayName: 'Cause of Disabling Condition',
      description: 'Information about how and where you became disabled.',
      generic: {
        title: '',
        url: '',
        uniqueFileName: '',
        description: '',
        customFileName: '',
        keyword: ''
      },
      onshore: {
        title: '',
        url: '',
        uniqueFileName: '',
        description: '',
        customFileName: '',
        keyword: ''
      },
      leave: {
        title: '',
        url: '',
        uniqueFileName: '',
        description: '',
        customFileName: '',
        keyword: ''
      },
      parentId: '1444626431877'
    },
    {
      id: '1444636864607',
      key: 'RRI_721',
      displayName: 'Eligibility Confirmation',
      description: 'Confirmation from your employer that you\'re enrolled for STD coverage, as well as some basic information about things like your coverage dates, job description, and last day of work',
      generic: {
        title: '',
        url: '/cm/files/1444636667242.pdf',
        uniqueFileName: '',
        description: '',
        customFileName: '',
        keyword: ''
      },
      onshore: {
        title: '',
        url: '',
        uniqueFileName: '',
        description: '',
        customFileName: '',
        keyword: ''
      },
      leave: {
        title: '',
        url: '',
        uniqueFileName: '',
        description: '',
        customFileName: '',
        keyword: ''
      },
      parentId: '1444626431877'
    },
    {
      id: '1444636840907',
      key: 'CD_739',
      displayName: 'Physical Demands Analysis Form',
      description: 'Information about the physical demands of your job, like how much time you spend sitting vs. standing at work, or whether you need to lift heavy objects',
      generic: {
        title: '',
        url: '',
        uniqueFileName: '',
        description: '',
        customFileName: '',
        keyword: ''
      },
      onshore: {
        title: '',
        url: '',
        uniqueFileName: '',
        description: '',
        customFileName: '',
        keyword: ''
      },
      leave: {
        title: '',
        url: '',
        uniqueFileName: '',
        description: '',
        customFileName: '',
        keyword: ''
      },
      parentId: '1444626431877'
    },
    {
      id: '1444636838721',
      key: 'CD_647',
      displayName: 'Check-In Questionnaire',
      description: 'Allows us to collect periodic information from you about your condition',
      generic: {
        title: 'lc4699_clmnt_quest_fi_29',
        url: '/cm/files/1444636845951.pdf',
        uniqueFileName: 'lc4699_clmnt_quest_fi_29',
        description: '',
        customFileName: '',
        keyword: ''
      },
      onshore: {
        title: 'osh_clmt_quest_lc-4699',
        url: '/cm/files/1444636846059.pdf',
        uniqueFileName: 'osh_clmt_quest_lc-4699',
        description: '',
        customFileName: '',
        keyword: ''
      },
      leave: {
        title: 'hart_lc7306_clmnt_quest_aso_lc7306_29',
        url: '/cm/files/1444636846167.pdf',
        uniqueFileName: 'hart_lc7306_clmnt_quest_aso_lc7306_29',
        description: '',
        customFileName: '',
        keyword: ''
      },
      parentId: '1444626431877'
    },
    {
      id: '1444636841013',
      key: 'CD_742',
      displayName: 'Job Description',
      description: 'Job description for your role',
      generic: {
        title: '',
        url: '',
        uniqueFileName: '',
        description: '',
        customFileName: '',
        keyword: ''
      },
      onshore: {
        title: '',
        url: '',
        uniqueFileName: '',
        description: '',
        customFileName: '',
        keyword: ''
      },
      leave: {
        title: '',
        url: '',
        uniqueFileName: '',
        description: '',
        customFileName: '',
        keyword: ''
      },
      parentId: '1444626431877'
    },
    {
      id: '1444636861150',
      key: 'RRI_696',
      displayName: 'Restrictions & Limitations',
      description: 'Doctor recommendations for your restrictions and limitations',
      generic: {
        title: '',
        url: '',
        uniqueFileName: '',
        description: '',
        customFileName: '',
        keyword: ''
      },
      onshore: {
        title: '',
        url: '',
        uniqueFileName: '',
        description: '',
        customFileName: '',
        keyword: ''
      },
      leave: {
        title: '',
        url: '',
        uniqueFileName: '',
        description: '',
        customFileName: '',
        keyword: ''
      },
      parentId: '1444626431877'
    },
    {
      id: '1444636839619',
      key: 'CD_703',
      displayName: 'Doctor\'s Note',
      description: 'A note from your doctor to support the extra time that you\'ve requested',
      generic: {
        title: '',
        url: '',
        uniqueFileName: '',
        description: '',
        customFileName: '',
        keyword: ''
      },
      onshore: {
        title: '',
        url: '',
        uniqueFileName: '',
        description: '',
        customFileName: '',
        keyword: ''
      },
      leave: {
        title: '',
        url: '',
        uniqueFileName: '',
        description: '',
        customFileName: '',
        keyword: ''
      },
      parentId: '1444626431877'
    },
    {
      id: '1444636839103',
      key: 'CD_667',
      displayName: 'Work and Educational History',
      description: 'Use this form to provide information about your work experience and personal background so we can assess what other occupations you might be capable of performing.',
      generic: {
        title: 'work_ed_hx_lc6889_15',
        url: '/cm/files/1444636848511.pdf',
        uniqueFileName: 'work_ed_hx_lc6889_15',
        description: '',
        customFileName: '',
        keyword: ''
      },
      onshore: {
        title: 'osh_wk_ed_hx_lc-6889',
        url: '/cm/files/1444636848629.pdf',
        uniqueFileName: 'osh_wk_ed_hx_lc-6889',
        description: '',
        customFileName: '',
        keyword: ''
      },
      leave: {
        title: 'hart_work_ed_hx_lc6889_15',
        url: '/cm/files/1444636848943.pdf',
        uniqueFileName: 'hart_work_ed_hx_lc6889_15',
        description: '',
        customFileName: '',
        keyword: ''
      },
      parentId: '1444626431877'
    },
    {
      id: '1444636865881',
      key: 'RRI_736',
      displayName: 'Last Date Worked Confirmation',
      description: 'Confirmation of the last day you worked',
      generic: {
        title: '',
        url: '',
        uniqueFileName: '',
        description: '',
        customFileName: '',
        keyword: ''
      },
      onshore: {
        title: '',
        url: '',
        uniqueFileName: '',
        description: '',
        customFileName: '',
        keyword: ''
      },
      leave: {
        title: '',
        url: '',
        uniqueFileName: '',
        description: '',
        customFileName: '',
        keyword: ''
      },
      parentId: '1444626431877'
    },
    {
      id: '1444636843966',
      key: 'RRI_663',
      displayName: 'VA Authorization Request to Release Medical Records',
      description: 'Gives us permission to request documents, like your medical records, from Veteran\'s Administration hospitals and clinics.',
      generic: {
        title: '',
        url: '',
        uniqueFileName: '',
        description: '',
        customFileName: '',
        keyword: ''
      },
      onshore: {
        title: '',
        url: '',
        uniqueFileName: '',
        description: '',
        customFileName: '',
        keyword: ''
      },
      leave: {
        title: '',
        url: '',
        uniqueFileName: '',
        description: '',
        customFileName: '',
        keyword: ''
      },
      parentId: '1444626431877'
    },
    {
      id: '1444636664944',
      key: 'CD_600',
      displayName: 'Other Income Benefits',
      description: 'Information about other benefits you might have (state benefits, worker\'s compensation, no-fault benefits)',
      generic: {
        title: '',
        url: '',
        uniqueFileName: '',
        description: '',
        customFileName: '',
        keyword: ''
      },
      onshore: {
        title: '',
        url: '',
        uniqueFileName: '',
        description: '',
        customFileName: '',
        keyword: ''
      },
      leave: {
        title: '',
        url: '',
        uniqueFileName: '',
        description: '',
        customFileName: '',
        keyword: ''
      },
      parentId: '1444626431877'
    },
    {
      id: '1444636839759',
      key: 'CD_710',
      displayName: 'Doctor\'s Statement: Physical Abilities',
      description: 'Your doctor will use this to specify your physical abilities, restrictions, and limitations.',
      generic: {
        title: 'provider_statement_physical_abilities_pce_lc-6766',
        url: '/cm/files/1444636852711.pdf',
        uniqueFileName: 'provider_statement_physical_abilities_pce_lc-6766',
        description: '',
        customFileName: '',
        keyword: ''
      },
      onshore: {
        title: 'osh_prov_stmnt_phys_abs_lc-6766_pce',
        url: '/cm/files/1444636852841.pdf',
        uniqueFileName: 'osh_prov_stmnt_phys_abs_lc-6766_pce',
        description: '',
        customFileName: '',
        keyword: ''
      },
      leave: {
        title: 'hart_provider_statement_physical_abilities_pce_lc-6766',
        url: '/cm/files/1444636852971.pdf',
        uniqueFileName: 'hart_provider_statement_physical_abilities_pce_lc-6766',
        description: '',
        customFileName: '',
        keyword: ''
      },
      parentId: '1444626431877'
    },
    {
      id: '1444636840269',
      key: 'CD_724',
      displayName: 'Gap Information',
      description: 'Information about how you were covered, if there was some time between your last day of work and the start date of your disability',
      generic: {
        title: '',
        url: '',
        uniqueFileName: '',
        description: '',
        customFileName: '',
        keyword: ''
      },
      onshore: {
        title: '',
        url: '',
        uniqueFileName: '',
        description: '',
        customFileName: '',
        keyword: ''
      },
      leave: {
        title: '',
        url: '',
        uniqueFileName: '',
        description: '',
        customFileName: '',
        keyword: ''
      },
      parentId: '1444626431877'
    },
    {
      id: '1444636864878',
      key: 'RRI_727',
      displayName: 'Hours Worked Confirmation',
      description: 'Confirmation of the hours you worked on your last day (i.e. time cards)',
      generic: {
        title: '',
        url: '',
        uniqueFileName: '',
        description: '',
        customFileName: '',
        keyword: ''
      },
      onshore: {
        title: '',
        url: '',
        uniqueFileName: '',
        description: '',
        customFileName: '',
        keyword: ''
      },
      leave: {
        title: '',
        url: '',
        uniqueFileName: '',
        description: '',
        customFileName: '',
        keyword: ''
      },
      parentId: '1444626431877'
    },
    {
      id: '1444636665183',
      key: 'CD_606',
      displayName: 'Gap Information',
      description: 'Information about how you were covered, if there was some time between your last day of work and the start date of your disability.',
      generic: {
        title: '',
        url: '',
        uniqueFileName: '',
        description: '',
        customFileName: '',
        keyword: ''
      },
      onshore: {
        title: '',
        url: '',
        uniqueFileName: '',
        description: '',
        customFileName: '',
        keyword: ''
      },
      leave: {
        title: '',
        url: '',
        uniqueFileName: '',
        description: '',
        customFileName: '',
        keyword: ''
      },
      parentId: '1444626431877'
    }
    ,
    {
      id: '1444636665184',
      key: 'CD_8699',
      displayName: 'Qualifying Military Leave Application',
      description: 'We need the following additional documents from you.',
      generic: {
        title: '',
        url: '',
        uniqueFileName: '',
        description: '',
        customFileName: '',
        keyword: ''
      },
      onshore: {
        title: '',
        url: '',
        uniqueFileName: '',
        description: '',
        customFileName: '',
        keyword: ''
      },
      leave: {
        title: '',
        url: '',
        uniqueFileName: '',
        description: '',
        customFileName: '',
        keyword: ''
      },
      parentId: '1444626431877'
    }
  ]
};
