
import {Observable, of} from 'rxjs';

import {catchError, map} from 'rxjs/operators';
/**
 * Created by FA81445 on 7/31/2017.
 */
import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';

import {env} from '../../../environments/environment-loader';


import {User} from '../models/user.model';
import {MessageRibbonService} from './message-ribbon.service';
import {ResponseErrorHandlerService} from '../services/response-error-handler.service';
import {AppLabelConstants} from '../constants/appLabel.constants';


/** Added By : AC12394
 *  Date : 11/01/2017
 *  Purpose : We need to mock the PEOPLE as per the environment
 */


@Injectable()
export class PersonService {

  data: object;
  pageId: string;

  constructor(private httpClient: HttpClient,
              private messageRibbonService: MessageRibbonService,
              private errorHandler: ResponseErrorHandlerService, ) {
  }
  /***********************
   * Date : 9/10/17
   * Auther :Ac12212
   * Purpose :To check if Active Indiactor is 1 i.e user has opted for text notificatiosn
   * Econsent serviec integration
   */

  getUserProfile(businessGroupId, insuredID, caseID) {
      if (insuredID && caseID) {
        const request: string = JSON.stringify(
          {
            insuredId: insuredID,
            businessGroupID: businessGroupId,
            caseId: caseID
          }
        );
        return this.httpClient.post(env.gbEconsent.getUserProfileURL, request,
          {
            headers: new HttpHeaders()
              .set(AppLabelConstants.CONTENT_TYPE_KEY, AppLabelConstants.APPLICATION_KEY),
          }).pipe(
       map(
          (response: Response) => {
            if (!this.errorHandler.isServiceResponseHasError(response)) {
              return response;
            } else {
              throw response;
            }
          }
        ),
       catchError((error: any) => this.errorHandler.handleError(error)), );
      } else {
        return of({});
      }
  }

  /***********************
   * Date : 9/10/17
   * Auther :Ac12212
   * Purpose :To check if site wide documnet has newer version available
   */

  UpdateSiteWideTC(partyrefId) {

    const request: string = JSON.stringify({
      partyRefId: partyrefId});

    return this.httpClient.post(env.gbMudi.getUpdateSiteWideTCURL, request,
      {
        headers: new HttpHeaders()
          .set(AppLabelConstants.CONTENT_TYPE_KEY, AppLabelConstants.APPLICATION_KEY)
      }).pipe(
      map(
        (response: Response) => {
          if (!this.errorHandler.isServiceResponseHasError(response)) {
            return response;
          } else {
            throw response;
          }
        }
      ), catchError((error: any) => this.errorHandler.globalErrorHandler(error)), );
  }

  /***********************
   * Date : 9/10/17
   * Auther :Ac12212
   * Purpose :To check if texting documnet has newer version available
   */

  UpdateTextTC(partyrefId, notificationTermsCondAcceptDT) {
      const request: string = JSON.stringify({
        partyRefId: partyrefId,
        notificationTermsCondAcceptDt: notificationTermsCondAcceptDT
      });

      return this.httpClient.post(env.gbMudi.UpadateTextingTermsAndConditionsURL, request,
        {
          headers: new HttpHeaders()
            .set(AppLabelConstants.CONTENT_TYPE_KEY, AppLabelConstants.APPLICATION_KEY)
        }).pipe(
        map(
        (response: Response) => {
          if (!this.errorHandler.isServiceResponseHasError(response)) {
            return response;
          } else {
            throw response;
          }
        }
      ), catchError((error: any) => this.errorHandler.globalErrorHandler(error)), );

  }
  /***********************
   * Date : 10/23/17
   * Auther :Ac12212
   * Purpose :To check if text TC  is outdated, MUDI service Integration
   */
  checkIfTextTCOutdated(partyrefId) {

      const request: string = JSON.stringify({
        partyRefId: partyrefId
      });

      return this.httpClient.post(env.gbMudi.verifyTextingTermsAndConditionsURL, request,
        {
          headers: new HttpHeaders()
            .set(AppLabelConstants.CONTENT_TYPE_KEY, AppLabelConstants.APPLICATION_KEY)
        }).pipe(
        map(
          (response: Response) => {
            if (!this.errorHandler.isServiceResponseHasError(response)) {
              return response;
            } else {
              throw response;
            }
          }
        ), catchError((error: any) => this.errorHandler.handleError(error)), );

  }

  /***********************
   * Date : 01/24/18
   * Author : DB
   * Purpose : Multiple CaseID User Stories Implementation
   */
  getUserCaseIdInfo(partyrefId): Observable<any> {

      const headers = new HttpHeaders().set('Content-Type', 'application/json');
      return this.httpClient.get(`${env.gbCase.getUserCasesURL}`, { headers }).pipe(
        map((response: Response) => {
            if (!this.errorHandler.isServiceResponseHasError(response)) {
              return response;
            } else {
              throw response;
            }
          }
        ), catchError((error: any) => this.errorHandler.handleError(error, true)), );
  }


  /***********************
   * Date : 01/24/18
   * Author : DB
   * Purpose : Multiple CaseID User Stories Implementation
   */
  setUserDefaultCaseId(defaultCaseID): Observable<object> {
      const request = JSON.stringify({
        defaultCaseId: defaultCaseID
      });
      const headers = new HttpHeaders().set(AppLabelConstants.CONTENT_TYPE_KEY, AppLabelConstants.APPLICATION_KEY);
      return this.httpClient.post(env.gbCase.setUserDefaultCaseIdURL, request, {headers});

  }

  /* Revised Methods for Person Service */
  getUserDetailsMudi(partyRefID: string): Observable<User> {
      const request: string = JSON.stringify(
        {
          partyRefId: partyRefID
        }
      );

      return this.httpClient.post(env.gbMudi.getUserDetailsURL, request,
        {
          headers: new HttpHeaders()
            .set(AppLabelConstants.CONTENT_TYPE_KEY, AppLabelConstants.APPLICATION_KEY)
        }).pipe(
        map(
          (response: User) => {
            if (!this.errorHandler.isServiceResponseHasError(response)) {
              return response;
            } else {
              throw response;
            }
          }
        ), catchError((error: any) => this.errorHandler.handleError(error, true)), );

  }

  getPreferredName(personId: string): Observable<any> {
    const header = {
      headers: new HttpHeaders()
        .set(AppLabelConstants.CONTENT_TYPE_KEY, AppLabelConstants.APPLICATION_KEY)
    };

    return this.httpClient.get(env.thaaWKABPortalService.getPreferredName + '&personId=' + personId , header).pipe(
      map(
        (response: Response) => {
          if (!this.errorHandler.isServiceResponseHasError(response)) {
            return response;
          } else {
            throw response;
          }
        }),
      catchError((error: any) => this.errorHandler.handleError(error)), );
  }


}
