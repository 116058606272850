
import {catchError, map} from 'rxjs/operators';
import {Injectable} from '@angular/core';

import {ResponseErrorHandlerService} from './response-error-handler.service';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {env} from '../../../environments/environment-loader';
import {Observable} from 'rxjs';
import {AppLabelConstants} from '../constants/appLabel.constants';

@Injectable()
export class ScheduledCallService {

  constructor( private errorHandler: ResponseErrorHandlerService,
               private httpClient: HttpClient) { }

  getScheduleStatus(personId: string): Observable<any> {
     return this.httpClient.get(env.thaaWKABPortalService.getScheduleStatusURL + '&personid=' + personId,
      {
        headers: new HttpHeaders()
          .set(AppLabelConstants.CONTENT_TYPE_KEY, AppLabelConstants.APPLICATION_KEY)
      }).pipe(
      map(
        (response: Response) => {
          if (!this.errorHandler.isServiceResponseHasError(response)) {
            return response;
          } else {
            throw response;
          }
        }
      ), catchError((error: any) => this.errorHandler.handleError(error)), );

  }

}
