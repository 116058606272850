export class INTAKE_ICONS {
  static STILL_PREGNANT_ICON = './assets/images/icons/GB_Pregnancy_Still_Pregnant.svg';
  static RECENTLY_DELIVERED_ICON = './assets/images/icons/GB_Pregnancy_Delivered.svg';
  static SOMETHINGELSE_HAPPENED_ICON = './assets/images/icons/GB_Pregnancy_SomethingElse.svg';
  static WHITE_X_CLOSE_SVG = './assets/images/icons/white-x close.svg';
  static EDIT_ICON = '../../assets/images/icons/Edit.svg';
  static EDIT_ICON_NEW = '../../assets/images/icons/Edit_New.png';
  static GREEN_CHECK_ICON = './assets/images/icons/green-check.svg';
  static CLOSE_HARTFORD_GRAY_ICON = './assets/images/icons/close-hartfordgray.svg';
  static WARNING_ICON = './assets/images/icons/Message-icon.svg';
  static CARET_RIGHT = './assets/images/icons/right-caret-blue.svg';
  static ADD_ICON = './assets/images/icons/add-icon.png';
  static DELETE_ICON = './assets/images/icons/delete-icon.png';
  static TOOLTIP_ICON = './assets/images/icons/Information.svg';
  static BACK_ICON = './assets/images/icons/icon-go-back.png';
  static BONDING_FINAL = './assets/images/icons/GB_MyBenefits_Bonding_final.svg';
  static TEXT_AND_EMAIL = './assets/images/icons/Text-and-Email.svg';
  static EMAIL_ONLY = './assets/images/icons/Email-only.svg';
  static PAPERLESS = './assets/images/icons/leaf-icon.png';
  static RECENTLY_DELIVERED_CONFIRMATION_ICON = './assets/images/icons/GB-Submission_Page.svg';
  static STILL_PREGNANT_CONFIRMATION_ICON = './assets/images/icons/GB-Submission_Page.svg';
  static INTAKE_SUCCESS = './assets/images/icons/successStatus.svg';
  static HARTFORD_LOGO = './assets/images/logos/hig-logo-small.jpg';
  static HARTFORD_LOGO_PRINT = './assets/images/logos/HIG.svg';
  static PAYMENT_SUCCESS_ICON = 'assets/images/icons/animated-payment-success-icon.svg';
  static PRINT_RECEIPT_ICON = 'assets/images/icons/print-icon.png';
  static SUCCESSCHECKMARK = './assets/images/icons/SuccessIcon.png';
  static CUSTOM_ERROR_ICON = './assets/images/icons/something-went-wrong.svg';

  // Edit Work Schedule cal examples
  static MULTI_WEEK_SCHEDULE_ICON = './assets/images/icons/Multi Week Schedule.svg';
  static WEEKLY_SCHEDULE_ICON = './assets/images/icons/Weekly Schedule.svg';
  static ROTATING_SCHEDULE_ICON = './assets/images/icons/Rotating Schedule Test2.svg';

}
