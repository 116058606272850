import {Component, Input, OnInit} from '@angular/core';
import { SafeHtml } from '@angular/platform-browser';
import {ElementControlComponent} from '../../sub-atomic/element-control/element-control.component';

@Component({
  selector: 'primary-checkbox',
  templateUrl: 'primary-checkbox.component.html',
  styleUrls: ['primary-checkbox.component.scss']
})
export class PrimaryCheckboxComponent extends ElementControlComponent implements OnInit {
  @Input() checkboxText: SafeHtml | string;
  @Input() inputId: string;
  @Input() checkboxDisabled: boolean;
  @Input() checkboxState: string;
  @Input() name: string;
  @Input() title: string = '';
  @Input() checkboxAriaChecked: string = '';

  ngOnInit() {
  }

  toggleCheck() {
    if (!this.checkboxDisabled) {
      //console.log('label click');
      this.checkboxState = (!this.checkboxState ? 'checked' : null);
    }
  }

  getCheckboxState() {
    return this.checkboxState;
  }

  checkboxAriaStatus() {
    return this.checkboxAriaChecked !== '' ? this.checkboxAriaChecked : this.checkboxState;
  }
}
