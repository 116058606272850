import {Component, Input} from '@angular/core';

@Component({
  selector: 'super-button',
  templateUrl: 'button.component.html',
  styleUrls: ['button.component.scss'],
})
export class ButtonComponent {

  @Input() buttonText: string;
  @Input() btnId: string;
  @Input() buttonDisabled = false;
  @Input() defaultWidth: boolean = false; /**pass true in case if the button is not able to accomodate long text
                                            as in for example "Return to Your Documents Page" Refer DE2911 **/
}
