/**
 * Created by AC12256 on 2/12/2018.
 */
import {MockPhysician} from './mock-physician';

export const MockRecentlyInfo = [
{
  recentlyInfoReqInfoDesc: 'HIPAA Fully Insured Provider Specific Authorization',
  recentlyInfoReqInfoCd: '31',
  displayValue: 'HIPAA Fully Insured Provider Specific',
  requestDate: '02/06/2018',
  detailsIndicator: false,
  listPhysicain: [MockPhysician[0] , MockPhysician[1]]
},
  {
    recentlyInfoReqInfoDesc: 'Job Description',
    recentlyInfoReqInfoCd: '31',
    displayValue: 'Job Description',
    requestDate: '02/06/2018',
    detailsIndicator: true,
    listPhysicain: [ MockPhysician[0] ]
  }];
