/**
 * Created by AC12396 on 4/4/2019.
 */
export const AACLAIMACTIVITY = {
  timelines: [{
    date: '02/26/2019',
    claimNumber: '772172',
    claimType: 'Short Term Disability Claim',
    milestone: 'We Sent a Letter',
    status: ''
  },
    {
      date: '01/19/2019',
      claimNumber: '772172',
      claimType: 'Short Term Disability Claim',
      milestone: 'We Sent a Letter',
      status: ''
    },
    {
      date: '12/24/2017',
      claimNumber: '772172',
      claimType: 'Short Term Disability Claim',
      milestone: 'Appeal Due Date--12/24/2017',
      status: ''
    },
    {
      date: '11/09/2017',
      claimNumber: '772172',
      claimType: 'Short Term Disability Claim',
      milestone: 'Appeal Received 11/09/2017',
      status: ''
    },
    {
      date: '03/29/2017',
      claimNumber: '772172',
      claimType: 'Short Term Disability Claim',
      milestone: 'Claim Status Start Date-03/10/2009  End Date-03/11/2009',
      status: ''
    },
    {
      date: '03/29/2017',
      claimNumber: '772172',
      claimType: 'Short Term Disability Claim',
      milestone: 'Claim Status Start Date-03/02/2009  End Date-03/09/2009',
      status: ''
    },
    {
      date: '05/17/2016',
      claimNumber: '772172',
      claimType: 'Short Term Disability Claim',
      milestone: 'We Received Information ',
      status: ''
    },
    {
      date: '05/17/2016',
      claimNumber: '772172',
      claimType: 'Short Term Disability Claim',
      milestone: 'We Received Information ',
      status: ''
    },
    {
      date: '05/17/2016',
      claimNumber: '772172',
      claimType: 'Short Term Disability Claim',
      milestone: 'We Received Information ',
      status: ''
    },
    {
      date: '05/17/2016',
      claimNumber: '772172',
      claimType: 'Short Term Disability Claim',
      milestone: 'We Received Information ',
      status: ''
    },
    {
      date: '05/17/2016',
      claimNumber: '772172',
      claimType: 'Short Term Disability Claim',
      milestone: 'We Received Information ',
      status: ''
    },
    {
      date: '05/17/2016',
      claimNumber: '772172',
      claimType: 'Short Term Disability Claim',
      milestone: 'We Received Information ',
      status: ''
    },
    {
      date: '05/17/2016',
      claimNumber: '772172',
      claimType: 'Short Term Disability Claim',
      milestone: 'We Received Information ',
      status: ''
    }
  ],
  pageInfo: {
    totalResults: '42',
    offSet: '0',
    itemsOnPage: '42',
    firstItemOnPage: '1',
    lastItemOnPage: '42',
    paging: [{
      offSet: '0',
      name: '1',
      current: 'true'
    }]
  },
  responseCode: '001',
  responseReason: 'Success'
}
