import {Component, ElementRef, Input, OnInit, ViewChild} from '@angular/core';
import {ElementControlComponent} from '../../../sub-atomic/element-control/element-control.component';

@Component({
  selector: 'primary-text-input',
  templateUrl: 'primary-text-input.component.html',
  styleUrls: ['primary-text-input.component.scss']
})
export class PrimaryTextInputComponent extends ElementControlComponent implements OnInit {

  @Input() inputString: string;
  @Input() inputPlaceholder: string;
  @Input() inputType: string;
  @Input() inputId: string;
  @Input() max: number;
  @Input() maxlength: string;
  @Input() minlength: string;
  @Input() labelName: string;
  @Input() inputValue = '';
  @Input() readOnlyCondition: boolean;
  @Input() disabled: boolean = false;
  @Input() inputSize?: string;
  @Input() ariaLabel: string;
  @Input() preRenderingLoad = false;
  @Input() title: string = '';
  @Input() textTransform?: string = 'none';
  @ViewChild('input', {static: false}) nativeElem: ElementRef;

  private passwordState = false;
    ngOnInit() {
    if (this.inputType === 'password') {
      this.passwordState = true;
    }
  }

  togglePassword() {
    if (this.inputValue) {
      this.nativeElem.nativeElement.setAttribute('type', 'text');
    }
  }

  getMouseUp() {
    if (this.inputValue) {
      this.nativeElem.nativeElement.setAttribute('type', 'password');
    }
  }
  disableSaveImage(e) {
    e.stopPropagation();
    e.preventDefault();
    return false;
  }
  getMask() {
    if (this.inputType === 'tel') {
      return {
        mask: ['(', /[1-9]/, /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/],
        guide: true,
        placeholderChar: '_',
        keepCharPositions: true
      }
    }
  }
  onSpaceEnter(event) {
    const e = event as KeyboardEvent;
    if (this.inputType === 'password') {
      e.preventDefault();
    }
  }
}
