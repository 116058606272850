import {GbBase} from './gb-base';

export class GbLogin extends GbBase {

  getUrls() {
    return {

    }
  }
}

