import {Component, Input, OnInit} from '@angular/core';

@Component({
  selector: 'app-primary-contact-label',
  templateUrl: 'primary-contact-label.component.html',
  styleUrls: ['primary-contact-label.component.scss']
})
export class PrimaryContactLabelComponent implements OnInit {
  @Input() mobileNumber;
  @Input() claimHandlerName;
  constructor() { }

  ngOnInit() {
    this.phoneNumberFormat(this.mobileNumber);
  }

phoneNumberFormat(phoneNo) {
  if (phoneNo && phoneNo.trim() && phoneNo.length >= 10) {
    const phNoValue = ((phoneNo.slice(0, 1) === '1' || phoneNo.slice(0, 2) === '(1') ? phoneNo.slice(1, phoneNo.length) : phoneNo.slice(0, phoneNo.length));
    const phNo = phNoValue.replace(/\D/g, '');
    return this.mobileNumber = '(' + phNo.slice(0, 3) + ')  ' + phNo.slice(3, 6) + '-' + phNo.slice(6, 10);
  } else {
    return this.mobileNumber;
  }
  }
}
