
import {catchError, map} from 'rxjs/operators';
import {Injectable} from '@angular/core';


import {Observable} from 'rxjs';
import {env} from '../../../environments/environment-loader';


import {ResponseErrorHandlerService} from './response-error-handler.service';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {AppLabelConstants} from '../constants/appLabel.constants';

/**
 * Created by Chaitanya on 8/17/2017.
 */

@Injectable()
export class ClaimHandlerContentService {

  constructor(private errorHandler: ResponseErrorHandlerService,
              private httpClient: HttpClient) {}

  getClaimHandlerInformation(claimEventId: string, officeId: string, claimProcessDate: string, coverageType: string, coverageCategory: string, claimStatusCode: string): Observable<any> {

      const request: string = JSON.stringify(
        {
          claimEventId: claimEventId || '',
          officeId: officeId || '',
          claimProcessDate: claimProcessDate || '',
          coverageType: coverageType || '',
          coverageCategory: coverageCategory || '',
          claimStatusCode: claimStatusCode || ''
        }
      );

      return this.httpClient.post(env.gbClaims.getClaimHandlerInfo, request,
        {
          headers: new HttpHeaders()
            .set(AppLabelConstants.CONTENT_TYPE_KEY, AppLabelConstants.APPLICATION_KEY)
        }).pipe(
        map(
        (response: Response) => {
          if (!this.errorHandler.isServiceResponseHasError(response)) {
            return response;
          } else {
            throw response;
          }
        }
      ),
        catchError((error: any) => this.errorHandler.handleError(error)), );

  }
}
