import {Component, Input, OnInit} from '@angular/core';
import {AppLabelConstants} from '../../../shared/constants/appLabel.constants';
import {AppConstants} from '../../../shared/constants/app.constants';

@Component({
  selector: 'app-claim-denied-timeline',
  templateUrl: 'claim-denied-timeline.component.html',
  styleUrls: ['claim-denied-timeline.component.scss']
})
export class ClaimDeniedTimelineComponent implements OnInit {
  @Input() Claimtype;
  @Input() encIndicator;
  @Input() encSurgeryIndicator;
  message: string;
  public appLabelConstant = AppLabelConstants;
  public AppConstants = AppConstants;

  constructor() { }

  ngOnInit() {

    if (this.Claimtype && this.Claimtype.toLowerCase() === AppConstants.MATERNITY_CLAIM) {
      this.message = AppConstants.AWAITING_DELIVERY;
    } else if (this.Claimtype && this.Claimtype.toLowerCase() === AppConstants.SURGERY_CLAIM) {
      this.message = AppConstants.AWAIT_SURG_DET_CONFIRM;
    } else {
      this.message = AppConstants.AWAITING_DISABILITY;
    }
  }


}
