import {PayeeTypeResponse} from '../shared/models/Payment.model';

/**
 * Created by AC12394 on 2/20/2018.
 */
export const MOCK_PAYEETYPE_RESPONSE: PayeeTypeResponse = {
  errorList : null,
  payeeTypeDetailsList: [
    {
      claimEventId: '1234567',
      payeeType: null
    },
    {
      claimEventId: '28701',
      payeeType: null
    },
    {
      claimEventId: '28940',
      payeeType: null
    }
  ]
};



