import {Component, OnInit} from '@angular/core';
import {Router} from '@angular/router';
import {DepositService} from '../../../../shared/services/enrolldeposit.service';
import {StorageManagementService} from '../../../../shared/services/storage-management.service';
import {PaymentsHistoryService} from '../../../../shared/services/claim-payment-history.service';
import {AppLabelConstants} from '../../../../shared/constants/appLabel.constants';
import {SessionConstants} from '../../../../shared/constants/session.constants';
import {ErrorConstants} from '../../../../shared/constants/error.constants';
import {AppConstants} from '../../../../shared/constants/app.constants';

@Component({
  selector: 'app-prompt-direct-deposit',
  templateUrl: 'prompt-direct-deposit.component.html',
  styleUrls: ['prompt-direct-deposit.component.scss']
})
export class PromptDirectDepositComponent implements OnInit {
  public appLabelConstants = AppLabelConstants;
  public hidePrompt: boolean = false;
  public isBankDetailsExist: boolean = true;
  public isHitAlready: boolean = true;
  public text_info: string = AppLabelConstants.ENROLL_IN_DD_TO_RECEIVE;
  public text_section_desktop: string =  AppLabelConstants.UR_FUNDS_AS_SOON_AS
  public text_section: string = AppLabelConstants.UR_FUNDS_AS_SOON_AS_AVAILABLE;
  public isDirectDepositDisplay: boolean = false;
  private userDetails;
  private insuredId: string;
  private personIds: string[];

  constructor(private router: Router,
              private depositService: DepositService,
              private paymentsHistoryService: PaymentsHistoryService,
              private storageManagementService: StorageManagementService) { }

  ngOnInit() {
    this.userDetails = this.storageManagementService.getAppData(SessionConstants.USER_DATA);
    this.insuredId = this.userDetails.srcSysId ? this.userDetails.srcSysId : '';
    this.personIds = this.userDetails.personIds ? this.userDetails.personIds : [];
    this.hidePrompt = this.storageManagementService.getSessionData(SessionConstants.HIDE_DIRECT_DEPOSIT_PROMPT) ? this.storageManagementService.getSessionData(SessionConstants.HIDE_DIRECT_DEPOSIT_PROMPT) : false;

    this.IsDirectDepositApplicable();
  }

  callHidePrompt() {
    this.hidePrompt = true;
    this.storageManagementService.setSessionData(SessionConstants.HIDE_DIRECT_DEPOSIT_PROMPT, true);
  }

  navToDirectDeposit() {
    this.router.navigate([AppLabelConstants.DEPOSIT_URL])
  }


  isPromptHitPreviously() {
    this.depositService.getEftPref().subscribe(
      (val: any ) =>  {
        if (val && val.promptDetailsList) {
          //console.log(val);
          for (const pref of val.promptDetailsList) {
            if (pref.preference === AppConstants.ENROLL_DIRECT_DEPOSIT_SM && pref.value.toUpperCase() === AppConstants.Y) {
              this.isHitAlready = true;
            } else {
              this.isHitAlready = false;
            }
          }
        } else {
          this.isHitAlready = false;
        }
      },
      (error) => {
        this.isHitAlready = false;
        console.log(ErrorConstants.ERROR_MESSAGE_1 + JSON.stringify(error));
      }
    );
  }

  eFTPrefUpdate() {
    this.depositService.updateEftPref().subscribe(
      val => {
        if (val) {
          this.callHidePrompt();
        }
      }
    )
  }
  IsDirectDepositApplicable() {
    this.paymentsHistoryService.checkIfBankDetailsExist();
    if (this.personIds && this.personIds.length > 0 ) {
      this.isBankDetailsExist = this.storageManagementService.getSessionData(SessionConstants.IS_BANKING_DETAILS_EXIST) ? this.storageManagementService.getSessionData(SessionConstants.IS_BANKING_DETAILS_EXIST) : true;
      if ( this.isBankDetailsExist === true) {
        this.paymentsHistoryService.isEFTdetailsExits.subscribe(
          (ifBankdetailsExist: boolean) => {
              this.isBankDetailsExist = ifBankdetailsExist;
              this.isDirectDepositDisplay = true;
              this.isPromptHitPreviously();
          }
        );
      }
    } else {
      this.paymentsHistoryService.isClaimantPaymentInfoSetInSession.subscribe(
        (ifClaimantPaymentInfoSet: boolean) => {
          if (ifClaimantPaymentInfoSet) {
            this.isDirectDepositDisplay = this.paymentsHistoryService.getIsPaymentApplicable();
            this.isBankDetailsExist = this.paymentsHistoryService.getIsBankDetailsExist();
            this.isPromptHitPreviously();
          }
        }
      );
    }
  }

}
