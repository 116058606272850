import {Observable, of as observableOf} from 'rxjs';
import {Injectable} from '@angular/core';
import {HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from '@angular/common/http';
import {env} from '../../../environments/environment-loader';
import {StorageManagementService} from '../services/storage-management.service';
import {NavigationEnd, Router} from '@angular/router';
import {TokenUtility} from './tokenUtility';
import {AppConstants} from '../constants/app.constants';
import {filter} from 'rxjs/operators';
import {RaeSessionConstants} from '@shared/constants/raeSession.constants';

@Injectable()
export class ServiceInterceptor implements HttpInterceptor {

  private frToken: string = '';
  private crToken: string = '';
  private loginState: boolean = false;

  constructor(private storageService: StorageManagementService,
              private router: Router) {
  }


  /* Method to bypass normal checks and just make the call regardless*/
  passThroughCall(req: HttpRequest<any>): boolean {
    return env.mocking ||
      (!env.proxyState && (env.envName === AppConstants.LOCAL || env.envName === AppConstants.LOCAL_QA)) ||
      (req.url.indexOf('/uws/') > 0 ) ||
      (req.url.indexOf('/psv/') > 0);
  }

  /**
   * Our main interceptor method for http requests coming in.
   * Required that */

  //todo add a capture for the response to be sanitized. New Callback Map function
  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    //console.log("URL: " + req.url);
    try {
      this.router.events
        .pipe(filter((event: any) => event instanceof NavigationEnd))
        .subscribe((event) => {
        const currentRoute = event.url;
          if (currentRoute.indexOf('/ire') === -1) {
            sessionStorage.removeItem(RaeSessionConstants.RAE_SESSION_DATA);
          }
        });
      /*
       * First Attempt is to see if there is any reason we want to bypass all checks and just run the service call.
       * This is necessary for mocking/unauth calls/jboss calls
       */
      if (this.passThroughCall(req)) {

        return next.handle(req);
      } else {

        /*
        * Now we obtain the two tokens. There is a need for both in the process in the situation we have one but not
        * the other and must be handling it.
        */
        this.frToken = TokenUtility.getExistingFrToken();
        const frTokenExists: boolean = this.frToken && this.frToken.length > 0;
        this.crToken = TokenUtility.getExistingMyBenefitsToken();
        const crTokenExists: boolean = this.crToken && this.crToken.length > 0;
        this.loginState = this.storageService.retrieveSessionObject('loginState') && JSON.parse(this.storageService.retrieveSessionObject('loginState')).loginState === true;
        //console.log("Fr token: " + frTokenExists+"("+this.frToken.substr(0,10)+")" + " | Cr Token: "+"("+this.crToken.substr(0,20)+")" + crTokenExists +  " | Login State: " + this.loginState);
        if (TokenUtility.getExistingFrTokenState()) {

          /*
           * Here we have the forgerock token available to us.
           * Next up we determine the different scenarios if we have or do not have the authorization token
           */
        if (crTokenExists) {
            if (!this.loginState) {
              /*
               * We are checking the login state for some vulnerability checks
               * If our third UI check is not available, then we will force the user out to re-log
               */
              throw new Error('tokens are available but login state is not - sending to home');
            }
            /*
             * In this situation we have obtained the authorization token as well as have the login state set to true
             * We are calling this for basically every regular service call. 90%+ go through here for authenticated flows
             */
            //console.log('making the good call');
            const updatedReq = req.clone({headers: req.headers
              .set('CIAMSession', this.frToken)
              .set('authorization', this.crToken)
            });
            return next.handle(updatedReq);

          } else if (TokenUtility.getExistingMyBenefitsTokenState()) {
            /*
             * In this situation we have obtained the authorization token as well as have the login state set to true
             * We are calling this for basically every regular service call. 90%+ go through here for authenticated flows
             */
            //console.log('making the good call');
            //const updatedReq = req.clone({headers: req.headers
                //.set('CIAMSession', TokenUtility.getExistingFrToken())
                //.set('authorization', TokenUtility.getExistingMyBenefitsToken())
            //});
            //return next.handle(updatedReq);

          } else {
              /*
               * If we have just the forgerock token and not authorization token, we are looking to send the user back to the login.
               * They will be required to re-login to the app to proceed as something seems to have happened to their token
               */
            throw new Error('Authorization token is missing - Sending the user back to the login screen')
          }
        } else if (TokenUtility.getExistingMyBenefitsTokenState()) {
          /*
           * In this scenario we have lost the forgerock token but have retained either the login state or the auth token
           * In this case, we will error out and redirect the user to the login page and get a new token
           */
          throw new Error('ForgeRock token is missing. Sending the user back to the login screen');
        } else {
          /*
           * Default situation where we just run the call as is. This should only be the situation
           * for when we are running the forgerock call (if not on local)
           */
          return next.handle(req);
        }
      }
    } catch (e) {
      /*
       * Any errors will be captured and displayed here
       * We are also clearing the cookies & loginState session item to be more proactive
       */
      //  this.clearThisSession();
       TokenUtility.removeAllMyBenefitsTokens();
       this.storageService.removeSessionObject('loginState');
      this.router.navigate(['/home']);
      return observableOf();
      //TokenUtility.removeAllMyBenefitsTokens();
      //this.storageService.removeSessionObject('loginState');
      //this.router.navigate(['/home']);
      //return Observable.empty();
    }
  }
  // getTheFRTokens(): string {
  //   if (document.cookie) {
  //     const ciamSessionCookie = (document.cookie.split(';')
  //       .find(str => (str.includes('CIAMSession'))));
  //     if (ciamSessionCookie && ciamSessionCookie.length > 0) {
  //       return this.frToken = ciamSessionCookie.split('=')[1];
  //     }
  //     return '';
  //   }
  // }

  // getTheCRTokens(): string {
  //   if (document.cookie) {
  //     const eeSessionCookie = (document.cookie.split(';')
  //       .find(str => (str.includes('EESession'))));
  //     if (eeSessionCookie && eeSessionCookie.length > 0) {
  //       return this.crToken = eeSessionCookie.split('=')[1];
  //     }
  //     return '';
  //   }
  // }

  // clearThisSession() {
  //   this.clearCookies();
  //   this.storageService.removeSessionObject('loginState');
  // }

  // clearCookies() {
  //   this.clearSessionCookies('CIAMSession');
  //   this.clearSessionCookies('EESession');
  // }

  // clearSessionCookies(cookieName) {
  //   if (env.envName !== 'local') {
  //     document.cookie = '' + cookieName + '=; domain=.thehartford.com; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;';
  //   } else {
  //     document.cookie = '' + cookieName + '=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;';
  //   }
  // }
}

