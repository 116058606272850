import {Component, HostListener, Input, OnInit} from '@angular/core';
import {AppLabelConstants} from '../../../../../shared/constants/appLabel.constants';

@Component({
  selector: 'secondary-link',
  templateUrl: 'secondary-link.component.html',
  styleUrls: ['secondary-link.component.scss']
})
export class SecondaryLinkComponent implements OnInit {

  @Input() linkName: string;
  @Input() linkLocation: string;
  @Input() linkId: number;
  @Input() elementId: string;
  @Input() linkDisabled = false;
  @Input() secondaryLinkAriaLabel: string = '';
  @Input() arrowEnabled = true;
  public appLabelConstants = AppLabelConstants;

  constructor() {}

  @HostListener('keyup.enter', ['$event'])
  onKeyUpEnter(event) {
    const eventText = event.target.innerText.trim();
    const isTextPdf = eventText && eventText.toLowerCase() === this.appLabelConstants.PAY_STUB_PDF.toLowerCase();
    if (!isTextPdf) {
      event.preventDefault();
      event.target.click();
    }
  }

  ngOnInit() {
  }

}
