/**
 * Created by AC12212 on 2/24/2018.
 */

import {ErrorList} from './ErrorList.model';

export class ssoRegistration {

  profileId: string;
  newRegistration: string;
  partyRefId: string;
  userId: string;
  sessionIdentifier: string;
  errorList: ErrorList[];

  constructor() {

    this.profileId = '3402';
    this.newRegistration = 'N';
    this.partyRefId = '63167';
    this.userId = null;
    this.sessionIdentifier = null;
    this.errorList = null;
  }
}
