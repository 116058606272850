import {RuleJson} from '../shared/models/ruleJson.model';

/**
 * Created by ACC334706 on 12/13/2017.
 */
export const ruleJson: RuleJson[] = [
  //Start  :Done

  /*Rule 1
   * Claim ID (CO_500) has a A/S/M  Indicator (CO_517) equals  M
   * and (OVRALL_ONLN_STAT_CD)(CO_503) equals '01'
   * and  (DTL_ONLN_STAT_CD)(CO_553) = '01'
   * then Date Label (CO_05) = Estimated Delivery Date
   * */
  {
    rule: '1',
    asmIndicator: 'M',
    coverageCategory: '',
    coverageType: null,
    claimProcessDate: '03/02/2016',
    claimOnlineStatusCode: '01',
    claimOnlineSubStatusCode: '01',
    claimType: ' Your Short Term Disability Claim rule resp',
    babyDeathIndicator: null,
    dateLabel: 'Estimated Delivery Date',
    confirmedLastDayWorkDate: '12/05/2017',
    confirmedDeliveryDate: '12/05/2017',
    estimatedSurgeryDate: '',
    confirmedSurgeryDate: '',
    disabilityDate: null,
    claimOnlineStatus: null,
    claimOnlineSubStatus: null,
    extensionStatus: null,
    extensionSubStatus: null
  },


  /*Rule 2
   * Claim ID (CO_500) has a A/S/M  Indicator (CO_517) equals M
   * and (OVRALL_ONLN_STAT_CD)(CO_503) equals '08'
   * and Confirmed Last Date Worked (CO_515) is not NULL
   * and Confirmed Delivery Date(CO_514) is not NULL
   * then Date Label (CO_05)= Date of Delivery*/
  {
    rule: '2',
    asmIndicator: 'M',
    coverageCategory: '',
    coverageType: null,
    claimProcessDate: '03/02/2016',
    claimOnlineStatusCode: '08',
    claimOnlineSubStatusCode: null,
    claimType: ' Your Short Term Disability Claim rule resp',
    dateLabel: 'Delivery Date',
    confirmedLastDayWorkDate: '12/05/2017',
    confirmedDeliveryDate: '12/05/2017',
    estimatedSurgeryDate: '',
    confirmedSurgeryDate: '',
    disabilityDate: null,
    claimOnlineStatus: null,
    claimOnlineSubStatus: null,
    extensionStatus: null,
    extensionSubStatus: null,
    babyDeathIndicator: null
  },


  /*Rule 3
   *
   * Claim ID (CO_500) has a  A/S/M  Indicator (CO_517) equals 'M'
   * and  (OVRALL_ONLN_STAT_CD)(CO_503) = '08'
   * and the Confirmed Last Date Worked (CO_515) is not NULL
   * and Confirmed Delivery Date(CO_514) is NULL
   * and Baby Death indicator is null
   * then Date Label (CO_05)= Estimated Delivery Date
   *
   * */
  { rule: '3',
    asmIndicator: 'M',
    coverageCategory: '',
    coverageType: null,
    claimProcessDate: '03/02/2016',
    claimOnlineStatusCode: '08',
    claimOnlineSubStatusCode: '',
    claimType: ' Your Short Term Disability Claim rule resp',
    claimOnlineStatus: null,
    dateLabel: 'Estimated Delivery Date',
    confirmedLastDayWorkDate: '12/05/2017',
    confirmedDeliveryDate: null,
    estimatedSurgeryDate: '',
    confirmedSurgeryDate: '',
    disabilityDate: null,
    claimOnlineSubStatus: null,
    extensionStatus: null,
    extensionSubStatus: null,
    babyDeathIndicator: null
  },

  /*Rule 4
   * Claim ID (CO_500) has a  A/S/M  Indicator (CO_517) equals 'M'
   * and  (OVRALL_ONLN_STAT_CD)(CO_503) = '04'
   * and Confirmed Last Date Worked (CO_515) is not NULL
   * then Date Label (CO_05) = Approved Through Date*/

  { rule: '4',
    asmIndicator: 'M',
    coverageCategory: '',
    coverageType: null,
    claimProcessDate: '03/02/2016',
    claimOnlineStatusCode: '04',
    claimOnlineSubStatusCode: null,
    claimType: ' Your Short Term Disability Claim rule resp',
    claimOnlineStatus: null,
    dateLabel: ' Approved Through Date',
    confirmedLastDayWorkDate: '12/05/2017',
    confirmedDeliveryDate: '12/05/2017',
    estimatedSurgeryDate: null,
    confirmedSurgeryDate: null,
    disabilityDate: null,
    claimOnlineSubStatus: null,
    extensionStatus: null,
    extensionSubStatus: null,
    babyDeathIndicator: null
  },


  /*Rule 5 RID_405
   * Claim ID (CO_500) has a A/S/M  Indicator (CO_517) equals 'A' or 'S'
   * and Procedure Date (CO_511) is 'Not Null'
   * and (OVRALL_ONLN_STAT_CD)(CO_503) = '03'
   * then Date Label (CO_05) = Scheduled Surgery Date*/


  { rule: '5',
    asmIndicator: 'A',
    coverageCategory: '',
    coverageType: null,
    claimProcessDate: '03/02/2016',
    claimOnlineStatusCode: '03',
    claimOnlineSubStatusCode: null,
    claimType: ' Your Short Term Disability Claim rule resp',
    claimOnlineStatus: null,
    dateLabel: 'Scheduled Surgery Date',
    confirmedLastDayWorkDate: '12/05/2017',
    confirmedDeliveryDate: '12/05/2017',
    estimatedSurgeryDate: '12/05/2017',
    confirmedSurgeryDate: '',
    disabilityDate: null,
    claimOnlineSubStatus: null,
    extensionStatus: null,
    extensionSubStatus: null,
    babyDeathIndicator: null
  },
  { rule: '6',
    asmIndicator: 'S',
    coverageCategory: '',
    coverageType: null,
    claimProcessDate: '03/02/2016',
    claimOnlineStatusCode: '03',
    claimOnlineSubStatusCode: null,
    claimType: ' Your Short Term Disability Claim rule resp',
    claimOnlineStatus: null,
    dateLabel: 'Scheduled Surgery Date',
    confirmedLastDayWorkDate: '12/05/2017',
    confirmedDeliveryDate: '12/05/2017',
    estimatedSurgeryDate: '12/05/2017',
    confirmedSurgeryDate: '',
    disabilityDate: null,
    claimOnlineSubStatus: null,
    extensionStatus: null,
    extensionSubStatus: null,
    babyDeathIndicator: null
  },


  /*Rule 6
   *
   * Claim ID (CO_500) has a  A/S/M  Indicator (CO_517) equals 'A' or 'S'
   * and Procedure Date (CO_511) is 'Not Null'
   * and (OVRALL_ONLN_STAT_CD)(CO_503) = '08'
   * and Confirmed Last Date Worked (CO_515) is 'Not Null'
   * and Confirmed Surgery Date (CO_512) is 'Not Null'
   * then Date Label (CO_05) = Surgery Date*/
  { rule: '7',
    asmIndicator: 'A',
    coverageCategory: null,
    coverageType: null,
    claimProcessDate: '03/02/2016',
    claimOnlineStatusCode: '08',
    claimOnlineSubStatusCode: null,
    claimType: ' Your Short Term Disability Claim rule resp',
    claimOnlineStatus: null,
    dateLabel: 'Surgery Date',
    confirmedLastDayWorkDate: '12/05/2017',
    confirmedDeliveryDate: '12/05/2017',
    estimatedSurgeryDate: '12/05/2017',
    confirmedSurgeryDate: '12/05/2017',
    disabilityDate: null,
    claimOnlineSubStatus: null,
    extensionStatus: null,
    extensionSubStatus: null,
    babyDeathIndicator: null
  },
  {
    rule: '8',
    asmIndicator: 'S',
    coverageCategory: null,
    coverageType: null,
    claimProcessDate: '03/02/2016',
    claimOnlineStatusCode: '08',
    claimOnlineSubStatusCode: null,
    claimType: ' Your Short Term Disability Claim rule resp',
    claimOnlineStatus: null,
    dateLabel: 'Surgery Date',
    confirmedLastDayWorkDate: '12/05/2017',
    confirmedDeliveryDate: '12/05/2017',
    estimatedSurgeryDate: '12/05/2017',
    confirmedSurgeryDate: '12/05/2017',
    disabilityDate: null,
    claimOnlineSubStatus: null,
    extensionStatus: null,
    extensionSubStatus: null,
    babyDeathIndicator: null
  },


  /*Rule 8
   * Claim ID (CO_500) has a A/S/M  Indicator (CO_517) equals 'A' or 'S'
   * and Procedure Date (CO_511) is 'Not Null'
   * and (OVRALL_ONLN_STAT_CD)(CO_503) = '08'
   * and Confirmed Last Date Worked (CO_515) is 'Not Null'
   * and Confirmed Surgery Date (CO_512) is null
   * then Date Label (CO_05) = Scheduled Surgery Date
   *
   * */
  { rule: '9',
    asmIndicator: 'A',
    coverageCategory: null,
    coverageType: null,
    claimProcessDate: '03/02/2016',
    claimOnlineStatusCode: '08',
    claimOnlineSubStatusCode: null,
    claimType: ' Your Short Term Disability Claim rule resp',
    claimOnlineStatus: null,
    dateLabel: 'Scheduled Surgery Date',
    confirmedLastDayWorkDate: '12/05/2017',
    confirmedDeliveryDate: '12/05/2017',
    estimatedSurgeryDate: '12/05/2017',
    confirmedSurgeryDate: null,
    disabilityDate: null,
    claimOnlineSubStatus: null,
    extensionStatus: null,
    extensionSubStatus: null,
    babyDeathIndicator: null
  },
  {
    rule: '10',
    asmIndicator: 'S',
    coverageCategory: null,
    coverageType: null,
    claimProcessDate: '03/02/2016',
    claimOnlineStatusCode: '08',
    claimOnlineSubStatusCode: null,
    claimType: ' Your Short Term Disability Claim rule resp',
    claimOnlineStatus: null,
    dateLabel: 'Scheduled Surgery Date',
    confirmedLastDayWorkDate: '12/05/2017',
    confirmedDeliveryDate: '12/05/2017',
    estimatedSurgeryDate: '12/05/2017',
    confirmedSurgeryDate: null,
    disabilityDate: null,
    claimOnlineSubStatus: null,
    extensionStatus: null,
    extensionSubStatus: null,
    babyDeathIndicator: null

  },

  /*Rule 9
   * Claim ID (CO_500) has a A/S/M  Indicator (CO_517) equals 'A' or 'S'
   * and Procedure Date (CO_511) is 'Not Null'
   * and (OVRALL_ONLN_STAT_CD)(CO_503) = '04'
   * and Confirmed Last Date Worked (CO_515) is 'Not Null'
   * and then Date Label (CO_05) = Approved Through Date
   * */

  { rule: '11',
    asmIndicator: 'A',
    coverageCategory: null,
    coverageType: null,
    claimProcessDate: '03/02/2016',
    claimOnlineStatusCode: '04',
    claimOnlineSubStatusCode: null,
    claimType: ' Your Short Term Disability Claim rule resp',
    claimOnlineStatus: null,
    dateLabel: 'Approved Through Date',
    confirmedLastDayWorkDate: '12/05/2017',
    confirmedDeliveryDate: null,
    estimatedSurgeryDate: '12/05/2017',
    confirmedSurgeryDate: '12/05/2017',
    disabilityDate: null,
    claimOnlineSubStatus: null,
    extensionStatus: null,
    extensionSubStatus: null,
    babyDeathIndicator: null
  },
  { rule: '12',
    asmIndicator: 'S',
    coverageCategory: null,
    coverageType: null,
    claimProcessDate: '03/02/2016',
    claimOnlineStatusCode: '04',
    claimOnlineSubStatusCode: null,
    claimType: ' Your Short Term Disability Claim rule resp',
    claimOnlineStatus: null,
    dateLabel: 'Approved Through Date',
    confirmedLastDayWorkDate: '12/05/2017',
    confirmedDeliveryDate: null,
    estimatedSurgeryDate: '12/05/2017',
    confirmedSurgeryDate: '12/05/2017',
    disabilityDate: null,
    claimOnlineSubStatus: null,
    extensionStatus: null,
    extensionSubStatus: null,
    babyDeathIndicator: null
  },

  /*Rule10 RID_406
   * Claim ID (CO_500) has a A/S/M  Indicator (CO_517) equals 'A' or 'S'
   * and Procedure Date (CO_511) is null
   * and (OVRALL_ONLN_STAT_CD)(CO_503) ='02'
   * and (DTL_ONLN_STAT_CD)(CO_553) equals '02'
   * then Date Label (CO_05) = Estimated Last Date Worked
   * */

  {
    rule: '13',
   asmIndicator: 'A',
   coverageCategory: null,
   coverageType: null,
   claimProcessDate: '03/02/2016',
   claimOnlineStatusCode: '02',
   claimOnlineSubStatusCode: '02',
   claimType: ' Your Short Term Disability Claim rule resp',
   claimOnlineStatus: null,
   dateLabel: 'Estimated Last Date Worked',
   confirmedLastDayWorkDate: null,
   confirmedDeliveryDate: null,
    estimatedSurgeryDate: null,
    confirmedSurgeryDate: '12/05/2017',
    disabilityDate: null,
    claimOnlineSubStatus: null,
    extensionStatus: null,
    extensionSubStatus: null,
    babyDeathIndicator: null
   }
   ,
   {
     rule: '14',
   asmIndicator: 'S',
   coverageCategory: null,
   coverageType: null,
   claimProcessDate: '03/02/2016',
   claimOnlineStatusCode: '02',
   claimOnlineSubStatusCode: '02',
   claimType: ' Your Short Term Disability Claim rule resp',
   claimOnlineStatus: null,
   dateLabel: 'Estimated Last Date Worked',
   confirmedLastDayWorkDate: null,
   confirmedDeliveryDate: null,
     estimatedSurgeryDate: null,
     confirmedSurgeryDate: '12/05/2017',
     disabilityDate: null,
     claimOnlineSubStatus: null,
     extensionStatus: null,
     extensionSubStatus: null,
     babyDeathIndicator: null
   },

   /*Rule11
   * Claim ID (CO_500) has a A/S/M  Indicator (CO_517) equals 'A' or 'S'
   * and Procedure Date (CO_511) is null
   * and (OVRALL_ONLN_STAT_CD)(CO_503) ='08'
   * and Confirmed Last Date Worked (CO_515) is 'Not Null'
   * then Date Label (CO_05) = Last Date Worked
   *
   * */
  { rule: '15',
    asmIndicator: 'A',
    coverageCategory: null,
    coverageType: null,
    claimProcessDate: '03/02/2016',
    claimOnlineStatusCode: '08',
    claimOnlineSubStatusCode: null,
    claimType: ' Your Short Term Disability Claim rule resp',
    claimOnlineStatus: null,
    dateLabel: 'Last Date Worked',
    confirmedLastDayWorkDate: '12/05/2017',
    confirmedDeliveryDate: null,
    estimatedSurgeryDate: null,
    confirmedSurgeryDate: null,
    disabilityDate: null,
    claimOnlineSubStatus: null,
    extensionStatus: null,
    extensionSubStatus: null,
    babyDeathIndicator: null
  }
  ,
  { rule: '16',
    asmIndicator: 'S',
    coverageCategory: null,
    coverageType: null,
    claimProcessDate: '03/02/2016',
    claimOnlineStatusCode: '08',
    claimOnlineSubStatusCode: null,
    claimType: ' Your Short Term Disability Claim rule resp',
    claimOnlineStatus: null,
    dateLabel: ' Last Date Worked',
    confirmedLastDayWorkDate: '12/05/2017',
    confirmedDeliveryDate: null,
    estimatedSurgeryDate: null,
    confirmedSurgeryDate: null,
    disabilityDate: null,
    claimOnlineSubStatus: null,
    extensionStatus: null,
    extensionSubStatus: null,
    babyDeathIndicator: null
  }

  /*Rule 12
   *  Claim ID (CO_500) has a A/S/M  Indicator (CO_517) equals 'A' or 'S'
   *  and Procedure Date (CO_511) is null
   *  and (OVRALL_ONLN_STAT_CD)(CO_503) ='04'
   *  and Confirmed Last Date Worked (CO_515) is 'Not Null'
   *  and Disability Date is 'Not Null'
   *  then Date Label (CO_05) = Approved Through Date
   *
   * */

  , {
    rule: '17',
    asmIndicator: 'A',
    coverageCategory: null,
    coverageType: null,
    claimProcessDate: '03/02/2016',
    claimOnlineStatusCode: '04',
    claimOnlineSubStatusCode: null,
    claimType: ' Your Short Term Disability Claim rule resp',
    claimOnlineStatus: null,
    dateLabel: 'Approved Through Date',
    confirmedLastDayWorkDate: '12/05/2017',
    confirmedDeliveryDate: null,
    estimatedSurgeryDate: null,
    confirmedSurgeryDate: null,
    disabilityDate: '12/05/2017',
    claimOnlineSubStatus: null,
    extensionStatus: null,
    extensionSubStatus: null,
    babyDeathIndicator: null
  },
  {
    rule: '18',
    asmIndicator: 'S',
    coverageCategory: null,
    coverageType: null,
    claimProcessDate: '03/02/2016',
    claimOnlineStatusCode: '04',
    claimOnlineSubStatusCode: null,
    claimType: ' Your Short Term Disability Claim rule resp',
    claimOnlineStatus: null,
    dateLabel: 'Approved Through Date',
    confirmedLastDayWorkDate: '12/05/2017',
    confirmedDeliveryDate: null,
    estimatedSurgeryDate: null,
    confirmedSurgeryDate: null,
    disabilityDate: '12/05/2017',
    claimOnlineSubStatus: null,
    extensionStatus: null,
    extensionSubStatus: null,
    babyDeathIndicator: null
  },

  /*Rule 13 /RID_721
   Rule 13 Claim ID (CO_500) has covergae category (CO_518) = null
   and Coverage type (CO_519) = BTRM  or BDEP or SDEP or STRM or VOL
   then Date (CO_06) should display the value associated  to Disability Date
   */
  {
    rule: '19',
    asmIndicator: null,
    coverageCategory: 'LIFE',
    coverageType: 'BTRM',
    claimProcessDate: null,
    claimOnlineStatusCode: null,
    claimOnlineSubStatusCode: null,
    claimType: null,
    claimOnlineStatus: null,
    dateLabel: 'Disability Date',
    confirmedLastDayWorkDate: null,
    confirmedDeliveryDate: null,
    estimatedSurgeryDate: null,
    confirmedSurgeryDate: null,
    disabilityDate: '12/05/2017',
    claimOnlineSubStatus: null,
    extensionStatus: null,
    extensionSubStatus: null,
    babyDeathIndicator: null
  },
  {
    rule: '19',
    asmIndicator: null,
    coverageCategory: 'LIFE',
    coverageType: 'BDEP',
    claimProcessDate: null,
    claimOnlineStatusCode: null,
    claimOnlineSubStatusCode: null,
    claimType: null,
    claimOnlineStatus: null,
    dateLabel: 'Disability Date',
    confirmedLastDayWorkDate: null,
    confirmedDeliveryDate: null,
    estimatedSurgeryDate: null,
    confirmedSurgeryDate: null,
    disabilityDate: '12/05/2017',
    claimOnlineSubStatus: null,
    extensionStatus: null,
    extensionSubStatus: null,
    babyDeathIndicator: null
  },
  {
    rule: '19',
    asmIndicator: null,
    coverageCategory: 'LIFE',
    coverageType: 'SDEP',
    claimProcessDate: null,
    claimOnlineStatusCode: null,
    claimOnlineSubStatusCode: null,
    claimType: null,
    claimOnlineStatus: null,
    dateLabel: 'Disability Date',
    confirmedLastDayWorkDate: null,
    confirmedDeliveryDate: null,
    estimatedSurgeryDate: null,
    confirmedSurgeryDate: null,
    disabilityDate: '12/05/2017',
    claimOnlineSubStatus: null,
    extensionStatus: null,
    extensionSubStatus: null,
    babyDeathIndicator: null
  },
  {
    rule: '19',
    asmIndicator: null,
    coverageCategory: 'LIFE',
    coverageType: 'STRM',
    claimProcessDate: null,
    claimOnlineStatusCode: null,
    claimOnlineSubStatusCode: null,
    claimType: null,
    claimOnlineStatus: null,
    dateLabel: 'Disability Date',
    confirmedLastDayWorkDate: null,
    confirmedDeliveryDate: null,
    estimatedSurgeryDate: null,
    confirmedSurgeryDate: null,
    disabilityDate: '12/05/2017',
    claimOnlineSubStatus: null,
    extensionStatus: null,
    extensionSubStatus: null,
    babyDeathIndicator: null
  },
  {
    rule: '19',
    asmIndicator: null,
    coverageCategory: null,
    coverageType: 'VOL',
    claimProcessDate: null,
    claimOnlineStatusCode: null,
    claimOnlineSubStatusCode: null,
    claimType: null,
    claimOnlineStatus: null,
    dateLabel: 'Disability Date',
    confirmedLastDayWorkDate: null,
    confirmedDeliveryDate: null,
    estimatedSurgeryDate: null,
    confirmedSurgeryDate: null,
    disabilityDate: '12/05/2017',
    claimOnlineSubStatus: null,
    extensionStatus: null,
    extensionSubStatus: null,
    babyDeathIndicator: null
  },

  /*Claim ID (CO_500) has covergae category (CO_518) = LTD
   then Date (CO_06) should display the value associated  to Disability Date*/

  {
    rule: '20',
    asmIndicator: null,
    coverageCategory: 'LTD',
    coverageType: null,
    claimProcessDate: null,
    claimOnlineStatusCode: null,
    claimOnlineSubStatusCode: null,
    claimType: null,
    claimOnlineStatus: null,
    dateLabel: 'Disability Date',
    confirmedLastDayWorkDate: null,
    confirmedDeliveryDate: null,
    estimatedSurgeryDate: null,
    confirmedSurgeryDate: null,
    disabilityDate: '12/05/2017',
    claimOnlineSubStatus: null,
    extensionStatus: null,
    extensionSubStatus: null,
    babyDeathIndicator: null
  },
  /*
   * User has a closed claim where Online Status = Claim Closed
   * and Online Substatus = Claim Closed
   * and Extension Status = null and Extension Substatus = null
   * then date label Approved Through Date and date value
   */

  {
    rule: '21',
    asmIndicator: null,
    coverageCategory: null,
    coverageType: null,
    claimProcessDate: null,
    claimOnlineStatusCode: null,
    claimOnlineSubStatusCode: null,
    claimType: null,
    claimOnlineStatus: 'Claim Closed',
    dateLabel: null,
    confirmedLastDayWorkDate: null,
    confirmedDeliveryDate: null,
    estimatedSurgeryDate: null,
    confirmedSurgeryDate: null,
    disabilityDate: null,
    claimOnlineSubStatus: 'Claim Closed',
    extensionStatus: null,
    extensionSubStatus: null,
    babyDeathIndicator: null
  }
  ,
  /*
   * User has a closed claim where Online Status = Claim Closed
   * and Online Substatus = Missing Information
   * ol_statuscode:05
   * ol-sub-ststus:09
   * asmindi :M
   * Date label and Date should have value of Delivery Date
   */

  {
    rule: '23',
    asmIndicator: 'M',
    coverageCategory: null,
    coverageType: null,
    claimProcessDate: null,
    claimOnlineStatusCode: '05',
    claimOnlineSubStatusCode: '09',
    claimType: null,
    claimOnlineStatus: 'Claim Closed',
    dateLabel: 'Estimated Delivery Date',
    confirmedLastDayWorkDate: null,
    confirmedDeliveryDate: null,
    estimatedSurgeryDate: null,
    confirmedSurgeryDate: null,
    disabilityDate: null,
    claimOnlineSubStatus: 'Missing Information',
    extensionStatus: null,
    extensionSubStatus: null,
    babyDeathIndicator: null
  },
  {
    rule: '62',
    asmIndicator: 'M',
    coverageCategory: null,
    coverageType: null,
    claimProcessDate: null,
    claimOnlineStatusCode: '05',
    claimOnlineSubStatusCode: '09',
    claimType: null,
    claimOnlineStatus: 'Claim Closed',
    dateLabel: 'Delivery Date',
    confirmedLastDayWorkDate: null,
    confirmedDeliveryDate: '02/02/2020',
    estimatedSurgeryDate: null,
    confirmedSurgeryDate: null,
    disabilityDate: null,
    claimOnlineSubStatus: 'Missing Information',
    extensionStatus: null,
    extensionSubStatus: null,
    babyDeathIndicator: null
  },

  // baby Deth indiactor  rules for Date label and date value for rules need to be implemented
   /*
   * Online status :08
   * Online sub status :13
   * babyDeth indiactor : not null
   * last day worked date: not null
   * confirm deliver date : null
   * ASM:M
   * */
  {
    rule: '24',
    asmIndicator: 'M',
    coverageCategory: null,
    coverageType: null,
    claimProcessDate: null,
    claimOnlineStatusCode: '08',
    claimOnlineSubStatusCode: '13',
    claimType: null,
    claimOnlineStatus: 'Claim Closed',
    dateLabel: 'Disability Date',
    confirmedLastDayWorkDate: '2/16/2018',
    confirmedDeliveryDate: null,
    estimatedSurgeryDate: null,
    confirmedSurgeryDate: null,
    disabilityDate: null,
    claimOnlineSubStatus: 'Missing Information',
    extensionStatus: null,
    extensionSubStatus: null,
    babyDeathIndicator: '2/16/2018'
  },
  /*
   * Online status :08
   * Online sub status :12
   * babyDeth indiactor : not null
   * last day worked date: not null
   * confirm deliver date : null
   * ASM:M
   * */
  {
    rule: '26',
    asmIndicator: 'M',
    coverageCategory: null,
    coverageType: null,
    claimProcessDate: null,
    claimOnlineStatusCode: '08',
    claimOnlineSubStatusCode: '12',
    claimType: null,
    claimOnlineStatus: 'Claim Closed',
    dateLabel: 'Disability Date',
    confirmedLastDayWorkDate: '2/16/2018',
    confirmedDeliveryDate: null,
    estimatedSurgeryDate: null,
    confirmedSurgeryDate: null,
    disabilityDate: null,
    claimOnlineSubStatus: 'Missing Information',
    extensionStatus: null,
    extensionSubStatus: null,
    babyDeathIndicator: '2/16/2018'
  },
  /*
   * Online status :04
   * Online sub status :any
   * babyDeth indiactor : not null
   * last day worked date: not null
   * confirm deliver date : null
   * ASM:M
   * */
  {
    rule: '27',
    asmIndicator: 'M',
    coverageCategory: null,
    coverageType: null,
    claimProcessDate: null,
    claimOnlineStatusCode: '04',
    claimOnlineSubStatusCode: null,
    claimType: null,
    claimOnlineStatus: 'Claim Closed',
    dateLabel: 'Disability Date',
    confirmedLastDayWorkDate: '2/16/2018',
    confirmedDeliveryDate: null,
    estimatedSurgeryDate: null,
    confirmedSurgeryDate: null,
    disabilityDate: null,
    claimOnlineSubStatus: 'Missing Information',
    extensionStatus: null,
    extensionSubStatus: null,
    babyDeathIndicator: '2/16/2018'
  },
  /*
   * Online status :05
   * Online sub status :08
   * babyDeth indiactor : not null
   * last day worked date: not null
   * confirm deliver date : null
   * ASM:M
   * */
  {
    rule: '28',
    asmIndicator: 'M',
    coverageCategory: null,
    coverageType: null,
    claimProcessDate: null,
    claimOnlineStatusCode: '05',
    claimOnlineSubStatusCode: '08',
    claimType: null,
    claimOnlineStatus: 'Claim Closed',
    dateLabel: 'Disability Date',
    confirmedLastDayWorkDate: '2/16/2018',
    confirmedDeliveryDate: null,
    estimatedSurgeryDate: null,
    confirmedSurgeryDate: null,
    disabilityDate: null,
    claimOnlineSubStatus: 'Missing Information',
    extensionStatus: null,
    extensionSubStatus: null,
    babyDeathIndicator: '2/16/2018'
  },
  /*
   * Online status :05
   * Online sub status :09
   * babyDeth indiactor : not null
   * last day worked date: not null
   * confirm deliver date : null
   * ASM:M
   * */
  {
    rule: '29',
    asmIndicator: 'M',
    coverageCategory: null,
    coverageType: null,
    claimProcessDate: null,
    claimOnlineStatusCode: '05',
    claimOnlineSubStatusCode: '09',
    claimType: null,
    claimOnlineStatus: 'Claim Closed',
    dateLabel: 'Disability Date',
    confirmedLastDayWorkDate: '2/16/2018',
    confirmedDeliveryDate: null,
    estimatedSurgeryDate: null,
    confirmedSurgeryDate: null,
    disabilityDate: null,
    claimOnlineSubStatus: 'Missing Information',
    extensionStatus: null,
    extensionSubStatus: null,
    babyDeathIndicator: '2/16/2018'
  },

// New rules need to be added

  /*Rule 30 _52A
     Claim ID (CO_500) has a A/S/M  Indicator (CO_517) equals M
     and (OVRALL_ONLN_STAT_CD)(CO_503) equals '08'
     and Confirmed Last Date Worked (CO_515) is not NULL
     and Baby Death Indicator = not null then Date Label (CO_05)= Disability Date
  */
  {
    rule: '30',
    asmIndicator: 'M',
    coverageCategory: null,
    coverageType: null,
    claimProcessDate: null,
    claimOnlineStatusCode: '08',
    claimOnlineSubStatusCode: null,
    claimType: null,
    claimOnlineStatus: null,
    dateLabel: 'Disability Date',
    confirmedLastDayWorkDate: '2/16/2018',
    confirmedDeliveryDate: null,
    estimatedSurgeryDate: null,
    confirmedSurgeryDate: null,
    disabilityDate: null,
    claimOnlineSubStatus: 'Missing Information',
    extensionStatus: null,
    extensionSubStatus: null,
    babyDeathIndicator: '2/16/2018'
  },

  /*Rule31
  * Claim ID (CO_500) has a A/S/M  Indicator (CO_517) = 'M'
  * and  (OVRALL_ONLN_STAT_CD)(CO_503) = '05'
  * and (DTL_ONLN_STAT_CD)(CO_553) equals '13'
  * and the Confirmed Last Date Worked (CO_515) is not NULL
  * and  Confirmed Delivery Date (CO_514) is  NULL
  * and Baby Death indicator is null then Date Label (CO_05) = Estimated Delivery Date
  * */

  {
    rule: '31',
    asmIndicator: 'M',
    coverageCategory: null,
    coverageType: null,
    claimProcessDate: null,
    claimOnlineStatusCode: '05',
    claimOnlineSubStatusCode: '13',
    claimType: null,
    claimOnlineStatus: null,
    dateLabel: 'Estimated Delivery Date',
    confirmedLastDayWorkDate: '2/16/2018',
    confirmedDeliveryDate: null,
    estimatedSurgeryDate: null,
    confirmedSurgeryDate: null,
    disabilityDate: null,
    claimOnlineSubStatus: 'Missing Information',
    extensionStatus: null,
    extensionSubStatus: null,
    babyDeathIndicator: null
  },
  /*Rule32
  *  Claim ID (CO_500) has a A/S/M  Indicator (CO_517) = 'M'
  *  and  (OVRALL_ONLN_STAT_CD)(CO_503) = '05'
  *  and (DTL_ONLN_STAT_CD)(CO_553) equals '13'
  *  and the Confirmed Last Date Worked (CO_515) is not NULL
  *  and  Baby Death Indicator is not NULL then Date Label (CO_05) = Disability Date
  * */
  {
    rule: '32',
    asmIndicator: 'M',
    coverageCategory: null,
    coverageType: null,
    claimProcessDate: null,
    claimOnlineStatusCode: '05',
    claimOnlineSubStatusCode: '13',
    claimType: null,
    claimOnlineStatus: 'Claim Closed',
    dateLabel: 'Disability Date',
    confirmedLastDayWorkDate: '2/16/2018',
    confirmedDeliveryDate: null,
    estimatedSurgeryDate: null,
    confirmedSurgeryDate: null,
    disabilityDate: null,
    claimOnlineSubStatus: 'Missing Information',
    extensionStatus: null,
    extensionSubStatus: null,
    babyDeathIndicator: '2/16/2018'
  },

  /*Rule 33
  *  Claim ID (CO_500) has a A/S/M  Indicator (CO_517) = 'M'
  *  and  (OVRALL_ONLN_STAT_CD)(CO_503) = '05'
  *  and (DTL_ONLN_STAT_CD)(CO_553) equals '13'
  *  and the Confirmed Last Date Worked (CO_515) is not NULL
  *  and  Confirmed Delivery Date (CO_514) is not NULL
  *  and Baby Death Indicator is NULL  then Date Label (CO_05) = Delivery Date
   *
  *
  * */
  {
    rule: '33',
    asmIndicator: 'M',
    coverageCategory: null,
    coverageType: null,
    claimProcessDate: null,
    claimOnlineStatusCode: '05',
    claimOnlineSubStatusCode: '13',
    claimType: null,
    claimOnlineStatus: null,
    dateLabel: 'Delivery Date',
    confirmedLastDayWorkDate: '2/16/2018',
    confirmedDeliveryDate: '2/16/2018',
    estimatedSurgeryDate: null,
    confirmedSurgeryDate: null,
    disabilityDate: null,
    claimOnlineSubStatus: 'Missing Information',
    extensionStatus: null,
    extensionSubStatus: null,
    babyDeathIndicator: null
  },

  /*Rule34
  *    Claim ID (CO_500) has  (OVRALL_ONLN_STAT_CD)(CO_503) = '06'
  *    then Date Label (CO_05) = Decision Date
   * */
  {
    rule: '34',
    asmIndicator: 'M',
    coverageCategory: null,
    coverageType: null,
    claimProcessDate: null,
    claimOnlineStatusCode: '06',
    claimOnlineSubStatusCode: '13',
    claimType: null,
    claimOnlineStatus: null,
    dateLabel: 'Decision Date',
    confirmedLastDayWorkDate: '2/16/2018',
    confirmedDeliveryDate: '2/16/2018',
    estimatedSurgeryDate: null,
    confirmedSurgeryDate: null,
    disabilityDate: null,
    claimOnlineSubStatus: 'Missing Information',
    extensionStatus: null,
    extensionSubStatus: null,
    babyDeathIndicator: null
  },
  /*Rule 35
  *  Claim ID (CO_500) has  (OVRALL_ONLN_STAT_CD)(CO_503) = '07'
  *  and (DTL_ONLN_STAT_CD)(CO_553) equals '11'
  *  then Date Label (CO_05) = Disability Date
  * */
  {
    rule: '35',
    asmIndicator: 'M',
    coverageCategory: null,
    coverageType: null,
    claimProcessDate: null,
    claimOnlineStatusCode: '07',
    claimOnlineSubStatusCode: '11',
    claimType: null,
    claimOnlineStatus: null,
    dateLabel: 'Disability Date',
    confirmedLastDayWorkDate: '2/16/2018',
    confirmedDeliveryDate: '2/16/2018',
    estimatedSurgeryDate: null,
    confirmedSurgeryDate: null,
    disabilityDate: null,
    claimOnlineSubStatus: 'Missing Information',
    extensionStatus: null,
    extensionSubStatus: null,
    babyDeathIndicator: null
  },
    /*Rule36 RID_059A
    * Claim ID (CO_500) has a A/S/M  Indicator (CO_517) equals 'A' or 'S'
    * and Procedure Date (CO_511) is 'Not Null'
    * and  ENC Surgery Indicator is checked (CO_534)
    * and (OVRALL_ONLN_STAT_CD)(CO_503) = '04'
    * and Confirmed Last Date Worked (CO_515) is 'Not Null'
    * and Confirmed Surgery Date(CO_512) is  Null
    * then Date Label (CO_05) = Approved Through Date
    * */
  {
    rule: '36',
    asmIndicator: 'A',
    coverageCategory: null,
    coverageType: null,
    claimProcessDate: null,
    claimOnlineStatusCode: '04',
    claimOnlineSubStatusCode: '11',
    claimType: null,
    claimOnlineStatus: null,
    dateLabel: 'Approved Through Date',
    confirmedLastDayWorkDate: '2/16/2018',
    confirmedDeliveryDate: '2/16/2018',
    estimatedSurgeryDate: '2/16/2018',
    confirmedSurgeryDate: null,
    disabilityDate: null,
    claimOnlineSubStatus: 'Missing Information',
    extensionStatus: null,
    extensionSubStatus: null,
    babyDeathIndicator: null
  },
  {
    rule: '37',
    asmIndicator: 'S',
    coverageCategory: null,
    coverageType: null,
    claimProcessDate: null,
    claimOnlineStatusCode: '04',
    claimOnlineSubStatusCode: '11',
    claimType: null,
    claimOnlineStatus: null,
    dateLabel: 'Approved Through Date',
    confirmedLastDayWorkDate: '2/16/2018',
    confirmedDeliveryDate: null,
    estimatedSurgeryDate: '2/16/2018',
    confirmedSurgeryDate: null,
    disabilityDate: null,
    claimOnlineSubStatus: 'Missing Information',
    extensionStatus: null,
    extensionSubStatus: null,
    babyDeathIndicator: null
  },



  /*Rule 39 rid059c
  *   Claim ID (CO_500) has a A/S/M  Indicator (CO_517) equals 'A' or 'S'
   *   and Procedure Date (CO_511) is 'Not Null'
   *   and (OVRALL_ONLN_STAT_CD)(CO_503) = '05'
   *  and (DTL_ONLN_STAT_CD)(CO_553) equals '13'
   *  and Confirmed Last Date Worked (CO_515) is 'Not Null'
   *  and Confirmed Surgery Date(CO_512) is  Null
   *  then Date Label (CO_05) = Scheduled Surgery Date
   *  */
  {
    rule: '39',
    asmIndicator: 'A',
    coverageCategory: null,
    coverageType: null,
    claimProcessDate: null,
    claimOnlineStatusCode: '05',
    claimOnlineSubStatusCode: '13',
    estimatedSurgeryDate: '2/16/2018',
    dateLabel: 'Scheduled Surgery Date',
    confirmedLastDayWorkDate: '2/16/2018',
    confirmedSurgeryDate: null,
    claimType: null,
    claimOnlineStatus: null,
    confirmedDeliveryDate: '2/16/2018',
    disabilityDate: null,
    claimOnlineSubStatus: 'Missing Information',
    extensionStatus: null,
    extensionSubStatus: null,
    babyDeathIndicator: null
  },
  {
    rule: '40',
    asmIndicator: 'S',
    coverageCategory: null,
    coverageType: null,
    claimProcessDate: null,
    claimOnlineStatusCode: '05',
    claimOnlineSubStatusCode: '13',
    claimType: null,
    claimOnlineStatus: null,
    dateLabel: 'Scheduled Surgery Date',
    confirmedLastDayWorkDate: '2/16/2018',
    confirmedDeliveryDate: null,
    estimatedSurgeryDate: '2/16/2018',
    confirmedSurgeryDate: null,
    disabilityDate: null,
    claimOnlineSubStatus: 'Missing Information',
    extensionStatus: null,
    extensionSubStatus: null,
    babyDeathIndicator: null
  },

  /*Rule 41 Rid_059D
  * Claim ID (CO_500) has a A/S/M  Indicator (CO_517) equals 'A' or 'S'
  * and Procedure Date (CO_511) is 'Not Null'
  * and  ENC Surgery Indicator is checked (CO_534)
  * and (OVRALL_ONLN_STAT_CD)(CO_503) = '05' and
  * and (DTL_ONLN_STAT_CD)(CO_553) equals '13'
  * and Confirmed Last Date Worked (CO_515) is 'Not Null'
  * and Confirmed Surgery Date(CO_512) is Not Null
  * then Date Label (CO_05) = Surgery Date
  * */
  {
    rule: '41',
    asmIndicator: 'A',
    coverageCategory: null,
    coverageType: null,
    claimProcessDate: null,
    claimOnlineStatusCode: '05',
    claimOnlineSubStatusCode: '13',
    estimatedSurgeryDate: '2/16/2018',
    dateLabel: 'Surgery Date',
    confirmedLastDayWorkDate: '2/16/2018',
    confirmedSurgeryDate: '2/16/2018',
    claimType: null,
    claimOnlineStatus: null,
    confirmedDeliveryDate: '2/16/2018',
    disabilityDate: null,
    claimOnlineSubStatus: 'Missing Information',
    extensionStatus: null,
    extensionSubStatus: null,
    babyDeathIndicator: null
  },
  {
    rule: '42',
    asmIndicator: 'S',
    coverageCategory: null,
    coverageType: null,
    claimProcessDate: null,
    claimOnlineStatusCode: '05',
    claimOnlineSubStatusCode: '13',
    claimType: null,
    claimOnlineStatus: null,
    dateLabel: 'Surgery Date',
    confirmedLastDayWorkDate: '2/16/2018',
    confirmedDeliveryDate: null,
    estimatedSurgeryDate: '2/16/2018',
    confirmedSurgeryDate: '2/16/2018',
    disabilityDate: null,
    claimOnlineSubStatus: 'Missing Information',
    extensionStatus: null,
    extensionSubStatus: null,
    babyDeathIndicator: null
  },

  /*Rule43 rid_062
  * Claim ID (CO_500) has a A/S/M  Indicator (CO_517) equals 'A' or 'S' \
  * and Procedure Date (CO_511) is null
  * and (OVRALL_ONLN_STAT_CD)(CO_503) = '04'
  * and  (DTL_ONLN_STAT_CD)(CO_553) equals '13'
  * and Confirmed Last Date Worked (CO_515) is 'Not Null'
  * then Date Label (CO_05) =  Approved Through Date
  *
  * */
  {
    rule: '43',
    asmIndicator: 'A',
    coverageCategory: null,
    coverageType: null,
    claimProcessDate: null,
    claimOnlineStatusCode: '04',
    claimOnlineSubStatusCode: '13',
    estimatedSurgeryDate: null,
    dateLabel: ' Approved Through Date',
    confirmedLastDayWorkDate: '2/16/2018',
    confirmedSurgeryDate: '2/16/2018',
    claimType: null,
    claimOnlineStatus: null,
    confirmedDeliveryDate: '2/16/2018',
    disabilityDate: null,
    claimOnlineSubStatus: 'Missing Information',
    extensionStatus: null,
    extensionSubStatus: null,
    babyDeathIndicator: null
  },
  {
    rule: '44',
    asmIndicator: 'S',
    coverageCategory: null,
    coverageType: null,
    claimProcessDate: null,
    claimOnlineStatusCode: '04',
    claimOnlineSubStatusCode: '13',
    claimType: null,
    claimOnlineStatus: null,
    dateLabel: ' Approved Through Date',
    confirmedLastDayWorkDate: '2/16/2018',
    confirmedDeliveryDate: null,
    estimatedSurgeryDate: null,
    confirmedSurgeryDate: '2/16/2018',
    disabilityDate: null,
    claimOnlineSubStatus: 'Missing Information',
    extensionStatus: null,
    extensionSubStatus: null,
    babyDeathIndicator: null
  },


  /*Rule43 rid_062A
   * Claim ID (CO_500) has a A/S/M  Indicator (CO_517) equals 'A' or 'S' \
   * and Procedure Date (CO_511) is null
   * and (OVRALL_ONLN_STAT_CD)(CO_503) = '04'
   * and Confirmed Last Date Worked (CO_515) is 'Not Null'
   * then Date Label (CO_05) = Last Date Worked
   *
   * */
  {
    rule: '43',
    asmIndicator: 'A',
    coverageCategory: null,
    coverageType: null,
    claimProcessDate: null,
    claimOnlineStatusCode: '04',
    claimOnlineSubStatusCode: '13',
    estimatedSurgeryDate: null,
    dateLabel: 'Last Date Worked',
    confirmedLastDayWorkDate: '2/16/2018',
    confirmedSurgeryDate: '2/16/2018',
    claimType: null,
    claimOnlineStatus: null,
    confirmedDeliveryDate: '2/16/2018',
    disabilityDate: null,
    claimOnlineSubStatus: 'Missing Information',
    extensionStatus: null,
    extensionSubStatus: null,
    babyDeathIndicator: null
  },
  {
    rule: '44',
    asmIndicator: 'S',
    coverageCategory: null,
    coverageType: null,
    claimProcessDate: null,
    claimOnlineStatusCode: '04',
    claimOnlineSubStatusCode: '13',
    claimType: null,
    claimOnlineStatus: null,
    dateLabel: 'Last Date Worked',
    confirmedLastDayWorkDate: '2/16/2018',
    confirmedDeliveryDate: null,
    estimatedSurgeryDate: null,
    confirmedSurgeryDate: '2/16/2018',
    disabilityDate: null,
    claimOnlineSubStatus: 'Missing Information',
    extensionStatus: null,
    extensionSubStatus: null,
    babyDeathIndicator: null
  },

  /*Rid_480
  * ol ststus : 08
  * ASM: M
  * ol sub status: 12
  * baby death  : null
  * CDD: Null
  * */

  {
    rule: '45',
    asmIndicator: 'M',
    coverageCategory: null,
    coverageType: null,
    claimProcessDate: null,
    claimOnlineStatusCode: '08',
    claimOnlineSubStatusCode: '12',
    estimatedSurgeryDate: null,
    dateLabel: 'Estimated Delivery Date',
    confirmedLastDayWorkDate: null,
    confirmedSurgeryDate: '2/16/2018',
    claimType: null,
    claimOnlineStatus: null,
    confirmedDeliveryDate: null,
    disabilityDate: null,
    claimOnlineSubStatus: 'Missing Information',
    extensionStatus: null,
    extensionSubStatus: null,
    babyDeathIndicator: null
  },

  /*Claim ID  has a A/S/M  Indicator  equals 'A' or 'S'
  and Procedure Date  is 'Not Null'
   and Confirmed Last Date Worked  is 'Not Null'
   and Confirmed Surgery Date  is null
   ol_status: 08
   ol_sub ststus:13
   */
  {
    rule: '45',
    asmIndicator: 'A',
    coverageCategory: null,
    coverageType: null,
    claimProcessDate: null,
    claimOnlineStatusCode: '08',
    claimOnlineSubStatusCode: '13',
    estimatedSurgeryDate: null,
    dateLabel: 'Last date worked',
    confirmedLastDayWorkDate: '2/16/2018',
    confirmedSurgeryDate: null,
    claimType: null,
    claimOnlineStatus: null,
    confirmedDeliveryDate: null,
    disabilityDate: null,
    claimOnlineSubStatus: 'Missing Information',
    extensionStatus: null,
    extensionSubStatus: null,
    babyDeathIndicator: null
  },
  {
    rule: '45',
    asmIndicator: 'S',
    coverageCategory: null,
    coverageType: null,
    claimProcessDate: null,
    claimOnlineStatusCode: '08',
    claimOnlineSubStatusCode: '13',
    estimatedSurgeryDate: null,
    dateLabel: 'Last date worked',
    confirmedLastDayWorkDate: '2/16/2018',
    confirmedSurgeryDate: null,
    claimType: null,
    claimOnlineStatus: null,
    confirmedDeliveryDate: null,
    disabilityDate: null,
    claimOnlineSubStatus: 'Missing Information',
    extensionStatus: null,
    extensionSubStatus: null,
    babyDeathIndicator: null
  },

  /*rid_760RuleForMissingInfoclaimclosed
   *Olstatus:05
   *olsubstatus:09
   *ASM=a/s
   *proceduredate:notnull/null
   *ApprovedThrough
   **/
  {
    rule: '45',
    asmIndicator: 'A',
    coverageCategory: null,
    coverageType: null,
    claimProcessDate: null,
    claimOnlineStatusCode: '05',
    claimOnlineSubStatusCode: '09',
    estimatedSurgeryDate: null,
    dateLabel: 'Last Date Worked',
    confirmedLastDayWorkDate: '2/16/2018',
    confirmedSurgeryDate: null,
    claimType: null,
    claimOnlineStatus: null,
    confirmedDeliveryDate: null,
    disabilityDate: null,
    claimOnlineSubStatus: 'MissingInformation',
    extensionStatus: null,
    extensionSubStatus: null,
    babyDeathIndicator: null,
    sourceSystemId: 'DCS'
  },
  {
    rule: '46',
    asmIndicator: 'S',
    coverageCategory: null,
    coverageType: null,
    claimProcessDate: null,
    claimOnlineStatusCode: '05',
    claimOnlineSubStatusCode: '09',
    estimatedSurgeryDate: null,
    dateLabel: 'Last Date Worked',
    confirmedLastDayWorkDate: '2/16/2018',
    confirmedSurgeryDate: null,
    claimType: null,
    claimOnlineStatus: null,
    confirmedDeliveryDate: null,
    disabilityDate: null,
    claimOnlineSubStatus: 'MissingInformation',
    extensionStatus: null,
    extensionSubStatus: null,
    babyDeathIndicator: null,
    sourceSystemId: 'DCS'
  },
  {
    rule: '47',
    asmIndicator: 'P',
    coverageCategory: null,
    coverageType: null,
    claimProcessDate: null,
    claimOnlineStatusCode: '02',
    claimOnlineSubStatusCode: '02',
    estimatedSurgeryDate: null,
    dateLabel: 'Start Date',
    confirmedLastDayWorkDate: '',
    confirmedSurgeryDate: null,
    claimType: null,
    claimOnlineStatus: null,
    confirmedDeliveryDate: null,
    disabilityDate: null,
    claimOnlineSubStatus: 'MissingInformation',
    extensionStatus: null,
    extensionSubStatus: null,
    babyDeathIndicator: null
  },
  {
    rule: '48',
    asmIndicator: 'P',
    coverageCategory: 'PFL',
    coverageType: null,
    claimProcessDate: null,
    claimOnlineStatusCode: '02',
    claimOnlineSubStatusCode: '02',
    estimatedSurgeryDate: null,
    dateLabel: 'Current Applicable Date',
    confirmedLastDayWorkDate: '',
    confirmedSurgeryDate: null,
    claimType: null,
    claimOnlineStatus: null,
    confirmedDeliveryDate: null,
    disabilityDate: null,
    claimOnlineSubStatus: 'MissingInformation',
    extensionStatus: null,
    extensionSubStatus: null,
    babyDeathIndicator: null
  },
  {
    rule: '49',
    asmIndicator: 'P',
    coverageCategory: 'PFL',
    coverageType: null,
    claimProcessDate: null,
    claimOnlineStatusCode: '03',
    claimOnlineSubStatusCode: '03',
    estimatedSurgeryDate: null,
    dateLabel: 'Current Applicable Date',
    confirmedLastDayWorkDate: '',
    confirmedSurgeryDate: null,
    claimType: null,
    claimOnlineStatus: null,
    confirmedDeliveryDate: null,
    disabilityDate: null,
    claimOnlineSubStatus: 'Request Submitted',
    extensionStatus: null,
    extensionSubStatus: null,
    babyDeathIndicator: null
  },
  {
    rule: '50',
    asmIndicator: 'P',
    coverageCategory: 'PFL',
    coverageType: null,
    claimProcessDate: null,
    claimOnlineStatusCode: '04',
    claimOnlineSubStatusCode: '04',
    estimatedSurgeryDate: null,
    dateLabel: 'Approved Through',
    confirmedLastDayWorkDate: '',
    confirmedSurgeryDate: null,
    claimType: null,
    claimOnlineStatus: null,
    confirmedDeliveryDate: null,
    disabilityDate: null,
    claimOnlineSubStatus: 'In Payment',
    extensionStatus: null,
    extensionSubStatus: null,
    babyDeathIndicator: null
  },
  {
    rule: '51',
    asmIndicator: 'P',
    coverageCategory: 'PFL',
    coverageType: null,
    claimProcessDate: null,
    claimOnlineStatusCode: '08',
    claimOnlineSubStatusCode: '13',
    estimatedSurgeryDate: null,
    dateLabel: 'Last Day of Work',
    confirmedLastDayWorkDate: '',
    confirmedSurgeryDate: null,
    claimType: null,
    claimOnlineStatus: null,
    confirmedDeliveryDate: null,
    disabilityDate: null,
    claimOnlineSubStatus: 'MissingInformation',
    extensionStatus: null,
    extensionSubStatus: null,
    babyDeathIndicator: null
  },
  {
    rule: '52',
    asmIndicator: 'P',
    coverageCategory: 'PFL',
    coverageType: null,
    claimProcessDate: null,
    claimOnlineStatusCode: '16',
    claimOnlineSubStatusCode: '16',
    estimatedSurgeryDate: null,
    dateLabel: 'Current Applicable Date',
    confirmedLastDayWorkDate: '',
    confirmedSurgeryDate: null,
    claimType: null,
    claimOnlineStatus: null,
    confirmedDeliveryDate: null,
    disabilityDate: null,
    claimOnlineSubStatus: 'Awaiting Child Bonding',
    extensionStatus: null,
    extensionSubStatus: null,
    babyDeathIndicator: null
  },
  {
    rule: '53',
    asmIndicator: 'P',
    coverageCategory: 'PFL',
    coverageType: null,
    claimProcessDate: null,
    claimOnlineStatusCode: '09',
    claimOnlineSubStatusCode: '14',
    estimatedSurgeryDate: null,
    dateLabel: 'Estimated Last Day of Work',
    confirmedLastDayWorkDate: '',
    confirmedSurgeryDate: null,
    claimType: null,
    claimOnlineStatus: null,
    confirmedDeliveryDate: null,
    disabilityDate: null,
    claimOnlineSubStatus: 'Awaiting Paid Family Leave',
    extensionStatus: null,
    extensionSubStatus: null,
    babyDeathIndicator: null
  },
  {
    rule: '54',
    asmIndicator: 'P',
    coverageCategory: 'PFL',
    coverageType: null,
    claimProcessDate: null,
    claimOnlineStatusCode: '08',
    claimOnlineSubStatusCode: '12',
    estimatedSurgeryDate: null,
    dateLabel: 'Last Day of Work',
    confirmedLastDayWorkDate: '',
    confirmedSurgeryDate: null,
    claimType: null,
    claimOnlineStatus: null,
    confirmedDeliveryDate: null,
    disabilityDate: null,
    claimOnlineSubStatus: 'Under Review by Claim Handler',
    extensionStatus: null,
    extensionSubStatus: null,
    babyDeathIndicator: null
  },

  /*Claim ID (CO_500) has covergae category (CO_518) = PW
   then Date (CO_06) should display the value associated  to Disability Date*/

  {
    rule: '55',
    asmIndicator: null,
    coverageCategory: 'PW',
    coverageType: null,
    claimProcessDate: null,
    claimOnlineStatusCode: null,
    claimOnlineSubStatusCode: null,
    claimType: null,
    claimOnlineStatus: null,
    dateLabel: 'Disability Date',
    confirmedLastDayWorkDate: null,
    confirmedDeliveryDate: null,
    estimatedSurgeryDate: null,
    confirmedSurgeryDate: null,
    disabilityDate: '12/05/2017',
    claimOnlineSubStatus: null,
    extensionStatus: null,
    extensionSubStatus: null,
    babyDeathIndicator: null
  },
  {
    rule: '56',
    asmIndicator: 'A',
    coverageCategory: null,
    coverageType: null,
    claimProcessDate: null,
    claimOnlineStatusCode: '05',
    claimOnlineSubStatusCode: '09',
    estimatedSurgeryDate: null,
    dateLabel: 'Last Date Worked',
    confirmedLastDayWorkDate: '2/16/2018',
    confirmedSurgeryDate: null,
    claimType: null,
    claimOnlineStatus: null,
    confirmedDeliveryDate: null,
    disabilityDate: null,
    claimOnlineSubStatus: 'MissingInformation',
    extensionStatus: null,
    extensionSubStatus: null,
    babyDeathIndicator: null,
    sourceSystemId: 'AA'
  },
  {
    rule: '57',
    asmIndicator: 'S',
    coverageCategory: null,
    coverageType: null,
    claimProcessDate: null,
    claimOnlineStatusCode: '05',
    claimOnlineSubStatusCode: '09',
    estimatedSurgeryDate: null,
    dateLabel: 'Last Date Worked',
    confirmedLastDayWorkDate: '2/16/2018',
    confirmedSurgeryDate: null,
    claimType: null,
    claimOnlineStatus: null,
    confirmedDeliveryDate: null,
    disabilityDate: null,
    claimOnlineSubStatus: 'MissingInformation',
    extensionStatus: null,
    extensionSubStatus: null,
    babyDeathIndicator: null,
    sourceSystemId: 'AA'
  },
  {
    rule: '58',
    asmIndicator: 'A',
    coverageCategory: null,
    coverageType: null,
    claimProcessDate: null,
    claimOnlineStatusCode: '05',
    claimOnlineSubStatusCode: '09',
    estimatedSurgeryDate: '2/16/2018',
    dateLabel: 'Estimated Surgery Date',
    confirmedLastDayWorkDate: '2/16/2018',
    confirmedSurgeryDate: null,
    claimType: null,
    claimOnlineStatus: null,
    confirmedDeliveryDate: null,
    disabilityDate: null,
    claimOnlineSubStatus: 'MissingInformation',
    extensionStatus: null,
    extensionSubStatus: null,
    babyDeathIndicator: null,
    sourceSystemId: 'AA'
  },
  {
    rule: '59',
    asmIndicator: 'S',
    coverageCategory: null,
    coverageType: null,
    claimProcessDate: null,
    claimOnlineStatusCode: '05',
    claimOnlineSubStatusCode: '09',
    estimatedSurgeryDate: '2/16/2018',
    dateLabel: 'Estimated Surgery Date',
    confirmedLastDayWorkDate: '2/16/2018',
    confirmedSurgeryDate: null,
    claimType: null,
    claimOnlineStatus: null,
    confirmedDeliveryDate: null,
    disabilityDate: null,
    claimOnlineSubStatus: 'MissingInformation',
    extensionStatus: null,
    extensionSubStatus: null,
    babyDeathIndicator: null,
    sourceSystemId: 'AA'
  },
  {
    rule: '60',
    asmIndicator: 'A',
    coverageCategory: null,
    coverageType: null,
    claimProcessDate: null,
    claimOnlineStatusCode: '05',
    claimOnlineSubStatusCode: '09',
    estimatedSurgeryDate: '2/16/2018',
    dateLabel: 'Surgery Date',
    confirmedLastDayWorkDate: '2/16/2018',
    confirmedSurgeryDate: '2/16/2018',
    claimType: null,
    claimOnlineStatus: null,
    confirmedDeliveryDate: null,
    disabilityDate: null,
    claimOnlineSubStatus: 'MissingInformation',
    extensionStatus: null,
    extensionSubStatus: null,
    babyDeathIndicator: null,
    sourceSystemId: 'AA'
  },
  {
    rule: '61',
    asmIndicator: 'S',
    coverageCategory: null,
    coverageType: null,
    claimProcessDate: null,
    claimOnlineStatusCode: '05',
    claimOnlineSubStatusCode: '09',
    estimatedSurgeryDate: '2/16/2018',
    dateLabel: 'Surgery Date',
    confirmedLastDayWorkDate: '2/16/2018',
    confirmedSurgeryDate: '2/16/2018',
    claimType: null,
    claimOnlineStatus: null,
    confirmedDeliveryDate: null,
    disabilityDate: null,
    claimOnlineSubStatus: 'MissingInformation',
    extensionStatus: null,
    extensionSubStatus: null,
    babyDeathIndicator: null,
    sourceSystemId: 'AA'
  }
];
