import {Component, ElementRef, Input, OnInit, ViewChild} from '@angular/core';

@Component({
  selector: 'app-stacked-bar-chart',
  templateUrl: './stacked-bar-chart.component.html',
  styleUrls: ['./stacked-bar-chart.component.scss']
})
export class StackedBarChartComponent implements OnInit {

  @ViewChild('bar', { static: true }) bar: ElementRef;
  @Input() data;
  @Input() options;
  public canvasWidth;
  public canvasHeight;
  private total;
  private ctx;

  constructor() { }

  ngOnInit() {
    this.ctx = this.initCanvas();
    this.getTotal();
    this.calculateItems();
  }

  initCanvas() {
    const el = this.bar.nativeElement;
    this.canvasWidth = el.width;
    this.canvasHeight = el.height;
    return el.getContext('2d');
  }

  getTotal() {
    this.total = 0;
    for (const prop in this.data) {
      if ( this.data.hasOwnProperty(prop) ) {
        this.total += this.data[prop];
      }
    }
  }

  calculateItems() {
    let itemPercentage; let itemWidth; let startX = 0; let colorIndex = 0;
    const startY = 0;
    if ( this.total <= 0) {
      this.ctx.fillStyle = '#CCCCCC';
      this.ctx.fillRect(startX, startY, this.canvasWidth, this.canvasHeight);
    } else {
      for (const prop in this.data) {
        if ( this.data.hasOwnProperty(prop) ) {
          itemPercentage = this.data[prop] / this.total;
          itemWidth = Math.round(this.canvasWidth * itemPercentage);

          this.ctx.fillStyle = this.options.fillColors[colorIndex];
          this.ctx.fillRect(startX, startY, itemWidth, this.canvasHeight);

          colorIndex++;
          if ( itemWidth > 0 ) {
            startX += itemWidth + 3;
          }
        }
      }
    }
  }
}
