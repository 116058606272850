/*
* This component is the container of all modals
* Used to hold the content of a modal and display
* it in the styles designed.
*
*/

import {AfterViewInit, Component, Input, OnDestroy, OnInit} from '@angular/core';
import {ModalService} from '../../../../shared/services/modal.service';

@Component({
  selector: 'page-spinner',
  templateUrl: './page-spinner.component.html',
  styleUrls: ['page-spinner.component.scss']
})
export class PageSpinnerComponent implements OnInit, AfterViewInit, OnDestroy {

@Input() active: boolean = false;
@Input() activeText: string = '';


  constructor(private modalService: ModalService) {
    //console.log('in constructor of sliding modal');
  }

  /* Subscribing the modal to the service capabilities
  * */
  ngAfterViewInit(): void {

  }
  ngOnInit(): void {
  }

  ngOnDestroy(): void {
  }


}
