/**
 * Created by AC12212 on 3/26/2018.
 */
export interface MissingInfoAlertInfo {
  missingInfoList: MissingInfoListThaa[];
}

export interface MissingInfoListThaa {
  mInfoCode: string;
  mInfoDesc: string;
  mInfoReqInfo: MissingReqInfoThaa[];
}

export class MissingInfoFromAllThaa {
  missingInfoDataFromEmployee: MissingInfoListThaa;
  missingInfoDataFromEmployer: MissingInfoListThaa;
  missingInfoDataFromDoctor: MissingInfoListThaa;
}

export class MissingReqInfoThaa {
  downloadIndicator: string;
  formURL?: any;
  upload: string;
  alertStatus: string;
  claimId: string;
  clickToChat: string;
  completionDate: string;
  deliveryMethod?: any;
  displayValue: string;
  dueDate?: any;
  mInfoReqInfoCd: string;
  initialRequestDate: string;
  requestedDate: string;
  missingInfoRequInfoDesc: string;
  finalFollowUpDate: string;
  informationNeededFrom: string;
  recipient: Recipient[];
  followups: FollowUps[];
  requestDate: string;
  fromDate: string;
  throughDate: string;
  echosignEnable: string;
  attachmentInstanceId: string;
  EsignURL: any;
}

export class Recipient {
  name: string;
  emailAddress: string;
  mailingAddress: string;
  faxNumber: string;
  faxedTo: string;
}

export class FollowUps {
  followupDate: string;
}

export class RecentlyReslovedItemsThaa {
  downloadIndicator: string;
  eSignURL?: any;
  formURL?: any;
  upload: string;
  alertStatus: string;
  claimId: string;
  clickToChat: string;
  completionDate: string;
  deliveryMethod?: any;
  displayValue: string;
  dueDate?: any;
  mInfoReqInfoCd: string;
  missingInfoRequInfoDesc: string;
  informationNeededFrom: string;
  recipient: Recipient[];
  requestDate: string;
  fromDate: string;
  throughDate: string;
  echosignEnable: string;
  attachmentInstanceId: string;
}
export class EmployeeMissingDocumentThaa {
  document: MissingReqInfoThaa;
  displayText: string;
  description: string;
  showByYou: boolean;
  dateEEIR: string;
  constructor() {
    this.showByYou = false;
  }
}

export class EmployerMissingDocumentThaa {
  document: MissingReqInfoThaa;
  displayText: string;
  description: string;
  showEmplDiv: boolean;
  dateERIR: string;
  constructor() {
    this.showEmplDiv = false;
  }
}

export class DoctorMissingDocumentThaa {
  document: MissingReqInfoThaa;
  displayText: string;
  description: string;
  showDoctorDiv: boolean;
  dateDRIR: string;
  constructor() {
    this.showDoctorDiv = false;
  }
}

export class MissingInfoDocThaa {
  docTitle: string;
  checked: boolean;
  docCode: string;
}

export class OWCSMissingItemsResponseThaa {
  id: string;
  name: string;
  forms: MissingItemThaa[];
}

export class Node {
  key: string;
  value: string[];
}

export class DownloadLinkThaa {
  name: string;
  displayName: any;
  description: string;
  isDownloadable: string;
  downloadURL: string;
}

export class MissingItemThaa {
  id: string;
  key: string;
  displayName: any;
  description: string;
  generic: VersionThaa;
  onshore: VersionThaa;
  leave: VersionThaa;
  parentId: string;
}

export class VersionThaa {
  title: string;
  url: string;
  uniqueFileName: string;
  description: string;
  customFileName: string;
  keyword: string;
}


