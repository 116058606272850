
import {catchError, map} from 'rxjs/operators';
import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {env} from '../../../environments/environment-loader';
import {ResponseErrorHandlerService} from './response-error-handler.service';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {StorageManagementService} from './storage-management.service';
import {Activity, ClaimActivity, ClaimActivityHistoryResponse} from '../models/claimActivityHistory.model';
import {AppLabelConstants} from '../constants/appLabel.constants';

/**
 * Created by AC12323 on 11/21/2017.
 */
@Injectable()
export class AetnaRequestExtensionService {
  private claimActivityHistory: ClaimActivity[] = [];
  private owcsHeader;

  getOWCSHeader() {
    return this.owcsHeader;
  }

  setOWCSHeader(owcsHeader) {
    this.owcsHeader = owcsHeader;
  }

  constructor(private errorHandler: ResponseErrorHandlerService,
              private httpClient: HttpClient,
              private sessionManagementService: StorageManagementService) {
  }

  getUpdatedExtesiondateThaa(formData): Observable <any> {
    let caseId = null;
    const curClaimId = this.sessionManagementService.retrieveSessionObject('curClaim');
    const appData = JSON.parse(this.sessionManagementService.retrieveSessionObject('appData')).appData;
    if (curClaimId && JSON.parse(curClaimId) && JSON.parse(curClaimId).curClaim) {
      caseId = JSON.parse(curClaimId).curClaim;
    }
    const request = formData;
    return this.httpClient.post(env.thaaWKABPortalService.getAARequestExtension, request,
      {
        headers: new HttpHeaders()
          .set(AppLabelConstants.CONTENT_TYPE_KEY, AppLabelConstants.APPLICATION_KEY)
      }).pipe(
      map(
        (response: Response) => {
          if (!this.errorHandler.isServiceResponseHasError(response)) {

            return response;
          } else {

            throw response;
          }
        }
      ), catchError((error: any) => this.errorHandler.handleThaaErrorForSubmit(error)), );
    //}
  }


  getClaimActivities(personIdValue, claimEventIdValue): Observable<ClaimActivityHistoryResponse> {
    const appData = JSON.parse(this.sessionManagementService.retrieveSessionObject('appData')).appData;
    const request = {
      personId: personIdValue,
      claimEventId: claimEventIdValue
    };

    return this.httpClient.post(env.thaaWKABPortalService.getAAClaimActivityURL, request,
      {
        headers: new HttpHeaders()
          .set(AppLabelConstants.CONTENT_TYPE_KEY, AppLabelConstants.APPLICATION_KEY)
      }).pipe(
      map(
        (response: ClaimActivityHistoryResponse) => {
          if (!this.errorHandler.isServiceResponseHasError(response)) {
            return response;
          } else {
            throw response;
          }
        }
      ), catchError((error: any) => this.errorHandler.handleError(error)), );
  }


  public getClaimActivityHistory(claimEventId: string): Activity[] {
    let filteredClaimActivityHistory: ClaimActivity[] = [];
    if (this.claimActivityHistory && this.claimActivityHistory.length > 0) {
      filteredClaimActivityHistory = this.claimActivityHistory.filter((claimActivity: ClaimActivity) => claimActivity.claimEventId === claimEventId);
      return (filteredClaimActivityHistory && filteredClaimActivityHistory.length > 0) ? filteredClaimActivityHistory[0].activityHistory : [];
    }
    return [];
  }

  public setClaimActivityHistory(claimEventId: string, activityHistory: Activity[]) {
    let indexOfClaimEventId = -1;
    if (this.claimActivityHistory && this.claimActivityHistory.length > 0) {
      indexOfClaimEventId = this.claimActivityHistory.findIndex((claimActivity: ClaimActivity) => (claimActivity && claimActivity.claimEventId === claimEventId));
    }
    if (indexOfClaimEventId === -1) {
      const newClaimActivityHistory: ClaimActivity = new ClaimActivity();
      newClaimActivityHistory.claimEventId = claimEventId;
      newClaimActivityHistory.activityHistory = activityHistory;
      this.claimActivityHistory.push(newClaimActivityHistory);
    } else {
      this.claimActivityHistory[indexOfClaimEventId].activityHistory = activityHistory;
    }
  }
}
