
import {catchError, map} from 'rxjs/operators';
import {Injectable} from '@angular/core';


import {Observable} from 'rxjs';
import {env} from '../../../environments/environment-loader';


import {ResponseErrorHandlerService} from './response-error-handler.service';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {AppLabelConstants} from '../constants/appLabel.constants';

@Injectable()
export class ThaaConfirmationService {
    constructor( private errorHandler: ResponseErrorHandlerService,
        private httpClient: HttpClient) { }

    thaaConfirmation(request): Observable<any> {
        return this.httpClient.post(env.thaaWKABPortalService.thaaConfirmationURL, request,
        {
            headers: new HttpHeaders()
            .set(AppLabelConstants.CONTENT_TYPE_KEY, AppLabelConstants.APPLICATION_KEY)
        }).pipe(
        map(
        (response: Response) => {
            if (!this.errorHandler.isServiceResponseHasError(response)) {
                return response;
            } else {
                throw response;
            }
        }
        ), catchError((error: any) => this.errorHandler.handleThaaErrorForSubmit(error)), );
    }
}
