import { Injectable } from '@angular/core';
import { ResponseErrorHandlerService } from '@shared/services/response-error-handler.service';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { env } from '@env/environment-loader';
import { AppLabelConstants } from '@shared/constants/appLabel.constants';
import { Observable } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { throwError as observableThrowError } from 'rxjs/internal/observable/throwError';
import { IExpediteClaimRequest, IExpediteClaimResponse } from '@app/cxr/modules/intake/shared/models/intake-content.model';

@Injectable()
export class ExpediteClaimService {
  constructor(private errorHandler: ResponseErrorHandlerService,
    private httpClient: HttpClient) { }
  public getExpediteIndicator(expediteClaimRequest: IExpediteClaimRequest): Observable<IExpediteClaimResponse> {
    return this.httpClient.post(env.thaaWKABPortalService.getExpediteIndicator, expediteClaimRequest,
    {
      headers: new HttpHeaders()
        .set(AppLabelConstants.CONTENT_TYPE_KEY, AppLabelConstants.APPLICATION_KEY)
    }).pipe(map(
      (response: IExpediteClaimResponse) => {
        if (!this.errorHandler.isServiceResponseHasError(response)) {
          return response;
        } else {
          throw response;
        }
      }
    ), catchError((error: any) => observableThrowError(error)));
  }
}
