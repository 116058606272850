import { Injectable } from '@angular/core';
import {NavigationExtras} from '@angular/router';
import {OwcsResponseModel} from '@shared/models/owcs-response.model';
import {AppLabelConstants} from '@shared/constants/appLabel.constants';
import {IntakeLabelConstants} from '@app/cxr/modules/intake/shared/constants/intake-label.constants';
import {INTAKE_OWCS_IDS} from '@app/cxr/modules/intake/shared/JSON/intake-owcs.constants';
import {SessionConstants} from '@shared/constants/session.constants';
import {StorageManagementService} from '@shared/services/storage-management.service';
import {IntakeSessionConstants} from '@app/cxr/modules/intake/shared/constants/intakeSession.constants';
import {ILogRequestModel, ILogResponseModel} from '@shared/models/log.model';
import {env} from '@env/environment-loader';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {map} from 'rxjs/operators';
import {Observable} from 'rxjs/internal/Observable';

@Injectable({
  providedIn: 'root'
})
export class CustomErrorService {
  intakeSessionConstants = IntakeSessionConstants;
  intakeLabelConstants = IntakeLabelConstants;
  appLabelConstants = AppLabelConstants;
  sessionConstants = SessionConstants;

  constructor(private storageManagementService: StorageManagementService,
              private httpClient: HttpClient) { }

  getCustomErrorPageData(isBonding: boolean, genericError?: boolean): NavigationExtras {
    const owcsContent: OwcsResponseModel = this.storageManagementService.getSessionData(this.intakeSessionConstants.NI_OWCS_CONTENT);

    return {
      state: {
        data: {
          IscustomErrorContent: true,
          customErrorIcon: this.appLabelConstants.CUSTOM_ERROR_ICON,
          customErrorHeader: genericError? 'Claim' : isBonding ? this.intakeLabelConstants.BONDING_CLAIM : this.fetchOWCSContent(owcsContent, INTAKE_OWCS_IDS.NI_100_Body),
          customErrorLabel: this.fetchOWCSContent(owcsContent, INTAKE_OWCS_IDS.NI_944_Body),
          customErrorBody: this.fetchOWCSContent(owcsContent, INTAKE_OWCS_IDS.NI_945_Body),
          customErrorReturntoHome: this.fetchOWCSContent(owcsContent, INTAKE_OWCS_IDS.NI_947_Body)?.split('<div>')[1]?.split('</div>')[0],
          customErrorDesc: this.fetchMobileNumber(owcsContent)
        }
      }
    }
  }

  fetchMobileNumber(owcsContent: OwcsResponseModel): string {
    const mobileNumber = this.storageManagementService.getSessionData(this.sessionConstants.CLAIM_MOBILE);
    const mobileNumberOWCS = this.fetchOWCSContent(owcsContent, INTAKE_OWCS_IDS.NI_946_Body);
    if (mobileNumber !== undefined && mobileNumberOWCS) {
      return mobileNumberOWCS?.replace(
        '[Case Specific Claim Office Number]',
        (screen.width < 480 || screen.height < 480) ? '<a href="tel:' + mobileNumber + '">' + mobileNumber + '</a>' : mobileNumber
      );
    } else {
      let customErrorDesc = this.fetchOWCSContent(owcsContent, INTAKE_OWCS_IDS.NI_946A_Body);
      if (screen.width < 480 || screen.height < 480) {
        var phone = customErrorDesc?.match("\\+?\\(?\\d*\\)? ?\\(?\\d+\\)?\\d*([\\s./-]\\d{2,})+")[0];
        customErrorDesc = customErrorDesc?.replace(phone, '<a href="tel:' + phone + '">' + phone + '</a>');
      }
      return customErrorDesc;
    }
  }

  fetchOWCSContent(owcsContent: OwcsResponseModel, contentId: string): string {
    if (owcsContent && owcsContent.data[contentId]) {
      return owcsContent.data[contentId].value;
    }
    return '';
  }

  logError(userId: string, logText: string, loggingLevel: string): Observable<ILogResponseModel> {
    const logRequest: ILogRequestModel = {
      userId,
      logText,
      loggingLevel,
      loggingType: '',
      sessionIdentifier: '',
      profileId: '',
    };
    return this.httpClient.post(env.gbLog.getSessionUrl, JSON.stringify(logRequest),
      {
        headers: new HttpHeaders().set(AppLabelConstants.CONTENT_TYPE_KEY, AppLabelConstants.APPLICATION_KEY)
      })
      .pipe(map((response: ILogResponseModel) => response));
  }

  createLoggingErrorMessage(error: any): string {
    let customErrorMessage = 'Add days submission failed :';
    if (error.status === 400) {
      //Data Power Error
      if (error.error['Error Message']) {
        customErrorMessage = `${customErrorMessage} ${error.error['Error Message']} :`;
      }
      customErrorMessage = `${customErrorMessage} ${error.message} - Data Power Error`;
    } else {
      // API issue
      customErrorMessage += ` ${error?.error?.responseReason || error.message} - API Error`;
    }
    return customErrorMessage;
  }
}
