import {Component, OnInit} from '@angular/core';

@Component({
  selector: 'app-leave-benefits',
  templateUrl: './leave-benefits.component.html',
  styleUrls: ['./leave-benefits.component.scss']
})
export class LeaveBenefitsComponent implements OnInit {
  constructor() { }

  ngOnInit() {
  }

}
