import { HttpEvent, HttpHandler, HttpRequest, HttpResponse } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { AppConstants } from "@app/shared/constants/app.constants";
import { Observable } from "rxjs";
import { env } from "../../../../environments/environment-loader";
import { StorageManagementService } from "../../../shared/services/storage-management.service";
import { ServiceInterceptor } from "../../../shared/utils/ServiceInterceptor";
import { FakeService } from "./fake.service";

@Injectable()
export class MaintenanceServiceInterceptor extends ServiceInterceptor {
  private authenticateImpersonateDEVURL: string =
    "http://lad1jdthd2002.thehartford.com:20000/openam/json/authenticate?realm=krb5&module=CIAM_kerb";
  private authenticateDEVURL: string =
    "https://openam-sb.thehartford.com/openam/json/authenticate?realm=/Customers&authIndexType=service&authIndexValue=EE_LDAP"; //LTD2 CIAM Authenticate JBOSS URL
  private authenticateImpersonateQAURL: string =
    "http://lad1jdthq2025.thehartford.com:20200/openam/json/authenticate?realm=krb5&module=CIAM_kerb";
  private authenticateQAURL: string =
    "http://lad1jdthq2025.thehartford.com:20200/openam/json/authenticate?realm=Customers";
  private authenticatePTEURL: string =
    "http://lad1jdthq2013.thehartford.com:20000/openam/json/authenticate?realm=Customers";

  constructor(
    private storageManagementService: StorageManagementService,
    private fakeService: FakeService,
    router: Router,
  ) {
    super(storageManagementService, router);
  }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (env.mocking) {
      const liveServices = this.storageManagementService.getSessionData("liveServices");
      if (liveServices) {
        for (const key in liveServices) {
          if (req.url.includes(key) && liveServices[key] === true) {
            return next.handle(req);
          }
        }
      }
      return this.fakeService.getMockResponse(req.url, req.method).map((data) => {
        if (data === "none") {
          return new HttpResponse<any>({});
        }
        return new HttpResponse<any>({ body: data });
      });
    } else if (
      req.url === this.authenticateImpersonateDEVURL ||
      req.url === this.authenticateImpersonateQAURL ||
      req.url === this.authenticatePTEURL
    ) {
      return next.handle(req);
    } else if (req.url === this.authenticateDEVURL || req.url === this.authenticateQAURL) {
      return next.handle(req);
    } else if (env.envName === AppConstants.LOCAL || env.envName === AppConstants.LOCAL_QA) {
      const updatedReq = req.clone({
        url: "http://localhost:1337/" + req.url,
      });
      return next.handle(updatedReq);
    } else {
      return next.handle(req);
    }
  }
}
