import {Component} from '@angular/core';


@Component({
  selector: 'sure-route',
  templateUrl: 'sureroute-test-object.component.html',
  styleUrls: []
})
export class SureRouteTestingComponent {


}
