import { Injectable } from '@angular/core';
import { ResponseErrorHandlerService } from '@shared/services/response-error-handler.service';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { env } from '@env/environment-loader';
import { AppLabelConstants } from '@shared/constants/appLabel.constants';
import { Observable, of } from 'rxjs';
import {catchError, map} from 'rxjs/operators';
import { StorageManagementService } from '@app/shared/services/storage-management.service';
import { IntakeSessionConstants } from '../constants/intakeSession.constants';
import { IntakeWorkSchedule } from '../models/intake-work-schedule.model';


@Injectable()
export class  IntakeWorkScheduleService {

  constructor(private errorHandler: ResponseErrorHandlerService,
    private httpClient: HttpClient, private storeManagementService: StorageManagementService) {
  }

  public  workScheduleFunction(personId: string): Observable<IntakeWorkSchedule> {
    const workScheduleList = this.storeManagementService.getIntakeSessionData(IntakeSessionConstants.WORK_SCHEDULE);
    if (workScheduleList ) {
      return of(workScheduleList );
    } else {
      if (personId) {
        return this.httpClient.get(env.gbLeaves.workScheduleApi + '&personId=' + personId,
          {
            headers: new HttpHeaders()
              .set(AppLabelConstants.CONTENT_TYPE_KEY, AppLabelConstants.APPLICATION_KEY)
          }).pipe(map(
            (response: IntakeWorkSchedule) => {
              if (!this.errorHandler.isServiceResponseHasError(response)) {
                this.storeManagementService.setIntakeSessionData(IntakeSessionConstants.WORK_SCHEDULE, response);
                return response;
              } else {
                throw response;
              }
            }
          ), catchError((error: any) => this.errorHandler.handleErrorThaa(error)) , );
      }
    }
  }

}
