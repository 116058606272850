import {Component, Input, OnInit} from '@angular/core';
import {Router} from '@angular/router';
import {User} from '../../../shared/models/user.model';
import {ContentService} from '../../../shared/services/content.service';
import {StorageManagementService} from '../../../shared/services/storage-management.service';
import {SessionConstants} from '../../../shared/constants/session.constants';
import {AppLabelConstants} from '../../../shared/constants/appLabel.constants';
import {AppConstants} from '../../../shared/constants/app.constants';

@Component({
  selector: 'app-confirm-disability-model',
  templateUrl: './confirm-disability-model.component.html',
  styleUrls: ['./confirm-disability-model.component.scss']
})
export class ConfirmDisabilityModelComponent implements OnInit {
  @Input() currentClaim;
  @Input() disability_Message;
  @Input() disability_Status_Message;
  currentUser: User;
  public appConstsnts = AppConstants;
  public appLabelConstants = AppLabelConstants;
  public serviceDate;
  public confirmDisabilityBlock = false;
  public msgBlockConfirmDisability = false;
  public hideCofirmDisabilityBlock = false;
  public claimType = '';
  public claimStatus = '';
  public disabilityDate;
  public coverageCategory;
  public sourceSystemId;
  public LDW;
  private lastDayWork;
  private disabilityLDW;

  constructor(private router: Router, private contentService: ContentService,
              private storageManagementService: StorageManagementService) {
  }

  ngOnInit() {
    this.modelClaims();
    this.claimType = this.currentClaim.claimSubType;
    this.claimStatus = this.currentClaim.claimOnlineStatus;
  }

  modelClaims() {

    let diffDays = null;

    if (this.currentClaim) {
      this.disabilityDate = this.currentClaim.disabilityDate;
      this.lastDayWork = this.currentClaim.sourceSystemId === 'AA' ? this.currentClaim.lastDayWork : this.currentClaim.disabilityDate;
      this.disabilityLDW = this.currentClaim.confirmedLastDayWorkDate;
      this.sourceSystemId = this.currentClaim.sourceSystemId;
      this.coverageCategory = this.currentClaim.coverageCategory;
      this.claimType = this.currentClaim.claimSubType;
      this.claimStatus = this.currentClaim.claimOnlineStatus;
      if (this.disabilityLDW) {
        this.hideCofirmDisabilityBlock = true;
      }
      if (this.lastDayWork) {
        this.lastDayWork = this.lastDayWork.replace(/^(\d{1,2}\/)(\d{1,2}\/)(\d{4})$/, '$2$1$3');
        const currentDate = new Date();
        this.serviceDate = new Date(this.lastDayWork.split('/')[2], this.lastDayWork.split('/')[1] - 1, this.lastDayWork.split('/')[0]);
        const timeDiff = currentDate.getTime() - this.serviceDate.getTime();
        diffDays = timeDiff / (1000 * 3600 * 24);
      }
      if (diffDays >= 0) {
        this.confirmDisabilityBlock = true;
      } else {
        this.msgBlockConfirmDisability = true;
      }
    }
  }


  navToDetailPage(coverageCategory) {
    if (this.currentClaim) {
      this.LDW = this.currentClaim.lastDayWork;
    }
    const LDWDateValue =  new Date(this.LDW);
    const currentDate =  new Date();
    this.LDW = this.LDW.replace(/^(\d{1,2}\/)(\d{1,2}\/)(\d{4})$/, '$2$1$3');
    this.LDW = new Date(this.LDW.split('/')[2], this.LDW.split('/')[1] - 1, this.LDW.split('/')[0]);
    const timeDiff = currentDate.getTime() - this.LDW.getTime();
    const diffDays = timeDiff / (1000 * 3600 * 24);
    if (this.currentClaim && this.currentClaim.sourceSystemId === this.appConstsnts.SOURCE_SYS_ID_DCS) {
      this.storageManagementService.setSessionData(SessionConstants.COV_CATEGORY,  coverageCategory);
      this.storageManagementService.setSessionObject(SessionConstants.CUR_CLAIM,  this.currentClaim);
      this.router.navigate([AppLabelConstants.URL_CONFIRM_DIS_DETAIL]);

    } else if ((this.currentClaim && this.currentClaim.sourceSystemId === this.appConstsnts.SOURCE_SYS_ID_AA) && (diffDays < 1)) {
        this.storageManagementService.setSessionData(SessionConstants.COV_CATEGORY, coverageCategory);
        this.storageManagementService.setSessionObject(SessionConstants.CUR_CLAIM, this.currentClaim);
        this.router.navigate([AppLabelConstants.URL_CONFIRM_DIS_DETAIL]);
      } else {
        this.storageManagementService.setSessionData(SessionConstants.COV_CATEGORY, coverageCategory);
        this.storageManagementService.setSessionObject(SessionConstants.CUR_CLAIM, this.currentClaim);
        this.router.navigate([AppLabelConstants.URL_DIS_THANK_U]);
      }
  }


  navToNewDisabilityDate(coverageCategory) {
    if (this.currentClaim && this.currentClaim.sourceSystemId === this.appConstsnts.SOURCE_SYS_ID_DCS) {
      this.storageManagementService.setSessionData(SessionConstants.COV_CATEGORY, coverageCategory);
      this.storageManagementService.setSessionObject(SessionConstants.CUR_CLAIM, this.currentClaim);
      this.router.navigate([AppLabelConstants.URL_NEW_DIS_DATE]);
    } else if (this.currentClaim && this.currentClaim.sourceSystemId === this.appConstsnts.SOURCE_SYS_ID_AA) {
      this.storageManagementService.setSessionData(SessionConstants.COV_CATEGORY, coverageCategory);
      this.storageManagementService.setSessionObject(SessionConstants.CUR_CLAIM, this.currentClaim);
      this.router.navigate([AppLabelConstants.URL_NEW_DIS_DATE_THAA]);
    }
    }
}
