export const MOCK_EMPLOYEE_TOGGLE = {
  toggleList: [
    {
      toggle: 'allClaimPayments',
      state: true
    },
    {
      toggle: 'productForms',
      state: true
    },
    {
      toggle: 'paperlessPaystub',
      state: true
    },
    {
      toggle: 'taxFormPrintprof',
      state: true
    },
    {
      toggle: 'newIntake',
      state: true
    },
    {
      toggle: 'employeeTHAAStatus',
      state: true
    }
  ],
  responseCode: '001',
  responseReason: 'success'
};
