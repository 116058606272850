import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve } from '@angular/router';
import { StorageManagementService } from '@app/shared/services/storage-management.service';
import { SessionConstants } from '@app/shared/constants/session.constants';
import { Observable } from 'rxjs';
import { LeaveReasonServiceService } from '@app/shared/services/leave-reason-service.service';
import { IntakeOWCSContentService } from '../services/intake-owcs-content.service';
import { ReasonList } from '../models/reasonList.model';
import { IntakeSessionConstants } from '../constants/intakeSession.constants';
import { map, take } from 'rxjs/operators';

@Injectable()
export class  IntakeResolveService implements Resolve<ReasonList[]> {
  public intakeId: string;
  constructor(private storageManagementService: StorageManagementService,
              private leaveReasonService: LeaveReasonServiceService, private intakeOWCSService: IntakeOWCSContentService) {
  }
  resolve(route: ActivatedRouteSnapshot): Observable<ReasonList[]> {
    this.intakeOWCSService.getIntakeOWCSContent();
    const userData = this.storageManagementService.getSessionData( SessionConstants.Employee_Status);
    if(userData){
      this.intakeId = userData.status[0].personID;
    } else {
      this.intakeId = this.storageManagementService.getAppData(SessionConstants.USER_DATA) ? this.storageManagementService.getAppData(SessionConstants.USER_DATA).personIds[0] : null;
    }
    if (this.intakeId && !this.storageManagementService.getSessionData(IntakeSessionConstants.COVERED_REASON_LISTS)) {
      return this.leaveReasonService.getLeaveReason(this.intakeId).pipe(
        take(1),
        map((list: ReasonList[]) => list));
    }
  }
}
