export const MOCK_PROVIDER_RESPONSE ={
        'Providers': [
          {
            'providerId': 0,
            'npiId': 0,
            'firstName': 'test',
            'lastName': 'test',
            'address': 'test',
            'addressLineTwo': 'test',
            'city': 'test',
            'statecode': 'AL',
            'zip': '12345',
            'phoneNumber': '1234567890',
            'fax': '344335553',
            'specialityId': 0
          }
        ],
        'responseCode': '001',
        'responseReason': 'success'
      }

      export const MOCK_PROVIDER_RESPONSE_ERROR ={
        'Providers':  [
          {
            'providerId': '',
            'npiId': 0,
            'firstName': '',
            'lastName': '',
            'address': '',
            'addressLineTwo': '',
            'city': '',
            'statecode': '',
            'zip': '',
            'phoneNumber': '',
            'fax': '',
            'specialityId': 0
          }
        ],
        'responseCode': '999',
        'responseReason': 'Error'
      }
