import { Injectable } from '@angular/core';
import {BehaviorSubject} from 'rxjs';
import {StorageManagementService} from './storage-management.service';
import {SessionConstants} from '../constants/session.constants';
import {AppLabelConstants} from '../constants/appLabel.constants';
import {AppLeaveConst} from '../constants/app.constants';

@Injectable()
export class AdaProductService {

  public isAdaProductSet: BehaviorSubject<boolean> = new BehaviorSubject(false);
  public appData;
  constructor(private storageManagementService: StorageManagementService) { }

  /**
   * function to check if enhanced ada applicable for the person
   */
  isEnhancedAdaApplicable() {
    this.appData = JSON.parse(this.storageManagementService.retrieveSessionObject(SessionConstants.APP_DATA)).appData;
    const adaProduct = this.appData ? this.appData.adaProduct ? this.appData.adaProduct : null : null;
    const provisionResponse = adaProduct ? adaProduct.provisionResponseList ? adaProduct.provisionResponseList[0] : null : null;
    if ( provisionResponse
      && provisionResponse.provisionId === AppLabelConstants.ADA_PROVISION_ID.toString()
      && provisionResponse.provisionOptionId === AppLabelConstants.THAA_ENHANCED_ADA_PROVISION_OPTION_ID.toString()
     ) {
      const enhancedAda = this.storageManagementService.getSessionData(SessionConstants.THAA_ENHANCED_ADA);
      if ( !enhancedAda ) {
        this.storageManagementService.setSessionData(SessionConstants.THAA_ENHANCED_ADA, true);
      }
      return true;
    } else if( provisionResponse
      && provisionResponse.provisionId === AppLabelConstants.ADA_PROVISION_ID.toString()
      && provisionResponse.provisionOptionId === AppLabelConstants.THAA_FUNDAMENTAL_ADA_PROVISION_OPTION_ID.toString()){
        const foundationalAda = this.storageManagementService.getSessionData(SessionConstants.THAA_FOUNDAMENTAL_ADA);
      if ( !foundationalAda ) {
        this.storageManagementService.setSessionData(SessionConstants.THAA_FOUNDAMENTAL_ADA, true);
      }
      return true;
    } else {
      return false;
    }
  }

  /**
   * function to check if an ada leave exists for the person
   */
  isAdaLeaveExists() {
    this.appData = JSON.parse(this.storageManagementService.retrieveSessionObject(SessionConstants.APP_DATA)).appData;
    const userClaims = this.appData ? this.appData.userClaims ? this.appData.userClaims : null : null;
    const claimOverview = userClaims ? userClaims.claimOverview ? userClaims.claimOverview : null : null;
    const leaveClaims = claimOverview ? (claimOverview.leaveClaims && claimOverview.leaveClaims.length > 0) ? claimOverview.leaveClaims : null : null;
    if ( leaveClaims ) {
      return this.appData.userClaims.claimOverview.leaveClaims.some(leaveClaim => leaveClaim.leaveType === AppLeaveConst.ADA_TYPE);
    }
  }
}
