/**
 * Created by AC12256 on 2/9/2018.
 */
import {ClaimLeaveDetails} from '../shared/models/LeaveMappings.model';

export const LEAVEDETAILS: ClaimLeaveDetails[] =
  [
  {
    claimEventId: '13344217',
    leaveId: '800858871952',
    caseId: '663614',
    url: 'https://www.thehartfordatwork.com/sso/SSO?SPEntityID=LeaveManagement&TARGET=https://Oilgear.LeaveMgt.TheHartford.com/router.ashx?cmd=sso'
  }
  ]
