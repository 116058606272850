import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {ActivatedRoute, ParamMap, Router} from '@angular/router';
import {Faq, FAQAsset, FAQResponse, Feedback} from '../../../../shared/models/faq.model';
import {FaqService} from '../../../../shared/services/faqs.service';
import {BlazeService} from '../../../../shared/services/blazeRule.service';
import {PaymentsHistoryService} from '../../../../shared/services/claim-payment-history.service';
import {StorageManagementService} from '../../../../shared/services/storage-management.service';
import {CustomEventService} from '../../../../shared/services/tealium/custom-event.service'
import {FaqCommonService} from '../../../../shared/services/faqCommon.service'
import {AppLabelConstants} from '../../../../shared/constants/appLabel.constants';
import { SessionConstants } from 'app/shared/constants/session.constants';
import { InternalFaqService } from 'app/shared/services/internal-faq.service';
import { ContentService } from '@app/shared/services/content.service';
@Component({
  selector: 'common-questions',
  templateUrl: './common-questions.component.html',
  styleUrls: ['./common-questions.component.scss']
})
export class CommonQuestionsComponent implements OnInit, OnDestroy {

  @Input() commonQsLabel = 'Common Questions';
  @Input() commonQsLabelAlignment = 'left';
  @Input() setBackground = false;

  public FAQs: Faq[] = [];
  public faqResponse: FAQResponse;
  public seeMorelink = AppLabelConstants.SEE_MORE;

  private subscribers = [];


  constructor(private router: Router,
              private faqService: FaqService,
              private blazeRulesService: BlazeService,
              private route: ActivatedRoute,
              private storageManagementService: StorageManagementService,
              private paymentService: PaymentsHistoryService,
              private customEventService: CustomEventService,
              private faqCommonService: FaqCommonService,
              private internalFaqService: InternalFaqService,
              private contentService: ContentService) {

  }

  ngOnInit() {
    this.internalFaqService.getQuestionsThatNeedToBeIncluded();
    this.subscribers[0] = this.route.paramMap
      .subscribe(
        (params: ParamMap) => {
          if (this.subscribers.length) {
            this.subscribers.forEach(
              val => {
                val.unsubscribe();
              }
            );
          }

          if (this.blazeRulesService.ifBlazeNeedsToBeCalled(this.router.url)) {
            if (this.router.url === AppLabelConstants.URL_HOME) {
              if(this.storageManagementService.getSessionData(SessionConstants.PREVIOUS_URL)!==this.router.url){
                this.storageManagementService.removeAttrFromSessionData(SessionConstants.FAQ);
              }
              this.subscribers[1] = this.storageManagementService.getIsUserClaimsSetFlag().subscribe(isServiceResponseComplete => {
                if (isServiceResponseComplete) {
                  this.paymentService.isProvisionsSet.subscribe(
                    (isProvisionsSet: boolean) => {
                      if (isProvisionsSet && this.router.url === AppLabelConstants.URL_HOME) {
                        if (this.blazeRulesService.getIsJSONBOMInvalidated()) {
                         this.blazeRulesService.isCasesDataSetInSession.subscribe(
                           (isCasesDataSetInSession: boolean) => {
                             if (isCasesDataSetInSession === true) {
                               this.callBlazeService();
                               this.blazeRulesService.setIsJSONBOMInvalidated(false);
                               this.blazeRulesService.isCasesDataSetInSession.next(false);
                             }
                           }
                         )
                        } else {
                          this.callBlazeService();
                        }
                      }
                    }
                  )
                }
                }
              );
            } else if (this.router.url.includes(AppLabelConstants.PRODUCT_INFO_STRNG)) {
              if(this.storageManagementService.getSessionData(SessionConstants.PREVIOUS_URL)!==this.router.url){
                this.storageManagementService.removeAttrFromSessionData(SessionConstants.FAQ);
              }
              this.subscribers[2] = this.storageManagementService.curPageSetFlag.subscribe(isServiceResponseComplete => {
                  if (isServiceResponseComplete && this.router.url.includes(AppLabelConstants.PRODUCT_INFO_STRNG)) {
                    this.callBlazeService();
                  }
                }
              );
            } else {
              this.callBlazeService();
            }
          } else {
         /***   'User has navigated back  no need to call Blaze'****/
            this.callBlazeService();
         //   this.FAQs = this.faqService.getFAQs();
          }
        }
      );
  }


  routeToFaqFromQuestion(id) {
    this.storageManagementService.setSessionData(SessionConstants.PREVIOUS_URL, this.router.url);
    this.router.navigate([AppLabelConstants.URL_FAQ + id]);
  }

  trackFaqClick(question) {
    this.customEventService.trackAccordion(question);
  }

  routeFaq() {
    this.storageManagementService.setSessionData(SessionConstants.PREVIOUS_URL, this.router.url);
    this.storageManagementService.removeAttrFromSessionData(SessionConstants.FAQ);
    this.router.navigateByUrl(AppLabelConstants.URL_FAQ_1);
  }

  ngOnDestroy() {
    this.faqService.setFAQs(this.FAQs);
    if (this.subscribers.length) {
      this.subscribers.forEach(
        val => {
          val.unsubscribe();
        }
      );
    }
  }

  private callBlazeService() {
    if(this.storageManagementService.getSessionData(SessionConstants.FAQ)) {
      this.FAQs = this.storageManagementService.getSessionData(SessionConstants.FAQ);
    } else {
      this.blazeRulesService.getFAQBlazeRule().subscribe(
        (faqResponse: FAQResponse) => {
          this.FAQs = this.faqCommonService.blazeSuccess(faqResponse);
          this.storageManagementService.setSessionData(SessionConstants.FAQ, this.FAQs);
        }, this.faqCommonService.blazeError
      );
    }
  }
}
