/**
 * Created by AC12396 on 12/17/2022.
 */

export const CLAIM_REPRESENTATIVE_DETAILS =  {

  claimOwnerName: 'Jennifer Smith',
  available: [
    {DisplayDayDate: 'Monday, February 15, 2021', DisplayTimePeriod: '08: 30 AM Eastern', AvailableDateTime: '04/27/2021 08: 30 AM'},
    {DisplayDayDate: 'Monday, February 15, 2021', DisplayTimePeriod: '09: 00 AM Eastern', AvailableDateTime: '02/15/2021 09: 00 AM'},
    {DisplayDayDate: 'Monday, February 15, 2021', DisplayTimePeriod: '09: 30 AM Eastern', AvailableDateTime: '02/15/2021 09: 30 AM'},
    {DisplayDayDate: 'Monday, February 15, 2021', DisplayTimePeriod: '10: 00 AM Eastern', AvailableDateTime: '02/15/2021 10: 00 AM'},
    {DisplayDayDate: 'Monday, February 15, 2021', DisplayTimePeriod: '10: 30 AM Eastern', AvailableDateTime: '02/15/2021 10: 30 AM'},
    {DisplayDayDate: 'Monday, February 15, 2021', DisplayTimePeriod: '11: 30 AM Eastern', AvailableDateTime: '02/15/2021 11: 30 AM'},
    {DisplayDayDate: 'Monday, February 15, 2021', DisplayTimePeriod: '12: 00 PM Eastern', AvailableDateTime: '02/15/2021 12: 00 PM'},
    {DisplayDayDate: 'Monday, February 15, 2021', DisplayTimePeriod: '12: 30 PM Eastern', AvailableDateTime: '02/15/2021 12: 30 PM'},
    {DisplayDayDate: 'Monday, February 15, 2021', DisplayTimePeriod: '01: 00 PM Eastern', AvailableDateTime: '02/15/2021 01: 00 PM'},
    {DisplayDayDate: 'Monday, February 15, 2021', DisplayTimePeriod: '01: 30 PM Eastern', AvailableDateTime: '02/15/2021 01: 30 PM'},
    {DisplayDayDate: 'Tuesday, February 16, 2021', DisplayTimePeriod: '08: 30 AM Eastern', AvailableDateTime: '02/16/2021 08: 30 AM'},
    {DisplayDayDate: 'Tuesday, February 16, 2021', DisplayTimePeriod: '09: 00 AM Eastern', AvailableDateTime: '02/16/2021 09: 00 AM'},
    {DisplayDayDate: 'Tuesday, February 16, 2021', DisplayTimePeriod: '09: 30 AM Eastern', AvailableDateTime: '02/16/2021 09: 30 AM'},
    {DisplayDayDate: 'Tuesday, February 16, 2021', DisplayTimePeriod: '10: 00 AM Eastern', AvailableDateTime: '02/16/2021 10: 00 AM'},
    {DisplayDayDate: 'Tuesday, February 16, 2021', DisplayTimePeriod: '10: 30 AM Eastern', AvailableDateTime: '02/16/2021 10: 30 AM'},
    {DisplayDayDate: 'Tuesday, February 16, 2021', DisplayTimePeriod: '11: 00 AM Eastern', AvailableDateTime: '02/16/2021 11: 00 AM'},
    {DisplayDayDate: 'Tuesday, February 16, 2021', DisplayTimePeriod: '11: 30 AM Eastern', AvailableDateTime: '02/16/2021 11: 30 AM'},
    {DisplayDayDate: 'Wednesday, February 17, 2021', DisplayTimePeriod: '08: 30 AM Eastern', AvailableDateTime: '02/17/2021 08: 30 AM'},
    {DisplayDayDate: 'Wednesday, February 17, 2021', DisplayTimePeriod: '09: 00 AM Eastern', AvailableDateTime: '02/17/2021 09: 00 AM'},
    {DisplayDayDate: 'Wednesday, February 17, 2021', DisplayTimePeriod: '09: 30 AM Eastern', AvailableDateTime: '02/17/2021 09: 30 AM'},
    {DisplayDayDate: 'Wednesday, February 17, 2021', DisplayTimePeriod: '10: 00 AM Eastern', AvailableDateTime: '02/17/2021 10: 00 AM'},
    {DisplayDayDate: 'Wednesday, February 17, 2021', DisplayTimePeriod: '10: 30 AM Eastern', AvailableDateTime: '02/17/2021 10: 30 AM'},
    {DisplayDayDate: 'Wednesday, February 17, 2021', DisplayTimePeriod: '11: 00 AM Eastern', AvailableDateTime: '02/17/2021 11: 00 AM'},
    {DisplayDayDate: 'Wednesday, February 17, 2021', DisplayTimePeriod: '11: 30 AM Eastern', AvailableDateTime: '02/17/2021 11: 30 AM'},
    {DisplayDayDate: 'Thursday, February 18, 2021', DisplayTimePeriod: '09: 00 AM Eastern', AvailableDateTime: '02/18/2021 09: 00 AM'},
    {DisplayDayDate: 'Thursday, February 18, 2021', DisplayTimePeriod: '09: 30 AM Eastern', AvailableDateTime: '02/18/2021 09: 30 AM'},
    {DisplayDayDate: 'Thursday, February 18, 2021', DisplayTimePeriod: '10: 00 AM Eastern', AvailableDateTime: '02/18/2021 10: 00 AM'},
    {DisplayDayDate: 'Thursday, February 18, 2021', DisplayTimePeriod: '10: 30 AM Eastern', AvailableDateTime: '02/18/2021 10: 30 AM'},
    {DisplayDayDate: 'Thursday, February 18, 2021', DisplayTimePeriod: '11: 00 AM Eastern', AvailableDateTime: '02/18/2021 11: 00 AM'},
    {DisplayDayDate: 'Thursday, February 18, 2021', DisplayTimePeriod: '11: 30 AM Eastern', AvailableDateTime: '02/18/2021 11: 30 AM'},
    {DisplayDayDate: 'Thursday, February 18, 2021', DisplayTimePeriod: '12: 00 PM Eastern', AvailableDateTime: '02/18/2021 12: 00 PM'},
    {DisplayDayDate: 'Friday, February 19, 2021', DisplayTimePeriod: '09: 00 AM Eastern', AvailableDateTime: '02/19/2021 09: 00 AM'},
    {DisplayDayDate: 'Friday, February 19, 2021', DisplayTimePeriod: '09: 30 AM Eastern', AvailableDateTime: '02/19/2021 09: 30 AM'},
    {DisplayDayDate: 'Friday, February 19, 2021', DisplayTimePeriod: '10: 00 AM Eastern', AvailableDateTime: '02/19/2021 10: 00 AM'},
    {DisplayDayDate: 'Friday, February 19, 2021', DisplayTimePeriod: '10: 30 AM Eastern', AvailableDateTime: '02/19/2021 10: 30 AM'},
    {DisplayDayDate: 'Friday, February 19, 2021', DisplayTimePeriod: '11: 00 AM Eastern', AvailableDateTime: '02/19/2021 11: 00 AM'}
  ],
  ConsentMessage:    {
    ConsentId: '100001',
    ConsentMsg: 'If you have a cell phone, The Hartford can send you a text message when important updates are made to your claim. The Hartford will not send you text messages unless you provide permission to do so.  If you decide that you no longer wish to receive text messages from The Hartford, you can stop them by updating your profile online.  Standard message rates apply to any text message that The Hartford sends you.',
    EmployeeConsent: false,
    ConsentOn: true,
    ConsentRequired: false,
    MobileNumber: '123/456-7890',
    HidePopup: false
  },
  EmailId: 'test@thehartford.com',
  ContactNumber: '23434344',
  TimeZoneId: 0,
  Notes: 'testing purpose',
  CallNumber: '866-442-7695',
  Confirmation: '1',
  Reminder: '0',
  responseCode: '001',
  responseReason: 'success'
};

export const LEAVE_REPRESENTATIVE_DETAILS = {
  CallNumber: null,
  Confirmation: null,
  ConsentMessage: null,
  ContactNumber: '   2289132',
  EmailId: 'xyz.abc@helloworld.com',
  Notes: null,
  Reminder: 0,
  TimeZoneId: 0,
  available: [],
  claimOwnerName: 'CLAIM REP',
  directPhoneNumber: '123456789',
  personId: '7522750',
  responseCode: '001',
  responseReason: 'success',
  claimRepresentativeName: 'CLAIM REP'
};
