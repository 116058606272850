import { Resolve, Router } from "@angular/router";
import { SessionConstants } from "@app/shared/constants/session.constants";
import { StorageManagementService } from "@app/shared/services/storage-management.service";
import { Observable, of } from "rxjs";
import { BondingIntakeService } from "../services/bonding/intake-bonding.service";
import { CustomErrorService } from "@app/shared/services/custom-error.service";
import { ICustomerSpecific } from "../models/intake-content.model";
import { catchError, map, take } from "rxjs/operators";
import { AppLabelConstants } from "@app/shared/constants/appLabel.constants";
import { Injectable } from "@angular/core";
import { Intake_Constants } from "../constants/intake.constants";
import { IntakeSessionConstants } from "../constants/intakeSession.constants";

@Injectable({
  providedIn: 'root'
})
export class SpouseSharedLeaveResolverService implements Resolve<boolean> {
  spouseSharedLeaveFlag: boolean
  constructor(private bondingIntakeService: BondingIntakeService, private router: Router, private customErrorService: CustomErrorService, private storageManagementService: StorageManagementService) { }

  resolve(): Observable<boolean> {
    const personId = this.storageManagementService.getAppData(SessionConstants.USER_DATA) ? this.storageManagementService.getAppData(SessionConstants.USER_DATA).personIds[0] : null;

    if (!personId) {
      return of(false);
    }
    if (this.storageManagementService.getIntakeSessionData(IntakeSessionConstants.SPOUSE_SHARED_LEAVE_FLAG) !== undefined) {
      return of(true);
    }
    return this.bondingIntakeService.getCustomerSpecific(personId, Intake_Constants.REASON_ID, Intake_Constants.RELATIONSHIP_ID, true).pipe(
      map((res: ICustomerSpecific) => {
        if (res?.responseCode === '001') {
          const spouseSharedLeaveFlag = res?.toggleList?.find(data => data.toggle === Intake_Constants.SPOUSE_SHARED_LEAVE_TOGGLE)?.state;
          this.storageManagementService.setIntakeSessionData(IntakeSessionConstants.SPOUSE_SHARED_LEAVE_FLAG, spouseSharedLeaveFlag);
          return spouseSharedLeaveFlag || false;
        }
        return false;
      }),
      catchError((error) => {
        this.router.navigate([AppLabelConstants.URL_CUSTOMERROR], this.customErrorService.getCustomErrorPageData(false));
        return of(false);
      })
    );
  }
}

