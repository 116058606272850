
import {of as observableOf, Observable} from 'rxjs';

import {Component, ComponentFactoryResolver, OnInit} from '@angular/core';
import {Router} from '@angular/router';
import {FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {HttpClient, HttpErrorResponse, HttpHeaders} from '@angular/common/http';
import {ProfileSyncService} from '../../../shared/services/profile-sync.service';
import {ProductService} from '../../../shared/services/product.service';
import {SelfLinkingService} from '../../../shared/services/self-linking.service';
import {phaOverviewService} from '../../../shared/services/pha-overview.service';
import {ClaimServiceService} from '../../../shared/services/claim-service.service';
import {PersonService} from '../../../shared/services/people.service';
import {StorageManagementService} from '../../../shared/services/storage-management.service';
import {DepositService} from '../../../shared/services/enrolldeposit.service';
import {ModalService} from '../../../shared/services/modal.service';
import {MessageRibbonService} from '../../../shared/services/message-ribbon.service';

import {Person} from '../../../shared/models/person.model';
import {CLAIMSALT} from '../../../mock-data/mock-claimDetails';
import {env} from '../../../../environments/environment-loader';
import {AuthStateDevService} from '../services/auth-state-dev.service';
import {ValidateMinLength} from '../../../shared/directives/validateMinLength.directive';
import {MOCK_GB_LEAVE_OVERVIEW} from 'app/mock-data/mock-gbLeave';

class MyToken {
  tokenId;
  private successUrl;
  private realm;
  constructor(tokenId: string, successUrl: string, realm: string) {
    this.tokenId = tokenId;
  }

  getTokenId() {
    return this.tokenId
  }
}

@Component({
  selector: 'sample-form',
  templateUrl: 'sample-form.component.html',
  styleUrls: ['sample-form.component.scss']
})
export class SampleFormComponent implements OnInit {

  public componentReadOnly = true;
  public insuredClaims_nonRelatedClaims;
  public insuredClaims_nonRelatedClaims_;
  public insuredClaims_RelatedClaims;
  public insuredClaims_RelatedClaims_;
  public claimEventId = '13107287';
  public claimNeeded: any;
  public claimDecision: string;
  public showFont = false;
  public showButton = false;
  public showModals = false;
  public showCheckbox = false;
  public showRibbons = false;
  public showRadio: boolean = false;
  public showForms = false;
  public showCardCase = false  ;
  public showCardCaseDelay = false;
  public showDocs = false;
  public showTimeline = false;
  public showBankValidate = false;
  public showAuth = false;
  public showService = false;
  public showInputs = false;
  public showReactive = false;
  public showOWCSDownload = false;
  public showReedImp = false;
  public buttonLoading = 'done';
  public showFNOL = false;
  public showCarousel = false;
  public newThawUrl = 'https://ltt-cxr.thehartford.com/thaw';
  public dropdownValues: string[] = [
    'option 1',
    'option 2',
    'option 3',
    'option 4',
    'option 5',
    'option 6'
  ];
  public currentUser: Person = new Person();
  public userform: FormGroup;
  public bankForm: FormGroup;
  public inputTesting: FormGroup;
  public reactiveForm: FormGroup;
  public impersonateTest: FormGroup;
  public frAuthOne = 'http://localhost:1337/lad1jdthd2002.thehartford.com:20000/openam/json/realms/root/realms/Customers/authenticate';
  public frAuthTwo = 'http://lad1jdthd2002.thehartford.com:20000/openam/json/authenticate?realm=Customers&client_id=1009';
  public frAuthThree = 'http://lad1jdthd2002.thehartford.com:20000/openam/json/realms/root/realms/Customers/authenticate';
  public authCallOne = 'http://localhost:1337/int-api.thehartford.com/authorize?client_id=1009';
  //public authCallOne:string ="http://localhost:1337/int-api.thehartford.com/authorize?client_id=1009";
  public authCallTwo = 'https://int-api.thehartford.com/authorize?client_id=1009';
  public contentTypeOne = 'application/x-www-form-urlencoded';
  public contentTypeTwo = 'application/json';
  public responseText: string;
  public ciamProxy = 'http://localhost:1337/openam-service-dev.thehartford.com/openam/json/Customers/sessions?_action=validate';

  public frResponse = 'n/a';
  public frState: string;
  public crResponse = 'n/a';
  public crState: string;
  public srResponse: any = 'n/a';
  public srState: string;
  public authButtonLoad = 'done';
  public appData: any = {};
  public bankPreRendering = false;
  public preRendering = 'skeleton-container';
  private myNum = 0;
  public cardsForCarousel = MOCK_GB_LEAVE_OVERVIEW.LeaveList;

  public carouselSelectedBenefit = 'No Card Selected';
  constructor(private router: Router,
              private authStateDevService: AuthStateDevService,
              private messageRibbonService: MessageRibbonService,
              private modalService: ModalService,
              private depositService: DepositService,
              private storageService: StorageManagementService,
              private componentFactoryResolver: ComponentFactoryResolver,
              private peopleService: PersonService,
              private claimService: ClaimServiceService,
              private phaOverview: phaOverviewService,
              private selfLink: SelfLinkingService,
              private productService: ProductService,
              private profileSyncService: ProfileSyncService,
              private http: HttpClient,
              private fb: FormBuilder,
              private phaoverviewservice: phaOverviewService) { }

  ngOnInit() {
    //Notes:
    //This needs to be built per form in the form's TS file?
    this.initializeObjects();
    //Test items
    this.userform = new FormGroup({
      simple: new FormControl('', []),
      simple2: new FormControl('', []),
      name: new FormControl('', []),
      number: new FormControl('', []),
      email: new FormControl('', []),
      city: new FormControl('', []),
      state: new FormControl('', []),
      address: new FormControl('', []),
      dropdown: new FormControl('',        []),
      toggleLoadingChange: new FormControl('',        []),
      toggleLoadingBlur: new FormControl('',        [])
    });

    this.bankForm = new FormGroup({
      routingNo: new FormControl('',        []),
      theBank: new FormControl('',        [])
    });

    this.inputTesting = this.fb.group({
      name: [],

    });

    this.reactiveForm = this.fb.group({
      fname: ['', [ Validators.required]],
      lname: ['', [ Validators.required]],
      email: ['', [ Validators.required]],
      phone: ['', [ Validators.required,
        (new ValidateMinLength()).validateByValue(5)]],

    });

    this.impersonateTest = this.fb.group({
      smtoken: ['', [ ]],
      reedUrl: ['', [ ]],
    });

    this.storageService.setSessionObject('claimEventId', this.claimEventId);
  }



  sampleFocus() {
    //console.log("This is a sample function called on FOCUS");
  }

  sampleBlur() {
    //console.log("This is a sample function called on BLUR");
  }

  sampleChange() {
    //console.log("this is a sample function called on CHANGE");
  }

  toggleReadOnly() {
    this.componentReadOnly = !this.componentReadOnly;
  }


  toggleLoader() {
    this.buttonLoading = this.buttonLoading === 'done' ? 'loading' : 'done';
  }

  showRibbonSuccess() {
    // console.log("Calling show ribbon");
    this.messageRibbonService.addMessage('success', 'success .......................................................................................................................................................................................................................................................................................................................................................................................................................................................................................................................................................................................................................................................................................................................................................................................................');
  }
  showRibbonError() {
    //  console.log("Calling show ribbon");
    this.messageRibbonService.addMessage('error', 'I\'ve failed');
  }
  showRibbonWarning() {
    // console.log("Calling show ribbon");
    this.messageRibbonService.addMessage('warning', 'This is a warning', 'false');
  }
  showRibbonInfo(autoclose?: string) {
    this.messageRibbonService.addMessage('info', 'Unhelpful information', 'Header', autoclose);
  }
  testRibbonHTML() {
    this.messageRibbonService.addMessage('info', `<div>This is is my test of the&nbsp; OWCS Informational Message for Overview</div><ul> <li> bp1</li> <li> bp2</li> <li> bp3</li></ul><ol> <li> <span style='color: red'>number 1 with red font</span></li> <li> <span style="background-color:#ffff00;">number 2 with yellow background</span></li> <li> <a href="http://www.cnn.com"><span style="background-color:#ffff00;">number 3 link test</span></a></li></ol>  `, 'false');
  }
  showModal() {
    // console.log("Showing Modal");
    this.modalService.openModal('myModal');
  }
  showAltModal() {
    // console.log("Showing Modal");
    this.modalService.openModal('myAltModal');
  }

  closeOtherModal() {
    this.modalService.closeModal('myAltModal');
  }



  toggle(n) {
    console.log('toggling: ' + n);
    switch (n) {
      case 1: this.showFont = !this.showFont; break;
      case 2: this.showButton = !this.showButton; break;
      case 3: this.showModals = !this.showModals; break;
      case 4: this.showCheckbox = !this.showCheckbox; break;
      case 5: this.showRibbons = !this.showRibbons; break;
      case 6: this.showForms = !this.showForms; break;
      case 7: this.showCardCase = !this.showCardCase; break;
      case 8: this.showCardCaseDelay = !this.showCardCaseDelay; break;
      case 9: this.showDocs = !this.showDocs; break;
      case 10: this.showTimeline = !this.showTimeline; break;
      case 11: this.showBankValidate = !this.showBankValidate; break;
      case 12: this.showAuth = !this.showAuth; break;
      case 13: this.showService = !this.showService; break;
      case 14: this.showInputs = !this.showInputs; break;
      case 16: this.showOWCSDownload = !this.showOWCSDownload; break;
      case 17: this.showFNOL = !this.showFNOL; break;
      case 19: this.showReactive = !this.showReactive; break;
      case 20: this.showReedImp = !this.showReedImp; break;
      case 21: this.showCarousel = !this.showCarousel; break;
      case 22: this.showRadio = !this.showRadio; break;
    }

  }


  /*Claim content */
  showTimeLineState() {
    this.initializeObjects();
    this.claimNeeded = CLAIMSALT[0].claimOverview.nonRelatedClaims[0];
    this.claimDecision = 'CLAIM APPROVED';
    this.toggle(10);
  }

  showClaimCard() {

    this.getClaims().subscribe(
      data => {
        console.log(data);
        this.insuredClaims_nonRelatedClaims = data.claimOverview.nonRelatedClaims;
        this.insuredClaims_RelatedClaims = data.claimOverview.relatedClaims;

        setTimeout(() => {
          this.toggleLoading()
        }, 5000);
      }
    );
    this.toggle(7);
  }


  toggleLoading() {
    this.preRendering = this.preRendering === 'skeleton-container removed' ? 'skeleton-container' : 'skeleton-container removed';
  }


  /* Getting the sample data for the claim status */
  getClaims(): Observable<any> {
    return observableOf(CLAIMSALT[0]);
  }



  /* Sample Bank Info component */

  validateRoutingNumber() {
    this.bankPreRendering = true;
    const $routingNumber = this.bankForm.get('routingNo');
    const rNumber = $routingNumber.value;
    const baseEnvMock = env.mocking;
    env.mocking = true;
    if ($routingNumber !== null || $routingNumber !== undefined) {
      this.depositService.getBankValidation($routingNumber.value).subscribe(
        val => {
          setTimeout(() => {
              if (val) {
                this.bankForm.get('theBank').patchValue(val.bankName);
                console.log('Val=');
                console.log(val);
              } else {
                this.bankForm.get('theBank').patchValue('No Bank Found');
              }
              this.bankPreRendering = false;
            }
            , 2000);
        },
        (error) => {
          env.mocking = baseEnvMock;
        }
      );
    }
    env.mocking = baseEnvMock;
  }



  initializeObjects() {
    this.appData.currentPage = this.appData.currentPage || 'preLogin';
    this.appData.userData = this.appData.userData || {};
    this.appData.userData.partyRefId = '51221';
    this.appData.paymentDetails = this.appData.paymentDetails || {};
    this.appData.provisions = this.appData.provisions || {};
    this.appData.coveragesMudi = this.appData.coveragesMudi || {};
    this.appData.profileDetails = this.appData.profileDetails || {};
    this.appData.userClaims = this.appData.userClaims || {};
    this.appData.coveragePlans = this.appData.coveragePlans || {};
    this.appData.leaveService = this.appData.leaveService || {};
    this.appData.claimPayments = this.appData.claimPayments || {};
    this.appData.bankingDetails = this.appData.bankingDetails || {};
    this.appData.reloadClaims = this.appData.reloadClaims || false;

    this.storageService.setSessionObject('appData', this.appData);
    this.storageService.setSessionObject('authorizedEndDate', CLAIMSALT[0].claimOverview.nonRelatedClaims[0].authorizedEndDate );
  }




  /* Authorization Calls*/
  //forgerock token


  callAuthService() {
    this.frState = 'skeleton-container';
    this.authButtonLoad = 'loading';
    this.authStateDevService.getForgeToken().subscribe(
      (data: MyToken) => {
        if (document.cookie) {
          const bodyFrToken = (document.cookie.split(';').find(x => x.includes('CIAMSession')));
          if (bodyFrToken) {
            this.frResponse = bodyFrToken.split('=')[1];
          }
        }
        this.frState = 'skeleton-container removed';
        this.crState = 'skeleton-container';

        this.authStateDevService.getCurrentAuthToken(10, false).subscribe(
          (res: any) => {

            const bodyEeToken = (document.cookie.split(';').find(x => x.includes('EESession')));
            if (bodyEeToken) {
              this.crResponse = bodyEeToken.split('=')[1];
            }
            this.crState = 'skeleton-container removed';
            this.srState = 'skeleton-container';

            setTimeout(() => {
              this.srResponse = 'done';
              this.srState = 'skeleton-container removed';
              this.authButtonLoad = 'done';
            }, 2000);



          },
          (error: HttpErrorResponse) => {
            this.crResponse = 'Error getting Authorization Token | error: ' + error.error + ' | Message: ' +
              error.message + ' | url called: ' + error.url ;
            console.log(error);
            this.crState = 'skeleton-container removed';
            this.srState = 'skeleton-container removed';
            this.authButtonLoad = 'done';
          },
          () => {
          }
        );
      },
      error => {
        this.frResponse = 'Error getting ForgeRock Token | ' + error;
        this.frState = 'skeleton-container removed';
        this.crState = 'skeleton-container removed';
        this.authButtonLoad = 'done';
      },
      () => {

      }
    );
  }

  theServiceCall(): Observable<any> {
    console.log('in the service call itself');
    return this.http
      .get(this.authCallOne,
        {
          headers: new HttpHeaders()
            .set('Content-Type', this.contentTypeTwo)
        }
      );
  }


  getForgeToken(): Observable<any> {
    console.log('in forge rock token|');
    const frToken = this.storageService.retrieveSessionObject('frToken') ? JSON.parse(this.storageService.retrieveSessionObject('frToken')).frToken : null;
    console.log(frToken);
    if (frToken) {
      const mytoken: MyToken = new MyToken(frToken, '', '');
      return observableOf(mytoken);
    }
    return this.http
      .post(this.frAuthTwo, null, {
        headers: new HttpHeaders()
          .set('Content-Type', this.contentTypeTwo)
          .set('X-OpenAM-Username', 'james.ball@thehartford.com')
          .set('X-OpenAM-Password', '1234P@ss')
      });
    /*.set('X-OpenAM-Username', 'ferdinand-test')
          .set('X-OpenAM-Password', 'Doe@YAq6AP5')

           .set('X-OpenAM-Username', 'ferdinand.aliaj@thehartford.com')
          .set('X-OpenAM-Password', 'P@ss1234')

          */
  }


  /* Login Process */
  loginProcess() {
    this.router.navigate(['/home']);
  }


  /*Service Test Cases*/
  leaveTest() {
    const defaultCaseId = '490827';
    const defaultInsuredId = '9003227418';
    this.selfLink.getConcurrentLeaves(['13107287']).subscribe(
      res => {
      }, err => {
        console.log('error in leave sso');
        console.log(err);
      })/* this.selfLink.getLeaveSSO(defaultCaseId).subscribe(
      res => {
        this.storageService.setLeaveSSOURL(res.url);
      },err=>{
        console.log("error in leave sso");
        console.log(err);
      })*/
  }

  econsentGetProfileTest() {
    const businessGroupID = '4';
    const insuredId = '9003227418';
    const caseId = 490827;
    this.peopleService.getUserProfile(businessGroupID, insuredId, caseId).subscribe(
      data => {

      }, err => {
        console.log('error in leave econsent get profile');
        console.log(err);
      }
    )
  }

  phaAvailabilityTest() {
    const caseId = '490827';
    const partyRefId = '957181';
    this.phaOverview.isPulseRecordPresent(caseId, partyRefId).subscribe(
      data => {

      }, err => {
        console.log('error in pha test');
        console.log(err);
      }
    );
  }

  bankvalidationTest() {

    this.depositService.getBankValidation('062004099').subscribe(
      val => {
        console.log('bank validation test');
        console.log(val);
      },
      (error) => {
      }
    );
  }


  coveragePlanTest() {
    const caseId = '490827';
    this.productService.getCoverageService(caseId).subscribe(
      data => {

      }, err => {
        console.log('error in product service get coverage');
        console.log(err);
      }
    );
    this.productService.getCoverageService(caseId).subscribe(
      data => {

      }, err => {
        console.log('error in product service get coverage2');
        console.log(err);
      }
    );
    this.productService.getCoverageService(caseId).subscribe(
      data => {

      }, err => {
        console.log('error in product service get coverage');
        console.log(err);
      }
    );
    this.productService.getCoverageService(caseId).subscribe(
      data => {

      }, err => {
        console.log('error in product service get coverage');
        console.log(err);
      }
    );
    this.productService.getCoverageService(caseId).subscribe(
      data => {

      }, err => {
        console.log('error in product service get coverage');
        console.log(err);
      }
    );
    this.productService.getCoverageService(caseId).subscribe(
      data => {

      }, err => {
        console.log('error in product service get coverage');
        console.log(err);
      }
    );
  }

  coveragePlanTestWithNum() {
    this.myNum++;
    const caseId = '490827';
    this.productService.getCoverageService(caseId, this.myNum).subscribe(
      data => {

      }, err => {
        console.log('error in product service get coverage');
        console.log(err);
      }
    );
    this.myNum++;
    this.productService.getCoverageService(caseId, this.myNum).subscribe(
      data => {

      }, err => {
        console.log('error in product service get coverage2');
        console.log(err);
      }
    );
    this.myNum++;
    this.productService.getCoverageService(caseId, this.myNum).subscribe(
      data => {

      }, err => {
        console.log('error in product service get coverage');
        console.log(err);
      }
    );
    this.myNum++;
    this.productService.getCoverageService(caseId, this.myNum).subscribe(
      data => {

      }, err => {
        console.log('error in product service get coverage');
        console.log(err);
      }
    );
    this.myNum++;
    this.productService.getCoverageService(caseId, this.myNum).subscribe(
      data => {

      }, err => {
        console.log('error in product service get coverage');
        console.log(err);
      }
    );
    this.myNum++;
    this.productService.getCoverageService(caseId, this.myNum).subscribe(
      data => {

      }, err => {
        console.log('error in product service get coverage');
        console.log(err);
      }
    );
  }

  bigTest() {
    this.coveragePlanTest();
    this.bankvalidationTest();
    this.phaAvailabilityTest();
    this.econsentGetProfileTest();
    this.leaveTest();
  }

  bigTestWithNum() {
    this.coveragePlanTestWithNum();
    this.bankvalidationTest();
    this.phaAvailabilityTest();
    this.econsentGetProfileTest();
    this.leaveTest();
  }




  putCallTest() {
    this.profileSyncService.profileSync(
      '1234567890',
      'myEmail@myEmail.com',
      'firstname',
      'lastname',
      '9003227418'
    ).subscribe(
      val => {
        console.log('Calling profile sync');
        console.log(val);
        console.log('end of profile sync');

      },
      error => {
        console.log('error occured');
        console.log(error);
      }
    )
  }

  searchDecisionLetter(isAll) {
    let request;
    if ( isAll ) {
      request = JSON.stringify(
        {
          retrievalType: 'gbMU',
          page: 1,
          itemsPerPage: 100,
          sortAttribute : 'SourceCreationDate',
          sortExpression : 'DESC',
          insuredId: '9003227418',
          searchAttributes: [
            {
              attribute: 'insuredId',
              value: '9003227418'
            },
            {
              attribute: 'DocumentSourceSystemName',
              value: 'ALL'
            }
          ],
          searchResultAttributes: [
            'HIGDocumentIdentifier',
            'DocumentType',
            'DocumentCreationDate',
            'SourceCreationDate',
            'DocumentId',
            'DocumentFileName'
          ]
        }

      );
    } else {
      request = JSON.stringify(
        {  // to retrieve mubatch, muscan documents only
          retrievalType: 'gbMU',
          page: 1,
          itemsPerPage: 5,
          sortAttribute : 'SourceCreationDate',
          sortExpression : 'ASC',
          letterId: '113213',
          searchAttributes: [
            {
              attribute: ' letterId ',
              value: '113213'
            },
            {
              attribute: ' DocumentSourceSystemName',
              value: 'ALL'
            }
          ],
          searchResultAttributes: [
            'HIGDocumentIdentifier ',
            'DocumentType',
            ' DocumentCreationDate ',
            'SourceCreationDate',
            'DocumentId'
          ]
        }
      );
    }
    this.http.post((env.documentum.documentumURL_search), request,
      {
        headers: new HttpHeaders()
          .set('Content-Type', 'application/json')
      }).subscribe(
      res => {
        console.log('Response Received: ', res);
      }, err => {
        console.log('Error Received: ', err);
      }
    );

  }
  searchDLWithService() {
    this.phaoverviewservice.searchForLetter('9003227418').subscribe(
      res => {
        console.log(res);
      }
    )
  }
  viewDLWithService(event) {
    event.preventDefault();
    this.phaoverviewservice.viewLetter('9003227418', '09000423800e0996').subscribe(res => {
      const blob = new Blob([res], {type: 'application/octet-stream'});
      const link = document.createElement('a');
      link.href = window.URL.createObjectURL(blob);
      link.download = 'MU20180402120000000000002.pdf';
      link.click();
    });
  }
  viewDecisionLetter(isLetterId) {
    let request;
    if (isLetterId) {
      request = JSON.stringify({
        retrievalType: 'gbMU',
        insuredId: '9003227418',
        letterId: '1701',
        searchAttributes: [
          {
            attribute: 'DocumentId',
            value: '09000423800e0996'
          },
          {
            attribute: 'insuredId ',
            value: '9003227418'
          },
          {
            attribute: 'letterId ',
            value: '1701'
          }
        ]
      });
    } else {

      request = JSON.stringify(
        {
          retrievalType: 'gbMU',
          insuredId: '9003227418',
          searchAttributes: [
            {
              attribute: 'DocumentId',
              value: '09000423800e0996'
            },
            {
              attribute: 'insuredId',
              value: '9003227418'
            }
          ]
        }

      );
    }
    this.http.post((env.documentum.documentumURL_view), request,
      {
        headers: new HttpHeaders()
          .set('Content-Type', 'application/json')
      }).subscribe(
      res => {
        console.log('Response Received: ', res);
      }, err => {
        console.log('Error Received: ', err);
      }
    );
  }


  ssoToReed() {
    //URL
    const ssoToReedURL = 'https://qa.thehartfordatwork.com/sso/SSO?SPEntityID=LeaveManagement&TARGET=https://USBank.BAT-ssleavemgt.thehartford.com/router.ashx?cmd=sso';
    const sysadminToken = this.impersonateTest.get('smtoken');
    window.open(ssoToReedURL);
    //  window.open('https://mybenefits.thehartford.com');

  }

  benefitSelected(benefit) {
    this.carouselSelectedBenefit = benefit;
  }

}





interface CrToken extends Response {
  access_token: string;
  expires_in: number;
  token_type: string;
}
