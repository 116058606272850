import {Component, Input, OnInit} from '@angular/core';
import {DocumentService} from '../../../shared/services/claim-documents.service';
import {StorageManagementService} from '../../../shared/services/storage-management.service';
import {Router} from '@angular/router';
import {ContentService} from '../../../shared/services/content.service';
import {CustomEventService} from '../../../shared/services/tealium/custom-event.service';
import {RelatedClaims} from '../../../shared/models/relatedClaims.model';
import {AppConstants} from '../../../shared/constants/app.constants';
import {AppLabelConstants} from '../../../shared/constants/appLabel.constants';
import {SessionConstants} from '../../../shared/constants/session.constants';


@Component({
  selector: 'claim-document-details',
  templateUrl: 'claim-document-details.component.html',
  styleUrls: ['claim-document-details.component.scss']
})
export class ClaimDocumentDetailsComponent implements OnInit {
  @Input() private numberOfDocuments: number;
  public appConstant = AppConstants;
  public appLabelConstant = AppLabelConstants;
  public documents = [];
  public claimEventId: string;
  public insuredId: string;
  public preRendering = 'skeleton-container';
  public erroredOut: boolean = false;
  public currentRoute;
  public checkCurrentRoute: boolean = false;
  public yourclaimdoc: string;
  public missingItems;
  public uploadingText: string;
  public downloading: boolean = false;
  public claimId: string;
  public leaveId: string;
  public personId: string;
  public curClaim: RelatedClaims;
  public curleave;
  public isClaimAA: boolean = false;
  public appData;
  public navigateFromClaim: boolean;
  private pageId;
  private owscContent;
  private runAttempts: number = 0;
  isFileUploadDisabled: boolean = false;

  constructor(private doc: DocumentService,
              private storageManageMentService: StorageManagementService,
              private contentService: ContentService,
              private customEventService: CustomEventService,
              private router: Router) {
    this.currentRoute = router.url;
  }




  ngOnInit() {
    this.isFileUploadDisabled = this.storageManageMentService.getSessionData(AppConstants.IS_UPLOAD_DISABLED);
    if (this.storageManageMentService.getSessionData(SessionConstants.CURRENT_PAGE) &&  this.storageManageMentService.getSessionData(SessionConstants.CURRENT_PAGE).includes(AppLabelConstants.URL_CLAIM_DETAILS)) {
      this.navigateFromClaim = true;
    } else if (this.storageManageMentService.getSessionData(SessionConstants.CURRENT_PAGE) &&  this.storageManageMentService.getSessionData(SessionConstants.CURRENT_PAGE) === AppLabelConstants.URL_LEAVE_DETAILS) {
      this.navigateFromClaim = false;
    }
    if (this.currentRoute.includes(AppLabelConstants.URL_CLAIM_DETAILS) || this.navigateFromClaim) {
      this.curClaim = this.storageManageMentService.getSessionData(SessionConstants.CUR_CLAIM);
      this.appData = JSON.parse(this.storageManageMentService.retrieveSessionObject(SessionConstants.APP_DATA)).appData;
      this.isClaimAA = this.curClaim.sourceSystemId === AppConstants.SOURCE_SYS_ID_AA;
      if (this.isClaimAA) {
        this.personId = this.curClaim.personId;
        this.insuredId = '';
        this.claimId = JSON.parse(this.storageManageMentService.getSessionData(SessionConstants.CUR_CLAIM).claimEventId);
        if (this.storageManageMentService.retrieveSessionObject(SessionConstants.MISSING_ITEMS)) {
          this.missingItems = JSON.parse(this.storageManageMentService.retrieveSessionObject(SessionConstants.MISSING_ITEMS)).missingItems;
        }
      } else {
          this.insuredId = this.appData.userData.insuredId;
          this.personId = '';
          this.claimEventId = JSON.parse(this.storageManageMentService.getSessionData(SessionConstants.CUR_CLAIM).claimEventId);
           if (this.storageManageMentService.retrieveSessionObject(SessionConstants.MISSING_ITEMS)) {
          this.missingItems = JSON.parse(this.storageManageMentService.retrieveSessionObject(SessionConstants.MISSING_ITEMS)).missingItems;
        }
      }
    }
    if (this.currentRoute === AppLabelConstants.URL_LEAVE_DETAILS ||  !this.navigateFromClaim) {
      this.curleave = this.storageManageMentService.getSessionData(SessionConstants.CURR_LEAVE);
      this.claimEventId = '';
      this.claimId = '';
      this.insuredId = '';
      if (this.storageManageMentService.getSessionData(SessionConstants.CURR_LEAVE)) {
        this.leaveId = this.curleave.leaveId;
        this.personId = this.curleave.personId;
      }
    }
    const docReq = this.doc.getDocDetails(1, this.numberOfDocuments, this.claimEventId, this.insuredId, this.claimId, this.leaveId, this.personId);
    this.runDocumentGet(docReq);
    this.owcsContentFetch();
    if (this.currentRoute.includes(AppLabelConstants.URL_CLAIM_DETAILS) || this.currentRoute === AppLabelConstants.URL_LEAVE_DETAILS) {
      this.checkCurrentRoute = true;
    } else {
      this.checkCurrentRoute = false;
    }
  }

  runDocumentGet(docReq) {
    this.runAttempts++;
    this.uploadingText = 'Pending' +
      (this.runAttempts % 3 === 1 ? '..' : '') +
      (this.runAttempts % 3 === 2 ? '...' : '') +
      (this.runAttempts % 3 === 0 ? '.' : '');

    docReq.subscribe(
      data => {
        if (data) {
          this.documents = data;
          if (this.documents.length > 0 && this.runAttempts < 10) {
            if (!(this.documents[0].SourceCreationDate) || this.documents[0].SourceCreationDate.length === 0) {
              setTimeout(() => {
                this.runDocumentGet(docReq);
              }, 2000);
            }
          }
        } else {
          this.erroredOut = true;
        }
        this.preRendering = '';
      }
    );
  }

  /** Service call to load a claiment document to their device
   *
   * @param docId: current document id file to send
   * @param name: name of the file to be sent
   *
   * */
  showDocument(docId, name) {
    this.downloading = true;
    this.doc.viewDocument(this.claimEventId, this.insuredId, this.claimId, this.leaveId, this.personId, docId.toString(), name).subscribe(
      data => {
        this.downloading = false;
      },
      err => {
        this.downloading = false;
      }
    );
  }

  trackDocumentsClick(linkTitle) {
    this.customEventService.trackAccordion(linkTitle);
  }

  owcsContentFetch() {
    if (this.storageManageMentService.getSessionData(SessionConstants.OWCS_CONTENT)) {
      this.fetchOWCSContentFromSession();
    } else {
      this.fetchOWCSContentFromService();
    }
  }

  fetchOWCSContentFromService() {
    this.pageId = this.contentService.getPageId(AppConstants.CLAIM_DETAILS);
    if (this.pageId) {
      this.contentService.getOWCSResponse(this.pageId, AppConstants.CLAIM_DETAILS_U).subscribe(
        val => {
          if (val) {
            this.owscContent = val;
            if (this.owscContent && this.owscContent.data) {
              if (this.owscContent.data.CD_28_Label) {
                this.yourclaimdoc = this.owscContent.data.CD_28_Label.value;
              }
              this.storageManageMentService.setSessionData(SessionConstants.OWCS_CONTENT, this.owscContent.data);
            }
          }
        });
    }
  }

  fetchOWCSContentFromSession() {
    this.owscContent = this.storageManageMentService.getSessionData(SessionConstants.OWCS_CONTENT);
    if (this.owscContent.CD_28_Label) {
      this.yourclaimdoc = this.owscContent.CD_28_Label.value;
    }
  }
}
