import {Component, Input, OnInit, AfterViewInit, HostListener} from '@angular/core';
import {MessageRibbonService} from '../../../../shared/services/message-ribbon.service';
import {AppConstants} from '../../../../shared/constants/app.constants';
import {AppLabelConstants} from '../../../../shared/constants/appLabel.constants';
import {DomSanitizer, SafeResourceUrl} from '@angular/platform-browser';

@Component({
  selector: 'message-ribbon',
  templateUrl: 'message-ribbon.component.html',
  styleUrls: ['message-ribbon.component.scss']
})
export class MessageRibbonComponent implements OnInit, AfterViewInit {

  @Input() active: boolean;
  @Input() messageType: string;
  @Input() messageContent: string;
  @Input() messageAutoClose: string;
  @Input() ribbonIndex: number;
  @Input() messageHeader: string;
  @Input() messageName: string;
  CLOSE_TIMEOUT = AppConstants.CLOSE_TIMEOUT; // Timer set by DB according to Jim Varney
  public appLabelConstants = AppLabelConstants;
  public sanitizedMessageContent: SafeResourceUrl;

  constructor(private messageRibbonService: MessageRibbonService,
              public sanitizer: DomSanitizer) {
  }

  @HostListener('keyup.enter', ['$event'])
  onKeyUpEnter(event) {
    event.preventDefault();
    event.target.click();
  }

  ngOnInit() {
    this.sanitizedMessageContent = this.messageContent ? this.sanitizer.bypassSecurityTrustHtml(this.messageContent) : '';
    if (this.messageAutoClose && this.messageAutoClose.toLowerCase() === AppConstants.TRUE) {
      setTimeout( () => {
        this.closeRibbon();
      }, this.CLOSE_TIMEOUT);
    }
  }
  ngAfterViewInit() {
    const elmnt = document.getElementById('msgRibbonCloseId');
    if (elmnt) {
      elmnt.scrollIntoView(true);
    }
  }

  closeRibbon() {
    /*
    if (this && this.animationTimer !== null && this.animationTimer > 0) {
      this.animationTimer = this.animationTimer - 1;
      requestAnimationFrame(this.closeRibbon.bind(this));
    } else if (this && this.animationTimer !== null && this.animationTimer <= 0) {
      this.messageRibbonService.removeRibbon(this.ribbonIndex);
    } else {
      this.messageRibbonService.removeRibbon(this.ribbonIndex);
    } */
    this.messageRibbonService.removeRibbon(this.messageName);
  }

}
