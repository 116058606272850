/**
 * Created by ACC22025 on 09/04/2020.
 */

export const MISSINGINFO_THAA_MOCK  = {
  MissingInfoAlertInfo: [{
    recentlyResolvedItemsCount: '1',
    requiredByDate: '12/01/2020',
    missingInfoList: [
      {
        mInfoCode: '10023364',
        mInfoDesc: 'Employer',
        mInfoReqInfo: [{
          alertStatus: 'Open',
          claimId: '1686545',
          clickToChat: '1002',
          completionDate: '8/08/2020',
          initialRequestDate: '01/01/2021',
          requestedDate: '01/01/2021',
          finalFollowUpDate: '3/02/2021',
          followups: [
            {followupDate: '1/19/2021'},
            {followupDate: '2/10/2021'},
            {followupDate: '02/20/2021'},
            {followupDate: '3/01/2021'},
          ],
          deliveryMethod: null,
          displayValue: 'Gap Information',
          downloadIndicator: 'false',
          dueDate: '8/12/2020',
          eSignURL: null,
          formURL: null,
          throughDate: '01/01/2021',
          mInfoReqInfoCd: '748942',
          missingInfoRequInfoDesc: ' Information about how you were covered if there was some time between your last day of work and the start of your disability. The timeframe needed is from 02/06/2020-02/13/2020',
          recipient: [],
          requestDate: null,
          upload: '1002'
        },
          {
            alertStatus: 'Open',
            claimId: '1686545',
            clickToChat: '1002',
            completionDate: '8/19/2020',
            initialRequestDate: '08/02/2020',
            requestedDate: '08/02/2020',
            deliveryMethod: null,
            displayValue: 'Wage Detail',
            downloadIndicator: 'false',
            dueDate: null,
            eSignURL: null,
            formURL: null,
            followups: [
              {followupDate: '8/15/2020'},
              {followupDate: '09/20/2020'}],
            mInfoReqInfoCd: '748946',
            missingInfoRequInfoDesc: ' Information about how you were covered if there was some time between your last day of work and the start of your disability. The timeframe needed is from 02/06/2020-02/13/2020',
            recipient: [
              {
                name: 'Your Employer',
                emailAddress: 'youremployer@employ.com',
                mailingAddress: null,
                faxNumber: null,
                faxedTo: null
              }
            ],
            requestDate: null,
            upload: '1002'
          },
          {
            alertStatus: 'Open',
            claimId: '1686545',
            clickToChat: '1002',
            completionDate: '8/19/2020',
            initialRequestDate: '08/03/2020',
            requestedDate: '08/03/2020',
            deliveryMethod: null,
            displayValue: 'Job Description',
            downloadIndicator: 'false',
            dueDate: '8/19/2020',
            eSignURL: null,
            formURL: null,
            mInfoReqInfoCd: '748943',
            missingInfoRequInfoDesc: 'Test Alert',
            recipient: [
              {
                name: 'Your Employer',
                emailAddress: null,
                mailingAddress: '123 Happy Lane, Windsor, CT, 06043',
                faxNumber: null,
                faxedTo: null
              }
            ],
            requestDate: '8/13/2020',
            upload: '1002'
          },
          {
            alertStatus: 'Open',
            claimId: '1686545',
            clickToChat: '1002',
            completionDate: '8/19/2020',
            initialRequestDate: '08/03/2020',
            requestedDate: '08/03/2020',
            deliveryMethod: null,
            displayValue: 'Job Description',
            downloadIndicator: 'false',
            dueDate: '8/19/2020',
            eSignURL: null,
            formURL: null,
            mInfoReqInfoCd: '748943',
            missingInfoRequInfoDesc: 'Test Alert',
            recipient: [
              {
                name: 'Your Employer',
                emailAddress: null,
                mailingAddress: '123 Happy Lane, Windsor, CT, 06043',
                faxNumber: null,
                faxedTo: null
              }
            ],
            requestDate: '8/13/2020',
            upload: '1002'
          }
        ]
      },

      {
        mInfoCode: '10023362',
        mInfoDesc: 'Doctor',
        mInfoReqInfo: [{
          alertStatus: 'Open',
          claimId: '1686545',
          clickToChat: '1002',
          completionDate: '8/19/2014',
          deliveryMethod: null,
          displayValue: 'Restrictions and Limitations',
          downloadIndicator: 'false',
          initialRequestDate: '08/01/2020',
          requestedDate: '08/01/2020',
          finalFollowUpDate: '12/02/2021',
          followups: [
            {followupDate: '9/19/2020'},
            {followupDate: '10/01/2020'},
          ],
          dueDate: '8/7/2020',
          eSignURL: null,
          formURL: null,
          mInfoReqInfoCd: '736392',
          fromDate: '1/12/2021',
          informationNeededFrom: 'Ed',
          missingInfoRequInfoDesc: ' Information about how you were covered if there was some time between your last day of work and the start of your disability. The timeframe needed is from 02/06/2020-02/13/2020',
          recipient: [],
          requestDate: '8/19/2020',
          upload: '1001'
        },
          {
            alertStatus: 'Open',
            claimId: '1686545',
            clickToChat: '1002',
            completionDate: '8/19/2014',
            deliveryMethod: null,
            displayValue: 'Restrictions and Limitations',
            downloadIndicator: 'false',
            initialRequestDate: '08/01/2020',
            requestedDate: '08/01/2020',
            finalFollowUpDate: '12/02/2021',
            followups: [
              {followupDate: '9/19/2020'},
              {followupDate: '10/01/2020'},
            ],
            dueDate: '8/7/2020',
            eSignURL: null,
            formURL: null,
            mInfoReqInfoCd: '736392',
            fromDate: '1/12/2021',
            throughDate: '1/15/2021',
            informationNeededFrom: 'Bob',
            missingInfoRequInfoDesc: ' Information about how you were covered if there was some time between your last day of work and the start of your disability. The timeframe needed is from 02/06/2020-02/13/2020',
            recipient: [],
            requestDate: '8/19/2020',
            upload: '1001'
          },
          {
            alertStatus: 'Open',
            claimId: '1686545',
            clickToChat: '1002',
            completionDate: '8/19/2014',
            initialRequestDate: '08/01/2019',
            requestedDate: '08/01/2019',
            deliveryMethod: null,
            displayValue: 'Doctor\'s Note',
            downloadIndicator: 'false',
            dueDate: '8/7/2020',
            eSignURL: null,
            formURL: null,
            mInfoReqInfoCd: '30',
            missingInfoRequInfoDesc: ' Information about how you were covered if there was some time between your last day of work and the start of your disability. The timeframe needed is from 02/06/2020-02/13/2020',
            recipient: [
              {
                name: 'Joe',
                emailAddress: 'joe@j.com',
                mailingAddress: null,
                faxNumber: null,
                faxedTo: null
              }
            ],
            requestDate: '8/19/2020',
            upload: '1001'
          },
          {
            alertStatus: 'Open',
            claimId: '1686545',
            clickToChat: '1002',
            completionDate: '8/19/2020',
            initialRequestDate: '01/01/2020',
            requestedDate: '01/01/2020',
            deliveryMethod: null,
            displayValue: 'Attending Physician\'s Statement',
            downloadIndicator: 'false',
            dueDate: null,
            eSignURL: null,
            formURL: null,
            mInfoReqInfoCd: '17',
            fromDate: '01/08/2021',
            missingInfoRequInfoDesc: 'Test Alert',
            recipient: [
              {
                name: 'Amit Y',
                emailAddress: null,
                mailingAddress: '123 Deer Lane Windsor Locks, CT',
                faxNumber: null,
                faxedTo: null
              }
            ],
            requestDate: '8/19/2020',
            upload: '1001'
          },
          {
            alertStatus: 'Open',
            claimId: '1686545',
            clickToChat: '1002',
            completionDate: '8/19/2020',
            initialRequestDate: '03/01/2020',
            requestedDate: '03/01/2020',
            deliveryMethod: null,
            displayValue: 'Attending Physician\'s Statement',
            downloadIndicator: 'false',
            dueDate: null,
            eSignURL: null,
            formURL: null,
            mInfoReqInfoCd: '18',
            fromDate: '01/01/2021',
            missingInfoRequInfoDesc: 'Test Alert',
            recipient: [],
            requestDate: '8/19/2020',
            upload: '1001'
          },
          {
            alertStatus: 'Open',
            claimId: '1686545',
            clickToChat: '1002',
            completionDate: '8/19/2020',
            initialRequestDate: '03/01/2020',
            requestedDate: '03/01/2020',
            deliveryMethod: null,
            displayValue: 'Attending Physician\'s Statement',
            downloadIndicator: 'false',
            dueDate: null,
            eSignURL: null,
            formURL: null,
            mInfoReqInfoCd: '18',
            fromDate: '01/01/2021',
            throughDate: '01/02/2020',
            missingInfoRequInfoDesc: 'Test Alert',
            recipient: [],
            requestDate: '8/19/2020',
            upload: '1001'
          }]
      },
      {
        mInfoCode: '10023366',
        mInfoDesc: 'Employee',
        mInfoReqInfo: [
          {
            echosignEnable: 'true',
            alertStatus: 'Open',
            claimId: '1686545',
            clickToChat: '1002',
            completionDate: '8/19/2020',
            deliveryMethod: null,
            displayValue: 'Medical History Form',
            initialRequestDate: '08/01/2021',
            requestedDate: '08/01/2021',
            followups: [],
            finalFollowUpDate: null,
            downloadIndicator: 'YES',
            dueDate: '8/15/2021',
            eSignURL: null,
            formURL: null,
            fromDate: '01/01/2021',
            attachmentInstanceId : '658622',
            informationNeededFrom: 'Amit',
            throughDate: '01/01/2020',
            mInfoReqInfoCd: '709081',
            missingInfoRequInfoDesc: ' Information about how you were covered if there was some time between your last day of work and the start of your disability. The timeframe needed is from 02/06/2020-02/13/2020',
            recipient: [],
            requestDate: '8/1/2020',
            upload: '1001'
          },
          {
            echosignEnable: 'false',
            alertStatus: 'Open',
            claimId: '1686545',
            clickToChat: '1002',
            completionDate: '08/19/2020',
            deliveryMethod: null,
            displayValue: 'Workers Compensation Denial Letter',
            downloadIndicator: 'false',
            initialRequestDate: '01/01/2019',
            requestedDate: '01/01/2019',
            dueDate: null,
            eSignURL: null,
            formURL: null,
            fromDate: '01/01/2021',
            throughDate: '01/05/2021',
            attachmentInstanceId : '658622',
            mInfoReqInfoCd: '03',
            followups: [
              {followupDate: '1/19/2021'},
              {followupDate: '2/10/2021'},
              {followupDate: '02/20/2021'},
              {followupDate: '3/01/2021'},
            ],
            missingInfoRequInfoDesc: 'Test Alert',
            recipient: [
              {
                name: 'Amit Y',
                emailAddress: 'aY@ay.com',
                mailingAddress: null,
                faxNumber: null,
                faxedTo: null
              }
            ],
            requestDate: '8/19/2020',
            upload: '1001'
          },
          {
            echosignEnable: 'true',
            attachmentInstanceId : '658622',
            alertStatus: 'Open',
            claimId: '1686545',
            clickToChat: '1002',
            completionDate: '8/19/2020',
            deliveryMethod: null,
            displayValue: 'Medical History Form',
            downloadIndicator: 'YES',
            initialRequestDate: '06/01/2020',
            requestedDate: '06/01/2020',
            dueDate: '8/15/2021',
            eSignURL: null,
            formURL: null,
            fromDate: '01/09/2021',
            throughDate: '01/21/2021',
            informationNeededFrom: null,
            mInfoReqInfoCd: '709080',
            missingInfoRequInfoDesc: ' Information about how you were covered if there was some time between your last day of work and the start of your disability. The timeframe needed is from 02/06/2020-02/13/2020',
            recipient: [],
            requestDate: '8/1/2020',
            upload: '1001'
          },
          {
            echosignEnable: 'false',
            attachmentInstanceId : '658622',
            alertStatus: 'Open',
            claimId: '1686545',
            clickToChat: '1002',
            completionDate: '8/19/2020',
            deliveryMethod: null,
            displayValue: 'Workers Compensation Denial Letter',
            downloadIndicator: 'false',
            initialRequestDate: '02/10/2020',
            requestedDate: '02/10/2020',
            dueDate: '8/20/2020',
            eSignURL: null,
            formURL: null,
            mInfoReqInfoCd: '03',
            missingInfoRequInfoDesc: 'Test Alert',
            recipient: [
              {
                name: null,
                emailAddress: null,
                mailingAddress: null,
                faxNumber: null,
                faxedTo: null
              }
            ],
            requestDate: '8/19/2020',
            upload: '1001'
          },
          {
            alertStatus: 'Open',
            claimId: '1686545',
            clickToChat: '1001',
            completionDate: '8/19/2020',
            deliveryMethod: null,
            displayValue: 'Doctor\'s Contact Information',
            downloadIndicator: 'false',
            initialRequestDate: '03/01/2020',
            requestedDate: '03/01/2020',
            dueDate: null,
            eSignURL: null,
            formURL: null,
            mInfoReqInfoCd: '05',
            missingInfoRequInfoDesc: 'Test Info Alert',
            recipient: [
              {
                name: 'Amit Y',
                emailAddress: null,
                mailingAddress: '133 Brook Lane, Windsor, CT',
                faxNumber: null,
                faxedTo: null
              }
            ],
            requestDate: '8/19/2020',
            upload: '1002'
          },
          {
            attachmentInstanceId : '658622',
            alertStatus: 'Open',
            claimId: '1686545',
            clickToChat: '1002',
            completionDate: '8/19/2020',
            deliveryMethod: null,
            displayValue: 'Esign Form',
            downloadIndicator: 'YES',
            initialRequestDate: '08/02/2020',
            requestedDate: '08/02/2020',
            dueDate: null,
            echosignEnable: 'true',
            formURL: null,
            mInfoReqInfoCd: '709082',
            missingInfoRequInfoDesc: 'Test Info Alert',
            recipient: null,
            requestDate: '8/19/2020',
            upload: '1001'
          }
        ]
      }
    ]
  }],
  responseCode: '001',
  responseReason: 'success'
};
/*  {
    "MissingInfoAlertInfo": {
      "missingInfoList": [{
        "mInfoCode": "10023364",
        "mInfoDesc": "Welcome",
        "mInfoReqInfo": [{
          "DownloadIndicator": "YES",
          "eSignURL": null,
          "FormURL": null,
          "Upload": "1001",
          "alertStatus": "Open",
          "claimId": "1686545",
          "clickToChat": "1002",
          "completionDate": "8/19/2020",
          "deliveryMethod": null,
          "displayValue": "GAP Coverage",
          "dueDate": null,
          "mInfoReqInfoCd": "709080",
          "recipient": null,
          "requestDate": "8/19/2020"
        },
          {
            "DownloadIndicator": "YES",
            "eSignURL": null,
            "FormURL": null,
            "Upload": "1002",
            "alertStatus": "Open",
            "claimId": "1686545",
            "clickToChat": "1002",
            "completionDate": "8/19/2020",
            "deliveryMethod": null,
            "displayValue": "Eligibility Confirmation",
            "dueDate": null,
            "mInfoReqInfoCd": "709081",
            "recipient": null,
            "requestDate": "8/19/2020"
          },
          {
            "DownloadIndicator": "YES",
            "eSignURL": null,
            "FormURL": null,
            "Upload": "1002",
            "alertStatus": "Open",
            "claimId": "1686545",
            "clickToChat": "1002",
            "completionDate": "8/19/2020",
            "deliveryMethod": null,
            "displayValue": "Job Description",
            "dueDate": null,
            "mInfoReqInfoCd": "709082",
            "recipient": null,
            "requestDate": "8/19/2020"
          }
        ]
      },
        {
          "mInfoCode": "10023362",
          "mInfoDesc": "Please provide a note or copies of treatment records that include the ICD code related to the primary disabling condition.",
          "mInfoReqInfo": [{
            "DownloadIndicator": "YES",
            "eSignURL": null,
            "FormURL": null,
            "Upload": "1001",
            "alertStatus": "Open",
            "claimId": "1686545",
            "clickToChat": "1002",
            "completionDate": "8/19/2020",
            "deliveryMethod": null,
            "displayValue": "Cause of Disabling Condition",
            "dueDate": null,
            "mInfoReqInfoCd": "709079",
            "recipient": null,
            "requestDate": "8/19/2020"
          }]
        },
        {
          "mInfoCode": "10023366",
          "mInfoDesc": "Welcome",
          "mInfoReqInfo": [{
            "DownloadIndicator": "YES",
            "eSignURL": null,
            "FormURL": null,
            "Upload": "1001",
            "alertStatus": "Open",
            "claimId": "1686545",
            "clickToChat": "1002",
            "completionDate": "8/19/2020",
            "deliveryMethod": null,
            "displayValue": "GAP Coverage",
            "dueDate": null,
            "mInfoReqInfoCd": "709080",
            "recipient": null,
            "requestDate": "8/19/2020"
          },
            {
              "DownloadIndicator": "YES",
              "eSignURL": null,
              "FormURL": null,
              "Upload": "1002",
              "alertStatus": "Open",
              "claimId": "1686545",
              "clickToChat": "1002",
              "completionDate": "8/19/2020",
              "deliveryMethod": null,
              "displayValue": "Eligibility Confirmation",
              "dueDate": null,
              "mInfoReqInfoCd": "709081",
              "recipient": null,
              "requestDate": "8/19/2020"
            },
            {
              "DownloadIndicator": "YES",
              "eSignURL": null,
              "FormURL": null,
              "Upload": "1002",
              "alertStatus": "Open",
              "claimId": "1686545",
              "clickToChat": "1002",
              "completionDate": "8/19/2020",
              "deliveryMethod": null,
              "displayValue": "Wage Details",
              "dueDate": null,
              "mInfoReqInfoCd": "709082",
              "recipient": null,
              "requestDate": "8/19/2020"
            }
          ]
        }
      ]
    },
    "responseCode": "001",
    "responseReason": "success"
  };*/
