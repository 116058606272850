import {GbBase} from './gb-base';

/**
 * Created by AC12396 on 4/18/2019.
 */

export class THAWDBService extends GbBase {

  getUrls() {
    return {
      getEftPrefUrl: this.getBaseUrl() + 'gb-thaw-db-service/thawdb/v1/eftPref?' + this.getClientId('auth')
    };
  }
}
