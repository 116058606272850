export class MagnoliaPageConstants {
    static ABOUT_YOUR_ABSENCE_PAGE = 'About Your Absence';
    static AYA_CONTENT_DATA = 'AYAContentData';
    static ABOUT_YOUR_BONDING_PAGE = 'About Your Bonding';
    static AYB_CONTENT_DATA = 'AYBContentData';
    static ABOUT_YOUR_DELIVERY_PAGE = 'About Your Delivery';
    static AYD_CONTENT_DATA = 'AYDContentData';
    static EMPLOYMENT_QUESTIONS = 'Employment Questions';
    static EQ_CONTENT_DATA = 'EQContentData';
    static NOTIFICATION_PREFERENCE = 'Notification Preferences';
    static NP_CONTENT_DATA = 'NPContentData';
    static INTAKE_CONFIRMATION = 'Intake Confirmation Page';
    static INTAKE_CONFIRMATION_DATA = 'IntakeConfirmationContentData'
    static EDIT_WORK_SCHEDULE_PAGE = 'Edit Work Schedule';
    static EDIT_WORK_SCHEDULE_DATA = 'editWorkScheduleData';
    static PLAN_MY_LEAVE_LAUNCH = 'Plan My Leave Launch from My Benefits';
}
