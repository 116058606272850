import {Injectable} from '@angular/core';
import {env} from '@env/environment-loader';
import {Observable} from 'rxjs';
import {HttpClient, HttpHeaders} from '@angular/common/http';

@Injectable()
export class ClaimWizardService {

  constructor(private httpClient: HttpClient) { }

   // Perform a GET request to Data Power service API,passing the recaptcha token as a parameter
  getOptionsPreAuth(policyNumber: string,token: string): Observable<any> {
    return this.httpClient.get(`${env.gbAuthorization.claimWizardOptionsURL}policyNumber=${policyNumber}`,{
      headers: new HttpHeaders().set("recaptcha",token)
    });
  }

   // Perform a GET request to Data Power service API, with the "recapflag" header set to indocate that recaptcha is toggled off
  getOptionsPreAuthRecaptchaDisabled(policyNumber: string,token: string): Observable<any> {
    return this.httpClient.get(`${env.gbAuthorization.claimWizardOptionsURL}policyNumber=${policyNumber}`,{
      headers: new HttpHeaders().set("recapflag","no").set("recaptcha",token)
    });
  }

  getOptionsPostAuth(caseId: string, partyRefId: string) {
    return this.httpClient.get(`${env.gbPolicy.claimWizardOptionsURL}caseId=${caseId}&partyRefId=${partyRefId}`);
  }

  // Perform a POST request to logging service
  logRecaptchaFailure(): Observable<any>{
    return this.httpClient.post(`${env.gbLog.getSessionUrlUnauth}`,{"logText":"Recaptcha validation not success.","loggingLevel":"Error "});
  }
}
