
import {catchError, map} from 'rxjs/operators';
import {Injectable} from '@angular/core';

import {Observable, BehaviorSubject} from 'rxjs';



import {env} from '../../../environments/environment-loader';
import {ResponseErrorHandlerService} from './response-error-handler.service';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {AppLabelConstants} from '../../shared/constants/appLabel.constants';

@Injectable()
export class AetnaConfirmSurgeryServiceService {
  multipleBirthSelected = new BehaviorSubject(false);
  constructor(private errorHandler: ResponseErrorHandlerService,
              private httpClient: HttpClient) { }

  aetnaUpDateClaimActivity(request, claimType): Observable<any> {
    let urlEndpoint;
    switch (claimType) {
      case 'surgery' : urlEndpoint = env.thaaWKABPortalService.getAAUpdateSurgeryClaimAttribute; break;
      case 'delivery' : urlEndpoint = env.thaaWKABPortalService.getAAUpdateDeliveryClaimAttribute; break;
      case 'disability' : urlEndpoint = env.thaaWKABPortalService.getAAUpdateDisabilityClaimAttribute; break;
    }
    //console.log(JSON.stringify(request));
    return this.httpClient.post(urlEndpoint, request,
      {
        headers: new HttpHeaders()
          .set(AppLabelConstants.CONTENT_TYPE_KEY, AppLabelConstants.APPLICATION_KEY)
      }).pipe(
      map(
        (response: Response) => {
          if (!this.errorHandler.isServiceResponseHasError(response)) {
            return response;
          } else {
            throw response;
          }
        }
      ), catchError((error: any) => this.errorHandler.handleThaaErrorForSubmit(error)), );
  }

}
