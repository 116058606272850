import {AppLabelConstants} from '@shared/constants/appLabel.constants';

export class AdaTimelineConstants {
  static ADA_FULL_DETAILS = 'ADA Full Details';
  static SELECTED_TILE_DETAILS = 'selectedTileDetails';
  static UPDATE_ACCOMODATION = 'Update Accommodation';
  static ACCOMMODATION_HISTORY = 'Accommodation History';
  static ACCOMMODATION_HISTORY_OPEN = 'Accommodation History - Accordion Open';
  static ACCOMMODATION_HISTORY_CLOSE = 'Accommodation History - Accordion Close';
  static DATE_FORMAT = 'MM/dd/yyyy';
  static LOCALE_DEFAULT = 'en-US';
  static ACCOMMODATION_TIMELINE = 'Accommodation Timeline';
  static REPORT_TIME_USED = 'Report Time Used';
  static PENDING_EMPLOYER_REVIEW = 'Pending Employer Review';
  static ADA_INTERMITTENT_REQUEST_DETAIL_VIEW = 'Ada Intermittent Request Detail View';
  static YOUR_REPORTED_TIME = 'Your Reported Time';


  /** ADA Timeline cert Status */
  static TIMELINE_STEPS = [
    'Claim Submitted', 'Pending Accommodation Certification', 'Accommodation Certification Review', 'Pending Clinical Review', 'Pending Employer Review'
  ];

  /** ADA Timeline claim decision */
  static CLAIM_DECISION_STATUS = {
    statusAccommodated: 'Accommodated',
    statusNotAccommodated: 'Not Accommodated',
    statusDefault: 'Claim Decision'
  };

  static APPOINTMENT = 'Appointment';
  static FLARES = 'Flareups';
  static UNITS_PRE_TRANSLATION = [
    {index: 0, label: 'Minute(s)'},
    {index: 1, label: 'Hour(s)'},
    {index: 2, label: 'Day(s)'},
    {index: 3, label: 'Week(s)'},
    {index: 4, label: 'Month(s)'},
    {index: 5, label: 'Year(s)'}
  ];
  static UNITS_TRANSLATED = ['minute', 'hour', 'day', 'week', 'month', 'year'];
  static FLARES_MEDICALLY_NECESSARY = 'Flareups: As Medically Necessary';
  static FLARES_NECESSARY_WITH_DURATION = 'Flareups: As medically necessary and each time lasting';
  static DURATION_VALUE_AND_UNIT = '[Duration Value] [Duration Time Designation]';
  static FREQUENCY_WITH_DURATION_NECESSARY = 'Flareups: [Frequency Value] times per [Frequency Time Designation] and each time lasting as medically necessary';
  static FREQUENCY_VALUE = '[Frequency Value]';
  static FREQUENCY_TIME_DESIGNATION = '[Frequency Time Designation]';
  static FREQUENCY_DURATION = 'Flareups: [Frequency Value] times per [Frequency Time Designation] and each time lasting [Duration Value] [Duration Time Designation]';
  static TIMES = 'times';
  static TIME = 'time';
  static REDUCED_SCHEDULE = 'Reduced Schedule: [Number_of_Hours] hours per week';
  static NUMBER_OF_HOURS = '[Number_of_Hours]';
}

export class AdaTimelineRoutes {
  static FULL_DETAILS = 'adaFullDetails';
  static FULL_DETAILS_PAGE = '/leave-details/leaveDetails/adaFullDetails';
  static LEAVE_DETAILS_PAGE = '/leave-details/leaveDetails';
}
