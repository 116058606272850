import {Component, OnInit} from '@angular/core';
import {Router} from '@angular/router';
import {ModalService} from '../../../shared/services/modal.service';
import {MessageRibbonService} from '../../../shared/services/message-ribbon.service';
import {HttpClient, HttpErrorResponse, HttpHeaders} from '@angular/common/http';
import {StorageManagementService} from '../../../shared/services/storage-management.service';
import {PersonService} from '../../../shared/services/people.service';
import {phaOverviewService} from '../../../shared/services/pha-overview.service';
import {env} from '../../../../environments/environment-loader';
import {ClaimServiceService} from '../../../shared/services/claim-service.service';
import {User} from '../../../shared/models/user.model';
import {AuthStateDevService} from '../services/auth-state-dev.service';
import {TokenUtility} from '../../../shared/utils/tokenUtility';
import { AppConstants } from '@app/shared/constants/app.constants';

/* Service runner class that is created. There is an array of this class we use to render the page */
class ServiceRunner {
  bar: string = 'warn';
  barDP: string = 'warn';
  errors: any = [];
  totalErrors: any = [{
    normal: 0,
    dp: 0
  }];
  mymethod: number;
  totalServices: any = [{
    normal: 0,
    dp: 0
  }];
  leftToRun: any = [{
    normal: 0,
    dp: 0
  }];
  name: string;
  disabled: boolean  = false;

  constructor(mymethod: number, totalServices: number, name: string, disabled?: boolean) {
    this.mymethod = mymethod;
    this.totalServices.normal = totalServices;
    this.totalServices.dp = totalServices;
    this.leftToRun.normal = totalServices;
    this.leftToRun.dp = totalServices;
    this.totalErrors.dp = 0;
    this.totalErrors.normal = 0;
    this.name = name;
    if (disabled) {
      this.disabled = true;
    }
  }

  /* I don't like this method. Its bad.*/
  selfCheck(increase, dataP: boolean) {
    const dp = dataP ? 'dp' : 'normal';

    if (dataP) {
      console.log('DataPower: Left To Run =' + this.leftToRun[dp] + ' Total errors=' + this.totalErrors[dp] + ' total services=' + this.totalServices[dp]);
    } else {
      console.log('JBoss: Left To Run =' + this.leftToRun[dp] + ' Total errors=' + this.totalErrors[dp] + ' total services=' + this.totalServices[dp]);
    }
    if (increase) {
      this.leftToRun[dp]--;
    } else {
      this.totalErrors[dp]++;
      this.leftToRun[dp]--;
    }
    if (this.leftToRun[dp] === 0) {
      if (this.totalErrors[dp] >= this.totalServices[dp]) {
        if (dataP) {
          this.barDP = 'err';
        } else {
          this.bar = 'err';
        }
      } else if (this.totalErrors[dp] > 0) {
        if (dataP) {
          this.barDP = 'warn';
        } else {
          this.bar = 'warn';
        }
      } else {
        if (dataP) {
          this.barDP = 'done';
        } else {
          this.bar = 'done';
        }
      }
      this.leftToRun[dp] = this.totalServices[dp];
    }
  }

  addDPError(error: any, erroringUrl) {
    if (error == null) {
      this.addNullError(erroringUrl);
    } else {
      this.errors.push(
        {
          ErrType: 'DP',
          FailedUrl: erroringUrl,
          'DataPower Error Code': error['DataPower Error Code'],
          'DataPower Service Name': error['DataPower Service Name'],
          'DataPower Transaction ID': error['DataPower Transaction ID'],
          'Error Message': error['Error Message'],
          'Error Message Type': error['Error Message Type'],
        }
      )
    }
  }

  addError(error: any, erroringUrl) {
    if (error == null) {
      this.addNullError(erroringUrl);
    } else {
      this.errors.push(
        {
          ErrType: 'Normal',
          FailedUrl: erroringUrl,
          ErrorMessage: error.errorMessage,
          'Error Message Type': 'Service Error',
        }
      )
    }
  }

  addNullError(erroringUrl) {
    this.errors.push({
      ErrType: 'Normal',
      FailedUrl: erroringUrl,
      ErrorMessage: 'Null - possibly 404\'d',
      'Error Message Type': 'Unknown if Service or Datapower - further investigation required',
    })
  }

  resetErrors() {
    this.errors = [];
    this.totalErrors.dp = 0;
    this.totalErrors.normal = 0;
  }
}

@Component({
  selector: 'companion',
  templateUrl: 'companion.component.html',
  styleUrls: ['companion.component.scss']
})
export class CompanionComponent implements OnInit {

  /* Page Variables */
  authButtonLoad = '';
  frTokenState: string = 'err';
  crTokenState: string = 'err';
  private jBossURL = 'http://lad1jbmhd2001.thehartford.com:20200/';
  private jBossURLLTD = 'https://lad1jbmhd2001.thehartford.com:20200/';
  private jBossURLQA = 'https://lad1jbmhd2001.thehartford.com:20200/';

  /* Service Runners */
  serviceRunners: ServiceRunner[] = [];

  caseAssocServiceRunner: ServiceRunner;
  mudiServiceRunner: ServiceRunner;
  claimsServiceRunner: ServiceRunner;
  caseServiceRunner: ServiceRunner;
  econsentRunner: ServiceRunner;
  owcsRunner: ServiceRunner;
  blazeRunner: ServiceRunner;
  bankValidatorRunner: ServiceRunner;
  documentumSearchRunner: ServiceRunner;
  phaOverviewRunner: ServiceRunner;
  sitesRunner: ServiceRunner;
  logServiceRunner: ServiceRunner;
  gbRulesRunner: ServiceRunner;
  leaveURLRunner: ServiceRunner;

  private user: User;
  private provisionId: string;
  private routingNo: string;

  constructor(
    private router: Router,
    private httpClient: HttpClient,
    private authStateDevService: AuthStateDevService,
    private messageRibbonService: MessageRibbonService,
    private modalService: ModalService,
    private storageService: StorageManagementService,
    private personService: PersonService,
    private phaOverview: phaOverviewService,
    private claimsService: ClaimServiceService,
  ) {

  }

  /***
   * The Oninit is for initalizing and setting the intial states of the different service runners.
   * Here we need to assign how many service methods are expected to be run and what the name should be.
   * */
  ngOnInit() {
    this.tokensAvailable(); //Checks if the forgerock/cr tokens are available. Can be reset.

    if (env.envName === AppConstants.LOCAL || env.envName === 'ltd') {
      this.loadForDev();
    } else if (env.envName === AppConstants.LOCAL_QA || env.envName === 'qa') {
      this.loadForQA();
    }

    //Initalize
    this.mudiServiceRunner = new ServiceRunner(1, 1, 'Mudi');
    this.claimsServiceRunner = new ServiceRunner(2, 1, 'DCS');
    this.caseAssocServiceRunner = new ServiceRunner(3, 1, 'Case Association');
    this.caseServiceRunner = new ServiceRunner(4, 1, 'Case Info');
    this.econsentRunner = new ServiceRunner(5, 1, 'Econsent');
    this.owcsRunner = new ServiceRunner(6, 1, 'OWCS' , true);
    this.blazeRunner = new ServiceRunner(7, 1, 'Blaze', true);
    this.bankValidatorRunner = new ServiceRunner(8, 1, 'Bank Validator');
    this.documentumSearchRunner = new ServiceRunner(9, 1, 'Documentum Search', true);
    this.phaOverviewRunner = new ServiceRunner(10, 1, 'PHA Overview');
    this.sitesRunner = new ServiceRunner(11, 1, 'Sites');
    this.logServiceRunner = new ServiceRunner(12, 1, 'Log Service');
    this.gbRulesRunner = new ServiceRunner(13, 1, 'GB Rules');
    this.leaveURLRunner = new ServiceRunner(14, 1, 'Leave URL');

    //Add to the list
    this.serviceRunners.push(this.caseAssocServiceRunner);
    this.serviceRunners.push(this.mudiServiceRunner);
    this.serviceRunners.push(this.claimsServiceRunner);
    this.serviceRunners.push(this.econsentRunner);
    this.serviceRunners.push(this.caseServiceRunner);
    this.serviceRunners.push(this.leaveURLRunner);
    this.serviceRunners.push(this.bankValidatorRunner);
    this.serviceRunners.push(this.phaOverviewRunner);
    this.serviceRunners.push(this.sitesRunner);
    this.serviceRunners.push(this.logServiceRunner);
    this.serviceRunners.push(this.gbRulesRunner);
    this.serviceRunners.push(this.documentumSearchRunner);
    this.serviceRunners.push(this.owcsRunner);
    this.serviceRunners.push(this.blazeRunner);
  }

  /* In order to make this as extensable as possible, we assign a number to each main function.
  *  This way when we call a function from each in the loop, it can obtain the reference function from this switch case.
  */
  testFunction(selection: number) {
    switch (selection) {
      case 1: this.mudiServiceRunner.resetErrors(); this.mudiTest(); break;
      case 2: this.claimsServiceRunner.resetErrors(); this.claimsTest(); break;
      case 3: this.caseAssocServiceRunner.resetErrors(); this.caseAssocTest(); break;
      case 4: this.caseServiceRunner.resetErrors(); this.caseInfoTest(); break;
      case 5: this.econsentRunner.resetErrors(); this.econsentTest(); break;
      case 6: this.owcsRunner.resetErrors(); this.owcsTest(); break;
      case 7: this.blazeRunner.resetErrors(); this.blazeTest(); break;
      case 8: this.bankValidatorRunner.resetErrors(); this.bankValidationTest(); break;
      case 9: this.documentumSearchRunner.resetErrors(); this.documentumSearchTest(); break;
      case 10: this.phaOverviewRunner.resetErrors(); this.phaOverviewTest(); break;
      case 11: this.sitesRunner.resetErrors(); this.sitesTest(); break;
      case 12: this.logServiceRunner.resetErrors(); this.logServiceTest(); break;
      case 13: this.gbRulesRunner.resetErrors(); this.gbRulesTest(); break;
      case 14: this.leaveURLRunner.resetErrors(); this.leaveUrlTest(); break;
    }
  }

  fullTest() {
    for (let i = 0; i < this.serviceRunners.length; i++) {
      if (!(this.serviceRunners[i].disabled)) {
        this.testFunction(this.serviceRunners[i].mymethod);
      }
    }
  }

  /*
  * The actual service methods being called are below
  * For each Family of services, we only need to add a helper function for each new tested service
  * We also asign the running state for the bars to let the spinners go.
  */

  /* Testing Mudi */
  mudiTest() {
    this.mudiServiceRunner.barDP = 'running';
    this.mudiServiceRunner.bar = 'running';
    //this.mudiGetUser();
    this.genericFunctionCaller(
      env.gbMudi.getUserDetailsURL,
      JSON.stringify({
        partyRefId: this.user.partyRefId
      }),
      this.mudiServiceRunner,
      'POST');
    //this.mudiPHA(); //Removed for now. Keeping 1 method per service

  }
  caseAssocTest() {
    this.caseAssocServiceRunner.barDP = 'running';
    this.caseAssocServiceRunner.bar = 'running';
    //this.caseAssoc();
    this.genericFunctionCaller(
      env.gbCase.getUserCasesURL,
      '',
      this.caseAssocServiceRunner,
      'GET');
  }
  caseInfoTest() {
    this.caseServiceRunner.barDP = 'running';
    this.caseServiceRunner.bar = 'running';
    //this.caseInfo();
    this.genericFunctionCaller(
      env.gbPolicy.getPolicyAdminProvisionsURLFirst + this.user.caseId.toString() + env.gbPolicy.getPolicyAdminProvisionsURLLSecond + this.provisionId.toString() + '&client_id=1009',
      '',
      this.caseServiceRunner,
      'GET');
  }
  /* Testing DCS */
  claimsTest() {
    this.claimsServiceRunner.barDP = 'running';
    this.claimsServiceRunner.bar = 'running';
    //this.claimsGetClaims();
    this.genericFunctionCaller(
      env.gbClaims.getInsuredPartyClaims,
      JSON.stringify({
        insuredId: this.user.insuredId
      }),
      this.claimsServiceRunner,
      'POST');
  }
  econsentTest() {
    this.econsentRunner.barDP = 'running';
    this.econsentRunner.bar = 'running';
    this.genericFunctionCaller(
      env.gbEconsent.getUserProfileURL,
      JSON.stringify({
        insuredId: this.user.insuredId,
        businessGroupID: 4,
        caseId: this.user.caseId
      }),
      this.econsentRunner,
      'POST');
  }
  owcsTest() {
    this.owcsRunner.barDP = 'running';
    this.owcsRunner.bar = 'running';
    // this.claimsGetClaims();
  }
  blazeTest() {
    this.blazeRunner.barDP = 'running';
    this.blazeRunner.bar = 'running';
    // this.claimsGetClaims();
  }
  bankValidationTest() {
    this.bankValidatorRunner.barDP = 'running';
    this.genericFunctionCaller(
      env.gbBankValidation.getValidateBankAbaURL,
      JSON.stringify({
        insuredId: this.user.insuredId,
        businessGroupID: 4,
        caseId: this.user.caseId
      }),
      this.bankValidatorRunner,
      'POST');
  }
  documentumSearchTest() {
    this.documentumSearchRunner.barDP = 'running';
    this.documentumSearchRunner.bar = 'running';
    //this.documentumSearch();
    this.genericFunctionCaller(
      env.documentum.documentumURL_search,
      JSON.stringify({
        retrievalType: 'gbClaim',
        page: 1,
        itemsPerPage: 3,
        sortAttribute : 'SourceCreationDate',
        sortExpression : 'DESC',
        claimEventId: this.user.claimEventId,
        searchAttributes: [
          {
            attribute: 'claimEventId',
            value: this.user.claimEventId
          },
          {
            attribute: 'DocumentSourceSystemName',
            value: 'clmsupload'
          }
        ],
        searchResultAttributes: [
          'HIGDocumentIdentifier',
          'DocumentType',
          'DocumentCreationDate',
          'SourceCreationDate',
          'DocumentId',
          'DocumentFileName'
        ]
      }),
      this.documentumSearchRunner,
      'POST');
  }
  gbRulesTest() {
    this.gbRulesRunner.barDP = 'running';
    this.gbRulesRunner.bar = 'running';
    const myloggedInState = true;
    this.genericFunctionCaller(
      (myloggedInState ? env.gbCxrRules.faqRules_URL : env.gbCxrRules.faqRules_unauth_URL) ,
      JSON.stringify({
        'Content-Type': 'application/json'
      }),
      this.gbRulesRunner,
      'POST');
  }
  logServiceTest() {
    this.logServiceRunner.barDP = 'running';
    this.logServiceRunner.bar = 'running';
    const myloggedInState = true;
    this.genericFunctionCaller(
      myloggedInState ?
        env.gbLog.getSessionUrl : env.gbLog.getSessionUrlUnauth,
      JSON.stringify({
        userId: this.user.userId,
        logText: 'companion app log test',
        loggingLevel: 'debug'
      }),
      this.logServiceRunner,
      'POST');
  }
  sitesTest() {
    this.sitesRunner.barDP = 'running';
    this.sitesRunner.bar = 'running';
    this.genericFunctionCaller(
      env.gbContent.siteMapUrl,
      '',
      this.sitesRunner,
      'GET');
  }
  phaOverviewTest() {
    this.phaOverviewRunner.barDP = 'running';
    this.phaOverviewRunner.bar = 'running';
    this.genericFunctionCaller(
      env.gbMudi.phaAvailabilityURL + '&caseId=' + this.user.caseId + '&partyRefId=' + this.user.partyRefId,
      '',
      this.phaOverviewRunner,
      'GET');
  }
  leaveUrlTest() {
    this.leaveURLRunner.barDP = 'running';
    this.leaveURLRunner.bar = 'running';
    const tempcaseId = 663614;
    this.genericFunctionCaller(
      env.gbClaims.getLeaveSSOURL + '&caseId=' + tempcaseId + '&insuredId=' + this.user.insuredId,
      '',
      this.leaveURLRunner,
      'GET');
  }
  /* Utility Functions */
  tokensAvailable() {
    if (document.cookie && document.cookie.split(';').find(x => x.includes('CIAMSession'))) {
      this.frTokenState = 'done';
    }
    if (document.cookie && document.cookie.split(';').find(x => x.includes('EESession'))) {
      this.crTokenState = 'done';
    }
  }

  loadForDev() {
    this.user = new User();
    this.user.partyRefId = '957181';
    this.user.profileId = '1756';
    this.user.caseId = '490827';
    this.user.insuredId = '9003227418';
    this.user.claimEventId = '13107287';
    this.provisionId = '524';
    this.routingNo = '062004099';
    this.storageService.removeSessionObject('loginState');
  }

  loadForQA() {
    this.user = new User();
    this.user.partyRefId = '957181';
    this.user.profileId = '1756';
    this.user.caseId = '490827';
    this.user.insuredId = '9003227418';
    this.user.claimEventId = '13107287';
    this.provisionId = '315';
    this.routingNo = '062004099';
  }

  /*
  * Below we re-write some of the post/get calls.
  * This is done because we otherwise don't have an easy time getting the right url to use.
  * The main data is set up the same way anyways and this file is rather inclusive of itself
  * */
  genericFunctionCaller(myUrl: string, requestParams: string, theServiceRunner: ServiceRunner, requestType: string) {
    if (requestType === 'POST') {
      this.httpClient.post(myUrl, requestParams,
        {
          headers: new HttpHeaders().set('Content-Type', 'application/json')
        }).subscribe(
        data => {
          theServiceRunner.selfCheck(true, true);
        },
        error => {
          theServiceRunner.barDP = 'running-failed';
          theServiceRunner.addDPError(error.error, myUrl);
          theServiceRunner.selfCheck(false, true);
          console.log('Error');
          console.log(error.error['Error Message']);
        });
      this.httpClient.post(this.getJbossUrl(myUrl), requestParams,
        {
          headers: new HttpHeaders()
            .set('Content-Type', 'application/json')
        })
        .subscribe(
          data => {
            theServiceRunner.selfCheck(true, false);
          },
          error => {
            theServiceRunner.bar = 'running-failed';
            theServiceRunner.addError(error, this.getJbossUrl(requestParams));
            theServiceRunner.selfCheck(false, false);
          });
    } else if (requestType === 'GET') {
      this.httpClient.get(myUrl,
        {
          headers: new HttpHeaders()
            .set('Content-Type', 'application/json')
        }).subscribe(
        data => {
          theServiceRunner.selfCheck(true, true);
        },
        error => {
          theServiceRunner.barDP = 'running-failed';
          theServiceRunner.addDPError(error.error, myUrl);
          theServiceRunner.selfCheck(false, true);
          console.log('Error');
          console.log(error.error['Error Message']);
        });
      this.httpClient.get(this.getJbossUrl(myUrl),
        {
          headers: new HttpHeaders()
            .set('Content-Type', 'application/json')
        })
        .subscribe(
          data => {
            theServiceRunner.selfCheck(true, false);
          },
          error => {
            theServiceRunner.bar = 'running-failed';
            theServiceRunner.addError(error, this.getJbossUrl(myUrl));
            theServiceRunner.selfCheck(false, false);
          });
    }
  }

  /* Authorization for tokens */
  callForgeRock() {
    this.frTokenState = 'running';
    document.cookie = 'CIAMSession=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;';
    this.authStateDevService.getForgeToken().subscribe(
      (data: MyToken) => {
        if (document.cookie) {
          const bodyFrToken = (document.cookie.split(';').find(x => x.includes('CIAMSession')));
          if (bodyFrToken) {
            this.frTokenState = 'done';
          } else {
            this.frTokenState = 'warn';
          }
        }
        this.frTokenState = 'done';
      },
      error => {
        this.frTokenState = 'err';
      },
      () => {
        this.frTokenState = 'done';
      }
    );
  }

  callAuth() {
    this.crTokenState = 'running';
    document.cookie = 'EESession=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;';
    this.authStateDevService.getCurrentAuthToken(10, true).subscribe(
      (res: any) => {
        this.crTokenState = 'done';
        this.storageService.setSessionObject('loginState', true);
      },
      (error: HttpErrorResponse) => {
        this.crTokenState = 'err';
      },
      () => {
        this.crTokenState = 'done';
      }
    );
  }

  callFullTest() {
    this.frTokenState = 'running';
    this.crTokenState = 'running';
    document.cookie = 'CIAMSession=;  expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;';
    document.cookie = 'EESession=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;';
    this.authButtonLoad = 'loading';
    this.authStateDevService.getForgeToken().subscribe(
      (data: MyToken) => {
        if (document.cookie) {
          const bodyFrToken = (document.cookie.split(';').find(x => x.includes('CIAMSession')));
          if (bodyFrToken) {
          }
        }
        this.frTokenState = 'done';
        this.authStateDevService.getCurrentAuthToken(10, true).subscribe(
          (res: any) => {
            console.log('Finished Auth');
            console.log(res);
            this.authButtonLoad = 'done';
            this.storageService.setSessionObject('loginState', true);
            this.fullTest();
          },
          (error: HttpErrorResponse) => {
            this.authButtonLoad = 'done';
            this.crTokenState = 'err';
          },
          () => {
            this.crTokenState = 'done';
          }
        );
      },
      error => {
        this.frTokenState = 'err';
        this.authButtonLoad = 'done';
      },
      () => {
      }
    );
  }

  copyFrToken() {
    // this.copyTextToClipBoard(TokenUtility.getExistingFrToken());
  }

  copyCrToken() {
    // this.copyTextToClipBoard(this.authStateDevService.getExistingEEToken());
  }

  /* Reformats the url from env file to be one with the jboss url*/
  getJbossUrl(urlToModify) {
    if (env.envName === 'qa') {
      return this.jBossURLQA + (urlToModify).toString().slice((urlToModify).indexOf('gb'));
    } else {
      return this.jBossURL + (urlToModify).toString().slice((urlToModify).indexOf('gb'));
    }
  }

  copyTextToClipBoard(textToCopy: string) {
    const myText = document.createElement('textarea');
    myText.value = textToCopy;
    myText.style.position = 'fixed';
    myText.style.bottom = '0';
    myText.style.left = '0';
    myText.style.opacity = '0';
    myText.style.zIndex = '-1';
    document.body.appendChild(myText);
    myText.select();
    try {
      if (document.execCommand('copy') !== true) {
        console.log('error copying');
      }
    } catch (error) {
       console.log('error in copying');
    }
    document.body.removeChild(myText);
  }

}


class MyToken {
  tokenId;
  private successUrl;
  private realm;
  constructor(tokenId: string, successUrl: string, realm: string) {
    this.tokenId = tokenId;
  }

  getTokenId() {
    return this.tokenId
  }
}

interface CrToken extends Response {
  access_token: string;
  expires_in: number;
  token_type: string;
}
