export class OverpaymentSessionConstants {
  static APP_DATA = 'appData';
  static HIDE_OVERPAYMENT_ALERT = 'hideOverpaymentAlert';
  static HIDE_OVERPAYMENT_ALERT_ON_LOGOUT = 'hideOverpaymentAlertOnLogout';
  static PAYMENT_YOU_OWE_OWCS = 'paymentYouOweOWCS';
  static PAYMENT_YOU_OWE = 'Payments You Owe';
  static OVERPAYMENTS = 'overPayments';
  static REGEX_EXP = /(<([^>]+)>)/ig;
  static SELECT_PAYMENT_AMOUNT_OWCS = 'selectPaymentAmountOwcs';
  static SELECTED_OVERPAYMENT = 'selectedOverpayment';
  static LETTERS_LABEL = 'letters';
  static OVERPAYMENT_CLAIM_DETAILS = 'OvepaymentClaimDetails'
  static PREVIOUS_OVERPAYMENT_URL = 'previousOverpaymentUrl';
  static PAYMENT_CONFIRMATION_OWCS = 'paymentConfirmationOwcs';
  static PAYMENT_SUBMITION_CONFIRM_LABEL = 'Payment Submission Confirm';
  static PAYMENT_AMOUNT_LABEL = 'Payment Amount: ';
  static CARD_NUMBER = 'Card Number: ';
  static CARD_TYPE = 'Card Type: ';
  static OVERPAYMENT_IFRAME = 'overpaymentIFrame';
  static SUCCESS_CODE_001 = '001';
  static USER_CLAIMS = 'userClaims';
  static PREVIOUS_URL = 'previousUrl';
  static PAYMENT_SUBMITION_OVERPAYMENT_IFRAME = 'paymentSubmissionOverpaymentIFrame';
  static OVERPAYMENT_SUBMISSION_STATUS  = 'OverpaymentSubmissionStatus'
  static AETNA_CONTACT_NUMBER = 'mobileNumber';
  static PAYMENT_CONFIRMATION = 'paymentConfirmation';
  static PAYMENT_CONFIRMATION_YES = 'true';
  static PAYMENT_CONFIRMATION_NO = 'false';
  static IS_TRY_AGAIN_CLICKED = 'isTryAgainClicked';
  static IS_TRY_AGAIN_CLICKED_YES = 'true';
  static IS_TRY_AGAIN_CLICKED_NO = 'false';
}
