export class AppScheduleCallConstants {
  /** schedule-call component */
  static SCHEDULE_CALL = 'Schedule a Call';
  static CHOOSE_DATE_TIME = 'Choose a date and time to have your claim representative call you.';
  static CALL_DIRECTLY = 'Don\'t see a time that works for you? You can also call your representative directly:';
  static YES = 'yes';
  static SORRY = 'Sorry...';
  static TIME_SELECTED_BTN = 'timeSelectionBtnId0';
  static SCHEDUEL_BANNER_IMPRESSION = 'Schedule a Call Banner Impression Tracking';

  /** schedule-call reason compoent */
   static CALL_SCHEDULED = 'You\'re scheduled for a call on ';
   static CALL_REASON = 'What is your reason for scheduling a call?';
   static CHARACTERS_LEFT = 'Characters remaining:';
   static CALL_TO_NUMBER = 'What number should your claim representative call you at?';
   static SEND_CONFIRMATION = 'This is where we\'ll send your confirmation and reminder.';
   static EMAIL_LABEL = 'Email:';
   static MOBILE_NUMBER = 'Mobile Number:';
   static MAIL_TEXT_CONFIRM = 'Do you want confirmation via email or text?';
   static EMAIL = 'Email';
   static NO_REMAINDER = 'No thanks, I don\'t want a reminder';
   static REMAINDER_MSG = 'We can send a reminder 2 hours before your rep calls. How would you like to get it?';
   static ADD_NUMBER = 'Add Phone Number ';
   static EMAIL_ID = 'emailId';
   static LABEL = 'LABEL';
   static ONE = '1';
   static TWO = '2';
   static ZERO = '0';
   static ONE_LABEL = 1;
   static TWO_LABEL = 2;
   static ZERO_LABEL = 0;
   static EASTERN = 'eastern';
   static CENTRAL = 'central';
   static MOUNTAIN = 'mountain';
   static PACIFIC = 'pacific';
   static SUCCESS = 'success';
   static CONTACT_NO = 'contactNo';
   static REASON_DISCRIPTION = 'reasonDescription';
   static CONF_QUESTION = 'confQuestion';
   static DIDNT_GAVE_REASON = 'The claimant didn\'t give a reason.';

   /** schedule call delete confirm page */
   static YOU_DELETED_CALL = 'You deleted your call for ';
   static AT = ' at ';
   static ANOTHER_CALL = '. You can schedule another call, or return to the homepage.';
   static SUCCESS_MSG = 'Success! Your call was deleted successfully.';

   /** schedule call success page */
   static CALL_SUCCESS = 'Success! Your call request has been submitted.';
   static SCHEDULED_CALL_AT = 'You are scheduled for a call at ';
   static CALL_BACK = '. They\'ll call you at ';
   static ON = ' on ';
   static WITH = ' with ';
   static EDIT_LABEL = 'Edit';
   static REPRESNTATIVE_CALL  = 'You have a call scheduled with your claim representative, ';
   static EDIT_RESERVATION = '. You can edit your existing reservation, or delete and schedule another call.';

   /**schedule call edit page */
   static DELETE_LABEL = 'Delete';

  /** RSPONSE CODE */
  static RES_CODE_001 = '001';
  static RES_CODE_002 = '002';
  /** button text */
  static NEXT_BTN_TEXT = 'Next';
  static CANCEL_BTN_TEXT = 'Cancel';
  static EDIT_BTN = 'Edit';
  static RETURN_HOME = 'Return to Homepage';
  static SCHEDULE_CALL_BTN = 'Schedule a Call';
  static EDIT_CALL_BTN = 'Edit Call Details';
  static DONT_DELETE_CALL = 'No, Don\'t Delete';
  static YES_DELETE_CALL = 'Yes, delete this call';

  /** navigation of pages */
  static CLAIM_DETAILS_PAGE = '/claimDetails';
  static DELETE_CALL_CONFIRM_PAGE = '/schedule-appointment/deleteCallConfirmation';
  static SCHEDULE_CALL_EDIT_PAGE = '/schedule-appointment/scheduleCallEdit';
  static SCHEDULE_CALL_PAGE = '/schedule-appointment/scheduleCall';
  static SUCESS_CALL = '/schedule-appointment/successScheduleCall';
  static UPDATE_ERROR = '/update-error';
  static SCHEDULE_REASON_PAGE = '/schedule-appointment/scheduleReason';
  static CLAIM_DETAILS = '/claimDetails';
  static HOME = '/home';
  static SINGLE_PAYMENT = '/singlePayment';
  static SINGLE_PAYMENT_FULL_URL = '/claimDetails/singlePayment';
  static LEAVE_DETAILS = '/leave-details/leaveDetails';

  /** modal text */
  static ARE_YOU_SURE = 'Are you sure?';
  static SURE_TO_EXIT = 'Are you sure you want to exit? If you made any changes, they won’t be saved.';
  static EXIT_WITHOUT_SAVING = 'Exit without saving changes';
  static DELETE_CALL = 'Are you sure you want to delete this call? You won’t be able to undo this action.';

  /** ERROR MESSAGE */
  static ALL_TIME_SLOTS = '*All time slots are in';
  static IT_LOOKS_LIKE = 'It looks like ';
  static TIME = 'Time';
  static CHOOSE_NEW_TIME = ' Time was taken by another user. Please choose a new time slot.';
  static PLEASE_CHOOSE_DATE = 'was taken by another user. Please choose a new time slot.';

  /** IMAGES PATH */
  static SLOT_ERROR = '../assets/images/icons/slot-error.svg';
  static GRAY_CLOSE = '../assets/images/icons/close-hartfordgray.svg';
  static EDIT_ICON = '../../assets/images/icons/Edit.svg';
  static CALENDAR_PROFILE = '/assets/images/icons/profile-complete-calendarpage.png';
  static TRASH_ICON = '../../assets/images/icons/Trash.svg';
}
