
import {catchError, map} from 'rxjs/operators';
/**
 * Created by AC12394 on 2/24/2018.
 */

import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {env} from '../../../environments/environment-loader';
import {Node, OWCSMissingItemsResponse} from '../models/missing-info.model';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {ResponseErrorHandlerService} from './response-error-handler.service';
import {AppLabelConstants} from '../constants/appLabel.constants';

export const DCS_TO_OWCS_KEY_MAPPINGS: Node[] = [
  {key: '01', value: ['CD_620']},
  {key: '02', value: ['CD_603', 'CD_690']},
  {key: '03', value: ['CD_624']},
  {key: '04', value: ['CD_606', 'CD_724']},
  {key: '05', value: ['CD_609']},
  {key: '06', value: ['CD_600']},
  {key: '07', value: ['CD_603', 'CD_690']},
  {key: '08', value: ['CD_718', 'CD_721']},
  {key: '09', value: ['CD_718', 'CD_721']},
  {key: '10', value: ['CD_606', 'CD_724']},
  {key: '11', value: ['CD_727']},
  {key: '12', value: ['CD_730']},
  {key: '13', value: ['CD_733']},
  {key: '14', value: ['CD_736']},
  {key: '15', value: ['CD_739']},
  {key: '16', value: ['CD_742']},
  {key: '17', value: ['CD_671']},
  {key: '18', value: ['CD_675']},
  {key: '19', value: ['CD_679']},
  {key: '20', value: ['CD_683']},
  {key: '21', value: ['CD_687']},
  {key: '22', value: ['CD_696']},
  {key: '23', value: ['CD_693']},
  {key: '24', value: ['CD_696']},
  {key: '25', value: ['CD_616']},
  {key: '26', value: ['CD_627']},
  {key: '27', value: ['CD_635']},
  {key: '28', value: []},
  {key: '29', value: ['CD_714']},
  {key: '30', value: ['CD_703']},
  {key: '31', value: ['CD_612']},
  {key: '32', value: []},
  {key: '33', value: ['CD_714']},
  {key: '35', value: ['CD_640']}
];

export const RECENTLY_RESOLVE_TO_OWCS_KEY_MAPPINGS: Node[] = [
  {key: '01', value: ['RRI_620']},
  {key: '02', value: ['RRI_603', 'RRI_690']},
  {key: '03', value: ['RRI_624']},
  {key: '04', value: ['RRI_606', 'RRI_696']},
  {key: '05', value: ['RRI_609']},
  {key: '06', value: ['RRI_600']},
  {key: '07', value: ['RRI_603', 'RRI_690']},
  {key: '08', value: ['RRI_718', 'RRI_721']},
  {key: '09', value: ['RRI_718', 'RRI_721']},
  {key: '10', value: ['RRI_606', 'RRI_724']},
  {key: '11', value: ['RRI_727']},
  {key: '12', value: ['RRI_730']},
  {key: '13', value: ['RRI_733']},
  {key: '14', value: ['RRI_736']},
  {key: '15', value: ['RRI_739']},
  {key: '16', value: ['RRI_742']},
  {key: '17', value: ['RRI_671']},
  {key: '18', value: ['RRI_675']},
  {key: '19', value: ['RRI_679']},
  {key: '20', value: ['RRI_683']},
  {key: '21', value: ['RRI_687']},
  {key: '22', value: ['RRI_696']},
  {key: '23', value: ['RRI_693']},
  {key: '24', value: ['RRI_696']},
  {key: '25', value: ['RRI_616']},
  {key: '26', value: ['RRI_627']},
  {key: '27', value: ['RRI_635']},
  {key: '28', value: []},
  {key: '29', value: []},
  {key: '30', value: ['RRI_703']},
  {key: '31', value: ['RRI_612']},
  {key: '32', value: []},
  {key: '33', value: ['RRI_714']},
];

export const THAA_TO_OWCS_KEY_MAPPINGS: Node[] = [
  {key: '709080', value: ['CD_620']},
  {key: '02', value: ['CD_603', 'CD_690']},
  {key: '03', value: ['CD_624']},
  {key: '04', value: ['CD_606', 'CD_724']},
  {key: '05', value: ['CD_609']},
  {key: '06', value: ['CD_600']},
  {key: '07', value: ['CD_603', 'CD_690']},
  {key: '08', value: ['CD_718', 'CD_721']},
  {key: '09', value: ['CD_718', 'CD_721']},
  {key: '10', value: ['CD_606', 'CD_724']},
  {key: '11', value: ['CD_727']},
  {key: '12', value: ['CD_730']},
  {key: '13', value: ['CD_733']},
  {key: '14', value: ['CD_736']},
  {key: '15', value: ['CD_739']},
  {key: '16', value: ['CD_742']},
  {key: '17', value: ['CD_671']},
  {key: '18', value: ['CD_675']},
  {key: '19', value: ['CD_679']},
  {key: '20', value: ['CD_683']},
  {key: '21', value: ['CD_687']},
  {key: '22', value: ['CD_696']},
  {key: '23', value: ['CD_693']},
  {key: '24', value: ['CD_696']},
  {key: '25', value: ['CD_616']},
  {key: '26', value: ['CD_627']},
  {key: '27', value: ['CD_635']},
  {key: '28', value: []},
  {key: '29', value: ['CD_714']},
  {key: '30', value: ['CD_703']},
  {key: '31', value: ['CD_612']},
  {key: '32', value: []},
  {key: '33', value: ['CD_714']},
  {key: '35', value: ['CD_640']}
];


export const THAA_RECENTLY_RESOLVE_TO_OWCS_KEY_MAPPINGS: Node[] = [
  {key: '01', value: ['RRI_620']},
  {key: '02', value: ['RRI_603', 'RRI_690']},
  {key: '03', value: ['RRI_624']},
  {key: '04', value: ['RRI_606', 'RRI_696']},
  {key: '05', value: ['RRI_609']},
  {key: '06', value: ['RRI_600']},
  {key: '07', value: ['RRI_603', 'RRI_690']},
  {key: '08', value: ['RRI_718', 'RRI_721']},
  {key: '09', value: ['RRI_718', 'RRI_721']},
  {key: '10', value: ['RRI_606', 'RRI_724']},
  {key: '11', value: ['RRI_727']},
  {key: '12', value: ['RRI_730']},
  {key: '13', value: ['RRI_733']},
  {key: '14', value: ['RRI_736']},
  {key: '15', value: ['RRI_739']},
  {key: '16', value: ['RRI_742']},
  {key: '17', value: ['RRI_671']},
  {key: '18', value: ['RRI_675']},
  {key: '19', value: ['RRI_679']},
  {key: '20', value: ['RRI_683']},
  {key: '21', value: ['RRI_687']},
  {key: '22', value: ['RRI_696']},
  {key: '23', value: ['RRI_693']},
  {key: '24', value: ['RRI_696']},
  {key: '25', value: ['RRI_616']},
  {key: '26', value: ['RRI_627']},
  {key: '27', value: ['RRI_635']},
  {key: '28', value: []},
  {key: '29', value: []},
  {key: '30', value: ['RRI_703']},
  {key: '31', value: ['RRI_612']},
  {key: '32', value: []},
  {key: '33', value: ['RRI_714']},
];

@Injectable()
export class OWCSCaasService {

  constructor(private httpClient: HttpClient,
              private errorHandler: ResponseErrorHandlerService) {

  }

  getMissingItems(): Observable<OWCSMissingItemsResponse> {


      return this.httpClient.get<OWCSMissingItemsResponse>(env.gbContent.missingItemCaasURL,
        {
          headers: new HttpHeaders()
            .set(AppLabelConstants.CONTENT_TYPE_KEY, AppLabelConstants.APPLICATION_KEY)
        }).pipe(
        map(
          (response: OWCSMissingItemsResponse) => {
            if (!this.errorHandler.isServiceResponseHasError(response)) {
              return response;
            } else {
              throw response;
            }
          }
        ), catchError((error: any) => this.errorHandler.handleError(error)), );


  }

  getValues(key: string): string[] {
   const nodes: Node[] = DCS_TO_OWCS_KEY_MAPPINGS.filter(
      (element: Node) => (element.key === key)
    );

   if (nodes && nodes.length === 1) {
     return nodes[0].value;
   }
  }

  getOWCSValue(key: string): string[] {
    const nodes: Node[] = RECENTLY_RESOLVE_TO_OWCS_KEY_MAPPINGS.filter(
      (element: Node) => (element.key === key)
    );

    if (nodes && nodes.length === 1) {
      return nodes[0].value;
    }
  }

  fetchThaaFormValues(key: string): string[] {
    const nodes: Node[] = THAA_TO_OWCS_KEY_MAPPINGS.filter(
      (element: Node) => (element.key === key)
    );

    if (nodes && nodes.length === 1) {
      return nodes[0].value;
    }
  }

  fetchThaaRrValue(key: string): string[] {
    const nodes: Node[] = THAA_RECENTLY_RESOLVE_TO_OWCS_KEY_MAPPINGS.filter(
      (element: Node) => (element.key === key)
    );

    if (nodes && nodes.length === 1) {
      return nodes[0].value;
    }
  }
}

