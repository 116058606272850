import {AfterViewInit, Component, OnInit} from '@angular/core';
import {ReferenceHelperService} from '../../../shared/services/reference-helper.service';
import {Router} from '@angular/router';
import {FooterContentService} from '../../../shared/services/footer.service';
import {ContentService} from '../../../shared/services/content.service';
import {PersonService} from '../../../shared/services/people.service';
import {LeaveDynatraceAction} from '../../../shared/utils/leaveDynatraceAction';
import {StorageManagementService} from '../../../shared/services/storage-management.service';
import {MessageRibbonService} from '../../../shared/services/message-ribbon.service';
import {LogonService} from '../../../shared/services/logon.service';
import {AppLabelConstants} from '../../../shared/constants/appLabel.constants';
import {SessionConstants} from '../../../shared/constants/session.constants';
import {AppConstants} from '../../../shared/constants/app.constants';
import {ErrorConstants} from '../../../shared/constants/error.constants';

@Component({
  selector: 'app-employee-benefits-registration',
  templateUrl: './employee-benefits-registration.component.html',
  styleUrls: ['./employee-benefits-registration.component.scss']
})
export class EmployeeBenefitsRegistrationComponent extends LeaveDynatraceAction implements OnInit, AfterViewInit {
  public appLabelConstants = AppLabelConstants;
  public siteWideTermsAgreed = false;
  public legalNoticePolicyAgreed = false;
  public firstName;
  public lastName;
  public emailAddr;
  public textInfo = '';
  private pageId: string;
  private owscContent;
  private partyrefId;
  private userDetails;
  private notificationName = '';
  private jsonBOM;

  constructor(private referenceHelper: ReferenceHelperService,
              private router: Router,
              private contentService: ContentService,
              private footerContentService: FooterContentService,
              private peopleService: PersonService,
              private logonService: LogonService,
              private storageManagementService: StorageManagementService,
              private messageRibbon: MessageRibbonService) {
              super(storageManagementService);
  }

  ngOnInit() {

    this.jsonBOM = JSON.parse(this.storageManagementService.retrieveSessionObject('appData')).appData;
    this.partyrefId = this.storageManagementService.getSessionData('ssoPartyRefId');
    this.getOWCSContent();
    this.getUser();
  }

  ngAfterViewInit() {
    super.ngAfterViewInit();
  }
  /* employee benefits registration URLS link*/
  SitewideTC() {
    window.open(AppLabelConstants.URL_SITE_WIDE_TnC);
  }

  NavToLegalNotice() {
    window.open(AppLabelConstants.URL_TH_LEGAL_NOTICE);
  }

  NavToPolicy() {
    window.open(AppLabelConstants.URL_TH_ONLINE_PRIVACY_POLICY);
  }

  notificationRegistration() {
    if (this.siteWideTermsAgreed && this.legalNoticePolicyAgreed) {
      this.peopleService.UpdateSiteWideTC(this.partyrefId).subscribe(data => {
        this.storageManagementService.setSessionData(SessionConstants.SSO_NEW_REG_IND, AppConstants.N);
        this.router.navigateByUrl(AppLabelConstants.URL_HOME_CHANNELID_1);
      }, err => {
        this.messageRibbon.addMessage(ErrorConstants.ERROR, ErrorConstants.TECHNICAL_DIFFICULTY_MSG, ErrorConstants.ERROR_MESSAGE_1, AppConstants.FALSE);
      });

    }
  }

  acceptTextTerms() {
    this.siteWideTermsAgreed = !this.siteWideTermsAgreed;
  }

  LegalNoticePolicyAccept() {
    this.legalNoticePolicyAgreed = !this.legalNoticePolicyAgreed;
  }


  getOWCSContent() {
    this.pageId = this.contentService.getPageId(AppConstants.EMPLOYEE_BENEFIT_REG);
    if (this.pageId) {
      this.contentService.getOWCSResponse(this.pageId, AppConstants.EMPLOYEE_BENEFIT_REG_U).subscribe(
        val => {
          if (val) {
            this.owscContent = val;
            if (this.owscContent && this.owscContent.data) {
              if (this.owscContent.data.EBR_002_Body) {
                this.textInfo = this.owscContent.data.EBR_002_Body.value;
              }
            }
          }
        }, (error) => {// console.error(error);
        });
    }
  }

  clickCancel() {
    this.router.navigate(['/']);
  }



  getUser() {
    if (this.jsonBOM && this.jsonBOM.userData && Object.keys(this.jsonBOM.userData).length > 0) {
      this.firstName = this.jsonBOM.userData.firstname;
      this.lastName = this.jsonBOM.userData.lastName;
      this.emailAddr = this.jsonBOM.userData.emailAddr;
    } else {
      this.peopleService.getUserDetailsMudi(this.partyrefId).subscribe(
        val => {
          if (val) {
            this.userDetails = val;
            for (let i = 0; i < this.userDetails.sourcePartyDetailsList.length; i++) {
              /* Self Linking
               *
               * Currently this is where the self linking check will go.
               * It is unknown if this will get moved elsewhere.
               *
               * */
              if (this.userDetails.sourcePartyDetailsList[i].linkTypeCd === AppConstants.LINK_TYPE_CD_2 && this.userDetails.sourcePartyDetailsList[i].srcSystemCd === 1) { //weak DCS
                // this.loginRoute = new LoginRouting(true, '/selfLinking');
                this.userDetails.srcSysId = this.userDetails.insuredId;
                // this.sessionManagementService.setUserData(this.user);
                // return this.loginRoute;
              } else if (this.userDetails.sourcePartyDetailsList[i].linkTypeCd === AppConstants.LINK_TYPE_CD_1 && this.userDetails.sourcePartyDetailsList[i].srcSystemCd === 1) {
                this.userDetails.insuredId = this.userDetails.sourcePartyDetailsList[i].srcSysId;
                this.userDetails.srcSysId = this.userDetails.insuredId;
              } else if (this.userDetails.sourcePartyDetailsList[i].srcSystemCd === AppConstants.SRC_SYS_CD_2) {
                this.userDetails.eligibilityId = this.userDetails.sourcePartyDetailsList[i].srcSysId;
              }
            }
             if (this.userDetails && this.userDetails.firstname) {
              this.firstName = this.userDetails.firstname;
            }
            if (this.userDetails && this.userDetails.firstname) {
              this.lastName = this.userDetails.lastName;
            }
            if (this.userDetails && this.userDetails.emailAddr) {
              this.emailAddr = this.userDetails.emailAddr;

            }
            this.userDetails.personIds = this.logonService.getCaseIds();
            this.storageManagementService.setAppData(SessionConstants.USER_DATA, this.userDetails);
          }
        }, (error) => {
          console.log(ErrorConstants.ERROR_GET_USER, error);
        })
    }

  }


}


