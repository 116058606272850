import {AfterViewInit, Component, OnInit} from '@angular/core';
import {Router} from '@angular/router';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {SaveProfiledataService} from '../../shared/services/saveProfileData.service';
import {StorageManagementService} from '../../shared/services/storage-management.service';
import {MessageRibbonService} from '../../shared/services/message-ribbon.service';
import {ModalService} from '../../shared/services/modal.service';
import {Decision} from '../popup-comm-pref/decision';
import {PersonService} from '../../shared/services/people.service';
import {LeaveDynatraceAction} from '../../shared/utils/leaveDynatraceAction';
import {ssoRegistrationService} from '../../shared/services/sso-registration.service';
import {EventManagementServiceService} from '../../shared/services/event-management-service.service';
import {BehaviorSubject} from 'rxjs';
import {NotificationRegistrationService} from '../../shared/services/notification-registration.service';
import {NotificationDetails, UpdateNotificationLogResponse} from '../../shared/models/notificationDetails.model';
import {ContentService} from '../../shared/services/content.service';
import {User} from '../../shared/models/user.model';
import {DynamicFeatureDownService} from '../../shared/services/dynamic-feature-down.service';
import {BlazeService} from '../../shared/services/blazeRule.service';
import {AppLabelConstants} from '../../shared/constants/appLabel.constants';
import {SessionConstants} from '../../shared/constants/session.constants';
import {AppConstants} from '../../shared/constants/app.constants';
import {ErrorConstants} from '../../shared/constants/error.constants';
import {ResetPasswordService} from '../../shared/services/reset-password.service';


@Component({
  selector: 'app-notification-registration',
  templateUrl: './notification-registration.component.html',
  styleUrls: ['./notification-registration.component.scss']
})
export class NotificationRegistrationComponent extends LeaveDynatraceAction implements OnInit, AfterViewInit {
  public appLabelConstants = AppLabelConstants;
  public mail: string;
  public mobile: string;
  private empBenefitRegiForm: FormGroup;
  private mobileno;
  private email;
  public statusEmailcheckboxValue = true;
  public statusTextcheckboxValue = false;
  public defaultPersonId;
  // public paymentEmailcheckboxValue = true;
  // public paymentTextcheckboxValue = false;
  private profileData: any = {};
  private profileDataCIAM: any = {};
  public profileDataTHAA: any = {};
  private updateProfileRequest: any;
  private updateProfileResponse: any;
  private caseId: string;
  private insuredId: string;
  public personIds: any = [];
  private addProfileResponse;
  private addProfileRequest: any;
  private jsonBOM;
  private emailAddress;
  private eligibilityId;
  private userDecision = new Decision();
  private isPaymentApplicable: boolean = false;
  public notificationDetails: NotificationDetails = new NotificationDetails(false, false, false);
  public showPage: boolean = false;
  public dispNotificationThaa: boolean = false;
  public showPopUp: boolean = false;
  public showPopUpTHAA: boolean = false;

  failedTransactionCtr = new BehaviorSubject(0);
  isUpdatePrefServiceFailed = new BehaviorSubject(false);
  public modalHeader: string = AppLabelConstants.BEFORE_U_CONTINUE;
  public preRendering: string = AppLabelConstants.SKELETON_CONTAINER;
  public disableSpinner: string = 'false';
  public pageId;
  public owscContent;
  public pageTitle;
  public emailText;
  public emailMessage;
  public mobileText;
  public mobileMessage;
  public commonMessage;
  public emailDCSText;
  public commonTHAADCSMessageFirst;
  public commonTHAADCSMessage;
  public defaultCaseThaa;
  public thaaCase: boolean = true;
  public showContent: boolean = false;
  public labelColor;
  constructor(private router: Router,
              private profiledataService: SaveProfiledataService,
              private storageManagementService: StorageManagementService,
              private messageRibbon: MessageRibbonService,
              private modalService: ModalService,
              private ssoRegistration: ssoRegistrationService,
              private notificationRegistrationService: NotificationRegistrationService,
              private dynamicFeatureDownService: DynamicFeatureDownService,
              private eventManagementServiceService: EventManagementServiceService,
              private blazeService: BlazeService,
              private resetPasswordService: ResetPasswordService,
              private personService: PersonService,
              private contentService: ContentService) {
    super(storageManagementService);
  }

  ngOnInit() {
    /*
     if clientId 1 this page is disabled and redirects to /home
     */

    const provisioOptions = this.storageManagementService.getAppData(SessionConstants.PROVISIONSOPTIONS); ;
    if ( provisioOptions && provisioOptions.provisionResponseList && provisioOptions.provisionResponseList.length && provisioOptions.provisionResponseList[0].provisionId && provisioOptions.provisionResponseList[0].provisionOptionId ) {
      if ((provisioOptions.provisionResponseList[0].provisionId === SessionConstants.PROVISIONSID && provisioOptions.provisionResponseList[0].provisionOptionId === SessionConstants.PROVISIONSOPTIONSID) && this.storageManagementService.getSessionData(SessionConstants.IS_NOTIFICATION_REG) === true && this.notificationRegistrationService.getIsNotificationInterceptorSkipped() === true) {
        this.router.navigateByUrl(AppLabelConstants.URL_HOME_CHANNELID_1);
    }
  }

    //OWCS service Integration
    this.fetchOWCSResponse();
    this.setUserForm();
    this.checkIfNotificationsFeatureIsTurnedOff(AppConstants.ABOUT_YOU);

  }

  ngAfterViewInit() {
    super.ngAfterViewInit();
  }

  fetchOWCSResponse() {
    this.pageId = this.contentService.getPageId(AppConstants.NOTIFICATION_INTERCEPT);
    if (this.pageId) {
      this.contentService.getOWCSResponse(this.pageId, AppConstants.NOTIFICATION_INTERCEPT_U).subscribe(
        val => {
          if (val) {
            this.owscContent = val;
            if (this.owscContent && this.owscContent.data) {
              if (this.owscContent.data.Notification_Promo_2020_02_Label) {
                this.pageTitle = this.owscContent.data.Notification_Promo_2020_02_Label.value;
              }
              if (this.owscContent.data.Notification_Promo_2020_26_Label) {
                this.emailText = this.owscContent.data.Notification_Promo_2020_26_Label.value;
              }
              if (this.owscContent.data.Notification_Promo_2020_27_Body) {
                this.emailMessage = this.owscContent.data.Notification_Promo_2020_27_Body.value;
              }
              if (this.owscContent.data.Notification_Promo_2020_09_Label) {
                this.mobileText = this.owscContent.data.Notification_Promo_2020_09_Label.value;
              }
              if (this.owscContent.data.Notification_Promo_2020_28_Body) {
                this.mobileMessage = this.owscContent.data.Notification_Promo_2020_28_Body.value;
              }
              if (this.owscContent.data.Notification_Promo_2020_29_Body) {
                this.commonMessage = this.owscContent.data.Notification_Promo_2020_29_Body.value;
              }
              if (this.owscContent.data.Notification_Promo_2020_07_Label) {
                this.emailDCSText = this.owscContent.data.Notification_Promo_2020_07_Label.value;
              }
              if (this.owscContent.data.Notification_Promo_2020_03_Body) {
                this.commonTHAADCSMessageFirst = this.owscContent.data.Notification_Promo_2020_03_Body.value;
              }

              if (this.owscContent.data.Notification_Promo_2020_05_Body) {
                this.commonTHAADCSMessage = this.owscContent.data.Notification_Promo_2020_05_Body.value;
              }
            }
          }
        }, (error) => {//console.error(error);
        });
    }
  }

  checkIfNotificationsFeatureIsTurnedOff(linkTitle: string) {
    const isFeatureTurnedOff: boolean = this.dynamicFeatureDownService.isFeatureTurnedOff(linkTitle);
    if (isFeatureTurnedOff) {
      this.router.navigateByUrl(AppLabelConstants.URL_HOME);
      this.notificationRegistrationService.setIsNotificationInterceptorSkipped(true);
    } else {
      this.proceedToNotificationsIntercept();
    }
  }

  proceedToNotificationsIntercept() {
    this.jsonBOM = JSON.parse(this.storageManagementService.retrieveSessionObject(SessionConstants.APP_DATA)).appData;
    if (this.jsonBOM && this.jsonBOM.userData) {
      this.caseId = this.jsonBOM.userData.caseId;
      this.insuredId = this.jsonBOM.userData.insuredId;
      this.personIds = this.jsonBOM.userData.personIds;
      if (this.jsonBOM.userData.sourcePartyDetailsList && this.jsonBOM.userData.sourcePartyDetailsList.length > 0) {
        for (let i = 0; i < this.jsonBOM.userData.sourcePartyDetailsList.length; i++) {
          if (this.jsonBOM.userData.sourcePartyDetailsList[i].srcSystemCd === 8) {
            this.thaaCase = true;
          } else if (this.jsonBOM.userData.sourcePartyDetailsList[i].srcSystemCd !== 8 && this.jsonBOM.userData.sourcePartyDetailsList[i].activeCase) {
            this.thaaCase = false;
            if (this.jsonBOM.userData.sourcePartyDetailsList[i].srcSystemCd === 2) {
              this.eligibilityId = this.jsonBOM.userData.sourcePartyDetailsList[i].srcSysId.toString();
            }
          }
        }
      }
    }
    this.getProfileDataCIAM();
    if ((this.insuredId || this.eligibilityId) && this.caseId && !this.thaaCase) {
      this.notificationRegistrationService.checkNotificationExempt(
        ((this.insuredId && this.insuredId.length > 0) ? this.insuredId : null),
        ((this.eligibilityId && this.eligibilityId.length > 0) ? this.eligibilityId : null),
        parseInt(this.caseId, 10))
        .subscribe(
          (notificationDetails: NotificationDetails) => {
            this.storageManagementService.setNotificationsData(notificationDetails);
            this.storageManagementService.setSessionData(SessionConstants.IS_NOTIFICATION_DATA_SET_IN_SESSION, true);
            /* if (notificationDetails && notificationDetails.isNotification &&
             (notificationDetails.notificationCategory && (notificationDetails.notificationCategory.isPaymentApplicable || notificationDetails.notificationCategory.isStatusApplicable))) {*/
            if (notificationDetails && !notificationDetails.notificationExempt) {
              this.notificationDetails = notificationDetails;
              //this.isPaymentApplicable = notificationDetails.notificationCategory.isPaymentApplicable;
              /*  if (!this.isPaymentApplicable) {
               this.paymentEmailcheckboxValue = false;
               }*/
              this.showPage = true;
              this.showPopUp = true;
              this.showContent = false;

            } else {
              this.storageManagementService.setSessionData(SessionConstants.IS_NOTIFICATION_REG, true);
              //JSONBOM is invalidated true as to call back FAQ service if we again go to home and yet FAQ is not called
              this.blazeService.setIsJSONBOMInvalidated(true);
              this.router.navigate([AppLabelConstants.URL_HOME]);
            }
          },
          (
            (error) => {
              this.storageManagementService.setSessionData(SessionConstants.IS_NOTIFICATION_REG, true);
              //JSONBOM is invalidated true as to call back FAQ service if we again go to home and yet FAQ is not called
              this.blazeService.setIsJSONBOMInvalidated(true);
              this.router.navigate([AppLabelConstants.URL_HOME]);
            }
          )
        );
      this.getProfile();
    }
    if (this.personIds && this.personIds.length > 0 && this.thaaCase) { //not writing else if because in case of migrated case user will have both personId and insuredId so both systems shall get updated
      const defaultPersonId = this.personIds[0];
      this.notificationRegistrationService.isThaaNotification(this.caseId, defaultPersonId).subscribe(
        (response) => {
          if (response) {
            this.profileDataTHAA = response;
            if (this.profileDataTHAA && this.profileDataTHAA.preferenceDetails) {
              this.storageManagementService.setAppData('profileDataTHAA', this.profileDataTHAA);

              if (this.profileDataTHAA.preferenceDetails.homeEmailAddress) {
                this.mail = this.profileDataTHAA.preferenceDetails.homeEmailAddress;
                this.storageManagementService.setSessionData(SessionConstants.USER_PROFILE_EMAIL, this.mail);
              }

              if (this.profileDataTHAA.preferenceDetails.mobileNumber) {
                this.mobile = this.profileDataTHAA.preferenceDetails.mobileNumber;
                this.storageManagementService.setSessionData(SessionConstants.USER_PROFILE_MOBILE, this.mobile);
              }
            }
            this.statusEmailcheckboxValue = ((response.preferenceDetails && response.preferenceDetails.preference && response.preferenceDetails.preference.email === 'true') ? true : false);
            this.statusTextcheckboxValue = ((response.preferenceDetails && response.preferenceDetails.preference && response.preferenceDetails.preference.text === 'true') ? true : false);
            if (!this.statusEmailcheckboxValue && !this.statusTextcheckboxValue) { //when both preferences r failse by default email shall be selected
              this.statusEmailcheckboxValue = true;
            }
            this.showPage = true;
            this.dispNotificationThaa = true;
            this.showPopUpTHAA = true;
            if (this.thaaCase) {
              this.showContent = true;
            }
          }
        },
        (
          (error) => {
            this.storageManagementService.setSessionData(SessionConstants.IS_NOTIFICATION_REG, true);
            //JSONBOM is invalidated true as to call back FAQ service if we again go to home and yet FAQ is not called
            this.blazeService.setIsJSONBOMInvalidated(true);
            this.router.navigate([AppLabelConstants.URL_HOME]);
          }
        ));
    }
    this.failedTransactionCtr.subscribe(ctr => {
      if (ctr === 1 || ctr === 2) {
        this.messageRibbon.addMessage(ErrorConstants.WARNING_SM, ErrorConstants.FAILED_TRANSACTION_MSG_RIBBON, null, AppConstants.TRUE);
      } else if (this.failedTransactionCtr.getValue() > 2) {
        this.router.navigate([AppLabelConstants.URL_UPDATE_ERROR]);
      }
    });
  };

  getProfile() {
    const profileDtls = this.storageManagementService.getAppData(SessionConstants.PROFILE_DETAILS);
    if (profileDtls && Object.keys(profileDtls).length > 0) {
      this.profileData = profileDtls;

      /*if there are no profile created yet create a profile*/
      /*****************************************************************************/
      if ((this.profileData &&
        this.profileData.econsentDataResponseBeanList &&
        JSON.stringify(this.profileData.econsentDataResponseBeanList[0]) !== '{}' &&
        this.profileData.econsentDataResponseBeanList.length > 0 && !this.profileData.econsentDataResponseBeanList[0].econsentCustomerList) ||
        (this.profileData &&
          this.profileData.econsentDataResponseBeanList &&
          this.profileData.econsentDataResponseBeanList.length === 0)) {
        this.addProfile();
      }
      /*****************************************************************************/

      this.getUserMobileEmail(this.profileData);
    } else {
      /*to update request parameters , hardcoded for now*/
      this.personService.getUserProfile('4', (
        (this.jsonBOM.userData.insuredId && this.jsonBOM.userData.insuredId.length > 0) ? this.jsonBOM.userData.insuredId : this.jsonBOM.userData.eligibilityId), this.caseId).subscribe(
        data => {
          if (data) {
            this.profileData = data;
            // store the data in session only when this list is populated
            if (this.profileData && this.profileData.econsentDataResponseBeanList &&
              JSON.stringify(this.profileData.econsentDataResponseBeanList[0]) !== '{}' &&
              this.profileData.econsentDataResponseBeanList[0].econsentCustomerList) {
              this.storageManagementService.setAppData(SessionConstants.PROFILE_DETAILS, data);
            } else if ((this.profileData &&
              this.profileData.econsentDataResponseBeanList &&
              JSON.stringify(this.profileData.econsentDataResponseBeanList[0]) !== '{}' &&
              this.profileData.econsentDataResponseBeanList.length > 0 && !this.profileData.econsentDataResponseBeanList[0].econsentCustomerList) ||
              (this.profileData &&
                this.profileData.econsentDataResponseBeanList &&
                this.profileData.econsentDataResponseBeanList.length === 0)) {
              /*if there are no profile created yet create a profile if the default case is not a THAA case*/
              /*****************************************************************************/
              if ((this.jsonBOM && this.jsonBOM.userData && this.jsonBOM.userData.insuredId) || (this.jsonBOM && this.jsonBOM.userData && this.jsonBOM.userData.eligibilityId)) {
                this.addProfile();
                /*****************************************************************************/
              }
            }
          }
        },
        (error) => {
        },
        () => {
          this.getUserMobileEmail(this.profileData);
        }
      );
    }

  }

  getProfileDataCIAM() {
    const profileCiamData = this.storageManagementService.getAppData(SessionConstants.PROFILE_DATA_CIAM);
    if (profileCiamData && Object.keys(profileCiamData).length > 0) {
      this.profileDataCIAM = profileCiamData;
      this.getUserMobileEmailCIAM(this.profileDataCIAM);
    } else {
      this.resetPasswordService.getTokenId()
        .subscribe(
          data => {
            if (data) {
              this.profileDataCIAM = data;
                this.getUserMobileEmailCIAM(this.profileDataCIAM);
                this.storageManagementService.setAppData(SessionConstants.PROFILE_DATA_CIAM, this.profileDataCIAM);
                this.showPopUp = true;
            }
          },
          error => {
            console.log('Error in CIAM Profile');
          }
        );
    }
  }

  setUserForm() {
    this.empBenefitRegiForm = new FormGroup({
      email: new FormControl(this.email,
        [Validators.required, Validators.pattern('[a-zA-Z0-9._%+-]+@[a-zA-Z0-9]+\.([a-zA-Z]{3,5}|[a-zA-z]{2,10}\.[a-zA-Z]{2,5})')]),
      mobileno: new FormControl(this.mobileno)
    });
  }

  toggleStatusEmail() {
    this.statusEmailcheckboxValue = !this.statusEmailcheckboxValue; // first checkbox
  }

  /*Function name:
   * Method name: toDisplayTextField
   * purpose; To check the which checkbox checked or not to display the text box base on condition
   * Created by: AC12487
   * Created Date: 2/21/2018
   * */

  toggleStatusText() {
    this.statusTextcheckboxValue = !this.statusTextcheckboxValue; // value for second check box
  }

  /*togglePaymentText() {
   this.paymentTextcheckboxValue = !this.paymentTextcheckboxValue; // value for 3rd checkbox
   }*/

  /*togglePaymentEmail() {
   this.paymentEmailcheckboxValue = !this.paymentEmailcheckboxValue; // value for 4th checkbox
   }*/

  showRibbonSuccess() {
    this.messageRibbon.addMessage(AppLabelConstants.SUCCESS_SM, ErrorConstants.UR_CHANGES_HAVE_BEEN_SAVED, null, AppConstants.TRUE);
  }


  onSaveChangesButtonClick(): void {
    this.disableSpinner = 'false';
    if (!this.statusEmailcheckboxValue && !this.statusTextcheckboxValue /*&& !this.paymentEmailcheckboxValue && !this.paymentTextcheckboxValue*/) {
      this.modalHeader = AppLabelConstants.TURN_OFF_NOTIFICATIONS;
      this.disableSpinner = 'true';
    } else {
      this.modalHeader = AppLabelConstants.BEFORE_U_CONTINUE;
      this.disableSpinner = 'true';
    }
    if (typeof (<any>window).trackNotificationRegistration === 'function') {
      (<any>window).trackNotificationRegistration(this.statusEmailcheckboxValue, this.statusTextcheckboxValue /*,
       this.paymentEmailcheckboxValue, this.paymentTextcheckboxValue*/);


      /*Function name:
       * Method name: toDisplayEmailField
       * purpose; To check the which checkbox checked or not to display the text box base on condition
       * Created by: AC12487
       * Created Date: 2/21/2018
       * */
    }
    this.modalService.openModal(AppLabelConstants.SAVE_NO_MOB_MODAL);

  }

  openCancel() {
    this.modalService.openModal(AppLabelConstants.CANCEL_MODAL);
  }

  addProfile() {
    this.addProfileRequest = {
      businessGroupID: '4',
      businessSegment: 'GB',
      requestSubmitter: 'TestTHAW',
      processingMode: 'activity-processed',
      insuredId: (
        (this.jsonBOM.userData.insuredId && this.jsonBOM.userData.insuredId.length > 0) ? this.jsonBOM.userData.insuredId : this.jsonBOM.userData.eligibilityId),
      caseId: this.caseId,
      eConsentProfileInfoRequestItemList: [
        {
          eConsentActivityInformation: {
            activityType: 'econ-profile-create',
            activityCustomerNotification: false,
            activitySourceSystem: 'THAW',
            activitySourceProcessorID: 'GBTHAW',
            activityConsentType: 'ConsentParty'
          },
          eConsentCustomerList: [
            {
              eConsentBusinessGroupPartyInfo: {
                fullName: this.jsonBOM.userData.firstname + ' ' + this.jsonBOM.userData.lastName,
                firstName: this.jsonBOM.userData.firstname,
                lastName: this.jsonBOM.userData.lastName,
                emailAddress: this.jsonBOM.userData.emailAddr,
                dateOfBirth: '',
                eConsentAddressInfo: null,
                eConsentGetContactPointInfoTypeList: null
              },
              eConsent: {
                subscribeResponseMethod: 'Web',
                consentActivityStatusCode: 'CMPL'
              },
              eConsentAdminSystemParty: {
                adminSystemName: 'DCS'
              },
              eConsentDocumentCategoryList: [
                {
                  documentCategoryCode: 'DCOT',
                  deliveryMethod: 'Email',
                  activeIndicator: '1',
                  communicationMethod: 'Web'
                },
                {
                  documentCategoryCode: 'DCOT',
                  deliveryMethod: 'SMS',
                  activeIndicator: '0',
                  communicationMethod: 'Web'
                },
                {
                  documentCategoryCode: 'DCST',
                  deliveryMethod: 'Email',
                  activeIndicator: '1',
                  communicationMethod: 'Web'
                },
                {
                  documentCategoryCode: 'DCST',
                  deliveryMethod: 'SMS',
                  activeIndicator: '0',
                  communicationMethod: 'Web'
                },
                {
                  documentCategoryCode: 'DCPT',
                  deliveryMethod: 'Email',
                  activeIndicator: '1',
                  communicationMethod: 'Web'
                },
                {
                  documentCategoryCode: 'DCPT',
                  deliveryMethod: 'SMS',
                  activeIndicator: '0',
                  communicationMethod: 'Web'
                }
              ]
            }
          ]
        }
      ]
    };


    this.ssoRegistration.addProfile(this.addProfileRequest).subscribe(
      val => {
        if (val) {
          this.addProfileResponse = val;
          this.personService.getUserProfile('4', (
            (this.jsonBOM.userData.insuredId && this.jsonBOM.userData.insuredId.length > 0) ? this.jsonBOM.userData.insuredId : this.jsonBOM.userData.eligibilityId), this.caseId).subscribe(
            data => {
              if (data) {
                this.profileData = data;
                // store the data in session only when this list is populated
                if (this.profileData && this.profileData.econsentDataResponseBeanList &&
                  JSON.stringify(this.profileData.econsentDataResponseBeanList[0]) !== '{}' &&
                  this.profileData.econsentDataResponseBeanList[0].econsentCustomerList) {
                  this.storageManagementService.setAppData(SessionConstants.PROFILE_DETAILS, data);
                }
              }
            },
            (error) => {
            },
            () => {
              this.getUserMobileEmail(this.profileData);
            }
          );

        }
      }, (error) => {
        console.log(ErrorConstants.ERROR_CRREATING_NEW_USER_PROFILE);
      }
    );
  }

  onSave() {
    sessionStorage.setItem(SessionConstants.TERMS_NOT_AGREED, AppConstants.FALSE);
    // call analytics function after save changes button appears
    setTimeout(() => {
      if (typeof (<any>window).trackSaveButton === 'function') {
        (<any>window).trackSaveButton();
      }
    }, 500);

  }

  reactToUserDecisionWithMob(event: Decision) {
    this.isUpdatePrefServiceFailed.next(false);
    this.userDecision = event;
    if (this.userDecision.decision) {
      this.updateTextTC();
      //  this.updateMobileAndEmail(this.userDecision.mobileno, this.userDecision.email);
      //   this.updatePreferences(this.statusEmailcheckboxValue, this.statusTextcheckboxValue /*, this.paymentEmailcheckboxValue, this.paymentTextcheckboxValue*/);

      //Call Orchestration for update prefrences in THAA and econcent  updating Email and Mobile Number
      this.updateProfileRequest = {
        personId: ((this.personIds && this.personIds.length > 0) ? this.personIds[0] : ''),
        insuredId: ((this.jsonBOM.userData.insuredId && this.jsonBOM.userData.insuredId.length > 0) ? this.jsonBOM.userData.insuredId : ''),
        eligibilityId: ((this.jsonBOM.userData.eligibilityId && this.jsonBOM.userData.eligibilityId.length > 0) ? this.jsonBOM.userData.eligibilityId : ''),
        caseId: this.caseId,
        notificationSource: '',
        phoneNumber: (this.userDecision.mobileno ? this.userDecision.mobileno.replace(/\D+/g, '') :  ''),
        emailAddress: this.userDecision.email ? this.userDecision.email : '',
        preference: {
          email: this.statusEmailcheckboxValue,
          text: this.statusTextcheckboxValue
        }
      };
      this.profiledataService.updateProfile(this.updateProfileRequest).subscribe(
        res => {
          this.disableSpinner = 'true';
          if (!this.dispNotificationThaa) {
            this.updateNotificationLogs();
          }

          this.router.navigate([AppLabelConstants.URL_HOME]);
        }, (error) => {
          this.disableSpinner = 'true';
          this.failedTransactionCtr.next(this.failedTransactionCtr.getValue() + 1);
        });
      this.disableSpinner = 'false';
    }
    this.modalService.closeModal(AppLabelConstants.SAVE_NO_MOB_MODAL);
  }

  updateTextTC() {
    this.jsonBOM = JSON.parse(this.storageManagementService.retrieveSessionObject('appData')).appData;
    const partyrefId = JSON.parse(this.storageManagementService.retrieveSessionObject('appData')).appData ?
      JSON.parse(this.storageManagementService.retrieveSessionObject('appData')).appData.userData.partyRefId : null;
    const today = new Date();
    const dd = today.getDate();
    const mm = today.getMonth() + 1;
    const yy = today.getFullYear();
    const notificationTermsCondAcceptDt = yy + '-' + (String('00' + mm).slice(-2)) + '-' + (String('00' + dd).slice(-2));
    this.personService.UpdateTextTC(partyrefId, notificationTermsCondAcceptDt).subscribe(
      val => {
        //console.log('In success');
      }, (error) => {
        this.failedTransactionCtr.next(this.failedTransactionCtr.getValue() + 1);
      }
    );
  }

  getUserMobileEmail(data) {
    if (this.personIds && this.personIds.length === 0) {
      if (this.profileData && this.profileData.econsentDataResponseBeanList &&
        JSON.stringify(this.profileData.econsentDataResponseBeanList[0]) !== '{}' &&
        this.profileData.econsentDataResponseBeanList[0].econsentCustomerList &&
        this.profileData.econsentDataResponseBeanList[0].econsentCustomerList[0].eConsentBusinessGroupPartyInfo &&
        this.profileData.econsentDataResponseBeanList[0].econsentCustomerList[0].eConsentBusinessGroupPartyInfo.emailAddress) {
        this.mail = this.profileData.econsentDataResponseBeanList[0].econsentCustomerList[0].eConsentBusinessGroupPartyInfo.emailAddress;
        this.storageManagementService.setSessionData(SessionConstants.USER_PROFILE_EMAIL, this.mail);
      }

      if (this.profileData && this.profileData.econsentDataResponseBeanList &&
        JSON.stringify(this.profileData.econsentDataResponseBeanList[0]) !== '{}' &&
        this.profileData.econsentDataResponseBeanList[0].econsentCustomerList &&
        this.profileData.econsentDataResponseBeanList[0].econsentCustomerList[0].eConsentBusinessGroupPartyInfo &&
        this.profileData.econsentDataResponseBeanList[0].econsentCustomerList[0].eConsentBusinessGroupPartyInfo && !this.profileData.econsentDataResponseBeanList[0].econsentCustomerList[0].eConsentBusinessGroupPartyInfo.eConsentPhoneNumber && this.profileData.econsentDataResponseBeanList[0].econsentCustomerList[0].eConsentBusinessGroupPartyInfo.eConsentPhoneNumber !== null) {
        this.mobile = this.profileData.econsentDataResponseBeanList[0].econsentCustomerList[0].eConsentBusinessGroupPartyInfo.eConsentPhoneNumber;
        this.storageManagementService.setSessionData(SessionConstants.USER_PROFILE_MOBILE, this.mobile);
      }
    }
  }

  getUserMobileEmailCIAM(data) {
    const email = this.storageManagementService.getSessionData(SessionConstants.USER_PROFILE_EMAIL);
    const phone = this.storageManagementService.getSessionData(SessionConstants.USER_PROFILE_MOBILE);
    if (this.profileDataCIAM  &&    this.profileDataCIAM.mail && !email) {
      this.mail = this.profileDataCIAM.mail;
      this.storageManagementService.setSessionData(SessionConstants.USER_PROFILE_EMAIL, this.mail);
    }
    if (this.profileDataCIAM &&  this.profileDataCIAM.telephoneNumber && !phone) {
      this.mobile = this.profileDataCIAM.telephoneNumber;
      this.storageManagementService.setSessionData(SessionConstants.USER_PROFILE_MOBILE, this.mobile);

    }
  }

  triggerManagementService(reqType) {
    /*const reqType = '99';*/
    const directDepositMethod = null;
    this.eventManagementServiceService.callEventManagementService(reqType, directDepositMethod, (this.userDecision.mobileno ? this.userDecision.mobileno.replace(/\D+/g, '') :  '')).subscribe(
      eventManagementServiceResponse => {
        if (eventManagementServiceResponse) {
          const getResponse = eventManagementServiceResponse;
        }
      });
  }

  updateNotificationLogs() {
    const user: User = this.storageManagementService.getAppData(SessionConstants.USER_DATA);
    const partyRefId: string = (user && user.partyRefId) ? user.partyRefId : null;
    const tnCVernInd = (user && user.noteTnCVersnId && (this.statusTextcheckboxValue /*|| this.paymentTextcheckboxValue*/)) ? user.noteTnCVersnId : null;
    const tnCAcceptDtFlag = (user && user.noteTnCVersnId && (this.userDecision.mobileno ? this.userDecision.mobileno.replace(/\D+/g, '') :  '') && (this.statusTextcheckboxValue /*|| this.paymentTextcheckboxValue*/)) ? 'Yes' : 'No';
    if (partyRefId) {
      this.notificationRegistrationService.updateNotificationLog(partyRefId, tnCVernInd, tnCAcceptDtFlag).subscribe(
        (updateNotificationLogResponse: UpdateNotificationLogResponse) => {
        }
      );
    }
  }
}

