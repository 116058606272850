import {Component, OnInit, ViewEncapsulation} from '@angular/core';

@Component({
  selector: 'H1Label',
  templateUrl: 'H1Label.component.html',
  styleUrls: ['H1Label.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class H1LabelComponent implements OnInit {
  constructor() {};

  ngOnInit() {

  };

};
