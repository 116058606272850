import {
  ChangeDetectorRef,
  Component,
  Input,
  OnChanges,
  OnInit,
  SimpleChanges,
} from "@angular/core";
import { Router } from "@angular/router";
import { SessionConstants } from "@app/shared/constants/session.constants";
import { StorageManagementService } from "@app/shared/services/storage-management.service";
import { DataExchangeService } from "gb-cxr-thaw-absence-ui";
import { env } from "@env/environment-loader";
import {
  ABSENCE_PLANNING_CONSTANT,
  ABSENCE_PLANNING_WITH_CLAIM_CONSTANT,
} from "./absence-planning-constants";

@Component({
  selector: "absence-planning",
  templateUrl: "./absence-planning-card.component.html",
  styleUrls: ["./absence-planning-card.component.scss"],
})
export class AbsencePlanningCardComponent implements OnInit, OnChanges {
  @Input() userClaims = [];
  @Input() isAbsencePlanningMaternity = false;
  public pageTitle: string;
  public pageContent: string;
  public pageContent2: string;
  public pregnancyImg: string;
  public buttonLabel: string;
  public absenceJSON: any;
  public absenceWithClaimJSON: any;
  public buttonClasses = "btn-md";
  public buttonWidthClass = "btn-width";
  public alternateMargin = "";
  public claimActive = false;
  public appData: any;
  public magnoliaContent: any;
  public pageSpecificContentName = "Plan My Leave Launch from My Benefits";
  public planMyLeaveTitleLabel = "PML_Launch_01";
  public planMyLeaveContentLabel = "PML_Launch_02";
  public planMyLeaveContent2Label = "PML_Launch_03";
  public planMyLeaveClaimContentLabel = "PML_Launch_04";
  public planMyLeaveClaimContent2Label = "PML_Launch_05";
  public planMyLeaveButtonLabel = "PML_Launch_06";

  constructor(
    private router: Router,
    private dataExchangeService: DataExchangeService,
    private storageManagementService: StorageManagementService,
    private changeDetector: ChangeDetectorRef
  ) {
  }

  ngOnInit(): void {
    this.getContent();
  }

  getContent(): void {
    this.magnoliaContent = this.storageManagementService.getSessionData(
      this.pageSpecificContentName
    );
    this.absenceJSON = {
      title: this.extractMagnoliaContent(this.magnoliaContent, this.planMyLeaveTitleLabel),
      contentPart1: this.extractMagnoliaContent(this.magnoliaContent, this.planMyLeaveContentLabel),
      contentPart2: this.extractMagnoliaContent(
        this.magnoliaContent,
        this.planMyLeaveContent2Label
      ),
      pregnancyImg: "../../../assets/images/icons/GB-pregnant-human.svg",
      buttonLabel: this.extractMagnoliaContent(this.magnoliaContent, this.planMyLeaveButtonLabel),
    };
    this.absenceWithClaimJSON = {
      title: this.extractMagnoliaContent(this.magnoliaContent, this.planMyLeaveTitleLabel),
      contentPart1: this.extractMagnoliaContent(
        this.magnoliaContent,
        this.planMyLeaveClaimContentLabel
      ),
      contentPart2: this.extractMagnoliaContent(
        this.magnoliaContent,
        this.planMyLeaveClaimContent2Label
      ),
      pregnancyImg: "../../../assets/images/icons/GB-pregnant-human.svg",
      buttonLabel: this.extractMagnoliaContent(this.magnoliaContent, this.planMyLeaveButtonLabel),
    };
    const data = this.extractMagnoliaContent(this.magnoliaContent, this.planMyLeaveTitleLabel);
    if (!data) {
      this.absenceJSON = ABSENCE_PLANNING_CONSTANT;
      this.absenceWithClaimJSON = ABSENCE_PLANNING_WITH_CLAIM_CONSTANT;
    }
  }

  extractMagnoliaContent(magnoliaContent: any, label: any): string | null {
    if (magnoliaContent && magnoliaContent[label]) {
      return magnoliaContent[label].value;
    } else if (magnoliaContent?.data?.[label]) {
      return magnoliaContent.data[label].value;
    } else {
      return null;
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.userClaims) {
      setTimeout(() => {
        this.fetchAbsenceData();
        this.claimActive = this.userClaims.length !== 0;
        this.buttonClasses = this.userClaims.length === 0 ? "btn-md" : "btn-lg";
        this.buttonWidthClass = this.userClaims.length === 0 ? "btn-width" : "btn-lg-width";
        this.alternateMargin = this.userClaims.length === 0 ? "" : "alternate-margin";
        this.changeDetector.detectChanges();
      }, 1000);
    }
  }

  fetchAbsenceData(): void {
    const jsonData = this.userClaims.length === 0 ? this.absenceJSON : this.absenceWithClaimJSON;
    this.pageTitle = jsonData.title;
    this.pageContent = jsonData.contentPart1;
    this.pageContent2 = jsonData.contentPart2;
    this.pregnancyImg = jsonData.pregnancyImg;
    this.buttonLabel = jsonData.buttonLabel;
  }

  planAbsence(): void {
    this.router.navigate(["/leave-plan"]);
    this.appData = JSON.parse(
      this.storageManagementService.retrieveSessionObject(SessionConstants.APP_DATA)
    ).appData;
    const caseId = this.appData.caseData.defaultCaseId;
    const cases = this.appData.caseData.cases;
    const companyName = cases.find((obj) => obj.caseId === caseId);
    const absenceData = {
      absenceData: {
        userData: {
          personIds: this.appData.userData?.personIds,
          empId: this.appData.userData?.empId,
          lastName: this.appData.userData?.lastName,
          firstname: this.appData.userData?.firstname,
          stateCode: this.appData.userData?.stateCode,
          stateDescription: "",
          provinceCode: this.appData.userData?.provinceCode,
          foreignProvinceCode: this.appData.userData?.foreignProvinceCode,
          countryCode: this.appData.userData?.countryCode,
        },
        caseData: {
          defaultCaseId: this.appData.caseData.defaultCaseId,
          products: this.appData.coveragePlans?.coveragePlansList,
          cases: [
            {
              caseId: companyName?.caseId,
              caseName: companyName?.caseName,
              defaultCase: companyName?.defaultCase,
            },
          ],
        },
        leavePlanToggle: this.isAbsencePlanningMaternity,
        logoutPath: env?.gbCiamCommonUtil?.SfdAccountUrl + "/navigation/logout",
      },
    };
    this.dataExchangeService.setData(absenceData);
  }
}
