import {Component, Input} from '@angular/core';
import {CustomPageLabelComponent} from '../../../sub-atomic/custom-page-label/custom-page-label.component';
import {AppLabelConstants} from '../../../../../shared/constants/appLabel.constants';

@Component({
  selector: 'primary-page-label',
  templateUrl: 'primary-page-label.component.html',
  styleUrls: ['primary-page-label.component.scss']
})

export class PrimaryPageLabelComponent extends CustomPageLabelComponent  {
  _ariaLevel: string = '2';
  _ariaLabel: string = '';
  public appLabelConstants = AppLabelConstants;
  @Input() set ariaLevel(data: string) {
    if (data) {
      this._ariaLevel = data;
    }
  }
  @Input() set ariaLabel(data: string) {
  if (data) {
    this._ariaLabel = data;
  }
}
}
