

export class Faq {
    id: string;
    question: string;
    answer: string;
    feedback: Feedback;
}

export class FAQResponse {
  status: string;
  data: FAQAsset[];
}

export class FAQAsset {
  id: string;
  key: string;
  question: string;
  answer: string;
  parentId: string;
}

export class  FaqRequest {
  requestedFaqIds: string[];
}

export class Feedback {
  if_thumbs_up_clicked: boolean;
  if_thumbs_down_clicked: boolean;

  constructor() {
    this.if_thumbs_up_clicked = false;
    this.if_thumbs_down_clicked = false;
  }
}

