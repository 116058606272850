import {GbBase} from './gb-base';

export class GbCase extends GbBase {

  getUrls() {
    return {
      getUserCasesURL: this.getBaseUrl() + `gb-case-association-service/login/v1/cases?` + this.getClientId('auth'),
      setUserDefaultCaseIdURL: this.getBaseUrl() + `gb-case-association-service/login/v1/defaultCase?` + this.getClientId('auth'),
    }
  }
}
