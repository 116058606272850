import {Component, Input, OnInit} from '@angular/core';
import {AppLabelConstants} from '../../../shared/constants/appLabel.constants';
import {AppConstants} from '../../../shared/constants/app.constants';
import { StorageManagementService } from '../../../shared/services/storage-management.service';


@Component({
  selector: 'app-claim-overview-claim-denied',
  templateUrl: 'claim-overview-claim-denied.component.html',
  styleUrls: ['claim-overview-claim-denied.component.scss']
})
export class ClaimOverviewClaimDeniedComponent implements OnInit {
  @Input() claimStatus;
  @Input() claimType;
  @Input() claimSubType;
  @Input() claimOnlineStatus;
  @Input() claimOnlineStatusCode;
  @Input() claimOnlineSubStatusCode;
  @Input() claimOnlineSubStatus;
  @Input() coverageCategory;
  @Input() showProgressIcon;
  @Input() claimProgress;
  @Input() orangeIconMissingInfo;
  @Input() claim;
   isClaimInProgress: boolean = false;
   isClaimCompleted: boolean = false;
  public message;
  public appLabelConstant = AppLabelConstants;
  public AppConstants = AppConstants;
  public claimStatusCode;
  public rtwStatusCode;
  public sourceSystemId;
  public defaultCheckIcon: boolean = false;
  isPaymentDisabled: boolean = false;
  constructor(private storageManagementService: StorageManagementService) { }
  ngOnInit() {
    this.isPaymentDisabled = this.storageManagementService.getSessionData(AppConstants.IS_PAYMENT_DISABLED);
    this.sourceSystemId = this.claim.sourceSystemId;
    this.coverageCategory = this.coverageCategory ? this.coverageCategory.toUpperCase() : null;
    this.claimStatusCode = this.claim.claimStatusCode;
    this.rtwStatusCode = this.claim.rtwStatusCode;
    this.claimOnlineStatusCode = this.claim.claimOnlineStatusCode;
    this.claimOnlineSubStatusCode = this.claim.claimOnlineSubStatusCode;
    this.isClaimInProgress = this.orangeIconMissingInfo;
    this.isClaimCompleted = this.showProgressIcon;

    if (this.claimStatus && this.coverageCategory && (this.coverageCategory.toUpperCase() === AppConstants.LTD || this.coverageCategory.toUpperCase() === AppConstants.LIFE || this.coverageCategory.toUpperCase() === AppConstants.PW)) {
      this.showProgressIcon = true;
      this.defaultCheckIcon = true;
    }
    this.setClaimsStatusMessage(this.claimStatusCode, this.rtwStatusCode, this.claimOnlineStatusCode, this.claimOnlineSubStatusCode);
  }
  setClaimsStatusMessage(claimStatusCode, rtwStatusCode, claimOnlineStatusCode, claimOnlineSubStatusCode) {
    const statusMessage = {
      [AppConstants.CS_PENDING_CD_01] : AppConstants.CLAIM_REVIEW,
      [AppConstants.RTWS_CD_42001] : AppConstants.CLAIM_REVIEW,
      [AppConstants.AA_CS_PEND_CD_773500]: AppConstants.CLAIM_REVIEW,
      [AppConstants.CS_FIRST_NOTICE_CD_08] : AppConstants.CLAIM_SUBMITTED,
      [AppConstants.CS_APPROVED_CD_02]: AppConstants.CLAIM_APPROVED ,
      [AppConstants.RTWS_CD_42002] : AppConstants.CLAIM_APPROVED ,
      [AppConstants.AA_CS_APPROVED_CD_773501]: AppConstants.CLAIM_APPROVED ,
      [AppConstants.CS_TERMINATE_CD_04]: AppConstants.CLOSED_CLAIM ,
      [AppConstants.RTWS_CD_42003]: AppConstants.CLOSED_CLAIM ,
      [AppConstants.AA_CS_TERMINATE_CD_773503]: AppConstants.CLOSED_CLAIM ,
      [AppConstants.CS_DENIED_CD_03]: AppConstants.CLAIM_DENIED,
      [AppConstants.RTWS_CD_42004]: AppConstants.CLAIM_DENIED,
      [AppConstants.AA_CS_DENIED_CD_773502]: AppConstants.CLAIM_DENIED,
      [AppConstants.CS_PENDING_CD_00] : AppConstants.CLAIM_REVIEW,
      [AppConstants.RTWS_CD_42006] : AppConstants.CLAIM_REVIEW


    }
    const onlineStatusMessage = {
      [AppConstants.COS_UNDER_REVIEW_BY_CLAIM_HANDLER_CD_08]: AppConstants.CLAIM_REVIEW,
      [AppConstants.COSS_AWAITING_SURGERY_DETAILS_CD_03]: AppConstants.AWAITING_SURGERY,
      [AppConstants.COS_AWAITING_PAID_FAMILY_LEAVE_CD_09]: AppConstants.AWAIT_PAID_FAMILY_LEAVE
    }
    const onlineSubStatusMessage = {
      [AppConstants.COSS_AWAITING_PAID_FAMILY_LEAVE_CD_14]: AppConstants.AWAIT_PAID_FAMILY_LEAVE
    }
    this.message = this.defaultCheckIcon ? (statusMessage[claimStatusCode] ? statusMessage[claimStatusCode] :
          statusMessage[rtwStatusCode]) : (onlineStatusMessage[claimOnlineStatusCode] && onlineSubStatusMessage[claimOnlineSubStatusCode]) ? onlineSubStatusMessage[claimOnlineSubStatusCode] :
      onlineStatusMessage[claimOnlineStatusCode] ? onlineStatusMessage[claimOnlineStatusCode] : null;
    this.message = this.message ? this.message : this.claimProgress;
    if (this.message === AppConstants.IN_PAYMENT) {
      this.message = this.AppConstants.CLAIM_APPROVED;
    }
  }
}
