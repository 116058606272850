import {GbBase} from './gb-base';

export class GbCxrEmail extends GbBase {

  getUrls() {
    return {
      mailEventServiceUrl:            this.getBaseUrl() + 'gb-email-service/email/v1/event-manager?'       + this.getClientId('auth'),
      triggerProdSupportEmail:        this.getBaseUrl() + 'gb-email-service/email/v1/trigger-pce-email?'   + this.getClientId('auth')
    }
  }
}

