import {GbBase} from './gb-base';

export class GbProfileSync extends GbBase {

  getUrls() {
    return {
      profileSyncURL: this.getBaseUrl() + 'gb-profile-sync-service/profile-sync/v1/CXR/profile?' + this.getClientId('auth'),
    }
  }
}
