import {Component, OnInit} from '@angular/core';
import {Router} from '@angular/router';
import {StorageManagementService} from '../../../../shared/services/storage-management.service';
import {ClaimRepresentativeService} from '../../../../shared/services/claimRepresentativeDetails.service';
import {ScheduledCallService} from '../../../../shared/services/scheduled-call.service';
import {CustomEventService} from '../../../../shared/services/tealium/custom-event.service';
import {SessionConstants} from '../../../../shared/constants/session.constants';
import {AppLeaveConst} from '../../../../shared/constants/app.constants';
import {AppLabelConstants} from '../../../../shared/constants/appLabel.constants';
import {AppLeaveConstants} from '../../../leave-details-flow/leave-details.constants';
import {AdaProductService} from '../../../../shared/services/ada-product.service';

/**
 * Schedule Call Link Component
 */
@Component({
  selector: 'app-schedule-call-link',
  templateUrl: './schedule-call-link.component.html',
  styleUrls: ['./schedule-call-link.component.scss']
})
export class ScheduleCallLinkComponent implements OnInit {
  public haveAnyAaClaim: boolean = false;
  public isNormalAdaLeave: boolean = false;
  public thaaEnhancedAda: boolean = false;
  public enhancedAdaLeave;
  public mostRecentAAClaim;
  public relatedClaims;
  public nonRelatedClaims;
  public leaveClaims;
  public availabilityNextFiveBusinessDays: boolean;
  public currentDate = new Date();
  public estimeteAvailableDate: Date;
  public schedule_call: string;
  public ciiIndicators;
  public appConst = {...AppLabelConstants, ...AppLeaveConstants};
  private appData;
  private personId: string;
  private claimId: string;
  private claimList = [];
  private curClaim;
  private currLeave;
  private sortedClaimList;
  private currentUrl;
  private previousUrl;

  /**
   * @param router
   * @param storageManagementService
   * @param claimRepresentativeService
   * @param customEventService
   * @param scheduledCallService
   */
  constructor(private router: Router,
              private storageManagementService: StorageManagementService,
              private claimRepresentativeService: ClaimRepresentativeService,
              private customEventService: CustomEventService,
              private adaProductService: AdaProductService,
              private scheduledCallService: ScheduledCallService) { }

  ngOnInit() {
    this.currentUrl = this.router.url;
    this.ciiIndicators = this.storageManagementService.getSessionData(SessionConstants.CII_FEATURE_DATA);
    if (this.storageManagementService.retrieveSessionObject(SessionConstants.APP_DATA)) {
      this.appData = JSON.parse(this.storageManagementService.retrieveSessionObject(SessionConstants.APP_DATA)).appData;
    }

    if (this.ciiIndicators && this.ciiIndicators.allowScheduleACall === 1) {
      this.schedule_call = AppLabelConstants.SCHEDULE_A_CALL_LABEL;
      if (this.currentUrl.includes(AppLabelConstants.URL_HOME)) {
        if ( this.adaProductService.isAdaLeaveExists() ) {
          if (this.storageManagementService.getSessionData(SessionConstants.THAA_ENHANCED_ADA)) {
            this.thaaEnhancedAda = this.storageManagementService.getSessionData(SessionConstants.THAA_ENHANCED_ADA);
            this.checkAaClaimsExist();
          } else {
            this.adaProductService.isAdaProductSet.subscribe(value => {
              this.thaaEnhancedAda = this.adaProductService.isEnhancedAdaApplicable();
              this.checkAaClaimsExist();
            })
          }
        } else {
          this.checkAaClaimsExist();
        }
        this.previousUrl = this.currentUrl;
      }
      if ( this.currentUrl === AppLabelConstants.URL_CLAIM_DETAILS
        || this.currentUrl === AppLabelConstants.URL_SINGLE_PAYMENT
        || this.currentUrl === AppLabelConstants.LINK_TO_SEE_MORE_FUTURE_PAYMENTS
      ) {
        this.previousUrl = this.currentUrl;
        this.curClaim = this.storageManagementService.getSessionData(SessionConstants.CUR_CLAIM);
        this.personId = this.curClaim.personId;
        this.claimId = this.curClaim.claimEventId;
        if (this.curClaim.sourceSystemId === AppLeaveConst.SOURCE_SYS_ID_AA) {
          this.haveAnyAaClaim = true;
          this.checkScheduleTimeAvail(this.personId, this.claimId);
        }
      }
      if (this.currentUrl === AppLabelConstants.URL_LEAVE_DETAILS) {
        this.previousUrl = this.currentUrl;
        this.currLeave = this.storageManagementService.getSessionData(SessionConstants.CURR_LEAVE);
        this.setClaimRelatedToLeave();
        this.curClaim = this.storageManagementService.getSessionData(SessionConstants.CUR_CLAIM);
        if ( this.currLeave && this.currLeave.leaveType.includes(this.appConst.ADA) ) {
          this.thaaEnhancedAda = this.storageManagementService.getSessionData(SessionConstants.THAA_ENHANCED_ADA);
          const foundationalAda = this.storageManagementService.getSessionData(SessionConstants.THAA_FOUNDAMENTAL_ADA);
          if ( this.thaaEnhancedAda || foundationalAda) {
            this.personId = this.currLeave.personId;
            this.claimId = this.currLeave.leaveId;
            this.isNormalAdaLeave = false;
            this.haveAnyAaClaim = true;
            this.checkScheduleTimeAvailForAda(this.personId, this.claimId);
          } else {
            this.isNormalAdaLeave = true;
          }
        } else if ( this.currLeave && this.curClaim) {
          this.isNormalAdaLeave = false;
          this.personId = this.curClaim.personId;
          this.claimId = this.curClaim.claimEventId;
          this.haveAnyAaClaim = true;
          this.checkScheduleTimeAvail(this.personId, this.claimId);
        }
      }
    }
  }

  /**
   * Set claim related to leave in session to show claim handler of that claim in its concurrent leave
   */
  setClaimRelatedToLeave() {
    const id = this.currLeave.leaveId;
    const sortedBenefits = this.storageManagementService.getSessionData(SessionConstants.SORTED_BENEFITS);
    for (const entry of sortedBenefits) {
      if ( Array.isArray(entry)) {
        const data = entry.filter((claim) => (claim.concurrentLeaveIDs && claim.concurrentLeaveIDs.indexOf(id) >= 0));
        if (data.length > 0) {
          this.storageManagementService.setSessionData(SessionConstants.CUR_CLAIM, data[0]);
          break;
        }
      } else {
        this.storageManagementService.setSessionData(SessionConstants.CUR_CLAIM, '');
      }
    }
  }

  /**
   * function is called when clicked on schedule a call button
   */
  onScheduleCall() {
    if (this.currentUrl.includes(AppLabelConstants.URL_HOME)) {
      this.checkAaClaimsExist();
    }
    this.checkScheduleStatus();
    this.storageManagementService.setSessionData(SessionConstants.PREVIOUS_URL, this.previousUrl);
    this.storageManagementService.setSessionData(SessionConstants.RESET_SLOT, false);
  }

  /**
   * Check if AA claim exist
   */
  checkAaClaimsExist() {
    const userClaims = this.appData ? this.appData.userClaims ? this.appData.userClaims : null : null;
    const claimOverview = userClaims ? userClaims.claimOverview ? userClaims.claimOverview : null : null;
    this.relatedClaims = claimOverview ? (claimOverview.relatedClaims && claimOverview.relatedClaims.length > 0) ? claimOverview.relatedClaims : null : null;
    this.nonRelatedClaims = claimOverview ? (claimOverview.nonRelatedClaims && claimOverview.nonRelatedClaims.length > 0) ? claimOverview.nonRelatedClaims : null : null;
    this.leaveClaims = claimOverview ? (claimOverview.leaveClaims && claimOverview.leaveClaims.length > 0) ? claimOverview.leaveClaims : null : null;

    if ( this.relatedClaims ) {
      for (let i = 0; i < this.relatedClaims.length; i++) {
        this.claimList.push(this.relatedClaims[i]);
      }
    }
    if ( this.nonRelatedClaims ) {
      for (let j = 0; j < this.nonRelatedClaims.length; j++) {
        this.claimList.push(this.nonRelatedClaims[j]);
      }
    }
    if ( this.leaveClaims ) {
      for (let l = 0; l < this.leaveClaims.length; l++) {
        this.claimList.push(this.leaveClaims[l]);
      }
    }
    this.sortedClaimList = this.sortData();
    for (let k = 0; k < this.sortedClaimList.length; k++) {
      if (this.sortedClaimList[k].sourceSystemId && this.sortedClaimList[k].sourceSystemId === AppLeaveConst.SOURCE_SYS_ID_AA && this.sortedClaimList[k].personId && this.sortedClaimList[k].claimEventId) {
        this.haveAnyAaClaim = true;
        this.personId = this.sortedClaimList[k].personId;
        this.claimId = this.sortedClaimList[k].claimEventId;
        if (this.currentUrl.includes(AppLabelConstants.URL_HOME)) {
          this.mostRecentAAClaim = this.sortedClaimList[k];
          this.storageManagementService.setSessionData(SessionConstants.CUR_CLAIM, this.sortedClaimList[k]);
        }
        break;
      }
    }

    //code to check for most recent enhanced ada leave on overview page
    if (this.thaaEnhancedAda && this.currentUrl.includes(AppLabelConstants.URL_HOME)) {
      this.checkAndSetEnhancedAdaLeave();
    } else {
      this.checkScheduleTimeAvail(this.personId, this.claimId);
    }
  }

  /**
   * function to check if most recent enhanced ada leave exists and set it
   */
  checkAndSetEnhancedAdaLeave() {
    const sortedBenefits = this.storageManagementService.getSessionData(SessionConstants.SORTED_BENEFITS);
    let adaLeave;
    //find the most recent adaLeave from the sorted benefits.
    if ( sortedBenefits.length > 0 ) {
      for ( let i = 0; i < sortedBenefits.length; i++ ) {
        if ( Array.isArray(sortedBenefits[i]) ) {
          adaLeave = sortedBenefits[i].find(leave => leave.leaveType === AppLeaveConst.ADA_TYPE);
        } else if ( sortedBenefits[i].leaveType && sortedBenefits[i].leaveType === AppLeaveConst.ADA_TYPE ) {
          adaLeave = sortedBenefits[i];
        }
        if ( adaLeave ) {
          break;
        }
      }
    }
    //for concurrent or standalone ada leave
    if ( this.mostRecentAAClaim ) {
      const curClaimDisabilityDate = new Date(this.mostRecentAAClaim.disabilityDate).getTime();
      const adaLeaveStartDate =  new Date(adaLeave.leaveStartDate).getTime();
      if  ( adaLeaveStartDate >= curClaimDisabilityDate ) {
        this.enhancedAdaLeave = adaLeave;
      }
    } else { //for standalone ada only leaves
      if (sortedBenefits && sortedBenefits[0].leaveType && sortedBenefits[0].leaveType === AppLeaveConst.ADA_TYPE ) {
        this.enhancedAdaLeave = {...sortedBenefits[0]};
      }
    }
    if (this.enhancedAdaLeave) {
      this.haveAnyAaClaim = true;
      this.isNormalAdaLeave = false;
      this.personId = this.enhancedAdaLeave.personId;
      this.claimId = this.enhancedAdaLeave.leaveId;
      this.checkScheduleTimeAvailForAda(this.personId, this.claimId);
      const thaaEnhancedAdaLeave = this.storageManagementService.getSessionData(SessionConstants.THAA_ENHANCED_ADA_LEAVE);
      if ( !thaaEnhancedAdaLeave ) {
        this.storageManagementService.setSessionData(SessionConstants.THAA_ENHANCED_ADA_LEAVE, this.enhancedAdaLeave);
      }
    } else {
      this.checkScheduleTimeAvail(this.personId, this.claimId);
    }
  }

  /**
   * sort AA claims/Leave based on disability date/leave start date
   */
  sortData() {
    return this.claimList.sort((a, b) => (
      (new Date(b.disabilityDate) as any) - (new Date(a.disabilityDate) as any) || (new Date(b.leaveStartDate) as any) - (new Date(a.leaveStartDate) as any))
    );
  }

  /**
   * to check if claim handler is avaliable based on which schedule a call button is displayed
   *
   * @param personIdVal
   * @param claimIdVal
   */
  checkScheduleTimeAvail(personIdVal, claimIdVal) {
    const startDate = new Date();
    let offset = 5;
    while (offset > 0) {
      this.estimeteAvailableDate = new Date(startDate.setDate(startDate.getDate() + 1));
      if (this.estimeteAvailableDate.getDay() !== 0 && this.estimeteAvailableDate.getDay() !== 6) {
        offset--;
      }
    }

    if (this.haveAnyAaClaim) {
      this.claimRepresentativeService.getClaimRepresentativeDetails(personIdVal, claimIdVal).subscribe(data => {
        if (data) {
          if (data.available && data.available.length > 0) {
            for (let k = 0; k < data.available.length; k++) {
              if ((data.claimOwnerName || data.claimOwnerName !== '') && ((new Date(data.available[k].AvailableDateTime) < this.estimeteAvailableDate) && new Date(data.available[k].AvailableDateTime) > this.currentDate)) {
                this.availabilityNextFiveBusinessDays = true;
              }
            }
          }
        }
      });
    }
  }

  /**
   * to check if claim handler is avaliable for Leave based on which schedule a call button is displayed
   *
   * @param personIdVal
   * @param leaveIdVal
   */
  checkScheduleTimeAvailForAda(personIdVal, leaveIdVal) {
    const startDate = new Date();
    for  (let i = 5; i > 0;) {
      this.estimeteAvailableDate = new Date(startDate.setDate(startDate.getDate() + 1));
      const getDayValue = this.estimeteAvailableDate.getDay();
      if (getDayValue !== 0 && getDayValue !== 6) {
        i--;
      }
    }
    if (this.haveAnyAaClaim) {
      this.claimRepresentativeService.getLeaveRepresentativeDetails(personIdVal, leaveIdVal).subscribe(data => {
        if (data) {
          if (data.available && data.available.length > 0) {
            for (let k = 0; k < data.available.length; k++) {
              if ((data.claimOwnerName || data.claimOwnerName !== '') && ((new Date(data.available[k].AvailableDateTime) < this.estimeteAvailableDate) && new Date(data.available[k].AvailableDateTime) > this.currentDate)) {
                this.availabilityNextFiveBusinessDays = true;
              }
            }
          }
        }
      });
    }
  }


  /**
   * service call to get schedule status
   */
  checkScheduleStatus() {
    this.scheduledCallService.getScheduleStatus(this.personId).subscribe(data => {
      if (data) {
        if (data.hasAppointments === true && (data.PreviousAppointmentDetails && ((new Date(data.PreviousAppointmentDetails.AppointmentDate)) >= this.currentDate))) {
          this.storageManagementService.setSessionData(SessionConstants.SCHEDULE_STATUS, data);
          this.router.navigateByUrl(AppLabelConstants.URL_SCHEDULE_CALL_EDIT);
        } else {
          this.storageManagementService.setSessionData(SessionConstants.SCHEDULE_STATUS, data);
          this.router.navigateByUrl(AppLabelConstants.URL_SCHEDULE_CALL);
        }
      }
    });
  }

  /**
   * this function called for analytics purpose to track if schedule a call button is clicked
   */
  trackScheduleACAllClick() {
    this.customEventService.customEvent(AppLabelConstants.SCHEDULE_CALL, AppLabelConstants.PAGE_TAG, AppLabelConstants.BUTTON_CLICK, AppLabelConstants.SCHEDULE_CALL_ID, AppLabelConstants.SCHEDULE_CALL);
  }
}
