import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { INTAKE_ROUTES } from '@app/cxr/modules/intake/shared/constants/Intake-routing.constants';
import { BondingIntakeSessionConstants, IntakeSessionConstants } from '@app/cxr/modules/intake/shared/constants/intakeSession.constants';
import { SessionConstants } from '@app/shared/constants/session.constants';
import { StorageManagementService } from '@app/shared/services/storage-management.service';
import { IPopReqExtensionId } from '@shared/models/gbLeave.model';
import { AppConstants } from '../../../shared/constants/app.constants';
import { AppLabelConstants } from '../../../shared/constants/appLabel.constants';
import { ModalService } from '../../../shared/services/modal.service';

@Component({
  selector: 'app-popup-request-extension',
  templateUrl: 'popup-request-extension.component.html',
  styleUrls: ['popup-request-extension.component.scss']
})
export class PopupRequestExtensionComponent implements OnInit {
  public applabelConstants = AppLabelConstants;
  @Input() routLink: string;
  @Input() intakeSession?: boolean = false;
  @Input() popupReqExtenisonId?: IPopReqExtensionId;
  @Input() isNewIntake?: boolean = false;
  @Input() isBondingIntake?: boolean = false;
  popupReqExtId: IPopReqExtensionId = {mobileReqExtnModalExitId: '', mobileReqExtnModalCancelId: '', reqExtnModalCancelId: '', reqExtnModalExitId: ''};
  constructor(private modalService: ModalService, private router: Router, private storageManagementService: StorageManagementService) { }

  ngOnInit() {
      this.popupReqExtId.mobileReqExtnModalExitId = this.popupReqExtenisonId?.mobileReqExtnModalExitId ?? 'mobileReqExtnModalExitId';
      this.popupReqExtId.mobileReqExtnModalCancelId = this.popupReqExtenisonId?.mobileReqExtnModalCancelId ?? 'mobileReqExtnModalCancelId';
      this.popupReqExtId.reqExtnModalCancelId = this.popupReqExtenisonId?.reqExtnModalCancelId ?? 'reqExtnModalCancelId';
      this.popupReqExtId.reqExtnModalExitId = this.popupReqExtenisonId?.reqExtnModalExitId ?? 'reqExtnModalExitId';
    }
  clickCancel() {
    this.modalService.closeModal(AppLabelConstants.CANCEL_MODAL);
  }
  exit() {
    const maternityLOA=this.storageManagementService.getIntakeSessionData(IntakeSessionConstants.LOA_INDICATOR);
    const maternityIntakeAbandon = this.storageManagementService.getSessionData(IntakeSessionConstants.MATERNITY_INTAKE_ABANDON);
    const bondingIntakeAbandon = this.storageManagementService.getSessionData(BondingIntakeSessionConstants.BONDING_INTAKE_ABANDON);
    const bondingFlowApplicable = this.storageManagementService.getIntakeSessionData(IntakeSessionConstants.BONDING_FLOW_APPLICABLE);
    if(this.isNewIntake && (maternityIntakeAbandon === undefined || maternityIntakeAbandon !== 'true')) {
      document['maternityIntakeAbandon'] = true;
      this.storageManagementService.setSessionData(IntakeSessionConstants.MATERNITY_INTAKE_ABANDON,'true');
    }
    if(this.isBondingIntake && bondingFlowApplicable && (bondingIntakeAbandon === undefined || bondingIntakeAbandon !== 'true')) {
      document[BondingIntakeSessionConstants.BONDING_INTAKE_ABANDON] = true;
      this.storageManagementService.setSessionData(BondingIntakeSessionConstants.BONDING_INTAKE_ABANDON,'true');
    }
    if ( this.intakeSession || this.router.url === INTAKE_ROUTES.INTAKE_THINGS_YOU_WILL_NEED_CLEAR|| this.router.url === INTAKE_ROUTES.INTAKE_THINGS_YOU_WILL_NEED_LOA) {
      this.storageManagementService.removeSessionObject(IntakeSessionConstants.INTAKE_SESSION_DATA);
    }
    if(this.storageManagementService.getSessionData(SessionConstants.IS_WORK_SCHEDULE_SUBMITTED) && maternityLOA){
      this.storageManagementService.removeAttrFromSessionData(SessionConstants.IS_WORK_SCHEDULE_SUBMITTED);
    }
    if(this.storageManagementService.getSessionData(SessionConstants.REGULAR_WORK_SCHEDULE_SET) && maternityLOA){
      this.storageManagementService.removeAttrFromSessionData(SessionConstants.REGULAR_WORK_SCHEDULE_SET);
    }
    const navrout = (this.routLink ? this.routLink : AppConstants.ROUTE_TO_HOME);
    this.router.navigate([navrout]);
  }
}
