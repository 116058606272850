import {Directive, ElementRef, HostListener} from '@angular/core';

@Directive({
  selector: '[appAlphaNumericOnly]'
})
export class AlphaNumericOnlyDirective {
  private regexStr = '^[a-zA-Z0-9]*$';
  constructor(private el: ElementRef) { }
  @HostListener('keydown', ['$event']) onKeyDown(event) {
    const e = <KeyboardEvent> event;
    if ([46, 8, 9, 27, 13, 110, 190].indexOf(e.keyCode) !== -1 ||
        // Allow: Ctrl+A
      (e.keyCode === 65 && e.ctrlKey === true) ||
        // Allow: Ctrl+C
      (e.keyCode === 67 && e.ctrlKey === true) ||
        // Allow: Ctrl+V
      (e.keyCode === 86 && e.ctrlKey === true) ||
        // Allow: Ctrl+X
      (e.keyCode === 88 && e.ctrlKey === true) ||
        // Allow: home, end, left, right
      (e.keyCode >= 35 && e.keyCode <= 39)) {
      // let it happen, don't do anything
      return;
    }

    const current: string = this.el.nativeElement.value;
    const next: string = current.concat(event.key);
    if (next && !String(next).match(this.regexStr)) {
      event.preventDefault();
    }
  }
}
