import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';

@Component({
  selector: 'accordion-box',
  templateUrl: './accordion-box.component.html',
  styleUrls: ['./accordion-box.component.scss']
})
export class AccordionBoxComponent implements OnInit {
  @Input() public title: string;
  @Input() public futurePaymentInfoPayments;
  @Input() public subtitle: string;
  @Input() public secondsubtitle: string;
  @Input() public secondtitle: string;
  @Input() public defaultState: boolean = false;
  @Input() public noPadding: boolean = false;
  @Input() public labelWeight: string = 'book';
  @Input() public accWithSubTitle: boolean = false;
  @Input() public state: boolean = false;
  @Input() public id: string;
  @Input() expandLetters: boolean;
  @Output() clickFn = new EventEmitter();
  public futurePayBoolean: boolean = false;
  public accordionStatus;
  public dateRange;
  public duplicateTierArray = [];
  public accordionOpenOrClose: boolean = false;
  constructor() { }

  ngOnInit() {
    this.defaultToggle();
  if (this.futurePaymentInfoPayments) {
    this.futurePayBoolean = true;
  } else {
    this.futurePayBoolean = false;
  }
    if (this.expandLetters) {
      this.toggle();
    }
  }
  toggle() {
    this.state = !this.state;
    if (this.clickFn) {
      this.clickFn.emit(this.id);
    }
    //added for analytics and data tagging for opening and closing of the accordion.
    if (this.futurePaymentInfoPayments) {
      this.duplicateTierArray = this.futurePaymentInfoPayments.filter((v, i) => this.futurePaymentInfoPayments.findIndex(item => item.payReqID === v.payReqID) === i);
      for (let k = 0; k < this.duplicateTierArray.length; k++) {
        if (this.id === this.duplicateTierArray[k].payReqID) {
          this.dateRange = this.duplicateTierArray[k].paymentPeriodStartDate + '-' + this.duplicateTierArray[k].paymentPeriodEndDate;
          this.duplicateTierArray[k].accordionOpenClose = !this.duplicateTierArray[k].accordionOpenClose;
          if (this.duplicateTierArray[k].accordionOpenClose) {
            this.duplicateTierArray[k].accordionStatus = 'Estimated Pay – Accordion open';
            this.accordionStatus = this.duplicateTierArray[k].accordionStatus;
            this.accordionOpenOrClose = true;
          } else {
            this.duplicateTierArray[k].accordionStatus = 'Estimated Pay – Accordion close';
            this.accordionStatus = this.duplicateTierArray[k].accordionStatus;
            this.accordionOpenOrClose = false;
          }
        }
      }
      }
    }

  defaultToggle() {
    if (this.defaultState) {
      this.state = true;
    }
  }
}
