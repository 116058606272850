import {Component, OnInit} from '@angular/core';

@Component({
  selector: 'app-leave-timeline',
  templateUrl: './leave-timeline.component.html',
  styleUrls: ['./leave-timeline.component.scss']
})
export class LeaveTimelineComponent implements OnInit {

  public stages: Array<string>
  constructor() { }

  ngOnInit() {
    this.stages = ['Leave Started', 'Awaiting Documentation', 'Under Review', 'Leave Approved']
  }

  setWidth(): boolean {
    return true;
  }
}
