
export const MOCK_NOTIFICATION_PREFERENCES = {
  preferenceDetails: {
  mbPortal: true,
    bypassPaperlessClaims: true,
    userPreferenceSet: true,
    homeEmailAddress: 'test@gmail.com',
    mobileNumber: '1234567890',
preference: {
    email: 'false',
    text: 'false',
    payStub: 'true'
  }
},
  responseCode: '001',
  responseReason: 'success'
};
