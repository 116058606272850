import {gbLeaveDetailResponse} from '../shared/models/gbLeave.model';

export const MOCK_GB_LEAVE_DETAIL: gbLeaveDetailResponse = {
  errorList: null,
  userId: '1111',
  sessionIdentifier: '123',
  personID: '2397',
  LeaveList: [
    {
      leaveId: '12345678',
      leaveType: 'Standalone Leave' ,
      dateLeaveOpened: '01/01/1970',
      leaveProcessDate: '01/01/1970',
      leaveStartDate: '06/14/2017',
      leaveReason: 'Your Intermittent Leave',
      leaveEstimatedEndDate: '02/01/1970',
      caseId: '456789 ',
      leaveStatus: 'Open',
      leaveStatusId: '01',
      leaveSubStatus: 'Missing Paperwork',
      leaveSubStatusId: '478'
    },
    {
      leaveId: '12345679',
      leaveType: 'Reduced Leave' ,
      dateLeaveOpened: '01/01/1970',
      leaveProcessDate: '01/01/1970',
      leaveStartDate: '06/14/2017',
      leaveReason: 'Leave Tile Header',
      leaveEstimatedEndDate: '02/01/1970',
      caseId: '456789 ',
      leaveStatus: 'Open',
      leaveStatusId: '01',
      leaveSubStatus: 'Missing Paperwork',
      leaveSubStatusId: '478'
    }
    ]
};
