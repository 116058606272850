/**
 * Created by AC12394 on 2/6/2018.
 */

export class PHARecordsAvailability {
  caseId: string;
  partyRefId: string;
  insuredId: string;
  recordExist: boolean;

  constructor() {
    this.caseId = '';
    this.partyRefId = '';
    this.insuredId = '';
    this.recordExist = true;
  }
}

