import {Component, EventEmitter, Input, OnChanges, OnInit, Output} from '@angular/core';

@Component({
  selector: 'huk-style-radio-button',
  templateUrl: './huk-style-radio-button.component.html',
  styleUrls: ['./huk-style-radio-button.component.scss']
})
export class HukStyleRadioButtonComponent implements OnInit, OnChanges {
  @Output() radioValue: EventEmitter<string> =  new EventEmitter<string>();
  @Input() options;
  @Input() radioSpacing: boolean = false;
  @Input() intake: boolean = false;
  @Input() resetAlignment: boolean = false;
  @Input() resetRadioButton: boolean;
  @Input() set radioButtonId(data) {
    if (data) {
      this.radioOptionsId = data;
    }
  }
  @Input() set setPreSelectedButton(data: string) {
    if (data) {
      this.btnClick(data);
    }
  }
  public yesBtnActive;
  public noBtnActive;
  public maybeBtnActive;
  radioOptionsId: string = '';

  constructor() { }

  ngOnInit() {
  }

  ngOnChanges() {
    if (this.resetRadioButton) {
      this.yesBtnActive = false;
      this.noBtnActive = false;
      this.maybeBtnActive = false;
    }
  }

  btnClick(btn) {
    this.yesBtnActive = false;
    this.noBtnActive = false;
    this.maybeBtnActive = false;
    if (btn === this.options[0].value) {
      this.yesBtnActive = true;
    } else if (btn === this.options[1].value) {
      this.noBtnActive = true;
    } else {
      this.maybeBtnActive = true;
    }
    this.radioValue.emit(btn);
  }
}
