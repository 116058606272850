export const MOCK_PRODUCT_FORM_LIST = {
  formsList: [
    {
      header: 'ADA',
      forms: [
        {
          linkText: 'Healthcare Provider Form - ADA Accommodation',
          description: 'Have your healthcare provider fill this form out to support your request for accommodation under the Americans with Disabilities Act (ADA) Have your healthcare provider fill this form out to support your request for accommodation under the Americans with Disabilities Act (ADA)',
          link: '24_Healthcare_Provider_Form___ADA_Accommodation.pdf'
        }
      ]
    },
    {
      header: 'Attending Physician Statement',
      forms: [
        {
          linkText: 'Attending Physician Statement  for Mental Health',
          description: 'Have your healthcare provider fill this form out to support your request for accommodation under  ',
          link: '24_Attending_Physician_Statement__for_Mental_Health.pdf'
        },
        {
          linkText: 'Attending Physician Statement - Spanish',
          description: 'Have your healthcare provider fill this form out to support your request for accommodation under Have your healthcare provider fill this form out to support your request for accommodation under  ',
          link: '24_Attending_Physician_Statement___Spanish.pdf'
        },
        {
          linkText: 'Initial Attending Physicians Statement',
          description: 'Have your healthcare provider fill this form out to support your request for accommodation under Have your healthcare provider fill this form out to support your request for accommodation under Have your healthcare provider fill this form out to support your request for accommodation under  ',
          link: '24_Initial_Attending_Physicians_Statement.pdf'
        }
      ]
    },
    {
      header: 'Leave of Absence',
      forms: [
        {
          linkText: 'CA Cert of Health Care Provider Family Members Health Condition SP',
          description: '',
          link: '24_thaa_cfra_chcp_famshc_sp.pdf'
        },
        {
          linkText: 'CA Medical Cert - Employee Serious Health Condition',
          description: '',
          link: '24_CA_Medical_Cert___Employee_Serious_Health_Condition.pdf'
        },
        {
          linkText: 'CA Medical Cert - Family Member Serious Health Condition',
          description: ' ',
          link: '24_thaa_cfra_chcp_famshc.pdf'
        },
        {
          linkText: 'Cert for Serious Injury or Illness of Vet for Military Caregiver ',
          description: ' ',
          link: '24_Cert_for_Serious_Injury_or_Illness_of_Vet_for_Military_Caregiver_LC_7589.pdf'
        },
        {
          linkText: 'Cert Health Care Provider Family Member\'\'s Serious Health Condition SP',
          description: ' ',
          link: '24_Cert_Health_Care_Provider_Family_Member_Serious_Health_Condition_SP.pdf'
        },
        {
          linkText: 'Cert of Health Care Provider - EE\'\'s Serious Health Condition Spanish',
          description: ' ',
          link: '24_Cert_of_Health_Care_Provider___EE_s_Serious_Health_Condition___SP.pdf'
        },
        {
          linkText: 'Cert of Health Care Provider Family Member\'\'s Serious Health Condition',
          description: ' ',
          link: '24_Cert_Health_Care_ProviderFamily_Member_s_Serious_Health_Condition.pdf'
        },
        {
          linkText: 'Certification of Qualifying Exigency - For Military Family Leave',
          description: ' ',
          link: '24_Certification_of_Qualifying_Exigency_For_Military_Family_Leave.pdf'
        },
        {
          linkText: 'Certification of Serious Injury or Illness of a Current Servicemember',
          description: ' ',
          link: '24_Certification_of_Serious_Injury_or_Illness_of_a_Current_Servicemember.pdf'
        }
      ]
    },
    {
      header: 'Paid Family Leave',
      forms: [
        {
          linkText: 'NY Paid Family Leave: Care of Family Member',
          description: ' ',
          link: '24_NY_PFL___Care_of_Family_Member_Application___LC_7730__2_.pdf'
        }
      ]
    },
    {
      header: 'Statutory Forms',
      forms: [
        {
          linkText: 'NY DBL- Notice and Proof of Claim for Disability Benefits ',
          description: ' ',
          link: '24_24_NY_DBL__Notice_and_Proof_of_Claim_for_Disability_Benefits_DB_450_2021.pdf'
        }
      ]
    },
    {
      header: 'The Hartford Authorization',
      forms: [
        {
          linkText: 'Application for Group Long Term Disability Income Benefits',
          description: ' ',
          link: '24_24_LTD_EE_Stmt_No_Auth_LC_7709.2__7_2020_.pdf'
        },
        {
          linkText: 'Cert. of Health Provider Form - Employee Own Illiness',
          description: ' ',
          link: '24_24_Cert._of_Health_Care_Provider_Form___Employee_Own_Illness.pdf'
        },
        {
          linkText: 'Disability Appeal Questionnaire',
          description: ' ',
          link: '24_24_Disability_Appeal_Questionnaire.pdf'
        },
        {
          linkText: 'Spanish LTD Employee Statement',
          description: ' ',
          link: '24_24_Spanish_LTD_Employee_Statement.pdf'
        }
      ]
    }
  ],
  responseCode: '001',
  responseReason: 'success'

}
