import {Component, OnDestroy, OnInit} from '@angular/core';
import {AppLabelConstants} from '../../shared/constants/appLabel.constants';
import {AuthStateService} from '../../shared/services/auth-state.service';

@Component({
  selector: 'app-session-time-out',
  templateUrl: './session-time-out.component.html',
  styleUrls: ['./session-time-out.component.scss']
})
export class SessionTimeOutComponent implements OnInit, OnDestroy {
  readonly appLabelConstants = AppLabelConstants;
  public sessionTimeOutModal = AppLabelConstants.SESSION_TIME_OUT_MODAL;
  public sessionTimeoutNotHidden = true;
  sessionTimeInd;
  constructor(private authService: AuthStateService) {}

  ngOnInit() {
    this.sessionTimeInd = this.authService.inactiveOpenChange.subscribe(response => {
        this.sessionTimeoutNotHidden = false
    })
  }

  ngOnDestroy() {
    if (this.sessionTimeInd) {
      this.sessionTimeInd.unsubscribe();
    }
  }

  returnSession()  {
    this.sessionTimeoutNotHidden = true;
    this.authService.closeModal(this.sessionTimeOutModal);
  }
}
