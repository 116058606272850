import {EnrollDeposit} from '../shared/models/enrolldeposit.model';

export const ENROLLDEPOSIT: EnrollDeposit[] = [

  {
    id: 1,
    partyrefId: '00001',
    insuredId: '00002',
    externalABANumber: '062004099', //Also known as routing number
    bankName: 'CAPITALSOUTH BANK',
    externalAccountType: 'Checking',
    externalAccountNumber: '1234567890123',
    authorizedUsers: 'Bob Cozzzlin,Rob,John',

  }
];

export const ADD_BANKING_DETAILS = {
    errorList: null,
    userId: null,
    sessionIdentifier: null,
    statusMessage: 'successful',
    statusCode: '0'
  };
