import { IMyDpOptions } from 'mydatepicker';
export class IntakeLabelConstants {
  static WARNING_VALUE = "You can't choose"
  static WARNING_BANNER_REP_ID ='Reach_a_representative';
  static WARNING_BANNER_DATE_ID = 'Date_first_reported';
  static WARNING_BANNER_REASON_VALUE = 'Reason for your absence';
  static WARNING_BANNER_VALUE ='What is or will be your first day out of work?' ;
  static WARNING_BANNER_EVENT = 'Start a new claim warning message';
  static FIELD_REQUIRED = 'Field Required'
  static WEEKLY_EXAMPLE ='Weekly Schedule Example';
  static WEEKLY_TITLE ='Weekly';
  static MULTI_WEEK_EXAMPLE ='Multi-Week Schedule Example';
  static ROTATING_WEEK_EXAMPLE ='Rotating Schedule Example';
  static WORK_DAY_KEY ='Work Day';
  static NON_WORK_DAY_KEY ='Non Work Day';
  static EDIT_WORK_SCHEDULE ='Edit Work Schedule';
  static ROTATING_SUB_HEADER = '(6 days on, 3 days off)';
  static SUBMIT = 'Submit';
  static START_CLAIM = 'Start a New Claim';
  static START_NEW_CLAIM = 'Start a New Leave AA Step 1';
  static CANCEL_MODAL = 'cancelModal';
  static CANCEL_MODAL_TITLE = 'Are you sure you want to exit?';
  static PREVIOUS_URL = 'previousUrl';
  static ADA_ACCOMMODATE = 'ADA accommodation';
  static NEWINTAKE = 'newIntake';
  static EMPLOYEE_STATUS = "employeethaastatus";
  static MATERNITY_LOA = 'MaternityLOA';
  static STD_ELIGIBILITY = 'stdEligibility';
  static NEW_FDA_FLOW = 'newFDAFlow';
  static ESTIMATED_FDA_NOFLOW = '125002';
  static ESTIMATED_FDA_YESFLOW = '125001';
  static LAST_DAY_WORK_NOFLOW = '1002'
  static DATE_IS_INVALID = 'Date is Invalid';
  static CONFIRM_SCHEDULE = 'Confirm Schedule';
  static MISSING_DATE = 'Missing Date';
  static MATERNITY = 'Maternity';
  static BONDING = 'Bonding';
  static DOWNLOADING_RECEIPT = 'Loading';
  static DATE_INVALID = 'Date is invalid.';
  static LOA_NEW_CLAIM_REQUIRED = 'Loa New Claim Required';
  static BONDING_EXTENSION = 'Bonding Extension';
  static FULL_BONDING_INTAKE = 'Full Bonding Intake';
  static NEW_INTAKE_WORKSCHEDULE = 'newintake workschedule';
  static TRANSITION_TO_BONDING = 'Transition to Bonding';
  static MATERNITY_KEY = '2';
  static NEWINTAKE_OWCS = 'Start_a_Claim_AA_Step_1';
  static PLEASE_SELECT = 'Please Select';
  static REQUIRED = 'Required';
  static ENTER_HOURS = 'Enter paid hours only. Don\'t count unpaid breaks or travel time';
  static PLEASE_ENTER = 'Please Enter';
  static ABSENT_REASON_LIST_API = 'absentReasonListAPI';
  static MATERNITY_CLAIM = 'Maternity Claim';
  static UPDATE = 'Update';
  static SAVE = 'Save';
  static CANCEL = 'Cancel';
  static DELETE = 'Delete';
  static INFORMATION = 'Please provide additional information.';
  static DEFAULT_GB_PHONE_NUMBER = '1-888-301-5615';
  static TOTALHOURSMINUTES = ' 00 hours 00 minutes'
  static TOTALHOURSNUMBERS = ' 00 hours 00 minutes';
  static YESCANCEL = 'Yes, Cancel';
  static MULTIPLECLAIMSCONFIRMATIONPAGE = 'claim ids pop up';
  static TOTALHOURSANDMINUTES = ' 0 hours 0 minutes';
  static CONTACT_INFO_LOA = "contactInfo";
  static HEALTH_SEARCH_LOA = "healthSearchProvider";
  static AYA_LOA = "aboutYourAbsence";
  static HEALTH_PROVIDER_LOA = "healthProvider";
  static WORK_SCHEDULE_LOA = "workSchedule";
  static WORK_SCHEDULE_EDIT_LOA = "workScheduleEdit";
  static CONFIRMATION_LOA = 'confirmation';
  static AYD_LOA = "aboutYourDelivery";
  static ED_LOA = "employmentQuestions";
  static NP_LOA = "notificationPreferences";
  static DATE_PICKER_OPTIONS: IMyDpOptions = {
    dateFormat: 'mm/dd/yyyy',
    selectorHeight: 'auto',
    dayLabels: {su: 'Su', mo: 'Mo', tu: 'Tu', we: 'We', th: 'Th', fr: 'Fr', sa: 'Sa'},
    showTodayBtn: false,
    firstDayOfWeek: 'su',
    monthSelector: false,
    yearSelector: false,
    minYear: 1970,
    showClearDateBtn: false,
    editableDateField: false,
    openSelectorOnInputClick: true,
    sunHighlight: false,
    markCurrentDay: false,
    height: '42px',
    componentDisabled: false,
    disableSince: {year: 0, month: 0, day: 0},
    disableUntil: {year: 0, month: 0, day: 0}
  };
  static DATE_PICKER_INTAKE_OPTIONS: IMyDpOptions = {
    dateFormat: 'mm/dd/yyyy',
    selectorHeight: 'auto',
    dayLabels: {su: 'Su', mo: 'Mo', tu: 'Tu', we: 'We', th: 'Th', fr: 'Fr', sa: 'Sa'},
    showTodayBtn: false,
    firstDayOfWeek: 'su',
    monthSelector: false,
    yearSelector: false,
    minYear: 1970,
    showClearDateBtn: false,
    editableDateField: false,
    openSelectorOnInputClick: true,
    sunHighlight: false,
    markCurrentDay: false,
    height: '42px',
    componentDisabled: false,
    disableSince: {year: 0, month: 0, day: 0},
    disableUntil: {year: 0, month: 0, day: 0}
  };
  static DATE_PICKER_INTAKE_OPTIONS_PAST: IMyDpOptions = {
    dateFormat: 'mm/dd/yyyy',
    selectorHeight: 'auto',
    dayLabels: {su: 'Su', mo: 'Mo', tu: 'Tu', we: 'We', th: 'Th', fr: 'Fr', sa: 'Sa'},
    showTodayBtn: false,
    firstDayOfWeek: 'su',
    monthSelector: false,
    yearSelector: false,
    minYear: 1970,
    showClearDateBtn: false,
    editableDateField: false,
    openSelectorOnInputClick: true,
    sunHighlight: false,
    markCurrentDay: false,
    height: '42px',
    componentDisabled: false,
    disableSince: {year: 0, month: 0, day: 0},
    disableUntil: {year: 0, month: 0, day: 0}
  };
  static DATE_PICKER_ABOUT_OPTIONS: IMyDpOptions = {
    dateFormat: 'mm/dd/yyyy',
    selectorHeight: 'auto',
    dayLabels: {su: 'Su', mo: 'Mo', tu: 'Tu', we: 'We', th: 'Th', fr: 'Fr', sa: 'Sa'},
    showTodayBtn: false,
    firstDayOfWeek: 'su',
    monthSelector: false,
    yearSelector: false,
    minYear: 1970,
    showClearDateBtn: false,
    editableDateField: false,
    openSelectorOnInputClick: true,
    sunHighlight: false,
    markCurrentDay: false,
    height: '42px',
    componentDisabled: false,
    disableSince: {year: 0, month: 0, day: 0},
    disableUntil: {year: 0, month: 0, day: 0}
  };
  static DATE_PICKER_ABOUT_OPTION_PLAN: IMyDpOptions = {
    dateFormat: 'mm/dd/yyyy',
    selectorHeight: 'auto',
    dayLabels: {su: 'Su', mo: 'Mo', tu: 'Tu', we: 'We', th: 'Th', fr: 'Fr', sa: 'Sa'},
    showTodayBtn: false,
    firstDayOfWeek: 'su',
    monthSelector: false,
    yearSelector: false,
    minYear: 1970,
    showClearDateBtn: false,
    editableDateField: false,
    openSelectorOnInputClick: true,
    sunHighlight: false,
    markCurrentDay: false,
    height: '42px',
    componentDisabled: false,
    disableSince: {year: 0, month: 0, day: 0},
    disableUntil: {year: 0, month: 0, day: 0}
  };
  static DATE_PICKER_ABOUT_RTW_PLAN: IMyDpOptions = {
    dateFormat: 'mm/dd/yyyy',
    selectorHeight: 'auto',
    dayLabels: {su: 'Su', mo: 'Mo', tu: 'Tu', we: 'We', th: 'Th', fr: 'Fr', sa: 'Sa'},
    showTodayBtn: false,
    firstDayOfWeek: 'su',
    monthSelector: false,
    yearSelector: false,
    minYear: 1970,
    showClearDateBtn: false,
    editableDateField: false,
    openSelectorOnInputClick: true,
    sunHighlight: false,
    markCurrentDay: false,
    height: '42px',
    componentDisabled: false,
    disableSince: {year: 0, month: 0, day: 0},
    disableUntil: {year: 0, month: 0, day: 0}
  };
  static DATE_PICKER_EE_ESTIMATE: IMyDpOptions = {
    dateFormat: 'mm/dd/yyyy',
    selectorHeight: 'auto',
    dayLabels: {su: 'Su', mo: 'Mo', tu: 'Tu', we: 'We', th: 'Th', fr: 'Fr', sa: 'Sa'},
    showTodayBtn: false,
    firstDayOfWeek: 'su',
    monthSelector: false,
    yearSelector: false,
    minYear: 1970,
    showClearDateBtn: false,
    editableDateField: false,
    openSelectorOnInputClick: true,
    sunHighlight: false,
    markCurrentDay: false,
    height: '42px',
    componentDisabled: false,
    disableSince: {year: 0, month: 0, day: 0},
    disableUntil: {year: 0, month: 0, day: 0}
  };
  static DATE_PICKER_HOSPITAL_ADMISSION: IMyDpOptions = {
    dateFormat: 'mm/dd/yyyy',
    selectorHeight: 'auto',
    dayLabels: {su: 'Su', mo: 'Mo', tu: 'Tu', we: 'We', th: 'Th', fr: 'Fr', sa: 'Sa'},
    showTodayBtn: false,
    firstDayOfWeek: 'su',
    monthSelector: false,
    yearSelector: false,
    minYear: 1970,
    showClearDateBtn: false,
    editableDateField: false,
    openSelectorOnInputClick: true,
    sunHighlight: false,
    markCurrentDay: false,
    height: '42px',
    componentDisabled: false,
    disableSince: {year: 0, month: 0, day: 0},
    disableUntil: {year: 0, month: 0, day: 0}
  };
  static DATE_PICKER_HOSPTIAL_DISCHARGE: IMyDpOptions = {
    dateFormat: 'mm/dd/yyyy',
    selectorHeight: 'auto',
    dayLabels: {su: 'Su', mo: 'Mo', tu: 'Tu', we: 'We', th: 'Th', fr: 'Fr', sa: 'Sa'},
    showTodayBtn: false,
    firstDayOfWeek: 'su',
    monthSelector: false,
    yearSelector: false,
    minYear: 1970,
    showClearDateBtn: false,
    editableDateField: false,
    openSelectorOnInputClick: true,
    sunHighlight: false,
    markCurrentDay: false,
    height: '42px',
    componentDisabled: false,
    disableSince: {year: 0, month: 0, day: 0},
    disableUntil: {year: 0, month: 0, day: 0}
  };
  static MONTH = {
    1: 'January',
    2: 'February',
    3: 'March',
    4: 'April',
    5: 'May',
    6: 'June',
    7: 'July',
    8: 'August',
    9: 'September',
    10: 'October',
    11: 'November',
    12: 'December'
  };
  static INTAKE_START_DATE = 'intakeStartDate';
  static INTAKE_END_DATE = 'intakeEndDate';
  static MAT_ID = 7004;
  static HEALTHCARE_REGISTERED_ID_ARRAY = ['FIRST_NAME', 'LAST_NAME', 'PHONE', 'FAX', 'ADDRESS_ONE' ,
  'ADDRESS_TWO', 'ZIP_CODE', 'CITY', 'LOCATION' , 'SPECIALITY', 'EMAIL'];
  static HEALTHCARE_VALUE_ARRAY = ['firstName', 'lastName', 'telephoneNumber', 'faxNumber', 'address1', 'address2', 'postalCode',
  'city', 'state', 'speciality', 'email'];
  static PROVIDER_NAME = 'provider';
  /**new intake Page id atribute for retreiving owcs content */
  static NEW_INTAKE_PAGEID = 'New Intake';
  static NEW_INTAKE_MOCK_OWCS_USERNAME = 'New_Intake';
  /**contact info mapping attributes */
  static HOME_REGISTERED_ID = '125367';
  static HOME_LOOKUP_ID = '4';
  static MOBILE_LOOKUP_ID = '16';
  static PHONE_NO = 'phoneNo';
  /**title for new intake maternity flow */
  static CLAIM_INTAKE_TITLE = 'Claim Intake';
  static EDIT = 'Edit';
  static ADD = 'Add';
  static CONTACT_US = 'Contact Us.';
  /**For progress bar step count */
  static STEP_VISUALIZATION = '# of #';
  static STEP_ONE = '1';
  static STEP_THREE = '3';
  static STEP_TWO = '2';
  static STEP_FOUR = '4';
  static STEP_FIVE = '5'
  static STEP_SIX = '6';
  static STEP_SEVEN = '7';
  // bonding intake
  static BONDING_KEY = '4';
  static BOND_WITH_A_NEW_CHILD = 'bond with a new child';
  static NEWBORN = 'Newborn';
  static PREGNANCY_CHILDBIRTH = 'Pregnancy/childbirth';
  static NEWBORN_RELATIONSHIP = '3';
  static NEWBORN_NURSING_INFANTS = '84';
  static NEWLY_ADOPTED_CHILD_ID = '6';
  static FOSTER_CARE_ID = '7';
  static DP_NEWBORN_ID = '87';  //DP = Domestic Partner
  static DP_ADOPTION_ID = '88';  //DP = Domestic Partner
  static DP_FOSTER_CARE_ID = '11997';  //DP = Domestic Partner
  static KNOWN_RELATIONSHIP_IDS = [
    IntakeLabelConstants.NEWBORN_RELATIONSHIP,
    IntakeLabelConstants.NEWLY_ADOPTED_CHILD_ID,
    IntakeLabelConstants.FOSTER_CARE_ID,
    IntakeLabelConstants.NEWBORN_NURSING_INFANTS,
    IntakeLabelConstants.DP_NEWBORN_ID,
    IntakeLabelConstants.DP_ADOPTION_ID,
    IntakeLabelConstants.DP_FOSTER_CARE_ID
  ];
  static BONDING_TILE = 'bondingtile';
  static RADIO_TILE_TITLE_BONDING = 'Bonding with ';
  static RADIO_TILE_TITLE_MATERNITY = 'Maternity';
  static RADIO_TILE_DOB = 'Date of Birth:';
  static RADIO_TILE_PLACEMENT_DATE = 'Placement date:';
  static RADIO_TILE_ACTUAL_DELIVERY = 'Delivery Date:';
  static RADIO_TILE_ESTIMATED_DELIVERY = 'Estimated delivery:';
  static RADIO_TILE_REQUESTED_DATE = 'Requested through:';
  static BONDING_CLAIM = 'Bonding Claim';
  // bonding intake
  static TEXT_EMAIL_ALT_TEXT = 'text and email';
  static EMAIL_ONLY_ALT_TEXT = 'email only';
  static PAPERLESS_ALT_TEXT = 'leaf-icon';
  static PAPER_MAIL_ALT_TEXT = 'paper-icon';
  static HTML = 'html';
  static TEXT = 'text';
  // add healthcare registerd id
  static NPI_ID = '619601';
  static PROVIDER_ID = '127';
  static FIRST_NAME = '54';
  static LAST_NAME = '53';
  static PHONE = '55';
  static FAX = '56';
  static ADDRESS_ONE = '1111';
  static ADDRESS_TWO = '1112';
  static ZIP_CODE = '1115';
  static CITY = '1113';
  static LOCATION = '1114';
  static SPECIALITY = '57';
  static EMAIL = '113912';
  static INSURANCE_CARRIER = '111977';
  static OTHER_TEXTAREA = '95243';
  static YES = 'Yes';
  static NO = 'No';
// Edit Contact Info
  static EditContactFirstName = '1491';
  static EditContactMiddleName = '1492';
  static EditContactLastName = '1490';
  static EditContactAddressLine1 = '1015';
  static EditContactAddressLine2 = '1016';
  static EditContactZip = '1020';
  static EditContactCity = '1018';
  static EditContactState = '1019';
  static EditContactSuffix = '95542';
  // add bonding registered id
  static FDA_QUESTION = '8';
  static MULTIPLE_RELATIONSHIPS = '21';
  static MULTIPLE_SECONDARY_RELATIONSHIPS = '111894';
  static UNKNOWN_FDA = '42';
  static UNKNOW_FDA_VALUE = 'Unknown';
  static FDA_REGISTER_ID = '43';
  // absence section registered id
  static ABSENCE_SECTION_REG_ID = '129'
  static ABSENCE_SECTION_DATA = 'absenceSectionData';
  static ABSENCE_SECTION_OVERTIME_REG_ID = '620333'
  static ABSENCE_SECTION_OVERTIME_DATA = 'absenceSectionOverTimeData';
  /**new intake extension Page id atribute for retreiving owcs content */
  static INTAKE_EXTENSION_PAGEID = 'Intake Extension';
  static INTAKE_EXTENSION_MOCK_OWCS_USERNAME = 'Intake_Extension';
  //about your absence reg ids
  static LastDayWorked = '49';
  static LastDayWorkedEstimatedFDA = '111576';
  static WorkAnyTimeEstimatedFDA = '175';
  static HoursMinutesWorked = '619302';
  static ContinueWorkPartialDays = '610434';
  static EstimatedRTWdate = '67';
  static AbsenceDayType = '175';
  static AbsenceDayLookUp = '125002';
  static GapReason = '605274';
  static RTWUnknown = '114704';
  static AdditionalBenefitsNone = '83161';
  static OtherTextArea = '608734';
  static CatastropheIndicator = '605953';
  static PhysicalDemand = '124682';
  static Sedentary = 'Sedentary';
  static LastDayYes = '1001'
  static LastDayNo = '1002'
  //about your delivery iam still pregnant reg ids
  static Delivered = '1668';
  static ExpectedDueDate = '70';
  static DUEDATE = '71';
  static Complication = '128104';
  static Complicationdetails = '128106';
  static HighRiskPregnancy = '73';
  static RisksFreeFormText = '125663';
  static MoreThanOneBaby = '608393';
  static HospitalDelivery = '27';
  static HospitalAdmission = '600293';
  static HospitalName = '29';
  static AdmissionDate = '33';
  static DischargeDate = '34';
  static YesLookupId = '2001';
  static NoLookupId = '2002';
  static UnknownLookupId = '2003';
  static HourlyLookupId = '767500';
  static SalaryLookupId = '767501';
  static FTLookupId = '192101';
  static PTLookupId = '192102';
  static IcdDetail = '600333';
  static CptDetail = '600334';
  static CPTDropdown = '608394';
  static isDeliveredTrue = 1001;
  static isDeliveredFalse = 1002;
  static expediteIndicator = '600354';
  static LEAVE_CONTINUOUS_LOOKUP = 767802;
  static ComplicationsICD = '608393';
  static ICDDiabetes = '620221';
  static CPTVaginal = '620222';
  static IcdDetailsFailureValue = '0|2500074|T14.90|INJURY, UNSPECIFIED|P|nill|nill|nill|ICD10DX|false|0#';
  static ComplicationsICDFailureValue = 'T14.90';
  static EmptyString = "";


 //Notification Preferences reg Id
  static EmailAddressNotification = '1512';
  static NotificationDiscussed = '614655';
  static NotificationDiscussedLookupId= '1001';

  static NotificationMobileNumber = '125843';

  static NotificationQuestion = '125425';
  static TextEmailLookupID = '1001';
  static OnlyEmailLookupID = '1002';

  static ClaimDoc = '125424';
  static paperLessLookupId = '1001';
  static paperMailLookupId = '1002';

  static leaveContinuityRegID = 125542;
  static leaveContinuityRegVal = '17001';
  static pregnancyHighRiskRegID = '73';
  static pregnancyHighRiskRegVal = '1002';
  static yesRegisterVal = '1001';
  static noRegisterVal = '1002';
//employement questions reg field ids
  static pdRegID = '124682';
  static pdNARegVal = 'Sedentary';
  static pdQ1RegID = '124662';
  static pdSQ1SQ2RegID = '607633';
  static pdQ4RegID = '607655';
  static pdQ5RegID = '607693';


  //confirmation page serial numbers
  static One = 1;
  static Two = 2;
  static Three = 3;
  static Four = 4;
  static MULTI_CLAIM_NO = 5;


  // ICD Codes
  //still pregnant
  static NO_COMPLICATIONS_SINGLE_CHILD = 'O80';
  static NO_COMPLICATIONS_MULTIPLE_CHILDREN = 'O30.90';

  //ive delivered
  static VAGINAL = 'O80';
  static C_SECTION = 'O82';

  //CPT
  static VAGINAL_CPT = '59410';
  static C_SECTION_CPT = '59514';

  static VAGINAL_LOOKUP = '14001';
  static C_SECTION_LOOKUP = '14002';

  static DELIVERY_METHOD = '72';

  //continuity code
  static CONTINUOUS_CONTINUITY = 17001;
  static INTERMITTENT_CONTINUITY = 17002;
  static REDUCED_SCHEDULE_CONTINUITY = 17003;
  static OTHER_CONDITION_REGISTER_ID = '606173';
  static COMORBID_TEXT = 'Other';
  static OTHER_TEXT_REGISTER_ID = '606175'
  static OTHER_CONDITION_TEXT = 'Online Maternity';
  static MULTI_CLAIM_MODAL = 'multiClaimModal';
  static APPOINTMENT =  'Appointment';
  static FLARE_UP = 'Flare-up';


   //Employment Details
   static ADDITIONAL_EMPLOYMENT = 'additionalEmployment';

   //Claim Submission Details
   static MEDICAL_LEAVE_INSURANCE = 'Medical Leave Insurance';
   static CLAIM_TYPES_WITH_LABEL = {
      STDClaim: 'Maternity',
      LOAClaim: 'Leave of Absence',
      STDCore: 'Maternity',
      STDBuyup: 'Maternity Buy Up',
      STATClaim: 'State-Mandated Disability / Maternity',
      MMLClaim: 'Maternitys'
   }

  static DYNAMIC_DAY_AND_DATE_LABEL = '[DAY, MM/DD/YYYY dynamic date]';
  static ESTIMATE_FDA_YES = 'SANLS1_17D_Body';
  static ESTIMATE_FDA_NO = 'SANLS1_17E_Body';
  static DAY_AND_DATE_LABEL = '[day, MM/DD/YY]';
  static DYNAMIC_VALUE = '[dynamic value dynamic value]';
  static SELECTED_LEAVE_ID = 'XXXXXXX';
  static UNKNOWN ='Unknown';
  static DYNAMIC_DAY_AND_DATE_LABEL_CONFIRM = '[DAY MM/DD/YYYY dynamic date]';
  static DYNAMIC_DAY_AND_DATE_LABEL_EDIT = '[Dynamic Day of Week], [Dynamic Date MM/DD/YY]';
  static DYNAMIC_DAY_DYNAMIC_DATE = '[Dynamic Day, Dynamic Date MM/DD/YY]';
  static DYNAMIC_LATE_REPORTED_VALUES = '[dynamic late reported values (numeric and business/calendar)]';
  static DYNAMIC_UNKNOWN_FDA_VALUE = '[Dynamic Date, MM/DD/YY]';
  static DAY_AND_DATE_LABEL_LRM = '{dynamic day of week, dynamic date MM/DD/YY}';
  static DYNAMIC_DATE_WITH_COMMA = '[Dynamic Date, MM/DD/YYYY]';
  static DYNAMIC_DATE_WITHOUT_COMMA = '[Dynamic Date MM/DD/YY]';
  static DYNAMIC_DATE_DEFAULTED = '[Dynamic Date MM/DD/YY] (defaulted)';
}
