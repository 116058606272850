import {Component, Input, OnInit, ViewChild} from '@angular/core';
import {Router} from '@angular/router';
import {FormBuilder, FormGroup} from '@angular/forms';
import {ModalService} from '../../../shared/services/modal.service';
import {ConfirmSurgeryServiceService} from '../../../shared/services/confirm-surgery-service.service';
import {StorageManagementService} from '../../../shared/services/storage-management.service';
import {ContentService} from '../../../shared/services/content.service';
import {CustomEventService} from '../../../shared/services/tealium/custom-event.service';
import {SecondaryDateDropdownComponent} from '../../common/molecules/secondary-date-dropdown/secondary-date-dropdown.component';
import {AetnaConfirmSurgeryServiceService} from '../../../shared/services/aetna-confirm-surgery-service';
import {AppConstants} from '../../../shared/constants/app.constants';
import {AppLabelConstants} from '../../../shared/constants/appLabel.constants';
import {SessionConstants} from '../../../shared/constants/session.constants';
import {ErrorConstants} from '../../../shared/constants/error.constants';


@Component({
  selector: 'app-disablity-last-day-section',
  templateUrl: 'disablity-last-day-section.component.html',
  styleUrls: ['disablity-last-day-section.component.scss']
})
export class DisablityLastDaySectionComponent implements OnInit {
  @Input() newDisabilityDateValue: string;
  @ViewChild(SecondaryDateDropdownComponent, {static: false}) DateLabelValue: SecondaryDateDropdownComponent;
  public appLabelConstants = AppLabelConstants;
  public showDiv = 0;
   welcomeMessage = AppLabelConstants.CONFIRM_DIS_DETAILS;
   showLDWDateError = true;
  public lastDayWorkedForm: FormGroup;
   month;
   year;
   hours;
   showHoursError = true;
   claimView;
   claimDetail;
   navTO;
   updateDisablityRequest;
   aetnaUpdateDisablityRequest;
   claimEventIdForDisability;
  public claimESDForDisability;
   pageId: string;
   owscContent;
  public isLDWGreater = true;
   isLDWGreaterCheck = true;
  public paragraph_1 = '';
  public qestion_1 = '';
  public question_2 = '';
   isDisabilityLDW;
  public disabilityLastDayFormValue;
   LWM;
   LWD;
   LWY;
   LWDDate;
   readExitingdate;
   claimDisabilityDate;
  public setDefalutvalue = true;
  public claimStatusDisability;
   incerementOperatorval = 0;
   displayError: number;
  public personId;
  public srcSysCd;
  public currentClaim;
  public Day = 'DAY';

  constructor(private router: Router,
              private _formBuilder: FormBuilder, private modalService: ModalService,
              private _updateclaimactivity: ConfirmSurgeryServiceService,
              private storageMgmtService: StorageManagementService,
              private _aetnaupdateclaimactivity: AetnaConfirmSurgeryServiceService,
              private contentService: ContentService,
              private customEventService: CustomEventService) {
  }

  ngOnInit() {
    sessionStorage.removeItem(SessionConstants.GET_FIRST_VAL);
    //OWCS service Integration
    this.pageId = this.contentService.getPageId(AppConstants.CONFIRM_DISABILITY_DETAILS);
    if (this.pageId) {
      this.contentService.getOWCSResponse(this.pageId, AppConstants.CONFIRM_DISABILITY_DETAILS_U).subscribe(
        val => {
          if (val) {
            this.owscContent = val;
            if (this.owscContent && this.owscContent.data) {
              if (this.owscContent.data.CDD_03_Body) {
                this.paragraph_1 = this.owscContent.data.CDD_03_Body.value;
              }
              if (this.owscContent.data.CDD_04_Label) {
                this.qestion_1 = this.owscContent.data.CDD_04_Label.value;
              }
              if (this.owscContent.data.CDD_08_Label) {
                this.question_2 = this.owscContent.data.CDD_08_Label.value;
              }
            }
          }
        });
    }
    this.claimView = sessionStorage.getItem(SessionConstants.CLAIM_VIEW);
    this.claimDetail = sessionStorage.getItem(SessionConstants.CLAIMDETAILS);
    if (JSON.parse(this.storageMgmtService.retrieveSessionObject(SessionConstants.CUR_CLAIM)).curClaim) {
      this.currentClaim = JSON.parse(this.storageMgmtService.retrieveSessionObject(SessionConstants.CUR_CLAIM)).curClaim;
    }
    this.claimEventIdForDisability = this.currentClaim.claimEventId;
    this.claimDisabilityDate = this.currentClaim.disabilityDate;
    this.claimESDForDisability = this.currentClaim.disabilityDate;
    this.claimStatusDisability = this.currentClaim.claimStatus;
    this.personId = this.currentClaim.personId;
    this.srcSysCd = this.currentClaim.sourceSystemId;
    this.isDisabilityLDW = sessionStorage.getItem(SessionConstants.IS_DIS_LDW);
    this.hoursWLD();
    this.lastDayWorkedForm = this._formBuilder.group({
      hoursWorked: ['Hours'],
    })
  }

  tagErrors(elementId, errorMsg) {
    if (errorMsg) {
      this.customEventService.formFieldValidationErrors(['' + elementId], ['' + errorMsg]);
    }
  }

  // Date from Component
  getDateFromComponent() {
    const i = 0;
    this.incerementOperatorval++;
    const getFirstValue = sessionStorage.getItem(SessionConstants.GET_FIRST_VAL);
    if (getFirstValue) {
    this.DateLabelValue.getDate();
    if (this.disabilityLastDayFormValue) {
      this.LWD = this.disabilityLastDayFormValue.day;
    }
    const date  = new Date(this.LWDDate);
    if (this.LWD.toUpperCase() === this.Day) {
      this.isLDWGreater = true;
    }
    this.getDefaultValue();
    this.DateLabelValue.getDate();
    this.LWM = this.disabilityLastDayFormValue.month;
    this.LWD = this.disabilityLastDayFormValue.day;
    this.LWY = this.disabilityLastDayFormValue.year;
     /* if (this.newDisabilityDateValue) {
        this.claimESDForDisability = this.newDisabilityDateValue;
      } else {
        this.claimESDForDisability = this.claimESDForDisability;
      }*/

    const newDisabilityServiceValue = new Date(this.claimESDForDisability);
    const LWDDate = this.LWM + '/' + this.LWD + '/' + this.LWY;
    const LDWDatevalue = new Date(LWDDate);
    const newDisabilityDateValue = new Date(this.newDisabilityDateValue);

    if (this.LWD.toUpperCase() !== this.Day && LDWDatevalue > newDisabilityServiceValue && this.isDisabilityLDW !== AppConstants.IS_DIS_LDW) {
      this.isLDWGreater = false;
      this.setDefalutvalue = this.isLDWGreater;
      this.tagErrors(ErrorConstants.LAST_DAY_OF_WPRK, ErrorConstants.LAST_DAY_WORK_MUST_BE_ON_OR_BEFORE);
     } else if (LDWDatevalue > newDisabilityDateValue) {
      this.isLDWGreater = false;
      this.setDefalutvalue = this.isLDWGreater;
      this.tagErrors(ErrorConstants.LAST_DAY_OF_WPRK, ErrorConstants.LAST_DAY_WORK_MUST_BE_ON_OR_BEFORE);
    } else {
      this.isLDWGreater = true;
      //this.setDefalutvalue = this.isLDWGreater;
    }

    }
  }
  getDefaultValue() {
    const i = 0;
    this.incerementOperatorval++;
    const getFirstValue = sessionStorage.getItem(SessionConstants.GET_FIRST_VAL);

    if ((this.incerementOperatorval >= 2 && getFirstValue) || this.displayError === 1) {
    this.DateLabelValue.getDate();
    if (this.disabilityLastDayFormValue === undefined || this.disabilityLastDayFormValue && this.disabilityLastDayFormValue.day.toUpperCase() === this.Day) {
      this.setDefalutvalue = false;
      this.tagErrors(ErrorConstants.LAST_DAY_OF_WPRK, ErrorConstants.ENTER_VALID_DATE);
    } else {
      this.setDefalutvalue = this.isLDWGreater;
    }
   }
  }

  btnNextClicked() {
    if (this.displayError !== 2) {
      this.displayError = 1;
    }
    this.DateLabelValue.getDate();
    this.getDefaultValue();
    this.showDiv++;
    if (this.disabilityLastDayFormValue) {
      this.LWM = this.disabilityLastDayFormValue.month;
      this.LWD = this.disabilityLastDayFormValue.day;
      this.LWY = this.disabilityLastDayFormValue.year;
    const LWDDate = this.LWM + '/' + this.LWD + '/' + this.LWY;

    if (this.newDisabilityDateValue) {
      this.claimESDForDisability = this.newDisabilityDateValue;
    } else {
      this.claimESDForDisability = this.claimESDForDisability;
    }

     // this.readExitingdate = this.newDisabilityDateValue || this.claimESDForDisability;
      const newDisabilityDateValue = new Date(this.newDisabilityDateValue);
    this.claimESDForDisability = new Date(this.claimESDForDisability);

    const LDWDatevalue = new Date(LWDDate);
    if (this.LWD.toUpperCase() === this.Day) {
      this.showLDWDateError = false;
      this.showDiv--;
    }

    if (this.isDisabilityLDW !== AppConstants.IS_DIS_LDW) {
      if (this.LWD.toUpperCase() !== this.Day && LDWDatevalue <= this.claimESDForDisability) {
        this.hoursWLD();
        this.isLDWGreater = true;
      } else if (this.LWD.toUpperCase() !== this.Day) {
        this.showDiv--;
        this.isLDWGreater = false;
        this.setDefalutvalue = this.isLDWGreater;
        //this.setDefalutvalue = true;
        this.tagErrors(ErrorConstants.LAST_DAY_OF_WPRK, ErrorConstants.LAST_DAY_WORK_MUST_BE_ON_OR_BEFORE);
      }
    } else if (this.LWD.toUpperCase() !== this.Day && LDWDatevalue <= newDisabilityDateValue) {
      this.hoursWLD();
      this.isLDWGreater = true;
    } else if (this.LWD.toUpperCase() !== this.Day) {
      this.showDiv--;
      this.isLDWGreater = false;
      this.setDefalutvalue = this.isLDWGreater;
      this.tagErrors(ErrorConstants.LAST_DAY_OF_WPRK, ErrorConstants.LAST_DAY_WORK_MUST_BE_ON_OR_BEFORE);
    }
  } else {
      this.showDiv--;
    }
  }


  hoursWLD() {
    this.hours = [];
    for (let i = 1; i <= 24; i++) {
      this.hours.push(i);
    }
    return this.hours;
  }

  onHourChange() {
    if (this.lastDayWorkedForm.value.hoursWorked === AppConstants.HOURS) {
      this.showHoursError = false;
      this.tagErrors(ErrorConstants.HOURS_WORKED, ErrorConstants.PLEASE_ENTER_UR_HRS_WRKD);
    } else {
      this.showHoursError = true;
    }
  }

  submitForm() {
    const LWM = (this.disabilityLastDayFormValue.month && this.disabilityLastDayFormValue.month.length === 1) ? '0' + this.disabilityLastDayFormValue.month : this.disabilityLastDayFormValue.month;
    const LWD = (this.disabilityLastDayFormValue.day && this.disabilityLastDayFormValue.day.length === 1) ? '0' + this.disabilityLastDayFormValue.day : this.disabilityLastDayFormValue.day;
    const LWY = this.disabilityLastDayFormValue.year;
    const LWDDate = LWM + '/' + LWD + '/' + LWY;
    const newDisabilityDateValue = new Date(this.newDisabilityDateValue);
    this.claimESDForDisability = new Date(this.claimESDForDisability);
    const LDWDatevalue = new Date(LWDDate);
    if (LWD.toUpperCase() === this.Day) {
     this.showLDWDateError = false;
      this.isLDWGreater = true;
    } else if (LWD.toUpperCase() !== this.Day) {
      this.isLDWGreater = false;
      this.showLDWDateError = true;
    }
    if (this.lastDayWorkedForm.value.hoursWorked === AppConstants.HOURS) {
      this.showHoursError = false;
      this.tagErrors(ErrorConstants.HOURS_WORKED, ErrorConstants.PLEASE_ENTER_UR_HRS_WRKD);
    }

    if (this.isDisabilityLDW !== AppConstants.IS_DIS_LDW) {
      if (this.claimESDForDisability >= LDWDatevalue) {
        this.isLDWGreaterCheck = true;
        this.isLDWGreater = true;
      } else {
        this.isLDWGreaterCheck = false;
        this.isLDWGreater = false;
        this.tagErrors(ErrorConstants.LAST_DAY_OF_WPRK, ErrorConstants.LAST_DAY_WORK_MUST_BE_ON_OR_BEFORE);
      }
    } else if (newDisabilityDateValue >= LDWDatevalue) {
      this.isLDWGreaterCheck = true;
      this.isLDWGreater = true;
    } else {
      this.isLDWGreaterCheck = false;
      this.isLDWGreater = false;
      this.tagErrors(ErrorConstants.LAST_DAY_OF_WPRK, ErrorConstants.LAST_DAY_WORK_MUST_BE_ON_OR_BEFORE);
    }


    if (this.showLDWDateError === true && this.showHoursError === true && this.isLDWGreaterCheck === true) {
      if (this.newDisabilityDateValue) {
        this.claimDisabilityDate = this.newDisabilityDateValue;
      }

      if (this.isDisabilityLDW === AppConstants.IS_DIS_LDW) {
        this.updateDisablityRequest = {
          claimEventId: this.claimEventIdForDisability,
          lastDayWorkedDt: LWDDate,
          cnfrmedDisabilityDt: this.claimDisabilityDate,
          lastDayHourWorked : this.lastDayWorkedForm.value.hoursWorked,
          expDisabilityDtChangeFlg: false,
          claimStatus : this.claimStatusDisability
        }

        this.aetnaUpdateDisablityRequest = {
          personId: this.personId,
          claimId: this.claimEventIdForDisability,
          lastDayWorkedDt: LWDDate,
          lastDayHourWorked : this.lastDayWorkedForm.value.hoursWorked,
          cnfrmedDisabilityDt : this.claimDisabilityDate
        }
      } else {
        this.updateDisablityRequest = {
          claimEventId: this.claimEventIdForDisability,
          lastDayWorkedDt: LWDDate,
          cnfrmedDisabilityDt: this.claimDisabilityDate,
          lastDayHourWorked : this.lastDayWorkedForm.value.hoursWorked,
          expDisabilityDtChangeFlg: false,
          newExpDisabilityDt : this.newDisabilityDateValue,
          claimStatus : this.claimStatusDisability
        }

        this.aetnaUpdateDisablityRequest = {
          personId: this.personId,
          claimId: this.claimEventIdForDisability,
          lastDayWorkedDt: LWDDate,
          lastDayHourWorked : this.lastDayWorkedForm.value.hoursWorked,
        }
      }

      if (this.srcSysCd === AppConstants.SOURCE_SYS_ID_DCS) {
        this._updateclaimactivity.upDateClaimActivity(this.updateDisablityRequest).subscribe(updateDisablityRes => {
          if (updateDisablityRes && updateDisablityRes.statusCode === AppConstants.STATUS_CD_0_STR) {
            this.storageMgmtService.setAppData(SessionConstants.RELOADCLAIMS , true);
          }
        }, error => {
          //console.error("Please Try after Some time");
        });
      } else if (this.srcSysCd === AppConstants.SOURCE_SYS_ID_AA) {
        this._aetnaupdateclaimactivity.aetnaUpDateClaimActivity(this.aetnaUpdateDisablityRequest, AppConstants.DISABILITY).subscribe(updateDisabilityRes => {
          if (updateDisabilityRes && updateDisabilityRes.statusCode === AppConstants.STATUS_CD_0) {
            //console.info("Success");
            this.storageMgmtService.setAppData(SessionConstants.RELOADCLAIMS , true);
          }
        }, error => {
          //console.error("Please Try after Some time");
        });
      }

      this.router.navigate([AppLabelConstants.URL_DIS_THANK_U]);
    }
  }
  updateDisabilityLastDayFormValue(event) {
    this.disabilityLastDayFormValue = event;
  }
  onclickCancel() {
    this.modalService.openModal(AppConstants.CANCEL_MODAL);
  }
}
