import {Component, Input, OnInit} from '@angular/core';
import {AppLabelConstants} from '../../../../../shared/constants/appLabel.constants';

@Component({
  selector: 'primary-link-transparent',
  templateUrl: 'primary-link-transparent.component.html',
  styleUrls: ['primary-link-transparent.component.scss']
})
export class PrimaryLinkTransparentComponent implements OnInit {

  @Input() linkName: string;
  @Input() faIcon: string;
  @Input() linkLocation: string;
  @Input() linkId: number;
  @Input() elementId: string;
  @Input() sizeRegular: string;
  @Input() profile_icon: string;
  public appLabelConstants = AppLabelConstants;

  constructor() {}
  ngOnInit() {}
}
