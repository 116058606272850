import { Injectable } from '@angular/core';
import { Resolve } from '@angular/router';
import { parallel } from 'async';
import { switchMap, filter, takeUntil } from 'rxjs/operators';
import { of, Subject } from 'rxjs';
import { IntakeSessionConstants } from '@app/cxr/modules/intake/shared/constants/intakeSession.constants';
import { IntakeLabelConstants } from '@app/cxr/modules/intake/shared/constants/intake-label.constants';
import { SessionConstants } from '@app/shared/constants/session.constants';
import { StorageManagementService } from '@app/shared/services/storage-management.service';
import { ContentService } from '@app/shared/services/content.service';

@Injectable({
  providedIn: 'root'
})
export class NewIntakeMaternityResolverService implements Resolve<any> {
  private _destroy$: Subject<void> = new Subject();
  pageId;
  newIntakeOwcsContent;
  constructor( private storageManagementService: StorageManagementService , private contentService: ContentService) {
    this.gettingOWCSContent();
   }
  gettingOWCSContent() {
    this.pageId = this.contentService.getPageId(IntakeLabelConstants.NEW_INTAKE_PAGEID);
    this.newIntakeOwcsContent = this.storageManagementService.getSessionData(IntakeSessionConstants.NI_OWCS_CONTENT);
    if (!this.newIntakeOwcsContent) {
       if (!this.pageId) {
        this.contentService.getSiteMap().pipe(
          filter(siteMapData => !!siteMapData),
          takeUntil(this._destroy$),
          switchMap(siteMapResponse => {
            if (siteMapResponse) {
              this.storageManagementService.setSessionData(SessionConstants.SITE_MAP_DATA, siteMapResponse);
              this.pageId = this.contentService.getPageId(IntakeLabelConstants.NEW_INTAKE_PAGEID);
              return this.contentService.getOWCSResponse(this.pageId)
            } else {
              return of()
            }
          }
        )).subscribe(
          contentResponse => {
           this.storageManagementService.setSessionData(IntakeSessionConstants.NI_OWCS_CONTENT, contentResponse);
          });
      }
    }
  }
  resolve() {
    const observer = new Subject<any>();
  if (!this.newIntakeOwcsContent) {
    parallel({
      intakeOwcsContentData: (data) => {
        this.contentService.getOWCSResponse(this.pageId, IntakeLabelConstants.NEW_INTAKE_MOCK_OWCS_USERNAME).pipe( filter(contentResponse => !!contentResponse),
        takeUntil(this._destroy$)).subscribe(
        contentResponse => {
          data(null, contentResponse);
          this.storageManagementService.setSessionData(IntakeSessionConstants.NI_OWCS_CONTENT, contentResponse);
      });
      }
    }, (err: string, results: any) => {
      observer.next(results);
      observer.complete();
    });
   return observer.asObservable();

    }
  }
}
