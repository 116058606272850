import {AfterViewInit, Component, OnInit} from '@angular/core';
import {Router} from '@angular/router';
import {LeaveDynatraceAction} from '../../shared/utils/leaveDynatraceAction';
import {StorageManagementService} from '../../shared/services/storage-management.service';
import {ContentService} from '../../shared/services/content.service';
import {AppLabelConstants} from '../../shared/constants/appLabel.constants';
import {SessionConstants} from '../../shared/constants/session.constants';
import {AppConstants} from '../../shared/constants/app.constants';

@Component({
  selector: 'error-view',
  templateUrl: 'error.component.html',
  styleUrls: ['error.component.scss']
})

export class ErrorComponent extends LeaveDynatraceAction implements OnInit, AfterViewInit {
  public appLabelConstants = AppLabelConstants;
  public image: string;
  public title: string;
  public description: string;
  public buttonText: string;

  private pageId: string;
  private OWCSResponse;
  private isLoggedIn = false;

  constructor(
    private contentService: ContentService,
    private storageManagementService: StorageManagementService,
    private router: Router
  ) {
    super(storageManagementService)
  }


  ngOnInit() {
    const loginStateJSON = JSON.parse(this.storageManagementService.retrieveSessionObject(SessionConstants.LOGIN_STATE));
    this.isLoggedIn = (loginStateJSON) ? loginStateJSON.loginState : false;
    this.integrateOWCS();
  }

  ngAfterViewInit() {
    super.ngAfterViewInit();
  }

  integrateOWCS() {
    this.pageId = this.contentService.getPageId(AppConstants.PAGE_NOT_FOUND);
    if(this.pageId) {
      this.contentService.getOWCSResponse(this.pageId, AppConstants.PAGE_NOT_FOUND).subscribe(
        res => {
          this.OWCSResponse = res;
          this.initElements(true);
        }, err => {
          this.initElements(false);
        }
      )
    }
  }

  initElements( owcsOnline = false ) {
    this.title = ( owcsOnline ) ? this.OWCSResponse.data['PageNotFound_02_Body'].value : AppLabelConstants.WE_CANT_FIND_D_PAGE_UR_LOOKIN_FOR;
    this.description = ( owcsOnline ) ?  this.OWCSResponse.data['PageNotFound_03_Body'].value : AppLabelConstants.WE_R_SORRY_FOR_INCONVENIENCE;
    this.buttonText = ( owcsOnline ) ? this.OWCSResponse.data['PageNotFound_04_Body'].value : AppLabelConstants.RETURN_TO_HOME_PG_MSG;
  }

  returnHome() {
    const path = ( this.isLoggedIn ) ? AppLabelConstants.URL_HOME : AppLabelConstants.URL_LOGIN;
    this.router.navigate([path]);
  }
}
