export const MOCK_THAAADAINTERMITTENTREQUESTDETAILVIEW = {
    ADARequestDetailsList: [
        {
            requestID: '3055926',
            startDate: '9/20/2022',
            endDate: '9/20/2022',
            requestStatus: 'Pending Accommodation Certification',
            requestSubStatus: 'Not at Work - Awaiting Certification'
        },
        {
            requestID: '3038519',
            startDate: '9/15/2022',
            endDate: '9/15/2022',
            requestStatus: 'Pending Accommodation Certification',
            requestSubStatus: 'Not at Work - Awaiting Certification'
        },
        {
            requestID: '2962080',
            startDate: '7/18/2022',
            endDate: '7/18/2022',
            requestStatus: 'Pending Accommodation Certification',
            requestSubStatus: 'Not at Work - Awaiting Certification'
        },
        {
            requestID: '2943245',
            startDate: '6/24/2022',
            endDate: '6/24/2022',
            requestStatus: 'Accommodated',
            requestSubStatus: ''
        },
        {
            requestID: '3038320',
            startDate: '12/12/2022',
            endDate: '12/12/2022',
            requestStatus: 'Pending Accommodation Certification',
            requestSubStatus: 'Not at Work - Awaiting Certification'
        },
        {
            requestID: '3038320',
            startDate: '12/12/2022',
            endDate: '12/12/2022',
            requestStatus: 'Pending Accommodation Certification',
            requestSubStatus: 'Not at Work - Awaiting Certification'
        },
        {
            requestID: '3038320',
            startDate: '12/02/2022',
            endDate: '12/12/2022',
            requestStatus: 'Pending Accommodation Certification',
            requestSubStatus: 'Not at Work - Awaiting Certification'
        },
        {
            requestID: '3038320',
            startDate: '12/11/2022',
            endDate: '12/12/2022',
            requestStatus: 'Pending Accommodation Certification',
            requestSubStatus: 'Not at Work - Awaiting Certification'
        }

        ],
        responseCode: '001',
        responseReason: 'success'
      }
