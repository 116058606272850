import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormGroup } from '@angular/forms';
import {IDropDownOptions} from './select-dropdown.model';

@Component({
  selector: 'select-dropdown',
  templateUrl: './select-dropdown.component.html',
  styleUrls: ['./select-dropdown.component.scss']
})
export class SelectDropdownComponent implements OnInit {
  @Input() dropdownList: IDropDownOptions[];
  @Input() dropdownOpen: boolean;
  @Output() dropdownIndex = new EventEmitter<string>();
  @Input() inputString: string;
  @Input() inputPlaceholder: string = 'Please Select';
  @Input() inputType: string;
  @Input() inputId: string;
  @Input() maxlength: string;
  @Input() minlength: string;
  @Input() labelName: string;
  @Input() disabledDropdown = false;
  @Input() readOnlyCondition: boolean;
  @Input() ariaLabel: string;
  @Input() selectOneDisplay: boolean = true;
  @Input() selectedOption: string;
  @Output() dropDownOptionEmit = new EventEmitter<object>();
  @Input() customErrorMssg?: boolean = false;
  @Input() formControlName?: string;
  @Input() formName?: FormGroup = new FormGroup({});
  error: boolean = false;

  constructor() { }

  ngOnInit() {
  }

  onChange(value, list) {
    const dropDownList = {
      optionText : 'string',
      optionId : 'string'
    };
    list.forEach((selectedOption) => {
      if (selectedOption.value.toString() === value) {
        dropDownList.optionText = selectedOption.displayText;
        dropDownList.optionId = selectedOption.value;
        this.dropDownOptionEmit.emit(dropDownList);
      }
    });
    if (this.customErrorMssg) {
      if ( this.formName && this.formName && this.formName?.controls[this.formControlName]?.touched && this.formName.controls[this.formControlName]?.value !== '') {
        this.error = false;
     }
    }
  }
  checkError(): void {
    if (this.customErrorMssg && this.formName ) {
      this.formName.controls[this.formControlName]?.markAsTouched();
    if (this.formName &&  this.formName.controls[this.formControlName]?.touched && this.formName.controls[this.formControlName]?.errors || this.formName.controls[this.formControlName]?.value === undefined) {
          this.error = true;
       } else {
         this.error = false;
      }
    }
  }
}
