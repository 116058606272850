import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { IRadioTileOption } from './radio-tile.model';

@Component({
  selector: 'radio-tile',
  templateUrl: 'radio-tile.component.html',
  styleUrls: ['radio-tile.component.scss']
})
export class RadioTileComponent implements OnInit {
  @Input() height?: string = 'auto';
  @Input() radioGroupName: string;
  @Input() radioGroupLabel: string;
  @Input() taggingEventId: string;
  @Input() taggingEventValue: string;
  @Output() selectedTileValue: EventEmitter<IRadioTileOption> =  new EventEmitter<IRadioTileOption>();
  @Input() set radioOptions(data: IRadioTileOption[]) {
    if (data) {
      this._radioOptions = data;
    }
  }
  _radioOptions: IRadioTileOption[];

  constructor() {}

  ngOnInit(): void {}

  selectRadioTile(event: Event, index: number, isDisabled: boolean): void {
    event.preventDefault();
    if (!isDisabled) {
      this._radioOptions.forEach((option) => {
        option.checked = false;
      });
      this._radioOptions[index].checked = !this._radioOptions[index].checked;
      this.selectedTileValue.emit(this._radioOptions[index]);
    }
  }
}
