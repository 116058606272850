import {AfterViewInit, Component, OnInit, Input} from '@angular/core';
import {LeaveDynatraceAction} from '../../shared/utils/leaveDynatraceAction';
import {StorageManagementService} from '../../shared/services/storage-management.service';
import {ClaimServiceService} from '../../shared/services/claim-service.service';
import {ModalService} from '../../shared/services/modal.service';
import {ActivatedRoute, Router} from '@angular/router';
import {
  DoctorMissingDocument,
  DownloadLink,
  EmployeeMissingDocument,
  EmployerMissingDocument,
  MissingInfo,
  MissingInfoDoc,
  MissingInfoFromAll, MissingInfoFromAllThaa,
  MissingItem,
  MissingReqInfo,
  OWCSMissingItemsResponse,
  Physician,
  Version
} from '../../shared/models/missing-info.model';
import {RelatedClaims} from '../../shared/models/relatedClaims.model';
import {OWCSCaasService} from '../../shared/services/owcs-caas.service';
import {CustomEventService} from '../../shared/services/tealium/custom-event.service'
import {env} from '../../../environments/environment-loader';
import {ContentService} from '../../shared/services/content.service';
import {ContactsService} from '../../shared/services/contacts.service';
import {AetnaContactsService} from '../../shared/services/aetnaContacts.service';
import {AppLabelConstants} from '../../shared/constants/appLabel.constants';
import {SessionConstants} from '../../shared/constants/session.constants';
import {AppConstants} from '../../shared/constants/app.constants';
import {DateLabelValueComponent} from '../common/organisms/datelabel-datevalue/date-label-value/date-label-value.component';

@Component({
  selector: 'app-leave-missing-information',
  templateUrl: './leave-missing-information.component.html',
  styleUrls: ['./leave-missing-information.component.scss']
})
export class LeaveMissingInformationComponent implements OnInit {
   missingItems: MissingItem[] = [];
   missingInfoFromAll: MissingInfoFromAll;
   missingInfoFromAllThaa: MissingInfoFromAllThaa;
   missingDocumentByEmployee: MissingInfo[];
   missingDocumentByDoctor: MissingInfo[];
   missingDocumentByEmployer: MissingInfo[];
   isDocumentMissingFromEmployee = false;
   isDocumentMissingFromEmployer = false;
   isDocumentMissingFromDoctor = false;
   pageId: string;
   owscContent;
  public appConstants = AppLabelConstants;
  @Input() leave;
  @Input() infoLabel;
  @Input() message_1_Label;
  @Input() gotoMissingInfo_Button;
  @Input() header_3;
  @Input() message_3;
  @Input() header_2;
  @Input() message_2;
  @Input() dateLabel: DateLabelValueComponent;
   claimNeeded;
  @Input() leaveDates: {
    startDate: '',
    dateLabel: '',
    leaveReason: '',
    leaveSubReason: '',
    isCareFor: false
  };
  constructor(private router: Router, private activatedRoute: ActivatedRoute,
              private claimService: ClaimServiceService,
              private owcsCaasService: OWCSCaasService,
              private storageManagementService: StorageManagementService,
              private contentService: ContentService) {
  }



  ngOnInit() {
    this.claimNeeded = this.leave;
    if (this.storageManagementService.retrieveSessionObject(SessionConstants.MISSING_ITEMS)) {
      this.missingItems = JSON.parse(this.storageManagementService.retrieveSessionObject(SessionConstants.MISSING_ITEMS)).missingItems;
      if (this.leave) {
        this.missingInfoFromAllThaa = this.claimService.documentFilterThaaLeave(this.leave);
        this.documentCollection();
      }
    } else {
      this.owcsCaasService.getMissingItems().subscribe(
        (owcsMissingInfoResponse: OWCSMissingItemsResponse) => {
          if (owcsMissingInfoResponse && owcsMissingInfoResponse.forms) {

            this.missingItems = owcsMissingInfoResponse.forms;
            this.storageManagementService.setSessionObject(SessionConstants.MISSING_ITEMS, owcsMissingInfoResponse.forms);
            this.missingInfoFromAllThaa = this.claimService.documentFilterThaaLeave(this.leave);
            this.documentCollection();
          }
        }
      );
    }

    /*
     This is a hack t scroll to missing info page if the "Go to missing info" button was clicked from another page
     It should be somehow fixed in the future. See jumpToMissingInfo() body too.
     */
    this.activatedRoute.queryParams.subscribe(params => {
      if (params.scrollTo === 'missingInfoHeader') {
        this.jumpToMissingInfo('', '', '', '', '')
      }
    });

    this.fetchOWCSContent();
  }

  documentCollection() {
    if (this.missingInfoFromAllThaa) {
      this.missingDocumentByEmployee = this.missingInfoFromAllThaa.missingInfoDataFromEmployee;
      this.missingDocumentByDoctor = this.missingInfoFromAllThaa.missingInfoDataFromDoctor;
      this.missingDocumentByEmployer = this.missingInfoFromAllThaa.missingInfoDataFromEmployer;

      // this.isDocumentMissingFromEmployee = this.missingDocumentByEmployee && this.missingDocumentByEmployee.mInfoReqInfo && this.missingDocumentByEmployee.mInfoReqInfo.length > 0;
      this.isDocumentMissingFromDoctor = this.missingDocumentByDoctor && this.missingDocumentByDoctor[0].mInfoReqInfo && this.missingDocumentByDoctor[0].mInfoReqInfo.length > 0;
      this.isDocumentMissingFromEmployer = this.missingDocumentByEmployer && this.missingDocumentByEmployer[0].mInfoReqInfo && this.missingDocumentByEmployer[0].mInfoReqInfo.length > 0;
    }
  }
  /*
   Replacement of the above navigateTo()
   Scrolls if accessed from another page.
   Still a hack for the demo.
   */
  jumpToMissingInfo(location, curLeave, leaveId, authorizedEndDate, claimOfficeId) {
    const el = document.getElementById(AppConstants.MISSING_INFO_HEADER);
    sessionStorage.setItem(SessionConstants.MISSING_INF_DIV, SessionConstants.MISSING_INF_DIV);
    if (el) {
      el.scrollIntoView();
    } else {
      this.storageManagementService.setSessionData(SessionConstants.CURR_LEAVE, curLeave);
      this.storageManagementService.setSessionData(SessionConstants.LEAVE_ID, leaveId);
      this.storageManagementService.setSessionData(SessionConstants.LEAVE_DATES, this.leaveDates);
     // this.storageManagementService.setSessionObject(SessionConstants.AUTHORIZED_END_DATE, authorizedEndDate);
      //this.storageManagementService.setSessionObject(SessionConstants.CLAIM_OFFICE_ID, claimOfficeId);
     // this.dateLabel.claimEventId(leaveId);
      this.router.navigate([location], {queryParams: {scrollTo: AppConstants.MISSING_INFO_HEADER}});
    }
  }

  getName(mInfoReqInfoCd: string): string {
    const owcsKeys: string[] = this.owcsCaasService.getValues(mInfoReqInfoCd);
    if (owcsKeys && owcsKeys.length > 0) {

      const filteredMissingItems: MissingItem[] = this.missingItems.filter(
        (missingItem: MissingItem) => (missingItem.key === owcsKeys[0])
      );

      if (filteredMissingItems && filteredMissingItems.length > 0) {
        return filteredMissingItems[0].displayName;
      }
    }
  }

  fetchOWCSContent() {
    if (this.storageManagementService.getSessionData(SessionConstants.CLAIM_OVERVIEW_OWCS_CONTENT)) {
      this.fetchOWCSContentFromSession();
    } else {
      this.fetchOWCSContentFromService();
    }
  }

  fetchOWCSContentFromSession() {
    this.owscContent = this.storageManagementService.getSessionData(SessionConstants.CLAIM_OVERVIEW_OWCS_CONTENT);
    if (this.owscContent.CO_47_Body) {
      this.infoLabel = this.owscContent.CO_47_Body.value;
    }
    if (this.owscContent.CO_48_Body) {
      this.message_1_Label = this.owscContent.CO_48_Body.value;
    }
    if (this.owscContent.CO_50_Body) {
      this.gotoMissingInfo_Button = this.owscContent.CO_50_Body.value;
    }
    if (this.owscContent.CO_53_Body) {
      this.header_3 = this.owscContent.CO_53_Body.value;
    }
    if (this.owscContent.CO_54_Body) {
      this.message_3 = this.owscContent.CO_54_Body.value;
    }
    if (this.owscContent.CO_51_Body) {
      this.header_2 = this.owscContent.CO_51_Body.value;
    }

    if (this.owscContent.CO_89_Body &&
      this.claimNeeded &&
      this.claimNeeded.taskOpen === true) {
      this.message_2 = this.owscContent.CO_89_Body.value;
    } else if (this.owscContent.CO_52_Body && !this.claimNeeded.taskOpen) {
      this.message_2 = this.owscContent.CO_52_Body.value;
    }
  }

  fetchOWCSContentFromService() {
    this.pageId = this.contentService.getPageId(AppConstants.CLAIM_OVERVIEW);
    if (this.pageId) {
      this.contentService.getOWCSResponse(this.pageId, AppConstants.CLAIM_OVERVIEW_U).subscribe(
        val => {
          this.owscContent = val;
          if (this.owscContent && this.owscContent.data) {
            if (this.owscContent.data.CO_47_Label) {
              this.infoLabel = this.owscContent.data.CO_47_Label.value;
            }
            if (this.owscContent.data.CO_48_Body && this.owscContent.data.CO_49_Body) {
              this.message_1_Label = (this.claimNeeded && this.claimNeeded.coverageCategory && this.claimNeeded.coverageCategory.toUpperCase() === AppConstants.PFL) ? this.owscContent.data.CO_49_Body.value : this.owscContent.data.CO_48_Body.value;
            }
            if (this.owscContent.data.CO_50_Body) {
              this.gotoMissingInfo_Button = this.owscContent.data.CO_50_Body.value;
            }
            if (this.owscContent.data.CO_53_Label) {
              this.header_3 = this.owscContent.data.CO_53_Label.value;
            }
            if (this.owscContent.data.CO_54_Body) {
              this.message_3 = this.owscContent.data.CO_54_Body.value;
            }
            if (this.owscContent.data.CO_51_Label) {
              this.header_2 = this.owscContent.data.CO_51_Label.value;
            }

            if (this.owscContent.data.CO_89_Body &&
              this.claimNeeded &&
              this.claimNeeded.taskOpen === true) {
              this.message_2 = this.owscContent.data.CO_89_Body.value;
            } else if (this.owscContent.data.CO_52_Body && !this.claimNeeded.taskOpen) {
              this.message_2 = this.owscContent.data.CO_52_Body.value;
            }
            this.storageManagementService.setSessionData(SessionConstants.CLAIM_OVERVIEW_OWCS_CONTENT, val.data);
          }
        }, (error) => {
          console.error(error);
        });
    }
  }
}
