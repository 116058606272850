
import { Subject, of } from 'rxjs';
import { Injectable, OnDestroy } from '@angular/core';
import { SessionConstants } from '@app/shared/constants/session.constants';
import { ContentService } from '@app/shared/services/content.service';
import { StorageManagementService } from '@app/shared/services/storage-management.service';
import { takeUntil ,  filter ,  switchMap } from 'rxjs/operators';
import { Intake_Content_JSON, INTAKE_LOOKUP_JSON } from '../JSON/intake-content.constants';
import { IntakeLabelConstants } from '../constants/intake-label.constants';
import { OWCSContents } from '@app/mock-data/mock-owcsData';
import { IntakeSessionConstants } from '../../shared/constants/intakeSession.constants';
import {  IClaimSubmitResponse, ILookupKeyObject } from '../models/intake-content.model';
import {IntakeUtilitiesService} from '@app/cxr/modules/intake/shared/services/intake-utilities.service';

@Injectable()
export class IntakeOWCSContentService implements OnDestroy {
  owcsContent;
  newIntakeOwcsContent;
  intakeLookupJSON: ILookupKeyObject  = INTAKE_LOOKUP_JSON;
  private _destroy$: Subject<void> = new Subject();

  constructor(
    private contentService: ContentService,
    private storageManagementService: StorageManagementService,
    private intakeUtilitiesService: IntakeUtilitiesService
  ) {
    this.owcsContent = this.storageManagementService.getSessionData(IntakeLabelConstants.NEWINTAKE);
  }

  getIntakeOWCSContent() {
    const intake_owcs_content = this.storageManagementService.getSessionData(IntakeLabelConstants.NEWINTAKE);
    if (!intake_owcs_content) {
      let pageId = this.contentService.getPageId(IntakeLabelConstants.START_NEW_CLAIM);

      if (pageId) {
        this.getContentIntake(pageId);
      } else {
        this.contentService.getSiteMap().pipe(
          filter(siteMapData => !!siteMapData),
          takeUntil(this._destroy$),
          switchMap(siteMapResponse => {
            if (siteMapResponse) {
              this.storageManagementService.setSessionData(SessionConstants.SITE_MAP_DATA, siteMapResponse);
              pageId = this.contentService.getPageId(IntakeLabelConstants.START_NEW_CLAIM);
              return this.contentService.getOWCSResponse(pageId)
            } else {
              return of()
            }
          }
        )).subscribe(
          contentResponse => {
           this.owcsContent = OWCSContents.Start_a_New_Leave_AA_Step_1.data;
           this.storageManagementService.setSessionData(IntakeLabelConstants.NEWINTAKE, contentResponse);
          });
      }
    }
  }
  getContentIntake(pageId) {
    this.contentService.getOWCSResponse(pageId, IntakeLabelConstants.NEWINTAKE_OWCS).pipe( filter(contentResponse => !!contentResponse),
      takeUntil(this._destroy$)).subscribe(
      contentResponse => {
        //real time InTAKE DATA needs to be updated
        this.storageManagementService.setSessionData(IntakeLabelConstants.NEWINTAKE, contentResponse );
    });
  }
  getOWCSContentSession(label, removeTags: boolean = true) {
    this.owcsContent = this.storageManagementService.getSessionData(IntakeLabelConstants.NEWINTAKE)
    if (this.owcsContent && this.owcsContent.data[label]) {
      if (removeTags) {
        return this.intakeUtilitiesService.removeTag(this.owcsContent.data[label].value);
      } else {
        return this.owcsContent.data[label].value;
      }
    }
  }
  isVisibility(key) {
    if (key && Intake_Content_JSON[key].visibility) {
      return true
    } else {
      return false
    }
  }

  getRegisteredId(key: string) {
    if (key && Intake_Content_JSON[key].registeredId) {
      return Intake_Content_JSON[key].registeredId;
    } else {
      return ''
    }
  }
  getLookUpId(key: string) {
    if (key && Intake_Content_JSON[key].lookUpId) {
      return Intake_Content_JSON[key].lookUpId;
    } else {
      return ''
    }
  }
   getMBAnswer(key: string) {
    if (key && Intake_Content_JSON[key].questionOWCSKey) {
      return Intake_Content_JSON[key].questionOWCSKey;
    } else {
      return ''
    }
  }
  getDeliveryRawValues(key: string) {
    if (key) {
      return key;
    } else {
      return null;
    }
  }
  getNewMaternityIntakeOwcsContent(label) {
    this.newIntakeOwcsContent = this.storageManagementService.getSessionData(IntakeSessionConstants.NI_OWCS_CONTENT);
    if (this.newIntakeOwcsContent && this.newIntakeOwcsContent.data[label]) {
      return this.newIntakeOwcsContent.data[label].value;
    }
  }
  getClaimTypeLabelList(claimLabel: string , showPrint: boolean){
    const claimSubmitResponse: IClaimSubmitResponse = this.storageManagementService.getIntakeSessionData(IntakeSessionConstants.CLAIM_SUBMIT_RESPONSE);
    const fmliStatStatus: boolean = this.storageManagementService.getSessionData(SessionConstants.FMLI_STATUS)?.fmliStatStatus;
    const submittedClaims: Map<string, string> = new Map();
    const claimTypeLabelList = [];
    for(let key in claimSubmitResponse) {
      if(!!IntakeLabelConstants.CLAIM_TYPES_WITH_LABEL[key] && claimSubmitResponse[key] !== null && claimSubmitResponse[key] !== '') {
        if(key === 'STATClaim' && fmliStatStatus) {
          submittedClaims.set(IntakeLabelConstants.MEDICAL_LEAVE_INSURANCE, claimSubmitResponse[key]);
        } else {
          submittedClaims.set(IntakeLabelConstants.CLAIM_TYPES_WITH_LABEL[key], claimSubmitResponse[key]);
        }
      }
    }

    submittedClaims.forEach((value, key) => {
      const claimTypeHTMLLabel: string = claimLabel.replace('{ClaimType}', key).replace('{ClaimNumber}', `<strong>${value}</strong>`);
      const convertedClaimTypeLabel =  `<div>${claimTypeHTMLLabel}</div>`;
      claimTypeLabelList.push(convertedClaimTypeLabel);
    })
    return claimTypeLabelList;
  }
  ngOnDestroy() {
    this._destroy$.next();
    this._destroy$.complete();
  }
}
