import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {ElementControlComponent} from '../../../sub-atomic/element-control/element-control.component';

@Component({
  selector: 'primary-dropdown',
  templateUrl: 'primary-dropdown.component.html',
  styleUrls: ['primary-dropdown.component.scss']
})
export class PrimaryDropdownComponent extends ElementControlComponent implements OnInit  {
  @Input() dropdownList: object[];
  @Input() dropdownOpen: boolean;
  @Output() dropdownIndex = new EventEmitter<string>();
  @Input() inputString: string;
  @Input() inputPlaceholder: string;
  @Input() inputType: string;
  @Input() inputId: string;
  @Input() maxlength: string;
  @Input() minlength: string;
  @Input() labelName: string;
  @Input() disabledDropdown = false;
  @Input() selectedOption: string;
  @Input() readOnlyCondition: boolean;
  @Input() ariaLabel: string;
  @Input() selectOneDisplay: boolean = true;
}
