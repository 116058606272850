
import {catchError, map} from 'rxjs/operators';
/**
 * Created by AC12212 on 8/10/2017.
 */
import {Injectable} from '@angular/core';
import {Observable, BehaviorSubject} from 'rxjs';
import {env} from '../../../environments/environment-loader';

import {ResponseErrorHandlerService} from '../services/response-error-handler.service';
import {StorageManagementService} from './storage-management.service';
import {PersonService} from './people.service';
import {SaveProfiledataService} from './saveProfileData.service';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {PolicyAdminLeaveResponse} from '../models/LeaveMappings.model';
import {AppConstants} from '../constants/app.constants';
import {SessionConstants} from '../constants/session.constants';
import {AppLabelConstants} from '../constants/appLabel.constants';

@Injectable()
export class NotificationService {

  private profileData;
  public jsonBOM;
  private caseId: string;
  private insuredId: string;
  private updateProfileRequest: any;
  private updateProfileResponse: any;
  failedTransactionCtr = new BehaviorSubject(0);
  isUpdatePrefServiceFailed = new BehaviorSubject(false);
  isProfileSyncServiceFailed = new BehaviorSubject(false);


  constructor(private errorHandler: ResponseErrorHandlerService,
              private storageMgmt: StorageManagementService,
              private profileService: PersonService,
              private updateData: SaveProfiledataService,
              private httpClient: HttpClient
  ) {

  }


  isNotification(caseID: number): Observable<any> {
      const request: string = JSON.stringify(
        {
           caseId: caseID,
        }
      );
      return this.httpClient.post(env.gbClaims.getIsNotificationURL, request,
        {
          headers: new HttpHeaders()
            .set(AppLabelConstants.CONTENT_TYPE_KEY, AppLabelConstants.APPLICATION_KEY)
        }).pipe(
        map(
        (response: Response) => {
          if (!this.errorHandler.isServiceResponseHasError(response)) {
            return response;
          } else {
            throw response;
          }
        }
      ), catchError((error: any) => this.errorHandler.handleError(error)), );

  }


  policyAdminLeaveService(caseId: number): Observable<PolicyAdminLeaveResponse> {
      return this.httpClient.get(env.gbPolicy.getPolicyAdminLeaveServiceURLFirst + '&caseId=' + caseId,
        {
          headers: new HttpHeaders()
            .set(AppLabelConstants.CONTENT_TYPE_KEY, AppLabelConstants.APPLICATION_KEY)
        }).pipe(
        map(
        (response: PolicyAdminLeaveResponse) => {
          if (!this.errorHandler.isServiceResponseHasError(response)) {
            return response;
          } else {
            throw response;
          }
        }
      ), catchError((error: any) => this.errorHandler.handleError(error)), );
  }


  UnenrollFromEconsent(communicationPreference) {
    this.jsonBOM = JSON.parse(this.storageMgmt.retrieveSessionObject('appData')).appData;
    this.caseId = this.jsonBOM.userData.caseId;
    this.insuredId = this.jsonBOM.userData.srcSysId;
    const profileDtls = this.storageMgmt.getAppData(SessionConstants.PROFILE_DETAILS);
    if (profileDtls && Object.keys(profileDtls).length > 0) {
      this.profileData = profileDtls;
    } else {
      this.profileService.getUserProfile('4', this.insuredId, this.caseId).subscribe(
        data => {
          if (data) {
            this.profileData = data;
          }
        }
      );
    }

    if (this.profileData &&
      this.profileData &&
      this.profileData.econsentDataResponseBeanList &&
      this.profileData.econsentDataResponseBeanList[0].econsentCustomerList &&
      this.profileData.econsentDataResponseBeanList[0].econsentCustomerList[0].eConsentDocumentCategoryList
      && this.profileData.econsentDataResponseBeanList[0].econsentCustomerList[0].eConsentDocumentCategoryList.length > 0) {
      for (let i = 0; i < this.profileData.econsentDataResponseBeanList[0].econsentCustomerList[0].eConsentDocumentCategoryList.length; i++) {
        if (this.profileData.econsentDataResponseBeanList[0].econsentCustomerList[0].eConsentDocumentCategoryList[i].documentCategoryCode === AppConstants.DCST) {
          if (this.profileData.econsentDataResponseBeanList[0].econsentCustomerList[0].eConsentDocumentCategoryList[i].deliveryMethod === AppConstants.SMS) {
            this.profileData.econsentDataResponseBeanList[0].econsentCustomerList[0].eConsentDocumentCategoryList[i].activeIndicator = communicationPreference;
          }
        }
        if (this.profileData.econsentDataResponseBeanList[0].econsentCustomerList[0].eConsentDocumentCategoryList[i].documentCategoryCode === AppConstants.DCPT) {
          if (this.profileData.econsentDataResponseBeanList[0].econsentCustomerList[0].eConsentDocumentCategoryList[i].deliveryMethod === AppConstants.SMS) {
            this.profileData.econsentDataResponseBeanList[0].econsentCustomerList[0].eConsentDocumentCategoryList[i].activeIndicator = communicationPreference;
          }

        }

        this.updateProfileRequest = {
          businessGroupID: '4',
          businessSegment: 'GB',
          requestSubmitter: 'TestTHAW',
          processingMode: 'activity-processed',
          insuredId: this.insuredId,
          caseId: this.caseId,
          eConsentProfileInfoRequestItemList: [
            {
              eConsentActivityInformation: {
                activityType: 'scsc-consent-acct-doc-change',
                activityCustomerNotification: false,
                activitySourceSystem: 'THAW',
                activitySourceProcessorID: 'GBTHAW',
                activityConsentType: 'ConsentParty'
              },
              eConsentCustomerList: [
                {
                  eConsent: {
                    subscribeResponseMethod: 'Web',
                    consentActivityStatusCode: 'CMPL'
                  },
                  eConsentAdminSystemParty: {
                    adminSystemName: 'DCS'
                  },
                  eConsentDocumentCategoryList: this.profileData.econsentDataResponseBeanList[0].econsentCustomerList[0].eConsentDocumentCategoryList
                }
              ]
            }
          ]
        }
      }
      this.updateData.updateProfile(this.updateProfileRequest).subscribe(
        val => {
          if (val) {
            this.updateProfileResponse = val;
            this.isUpdatePrefServiceFailed.next(false);
          }
        }, (error) => {
            if (!this.isProfileSyncServiceFailed.getValue()) {
              this.failedTransactionCtr.next(this.failedTransactionCtr.getValue() + 1);
              this.isUpdatePrefServiceFailed.next(true);
              console.error(error);
            }
        },

      () => {
        if (communicationPreference === 0) {
          this.storageMgmt.setSessionData(SessionConstants.ACTIVE_INDICATOR, false);
        } else if (communicationPreference === 1) {
          this.storageMgmt.setSessionData(SessionConstants.ACTIVE_INDICATOR, true);
        }
      }
      );

    }
  }
}


