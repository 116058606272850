import {Component, OnInit} from '@angular/core';
import {Router} from '@angular/router';
import {TaxFormConstants} from '../tax-form.constants';

/**
 * Tax Form Widget
 */
@Component({
  selector: 'app-tax-form-widget',
  templateUrl: './tax-forms-widget.component.html',
  styleUrls: ['./tax-forms-widget.component.scss']
})
export class TaxFormWidgetComponent implements OnInit {

  public taxFormLabel = TaxFormConstants.TAX_FORM;
  public seeMoreforms = TaxFormConstants.GET_TAX_FORMS;
  public taxFormpara = TaxFormConstants.TAX_WIDGET_INFO;

  /**
   * @param router
   */
  constructor(private router: Router) { }

  ngOnInit() {
  }

  /**
   * Navigate user to Tax Form List page
   */
  routeToTaxForm() {
    this.router.navigateByUrl(TaxFormConstants.TAX_FORMS);
  }

}
