
import {from, of, Observable, ReplaySubject} from 'rxjs';

import {toArray, concatMap, mergeMap, catchError, map} from 'rxjs/operators';
/**
 * Created by AC12394 on 9/11/2017.
 */


import {Injectable} from '@angular/core';
import {
  CaseInfo,
  ClaimantPaymentInfo,
  ClaimPaymentDetailsRequest,
  ClaimPaymentDetailsResponse,
  ClaimPaymentInfo,
  ClaimPaymentRequest,
  ClaimPaymentResponse,
  ClaimToCaseInfoMapping,
  ItotalPayments,
  PayeeDetailsRequest,
  PayeeDetailsResponse,
  PayeeType,
  PayeeTypeRequest,
  PayeeTypeResponse,
  Payment
} from '../models/Payment.model';

import {env} from '../../../environments/environment-loader';
import {RelatedClaims} from '../models/relatedClaims.model';
import {ResponseErrorHandlerService} from './response-error-handler.service';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {StorageManagementService} from './storage-management.service';
import {DepositService} from './enrolldeposit.service';
import {Provision, ProvisionResponse} from '../models/Provision.model';
import {BlazeService} from './blazeRule.service';
import {BankDetails} from '../models/bankDetails.model';
import {SessionConstants} from '../constants/session.constants';
import {AppConstants} from '../constants/app.constants';
import {AppLabelConstants} from '../constants/appLabel.constants';
import {PaymentList} from '@shared/models/aetnaPayments.model';


@Injectable()
export class PaymentsHistoryService {

  private loggedInUser;
  public isClaimantPaymentInfoSetInSession: ReplaySubject<boolean> = new ReplaySubject<boolean>(1);
  public isHistoricalClaimantPaymentInfoSetInSession: ReplaySubject<boolean> = new ReplaySubject<boolean>(1);
  public isProvisionsSet: ReplaySubject<boolean> = new ReplaySubject<boolean>(1);
  public isEFTdetailsExits: ReplaySubject<boolean> = new ReplaySubject<boolean>(1);
  public isGetBankingResponseAvlbl: ReplaySubject<boolean> = new ReplaySubject<boolean>(1);
  public userData;

  constructor(private errorHandler: ResponseErrorHandlerService,
              private httpClient: HttpClient,
              private storageManagementService: StorageManagementService,
              private depositService: DepositService,
              private blazeService: BlazeService) {

    this.initializeClaimantPaymentInfoFlags();
  }

  /**
   * GetClaimPayment Service Integration
   *
   * @param claimPaymentRequest  : ClaimPaymentRequest
   * @returns {Observable<ClaimPaymentResponse>}
   */

  getClaimPaymentHistory(claimPaymentRequest: ClaimPaymentRequest): Observable<ClaimPaymentResponse> {
    const request: string = JSON.stringify(
      claimPaymentRequest
    );
    const totalPayments = this.storageManagementService.getSessionData(AppLabelConstants.PAYMENTS_LIST_DCS);
    let totalPaymentsArray = [];
    if (totalPayments && totalPayments.length > 0) {
      totalPaymentsArray = totalPayments.filter(data => data.claimEventId === claimPaymentRequest.claimEventId);
    }
    if (totalPaymentsArray && totalPaymentsArray.length > 0) {
      if (totalPaymentsArray[0].claimEventId === claimPaymentRequest.claimEventId) {
        if (totalPaymentsArray[0].totalPaymentResponseData && totalPaymentsArray[0].totalPaymentResponseData.claimPaymentInfo) {
          return of(totalPaymentsArray[0].totalPaymentResponseData);
        }
      }
    } else {
    return this.httpClient.post(env.gbClaims.getClaimPaymentsHistory, request,
      {
        headers: new HttpHeaders()
          .set(AppLabelConstants.CONTENT_TYPE_KEY, AppLabelConstants.APPLICATION_KEY)
      }).pipe(
      map(
        (response: ClaimPaymentResponse) => {
          if (!this.errorHandler.isServiceResponseHasError(response)) {
            return response;
          } else {
            throw response;
          }
        }
      ), catchError((error: any) => this.errorHandler.handleError(error)), );
    }
  }

  /**
   * GetPaymentDetails Service Integration
   *
   * @param claimPaymentDetailsRequest: ClaimPaymentDetailsRequest
   * @returns {Observable<ClaimPaymentDetailsResponse>}
   */

  getSinglePaymentDetails(claimPaymentDetailsRequest: ClaimPaymentDetailsRequest): Observable<ClaimPaymentDetailsResponse> {

    const request: string = JSON.stringify(
      claimPaymentDetailsRequest
    );

    return this.httpClient.post(env.gbClaims.getSinglePaymentDetails, request,
      {
        headers: new HttpHeaders()
          .set(AppLabelConstants.CONTENT_TYPE_KEY, AppLabelConstants.APPLICATION_KEY)
      }).pipe(
      map(
        (response: ClaimPaymentDetailsResponse) => {
          if (!this.errorHandler.isServiceResponseHasError(response)) {
            return response;
          } else {
            throw response;
          }
        }
      ), catchError((error: any) => this.errorHandler.handleError(error)), );

  }

  /**
   * GetPaymentDetails Service Integration
   *
   * @param payeeDetailsRequest : PayeeDetailsRequest
   * @returns {Observable<PayeeDetailsResponse>}
   */

  getPayeeDetails(payeeDetailsRequest: PayeeDetailsRequest): Observable<PayeeDetailsResponse> {
    const request: string = JSON.stringify(
      payeeDetailsRequest
    );

    return this.httpClient.post(env.gbClaims.getPayeeDetails, request,
      {
        headers: new HttpHeaders()
          .set(AppLabelConstants.CONTENT_TYPE_KEY, AppLabelConstants.APPLICATION_KEY)
      }).pipe(
      map(
        (response: PayeeDetailsResponse) => {
          if (!this.errorHandler.isServiceResponseHasError(response)) {
            return response;
          } else {
            throw response;
          }
        }
      ), catchError((error: any) => this.errorHandler.handleError(error)), );

  }

  /**
   * GetPayeeType Service Integration
   *
   * @param claimEventId : string
   * @returns {Observable<string>}
   */

  getPayeeType(claimEventIdList: string[]): Observable<PayeeTypeResponse> {

    const payeeTypeRequestBody: PayeeTypeRequest = new PayeeTypeRequest();

    payeeTypeRequestBody.claimEventIds = claimEventIdList;

    return this.httpClient.post<PayeeTypeResponse>(env.gbClaims.getPayeeType,
      JSON.stringify(payeeTypeRequestBody),
      {
        headers: new HttpHeaders()
          .set(AppLabelConstants.CONTENT_TYPE_KEY, AppLabelConstants.APPLICATION_KEY)
      }).pipe(
      map(
        (response: PayeeTypeResponse) => {
          if (!this.errorHandler.isServiceResponseHasError(response)) {
            return response;
          } else {
            throw response;
          }
        }
      ), catchError((error: any) => this.errorHandler.handleError(error)), );


  }

  /**
   * Helper Methods go below
   */


  /**
   * @param claimEventId : string
   * @returns {boolean}
   */


  ifPaymentInfoToBeDisplayed(curClaim: RelatedClaims): boolean {
    return ((curClaim.claimOnlineStatusCode === '04') || ((curClaim.claimOnlineStatusCode === '05') && (curClaim.claimOnlineSubStatusCode === '08')) || (curClaim.coverageCategory.toUpperCase() === 'LTD') || (curClaim.coverageCategory.toUpperCase() === 'PFL'))
  }


  checkIfBankDetailsExist() {
    this.isGetBankingResponseAvlbl.subscribe((data) => {
      if (data) {
        const bankDtls = this.storageManagementService.getAppData(SessionConstants.BANKING_DETAILS);
        if (bankDtls && Object.keys(bankDtls).length > 0) {
          this.setIsBankDetailsExist(true);
        } else {
          this.loggedInUser = this.storageManagementService.getAppData(SessionConstants.USER_DATA);
          if (this.loggedInUser.insuredId !== undefined || this.loggedInUser.insuredId !== null || (this.loggedInUser.personIds && this.loggedInUser.personIds.length > 0 )) {
            this.depositService.getBankDatailService(this.loggedInUser.insuredId, this.loggedInUser.personIds).subscribe(
              val => {
                if (val) {
                  this.setIsBankDetailsExist(true);
                  this.storageManagementService.setAppData(SessionConstants.BANKING_DETAILS, val );
                } else {
                  this.setIsBankDetailsExist(false);
                }
              },
              (error) => {
                this.setIsBankDetailsExist(false);
              });
          }
        }
      }
    });
  }

  getIsBankDetailsExist(): boolean {
    const claimantPaymentInfoVar: ClaimantPaymentInfo = this.getClaimantPaymentInfo();
    return (claimantPaymentInfoVar && claimantPaymentInfoVar.isBankDetailsExist);
  }

  getBankDetails(): BankDetails {
    const claimantPaymentInfoVar: ClaimantPaymentInfo = this.getClaimantPaymentInfo();
    return ((claimantPaymentInfoVar && claimantPaymentInfoVar.bankDetails) ? claimantPaymentInfoVar.bankDetails : null);
  }

  setIsBankDetailsExist(isBankDetailsExist: boolean) {
    const claimantPaymentInfoVar: ClaimantPaymentInfo = this.getClaimantPaymentInfo();
    const  claimantPaymentInfoSetInSession = this.storageManagementService.getSessionData(SessionConstants.IS_CLAIMANT_PAYMENT_INFO_STE_IN_SESSION);
    if (claimantPaymentInfoVar !== null && !claimantPaymentInfoSetInSession) {
      claimantPaymentInfoVar.isBankDetailsExist = isBankDetailsExist;
      this.saveClaimantPaymentInfoInSession(claimantPaymentInfoVar);
    }
    this.storageManagementService.setSessionData(SessionConstants.IS_BANKING_DETAILS_EXIST, isBankDetailsExist);
    this.isEFTdetailsExits.next(isBankDetailsExist);
  }

  getIsPaymentApplicable(): boolean {
    const claimantPaymentInfoVar: ClaimantPaymentInfo = this.getClaimantPaymentInfo();
    return (claimantPaymentInfoVar && claimantPaymentInfoVar.isPaymentApplicable);
  }

  getClaimantPaymentInfo(): ClaimantPaymentInfo {
    const claimantPaymentInfoVar: ClaimantPaymentInfo = this.storageManagementService.getSessionData(SessionConstants.CLAIMANT_PAYMENT_INFO_LG) ?
      this.storageManagementService.getSessionData(SessionConstants.CLAIMANT_PAYMENT_INFO_LG) : null;
    return claimantPaymentInfoVar;
  }

  getClaimPaymentInfo(claimEventId: string): ClaimPaymentInfo {
    const claimantPaymentInfoVar: ClaimantPaymentInfo = this.getClaimantPaymentInfo();
    let filteredClaimantPaymentInfo: ClaimPaymentInfo[] = [];
    if (claimantPaymentInfoVar && claimantPaymentInfoVar.claimsPaymentInfo && claimantPaymentInfoVar.claimsPaymentInfo.length > 0) {
      filteredClaimantPaymentInfo = claimantPaymentInfoVar.claimsPaymentInfo.filter((claimantPaymentInfo: ClaimPaymentInfo) => claimantPaymentInfo.claimEventId === claimEventId);
    }
    if (filteredClaimantPaymentInfo && filteredClaimantPaymentInfo.length > 0) {
      return filteredClaimantPaymentInfo[0];
    }
    return null;
  }

  setClaimPaymentInfo(claimEventId: string, payments: Payment[]) {
    const claimantPaymentInfo: ClaimantPaymentInfo = this.getClaimantPaymentInfo();
    let index = -1;

    if (claimantPaymentInfo && claimantPaymentInfo.claimsPaymentInfo && claimantPaymentInfo.claimsPaymentInfo.length > 0) {
      index = claimantPaymentInfo.claimsPaymentInfo.findIndex(
        (el: ClaimPaymentInfo) => el.claimEventId === claimEventId
      );
    }

    if (index !== -1) {
      claimantPaymentInfo.claimsPaymentInfo[index].payments = payments;
      this.storageManagementService.setSessionData(SessionConstants.CLAIMANT_PAYMENT_INFO_LG, claimantPaymentInfo);
    }
  }

  transformPayments(paymentInfo, claimEventId, personIdValue, claimType, claimSubType) {
    if (paymentInfo.paymentList && paymentInfo.paymentList.length > 0) {
      let paymentId = 1000;
      const payments = [];
      paymentInfo.paymentList.forEach((aaPayment: PaymentList) => {
        const payment: Payment = new Payment();
        paymentId = ++paymentId;
        payment.paymentID = paymentId.toString();
        payment.checkNbr = (aaPayment.deliveryMethod && aaPayment.deliveryMethod.toUpperCase() === AppConstants.PAYMENTS_EFT) ? aaPayment.deliveryMethod : aaPayment.checkNum;
        payment.payee = aaPayment.payeeName;
        payment.paymentDate = aaPayment.checkDate;
        payment.checkAmt = parseFloat(aaPayment.netPayAmount.replace(/,/g, '').slice(1));
        payment.startDate = aaPayment.payPeriodFrom;
        payment.endDate = aaPayment.payPeriodThrough;
        payment.eftAccountNumber = [aaPayment.accountNumber];
        payment.eobBlob = {
          personId: personIdValue,
          claimId: claimEventId,
          eobNum: aaPayment.eobNum,
          company: aaPayment.company,
          paygroup: aaPayment.paygroup,
          payEndDate: aaPayment.payEndDate,
          offcycle: aaPayment.offcycle,
          pageNum: aaPayment.pageNum,
          lineNum: aaPayment.lineNum,
          sepchk: aaPayment.sepchk
        };
        payments.push(payment);
        const paystubFlag = paymentInfo.displayPaystub !== null && paymentInfo.displayPaystub !== undefined ? paymentInfo.displayPaystub : '';
        this.paymentsSortedByIssueDate(claimEventId, claimType, claimSubType, AppConstants.SRC_PARTY_NAME_THAA, paystubFlag, aaPayment, personIdValue, null);
      });
      this.setClaimPaymentDetails(claimEventId, paymentInfo, AppConstants.SRC_PARTY_NAME_THAA);
      return payments;
    }
  }

  /*
  * store individual payments for all thaa and dcs claims in a single session object,
  * sorted by most recent check date (thaa) and start date (dcs)
  * @param claimEventId
  * @param claimType
  * @param claimSubType
  * @param claimSystem - thaa or dcs
  * @param paystubFlag - display paystub true/false
  * @param paymentData - response from payment api
  * */
  paymentsSortedByIssueDate(claimEventIdValue, claimTypeValue, claimSubTypeValue, claimSystemValue, paystubFlag, paymentData, personIdValue, insuredIdValue) {
    const isAA = AppConstants.SRC_PARTY_NAME_THAA;

    const paymentDataExists = this.storageManagementService.getSessionData(SessionConstants.ALL_CLAIM_PAYMENTS_SORTED_BY_ISSUE_DATE);
    let paymentObj = [];
    if (paymentDataExists) {
      paymentObj = paymentDataExists;
    }

    const claimInfoObject = {
      claimEventId: claimEventIdValue,
      claimType: claimTypeValue,
      claimSubType: claimSubTypeValue,
      claimSystem: claimSystemValue,
      displayPaystub: paystubFlag,
      personId: personIdValue,
      insuredId: insuredIdValue
    };

    const index = paymentObj.findIndex(claim => {
      const isClaimInSessionAA = claim.claimInfo.claimSystem.toLowerCase() === isAA.toLowerCase();
      const isCurrentClaimAA = claimSystemValue.toLowerCase() === isAA.toLowerCase();

      if (isClaimInSessionAA && isCurrentClaimAA) {
        return claim.paymentInfo.checkNum === paymentData.checkNum;
      } else if (!isClaimInSessionAA && !isCurrentClaimAA) {
        return claim.paymentInfo.paymentID === paymentData.paymentID;
      }
    });

    if (index === -1) {
      paymentObj.push({claimInfo: claimInfoObject, paymentInfo: paymentData});

      if (paymentObj && paymentObj.length > 1) {
        paymentObj.sort((firstClaim, secondClaim) => {
          const firstClaimAA = firstClaim.claimInfo.claimSystem.toLowerCase() === isAA.toLowerCase();
          const secondClaimAA = secondClaim.claimInfo.claimSystem.toLowerCase() === isAA.toLowerCase();

          return this.paymentDateComparision(
            secondClaimAA ? secondClaim.paymentInfo.checkDate : secondClaim.paymentInfo.paymentDate,
            firstClaimAA ? firstClaim.paymentInfo.checkDate : firstClaim.paymentInfo.paymentDate,
            secondClaimAA, firstClaimAA, secondClaim.paymentInfo, firstClaim.paymentInfo
          );
        });
      }
    }
    this.storageManagementService.setSessionData(SessionConstants.ALL_CLAIM_PAYMENTS_SORTED_BY_ISSUE_DATE, paymentObj);
  }

  /*
  * logic to compare dates for claims present in session and return most recent one (latest date) between the two
  * */
  paymentDateComparision(dateForSecondClaim, dateForFirstClaim, isSecondClaimAA, isFirstClaimAA, secondClaimPaymentInfo, firstClaimPaymentInfo) {
    const dateClaimTwo = new Date(dateForSecondClaim).getTime();
    const dateClaimOne = new Date(dateForFirstClaim).getTime();
    if (dateClaimTwo === dateClaimOne) {
      const newDateClaimTwo = isSecondClaimAA ? secondClaimPaymentInfo.payPeriodThrough : secondClaimPaymentInfo.endDate;
      const newDateClaimOne = isFirstClaimAA ? firstClaimPaymentInfo.payPeriodThrough : firstClaimPaymentInfo.endDate;
      return new Date(newDateClaimTwo).getTime() - new Date(newDateClaimOne).getTime();
    } else {
      return dateClaimTwo - dateClaimOne;
    }
  }

  /*
  * set payment api response in session for thaa and dcs
  * @param: claimEventId
  * @param: payments - payment details for individual claims
  * @param: claimSystem: thaa or dcs
  * */
  setClaimPaymentDetails(claimEventIdValue, payments, claimSystemValue) {
    const isAA = claimSystemValue.toLowerCase() === AppConstants.SRC_PARTY_NAME_THAA.toLowerCase();

    if (claimEventIdValue && payments && claimSystemValue) {
      const paystubFlag = payments.displayPaystub !== null && payments.displayPaystub !== undefined ? payments.displayPaystub : '';
      const paymentData = {
        claimEventId: claimEventIdValue,
        claimSystem: claimSystemValue,
        displayPaystub: paystubFlag,
        payments: this.dataExists(isAA ? payments.paymentList : payments)
      };

      let paymentDetailsObject = [];
      const paymentDetailsExist = this.storageManagementService.getSessionData(SessionConstants.ALL_CLAIM_PAYMENT_DETAILS);
      if (paymentDetailsExist) {
        paymentDetailsObject = paymentDetailsExist;
      }

      const index = paymentDetailsObject.findIndex(claim => claim.claimEventId === paymentData.claimEventId);
      if (index === -1) {
        paymentDetailsObject.push(paymentData);
      }
      this.storageManagementService.setSessionData(SessionConstants.ALL_CLAIM_PAYMENT_DETAILS, paymentDetailsObject);
    }
  }

  /*
  * null check for data
  * */
  dataExists(data) {
    return data ? data : '';
  }

  updateClaimPaymentSession(payment, claimEventId, personId, claimType, claimSubType) {
    const payments = this.transformPayments(payment, claimEventId, personId, claimType, claimSubType);
    this.setClaimPaymentInfo(claimEventId, payments);
  }

/*  addClaimPaymentInfo(claimEventId: string, isClaimFullyInsured ?: boolean, payments ?: Payment[], payeeType ?: string) {
    const claimantPaymentInfo: ClaimantPaymentInfo = this.getClaimantPaymentInfo();

    const newClaimPaymentInfo: ClaimPaymentInfo = new ClaimPaymentInfo();
    newClaimPaymentInfo.claimEventId = claimEventId;
    newClaimPaymentInfo.isClaimFullyInsured = isClaimFullyInsured;
    newClaimPaymentInfo.payments = payments;
    newClaimPaymentInfo.payeeType = payeeType;

    if (claimantPaymentInfo && claimantPaymentInfo.claimsPaymentInfo && claimantPaymentInfo.claimsPaymentInfo.length >= 0) {
      claimantPaymentInfo.claimsPaymentInfo.push(newClaimPaymentInfo);
      this.storageManagementService.setSessionData(SessionConstants.CLAIMANT_PAYMENT_INFO_LG, claimantPaymentInfo);
    }
  }*/

  setProvisions(caseId: number) {
    this.depositService.getPolicyAdminProvisions(caseId, 315).subscribe(
      (policyAdminProvisionsResponse: ProvisionResponse) => {
        this.setProvisionsFlag(policyAdminProvisionsResponse);
      },
      (error) => {
        this.setProvisionsFlag();
      }
    );
  }
  setProvisionsForChannelId(caseId: number) {
    this.depositService.getPolicyAdminProvisions(caseId, 942).subscribe(
      (policyAdminProvisionsOptionResponse: ProvisionResponse) => {
        this.storageManagementService.setAppData(SessionConstants.PROVISIONSOPTIONS, policyAdminProvisionsOptionResponse);

      },
      (error) => {

      }
    );
  }


  /**
   * TODO : New method which accepts claims as an array of RelatedClaim[] and returns an Observable of ClaimantPaymentInfo to indicate whether a claim is fully insured or not
   */

  public setupClaimantPaymentInfo(claims: RelatedClaims[], defaultCaseId: string, isHistorical: boolean): Observable<ClaimantPaymentInfo> {
    let claimsPaymentInfo: ClaimPaymentInfo[] = this.initializeClaimsPaymentInfo(claims, defaultCaseId, isHistorical);
    const casesInfo: CaseInfo[] = this.createUniqueCasesInfo(claimsPaymentInfo);
    const claimantPaymentInfo: ClaimantPaymentInfo = new ClaimantPaymentInfo(claimsPaymentInfo);
    claimantPaymentInfo.casesInfo = casesInfo;

    return this.fetchPayeeTypeForAllClaims(claimsPaymentInfo).pipe(
      mergeMap((payeeTypeDetailsList: PayeeType[]) => {
        if (payeeTypeDetailsList && payeeTypeDetailsList.length > 0) {
          claimsPaymentInfo = this.addPayeeTypeInClaimPaymentInfo(claimsPaymentInfo, payeeTypeDetailsList);
          if (!isHistorical) {
            this.blazeService.addPayeeTypeInUserClaims(payeeTypeDetailsList);
          }
        }
        return of(claimsPaymentInfo);
      }),
      mergeMap((updatedClaimsPaymentInfo: ClaimPaymentInfo[]) => {
        claimantPaymentInfo.claimsPaymentInfo = updatedClaimsPaymentInfo;
        return from(updatedClaimsPaymentInfo);
      }),
      concatMap((claimPaymentInfo: ClaimPaymentInfo) => this.checkIfClaimIsFullyInsured(claimPaymentInfo, claimantPaymentInfo.casesInfo).pipe(
          mergeMap(
            (claimToCaseInfo: ClaimToCaseInfoMapping) => {
              claimPaymentInfo = claimToCaseInfo.claimPaymentInfo;
              claimantPaymentInfo.casesInfo = claimToCaseInfo.casesInfo;
              return of(claimPaymentInfo);
            }
          ))),
      toArray(),
      mergeMap((claimsPaymentInfoElem: ClaimPaymentInfo[]) => {
        if (!isHistorical) {
          claimantPaymentInfo.checkIfPaymentApplicable();

          /** Banking Logic Begins **/
          return this.fetchBankingInfo().pipe(mergeMap(
            (bankingInfo: BankDetails) => {
              claimantPaymentInfo.bankDetails = bankingInfo;
              claimantPaymentInfo.isBankDetailsExist = !!bankingInfo;
              return of(claimantPaymentInfo);
            }
          ))
        }
        return of(claimantPaymentInfo);
      }), );
  }

  public fetchBankingInfo(): Observable<BankDetails> {
    const bankDtls = this.storageManagementService.getAppData(SessionConstants.BANKING_DETAILS);
    if (bankDtls && Object.keys(bankDtls).length > 0) {
      return of(bankDtls);
  } else {
      this.loggedInUser = this.storageManagementService.getAppData(SessionConstants.USER_DATA);
      if (this.loggedInUser && (this.loggedInUser.insuredId || (this.loggedInUser.personIds && this.loggedInUser.personIds.length > 0 ))) {
        let isBankError = false;
        return this.depositService.getBankDatailService(this.loggedInUser.insuredId, this.loggedInUser.personIds).pipe(
          catchError((err) => {
            isBankError = true;
            this.isGetBankingResponseAvlbl.next(true);
            return of(null);
          }),
          mergeMap(val => {
            if (val && !isBankError) {
              this.storageManagementService.setAppData(SessionConstants.BANKING_DETAILS, val );
              this.isGetBankingResponseAvlbl.next(true);
              return of(val);
            }
            return of(val);
          }), );
      }
    }
  }

  public initializeClaimsPaymentInfo(claims: RelatedClaims[], defaultCaseId: string, isHistorical: boolean): ClaimPaymentInfo[] {
    const claimsPaymentInfo: ClaimPaymentInfo[] = [];
    claims.forEach((claim: RelatedClaims) => {
      const claimPaymentInfo: ClaimPaymentInfo = new ClaimPaymentInfo();
      claimPaymentInfo.claimEventId = claim.claimEventId;
      claimPaymentInfo.sourceSystemId = claim.sourceSystemId;
      claimPaymentInfo.caseId = claim.caseId;
      claimPaymentInfo.fundingMethodCode = claim.fundingMethod;
      claimPaymentInfo.isDefaultCase = (claim.caseId === defaultCaseId);
      claimPaymentInfo.coverageCategory = claim.coverageCategory;
      claimPaymentInfo.claimOnlineStatusCode = claim.claimOnlineStatusCode;
      claimPaymentInfo.claimOnlineSubStatusCode = claim.claimOnlineSubStatusCode;
      claimPaymentInfo.claimStatusCode = claim.claimStatusCode;
      claimPaymentInfo.isHistoricalClaim = isHistorical;
      claimsPaymentInfo.push(claimPaymentInfo);
    });
    return claimsPaymentInfo;
  }

  public fetchPayeeTypeForAllClaims(claimsPaymentInfo: ClaimPaymentInfo[]): Observable<PayeeType[]> {
    this.userData = this.storageManagementService.getAppData(SessionConstants.USER_DATA);
    const payeeTypeResponse: PayeeTypeResponse = new PayeeTypeResponse();
    payeeTypeResponse.payeeTypeDetailsList = [];
    if (this.userData && this.userData.personIds && this.userData.personIds.length > 0) {

      const AAclaimEventIdList: string[] = claimsPaymentInfo.filter((claimPaymentInfo: ClaimPaymentInfo) => claimPaymentInfo.sourceSystemId === 'AA')
        .map((claimPaymentInfo: ClaimPaymentInfo) => claimPaymentInfo.claimEventId);
      if (AAclaimEventIdList && AAclaimEventIdList.length > 0) {
        AAclaimEventIdList.forEach((claimId: string) => {
          const payeeType: PayeeType = new PayeeType();
          payeeType.claimEventId = claimId;
          payeeType.payeeType = null;
          payeeTypeResponse.payeeTypeDetailsList.push(payeeType);
        })
      }
    }
    if (this.userData && this.userData.insuredId) {

      const claimEventIdList: string[] = claimsPaymentInfo.filter((claimPaymentInfo: ClaimPaymentInfo) => claimPaymentInfo.sourceSystemId === 'DCS')
        .map((claimPaymentInfo: ClaimPaymentInfo) => claimPaymentInfo.claimEventId);
      return this.getPayeeType(claimEventIdList).pipe(
        catchError(err => of(payeeTypeResponse)),
        mergeMap((payeeTypeResponses: PayeeTypeResponse) => of((payeeTypeResponses && payeeTypeResponses.payeeTypeDetailsList && payeeTypeResponses.payeeTypeDetailsList.length > 0) ?
              this.concatDCSTHAAPayeeTypeList(payeeTypeResponse.payeeTypeDetailsList, payeeTypeResponses.payeeTypeDetailsList) : [])
        ), );
    }


    return of((payeeTypeResponse && payeeTypeResponse.payeeTypeDetailsList && payeeTypeResponse.payeeTypeDetailsList.length > 0) ? payeeTypeResponse.payeeTypeDetailsList : []);

  }

  private concatDCSTHAAPayeeTypeList(thaaPayeeTypeResponse: PayeeType[], dcsPayeeTypeResponse: PayeeType[]): PayeeType[] {
    if (thaaPayeeTypeResponse && thaaPayeeTypeResponse.length <= 0) {
      return dcsPayeeTypeResponse;
    }
    if (dcsPayeeTypeResponse && dcsPayeeTypeResponse.length <= 0) {
      return thaaPayeeTypeResponse
    }
    if (thaaPayeeTypeResponse && thaaPayeeTypeResponse.length > 0 && dcsPayeeTypeResponse && dcsPayeeTypeResponse.length > 0) {
      return thaaPayeeTypeResponse.concat(dcsPayeeTypeResponse);
    } else {
      return [];
    }
  }


  public addPayeeTypeInClaimPaymentInfo(claimsPaymentInfo: ClaimPaymentInfo[], payeeTypeDetailsList: PayeeType[]): ClaimPaymentInfo[] {
    claimsPaymentInfo.forEach(
      (claimPaymentInfo: ClaimPaymentInfo) => {

          const filteredPayeeTypeDetailsList: PayeeType[] = payeeTypeDetailsList.filter((payeeTypeDetails: PayeeType) => payeeTypeDetails.claimEventId === claimPaymentInfo.claimEventId);
          if (filteredPayeeTypeDetailsList && filteredPayeeTypeDetailsList.length > 0) {
            claimPaymentInfo.payeeType = filteredPayeeTypeDetailsList[0].payeeType;
          }
      });
    return claimsPaymentInfo;
  }

  public checkIfClaimIsFullyInsured(claimPaymentInfo: ClaimPaymentInfo, casesInfo: CaseInfo[]): Observable<ClaimToCaseInfoMapping> {

    if (claimPaymentInfo && (claimPaymentInfo.payeeType && claimPaymentInfo.payeeType.toUpperCase() === AppConstants.CLAIMANT || claimPaymentInfo.payeeType === null)  && claimPaymentInfo.claimStatusCode !== AppConstants.CS_FIRST_NOTICE_CD_08 && (claimPaymentInfo.coverageCategory && claimPaymentInfo.coverageCategory.toUpperCase() === AppConstants.LTD || claimPaymentInfo.coverageCategory && claimPaymentInfo.coverageCategory.toUpperCase() === AppConstants.WD || claimPaymentInfo.coverageCategory && claimPaymentInfo.coverageCategory.toUpperCase() === AppConstants.PFL)) {
      if (claimPaymentInfo.fundingMethodCode && claimPaymentInfo.fundingMethodCode.toUpperCase() === AppConstants.FI) {
        claimPaymentInfo.isClaimFullyInsured = true;
        return of(new ClaimToCaseInfoMapping(claimPaymentInfo, casesInfo));
      } else if (claimPaymentInfo.fundingMethodCode && claimPaymentInfo.fundingMethodCode.toUpperCase() === AppConstants.ASO) {
        const doesProvisionServiceNeedsToBeCalledIndex: number = this.doesProvisionsNeedsToBeCalled(casesInfo, claimPaymentInfo.claimEventId);
        if (doesProvisionServiceNeedsToBeCalledIndex === -1) {
          claimPaymentInfo.isClaimFullyInsured = casesInfo[this.findCaseId(casesInfo, claimPaymentInfo.caseId)].isCaseFullyInsured;
          return of(new ClaimToCaseInfoMapping(claimPaymentInfo, casesInfo));
        } else if (doesProvisionServiceNeedsToBeCalledIndex >= 0) {
          return this.checkASOProvisions(parseInt(claimPaymentInfo.caseId, 10)).pipe(
            mergeMap(
              (isClaimFullyInsured: boolean) => {
                if (isClaimFullyInsured) {
                  casesInfo[doesProvisionServiceNeedsToBeCalledIndex].isCaseFullyInsured = true;
                }
                claimPaymentInfo.isClaimFullyInsured = isClaimFullyInsured;
                return of(new ClaimToCaseInfoMapping(claimPaymentInfo, casesInfo));
              }
            ));
        }
      }
    }
    claimPaymentInfo.isClaimFullyInsured = false;
    return of(new ClaimToCaseInfoMapping(claimPaymentInfo, casesInfo));
  }

  public checkASOProvisions(caseId: number): Observable<boolean> {
    return this.depositService.getPolicyAdminProvisions(caseId, 315).pipe(
      catchError(
        (err) => {
          const policyAdminProvisionsResponse: ProvisionResponse = new ProvisionResponse();
          policyAdminProvisionsResponse.provisionResponseList = [];
          return of(policyAdminProvisionsResponse);
        }
      ),
      mergeMap(
        (policyAdminProvisionsResponse: ProvisionResponse) => {
          if (policyAdminProvisionsResponse && policyAdminProvisionsResponse.provisionResponseList && policyAdminProvisionsResponse.provisionResponseList.length > 0) {
            return of(this.isASOProvisionHasOptionIdOne(policyAdminProvisionsResponse.provisionResponseList));
          }
            return of(false);
        }
      ), );
  }

  public isASOProvisionHasOptionIdOne(provisions: Provision[]): boolean {
    return (provisions.findIndex((provision: Provision) => (provision && (provision.provisionOptionId === '1'))) > -1);
  }

  public saveClaimantPaymentInfoInSession(claimantPaymentInfo: ClaimantPaymentInfo) {
    let activeClaimantPaymentInfo: ClaimantPaymentInfo = this.getClaimantPaymentInfo();
    if (activeClaimantPaymentInfo && activeClaimantPaymentInfo.claimsPaymentInfo && (activeClaimantPaymentInfo.claimsPaymentInfo.length > 0)) {
      if (claimantPaymentInfo && claimantPaymentInfo.claimsPaymentInfo && claimantPaymentInfo.claimsPaymentInfo.length > 0) {
        claimantPaymentInfo.claimsPaymentInfo.forEach(
          (claimantPaymentInfoElem: ClaimPaymentInfo) => {
            activeClaimantPaymentInfo.claimsPaymentInfo.push(claimantPaymentInfoElem);
          }
        );
      }

      activeClaimantPaymentInfo.bankDetails = claimantPaymentInfo.bankDetails;
      activeClaimantPaymentInfo.isBankDetailsExist = claimantPaymentInfo.isBankDetailsExist;
      activeClaimantPaymentInfo.isPaymentApplicable = claimantPaymentInfo.isPaymentApplicable;

    } else if (claimantPaymentInfo && claimantPaymentInfo.claimsPaymentInfo && claimantPaymentInfo.claimsPaymentInfo.length > 0) {
      activeClaimantPaymentInfo = claimantPaymentInfo;
    }

    this.storageManagementService.setSessionData(SessionConstants.CLAIMANT_PAYMENT_INFO_LG, activeClaimantPaymentInfo);
    this.storageManagementService.setSessionData(SessionConstants.IS_CLAIMANT_PAYMENT_INFO_STE_IN_SESSION, true);
    this.isClaimantPaymentInfoSetInSession.next(true);
  }

  public saveHistoricalClaimantPaymentInfoInSession(historicalClaimantPaymentInfo: ClaimantPaymentInfo) {
    let activeClaimantPaymentInfo: ClaimantPaymentInfo = this.getClaimantPaymentInfo();
    if (activeClaimantPaymentInfo && activeClaimantPaymentInfo.claimsPaymentInfo && activeClaimantPaymentInfo.claimsPaymentInfo.length > 0) {
      historicalClaimantPaymentInfo.claimsPaymentInfo.forEach(
        (historicalClaimPaymentInfo: ClaimPaymentInfo) => {
          activeClaimantPaymentInfo.claimsPaymentInfo.push(historicalClaimPaymentInfo);
        }
      );
    } else if (activeClaimantPaymentInfo === null) {
      activeClaimantPaymentInfo = new ClaimantPaymentInfo(historicalClaimantPaymentInfo.claimsPaymentInfo);
    }

    this.storageManagementService.setSessionData(SessionConstants.CLAIMANT_PAYMENT_INFO_LG, activeClaimantPaymentInfo);
    this.storageManagementService.setSessionData(SessionConstants.IS_HISTORICAL_CLAIMANT_PAYMENT_INFO_STE_IN_SESSION, true);
    this.isHistoricalClaimantPaymentInfoSetInSession.next(true);
  }

  createUniqueCasesInfo(claimsPaymentInfo: ClaimPaymentInfo[]): CaseInfo[] {
    const casesInfo: CaseInfo[] = [];
    claimsPaymentInfo.forEach(
      (claimPaymentInfo: ClaimPaymentInfo) => {
        const caseId: string = claimPaymentInfo.caseId;
        const indexOfCase: number = casesInfo.findIndex((caseInfo: CaseInfo) => caseInfo.caseId === caseId);
        if (indexOfCase === -1) {
          const newCaseInfo: CaseInfo = new CaseInfo();
          newCaseInfo.caseId = caseId;
          newCaseInfo.isDefault = claimPaymentInfo.isDefaultCase;
          newCaseInfo.toBeCalledForId = (claimPaymentInfo.fundingMethodCode && claimPaymentInfo.fundingMethodCode.toUpperCase() === AppConstants.ASO) ? claimPaymentInfo.claimEventId : newCaseInfo.toBeCalledForId;
          if (claimPaymentInfo.fundingMethodCode && claimPaymentInfo.fundingMethodCode.toUpperCase() === AppConstants.ASO) {
            casesInfo.push(newCaseInfo);
          }
        }
      }
    );
    return casesInfo;
  }

  doesProvisionsNeedsToBeCalled(casesInfo: CaseInfo[], claimEventId: string): number {
    return casesInfo.findIndex((caseInfo: CaseInfo) => caseInfo.toBeCalledForId === claimEventId);
  }

  public setProvisionsFlag(policyAdminProvisionsResponse?: ProvisionResponse) {
    if (policyAdminProvisionsResponse && policyAdminProvisionsResponse.provisionResponseList && policyAdminProvisionsResponse.provisionResponseList.length > 0) {
      this.storageManagementService.setAppData(SessionConstants.PROVISIONS , policyAdminProvisionsResponse );
    }
    this.storageManagementService.setSessionData(SessionConstants.IS_DEFAULT_PROVISIONS_SET, true);
    this.isProvisionsSet.next(true);
  }

  public findCaseId(casesInfo: CaseInfo[], caseId: string) {
    return casesInfo.findIndex((caseInfo: CaseInfo) => caseInfo.caseId === caseId);
  }

  public initializeClaimantPaymentInfoFlags() {
    if (this.storageManagementService.getSessionData(SessionConstants.IS_CLAIMANT_PAYMENT_INFO_STE_IN_SESSION)) {
      this.isClaimantPaymentInfoSetInSession.next(true);
    }

    if (this.storageManagementService.getSessionData(SessionConstants.IS_HISTORICAL_CLAIMANT_PAYMENT_INFO_STE_IN_SESSION)) {
      this.isHistoricalClaimantPaymentInfoSetInSession.next(true);
    }
  }
}

