import {Component, Input, OnInit, ViewChild, AfterViewInit} from '@angular/core';
import {Router} from '@angular/router';
import {FormBuilder, Validators} from '@angular/forms';
import {ModalService} from '../../../shared/services/modal.service';
import {ConfirmSurgeryServiceService} from '../../../shared/services/confirm-surgery-service.service';
import {StorageManagementService} from '../../../shared/services/storage-management.service';
import {ContentService} from '../../../shared/services/content.service';
import {CustomEventService} from '../../../shared/services/tealium/custom-event.service';
import {SecondaryDateDropdownComponent} from '../../common/molecules/secondary-date-dropdown/secondary-date-dropdown.component';
import {AppConstants, AppLeaveConst} from '../../../shared/constants/app.constants';
import {AppLabelConstants} from '../../../shared/constants/appLabel.constants';
import {SessionConstants} from '../../../shared/constants/session.constants';
import {ErrorConstants} from '../../../shared/constants/error.constants';
import {FormControl, FormGroup} from '@angular/forms';
import {AppLeaveIntakeConstants} from '../../leave-claim-intake-flow/leave-intake.constants';
import {IMyCalendarViewChanged, IMyDateModel, IMyDpOptions} from 'mydatepicker';
import {DatePipe} from '@angular/common';
import { JsonArrayPipe } from 'app/shared/pipes/json-array.pipe';
import { ThaaConfirmationService } from 'app/shared/services/thaa-confirmation.service';
import {NotificationService} from '../../../shared/services/notifications.service';


@Component({
  selector: 'app-disablity-last-day-section-thaa',
  templateUrl: 'disablity-last-day-section-thaa.component.html',
  styleUrls: ['disablity-last-day-section-thaa.component.scss']
})
export class DisablityLastDaySectionThaaComponent implements OnInit, AfterViewInit {
  @Input() newDisabilityDateValue: string;
  @ViewChild('dontKnwFDA', { static: false }) dontKnwFDA;
  @ViewChild('dontKnwNV', { static: false }) dontKnwNV;
  @ViewChild('dontKnwLV', { static: false }) dontKnwLV;
  @ViewChild('dontKnwRTW', { static: false }) dontKnwRTW;
  @ViewChild(SecondaryDateDropdownComponent, { static: false }) DateLabelValue: SecondaryDateDropdownComponent;
  public appLabelConstants = AppLabelConstants;
  public errorConstants = ErrorConstants;
  public showDiv = 0;
  public lastDayWorkedFormThaa: FormGroup;
   hours;
   showHoursError = true;
   claimView;
   claimDetail;
   claimEventIdForDisability;
   aetnaUpdateDisablityRequest;
  public claimESDForDisability;
   pageId: string;
   owscContent;
  public isLDWGreater = true;
  public isLDWDateEmpty: boolean = true;
  public isReasonEmpty: boolean = true;
  public questionList = [];
  public paragraph_1 = '';
  public qestion_1 = '';
  public question_2 = '';
  public question_3 = '';
  public question_4 = '';
  public question_5 = '';
  public question_6 = '';
  public question_7 = '';
  public question_7a = '';
  public question_8 = '';
  public question_9 = '';
  public question_10 = '';
  public question_checkbox = '';
   isDisabilityLDW;
  public disabilityLastDayFormValue;
  public radioValueVar: string;
   LWM;
   LWD;
   LWY;
  public LWDDate;
  public gapQuestionValue;
   claimDisabilityDate;
  public setDefalutvalue = true;
  public claimStatusDisability;
   incerementOperatorval = 0;
   displayError: number;
  public personId;
  public srcSysCd;
  public currentClaim;
  public absentDate;
  public nextVisitDate;
  public newDateDisability;
  public lastVisitDate;
  public rtwDate;
  public fullRadial = 'Full';
  public partialRadial = 'Partial';
  public isRTWDateGreater: boolean = true;
  public isFDADateGreater: boolean = true;
  public isFDADateEmpty: boolean = true;
  public currentDate;
  public isAbsenceDateGreater: boolean = true;
  public isAbsenceDateEmpty: boolean = true;
  public isLVDateEmpty: boolean = true;
  public isNVDateEmpty: boolean = true;
  public isRTWDateEmpty: boolean = true;
  public startHoursOptions = [];
  public startHoursOptionsSchedTime = [];
  public startHoursOptionsActTime = [];
  public startMinutesOptions = [];
  public startMinutesOptionsSchedTime = [];
  public startMinutesOptionsActTime = [];
  public absenceHoursOptions = [];
  public absenceMinOptions = [];
  public amPmOptions = [];
  public showQs: boolean = false;
  public objTransform: JsonArrayPipe = new JsonArrayPipe();
  public absenceHourRequest = {
    hour: 'HH',
    min: 'MM',
    unit: 'AM/PM',
    absenceHour: 'hours',
    absenceMin: 'min',
    hourSchedTime: 'hours',
    minSchedTime: 'min',
    hourActTime: 'hours',
    minActTime: 'min'
  };
  public gapQuestions = [{

    notSelected: '--select one--',
    personalTime: 'Personal time',
    outSickTime: 'Sick time',
    notSched: 'Not scheduled to work',
    onLeave: 'Already on a leave of absence',
    laidOff: 'Laid off',
    other: 'Other'

  }];
  public isDateEmpty: boolean = true;
  public disableMinutes: boolean = false;
  public showErrorForTime: boolean = false;
  public appConstant = AppLeaveIntakeConstants;
  public full;
  public lwd;
  public fda;
  public partial;
  public stopButton: boolean = false;
  public insuredLeaves;
  public hasLeave: boolean = false;
  public radioValueVarLDW: string;
  public showSchedQ: boolean = false;
  public other = 'Other';
  public freeTextOther: boolean = false;
  public isFull: boolean = false;
  public isPartial: boolean = false;
  public showLDWError: boolean = false;
  public showLDWErrorGreater: boolean = false;
  public isRadioValueEmpty: boolean = false;
  public checkBoxValueFDA: boolean = false;
  public checkBoxValueLV: boolean = false;
  public checkBoxValueNV: boolean = false;
  public checkBoxValueRTW: boolean = false;
  public disableSpinner: string = 'false';
  public disableButton: boolean = false;
  public showErrorForAbsenceTime: boolean = true;
  public showErrorForSchedTime: boolean = false;
  public showErrorForActTime: boolean = false;
  public thereIsGap: boolean = false;
  public radioReqVal: string = '';
  public datePickerOptions: IMyDpOptions = {
    dateFormat: 'mm/dd/yyyy',
    selectorHeight: 'auto',
    dayLabels: {su: 'Su', mo: 'Mo', tu: 'Tu', we: 'We', th: 'Th', fr: 'Fr', sa: 'Sa'},
    showTodayBtn: false,
    firstDayOfWeek: 'su',
    monthSelector: false,
    yearSelector: false,
    minYear: 1970,
    showClearDateBtn: false,
    editableDateField: false,
    openSelectorOnInputClick: true,
    sunHighlight: false,
    markCurrentDay: false,
    height: '42px'
  };
  public Day = 'DAY';
  public btnEventId: string = '';
  public userData;
  private months = {
    1: 'January',
    2: 'February',
    3: 'March',
    4: 'April',
    5: 'May',
    6: 'June',
    7: 'July',
    8: 'August',
    9: 'September',
    10: 'October',
    11: 'November',
    12: 'December'
  };

  constructor(private router: Router,
              private _formBuilder: FormBuilder, private modalService: ModalService,
              private _updateclaimactivity: ConfirmSurgeryServiceService,
              public datepipe: DatePipe,
              private storageMgmtService: StorageManagementService,
              private contentService: ContentService,
              private storageManagementService: StorageManagementService,
              private customEventService: CustomEventService,
              private notificationService: NotificationService,
              private thaaConfirmationService: ThaaConfirmationService) {
  }

  ngOnInit() {
    if (JSON.parse(this.storageManagementService.retrieveSessionObject(SessionConstants.APP_DATA)).appData.userData) {
      this.userData = JSON.parse(this.storageManagementService.retrieveSessionObject(SessionConstants.APP_DATA)).appData.userData;
    }
    this.dropDownMapper();
    this.setForm();
    sessionStorage.removeItem(SessionConstants.GET_FIRST_VAL);
    //OWCS service Integration
    this.fetchOWCSContent();
    this.checkClaimDetails();
    this.hoursWLD();
    this.questionList.push('question_1', 'question_6', 'question_8', 'question_9', 'question_10');
    this.checkLeaveClaims();
  }

  ngAfterViewInit() {
    // super.ngAfterViewInit();
    this.modifyHeaderNavigation();
    const styleElem = document.head.appendChild(document.createElement('style'));
    styleElem.innerHTML = 'span.mydpicon.icon-mydpcalendar::before {font-family: inherit!important;}';
  }

  modifyHeaderNavigation() {
    const monthSelect = (document.getElementsByClassName('headerlabelbtn')[0]) as HTMLElement;
    const yearSelect = (document.getElementsByClassName('headerlabelbtn')[1]) as HTMLElement;
    if (monthSelect && yearSelect) {
      monthSelect.innerHTML = monthSelect.innerHTML + ' ' + yearSelect.innerHTML;
    }
  }
  checkClaimDetails() {
    this.claimView = sessionStorage.getItem(SessionConstants.CLAIM_VIEW);
    this.claimDetail = sessionStorage.getItem(SessionConstants.CLAIMDETAILS);
    if (JSON.parse(this.storageMgmtService.retrieveSessionObject(SessionConstants.CUR_CLAIM)).curClaim) {
      this.currentClaim = JSON.parse(this.storageMgmtService.retrieveSessionObject(SessionConstants.CUR_CLAIM)).curClaim;
    }
    this.claimEventIdForDisability = this.currentClaim.claimEventId;
    this.claimDisabilityDate = this.currentClaim.disabilityDate;
    this.claimESDForDisability = this.currentClaim.disabilityDate;
    this.claimStatusDisability = this.currentClaim.claimStatus;
    this.personId = this.currentClaim.personId;
    this.srcSysCd = this.currentClaim.sourceSystemId;
    this.isDisabilityLDW = sessionStorage.getItem(SessionConstants.IS_DIS_LDW);
  }

  /**
   * @desc in this function we check if case associated to claim has leave associated i.e. 806/4 provision
   * @param workPlan  whether its full or partial
   */
  checkLeaveClaims() {
    if (this.userData && this.userData.caseId) {
      this.notificationService.policyAdminLeaveService(this.userData.caseId).subscribe(
        val => { // claim realated with leave or not
          if (val) {
            for (let i = 0; i < val.leaveResponseList.length; i++) {
              if (val.leaveResponseList[i].provisionId === AppConstants.PROVISION_ID_806 &&
                val.leaveResponseList[i].provisionOptionId === AppConstants.PROVISION_OPTION_ID_4) { // case related with leave or not
                this.hasLeave = true;
              } else {
                this.hasLeave = false;
              }
            }
            //this.leaveExistsCheck();
          }
        },
        (error) => {
          console.log(AppConstants.ERROR + error);
          this.hasLeave = false;
          //this.leaveExistsCheck();
        }
      );
    }
  }

  tagErrors(elementId, errorMsg) {
    if (errorMsg) {
      this.customEventService.formFieldValidationErrors(['' + elementId], ['' + errorMsg]);
    }
  }

  tagErrorOther(formValue, error) {
    if (formValue === '') {
      error = this.lastDayWorkedFormThaa.controls['otherReason'].setErrors({otherReasonError: true});
    }
  }

  tagErrorsGap(formValue, error) {
    if (formValue === 'notSelected') {
      error = this.lastDayWorkedFormThaa.controls['gapList'].setErrors({gapList: true})
    }
  }

  dropDownMapper() {
    const filteredArray = [];
    this.appLabelConstants.REASON_OUT.forEach(element => {
      filteredArray.push(element.personalTime)
    });
    this.gapQuestions = filteredArray;
  }

  // Date from Component
  getDateFromComponent() {
    const i = 0;
    this.incerementOperatorval++;
    const getFirstValue = sessionStorage.getItem(SessionConstants.GET_FIRST_VAL);
    if (getFirstValue) {
      this.DateLabelValue.getDate();
      if (this.disabilityLastDayFormValue) {
        this.LWD = this.disabilityLastDayFormValue.day;
      }
      const date = new Date(this.LWDDate);
      if (this.LWD.toUpperCase() === this.Day) {
        this.isLDWGreater = true;
      }
      this.getDefaultValue();
      this.DateLabelValue.getDate();
      this.LWM = this.disabilityLastDayFormValue.month;
      this.LWD = this.disabilityLastDayFormValue.day;
      this.LWY = this.disabilityLastDayFormValue.year;

      const newDisabilityServiceValue = new Date(this.claimESDForDisability);
      const LWDDate = this.LWM + '/' + this.LWD + '/' + this.LWY;
      const LDWDatevalue = new Date(LWDDate);
      const newDisabilityDateValue = new Date(this.newDisabilityDateValue);

      if (this.LWD.toUpperCase() !== this.Day && LDWDatevalue > newDisabilityServiceValue && this.isDisabilityLDW !== AppConstants.IS_DIS_LDW) {
        this.isLDWGreater = false;
        this.setDefalutvalue = this.isLDWGreater;
        this.tagErrors(ErrorConstants.LAST_DAY_OF_WPRK, ErrorConstants.LAST_DAY_WORK_MUST_BE_ON_OR_BEFORE);
      } else if (LDWDatevalue > newDisabilityDateValue) {
        this.isLDWGreater = false;
        this.setDefalutvalue = this.isLDWGreater;
        this.tagErrors(ErrorConstants.LAST_DAY_OF_WPRK, ErrorConstants.LAST_DAY_WORK_MUST_BE_ON_OR_BEFORE);
      } else {
        this.isLDWGreater = true;
      }

    }
  }

  getDefaultValue() {
    const i = 0;
    this.incerementOperatorval++;
    const getFirstValue = sessionStorage.getItem(SessionConstants.GET_FIRST_VAL);

    if ((this.incerementOperatorval >= 2 && getFirstValue) || this.displayError === 1) {
      this.DateLabelValue.getDate();
      if (this.disabilityLastDayFormValue === undefined || this.disabilityLastDayFormValue && this.disabilityLastDayFormValue.day.toUpperCase() === this.Day) {
        this.setDefalutvalue = false;
        this.tagErrors(ErrorConstants.LAST_DAY_OF_WPRK, ErrorConstants.ENTER_VALID_DATE);
      } else {
        this.setDefalutvalue = this.isLDWGreater;
      }
    }
  }


  btnNextClicked() {
    this.customEventService.trackFormButton(this.btnEventId);
    if (this.showDiv > 0) {
      this.validateStartTime();
    }

    if (this.displayError !== 2) {
      this.displayError = 1;
    }

    //claimESDForDisability checks old disability date in yes flow
    //newDateDisability checks for the new disability date in the no I have a new date flow
    this.newDateDisability = this.newDisabilityDateValue ? new Date(this.newDisabilityDateValue) : '';
    const newDisabilityServiceValue = new Date(this.claimESDForDisability);
    if (this.showDiv === 0 && !this.lastDayWorkedFormThaa.value.datePickerLDWFC) {
      this.isLDWDateEmpty = false;
      this.isLDWGreater = true;
      this.showLDWError = true;
      this.showLDWErrorGreater = false;
      this.showErrorCalendarLabel();
      // this.tagErrors(ErrorConstants.LAST_DAY_OF_WPRK, ErrorConstants.PLEASE_ENTER_LAST_DAY_WORK);
      this.showDiv--;
    } else if ((this.newDateDisability) && this.LWDDate > this.newDateDisability) {
      this.isLDWGreater = false;
      this.isLDWDateEmpty = true;
      this.showLDWError = false;
      this.showLDWErrorGreater = true;
      this.showErrorCalendarLabel();
      this.showDiv--;
    } else if ((!this.newDateDisability) && this.LWDDate > newDisabilityServiceValue) {
      this.isLDWGreater = false;
      this.isLDWDateEmpty = true;
      this.showLDWError = false;
      this.showLDWErrorGreater = true;
      this.showErrorCalendarLabel();
      this.showDiv--;
    } else {
      this.isLDWDateEmpty = true;
      this.isLDWGreater = true;
      this.btnEventId = this.question_2;
      this.showLDWError = false;
      this.showLDWErrorGreater = false;
      this.showErrorCalendarLabel();
    }
    if (this.showDiv === 1) {
      this.lastDayWorkedFormThaa.get('radioValue').setValue('');
      this.lastDayWorkedFormThaa.controls['radioValue'].markAsTouched();
      if (!this.radioValueVarLDW) {
        this.showDiv--;
        this.isRadioValueEmpty = true;
        this.stopButton = true;
      } else if (this.radioValueVarLDW === this.fullRadial) {
        this.lastDayWorkedFormThaa.get('radioValue').setValue(this.fullRadial);
        this.isFull = true;
        this.isPartial = false;
        this.isRadioValueEmpty = false;
        this.lastDayWorkedFormThaa.controls['radioValue'].markAsUntouched();
        this.showDiv++;
        this.showErrorForTime = false;
        this.stopButton = false;
        this.btnEventId = this.question_5;
      } else if (this.radioValueVarLDW === this.partialRadial) {
        this.lastDayWorkedFormThaa.get('radioValue').setValue(this.partialRadial);
        this.isPartial = true;
        this.isFull = false;
        this.lastDayWorkedFormThaa.controls['radioValue'].markAsUntouched();
        this.isRadioValueEmpty = false;
        this.stopButton = false
        this.showDiv++;
        this.btnEventId = this.hasLeave ? this.question_3 : this.question_4;
      }
    }

    if (this.showDiv === 2) {
      if (this.isPartial) {
        this.isFull = false;
        this.isPartial = true;
        this.isRadioValueEmpty = false;
        this.showErrorForTime = false;
        this.showErrorForSchedTime = false;
        this.showErrorForActTime = false;
      } else {
        this.showErrorForTime = false;
        this.showErrorForSchedTime = false;
        this.showErrorForActTime = false;
        this.isFull = true;
        this.isPartial = false;
        this.isRadioValueEmpty = false;
        this.showDiv++;
      }
    }

    if (this.showDiv === 3 && (this.isPartial && this.hasLeave)) {
      if ((this.absenceHourRequest.hour === 'HH' || this.absenceHourRequest.hour === 'hours') || (this.absenceHourRequest.min === 'MM' || this.absenceHourRequest.min === 'min')
        || this.absenceHourRequest.unit === 'AM/PM') {
        this.showErrorForTime = true;
        this.showErrorForActTime = true;
        this.showDiv--;
      } else {
        this.showErrorForTime = false;
        this.showErrorForActTime = false;
        this.btnEventId = this.question_5;
      }
    } else if (this.showDiv === 3 && (this.isPartial && !this.hasLeave)) {
      this.showErrorForTime = false;
      this.showErrorForActTime = false;
      if ((this.absenceHourRequest.hourSchedTime === 'HH' || this.absenceHourRequest.hourSchedTime === 'hours') || (this.absenceHourRequest.minSchedTime === 'MM' || this.absenceHourRequest.minSchedTime === 'min')) {
        this.showErrorForTime = true;
        this.showErrorForSchedTime = true
        this.showDiv--;
      } else {
        this.showErrorForTime = false;
        this.showErrorForActTime = false;
        this.btnEventId = this.question_5;
      }
    }
    if (this.showDiv === 4) {
      if ((this.absenceHourRequest.hourActTime === 'HH' || this.absenceHourRequest.hourActTime === 'hours') || (this.absenceHourRequest.minActTime === 'MM' || this.absenceHourRequest.minActTime === 'min')) {
        this.showErrorForActTime = true;
        this.showDiv--;
      } else {
        this.showErrorForActTime = false;
        this.btnEventId = this.question_6;
      }
    }
    if (this.showDiv === 5) {
      if (!this.lastDayWorkedFormThaa.value.datePickerAbsent && !this.checkBoxValueFDA) {
        this.isAbsenceDateEmpty = false;
        this.showErrorCalendarLabel();
        this.showDiv--;
      } else {
        this.isAbsenceDateEmpty = true;
        this.btnEventId = this.question_8;
      }
    }
    if (this.showDiv === 5 && this.checkBoxValueFDA) {
      this.thereIsGap = false;
    }
    if ((this.showDiv === 5 && !this.checkBoxValueFDA) && (this.lastDayWorkedFormThaa.value.datePickerLDWFC.formatted && this.lastDayWorkedFormThaa.value.datePickerAbsent.formatted)) {
      if (this.checkLwdWithFda(this.lastDayWorkedFormThaa.value.datePickerLDWFC.formatted, this.lastDayWorkedFormThaa.value.datePickerAbsent.formatted)) {
        this.thereIsGap = false;
      } else if (this.lastDayWorkedFormThaa.value.datePickerLDWFC.formatted !== this.lastDayWorkedFormThaa.value.datePickerAbsent.formatted) {
        this.thereIsGap = true;
        this.isAbsenceDateEmpty = true;
        this.btnEventId = this.question_7;
      } else {
        this.thereIsGap = true;
        this.isAbsenceDateEmpty = false;
      }
    }


    this.gapQuestionValue = this.lastDayWorkedFormThaa.value.gapList;
    if (this.showDiv === 6 && this.thereIsGap && ((this.lastDayWorkedFormThaa.value.gapList === null || this.lastDayWorkedFormThaa.value.gapList === '') || this.gapQuestionValue === 'notSelected')) {
      this.isDateEmpty = false;
      this.lastDayWorkedFormThaa.get('gapList').setValue('notSelected');
      this.lastDayWorkedFormThaa.controls['gapList'].markAsTouched();
      this.tagErrorsGap(this.lastDayWorkedFormThaa.controls['gapList'].value, this.lastDayWorkedFormThaa.controls['gapList'].errors)
      this.showDiv--;
    } else if (this.showDiv === 6 && this.lastDayWorkedFormThaa.value.gapList === 'Other') {
      this.freeTextOther = true;
      if ((this.lastDayWorkedFormThaa.value.otherReason === null || this.lastDayWorkedFormThaa.value.otherReason === '')) {
        this.lastDayWorkedFormThaa.get('otherReason').setValue('');
        this.lastDayWorkedFormThaa.controls['otherReason'].markAsTouched();
        this.lastDayWorkedFormThaa.controls['otherReason'].markAsDirty();
        this.tagErrorOther(this.lastDayWorkedFormThaa.controls['otherReason'].value, this.lastDayWorkedFormThaa.controls['otherReason'].errors);
        this.isReasonEmpty = false;
        this.showDiv--;
      } else if (this.lastDayWorkedFormThaa.value.otherReason && this.lastDayWorkedFormThaa.value.otherReason !== 'notSelected') {
        this.isDateEmpty = true;
        this.isReasonEmpty = true;
        this.freeTextOther = true;
      }
    }

    this.lastVisitDate = this.lastDayWorkedFormThaa.value.datePickerLastVisit;
    if (((this.showDiv === 6 && !this.thereIsGap) || (this.showDiv === 7 && this.thereIsGap)) && (!this.lastDayWorkedFormThaa.value.datePickerLastVisit && !this.checkBoxValueLV)) {
      this.isLVDateEmpty = false;
      this.showErrorCalendarLabel();
      this.showDiv--;
    } else {
      this.isLVDateEmpty = true;
      if ((this.showDiv === 6 && this.thereIsGap)) {
         this.btnEventId = this.question_8;
      } else if ((this.showDiv >= 6 && !this.thereIsGap) || this.showDiv >= 7) {
        this.btnEventId = this.question_9
      }
    }


    this.nextVisitDate = this.lastDayWorkedFormThaa.value.datePickerNextVisit;
    if ((this.showDiv === 7 && !this.thereIsGap || this.showDiv === 8 && this.thereIsGap) && (!this.lastDayWorkedFormThaa.value.datePickerNextVisit && !this.checkBoxValueNV)) {
      this.isNVDateEmpty = false;
      this.showErrorCalendarLabel();
      this.showDiv--;
    } else {
      this.isNVDateEmpty = true;
    }

    this.rtwDate = this.lastDayWorkedFormThaa.value.datePickerRTW;
    if ((this.showDiv === 8 && !this.thereIsGap || this.showDiv === 9 && this.thereIsGap) && (!this.lastDayWorkedFormThaa.value.datePickerRTW && !this.checkBoxValueRTW)) {
      this.isRTWDateEmpty = false;
      this.showErrorCalendarLabel();
      this.showDiv--;
    } else {
      this.isRTWDateEmpty = true;
    }

    this.showDiv++;
  }


  showErrorCalendarLabel() {
    for (let index = 0; index < this.questionList.length; index++) {
      if (document.getElementById(this.questionList[index])) {
        const element = document.getElementById(this.questionList[index]).getElementsByClassName('selectiongroup');
        element[0].classList.remove('incorrectDate');
        if (((this.questionList[index] === 'question_1') && !this.isLDWDateEmpty || !this.isLDWGreater) || ((this.questionList[index] === 'question_6') && !this.isAbsenceDateEmpty)
          || ((this.questionList[index] === 'question_8') && !this.isLVDateEmpty) || ((this.questionList[index] === 'question_9') && !this.isNVDateEmpty) ||
          ((this.questionList[index] === 'question_10') && !this.isRTWDateEmpty)) {
          element[0].classList.add('incorrectDate');
        }
      }
    }
  }

  checkLwdWithFda(lwdDate, fdaDate) {
    this.lwd = new Date(lwdDate);
    this.fda = new Date(fdaDate);
    const diff = Math.floor((Date.UTC(this.lwd.getFullYear(), this.lwd.getMonth(), this.lwd.getDate()) - Date.UTC(this.fda.getFullYear(), this.fda.getMonth(), this.fda.getDate()) ) / (1000 * 60 * 60 * 24));
    if (diff < 2 && diff > -2) {
      return true;
    } else {
      return false;
    }
  }


  showErrorCalLabelOnBlur(elementId) {
    if (elementId) {
      const element = document.getElementById(elementId).getElementsByClassName('selectiongroup');
      element[0].classList.remove('incorrectDate');
      if ((elementId === 'question_1') && !this.isLDWDateEmpty || (elementId === 'question_1') && !this.isLDWGreater) {
        element[0].classList.add('incorrectDate');
      }
      if ((elementId === 'question_6') && !this.isAbsenceDateEmpty) {
        element[0].classList.add('incorrectDate');
      }
      if ((elementId === 'question_8') && !this.isLVDateEmpty) {
        element[0].classList.add('incorrectDate');
      }
      if ((elementId === 'question_9') && !this.isNVDateEmpty) {
        element[0].classList.add('incorrectDate');
      }
      if ((elementId === 'question_10') && !this.isRTWDateEmpty) {
        element[0].classList.add('incorrectDate');
      }
      if ((elementId === 'question_7a') && !this.isDateEmpty) {
        element[0].classList.add('incorrectDate');
      }
    }
  }



  hoursWLD() {
    this.hours = [];
    for (let i = 1; i <= 24; i++) {
      this.hours.push(i);
    }
    return this.hours;
  }

  onHourChange() {
    if (this.lastDayWorkedFormThaa.value.hoursWorked === AppConstants.HOURS) {
      this.showHoursError = false;
      this.tagErrors(ErrorConstants.HOURS_WORKED, ErrorConstants.PLEASE_ENTER_UR_HRS_WRKD);
    } else {
      this.showHoursError = true;
    }
  }

  submitForm() {
    const newAbsentDate = !sessionStorage.getItem(SessionConstants.NEW_DIS_DATE) ? '' : sessionStorage.getItem(SessionConstants.NEW_DIS_DATE);
    const newfirstAbsentDt = newAbsentDate ? this.datepipe.transform(newAbsentDate, 'MM/dd/yyyy') : '';
    const lastDayWorkDt = this.LWDDate ? this.datepipe.transform(this.LWDDate, 'MM/dd/yyyy') : '';
    const formData = {
      ['LDW/FDA Info']: '',
      [this.qestion_1]: lastDayWorkDt,
      [this.question_2]: this.radioValueVarLDW,
      [this.question_3]: this.absenceHourRequest.hour === 'HH' ? '' : this.absenceHourRequest.hour + ':' + this.absenceHourRequest.min + ' ' + this.absenceHourRequest.unit,
      [this.question_4]: this.absenceHourRequest.hourSchedTime === 'hours' ? '' : this.absenceHourRequest.hourSchedTime + ' : ' + this.absenceHourRequest.minSchedTime,
      [this.question_5]: this.absenceHourRequest.hourActTime + ' : ' + this.absenceHourRequest.minActTime,
      [this.question_6]: !this.lastDayWorkedFormThaa.value.datePickerAbsent ? this.question_checkbox : this.absentDate,
      [this.question_7]: this.gapQuestionValue ? (this.gapQuestionValue === 'Other' ? this.lastDayWorkedFormThaa.value.otherReason : this.gapQuestionValue) : '',
      [this.question_8]: !this.lastDayWorkedFormThaa.value.datePickerLastVisit ? this.question_checkbox : this.lastDayWorkedFormThaa.value.datePickerLastVisit.formatted,
      [this.question_9]: !this.lastDayWorkedFormThaa.value.datePickerNextVisit ? this.question_checkbox : this.lastDayWorkedFormThaa.value.datePickerNextVisit.formatted,
      ['RTW Info']: '',
      [this.question_10]: !this.lastDayWorkedFormThaa.value.datePickerRTW ? this.question_checkbox : this.rtwDate

    }
    if (newfirstAbsentDt) {
      formData[this.appLabelConstants.NEW_DIS_DATE_QUESTION] = newfirstAbsentDt;
    }
    if (this.radioReqVal && this.radioReqVal.toLowerCase() === 'full') {
      delete formData[this.question_3];
      delete formData[this.question_4];
    }
    if (this.radioReqVal && this.radioReqVal.toLowerCase() === 'partial') {
      if (!formData[this.question_4]) {
        delete formData[this.question_4];
      }
      if (!formData[this.question_3]) {
        delete formData[this.question_3];
      }
    }
    if (!this.gapQuestionValue) {
      delete formData[this.question_7];
    }
    // this.showErrorCalendarLabel();
    const formDataTranform = this.objTransform.transform(formData);


    if ((this.showDiv === 8 && !this.thereIsGap || this.showDiv === 9 && this.thereIsGap) && (!this.lastDayWorkedFormThaa.value.datePickerRTW && !this.checkBoxValueRTW)) {
      this.isRTWDateEmpty = false;
      this.showErrorCalendarLabel();
      this.disableButton = false;
      this.disableSpinner = 'true';
      return false;
    } else {
      this.isRTWDateEmpty = true;
      const formDataRequest =  {
        personId: this.personId,
        claimId:  this.claimEventIdForDisability,
        header: newfirstAbsentDt ? AppConstants.NEW_DIS_DATE_HEADER : AppConstants.DIS_LAST_DAY_HEADER,
        lastDayWorked: lastDayWorkDt,
        firstDateAbsent: this.absentDate ? this.absentDate : '',
        additionalComments: formDataTranform
      }
      if (newfirstAbsentDt) {
        formDataRequest['newfirstAbsentDt'] = newfirstAbsentDt;
      }
      this.disableButton = true;
      this.thaaConfirmationService.thaaConfirmation(formDataRequest).subscribe(data => {
        this.disableSpinner = 'false';
        if (data && data.statusCode === AppConstants.STATUS_CD_0) {
          this.storageManagementService.setAppData(SessionConstants.RELOADCLAIMS , true);
          this.router.navigate([AppLabelConstants.URL_DIS_THANK_U]);
        }
      }, error => {
        this.disableSpinner = 'true';
        this.disableButton = false;

      });
    }
  }





  onRadioClick(event, radioText) {
    if (event) {
      event.preventDefault();
    }
    this.radioValueVarLDW = sessionStorage.getItem(SessionConstants.RADIO_VALUE);
    if (radioText === this.fullRadial) {
      this.showDiv = 1;
      this.showErrorForTime = false;
      this.absenceHourRequest.hour = 'HH';
      this.absenceHourRequest.min = 'MM';
      this.absenceHourRequest.unit = 'AM/PM';
      this.absenceHourRequest.hourActTime = 'hours';
      this.absenceHourRequest.minActTime = 'min';
      this.absenceHourRequest.hourSchedTime = 'hours';
      this.absenceHourRequest.minSchedTime = 'min';
      this.lastDayWorkedFormThaa.controls['radioValue'].setValue('Full');
      this.lastDayWorkedFormThaa.controls['datePickerAbsent'].setValue('');
      this.lastDayWorkedFormThaa.value.datePickerAbsent = null;
      this.lastDayWorkedFormThaa.controls['datePickerRTW'].setValue('');
      this.lastDayWorkedFormThaa.value.datePickerRTW = null;
      this.lastDayWorkedFormThaa.value.datePickerLastVisit = null;
      this.lastDayWorkedFormThaa.value.datePickerNextVist = null;
      this.lastDayWorkedFormThaa.controls['datePickerLastVisit'].setValue('');
      this.lastDayWorkedFormThaa.controls['datePickerNextVisit'].setValue('');
      this.gapQuestionValue = null;
      this.lastDayWorkedFormThaa.value.gapList = null;
      this.lastDayWorkedFormThaa.controls['gapList'].setValue(null);
      this.lastDayWorkedFormThaa.controls['otherReason'].setValue('');
      this.lastDayWorkedFormThaa.value.otherReason = null;
      this.isDateEmpty = true;
      this.checkBoxValueFDA = false;
      this.checkBoxValueLV = false;
      this.checkBoxValueRTW = false;
      this.checkBoxValueNV = false;
      this.freeTextOther = false;
      this.showQs = false;
      this.showSchedQ = true;
      this.stopButton = false;
      this.radioValueVarLDW = this.fullRadial;
      this.isFull = true;
      this.isPartial = false;
    } else if ( radioText === this.partialRadial) {
      this.showDiv = 1;
      this.showErrorForTime = false;
      this.absenceHourRequest.hour = 'HH';
      this.absenceHourRequest.min = 'MM';
      this.absenceHourRequest.unit = 'AM/PM';
      this.absenceHourRequest.hourActTime = 'hours';
      this.absenceHourRequest.minActTime = 'min';
      this.absenceHourRequest.hourSchedTime = 'hours';
      this.absenceHourRequest.minSchedTime = 'min';
      this.lastDayWorkedFormThaa.controls['radioValue'].setValue('Partial');
      this.lastDayWorkedFormThaa.controls['datePickerAbsent'].setValue('');
      this.lastDayWorkedFormThaa.value.datePickerAbsent = null;
      this.lastDayWorkedFormThaa.value.datePickerLastVisit = null;
      this.lastDayWorkedFormThaa.value.datePickerNextVist = null;
      this.lastDayWorkedFormThaa.controls['datePickerRTW'].setValue('');
      this.lastDayWorkedFormThaa.controls['datePickerLastVisit'].setValue('');
      this.lastDayWorkedFormThaa.controls['datePickerNextVisit'].setValue('');
      this.lastDayWorkedFormThaa.value.datePickerRTW = null;
      this.gapQuestionValue = null;
      this.lastDayWorkedFormThaa.controls['gapList'].setValue(null);
      this.lastDayWorkedFormThaa.controls['otherReason'].setValue('');
      this.lastDayWorkedFormThaa.value.gapList = null;
      this.lastDayWorkedFormThaa.value.otherReason = null;
      this.freeTextOther = false;
      this.isDateEmpty = true;
      this.showQs = true;
      this.radioValueVarLDW = this.partialRadial;
      this.checkBoxValueFDA = false;
      this.checkBoxValueLV = false;
      this.checkBoxValueRTW = false;
      this.checkBoxValueNV = false;
      this.stopButton = false;
      this.isFull = false;
      this.isPartial = true;
    }
    this.btnEventId = this.question_2;
    this.customEventService.trackRadioButton(this.btnEventId, radioText);
    if (!this.radioValueVarLDW) {
      this.isRadioValueEmpty = true;
    } else if (this.radioValueVarLDW === 'full') {
      this.isFull = true;
      this.isRadioValueEmpty = false;
    } else if (this.radioValueVarLDW === 'partial') {
      this.isPartial = true;
      this.isRadioValueEmpty = false;
    }
  }

  getGapList($event) {
    this.gapQuestionValue = $event.target.value;
    if ($event.target.value === 'notSelected') {
      this.isDateEmpty = false;
      this.tagErrorsGap(this.lastDayWorkedFormThaa.controls['gapList'].value, this.lastDayWorkedFormThaa.controls['gapList'].errors)
    }
    if ($event.target.value === this.other) {
      this.freeTextOther = true;
    } else {
      this.freeTextOther = false;
      this.setOtherReason($event.target.value);
    }
    if (this.gapQuestionValue === null && this.setOtherReason($event.target.value) === null) {
      this.isDateEmpty = false;
    } else {
      this.isDateEmpty = true;
    }
  }


  onclickCancel() {
    this.modalService.openModal(AppConstants.CANCEL_MODAL);
  }

  setForm() {
    this.lastDayWorkedFormThaa = this._formBuilder.group({
      hoursWorked: ['Hours'],
      radioValue: new FormControl(),
      personalTime: new FormControl(),
      outSickTime: new FormControl(),
      notSched: new FormControl(),
      onLeave: new FormControl(),
      laidOff: new FormControl(),
      other: new FormControl(),
      unit: new FormControl(),
      actHours: new FormControl(),
      actMinutes: new FormControl(),
      schedHourFCN: new FormControl(),
      schedMinutesFCN: new FormControl(),
      absenceHours: new FormControl(),
      absenceMinutes: new FormControl(),
      gapList: new FormControl(),
      otherReason: new FormControl(),
      datePickerLDWFC: new FormControl(),
      datePickerAbsent: new FormControl(),
      datePickerLastVisit: new FormControl(),
      datePickerNextVisit: new FormControl(),
      datePickerRTW: new FormControl(),

    });
    this.amPmOptions = ['AM', 'PM'];
    for (let i = 0; i < 60; i++) {
      this.startMinutesOptions[i] = (i < 10 ? '0' + `${i}` : `${i}`);
    }
    for (let i = 0; i < 12; i++) {
      this.startHoursOptions[i] = `${i + 1 }`;
    }
    for (let i = 0; i < 60; i++) {
      this.startMinutesOptionsSchedTime[i] = i < 2 ? `${i}` + ' min' : `${i}` + ' mins';
      this.startMinutesOptionsActTime[i] = i < 2 ? `${i}` + ' min' : `${i}` + ' mins';
    }
    for (let i = 0; i < 24; i++) {
      this.startHoursOptionsSchedTime[i] = i === 1 ? `${i}` + ' hour' : `${i}` + ' hours';
      this.startHoursOptionsActTime[i] = i === 1 ? `${i}` + ' hour' : `${i}` + ' hours';
    }
    for (let i = 0; i <= 24; i++) {
      this.absenceHoursOptions[i] = i === 1 ? `${i}` + ' hour' : `${i}` + ' hours';
    }
    for (let i = 0; i < 60; i++) {
      this.absenceMinOptions[i] = `${i}` + ' min';
    }
  }


  startHoursSelectedAbStartH(value) {
    const selectedValue = (this.lastDayWorkedFormThaa.value.hours) ? this.lastDayWorkedFormThaa.value.hours : value;
    if (selectedValue) {
      this.absenceHourRequest.hour = selectedValue.trim();
    }
  }

  startMinutesSelectedAbStartM(value) {
    const selectedValue = (this.lastDayWorkedFormThaa.value.minutes) ? this.lastDayWorkedFormThaa.value.minutes : value;
    if (selectedValue) {
      this.absenceHourRequest.min = selectedValue.trim();
    }
  }

  startHoursSelectedSchedTimeH(value) {
    const selectedValue = (this.lastDayWorkedFormThaa.value.schedHourFCN) ? this.lastDayWorkedFormThaa.value.schedHourFCN : value;
    if (selectedValue) {
      this.absenceHourRequest.hourSchedTime = selectedValue.trim();
    }
  }

  startMinutesSelectedSchedTimeM(value) {
    const selectedValue = (this.lastDayWorkedFormThaa.value.schedMinutesFCN) ? this.lastDayWorkedFormThaa.value.schedMinutesFCN : value;
    if (selectedValue) {
      this.absenceHourRequest.minSchedTime = selectedValue.trim();
    }
  }

  startHoursSelectedActTime(value) {
    const selectedValue = (this.lastDayWorkedFormThaa.value.actHours) ? this.lastDayWorkedFormThaa.value.actHours : value;
    if (selectedValue) {
      this.absenceHourRequest.hourActTime = selectedValue.trim();
    }
    if (selectedValue === 'HH') {
      this.showErrorForActTime = true;
    } else {
      this.showErrorForActTime = false;
    }
  }


  startMinutesSelectedActTime(value) {
    const selectedValue = (this.lastDayWorkedFormThaa.value.actMinutes) ? this.lastDayWorkedFormThaa.value.actMinutes : value;
    if (selectedValue) {
      this.absenceHourRequest.minActTime = selectedValue.trim();
    }
    if (selectedValue === 'MM') {
      this.showErrorForActTime = true;
    } else {
      this.showErrorForActTime = false;
    }
  }

  startUnitSelected(value) {
    const selectedValue = (this.lastDayWorkedFormThaa.value.unit) ? this.lastDayWorkedFormThaa.value.unit : value;
    if (selectedValue) {
      this.absenceHourRequest.unit = selectedValue.trim();
    }
  }

  checkBoxFDA() {
    if (this.dontKnwFDA.checkboxState === AppLabelConstants.CHECKED) {
      this.checkBoxValueFDA = true;
      this.isAbsenceDateEmpty = true;
      this.showErrorCalendarLabel();
    } else {
      this.checkBoxValueFDA = false;
      this.isAbsenceDateEmpty = false;
      this.showErrorCalendarLabel();
    }
    if (this.checkBoxValueFDA) {
      this.lastDayWorkedFormThaa.controls['datePickerAbsent'].setValue('');
      this.lastDayWorkedFormThaa.value.datePickerAbsent = null;
    }
  }

  checkBoxNV() {
    if (this.dontKnwNV.checkboxState === AppLabelConstants.CHECKED) {
      this.checkBoxValueNV = true;
      this.isNVDateEmpty = true;
      this.showErrorCalendarLabel();
    } else {
      this.checkBoxValueNV = false;
      this.isNVDateEmpty = false;
      this.showErrorCalendarLabel();
    }
    if (this.checkBoxValueNV) {
      this.lastDayWorkedFormThaa.controls['datePickerNextVisit'].setValue('');
      this.lastDayWorkedFormThaa.value.datePickerNextVisit = null;
    }
  }

  checkBoxLV() {
    if (this.dontKnwLV.checkboxState === AppLabelConstants.CHECKED) {
      this.checkBoxValueLV = true;
      this.isLVDateEmpty = true;
      this.showErrorCalendarLabel();
    } else {
      this.checkBoxValueLV = false;
      this.isLVDateEmpty = false;
      this.showErrorCalendarLabel();
    }
    if (this.checkBoxValueLV) {
      this.lastDayWorkedFormThaa.controls['datePickerLastVisit'].setValue('');
      this.lastDayWorkedFormThaa.value.datePickerLastVisit = null;
    }
  }


  checkBoxRTW() {
    if (this.dontKnwRTW.checkboxState === AppLabelConstants.CHECKED) {
      this.checkBoxValueRTW = true;
      this.isRTWDateEmpty = true;
      this.showErrorCalendarLabel();
    } else {
      this.checkBoxValueRTW = false;
      this.isRTWDateEmpty = false;
      this.showErrorCalendarLabel();
    }
    if (this.checkBoxValueRTW) {
      this.lastDayWorkedFormThaa.controls['datePickerRTW'].setValue('');
      this.lastDayWorkedFormThaa.value.datePickerRTW = null;
    }
  }

  setOtherReason(value) {
    const selectedValue = (this.lastDayWorkedFormThaa.value.gapList) ? this.lastDayWorkedFormThaa.value.gapList : value;
    if (selectedValue === null || selectedValue === '') {
      this.isReasonEmpty = false;
      this.isDateEmpty = false;
      this.tagErrorsGap(this.lastDayWorkedFormThaa.controls['gapList']. value, this.lastDayWorkedFormThaa.controls['gapList'].errors)
    } else if (selectedValue === 'notSelected') {
      this.isDateEmpty = false;
      this.tagErrorsGap(this.lastDayWorkedFormThaa.controls['gapList']. value, this.lastDayWorkedFormThaa.controls['gapList'].errors)
    } else {
      this.isReasonEmpty = true;
      this.isDateEmpty = true;
    }
  }

  validateStartTime() {
    if (this.absenceHourRequest.hour === 'HH' || this.absenceHourRequest.min === 'MM' || this.absenceHourRequest.unit === 'AM/PM') {
      this.showErrorForTime = true;
    } else {
      this.showErrorForTime = false;
    }

    if (this.absenceHourRequest.hourSchedTime === 'hours' || this.absenceHourRequest.minSchedTime === 'min') {
      this.showErrorForSchedTime = true;
    } else {
      this.showErrorForSchedTime = false;
    }

  }


  removeErrorForStartTime() {
    this.showErrorForTime = this.showErrorForTime ? !this.showErrorForTime : this.showErrorForTime;
  }

  removeErrorForSchedTime() {
    this.showErrorForSchedTime = this.showErrorForSchedTime ? !this.showErrorForSchedTime : this.showErrorForSchedTime;
  }

  /**
   * get the date selcted by user
   *
   * @param event
   */
  dateSelected(event: IMyDateModel, questionId) {
    this.CheckDateSelected(event);
    this.showErrorCalLabelOnBlur(questionId);
  }

  /**
   * to check if date is below compant acceptance date and yes then check for work schedule
   *
   * @param date event obj
   */
  CheckDateSelected(event) {
    const dateSelected = event.jsdate;
    const selected = new Date(event.formatted);
    this.LWDDate = selected;
    const newDisabilityServiceValue = new Date(this.claimESDForDisability);
    const newDisabilityServiceValueClaim = this.datepipe.transform(newDisabilityServiceValue, 'MM/dd/yyyy');
    this.newDateDisability = this.newDisabilityDateValue ? new Date(this.newDisabilityDateValue) : '';
    // this.newDisabilityDateValue = this.datepipe.transform(newDisabilityDateValue, 'MM/dd/yyyy');
    if (this.LWDDate === null) {
      this.isLDWDateEmpty = false;
      this.isLDWGreater = true;
      this.showLDWError = true;
      this.showLDWErrorGreater = false;
    } else if (((this.newDateDisability) && selected > this.newDateDisability) || ((!this.newDateDisability) && selected > newDisabilityServiceValue)) {
      this.isLDWGreater = false;
      this.isLDWDateEmpty = true;
      this.showLDWError = false;
      this.showLDWErrorGreater = true;
    } else {
      this.isLDWDateEmpty = true;
      this.isLDWGreater = true;
      this.showLDWError = false;
      this.showLDWErrorGreater = false;
    }
  }


  dateSelectedAbsent(event: IMyDateModel, questionId) {
    this.CheckDateSelectedAbsent(event);
    this.showErrorCalLabelOnBlur(questionId);
  }

  /**
   * to check if date is below compant acceptance date and yes then check for work schedule
   *
   * @param date event obj
   */
  CheckDateSelectedAbsent(event) {
    const dateSelected = event.jsdate;
    const selected = new Date(event.formatted);
    this.absentDate = this.datepipe.transform(selected, 'MM/dd/yyyy');
    if (this.absentDate === null && !this.checkBoxValueFDA) {
      this.isAbsenceDateEmpty = false;
    } else {
      this.isAbsenceDateEmpty = true;
    }
    if (this.absentDate) {
      this.dontKnwFDA.checkboxState = undefined;
      this.checkBoxValueFDA = false;
    }
  }


  dateSelectedLastVisit(event: IMyDateModel, questionId) {
    this.CheckDateSelectedLastVisit(event);
    this.showErrorCalLabelOnBlur(questionId);
  }

  /**
   * to check if date is below compant acceptance date and yes then check for work schedule
   *
   * @param date event obj
   */
  CheckDateSelectedLastVisit(event) {
    const dateSelected = event.jsdate;
    const selected = new Date(event.formatted);
    this.lastVisitDate = this.datepipe.transform(selected, 'MM/dd/yyyy');
    if (this.lastVisitDate === null && !this.checkBoxValueLV) {
      this.isLVDateEmpty = false;
    } else {
      this.isLVDateEmpty = true;
    }
    if (this.lastVisitDate) {
      this.dontKnwLV.checkboxState = undefined;
      this.checkBoxValueLV = false;
    }
  }

  dateSelectedNextVisit(event: IMyDateModel, questionId) {
    this.CheckDateSelectedNextVisit(event);
    this.showErrorCalLabelOnBlur(questionId);
  }

  /**
   * to check if date is below compant acceptance date and yes then check for work schedule
   *
   * @param date event obj
   */
  CheckDateSelectedNextVisit(event) {
    const dateSelected = event.jsdate;
    const selected = new Date(event.formatted);
    this.nextVisitDate = this.datepipe.transform(selected, 'MM/dd/yyyy');
    if (this.nextVisitDate === null && !this.checkBoxValueNV) {
      this.isNVDateEmpty = false;
    } else {
      this.isNVDateEmpty = true;
    }
    if (this.nextVisitDate) {
      this.dontKnwNV.checkboxState = undefined;
      this.checkBoxValueNV = false;
    }
  }


  dateSelectedRTW(event: IMyDateModel, questionId) {
    this.CheckDateSelectedRTW(event);
    this.showErrorCalLabelOnBlur(questionId);
  }

  /**
   * to check if date is below compant acceptance date and yes then check for work schedule
   *
   * @param date event obj
   */
  CheckDateSelectedRTW(event) {
    const dateSelected = event.jsdate;
    const selected = new Date(event.formatted);
    this.rtwDate = this.datepipe.transform(selected, 'MM/dd/yyyy');
    if (this.rtwDate === null && !this.checkBoxValueRTW) {
      this.isRTWDateEmpty = false;
    } else {
      this.isRTWDateEmpty = true;
    }
    if (this.rtwDate) {
      this.dontKnwRTW.checkboxState = undefined;
      this.checkBoxValueRTW = false;
    }
  }

  /**
   * to get next month view on calendar on arrow clicked
   *
   * @param event
   */
  onCalendarViewChanged(event: IMyCalendarViewChanged) {
    const monthSelect: any = document.getElementsByClassName('headerlabelbtn');
    if (monthSelect && monthSelect.length) {
      for (let i = 0; i <= monthSelect.length; i++) {
        if (monthSelect[i]) {
          monthSelect[i].innerHTML = `${this.months[event.month]} ${event.year}`;
        }
      }
    }
  }

  fetchOWCSContent() {
    this.pageId = this.contentService.getPageId(AppConstants.THAA_CONFIRM_DISABILITY);
    if (this.pageId) {
      this.contentService.getOWCSResponse(this.pageId, AppConstants.THAA_CONFIRM_DISABILITY_U).subscribe(
        val => {
          if (val) {
            this.owscContent = val;
            if (this.owscContent && this.owscContent.data) {
              this.question_checkbox = this.appLabelConstants.CHECKBOX;
              if (this.owscContent.data.THAA_CDD_04) {
                this.qestion_1 = this.owscContent.data.THAA_CDD_04.value;
                this.btnEventId = this.owscContent.data.THAA_CDD_04.value;
              }
              if (this.owscContent.data.THAA_CDD_06) {
                this.question_2 = this.owscContent.data.THAA_CDD_06.value;
              }
              if (this.owscContent.data.THAA_CDD_08) {
                this.question_3 = this.owscContent.data.THAA_CDD_08.value;
              }
              if (this.owscContent.data.THAA_CDD_12) {
                this.question_4 = this.owscContent.data.THAA_CDD_12.value;
              }
              if (this.owscContent.data.THAA_CDD_15) {
                this.question_5 = this.owscContent.data.THAA_CDD_15.value;
              }
              if (this.owscContent.data.THAA_CDD_18) {
                this.question_6 = this.owscContent.data.THAA_CDD_18.value;
              }
              if (this.owscContent.data.THAA_CDD_21) {
                this.question_7 = this.owscContent.data.THAA_CDD_21.value;
              }
              if (this.owscContent.data.THAA_CDD_23) {
                this.question_7a = this.owscContent.data.THAA_CDD_23.value;
              }
              if (this.owscContent.data.THAA_CDD_25) {
                this.question_8 = this.owscContent.data.THAA_CDD_25.value;
              }
              if (this.owscContent.data.THAA_CDD_28) {
                this.question_9 = this.owscContent.data.THAA_CDD_28.value;
              }
              if (this.owscContent.data.THAA_CDD_31) {
                this.question_10 = this.owscContent.data.THAA_CDD_31.value;
              }
            }
          }
        })
    }
  }
}
