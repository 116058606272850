import {Injectable} from '@angular/core';

/**
 * Created by AC12393 on 11/3/2017.
 */
@Injectable()
export class DepositDisclaimerService {

  constructor() { }
 /* getDepositDisclaimer() {
    return DepositDisclaimer;
  }*/
}

