import {Component, OnInit} from '@angular/core';
import {AppLabelConstants} from '../../../../shared/constants/appLabel.constants';

@Component({
  selector: 'app-save-message-banner',
  templateUrl: './save-message-banner.component.html',
  styleUrls: ['./save-message-banner.component.scss']
})
export class SaveMessageBannerComponent implements OnInit {

  public appLabelConstants = AppLabelConstants;
  public active: boolean;

  constructor() { }

  ngOnInit() {
    this.active = true;
  }

}
