
import {pairwise, filter} from 'rxjs/operators';
import {Component, Inject, OnInit, isDevMode} from '@angular/core';
import {ResponseErrorHandlerService} from '@shared/services/response-error-handler.service';
import {ActivatedRoute, NavigationEnd, Router, RoutesRecognized} from '@angular/router';
import {FaqService} from '@shared/services/faqs.service';
import {StorageManagementService} from '@shared/services/storage-management.service';

import {AuthStateService} from '@shared/services/auth-state.service';
import {ProductService} from '@shared/services/product.service';
import {ContentService} from '@shared/services/content.service';
import {MessageRibbonService} from '@shared/services/message-ribbon.service';
import {DepositService} from '@shared/services/enrolldeposit.service';

import { env } from 'environments/environment-loader';
import {TokenUtility} from '@shared/utils/tokenUtility';
import {ModalService} from '@shared/services/modal.service';
import { AppLabelConstants } from '@shared/constants/appLabel.constants';
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  public urlResponse = [];
  public sfd_login_url = env.gbCiamCommonUtil.SfdAccountUrl;
  public currentUrl: any;
  public routeUrl: any;
  public appLandingPage_URL = encodeURI(window.location.href);
  public cxrURL = env.cxrBase;
  public ciam_login_url = env.gbCiamCommonUtil.CIAMLoginURL;
  public urlThaaResponse = [];
  public isCxr = false;
  private getSessionIdResponse;

  constructor(
    private sessionManagementService: StorageManagementService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private errorHandler: ResponseErrorHandlerService,
    private faqService: FaqService,
    private authStateService: AuthStateService,
    private productService: ProductService,
    private storageManagementService: StorageManagementService,
    private contentService: ContentService,
    private messageRibbonService: MessageRibbonService,
    private depositService: DepositService,
    private modalService: ModalService,
             // private activatedRoute: ActivatedRoute
  ) {
    if ((this.activatedRoute.snapshot.queryParams['channelId'] === '3' || this.activatedRoute.snapshot.queryParams['channelId'] === '4') &&  this.activatedRoute.snapshot.queryParams['authToken']) {
    //  sessionStorage.clear();
      TokenUtility.removeMyBenefitsSiteAccess();
      TokenUtility.removeAllMyBenefitsTokens();
    }
    this.storageManagementService.setSessionData(
      'channelId',
      this.activatedRoute.snapshot.queryParams['channelId']
    );
    router.events.pipe(
      filter(event => event instanceof NavigationEnd))
      .subscribe((routeData: any) => {
        this.currentUrl = routeData.url;
        this.storageManagementService.setSessionData(
          'previousRoute',
          this.currentUrl
        );
        window.scrollTo(0, 0);
        this.enterDynatraceAction(routeData);
        productService.isProductExist.next(true);
        errorHandler.isServiceError.next(false);
        errorHandler.failedTransactionCtr.next(0);
        //Clear the Login Page SysDown message ribbon While Navigation if any
        if (
          this.errorHandler.getSysDownKeyLogin() &&
          this.errorHandler.getSysDownKeyLogin().length > 0
        ) {
          this.messageRibbonService.removeRibbon(
            this.errorHandler.getSysDownKeyLogin()
          );
        }
        //Clear the Overview Page SysDown message ribbon While Navigation if any
        if (
          this.errorHandler.getSysDownKeyOverview() &&
          this.errorHandler.getSysDownKeyOverview().length > 0
        ) {
          this.messageRibbonService.removeRibbon(
            this.errorHandler.getSysDownKeyOverview()
          );
        }
      });

    router.events.pipe(
      filter(e => e instanceof RoutesRecognized),
      pairwise(), )
      .subscribe((event: any[]) => {
        const prevPage = event[0].urlAfterRedirects;
        const nextPage = event[1].urlAfterRedirects;
        this.faqService.setPrevPage(prevPage);
        if (
          prevPage &&
          prevPage.toLowerCase().includes('editdeposit') &&
          nextPage &&
          nextPage.toLowerCase().includes('enrolldeposit')
        ) {
          this.depositService.setIsUserBackToEnrollDeposit(true);
        } else if (
          prevPage &&
          prevPage.toLowerCase().includes('editdeposit') &&
          nextPage &&
          !nextPage.toLowerCase().includes('enrolldeposit')
        ) {
          this.depositService.clearEnrollDepositRelatedData();
          this.depositService.setIsUserBackToEnrollDeposit(false);
        } else if (
          prevPage &&
          prevPage.toLowerCase().includes('editdeposit') &&
          !nextPage
        ) {
          this.depositService.clearEnrollDepositRelatedData();
          this.depositService.setIsUserBackToEnrollDeposit(false);
        } else if (
          prevPage &&
          prevPage.toLowerCase().includes('enrolldeposit') &&
          (!nextPage ||
            (nextPage && !nextPage.toLowerCase().includes('editdeposit')))
        ) {
          this.depositService.clearEnrollDepositRelatedData();
          this.depositService.setIsUserBackToEnrollDeposit(false);
        }
      });
  }
  ngOnInit() {
    if ((this.activatedRoute.snapshot.queryParams['channelId'] === '3' || this.activatedRoute.snapshot.queryParams['channelId'] === '4') &&  this.activatedRoute.snapshot.queryParams['authToken']) {
      //  sessionStorage.clear();
      TokenUtility.removeMyBenefitsSiteAccess();
      TokenUtility.removeAllMyBenefitsTokens();
    }
    this.checkCIAMSessionToken();
  }
  /*
   * US14494 - Performance Monitoring Requirement
   * Author: DB
   * Added: 02/21/2018
   */
  enterDynatraceAction(routeData) {
    const actionName = `Navigated to ${routeData.url}`;
    const actionType = `Angular View load`;
    const actionTime = Date.now();
    const actionParent = false;
    if (window.hasOwnProperty('dT_') && window.hasOwnProperty('dynaTrace')) {
      const actionId = dynaTrace.enterAction(
        actionName,
        actionType,
        actionTime,
        actionParent
      );
      dynaTrace.reportString('Angular view load action ID', actionId);
      this.storageManagementService.setSessionObjectNoJSON(
        'dynatraceAction',
        actionId
      );
    } else {
      const actionId = this.simulateEnterAction(
        actionName,
        actionType,
        actionTime,
        actionParent
      );
      this.simulateReportString('Angular view load action ID', actionId);
      this.storageManagementService.setSessionObjectNoJSON(
        'dynatraceAction',
        actionId
      );
    }
  }

  simulateEnterAction(name, type, time, parent) {
    return `Dynatrace Action Id for ${name}`;
  }

  simulateReportString(key, value) {
    return;
  }
  checkCIAMSessionToken() {
    if (document.cookie) {
      const authorizationToken = this.getExistingForgeRockToken(
        document.cookie,
        'EESession'
      );
      const ciamToken = this.getExistingForgeRockToken(
        document.cookie,
        'CIAMSession'
      );
      const curURL = window.location.pathname;
      if (curURL === '/login' || curURL === '/') {
        this.router.navigate(['/login']);
      } else if (ciamToken && authorizationToken === undefined && curURL !== '/psv') {
        window.location.href = this.setUrlParameter(
          this.sfd_login_url,
          'goto',
          this.appLandingPage_URL
        );
      } else {
        if (!ciamToken && this.ciam_login_url && this.appLandingPage_URL && curURL !== '/appInfo' && curURL !== '/login' && curURL !== '/' && curURL !== '/contactUs' && curURL !== '/psv') {
          window.location.href = this.setUrlParameter(
            this.ciam_login_url,
            'goto',
            this.appLandingPage_URL
          );
        }
      } /*if (ciamToken && authorizationToken !== undefined) {
        this.sfdAuthorizeResponse();
      }*/
    }
  }

setUrlParameter(url, key, value) {
    let newValueUrl;
    const valueLen = value.split('?');
    const baseUrl = url.split('?')[0];
    if (valueLen.length > 1) {
      newValueUrl = value.split('?')[0] + '&' + value.split('?')[1];
    } else {
      newValueUrl = value;
    }
    const newParam = '?' + key + '=' + newValueUrl;
    return baseUrl + newParam;
  }
 /* sfdAuthorizeResponse() {
    const getAuthorize = this.getExistingForgeRockToken(document.cookie, 'siteAccess' );
    if (getAuthorize) {
      const AuthorizeResponse = JSON.parse(getAuthorize);
      const urlDataEmp: any = AuthorizeResponse.find(sites => sites.provisionid === '2791');
       if (urlDataEmp) {
          const siteData = urlDataEmp.site;
              if (siteData.find(urlData => urlData.url.split('/')[2] === (this.cxrURL).split('/')[2])) {
                 const curUrlPath = window.location.pathname + window.location.search;
                   const curURLs = window.location.pathname;
                    // const curUrlPath = window.location.pathname + window.location.search;
                    this.router.navigateByUrl(curUrlPath);
                    return;
              } else {
                window.location.href = env.gbCiamCommonUtil.SfdAccountUrl + '/contactUs';
                return;
              }
              } else {
                 window.location.href = env.gbCiamCommonUtil.SfdAccountUrl + '/contactUs' ;
              }
             }
  }*/
  getExistingForgeRockToken(cookie, key) {
    let bodyFrToken; let frToken;
    if (cookie) {
      bodyFrToken = cookie.split(';').find(x => x.includes(key));
      if (bodyFrToken) {
        frToken = bodyFrToken.split('=')[1];
      }
    }
    return frToken;
  }
  /**
   * clearCookie () this method is use to clear cookie value based on key name
   *
   * @param cookieName
   */
  clearCookie(cookieName: string) {
    document.cookie = '' + cookieName + '=; domain=.thehartford.com; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;';
  }
  bake_cookie(name, value) {
    const cookie = [
      name,
      '=',
      JSON.stringify(value),
      '; path=/;domain=.thehartford.com;'
    ].join('');
    document.cookie = cookie;
  }

  /**
   * set focus on header of the page
   */
  setFocusToHeader(): void {
    const elementById = document.querySelector('#' + AppLabelConstants.HARTFORD_LOGO_LINK_ID);
    if (elementById) {
      setTimeout(()=> {
        (elementById as HTMLElement)?.focus()
      }, 500);
    }
  }
}
