import { Injectable } from '@angular/core';
import { ResponseErrorHandlerService } from '@shared/services/response-error-handler.service';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { env } from '@env/environment-loader';
import { AppLabelConstants } from '@shared/constants/appLabel.constants';
import { BehaviorSubject, Observable, of, throwError } from 'rxjs';
import {catchError, map} from 'rxjs/operators';
import { IFetchLookUp, IntakeStateList } from '../models/reasonList.model';
import { StorageManagementService } from '@app/shared/services/storage-management.service';
import { IntakeSessionConstants } from '../constants/intakeSession.constants';


@Injectable()
export class ContactStateService {

  constructor(private errorHandler: ResponseErrorHandlerService,
    private httpClient: HttpClient, private storeManagementService: StorageManagementService) {
  }
  isDisabled = new BehaviorSubject(false);
  currentState =this.isDisabled.asObservable();
  public updateState(newState: boolean){
    this.isDisabled.next(newState);
  }
  public contactStateFunction(personId: string): Observable<IntakeStateList> {
    const stateDetails = this.storeManagementService.getIntakeSessionData(IntakeSessionConstants.STATE_LIST);
    if (stateDetails) {
      return of(stateDetails);
    } else {
      if (personId) {
        return this.httpClient.get(env.thaaWKABPortalService.intakeContactState + '&personId=' + personId,
          {
            headers: new HttpHeaders()
              .set(AppLabelConstants.CONTENT_TYPE_KEY, AppLabelConstants.APPLICATION_KEY)
          }).pipe(
          map(
            (response: IntakeStateList) => {
              if (!this.errorHandler.isServiceResponseHasError(response)) {
                this.storeManagementService.setIntakeSessionData(IntakeSessionConstants.STATE_LIST, response);
                return response;
              } else {
                throw response;
              }
            }
          ), catchError((error: any) => this.handleError(error)), );
      }
    }
  }
  public  utitlityFetchup(personId: string, codeType: string): Observable<IFetchLookUp> {
    const fetchLookUp = this.storeManagementService.getIntakeSessionData(IntakeSessionConstants.FETCH_LOOK_UP);
    return this.fetchlookUp(personId, codeType, IntakeSessionConstants.FETCH_LOOK_UP);
  }

  public  fetchlookUp(personId: string, codeType: string, sessionKey: string): Observable<IFetchLookUp> {
    const fetchLookUp = this.storeManagementService.getIntakeSessionData(sessionKey);
    if (fetchLookUp ) {
      return of(fetchLookUp );
    } else {
      if (personId) {
        return this.httpClient.get(env.thaaWKABPortalService.getFetchLookUp + '&personId=' + personId + '&codeType=' + codeType,
          {
            headers: new HttpHeaders()
              .set(AppLabelConstants.CONTENT_TYPE_KEY, AppLabelConstants.APPLICATION_KEY)
          }).pipe(map(
            (response: IFetchLookUp) => {
              if (!this.errorHandler.isServiceResponseHasError(response)) {
                this.storeManagementService.setIntakeSessionData(sessionKey, response);
                return response;
              } else {
                throw response;
              }
            }
          ), catchError((error: any) => this.handleError(error)) , );
      }
    }
  }

  handleError(error: Response) {
    return throwError(error);
  }
}
