import { Component, OnInit } from '@angular/core';
import {AppConstants} from '../../../shared/constants/app.constants';
import {AppLabelConstants} from '../../../shared/constants/appLabel.constants';
import {ModalService} from '../../../shared/services/modal.service';

@Component({
  selector: 'app-still-working-modal',
  templateUrl: './still-working-modal.component.html',
  styleUrls: ['./still-working-modal.component.scss']
})
export class StillWorkingModalComponent implements OnInit {

  public appLabelConstant = AppLabelConstants;
  constructor(private modalService: ModalService) { }
  returnToOverview() {
    this.modalService.closeModal(AppConstants.STILL_WORKING_MODAL);
  }
  ngOnInit() {
  }
}
