import {AfterContentInit, Component, OnInit, Output} from '@angular/core';
import {Router} from '@angular/router';
import {AuthStateService} from '../../../../shared/services/auth-state.service';
import {CustomEventService} from '../../../../shared/services/tealium/custom-event.service';
import {StorageManagementService} from '../../../../shared/services/storage-management.service';
import {AppLabelConstants} from '../../../../shared/constants/appLabel.constants';
import {SessionConstants} from '../../../../shared/constants/session.constants';
import {AppConstants} from 'app/shared/constants/app.constants';
import {ClaimRepresentativeService} from '../../../../shared/services/claimRepresentativeDetails.service';
import {AppLeaveConstants} from '../../../leave-details-flow/leave-details.constants';
import {AdaProductService} from '../../../../shared/services/ada-product.service';
import {Claim} from '@app/shared/models/claim.model';
import {ClaimHistory} from '@app/shared/models/claimHistory.model';

@Component({
  selector: 'contact-us',
  templateUrl: 'contact-us.component.html',
  styleUrls: ['contact-us.component.scss']
})
export class ContactUsComponent implements OnInit, AfterContentInit {
  @Output() contactLink = 'Contact Us';
  linkLocation = AppLabelConstants.URL_CONTACT_US;
  public isLoggedIn = false;
  public needHelpClass;
  public isFAQDisplayFlag = false;
  public isClaimHandlerDisplayFlag = true;
  public appLabelConstant = AppLabelConstants;
  public appConstants = AppConstants;
  public showScheduleACall: boolean = true;
  public isHistoryClaimsDeniedOrClosed: boolean = false;
  public handlerDetails = [];
  public appConst = {...AppLabelConstants, ...AppLeaveConstants};
  public leaveData;
  public claimHistory: ClaimHistory;
  private curPage: string;

  constructor(private router: Router,
              private authStateService: AuthStateService,
              private adaProductService: AdaProductService,
              private customEventService: CustomEventService,
              private storageManagementService: StorageManagementService,
              public claimRepresentativeService: ClaimRepresentativeService
              ) {

  }

  ngOnInit() {
    this.curPage = this.router.url;

    this.storageManagementService.setSessionData(SessionConstants.IS_CUR_PAGE_CLAIM_DETAILS, false);
    if (this.curPage === AppLabelConstants.URL_CLAIM_DETAILS || this.curPage === AppLabelConstants.URL_SINGLE_PAYMENT || this.curPage === AppLabelConstants.URL_LEAVE_DETAILS) {
      this.storageManagementService.setSessionData(SessionConstants.IS_CUR_PAGE_CLAIM_DETAILS, true);
      //console.log('curPage from xcdesfcre came first: ' + this.storageManagementService.getSessionData('isCurPageClaimDetails'));
    }
    this.isFAQDisplayFlag = this.curPage && this.curPage !== AppLabelConstants.URL_LOGIN && !this.curPage.includes(AppLabelConstants.PRODUCT_INFO_STRNG) && !this.curPage.includes(AppLabelConstants.HOME_TEXT) && this.curPage !== AppLabelConstants.URL_PHA_DETAILS;

    if (this.curPage && (this.curPage === AppLabelConstants.URL_CLAIM_DETAILS || this.curPage === AppLabelConstants.URL_SINGLE_PAYMENT || this.curPage === AppLabelConstants.URL_LEAVE_DETAILS || this.curPage === AppLabelConstants.URL_LEAVE_PLAN || this.curPage === AppLabelConstants.URL_LEAVE_PLAN_DETAILS || this.curPage === AppLabelConstants.YOUR_DOCUMENTS_URL)
    || this.curPage === AppConstants.URL_CLAIMdETAILS_MISSINGINFO || this.curPage === AppConstants.URL_LEAVEdETAILS_MISSINGINFO || this.curPage === AppLabelConstants.LINK_TO_SEE_MORE_FUTURE_PAYMENTS) {
      this.needHelpClass = 'col-xs-12 col-sm-12 col-md-12 contact-button no-padding';
    } else {
      this.needHelpClass = 'col-xs-12 col-sm-6 contact-button';
    }
    this.isLoggedIn = this.authStateService.getLoginState();
    //To hide claim handler details in case of leavePlan and leaveRequestHistory page
    this.isClaimHandlerDisplayFlag = (this.curPage === AppLabelConstants.URL_LEAVE_PLAN) ? false : true;
    this.storageManagementService.setSessionData(SessionConstants.CURRENT_PAGE, this.curPage);
    this.isLoggedIn = this.authStateService.getLoginState();
    if (this.storageManagementService.retrieveSessionObject(SessionConstants.CLAIM_HISTORY)) {
      this.showScheduleCall();
    }
    if (this.storageManagementService.retrieveSessionObject(SessionConstants.RELATED_CLAIMS)) {
      this.fetchHandlerDetails();
    }
  }

  ngAfterContentInit() {
    this.isLoggedIn = this.authStateService.getLoginState();
  }

  /*
  * to determine if schedule a call button should be displayed for LOA
  * */
  fetchHandlerDetails() {
    let data = JSON.parse(this.storageManagementService.retrieveSessionObject(SessionConstants.RELATED_CLAIMS));
    data = data.relatedClaims;
    for (let i = 0; i < data.length; i++) {
      if (data[i].claimType === AppConstants.SHORT_TERM_DISABILITY && (data[i].coverageCategory === AppConstants.WD && (data[i].coverageType === AppConstants.NST || data[i].coverageType === AppConstants.STAT))) {
        this.claimRepresentativeService.getClaimRepresentativeDetails(data[i].personId, data[i].claimEventId).subscribe(res => {
          if (res) {
            this.handlerDetails.push(res.personId);
            this.storageManagementService.setSessionData(SessionConstants.HANDLER_DETAILS, this.handlerDetails);
            const handlerInfo = Array.from(new Set(this.storageManagementService.getSessionData(SessionConstants.HANDLER_DETAILS)));
            this.showScheduleACall = handlerInfo.length === 1;
            if ((this.curPage.includes(this.appLabelConstant.URL_LEAVE_DETAILS) || this.curPage.includes(this.appLabelConstant.URL_CLAIM_DETAILS)) && this.isHistoryClaimsDeniedOrClosed) {
              this.showScheduleACall = false;
              this.isClaimHandlerDisplayFlag = false;
            }
            if (this.isLoggedIn && this.storageManagementService.getSessionData(SessionConstants.CURR_LEAVE)) {
              this.getLeaveHandlerDetails();
            }
          }
        }, (error) => {
          console.log(AppConstants.ERROR + error);
        });
      }
    }
  }

  getLeaveHandlerDetails() {
    this.leaveData = this.storageManagementService.getSessionData(SessionConstants.CURR_LEAVE);
    const currentUser = JSON.parse(this.storageManagementService.retrieveSessionObject(SessionConstants.APP_DATA)).appData;
    if (this.leaveData.leaveType.includes(this.appConst.ADA)) {
      this.adaProductService.isAdaProductSet.subscribe(value => {
        const enhancedAdaApplicable = this.adaProductService.isEnhancedAdaApplicable();
        if (enhancedAdaApplicable) {
          this.claimRepresentativeService.getLeaveRepresentativeDetails(this.leaveData.personId, this.leaveData.leaveId).subscribe(res => {
            if (res) {
              this.storageManagementService.setSessionData(SessionConstants.ADA_HANDLER_PERSONID, res.personId || '');
            }
          }, (error) => {
            console.log(AppConstants.ERROR + error);
          });
          if (this.storageManagementService.getSessionData(SessionConstants.ADA_HANDLER_PERSONID)) {
            const adaHnadlerPersonID = this.storageManagementService.getSessionData(SessionConstants.ADA_HANDLER_PERSONID);
            const handlerpersonIds = this.storageManagementService.getSessionData(SessionConstants.HANDLER_DETAILS) ? this.storageManagementService.getSessionData(SessionConstants.HANDLER_DETAILS) : [];
            handlerpersonIds.push(adaHnadlerPersonID);
            this.showScheduleACall = handlerpersonIds.length >= 1;
          }
        }
      });
    }
  }
  showScheduleCall() {
    let claimNeeded: Claim;
    let claimEventId: string;
    let leaveId: string;
    const arrClaimEventId: string[] = [];
    const arrleaveId: string[] = [];
    this.claimHistory = JSON.parse(this.storageManagementService.retrieveSessionObject(SessionConstants.CLAIM_HISTORY)).claimHistory;
    if (this.storageManagementService.retrieveSessionObject(SessionConstants.CUR_CLAIM)) {
      claimNeeded = JSON.parse(this.storageManagementService.retrieveSessionObject(SessionConstants.CUR_CLAIM)).curClaim;
      claimEventId = (claimNeeded && claimNeeded.claimEventId);
    }
    if (this.storageManagementService.getSessionData(SessionConstants.CURR_LEAVE)) {
      this.leaveData = this.storageManagementService.getSessionData(SessionConstants.CURR_LEAVE);
      leaveId = (this.leaveData && this.leaveData.leaveId);
    }
    this.claimHistory.disabilityClaims.forEach(element =>
      arrClaimEventId.push(element.claimEventId));
    this.claimHistory.leaveClaims.forEach(element =>
      arrleaveId.push(element.leaveId));
    if ((arrClaimEventId.indexOf(claimEventId) !== -1 && this.curPage.includes(this.appLabelConstant.URL_CLAIM_DETAILS)) || (arrleaveId.indexOf(leaveId) !== -1 && this.curPage.includes(this.appLabelConstant.URL_LEAVE_DETAILS))) {
      this.isHistoryClaimsDeniedOrClosed = true;
      this.showScheduleACall = false;
    }
  }
  onContactUsClick() {
    this.router.navigateByUrl(AppLabelConstants.URL_CONTACT_US);
  }

  checkState() {
    return this.router.url !== AppLabelConstants.URL_LOGIN;
  }

  trackContactUsClick() {
    this.customEventService.customEvent('Contact Us', 'Page Tag', 'Button Click', 'contactUsId', 'Contact Us');
  }
}
