import {Component, OnInit} from '@angular/core';
import {FormControl, FormGroup} from '@angular/forms';
import {env} from '../../../../environments/environment-loader';
import {StorageManagementService} from '../../../shared/services/storage-management.service';
import {User} from '../../../shared/models/user.model';
import {Router} from '@angular/router';
import {AuthStateDevService} from '../services/auth-state-dev.service';
import {FakeService} from '../mocking/fake.service';
import {SessionConstants} from '../../../shared/constants/session.constants';
import { AppConstants } from '@app/shared/constants/app.constants';

@Component({
  selector: 'mock-login',
  templateUrl: './mockLanding.component.html',
  styleUrls: ['mockLanding.component.scss']
})
export class MockLandingComponent implements OnInit {

  public partyRefHiddenNonLocal: boolean;
  public proxyState: any;
  public mockIndicator: any;
  public userform: any;
  private user: User;
  public people: any;
  public peopleNames: any;
  public mockedServices;
  public fileContent;
  public userClaims;

  constructor(private router: Router,
              private authStateDevService: AuthStateDevService,
              private fakeService: FakeService,
              private storageManagementService: StorageManagementService) {

  }

  ngOnInit(): void {
    this.partyRefHiddenNonLocal = env.envName === AppConstants.LOCAL || env.envName === AppConstants.LOCAL_QA;
    this.proxyState = env.proxyState;
    this.mockIndicator = env.mocking;

    if (this.mockIndicator) {
      this.fakeService.getPeopleForLogin();
    }

    this.userform = new FormGroup({
      name: new FormControl('', []),
      pass: new FormControl('', []),
      //TODO: This Form control is just for temporary purpose need to remove after get User Service Integration
      partRefID: new FormControl('', [])
    });

    if (this.mockIndicator) {
      this.fakeService.getPeopleForLogin();
      this.people = this.storageManagementService.retrieveSessionObject('people') ? JSON.parse(this.storageManagementService.retrieveSessionObject('people')).people : null;
      this.peopleNames = this.storageManagementService.retrieveSessionObject('peopleNames') ? JSON.parse(this.storageManagementService.retrieveSessionObject('peopleNames')).peopleNames : null;
    }
  }

  loginToApp() {
    if (this.proxyState && !this.mockIndicator ) {

      this.authStateDevService.getForgeToken(this.userform.get('partRefID').value, this.userform.get('pass').value).subscribe(
        data => {
          if (this.userform.get('partRefID').value === 'sso') {
            this.router.navigate(['/home'], {queryParams: {channelId: '1'}});
          } else {
            this.router.navigate(['/home']);
          }
        }
      );
    } else  {
      if (this.userform.get('partRefID').value === '') {
        this.storageManagementService.setSessionData('selectedPrtyRef', '957181' );
      } else if (this.userform.get('partRefID').value === 'sso') {
        this.router.navigate(['/home'], {queryParams: {channelId: '1'}});
      } else {
        //this.storageManagementService.setSessionObject('selectedPrtyRef', this.userform.get('partRefID').value);
        this.storageManagementService.setSessionData('selectedPrtyRef', this.userform.get('partRefID').value);
      }
      this.router.navigate(['/home']);
    }

  }


  //TODO: Used for Mocked Flow
  selectedVal(eve) {
    this.characterSelect((parseInt(eve.target.selectedIndex, 10) - 1));
  }

  //TODO: Used for Mocked Flow
  characterSelect(ind) {
    this.user = this.people[ind];
    if (this.user) {
      this.storageManagementService.setAppData(SessionConstants.USER_DATA, this.user);
    }
  }

  public onChange(fileList: FileList): void {
    const file = fileList[0];
    const fileReader: FileReader = new FileReader();
    const self = this;
    fileReader.onloadend = function(x) {
      self.fileContent = fileReader.result;
      self.userClaims = JSON.parse(self.fileContent);
      self.storageManagementService.setAppData(SessionConstants.USER_CLAIMS , self.userClaims);
    }
    fileReader.readAsText(file);
  }

}
