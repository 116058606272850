import { Injectable } from '@angular/core';
import {ResponseErrorHandlerService} from '@shared/services/response-error-handler.service';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {env} from '@env/environment-loader';
import {AppLabelConstants} from '@shared/constants/appLabel.constants';
import { Observable, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import {IIcdResponse} from '@app/cxr/modules/intake/shared/models/intake-content.model';

@Injectable()
export class IcdDetailsThaaService {

  constructor(private errorHandler: ResponseErrorHandlerService,
              private httpClient: HttpClient) {
  }

  public icdDetailsFunction( personId: string, icdCode: string, isDelivered: number): Observable<IIcdResponse>  {
    return this.httpClient.get(env.thaaWKABPortalService.getIcdDetails + '&personId=' + personId + '&icdCode=' + icdCode + '&isDelivered=' + isDelivered,
      {
        headers: new HttpHeaders()
          .set(AppLabelConstants.CONTENT_TYPE_KEY, AppLabelConstants.APPLICATION_KEY)
      }).pipe(map(
      (response: IIcdResponse) => {
        if (!this.errorHandler.isServiceResponseHasError(response)) {
          return response;
        } else {
          throw response;
        }
      }
    ), catchError((error: any) => this.handleError(error)));
  }

  handleError(error: Response) {
    return throwError(error);
  }

}
