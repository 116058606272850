
import {Observable} from 'rxjs';
import {catchError, map} from 'rxjs/operators';
import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';

import {AppLabelConstants} from '@app/shared/constants/appLabel.constants';
import {ResponseErrorHandlerService} from '@shared/services/response-error-handler.service';
import {env} from '@env/environment-loader';
import {ILeaveReasons, IReasonResponse} from '@app/cxr/modules/intake/shared/models/intake-content.model';
import {StorageManagementService} from '@shared/services/storage-management.service';
import {of} from 'rxjs/index';
import {IntakeLabelConstants} from '@app/cxr/modules/intake/shared/constants/intake-label.constants';

@Injectable()
export class IntakeLeaveReasonService {

  constructor(private errorHandler: ResponseErrorHandlerService,
              private httpClient: HttpClient,
              private storageManagementService: StorageManagementService) {
  }

  getStartALeaveReasonAPI(personId: string, firstDayAbsent: string): Observable<IReasonResponse> {
      if (personId && firstDayAbsent) {
        return this.httpClient.get(env.thaaWKABPortalService.getStartLeaveReason + '&personid=' + personId + '&FDA=' + firstDayAbsent,
          {
            headers: new HttpHeaders()
              .set(AppLabelConstants.CONTENT_TYPE_KEY, AppLabelConstants.APPLICATION_KEY)
          }).pipe(
          map(
            (response: IReasonResponse) => {
              if (!this.errorHandler.isServiceResponseHasError(response)) {
                this.storageManagementService.setIntakeSessionData(IntakeLabelConstants.ABSENT_REASON_LIST_API, response.ReasonList);
                return response;
              } else {
                throw response;
              }
            }
          ), catchError((error: any) => this.errorHandler.handleError(error)),
        );
      } else {
        return this.errorHandler.handleError('Reason List API failed ');
      }
  }
}
