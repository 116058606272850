import {Component, Input, OnInit} from '@angular/core';
import {Router} from '@angular/router';
import {StorageManagementService} from '../../../shared/services/storage-management.service';
import {ConfirmSurgeryServiceService} from '../../../shared/services/confirm-surgery-service.service';
import {AppConstants} from '../../../shared/constants/app.constants';
import {AppLabelConstants} from '../../../shared/constants/appLabel.constants';
import {SessionConstants} from '../../../shared/constants/session.constants';

@Component({
  selector: 'app-confirm-surgery-model',
  templateUrl: 'confirm-surgery-model.component.html',
  styleUrls: ['confirm-surgery-model.component.scss'],

})
export class ConfirmSurgeryModelComponent implements OnInit {
  @Input() currentClaim;
  @Input() surgeryMessageBlock;
  @Input() surgery_Message;
  public appConstant = AppConstants;
  public appLabelConstant = AppLabelConstants;
  public confirmSurgeryBlock = true;
  public hideCofirmSurgeryBlock = true;
  public msgBlockConfirmSurgery = true;
  public estSurgeryDate;
  public claimType = '';
  public claimStatus = '';
  private lastdayworkexist: string;
  private estimatedSurgeryDate;
  private confirmedSurgeryDate;
  private updateSurgeryRequest;
  private selectedSurgeryObject;


  constructor(private router: Router, private _updateConfirmSurgeryService: ConfirmSurgeryServiceService,
              private storageMgmtService: StorageManagementService) {
  }

  ngOnInit() {
    this.surgeryCallToAction(this.currentClaim);
    this.confirmSurgerytriggerUpdate(); // Confirm Surgery - Trigger Update
  }

  surgeryCallToAction(currentClaim: any) {
    this.confirmedSurgeryDate = currentClaim.confirmedSurgeryDate;
    if (this.confirmedSurgeryDate) {
      this.hideCofirmSurgeryBlock = false
    }
    this.lastdayworkexist = currentClaim.confirmedLastDayWorkDate;
    this.estSurgeryDate = currentClaim.estimatedSurgeryDate;
    this.estimatedSurgeryDate = currentClaim.estimatedSurgeryDate;
    this.claimType = currentClaim.claimSubType;
    this.claimStatus = currentClaim.claimOnlineStatus;
  }

  showhideSurgery() {
    this.storageMgmtService.setSessionObject(SessionConstants.CUR_CLAIM, this.currentClaim);
    this.storageMgmtService.setSessionData(SessionConstants.COV_CATEGORY, this.currentClaim.coverageCategory);
    this.storageMgmtService.setSessionData(SessionConstants.CONFIRM_SURGERY, true);
    this.storageMgmtService.setSessionData(SessionConstants.IS_LDW_POPULATED, !!this.lastdayworkexist);
    if (this.currentClaim && this.currentClaim.sourceSystemId === AppConstants.SOURCE_SYS_ID_AA) {
      this.storageMgmtService.setSessionData('surgeryNoSelected', false);
      this.router.navigate([AppConstants.CONFIRM_SURGERY_FLOW_THAA]);
    } else {
      this.router.navigate([AppConstants.SURGERY_LAST_DAY_WORK]);
    }
  }

  navToNewSurgaryDate() {
    this.storageMgmtService.setSessionObject(SessionConstants.CUR_CLAIM, this.currentClaim);
    this.storageMgmtService.setSessionData(SessionConstants.COV_CATEGORY, this.currentClaim.coverageCategory);
    if (this.currentClaim && this.currentClaim.sourceSystemId === AppConstants.SOURCE_SYS_ID_AA) {
      this.storageMgmtService.setSessionData('surgeryNoSelected', true);
      this.router.navigate([AppConstants.CONFIRM_SURGERY_FLOW_THAA]); //THAA flow
    } else {
      this.router.navigate([AppConstants.NEW_SURGERY_DATE]);  //existing DCS flow
    }
  }

  confirmSurgerytriggerUpdate() {
    if (this.estimatedSurgeryDate) {
      const startDate = new Date(); // system date
      const endDate = new Date(this.estimatedSurgeryDate);
      if (startDate <= endDate) { // check estimate date is greater than system date or not
        const numOfDates = this.confirmSurgerytriggerLogicFilter(startDate, endDate);

        // This function is used to check day diff between system date and estimate surgery date
        if (numOfDates < 4) { // Check date diff
          this.confirmSurgeryBlock = false; // show yes no options
        } else {
          this.msgBlockConfirmSurgery = false;
        }
      } else {
        this.confirmSurgeryBlock = false; // show yes no options
      }
    }
  }

  confirmSurgerytriggerLogicFilter(startDate, endDate) {
    let count = 0;
    const curDate = startDate;
    while (curDate <= endDate) {
      const dayOfWeek = curDate.getDay();
      if (!((dayOfWeek === 6) || (dayOfWeek === 0))) {
        count++;
      }
      curDate.setDate(curDate.getDate() + 1);
    }
    return count;
  }
}
