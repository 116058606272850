import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { IHorizontalStepper } from './horizontal-stepper.model';

@Component({
  selector: 'app-horizontal-stepper',
  templateUrl: './horizontal-stepper.component.html',
  styleUrls: ['./horizontal-stepper.component.scss']
})
export class HorizontalStepperComponent implements OnInit {
  @Output() changeStep: EventEmitter<IHorizontalStepper> =  new EventEmitter<IHorizontalStepper>();
  @Input() set steps(data: IHorizontalStepper[]) {
    if (data) {
      this._steps = data;
    }
  }
  _steps: IHorizontalStepper[];

  constructor() { }

  ngOnInit() {
  }

  emitChangeStep(event: IHorizontalStepper) {
    this.changeStep.emit(event);
  }
}
