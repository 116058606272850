
import {catchError, map} from 'rxjs/operators';
import {Injectable} from '@angular/core';
import {ResponseErrorHandlerService} from './response-error-handler.service';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {Observable, of} from 'rxjs';
import {env} from '../../../environments/environment-loader';

import {AppLabelConstants} from '../constants/appLabel.constants';
import { StorageManagementService } from './storage-management.service';
import { IntakeSessionConstants } from '@app/cxr/modules/intake/shared/constants/intakeSession.constants';
import { ICoveredReason } from '../models/getCoverages.model';

@Injectable()
export class LeaveReasonServiceService {

  constructor(private httpClient: HttpClient,
              private storeManagementService: StorageManagementService,
              private errorHandler: ResponseErrorHandlerService) { }


  getLeaveReason(personId: string): Observable<any> {
    const coveredReasonList = this.storeManagementService.getSessionData(IntakeSessionConstants.COVERED_REASON_LISTS);
    if (coveredReasonList) {
      return of(coveredReasonList);
    } else {
      const header = {
        headers: new HttpHeaders()
          .set(AppLabelConstants.CONTENT_TYPE_KEY, AppLabelConstants.APPLICATION_KEY)
      };
      return this.httpClient.get(env.gbLeaves.leaveReasonApi + '&personId=' + personId, header).pipe(
        map(
          (response: ICoveredReason) => {
            if (!this.errorHandler.isServiceResponseHasError(response)) {
              this.storeManagementService.setSessionData( IntakeSessionConstants.COVERED_REASON_LISTS, response.availableReason);
              return response;
            } else {
              throw response;
            }
          }),
        catchError((error: any) => this.errorHandler.handleError(error)));
    }
  }

}





