import {env} from '../../../environments/environment-loader';
import { AppConstants } from '../constants/app.constants';

/**
 * TokenUtility handles the basic functionality with required tokens of myBenefits
 * Here there are a variety of generic functions to allow for flexibility in the future in the event
 * token management needs to be updated
 *
 * */

export class TokenUtility {

  private static SITE_ACCESS_KEY = 'siteAccess';
  private static EE_TOKEN = 'EESession';
  private static FR_TOKEN = 'CIAMSession';
  public static FR_TOKEN_LATEST = '';

  /* Generic Functionality */

  static clearToken(cookieName: string) {
    if (env.envName === AppConstants.LOCAL || env.envName === AppConstants.LOCAL_QA || ((env.envName === 'qa' || env.envName === 'pte') && env.proxyState)) {
      document.cookie = cookieName + '=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;';
    } else {
      document.cookie = cookieName + '=; domain=.thehartford.com; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;'; //Domain Clearing
      document.cookie = cookieName + '=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;'; //Any similar to the pathing. Caused Infinite loops
    }
  }

  static addToken(cookieName: string, cookieValue: string) {
    if (env.envName === AppConstants.LOCAL || env.envName === AppConstants.LOCAL_QA  || ((env.envName === 'qa' || env.envName === 'pte') && env.proxyState)) {
      document.cookie = cookieName + '=' + cookieValue + '; path=/;';
    } else {
      document.cookie = cookieName + '=' + cookieValue + '; domain=.thehartford.com; path=/;';
    }
  }

  static getToken(cookieName: string): string {
    let bodyFrToken; let token;
    if (document.cookie) {
      bodyFrToken = (document.cookie.split(';').find(x => x.includes(cookieName)));
      if (bodyFrToken) {
        token = bodyFrToken.split('=')[1];
      }
    }
    if (token != null && token.length > 0) {
      return token;
    } else {
      return ''
    }
  }

  static updateToken(cookieName: string, cookieValue: string) {
    this.addToken(cookieName, cookieValue);
  }

  /* MyBenefits Specific */

  static removeMyBenefitsToken() {
    this.clearToken(this.EE_TOKEN);
  }
  static removeMyBenefitsSiteAccess() {
    this.clearToken(this.SITE_ACCESS_KEY);
  }
  static removeAllMyBenefitsTokens() {
    this.clearToken(this.EE_TOKEN);
    this.clearToken(this.FR_TOKEN);
  }

  static addMyBenefitsToken(tokenValue: string) {
    this.addToken(this.EE_TOKEN, tokenValue)
  }

  static getExistingFrToken(): string {
    return this.getToken(this.FR_TOKEN);
  }

  static setCurrentFrToken(): void {
    this.FR_TOKEN_LATEST = this.getExistingFrToken();
  }

  static getExistingFrTokenState(): boolean {
    return (this.getToken(this.FR_TOKEN) != null && this.getToken(this.FR_TOKEN).length > 0);
  }

  static getExistingMyBenefitsToken(): string {
    return this.getToken(this.EE_TOKEN);
  }

  static getExistingMyBenefitsTokenState(): boolean {
    return (this.getToken(this.EE_TOKEN) != null && this.getToken(this.EE_TOKEN).length > 0);
  }

  static doRequiredTokensExist() {
    if ( env.mocking) {
      return (true);
    } else {
      return this.getExistingFrTokenState() && this.getExistingMyBenefitsTokenState();
    }
  }

  static doEitherRequiredTokensExist() {
    if ( env.mocking) {
      return (true);
    } else {
      return (this.getExistingFrTokenState()) || (this.getExistingMyBenefitsTokenState());
    }
  }

}
