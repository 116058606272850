import {Component, OnInit} from '@angular/core';
import {MessageRibbonService} from '../../../../shared/services/message-ribbon.service';
import {MessageRibbon} from '../../../../shared/models/message-ribbon.model';
import {CustomEventService} from '../../../../shared/services/tealium/custom-event.service';
import {AppLabelConstants} from '../../../../shared/constants/appLabel.constants';

@Component({
  selector: 'message-ribbon-container',
  templateUrl: 'message-ribbon-container.component.html',
  styleUrls: ['message-ribbon-container.component.scss']
})
export class MessageRibbonContainerComponent implements OnInit {

  ribbons: MessageRibbonWrapper[] = [];

  /** Constructs the interaction and service subscriptions for the app wide message ribbons*/
  constructor(private messageRibbonService: MessageRibbonService, public customEventService: CustomEventService) {
      this.messageRibbonService.ribbonClosed$.subscribe(
        ribbon => {
          this.removeRibbon(ribbon.toString());
        });
      this.messageRibbonService.ribbonOpened$.subscribe(
        (ribbon: MessageRibbon) => {
          this.addNewRibbon(ribbon.messageType, ribbon.messageContent, ribbon.messageAutoClose, ribbon.messageHeader, ribbon.messageName);
        });
  }

  ngOnInit() {

  }
  /** Adds a new Ribbon to the queue
   *
   * @param messageTypeValue tells the ribbon the type of message (error,warning,info,success)
   * @param messageContentValue has the content to be displayed to the user
   * @param messageAutoCloseValue
   * @param messageHeaderValue
   * @param messageNameValue
   * */
  addNewRibbon(messageTypeValue: string, messageContentValue: string, messageAutoCloseValue: string, messageHeaderValue: string, messageNameValue: string) {
    this.ribbons.push(
      {
        name: '' + messageNameValue,
        message: {
          messageType: messageTypeValue,
          messageContent: messageContentValue,
          messageAutoClose: messageAutoCloseValue,
          messageHeader: messageHeaderValue,
          messageName: messageNameValue
        }
      }
    );
    if (messageTypeValue && (messageTypeValue.toLowerCase() === AppLabelConstants.WARNING_SM || messageTypeValue.toLowerCase() === AppLabelConstants.ERROR_SM)) {
      this.customEventService.popupErrorTracking(messageTypeValue, messageContentValue);
    }
  }

  /** Removes the ribbon selected by the index
   *
   * @param indexToRemove the ribbon to remove from the stack */
  removeRibbon(nameToRemove: string) {
    for (let i = 0; i < this.ribbons.length; i++) {
      if (this.ribbons[i].name === nameToRemove) {
        this.ribbons.splice(i, 1);
        break;
      }
    }
  }

}

class MessageRibbonWrapper {
  name: string;
  message: MessageRibbon;

  constructor() {

  }

}
