import {FAQResponse} from '../shared/models/faq.model';

export const MockFAQ: FAQResponse = {
  status: '0',
  data:    [
    {
      id: '1444617523525',
      key: 'QID_94',
      question: 'Does critical illness insurance cover your family?',
      answer: 'That depends on whether you chose to cover them when you enrolled. Depending on your plan and your insurer, you may be able to add family members during your open enrollment period for benefits.',
      parentId: '1444617241635'
    },
    {
      id: '1444617523864',
      key: 'QID_96',
      question: 'Should you make separate disability and leave of absence claims if they happen at the same time?',
      answer: 'That depends. If your leave and disability claims occur at the same time, and both products are provided by The Hartford, rest assured that we make the process simple and seamless. But if different providers handle your leave and disability claims, you may need to file separate claims with each provider. Your human resources department guide you through the process.',
      parentId: '1444617241635'
    },
    {
      id: '1444617512333',
      key: 'QID_82',
      question: 'How much does disability insurance pay?',
      answer: 'Disability insurance wonâ€™t replace your entire paycheck, but it will replace a substantial portion. How much? Your plan documents define the exact percentage. It usually ranges from half (50 percent) and two-thirds (67 percent) of your paycheck, depending on your plan.',
      parentId: '1444617241635'
    },
    {
      id: '1444617523028',
      key: 'QID_92',
      question: 'Are critical illness and disability insurance different?',
      answer: 'Yes. Disability insurance protects your income when an illness, injury or pregnancy stops you from working. Critical illness coverage gives you money to pay for things you need while youâ€™re sick â€“ even if you can work. And although theyâ€™re totally different benefits, but could find yourself in a situation where you collect both. For example: If you suffered a heart attack and couldnâ€™t work for eight weeks, you could receive a critical illness payment and also might also file a disability claim.',
      parentId: '1444617241635'
    },
    {
      id: '1444617523028',
      key: 'QID_172',
      question: 'Can I enroll in benefits within this site?',
      answer: 'No. Your employer determines when you can enroll for benefits and which benefits are available to you. Please contact your employer or your benefits administrator for more information about your benefits enrollment period.',
      parentId: '1444617241635'
    },
    {
      id: '1444617523028',
      key: 'QID_173',
      question: 'Do I need to have a claim prior to registering for this site?',
      answer: 'Generally, no. It depends on the information your employer supplies to The Hartford when they set up their plan. But in most cases, you\'ll be able to register without having an active claim ?',
      parentId: '1444617241635'
    },
    {
      id: '1444617513144',
      key: 'QID_84',
      question: 'Do disabilities happen often?',
      answer: 'Disabilities happen more often than you may realize. In fact, nearly one in four workers will suffer some sort of disability during the course of their working life, according to insurance industry research. With odds like that, you should to understand your protections and what to do if you face a short-term disability.',
      parentId: '1444617241635'
    },
    {
      id: '1444617524405',
      key: 'QID_99',
      question: 'Do your benefits last forever?',
      answer: 'No. While you are receiving long-term disability benefits, The Hartford will track your medical milestones and either help your eventual return to work or will help you pursue Social Security Disability insurance to further replace your lost income.',
      parentId: '1444617241635'
    },
    {
      id: '1444617523693',
      key: 'QID_95',
      question: 'Does it cover your family members?',
      answer: 'That depends. When you enroll in your benefits, you can choose whether to cover your family members.  And itâ€™s probably a good idea. Especially if you have kids: Playground injuries lead to more than 200,000 visits a year to emergency rooms. Accident insurance could help you pay for what health insurance wouldnâ€™t.',
      parentId: '1444617241635'
    },
    {
      id: '1444617523358',
      key: 'QID_93',
      question: 'Why do you need critical illness coverage?',
      answer: 'Medical bills are a big problem for everyone. In fact, health care costs are the leading cause of most personal bankruptcies, and most of those bankruptcies happen to people with health insurance. Critical illness insurance helps you keep your finances in order so you can focus on recovering and prevailing over serious health conditions.',
      parentId: '1444617241635'
    },
    {
      id: '1444617524037',
      key: 'QID_97',
      question: 'Why do you need hospital indemnity coverage?',
      answer: 'A 4-day hospital stay could cost you as much as $10,000, according to insurance industry research. And that doesnâ€™t even include all the other expenses that can pop up while youâ€™re out of commission for a few days. A serious illness affects every part of your life. Hospital indemnity coverage helps take care of the financial burdens illnesses can create.',
      parentId: '1444617241635'
    },
    {
      id: '1444617512509',
      key: 'QID_83',
      question: 'What are common short-term disability claims?',
      answer: 'Some of the common conditions that are covered by short-term disability insurance include: â€¢ Pregnancyâ€¢ Maternity leaveâ€¢ Surgery (and recovery from surgery)â€¢ Accidentsâ€¢ Musculoskeletal conditionsâ€¢ Stress-related illnessesâ€¢ Pneumonia â€¢ Rehabilitationâ€¢ Runner\'s kneeâ€¢ Whiplashâ€¢ Tennis elbowâ€¢ Bunion removalâ€¢ Carpal tunnel syndromeâ€¢ Stress/anxietyâ€¢ Tendonitisâ€¢ Herniated disksâ€¢ Broken bonesâ€¢ Other injuries and illnesses',
      parentId: '1444617241635'
    },
    {
      id: '1444617522843',
      key: 'QID_91',
      question: 'What happens after you file for a leave of absence?',
      answer: 'That depends on the type of leave you\'ve requested. We may need to contact your medical provider to obtain more information. Then weâ€™ll assign one of our analysts to review your eligibility, relevant federal and state laws, your employerâ€™s handbook and other documents. Your analyst will update you on your claim status, discuss your claim more if needed, and help you figure out a date when you\'ll be ready to return to work.',
      parentId: '1444617241635'
    },
    {
      id: '1444617505661',
      key: 'QID_80',
      question: 'Why do we call it â€œdisability?â€�',
      answer: 'Insurers like us use that word a little differently than you may be used to. Sometimes when people hear â€œdisability,â€� they think of a condition that someone was born with. But in insurance, we use that word to mean an illness, injury or condition that prevents a person from doing something they used to be â€œableâ€� to. Pregnancy, for instance, could be called a disability. Or recovery from surgery.',
      parentId: '1444617241635'
    },
    {
      id: '1444617522637',
      key: 'QID_90',
      question: 'Can someone else file your claim for you?',
      answer: 'Yes. If you are incapacitated, hospitalized or canâ€™t complete claim forms on your own, then your authorized representative can file your claim on your behalf.',
      parentId: '1444617241635'
    },
    {
      id: '1444617524229',
      key: 'QID_98',
      question: 'What happens when youâ€™re ready to go back to work?',
      answer: 'If you\'re ready to go back to work in either a full or part-time capacity, we work with you and your employer on any workplace modifications you may need, and our support continues in other ways. At The Hartford, we know that a disability goes well beyond the physical. That\'s why you have the added help of ability assist counseling services. This added benefit gives you access to confidential support for any emotional, legal and financial issues you may have during your disability event.',
      parentId: '1444617241635'
    },
    {
      id: '1444617513342',
      key: 'QID_85',
      question: 'Are long-term and short-term disability insurance different?',
      answer: 'Yes. Short-term disability insurance and long-term disability insurance complement each other in some ways, but are entirely different coverage and plans. Both will replace your lost income if a covered disability keeps you out of work. But they differ when coverage begins and ends. â€¢ Short-term disability benefits are designed to start soon after youâ€™re disabled (usually five days) and continue paying for a defined length of time.â€¢ Long-term disability benefits are designed to start after youâ€™ve been disabled for a period of time, and continue paying until youâ€™ve recovered or until you need to file for Social Security. As with any coverage, refer to your plan documents for specific details of your disability benefits.',
      parentId: '1444617241635'
    },
    {
      id: '1444617515459',
      key: 'QID_86',
      question: 'Is short-term disability the same thing a leave of absence?',
      answer: 'Short-term disability insurance is not the same thing as a leave of absence, although they often occur at the same time. A leave of absence is a formal process that allows you to temporarily stop working because of legally allowed reason. A federal law called the Family and Medical Leave Act (FMLA) defines your eligibility and the protections youâ€™re entitled to. Youâ€™re allowed to take up to 12 weeks during any 12-month period, as long as you meet certain conditions. You wonâ€™t be paid during your leave-of-absence, but your employer must protect your job and allow you to return to it. Common reasons for a leave of absence include: â€¢ Pregnancy and maternity leaveâ€¢ Serious health conditions â€¢ Caregiving for a sick family member Many states have laws that allow you to take leaves for even more reasons, such as school visitations. And many employers allow leaves of absence for things that go beyond whatâ€™s required by law. Examples include personal leave, education leave, sabbaticals, and extended maternity and paternity leaves. Although disability insurance and leaves of absence are different, employees common take them for related reasons. For example, a new mother might receive short-term disability benefits while she recovers from childbirth. But once she medically recovers and is physically able to return to work, she could still use a leave of absence to stay home and care for her child. That overlap between short-term disability and leave of absence happens often.',
      parentId: '1444617241635'
    }
    ,
    {
      id: '1444617512106',
      key: 'QID_81',
      question: 'What does â€œshort-termâ€� mean?',
      answer: 'So whatâ€™s â€œshort-term?â€� Well, that depends on your particular disability insurance plan. But it usually means your illness, injury or condition keeps you out of work longer than five days but less than two years.',
      parentId: '1444617241635'
    }
  ]
};



export const MockLeavePlanFAQ: FAQResponse = {
          status: '0',
  data: [
    {
      id: '1444617462372',
      key: 'QID_39',
      question: 'What\'s the difference between Short-Term Disability (STD) and a Leave of Absence?',
      answer: '<div>Short-Term Disability provides partial income replacement when you&#39;re disabled under the terms of your plan.<\/div><div>&nbsp;<\/div><div>A Leave of Absence provides job protection for your time off work. Unlike Short-Term Disability, leaves generally don&#39;t replace any income while you&#39;re out of work.<\/div>',
      parentId: '1444617241635'
    },
    {
      id: '1444656437564',
      key: 'QID_211',
      question: 'How much FMLA time do I get?',
      answer: '<div>If you&rsquo;re approved, FMLA gives you 12 weeks per calendar year.&nbsp;<\/div><div>&nbsp;<\/div><div>See also:<\/div><div>Does my FMLA balance renew at the beginning of the year?<\/div>',
      parentId: '1444617241635'
    },
    {
      id: '1444656436834',
      key: 'QID_197',
      question: 'What is a qualifying relationship?',
      answer: '<div>Sample Leave Overview Answer 2<\/div>',
      parentId: '1444617241635'
    },
    {
      id: '1444656437013',
      key: 'QID_201',
      question: 'When will I be paid FMLA benefits?',
      answer: '<div>The Family and Medical Leave Act (FMLA) doesn&rsquo;t pay monetary benefits. The purpose of this leave is to protect your job while you&rsquo;re unable to work.&nbsp;<\/div><div>&nbsp;<\/div><div>If you&rsquo;re out on any kind of Disability claim at the same time as your FMLA, you may get benefits as part of that claim. Those are usually paid on the same schedule as your regular paychecks, but that can vary depending on how your employer&rsquo;s plan is set up.<\/div>',
      parentId: '1444617241635'
    },
    {
      id: '1444656437059',
      key: 'QID_202',
      question: 'How do I know if Iâ€™m eligible for Family and Medical Leave (FMLA) benefits?',
      answer: '<div>Eligibility for FMLA depends on three things:<\/div><div>&nbsp;<\/div><div>First, your location. To be covered by FMLA job protection benefits, you have to work for a covered employer at a location where at least 50 employees work. If you work from home or at a satellite office, you have to work within 75 miles of a location that employs at least 50 people.<\/div><div>&nbsp;<\/div><div>Second, your employment history. You must have worked for your employer for a minimum of 12 months. Within those 12 months, you have to have worked at least 1,250 hours.<\/div><div>&nbsp;<\/div><div>Third, the reason for your leave request. To be covered by FMLA, you have to be in one of the following situations:<\/div><ul>\t<li>\t\tYou cannot work because of a serious medical condition<\/li>\t<li>\t\tYou need time off to care for an immediate family member who has a serious medical condition<\/li>\t<li>\t\tYou need time off to give birth to and care for your new child<\/li>\t<li>\t\tYou need time off to handle placement and care of your adopted child or foster child<\/li>\t<li>\t\tYour spouse, child, or parent is on active military duty for the National Guard or Reserve, and you need time off to help them prepare for deployment or return from deployment (This is also known as a &ldquo;qualifying exigency&rdquo;)<\/li><\/ul>',
      parentId: '1444617241635'
    },
    {
      id: '1444656437209',
      key: 'QID_205',
      question: 'What can I do if I donâ€™t qualify for Family and Medical Leave (FMLA) benefits?',
      answer: '<div>If your employer offers their own leave, you might qualify for that even if you&rsquo;re not eligible for FMLA. State leaves might also be available, depending on where you live and work. Check with your employer or contact us to learn more.<\/div>',
      parentId: '1444617241635'
    },
    {
      id: '1444656437263',
      key: 'QID_206',
      question: 'What is the 75% exhaustion letter?',
      answer: '<div>We send this letter to you when you&rsquo;ve used 75% of your available leave time. When you get this letter, it means you have 3 weeks of leave left to use for the year.<\/div><div>&nbsp;<\/div><div>See also:&nbsp;<\/div><div>Does my FMLA balance renew at the beginning of the year?<\/div>',
      parentId: '1444617241635'
    },
    {
      id: '1444656437319',
      key: 'QID_207',
      question: 'What is the 100% exhaustion letter?',
      answer: '<div>We send this letter to you when you&rsquo;ve used all of your available leave time in one or all of your leaves. The letter will have details on the leave(s) you&rsquo;ve used up.<\/div><div>&nbsp;<\/div><div>See also:&nbsp;<\/div><div>Does my FMLA balance renew at the beginning of the year?<\/div><div>I got a letter saying that my FMLA benefit is exhausted. I&rsquo;m still out of work on Short-Term Disability. Am I fired?<\/div>',
      parentId: '1444617241635'
    },
    {
      id: '1444656437377',
      key: 'QID_208',
      question: 'Can I become eligible for leave if Iâ€™m already out of work?',
      answer: '<div>Sample&nbsp; Answer<\/div>',
      parentId: '1444617241635'
    },
    {
      id: '1444656437437',
      key: 'QID_209',
      question: 'Can I appeal if my FMLA request is denied?',
      answer: '<div>Right now, there&rsquo;s no appeal process if your FMLA request is denied.<\/div><div>&nbsp;<\/div><div>If your request is closed and we receive a certification from your healthcare provider, we&rsquo;ll check with your employer. We&rsquo;d need their approval to re-open your claim for review.<\/div><div>&nbsp;<\/div><div>See also:<\/div><div>Can my employer see my medical information when I apply for a leave?<\/div>',
      parentId: '1444617241635'
    },
    {
      id: '1444656437821',
      key: 'QID_213',
      question: 'Does my FMLA balance renew at the beginning of the year?',
      answer: '<div>FMLA is tracked on a rolling 12-month calendar. Your balance is based on your usage over the past 12 months.<\/div>',
      parentId: '1444617241635'
    },
    {
      id: '1444656438043',
      key: 'QID_216',
      question: 'I got a letter saying that my FMLA benefit is exhausted. Iâ€™m still out of work on Short-Term Disability. Am I fired?',
      answer: '<div>Unfortunately, we&rsquo;re not able to answer that.&nbsp;<\/div><div>&nbsp;<\/div><div>The letter you got was to let you know that you used up all 12 weeks of your FMLA benefit for the year. While you&rsquo;re on leave under FMLA, your job is federally protected and employer can&rsquo;t fire you. But once your FMLA runs out, the federal protection goes away.&nbsp;<\/div><div>&nbsp;<\/div><div>This doesn&rsquo;t mean that you&rsquo;re automatically fired. Your employment status is your employer&rsquo;s decision, not The Hartford&rsquo;s. Talk to your employer to find out how further absences may affect you.&nbsp;<\/div><div>&nbsp;<\/div><div>As long as you&rsquo;re still employed and still eligible, you can continue receiving STD benefits after your FMLA is exhausted.<\/div>',
      parentId: '1444617241635'
    },
    {
      id: '1444656439064',
      key: 'QID_224',
      question: 'When will I earn time on my leave?',
      answer: '<div>We can&rsquo;t answer this one without looking at your actual leave. Give us a call at <span style="color:#ff0000;">&lt;&lt;number&gt;&gt;<\/span> and we can go over your leave with you.<\/div>',
      parentId: '1444617241635'
    },
    {
      id: '1444659465394',
      key: 'QID_227',
      question: 'What are the different kinds of leaves out there? How many are there?',
      answer: '<div><div>There are several kinds of leaves that may be available to you.<\/div><div>&nbsp;<\/div><div>Federal leaves, like the Family and Medical Leave Act, are available to anyone as long as you qualify.<\/div><div>&nbsp;<\/div><div>Some states also provide state leaves. The rules for eligibility can vary, depending on what state you&rsquo;re in.<\/div><div>&nbsp;<\/div><div>Your employer may also offer a leave program, separate from any federal or state leave. If your employer offers a leave, eligibility requirements are up to them.<\/div><div>&nbsp;<\/div><div>Check with your employer or give us a call at <span style="color:#ff0000;">&lt;&lt;number&gt;&gt;<\/span> to learn more about what&rsquo;s available to you.<\/div><div>&nbsp;<\/div><div>See also:<\/div><div>Links to any applicable product pages<\/div><\/div><div>&nbsp;<\/div>',
      parentId: '1444617241635'
    },
    {
      id: '1444659468006',
      key: 'QID_233',
      question: 'What if the reason I need leave changes?',
      answer: '<div>Sample Answer<\/div>',
      parentId: '1444617241635'
    },
    {id: '1444716817251',
key: 'QID_250',
question: 'Do I need to report other income or benefits I\'m getting?',
answer: '<div><div>Yes. Reporting your other income sources helps us make sure your benefit amount is right.&nbsp;</div><div>&nbsp;</div><div>Some types of income will mean we need to reduce your disability benefit amount. Others won&#39;t. It depends on your plan, so it&#39;s important for us to know. If you have other income sources and we don&#39;t know about them, you could end up with an overpayment which you&#39;d have to pay back.</div></div><div>&nbsp;</div>',
parentId: '1444617241635'
},
{
    id: '1444716817348',
    key: 'QID_251',
    question: 'What is a minimum benefit?',
    answer: '<div>If your plan has a minimum benefit, it means that you&#39;re guaranteed to get at least that amount. Minimum benefits can vary depending on the plan. You may still go below the minimum if your plan takes out other deductions, like medical, dental or taxes.</div>',
    parentId: '1444617241635'
},
{
    id: '1444716817447',
    key: 'QID_252',
    question: 'What is a maximum benefit?',
    answer: '<div><div>A maximum benefit is a cap on how much you can receive in benefits. The amount can vary by plan.</div><div>&nbsp;</div><div>If you&#39;re a high earner and your plan has a max benefit, you&#39;ll automatically be bumped down to the plan max.</div></div><div>&nbsp;</div>',
    parentId: '1444617241635'
},
{id: '1444716817937',
key: 'QID_255',
question: 'What is FICA? ',
answer: '<div><div>FICA stands for Federal Insurance Contributions Act. It&#39;s the combination of Social Security and Medicare taxes.&nbsp;</div><div>&nbsp;</div><div>Only the taxable part of your benefit is subject to FICA. The tax is applied to all payments issued within the &quot;FICA period&quot;. That starts on the day following your last day worked, and is applied for the rest of that month and the next six months after that. If you&#39;re still getting a benefit after that time, FICA would drop off.&nbsp;</div><div>&nbsp;</div><div>Some taxable benefits aren&#39;t subject to FICA. If your benefit has FICA taken out, you&#39;ll see that on your pay statement.</div></div><div>&nbsp;</div>',
parentId: '1444617241635'
},
{
    id: '1444716818217',
    key: 'QID_256',
    question: 'How does tax withholding work on my benefit?',
    answer: '<div><div>If your benefits are taxable, state and federal income tax withholding is optional. We&#39;ll only apply these taxes to your benefit if you submit the appropriate form(s).&nbsp;</div><div>&nbsp;</div><div>For state income tax withholding, visit your state website to find the form you need. Some states, like Florida and Nevada, may not be subject to state income tax withholding.</div><div>&nbsp;</div><div>For federal income tax withholding, you&#39;ll need to submit a W4-S form. You can find that form on the IRS website.</div></div><div>&nbsp;</div>',
    parentId: '1444617241635'
},
{
  id: '1444716818217',
  key: 'QID_257',
  question: 'How does tax withholding work on my benefit?',
  answer: '<div><div>If your benefits are taxable, state and federal income tax withholding is optional. We&#39;ll only apply these taxes to your benefit if you submit the appropriate form(s).&nbsp;</div><div>&nbsp;</div><div>For state income tax withholding, visit your state website to find the form you need. Some states, like Florida and Nevada, may not be subject to state income tax withholding.</div><div>&nbsp;</div><div>For federal income tax withholding, you&#39;ll need to submit a W4 form. You can find that form on the IRS website.</div></div><div>&nbsp;</div>',
  parentId: '1444617241635'
},
{
    id: '1444717226617',
    key: 'QID_267',
    question: 'Will my future payments always be the same amount?',
    answer: '<div>They might, but they might not. Payment amounts could be different depending on how your benefit was designed or how many days are paid within each payment. Make sure you look into the details of each future payment to see how the amounts are calculated.</div>',
    parentId: '1444617241635'
}
  ]
        };


