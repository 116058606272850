import {Component, OnInit} from '@angular/core';
import {ProductService} from '../../shared/services/product.service';
import {Product} from '../../shared/models/product.model';
import {ActivatedRoute, ParamMap} from '@angular/router';
import {AuthStateService} from '../../shared/services/auth-state.service';
import {ErrorConstants} from '../../shared/constants/error.constants';
import {StorageManagementService} from '../../shared/services/storage-management.service';


@Component({
  selector: 'app-successor-product-list',
  templateUrl: './successor-product-list.component.html',
  styleUrls: ['./successor-product-list.component.scss']
})
export class SuccessorProductListComponent implements OnInit {
  private productList;
  private perRow: number;
  private successorProductList: any[] = [];
  public successorProducts: Product[] = [];
  private isLoggedIn = false;
  public id;

  constructor(private productService: ProductService,
              private route: ActivatedRoute,
              private authStateService: AuthStateService,
              private storageManagementService: StorageManagementService) { }

  ngOnInit() {
    this.isLoggedIn = this.authStateService.getLoginState();

    this.productList = this.productService.getPostLogInProduct();
    this.route.paramMap
      .subscribe(
      (params: ParamMap) => {
        this.successorProducts = [];
        this.successorProductList = [];

        this.productService.getSuccessorProductList().subscribe(data => {
          if (data && data.response) {
            this.successorProductList = data.response;

            try {
              for (const productKey of  this.successorProductList) {
                for (const product of this.productList) {
                  if (productKey.uiFieldId === product.prd_key) {
                    this.successorProducts.push({
                      id: product.id,
                      prdKey: product.prdKey,
                      name: product.name,
                      description: product.description,
                      disclosure : product.disclosure,
                      benefitsOfferBy: product.benefitsOfferBy,
                      icon: product.icon,
                      location: product.location,
                      covType: product.covType,
                      covCode: product.covCode,
                      prd_key: product.prd_key,
                      isVoluntary: product.isVoluntary
                    })
                  }
                }
              }

              if (this.successorProducts && this.successorProducts.length > 6) {
                this.determineRows(this.successorProducts.length, 1);
              }

              if (this.successorProducts && this.successorProducts.length > 0) {
                this.storageManagementService.setSessionData('successorProduct', this.successorProducts);

                // US156881 - Check if any successor product from the successor products list
                // is not available in overall products list and add it to the list.
                if (this.storageManagementService.getSessionData('overAllProducts')) {
                  let overAllProducts: Product[] = this.storageManagementService.getSessionData('overAllProducts');
                  this.successorProducts.forEach((successorProduct)=>{
                    if(!overAllProducts.some((product)=>product.prdKey === successorProduct.prdKey)){
                      overAllProducts.push(successorProduct);
                      this.storageManagementService.setSessionData('overAllProducts', overAllProducts);
                    }
                  });
                } else {
                  this.storageManagementService.setSessionData('overAllProducts', this.successorProducts);
                }
                this.productService.isProductExist.next(true);
              } else {
                this.storageManagementService.setSessionData('successorProduct', []);
                this.productService.isProductExist.next(false);
              }

            } catch (error) {
              console.log(ErrorConstants.ERROR_WHILE_FILTERING_PROD);
              console.log(error.message);
            } finally {}
          }
        }, (error) => {
        });
      }
    );


  }

  determineRows(items: number, setRows: number): void {
    if (Math.ceil(items / setRows) > 6) {
      this.determineRows(items, (setRows + 1));
    } else {
      this.perRow = (Math.ceil(items / setRows));
    }
  }
}
