import {GbBase} from './gb-base';

export class GbBlazebom extends GbBase {

  getUrls() {
    return {

    }
  }
}

