import { Injectable } from '@angular/core';
import {ResponseErrorHandlerService} from '@shared/services/response-error-handler.service';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {env} from '@env/environment-loader';
import {AppLabelConstants} from '@shared/constants/appLabel.constants';
import { Observable, of, throwError } from 'rxjs';
import {catchError, map} from 'rxjs/operators';
import {IntakeSessionConstants} from '@app/cxr/modules/intake/shared/constants/intakeSession.constants';
import {StorageManagementService} from '@shared/services/storage-management.service';
import {ContactInfoList, IContactInfo} from '@app/cxr/modules/intake/shared/models/contact-Info.model';

@Injectable()
export class ContactInfoService {

  constructor(private errorHandler: ResponseErrorHandlerService,
              private storeManagementService: StorageManagementService,
              private httpClient: HttpClient) { }
  public intakeContactInfo(personId: string): Observable<ContactInfoList> {
    const contactInfo = this.storeManagementService.getIntakeSessionData(IntakeSessionConstants.CONTACT_INFO);
    if (contactInfo) {
      return of(contactInfo);
    } else {
      if (personId) {
        return this.httpClient.get(env.thaaWKABPortalService.intakeContactInfo + '&personId=' + personId,
          {
            headers: new HttpHeaders()
              .set(AppLabelConstants.CONTENT_TYPE_KEY, AppLabelConstants.APPLICATION_KEY)
          }).pipe(map(
          (response: IContactInfo) => {
            if (!this.errorHandler.isServiceResponseHasError(response)) {
              this.storeManagementService.setIntakeSessionData(IntakeSessionConstants.CONTACT_INFO, response.contactInfo);
              return response.contactInfo;
            } else {
              throw response;
            }
          }
        ), catchError((error: any) => this.handleError(error)));
      }
    }
  }

  handleError(error: Response) {
    return throwError(error);
  }
}
