import {Injectable} from '@angular/core';




@Injectable()
export class ErrorMessagingService {



  myErrors = {
    validateSequentialDigits: 'Please enter a valid phone number',
    validateSequentialDigitsFax: 'Please enter a valid fax number',
    validateConsecutiveDigits: 'Please enter a valid phone number',
    validateConsecutiveDigitsFax: 'Please enter a valid fax number',
    ValidateTwoDigitsRequired: 'Please enter a two-digit number',
    validateInteger: 'The length of extension should be numeric digits',
    validateZeroErrMsg: 'Please enter a value greater than 0',
    required: ' is required.',
    RequiredEmail : 'We need your email address to continue.',
    RequiredMobile : 'We need your mobile number to continue.',
    RequiredDocNumber : 'We need your doctor\s phone number to continue.',
    RequiredFax : 'We need your doctor\s fax number to continue.',
    validateEmail: 'Please enter an email in the format xxx@xxx.xxx',
    getEmailValidator: 'Hm, that\'s not quite right. Please enter your email in this format: xxx@xxx.com.',
    mobileNumberValidator: 'Looks like that might not be a real number. Please double check and try again.',
    pattern: 'Please enter a valid pattern',
    AccountTypeValidator: 'Please Select Account Type.',
    minLength: 'Please Provide minimum length',
    maxLength: 'Length is too much',
    minlength: ' length is short',
    accMinlength: ' Your account number should be in-between 5 and 17 digits.',
    maxlength: 'Length is too much',
    incorrectRoutingNo: 'Routing Number is incorrect.',
    invalidZip: 'Please double check your zip code and re-enter.',
    validateMinLength: 'The mobile number must be 10 numeric digits.',
    incorrectInsuredId: 'It looks like that didn’t work. Please double-check your information and try again.',
    passwordMismatch: 'These don’t match. Please try again.',
    newAndOldPasswordSame: 'The new and old password cannot be same.',
    validatePolicyNumber: 'The account number must be 6 numeric digits',
    NameValidator: 'Please enter a valid pattern',
    policyNumberNotFound: `We can't find that policy number, please try again.`,
    validateFaxMinLength: 'The fax number should be 10 numeric digits',
    validatePhoneMinLength: 'The phone number should be 10 numeric digits',
    intakeError: 'Please answer before submitting.',
    otherReasonError: 'Please enter a reason',
    RequestExtReasonError: 'Please enter a reason for your extension',
    RequestExtReasonDropDownError: 'Please make a selection',
    gapList: 'Please select a reason',
    docForm: 'Please enter your doctor\'s name',
    docFormError: 'Please enter your doctor\'s name',
    days: 'Please make a selection',
    babyName: 'Please tell us your baby\'s name',
    babiesName: 'Please tell us your babies\' names',
    placeHolderForRedBorder: '',
    amountlessthan10: 'Amout Should 10 or more'

  };
  constructor() {

  }
  getError(errKey) {
    return this.myErrors[errKey];
  }
}
