/**
 * Created by AC12396 on 4/4/2019.
 */
export const AASCHEDULEDCALL = {
  hasAppointments: false,
  PreviousAppointmentDetails:    {
    ClaimID: 100023,
    AppointmentDate: 'Monday, May 21, 2019',
    AppointmentID: 10852,
    StartTime: '4:30 PM Pacific',
    ClaimOwner: 'LOPAMUDRA MISHRA'
  },
  claimList: null,
  hasAvailableRepresnetatives: true,
  responseCode: '001',
  responseReason: 'success'
};
/*ERROR RESPONSE FOR ALREADY SELECTED SLOT */
 /* {
    "confirmedAppointmentDate": null,
    "confirmedAppointmentTime": null,
    "ClaimOwner": null,
    "AppointmentId": 0,
    "responseCode": "002",
    "responseReason": "Looks like 11:00 am Eastern time on Thursday, July 30, 2020 has already been taken. Please choose a different time slot or return home."
  };*/
