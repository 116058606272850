
/**
 * Created by AC12394 on 10/12/2018.
 */

export class ClaimCaseMapping {
  caseId: string;
  claimEventId: string;

  constructor(caseId: string, claimEventId: string) {
    this.caseId = caseId;
    this.claimEventId = claimEventId;
  }
}

export class ClaimLeaveMapping {
  claimEventId: string;
  hasAssociatedLeave: boolean;

  constructor(claimEventId: string, hasAssociatedLeave: boolean) {
    this.claimEventId = claimEventId;
    this.hasAssociatedLeave = hasAssociatedLeave;
  }
}


export class CaseLeaveMapping {
  caseId: string;
  isLeaveApplicable: boolean;

  constructor(caseId: string, isLeaveApplicable: boolean ) {
    this.caseId = caseId;
    this.isLeaveApplicable = isLeaveApplicable;
  }
}

export class PolicyAdminLeaveResponse {
  leaveResponseList: LeaveResponse[]
}

export class LeaveResponse {
  public caseId: string;
  public provisionId: string;
  public provisionDescription: string;
  public provisionLevel: string;
  public provisionType: string;
  public provisionOptionId: string;
  public provisionOptionValue: string;
  public provisionOptionEffectiveDate: string;
  public provisionOptionTerminationDate: string;
}

export class ClaimsLeaveResponse {
  public leaveDetailsList: ClaimLeaveDetails[];
}

export class ClaimLeaveDetails {
  public claimEventId: string;
  public leaveId: string;
  public caseId: string;
  public url: string;
}

export class AppLeaveInfo {
  public claimCaseMappings: ClaimCaseMapping[];
  public caseLeaveDetails: CaseLeaveMapping[];
  public claimLeaveDetails: ClaimLeaveDetails[];
  public defaultCaseId: string;
  public leaveEligibleClaimEventIds: string[];
  public leaveApplicableCaseIds: string[];
}
