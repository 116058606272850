/**
 * Created by AC12396 on 12/13/2018.
 */

import { GbBase } from "./gb-base";

export class THAAWKABPortalService extends GbBase {
  getUrls() {
    return {
      getAetnaContactURL:
        this.getBaseUrl() + "gb/net/member/contact?" + this.getThaaClientId("auth"),
      getClaimRepresentativeDetailsURL:
        this.getBaseUrl() + "gb/net/claims/handler?" + this.getThaaClientId("auth"),
      getLeaveRepresentativeDetailsURL:
        this.getBaseUrl() + "gb/net/claims/leave/handler?" + this.getThaaClientId("auth"),
      getClaimPaymentsURL:
        this.getBaseUrl() + "gb/net/claims/payments?" + this.getThaaClientId("auth"),
      getRecentlyResolvedAetnaURL:
        this.getBaseUrl() + "gb/net/claims/recently-resolved-items?" + this.getThaaClientId("auth"),
      getRecentlyResolvedLeaveThaaURL:
        this.getBaseUrl() +
        "gb/net/claims/leave/recently-resolved-items?" +
        this.getThaaClientId("auth"),
      getAAClaimActivityURL:
        this.getBaseUrl() + "gb/net/claims/activities?" + this.getThaaClientId("auth"),
      getScheduleStatusURL:
        this.getBaseUrl() + "gb/net/appointment?" + this.getThaaClientId("auth"),
      submitAppointmentURL:
        this.getBaseUrl() + "gb/net/appointment?" + this.getThaaClientId("auth"),
      cancelAppointmentURL:
        this.getBaseUrl() + "gb/net/appointment/cancel?" + this.getThaaClientId("auth"),
      getAAUpdateClaimAttribute:
        this.getBaseUrl() + "gb/net/claims/update-claim-attribute?" + this.getThaaClientId("auth"),
      getAAUpdateSurgeryClaimAttribute:
        this.getBaseUrl() +
        "gb/net/claims/update-claim-surgery-attribute?" +
        this.getThaaClientId("auth"),
      getAAUpdateDeliveryClaimAttribute:
        this.getBaseUrl() +
        "gb/net/claims/update-claim-delivery-attribute?" +
        this.getThaaClientId("auth"),
      getAAUpdateDisabilityClaimAttribute:
        this.getBaseUrl() + "update-claim-disability-attribute?" + this.getThaaClientId("auth"),
      getAARequestExtension:
        this.getBaseUrl() + "gb/net/claims/extension?" + this.getThaaClientId("auth"),
      getLettersListURL:
        this.getBaseUrl() + "gb/net/documents/letters?" + this.getThaaClientId("auth"),
      getLettersListLeaveURL:
        this.getBaseUrl() + "gb/net/documents/letters/leave?" + this.getThaaClientId("auth"),
      getLetterImageURL:
        this.getBaseUrl() + "gb/net/documents/letter-image?" + this.getThaaClientId("auth"),
      taxFormURL: this.getBaseUrl() + "gb/net/taxforms?" + this.getThaaClientId("auth"),
      getTaxFormPhoneNumber:
        this.getBaseUrl() + "gb/net/documents/tax/phone-number?" + this.getThaaClientId("auth"),
      getTaxFormPDFURL: this.getBaseUrl() + "gb/net/taxforms/pdf?" + this.getThaaClientId("auth"),
      getNotificationPreference:
        this.getBaseUrl() + "gb/net/employee/preference?" + this.getThaaClientId("auth"),
      getPreferredName:
        this.getBaseUrl() + "gb/net/employee/preferredName?" + this.getThaaClientId("auth"),
      thaaConfirmationURL:
        this.getBaseUrl() + "gb/net/claims/confirmation?" + this.getThaaClientId("auth"),
      thaaMissingInfoURL:
        this.getBaseUrl() + "gb/net/claims/missing-info?" + this.getThaaClientId("auth"),
      thaaMissingInfoLeaveURL:
        this.getBaseUrl() + "gb/net/claims/leave/missing-info?" + this.getThaaClientId("auth"),
      thaaUpdateMI:
        this.getBaseUrl() + "gb/net/claims/missing-info?" + this.getThaaClientId("auth"),
      thaaGetAPSURL:
        this.getBaseUrl() + "gb/net/claims/extension/aps?" + this.getThaaClientId("auth"),
      thaaGetEsignFormURL:
        this.getBaseUrl() + "gb/net/alerts/ecosign-url?" + this.getThaaClientId("auth"),
      thaaMissInfoDownloadFormURL:
        this.getBaseUrl() + "gb/net/alerts/form-download?" + this.getThaaClientId("auth"),
      thaaClaimsDetailURL:
        this.getBaseUrl() + "gb/net/claims/detail?" + this.getThaaClientId("auth"),
      getFuturePaymentURL:
        this.getBaseUrl() + "gb/net/claims/payments/future?" + this.getThaaClientId("auth"),
      getFuturePaymentExistURL:
        this.getBaseUrl() + "gb/net/claims/payments/future/exists?" + this.getThaaClientId("auth"),
      thaaClaimReportedReturnToWork:
        this.getBaseUrl() + "gb/net/claims/ReportedReturnToWork?" + this.getThaaClientId("auth"),
      thaaADAUpdateAccommodation:
        this.getBaseUrl() + "gb/net/claims/ada/accommodation?" + this.getThaaClientId("auth"),
      thaaADAIntermittentRequestDetailView:
        this.getBaseUrl() + "gb/net/claims/ada/intermittent/detail?" + this.getThaaClientId("auth"),
      thaaNewExtension:
        this.getBaseUrl() + "gb/net/claims/extension/v2/enable?" + this.getThaaClientId("auth"),
      thaaADAUpdateAccommodationSubmitURL:
        this.getBaseUrl() + "gb/net/claims/ada/accommodation?" + this.getThaaClientId("auth"),
      thaaPsvDataUrl:
        this.getBaseUrl() + "gb/thaapsv/thaaapi/api/psv/psvdata?" + this.getClientId("auth"),
      thaaPsvTtsUrl:
        this.getBaseUrl() + "gb/thaapsv/thaaapi/api/psv/psvtts?" + this.getClientId("auth"),
      thaaPsvVideoAlertsForClaimURL:
        this.getBaseUrl() + "gb/net/alerts/mb-psv/video?" + this.getThaaClientId("auth"),
      adaTimelineDetails:
        this.getBaseUrl() + "gb/net/claims/ada/timeline?" + this.getThaaClientId("auth"),
      productFormsToggle:
        this.getBaseUrl() + "gb/net/company/product-forms?" + this.getThaaClientId("auth"),
      getLibraryForms:
        this.getBaseUrl() +
        "gb/net/company/product-forms/forms-list?" +
        this.getThaaClientId("auth"),
      newExtensionicdcptInfo:
        this.getBaseUrl() +
        "gb/net/claims/extension/v2/icd-cpt-codes?" +
        this.getThaaClientId("auth"),
      getHealthProviderDetails:
        this.getBaseUrl() + "gb/net/claims/extension/v2/provider?" + this.getThaaClientId("auth"),
      downloadProductForm:
        this.getBaseUrl() + "gb/net/company/product-forms/download?" + this.getThaaClientId("auth"),
      psvToggle: this.getBaseUrl() + "gb/net/alerts/mb-psv/ft?" + this.getThaaClientId("auth"),
      specialtyList:
        this.getBaseUrl() + "gb/net/claims-intake/specialty?" + this.getThaaClientId("auth"),
      stateList: this.getBaseUrl() + "gb/net/claims-intake/state?" + this.getThaaClientId("auth"),
      downloadPaystub:
        this.getBaseUrl() + "gb/net/claims/payments/paystub?" + this.getThaaClientId("auth"),
      employeeToggle: this.getBaseUrl() + "gb/net/toggle/employee?" + this.getThaaClientId("auth"),
      employeeActiveStatusCheck:
        this.getBaseUrl() + "gb/net/employee/status?" + this.getThaaClientId("auth"),
      newRAEFinalSubmit:
        this.getBaseUrl() + "gb/net/claims/extension/v2?" + this.getThaaClientId("auth"),
      intakeToggle: this.getBaseUrl() + "gb/net/toggle/intake?" + this.getThaaClientId("auth"),
      zipcodeLookup:
        this.getBaseUrl() + "gb/net/claims-intake/zipcode-lookup?" + this.getThaaClientId("auth"),
      intakeContactState:
        this.getBaseUrl() + "gb/net/claims-intake/state/?" + this.getThaaClientId("auth"),
      intakeSTDEligibility:
        this.getBaseUrl() + "gb/net/employee/stdEligibility?" + this.getThaaClientId("auth"),
      intakeContactInfo:
        this.getBaseUrl() + "gb/net/claims-intake/contact-info?" + this.getThaaClientId("auth"),
      getStartLeaveReason:
        this.getBaseUrl() +
        "gb/net/claims-intake/covered-reason/v2?" +
        this.getThaaClientId("auth"),
      overpaymentEligibility:
        this.getBaseUrl() + "gb/net/claims/overpayment-eligible?" + this.getThaaClientId("auth"),
      overpayments: this.getBaseUrl() + "gb/net/claims/overpayment?" + this.getThaaClientId("auth"),
      fetchTempusIFrame:
        this.getBaseUrl() + "gb/net/claims/overpayment/payment?" + this.getThaaClientId("auth"),
      getNPIDetails: this.getBaseUrl() + "gb/net/provider/npi?" + this.getThaaClientId("auth"),
      paymentConfirmationSubmit:
        this.getBaseUrl() + "gb/net/claims/overpayment/submit?" + this.getThaaClientId("auth"),
      intakeHealthcareInsuranceCarrier:
        this.getBaseUrl() + "gb/net/provider/insurance-carrier?" + this.getThaaClientId("auth"),
      getRelationshipList:
        this.getBaseUrl() +
        "gb/net/claims-intake/primary-relationship?" +
        this.getThaaClientId("auth"),
      getSecondaryRelationshipList:
        this.getBaseUrl() +
        "gb/net/claims-intake/secondary-relationship?" +
        this.getThaaClientId("auth"),
      getCustomerSpecific:
        this.getBaseUrl() +
        "gb/net/claims-intake/customer-specific?" +
        this.getThaaClientId("auth"),
      getStatFlag:
        this.getBaseUrl() + "gb/net/employee/additional-employer?" + this.getThaaClientId("auth"),
      getFetchLookUp:
        this.getBaseUrl() + "gb/net/utility/fetch-lookup?" + this.getThaaClientId("auth"),
      getIcdDetails: this.getBaseUrl() + "gb/net/clinical/icd?" + this.getThaaClientId("auth"),
      getCptDetails: this.getBaseUrl() + "gb/net/clinical/cpt?" + this.getThaaClientId("auth"),
      getExpediteIndicator:
        this.getBaseUrl() + "gb/net/clinical/expedite-indicator?" + this.getThaaClientId("auth"),
      getReasonContinuity:
        this.getBaseUrl() +
        "gb/net/claims-intake/reason-continuity?" +
        this.getThaaClientId("auth"),
      getRelatedPregnancyClaim:
        this.getBaseUrl() + "gb/net/claims-intake/parental-bonding?" + this.getThaaClientId("auth"),
      lateReportingForIntermittent:
        this.getBaseUrl() +
        "gb/net/claims-intake/intermittent/late-reporting?" +
        this.getThaaClientId("auth"),
      fetchProviderInfo:
        this.getBaseUrl() + "gb/net/provider/fetchproviderinfo?" + this.getThaaClientId("auth"),
      specialtyWithKey:
        this.getBaseUrl() + "gb/net/provider/provider-specialty?" + this.getThaaClientId("auth"),
      getAdditionalEmployment:
        this.getBaseUrl() + "gb/net/employee/additional-employer?" + this.getThaaClientId("auth"),
      pflbenefits:
        this.getBaseUrl() + "gb/net/employee/pflbenefits?" + this.getThaaClientId("auth"),
      submitIntakeClaim:
        this.getBaseUrl() + "gb/net/claims-intake/v2?" + this.getThaaClientId("auth"),
      submitIntakeClaimByValue:
        this.getBaseUrl() + "gb/net/claims-intake/v3?" + this.getThaaClientId("auth"),
      companyParm:
        this.getBaseUrl() +
        "gb/net/utility/fetch-companyparmsvalue?" +
        this.getThaaClientId("auth"),
      isParmEnabled:
        this.getBaseUrl() + "gb/net/utility/fetch-isparmenabled?" + this.getThaaClientId("auth"),
      pdClassify:
        this.getBaseUrl() + "gb/net/physical-demand/classification?" + this.getThaaClientId("auth"),
      submitWorkSchedule:
        this.getBaseUrl() + "gb/net/employee/edit-work-schedule?" + this.getThaaClientId("auth"),
      addAbsenceExtension:
        this.getBaseUrl() + "gb/net/claims/absenceExtension?" + this.getThaaClientId("auth"),
      localRegulation:
        this.getBaseUrl() + "gb/net/employee/local-regulation?" + this.getThaaClientId("auth"),
      fmliStatus: this.getBaseUrl() + "gb/net/company/fmli_status?" + this.getThaaClientId("auth"),
      stateDisability:
        this.getBaseUrl() + "gb/net/employee/state-disability?" + this.getThaaClientId("auth"),
    };
  }
}
