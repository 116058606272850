import {AfterViewInit, Component, Input, OnDestroy, OnInit} from '@angular/core';
import {RequestExtensionService} from '../../shared/services/request-extension.service';
import {StorageManagementService} from '../../shared/services/storage-management.service';
import {Router} from '@angular/router';
import {LeaveDynatraceAction} from '../../shared/utils/leaveDynatraceAction';
import {RelatedClaims} from '../../shared/models/relatedClaims.model';
import {Activity, ClaimActivityHistoryResponse} from '../../shared/models/claimActivityHistory.model';
import {AetnaClaimActivityService} from '../../shared/services/aetna-claim-activity.service';
import {AAClaimActivityResponse, Timelines} from '../../shared/models/aetnaClaimActivity.model';
import {AppLabelConstants} from '../../shared/constants/appLabel.constants';
import {SessionConstants} from '../../shared/constants/session.constants';
import {AppConstants, AppLeaveConst} from '../../shared/constants/app.constants';
import {LeaveService} from '../../shared/services/leave-service.service';

@Component({
  selector: 'app-claim-activity-history',
  templateUrl: './claim-activity-history.component.html',
  styleUrls: ['./claim-activity-history.component.scss']
})
export class ClaimActivityHistoryComponent extends LeaveDynatraceAction implements OnInit, AfterViewInit, OnDestroy {
  @Input() headerLabel;
  claimNeeded: RelatedClaims;
  claimEventId: string;
  public activityHistory: Activity[] = [];
  public showLink = true;
  public hideHeader = false;
  public bgcolorClass;
  public getFooter;
  public claimActivities: AAClaimActivityResponse;
  public displayLTDDCSActivity = false;
  public appLabelConstant = AppLabelConstants;
  public AppConstants = AppConstants;
  public leaveData;

  constructor(private ClaimActivityService: RequestExtensionService,
              private aetnaClaimActivityService: AetnaClaimActivityService,
              private storageManagementService: StorageManagementService,
              private router: Router,
              private leaveService: LeaveService) {
    super(storageManagementService);
  }

//getclaimActivityDetailURL
  ngOnInit() {
    this.bgcolorClass = AppLabelConstants.BG_COLOR_CLASS;
    this.getFooter = AppLabelConstants.GET_FOOTER_1;
    this.claimNeeded = JSON.parse(this.storageManagementService.retrieveSessionObject(SessionConstants.CUR_CLAIM)).curClaim; // use to read claim eventid from session stprage;
    this.leaveData = this.storageManagementService.getSessionData(SessionConstants.CURR_LEAVE);
    this.claimEventId = (this.claimNeeded && this.claimNeeded.claimEventId) ? this.claimNeeded.claimEventId :  (this.leaveData && this.leaveData.leaveType.includes('ADA')) ? this.leaveData.leaveId : null ;
    this.getClaimActivityHistory();
  }

  public callService() {
    if (this.claimNeeded && this.claimNeeded.sourceSystemId === AppConstants.SOURCE_SYS_ID_DCS) {
      this.displayLTDDCSActivity = true;
      this.ClaimActivityService.getClaimActivity(this.claimEventId).subscribe((claimActivityHistoryServiceResponse: ClaimActivityHistoryResponse) => {
        if (claimActivityHistoryServiceResponse && claimActivityHistoryServiceResponse.claimActivity && claimActivityHistoryServiceResponse.claimActivity.op_ref_cusror && claimActivityHistoryServiceResponse.claimActivity.op_ref_cusror.length > 0) { //Null check
          this.activityHistory = claimActivityHistoryServiceResponse.claimActivity.op_ref_cusror;
          this.ClaimActivityService.setClaimActivityHistory(this.claimEventId, this.activityHistory);
        }
      });
    } else if (this.claimNeeded && this.claimNeeded.sourceSystemId === AppConstants.SOURCE_SYS_ID_AA) {
      this.displayLTDDCSActivity = false;
      const personId = (this.claimNeeded && this.claimNeeded.personId) ? this.claimNeeded.personId :  null;
      const claimEventId = (this.claimNeeded && this.claimNeeded.claimEventId) ? this.claimNeeded.claimEventId : null;
      this.aetnaClaimActivityService.getClaimActivities(personId, claimEventId).subscribe((val) => {
        this.claimActivities = val;
      this.claimActivitiesTimeline(this.claimActivities, claimEventId)
      });
    } else if (this.leaveData && this.leaveData.leaveType.includes(AppLeaveConst.ADA)) {
      this.displayLTDDCSActivity = false;
      const personId =  (this.leaveData && this.leaveData.personId) ? this.leaveData.personId : null;
      const claimEventId =  (this.leaveData && this.leaveData.leaveId) ? this.leaveData.leaveId : null;
      this.leaveService.getADAClaimActivities(personId, claimEventId).subscribe((val) => {
        this.claimActivities = val;
       this.claimActivitiesTimeline(this.claimActivities, claimEventId);
      });
    }
  }

  claimActivitiesTimeline(claimActivities, claimEventId) {
    if (claimActivities && claimActivities.timelines && claimActivities.timelines.length > 0) {
      claimActivities.timelines.forEach((timeline: Timelines) => {
        const activity: Activity = new Activity();

        activity.date = timeline.date;
        activity.activity = timeline.milestone;
        this.activityHistory.push(activity);
      });
      this.ClaimActivityService.setClaimActivityHistory(claimEventId, this.activityHistory);
    }
  }
  public getClaimActivityHistory() {
    const claimActivityHistory: Activity[] = this.ClaimActivityService.getClaimActivityHistory(this.claimEventId);
    if (claimActivityHistory && claimActivityHistory.length > 0) {
      this.activityHistory = claimActivityHistory;
    } else {
      this.callService();
    }
  }

  navigateToDetailsActivity() {
    this.showLink = false;
    this.router.navigate([AppLabelConstants.URL_CLAIM_ACTIVITY]);
    sessionStorage.setItem(SessionConstants.IDE_ELEMENT, AppLabelConstants.IDE_ELEMENT)
  }

  ngAfterViewInit() { // this method is used to detect weather user land on aclim activty page or not.
    this.headerLabel = this.ClaimActivityService.getOWCSHeader() ? this.ClaimActivityService.getOWCSHeader() : this.headerLabel;
    const ideElement = sessionStorage.getItem(SessionConstants.IDE_ELEMENT);
    if (ideElement) { // null check.
      this.showLink = false;
      this.hideHeader = true;
      this.getFooter = AppLabelConstants.GET_FOOTER;
    }
    if (this.claimNeeded && this.claimNeeded.sourceSystemId === AppConstants.SOURCE_SYS_ID_DCS) {
      this.displayLTDDCSActivity = true;
    }
    super.ngAfterViewInit();
  }

  ngOnDestroy() {
    this.ClaimActivityService.setOWCSHeader(this.headerLabel);
  }
}
