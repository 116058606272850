import {Injectable} from '@angular/core';
import {StorageManagementService} from '.././storage-management.service';
import {SessionKey, SessionService} from './session.service';
import {SessionConstants} from '../../constants/session.constants';
import {AppLeaveConst} from '../../constants/app.constants';


@Injectable()
export class TealiumDataService {
  public userClaims: any = {};
  public claimRequired: any = [];
  public coveragePlansObj: any = {};
  public pageType: string = '';
  public requiredPlans: any = [];
  public sortedPlans: any = [];
  public claimHandlerName: string;
  public provisionsId: string = '';
  public provisionsDesc: string = '';
  public gb_confirmationYesNo;
  udo = {};
  // events stores custom tags, or specific scenarios that occur during interactions, cleared out after page load completes
  // or single interaction after page load occurs
  events = [];

  constructor(private _storageManagementService: StorageManagementService, private sessionService: SessionService) {
  }

  setDataLayer(config) {

    const sessionObj = this._storageManagementService.getAppData(SessionConstants.USER_DATA);
    const view_id = config.locationurl;
    let prevPageName = '';
    let pageName = '';

    if (view_id) {
      pageName = view_id.split('/').pop();
    }
    this.setClaimId(view_id);
    this.setPageName(view_id);
    this.coveragePlansObj = this._storageManagementService.getAppData(SessionConstants.COVERAGE_PLANS);
    if (this.coveragePlansObj && this.coveragePlansObj.coveragePlansList && this.coveragePlansObj.coveragePlansList.length > 0) {
      this.requiredPlans = this.coveragePlansObj.coveragePlansList.map((data) => data.policyNumber);
      this.sortedPlans = Array.from(new Set(this.requiredPlans));
    }

    if (this.sessionService.getSession(SessionKey.CURRENT_URL)) {
      this.sessionService.setSession(SessionKey.PREVIOUS_URL, this.sessionService.getSession(SessionKey.CURRENT_URL));
    }
    if (pageName !== '') {
      this.sessionService.setSession(SessionKey.CURRENT_URL, pageName);
    }
    this.getProvisions();
    // Obtain UDO fields described in Data Layer Guide via SessionStorage
    // Add SessionStorage value to variable if it exists; otherwise set to default value for that data type

    const siteName = 'CXR';
    const firstNameVal: string = (sessionObj && sessionObj.firstname) ? sessionObj.firstname : '';
    const lastNameVal: string = (sessionObj && sessionObj.lastName) ? sessionObj.lastName : '';
    const email: string = (sessionObj && sessionObj.emailAddr) ? sessionObj.emailAddr : '';
    const gbcaseiedVal: string = (sessionObj && sessionObj.caseId) ? sessionObj.caseId : '';
    const previousUrl: string = this.sessionService.getSession(SessionKey.PREVIOUS_URL) ? this.sessionService.getSession(SessionKey.PREVIOUS_URL) : '';
    const thawuID: string = (sessionObj && sessionObj.partyRefId) ? sessionObj.partyRefId : '';
    const gbclaimevID: any[] = this.claimRequired ? this.claimRequired : '';
    const gbinsuredID: string = (sessionObj && sessionObj.insuredId) ? sessionObj.insuredId : '';
    const gbpolVal: any[] = this.sortedPlans ? this.sortedPlans : '';
    const pageType = this.pageType ? this.pageType : '';
    const claimHandler: string = ((this._storageManagementService.getSessionData(SessionConstants.CLAIM_REP_DETAILS) && this._storageManagementService.getSessionData(SessionConstants.CLAIM_REP_DETAILS).claimOwnerName) ? this._storageManagementService.getSessionData(SessionConstants.CLAIM_REP_DETAILS).claimOwnerName : '');
    let defaultCase = '';
    let claimStatus = '';
    let fundingMethodCodeVal = '';
    const provisionID = this.provisionsId;
    const provisionDescriptionVal = this.provisionsDesc;
    let estimatedSurgeryDateVal = '';
    let estimatedDeliveryDateVal = '';
    let confirmedDeliveryDateVal = '';
    let confirmedSurgeryDateVal = '';
    let disabilityDate = '';
    const personIDs: any[] = (sessionObj && sessionObj.personIds) ? sessionObj.personIds : '';
    const partyRefID: string = (sessionObj && sessionObj.partyRefId) ? sessionObj.partyRefId : '';
    const profileID: string = (sessionObj && sessionObj.profileId) ? sessionObj.profileId : '';
    let srcSystemCD = '';
    let extensionStatusVal = '';
    let psvInstanceID = '';

    if (view_id && view_id === '/home') {
       defaultCase = this._storageManagementService.getSessionData(SessionConstants.DEFAULT_CASE) ? this._storageManagementService.getSessionData(SessionConstants.DEFAULT_CASE) : '';
      } else if (view_id === '/claimDetails') {
       defaultCase = this._storageManagementService.getSessionData(SessionConstants.CUR_CLAIM).sourceSystemId ? this._storageManagementService.getSessionData(SessionConstants.CUR_CLAIM).sourceSystemId : '';
       claimStatus = this._storageManagementService.getSessionData(SessionConstants.CUR_CLAIM).claimStatus ? this._storageManagementService.getSessionData(SessionConstants.CUR_CLAIM).claimStatus : '';
      fundingMethodCodeVal = this._storageManagementService.getSessionData(SessionConstants.CUR_CLAIM).fundingMethod ? this._storageManagementService.getSessionData(SessionConstants.CUR_CLAIM).fundingMethod : '';
      estimatedSurgeryDateVal = this._storageManagementService.getSessionData(SessionConstants.CUR_CLAIM).estimatedSurgeryDate ? this._storageManagementService.getSessionData(SessionConstants.CUR_CLAIM).estimatedSurgeryDate : '';
      estimatedDeliveryDateVal = this._storageManagementService.getSessionData(SessionConstants.CUR_CLAIM).estimatedDeliveryDate ? this._storageManagementService.getSessionData(SessionConstants.CUR_CLAIM).claimStatus : '';
      confirmedDeliveryDateVal = this._storageManagementService.getSessionData(SessionConstants.CUR_CLAIM).confirmedDeliveryDate ? this._storageManagementService.getSessionData(SessionConstants.CUR_CLAIM).confirmedDeliveryDate : '';
      confirmedSurgeryDateVal = this._storageManagementService.getSessionData(SessionConstants.CUR_CLAIM).confirmedSurgeryDate ? this._storageManagementService.getSessionData(SessionConstants.CUR_CLAIM).confirmedSurgeryDate : '';
      disabilityDate = this._storageManagementService.getSessionData(SessionConstants.CUR_CLAIM).disabilityDate ? this._storageManagementService.getSessionData(SessionConstants.CUR_CLAIM).disabilityDate : '';
      srcSystemCD = this._storageManagementService.getSessionData(SessionConstants.SOURCE_SYSTEM_CD) ? this._storageManagementService.getSessionData(SessionConstants.SOURCE_SYSTEM_CD) : '';
      extensionStatusVal = this._storageManagementService.getSessionData(SessionConstants.CUR_CLAIM).extensionStatus ? this._storageManagementService.getSessionData(SessionConstants.CUR_CLAIM).extensionStatus : '';
    } else if (view_id === '/leave-details/leaveDetails') {
       defaultCase = AppLeaveConst.SOURCE_SYS_ID_AA;
    } else if (view_id && view_id.indexOf('/psv') !== -1) {
      defaultCase = this._storageManagementService.getSessionData(SessionConstants.DEFAULT_CASE) ? this._storageManagementService.getSessionData(SessionConstants.DEFAULT_CASE) : '';
      psvInstanceID = this._storageManagementService.getSessionData(SessionConstants.PSV_INSTANCE_ID) ? this._storageManagementService.getSessionData(SessionConstants.PSV_INSTANCE_ID) : '';
    }
    if (previousUrl) {
      prevPageName = previousUrl.split('/').pop();
    }

    // will become new udo object
    // Todo: Fill in missing and replace hard-coded values
    this.udo = {
      site_name: siteName,
      firstName: firstNameVal,
      lastName: lastNameVal,
      emailAddress: email,
      page_name: pageName,
      gbcaseied: gbcaseiedVal,
      previous_page_name: prevPageName,
      thawuid: thawuID,
      gbinsuredid: gbinsuredID,
      gbclaimevid: gbclaimevID,
      gbpol: gbpolVal,
      events: this.events,
      page_type: pageType,
      claim_handler_name: claimHandler,
      claimant_migration_type: defaultCase,
      claim_status: claimStatus,
      fundingMethodCode: fundingMethodCodeVal,
       provisionId: provisionID,
       provisionDescription: provisionDescriptionVal,
       estimatedSurgeryDate: estimatedSurgeryDateVal,
       estimatedDeliveryDate: estimatedDeliveryDateVal,
       confirmedDeliveryDate: confirmedDeliveryDateVal,
       confirmedSurgeryDate: confirmedSurgeryDateVal,
       confirmedDisabilityDate: disabilityDate,
       personIds: personIDs,
       partyRefId: partyRefID,
       srcSystemCd: srcSystemCD,
       extensionStatus: extensionStatusVal,
       claimant_flow_type: this.gb_confirmationYesNo,
       profileId: profileID,
       gb_video_id: psvInstanceID
    };
    this.events = [];
    return this.udo;

  }

  // for custom tags that occur during page loads
  addPageLevelEvent(event) {
    this.events.push(event);
  }

  setClaimId(view_id) {
    if (view_id === '/schedule-appointment/scheduleCall') {
      this.claimRequired = [];
      this._storageManagementService.getSessionData('claimId') ? this.claimRequired.push(this._storageManagementService.getSessionData('claimId')) : this.claimRequired.push('');
    } else if (view_id === '/leave-details/leaveDetails') {
      this.claimRequired = [];
      const curLeave = this._storageManagementService.getSessionData('currLeave');
      curLeave && curLeave.leaveId ? this.claimRequired.push(curLeave.leaveId) : this.claimRequired.push('');
    } else {
      if (this._storageManagementService.retrieveSessionObject('appData')) {
        this.userClaims = JSON.parse(this._storageManagementService.retrieveSessionObject('appData')).appData.userClaims;
        if (this.userClaims && this.userClaims.claimOverview) {
          this.claimRequired = [];
          const claims = [...this.userClaims.claimOverview.nonRelatedClaims, ...this.userClaims.claimOverview.relatedClaims, ...this.userClaims.claimOverview.leaveClaims ];
          for (const userClaim of  claims) {
            userClaim.claimEventId ? this.claimRequired.push(userClaim.claimEventId) : this.claimRequired.push(userClaim.leaveId);
          }
        }
      }
    }
  }

  getProvisions() {
    const provisionsObj = this._storageManagementService.getAppData(SessionConstants.PROVISIONS);
    if (provisionsObj && provisionsObj.provisionResponseList &&  provisionsObj.provisionResponseList.length > 0) {
        this.provisionsId = provisionsObj.provisionResponseList[0].provisionId;
        this.provisionsDesc = provisionsObj.provisionResponseList[0].provisionDescription;
    }
  }
  setPageName(view_id) {
    const taggingURL = [ '/claimDocuments', '/requestExtension', '/claimDetails', '/leave-details/leaveDetails'];
    const taggingConfirmURL = ['/surgery/confirmSurgery', '/surgery/newSurgeryDate', '/surgery/surgeryLastDayWork', '/delivery/confirmDelivery', '/delivery/deliveryCongrats', '/delivery/last-day-worked', '/delivery/thankyou', '/disability/confirmDisabilityDetail', '/disability/newDisabilityDate', '/disability/disabilityThankYou'];
    const currentPage = this._storageManagementService.getSessionData(SessionConstants.CURRENT_PAGE);

    if (taggingURL.indexOf(view_id) > -1 && currentPage === '/leave-details/leaveDetails') {
      const curLeave = this._storageManagementService.getSessionData(SessionConstants.CURR_LEAVE);
      this.pageType = curLeave && curLeave.leaveType ? curLeave.leaveType : '';
    } else if (taggingURL.indexOf(view_id) > -1 && (currentPage === '/claimDetails')) {
      const curClaim = this._storageManagementService.getSessionData(SessionConstants.CUR_CLAIM);
      this.pageType = curClaim && curClaim.coverageCategory ? curClaim.coverageCategory : '';
    } else if (view_id === '/phadetails') {
      const phaClaim = JSON.parse(this._storageManagementService.retrieveSessionObject(SessionConstants.PHA_OVERVIEW_DATA)).phaOverViewData;
      this.pageType = phaClaim && phaClaim.coverageCategoryCd ? phaClaim.coverageCategoryCd : '';
    } else if (taggingConfirmURL.indexOf(view_id) > -1) {
      this.pageType = this._storageManagementService.getSessionData(SessionConstants.COV_CATEGORY);
    } else {
      this.pageType = '';
    }
  }

  // for custom tags that occur after page load
  // add custom tag to event, call _appTrack and clear events array
  addEvent(event) {
    if (typeof (<any>window)._trackAnalytics === 'function' && this.udo['events']) {
      this.udo['events'].push(event);
      const elementvars = {
        da_track: event.da_track
      };
      const elementAttrs = JSON.parse(JSON.stringify(elementvars));
      this._appTrack(this.udo, null, elementAttrs);
      this.udo['events'] = [];
    } else {
      this.eventLag(event);
    }
  }
  eventLag(event) {
    setTimeout(() => {
      this.addEvent(event);
    }, 200);
  }

  // Calls Tealium extension with data from interaction
  // element includes DOM element data if interaction from event tracking
  _appTrack(data, element, elementAttrs) {
    try {
      //Handle automatic events without data-dl
      if (elementAttrs === null) {
        if (typeof (<any>window)._trackEvents === 'function') {
          (<any>window)._trackEvents(data, element);
        }
      } else {
        if (typeof (<any>window)._trackAnalytics === 'function') {
          (<any>window)._trackAnalytics(data, element, elementAttrs);
        }
      }
    } catch (e) {
      //console.log('Tealium error : ', e.message);
    }
    this.gb_confirmationYesNo = data.event_value;
  }
}
