import {Component, HostListener, OnInit, Input} from '@angular/core';
import {AppLeaveIntakeConstants} from '@app/cxr/leave-claim-intake-flow/leave-intake.constants';
import {AppLabelConstants} from '@shared/constants/appLabel.constants';

@Component({
  selector: 'description-fade',
  templateUrl: './description-fade.component.html',
  styleUrls: ['./description-fade.component.scss']
})
export class DescriptionFadeComponent implements OnInit {
  @Input() description: string = '';
  @Input() header: string = '';
  @Input() formLinkText: string = '';
  fadeDesc: boolean = false;
  fullDesc: boolean = false;
  showLink: boolean = false;
  showFullDescLink: boolean = false;
  hideDesc: boolean = true;
  constants = {...AppLeaveIntakeConstants, ...AppLabelConstants};
  uniqueHeaderIndex: string = '';

  constructor() { }

  ngOnInit() {
    const unquiHeader = this.header ? this.header + Math.random() : this.constants.TEST_HEADER + Math.random();
    this.uniqueHeaderIndex = unquiHeader.replace('.', '').split(' ').join('');
    this.fadeWord(event);
  }

  @HostListener('window:resize', ['$event'])
  fadeWord(event?) {
    this.hideDesc = true;
    setTimeout(() => {
      const DescElement = `.${this.uniqueHeaderIndex}`;
      if (DescElement) {
        const decriptionValue = (document.querySelector(DescElement)) as HTMLElement ;
        if (decriptionValue) {
          const heightOfDesc = decriptionValue.getBoundingClientRect();
          if (heightOfDesc && Math.floor(heightOfDesc.height / 20) > 3) {
            if (!this.showLink) {
              this.displayFadeOrFullDesc(false);
              this.showFullDescLink = false;
            }
          } else {
            this.showLink = false;
            this.showFullDescLink = true;
            this.displayFadeOrFullDesc(true);
          }
          this.hideDesc = false;
        }
      }
    }, 0);
  };

  changeLinkState() {
    this.showLink = !this.showLink;
    if (this.showLink) {
      this.displayFadeOrFullDesc(true);
    } else {
      this.displayFadeOrFullDesc(false);
    }
  }

  displayFadeOrFullDesc(key) {
    this.fullDesc = key;
    this.fadeDesc = !key;
  }

}
