export const recentlyResolvedAetna: any = {

  MissingInfoAlertInfo: [{
    recentlyResolvedItemsCount: '1',
    missingInfoList: [{
      mInfoCode: '10023364',
      mInfoDesc: 'Employer',
      mInfoReqInfo: [{
        alertStatus: 'Open',
        claimId: '1686545',
        clickToChat: '1002',
        completionDate: '8/19/2020',
        deliveryMethod: null,
        displayValue: 'GAP Coverage',
        downloadIndicator: 'YES',
        dueDate: '8/12/2020',
        informationNeededFrom: '',
        throughDate: '03/31/2021',
        fromDate: '01/01/2021',
        esignURL: null,
        formURL: null,
        mInfoReqInfoCd: '709080',
        missingInfoRequInfoDesc: ' Information about how you were covered if there was some time between your last day of work and the start of your disability. The timeframe needed is from 02/06/2020-02/13/2020',
        recipient: [],
        requestDate: '8/19/2020',
        upload: '1001',
        requestedByDate: '03/05/2021',
        requestedDate: '03/05/2021',
        followups: [
          {followupDate: '03/01/2020'},
          {followupDate: '03/05/2020'},
          {followupDate: '03/10/2020'}
        ],
        finalFollowUpDate: '03/25/2020'
      },
        {
          alertStatus: 'Open',
          claimId: '1686545',
          clickToChat: '1002',
          completionDate: '8/19/2020',
          deliveryMethod: null,
          displayValue: 'GAP Coverage',
          downloadIndicator: 'YES',
          dueDate: '8/12/2020',
          esignURL: null,
          formURL: null,
          informationNeededFrom: '',
          throughDate: '',
          fromDate: '1/1/2021',
          mInfoReqInfoCd: '709080',
          missingInfoRequInfoDesc: ' Information about how you were covered if there was some time between your last day of work and the start of your disability. The timeframe needed is from 02/06/2020-02/13/2020',
          recipient: [
            {
              name: 'Your Employer',
              emailAddress: 'ee@ee.com',
              mailingAddress: null,
              faxNumber: null,
              faxedTo: null
            }
          ],
          requestDate: '8/19/2020',
          upload: '1001',
          requestedByDate: '03/05/2021',
          requestedDate: '03/05/2021',
          followups: [
            {followupDate: '03/01/2020'},
            {followupDate: '03/05/2020'},
            {followupDate: '03/10/2020'}
          ],
          finalFollowUpDate: '03/25/2020'
        },
        {
          alertStatus: 'Open',
          claimId: '1686545',
          clickToChat: '1002',
          completionDate: '8/19/2020',
          deliveryMethod: null,
          displayValue: 'Eligibility Confirmation',
          downloadIndicator: 'YES',
          dueDate: '',
          esignURL: null,
          formURL: null,
          informationNeededFrom: '',
          throughDate: '',
          fromDate: '',
          mInfoReqInfoCd: '709081',
          missingInfoRequInfoDesc: 'Test Alert',
          recipient: [    {
            name: 'Your Employer',
            emailAddress: 'ee@ee.com',
            mailingAddress: null,
            faxNumber: null,
            faxedTo: null
          }],
          requestDate: null,
          upload: '1002',
          requestedByDate: '03/05/2021',
          requestedDate: '03/05/2021',
          followups: [
            {followupDate: '03/01/2020'},
            {followupDate: '03/05/2020'},
            {followupDate: '03/10/2020'}
          ],
          finalFollowUpDate: '03/25/2020'
        }
      ]
    },
      {
        mInfoCode: '10023362',
        mInfoDesc: 'Doctor',
        mInfoReqInfo: [
          {
            alertStatus: 'Open',
            claimId: '1686545',
            clickToChat: '1002',
            completionDate: '8/19/2014',
            deliveryMethod: null,
            displayValue: 'Doctor\'s Note',
            downloadIndicator: 'NO',
            dueDate: '8/7/2020',
            esignURL: null,
            formURL: null,
            informationNeededFrom: 'Hello',
            throughDate: '03/31/2021',
            fromDate: '01/01/2021',
            mInfoReqInfoCd: '709079',
            missingInfoRequInfoDesc: ' Information about how you were covered if there was some time between your last day of work and the start of your disability. The timeframe needed is from 02/06/2020-02/13/2020',
            recipient: [],
            requestDate: '8/19/2020',
            upload: '1001',
            requestedByDate: '03/05/2021',
            requestedDate: '03/05/2021',
            followups: [
              {followupDate: '03/01/2020'},
              {followupDate: '03/05/2020'},
              {followupDate: '03/10/2020'}
            ],
            finalFollowUpDate: '03/25/2020'
          },
          {
            alertStatus: 'Open',
            claimId: '1686545',
            clickToChat: '1002',
            completionDate: '8/19/2014',
            deliveryMethod: null,
            displayValue: 'Doctor\'s Note',
            downloadIndicator: 'NO',
            informationNeededFrom: '',
            throughDate: '02/02/2021',
            fromDate: '01/01/2021',
            dueDate: '8/7/2020',
            esignURL: null,
            formURL: null,
            mInfoReqInfoCd: '709079',
            missingInfoRequInfoDesc: ' Information about how you were covered if there was some time between your last day of work and the start of your disability. The timeframe needed is from 02/06/2020-02/13/2020',
            recipient: [
              // {
              //   'name': 'Jim V',
              //   'emailAddress': 'JamesV@hig.com',
              //   'mailingAddress': null,
              //   'faxNumber': null,
              //   'faxedTo': null
              // }
            ],
            requestDate: '8/19/2020',
            upload: '1001',
            requestedByDate: '03/05/2021',
            requestedDate: '03/05/2021',
            followups: [
              {followupDate: '03/01/2020'},
              {followupDate: '03/05/2020'},
              {followupDate: '03/10/2020'}
            ],
            finalFollowUpDate: '03/25/2020'
          },
          {
            alertStatus: 'Open',
            claimId: '1686545',
            clickToChat: '1002',
            completionDate: '8/19/2014',
            deliveryMethod: null,
            displayValue: 'Doctor\'s Note',
            downloadIndicator: 'NO',
            dueDate: null,
            esignURL: null,
            throughDate: '02/02/2021',
            fromDate: '01/01/2021',
            formURL: null,
            mInfoReqInfoCd: '709079',
            missingInfoRequInfoDesc: ' Information about how you were covered if there was some time between your last day of work and the start of your disability. The timeframe needed is from 02/06/2020-02/13/2020',
            recipient: [
              {
                name: 'Jim V',
                emailAddress: null,
                mailingAddress: null,
                faxNumber: null,
                faxedTo: null
              }
            ],
            requestDate: null,
            upload: '1001',
            requestedByDate: '03/05/2021',
            requestedDate: '03/05/2021',
            followups: [
              {followupDate: '03/01/2020'},
              {followupDate: '03/05/2020'},
              {followupDate: '03/10/2020'}
            ],
            finalFollowUpDate: '03/25/2020'
          },
          {
            alertStatus: 'Open',
            claimId: '1686545',
            clickToChat: '1002',
            completionDate: '8/19/2020',
            deliveryMethod: null,
            displayValue: 'Attending Physician\'s Statement',
            downloadIndicator: 'YES',
            dueDate: null,
            esignURL: null,
            formURL: null,
            mInfoReqInfoCd: '709081',
            missingInfoRequInfoDesc: 'Test Alert',
            recipient: [
              {
                name: 'Amit Y',
                emailAddress: null,
                mailingAddress: '123 Deer Lane Windsor Locks, CT',
                faxNumber: null,
                faxedTo: null
              }
            ],
            requestDate: '8/19/2020',
            upload: '1001',
            requestedByDate: '03/05/2021',
            requestedDate: '03/05/2021',
            followups: [
              {followupDate: '03/01/2020'},
              {followupDate: '03/05/2020'},
              {followupDate: '03/10/2020'}
            ],
            finalFollowUpDate: '03/25/2020'
          },
          {
            alertStatus: 'Open',
            claimId: '1686545',
            clickToChat: '1002',
            completionDate: '8/19/2020',
            deliveryMethod: null,
            displayValue: 'Gap Coverage',
            downloadIndicator: 'YES',
            dueDate: null,
            esignURL: null,
            formURL: null,
            mInfoReqInfoCd: '709081',
            missingInfoRequInfoDesc: 'Test Alert',
            recipient: [
              {
                name: null,
                emailAddress: null,
                mailingAddress: null,
                faxNumber: null,
                faxedTo: null
              }
            ],
            requestDate: '8/19/2020',
            upload: '1001',
            requestedByDate: '03/05/2021',
            requestedDate: '03/05/2021',
            followups: [
              {followupDate: '03/01/2020'},
              {followupDate: '03/05/2020'},
              {followupDate: '03/10/2020'}
            ],
            finalFollowUpDate: '03/25/2020'
          },
          {
            alertStatus: 'Open',
            claimId: '1686545',
            clickToChat: '1002',
            completionDate: '8/19/2020',
            deliveryMethod: null,
            displayValue: 'APS',
            downloadIndicator: 'YES',
            dueDate: '8/30/2020',
            esignURL: null,
            formURL: null,
            mInfoReqInfoCd: '709081',
            missingInfoRequInfoDesc: 'Test Alert',
            recipient: [
              {
                name: null,
                emailAddress: null,
                mailingAddress: null,
                faxNumber: null,
                faxedTo: null
              }
            ],
            requestDate: '8/19/2020',
            upload: '1001',
            requestedByDate: '03/05/2021',
            requestedDate: '03/05/2021',
            followups: [
              {followupDate: '03/01/2020'},
              {followupDate: '03/05/2020'},
              {followupDate: '03/10/2020'}
            ],
            finalFollowUpDate: '03/25/2020'
          }]
      },
      {
        mInfoCode: '10023366',
        mInfoDesc: 'Employee',
        mInfoReqInfo: [{
          alertStatus: 'Open',
          claimId: '1686545',
          clickToChat: '1002',
          completionDate: '8/19/2020',
          deliveryMethod: null,
          displayValue: 'Medical History Form',
          downloadIndicator: 'YES',
          dueDate: null,
          esignURL: null,
          formURL: null,
          throughDate: '01/20/2021',
          fromDate: '01/01/2021',
          mInfoReqInfoCd: '709079',
          missingInfoRequInfoDesc: ' Information about how you were covered if there was some time between your last day of work and the start of your disability. The timeframe needed is from 02/06/2020-02/13/2020',
          recipient: [],
          requestDate: null,
          upload: '1001',
          requestedByDate: '3/8/21',
          requestedDate: '3/8/21',
          followups: [
            {followupDate: '3/1/2020'},
            {followupDate: '03/05/2020'},
            {followupDate: '03/10/2020'}
          ],
          finalFollowUpDate: '3/25/2020'
        },  {
          alertStatus: 'Open',
          claimId: '1686545',
          clickToChat: '1002',
          completionDate: '8/19/2020',
          deliveryMethod: null,
          displayValue: 'Workers Compensation Denial Letter',
          downloadIndicator: 'NO',
          dueDate: '9/19/2020',
          esignURL: null,
          formURL: null,
          informationNeededFrom: 'Amit',
          throughDate: '01/01/2021',
          fromDate: '',
          mInfoReqInfoCd: '709081',
          missingInfoRequInfoDesc: 'Test Alert',
          recipient: [],
          requestDate: '8/19/2020',
          upload: '1001',
          requestedByDate: '03/05/2021',
          requestedDate: '3/8/21',
          followups: [
            {followupDate: '03/01/2020'},
            {followupDate: '03/05/2020'},
            {followupDate: '03/10/2020'}
          ],
          finalFollowUpDate: '03/25/2020'
        },
          {
            alertStatus: 'Open',
            claimId: '1686545',
            clickToChat: '1001',
            completionDate: '8/19/2020',
            deliveryMethod: null,
            displayValue: 'Doctor\'s Contact Information',
            downloadIndicator: 'NO',
            dueDate: null,
            esignURL: null,
            formURL: null,
            mInfoReqInfoCd: '709082',
            missingInfoRequInfoDesc: 'Test Info Alert',
            recipient: [
              {
                name: 'Amit Y',
                emailAddress: null,
                mailingAddress: '133 Brook Lane, Windsor, CT',
                faxNumber: null,
                faxedTo: null
              }
            ],
            requestDate: '8/19/2020',
            upload: '1002',
            requestedByDate: '03/05/2021',
            requestedDate: '3/8/21',
            followups: [
              {followupDate: '03/01/2020'},
              {followupDate: '03/05/2020'},
              {followupDate: '03/10/2020'}
            ],
            finalFollowUpDate: '03/25/2020'
          },
          {
            alertStatus: 'Open',
            claimId: '1686545',
            clickToChat: '1002',
            completionDate: '8/19/2020',
            deliveryMethod: null,
            displayValue: 'Esign Form',
            downloadIndicator: 'YES',
            dueDate: '8/20/2020',
            EsignURL: 'www.hig.com',
            formURL: null,
            mInfoReqInfoCd: '709082',
            missingInfoRequInfoDesc: 'Test Info Alert',
            recipient: [
              {
                name: null,
                emailAddress: null,
                mailingAddress: null,
                faxNumber: null,
                faxedTo: null
              }
            ],
            requestDate: '8/19/2020',
            upload: '1002',
            requestedByDate: '03/05/2021',
            requestedDate: '3/8/21',
            followups: [
              {followupDate: '03/01/2020'},
              {followupDate: '03/05/2020'},
              {followupDate: '03/10/2020'}
            ],
            finalFollowUpDate: '03/25/2020'
          },
          {
            alertStatus: 'Open',
            claimId: '1686545',
            clickToChat: '1002',
            completionDate: '8/19/2020',
            deliveryMethod: null,
            displayValue: 'Worker\'s Comp Form',
            downloadIndicator: 'YES',
            dueDate: '8/20/2020',
            EsignURL: 'www.hig.com',
            formURL: null,
            mInfoReqInfoCd: '709082',
            missingInfoRequInfoDesc: 'Test Info Alert',
            recipient: [
              {
                name: null,
                emailAddress: null,
                mailingAddress: null,
                faxNumber: null,
                faxedTo: null
              }
            ],
            requestDate: null,
            upload: '1001',
            requestedByDate: '03/05/2021',
            requestedDate: '3/8/21',
            followups: [
              {followupDate: '03/01/2020'},
              {followupDate: '03/05/2020'},
              {followupDate: '03/10/2020'}
            ],
            finalFollowUpDate: '03/25/2020'
          }
        ]
      }
    ]
  }],
  responseCode: '001',
  responseReason: 'success'
};
/*  {
    "MissingInfoAlertInfo": {
      "missingInfoList": [{
        "mInfoCode": "10023364",
        "mInfoDesc": "Welcome",
        "mInfoReqInfo": [{
          "DownloadIndicator": "YES",
          "EsignURL": null,
          "FormURL": null,
          "Upload": "1001",
          "alertStatus": "Open",
          "claimId": "1686545",
          "clickToChat": "1002",
          "completionDate": "8/19/2020",
          "deliveryMethod": null,
          "displayValue": "GAP Coverage",
          "dueDate": null,
          "mInfoReqInfoCd": "709080",
          "recipient": null,
          "requestDate": "8/19/2020"
        },
          {
            "DownloadIndicator": "YES",
            "EsignURL": null,
            "FormURL": null,
            "Upload": "1002",
            "alertStatus": "Open",
            "claimId": "1686545",
            "clickToChat": "1002",
            "completionDate": "8/19/2020",
            "deliveryMethod": null,
            "displayValue": "Eligibility Confirmation",
            "dueDate": null,
            "mInfoReqInfoCd": "709081",
            "recipient": null,
            "requestDate": "8/19/2020"
          },
          {
            "DownloadIndicator": "YES",
            "EsignURL": null,
            "FormURL": null,
            "Upload": "1002",
            "alertStatus": "Open",
            "claimId": "1686545",
            "clickToChat": "1002",
            "completionDate": "8/19/2020",
            "deliveryMethod": null,
            "displayValue": "Job Description",
            "dueDate": null,
            "mInfoReqInfoCd": "709082",
            "recipient": null,
            "requestDate": "8/19/2020"
          }
        ]
      },
        {
          "mInfoCode": "10023362",
          "mInfoDesc": "Please provide a note or copies of treatment records that include the ICD code related to the primary disabling condition.",
          "mInfoReqInfo": [{
            "DownloadIndicator": "YES",
            "EsignURL": null,
            "FormURL": null,
            "Upload": "1001",
            "alertStatus": "Open",
            "claimId": "1686545",
            "clickToChat": "1002",
            "completionDate": "8/19/2020",
            "deliveryMethod": null,
            "displayValue": "Cause of Disabling Condition",
            "dueDate": null,
            "mInfoReqInfoCd": "709079",
            "recipient": null,
            "requestDate": "8/19/2020"
          }]
        },
        {
          "mInfoCode": "10023366",
          "mInfoDesc": "Welcome",
          "mInfoReqInfo": [{
            "DownloadIndicator": "YES",
            "EsignURL": null,
            "FormURL": null,
            "Upload": "1001",
            "alertStatus": "Open",
            "claimId": "1686545",
            "clickToChat": "1002",
            "completionDate": "8/19/2020",
            "deliveryMethod": null,
            "displayValue": "GAP Coverage",
            "dueDate": null,
            "mInfoReqInfoCd": "709080",
            "recipient": null,
            "requestDate": "8/19/2020"
          },
            {
              "DownloadIndicator": "YES",
              "EsignURL": null,
              "FormURL": null,
              "Upload": "1002",
              "alertStatus": "Open",
              "claimId": "1686545",
              "clickToChat": "1002",
              "completionDate": "8/19/2020",
              "deliveryMethod": null,
              "displayValue": "Eligibility Confirmation",
              "dueDate": null,
              "mInfoReqInfoCd": "709081",
              "recipient": null,
              "requestDate": "8/19/2020"
            },
            {
              "DownloadIndicator": "YES",
              "EsignURL": null,
              "FormURL": null,
              "Upload": "1002",
              "alertStatus": "Open",
              "claimId": "1686545",
              "clickToChat": "1002",
              "completionDate": "8/19/2020",
              "deliveryMethod": null,
              "displayValue": "Wage Details",
              "dueDate": null,
              "mInfoReqInfoCd": "709082",
              "recipient": null,
              "requestDate": "8/19/2020"
            }
          ]
        }
      ]
    },
    "responseCode": "001",
    "responseReason": "success"
  };*/
