import {Component, Input, OnInit} from '@angular/core';
import {DomSanitizer, SafeHtml, SafeResourceUrl} from '@angular/platform-browser';

@Component({
  selector: 'custom-page-label',
  templateUrl: 'custom-page-label.component.html',
  styleUrls: ['custom-page-label.component.scss']
})
export class CustomPageLabelComponent implements OnInit {

  @Input() higLabel: string | SafeResourceUrl;
  @Input() faIcon: string;
  @Input() myCondition = false;
  @Input() labelSize = 'regular';
  @Input() labelWeight = 'book';
  @Input() labelColor = 'regular';
  @Input() optional: boolean = false;

  constructor(public sanitizer: DomSanitizer) {

  }

  ngOnInit() {
    if (this.higLabel && this.sanitizer) {
      this.higLabel = this.sanitizer.bypassSecurityTrustHtml(this.higLabel + (this.optional ? ' (Optional)' : ''));
    }
  }

}
