import { DatePipe } from "@angular/common";
import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
} from "@angular/core";
import { Router } from "@angular/router";
import { INTAKE_NON_WORKDAY_MODAL } from "@app/cxr/modules/intake/components/bonding/shared/owcs-content-ids";
import { INTAKE_OWCS_IDS } from "@app/cxr/modules/intake/shared/JSON/intake-owcs.constants";
import { INTAKE_ROUTES } from "@app/cxr/modules/intake/shared/constants/Intake-routing.constants";
import { IntakeLabelConstants } from "@app/cxr/modules/intake/shared/constants/intake-label.constants";
import {
  BondingIntakeSessionConstants,
  IntakeSessionConstants,
} from "@app/cxr/modules/intake/shared/constants/intakeSession.constants";
import { AppLabelConstants } from "@shared/constants/appLabel.constants";
import { OwcsUtilityService } from "@shared/services/owcs-utility.service";
import { StorageManagementService } from "@shared/services/storage-management.service";

@Component({
  selector: "intake-non-workday-modal",
  templateUrl: "./intake-non-workday-modal.component.html",
  styleUrls: [
    "./intake-non-workday-modal.component.scss",
    "../../../modules/intake/shared/intake-styles/intake-common-style.scss",
  ],
})
export class IntakeNonWorkdayModalComponent implements OnInit, OnDestroy {
  @Input() set displayMandatedOTModal(displayOTModal: boolean) {
    if (displayOTModal) {
      this.openMandatedOTModal();
    }
  }
  @Input() editingSchedule: boolean;
  @Output() onContinueEmit: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() onModalCloseEmit: EventEmitter<boolean> = new EventEmitter<boolean>();
  @ViewChild("overTimeModal") overTimeModal: ElementRef;
  @Output() resetMandateOT: EventEmitter<boolean> = new EventEmitter<boolean>();
  owcsContentMap: Map<string, string> = new Map();
  intakeOWCSIds = INTAKE_OWCS_IDS;
  appLabelConstant = AppLabelConstants;
  bondingSessionConst = BondingIntakeSessionConstants;
  intakeSessionConst = IntakeSessionConstants;
  continueBtnDisabled: boolean = true;
  checkChangeFirstDayAbsent: boolean = false;
  checkChangeWorkSchedule: boolean = false;
  reportMandatedOvertime: boolean = false;
  mandateOTModalContent: string = "";
  isLOAEnabled: boolean = false;
  mandatedOTNonWorkdayResponse: string;
  intakeType: string;
  mandateOTModalContentSecondPara: string = "";
  modalDismissed: boolean = false;

  constructor(
    private router: Router,
    private owcsUtilityService: OwcsUtilityService,
    private storageManagementService: StorageManagementService,
    private datePipe: DatePipe,
  ) {}

  ngOnInit(): void {
    const owcsContent = this.storageManagementService.getSessionData(
      this.intakeSessionConst.NI_OWCS_CONTENT,
    );
    if (owcsContent) {
      this.owcsContentMap = this.owcsUtilityService.createOWCSMap(
        owcsContent,
        INTAKE_NON_WORKDAY_MODAL,
      );
    }
    this.isLOAEnabled = this.storageManagementService.getIntakeSessionData(
      IntakeSessionConstants.LOA_INDICATOR,
    );
    if (this.isLOAEnabled) {
      this.mandatedOTNonWorkdayResponse =
        IntakeSessionConstants.DEFAULT_MANDATED_OT_NONWORKDAY_RESPONSE_FROM_MODAL;
      this.intakeType = IntakeSessionConstants.INTAKE_TYPE_MATERNITY;
    } else {
      this.mandatedOTNonWorkdayResponse =
        this.bondingSessionConst.DEFAULT_MANDATED_OT_NONWORKDAY_RESPONSE_FROM_MODAL;
      this.intakeType = this.bondingSessionConst.INTAKE_TYPE_BONDING;
    }
  }

  // opens OverTime Modal
  openMandatedOTModal(): void {
    this.mandateOTModalContent = this.owcsContentMap.get(
      this.editingSchedule ? INTAKE_OWCS_IDS.NI_952A_Body : INTAKE_OWCS_IDS.NI_952AA_Body,
    );

    const fdaDate = this.storageManagementService.getIntakeSessionData(
      this.intakeSessionConst.FIRST_DAY_ABSENT,
    );
    if (fdaDate) {
      //returns Saturday, 01/13/24
      const formattedDate = this.datePipe.transform(fdaDate, "EEEE, MM/dd/yy");

      if (this.editingSchedule) {
        this.mandateOTModalContent = this.mandateOTModalContent
          .replace(IntakeLabelConstants.DYNAMIC_DAY_AND_DATE_LABEL_EDIT, formattedDate)
          .replace(IntakeLabelConstants.DYNAMIC_DAY_AND_DATE_LABEL_EDIT, formattedDate);
      } else {
        this.mandateOTModalContent = this.mandateOTModalContent.replace(
          IntakeLabelConstants.DYNAMIC_DAY_AND_DATE_LABEL_CONFIRM,
          formattedDate,
        );
        this.mandateOTModalContentSecondPara = this.owcsContentMap.get(
          INTAKE_OWCS_IDS.NI_952AB_Body,
        );
      }
    }

    this.continueBtnDisabled = true;
    this.checkChangeFirstDayAbsent = false;
    this.checkChangeWorkSchedule = false;
    this.reportMandatedOvertime = false;
    this.overTimeModal?.nativeElement?.present();
  }

  // when buttons in OverTime Modal are selected
  changeFdaOrWorkSchedule(event): void {
    if (event.target.hukid === this.appLabelConstant.CHANGE_FDA_BTN) {
      this.continueBtnDisabled = false;
      this.checkChangeFirstDayAbsent = true;
      this.checkChangeWorkSchedule = false;
      this.reportMandatedOvertime = false;
      this.resetOTFlagsInSession();
    } else if (event.target.hukid === this.appLabelConstant.CHANGE_WORK_SCHEDULE) {
      this.continueBtnDisabled = false;
      this.checkChangeFirstDayAbsent = false;
      this.checkChangeWorkSchedule = true;
      this.reportMandatedOvertime = false;
      this.resetOTFlagsInSession();
    } else if (event.target.hukid === this.appLabelConstant.REPORT_MANDATED_OT) {
      this.continueBtnDisabled = false;
      this.checkChangeFirstDayAbsent = false;
      this.checkChangeWorkSchedule = false;
      this.reportMandatedOvertime = true;
      this.storageManagementService.setIntakeSessionData(
        this.mandatedOTNonWorkdayResponse,
        true,
        this.intakeType,
      );
      this.storageManagementService.setIntakeSessionData(
        this.bondingSessionConst.DEFAULT_MANDATED_OT_NONWORKDAY_RESPONSE,
        true,
        this.bondingSessionConst.INTAKE_TYPE_BONDING,
      );
    } else if (event.target.hukid === this.appLabelConstant.CONTINUE_BTN) {
      if (this.checkChangeFirstDayAbsent) {
        this.storageManagementService.setIntakeSessionData(
          this.intakeSessionConst.CHANGE_FDA_WORKSCHEDULE,
          this.appLabelConstant.CHANGE_FDA_BTN,
        );
        this.onContinueEmit.emit();
      } else if (this.checkChangeWorkSchedule) {
        this.storageManagementService.setIntakeSessionData(
          this.intakeSessionConst.CHANGE_FDA_WORKSCHEDULE,
          this.appLabelConstant.CHANGE_WORK_SCHEDULE,
        );
        this.editWorkSchedule();
      } else if (this.reportMandatedOvertime) {
        this.storageManagementService.setIntakeSessionData(
          this.intakeSessionConst.CHANGE_FDA_WORKSCHEDULE,
          this.appLabelConstant.REPORT_MANDATED_OT,
        );
        this.onContinueEmit.emit();
      }
      this.modalDismissed = true;
      this.overTimeModal?.nativeElement?.dismiss();
    } else {
      if (this.isLOAEnabled && event.target.hukid === this.appLabelConstant.GO_BACK_BTN) {
        this.resetMandateOT.emit();
      } else if (event.target.hukid === this.appLabelConstant.GO_BACK_BTN) {
        this.resetMandateOT.emit();
      }
      this.resetOTFlagsInSession();
      this.modalDismissed = true;
      this.overTimeModal?.nativeElement?.dismiss();
    }
  }

  resetOTFlagsInSession(): void {
    this.storageManagementService.removeAttrFromIntakeSessionData(
      this.mandatedOTNonWorkdayResponse,
      this.intakeType,
    );
    this.storageManagementService.removeAttrFromIntakeSessionData(
      this.bondingSessionConst.DEFAULT_MANDATED_OT_NONWORKDAY_RESPONSE,
      this.intakeType,
    );
  }
  editWorkSchedule(): void {
    const bondingFlowApplicable = this.storageManagementService.getIntakeSessionData(
      this.bondingSessionConst.BONDING_FLOW,
      this.bondingSessionConst.INTAKE_TYPE_BONDING,
    );
    const transitionBonding = this.storageManagementService.getIntakeSessionData(
      this.bondingSessionConst.TRANSITION_TO_BONDING,
      this.bondingSessionConst.INTAKE_TYPE_BONDING,
    );
    const isLOAEnabled = this.storageManagementService.getIntakeSessionData(
      IntakeSessionConstants.LOA_INDICATOR,
    );
    if (bondingFlowApplicable) {
      this.storageManagementService.setIntakeSessionData(
        this.intakeSessionConst.IS_EDIT_WORK_SCHEDULE,
        true,
      );
      this.router.navigate([INTAKE_ROUTES.WORKSCHEDULE_EDIT]);
    }
    if (isLOAEnabled) {
      this.router.navigate([INTAKE_ROUTES.WORK_SCHEDULE_EDIT_LOA]);
    }
  }

  onMandatedOTModalClose(): void {
    this.onModalCloseEmit.emit(false);
  }

  ngOnDestroy() {
    if (!this.modalDismissed) {
      console.log("on destory");
      this.overTimeModal?.nativeElement?.dismiss();
    }
  }
}
