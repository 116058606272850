import {Directive} from '@angular/core';
import {AbstractControl, NG_VALIDATORS, Validator} from '@angular/forms';

@Directive({
  selector: '[validateSequentialDigits]',
  providers: [{provide: NG_VALIDATORS, useExisting: ValidateSequentionalDigitsPattern, multi: true}]
})
export class ValidateSequentionalDigitsPattern implements Validator {

  validate(control: AbstractControl) {
    if (control.dirty && control.value.length > 8 && control.value.includes('123456789')) {
            //console.log("Error in seq");
              return {validateSequentialDigits: true};
        }
      return null;
  }
}
