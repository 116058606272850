import {Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import {animate, AnimationBuilder, style} from '@angular/animations';
import {AppLabelConstants} from '../../../../shared/constants/appLabel.constants';

@Component({
  selector: 'app-card-carousel',
  templateUrl: './card-carousel.component.html',
  styleUrls: ['./card-carousel.component.scss']
})
export class CardCarouselComponent implements OnInit {
  @Input() cards;
  @Output() clickedCard: number;
  @Output() onBenefitSelected: EventEmitter<any> = new EventEmitter<any>();
  @ViewChild('carousel', {static: true}) private carousel: ElementRef;
  public itemWidth;
  public appLabelCostants = AppLabelConstants;
  public carouselWidth;
  public isPrevHidden = true;
  public isNextHidden = false;
  private animationDuration = AppLabelConstants.ANIMATION_DUR;
  private currentSlide = 0;

  constructor(private animationBuilder: AnimationBuilder) { }

  ngOnInit() {
    this.itemWidth = window.innerWidth - 30;
    this.carouselWidth = this.itemWidth * this.cards.length;
  }

  next() {
    if (this.currentSlide + 1 === this.cards.length) {
      return;
    }
    this.currentSlide++;
    this.setNavigationVisibility();
    this.changeCard();
  }

  prev() {
    if ( this.currentSlide === 0 ) {
      return;
    }
    this.currentSlide--;
    this.setNavigationVisibility();
    this.changeCard();
  }

  cardSelected() {
    this.clickedCard = this.currentSlide;
    this.onBenefitSelected.emit(this.cards[this.currentSlide]);
  }

  setNavigationVisibility() {
    this.isNextHidden = (this.currentSlide + 1 === this.cards.length);
    this.isPrevHidden = ( this.currentSlide === 0 );
  }

  changeCard() {
    const offset = this.currentSlide * this.itemWidth;
    const animation = this.changeCardAnimation(offset);
    if (animation) {
      const player = animation.create(this.carousel.nativeElement);
      player.play();
    }

  }

  changeCardAnimation( offset ) {
    return this.animationBuilder.build([
      animate(this.animationDuration, style({transform: `translateX(-${offset}px)`}))
    ]);
  }
}
