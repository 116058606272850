import {Component, OnInit} from '@angular/core';
import {StorageManagementService} from '../../shared/services/storage-management.service';
import {ContentService} from '../../shared/services/content.service';
import {Router} from '@angular/router';
import {SessionConstants} from '../../shared/constants/session.constants';
import {AppConstants} from '../../shared/constants/app.constants';

@Component({
  selector: 'app-co-branding',
  templateUrl: 'co-branding.component.html',
  styleUrls: ['co-branding.component.scss'],
  providers : []
})
export class CoBrandingComponent implements OnInit {

  public imgUrl: string;
  currentUser: any;
  private pageId: string;
  private owscContent;

  constructor(private storageManagementService: StorageManagementService,
              private contentService: ContentService,
              private router: Router) {
  }

  ngOnInit() {

    this.currentUser  =  this.storageManagementService.getAppData(SessionConstants.USER_DATA);
    this.pageId = this.contentService.getPageId(AppConstants.PAGE_NAME_CO_BRANDING);
    if (this.pageId && !this.storageManagementService.getSessionData(SessionConstants.COBRANDING_DATA)) {
      this.contentService.getOWCSResponse(this.pageId, AppConstants.PAGE_NAME_COBRANDING).subscribe(
        val => {
          if (val) {
            this.owscContent = val;
            if (this.currentUser && this.currentUser.caseId && this.owscContent && this.owscContent.data) {
              this.storageManagementService.setSessionData(SessionConstants.COBRANDING_DATA, val);
              this.fetchLogo();
            }
          }
        }, (error) => {
          console.error(error);
      });
    } else {
      this.owscContent = this.storageManagementService.getSessionData(SessionConstants.COBRANDING_DATA);
      this.fetchLogo();
    }
  }

  fetchLogo() {
    switch (this.currentUser.caseId) {
      case AppConstants.CASE_ID_164477 :
        this.imgUrl = this.owscContent.data.CB_IMG_CovantaEneregy_Logo ? this.owscContent.data.CB_IMG_CovantaEneregy_Logo.value : '';
        break;
      case AppConstants.CASE_ID_839295 :
        this.imgUrl = this.owscContent.data.CB_IMG_Trinity_Health_Logo ? this.owscContent.data.CB_IMG_Trinity_Health_Logo.value : '';
        break;
      case AppConstants.CASE_ID_400055 :
        this.imgUrl = this.owscContent.data.CB_IMG_Mathematica_Logo ? this.owscContent.data.CB_IMG_Mathematica_Logo.value : '';
        break;
      case AppConstants.CASE_ID_1030933 :
        this.imgUrl = this.owscContent.data.CB_IMG_Carlson_Logo ? this.owscContent.data.CB_IMG_Carlson_Logo.value : '';
        break;
      case AppConstants.CASE_ID_694205 :
        this.imgUrl = this.owscContent.data.CB_IMG_TCF_Financial_Logo ? this.owscContent.data.CB_IMG_TCF_Financial_Logo.value : '';
        break;
      case AppConstants.CASE_ID_516644 :
        this.imgUrl = this.owscContent.data.CB_IMG_Thrivent_Financial_Logo ? this.owscContent.data.CB_IMG_Thrivent_Financial_Logo.value : '';
        break;
      case AppConstants.CASE_ID_561724 :
        this.imgUrl = this.owscContent.data.CB_IMG_Andersen_Logo ? this.owscContent.data.CB_IMG_Andersen_Logo.value : '';
        break;
      case AppConstants.CASE_ID_481706 :
        this.imgUrl = this.owscContent.data.CB_IMG_PPG_Industries_Logo ? this.owscContent.data.CB_IMG_PPG_Industries_Logo.value : '';
        break;
      case AppConstants.CASE_ID_487970 :
        this.imgUrl = this.owscContent.data.CB_IMG_PPG_Industries_Logo ? this.owscContent.data.CB_IMG_PPG_Industries_Logo.value : '';
        break;
      case AppConstants.CASE_ID_33220 :
        this.imgUrl = this.owscContent.data.CB_IMG_YRC_Worldwide_Logo ? this.owscContent.data.CB_IMG_YRC_Worldwide_Logo.value : '';
        break;
      case AppConstants.CASE_ID_875199 :
        this.imgUrl = this.owscContent.data.CB_IMG_Schneider_Logo ? this.owscContent.data.CB_IMG_Schneider_Logo.value : '';
        break;
      case AppConstants.CASE_ID_1036267 :
        this.imgUrl = this.owscContent.data.CB_IMG_Level_3_Communications_Logo ? this.owscContent.data.CB_IMG_Level_3_Communications_Logo.value : '';
        break;
      case AppConstants.CASE_ID_821532 :
        this.imgUrl = this.owscContent.data.CB_IMG_Best_Buy_Logo ? this.owscContent.data.CB_IMG_Best_Buy_Logo.value : '';
        break;
      case AppConstants.CASE_ID_1169842 :
        this.imgUrl = this.owscContent.data.CB_IMG_Sysco_Logo ? this.owscContent.data.CB_IMG_Sysco_Logo.value : '';
        break;
      case AppConstants.CASE_ID_1225118 :
        this.imgUrl = this.owscContent.data.CB_IMG_Amazon_Logo ? this.owscContent.data.CB_IMG_Amazon_Logo.value : '';
        break;
      case AppConstants.CASE_ID_498852 :
        this.imgUrl = this.owscContent.data.CB_IMG_Davita_Logo ? this.owscContent.data.CB_IMG_Davita_Logo.value : '';
        break;
      default :
        this.imgUrl = '';
    }

  }
}
