import {Component, HostListener, Input, OnInit} from '@angular/core';

@Component({
  selector: 'tertiary-link',
  templateUrl: 'tertiary-link.component.html',
  styleUrls: ['tertiary-link.component.scss']
})
export class TertiaryLinkComponent implements OnInit {
  @Input() labelSize = 'regular';
  @Input() labelWeight = 'book';
  @Input() linkName: string;
  @Input() linkLocation: string;
  @Input() linkId: number;
  @Input() linkDisabled = false;
  @Input() linkColor: string;
  @Input() arialabel = '';
  @Input() bypassHref: string;
  @Input() linkImg: string;
  @Input() set ariaLabel(data: string) {
    if (data) {
      this._ariaLabel = data;
    }
  }
  @Input() removeNestedInteraction: boolean;
  _ariaLabel: string = '';

  constructor() {}

  @HostListener('keyup.enter', ['$event'])
  onKeyUpEnter(event) {
    event.preventDefault();
    event.target.click();
  }

  ngOnInit() {
  }

}
