import {Component, OnInit} from '@angular/core';
import {Router} from '@angular/router';
import {ModalService} from '../../shared/services/modal.service';
import {CustomEventService} from '../../shared/services/tealium/custom-event.service';
import {StorageManagementService} from '../../shared/services/storage-management.service';
import {ContentService} from '../../shared/services/content.service';
import {EventManagementServiceService} from '../../shared/services/event-management-service.service';
import {AppLabelConstants} from '../../shared/constants/appLabel.constants';


@Component({
  selector: 'app-thaa-redirect-save-modal',
  templateUrl: './thaa-redirect-save-modal.component.html',
  styleUrls: ['./thaa-redirect-save-modal.component.scss']
})
export class ThaaRedirectSaveModalComponent implements OnInit {

  public appLabelConstants = AppLabelConstants;

  constructor(private modalService: ModalService,
              private router: Router,
              private contentService: ContentService,
              private storageManagementService: StorageManagementService,
              private eventManagementServiceService: EventManagementServiceService,
              private customEventService: CustomEventService,
  ) { }

  ngOnInit() {
    this.redirectToThaa();

  }

  redirectToThaa() {
    setTimeout(() => {
      this.modalService.openModal(AppLabelConstants.THAA_MODAL);
    }, 200);
  }

  continueToTHAA() {
    window.open(AppLabelConstants.URL_AA_PORTAL, AppLabelConstants.SELF);
  }



}
