export class AetnaCompanySpecificContactResponse {
  loaSpecificCaseName: string;
  loaSpecificCasePhone: string;
  loaSpecificCaseFax: string;
  loaSpecificCaseWrkHrs: string;
  loaSpecificCasePhoneHeader: string;
  loaSpecificCaseFaxHeader: string;
  responseReason: string;
  responseCode: string;
  aetnaContactDetails: string;
  aetnaContactAltDisplayLines: string[];
}
