import { AfterContentInit, AfterViewInit, Component, EventEmitter, Input, OnChanges, OnInit, Output, ViewChild } from '@angular/core';
import { NavigationExtras, Router } from '@angular/router';
import { User } from '../../../../shared/models/user.model';
import { ContentService } from '../../../../shared/services/content.service';
import { DateLabelValueComponent } from '../datelabel-datevalue/date-label-value/date-label-value.component';
import { StorageManagementService } from '../../../../shared/services/storage-management.service';
import { ClaimServiceService } from '../../../../shared/services/claim-service.service';
import { ClaimWizardService } from '../../../../shared/services/claim-wizard.service';
import { MessageRibbonService } from '../../../../shared/services/message-ribbon.service';
import { AppLabelConstants } from '../../../../shared/constants/appLabel.constants';
import { SessionConstants } from '../../../../shared/constants/session.constants';
import { AppConstants } from '../../../../shared/constants/app.constants';
import { ErrorConstants } from '../../../../shared/constants/error.constants';
import { ReferenceHelperService } from '@app/shared/services/reference-helper.service';
import { Subscription } from 'rxjs';
import { IntakeOWCSContentService } from '@app/cxr/modules/intake/shared/services/intake-owcs-content.service';
import { IntakeLabelConstants } from '@app/cxr/modules/intake/shared/constants/intake-label.constants';
import { OverpaymentSessionConstants } from '@app/cxr/overpayment/shared/constants/overpayment-session.constants';
import { AppLeaveIntakeConstants } from '@app/cxr/leave-claim-intake-flow/leave-intake.constants';
import { IFuturePaymentsExistList } from '@shared/models/futurePaymentsExist.model';
import { EmployeeStatusService } from '@app/shared/services/employee-status.service';

@Component({
  selector: 'claim-status',
  templateUrl: './claim-status.component.html',
  styleUrls: ['./claim-status.component.scss']
})
export class ClaimStatusComponent implements OnInit, AfterContentInit, AfterViewInit, OnChanges {
  @Input() currentClaimRelated = [];
  @Input() currentClaim = [];
  @Input() claimsReturned;
  @Input() isPromptDisplay: boolean;
  @Input() leaveCalcFT: boolean;
  @Input() companyParm: boolean;
  @Input() insuredLeaves;
  @Input() showContainer: string;
  @Input() preRendering = 'skeleton-container';
  @Input() overpaymentApplicable: boolean;
  // call the DateLabelValueComponent using view child
  @ViewChild(DateLabelValueComponent, { static: false }) DateLabelValue: DateLabelValueComponent;
  @Output() resolveContent = new EventEmitter();
  currentUser: User;
  showProgressIcon = true;
  orangeIconMissingInfo = true;
  url: string;
  claimsWithFuturePayments: string[] = [];
  overpaymentMessage: string = '';
  overpaymentLink: string = '';
  claimFuturePaymentExist: IFuturePaymentsExistList[] = [];
  disableSpinner: string = 'false';
  disableButton: boolean = false;
  isStartANewClaimPostAuthDisabled: boolean = false;
  public userClaims: any[] = [];
  public newClaimIntakeAbsenceRights;
  public surgerySection;
  public mutlipleIndicator: string;
  public form_Library_name = '';
  public form_Library_desc = '';
  public sortedBenefits;
  public jsonBOM;
  public currUser;
  public message_Header;
  public message_Body;
  public filteredLeaves = [];
  public insuredId: string;
  public personIds: string[];
  public isHavingClaims: boolean = false;
  public isFullyInsured: boolean = false;
  public userClaim;
  public isClaimServiceHasError: boolean = false;
  public userDetails;
  public appLabelConstant = AppLabelConstants;
  private showProgressIconsession;
  private orangeIconMissingInfosession;
  private pageId: string;
  private owscContent;
  private section_title = '';
  private extension_Status_Message = '';
  private extension_Status_Icon_Text = '';
  private infoLabel = '';
  private message_1_Label = '';
  private gotoMissingInfo_Button = '';
  private header_3 = '';
  private message_3 = '';
  private header_2 = '';
  private message_2 = '';
  private disability_Message = '';
  private disability_Message1 = '';
  private surgery_Message = '';
  private maternity_Action_Message = '';
  private subscription: Subscription;
  private maternityToggleParmsSubcription: Subscription;
  employeeStatusSubscription: Subscription;
  mobileNumber: string;
  constructor(private router: Router,
    private contentService: ContentService,
    private storageManagementService: StorageManagementService,
    private claimService: ClaimServiceService,
    private claimWizardService: ClaimWizardService,
    private referenceHelper: ReferenceHelperService,
    private messageRibbonService: MessageRibbonService,
    private intakeOWCSService: IntakeOWCSContentService,
    private employeeStatusService: EmployeeStatusService,) {
  }

  ngOnInit() {
    if (this.storageManagementService.getSessionData(SessionConstants.CLAIM_MOBILE)) {
      this.mobileNumber = this.storageManagementService.getSessionData(SessionConstants.CLAIM_MOBILE);
    }
    this.userDetails = this.storageManagementService.getAppData(SessionConstants.USER_DATA);
    this.personIds = this.userDetails.personIds;
    this.insuredId = this.userDetails.insuredId;
    this.userClaim = this.storageManagementService.getAppData(SessionConstants.USER_CLAIMS);
    if (this.userClaim && this.userClaim.claimOverview) {
      this.isHavingClaims = true;
      this.isPromptDisplay = this.storageManagementService.getSessionData('isPromptDisplay');
      this.claimServiceHasError();
    } else {
      this.subscription = this.referenceHelper.reference.subscribe(
        val => {
          if (val.get('isUserclaim')) {
            this.userClaim = this.storageManagementService.getAppData(SessionConstants.USER_CLAIMS);
            this.isHavingClaims = true;
            this.isPromptDisplay = this.storageManagementService.getSessionData('isPromptDisplay');
            this.claimServiceHasError();
          } else {
            this.isHavingClaims = false;
          }
        });
    }

  }
  setupBenefitsData() {
    this.currentClaim = ((this.storageManagementService.getAppData(SessionConstants.USER_CLAIMS)) && (this.storageManagementService.getAppData(SessionConstants.USER_CLAIMS).claimOverview) &&
      (this.storageManagementService.getAppData(SessionConstants.USER_CLAIMS).claimOverview.nonRelatedClaims) &&
      (this.storageManagementService.getAppData(SessionConstants.USER_CLAIMS).claimOverview.nonRelatedClaims.length > 0)) ?
      this.storageManagementService.getAppData(SessionConstants.USER_CLAIMS).claimOverview.nonRelatedClaims : [];
    this.currentClaimRelated = ((this.storageManagementService.getAppData(SessionConstants.USER_CLAIMS)) && (this.storageManagementService.getAppData(SessionConstants.USER_CLAIMS).claimOverview) &&
      (this.storageManagementService.getAppData(SessionConstants.USER_CLAIMS).claimOverview.relatedClaims) &&
      (this.storageManagementService.getAppData(SessionConstants.USER_CLAIMS).claimOverview.relatedClaims.length > 0)) ?
      this.storageManagementService.getAppData(SessionConstants.USER_CLAIMS).claimOverview.relatedClaims : [];
    this.userClaims = [this.currentClaim, this.currentClaimRelated, this.insuredLeaves];
    /*Page reload flag*/
   // const reloadFlg = this.storageManagementService.getAppData(SessionConstants.RELOADCLAIMS);
    const reloadFlg = true;
    if (!reloadFlg) {
      this.sortedBenefits = this.storageManagementService.getSessionData(SessionConstants.SORTED_BENEFITS);
    }
    if (this.claimsReturned && !this.sortedBenefits && (this.currentClaim || this.currentClaimRelated || this.insuredLeaves)) {
      this.removeCancelledLeaves();
      this.sortedBenefits = this.claimService.sortBenefitsForDisplay([this.currentClaim, this.currentClaimRelated, this.filteredLeaves]);
      this.storageManagementService.setSessionData(SessionConstants.SORTED_BENEFITS, this.sortedBenefits);
      this.storageManagementService.setAppData(SessionConstants.RELOADCLAIMS, false);
    }
  }

  removeCancelledLeaves() {
    if (this.insuredLeaves) {
      this.filteredLeaves = this.insuredLeaves.filter(leave => leave.leaveStatus.toLowerCase() !== AppConstants.LEAVE_CANCELLED);
    }
  }

  claimServiceHasError() {
    if (this.isHavingClaims) {
      const validInsuredId = this.userDetails && this.insuredId && this.insuredId.length > 0;
      const validPersonId = this.userDetails && this.personIds && this.personIds.length > 0 && this.personIds[0].length > 0;
      const errorCode = this.userClaim && this.userClaim.errorList && parseInt(this.userClaim.errorList[0].errorCode, 10);
      if (this.userClaim && this.userClaim.claimOverview && this.userClaim.claimOverview.nonRelatedClaims) {
        for (let i = 0; i < this.userClaim.claimOverview.nonRelatedClaims.length; i++) {
          if (this.userClaim.claimOverview.nonRelatedClaims[i].fundingMethod === ErrorConstants.FUNDING_METHOD) {
            this.isFullyInsured = true;
          }
          break;
        }
      }
      if (this.userClaim && this.userClaim.claimOverview && this.userClaim.claimOverview.relatedClaims) {
        for (let i = 0; i < this.userClaim.claimOverview.relatedClaims.length; i++) {
          if (this.userClaim.claimOverview.relatedClaims[i].fundingMethod === ErrorConstants.FUNDING_METHOD) {
            this.isFullyInsured = true;
            break;
          }
        }
      }
      if (this.userClaim && this.userClaim.claimHistory && this.userClaim.claimHistory.disabilityClaims) {
        for (let i = 0; i < this.userClaim.claimHistory.disabilityClaims.length; i++) {
          if (this.userClaim.claimHistory.disabilityClaims[i].fundingMethod === ErrorConstants.FUNDING_METHOD) {
            this.isFullyInsured = true;
            break;
          }
        }
      }
      if (validInsuredId && validPersonId) {
        if (errorCode === ErrorConstants.ERR_CODE_50001 || errorCode === ErrorConstants.ERR_CODE_50002 || errorCode === ErrorConstants.ERR_CODE_50003) {
          this.isClaimServiceHasError = true;
        }
      } else if (validInsuredId && !validPersonId) {
        if (errorCode === ErrorConstants.ERR_CODE_50001 || errorCode === ErrorConstants.ERR_CODE_50002) {
          this.isClaimServiceHasError = true;
        }
      } else if (!validInsuredId && validPersonId) {
        if (errorCode === ErrorConstants.ERR_CODE_50002 || errorCode === ErrorConstants.ERR_CODE_50003) {
          this.isClaimServiceHasError = true;
        }
      }
    }

  }

  ngAfterContentInit() {
    this.contentService.isSiteMapReady.subscribe(
      data => {
        this.callOWCS();
      });
  }
  ngOnChanges(changes) {
    this.isStartANewClaimPostAuthDisabled = this.storageManagementService.getSessionData(AppConstants.IS_START_A_NEW_CLAIM_POST_AUTH_DISABLED);
    if (this.claimsReturned) {
      this.setupBenefitsData();
    }
  }
  isArray(val) {
    return val instanceof Array;
  }

  ngAfterViewInit() {
  }

  callOWCS() {
    this.jsonBOM = JSON.parse(this.storageManagementService.retrieveSessionObject(SessionConstants.APP_DATA)).appData;
    this.currUser = this.jsonBOM.userData;
    if(this.storageManagementService.getSessionData(SessionConstants.CLAIM_OVERVIEW_OWCS)){
      this.owscContent = this.storageManagementService.getSessionData(SessionConstants.CLAIM_OVERVIEW_OWCS)
      this.populateContent()
    } else {
      this.pageId = this.contentService.getPageId(AppConstants.PAGE_NAME_CLAIM_OVERVIEW);
      if (this.pageId) {
        this.contentService.getOWCSResponse(this.pageId, AppConstants.PAGE_NAME_CLAIM_OVERVIEW_1).subscribe(
          val => {
            if (val) {
              this.storageManagementService.setSessionData(SessionConstants.CLAIM_OVERVIEW_OWCS, val);
              this.owscContent = val;
              this.populateContent()
            }
          }, (error) => {
            console.error(error);
          });
      }
    }
  }

  populateContent() {
    if (this.owscContent && this.owscContent.data) {
      this.section_title = this.fetchContent('CO_01_Label');
      this.extension_Status_Message = this.fetchContent('CO_58_Label');
      this.extension_Status_Icon_Text = this.fetchContent('CO_60_Label');
      this.infoLabel = this.fetchContent('CO_47_Body');    //Missing Info Labels
      this.message_1_Label = this.fetchContent('CO_48_Body');
      this.gotoMissingInfo_Button = this.fetchContent('CO_50_Body');
      this.header_3 = this.fetchContent('CO_53_Body');
      this.message_3 = this.fetchContent('CO_54_Body');
      this.header_2 = this.fetchContent('CO_51_Body');
      this.message_2 = this.fetchContent('CO_52_Body');
      this.disability_Message1 = this.fetchContent('CO_55A_Body');   //for confirm disability
      this.disability_Message = this.fetchContent('CO_56_Body');
      this.surgery_Message = this.fetchContent('CO_55_Body');    //for confirm surgery model\
      this.maternity_Action_Message = this.fetchContent('CO_08_Body');    //for maternity model
      this.form_Library_name = this.fetchContent('CO_85_Body');    //form library widget
      this.form_Library_desc = this.fetchContent('CO_86_Body');
      this.message_Header = this.fetchContent('CO_103_Body');   //for leave status message
      this.message_Body = this.fetchContent('CO_104_Body');
      this.overpaymentMessage = this.fetchContent('CO_0007_Body');
      this.overpaymentLink = this.fetchContent('CO_0008_Body');
    }
  }

  fetchContent(label) {
    const data = this.owscContent.data;
    return data[label] && data[label].value ? data[label].value : '';
  }

  hideProgressIconChange() {
    if (this.currentClaim) {
      for (const claim of this.currentClaim) {
        claim.showProgressIcon = true;
        claim.orangeIconMissingInfo = false;
        claim.missingInfoclaim = false;
        if (this.showProgressIconsession) {
          claim.showProgressIcon = this.showProgressIconsession;
          if (this.orangeIconMissingInfosession) {
            claim.orangeIconMissingInfo = this.orangeIconMissingInfosession;
          }
        }
        claim.LTDmissingInfo = false;
        if (claim.claimOnlineStatus === AppConstants.CLOSED_CLAIM && claim.claimOnlineSubStatus === AppConstants.CLOSED_CLAIM) {
          claim.showProgressIcon = false;
        }
        if ((claim.claimOnlineStatus === AppConstants.CLOSED_CLAIM && claim.claimOnlineSubStatus === AppConstants.MISSING_INFO)) {
          claim.orangeIconMissingInfo = true;
          claim.showProgressIcon = false;
          claim.missingInfoclaim = true;
        }
        if ((claim.coverageCategory === AppConstants.LTD) && claim.claimStatus.toLowerCase() === AppConstants.CS_PENDING) {
          claim.LTDmissingInfo = true;
        }
      }
      for (const claim2 of this.currentClaimRelated) {
        claim2.showProgressIcon = true;
        claim2.orangeIconMissingInfo = false;
        if (this.showProgressIconsession) {
          claim2.showProgressIcon = this.showProgressIconsession;
          if (this.orangeIconMissingInfosession) {
            claim2.orangeIconMissingInfo = this.orangeIconMissingInfosession;
          }
        }
        if (claim2.claimOnlineStatus === AppConstants.CLOSED_CLAIM && claim2.claimOnlineSubStatus === AppConstants.CLOSED_CLAIM && claim2.extensionStatusCode === null && claim2.extensionSubStatusCode === null) {
          claim2.showProgressIcon = false;
        }
        if ((claim2.claimOnlineStatus === AppConstants.CLOSED_CLAIM && claim2.claimOnlineSubStatus === AppConstants.MISSING_INFO)) {
          claim2.orangeIconMissingInfo = true;
          claim2.showProgressIcon = false;
          claim2.missingInfoclaim = true;
        }
        if ((claim2.coverageCategory === AppConstants.LTD) && claim2.claimStatus.toLowerCase() === AppConstants.CS_PENDING) {
          claim2.LTDmissingInfo = true;
        }
      }
    }
  }

  getActiveEmployeeID(e: Event): void {
    const activePersoneIds: string[] = JSON.parse(this.storageManagementService.retrieveSessionObject(SessionConstants.APP_DATA)).appData.personIdsInMudi;
    let activeUserIds = [];
    this.employeeStatusSubscription = this.employeeStatusService.getEmployeeActiveStatus(activePersoneIds)?.subscribe(
      val => {
        if (val && val.responseCode === '001') {
          if (val.flag) {
            activeUserIds = activePersoneIds?.filter(x => x === val?.status[0]?.personID);
            this.storageManagementService.setAppData(SessionConstants.ACTIVE_USER_ID, activeUserIds[0]);
            this.getstartClaimDetails(e);
          } else {
            this.resolveContent.emit();
          }
        } else {
          this.resolveContent.emit();
        }
      }, (error) => {
        this.resolveContent.emit();
      });
  }

  /*
   * US12116 - Claim Wizard
   * Author: DB
   * Added: 03/08/2018
   * Developer Note: using caseid and partyrefid as block-scope variables because
   * class properties of the same purpose are getting values from the wrong fields at the moment of implementation.
   */

  getstartClaimDetails(e: Event): void {
    this.userDetails = this.storageManagementService.getAppData(SessionConstants.USER_DATA);
    e.stopPropagation();
    e.preventDefault();
    const appData = JSON.parse(this.storageManagementService.retrieveSessionObject(SessionConstants.APP_DATA)).appData;
    const caseId = appData.caseData.defaultCaseId;
    const partyRefId = appData.caseData.defaultPartyRefId;
    const staleData = this.storageManagementService.retrieveSessionObject(SessionConstants.WIZARD_OPTIONS);
    if (staleData) {
      this.storageManagementService.removeSessionObject(SessionConstants.WIZARD_OPTIONS);
    }
    // for THAA user to navigate to leave submit page and for DCS only user it should navigate to claim wizard flow
    const caseData = this.storageManagementService.getAppData(SessionConstants.CASE_DATA);
    const intake = this.storageManagementService.getSessionData(SessionConstants.TOGGLE_LIST);
    let checkToggle = false;
    const ciiSessionData = this.storageManagementService.getSessionData(SessionConstants.CII_FEATURE_DATA);
    if (ciiSessionData) {
      this.newClaimIntakeAbsenceRights = ciiSessionData.allowEnterAbsence;
    }
    if (intake) {
      const toggleIndex = intake.findIndex(toggles => toggles.toggle === IntakeLabelConstants.NEWINTAKE);
      if (toggleIndex > -1) {
        checkToggle = intake[toggleIndex].state;
      }
    }
    if (checkToggle) {
      this.intakeOWCSService.getIntakeOWCSContent();
    }
    if (checkToggle && (this.newClaimIntakeAbsenceRights === 1)) {
      this.router.navigate([AppLabelConstants.URL_START_CLAIM]);
    } else if (checkToggle && this.newClaimIntakeAbsenceRights !== 1) {
      this.router.navigate([AppLeaveIntakeConstants.CLAIM_SUBMIT_NOT_ALLOW_PAGE]);
    } else if (this.userDetails && this.userDetails.sourcePartyDetailsList[0]?.srcSystemCd === AppConstants.SRC_SYS_CD_8) {
      this.router.navigate([AppLabelConstants.URL_SELECT_REASON]);
    } else {
      this.claimWizardService.getOptionsPostAuth(caseId, partyRefId).subscribe(res => {
        this.processCWResponse(res);
      }, err => {
        this.processCWResponse(err);
      });
    }
  }
  startAClaim(e: Event) {
    let statusToggle = false;
    const toggleList = this.storageManagementService.getSessionData(SessionConstants.TOGGLE_LIST);
    if (toggleList && toggleList.length > 0) {
      const toggleIndex = toggleList.findIndex(toggles => toggles.toggle.toLowerCase() === IntakeLabelConstants.EMPLOYEE_STATUS.toLowerCase());
      if (toggleIndex > -1) {
        statusToggle = (toggleList[toggleIndex].state);
      }
    }
    const isMultiplePersoneId =JSON.parse(this.storageManagementService.retrieveSessionObject(SessionConstants.APP_DATA)).appData.personIdsInMudi?.length > 1;
    if (isMultiplePersoneId && statusToggle) {
     this.storageManagementService.deleteActivePersonId();
     this.getActiveEmployeeID(e);
    } else {
     this.getstartClaimDetails(e);
    }
    this.disableSpinner = 'false';
    this.disableButton = true;

  }

  processCWResponse(res) {
    switch (res.status) {
      case 404:
      case AppConstants.STATUS_CODE_404:
        this.messageRibbonService.addMessage(ErrorConstants.ERROR, ErrorConstants.ERROR_MSG_SOMETHNG_WENT_WRONG, ErrorConstants.ERROR_MESSAGE_1, AppLabelConstants.MSG_AUTO_CLS_TEXT);
        this.disableSpinner = 'true';
        this.disableButton = true;
        break;
      case 403:
      case AppConstants.STATUS_CODE_403:
        this.storageManagementService.setSessionObjectNoJSON(SessionConstants.WIZARD_ERROR, AppLabelConstants.UNABLE_CASE);
        this.router.navigateByUrl(AppLabelConstants.URL_CLAIMWIZ_ERR);
        break;
      case 400:
      case AppConstants.STATUS_CODE_400:
        this.messageRibbonService.addMessage(ErrorConstants.ERROR, ErrorConstants.ERROR_MSG_SOMETHNG_WENT_WRONG, ErrorConstants.ERROR_MESSAGE_1, AppLabelConstants.MSG_AUTO_CLS_TEXT);
        this.disableSpinner = 'true';
        this.disableButton = true;
        break;
      default:
        this.storageManagementService.setSessionObject(SessionConstants.WIZARD_OPTIONS, res);
        this.router.navigateByUrl(AppLabelConstants.URL_CLAIMWIZ_QUES);
        break;
    }
  }
  getClaimsWithFuturePayments(event): void {
    if (event) {
      this.claimsWithFuturePayments.push(event);
      this.claimsWithFuturePayments.sort();
      this.filterClaimsWithFuturePayments(this.claimsWithFuturePayments);
      this.storageManagementService.setSessionData(SessionConstants.CLAIMS_WITH_FUTURE_PAYMENTS, this.claimsWithFuturePayments);
    }
  }
  filterClaimsWithFuturePayments(data): string[] {
    return data.filter((value, index) => data.indexOf(value !== index));
  }

  getfuturePaymentClaims(event): void {
    let futurePaymentArray: IFuturePaymentsExistList[] = [];
    if (event) {
      const futurePaymentData = this.storageManagementService.getSessionData(SessionConstants.IS_FUTURE_PAYMENT_EXIST_LIST);
      if (futurePaymentData && futurePaymentData.length > 0) {
        this.claimFuturePaymentExist = futurePaymentData;
        futurePaymentArray = futurePaymentData.filter(data => data.claimEventId === event.claimEventId);
        if (futurePaymentArray && futurePaymentArray.length === 0) {
          this.claimFuturePaymentExist.push(event);
          this.storageManagementService.setSessionData(SessionConstants.IS_FUTURE_PAYMENT_EXIST_LIST, this.claimFuturePaymentExist);
        }
      } else {
        this.claimFuturePaymentExist.push(event);
        this.storageManagementService.setSessionData(SessionConstants.IS_FUTURE_PAYMENT_EXIST_LIST, this.claimFuturePaymentExist);
      }
    }
  }

  //logic to determine if over payment alert section should be displayed
  isOverpaymentApplicable() {
    let hideOverpaymentAlert = this.storageManagementService.getSessionData(OverpaymentSessionConstants.HIDE_OVERPAYMENT_ALERT);
    const hideOverpaymentAlertOnLogout = this.storageManagementService.getSessionData(OverpaymentSessionConstants.HIDE_OVERPAYMENT_ALERT_ON_LOGOUT);
    hideOverpaymentAlert = (hideOverpaymentAlert && JSON.parse(hideOverpaymentAlert)) || (hideOverpaymentAlertOnLogout && JSON.parse(hideOverpaymentAlertOnLogout));
    return !hideOverpaymentAlert && this.claimsReturned && this.overpaymentApplicable && this.overpaymentMessage && this.overpaymentLink;
  }
}
