import {Component, OnInit} from '@angular/core';
import {Router} from '@angular/router';
import {AppLabelConstants} from '@shared/constants/appLabel.constants';

@Component({
  selector: 'app-error-page',
  templateUrl: './error-page.component.html',
  styleUrls: ['./error-page.component.scss']
})
export class ErrorPageComponent implements OnInit {
  public errorImgPath: string = AppLabelConstants.PHONE_ICON;
  public pageTitle: string = '';

  public errorLbl: string;
  public errorText: string;
  constructor(private router: Router) { }


  ngOnInit() {
    this.errorLbl = 'Let\'s Chat instead.';
    this.errorText = 'It seems that we\'re having some trouble processing your request online. Call us at 1-877-778-1383  for help.';
  }

  backToHome() {
    this.router.navigate([AppLabelConstants.URL_HOME]);
  }
}
