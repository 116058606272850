/**
 * Created by AC12256 on 12/15/2017.
 */
import {systemDownAlert} from '../shared/models/systemDownAlert.model';

export const SYSALERTMOCK: systemDownAlert[] =
[
  {
    page: 'About You',
    startDate: '12/15/2017 12:00PM',
    endDate: '12/15/2017 2:00PM',
    message: null
  },
/*{
{
  'page': 'Claim Details',
  'startDate': '12/16/2017 2:30:10PM',
  'endDate': '12/16/2017 4:30:10PM',
  'message': [
    'Due to some scheduled downtime, our systems will not be available between ',
    ' at ',
    ' and ',
    '. If you have an urgent matter, please call our support line at 1-888-575-5762']
}*/];
