import {Component, HostListener, Input, OnInit} from '@angular/core';

@Component({
  selector: 'img-link',
  templateUrl: 'img-link.component.html',
  styleUrls: ['img-link.component.scss']
})
export class ImgLinkComponent implements OnInit {

  @Input() linkName: string;
  @Input() faIcon: string;
  @Input() linkLocation: string;
  @Input() linkId: number;
  @Input() imgUrl: string;
  public templateUrl: string;

  constructor() {}

  @HostListener('keyup.enter', ['$event'])
  onKeyUpEnter(event) {
    event.preventDefault();
    event.target.click();
  }

  ngOnInit() {
    this.templateUrl = ( this.imgUrl.includes('/assets/') ) ? this.imgUrl : `/assets/images/icons/${this.imgUrl}.svg`;
  }

}
