
import {map} from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import {env} from '../../../environments/environment-loader';
import { AppLabelConstants } from '../constants/appLabel.constants';
import { HttpClient, HttpHeaders } from '@angular/common/http';

@Injectable()
export class PsvService {

  constructor(private http: HttpClient) { }

  getPsvToggle(ClaimId: string, PersonId: string): Observable<any> {
    return this.http.get(env.thaaWKABPortalService.psvToggle + '&ClaimID=' + ClaimId + '&personId=' + PersonId
    , {
      headers: new HttpHeaders()
        .set(AppLabelConstants.CONTENT_TYPE_KEY, AppLabelConstants.APPLICATION_KEY)
    }
    ).pipe(
      map((response: Response) => response));
  }

  getPsvVideoAlertsForClaim(ClaimId: string, PersonId: string): Observable<any> {
    return this.http.get(env.thaaWKABPortalService.thaaPsvVideoAlertsForClaimURL + '&ClaimID=' + ClaimId + '&personId=' + PersonId
    , {

      headers: new HttpHeaders()

        .set(AppLabelConstants.CONTENT_TYPE_KEY, AppLabelConstants.APPLICATION_KEY)

    }
    ).pipe(
      map((response: Response) => response));
  }
}
