import {AfterViewInit, Component, OnInit} from '@angular/core';
import {StorageManagementService} from '../../shared/services/storage-management.service';
import {LeaveDynatraceAction} from '../../shared/utils/leaveDynatraceAction';
import {AppLabelConstants} from '../../shared/constants/appLabel.constants';

@Component({
  selector: 'feedback-ciam',
  templateUrl: 'feedback-ciam.component.html',
  styleUrls: ['feedback-ciam.component.scss']
})

export class FeedbackCiamComponent extends LeaveDynatraceAction implements OnInit, AfterViewInit {

  public appLabelConstants = AppLabelConstants;
  constructor(
    private storageManagementService: StorageManagementService
  ) {
    super(storageManagementService)
  }

  ngOnInit() {
    // Initializing
  }

  ngAfterViewInit() {
    super.ngAfterViewInit();
  }
}
