export const MOCK_FUTURE_PAYMENTS = {
  futurePaymentDetailsInfo: [
    {
      futurepaymentInfoList: [
        {
          claimId: '2605667',
          authorizedEndDate: '11/24/2021',
          eliminationPeriod: 7,
          remainingEliminationPeriod: 0,
          eliminationPeriodStartDate: '10/30/2021',
          eliminationPeriodEndDate: '11/07/2021',
          firstDayAbsentDate: '',
          isRemainingDays: 0,
          isVariablePay: false,
          minimumBenefitAmount: 20,
          maximumBenefitAmount: 170,
          nonTaxablePct: 11,
          frequency: 'BI-WEEKLY',
          paymentType: 'Percentage',
          flatAmount: 0,
          benefitPercentage: 0,
          tieredBenefit: [
            {
              fromDate: '11/08/2021',
              toDate: '11/21/2021',
              percentage: 96.666,
              displayOrder: 1,
              benefitDuration: 2,
              maxBenefitAmount: 170
            },
            {
              fromDate: '11/22/2021',
              toDate: '12/12/2021',
              percentage: 85,
              displayOrder: 2,
              benefitDuration: 3,
              maxBenefitAmount: 170
            },
            {
              fromDate: '12/13/2021',
              toDate: '01/02/2022',
              percentage: 67,
              displayOrder: 3,
              benefitDuration: 3,
              maxBenefitAmount: 170
            },
            {
              fromDate: '01/03/2022',
              toDate: '01/23/2022',
              percentage: 50,
              displayOrder: 4,
              benefitDuration: 3,
              maxBenefitAmount: 170
            },
            {
              fromDate: '01/24/2022',
              toDate: '05/08/2022',
              percentage: 33.333,
              displayOrder: 5,
              benefitDuration: 15,
              maxBenefitAmount: 170
            }
          ],
          payments: [
            {
              payReqID: '2080086',
              netBenefitAfterOffset: 126.9,
              paymentPeriodStartDate: '11/08/2021',
              paymentPeriodEndDate: '11/20/2021',
              paymentPeriodGrossBenefit: 100,
              planPayPercentage: 96.666,
              netPaymentAfterOffOvp: 126.9,
              preDisabilityEarningsAmt: 100,
              grossBenefit: 96.67,
              periodGrossBenefit: 89.76,
              planPayMaximumAmount: 170,
              payrollDays: 14,
              daysPaid: 13,
              isProrated: true,
              isMinPayment: false,
              hasMultiple: false,
              isMaxPayment: true,
              isLumpedPayment: false,
              overPaymentIndicator: false,
              overPaymentAmount: 0,
              fpOffsets: [
                {
                  offsetId: '114688',
                  offsetDescription: 'Child Allowance Dependent 9',
                  offsetAmount: 37.14,
                  offsetCode: 'CI',
                  offsetType: 'ADD'
                }
              ],
              fpDeductions: [{
                deductionId: '10',
                deductionCode: '',
                deductionDescription: 'Medical',
                deductionAmount: 25,
                deductionType: 'DEDUCT',
              } ],
              fpOverPayment: []
            },
            {
              payReqID: '2080085',
              netBenefitAfterOffset: 36.55,
              paymentPeriodGrossBenefit: 100,
              paymentPeriodStartDate: '11/21/2021',
              paymentPeriodEndDate: '11/21/2021',
              planPayPercentage: 96.666,
              netPaymentAfterOffOvp: 36.55,
              preDisabilityEarningsAmt: 100,
              grossBenefit: 96.67,
              periodGrossBenefit: 6.9,
              planPayMaximumAmount: 170,
              payrollDays: 14,
              daysPaid: 1,
              isProrated: true,
              hasMultiple: false,
              isMinPayment: false,
              isMaxPayment: true,
              isLumpedPayment: false,
              overPaymentIndicator: false,
              overPaymentAmount: 0,
              fpOffsets: [
                {
                  offsetId: '114688',
                  offsetDescription: 'Child Allowance Dependent 9',
                  offsetAmount: 11.43,
                  offsetCode: 'CI',
                  offsetType: 'ADD'
                }
              ],
              fpDeductions: [{
                deductionId: '10',
                deductionCode: '',
                deductionDescription: 'Medical',
                deductionAmount: 25,
                deductionType: 'DEDUCT',
              } ],
              fpOverPayment: []
            },
            {
              payReqID: '2080085',
              netBenefitAfterOffset: 36.55,
              paymentPeriodGrossBenefit: 100,
              paymentPeriodStartDate: '11/22/2021',
              paymentPeriodEndDate: '11/24/2021',
              planPayPercentage: 85,
              hasMultiple: true,
              netPaymentAfterOffOvp: 36.55,
              preDisabilityEarningsAmt: 100,
              grossBenefit: 85,
              periodGrossBenefit: 18.21,
              planPayMaximumAmount: 170,
              payrollDays: 14,
              daysPaid: 3,
              isProrated: true,
              isMinPayment: false,
              isMaxPayment: true,
              isLumpedPayment: false,
              overPaymentIndicator: false,
              overPaymentAmount: 0,
              fpOffsets: [
                {
                  offsetId: '114688',
                  offsetDescription: 'Child Allowance Dependent 9',
                  offsetAmount: 11.43,
                  offsetCode: 'CI',
                  offsetType: 'ADD'
                }
              ],
              fpDeductions: [{
                deductionId: '10',
                deductionCode: '',
                deductionDescription: 'Medical',
                deductionAmount: 25,
                deductionType: 'DEDUCT',
              } ],
              fpOverPayment: []
            }
          ]
        }
      ]
    }
  ],
  responseCode: '001',
  responseReason: 'Success'
}
