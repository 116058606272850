import {LeaveDetailsListModel} from '../shared/models/leaveDetailsListModel.model';

export const MOCK_LEAVE_DETAILS_LIST: LeaveDetailsListModel = {
  claimDetails: {
  claimNumber: 1608776,
    product: '6001',
    disabilityDate: null,
    eliminationPeriod: null,
    benefitBeginDate: null,
    changeInDisabilityDefinitionDate: null,
    maximumBenefitEndDate: null,
    workRelatedIndicator: null,
    workStatus: null,
    claimStatus: null,
    overpaymentBalance: null,
    lastDayWorked: null,
    daysApproved: null,
    claimStatusReason: null,
    approvedStartDate: null,
    approvedThroughDate: null,
    benefitEndDate: null,
    benefitDuration: null,
    /*'certDetailsList': [
    {
      'certId': 209977,
      'certPeriodStartDate': '12/19/2017',
      'certPeriodEndDate': '12/20/2020',
      'certCore_Intermit_Freq': '1',
      'certCore_Intermit_Freq_Unit': '',
      'certFuture_Intermit_Freq_Unit_NBR': '',
      'certCore_Intermit_Dur': '1',
      'certCore_Intermit_Dur_Unit': '',
      'certFrequency_AMN_Flag': '',
      'certDuration_AMN_Flag': '',
      'certAbsenceType': 'Flare up',
      'certPeriodTermDate': '12/20/2020',
      'certStatus': 'Not Yet Received',
      'certStatusId': '10'
    },
      {
        'certId': 209977,
        'certPeriodStartDate': '5/19/2018',
        'certPeriodEndDate': '5/30/2020',
        'certCore_Intermit_Freq': '',
        'certCore_Intermit_Freq_Unit': '',
        'certFuture_Intermit_Freq_Unit_NBR': '',
        'certCore_Intermit_Dur': '',
        'certCore_Intermit_Dur_Unit': '',
        'certFrequency_AMN_Flag': 'T',
        'certDuration_AMN_Flag': 'T',
        'certAbsenceType': 'flare up',
        'certPeriodTermDate': '5/30/2020',
        'certStatus': 'accepted',
        'certStatusId': '1'
      },
    {
      'certId': 209815,
      'certPeriodStartDate': '12/4/2018',
      'certPeriodEndDate': '12/6/2020',
      'certCore_Intermit_Freq': '2',
      'certCore_Intermit_Freq_Unit': 'Month',
      'certFuture_Intermit_Freq_Unit_NBR': '2',
      'certCore_Intermit_Dur': '1',
      'certCore_Intermit_Dur_Unit': 'Day',
      'certFrequency_AMN_Flag': 'F',
      'certDuration_AMN_Flag': 'F',
      'certAbsenceType': 'Flare Up',
      'certPeriodTermDate': '12/6/2020',
      'certStatus': 'accepted/std',
      'certStatusId': '6'
    },
      {
        'certId': 209983,
        'certPeriodStartDate': '9/20/2018',
        'certPeriodEndDate': '11/10/2020',
        'certCore_Intermit_Freq': '5',
        'certCore_Intermit_Freq_Unit': 'month',
        'certFuture_Intermit_Freq_Unit_NBR': '',
        'certCore_Intermit_Dur': '4',
        'certCore_Intermit_Dur_Unit': 'day',
        'certFrequency_AMN_Flag': 'T',
        'certDuration_AMN_Flag': 'T',
        'certAbsenceType': '',
        'certPeriodTermDate': '12/10/2020',
        'certStatus': 'accepted/work comp',
        'certStatusId': '7'
      },
    {
      'certId': 209979,
      'certPeriodStartDate': '11/11/2018',
      'certPeriodEndDate': '9/6/2020',
      'certCore_Intermit_Freq': '2',
      'certCore_Intermit_Freq_Unit': 'month',
      'certFuture_Intermit_Freq_Unit_NBR': '',
      'certCore_Intermit_Dur': '4',
      'certCore_Intermit_Dur_Unit': 'day',
      'certFrequency_AMN_Flag': 'F',
      'certDuration_AMN_Flag': 'F',
      'certAbsenceType': 'Appointment',
      'certPeriodTermDate': '9/10/2018',
      'certStatus': 'accepted my manager/supervisor',
      'certStatusId': '9'
    },
    {
      'certId': 209978,
      'certPeriodStartDate': '9/11/2018',
      'certPeriodEndDate': '10/10/2020',
      'certCore_Intermit_Freq': '3',
      'certCore_Intermit_Freq_Unit': 'month',
      'certFuture_Intermit_Freq_Unit_NBR': '',
      'certCore_Intermit_Dur': '',
      'certCore_Intermit_Dur_Unit': '',
      'certFrequency_AMN_Flag': 'F',
      'certDuration_AMN_Flag': 'F',
      'certAbsenceType': 'Appointment',
      'certPeriodTermDate': '10/12/2019',
      'certStatus': 'accepted/pfl',
      'certStatusId': '14'
    },
      {
        'certId': 209979,
        'certPeriodStartDate': '9/11/2018',
        'certPeriodEndDate': '12/10/2019',
        'certCore_Intermit_Freq': '',
        'certCore_Intermit_Freq_Unit': '',
        'certFuture_Intermit_Freq_Unit_NBR': '',
        'certCore_Intermit_Dur': '4',
        'certCore_Intermit_Dur_Unit': 'day',
        'certFrequency_AMN_Flag': 'F',
        'certDuration_AMN_Flag': 'F',
        'certAbsenceType': 'Appointment',
        'certPeriodTermDate': '12/12/2019',
        'certStatus': 'accepted/pfl',
        'certStatusId': ''
      },
      {
        'certId': 209979,
        'certPeriodStartDate': '10/11/2018',
        'certPeriodEndDate': '12/10/2019',
        'certCore_Intermit_Freq': '',
        'certCore_Intermit_Freq_Unit': '',
        'certFuture_Intermit_Freq_Unit_NBR': '',
        'certCore_Intermit_Dur': '4',
        'certCore_Intermit_Dur_Unit': 'day',
        'certFrequency_AMN_Flag': 'T',
        'certDuration_AMN_Flag': 'F',
        'certAbsenceType': 'Appointment',
        'certPeriodTermDate': '12/10/2019',
        'certStatus': 'Reviewed',
        'certStatusId': '11'
      },
      {
        'certId': 209980,
        'certPeriodStartDate': '10/11/2018',
        'certPeriodEndDate': '10/10/2019',
        'certCore_Intermit_Freq': '5',
        'certCore_Intermit_Freq_Unit': 'month',
        'certFuture_Intermit_Freq_Unit_NBR': '',
        'certCore_Intermit_Dur': '4',
        'certCore_Intermit_Dur_Unit': 'hours',
        'certFrequency_AMN_Flag': 'F',
        'certDuration_AMN_Flag': 'F',
        'certAbsenceType': 'Flare up',
        'certPeriodTermDate': '12/15/2019',
        'certStatus': 'accepted/work comp',
        'certStatusId': '7'
      },
      {
        'certId': 209987,
        'certPeriodStartDate': '10/11/2018',
        'certPeriodEndDate': '10/15/2019',
        'certCore_Intermit_Freq': '4',
        'certCore_Intermit_Freq_Unit': 'month',
        'certFuture_Intermit_Freq_Unit_NBR': '',
        'certCore_Intermit_Dur': '4',
        'certCore_Intermit_Dur_Unit': 'hours',
        'certFrequency_AMN_Flag': 'F',
        'certDuration_AMN_Flag': 'F',
        'certAbsenceType': 'Flare up',
        'certPeriodTermDate': '12/15/2019',
        'certStatus': 'accepted/work comp',
        'certStatusId': '7'
      },
      {
        'certId': 209988,
        'certPeriodStartDate': '11/11/2018',
        'certPeriodEndDate': '10/16/2019',
        'certCore_Intermit_Freq': '4',
        'certCore_Intermit_Freq_Unit': 'month',
        'certFuture_Intermit_Freq_Unit_NBR': '',
        'certCore_Intermit_Dur': '4',
        'certCore_Intermit_Dur_Unit': 'hours',
        'certFrequency_AMN_Flag': 'F',
        'certDuration_AMN_Flag': 'F',
        'certAbsenceType': 'Appointment',
        'certPeriodTermDate': '12/15/2019',
        'certStatus': 'accepted/work comp',
        'certStatusId': '7'
      },
      {
        'certId': 209989,
        'certPeriodStartDate': '11/11/2018',
        'certPeriodEndDate': '10/18/2019',
        'certCore_Intermit_Freq': '4',
        'certCore_Intermit_Freq_Unit': 'month',
        'certFuture_Intermit_Freq_Unit_NBR': '',
        'certCore_Intermit_Dur': '4',
        'certCore_Intermit_Dur_Unit': 'hours',
        'certFrequency_AMN_Flag': 'F',
        'certDuration_AMN_Flag': 'F',
        'certAbsenceType': 'Appointment',
        'certPeriodTermDate': '12/15/2019',
        'certStatus': 'accepted/work comp',
        'certStatusId': '7'
      },
      {
        'certId': 209981,
        'certPeriodStartDate': '11/17/2018',
        'certPeriodEndDate': '9/10/2019',
        'certCore_Intermit_Freq': '5',
        'certCore_Intermit_Freq_Unit': 'month',
        'certFuture_Intermit_Freq_Unit_NBR': '',
        'certCore_Intermit_Dur': '',
        'certCore_Intermit_Dur_Unit': '',
        'certFrequency_AMN_Flag': 'T',
        'certDuration_AMN_Flag': 'T',
        'certAbsenceType': '',
        'certPeriodTermDate': '9/10/2019',
        'certStatus': 'accepted/work comp',
        'certStatusId': '7'
      },
      {
        'certId': 209982,
        'certPeriodStartDate': '11/19/2018',
        'certPeriodEndDate': '9/10/2019',
        'certCore_Intermit_Freq': '5',
        'certCore_Intermit_Freq_Unit': 'month',
        'certFuture_Intermit_Freq_Unit_NBR': '',
        'certCore_Intermit_Dur': '4',
        'certCore_Intermit_Dur_Unit': 'day',
        'certFrequency_AMN_Flag': 'F',
        'certDuration_AMN_Flag': 'F',
        'certAbsenceType': '',
        'certPeriodTermDate': '10/10/2019',
        'certStatus': 'accepted/work comp',
        'certStatusId': '7'
      },
      {
        'certId': 209985,
        'certPeriodStartDate': '11/19/2018',
        'certPeriodEndDate': '9/10/2019',
        'certCore_Intermit_Freq': '',
        'certCore_Intermit_Freq_Unit': 'month',
        'certFuture_Intermit_Freq_Unit_NBR': '',
        'certCore_Intermit_Dur': '',
        'certCore_Intermit_Dur_Unit': 'day',
        'certFrequency_AMN_Flag': 'T',
        'certDuration_AMN_Flag': 'T',
        'certAbsenceType': '',
        'certPeriodTermDate': '10/10/2019',
        'certStatus': 'accepted/work comp',
        'certStatusId': '7'
      }
  ],*/
    certDetailsList : [
      /*{
      'certId': 798854,
      'certStatus': 'Accepted',
      'certPeriodStartDate': '7/1/2020',
      'certPeriodEndDate': '7/30/2020',
      'certCore_Intermit_Freq': '7',
      'certCore_Intermit_Freq_Unit': 'Month',
      'certFuture_Intermit_Freq_Unit_NBR': '1',
      'certCore_Intermit_Dur': '8',
      'certCore_Intermit_Dur_Unit': 'Hours',
      'certFrequency_AMN_Flag': 'F',
      'certDuration_AMN_Flag': 'F',
      'certAbsenceType': 'Appointment',
      'certPeriodTermDate': '6/30/2021',
      'certStatusId': '12',
      'certCreationDate': '7/21/2019 04:10',
    },
      {
        'certId': 798855,
        'certStatus': 'Accepted',
        'certPeriodStartDate': '7/1/2020',
        'certPeriodEndDate': '6/30/2021',
        'certCore_Intermit_Freq': '1',
        'certCore_Intermit_Freq_Unit': 'Year',
        'certFuture_Intermit_Freq_Unit_NBR': '1',
        'certCore_Intermit_Dur': '1',
        'certCore_Intermit_Dur_Unit': 'Hour',
        'certFrequency_AMN_Flag': 'F',
        'certDuration_AMN_Flag': 'F',
        'certAbsenceType': 'Flare Up',
        'certPeriodTermDate': '6/30/2021',
        'certStatusId': '8',
        'certCreationDate': '6/20/2020 06:10',
      },
      {
        'certId': 802930,
        'certStatus': 'Not Certified',
        'certPeriodStartDate': '7/1/2020',
        'certPeriodEndDate': '6/30/2021',
        'certCore_Intermit_Freq': '8',
        'certCore_Intermit_Freq_Unit': 'Month',
        'certFuture_Intermit_Freq_Unit_NBR': '1',
        'certCore_Intermit_Dur': '3',
        'certCore_Intermit_Dur_Unit': 'Hours',
        'certFrequency_AMN_Flag': 'F',
        'certDuration_AMN_Flag': 'F',
        'certAbsenceType': 'Flare Up',
        'certPeriodTermDate': '6/30/2021',
        'certStatusId': '5',
        'certCreationDate': '1/19/2020 05:10',
      },
      {
        'certId': 802931,
        'certStatus': 'Accepted',
        'certPeriodStartDate': '7/1/2020',
        'certPeriodEndDate': '7/23/2020',
        'certCore_Intermit_Freq': '1',
        'certCore_Intermit_Freq_Unit': 'Month',
        'certFuture_Intermit_Freq_Unit_NBR': '1',
        'certCore_Intermit_Dur': '3',
        'certCore_Intermit_Dur_Unit': 'Days',
        'certFrequency_AMN_Flag': 'F',
        'certDuration_AMN_Flag': 'F',
        'certAbsenceType': '',
        'certPeriodTermDate': '6/30/2021',
        'certStatusId': '6',
        'certCreationDate': '7/21/2020 09:10',
      },
      {
        'certId': 803715,
        'certStatus': 'Not Certified',
        'certPeriodStartDate': '5/26/2020',
        'certPeriodEndDate': '7/1/2020',
        'certCore_Intermit_Freq': '6',
        'certCore_Intermit_Freq_Unit': 'Week',
        'certFuture_Intermit_Freq_Unit_NBR': '4',
        'certCore_Intermit_Dur': '6',
        'certCore_Intermit_Dur_Unit': 'Hours',
        'certFrequency_AMN_Flag': 'F',
        'certDuration_AMN_Flag': 'F',
        'certAbsenceType': 'Appointment',
        'certPeriodTermDate': '7/1/2020',
        'certStatusId': '10',
        'certCreationDate': '7/20/2020 16:10',
      },*/
     /* {
        'certId': 805967,
        'certStatus': 'Not Certified',
        'certPeriodStartDate': '5/26/2020',
        'certPeriodEndDate': '7/1/2020',
        'certCore_Intermit_Freq': '1',
        'certCore_Intermit_Freq_Unit': 'Year',
        'certFuture_Intermit_Freq_Unit_NBR': '1',
        'certCore_Intermit_Dur': '1',
        'certCore_Intermit_Dur_Unit': 'Hour',
        'certFrequency_AMN_Flag': 'F',
        'certDuration_AMN_Flag': 'F',
        'certAbsenceType': 'Appointment',
        'certPeriodTermDate': '7/1/2020',
        'certStatusId': '5'
      },
      {
        'certId': 805968,
        'certStatus': 'Accepted',
        'certPeriodStartDate': '5/26/2020',
        'certPeriodEndDate': '7/1/2020',
        'certCore_Intermit_Freq': '100',
        'certCore_Intermit_Freq_Unit': 'Year',
        'certFuture_Intermit_Freq_Unit_NBR': '1',
        'certCore_Intermit_Dur': '8',
        'certCore_Intermit_Dur_Unit': 'Hours',
        'certFrequency_AMN_Flag': 'F',
        'certDuration_AMN_Flag': 'F',
        'certAbsenceType': 'Flare Up',
        'certPeriodTermDate': '7/1/2020',
        'certStatusId': '1'
      },
      {
        'certId': 805972,
        'certStatus': 'Not Certified',
        'certPeriodStartDate': '5/26/2020',
        'certPeriodEndDate': '7/1/2020',
        'certCore_Intermit_Freq': '1',
        'certCore_Intermit_Freq_Unit': 'Year',
        'certFuture_Intermit_Freq_Unit_NBR': '1',
        'certCore_Intermit_Dur': '1',
        'certCore_Intermit_Dur_Unit': 'Hour',
        'certFrequency_AMN_Flag': 'F',
        'certDuration_AMN_Flag': 'F',
        'certAbsenceType': 'Flare Up',
        'certPeriodTermDate': '7/1/2020',
        'certStatusId': '5'
      }*/
      {
        certId: 795322,
        certStatus: 'Accepted',
        certPeriodStartDate: '8/5/2019',
        certPeriodEndDate: '8/5/2020',
        certCore_Intermit_Freq: '1',
        certCore_Intermit_Freq_Unit: 'Week',
        certFuture_Intermit_Freq_Unit_NBR: '5',
        certCore_Intermit_Dur: '',
        certCore_Intermit_Dur_Unit: '',
        certFrequency_AMN_Flag: 'F',
        certDuration_AMN_Flag: 'F',
        certAbsenceType: '',
        certPeriodTermDate: '1/1/0001',
        certStatusId: '1',
        certCreationDate: '5/11/2020 6:08:50 AM'
},
      {
        certId: 795325,
        certStatus: 'Accepted',
        certPeriodStartDate: '7/8/2019',
        certPeriodEndDate: '7/8/2020',
        certCore_Intermit_Freq: '1',
        certCore_Intermit_Freq_Unit: 'Month',
        certFuture_Intermit_Freq_Unit_NBR: '5',
        certCore_Intermit_Dur: '',
        certCore_Intermit_Dur_Unit: '',
        certFrequency_AMN_Flag: 'F',
        certDuration_AMN_Flag: 'F',
        certAbsenceType: 'Flare Up',
        certPeriodTermDate: '1/8/2020',
        certStatusId: '1',
        certCreationDate: '5/11/2020 6:30:06 AM'
      },
      {
        certId: 795323,
        certStatus: 'Denied',
        certPeriodStartDate: '7/1/2019',
        certPeriodEndDate: '7/1/2020',
        certCore_Intermit_Freq: '1',
        certCore_Intermit_Freq_Unit: 'Week',
        certFuture_Intermit_Freq_Unit_NBR: '5',
        certCore_Intermit_Dur: '',
        certCore_Intermit_Dur_Unit: 'Week',
        certFrequency_AMN_Flag: 'F',
        certDuration_AMN_Flag: 'F',
        certAbsenceType: 'Flare Up',
        certPeriodTermDate: '2/4/2020',
        certStatusId: '1',
        certCreationDate: '5/11/2020 6:24:48 AM'
      },
      {
        certId: 795328,
        certStatus: 'Denied',
        certPeriodStartDate: '6/10/2019',
        certPeriodEndDate: '7/25/2020',
        certCore_Intermit_Freq: '1',
        certCore_Intermit_Freq_Unit: 'Week',
        certFuture_Intermit_Freq_Unit_NBR: '5',
        certCore_Intermit_Dur: '',
        certCore_Intermit_Dur_Unit: '',
        certFrequency_AMN_Flag: 'F',
        certDuration_AMN_Flag: 'F',
        certAbsenceType: '',
        certPeriodTermDate: '3/11/2020',
        certStatusId: '1',
        certCreationDate: '5/11/2020 6:36:07 AM'
      },
      {
        certId: 795326,
        certStatus: 'Accepted',
        certPeriodStartDate: '6/1/2019',
        certPeriodEndDate: '6/1/2020',
        certCore_Intermit_Freq: '',
        certCore_Intermit_Freq_Unit: '',
        certFuture_Intermit_Freq_Unit_NBR: '0',
        certCore_Intermit_Dur: '',
        certCore_Intermit_Dur_Unit: '',
        certFrequency_AMN_Flag: 'F',
        certDuration_AMN_Flag: 'F',
        certAbsenceType: '',
        certPeriodTermDate: '4/15/2020',
        certStatusId: '1',
        certCreationDate: '5/12/2020 6:32:48 AM'
      },
      {
        certId: 795321,
        certStatus: 'Accepted',
        certPeriodStartDate: '1/8/2019',
        certPeriodEndDate: '1/8/2020',
        certCore_Intermit_Freq: '1',
        certCore_Intermit_Freq_Unit: 'Hour',
        certFuture_Intermit_Freq_Unit_NBR: '5',
        certCore_Intermit_Dur: '',
        certCore_Intermit_Dur_Unit: '',
        certFrequency_AMN_Flag: 'F',
        certDuration_AMN_Flag: 'F',
        certAbsenceType: '',
        certPeriodTermDate: '5/14/2019',
        certStatusId: '1',
        certCreationDate: '5/11/2020 6:03:27 AM'
      }
     ],
    certRequestList: [
    {
      datesOfAbsence: '10/22/2016<BR>10:00 AM - 6:00 PM',
      regulationShortName: 'Federal Family and Medical Leave Act (FMLA)',
      status: 'Pend',
      AbsenceType: ' '
    },
    {
      datesOfAbsence: '10/22/2016<BR>10:00 AM - 6:00 PM',
      regulationShortName: 'California Pregnancy Disability Leave (CAPDL)',
      status: 'Pend',
      AbsenceType: ' '
    },
    {
      datesOfAbsence: '9/4/2017<BR>9:00 AM - 5:00 PM',
      regulationShortName: 'Federal Family and Medical Leave Act (FMLA)',
      status: 'Denied',
      AbsenceType: 'Appointment'
    },
    {
      datesOfAbsence: '9/4/2017<BR>9:00 AM - 5:00 PM',
      regulationShortName: 'California Pregnancy Disability Leave (CAPDL)',
      status: 'Pend',
      AbsenceType: 'Appointment'
    },
    {
      datesOfAbsence: '9/6/2017<BR>9:00 AM - 12:00 PM',
      regulationShortName: 'Federal Family and Medical Leave Act (FMLA)',
      status: 'Pend',
      AbsenceType: 'Appointment'
    },
    {
      datesOfAbsence: '9/6/2017<BR>9:00 AM - 12:00 PM',
      regulationShortName: 'California Family Rights Act (CFRA)',
      status: 'Pend',
      AbsenceType: 'Appointment'
    },
    {
      datesOfAbsence: '9/6/2017<BR>1:00 PM - 5:00 PM',
      regulationShortName: 'Federal Family and Medical Leave Act (FMLA)',
      status: 'Pend',
      AbsenceType: 'Flare Up'
    },
    {
      datesOfAbsence: '9/6/2017<BR>1:00 PM - 5:00 PM',
      regulationShortName: 'California Family Rights Act (CFRA)',
      status: 'Pend',
      AbsenceType: 'Flare Up'
    },
    {
      datesOfAbsence: '9/7/2017<BR>9:00 AM - 5:00 PM',
      regulationShortName: 'Federal Family and Medical Leave Act (FMLA)',
      status: 'Pend',
      AbsenceType: 'Flare Up'
    },
    {
      datesOfAbsence: '9/7/2017<BR>9:00 AM - 5:00 PM',
      regulationShortName: 'California Pregnancy Disability Leave (CAPDL)',
      status: 'Pend',
      AbsenceType: 'Flare Up'
    },
    {
      datesOfAbsence: '9/11/2017<BR>9:00 AM - 5:00 PM',
      regulationShortName: 'Federal Family and Medical Leave Act (FMLA)',
      status: 'Approved',
      AbsenceType: ' '
    },
    {
      datesOfAbsence: '9/11/2017<BR>9:00 AM - 5:00 PM',
      regulationShortName: 'Federal Family and Medical Leave Act (FMLA)',
      status: 'Pend',
      AbsenceType: ' '
    },
    {
      datesOfAbsence: '9/11/2017<BR>9:00 AM - 5:00 PM',
      regulationShortName: 'California Family Rights Act (CFRA)',
      status: 'Pend',
      AbsenceType: ' '
    },
    {
      datesOfAbsence: '9/12/2017<BR>9:00 AM - 5:00 PM',
      regulationShortName: 'CA Technologies Organ Donation Leave',
      status: 'Pend',
      AbsenceType: 'Flare Up'
    },
    {
      datesOfAbsence: '9/14/2017<BR>9:00 AM - 12:00 PM',
      regulationShortName: 'Federal Family and Medical Leave Act (FMLA)',
      status: 'Pend',
      AbsenceType: 'Appointment'
    },
    {
      datesOfAbsence: '9/14/2017<BR>9:00 AM - 12:00 PM',
      regulationShortName: 'California Pregnancy Disability Leave (CAPDL)',
      status: 'Pend',
      AbsenceType: 'Appointment'
    },
    {
      datesOfAbsence: '9/14/2017<BR>1:00 PM - 5:00 PM',
      regulationShortName: 'Federal Family and Medical Leave Act (FMLA)',
      status: 'Pend',
      AbsenceType: 'Flare Up'
    },
    {
      datesOfAbsence: '9/14/2017<BR>1:00 PM - 5:00 PM',
      regulationShortName: 'California Pregnancy Disability Leave (CAPDL)',
      status: 'Pend',
      AbsenceType: 'Flare Up'
    },
    {
      datesOfAbsence: '9/15/2017<BR>9:00 AM - 12:00 PM',
      regulationShortName: 'Federal Family and Medical Leave Act (FMLA)',
      status: 'Pend',
      AbsenceType: 'Appointment'
    },
    {
      datesOfAbsence: '9/15/2017<BR>9:00 AM - 12:00 PM',
      regulationShortName: 'California Pregnancy Disability Leave (CAPDL)',
      status: 'Pend',
      AbsenceType: 'Appointment'
    },
    {
      datesOfAbsence: '9/15/2017<BR>1:00 PM - 5:00 PM',
      regulationShortName: 'Federal Family and Medical Leave Act (FMLA)',
      status: 'Pend',
      AbsenceType: 'Flare Up'
    },
    {
      datesOfAbsence: '9/15/2017<BR>1:00 PM - 5:00 PM',
      regulationShortName: 'California Pregnancy Disability Leave (CAPDL)',
      status: 'Pend',
      AbsenceType: 'Flare Up'
    },
    {
      datesOfAbsence: '9/18/2017<BR>9:00 AM - 5:00 PM',
      regulationShortName: 'Federal Family and Medical Leave Act (FMLA)',
      status: 'Approved',
      AbsenceType: 'Flare Up'
    },
    {
      datesOfAbsence: '9/18/2017<BR>9:00 AM - 5:00 PM',
      regulationShortName: 'California Family Rights Act (CFRA)',
      status: 'Approved',
      AbsenceType: 'Flare Up'
    },
    {
      datesOfAbsence: '9/19/2017<BR>9:00 AM - 5:00 PM',
      regulationShortName: 'Federal Family and Medical Leave Act (FMLA)',
      status: 'Pend',
      AbsenceType: 'Appointment'
    },
    {
      datesOfAbsence: '9/19/2017<BR>9:00 AM - 5:00 PM',
      regulationShortName: 'California Family Rights Act (CFRA)',
      status: 'Pend',
      AbsenceType: 'Appointment'
    },
    {
      datesOfAbsence: '9/27/2017<BR>9:00 AM - 12:00 PM',
      regulationShortName: 'CA Technologies Organ Donation Leave',
      status: 'Pend',
      AbsenceType: 'Appointment'
    },
    {
      datesOfAbsence: '9/27/2017<BR>1:00 PM - 5:00 PM',
      regulationShortName: 'CA Technologies Organ Donation Leave',
      status: 'Pend',
      AbsenceType: 'Flare Up'
    },
    {
      datesOfAbsence: '10/2/2017<BR>9:00 AM - 12:00 PM',
      regulationShortName: 'CA Technologies Organ Donation Leave',
      status: 'Pend',
      AbsenceType: 'Appointment'
    },
    {
      datesOfAbsence: '10/2/2017<BR>1:00 PM - 5:00 PM',
      regulationShortName: 'CA Technologies Organ Donation Leave',
      status: 'Pend',
      AbsenceType: 'Flare Up'
    },
    {
      datesOfAbsence: '10/4/2017<BR>9:00 AM - 12:00 PM',
      regulationShortName: 'CA Technologies Organ Donation Leave',
      status: 'Approved',
      AbsenceType: 'Appointment'
    },
    {
      datesOfAbsence: '10/4/2017<BR>1:00 PM - 5:00 PM',
      regulationShortName: 'CA Technologies Organ Donation Leave',
      status: 'Approved',
      AbsenceType: 'Flare Up'
    },
    {
      datesOfAbsence: '10/9/2017<BR>9:00 AM - 12:00 PM',
      regulationShortName: 'Federal Family and Medical Leave Act (FMLA)',
      status: 'Pend',
      AbsenceType: 'Flare Up'
    },
    {
      datesOfAbsence: '10/9/2017<BR>9:00 AM - 12:00 PM',
      regulationShortName: 'California Pregnancy Disability Leave (CAPDL)',
      status: 'Pend',
      AbsenceType: 'Flare Up'
    },
    {
      datesOfAbsence: '10/9/2017<BR>1:00 PM - 5:00 PM',
      regulationShortName: 'Federal Family and Medical Leave Act (FMLA)',
      status: 'Pend',
      AbsenceType: 'Appointment'
    },
    {
      datesOfAbsence: '10/9/2017<BR>1:00 PM - 5:00 PM',
      regulationShortName: 'California Pregnancy Disability Leave (CAPDL)',
      status: 'Pend',
      AbsenceType: 'Appointment'
    },
    {
      datesOfAbsence: '10/11/2017<BR>9:00 AM - 5:00 PM',
      regulationShortName: 'Federal Family and Medical Leave Act (FMLA)',
      status: 'Pend',
      AbsenceType: 'Appointment'
    },
    {
      datesOfAbsence: '10/11/2017<BR>9:00 AM - 5:00 PM',
      regulationShortName: 'California Pregnancy Disability Leave (CAPDL)',
      status: 'Pend',
      AbsenceType: 'Appointment'
    },
    {
      datesOfAbsence: '9/18/2018<BR>9:00 AM - 5:00 PM',
      regulationShortName: 'Federal Family and Medical Leave Act (FMLA)',
      status: 'Pend',
      AbsenceType: ' '
    },
    {
      datesOfAbsence: '9/18/2018<BR>9:00 AM - 5:00 PM',
      regulationShortName: 'California Pregnancy Disability Leave (CAPDL)',
      status: 'Pend',
      AbsenceType: ' '
    },
    {
      datesOfAbsence: '9/19/2018<BR>9:00 AM - 5:00 PM',
      regulationShortName: 'Federal Family and Medical Leave Act (FMLA)',
      status: 'Pend',
      AbsenceType: ' '
    },
    {
      datesOfAbsence: '9/19/2018<BR>9:00 AM - 5:00 PM',
      regulationShortName: 'California Pregnancy Disability Leave (CAPDL)',
      status: 'Pend',
      AbsenceType: ' '
    },
    {
      datesOfAbsence: '9/20/2018<BR>9:00 AM - 5:00 PM',
      regulationShortName: 'Federal Family and Medical Leave Act (FMLA)',
      status: 'Pend',
      AbsenceType: ' '
    },
    {
      datesOfAbsence: '9/20/2018<BR>9:00 AM - 5:00 PM',
      regulationShortName: 'California Pregnancy Disability Leave (CAPDL)',
      status: 'Pend',
      AbsenceType: ' '
    },
    {
      datesOfAbsence: '9/21/2018<BR>9:00 AM - 5:00 PM',
      regulationShortName: 'Federal Family and Medical Leave Act (FMLA)',
      status: 'Pend',
      AbsenceType: ' '
    },
    {
      datesOfAbsence: '9/21/2018<BR>9:00 AM - 5:00 PM',
      regulationShortName: 'California Pregnancy Disability Leave (CAPDL)',
      status: 'Pend',
      AbsenceType: ' '
    },
    {
      datesOfAbsence: '10/22/2018<BR>9:00 AM - 5:00 PM',
      regulationShortName: 'Federal Family and Medical Leave Act (FMLA)',
      status: 'Pend',
      AbsenceType: ' '
    },
    {
      datesOfAbsence: '10/22/2018<BR>9:00 AM - 5:00 PM',
      regulationShortName: 'California Pregnancy Disability Leave (CAPDL)',
      status: 'Pend',
      AbsenceType: ' '
    },
    {
      datesOfAbsence: '10/23/2018<BR>9:00 AM - 5:00 PM',
      regulationShortName: 'Federal Family and Medical Leave Act (FMLA)',
      status: 'Pend',
      AbsenceType: ' '
    },
    {
      datesOfAbsence: '10/23/2018<BR>9:00 AM - 5:00 PM',
      regulationShortName: 'California Pregnancy Disability Leave (CAPDL)',
      status: 'Pend',
      AbsenceType: ' '
    },
    {
      datesOfAbsence: '10/24/2018<BR>9:00 AM - 5:00 PM',
      regulationShortName: 'Federal Family and Medical Leave Act (FMLA)',
      status: 'Pend',
      AbsenceType: ' '
    },
    {
      datesOfAbsence: '10/24/2018<BR>9:00 AM - 5:00 PM',
      regulationShortName: 'California Pregnancy Disability Leave (CAPDL)',
      status: 'Pend',
      AbsenceType: ' '
    },
    {
      datesOfAbsence: '10/25/2018<BR>9:00 AM - 5:00 PM',
      regulationShortName: 'Federal Family and Medical Leave Act (FMLA)',
      status: 'Pend',
      AbsenceType: ' '
    },
    {
      datesOfAbsence: '10/25/2018<BR>9:00 AM - 5:00 PM',
      regulationShortName: 'California Pregnancy Disability Leave (CAPDL)',
      status: 'Pend',
      AbsenceType: ' '
    },
    {
      datesOfAbsence: '10/31/2018<BR>9:00 AM - 5:00 PM',
      regulationShortName: 'Federal Family and Medical Leave Act (FMLA)',
      status: 'Pend',
      AbsenceType: 'Appointment'
    },
    {
      datesOfAbsence: '10/31/2018<BR>9:00 AM - 5:00 PM',
      regulationShortName: 'California Pregnancy Disability Leave (CAPDL)',
      status: 'Pend',
      AbsenceType: 'Appointment'
    },
    {
      datesOfAbsence: '11/1/2018<BR>9:00 AM - 5:00 PM',
      regulationShortName: 'Federal Family and Medical Leave Act (FMLA)',
      status: 'Pend',
      AbsenceType: 'Appointment'
    },
    {
      datesOfAbsence: '11/1/2018<BR>9:00 AM - 5:00 PM',
      regulationShortName: 'California Pregnancy Disability Leave (CAPDL)',
      status: 'Pend',
      AbsenceType: 'Appointment'
    },
    {
      datesOfAbsence: '11/6/2018<BR>9:00 AM - 5:00 PM',
      regulationShortName: 'Federal Family and Medical Leave Act (FMLA)',
      status: 'Pend',
      AbsenceType: 'Appointment'
    },
    {
      datesOfAbsence: '11/6/2018<BR>9:00 AM - 5:00 PM',
      regulationShortName: 'California Pregnancy Disability Leave (CAPDL)',
      status: 'Pend',
      AbsenceType: 'Appointment'
    },
    {
      datesOfAbsence: '11/7/2018<BR>9:00 AM - 5:00 PM',
      regulationShortName: 'Federal Family and Medical Leave Act (FMLA)',
      status: 'Pend',
      AbsenceType: 'Appointment'
    },
    {
      datesOfAbsence: '11/7/2018<BR>9:00 AM - 5:00 PM',
      regulationShortName: 'California Pregnancy Disability Leave (CAPDL)',
      status: 'Pend',
      AbsenceType: 'Appointment'
    },
    {
      datesOfAbsence: '11/8/2018<BR>9:00 AM - 5:00 PM',
      regulationShortName: 'Federal Family and Medical Leave Act (FMLA)',
      status: 'Pend',
      AbsenceType: 'Appointment'
    },
    {
      datesOfAbsence: '11/8/2018<BR>9:00 AM - 5:00 PM',
      regulationShortName: 'California Pregnancy Disability Leave (CAPDL)',
      status: 'Pend',
      AbsenceType: 'Appointment'
    },
    {
      datesOfAbsence: '11/9/2018<BR>9:00 AM - 5:00 PM',
      regulationShortName: 'Federal Family and Medical Leave Act (FMLA)',
      status: 'Pend',
      AbsenceType: 'Appointment'
    },
    {
      datesOfAbsence: '11/9/2018<BR>9:00 AM - 5:00 PM',
      regulationShortName: 'California Pregnancy Disability Leave (CAPDL)',
      status: 'Pend',
      AbsenceType: 'Appointment'
    }
  ]
}
};
