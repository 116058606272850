export const MOCK_WORKSCHEDULE_EMPLOYEE = {

  empWorkSchedulesList:
  [
    {
      workScheduleId: 452634,
      workScheduleEffectiveDate: '10/01/2020',
      thaaWorkSchduleType: 'Multi Week',
      daysInRotation: 1,
      employeeWorkSchedules: [
        {
          weekNumber: 1,
          weekDetails: [
            {
              workMinutes: 0,
              dayNumber: 1,
              weekNumber: 1
            },
            {
              workMinutes: 330,
              dayNumber: 2,
              weekNumber: 1
            },
            {
              workMinutes: 62,
              dayNumber: 3,
              weekNumber: 1
            },
            {
              workMinutes: 1,
              dayNumber: 4,
              weekNumber: 1
            },
            {
              workMinutes: 480,
              dayNumber: 5,
              weekNumber: 1
            },
            {
              workMinutes: 300,
              dayNumber: 6,
              weekNumber: 1
            },
            {
              workMinutes: 0,
              dayNumber: 7,
              weekNumber: 1
            }
          ]
        },
        {
          weekNumber: 2,
          weekDetails: [
            {
              workMinutes: 0,
              dayNumber: 1,
              weekNumber: 2
            },
            {
              workMinutes: 330,
              dayNumber: 2,
              weekNumber: 2
            },
            {
              workMinutes: 62,
              dayNumber: 3,
              weekNumber: 2
            },
            {
              workMinutes: 1,
              dayNumber: 4,
              weekNumber: 2
            },
            {
              workMinutes: 480,
              dayNumber: 5,
              weekNumber: 2
            },
            {
              workMinutes: 300,
              dayNumber: 6,
              weekNumber: 2
            },
            {
              workMinutes: 0,
              dayNumber: 7,
              weekNumber: 2
            }
          ]
        }
      ]
    }
  ],
    responseCode: '001',
  responseReason: 'success'
};

export const MOCK_WORKSCHEDULE_EMPLOYEE_WEEKLY = {

  empWorkSchedulesList:
    [
      {
        workScheduleId: 452634,
        workScheduleEffectiveDate: '10/01/2020',
        thaaWorkSchduleType: 'Weekly',
        daysInRotation: 1,
        employeeWorkSchedules: [
          {
            weekNumber: 1,
            weekDetails: [
              {
                workMinutes: 0,
                dayNumber: 1,
                weekNumber: 1
              },
              {
                workMinutes: 120,
                dayNumber: 2,
                weekNumber: 1
              },
              {
                workMinutes: 120,
                dayNumber: 3,
                weekNumber: 1
              },
              {
                workMinutes: 120,
                dayNumber: 4,
                weekNumber: 1
              },
              {
                workMinutes: 120,
                dayNumber: 5,
                weekNumber: 1
              },
              {
                workMinutes: 0,
                dayNumber: 6,
                weekNumber: 1
              },
              {
                workMinutes: 0,
                dayNumber: 7,
                weekNumber: 1
              }
            ]
          }
        ]
      }
    ],
  responseCode: '001',
  responseReason: 'success'
};

export const MOCK_WORKSCHEDULE_EMPLOYEE_ROTATING = {
  'empWorkSchedulesList': [
      {
        'workScheduleId': 890140,
        'workScheduleEffectiveDate': '12/31/2015',
        'thaaWorkSchduleType': 'Rotating',
        'daysInRotation': 8,
        'employeeWorkSchedules': [
          {
            'weekNumber': -1,
            'weekDetails': [
              {
                'workMinutes': 480,
                'dayNumber': 1,
                'weekNumber': -1
              },
              {
                'workMinutes': 0,
                'dayNumber': 2,
                'weekNumber': -1
              },
              {
                'workMinutes': 120,
                'dayNumber': 3,
                'weekNumber': -1
              },
              {
                'workMinutes': 0,
                'dayNumber': 4,
                'weekNumber': -1
              },
              {
                'workMinutes': 0,
                'dayNumber': 5,
                'weekNumber': -1
              },
              {
                'workMinutes': 0,
                'dayNumber': 6,
                'weekNumber': -1
              },
              {
                'workMinutes': 0,
                'dayNumber': 7,
                'weekNumber': -1
              },
              {
                'workMinutes': 0,
                'dayNumber': 8,
                'weekNumber': -1
              }
            ]
          }
        ]
      }
    ],
    'responseCode': '001',
    'responseReason': 'success'
}

export const MOCK_WORK_SCHEDULE_ADD_EXTENSION = {
    "personId": "7661533",
    "parentClaimID": "2139332",
    "userInputs": [
        {
            "RegFieldId": 83208,
            "RegFieldValue": "274004"
        },
        {
            "RegFieldId": 4,
            "RegFieldValue": "9001"
        },
        {
            "RegFieldId": 996,
            "RegFieldValue": "1001"
        },
        {
            "RegFieldId": 110,
            "RegFieldValue": "2139332"
        },
        {
            "RegFieldId": 44,
            "RegFieldValue": "1001"
        },
        {
            "RegFieldId": 8,
            "RegFieldValue": "4"
        },
        {
            "RegFieldId": 10,
            "RegFieldValue": "17001"
        },
        {
            "RegFieldId": 43,
            "RegFieldValue": "01/10/2024"
        },
        {
            "RegFieldId": 49,
            "RegFieldValue": "09/14/2023"
        },
        {
            "RegFieldId": 175,
            "RegFieldValue": "125001"
        },
        {
            "RegFieldId": 130,
            "RegFieldValue": "{\"THHAEmployeeWorkSchedule\":{\"EmployeeID\":\"\",\"EffectiveDate\":\"09/07/2023\",\"DaysInRotation\":0,\"DaysON\":0,\"DaysOff\":0,\"THAAWorkSchduelType\":\"Weekly\",\"THAAWorkScheduleDetails\":[{\"day_num\":1,\"workMinutes\":62},{\"day_num\":2,\"workMinutes\":121},{\"day_num\":3,\"workMinutes\":122},{\"day_num\":4,\"workMinutes\":61},{\"day_num\":5,\"workMinutes\":122},{\"day_num\":6,\"workMinutes\":122},{\"day_num\":7,\"workMinutes\":122}]},\"responseCode\":null,\"responseReason\":null}"
        },
        {
            "RegFieldId": 45,
            "RegFieldValue": "01/17/2024"
        },
        {
            "RegFieldId": 129,
            "RegFieldValue": "01/10/2024|false|03$03*04$06|01:03|PM"
        },
        {
            "RegFieldId": 605274,
            "RegFieldValue": "10022016"
        }
    ],
    'responseCode': '001'
}

export const EMPLOYER_SCHEDULE ={
  'EmployeeID': '1234',
  'EffectiveDate': '12/09/2023',
  'DaysInRotation': 4,
  'DaysON': 3,
  'DaysOff': 4,
  'THAAWorkSchduelType': 'Rotation',
  'THAAWorkScheduleDetails': [{
    'day_num': 3,
   'workMinutes': 2,
    'WeekNumber': 4}]
}
