import {Component, Input, OnInit} from '@angular/core';
import {StorageManagementService} from '../../../shared/services/storage-management.service';
import {SelfLinkingService} from '../../../shared/services/self-linking.service';
import {ClaimLeaveDetails} from '../../../shared/models/LeaveMappings.model';
import {AppLabelConstants} from '../../../shared/constants/appLabel.constants';
import {SessionConstants} from '../../../shared/constants/session.constants';

@Component({
  selector: 'app-leave-request',
  templateUrl: './leave-request.component.html',
  styleUrls: ['./leave-request.component.scss']
})
export class LeaveRequestComponent implements OnInit {

  @Input() public claimEventId: string;
  public linkToSelfELG: string = AppLabelConstants.URL_CONFIRM_ELG;
  public isELGWeak: boolean = false;
  public isLeaveRelatedToClaim: boolean =  false;
  public reedURL: string;
  public claimLeaveInfo: ClaimLeaveDetails;
  public applabelConstants = AppLabelConstants;
  constructor(private storageManagementService: StorageManagementService,
              private selfLinkingService: SelfLinkingService) { }

  ngOnInit() {
    this.isELGWeak = this.storageManagementService.getSessionData(SessionConstants.IS_EL_WEAK);
    this.storageManagementService.setSessionData(SessionConstants.CLAIM_EVENT_ID, this.claimEventId);
    this.subscribeAndSetupClaimLeaveInfo();
  }

  public subscribeAndSetupClaimLeaveInfo() {
    this.selfLinkingService.isAppLeaveDataSetInSession.subscribe(
      (isAppLeaveDataSetInSession: boolean) => {
        if (isAppLeaveDataSetInSession) {
          this.claimLeaveInfo = this.selfLinkingService.getSpecificClaimLeaveInfo(this.claimEventId);
          this.isLeaveRelatedToClaim = !!(this.claimLeaveInfo && this.claimLeaveInfo.leaveId && this.claimLeaveInfo.url);
          this.reedURL = this.isLeaveRelatedToClaim ? this.claimLeaveInfo.url : null;
          if (this.reedURL) {
            this.storageManagementService.setLeaveSSOURL(this.reedURL, this.claimEventId);
          }
        }
      }
    );
  }

  public navToReed() {
    if (this.storageManagementService.getAppData(SessionConstants.LEAVE_SSO_URL)) {
      let leaveList = this.storageManagementService.getAppData(SessionConstants.LEAVE_SSO_URL);
      leaveList = leaveList ? leaveList.filter((res) => (res.claimEventId === this.claimEventId)) : [];
      if (leaveList.length > 0) {
        window.open(leaveList[0].url);
      }
    }

  }
}
