
import {catchError, concatMap, filter, map, repeat, retryWhen, take, tap} from 'rxjs/operators';
import {Injectable} from '@angular/core';


import {env} from '../../../environments/environment-loader';


import {ResponseErrorHandlerService} from './response-error-handler.service';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {AppLabelConstants} from '../constants/appLabel.constants';
import { StorageManagementService } from './storage-management.service';
import { Observable, of } from 'rxjs';
import { IEmployeeStatus } from '../models/employeeStatus.model';
import { SessionConstants } from '../constants/session.constants';

@Injectable()
export class EmployeeStatusService {
  isErrorAgain: boolean = false;
  constructor(private errorHandler: ResponseErrorHandlerService,
              private httpClient: HttpClient,
              private storeManagementService: StorageManagementService) {}
  public getEmployeeActiveStatus(activePersonIds: string[]): Observable<IEmployeeStatus>{
    const employeeStatusList = this.storeManagementService.getSessionData(SessionConstants.Employee_Status);
    if (employeeStatusList) {
      return of(employeeStatusList);
    } else {
    let personIds = '';
    if (activePersonIds && activePersonIds.length > 1) {
      activePersonIds.forEach((personId: string) => {
        personIds = personIds + '&personId=' + personId;
      });
    }
    return this.httpClient.get(env.thaaWKABPortalService.employeeActiveStatusCheck + personIds ,
      {
        headers: new HttpHeaders()
          .set(AppLabelConstants.CONTENT_TYPE_KEY, AppLabelConstants.APPLICATION_KEY)
      }).pipe(
      repeat(),
      filter((response: IEmployeeStatus) => this.checkCondition(response)),
      take(1),
      map(
      (response: IEmployeeStatus) => {
        if (!this.errorHandler.isServiceResponseHasError(response)) {
          this.storeManagementService.setSessionData( SessionConstants.Employee_Status, response);
          return response;
        } else {
          throw response;
        }
      }
    ),
    retryWhen(errors => errors.pipe(
      tap(error => {
        if(error && error?.status !== '001' && !this.isErrorAgain) {
          this.isErrorAgain = true;
        } else {
          this.isErrorAgain = true;
          throw error
        }
      })
    )),
    catchError((error: any) => this.errorHandler.handleError(error)), );
    }
  }

  checkCondition(response: IEmployeeStatus): boolean {
    if(response && response.responseCode !== '001' && !this.isErrorAgain) {
      this.isErrorAgain = true;
      return false
    } else {
      this.isErrorAgain = true;
      return true
    }
  }
}
