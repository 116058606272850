/* This file will contain the overall project routing
 * CanActivate/CanLoad/etc types of functions will also come from here
 */
/* Major Flow routes
 * Focus on routing to the larger individual flows
 * Let individual flows handle their inner sub routing*/
import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import { IntermediatePageLoaderComponent } from "@app/cxr/common/organisms/intermediate-page-loader/intermediate-page-loader.component";
import { AbsenceModule } from "gb-cxr-thaw-absence-ui";
import { AppInfoComponent } from "./cxr/app-info/app-info.component";
import { CancelPopupComponent } from "./cxr/cancel-popup/cancel-popup.component";
import { ContactsComponent } from "./cxr/common/organisms/contacts/contacts.component";
import { FaqComponent } from "./cxr/common/organisms/faq/faq.component";
import { FeatureDownComponent } from "./cxr/common/organisms/feature-down/feature-down.component";
import { CustomErrorComponent } from "./cxr/custom-error/custom-error.component";
import { ClaimantDocumentsComponent } from "./cxr/document-upload/document/document.component";
import { EmployeeBenefitsRegistrationComponent } from "./cxr/employee-benefits-registration/employee-benefits-registration/employee-benefits-registration.component";
import { ErrorPageComponent } from "./cxr/error-page/error-page.component";
import { ErrorComponent } from "./cxr/error/error.component";
import { FeedbackCiamComponent } from "./cxr/feedback-ciam/feedback-ciam.component";
import { HomeComponent } from "./cxr/home/home.component";
import { LoginErrorComponent } from "./cxr/login-error/login-error.component";
import { LoginComponent } from "./cxr/login/login.component";
import { IntakeResolveService } from "./cxr/modules/intake/shared/resolvers/intake-resolve.service";
import { DirectDepositTCComponent } from "./cxr/my-account/direct-deposit/enroll-deposit/direct-deposit-tc/direct-deposit-tc.component";
import { NotificationRegistrationComponent } from "./cxr/notification-registration/notification-registration.component";
import { PhaAccessPointComponent } from "./cxr/pha-access-point/pha-access-point.component";
import { PopupCommPrefComponent } from "./cxr/popup-comm-pref/popup-comm-pref.component";
import { ProductInfoComponent } from "./cxr/product-info/product-info.component";
import { ProfileErrorComponent } from "./cxr/profile-error/profile-error.component";
import { RequestExtensionThaaComponent } from "./cxr/request-extensions/request-extension-thaa/request-extension-thaa.component";
import { RequestExtensionComponent } from "./cxr/request-extensions/request-extension/request-extension.component";
import { SecurityStatementComponent } from "./cxr/security-statement/security-statement.component";
import { ConfirmELGComponent } from "./cxr/self-linking/dcs-eligibility/confirm-elg/confirm-elg.component";
import { SelfLinkingComponent } from "./cxr/self-linking/self-linking-flow/self-linking/self-linking.component";
import { SuccessfulRegistrationComponent } from "./cxr/self-linking/self-linking-flow/successful-registration/successful-registration.component";
import { SiteWideTCsComponent } from "./cxr/terms-and-conditions-alert/site-wide-tcs/site-wide-tcs.component";
import { TextTCsComponent } from "./cxr/terms-and-conditions-alert/text-tcs/text-tcs.component";
import { UpdateTCsAlertModalComponent } from "./cxr/terms-and-conditions-alert/update-tcs-alert-modal/update-tcs-alert-modal.component";
import { TermsCondPopupComponent } from "./cxr/terms-cond-popup/terms-cond-popup.component";
import { ThaaRedirectSaveModalComponent } from "./cxr/thaa-redirect-modal/thaa-redirect-save-modal.component";
import { BlazeComponent } from "./cxr/to-remove/blaze/blaze.component";
import { TransactionErrorComponent } from "./cxr/transaction-error/transaction-error.component";
import { MoreLettersPageComponent } from "./cxr/view-letters/More-letters/more-letters-page.component";
import { FEATURELISTS } from "./shared/models/Feature";
import { IntakeGuardService } from "./shared/services/intake-guard.service";
import { RaeGuardService } from "./shared/services/rae-guard.service";
import {
  CanActivateFeature,
  CanActivateLogin,
  CanActivateSession,
} from "./shared/utils/CanActivateUtils";

export const ROUTES: Routes = [
  {
    path: "psv",
    loadChildren: () => import("app/psv/psv.module").then((m) => m.PsvModule),
    canActivate: [CanActivateSession],
  },
  {
    path: "login",
    component: LoginComponent,
  },
  {
    path: "login-error",
    component: LoginErrorComponent,
  },
  {
    path: "home",
    component: HomeComponent,
    canActivate: [CanActivateSession, CanActivateLogin],
  },
  {
    path: "homeRemoveLater",
    component: HomeComponent,
    canActivate: [CanActivateSession, CanActivateLogin],
  },
  {
    path: "loading",
    component: IntermediatePageLoaderComponent,
  },
  {
    //Note: The reason this one has the sub nav item as part of the url is
    // because the parent url path would not have anything of value for the
    // child. This would be subdivided further if it contained anything that
    // was to be reused for all children links.
    path: "productInfo/:id",
    component: ProductInfoComponent,
  },
  {
    path: "faq",
    component: FaqComponent,
  },
  {
    path: "faq/:id",
    component: FaqComponent,
  },
  {
    path: "contactUs",
    component: ContactsComponent,
  },
  {
    path: "moreLetters",
    component: MoreLettersPageComponent,
  },
  {
    path: "confirmELG",
    component: ConfirmELGComponent,
    canActivate: [CanActivateSession],
  },
  {
    path: "EmployeeBNR",
    component: EmployeeBenefitsRegistrationComponent,
    canActivate: [CanActivateSession],
  },
  {
    path: "notificationRegistration",
    component: NotificationRegistrationComponent,
    canActivate: [CanActivateSession],
  },
  {
    path: "leaveFormLibrary",
    loadChildren: () =>
      import("@app/cxr/form-library/leave-form-library.module").then(
        (m) => m.LeaveFormLibraryModule
      ),
    canActivate: [CanActivateSession],
  },
  {
    path: "formLibrary",
    loadChildren: () =>
      import("@app/cxr/product-forms/product-forms.module").then((m) => m.ProductFormsModule),
    canActivate: [CanActivateSession],
  },
  {
    path: "myAccount",
    loadChildren: () =>
      import("app/cxr/my-account/my-account.module").then((m) => m.MyAccountModule),
    canActivate: [CanActivateSession],
  },
  {
    path: "allPayments",
    loadChildren: () =>
      import("app/cxr/all-payment/all-payment.module").then((m) => m.AllPaymentModule),
    canActivate: [CanActivateSession],
  },
  {
    path: "overPayment",
    loadChildren: () =>
      import("app/cxr/overpayment/overpayment.module").then((m) => m.OverpaymentModule),
    canActivate: [CanActivateSession],
  },
  {
    path: "claimDetails",
    loadChildren: () =>
      import("app/cxr/claim-details/claim-details.module").then((m) => m.ClaimDetailsModule),
    canActivate: [CanActivateSession],
  },
  {
    path: "taxForms",
    loadChildren: () => import("app/cxr/tax-form/tax-forms.module").then((m) => m.TaxFormsModule),
    canActivate: [CanActivateSession, CanActivateLogin],
  },
  {
    path: "delivery",
    loadChildren: () =>
      import("app/cxr/confirm-delivery/delivery.module").then((m) => m.DeliveryModule),
    canActivate: [CanActivateSession],
  },
  {
    path: "surgery",
    loadChildren: () =>
      import("app/cxr/confirm-surgery/surgery.module").then((m) => m.SurgeryModule),
    canActivate: [CanActivateSession],
  },
  {
    path: "disability",
    loadChildren: () =>
      import("app/cxr/confirm-disability/disability.module").then((m) => m.DisabilityModule),
    canActivate: [CanActivateSession],
  },
  {
    path: "termsAndCondtionEFT",
    component: DirectDepositTCComponent,
    canActivate: [CanActivateSession],
  },
  {
    path: "popupcommpref",
    component: PopupCommPrefComponent,
    canActivate: [CanActivateSession],
  },
  {
    path: "cancelPopup",
    component: CancelPopupComponent,
    canActivate: [CanActivateSession],
  },
  {
    path: "termsAndConditions",
    component: TermsCondPopupComponent,
    canActivate: [CanActivateSession],
  },
  {
    path: "siteWidetermsAndConditions",
    component: SiteWideTCsComponent,
    canActivate: [CanActivateSession],
  },
  { path: "phaAccessPoint", component: PhaAccessPointComponent, canActivate: [CanActivateSession] },
  { path: "textTcs", component: TextTCsComponent, canActivate: [CanActivateSession] },
  {
    path: "ClaimHistory",
    loadChildren: () =>
      import("app/cxr/benefits-history/benefits-history.module").then(
        (m) => m.BenefitsHistoryModule
      ),
    canActivate: [CanActivateSession],
  },
  {
    path: "appInfo",
    component: AppInfoComponent,
  },
  {
    path: "featureDownPage",
    component: FeatureDownComponent,
    canActivate: [CanActivateSession],
  },
  {
    path: "feedback-ciam",
    component: FeedbackCiamComponent,
    canActivate: [CanActivateSession],
  },
  {
    path: "claimDocuments",
    component: ClaimantDocumentsComponent,
    canActivate: [CanActivateFeature, CanActivateSession],
    data: {
      featureset: FEATURELISTS[3],
    },
  },
  {
    path: "updateT&CsAlert",
    component: UpdateTCsAlertModalComponent,
    canActivate: [CanActivateSession],
  },
  {
    path: "securitystmt",
    component: SecurityStatementComponent,
    canActivate: [CanActivateSession],
  },
  {
    path: "uploadDocumentWizard",
    loadChildren: () =>
      import("app/cxr/document-upload/shared-doc-upload.module").then(
        (m) => m.SharedDocumentUploadModule
      ),
    canActivate: [CanActivateSession, CanActivateFeature],
    data: {
      featureset: FEATURELISTS[3],
    },
  },
  {
    path: "successrequestextension",
    loadChildren: () =>
      import("app/cxr/success-request-extension/success-request-extension.module").then(
        (m) => m.SuccessRequestExtensionModule
      ),
    canActivate: [CanActivateSession],
  },
  {
    path: "success-request-extension",
    loadChildren: () =>
      import("@app/cxr/thaa-success-request-extension/success-request-extension-thaa.module").then(
        (m) => m.SuccessRequestExtensionThaaModule
      ),
    canActivate: [CanActivateSession],
  },
  {
    path: "requestExtension",
    component: RequestExtensionComponent,
    canActivate: [CanActivateSession],
  },
  {
    path: "request-extension",
    component: RequestExtensionThaaComponent,
    canActivate: [CanActivateSession],
  },
  {
    path: "ire",
    loadChildren: () =>
      import(
        "app/cxr/modules/interactive-request-extension/interactive-request-extension.module"
      ).then((m) => m.InteractiveRequestExtensionModule),
    canActivate: [CanActivateSession, CanActivateLogin, RaeGuardService],
  },
  {
    path: "intake",
    loadChildren: () => import("app/cxr/modules/intake/intake.module").then((m) => m.IntakeModule),
    canActivate: [CanActivateSession, CanActivateLogin, IntakeGuardService],
    resolve: { intakeResolve: IntakeResolveService },
  },
  {
    path: "selfLinking",
    component: SelfLinkingComponent,
    canActivate: [CanActivateSession],
  },
  {
    path: "selfLinkingFailed",
    component: SuccessfulRegistrationComponent,
    canActivate: [CanActivateSession],
  },
  {
    path: "schedule-appointment",
    loadChildren: () =>
      import("app/cxr/schedule-appointment/schedule-appointment.module").then(
        (m) => m.ScheduleAppointmentModule
      ),
    canActivate: [CanActivateSession],
  },

  {
    path: "phaoverview",
    loadChildren: () =>
      import("app/cxr/pha-overview-details/pha-overview.module").then((m) => m.PhaOverviewModule),
    canActivate: [CanActivateSession],
  },
  {
    path: "claim-wizard",
    loadChildren: () =>
      import("app/cxr/claim-wizard/claim-wizard.module").then((m) => m.ClaimWizardModule),
  },
  {
    path: "profile-error",
    component: ProfileErrorComponent,
    canActivate: [CanActivateSession],
  },
  {
    path: "blaze-test-remove-me",
    component: BlazeComponent,
    canActivate: [CanActivateSession],
  },
  {
    path: "",
    redirectTo: "/login",
    pathMatch: "full",
  },
  {
    path: "update-error",
    component: TransactionErrorComponent,
    canActivateChild: [CanActivateLogin],
    canActivate: [CanActivateSession],
  },
  {
    path: "call-us",
    component: ErrorPageComponent,
    canActivate: [CanActivateSession],
  },
  {
    path: "leave-details",
    loadChildren: () =>
      import("app/cxr/leave-details-flow/leave-details.module").then((m) => m.LeaveDetailsModule),
    canActivate: [CanActivateSession],
  },
  {
    path: "leave-detail",
    loadChildren: () =>
      import("app/cxr/modules/leave-details-old-calc/loa-details.module").then(
        (m) => m.LoaDetailsModule
      ),
    canActivate: [CanActivateSession],
  },
  {
    path: "leave-intake",
    loadChildren: () =>
      import("app/cxr/leave-claim-intake-flow/leave-claim-intake.module").then(
        (m) => m.LeaveClaimIntakeModule
      ),
    canActivate: [CanActivateSession],
  },
  {
    path: "thaa-modal",
    component: ThaaRedirectSaveModalComponent,
    canActivate: [CanActivateSession],
  },
  {
    path: "leave-plan",
    loadChildren: (): any =>
      import("app/cxr/absence-planning/absence-planning-wrapper.module").then(
        (m) => m.AbsencePlanningWrapperModule
      ),
    canActivate: [CanActivateSession],
  },
  {
    path: "custom-error",
    component: CustomErrorComponent,
    canActivate: [CanActivateSession],
  },
  {
    path: "**",
    component: ErrorComponent,
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(ROUTES, /*,{enableTracing: true}*/ { relativeLinkResolution: "legacy" }), // for debugging
    AbsenceModule,
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
