
import {Observable, Subject, of} from 'rxjs';
import {catchError, map} from 'rxjs/operators';
/**
 * Created by AC12396 on 12/13/2018.
 */
import {Injectable} from '@angular/core';
import {env} from '../../../environments/environment-loader';
import {ResponseErrorHandlerService} from './response-error-handler.service';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {AppLabelConstants} from '../../shared/constants/appLabel.constants';
import { StorageManagementService } from '@app/shared/services/storage-management.service';
import { SessionConstants } from '@app/shared/constants/session.constants';
@Injectable()
export class AetnaContactsService {
  isDisabilityContactCompleted = new Subject();

  constructor( private errorHandler: ResponseErrorHandlerService,
               private httpClient: HttpClient,
               private storeManagementService: StorageManagementService) { }

  getAetnaDisabilityClaimRelatedContact(personId: string): Observable<any> {
    const aetnaMobileNumber = this.storeManagementService.getSessionData(SessionConstants.AETNA_MOBILE_NUM);
    if (aetnaMobileNumber) {
      return of(aetnaMobileNumber);
    } else {
      return this.httpClient.get(env.thaaWKABPortalService.getAetnaContactURL + '&personid=' + personId,
      {
        headers: new HttpHeaders()
          .set(AppLabelConstants.CONTENT_TYPE_KEY, AppLabelConstants.APPLICATION_KEY)
      }).pipe(
      map(
        (response) => {
          if (!this.errorHandler.isAetnaContactServiceResponseHasError(response)) {
            this.storeManagementService.setSessionData(SessionConstants.AETNA_MOBILE_NUM,response);
            return response;
          } else {
            throw response;
          }
        }
      ),   catchError((error: any) => this.errorHandler.handleErrorThaa(error)), );
    }
  }
}
