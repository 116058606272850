import {CUSTOM_ELEMENTS_SCHEMA, NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {HeadingNavigationComponent} from '../cxr/common/molecules/heading-navigation/heading-navigation.component';
import {RouterModule} from '@angular/router';
import {InfoBarComponent} from '../cxr/common/atoms/labels/info-bar/info-bar.component';
import {MissingInfoComponent} from '../cxr/common/organisms/missing-info/missing-info.component';
import {SecondaryPageLabelComponent} from '../cxr/common/atoms/labels/secondary-page-label/secondary-page-label.component';
import {PrimaryButtonComponent} from '../cxr/common/atoms/buttons/primary-button/primary-button.component';
import {PrimaryLinkTransparentComponent} from '../cxr/common/atoms/links/primary-link-transparent/primary-link-transparent.component';
import {PrimaryLinkComponent} from '../cxr/common/atoms/links/primary-link/primary-link.component';
import {PrimaryDropdownComponent} from '../cxr/common/atoms/dropdowns/primary-dropdown/primary-dropdown.component';
import {TertiaryButtonComponent} from '../cxr/common/atoms/buttons/tertiary-button/tertiary-button.component';
import {SecondaryButtonComponent} from '../cxr/common/atoms/buttons/secondary-button/secondary-button.component';
import {PrimaryPageLabelComponent} from '../cxr/common/atoms/labels/primary-page-label/primary-page-label.component';
import {TertiaryPageLabelComponent} from '../cxr/common/atoms/labels/tertiary-page-label/tertiary-page-label.component';
import {MessageRibbonComponent} from '../cxr/common/organisms/message-banner/message-ribbon.component';
import {PrimaryInputComponent} from '../cxr/common/atoms/inputs/material-input/primary-input.component';
import {PrimaryCheckboxComponent} from '../cxr/common/atoms/checkbox/primary-checkbox.component';
import {PrimaryRadioComponent} from '../cxr/common/atoms/radio/primary-radio.component';
import {SecondaryLinkComponent} from '../cxr/common/atoms/links/secondary-link/secondary-link.component';
import {TertiaryLinkComponent} from '../cxr/common/atoms/links/tertiary-link/tertiary-link.component';
import {SecondaryDropdownComponent} from '../cxr/common/atoms/dropdowns/secondary-dropdown/secondary-dropdown.component';
import {PrimaryTextInputComponent} from '../cxr/common/atoms/inputs/primary-text-input/primary-text-input.component';
import {PrimaryFormTextInputComponent} from '../cxr/common/molecules/primary-form-text-input/primary-form-text-input.component';
import {ButtonComponent} from '../cxr/common/sub-atomic/button/button.component';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {HttpClientModule} from '@angular/common/http';

import {PrimaryFormDropdownComponent} from '../cxr/common/molecules/primary-form-dropdown/primary-form-dropdown.component';
import {AnchorButtonComponent} from '../cxr/common/atoms/buttons/anchor-button/anchor-button.component';
import {ImgLinkComponent} from '../cxr/common/atoms/links/img-link/img-link.component';
import {SlidingModalComponent} from '../cxr/common/molecules/modal/sliding-modal.component';
import {EditCancelPopupComponent} from '../cxr/common/organisms/edit-cancel-popup/edit-cancel-popup.component';
import {PopupCommPrefWithMobileComponent} from '../cxr/popup-comm-pref-with-mobile/popup-comm-pref-with-mobile.component';
import {CustomPageLabelComponent} from '../cxr/common/sub-atomic/custom-page-label/custom-page-label.component';
import {FormPageLabelComponent} from '../cxr/common/atoms/labels/form-page-label/form-page-label.component';
import {TermsCondPopupComponent} from '../cxr/terms-cond-popup/terms-cond-popup.component';
import {PopupCommPrefComponent} from '../cxr/popup-comm-pref/popup-comm-pref.component';
import {CancelPopupComponent} from '../cxr/cancel-popup/cancel-popup.component';
import {BoxLabelComponent} from '../cxr/common/atoms/labels/box-label/box-label.component';
import {ValidatePolicyNumber} from './directives/validatePolicyNumber.directive';
import {ValidateMinLength} from './directives/validateMinLength.directive';
import {ValidateTwoDigitsRequiredPattern} from './directives/validateTwoDigitsOnly.directive';
import {ValidateConsecutiveDigitsPattern} from './directives/validateConsecutiveDigits.directive';
import {ValidateSequentionalDigitsPattern} from './directives/validateSequentialDigits.directive';
import {MaskAccountNumberPipe} from './pipes/mask-account-number.pipe';
import {PrimaryLightButtonComponent} from '../cxr/common/atoms/buttons/primary-light-button/primary-light-button.component';
import {LinkDirective} from './directives/tealiumDataDl.directive';
import {BlockCopyPasteDirective} from './directives/block-copy-paste.directive';
import {HeadingLabelComponent} from '../cxr/common/molecules/heading-label/heading-label.component';
import {PrimaryFormCheckboxComponent} from '../cxr/common/molecules/primary-form-checkbox/primary-form-checkbox.component';
import {ClaimCardComponent} from '../cxr/common/organisms/claim-card/claim-card.component';
import {ClaimstatusheaderComponent} from '../cxr/claim-denied-display/claim-status-header/claimstatusheader.component';
import {DateLabelValueComponent} from '../cxr/common/organisms/datelabel-datevalue/date-label-value/date-label-value.component';
import {ClaimOverviewClaimDeniedComponent} from '../cxr/claim-denied-display/claim-overview-claim-denied/claim-overview-claim-denied.component';
import {ConfirmSurgeryModelComponent} from '../cxr/confirm-surgery/confirm-surgery-model/confirm-surgery-model.component';
import {ConfirmMaternityModelComponent} from '../cxr/confirm-delivery/confirm-maternity-model/confirm-maternity-model.component';
import {ConfirmDisabilityModelComponent} from '../cxr/confirm-disability/confirm-disability-model/confirm-disability-model.component';
import {ExtentionStatusComponent} from '../cxr/request-extensions/extention-status/extention-status.component';
import {LeaveRequestComponent} from '../cxr/leave-request/leave-request/leave-request.component';
import {NextPaymentBlockComponent} from '../cxr/common/organisms/next-payment-block/next-payment-block.component';
import {DecimalFormatPipe} from './pipes/decimal-format.pipe';
import { DecodeHtmlString } from './pipes/decodeHtml.pipe';
import {BubbleContainerComponent} from '../cxr/common/molecules/bubble-container/bubble-container.component';
import {EistimateUnderReviewComponent} from '../cxr/request-extensions/eistimate-under-review/eistimate-under-review.component';
import {ClaimDocumentDetailsComponent} from '../cxr/document-upload/claim-document-details/claim-document-details.component';
import {FileUploadButtonComponent} from '../cxr/common/molecules/file-upload/file-upload-button.component';
import {PageSpinnerComponent} from '../cxr/common/molecules/page-spinner/page-spinner.component';
import {LoginComponent} from '../cxr/login/login.component';
import {CommonQuestionsComponent} from '../cxr/common/organisms/common-questions/common-questions.component';
import {ProductListComponent} from '../cxr/product-list/product-list.component';
import {ContactUsComponent} from '../cxr/common/organisms/contact-us/contact-us.component';
import {SuccessorProductListComponent} from '../cxr/successor-product-list/successor-product-list.component';
import {ProductListPostLoginComponent} from '../cxr/product-list-post-login/product-list-post-login.component';
import {ProductListPreLoginComponent} from '../cxr/product-list-pre-login/product-list-pre-login.component';
import {VolProdContactComponent} from '../cxr/common/organisms/vol-prod-contact/vol-prod-contact.component';
import {ClaimRepresentativeInformationComponent} from '../cxr/common/organisms/claim-representative-info/claim-representative-information/claim-representative-information.component';
import {ClaimHandlerComponent} from '../cxr/common/organisms/claim-handler/claim-handler.component';
import {PrimaryContactLabelComponent} from '../cxr/common/atoms/labels/primary-contact-label/primary-contact-label.component';
import {NumberOnlyDirective} from './directives/number-only.directive';
import {TextMaskModule} from 'angular2-text-mask';
import {SecondaryFormTextInputComponent} from '../cxr/common/molecules/secondary-form-text-input/secondary-form-text-input.component';
import {SecondaryTextInputComponent} from '../cxr/common/atoms/inputs/secondary-text-input/secondary-text-input.component';
import {AlphaNumericOnlyDirective} from './directives/alpha-numeric-only.directive';
import {LeaveCardComponent} from '../cxr/leave-card/leave-card.component';
import {CardCarouselComponent} from '../cxr/common/organisms/card-carousel/card-carousel.component';

import {ScheduleCallLinkComponent} from '../cxr/common/organisms/schedule-call-link/schedule-call-link.component';
import {TimeSelectionButtonComponent} from '../cxr/common/atoms/buttons/time-selection-button/time-selection-button.component';
import {H1LabelComponent} from '../cxr/common/atoms/labels/h1/H1Label.component';
import {MyDatePicker, MyDatePickerModule} from 'mydatepicker';

import {ThaaRedirectSaveModalComponent} from '../cxr/thaa-redirect-modal/thaa-redirect-save-modal.component';
import {TertiaryDropdownComponent} from '../cxr/common/atoms/dropdowns/tertiary-dropdown/tertiary-dropdown.component';
import {SecondaryMessageRibbonComponent} from '../cxr/common/organisms/secondary-message-ribbon/secondary-message-ribbon.component';
import {AccordionBoxComponent} from '../cxr/common/molecules/accordion-box/accordion-box.component';
import {ViewLettersComponent} from '../cxr/common/organisms/view-letters/view-letters.component';
import {SecondarySlidingModalComponent} from '../cxr/common/molecules/modal/secondary-sliding-modal/secondary-sliding-modal.component';
import {HoursMinDropdownComponent} from '../cxr/common/atoms/dropdowns/hours-min-dropdown/hours-min-dropdown.component';
import { JsonArrayPipe } from './pipes/json-array.pipe';
import {ReduceScheduleGridComponent} from '../cxr/leave-claim-intake-flow/reduce-schedule-grid/reduce-schedule-grid.component';
import {PopupRequestExtensionComponent} from '../cxr/request-extensions/popup-request-extension/popup-request-extension.component';
import {LeaveMissingInformationComponent} from '../cxr/leave-missing-information/leave-missing-information.component';
import {MissingInfoThaaComponent} from '../cxr/common/organisms/missing-info-thaa/missing-info-thaa.component';
import {MissingDocumentUploadModalComponent} from '../cxr/document-upload/missing-document-upload-modal/missing-document-upload-modal.component';
import {PopoverComponent} from '../cxr/common/molecules/popover/popover.component';
import {MissingInfoDetailsThaaComponent} from '../cxr/missing-info-details-thaa/missing-info-details-thaa.component';
import {RecentlyResolvedThaaComponent} from '../cxr/missing-information/recently-resolved-thaa/recently-resolved-thaa.component';
import {StillWorkingModalComponent} from '../cxr/confirm-delivery/still-working-modal/still-working-modal.component';
import { LeaveReasonCapitalizedPipe } from './pipes/leave-reason-capitalized.pipe';
import {ClaimActivityHistoryComponent} from '../cxr/claim-activity-history/claim-activity-history.component';
import {HukStyleRadioButtonComponent} from '../cxr/common/atoms/buttons/huk-style-radio-button/huk-style-radio-button.component';
import {HourMinUnitMultiDropdownComponent} from '../cxr/common/molecules/multi-dropdowns/hour-min-unit-multi-dropdown/hour-min-unit-multi-dropdown.component';
import {HoursMinsMultiDropdownComponent} from '../cxr/common/molecules/multi-dropdowns/hours-mins-multi-dropdown/hours-mins-multi-dropdown.component';
import { ViewVideosComponent } from '../cxr/common/organisms/view-videos/view-videos.component';
import {TimelineStepComponent} from '@app/cxr/common/molecules/timeline-step/timeline-step.component';
import { HorizontalStepperComponent } from '@app/cxr/common/molecules/horizontal-stepper/horizontal-stepper.component';
import { SelectDropdownComponent } from '@app/cxr/common/atoms/dropdowns/select-dropdown/select-dropdown.component';
import { PrimaryTextAreaComponent } from '@app/cxr/common/atoms/textarea/primary-text-area/primary-text-area.component';
import {SinglePaymentDetailsComponent} from '@app/cxr/common/organisms/single-payment-details/single-payment-details.component';
import {OverpaymentAlertSectionComponent} from '@app/cxr/common/organisms/overpayment-alert-section/overpayment-alert-section.component';
import {DescriptionFadeComponent} from '@app/cxr/common/organisms/description-fade/description-fade.component';
import { LimitdecimalpointDirective } from './directives/limitdecimalpoint.directive';
import { SurgeryCancelModalComponent } from '@app/cxr/confirm-surgery/surgery-cancel-modal/surgery-cancel-modal.component';
import { DisabilityCancelPopupModelComponent } from '@app/cxr/confirm-disability/disability-cancel-popup-model/disability-cancel-popup-model.component';
import { DisablityLastDaySectionThaaComponent } from '@app/cxr/confirm-disability/disablity-last-day-section-thaa/disablity-last-day-section-thaa.component';
import { DisablityLastDaySectionComponent } from '@app/cxr/confirm-disability/disablity-last-day-section/disablity-last-day-section.component';
import { SecondaryDateDropdownComponent } from '@app/cxr/common/molecules/secondary-date-dropdown/secondary-date-dropdown.component';
import { SurgeryCommonQuetionsComponent } from '@app/cxr/confirm-surgery/surgery-common-questions/surgery-common-quetions.component';
import { RadioTileComponent } from '@app/cxr/common/molecules/radio-tile/radio-tile.component';
import { WorkScheduleListComponent } from '@app/cxr/common/organisms/work-schedule-list/work-schedule-list.component';
import { HukHourMinUnitMultiDropdownComponent } from '@app/cxr/common/molecules/multi-dropdowns/huk-hour-min-unit-multi-dropdown/huk-hour-min-unit-multi-dropdown.component';
import { ConvertToDoubleDigitsPipe } from './pipes/convert-to-double-digits.pipe';
import {IntakeNonWorkdayModalComponent} from '@app/cxr/common/organisms/intake-non-workday-modal/intake-non-workday-modal.component';
import { TimePickerDropdownComponent } from '@app/cxr/modules/intake/shared/components/time-picker-dropdown/time-picker-dropdown.component';
import { CombineSlidingModalComponent } from '@app/cxr/common/molecules/modal/combine-sliding-modal/combine-sliding-modal.component';

const SHARED_COMPONENTS = [
  HeadingNavigationComponent,
  HeadingLabelComponent,
  InfoBarComponent,
  MissingInfoComponent,
  MissingInfoThaaComponent,
  LeaveMissingInformationComponent,
  SecondaryPageLabelComponent,
  PrimaryButtonComponent,
  SecondaryButtonComponent,
  TertiaryButtonComponent,
  PrimaryDropdownComponent,
  TertiaryDropdownComponent,
  PrimaryLinkComponent,
  PrimaryLinkTransparentComponent,
  PrimaryPageLabelComponent,
  TertiaryPageLabelComponent,
  TimelineStepComponent,
  MessageRibbonComponent,
  PrimaryInputComponent,
  PrimaryCheckboxComponent,
  PrimaryRadioComponent,
  SecondaryLinkComponent,
  TertiaryLinkComponent,
  SecondaryDropdownComponent,
  PrimaryTextInputComponent,
  PrimaryFormTextInputComponent,
  RadioTileComponent,
  ButtonComponent,
  PrimaryFormDropdownComponent,
  SecondaryDateDropdownComponent,
  SurgeryCommonQuetionsComponent,
  AnchorButtonComponent,
  ImgLinkComponent,
  SlidingModalComponent,
  EditCancelPopupComponent,
  PopupCommPrefWithMobileComponent,
  FormPageLabelComponent,
  CustomPageLabelComponent,
  CancelPopupComponent,
  PopupCommPrefComponent,
  TermsCondPopupComponent,
  BoxLabelComponent,
  SurgeryCancelModalComponent,
  DisabilityCancelPopupModelComponent,
  DisablityLastDaySectionThaaComponent,
  DisablityLastDaySectionComponent,
  PrimaryLightButtonComponent,
  PrimaryFormCheckboxComponent,
  PageSpinnerComponent,
  PrimaryTextAreaComponent,
  SecondaryFormTextInputComponent,
  SecondaryTextInputComponent,
  H1LabelComponent,
  PopupRequestExtensionComponent,
  MissingInfoDetailsThaaComponent,
  PopoverComponent,
  RecentlyResolvedThaaComponent,
  MissingDocumentUploadModalComponent,

  // just a trial to avoid two declaration for lazy loading
  SecondaryMessageRibbonComponent,

  //I don't want to include these, but sample form made me
  DecimalFormatPipe,
  DecodeHtmlString,
  JsonArrayPipe,
  LeaveReasonCapitalizedPipe,
  ClaimCardComponent,
  ClaimstatusheaderComponent,
  DateLabelValueComponent,
  ClaimOverviewClaimDeniedComponent,
  NextPaymentBlockComponent,

  ConfirmSurgeryModelComponent,
  ConfirmMaternityModelComponent,
  StillWorkingModalComponent,
  ConfirmDisabilityModelComponent,
  ExtentionStatusComponent,
  LeaveRequestComponent,

  //ClaimTimelineComponent,
  BubbleContainerComponent,

  EistimateUnderReviewComponent,

  ClaimDocumentDetailsComponent,
  FileUploadButtonComponent,

  //Login Component reqs
  LoginComponent,

  CommonQuestionsComponent,
  ContactUsComponent,

  ProductListComponent,
  ProductListPreLoginComponent,
  ProductListPostLoginComponent,
  SuccessorProductListComponent,
  VolProdContactComponent,
  ScheduleCallLinkComponent,

  ClaimRepresentativeInformationComponent,
  ClaimHandlerComponent,
  PrimaryContactLabelComponent,
  LeaveCardComponent,
  CardCarouselComponent,
  ScheduleCallLinkComponent,
  TimeSelectionButtonComponent,
  ThaaRedirectSaveModalComponent,
  AccordionBoxComponent,
  ViewLettersComponent,
  SecondarySlidingModalComponent,
  HoursMinDropdownComponent,
  HourMinUnitMultiDropdownComponent,
  HoursMinsMultiDropdownComponent,
  HukHourMinUnitMultiDropdownComponent,
  TimePickerDropdownComponent,
  ReduceScheduleGridComponent,
  ClaimActivityHistoryComponent,
  HukStyleRadioButtonComponent,
  HorizontalStepperComponent,
  ViewVideosComponent,
  SelectDropdownComponent,
  SinglePaymentDetailsComponent,
  OverpaymentAlertSectionComponent,
  DescriptionFadeComponent,
  WorkScheduleListComponent,
  ConvertToDoubleDigitsPipe,
  IntakeNonWorkdayModalComponent,
  CombineSlidingModalComponent
];

const SHARED_VALIDATORS = [
  ValidateSequentionalDigitsPattern,
  ValidateConsecutiveDigitsPattern,
  ValidateTwoDigitsRequiredPattern,
  ValidateMinLength,
  ValidatePolicyNumber
];

const SHARED_DIRECTIVES = [
  LinkDirective,
  MaskAccountNumberPipe,
  BlockCopyPasteDirective,
  NumberOnlyDirective,
  AlphaNumericOnlyDirective,
  LimitdecimalpointDirective
];

@NgModule({
  imports: [
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    CommonModule,
    RouterModule,
    TextMaskModule,
    MyDatePickerModule
  ],
  declarations: [...SHARED_COMPONENTS, ...SHARED_VALIDATORS, ...SHARED_DIRECTIVES ],
  exports: [...SHARED_COMPONENTS, ...SHARED_VALIDATORS, ...SHARED_DIRECTIVES, DecodeHtmlString ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  providers: [ConvertToDoubleDigitsPipe]
})
export class SharedModule { }
