export class EnvironmentConfig {
  public baseEnvConfig = {
    prod: {
      dpUrl: "https://api.thehartford.com/",
      cxrBase: "https://mybenefits.thehartford.com/",
      thaaBase: "",
      envName: "prod",
      ciamAccountUrl: "https://account.thehartford.com",
      sfdAccountUrl: "https://gbaccount.thehartford.com",
      proxyState: false,
      mbUrlChunk: "mybenefits",
      impersonateUrlChunk: "internaladmin-mybenefits",
      mbTargetEnv: "",
      thaaTargetEnv: "",
      appId: "EE",
      ciamLoginUrl: "/customer/login?",
      ciamRegistrationUrl: "/customer/registration?appid=EE",
      psvCdnUrl: "https://s0.hfdstatic.com/psvthaa/prod/MYBENEFIT/",
      psvGoogleAnalyticKey: "UA-195140529-4",
      recaptchaKey: "6LeJydUlAAAAAG6O5gu1Z__gkqNLibFbkb0ij3KE",
      ciamApiSgwUrl: "https://ciam-sgw.thehartford.com/",
      ciamApiSgwTargetEnv: "",
    },
    ltd2: {
      dpUrl: "https://int-api.thehartford.com/",
      cxrBase: "https://ltd2-cxr.thehartford.com/",
      thaaBase: "https://dev2wkab.thehartford.com/",
      envName: "ltd2",
      ciamAccountUrl: "https://account-dev.thehartford.com",
      sfdAccountUrl: "https://ltd2-gbaccount.thehartford.com",
      proxyState: false,
      mbUrlChunk: "ltd2-cxr",
      impersonateUrlChunk: "ltd2-internaladmin-mybenefits",
      mbTargetEnv: "LTD2",
      thaaTargetEnv: "LTD2",
      appId: "EELTD2",
      ciamLoginUrl: "/customer/login?",
      ciamRegistrationUrl: "/customer/registration?appid=EE",
      psvCdnUrl: "https://ts0.hfdstatic.com/psvthaa/dev/MYBENEFIT/",
      psvGoogleAnalyticKey: "UA-195140529-1",
      recaptchaKey: "6Lcoy9UlAAAAAKx6cy5da-eFbpsP_rIDx2DslyZx",
      ciamApiSgwUrl: "https://ciam-sgwdev.thehartford.com/",
      ciamApiSgwTargetEnv: "dev",
    },
    local: {
      dpUrl: "int-api.thehartford.com/",
      cxrBase: "https://ltd2-cxr.thehartford.com/",
      thaaBase: "",
      envName: "local",
      ciamAccountUrl: "https://account-dev.thehartford.com",
      sfdAccountUrl: "",
      proxyState: true,
      mbUrlChunk: "",
      impersonateUrlChunk: "",
      mbTargetEnv: "LTD2",
      thaaTargetEnv: "LTD2",
      appId: "EE",
      ciamLoginUrl: "/customer/login?",
      ciamRegistrationUrl: "/customer/registration?appid=EE",
      psvCdnUrl: "https://ts0.hfdstatic.com/psvthaa/dev/MYBENEFIT/",
      psvGoogleAnalyticKey: "UA-195140529-1",
      recaptchaKey: "6Lcoy9UlAAAAAKx6cy5da-eFbpsP_rIDx2DslyZx",
      ciamApiSgwUrl: "ciam-sgwdev.thehartford.com/",
      ciamApiSgwTargetEnv: "dev",
    },
    "local-qa": {
      dpUrl: "qa-api.thehartford.com/",
      cxrBase: "https://lqa2-cxr.thehartford.com/",
      thaaBase: "",
      envName: "local-qa",
      ciamAccountUrl: "https://account-qa.thehartford.com",
      sfdAccountUrl: "",
      proxyState: true,
      mbUrlChunk: "",
      impersonateUrlChunk: "",
      mbTargetEnv: "QA4",
      thaaTargetEnv: "QA4",
      appId: "EE",
      ciamLoginUrl: "/customer/login?",
      ciamRegistrationUrl: "/customer/registration?appid=EE",
      psvCdnUrl: "https://ts0.hfdstatic.com/psvthaa/qa/MYBENEFIT/",
      psvGoogleAnalyticKey: "UA-195140529-2",
      recaptchaKey: "6Lcoy9UlAAAAAKx6cy5da-eFbpsP_rIDx2DslyZx",
      ciamApiSgwUrl: "ciam-sgwqa.thehartford.com/",
      ciamApiSgwTargetEnv: "qa",
    },
    ltd: {
      dpUrl: "https://int-api.thehartford.com/",
      cxrBase: "https://ltd-cxr.thehartford.com/",
      thaaBase: "",
      envName: "ltd",
      ciamAccountUrl: "https://account-dev.thehartford.com",
      sfdAccountUrl: "https://ltd-gbaccount.thehartford.com",
      proxyState: false,
      mbUrlChunk: "ltd-cxr",
      impersonateUrlChunk: "ltd-internaladmin-mybenefits",
      mbTargetEnv: "int",
      thaaTargetEnv: "int",
      appId: "EELTD",
      ciamLoginUrl: "/customer/login?",
      ciamRegistrationUrl: "/customer/registration?appid=EE",
      psvCdnUrl: "https://ts0.hfdstatic.com/psvthaa/dev/MYBENEFIT/",
      psvGoogleAnalyticKey: "UA-195140529-1",
      recaptchaKey: "6Lcoy9UlAAAAAKx6cy5da-eFbpsP_rIDx2DslyZx",
      ciamApiSgwUrl: "https://ciam-sgwdev.thehartford.com/",
      ciamApiSgwTargetEnv: "dev",
    },
    ltd3: {
      dpUrl: "https://int-api.thehartford.com/",
      cxrBase: "https://ltd3-cxr.thehartford.com/",
      thaaBase: "",
      envName: "ltd3",
      ciamAccountUrl: "https://account-dev.thehartford.com",
      sfdAccountUrl: "https://ltd3-gbaccount.thehartford.com",
      proxyState: false,
      mbUrlChunk: "ltd3-cxr",
      impersonateUrlChunk: "ltd3-internaladmin-mybenefits",
      mbTargetEnv: "LTD3",
      thaaTargetEnv: "LTD3",
      appId: "EELTD3",
      ciamLoginUrl: "/customer/login?",
      ciamRegistrationUrl: "/customer/registration?appid=EE",
      psvCdnUrl: "https://ts0.hfdstatic.com/psvthaa/dev/MYBENEFIT/",
      psvGoogleAnalyticKey: "UA-195140529-1",
      recaptchaKey: "6Lcoy9UlAAAAAKx6cy5da-eFbpsP_rIDx2DslyZx",
      ciamApiSgwUrl: "https://ciam-sgwdev.thehartford.com/",
      ciamApiSgwTargetEnv: "dev",
    },
    lqa: {
      dpUrl: "https://qa-api.thehartford.com/",
      cxrBase: "https://lqa-cxr.thehartford.com/",
      thaaBase: "",
      envName: "lqa",
      ciamAccountUrl: "https://account-qa.thehartford.com",
      sfdAccountUrl: "https://lqa-gbaccount.thehartford.com",
      proxyState: false,
      mbUrlChunk: "lqa-cxr",
      impersonateUrlChunk: "lqa-internaladmin-mybenefits",
      mbTargetEnv: "qa",
      thaaTargetEnv: "qa",
      appId: "EELQA",
      ciamLoginUrl: "/customer/login?",
      ciamRegistrationUrl: "/customer/registration?appid=EE",
      psvCdnUrl: "https://ts0.hfdstatic.com/psvthaa/qa/MYBENEFIT/",
      psvGoogleAnalyticKey: "UA-195140529-2",
      recaptchaKey: "6Lcoy9UlAAAAAKx6cy5da-eFbpsP_rIDx2DslyZx",
      ciamApiSgwUrl: "https://ciam-sgwqa.thehartford.com/",
      ciamApiSgwTargetEnv: "qa",
    },
    lqa2: {
      dpUrl: "https://qa-api.thehartford.com/",
      cxrBase: "https://lqa2-cxr.thehartford.com/",
      thaaBase: "",
      envName: "lqa2",
      ciamAccountUrl: "https://account-qa.thehartford.com",
      sfdAccountUrl: "https://lqa2-gbaccount.thehartford.com",
      proxyState: false,
      mbUrlChunk: "lqa2-cxr",
      impersonateUrlChunk: "lqa2-internaladmin-mybenefits",
      mbTargetEnv: "QA4",
      thaaTargetEnv: "qa4",
      appId: "EELQA2",
      ciamLoginUrl: "/customer/login?",
      ciamRegistrationUrl: "/customer/registration?appid=EE",
      psvCdnUrl: "https://ts0.hfdstatic.com/psvthaa/qa/MYBENEFIT/",
      psvGoogleAnalyticKey: "UA-195140529-2",
      recaptchaKey: "6Lcoy9UlAAAAAKx6cy5da-eFbpsP_rIDx2DslyZx",
      ciamApiSgwUrl: "https://ciam-sgwqa.thehartford.com/",
      ciamApiSgwTargetEnv: "qa",
    },
    lqa3: {
      dpUrl: "https://qa-api.thehartford.com/",
      cxrBase: "https://lqa3-cxr.thehartford.com/",
      thaaBase: "",
      envName: "lqa3",
      ciamAccountUrl: "https://account-qa.thehartford.com",
      sfdAccountUrl: "https://lqa3-gbaccount.thehartford.com",
      proxyState: false,
      mbUrlChunk: "lqa3-cxr",
      impersonateUrlChunk: "lqa3-internaladmin-mybenefits",
      mbTargetEnv: "lqa3",
      thaaTargetEnv: "qa4",
      appId: "EELQA3",
      ciamLoginUrl: "/customer/login?",
      ciamRegistrationUrl: "/customer/registration?appid=EE",
      psvCdnUrl: "https://ts0.hfdstatic.com/psvthaa/qa/MYBENEFIT/",
      psvGoogleAnalyticKey: "UA-195140529-2",
      recaptchaKey: "6Lcoy9UlAAAAAKx6cy5da-eFbpsP_rIDx2DslyZx",
      ciamApiSgwUrl: "https://ciam-sgwqa.thehartford.com/",
      ciamApiSgwTargetEnv: "qa",
    },
    qa: {
      dpUrl: "https://qa-api.thehartford.com/",
      cxrBase: "https://qa-cxr.thehartford.com/",
      thaaBase: "",
      envName: "qa",
      ciamAccountUrl: "https://account-qa.thehartford.com",
      sfdAccountUrl: "https://qa-gbaccount.thehartford.com",
      proxyState: false,
      mbUrlChunk: "qa-cxr",
      impersonateUrlChunk: "qa-internaladmin-mybenefits",
      mbTargetEnv: "qa2",
      thaaTargetEnv: "qa2",
      appId: "EE",
      ciamLoginUrl: "/customer/login?",
      ciamRegistrationUrl: "/customer/registration?appid=EE",
      psvCdnUrl: "https://ts0.hfdstatic.com/psvthaa/qa/MYBENEFIT/",
      psvGoogleAnalyticKey: "UA-195140529-2",
      recaptchaKey: "6Lcoy9UlAAAAAKx6cy5da-eFbpsP_rIDx2DslyZx",
      ciamApiSgwUrl: "https://ciam-sgwqa.thehartford.com/",
      ciamApiSgwTargetEnv: "qa",
    },
    qa3: {
      dpUrl: "https://qa-api.thehartford.com/",
      cxrBase: "https://qa3-cxr.thehartford.com/",
      thaaBase: "",
      envName: "qa3",
      ciamAccountUrl: "https://account-pte.thehartford.com",
      sfdAccountUrl: "https://qa3-gbaccount.thehartford.com",
      proxyState: false,
      mbUrlChunk: "qa3-cxr",
      impersonateUrlChunk: "qa3-internaladmin-mybenefits",
      mbTargetEnv: "SQA3",
      thaaTargetEnv: "SQA3",
      appId: "EESQA3",
      ciamLoginUrl: "/customer/login?",
      ciamRegistrationUrl: "/customer/registration?appid=EE",
      psvCdnUrl: "https://ts0.hfdstatic.com/psvthaa/qa/MYBENEFIT/",
      psvGoogleAnalyticKey: "UA-195140529-2",
      recaptchaKey: "6Lcoy9UlAAAAAKx6cy5da-eFbpsP_rIDx2DslyZx",
      ciamApiSgwUrl: "https://ciam-sgwqa.thehartford.com/",
      ciamApiSgwTargetEnv: "qa",
    },
    pte: {
      dpUrl: "https://qa-api.thehartford.com/",
      cxrBase: "https://qa-cxr.thehartford.com/",
      thaaBase: "",
      envName: "pte",
      ciamAccountUrl: "https://account-pte.thehartford.com",
      sfdAccountUrl: "https://pte-gbaccount.thehartford.com",
      proxyState: false,
      mbUrlChunk: "pte-cxr",
      impersonateUrlChunk: "pte-internaladmin-mybenefits",
      mbTargetEnv: "pte",
      thaaTargetEnv: "pte",
      appId: "EE",
      ciamLoginUrl: "/customer/login?",
      ciamRegistrationUrl: "/customer/registration?appid=EE",
      psvCdnUrl: "https://ts0.hfdstatic.com/psvthaa/qa/MYBENEFIT/",
      psvGoogleAnalyticKey: "UA-195140529-2",
      recaptchaKey: "6Lcoy9UlAAAAAKx6cy5da-eFbpsP_rIDx2DslyZx",
      ciamApiSgwUrl: "https://ciam-sgwqa.thehartford.com/",
      ciamApiSgwTargetEnv: "pte",
    },
  };
}
