import {Component, EventEmitter, OnInit, Output} from '@angular/core';
import {Router} from '@angular/router';
import {ModalService} from '../../../../shared/services/modal.service';
import {AppLabelConstants} from '../../../../shared/constants/appLabel.constants';

@Component({
  selector: 'app-edit-cancel-popup',
  templateUrl: './edit-cancel-popup.component.html',
  styleUrls: ['./edit-cancel-popup.component.scss']
})
export class EditCancelPopupComponent implements OnInit {
  @Output() onCancel = new EventEmitter<boolean>();
  public appLabelConstants = AppLabelConstants;
  constructor(private router: Router,
  private modalService: ModalService) { }

  ngOnInit() {

  }
  backToView(route) {
    this.router.navigate([route]);
  }

  backToEdit() {
    this.modalService.closeActiveModal();
  }

}
