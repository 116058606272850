import { Injectable } from "@angular/core";
import { TealiumDataService } from "./tealium-data.service";

@Injectable()
export class CustomEventService {
  events = [];

  constructor(private readonly tealium: TealiumDataService) {}

  /** Called when form error(s) occur within single UI interaction
   * Format into array with one or more objects as specified in DLG requirement
   *
   * @param idArray: array of IDs of all fields that currently have form error message visible
   * @param errorMsgArray: array of visible form validation messages
   */
  formFieldValidationErrors(idArray: Array<string>, errorMsgArray: Array<string>) {
    if (idArray.length && errorMsgArray.length && idArray.length === errorMsgArray.length) {
      const formErrors = [];
      for (const i in idArray) {
        if (idArray.hasOwnProperty(i)) {
          if (errorMsgArray[i]) {
            errorMsgArray[i] = errorMsgArray[i].replace(/<div>/g, "");
            errorMsgArray[i] = errorMsgArray[i].replace(/<\/div>/g, "");
          }
          formErrors.push({
            event_type: "form_error_message",
            event_id: idArray[i],
            event_value: errorMsgArray[i],
          });
        }
      }

      this.tealium.addEvent({
        event_name: "form field validation error",
        event_parent: "form field validation error",
        form_errors: formErrors,
        da_track: true,
      });
    }
  }

  linkClick(id, url) {
    if (url) {
      this.tealium.addEvent({
        event_parent: "Page Tag",
        event_type: "Link Click",
        event_id: id.toString(),
        event_value: url.toString(),
        da_track: true,
      });
    } else if (id === "answer_QID_185") {
      this.tealium.addEvent({
        event_parent: "Page Tag",
        event_type: "Click",
        event_id: "Go To Day One Absence",
        event_value: "FAQ",
        da_track: true,
      });
    }
  }

  /*** Field level custom event tracking
   * ParentTag: Can be the name of the page the event got triggered for example: About-me or Quote Page etc..
   * EventType: Can be "Input Field Click -Checkbox, Button click, or Radio Button etc..
   * EventId: Can be set to HTML element ID
   * EventValue: Can be true or flase or anything else. "
   * **/
  fieldClickEvents(parenTag: string, eventType: string, eventID: string, eventValue?: string) {
    if (parenTag && eventType && eventID) {
      this.tealium.addEvent({
        event_parent: parenTag,
        event_type: eventType,
        event_id: eventID,
        event_value: eventValue,
        da_track: true,
      });
    }
  }
  namedEvent(name: string, parent: string, type: string, eventValue?: string) {
    if (name && type && parent) {
      this.tealium.addEvent({
        event_name: name,
        event_parent: parent,
        event_type: type,
        event_value: eventValue,
        da_track: true,
      });
    }
  }

  //modal error window tracking
  modalErrorView(message: string) {
    if (message) {
      this.tealium.addEvent({
        event_type: "View",
        event_id: "Modal Error Window",
        event_value: message,
        da_track: true,
      });
    }
  }
  modalView(page: string, message: string) {
    if (message && page) {
      this.tealium.addEvent({
        event_name: "modal window view",
        event_parent: "Modal Window Impression",
        event_type: "View",
        event_id: message,
        event_value: page,
        da_track: true,
      });
    }
  }

  customEvent(
    eventName: string,
    parenTag: string,
    eventType: string,
    eventID: any,
    eventValue: any
  ) {
    if (eventID && eventValue) {
      this.tealium.addEvent({
        event_name: eventName,
        event_parent: parenTag,
        event_type: eventType,
        event_id: eventID,
        event_value: eventValue,
        da_track: true,
      });
    }
  }

  /**
   * Tracks metadata of thank you page error
   *
   * @param errId: Error Code
   * @param errVal: Error Reason
   * @param errType: Error Category
   */
  errorTracking(errId, errVal, errType) {
    this.tealium.addEvent({
      event_parent: "Thank you Page Error",
      event_name: "thank you page error",
      form_errors: {
        event_type: errType,
        event_id: errId,
        event_value: errVal,
      },
      da_track: true,
    });
  }

  faqTracking(question) {
    this.tealium.addEvent({
      event_parent: "Page Tag",
      event_name: "FAQ Click",
      event_value: question,
      event_type: "Link Click",
      event_id: "Accordion +",
      da_track: true,
    });
  }

  popupErrorTracking(errorType, errorContent) {
    this.tealium.addEvent({
      event_parent: "Error Tracking",
      event_type: "Page Error",
      event_name: "Popup Error",
      event_id: errorType,
      messageText: errorContent,
      da_track: true,
    });
  }

  trackAccordion(accordionValue, eventValue?: string) {
    this.tealium.addEvent({
      event_parent: "Page Tag",
      event_name: "Accordion Track",
      event_value: eventValue || window.location.href,
      event_type: "Link Click",
      event_id: accordionValue,
      da_track: true,
    });
  }

  trackWatchNowButton(buttonClickInfo, eventValue?) {
    this.tealium.addEvent({
      event_parent: "Page Tag",
      event_value: eventValue ? eventValue : window.location.href,
      event_type: "Button Click",
      event_id: buttonClickInfo,
      da_track: true,
    });
  }

  trackWatchNowLink(linkClickInfo) {
    this.tealium.addEvent({
      event_parent: "Page Tag",
      event_value: window.location.href,
      event_type: "Link Click",
      event_id: linkClickInfo,
      da_track: true,
    });
  }

  trackMissingInfoDocumentCategory(documentName, documentCategory) {
    this.tealium.addEvent({
      event_parent: "Page Tag",
      event_name: "Missing Info Document",
      event_value: documentCategory,
      event_type: "Link Click",
      event_id: documentName,
      da_track: true,
    });
  }

  trackHukPopOver(eventValue: any) {
    this.tealium.addEvent({
      event_parent: "Page Tag",
      event_value: eventValue,
      event_type: "Image Click",
      event_id: "HUK-Popover",
      da_track: true,
    });
  }

  trackDropdown(eventID: string, eventValue: any) {
    this.tealium.addEvent({
      event_parent: "Page Tag",
      event_name: "Dropdown Track",
      event_value: eventValue,
      event_type: "Select Click - (select-one)",
      event_id: eventID,
      da_track: true,
    });
  }

  trackBannerMsg(eventName, parent, bannerMessage) {
    this.tealium.addEvent({
      event_parent: parent,
      event_name: eventName,
      event_type: "Banner Impression",
      event_id: bannerMessage,
      da_track: true,
    });
  }

  calendarTracking(fieldQues, page) {
    this.tealium.addEvent({
      event_parent: "Form Field Interaction",
      event_name: "Form Calendar Tracking",
      event_type: "Form Calendar Tracking",
      event_id: fieldQues,
      event_value: page,
      da_track: true,
    });
  }

  trackCheckBox(eventId, OptionSelected) {
    this.tealium.addEvent({
      event_parent: "Page Tag",
      event_name: "Checkbox Tracking",
      event_type: "Input Field Click - (checkbox)",
      event_id: eventId,
      event_value: OptionSelected,
      da_track: true,
    });
  }

  trackCalendarInteraction() {
    this.tealium.addEvent({
      event_parent: "Leave Details Page",
      event_name: "Calendar Interaction Tracking",
      event_type: "Calendar Click",
      event_value: window.location.href,
      da_track: true,
    });
  }

  trackClaimType(claimType, claimId) {
    this.tealium.addEvent({
      event_name: "Claim Type Tracking",
      event_parent: "Claim Submission",
      event_type: "Claim Type Tracking",
      event_value: claimType,
      gbclaimevid: claimId,
      da_track: false,
    });
  }

  trackRadioButton(eventId, OptionSelected) {
    this.tealium.addEvent({
      event_parent: "Page Tag",
      event_name: "Radio Button Tracking",
      event_type: "Input Field Click - (radio)",
      event_id: eventId,
      event_value: OptionSelected,
      da_track: true,
    });
  }

  trackFormButton(eventId) {
    this.tealium.addEvent({
      event_parent: "Page Tag",
      event_name: "Button Tracking",
      event_type: "click",
      event_id: eventId,
      event_value: "Question Value",
      da_track: true,
    });
  }

  trackWarningBanner(eventId, eventValue) {
    this.tealium.addEvent({
      event_parent: "Start a new claim Impression Tracking",
      event_type: "Banner Impression",
      event_id: eventId,
      event_value: eventValue,
      da_track: true,
    });
  }

  trackIntakeWarningBanner(eventId, eventValue) {
    this.tealium.addEvent({
      event_parent: "Page Tag",
      event_type: "click",
      event_id: eventId,
      event_value: eventValue,
      da_track: true,
    });
  }

  trackFormValidationError(eventValue) {
    this.tealium.addEvent({
      event_parent: "Form Field Validation Error",
      event_name: "form field validation error",
      event_type: "form_error_message",
      event_value: eventValue,
      da_track: true,
    });
  }

  trackFormTextBox(eventId, eventValue) {
    this.tealium.addEvent({
      event_parent: "Form Field Interaction",
      event_name: "Form Field Interaction",
      event_type: "Input Field Click - (text)",
      event_value: eventValue,
      event_id: eventId,
      da_track: true,
    });
  }

  pageTracking(eventId) {
    this.tealium.addEvent({
      event_parent: "Confirm Return to Work",
      event_name: "Confirm Return to Work",
      event_type: "Page Tag",
      event_value: "Page Tag",
      event_id: eventId,
      da_track: true,
    });
  }

  helpTipTracking(eventId) {
    this.tealium.addEvent({
      event_parent: "Benefit Details",
      event_name: "Help Tip Track",
      event_type: "Help Tip Track",
      event_id: eventId,
      da_track: true,
    });
  }

  trackRadio(eventId: string, optionSelected: any) {
    this.tealium.addEvent({
      event_parent: "Page Tag",
      event_name: "Radio Tracking",
      event_type: "Input Field Click - (radio)",
      event_id: eventId,
      event_value: optionSelected,
      da_track: true,
    });
  }

  trackButton(eventId: string, eventValue: any) {
    this.tealium.addEvent({
      event_parent: "Page Tag",
      event_name: "Button Tracking",
      event_type: "click",
      event_id: eventId,
      event_value: eventValue,
      da_track: true,
    });
  }

  trackDatePicker(eventId: string, eventValue: any) {
    this.tealium.addEvent({
      event_parent: "Page Tag",
      event_name: "Datepicker Tracking",
      event_type: "Input Field Click - (date)",
      event_value: eventValue,
      event_id: eventId,
      da_track: true,
    });
  }

  trackRadioTile(eventId: string, optionSelected: any) {
    this.tealium.addEvent({
      event_parent: "Page Tag",
      event_name: "Radio Tile Tracking",
      event_type: "Input Field Click - (radio)",
      event_id: eventId,
      event_value: optionSelected,
      da_track: true,
    });
  }
}
