/**
 * Created by AC12396 on 2/19/2019.
 */

import {GbBase} from './gb-base';

export class OrchService extends GbBase {

  getUrls() {
    return {
      getClaims: this.getBaseUrl() + 'gb-orch-service/claims/v1/claims?' + this.getClientId('auth'),
      directDepositURL: this.getBaseUrl() + 'gb-orch-service/claims/v1/direct-deposit?' + this.getClientId('auth'),
      updateProfileServiceURL : this.getBaseUrl() + 'gb-orch-service/claims/v1/profile?' + this.getClientId('auth')
    };
  }
}
