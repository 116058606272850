/**
 * Created by AC12394 on 8/28/2017.
 */

import {Injectable} from '@angular/core';
import {AppLabelConstants} from '../constants/appLabel.constants';

export const FooterContent = {
  footer_links: [
    {linkVal: 'https://www.thehartford.com/legal-notice', linkName: 'Legal Notice'},
    {linkVal: 'https://www.thehartford.com/customer-privacy-notice', linkName: 'Privacy Policy'},
    {'linkVal': 'https://www.thehartford.com/data-privacy-opt-out-form', 'linkName': 'Your California Privacy Choices', 'linkImg': '../../../../../../assets/images/icons/privacyoptions.svg'},
    {linkVal: '/securitystmt', linkName: 'Security Statement'},
    {linkVal: 'https://www.thehartford.com/accessibility-statement', linkName: 'Accessibility Statement'}
  ],
  copyright: {
    trademark: AppLabelConstants.TRADE_MARK
  }
};

@Injectable()
export class FooterContentService {

  getFooterContent(): any {
    return FooterContent;
  }
}
