import {ClaimDetails} from './claimDetails.model';

/**
 * Created by FA81445 on 7/31/2017.
 */
export class User {
  //id: number;
  srcSysId: string;
  profileId: string;
  //name: string;
  //employer: string;
  birthDt: string;
  userId: string;
  sessionIdentifier: string;
  partyRefId: string;
  insuredId: string;
  claimEventId: string;
  //paymentMethod: string;
  //coverages: Coverage[];
  sourcePartyDetailsList: any[];
 // higEmployee: boolean;
  claims: ClaimDetails;
  //businessGroupID: string;
 // isEnrolled: boolean;
  lastName: string;
  firstname: string;
  //paymentType: string;
  termsAndCondAcceptDt: string;
  termsAndCondEffectiveDt: string;
  caseId: string;
  //newCaseIndicator: string;
 // ssoOnlyCaseIndicator: string;
 // excludedCaseIndicator: string;
  eligibilityId: string;
 // cases: Array<Case>;

  //Address info that comes back from get user
  addressLine1: string;
  addressLine2: string;
  city: string;
  stateCode: string;
  countryCode: string;
  emailAddr: string;
  emailAddrLastUpdtDt: string;
  foreignProvinceCode: string;
  lastLoginDt: string;
  provinceCode: string;
  regTypeCd: number;
  errorList: string;
  zipCd: string;
  empId: string;
  processDt: string;
  ssn: string;
  statusIndicator: string;
  termsAndConditionsExpiredFlag: number;
  notePrefSetInd: string;
  noteTnCVersnId: string;
  personIds: string[];
}
