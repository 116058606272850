import {Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges} from '@angular/core';
import {AbstractControl, FormControl, FormGroup, Validators} from '@angular/forms';
import {Decision} from '../popup-comm-pref/decision';
import {ModalService} from '../../shared/services/modal.service';
import {ContentService} from '../../shared/services/content.service';
import {Router} from '@angular/router';
import {StorageManagementService} from '../../shared/services/storage-management.service';
import {SaveProfiledataService} from '../../shared/services/saveProfileData.service';
import {MessageRibbonService} from '../../shared/services/message-ribbon.service';
import {AppLabelConstants} from '../../shared/constants/appLabel.constants';
import {SessionConstants} from '../../shared/constants/session.constants';
import {AppConstants} from '../../shared/constants/app.constants';

function mobileNumberValidator(c: AbstractControl): {[key: string]: boolean} {
  if (!c.value || c.value.length === 0) {
    return {RequiredMobile: true};
  }
  if ( c.value && c.dirty && c.value.indexOf('_') > -1) {
    return {validateMinLength: true};
  }
  if (c.value && c.dirty && c.value.length > 8 && allDigitsSame(c.value.replace(/\D+/g, ''))) {
    return {validateConsecutiveDigits: true};
  }
  if (c.dirty && c.value.length > 8 && c.value.includes('(123) 456-789')) {
    //console.log("Error in seq");
    return {validateSequentialDigits: true};
  }
  return null;

}
function allDigitsSame(value) {
  const firstChar = value.charAt(0);
  let digitsSame = true;
  for (let i = 1; i < value.length; i++) {
    if (firstChar !== value[i]) {
      digitsSame = false;
      break;
    }
  }
  return digitsSame;
}



@Component({
  selector: 'app-popup-notification-reg',
  templateUrl: './popup-notification-reg.component.html',
  styleUrls: ['./popup-notification-reg.component.scss']
})
export class PopupNotificationRegComponent implements OnInit, OnChanges {
  public appLabelConstants = AppLabelConstants;
  private mobile;
  private jsonBOM;
  private caseId;
  private insuredId;
  private mobileno: string = '';
  private email: string = '';
  private updateProfileRequest: any;
  private updateProfileResponse: any;
  private profileData: any = {};
  public empBenefitRegiForm: FormGroup;
  @Output() private ifChangeDone = new EventEmitter<boolean>();
  @Output() private ifChangesSaved = new EventEmitter<boolean>();
  @Input() checkedText = false;
  @Input() checkedStatus = false;
  @Output() public termsNotAgreed = false;
  @Input() statusEmailcheckboxValue: boolean;
  @Input() statusTextcheckboxValue: boolean;
  @Input() mail;
  @Input() phone;
  //@Input() paymentEmailcheckboxValue: boolean;
 // @Input() paymentTextcheckboxValue: boolean;
  private userEmail: string = '';
  private userMobile: string = '';
  private pageId: string;
  private owscContent;
  //public notificationText = AppLabelConstants.PLEASE_ENTER_OR_VERIFY_UR_INFO;
  public notificationText;
  public changeInfoText;
  public textSignupone = AppLabelConstants.WE_WILL_SEND_A_TXT_MSG;
  public textSignuptwo = '';
  public optToUnEnrollText: string = AppLabelConstants.U_DIDNT_CHECK_THE_BOXES_MSG;
  public optToUnEnrollTwo: string = AppLabelConstants.REMEMBER_U_CAN_CHANGE_UR_PREF;
  @Output() userDecision  = new EventEmitter<Decision>();
  public thaaContent;
  public dcsContent;

  private userDecisionToBeEmitted: Decision = new Decision();

  constructor(private modalService: ModalService,
              private messageRibbon: MessageRibbonService,
              private contentService: ContentService,
              private router: Router,
              private updateData: SaveProfiledataService,
              private storageManagementService: StorageManagementService ) { }

  ngOnInit() {

    this.jsonBOM = JSON.parse(this.storageManagementService.retrieveSessionObject(SessionConstants.APP_DATA)).appData;
    this.caseId = this.jsonBOM.userData.caseId;
    this.insuredId = this.jsonBOM.userData.insuredId;
    /*
     * if User has already mobile/email populate it
     * */
    this.userDecisionToBeEmitted = {
      decision : false,
      mobileno : '',
      email: ''
    };
    this.setUserForm();
    this.getOWCSContent();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (this.mail && changes['mail']) {
      if (changes['mail'].currentValue !== changes['mail'].previousValue) {
         this.email = changes['mail'].currentValue;
         if (this.empBenefitRegiForm) {
          this.empBenefitRegiForm.controls.email.setValue(this.email);
         }
      }
    }
    if (this.phone && changes['phone']) {
      if (changes['phone'].currentValue !== changes['phone'].previousValue) {
         this.mobileno = changes['phone'].currentValue;
         if (this.empBenefitRegiForm) {
          this.empBenefitRegiForm.controls.mobileno.setValue(this.mobileno);
         }
      }
    }
    if (this.empBenefitRegiForm) {
      if (this.statusEmailcheckboxValue /*|| this.paymentEmailcheckboxValue*/) {
        this.empBenefitRegiForm.controls[AppConstants.EMAIL].setValidators([Validators.required, Validators.pattern('[a-zA-Z0-9._%+-]+@[a-zA-Z0-9]+\.([a-zA-Z]{3,5}|[a-zA-z]{2,10}\.[a-zA-Z]{2,5})')])
        this.empBenefitRegiForm.controls[AppConstants.EMAIL].updateValueAndValidity();
      }  else {
        this.empBenefitRegiForm.controls[AppConstants.EMAIL].setValidators([]);
        this.empBenefitRegiForm.controls[AppConstants.EMAIL].updateValueAndValidity();
      }
      if (this.statusTextcheckboxValue /*|| this.paymentTextcheckboxValue*/) {
        this.empBenefitRegiForm.controls[AppConstants.MOBILE_NO].setValidators([mobileNumberValidator]);
        this.empBenefitRegiForm.controls[AppConstants.MOBILE_NO].updateValueAndValidity();
      }  else {
        this.empBenefitRegiForm.controls[AppConstants.MOBILE_NO].setValidators([]);
        this.empBenefitRegiForm.controls[AppConstants.MOBILE_NO].updateValueAndValidity();
      }
    }
  }

  setUserForm() {
    this.empBenefitRegiForm = new FormGroup({
      email: new FormControl(this.email,
        [Validators.required, Validators.pattern('[a-zA-Z0-9._%+-]+@[a-zA-Z0-9]+\.([a-zA-Z]{3,5}|[a-zA-z]{2,10}\.[a-zA-Z]{2,5})')]),
      mobileno: new FormControl(this.mobileno)
    });

  }


  clickClose() {

    this.modalService.closeModal(AppLabelConstants.SAVE_NO_MOB_MODAL);


    this.userDecisionToBeEmitted.decision = false;
    this.userDecisionToBeEmitted.mobileno = '';
    this.userDecisionToBeEmitted.email = '';
    this.userDecision.emit(this.userDecisionToBeEmitted);

    this.modalService.closeModal(AppLabelConstants.SAVE_MODAL);
    this.modalService.closeModal(AppLabelConstants.SAVE_NO_MOB_MODAL);

    this.termsNotAgreed = false;
    sessionStorage.setItem(SessionConstants.TERMS_NOT_AGREED, this.termsNotAgreed.toString());
    this.clearForm();
  }

  onclickterms() {
    window.open(AppConstants.TEXT_TCS);
  }

  saveChanges() {
    this.userDecisionToBeEmitted.decision = true;
    /*Replace the mobile number masking character if user selected Text Notification*/
    if ((this.statusTextcheckboxValue /*|| this.paymentTextcheckboxValue*/) &&
      this.empBenefitRegiForm && this.empBenefitRegiForm.value && this.empBenefitRegiForm.value.mobileno) {
      this.userDecisionToBeEmitted.mobileno = this.empBenefitRegiForm.value.mobileno.replace(/\D+/g, '');
    } else {
      this.userDecisionToBeEmitted.mobileno = this.mobileno;
    }
    this.userDecisionToBeEmitted.email = this.empBenefitRegiForm.value.email;
    this.userDecision.emit(this.userDecisionToBeEmitted);
    this.clearForm();
  }

  toggleTerms() {
    this.termsNotAgreed = !this.termsNotAgreed;
  }

  clearForm() {
    this.empBenefitRegiForm.value.mobileno = '';
    this.empBenefitRegiForm.value.email = '';
    this.setUserForm();
  }


  // owcs integration for notification registartion
  getOWCSContent() {
    // OWCS service Integration
    this.pageId = this.contentService.getPageId(AppConstants.NOTIFICATION_INTERCEPT);
    if (this.pageId) {
      this.contentService.getOWCSResponse(this.pageId, AppConstants.NOTIFICATION_INTERCEPT_U).subscribe(
          val => {
          if (val) {
            this.owscContent = val;
            if (this.owscContent && this.owscContent.data) {
              /*if (this.owscContent.data.Notification_Promo_21_Body) {
               this.notificationText = this.owscContent.data.Notification_Promo_21_Body.value;
               }*/
            }
            if (this.owscContent && this.owscContent.data) {
              if (this.owscContent.data.Notification_Promo_23_Body) {
                this.textSignupone = this.owscContent.data.Notification_Promo_23_Body.value;
              }
            }
            if (this.owscContent && this.owscContent.data) {
              if (this.owscContent.data.Notification_Promo_24_Body) {
                this.textSignuptwo = this.owscContent.data.Notification_Promo_24_Body.value;
              }
            }
            if (this.owscContent && this.owscContent.data) {
              if (this.owscContent.data.Notification_Promo_2020_13_Body) {
                this.notificationText = this.owscContent.data.Notification_Promo_2020_13_Body.value;
              }
              if (this.owscContent.data.Notification_Promo_2020_10_Body) {
                this.changeInfoText = this.owscContent.data.Notification_Promo_2020_10_Body.value;
              }
            }
          }
        }, (error) => {// console.error(error);
        });
    }
  }

}

