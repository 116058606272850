import {Directive} from '@angular/core';
import {AbstractControl, NG_VALIDATORS, Validator} from '@angular/forms';

@Directive({
  selector: '[validatePolicyNumber]',
  providers: [{provide: NG_VALIDATORS, useExisting: ValidatePolicyNumber, multi: true}]
})
export class ValidatePolicyNumber implements Validator {

  private pattern = new RegExp('^\\d+$');
  validate(control: AbstractControl) {
    if ( control.value && control.dirty &&
      ( control.value.length !== 6 || !this.pattern.test(control.value) )
    ) {
      return {validatePolicyNumber: true};
    }
    return null;
  }
}
