import { Injectable } from '@angular/core';
import { Router, ActivatedRouteSnapshot, RouterStateSnapshot, CanActivate } from '@angular/router';
import { StorageManagementService } from '@app/shared/services/storage-management.service';
import { SessionConstants } from '../constants/session.constants';
import { AppConstants } from '../constants/app.constants';
import { IntakeLabelConstants } from '@app/cxr/modules/intake/shared/constants/intake-label.constants';
@Injectable()
export class  IntakeGuardService implements CanActivate {

    AppConstants = AppConstants;
    constructor(private router: Router, private storageManagementService: StorageManagementService) {
    }
    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
        const intake  = this.storageManagementService.getSessionData(SessionConstants.TOGGLE_LIST);
        const toggleIndex = intake?.findIndex(toggles => toggles.toggle === IntakeLabelConstants.NEWINTAKE);
        let checkToggle = false;
        if (intake && toggleIndex > -1) {
            checkToggle = intake[toggleIndex].state;
        }
        if ( checkToggle ) {
                return true;
        } else {
            this.router.navigate(['/home']);
              return false;
        }
    }
}
