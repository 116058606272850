export class TimeOffConstants {
  /** TIME-OFF-FLOW success, summary, error  */
  static REPORT_MISSED_HOUR = 'Report Missed Work Hours';
  static IS_ABSENCE_QUES_TOGGLE_NAME = 'ADAIntermittentIntake';
  static ADA_REQUEST_TIME_OFF_TOGGLE_NAME = 'ADAIntermittentReportMissedWorkHours';
  static ADA_INTERMITTENT_ABSENCE_STATUS_TOGGLE_NAME = 'ADAIntermittentAbsenceStatus';
  static TIME_OFF_SUCCESS_MSG = 'Success! Your missed work hours have been submitted.';
  static TIME_OFF_SUMMARY_MSG = 'It looks like only some of your requests went through.';
  static TIME_OFF_ERROR_MSG = 'Let’s Chat Instead.';
  static SPACE_LABEL = '&nbsp;';
  static FOR_LABEL = '&nbsp;for&nbsp;';
  static HOURS_LABEL = '&nbsp;hours&nbsp;';
  static HOUR_LABEL = '&nbsp;hour&nbsp;';
  static MINS_LABEL = '&nbsp;mins';
  static MIN_LABEL = '&nbsp;min';
  static FAILED_STATUS = 'Failed';
  static SUCCESS_STATUS = 'Success';
  static REQUEST_LABEL = 'Request';
  static LEAVE_FAILED_TRANSACTION = 'Leave Failed Transaction Error';
  static REQUEST_TIME_OFF_PARTIAL = 'Request Time Off Partial Fail';
  static REQUEST_TIME_OFF_CONFIRM = 'Request Time Off Confirm';
  static SOMETHING_WENT_WRONG = 'Something went wrong.';
  static COULDNT_ADD_HOURS = 'We couldn\'t add these hours to your claim. <br> Please call us at 1-888-301-5615 for help.';
  /** TIME-OFF-FLOW */

  /** TIME-OFF-FLOW review and request */
  static TIME_OFF_REQUEST_MSG = 'We need some information to get your request going.';
  static TIME_OFF_REASON_LABEL = 'Why are you requesting time off?';
  static TIME_OFF_TIME_LABEL = 'How many hours are you requesting off?';
  static TIME_OFF_DATE_LABEL = 'What date did you/do you plan to take off?';
  static TIME_OFF_PAST_REASON_LABEL = 'It looks like you’re requesting leave for a day or days in the past. Take a minute to let us know why you waited to report it.';
  static TIME_OFF_START_TIME_LABEL = 'What time did (or will) your absence start?';
  static TIME_OFF_REVIEW = 'Review your Requests';
  static TIME_OFF_REVIEW_MSG = 'Your request isn’t final until you hit submit.';
  static REVIEW_NO_REQUEST = 'You currently have no requests.';
  static REQUEST_ABSENCES = 'Request an additional absence';
  static EDIT = 'Edit';
  static DELETE = 'Delete';
  static URL_REQUEST_TIME_OFF = '/leave-details/requestTimeOff';
  static REQUEST_TIME_OFF_PATH = 'leave-details/requestTimeOff';
  static URL_REQUEST_REVIEW = '/leave-details/requestReview';
  static URL_TIME_OFF_SUCCESS = '/leave-details/timeOffSuccess';
  static URL_TIME_OFF_SUMMARY = '/leave-details/timeOffSummary';
  static LINK_CLICK_TRACKING = 'Link Click Tracking';
  static LINK_CLICK = 'Link Click';
  static PAGE_TAG = 'Page Tag';
  static HH_LABEL = 'HH';
  static MM_LABEL = 'MM';
  static HOURS_TEXT = 'hours';
  static MIN_TEXT = 'min';
  static CHARACTERS_LEFT = 'Characters Remaining: ';
  static WORK_FULL_PARTIAL = 'Did you/do you plan to miss a full or partial day?';
  static WARNING_BANNER = 'Banner Impression Tracking';
  static FORM_FIELD_INTERACTION = 'Form Field Interaction';
  static INPUT_FIELD_CLICK = 'Input Field Click-(text)';
  static PAST_REPORTING_REASON = 'pastReportingReason';
  /** TIME-OFF-FLOW */

  /** BUTTON TEXT */
  static GOBACK_BUTTON_TEXT = 'Go Back';
  static EXIT_BTN_TEXT = 'Exit without saving changes';
  static CANCEL_BTN_TEXT = 'Cancel';
  static NEXT_BTN_TEXT = 'Next';
  static RETURN_HOME = 'Return Home';
  static SUBMIT = 'Submit';
  /** BUTTON TEXT */

  /** IMAGE PATHS FOR ALL THE FILES*/
  static TIME_OFF_SUCCESS_ICON = './assets/images/icons/profile-complete.png';
  static TIME_OFF_ERROR_ICON = './assets/images/icons/request-error.svg';
  static TIME_OFF_SUMMARY_ICON = './assets/images/icons/Attention.svg';
  static GREEN_CHECK_ICON = '../assets/images/icons/green-check.svg';
  static DENIED_ICON = '../assets/images/icons/denied-Icon.svg';
  static ERROR_ICON = '/assets/images/icons/white-x close.svg';
  static EDIT_ICON = '../../assets/images/icons/Edit.svg';
  static DELETE_ICON = '../../assets/images/icons/Trash.svg';
  static PLUS_ICON = '../../assets/images/icons/PlusIcon.svg';
  static  WARNING_ICON = '../../assets/images/icons/warning-icon.svg';
  /** IMAGE PATHS FOR ALL THE FILES ENDS HERE*/

  /** MODAL */
  static CANCEL_MODAL = 'cancelModal';
  static CANCEL_MODAL_TITLE = 'Are you sure you want to exit?';
  static CANCEL_MODAL_PARA = 'If you made any changes, they won\'t be saved.';
  /** MODAL */

  static NOT_SELECTED = 'notSelected';
  static APPOINTMENT_TXT = 'Appointment';
  static FLARE_UP_TXT = 'Flare-up';
  static AM = 'AM';
  static PM = 'PM';
  static AM_PM = 'AM/PM';
  static CHARACTERS_REMAIN = 'Characters Remaining ';
};
