
import {of as observableOf,  Observable } from 'rxjs';

/**
 * Created by AC12256 on 12/15/2017.
 */
import {Injectable, OnInit} from '@angular/core';




import {SYSALERTMOCK} from '../../../mock-data/mock.sysDownAlert';
import {env} from '../../../../environments/environment-loader';
import {MockLeavePlanFAQ} from '../../../mock-data/mock-faqs';
import {PAYMENT_HISTORY_MOCK_SERVICE_RESPONSE, SINGLE_PAYMENT_DETAILS_MOCK} from '../../../mock-data/mock.payments';
import {PayeeDetails, PayeeDetailsResponse} from '../../../shared/models/Payment.model';
import {MOCK_PAYEETYPE_RESPONSE} from '../../../mock-data/mock.payeeType';
import {UpdateReceivedDateResponse, UpdateReceivedDateResponseAA} from '../../../shared/models/updateReceived.model';
import {ClaimHandlerMockContent} from '../../../mock-data/claimerHandler-mock-content';
import {DisabilityClaimContacts} from '../../../mock-data/mock-contact';
import {ENROLLDEPOSIT} from '../../../mock-data/mock.enrolldeposit';
import {BANKDETAILS} from '../../../mock-data/mock.bankDetails';
import {MOCK_PROVISIONS} from '../../../mock-data/mock-provisions';
import {POLICYLEAVE} from '../../../mock-data/mock-policyAdmin';
import {MISSING_ITEMS_OWCS_RESPONSE_MOCK} from '../../../mock-data/mock.missing-Items';
import {NOTIFICATIONS} from '../../../mock-data/mock-notifications';
import {TNCResponses} from '../../../mock-data/mock-termsAndConditions';
import {phaOverview} from '../../../mock-data/mock.phaClaim';
import {PHARecordsAvailability} from '../../../shared/models/pha.model';
import {MudiCoverageMockContent} from '../../../mock-data/mock-mudi-coverage';
import {PostLoginProducts, SuccessorProducts} from '../../../mock-data/mock-product';
import {profileSync} from '../../../shared/models/profileSync.model';
import {resolvedInfo} from '../../../mock-data/resolvedInformation-mock';
import {LEAVEREED} from '../../../mock-data/mock.leave-reed';
import {ssoRegistration} from '../../../shared/models/ssoRegistration.model';
import {responsePHADetails} from '../../../mock-data/mock.phaDetailResponse';
import {USERS} from '../../../mock-data/mock-people';
import {User} from '../../../shared/models/user.model';
import {StorageManagementService} from '../../../shared/services/storage-management.service';
import {Policies} from '../../../mock-data/mock.claimWizard';
import {SiteMapMock} from '../../../mock-data/mock-sitemap';
import {OWCSContents} from '../../../mock-data/mock-owcsData';
import {ResetPasswordResp} from '../../../mock-data/mock-resetpassword';
import {DocSuccessXMLResponse, MOCK_DOCUMENT_SEARCH_RESPONSE, documentsclaim} from '../../../mock-data/mock.claim.documents';
import {leaveLibraryForm} from '../../../mock-data/mock.leaveFormlibrary';
import {PolicyAdminCoverageMockContent} from '../../../mock-data/mock-policy-admin-coverage';
import {GB_MOCK_ORCHESTRATION, MOCK_GB_LEAVE_SCHEDULE_DETAIL} from '../../../mock-data/mock-claimDetails';
import {
  MOCK_GB_LEAVE_HISTORY,
  MOCK_GB_LEAVE_OVERVIEW,
  MOCK_GB_LEAVE_PLAN_DETAIL
} from '../../../mock-data/mock-gbLeave';
import {MOCK_GB_LEAVE_DETAIL} from '../../../mock-data/mock-gbLeaveDetail';
import {MOCK_CLAIM_ACTIVITY_HISTORY} from '../../../mock-data/mock-claimActivityHistory';
import {MOCK_LETTERS_LIST} from 'app/mock-data/mock.letterList';
import {AETNA_CLAIMS_CONTACTS} from '../../../mock-data/mock-AetnaContacts';
import {CLAIM_REPRESENTATIVE_DETAILS} from '../../../mock-data/mock-claimRepresentativeDetails';
import {
  MOCK_ABSENCE_LIST_OVERVIEW,
  MOCK_COMPANY_DETAILS,
  MOCK_NTERMITTENT_LEAVE_SCHEDULE,
  MOCK_WORK_SCHEDULE
} from '../../../mock-data/mock-intermittentAbsences';
import {AETNA_PAYMENTS} from '../../../mock-data/mock.AetnaPayments';
import {recentlyResolvedAetna} from '../../../mock-data/mock.recentlyResolvedAetnaClaim';
import {MOCK_LEAVE_DETAILS_LIST} from '../../../mock-data/mock-leave-details-list';
import {AACLAIMACTIVITY} from '../../../mock-data/mock-AAClaimActivity';
import {ADACLAIMACTIVITY} from '../../../mock-data/mock-ADAClaimActivity';
import {AASCHEDULEDCALL} from '../../../mock-data/mock-aaScheduledCall';
import {MOCK_LETTER_IMAGE_CODE} from '../../../mock-data/mock.letterImageCode';
import {CIAMACCOUNT} from '../../../mock-data/mock.ciamAccount';
import {AACANCELSCHEDULEDCALL} from '../../../mock-data/mock-aaCancelScheduledCall';
import {
  AABONDINGRESPONSE, MOCK_ADA_ACCOMMODATION,
  MOCK_CARE_FOR_SOMEONE,
  MOCK_EEIllness,
  MOCK_MATERNITY,
  MOCK_OTHER
} from '../../../mock-data/mock-leave-intake';
import {MOCK_LEAVE_REASON} from '../../../mock-data/mock-leave-reason';
import {PLAN_BALANCES} from '../../../mock-data/mock-planBalances';
import {TAX_FORM_MOCKDATA} from '../../../mock-data/mock.taxForms';
import {MOCK_CII_FEATURE} from '../../../mock-data/mock.CIIFeature';
import {MOCK_NOTIFICATION_PREFERENCES} from '../../../mock-data/mock-thaaNotificationPreferences';
import { MOCK_THAA_CONFIRMATION } from 'app/mock-data/mock-thaaConfirmation';
import {MOCK_CONTINUOUS_RAE} from '../../../mock-data/mock.continuousRAE';
import {MOCK_WORKSCHEDULE_EMPLOYEE} from '../../../mock-data/mock-workScheduleEmployee';
import {MISSINGINFO_THAA_MOCK} from '../../../mock-data/mock-missingInfoThaa';
import {MOCK_APS_FORM} from '../../../mock-data/mock.APSForm';
import {MOCK_ESIGN_FORM} from '../../../mock-data/mock.eSignForm';
import {MOCK_MISSINFO_FORM} from '../../../mock-data/mock.MissingInfoDownLoadForm';
import {MOCK_CLAIM_ADDITIONAL_DETAILS} from '../../../mock-data/mock.claims-additional-details';
import {MOCK_FUTURE_PAYMENTS} from '../../../mock-data/mock.futurePayments';
import {MOCK_FUTURE_PAYMENTS_EXIST} from '../../../mock-data/mock.futurePaymentsExist';
import {REPORTED_RETURN_TO_WORK} from '../../../mock-data/mock.reportedReturnToWork';
import {ADA_RESPONSE} from '../../../mock-data/mock.adaResponse';
import { MOCK_NEW_EXTENSION } from 'app/mock-data/mock.NewExtension';
import {MOCK_ADA_TIMELINE} from '../../../mock-data/mock-adaTimeline';
import {MOCK_ADA_TIMELINE_DETAILS} from '@app/mock-data/mock-adaTimelineDetails';
import { MOCK_ICD_CPT_LIST } from '@app/mock-data/mock-diagnosis-icd-cpt';
import { HEALTH_PROVIDER } from '@app/mock-data/mock-health-provider';
import { MOCK_PRODUCT_FORM_LIST } from '@app/mock-data/mock.formslist';
import { MOCK_PRODUCTFORM_TOGGLE } from '@app/mock-data/mock.productform-toggle';
import {MOCK_PRODUCT_FORM} from '@app/mock-data/mock.productForm';
import {MOCK_SPECIALTY_LIST} from '../../../mock-data/mock.specialtyList';
import {MOCK_STATE_LIST} from '../../../mock-data/mock.stateList';
import {MOCK_PAYSTUB} from '@app/mock-data/mock.paystub';
import {MOCK_EMPLOYEE_TOGGLE} from '@app/mock-data/mock.employeeToggle';
import { MOCK_NEW_RAE_SUBMIT } from '@app/mock-data/mock-rae-final-submit-response';
import {MOCK_ABSENT_REASON_LIST} from '@app/mock-data/mock-absent-reason-list';
import {TAXFORM_PHONE_NUMBER_MOCKDATA} from '@app/mock-data/mock.taxForms.phoneNumber';
import { MOCK_INTAKE_TOGGLE } from '@app/mock-data/mock.intakeToggle';
import {MOCK_OVERPAYMENT_ELIGIBILITY} from '@app/mock-data/mock.overpaymentEligibility';
import {MOCK_OVERPAYMENTS} from '@app/mock-data/mock.Overpayments';
import { ZIP_CODE } from '@app/mock-data/mock-zip-code';
import {MOCK_INTAKE_CONTACT_INFO} from '@app/mock-data/mock-contact-info';
import {MOCK_IFRAME} from '@app/mock-data/mock.tempIFrame';
import { MOCK_PREFERRED_NAME } from '@app/mock-data/mock-thaapreferredname';
import { MOCK_INTAKE_HEALTH_PROVIDER } from '@app/mock-data/mock-healthProvider';
import { MOCK_INSURANCE_CARRIER } from '@app/mock-data/mock-insuranceCarrier';
import { MOCK_THAAADAINTERMITTENTREQUESTDETAILVIEW } from '@app/mock-data/mock-thaaADAIntermittentRequestDetailView';
import {MOCK_CPT_DETAILS} from '@app/mock-data/mock-cpt-details';
import {MOCK_ICD_DETAILS} from '@app/mock-data/mock-icd-details';
import { MOCK_PROVIDER_RESPONSE } from '@app/mock-data/mock-provider-response';


@Injectable()

export class FakeService implements OnInit {

  private user: User;
  private siteMap: any;
  private urlGroup: string[];
  private urlGroupEndPoint: string;

  public owcsMapper = {
    1444615948971: 'Claim_Details',
    1444616046003: 'Recently_Resolved_Items'
  };

  constructor(
    private storage: StorageManagementService
  ) {
    this.getPeopleForLogin();
  }

  ngOnInit() {
    this.getPeopleForLogin();
  }

  getMockResponse(url: string, method: string): any {
    return observableOf(this.serviceFiltering(url, method));
  }

  serviceFiltering(url: string, method: string): any {
    let contentToReturn = '';
    this.urlDecoder(url);
    switch (this.urlGroup[0]) {
      case 'gb': contentToReturn = this.externalResponses(this.urlGroup, this.urlGroupEndPoint, method); break;
      default: contentToReturn = this.internalResponses(this.urlGroup, this.urlGroupEndPoint);
    }

    if (!contentToReturn) {
      contentToReturn = 'none';
    }
    return contentToReturn;
  }
  urlDecoder(url: string) {
    /* Step 1 - Remove the base url & anything after a '?'*/
    const baseUrlLength = env.myBaseUrl.length;
    const urlToSplice = (url.slice(baseUrlLength)).split('?')[0];
    //console.log("url to splice; " + urlToSplice);
    /* Step 2 - Remove any chance of there being an empty array element at the end*/
    const splicedUrl = urlToSplice.split('/');
    if (splicedUrl[splicedUrl.length - 1].length === 0) {
      splicedUrl.splice(splicedUrl.length - 1, 1);
    }
    //console.log("spliced; " + splicedUrl);;
    /* Step 3 - Remove the UWS if unauth */
    if (splicedUrl[0] === 'uws') {
      splicedUrl.splice(0, 1);
    }
    /* Step 4 - Assign the proper values */
    this.urlGroup = splicedUrl;
    this.urlGroupEndPoint = splicedUrl[splicedUrl.length - 1 ];
  }
  /** Switch Case for mybenefits actions */
  internalResponses(urlToSplice: string[], urlEndPoint) {
    switch (urlToSplice[0]) {
      case 'gb-content-service' : return this.contentServiceResponses(urlEndPoint);
      case 'gb-mudi-service' : return this.mudiServiceResponses(urlEndPoint);
      case 'gb-claims-service' : return this.claimServiceResponses(urlEndPoint);
      case 'gb-log-service' : return this.logServiceResponses(urlEndPoint);
      case 'gbrules' : return this.rulesServiceResponses(urlEndPoint);
      case 'gb-bankvalidation-service' : return this.bankValidationResponses(urlEndPoint);
      case 'gb-policy-service' : return this.policyServiceResponses(urlEndPoint);
      case 'gb-econsent-service' : return this.econsentServiceResponses(urlEndPoint);
      case 'gb-pha-service' : return this.phaServiceResponses(urlEndPoint);
      case 'gb-profile-sync-service' : return this.profileSyncServiceResponses(urlEndPoint);
      case 'gb-sso-registration-service' : return this.ssoRegistrationServiceResponses(urlEndPoint);
      case 'gb-registration-service' : return this.registrationServiceResponses(urlEndPoint);
      case 'gb-case-association-service' : return this.caseAssocServiceResponses(urlEndPoint);
      /*case 'gb-orch-service': return this.claimorchServiceResponses(urlEndPoint);*/
      case 'gb-orch-service' : return this.ORCHServiceResponse(urlEndPoint);
      case 'sysDownMessage' : return this.contentServiceResponses(urlEndPoint);
      default: return 'none';
    }
  }
  /** Switch Case for external to MyBenefits */
  externalResponses(urlToSplice: string[], urlEndPoint, method) {
    switch (urlToSplice[1]) {
      case 'ciam' : return this.gbCiamServiceResponses(urlEndPoint);
      case 'ecm' : return this.documentumServiceResponses(urlEndPoint);
      case 'WKABPortalService' : return this.WKABPortalServiceResponses(urlEndPoint);
      case 'thaaApi' : return this.THAAAPIServiceResponses(urlToSplice[3], urlEndPoint, method);
      case 'net' : return (urlToSplice[2] === 'employee' ? this.THAAAPIEmployeeServiceResponses(urlEndPoint) : urlToSplice[2] === 'alerts' ? this.THAAAPIAlertServiceResponses(urlEndPoint) : this.THAAAPIServiceResponses(urlToSplice[3], urlEndPoint, method));
      case 'gb-email-service' : return this.emailServiceResponse(urlEndPoint);
      case 'gb-leave-service' : return this.leaveServiceResponses(urlEndPoint);
      case 'gb-thaw-db-service' : return this.THAWDBServiceResponse(urlEndPoint);
      default: return 'none';
    }
  }

  claimorchServiceResponses(urlEndPoint) {
    switch (urlEndPoint) {

      case 'claims': return GB_MOCK_ORCHESTRATION;
      case 'getLeaveSchedule': return MOCK_GB_LEAVE_SCHEDULE_DETAIL;
    }
  }
  THAWDBServiceResponse(urlEndPoint: string) {
    switch (urlEndPoint) {
      case 'eftPref' : return true;
    }
  }
  ORCHServiceResponse(urlEndPoint: string) {
    switch (urlEndPoint) {
      case 'claims' : return GB_MOCK_ORCHESTRATION;
      case 'direct-deposit' : return BANKDETAILS;
    }
  }

  leaveServiceResponses(urlEndPoint: string) {
    switch (urlEndPoint) {
      case 'InsuredPartyLeavesOverview' : return MOCK_GB_LEAVE_OVERVIEW;
      case 'InsuredLeaveDetail' : return MOCK_GB_LEAVE_DETAIL;
      case 'InsuredLeaveHistory' : return MOCK_GB_LEAVE_HISTORY;
      case 'leavePlanDetails' : return MOCK_GB_LEAVE_PLAN_DETAIL;
      case 'intermittent' : return MOCK_ABSENCE_LIST_OVERVIEW;
    }
  }


  WKABPortalServiceResponses(urlEndPoint: string) {
    switch (urlEndPoint) {
      case 'GetAetnaContact' : return AETNA_CLAIMS_CONTACTS;
      case '16015348' : return CLAIM_REPRESENTATIVE_DETAILS;
      case '100023' : return CLAIM_REPRESENTATIVE_DETAILS;
      case 'GetClaimRepresentativeDetails' : return CLAIM_REPRESENTATIVE_DETAILS;
      case '100013' : return CLAIM_REPRESENTATIVE_DETAILS;
      case '100028' : return CLAIM_REPRESENTATIVE_DETAILS;
      case '100023' : return CLAIM_REPRESENTATIVE_DETAILS;
      case '100024' : return CLAIM_REPRESENTATIVE_DETAILS;
      case '100040' : return CLAIM_REPRESENTATIVE_DETAILS;
      case 'icd-cpt-codes' : return MOCK_ICD_CPT_LIST;
      case 'provider' : return HEALTH_PROVIDER;
      case 'claim-handler' : return CLAIM_REPRESENTATIVE_DETAILS;
      case 'v2' : return MOCK_NEW_RAE_SUBMIT;
      case 'zipcode-lookup' : return ZIP_CODE;
      case 'state' : return MOCK_STATE_LIST;
    }
  }

  THAAAPIServiceResponses(urlStartPoint: string, urlEndPoint: string, method) {
    switch (urlEndPoint) {
      case 'contact' : return AETNA_CLAIMS_CONTACTS; //contacts url for getAetnaContact
      case 'letters' : return MOCK_LETTERS_LIST;
      case 'letter' : return MOCK_LETTER_IMAGE_CODE;
      case 'aps': return MOCK_APS_FORM;
      case 'payments' : return AETNA_PAYMENTS;
      case 'handler' : return CLAIM_REPRESENTATIVE_DETAILS;
      case 'recently-resolved-items' : return recentlyResolvedAetna;
      case 'future' : return MOCK_FUTURE_PAYMENTS;
      case 'exists' : return MOCK_FUTURE_PAYMENTS_EXIST;
      case 'activities' : return (urlStartPoint.toLowerCase() === 'leave' ) ? ADACLAIMACTIVITY : AACLAIMACTIVITY;
      case 'appointment' : return AASCHEDULEDCALL;
      case 'submit' : return AASCHEDULEDCALL;
      case 'cancel' : return AACANCELSCHEDULEDCALL;
      case 'InsuredPartyLeavesOverview' : return MOCK_GB_LEAVE_OVERVIEW; //leave url -- start
      case 'InsuredLeaveDetail' : return MOCK_GB_LEAVE_DETAIL;
      case 'InsuredLeaveHistory' : return MOCK_GB_LEAVE_HISTORY;
      case 'leavePlanDetails' : return MOCK_GB_LEAVE_PLAN_DETAIL;
      case 'detail' : return(urlStartPoint === 'leave' ?  MOCK_LEAVE_DETAILS_LIST : (urlStartPoint === 'company' ? MOCK_COMPANY_DETAILS : MOCK_CLAIM_ADDITIONAL_DETAILS));
      case 'leave' : return MOCK_ABSENCE_LIST_OVERVIEW; //leave url -- end/
      case 'detail' : return MOCK_LEAVE_DETAILS_LIST;
      case 'intermittent-leave-schedule' : return MOCK_NTERMITTENT_LEAVE_SCHEDULE;
      case 'claims-intake' : return this.getReasonSpecificResponse();
      case 'covered-reason' : return MOCK_LEAVE_REASON;
      case 'update-claim-surgery-attribute' : return {statusCode: 0};
      case 'update-claim-delivery-attribute' : return {statusCode: 0};
      case 'update-claim-disability-attribute' : return {statusCode: 0};
      case 'update-claim-attribute' : return {statusCode: 0};
      case 'balance' : return PLAN_BALANCES;
      case 'taxforms' : return TAX_FORM_MOCKDATA;
      case 'phone-number' : return TAXFORM_PHONE_NUMBER_MOCKDATA;
      case 'work-schedule': return MOCK_WORK_SCHEDULE;
      case 'missing-info': return (method === 'POST' ? this.recivedDateAA()  : MISSINGINFO_THAA_MOCK);
      //case 'rights': return MOCK_CII_FEATURE;
      //case 'preference': return MOCK_NOTIFICATION_PREFERENCES;
      case 'extension': return this.getRequest();
      case 'confirmation': return MOCK_THAA_CONFIRMATION;
      case 'continuous': return MOCK_CONTINUOUS_RAE;
      case 'ReportedReturnToWork' : return REPORTED_RETURN_TO_WORK;
      case 'accommodation' : return ADA_RESPONSE;
      case 'newExtension' : return MOCK_NEW_EXTENSION;
      case 'timeline' : return MOCK_ADA_TIMELINE_DETAILS;
      case 'ADAIntermittentRequestDetailView' : return MOCK_THAAADAINTERMITTENTREQUESTDETAILVIEW;
      case 'icd-cpt-codes' : return MOCK_ICD_CPT_LIST;
      case 'provider' : return HEALTH_PROVIDER;
      case 'product-forms' : return MOCK_PRODUCTFORM_TOGGLE;
      case 'forms-list' : return MOCK_PRODUCT_FORM_LIST;
      case 'download' : return MOCK_PRODUCT_FORM;
      case 'specialty' : return MOCK_SPECIALTY_LIST;
      case 'state' : return MOCK_STATE_LIST;
      case 'paystub' : return MOCK_PAYSTUB;
      case 'employee' : return MOCK_EMPLOYEE_TOGGLE;
      case 'intake' : return MOCK_INTAKE_TOGGLE;
      case 'v2' : return (urlStartPoint.toLowerCase() === 'covered-reason' ? MOCK_ABSENT_REASON_LIST  : MOCK_NEW_RAE_SUBMIT);
      case 'contact-info' : return  MOCK_INTAKE_CONTACT_INFO;
      case 'overpayment-eligible' : return MOCK_OVERPAYMENT_ELIGIBILITY;
      case 'overpayment' : return MOCK_OVERPAYMENTS;
      case 'zipcode-lookup' : return ZIP_CODE;
      case 'payment': return MOCK_IFRAME;
      case 'npi': return MOCK_INTAKE_HEALTH_PROVIDER;
      case 'insurance-carrier': return MOCK_INSURANCE_CARRIER;
      case 'cpt': return MOCK_CPT_DETAILS;
      case 'icd': return MOCK_ICD_DETAILS;
      case 'fetchproviderinfo': return MOCK_PROVIDER_RESPONSE;
      //case 'ESign': return MOCK_ESIGN_FORM;
    }
  }

  THAAAPIEmployeeServiceResponses(urlEndPoint: string) {
    switch (urlEndPoint) {
      case 'rights': return MOCK_CII_FEATURE;
      case 'preference': return MOCK_NOTIFICATION_PREFERENCES;
      case 'work-schedule' : return MOCK_WORKSCHEDULE_EMPLOYEE;
      case 'preferredName' : return MOCK_PREFERRED_NAME;
      case 'ada-timeline' : return MOCK_ADA_TIMELINE;
    }
  }
  THAAAPIAlertServiceResponses(urlEndPoint: string) {
    switch (urlEndPoint) {
      case 'ecosign-url': return MOCK_ESIGN_FORM;
      case 'form-download': return MOCK_MISSINFO_FORM;
    }
  }
  getReasonSpecificResponse() {
    const reasonSelected = this.storage.getSessionData('absenceReason');
    switch (reasonSelected.Reason_KeyId) {
      case '3' : return MOCK_CARE_FOR_SOMEONE;
      case '4' : return AABONDINGRESPONSE;
      case '1' : return MOCK_EEIllness;
      case '2' : return MOCK_MATERNITY;
      case '5' : return MOCK_OTHER;
      case '15': return MOCK_ADA_ACCOMMODATION;
    }
    //return MOCK_LEAVE_INTAKE;
  }
  documentumServiceResponses(urlEndPoint: string) {
    switch (urlEndPoint) {
      case 'search' : return MOCK_DOCUMENT_SEARCH_RESPONSE;
      case 'view' : return this.documentumView('0001');
      case 'eaupload' : return DocSuccessXMLResponse;

    }
  }

  caseAssocServiceResponses(urlEndPoint: string) {
    switch (urlEndPoint) {
      case 'cases' : return this.getCaseAssoc();
      case 'defaultCase' : return [{}];

    }
  }

  gbCiamServiceResponses(urlEndPoint: string) {
    switch (urlEndPoint) {
      case 'account' : return CIAMACCOUNT;
      case 'user' : return ResetPasswordResp;
    }
  }

  profileSyncServiceResponses(urlEndPoint: string) {
    switch (urlEndPoint) {
      case 'profile' : return new profileSync();
    }
  }

  registrationServiceResponses(urlEndPoint: string) {
    switch (urlEndPoint) {
      case 'claimWizard' : return Policies;
    }
  }


  ssoRegistrationServiceResponses(urlEndPoint: string) {
    switch (urlEndPoint) {
      case 'ssoRegistration' : return ssoRegistration;
    }
  }

  rulesServiceResponses(urlEndPoint: string) {
    switch (urlEndPoint) {
      case 'faq' : return MockLeavePlanFAQ;
      case 'postAuthProduct' : return SuccessorProducts;
      case 'claimsOverview' : return PostLoginProducts;
    }
  }

  logServiceResponses(urlEndPoint: string) {
    switch (urlEndPoint) {
      case 'Log' : return [{}];
    }
  }

  policyServiceResponses(urlEndPoint: string) {
    switch (urlEndPoint) {
      case 'provisions' : return MOCK_PROVISIONS;
      case 'leaveService' : return POLICYLEAVE;
      case 'coveragePlans' : return PolicyAdminCoverageMockContent; //PolicyAdminCoverageMockContent
      case 'claimWizard' : return Policies;
    }
  }

  bankValidationResponses(urlEndPoint: string) {
    switch (urlEndPoint) {
      case 'ValidateBankAba' : return BANKDETAILS;
    }
  }

  phaServiceResponses(urlEndPoint: string) {
    switch (urlEndPoint) {
      case 'coverage-overview' : return phaOverview;
      case 'coverage-details' : return responsePHADetails;
    }
  }

  econsentServiceResponses(urlEndPoint: string) {
    switch (urlEndPoint) {
      case 'getProfile' : return NOTIFICATIONS;
      case 'updateProfile' : return true;
      case 'addProfile' : return true;
    }
  }

  contentServiceResponses(urlEndPoint: string) {
    switch (urlEndPoint) {
      case 'sysMessage' : return SYSALERTMOCK[0];
      case 'sysDownMessage' : return SYSALERTMOCK[0];
      case 'sites' : return SiteMapMock;
      case 'formName=mi' : return MISSING_ITEMS_OWCS_RESPONSE_MOCK;
      case 'formName=ll' : return leaveLibraryForm;
      case 'byid' : return OWCSContents;
      default : {
        return this.getContentById(urlEndPoint);
      }
    }
  }

  mudiServiceResponses(urlEndPoint: string) {
    switch (urlEndPoint) {
      case 'updateTextingTermsAndConditions' : return true;
      case 'notificationLogPreferences' : return true;
      case 'verifyTextingTermsAndConditions' : return TNCResponses[0];
      case 'checkPHAAvailability' : return new PHARecordsAvailability();
      case 'coverages' : return MudiCoverageMockContent.coverageResponseList[0];
      case 'linkType' : return {spErrorCode: 0};
      case 'user' : return this.getMyUser('00000');

      default : break;
    }

  }

  claimServiceResponses(urlEndPoint: string) {
    switch (urlEndPoint) {
      case 'claimPayments' : return PAYMENT_HISTORY_MOCK_SERVICE_RESPONSE;
      case 'claimPaymentDetails' : return SINGLE_PAYMENT_DETAILS_MOCK;
      case 'payeeDetails' : return this.payeeDetailsMock();
      case 'payeeType' : return MOCK_PAYEETYPE_RESPONSE;
      case 'receivedDate' : return this.recivedDate();
      case 'claimHandlers' : return ClaimHandlerMockContent;
      case 'claimOfficePhoneNumbers' : return DisabilityClaimContacts;
      case 'claimActivityDetail' : return MOCK_CLAIM_ACTIVITY_HISTORY;
      case 'DeleteBankingInformation' : return true;
      case 'AddBankingInformation' : return true;
      case 'GetBankingInformation' : return ENROLLDEPOSIT[0];
      case 'ModifyBankingInformation' : return ENROLLDEPOSIT[0];
      case 'eftPref' : return true;
      case 'IsNotification' : return {notificationExempt : false};
      case 'recentlyResolvedItem' : return resolvedInfo;
      case 'leaves' : return LEAVEREED;
      case 'leaveSsoUrl' : return true;
      case 'GetAddress' : return this.mockAddress();
      case 'UpdateClaimActivity': return {statusCode: '0'};
    }
    return false;
  }
  emailServiceResponse(urlEndPoint: string) {
    return true;
  }

  mockAddress() {
    const mockAddress = {
      profileId: null,
      insuredId : null,
      addressLine1 :  'Wonderland' ,
      addressLine2 : 'Chocolate Factory',
      city :  'Wakanda' ,
      stateCode :  'OK' ,
      zipCode :  1234 ,
      countryId : 5678 ,
      provinenceCode : null,
      countryCode : null
    };
    return mockAddress;
  }

  getRequest() {
    const getRequest = {
      statusCode: '0',
      statusMessage: 'success',
      form: '02',
      responseCode: '0',
      responseReason: '0'
    }
    return getRequest
  }

  recivedDate() {
    const mockResponse: UpdateReceivedDateResponse = {
      errorList: null,
      statusCode: '0',
      statusMsg: 'SUCCESSFULLY UPDATED REQUIRED FIELDS'
    };
    return mockResponse;
  }

  recivedDateAA() {
    const mockResponse: UpdateReceivedDateResponseAA = {
      statusCode: '0',
      statusMessage: 'SUCCESSFULLY UPDATED REQUIRED FIELDS',
      form: 'Gap List',
      responseCode: '0',
      responseReason: '0'
    };
    return mockResponse;
  }

  payeeDetailsMock() {
    const mockPayeeDetailsResp = new PayeeDetailsResponse();
    const mockClaimPayeeDetails = new PayeeDetails();
    mockClaimPayeeDetails.address1 = '14 Street, Taj Hotel';
    mockClaimPayeeDetails.address2 = 'Nariman Point';
    mockClaimPayeeDetails.city = 'Mumbai';
    mockClaimPayeeDetails.state = 'Maharashtra';
    mockClaimPayeeDetails.zipCd = '400021';
    const mockClaimPayeeDetailsList: PayeeDetails[] = [];
    mockClaimPayeeDetailsList[0] = mockClaimPayeeDetails;
    mockPayeeDetailsResp.claimPayeeDetailsResList = mockClaimPayeeDetailsList;
    return mockPayeeDetailsResp;
  }

  getNamesOnly(users: User[]) {
    const peopleNames = users.map(function(obj: User) {
      return obj.firstname + ' ' + obj.lastName;
    });
    return peopleNames;
  }

  getPeopleForLogin() {
    //console.log('Stating people for login');
    this.storage.setSessionObject('people', USERS[0]);
    const peopleNames = USERS.map(function(obj: User) {
      return obj.firstname + ' ' + obj.lastName;
    });
    this.storage.setSessionObject('peopleNames', peopleNames);
    //return of(NEWPEOPLE);
  }

  getPageId() {
    const pageName = 'mypage';
    this.siteMap = SiteMapMock;
    if (this.siteMap && this.siteMap.data && this.siteMap.data.sitemap) {
      for (let i = 0; i < this.siteMap.data.sitemap.length; i++) {
        if (this.siteMap.data.sitemap[i].pagename === pageName) {
          return this.siteMap.data.sitemap[i].id;
        }
      }
    }
  }

  getCaseAssoc() {
    const cases =  {errorList: null, userId: '', sessionIdentifier: '', defaultCaseId: '1173313', hasThaa: true, defaultPartyRefId: '1330345', newCaseIndicator: 'N', ssoOnlyCaseIndicator: 'N', excludedCaseIndicator: 'N', hasDcsClaims: false, hasThaaClaims: true, hasReedLeave: false, hasThaaLeave: true, cases: [{caseId: '904347', caseName: 'ELIG_BAS_ABIL_NST_STAT_SUPP_FLEX', partyRefId: '1327551', defaultCase: false, sourcePartyDetailsList: [{partyId: 1327551, srcPartyName: 'DCS Claims', srcSystemCd: 1, workStateCd: 0, stateCode: null, linkTypeCd: 1, empId: null, empVersionId: 0, empEffDt: null, srcSysId: '9003249397'}]}, {caseId: '1173313', caseName: 'THE LEUKEMIA & LYMPHOMA SOCIETY', partyRefId: '1330345', defaultCase: true, sourcePartyDetailsList: [{partyId: 1330345, srcPartyName: 'THAA', srcSystemCd: 8, workStateCd: 0, stateCode: null, linkTypeCd: 1, empId: null, empVersionId: 0, empEffDt: null, srcSysId: '7067097'}]}, {caseId: '1173313', caseName: 'THE LEUKEMIA & LYMPHOMA SOCIETY', partyRefId: '1327551', defaultCase: false, sourcePartyDetailsList: [{partyId: 1327551, srcPartyName: 'DCS Claims', srcSystemCd: 1, workStateCd: 0, stateCode: null, linkTypeCd: 1, empId: null, empVersionId: 0, empEffDt: null, srcSysId: '9003249397'}]}]}
    return cases;
  }

  getMyUser(partyRefId) {
    return USERS[0]; //(NEWPEOPLE.filter((user) => {if (user.partyRefId === partyRefId) { return user }})) [0]
  }

  documentumView(documentId) {
    if (documentId === '0001') {
      window.open('/assets/documents/Sample-Img.png');
    } else if (documentId === '0002') {
      window.open('/assets/documents/Sample-PDF.pdf');
    } else if (documentId === '0003') {
      window.open('/assets/documents/Sample-Img-two.img');
    } else if (documentId === '0004') {
      window.open('/assets/documents/Sample-PDF-two.pdf');
    }
    return null;
  }

  getPageNameFromId(id: string): string {
    let filteredPageMaps = [];
    filteredPageMaps = SiteMapMock.data.sitemap.filter((pageMap) => (pageMap.id === id));
    return filteredPageMaps.length > 0 ? filteredPageMaps[0].pagename : '';
  }

  getContentById(id: string): string {
    const pageName: string = this.getPageNameFromId(id).replace(/\s/g, '_');
    //console.log('Content From Id ' + id + ' is: ', pageName ? OWCSContents[pageName] : '');
    return pageName ? OWCSContents[pageName] : '';
  }

}
