import { Component, Input, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import {CustomEventService} from '@shared/services/tealium/custom-event.service';
@Component({
  selector: 'popover',
  templateUrl: './popover.component.html',
  styleUrls: ['./popover.component.scss']
})
export class PopoverComponent implements OnInit {
  @Output() active: boolean = false;
  @Input() isFuturePayment = false;
  @Input() popoverName: string;
  @Input() popoverTitle: string;
  @Input() popoverText: string;
  @Input() hideCloseButton: string;
  @Input() isClaim: boolean = false;
  @Input() isEmailInfoIcon: boolean = false;
  @Input() closeButtonId: string = '';

  constructor(private router: Router,
              private customEventService: CustomEventService
              ) {}

  ngOnInit() {}

  onKeydownHandler(event: KeyboardEvent) {
   if (event.key === 'Enter') {
      this.active = !this.active;
    }
  }

  redirectContactUs() {
    this.router.navigateByUrl('contactUs');
  }

  togglepopOver()  {
    this.active = true;
  }

  closePopOver() {
    this.active = false;
    this.customEventService.trackWatchNowButton('CI-leave time modal close', 'CI-leave time modal close');
  }

}
