import { AppLabelConstants } from '@shared/constants/appLabel.constants';
import { IntakeSessionConstants } from './intakeSession.constants';
export class Intake_Constants {
  static INTAKE_FORMAT: string[] = ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9', '!', '@', '#', '$', '%', '^', '(', ')', '_', '+', '=', '\\', '<', '>', '?', '|', '[', ']'];
  static NUMBER_ONLY: string[] = ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9', 'Backspace', 'Tab'];
  static TEXTAREA_MAX_LENGTH: number = 200;
  static TEXTAREA_COUNT: number = 400;
  static POPOVER_NAME: string = `<img class="tool-tip-con" src="${AppLabelConstants.TOOLTIP_ICON}" alt="Temporary Address">`
  static SEARCH_PROVIDER_MAX: number = 199;
  static RESPONSE_CODE_001: string = '001';
  static PLEASE_SPECIFY = 'Please Specify';
  static PHONE = 'Phone';
  static FAX = 'Fax';
  static GREY_CLASS_NAME = 'grey-text';
  static YES_RADIO_BUTTON = '1001';
  static NO_RADIO_BUTTON = '1002';
  static TEXT_AND_EMAIL_VAL = '1';
  static EMAIL_ONLY_VAL = '2';
  static NO_EMAIL_TEXT_VAL = '3';
  static PAPERLESS_TEXT_VAL = '4';
  static PAPER_MAIL_TEXT_VAL = '5';
  static HOUR_MAX_VAL: string = '24';
  static MINUTE_MIN_VAL: string = '00';
  static TEXT_MAX_LENGTH: number = 250;
  static TEXT_CLAIM_MAX_LENGTH: number = 400;
  static NEXT = 'Next';
  static ABSENT_REASON = 'absentReason';
  static SUB_MATERNITY_REASON = 'subMaternityReason';
  //  register filed ids for json mapping
  static MATERNITY_CLAIM_ID_REG_FIELD_ID = '129264'; //specific to transition to bonding flow
  static BONDING_CLAIM_ID_REG_FIELD_ID = 'TBD'; //specific to extension to bonding flow

  //About your absence Past flow
  static LAST_DAY_WORKED = '49';
  static HOURS_MINUTES_WORKED = '619302';
  static CONTINUE_WORK_PARTIAL_DAYS = '610434';
  static ESTIMATED_RTW_DATE = '67';
  static RETURN_TO_WORK_REGISTER_ID = '44';
  static ABSENCE_DAY_TYPE = '175';
  static GAP_REASON = '605274';
  static RTW_UNKNOWN = '114704';
  static FULL_DAY = '125002';
  static PARTIAL_DAY = '125001';
  static RETURN_TO_WORK = '44';
  static RETURN_Date = '45';
  static INCOME_BENEFITS_REGISTER_ID = '83161';
  static NO_BENFITS_LOOKUP_ID = '1002';
  static MULTI_BENEFITS_LOOKUP_ID = '1001';
  static MULTI_BENEFITS_REGISTER_ID = '608733';
  static OTHER_BENEFITS_REGISTER_ID = '608734';
  static STATE_DISABILITY_LOOKUP_ID = '10026431';
  static SICK_PAY_LOOKUP_ID = '10026432';
  static SALARY_CONTINUATION_LOOKUP_ID = '10026433';
  static PAID_TIME_OFF_LOOKUP_ID = '10026434';
  static OTHER_LOOKUP_ID = '10026437';
  static CODE_TYPE = 'Intake_Leave_Gap_Reasons'
  static CODE_TYPE_STATUTORY_STATE = 'STATUTORY_STATE'
  static ABOUT_OTHER_LOOKUP_ID = '10022018';
  static REASON_LIST = '605274';
  static OTHER_REASON = '96172';
  static MATERNITY_REASON = 'Pregnancy/Maternity';
  static EMP_ELSEWHERE_RID = '619575';
  static MORE_THAN_ONE_EMP = '1664';
  static OTHER_EMPLOYER_RID = '95782';
  static ZIP_CODE_RID = '95824';
  static CITY_RID = '95822';
  static STATE_RID = '95823'
  static WORK_SCHEDULE_RID = '95827';
  static EMPLOYED_ELSE_WHERE ='606095';
  static EMPLOYER_CLAIM ='618253';
  static CLAIM_DROPDOWN ='618254';
  static CLAIM_DOCFORANALYST ='106';
  static PFL_REGISTERED_ID = '1648';
  static PFL_CHECKBOX_REGISTERED_ID = '1666';
  static PFL_FREEFORM_REGISTERED_ID = '1667';
  static STEP_JSON_MATERNITY = [IntakeSessionConstants.INTAKE_FIRST_DAY_OF_ABSENCE,IntakeSessionConstants.STEP_ONE_JSON_INFO,IntakeSessionConstants.STEP_TWO_JSON_INFO,IntakeSessionConstants.STEP_THREE_ABSENCE_INFO,IntakeSessionConstants.STEP_FOUR_JSON_INFO,IntakeSessionConstants.STEP_FIVE_JSON_INFO,IntakeSessionConstants.STEP_SIX_JSON_INFO,IntakeSessionConstants.RELEASE_RECORD_JSON_CLOB];
  static ALERT_NOTIFICATION = 'AlertNotifications';
  static STD_NOTIFICATION = 'RCISTD';
  static LOA_CALC_PARM = 'LOA CALCULATOR';
  static PD_SITTING_TIME ='607634';
  static PD_SITTING_TIME_OPTION = '607635';
  static PD_JOB_REQUIRE = '607653';
  static PD_SUB_JOB_REQUIRE = '607654';
  static PD_HAND_FOOT_MOTIONS = '607694';
  static PD_OCCASIONAL_WORK = "NI_921A_Body";
  static PD_FREQUENT_WORK = "NI_921B_Body";
  static PD_WEIGHT_100_LBS = "10024957";
  static PD_WEIGHT_50_LBS = "10024958";
  static PD_SITTING_VAL = "10024960";
  static PD_SUB_JOB_REG_VAL1 = '10024965';
  static PD_SUB_JOB_REG_VAL2 = '10024963';
  static PHYSICAL_DEMAND = '124682';
  static PRINT= 'Print';
  static DOWNLOAD = 'Download';
  static SUBMIT ='Submit';
  static CONFIRM_SUBMIT = 'Confirm Submit';
  static CLAIM_ID = 'Separate Claim IDs';
  static CLAIM_ID_VALUE = 'Why are there separate Claim IDs'
  static PAST_ABSENCE_HOUR= 'partial PastAbsence Hour';
  static PAST_ABSENCE_MIN= 'partial PastAbsence Minutes';
  static INTAKE_SALARY_UNIT= '125482';
  static FTPTL_LOOKUP= '113917';
  static INTAKE_AYA_INTERMITTENT_TIME ='00'
  //Claim Overview constants
  static CLAIM_OVERVIEW_CO_36 = 'CO_36';
  static INVALID_DATE = "Invalid Date";
  static REASON_ID = 2;
  static RELATIONSHIP_ID = 1;
  static SPOUSE_SHARED_LEAVE_TOGGLE = "Shared Leave Time";

  static DYNAMIC_HOURS = '[Dynamic Value] hours';
  static DYNAMIC_MINUTES = '[Dynamic Value] minutes';

  static BONDING_YES = "AYA_006A_Body";
  static BONDING_NO = "AYA_006B_Body";
  static RTW_BONDING_YES = "AYA_010A_Body";
  static RTW_BONDING_LONG = "AYA_010B_Body";
  static BONDING_YES_LOOKUP = "2001";
  static BONDING_NO_LOOKUP = "2002";
  static BONDING_UNKNOWN_LOOKUP = "2003";
  static RTW_BONDING_YES_LOOKUP = "773100";
  static RTW_BONDING_LONG_LOOKUP = "773102";
  static RTW_BONDING_UNKNOWN_LOOKUP = "773101";
  static LEAVE_TYPE_LOOkUP_CONT = "17001";

  static DEFAULT_VALUE = "0";
  static ABSENCE_TYPE_PARTIAL = "Partial";
  static ABSENCE_TYPE_FULL = "Full";
  static FDA_DATE = "FDA_DATE";
  static MANDATORY_OVERTIME = "MANDATORY_OVERTIME";
  static DATE_FORMAT = "DATE_FORMAT";
  static ABSENCE_TYPE = "ABSENCE_TYPE";
  static TOTAL_SCHEDULE_HOURS = "TOTAL_SCHEDULE_HOURS";
  static SCHEDULE_WORKH_OURS = "SCHEDULE_WORKH_OURS";
  static START_HOURS = "START_HOURS"
  static START_TIME_MIN = "START_TIME_MIN"
  static END_TIME_HOUR = "END_TIME_HOUR";
  static END_TIME_MINUTE = "END_TIME_MINUTE";
  static TIME_UNIT_AM = "AM";
  static TIME_UNIT_PM = "PM";
  static TRUE = "True";
  static FALSE = "False";
  static WORK_DAY = 'Work Day';
  static NON_WORK_DAY = 'Non Work Day';
  static DAY_TYPE = "DAY_TYPE"
  static DOLLAR_SYMBOL = "$";
  static COLLON_SYMBOL = ":";
  static ABSENCE_DATES_AND_TIMES_TEMPLATE = "FDA_DATE|0|START_HOURS$START_TIME_MIN*END_TIME_HOUR$END_TIME_MINUTE|SCHEDULE_WORKH_OURS|DATE_FORMAT|ABSENCE_TYPE";
  static OVER_TIME_INFO_TEMPLATE = "FDA_DATE|MANDATORY_OVERTIME|TOTAL_SCHEDULE_HOURS|START_HOURS$START_TIME_MIN*END_TIME_HOUR$END_TIME_MINUTE|SCHEDULE_WORKH_OURS|DATE_FORMAT|ABSENCE_TYPE|DAY_TYPE";
  static CLAIM_INTAKE_QUESTIONS_DESCRPTION = "Mandatory OT has been reported for the First Day Absent.";
  static NPI_LOCATION = "NY";
  static NPI_FNAME = "*JO";
  static NPI_LNAME = "*JO";
  static NPI_CITY = "New York";
  //Intermittent constants
  static SCHEDULE_HOURS = 23;
  static SCHEDULE_MINS = 59;
  static TOTAL_MINS = 1440;
  static MAX_HOURS_IN_DAY = 24;
  static MAX_MINUTES_IN_HOUR = 59;
  static ABSENCE_FUTURE_CONTROLS = ['partialDayReason','partialDayHr','partialDayMin','lastWordDay','otherReason',
  'hourMinDropDownContinuous','hourMinDropDown','datePickerLastDay','returnReason',
  'estimateReturnToWorkEE','estimateRTW','reasonList','bondingQuestion'];
  static ABSENCE_CONTROLS = ['partialDayReason','partialDayHr','partialDayMin','wrkOnYrAbsenceDay','otherReason',
  'hourMinDropDownContinuous','hourMinDropDown','lastDayWorked','returnReason',
  'estimateReturnToWorkEE','estimateRTW','estimatedReason','RTWDate','estimatedRTW','bondingQuestion']
  static TIME_MINIMUM_VAL = '00';
  static TIME_01_HOUR_VAL = "01";
  static TIME_12_HOUR_VAL = '12';
  static TIME_08_HOUR_VAL = "08:00";
  static TIME_PM_UNIT = 'PM';
  static API_ADDITIONAL_TIME = 1;
  static ROTATING_TYPE = 'Rotating';
  static INTERMITTENT_LEAVE_TYPE = 'IntermittentLeaveType';

}

export enum PARTIAL_ABSENCE_MODAL_RESPONSE { NotAnswered, Yes, No }
