/**
 * Created by Ac12286 on 2/16/2018.
 */

export const responsePHADetails: any = {
  errorList: null,
  userId: null,
  sessionIdentifier: null,
  currentCoverageAmt: '0',
  additionalCoverageAmt: '100000',
  totalCoverageAmt: '100000',
  letterPrcssDt: '2003-03-07',
  letterId: '4019213',
  letterFileName: 'abc.pdf',
  category: 'PHS',
  decisionLetterDate: '2018-02-08',
  enrolleeInsuredId: '1155705',
  enrolleeFirstName: 'Chzzzung',
  enrolleeMiddleName: null,
  enrolleeLastName: 'Sezzzeger',
  coverageOverview: {
  firstName: 'Chzzzung',
    lastName: 'Sezzzeger',
    middleName: null,
    applicationId: '911673',
    applicantId: '15045',
    insuredId: '1155705',
    caseId: '21661',
    applicationRcvdDate: '2003-01-13',
    enrolleeRelationship: 'Self',
    policyId: null,
    coverageCategoryCd: 'LIFE',
    coverageTypeCd: 'BTRM',
    statusCd: '4',
    coverageStatus: 'Incomplete',
    coverageStatusDt: '2004-07-13'
}
};
