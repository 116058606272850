import {AfterViewInit, Component, OnInit} from '@angular/core';
import {ModalService} from '../../../../shared/services/modal.service';
import {Router} from '@angular/router';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {StorageManagementService} from '../../../../shared/services/storage-management.service';
import {SelfLinkingService} from '../../../../shared/services/self-linking.service';
import {ContentService} from '../../../../shared/services/content.service';
import {PersonService} from '../../../../shared/services/people.service';
import {LeaveDynatraceAction} from '../../../../shared/utils/leaveDynatraceAction';
import {CustomEventService} from '../../../../shared/services/tealium/custom-event.service';
import {ErrorMessagingService} from '../../../../shared/services/error-messaging.service';
import {User} from '../../../../shared/models/user.model';
import {AuthStateService} from '../../../../shared/services/auth-state.service';
import {AppConstants} from '../../../../shared/constants/app.constants';
import {AppLabelConstants} from '../../../../shared/constants/appLabel.constants';
import {SessionConstants} from '../../../../shared/constants/session.constants';

@Component({
  selector: 'app-self-linking',
  templateUrl: './self-linking.component.html',
  styleUrls: ['./self-linking.component.scss']
})
export class SelfLinkingComponent extends LeaveDynatraceAction implements OnInit, AfterViewInit {
  public appConstant = AppConstants;
  public appLabelConstant = AppLabelConstants;
  selfLinkingForm: FormGroup;
  private count = 0;
  private insuredId;
  private partyRefId = '';
  private srcPartyId = '';
  private JsonBOM;
  public textInfo = ''; //= 'We need to clear some things up.It seems like we have multiple records for you in our systems.';
  private pageId;
  private pageId1;
  private owcsContent;
  public Popup_Title = '';
  public lblID = '';
  private profileId: string;
  public user: User;
  public personId;
  constructor(private modalService: ModalService,
              private router: Router,
              private formBuilder: FormBuilder,
              private storageManagementService: StorageManagementService,
              private selfLinkingService: SelfLinkingService,
              private contentService: ContentService,
              private customEventService: CustomEventService,
              private authStateService: AuthStateService,
              private errorList: ErrorMessagingService,
              private personService: PersonService) {
  super(storageManagementService);
  }

  ngOnInit() {

    //OWCS Integration
    this.pageId = this.contentService.getPageId(AppConstants.CONFIRM_INSURED_PAGE_ID);
    if (this.pageId) {
      this.populateContent(this.pageId);
    } else {
      this.contentService.getSiteMap().subscribe(
        val => {
          if (val) {
            const siteMapResponse = val;
            this.storageManagementService.setSessionData(SessionConstants.SITE_MAP_DATA, siteMapResponse);
            this.pageId1 = this.contentService.getPageId(AppConstants.CONFIRM_INSURED_PAGE_ID);
            this.populateContent(this.pageId1);
          }
        }, (error) => {
        });
    }

    this.JsonBOM = JSON.parse(this.storageManagementService.retrieveSessionObject(SessionConstants.APP_DATA)).appData.userData;

    this.partyRefId = this.JsonBOM.partyRefId;
    this.profileId = this.JsonBOM.profileId;
    if (this.JsonBOM.sourcePartyDetailsList) {
      for (let i = 0; i < this.JsonBOM.sourcePartyDetailsList.length; i++) {
        if ( this.JsonBOM.sourcePartyDetailsList[i].srcSystemCd === AppConstants.SRC_SYS_CD_1 && this.JsonBOM.sourcePartyDetailsList[i].linkTypeCd === AppConstants.LINK_TYPE_CD_2) {
          this.srcPartyId = this.JsonBOM.sourcePartyDetailsList[i].partyId;
          this.insuredId = this.JsonBOM.sourcePartyDetailsList[i].srcSysId;
        } else if ( this.JsonBOM.sourcePartyDetailsList[i].srcSystemCd === AppConstants.SRC_SYS_CD_8 && this.JsonBOM.sourcePartyDetailsList[i].linkTypeCd === AppConstants.LINK_TYPE_CD_2) {
          this.srcPartyId = this.JsonBOM.sourcePartyDetailsList[i].partyId;
          this.personId = this.JsonBOM.sourcePartyDetailsList[i].srcSysId;
          this.selfLinkingService.personId = this.personId;
        }
      }
    }

      this.selfLinkingForm = this.formBuilder.group(
      {
       insuredId: ['', [Validators.required, Validators.pattern('[0-9]+')]]
      });
  }
  ngAfterViewInit() {
    super.ngAfterViewInit();
  }
  onClaim() {
    this.modalService.openModal(AppLabelConstants.SELF_LINKING_MODAL);
  }

  validateInsuredId(): boolean {
    const srcSysID = this.selfLinkingForm.get(AppConstants.SELF_LINKING_INSURED_ID);
    if (srcSysID.value !== null && srcSysID.value !== undefined) {
      if (srcSysID.value === this.insuredId || srcSysID.value === this.personId) {
        this.storageManagementService.setSessionData(SessionConstants.SELF_LINKING_PATH, AppLabelConstants.SELF_LINKING_URL);
        return true;
      } else if (srcSysID.value !== this.insuredId || srcSysID.value !== this.personId) {
        this.count = this.count + 1;
        if (this.count > 0 && this.count < 3) {
          this.selfLinkingForm.get(AppConstants.SELF_LINKING_INSURED_ID).patchValue('');
          this.selfLinkingForm.controls[AppConstants.SELF_LINKING_INSURED_ID].setErrors({incorrectInsuredId: true});
          this.storageManagementService.setSessionData(SessionConstants.SELF_LINKING_PATH, AppLabelConstants.SELF_LINKING_URL);
          return false;

        } else if (this.count >= 3 ) {
          this.selfLinkingForm.get(AppConstants.SELF_LINKING_INSURED_ID).patchValue('');
          this.router.navigate([AppLabelConstants.SELF_LINKING_FAILED_URL]);
          this.storageManagementService.setSessionData(SessionConstants.SELF_LINKING_PATH, AppLabelConstants.SELF_LINKING_URL);
          return false;

        } else {
          this.storageManagementService.setSessionData(SessionConstants.SELF_LINKING_PATH, AppLabelConstants.SELF_LINKING_URL);
          return false;
        }
      }
    }
  }
  onSubmit() {
    if (this.validateInsuredId()) {
      this.selfLinkingService.getLinkType(this.partyRefId, this.srcPartyId, '1', 'Converting Weak to Strong', this.profileId)
      .subscribe(
          val => {
                if (val) {
                  if (val.spErrorCode === '0') {
                    this.authStateService.forceAuthorizationTokenReset().subscribe(
                      auth => {
                        if (auth) {
                          this.personService.getUserDetailsMudi(this.partyRefId).subscribe(
                            data => {
                              if (data) {
                                this.user = data;
                                this.user.partyRefId = this.partyRefId;
                                this.storageManagementService.setAppData(SessionConstants.USER_DATA, this.user);
                                this.router.navigate([AppLabelConstants.URL_HOME]);
                              }
                            }
                          );
                        }
                      });
                  }
                }
          },
          (error) => {
          console.log('Error' + error);
        }
      );
    }
  }
  onLogout() {
    this.router.navigate(['/']);
  }
  populateContent(pageId) {
    this.contentService.getOWCSResponse(pageId, AppConstants.CONFIRM_INSURED_PAGE_ID).subscribe(
      OWCSResponse => {
        if (OWCSResponse) {
          this.owcsContent = OWCSResponse;
          if (this.owcsContent.data.Confirm_Insured_ID_02_Body) {
            this.textInfo = this.owcsContent.data.Confirm_Insured_ID_02_Body.value;
          }
          if (this.owcsContent.data.Confirm_Insured_ID_09_Body) {
            this.Popup_Title = this.owcsContent.data.Confirm_Insured_ID_09_Body.value;
          }
          if (this.owcsContent.data) {
            this.lblID = this.personId ?
                        (this.owcsContent.data.Confirm_Insured_ID_03A_Label.value ? this.owcsContent.data.Confirm_Insured_ID_03A_Label.value : '') :
                        (this.owcsContent.data.Confirm_Insured_ID_03_Body.value ? this.owcsContent.data.Confirm_Insured_ID_03_Body.value : '');
          }
        }
      }, (error) => {
        //console.error(error);
      });
  }

  tagErrors(elementId, errMsg) {
    if (errMsg) {
      if (Object.keys(errMsg) && this.errorList.myErrors['' + Object.keys(errMsg)]) {
        const errorMsg = this.errorList.myErrors['' + Object.keys(errMsg)];

        if (errorMsg === this.errorList.myErrors.required) {
          this.customEventService.formFieldValidationErrors(['' + elementId], ['' + 'Please enter your Insured ID' + errorMsg]);
        } else {
          this.customEventService.formFieldValidationErrors(['' + elementId], ['' + errorMsg]);

        }
      }
    }
  }
}
