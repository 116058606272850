
import {throwError as observableThrowError} from 'rxjs/internal/observable/throwError';
import {of, Observable} from 'rxjs';

import {catchError, map} from 'rxjs/operators';
/**
 * Created by AC12323 on 9/27/2017.
 * Updated by FA81445 on 10/10/2017.
 */


import {Injectable} from '@angular/core';
import {ClaimDocument} from '../models/claim-document.model';

import {HttpClient, HttpHeaders} from '@angular/common/http';
import {env} from '../../../environments/environment-loader';
import {ResponseErrorHandlerService} from './response-error-handler.service';



import {ClaimServiceService} from './claim-service.service';
import {DocumentumSearch, FILE_LIMITS, ModularFile, UploadingFiles} from '../models/document.model';
import {MessageRibbonService} from './message-ribbon.service';
import {StorageManagementService} from './storage-management.service';
import {User} from '../models/user.model';
import {RelatedClaims} from '../models/relatedClaims.model';
import {Router} from '@angular/router';
import {SessionConstants} from '../constants/session.constants';
import {AppLabelConstants} from '../constants/appLabel.constants';

declare global {
  interface Navigator {
    msSaveBlob: (blob: Blob, fileName: string) => boolean
  }
}

@Injectable()
export class DocumentService {
  private documentumList: ModularFile[] = [];
  private documentForDisplay: ClaimDocument[] = [];
  private ifMergeMultipleFiles = false;
  public currentRoute;
  public docUploadStatus;
  constructor(private storageService: StorageManagementService,
              private errorHandler: ResponseErrorHandlerService,
              private messageRibbonService: MessageRibbonService,
              private claimService: ClaimServiceService,
              private router: Router,
              private httpClient: HttpClient) {

  }

  /** Service Call to obtain the documents stored within documentum.
   *
   * @returns Observable of a ClaimDocument Array
   * @param offset: Number of documents to skip from the starting point
   *        limit: Total number of documents to return
   *        claimEventId: Associated Id to the documents
   * */
  getDocDetails(offset: number, limit: number, claimEventId: string, insuredId: string, claimId: string, leaveId: string, personId: string): Observable<ClaimDocument[]> {
      const attributeType = (claimEventId ? 'claimEventId' : claimId ? 'claimId' : leaveId ? 'leaveId' : '');
      const attributeValue = (claimEventId ? claimEventId : claimId ? claimId : leaveId ? leaveId : '').toString();
      const sourceSysName = (claimEventId ? 'dcsclaimsupl' : claimId ? 'thaaclaimsupl' : leaveId ? 'thaaleaveupl' : '');
      const userType = (claimEventId ? 'insuredId' : claimId ? 'personIds' : leaveId ? 'personIds' : '');
      const userId = (claimEventId ? insuredId : claimId ? personId : leaveId ? personId : '');
      const request: string = JSON.stringify(
        {
          retrievalType: 'gbmyben',
          page: 1,
          itemsPerPage: limit,
          sortAttribute : 'SourceCreationDate',
          sortExpression : 'DESC',
           [attributeType]  : attributeValue,
            [userType]: (userType === 'insuredId' ? userId : [userId]),
          searchAttributes: [
            {
              attribute: attributeType,
              value: attributeValue
            }, {
              attribute: 'DocumentSourceSystemName',
              value: sourceSysName
            }
          ],
          searchResultAttributes: [
            'HIGDocumentIdentifier',
            'DocumentType',
            'DocumentCreationDate',
            'SourceCreationDate',
            'DocumentId',
            'DocumentFileName'
          ]
        }
      );
      return this.httpClient.post((env.documentum.documentumURL_search), request,
        {
          headers: new HttpHeaders()
            .set(AppLabelConstants.CONTENT_TYPE_KEY, AppLabelConstants.APPLICATION_KEY)
        }).pipe(
        map(
        (response: DocumentumSearch) => {
          if (!this.errorHandler.isServiceResponseHasError(response)) {
            for (let i = 0; i < this.documentForDisplay.length; i++) {
              if (this.documentForDisplay.length > 0) {
                for (let j = 0; j < response.documents.length; j++) {
                  const splitName = this.documentForDisplay[i].DocumentFileName.split('.')[0].split('-');
                  const splitKnownName = response.documents[j].DocumentFileName.split('.')[0].split('-');
                  if ((splitName[0] === splitKnownName[0] && splitName[1] === splitKnownName[1])) {
                    this.documentForDisplay.splice(i, 1);
                    i--;
                    break;
                  }
                }
              }
            }

            if (this.documentForDisplay.length > 0) {
              if (limit > 0 && (this.documentForDisplay.length + response.documents.length > limit)) {
                return (this.documentForDisplay.concat(response.documents)).slice(0, limit);
              }
              return this.documentForDisplay.concat(response.documents);
            } else {
              return response.documents;
            }
          } else {
            // console.log("From Service:: Service returned error with 200");
            throw response;
          }
        }
      ), catchError((error: any) => {
          //console.log("Inside Catch Handler");
          this.errorHandler.handleError(error);
          return of(null)
      }), );

  }

  /** Service call to load a claiment document to their device
   *
   * @return Observable array of ClaimDocument
   * @param claimEventId: value of claim associated to the document
   * @param documentId: value of the specific document
   * @param documentName: Name of the specific document. It will be used as the way to get the extension
   *
   * */
  viewDocument(claimEventId: string, insuredId: string, claimId: string, leaveId: string, personId: string, documentId: string, documentName: string): Observable<ClaimDocument[]> {
    const attributeType = (claimEventId ? 'claimEventId' : claimId ? 'claimId' : leaveId ? 'leaveId' : '');
    const attributeValue = (claimEventId ? claimEventId : claimId ? claimId : leaveId ? leaveId : '').toString();
    const sourceSysName = (claimEventId ? 'dcsclaimsupl' : claimId ? 'thaaclaimsupl' : leaveId ? 'thaaleaveupl' : '');
    const userType = (claimEventId ? 'insuredId' : claimId ? 'personIds' : leaveId ? 'personIds' : '');
    const userId = (claimEventId ? insuredId : claimId ? personId : leaveId ? personId : '');
    /* Call to view function */
    const request: string = JSON.stringify(
      {
        retrievalType: 'gbmyben',
         [attributeType] : attributeValue,
         [userType] : (userType === 'insuredId' ? userId : [userId]),
        searchAttributes: [
          {
            attribute: 'DocumentSourceSystemName',
            value: sourceSysName
          },
          {
            attribute: 'DocumentId',
            value: documentId
          },
          {
            attribute: attributeType,
            value: attributeValue
          }
        ]
      }
    );
    /*claimEventId*/

    let mimeType = '';
    const spli = documentName.split('.');
    const fileExt = (spli[spli.length - 1]).toUpperCase();

    switch (fileExt) {
      case 'PDF' : mimeType = 'application/pdf'; break;
      case 'RTF' : mimeType = 'application/rtf'; break;

      case 'DOC' : mimeType = 'application/msword'; break;
      case 'PPT' : mimeType = 'application/vnd.ms-powerpoint'; break;
      case 'XLS' : mimeType = 'application/vnd.ms-excel'; break;
      case 'XLSX' : mimeType = 'vnd.openxmlformats-officedocument.spreadsheetml.sheet'; break;
      case 'PPTX' : mimeType = 'application/vnd.openxmlformats-officedocument.presentationml.presentation'; break;
      case 'DOCX' : mimeType = 'application/vnd.openxmlformats-officedocument.wordprocessingml.document'; break;

      case 'TXT' : mimeType = 'text/plain'; break;

      case 'PNG' : mimeType = 'image/png'; break;
      case 'TIF' : mimeType = 'image/tiff'; break;
      case 'TIFF' : mimeType = 'image/tiff'; break;
      case 'JPG' : mimeType = 'image/jpeg'; break;
      case 'JPEG' : mimeType = 'image/jpeg'; break;
      case 'GIF' : mimeType = 'image/gif'; break;
      case 'BMP' : mimeType = 'image/jpeg'; break;
      default : mimeType = 'application/octet-stream'; break;

    }
    return this.httpClient
      .post((env.documentum.documentumURL_view), request,
        {
          headers: new HttpHeaders()
            .set(AppLabelConstants.CONTENT_TYPE_KEY, AppLabelConstants.APPLICATION_KEY),
          responseType: 'arraybuffer'
        }).pipe(
      map(response => {
        if ( window.navigator.userAgent.indexOf( 'MSIE ' ) > 0 || !!navigator.userAgent.match(/Trident.*rv\:11\./) ) {
          const blob = new Blob([response], {type: mimeType});
          const issuccess = window.navigator.msSaveBlob(blob, documentName);
        } else {
          const blob = new Blob([response], {type: mimeType});
          const link = document.createElement('a');
          link.href = window.URL.createObjectURL(blob);
          link.download = documentName;
          link.click();
        }


        return null;
      }),
      catchError(this.handleError), );

  }

  /** Service call to load a claiment document to their device
   *
   * @return Observable array of ClaimDocument
   * @param claimDocument: current document file to send
   *
   * */
  uploadDocument() {
    if (this.validateMaxFileSizes()) {
      this.currentRoute = this.storageService.getSessionData('currentPage');
      const currentTimeStamp = new Date().getTime();
            const dataBoundry = 'epoch-' + currentTimeStamp;
            //format date
            const now = new Date();
            const year = now.getFullYear();
            const month = now.getMonth() + 1;
            const day = now.getDate();
            const hour = now.getHours();
            const minute = now.getMinutes();
            const second = now.getSeconds();
            const sourceSystemCreationDate = `${month}/${day}/${year} ${hour}:${minute}:${second}`;
            const receivedDate = `${month}/${day}/${year}`;
            //get variables for header
            const appData = JSON.parse(this.storageService.retrieveSessionObject('appData')).appData;
            const claim = (this.currentRoute.includes('/claimDetails') ? this.storageService.getSessionData('curClaim') : '');
            const leave = (this.currentRoute.includes('/leave-details/leaveDetails') ? this.storageService.getSessionData('currLeave') : '');
            const clmevntid = ((this.currentRoute.includes('/claimDetails') && claim.sourceSystemId === 'DCS') ? JSON.parse(claim.claimEventId) : '');
            const insuredId = ((this.currentRoute.includes('/claimDetails') && claim.sourceSystemId === 'DCS') ? appData.userData.insuredId : '');
            const claimid = ((this.currentRoute.includes('/claimDetails') && claim.sourceSystemId === 'AA') ? JSON.parse(claim.claimEventId) : '');
            const leaveId = ((this.currentRoute.includes('/leave-details/leaveDetails')) ? JSON.parse(leave.leaveId) : '');
            const personId = (claimid ? claim.personId : leaveId ? leave.personId : '');
            const caseId = (clmevntid ? claim.caseId : claimid ? claim.caseId : leaveId ? leave.caseId : '');
            const stateCode = appData.userData.stateCode;
            const city = appData.userData.city;
            const firstName = appData.userData.firstname;
            const lastName = appData.userData.lastName;
            const processDate = claim.claimProcessDate;
            const effectiveDate = claim.claimEffectiveDate;
            const claimStatusCode = claim.claimStatusCode;
            const claimTypeCode = claim.coverageType;
            const authActiveSource = ((this.currentRoute.includes('/claimDetails') && (claim.sourceSystemId === 'AA' || claim.sourceSystemId === 'DCS')) || (this.currentRoute.includes('/leave-details/leaveDetails')) ) ? 'APPX' : '';
            const randomNo = Math.random();
            const randomNoVal = randomNo.toString().substr(randomNo.toString().length - 6);
         const caseName = this.getCaseName(caseId);
      // const caseName = (appData.caseData.cases) ? appData.caseData.cases.find( el =>  el.caseId === caseId ) : 'US Bank';
         const claimNo = ((this.currentRoute.includes('/claimDetails') && claim.sourceSystemId === 'DCS') ? clmevntid : (this.currentRoute.includes('/claimDetails') && claim.sourceSystemId === 'AA') ? claimid :
          (this.currentRoute.includes('/leave-details/leaveDetails')) ? leaveId : '');
         const docCategory = ((this.currentRoute.includes('/claimDetails') && claim.sourceSystemId === 'DCS') ? 'Disability Claims' : (this.currentRoute.includes('/claimDetails') && claim.sourceSystemId === 'AA') ? 'Disability Claims' :
          (this.currentRoute.includes('/leave-details/leaveDetails')) ? 'Leave Claims' : '');
         const sourceAppSysName = ((this.currentRoute.includes('/claimDetails') && claim.sourceSystemId === 'DCS') ? 'dcsclaimsupl' : (this.currentRoute.includes('/claimDetails') && claim.sourceSystemId === 'AA') ? 'thaaclaimsupl' :
          (this.currentRoute.includes('/leave-details/leaveDetails')) ? 'thaaleaveupl' : '');
         const dcn = ((this.currentRoute.includes('/claimDetails') && claim.sourceSystemId === 'DCS') ? '9012' : (this.currentRoute.includes('/claimDetails') && claim.sourceSystemId === 'AA') ? '9012' :
          (this.currentRoute.includes('/leave-details/leaveDetails')) ? '9021' : '');
         const userType = (clmevntid ? 'insuredId' : claimid ? 'personIds' : leaveId ? 'personIds' : '');
         const userId = (clmevntid ? insuredId : claimid ? personId : leaveId ? personId : '');
      let dataPartOne = '' +
        '--' + dataBoundry +
        '\r\n' +
        'Content-Type: application/json\r\n' +
        'Content-Location: root\r\n\r\n{\r\n' +
        '"claimEventId":"' + clmevntid + '",\r\n' +
        '"claimId":"' + claimid + '",\r\n' +
        '"leaveId":"' + leaveId + '",\r\n' +
        '"' + userType + (userType === 'insuredId' ? '":"' + userId + '",\r\n' : '":["' + userId + '"],\r\n')  +
        '"CommonAttributes": [\r\n ' +
        '{\r\n "Attribute": "DocumentCode",             \r\n  "Value": "' + dcn + '"\r\n        },\r\n        ' +
        '{\r\n "Attribute": "DocumentCategory",         \r\n  "Value":  "CLAIMS"\r\n        },\r\n        ' +
        '{\r\n "Attribute": "DocumentType",             \r\n  "Value": "' + docCategory + '"\r\n        },\r\n        ' +
        '{\r\n "Attribute": "SourceApplicationSystemName",  \r\n  "Value": "' + sourceAppSysName + '"\r\n        },\r\n        ' +
        '{\r\n "Attribute": "SourceApplicationSystemId",  \r\n  "Value": "' + sourceAppSysName + '"\r\n        },\r\n        ' +
        '{\r\n "Attribute": "DocumentControlNumber",    \r\n  "Value": "' + dcn + currentTimeStamp + randomNoVal + '"\r\n        },\r\n        ' +
        '{\r\n "Attribute": "SourceSystemCreationDate", \r\n  "Value": "' + sourceSystemCreationDate + '"\r\n  },\r\n        ' +
        '{\r\n "Attribute": "ReceivedDate",             \r\n  "Value": "' + receivedDate + '"\r\n           },\r\n        ' +
        '{\r\n "Attribute": "authoritativeSource",      \r\n  "Value": "' + authActiveSource + '"\r\n },\r\n ' +
        '{\r\n "Attribute": "BusinessDomain",           \r\n  "Value": "gbmyben"\r\n             },\r\n        ' +
        '{\r\n "Attribute": "LineOfBusiness",           \r\n  "Value": "GB CLAIMS"\r\n             },\r\n        ' +
        '{\r\n "Attribute": "FileCount",                \r\n  "Value": "' + this.documentumList.length + '"\r\n                    }\r\n       ],' +
        '\r\n ' +
        '"BusinessDomainAttributes": [\r\n ' +
        '{\r\n "Attribute": "ClaimEventId",             \r\n  "Value": "' + clmevntid + '"\r\n        },\r\n        ' +
        '{\r\n "Attribute": "InsuredId",                \r\n  "Value": "' + insuredId + '"\r\n                  },\r\n        ' +
        '{\r\n "Attribute": "claimId",                  \r\n  "Value": "' + claimid + '"\r\n                  },\r\n        ' +
        '{\r\n "Attribute": "LeaveId",                  \r\n  "Value": "' + leaveId + '"\r\n                  },\r\n        ' +
        '{\r\n "Attribute": "personId",                 \r\n  "Value": "' + personId + '"\r\n                  },\r\n        ' +
        '{\r\n "Attribute": "CaseId",                   \r\n  "Value": "' + caseId + '"\r\n                  },\r\n        ' +
        '{\r\n "Attribute": "CaseName",                 \r\n  "Value": "' + caseName + '"\r\n            },\r\n        ' +
       /* '{\r\n "Attribute": "ResidentStateCode",        \r\n  "Value": "' + stateCode + '"\r\n                   },\r\n        ' +
        '{\r\n "Attribute": "ResidentCity",             \r\n  "Value": "' + city + '"\r\n               },\r\n        ' +
        '{\r\n "Attribute": "DateOfBirth",              \r\n  "Value": ""\r\n           },\r\n        ' +*/
        '{\r\n "Attribute": "LossDate",                 \r\n  "Value": ""\r\n           },\r\n        ' +
        '{\r\n "Attribute": "ClaimantFirstName",        \r\n  "Value": "' + firstName + '"\r\n           },\r\n        ' +
        '{\r\n "Attribute": "ClaimantLastName",         \r\n  "Value": "' + lastName + '"\r\n            }\r\n        ' +
   /*     '{\r\n "Attribute": "StatusEffectiveDate",      \r\n  "Value": "' + effectiveDate + '"\r\n           },\r\n        ' +
        '{\r\n "Attribute": "StatusProcessDate",        \r\n  "Value": "' + processDate + '"\r\n           },\r\n        ' +
        '{\r\n "Attribute": "ClaimStatusCode",          \r\n  "Value": "' + claimStatusCode + '"\r\n                    },\r\n        ' +
        '{\r\n "Attribute": "CoverageTypeCode",         \r\n  "Value": "' + claimTypeCode + '"\r\n                    }\r\n       ],' +*/
        '\r\n   ],' +
        '"FileNames": [\r\n     ';

      const timeStamp = this.getUploadTime();
      for (let i = 0; i < this.documentumList.length; i++) {

        dataPartOne += '"' + this.getUploadDocName(claimNo, timeStamp, (i + 1), this.documentumList[i].enclosedFileExt);
        dataPartOne += '"\r\n';

        if ((this.documentumList.length - 1) !== i) {
          dataPartOne += ', ';
        }
      }
      dataPartOne += '  ]\r\n}\r\n\r\n';
      for (let i = 0; i < this.documentumList.length; i++) {
        dataPartOne += '--' + dataBoundry + '\r\n';
        dataPartOne += 'Content-Type: application/pdf\r\nContent-Transfer-Encoding: base64\r\n' +
          'Content-Id: <' + this.getUploadDocName(claimNo, timeStamp, (i + 1), this.documentumList[i].enclosedFileExt) + '>' +
          '\r\nContent-Disposition: attachment; filename="' + this.getUploadDocName(claimNo, timeStamp, (i + 1), this.documentumList[i].enclosedFileExt) + '"\r\n\r\n' +
          this.documentumList[i].encodedContent +
          '\r\n';
        const claimDoc: ClaimDocument = new ClaimDocument();
        claimDoc.DocumentFileName = this.getUploadDocNameToReserve(claimNo, timeStamp, this.documentumList[i].enclosedFileExt);
        this.documentForDisplay.push(claimDoc);

      }

      dataPartOne += '--' + dataBoundry + '--';

      return this.httpClient
        .post(env.documentum.uploadToDocumentum, dataPartOne, {
          headers: new HttpHeaders()
            .set('Content-Type', 'multipart/related; type=\"application/json\"; boundary=\"' + dataBoundry + '\"')
            .append('Accept', 'application/xml')

          , responseType: 'text'
        }).pipe(
        map((res) => {
          this.documentumList.length = 0;
          const missingInfoCodes: string[] = this.getCodesForMissingInfo();
          if (missingInfoCodes && missingInfoCodes.length > 0) {
            this.claimService.updateReceivedDate(clmevntid, receivedDate, missingInfoCodes).subscribe(
              data => {
                /** DE2843 : Reset Claim Info when missing codes are updated in DCS database so that user sees updated missing info on claim details page **/
                this.resetMissingClaimInfo();
              }, err => {
              }
            );
          }

        }),
        catchError(this.handleError), );

    } else {
      this.messageRibbonService.addMessage('error', 'It looks like you have one or more files that are too big. You can upload up to 10 MB per file.', 'Upload Error', 'false');
      return observableThrowError(null);
    }
  }


  private getCaseName(caseId) {
    const appData = JSON.parse(this.storageService.retrieveSessionObject('appData')).appData;
    for (let i = 0; i < appData.caseData.cases.length; i++) {
     if (appData.caseData.cases[i].caseId === caseId) {
       const caseNm = appData.caseData.cases[i].caseName;
       const updatedCaseNm = caseNm.replace(/[,.'"]+/g, '\'');
        return updatedCaseNm;
      }
    }
    return 'US Bank';
  }
  private validateMaxFileSizes() {
    let mergeCount = 0;
    let bulkCount = 0;
    for (let i = 0; i < this.documentumList.length; i++) {
      if (this.documentumList[i].isMergeable()) {
        mergeCount += this.documentumList[i].getFile().size;
      }
      bulkCount += this.documentumList[i].getFile().size;
    }
     return mergeCount < FILE_LIMITS.merged && bulkCount < FILE_LIMITS.bulk;
  }

  /** Private method to handle an error case of the service call */
  private handleError(error: Response) {
    return observableThrowError(error);
  }


  /* Base 64 Utility Methods*/
  private getBase64(file: ModularFile) {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      if (!(file.isValid())) {
        reader.readAsDataURL(new Blob());
      } else {
        reader.readAsDataURL(file.getFile());
      }
      reader.onload = () => resolve(
        this.documentumList.push(new ModularFile(file.getFile(), file.getErrorTypes(), (reader && reader.result ? reader.result : '').toString().split(',')[1]))
      );
      reader.onerror = error => reject(error);
    });
  }
  storeSessionFile(newFiles: UploadingFiles) {
    if (!env.mocking) {
      //console.log(this.getUploadTime());
      for (let i = 0; i < newFiles.getAllFiles().length; i++) {
        const currFile = (newFiles.getAllFiles())[i];
          this.getBase64(currFile).then(
            data => {
            });
      }
   }
  }

  retrieveSessionFiles(): ModularFile[] {
    if (env.mocking) {
      const sampleDocList: ModularFile[] = [];
      sampleDocList.push(new ModularFile(new File([''], 'Sample PDF.pdf'), null));
      return sampleDocList;
    }
    return this.documentumList;
  }

  removeSessionFiles(index?: number, filesToRemove?: ModularFile) {
    if (!filesToRemove) {
      this.documentumList = [];
    } else {
      if (this.documentumList[index].getFile().name === filesToRemove.getFile().name) {
        this.documentumList.splice(index, 1);
      }
    }
  }

  ifMultipleFilesToBeMerged() {
    return this.ifMergeMultipleFiles;
  }

  getUploadTimeUTC() {
    const curTime = new Date();
    return '-' + curTime.getTime();
  }

  getUploadTime() {
    const curTime = new Date();
    return '-' + ((curTime.getMonth() + 1) < 10 ? '0' + (curTime.getMonth() + 1) : (curTime.getMonth() + 1)) + '' +
      ((curTime.getDate()) < 10 ? '0' + (curTime.getDate()) : (curTime.getDate()))
      + '' + curTime.getFullYear() + '' + (curTime.getHours() < 10 ? '0' + curTime.getHours() : curTime.getHours()) + '' +
      (curTime.getMinutes() < 10 ? '0' + (curTime.getMinutes()) : (curTime.getMinutes())) + '' +
      (curTime.getSeconds() < 10 ? '0' + (curTime.getSeconds()) : (curTime.getSeconds()));
  }

  getUploadDocName(clmevntid: string, myTime, seq, ext: string): string {
    return clmevntid + myTime + '-' + seq + ext;
  }
  getUploadDocNameToReserve(clmevntid: string, myTime, ext: string): string {
    return clmevntid + myTime + ext;
  }

  getCodesForMissingInfo(): string[] {
    return this.storageService.retrieveSessionObject('missingInfoCodes') ?
      JSON.parse(this.storageService.retrieveSessionObject('missingInfoCodes')).missingInfoCodes : [];
  }

  resetMissingClaimInfo() {
    const userDetails: User = this.storageService.getAppData(SessionConstants.USER_DATA);
    if (userDetails && userDetails.insuredId) {
      this.claimService.getClaimDetails(userDetails).subscribe(
        (claimsInfo) => {
          this.storageService.setAppData(SessionConstants.USER_CLAIMS , claimsInfo);
          const curClaim = JSON.parse(this.storageService.retrieveSessionObject('curClaim')).curClaim;
          if (claimsInfo && claimsInfo.claimOverview && claimsInfo.claimOverview.relatedClaims && claimsInfo.claimOverview.nonRelatedClaims) {
            const relatedClaims: RelatedClaims[] = claimsInfo.claimOverview.relatedClaims;
            const nonRelatedClaims: RelatedClaims[] = claimsInfo.claimOverview.nonRelatedClaims;
            let relatedClaimsIndex = -1;
            let nonRelatedClaimsIndex = -1;

            if (relatedClaims && relatedClaims.length > 0) {
              relatedClaimsIndex = relatedClaims.findIndex((relatedClaimElem: RelatedClaims) => relatedClaimElem.claimEventId === curClaim.claimEventId);
              if (relatedClaimsIndex !== -1) {
                this.storageService.setSessionObject('curClaim', relatedClaims[relatedClaimsIndex]);
              }
            }

            if (nonRelatedClaims && nonRelatedClaims.length > 0) {
              nonRelatedClaimsIndex = nonRelatedClaims.findIndex((nonRelatedClaimElem: RelatedClaims) => nonRelatedClaimElem.claimEventId === curClaim.claimEventId);
              if (nonRelatedClaimsIndex !== -1) {
                this.storageService.setSessionObject('curClaim', nonRelatedClaims[nonRelatedClaimsIndex]);
              }
            }

          }
          this.router.navigate([AppLabelConstants.DOCUMENT_CONFIRMATION_PAGE_URL]);
        }
      );
    }
  }

}


