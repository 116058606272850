import {ProvisionResponse} from '../shared/models/Provision.model';

/**
 * Created by :- Chaitanya
 * Date :- 20/2/2018
 * Purpose :- To mock the policy admin service which gives us the provisions for a particular case Id
 */

export const MOCK_PROVISIONS: ProvisionResponse = {
  errorList: [],

  provisionResponseList:    [
    {
      caseId: 1004336,
      coverageCategoryCode: 'N/A',
      coverageTypeCode: 'N/A',
      fundingMethodCode: 'ASO',
      provisionId: '315',
      provisionDescription: 'SERVICE PROVIDED',
      classNumber: 'N/A',
      classDescription: 'ALL ACTIVE FULL-TIME EMPLOYEES ELECTING THE 50% LTD PLAN',
      provisionOptionId: '10',
      provisionOptionValue: 'CALCULATE CLAIM ONLY',
      selectionNumber: '0',
      provisionOptionTerminationDate: null,
      provisionOptionEffectiveDate: '2014-05-01'
    },
    {
      caseId: 1004336,
      coverageCategoryCode: 'N/A',
      coverageTypeCode: 'N/A',
      fundingMethodCode: 'ASO',
      provisionId: '315',
      provisionDescription: 'SERVICE PROVIDED',
      classNumber: 'N/A',
      classDescription: 'ALL ACTIVE FULL-TIME EMPLOYEES ELECTING THE 50% LTD PLAN',
      provisionOptionId: '1',
      provisionOptionValue: 'CALCULATE CLAIM ONLY',
      selectionNumber: '0',
      provisionOptionTerminationDate: null,
      provisionOptionEffectiveDate: '2014-05-01'
    }]
};
