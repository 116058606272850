import { Injectable, OnInit } from '@angular/core';
import { ActivatedRouteSnapshot, NavigationExtras, Resolve, Router, RouterStateSnapshot } from '@angular/router';
import { parallel } from 'async';
import { switchMap, filter, takeUntil } from 'rxjs/operators';
import { of, Subject } from 'rxjs';
import { IntakeSessionConstants } from '@app/cxr/modules/intake/shared/constants/intakeSession.constants';
import { IntakeLabelConstants } from '@app/cxr/modules/intake/shared/constants/intake-label.constants';
import { SessionConstants } from '@app/shared/constants/session.constants';
import { StorageManagementService } from '@app/shared/services/storage-management.service';
import { ContentService } from '@app/shared/services/content.service';
import { PFLTransitionService } from '../services/pfl-transition.service';
import { IPflTransition } from '../models/intake-content.model';
import { AppLabelConstants } from '@app/shared/constants/appLabel.constants';
import { CustomError } from '@app/shared/models/customError.model';
import { INTAKE_OWCS_IDS } from '../JSON/intake-owcs.constants';
import { IntakeOWCSContentService } from '../services/intake-owcs-content.service';

@Injectable({
  providedIn: 'root'
})
export class PflTransitionResolverService implements Resolve<any> {
  personId: string;
  mobileNumber: string;
  customError: CustomError = Object.assign({});
  navigationExtras: NavigationExtras = {
    state: {
      data: this.customError
    }
  };
  constructor(private pflTransitionService: PFLTransitionService,private router: Router, private intakeOWCSservice: IntakeOWCSContentService, private storageManagementService: StorageManagementService ) {
    this.personId = this.storageManagementService.getAppData(SessionConstants.USER_DATA) ? this.storageManagementService.getAppData(SessionConstants.USER_DATA).personIds[0] : null;
    if (this.storageManagementService.getSessionData(SessionConstants.CLAIM_MOBILE)) {
      this.mobileNumber = this.storageManagementService.getSessionData(SessionConstants.CLAIM_MOBILE);
    }
    this.getRouteData();
   }

  getRouteData(): void {
    this.customError.IscustomErrorContent = true;
    this.customError.customErrorIcon = AppLabelConstants.CUSTOM_ERROR_ICON;
    this.customError.customErrorHeader = this.intakeOWCSservice.getNewMaternityIntakeOwcsContent(INTAKE_OWCS_IDS.NI_100_Body);
    this.customError.customErrorLabel = this.intakeOWCSservice.getNewMaternityIntakeOwcsContent(INTAKE_OWCS_IDS.NI_944_Body)
    this.customError.customErrorBody = this.intakeOWCSservice.getNewMaternityIntakeOwcsContent(INTAKE_OWCS_IDS.NI_945_Body)
    this.customError.customErrorReturntoHome = this.intakeOWCSservice.getNewMaternityIntakeOwcsContent(INTAKE_OWCS_IDS.NI_947_Body)?.split('<div>')[1]?.split('</div>')[0];
    if (this.mobileNumber !== undefined) {
      this.customError.customErrorDesc = this.intakeOWCSservice.getNewMaternityIntakeOwcsContent(INTAKE_OWCS_IDS.NI_946_Body).replace('[Case Specific Claim Office Number]', this.mobileNumber);

      if (screen.width < 480 || screen.height < 480) {
        this.customError.customErrorDesc = this.intakeOWCSservice.getNewMaternityIntakeOwcsContent(INTAKE_OWCS_IDS.NI_946_Body).replace('[Case Specific Claim Office Number]', '<a href="tel:' + this.mobileNumber + '">' + this.mobileNumber + '</a>');
      }
    } else {
      this.customError.customErrorDesc = this.intakeOWCSservice.getNewMaternityIntakeOwcsContent(INTAKE_OWCS_IDS.NI_946A_Body);
      if (screen.width < 480 || screen.height < 480) {
        var phone = this.customError.customErrorDesc.match("\\+?\\(?\\d*\\)? ?\\(?\\d+\\)?\\d*([\\s./-]\\d{2,})+")[0];
        this.customError.customErrorDesc = this.customError.customErrorDesc.replace(phone, '<a href="tel:' + phone + '">' + phone + '</a>');
      }
    }
  }
   resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
       const observer = new Subject<any>();
       parallel({
        data: (pflData) =>{
            this.pflTransitionService.pflTransitionFunction(this.personId).subscribe( response =>{
                pflData(null, response);

            }, (error)=>{
                pflData(error, null);
                this.router.navigate([AppLabelConstants.URL_CUSTOMERROR], this.navigationExtras);
            });
        },
       }, (err: string, result: IPflTransition) =>{
        observer.next(result);
        observer.complete();
       });
       return observer.asObservable();
   }
}
