import {Component, Input, OnChanges, OnInit, Output} from '@angular/core';
import {StorageManagementService} from '../../../shared/services/storage-management.service';
import {Router} from '@angular/router';
import {ContentService} from '../../../shared/services/content.service';
import {AppLabelConstants} from '../../../shared/constants/appLabel.constants';
import {SessionConstants} from '../../../shared/constants/session.constants';
import {AppConstants} from '../../../shared/constants/app.constants';
import {env} from '../../../../environments/environment-loader';
import {AetnaContactsService} from '../../../shared/services/aetnaContacts.service';
import {AetnaApsFormsService} from '../../../shared/services/aetna-aps-forms.service';
import {TaxFormConstants} from '../../tax-form/tax-form.constants';
import {ResponseErrorHandlerService} from '../../../shared/services/response-error-handler.service';

@Component({
  selector: 'app-extention-status',
  templateUrl: 'extention-status.component.html',
  styleUrls: ['extention-status.component.scss']
})
export class ExtentionStatusComponent implements OnInit, OnChanges {
  @Input() currentClaim;
  @Input() infoLabel;
  @Input() message_1_Label;
  @Input() gotoMissingInfo_Button;
  @Input() header_3;
  @Input() message_3;
  @Input() header_2;
  @Input() message_2;
  @Input() dateLabel;
  @Input() extensionUIAdjStatus;
  @Input() extensionUIMissStatus;
  @Output() loading = false;
   extensionRequestedThroughDate;
   extensionApproveThroughDate;
   Approveddate;
   pageId;
   owscContent;
  missingURMsg = '';
  URAdjMsg = AppLabelConstants.UR_ADJ_MSG;
  URByNurseMsg = '';
  EAPartialMsg = '';
  EAFullMsg = '';
  EDeniedMsg = '';
   extClosedMissingMsg;
   phone_number_ext_closed;
  public appConstant = AppConstants;
  public appLabelConstant = AppLabelConstants;
  public claimNeeded;
  public apsData;
  public baseUrl;
  public sourceSysId: string;
  public isThaaClaim: boolean = false;
  public appConstants = AppConstants;
  public para1FirstHalf;
  public para1Link;
  public para1LastHalf;
  public personId;
  public claimId;
  public extensionStatusCode;
  public extensionSubStatusCode;
  public taxFormConstants = TaxFormConstants;
  isMissingInfoDisabled: boolean = false;

  constructor(private storageManagementService: StorageManagementService,
              private aetnaApsFormsService: AetnaApsFormsService,
              private router: Router,
              private contentService: ContentService,
              private _responseErrorHandlerService: ResponseErrorHandlerService,
              private aetnaContactsService: AetnaContactsService) {
  }

    ngOnInit() {
      this.isMissingInfoDisabled = this.storageManagementService.getSessionData(AppConstants.IS_MISSING_INFO_DISABLED);
   /*   this.storageManagementService.disabilityContactsForExtension.subscribe(value => {
        if (value) {
          this.phone_number_ext_closed = value;
          this.extClosedMissingMsg = this.extClosedMissingMsg + ' ' + AppLabelConstants.phoneNumberFormat(this.phone_number_ext_closed);
        }
      });*/

    if (this.currentClaim !== null || this.currentClaim.length >= 0) {
      this.extensionRequestedThroughDate = this.currentClaim.extensionRequestDate;
      this.extensionApproveThroughDate = this.currentClaim.authorizedEndDate;
      this.isThaaClaim =  this.currentClaim.sourceSystemId === this.appConstant.SOURCE_SYS_ID_AA ? true : false;
    }
    this.getSourceSysId();
      this.personId = this.currentClaim.personId;
      this.claimId = this.currentClaim.claimEventId;
      this.extensionStatusCode = this.currentClaim.extensionSubStatusCode;
      this.extensionSubStatusCode = this.currentClaim.extensionSubStatusCode;
  }

  ngOnChanges() {
    this.fetchOWCSData();
  }

  navToRequextension() {
    if (this.sourceSysId === this.appConstant.SOURCE_SYS_ID_DCS) {
      sessionStorage.removeItem(SessionConstants.EXTENSION_DATE);
      sessionStorage.setItem(SessionConstants.APPROVED_DATE,  this.Approveddate);
      this.router.navigate([AppConstants.URL_REQUEST_EXTENSION]);

    } else if (this.sourceSysId === this.appConstant.SOURCE_SYS_ID_AA) {
      sessionStorage.removeItem(SessionConstants.EXTENSION_DATE);
      sessionStorage.setItem(SessionConstants.APPROVED_DATE,  this.Approveddate);
      this.router.navigate([AppConstants.URL_REQUEST_EXTENSION_THAA]);
    }
  }

  navigateTo(location, curClaim, claimEventId, authorizedEndDate, claimOfficeId) {
    const el = document.getElementById(AppConstants.MISSING_INFO_HEADER);
    sessionStorage.setItem(SessionConstants.MISSING_INF_DIV, SessionConstants.MISSING_INF_DIV);
    if ( el ) {
      el.scrollIntoView();
    } else {
      this.storageManagementService.setSessionObject(SessionConstants.CUR_CLAIM, curClaim);
      this.storageManagementService.setSessionObject(SessionConstants.CLAIM_EVENT_ID, claimEventId);
      this.storageManagementService.setSessionObject(SessionConstants.AUTHORIZED_END_DATE, authorizedEndDate);
      this.storageManagementService.setSessionObject(SessionConstants.CLAIM_OFFICE_ID, claimOfficeId);
      sessionStorage.setItem(SessionConstants.MISSING_INF_DIV, SessionConstants.MISSING_INF_DIV);
      this.router.navigate(['/' + location], { queryParams: { scrollTo: AppConstants.MISSING_INFO_HEADER } });
    }

  }

fetchOWCSData() {
  if (this.storageManagementService.getSessionData(SessionConstants.OWCS_CONTENT)) {
    this.fetchOWCSContentFromSession();
  } else {
    this.fetchOWCSContentFromService();

  }
}


  fetchOWCSContentFromService() {
    this.pageId = this.contentService.getPageId(AppConstants.CLAIM_DETAILS);
    if (this.pageId) {
      this.contentService.getOWCSResponse(this.pageId, AppConstants.CLAIM_DETAILS_U).subscribe(
        val => {
          if (val) {
            this.owscContent = val;
            if (this.owscContent && this.owscContent.data) {
              if (this.owscContent.data.CD_127_Body && !this.extensionUIAdjStatus) {
                this.URAdjMsg = this.owscContent.data.CD_127_Body.value;
              }
              if (this.owscContent.data.CD_128_Body) {
                this.URByNurseMsg = this.owscContent.data.CD_128_Body.value
              }
              if (this.owscContent.data.CD_129_Body) {
                this.EAFullMsg = this.owscContent.data.CD_129_Body.value.replace('[EXTENSION_DATE]', this.extensionApproveThroughDate)
              }
              if (this.currentClaim.sourceSystemId === this.appConstant.SOURCE_SYS_ID_AA && (this.owscContent.data.CD_130A_Body)) {
                  this.EAPartialMsg = this.owscContent.data.CD_130A_Body.value.replace('[EXTENSION_DATE]', this.extensionApproveThroughDate);
              } else if (this.owscContent.data.CD_130_Body) {
                  this.EAPartialMsg = this.owscContent.data.CD_130_Body.value.replace('[EXTENSION_DATE]', this.extensionApproveThroughDate)
                }
              if (this.currentClaim.sourceSystemId === this.appConstant.SOURCE_SYS_ID_AA && (this.owscContent.data.CD_132A_Body)) {
                this.extClosedMissingMsg = this.owscContent.data.CD_132A_Body.value;
                this.missingURMsg = this.owscContent.data.CD_132A_Body.value;
              } else if (this.owscContent.data.CD_132_Body) {
                this.extClosedMissingMsg = this.owscContent.data.CD_132_Body.value;
                this.missingURMsg = this.owscContent.data.CD_132_Body.value;
              }
              if (this.currentClaim.sourceSystemId === this.appConstant.SOURCE_SYS_ID_AA && (this.owscContent.data.CD_126A_Body) && this.currentClaim.coverageCategory && this.currentClaim.coverageCategory.toUpperCase() !== this.appConstant.PFL) {
                this.missingURMsg = this.owscContent.data.CD_126A_Body.value.replace(/^<div[^>]*>|<\/div>$/g, '').replace('</a>', ' ').replace('<a>', '').replace('<a href="#">', '');
                this.para1FirstHalf = this.missingURMsg.substr(0, 165);
                this.para1Link = this.missingURMsg.substr(166, 9);
                this.para1LastHalf = this.missingURMsg.substr(176, 190);
              } else if (this.owscContent.data.CD_126_Body && this.currentClaim.coverageCategory && this.currentClaim.coverageCategory.toUpperCase() !== this.appConstant.PFL) {
              this.missingURMsg = this.owscContent.data.CD_126_Body.value;
            } else if (this.owscContent.data.CD_126B_Body && this.currentClaim.coverageCategory && this.currentClaim.coverageCategory.toUpperCase() === this.appConstant.PFL) {
                this.missingURMsg = this.owscContent.data.CD_126B_Body.value;
                this.para1FirstHalf = this.missingURMsg;
              }
              if (this.owscContent.data.CD_131_Body) {
                this.EDeniedMsg = this.owscContent.data.CD_131_Body.value
              }
              this.storageManagementService.setSessionData(SessionConstants.OWCS_CONTENT, this.owscContent.data);
              //integrate CD_132
            }
          }
        }, (error) => {
        });
    }
  }

  fetchOWCSContentFromSession() {
    this.owscContent = this.storageManagementService.getSessionData(SessionConstants.OWCS_CONTENT);
    if (this.owscContent) {
      if (this.owscContent.CD_127_Body && !this.extensionUIAdjStatus) {
        this.URAdjMsg = this.owscContent.CD_127_Body.value;
      }
      if (this.owscContent.CD_128_Body) {
        this.URByNurseMsg = this.owscContent.CD_128_Body.value
      }
      if (this.owscContent.CD_129_Body) {
        this.EAFullMsg = this.owscContent.CD_129_Body.value.replace('[EXTENSION_DATE]', this.extensionApproveThroughDate)
      }
      if (this.currentClaim.sourceSystemId === this.appConstant.SOURCE_SYS_ID_AA && (this.owscContent.CD_130A_Body)) {
        this.EAPartialMsg = this.owscContent.CD_130A_Body.value.replace('[EXTENSION_DATE]', this.extensionApproveThroughDate);
      } else if (this.owscContent.CD_130_Body) {
        this.EAPartialMsg = this.owscContent.CD_130_Body.value.replace('[EXTENSION_DATE]', this.extensionApproveThroughDate)
      }
      if (this.currentClaim.sourceSystemId === this.appConstant.SOURCE_SYS_ID_AA && (this.owscContent.CD_132A_Body)) {
        this.extClosedMissingMsg = this.owscContent.CD_132A_Body.value;
      } else if (this.owscContent.CD_132_Body) {
        this.extClosedMissingMsg = this.owscContent.CD_132_Body.value;
      }
      if (this.currentClaim.sourceSystemId === this.appConstant.SOURCE_SYS_ID_AA && (this.owscContent.CD_126A_Body) && this.currentClaim.coverageCategory && this.currentClaim.coverageCategory.toUpperCase() !== this.appConstant.PFL) {
        this.missingURMsg = this.owscContent.CD_126A_Body.value.replace(/^<div[^>]*>|<\/div>$/g, '').replace('</a>', ' ').replace('<a>', '').replace('<a href="#">', '');
        this.para1FirstHalf = this.missingURMsg.substr(0, 165);
        this.para1Link = this.missingURMsg.substr(166, 9);
        this.para1LastHalf = this.missingURMsg.substr(176, 190);
      } else if (this.owscContent.CD_126_Body && this.currentClaim.coverageCategory && this.currentClaim.coverageCategory.toUpperCase() !== this.appConstant.PFL) {
        this.missingURMsg = this.owscContent.CD_126_Body.value;
      } else if (this.owscContent.CD_126B_Body && this.currentClaim.coverageCategory && this.currentClaim.coverageCategory.toUpperCase() === this.appConstant.PFL) {
        this.missingURMsg = this.owscContent.CD_126B_Body.value;
        this.para1FirstHalf = this.missingURMsg
      }

      if (this.owscContent.CD_131_Body) {
        this.EDeniedMsg = this.owscContent.CD_131_Body.value
      }
    }
  }

  getSourceSysId() {
    if (this.currentClaim !== null || this.currentClaim.length >= 0) {
      this.sourceSysId = this.currentClaim.sourceSystemId;
    }
  }


  getApsForm() {
    this.loading = true;
    if ((this.extensionStatusCode === this.appConstants.EXTENSION_UNDER_REVIEW_CD_01 && this.extensionSubStatusCode === this.appConstants.EXT_MISSING_INFO_CD_01)
      || this.extensionStatusCode === this.appConstants.EXTENSION_UNDER_REVIEW_CD_1 && this.extensionSubStatusCode === this.appConstants.EXT_MISSING_INFO_CD_1) {
      this.aetnaApsFormsService.thaaGetAPS(this.personId, this.claimId).subscribe(
        data => {
         if (data && data.LetterBytes) {
            this.apsData = data;
            this.loading = false;
            const mimeType = 'application/pdf';
            const res = this.aetnaApsFormsService.b64toBlob(this.apsData.LetterBytes, mimeType, 512);
            const blob = new Blob([res], {type: mimeType});
            const fileObjectURL = URL.createObjectURL(blob);

            if (window.navigator.appVersion.toString().indexOf('.NET') > 0) { // for IE browser
              const nav = (window.navigator as any);
              if (nav.msSaveOrOpenBlob) {
                nav.msSaveOrOpenBlob(blob, fileObjectURL);
              }
            } else {
              this.loading = false;
              const link = document.createElement('a');
              link.href = fileObjectURL;
              link.download = 'APS Form ' + this.claimId;
              link.click();
            }
          } else {
            this.loading = false;
          }
        }, (error) => {
          this._responseErrorHandlerService.handleThaaErrorForSubmit(Error);
          this.loading = false;
        }
      )
    }
  }

}
