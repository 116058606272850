import {Component, Input, OnInit} from '@angular/core';
import { SessionConstants } from '@app/shared/constants/session.constants';
import { StorageManagementService } from '@app/shared/services/storage-management.service';
import {FooterContentService} from '../../../../shared/services/footer.service';

@Component({
  selector: 'app-footer',
  templateUrl: 'footer.component.html',
  styleUrls: ['footer.component.scss']
})
export class FooterComponent implements OnInit {

  @Input() pageTitle: string;
  footer_links: string[] = [];
  copyright: any;
  trademark: string;
  year: number;
  public hideFooter: boolean = false;
  constructor(private footerContentService: FooterContentService,
    private storageManagement: StorageManagementService) { }

  ngOnInit() {
    this.footer_links = this.footerContentService.getFooterContent().footer_links;
    this.copyright = this.footerContentService.getFooterContent().copyright;
    this.trademark = this.copyright.trademark;
    this.year = new Date().getFullYear();
    if (window.location.pathname === '/psv') {
      const loginStateJSON = JSON.parse(this.storageManagement.retrieveSessionObject(SessionConstants.LOGIN_STATE));
      this.hideFooter = (loginStateJSON) ? loginStateJSON.loginState : false;
    }
  }

  linkLocation(linksLocation) {
    window.open(linksLocation.linkVal);
  }
}
