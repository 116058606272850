export class OverpaymentLabelsConstants {
  static  WARNING_MESSAGE = 'Wait a few minutes and then refresh the page. We’re sorry for any inconvenience. Please note: If you’re inactive for 15 minutes, this session will automatically close and you’ll have to start over.';
  static ERROR_TITLE = 'Our system isn’t responding right now';
  static ERROR_TITLE_PAYMENT_YOU_OWE_STEP_1 = 'It looks like our servers aren’t responding';
  static WARNING_MESSAGE_PAYMENT_YOU_OWE_STEP_1 = 'Give it a minute or two and try submitting again. Your session will time out after 15 minutes of inactivity. If that happens, you’ll have to start over.';
  static TRUE = 'true';
  static ERROR_SM = 'error';
  static PAYMENT_SUBMISSION = 'Payment Submission';
  static PAGE_TITLE = 'pageTitle';
  static STEP_1 = 'step1';
  static STEP_2 = 'step2';
  static PAYMENT_AMOUNT = 'paymentAmount';
  static AMOUNT_DUE = 'amountDue';
  static SELECT_AMOUNT = 'selectAmount';
  static TOTAL_AMOUNT_DUE = 'Total Amount Due: ';
  static OTHER_AMOUNT = 'Other Amount';
  static CONTINUE = 'Continue';
  static CANCEL = 'Cancel';
  static CARD_INFORMATION = 'cardInformation';
  static PAYMENT_TODAY = 'paymentToday';
  static ANNOUNCEMENT_MESSAGE = 'announcementMessage';
  static DOLLAR_BILLS_ALT_TEXT = 'Dollar Bills Icon';
  static CREDIT_CARD_ALT_TEXT = 'Credit Card Icon';
  static INVALID_AMOUT_ERROR_MESSAGE = 'amountErrorMsg';
  static PRINT_RECEIPT = 'Print';
  static DOWNLOAD_RECEIPT = 'Download';
  static MAKE_ANOTHER_PAYMENT = 'Make Another Payment';
  static RETURN_HOME = 'Return Home';
  static CANCEL_MODAL = 'cancelModal';
  static CANCEL_MODAL_TITLE = 'Are you sure you want to exit?';
  static CANCEL_MODAL_PARA = 'If you made any changes, they won\'t be saved.';
  static EXIT_BTN_TEXT = 'Exit without saving changes';
  static LETTERS = ' Letters';
  static OTHER_AMOUNT_ERROR = 'OtherAmountError';
  static DOWNLOADING_RECEIPT = 'Loading';
  static TRY_AGAIN = 'Try Again';
  static DOWNLOAD = 'Download';
  static CVV_FAILURE_RESPONSE_CODES = ['n', 's', 'u', 'x'];
  static CARD_TYPES =  {
    '001': 'MasterCard',
    '002': 'Visa',
    '003': 'Disc',
    '006': 'Amex'
  };
  static MOBILE_NUMBER_OWCS = '&nbsp;&lt;&lt;Number&gt;&gt;';

  // Time out modal start
  static DURATION_FOR_TIMEOUT = 600;  // 600 = 10 minutes
  static TIMEOUT_MODAL = 'timeoutModal';
  static TIMEOUT_MODAL_TITLE = 'Your session expired';
  static TIMEOUT_MODAL_PARA = 'If you\'ve entered any credit card information, we deleted it to protect your security.';
  static RE_ENTER_CARD_INFORMATION_BTN = 'Re-Enter Card Information';
  static BACK_TO_OVERPAYMENTS_BTN = 'Back To Overpayments';
  static FAILED = 'FAILED';
  static DECLINED = 'DECLINED';
  static SUCCESS = 'SUCCESS';
  static PAYMENT_LABEL = 'How much do you want to pay'
  static ANALYTICS_PAYMENT_TEXT = 'Other Amount – How much do you want to pay'
  // Time out modal end

  //Analytics
 static ALL_CLAIM_URL = '/allPayments/allClaimPayments';
 static OVERPAYMENT_ALLCLAIM_MSG = 'You owe money on overpaid claim';
 static ANALYTICS_OPC_023_Body = 'Something Went Wrong - This may happened for a number of reasons, such as card limit.You can try making this payment again.';
 static ANALYTICS_OPC_025_Body = 'Something Went Wrong - Please reach to help desk for more information or try making this payment again.';
 static ANALYTICS_OPC_024_Body = 'Something Went Wrong - We\'re still having trouble processing your payment due to an invalid CVV. You can call us or try making this payment again.';
 static ANALYTICS_OPC_022_Body = 'Something Went Wrong - The payment didn\'t go through due to an invalid CVV. You can try making this payment again.';
 static ANALYTICS_SUBMIT_PAYMENT = 'Submit – payments you owe';
 static ANALYTICS_OTHER_AMOUNT = 'How much do you want to pay – Other Amount';
 static ANALYTICS_OTHER_AMOUNT_ERROR = 'You must enter an amount between $10 and the amount due';
 static ANALYTICS_CLOSE_MODAL =  'Are you sure you want to exit – Payments You Owe'
 static ANALYTICS_PAGE_TAG = 'Page Tag';
 static ANALYTICS_BUTTON_CLICK = 'Button Click';
 static ANALYTICS_LINK_CLICK = 'Link Click';
 static ANALYTICS_MODEL_CLOSE = 'modalclose';
 static ANALYTICS_LEARN_MORE = 'Learn More';
 static ANALYTICS_PAYMENT_HISTORY_LABEL = 'Payment History'
 static ANALYTICS_PAY_HISTORY_CLOSE = 'Payment History - Accordion Close';
 static ANALYTICS_PAY_HISTORY_OPEN = 'Payment History - Accordion Open';
 static ANALYTICS_LEARN_MORE_CLOSE = 'Where can I learn more - Accordion Close';
 static ANALYTICS_LEARN_MORE_OPEN = 'Where can I learn more - Accordion Open';
 static PAYMENT_YOU_OWE_LABEL = 'Payments You Owe';
 static ANALYTICS_BACK = 'Back';
 static ANALYTICS_FORM_ERROR_EVENT_PARENT = 'Form Field Validation Error';
 static ANALYTICS_FORM_ERROR_EVENT_TYPE = 'form_error_message';
 static ANALYTICS_CANCEL_PAYMENT_MODEL = 'Cancel – payments you owe';
 static ANALYTICS_INPUT_TEXT = 'Input Field Click - (text)';
 static ANALYTICS_MODAL_WINDOW_IMPRESSION = 'Modal Window Impression'
 static maskAccountNumber(val) {
    return val ? val.toString().replace(/.(?=.{4})/g, 'X') : '';
  }
}
