import {gbLeaveOverviewResponse, leavePlans} from '../shared/models/gbLeave.model';


export const MOCK_GB_LEAVE_OVERVIEW: gbLeaveOverviewResponse = {
  errorList: null,
  userId: '1111',
  sessionIdentifier: '123',
  personID: '2397',
  LeaveList: [
    {
      leaveId: '12345678',
      leaveType: 'Continuous Leave' ,
      dateLeaveOpened: '01/01/1970',
      leaveProcessDate: '01/01/1970',
      leaveStartDate: '06/14/2017',
      leaveEndDate: '07/10/2017',
      leaveReason: 'Leave Tile Header',
      leaveEstimatedEndDate: '02/01/1970',
      caseId: '456789 ',
      leaveStatus: 'Open',
      leaveStatusId: '01',
      leaveSubStatus: 'Missing Paperwork',
      leaveSubStatusId: '478',
      relatedDisabilityClaimsList: ['123', '456', '789']
    },
    {
      leaveId: '12345678',
      leaveType: 'Reduced Leave' ,
      dateLeaveOpened: '01/01/1970',
      leaveProcessDate: '01/01/1970',
      leaveStartDate: '06/14/2017',
      leaveEndDate: '07/10/2017',
      leaveReason: 'Leave Tile Header',
      leaveEstimatedEndDate: '02/01/1970',
      caseId: '456789 ',
      leaveStatus: 'Open',
      leaveStatusId: '01',
      leaveSubStatus: 'Missing Paperwork',
      leaveSubStatusId: '478',
      relatedDisabilityClaimsList: ['123', '456', '789']
    },
    {
      leaveId: '12345678',
      leaveType: 'Intermittent Leave' ,
      dateLeaveOpened: '01/01/1970',
      leaveProcessDate: '01/01/1970',
      leaveStartDate: '06/14/2017',
      leaveEndDate: '07/10/2017',
      leaveReason: 'Leave Tile Header',
      leaveEstimatedEndDate: '02/01/1970',
      caseId: '456789 ',
      leaveStatus: 'Open',
      leaveStatusId: '01',
      leaveSubStatus: 'Missing Paperwork',
      leaveSubStatusId: '478',
      relatedDisabilityClaimsList: ['123', '456', '789']
    },
    {
      leaveId: 'Leave Id',
      leaveType: 'Leave Type' ,
      dateLeaveOpened: '01/01/2018',
      leaveProcessDate: '01/15/2018',
      leaveEndDate: '07/10/2017',
      leaveStartDate: 'Leave Start Date',
      leaveReason: 'Leave Reason',
      leaveEstimatedEndDate: '02/03/2018',
      caseId: '456789',
      leaveStatus: 'Leave Status',
      leaveStatusId: '02',
      leaveSubStatus: 'No sub status',
      leaveSubStatusId: '567',
      relatedDisabilityClaimsList: []
    }
    ]
};

export const MOCK_GB_LEAVE_PLAN_DETAIL: leavePlans = {
  leavePlanName: 'Takeda Company FMLA',
  planList: [{
    planId: '256',
    planName: 'COMPANY',
    startDate: '04/01/2019',
    endDate: '04/01/2019',
    continuity: 'Intermittent',
    lastChangeDate: '06/20/2019',
    changeAction: 'APPROVED',
    changeActionId: '1',
    requestActionId: '833829385',
    leavePlanName: 'Takeda Company FMLA',
    leavePlanType: 'COMPANY',
    leavePlanDescription: 'Except as explained below, you have a right under the FMLA for up to 12 weeks of unpaid leave in a 12-month period for the care of a spouse, child, or parent, domestic partner, or for your own serious illness. Your health benefits will be maintained during any period of approved unpaid leave under the same conditions as if you continued to work, and are subject to your continued employee contributions. You may be reinstated to the same or an equivalent job with the same pay, benefits, and terms and conditions of employment on your return from leave.',
    denialReason: ''
  }, {
    planId: '256',
    planName: 'COMPANY',
    startDate: '06/19/2019',
    endDate: '06/19/2019',
    continuity: 'Intermittent',
    lastChangeDate: '06/20/2019',
    changeAction: 'CANCELLED',
    changeActionId: '4',
    requestActionId: '837902170',
    leavePlanName: 'Takeda Company FMLA',
    leavePlanType: 'COMPANY',
    leavePlanDescription: 'Except as explained below, you have a right under the FMLA for up to 12 weeks of unpaid leave in a 12-month period for the care of a spouse, child, or parent, domestic partner, or for your own serious illness. Your health benefits will be maintained during any period of approved unpaid leave under the same conditions as if you continued to work, and are subject to your continued employee contributions. You may be reinstated to the same or an equivalent job with the same pay, benefits, and terms and conditions of employment on your return from leave.',
    denialReason: ''
  }, {
    planId: '256',
    planName: 'COMPANY',
    startDate: '04/03/2019',
    endDate: '04/03/2019',
    continuity: 'Intermittent',
    lastChangeDate: '06/20/2019',
    changeAction: 'APPROVED',
    changeActionId: '1',
    requestActionId: '833829394',
    leavePlanName: 'Takeda Company FMLA',
    leavePlanType: 'COMPANY',
    leavePlanDescription: 'Except as explained below, you have a right under the FMLA for up to 12 weeks of unpaid leave in a 12-month period for the care of a spouse, child, or parent, domestic partner, or for your own serious illness. Your health benefits will be maintained during any period of approved unpaid leave under the same conditions as if you continued to work, and are subject to your continued employee contributions. You may be reinstated to the same or an equivalent job with the same pay, benefits, and terms and conditions of employment on your return from leave.',
    denialReason: ''
  }, {
    planId: '256',
    planName: 'COMPANY',
    startDate: '04/08/2019',
    endDate: '04/08/2019',
    continuity: 'Intermittent',
    lastChangeDate: '06/20/2019',
    changeAction: 'APPROVED',
    changeActionId: '1',
    requestActionId: '833829403',
    leavePlanName: 'Takeda Company FMLA',
    leavePlanType: 'COMPANY',
    leavePlanDescription: 'Except as explained below, you have a right under the FMLA for up to 12 weeks of unpaid leave in a 12-month period for the care of a spouse, child, or parent, domestic partner, or for your own serious illness. Your health benefits will be maintained during any period of approved unpaid leave under the same conditions as if you continued to work, and are subject to your continued employee contributions. You may be reinstated to the same or an equivalent job with the same pay, benefits, and terms and conditions of employment on your return from leave.',
    denialReason: ''
  }, {
    planId: '256',
    planName: 'COMPANY',
    startDate: '04/10/2019',
    endDate: '04/10/2019',
    continuity: 'Intermittent',
    lastChangeDate: '06/20/2019',
    changeAction: 'APPROVED',
    changeActionId: '1',
    requestActionId: '833829412',
    leavePlanName: 'Takeda Company FMLA',
    leavePlanType: 'COMPANY',
    leavePlanDescription: 'Except as explained below, you have a right under the FMLA for up to 12 weeks of unpaid leave in a 12-month period for the care of a spouse, child, or parent, domestic partner, or for your own serious illness. Your health benefits will be maintained during any period of approved unpaid leave under the same conditions as if you continued to work, and are subject to your continued employee contributions. You may be reinstated to the same or an equivalent job with the same pay, benefits, and terms and conditions of employment on your return from leave.',
    denialReason: ''
  }, {
    planId: '256',
    planName: 'COMPANY',
    startDate: '04/15/2019',
    endDate: '04/15/2019',
    continuity: 'Intermittent',
    lastChangeDate: '06/20/2019',
    changeAction: 'APPROVED',
    changeActionId: '1',
    requestActionId: '833829421',
    leavePlanName: 'Takeda Company FMLA',
    leavePlanType: 'COMPANY',
    leavePlanDescription: 'Except as explained below, you have a right under the FMLA for up to 12 weeks of unpaid leave in a 12-month period for the care of a spouse, child, or parent, domestic partner, or for your own serious illness. Your health benefits will be maintained during any period of approved unpaid leave under the same conditions as if you continued to work, and are subject to your continued employee contributions. You may be reinstated to the same or an equivalent job with the same pay, benefits, and terms and conditions of employment on your return from leave.',
    denialReason: ''
  }, {
    planId: '256',
    planName: 'COMPANY',
    startDate: '04/17/2019',
    endDate: '04/17/2019',
    continuity: 'Intermittent',
    lastChangeDate: '06/20/2019',
    changeAction: 'APPROVED',
    changeActionId: '1',
    requestActionId: '833829430',
    leavePlanName: 'Takeda Company FMLA',
    leavePlanType: 'COMPANY',
    leavePlanDescription: 'Except as explained below, you have a right under the FMLA for up to 12 weeks of unpaid leave in a 12-month period for the care of a spouse, child, or parent, domestic partner, or for your own serious illness. Your health benefits will be maintained during any period of approved unpaid leave under the same conditions as if you continued to work, and are subject to your continued employee contributions. You may be reinstated to the same or an equivalent job with the same pay, benefits, and terms and conditions of employment on your return from leave.',
    denialReason: ''
  }, {
    planId: '256',
    planName: 'COMPANY',
    startDate: '04/19/2019',
    endDate: '04/19/2019',
    continuity: 'Intermittent',
    lastChangeDate: '06/20/2019',
    changeAction: 'APPROVED',
    changeActionId: '1',
    requestActionId: '833829439',
    leavePlanName: 'Takeda Company FMLA',
    leavePlanType: 'COMPANY',
    leavePlanDescription: 'Except as explained below, you have a right under the FMLA for up to 12 weeks of unpaid leave in a 12-month period for the care of a spouse, child, or parent, domestic partner, or for your own serious illness. Your health benefits will be maintained during any period of approved unpaid leave under the same conditions as if you continued to work, and are subject to your continued employee contributions. You may be reinstated to the same or an equivalent job with the same pay, benefits, and terms and conditions of employment on your return from leave.',
    denialReason: ''
  }, {
    planId: '256',
    planName: 'COMPANY',
    startDate: '04/22/2019',
    endDate: '04/22/2019',
    continuity: 'Intermittent',
    lastChangeDate: '06/20/2019',
    changeAction: 'APPROVED',
    changeActionId: '1',
    requestActionId: '833829448',
    leavePlanName: 'Takeda Company FMLA',
    leavePlanType: 'COMPANY',
    leavePlanDescription: 'Except as explained below, you have a right under the FMLA for up to 12 weeks of unpaid leave in a 12-month period for the care of a spouse, child, or parent, domestic partner, or for your own serious illness. Your health benefits will be maintained during any period of approved unpaid leave under the same conditions as if you continued to work, and are subject to your continued employee contributions. You may be reinstated to the same or an equivalent job with the same pay, benefits, and terms and conditions of employment on your return from leave.',
    denialReason: ''
  }, {
    planId: '256',
    planName: 'COMPANY',
    startDate: '04/25/2019',
    endDate: '04/25/2019',
    continuity: 'Intermittent',
    lastChangeDate: '06/20/2019',
    changeAction: 'APPROVED',
    changeActionId: '1',
    requestActionId: '833829457',
    leavePlanName: 'Takeda Company FMLA',
    leavePlanType: 'COMPANY',
    leavePlanDescription: 'Except as explained below, you have a right under the FMLA for up to 12 weeks of unpaid leave in a 12-month period for the care of a spouse, child, or parent, domestic partner, or for your own serious illness. Your health benefits will be maintained during any period of approved unpaid leave under the same conditions as if you continued to work, and are subject to your continued employee contributions. You may be reinstated to the same or an equivalent job with the same pay, benefits, and terms and conditions of employment on your return from leave.',
    denialReason: ''
  }, {
    planId: '256',
    planName: 'COMPANY',
    startDate: '05/01/2019',
    endDate: '05/01/2019',
    continuity: 'Intermittent',
    lastChangeDate: '06/20/2019',
    changeAction: 'PENDED',
    changeActionId: '3',
    requestActionId: '837902169',
    leavePlanName: 'Takeda Company FMLA',
    leavePlanType: 'COMPANY',
    leavePlanDescription: 'Except as explained below, you have a right under the FMLA for up to 12 weeks of unpaid leave in a 12-month period for the care of a spouse, child, or parent, domestic partner, or for your own serious illness. Your health benefits will be maintained during any period of approved unpaid leave under the same conditions as if you continued to work, and are subject to your continued employee contributions. You may be reinstated to the same or an equivalent job with the same pay, benefits, and terms and conditions of employment on your return from leave.',
    denialReason: ''
  }, {
    planId: '256',
    planName: 'COMPANY',
    startDate: '06/20/2019',
    endDate: '06/20/2019',
    continuity: 'Intermittent',
    lastChangeDate: '06/20/2019',
    changeAction: 'PENDED',
    changeActionId: '3',
    requestActionId: '837902171',
    leavePlanName: 'Takeda Company FMLA',
    leavePlanType: 'COMPANY',
    leavePlanDescription: 'Except as explained below, you have a right under the FMLA for up to 12 weeks of unpaid leave in a 12-month period for the care of a spouse, child, or parent, domestic partner, or for your own serious illness. Your health benefits will be maintained during any period of approved unpaid leave under the same conditions as if you continued to work, and are subject to your continued employee contributions. You may be reinstated to the same or an equivalent job with the same pay, benefits, and terms and conditions of employment on your return from leave.',
    denialReason: ''
  }, {
    planId: '256',
    planName: 'COMPANY',
    startDate: '08/14/2019',
    endDate: '08/14/2019',
    continuity: '',
    lastChangeDate: '08/05/2019',
    changeAction: 'PENDED',
    changeActionId: '3',
    requestActionId: '837902172',
    leavePlanName: 'Takeda Company FMLA',
    leavePlanType: 'COMPANY',
    leavePlanDescription: 'Except as explained below, you have a right under the FMLA for up to 12 weeks of unpaid leave in a 12-month period for the care of a spouse, child, or parent, domestic partner, or for your own serious illness. Your health benefits will be maintained during any period of approved unpaid leave under the same conditions as if you continued to work, and are subject to your continued employee contributions. You may be reinstated to the same or an equivalent job with the same pay, benefits, and terms and conditions of employment on your return from leave.',
    denialReason: ''
  }]
};

export const MOCK_GB_LEAVE_HISTORY: gbLeaveOverviewResponse = {
  errorList: null,
  userId: '1111',
  sessionIdentifier: '123',
  personID: '2397',
  LeaveList: [
    {
      leaveId: '12345678',
      leaveType: 'Continuous Leave' ,
      dateLeaveOpened: '01/01/1970',
      leaveProcessDate: '01/01/1970',
      leaveStartDate: '06/14/2017',
      leaveReason: 'Leave Tile Header',
      leaveEndDate: '07/10/2017',
      leaveEstimatedEndDate: '02/01/1970',
      caseId: '456789 ',
      leaveStatus: 'Closed',
      leaveStatusId: '01',
      leaveSubStatus: 'Missing Paperwork',
      leaveSubStatusId: '478',
      relatedDisabilityClaimsList: ['123', '456', '789']
    },
    {
      leaveId: '12345678',
      leaveType: 'Reduced Leave' ,
      dateLeaveOpened: '01/01/1970',
      leaveProcessDate: '01/01/1970',
      leaveStartDate: '06/14/2017',
      leaveReason: 'Leave Tile Header',
      leaveEndDate: '07/10/2017',
      leaveEstimatedEndDate: '02/01/1970',
      caseId: '456789 ',
      leaveStatus: 'Closed',
      leaveStatusId: '01',
      leaveSubStatus: 'Missing Paperwork',
      leaveSubStatusId: '478',
      relatedDisabilityClaimsList: ['123', '456', '789']
    },
    {
      leaveId: '12345678',
      leaveType: 'Intermittent Leave' ,
      dateLeaveOpened: '01/01/1970',
      leaveProcessDate: '01/01/1970',
      leaveStartDate: '06/14/2017',
      leaveEndDate: '07/10/2017',
      leaveReason: 'Leave Tile Header',
      leaveEstimatedEndDate: '02/01/1970',
      caseId: '456789 ',
      leaveStatus: 'Closed',
      leaveStatusId: '01',
      leaveSubStatus: 'Missing Paperwork',
      leaveSubStatusId: '478',
      relatedDisabilityClaimsList: ['123', '456', '789']
    },
    {
      leaveId: 'Leave Id',
      leaveType: 'Leave Type' ,
      dateLeaveOpened: '01/01/2018',
      leaveProcessDate: '01/15/2018',
      leaveEndDate: '07/10/2017',
      leaveStartDate: 'Leave Start Date',
      leaveReason: 'Leave Reason',
      leaveEstimatedEndDate: '02/03/2018',
      caseId: '456789',
      leaveStatus: 'Leave Status',
      leaveStatusId: '02',
      leaveSubStatus: 'No sub status',
      leaveSubStatusId: '567',
      relatedDisabilityClaimsList: []
    }
  ]
};
