import {Component, Input, OnInit} from '@angular/core';
import {ButtonComponent} from '../../../sub-atomic/button/button.component';

@Component({
  selector: 'tertiary-button',
  templateUrl: 'tertiary-button.component.html',
  styleUrls: ['tertiary-button.component.scss']
})
export class TertiaryButtonComponent extends ButtonComponent implements OnInit {

  @Input() logoName: string;
  @Input() className: string;
  @Input() imgAltAttr: string;
  @Input() labelWeight = 'bold';

  ngOnInit() {
    if (this.className === undefined) {
      this.className = 'contactUsClass';
     } else if (this.className === 'contactUsClass') {
      this.className = 'contactUsClass';
    } else if (this.className === 'scheduleCallClass') {
      this.className = 'scheduleCallClass';
    } else  if (this.className === 'health-provider') {
      this.labelWeight = 'normal';
      this.className = 'health-provider';
    } else  if (this.className === 'health-provider-maternity') {
      this.labelWeight = 'normal';
      this.className = 'health-provider-maternity'
    } else {
      this.className = 'btn-primary';
    }
  }
}


