import {Observable} from 'rxjs';
import {throwError as observableThrowError} from 'rxjs/internal/observable/throwError';
import {catchError, map} from 'rxjs/operators';
import {Injectable} from '@angular/core';
import {ResponseErrorHandlerService} from './response-error-handler.service';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {env} from '@env/environment-loader';
import {SubmitAppointmentModel} from '../models/aetnaSubmitAppointment.model';
import {AppLabelConstants} from '@shared/constants/appLabel.constants';
import {AetnaSubmitAppointmentServiceResponseModel} from '@shared/services/service-response.model';

@Injectable()
export class AetnaSubmitAppointmentService {

  constructor( private errorHandler: ResponseErrorHandlerService,
               private httpClient: HttpClient) { }

  submitAppointment(requestData: SubmitAppointmentModel): Observable<any> {
    const request = JSON.stringify(requestData);
    return this.httpClient.post(env.thaaWKABPortalService.submitAppointmentURL, request,
    {
      headers: new HttpHeaders()
        .set(AppLabelConstants.CONTENT_TYPE_KEY, AppLabelConstants.APPLICATION_KEY)
    }).pipe(
    map(
      (response: AetnaSubmitAppointmentServiceResponseModel) => {
        if (!this.errorHandler.isServiceResponseHasError(response)) {
          return response;
        } else {
          throw response;
        }
      }
    ), catchError((error: any) => this.errorHandler.handleError(error)),
    );
  }

  private handleError(error: Response) {
    return observableThrowError(error);
  }
}

