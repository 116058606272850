import { Injectable } from '@angular/core';
import {OwcsResponseModel} from '@shared/models/owcs-response.model';

@Injectable({
  providedIn: 'root'
})

export class OwcsUtilityService {
  constructor() { }

  /*
  * create a map of owcs content from the content object and passed id(s)
  * @param owcsContent    - owcs content object to fetch data from
  * @param owcsContentId  - id(s) to be used as key for the map
  * @return               - map of id as key and label content as value
  * */
  createOWCSMap(owcsContent: OwcsResponseModel, owcsContentId: string[]): Map<string, string> {
    const owcsMap: Map<string, string> = new Map();

    owcsContentId.forEach(id => {
      owcsMap.set(id, this.getOWCSContentValue(owcsContent, id));
    });

    return owcsMap;
  }

  /*
  * get value for owcs item from corresponding label
  * @param owcsContent - owcs content object to fetch data from
  * @param label       - label to find and fetch data for from owcs content object
  * @return            - label to display on UI
  * */
  getOWCSContentValue(owcsContent: OwcsResponseModel, label: string): string {
    if (owcsContent?.data && owcsContent.data[label]?.value) {
      const div = document.createElement('div');
      div.innerHTML = owcsContent.data[label].value;
      return div.innerText.trim();
    }
    return '';
  }

}
