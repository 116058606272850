import {Component, OnInit} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {AppLabelConstants} from '../../../shared/constants/appLabel.constants';

@Component({
  selector: 'app-blaze',
  templateUrl: './blaze.component.html',
  styleUrls: ['./blaze.component.scss']
})
export class BlazeComponent implements OnInit {
  public blazeResponse;
  public blazeUrl;
  public blazeError;
  constructor(
    private httpClient: HttpClient
  ) { }

  ngOnInit() {
    this.blazeUrl = AppLabelConstants.URL_BLAZE;

    this.httpClient.get(this.blazeUrl).subscribe(
      res => {
        this.blazeResponse = res;
      },
      err => {
        this.blazeError = err;
      }
    );

  }

}
