
import {catchError, map} from 'rxjs/operators';
  import { Injectable } from '@angular/core';
import {ResponseErrorHandlerService} from '@shared/services/response-error-handler.service';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {env} from '@env/environment-loader';
import {AppLabelConstants} from '@shared/constants/appLabel.constants';


@Injectable()
export class ToggleService {

  constructor(private errorHandler: ResponseErrorHandlerService,
              private httpClient: HttpClient) {
  }

  public employeeToggle(personId: string) {
    return this.httpClient.get(env.thaaWKABPortalService.employeeToggle + '&personId=' + personId,
      {
        headers: new HttpHeaders()
          .set(AppLabelConstants.CONTENT_TYPE_KEY, AppLabelConstants.APPLICATION_KEY)
      }).pipe(map(
      (response: Response) => {
        if (!this.errorHandler.isServiceResponseHasError(response)) {
          return response;
        } else {
          throw response;
        }
      }
    ), catchError((error: any) => this.errorHandler.handleErrorThaa(error)), );
  }

}
