import { Component, OnInit, Input } from '@angular/core';
import {AppLabelConstants} from '@shared/constants/appLabel.constants';
import {Router} from '@angular/router';
import {StorageManagementService} from '@shared/services/storage-management.service';
import {AdaTimelineConstants} from '@app/cxr/leave-details-flow/ada-requests-timeline/shared/constants/ada-timeline-constants';

@Component({
  selector: 'timeline-step',
  templateUrl: './timeline-step.component.html',
  styleUrls: ['./timeline-step.component.scss']
})
export class TimelineStepComponent implements OnInit {
  @Input() state: string;
  @Input() statusText: string;
  @Input() subStatusText: string;
  @Input() statusIconPath: string;
  @Input() labelSize: string = AppLabelConstants.STATUS_FONT_SIZE_DEFAULT;
  @Input() labelWeight: string = AppLabelConstants.STATUS_FONT_WEIGHT_DEFAULT;
  @Input() labelColor: string = AppLabelConstants.STATUS_FONT_COLOR_DEFAULT;
  appLabelConstants = {...AppLabelConstants, ...AdaTimelineConstants};
  statusToBeDoneActive: boolean = false;
  splitSubStatus: boolean;
  subsStatusFirstHalf: string;
  subsStatusSecondHalf: string;
  timelineData;

  constructor(private storageManagementService: StorageManagementService,
              private router: Router) { }

  ngOnInit() {
    this.setIconForStatus();
    this.splitSubStatusForDocuments();
    this.timelineData = this.storageManagementService.getSessionData(this.appLabelConstants.SELECTED_TILE_DETAILS);
  }

  /*
  * splits the cert sub status if it exists and converts the text 'Your Documents' to a hyperlink which navigates to Leave Details on click
  * */
  splitSubStatusForDocuments() {
    if (this.subStatusText && this.subStatusText.toLowerCase().includes(this.appLabelConstants.YOUR_DOCUMENTS.toLowerCase())) {
      const subStatus = this.subStatusText.split(this.appLabelConstants.YOUR_DOCUMENTS);
      this.subsStatusFirstHalf = subStatus[0] ? subStatus[0] : '';
      this.subsStatusSecondHalf = subStatus[1] ? subStatus[1] : '';
      this.splitSubStatus = true;
    }
  }

  /*
  * navigate to Leave Details page
  * */
  navigateToDocumentsSection() {
    this.router.navigate([this.appLabelConstants.LEAVE_DETAIL_PAGE_URL], {queryParams: {scrollTo: this.appLabelConstants.YOUR_DOCUMENTS_HEADER}});
  }

  /**
   * function to set the appropriate icon for the state of the timeline-step
   */
  setIconForStatus() {
    switch (this.state) {
      case this.appLabelConstants.STATUS_CURRENT :
        this.statusIconPath = this.appLabelConstants.STATUS_IN_PROGRESS_ICON_SVG;
        break;
      case this.appLabelConstants.STATUS_COMPLETE :
        this.statusIconPath = this.appLabelConstants.STATUS_COMPLETE_ICON_SVG;
        break;
      case this.appLabelConstants.STATUS_TO_BE_DONE :
        this.statusIconPath = this.appLabelConstants.STATUS_TO_BE_DONE_ICON_SVG;
        this.statusToBeDoneActive = true;
        break;
      case this.appLabelConstants.STATUS_DENIED :
        this.statusIconPath = this.appLabelConstants.STATUS_DENIED_ICON_SVG;
        break;
      default:
        this.statusIconPath = this.appLabelConstants.STATUS_TO_BE_DONE_ICON_SVG;
    }
  }
}
