import {INTAKE_OWCS_IDS} from '@app/cxr/modules/intake/shared/JSON/intake-owcs.constants';

export const EDIT_WORK_SCHEDULE_IDS = [
  INTAKE_OWCS_IDS.NI_8871_Body, INTAKE_OWCS_IDS.NI_8873_Body, INTAKE_OWCS_IDS.NI_8874_Body, INTAKE_OWCS_IDS.NI_8875_Body, INTAKE_OWCS_IDS.NI_8876_Body,
  INTAKE_OWCS_IDS.NI_8877_Body, INTAKE_OWCS_IDS.NI_8878_Body, INTAKE_OWCS_IDS.NI_8879_Body, INTAKE_OWCS_IDS.NI_8883_Body, INTAKE_OWCS_IDS.NI_8884_Body,
  INTAKE_OWCS_IDS.NI_8885_Body, INTAKE_OWCS_IDS.NI_8882_Body, INTAKE_OWCS_IDS.NI_8855_Body, INTAKE_OWCS_IDS.NI_8866_Body, INTAKE_OWCS_IDS.NI_8881_Body,
  INTAKE_OWCS_IDS.NI_8886_Body, INTAKE_OWCS_IDS.NI_8889_Body, INTAKE_OWCS_IDS.NI_8890_Body, INTAKE_OWCS_IDS.NI_948_Body, INTAKE_OWCS_IDS.NI_8890A_Body,
  INTAKE_OWCS_IDS.NI_8890B_Body, INTAKE_OWCS_IDS.NI_8890C_Body, INTAKE_OWCS_IDS.NI_8890D_Body, INTAKE_OWCS_IDS.NI_8893_Body, INTAKE_OWCS_IDS.NI_882_Body
];

export const TAKING_LEAVE_BONDING_VIEW_IE_IDS = [
  INTAKE_OWCS_IDS.IE_003_Body, INTAKE_OWCS_IDS.IE_004_Body, INTAKE_OWCS_IDS.IE_005_Body, INTAKE_OWCS_IDS.IE_148_Body,
  INTAKE_OWCS_IDS.IE_149_Body, INTAKE_OWCS_IDS.IE_152_Body, INTAKE_OWCS_IDS.IE_154_Body
];

export const TAKING_LEAVE_BONDING_VIEW_NI_IDS = [
  INTAKE_OWCS_IDS.NI_8869_Body, INTAKE_OWCS_IDS.NI_8870_Body
];

export const TAKING_LEAVE_BONDING_IDS = [
  INTAKE_OWCS_IDS.IE_010_Body, INTAKE_OWCS_IDS.IE_011_Body, INTAKE_OWCS_IDS.IE_025_Body, INTAKE_OWCS_IDS.IE_016_Body, INTAKE_OWCS_IDS.IE_017_Body,
  INTAKE_OWCS_IDS.IE_020_Body, INTAKE_OWCS_IDS.IE_022_Body, INTAKE_OWCS_IDS.IE_007_Body, INTAKE_OWCS_IDS.IE_008_Body, INTAKE_OWCS_IDS.IE_009_Body,
  INTAKE_OWCS_IDS.IE_018_Body, INTAKE_OWCS_IDS.IE_019_Body, INTAKE_OWCS_IDS.IE_028_Body, INTAKE_OWCS_IDS.IE_029_Body, INTAKE_OWCS_IDS.IE_030_Body,
  INTAKE_OWCS_IDS.IE_031_Body, INTAKE_OWCS_IDS.IE_032_Body, INTAKE_OWCS_IDS.IE_033_Body, INTAKE_OWCS_IDS.IE_034_Body, INTAKE_OWCS_IDS.IE_035_Body,
  INTAKE_OWCS_IDS.IE_027_Body, INTAKE_OWCS_IDS.IE_026_Body, INTAKE_OWCS_IDS.IE_038_Body, INTAKE_OWCS_IDS.IE_012_Body, INTAKE_OWCS_IDS.IE_013_Body,
  INTAKE_OWCS_IDS.IE_039_Body, INTAKE_OWCS_IDS.IE_040_Body, INTAKE_OWCS_IDS.IE_041_Body, INTAKE_OWCS_IDS.IE_042_Body, INTAKE_OWCS_IDS.IE_013_Body,
  INTAKE_OWCS_IDS.IE_036_Body, INTAKE_OWCS_IDS.IE_044_Body, INTAKE_OWCS_IDS.IE_045_Body, INTAKE_OWCS_IDS.IE_046_Body, INTAKE_OWCS_IDS.IE_048_Body,
  INTAKE_OWCS_IDS.IE_049_Body, INTAKE_OWCS_IDS.IE_068_Body, INTAKE_OWCS_IDS.IE_068A_Body, INTAKE_OWCS_IDS.IE_068B_Body, INTAKE_OWCS_IDS.IE_068C_Body,
  INTAKE_OWCS_IDS.IE_069_Body, INTAKE_OWCS_IDS.IE_069A_Body, INTAKE_OWCS_IDS.IE_069B_Body, INTAKE_OWCS_IDS.IE_069C_Body, INTAKE_OWCS_IDS.IE_050_Body,
  INTAKE_OWCS_IDS.IE_037_Body, INTAKE_OWCS_IDS.IE_043_Body, INTAKE_OWCS_IDS.IE_048A_Body, INTAKE_OWCS_IDS.IE_054_Body, INTAKE_OWCS_IDS.IE_055_Body,
  INTAKE_OWCS_IDS.IE_056_Body, INTAKE_OWCS_IDS.IE_057_Body, INTAKE_OWCS_IDS.IE_058_Body, INTAKE_OWCS_IDS.IE_059_Body, INTAKE_OWCS_IDS.IE_060_Body,
  INTAKE_OWCS_IDS.IE_061_Body, INTAKE_OWCS_IDS.IE_062_Body, INTAKE_OWCS_IDS.IE_063_Body, INTAKE_OWCS_IDS.IE_064_Body, INTAKE_OWCS_IDS.IE_024_Body,
  INTAKE_OWCS_IDS.IE_065_Body, INTAKE_OWCS_IDS.IE_065A_Body, INTAKE_OWCS_IDS.IE_065B_Body, INTAKE_OWCS_IDS.IE_066_Body, INTAKE_OWCS_IDS.IE_066A_Body,
  INTAKE_OWCS_IDS.IE_066B_Body, INTAKE_OWCS_IDS.IE_067_Body, INTAKE_OWCS_IDS.IE_067A_Body, INTAKE_OWCS_IDS.IE_067B_Body, INTAKE_OWCS_IDS.IE_030A_Body,
  INTAKE_OWCS_IDS.IE_070_Body, INTAKE_OWCS_IDS.IE_071_Body, INTAKE_OWCS_IDS.IE_072_Body, INTAKE_OWCS_IDS.IE_030A_Body, INTAKE_OWCS_IDS.IE_047_Body,
  INTAKE_OWCS_IDS.IE_051_Body, INTAKE_OWCS_IDS.IE_052_Body, INTAKE_OWCS_IDS.IE_053_Body, INTAKE_OWCS_IDS.IE_073_Body, INTAKE_OWCS_IDS.IE_074_Body,
  INTAKE_OWCS_IDS.IE_075_Body, INTAKE_OWCS_IDS.IE_077_Body, INTAKE_OWCS_IDS.IE_077A_Body, INTAKE_OWCS_IDS.IE_077B_Body, INTAKE_OWCS_IDS.IE_078_Body,
  INTAKE_OWCS_IDS.IE_078A_Body, INTAKE_OWCS_IDS.IE_079_Body, INTAKE_OWCS_IDS.IE_079A_Body, INTAKE_OWCS_IDS.IE_079B_Body, INTAKE_OWCS_IDS.IE_079C_Body,
  INTAKE_OWCS_IDS.IE_079D_Body, INTAKE_OWCS_IDS.IE_079E_Body, INTAKE_OWCS_IDS.IE_079F_Body, INTAKE_OWCS_IDS.IE_082_Body, INTAKE_OWCS_IDS.IE_083_Body, INTAKE_OWCS_IDS.IE_084_Body,
  INTAKE_OWCS_IDS.IE_084A_Body, INTAKE_OWCS_IDS.IE_099_Body, INTAKE_OWCS_IDS.IE_102_Body, INTAKE_OWCS_IDS.IE_079G_Body, INTAKE_OWCS_IDS.IE_079H_Body,
  INTAKE_OWCS_IDS.IE_079I_Body, INTAKE_OWCS_IDS.IE_079J_Body, INTAKE_OWCS_IDS.IE_079K_Body, INTAKE_OWCS_IDS.IE_100_Body, INTAKE_OWCS_IDS.IE_101_Body,
  INTAKE_OWCS_IDS.IE_105_Body, INTAKE_OWCS_IDS.IE_106_Body, INTAKE_OWCS_IDS.IE_085_Body, INTAKE_OWCS_IDS.IE_086_Body, INTAKE_OWCS_IDS.IE_087_Body,
  INTAKE_OWCS_IDS.IE_088_Body, INTAKE_OWCS_IDS.IE_090_Body, INTAKE_OWCS_IDS.IE_091_Body, INTAKE_OWCS_IDS.IE_089_Body, INTAKE_OWCS_IDS.IE_092_Body,
  INTAKE_OWCS_IDS.IE_093_Body, INTAKE_OWCS_IDS.IE_094_Body, INTAKE_OWCS_IDS.IE_079L_Body, INTAKE_OWCS_IDS.IE_107_Body, INTAKE_OWCS_IDS.IE_108_Body,
  INTAKE_OWCS_IDS.IE_109_Body, INTAKE_OWCS_IDS.IE_110_Body, INTAKE_OWCS_IDS.IE_118_Body, INTAKE_OWCS_IDS.IE_111_Body, INTAKE_OWCS_IDS.IE_112_Body, INTAKE_OWCS_IDS.IE_113_Body,
  INTAKE_OWCS_IDS.IE_114_Body, INTAKE_OWCS_IDS.IE_135_Body, INTAKE_OWCS_IDS.IE_116_Body, INTAKE_OWCS_IDS.IE_117_Body, INTAKE_OWCS_IDS.IE_119_Body,
  INTAKE_OWCS_IDS.IE_131_Body, INTAKE_OWCS_IDS.IE_132_Body, INTAKE_OWCS_IDS.IE_133_Body, INTAKE_OWCS_IDS.IE_136_Body, INTAKE_OWCS_IDS.IE_137_Body,
  INTAKE_OWCS_IDS.IE_138_Body, INTAKE_OWCS_IDS.IE_145_Body, INTAKE_OWCS_IDS.IE_146_Body, INTAKE_OWCS_IDS.IE_130_Body, INTAKE_OWCS_IDS.IE_130A_Body, INTAKE_OWCS_IDS.IE_129_Body,
  INTAKE_OWCS_IDS.IE_143_Body, INTAKE_OWCS_IDS.IE_144_Body, INTAKE_OWCS_IDS.IE_142_Body, INTAKE_OWCS_IDS.IE_156_Body, INTAKE_OWCS_IDS.IE_147_Body,
  INTAKE_OWCS_IDS.IE_103_Body, INTAKE_OWCS_IDS.IE_104_Body, INTAKE_OWCS_IDS.IE_097_Body, INTAKE_OWCS_IDS.IE_098_Body, INTAKE_OWCS_IDS.IE_158_Body,
  INTAKE_OWCS_IDS.IE_159_Body, INTAKE_OWCS_IDS.IE_095_Body
];

export const ENTER_LEAVE_SCHEDULE_SECTION_IDS = [
  INTAKE_OWCS_IDS.IE_012_Body, INTAKE_OWCS_IDS.IE_013_Body, INTAKE_OWCS_IDS.IE_074_Body, INTAKE_OWCS_IDS.IE_079G_Body, INTAKE_OWCS_IDS.IE_079H_Body,
  INTAKE_OWCS_IDS.IE_086_Body, INTAKE_OWCS_IDS.IE_087_Body, INTAKE_OWCS_IDS.IE_088_Body, INTAKE_OWCS_IDS.IE_096_Body, INTAKE_OWCS_IDS.IE_097_Body,
  INTAKE_OWCS_IDS.IE_098_Body, INTAKE_OWCS_IDS.IE_115_Body, INTAKE_OWCS_IDS.IE_127_Body, INTAKE_OWCS_IDS.IE_128_Body, INTAKE_OWCS_IDS.IE_129_Body,
  INTAKE_OWCS_IDS.IE_130_Body, INTAKE_OWCS_IDS.IE_134_Body, INTAKE_OWCS_IDS.IE_142_Body, INTAKE_OWCS_IDS.IE_147_Body, INTAKE_OWCS_IDS.IE_150_Body,
  INTAKE_OWCS_IDS.IE_139_Body, INTAKE_OWCS_IDS.IE_140_Body, INTAKE_OWCS_IDS.IE_095_Body, INTAKE_OWCS_IDS.IE_157_Body
];

export const INTAKE_NON_WORKDAY_MODAL = [
  INTAKE_OWCS_IDS.NI_952_Body, INTAKE_OWCS_IDS.NI_952A_Body, INTAKE_OWCS_IDS.NI_952AA_Body, INTAKE_OWCS_IDS.NI_952AB_Body, INTAKE_OWCS_IDS.NI_952B_Body,
  INTAKE_OWCS_IDS.NI_952C_Body, INTAKE_OWCS_IDS.NI_952D_Body, INTAKE_OWCS_IDS.NI_952E_Body, INTAKE_OWCS_IDS.NI_952F_Body, INTAKE_OWCS_IDS.NI_952G_Body
];

export const MAX_TIME_USED_MODEL = [
  INTAKE_OWCS_IDS.IE_127_Body, INTAKE_OWCS_IDS.IE_151_Body, INTAKE_OWCS_IDS.IE_153_Body, INTAKE_OWCS_IDS.IE_155_Body
];

export const EXTENSION_CONFIRMATION_PAGE_IDS = [
  INTAKE_OWCS_IDS.IE_076_Body, INTAKE_OWCS_IDS.IE_076A_Body, INTAKE_OWCS_IDS.IE_076B_Body, INTAKE_OWCS_IDS.IE_076C_Body,
  INTAKE_OWCS_IDS.IE_076D_Body, INTAKE_OWCS_IDS.IE_076E_Body, INTAKE_OWCS_IDS.IE_076F_Body, INTAKE_OWCS_IDS.IE_076G_Body,
  INTAKE_OWCS_IDS.IE_076H_Body, INTAKE_OWCS_IDS.IE_076I_Body
];

export const ABOUT_YOUR_BONDING_IDS = [
  INTAKE_OWCS_IDS.NI_561_Body, INTAKE_OWCS_IDS.NI_562_Body]
;
