import {Component, Input} from '@angular/core';

@Component({
  selector: 'primary-light-button',
  templateUrl: 'primary-light-button.component.html',
  styleUrls: ['primary-light-button.component.scss']
})
export class PrimaryLightButtonComponent {

  @Input() buttonText: string;
  @Input() buttonSize: string;
  @Input() btnId: string;
  @Input() buttonDisabled = false;

  buttonClicked(event) {
    if ( event.target.dataset.disabled === 'true' ) {
      event.preventDefault();
      event.stopImmediatePropagation();
      event.stopPropagation();
    }
  }
}
