
import {catchError, map} from 'rxjs/operators';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { env } from '@env/environment-loader';
import { ResponseErrorHandlerService } from './response-error-handler.service';
import {Observable} from '../../../../node_modules/rxjs';
import { AppLabelConstants } from '@app/shared/constants/appLabel.constants';
import { NewRaeSubmitResponse } from '../models/newRAESubmitResponse.model';

@Injectable()
export class NewRaeFinalSubmitService {

  constructor(private errorHandler: ResponseErrorHandlerService,
    private httpClient: HttpClient) { }
  public postRaeFinalResponse(request): Observable<any> {
    return this.httpClient.post(env.thaaWKABPortalService.newRAEFinalSubmit, request,
      {
        headers: new HttpHeaders()
          .set(AppLabelConstants.CONTENT_TYPE_KEY, AppLabelConstants.APPLICATION_KEY)
      }).pipe(map(
      (response: Response) => {
        if (!this.errorHandler.isServiceResponseHasError(response)) {
          return response;
        } else {
          throw response;
        }
      }
    ), catchError((error: any) => this.errorHandler.handleErrorThaa(error)), );
  }

}
