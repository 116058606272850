import {GbBase} from './gb-base';

export class GbTrillium extends GbBase {

  getUrls() {
    return {
      getAreaFromZipCodeURL:  this.getBaseUrl() + 'gb-trillium-service/trillium/v1/lookupZipCode/zipCode=' + this.getClientId('auth'),
      cleanseAddressURL : this.getBaseUrl() + 'gb-trillium-service/trillium/v1/cleanseStreetAddress?' + this.getClientId('auth'),
    }
  }
}

