
import { catchError, map } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { Observable, of } from '../../../../node_modules/rxjs';
import { ResponseErrorHandlerService } from './response-error-handler.service';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { AppLabelConstants } from '../constants/appLabel.constants';
import { env } from '../../../environments/environment-loader';
import { StorageManagementService } from '@shared/services/storage-management.service';
import { SessionConstants } from '../constants/session.constants';
import { futurePaymentExist, IFuturePaymentsExistList } from '../models/futurePaymentsExist.model';

@Injectable()
export class FuturePaymentExistServiceService {
   futurePaymentArray: IFuturePaymentsExistList[] = [];

  constructor(private errorHandler: ResponseErrorHandlerService,
    private storeManagementService: StorageManagementService,
    private httpClient: HttpClient) { }

  getFuturePaymentsExist(personId: string, claimId: string): Observable<any> {
      const futurePaymentData = this.storeManagementService.getSessionData(SessionConstants.IS_FUTURE_PAYMENT_EXIST_LIST);
      if (futurePaymentData && futurePaymentData.length > 0) {
        this.futurePaymentArray = futurePaymentData.filter(data => data.claimEventId === claimId);
      }
      if (this.futurePaymentArray && this.futurePaymentArray.length > 0) {
        if (this.futurePaymentArray[0].claimEventId === claimId) {
          const isFuturePaymentApplicableList: futurePaymentExist = Object.assign({});
          isFuturePaymentApplicableList.isFuturePaymentApplicable = this.futurePaymentArray[0].value;
          return of(isFuturePaymentApplicableList);
        }
      } else {
      return this.httpClient.get(env.thaaWKABPortalService.getFuturePaymentExistURL + '&personid=' + personId + '&claimId=' + claimId,
        {
          headers: new HttpHeaders()
            .set(AppLabelConstants.CONTENT_TYPE_KEY, AppLabelConstants.APPLICATION_KEY)
        }).pipe(
          map(
            (response: Response) => {
              if (!this.errorHandler.isServiceResponseHasError(response)) {
                return response;
              } else {
                throw response;
              }
            }
          ), catchError((error: any) => this.errorHandler.handleError(error)));
    }
  }
}
