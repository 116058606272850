import {Component, Input, OnInit} from '@angular/core';

@Component({
  selector: 'app-vol-prod-contact',
  templateUrl: './vol-prod-contact.component.html',
  styleUrls: ['./vol-prod-contact.component.scss']
})
export class VolProdContactComponent implements OnInit {

  @Input () productAvailable: string;
  constructor() { }
  ngOnInit() {
  }
}
