/**
 * Created by AC12212 on 8/10/2017.
 */
import { Notifications } from "../shared/models/notification.model";

export const NOTIFICATIONS: Notifications = {
  econsentDataResponseBeanList: [
    {
      econsentCustomerList: [
        {
          eConsentBusinessGroupPartyInfo: {
            businessGroupID: "4",
            fullName: "Mock-User One",
            firstName: "Mock-User",
            lastName: "One",
            namePrefix: "",
            nameSuffix: "",
            emailAddress: "ferdinand.aliaj@thehartford.com",
            dateOfBirth: "10/16/1940 12:00 AM EST",
            eConsentAddressInfo: {
              addressType: "Permanent",
              address1: "1",
              address2: "Lane",
              address3: "Street",
              city: "New York",
              stateCode: "NY",
              zipCode: "94558",
              zipCodeExtension: null,
            },
            eConsentPhoneNumber: null,
            eConsentGetContactPointInfoTypeList: null,
            consentID: "01",
          },
          eConsentAdminSystemParty: {
            sourceSystemPartyID: "100_01",
            adminSystemName: "DCS",
            claimInsuredID: null,
            caseID: null,
          },
          eConsentDocumentCategoryList: [
            {
              documentCategoryCode: "DCST",
              documentCategoryText: "",
              documentCategoryStatus: "",
              communicationMethod: "Web",
              deliveryMethod: "SMS",
              activeIndicator: "1",
              consentId: "01",
            },
          ],
          eConsent: null,
        },
      ],
    },
  ],
};

export const DELIVERY = {
  IntakeIcdPopup: "O82",
  Intakecptpopup: "59514",
  expectedDeliveryDate: "02/09/2024",
};

export const ABSENCE = {
  ReturnToWork: "1001",
};
