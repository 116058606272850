/**
 * Created by AC12212 on 3/20/2018.
 */
export const  CIAMACCOUNT = {
  result: [{
    _id: '31f08351-1f66-4451-9104-efe28fa9f228',
    inetuserstatus: 'active',
    eeprofilekey: '80001',
    objectClass: ['inetuser', 'top', 'EEOC', 'inetOrgPerson', 'organizationalPerson', 'person'],
    sn: 'HYZZSDF',
    dn: 'uid=Lexus_701@cxr.ciamtest.com,ou=EE_cust,dc=thehartford,dc=com',
    aliasList: [],
    givenName: 'NJZZSF',
    kbaInfo: [],
    idmUUID: '31f08351-1f66-4451-9104-efe28fa9f228',
    telephoneNumber: '860-834-1234',
    uid: 'Lexus_701@cxr.ciamtest.com',
    mail: 'Lexus_701@cxr.ciamtest.com',
    cn: 'NJZZSF HYZZSDF',
    ldapGroups: []
  }],
  resultCount: 1,
  pagedResultsCookie: null,
  totalPagedResultsPolicy: 'NONE',
  totalPagedResults: -1,
  remainingPagedResults: -1
}
