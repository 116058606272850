import {AfterViewInit, Component, Input, OnInit} from '@angular/core';
import {RecentlyResolvedClaimService} from '../../../shared/services/recentlyResolvedClaims.service';
import {Stack} from './Stack';
import {StorageManagementService} from '../../../shared/services/storage-management.service';
import {ContentService} from '../../../shared/services/content.service';
import {LeaveDynatraceAction} from '../../../shared/utils/leaveDynatraceAction';
import {OWCSCaasService} from '../../../shared/services/owcs-caas.service';
import {MissingItem} from '../../../shared/models/missing-info.model';
import {RecentlyResolvedAetnaClaimsService} from '../../../shared/services/recentlyResolvedAetnaClaims.service';
import {AppLabelConstants} from '../../../shared/constants/appLabel.constants';
import {AppConstants} from '../../../shared/constants/app.constants';
import {SessionConstants} from '../../../shared/constants/session.constants';
import {ErrorConstants} from '../../../shared/constants/error.constants';
import {LeaveListModel} from '../../../shared/models/gbLeave.model';
import {
  DoctorMissingDocumentThaa,
  DownloadLinkThaa, EmployeeMissingDocumentThaa, EmployerMissingDocumentThaa, MissingInfoAlertInfo,
  MissingItemThaa
} from '../../../shared/models/missingInfoThaa.model';
import {RecentlyResolvedAetnaLeaveService} from '../../../shared/services/recentlyResolvedAetnaLeave.service';
import {CustomEventService} from "@shared/services/tealium/custom-event.service";

@Component({
  selector: 'app-recently-resolved-thaa',
  templateUrl: './recently-resolved-thaa.component.html',
  styleUrls: ['./recently-resolved-thaa.component.scss']
})
export class RecentlyResolvedThaaComponent extends LeaveDynatraceAction implements OnInit, AfterViewInit {


  public appLabelConstants = AppLabelConstants;
   fromYouLbl = '';
   fromDrLbl = '';
   fromErLbl = '';
  public welcomeMessage = 'Recently Resolved Items';
   recentlResolvedData = [];
  informationFromYou = [];
  informationFromDr = [];
  informationFromEr = [];
  public recipientEE = [];
  public recipientER = [];
  public recipientDR = [];
  public listPhysicianLeave = [];
  public listPhysicianLeaveER = [];
  public listPhysicianLeaveDR = [];
   currentUser;
   idClicked: string;
   stack: Stack[] = [];
   top = -1;
  public showRecentlyResolved: boolean;
   imgURL: string;
   getResponse = [];
  public isYouExist: boolean;
  public isDrExist: boolean;
  public isEmprExist: boolean;
  public leaveClaim: boolean = false;
   data = [];
   claimEventId: string;
   pageId: string;
   owscContent;
  public recentlyResolvedThaa;
  public recentlyResolvedLeaveThaa;
   page_Title = '';
  // public requiredInfoFromDoctorDetails: DoctorMissingDocumentThaa[] = [];
  // public requiredInfoEmployeeDetails: EmployeeMissingDocumentThaa[] = [];
  // public requiredInfoEmployerDetails: EmployerMissingDocumentThaa[] = [];
   message = '';
   noRecentlyResolved = '';
   recentlyResolvedData;
   missingItems: MissingItem[] = [];
   rrDescription: string;
   descriptionValue;
   displayText;
   curClaim;
  public curLeave;
  public sentMailTo;
  public sentEmailTo;
  public sentFaxTo;
  // public whats_This = 'What\'s this?';
  public learnMreEE = '';
  public learnMreER = '';
  public learnMreDR = '';
  public needed_from = '';
  public needed_for = '';
  public DatesEE = '';
  public DatesER = '';
  public DatesDR = '';
  public Dates = '';
   claimNeeded;
   physicianDatesRR = '';
  @Input() leave;
  public learnMore;
  public leaveData: LeaveListModel;
  public currentRoute;
  public leaveId;
  public reviewCompleteDate = '';
  public initialRequestDate = '';
  public followUp = '';
  public finalFollowUp = '';
  public finalFollowUpMessage = '';


  constructor(private recentlyResolvedService: RecentlyResolvedClaimService,
              private recentlyResolvedAetnaService: RecentlyResolvedAetnaClaimsService,
              private recentlyResolvedAetnaLeaveService: RecentlyResolvedAetnaLeaveService,
              private storageManagementService: StorageManagementService,
              private contentService: ContentService,
              private customEventService: CustomEventService,
              private owcsCaasService: OWCSCaasService) {
    super(storageManagementService);
  }

  ngOnInit() {
    this.currentRoute = this.storageManagementService.getSessionData('currentPage');
    this.learnMore = 'Learn More';
    if (this.storageManagementService.getSessionData(SessionConstants.CURR_LEAVE)) {
      this.curLeave = this.storageManagementService.getSessionData(SessionConstants.CURR_LEAVE);
    }
    //OWCS service Integration
    this.pageId = this.contentService.getPageId(AppConstants.RECENTLY_RESOLVED_ITEMS);
    if (this.pageId) {
      this.contentService.getOWCSResponse(this.pageId, AppConstants.RECENTLY_RESOLVED_ITEMS_U).subscribe(
        val => {
          if (val) {
            this.owscContent = val;
            if (this.owscContent && this.owscContent.data) {
              if (this.owscContent.data.RRI_01_Label) {
                this.page_Title = this.owscContent.data.RRI_01_Label.value;
              }
              if (this.owscContent.data.RRI_03_Body) {
                this.message = this.owscContent.data.RRI_03_Body.value;
              }
              if (this.owscContent.data.RRI_25_Body) {
                this.noRecentlyResolved = this.owscContent.data.RRI_25_Body.value;
              }
              if (this.owscContent.data.RRI_40_Label) {
                this.physicianDatesRR = this.owscContent.data.RRI_40_Label.value;
              }
              if (this.owscContent.data.RRI_47_Label) {
                this.fromYouLbl = this.owscContent.data.RRI_47_Label.value;
              }
              if (this.owscContent.data.RRI_48_Label) {
                this.fromDrLbl = this.owscContent.data.RRI_48_Label.value;
              }
              if (this.owscContent.data.RRI_49_Label) {
                this.fromErLbl = this.owscContent.data.RRI_49_Label.value;
              }
              if (this.owscContent.data.RRI_101_Body) {
                this.learnMreEE = this.owscContent.data.RRI_101_Body.value;
              }
              if (this.owscContent.data.RRI_106_Body) {
                this.learnMreER = this.owscContent.data.RRI_106_Body.value;
              }
              if (this.owscContent.data.RRI_111_Body) {
                this.learnMreDR = this.owscContent.data.RRI_111_Body.value;
              }
              if (this.owscContent.data.RRI_107_Body) {
                this.needed_from = this.owscContent.data.RRI_107_Body.value;
              }
              if (this.owscContent.data.RRI_102_Body) {
                this.needed_for = this.owscContent.data.RRI_102_Body.value;
              }
              if (this.owscContent.data.RRI_103_Body) {
                this.DatesEE = this.owscContent.data.RRI_103_Body.value;
              }
              if (this.owscContent.data.RRI_108_Body) {
                this.DatesER = this.owscContent.data.RRI_108_Body.value;
              }
              if (this.owscContent.data.RRI_112_Body) {
                this.DatesDR = this.owscContent.data.RRI_112_Body.value;
              }
              if (this.owscContent.data.RRI_114_Body) {
                this.sentMailTo = this.owscContent.data.RRI_114_Body.value;
              }
              if (this.owscContent.data.RRI_115_Body) {
                this.sentEmailTo = this.owscContent.data.RRI_115_Body.value;
              }
              if (this.owscContent.data.RRI_116_Body) {
                this.sentFaxTo = this.owscContent.data.RRI_116_Body.value;
              }
              if (this.owscContent.data.RRI_117_Body) {
                this.reviewCompleteDate = this.owscContent.data.RRI_117_Body.value.replace(/(<([^>]+)>)/gi, '');
              }
              if (this.owscContent.data.RRI_118_Body) {
                this.initialRequestDate = this.owscContent.data.RRI_118_Body.value.replace(/(<([^>]+)>)/gi, '');
              }
              if (this.owscContent.data.RRI_119_Body) {
                this.followUp = this.owscContent.data.RRI_119_Body.value.replace(/(<([^>]+)>)/gi, '');
              }
              if (this.owscContent.data.RRI_121_Body) {
                this.finalFollowUp = this.owscContent.data.RRI_121_Body.value.replace(/(<([^>]+)>)/gi, '');
              }
              if (this.owscContent.data.RRI_122_Body) {
                this.finalFollowUpMessage = this.owscContent.data.RRI_122_Body.value;
              }
            }
          }
        });
   console.log(this.informationFromYou);
    }
    this.imgURL = AppLabelConstants.GREEN_CHECK_PNG;
    if (this.storageManagementService.retrieveSessionObject(SessionConstants.MISSING_ITEMS)) {
      this.missingItems = JSON.parse(this.storageManagementService.retrieveSessionObject(SessionConstants.MISSING_ITEMS)).missingItems;
    }
    if (this.storageManagementService.retrieveSessionObject(SessionConstants.CUR_CLAIM)) {
      this.curClaim = JSON.parse(this.storageManagementService.retrieveSessionObject(SessionConstants.CUR_CLAIM)).curClaim;
    }
    if (this.storageManagementService.retrieveSessionObject(SessionConstants.CURR_LEAVE)) {
      this.curLeave = JSON.parse(this.storageManagementService.getSessionData(SessionConstants.CURR_LEAVE)).curLeave;
    }
    if (this.curClaim &&  this.curClaim.sourceSystemId && this.curClaim.sourceSystemId.toUpperCase() === AppConstants.SOURCE_SYS_ID_AA &&
      this.currentRoute !== '/leave-details/leaveDetails') {
      console.log(this.currentRoute);
      this.leaveClaim = false;
      if (JSON.parse(sessionStorage.getItem(SessionConstants.CLAIM_EVENT_ID))) {
        this.claimEventId = JSON.parse(sessionStorage.getItem(SessionConstants.CLAIM_EVENT_ID)).claimEventId;
        if (this.claimEventId) {
          // this.data.push(this.recentlyResolvedService.getrecentlyResolved(this.claimEventId));
          if (this.storageManagementService.retrieveSessionObject(SessionConstants.MISSING_INFO_THAA)) {
            this.recentlyResolvedThaa = JSON.parse(this.storageManagementService.retrieveSessionObject(SessionConstants.MISSING_INFO_THAA));
          }
          this.recentlyResolvedAetnaService.getRecentlyResolvedAetnaClaims(this.claimEventId, this.curClaim.personId, '').subscribe(val => {
              if (val) {
                this.storageManagementService.setSessionData(SessionConstants.RECENTLY_RESOLVED_AETNA, val);
                /*pushdata
                 * Method name : getrecentlyResolved
                 * purpose : this method is used to call service response from the service
                 * created Date : 2/8/18
                 * Modified on :
                 *
                 * */
                this.data.push(val);

                if (this.data) { // null check
                  if (this.data[0].MissingInfoAlertInfo && this.data[0].MissingInfoAlertInfo.length > 0) {
                    console.log('recently resolved data', this.data);
                    this.showRecentlyResolved = true;
                  }
                  if (this.data && this.data[0].MissingInfoAlertInfo && this.data[0].MissingInfoAlertInfo[0].missingInfoList && this.data[0].MissingInfoAlertInfo[0].missingInfoList.length) {
                    for (let j = 0; j < this.data[0].MissingInfoAlertInfo[0].missingInfoList.length; j++) {
                      if (this.data[0].MissingInfoAlertInfo[0].missingInfoList[j].mInfoDesc && this.data[0].MissingInfoAlertInfo[0].missingInfoList[j].mInfoDesc.toLowerCase() === AppConstants.EMPLOYEE) {
                        if (this.data[0].MissingInfoAlertInfo[0].missingInfoList[j].mInfoReqInfo) {
                          const requiredInfoFromEmployee = this.data[0].MissingInfoAlertInfo[0].missingInfoList[j].mInfoReqInfo;
                          const downloadInfo: DownloadLinkThaa = this.ifFormIsDownloadable(requiredInfoFromEmployee);
                          const employeeMissingDocument: EmployeeMissingDocumentThaa = new EmployeeMissingDocumentThaa();
                          employeeMissingDocument.showByYou = false;
                          employeeMissingDocument.displayText = downloadInfo.displayName;
                          this.learnMreEE = employeeMissingDocument.displayText;
                          employeeMissingDocument.document = requiredInfoFromEmployee;
                          this.isYouExist = true;
                          for (let k = 0; k < this.data[0].MissingInfoAlertInfo[0].missingInfoList[j].mInfoReqInfo.length; k++) {
                            this.informationFromYou.push(this.data[0].MissingInfoAlertInfo[0].missingInfoList[j].mInfoReqInfo[k]);
                            this.informationFromYou[k].showDetailsFlag = this.data[0].MissingInfoAlertInfo[0].missingInfoList[j].mInfoReqInfo[k].detailInd;
                            this.informationFromYou[k].showDetails = false;
                            this.descriptionValue = this.getDescriptionData(this.data[0].MissingInfoAlertInfo[0].missingInfoList[j].mInfoReqInfo[k].mInfoReqInfoCd);
                            this.informationFromYou[k].description = this.descriptionValue ? this.descriptionValue : '';
                            this.informationFromYou[k].recipient = this.data[0].MissingInfoAlertInfo[0].missingInfoList[j].mInfoReqInfo[k].recipient;
                            if (this.data[0].MissingInfoAlertInfo[0].missingInfoList[j].mInfoReqInfo[k].recipient) {
                              for (let l = 0; l < this.data[0].MissingInfoAlertInfo[0].missingInfoList[j].mInfoReqInfo[k].recipient.length; l++) {
                                if (this.data[0].MissingInfoAlertInfo[0].missingInfoList[j].mInfoReqInfo[k].recipient[l]) {
                                  if (this.data[0].MissingInfoAlertInfo[0].missingInfoList[j].mInfoReqInfo[k].recipient[l].mailingAddress) {
                                    this.data[0].MissingInfoAlertInfo[0].missingInfoList[j].mInfoReqInfo[k].recipient[l].mailingAddress = this.data[0].MissingInfoAlertInfo[0].missingInfoList[j].mInfoReqInfo[k].recipient[l].mailingAddress.replace(/,/g, ', ');
                                  }
                                }
                              }
                            }
                            if (this.data[0].MissingInfoAlertInfo[0].missingInfoList[j].mInfoReqInfo[k].recipient) {
                              for (let l = 0; l < this.data[0].MissingInfoAlertInfo[0].missingInfoList[j].mInfoReqInfo[k].recipient.length; l++) {
                                if (this.data[0].MissingInfoAlertInfo[0].missingInfoList[j].mInfoReqInfo[k].recipient[l]) {
                                  if (this.data[0].MissingInfoAlertInfo[0].missingInfoList[j].mInfoReqInfo[k].recipient[l].faxNumber) {
                                    this.data[0].MissingInfoAlertInfo[0].missingInfoList[j].mInfoReqInfo[k].recipient[l].faxNumber = this.data[0].MissingInfoAlertInfo[0].missingInfoList[j].mInfoReqInfo[k].recipient[l].faxNumber.replace(/(\d{3})(\d{3})(\d{4})/, '($1) $2-$3');
                                  }
                                }
                              }
                            }
                          }
                        }
                      } else if (this.data[0].MissingInfoAlertInfo[0].missingInfoList[j].mInfoDesc && this.data[0].MissingInfoAlertInfo[0].missingInfoList[j].mInfoDesc.toLowerCase() === AppConstants.DOCTOR) {
                        for (let k = 0; k < this.data[0].MissingInfoAlertInfo[0].missingInfoList[j].mInfoReqInfo.length; k++) {
                          if (this.data[0].MissingInfoAlertInfo[0].missingInfoList[j].mInfoReqInfo[k]) {
                            const requiredInfoFromDoctor = this.data[0].MissingInfoAlertInfo[0].missingInfoList[j].mInfoReqInfo;
                            const downloadInfo: DownloadLinkThaa = this.ifFormIsDownloadable(requiredInfoFromDoctor);
                            const employeeMissingDocument: EmployeeMissingDocumentThaa = new EmployeeMissingDocumentThaa();
                            employeeMissingDocument.showByYou = false;
                            employeeMissingDocument.displayText = downloadInfo.displayName;
                            this.learnMreDR = employeeMissingDocument.displayText;
                            employeeMissingDocument.document = requiredInfoFromDoctor;
                            this.isDrExist = true;
                            this.informationFromDr.push(this.data[0].MissingInfoAlertInfo[0].missingInfoList[j].mInfoReqInfo[k]);
                            this.informationFromDr[k].showDetailsFlag = this.data[0].MissingInfoAlertInfo[0].missingInfoList[j].mInfoReqInfo[k].detailInd;
                            this.informationFromDr[k].showDetails = false;
                            this.descriptionValue = this.getDescriptionData(this.data[0].MissingInfoAlertInfo[0].missingInfoList[j].mInfoReqInfo[k].mInfoReqInfoCd);
                            this.informationFromDr[k].description = this.descriptionValue ? this.descriptionValue : '';
                            this.informationFromDr[k].recipient = this.data[0].MissingInfoAlertInfo[0].missingInfoList[j].mInfoReqInfo[k].recipient;
                            if (this.data[0].MissingInfoAlertInfo[0].missingInfoList[j].mInfoReqInfo[k].recipient) {
                              for (let l = 0; l < this.data[0].MissingInfoAlertInfo[0].missingInfoList[j].mInfoReqInfo[k].recipient.length; l++) {
                                if (this.data[0].MissingInfoAlertInfo[0].missingInfoList[j].mInfoReqInfo[k].recipient[l]) {
                                  if (this.data[0].MissingInfoAlertInfo[0].missingInfoList[j].mInfoReqInfo[k].recipient[l].mailingAddress) {
                                    this.data[0].MissingInfoAlertInfo[0].missingInfoList[j].mInfoReqInfo[k].recipient[l].mailingAddress = this.data[0].MissingInfoAlertInfo[0].missingInfoList[j].mInfoReqInfo[k].recipient[l].mailingAddress.replace(/,/g, ', ');
                                  }
                                }
                              }
                            }
                            if (this.data[0].MissingInfoAlertInfo[0].missingInfoList[j].mInfoReqInfo[k].recipient) {
                              for (let l = 0; l < this.data[0].MissingInfoAlertInfo[0].missingInfoList[j].mInfoReqInfo[k].recipient.length; l++) {
                                if (this.data[0].MissingInfoAlertInfo[0].missingInfoList[j].mInfoReqInfo[k].recipient[l]) {
                                  if (this.data[0].MissingInfoAlertInfo[0].missingInfoList[j].mInfoReqInfo[k].recipient[l].faxNumber) {
                                    this.data[0].MissingInfoAlertInfo[0].missingInfoList[j].mInfoReqInfo[k].recipient[l].faxNumber = this.data[0].MissingInfoAlertInfo[0].missingInfoList[j].mInfoReqInfo[k].recipient[l].faxNumber.replace(/(\d{3})(\d{3})(\d{4})/, '($1) $2-$3');
                                  }
                                }
                              }
                            }
                          }
                        }
                      } else if (this.data[0].MissingInfoAlertInfo[0].missingInfoList[j].mInfoDesc && this.data[0].MissingInfoAlertInfo[0].missingInfoList[j].mInfoDesc.toLowerCase() === AppConstants.EMPLOYER) {
                        if (this.data[0].MissingInfoAlertInfo[0].missingInfoList[j].mInfoReqInfo) {
                          const requiredInfoFromEmployer = this.data[0].MissingInfoAlertInfo[0].missingInfoList[j].mInfoReqInfo;
                          const downloadInfo: DownloadLinkThaa = this.ifFormIsDownloadable(requiredInfoFromEmployer);
                          const employeeMissingDocument: EmployeeMissingDocumentThaa = new EmployeeMissingDocumentThaa();
                          employeeMissingDocument.showByYou = false;
                          employeeMissingDocument.displayText = downloadInfo.displayName;
                          this.learnMreER = employeeMissingDocument.displayText;
                          employeeMissingDocument.document = requiredInfoFromEmployer;
                          this.isEmprExist = true;
                          for (let k = 0; k < this.data[0].MissingInfoAlertInfo[0].missingInfoList[j].mInfoReqInfo.length; k++) {
                            this.informationFromEr.push(this.data[0].MissingInfoAlertInfo[0].missingInfoList[j].mInfoReqInfo[k]);
                            this.informationFromEr[k].showDetailsFlag = this.data[0].MissingInfoAlertInfo[0].missingInfoList[j].mInfoReqInfo[k].detailInd;
                            this.informationFromEr[k].showDetails = false;
                            this.descriptionValue = this.getDescriptionData(this.data[0].MissingInfoAlertInfo[0].missingInfoList[j].mInfoReqInfo[k].mInfoReqInfoCd);
                            this.informationFromEr[k].description = this.descriptionValue ? this.descriptionValue : '';
                            this.informationFromEr[k].recipient = this.data[0].MissingInfoAlertInfo[0].missingInfoList[j].mInfoReqInfo[k].recipient;
                          }
                        }
                      }
                    }
                    // }
                  }
                } else {
                  this.showRecentlyResolved = false;
                }
              } else {
                this.showRecentlyResolved = false;
              }

            },
            (error) => {
              console.log('Error' + error);
            });
        } else {
          this.showRecentlyResolved = false;
        }
      } else {
        this.showRecentlyResolved = false;
      }
    }  else if (this.curLeave && (this.currentRoute === this.appLabelConstants.URL_LEAVE_DETAILS || this.currentRoute === '/leave-details/leaveDetails?scrollTo=missingInfoHeader')) {
      this.leaveClaim = true;
      if (this.storageManagementService.retrieveSessionObject(SessionConstants.MISSING_ITEMS)) {
        this.missingItems = JSON.parse(this.storageManagementService.retrieveSessionObject(SessionConstants.MISSING_ITEMS)).missingItems;
      }
      //this.leaveId = this.leaveData.leaveId;
      if (this.curLeave && this.curLeave.leaveId && this.curLeave.personId) {
        if (this.storageManagementService.retrieveSessionObject(SessionConstants.MISSING_INFO_THAA)) {
          this.recentlyResolvedLeaveThaa = JSON.parse(this.storageManagementService.retrieveSessionObject(SessionConstants.MISSING_INFO_THAA));
          this.claimEventId = '';
        }
        this.recentlyResolvedAetnaLeaveService.getRecentlyResolvedLeaveThaa(this.curLeave.leaveId, this.curLeave.personId).subscribe(val => {
            if (val) {
              this.storageManagementService.setSessionData(SessionConstants.RECENTLY_RESOLVED_LEAVE, val);
              /*pushdata
               * Method name : getrecentlyResolved
               * purpose : this method is used to call service response from the service
               * created Date : 2/8/18
               * Modified on :
               *
               * */
              this.data.push(val);

              if (this.data) { // null check
                this.showRecentlyResolved = true;
                if (this.data && this.data[0].MissingInfoAlertInfo && this.data[0].MissingInfoAlertInfo[0]) {
                  // for (let i = 0; i < this.data[0].MissingInfoAlertInfo.length; i++) {
                  for (let j = 0; j < this.data[0].MissingInfoAlertInfo[0].missingInfoList.length; j++) {
                    if (this.data[0].MissingInfoAlertInfo[0].missingInfoList[j].mInfoDesc && this.data[0].MissingInfoAlertInfo[0].missingInfoList[j].mInfoDesc.toLowerCase() === AppConstants.EMPLOYEE) {
                      if (this.data[0].MissingInfoAlertInfo[0].missingInfoList[j].mInfoReqInfo) {
                        const requiredInfoFromEmployee = this.data[0].MissingInfoAlertInfo[0].missingInfoList[j].mInfoReqInfo;
                        const downloadInfo: DownloadLinkThaa = this.ifFormIsDownloadable(requiredInfoFromEmployee);
                        const employeeMissingDocument: EmployeeMissingDocumentThaa = new EmployeeMissingDocumentThaa();
                        employeeMissingDocument.showByYou = false;
                        employeeMissingDocument.displayText = downloadInfo.displayName;
                        this.learnMreEE = employeeMissingDocument.displayText;
                        employeeMissingDocument.document = requiredInfoFromEmployee;
                        this.isYouExist = true;
                        for (let k = 0; k < this.data[0].MissingInfoAlertInfo[0].missingInfoList[j].mInfoReqInfo.length; k++) {
                          this.informationFromYou.push(this.data[0].MissingInfoAlertInfo[0].missingInfoList[j].mInfoReqInfo[k]);
                          this.informationFromYou[k].showDetailsFlag = this.data[0].MissingInfoAlertInfo[0].missingInfoList[j].mInfoReqInfo[k].detailInd;
                          this.informationFromYou[k].showDetails = false;
                          this.descriptionValue = this.getDescriptionData(this.data[0].MissingInfoAlertInfo[0].missingInfoList[j].mInfoReqInfo[k].mInfoReqInfoCd);
                          this.informationFromYou[k].description = this.descriptionValue ? this.descriptionValue : '';
                          if (this.data[0].MissingInfoAlertInfo[0].missingInfoList[j].mInfoReqInfo[k].recipient) {
                            for (let l = 0; l < this.data[0].MissingInfoAlertInfo[0].missingInfoList[j].mInfoReqInfo[k].recipient.length; l++) {
                              if (this.data[0].MissingInfoAlertInfo[0].missingInfoList[j].mInfoReqInfo[k].recipient[l]) {
                                if (this.data[0].MissingInfoAlertInfo[0].missingInfoList[j].mInfoReqInfo[k].recipient[l].mailingAddress) {
                                  this.data[0].MissingInfoAlertInfo[0].missingInfoList[j].mInfoReqInfo[k].recipient[l].mailingAddress = this.data[0].MissingInfoAlertInfo[0].missingInfoList[j].mInfoReqInfo[k].recipient[l].mailingAddress.replace(/,/g, ', ');
                                }
                              }
                            }
                          }
                          if (this.data[0].MissingInfoAlertInfo[0].missingInfoList[j].mInfoReqInfo[k].recipient) {
                            for (let l = 0; l < this.data[0].MissingInfoAlertInfo[0].missingInfoList[j].mInfoReqInfo[k].recipient.length; l++) {
                              if (this.data[0].MissingInfoAlertInfo[0].missingInfoList[j].mInfoReqInfo[k].recipient[l]) {
                                if (this.data[0].MissingInfoAlertInfo[0].missingInfoList[j].mInfoReqInfo[k].recipient[l].faxNumber) {
                                  this.data[0].MissingInfoAlertInfo[0].missingInfoList[j].mInfoReqInfo[k].recipient[l].faxNumber = this.data[0].MissingInfoAlertInfo[0].missingInfoList[j].mInfoReqInfo[k].recipient[l].faxNumber.replace(/(\d{3})(\d{3})(\d{4})/, '($1) $2-$3');
                                }
                              }
                            }
                          }
                        }
                      }
                    } else if (this.data[0].MissingInfoAlertInfo[0].missingInfoList[j].mInfoDesc && this.data[0].MissingInfoAlertInfo[0].missingInfoList[j].mInfoDesc.toLowerCase() === AppConstants.DOCTOR) {
                      for (let k = 0; k < this.data[0].MissingInfoAlertInfo[0].missingInfoList[j].mInfoReqInfo.length; k++) {
                        if (this.data[0].MissingInfoAlertInfo[0].missingInfoList[j].mInfoReqInfo[k]) {
                          const requiredInfoFromDoctor = this.data[0].MissingInfoAlertInfo[0].missingInfoList[j].mInfoReqInfo;
                          const downloadInfo: DownloadLinkThaa = this.ifFormIsDownloadable(requiredInfoFromDoctor);
                          const employeeMissingDocument: EmployeeMissingDocumentThaa = new EmployeeMissingDocumentThaa();
                          employeeMissingDocument.showByYou = false;
                          employeeMissingDocument.displayText = downloadInfo.displayName;
                          this.learnMreDR = employeeMissingDocument.displayText;
                          employeeMissingDocument.document = requiredInfoFromDoctor;
                          this.isDrExist = true;
                          this.informationFromDr.push(this.data[0].MissingInfoAlertInfo[0].missingInfoList[j].mInfoReqInfo[k]);
                          this.informationFromDr[k].showDetailsFlag = this.data[0].MissingInfoAlertInfo[0].missingInfoList[j].mInfoReqInfo[k].detailInd;
                          this.informationFromDr[k].showDetails = false;
                          this.descriptionValue = this.getDescriptionData(this.data[0].MissingInfoAlertInfo[0].missingInfoList[j].mInfoReqInfo[k].mInfoReqInfoCd);
                          this.informationFromDr[k].description = this.descriptionValue ? this.descriptionValue : '';
                          if (this.data[0].MissingInfoAlertInfo[0].missingInfoList[j].mInfoReqInfo[k].recipient) {
                            for (let l = 0; l < this.data[0].MissingInfoAlertInfo[0].missingInfoList[j].mInfoReqInfo[k].recipient.length; l++) {
                              if (this.data[0].MissingInfoAlertInfo[0].missingInfoList[j].mInfoReqInfo[k].recipient[l]) {
                                if (this.data[0].MissingInfoAlertInfo[0].missingInfoList[j].mInfoReqInfo[k].recipient[l].mailingAddress) {
                                  this.data[0].MissingInfoAlertInfo[0].missingInfoList[j].mInfoReqInfo[k].recipient[l].mailingAddress = this.data[0].MissingInfoAlertInfo[0].missingInfoList[j].mInfoReqInfo[k].recipient[l].mailingAddress.replace(/,/g, ', ');
                                }
                              }
                            }
                          }
                          if (this.data[0].MissingInfoAlertInfo[0].missingInfoList[j].mInfoReqInfo[k].recipient) {
                            for (let l = 0; l < this.data[0].MissingInfoAlertInfo[0].missingInfoList[j].mInfoReqInfo[k].recipient.length; l++) {
                              if (this.data[0].MissingInfoAlertInfo[0].missingInfoList[j].mInfoReqInfo[k].recipient[l]) {
                                if (this.data[0].MissingInfoAlertInfo[0].missingInfoList[j].mInfoReqInfo[k].recipient[l].faxNumber) {
                                  this.data[0].MissingInfoAlertInfo[0].missingInfoList[j].mInfoReqInfo[k].recipient[l].faxNumber = this.data[0].MissingInfoAlertInfo[0].missingInfoList[j].mInfoReqInfo[k].recipient[l].faxNumber.replace(/(\d{3})(\d{3})(\d{4})/, '($1) $2-$3');
                                }
                              }
                            }
                          }
                        }
                      }
                    } else if (this.data[0].MissingInfoAlertInfo[0].missingInfoList[j].mInfoDesc && this.data[0].MissingInfoAlertInfo[0].missingInfoList[j].mInfoDesc.toLowerCase() === AppConstants.EMPLOYER) {
                      if (this.data[0].MissingInfoAlertInfo[0].missingInfoList[j].mInfoReqInfo) {
                        const requiredInfoFromEmployer = this.data[0].MissingInfoAlertInfo[0].missingInfoList[j].mInfoReqInfo;
                        const downloadInfo: DownloadLinkThaa = this.ifFormIsDownloadable(requiredInfoFromEmployer);
                        const employeeMissingDocument: EmployeeMissingDocumentThaa = new EmployeeMissingDocumentThaa();
                        employeeMissingDocument.showByYou = false;
                        employeeMissingDocument.displayText = downloadInfo.displayName;
                        this.learnMreER = employeeMissingDocument.displayText;
                        employeeMissingDocument.document = requiredInfoFromEmployer;
                        this.isEmprExist = true;
                        for (let k = 0; k < this.data[0].MissingInfoAlertInfo[0].missingInfoList[j].mInfoReqInfo.length; k++) {
                          this.informationFromEr.push(this.data[0].MissingInfoAlertInfo[0].missingInfoList[j].mInfoReqInfo[k]);
                          this.informationFromEr[k].showDetailsFlag = this.data[0].MissingInfoAlertInfo[0].missingInfoList[j].mInfoReqInfo[k].detailInd;
                          this.informationFromEr[k].showDetails = false;
                          this.descriptionValue = this.getDescriptionData(this.data[0].MissingInfoAlertInfo[0].missingInfoList[j].mInfoReqInfo[k].mInfoReqInfoCd);
                          this.informationFromEr[k].description = this.descriptionValue ? this.descriptionValue : '';
                        }
                      }
                    }
                  }
                  // }
                }
              } else {
                this.showRecentlyResolved = false;
              }
            } else {
              this.showRecentlyResolved = false;
            }

          },
          (error) => {
            console.log('Error' + error);
          });
      } else {
        this.showRecentlyResolved = false;
      }
    } else {
      this.showRecentlyResolved = false;
    }
  }

  ngAfterViewInit() {
    super.ngAfterViewInit();
  }
  //
  // toggleDetails(id, origin) {
  //   if (origin === AppConstants.INFORMATION_FROM_YOU) {
  //     this.informationFromYou[id].showDetails = !this.informationFromYou[id].showDetails;
  //   }
  //   if (origin === AppConstants.INFORMATION_FROM_DR) {
  //     this.informationFromDr[id].showDetails = !this.informationFromDr[id].showDetails;
  //   }
  //   if (origin === AppConstants.INFORMATION_FROM_ER) {
  //     this.informationFromEr[id].showDetails = !this.informationFromEr[id].showDetails;
  //   }
  //
  // }


  //console.log(this.top);
  /* this.idClicked=id;

   if(this.top===-1){
     this.stack.push({
       id : this.idClicked,
       state : true
     });
     this.top++;
     //console.log(this.stack);
   }
   else {
     //console.log('Non empty stack');
     if (this.stack[this.top].id === this.idClicked) {
       /!** user has clicked on the same id twice hence only toggle the state *!/
      // console.log('user has clicked on the same id twice hence only toggle the state ');
       this.stack[this.top].state = !this.stack[this.top].state;
     } else {
       /!** user has clicked on a different id *!/
       //console.log('user has clicked on a different id');
       this.stack.pop();
       this.top--;

       this.stack.push({
         id: this.idClicked,
         state: true
       });
       this.top++;
     }
   }
   this.state= this.stack[this.top].state;
 }*/

  getDescriptionData(rrCode) {
    let missingItemsOWCS: MissingItem[] = [];
    const values: string[] = this.owcsCaasService.getOWCSValue(rrCode);
    if (values && values.length >= 1) {
      if (this.missingItems.length > 0) {
        missingItemsOWCS = this.missingItems.filter(
          (missingItem: MissingItem) => {
            for (let i = 0; i < values.length; i++) {
              if (missingItem.key === values[i]) {
                // we got the match between owcs key and dcs key
                return true;
              }
            }
          }
        );

        //console.log('Got mappping',missingItemsOWCS);

        if (missingItemsOWCS.length >= 1) {
          return this.rrDescription = missingItemsOWCS[0].description;
        }
      }
    }
  }

  ifFormIsDownloadable(param): DownloadLinkThaa {
    const missingItemDownloadLink: DownloadLinkThaa = new DownloadLinkThaa();
    missingItemDownloadLink.description = param.recentlyInfoReqInfoDesc;
    missingItemDownloadLink.name = param.displayValue;
    missingItemDownloadLink.isDownloadable = param.downloadIndicator;
    missingItemDownloadLink.displayName = this.learnMore;
    return missingItemDownloadLink;
  }

  callTaggingSpec(eventId, event) {
    if (event.detail === 'open') {
      this.customEventService.trackHukPopOver(eventId);
    }
  }


  public sortByDate(benefits) {
    if (benefits) {
      benefits.sort(function(benefitOne, benefitTwo) {
        if (benefitOne instanceof Array) {
          benefitOne = benefitOne[0];
        }
        if (benefitTwo instanceof Array) {
          benefitTwo = benefitTwo[0];
        }
        const dateOne = benefitOne.hasOwnProperty('requestedDate') ? benefitOne.requestedDate : '';
        const dateTwo = benefitTwo.hasOwnProperty('requestedDate') ? benefitTwo.requestedDate : '';
        const dateDiff = new Date(dateTwo).getTime() - new Date(dateOne).getTime();

        return dateDiff;
      });
      return benefits;
    }
  }
}


