import {Observable} from 'rxjs';
import {throwError as observableThrowError} from 'rxjs/internal/observable/throwError';
import {catchError, map} from 'rxjs/operators';
import {Injectable} from '@angular/core';
import {ResponseErrorHandlerService} from './response-error-handler.service';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {CancelAppointmentModel} from '../models/aetnaCancelAppointment.model';
import {env} from '@env/environment-loader';
import {AppLabelConstants} from '@shared/constants/appLabel.constants';
import {GenericServiceResponseModel} from '@shared/services/service-response.model';

@Injectable()
export class AetnaCancelAppointmentService {

  constructor( private errorHandler: ResponseErrorHandlerService,
               private httpClient: HttpClient) { }

  cancelAppointment(requestData: CancelAppointmentModel): Observable<any> {
    const request = JSON.stringify(requestData);
    return this.httpClient.post(env.thaaWKABPortalService.cancelAppointmentURL, request,
      {
        headers: new HttpHeaders()
          .set(AppLabelConstants.CONTENT_TYPE_KEY, AppLabelConstants.APPLICATION_KEY)
      }).pipe(
      map(
        (response: GenericServiceResponseModel) => {
          if (!this.errorHandler.isServiceResponseHasError(response)) {
            return response;
          } else {
            throw response;
          }
        }
      ), catchError((error: any) =>
        //console.log("Inside Catch Handler");
         this.errorHandler.handleError(error) ), );
  }

  private handleError(error: Response) {
    //console.error("Error at Service layer:: "+ error);
    return observableThrowError(error);
  }
}
