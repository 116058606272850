
import {throwError as observableThrowError} from 'rxjs/internal/observable/throwError';
import {Observable, BehaviorSubject} from 'rxjs';
import {Injectable} from '@angular/core';
import {MessageRibbonService} from './message-ribbon.service';
import {Router} from '@angular/router';
import {HttpClient, HttpErrorResponse, HttpHeaders} from '@angular/common/http';
import {env} from '../../../environments/environment-loader';
import {StorageManagementService} from './storage-management.service';
import {SessionConstants} from '../constants/session.constants';
import {AppLabelConstants} from '../constants/appLabel.constants';
import {AppConstants} from '../constants/app.constants';
import {ErrorConstants} from '../constants/error.constants';
import {TokenUtility} from '../utils/tokenUtility';


@Injectable()
export class ResponseErrorHandlerService  {
  isServiceError = new BehaviorSubject(false);
  failedTransactionCtr = new BehaviorSubject(0);

  private pageId: string;
  private owcsContent;
  private sysDownKeyLogin: string = '';
  private sysDownKeyOverview: string = '';
  public currUser;
  public isClaimServiceHasError: boolean = false;
  public  defaultDownMessage: string = '';
  public newDownMessage: string = '';
  public isClaimsServiceCalled: boolean = false;
  public isContactServiceCalled: boolean = false;
  public isTHAADPErrorOccured: boolean = false;
  public futurePaymentOWCSmsg: string = '';

  setSysDownKeyLogin(value) {
    this.sysDownKeyLogin = value;
  }
  getSysDownKeyLogin() {
    return this.sysDownKeyLogin;
  }

  setSysDownKeyOverview(value) {
    this.sysDownKeyOverview = value;
  }
  getSysDownKeyOverview() {
    return this.sysDownKeyOverview;
  }


  constructor( private messageRibbonService: MessageRibbonService, private router: Router, private storageManagementService: StorageManagementService,
               private httpClient: HttpClient) {
    this.isServiceError.subscribe(val => {
      if (val && !this.isClaimServiceHasError) {
        this.messageRibbonService.addMessage(AppLabelConstants.WARNING_SM,
          ErrorConstants.SOMETHING_WENT_ON_OUR_END_MSG_OPTION_5_NUMBER, null, AppConstants.FALSE);
      }
    })
  }
  isServiceResponseHasError(response): boolean {
    if (response &&
      (response['DataPower Service Name'] ||
        response['DataPower Error Code'] ||
        response['Error Message'])) {
      return true;
    } else if (response && !response.errorMessage
      && !response.errorCode
      && (!response.errorList || response.errorList.length === 0)) {
      return false ;
    } else {
      return true ;
    }
  }
  /*
  this new function is to show a new message if one of the claims systems fails and the standard message if they all fail.
   */

  getClaimsError(response): boolean {
    this.currUser = this.storageManagementService.getAppData(SessionConstants.USER_DATA);
    this.defaultDownMessage = this.storageManagementService.getSessionData(SessionConstants.OWCS_ERR_MSG_CONTENT)?.OWCS_ERR_PG_002_Label?.value;
    this.newDownMessage = this.storageManagementService.getSessionData(SessionConstants.OWCS_ERR_MSG_CONTENT)?.OWCS_ERR_PG_001_Body?.value;
    if (response &&
      (response['DataPower Service Name'] ||
        response['DataPower Error Code'] ||
        response['Error Message'])) {
      this.isClaimServiceHasError = true;
      return true;
    }
    const currInsuredId = this.currUser && this.currUser.insuredId && this.currUser.insuredId.length > 0;
    const currPersonId = this.currUser && this.currUser.personIds && (this.currUser.personIds.length !== 0 && this.currUser.personIds[0].length > 0);

    this.isClaimsServiceCalled = this.storageManagementService.getSessionData(SessionConstants.IS_CLAIM_SERVICE_CALLED) ? this.storageManagementService.getSessionData(SessionConstants.IS_CLAIM_SERVICE_CALLED) : false;

    if (response && response.errorList && response.errorList[0].errorCode) {

      switch (response.errorList[0].errorCode) {
        case `50001`:
          if (currInsuredId && !currPersonId && response && response.errorList) {
            if (!this.isClaimsServiceCalled) {
              this.messageRibbonService.addMessage(AppLabelConstants.WARNING_SM,
                this.defaultDownMessage, null, AppConstants.FALSE);
              this.storageManagementService.setSessionData(SessionConstants.IS_CLAIM_SERVICE_CALLED, true);
            }
            this.isClaimServiceHasError = true;
            return false;
          }
          if (currInsuredId && currPersonId && response && response.errorList) {
            if (!this.isClaimsServiceCalled) {
              this.messageRibbonService.addMessage(AppLabelConstants.WARNING_SM,
                this.newDownMessage, null, AppConstants.FALSE);
              this.storageManagementService.setSessionData(SessionConstants.IS_CLAIM_SERVICE_CALLED, true);
            }
            this.isClaimServiceHasError = true;
            return false;
          }
          break;
        case `50003`:
          if (currPersonId && !currInsuredId && response && response.errorList) {
            if (!this.isClaimsServiceCalled) {
              this.messageRibbonService.addMessage(AppLabelConstants.WARNING_SM,
                this.defaultDownMessage, null, AppConstants.FALSE);
              this.storageManagementService.setSessionData(SessionConstants.IS_CLAIM_SERVICE_CALLED, true);
            }
            this.isClaimServiceHasError = true;
            return false;
          }
          if (currPersonId && currInsuredId && response && response.errorList) {
            if (!this.isClaimsServiceCalled) {
              this.messageRibbonService.addMessage(AppLabelConstants.WARNING_SM,
                this.newDownMessage, null, AppConstants.FALSE);
              this.storageManagementService.setSessionData(SessionConstants.IS_CLAIM_SERVICE_CALLED, true);
            }
            this.isClaimServiceHasError = true;
            return false;
          }
          break;
        case `50002`:
          if ((response && response.errorList) && ((currInsuredId && currPersonId) || (!currInsuredId && currPersonId) || (currInsuredId && !currPersonId))) {
            if (!this.isClaimsServiceCalled) {
              this.messageRibbonService.addMessage(AppLabelConstants.WARNING_SM,
                this.defaultDownMessage, null, AppConstants.FALSE);
              this.storageManagementService.setSessionData(SessionConstants.IS_CLAIM_SERVICE_CALLED, true);
            }
            this.isClaimServiceHasError = true;
            return false;
          }
          break;
      }
      this.storageManagementService.setSessionData(SessionConstants.IS_CLAIM_SERVICE_CALLED, true);
      return false;
    }
  }

  isAetnaContactServiceResponseHasError(response): boolean {
    this.defaultDownMessage = this.storageManagementService.getSessionData(SessionConstants.OWCS_ERR_MSG_CONTENT).OWCS_ERR_PG_002_Label.value;
    this.newDownMessage = this.storageManagementService.getSessionData(SessionConstants.OWCS_ERR_MSG_CONTENT).OWCS_ERR_PG_001_Body.value;
    this.isContactServiceCalled = this.storageManagementService.getSessionData(SessionConstants.IS_CONTACT_SERVICE_CALLED) ? this.storageManagementService.getSessionData(SessionConstants.IS_CONTACT_SERVICE_CALLED) : false;

    if (response &&
      (response['DataPower Service Name'] ||
        response['DataPower Error Code'] ||
        response['Error Message'])) {
      return true;
    } else if (response && !response.errorMessage
      && !response.errorCode
      && (!response.errorList || response.errorList.length === 0)) {
      return false ;
    } else {
      if (!this.isContactServiceCalled) {
        this.messageRibbonService.addMessage(AppLabelConstants.WARNING_SM,
          this.newDownMessage, null, AppConstants.FALSE);
        this.storageManagementService.setSessionData(SessionConstants.IS_CLAIM_SERVICE_CALLED, true);
      }
      return true ;
    }
  }

  /*Error handler will be used for explicitly get operation where we have to display only one message banner
   * on the single page even if multiple get opertaion fail isServiceError flag used to identify that and reset this
   * flag when user navigate to another page in the app.component.ts*/
  handleError(error, enabled = false) {
    /*
      As per the 6/5/2018 business requirements update: Warning message needs to be shown only for Sites, Authorize,
      Cases and get user calls. Only these calls have enabled passed as true as of 6/5/2018 implementation.
    */

    if ( enabled ) {
      if (error instanceof HttpErrorResponse ||
        (error && (error['DataPower Service Name'] || error['DataPower Error Code'] || error['Error Message']))) {
        if (!this.isServiceError.getValue()) {
          if (error.error['Error Message'] !== 'Unexpected response from Authentication Server') {
            this.isServiceError.next(true);
            this.failedTransactionCtr.next(this.failedTransactionCtr.getValue() + 1);
          } else {
            TokenUtility.removeAllMyBenefitsTokens();
            this.router.navigate(['/']);
          }
        }
      }
      //return throwError(error);
      return this.globalErrorHandler(error);
    } else {
      return this.globalErrorHandler(error);
    }
  }


  /*function to handle 500 error for contact us */

  handleErrorThaa(error, enabled = false) {
    this.defaultDownMessage = this.storageManagementService.getSessionData(SessionConstants.OWCS_ERR_MSG_CONTENT).OWCS_ERR_PG_002_Label.value;
    this.newDownMessage = this.storageManagementService.getSessionData(SessionConstants.OWCS_ERR_MSG_CONTENT).OWCS_ERR_PG_001_Body.value;
    //   /*
    //     As per the 6/5/2018 business requirements update: Warning message needs to be shown only for Sites, Authorize,
    //     Cases and get user calls. Only these calls have enabled passed as true as of 6/5/2018 implementation.
    //   */

    this.isTHAADPErrorOccured = this.storageManagementService.getSessionData(SessionConstants.IS_THAA_DP_ERROR_OCCURED) ? this.storageManagementService.getSessionData(SessionConstants.IS_THAA_DP_ERROR_OCCURED) : false;

    if ( enabled ) {
      if (error instanceof HttpErrorResponse ||
        (error &&
          (error['DataPower Service Name'] ||
            error['DataPower Error Code'] ||
            error['Error Message']))) {
        if (!this.isServiceError.getValue()) {
          this.isServiceError.next(true);
          this.failedTransactionCtr.next(this.failedTransactionCtr.getValue() + 1);
        }
      }
      return this.globalErrorHandler(error);
    } else {
      if (!this.isTHAADPErrorOccured) {
        this.messageRibbonService.addMessage(AppLabelConstants.WARNING_SM,
          this.newDownMessage, null, AppConstants.FALSE);
        this.storageManagementService.setSessionData(SessionConstants.IS_THAA_DP_ERROR_OCCURED, true);
      }
    }
  }

  /*Error Handler will be used  explicitly for handling
   * Data Power timeout error in while submitting Leave Request
   * */

  handleTimeOutErrorForLeaveRequest() {
      this.storageManagementService.setSessionData(SessionConstants.INTERMITTENT_IN_PROGRESS, []);
      this.router.navigate([AppLabelConstants.URL_LEAVE_DETAILS_TIME_OFF_ERROR], {queryParams: { isDPTimeoutError: true }});
  }

  /*Error Handler will be used  explicitly for Write operation
   * where we have to display banner for 2 failure and on third failure will redirect user
   * to error page, failedTransactionCtr flag used for this reason and reset failedTransactionCtr to 0 when navigate to
   * another page in the app.component.ts
   * */
  handleErrorForSubmit(error) {
    this.failedTransactionCtr.next(this.failedTransactionCtr.getValue() + 1);
    if (this.failedTransactionCtr.getValue() === 1 || this.failedTransactionCtr.getValue() === 2 ) {
      this.messageRibbonService.addMessage(AppLabelConstants.WARNING_SM, ErrorConstants.SOMETHING_WENT_ON_OUR_END_MSG_OPTION_5_NUMBER, null, AppConstants.TRUE);
    } else if (this.failedTransactionCtr.getValue() > 2  && this.router.url === AppLabelConstants.URL_LEAVE_DETAILS_REQUST_REVIEW) {  // There is a separate leave error page
      this.router.navigate([AppLabelConstants.URL_LEAVE_DETAILS_TIME_OFF_ERROR], {queryParams: { isDPTimeoutError: false }});
    } else if (this.failedTransactionCtr.getValue() > 2 ) {
      this.router.navigate([AppLabelConstants.URL_UPDATE_ERROR]);
    }
    return this.globalErrorHandler(error);
  }

  handleThaaErrorForSubmit(error) {
  this.messageRibbonService.addMessage(AppLabelConstants.ERROR_SM, ErrorConstants.SOMETHING_WENT_THAA_DANGER_MSG, ErrorConstants.SOMETHING_WENT_THAA_DANGER_MSG_HEADER, AppConstants.TRUE);
  return this.globalErrorHandler(error);
  }

  handleDownloadFormErrorForSubmit(error) {
    this.messageRibbonService.addMessage(AppLabelConstants.ERROR_SM, ErrorConstants.DOWNLOAD_FORM_ERR_MESSAGE, AppLabelConstants.ERROR_LG, AppConstants.TRUE);
    return this.globalErrorHandler(error);

  }
  handleEcoSignErrorForSubmit(error) {
    this.messageRibbonService.addMessage(AppLabelConstants.ERROR_SM, ErrorConstants.ECOSIGN_FORM_ERR_MESSAGE, AppLabelConstants.ERROR_LG, AppConstants.TRUE);
    return this.globalErrorHandler(error);

  }
  getRequestExtensionError() {
    this.messageRibbonService.addMessage(AppLabelConstants.ERROR_SM,
      ErrorConstants.IT_LOOKS_LIKE_U_ALREADY_REQUESTED_AN_EXTENSION_FOR_THIS_DT_MSG, AppLabelConstants.ERROR_SM, AppConstants.TRUE);
  }
  /* This handler will be used do common error logic for handleError/handleErrorForTransaction */
  globalErrorHandler(error) {
    return observableThrowError(error);
  }

  handleThaaErrorForAPIFailure(error) {
    this.futurePaymentOWCSmsg = this.storageManagementService.getSessionData(SessionConstants.OWCS_ERR_MSG_CONTENTFP).FTEP_03_Body.value;
    this.messageRibbonService.addMessage(AppLabelConstants.ERROR_SM, this.futurePaymentOWCSmsg, ErrorConstants.SOMETHING_WENT_THAA_DANGER_MSG_HEADER, AppConstants.FALSE);
    return this.globalErrorHandler(error);
  }

  //System Downtime service
  getSysDownTime(authenticated: boolean, pageName): Observable<any> {
    const request: string = JSON.stringify({
      pagename: pageName,
      pageId: this.getPageId(AppConstants.SYS_MESSAGE_DOWN_PAGE_NAME)
    });
    const headers = new HttpHeaders().set(AppLabelConstants.CONTENT_TYPE_KEY, AppLabelConstants.APPLICATION_KEY);
    return this.httpClient.post(authenticated ?
      env.gbContent.getSystemDownTime : env.gbContent.getSystemDownTime_unauth, request, {headers});
  }

  getPageId(pageName: string): any {
    if (this.storageManagementService.getSessionData(SessionConstants.SITE_MAP_DATA)) {
      const siteMapData = this.storageManagementService.getSessionData(SessionConstants.SITE_MAP_DATA);
      if (siteMapData && siteMapData.data && siteMapData.data.sitemap && siteMapData.data.sitemap.length > 0) {
        let siteMap = siteMapData.data.sitemap;
        siteMap = siteMap.filter(data => data.pagename === pageName);
        return ( siteMap[0] ) ? siteMap[0].id : {};
      }
    }
  }
}
