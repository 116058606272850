import {Injectable, NgZone} from '@angular/core';
import {Router} from '@angular/router';
import {StorageManagementService} from './storage-management.service';
import {AuthStateService} from './auth-state.service';
import {SessionConstants} from '../constants/session.constants';

@Injectable()
export class SessionManagementService {

  private CHECK_INTERVAL = 60000;

  private MINUTES_UNTIL_LOGOUT = 30;
  private MINUTES_UNTIL_WARN = 25;

  private TIMEOUT_KEY = 'lastAction';

  private MINUTES_UNTIL_DATA_FETCH = 5;
  private DATA_FETCH_KEY = 'lastDataFetch';

  private  WARN_FLAG = true;

  get lastAction() {
    return parseInt( this.storageManagementService.retrieveSessionObject(this.TIMEOUT_KEY), 10 );
  }

  set lastAction( value ) {
    this.storageManagementService.setSessionObjectNoJSON(this.TIMEOUT_KEY, value);
  }

  get isLoggedIn() {
    return this.storageManagementService.retrieveSessionObject('loginState');
  }

  set lastDataFetch( value ) {
    this.storageManagementService.setSessionObjectNoJSON(this.DATA_FETCH_KEY, value);
  }

  get lastDataFetch() {
    return parseInt( this.storageManagementService.retrieveSessionObject(this.DATA_FETCH_KEY), 10 );
  }

  set claimsFlag(value) {
    this.storageManagementService.setAppData(SessionConstants.RELOADCLAIMS , value);
  }

  get claimsFlag() {
    return this.storageManagementService.getAppData(SessionConstants.RELOADCLAIMS);
  }

  constructor(
    private authState: AuthStateService,
    private storageManagementService: StorageManagementService,
    private ngZone: NgZone,
    private router: Router
  ) {
    this.lastAction = Date.now();
    this.initListener();
    this.initInterval();
  }

  /*
    Called in home.component on every claims data fetch
   */
  initDataFetch() {
    this.lastDataFetch = Date.now();
  }

  initListener() {
    this.ngZone.runOutsideAngular(() => {
      document.body.addEventListener('click', () => this.reset());
    })
  }

  reset() {
    this.lastAction = Date.now();
  }

  initInterval() {
    this.ngZone.runOutsideAngular(() => {
      setInterval(() => {
        this.checkTimeWarn();
         this.checkTimeOut();
        this.checkUserDataUpdate();
      }, this.CHECK_INTERVAL);
    });
  }
  checkTimeWarn() {
    const now = Date.now();
    const timeLeft = this.lastAction + this.MINUTES_UNTIL_WARN * 60 * 1000;
    const difference = timeLeft - now;
    if ( difference < 0 && this.isLoggedIn ) {
      this.ngZone.run( () => {
        if (this.WARN_FLAG) {
          this.authState.warnout();
          this.WARN_FLAG = false;
        }

      });
    } else {
      this.WARN_FLAG = true;
    }
  }

  checkTimeOut() {
    const now = Date.now();
    const timeLeft = this.lastAction + this.MINUTES_UNTIL_LOGOUT * 60 * 1000;
    const difference = timeLeft - now;

    if ( difference < 0 && this.isLoggedIn ) {
      this.ngZone.run( () => {
        this.authState.logout(true);
        //this.router.navigate(['/login']);
        //this.storageManagementService.setSessionObjectNoJSON('autoLogOut', true);
      });
    }
  }

  checkUserDataUpdate() {
    const now = Date.now();
    const timeLeft = this.lastDataFetch + this.MINUTES_UNTIL_DATA_FETCH * 60 * 1000;
    const difference = timeLeft - now;

    if ( difference < 0 && this.lastDataFetch && !this.claimsFlag) {
      this.claimsFlag = true;
    }
  }
}
