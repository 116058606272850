import {EnvironmentConfig} from '../env.config'
export class GbBase {
  public baseEnvConfig = new EnvironmentConfig().baseEnvConfig;

  constructor(private clientId: Map<string, string>, private baseUrl: string, private envName: string) {}

  getClient(id) {
    return this.clientId.get(id);
  }

  getClientId(id) {
    let targetEnv: string;
    if (this.envName.toLowerCase() in this.baseEnvConfig) {
      if (this.baseEnvConfig[this.envName.toLowerCase()]['mbTargetEnv'] === '') {
        targetEnv = '';
      } else {
        targetEnv = '&target_env=' + this.baseEnvConfig[this.envName.toLowerCase()]['mbTargetEnv'];
      }
    } else {
      targetEnv = this.baseEnvConfig.prod.mbTargetEnv;
    }

    return 'client_id=' + this.getClient(id) + targetEnv;
  }

  getThaaClientId(id) {
    let thaaTargetEnv: string;
    if (this.envName in this.baseEnvConfig) {
      if (this.baseEnvConfig[this.envName.toLowerCase()]['thaaTargetEnv'] === '') {
        thaaTargetEnv = '';
      } else {
        thaaTargetEnv = '&target_env=' + this.baseEnvConfig[this.envName.toLowerCase()]['thaaTargetEnv'];
      }
    } else {
      thaaTargetEnv = this.baseEnvConfig.prod.thaaTargetEnv;
    }

    return 'client_id=' + this.getClient(id) + thaaTargetEnv;
  }
  getBaseUrl() {
    return this.baseUrl;
  }

  getEnvName() {
    return this.envName;
  }
}
