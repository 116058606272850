import {AfterViewInit, Component, OnInit, ViewChild, TemplateRef, OnDestroy} from '@angular/core';
import {Router} from '@angular/router';
import {ModalService} from '../../../shared/services/modal.service';
import {AbstractControl, FormBuilder, FormControl, FormGroup} from '@angular/forms';
import {AetnaRequestExtensionService} from '../../../shared/services/aetna-request-extension.service';
import {PersonService} from '../../../shared/services/people.service';
import {ContentService} from '../../../shared/services/content.service';
import {CustomEventService} from '../../../shared/services/tealium/custom-event.service';
import {ErrorMessagingService} from '../../../shared/services/error-messaging.service';
import {StorageManagementService} from '../../../shared/services/storage-management.service';
import {LeaveDynatraceAction} from '../../../shared/utils/leaveDynatraceAction';
import {SecondaryDateDropdownComponent} from '../../common/molecules/secondary-date-dropdown/secondary-date-dropdown.component';
import {ResponseErrorHandlerService} from '../../../shared/services/response-error-handler.service';
import {AppLabelConstants} from '../../../shared/constants/appLabel.constants';
import {SessionConstants} from '../../../shared/constants/session.constants';
import {AppConstants} from '../../../shared/constants/app.constants';
import {ErrorConstants} from '../../../shared/constants/error.constants';
import {IMyCalendarViewChanged, IMyDateModel, IMyDpOptions} from 'mydatepicker';
import {DatePipe} from '@angular/common';
import {MessageRibbonService} from '../../../shared/services/message-ribbon.service';
import {ConfirmSurgeryConstants} from '../../confirm-surgery/confirmSurgeryThaa/confirmSurgery.constants';
import {AppLeaveIntakeConstants} from '../../leave-claim-intake-flow/leave-intake.constants';
import {AppLeaveConstants} from '../../leave-details-flow/leave-details.constants';
import {TimeOffConstants} from '../../leave-details-flow/leave-request-time-off/time-off.constants';
import {env} from '../../../../environments/environment-loader';

function getNameValidator(c: AbstractControl): {[key: string]: boolean} {

  const namePattern = /^[ A-Za-z0-9_@$*?{%[},'\]|!./#\\&-]*$/;

  if (c.value && c.dirty && !c.value.match(namePattern)) {
    return {NameValidator: true};
  }
  return null;
}

function faxNumberValidator(c: AbstractControl): { [key: string]: boolean } {

  if (c.value && c.dirty && c.value.length > 8 && allDigitsSame(c.value.replace(/\D+/g, ''))) {
    return {validateConsecutiveDigitsFax: true};
  }
  if (c.dirty && c.value.length > 8 && c.value.includes('(123) 456-789')) {
    //console.log("Error in seq");
    return {validateSequentialDigitsFax: true};
  }

  // if (!c.value || c.value.length === 0) {
  //   return {'RequiredFax': true};
  // }
  if (c.value && c.dirty && c.value.indexOf('_') > -1) {
    return {validateFaxMinLength: true};
  }
  return null;
}

function phoneNumberValidator(c: AbstractControl): { [key: string]: boolean } {

  if (c.value && c.dirty && c.value.length > 8 && allDigitsSame(c.value.replace(/\D+/g, ''))) {
    return {validateConsecutiveDigits: true};
  }
  if (c.dirty && c.value.length > 8 && c.value.includes('(123) 456-789')) {
    //console.log("Error in seq");
    return {validateSequentialDigits: true};
  }

  // if (!c.value || c.value.length === 0) {
  //   return {'RequiredDocNumber': true};
  // }
  if (c.value && c.dirty && c.value.indexOf('_') > -1) {
    return {validatePhoneMinLength: true};
  }
  return null;
}

function allDigitsSame(value) {
  const firstChar = value.charAt(0);
  let digitsSame = true;
  for (let i = 1; i < value.length; i++) {
    if (firstChar !== value[i]) {
      digitsSame = false;
      break;
    }
  }
  return digitsSame;
}


@Component({
  selector: 'app-request-extension-thaa',
  templateUrl: 'request-extension-thaa.component.html',
  styleUrls: ['request-extension-thaa.component.scss']
})
export class RequestExtensionThaaComponent extends LeaveDynatraceAction implements OnInit, AfterViewInit, OnDestroy {
  public doctorDetails: any;
  public appConstant = AppConstants;
  public welcomeMessage = AppLabelConstants.REQUEST_AN_EXT;
  public extensionRequestFormThaa: FormGroup;
  public error = false;
   errormsg = false;
  public timeFrame;
   requestExtensionDateValue;
  public selectMonth;
  public exactdate;
  public estimate;
  public estimateDate;
  lengthExtension = [];
  year = [];
  day = [];
   weekDayMonth;
  public updatedEstimatedDate = false;
   extendThroughSubmitVal = true;
  public isApprovedGreater = true;
   hideNextShowSubmitBtn = true;
   claimEventID;
  public approveThroughDate: string;
   pageId: string;
   owcsContent;
  public successMsg: string;
  public para1: string;
  public currentApprovedDate: string
  public exact_Radial: string;
  public message_1: string;
  public estimateRadial: string;
  public dateLabel: string;
  public lengthLabel: string;
  public extensionThruLabel: string;
  public extensionReasonLabel: string;
  public lastVisitLabel: string;
  public nextVisitLabel: string;
  public rtwLabel: string;
  public checkboxLabel: string;
  public docMsg: string;
  public docName: string;
  public docTel: string;
  public docFax: string;
  public questionList = [];
  public disableSpinner: string = 'false';
  public disableButton: boolean = false;
   formData;
   incrementValue = 0;
   handleErrorLable = false;
  @ViewChild(SecondaryDateDropdownComponent, { static: false }) DateLabelValue: SecondaryDateDropdownComponent;
   readDayValue = true;
  public date: string;
  public nextButton = true;
  public appLabelConstants = AppLabelConstants;
  public errorConstants = ErrorConstants;
  public lastVisitDate;
  public isExtensionValueEmpty: boolean = false;
  public messageAdded: boolean = false;
  public isTwoDigit: boolean = false;
  public checkBoxValueLV: boolean = false;
  public isLVDateEmpty: boolean = true;
  public isLVDateGreater: boolean = true;
  public nextVisitDate;
  public checkBoxValueNV: boolean = false;
  public isNVDateEmpty: boolean = true;
  public isNVDateGreater: boolean = true;
  public showExtendError: boolean = false;
  public showExtendErrorGreater: boolean = false;
  public rtwDate;
  public isRTWDateEmpty = true;
  boolean = true;
  public checkBoxValueRTW: boolean = false;
  public isRadioValueEmpty: boolean = false;
  public isReasonEmpty: boolean = true;
  public isExactDate: boolean = false;
  public isExtendDateEmpty: boolean = true;
  public isDocNameEmpty: boolean = true;
  public isDocTelEmpty: boolean = true;
  public isDocFaxEmpty: boolean = true;
  public extendDate: string;
  public isEstimateDate: boolean = false;
   displayError: number;
  public radioValueVar;
  public showDiv = 1;
  public owscContent;
  public currentDate;
  public currentClaim;
  public maxLength = 400;
  public charLeft = this.maxLength;
  public reasonInput: any;
  public otherReasonQue: string;
  public appConst = {...TimeOffConstants, ...AppLeaveConstants};
  public lateReportingErr = AppLeaveIntakeConstants.LATE_REPORTING_ERR;
  public showNewReasonQue: boolean = false;
  public datePickerOptions: IMyDpOptions = {
    dateFormat: 'mm/dd/yyyy',
    selectorHeight: 'auto',
    dayLabels: {su: 'Su', mo: 'Mo', tu: 'Tu', we: 'We', th: 'Th', fr: 'Fr', sa: 'Sa'},
    showTodayBtn: false,
    firstDayOfWeek: 'su',
    monthSelector: false,
    yearSelector: false,
    minYear: 1970,
    showClearDateBtn: false,
    editableDateField: false,
    openSelectorOnInputClick: true,
    sunHighlight: false,
    markCurrentDay: false,
    height: '42px'
  };
   months = {
    1: 'January',
    2: 'February',
    3: 'March',
    4: 'April',
    5: 'May',
    6: 'June',
    7: 'July',
    8: 'August',
    9: 'September',
    10: 'October',
    11: 'November',
    12: 'December'
  }

  @ViewChild('dontKnwFDA', { static: false }) dontKnwFDA;
  @ViewChild('dontKnwNV', { static: false }) dontKnwNV;
  @ViewChild('dontKnwLV', { static: false }) dontKnwLV;
  @ViewChild('dontKnwRTW', { static: false }) dontKnwRTW;
  public previousUrl;
  public btnEventId: string = '';
  public replaceDiv = /(<[^>]+>|<[^>]>|<\/[^>]>|\?)/g;
  public rtwExtension: boolean = false;
  public isPFLExtension: boolean = false;
  public invalidFields = [];
  public isRTWDateValid: boolean = true;
  disableDatePickerExtend: boolean = false;

  constructor(private router: Router,
              private _formBuilder: FormBuilder,
              private modalService: ModalService,
              private requestExtensionService: AetnaRequestExtensionService,
              private personService: PersonService,
              private _responseErrorHandlerService: ResponseErrorHandlerService,
              private contentService: ContentService,
              private messageRibbonService: MessageRibbonService,
              private customEventService: CustomEventService,
              private errorList: ErrorMessagingService,
              private datepipe: DatePipe,
              private storageManagementService: StorageManagementService, ) {
    super(storageManagementService);
  }


  ngOnInit() {
    sessionStorage.removeItem(SessionConstants.GET_FIRST_VAL);
    const claimEventId = JSON.parse(sessionStorage.getItem(SessionConstants.CLAIM_EVENT_ID));
    if (claimEventId) {
      this.claimEventID = claimEventId.claimEventId;
      this.questionList.push('question_1', 'last_visit', 'next_visit', 'rtw_FC');
    }

    if (this.storageManagementService.getSessionData(SessionConstants.CUR_CLAIM)) {
      this.currentClaim = this.storageManagementService.getSessionData(SessionConstants.CUR_CLAIM);
      if (this.currentClaim.coverageCategory && (this.currentClaim.coverageCategory.toLowerCase() === this.appConstant.PFL.toLowerCase())) {
        this.isPFLExtension = true;
      }
    }

    this.reMoveSessionObject();
    this.lengthExtension = [AppConstants.WEEKS, AppConstants.DAYS];

    /*envname check will get applied in order to hide rtw button for claims in prod*/
    // if (env.envName.toLowerCase() !== AppConstants.PROD_ENV_NAME) {
    //   this.showNewReasonQue = true;
    // }
    //OWCS Integration
    this.fetchOWCSContent();
    this.setextensionRequestFormThaa();
    this.approveThroughDate = JSON.parse(sessionStorage.getItem(SessionConstants.AUTHORIZED_END_DATE)).authorizedEndDate;
    this.currentDate = this.datepipe.transform(new Date(), 'MM/dd/yyyy');

    if (this.storageManagementService.getSessionData(SessionConstants.CLAIM_RTW_EXTENSION_DATE)) {
      setTimeout(() => {
        this.rtwExtensionFlow()
      }, 1000);
    }
    this.previousUrl = this.storageManagementService.getSessionData(SessionConstants.PREVIOUS_URL);
  }

  rtwExtensionFlow() {
    this.rtwExtension = true;
    this.onRadioClick({isTrusted: true}, this.appLabelConstants.EXACT, 'rtwExtensionFlow');
    this.customEventService.trackFormButton(this.btnEventId);
    if (this.showDiv === 1) {
      if ( this.isExactDate) {
        this.btnEventId = this.owcsContent.data.THAA_RAE_08_Body.value.replace(this.replaceDiv, '');
      }
    }
    if (this.displayError !== 2) {
      this.displayError = 1;
    }
    this.showDiv++;
    this.setExtensionDateFromRTW();
    this.btnNextClicked();
  }

  setExtensionDateFromRTW() {
    if (this.storageManagementService.getSessionData(SessionConstants.CLAIM_RTW_EXTENSION_DATE)) {
      const rtwExtensionDateSelected = this.storageManagementService.getSessionData(SessionConstants.CLAIM_RTW_EXTENSION_DATE);
      let rtwExtensionDateMinusOne = new Date(rtwExtensionDateSelected.formatted);
      rtwExtensionDateMinusOne = new Date(rtwExtensionDateMinusOne.setDate(rtwExtensionDateMinusOne.getDate() - 1));
      const rtwExtDateMinusOne = {
        date: {
          year: rtwExtensionDateMinusOne.getFullYear(),
          month: rtwExtensionDateMinusOne.getMonth() + 1,
          day: rtwExtensionDateMinusOne.getDate()
        },
        jsdate: rtwExtensionDateMinusOne.toISOString(),
        formatted: this.datepipe.transform(rtwExtensionDateMinusOne, 'MM/dd/yyyy'),
        epoc: rtwExtensionDateMinusOne.getTime()
      };
      this.extensionRequestFormThaa.controls['datePickerExtend'].setValue(rtwExtDateMinusOne);
      this.CheckDateSelectedExtend(rtwExtDateMinusOne);
      this.extensionRequestFormThaa.controls['datePickerRTW'].setValue(rtwExtensionDateSelected);
      this.rtwDate = rtwExtensionDateSelected.formatted;
      this.disableDatePickerExtend = true;
    }
  }

  ngAfterViewInit() {
    // super.ngAfterViewInit();
    this.modifyHeaderNavigation();
    const styleElem = document.head.appendChild(document.createElement('style'));
    styleElem.innerHTML = 'span.mydpicon.icon-mydpcalendar::before {font-family: inherit!important;}';
  }

  modifyHeaderNavigation() {
    const monthSelect = <HTMLElement> document.getElementsByClassName('headerlabelbtn')[0];
    const yearSelect = <HTMLElement> document.getElementsByClassName('headerlabelbtn')[1];
    if (monthSelect && yearSelect) {
      monthSelect.innerHTML = monthSelect.innerHTML + ' ' + yearSelect.innerHTML;
    }
  }



  tagErrors(elementId, labelName, errMsg) {
    if (errMsg) {
      if (Object.keys(errMsg) && this.errorList.myErrors['' + Object.keys(errMsg)]) {
        const errorMessage = this.errorList.myErrors['' + Object.keys(errMsg)];

        if (errorMessage === this.errorList.myErrors.required) {
          this.customEventService.formFieldValidationErrors(['' + elementId], ['' + labelName + errorMessage]);
        } else {
          this.customEventService.formFieldValidationErrors(['' + elementId], ['' + errorMessage]);

        }
      } else {
        this.customEventService.formFieldValidationErrors(['' + elementId], ['' + errMsg]);
      }
    }
  }


  setextensionRequestFormThaa() {
    this.extensionRequestFormThaa = new FormGroup({
      radioValue: new FormControl(),
      days: new FormControl(),
      weeks: new FormControl(),
      docForm: new FormControl('', [getNameValidator]),
      tForm: new FormControl('', [phoneNumberValidator]),
      fForm: new FormControl('', [faxNumberValidator]),
      otherReason: new FormControl(),
      datePickerExtend: new FormControl(),
      datePickerLastVisit: new FormControl(),
      datePickerNextVisit: new FormControl(),
      datePickerRTW: new FormControl(),
      reasonRadio: new FormControl(),
    });
  }

  onRadioClick(event, radioText, rtwExtensionFlow?) {
    if (event && this.rtwExtension === false) {
      event.preventDefault();
    }
    const claims = this.storageManagementService.getAppData(SessionConstants.USER_CLAIMS);
    this.radioValueVar = sessionStorage.getItem(SessionConstants.RADIO_VALUE);

    if (radioText === this.exact_Radial) {
      this.showDiv = 1;
      this.extensionRequestFormThaa.controls['radioValue'].setValue('exactDate');
      this.extensionRequestFormThaa.value.datePickerExtend = null;
      this.extensionRequestFormThaa.controls['datePickerExtend'].setValue('');
      this.extensionRequestFormThaa.value.days = null;
      this.extensionRequestFormThaa.controls['days'].setValue('');
      this.extendDate = '';
      this.checkBoxValueLV = false;
      this.checkBoxValueRTW = false;
      this.checkBoxValueNV = false;
      this.showExtendError = false;
      this.showExtendErrorGreater = false;
      this.radioValueVar = 'exactDate';
      this.isExactDate = true;
      this.isEstimateDate = false;
      this.isLVDateEmpty = true;
      this.isDocFaxEmpty = true;
      this.isDocNameEmpty = true;
      this.isDocTelEmpty = true;
      this.extensionRequestFormThaa.controls['tForm'].markAsUntouched();
      this.extensionRequestFormThaa.controls['tForm'].setValue('');
      this.extensionRequestFormThaa.value.tForm = null;
      this.extensionRequestFormThaa.controls['fForm'].markAsUntouched();
      this.extensionRequestFormThaa.controls['fForm'].setValue('');
      this.extensionRequestFormThaa.value.fForm = null;
      this.extensionRequestFormThaa.controls['docForm'].markAsUntouched();
      this.extensionRequestFormThaa.controls['docForm'].setValue('');
      this.extensionRequestFormThaa.value.docForm = null;
      this.extensionRequestFormThaa.value.datePickerLastVist = null;
      this.extensionRequestFormThaa.controls['datePickerLastVisit'].setValue('');
      this.extensionRequestFormThaa.value.datePickerNextVisit = null;
      this.extensionRequestFormThaa.controls['datePickerNextVisit'].setValue('');
      this.isNVDateEmpty = true;
      this.extensionRequestFormThaa.value.datePickerRTW = null;
      this.extensionRequestFormThaa.controls['datePickerRTW'].setValue('');
      this.isRTWDateEmpty = true;
      this.extensionRequestFormThaa.controls['otherReason'].setValue('');
      this.extensionRequestFormThaa.controls['reasonRadio'].markAsUntouched();
      this.extensionRequestFormThaa.controls['reasonRadio'].setValue('');
      this.extensionRequestFormThaa.controls['otherReason'].markAsUntouched();
      this.charLeft = this.maxLength;
      this.btnEventId = this.owcsContent.data.THAA_RAE_06_Label.value.replace(this.replaceDiv, '');
      if (this.storageManagementService.getSessionData(SessionConstants.CLAIM_RTW_EXTENSION_DATE)) {
        this.showDiv++;
        this.setExtensionDateFromRTW();
        if (!rtwExtensionFlow) {
          this.btnNextClicked();
        }
      }
    } else if (radioText === this.estimateRadial) {
      this.showDiv = 1;
      this.extendDate = '';
      this.isLVDateEmpty = true;
      this.extensionRequestFormThaa.value.days = null;
      this.extensionRequestFormThaa.controls['days'].setValue('');
      this.extensionRequestFormThaa.value.datePickerExtend = null;
      this.extensionRequestFormThaa.controls['datePickerExtend'].setValue('');
      this.checkBoxValueLV = false;
      this.checkBoxValueRTW = false;
      this.checkBoxValueNV = false;
      this.isDocFaxEmpty = true;
      this.isDocNameEmpty = true;
      this.isDocTelEmpty = true;
      this.showExtendError = false;
      this.showExtendErrorGreater = false;
      this.extensionRequestFormThaa.controls['tForm'].markAsUntouched();
      this.extensionRequestFormThaa.controls['tForm'].setValue('');
      this.extensionRequestFormThaa.value.tForm = null;
      this.extensionRequestFormThaa.controls['fForm'].markAsUntouched();
      this.extensionRequestFormThaa.controls['fForm'].setValue('');
      this.extensionRequestFormThaa.value.fForm = null;
      this.extensionRequestFormThaa.controls['docForm'].markAsUntouched();
      this.extensionRequestFormThaa.controls['docForm'].setValue('');
      this.extensionRequestFormThaa.value.docForm = null;
      this.extensionRequestFormThaa.controls['radioValue'].setValue('estimateDate');
      this.radioValueVar = 'estimateDate';
      this.extensionRequestFormThaa.value.datePickerLastVist = null;
      this.extensionRequestFormThaa.controls['datePickerLastVisit'].setValue('');
      this.extensionRequestFormThaa.value.datePickerNextVisit = null;
      this.extensionRequestFormThaa.controls['datePickerNextVisit'].setValue('');
      this.isNVDateEmpty = true;
      this.extensionRequestFormThaa.value.datePickerRTW = null;
      this.extensionRequestFormThaa.controls['datePickerRTW'].setValue('');
      this.isRTWDateEmpty = true;
      this.isEstimateDate = true;
      this.isExactDate = false;
      this.extensionRequestFormThaa.controls['otherReason'].setValue('');
      this.extensionRequestFormThaa.controls['reasonRadio'].markAsUntouched();
      this.extensionRequestFormThaa.controls['reasonRadio'].setValue('');
      this.extensionRequestFormThaa.controls['otherReason'].markAsUntouched();
      this.charLeft = this.maxLength;
      this.btnEventId = this.owcsContent.data.THAA_RAE_07_Label.value.replace(this.replaceDiv, '');
    }
    this.customEventService.trackRadioButton(this.btnEventId, this.radioValueVar);
    if (!this.radioValueVar) {
      this.isRadioValueEmpty = true;
    } else if (this.radioValueVar === 'exactDate') {
      this.isExactDate = true;
      this.isRadioValueEmpty = false;
    } else if (this.radioValueVar === 'estimateDate') {
      this.isEstimateDate = true;
      this.isRadioValueEmpty = false;
    }
    this.isRTWDateValid = true;
    this.rtwDate = '';
  }

  btnNextClicked() {
    this.customEventService.trackFormButton(this.btnEventId);
    if (this.showDiv === 1) {
      if ( this.isExactDate) {
        this.btnEventId = this.owcsContent.data.THAA_RAE_08_Body.value.replace(this.replaceDiv, '');
      } else {
        this.btnEventId = this.owcsContent.data.THAA_RAE_14_Body.value.replace(this.replaceDiv, '');
      }
    }

    if (this.displayError !== 2) {
      this.displayError = 1;
    }


    if (this.showDiv === 1 && this.showDiv < 1) {
      if (!this.radioValueVar) {
        this.showDiv--;
        this.isRadioValueEmpty = true;
      } else if (this.radioValueVar === 'exactDate') {
        this.isExactDate = true;
        this.isEstimateDate = false;
        this.isRadioValueEmpty = false;
        this.showDiv++;
      } else if (this.radioValueVar === 'estimateDate') {
        this.isEstimateDate = true;
        this.isExactDate = false;
        this.isRadioValueEmpty = false;
        this.showDiv++;
      }
    }


    if (this.showDiv === 2 && this.isEstimateDate) {
      if (!this.extensionRequestFormThaa.value.days) {
        this.isExtensionValueEmpty = true;
        this.showErrorCalendarLabel();
        this.extensionRequestFormThaa.get('days').setValue('');
        this.extensionRequestFormThaa.controls['days'].markAsTouched();
        this.extensionRequestFormThaa.controls['days'].markAsDirty();
        this.tagErrorDays(this.extensionRequestFormThaa.controls['days'].value, this.extensionRequestFormThaa.controls['days'].errors);
        // this.tagErrors(AppLabelConstants.EXTENSION_DATE_TEXT, '', ErrorConstants.ENTER_VALID_DATE);
        this.showDiv--;
      } else if (this.isDigitValid()) {
        this.isExtendDateEmpty = false;
        this.isTwoDigit = true;
        this.showDiv--;
      } else {
        this.isExtensionValueEmpty = false;
        this.isTwoDigit = false;
        this.showDiv++;
      }
    }

    if (this.showDiv === 2 && this.isExactDate) {
      if (new Date(this.extendDate) <= (new Date(this.approveThroughDate))) {
        this.isApprovedGreater = false;
        this.isExtendDateEmpty = true;
        this.showExtendErrorGreater = true;
        this.showExtendError = false;
        this.showErrorCalendarLabel();
        // this.tagErrors(AppLabelConstants.EXTENSION_DATE_TEXT, '', ErrorConstants.ENTER_VALID_DATE);
        this.showDiv--;
      } else if (!this.extensionRequestFormThaa.value.datePickerExtend) {
        this.isExtendDateEmpty = false;
        this.isApprovedGreater = true;
        this.showExtendError = true;
        this.showExtendErrorGreater = false;
        this.showErrorCalendarLabel();
        this.showDiv--;
      } else {
        this.isExtendDateEmpty = true;
        this.isApprovedGreater = true;
        this.showExtendError = false;
        this.showExtendErrorGreater = false;
        this.showDiv++;
      }

    }

    if (this.showDiv === 3) {
      /*Added check for PFL claims only to default reason 'Other' value in radio along with reason text area*/
      if (this.isPFLExtension) {
        this.extensionRequestFormThaa.controls['reasonRadio'].setValue('Other');
        this.isReasonEmpty = true;
      }
      this.btnEventId = this.owcsContent.data.THAA_RAE_22_Body.value.replace(this.replaceDiv, '');
    }

    if (this.showDiv === 4) {
      // if (!this.showNewReasonQue) {
      //   if (this.extensionRequestFormThaa.value.otherReason === null || this.extensionRequestFormThaa.value.otherReason === '') {
      //     this.isReasonEmpty = false;
      //     this.extensionRequestFormThaa.get('otherReason').setValue('');
      //     this.extensionRequestFormThaa.controls['otherReason'].markAsTouched();
      //     this.extensionRequestFormThaa.controls['otherReason'].markAsDirty();
      //     this.tagErrorOther(this.extensionRequestFormThaa.controls['otherReason'].value, this.extensionRequestFormThaa.controls['otherReason'].errors);
      //     this.showDiv--;
      //   } else {
      //     this.isReasonEmpty = true;
      //     this.btnEventId = this.owcsContent.data.THAA_RAE_24_Body.value.replace(this.replaceDiv, '');
      //   }
      // }

      // if (this.showNewReasonQue) {
        if (this.extensionRequestFormThaa.value.reasonRadio === null || this.extensionRequestFormThaa.value.reasonRadio === '' || this.extensionRequestFormThaa.value.reasonRadio === 'notSelected') {
          // this.isReasonEmpty = false;
          this.extensionRequestFormThaa.get('reasonRadio').setValue('');
          this.extensionRequestFormThaa.controls['reasonRadio'].markAsTouched();
          this.extensionRequestFormThaa.controls['reasonRadio'].markAsDirty();
          this.tagErrorDropDown(this.extensionRequestFormThaa.controls['reasonRadio'].value, this.extensionRequestFormThaa.controls['reasonRadio'].errors);
          this.showDiv--;

        } else {
          // this.isReasonEmpty = true;
          this.btnEventId = this.owcsContent.data.THAA_RAE_24_Body.value.replace(this.replaceDiv, '');
        }

        if (this.extensionRequestFormThaa.value.reasonRadio.toLowerCase() === 'other' && (this.extensionRequestFormThaa.value.otherReason === '' || this.extensionRequestFormThaa.value.otherReason === null || !this.extensionRequestFormThaa.value.otherReason.trim())) {
          this.extensionRequestFormThaa.get('otherReason').setValue('');
          this.charLeft = this.maxLength;
          this.extensionRequestFormThaa.controls['otherReason'].markAsTouched();
          this.extensionRequestFormThaa.controls['otherReason'].markAsDirty();
          this.reasonInput = '';
          this.isReasonEmpty = false;
          this.tagErrorOther(this.extensionRequestFormThaa.controls['otherReason'].value, this.extensionRequestFormThaa.controls['otherReason'].errors);
          this.showDiv--;
        } else {
          this.isReasonEmpty = true;
          this.btnEventId = this.owcsContent.data.THAA_RAE_24_Body.value.replace(this.replaceDiv, '');
        }
      // }


    }

    if (this.showDiv === 4 && this.isPFLExtension) {
      this.showDiv = 6;
    }

    if (!this.isPFLExtension) {
      if (this.showDiv === 5) {
        if (!this.extensionRequestFormThaa.value.datePickerLastVisit && !this.checkBoxValueLV) {

          this.isLVDateEmpty = false;
          this.isLVDateGreater = true;
          this.showErrorCalendarLabel();
          this.showDiv--;
          // } else if (this.lastVisitDate > this.currentDate) {
          //   this.isLVDateGreater = false;
          //   this.isLVDateEmpty = true;
          //   this.showErrorCalendarLabel();
          //   this.showDiv--;
        } else {
          this.isLVDateEmpty = true;
          this.isLVDateGreater = true;
          this.btnEventId = this.owcsContent.data.THAA_RAE_27_Body.value.replace(this.replaceDiv, '');
        }
      }

      if (this.showDiv === 6) {
        if ((!this.extensionRequestFormThaa.value.datePickerNextVisit && !this.checkBoxValueNV)) {
          this.isNVDateEmpty = false;
          this.isNVDateGreater = true;
          this.showErrorCalendarLabel();
          this.showDiv--;
          // } else if (this.nextVisitDate < this.currentDate) {
          //   this.isNVDateGreater = false;
          //   this.isNVDateEmpty = true;
          //   this.showErrorCalendarLabel();
          //   this.showDiv--;
        } else {
          this.isNVDateEmpty = true;
          this.isNVDateGreater = true;
          this.btnEventId = this.owcsContent.data.THAA_RAE_30_Body.value.replace(this.replaceDiv, '');
        }
      }
    }

    if (this.showDiv === 7) {
      if (!this.extensionRequestFormThaa.value.datePickerRTW && !this.checkBoxValueRTW) {
        this.isRTWDateEmpty = false;
        this.showDiv--;
        this.showErrorCalendarLabel();
      } else {
        this.isRTWDateEmpty = true;
      }
    }

    this.showDiv++;
  }

  showErrorCalendarLabel() {
    for (let index = 0; index < this.questionList.length; index++) {
      if (document.getElementById(this.questionList[index])) {
        const element = document.getElementById(this.questionList[index]).getElementsByClassName('selectiongroup');
        element[0].classList.remove('incorrectDate');
        if (((this.questionList[index] === 'question_1') && (!this.isExtendDateEmpty || !this.isApprovedGreater)) || ((this.questionList[index] === 'last_visit') && !this.isLVDateEmpty)
          || ((this.questionList[index] === 'last_visit') && !this.isLVDateGreater) || ((this.questionList[index] === 'next_visit') && !this.isNVDateEmpty)
          || ((this.questionList[index] === 'next_visit') && !this.isNVDateGreater) || ((this.questionList[index] === 'rtw_FC') && (!this.isRTWDateEmpty || !this.isRTWDateValid)))  {
          element[0].classList.add('incorrectDate');
        }
      }
    }
  }

  showErrorCalLabelOnBlur(elementId) {
    if (elementId) {
      const element = document.getElementById(elementId).getElementsByClassName('selectiongroup');
      element[0].classList.remove('incorrectDate');
      if ((elementId === 'question_1') && !this.isExtendDateEmpty || (elementId === 'question_1') && !this.isApprovedGreater) {
        element[0].classList.add('incorrectDate');
      }
      if (((elementId === 'last_visit') && !this.isLVDateEmpty) || (elementId === 'last_visit') && !this.isLVDateGreater) {
        element[0].classList.add('incorrectDate');
      }
      if (((elementId === 'next_visit') && !this.isNVDateEmpty) || (elementId === 'next_visit') && !this.isNVDateGreater) {
        element[0].classList.add('incorrectDate');
      }
      if ((elementId === 'rtw_FC') && !this.isRTWDateEmpty) {
        element[0].classList.add('incorrectDate');
      }
    }
  }

  tagErrorOther(formValue, error) {
    if (formValue === '' || !formValue.trim()) {
      error = this.extensionRequestFormThaa.controls['otherReason'].setErrors({RequestExtReasonError: true});
    }
  }

  tagErrorDropDown(formValue, error) {
    if (formValue.toLowerCase() === 'notselected' || formValue === '') {
      error = this.extensionRequestFormThaa.controls['reasonRadio'].setErrors({RequestExtReasonDropDownError: true});
    }
  }
  tagErrorDays(formValue, error) {
    if (formValue === '') {
      error = this.extensionRequestFormThaa.controls['days'].setErrors({days: true});
    }
  }
  tagErrorDoc(formValue, error) {
    if (formValue === '' || !formValue.trim()) {
      error = this.extensionRequestFormThaa.controls['docForm'].setErrors({docFormError: true});
    }
  }
  tagErrorTel(formValue, error) {
    if (formValue === '') {
      error = this.extensionRequestFormThaa.controls['tForm'].setErrors({RequiredDocNumber: true});
    }
  }
  tagErrorFax(formValue, error) {
    if (formValue === '') {
      error = this.extensionRequestFormThaa.controls['fForm'].setErrors({RequiredFax: true});
    }
  }

  setOtherReason(value) {
    const selectedValue = (this.extensionRequestFormThaa.value.otherReason) ? this.extensionRequestFormThaa.value.otherReason : value;
    if (!selectedValue) {
      this.isReasonEmpty = false;
      this.tagErrorOther(this.extensionRequestFormThaa.controls['otherReason']. value, this.extensionRequestFormThaa.controls['otherReason'].errors)
    } else {
      this.isReasonEmpty = true;
    }
  }

  setDocName(value) {
    const selectedValue = (this.extensionRequestFormThaa.value.docForm) ? this.extensionRequestFormThaa.value.docForm.trim() : value;
    if (!selectedValue) {
      this.isDocNameEmpty = false;
      this.extensionRequestFormThaa.get('docForm').setValue('');
      this.extensionRequestFormThaa.controls['docForm'].markAsTouched();
      this.extensionRequestFormThaa.controls['docForm'].markAsDirty();
      this.tagErrorDoc(this.extensionRequestFormThaa.controls['docForm'].value, this.extensionRequestFormThaa.controls['docForm'].errors);
    } else {
      this.isDocNameEmpty = true;
    }
  }

  setDocTel(value) {
    const selectedValue = (this.extensionRequestFormThaa.value.tForm) ? this.extensionRequestFormThaa.value.tForm : value;
    if (!selectedValue) {
      this.isDocTelEmpty = false;
    } else {
      this.isDocTelEmpty = true;
    }
  }

  setDocFax(value) {
    const selectedValue = (this.extensionRequestFormThaa.value.fForm) ? this.extensionRequestFormThaa.value.fForm : value;
    if (!selectedValue) {
      this.isDocFaxEmpty = false;
    } else {
      this.isDocFaxEmpty = true;
    }
  }

  dateSelectedExtend(event: IMyDateModel, questionId) {
    this.CheckDateSelectedExtend(event);
    this.showErrorCalLabelOnBlur(questionId);
    this.customEventService.calendarTracking(this.extendDate, window.location.href);
    if (this.rtwDate && this.currentClaim && this.currentClaim.claimOnlineStatusCode === this.appConstant.COS_CLAIM_APPROVED_CD_04) {
      this.verifyRTWDate(event, this.appConstant.EXTENSION_DATE_CHANGED);
    }
  }

  /**
   * to check if date is below compant acceptance date and yes then check for work schedule
   *
   * @param date event obj
   */
  CheckDateSelectedExtend(event) {
    const dateSelected = event.jsdate;
    const selected = new Date(event.formatted);
    this.extendDate = this.datepipe.transform(selected, 'MM/dd/yyyy');
    if (!this.extendDate) {
      this.isExtendDateEmpty = false;
      this.isApprovedGreater = true;
      this.showExtendError = true;
      this.showExtendErrorGreater = false;
    } else if (new Date(this.extendDate) <= (new Date(this.approveThroughDate))) {
      this.isApprovedGreater = false;
      this.isExtendDateEmpty = true;
      this.showExtendError = false;
      this.showExtendErrorGreater = true;
    } else {
      this.isApprovedGreater = true;
      this.isExtendDateEmpty = true;
      this.showExtendError = false;
      this.showExtendErrorGreater = false;
    }
  }

  dateSelectedLastVisit(event: IMyDateModel, questionId) {
    this.CheckDateSelectedLastVisit(event);
    this.showErrorCalLabelOnBlur(questionId);
    this.customEventService.calendarTracking(this.lastVisitDate, window.location.href);
  }

  /**
   * to check if date is below compant acceptance date and yes then check for work schedule
   *
   * @param date event obj
   */
  CheckDateSelectedLastVisit(event) {
    const dateSelected = event.jsdate;
    const selected = new Date(event.formatted);
    this.lastVisitDate = this.datepipe.transform(selected, 'MM/dd/yyyy');
    this.currentDate = this.datepipe.transform(this.currentDate, 'MM/dd/yyyy')
    if (this.lastVisitDate === null && !this.checkBoxValueLV) {
      this.isLVDateEmpty = false;
      this.isLVDateGreater = true;
      // } else if (this.lastVisitDate > this.currentDate) {
      //   this.isLVDateGreater = false;
      //   this.isLVDateEmpty = true;
    } else {
      this.isLVDateEmpty = true;
      this.isLVDateGreater = true;
    }
    if (this.lastVisitDate) {
      this.dontKnwLV.checkboxState = undefined;
      this.checkBoxValueLV = false;
    }
  }

  checkBoxLV() {
    if (this.dontKnwLV.checkboxState === AppLabelConstants.CHECKED) {
      this.checkBoxValueLV = true;
      this.isLVDateEmpty = true;
      this.isLVDateGreater = true;
      this.showErrorCalendarLabel();
    } else {
      this.checkBoxValueLV = false;
      this.isLVDateEmpty = false;
      this.showErrorCalendarLabel();
    }
    if (this.checkBoxValueLV) {
      this.extensionRequestFormThaa.controls['datePickerLastVisit'].setValue('');
      this.extensionRequestFormThaa.value.datePickerLastVisit = null;
      this.lastVisitDate = null;
    }
  }

  onKeyBoardPressLV() {
    const checkbox = document.getElementById('iDontKnow3');
    function handleCheckboxEvent(e) {
      // e.preventDefault();
      if (e.keyCode === 32) {  // If spacebar fired the event
        this.checked = !this.checked;
      }
    }
    if (checkbox) {
    this.checkBoxLV();
    }
  }

  onKeyBoardPressNV() {
    const checkbox = document.getElementById('iDontKnow4');
    function handleCheckboxEvent(e) {
      // e.preventDefault();
      if (e.keyCode === 32) {  // If spacebar fired the event
        this.checked = !this.checked;
      }
    }
    if (checkbox) {
      this.checkBoxNV();
    }
  }

  onKeyBoardPressRTW() {
    const checkbox = document.getElementById('iDontKnow5');
    function handleCheckboxEvent(e) {
      // e.preventDefault();
      if (e.keyCode === 32) {  // If spacebar fired the event
        this.checked = !this.checked;
      }
    }
    if (checkbox) {
      this.checkBoxRTW();
    }
  }

  dateSelectedNextVisit(event: IMyDateModel, questionId) {
    this.CheckDateSelectedNextVisit(event);
    this.showErrorCalLabelOnBlur(questionId);
    this.customEventService.calendarTracking(this.nextVisitDate, window.location.href);
  }

  /**
   * to check if date is below compant acceptance date and yes then check for work schedule
   *
   * @param date event obj
   */
  CheckDateSelectedNextVisit(event) {
    const dateSelected = event.jsdate;
    const selected = new Date(event.formatted);
    this.nextVisitDate = this.datepipe.transform(selected, 'MM/dd/yyyy');
    this.currentDate = this.datepipe.transform(this.currentDate, 'MM/dd/yyyy')
    if (!this.nextVisitDate && !this.checkBoxValueNV) {
      this.isNVDateEmpty = false;
      this.isNVDateGreater = true;
      // } else if (this.nextVisitDate < this.currentDate) {
      //   this.isNVDateGreater = false;
      //   this.isNVDateEmpty = true;
    } else {
      this.isNVDateEmpty = true;
      this.isNVDateGreater = true;
    }
    if (this.nextVisitDate) {
      this.dontKnwNV.checkboxState = undefined;
      this.checkBoxValueNV = false;
    }
  }

  checkBoxNV() {
    if (this.dontKnwNV.checkboxState === AppLabelConstants.CHECKED) {
      this.checkBoxValueNV = true;
      this.isNVDateEmpty = true;
      this.isNVDateGreater = true;
      this.showErrorCalendarLabel();
    } else {
      this.checkBoxValueNV = false;
      this.isNVDateEmpty = false;
      this.showErrorCalendarLabel();

    }
    if (this.checkBoxValueNV) {
      this.extensionRequestFormThaa.controls['datePickerNextVisit'].setValue('');
      this.nextVisitDate = null;
    }
  }

  dateSelectedRTW(event: IMyDateModel, questionId) {
    this.CheckDateSelectedRTW(event);
    this.showErrorCalLabelOnBlur(questionId)
    this.customEventService.calendarTracking(this.rtwDate, window.location.href);
    if (this.currentClaim && this.currentClaim.claimOnlineStatusCode === this.appConstant.COS_CLAIM_APPROVED_CD_04) {
      this.verifyRTWDate(event,  this.appConstant.RTW_DATE_CHANGED);
    }
  }

  /**
   * to check if date is below compant acceptance date and yes then check for work schedule
   *
   * @param date event obj
   */
  CheckDateSelectedRTW(event) {
    const dateSelected = event.jsdate;
    const selected = new Date(event.formatted);
    this.rtwDate = this.datepipe.transform(selected, 'MM/dd/yyyy');
    if (!this.rtwDate && !this.checkBoxValueRTW) {
      this.isRTWDateEmpty = false;
    } else {
      this.isRTWDateEmpty = true;
      this.nextButton = true;
    }
    if (this.rtwDate) {
      this.dontKnwRTW.checkboxState = undefined;
    }
  }

  /*
  * to check if rtw date entered is valid, if not, prompt user with an error
  * @param date event obj
  * @param rtw or ext (rtw - rtw date change, ext - extension date change)
  * */
  verifyRTWDate(event, raeDate) {
    if (this.isExactDate) {
      this.isRTWDateValid = raeDate.toLowerCase() === this.appConstant.EXTENSION_DATE_CHANGED.toLowerCase() ? event.jsdate < new Date(this.rtwDate) : new Date(event.formatted) > new Date(this.extensionRequestFormThaa.controls['datePickerExtend'].value.formatted);
    } else if (this.isEstimateDate && this.timeFrame) {
      const estimateDateObj = new Date(this.timeFrame);
      this.isRTWDateValid = raeDate.toLowerCase() === this.appConstant.EXTENSION_DATE_CHANGED.toLowerCase() ? event > estimateDateObj : event.jsdate > estimateDateObj;
    }
    this.disableButton = !this.isRTWDateValid;
    this.showErrorCalendarLabel();
  }

  checkBoxRTW() {
    if (this.dontKnwRTW.checkboxState === AppLabelConstants.CHECKED) {
      this.checkBoxValueRTW = true;
      this.isRTWDateEmpty = true;
      this.isRTWDateValid = true;
      this.showErrorCalendarLabel();
    } else {
      this.checkBoxValueRTW = false;
      this.isRTWDateEmpty = false;
      this.showErrorCalendarLabel();
    }
    if (this.checkBoxValueRTW) {
      this.extensionRequestFormThaa.controls['datePickerRTW'].setValue('');
      this.rtwDate = null;
      this.isRTWDateValid = true;
      this.disableButton = false;
    }
    this.customEventService.trackCheckBox(this.rtwLabel.replace(this.appConstant.REGEX_EXP, ''),  AppLeaveIntakeConstants.I_DONT_KNOW_LABEL + ' - (' + this.checkBoxValueRTW + ')');
  }


  /**
   * to get next month view on calendar on arrow clicked
   *
   * @param event
   */
  onCalendarViewChanged(event: IMyCalendarViewChanged) {
    const monthSelect: any = document.getElementsByClassName('headerlabelbtn');
    if (monthSelect && monthSelect.length) {
      for (let i = 0; i <= monthSelect.length; i++) {
        if (monthSelect[i]) {
          monthSelect[i].textContent = `${this.months[event.month]} ${event.year}`;
        }
      }
    }
  }

  onclickCancel() {
    this.previousUrl = this.storageManagementService.getSessionData(SessionConstants.PREVIOUS_URL);
    this.modalService.openModal(AppConstants.CANCEL_MODAL);
  }

  submitForm() {

    if (this.extensionRequestFormThaa.value.reasonRadio.toLowerCase() === 'other' && (this.extensionRequestFormThaa.value.otherReason === '' || this.extensionRequestFormThaa.value.otherReason === null || !this.extensionRequestFormThaa.value.otherReason.trim())) {
      this.extensionRequestFormThaa.get('otherReason').setValue('');
      this.extensionRequestFormThaa.controls['otherReason'].markAsTouched();
      this.extensionRequestFormThaa.controls['otherReason'].markAsDirty();
      this.reasonInput = '';
      this.isReasonEmpty = false;
      this.tagErrorOther(this.extensionRequestFormThaa.controls['otherReason'].value, this.extensionRequestFormThaa.controls['otherReason'].errors);
    }

    if (this.showDiv === 7 && this.isPFLExtension) {
      if (!this.extensionRequestFormThaa.value.datePickerRTW && !this.checkBoxValueRTW) {
        this.isRTWDateEmpty = false;
        this.showErrorCalendarLabel();
        this.disableSpinner = 'true';
        this.disableButton = false;
      } else {
        this.isRTWDateEmpty = true;
        this.showDiv++;
      }
    }

    if (this.showDiv === 8) {
      if (!this.isPFLExtension) {
        if (!this.extensionRequestFormThaa.value.docForm || !this.extensionRequestFormThaa.value.docForm.trim()) {
          this.extensionRequestFormThaa.get('docForm').setValue('');
          this.extensionRequestFormThaa.controls['docForm'].markAsTouched();
          this.extensionRequestFormThaa.controls['docForm'].markAsDirty();
          this.tagErrorDoc(this.extensionRequestFormThaa.controls['docForm'].value, this.extensionRequestFormThaa.controls['docForm'].errors);
          this.isDocNameEmpty = false;
        } else {
          this.isDocNameEmpty = true;
        }
        if (!this.extensionRequestFormThaa.value.tForm) {
          this.isDocTelEmpty = false;
          this.extensionRequestFormThaa.get('tForm').setValue('');
          this.extensionRequestFormThaa.controls['tForm'].markAsTouched();
          this.tagErrorTel(this.extensionRequestFormThaa.controls['tForm'].value, this.extensionRequestFormThaa.controls['tForm'].errors);
        } else {
          this.isDocTelEmpty = true;
        }
        if (!this.extensionRequestFormThaa.value.fForm) {
          this.extensionRequestFormThaa.get('fForm').setValue('');
          this.extensionRequestFormThaa.controls['fForm'].markAsTouched();
          this.tagErrorFax(this.extensionRequestFormThaa.controls['fForm'].value, this.extensionRequestFormThaa.controls['fForm'].errors);
          this.isDocFaxEmpty = false;
        } else {
          this.isDocFaxEmpty = true;
        }
      }

      if ((this.showExtendError || this.showExtendErrorGreater || this.isExtensionValueEmpty || !this.isExtendDateEmpty) || (!this.isReasonEmpty) || (!this.isLVDateEmpty) || (!this.isNVDateEmpty) || (!this.isRTWDateEmpty) || !this.isPFLExtension && ((!this.extensionRequestFormThaa.value.docForm) || (!this.extensionRequestFormThaa.value.tForm || (this.extensionRequestFormThaa.value.tForm && this.extensionRequestFormThaa.value.tForm.replace(/[^0-9]/g, '').length !== 10))
        || ((!this.extensionRequestFormThaa.value.fForm) || (!this.extensionRequestFormThaa.value.fForm || (this.extensionRequestFormThaa.value.tForm && this.extensionRequestFormThaa.value.fForm.replace(/[^0-9]/g, '').length !== 10))))) {
        this.disableSpinner = 'true';
        this.disableButton = false;
        return false;
      } else {
        if (this.extensionRequestFormThaa.value.otherReason) {
          this.extensionRequestFormThaa.value.otherReason = this.extensionRequestFormThaa.value.otherReason.replace(/\\n/g, '');
          this.extensionRequestFormThaa.value.otherReason = this.extensionRequestFormThaa.value.otherReason.replace(/\\r/g, '');
        }
        if (this.extensionRequestFormThaa.value.docForm) {
          this.extensionRequestFormThaa.value.docForm = this.extensionRequestFormThaa.value.docForm.replace(/\\n/g, '');
          this.extensionRequestFormThaa.value.docForm = this.extensionRequestFormThaa.value.docForm.replace(/\\r/g, '');
        }
        this.formData = {
          personId: this.currentClaim.personId,
          claimId: this.currentClaim.claimEventId,
          header: '10023802',
          approvedThroughDt: this.approveThroughDate,
          extensionRequestedThroughDt: (this.radioValueVar === 'exactDate' ? this.extendDate : this.timeFrame),
          additionalComments: []
        };

        this.formData.additionalComments.push('Extension Info' + ' |');
        this.formData.additionalComments.push(this.appLabelConstants.CURRENT_THRU_DATE + ' | ' + this.approveThroughDate);
        this.formData.additionalComments.push(this.appLabelConstants.REQUEST_EXT_THRU + ' | ' + (this.radioValueVar === 'exactDate' ? this.extendDate : this.timeFrame));
        this.formData.additionalComments.push(this.appLabelConstants.REQUEST_REASON + ' | ' + (this.extensionRequestFormThaa.value.reasonRadio ? this.extensionRequestFormThaa.value.reasonRadio : (this.extensionRequestFormThaa.value.otherReason.replace(/(<([^>]+)>)/ig, ''))));
        if (this.extensionRequestFormThaa.value.reasonRadio.toLowerCase() === 'other') {
          this.formData.additionalComments.push('Other Reason' + ' | ' + this.extensionRequestFormThaa.value.otherReason.trim());
        }
        if (!this.isPFLExtension) {
          this.formData.additionalComments.push(this.appLabelConstants.LAST_VISIT + ' | ' + (this.lastVisitDate ? this.lastVisitDate : this.checkboxLabel));
          this.formData.additionalComments.push(this.appLabelConstants.NEXT_VISIT + ' | ' + (this.nextVisitDate ? this.nextVisitDate : this.checkboxLabel));
        }
        this.formData.additionalComments.push('RTW Capacity' + ' |');
        this.formData.additionalComments.push(this.appLabelConstants.RTW + ' | ' + (this.rtwDate ? this.rtwDate : this.checkboxLabel));
        if (!this.isPFLExtension) {
          this.formData.additionalComments.push('Provider Info' + ' |');
          this.formData.additionalComments.push(this.appLabelConstants.DOC_MSG + ' | ');
          this.formData.additionalComments.push(this.docName + ' | ' + this.extensionRequestFormThaa.value.docForm.replace(/(<([^>]+)>)/ig, '').trim());
          this.formData.additionalComments.push(this.docTel + ' | ' + this.extensionRequestFormThaa.value.tForm);
          this.formData.additionalComments.push(this.docFax + ' | ' + this.extensionRequestFormThaa.value.fForm);
        }
      }

      if (this.radioValueVar === 'exactDate') {
        this.date = this.extendDate;
      } else if (this.radioValueVar === 'estimateDate') {
        this.date = this.timeFrame;
      }

      if (this.isPFLExtension || (!this.isPFLExtension && (this.extensionRequestFormThaa.value.docForm && this.extensionRequestFormThaa.value.tForm && this.extensionRequestFormThaa.value.fForm && this.extensionRequestFormThaa.value.tForm.replace(/[^0-9]/g, '').length === 10 && this.extensionRequestFormThaa.value.fForm.replace(/[^0-9]/g, '').length === 10))) {
        this.callRequestExtensionService(this.date, this.extensionRequestFormThaa.value);
        sessionStorage.setItem(SessionConstants.UPDATE_DATE, this.date);
        sessionStorage.setItem(SessionConstants.EXTENSION_DATE, SessionConstants.EXTENSION_DATE_S);
      }
    }

  }

  /*MethodName : getDateFromComponent
   * Purpose : This method is used to get date value from child component anfd call method from datevalue component.
   * Modified By :AC12323
   * Creted Date : 3/6/2018
   * */

  getDateFromComponent() {
    const getFirstValue = sessionStorage.getItem(SessionConstants.GET_FIRST_VAL);
    let date;
    if (getFirstValue) {
      this.DateLabelValue.getDate();
    }
    if (this.requestExtensionDateValue) {
      date = this.requestExtensionDateValue.month + '/' + this.requestExtensionDateValue.day + '/' + this.requestExtensionDateValue.year
    }
    if (getFirstValue && (this.requestExtensionDateValue
        && this.requestExtensionDateValue.month
        && this.requestExtensionDateValue.year
        && this.requestExtensionDateValue.day === AppConstants.FORM_VALUE_DAY)
    ) {
      this.requestExtensionDateValue = {};
      this.extendThroughSubmitVal = true;
      this.readDayValue = true;
      this.errormsg = false;
      this.handleErrorLable = true;
      this.tagErrors(AppLabelConstants.EXTENSION_DATE_TEXT, '', ErrorConstants.ENTER_VALID_DATE)
    } else if (getFirstValue && (new Date(date) <= new Date(this.approveThroughDate))) {
      this.handleErrorLable = true;
      this.errormsg = true;
      this.tagErrors(AppLabelConstants.EXTENSION_DATE_TEXT, '', ErrorConstants.APPROVED_THROUGH_DATE)
    } else if (this.requestExtensionDateValue
      && this.requestExtensionDateValue.day !== AppConstants.FORM_VALUE_DAY) {
      this.handleErrorLable = false;
      this.extendThroughSubmitVal = false;
      this.readDayValue = false;
      this.errormsg = false;
    }

    this.incrementValue++;

  }


  /*MethodName : extendThroughSubmitbtn
   * Purpose : This method is used to check the slected date is greather than approve through date or not
   *           and if date is greather than then update value in data base.
   * Modified By :AC12323
   * Creted Date : 3/6/2018
   * */

  extendThroughSubmitbtn() {
    this.extensionRequestFormThaa.value.docForm = this.extensionRequestFormThaa.value.docForm.replace(/\\r/g, '');
    const formData = {
      doctorname: this.extensionRequestFormThaa.value.docForm.replace(/\\n/g, ''),
      faxNo: this.extensionRequestFormThaa.value.fForm
    };
    this.doctorDetails = formData;
    const date = this.requestExtensionDateValue.month + '/' + this.requestExtensionDateValue.day + '/' + this.requestExtensionDateValue.year;
    const approvethrough = this.approveThroughDate;
    const extendThrough = new Date(approvethrough);
    const extendthroughCompareDate = new Date(date);
    if (extendThrough.getTime() <= extendthroughCompareDate.getTime()) {
      this.updatedEstimatedDate = false;
      this.hideNextShowSubmitBtn = true;
      this.callRequestExtensionService(date, this.doctorDetails);
      sessionStorage.setItem(SessionConstants.UPDATE_DATE, date);
      sessionStorage.setItem(SessionConstants.EXTENSION_DATE, SessionConstants.EXTENSION_DATE_S);
    } else {
      this.error = true;
      this.errormsg = true;
      this.handleErrorLable = true;
    }
  }


  exit() {
    this.router.navigate([AppConstants.URL_CLAIM_DETAILS]);
  }

  /*MethodName : callRequestExtensionService
   * Purpose : This method is used to call service and update the approve through date in data base and navigate to success page.
   * Modified By :AC12323
   * Creted Date : 3/6/2018
   * */

  callRequestExtensionService(date, doctorDetails) {
    const extensionRequestDate = JSON.parse(this.storageManagementService.retrieveSessionObject(SessionConstants.CUR_CLAIM)).curClaim.extensionRequestDate;
    this.disableButton = true;
    if (date && extensionRequestDate && extensionRequestDate === date) {
      this._responseErrorHandlerService.getRequestExtensionError();
    } else {
      if (date) {
        this.requestExtensionService.getUpdatedExtesiondateThaa(this.formData).subscribe(
          UpdatedExtesiondate => {
            this.disableSpinner = 'false';
            if (UpdatedExtesiondate) {
              const userClaims = this.storageManagementService.getAppData(SessionConstants.USER_CLAIMS);
              let claimFound = false;
              //code to update the approveThrough date in app JSON
              if (userClaims && userClaims.claimOverview) {
                if (userClaims.claimOverview.nonRelatedClaims && userClaims.claimOverview.nonRelatedClaims.length > 0) {
                  const nonRelClaims = userClaims.claimOverview.nonRelatedClaims;
                  nonRelClaims.filter(function(claim, i) {
                    const eventId = JSON.parse(sessionStorage.getItem(SessionConstants.CLAIM_EVENT_ID)).claimEventId;
                    if (claim.claimEventId === eventId) {
                      claimFound = true;
                    }
                  });
                }
                if (!claimFound && userClaims.claimOverview.relatedClaims && userClaims.claimOverview.relatedClaims.length > 0) {
                  const relClaims = userClaims.claimOverview.relatedClaims;
                  relClaims.filter(function(claim, i) {
                    const eventId = JSON.parse(sessionStorage.getItem(SessionConstants.CLAIM_EVENT_ID)).claimEventId;
                    if (claim.claimEventId === eventId) {
                      claimFound = true;
                    }
                  });
                }
                if (claimFound) {
                  this.storageManagementService.setAppData(SessionConstants.USER_CLAIMS, userClaims);
                }
              }
              this.storageManagementService.setSessionData('extensionForm', UpdatedExtesiondate.form);
              this.router.navigate([AppLabelConstants.SUCCESS_REQUEST_EXTENSION_THAA_URL]);
              this.storageManagementService.setSessionData(SessionConstants.REQUEST_EXTENSION_DATE, date);
              // }
              // }
            }
          },
          (error) => {
            console.log('Error' + error);
            this.disableSpinner = 'true';
            this.disableButton = false;
            if (!this.messageAdded) {
              this.messageAdded = true;
              this.messageRibbonService.addMessage(ErrorConstants.WARNING_SM, ErrorConstants.ERROR_MSG_SOMETHNG_WENT_WRONG, null)
            }
          });
      }
    }
  }

  reMoveSessionObject() {
    sessionStorage.removeItem(SessionConstants.RADIO_VALUE);
    sessionStorage.removeItem(SessionConstants.ESTIMATE);
    sessionStorage.removeItem(SessionConstants.EXTENSION_LENGTH);
  }


  changeExtensionDate(value) {

    const selectedValue = (this.extensionRequestFormThaa.value.days) ? this.extensionRequestFormThaa.value.days : value;
    if (!selectedValue) {
      this.isExtensionValueEmpty = true;
    } else if (this.isTwoDigit) {
      this.isTwoDigit = true;
    } else {
      this.isExtensionValueEmpty = false;
      this.isTwoDigit = false;
    }
    if (!this.selectMonth) {
      this.selectMonth = AppConstants.WEEKS;
      this.weekDayMonth = this.extensionRequestFormThaa.value.days * 7;
    } else if (this.selectMonth === AppConstants.WEEKS) {
      this.weekDayMonth = this.extensionRequestFormThaa.value.days * 7;
    } else if (this.selectMonth === AppConstants.DAYS) {
      this.weekDayMonth = parseInt(this.extensionRequestFormThaa.value.days, 10);
    }
    const estimatedate = this.approveThroughDate;
    const date = new Date(estimatedate);
    const estimetedate = date.setDate(date.getDate() + this.weekDayMonth);
    const EstimeteTimestamp = new Date(estimetedate).toUTCString();
    const DateMonthYear = new Date(EstimeteTimestamp).getMonth() + 1 + '/' + new Date(EstimeteTimestamp).getDate() + '/' + new Date(EstimeteTimestamp).getFullYear();
    sessionStorage.setItem(SessionConstants.UPDATE_DATE, DateMonthYear);
    this.updatedEstimatedDate = true;
    this.timeFrame = DateMonthYear;
    this.btnEventId = this.owcsContent.data.THAA_RAE_14_Body.value.replace(this.replaceDiv, '');
    this.customEventService.trackDropdown(this.btnEventId, selectedValue);

    if (this.rtwDate && this.currentClaim && this.currentClaim.claimOnlineStatusCode === this.appConstant.COS_CLAIM_APPROVED_CD_04) {
      this.verifyRTWDate(new Date(this.rtwDate), this.appConstant.EXTENSION_DATE_CHANGED);
    }
  }

  /*
  * This function is used to prevent the user from entering a decimal point in the length of extension input field.
  * */
  preventDecimal(event) {
    if (event.key === '.') {
      event.preventDefault();
    }
  };

  isDigitValid(): boolean {
    return (this.extensionRequestFormThaa && this.extensionRequestFormThaa.get('days') && this.extensionRequestFormThaa.get('days').value && (this.extensionRequestFormThaa.get('days').value.length > 2 || this.extensionRequestFormThaa.get('days').value === '0' || this.extensionRequestFormThaa.get('days').value === '00'));
  }


  fetchOWCSContent() {
    this.pageId = this.contentService.getPageId(AppLabelConstants.THAA_REQUEST_AN_EXT);
    if (this.pageId) {
      this.contentService.getOWCSResponse(this.pageId, AppLabelConstants.THAA_REQUEST_AN_EXT_UN).subscribe(
        OWCSResponse => {
          if (OWCSResponse) {
            this.owcsContent = OWCSResponse;
            this.message_1 = this.owcsContent.data.THAA_RAE_03_Body.value;
            this.para1 = this.owcsContent.data.THAA_RAE_04_Body.value;
            this.currentApprovedDate = this.appLabelConstants.CURRENT_APPROVED;
            this.exact_Radial = this.owcsContent.data.THAA_RAE_06_Label.value;
            this.estimateRadial = this.owcsContent.data.THAA_RAE_07_Label.value;
            this.dateLabel = this.owcsContent.data.THAA_RAE_08_Body.value;
            this.lengthLabel = this.owcsContent.data.THAA_RAE_14_Body.value;
            this.extensionThruLabel = this.appLabelConstants.EXTENSION_THRU;
            this.extensionReasonLabel = this.owcsContent.data.THAA_RAE_22_Body.value;
            this.lastVisitLabel = this.owcsContent.data.THAA_RAE_24_Body.value;
            this.checkboxLabel = this.appLabelConstants.CHECKBOX;
            this.nextVisitLabel = this.owcsContent.data.THAA_RAE_27_Body.value;
            this.rtwLabel = this.owcsContent.data.THAA_RAE_30_Body.value;
            this.docMsg = this.owcsContent.data.THAA_RAE_33_Body.value;
            this.docName = this.appLabelConstants.DOCNAME;
            this.docTel = this.appLabelConstants.DOCTEL;
            this.docFax = this.appLabelConstants.DOCFAX;
            this.otherReasonQue = this.owcsContent.data.THAA_RAE_23A_Body.value;

          }
        }, (error) => {
          console.error(error);
        });
    }
  }


  checkForOther($event, formId, questionId) {
    // if ($event && $event.target) {
    //   const selectValue = $event.target.value;
    //   this.confirmSurgeryFormThaa.get([formId]).setValue(selectValue);
    //   if (selectValue.trim() === 'Other') {
    //     this.questionList.push('question_16');
    //     this.tagErrors(this.confirmSurgeryFormThaa.controls['question_16FC'].value, this.confirmSurgeryFormThaa.controls['question_16FC'].errors);
    //     this.questionList = this.arrayRemove(this.questionList, 'question_18');
    //   } else {
    //     this.questionList = this.arrayRemove(this.questionList, 'question_16');
    //     //this.questionList.push('question_18');
    //
    //   }
    //   if (selectValue.trim() === 'notSelected') {
    //     this.confirmSurgeryFormThaa.get([formId]).setValue('');
    //   }
    // }
    // this.updateQuestionList(questionId);
  }


  /**
   * this is to check whether the user has selected Other from the I am caring for  -- dropdown
   *
   * @param val
   * @param index1
   * @param index2
   * @param QId
   */
  getDropdownValue(val) {
    this.extensionRequestFormThaa.controls['reasonRadio'].setValue(val);
    this.tagErrorDropDown(this.extensionRequestFormThaa.controls['reasonRadio']. value, this.extensionRequestFormThaa.controls['reasonRadio'].errors)
        if (val.toLowerCase() === 'notselected' || val === null || val === '') {
          this.extensionRequestFormThaa.controls['reasonRadio'].markAsUntouched();
          this.extensionRequestFormThaa.controls['reasonRadio'].setErrors({required: true});
          this.extensionRequestFormThaa.controls['reasonRadio'].setValue('');
        }
          this.extensionRequestFormThaa.controls['otherReason'].markAsUntouched();
          this.extensionRequestFormThaa.controls['otherReason'].setValue('');
          this.extensionRequestFormThaa.controls['otherReason'].reset();
          this.isReasonEmpty = true;
          this.charLeft = this.maxLength;
  };

  /**
   * this function is called on radio selection
   *
   * @param eve
   * @param dispId
   * @param field
   * @param value
   * @param quesText
   */
  setRadioVal(eve, dispId, field, value) {
    if (eve) {
      eve.preventDefault();
      eve.stopImmediatePropagation();
    }
    this.extensionRequestFormThaa.controls['reasonRadio'].setValue(dispId);
    if (dispId.toLowerCase() === 'notselected' || dispId === null || dispId === '') {
      this.extensionRequestFormThaa.controls['reasonRadio'].markAsUntouched();
      this.extensionRequestFormThaa.controls['reasonRadio'].setErrors({required: true});
      this.extensionRequestFormThaa.controls['reasonRadio'].setValue('');
    }
    this.extensionRequestFormThaa.controls['otherReason'].markAsUntouched();
    this.extensionRequestFormThaa.controls['otherReason'].setValue('');
    this.extensionRequestFormThaa.controls['otherReason'].reset();
    this.isReasonEmpty = true;
    this.charLeft = this.maxLength;
    this.customEventService.trackRadioButton(AppLabelConstants.REASON_FOR_EXTENSION, value);

  }

  /**
   * To set reason in user request
   */
  validateReason(event) {
    this.reasonInput = event.target.value;
    if (this.maxLength >= this.reasonInput.length) {
      this.charLeft = this.maxLength - this.reasonInput.length;
    }
    if (this.extensionRequestFormThaa.value.reasonRadio.toLowerCase() === 'other' && (this.extensionRequestFormThaa.value.otherReason === '' || this.extensionRequestFormThaa.value.otherReason === null || !this.extensionRequestFormThaa.value.otherReason.trim())) {
      this.extensionRequestFormThaa.get('otherReason').setValue('');
      this.charLeft = this.maxLength;
      this.extensionRequestFormThaa.controls['otherReason'].markAsTouched();
      this.extensionRequestFormThaa.controls['otherReason'].markAsDirty();
      this.reasonInput = '';
      this.isReasonEmpty = false;
      this.tagErrorOther(this.extensionRequestFormThaa.controls['otherReason'].value, this.extensionRequestFormThaa.controls['otherReason'].errors);
    }
  }

  textBoxBlur(data) {
    this.extensionRequestFormThaa.controls[data].markAsTouched();
    this.isReasonEmpty = true;
    this.customEventService.trackFormTextBox(AppLabelConstants.REASON_FOR_EXTENSION, 'Other');
  }

  selectedVal(event, quesId) {
   this.extensionRequestFormThaa.controls[quesId].setValue(event);
  };

  /**
   * check if user is on radio button when he/she use tab to move on Radio buttons
   *
   * @param event
   * @param quesId
   * @param radioIndex
   * @param length
   */
  radioBlur(event, quesId, radioIndex, length) {
    if (!this.extensionRequestFormThaa.controls[quesId].valid || this.extensionRequestFormThaa.controls[quesId].value === AppLeaveIntakeConstants.NOT_SELECTED) {
      if ( radioIndex === length - 1 && event === 'blur' || ( radioIndex === 0 && event === 'blur')) {
        this.extensionRequestFormThaa.controls[quesId].setValue(AppLeaveIntakeConstants.NOT_SELECTED);
      } else {
        this.extensionRequestFormThaa.controls[quesId].setValue('');
      }
    }
  };

  ngOnDestroy() {
    if (this.storageManagementService.getSessionData(SessionConstants.CLAIM_RTW_EXTENSION_DATE)) {
      this.storageManagementService.removeAttrFromSessionData(SessionConstants.CLAIM_RTW_EXTENSION_DATE);
    }
  }

}

