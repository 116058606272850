/**
 * Created by AC12256 on 2/10/2018.
 */
import {Provision} from '../shared/models/provisionLeave.model';

export const PROVISIONLEAVE: Provision[] = [
{
  caseId: '1224651',
  provisionId: '806',
  provisionDescription: 'FMLA/ABSENCE MGT SERVICES',
  provisionOptionId: '4',
  provisionOptionValue: 'LEAVE MANAGEMENT SERVICES (NEW SYSTEM)',
  provisionOptionTerminationDate: null
}];


