import { Injectable } from "@angular/core";
import { ActivatedRouteSnapshot, Resolve } from "@angular/router";
import { MagnoliaPageConstants } from "@app/shared/constants/magnoliaPage.constants";
import { ContentService } from "@app/shared/services/content.service";
import { StorageManagementService } from "@app/shared/services/storage-management.service";
import { MagnoliaContent } from "../models/magnolia-content.model";

@Injectable({
    providedIn: 'root'
  })
export class MagnoliaContentResolverService implements Resolve<void> {
    private pageID: string;
    private pageName: string;
    private pageSpecificContentName: string;
    private magnoliaContent: MagnoliaContent;
    constructor(private storageManagementService: StorageManagementService, private contentService: ContentService) {
    };

    async resolve(route: ActivatedRouteSnapshot) {
        this.pageName = route.data['pageName'];
        this.pageSpecificContentName = this.getPageSpecificContentName(this.pageName);
        await this.getMagnoliaContent();
    };

  async getMagnoliaContent(): Promise<void> {
    this.pageID = this.contentService.getPageId(this.pageName);
    if (this.pageID) {
      this.magnoliaContent = this.storageManagementService.getSessionData(this.pageSpecificContentName);
      if (this.magnoliaContent === undefined || this.magnoliaContent === null) {
        const magnoliaResponse = await this.contentService.getOWCSResponse(this.pageID, this.pageName).toPromise();
        this.storageManagementService.setSessionData(this.pageSpecificContentName, magnoliaResponse);
        if (MagnoliaPageConstants.NOTIFICATION_PREFERENCE === this.pageName) {

          const pageContentName: string = this.getPageSpecificContentName(MagnoliaPageConstants.INTAKE_CONFIRMATION);
          const magnoliaContent: MagnoliaContent = this.storageManagementService.getSessionData(pageContentName);
          if (magnoliaContent === undefined || magnoliaContent === null) {
            const id = this.contentService.getPageId(MagnoliaPageConstants.INTAKE_CONFIRMATION);
            const magnoliaContentResponse = await this.contentService.getOWCSResponse(id, MagnoliaPageConstants.INTAKE_CONFIRMATION).toPromise();
            this.storageManagementService.setSessionData(pageContentName, magnoliaContentResponse);
          }
        }
      }
    };
  };

  private getPageSpecificContentName(pageName: string): string {
    switch (pageName) {
      case MagnoliaPageConstants.ABOUT_YOUR_ABSENCE_PAGE:
        return MagnoliaPageConstants.AYA_CONTENT_DATA;
      case MagnoliaPageConstants.ABOUT_YOUR_BONDING_PAGE:
        return MagnoliaPageConstants.AYB_CONTENT_DATA;
      case MagnoliaPageConstants.ABOUT_YOUR_DELIVERY_PAGE:
        return MagnoliaPageConstants.AYD_CONTENT_DATA;
      case MagnoliaPageConstants.EMPLOYMENT_QUESTIONS:
        return MagnoliaPageConstants.EQ_CONTENT_DATA;
      case MagnoliaPageConstants.NOTIFICATION_PREFERENCE:
        return MagnoliaPageConstants.NP_CONTENT_DATA;
      case MagnoliaPageConstants.INTAKE_CONFIRMATION:
        return MagnoliaPageConstants.INTAKE_CONFIRMATION_DATA;
      case MagnoliaPageConstants.EDIT_WORK_SCHEDULE_PAGE:
        return MagnoliaPageConstants.EDIT_WORK_SCHEDULE_DATA;
      case MagnoliaPageConstants.PLAN_MY_LEAVE_LAUNCH:
        return MagnoliaPageConstants.PLAN_MY_LEAVE_LAUNCH;
      default:
        return '';
    }
  };
}
