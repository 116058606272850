import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
  name: 'decimalFormat'
})
export class DecimalFormatPipe implements PipeTransform {

  transform(amount: string, args?: any): string {

    if ((amount.split('.')).length === 2) {
      const valueBeforeDecimal = amount.split('.')[0];
      const valueAfterDecimal = amount.split('.')[1];

      if ((valueBeforeDecimal === '0') && (valueAfterDecimal === '00')) {
        return '0';
      }

      // if((valueBeforeDecimal!=='0') && (valueAfterDecimal==='00')) return valueBeforeDecimal;


      return amount;
    }
  }

}
