import {RouterModule, Routes} from '@angular/router';
import {NgModule} from '@angular/core';
import {CompanionComponent} from './companion/companion.component';
import {SampleFormComponent} from './sample-form/sample-form.component';
import {MockLandingComponent} from './mock-landing/mockLanding.component';
import {HomeComponent} from '../home/home.component';
import {CanActivateLogin} from '../../shared/utils/CanActivateUtils';
import { MagnoliaContentResolverService } from '../../shared/services/magnolia-content-resolver.service';
import { MagnoliaPageConstants } from '../../shared/constants/magnoliaPage.constants';

export const MRoutes: Routes = [
  {
    path: 'login',
    component: MockLandingComponent
  },
  {
    path: 'home',
    component: HomeComponent, resolve: { data: MagnoliaContentResolverService },
    data: { pageName: MagnoliaPageConstants.PLAN_MY_LEAVE_LAUNCH },
    canActivate: [CanActivateLogin]
  },
  {
    path: 'companion',
    component: CompanionComponent
  },
  {
    path: 'sampleForm',
    component: SampleFormComponent
  },

];

@NgModule({
  imports: [RouterModule.forChild(MRoutes)],
  exports: [RouterModule]
})

export class AppRoutingMaintenanceModule {}
