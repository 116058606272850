export const ADA_RESPONSE = {
  accommodationDetails: [
    {
      certId: '123',
      accommodationUpdate: true,
      certStatus: 'Pending Accommodation Certification',
      certSubStatus: 'Your health care provider must complete and return the paperwork you received. Once we receive it, we will continue reviewing your claim.',
      accommodationType: 'Leave',
      accommodationSubType: 'Continuous Leave'
    },
    {
      certId: '456',
      accommodationUpdate: true,
      certStatus: 'Pending Accommodation Certification',
      certSubStatus: 'Your health care provider must complete and return the paperwork you received. Once we receive it, we will continue reviewing your claim.',
      accommodationType: 'Leave',
      accommodationSubType: 'Continuous Leave'
    },
    {
      certId: '789',
      accommodationUpdate: false,
      certStatus: 'Pending Accommodation Certification',
      certSubStatus: 'Your health care provider must complete and return the paperwork you received. Once we receive it, we will continue reviewing your claim.',
      accommodationType: 'Leave',
      accommodationSubType: 'Continuous Leave'
    }
  ],
  responseCode: '001',
  responseReason: 'success'
};
