export class AppLeaveIntakeConstants {
  /** START A NEW CLAIM AA RESPONSE CODE */
  static SUCCESS_CODE_001 = '001';
  static OPEN_DISABILITY_CODE_003 = '003';
  static NO_CALIMID_CODE_005 = '005';
  static RES_CODE_478 = '478'; // tactical feature toggle response code
  static RES_CODE_002 = '002';
  static RES_CODE_560 = '560';

  /** naviagtion to pages */
  static REASON_SELECTION_PAGE = '/leave-intake/selectReason';
  static SUBMIT_CONFIRM_PAGE = '/leave-intake/leaveSubmitConfirm';
  static UPDATE_ERROR_PAGE = '/update-error';
  static HOME = '/home';
  static CONTACT_US_URL = '/contactUs';
  static PRODUCT_INFO = '/productInfo/';
  static LEAVE_SPECIFIC_PAGE = '/leave-intake/leaveSpecificQues';
  static CLAIM_SUBMIT_NOT_ALLOW_PAGE = '/leave-intake/claimSubmitNotAllow';
  static ADA_INTAKE_FLOW = '/leave-intake/adaSpecificQues';

  /** Button labels */
  static SUBMIT_BTN = 'Submit';
  static EXIT_BTN_TEXT = 'Exit without saving changes';
  static CANCEL_BTN_TEXT = 'Cancel';
  static CANCEL_BTN = 'Cancel Button';
  static NEXT_BTN_TEXT = 'Next';
  static RETURN_HOME = 'Return Home';
  static CONTACT_US = 'Contact Us';
  static GO_BACK = 'Go Back';
  static CANCEL_MODAL_INTAK = 'cancelModalIntake';
  static CANCEL_MODAL_TITLE = 'Are you sure you want to exit?';
  static CANCEL_MODAL_PARA = 'If you made any changes, they won\'t be saved.';
  static CONTINUE_BTN = 'Continue';
  static SAVE_CHANGES = 'Save Changes';
  static LOADING_LABEL = 'Loading';

  /** select reason - leave intake flow */
  static BEFORE_YOU_CONTINUE = 'Before You Continue';
  static CLAIM_EXIST = 'You already have an open disability claim. You can see the details on the homepage.';
  static START_CLAIM = 'Start a New Claim';
  static START_A_CLAIM = 'Please call us to start a claim';
  static SELECT_REASON = 'Please choose a reason:';
  static BANNER_IMPRESSION = 'Before you Continue Banner Impression Tracking';
  static NEW_LEAVE_STEP_ONE = 'Start a New Leave AA Step 1';
  static REASON_TAG  = 'Please choose a Reason';
  static GAPREASON = 'gapReason';

  /** */
  static SCHEDULE_HOURS_LABEL = 'Scheduled work time';
  static MISSED_HOURS_LABEL =  'Time missed';
  static START_TIME_LABEL = 'Start time of absence';
  /*regular-workschedule page*/
  static REGULAR_WORKSCHEDULE_COMMON_TEXT =  'Here are the hours we have for you current work schedule .Please review and confirm that all the information is accurate.';
  static SCHEDULE = ' Schedule';
  static FIRST_DAY_LABEL = 'First Day';
  static SCHEDULE_CHANGE_WARNING = 'You\'ll need to contact us about any changes to your schedule during your leave. Having an accurate picture of your schedule helps us track your leave time correctly.';
  static ROTATING = 'Rotating';
  static WEEKLY_SCHEDULE = 'Weekly Schedule';
  static WEEK = 'Week';
  static DAY = 'Day';
  static DAYS = 'days';
  static HOURS_LABEL = 'hours';
  static MIN_LABEL = 'min';
  static SINGLE_DAY = 'day';
  static DAY_ROTATION = 'Day Rotation'
  /** leave intake - success page*/
  static PFLC_LABEL = 'Paid Family Leave Claim:';
  static LOAC_LABEL = 'Leave of Absence Claim:';
  static STD_LABEL = 'Short-Term Disability:';
  static STAT_LABEL = 'State-Mandated Disability:';
  static ADA_LABEL = 'Accommodation Request:';
  static SUBMIT_ANOTHER_CLAIM = 'Submit Another Claim';
  static ZERO = '0';
  static NEW_LEAVE_STEP_FOUR = 'Start a New Leave AA Step 4';
  static  UNABLE_T0_SUBMIT_CLAIMS_ONLINE = 'Unable to Submit Claims Online';
  static  UNABLE_T0_SUBMIT_CLAIMS_ONLINE_U = 'Unable_to_Submit_Claims_Online';
  static PHONE_NUMBER_CLICK = 'Phone Number Click';
  static PAGE_TAG = 'Page Tag';
  static LINK_CLICK = 'Link Click';
  static CLAIM_SUBMITTED = 'Claim Submitted';

  /** leave intake -specific question page */
  static NOT_SELECTED = 'notSelected';
  static REASON_LABEL = 'Reason:';
  static DATE_HOUR = 'Date and Hours';
  static HOURS_ABSENT = 'Time Absent on ';
  static CLAIM_SPECIFIC = 'Claim Reason Specifics';
  static REDUCED_SCHEDULE = 'Reduced Schedule';
  static REGULAR_WORK_SCHEDULE = 'Regular Work Schedule';
  static REDUCEDGRID_DEF = 'Enter your planned work hours for each day during your leave:';
  static REDUCEDGRID_CHKBOX = 'Enter your planned work time for the first day of your extension:';
  static REDUCED_DATE_LABEL = 'Date :';
  static REDUCED_SCHEDULE_HOURS_LABEL = 'Scheduled work time';
  static REDUCED_WORK_HOURS_LABEL = 'Planned/actual work time';
  static REDUCED_NOTWORK_HOURS_LABEL = 'Time not worked';
  static ONE = '1';
  static TWO = '2';
  static THREE = '3';
  static FIFTEEN = '15';
  static DISPLAY_REASON_ADA = 'ADA accommodation';
  static ADA_PROCESS_KEY = 'DisabilityAccomodations';
  static INVALID = 'INVALID';
  static INVALID_LABEL  = 'invalid';
  static  VALID_LABEL = 'valid';
  static NEW_LEAVE_STEP_TWO = 'Start a New Leave AA Step 2';
  static ADA_START_A_CLAIM = 'ADA Start a Claim';
  static REGULAR_WORK_SCHEDULE_OWCS = 'Work Schedule';
  static I_DONT_KNOW_LABEL = 'I don\'t know';
  static UNKNOWN_LABEL = 'Unknown';
  static APPROVED = 'Approved';
  static REDUCED_SCHEDULE_LOAD_LABEL = 'Loading...';
  static PLANNED_WORK_LABEL = 'Planned work:';
  static PREFILL_QUE_TEXT = 'Do you want to pre-fill your schedule? You’ll get the same amount of time for each work day. This is handy if you work the same shift/number of hours every day.';
  static REDUCED_WORK_HOURS_PREFILL = 'Planned/actual work time each workday';
  static YES_LABEL = 'Yes';
  static NO_LABEL = 'No';
  static NO_PARTIAL_LABEL = 'No, Partial';
  static SHOW_WARNING_MESSAGE = 'showWarningMessage';
  static ARE_YOU_SURE = 'Are You Sure?';
  static DISP_NAME = 'DisplayName';
  static WARNING_BANNER = 'Banner Impression Tracking';
  static LEAVE_TYPE_QUES = 'When did you start/do you expect to start your leave? If you don\'t know, that\'s okay--just select today\'s date.';
  static RTW_QUES = 'When do you expect to return to work? If you don\'t know yet, check the box that says "I don\'t know". If your date changes, that\'s okay--just make sure to call us once you\'re back at work.'
  static DAY_TYPE_QUES = 'Will your first day absent be a full day or a partial day?';
  static TEXTBOXID = 'requesteForPastLeaves';
  static MEDICAL_REASON_QUESTION = 'What\'s the medical reason for your claim?';
  static MEDICAL_REASON_ID = 'medicalConditionsDropdown_';
  static ACCOMMODATION_TYPE = '[accommodation_type]';
  static ACCOMMODATION_STARTED_AT = 'My accommodation started at';
  static ACCOMMODATION_FIRST_DAY_HAD_FOR = 'On the first day, I had my accommodation for';

  /** Calendar events - when calendar open/ close/ date selected/ closed ithout date selection */
  static CAL_2 = 2;
  static CAL_3 = 3;
  static CAL_4 = 4;
  static CAL_5 = 5;

  /** control IDs */
  static CID_1 = 1;
  static CID_2 = 2;
  static CID_3 = 3;
  static CID_4 = 4;
  static CID_5 = 5;
  static CID_10_UNKNOWN = 10;
  static CID_12 = 12;
  static CID_17 = 17;
  static CID_18 = 18;
  static CID_22 = 22;
  static CID_38 = 38;

  /** Registered field IDs */
  static FID_10 = 10;
  static FID_21 = 21;
  static FID_1516 = 1516;
  static FID_42_UNKNOWN = 42;
  static FID_114704 = 114704;
  static FID_43 = 43; //Date
  static FID_49 = 49;
  static FID_67 = 67;
  static FID_608213 = 608213; //Non Work Day
  static FID_600914 = 600914; //Redused Schedule for Proper
  static FID_70 = 70;
  static FID_PERMANENT = 136609;
  static FID_175 = 175;
  static FID_117529 = 117529;
  static FID_126383 = 126383;
  static FID_134484 = 134484;
  static FID_601755 = 601755;
  static FID_129 = 129;
  static FID_600493 = 600493;
  static FID_TIME_AWAY = 127164;
  static FID_ACCOMMODATION_VALUE_TYPE = 615614;
  static FID_ACCOMMODATION_TYPE = 615613;
  static FID_ACTIONS_HAVING = 615516;
  static FID_ACTIONS_UNABLE_TO_PERFORM = 615675;
  static FID_STARTDATE_ACCOMMODATION = 43;
  static FID_LASTDATE_WO_ACCOMMODATION = 49;
  static FID_ACCOMMODATION_END_DATE = 67;

  /** Registered field IDs as string */
  static FID_35_LABEL = '35';
  static FID_43_LABEL = '43';
  static FID_49_LABEL = '49';
  static FID_67_LABEL = '67';
  static FID_68_LABEL = '68';
  static FID_70_LABEL = '70';
  static FID_129_LABEL = '129';
  static FID_601755_LABEL = '601755';
  static FID_600914_LABEL = '600914';
  static FID_175_LABEL = '175';
  static FID_1516_LABEL = '1516';
  static FID_112373_LABEL = '112373';
  static FID_134484_LABEL = '134484';
  static FID_124522_LABEL = '124522';
  static FID_126383_LABEL = '126383';
  static FID_124802_LABEL = '124802';
  static FID_117533_LABEL = '117533';
  static FID_10_LABEL = '10';
  static FID_608213_LABEL = '608213';
  static FID_600493_LABEL = '600493';
  static FID_605293_Label = '605293';
  static FID_605274_Label = '605274';
  static FID_118895_LABEL = '118895';
  static FID_68_LABEL_INTEGER = 68;
  static FID_69_OTHER_MEDICAL_REASON = 69;
  static FID_ACCOMMODATION_TYPE_OTHER_OPTION_DEFINED = 615715;
  static FID_ACCOMMODATION_SUB_TYPE_OTHER_OPTION_DEFINED = 615735;

  /** Display ID */
  static DID_13 = '13';
  static DID_YES = '1001';
  static DID_1002 = '1002';
  static DID_125001 = '125001';
  static DID_125002 = '125002';
  static DID_17001 = '17001';
  static DID_17002 = '17002';
  static DID_17003 = '17003';
  static DID_18038 = '18038';
  static DID_2002 = '2002';
  static DID_2001 = '2001';
  static DID_773101 = '773101';
  static DID_773100 = '773100';
  static DID_OTHER = '18028';
  static DID_OTHERS = '416347';
  static DID_416357 = '416357';
  static DID_LEAVE_ACCOMMODATION = '416357';
  static DID_OTHER_ACCOMMODATION_TYPE = '416370';
  static DID_OTHER_ACCOMMODATION_SUBTYPE = '416577';
  static DID_OTHER_ACCOMMODATION_SUBTYPE_CON = '416372';

  /* Question ID */
  static QID_LAST_DAY = 25507574;
  static QID_FIRST_DAY = 25507575;
  static QID_ACCOMMODATION_VALUE_TYPE = 25507572;

  /* true or false values */
  static TRUE_VALUE = true;
  static FALSE_VALUE = false;

  static scriptDetailId = '14396700';

  static PARTIAL_RADIO_125001 = '125001';
  static FULL_RADIO_125002 = '125002';
  /** Error messages */
  static PLEASE_ANS = 'Please answer before submitting.';
  static WARNING_AT_SUCCESS_PAGE = 'You’ve entered more hours than you’re scheduled to work. To speed up the claim process, please <a href="/contactUs"><strong><u>contact us</u></strong></a> and verify your schedule.';
  static PROVIDE_DIFF_DATE = 'Provide a different date. We want to know your last day of work BEFORE your leave.';
  static CHECK_DELIVERY_DATE = 'Please check the delivery or due date, and leave date you’ve provided. Your leave shouldn’t start AFTER your delivery or due date.';
  static CHECK_RETRUN_DATE = 'Please check your return-to-work date and leave start date. Your leave shouldn’t start AFTER you return to work.';
  static CHECK_PROCEED = 'You’ve entered more hours than you’re scheduled to work. Please verify that the hours you entered are right before you continue.';
  static NO_TO_YES_WARNING_MSG = 'Pre-filling your schedule will clear the hours you already entered. Do you want to continue?';
  static CONFRIM_RWS_ERR = 'Please confirm that your work schedule is accurate, or make changes if it\'s not.';
  static LATE_REPORTING_ERR = 'Please share why you waited to report your time. Most plans give you 2 days to report intermittent time after taking it.';
  static CHECK_RETURN_DELIVERY_DATA = 'The date you expect to return to work must be after the date you delivered.';
  static EXISTING_FDA_SELECTED = 'It looks like you already reported an absence on this date. Please double check your date and try again.';
  /** IMAGES PATH */
  static PROFILE_CALENDAR = '/assets/images/icons/profile-complete-calendarpage.png';
  static WHITE_CLOSE = '/assets/images/icons/white-x close.svg';
  static SSO_ONLY = '/assets/images/icons/sso-only.svg';
  static  WARNING_ICON = '/assets/images/icons/warning-icon.svg';
  static WEEKLY = 'Weekly';
  static MULTI_WEEKLY = 'Multi-Week';
  static MULTI_WEEK = 'Multi Week';
  static BACKBUTTON = 'Back Button';
  static DEFAULT_WORK_SCHEDULE =  {
    createWeekly: {
      empWorkSchedulesList:
        [
          {
            workScheduleId: '',
            workScheduleEffectiveDate: '',
            thaaWorkSchduleType: 'Weekly',
            daysInRotation: 0,
            employeeWorkSchedules: [
              {
                weekNumber: 1,
                weekDetails: [
                  {
                    workMinutes: 0,
                    dayNumber: 1,
                    weekNumber: 1
                  },
                  {
                    workMinutes: 0,
                    dayNumber: 2,
                    weekNumber: 1
                  },
                  {
                    workMinutes: 0,
                    dayNumber: 3,
                    weekNumber: 1
                  },
                  {
                    workMinutes: 0,
                    dayNumber: 4,
                    weekNumber: 1
                  },
                  {
                    workMinutes: 0,
                    dayNumber: 5,
                    weekNumber: 1
                  },
                  {
                    workMinutes: 0,
                    dayNumber: 6,
                    weekNumber: 1
                  },
                  {
                    workMinutes: 0,
                    dayNumber: 7,
                    weekNumber: 1
                  }
                ]
              }
            ]
          }
        ],
      responseCode: '001',
      responseReason: 'success'
    },
    createMultiWeek : {
      empWorkSchedulesList:
        [
          {
            workScheduleId: '',
            workScheduleEffectiveDate: '',
            thaaWorkSchduleType: 'Weekly',
            daysInRotation: 0,
            employeeWorkSchedules: [
              {
                weekNumber: 1,
                weekDetails: [
                  {
                    workMinutes: 0,
                    dayNumber: 1,
                    weekNumber: 1
                  },
                  {
                    workMinutes: 0,
                    dayNumber: 2,
                    weekNumber: 1
                  },
                  {
                    workMinutes: 0,
                    dayNumber: 3,
                    weekNumber: 1
                  },
                  {
                    workMinutes: 0,
                    dayNumber: 4,
                    weekNumber: 1
                  },
                  {
                    workMinutes: 0,
                    dayNumber: 5,
                    weekNumber: 1
                  },
                  {
                    workMinutes: 0,
                    dayNumber: 6,
                    weekNumber: 1
                  },
                  {
                    workMinutes: 0,
                    dayNumber: 7,
                    weekNumber: 1
                  }
                ]
              },
              {
                weekNumber: 2,
                weekDetails: [
                  {
                    workMinutes: 0,
                    dayNumber: 1,
                    weekNumber: 2
                  },
                  {
                    workMinutes: 0,
                    dayNumber: 2,
                    weekNumber: 2
                  },
                  {
                    workMinutes: 0,
                    dayNumber: 3,
                    weekNumber: 2
                  },
                  {
                    workMinutes: 0,
                    dayNumber: 4,
                    weekNumber: 2
                  },
                  {
                    workMinutes: 0,
                    dayNumber: 5,
                    weekNumber: 2
                  },
                  {
                    workMinutes: 0,
                    dayNumber: 6,
                    weekNumber: 2
                  },
                  {
                    workMinutes: 0,
                    dayNumber: 7,
                    weekNumber: 2
                  }
                ]
              }
            ]
          }
        ],
      responseCode: '001',
      responseReason: 'success'
    },
    createRotating : {
      empWorkSchedulesList:
        [
          {
            workScheduleId: '',
            workScheduleEffectiveDate: '',
            thaaWorkSchduleType: 'Rotating',
            daysInRotation: 0,
            employeeWorkSchedules: [
              {
                weekNumber: -1,
                weekDetails: [
                ]
              }
            ]
          }
        ],
      responseCode: '001',
      responseReason: 'success'
    }
  };
  static ADD_WEEK =  {
    weekNumber: 2,
    weekDetails: [
      {
        workMinutes: 0,
        dayNumber: 1,
        weekNumber: 2,
        regularHours: {hours: 0, min: 0},
        regularHoursDisplay: '0 hours',
        dayVal: 'Sun'
      },
      {
        workMinutes: 0,
        dayNumber: 2,
        weekNumber: 2,
        regularHours: {hours: 0, min: 0},
        regularHoursDisplay: '0 hours',
        dayVal: 'Mon'
      },
      {
        workMinutes: 0,
        dayNumber: 3,
        weekNumber: 2,
        regularHours: {hours: 0, min: 0},
        regularHoursDisplay: '0 hours',
        dayVal: 'Tue'
      },
      {
        workMinutes: 0,
        dayNumber: 4,
        weekNumber: 2,
        regularHours: {hours: 0, min: 0},
        regularHoursDisplay: '0 hours',
        dayVal: 'Wed'
      },
      {
        workMinutes: 0,
        dayNumber: 5,
        weekNumber: 2,
        regularHours: {hours: 0, min: 0},
        regularHoursDisplay: '0 hours',
        dayVal: 'Thu'
      },
      {
        workMinutes: 0,
        dayNumber: 6,
        weekNumber: 2,
        regularHours: {hours: 0, min: 0},
        regularHoursDisplay: '0 hours',
        dayVal: 'Fri'
      },
      {
        workMinutes: 0,
        dayNumber: 7,
        weekNumber: 2,
        regularHours: {hours: 0, min: 0},
        regularHoursDisplay: '0 hours',
        dayVal: 'Sat'
      }
    ]
  };

  static MEDICAL_CONDITIONS = [
    {
      DisplayId : '18030',
      DisplayName: 'Pregnancy w/Comp',
      ParentDisplayId: 0
    },
    {
      DisplayId : '18031',
      DisplayName: 'Pregnancy Normal',
      ParentDisplayId: 0
    },
    {
      DisplayId : '18038',
      DisplayName: 'Unknown',
      ParentDisplayId: 0
    },
    {
      DisplayId : '10025097',
      DisplayName: 'Behavioral Health Conditions',
      ParentDisplayId: 0
    },
    {
      DisplayId : '10025098',
      DisplayName: 'Brain/Head Conditions',
      ParentDisplayId: 0
    },
    {
      DisplayId : '10025099',
      DisplayName: 'Face Conditions',
      ParentDisplayId: 0
    },
    {
      DisplayId : '10025100',
      DisplayName: 'Eye/Ear Conditions',
      ParentDisplayId: 0
    },
    {
      DisplayId : '10025101',
      DisplayName: 'Nose/Sinus Conditions',
      ParentDisplayId: 0
    },
    {
      DisplayId : '10025102',
      DisplayName: 'Mouth/Tonsils/Teeth Conditions',
      ParentDisplayId: 0
    },
    {
      DisplayId : '10025103',
      DisplayName: 'Thyroid Conditions',
      ParentDisplayId: 0
    },
    {
      DisplayId : '10025104',
      DisplayName: 'Respiratory/Flu',
      ParentDisplayId: 0
    },
    {
      DisplayId : '10025105',
      DisplayName: 'Chest/Lung Conditions',
      ParentDisplayId: 0
    },
    {
      DisplayId : '10025106',
      DisplayName: 'Breast/Chest Conditions',
      ParentDisplayId: 0
    },
    {
      DisplayId : '10025107',
      DisplayName: 'Heart/Cardiac/Blood Pressure',
      ParentDisplayId: 0
    },
    {
      DisplayId : '18020',
      DisplayName: 'Hernia',
      ParentDisplayId: 0
    },
    {
      DisplayId : '18052',
      DisplayName: 'Gallbladder Conditions',
      ParentDisplayId: 0
    },
    {
      DisplayId : '10025108',
      DisplayName: 'Weight Loss/Bariatric',
      ParentDisplayId: 0
    },
    {
      DisplayId : '10025109',
      DisplayName: 'Appendix/Pancreas/Spleen Conditions',
      ParentDisplayId: 0
    },
    {
      DisplayId : '10025135',
      DisplayName: 'Kidney Conditions',
      ParentDisplayId: 0
    },
    {
      DisplayId : '10025136',
      DisplayName: 'Liver Conditions',
      ParentDisplayId: 0
    },
    {
      DisplayId : '10025110',
      DisplayName: 'Abdomen/Upper GI Conditions',
      ParentDisplayId: 0
    },
    {
      DisplayId : '10025111',
      DisplayName: 'Bowel/Colon Conditions',
      ParentDisplayId: 0
    },
    {
      DisplayId : '10025112',
      DisplayName: 'Rectal/Lower GI Conditions',
      ParentDisplayId: 0
    },
    {
      DisplayId : '10025113',
      DisplayName: 'Neck/Cervical/Upper Back Conditions',
      ParentDisplayId: 0
    },
    {
      DisplayId : '10025114',
      DisplayName: 'Mid Back/Thoracic Conditions',
      ParentDisplayId: 0
    },
    {
      DisplayId : '10025115',
      DisplayName: 'Low Back/Lumbar/Sacral Conditions',
      ParentDisplayId: 0
    },
    {
      DisplayId : '10025116',
      DisplayName: 'Hand/Finger Conditions',
      ParentDisplayId: 0
    },
    {
      DisplayId : '10025117',
      DisplayName: 'Wrist Conditions',
      ParentDisplayId: 0
    },
    {
      DisplayId : '10025118',
      DisplayName: 'Arm Conditions',
      ParentDisplayId: 0
    },
    {
      DisplayId : '10025119',
      DisplayName: 'Elbow Conditions',
      ParentDisplayId: 0
    },
    {
      DisplayId : '10025120',
      DisplayName: 'Shoulder Conditions',
      ParentDisplayId: 0
    },
    {
      DisplayId : '10025121',
      DisplayName: 'Pelvis/Hip Conditions',
      ParentDisplayId: 0
    },
    {
      DisplayId : '10025122',
      DisplayName: 'Leg Conditions',
      ParentDisplayId: 0
    },
    {
      DisplayId : '10025123',
      DisplayName: 'Knee Conditions',
      ParentDisplayId: 0
    },
    {
      DisplayId : '10025124',
      DisplayName: 'Ankle/Foot Conditions',
      ParentDisplayId: 0
    },
    {
      DisplayId : '10025125',
      DisplayName: 'Cervix/Fallopian Tube Conditions',
      ParentDisplayId: 0
    },
    {
      DisplayId : '10025126',
      DisplayName: 'Vaginal/Ovarian Conditions',
      ParentDisplayId: 0
    },
    {
      DisplayId : '10025127',
      DisplayName: 'Hysterectomy/Uterus Conditions',
      ParentDisplayId: 0
    },
    {
      DisplayId : '10025128',
      DisplayName: 'Pregnancy Complications',
      ParentDisplayId: 0
    },
    {
      DisplayId : '10025129',
      DisplayName: 'Prostate/Testicular/Genital Conditions',
      ParentDisplayId: 0
    },
    {
      DisplayId : '18028',
      DisplayName: 'Other',
      ParentDisplayId: 0
    },
    {
      DisplayId : '18013',
      DisplayName: 'Fibromyalgia/Chronic Fatigue',
      ParentDisplayId: 0
    },
    {
      DisplayId : '18011',
      DisplayName: 'Diabetes',
      ParentDisplayId: 0
    },
    {
      DisplayId : '10025134',
      DisplayName: 'Autoimmune Conditions',
      ParentDisplayId: 0
    },
    {
      DisplayId : '10023594',
      DisplayName: 'Quarantine without symptoms',
      ParentDisplayId: 0
    },
    {
      DisplayId : '10023593',
      DisplayName: 'Quarantine with symptoms or positive test',
      ParentDisplayId: 0
    }
  ];

  static ACCOMMODATION_TYPES = [
    {
      DisplayId: '416374',
      DisplayName: 'Intermittent time off for appointments',
      ParentDisplayId: 416357
    },
    {
      DisplayId: '416373',
      DisplayName: 'Intermittent time off for flare ups',
      ParentDisplayId: 416357
    },
    {
      DisplayId: '416375',
      DisplayName: 'Intermittent time off for flare ups and appointments',
      ParentDisplayId: 416357
    }
  ]

  static ACCOMMODATION_SUBTYPE_OTHER = {
    DisplayId: '416577',
    DisplayName: 'Other',
    ParentDisplayId: 0
    }
}
