/**
 * Created by AC12394 on 1/18/2018.
 */
export class MissingInfo {
  mInfoCode: string;
  mInfoDesc: string;
  requiredByDate: string;
  mInfoReqInfo: MissingReqInfo[];
  gracePeriod: boolean;
}

export class MissingReqInfo {
  mInfoReqInfoCd: string;
  mInfoReqInfoDesc: string;
  displayValue: string;
  chatInd: boolean;
  uploadInd: boolean;
  downloadInd?: boolean;
  detailsInd?: boolean;
  callInd: boolean;
  listPhysicain: Physician[];
  formType: string;
  url?: string;
  //detailInd:boolean;
}

export class Physician {
  physicianName: string;
  fromDataRange: string;
  toDataRange: string;
}


export class MissingInfoDoc {
  docTitle: string;
  checked: boolean;
  docCode: string;
}


export class MissingItem {
  id: string;
  key: string;
  displayName: string;
  description: string;
  generic: Version;
  onshore: Version;
  leave: Version;
  parentId: string;
}

export class Version {
  title: string;
  url: string;
  uniqueFileName: string;
  description: string;
  customFileName: string;
  keyword: string;
}

export class OWCSMissingItemsResponse {
  id: string;
  name: string;
  forms: MissingItem[];
}

export class Node {
  key: string;
  value: string[];
}

export class DownloadLink {
  name: string;
  description: string;
  isDownloadable: boolean;
  downloadURL: string;
}

export class UpdateMissingInfoRequest {
  claimEventId: string;
  receivedDate: string;
  missingInfoCodeList: MissingInfoRqstCodeObj[];
}

export class MissingInfoRqstCodeObj {
  missingInfoRqstCode: string[];
}

export class MissingInfoFromAll {
  missingInfoDataFromEmployee: MissingInfo;
  missingInfoDataFromEmployer: MissingInfo;
  missingInfoDataFromDoctor: MissingInfo;
}

export class MissingInfoFromAllThaa {
  missingInfoDataFromEmployee: MissingInfo[];
  missingInfoDataFromEmployer: MissingInfo[];
  missingInfoDataFromDoctor: MissingInfo[];
}


export class EmployeeMissingDocument {
  document: MissingReqInfo;
  displayText: string;
  showByYou: boolean;
  constructor() {
    this.showByYou = false;
  }
}

export class EmployerMissingDocument {
  document: MissingReqInfo;
  displayText: string;
  showEmplDiv: boolean;
  constructor() {
    this.showEmplDiv = false;
  }
}

export class DoctorMissingDocument {
  document: MissingReqInfo;
  displayText: string;
  showDoctorDiv: boolean;
  constructor() {
    this.showDoctorDiv = false;
  }
}
