import {Directive, Input, HostListener} from '@angular/core';

/**
 * Created by AC12394 on 9/18/2017.
 */

@Directive({
  selector: '[higLabel]'
})
export class HigLabelStubDirective {
  @HostListener('(click)')
  @Input('higLabel') linkParams: any;
  setLabel: string = null;

  onClick() {
    this.setLabel = this.linkParams;
  }
}
