export const HEALTH_PROVIDER = {
    providerList: [
      {
        providerId: 249412,
        firstName: 'Barca',
        lastName: 'TEST',
        specialty: 'Geriatric Medicine',
        phoneNumber: '6153672929',
        faxNumber: '1234567890',
        emailAddress: 'myEmail_isHere@test.com'
      },
      {
        providerId: 249413,
        firstName: 'Amanda',
        lastName: 'TEST',
        phoneNumber: '6153672925',
        faxNumber: '1234567896',
        emailAddress: 'myEmail_Here@test.com',
        specialty: 'Not on file',
      },
      {
        providerId: 249415,
        firstName: 'Christian',
        lastName: 'Johnis',
        specialty: 'Geriatric Medicine',
        phoneNumber: '1234567890',
        emailAddress: 'myEmail_Here@test.com'
      },
    ],
    responseCode: '001',
    responseReason: 'Success'
  }
