/** This utility file will contain the various implementations of can activate that can be used for routing
 * These are great options for creating features and functions wherein the gating can be controled outside
 * the components realm.
 * */

import {ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot} from '@angular/router';
import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {DynamicFeatureDownService} from '../services/dynamic-feature-down.service';
import {TokenUtility} from './tokenUtility';
import {StorageManagementService} from '../services/storage-management.service';
import {HttpClient} from '@angular/common/http';
import {MessageRibbonService} from '../services/message-ribbon.service';
import {ReferenceHelperService} from '../services/reference-helper.service';
import {ResponseErrorHandlerService} from '../services/response-error-handler.service';
import {AuthStateService} from '../services/auth-state.service';
import {env} from '../../../environments/environment-loader';
import { SessionConstants } from '../constants/session.constants';
import { AppLabelConstants } from '../constants/appLabel.constants';
import { IntakeLabelConstants } from '@app/cxr/modules/intake/shared/constants/intake-label.constants';

/* This can activate is to be used where a url needs to be blocked off dynamically */
@Injectable()
export class CanActivateFeature implements CanActivate {

  constructor( private router: Router,
               private dynamicFeatureDown: DynamicFeatureDownService) {}

  canActivate(route: ActivatedRouteSnapshot,
              state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
    if (this.dynamicFeatureDown.isFeatureTurnedOff(route.data.featureset.name)) {
      this.router.navigate(['/featureDownPage']);
      return false;
    } else if (!this.dynamicFeatureDown.isFeatureNeedToSuppress(route.data.featureset)) {
      this.router.navigate(['/' + route.data.url]); /** navigation if user is not allowed*/
      return false;
    } else {
      return true;
    }
  }
}

@Injectable()
export class CanActivateLogin implements CanActivate {
  public appLandingPage_URL = window.location.href;
  public sfd_login_url = env.gbCiamCommonUtil.SfdAccountUrl;
  constructor( private sessionManagementService: StorageManagementService,
               private router: Router,
               public httpClient: HttpClient,
               private messageRibbonService: MessageRibbonService,
               private referenceHelper: ReferenceHelperService,
               private errorHandler: ResponseErrorHandlerService,
               private authStateService: AuthStateService) {}

  /* This needs to be able to not cause any sort of loops*/
  canActivate(route: ActivatedRouteSnapshot,
              state: RouterStateSnapshot): any {
    /**
     * Storing the channel id for future use incase the url changes to remove it
     */
    if (route.queryParams['channelId']) {
      this.sessionManagementService.setSessionData('channelId', route.queryParams['channelId']);
      /**
       * In this block we check for the impersonation & SSO channel Ids. If they are found we have to do a bit of cleanup on the app.
       * This helps when the user decides to close the tab without logging out
       */
      if ((route.queryParams['channelId'] === '3' || route.queryParams['channelId'] === '4') &&  route.queryParams['authToken']) {
        TokenUtility.removeMyBenefitsToken();
        this.sessionManagementService.setSessionObjectNoJSON('authToken', route.queryParams['authToken']);
      }
    }

    /* Quick check to see if the current login is invalid. Logs the User out in this event*/
    if (!TokenUtility.getExistingFrTokenState() && !env.mocking) {
      this.authStateService.logout(true);
      return false;
    }
    if (this.getExistingForgeRockToken(document.cookie, 'EESession' )) {
      const getAuthorize = this.getExistingForgeRockToken(document.cookie, 'EESession' );
      if (getAuthorize) {
        this.authStateService.logIn();
      }

      return true;
    }
  }
  setUrlParameter(url, key, value) {
    let newValueUrl;
    const valueLen = value.split('?');
    const baseUrl = url.split('?')[0];
    if (valueLen.length > 1) {
      newValueUrl = value.split('?')[0] + '&' + value.split('?')[1];
    } else {
      newValueUrl = value;
    }
    const newParam = '?' + key + '=' + newValueUrl;
    return baseUrl + newParam;
  }
  canActivateChild(route: ActivatedRouteSnapshot,
                   state: RouterStateSnapshot) {
    return true;
  }

  getExistingForgeRockToken(cookie, key) {
    let bodyFrToken; let frToken;
    if (cookie) {
      bodyFrToken = cookie.split(';').find(x => x.includes(key));
      if (bodyFrToken) {
        frToken = bodyFrToken.split('=')[1];
      }
    }
    return frToken;
  }
}

@Injectable()
export class CanActivateSession implements CanActivate {
  constructor(private authStateService: AuthStateService) {}
  canActivate(): boolean {
    if (!TokenUtility.getToken('CIAMSession')) {
      this.authStateService.logout();
      return false;
    } else {
      return true;
    }
  }
}

@Injectable()
export class CanActivateNewIntake implements CanActivate {
  constructor(private storageManagementService: StorageManagementService,
              private router: Router) {}
  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    const isHomeVisited = this.storageManagementService.getSessionData(SessionConstants.IS_HOME_VISITED)
    const toggleList = this.storageManagementService.getSessionData(SessionConstants.TOGGLE_LIST);
    let newIntake = true;
    if (toggleList && toggleList.length > 0) {
      const toggleIndex = toggleList.findIndex(toggles => toggles.toggle.toLowerCase() === IntakeLabelConstants.NEWINTAKE.toLowerCase());
      if (toggleIndex > -1) {
        newIntake = (toggleList[toggleIndex].state);
      }
    }
    if (isHomeVisited && isHomeVisited === "true") {
      if(state.url.includes(AppLabelConstants.URL_INTAKE)) {
        if (newIntake) {
          return true;
        } else {
          this.router.navigate([AppLabelConstants.URL_HOME]);
          return false;
        }
      } else {
        if (newIntake) {
          this.router.navigate([AppLabelConstants.URL_HOME]);
          return false;
        } else {
          return true;
        }
      }
    } else {
      this.router.navigate([AppLabelConstants.URL_HOME]);
      return false;
    }
  }
}
