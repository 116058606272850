
import {catchError, map} from 'rxjs/operators';
/**
 * Created by AC12256 on 11/14/2017.
 */
import {Injectable} from '@angular/core';


import {env} from '../../../environments/environment-loader';


import {ResponseErrorHandlerService} from '../services/response-error-handler.service';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {AppLabelConstants} from '../constants/appLabel.constants';

@Injectable()

export class RecentlyResolvedClaimService {


  constructor(private errorHandler: ResponseErrorHandlerService,
              private httpClient: HttpClient) {}


  getrecentlyResolved(claimEventID: string) {
      const request: string = JSON.stringify({claimEventId: claimEventID});

     return this.httpClient.post(env.gbClaims.recentlyResolvedURL, request,
      {
        headers: new HttpHeaders()
          .set(AppLabelConstants.CONTENT_TYPE_KEY, AppLabelConstants.APPLICATION_KEY)
      }).pipe(
      map(
      (response: Response) => {
        if (!this.errorHandler.isServiceResponseHasError(response)) {
          return response;
        } else {
          throw response;
        }
      }
    ), catchError((error: any) => this.errorHandler.handleError(error)), );


  }
}
