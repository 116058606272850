
import {Observable, from, of, ReplaySubject} from 'rxjs';

import {map, catchError, toArray, mergeMap} from 'rxjs/operators';
/**
 * Created by AC12256 on 1/19/2018.
 */
import {Injectable} from '@angular/core';

import {env} from '../../../environments/environment-loader';



import {ResponseErrorHandlerService} from '../services/response-error-handler.service';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {StorageManagementService} from './storage-management.service';
import {RelatedClaims} from '../models/relatedClaims.model';
import {
  AppLeaveInfo,
  CaseLeaveMapping,
  ClaimCaseMapping,
  ClaimLeaveDetails,
  ClaimsLeaveResponse,
  LeaveResponse,
  PolicyAdminLeaveResponse
} from '../models/LeaveMappings.model';
import {NotificationService} from './notifications.service';
import {AppConstants} from '../constants/app.constants';
import {SessionConstants} from '../constants/session.constants';
import {AppLabelConstants} from '../constants/appLabel.constants';

@Injectable()
export class SelfLinkingService {

  public isAppLeaveDataSetInSession: ReplaySubject<boolean> = new ReplaySubject<boolean>(1);
  public srcPartyId: string = '';
  constructor(private errorHandler: ResponseErrorHandlerService,
              private notificationService: NotificationService,
              private httpClient: HttpClient,
              private storageManagementService: StorageManagementService) {
  }


  /** UTILITY METHODS BELOW **/

  public extractCaseIdToClaimEventIdMappings(claims: RelatedClaims[]): ClaimCaseMapping[] {
    const claimCaseMappings: ClaimCaseMapping[] = [];
    if (claims && claims.length > 0) {
      claims.forEach(
        (claim: RelatedClaims) => {
          const tempClaimCaseMapping = new ClaimCaseMapping(claim.caseId, claim.claimEventId);
          claimCaseMappings.push(tempClaimCaseMapping);
        }
      );
    }

    return claimCaseMappings;
  }

  public extractUniqueCaseIds(claimCaseMappings: ClaimCaseMapping[]): string[] {
    const uniqueCaseIds: string[] = [];
    if (claimCaseMappings && claimCaseMappings.length > 0) {
      claimCaseMappings.forEach(
        (claimCaseMapping: ClaimCaseMapping) => {
          const newCaseId: string = claimCaseMapping.caseId;
          if (!this.isDuplicateCase(uniqueCaseIds, newCaseId)) {
            uniqueCaseIds.push(newCaseId);
          }
        }
      );
    }
    return uniqueCaseIds;
  }

  public isDuplicateCase(caseIds: string[], caseId: string): boolean {
    if (caseIds && caseIds.length > 0) {
      return (caseIds.findIndex((caseElemId: string) => caseElemId === caseId) !== -1);
    }
  }

  public isLeaveApplicableToCase(caseId: string): Observable<boolean> {
    return this.notificationService.policyAdminLeaveService(parseInt(caseId, 10)).pipe(
      mergeMap(
        (policyAdminLeaveResponse: PolicyAdminLeaveResponse) => {
          if (policyAdminLeaveResponse && policyAdminLeaveResponse.leaveResponseList && policyAdminLeaveResponse.leaveResponseList.length > 0) {
            const leaveResponseList: LeaveResponse[] = policyAdminLeaveResponse.leaveResponseList;
            const filteredLeaveResponseList: LeaveResponse[] = leaveResponseList.filter((leaveResponse: LeaveResponse) => leaveResponse && leaveResponse.provisionId === AppConstants.PROVISION_ID_806 && (leaveResponse.provisionOptionId === AppConstants.PROVISION_OPTION_ID_2 || leaveResponse.provisionOptionId === AppConstants.PROVISION_OPTION_ID_3));
            if (filteredLeaveResponseList && filteredLeaveResponseList.length > 0) {
              return of(true);
            }
          }
          return of(false);
        }
      ));
  }

  public createCaseLeaveMappings(caseIds: string[]): Observable<CaseLeaveMapping[]> {
    return from(caseIds).pipe(
      mergeMap(
        (caseId: string) => this.isLeaveApplicableToCase(caseId).pipe(
            mergeMap(
              (isLeaveApplicable: boolean) => {
                const newCaseLeaveMapping: CaseLeaveMapping = new CaseLeaveMapping(caseId, isLeaveApplicable);
                return of(newCaseLeaveMapping);
              }
            ))
      ),
      toArray(), );
  }

  public extractApplicableLeaveCaseIds(caseLeaveMappings: CaseLeaveMapping[]): string[] {
    let leaveApplicableCaseIds: string[] = [];
    if (caseLeaveMappings && caseLeaveMappings.length > 0) {
      const filteredCaseLeaveMappings: CaseLeaveMapping[] = caseLeaveMappings.filter((caseLeaveMapping: CaseLeaveMapping) => caseLeaveMapping.isLeaveApplicable === true)
      if (filteredCaseLeaveMappings && filteredCaseLeaveMappings.length > 0) {
        leaveApplicableCaseIds = filteredCaseLeaveMappings.map((caseLeaveMappingElem: CaseLeaveMapping) => caseLeaveMappingElem.caseId);
      }
    }
    return leaveApplicableCaseIds;
  }

  public extractLeaveEligibleClaimEventIds(claimCaseMappings: ClaimCaseMapping[], leaveApplicableCaseIds: string[]): string[] {
    const leaveEligibleClaimEventIds: string[] = [];
    if (claimCaseMappings && claimCaseMappings.length > 0 && leaveApplicableCaseIds && leaveApplicableCaseIds.length > 0) {
      leaveApplicableCaseIds.forEach((leaveApplicableCaseId: string) => {
          const filteredAndMappedLeaveEligibleClaimEventIds = claimCaseMappings.filter((claimCaseMapping: ClaimCaseMapping) => claimCaseMapping.caseId === leaveApplicableCaseId)
            .map((claimCaseMapping: ClaimCaseMapping) => claimCaseMapping.claimEventId);
          if (filteredAndMappedLeaveEligibleClaimEventIds && filteredAndMappedLeaveEligibleClaimEventIds.length > 0) {
            filteredAndMappedLeaveEligibleClaimEventIds.forEach(
              (filteredAndMappedLeaveEligibleClaimEventId: string) => {
                leaveEligibleClaimEventIds.push(filteredAndMappedLeaveEligibleClaimEventId);
              }
            )
          }
        }
      );
    }
    return leaveEligibleClaimEventIds;
  }

  public getClaimsLeaveDetails(claimEventIds: string[]): Observable<ClaimLeaveDetails[]> {
    return this.getConcurrentLeaves(claimEventIds).pipe(
      catchError(
        (err) => {
          const claimsLeaveResponse: ClaimsLeaveResponse = new ClaimsLeaveResponse();
          claimsLeaveResponse.leaveDetailsList = [];
          return of(claimsLeaveResponse);
        }
      ),
      mergeMap((claimsLeaveResponse: ClaimsLeaveResponse) => of(claimsLeaveResponse.leaveDetailsList)), );
  }

  public setupClaimsLeaveData(claims: RelatedClaims[]): Observable<AppLeaveInfo> {
    const appLeaveInfo: AppLeaveInfo = new AppLeaveInfo();
    const claimCaseMappings: ClaimCaseMapping[] = this.extractCaseIdToClaimEventIdMappings(claims);
    appLeaveInfo.claimCaseMappings = claimCaseMappings;
    const uniqueCaseIds: string[] = this.extractUniqueCaseIds(claimCaseMappings);
    return this.createCaseLeaveMappings(uniqueCaseIds).pipe(
      mergeMap((caseLeaveMappings: CaseLeaveMapping[]) => {
          const leaveApplicableCaseIds: string[] = this.extractApplicableLeaveCaseIds(caseLeaveMappings);
          const leaveEligibleClaimEventIds: string[] = this.extractLeaveEligibleClaimEventIds(claimCaseMappings, leaveApplicableCaseIds);
          appLeaveInfo.caseLeaveDetails = caseLeaveMappings;
          appLeaveInfo.leaveApplicableCaseIds = leaveApplicableCaseIds;
          appLeaveInfo.leaveEligibleClaimEventIds = leaveEligibleClaimEventIds;
            return (leaveEligibleClaimEventIds && leaveEligibleClaimEventIds.length > 0 ? this.getClaimsLeaveDetails(leaveEligibleClaimEventIds) : of(null)).pipe(
              mergeMap((claimLeaveMappings: ClaimLeaveDetails[]) => {
                  appLeaveInfo.claimLeaveDetails = claimLeaveMappings;
                  return of(appLeaveInfo);
                }
              ));
        }
      ));

  }

  /** APP LEAVE INFO PROPERTIES RETRIEVAL METHODS BELOW **/

  public getAppLeaveInfo(): AppLeaveInfo {
    const appLeaveInfo: AppLeaveInfo = this.storageManagementService.getSessionData(SessionConstants.APP_LEAVE_INFO);
    return appLeaveInfo ? appLeaveInfo : null;
  }

  public getSpecificClaimLeaveInfo(claimEventId: string): ClaimLeaveDetails {
    const appLeaveInfo: AppLeaveInfo = this.getAppLeaveInfo();
    const claimsLeaveDetails: ClaimLeaveDetails[] = appLeaveInfo?.claimLeaveDetails;
    let specificClaimLeaveDetails: ClaimLeaveDetails = null;
    const filteredClaimLeaveDetails: ClaimLeaveDetails[] = claimsLeaveDetails?.filter(
      (claimLeaveDetails: ClaimLeaveDetails) => claimLeaveDetails?.claimEventId === claimEventId);
    if (filteredClaimLeaveDetails && filteredClaimLeaveDetails.length > 0) {
      specificClaimLeaveDetails = filteredClaimLeaveDetails[0];
    }
    return specificClaimLeaveDetails;
  }

  public areAllClaimsLeaveless(): boolean {
    const appLeaveInfo: AppLeaveInfo = this.getAppLeaveInfo();
    let claimsLeaveInfo: ClaimLeaveDetails[] = appLeaveInfo ? appLeaveInfo.claimLeaveDetails : [];
    claimsLeaveInfo === null ? claimsLeaveInfo = [] : claimsLeaveInfo = claimsLeaveInfo;
    const doesAnyClaimHaveLeaveAssociated: number = claimsLeaveInfo.findIndex((claimLeaveInfo: ClaimLeaveDetails) => !!(claimLeaveInfo && claimLeaveInfo.leaveId && claimLeaveInfo.url));
    return (doesAnyClaimHaveLeaveAssociated === -1);
  }

  /** LEAVE API CALLS METHODS BELOW **/
  public getLinkType(partyRefID, partyID, linkTypeValue, actionCommentValue, profileID): Observable<any> {
    const request: string = JSON.stringify(
      {
        partyRefId: partyRefID,
        partyId: partyID,
        linkType: linkTypeValue,
        actionComment: actionCommentValue
      }
    );

    return this.httpClient.post(env.gbMudi.getLinkTypeURL, request,
      {
        headers: new HttpHeaders()
          .set(AppLabelConstants.CONTENT_TYPE_KEY, AppLabelConstants.APPLICATION_KEY)
      }).pipe(
      map(
        (response: Response) => {
          if (!this.errorHandler.isServiceResponseHasError(response)) {
            return response;
          } else {
            throw response;
          }
        }
      ), catchError((error: any) => this.errorHandler.handleError(error)), );

  }

  public getConcurrentLeaves(claimEventIDs: string[]): Observable<ClaimsLeaveResponse> {
    const request = JSON.stringify({
        claimEventIds: claimEventIDs
      }
    );
    return this.httpClient.post(env.gbClaims.getConcurrentLeavesURL, request,
      {
        headers: new HttpHeaders()
          .set(AppLabelConstants.CONTENT_TYPE_KEY, AppLabelConstants.APPLICATION_KEY)
      }).pipe(
      map(
        (response: ClaimsLeaveResponse) => {
          if (!this.errorHandler.isServiceResponseHasError(response)) {
            ////console.log('CHA', response);
            return response;
          } else {
            throw response;
          }
        }
      ), catchError((error: any) => this.errorHandler.handleError(error)), );

  }

  public getLeaveSSO(caseId) {

    return this.httpClient.get(env.gbClaims.getLeaveSSOURL + '&caseId=' + caseId,
      {
        headers: new HttpHeaders()
          .set(AppLabelConstants.CONTENT_TYPE_KEY, AppLabelConstants.APPLICATION_KEY)
      }).pipe(
      map(
        (response: Response) => {
          if (!this.errorHandler.isServiceResponseHasError(response)) {
            return response;
          } else {
            throw response;
          }
        }
      ), catchError((error: any) => this.errorHandler.handleError(error)), );

  }

  get personId() {
    return this.srcPartyId;
  }
  set personId(personId) {
    this.srcPartyId = personId;
  }
}
