import {BrowserModule} from '@angular/platform-browser';
import {CUSTOM_ELEMENTS_SCHEMA, ErrorHandler, NgModule} from '@angular/core';
import {APP_BASE_HREF, CommonModule, DatePipe, PercentPipe, TitleCasePipe} from '@angular/common'
import {AppComponent} from './app.component';
import {HeaderComponent} from './cxr/common/molecules/header/header.component';
import {HomeComponent} from './cxr/home/home.component';
import {ProductInfoComponent} from './cxr/product-info/product-info.component';
import {FooterComponent} from './cxr/common/molecules/footer/footer.component';
import {AuthStateService} from './shared/services/auth-state.service';
import {StorageManagementService} from './shared/services/storage-management.service';
import {FaqComponent} from './cxr/common/organisms/faq/faq.component';
import {ContactsComponent} from './cxr/common/organisms/contacts/contacts.component';
import {AppRoutingModule} from './app-routing.module';
import {ErrorComponent} from './cxr/error/error.component';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {CleanseAddressComponent} from './cxr/my-account/about-you/cleanse-address/cleanse-address.component';
import {ZipcodeService} from './shared/services/zipcode.service';
import {ProfileService} from './shared/services/profile.service';
import {SaveProfiledataService} from './shared/services/saveProfileData.service';
import {SaveMessageBannerComponent} from './cxr/common/organisms/save-message-banner/save-message-banner.component';
import {FooterContentService} from './shared/services/footer.service';
import {ClaimStatusComponent} from './cxr/common/organisms/claim-status/claim-status.component';
import {ModalService} from './shared/services/modal.service';
import {DepositService} from './shared/services/enrolldeposit.service';
import {ReferenceHelperService} from './shared/services/reference-helper.service';
import {MailPaymentPopupComponent} from './cxr/my-account/direct-deposit/edit-direct-deposit/mail-payment-popup/mail-payment-popup.component';
import {SystemAlertComponent} from './cxr/system-alert/system-alert.component';
import {AppInfoComponent} from './cxr/app-info/app-info.component';
import {FeedbackCiamComponent} from './cxr/feedback-ciam/feedback-ciam.component';
import {UpdateTCsAlertModalComponent} from './cxr/terms-and-conditions-alert/update-tcs-alert-modal/update-tcs-alert-modal.component';
import {SiteWideTCsComponent} from './cxr/terms-and-conditions-alert/site-wide-tcs/site-wide-tcs.component';
import {TextTCsComponent} from './cxr/terms-and-conditions-alert/text-tcs/text-tcs.component';
import {SecurityStatementComponent} from './cxr/security-statement/security-statement.component';
import {DocumentService} from './shared/services/claim-documents.service';
import {Security} from './shared/models/security.model';
import {TealiumModule} from './modules/tealium.module';
import {ResetPasswordService} from './shared/services/reset-password.service';
import {LeaveTimelineComponent} from './cxr/leave-timeline/leave-timeline.component';
import {LeaveBenefitsComponent} from './cxr/leave-benefits/leave-benefits.component';


/*Tealium Import files */
import {TealiumUtagService} from './shared/services/tealium/utag.service'
import {TealiumDataService} from './shared/services/tealium/tealium-data.service';
import {HTTP_INTERCEPTORS, HttpClientModule} from '@angular/common/http';
import {ClaimantDocumentsComponent} from './cxr/document-upload/document/document.component';
import {NavigationEnd, Router} from '@angular/router';
import {DepositDisclaimerService} from './shared/services/deposit-DisclaimerService';
import {CoBrandingComponent} from './cxr/co-branding/co-branding.component';
import {CoBrandingService} from './shared/services/co-branding.service';
import {PersonService} from './shared/services/people.service';
import {RecentlyResolvedClaimService} from './shared/services/recentlyResolvedClaims.service';
import {ClaimServiceService} from './shared/services/claim-service.service';
import {RequestExtensionComponent} from './cxr/request-extensions/request-extension/request-extension.component';
import {FaqService} from './shared/services/faqs.service';
import {RequestExtensionService} from './shared/services/request-extension.service';
import {ElementControlComponent} from './cxr/common/sub-atomic/element-control/element-control.component';
import {ErrorMessagingService} from './shared/services/error-messaging.service';
import {ElementControlFormWrapperComponent} from './cxr/common/sub-atomic/element-control-form-wrapper/element-control-form-wrapper.component';
import {SessionService} from './shared/services/tealium/session.service';
import {NotificationService} from './shared/services/notifications.service';
import {ValidatedrodownRequired} from './shared/directives/validatedrodownRequired.directive';
import {MessageRibbonService} from './shared/services/message-ribbon.service';
import {MessageRibbonContainerComponent} from './cxr/common/organisms/message-ribbon-container/message-ribbon-container.component';
import {ClaimDeniedTimelineComponent} from './cxr/claim-denied-display/claim-denied-timeline/claim-denied-timeline.component';
import {PromptDirectDepositComponent} from './cxr/my-account/direct-deposit/prompt-direct-deposit/prompt-direct-deposit.component';
import {CustomEventService} from './shared/services/tealium/custom-event.service';
import {SysDownAlertMessageService} from './shared/services/sysDownAlertMessages.service';
import {ProductService} from './shared/services/product.service';
import {ConfirmSurgeryServiceService} from './shared/services/confirm-surgery-service.service';
import {ResponseErrorHandlerService} from './shared/services/response-error-handler.service';
import {SelfLinkingComponent} from './cxr/self-linking/self-linking-flow/self-linking/self-linking.component';
import {SelfLinkingModalComponent} from './cxr/self-linking/self-linking-flow/self-linking-modal/self-linking-modal.component';
import {SuccessfulRegistrationComponent} from './cxr/self-linking/self-linking-flow/successful-registration/successful-registration.component';
import {PhaAccessPointComponent} from './cxr/pha-access-point/pha-access-point.component';
import {DirectDepositTCComponent} from './cxr/my-account/direct-deposit/enroll-deposit/direct-deposit-tc/direct-deposit-tc.component';
import {LeaveCaseComponent} from './cxr/leave-case/leave-case/leave-case.component';
import {ContentService} from './shared/services/content.service';
import {LeaveFormLibraryService} from './shared/services/leave-form-library.service';
import {phaOverviewService} from './shared/services/pha-overview.service';
import {ConfirmELGComponent} from './cxr/self-linking/dcs-eligibility/confirm-elg/confirm-elg.component';
import {SelfLinkingService} from './shared/services/self-linking.service';
import {getBaseLocation} from './shared/utils/vanity-urls.utils';
import {BlazeService} from './shared/services/blazeRule.service';
import {ServiceInterceptor} from './shared/utils/ServiceInterceptor';
import {LoginErrorComponent} from './cxr/login-error/login-error.component';
import {BlazeComponent} from './cxr/to-remove/blaze/blaze.component';
import {EmployeeBenefitsRegistrationComponent} from './cxr/employee-benefits-registration/employee-benefits-registration/employee-benefits-registration.component';
import {NotificationRegistrationComponent} from './cxr/notification-registration/notification-registration.component';
import {LogonService} from './shared/services/logon.service';
import {SessionManagementService} from './shared/services/session-management.service';
import {PaymentsHistoryService} from './shared/services/claim-payment-history.service';
import {PopupNotificationRegComponent} from './cxr/popup-notification-reg/popup-notification-reg.component';
import {TransactionErrorComponent} from './cxr/transaction-error/transaction-error.component';
import {FormLibraryWidgetComponent} from '@app/cxr/leave-forms-library-widget/leave-forms-library-widget.component';
import {ssoRegistrationService} from './shared/services/sso-registration.service';
import {OWCSCaasService} from './shared/services/owcs-caas.service';
import {ProfileSyncService} from './shared/services/profile-sync.service';
import {ContactsService} from './shared/services/contacts.service';
import {ClaimWizardService} from './shared/services/claim-wizard.service';
import {SureRouteTestingComponent} from './cxr/sureroute/sureroute-test-object.component';
import {HigLabelStubDirective} from './testing/higLabel';
import {RouterLinkStubDirective} from './testing/router-stub';
import {LineBreakFormatPipe} from './shared/pipes/line-break-format.pipe';
import {SharedModule} from './shared/shared.module';
import {env} from '../environments/environment-loader';
import {EventManagementServiceService} from './shared/services/event-management-service.service';
import {ProfileErrorComponent} from './cxr/profile-error/profile-error.component';
import {NotificationRegistrationService} from './shared/services/notification-registration.service';
import {TextMaskModule} from 'angular2-text-mask';
import {ClaimHandlerContentService} from './shared/services/claimHanlder-content.service';
import { FeatureDownComponent } from './cxr/common/organisms/feature-down/feature-down.component';
import {CanActivateFeature, CanActivateLogin, CanActivateNewIntake, CanActivateSession} from './shared/utils/CanActivateUtils';
import {DynamicFeatureDownService} from './shared/services/dynamic-feature-down.service';
import {LeaveService} from './shared/services/leave-service.service';
import {LeaveDetailsService} from './shared/services/leave-details.service';
import {AetnaContactsService} from './shared/services/aetnaContacts.service';
import {ClaimRepresentativeService} from './shared/services/claimRepresentativeDetails.service';
import {LeaveBalanceChartComponent} from './cxr/leave-balance-chart/leave-balance-chart.component';
import {DonutChartComponent} from './cxr/common/organisms/donut-chart/donut-chart.component';
import {LeaveBalanceSectionComponent} from './cxr/leave-balance-section/leave-balance-section.component';
import {StackedBarChartComponent} from './cxr/common/organisms/stacked-bar-chart/stacked-bar-chart.component';
import {MoreLettersPageComponent} from './cxr/view-letters/More-letters/more-letters-page.component';
import {LetterListService} from './shared/services/letter-list.service';
import {LetterImageCodeService} from './shared/services/letter-image-code.service';
import {MyDatePickerModule} from 'mydatepicker';
import {AetnaPaymentsService} from './shared/services/aetnaPayments.service';
import {RecentlyResolvedAetnaClaimsService} from './shared/services/recentlyResolvedAetnaClaims.service';
import {AetnaClaimActivityService} from './shared/services/aetna-claim-activity.service';
import {ScheduledCallService} from './shared/services/scheduled-call.service';
import {AetnaSubmitAppointmentService} from './shared/services/aetna-submit-appointment.service';
import {AetnaCancelAppointmentService} from './shared/services/aetna-cancel-appointment.service';
import {AetnaConfirmSurgeryServiceService} from './shared/services/aetna-confirm-surgery-service';
import {AetnaRequestExtensionService} from './shared/services/aetna-request-extension.service';
import {MyBenefitsTitleService} from './shared/services/myBenefitsTitleService.service';
import {StartNewLeaveService} from './shared/services/start-new-leave.service';
import {LeaveReasonServiceService} from './shared/services/leave-reason-service.service';
import {TaxFormWidgetComponent} from './cxr/tax-form/tax-forms-widget/tax-forms-widget.component';
import {TaxFormService} from './shared/services/tax-form.service';
import {CIIFeatureService} from './shared/services/CII-feature.service';
import {FaqCommonService} from './shared/services/faqCommon.service';
import {RequestExtensionThaaComponent} from './cxr/request-extensions/request-extension-thaa/request-extension-thaa.component';
import { ThaaConfirmationService } from './shared/services/thaa-confirmation.service';
import { ErrorPageComponent } from './cxr/error-page/error-page.component';
import { SessionTimeOutComponent } from './cxr/session-time-out/session-time-out.component';
import {MissingInfoThaaService} from './shared/services/missing-info-thaa.service';
import {AetnaApsFormsService} from './shared/services/aetna-aps-forms.service';
import {MissingInfoThaaLeaveService} from './shared/services/missing-info-thaa-leave.service';
import {RecentlyResolvedAetnaLeaveService} from './shared/services/recentlyResolvedAetnaLeave.service';
import {FuturePaymentServiceService} from './shared/services/future-payment-service.service';
import {FuturePaymentExistServiceService} from './shared/services/future-payment-exist-service.service';
import {InteractiveRequestExtensionThaaComponent} from './cxr/request-extensions/interactive-request-extension-thaa/interactive-request-extension-thaa.component';
import { InternalFaqService } from './shared/services/internal-faq.service';
import {AdaProductService} from './shared/services/ada-product.service';
import { PsvPageService } from './psv/psv-page/psv-page.service';
import { ScriptService } from './shared/services/script.service';
import { PsvService } from './shared/services/psv.service';
import { NewRaeDiagnosisFlowService } from './shared/services/new-rae-diagnosis-flow.service';
import {SpecialtyService} from '@shared/services/specialty.service';
import {HealthcareProviderFlowService } from './shared/services/healthcare-provider-flow.service'
import {StateLocationService} from '@shared/services/state-location.service';
import {ToggleService} from '@shared/services/toggle.service';
import { AppConstants } from './shared/constants/app.constants';
import { NewRaeFinalSubmitService } from './shared/services/new-rae-final-submit.service';
import { RaeGuardService } from './shared/services/rae-guard.service';
import { IntakeOWCSContentService } from './cxr/modules/intake/shared/services/intake-owcs-content.service';
import { IntakeToggleService } from './cxr/modules/intake/shared/services/intake-toggle.service';
import {IntakeLeaveReasonService} from '@app/cxr/modules/intake/shared/services/intake-leave-reason.service';
import { IntakeGuardService } from './shared/services/intake-guard.service';
import {ContactInfoService} from '@app/cxr/modules/intake/shared/services/contact-info.service';
import { IntakeResolveService } from './cxr/modules/intake/shared/resolvers/intake-resolve.service';
import { IntakeZipCodeService } from './cxr/modules/intake/shared/services/intake-zipcode.service';
import { ContactStateService } from './cxr/modules/intake/shared/services/contact-state.service';
import { IntakeWorkScheduleService } from './cxr/modules/intake/shared/services/intake-work-schedule.service';
import { HealthProviderService } from '@app/cxr/modules/intake/shared/services/intake-health-provider.service';
import { IntermediatePageLoaderComponent } from './cxr/common/organisms/intermediate-page-loader/intermediate-page-loader.component';
import {BondingIntakeService} from '@app/cxr/modules/intake/shared/services/bonding/intake-bonding.service';
import {IcdDetailsThaaService} from '@app/cxr/modules/intake/shared/services/intake-icd-details-thaa.service';
import {CptDetailsThaaService} from '@app/cxr/modules/intake/shared/services/intake-cpt-details';
import { ExpediteClaimService } from './cxr/modules/intake/shared/services/intake-expedite-claim.service';
import { IntakeAdditionalEmploymentService } from './cxr/modules/intake/shared/services/additional-employment.service';
import { PFLTransitionService } from './cxr/modules/intake/shared/services/pfl-transition.service';
import { PflTransitionResolverService } from './cxr/modules/intake/shared/resolvers/pfl-transition-resolver.service';
import { ClaimSubmitService } from './cxr/modules/intake/shared/services/intake-claim-submit.service';
import { CustomErrorComponent } from './cxr/custom-error/custom-error.component';
import { AddAbsenceExtension } from './cxr/modules/intake/shared/services/bonding/add-absence-extension.service';
import { IntakeSTDEligibiliyService } from './cxr/modules/intake/shared/services/intake-std-eligibility.service';
import { EmployeeStatusService } from '@app/shared/services/employee-status.service';
import { NewIntakeMaternityResolverService } from './cxr/modules/intake/shared/resolvers/new-intake-maternity-resolver.service';
import { ErrorLoggingService } from '@app/shared/services/error-logging.service';
import {CustomErrorService} from '@shared/services/custom-error.service';
import { MagnoliaUtilityService } from './shared/services/magnolia-utility.service';
import { FMLIStatusService } from './shared/services/fmli-status.service';
import { SpouseSharedLeaveResolverService } from './cxr/modules/intake/shared/resolvers/spouse-shared-leave-resolver.service';
import { AbsencePlanningCardComponent } from './cxr/absence-planning/absence-planning-card.component';

declare global {
  interface Navigator {
    msSaveBlob: (blob: Blob, fileName: string) => boolean
  }
}
@NgModule({
    declarations: [
        AppComponent,
        HigLabelStubDirective,
        RouterLinkStubDirective,
        SureRouteTestingComponent,
        HeaderComponent,
        FooterComponent,
        ClaimStatusComponent,
        ProductInfoComponent,
        HomeComponent,
        FaqComponent,
        ContactsComponent,
        ErrorComponent,
        CustomErrorComponent,
        CleanseAddressComponent,
        SaveMessageBannerComponent,
        MailPaymentPopupComponent,
        SystemAlertComponent,
        //SinglePaymentComponent,
        AppInfoComponent,
        FeedbackCiamComponent,
        UpdateTCsAlertModalComponent,
        SiteWideTCsComponent,
        TextTCsComponent,
        SecurityStatementComponent,
        ClaimantDocumentsComponent,
        CoBrandingComponent,
        RequestExtensionComponent,
        RequestExtensionThaaComponent,
        InteractiveRequestExtensionThaaComponent,
        //  PopupRequestExtensionComponent,
        CoBrandingComponent,
        CoBrandingComponent,
        ElementControlComponent,
        ElementControlFormWrapperComponent,
        MessageRibbonContainerComponent,
        ValidatedrodownRequired,
        ClaimDeniedTimelineComponent,
        PromptDirectDepositComponent,
        SelfLinkingComponent,
        SelfLinkingModalComponent,
        SuccessfulRegistrationComponent,
        PhaAccessPointComponent,
        DirectDepositTCComponent,
        LeaveCaseComponent,
        ConfirmELGComponent,
        LoginErrorComponent,
        BlazeComponent,
        EmployeeBenefitsRegistrationComponent,
        NotificationRegistrationComponent,
        PopupNotificationRegComponent,
        TransactionErrorComponent,
        FormLibraryWidgetComponent,
        LineBreakFormatPipe,
        ProfileErrorComponent,
        FeatureDownComponent,
        LeaveTimelineComponent,
        LeaveBenefitsComponent,
        LeaveBalanceChartComponent,
        DonutChartComponent,
        DonutChartComponent,
        LeaveBalanceSectionComponent,
        StackedBarChartComponent,
        MoreLettersPageComponent,
        FeatureDownComponent,
        MoreLettersPageComponent,
        TaxFormWidgetComponent,
        ErrorPageComponent,
        SessionTimeOutComponent,
        IntermediatePageLoaderComponent,
        AbsencePlanningCardComponent
    ],
    imports: [
        BrowserModule,
        TealiumModule,
        FormsModule,
        ReactiveFormsModule,
        AppRoutingModule,
        HttpClientModule,
        CommonModule,
        SharedModule,
        TealiumModule,
        TextMaskModule,
        MyDatePickerModule
    ],
    providers: [
        CanActivateFeature,
        CanActivateLogin,
        CanActivateSession,
        CanActivateNewIntake,
        DynamicFeatureDownService,
        EventManagementServiceService,
        LetterImageCodeService,
        ContactsService,
        AdaProductService,
        AetnaContactsService,
        AetnaPaymentsService,
        NewIntakeMaternityResolverService,
        AetnaClaimActivityService,
        ClaimRepresentativeService,
        ClaimHandlerContentService,
        AuthStateService,
        OWCSCaasService,
        BlazeService,
        StorageManagementService,
        NotificationRegistrationService,
        FaqService,
        ProfileService,
        ZipcodeService,
        SaveProfiledataService,
        FooterContentService,
        ModalService,
        DepositService,
        ReferenceHelperService,
        ClaimServiceService,
        DepositDisclaimerService,
        Security,
        ConfirmSurgeryServiceService,
        CoBrandingService,
        ProductService,
        PersonService,
        RecentlyResolvedClaimService,
        RecentlyResolvedAetnaClaimsService,
        RecentlyResolvedAetnaLeaveService,
        DocumentService,
        NotificationService,
        ErrorMessagingService,
        RequestExtensionService,
        LeaveFormLibraryService,
        ProfileSyncService,
        SelfLinkingService,
        ScheduledCallService,
        AetnaCancelAppointmentService,
        AetnaConfirmSurgeryServiceService,
        NewRaeDiagnosisFlowService,
        HealthcareProviderFlowService,
        AetnaRequestExtensionService,
        NewRaeFinalSubmitService,
        {
            provide: HTTP_INTERCEPTORS,
            useClass: ServiceInterceptor,
            multi: true,
        },
        TealiumUtagService,
        TealiumDataService,
        SessionService,
        CustomEventService,
        MessageRibbonService,
        SysDownAlertMessageService,
        ResponseErrorHandlerService,
        ContentService,
        AetnaApsFormsService,
        ssoRegistrationService,
        phaOverviewService,
        {
            provide: APP_BASE_HREF,
            useFactory: getBaseLocation
        },
        LogonService,
        TaxFormService,
        PaymentsHistoryService,
        SessionManagementService,
        ClaimWizardService,
        ResetPasswordService,
        LeaveService,
        LeaveDetailsService,
        TitleCasePipe,
        PercentPipe,
        LetterListService,
        AetnaSubmitAppointmentService,
        ResetPasswordService,
        MyBenefitsTitleService,
        StartNewLeaveService,
        LeaveReasonServiceService,
        DatePipe,
        CIIFeatureService,
        FaqCommonService,
        ThaaConfirmationService,
        MissingInfoThaaService,
        MissingInfoThaaLeaveService,
        FuturePaymentServiceService,
        FuturePaymentExistServiceService,
        InternalFaqService,
        PsvPageService,
        ScriptService,
        PsvService,
        SpecialtyService,
        StateLocationService,
        ToggleService,
        RaeGuardService,
        IntakeOWCSContentService,
        IntakeToggleService,
        IntakeSTDEligibiliyService,
        EmployeeStatusService,
        IntakeLeaveReasonService,
        IntakeGuardService,
        IntakeResolveService,
        IntakeZipCodeService,
        IcdDetailsThaaService,
        CptDetailsThaaService,
        ContactInfoService,
        ContactStateService,
        IntakeWorkScheduleService,
        HealthProviderService,
        BondingIntakeService,
        ExpediteClaimService,
        IntakeAdditionalEmploymentService,
        PFLTransitionService,
        PflTransitionResolverService,
        SpouseSharedLeaveResolverService,
        ClaimSubmitService,
        AddAbsenceExtension,
        {provide: ErrorHandler, useClass: ErrorLoggingService},
        CustomErrorService,
        MagnoliaUtilityService,
        FMLIStatusService
    ],

    bootstrap: [AppComponent],
    exports: [
        HigLabelStubDirective
    ],
    schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class AppModule {
  constructor(private router: Router, private tealium: TealiumUtagService) {
    router.events.subscribe((val) => {
      if (val instanceof NavigationEnd && env.envName !== AppConstants.LOCAL && env.envName !== AppConstants.LOCAL_QA && env.proxyState === false) {
          this.tealium.view();
      }
    });
  }
  }
