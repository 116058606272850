/**
 * Created by AC12256 on 11/16/2017.
 */

import {mockInfoResolved} from './mock-mInfoResolved';

export const resolvedInfo: any = {
 errorList: null,
 userId: '123456',
 sessionIdentifier: '1572ca48-63fd-4975-8622-72fdb34e3254',
 rcecentlyResolvedInfo: mockInfoResolved

 };
/*{
  "errorList": null,
  "userId": "123456",
  "sessionIdentifier": "1572ca48-63fd-4975-8622-72fdb34e3254",
  "rcecentlyResolvedInfo":    [
  {
    "mInfoCode": "01",
    "mInfoDesc": "Employee",
    "mInfoReqInfo": [         {
      "recentlyInfoReqInfoDesc": "HIPAA Fully Insured Provider Specific Authorization",
      "recentlyInfoReqInfoCd": "31",
      "displayValue": "HIPAA Fully Insured Provider Specific",
      "requestDate": "02/06/2018",
      "detailsIndicator": false,
      "listPhysicain":             [
        {
          "physicianName": null,
          "fromDataRange": null,
          "toDataRange": null
        },
        {
          "physicianName": null,
          "fromDataRange": null,
          "toDataRange": null
        }
      ]
    }]
  },
  {
    "mInfoCode": "02",
    "mInfoDesc": "Employer",
    "mInfoReqInfo": [         {
      "recentlyInfoReqInfoDesc": "Job Description",
      "recentlyInfoReqInfoCd": "16",
      "displayValue": "Job Description",
      "requestDate": "02/06/2018",
      "detailsIndicator": true,
      "listPhysicain": [            {
        "physicianName": null,
        "fromDataRange": null,
        "toDataRange": null
      }]
    }]
  }
]
}*/
