import {Component, Input, OnInit} from '@angular/core';
import {ElementControlFormWrapperComponent} from 'app/cxr/common/sub-atomic/element-control-form-wrapper/element-control-form-wrapper.component';
import {FormControl} from '@angular/forms';

@Component({
  selector: 'hours-min-dropdown',
  templateUrl: './hours-min-dropdown.component.html',
  styleUrls: ['./hours-min-dropdown.component.scss']
})
export class HoursMinDropdownComponent extends ElementControlFormWrapperComponent implements OnInit {
  @Input() dropdownList: object[];
  @Input() errorCondition: boolean = false;
  @Input() inputId: string;
  @Input() disabledDropdown = false;
  @Input() selectedOption: string;
  @Input() readOnlyCondition: boolean;
  @Input() ariaLabel: string;
  @Input() defaultValue: string;
  @Input() controls: FormControl = new FormControl();
  @Input() disableDefaultvalue?: boolean;
  @Input() dropdownTitle: string;
}
