export const ABSENCE_PLANNING_CONSTANT = {
    title: "MyBenefits Guide",
    contentPart1: "We'll help you get the most out of your leave benefits when you need time away from work",
    contentPart2: "Keep in mind: This won't start a claim. And we won't share your personal details with your employer until after you file a claim.",
    pregnancyImg: "../../../assets/images/icons/GB-pregnant-human.svg",
    buttonLabel: "Plan My Leave",
};

export const ABSENCE_PLANNING_WITH_CLAIM_CONSTANT = {
    title: "MyBenefits Guide",
    contentPart1: "Understand your time off and how you are going to continued to get paid.",
    contentPart2: "Let us help you spend more time either taking care of yourself or bonding with your newborn child.",
    pregnancyImg: "../../../assets/images/icons/GB-pregnant-human.svg",
    buttonLabel: "Plan My Leave",
};
