import {AfterContentInit, AfterViewInit, Component, Input, OnInit, } from '@angular/core';
import {FormControl, FormGroup} from '@angular/forms';
import {ElementControlFormWrapperComponent} from '../../sub-atomic/element-control-form-wrapper/element-control-form-wrapper.component';
import {AppLabelConstants} from '../../../../shared/constants/appLabel.constants';

@Component({
  selector: 'secondary-form-text-input',
  templateUrl: 'secondary-form-text-input.component.html',
  styleUrls: ['secondary-form-text-input.component.scss']
})
export class SecondaryFormTextInputComponent extends ElementControlFormWrapperComponent implements OnInit, AfterViewInit, AfterContentInit {


  @Input() group: FormGroup;
  @Input() controls: FormControl = new FormControl();
  @Input() groupControl: any;
  @Input() inputId: string;
  @Input() inputValue: string;
  @Input() labelName: string;
  @Input() inputPlaceholder: string;
  @Input() textType: string;
  @Input() max: number;
  @Input() maxlength: string;
  @Input() minlength: string;
  @Input() readOnlyCondition: boolean;
  @Input() inputSize?: string;
  @Input() preRenderingLoad = false;
  public appLabelConstants = AppLabelConstants



  ngOnInit() {
    this.groupControl = this.group.controls[this.inputId];
  }
  ngAfterViewInit() {
  }

  ngAfterContentInit() {

  }
}
