

export const MOCK_CII_FEATURE = {
  mobileSecurityID: null,
  allowViewPayments: 0,
  allowSendEmail: 1,
  allowAlerts: 0,
  allowEditRTW: 1,
  allowRTW: 1,
  allowLetters: 1,
  allowUpload: 0,
  lastLogin: null,
  allowViewElectronicDocuments: 0,
  allowAddTimeToExistingClaim: 1,
  WcpHideViewBalances: 0,
  OverPaymentByCreditCardAccess: 1,
  requestDirectDeposit: 0,
  allowSuppressDirectDepositPayStub: 1,
  manageDirectDeposit: 0,
  requestPrepaidDebitCard: 0,
  managePrepaidDebitCard: 0,
  displayCancelledLeaves: 0,
  displayInactiveLeaves: 0,
  allowMemberTimeline: 1,
  allowUpdateProfile: 0,
  allowBenefitCalendar: 1,
  allowEnterAbsence: 1,
  allowMaternity: 0,
  patentPendingText: 'Patent Pending',
  allowEOI: 0,
  allowCOFM: 0,
  allowBondingAdoption: 0,
  viewSupervisorDashboard: 0,
  noPrintTaxForms: 1,
  allowPerformSearch: 0,
  viewCommsEmails: 0,
  allowScheduleACall: 1,
  allowLifeInsurance: 0,
  allowADA: 0,
  displayADAclaims: 0,
  responseCode: '001',
  responseReason: 'success'
};
