import {Component, Input, OnInit} from '@angular/core';
import {Router} from '@angular/router';

@Component({
  selector: 'heading-label',
  templateUrl: 'heading-label.component.html',
  styleUrls: ['heading-label.component.scss']
})
export class HeadingLabelComponent implements OnInit {

  @Input() pageTitle: string;
  @Input() pageSubTitle: string;
  @Input() loading = false;

  constructor(private router: Router) { }

  ngOnInit() {
  }


}
