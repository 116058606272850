
import {catchError, map} from 'rxjs/operators';
/**
 * Created by AC12212 on 2/22/2018.
 */
import {Injectable} from '@angular/core';
import {env} from '../../../environments/environment-loader';
import {Observable} from 'rxjs';
import {ResponseErrorHandlerService} from './response-error-handler.service';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {profileSync} from '../models/profileSync.model';
import {AppLabelConstants} from '../constants/appLabel.constants';


@Injectable()
export class ProfileSyncService {
  constructor(private errorHandler: ResponseErrorHandlerService,
              private httpClient: HttpClient) {
  }


  profileSync(phoneNum, emailID, firstNameValue, lastNameValue, insuredID): Observable<profileSync> {
      const request = JSON.stringify({
        phoneNumber: phoneNum,
        email: emailID,
        firstName: firstNameValue,
        lastName: lastNameValue,
        insuredId: insuredID
      });
      return this.httpClient.put<profileSync>(env.gbProfileSync.profileSyncURL, request,
        {
          headers: new HttpHeaders()
            .set(AppLabelConstants.CONTENT_TYPE_KEY, AppLabelConstants.APPLICATION_KEY)
        }).pipe(
        map(
          (response: profileSync) => {
            if (!this.errorHandler.isServiceResponseHasError(response)) {
              return response;
            } else {
              throw response;
            }
          }
        ), catchError((error: any) => this.errorHandler.globalErrorHandler(error)), );

    // TODO: currently we are hardcoding ProfileId For Now

  }
}
