
import {ReplaySubject, Observable, of} from 'rxjs';


import {catchError, map} from 'rxjs/operators';
/**
 * Created by AC12394 on 5/30/2018.
 */
import {Injectable} from '@angular/core';



import {User} from '../models/user.model';
import {ProductService} from './product.service';
import {CoveragePlan, PolicyAdminCoverageResponse} from '../models/coveragePlan.model';
import {DepositService} from './enrolldeposit.service';
import {
  NotificationDetails,
  UpdateNotificationLogRequest,
  UpdateNotificationLogResponse
} from '../models/notificationDetails.model';
import {Provision, ProvisionResponse} from '../models/Provision.model';
import {NotificationService} from './notifications.service';
import {StorageManagementService} from './storage-management.service';
import {env} from '../../../environments/environment-loader';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {ResponseErrorHandlerService} from './response-error-handler.service';

import {SessionConstants} from '../constants/session.constants';
import {AppLabelConstants} from '../constants/appLabel.constants';


@Injectable()
export class NotificationRegistrationService {

  private fiCoveragePlansExist: boolean = false;
  private asoCoveragePlansExist: boolean = false;
  private notificationDetails: NotificationDetails;
  public isNotificationsDataSetInSession: ReplaySubject<boolean> = new ReplaySubject<boolean>(1);
  public isNotificationInterceptorSkipped: boolean = false;

  constructor(private productService: ProductService,
              private depositService: DepositService,
              private errorHandler: ResponseErrorHandlerService,
              private httpClient: HttpClient,
              private notificationService: NotificationService,
              private storageManagementService: StorageManagementService) {
    this.initializeNotificationFlag();
  }

  public getIsNotificationInterceptorSkipped(): boolean {
    return this.isNotificationInterceptorSkipped;
  }

  public setIsNotificationInterceptorSkipped(isNotificationInterceptorSkipped: boolean) {
    this.isNotificationInterceptorSkipped = isNotificationInterceptorSkipped;
  }

  isThaaNotification(caseId: string, personId: string): Observable<any> {
    if (caseId && personId) {
      let reqUrl = env.thaaWKABPortalService.getNotificationPreference + '&caseId=' + caseId;
      if (personId) {
        reqUrl += '&personId=' + personId;
      }
      return this.httpClient.get(reqUrl,
        {
          headers: new HttpHeaders()
            .set(AppLabelConstants.CONTENT_TYPE_KEY, AppLabelConstants.APPLICATION_KEY)
        }).pipe(
        map(
          (response: Response) => {
            if (!this.errorHandler.isServiceResponseHasError(response)) {
              return response;
            } else {
              throw response;
            }
          }
        ), catchError((error: any) => this.errorHandler.handleError(error)), );

    }

  }
  checkNotificationExempt(insuredId: string, eligibilityId: string, caseId: number): Observable<NotificationDetails> {

    const user: User = new User();
    user.insuredId = insuredId;

    return this.notificationService.isNotification(caseId).pipe(
      catchError(
        (err) => {
          this.notificationDetails = new NotificationDetails(false, false, false);
          return of(this.notificationDetails);
        }
      ));
    /* .mergeMap(
        (val: any) => {
          if (val && val.notificationExempt === false && (insuredId || eligibilityId)) {
            this.notificationDetails = new NotificationDetails(true, true, false);
            return this.productService.getCoverageService(caseId, true)
              .catch(
                (err) => {
                  this.notificationDetails = new NotificationDetails(true, true, false);
                  return of(this.notificationDetails);
                }
              )
              .mergeMap(
                (valElem: any) => {
                  if (valElem && valElem.coveragePlansList && valElem.coveragePlansList.length > 0) {
                    const coveragePlansResponse: PolicyAdminCoverageResponse = valElem;

                    const fiCoveragePlans: CoveragePlan[] = coveragePlansResponse.coveragePlansList.filter(
                      (coveragePlan: CoveragePlan) => coveragePlan.fundingMethodCode === 'FI');

                    this.fiCoveragePlansExist = (fiCoveragePlans && fiCoveragePlans.length > 0);

                    const asoCoveragePlans: CoveragePlan[] = coveragePlansResponse.coveragePlansList.filter(
                      (coveragePlan: CoveragePlan) => coveragePlan.fundingMethodCode === 'ASO');

                    this.asoCoveragePlansExist = (asoCoveragePlans && asoCoveragePlans.length > 0);

                    if (this.fiCoveragePlansExist) {
                      this.notificationDetails = new NotificationDetails(true, true, true);
                      return of(this.notificationDetails);
                    }

                    if (this.asoCoveragePlansExist) {
                      return this.depositService.getPolicyAdminProvisions(caseId, 315)
                        .catch(
                          (err) => {
                            this.notificationDetails = new NotificationDetails(true, true, false);
                            return of(this.notificationDetails);
                          }
                        )
                        .mergeMap(
                          (newValElem: any) => {
                            if (newValElem && newValElem.provisionResponseList) {
                              console.log(newValElem);
                              const policyAdminProvisionsResponse: ProvisionResponse = newValElem;

                              const provisionsList: Provision[] = policyAdminProvisionsResponse.provisionResponseList;
                              let asoProvisionsWithOptionIdOne: Provision[] = [];
                              if (provisionsList.length > 0) {
                                asoProvisionsWithOptionIdOne = provisionsList.filter((provision: Provision) => (provision.provisionOptionId === '1' && provision.fundingMethodCode === 'ASO'));
                                if (asoProvisionsWithOptionIdOne.length > 0) {
                                  this.notificationDetails = new NotificationDetails(true, true, true);
                                  return of(this.notificationDetails);
                                }
                              }
                              this.notificationDetails = new NotificationDetails(true, true, false);
                              return of(this.notificationDetails);
                            } else {
                              return of(newValElem);
                            }
                          }
                        )
                    }
                  } else {
                    return of(valElem);
                  }
                }
              )
          } else {
            this.notificationDetails = new NotificationDetails(false, false, false);
            return of(this.notificationDetails);
          }
        }
      )*/
  }

  getNotificationsRelatedData(insuredId: string, eligibilityId: string, caseId: number): Observable<NotificationDetails> {
    const isNotificationInterceptorPageVisited = this.storageManagementService.getSessionData(SessionConstants.IS_NOTIFICATION_DATA_SET_IN_SESSION);
    if (isNotificationInterceptorPageVisited) {
      return of(this.storageManagementService.getNotificationsData());
    } else {
      return this.checkNotificationExempt(insuredId, eligibilityId, caseId);
    }
  }

  updateNotificationLog(partyRefId: string, noteTnCVersnId: string, noteTnCAccptFlag: string): Observable<UpdateNotificationLogResponse> {

    const updateNotificationLogRequestObj: UpdateNotificationLogRequest = new UpdateNotificationLogRequest(partyRefId, noteTnCVersnId, noteTnCAccptFlag);
    const updateNotificationLogRequest: string = JSON.stringify(
      updateNotificationLogRequestObj
    );

    return this.httpClient.post(env.gbMudi.updateNotificationLog, updateNotificationLogRequest,
      {
        headers: new HttpHeaders()
          .set(AppLabelConstants.CONTENT_TYPE_KEY, AppLabelConstants.APPLICATION_KEY)
      }).pipe(
      map(
        (response: UpdateNotificationLogResponse) => {
          if (!this.errorHandler.isServiceResponseHasError(response)) {
            return response;
          } else {
            throw response;
          }
        }
      ), catchError((error: any) => this.errorHandler.handleErrorForSubmit(error)), );

  }

  initializeNotificationFlag() {
    if (this.storageManagementService.getSessionData(SessionConstants.IS_NOTIFICATION_DATA_SET_IN_SESSION)) {
      this.isNotificationsDataSetInSession.next(true);
    }
  }
}

