import {AfterViewInit, Component, OnInit, ViewChild} from '@angular/core';
import {Router} from '@angular/router';
import {ModalService} from '../../../shared/services/modal.service';
import {AbstractControl, FormBuilder, FormControl, FormGroup} from '@angular/forms';
import {RequestExtensionService} from '../../../shared/services/request-extension.service';
import {PersonService} from '../../../shared/services/people.service';
import {ContentService} from '../../../shared/services/content.service';
import {CustomEventService} from '../../../shared/services/tealium/custom-event.service';
import {ErrorMessagingService} from '../../../shared/services/error-messaging.service';
import {StorageManagementService} from '../../../shared/services/storage-management.service';
import {LeaveDynatraceAction} from '../../../shared/utils/leaveDynatraceAction';
import {SecondaryDateDropdownComponent} from '../../common/molecules/secondary-date-dropdown/secondary-date-dropdown.component';
import {ResponseErrorHandlerService} from '../../../shared/services/response-error-handler.service';
import {AppLabelConstants} from '../../../shared/constants/appLabel.constants';
import {SessionConstants} from '../../../shared/constants/session.constants';
import {AppConstants} from '../../../shared/constants/app.constants';
import {ErrorConstants} from '../../../shared/constants/error.constants';


function faxNumberValidator(c: AbstractControl): {[key: string]: boolean} {

  if ( c.value && c.dirty && c.value.indexOf('_') > -1) {
    return {validateFaxMinLength: true};
  }
  return null;
}

function getNameValidator(c: AbstractControl): {[key: string]: boolean} {

  const namePattern = /^[ A-Za-z0-9_@$*?{%[},'\]|!./#\\&-]*$/;

  if (c.value && c.dirty && !c.value.match(namePattern)) {
    return {NameValidator: true};
  }
  return null;
}


@Component({
  selector: 'app-request-extension',
  templateUrl: 'request-extension.component.html',
  styleUrls: ['request-extension.component.scss']
})
export class RequestExtensionComponent extends LeaveDynatraceAction implements OnInit, AfterViewInit {
  public doctorDetails: any;
  public welcomeMessage = AppLabelConstants.REQUEST_AN_EXT;
  public userForm: FormGroup;
  public doctorForm: FormGroup;
  public error = false;
   errormsg = false;
   timeFrame;
   requestExtensionDateValue;
  public selectMonth;
  public exactdate;
  public estimateDate;
  lengthExtension = [];
  year = [];
  day = [];
   weekDayMonth;
  public noOfDays;
  public selectedMon;
  public updatedEstimatedDate = false;
   extendThrough = false;
  public extendThroughHead = false;
  public extendThroughTitle = false;
  public extendThroughLabel = false;
   lengthOfExtension = false;
  public extendThroughHeadDropdown = false;
   extendThroughSubmit = true;
   extendThroughSubmitVal = true;
   showSubmitBtn = false;
   selectedLWD;
   hideNextShowSubmitBtn = true;
   claimEventID;
   approveThroughDate: string;
   pageId: string;
   owcsContent;
  public para1: string;
  public exact_Radial: string;
  public message_1: string;
  public estimateRadial: string;
   dateLabel: string;
   lengthLabel: string;
   fromLabel: string;
   docMsg: string;
   incrementValue = 0;
   handleErrorLable = false;
  public displayDoctorInfoExact = false;
  public displayDoctorInfoEstimate = false;
  @ViewChild(SecondaryDateDropdownComponent, { static: false }) DateLabelValue: SecondaryDateDropdownComponent;
   readDayValue = true;
   getnextBtnCount: number;
  public nextButton = true;
  public displayWhenValid = false;
  public hideSubmitBtn = false;
   hideNextBtn = false;
   nextBtnDisable = false;
  public hideSubmitButn = false;
  public appLabelConstants = AppLabelConstants;
  public errorConstants = ErrorConstants;
  public previousUrl;

  constructor(private router: Router,
              private _formBuilder: FormBuilder,
              private modalService: ModalService,
              private requestExtensionService: RequestExtensionService,
              private personService: PersonService,
              private _responseErrorHandlerService: ResponseErrorHandlerService,
              private contentService: ContentService,
              private customEventService: CustomEventService,
              private errorList: ErrorMessagingService,
              private storageManagementService: StorageManagementService,
  ) {
    super(storageManagementService);
  }


  ngOnInit() {
    sessionStorage.removeItem(SessionConstants.GET_FIRST_VAL);
    const claimEventId = JSON.parse(sessionStorage.getItem(SessionConstants.CLAIM_EVENT_ID));
    if (claimEventId) {
      this.claimEventID = claimEventId.claimEventId;
    }

    this.reMoveSessionObject();
    this.lengthExtension = [AppConstants.DAYS, AppConstants.WEEKS];
    //OWCS Integration
    this.pageId = this.contentService.getPageId(AppLabelConstants.REQUEST_AN_EXT);
    if (this.pageId) {
      this.contentService.getOWCSResponse(this.pageId, AppLabelConstants.REQUEST_AN_EXT_UN).subscribe(
        OWCSResponse => {
          if (OWCSResponse) {
            this.owcsContent = OWCSResponse;
            this.message_1 = this.owcsContent.data.RAE_03_Body.value;
            this.para1 = this.owcsContent.data.RAE_04_Body.value;
            this.exact_Radial = this.owcsContent.data.RAE_05_Label.value;
            this.estimateRadial = this.owcsContent.data.RAE_06_Label.value;
            this.dateLabel = this.owcsContent.data.RAE_07_Body.value;
            this.lengthLabel = this.owcsContent.data.RAE_11_Body.value;
            this.fromLabel = this.owcsContent.data.RAE_14_Body.value;
            this.docMsg = this.owcsContent.data.RAE_27_Body.value;

          }
        }, (error) => {
          console.error(error);
        });
    }

    this.approveThroughDate = JSON.parse(sessionStorage.getItem(SessionConstants.AUTHORIZED_END_DATE)).authorizedEndDate;
    this.setuserForm();
  }

  ngAfterViewInit() {
    super.ngAfterViewInit();
  }

  tagErrors(elementId, labelName, errMsg) {
    if (errMsg) {
      if (Object.keys(errMsg) && this.errorList.myErrors['' + Object.keys(errMsg)]) {
        const errorMessage = this.errorList.myErrors['' + Object.keys(errMsg)];

        if (errorMessage === this.errorList.myErrors.required) {
          this.customEventService.formFieldValidationErrors(['' + elementId], ['' + labelName + errorMessage]);
        } else {
          this.customEventService.formFieldValidationErrors(['' + elementId], ['' + errorMessage]);

        }
      } else {
        this.customEventService.formFieldValidationErrors(['' + elementId], ['' + errMsg]);
      }
    }
  }

  setuserForm() {
    this.userForm = new FormGroup({
      days: new FormControl('')
    });
    this.doctorForm = new FormGroup({
      docForm: new FormControl('', [getNameValidator]),
      fForm: new FormControl('', [faxNumberValidator])
    });


  }

  getEstimate() {
    this.setuserForm();
    this.getnextBtnCount = 0;
    sessionStorage.removeItem(SessionConstants.ESTIMATE);
    const radiovalue = sessionStorage.getItem(SessionConstants.RADIO_VALUE);
    const extensionlength = sessionStorage.getItem(SessionConstants.EXTENSION_LENGTH);
    if (extensionlength) {

    } else {
      if (radiovalue) {
        sessionStorage.removeItem(SessionConstants.RADIO_VALUE);

        this.error = false;
        this.day = [];
        this.selectedLWD = AppConstants.FORM_VALUE_DAY;
        this.extendThrough = false;
        this.extendThroughHead = true;
        this.lengthOfExtension = true;
        this.extendThroughLabel = false;
        this.extendThroughHeadDropdown = false;
        this.extendThroughSubmit = true;
        this.year = [];
        this.reMoveSessionObject();
        this.selectedMon = null;
        this.errormsg = false;
        this.handleErrorLable = false;
        this.nextButton = false;
        this.displayDoctorInfoExact = false;
        this.displayDoctorInfoEstimate = false;
      }
    }
  }

  getExactDate() {

    this.getnextBtnCount = 0;
    const radiovalue = sessionStorage.getItem(SessionConstants.RADIO_VALUE);
    sessionStorage.removeItem(SessionConstants.EXTENSION_LENGTH);
    const estimate = sessionStorage.getItem(SessionConstants.ESTIMATE);
    if (estimate) {

    } else {
      if (radiovalue) {
        sessionStorage.removeItem(SessionConstants.RADIO_VALUE);
        this.error = false;
        this.updatedEstimatedDate = false;
        this.hideNextShowSubmitBtn = true;
        this.day = [];
        this.selectedLWD = AppConstants.FORM_VALUE_DAY;
        this.extendThrough = true;
        this.extendThroughHead = false;
        this.extendThroughTitle = true;
        this.lengthOfExtension = false;
        this.extendThroughSubmit = false;
        this.year = [];
        this.reMoveSessionObject();
        this.selectedMon = null;
        this.extendThroughSubmitVal = true;
        this.errormsg = false;
        this.setuserForm();
        this.handleErrorLable = false;
        this.nextButton = false;
        this.hideSubmitBtn = false;
        this.displayWhenValid = false;
        this.displayDoctorInfoExact = false;
        this.displayDoctorInfoEstimate = false;
        this.showSubmitBtn = false;

      }
    }
  }

  navToextensionlength() {

    sessionStorage.setItem(SessionConstants.ESTIMATE, SessionConstants.ESTIMATE);
    this.extendThroughLabel = true;
    this.extendThrough = true;
    this.nextButton = false;
    this.hideSubmitBtn = false;
    this.hideSubmitButn = false;
    this.hideNextBtn = true;
    this.showSubmitBtn = false;
    this.displayDoctorInfoExact = true;
    this.displayDoctorInfoEstimate = false;
    this.isValid();
  }

  isValid() {
    if (this.DateLabelValue && this.DateLabelValue.dateFormGroupName.valid) {

      this.displayWhenValid = true;
      this.extendThrough = false;
      this.nextBtnDisable = false;
      this.hideSubmitButn = true;
    }

  }
  extensionLength() {
    sessionStorage.setItem(SessionConstants.EXTENSION_LENGTH, SessionConstants.EXTENSION_LENGTH);
    this.extendThroughHeadDropdown = true;
    this.lengthOfExtension = false;
    this.showSubmitBtn = false;
    this.extendThroughSubmit = true;
    this.displayDoctorInfoExact = false;
    this.displayDoctorInfoEstimate = true;
    this.nextButton = false;
  }

  navToExtend() {
    this.router.navigate([AppLabelConstants.SUCCESS_REQUEST_EXTENSION_URL]);
  }



  /*MethodName : getDateFromComponent
   * Purpose : This method is used to get date value from child component anfd call method from datevalue component.
   * Modified By :AC12323
   * Creted Date : 3/6/2018
   * */

  getDateFromComponent() {
    const getFirstValue = sessionStorage.getItem(SessionConstants.GET_FIRST_VAL);
    let date;
    if (getFirstValue ) {
      this.DateLabelValue.getDate();
    }
    if (this.requestExtensionDateValue) {
      date = this.requestExtensionDateValue.month + '/' + this.requestExtensionDateValue.day + '/' + this.requestExtensionDateValue.year
    }
    if (getFirstValue && (this.requestExtensionDateValue
        && this.requestExtensionDateValue.month
        && this.requestExtensionDateValue.year
        && this.requestExtensionDateValue.day === AppConstants.FORM_VALUE_DAY)
    ) {
      this.requestExtensionDateValue = {};
      this.extendThroughSubmitVal = true;
      this.readDayValue = true;
      this.errormsg = false;
      this.handleErrorLable = true;
      this.tagErrors(AppLabelConstants.EXTENSION_DATE_TEXT, '', ErrorConstants.ENTER_VALID_DATE)
    } else if (getFirstValue && (new Date(date) <= new Date(this.approveThroughDate))) {
      this.handleErrorLable = true;
      this.errormsg = true;
      this.tagErrors(AppLabelConstants.EXTENSION_DATE_TEXT, '', ErrorConstants.APPROVED_THROUGH_DATE)
    } else if (this.requestExtensionDateValue
      && this.requestExtensionDateValue.day !== AppConstants.FORM_VALUE_DAY) {
      this.handleErrorLable = false;
      this.extendThroughSubmitVal = false;
      this.readDayValue = false;
      this.errormsg = false;
      //this.extendThroughSubmitVal = false;
    }

    this.incrementValue++;
    // this.errormsg = false;

  }


  /*MethodName : extendThroughSubmitbtn
   * Purpose : This method is used to check the slected date is greather than approve through date or not
   *           and if date is greather than then update value in data base.
   * Modified By :AC12323
   * Creted Date : 3/6/2018
   * */

  extendThroughSubmitbtn() {
    this.doctorForm.value.docForm = this.doctorForm.value.docForm.replace(/\\r/g, '');
    const formData = {
      doctorname: this.doctorForm.value.docForm.replace(/\\n/g, ''),
      faxNo: this.doctorForm.value.fForm
    };
    this.doctorDetails = formData;
    const date = this.requestExtensionDateValue.month + '/' + this.requestExtensionDateValue.day + '/' + this.requestExtensionDateValue.year;
    const approvethrough = this.approveThroughDate;
    const extendThrough = new Date(approvethrough);
    const extendthroughCompareDate = new Date(date);
    if (extendThrough.getTime() <= extendthroughCompareDate.getTime()) {
      this.updatedEstimatedDate = false;
      this.hideNextShowSubmitBtn = true;
      this.callRequestExtensionService(date, this.doctorDetails);
      sessionStorage.setItem(SessionConstants.UPDATE_DATE, date);
      sessionStorage.setItem(SessionConstants.EXTENSION_DATE, SessionConstants.EXTENSION_DATE_S);
    } else {
      this.error = true;
      this.errormsg = true;
      this.handleErrorLable = true;
    }
  }


  /****************
   * Methodname:notallowe this method is used  to avoid alphabets special  characters and symboles in input field.
   * @param event
   */

  navTolengthExtension() {
    if (this.userForm.value.days) {
      if (this.selectMonth === AppConstants.WEEKS) {
        this.weekDayMonth = this.userForm.value.days * 7;
      } else if (this.selectMonth === AppConstants.MONTHS) {
        this.weekDayMonth = this.userForm.value.days * this.noOfDays;
      } else {
        this.weekDayMonth = parseInt(this.userForm.value.days, 10);
      }
      const estimatedate = this.approveThroughDate;

      const date = new Date(estimatedate);
      const estimetedate = date.setDate(date.getDate() + this.weekDayMonth);
      const EstimeteTimestamp = new Date(estimetedate).toUTCString();
      const DateMonthYear = new Date(EstimeteTimestamp).getMonth() + 1 + '/' + new Date(EstimeteTimestamp).getDate() + '/' + new Date(EstimeteTimestamp).getFullYear();

      const approvethroughdate = sessionStorage.getItem(SessionConstants.APPROVED_DATE);
      const ApprovedThrough = new Date(approvethroughdate);
      const CompareDate = new Date(DateMonthYear);


      sessionStorage.setItem(SessionConstants.UPDATE_DATE, DateMonthYear);
      this.updatedEstimatedDate = true;
      this.timeFrame = DateMonthYear;
      this.hideNextShowSubmitBtn = false;
      this.showSubmitBtn = true;
      this.getnextBtnCount = 1;
    }
  }

  updateestimate() {
    this.doctorForm.value.docForm = this.doctorForm.value.docForm.replace(/\\r/g, '');
    const formData = {
      doctorname: this.doctorForm.value.docForm.replace(/\\n/g, ''),
      faxNo: this.doctorForm.value.fForm
    };
    this.doctorDetails = formData;


    this.callRequestExtensionService(this.timeFrame , this.doctorDetails);
    sessionStorage.setItem(SessionConstants.EXTENSION_DATE , SessionConstants.ESTIMATED_EXTENSION_DATE);
  }

  clickSave() {
    this.previousUrl = this.storageManagementService.getSessionData(SessionConstants.PREVIOUS_URL);
    this.modalService.openModal(AppLabelConstants.CANCEL_MODAL);

  }

  exit() {
    this.router.navigate([AppConstants.URL_CLAIM_DETAILS]);
  }

  /*MethodName : callRequestExtensionService
   * Purpose : This method is used to call service and update the approve through date in data base and navigate to success page.
   * Modified By :AC12323
   * Creted Date : 3/6/2018
   * */

  callRequestExtensionService(date, doctorDetails) {
    const extensionRequestDate = JSON.parse(this.storageManagementService.retrieveSessionObject(SessionConstants.CUR_CLAIM)).curClaim.extensionRequestDate;

        if (date && extensionRequestDate && extensionRequestDate === date) {
          this._responseErrorHandlerService.getRequestExtensionError();
        } else {
          if (date) {
        this.requestExtensionService.getUpdatedExtesiondate(doctorDetails, date, this.claimEventID, this.approveThroughDate).subscribe(
          UpdatedExtesiondate => {
            if (UpdatedExtesiondate) {
              const userClaims = this.storageManagementService.getAppData(SessionConstants.USER_CLAIMS);
              let claimFound = false;
              //code to update the approveThrough date in app JSON
              if (userClaims && userClaims.claimOverview) {
                if (userClaims.claimOverview.nonRelatedClaims && userClaims.claimOverview.nonRelatedClaims.length > 0) {
                  const nonRelClaims = userClaims.claimOverview.nonRelatedClaims;
                  nonRelClaims.filter(function(claim, i) {
                    const eventId = JSON.parse(sessionStorage.getItem(SessionConstants.CLAIM_EVENT_ID)).claimEventId;
                    if (claim.claimEventId === eventId) {
                      claimFound = true;
                    }
                  });
                }
                if (!claimFound && userClaims.claimOverview.relatedClaims && userClaims.claimOverview.relatedClaims.length > 0) {
                  const relClaims = userClaims.claimOverview.relatedClaims;
                  relClaims.filter(function(claim, i) {
                    const eventId = JSON.parse(sessionStorage.getItem(SessionConstants.CLAIM_EVENT_ID)).claimEventId;
                    if (claim.claimEventId === eventId) {
                      claimFound = true;
                    }
                  });
                }
                if (claimFound) {
                  this.storageManagementService.setAppData(SessionConstants.USER_CLAIMS , userClaims);
                }
              }
              this.router.navigate([AppLabelConstants.SUCCESS_REQUEST_EXTENSION_URL]);
              this.storageManagementService.setSessionData(SessionConstants.REQUEST_EXTENSION_DATE, date);
            }
          },
          (error) => {
            console.log('Error' + error);

          });
      }
    }
  }

  reMoveSessionObject() {
    sessionStorage.removeItem(SessionConstants.RADIO_VALUE);
    sessionStorage.removeItem(SessionConstants.ESTIMATE);
    sessionStorage.removeItem(SessionConstants.EXTENSION_LENGTH);
  }


  changeExtensionDate() {
    if (this.getnextBtnCount === 1) {
      if (this.selectMonth === AppConstants.WEEKS) {
        this.weekDayMonth = this.userForm.value.days * 7;
      } else if (this.selectMonth === AppConstants.MONTHS) {
        this.weekDayMonth = this.userForm.value.days * this.noOfDays;
      } else {
        this.weekDayMonth = parseInt(this.userForm.value.days, 10);
      }
      const estimatedate = this.approveThroughDate;
      const date = new Date(estimatedate);
      const estimetedate = date.setDate(date.getDate() + this.weekDayMonth);
      const EstimeteTimestamp = new Date(estimetedate).toUTCString();
      const DateMonthYear = new Date(EstimeteTimestamp).getMonth() + 1 + '/' + new Date(EstimeteTimestamp).getDate() + '/' + new Date(EstimeteTimestamp).getFullYear();
      sessionStorage.setItem(SessionConstants.UPDATE_DATE, DateMonthYear);
      this.updatedEstimatedDate = true;
      this.timeFrame = DateMonthYear;
    }
  }
}

