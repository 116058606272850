import {Component, Input, OnInit} from '@angular/core';
import {FormControl} from '@angular/forms';
import {ElementControlFormWrapperComponent} from '../../sub-atomic/element-control-form-wrapper/element-control-form-wrapper.component';
import {AppLabelConstants} from '../../../../shared/constants/appLabel.constants';

@Component({
  selector: 'primary-form-dropdown',
  templateUrl: 'primary-form-dropdown.component.html',
  styleUrls: ['primary-form-dropdown.component.scss']
})
export class PrimaryFormDropdownComponent extends ElementControlFormWrapperComponent implements OnInit {

  @Input() labelName: string;
  @Input() inputPlaceholder: string;
  @Input() textType: string;
  @Input() inputId: string;
  @Input() maxlength: string;
  @Input() minlength: string;
  @Input() dropdownList: string[];
  @Input() dropdownOpen: boolean;
  @Input() disabledDropdown = false;
  @Input() controls: FormControl = new FormControl();
  @Input() selectedOption: string;
  @Input() readOnlyCondition: boolean;
  @Input() dropdownIndex: number;
  @Input() selectOneDisplay: boolean = true;
  public appLabelConstants = AppLabelConstants;
  ngOnInit() {}

  hasChanged( e ) {
    return;
  }
}
