/*
* This component is the container of all modals
* Used to hold the content of a modal and display
* it in the styles designed.
*
*/

import {AfterViewInit, Component, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {ModalService} from '../../../../../shared/services/modal.service';

@Component({
  selector: 'secondary-sliding-modal',
  templateUrl: './secondary-sliding-modal.component.html',
  styleUrls: ['./secondary-sliding-modal.component.scss']
})
export class SecondarySlidingModalComponent implements OnInit, AfterViewInit, OnDestroy {

  @Output() active: string;
  @Input() modalName: string;
  @Input() modalTitle: string;
  @Input() hideCloseButton: string;
  @Input() secondaryModelId: string;
  @Input() intakeFlow: boolean = false;

  constructor(private modalService: ModalService) {
    //console.log('in constructor of sliding modal');
  }

  /* Subscribing the modal to the service capabilities
  * */
  ngAfterViewInit(): void {
    //console.log('After view init of modal service');
  }
  ngOnInit(): void {
    //console.log('on init of modal service');
    this.modalService.modalClosed$.subscribe(
      modalName => {
        if (this.modalName === modalName) {
          // console.log('Modal being closed= ' + modalName);
          this.setModalState(false);
        }
      });
    this.modalService.modalOpened$.subscribe(
      modalName => {
        //todo @ModalComponent - Launching Modal - This was removed temporarily - May need a fix to bring it back in. Seems to be a load timing issue?
        if (this.modalName === modalName) {
          // console.log('Modal being openned= ' + modalName);
          this.setModalState(true);
        }
      });
  }

  ngOnDestroy(): void {
    //console.log('On destroy of the modal');
    this.modalService.clear();
    this.toggleLockBackground(false);
  }

  /* Setting the state based on service action
  * Used in the styles for animation/display */
  setModalState(state: boolean) {
    this.active = state ? 'active' : '';
    this.toggleLockBackground(state);
  }

  /* Closing Modal on shader click */
  closeModalByShader() {
    // this.modalService.closeModal(this.modalService.getActiveModal());
  }

  closeModal() {
    this.modalService.closeModal(this.modalService.getActiveModal());
  }

  toggleLockBackground(lockScrolling: boolean) {
    if (lockScrolling === true) {
      document.body.className = 'no-scroll';
    } else {
      document.body.className = '';
    }
  }
}

