import { Injectable } from '@angular/core';
import { ResponseErrorHandlerService } from '@shared/services/response-error-handler.service';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { env } from '@env/environment-loader';
import { AppLabelConstants } from '@shared/constants/appLabel.constants';
import { Observable, of, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { StorageManagementService } from '@app/shared/services/storage-management.service';
import { IntakeSessionConstants } from '../constants/intakeSession.constants';
import { ISTDEligibility } from '../models/intake-content.model';
import { SessionConstants } from '@app/shared/constants/session.constants';


@Injectable()
export class IntakeSTDEligibiliyService {

  constructor(private errorHandler: ResponseErrorHandlerService,
    private httpClient: HttpClient, private storeManagementService: StorageManagementService) {
  }

  public stdEligibilityFunction(personId: string,FDADate: string): Observable<ISTDEligibility> {

      return this.httpClient.get(env.thaaWKABPortalService.intakeSTDEligibility + '&personId=' + personId + '&FDA=' + FDADate,
        {
          headers: new HttpHeaders()
            .set(AppLabelConstants.CONTENT_TYPE_KEY, AppLabelConstants.APPLICATION_KEY)
        }).pipe(
          map(
            (response: ISTDEligibility) => {
              if (!this.errorHandler.isServiceResponseHasError(response)) {
                this.storeManagementService.setIntakeSessionData(SessionConstants.STD_ELIGIBILITY, response.isEligible);
                return response;
              } else {
                throw response;
              }
            }
          ), catchError((error: any) => this.handleError(error)));


  }
  handleError(error: Response) {
    return throwError(error);
  }
}
