import {Component, Input, OnInit} from '@angular/core';
import {Router} from '@angular/router';
import {ModalService} from '../../../shared/services/modal.service';
import {AppLabelConstants} from '../../../shared/constants/appLabel.constants';
import {AppConstants} from '../../../shared/constants/app.constants';
@Component({
  selector: 'app-disability-cancel-popup-model',
  templateUrl: './disability-cancel-popup-model.component.html',
  styleUrls: ['./disability-cancel-popup-model.component.scss']
})
export class DisabilityCancelPopupModelComponent implements OnInit {
  @Input() routLink: string;
  public appLabelConstants = AppLabelConstants;
  public appConstant = AppConstants;

  constructor(private router: Router,
              private modalService: ModalService) {
  }

  ngOnInit() {

  }

  backToView() {
    const navrout = (this.routLink ? this.routLink : AppConstants.ROUTE_TO_HOME);
    this.router.navigate([navrout]);
    sessionStorage.removeItem('newDisabilityDate');
    /* this.router.navigate([AppLabelConstants.HOME_TEXT]);*/
  }

  backToEdit() {
    this.modalService.closeActiveModal();
  }

}
