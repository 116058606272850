import { DatePipe } from '@angular/common';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { AppLeaveConstants } from '@app/cxr/leave-details-flow/leave-details.constants';
import { INTAKE_ROUTES } from '@app/cxr/modules/intake/shared/constants/Intake-routing.constants';
import { ResponseErrorHandlerService } from '@app/shared/services/response-error-handler.service';
import { env } from '@env/environment-loader';
import { AppLabelConstants } from '@shared/constants/appLabel.constants';
import { CustomErrorService } from '@shared/services/custom-error.service';
import { Observable } from 'rxjs';
import { throwError as observableThrowError } from 'rxjs/internal/observable/throwError';
import { catchError, map, take } from 'rxjs/operators';
import {
  IAddAbsenceExtensionSubmitRequest,
  IAddAbsenceExtensionSubmitResponseModel
} from '../../models/intake-content.model';

@Injectable()
export class AddAbsenceExtension {
  appConst = AppLeaveConstants;

  constructor( private errorHandler: ResponseErrorHandlerService,
               private httpClient: HttpClient,
               private router: Router,
               private customErrorService: CustomErrorService,
               private datePipe: DatePipe
  ) { }

  /*
  * logic to identify the start and end dates to be passed to the add days api and return the final string
  * @param fdaDate: First day of absence
  * @param rtwDate: Return to work date (exact or estimated)
  * @param maxLateReportDate: Maximum allowable date for late reporting
  * @param estimateRTOReason: Selected radio option value for Estimate RTW question
  * @returns the string: '01/01/2024' or '01/01/2024 through 01/15/2024'
  * */
  lateReportDateCalculation(fdaDate: Date, rtwDate: Date, maxLateReportDate: Date, estimateRTOReason: string): string | null {
    const curDate = new Date(new Date().toLocaleDateString());
    const transformedFDA = this.datePipe.transform(fdaDate, 'MM/dd/yyyy');
    const transformedRTWDate = this.datePipe.transform(rtwDate, 'MM/dd/yyyy');
    const transformedMaxLateReportDate = this.datePipe.transform(maxLateReportDate, 'MM/dd/yyyy');

    switch(true) {
      case (fdaDate > curDate) || (fdaDate > maxLateReportDate):
        return null;

      case (estimateRTOReason === this.appConst.UNKNOWN) && (fdaDate <= curDate):
        return transformedFDA;

      case (estimateRTOReason === this.appConst.AS_MUCH_TIME_AS_POSSIBLE) && (fdaDate <= curDate):
        return (transformedFDA === transformedMaxLateReportDate) ? transformedFDA : `${transformedFDA} through ${transformedMaxLateReportDate}`;

      case (rtwDate <= maxLateReportDate):
        return (transformedFDA === transformedRTWDate) ? transformedFDA : `${transformedFDA} through ${transformedRTWDate}`;

      case (transformedFDA === transformedMaxLateReportDate):
        return transformedFDA;

      default:
        return `${transformedFDA} through ${transformedMaxLateReportDate}`;
    }
  }
  logMessage( personId: string, logMessage?: string){
    this.customErrorService.logError(personId, logMessage, AppLabelConstants.LOG_LEVEL_INFO)
      .pipe(take(1))
      .subscribe();
  }

  /*
  * Submit extension request
  * navigate to confirmation on success
  * navigate to custom error page on failure
  * */
  submitAbsenceExtensionRequest(addAbsenceExtensionSubmitRequest: IAddAbsenceExtensionSubmitRequest, personId: string, logMessage?: string): void {
    let hasError = false;
    this.addAbsenceExtension(addAbsenceExtensionSubmitRequest)
      .pipe(catchError(error => {
        hasError = true;
        const customErrorMessage = this.customErrorService.createLoggingErrorMessage(error);
        return this.customErrorService.logError(personId, customErrorMessage, AppLabelConstants.LOG_LEVEL_ERROR);
      }))
      .subscribe({
        next: data => hasError = !(data && data['responseCode'] === '001'),
        complete: () => {
          if (hasError) {
            this.router.navigate([AppLabelConstants.URL_CUSTOMERROR], this.customErrorService.getCustomErrorPageData(true));
          } else {
            if(logMessage){
              this.logMessage(personId, logMessage);
              this.router.navigate([INTAKE_ROUTES.CONFIRMATION_PAGE]);
            } else {
              this.router.navigate([INTAKE_ROUTES.CONFIRMATION_PAGE]);
            }


          }
        }
      }
    );
  }

  addAbsenceExtension(requestData: IAddAbsenceExtensionSubmitRequest): Observable<any> {
    const request = JSON.stringify(requestData);
    return this.httpClient.post(env.thaaWKABPortalService.addAbsenceExtension, request,
      {
        headers: new HttpHeaders()
          .set(AppLabelConstants.CONTENT_TYPE_KEY, AppLabelConstants.APPLICATION_KEY)
      }).pipe(
      map(
        (response: IAddAbsenceExtensionSubmitResponseModel) => {
          if (!this.errorHandler.isServiceResponseHasError(response)) {
            return response;
          } else {
            throw response;
          }
        }
      ), catchError((error: any) =>
         this.errorHandler.handleError(error) ), );
  }

  private handleError(error: Response) {
    return observableThrowError(error);
  }
}
