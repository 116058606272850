import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'leaveReasonCapitalized'
})
export class LeaveReasonCapitalizedPipe implements PipeTransform {

  transform(leaveReason: string): string {
    let reason = '';
    for (let i = 0; i < leaveReason.length; i++) {
      if (i === 0 || leaveReason.charAt(i - 1) === ' ' && (leaveReason.charAt(i + 1) !== ' ' && i !== 5)) {
        reason += leaveReason.charAt(i).toUpperCase();
      } else {
        reason += leaveReason.charAt(i);
      }
    }
    return reason;
  }

}
