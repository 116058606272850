
import {catchError, map} from 'rxjs/operators';
/**
 * Created by FA81445 on 7/31/2017.
 */
import {Injectable} from '@angular/core';
import {Product} from '../models/product.model';

import {Observable, BehaviorSubject} from 'rxjs';

import {env} from '../../../environments/environment-loader';
import {ResponseErrorHandlerService} from './response-error-handler.service';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {StorageManagementService} from './storage-management.service';
import {AppLabelConstants} from '../constants/appLabel.constants';


export const PRODUCTS: Product[] = [
  {
    id: 1,
    prdKey: 'Prd_1',
    name: 'Maternity', //Maternity withiout Leave
    description: '',
    disclosure : '',
    benefitsOfferBy : '',
    icon: 'maternity',
    location: '/productInfo',
    covType: 'none',
    covCode: 'Leave',
    isVoluntary : false,
    prd_key: 'CO_66'

  },
  {
    id: 2,
    prdKey: 'Prd_2',
    name: 'Planned Surgery',
    description: '',
    disclosure : '',
    benefitsOfferBy : '',
    icon: 'surgery',
    location: '/productInfo',
    covType: 'none',
    covCode: 'MWH',
    isVoluntary : false,
    prd_key: 'CO_32'
  },
  {
    id: 3,
    prdKey: 'Prd_3',
    name: 'Short Term Disability',
    description: '',
    disclosure : '',
    benefitsOfferBy : '',
    icon: 'STD',
    location: '/productInfo',
    covType: 'NST',
    covCode: 'STD',
    isVoluntary : false,
    prd_key: 'CO_34'

  },
  {
    id: 4,
    prdKey: 'Prd_4',
    name: 'Leave Of Absence',
    description: '',
    disclosure : '',
    benefitsOfferBy : '',
    icon: 'Leave',
    location: '/productInfo',
    covType: 'STAT',
    covCode: 'PFL',
    isVoluntary : false,
    prd_key: 'CO_36'
  },
  {
    id: 5,
    prdKey: 'Prd_5',
    name: 'Paid Family and Medical Leave',
    description:  '',
    disclosure : '',
    benefitsOfferBy : '',
    icon: 'CA_PFML_updated',
    location: '/productInfo',
    covType: 'STAT',
    covCode: 'PFL',
    isVoluntary : false,
    prd_key: 'CO_38'
  },
  {
    id: 6,
    prdKey: 'Prd_6',
    name: 'Long Term Disability',
    description:  '',
    disclosure : '',
    benefitsOfferBy : '',
    icon: 'LTD',
    location: '/productInfo',
    covType: 'ABIL',
    covCode: 'LTD',
    isVoluntary : false,
    prd_key: 'CO_40'
  },
  {
    id: 7,
    prdKey: 'Prd_7',
    name: 'Accident',
    description:  '',
    disclosure : '',
    benefitsOfferBy : '',
    icon: 'accident',
    location: '/productInfo',
    covType: 'VOL',
    covCode: 'ACC',
    isVoluntary : true,
    prd_key: 'CO_44'
  },
  {
    id: 8,
    prdKey: 'Prd_8',
    name: 'Critical Illness',
    description:  '',
    disclosure : '',
    benefitsOfferBy : '',
    icon: 'criticalillness',
    location: '/productInfo',
    covType: 'VOL',
    covCode: 'CI',
    isVoluntary : true,
    prd_key: 'CO_42'
  },
  {
    id: 9,
    prdKey: 'Prd_9',
    name: 'Hospital Indemnity',
    description: '',
    disclosure : '',
    benefitsOfferBy : '',
    icon: 'hospitalindemnity',
    location: '/productInfo',
    covType: 'VOL',
    covCode: 'HI',
    isVoluntary : true,
    prd_key: 'CO_46'
  },
  {
    id: 10,
    prdKey: 'Prd_10',
    name: 'Maternity', //Maternity with Leave
    description: '',
    disclosure : '',
    benefitsOfferBy : '',
    icon: 'maternity',
    location: '/productInfo',
    covType: 'none',
    covCode: 'Leave',
    isVoluntary : true,
    prd_key: 'CO_30'
  },
  {
    id: 11,
    prdKey: 'Prd_11',
    name: 'ADA Accommodation',
    description: '',
    disclosure : '',
    benefitsOfferBy : '',
    icon: 'accommodation-icon-blue',
    location: '/productInfo',
    covType: 'none',
    covCode: 'none',
    isVoluntary : false,
    prd_key: 'CO_ADA'
  }
];

export const SuccessorProducts = {response: [
  {
    ruleId: 'RID_106_c',
    uiFieldId: 'CO_40',
    displayInd: 'true'
  },
  {
    ruleId: 'RID_114_b',
    uiFieldId: 'CO_30',
    displayInd: 'true'
  }
]};


export const PostLoginProducts = {claimOverviewResponseList: [
  {
    ruleId: 'RID_099',
    uiFieldId: 'CO_66',
    displayInd: 'true'
  },
  {
    ruleId: 'RID_095',
    uiFieldId: 'CO_40',

    displayInd: 'true'
  },
  {
    ruleId: 'RID_099',
    uiFieldId: 'CO_30',
    displayInd: 'true'
  },
  {
    ruleId: 'RID_103',
    uiFieldId: 'CO_46',
    displayInd: 'true'
  },
  {
    ruleId: 'RID_104',
    uiFieldId: 'CO_42',
    displayInd: 'true'
  },
  {
    ruleId: 'RID_ADA_PRD_POST_AUTH',
    uiFieldId: 'CO_ADA_PRD_PG',
    displayInd: 'true'
  }
]};

@Injectable()
export class ProductService {
  productLabelFromPrdInfo = new BehaviorSubject('');
  productLabelPostLogin = new BehaviorSubject('');
  productDisclosurePostLogin = new BehaviorSubject('');
  //Set the default value to true to load product list comp
  isProductExist = new BehaviorSubject(true);
  public userProducts: Product[] = [];
  public ProductArr: Product[] = [];
  public loginStateParameter: boolean;
  public successorProduct: Product[] = [];
  public overallProduct: Product[] = [];

  myVar = 0;
  constructor(

              private errorHandler: ResponseErrorHandlerService,
              private storageService: StorageManagementService,
              private httpClient: HttpClient) {}

  //Future state: update filter so it works with cov type and cov code
  //todo these are using mock items. swap to real stuff
  getPostLogInProduct(): Product[] {
    //using mock to show real data?
    return PRODUCTS;
  }

  getPreLogInProduct(): Product[] {
    //using mock to show real data?
   return  PRODUCTS;
  }

  getProduct(partyID, partyRefID): Observable<any> {
   /* if (env.mocking) {
      const userData = this.storageService.getUserData();
      //let partyRefId;
      if (userData && userData.partyRefId) {
        partyRefId = userData.partyRefId
      }
      console.log('In getProduct Method Mock' + partyRefId);
      return of((MudiCoverageMockContent.coverageResponseList
          .filter((coverage) => coverage.partyRefId === partyRefId))
      ).map(data => {
          return{ 'coverageResponseList': data
          }}
      );
    } else {*/
      if (partyRefID && partyID) {
        //console.log("I am in co branding");
        const request: string = JSON.stringify({
          partyRefId: partyRefID,
          partyId: partyID
        });


        return this.httpClient.post(env.gbMudi.getCoverages, request,
          {
            headers: new HttpHeaders()
              .set(AppLabelConstants.CONTENT_TYPE_KEY, AppLabelConstants.APPLICATION_KEY)
          }).pipe(
          map(
          (response: Response) => {
            if (!this.errorHandler.isServiceResponseHasError(response)) {
              //console.log("From Service:: Requested data available");
              return response;
            } else {
              // console.log("From Service:: Service returned error with 200");
              throw response;
            }
          }
        ), catchError((error: any) =>
            //console.log("Inside Catch Handler");
             this.errorHandler.handleError(error) ), );

      }
    /*}*/
  }


  getCoverageService(caseId, isInActiveCoverage?): Observable<any> {

      if (caseId) {
        let reqUrl = env.gbPolicy.getPolicyAdminCoveragePlanURLFirst + '&caseId=' + caseId;
        if (isInActiveCoverage) {
          reqUrl += '&isInActiveCoverage=' + isInActiveCoverage;
        }
        return this.httpClient.get(reqUrl,
          {
            headers: new HttpHeaders()
              .set(AppLabelConstants.CONTENT_TYPE_KEY, AppLabelConstants.APPLICATION_KEY)
          }).pipe(
          map(
          (response: Response) => {
            if (!this.errorHandler.isServiceResponseHasError(response)) {
              return response;
            } else {
              throw response;
            }
          }
        ), catchError((error: any) => this.errorHandler.handleError(error)), );

      }

  }

 /* getLeaveService(caseIdVal): Observable<any> {
    if (caseIdVal) {

      return this.httpClient.get(env.gbPolicy.getPolicyAdminLeaveServiceURLFirst + '&caseId=' + caseIdVal,
        {
          headers: new HttpHeaders()
            .set('Content-Type', 'application/json')
        })
        .map((response: Response) => {
          if (!this.errorHandler.isServiceResponseHasError(response)) {
            return response;
          } else {
            throw response;
          }
        }
      ).catch((error: any) => {
          return this.errorHandler.handleError(error); });
    }
  }*/

  getProductsById(id: number): Product {
    //Uses mock data??
    this.loginStateParameter = JSON.parse(this.storageService.retrieveSessionObject('appData')).appData.loginStatus;
    if (this.loginStateParameter === true && this.storageService.getSessionData('overAllProducts')) {
      this.ProductArr = [];
      // US156881 - Find the product by ID from the newly created overallProducts.
      // Refer PR 7749
      this.ProductArr = this.storageService.getSessionData('overAllProducts');
    } else {
      this.ProductArr = PRODUCTS;
    }
    const filterProductList = this.ProductArr.filter(function(obj: Product) {
      if (obj.id === id) {
        return obj;
      }
    });
    //console.log("Filter size: " + filterProductList[0].name);
    if (filterProductList.length > 0) {
      return filterProductList[0];
    }

    return null;
  }
  /***********************
   * Date : 01/30/18
   * Author : DB
   * Purpose : US13434: Start A claim Buttons
   */
  getCXRUrl() {
    return env.cxrBase;
  }

  getApplicableProductsForUser(): Observable<any> {
    //env.mocking= true;
      const appData = this.storageService.retrieveSessionObject('appData');
      return this.httpClient.post(env.gbCxrRules.prdRules_URL_Claim_Overview,
        appData, {
          headers: new HttpHeaders()
            .set(AppLabelConstants.CONTENT_TYPE_KEY, AppLabelConstants.APPLICATION_KEY)
        }).pipe(
        map((blazeResponse) => {
          if (blazeResponse) {
            return blazeResponse;
          } else {
            throw blazeResponse;
          }
        }),
        catchError((error: any) => this.errorHandler.handleError(error)), );

  }

  getSuccessorProductList(): Observable<any> {

      const appData = this.storageService.retrieveSessionObject('appData');
      return this.httpClient.post(env.gbCxrRules.prdRules_URL,
        appData,  {
          headers: new HttpHeaders()
            .set(AppLabelConstants.CONTENT_TYPE_KEY, AppLabelConstants.APPLICATION_KEY)
        }).pipe(
        map( (blazeResponse) => {
          if (blazeResponse) {
            return blazeResponse;
          } else {
            throw blazeResponse;
          }
        }),
        catchError((error: any) => this.errorHandler.handleError(error)), );


  }


  getAdaOptions(caseID: number): Observable<any> {

    return this.httpClient
      .get(env.gbPolicy.getPolicyAdminOptionsURLFirst + caseID.toString() + env.gbPolicy.getPolicyAdminOptionsURLLSecond + AppLabelConstants.ADA_PROVISION_ID.toString(),
        {
          headers: new HttpHeaders()
            .set(AppLabelConstants.CONTENT_TYPE_KEY, AppLabelConstants.APPLICATION_KEY)
        }).pipe(
      map(
        (response: Response) => {
          if (!this.errorHandler.isServiceResponseHasError(response)) {
            return response;
          } else {
            throw response;
          }
        }
      ), catchError((error: any) => this.errorHandler.handleError(error)), );

}
}
