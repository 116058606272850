import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {FormBuilder} from '@angular/forms';
import {AppLabelConstants} from '@shared/constants/appLabel.constants';
import {SessionConstants} from '@shared/constants/session.constants';
import {AppConstants} from '@shared/constants/app.constants';

@Component({
  selector: 'app-secondary-date-dropdown',
  templateUrl: 'secondary-date-dropdown.component.html',
  styleUrls: ['secondary-date-dropdown.component.scss']
})
export class SecondaryDateDropdownComponent implements OnInit {
  @Input() dateLabelErrMsg;
  @Input() dateLabelId;
  @Input() showMessage;
  @Input() seconderyDateDropwonName;
  @Input() dropDowndateLabel;
  @Input() extensionYearCount: number;
  @Input() getServiceDate;
  @Input() newDisabilityDateValue: string;
  @Output() countChange: EventEmitter<object> = new EventEmitter<object>();
  year = [];
  day = [];
  monthNames = [];
  public yearvalue;
  public mon;
  public noOfDays;
  public higLabelColor = false;
  public submitbtn = true;
  public newdayvalue;
  public defaultMonth;
  public selectedItem;
  public currentyearloop;
  public selectedDay;
  public showDateErrorMessage = true;
  //private lastDayWorkSection;
  public dateFormGroupName;
  public appLabelConstants = AppLabelConstants;
  private disaplyBtn;
  private displayError;
  private dateValue: {
    month: string;
    day: string;
    year: string
  };

  constructor(private _formBuilder: FormBuilder) {}

  ngOnInit() {
    this.dateFormGroupName = this.seconderyDateDropwonName;
    //this.check = this.surgeryMonthId;
    this.yearvalue = new Date().getFullYear(); // to read the corrent year
    this.selectedDay = 'Day'; // to set the default day in drop down list
    if (window.innerWidth <= 414) {
      this.defaultMonth = true;
      this.monthNames = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun',
        'Jul', 'Aug', 'Sept', 'Oct', 'Nov', 'Dec'];
    } else {
      this.monthNames = ['January', 'February', 'March', 'April', 'May', 'June',
        'July', 'August', 'September', 'October', 'November', 'December'];
    }

    this.currentyearloop = new Date().getFullYear() + 1; // this method is used to read the year ahed then current year.
    if (this.extensionYearCount) {
      this.extensionYearCount = 2
    } else {
      this.extensionYearCount = 4
    }
    for (let i = this.currentyearloop; i > this.currentyearloop - this.extensionYearCount; i--) {
      this.year.push(i);
    }
    this.selectedItem = this.year[1];

    // Form builder need user can change the form group name based on requirment
    this.dateFormGroupName = this._formBuilder.group({
      getmonthNameval: [AppLabelConstants.MONTH_TEXT],
      getdayVal: [AppLabelConstants.DAY_TEXT],
      getyearVal: [this.yearvalue]
    });

    /*if (typeof this.dateFormGroupName.reset === 'function') {
      this.dateFormGroupName.reset();
    }*/
  }

  /*MethodName : onChange()
   * Purpose : This method is used to read the Day value
   * Created By : AC12323
   * Created Date : 3/6/210
   * Hand Over to :
   * */
  onChange(newMonth) {
    this.selectedDay = AppLabelConstants.DAY_TEXT;
    this.mon = newMonth;
    if (this.mon !== AppLabelConstants.MONTH_TEXT) {
      this.noOfDays = new Date(this.yearvalue, this.mon, 0).getDate();
      this.day = [];
      for (let i = 0; i < this.noOfDays; i++) {
        this.day.push(i + 1);
      }
    } else {
      this.day = [];
      this.selectedDay = AppLabelConstants.DAY_TEXT;
    }
    if ((this.dateFormGroupName.value.getdayVal !== AppLabelConstants.DAY_TEXT || this.dateFormGroupName.value.getdayVal === AppLabelConstants.DAY_TEXT) && this.displayError === 2) {
      this.showDateErrorMessage = false;
    } else {
      this.showDateErrorMessage = true;
    }
    if (this.mon && this.displayError === 2) {
      this.getDate();
      this.getUpdateDate();
    }
    //  sessionStorage.removeItem('getFirstValue');
  }

  /*MethodName : onChangeYear()
   * Purpose : This method is used to read the year value
   * Created By : AC12323
   * Created Date : 3/6/210
   * Hand Over to :
   * */
  onChangeYear() {
    this.yearvalue = this.selectedItem;
    this.day = [];
    this.noOfDays = new Date(this.yearvalue, this.mon, 0).getDate();
    this.day = [];
    for (let i = 0; i < this.noOfDays; i++) {
      this.day.push(i + 1);
    }
    this.selectedDay = AppLabelConstants.DAY_TEXT;
    if ((this.dateFormGroupName.value.getdayVal !== AppLabelConstants.DAY_TEXT || this.dateFormGroupName.value.getdayVal === AppLabelConstants.DAY_TEXT) && this.displayError === 2) {
      this.showDateErrorMessage = false;
    } else {
      this.showDateErrorMessage = true;
    }
    if (this.yearvalue && this.displayError === 2) {
      this.getDate();
      this.getUpdateDate();
    }
    //sessionStorage.removeItem('getFirstValue');
  }

  /*MethodName : onChangeday()
   * Purpose : This method is used to read the day value
   * Created By : AC12323
   * Created Date : 3/6/210
   * Hand Over to :
   * */
  onChangeday(newday) {
    this.displayError = 2;
    this.newdayvalue = newday;
    this.newdayvalue = this.mon + '-' + this.newdayvalue + '-' + this.yearvalue;
    this.submitbtn = false;
    if (this.dateFormGroupName.value.getdayVal !== AppLabelConstants.DAY_TEXT || this.dateFormGroupName.value.getdayVal === AppLabelConstants.DAY_TEXT) {
      this.showDateErrorMessage = true;
    }
    if (this.newdayvalue && this.dateFormGroupName.value.getdayVal !== AppLabelConstants.DAY_TEXT) {
      sessionStorage.setItem(SessionConstants.GET_FIRST_VALUE, SessionConstants.GET_FIRST_VALUE);
    }
    this.getDate();
    this.getUpdateDate();
    if (newday === AppLabelConstants.DAY_TEXT) {
      this.showDateErrorMessage = false;
    }
  }

  /*MethodName : getDate()
   * Purpose : This method is used to pass date value to the parent component
   * Created By : AC12323
   * Created Date : 3/6/210
   * Hand Over to :
   * */
  getDate() {
    let monthValue;
    let dayValue;
    if (9 >= this.mon) {
      monthValue = 0 + this.mon;
    } else {
      monthValue = this.mon;
    }
    if ( 9 >= this.selectedDay) {
      dayValue = 0 + this.selectedDay;
    } else {
      dayValue = this.selectedDay;
    }
    this.dateValue = {
      month: monthValue,
      day: dayValue,
      year: this.yearvalue
    };
    if (this.dateValue.month && this.dateValue.day && this.dateValue.year) {
      this.countChange.emit(this.dateValue);
    } else {
      this.showDateErrorMessage = false;
      this.higLabelColor = true;
    }


    this.checkServicseDate();
  }

  clearForm() {
    this.dateFormGroupName.resetForm();
  }

  getUpdateDate() {
    this. getDate();
  }

  checkServicseDate() {
    const selectedDate = this.dateValue.month + '/' + this.dateValue.day + '/' + this.dateValue.year;
    const newDisabilityDateValue = new Date(selectedDate);
    //const reciveServicedate = new Date(this.getServiceDate);
    const reciveServicedate = this.newDisabilityDateValue ? new Date(this.newDisabilityDateValue) : new Date(this.getServiceDate);
    const currentdate = new Date();
    if (this.dropDowndateLabel === AppLabelConstants.THROUGH_LBL && ((reciveServicedate < newDisabilityDateValue) && (currentdate < newDisabilityDateValue))) {
      this.showDateErrorMessage = false;
      this.showMessage = false;
    } else if (this.dropDowndateLabel === AppLabelConstants.THROUGH_LBL && (reciveServicedate > newDisabilityDateValue)) {
      this.showDateErrorMessage = false;
      this.showMessage = false;
    } else if (this.dropDowndateLabel !== AppLabelConstants.THROUGH_LBL && this.dropDowndateLabel !== AppLabelConstants.EXTENSION_LBL && this.getServiceDate !== AppConstants.CURRENT_DATE && this.getServiceDate !== AppConstants.NEW_BORN_DATE
      && (newDisabilityDateValue > reciveServicedate) && this.dateValue && this.dateValue.month && this.dateValue.day && this.dateValue.year ) {
      if (newDisabilityDateValue > reciveServicedate) {
        this.showDateErrorMessage = false;
        this.showMessage = false;
      }

    } else if (this.getServiceDate === AppConstants.CURRENT_DATE && this.dateValue.day !== AppLabelConstants.DAY_TEXT) {
      const newDisabilityDateVal = new Date(selectedDate);
      const reciveServiceDate = new Date();
      if (reciveServiceDate > newDisabilityDateVal) {
        this.showDateErrorMessage = true;
        this.showMessage = false;
      }
    } else if (this.dropDowndateLabel === AppLabelConstants.EXTENSION_LBL && (newDisabilityDateValue <= reciveServicedate) && this.dateValue && this.dateValue.month && this.dateValue.day && this.dateValue.year ) {
      this.showDateErrorMessage = false;
      this.showMessage = false;
    } else if (this.getServiceDate === AppConstants.NEW_BORN_DATE && this.dateValue.day !== AppLabelConstants.DAY_TEXT) {
      const newBornBabyDateVal = new Date(selectedDate);
      const reciveServiceDate = new Date();
      if (reciveServiceDate <= newBornBabyDateVal) {
        this.showDateErrorMessage = false;
        this.showMessage = false;
      }
    }
    if (this.dateValue.day === AppLabelConstants.DAY_TEXT) {
      this.showMessage = true;
    }
  }

}
