import { DomSanitizer } from '@angular/platform-browser';
import { AppLabelConstants } from '@app/shared/constants/appLabel.constants';
import { ChangeDetectorRef, Component, HostListener, Input, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { OwcsUtilityService } from '@app/shared/services/owcs-utility.service';
import { StorageManagementService } from '@app/shared/services/storage-management.service';
import { SessionConstants } from '@app/shared/constants/session.constants';
import { Subscription } from 'rxjs';
import { AppConstants } from '@app/shared/constants/app.constants';
import { ContentService } from '@app/shared/services/content.service';
import { CustomError } from '@app/shared/models/customError.model';
import { IntakeSessionConstants } from '../modules/intake/shared/constants/intakeSession.constants';




@Component({
  selector: 'custom-error',
  templateUrl: './custom-error.component.html',
  styleUrls: ['./custom-error.component.scss']
})
export class CustomErrorComponent {

  owcsContentMap: Map<string, string> = new Map();
  customErrorIcon: string;
  personId: string;
  customErrorDesc: string;
  owscContent;
  customErrorHeader: string;
  customErrorLabel: string;
  customErrorBody: string;
  returnHomeButton: string;
  IscustomErrorContent: boolean = false;
  data;
  constructor(private changeDetector: ChangeDetectorRef, private storageManagementService: StorageManagementService,
    private owcsUtilityService: OwcsUtilityService,
    private router: Router,
    public route: ActivatedRoute,
    private contentService: ContentService,
    private sanitizer: DomSanitizer) {
    const state = this.router.getCurrentNavigation()?.extras?.state as {
      [x: string]: any;
      IscustomErrorContent: boolean;
      customErrorIcon: string;
      customErrorHeader: string;
      customErrorLabel: string;
      customErrorBody: string;
      customErrorDesc: string;
      customErrorReturntoHome: string;
    };
    this.IscustomErrorContent = state?.data?.IscustomErrorContent;
    this.customErrorIcon = state?.data?.customErrorIcon;
    this.customErrorHeader = state?.data?.customErrorHeader;
    this.customErrorLabel = state?.data?.customErrorLabel;
    this.customErrorBody = state?.data?.customErrorBody;
    this.customErrorDesc = state?.data?.customErrorDesc;
    this.returnHomeButton = state?.data?.customErrorReturntoHome;
  }

  goToBenefits(): void {
    this.storageManagementService.removeSessionObject(IntakeSessionConstants.INTAKE_SESSION_DATA);
    this.router.navigate([AppLabelConstants.URL_HOME]);
  }

}
