
import {of as observableOf, Observable} from 'rxjs';
import {map} from 'rxjs/operators';
/*
 Service class for handing the "what" of session/locally stored objects.
 Connects to another service for the saving/retrieving/removing function.
 */
import {Injectable} from '@angular/core';

import {AuthStateService} from '../../../shared/services/auth-state.service';
import {env} from '../../../../environments/environment-loader';
import {HttpHeaders} from '@angular/common/http';
import {ActivatedRouteSnapshot, RouterStateSnapshot} from '@angular/router';
import {TokenUtility} from '../../../shared/utils/tokenUtility';
import { AppConstants } from '@app/shared/constants/app.constants';

@Injectable()
export class AuthStateDevService extends AuthStateService {

  private authenticateDEVURL: string = 'https://openam-sb.thehartford.com/openam/json/authenticate?realm=/Customers&authIndexType=service&authIndexValue=EE_LDAP';  //LTD2 CIAM Authenticate JBOSS URL
  //LTD CIAM Authenticate JBOSS URL: 'http://lad1jdthd2002.thehartford.com:20000/openam/json/authenticate?realm=Customers';
  private authenticateImpersonateDEVURL: string = 'http://lad1jdthd2002.thehartford.com:20000/openam/json/authenticate?realm=krb5&module=CIAM_kerb';
  //private authenticateQAURL: string = 'http://lad1jdthq2015.thehartford.com:20000/openam/json/authenticate?realm=Customers';
  private authenticateQAURL: string = 'http://lad1jdthq2025.thehartford.com:20200/openam/json/authenticate?realm=Customers';
  private authenticatePTEURL: string = 'http://lad1jdthq2013.thehartford.com:20000/openam/json/authenticate?realm=Customers';
  //private authenticateQAURL: string = 'http://localhost:1337/openam-service-ltq.thehartford.com/openam/json/authenticate?realm=Customers';
  //private authenticateQAURL: string = 'http:localhost:1337/openam-service-ltq.thehartford.com/openam/json/authenticate?realm=Customers  ';

  ///private authenticateQAURL: string = 'http://lad1jdthq2025.thehartford.com:20200/openam/json/authenticate?realm=Customers';
  private authenticateImpersonateQAURL: string = 'http://lad1jdthq2025.thehartford.com:20200a/openam/json/authenticate?realm=krb5&module=CIAM_kerb';

  getForgeToken(userid?: string , password?: string, impersonate?: boolean): Observable<any> {
    const frToken = TokenUtility.getExistingFrToken();
    if (!frToken) {
      if (!impersonate) {
        return this.httpClient
          .post(((env.envName !== 'qa' && env.envName !== 'pte') ? this.authenticateDEVURL : (env.envName !== 'pte' ? this.authenticateQAURL : this.authenticatePTEURL)), null, {
            headers: new HttpHeaders()
              .set('Content-Type', 'application/json')
              .set('X-OpenAM-Username',
                (userid && userid.length > 3) ? userid :
                  ((userid && userid === 'sso') ? 'testssouser2@cxr.ciamtest.com' :
                    (env.envName === 'qa' ? 'Welcomeemail7@cxr.ciamtest.com' : 'ferdinand.aliaj@thehartford.com')))
              .set('X-OpenAM-Password',
                (password && password.length > 3) ? password :
                  ((userid && userid === 'sso') ? 'CIAMpass098' :
                    (env.envName === 'qa' ? 'Welcome@123' : 'CIAMpass098')))
          }).pipe(map((data: MyToken) => {
            if (env.envName === AppConstants.LOCAL || env.envName === AppConstants.LOCAL_QA || ((env.envName === 'qa' || env.envName === 'pte') && env.proxyState)) {
              document.cookie = 'CIAMSession=' + data.tokenId + '; path=/;';
            } else {
              document.cookie = 'CIAMSession=' + data.tokenId + '; domain=.thehartford.com; path=/;';
            }
          }));
      } else {
        return this.httpClient
          .post((env.envName !== 'qa' ? this.authenticateImpersonateDEVURL : this.authenticateImpersonateQAURL), null, {
            headers: new HttpHeaders()
              .set('Content-Type', 'application/json')
          }).pipe(map((data: MyTokenKerb) => {
            if (env.envName === AppConstants.LOCAL || env.envName === AppConstants.LOCAL_QA || ((env.envName === 'qa' || env.envName === 'pte') && env.proxyState)) {
              document.cookie = 'CIAMSession=' + data.authId + '; path=/;';
            } else {
              document.cookie = 'CIAMSession=' + data.authId + '; domain=.thehartford.com; path=/;';
            }
          }));
      }
    } else {
      return observableOf(env.envName === AppConstants.LOCAL || env.envName === AppConstants.LOCAL_QA);
    }
  }

}

class MyTokenKerb {
  authId: string;
}

class MyToken {
  tokenId;
  private successUrl;
  private realm;
  constructor(tokenId: string, successUrl: string, realm: string) {
    this.tokenId = tokenId;
  }

  getTokenId() {
    return this.tokenId
  }
}
