import {GbBase} from './gb-base';

export class GbBankvalidation extends GbBase {

  getUrls() {
    return {
      getValidateBankAbaURL:  this.getBaseUrl() + 'gb-bankvalidation-service/bankvalidation/v1/ValidateBankAba?' + this.getClientId('auth'),
    }
  }
}

