export class RAE_ROUTES {
    static RAE_FLOW_THINGS = '/ire';
    static RAE_FLOW = '/ire/rae';
    static GETTING_STARTED_STEP = '/ire/rae/getting-started';
    static HEALTH_CARE_PROVIDER_STEP = '/ire/rae/health-care-provider';
    static DIAGNOSIS = '/ire/rae/diagnosis';
    static YOUR_PROGRESS = '/ire/rae/your-progress';
    static PHYSICAL_ABILITIES = '/ire/rae/physical-abilities';
    static SUCCESS = '/ire/success';
    static ADD_PROVIDER = '/ire/add-provider';
}
export class PROGRESS_BAR_STEP_ID {
    static STEP1 = 1;
    static STEP2 = 2;
    static STEP3 = 3;
    static STEP4 = 4;
    static STEP5 = 5;
    static STEP6 = 6;
}
export class ICONS {
    static icon_schedule = './assets/images/icons/getting-started-progressbar-icon.svg';
    static icon_clipboard = './assets/images/icons/diagnosis-progressbar-icon.svg';
    static icon_review_questions = './assets/images/icons/your-progress-progressbar-icon.svg';
    static icon_ada_accommodation = './assets/images/icons/your-abilities-progressbar-icon.svg';
    static icon_icon_stethoscope = './assets/images/icons/healthcare-provider-progressbar-icon.svg';
    static icon_check_mark = './assets/images/icons/success-progressbar-icon.svg';
}

export class Active_ICONS {
    static icon_schedule = './assets/images/icons/Getting_Started_Active_Icon.svg';
    static icon_clipboard = './assets/images/icons/Diagnosis_Active_Icon.svg';
    static icon_review_questions = './assets/images/icons/Your_Progress_Active_Icon.svg';
    static icon_ada_accommodation = './assets/images/icons/Your_Abilities_Active_Icon.svg';
    static icon_icon_stethoscope = './assets/images/icons/Healthcare_Provider_Active_Icon.svg';
    static icon_check_mark = './assets/images/icons/Success_Active_Icon.svg';
}

export class In_Active_ICONS {
    static icon_schedule = './assets/images/icons/Getting_Started_InActive_Icon.svg';
    static icon_clipboard = './assets/images/icons/Diagnosis_InActive_Icon.svg';
    static icon_review_questions = './assets/images/icons/Your_Progress_InActive_icon.svg';
    static icon_ada_accommodation = './assets/images/icons/Your_Abilities_InActive_icon.svg';
    static icon_icon_stethoscope = './assets/images/icons/Healthcare_Provider_InActive_Icon.svg';
    static icon_check_mark = './assets/images/icons/Success_InActive_Icon.svg';
}
