import {Component, Input, OnInit} from '@angular/core';
import {ProductService} from '../../shared/services/product.service';
import {StorageManagementService} from '../../shared/services/storage-management.service';
import {Product} from '../../shared/models/product.model';
import {BehaviorSubject, combineLatest} from 'rxjs';
import {ReferenceHelperService} from '../../shared/services/reference-helper.service';
import {NotificationService} from '../../shared/services/notifications.service';
import {SessionConstants} from '../../shared/constants/session.constants';
import {AppLabelConstants} from '../../shared/constants/appLabel.constants';
import {AppConstants} from '../../shared/constants/app.constants';
import {AdaProductService} from '../../shared/services/ada-product.service';
import { Intake_Constants } from '../modules/intake/shared/constants/intake.constants';

@Component({
  selector: 'app-product-list-post-login',
  templateUrl: './product-list-post-login.component.html',
  styleUrls: ['./product-list-post-login.component.scss']
})
export class ProductListPostLoginComponent implements OnInit {
  private productList;
  private applicableProductForUser: any[] = [];
  public userProducts: Product[] = [];
  private perRow: number;
  private partyId = '';
  private partyRefId = '';
  private caseid: number;
  private _data = new BehaviorSubject({});
  private sourcePartyDetailsList: any = {};
  private currentUser;
  private disabilityClaimContact = '';

  isPolicyAdminCompleted = new BehaviorSubject(false);
  isLeaveCompleted = new BehaviorSubject(false);
  isMudiCompleted = new BehaviorSubject(false);
  @Input()
  set data(value) {
    this._data.next(value);
  }
  get data() {
    return this._data.getValue();
  }

  constructor(private productService: ProductService,
              private adaProductService: AdaProductService,
              private notificationService: NotificationService,
              private referenceHelper: ReferenceHelperService,
              private storageManagementService: StorageManagementService) { }

  ngOnInit() {

    this.productList = this.productService.getPostLogInProduct();
    this._data.subscribe(x => {
      this.populateProductDesc(this.data);
    });
    if (this.storageManagementService.getSessionData(SessionConstants.USER_PRODUCT)) {
      this.referenceHelper.setReferenceOption(AppConstants.IS_OVERVIEW_LOADED, AppConstants.TRUE);
      this.userProducts = [];
      this.userProducts = this.storageManagementService.getSessionData(SessionConstants.USER_PRODUCT);
      //console.log('Applicable Product from Session on Overview outer');
      // console.log(this.userProducts);
      if (this.userProducts && this.userProducts.length > 6) {
        this.determineRows(this.userProducts.length, 1);
      }
      if (this.userProducts && this.userProducts.length > 0) {
        this.productService.isProductExist.next(true);
      } else {
        this.productService.isProductExist.next(false);
      }
    } else {
      /* ** Start:: Prepare data for blaze call** */
      this.currentUser = (JSON.parse(this.storageManagementService.retrieveSessionObject(SessionConstants.APP_DATA)).appData).userData;

      if (this.currentUser) {
        this.caseid = this.currentUser.caseId;
        this.partyRefId = this.currentUser.partyRefId;

        if (this.currentUser.sourcePartyDetailsList) {
          this.sourcePartyDetailsList = this.currentUser.sourcePartyDetailsList
            .filter((data) => data.srcSystemCd === 1);
          if (this.sourcePartyDetailsList.length) {
            this.partyId = this.sourcePartyDetailsList[0].partyId;
          } else {
            this.sourcePartyDetailsList = this.currentUser.sourcePartyDetailsList
              .filter((data) => data.srcSystemCd === 2 || data.srcSystemCd === 7  || data.srcSystemCd === 8);
            if (this.sourcePartyDetailsList.length) {
              this.partyId = this.sourcePartyDetailsList[0].partyId;
            }
          }
        } else {
          this.partyId = null;
        }
      } else {
        this.partyRefId = '';
        this.partyId = '';
      }

      //Start: Prepare Data for blaze and call the blaze Service

      let adaAPI$ = this.productService.getAdaOptions(this.caseid);
      let coveragePlansAPI$ = this.productService.getCoverageService(this.caseid);
      let combinedAPI$ = combineLatest([adaAPI$, coveragePlansAPI$]);
      if (this.caseid) {
        //Call Options service for ADA Product and Coverage Plans Service
        combinedAPI$.subscribe(data => {
          if(data && data[0]){
            this.storageManagementService.setAppData(SessionConstants.ADA_PRODUCT_PROVISION, data[0]);
            this.adaProductService.isAdaProductSet.next(true);
          }
          if(data && data[1]){
            this.storageManagementService.setAppData(SessionConstants.COVERAGE_PLANS , data[1]);
          }
          this.isPolicyAdminCompleted.next(true);
        }, () => {
          this.isPolicyAdminCompleted.next(true);
        });
      }

      // Call the getLeaveService Service once getCoverageService complete
      this.isPolicyAdminCompleted.subscribe(data => {
        if (data) {
          if (this.caseid) {
            let leaveDetails = this.storageManagementService.getLeaveDtls();
            if (leaveDetails && leaveDetails.leaveResponseList) {
              this.isLeaveCompleted.next(true);
            } else {
              this.notificationService.policyAdminLeaveService(this.caseid).subscribe(res => {
                //console.log('Product:: Success in Leave Service');
                this.storageManagementService.setAppData(SessionConstants.LEAVE_SERVICE, res);
                this.isLeaveCompleted.next(true);
              }, (error) => {
                //console.log('Product:: Error in Leave Service');
                this.isLeaveCompleted.next(true);
              }, () => {
              });
            }
          } else {
            //if case id does not exist call the next service
            this.isLeaveCompleted.next(true);
          }
        }
      });

      // Call the getProduct Service once getLeaveService complete
      this.isLeaveCompleted.subscribe(data => {
        if (data) {
          if (this.partyId && this.partyRefId) {
            //  this.productService.getProduct('2067520', '1031600').subscribe(res => {
            this.productService.getProduct(this.partyId, this.partyRefId).subscribe(res => {
              if (data) {
                // console.log('Product:: Success in MUDI Coverage');
                this.storageManagementService.setAppData( SessionConstants.COVERAGE_MUDI , res);
                this.storageManagementService.setSessionData(SessionConstants.IS_EMPLOYEE_HAS_VOL_PRD, res.voluntaryCoveragePresence);
              }
              this.isMudiCompleted.next(true);
            }, (error) => {
              // console.log('Product:: Error in MUDI Coverage');
              this.isMudiCompleted.next(true);
            }, () => {
            });
          } else {
            //if case id does not exist call the next service
            this.isMudiCompleted.next(true);
          }
        }
      });

      this.storageManagementService.setSessionData(SessionConstants.IS_LOA_PRODUCT_ACTIVE, false);

      // Call the getApplicableProductsForUser Service once getProduct complete
      this.isMudiCompleted.subscribe(data => {
        if (data) {
          //Start: Do not call blaze rule for product if Overview Already Visited
          this.productService.getApplicableProductsForUser().subscribe(res => {

            // At this point overiview loaded completely, subscribe to load profile on header
            this.referenceHelper.setReferenceOption(AppConstants.IS_OVERVIEW_LOADED, AppConstants.TRUE);
            this.userProducts = [];
            this.applicableProductForUser = res.claimOverviewResponseList;

            try {
              for (const productKey of  this.applicableProductForUser) {
                for (const product of this.productList) {
                  if (productKey.uiFieldId === product.prd_key) {
                    this.userProducts.push({
                      id: product.id,
                      prdKey: product.prdKey,
                      name: product.name,
                      description: product.description,
                      disclosure: product.disclosure,
                      benefitsOfferBy: product.benefitsOfferBy,
                      icon: product.icon,
                      location: product.location,
                      covType: product.covType,
                      covCode: product.covCode,
                      prd_key: product.prd_key,
                      isVoluntary: product.isVoluntary
                    })
                    // Checking the LOA product status
                    if (productKey.uiFieldId === Intake_Constants.CLAIM_OVERVIEW_CO_36 && productKey.displayInd === 'true') {
                      this.storageManagementService.setSessionData(SessionConstants.IS_LOA_PRODUCT_ACTIVE, true);
                    }
                  }
                }
              }

              if (this.userProducts && this.userProducts.length > 6) {
                this.determineRows(this.userProducts.length, 1);
              }

              if (this.userProducts && this.userProducts.length > 0) {
                this.storageManagementService.setSessionData(SessionConstants.USER_PRODUCT, this.userProducts);

                /*
                * US156881 - Create a new session data overAllProducts
                * to maintain both userProducts and successorProducts
                */

                this.storageManagementService.setSessionData('overAllProducts', this.userProducts);
                this.productService.isProductExist.next(true);
              } else {
                this.storageManagementService.setSessionData(SessionConstants.USER_PRODUCT, []);
                this.storageManagementService.setSessionData('overAllProducts', []);
                this.productService.isProductExist.next(false);
              }

            } catch (error) {
              //console.log(error.message);
            }

          }, (error) => {
            // At this point overiview loaded completely, subscribe to load profile on header
            this.referenceHelper.setReferenceOption(AppConstants.IS_OVERVIEW_LOADED, AppConstants.TRUE);
            //
          });
        }
      });
      //End:
    }
  }
  determineRows(items: number, setRows: number): void {
    if (Math.ceil(items / setRows) > 6) {
      this.determineRows(items, (setRows + 1));
    } else {
      this.perRow = (Math.ceil(items / setRows));
    }
  }

  populateProductDesc(data) {
    try {
      if (data && data.data) {
        if (data.data.Post_auth_17_Body) {
          //Maternity withiout Leave
          this.productList[0].description = data.data.Post_auth_17_Body.value;
        }
        if (data.data.Post_auth_18_Body) {
          //Planned Surgery
          this.productList[1].description = data.data.Post_auth_18_Body.value;
        }
        if (data.data.Post_auth_19_Body) {
          //STD
          this.productList[2].description = data.data.Post_auth_19_Body.value;
        }
        if (data.data.Post_auth_14_Body) {
          //Leave Of Absence
          this.productList[3].description = data.data.Post_auth_14_Body.value;
        }
        if (data.data.Post_auth_11_Body) {
          //California Paid Family Leave
          this.productList[4].description = data.data.Post_auth_11_Body.value;
        }
        if (data.data.Post_auth_15_Body) {
          //Long Term Disability
          this.productList[5].description = data.data.Post_auth_15_Body.value;
        }
        if (data.data.Post_auth_10_Body) {
          this.productList[6].description = data.data.Post_auth_10_Body.value;
        }
        if (data.data.Post_auth_12_Body) {
          //Critical Illness
          this.productList[7].description = data.data.Post_auth_12_Body.value;
        }
        if (data.data.Post_auth_13_Body) {
          this.productList[8].description = data.data.Post_auth_13_Body.value;
        }
        if (data.data.Post_auth_16_Body) {
          //Maternity with Leave
          this.productList[9].description = data.data.Post_auth_16_Body.value;
        }
        if (data.data.Post_auth_132_body) {
          this.productList[10].description = data.data.Post_auth_132_body.value;
        }
        if (data.data.Post_auth_58_Body) {
          this.productList[0].benefitsOfferBy = data.data.Post_auth_58_Body.value;
        }
        if (data.data.Post_auth_59_Body) {
          this.productList[1].benefitsOfferBy = data.data.Post_auth_59_Body.value;
        }
        if (data.data.Post_auth_60_Body) {
          this.productList[2].benefitsOfferBy = data.data.Post_auth_60_Body.value;
        }
        if (data.data.Post_auth_55_Body) {
          this.productList[3].benefitsOfferBy = data.data.Post_auth_55_Body.value;
        }
        if (data.data.Post_auth_52_Body) {
          this.productList[4].benefitsOfferBy = data.data.Post_auth_52_Body.value;
        }
        if (data.data.Post_auth_56_Body) {
          this.productList[5].benefitsOfferBy = data.data.Post_auth_56_Body.value;
        }
        if (data.data.Post_auth_51_Body) {
          this.productList[6].benefitsOfferBy = data.data.Post_auth_51_Body.value;
        }
        if (data.data.Post_auth_53_Body) {
          this.productList[7].benefitsOfferBy = data.data.Post_auth_53_Body.value;
        }
        if (data.data.Post_auth_54_Body) {
          this.productList[8].benefitsOfferBy = data.data.Post_auth_54_Body.value;
        }
        if (data.data.Post_auth_57_Body) {
          this.productList[9].benefitsOfferBy = data.data.Post_auth_57_Body.value;
        }
        if (data.data.Post_auth_133_body) {
          this.productList[10].benefitsOfferBy = data.data.Post_auth_133_body.value;
        }
        // disclosure for products
        if (data.data.Post_auth_120_Body) {
          this.productList[0].disclosure = data.data.Post_auth_120_Body.value;
        }
        if (data.data.Post_auth_122_Body) {
          this.productList[1].disclosure = data.data.Post_auth_122_Body.value;
        }
        if (data.data.Post_auth_123_Body) {
          this.productList[2].disclosure = data.data.Post_auth_123_Body.value;
        }
        if (data.data.Post_auth_124_Body) {
          this.productList[3].disclosure = data.data.Post_auth_124_Body.value;
        }
        if (data.data.Post_auth_125_Body) {
          this.productList[4].disclosure = data.data.Post_auth_125_Body.value;
        }
        if (data.data.Post_auth_126_Body) {
          this.productList[5].disclosure = data.data.Post_auth_126_Body.value;
        }
        if (data.data.Post_auth_128_Body) {
          this.productList[6].disclosure = data.data.Post_auth_128_Body.value;
        }
        if (data.data.Post_auth_127_Body) {
          this.productList[7].disclosure = data.data.Post_auth_127_Body.value;
        }
        if (data.data.Post_auth_129_Body) {
          this.productList[8].disclosure = data.data.Post_auth_129_Body.value;
        }
        if (data.data.Post_auth_121_Body) {
          this.productList[9].disclosure = data.data.Post_auth_121_Body.value;
        }
        if (data.data.Post_auth_136_body) {
          this.productList[10].disclosure = data.data.Post_auth_136_body.value;
        }
      }
    } catch (error) {
    } finally {

    }
  }
}
