import {Component, EventEmitter, HostListener, Input, OnInit, Output} from '@angular/core';
import {AppLabelConstants} from '@shared/constants/appLabel.constants';

@Component({
  selector: 'primary-link',
  templateUrl: 'primary-link.component.html',
  styleUrls: ['primary-link.component.scss']
})
export class PrimaryLinkComponent implements OnInit {

  @Input() linkName: string;
  @Input() faIcon: string;
  @Input() linkLocation: string;
  @Input() linkId: number;
  @Input() elementId: string;
  @Input() startAClaim = false;
  @Input() isLoading: boolean = false;
  @Input() defaultSpinnerText: string = 'Loading';
  @Input() disableButton: boolean = false;
  @Input() alternate: boolean; //Alternate Colors
  @Output() linkClick: EventEmitter<Event> = new EventEmitter<Event>();
  public appLabelConstants = AppLabelConstants; //Font Styles?

  constructor() {}

  @HostListener('keyup.enter', ['$event'])
  onKeyUpEnter(event) {
    event.preventDefault();
    event.target.click();
  }

  ngOnInit() {
  }

  clickHandler(event: Event) {
    this.linkClick.emit(event);
  }

}
