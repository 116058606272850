export const MOCK_INSURANCE_CARRIER = {
insuranceCarrier : [{insProviderName: 'Aetna', lookUpId: '663011'}],
responseCode: '001',
responseReason: 'success' };

export const MOCK_INSURANCE_CARRIER_PARM_OFF = {
    insuranceCarrier : null,
    responseCode: '010',
    responseReason: 'success' };

export const MOCK_INSURANCE_CARRIER_Error = {
    insuranceCarrier : null,
    responseCode: '400',
    responseReason: 'BadRequest' };
