import {Component, Input} from '@angular/core';
import {SafeHtml, SafeResourceUrl} from '@angular/platform-browser';
import {CustomPageLabelComponent} from '../../../sub-atomic/custom-page-label/custom-page-label.component';

@Component({
  selector: 'tertiary-page-label',
  templateUrl: 'tertiary-page-label.component.html',
  styleUrls: ['tertiary-page-label.component.scss']
})
export class TertiaryPageLabelComponent extends CustomPageLabelComponent {

  @Input() higLabel: string | SafeResourceUrl;
  @Input() labelDisabled = false;
  @Input() errorCondition: boolean;
  @Input() focusCondition: boolean;
  @Input() faIcon: string;
  @Input() innerHtmlval: string;
  @Input() optional: boolean = false;
  @Input() superSetValue?: string;
  @Input() set ariaLevel(data: string) {
    if (data) {
      this._ariaLevel = data;
    }
  }
  @Input() set ariaLabel(data: string) {
    if (data) {
      this._ariaLabel = data;
    }
  }
  _ariaLevel: string = '2';
  _ariaLabel: string = '';

}
