import {platformBrowserDynamic} from '@angular/platform-browser-dynamic';
import {MaintanenceModule} from './app/cxr/maintenance/maintanence.module';
import {defineCustomElements} from 'huk-components/loader';

platformBrowserDynamic().bootstrapModule(MaintanenceModule, {
    preserveWhitespaces: true
  })
  .catch(err => console.log(err));

// define components in the huk-components library
defineCustomElements();
