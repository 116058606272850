
import {of} from 'rxjs';

import {catchError, map} from 'rxjs/operators';
import { Injectable } from '@angular/core';
import {Observable} from '../../../../node_modules/rxjs';
import {ResponseErrorHandlerService} from './response-error-handler.service';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {AppLabelConstants} from '../constants/appLabel.constants';
import {env} from '../../../environments/environment-loader';

import {IStateList, IStateResponse} from '@shared/models/stateList.model';
import {StorageManagementService} from '@shared/services/storage-management.service';
import {RaeSessionConstants} from '@shared/constants/raeSession.constants';

@Injectable()
export class StateLocationService {

  raeSessionConstants = RaeSessionConstants;

  constructor(private errorHandler: ResponseErrorHandlerService,
              private storeManagementService: StorageManagementService,
              private httpClient: HttpClient) {}

  getLocation(personId: string): Observable<IStateList[]> {
    const stateList = this.storeManagementService.getRAESessionData(this.raeSessionConstants.STATE_LIST);
    if (stateList) {
      return of(stateList);
    } else {
      if (personId) {
    return this.httpClient.get(env.thaaWKABPortalService.stateList + '&personid=' + personId,
      {
        headers: new HttpHeaders()
          .set(AppLabelConstants.CONTENT_TYPE_KEY, AppLabelConstants.APPLICATION_KEY)
      }).pipe(
      map(
        (response: IStateResponse) => {
          if (!this.errorHandler.isServiceResponseHasError(response)) {
            this.storeManagementService.setRAESessionData(this.raeSessionConstants.STATE_LIST, response.stateList);
            return response.stateList;
          } else {
            throw response;
          }
        }
      ),   catchError((error: any) => this.errorHandler.handleError(error)), );

  } else {
        return this.errorHandler.handleError('StateLocation API failed because a personId was not available');
      }
      }
    }

}
