import {AfterViewInit, Component, OnInit} from '@angular/core';
import {LeaveDynatraceAction} from '../../shared/utils/leaveDynatraceAction';
import {StorageManagementService} from '../../shared/services/storage-management.service';
import {FormControl, FormGroup} from '@angular/forms';
import {AppLabelConstants} from '../../shared/constants/appLabel.constants';
import {SessionConstants} from '../../shared/constants/session.constants';
import {AppConstants} from '../../shared/constants/app.constants';
@Component({
  selector: 'app-info',
  templateUrl: 'app-info.component.html',
  styleUrls: ['app-info.component.scss'],
})
export class AppInfoComponent extends LeaveDynatraceAction implements OnInit, AfterViewInit {
  public appLabelConstant = AppLabelConstants;
  public envList = [AppConstants.ENABLE, AppConstants.DISABLE];
  public blockTHAA = [AppConstants.ENABLE, AppConstants.DISABLE];
  public appInfoForm: FormGroup;
  public showMoking: boolean = false;
  private tempEnvValue: number;
  private tempThaaValue: boolean = true;

  constructor(private sessionManagementService: StorageManagementService) {
    super(sessionManagementService)
  }

  ngOnInit() {
    // Initializing the form
    this.appInfoForm = new FormGroup({
      envList: new FormControl('', [])
    });
    this.showMoking = false;
  }

  ngAfterViewInit() {
    super.ngAfterViewInit();
  }

  selectedEnvVal(eve) {
    //Setting selected value
    this.tempEnvValue = eve.target.selectedIndex - 1;
  }
  selectedThaaVal(eve) {
    this.tempThaaValue = (eve.target.selectedIndex === 1 ? true : false);
    this.blockThaaUser(this.tempThaaValue);
  }
  applyOverride() {

    //Environments
    if (this.tempEnvValue === 0) {
      this.sessionManagementService.setSessionObjectNoJSON(SessionConstants.MOCKING, true);
    } else if (this.tempEnvValue === 1) {
      this.sessionManagementService.setSessionObjectNoJSON(SessionConstants.MOCKING, false);
    } else {
      this.sessionManagementService.setSessionObjectNoJSON(SessionConstants.MOCKING, false);
    }
  }

  blockThaaUser(val) {
    if (val) {
      this.sessionManagementService.setSessionData(SessionConstants.ALLOW_THAA_USER, true);
    } else {
      this.sessionManagementService.setSessionData(SessionConstants.ALLOW_THAA_USER, false);
    }
  }
}
