import {Component, OnInit} from '@angular/core';
import {StorageManagementService} from '../../../../../shared/services/storage-management.service';
import {ContactsService} from '../../../../../shared/services/contacts.service';
import {ClaimHandlerContentService} from '../../../../../shared/services/claimHanlder-content.service';
import {CustomEventService} from '../../../../../shared/services/tealium/custom-event.service';
import {RelatedClaims} from '../../../../../shared/models/relatedClaims.model';
import {ClaimRepresentativeService} from '../../../../../shared/services/claimRepresentativeDetails.service';
import {Router} from '@angular/router';
import {SessionConstants} from '../../../../../shared/constants/session.constants';
import {AppLabelConstants} from '../../../../../shared/constants/appLabel.constants';
import {AppConstants} from '../../../../../shared/constants/app.constants';
import {AppLeaveConst} from '../../../../../shared/constants/app.constants';
import { AetnaContactsService } from 'app/shared/services/aetnaContacts.service';
import {AdaProductService} from '../../../../../shared/services/ada-product.service';
import {BehaviorSubject} from 'rxjs/internal/BehaviorSubject';
import {Subject} from 'rxjs/internal/Subject';
import {takeUntil} from 'rxjs/operators';

@Component({
  selector: 'app-claim-representative-information',
  templateUrl: './claim-representative-information.component.html',
  styleUrls: ['./claim-representative-information.component.scss']
})
export class ClaimRepresentativeInformationComponent implements OnInit {
  public appLabelConstant = AppLabelConstants;
   insuredID: string;
  public curClaim: RelatedClaims;
  public enhancedAdaLeave;
  public thaaEnhancedAda: boolean = false;
  public thaaFoundationalADA: boolean = false;
  public DCSClaims: RelatedClaims[] = [];
   requiredClaimEventId: string;
   requiredClaimOfficeId: string;
   requiredClaimProcessDate: string;
   requiredCoverageType: string;
   requiredCoverageCategory: string;
   requiredClaimStatusCode: string;
   claimHandlerOfficeNumber: string;
   claimHandlerInfo;
  public claimHandlerName;
   extension;
   mobileNumber;
  public isClaimHandlerApplicable = false;
   appData;
   isClaimExist = false;
  public isClaimHandlerDisplayFlag: boolean = false;
  public claimHandlerInfoExist = false;
  public isCurPageClaimDetails: boolean = false;
   curPage: string;
  public previousPage: string;
   firstName;
   lastName;
  public mostRecentClaim;
  public claimRepresentativeDetailsResponse;
  public claim_rep_infobox;
  public extensionLabel = AppLabelConstants.EXTENSION;
  contactInfoFetched = new BehaviorSubject(false);
  _destroy$: Subject<void> = new Subject();

  constructor(private router: Router,
              private storageManagementService: StorageManagementService,
              private contactService: ContactsService,
              private claimHandlerContentService: ClaimHandlerContentService,
              private claimRepresentativeService: ClaimRepresentativeService,
              private customEventService: CustomEventService,
              private aetnaContactsService: AetnaContactsService,
              private adaProductService: AdaProductService) {
  }

  ngOnInit() {

    this.isClaimHandlerDisplayFlag = this.contactService.getIsClaimRepNeedToDisplay();
    this.curPage = this.router.url.split('?')[0];
    this.previousPage = this.storageManagementService.getSessionData(SessionConstants.PREVIOUS_URL);
    this.previousPage = this.previousPage ? this.previousPage.split('?')[0] : null;

    // reterive the session object from session
    if (this.storageManagementService.retrieveSessionObject(SessionConstants.APP_DATA)) {
      this.appData = JSON.parse(this.storageManagementService.retrieveSessionObject(SessionConstants.APP_DATA)).appData;
    }

    if ( this.curPage === AppLabelConstants.URL_HOME ) {
      if ( this.adaProductService.isAdaLeaveExists() ) {
        if (this.storageManagementService.getSessionData(SessionConstants.THAA_ENHANCED_ADA)) {
          this.thaaEnhancedAda = this.storageManagementService.getSessionData(SessionConstants.THAA_ENHANCED_ADA);
          this.executeClaimRepInfo();
        } else {
          this.adaProductService.isAdaProductSet.subscribe(value => {
            this.thaaEnhancedAda = this.adaProductService.isEnhancedAdaApplicable();
            this.claimHandlerName = '';
            this.mobileNumber = '';
            this.extension = '';
            this.executeClaimRepInfo();
          })
        }
      } else {
        this.executeClaimRepInfo();
      }
      this.storageManagementService.setSessionData(SessionConstants.CLAIM_HANDLER_DISPLAY, null);
      this.storageManagementService.setSessionData(SessionConstants.IS_CLAIM_REP_INFO_SET, null);
    } else {
      //code preserve or reset the claim handler display on details page refreshes
      if ( this.storageManagementService.getSessionData(SessionConstants.CLAIM_HANDLER_DISPLAY) ) {
        this.isClaimHandlerDisplayFlag = this.storageManagementService.getSessionData(SessionConstants.CLAIM_HANDLER_DISPLAY);
      } else {
        this.storageManagementService.setSessionData(SessionConstants.CLAIM_HANDLER_DISPLAY, this.isClaimHandlerDisplayFlag);
      }
      this.executeClaimRepInfo();
    }
  }

  /**
   * function to execute claim representative info logic
   */
  executeClaimRepInfo() {
    let insuredClaimVal;
    let relatedClaimLength;
    let nonRelatedClaimLength;
    this.claimRepresentativeInfo();
    if (this.appData && this.appData.userClaims) {
      insuredClaimVal = this.appData.userClaims.claimOverview;
      if (insuredClaimVal && insuredClaimVal.relatedClaims) {
        relatedClaimLength = insuredClaimVal.relatedClaims.length;
      }
      if (insuredClaimVal && insuredClaimVal.nonRelatedClaims) {
        nonRelatedClaimLength = insuredClaimVal.nonRelatedClaims.length;
      }
    }

    if (relatedClaimLength > 0 || nonRelatedClaimLength > 0 ) {
      this.isClaimExist = true;
    }

    if (this.appData && this.appData.userData && this.appData.userData.sourcePartyDetailsList && this.appData.userData.sourcePartyDetailsList[0] &&
      this.appData.userData.sourcePartyDetailsList[0].srcSystemCd === AppConstants.SOURCE_SYS_CD_DCS && this.isClaimExist) { //null check
      this.insuredID = this.appData.userData.insuredId; //  to read the insuredId
      //this.claimRepresentativeInfo(this.insuredID);
      if (this.setClaimHandlerDisplayRule()) {
        this.getRepresentativeName();
      }
    }
  }

  /*MetodNAme:  claimRepresentativeInfo()
   *  Purpose : This method is used to reterive first name and last name data will contain all the combination of claimEvent ID  && office id which can be use for clantact phone as weel
   *  Create date : 3/1/2018
   *  Author:
   *  Modified Date :
   *
   * */
  claimRepresentativeInfo() {
    if ( this.curPage.toLowerCase() === AppLabelConstants.URL_CLAIM_DETAILS.toLowerCase()
      || this.curPage.toLowerCase() === AppLabelConstants.URL_LEAVE_DETAILS.toLowerCase()
      || this.curPage.toLowerCase() === AppLabelConstants.URL_SINGLE_PAYMENT.toLowerCase()
      || this.curPage.toLowerCase() === AppLabelConstants.LINK_TO_SEE_MORE_FUTURE_PAYMENTS.toLowerCase()
    ) {
      const curClaim = JSON.parse(this.storageManagementService.retrieveSessionObject(SessionConstants.CUR_CLAIM)).curClaim;
      const curLeave = this.storageManagementService.getSessionData(SessionConstants.CURR_LEAVE);
      if ( (this.curPage.toLowerCase() === AppLabelConstants.URL_CLAIM_DETAILS.toLowerCase()
        || this.curPage.toLowerCase() === AppLabelConstants.URL_SINGLE_PAYMENT.toLowerCase())
        && curClaim
      ) {
        this.curClaim = curClaim;
      } else if ( this.curPage.toLowerCase() === AppLabelConstants.URL_LEAVE_DETAILS.toLowerCase() && curLeave && curLeave.leaveType ) {
        this.thaaEnhancedAda = this.storageManagementService.getSessionData(SessionConstants.THAA_ENHANCED_ADA);
        this.thaaFoundationalADA = curLeave.leaveType === AppLeaveConst.ADA_TYPE && this.storageManagementService.getSessionData(SessionConstants.THAA_FOUNDAMENTAL_ADA);
        if ( curLeave.leaveType === AppLeaveConst.ADA_TYPE && (this.thaaEnhancedAda || this.thaaFoundationalADA)) {
          this.enhancedAdaLeave = curLeave;
        } else if ( curLeave.leaveType === AppLeaveConst.ADA_TYPE && !this.thaaEnhancedAda && !this.thaaFoundationalADA) {
          this.curClaim = null;
        } else if ( curClaim ) {
          this.curClaim = curClaim;
        }
      }
    } else if ( this.curPage.toLowerCase().includes(AppLabelConstants.URL_SCHEDULE_CALL.toLowerCase()) ) {
      const enhancedAdaLeaveFromHome = this.storageManagementService.getSessionData((SessionConstants.THAA_ENHANCED_ADA_LEAVE));
      if ( this.previousPage.toLowerCase() === AppLabelConstants.URL_HOME.toLowerCase() && enhancedAdaLeaveFromHome ) {
        this.enhancedAdaLeave = enhancedAdaLeaveFromHome;
        this.thaaEnhancedAda = true;
      } else {
        const curClaim = this.storageManagementService.getSessionData(SessionConstants.CUR_CLAIM);
        if ( this.previousPage.toLowerCase() === AppLabelConstants.URL_CLAIM_DETAILS.toLowerCase()
          || this.previousPage.toLowerCase().includes(AppLabelConstants.URL_SINGLE_PAYMENT.toLowerCase())
          || this.previousPage.includes(AppLabelConstants.LINK_TO_SEE_MORE_FUTURE_PAYMENTS.toLowerCase())
          || this.previousPage.toLowerCase() === AppLabelConstants.URL_HOME.toLowerCase()
            && curClaim
        ) {
          this.curClaim = curClaim;
        } else if ( this.previousPage.toLowerCase() === AppLabelConstants.URL_LEAVE_DETAILS.toLowerCase() ) {
          const curLeave = this.storageManagementService.getSessionData(SessionConstants.CURR_LEAVE);
          if ( curLeave && curLeave.leaveType === AppLeaveConst.ADA_TYPE ) {
            this.enhancedAdaLeave = curLeave;
            this.thaaEnhancedAda = true;
          } else if ( curClaim ) {
            this.curClaim = curClaim;
          }
        }
      }
    } else {
      this.setCurrentClaim();
    }
    if ( (this.thaaEnhancedAda || this.thaaFoundationalADA) && this.enhancedAdaLeave ) {
      this.setEnhancedAdaClaimRepInfo();
    } else {
      if (this.curClaim && this.curClaim.sourceSystemId && this.curClaim.sourceSystemId === AppConstants.SOURCE_SYS_ID_DCS) {
        this.setDCSRelatedClaimRepInfo();
      } else if (this.curClaim && this.curClaim.sourceSystemId && this.curClaim.sourceSystemId === AppConstants.SOURCE_SYS_ID_AA) {
        this.setAARelatedClaimRepInfo();
      } else {
        this.contactService.isDisabilityContactCompleted.next(true);
      }
    }
  }

  /**
   * function to set the most recent claim/leave
   */
  setCurrentClaim() {
    const userClaims = this.appData ? this.appData.userClaims ? this.appData.userClaims : null : null;
    const claimOverview = userClaims ? userClaims.claimOverview ? userClaims.claimOverview : null : null;
    const relatedClaims = claimOverview ? (claimOverview.relatedClaims && claimOverview.relatedClaims.length > 0) ? claimOverview.relatedClaims : null : null;
    const nonRelatedClaims = claimOverview ? (claimOverview.nonRelatedClaims && claimOverview.nonRelatedClaims.length > 0) ? claimOverview.nonRelatedClaims : null : null;
    const leaveClaims = claimOverview ? (claimOverview.leaveClaims && claimOverview.leaveClaims.length > 0) ? claimOverview.leaveClaims : null : null;

    if ( relatedClaims || nonRelatedClaims ) {
      this.mostRecentClaim = '';
      const recentClaimDate = relatedClaims !== null && relatedClaims.length > 0 ? new Date(relatedClaims[0].disabilityDate).getTime() : 0;
      const nrRecentClaimDate = nonRelatedClaims !== null && nonRelatedClaims.length > 0 ? new Date(nonRelatedClaims[0].disabilityDate).getTime() : 0;
      if (recentClaimDate >= nrRecentClaimDate) {
        this.mostRecentClaim = recentClaimDate;
        this.DCSClaims = relatedClaims.filter(dcsClaim => (dcsClaim.sourceSystemId === AppConstants.SOURCE_SYS_ID_DCS));
        this.curClaim = relatedClaims[0];
      } else {
        this.mostRecentClaim = nrRecentClaimDate;
        this.DCSClaims = nonRelatedClaims.filter(dcsClaim => (dcsClaim.sourceSystemId === AppConstants.SOURCE_SYS_ID_DCS));
        this.curClaim = nonRelatedClaims[0];
      }
      if (this.DCSClaims && this.DCSClaims.length > 0) {
        this.requiredClaimEventId = this.DCSClaims[0].claimEventId; //rClaims[0].claimEventId;
        this.requiredClaimOfficeId = this.DCSClaims[0].claimOfficeId;
        this.requiredClaimProcessDate = this.DCSClaims[0].claimProcessDate;
        this.requiredCoverageType = this.DCSClaims[0].coverageType;
        this.requiredCoverageCategory = this.DCSClaims[0].coverageCategory;
        this.requiredClaimStatusCode = this.DCSClaims[0].claimStatusCode;
      }
      /*if (this.curClaim.coverageCategory === (AppConstants.LTD) || this.curClaim.coverageCategory === (AppConstants.WD)) {
        this.isClaimHandlerApplicable = true;
        this.claimHandlerInfoExist = true;
      } else {
        this.isClaimHandlerApplicable = false;
        this.claimHandlerInfoExist = false;
      }*/
    }
    if ( this.thaaEnhancedAda ) {
      const sortedBenefits = this.storageManagementService.getSessionData(SessionConstants.SORTED_BENEFITS);
      let adaLeave;
      //find the most recent adaLeave from the sorted benefits.
      if ( sortedBenefits.length > 0 ) {
        for ( let i = 0; i < sortedBenefits.length; i++ ) {
          if ( Array.isArray(sortedBenefits[i]) ) {
            adaLeave = sortedBenefits[i].find(leave => leave.leaveType === AppLeaveConst.ADA_TYPE);
          } else if ( sortedBenefits[i].leaveType && sortedBenefits[i].leaveType === AppLeaveConst.ADA_TYPE ) {
              adaLeave = sortedBenefits[i];
          }
          if ( adaLeave ) {
            break;
          }
        }
      }
      //for concurrent or standalone ada leave
      if ( this.curClaim ) {
        const curClaimDisabilityDate = new Date(this.curClaim.disabilityDate).getTime();
        const adaLeaveStartDate =  new Date(adaLeave.leaveStartDate).getTime();
        if  ( adaLeaveStartDate >= curClaimDisabilityDate ) {
          this.enhancedAdaLeave = adaLeave;
        }
      } else { //for standalone ada only leaves
        if (sortedBenefits && sortedBenefits[0].leaveType && sortedBenefits[0].leaveType === AppLeaveConst.ADA_TYPE ) {
          this.enhancedAdaLeave = {...sortedBenefits[0]};
        }
      }
    }
  }

  /**
   * function to set claim representative information for enhanced ada leave
   */
  setEnhancedAdaClaimRepInfo() {
    this.claimRepresentativeService.getLeaveRepresentativeDetails(this.enhancedAdaLeave.personId, this.enhancedAdaLeave.leaveId).subscribe(repData => {
      if ( repData ) {
        this.setClaimRepInfo(repData);
        this.extension = this.claimRepresentativeDetailsResponse.ContactNumber.trim();
        if ( this.extension ) {
          this.storageManagementService.setSessionData(SessionConstants.CLAIM_EXTENSION, this.extension);
        }
        this.mobileNumber = AppLabelConstants.phoneNumberFormat(this.claimRepresentativeDetailsResponse.directPhoneNumber);
      }
      if ( this.mobileNumber.trim() === '' ) {
        this.setAetnaContact(this.enhancedAdaLeave.personId);
        this.storageManagementService.isClaimRepInfoSet.next(true);
      } else if ( this.claimHandlerName && this.claimHandlerName.trim() !== '' && this.mobileNumber && this.mobileNumber.trim() !== '' ) {
        this.claimHandlerInfoExist = true;
        this.storageManagementService.setSessionData(SessionConstants.CLAIM_MOBILE, this.mobileNumber);
        this.storageManagementService.isClaimRepInfoSet.next(true);
      } else {
        this.claimHandlerInfoExist = false;
        this.storageManagementService.isClaimRepInfoSet.next(false);
      }
    });
  }

  /**
   * function to set claim representative information for dcs claims
   */
  setDCSRelatedClaimRepInfo() {
    if (this.storageManagementService.getSessionData(SessionConstants.DIS_CLAIM_OFC_CONTACTS) &&
      this.storageManagementService.getSessionData(SessionConstants.DIS_CLAIM_OFC_CONTACTS).length > 0) {
      const data = this.storageManagementService.getSessionData(SessionConstants.DIS_CLAIM_OFC_CONTACTS);
      // making this flag true as claims can come from history service or based on caseid, in that case filteredData would be empty
      this.contactService.isDisabilityContactCompleted.next(true);
      const filteredData = data.filter(el => (el.claimEventId === this.curClaim.claimEventId && el.officeId === this.curClaim.claimOfficeId));
      if (filteredData && filteredData.length > 0) {
        //get the claim handler phone number to pass as Input tt Claim Handler Component
        this.claimHandlerOfficeNumber = filteredData[0].phoneNumber;
        this.mobileNumber = AppLabelConstants.phoneNumberFormat(this.claimHandlerOfficeNumber);
        this.storageManagementService.setSessionData(SessionConstants.DISABILLITY_CLAIM_CONTACT, this.claimHandlerOfficeNumber);
        this.storageManagementService.disabilityContactsForExtension.next(this.claimHandlerOfficeNumber);
        this.storageManagementService.isClaimRepInfoSet.next(true);
        this.contactInfoFetched.next(true);
      }
    } else {
      this.contactInfoFetched.next(false);
      this.insuredID = (this.appData.userData && this.appData.userData.insuredId) ? this.appData.userData.insuredId : null;
      const caseId = this.storageManagementService.getAppData(SessionConstants.CASE_DATA) ? this.storageManagementService.getAppData(SessionConstants.CASE_DATA).defaultCaseId : null;
      this.contactService.getDisabilityClaimRelatedContact(this.insuredID, caseId).subscribe(data => {
        // data will contain all the combination of claimEvent ID  && office id which can be use for claim handler contact phone as weel
        if (data && data.disabilityClaimOfficeContacts) {
          /*this.storageManagementService.setSessionObject('disabilityClaimOfficeContacts', data.disabilityClaimOfficeContacts);*/
          this.storageManagementService.setSessionData(SessionConstants.DIS_CLAIM_OFC_CONTACTS, data.disabilityClaimOfficeContacts);
          const filteredData = data.disabilityClaimOfficeContacts.filter(el => (el.claimEventId === this.curClaim.claimEventId && el.officeId === this.curClaim.claimOfficeId));
          if (filteredData && filteredData.length > 0) {
            //get the claim handler phone number to pass as Input tt Claim Handler Component
            this.claimHandlerOfficeNumber = filteredData[0].phoneNumber;
            this.mobileNumber = AppLabelConstants.phoneNumberFormat(this.claimHandlerOfficeNumber);
            this.storageManagementService.setSessionData(SessionConstants.DISABILLITY_CLAIM_CONTACT, this.claimHandlerOfficeNumber);
            this.contactService.isDisabilityContactCompleted.next(true);
            this.storageManagementService.disabilityContactsForExtension.next(this.claimHandlerOfficeNumber);
            this.storageManagementService.isClaimRepInfoSet.next(true);
            this.contactInfoFetched.next(true);
          }
        }
      }, (error) => {
        this.contactService.isDisabilityContactCompleted.next(true);
      }, () => {
        this.contactService.isDisabilityContactCompleted.next(true);
      });
    }
  }

  /**
   * function to set claim representative information for aa claims
   */
  setAARelatedClaimRepInfo() {
    this.claimRepresentativeService.getClaimRepresentativeDetails(this.curClaim.personId, this.curClaim.claimEventId).subscribe(data => {
      if (data) {
        this.setClaimRepInfo(data);
        this.claimHandlerOfficeNumber = this.claimRepresentativeDetailsResponse.ContactNumber;
        this.mobileNumber = AppLabelConstants.phoneNumberFormat(this.claimRepresentativeDetailsResponse.ContactNumber);
        this.contactService.isDisabilityContactCompleted.next(true);
        this.storageManagementService.disabilityContactsForExtension.next(this.claimHandlerOfficeNumber);
        this.storageManagementService.isClaimRepInfoSet.next(true);
        if (this.claimHandlerName && this.claimHandlerName.trim() !== '' && this.mobileNumber && this.mobileNumber.trim() !== ''
          && this.curClaim.claimType !== AppConstants.LONG_TERM_DISABILITY && this.curClaim.claimType !== AppConstants.SHORT_TERM_DISABILITY && this.curClaim.claimType !== AppConstants.PAID_FAMILY_LEAVE_TYPE
          && this.curClaim.coverageCategory !== AppConstants.PWD) {
          this.claimHandlerInfoExist = true;
        } else {
          this.claimHandlerInfoExist = false;
        }

        if ((this.curClaim.claimType === AppConstants.LONG_TERM_DISABILITY || this.curClaim.coverageCategory === AppConstants.PWD)
          && this.claimRepresentativeDetailsResponse.directPhoneNumber) {
          this.claimHandlerInfoExist = true;
          this.mobileNumber = AppLabelConstants.phoneNumberFormat(this.claimRepresentativeDetailsResponse.directPhoneNumber);
        }
        //check claim type and set extension number
        switch (this.curClaim.claimType) {
          case AppConstants.SHORT_TERM_DISABILITY:
          case AppConstants.PAID_FAMILY_LEAVE_TYPE:
            if (this.claimRepresentativeDetailsResponse.ContactNumber && this.claimRepresentativeDetailsResponse.ContactNumber.trim() !== '') {
              this.storageManagementService.setSessionData(SessionConstants.CLAIM_EXTENSION, this.claimRepresentativeDetailsResponse.ContactNumber);
              this.extension = this.claimRepresentativeDetailsResponse.ContactNumber;
            }
            break;
          case AppConstants.LONG_TERM_DISABILITY :
            if (!this.claimRepresentativeDetailsResponse.directPhoneNumber && this.claimRepresentativeDetailsResponse.ContactNumber
              && this.claimRepresentativeDetailsResponse.ContactNumber.trim() !== '') {
              this.storageManagementService.setSessionData(SessionConstants.CLAIM_EXTENSION, this.claimRepresentativeDetailsResponse.ContactNumber);
              this.extension = this.claimRepresentativeDetailsResponse.ContactNumber;
            }
            break;
        }
        if (this.mobileNumber && this.claimHandlerInfoExist) {
          this.storageManagementService.setSessionData(SessionConstants.CLAIM_MOBILE, this.mobileNumber);
        }
        if (this.curClaim.claimType && (this.curClaim.claimType === AppConstants.SHORT_TERM_DISABILITY || this.curClaim.claimType === AppConstants.PAID_FAMILY_LEAVE_TYPE ||
          (this.curClaim.claimType === AppConstants.LONG_TERM_DISABILITY && !this.claimRepresentativeDetailsResponse.directPhoneNumber))) {
          //calling the Aetna case specific contact information
          this.setAetnaContact(this.curClaim.personId);
        }
      }
    }, (error) => {
      this.contactService.isDisabilityContactCompleted.next(true);
    }, () => {
      this.contactService.isDisabilityContactCompleted.next(true);
    });
  }

  /**
   * function to set claim representative details
   *
   * @param representativeInfo
   */
  setClaimRepInfo( representativeInfo ) {
    if (this.storageManagementService.getSessionData(SessionConstants.CLAIM_MOBILE)) {
      this.storageManagementService.setSessionData(SessionConstants.CLAIM_MOBILE, '');
    }
    if (this.storageManagementService.getSessionData(SessionConstants.CLAIM_EXTENSION)) {
      this.storageManagementService.setSessionData(SessionConstants.CLAIM_EXTENSION, '');
    }
    if(this.thaaFoundationalADA){
      const foundationalAdaContact = this.storageManagementService.getSessionData(SessionConstants.FOUNDATIONAL_ADA_CONTACT);
      this.storageManagementService.setSessionData(SessionConstants.THAA_FOUNDATIONAL_ADA_MOBILE,foundationalAdaContact);
    } else {
      this.storageManagementService.setSessionData(SessionConstants.THAA_FOUNDATIONAL_ADA_MOBILE,'');
    }
    this.storageManagementService.setSessionData(SessionConstants.CLAIM_REP_DETAILS, representativeInfo);
    this.claimRepresentativeDetailsResponse = representativeInfo;
    this.claimHandlerName = this.claimRepresentativeDetailsResponse.claimOwnerName;
    this.storageManagementService.setSessionData(SessionConstants.CLAIM_REP_NAME, this.claimHandlerName );
  }

  /**
   * function to set claim specific office number if direct mobile number is not available
   *
   * @param personId
   */
  setAetnaContact( personId ) {
    this.aetnaContactsService.getAetnaDisabilityClaimRelatedContact(personId).subscribe(contact => {
      if (contact) {
        if (contact.aetnaContactDetails && contact.aetnaContactDetails.phone) {
          if (this.claimHandlerName && this.claimHandlerName.trim() !== '') {
            this.claimHandlerInfoExist = true;
          }
          this.mobileNumber = AppLabelConstants.phoneNumberFormat(contact.aetnaContactDetails.phone);
          this.storageManagementService.setSessionData(SessionConstants.CLAIM_MOBILE, this.mobileNumber);
        }
      }
    });
  }

  /*MetodNAme:  getRepresentativeName()
   *  Purpose : This method is used to reterive first name and last name
   *  Create date : 3/1/2018
   *  Author:
   *  Modified Date :
   *
   * */
  getRepresentativeName() {
    if (this.curClaim && this.curClaim.sourceSystemId && this.curClaim.sourceSystemId === AppConstants.SOURCE_SYS_ID_DCS) {
      this.claimHandlerContentService.getClaimHandlerInformation(
        this.curClaim.claimEventId, this.curClaim.claimOfficeId,    this.curClaim.claimProcessDate,
        this.curClaim.coverageType, this.curClaim.coverageCategory, this.curClaim.claimStatusCode
      ).pipe(takeUntil(this._destroy$)).subscribe(data => {
        if (data) {
          this.claimHandlerInfo = data;
          this.firstName = this.claimHandlerInfo.firstName;
          this.lastName = this.claimHandlerInfo.lastName;
          this.extension = this.claimHandlerInfo.phoneExtension ? this.claimHandlerInfo.phoneExtension : '';

          if (this.firstName && this.lastName) {
            this.claimHandlerName = this.firstName + ' ' + this.lastName;
          } else if (this.firstName) {
            this.claimHandlerName = this.firstName;
          } else if (this.lastName) {
            this.claimHandlerName = this.lastName;
          } else {
            return null;
          }
          if (this.claimHandlerName) {
            this.claimHandlerName = this.claimHandlerName.toLowerCase().replace(/\b[a-z]/g, (letter) => letter.toUpperCase());
            this.storageManagementService.setSessionData(SessionConstants.CLAIM_REP_NAME, this.claimHandlerName);
          }

          this.extension = (this.extension ? this.extension : '');
          //As per service team claim handler phone number will same as disability claim number as both will populate from letter head

          this.contactInfoFetched.subscribe(status => {
            if (status) {
              this.mobileNumber = this.claimHandlerOfficeNumber; //data[i].officeNumber

              //Start: Set the Claim Handler Details in the Session data
              const claimHandlerInfo = {
                claimHanlerName: this.claimHandlerName,
                claimHandlerExt: this.extension,
                claimHanlderOfficeNumber: this.mobileNumber
              };

              this.storageManagementService.setSessionData(SessionConstants.CLAIM_HANDLER_INFO, claimHandlerInfo);
              this.storageManagementService.isClaimRepInfoSet.next(true);
              //End

              this.claimHandlerInfoExist = this.claimHandlerName?.trim() !== '' && this.mobileNumber?.trim() !== '';
              this.contactInfoFetched.unsubscribe();
            }
          });
        } else {
          this.claimHandlerInfoExist = false;
        }
      }, (error) => {
        console.log('Claim Handler Not available');
      })
    }
  };

  /**
   * function to set claim handler display rule for claims
   */
  setClaimHandlerDisplayRule(): boolean {
    const insuredClaimVal = this.appData.userClaims.claimOverview;
    const relatedClaimLength = insuredClaimVal.relatedClaims.length;
    const nonRelatedClaimLength = insuredClaimVal.nonRelatedClaims.length;
    if (relatedClaimLength > 0) {
      this.isClaimHandlerApplicable = true;
    } else if (relatedClaimLength === 0  && nonRelatedClaimLength === 1) {
      this.isClaimHandlerApplicable = true;
    } else {
      this.isClaimHandlerApplicable = false;
    }

    return this.isClaimHandlerApplicable;
  }

  trackCallYourRepresentativeClick(documentName, documentCategory) {
    this.customEventService.trackMissingInfoDocumentCategory(documentName, documentCategory);
  }
}
