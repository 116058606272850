import {ClaimPaymentDetailsResponse, ClaimPaymentResponse} from '../shared/models/Payment.model';

/**
 * Created by AC12394 on 9/12/2017.
 */

const mockEobBlob = {
  personId: '11065998',
  claimId: '2077682',
  eobNum: '2048975',
  company: 'NTB',
  paygroup: 'PIW',
  payEndDate: '2021-03-17T00:00:00',
  offcycle: 'N',
  pageNum: '2',
  lineNum: '5',
  sepchk: '1'
};

export const PAYMENT_HISTORY_MOCK_SERVICE_RESPONSE: ClaimPaymentResponse = {
  errorList: null,
  claimPaymentInfo:    [
    {
      paymentID: '142961965',
      checkNbr: 'EFT',
      payee: 'FSDGFSGF SFHSKDFH',
      paymentDate: '07/28/2020',
      checkAmt: 170.11,
      startDate: '07/28/2020',
      endDate: '07/28/2020',
      paymentTypeCode: '01',
      electronicTransfer: 'No',
      payeeId: '9005604037',
      eftAccountNumber: ['*************7456'],
      eobBlob: mockEobBlob
    }, {
      paymentID: '142960982',
      checkNbr: '22930158',
      payee: 'FSDGFSGF SFHSKDFH',
      paymentDate: '07/17/2020',
      checkAmt: 295.33,
      startDate: '07/01/2020',
      endDate: '07/31/2020',
      paymentTypeCode: '01',
      electronicTransfer: 'No',
      payeeId: '9005604037',
      eftAccountNumber: null,
      eobBlob: mockEobBlob
    }, {
      paymentID: '142959480',
      checkNbr: '22929448',
      payee: 'FSDGFSGF SFHSKDFH',
      paymentDate: '06/18/2020',
      checkAmt: 295.33,
      startDate: '06/01/2020',
      endDate: '06/30/2020',
      paymentTypeCode: '01',
      electronicTransfer: 'No',
      payeeId: '9005604037',
      eftAccountNumber: null,
      eobBlob: mockEobBlob
    }, {
      paymentID: '142958789',
      checkNbr: '22928926',
      payee: 'FSDGFSGF SFHSKDFH',
      paymentDate: '05/27/2020',
      checkAmt: 167.35,
      startDate: '05/15/2020',
      endDate: '05/31/2020',
      paymentTypeCode: '01',
      electronicTransfer: 'No',
      payeeId: '9005604037',
      eftAccountNumber: null,
      eobBlob: mockEobBlob
    },
    {
      paymentID: '2',
      checkNbr: 'EFT',
      payee: 'Robert Anderson',
      paymentDate: '01/27/2005',
      checkAmt: 1402.2,
      startDate: '01/07/2005',
      endDate: '02/06/2005',
      paymentTypeCode: '01',
      electronicTransfer: 'No',
      payeeId: '9001686813',
      eftAccountNumber: ['101000695'],
      eobBlob: mockEobBlob
    },
    {
    paymentID: '1001',
    checkNbr: 'EFT',
    payee: 'ROBERT ANDERSON',
    paymentDate: '08/26/2008',
    checkAmt: 156,
    startDate: '08/01/2008',
    endDate: '08/31/2008',
    paymentTypeCode: '01',
    electronicTransfer: 'No',
    payeeId: '9001686813',
    eftAccountNumber: ['101000695'],
    eobBlob: mockEobBlob
  },
  {
    paymentID: '1002',
    checkNbr: 'EFT',
    payee: 'ROBERT ANDERSON',
    paymentDate: '07/28/2008',
    checkAmt: 1560,
    startDate: '07/01/2008',
    endDate: '07/31/2008',
    paymentTypeCode: '01',
    electronicTransfer: 'No',
    payeeId: '9001686813',
    eftAccountNumber: ['101000695'],
    eobBlob: mockEobBlob
  },
  {
    paymentID: '1003',
    checkNbr: 'EFT',
    payee: 'ROBERT ANDERSON',
    paymentDate: '06/25/2008',
    checkAmt: 1560,
    startDate: '06/01/2008',
    endDate: '06/30/2008',
    paymentTypeCode: '01',
    electronicTransfer: 'No',
    payeeId: '9001686813',
    eftAccountNumber: ['101000695'],
    eobBlob: mockEobBlob
  },
  {
    paymentID: '1004',
    checkNbr: 'EFT',
    payee: 'ROBERT ANDERSON',
    paymentDate: '05/27/2008',
    checkAmt: 1560,
    startDate: '05/01/2008',
    endDate: '05/31/2008',
    paymentTypeCode: '01',
    electronicTransfer: 'No',
    payeeId: '9001686813',
    eftAccountNumber: ['101000695'],
    eobBlob: mockEobBlob
  },
  {
    paymentID: '1005',
    checkNbr: 'EFT',
    payee: 'ROBERT ANDERSON',
    paymentDate: '04/25/2008',
    checkAmt: 1560,
    startDate: '04/01/2008',
    endDate: '04/30/2008',
    paymentTypeCode: '01',
    electronicTransfer: 'No',
    payeeId: '9001686813',
    eftAccountNumber: ['101000695'],
    eobBlob: mockEobBlob
  },
  {
    paymentID: '1006',
    checkNbr: 'EFT',
    payee: 'ROBERT ANDERSON',
    paymentDate: '03/26/2008',
    checkAmt: 1560,
    startDate: '03/01/2008',
    endDate: '03/31/2008',
    paymentTypeCode: '01',
    electronicTransfer: 'No',
    payeeId: '9001686813',
    eftAccountNumber: ['101000695'],
    eobBlob: mockEobBlob
  },
  {
    paymentID: '1007',
    checkNbr: 'EFT',
    payee: 'ROBERT ANDERSON',
    paymentDate: '02/26/2008',
    checkAmt: 1560,
    startDate: '02/01/2008',
    endDate: '02/29/2008',
    paymentTypeCode: '01',
    electronicTransfer: 'No',
    payeeId: '9001686813',
    eftAccountNumber: ['101000695'],
    eobBlob: mockEobBlob
  },
  {
    paymentID: '1008',
    checkNbr: 'EFT',
    payee: 'ROBERT ANDERSON',
    paymentDate: '01/28/2008',
    checkAmt: 1560,
    startDate: '01/01/2008',
    endDate: '01/31/2008',
    paymentTypeCode: '01',
    electronicTransfer: 'No',
    payeeId: '9001686813',
    eftAccountNumber: ['101000695'],
    eobBlob: mockEobBlob
  },
  {
    paymentID: '1009',
    checkNbr: 'EFT',
    payee: 'ROBERT ANDERSON',
    paymentDate: '12/21/2007',
    checkAmt: 1560,
    startDate: '12/01/2007',
    endDate: '12/31/2007',
    paymentTypeCode: '01',
    electronicTransfer: 'No',
    payeeId: '9001686813',
    eftAccountNumber: ['101000695'],
    eobBlob: mockEobBlob
  },
  {
    paymentID: '1010',
    checkNbr: 'EFT',
    payee: 'ROBERT ANDERSON',
    paymentDate: '11/27/2007',
    checkAmt: 1560,
    startDate: '11/01/2007',
    endDate: '11/30/2007',
    paymentTypeCode: '01',
    electronicTransfer: 'No',
    payeeId: '9001686813',
    eftAccountNumber: ['101000695'],
    eobBlob: mockEobBlob
  },
  {
    paymentID: '1011',
    checkNbr: 'EFT',
    payee: 'ROBERT ANDERSON',
    paymentDate: '10/26/2007',
    checkAmt: 1560,
    startDate: '10/01/2007',
    endDate: '10/31/2007',
    paymentTypeCode: '01',
    electronicTransfer: 'No',
    payeeId: '9001686813',
    eftAccountNumber: ['101000695'],
    eobBlob: mockEobBlob
  },
  {
    paymentID: '1012',
    checkNbr: 'EFT',
    payee: 'ROBERT ANDERSON',
    paymentDate: '09/25/2007',
    checkAmt: 1560,
    startDate: '09/01/2007',
    endDate: '09/30/2007',
    paymentTypeCode: '01',
    electronicTransfer: 'No',
    payeeId: '9001686813',
    eftAccountNumber: ['101000695'],
    eobBlob: mockEobBlob
  },
  {
    paymentID: '1013',
    checkNbr: 'EFT',
    payee: 'ROBERT ANDERSON',
    paymentDate: '08/28/2007',
    checkAmt: 1560,
    startDate: '08/01/2007',
    endDate: '08/31/2007',
    paymentTypeCode: '01',
    electronicTransfer: 'No',
    payeeId: '9001686813',
    eftAccountNumber: ['101000695'],
    eobBlob: mockEobBlob
  },
  {
    paymentID: '1014',
    checkNbr: 'EFT',
    payee: 'ROBERT ANDERSON',
    paymentDate: '07/26/2007',
    checkAmt: 1560,
    startDate: '07/01/2007',
    endDate: '07/31/2007',
    paymentTypeCode: '01',
    electronicTransfer: 'No',
    payeeId: '9001686813',
    eftAccountNumber: ['101000695'],
    eobBlob: mockEobBlob
  },
  {
    paymentID: '1015',
    checkNbr: 'EFT',
    payee: 'ROBERT ANDERSON',
    paymentDate: '06/26/2007',
    checkAmt: 1560,
    startDate: '06/01/2007',
    endDate: '06/30/2007',
    paymentTypeCode: '01',
    electronicTransfer: 'No',
    payeeId: '9001686813',
    eftAccountNumber: ['101000695'],
    eobBlob: mockEobBlob
  },
  {
    paymentID: '1016',
    checkNbr: 'EFT',
    payee: 'ROBERT ANDERSON',
    paymentDate: '05/25/2007',
    checkAmt: 1560,
    startDate: '05/01/2007',
    endDate: '05/31/2007',
    paymentTypeCode: '01',
    electronicTransfer: 'No',
    payeeId: '9001686813',
    eftAccountNumber: ['101000695'],
    eobBlob: mockEobBlob
  },
  {
    paymentID: '1017',
    checkNbr: 'EFT',
    payee: 'ROBERT ANDERSON',
    paymentDate: '04/25/2007',
    checkAmt: 1560,
    startDate: '04/01/2007',
    endDate: '04/30/2007',
    paymentTypeCode: '01',
    electronicTransfer: 'No',
    payeeId: '9001686813',
    eftAccountNumber: ['101000695'],
    eobBlob: mockEobBlob
  },
  {
    paymentID: '1018',
    checkNbr: 'EFT',
    payee: 'ROBERT ANDERSON',
    paymentDate: '03/27/2007',
    checkAmt: 1560,
    startDate: '03/01/2007',
    endDate: '03/31/2007',
    paymentTypeCode: '01',
    electronicTransfer: 'No',
    payeeId: '9001686813',
    eftAccountNumber: ['101000695'],
    eobBlob: mockEobBlob
  },
  {
    paymentID: '1019',
    checkNbr: 'EFT',
    payee: 'ROBERT ANDERSON',
    paymentDate: '02/23/2007',
    checkAmt: 1560,
    startDate: '02/01/2007',
    endDate: '02/28/2007',
    paymentTypeCode: '01',
    electronicTransfer: 'No',
    payeeId: '9001686813',
    eftAccountNumber: ['101000695'],
    eobBlob: mockEobBlob
  },
  {
    paymentID: '1020',
    checkNbr: 'EFT',
    payee: 'ROBERT ANDERSON',
    paymentDate: '01/26/2007',
    checkAmt: 1560,
    startDate: '01/01/2007',
    endDate: '01/31/2007',
    paymentTypeCode: '01',
    electronicTransfer: 'No',
    payeeId: '9001686813',
    eftAccountNumber: ['101000695'],
    eobBlob: mockEobBlob
  },
  {
    paymentID: '1021',
    checkNbr: 'EFT',
    payee: 'ROBERT ANDERSON',
    paymentDate: '12/26/2006',
    checkAmt: 1560,
    startDate: '12/01/2006',
    endDate: '12/31/2006',
    paymentTypeCode: '01',
    electronicTransfer: 'No',
    payeeId: '9001686813',
    eftAccountNumber: ['101000695'],
    eobBlob: mockEobBlob
  },
  {
    paymentID: '1022',
    checkNbr: 'EFT',
    payee: 'ROBERT ANDERSON',
    paymentDate: '11/27/2006',
    checkAmt: 1560,
    startDate: '11/01/2006',
    endDate: '11/30/2006',
    paymentTypeCode: '01',
    electronicTransfer: 'No',
    payeeId: '9001686813',
    eftAccountNumber: ['101000695'],
    eobBlob: mockEobBlob
  },
  {
    paymentID: '1023',
    checkNbr: 'EFT',
    payee: 'ROBERT ANDERSON',
    paymentDate: '10/26/2006',
    checkAmt: 1560,
    startDate: '10/01/2006',
    endDate: '10/31/2006',
    paymentTypeCode: '01',
    electronicTransfer: 'No',
    payeeId: '9001686813',
    eftAccountNumber: ['101000695'],
    eobBlob: mockEobBlob
  },
  {
    paymentID: '1024',
    checkNbr: 'EFT',
    payee: 'ROBERT ANDERSON',
    paymentDate: '09/26/2006',
    checkAmt: 1560,
    startDate: '09/01/2006',
    endDate: '09/30/2006',
    paymentTypeCode: '01',
    electronicTransfer: 'No',
    payeeId: '9001686813',
    eftAccountNumber: ['101000695'],
    eobBlob: mockEobBlob
  },
  {
    paymentID: '1025',
    checkNbr: '53424465',
    payee: 'ROBERT ANDERSON',
    paymentDate: '08/23/2006',
    checkAmt: 1560,
    startDate: '08/01/2006',
    endDate: '08/31/2006',
    paymentTypeCode: '01',
    electronicTransfer: 'No',
    payeeId: '9001686813',
    eftAccountNumber: [],
    eobBlob: mockEobBlob
  },
  {
    paymentID: '1026',
    checkNbr: '53286270',
    payee: 'ROBERT ANDERSON',
    paymentDate: '07/21/2006',
    checkAmt: 1560,
    startDate: '07/01/2006',
    endDate: '07/31/2006',
    paymentTypeCode: '01',
    electronicTransfer: 'No',
    payeeId: '9001686813',
    eftAccountNumber: [],
    eobBlob: mockEobBlob
  },
  {
    paymentID: '1027',
    checkNbr: '53163103',
    payee: 'ROBERT ANDERSON',
    paymentDate: '06/22/2006',
    checkAmt: 1560,
    startDate: '06/01/2006',
    endDate: '06/30/2006',
    paymentTypeCode: '01',
    electronicTransfer: 'No',
    payeeId: '9001686813',
    eftAccountNumber: [],
    eobBlob: mockEobBlob
  },
  {
    paymentID: '106134189',
    checkNbr: '53031052',
    payee: 'ROBERT ANDERSON',
    paymentDate: '05/22/2006',
    checkAmt: 1560,
    startDate: '05/01/2006',
    endDate: '05/31/2006',
    paymentTypeCode: '01',
    electronicTransfer: 'No',
    payeeId: '9001686813',
    eftAccountNumber: [],
    eobBlob: mockEobBlob
  },
  {
    paymentID: '104604992',
    checkNbr: '52904077',
    payee: 'ROBERT ANDERSON',
    paymentDate: '04/20/2006',
    checkAmt: 1560,
    startDate: '04/01/2006',
    endDate: '04/30/2006',
    paymentTypeCode: '01',
    electronicTransfer: 'No',
    payeeId: '9001686813',
    eftAccountNumber: [],
    eobBlob: mockEobBlob
  },
  {
    paymentID: '16',
    checkNbr: '01661442',
    payee: 'Robert Anderson',
    paymentDate: '03/15/2006',
    checkAmt: 1560,
    startDate: '03/01/2006',
    endDate: '03/30/2006',
    paymentTypeCode: '01',
    electronicTransfer: 'No',
    payeeId: '9001686813',
    eftAccountNumber: [],
    eobBlob: mockEobBlob
  },
  {
    paymentID: '15',
    checkNbr: '01651529',
    payee: 'Robert Anderson',
    paymentDate: '02/17/2006',
    checkAmt: 1248,
    startDate: '02/07/2006',
    endDate: '02/28/2006',
    paymentTypeCode: '01',
    electronicTransfer: 'No',
    payeeId: '9001686813',
    eftAccountNumber: [],
    eobBlob: mockEobBlob
  },
  {
    paymentID: '14',
    checkNbr: '01641132',
    payee: 'Robert Anderson',
    paymentDate: '01/27/2006',
    checkAmt: 1560,
    startDate: '01/07/2006',
    endDate: '02/06/2006',
    paymentTypeCode: '01',
    electronicTransfer: 'No',
    payeeId: '9001686813',
    eftAccountNumber: [],
    eobBlob: mockEobBlob
  },
  {
    paymentID: '13',
    checkNbr: '01631523',
    payee: 'Robert Anderson',
    paymentDate: '01/10/2006',
    checkAmt: 1560,
    startDate: '12/07/2005',
    endDate: '01/06/2006',
    paymentTypeCode: '01',
    electronicTransfer: 'No',
    payeeId: '9001686813',
    eftAccountNumber: [],
    eobBlob: mockEobBlob
  },
  {
    paymentID: '12',
    checkNbr: '01603364',
    payee: 'Robert Anderson',
    paymentDate: '11/23/2005',
    checkAmt: 1560,
    startDate: '11/07/2005',
    endDate: '12/06/2005',
    paymentTypeCode: '01',
    electronicTransfer: 'No',
    payeeId: '9001686813',
    eftAccountNumber: [],
    eobBlob: mockEobBlob
  },
  {
    paymentID: '11',
    checkNbr: '01578883',
    payee: 'Robert Anderson',
    paymentDate: '10/27/2005',
    checkAmt: 1560,
    startDate: '10/07/2005',
    endDate: '11/06/2005',
    paymentTypeCode: '01',
    electronicTransfer: 'No',
    payeeId: '9001686813',
    eftAccountNumber: [],
    eobBlob: mockEobBlob
  },
  {
    paymentID: '10',
    checkNbr: '01550633',
    payee: 'Robert Anderson',
    paymentDate: '09/26/2005',
    checkAmt: 1560,
    startDate: '09/07/2005',
    endDate: '10/06/2005',
    paymentTypeCode: '01',
    electronicTransfer: 'No',
    payeeId: '9001686813',
    eftAccountNumber: [],
    eobBlob: mockEobBlob
  },
  {
    paymentID: '9',
    checkNbr: '01523389',
    payee: 'Robert Anderson',
    paymentDate: '08/26/2005',
    checkAmt: 1560,
    startDate: '08/07/2005',
    endDate: '09/06/2005',
    paymentTypeCode: '01',
    electronicTransfer: 'No',
    payeeId: '9001686813',
    eftAccountNumber: [],
    eobBlob: mockEobBlob
  },
  {
    paymentID: '8',
    checkNbr: '01514328',
    payee: 'Robert Anderson',
    paymentDate: '08/17/2005',
    checkAmt: 1560,
    startDate: '07/07/2005',
    endDate: '08/06/2005',
    paymentTypeCode: '01',
    electronicTransfer: 'No',
    payeeId: '9001686813',
    eftAccountNumber: [],
    eobBlob: mockEobBlob
  },
  {
    paymentID: '7',
    checkNbr: '01487867',
    payee: 'Robert Anderson',
    paymentDate: '07/20/2005',
    checkAmt: 1560,
    startDate: '06/07/2005',
    endDate: '07/06/2005',
    paymentTypeCode: '01',
    electronicTransfer: 'No',
    payeeId: '9001686813',
    eftAccountNumber: [],
    eobBlob: mockEobBlob
  },
  {
    paymentID: '6',
    checkNbr: '01441061',
    payee: 'Robert Anderson',
    paymentDate: '05/27/2005',
    checkAmt: 1560,
    startDate: '05/07/2005',
    endDate: '06/06/2005',
    paymentTypeCode: '01',
    electronicTransfer: 'No',
    payeeId: '9001686813',
    eftAccountNumber: [],
    eobBlob: mockEobBlob
  },
  {
    paymentID: '5',
    checkNbr: '01411851',
    payee: 'Robert Anderson',
    paymentDate: '04/26/2005',
    checkAmt: 1560,
    startDate: '04/07/2005',
    endDate: '05/06/2005',
    paymentTypeCode: '01',
    electronicTransfer: 'No',
    payeeId: '9001686813',
    eftAccountNumber: [],
    eobBlob: mockEobBlob
  },
  {
    paymentID: '4',
    checkNbr: '01383010',
    payee: 'Robert Anderson',
    paymentDate: '03/25/2005',
    checkAmt: 1560,
    startDate: '03/07/2005',
    endDate: '04/06/2005',
    paymentTypeCode: '01',
    electronicTransfer: 'No',
    payeeId: '9001686813',
    eftAccountNumber: [],
    eobBlob: mockEobBlob
  },
  {
    paymentID: '3',
    checkNbr: '01355239',
    payee: 'Robert Anderson',
    paymentDate: '02/24/2005',
    checkAmt: 1560,
    startDate: '02/07/2005',
    endDate: '03/06/2005',
    paymentTypeCode: '01',
    electronicTransfer: 'No',
    payeeId: '9001686813',
    eftAccountNumber: [],
    eobBlob: mockEobBlob
  }
]
};


export const SINGLE_PAYMENT_DETAILS_MOCK: ClaimPaymentDetailsResponse = {
    errorList: null,
    claimPaymentDetailRes: [   {
      grossAmt: 6738.54,
      assignedPayment: 0,
      offsets: [      {
        deductionAmount: 2616,
        negativeDeductionAmount: -2616,
        description: 'PRIMARY SOCIAL SECURITY DISABILITY'
      }],
      taxDeductions: [      {
        deductionAmount: 770,
        negativeDeductionAmount: -2616,
        description: 'FIT'
      }],
      benefitDeductions:       [
        {
          deductionAmount: 24.48,
          negativeDeductionAmount: -2616,
          description: 'DENTAL INSURANCE POST-TAX'
        },
        {
          deductionAmount: 24.48,
          negativeDeductionAmount: -2616,
          description: 'DENTAL INSURANCE POST-TAX'
        },
        {
          deductionAmount: 72.9,
          negativeDeductionAmount: -2616,
          description: 'MEDICAL INSURANCE POST-TAX'
        },
        {
          deductionAmount: 72.9,
          negativeDeductionAmount: -2616,
          description: 'MEDICAL INSURANCE POST-TAX'
        }
      ],
      claimAdjAmount: 0,
      netPayment: 4291.38,
      offsetTotal: 2616,
      totalDeductions: 964.76,
      amountsEqualTotalPay: false,
      amountsNotEqualTotalPay: true,
      claimAdjAmountNegative: false
    }]
  };


