import {Component, Input, OnInit, Output} from '@angular/core';
import {StorageManagementService} from '../../../shared/services/storage-management.service';
import {Router} from '@angular/router';
import {ContentService} from '../../../shared/services/content.service';
import {AppLabelConstants} from '../../../shared/constants/appLabel.constants';
import {SessionConstants} from '../../../shared/constants/session.constants';
import {AppConstants} from '../../../shared/constants/app.constants';
import {env} from '../../../../environments/environment-loader';
import {AetnaContactsService} from '../../../shared/services/aetnaContacts.service';
import {AetnaApsFormsService} from '../../../shared/services/aetna-aps-forms.service';
import {TaxFormConstants} from '../../tax-form/tax-form.constants';
import {ResponseErrorHandlerService} from '../../../shared/services/response-error-handler.service';

@Component({
  selector: 'app-eistimate-under-review',
  templateUrl: 'eistimate-under-review.component.html',
  styleUrls: ['eistimate-under-review.component.scss']
})
export class EistimateUnderReviewComponent implements OnInit {
   estimatedate;
   updateddate;
   claimOnlineStatus: string;
   claimOnlineSubStatus: string;
  public extensionStatusCode: string;
  public extensionSubStatusCode: string;
  public extensionStatus: string;
  public extensionSubStatus: string;
   extensionRequestedThroughDate: string;
   claimEventId: string;
   authorizedEndDate: string;
   claimOfficeId: string;
   exteReqDate: string;
   extTimeLine: string;
   exteDecisionDate: string;
   pageId;
   EURmissing = AppLabelConstants.MISSING_UI_MSG;
   EURInAdju = AppLabelConstants.UR_ADJ_MSG;
   EURNurse;
   EAPartialMsg;
   extApproveDate;
   owscContent;
   EAFullMsg;
   EDeniedMsg;
  public displayMsg: string;
   eClosedMsg;
   extensionDeniedLabel:  string;
   extensionReqLabel:  string;
   extensionURLabel:  string;
   extensionDecisionLabel:  string;
  // public extensionUIAdjStatusIcon: boolean = false;
  // public extensionUIMissStatusIcon: boolean = false;
  public apsForms: boolean = false;
   extensionAppLabel:  string;
   dateValue:  string;
  @Input() currentClaim;
  @Input() authorizedEndDateRequest;
   extensionApproveThroughDate: string;
  public showMissingInfobutton: boolean;
  public claimClosedClass: boolean = false;
  public claimClosedClassUR: boolean = false;
   goToMissingInformation_label: string;
   requestAnExtension_label: string;
   extensionDateLabel: string;
   firstEleTimeline: string;
   secondEleTimeline:  string;
   thirdEleTimeline: string;
  public showReqExtButton: boolean;
   phone_number_ext_closed;
  public appConstants = AppConstants;
  public appLabelConstants = AppLabelConstants;
  public claimNeeded;
  public baseUrl;
  public sourceSysId: string;
  public para1FirstHalf;
  public para1Link;
  public para1LastHalf;
  public personId;
  public claimId;
  public taxFormConstants = TaxFormConstants;
  @Output() loading = false;
  public apsData;
  isMissingInfoDisabled: boolean = false;
  // public rtwDate;

  constructor(private router: Router, private storageManagementService: StorageManagementService,   private _responseErrorHandlerService: ResponseErrorHandlerService, private contentService: ContentService, private aetnaApsFormsService: AetnaApsFormsService,
              private aetnaContactsService: AetnaContactsService) { }

  ngOnInit() {
    this.isMissingInfoDisabled = this.storageManagementService.getSessionData(AppConstants.IS_MISSING_INFO_DISABLED);
    this.phone_number_ext_closed = this.storageManagementService.getSessionData(SessionConstants.DISABILLITY_CLAIM_CONTACT);

    this.estimatedate = sessionStorage.getItem(SessionConstants.EXTENSION_DATE);
    this.updateddate = sessionStorage.getItem(SessionConstants.UPDATE_DATE);
    this.getSourceSysId();
    this.fetchData();
    this.fetchOWCSData();
    this.personId = this.currentClaim.personId;
    this.claimId = this.currentClaim.claimEventId;
  }



  fetchOWCSData() {
    if (this.storageManagementService.getSessionData(SessionConstants.OWCS_CONTENT)) {
      this.fetchOWCSContentFromSession();
    } else {
      this.fetchOWCSContentFromService();
    }
  }
  fetchOWCSContentFromService() {
    this.pageId = this.contentService.getPageId(AppConstants.CLAIM_DETAILS);
    if (this.pageId) {
      this.contentService.getOWCSResponse(this.pageId, AppConstants.CLAIM_DETAILS_U).subscribe(
        val => {
          if (val) {
            this.owscContent = val;
            if (this.owscContent && this.owscContent.data) {
              if (this.owscContent.data.CD_92_Body) {
                this.goToMissingInformation_label = this.owscContent.data.CD_92_Body.value;
              }
              if (this.owscContent.data.CD_41_Body) {
                this.requestAnExtension_label = this.owscContent.data.CD_41_Body.value;
              }
              if (this.owscContent.data.CD_122_Body) {
                this.exteReqDate = this.owscContent.data.CD_122_Body.value;
              }
              if (this.owscContent.data.CD_123_Body) {
                this.exteDecisionDate = this.owscContent.data.CD_123_Body.value;
              }
              if (this.owscContent.data.CD_124_Body) {
                this.extApproveDate = this.owscContent.data.CD_124_Body.value;
              }
              if (this.owscContent.data.CD_125_Body) {
                this.extTimeLine = this.owscContent.data.CD_125_Body.value;
              }

              if (this.currentClaim.sourceSystemId === AppConstants.SOURCE_SYS_ID_AA && (this.owscContent.CD_126A_Body) && this.currentClaim.coverageCategory && this.currentClaim.coverageCategory.toUpperCase() !== AppConstants.PFL) {
                this.EURmissing = this.owscContent.CD_126A_Body.value.replace(/^<div[^>]*>|<\/div>$/g, '').replace('</a>', ' ').replace('<a>', '').replace('<a href="#">', '');
                this.para1FirstHalf = this.EURmissing.substr(0, 165);
                this.para1Link = this.EURmissing.substr(166, 9);
                this.para1LastHalf = this.EURmissing.substr(176, 190);
              } else if (this.owscContent && this.owscContent.CD_126_Body && this.currentClaim.coverageCategory && this.currentClaim.coverageCategory.toUpperCase() !== AppConstants.PFL) {
                this.EURmissing = this.owscContent.CD_126_Body.value;
              } else if (this.owscContent.CD_126B_Body && this.currentClaim.coverageCategory && this.currentClaim.coverageCategory.toUpperCase() === AppConstants.PFL) {
                this.EURmissing = this.owscContent.CD_126B_Body.value;
                this.para1FirstHalf = this.EURmissing;
              }


              if (this.owscContent.data.CD_127_Body && this.currentClaim.sourceSystemId !== AppConstants.SOURCE_SYS_ID_AA) {
                this.EURInAdju = this.owscContent.data.CD_127_Body.value;
              } else if (this.owscContent.data.CD_127A_Body) {
                this.EURInAdju = this.owscContent.data.CD_127A_Body.value;
              }

              if (this.owscContent.data.CD_128_Body) {
                this.EURNurse = this.owscContent.data.CD_128_Body.value;
              }
              if (this.owscContent.data.CD_129_Body) {
                this.EAFullMsg = this.owscContent.data.CD_129_Body.value.replace('[EXTENSION_DATE]', this.extensionApproveThroughDate);
              }

              if (this.currentClaim.sourceSystemId === 'AA' && (this.owscContent.data.CD_130A_Body)) {
                this.EAPartialMsg = this.owscContent.data.CD_130A_Body.value.replace('[EXTENSION_DATE]', this.extensionApproveThroughDate)
              } else if (this.owscContent.data.CD_130_Body) {
                this.EAPartialMsg = this.owscContent.data.CD_130_Body.value.replace('[EXTENSION_DATE]', this.extensionApproveThroughDate)
              }
              if (this.currentClaim.sourceSystemId === AppConstants.SOURCE_SYS_ID_AA && (this.owscContent.data.CD_132A_Body)) {
                this.eClosedMsg = this.owscContent.data.CD_132A_Body.value;
              } else if (this.owscContent.data.CD_132_Body) {
                this.eClosedMsg = this.owscContent.data.CD_132_Body.value;
              }
              if (this.owscContent.data.CD_131_Body) {
                this.EDeniedMsg = this.owscContent.data.CD_131_Body.value;
              }

              if (this.owscContent.data.CD_125A_Body) {
                this.extensionReqLabel = this.owscContent.data.CD_125A_Body.value;
              }
              if (this.owscContent.data.CD_125B_Body) {
                this.extensionURLabel = this.owscContent.data.CD_125B_Body.value;
              }
              if (this.owscContent.data.CD_125C_Body) {
                this.extensionDecisionLabel = this.owscContent.data.CD_125C_Body.value;
              }
              if (this.owscContent.data.CD_125D_Body) {
                this.extensionAppLabel = this.owscContent.data.CD_125D_Body.value;
              }
              if (this.owscContent.data.CD_125E_Body) {
                this.extensionDeniedLabel = this.owscContent.data.CD_125E_Body.value;
              }
              this.blazeUIRules();
              this.storageManagementService.setSessionData(SessionConstants.OWCS_CONTENT , this.owscContent.data);
            }
          }

        }, (error) => {
          //console.error(error);
        });
    }
  }

  fetchOWCSContentFromSession() {
    this.baseUrl = env.cxrBase;
    this.owscContent = this.storageManagementService.getSessionData(SessionConstants.OWCS_CONTENT);
    if (this.owscContent) {
      if (this.owscContent && this.owscContent.CD_92_Body) {
        this.goToMissingInformation_label = this.owscContent.CD_92_Body.value;
      }
      if (this.owscContent && this.owscContent.CD_41_Body) {
        this.requestAnExtension_label = this.owscContent.CD_41_Body.value;
      }
      if (this.owscContent && this.owscContent.CD_122_Body) {
        this.exteReqDate = this.owscContent.CD_122_Body.value;
      }
      if (this.owscContent && this.owscContent.CD_123_Body) {
        this.exteDecisionDate = this.owscContent.CD_123_Body.value;
      }
      if (this.owscContent && this.owscContent.CD_124_Body) {
        this.extApproveDate = this.owscContent.CD_124_Body.value;
      }
      if (this.owscContent && this.owscContent.CD_125_Body) {
        this.extTimeLine = this.owscContent.CD_125_Body.value;
      }

      if (this.currentClaim.sourceSystemId === AppConstants.SOURCE_SYS_ID_AA && this.owscContent && this.owscContent.CD_126A_Body && this.currentClaim.coverageCategory && this.currentClaim.coverageCategory.toUpperCase() !== AppConstants.PFL) {
        this.EURmissing = this.owscContent.CD_126A_Body.value.replace(/^<div[^>]*>|<\/div>$/g, '').replace('</a>', ' ').replace('<a>', '').replace('<a href="#">', '');
        this.para1FirstHalf = this.EURmissing.substr(0, 165);
        this.para1Link = this.EURmissing.substr(166, 9);
        this.para1LastHalf = this.EURmissing.substr(176, 190);
        } else if (this.owscContent && this.owscContent.CD_126_Body && this.currentClaim.coverageCategory && this.currentClaim.coverageCategory.toUpperCase() !== AppConstants.PFL) {
        this.EURmissing = this.owscContent.CD_126_Body.value;
      }  else if (this.owscContent.CD_126B_Body && this.currentClaim.coverageCategory && this.currentClaim.coverageCategory.toUpperCase() === AppConstants.PFL) {
        this.EURmissing = this.owscContent.CD_126B_Body.value;
        this.para1FirstHalf = this.EURmissing;
      }

        if (this.owscContent && this.owscContent.CD_127_Body && this.currentClaim.sourceSystemId !== AppConstants.SOURCE_SYS_ID_AA) {
        this.EURInAdju = this.owscContent.CD_127_Body.value;
      } else if (this.owscContent && this.owscContent.CD_127A_Body) {
        this.EURInAdju = this.owscContent.CD_127A_Body.value;
      }

      if (this.owscContent && this.owscContent.CD_128_Body) {
        this.EURNurse = this.owscContent.CD_128_Body.value;
      }
      if (this.owscContent && this.owscContent.CD_129_Body) {
        this.EAFullMsg = this.owscContent.CD_129_Body.value.replace('[EXTENSION_DATE]', this.extensionApproveThroughDate);
      }
      if (this.owscContent && this.currentClaim.sourceSystemId === AppConstants.SOURCE_SYS_ID_AA && (this.owscContent.CD_130A_Body)) {
        this.EAPartialMsg = this.owscContent.CD_130A_Body.value.replace('[EXTENSION_DATE]', this.extensionApproveThroughDate)
      } else if (this.owscContent && this.owscContent.CD_130_Body) {
        this.EAPartialMsg = this.owscContent.CD_130_Body.value.replace('[EXTENSION_DATE]', this.extensionApproveThroughDate)
      }
      if (this.owscContent && this.currentClaim.sourceSystemId === AppConstants.SOURCE_SYS_ID_AA && (this.owscContent.CD_132A_Body)) {
        this.eClosedMsg = this.owscContent.CD_132A_Body.value;
      } else if (this.owscContent && this.owscContent.CD_132_Body) {
        this.eClosedMsg = this.owscContent.CD_132_Body.value;
      }
      if (this.owscContent && this.owscContent.CD_131_Body) {
        this.EDeniedMsg = this.owscContent.CD_131_Body.value;
      }

      if (this.owscContent && this.owscContent.CD_125A_Body) {
        this.extensionReqLabel = this.owscContent.CD_125A_Body.value;
      }
      if (this.owscContent && this.owscContent.CD_125B_Body) {
        this.extensionURLabel = this.owscContent.CD_125B_Body.value;
      }
      if (this.owscContent && this.owscContent.CD_125C_Body) {
        this.extensionDecisionLabel = this.owscContent.CD_125C_Body.value;
      }
      if (this.owscContent && this.owscContent.CD_125D_Body) {
        this.extensionAppLabel = this.owscContent.CD_125D_Body.value;
      }
      if (this.owscContent && this.owscContent.CD_125E_Body) {
        this.extensionDeniedLabel = this.owscContent.CD_125E_Body.value;
      }
      this.blazeUIRules();
    }
  }

  fetchData() {
    if (this.currentClaim !== null || this.currentClaim.length >= 0) {
      this.claimOnlineStatus = this.currentClaim.claimOnlineStatus;
      this.claimOnlineSubStatus = this.currentClaim.claimOnlineSubStatus;
      this.extensionStatus = this.currentClaim.extensionStatus;
      this.extensionSubStatus = this.currentClaim.extensionSubStatus;
      this.extensionStatusCode = this.currentClaim.extensionStatusCode;
      this.extensionSubStatusCode = this.currentClaim.extensionSubStatusCode;
      this.claimEventId = this.currentClaim.claimEventId;
      this.extensionRequestedThroughDate = this.storageManagementService.getSessionData(SessionConstants.REQUEST_EXTENSION_DATE) ?
        (this.storageManagementService.getSessionData(SessionConstants.REQUEST_EXTENSION_DATE)) : this.currentClaim.extensionRequestDate;
      this.authorizedEndDate = this.currentClaim.authorizedEndDate;
      this.claimOfficeId =  this.currentClaim.claimOfficeId;
      this.extensionApproveThroughDate = this.authorizedEndDate;
      // this.rtwDate = sessionStorage.getItem(SessionConstants.RTW_DATE);
    }
  }


  blazeUIRules() {
    if (((this.extensionStatusCode === AppConstants.EXTENSION_UNDER_REVIEW_CD_1) && (this.extensionSubStatusCode === AppConstants.EXT_MISSING_INFO_CD_1))
      && ((this.currentClaim.sourceSystemId === AppConstants.SOURCE_SYS_ID_DCS) || (this.currentClaim.sourceSystemId === AppConstants.SOURCE_SYS_ID_AA &&
        this.currentClaim.extensionRequestDate !== null))) {
      this.extensionDateLabel = this.exteReqDate;
      this.dateValue = this.extensionRequestedThroughDate;
      this.firstEleTimeline = this.extensionReqLabel;
      this.secondEleTimeline = this.extensionURLabel;
      this.thirdEleTimeline = this.extensionDecisionLabel;
      this.displayMsg = this.EURmissing;
      // this.showMissingInfobutton = true;
      this.claimClosedClassUR = true;
      this.checkMissingInfo();
    } else if (((this.extensionStatusCode === AppConstants.EXTENSION_UNDER_REVIEW_CD_1) && (this.extensionSubStatusCode === AppConstants.EXT_MISSING_INFO_CD_1))
      && (this.currentClaim.sourceSystemId === AppConstants.SOURCE_SYS_ID_AA && this.currentClaim.extensionRequestDate === null)) {
      this.extensionDateLabel = this.appLabelConstants.EXTENSION_REQUESTED;
      this.dateValue = '';
      this.firstEleTimeline = this.extensionReqLabel;
      this.secondEleTimeline = this.extensionURLabel;
      this.thirdEleTimeline = this.extensionDecisionLabel;
      this.displayMsg = this.EURmissing;
      this.claimClosedClassUR = true;
      this.checkMissingInfo();
    } else if (((this.extensionStatusCode === AppConstants.EXTENSION_UNDER_REVIEW_CD_1) && (this.extensionSubStatusCode === AppConstants.EXT_IN_ADJUDICATION_CD_2))
      && ((this.currentClaim.sourceSystemId === AppConstants.SOURCE_SYS_ID_DCS) || (this.currentClaim.sourceSystemId === AppConstants.SOURCE_SYS_ID_AA &&
        this.currentClaim.extensionRequestDate !== null))) {
      this.extensionDateLabel = this.exteReqDate;
      this.dateValue = this.extensionRequestedThroughDate;
      this.firstEleTimeline = this.extensionReqLabel;
      this.secondEleTimeline = this.extensionURLabel;
      this.thirdEleTimeline = this.extensionDecisionLabel;
      this.displayMsg = this.EURInAdju;
    } else if (((this.extensionStatusCode === AppConstants.EXTENSION_UNDER_REVIEW_CD_1) && (this.extensionSubStatusCode === AppConstants.EXT_IN_ADJUDICATION_CD_2))
      && (this.currentClaim.sourceSystemId === AppConstants.SOURCE_SYS_ID_AA &&
        this.currentClaim.extensionRequestDate === null)) {
      this.extensionDateLabel = this.appLabelConstants.EXTENSION_REQUESTED;
      this.dateValue = '';
      this.firstEleTimeline = this.extensionReqLabel;
      this.secondEleTimeline = this.extensionURLabel;
      this.thirdEleTimeline = this.extensionDecisionLabel;
      this.displayMsg = this.EURInAdju;
    } else if (this.extensionStatusCode === AppConstants.EXTENSION_UNDER_REVIEW_CD_1 && this.extensionSubStatusCode === AppConstants.EXT_UNDER_REVIEW_BY_A_NURSE_CD_3) {
      this.extensionDateLabel = this.exteReqDate;
      this.dateValue = this.extensionRequestedThroughDate;
      this.firstEleTimeline = this.extensionReqLabel;
      this.secondEleTimeline = this.extensionURLabel;
      this.thirdEleTimeline = this.extensionDecisionLabel;
      this.displayMsg = this.EURNurse;
    } else if (this.extensionStatusCode === AppConstants.EXTENSION_APPROVED_CD_2 && this.extensionSubStatusCode === AppConstants.EXT_FULL_APPROVAL_CD_5) {
      this.extensionDateLabel = this.extApproveDate;
      this.dateValue = this.extensionApproveThroughDate;
      this.firstEleTimeline = this.extensionReqLabel;
      this.secondEleTimeline = this.extensionURLabel;
      this.thirdEleTimeline = this.extensionDecisionLabel;
      this.displayMsg = this.EAFullMsg;
    } else if (this.extensionStatusCode === AppConstants.EXTENSION_APPROVED_CD_2 && this.extensionSubStatusCode === AppConstants.EXT_PARTIAL_APPROVAL_CD_4) {
      this.extensionDateLabel = this.extApproveDate;
      this.dateValue = this.extensionApproveThroughDate;
      this.firstEleTimeline = this.extensionReqLabel;
      this.secondEleTimeline = this.extensionURLabel;
      this.thirdEleTimeline = this.extensionDecisionLabel;
      this.displayMsg = this.EAPartialMsg;
      //this.showReqExtButton = true;
      //remove after fice business days
    } else if (this.extensionStatusCode === AppConstants.EXTENSION_DENIED_CD_3 && this.extensionSubStatusCode === AppConstants.EXT_EXTENSION_DENIED_CD_6) {
      this.extensionDateLabel = this.exteDecisionDate;
      this.dateValue = this.extensionApproveThroughDate;
      this.firstEleTimeline = this.extensionReqLabel;
      this.secondEleTimeline = this.extensionURLabel;
      this.thirdEleTimeline = this.extensionDecisionLabel;
      this.displayMsg = this.EDeniedMsg;
    } else if (( this.currentClaim.sourceSystemId === AppConstants.SOURCE_SYS_ID_DCS && this.extensionStatusCode === AppConstants.EXTENSION_CLOSED_CD_4
        && this.extensionSubStatusCode === AppConstants.EXT_MISSING_INFO_CD_1 )
        || (this.currentClaim.sourceSystemId === AppConstants.SOURCE_SYS_ID_DCS && this.extensionStatusCode === AppConstants.EXTENSION_CLOSED_CD_4 &&
          this.extensionSubStatusCode === AppConstants.EXT_EXTENSION_MISSING_INFO_CD_8 ) || (this.currentClaim.sourceSystemId === AppConstants.SOURCE_SYS_ID_AA && this.currentClaim.extensionRequestDate !== null &&
        this.extensionStatusCode === AppConstants.EXTENSION_CLOSED_CD_4
        && this.extensionSubStatusCode === AppConstants.EXT_MISSING_INFO_CD_1 ) || (this.currentClaim.sourceSystemId === AppConstants.SOURCE_SYS_ID_AA && this.currentClaim.extensionRequestDate !== null && this.extensionStatusCode === AppConstants.EXTENSION_CLOSED_CD_4 &&
        this.extensionSubStatusCode === AppConstants.EXT_EXTENSION_MISSING_INFO_CD_8 )) {
      this.extensionDateLabel = this.exteReqDate;
      this.dateValue = this.extensionRequestedThroughDate;
      this.displayMsg = this.eClosedMsg;
      this.claimClosedClass = true;
      this.checkMissingInfo();
    } else if ((this.currentClaim.sourceSystemId === AppConstants.SOURCE_SYS_ID_AA &&
        this.currentClaim.extensionRequestDate === null && this.extensionStatusCode === AppConstants.EXTENSION_CLOSED_CD_4
        && this.extensionSubStatusCode === AppConstants.EXT_MISSING_INFO_CD_1 ) || (this.currentClaim.sourceSystemId === AppConstants.SOURCE_SYS_ID_AA &&
        this.currentClaim.extensionRequestDate === null && this.extensionStatusCode === AppConstants.EXTENSION_CLOSED_CD_4 &&
        this.extensionSubStatusCode === AppConstants.EXT_EXTENSION_MISSING_INFO_CD_8 )) {
      this.extensionDateLabel = this.appLabelConstants.EXTENSION_REQUESTED;
      this.dateValue = '';
      this.displayMsg = this.eClosedMsg;
        this.claimClosedClass = true;
      this.checkMissingInfo();
    }
  }

  checkMissingInfo() {
    if ((this.sourceSysId === this.appConstants.SOURCE_SYS_ID_DCS && this.currentClaim && this.currentClaim.missingInfo && this.currentClaim.missingInfo.length > 0)
      ||  ((this.sourceSysId === this.appConstants.SOURCE_SYS_ID_AA && this.currentClaim && this.currentClaim.missingInfo && this.currentClaim.missingInfo.length > 0)
        && ((this.extensionStatusCode !== this.appConstants.EXTENSION_CLOSED_CD_4 || this.extensionStatusCode !== this.appConstants.EXTENSION_UNDER_REVIEW_CD_1) && (this.extensionSubStatusCode !== this.appConstants.EXT_MISSING_INFO_CD_1)))) {
      this.showMissingInfobutton = true;
    } else {
      this.showMissingInfobutton = false;
    }
  }
  /*
   Replacement of the above navigateTo()
   Scrolls if accessed from another page.
   Still a hack for the demo.
   */
  navigateTo(location, curClaim, claimEventId, authorizedEndDate, claimOfficeId) {
    const el = document.getElementById(AppConstants.MISSING_INFO_HEADER);
    sessionStorage.setItem(SessionConstants.MISSING_INF_DIV , SessionConstants.MISSING_INF_DIV);
    if ( el ) {
      el.scrollIntoView();
    } else {
      this.storageManagementService.setSessionObject(SessionConstants.CUR_CLAIM , curClaim);
      this.storageManagementService.setSessionObject(SessionConstants.CLAIM_EVENT_ID, claimEventId);
      this.storageManagementService.setSessionObject(SessionConstants.AUTHORIZED_END_DATE, authorizedEndDate);
      this.storageManagementService.setSessionObject(SessionConstants.CLAIM_OFFICE_ID, claimOfficeId);
      this.router.navigate(['/' + location], { queryParams: { scrollTo: AppConstants.MISSING_INFO_HEADER } });
    }

  }

  navToRequextension() {
    if (this.sourceSysId === this.appConstants.SOURCE_SYS_ID_DCS) {
      sessionStorage.removeItem(SessionConstants.EXTENSION_DATE);
      sessionStorage.setItem(SessionConstants.APPROVED_DATE, this.authorizedEndDateRequest);
      this.router.navigate([AppConstants.URL_REQUEST_EXTENSION]);

    } else if (this.currentClaim.sourceSystemId === this.appConstants.SOURCE_SYS_ID_AA) {
      sessionStorage.removeItem(SessionConstants.EXTENSION_DATE);
      sessionStorage.setItem(SessionConstants.APPROVED_DATE, this.authorizedEndDateRequest);
      this.router.navigate([AppConstants.URL_REQUEST_EXTENSION_THAA]);
    }
  }
  getSourceSysId() {
    if (this.currentClaim !== null || this.currentClaim.length >= 0) {
      this.sourceSysId = this.currentClaim.sourceSystemId;
    }
  }

  getApsForm() {
    this.loading = true;
    if ((this.extensionStatusCode === this.appConstants.EXTENSION_UNDER_REVIEW_CD_01 && this.extensionSubStatusCode === this.appConstants.EXT_MISSING_INFO_CD_01)
      || this.extensionStatusCode === this.appConstants.EXTENSION_UNDER_REVIEW_CD_1 && this.extensionSubStatusCode === this.appConstants.EXT_MISSING_INFO_CD_1) {
      this.aetnaApsFormsService.thaaGetAPS(this.personId, this.claimId).subscribe(
        data => {
          if (data && data.LetterBytes) {
            this.apsData = data;
            this.loading = false;
            const mimeType = 'application/pdf';
            const res = this.aetnaApsFormsService.b64toBlob(this.apsData.LetterBytes, mimeType, 512);
            const blob = new Blob([res], {type: mimeType});
            const fileObjectURL = URL.createObjectURL(blob);

            if (window.navigator.appVersion.toString().indexOf('.NET') > 0) { // for IE browser
              const nav = (window.navigator as any);
              if (nav.msSaveOrOpenBlob) {
                nav.msSaveOrOpenBlob(blob, fileObjectURL);
              }
            } else {
              this.loading = false;
              const link = document.createElement('a');
            link.href = fileObjectURL;
            link.download = 'APS Form ' + this.claimId;
            link.click();
            }
          } else {
            this.loading = false;
          }
        }, (error) => {
          this._responseErrorHandlerService.handleThaaErrorForSubmit(Error);
          this.loading = false;
        }
      )
    }
  }

}
