export class LoginRouting {
  constructor(private reRoute: boolean, private newRoute: string) {

  }

  reRouting(): boolean {
    return this.reRoute;
  }

  newRouting(): string {
    return this.newRoute;
  }
}
