import {Environment} from './environment'
import {EnvironmentConfig} from './env.config'
import { AppConstants } from '@app/shared/constants/app.constants';

export class EnvironmentLoader {

  static getEnvironmentFile(): any {
    const url = document.location.hostname;
    let mockingSet = false;
    if (sessionStorage.getItem('mocking') ) {
      mockingSet = sessionStorage.getItem('mocking') === 'true' ? true : false;
    }
    const clientId: Map<string, string> = new Map([
      ['auth', '1009']
    ]);
    const prodState = true;
    const hitQA = true;
    const proxyState = false; //False = JBOSS & true = Proxy 45
    let envKey = AppConstants.LOCAL;
    const baseEnvConfig = new EnvironmentConfig().baseEnvConfig;


    // if (!hitQA) {
    //   envKey = 'localDev';
    // } else {
    //   envKey = 'localQa';
    // }

    // //Iterate through the array and check if the URL includes 'mbUrlChunk' or 'impersonateUrlChunk'
    // Object.keys(baseEnvConfig).map(key => {
    //   if (url.includes(baseEnvConfig[key]['mbUrlChunk']) || url.includes(baseEnvConfig[key]['impersonateUrlChunk'])) {
    //     envKey = key;
    //   }
    // });

    if (url.includes('ltd-cxr') || url.includes('ltd-internaladmin-mybenefits')) {
      envKey = 'ltd';
    } else if (url.includes('ltd2-cxr') || url.includes('ltd2-internaladmin-mybenefits')) {
      envKey = 'ltd2';
    } else if (url.includes('ltd3-cxr') || url.includes('ltd3-internaladmin-mybenefits')) {
      envKey = 'ltd3';
    } else if (url.includes('lqa-cxr') || url.includes('lqa-internaladmin-mybenefits')) {
      envKey = 'lqa';
    } else if (url.includes('lqa2-cxr') || url.includes('lqa2-internaladmin-mybenefits')) {
      envKey = 'lqa2';
    } else if (url.includes('lqa3-cxr') || url.includes('lqa3-internaladmin-mybenefits')) {
      envKey = 'lqa3';
    } else if (url.includes('qa3-cxr') || url.includes('qa3-internaladmin-mybenefits')) {
      envKey = 'qa3';
    } else if (url.includes('qa-cxr') || url.includes('qa-internaladmin-mybenefits')) {
      envKey = 'qa';
    } else if (url.includes('pte-cxr') || url.includes('pte-internaladmin-mybenefits')) {
      envKey = 'pte';
    } else if (url.includes('mybenefits') || url.includes('internaladmin-mybenefits')) {
      envKey = 'prod';
    } else {
      if ( hitQA ) {
        envKey = AppConstants.LOCAL_QA;
      }
    }

    return new Environment(clientId, baseEnvConfig[envKey]['dpUrl'], baseEnvConfig[envKey]['cxrBase'], baseEnvConfig[envKey]['thaaBase'], baseEnvConfig[envKey]['envName'], mockingSet, prodState, baseEnvConfig[envKey]['proxyState'], baseEnvConfig[envKey]['ciamAccountUrl'], baseEnvConfig[envKey]['sfdAccountUrl'], hitQA, baseEnvConfig[envKey]['psvCdnUrl'], baseEnvConfig[envKey]['psvGoogleAnalyticKey'],baseEnvConfig[envKey]['recaptchaKey'], baseEnvConfig[envKey]['ciamApiSgwUrl']).getEnv();
  }
}
export const env: any = EnvironmentLoader.getEnvironmentFile();
