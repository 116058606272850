import {Component, EventEmitter, Input, Output, OnInit} from '@angular/core';
import {AppLeaveIntakeConstants} from '../../../../leave-claim-intake-flow/leave-intake.constants';
import {FormControl, FormGroup} from '@angular/forms';
import {AppLabelConstants} from '../../../../../shared/constants/appLabel.constants';
import {CustomEventService} from '../../../../../shared/services/tealium/custom-event.service';

@Component({
  selector: 'app-hours-mins-multi-dropdown',
  templateUrl: './hours-mins-multi-dropdown.component.html',
  styleUrls: ['./hours-mins-multi-dropdown.component.scss']
})
export class HoursMinsMultiDropdownComponent implements OnInit {
  @Input() durationLabel: string;
  @Input() selectedDuration;
  @Input() required: boolean = false;
  @Input() invalidDuration: boolean = false;
  @Input() disableDuration: boolean = false;
  @Input() errorMessage: string = AppLeaveIntakeConstants.PLEASE_ANS;
  @Output() durationValue = new EventEmitter();
  public leaveIntakeConstant = AppLeaveIntakeConstants;
  public appLabelConstant = AppLabelConstants;
  public hourOptions = [];
  public minuteOptions = [];
  public hoursLabel = this.appLabelConstant.HH_TEXT;
  public minutesLabel = this.appLabelConstant.MIN_TEXT;
  public hours24 = '24 ' + this.hoursLabel;
  public hourszero = '0 ' + this.hoursLabel;
  public minutesZero = '0 ' + this.minutesLabel;
  public durationForm;
  public showError: boolean = false;
  public disableMinutes: boolean = false;

  constructor(private customEventService: CustomEventService) {}

  ngOnInit() {
    this.createAndSetDurationForm();
  }

  createAndSetDurationForm() {
    if ( !this.selectedDuration ) {
      this.selectedDuration = {
        [this.hoursLabel] : this.hoursLabel,
        [this.minutesLabel] : this.minutesLabel
      }
    }
    const formControls = {};
    for ( const field of Object.keys(this.selectedDuration) ) {
      formControls[field] = new FormControl(this.selectedDuration[field]);
    }
    this.durationForm = new FormGroup(formControls);

    for (let i = 0; i <= 24; i++) {
      this.hourOptions[i] = i === 1 ?  `${i}` + ' ' + this.appLabelConstant.HH_HOUR_TEXT :  `${i}` + ' ' + this.hoursLabel;
    }
    for (let i = 0; i < 60; i++) {
      this.minuteOptions[i] = i < 2 ? `${i}` + ' ' + this.minutesLabel : `${i}` + ' ' + this.appLabelConstant.MINS_TEXT;
    }
  }

  /**
   * function to populate the hours field in the 'selectedDuration' object
   *
   * @param hoursValue
   */
  setHoursSelected(hoursValue) {
    const selectedHours = (this.durationForm.controls[this.hoursLabel].value) ? this.durationForm.controls[this.hoursLabel].value : this.appLabelConstant.HH_TEXT;
    if (selectedHours ) {
      this.selectedDuration[this.hoursLabel] = selectedHours.trim();
      if ( this.selectedDuration[this.hoursLabel] === this.hours24 ) {
        this.disableMinutes = true;
        this.selectedDuration[this.minutesLabel] = this.minutesZero;
        this.durationForm.controls[this.minutesLabel].setValue(this.minutesZero);
      } else if ( this.selectedDuration[this.hoursLabel] === this.hourszero) {
        if ( this.minuteOptions[0] === this.minutesZero ) {
          this.minuteOptions.shift();
        }
        this.disableMinutes = false;
      } else {
        if (this.minuteOptions[0] !== this.minutesZero) {
          this.minuteOptions.unshift(this.minutesZero);
        }
        if ( this.disableMinutes ) {
          this.selectedDuration[this.minutesLabel] = this.minutesLabel;
          this.durationForm.controls[this.minutesLabel].setValue(this.minutesLabel);
        }
        this.disableMinutes = false;
      }
      this.durationValue.emit(this.selectedDuration);
      this.customEventService.trackDropdown(this.durationLabel + ' - (' + this.appLabelConstant.HOURS_TEXT + ')', this.selectedDuration[this.hoursLabel]);
    }
  }

  /**
   * function to populate the minutes field in the 'selectedDuration' object
   *
   * @param value
   */
  setMinutesSelected(minutesValue) {
    const selectedMinutes = (this.durationForm.controls[this.minutesLabel].value) ? this.durationForm.controls[this.minutesLabel].value : this.appLabelConstant.MIN_TEXT;
    if ( selectedMinutes ) {
      this.selectedDuration[this.minutesLabel] = selectedMinutes.trim();
      this.durationValue.emit(this.selectedDuration);
      this.customEventService.trackDropdown(this.durationLabel + ' - (' + this.appLabelConstant.MINUTES_TEXT + ')', this.selectedDuration[this.minutesLabel]);
    }
  }

  /**
   * function to validate the duration section by the user
   */
  validateSelectedDuration() {
    if ( this.required ) {
      if ( this.selectedDuration[this.hoursLabel].toLowerCase() !== this.hours24.toLowerCase()
        && ( this.selectedDuration[this.minutesLabel].toLowerCase() === this.minutesLabel.toLowerCase() ||
          this.selectedDuration[this.hoursLabel].toLowerCase() === this.hoursLabel.toLowerCase() || this.invalidDuration )
      ) {
        this.showError = true;
      } else if (this.selectedDuration[this.hoursLabel] && this.selectedDuration[this.minutesLabel] && !this.invalidDuration) {
        this.showError = false;
      }
    }
  }

  /**
   * to remove error on focus of any dropdown
   */
  removeError() {
    this.showError = this.showError ? !this.showError : this.showError;
  }
}
