
import {catchError, map} from 'rxjs/operators';
import {Injectable} from '@angular/core';

import {env} from '../../../environments/environment-loader';
import {ResponseErrorHandlerService} from '../services/response-error-handler.service';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {AppLabelConstants} from '../constants/appLabel.constants';

@Injectable()

export class RecentlyResolvedAetnaLeaveService {


  constructor(private errorHandler: ResponseErrorHandlerService,
              private httpClient: HttpClient) {}


  getRecentlyResolvedLeaveThaa(leaveId: string, personId: string) {
    return this.httpClient.get(env.thaaWKABPortalService.getRecentlyResolvedLeaveThaaURL + '&leaveId=' + leaveId + '&personId=' + personId,
      {
        headers: new HttpHeaders()
          .set(AppLabelConstants.CONTENT_TYPE_KEY, AppLabelConstants.APPLICATION_KEY)
      }).pipe(
      map(
        (response: Response) => {
          if (!this.errorHandler.isServiceResponseHasError(response)) {
            return response;
          } else {
            throw response;
          }
        }
      ), catchError((error: any) => this.errorHandler.handleError(error)), );
  }

}
