import {AfterViewInit, Component, OnInit} from '@angular/core';
import {ContentService} from '../../../shared/services/content.service';
import {LeaveDynatraceAction} from '../../../shared/utils/leaveDynatraceAction';
import {StorageManagementService} from '../../../shared/services/storage-management.service';
import {AppLabelConstants} from '../../../shared/constants/appLabel.constants';
import {AppConstants} from '../../../shared/constants/app.constants';

@Component({
  selector: 'app-text-tcs',
  templateUrl: 'text-tcs.component.html',
  styleUrls: ['text-tcs.component.scss']
})
export class TextTCsComponent extends LeaveDynatraceAction implements OnInit, AfterViewInit {

  pageLabel = AppLabelConstants.THE_HFG_BENEFIT_DIS_N_LEAVE;
  close = AppLabelConstants.CLOSE;
  print = AppLabelConstants.PRINT;
  //TextTCParas: string[] = [];
  private pageId: string;
  private owscContent;
  public message = '';

  constructor(private contentService: ContentService,
              private storageManagementService: StorageManagementService) {
    super(storageManagementService);
    //this.TextTCParas = termsAndConditionsService.getContent().TextTCParas;
  }

  ngOnInit() {
    //OWCS service Integration
    this.pageId = this.contentService.getPageId(AppConstants.TEXTING_TNC);
    if (this.pageId) {
      this.contentService.getOWCSResponse(this.pageId, AppConstants.TEXTING_TNC_U).subscribe(
        val => {
          if (val) {
            this.owscContent = val;
            if (this.owscContent && this.owscContent.data) {
              if (this.owscContent.data.TextingT_C_01_Body) {
                this.message = this.owscContent.data.TextingT_C_01_Body.value;
              }
            }
          }
        });
    }
  }

  ngAfterViewInit() {
    super.ngAfterViewInit();
  }
 /* clickPrint()
  {
    window.print();
  }*/

}
