/*
Vanity URLs
1. Amazon
 */
const vanityURLs = [
  {
    id: 1225118,
    urlPrefix: 'amazon'
  }
];

let vanityUrlAssigned = false;

export function getBaseLocation() {
  const foundPrefix: string[] = location.pathname.split('/').splice(1, 1);

  if (vanityURLs.filter(x => x.urlPrefix === foundPrefix[0]).length > 0) {
    vanityUrlAssigned = true;
    return '/' + foundPrefix[0];
  } else {
    vanityUrlAssigned = false;
    return '/'
  }
}


export class VanityUrlsUtility {

  static getVanityUrl(id: number, currentUrl: string) {
    return '/' +  vanityURLs.filter(x => x.id === id)[0].urlPrefix + currentUrl;
  }

  static isVanityUrlNecessary(id) {
    if (vanityURLs.filter(x => x.id === id).length > 0 && !vanityUrlAssigned ) {
      return true;
    }
    return false;
  }

}
