import {Component, ElementRef, Input, ViewChild} from '@angular/core';
import {ButtonComponent} from '../../sub-atomic/button/button.component';
import {Router} from '@angular/router';
import {DocumentService} from '@shared/services/claim-documents.service';
import {FILE_LIMITS, UploadingFiles} from '@shared/models/document.model';
import {StorageManagementService} from '@shared/services/storage-management.service';
import {AppLabelConstants} from '@shared/constants/appLabel.constants';
import {SessionConstants} from '@shared/constants/session.constants';
import {AppLeaveConst} from '@shared/constants/app.constants';
import {BehaviorSubject, Subscription} from 'rxjs';
import {ErrorConstants} from '@shared/constants/error.constants';

@Component({
  selector: 'file-upload-button',
  templateUrl: 'file-upload-button.component.html',
  styleUrls: ['file-upload-button.component.scss']
})
export class FileUploadButtonComponent extends ButtonComponent {
  @Input() elementId;
  @ViewChild('fileUploadRef', {static: true}) fileUpload: ElementRef;
  @Input() buttonText;
  @Input() buttonDisabled;
  @Input() ctaState;
  @Input() type: string = '';
  /**
   * This is the container of the files we are sending up
   * */
  containsPDFWithEmbeddedJS = new BehaviorSubject(0);
  embeddedPDFIdentificationSubscription: Subscription;
  private filesGoingUp: UploadingFiles;
  private THAAClaim: boolean = false;

  constructor(
    private router: Router,
    private docService: DocumentService,
    private storageManagementService: StorageManagementService) {
    super();
  }

  triggerUploadEvent() {
    this.fileUpload.nativeElement.click();
  }

  fileChange(event) {
    /*
     * We have a list of files that come in through a file upload event
     */
    const fileList: FileList = event.target.files;
    this.filesGoingUp = new UploadingFiles(null);
    let numberOfPDFFiles = 0;
    this.containsPDFWithEmbeddedJS.next(0);

    if (fileList.length > 0) {
      /* If we have files to be uploaded start the validation process*/
      this.validateClaimType();
      for (let i = 0; i < fileList.length; i++) {
        /*
        * We will add the file normally if it passes the check
        * The failed scenario will auto add it to the same list with the error reason
        */
        if (this.validFile(fileList.item(i))) {
          if (fileList.item(i).name.toLowerCase().includes('.pdf')) {
            numberOfPDFFiles++;
            this.checkPDFForEmbeddedJS(fileList.item(i));
          } else {
            this.filesGoingUp.addFile(fileList.item(i));
          }
        }
      }
      /* Store these selected files & send the to the wizard to complete the process*/
      if (numberOfPDFFiles === 0) {
        this.uploadFiles();
      } else {
        this.embeddedPDFIdentificationSubscription = this.containsPDFWithEmbeddedJS.subscribe(pdfFileCount => {
          if (pdfFileCount === numberOfPDFFiles) {
            this.uploadFiles();
            this.embeddedPDFIdentificationSubscription.unsubscribe();
          }
        });
      }
    }
  }

  // upload files and navigate to final upload wizard
  uploadFiles(): void {
    this.docService.storeSessionFile(this.filesGoingUp);
    this.fileUpload.nativeElement.value = null;
    this.router.navigate([AppLabelConstants.UPLOAD_DOC_WIZARD]);
  }

  /*
  * setup file reader to read pdf file and identify if it contains embedded JavaScript using the EMBEDDED_JS_CONSTANT
  * @param file: pdf file to read
  * */
  checkPDFForEmbeddedJS(file): void {
    const errorTypes: string [] = [];
    const fr = new FileReader();
    fr.readAsText(file);
    fr.onload = () => {
      let pdfFileCount = this.containsPDFWithEmbeddedJS.getValue();
      const docString = fr.result.toString();
      if (docString && (docString.includes(AppLabelConstants.EMBEDDED_JS_CONSTANT) || docString.includes(AppLabelConstants.EMBEDDED_JAVASCRIPT_CONSTANT))) {
        errorTypes.push(ErrorConstants.MALICIOUS_CONTENT_ERROR);
        this.filesGoingUp.addInvalidFile(file, errorTypes);
      } else {
        this.filesGoingUp.addFile(file);
      }
      this.containsPDFWithEmbeddedJS.next(++pdfFileCount);
    };
  }

  /**
   * To check whether the previous page was of leave\claim details and vased on that retrieve sessionData for claim/leave to validate whether it is AA/DCS supported
   */
  validateClaimType() {
    const previousPage = this.storageManagementService.getSessionData(SessionConstants.CURRENT_PAGE);
    const currentClaim = this.storageManagementService.getSessionData(SessionConstants.CUR_CLAIM);
    if (previousPage) {
    this.THAAClaim = (previousPage.includes(AppLabelConstants.URL_LEAVE_DETAILS) || (previousPage.includes(AppLabelConstants.URL_CLAIM_DETAILS) && currentClaim.sourceSystemId.toUpperCase() === AppLeaveConst.SOURCE_SYS_ID_AA)) ? true : false;
    }
  }

  /*
  * We are validating our files here. We check first that the file size is valid for an upload,
  * then we check for the type. We only show one error at a time currently
  */
  validFile(file: File): boolean {
    let hasAnError = false;
    const errorTypes: string [] = [];
    if (file.size > FILE_LIMITS.individual) {
      //console.log("File size: " + file.size + " | limit: " +FILE_LIMITS.individual );
      errorTypes.push(AppLabelConstants.SIZE_ERROR);
      hasAnError = true;
    }

    if (file.size <= FILE_LIMITS.min) {
      //console.log("File size: " + file.size + " | limit: " +FILE_LIMITS.min );
      errorTypes.push(AppLabelConstants.MIN_ERROR);
      hasAnError = true;
    }
    if (this.THAAClaim) {
      if (!this.validTHAAFileType(file)) {
        hasAnError = true;
        if ((file.name.substr(file.name.lastIndexOf('.') + 1)).toLowerCase() === AppLabelConstants.TXT_FORMAT) {
          errorTypes.push(AppLabelConstants.TXT_FORMAT);
        } else if ((file.name.substr(file.name.lastIndexOf('.') + 1)).toLowerCase() === AppLabelConstants.RTF_FORMAT) {
          errorTypes.push(AppLabelConstants.RTF_FORMAT);
        } else {
          errorTypes.push(AppLabelConstants.NOT_THAA_TYPE);
        }
      }
    } else {
      if (!this.validFileType(file)) {
        errorTypes.push(AppLabelConstants.TYPE_ERROR);
        hasAnError = true;
      }
    }
    if (hasAnError) {
      this.filesGoingUp.addInvalidFile(file, errorTypes);
    }
    return !hasAnError;
  }

  /**
   * The purpose of this method is to determine if the file is of the correct file type
   * */
  validFileType(file: File): boolean {
      return FILE_LIMITS?.allowedDCSFiles?.lastIndexOf((file?.name?.substr(file?.name?.lastIndexOf('.') + 1))?.toLowerCase()) !== -1;
  }

  validTHAAFileType(file: File): boolean {
    return FILE_LIMITS.allowedTHAAFiles.lastIndexOf((file.name.substr(file.name.lastIndexOf('.') + 1)).toLowerCase()) !== -1;
  }
}
