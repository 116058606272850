/**
 * Created by AC12394 on 10/15/2018.
 */

import {ClaimActivityHistoryResponse} from '../shared/models/claimActivityHistory.model';

export const MOCK_CLAIM_ACTIVITY_HISTORY: ClaimActivityHistoryResponse = {
  claimActivity: {
  op_ref_cusror: [
    {
      date: '08/30/2018',
      activity: 'Online Update',
      category: 'You Confirmed / Updated Info Online'
    },
    {
      date: '07/30/2018',
      activity: 'Online Update',
      category: 'You Confirmed / Updated Info Online'
    },
    {
      date: '07/30/2018',
      activity: 'Online Update',
      category: 'You Confirmed / Updated Info Online'
    },
    {
      date: '07/30/2018',
      activity: 'Online Update',
      category: 'You Confirmed / Updated Info Online'
    },
    {
      date: '07/30/2018',
      activity: 'Online Update',
      category: 'You Confirmed / Updated Info Online'
    },
    {
      date: '07/27/2018',
      activity: 'Online Update',
      category: 'You Confirmed / Updated Info Online'
    },
    {
      date: '07/23/2018',
      activity: 'Online Update',
      category: 'You Confirmed / Updated Info Online'
    },
    {
      date: '10/04/2016',
      activity: 'Fax Received',
      category: 'Attending Physician\'s Statement'
    },
    {
      date: '10/04/2016',
      activity: 'Fax Received',
      category: 'Attending Physician\'s Statement'
    },
    {
      date: '10/04/2016',
      activity: 'Phone Call',
      category: 'Physician (Outbound)'
    },
    {
      date: '09/27/2016',
      activity: 'Mail Received',
      category: 'Attending Physician\'s Statement'
    },
    {
      date: '05/06/2015',
      activity: 'Fax Received',
      category: 'Attending Physician\'s Statement'
    },
    {
      date: '05/05/2015',
      activity: 'Phone Call',
      category: 'Claimant (Outbound)'
    },
    {
      date: '04/23/2015',
      activity: 'Phone Call',
      category: 'Claimant (Outbound / Unsuccessful)'
    },
    {
      date: '03/25/2015',
      activity: 'Phone Call',
      category: 'Claimant (Inbound)'
    },
    {
      date: '11/01/2013',
      activity: 'Mail Received',
      category: 'Attending Physician\'s Statement'
    },
    {
      date: '10/29/2010',
      activity: 'Fax Received',
      category: 'Medical Records'
    },
    {
      date: '10/27/2010',
      activity: 'Phone Call',
      category: 'Physician (Outbound)'
    },
    {
      date: '10/26/2010',
      activity: 'Phone Call',
      category: 'Physician (Outbound / Unsuccessful)'
    },
    {
      date: '10/07/2010',
      activity: 'Phone Call',
      category: 'Physician (Outbound)'
    },
    {
      date: '10/07/2010',
      activity: 'Phone Call',
      category: 'Physician (Inbound)'
    },
    {
      date: '10/06/2010',
      activity: 'Phone Call',
      category: 'Medical Provider (Inbound)'
    },
    {
      date: '09/29/2010',
      activity: 'Fax Received',
      category: 'Medical Records'
    },
    {
      date: '09/29/2010',
      activity: 'Phone Call',
      category: 'Claimant (Outbound)'
    },
    {
      date: '09/29/2010',
      activity: 'Phone Call',
      category: 'Claimant (Inbound)'
    },
    {
      date: '09/28/2010',
      activity: 'Phone Call',
      category: 'Claimant (Outbound / Unsuccessful)'
    },
    {
      date: '09/28/2010',
      activity: 'Phone Call',
      category: 'Claimant (Inbound)'
    },
    {
      date: '09/27/2010',
      activity: 'Phone Call',
      category: 'Claimant (Inbound)'
    },
    {
      date: '09/27/2010',
      activity: 'Phone Call',
      category: 'Claimant (Outbound / Unsuccessful)'
    },
    {
      date: '09/20/2010',
      activity: 'Phone Call',
      category: 'Claimant (Outbound / Unsuccessful)'
    },
    {
      date: '09/14/2010',
      activity: 'Mail Received',
      category: 'Attending Physician\'s Statement'
    },
    {
      date: '09/08/2010',
      activity: 'Mail Received',
      category: 'Claim Form'
    },
    {
      date: '09/01/2010',
      activity: 'Phone Call',
      category: 'Claimant (Inbound)'
    },
    {
      date: '08/25/2010',
      activity: 'Phone Call',
      category: 'Claimant (Outbound / Unsuccessful)'
    },
    {
      date: '05/18/2010',
      activity: 'Phone Call',
      category: 'Claimant (Outbound)'
    },
    {
      date: '05/18/2010',
      activity: 'Phone Call',
      category: 'Claimant (Inbound)'
    },
    {
      date: '05/18/2010',
      activity: 'Phone Call',
      category: 'Claimant (Inbound)'
    },
    {
      date: '02/19/2010',
      activity: 'Phone Call',
      category: 'Claimant (Inbound)'
    },
    {
      date: '02/19/2010',
      activity: 'Phone Call',
      category: 'Claimant (Inbound)'
    },
    {
      date: '02/16/2010',
      activity: 'Phone Call',
      category: 'Claimant (Inbound)'
    },
    {
      date: '04/28/2008',
      activity: 'Phone Call',
      category: 'Claimant (Outbound / Unsuccessful)'
    },
    {
      date: '06/21/2007',
      activity: 'Phone Call',
      category: 'Claimant (Inbound)'
    },
    {
      date: '06/14/2007',
      activity: 'Phone Call',
      category: 'Claimant (Outbound / Unsuccessful)'
    },
    {
      date: '06/29/2006',
      activity: 'Phone Call',
      category: 'Claimant (Inbound)'
    },
    {
      date: '06/22/2006',
      activity: 'Phone Call',
      category: 'Claimant (Outbound / Unsuccessful)'
    },
    {
      date: '08/10/2005',
      activity: 'Phone Call',
      category: 'Claimant (Inbound)'
    },
    {
      date: '07/21/2005',
      activity: 'Phone Call',
      category: 'Claimant (Outbound)'
    },
    {
      date: '08/18/2004',
      activity: 'Phone Call',
      category: 'Medical Provider (Outbound)'
    },
    {
      date: '08/05/2004',
      activity: 'Phone Call',
      category: 'Claimant (Inbound)'
    },
    {
      date: '08/03/2004',
      activity: 'Phone Call',
      category: 'Claimant (Outbound)'
    },
    {
      date: '07/19/2004',
      activity: 'Phone Call',
      category: 'Claimant (Inbound)'
    },
    {
      date: '07/16/2004',
      activity: 'Phone Call',
      category: 'Claimant (Outbound)'
    },
    {
      date: '06/15/2004',
      activity: 'Phone Call',
      category: 'Claimant (Inbound)'
    },
    {
      date: '05/23/2002',
      activity: 'Phone Call',
      category: 'Medical Provider (Inbound)'
    },
    {
      date: '05/23/2002',
      activity: 'Phone Call',
      category: 'Medical Provider (Outbound)'
    },
    {
      date: '05/15/2001',
      activity: 'Phone Call',
      category: 'Hospital (Outbound)'
    },
    {
      date: '05/08/2001',
      activity: 'Phone Call',
      category: 'Hospital (Outbound)'
    },
    {
      date: '05/08/2001',
      activity: 'Phone Call',
      category: 'Hospital (Outbound)'
    },
    {
      date: '05/02/2001',
      activity: 'Phone Call',
      category: 'Hospital (Outbound)'
    },
    {
      date: '04/30/2001',
      activity: 'Phone Call',
      category: 'Hospital (Outbound)'
    },
    {
      date: '04/17/2001',
      activity: 'Phone Call',
      category: 'Medical Provider (Inbound)'
    },
    {
      date: '04/13/2001',
      activity: 'Phone Call',
      category: 'Medical Provider (Outbound)'
    },
    {
      date: '04/13/2001',
      activity: 'Phone Call',
      category: 'Medical Provider (Outbound / Unsuccessful)'
    },
    {
      date: '04/06/2001',
      activity: 'Phone Call',
      category: 'Hospital (Outbound)'
    },
    {
      date: '04/06/2001',
      activity: 'Phone Call',
      category: 'Medical Provider (Outbound / Unsuccessful)'
    },
    {
      date: '01/23/2001',
      activity: 'Phone Call',
      category: 'Claimant (Inbound)'
    }
  ]
}
}
