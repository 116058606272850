import { Injectable } from '@angular/core';
import { Router, ActivatedRouteSnapshot, RouterStateSnapshot, CanActivate } from '@angular/router';
import { StorageManagementService } from '@app/shared/services/storage-management.service';
import { SessionConstants } from '../constants/session.constants';
import { AppConstants } from '../constants/app.constants';
@Injectable()
export class RaeGuardService implements CanActivate {
    newExtension;
    isTaskPresent: boolean = false;
    isNewExtensionEnabled: boolean = false;
    isWDClaim: boolean = false;
    claimData;
    AppConstants = AppConstants;
    constructor(private router: Router, private storageManagementService: StorageManagementService) {
    }
    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
        const curClaim = JSON.parse(this.storageManagementService.retrieveSessionObject(SessionConstants.CUR_CLAIM));
        this.claimData = curClaim ? curClaim.curClaim : {};
        this.isWDClaim = this.claimData && this.claimData.coverageCategory && (this.claimData.coverageCategory.toLowerCase() === this.AppConstants.WD.toLowerCase());
        const raeToggleSession = this.storageManagementService.getSessionData(SessionConstants.FT_20211009_F12304_REQUEST_AN_EXTENSION_TOGGLE)
        this.newExtension = raeToggleSession ? raeToggleSession : {};
        if (this.newExtension) {
            this.isNewExtensionEnabled = this.newExtension.isNewExtensionEnabled ;
            this.isTaskPresent = this.newExtension.isNewExtensionTaskPresent;
        }
        if (this.isNewExtensionEnabled && !this.isTaskPresent) {
            if (this.isWDClaim) {
                return true;
            } else {
                this.router.navigate(['/home']);
                return false;
            }
        } else {
            this.router.navigate(['/home']);
            return false;
        }
    }
}
