
/*
* This component is the container of all modals
* Used to hold the content of a modal and display
* it in the styles designed.
*
*/

import { AfterViewInit, Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import { INTERMITTENT_MODAL_CATEGORY } from '@app/cxr/leave-details-flow/leave-details.constants';
import { OverpaymentLabelsConstants } from '@app/cxr/overpayment/shared/constants/overpayment-labels.constants';
import { OverpaymentRouteConstants } from '@app/cxr/overpayment/shared/constants/overpayment-route.constants';
import { CustomEventService } from '@app/shared/services/tealium/custom-event.service';
import { AuthStateService } from '../../../../shared/services/auth-state.service';
import { ModalService } from '../../../../shared/services/modal.service';
import { StorageManagementService } from '../../../../shared/services/storage-management.service';

@Component({
  selector: 'sliding-modal',
  templateUrl: './sliding-modal.component.html',
  styleUrls: ['sliding-modal.component.scss']
})
export class SlidingModalComponent implements OnInit, AfterViewInit, OnDestroy {
  @Input() modalName: string;
  @Input() modalTitle: string;
  @Input() hideCloseButton: string;
  @Input() disableCrossCheckFlag: string;
  @Input() allClaimPayments: boolean;
  @Input() hideTitleBorder: boolean;
  @Input() labelSize: string = 'regular';
  @Input() set isPsv(data) {
    this.psvFlag = data;
  }
  @Input() modalCategory: INTERMITTENT_MODAL_CATEGORY;
  @Output() active: string;
  @Output() ifCancel = new EventEmitter<boolean>();
  @Output() psvClose = new EventEmitter<boolean>();
  psvFlag: boolean;
  isOverpaymentPage: boolean = false;

  constructor(private customEventService: CustomEventService, private router: Router, private modalService: ModalService, private session: StorageManagementService, private authStateService: AuthStateService) {

  }

  /* Subscribing the modal to the service capabilities
  * */
  ngAfterViewInit(): void {
    //console.log('After view init of modal service');
  }
  ngOnInit(): void {

    this.isOverpaymentPage = (this.router.url === OverpaymentRouteConstants.MAKE_PAYMENT || this.router.url === OverpaymentRouteConstants.SELECT_PAYMENT_AMOUNT) ? true : false;

    //console.log('on init of modal service');
    this.modalService.modalClosed$.subscribe(
      modalName => {
        if (this.modalName === modalName) {
          // console.log('Modal being closed= ' + modalName);
          this.setModalState(false);
          const nonModalNodes = document.querySelectorAll('[tabindex="-1"]');
          if (nonModalNodes.length > 0) {
            for (let i = 0; i < nonModalNodes.length; i++) {
              const node = nonModalNodes[i];
              if (node.getAttribute('_prevTabindex') && node.getAttribute('_prevTabindex') != null) {
                if (this.psvFlag === undefined) {
                  node.setAttribute('tabindex', node.getAttribute('_prevTabindex'));
                } else {
                  node.setAttribute('tabindex', '0');
                }
                node.setAttribute('_prevTabindex', null);
              }
            }
          }
        }
      });
    this.modalService.modalOpened$.subscribe(
      modalName => {
        //todo @ModalComponent - Launching Modal - This was removed temporarily - May need a fix to bring it back in. Seems to be a load timing issue?
        if (this.modalName === modalName) {
          // console.log('Modal being openned= ' + modalName);
          this.setModalState(true);
          const modalNodes = document.querySelector('#' + modalName);
          const nonModalNode = document.querySelectorAll('a[href], area[href], input:not([disabled]), select:not([disabled]), textarea:not([disabled]), button:not([disabled]), iframe, object, embed, [tabindex="0"], [contenteditable]');
          if (modalNodes !== null && nonModalNode.length > 0) {
            for (let i = 0; i < nonModalNode.length; i++) {
              const node = nonModalNode[i];
              if (!modalNodes.contains(node)) {
                node.setAttribute('_prevTabindex', node.getAttribute('tabindex'));
                node.setAttribute('tabindex', '-1');
              }
            }
          }
        }
      });
    this.authStateService.modalClosed$.subscribe(
      modalName => {
        if (this.modalName === modalName) {
          // console.log('Modal being closed= ' + modalName);
          this.setModalState(false);
          const nonModalNodes = document.querySelectorAll('[tabindex="-1"]');
          if (nonModalNodes.length > 0) {
            for (let i = 0; i < nonModalNodes.length; i++) {
              const node = nonModalNodes[i];
              if (node.getAttribute('_prevTabindex') && node.getAttribute('_prevTabindex') != null) {
                node.setAttribute('tabindex', node.getAttribute('_prevTabindex'));
                node.setAttribute('_prevTabindex', null);
              }
            }
          }
        }
      });
    this.authStateService.modalOpened$.subscribe(
      modalName => {
        //todo @ModalComponent - Launching Modal - This was removed temporarily - May need a fix to bring it back in. Seems to be a load timing issue?
        if (this.modalName === modalName) {
          // console.log('Modal being openned= ' + modalName);
          this.setModalState(true);
          const modalNodes = document.querySelector('#' + modalName);
          const nonModalNode = document.querySelectorAll('a[href], area[href], input:not([disabled]), select:not([disabled]), textarea:not([disabled]), button:not([disabled]), iframe, object, embed, [tabindex="0"], [contenteditable]');
          if (modalNodes !== null && nonModalNode.length > 0) {
            for (let i = 0; i < nonModalNode.length; i++) {
              const node = nonModalNode[i];
              if (!modalNodes.contains(node)) {
                node.setAttribute('_prevTabindex', node.getAttribute('tabindex'));
                node.setAttribute('tabindex', '-1');
              }
            }
          }
        }
      });
  }

  ngOnDestroy(): void {
    //console.log('On destroy of the modal');
    if (!this.session.getSessionData('preserveModalData')) { //added this condition to handle when there are multiple modals on the same component
      this.modalService.clear();
    }

    this.toggleLockBackground(false);
  }

  /* Setting the state based on service action
  * Used in the styles for animation/display */

  setModalState(state: boolean) {
    this.active = state ? 'active' : '';
    this.toggleLockBackground(state);
  }

  /* Closing Modal on shader click */
  closeModalByShader() {
    // this.modalService.closeModal(this.modalService.getActiveModal());
  }

  closeModal() {
    if (this.isOverpaymentPage) {
      this.customEventService.customEvent(OverpaymentLabelsConstants.ANALYTICS_MODEL_CLOSE, OverpaymentLabelsConstants.ANALYTICS_PAGE_TAG, OverpaymentLabelsConstants.ANALYTICS_LINK_CLICK, OverpaymentLabelsConstants.ANALYTICS_MODEL_CLOSE, OverpaymentLabelsConstants.ANALYTICS_CLOSE_MODAL);
    } else {
      if (this.modalCategory === INTERMITTENT_MODAL_CATEGORY.NonWorkday) {
        this.customEventService.trackWatchNowButton('Int-non work day modal close', 'Int-non work day modal close');
      } else if (this.modalCategory === INTERMITTENT_MODAL_CATEGORY.Unavailable) {
        this.customEventService.trackWatchNowButton('Int-this date isnt available modal close', 'Int-this date isnt available modal close');
      } else if (this.modalCategory === INTERMITTENT_MODAL_CATEGORY.PartialDay) {
        this.customEventService.trackWatchNowButton('Int-modal close', 'Int-modal close');
      }
    }
    this.ifCancel.emit(false);
    this.psvClose.emit(true);
    this.modalService.closeModal(this.modalService.getActiveModal());
  }

  toggleLockBackground(lockScrolling: boolean) {
    if (lockScrolling === true) {
      document.body.className = 'no-scroll';
    } else {
      document.body.className = '';
    }
  }
}
