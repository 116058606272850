import {Component, OnInit, Output} from '@angular/core';
import {Router} from '@angular/router';
import {ProductService} from '../../shared/services/product.service';
import {AuthStateService} from '../../shared/services/auth-state.service';
import {StorageManagementService} from '../../shared/services/storage-management.service';
import {ContentService} from '../../shared/services/content.service';
import {AppLabelConstants} from '../../shared/constants/appLabel.constants';
import {AppConstants} from '../../shared/constants/app.constants';
import {SessionConstants} from '../../shared/constants/session.constants';
import {ErrorConstants} from '../../shared/constants/error.constants';

@Component({
  selector: 'product-list',
  templateUrl: 'product-list.component.html',
  styleUrls: ['product-list.component.scss']
})
export class ProductListComponent implements OnInit {

   productList;
  @Output() loggedIn = false;
  @Output() pageLabel = AppLabelConstants.PRODUCT_INFO_PAGES;
   rows: number;
   perRow: number;
   productMap;
   currentUser;
  public partyId;
   caseId: string;
   getovrageResponse;
   coveragePlanResponse;
   leaveServiceResponse;
   pageId: string;
   owscContent;
   section_title = '';
  public product_Available_Disclosure = AppLabelConstants.YOUR_EMPLOYER_MIGHT_OFFER_MSG;
  public id;
   benefitLabel = AppLabelConstants.BENEFITS_COMMONLY_OFFERED_BY_HF;
   pageId2: string;
   owscContentPostLoginProduct;
   routerValue;
  public isLoggedIn = false;
  public isOnProductInfo = false;
   owscContentPreLoginProduct;
   prodInfBenefitLabel = '';
  public isProductExist = true;

  constructor(private router: Router,
              private productService: ProductService,
              private authStateService: AuthStateService,
              private sessionManagementService: StorageManagementService,
              private contentService: ContentService
  ) {}

  ngOnInit(): void {
    this.isLoggedIn = this.authStateService.getLoginState();

    this.routerValue = this.router.url;
    if (this.routerValue.search('productInfo') > -1) {
      this.isOnProductInfo = true;
    }
    // Refresh the product label
    this.productService.productLabelFromPrdInfo.subscribe(data => {
      this.prodInfBenefitLabel = this.productService.productLabelFromPrdInfo.getValue();
    });

    //Validate the product availability from preLogin/PostLogin/SuccessorProduct
    this.productService.isProductExist.subscribe(data => {
      this.isProductExist = data;
    });

   this.contentService.isSiteMapReady.subscribe(
     (isSiteMapReady: boolean) => {
        if (isSiteMapReady) {
          if (!this.isLoggedIn) {
            this.pageId = this.contentService.getPageId(AppConstants.PRE_AUTH_PROD_PAGE);
            if (this.pageId) {
              //console.log('not calling the sites service again');
              this.populateContent(this.pageId, false);
            } else {
              //console.log('calling the sites service again');
              this.contentService.getSiteMap().subscribe(
                val => {
                  if (val) {
                    const siteMapResponse = val;
                    //this.sessionManagementService.setSessionObject("siteMapData", siteMapResponse);
                    this.sessionManagementService.setSessionData(SessionConstants.SITE_MAP_DATA, siteMapResponse);
                    this.pageId = this.contentService.getPageId(AppConstants.PRE_AUTH_PROD_PAGE);
                    this.populateContent(this.pageId, false);
                  }
                }, (error) => {
                  console.log(ErrorConstants.ERROR_GETTING_SITEMAP_PRE_LOGIN);
                  console.log(error);
                });
            }
          } else if (this.isLoggedIn) {
            this.pageId2 = this.contentService.getPageId(AppConstants.POST_AUTH_PROD_PAGE);
            if (this.pageId2) {
              this.populateContent(this.pageId2, true);
            } else {
              this.contentService.getSiteMap().subscribe(
                val => {
                  if (val) {
                    const siteMapResponse = val;
                    this.sessionManagementService.setSessionData(SessionConstants.SITE_MAP_DATA, siteMapResponse);
                    this.pageId2 = this.contentService.getPageId(AppConstants.POST_AUTH_PROD_PAGE);
                    this.populateContent(this.pageId2, true);
                  }
                }, (error) => {
                  console.log(ErrorConstants.ERROR_GETTING_SITEMAP_POST_LOGIN);
                  console.log(error);
                });
            }
          }
        }
     }
   );

    this.productService.productLabelPostLogin.subscribe(value => {
      if (value && value.length > 0) {
        this.benefitLabel = value;
      }
    });

    this.productService.productDisclosurePostLogin.subscribe(value => {
      if (value && value.length > 0) {
        this.product_Available_Disclosure = value;
      }
    })
  }

  /*
   * This methos is used to get the product related content
   * from OWCS Pre/Post Login
   */

  populateContent(pageId, loggedIn) {
    if (!loggedIn && pageId) {
      this.contentService.getOWCSResponse(pageId, AppConstants.PRE_AUTH_PROD_PAGE_U).subscribe(
          val => {
          if (val && val.data ) {
            this.owscContentPreLoginProduct = val;
            if (this.owscContentPreLoginProduct
              && this.owscContentPreLoginProduct.data
              && this.owscContentPreLoginProduct.data.Pre_auth_40_Body) {
              this.product_Available_Disclosure = this.owscContentPreLoginProduct.data.Pre_auth_40_Body.value;
            }
            if (this.owscContentPreLoginProduct
              && this.owscContentPreLoginProduct.data
              && this.owscContentPreLoginProduct.data.Pre_auth_22_Body) {
              this.benefitLabel = this.owscContentPreLoginProduct.data.Pre_auth_22_Body.value;
            }
          } else {
            throw val;
          }
        }, error => {
/*          console.log('Error Product List OWCS Content Pre Login');
          console.log(error);*/
          console.log('' + error);
        }, () => {

        });
    } else if (loggedIn && pageId) {
      this.contentService.getOWCSResponse(pageId, AppConstants.POST_AUTH_PROD_PAGE_U).subscribe(
          val => {
          if (val && val.data) {
            this.owscContentPostLoginProduct = val;
          } else {
            throw val;
          }
        }, (error) => {
          console.log(ErrorConstants.ERROR_PRODUCT_LIST_OWCS_POST_LOGIN);
          console.log(error);
        });
    }
  }
}
