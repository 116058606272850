import {AfterViewInit, Component, Input, OnInit, Output, EventEmitter, QueryList, ViewChildren} from '@angular/core';
import {StorageManagementService} from '../../shared/services/storage-management.service';
import {ModalService} from '../../shared/services/modal.service';
import {Router} from '@angular/router';
import {RelatedClaims} from '../../shared/models/relatedClaims.model';
import {OWCSCaasService} from '../../shared/services/owcs-caas.service';
import {CustomEventService} from '../../shared/services/tealium/custom-event.service'
import {env} from '../../../environments/environment-loader';
import {ContentService} from '../../shared/services/content.service';
import {ContactsService} from '../../shared/services/contacts.service';
import {AetnaContactsService} from '../../shared/services/aetnaContacts.service';
import {AppLabelConstants} from '../../shared/constants/appLabel.constants';
import {SessionConstants} from '../../shared/constants/session.constants';
import {AppConstants} from '../../shared/constants/app.constants';
import {MissingInfoThaaService} from '../../shared/services/missing-info-thaa.service';
import {
  MissingInfoFromAllThaa, EmployeeMissingDocumentThaa, EmployerMissingDocumentThaa, DoctorMissingDocumentThaa,
  MissingInfoDocThaa,
  MissingReqInfoThaa, OWCSMissingItemsResponseThaa, MissingInfoListThaa, DownloadLinkThaa, MissingItemThaa, VersionThaa,
  MissingInfoAlertInfo, Recipient, FollowUps
} from '../../shared/models/missingInfoThaa.model';
import {DownloadLink, DoctorMissingDocument} from '../../shared/models/missing-info.model';
import {DocumentService} from '../../shared/services/claim-documents.service';
import {AppLeaveConstants} from '../leave-details-flow/leave-details.constants';
import {ResponseErrorHandlerService} from '../../shared/services/response-error-handler.service';
import {MissingInfoThaaLeaveService} from '../../shared/services/missing-info-thaa-leave.service';
import {AetnaApsFormsService} from '../../shared/services/aetna-aps-forms.service';

@Component({
  selector: 'app-missing-info-details-thaa',
  templateUrl: 'missing-info-details-thaa.component.html',
  styleUrls: ['missing-info-details-thaa.component.scss']
})
export class MissingInfoDetailsThaaComponent implements OnInit, AfterViewInit {
  @Input() missingInfoHeader;
  @Input() requestFor;
  @Input() sectionMessagePart1; //:string="We're missing some information we need for your claim. Incomplete claims may be closed. They can be reopened, but you'll get a decision faster if you can provide the missing information as soon as possible.";
  @Input() currentClaim: RelatedClaims;
  @Input() leaveMessagePart1;
  @Input() uploadButtonText;
  @Input() missedInfoHeaderByYouUpload; //:string="Information from You via Upload";
  @Input() missedInfoHeaderByYouCall; //:string="From You via Call";
  @Input() missedInfoHeaderByYouCallandChat = '';
  @Input() missedInfoHeaderByYouCallandChatmsg = '';
  @Input() missedInfoHeaderByEmployer; //:string;//="From Your Employer";
  @Input() missedInfoHeaderByDoctor; //:string;//="From Your Doctor";
  @Input() messageforYouUpload; //:string="Please update this information by uploading your missing form(s). If you don't have the forms, you can download them from here. Once they're filled in, use the Upload button to submit.";
  @Input() messageforYouCall; //:string="Please give us a call to update this information.";
  @Input() messageforDoctor: string; //:"We've requested information from your doctor and are still waiting to hear from them. You don't have to do anything, but if you want to, you  can download the necessary forms and  then upload them after your doctor fills them out.";
  @Input() messageforEmployer; //"We are gathering some information from your employer. Don’t worry, there’s nothing you need to do."
  @Input() employeeChatAndCall; // 'There’s no need to fill out a form. You can just tell us. Chat online with us by clicking on the chat button, or call us at ';
  @Input() employeeCallIndicator; // 'There’s no need to fill out a form. You can just tell us this information by calling 1-888-676-55466 ';
  @Input() callWithInfo; // If you have this information, you can tell us by phone. Call us at 1-888-676-5546.
  @Input() missedInfoHeaderByYou = '';
  @Input() dateRange;
  @Input() source;
  @Input() neededFor;
  @Input() followUp;
  @Input() finalFollow;
  @Input() finalFollowPhrase;
  @Input() initialRequestDate;
  @Input() requestedDate;
  @Input() neededFrom;
  @Input() datesER;
  @Input() datesDR;
  @Input() labelForDate;
  @Input() physiciansAndDates;
  @Input() learnMore;

  @Input() doctorDownloadAndUpload = ''; // You can make this process go faster by downloading the form, having your doctor fill it out, and uploading it here.
  @Input() doctorUpload = ''; // If you have this form from your doctor, you can upload it here.
  @Input() downloadAndUpload = ''; //download and upload completed form
  @Input() pleaseUpload = ''; // upload completed form
  @Input() eSignBlock = ''; // Complete the form online. You’ll need to agree to some e-sign terms and conditions.

  @Input() sentEmailTo = '';
  @Input() sentFaxTo = '';
  @Input() sentMailTo = '';
  public eSignDownload;
  public eSignMiddlePharse;
  public eSignUploadPhrase;
  public pleaseWord: string;
  public yourForm: string;
  public downLoadWord: string;
  public upLoadWord: string;
  public uploadOnlyForm: string;
  public lastOfUploadPhrase: string;
  public eSignLink: string;
  public eSignLastPhrase: string;
  public lastDotPhase;
  public formFromDocPhrase: string;
  public linktoUploadDocForm: string;
  public doctorDownloadPhrase: string;
  public doctorDowloadForm: string;
  public doctorUploadPhrase: string;
  public doctorUploadForm: string;
  public appLabelConstants = AppLabelConstants;
  public appConstants = AppConstants;
  public RequiredByDate: string;
  public callInd: string;
  public chatInd: string;
  public UploadIndVar = false;
  public leaveClaim = false;
  public infoCodeYou: string;
  public infoCodeDoctor: string;
  public infoCodeEmployer: string;
  public employeeCallAndChatSection: boolean = true;
  public employeeCallOrChatSection: boolean = false;
  public missingInfoFromAllThaa: MissingInfoFromAllThaa;
  public missingDocumentByEmployee: MissingInfoListThaa;
  public missingDocumentByDoctor: MissingInfoListThaa;
  public missingDocumentByEmployer: MissingInfoListThaa;
   claimOnlineSubStatusCode: string;
   claimOnlineStatusCode: string;
  public intialRequestDateArray = [];
  public requiredInfoFromDoctorDetails: DoctorMissingDocumentThaa[] = [];
  public requiredInfoEmployeeDetails: EmployeeMissingDocumentThaa[] = [];
  public requiredInfoEmployerDetails: EmployerMissingDocumentThaa[] = [];
  public dateDisplayUpdate = [];
  public documentByCallOrChatCount: number = 0;
  public documentByUploadCount = 0;
  public DocumentsByEmployerCount: number;
  public DocumentsByDoctorCount: number;
  public missingDocsRequired: MissingInfoDocThaa[] = [];
   contacts;
   phoneNumber;
  public showRecentlyResolved = false;
  public paperlessIntakethis;
  public claimOnlineStatus;
   claimOnlineSubStatus: string;
  public displayEmployer = true;
  public missingItems: MissingItemThaa[] = [];
   prefixHostName: string;
   appData;
   insuredID: string;
  missingDocsPopup: boolean = false;
  public curLeave;
  public Requiredlabel = this.appLabelConstants.REQUIRED_BY;
  public screenWidth = window.screen.width;
  public missingInfoDetailsListThaa: MissingInfoListThaa[];
  public missingInfoRecentlyResolvedCount: MissingInfoListThaa[];
  public recentResItemsCount;
  public recentResItemsCountLeave;
  @Output() recentResItemsCountt = new EventEmitter<any>();
  @Output() recentResItemsCounttLeave = new EventEmitter<any>();
  @ViewChildren('removeCheck') removeChecks: QueryList<any>;
  public clearModalData: boolean = false;
  public EsignFormUrl;
  @Output() loading = false;
  // public showFinalFollowUp = false;
  isFileUploadDisabled: boolean=false;

  constructor(private storageManagementService: StorageManagementService,
              private missingInfoThaaService: MissingInfoThaaService,
              private missingInfoThaaLeaveService: MissingInfoThaaLeaveService,
              private doc: DocumentService,
              private contactService: ContactsService,
              private aetnaContactsService: AetnaContactsService,
              private modalService: ModalService,
              private owcsCaasService: OWCSCaasService,
              private contentService: ContentService,
              private router: Router,
              private customEventService: CustomEventService,
              private _responseErrorHandlerService: ResponseErrorHandlerService,
              private aetnaApsFormsService: AetnaApsFormsService ) {
  }

  ngOnInit() {
    this.isFileUploadDisabled = this.storageManagementService.getSessionData(AppConstants.IS_UPLOAD_DISABLED);
    this.curLeave = this.storageManagementService.getSessionData(SessionConstants.CURR_LEAVE);
    if (this.router.url === AppLabelConstants.URL_LEAVE_DETAILS || this.router.url === AppConstants.URL_LEAVEdETAILS_MISSINGINFO) {
      this.leaveClaim = true;
    } else {
      this.leaveClaim = false;
    }
    if (this.storageManagementService.retrieveSessionObject(SessionConstants.APP_DATA)) {
      this.appData = JSON.parse(this.storageManagementService.retrieveSessionObject(SessionConstants.APP_DATA)).appData;
    }
    this.prefixHostName = env.cxrBase;
    if (this.requestFor === AppConstants.MISSINGINFO_CLAIM) {
      this.claimOnlineStatus = this.currentClaim.claimOnlineStatus.toLowerCase(); // used to read online status
      this.claimOnlineSubStatus = this.currentClaim.claimOnlineSubStatus.toLowerCase(); // used to read online sub status
      this.claimOnlineStatusCode = this.currentClaim.claimOnlineStatusCode; // used to read online status code
      this.claimOnlineSubStatusCode = this.currentClaim.claimOnlineSubStatusCode; // used to read online sub status code
    }
    if (this.currentClaim.claimEventId) {
      this.getMissingInfoForClaim();
    } else {
      this.getMissingInfoForLeave();
    }
    this.getClaimContact();
    this.stringParsing();
    if ((this.router.url === AppConstants.URL_CLAIM_DETAILS || this.router.url === AppLabelConstants.URL_LEAVE_DETAILS ) || this.router.url === AppConstants.URL_CLAIMdETAILS_MISSINGINFO) {
      this.showRecentlyResolved = true;
    }

    //check for Finalfollowup toggle.
  //   if (this.appConstants.FT_20210814_F10471 && env.envName.toLowerCase() !== AppConstants.PROD_ENV_NAME) {
  //     this.showFinalFollowUp = true;
  //   }
  }

  ngAfterViewInit() {
    // this.getMissingItems();
  }

  stringParsing() {
    this.pleaseWord = this.downloadAndUpload.substr(0, 6);
    this.yourForm = this.downloadAndUpload.substr(29, 15);
    this.downLoadWord = this.downloadAndUpload.substr(6, 22);
    this.upLoadWord = this.downloadAndUpload.substr(44, 33);
    this.pleaseWord = this.pleaseUpload.substr(0, 6);
    this.uploadOnlyForm = this.pleaseUpload.substr(7, 18);
    this.lastOfUploadPhrase = this.pleaseUpload.substr(25, 18);
    this.eSignLink = this.eSignBlock.substr(0, 24).replace(/^<div[^>]*>|<\/div>$/g, '');
    this.eSignLastPhrase = this.eSignBlock.substr(24, 138);
    this.eSignDownload = this.eSignBlock.substr(162, 30).replace('<a href="#">', '');
    this.eSignMiddlePharse = this.eSignBlock.substr(196, 18);
    this.eSignUploadPhrase = this.eSignBlock.substr(214, 30);
    this.lastDotPhase =  this.eSignBlock.substr(244, 5);
    this.doctorDownloadPhrase = this.doctorDownloadAndUpload.substr(0, 38);
    this.doctorDowloadForm = this.doctorDownloadAndUpload.substr(39, 22);
    this.doctorUploadPhrase = this.doctorDownloadAndUpload.substr(60, 37);
    this.doctorUploadForm = this.doctorDownloadAndUpload.substr(97, 22);
    this.formFromDocPhrase = this.doctorUpload.substr(0, 47);
    this.linktoUploadDocForm = this.doctorUpload.substr(48, 25);
   // this.eSignLastPhrase.replace(/^<div[^>]*>|<\/div>$/g, '');
  }

  getMissingInfoForClaim() {
    const claimId = this.currentClaim.claimEventId;
    const personId = this.currentClaim.personId;
    this.missingInfoThaaService.retrieveMissingInfo(claimId, personId).subscribe(resp => {
      if (resp && resp['MissingInfoAlertInfo']) {
        this.recentResItemsCount = resp['countOfRecentlyCompletedItems'];
        this.recentResItemsCountt.emit(this.recentResItemsCount);
        this.missingInfoDetailsListThaa = resp['MissingInfoAlertInfo'][0].missingInfoList;
        this.RequiredByDate = resp['informationDueByDate'] ? resp['informationDueByDate'] : '';
        this.storageManagementService.setSessionObject(SessionConstants.MISSING_INFO_THAA, resp);
        this.missingInfoFromAllThaa = this.missingInfoThaaService.documentFilterThaa(this.missingInfoDetailsListThaa);
        this.documentCollection(this.missingInfoFromAllThaa);
        this.getMissingItems();
      }
    });
    // this.missingInfoFromAllThaa = this.missingInfoThaaService.documentFilterThaa(this.missingInfoDetailsListThaa);
    /* this.dateDisplayUpdate.push(this.currentClaim); // Used to push array object into empty array
     for (const update of this.dateDisplayUpdate) { // for reach loop
       const i = 0; // to get the initail position of required by date
       this.RequiredByDate = update.missingInfo[i].requiredByDate; // to display required by dtae on ui

     }*/


  }

  getMissingInfoForLeave() {
    const leaveId = this.curLeave.leaveId;
    const personId = this.curLeave.personId;
    this.missingInfoThaaLeaveService.retrieveMissingInfoLeave(leaveId, personId).subscribe(resp => {
      if (resp && resp['MissingInfoAlertInfo']) {
        this.recentResItemsCountLeave = resp['countOfRecentlyCompletedItems'];
        this.recentResItemsCounttLeave.emit(this.recentResItemsCountLeave);
        this.missingInfoRecentlyResolvedCount = resp;
        this.missingInfoDetailsListThaa = resp['MissingInfoAlertInfo'][0].missingInfoList;
        this.RequiredByDate = resp['informationDueByDate'] ? resp['informationDueByDate'] : '';
        this.storageManagementService.setSessionObject(SessionConstants.MISSING_INFO_THAA_LEAVE, resp);
        this.missingInfoFromAllThaa = this.missingInfoThaaService.documentFilterThaa(this.missingInfoDetailsListThaa);
        this.documentCollection(this.missingInfoFromAllThaa);
        this.getMissingItems();
      }
    });
  }


  getMissingItems() {
    if (this.storageManagementService.retrieveSessionObject(SessionConstants.MISSING_ITEMS)) {
      this.missingItems = JSON.parse(this.storageManagementService.retrieveSessionObject(SessionConstants.MISSING_ITEMS)).missingItems;
      // this.missingInfoFromAllThaa = this.missingInfoThaaService.documentFilterThaa(this.missingInfoDetailsListThaa);
      // this.documentCollection(this.missingInfoFromAllThaa);
      this.determineTitle();
      // this.mailingAddressFix();

    } else {
      this.owcsCaasService.getMissingItems().subscribe(
        (owcsMissingInfoResponse: OWCSMissingItemsResponseThaa) => {
          if (owcsMissingInfoResponse && owcsMissingInfoResponse.forms) {

            this.missingItems = owcsMissingInfoResponse.forms;
            this.storageManagementService.setSessionObject(SessionConstants.MISSING_ITEMS, owcsMissingInfoResponse.forms);
            this.missingInfoFromAllThaa = this.missingInfoThaaService.documentFilterThaa(this.missingInfoDetailsListThaa);
            this.documentCollection(this.missingInfoFromAllThaa);
            this.determineTitle();
          }
        }
      );
    }
  }

  getClaimContact() {
    if ((this.currentClaim && this.currentClaim.sourceSystemId && this.currentClaim.sourceSystemId === AppConstants.SOURCE_SYS_ID_AA) || (this.curLeave)) {
      const data = this.storageManagementService.getSessionData(SessionConstants.AETNA_CLAIMS_CONTACT);
      if (data && JSON.stringify(data).length > 0 && data.aetnaContactDetails) {
        this.phoneNumber = data.aetnaContactDetails.phone;
        this.phoneLink();
      } else {
        this.aetnaContactsService.getAetnaDisabilityClaimRelatedContact(this.currentClaim.personId).subscribe(res => {
          if (res && res.aetnaContactDetails) {
            this.storageManagementService.setSessionData(SessionConstants.AETNA_CLAIMS_CONTACT, res);
            //console.log('AetnaContacts Phone: ' + data.aetnaContactResponse.aetnaContactDetails.phone);
            this.phoneNumber = res.aetnaContactDetails.phone;
            this.phoneLink();
          }
        });
      }
    }
  }


  determineTitle() {
    if (this.missingInfoDetailsListThaa && this.missingInfoDetailsListThaa.length > 0) {
      const missingInfoFromAllThaa: MissingInfoListThaa[] = this.missingInfoDetailsListThaa;
      missingInfoFromAllThaa.forEach(
        (missingInfo: MissingInfoListThaa) => {
          if (missingInfo && missingInfo.mInfoReqInfo && missingInfo.mInfoReqInfo.length > 0) {
            const missingReqInfoDocs: MissingReqInfoThaa[] = missingInfo.mInfoReqInfo;
            missingReqInfoDocs.forEach(
              (missingReqInfo: MissingReqInfoThaa) => {
                if (missingReqInfo && missingReqInfo.upload) {
                  const missingDocsRequired: MissingInfoDocThaa = new MissingInfoDocThaa();
                  missingDocsRequired.docTitle = missingReqInfo.displayValue;
                  missingDocsRequired.checked = false;
                  missingDocsRequired.docCode = missingReqInfo.mInfoReqInfoCd;
                  this.missingDocsRequired.push(missingDocsRequired);
                  if (missingReqInfo && missingReqInfo.recipient && missingReqInfo.recipient.length > 0) {
                    const missingRecip: Recipient[] =  missingReqInfo.recipient;
                    missingRecip.forEach((recipMA: Recipient) => {
                        if (recipMA && recipMA.mailingAddress) {
                          recipMA.mailingAddress = recipMA.mailingAddress.replace(/,/g, ', ');
                        }
                        if (recipMA && recipMA.faxNumber) {
                          recipMA.faxNumber = recipMA.faxNumber.replace(/(\d{3})(\d{3})(\d{4})/, '($1) $2-$3');
                        }
                      }
                    )
                  }
                }
              }
            )
          }
        }
      )
    }
  }


  documentCollection(missingInfoFromAllThaa: MissingInfoFromAllThaa) {
    if (missingInfoFromAllThaa) {
      this.missingDocumentByEmployee = missingInfoFromAllThaa.missingInfoDataFromEmployee;
      this.missingDocumentByEmployer = missingInfoFromAllThaa.missingInfoDataFromEmployer;
      this.missingDocumentByDoctor = missingInfoFromAllThaa.missingInfoDataFromDoctor;
      //if missing documents from employee exists
    }
    if (this.missingDocumentByEmployee) {
      this.infoCodeYou = this.missingDocumentByEmployee.mInfoCode;
      for (const requiredInfoFromEmployee of this.missingDocumentByEmployee.mInfoReqInfo) {
        const downloadInfo: DownloadLinkThaa = this.ifFormIsDownloadable(requiredInfoFromEmployee);
        const employeeMissingDocument: EmployeeMissingDocumentThaa = new EmployeeMissingDocumentThaa();
        employeeMissingDocument.showByYou = false;
        requiredInfoFromEmployee.downloadIndicator = downloadInfo.isDownloadable;
        requiredInfoFromEmployee.formURL = downloadInfo.downloadURL;
        requiredInfoFromEmployee.displayValue = downloadInfo.name;
        employeeMissingDocument.displayText = downloadInfo.displayName.learnMoreEE;
        employeeMissingDocument.document = requiredInfoFromEmployee;
        employeeMissingDocument.dateEEIR = requiredInfoFromEmployee.requestedDate;
        this.requiredInfoEmployeeDetails.push(employeeMissingDocument);

        if (requiredInfoFromEmployee.upload) {
          this.UploadIndVar = true;
          this.documentByUploadCount++;

        }
        /*else if (requiredInfoFromEmployee.callInd && !requiredInfoFromEmployee.chatInd) {

                 this.employeeCallAndChatSection = false;
                 this.employeeCallOrChatSection = true;
                 this.documentByCallOrChatCount++;

               } else if (requiredInfoFromEmployee.callInd && requiredInfoFromEmployee.chatInd) {
                 this.employeeCallOrChatSection = true;
                 this.documentByCallOrChatCount++;
               }*/
      }
      this.sortEEData();
    }

    if (this.missingDocumentByEmployer) {
      this.infoCodeEmployer = this.missingDocumentByEmployer.mInfoCode;
      if (this.currentClaim.claimOnlineStatusCode === AppConstants.COS_UNDER_REVIEW_BY_CLAIM_HANDLER_CD_08 && this.currentClaim.claimOnlineSubStatusCode === AppConstants.COSS_MISSING_INFORMATION_CD_13 &&
        this.currentClaim.extensionStatusCode === null && this.currentClaim.extensionSubStatusCode === null) {
        //this.missingDocumentByEmployer.dueDate ? this.displayEmployer = false : this.displayEmployer = true;
      }

      for (const requiredInfoFromEmployer of this.missingDocumentByEmployer.mInfoReqInfo) {
        const downloadInfo: DownloadLinkThaa = this.ifFormIsDownloadable(requiredInfoFromEmployer);
        const employerMissingDocument: EmployerMissingDocumentThaa = new EmployerMissingDocumentThaa();

        requiredInfoFromEmployer.downloadIndicator = downloadInfo.isDownloadable;
        requiredInfoFromEmployer.formURL = downloadInfo.downloadURL;
        requiredInfoFromEmployer.displayValue = downloadInfo.name;
        employerMissingDocument.displayText = downloadInfo.displayName.learnMoreER;
        employerMissingDocument.description = downloadInfo.description;
        employerMissingDocument.showEmplDiv = false;
        employerMissingDocument.document = requiredInfoFromEmployer;
        employerMissingDocument.dateERIR = requiredInfoFromEmployer.requestedDate;
        this.requiredInfoEmployerDetails.push(employerMissingDocument);
      }
      this.DocumentsByEmployerCount = this.requiredInfoEmployerDetails.length;
      this.sortERData();
    }


    if (this.missingDocumentByDoctor) {

      this.infoCodeDoctor = this.missingDocumentByDoctor.mInfoCode;
      for (const requiredInfoFromDoctor of  this.missingDocumentByDoctor.mInfoReqInfo) {
        const downloadInfo: DownloadLinkThaa = this.ifFormIsDownloadable(requiredInfoFromDoctor);
        const doctorMissingDocument: DoctorMissingDocumentThaa = new DoctorMissingDocumentThaa();

        requiredInfoFromDoctor.downloadIndicator = downloadInfo.isDownloadable;
        requiredInfoFromDoctor.formURL = downloadInfo.downloadURL;
        requiredInfoFromDoctor.displayValue = ((downloadInfo.name) ? downloadInfo.name : requiredInfoFromDoctor.displayValue ? requiredInfoFromDoctor.displayValue : ' ');
        doctorMissingDocument.displayText = downloadInfo.displayName.learnMoreDoc;
        doctorMissingDocument.showDoctorDiv = false;
        doctorMissingDocument.document = requiredInfoFromDoctor;
        doctorMissingDocument.dateDRIR = requiredInfoFromDoctor.requestedDate;
        this.requiredInfoFromDoctorDetails.push(doctorMissingDocument);
      }
      this.DocumentsByDoctorCount = this.requiredInfoFromDoctorDetails.length;
      this.sortDRData();
    }
  }

  sortEEData() {
    if (this.requiredInfoEmployeeDetails) {
      return this.requiredInfoEmployeeDetails.sort((a, b) => <any>new Date(b.dateEEIR) - <any>new Date(a.dateEEIR))
    }
  }
  sortERData() {
    if (this.requiredInfoEmployerDetails) {
      return this.requiredInfoEmployerDetails.sort((a, b) => <any>new Date(b.dateERIR) - <any>new Date(a.dateERIR))
    }
  }
  sortDRData() {
    if (this.requiredInfoFromDoctorDetails) {
      return this.requiredInfoFromDoctorDetails.sort((a, b) => <any>new Date(b.dateDRIR) - <any>new Date(a.dateDRIR))
    }
  }

  showUploadModal() {
    if (this.missingDocsRequired.length > 0) {
      this.missingDocsPopup = true;
      setTimeout(() => {
        this.modalService.openModal(AppLabelConstants.UPLOAD_MISSING_THAA_DOC_MODAL);
      }, 500);
    }
    setTimeout(() => {
      if (typeof (<any>window).trackSaveButton === 'function') {
        (<any>window).trackUploadButton();
      }
    }, 500);
  }

  /*  public  checkIfDateExistsInDocuments(physicianList: Physician[]): boolean {
      return physicianList.filter(
          (physician: Physician) => !!(physician.fromDataRange || physician.toDataRange)
        ).length > 0;
    }

    public  checkIfNameExistsInDocuments(physicianList: Physician[]): boolean {
      return physicianList.filter(
          (physician: Physician) => !!physician.physicianName
        ).length > 0;
    }*/

  ifFormIsDownloadable(param): DownloadLinkThaa {
    const missingItemDownloadLink: DownloadLinkThaa = new DownloadLinkThaa();
    //const dcsVersion: string = param.formType.toLowerCase();
    //let owcsVersion: VersionThaa;
    const missingItemsOWCS: MissingItemThaa[] = [];
    const docCode = param.mInfoReqInfoCd;
    missingItemDownloadLink.description = param.missingInfoRequInfoDesc;
    missingItemDownloadLink.name = param.displayValue;
    missingItemDownloadLink.isDownloadable = param.downloadIndicator;
    missingItemDownloadLink.displayName = this.learnMore;
    /*    const values: string[] = this.owcsCaasService.fetchThaaFormValues(docCode);
        if (values && values.length >= 1) {
          if (this.missingItems.length > 0) {
            missingItemsOWCS = this.missingItems.filter(
              (missingItem: MissingItemThaa) => {
                for (let i = 0; i < values.length; i++) {
                  if (missingItem.key === values[i]) {
                    // we got the match between owcs key and dcs key
                    return true;
                  }
                }
              }
            );*/


    /* if (missingItemsOWCS.length >= 1) {
       missingItemDownloadLink.description = missingItemsOWCS[0].description;
       missingItemDownloadLink.name = missingItemsOWCS[0].displayName;
     /!*  if (dcsVersion && dcsVersion.toLowerCase() === AppConstants.ONSHORE) {
         owcsVersion = missingItemsOWCS[0].onshore;
       } else if (dcsVersion && dcsVersion.toLowerCase() === AppConstants.GENERIC) {
         owcsVersion = missingItemsOWCS[0].generic;
       } else {
         owcsVersion = missingItemsOWCS[0].leave;
       }*!/
       if (owcsVersion.url) {
         missingItemDownloadLink.isDownloadable = "true";
         missingItemDownloadLink.downloadURL = owcsVersion.url;
         return missingItemDownloadLink;
       } else {
         missingItemDownloadLink.isDownloadable = "false";
         return missingItemDownloadLink;
       }
     } else {
       missingItemDownloadLink.isDownloadable ="false";
       return missingItemDownloadLink;
     }*/
    /*   }
     } else {
       missingItemDownloadLink.isDownloadable ="false";
       return missingItemDownloadLink;
     }*/

    return missingItemDownloadLink;
  }

  trackAccordionToggle(documentdetails, documentCategory) {
    let documentName = documentdetails.document.displayValue;
    if (documentdetails.showDoctorDiv === true || documentdetails.showByYou === true || documentdetails.showEmplDiv === true) {
      documentName += AppLabelConstants.EXPAND;
    } else {
      documentName += AppLabelConstants.COLLAPSE;
    }
    this.customEventService.trackMissingInfoDocumentCategory(documentName, documentCategory);
  }

  trackDocumentDownload(documentDetails, documentCategory) {
    //this.customEventService.trackMissingInfoDocumentCategory(documentDetails.displayValue + AppLabelConstants.DOWNLOAD, documentCategory);
  }

  trackMissingInfoContact(contactUsLabel, contactCategory) {
    this.customEventService.trackMissingInfoDocumentCategory(contactUsLabel, contactCategory);
  }

  getName(mInfoReqInfoCd: string): string {
    const owcsKeys: string[] = this.owcsCaasService.fetchThaaFormValues(mInfoReqInfoCd);
    if (owcsKeys && owcsKeys.length > 0) {
      const filteredMissingItems: MissingItemThaa[] = this.missingItems.filter(
        (missingItem: MissingItemThaa) => (missingItem.key === owcsKeys[0])
      );
      if (filteredMissingItems && filteredMissingItems.length > 0) {
        return filteredMissingItems[0].displayName;
      }
    }
  }

  phoneLink() {
    if (window.innerWidth > 768) {
      if (this.employeeChatAndCall) {
        this.employeeChatAndCall = this.employeeChatAndCall.replace('[Claim Office Phone Number].', '<strong>' + this.phoneNumber + '</strong>' + '.');
      }
    } else if (window.innerWidth <= 768) {
      if (this.employeeChatAndCall) {
        this.employeeChatAndCall = this.employeeChatAndCall.replace('[Claim Office Phone Number].', '<strong>' + '<a href=tel:"###">' + this.phoneNumber + '</a>' + '.').replace('###', this.phoneNumber) + '</strong>'
      }
    }
  }
  eSignOpenForm(mInfoReqInfoCd) {
    const esignLoader = window.open('/loading');
    this.loading = true;
    const personId = this.currentClaim.personId;
    this.missingInfoThaaService.getEsignForm(personId, mInfoReqInfoCd).subscribe(data => {
      if (data && data['responseCode'] === '002') {
        this.loading = false;
        this._responseErrorHandlerService.handleEcoSignErrorForSubmit(Error);
      } else if (data && data['echoSignURL']) {
        this.EsignFormUrl =  data['echoSignURL'];
        this.loading = false;
        esignLoader.location.href = this.EsignFormUrl;
      } else {
        this.loading = false;
      }

    }, error => {
      this.loading = false;
    });

  }
  downLoadMissInfoForm(mInfoReqInfoCd, attachmentInstanceId) {
    this.loading = true;
    const personId = this.currentClaim.personId;
      this.missingInfoThaaService.getDownLoadMissInfoForm(personId, mInfoReqInfoCd, attachmentInstanceId).subscribe(data => {
      if (data && (data['responseCode'] === '002' || data['responseCode'] === '999')) {
        this.loading = false;
        this._responseErrorHandlerService.handleDownloadFormErrorForSubmit(Error);

      } else if (data && data['imgStream']) {
        this.loading = false;
        const mimeType = 'application/pdf';
        const res = this.aetnaApsFormsService.b64toBlob(data['imgStream'], mimeType, 512);
        const blob = new Blob([res], {type: mimeType});
        const fileObjectURL = URL.createObjectURL(blob);
        //window.open(fileObjectURL);
        if (window.navigator.appVersion.toString().indexOf('.NET') > 0) { // for IE browser
          const nav = (window.navigator as any);
          if (nav.msSaveOrOpenBlob) {
            nav.msSaveOrOpenBlob(blob, fileObjectURL);
          }
        } else {
          const link = document.createElement('a');
          link.href = fileObjectURL;
          link.download = 'Form ' + mInfoReqInfoCd;
          link.click();
        }

      } else {
        this.loading = false;
      }

    }, error => {
        this.loading = false;
    });

  }

  callTaggingSpec(eventId, event) {
    if (event.detail === 'open') {
      this.customEventService.trackHukPopOver(eventId);
    }
  }


  clickCancel() {
    this.missingDocsPopup = false;
    this.clearModalData = !this.clearModalData;
  }
}


