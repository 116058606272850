import {Component, Input, OnInit} from '@angular/core';
import {Location} from '@angular/common';

@Component({
  selector: 'app-box-label',
  templateUrl: './box-label.component.html',
  styleUrls: ['./box-label.component.scss']
})
export class BoxLabelComponent implements OnInit {

  @Input() pageLabel: string;

  constructor(private _location: Location) { }

  ngOnInit() {
  }

  goBack() {
    this._location.back();
  }
}
