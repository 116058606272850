import {Component, Input, OnInit} from '@angular/core';
import {Router} from '@angular/router';
import {ModalService} from '../../../shared/services/modal.service';
import {PersonService} from '../../../shared/services/people.service';
import {SaveProfiledataService} from '../../../shared/services/saveProfileData.service';
import {StorageManagementService} from '../../../shared/services/storage-management.service';
import {BehaviorSubject} from 'rxjs';
import {MessageRibbonService} from '../../../shared/services/message-ribbon.service';
import {EventManagementServiceService} from '../../../shared/services/event-management-service.service';
import {CustomEventService} from '../../../shared/services/tealium/custom-event.service';
import {SessionConstants} from '../../../shared/constants/session.constants';
import {AppConstants} from '../../../shared/constants/app.constants';
import {AppLabelConstants} from '../../../shared/constants/appLabel.constants';
import {ErrorConstants} from '../../../shared/constants/error.constants';
import { AuthStateService } from 'app/shared/services/auth-state.service';
import { ReferenceHelperService } from 'app/shared/services/reference-helper.service';

@Component({
  selector: 'app-update-tcs-alert-modal',
  templateUrl: 'update-tcs-alert-modal.component.html',
  styleUrls: ['update-tcs-alert-modal.component.scss']
})

export class UpdateTCsAlertModalComponent implements OnInit {
  public appLabelConstants = AppLabelConstants;
  private termsNotAgreed = false;
  public showSiteError = false;
  private TextToggleTermsAgreed = false;
  private ActiveIndicator: boolean = false;
  private ifSiteWideOutdated: boolean = false;
  public partyrefId: string;
  private textTCOutdated: boolean = false;
  private sitewideOutdated = false;
  private notificationTermsCondAcceptDt: string;
  private UpdateTextTCResponse: any;
  public jsonBOM;
  private caseId: string;
  private insuredId: string;
  private updateProfileRequest: any;
  private updateProfileResponse: any;
  private profileData: any = {};
  failedTransactionCtr = new BehaviorSubject(0);
  termsAndConditionsFails = new BehaviorSubject(false);
  isupdateTCServiceFailed = new BehaviorSubject(false);
  isupdateProfileServiceFailed = new BehaviorSubject(false);
  isUpdateTnCSuccessCtr = new BehaviorSubject(0);

  @Input() activeIndicator = 0;

  constructor(private modalService: ModalService,
              private router: Router,
              private personService: PersonService,
              private updateData: SaveProfiledataService,
              private storageMgmt: StorageManagementService,
              private messageRibbon: MessageRibbonService,
              private eventManagementServiceService: EventManagementServiceService,
              private storageManagement: StorageManagementService,
              private customEventService: CustomEventService,
              private authStateService: AuthStateService,
              private referenceHelper: ReferenceHelperService, ) {
  }

  ngOnInit() {
    this.jsonBOM = JSON.parse(this.storageMgmt.retrieveSessionObject(SessionConstants.APP_DATA)).appData;
    this.caseId = this.jsonBOM.userData.caseId;
    this.insuredId = this.jsonBOM.userData.srcSysId;
    this.partyrefId = this.jsonBOM.userData.partyRefId;


    //error handling
    this.failedTransactionCtr.subscribe(ctr => {
      if (ctr === 1 || ctr === 2) {
        this.messageRibbon.addMessage(AppLabelConstants.WARNING_SM, ErrorConstants.UPDATES_TCS_WARNING_MSG, null, AppConstants.TRUE);
      } else if (this.failedTransactionCtr.getValue() > 2 ) {
        this.router.navigate([AppLabelConstants.URL_UPDATE_ERROR]);
      }
    })



    this.isUpdateTnCSuccessCtr.subscribe(ctr => {
      // IF checkIftextOutdated returns true , two services wil be called and on success of both ctr will be set to 2 , then close the modal
      if (this.checkIftextOutdated() && (ctr === 2) && (this.modalService.getActiveModal()) ) {
        this.modalService.closeModal(this.modalService.getActiveModal());
      } else if (!this.checkIftextOutdated() && (ctr === 1) && (this.modalService.getActiveModal())) {
        this.modalService.closeModal(this.modalService.getActiveModal());
      } else if (this.checkIftextOutdated() && (ctr === 1) && !this.checkIfOutdated() && (this.modalService.getActiveModal())) {
        this.modalService.closeModal(this.modalService.getActiveModal());
      }
    });


  }


  //todo what is this used for? --- this is to check if user has optedfor text notifications ,if yes then only show text terms and conditions link

  onclicktermsText() {
    window.open(AppConstants.TEXT_TCS);
  }

  onclicktermsSitewide() {
    window.open(AppConstants.SITEWIDE_TNC);
  }

  //upon continue to update mudi database
  UpdateSiteWideTC() {
    this.jsonBOM = JSON.parse(this.storageMgmt.retrieveSessionObject(SessionConstants.APP_DATA)).appData;
    this.partyrefId = this.jsonBOM.userData.partyRefId;
    this.personService.UpdateSiteWideTC(this.partyrefId).subscribe(data => {
        this.termsAndConditionsFails.next(false);
        (JSON.stringify(data));
        this.isUpdateTnCSuccessCtr.next(this.isUpdateTnCSuccessCtr.getValue() + 1);

      }, (error) => {
        this.failedTransactionCtr.next(this.failedTransactionCtr.getValue() + 1);
        this.termsAndConditionsFails.next(true);
        console.log(ErrorConstants.SERVER_IS_DOWN_MSG);
      }
    );
  }

  UpdateTextTC() {
    this.jsonBOM = JSON.parse(this.storageMgmt.retrieveSessionObject(SessionConstants.APP_DATA)).appData;
    this.partyrefId = this.jsonBOM.userData.partyRefId;
    const today = new Date();
    const dd = ( '0' + today.getDate()).slice(-2);
    const mm =  ( '0' + (today.getMonth() + 1 )).slice(-2);
    const yy = today.getFullYear();
    this.notificationTermsCondAcceptDt = yy + '-' + mm + '-' + dd;
    this.personService.UpdateTextTC(this.partyrefId, this.notificationTermsCondAcceptDt).subscribe(
      val => {
        this.UpdateTextTCResponse = val;
        this.isupdateTCServiceFailed.next(false);
        this.isUpdateTnCSuccessCtr.next(this.isUpdateTnCSuccessCtr.getValue() + 1);
      }, (error) => {
        console.log(ErrorConstants.SERVER_IS_DOWN_MSG);

        if (!this.termsAndConditionsFails.getValue()) {
          this.failedTransactionCtr.next(this.failedTransactionCtr.getValue() + 1);
          this.isupdateTCServiceFailed.next(true);

        }
      }
    );
  }

  acceptSiteTerms() {
    this.termsNotAgreed = !this.termsNotAgreed;
    if (this.termsNotAgreed) {
      this.showSiteError = false;
    }
  }

  acceptTextTerms() {
    this.TextToggleTermsAgreed = !this.TextToggleTermsAgreed;

  }

  //to check if the newer version is available for SITE WIDE DOCUMENT
  checkIfOutdated(): boolean {
    this.ifSiteWideOutdated = this.storageMgmt.getSessionData(SessionConstants.DOC_OUTDATED) ? this.storageMgmt.getSessionData(SessionConstants.DOC_OUTDATED) : null;
    if (this.ifSiteWideOutdated === true) {
      return true;
    } else {
      this.sitewideOutdated = false;
      return false;
    }
  }

  //to check if the newer version is available for SITE WIDE DOCUMENT
  checkIftextOutdated(): boolean {
    this.ActiveIndicator = this.storageMgmt.getSessionData(SessionConstants.ACTIVE_INDICATOR);
    this.textTCOutdated = this.storageMgmt.getSessionData(SessionConstants.TEXT_TC_OUTDATED);
    if (this.textTCOutdated === true && this.ActiveIndicator) {
      return true;
    } else {
      return false;
    }
  }

  UnenrollFromEconsent() {

    const profileDtls = this.storageMgmt.getAppData(SessionConstants.PROFILE_DETAILS);
    this.jsonBOM = JSON.parse(this.storageMgmt.retrieveSessionObject(SessionConstants.APP_DATA)).appData;
    this.caseId = this.jsonBOM.userData.caseId;
    this.insuredId = this.jsonBOM.userData.srcSysId;

    if (profileDtls && Object.keys(profileDtls).length > 0) {
      this.profileData = profileDtls;
    } else {
      this.personService.getUserProfile('4', this.insuredId, this.caseId).subscribe(
        data => {
          if (data) {
            this.profileData = data;
            this.isUpdateTnCSuccessCtr.next(this.isUpdateTnCSuccessCtr.getValue() + 1);
            this.storageMgmt.setAppData(SessionConstants.PROFILE_DETAILS, data);
          }
        }
      );
    }



    if (this.profileData &&
      this.profileData &&
      this.profileData.econsentDataResponseBeanList &&
      JSON.stringify(this.profileData.econsentDataResponseBeanList[0]) !== '{}' &&
      this.profileData.econsentDataResponseBeanList[0].econsentCustomerList &&
      this.profileData.econsentDataResponseBeanList[0].econsentCustomerList[0].eConsentDocumentCategoryList
      && this.profileData.econsentDataResponseBeanList[0].econsentCustomerList[0].eConsentDocumentCategoryList) {

      const docList = this.profileData.econsentDataResponseBeanList[0].econsentCustomerList[0].eConsentDocumentCategoryList;
      let filteredIndex = -1;
      //DCST SMS
      if (docList.length > 0) {
        docList.filter(function(data, index) {
          if (data && data.documentCategoryCode && data.documentCategoryCode.toUpperCase() === AppConstants.DCST && data.deliveryMethod && data.deliveryMethod.toUpperCase() === AppConstants.SMS) {
            filteredIndex = index;
          }
        });
      }
      if (filteredIndex > -1) {
        this.profileData.econsentDataResponseBeanList[0].econsentCustomerList[0].eConsentDocumentCategoryList[filteredIndex].activeIndicator = '0';
      } else {
        this.profileData.econsentDataResponseBeanList[0].econsentCustomerList[0].eConsentDocumentCategoryList.push({
          documentCategoryCode: 'DCST',
          documentCategoryText: '',
          documentCategoryStatus: '',
          communicationMethod: 'CSR Managed',
          deliveryMethod: 'SMS',
          activeIndicator: '0',
          consentId: this.profileData.econsentDataResponseBeanList[0].econsentCustomerList[0].eConsentBusinessGroupPartyInfo.consentID
        })
      }

      filteredIndex = -1;
      //DCPT SMS
      if (docList.length > 0) {
        docList.filter(function(data, index) {
          if (data && data.documentCategoryCode && data.documentCategoryCode.toUpperCase() === AppConstants.DCPT && data.deliveryMethod && data.deliveryMethod.toUpperCase() === AppConstants.SMS) {
            filteredIndex = index;
          }
        });
      }
      if (filteredIndex > -1) {
        this.profileData.econsentDataResponseBeanList[0].econsentCustomerList[0].eConsentDocumentCategoryList[filteredIndex].activeIndicator = '0';
      } else {
        this.profileData.econsentDataResponseBeanList[0].econsentCustomerList[0].eConsentDocumentCategoryList.push({
          documentCategoryCode: 'DCPT',
          documentCategoryText: '',
          documentCategoryStatus: '',
          communicationMethod: 'CSR Managed',
          deliveryMethod: 'SMS',
          activeIndicator: '0',
          consentId: this.profileData.econsentDataResponseBeanList[0].econsentCustomerList[0].eConsentBusinessGroupPartyInfo.consentID
        })
      }

      this.updateProfileRequest = {
        businessGroupID: '4',
        businessSegment: 'GB',
        requestSubmitter: 'TestTHAW',
        processingMode: 'activity-processed',
        insuredId: this.insuredId,
        caseId: this.caseId,
        eConsentProfileInfoRequestItemList: [
          {
            eConsentActivityInformation: {
              activityType: 'scsc-consent-acct-doc-change',
              activityCustomerNotification: false,
              activitySourceSystem: 'THAW',
              activitySourceProcessorID: 'GBTHAW',
              activityConsentType: 'ConsentParty'
            },
            eConsentCustomerList: [
              {
                eConsent: {
                  subscribeResponseMethod: 'Web',
                  consentActivityStatusCode: 'CMPL'
                },
                eConsentAdminSystemParty: {
                  adminSystemName: 'DCS'
                },
                eConsentDocumentCategoryList: this.profileData.econsentDataResponseBeanList[0].econsentCustomerList[0].eConsentDocumentCategoryList
              }
            ]
          }
        ]
      }
    }

    this.updateData.updateProfile(this.updateProfileRequest).subscribe(
      val => {
        if (val) {
          this.updateProfileResponse = val;
          this.failedTransactionCtr.next(this.failedTransactionCtr.getValue());
          this.isUpdateTnCSuccessCtr.next(this.isUpdateTnCSuccessCtr.getValue() + 1);
          this.isupdateProfileServiceFailed.next(false);
        }
      }, (error) => {

        if (!this.termsAndConditionsFails.getValue() && !this.isupdateTCServiceFailed.getValue()) {

          this.failedTransactionCtr.next(this.failedTransactionCtr.getValue() + 1);
        }

        console.error(error);
      }
    );
    this.storageMgmt.setAppData(SessionConstants.PROFILE_DETAILS, this.profileData);
  }

  completeLogin() {

    this.storageMgmt.setSessionData(SessionConstants.CONTINUE_CLICKED, true);

    if (this.termsNotAgreed || !this.checkIfOutdated() ) {

      this.showSiteError = false;

      //to update user preference for site Wide terms and conditions ack
      if (this.checkIfOutdated()) {

        this.UpdateSiteWideTC();
      }

      //to update user preference for text terms and conditions ack
      if (this.TextToggleTermsAgreed) {
        this.UpdateTextTC();
        this.triggerManagementService();
      } else if (this.checkIftextOutdated() && !this.TextToggleTermsAgreed) {
        //unroll user for text notifications from econsent if Text T&C not accepted
        this.UnenrollFromEconsent()
      }



    } else {
      this.showSiteError = true;
      this.customEventService.formFieldValidationErrors([ErrorConstants.UPDATED_TCS], [ErrorConstants.THE_TERMS_OF_SERVICE_MSG]);
    }
    //Do other stuff for accepting login


  }

  backToLogin() {
    this.termsNotAgreed = false;
    this.showSiteError = false;
    this.storageMgmt.removeSessionObject(SessionConstants.LOGIN_STATE);
    this.authStateService.logout();
    // this.router.navigate(['/']);
  }

  triggerManagementService() {
    const reqType = '99';
    const directDepositMethod = null;
    const phoneNumber = this.storageManagement.getPhoneNumber();
    this.eventManagementServiceService.callEventManagementService(reqType, directDepositMethod, phoneNumber).subscribe(
      eventManagementServiceResponse => {
        if (eventManagementServiceResponse) {
          const getResponse = eventManagementServiceResponse;
        }
      });
  }
}

