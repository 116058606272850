import {AfterViewInit, Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {AbstractControl, FormControl, FormGroup} from '@angular/forms';
import {ModalService} from '../../shared/services/modal.service';
import {SaveProfiledataService} from '../../shared/services/saveProfileData.service';
import {StorageManagementService} from '../../shared/services/storage-management.service';
import {MessageRibbonService} from '../../shared/services/message-ribbon.service';
import {Decision} from './decision';
import {CustomEventService} from '../../shared/services/tealium/custom-event.service';
import {ErrorMessagingService} from '../../shared/services/error-messaging.service';
import {LeaveDynatraceAction} from '../../shared/utils/leaveDynatraceAction';
import {AppConstants} from '../../shared/constants/app.constants';
import {SessionConstants} from '../../shared/constants/session.constants';
import {AppLabelConstants} from '../../shared/constants/appLabel.constants';

function mobileNumberValidator(c: AbstractControl): {[key: string]: boolean} {
  if (!c.value || c.value.length === 0) {
    return {RequiredMobile: true};
  }
  if ( c.value && c.dirty && c.value.indexOf('_') > -1) {
    return {validateMinLength: true};
  }
  if (c.value && c.dirty && c.value.length > 8 && allDigitsSame(c.value.replace(/\D+/g, ''))) {
    return {validateConsecutiveDigits: true};
  }
  if (c.dirty && c.value.length > 8 && c.value.includes('(123) 456-789')) {
    //console.log("Error in seq");
    return {validateSequentialDigits: true};
  }
  return null;

}
function allDigitsSame(value) {
  const firstChar = value.charAt(0);
  let digitsSame = true;
  for (let i = 1; i < value.length; i++) {
    if (firstChar !== value[i]) {
      digitsSame = false;
      break;
    }
  }
  return digitsSame;
}

@Component({
  selector: 'app-popup-comm-pref',
  templateUrl: './popup-comm-pref.component.html',
  styleUrls: ['./popup-comm-pref.component.scss'],
})

export class PopupCommPrefComponent extends LeaveDynatraceAction implements OnInit, AfterViewInit {
  public appLabelConstants = AppLabelConstants;
  private mobile;
  public mobileno;
  private email;
  private updateProfileRequest: any;
  private updateProfileResponse: any;
  private profileData: any = {};
  public userForm: FormGroup;
  @Output() private ifChangeDone = new EventEmitter<boolean>();
  @Output() private ifChangesSaved = new EventEmitter<boolean>();
  @Input() checkedText = false;
  @Input() checkedStatus = false;
  @Output() public termsNotAgreed = false;
  @Input() emailValue: boolean;
  @Input() textValue: boolean;
  @Input() emailPaymentValue: boolean;
  @Input() textPaymentValue: boolean;
  @Input() statusEmailcheckboxValue: boolean;
  @Input() statusTextcheckboxValue: boolean;

  @Output() userDecision  = new EventEmitter<Decision>();
  private userDecisionToBeEmitted: Decision = new Decision();

  constructor(private modalService: ModalService,
              private updateData: SaveProfiledataService,
              private storageManagementService: StorageManagementService,
              private messageRibbon: MessageRibbonService,
              private customEventService: CustomEventService,
              private errorList: ErrorMessagingService) {
    super(storageManagementService);
  }

  ngOnInit() {
    this.userDecisionToBeEmitted = {
      decision : false,
      mobileno : '',
      email: ''
    };
    if ((sessionStorage.getItem(AppConstants.TERMS_NOT_AGREED)) === AppConstants.TRUE) {
      this.termsNotAgreed = true;
    } else {
      this.termsNotAgreed = false;
    }

    this.mobile = this.storageManagementService.getAPPSessionData(SessionConstants.MOBILE_NO);
    this.email = this.storageManagementService.getAPPSessionData(SessionConstants.EMAIL);
    this.setUserForm();
  }

  ngAfterViewInit() {
    super.ngAfterViewInit();
  }

  tagErrors(elementId, labelName, errMsg) {
    if (errMsg) {
      if (Object.keys(errMsg) && this.errorList.myErrors['' + Object.keys(errMsg)]) {
        const errorMsg = this.errorList.myErrors['' + Object.keys(errMsg)];

        if (errorMsg === this.errorList.myErrors.required) {
          this.customEventService.formFieldValidationErrors(['' + elementId], ['' + labelName + errorMsg]);
        } else {
          this.customEventService.formFieldValidationErrors(['' + elementId], ['' + errorMsg]);

        }
      }
    }
  }

  setUserForm() {
    this.userForm = new FormGroup({
      mobileno: new FormControl(this.mobileno, [mobileNumberValidator])
    });
    this.userForm.controls['mobileno'].setValue(this.mobile);
  }

  clickClose() {
    this.userDecisionToBeEmitted.decision = false;
    this.userDecisionToBeEmitted.mobileno = '';
    this.userDecision.emit(this.userDecisionToBeEmitted);
    this.modalService.closeModal(AppLabelConstants.SAVE_MODAL);
    this.modalService.closeModal(AppLabelConstants.SAVE_NO_MOB_MODAL);
    this.termsNotAgreed = false;
    const termsNotAgreed: string = this.termsNotAgreed.toString();
    sessionStorage.setItem(SessionConstants.TERMS_NOT_AGREED, termsNotAgreed);
    this.clearForm();
  }

  onclickterms() {
    window.open(AppConstants.TEXT_TCS);
  }

  saveChanges() {
    this.userDecisionToBeEmitted.decision = true;
    if ((this.statusTextcheckboxValue) &&
      this.userForm && this.userForm.value && this.userForm.value.mobileno) {
      this.userDecisionToBeEmitted.mobileno = this.userForm.value.mobileno.replace(/\D+/g, '');
    } else {
      this.userDecisionToBeEmitted.mobileno = this.mobileno;
    }
    if ((this.statusTextcheckboxValue) &&
      this.userForm && this.userForm.value && this.userForm.value.email) {
      this.userDecisionToBeEmitted.email = this.userForm.value.email;
    } else {
      this.userDecisionToBeEmitted.email = this.email
    }
    this.userDecision.emit(this.userDecisionToBeEmitted);
    this.modalService.closeModal(AppLabelConstants.SAVE_MODAL);
    this.modalService.closeModal(AppLabelConstants.SAVE_NO_MOB_MODAL);
    this.termsNotAgreed = false;
    const termsNotAgreed: string = this.termsNotAgreed.toString();
    sessionStorage.setItem(SessionConstants.TERMS_NOT_AGREED, termsNotAgreed);
    this.clearForm();
  }

  toggleTerms() {
    this.termsNotAgreed = !this.termsNotAgreed;
  }

  clearForm() {
    this.userForm.reset();
    }
}
