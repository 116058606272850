import {AfterViewInit, Component, OnInit} from '@angular/core';
import {ContentService} from '../../../../../shared/services/content.service';
import {ReferenceHelperService} from '../../../../../shared/services/reference-helper.service';
import {LeaveDynatraceAction} from '../../../../../shared/utils/leaveDynatraceAction';
import {StorageManagementService} from '../../../../../shared/services/storage-management.service';
import {AppConstants} from '../../../../../shared/constants/app.constants';
import {AppLabelConstants} from '../../../../../shared/constants/appLabel.constants';

@Component({
  selector: 'app-direct-deposit-tc',
  templateUrl: './direct-deposit-tc.component.html',
  styleUrls: ['./direct-deposit-tc.component.scss']
})
export class DirectDepositTCComponent extends LeaveDynatraceAction implements OnInit, AfterViewInit {

  pageTitle = AppLabelConstants.TnC;
  paras: string[] = [];
  private pageId: string;
  private owscContent;

  constructor(private contentService: ContentService,
              private referenceHelper: ReferenceHelperService,
              private storageManagementService: StorageManagementService) {
    super(storageManagementService);
    this.referenceHelper.setReferenceOption(AppConstants.SECTION_TITLE, AppLabelConstants.TnC);
  }

  ngOnInit() {
    this.pageId = this.contentService.getPageId(AppConstants.DIRECT_DEPOSIT_TNC);
    this.contentService.getOWCSResponse(this.pageId, AppConstants.DIRECT_DEPOSIT_TNC_U).subscribe(
      val => {
        if (val) {
          this.owscContent = val;
          this.paras = this.owscContent.data.DDT_C_01_Body.value;
        }
      }, (error) => {
        console.error(error);
      });
  }

  ngAfterViewInit() {
    super.ngAfterViewInit();
  }

  /*clickClose()
  {
    window.close();
  }*/
}
