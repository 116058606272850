import {Component, Input, OnInit} from '@angular/core';
import {LetterListService} from '../../../../shared/services/letter-list.service';
import {DocumentInfo} from '../../../../shared/models/document.model';
import {LetterClaimDetails, LetterListResponse} from '../../../../shared/models/letterList.model';
import {LetterImageCodeService} from '../../../../shared/services/letter-image-code.service';
import {StorageManagementService} from '../../../../shared/services/storage-management.service';
import {Router} from '@angular/router';
import {AppLabelConstants} from '../../../../shared/constants/appLabel.constants';
import {SessionConstants} from '../../../../shared/constants/session.constants';

/**
 * View Letters Component
 */
@Component({
  selector: 'app-view-letters',
  templateUrl: 'view-letters.component.html',
  styleUrls: ['view-letters.component.scss']
})
export class ViewLettersComponent implements OnInit {

  @Input() public isClaimAA: boolean = false;
  @Input() public claimEventId: string;
  public noLettersMsg: string;
  public letterRoute: string = AppLabelConstants.MORE_LETTERS_LOCATION;
  public letterList: DocumentInfo[] = [];
  public seeMoreLbl: string = AppLabelConstants.SEE_MORE_LETTERS;
  public personId: string;
  public currentRoute;
  public appLabelConstants = AppLabelConstants;

  /**
   * @param letterListService
   * @param letterImageCodeService
   * @param storageManagementService
   * @param router
   */
  constructor(private letterListService: LetterListService,
              private letterImageCodeService: LetterImageCodeService,
              private storageManagementService: StorageManagementService,
              private router: Router) {
    this.currentRoute = router.url;
  }

  ngOnInit() {
    this.noLettersMsg = this.isClaimAA ? AppLabelConstants.NO_LETTERS_MSG_AA : AppLabelConstants.NO_LETTER_MSG;
    if (this.isClaimAA === true) {
    this.callGetLetterMapping();
    }
  }

  /**
   * To get list of letters
   */
  callGetLetterMapping() {
    const userData = this.storageManagementService.getAppData(SessionConstants.USER_DATA);
    const personIds = userData ? userData.personIds : [];
    if (personIds && personIds.length > 0) {
      this.personId = personIds[0];
    }
    this.letterListService.getLettersList(this.personId, this.claimEventId, this.currentRoute).subscribe((letterMappingsResponse: LetterListResponse) => {
      if (letterMappingsResponse && letterMappingsResponse.letters && letterMappingsResponse.letters.length > 0) {
        const letterList: LetterClaimDetails[] = letterMappingsResponse.letters.filter((letterClaimDetails: LetterClaimDetails) => letterClaimDetails.claimNumber.toString() === this.claimEventId);
        letterList.forEach(item => {
          this.letterList.push(new DocumentInfo(item.letterId, item.letterName, item.createDate));
        });
        this.storageManagementService.setSessionData('letters', letterList);
      }
    });
  }
  /**
   * To get letter user clicked on
   *
   * @param letterId
   */
  viewLetter(letterId: string) {
    this.letterImageCodeService.getLetterImageService(this.personId, letterId).subscribe(
      (data) => {
        if (data && data.imgStream) {
          const mimeType = 'application/pdf';
          const res = this.letterImageCodeService.b64toBlob(data.imgStream, mimeType, 512);
          const blob = new Blob([res], {type: mimeType});
          const fileObjectURL = URL.createObjectURL(blob);

          if (window.navigator.appVersion.toString().indexOf('.NET') > 0) { // for IE browser
            const nav = (window.navigator as any);
            if (nav.msSaveOrOpenBlob) {
              nav.msSaveOrOpenBlob(blob, fileObjectURL);
            }
          } else {
            const link = document.createElement('a');
            link.href = fileObjectURL;
            link.download = 'Letter ' + letterId;
            link.click();
          }
        }
      }
    );
  }
}
