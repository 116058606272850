import {GbCiamBase} from './gb-ciam-base';

export class GbCiamCommonUtil extends GbCiamBase {

  getUrls() {
    return {
  /*    CIAMLoginURL: this.getCiamAccountUrl() + ((this.getEnvName() !== 'ltd' && this.getEnvName() !== 'local') ? '/customer/#/login?appid=EELTD2' : '/ews_ciam/#/login'),
        CIAMRegisterURL: this.getCiamAccountUrl() + ((this.getEnvName() !== 'ltd' && this.getEnvName() !== 'local') ?  '/customer/#/urlSearch/common?appid=EELTD2' : '/ews_ciam/#/urlSearch/common'),*/
     // CIAMLoginURL: this.getCiamAccountUrl() + '/customer/#/login?appid=' + this.getCiamAppId(),
     // CIAMRegisterURL: this.getCiamAccountUrl() + '/customer/#/registration?appid=' + this.getCiamAppId(),
      CIAMEditContactURL : this.getCiamAccountUrl() + '/customer/updateContactInfo?',
      CIAMEditPasswordURL : this.getCiamAccountUrl() + '/customer/updatePassword?',
    //  CIAMLoginURL: this.getCiamAccountUrl() + '/customer/#/login?appid=' + this.getCiamAppId(),
     // CIAMRegisterURL: this.getCiamAccountUrl() + '/customer/#/registration?appid=' + this.getCiamAppId(),
      // CIAMRegisterURL: this.getCiamAccountUrl() + '/customer/#/urlSearch/common?appid=' + this.getCiamAppId(),
      SfdAccountUrl : this.getSfdAccountUrl(),
      getUuidURL : this.getBaseUrl() + 'gb-profile-sync-service/profile-sync/v1/ciam/users?' + this.getClientId('auth'),
      CIAMLoginURL: this.getCiamAccountLoginUrl(),
      CIAMRegisterURL: this.getCiamAccountRegistrationUrl(),
      updateUserPaswordURL : this.getCiamApiSgwUrl() + 'ea/ciam/reg/managed/user/{uuId}?' + this.getCiamSgwClientId('auth'),
      // LoggedOutApiURL: this.getCiamApiSgwUrl() + 'ea/ciam/auth/sessions?_action=logout&realm=Customers&' + this.getCiamSgwClientId('auth'),
      // getWeakProfileURL : this.getCiamApiSgwUrl() + 'ea/ciam/reg/managed/user?_queryFilter=EEProfileKey eq {profileid}&' + this.getCiamSgwClientId('auth'),
      SessionInfoApiURL: this.getCiamApiSgwUrl() + 'ea/ciam/auth/sessions?_action=getSessionInfo&' + this.getCiamSgwClientId('auth'),
    //  appLandingPage_URL: 'https://ltd3-cxr.thehartford.com'
      //getWeakProfileUrl:'https://openidm-service-ltq.thehartford.com/openidm/managed/user?_queryFilter=EEProfileKey eq “936284”&client_id=1024&target_env=qa3',
    }
  }
}
