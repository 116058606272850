import {Directive} from '@angular/core';
import {AbstractControl, NG_VALIDATORS, Validator} from '@angular/forms';

@Directive({
  selector: '[validateConsecutiveDigits]',
  providers: [{provide: NG_VALIDATORS, useExisting: ValidateConsecutiveDigitsPattern, multi: true}]
})
export class ValidateConsecutiveDigitsPattern implements Validator {

  validate(control: AbstractControl) {
    if (control.value && control.dirty && control.value.length > 8 && this.allDigitsSame(control.value)) {
      return {validateConsecutiveDigits: true};
    }
    return null;
  }

  allDigitsSame(value) {
    const firstChar = value.charAt(0);
    let digitsSame = true;
    for (let i = 1; i < value.length; i++) {
      if (firstChar !== value[i]) {
        digitsSame = false;
        break;
      }
    }
    return digitsSame;
  }
}
