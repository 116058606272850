import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { AppConstants } from 'app/shared/constants/app.constants';
import { AppLabelConstants } from 'app/shared/constants/appLabel.constants';
import { SessionConstants } from 'app/shared/constants/session.constants';
import { ContentService } from 'app/shared/services/content.service';
import { PsvService } from 'app/shared/services/psv.service';
import { StorageManagementService } from 'app/shared/services/storage-management.service';
import { IVideoDetails } from '../../../../shared/models/videoList.model';
import { LeaveListModel } from 'app/shared/models/gbLeave.model';
import { RelatedClaims } from 'app/shared/models/relatedClaims.model';
import { env } from '@env/environment-loader';
import {ModalService} from 'app/shared/services/modal.service';
import {CustomEventService} from 'app/shared/services/tealium/custom-event.service';

@Component({
  selector: 'app-view-videos',
  templateUrl: './view-videos.component.html',
  styleUrls: ['./view-videos.component.scss']
})
export class ViewVideosComponent implements OnInit , OnDestroy {
  @Input() pageName: string = 'CD';
  @Input() videoCount: number = 3;
  @Input() public claimID: string;
  public alertDescription: string;
  public psvUrl: string;
  public uploadDate: string;
  public videoList: IVideoDetails[] = [];
  public videoRoute: string = AppLabelConstants.MORE_VIDEOS_LOCATION;
  public displayStyle: string = 'none';
  //OWCS
  public videoLinkLabel: string;
  public watchAgainVideoLinkLable: string;
  public approvalVideoDescriptionLabel: string;
  public day1VideoDescriptionLabel: string;
  public concurrentVideoDescriptionLabel: string;
  public extensionVideoDescriptionLabel: string;
  public disclaimerLabel: string;
  public noVideoMsgLabel: string;
  public pageId2;
  public seeMoreLabel: string;
  public leaveData: LeaveListModel;
  public curClaim: RelatedClaims;
  public isPSV: boolean = false;
  public isLoading: boolean;
  public approvalVideoUsecaseName = AppLabelConstants.APPROVAL_VIDEO_USECASE_NAME;
  public day1VideoUsecaseName = AppLabelConstants.DAY1_VIDEO_USECASE_NAME;
  public concurrentVideoUsecaseName = AppLabelConstants.CONCURRENT_VIDEO_USECASE_NAME;
  //Telium VideoList
  public teliumVideoIntancesID: string[] = [];
  private owscContent;
  private yourVideosOwscContent;
  private pageId: string;
  private psvVideoAlertsSubscription;

  constructor(private psvservice: PsvService,
    private contentService: ContentService,
    private storageManagementService: StorageManagementService,
    private modalService: ModalService,
    private customEventService: CustomEventService) {
    }

  ngOnInit() {
    this.populateContent();
    this.callGetVideosMapping();
  }

  /**
   * Service call to get psv details
   */
  callGetVideosMapping() {
    if (this.storageManagementService.retrieveSessionObject(SessionConstants.CUR_CLAIM)) {
    this.curClaim = JSON.parse(this.storageManagementService.retrieveSessionObject(SessionConstants.CUR_CLAIM)).curClaim;
    if (this.curClaim.claimEventId && this.curClaim.personId) {
      if (this.storageManagementService.getSessionData(this.curClaim.claimEventId + 'videos')) {
        this.videoList = this.storageManagementService.getSessionData(this.curClaim.claimEventId + 'videos');
      } else {
      this.psvVideoAlertsSubscription = this.psvservice.getPsvVideoAlertsForClaim(this.curClaim.claimEventId, this.curClaim.personId).subscribe((videoListResponse: string) => {
        if (videoListResponse) {
          const data = JSON.parse(videoListResponse);
          const _videoList: IVideoDetails[] = data.videos;
          _videoList.forEach(innerItem => {
          this.videoList.push( {
            Alert_Description: innerItem.Alert_Description,
            PsvURL: innerItem.PsvURL,
            Upload_Date: innerItem.Upload_Date,
            Psv_TemplateName: innerItem.Psv_TemplateName,
            Psv_UseCaseName: innerItem.Psv_UseCaseName,
            PsvInstanceID: innerItem.PsvInstanceID
          });
        });
        this.storageManagementService.setSessionData(this.curClaim.claimEventId + 'videos', this.videoList);
        }
      });
    }
  }
  }
}

ngOnDestroy() {
  if (this.psvVideoAlertsSubscription) {
    this.psvVideoAlertsSubscription.unsubscribe();
  }
}

/* Tealium Code Integration */
trackWatchNowButton(buttonClickInfo) {
  this.customEventService.trackWatchNowButton(buttonClickInfo);
}

trackWatchNowLink(linkClickInfo) {
  this.customEventService.trackWatchNowLink(linkClickInfo);
}
isContainInstanceID(PsvInstanceID) {
 if (this.teliumVideoIntancesID.length === 0) {
    this.teliumVideoIntancesID = window['utag_data']['va.property_sets.8758'] === undefined ? [] : window['utag_data']['va.property_sets.8758'];
    }
  return this.teliumVideoIntancesID.find(id => id === PsvInstanceID.toString()) === undefined;
}
  openPopup(inputUrl: string, ind: number, type: number) {
    this.isPSV = !this.isPSV;
    this.modalService.openModal('PSV');
    const watchId = 'watch_' + ind;
    const alertId = 'title_' + ind;
    this.isLoading = true;
    document.getElementById('iframev').innerHTML = '<object type="text/html" data="' + inputUrl + '" title="Video" height="90%" width="100%" style="border:1px solid gray"></object>';
    if (type === 1) {
    document.getElementById(alertId)?.classList.add('activeButton');
    } else {
      document.getElementById(watchId)?.classList.add('activeButton');
    }
    setTimeout(() => {
      this.isLoading = false;
    }, 3000);
  }

  populateContent() {
    if (this.storageManagementService.getSessionData(SessionConstants.OWCS_CONTENT) && this.storageManagementService.getSessionData(SessionConstants.YOUR_VIDEOS_OWCS_CONTENT)) {
      this.fetchOWCSContentFromSession();
    } else {
      this.fetchOWCSContentFromService();
    }
  }
  getYourVideosOWCSContent(yourVideosOwscContent) {
    if (yourVideosOwscContent && yourVideosOwscContent.data) {
      if (yourVideosOwscContent.data.YV_10_Body) {
        this.day1VideoDescriptionLabel = yourVideosOwscContent.data.YV_10_Body.value;
      }
      if (this.pageName === 'YV') {
        if (yourVideosOwscContent.data.YV_06_Body) {
          this.videoLinkLabel = yourVideosOwscContent.data.YV_06_Body.value;
        }
        if (yourVideosOwscContent.data.YV_07_Body) {
          this.watchAgainVideoLinkLable = yourVideosOwscContent.data.YV_07_Body.value;
        }
        if (yourVideosOwscContent.data.YV_09_Body) {
          this.disclaimerLabel = yourVideosOwscContent.data.YV_09_Body.value;
        }
        if (yourVideosOwscContent.data.YV_11_Body) {
          this.concurrentVideoDescriptionLabel = yourVideosOwscContent.data.YV_11_Body.value;
        }
        if (yourVideosOwscContent.data.YV_12_Body) {
          this.extensionVideoDescriptionLabel = yourVideosOwscContent.data.YV_12_Body.value;
        }
      }
    }
  }
  getOWCSContent(owscContent) {
    if (owscContent) {
      if (owscContent.CD_356_Body) {
        this.approvalVideoDescriptionLabel = owscContent.CD_356_Body.value;
      }
      if (owscContent.CD_353_Body) {
        this.noVideoMsgLabel = owscContent.CD_353_Body.value;
      }
      if (this.pageName === 'CD') {
        if (owscContent.CD_354_Body) {
          this.videoLinkLabel = owscContent.CD_354_Body.value
        }
        if (owscContent.CD_355_Body) {
          this.watchAgainVideoLinkLable = owscContent.CD_355_Body.value
        }
        if (owscContent.CD_357_Body) {
          this.disclaimerLabel = owscContent.CD_357_Body.value;
        }
        if (owscContent.CD_358_Body) {
          this.seeMoreLabel = owscContent.CD_358_Body.value.replace(/^<div[^>]*>|<\/div>$/g, '');
        }
        if (owscContent.CD_359_Body) {
          this.concurrentVideoDescriptionLabel = owscContent.CD_359_Body.value;
        }
        if (owscContent.CD_360_Body) {
          this.extensionVideoDescriptionLabel = owscContent.CD_360_Body.value;
        }
      }
    }
  }
  fetchOWCSContentFromSession() {
    this.yourVideosOwscContent = this.storageManagementService.getSessionData(SessionConstants.YOUR_VIDEOS_OWCS_CONTENT);
    this.owscContent = this.storageManagementService.getSessionData(SessionConstants.OWCS_CONTENT);
    this.getOWCSContent(this.owscContent);
    this.getYourVideosOWCSContent(this.yourVideosOwscContent);
  }

  fetchOWCSContentFromService() {
    this.pageId = this.contentService.getPageId(AppConstants.CLAIM_DETAILS);
    this.pageId2 = this.contentService.getPageId(AppConstants.YOUR_VIDEOS);
    if (this.pageId2) {
    this.contentService.getOWCSResponse(this.pageId2, AppConstants.YOUR_VIDEOS).subscribe(
      val => {
        if (val) {
          this.yourVideosOwscContent = val;
          this.getYourVideosOWCSContent(this.yourVideosOwscContent);
          this.storageManagementService.setSessionData(SessionConstants.YOUR_VIDEOS_OWCS_CONTENT, this.yourVideosOwscContent);
          }
      });
    }
    if (this.pageId) {
    this.contentService.getOWCSResponse(this.pageId, AppConstants.CLAIM_DETAILS).subscribe(
      val => {
        if (val) {
          this.owscContent = val;
          this.getOWCSContent(this.owscContent.data);
          this.storageManagementService.setSessionData(SessionConstants.OWCS_CONTENT, this.owscContent.data);
        }
      });
    }
  }

  psvClosePopup($event) {
    document.getElementById('iframev').innerHTML = '';
    const _id = document.querySelector('.activeButton')?.id;
    document.getElementById(_id)?.focus();
    document.getElementById(_id)?.classList.remove('activeButton');
  }
}
