import {Component, Input} from '@angular/core';
import {ButtonComponent} from '../../../sub-atomic/button/button.component';

//Component - 10
@Component({
  selector: 'primary-button',
  templateUrl: 'primary-button.component.html',
  styleUrls: ['primary-button.component.scss']
})

export class PrimaryButtonComponent extends ButtonComponent {
  @Input() buttonColor = 'green';
  @Input() buttonLoading = 'done';
  @Input() buttonSize = '';
  @Input() textWeight = '';
  @Input() defaultSpinnerText: string = 'Saving';
  @Input() isSpinnerDisabled: string = 'true';
  @Input() type: string = '';
  @Input() buttonDisabled: boolean = false;
  @Input() hukStyleButton: boolean = false;
  @Input() btnId: string;

  private currentEv;

  constructor() {
    super();
  }


  buttonClicked(event) {
    if (event.target.dataset.disabled === 'true') {
      event.preventDefault();
      event.stopImmediatePropagation();
      event.stopPropagation();
    } else if (this.isSpinnerDisabled === 'false') {
      event.target.dataset.disabled = 'true';
      this.currentEv = event;
      this.buttonLoading = 'loading';
    }
    if (!this.buttonDisabled) {
      event.target.dataset.disabled = 'false';
    }
  }

  reset() {
    if (this.currentEv) {
      this.currentEv.target.dataset.disabled = false;
    }
    this.buttonLoading = 'done';
  }
}
