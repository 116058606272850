import { MagnoliaContent } from "../models/magnolia-content.model";

export class MagnoliaUtilityService{
    constructor(){}

    /*
  * create a map of magnolia content from the content object and passed id(s)
  * @param magnoliaContent    - magnolia content object to fetch data from
  * @param magnoliaContentId  - id(s) to be used as key for the map
  * @return               - map of id as key and label content as value
  * */
  createMagnoliaMap(magnoliaContent: MagnoliaContent, magnoliaContentId: string[]): Map<string, string> {
    const magnoliaMap: Map<string, string> = new Map();

    magnoliaContentId.forEach(id => {
      magnoliaMap.set(id, this.getMagnoliaContentValue(magnoliaContent, id));
    });
    return magnoliaMap;
  }

  /*
  * get value for magnolia item from corresponding label
  * @param magnoliaContent - magnolia content object to fetch data from
  * @param label       - label to find and fetch data for from magnolia content object
  * @return            - label to display on UI
  * */
  getMagnoliaContentValue(magnoliaContent: MagnoliaContent, label: string): string {
    if (magnoliaContent?.data && magnoliaContent.data[label]?.value) {
        const div = document.createElement('div');
      div.innerHTML = magnoliaContent.data[label].value;
          return div.innerText.trim();
    }
    return '';
  }

    getMagnoliaContent(key: string, magnoliaContent: MagnoliaContent): string{
        if (magnoliaContent && magnoliaContent.data[key]) {
          return magnoliaContent.data[key].value;
        }
        return '';
    }

    // Old calc functionality

  createMagnoliaMapLoa(magnoliaContent: MagnoliaContent, magnoliaContentId: string[]): Map<string, string> {
    const magnoliaMap: Map<string, string> = new Map();

    magnoliaContentId.forEach(id => {
      magnoliaMap.set(id, this.getMagnoliaContentValueLoa(magnoliaContent, id));
    });
    return magnoliaMap;
  }

  /*
* get value for magnolia item from corresponding label
* @param magnoliaContent - magnolia content object to fetch data from
* @param label       - label to find and fetch data for from magnolia content object
* @return            - label to display on UI
* */
  getMagnoliaContentValueLoa(magnoliaContent: MagnoliaContent, label: string): string {
    if (magnoliaContent && magnoliaContent[label] && magnoliaContent[label]?.value) {
      const div = document.createElement('div');
      div.innerHTML = magnoliaContent[label]?.value;
      return div.innerText.trim();
    }
    return '';
  }

  getMagnoliaContentLoa(key: string, magnoliaContent: MagnoliaContent): string{
    if (magnoliaContent && magnoliaContent[key]) {
      return magnoliaContent[key].value;
    }
    return '';
  }
}
