import { Injectable } from "@angular/core";
import {
  BondingIntakeSessionConstants,
  IntakeSessionConstants,
} from "@app/cxr/modules/intake/shared/constants/intakeSession.constants";
import { BehaviorSubject, ReplaySubject, Subject } from "rxjs";
import { RaeSessionConstants } from "../constants/raeSession.constants";
import { SessionConstants } from "../constants/session.constants";
import { Feature } from "../models/Feature";
import { ProvisionResponse } from "../models/Provision.model";
import { PolicyAdminCoverageResponse } from "../models/coveragePlan.model";
import { NotificationDetails } from "../models/notificationDetails.model";

@Injectable()
export class StorageManagementService {
  public isUserClaimsSet: BehaviorSubject<boolean> = new BehaviorSubject(false);
  public curPageSetFlag: BehaviorSubject<boolean> = new BehaviorSubject(false);
  public isOverViewLoaded: BehaviorSubject<boolean> = new BehaviorSubject(true);
  public disabilityContactsForExtension = new Subject();
  public isClaimRepInfoSet: ReplaySubject<boolean> = new ReplaySubject<boolean>(1);
  public isFeaturesTurnOnOffDataSetInSession: ReplaySubject<boolean> = new ReplaySubject<boolean>(
    1,
  );
  public toggleMsgRibbon: BehaviorSubject<boolean> = new BehaviorSubject(false);
  public showAllLeaveInd: BehaviorSubject<boolean> = new BehaviorSubject(false);
  public gridValidation: BehaviorSubject<boolean> = new BehaviorSubject(false);

  appSessionData: any = {};

  bondingIntakeConst = BondingIntakeSessionConstants;

  setShowAllLeaveInd(showAllLeaveInd: boolean) {
    this.showAllLeaveInd.next(showAllLeaveInd);
  }

  getShowAllLeaveInd() {
    return this.showAllLeaveInd;
  }

  setIsUserClaimsSetFlag(isUserClaimsSet: boolean) {
    this.isUserClaimsSet.next(isUserClaimsSet);
  }

  setNotificationsData(notificationDetails: NotificationDetails) {
    this.setSessionData(SessionConstants.NOTIFICATION_DETAILS, notificationDetails);
  }

  getNotificationsData(): NotificationDetails {
    return this.getSessionData(SessionConstants.NOTIFICATION_DETAILS);
  }

  getIsUserClaimsSetFlag() {
    return this.isUserClaimsSet;
  }

  /* Common set of functionality to set to work with session storage*/
  setSessionObject(objToSetKey: string, ObjToSetValue) {
    sessionStorage.setItem(objToSetKey, JSON.stringify({ [objToSetKey]: ObjToSetValue }));
  }

  setSessionObjectNoJSON(objToSetKey: string, ObjToSetValue) {
    sessionStorage.setItem(objToSetKey, ObjToSetValue);
  }

  removeSessionObject(objToSetKey) {
    sessionStorage.removeItem(objToSetKey);
  }

  retrieveSessionObject(objToSetKey) {
    return sessionStorage.getItem(objToSetKey);
  }

  isSessionStorageEmpty(): boolean {
    return sessionStorage.length === 0;
  }

  /* Common set of functionality to set to work with local storage*/
  setLocalObject(objToSetKey: string, ObjToSetValue) {
    localStorage.setItem(objToSetKey, JSON.stringify({ [objToSetKey]: ObjToSetValue }));
  }

  removeLocalObject(objToSetKey) {
    localStorage.removeItem(objToSetKey);
  }

  retrieveLocalObject(objToSetKey) {
    return localStorage.getItem(objToSetKey);
  }

  setLoginState(loginStatus) {
    const sessionObj = JSON.parse(this.retrieveSessionObject(SessionConstants.APP_DATA)).appData;
    sessionObj.loginStatus = loginStatus;
    this.setSessionObject(SessionConstants.APP_DATA, sessionObj);
  }

  setCaseData(caseData) {
    const sessionObj = JSON.parse(this.retrieveSessionObject(SessionConstants.APP_DATA)).appData;
    sessionObj.caseData = caseData;
    this.setSessionObject(SessionConstants.APP_DATA, sessionObj);
  }

  getCaseData() {
    const sessionObj = JSON.parse(this.retrieveSessionObject(SessionConstants.APP_DATA)).appData;
    return sessionObj ? sessionObj.caseData : null;
  }

  setDefaultCaseId(defaultCaseId: string) {
    const sessionObj = JSON.parse(this.retrieveSessionObject(SessionConstants.APP_DATA)).appData;
    sessionObj.caseData.defaultCaseId = defaultCaseId;
    this.setSessionObject(SessionConstants.APP_DATA, sessionObj);
  }

  setDefaultCaseDataForSSO(defaultCaseID: string, defaultPartyRefID: string) {
    const sessionObj = JSON.parse(this.retrieveSessionObject(SessionConstants.APP_DATA)).appData;
    sessionObj.caseData = { defaultCaseId: defaultCaseID, defaultPartyRefId: defaultPartyRefID };
    this.setSessionObject(SessionConstants.APP_DATA, sessionObj);
  }

  setForcedEmployerSelectFlag() {
    const sessionObj = JSON.parse(this.retrieveSessionObject(SessionConstants.APP_DATA)).appData;
    sessionObj.forcedCaseSelectFlag = true;
    this.setSessionObject(SessionConstants.APP_DATA, sessionObj);
  }

  getForcedEmployerSelectFlag() {
    const sessionObj = JSON.parse(this.retrieveSessionObject(SessionConstants.APP_DATA)).appData;
    return sessionObj ? sessionObj.forcedCaseSelectFlag : null;
  }

  deleteForcedEmployerSelectFlag() {
    const sessionObj = JSON.parse(this.retrieveSessionObject(SessionConstants.APP_DATA)).appData;
    delete sessionObj.forcedCaseSelectFlag;
    this.setSessionObject(SessionConstants.APP_DATA, sessionObj);
  }

  deleteActivePersonId() {
    const sessionObj = JSON.parse(this.retrieveSessionObject(SessionConstants.APP_DATA)).appData;
    delete sessionObj.activeUserId;
    this.setSessionObject(SessionConstants.APP_DATA, sessionObj);
  }

  setUserData(user) {
    const sessionObj = JSON.parse(this.retrieveSessionObject(SessionConstants.APP_DATA)).appData;
    sessionObj.userData = user;
    this.setSessionObject(SessionConstants.APP_DATA, sessionObj);
  }

  setBankingDetails(data) {
    const sessionObj = JSON.parse(this.retrieveSessionObject(SessionConstants.APP_DATA)).appData;
    sessionObj.bankingDetails = data;
    this.setSessionObject(SessionConstants.APP_DATA, sessionObj);
  }

  getBankDetails() {
    const sessionObj = JSON.parse(this.retrieveSessionObject(SessionConstants.APP_DATA))
      ? JSON.parse(this.retrieveSessionObject(SessionConstants.APP_DATA)).appData
      : null;
    return sessionObj ? sessionObj.bankingDetails : null;
  }

  setUserAddressDetails(userAddressDetails) {
    const sessionObj = JSON.parse(this.retrieveSessionObject(SessionConstants.APP_DATA)).appData;
    sessionObj.userData.userAddr = userAddressDetails;
    this.setSessionObject(SessionConstants.APP_DATA, sessionObj);
  }

  getUserAddressDetails() {
    const sessionObj = JSON.parse(this.retrieveSessionObject(SessionConstants.APP_DATA))
      ? JSON.parse(this.retrieveSessionObject(SessionConstants.APP_DATA)).appData
      : null;
    return sessionObj ? sessionObj.userAddr : null;
  }

  getUserData() {
    const sessionObj = JSON.parse(this.retrieveSessionObject(SessionConstants.APP_DATA))
      ? JSON.parse(this.retrieveSessionObject(SessionConstants.APP_DATA)).appData
      : null;
    return sessionObj ? sessionObj.userData : null;
  }
  setProfile(userprofile) {
    const sessionData = JSON.parse(this.retrieveSessionObject(SessionConstants.APP_DATA)).appData;
    sessionData.profileDetails = userprofile;
    this.setSessionObject(SessionConstants.APP_DATA, sessionData);
  }

  getProfile() {
    const sessionObj = JSON.parse(this.retrieveSessionObject(SessionConstants.APP_DATA)).appData
      ? JSON.parse(this.retrieveSessionObject(SessionConstants.APP_DATA)).appData
      : null;
    return sessionObj ? sessionObj.profileDetails : null;
  }

  setCIAMProfile(userprofile) {
    const sessionData = JSON.parse(this.retrieveSessionObject(SessionConstants.APP_DATA)).appData;
    sessionData.profileDataCIAM = userprofile;
    this.setSessionObject(SessionConstants.APP_DATA, sessionData);
  }

  getCIAMProfile() {
    const sessionObj = JSON.parse(this.retrieveSessionObject(SessionConstants.APP_DATA)).appData
      ? JSON.parse(this.retrieveSessionObject(SessionConstants.APP_DATA)).appData
      : null;
    return sessionObj ? sessionObj.profileDataCIAM : null;
  }

  getClaims() {
    const sessionObj = JSON.parse(this.retrieveSessionObject(SessionConstants.APP_DATA)).appData
      ? JSON.parse(this.retrieveSessionObject(SessionConstants.APP_DATA)).appData
      : null;
    return sessionObj ? sessionObj.userClaims : null;
  }

  setUserClaims(data) {
    const sessionObj = JSON.parse(this.retrieveSessionObject(SessionConstants.APP_DATA)).appData;
    sessionObj.userClaims = data;
    this.setSessionObject(SessionConstants.APP_DATA, sessionObj);
    this.setIsUserClaimsSetFlag(true);
  }

  setPaymentDetails(data) {
    const sessionObj = JSON.parse(this.retrieveSessionObject(SessionConstants.APP_DATA)).appData;
    sessionObj.paymentDetails = data;
    this.setSessionObject(SessionConstants.APP_DATA, sessionObj);
  }

  setProvisions(data: ProvisionResponse) {
    const sessionObj = JSON.parse(this.retrieveSessionObject(SessionConstants.APP_DATA)).appData;
    sessionObj.provisions = data;
    this.setSessionObject(SessionConstants.APP_DATA, sessionObj);
  }

  getProvisions(): ProvisionResponse {
    const sessionObj = JSON.parse(this.retrieveSessionObject(SessionConstants.APP_DATA)).appData
      ? JSON.parse(this.retrieveSessionObject(SessionConstants.APP_DATA)).appData
      : null;
    return sessionObj ? sessionObj.provisions : null;
  }

  setClaimPayments(data) {
    const sessionObj = JSON.parse(this.retrieveSessionObject(SessionConstants.APP_DATA)).appData;
    sessionObj.claimPayments = data;
    this.setSessionObject(SessionConstants.APP_DATA, sessionObj);
  }

  getClaimPayments() {
    const sessionObj = JSON.parse(this.retrieveSessionObject(SessionConstants.APP_DATA)).appData
      ? JSON.parse(this.retrieveSessionObject(SessionConstants.APP_DATA)).appData
      : null;
    return sessionObj ? sessionObj.claimPayments : null;
  }

  setLeaveDtls(data) {
    const sessionObj = JSON.parse(this.retrieveSessionObject(SessionConstants.APP_DATA)).appData;
    sessionObj.leaveService = data;
    this.setSessionObject(SessionConstants.APP_DATA, sessionObj);
  }

  getLeaveDtls() {
    const sessionObj = JSON.parse(this.retrieveSessionObject(SessionConstants.APP_DATA)).appData
      ? JSON.parse(this.retrieveSessionObject(SessionConstants.APP_DATA)).appData
      : null;
    return sessionObj ? sessionObj.leaveService : null;
  }

  //e-consent coverages
  setCoveragePlans(data: PolicyAdminCoverageResponse) {
    const sessionObj = JSON.parse(this.retrieveSessionObject(SessionConstants.APP_DATA)).appData;
    sessionObj.coveragePlans = data;
    this.setSessionObject(SessionConstants.APP_DATA, sessionObj);
  }

  getCoveragePlans(): PolicyAdminCoverageResponse {
    const sessionObj = JSON.parse(this.retrieveSessionObject(SessionConstants.APP_DATA))
      ? JSON.parse(this.retrieveSessionObject(SessionConstants.APP_DATA)).appData
      : null;
    return sessionObj ? sessionObj.coveragePlans : null;
  }

  setMudiCoverage(data) {
    const sessionObj = JSON.parse(this.retrieveSessionObject(SessionConstants.APP_DATA)).appData;
    sessionObj.coveragesMudi = data;
    this.setSessionObject(SessionConstants.APP_DATA, sessionObj);
  }

  getMudiCoverage() {
    const sessionObj = JSON.parse(this.retrieveSessionObject(SessionConstants.APP_DATA)).appData
      ? JSON.parse(this.retrieveSessionObject(SessionConstants.APP_DATA)).appData
      : null;
    return sessionObj ? sessionObj.coveragesMudi : null;
  }

  setCurrentPage(pagename) {
    const sessionObj = JSON.parse(this.retrieveSessionObject(SessionConstants.APP_DATA)).appData;
    sessionObj.currentPage = pagename;
    this.setSessionObject(SessionConstants.APP_DATA, sessionObj);
    this.curPageSetFlag.next(true);
  }

  setClaimsFlag(reload) {
    const sessionObj = JSON.parse(this.retrieveSessionObject(SessionConstants.APP_DATA)).appData;
    sessionObj.reloadClaims = reload;
    this.setSessionObject(SessionConstants.APP_DATA, sessionObj);
  }

  getClaimsFlag() {
    const sessionObj =
      JSON.parse(this.retrieveSessionObject(SessionConstants.APP_DATA)).appData || null;
    return sessionObj.reloadClaims || null;
  }

  getPhoneNumber() {
    const sessionObj =
      JSON.parse(this.retrieveSessionObject(SessionConstants.APP_DATA)).appData || null;
    return (
      sessionObj.profileDetails.phoneNumber ||
      sessionObj.profileDetails.econsentDataResponseBeanList[0].econsentCustomerList[0]
        .eConsentBusinessGroupPartyInfo.eConsentPhoneNumber ||
      sessionObj.profileDetails.econsentDataResponseBeanList[0].econsentCustomerList[0]
        .eConsentBusinessGroupPartyInfo.eConsentGetContactPointInfoTypeList[0].phoneNumber ||
      null
    );
  }

  setClaimWizardRecommendation(recommendation) {
    const sessionObj = JSON.parse(this.retrieveSessionObject(SessionConstants.APP_DATA)).appData;
    sessionObj.userData.claimWizardRecommendation = recommendation;
    this.setSessionObject(SessionConstants.APP_DATA, sessionObj);
  }

  getClaimWizardRecommendation() {
    const sessionObj = JSON.parse(this.retrieveSessionObject(SessionConstants.APP_DATA)).appData;
    return sessionObj.userData.claimWizardRecommendation;
  }

  removeClaimWizardRecommendation() {
    const sessionObj = JSON.parse(this.retrieveSessionObject(SessionConstants.APP_DATA)).appData;
    delete sessionObj.userData.claimWizardRecommendation;
    this.setSessionObject(SessionConstants.APP_DATA, sessionObj);
  }

  setSessionIden(identifier) {
    const sessionObj = JSON.parse(this.retrieveSessionObject(SessionConstants.APP_DATA)).appData;
    sessionObj.sessionIdentifier = identifier;
    this.setSessionObject(SessionConstants.APP_DATA, sessionObj);
  }

  getSessionIden() {
    const sessionObj = JSON.parse(this.retrieveSessionObject(SessionConstants.APP_DATA)).appData;
    return sessionObj.sessionIdentifier;
  }

  setProfileId(profileId) {
    const sessionObj = JSON.parse(this.retrieveSessionObject(SessionConstants.APP_DATA)).appData;
    sessionObj.profileId = profileId;
    this.setSessionObject(SessionConstants.APP_DATA, sessionObj);
  }

  //We need to check if this still works, profileId is nested in another level
  getProfileId() {
    const sessionObj = JSON.parse(this.retrieveSessionObject(SessionConstants.APP_DATA)).appData;
    return sessionObj.profileId;
  }

  //Required to change Password
  getUserDataProfileId() {
    const sessionObj = JSON.parse(this.retrieveSessionObject(SessionConstants.APP_DATA)).appData;
    return sessionObj.userData.profileId;
  }
  setLeaveSSOURL(urlValue, claimEventID) {
    const sessionObj = JSON.parse(this.retrieveSessionObject(SessionConstants.APP_DATA)).appData;
    if (sessionObj.leaveSSOURL && sessionObj.leaveSSOURL.length > 0) {
      sessionObj.leaveSSOURL.push({ url: urlValue, claimEventId: claimEventID });
    } else {
      sessionObj.leaveSSOURL = [{ url: urlValue, claimEventId: claimEventID }];
    }
    this.setSessionObject(SessionConstants.APP_DATA, sessionObj);
  }

  getLeaveSSOURL() {
    const sessionObj = JSON.parse(this.retrieveSessionObject(SessionConstants.APP_DATA)).appData;
    return sessionObj.leaveSSOURL;
  }

  setSessionData(key: any, value: any) {
    if (!this.retrieveSessionObject(SessionConstants.SESSION_DATA)) {
      this.setSessionObject(SessionConstants.SESSION_DATA, {});
    }
    const sessionData = JSON.parse(
      this.retrieveSessionObject(SessionConstants.SESSION_DATA),
    ).sessionData;
    sessionData[key] = value;
    this.setSessionObject(SessionConstants.SESSION_DATA, sessionData);
  }

  getSessionData(key) {
    if (this.retrieveSessionObject(SessionConstants.SESSION_DATA)) {
      const sessionData = JSON.parse(
        this.retrieveSessionObject(SessionConstants.SESSION_DATA),
      ).sessionData;
      return sessionData[key];
    } else {
      return null;
    }
  }

  setRAESessionData(key: any, value: any) {
    if (!this.retrieveSessionObject(RaeSessionConstants.RAE_SESSION_DATA)) {
      this.setSessionObject(RaeSessionConstants.RAE_SESSION_DATA, {});
    }
    const sessionData = JSON.parse(
      this.retrieveSessionObject(RaeSessionConstants.RAE_SESSION_DATA),
    ).raeSessionData;
    sessionData[key] = value;
    this.setSessionObject(RaeSessionConstants.RAE_SESSION_DATA, sessionData);
  }

  removeAttrFromRAESessionData(key) {
    if (
      this.retrieveSessionObject(RaeSessionConstants.RAE_SESSION_DATA) &&
      JSON.parse(this.retrieveSessionObject(RaeSessionConstants.RAE_SESSION_DATA)).raeSessionData &&
      JSON.parse(this.retrieveSessionObject(RaeSessionConstants.RAE_SESSION_DATA)).raeSessionData[
        key
      ]
    ) {
      const raeSessionData = JSON.parse(
        this.retrieveSessionObject(RaeSessionConstants.RAE_SESSION_DATA),
      ).raeSessionData;
      delete raeSessionData[key];
      this.setSessionObject(RaeSessionConstants.RAE_SESSION_DATA, raeSessionData);
    }
  }

  getRAESessionData(key) {
    if (this.retrieveSessionObject(RaeSessionConstants.RAE_SESSION_DATA)) {
      const sessionData = JSON.parse(
        this.retrieveSessionObject(RaeSessionConstants.RAE_SESSION_DATA),
      ).raeSessionData;
      return sessionData[key];
    } else {
      return null;
    }
  }

  setIntakeSessionData(key: any, value: any, intakeType?: string) {
    const sessionConstant =
      intakeType === "bonding"
        ? this.bondingIntakeConst.BONDING_INTAKE_SESSION_DATA
        : intakeType === "maternity"
          ? IntakeSessionConstants.MATERNITY_INTAKE_SESSION_DATA
          : IntakeSessionConstants.INTAKE_SESSION_DATA;
    if (!this.retrieveSessionObject(sessionConstant)) {
      this.setSessionObject(sessionConstant, {});
    }
    const sessionData =
      intakeType === "bonding"
        ? JSON.parse(this.retrieveSessionObject(sessionConstant)).bondingIntakeSessionData
        : intakeType === "maternity"
          ? JSON.parse(this.retrieveSessionObject(sessionConstant)).maternityIntakeSessionData
          : JSON.parse(this.retrieveSessionObject(sessionConstant)).intakeSessionData;
    sessionData[key] = value;
    this.setSessionObject(sessionConstant, sessionData);
  }

  removeAttrFromIntakeSessionData(key, intakeType?: string) {
    const sessionConstant =
      intakeType === "bonding"
        ? this.bondingIntakeConst.BONDING_INTAKE_SESSION_DATA
        : intakeType === "maternity"
          ? IntakeSessionConstants.MATERNITY_INTAKE_SESSION_DATA
          : IntakeSessionConstants.INTAKE_SESSION_DATA;
    const hasSessionObj =
      intakeType === "bonding"
        ? this.retrieveSessionObject(sessionConstant) &&
          JSON.parse(this.retrieveSessionObject(sessionConstant)).bondingIntakeSessionData &&
          JSON.parse(this.retrieveSessionObject(sessionConstant)).bondingIntakeSessionData[
            key
          ]?.toString()
        : intakeType === "maternity"
          ? this.retrieveSessionObject(sessionConstant) &&
            JSON.parse(this.retrieveSessionObject(sessionConstant)).maternityIntakeSessionData &&
            JSON.parse(this.retrieveSessionObject(sessionConstant)).maternityIntakeSessionData[
              key
            ]?.toString()
          : this.retrieveSessionObject(sessionConstant) &&
            JSON.parse(this.retrieveSessionObject(sessionConstant)).intakeSessionData &&
            JSON.parse(this.retrieveSessionObject(sessionConstant)).intakeSessionData[key];
    if (!(hasSessionObj === undefined || hasSessionObj === null)) {
      const intakeSessionData =
        intakeType === "bonding"
          ? JSON.parse(this.retrieveSessionObject(sessionConstant)).bondingIntakeSessionData
          : intakeType === "maternity"
            ? JSON.parse(this.retrieveSessionObject(sessionConstant)).maternityIntakeSessionData
            : JSON.parse(this.retrieveSessionObject(sessionConstant)).intakeSessionData;
      delete intakeSessionData[key];
      this.setSessionObject(sessionConstant, intakeSessionData);
    }
  }

  getIntakeSessionData(key, intakeType?: string) {
    const sessionConstant =
      intakeType === "bonding"
        ? this.bondingIntakeConst.BONDING_INTAKE_SESSION_DATA
        : intakeType === "maternity"
          ? IntakeSessionConstants.MATERNITY_INTAKE_SESSION_DATA
          : IntakeSessionConstants.INTAKE_SESSION_DATA;
    if (this.retrieveSessionObject(sessionConstant)) {
      let sessionData = JSON.parse(this.retrieveSessionObject(sessionConstant));
      sessionData =
        intakeType === "bonding"
          ? sessionData.bondingIntakeSessionData
          : intakeType === "maternity"
            ? sessionData.maternityIntakeSessionData
            : sessionData.intakeSessionData;
      return sessionData[key];
    } else {
      return null;
    }
  }

  removeAttrFromSessionData(key) {
    if (
      this.retrieveSessionObject(SessionConstants.SESSION_DATA) &&
      JSON.parse(this.retrieveSessionObject(SessionConstants.SESSION_DATA)).sessionData &&
      JSON.parse(this.retrieveSessionObject(SessionConstants.SESSION_DATA)).sessionData[key]
    ) {
      const sessionData = JSON.parse(
        this.retrieveSessionObject(SessionConstants.SESSION_DATA),
      ).sessionData;
      delete sessionData[key];
      this.setSessionObject(SessionConstants.SESSION_DATA, sessionData);
    }
  }

  /* Invalidating data in appData*/
  invalidateDataObject(obj) {
    const sessionObj = JSON.parse(this.retrieveSessionObject(SessionConstants.APP_DATA)).appData;
    sessionObj[obj] = {};
    this.setSessionObject(SessionConstants.APP_DATA, sessionObj);
  }

  setFeaturesData(features: Feature[]) {
    this.setSessionData(SessionConstants.FEATURES, features);
  }

  getFeaturesData(): Feature[] {
    return this.getSessionData(SessionConstants.FEATURES);
  }
  setAppData(key: any, value: any) {
    const sessionObj = JSON.parse(this.retrieveSessionObject(SessionConstants.APP_DATA))?.appData;
    sessionObj[key] = value;
    this.setSessionObject(SessionConstants.APP_DATA, sessionObj);
    if (SessionConstants.USER_CLAIMS === key) {
      this.setIsUserClaimsSetFlag(true);
    } else if (SessionConstants.CURRENT_PAGE === key) {
      this.curPageSetFlag.next(true);
    }
  }

  getAppData(key) {
    if (this.retrieveSessionObject(SessionConstants.APP_DATA)) {
      const sessionObj = JSON.parse(this.retrieveSessionObject(SessionConstants.APP_DATA)).appData
        ? JSON.parse(this.retrieveSessionObject(SessionConstants.APP_DATA)).appData
        : null;
      return sessionObj ? sessionObj[key] : null;
    }
  }

  removeAttrFromAppData(key) {
    if (
      this.retrieveSessionObject(SessionConstants.APP_DATA) &&
      JSON.parse(this.retrieveSessionObject(SessionConstants.APP_DATA)).appData &&
      JSON.parse(this.retrieveSessionObject(SessionConstants.APP_DATA)).appData[key]
    ) {
      const appData = JSON.parse(this.retrieveSessionObject(SessionConstants.APP_DATA)).appData;
      delete appData[key];
      this.setSessionObject(SessionConstants.APP_DATA, appData);
    }
  }

  setAPPSessionData(key, value) {
    this.appSessionData[key] = value;
  }

  getAPPSessionData(key) {
    return this.appSessionData[key];
  }
}
