import {AfterViewInit, Component, OnInit} from '@angular/core';
import {ContactsService} from '../../../../shared/services/contacts.service';
import {AuthStateService} from '../../../../shared/services/auth-state.service';
import {StorageManagementService} from '../../../../shared/services/storage-management.service';
import {PersonService} from '../../../../shared/services/people.service';
import {ContentService} from '../../../../shared/services/content.service';
import {LeaveDynatraceAction} from '../../../../shared/utils/leaveDynatraceAction';
import {CustomEventService} from '../../../../shared/services/tealium/custom-event.service';
import {RelatedClaims} from '../../../../shared/models/relatedClaims.model';
import {AetnaContactsService} from '../../../../shared/services/aetnaContacts.service';
import {ClaimRepresentativeService} from '../../../../shared/services/claimRepresentativeDetails.service';
import {AetnaCompanySpecificContactResponse} from '../../../../shared/models/aetnaCompanySpecificContact.model';
import {AppLabelConstants} from '../../../../shared/constants/appLabel.constants';
import {SessionConstants} from '../../../../shared/constants/session.constants';
import {AppConstants, AppLeaveConst} from '../../../../shared/constants/app.constants';

@Component({
  selector: 'app-contacts',
  templateUrl: './contacts.component.html',
  styleUrls: ['contacts.component.scss']
})

export class ContactsComponent   extends LeaveDynatraceAction implements OnInit, AfterViewInit {
  contacts: any[];
  isLoggedIn: any;
  isClaimExist = false;
  isHigEmployee = false;
  disabilityClaimsContact: any[] = [];
  public pageLabel = AppLabelConstants.CONTACT_US_TXT;
  isVoluntaryProductExist = false;
  insuredID: string;
   caseId: string;
   lblInsured = '';
  fa_icon = 'fa fa-chevron-left';
   currentUser;
  public generalQuestions = '';
   claimHandlerOfficeNumber = '';
   requiredClaimEventId = '';
   requiredClaimOfficeId = '';
  public isClaimHandlerApplicable = false;
   pageId: string;
   owscContent;
  public isDisabilityContactExist = false;
  public isDisabilitySpecificCaseContactExist = false;
   requiredClaimProcessDate = '';
   requiredCoverageType = '';
   requiredCoverageCategory = '';
   requiredClaimStatusCode = '';
  public isCurPageClaimDetails: boolean = false;
  public enhancedAdaLeaveExists: boolean = false;
  public foundationalAdaLeaveExists: boolean = false;
  public curClaim: RelatedClaims;
  public DCSClaims: RelatedClaims[] = [];
  public personId;
  public aetnaCaseSpecificContactList: AetnaCompanySpecificContactResponse = new AetnaCompanySpecificContactResponse();


  //Start: PHA Contact variable
  public phaHeading = '';
  public phaPhoneHeading = '';
   phaContactNonHig = '';
   phaContactHig = '';
   phaOptionNonHig = '';
  public phaHrsOfWork = '';
  //Start: PHA Contact variable

  //Start: Technical Support Variables
   technicalSupportHeading = '';
   technicalSupportContact = '';
   technicalSupportOption = '';
   technicalSupportHrsOfWork = '';
   technicalSupportPhoneHeading = '';
  //End: Technical Support Variables

  //Start: HIG Technical Support Variables
   higTechnicalSupportHeading = '';
   higTechnicalSupportContact = '';
   higTechnicalSupportHrsOfWork = '';
   higTechnicalSupportPhoneHeading = '';
  //End: HIG Technical Support Variables

  //Start: Vol Prod Contact
   volProdHeading = '';
   volProdPhoneHeading = '';
   volProdFaxHeading = '';
   volProdAddressHeading = '';
   volProdFax = '';
   volProdPhone = '';
   volProdAddress = '';
   volWrkHrs = '';
   webTPAURLTitle = '';
   volUrl = '';
  //End : Vol Prod Contact

  //Start: LOA Prod Contact
   loaHeading = '';
   loaPhoneHeading = '';
   loaFaxHeading = '';
   loaAddressHeading = '';
   loaFax = '';
   loaPhone = '';
   loaAddress = '';
   loaWrkHrs = '';
  //End : LOA Prod Contact

  //Start: LOA Specific Case Contact
   loaSpecificCaseName = '';
   loaSpecificCaseFaxHeading = '';
   loaSpecificCaseFax = '';
   loaSpecificCasePhone = '';
   loaSpecificCaseWrkHrs = '';
   loaSpecificCasePhoneHeader = '';
   loaSpecificCaseFaxHeader = '';
  //End : LOA Specific Case Contact

  //start :day one absence
   dayOneAbsenceHeading = '';
  dayOneAbsenceLink = false;
  //end:day one absence

  public isOWCSServiceHasError: boolean = false;
  public isClaimHandlerDisplayFlag: boolean = false;
  public aetnaContactResList: AetnaCompanySpecificContactResponse[] = [];
  public appLabelConstant = AppLabelConstants;

  constructor(private contactService: ContactsService,
              private authStateService: AuthStateService,
              private storageManagementService: StorageManagementService,
              private personService: PersonService,
              private contentService: ContentService,
              private claimRepresentativeService: ClaimRepresentativeService,
              private aetnaContactsService: AetnaContactsService,
              private customEventService: CustomEventService) {
    super(storageManagementService);
  }

  ngOnInit() {
    this.isLoggedIn = this.authStateService.getLoginState();
    this.isClaimHandlerDisplayFlag = this.contactService.getIsClaimRepNeedToDisplay();
    const enroutePage = this.storageManagementService.getSessionData(SessionConstants.CURRENT_PAGE);
    const previousURL = this.storageManagementService.getSessionData(SessionConstants.PREVIOUS_URL);

    //code to preserve or reset the claim handler display on contact us page refreshes
    if ( previousURL
      && (
             previousURL === AppLabelConstants.URL_HOME
          || previousURL.includes(AppLabelConstants.URL_LEAVE_DETAILS)
          || previousURL.includes(AppLabelConstants.URL_CLAIM_DETAILS)
        )
    ) {
      if ( this.storageManagementService.getSessionData(SessionConstants.CLAIM_HANDLER_DISPLAY) ) {
        this.isClaimHandlerDisplayFlag = this.storageManagementService.getSessionData(SessionConstants.CLAIM_HANDLER_DISPLAY);
      } else {
        this.storageManagementService.setSessionData(SessionConstants.CLAIM_HANDLER_DISPLAY, this.isClaimHandlerDisplayFlag);
      }
    }

    if (enroutePage === AppLabelConstants.URL_LEAVE_PLAN) {
      this.isClaimHandlerDisplayFlag = false;
    }
    if (this.isLoggedIn) {
      if (this.storageManagementService.retrieveSessionObject(SessionConstants.APP_DATA)) {
        this.currentUser = JSON.parse(this.storageManagementService.retrieveSessionObject(SessionConstants.APP_DATA)).appData;
        const userClaims = this.currentUser ? this.currentUser.userClaims ? this.currentUser.userClaims : null : null;
        const claimOverview = userClaims ? userClaims.claimOverview ? userClaims.claimOverview : null : null;
        const relatedClaims = claimOverview ? (claimOverview.relatedClaims && claimOverview.relatedClaims.length > 0) ? claimOverview.relatedClaims : null : null;
        const nonRelatedClaims = claimOverview ? (claimOverview.nonRelatedClaims && claimOverview.nonRelatedClaims.length > 0) ? claimOverview.nonRelatedClaims : null : null;
        const leaveClaims = claimOverview ? (claimOverview.leaveClaims && claimOverview.leaveClaims.length > 0) ? claimOverview.leaveClaims : null : null;

        //Validate is user has Vol coverages.
        this.setProductCategory();
        // JSON.parse(this.storageManagementService.retrieveSessionObject('curUser'));

        if (this.currentUser && this.currentUser.userData) {
          this.caseId = this.currentUser.userData.caseId;
          this.insuredID = this.currentUser.userData.insuredId;
          this.personId = this.currentUser.userData.personIds;
          if (this.caseId === AppConstants.CASE_ID_11148) {
            this.isHigEmployee = true;
          }
        }
        if ( nonRelatedClaims || relatedClaims ) {
          this.isClaimExist = true;
        }
        const thaaEnhancedAdaLeave = this.storageManagementService.getSessionData(SessionConstants.THAA_ENHANCED_ADA_LEAVE);
        const currentLeave = this.storageManagementService.getSessionData(SessionConstants.CURR_LEAVE);
        if ( previousURL === AppLabelConstants.URL_HOME && thaaEnhancedAdaLeave ) {
          this.enhancedAdaLeaveExists = true;
        } else if ( previousURL === AppLabelConstants.URL_LEAVE_DETAILS  && currentLeave ) {
          this.enhancedAdaLeaveExists = currentLeave.leaveType === AppLeaveConst.ADA_TYPE
            && this.storageManagementService.getSessionData(SessionConstants.THAA_ENHANCED_ADA);
            this.foundationalAdaLeaveExists = currentLeave.leaveType === AppLeaveConst.ADA_TYPE
            && this.storageManagementService.getSessionData(SessionConstants.THAA_FOUNDAMENTAL_ADA);
        }

        if (this.currentUser.caseData && this.currentUser.caseData.dayOneCaseIndicator) {
          this.dayOneAbsenceLink = true;
        }
      }
    }

    if (this.isLoggedIn ) {
      this.getDisabilityClaimRelatedContact();
      if (this.isClaimExist || this.enhancedAdaLeaveExists || this.foundationalAdaLeaveExists) {
        this.setClaimHandlerDisplayRule();
      }

    }

    this.pageId = this.contentService.getPageId(AppConstants.PAGE_NAME_CONTACT_US);
    if (this.pageId) {
      this.contentService.getOWCSResponse(this.pageId, AppConstants.PAGE_NAME_CONTACT_US_1).subscribe(val => {

          this.owscContent = val;
          if (this.owscContent && this.owscContent.data) {
            this.isOWCSServiceHasError = false;
            this.pageLabel = AppLabelConstants.CONTACT_US_TXT;
            if (this.owscContent.data.CU_25_Label) {
              this.lblInsured = this.owscContent.data.CU_25_Label.value;
            } else {
              this.lblInsured = AppLabelConstants.INSURED_ID_LABEL;
            }

            this.generalQuestions = this.owscContent.data.CU_08_Label.value;

            //Start: PHA
            this.phaHeading = this.owscContent.data.CU_39_Label.value;
            this.phaHrsOfWork = this.owscContent.data.CU_40_Label.value;
            this.phaPhoneHeading = this.owscContent.data.CU_41_Label.value;
            this.phaContactNonHig = AppLabelConstants.phoneNumberFormat(this.owscContent.data.CU_42_Label.value.substring(0, ((this.owscContent.data.CU_42_Label.value).indexOf('O') - 1)));
            this.phaOptionNonHig = this.owscContent.data.CU_42_Label.value.substring((this.owscContent.data.CU_42_Label.value).indexOf('O'), this.owscContent.data.CU_42_Label.value.toString().length);
            this.phaContactHig = AppLabelConstants.phoneNumberFormat(this.owscContent.data.CU_43_Label.value);
            //End: PHA

            //Start: Technical Support
            this.technicalSupportHeading = this.owscContent.data.CU_44_Label.value;
            this.technicalSupportHrsOfWork = this.owscContent.data.CU_45_Label.value;
            this.technicalSupportPhoneHeading = this.owscContent.data.CU_46_Label.value;
            this.technicalSupportContact = AppLabelConstants.phoneNumberFormat(this.owscContent.data.CU_47_Label.value.substring(0, ((this.owscContent.data.CU_47_Label.value).indexOf('O') - 1))); //'1-877-778-1383';
            this.technicalSupportOption = AppLabelConstants.phoneNumberFormat(this.owscContent.data.CU_47_Label.value.substring((this.owscContent.data.CU_47_Label.value).indexOf('O'), this.owscContent.data.CU_47_Label.value.toString().length)); //'Option 5';

            //End: Technical Support

            //Start: Hig Technical Support
            this.higTechnicalSupportHeading = this.owscContent.data.CU_60_Label.value;
            this.higTechnicalSupportHrsOfWork = this.owscContent.data.CU_61_Label.value;
            this.higTechnicalSupportPhoneHeading = this.owscContent.data.CU_62_Label.value;
            this.higTechnicalSupportContact = this.owscContent.data.CU_63_Label.value;
            //End: Hig Technical Support

            //Start: Voluntary Product Contact
            this.volProdHeading = this.owscContent.data.CU_15_Label.value;
            this.volProdPhoneHeading = this.owscContent.data.CU_34_Label.value;
            this.volProdPhone =  AppLabelConstants.phoneNumberFormat(this.owscContent.data.CU_16_Label.value);
            this.volProdFaxHeading = this.owscContent.data.CU_35_Label.value;
            this.volProdFax = this.owscContent.data.CU_36_Label.value;
            this.volProdAddressHeading = this.owscContent.data.CU_37_Label.value;
            this.volProdAddress = this.owscContent.data.CU_38_Label.value;
            this.volWrkHrs = this.owscContent.data.CU_65_Label.value;
            this.webTPAURLTitle = this.owscContent.data.CU_67_Label.value;
            this.volUrl = this.owscContent.data.CU_66_Body.value;
            //End:Voluntary Product Contact

            //Start: LOA Contact
            this.loaHeading = this.owscContent.data.CU_28_Label.value;
            this.loaPhoneHeading = this.owscContent.data.CU_30_Label.value;
            this.loaPhone =  AppLabelConstants.phoneNumberFormat(this.owscContent.data.CU_31_Label.value);
            this.loaFaxHeading = this.owscContent.data.CU_32_Label.value;
            if (this.currentUser && this.currentUser.userData && this.currentUser.userData.caseId === AppConstants.CASE_ID_11148) {
              this.loaFax = this.owscContent.data.CU_33A_Label.value;
              this.loaWrkHrs = this.owscContent.data.CU_65_Label.value;
              this.loaAddress = this.owscContent.data.CU_57A_Body.value;
              this.claimHandlerOfficeNumber = this.owscContent.data.CU_31B_Label.value;
            } else {
              this.loaFax = this.owscContent.data.CU_33_Label.value;
              this.loaWrkHrs = this.owscContent.data.CU_29_Label.value;
              this.loaAddress = this.owscContent.data.CU_57_Body.value;
            }
            this.loaAddressHeading = AppLabelConstants.ADDRESS;
            //End : Loa Contact

            //Start: day one absence link
            this.dayOneAbsenceHeading = this.owscContent.data.CU_64_Body.value;
            //End: day one absence link
          } else {
            throw val;
          }
        }
        , (error) => {
          this.isOWCSServiceHasError = true;
          //console.log('Contact US: OWCS Error');
        });
    } else {
      this.contentService.getSiteMap().subscribe(
        siteMapData => {
          if (siteMapData) {
            const siteMapResponse = siteMapData;
            this.storageManagementService.setSessionData(SessionConstants.SITE_MAP_DATA, siteMapResponse);
            this.pageId = this.contentService.getPageId(AppConstants.PAGE_NAME_CONTACT_US);
            if (this.pageId) {
              this.contentService.getOWCSResponse(this.pageId, AppConstants.PAGE_NAME_CONTACT_US_1).subscribe(val => {

                  this.owscContent = val;
                  if (this.owscContent && this.owscContent.data) {
                    this.isOWCSServiceHasError = false;
                    this.pageLabel = AppLabelConstants.CONTACT_US_TXT;
                    if (this.owscContent.data.CU_25_Label) {
                      this.lblInsured = this.owscContent.data.CU_25_Label.value;
                    } else {
                      this.lblInsured = AppLabelConstants.INSURED_ID_LABEL;
                    }

                    this.generalQuestions = this.owscContent.data.CU_08_Label.value;

                    //Start: PHA
                    this.phaHeading = this.owscContent.data.CU_39_Label.value;
                    this.phaHrsOfWork = this.owscContent.data.CU_40_Label.value;
                    this.phaPhoneHeading = this.owscContent.data.CU_41_Label.value;
                    this.phaContactNonHig =  AppLabelConstants.phoneNumberFormat(this.owscContent.data.CU_42_Label.value.substring(0, ((this.owscContent.data.CU_42_Label.value).indexOf('O') - 1)));
                    this.phaOptionNonHig = this.owscContent.data.CU_42_Label.value.substring((this.owscContent.data.CU_42_Label.value).indexOf('O'), this.owscContent.data.CU_42_Label.value.toString().length);
                    this.phaContactHig =  AppLabelConstants.phoneNumberFormat(this.owscContent.data.CU_43_Label.value);
                    //End: PHA

                    //Start: Technical Support
                    this.technicalSupportHeading = this.owscContent.data.CU_44_Label.value;
                    this.technicalSupportHrsOfWork = this.owscContent.data.CU_45_Label.value;
                    this.technicalSupportPhoneHeading = this.owscContent.data.CU_46_Label.value;
                    this.technicalSupportContact =  AppLabelConstants.phoneNumberFormat(this.owscContent.data.CU_47_Label.value.substring(0, ((this.owscContent.data.CU_47_Label.value).indexOf('O') - 1))); //'1-877-778-1383';
                    this.technicalSupportOption = AppLabelConstants.phoneNumberFormat(this.owscContent.data.CU_47_Label.value.substring((this.owscContent.data.CU_47_Label.value).indexOf('O'), this.owscContent.data.CU_47_Label.value.toString().length)); //'Option 5';

                    //End: Technical Support

                    //Start: Hig Technical Support
                    this.higTechnicalSupportHeading = this.owscContent.data.CU_60_Label.value;
                    this.higTechnicalSupportHrsOfWork = this.owscContent.data.CU_61_Label.value;
                    this.higTechnicalSupportPhoneHeading = this.owscContent.data.CU_62_Label.value;
                    this.higTechnicalSupportContact = this.owscContent.data.CU_63_Label.value;
                    //End: Hig Technical Support

                    //Start: Voluntary Product Contact
                    this.volProdHeading = this.owscContent.data.CU_15_Label.value;
                    this.volProdPhoneHeading = this.owscContent.data.CU_34_Label.value;
                    this.volProdPhone =  AppLabelConstants.phoneNumberFormat(this.owscContent.data.CU_16_Label.value);
                    this.volProdFaxHeading = this.owscContent.data.CU_35_Label.value;
                    this.volProdFax = this.owscContent.data.CU_36_Label.value;
                    this.volProdAddressHeading = this.owscContent.data.CU_37_Label.value;
                    this.volProdAddress = this.owscContent.data.CU_38_Label.value;
                    this.volWrkHrs = this.owscContent.data.CU_65_Label.value;
                    this.webTPAURLTitle = this.owscContent.data.CU_67_Label.value;
                    this.volUrl = this.owscContent.data.CU_66_Body.value;
                    //End:Voluntary Product Contact

                    //Start: LOA Contact
                    this.loaHeading = this.owscContent.data.CU_28_Label.value;
                    this.loaPhoneHeading = this.owscContent.data.CU_30_Label.value;
                    this.loaPhone =  AppLabelConstants.phoneNumberFormat(this.owscContent.data.CU_31_Label.value);
                    this.loaFaxHeading = this.owscContent.data.CU_32_Label.value;
                    if (this.currentUser && this.currentUser.userData && this.currentUser.userData.caseId === AppConstants.CASE_ID_11148) {
                      this.loaFax = this.owscContent.data.CU_33A_Label.value;
                      this.loaWrkHrs = this.owscContent.data.CU_65_Label.value;
                      this.loaAddress = this.owscContent.data.CU_57A_Body.value;
                      this.claimHandlerOfficeNumber = this.owscContent.data.CU_31B_Label.value;
                    } else {
                      this.loaFax = this.owscContent.data.CU_33_Label.value;
                      this.loaWrkHrs = this.owscContent.data.CU_29_Label.value;
                      this.loaAddress = this.owscContent.data.CU_57_Body.value;
                    }
                    this.loaAddressHeading = AppLabelConstants.ADDRESS;
                    //End : Loa Contact

                    //Start: day one absence link
                    this.dayOneAbsenceHeading = this.owscContent.data.CU_64_Body.value;
                    //End: day one absence link
                  } else {
                    throw val;
                  }
                }
                , (error) => {
                  this.isOWCSServiceHasError = true;
                  //console.log('Contact US: OWCS Error');
                });
            }

          }
        }, (error) => {
          console.log(error);
        });
    }
  }

  ngAfterViewInit() {
    super.ngAfterViewInit();
  }

  setProductCategory() {
    const isVoluntaryProduct = this.storageManagementService.getSessionData(SessionConstants.IS_EMPLOYEE_HAS_VOL_PRD);  //sessionStorage.getItem('isEmployeeHasVoluntaryProduct');
    if (isVoluntaryProduct) {
      this.isVoluntaryProductExist = true;
    } else {
      this.isVoluntaryProductExist = false ;
    }
  }

  getDisabilityClaimRelatedContact() {
    const relatedClaimLength = (this.currentUser.userClaims && this.currentUser.userClaims.claimOverview &&
      this.currentUser.userClaims.claimOverview.relatedClaims) ? this.currentUser.userClaims.claimOverview.relatedClaims.length : 0;
    const nonRelatedClaimLength = (this.currentUser.userClaims && this.currentUser.userClaims.claimOverview &&
      this.currentUser.userClaims.claimOverview.nonRelatedClaims) ? this.currentUser.userClaims.claimOverview.nonRelatedClaims.length : 0;
    if (relatedClaimLength > 0) {
      this.DCSClaims = this.currentUser.userClaims.claimOverview.relatedClaims.filter(dcsClaim => (dcsClaim.sourceSystemId === AppConstants.SOURCE_SYS_ID_DCS));
      if (this.DCSClaims && this.DCSClaims.length > 0) {
        this.requiredClaimEventId = this.DCSClaims[0].claimEventId;
        this.requiredClaimOfficeId = this.DCSClaims[0].claimOfficeId;
        this.requiredClaimProcessDate = this.DCSClaims[0].claimProcessDate;
        this.requiredCoverageType = this.DCSClaims[0].coverageType;
        this.requiredCoverageCategory = this.DCSClaims[0].coverageCategory;
        this.requiredClaimStatusCode = this.DCSClaims[0].claimStatusCode;
      }
      this.curClaim = this.currentUser.userClaims.claimOverview.relatedClaims[0];
    } else if (relatedClaimLength === 0  && nonRelatedClaimLength === 1) {
      this.DCSClaims = this.currentUser.userClaims.claimOverview.nonRelatedClaims.filter(dcsClaim => (dcsClaim.sourceSystemId === AppConstants.SOURCE_SYS_ID_DCS));
      if (this.DCSClaims && this.DCSClaims.length > 0) {
        this.requiredClaimEventId = this.DCSClaims[0].claimEventId;
        this.requiredClaimOfficeId = this.DCSClaims[0].claimOfficeId;
        this.requiredClaimProcessDate = this.DCSClaims[0].claimProcessDate;
        this.requiredCoverageType = this.DCSClaims[0].coverageType;
        this.requiredCoverageCategory = this.DCSClaims[0].coverageCategory;
        this.requiredClaimStatusCode = this.DCSClaims[0].claimStatusCode;
      }
      this.curClaim = this.currentUser.userClaims.claimOverview.nonRelatedClaims[0];
    } else if (relatedClaimLength === 0 && nonRelatedClaimLength === 0) { // to check claim history data
      const claimHistoryLength = (this.storageManagementService.retrieveSessionObject(SessionConstants.CLAIM_HISTORY) &&
        JSON.parse(this.storageManagementService.retrieveSessionObject(SessionConstants.CLAIM_HISTORY)).claimHistory &&
        JSON.parse(this.storageManagementService.retrieveSessionObject(SessionConstants.CLAIM_HISTORY)).claimHistory.disabilityClaims) ?
        JSON.parse(this.storageManagementService.retrieveSessionObject(SessionConstants.CLAIM_HISTORY)).claimHistory.disabilityClaims.length : 0;
      if (claimHistoryLength > 0) {
        const historyData = JSON.parse(this.storageManagementService.retrieveSessionObject(SessionConstants.CLAIM_HISTORY)).claimHistory.disabilityClaims;
        this.requiredClaimEventId = historyData[0].claimEventId;
        this.requiredClaimOfficeId = historyData[0].claimOfficeId;
        this.curClaim = historyData[0];
      }

    }

    this.isCurPageClaimDetails = this.storageManagementService.getSessionData(SessionConstants.IS_CUR_PAGE_CLAIM_DETAILS);

    if (this.isCurPageClaimDetails) {
      this.curClaim = JSON.parse(this.storageManagementService.retrieveSessionObject(SessionConstants.CUR_CLAIM)).curClaim;
    }

    if (this.curClaim && this.curClaim.sourceSystemId && this.curClaim.sourceSystemId === AppConstants.SOURCE_SYS_ID_DCS) {
      const disabilityClaimOfficeContacts = this.storageManagementService.getSessionData(SessionConstants.DIS_CLAIM_OFC_CONTACTS);
      if (disabilityClaimOfficeContacts && disabilityClaimOfficeContacts.length > 0) {
        const filteredData = disabilityClaimOfficeContacts.filter(el => (el.claimEventId === this.requiredClaimEventId && el.officeId === this.requiredClaimOfficeId));
        if (filteredData && filteredData.length > 0) {
          this.isDisabilityContactExist = true;
          this.disabilityClaimsContact.push(filteredData[0]);
          //get the claim handler phone number to pass as Input tt Claim Handler Component
          this.claimHandlerOfficeNumber = AppLabelConstants.phoneNumberFormat(filteredData[0].phoneNumber);
        } else if (!this.isClaimExist && !this.storageManagementService.getSessionData(SessionConstants.IS_CLAIM_HISTORY_EXIST)) { //get data based on defaultcaseId
          this.isDisabilityContactExist = true;
          this.disabilityClaimsContact.push(disabilityClaimOfficeContacts[0]);
        } else {
          //if there is no contact details available display prelogin contact
          this.isDisabilityContactExist = false;
        }
      } else {
        //if there is no contact details available display prelogin contact
        this.isDisabilityContactExist = false;
      }
    }
    //  else  if (this.curClaim && this.curClaim.sourceSystemId && this.curClaim.sourceSystemId === AppConstants.SOURCE_SYS_ID_AA) {
    //   if (this.storageManagementService.getSessionData(SessionConstants.CLAIM_REP_DETAILS) &&
    //     JSON.stringify(this.storageManagementService.getSessionData(SessionConstants.CLAIM_REP_DETAILS)).length > 0) {

    //     this.isDisabilityContactExist = true;
    //     const data = this.storageManagementService.getSessionData(SessionConstants.CLAIM_REP_DETAILS);
    //     this.claimHandlerOfficeNumber = AppLabelConstants.phoneNumberFormat(data.ContactNumber);
    //   } else {
    //     this.claimRepresentativeService.getClaimRepresentativeDetails(this.curClaim.personId, this.curClaim.claimEventId).subscribe(data => {

    //       if (data) {
    //         this.storageManagementService.setSessionData(SessionConstants.CLAIM_REP_DETAILS, data);
    //         this.isDisabilityContactExist = true;
    //         this.claimHandlerOfficeNumber = AppLabelConstants.phoneNumberFormat(data.ContactNumber);
    //       }
    //     })
    //   }
    // }
    if (this.currentUser && this.currentUser.userData.personIds && this.currentUser.userData.personIds.length > 0) {
      if (this.storageManagementService.getSessionData(SessionConstants.AETENA_CONTACT_ALT_DISP_LINE) &&
        this.storageManagementService.getSessionData(SessionConstants.AETENA_CONTACT_ALT_DISP_LINE).length > 0) {
        this.isDisabilitySpecificCaseContactExist = true;
        this.aetnaContactResList = this.storageManagementService.getSessionData(SessionConstants.AETENA_CONTACT_ALT_DISP_LINE);
      } else {
        //looping thru the personIds array
        this.currentUser.userData.personIds.forEach((personId) => {
            //calling the Aetna case specific contact information
            this.aetnaContactsService.getAetnaDisabilityClaimRelatedContact(personId).subscribe(data => {
              if (data) {

                if (data.aetnaContactAltDisplayLines && data.aetnaContactAltDisplayLines.length > 0) {
                  this.aetnaContactResponseMapper(data);
                } else if (data.aetnaContactDetails  && data.aetnaContactDetails.phone) {
                  this.claimHandlerOfficeNumber = AppLabelConstants.phoneNumberFormat(data.aetnaContactDetails.phone);
                }
                this.storageManagementService.setSessionData(SessionConstants.AETENA_CONTACT_ALT_DISP_LINE, this.aetnaContactResList);
              }
            });
            if (this.aetnaContactResList && this.aetnaContactResList.length >= 0) {
              this.isDisabilitySpecificCaseContactExist = true;
            }
          })
      }
    }
  }

  setClaimHandlerDisplayRule() {
    const relatedClaimLength = this.currentUser.userClaims.claimOverview.relatedClaims.length;
    const nonRelatedClaimLength = this.currentUser.userClaims.claimOverview.nonRelatedClaims.length;
    if (relatedClaimLength > 0) {
      this.isClaimHandlerApplicable = true;
    } else if (relatedClaimLength === 0 && nonRelatedClaimLength > 0) {
      this.isClaimHandlerApplicable = true;
    } else if ( this.enhancedAdaLeaveExists || this.foundationalAdaLeaveExists) {
      this.isClaimHandlerApplicable = true;
    } else {
      this.isClaimHandlerApplicable = false;
    }
  }

  trackContactsClick(documentName, documentCategory) {
    this.customEventService.trackMissingInfoDocumentCategory(documentName, documentCategory);
  }

  private aetnaContactResponseMapper(aetnaRsponse: AetnaCompanySpecificContactResponse) {
    const aetnaContactCompaniesRes: AetnaCompanySpecificContactResponse = new AetnaCompanySpecificContactResponse();
    aetnaContactCompaniesRes.loaSpecificCasePhone = aetnaRsponse.aetnaContactAltDisplayLines[1].split(':')[1];
    aetnaContactCompaniesRes.loaSpecificCasePhoneHeader = aetnaRsponse.aetnaContactAltDisplayLines[1].split(':')[0];
    aetnaContactCompaniesRes.loaSpecificCaseName = aetnaRsponse.aetnaContactAltDisplayLines[0];
    aetnaContactCompaniesRes.loaSpecificCaseFax = aetnaRsponse.aetnaContactAltDisplayLines[2].split(':')[1];
    aetnaContactCompaniesRes.loaSpecificCaseFaxHeader = aetnaRsponse.aetnaContactAltDisplayLines[2].split(':')[0];
    aetnaContactCompaniesRes.loaSpecificCaseWrkHrs = aetnaRsponse.aetnaContactAltDisplayLines[5];
    this.aetnaContactResList.push(aetnaContactCompaniesRes);
  }

}
