import {ISpecialityResponse} from '@shared/models/specialtyList.model';

export const MOCK_SPECIALTY_LIST: ISpecialityResponse = {
  specialtyList: [
  {
    specialtyId: 0,
    specialtyDescription: 'Medical Specialist',
    responseCode: '',
    responseReason: 'No Error'
  },
    {
    specialtyId: 1,
    specialtyDescription: 'Derm',
      responseCode: '',
      responseReason: ''
  },
  ],
}
