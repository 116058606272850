import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
  name: 'maskAccountNumber'
})
export class MaskAccountNumberPipe implements PipeTransform {
  transform(value: any): string {
    /*
    let limit;
    let trimNumber;
    let noOfStarChar;
    let star: string;
    const count = 0;
    */
    if (value ) {
      const starChar  = value.toString().replace(/.(?=.{4})/g,  '*');
      return starChar  ;
    }
    return;
  }
}
