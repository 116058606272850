import {Component, OnInit} from '@angular/core';
import {Router} from '@angular/router';
import {StorageManagementService} from '../../../../shared/services/storage-management.service';
import {Feature, FEATURELISTS} from '../../../../shared/models/Feature';
import {AppLabelConstants} from '../../../../shared/constants/appLabel.constants';
import {SessionConstants} from '../../../../shared/constants/session.constants';

@Component({
  selector: 'app-feature-down',
  templateUrl: 'feature-down.component.html',
  styleUrls: ['feature-down.component.scss']
})
export class FeatureDownComponent implements OnInit {
  public appLabelConstants = AppLabelConstants;
  public pageTitle: string = AppLabelConstants.ERROR;
  public message: string = AppLabelConstants.WE_R_MAKING_UPDATES;
  public returnHomeLbl: string = AppLabelConstants.RETURN_TO_HOME;
  public actionLbl: string = AppLabelConstants.PLEASE_TRY_AGAIN_LATER;

  constructor(private router: Router,
              private storageManagementService: StorageManagementService) { }

  ngOnInit() {
    if (this.storageManagementService.getSessionData(SessionConstants.SYS_DOWN_FEATURE)) {
      const featureFound: Feature = this.storageManagementService.getSessionData(SessionConstants.SYS_DOWN_FEATURE);
      this.actionLbl = featureFound.value;
      const featureListFiltered = FEATURELISTS.filter(feature => feature.name === featureFound.title);
      if (featureListFiltered && featureListFiltered.length > 0) {
        this.pageTitle = featureListFiltered[0].pageTitle;
      }
    }

  }

  navToHome() {
    this.router.navigateByUrl(AppLabelConstants.URL_HOME);
  }
}
