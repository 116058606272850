
import {throwError as observableThrowError} from 'rxjs/internal/observable/throwError';
import {of, Observable} from 'rxjs';

import {Injectable} from '@angular/core';

import {env} from '../../../environments/environment-loader';
import {cobrandingimgURL} from '../models/co-branding.model';

@Injectable()
export class CoBrandingService {
  private imgURL: string;

  constructor() { }

  getCoBrands(caseId): Observable<any> {
    if (env.mocking) {
      return of(cobrandingimgURL);
    } else {
      return of(cobrandingimgURL);
    }
  }
  private handleError(error: Response) {
    console.error(error);
    return observableThrowError(error);
  }


}
