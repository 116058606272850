/**
 * Created by AC12323 on 1/16/2018.
 */
export const phaOverview: any = {
    errorList: null,
    userId: '1111',
    sessionIdentifier: '123',
    coverageList: [   {
    firstName: 'Nazzzkia',
    lastName: 'zzzzrteltzz',
    middleName: null,
    applicationId: '911569',
    applicantId: '14941',
    insuredId: '1155591',
    caseId: '21090',
    applicationRcvdDate: '2002-12-10',
    enrolleeRelationship: 'Self',
    policyId: '41140',
    coverageCategoryCd: 'LIFE',
    coverageTypeCd: 'VOL',
    statusCd: '4',
    coverageStatus: 'Pending',
    coverageStatusDt: '2004-06-18'
  },
      {
        firstName: 'Nazzzkia',
        lastName: 'zzzzrteltzz',
        middleName: null,
        applicationId: '911569',
        applicantId: '14941',
        insuredId: '1155591',
        caseId: '21090',
        applicationRcvdDate: '2002-12-10',
        enrolleeRelationship: 'Spouse',
        policyId: '41140',
        coverageCategoryCd: 'LIFE',
        coverageTypeCd: 'BTRM',
        statusCd: '4',
        coverageStatus: 'Approve',
        coverageStatusDt: '2004-06-18'
      }]
}
