/**
 * Created by AC12394 on 10/15/2018.
 */

export class Activity {
  date: string;
  activity: string;
  category: string;
}

export class ClaimActivityHistoryResponse {
  claimActivity: ClaimActivityHistoryObj;
}

export class ClaimActivityHistoryObj {
  op_ref_cusror: Activity[];
}

export class ClaimActivity {
  claimEventId: string;
  activityHistory: Activity[];
}

