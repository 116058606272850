/**
 * Created by AC12394 on 5/31/2018.
 */

export class NotificationCategory {
  isStatusApplicable: boolean;
  isPaymentApplicable: boolean;
}

export class NotificationDetails {
  notificationExempt: boolean;
  notificationCategory: NotificationCategory;

  constructor(notificationExempt: boolean, isStatusApplicable: boolean , isPaymentApplicable: boolean) {
    this.notificationExempt = notificationExempt;
    this.notificationCategory = new NotificationCategory();
    this.notificationCategory.isStatusApplicable = isStatusApplicable;
    this.notificationCategory.isPaymentApplicable = isPaymentApplicable;
  }
}

export class UpdateNotificationLogRequest {
  partyRefId: string;
  noteTnCVersnId: string;
  noteTnCAccptFlag: string;

  constructor(partyRedId: string, noteTnCVersnId: string, noteTnCAccptFlag: string) {
    this.partyRefId = partyRedId;
    this.noteTnCVersnId = noteTnCVersnId;
    this.noteTnCAccptFlag = noteTnCAccptFlag;
  }
}

export class UpdateNotificationLogResponse {
  errorList: any;
  statusCode: string;
  statusMsg: string;
}


