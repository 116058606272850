import {Component, Input, OnInit} from '@angular/core';
import {ClaimServiceService} from '../../../../shared/services/claim-service.service';
import {StorageManagementService} from '../../../../shared/services/storage-management.service';
import {ActivatedRoute, Router} from '@angular/router';
import {ContentService} from '../../../../shared/services/content.service';
import {
  MissingInfo,
  MissingInfoFromAll,
  MissingItem,
  MissingReqInfo,
  MissingInfoDoc,
  OWCSMissingItemsResponse, MissingInfoFromAllThaa
} from '../../../../shared/models/missing-info.model';
import {OWCSCaasService} from '../../../../shared/services/owcs-caas.service';
import {DateLabelValueComponent} from '../datelabel-datevalue/date-label-value/date-label-value.component';
import {SessionConstants} from '../../../../shared/constants/session.constants';
import {AppConstants} from '../../../../shared/constants/app.constants';

@Component({
  selector: 'app-missing-info-thaa',
  templateUrl: './missing-info-thaa.component.html',
  styleUrls: ['./missing-info-thaa.component.scss']
})
export class MissingInfoThaaComponent implements OnInit {
  @Input() currentClaim;
  @Input() infoLabel;
  @Input() message_1_Label;
  @Input() gotoMissingInfo_Button;
  @Input() header_3;
  @Input() message_3;
  @Input() header_2;
  @Input() message_2;
  @Input() dateLabel: DateLabelValueComponent;
  missingItems: MissingItem[] = [];
  missingInfoFromAll: MissingInfoFromAll;
  missingDocumentByDoctor: MissingInfo[];
  missingDocumentByEmployer: MissingInfo[];
  isDocumentMissingFromEmployee = false;
  isDocumentMissingFromEmployer = false;
  isDocumentMissingFromDoctor = false;
  pageId: string;
  owscContent;
  claimNeeded;
  public missingInfoFromAllThaa: MissingInfoFromAllThaa;
  public missingDocumentByEmployee: MissingInfo[];
  public missingDocsRequired = [];
  public defaultCase;
  public appConstant = AppConstants;
  public sourceSystemId: string;
  isMissingInfoDisabled: boolean = false;

  constructor(private router: Router, private activatedRoute: ActivatedRoute,
              private claimService: ClaimServiceService,
              private owcsCaasService: OWCSCaasService,
              private storageManagementService: StorageManagementService,
              private contentService: ContentService) {
  }

  ngOnInit() {
    this.isMissingInfoDisabled = this.storageManagementService.getSessionData(AppConstants.IS_MISSING_INFO_DISABLED);
    this.defaultCase = this.storageManagementService.getSessionData(SessionConstants.DEFAULT_CASE);
    this.claimNeeded = this.currentClaim;
    if (this.storageManagementService.retrieveSessionObject(SessionConstants.MISSING_ITEMS)) {
      this.missingItems = JSON.parse(this.storageManagementService.retrieveSessionObject(SessionConstants.MISSING_ITEMS)).missingItems;
      this.missingInfoFromAllThaa = this.claimService.documentFilterThaa(this.currentClaim);
      this.documentCollection();

    } else {
      this.owcsCaasService.getMissingItems().subscribe(
        (owcsMissingInfoResponse: OWCSMissingItemsResponse) => {
          if (owcsMissingInfoResponse && owcsMissingInfoResponse.forms) {
            this.missingItems = owcsMissingInfoResponse.forms;
            this.storageManagementService.setSessionObject(SessionConstants.MISSING_ITEMS, owcsMissingInfoResponse.forms);
            this.missingInfoFromAllThaa = this.claimService.documentFilterThaa(this.currentClaim);
            this.documentCollection();
          }
        }
      );
    }

    /*
     This is a hack t scroll to missing info page if the "Go to missing info" button was clicked from another page
     It should be somehow fixed in the future. See jumpToMissingInfo() body too.
     */
    this.activatedRoute.queryParams.subscribe(params => {
      if (params.scrollTo === 'missingInfoHeader') {
        this.jumpToMissingInfo('', '', '', '', '')
      }
    });

    this.fetchOWCSContent();
  }

  documentCollection() {
    if (this.missingInfoFromAllThaa) {
      this.missingDocumentByEmployee = this.missingInfoFromAllThaa.missingInfoDataFromEmployee;
      this.missingDocumentByDoctor = this.missingInfoFromAllThaa.missingInfoDataFromDoctor;
      this.missingDocumentByEmployer = this.missingInfoFromAllThaa.missingInfoDataFromEmployer;

      // this.isDocumentMissingFromEmployee = this.missingDocumentByEmployee && this.missingDocumentByEmployee.mInfoReqInfo && this.missingDocumentByEmployee.mInfoReqInfo.length > 0;
      this.isDocumentMissingFromDoctor = this.missingDocumentByDoctor && this.missingDocumentByDoctor[0].mInfoReqInfo && this.missingDocumentByDoctor[0].mInfoReqInfo.length > 0;
      this.isDocumentMissingFromEmployer = this.missingDocumentByEmployer && this.missingDocumentByEmployer[0].mInfoReqInfo && this.missingDocumentByEmployer[0].mInfoReqInfo.length > 0;
    }
  }
  /*
   Replacement of the above navigateTo()
   Scrolls if accessed from another page.
   Still a hack for the demo.
   */
  jumpToMissingInfo(location, curClaim, claimEventId, authorizedEndDate, claimOfficeId) {
    const el = document.getElementById(AppConstants.MISSING_INFO_HEADER);
    sessionStorage.setItem(SessionConstants.MISSING_INF_DIV, SessionConstants.MISSING_INF_DIV);
    if (el) {
      el.scrollIntoView();
    } else {
      this.storageManagementService.setSessionObject(SessionConstants.CUR_CLAIM, curClaim);
      this.storageManagementService.setSessionObject(SessionConstants.CLAIM_EVENT_ID, claimEventId);
      this.storageManagementService.setSessionObject(SessionConstants.AUTHORIZED_END_DATE, authorizedEndDate);
      this.storageManagementService.setSessionObject(SessionConstants.CLAIM_OFFICE_ID, claimOfficeId);
      this.dateLabel.claimEventId(claimEventId);
      this.router.navigate(['/' + location], {queryParams: {scrollTo: AppConstants.MISSING_INFO_HEADER}});
    }
  }

  getName(mInfoReqInfoCd: string): string {
    const owcsKeys: string[] = this.owcsCaasService.getValues(mInfoReqInfoCd);
    if (owcsKeys && owcsKeys.length > 0) {

      const filteredMissingItems: MissingItem[] = this.missingItems.filter(
        (missingItem: MissingItem) => (missingItem.key === owcsKeys[0])
      );

      if (filteredMissingItems && filteredMissingItems.length > 0) {
        return filteredMissingItems[0].displayName;
      }
    }
  }


  fetchOWCSContent() {
    if (this.storageManagementService.getSessionData(SessionConstants.CLAIM_OVERVIEW_OWCS_CONTENT)) {
      this.fetchOWCSContentFromSession();
    } else {
      this.fetchOWCSContentFromService();
    }
  }

  fetchOWCSContentFromSession() {
    this.owscContent = this.storageManagementService.getSessionData(SessionConstants.CLAIM_OVERVIEW_OWCS_CONTENT);
    if (this.owscContent.CO_500_Label) {
      this.infoLabel = this.owscContent.CO_500_Label.value;
    }
    if (this.owscContent.CO_501_Body) {
      this.message_1_Label = this.owscContent.CO_501_Body.value;
    }
    if (this.owscContent.CO_503_Body) {
      this.gotoMissingInfo_Button = this.owscContent.CO_503_Body.value.replace(/(<([^>]+)>)/ig, '');
    }
    if (this.owscContent.CO_506_Label) {
      this.header_3 = this.owscContent.CO_506_Label.value;
    }
    if (this.owscContent.CO_507_Body) {
      this.message_3 = this.owscContent.CO_507_Body.value;
    }
    if (this.owscContent.CO_504_Label) {
      this.header_2 = this.owscContent.CO_504_Label.value;
    }

    if (this.owscContent.CO_89A_Body &&
      this.claimNeeded &&
      this.claimNeeded.taskOpen === true) {
      this.message_2 = this.owscContent.CO_89A_Body.value;
    } else if (this.owscContent.CO_505_Body && !this.claimNeeded.taskOpen) {
      this.message_2 = this.owscContent.CO_505_Body.value;
    }
  }

  fetchOWCSContentFromService() {
    this.pageId = this.contentService.getPageId(AppConstants.CLAIM_OVERVIEW);
    if (this.pageId) {
      this.contentService.getOWCSResponse(this.pageId, AppConstants.CLAIM_OVERVIEW_U).subscribe(
        val => {
          this.owscContent = val;
          if (this.owscContent && this.owscContent.data) {
            if (this.owscContent.data.CO_500_Label) {
              this.infoLabel = this.owscContent.data.CO_500_Label.value;
            }
            if (this.owscContent.data.CO_501_Body) {
              this.message_1_Label = this.owscContent.data.CO_501_Body.value;
            //  this.message_1_Label = (this.claimNeeded && this.claimNeeded.coverageCategory && this.claimNeeded.coverageCategory.toUpperCase() === AppConstants.PFL) ? this.owscContent.data.CO_501_Body.value : this.owscContent.data.CO_501_Body.value;
            }
            if (this.owscContent.data.CO_503_Body) {
              this.gotoMissingInfo_Button = this.owscContent.data.CO_503_Body.value.replace(/(<([^>]+)>)/ig, '');
            }
            if (this.owscContent.data.CO_506_Label) {
              this.header_3 = this.owscContent.data.CO_506_Label.value;
            }
            if (this.owscContent.data.CO_507_Body) {
              this.message_3 = this.owscContent.data.CO_507_Body.value;
            }
            if (this.owscContent.data.CO_504_Label) {
              this.header_2 = this.owscContent.data.CO_504_Label.value;
            }

            if (this.owscContent.data.CO_89A_Body &&
              this.claimNeeded &&
              this.claimNeeded.taskOpen === true) {
              this.message_2 = this.owscContent.data.CO_89A_Body.value;
            } else if (this.owscContent.data.CO_505_Body && !this.claimNeeded.taskOpen) {
              this.message_2 = this.owscContent.data.CO_505_Body.value;
            }
            this.storageManagementService.setSessionData(SessionConstants.CLAIM_OVERVIEW_OWCS_CONTENT, val.data);
          }
        }, (error) => {
          console.error(error);
        });
    }
  }
}
