import {AfterViewInit, Component, OnInit, Output, ViewChild} from '@angular/core';
import {ClaimDocument} from '../../../shared/models/claim-document.model';
import {StorageManagementService} from '../../../shared/services/storage-management.service';
import {AppConstants} from '../../../shared/constants/app.constants';
import {AppLabelConstants} from '../../../shared/constants/appLabel.constants';
import {SessionConstants} from '../../../shared/constants/session.constants';

@Component({
  selector: 'claimant-documents',
  templateUrl: 'document.component.html',
  styleUrls: ['document.component.scss']
})
export class ClaimantDocumentsComponent implements OnInit, AfterViewInit  {
  @Output() numberOfDocuments = 100;
  //View child element is used to read porty of specific tag.
  @ViewChild('dochead', { static: false }) dochead;
  @ViewChild('showmorebtn', { static: false }) showmorebtn;
  @ViewChild('uploaddoc', { static: false }) uploaddoc;
  @ViewChild('uploadbtn', { static: false })uploadbtn;
  public appConstant = AppConstants;
  public appLabelConstant = AppLabelConstants;
  public documents;
  public previousUrl;
  public spinner = false;
  public img;
  ClaimDocument: ClaimDocument;

  constructor(private storageManagementService: StorageManagementService) {
  }


  ngOnInit() {
     this.previousUrl = this.storageManagementService.getSessionData(SessionConstants.PREVIOUS_URL);
    this.storageManagementService.setSessionObject(SessionConstants.CLAIM_DOCS_RETURN_LINK, AppLabelConstants.ALL_DOCUMENTS);
  }

  ngAfterViewInit() {
  }

  /***********************
   * Date : 26/09/17
   * Auther :Ac12323
   * Modified By: AC12323
   * Modified date : 26/09/17
   * Purpose :showdocument function is used to store path and doc name in session storage base on array index.
   * Note:
   */

  /*showdocument(doc,path){
    sessionStorage.setItem("path",path);
    sessionStorage.setItem("docpath",doc);
    this.docName= sessionStorage.getItem('docpath');
    this.filepath = sessionStorage.getItem('path'); // This is used to recieve file Name from sessionstorage.

    this.extension = this.docName.split('.').pop(); // Read File extension without name to filter accoding to type.

    if(this.extension === 'pdf' || this.extension === 'doc' ||  this.extension === 'txt'  ||this.extension === 'XLS'|| this.extension === 'PPT'){
      window.open(this.filepath, '_blank');
    }else{
      this.route.navigate(['/Documentsdetails']);
    }
    /!*window.open(path, '_blank', 'fullscreen=yes');*!/
  }*/

  /***********************
   * Date : 26/09/17
   * Auther :Ac12323
   * Modified By:
   * Modified date :
   * Purpose : showmore is used to show more user documents.
   * Note: .
   */

  /*showmore(){
    this.showmoredocument= this.showmoredocument+3;
  }
  uploaddocument(){
    this.uploaddoc.nativeElement.click();

  }
  fileEvent($event){
    for(var i=0; i<$event.target.files.length;i++ ){
      var file:File = $event.target.files[i];
      console.log(file);
      var reader = new FileReader();
      if($event.target.files[i]){
        this.load = false;
        reader.onload = this._handlefile.bind(this);
        reader.readAsDataURL($event.target.files[i]);
      }
    }
  }

  _handlefile(event){
    var reader = event.target;
    this.img=reader.result;
    this.load = true;
  }*/
}
