
import {catchError, map} from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { env } from '@env/environment-loader';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { AppLabelConstants } from '@app/shared/constants/appLabel.constants';
import { ResponseErrorHandlerService } from './response-error-handler.service';
import {Observable} from '../../../../node_modules/rxjs';


@Injectable()
export class NewRaeDiagnosisFlowService {

  constructor(private errorHandler: ResponseErrorHandlerService,
    private httpClient: HttpClient) { }
    public getNewExtensionicdcptData(personId: string, claimId: string): Observable<any> {
      return this.httpClient.get(env.thaaWKABPortalService.newExtensionicdcptInfo + '&personId=' + personId + '&claimId=' + claimId,
        {
          headers: new HttpHeaders()
            .set(AppLabelConstants.CONTENT_TYPE_KEY, AppLabelConstants.APPLICATION_KEY)
        }).pipe(map(
        (response: Response) => {
          if (!this.errorHandler.isServiceResponseHasError(response)) {
            return response;
          } else {
            throw response;
          }
        }
      ), catchError((error: any) => this.errorHandler.handleErrorThaa(error)), );
    }
  }
