
import {catchError, filter, map, switchMap, take, takeUntil} from 'rxjs/operators';
/**
 * Created by AC12212 on 8/7/2017.
 */
import {Injectable} from '@angular/core';
import {Observable, ReplaySubject, Subject, of} from 'rxjs';

import {env} from '../../../environments/environment-loader';
import {StorageManagementService} from './storage-management.service';
import {ResponseErrorHandlerService} from './response-error-handler.service';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {AuthStateService} from '../../shared/services/auth-state.service';
import {FeatureTurnOnOffResponse} from '../models/Feature';
import {SessionConstants} from '../constants/session.constants';
import {AppLabelConstants} from '../constants/appLabel.constants';
import {AppConstants, AppLeaveConst} from "@shared/constants/app.constants";
import { CustomErrorService } from './custom-error.service';

/*import {FEATURE_TURN_ON_OFF_DATA} from '../../mock-data/mock.featuresTurnOnOff';*/


@Injectable()

export class ContentService {

  pageId: string;
  private siteMapResponse;
  private _destroy$: Subject<void> = new Subject();
  public isSiteMapReady: ReplaySubject<boolean> = new ReplaySubject<boolean>(1);


  constructor(private errorHandler: ResponseErrorHandlerService,
              private storageManagementService: StorageManagementService,
              private authStateService: AuthStateService,
              private httpClient: HttpClient,
              private customErrorService: CustomErrorService) {
  }

  //OWCS SiteMap Integration
  getSiteMap(): Observable<any> {
    let siteURL = env.gbContent.siteMapUrl;
    if (!this.authStateService.getLoginState()) {
      siteURL = env.gbContent.unAuthSiteMapUrl;
    }
    return this.httpClient.get(siteURL,
      {
        headers: new HttpHeaders()
          .set(AppLabelConstants.CONTENT_TYPE_KEY, AppLabelConstants.APPLICATION_KEY)
      }).pipe(
      map((response: Response) => {
          if (!this.errorHandler.isServiceResponseHasError(response)) {
            return response;
          } else {
            throw response;
          }
        }
      ), catchError((error: any) => this.errorHandler.handleError(error, true)), );


  }

  getPageId(pageName: string): any {
      if (this.storageManagementService.getSessionData(SessionConstants.SITE_MAP_DATA)) {
        this.siteMapResponse = this.storageManagementService.getSessionData(SessionConstants.SITE_MAP_DATA);
        if (this.siteMapResponse && this.siteMapResponse.data && this.siteMapResponse.data.sitemap && this.siteMapResponse.data.sitemap.length > 0) {
          return this.getPageIDFromSiteMapResponse(pageName);
      }
    } else if (this.storageManagementService.getSessionData(SessionConstants.SITE_MAP_DATA) === undefined) {
      this.getSiteMap().pipe(
        filter(siteMapData => !!siteMapData),
        takeUntil(this._destroy$),
        switchMap(siteMapResponse => {
          if (siteMapResponse) {
          this.siteMapResponse = siteMapResponse;
          this.storageManagementService.setSessionData(SessionConstants.SITE_MAP_DATA, siteMapResponse);
          return of(this.getPageIDFromSiteMapResponse(pageName));
          }
        }
      )).subscribe(
        pageID => pageID);
    }
  }

  getPageIDFromSiteMapResponse(pageName: string): string {
    let siteMapData = this.siteMapResponse.data.sitemap;
    siteMapData = siteMapData.filter(data => data.pagename === pageName);
    return ( siteMapData[0] ) ? siteMapData[0].id : '';
  }

  getOWCSResponse(pageId, pageName?): Observable<any> {
    if (pageId.match("[a-zA-Z0-9-]*$")) {
      return this.httpClient.get((this.authStateService.getLoginState() ?
        env.gbContent.assetsByPageId :
        env.gbContent.assetsByPageId_unauth).replace('{pageId}', pageId),
        {
          headers: new HttpHeaders().set(AppLabelConstants.CONTENT_TYPE_KEY, AppLabelConstants.APPLICATION_KEY)
        }).pipe(
        map((response: Response) => {
            if (!this.errorHandler.isServiceResponseHasError(response)) {
              return response;
            } else {
              throw response;
            }
          }
        ), catchError((error: any) => this.handleErrors(error, pageName)) );
    }
  }

  handleErrors(error: any, pageName: string): Observable<any>{
    let personId: string = JSON.parse(this.storageManagementService.retrieveSessionObject(SessionConstants.SESSION_DATA)).sessionData.personId;
    if(personId !== null && pageName !== null) {
      this.customErrorService.logError(personId, AppLabelConstants.CONTENT_FAILURE_MESSAGE+pageName, AppLabelConstants.LOG_LEVEL_ERROR)
      .pipe(take(1))
      .subscribe();
    }
    return this.errorHandler.handleError(error);
  }
  getFeaturesTurnOnOff(): Observable<FeatureTurnOnOffResponse> {
    const pageId = this.getPageId(AppConstants.SYS_MESSAGE_DOWN_PAGE_NAME);
    return this.httpClient.get((env.gbContent.featuresTurnOnOff_auth + pageId),
      {
        headers: new HttpHeaders().set(AppLabelConstants.CONTENT_TYPE_KEY, AppLabelConstants.APPLICATION_KEY)
      }).pipe(
      map((response: FeatureTurnOnOffResponse) => {
          if (!this.errorHandler.isServiceResponseHasError(response)) {
            return response;
          } else {
            throw response;
          }
        }
      ), catchError((error: any) => this.errorHandler.handleError(error)), );
  }
}
