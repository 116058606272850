import { IExistingOvertimeContent } from "@app/cxr/modules/intake/shared/models/existing-overtime.model";
import { IMinutesHoursListObject } from "@app/cxr/modules/intake/shared/models/intake-work-schedule.model";
import { IHukDropDownOptions } from "../models/absence-section.model";
import { IDayAndMonthFormat } from "../models/date-format.model";

export class INTAKE_OWCS_IDS {
  static SANLS1_14A_Body = "SANLS1_14A_Body";
  static SANLS1_14B_Body = "SANLS1_14B_Body";
  static SANLS1_14C_Body = "SANLS1_14C_Body";
  static SANLS1_14_Body = "SANLS1_14_Body";
  static SANLS1_17_Body = "SANLS1_17_Body";
  static SANLS1_19_Body = "SANLS1_19_Body";
  static SANLS1_18_Body = "SANLS1_18_Body";
  static SANLS1_20_Body = "SANLS1_20_Body";
  static MATERNITY_ARRAY = ["SANLS1_22_Body", "SANLS1_23_Body", "SANLS1_24_Body"];
  static PARENTHOOD_ARRAY = ["SANLS1_22_Body", "SANLS1_23_Body"];
  static ESTIMATE_FDA_RADIO_ARRAY = ["SANLS1_17D_Body", "SANLS1_17E_Body"];
  static SANLS1_21_Body = "SANLS1_21_Body";
  static SANLS1_17C_Body = "SANLS1_17C_Body";
  static SANLS1_17F_Body = "SANLS1_17F_Body";
  static SANLS1_17A_Body = "SANLS1_17A_Body";
  static SANLS1_22_Body = "SANLS1_22_Body";
  static SANLS1_23_Body = "SANLS1_23_Body";
  static SANLS1_24_Body = "SANLS1_24_Body";
  static SANLS1_25_Body = "SANLS1_25_Body";
  static SANLS1_26_Body = "SANLS1_26_Body";
  static SANLS1_27_Body = "SANLS1_27_Body";
  static SANLS1_28_Body = "SANLS1_28_Body";
  static SANLS1_29_Body = "SANLS1_29_Body";
  static SANLS1_30 = "SANLS1_30";
  static SANLS1_31 = "SANLS1_31";
  static SANLS1_32 = "SANLS1_32";
  static SANLS1_33 = "SANLS1_33_Body";
  static SANLS1_34 = "SANLS1_34_Body";
  static SANLS1_35 = "SANLS1_35_Body";
  static SANLS1_36 = "SANLS1_36_Body";
  static SANLS1_37 = "SANLS1_37_Body";
  static SANLS1_38 = "SANLS1_38_Body";
  static SANLS1_39 = "SANLS1_39_Body";
  static SANLS1_40_Body = "SANLS1_40_Body";
  static SANLS1_41_Body = "SANLS1_41_Body";
  static SANLS1_42_Body = "SANLS1_42_Body";
  static SANLS1_43_Body = "SANLS1_43_Body";
  static SANLS1_44_Body = "SANLS1_44_Body";
  static SANLS1_45_Body = "SANLS1_45_Body";
  static SANLS1_46_Body = "SANLS1_46_Body";
  static SANLS1_47_Body = "SANLS1_47_Body";
  static SANLS1_48_Body = "SANLS1_48_Body";
  static SANLS1_49_Body = "SANLS1_49_Body";
  static SANLS1_51_Body = "SANLS1_51_Body";
  static NI_009_Body = "NI_009_Body";
  static NI_11A_Body = "NI_11A_Body";
  static NI_007A_Body = "NI_007A_Body";
  static NI_013_Body = "NI_013_Body";
  static NI_014_Body = "NI_014_Body";
  static NI_015_Body = "NI_015_Body";
  static NI_016_Body = "NI_016_Body";
  static NI_005_Body = "NI_005_Body";
  static NI_018_Body = "NI_018_Body";
  static NI_006_Body = "NI_006_Body";
  static NI_007_Body = "NI_007_Body";
  static NI_008_Body = "NI_008_Body";
  static NI_010_Body = "NI_010_Body";
  static NI_011_Body = "NI_011_Body";
  static NI_011B_Body = "NI_011B_Body";
  static NI_017_Body = "NI_017_Body";
  static NI_021_Body = "NI_021_Body";
  static NI_022_Body = "NI_022_Body";
  static NI_023_Body = "NI_023_Body";
  static NI_024_Body = "NI_024_Body";
  static NI_020A_Body = "NI_020A_Body";
  static NI_020_Body = "NI_020_Body";
  static NI_020B_Body = "NI_020B_Body";
  static NI_019_Body = "NI_019_Body";
  static NI_100_Body = "NI_100_Body";
  static NI_101_Body = "NI_101_Body";
  static NI_102_Body = "NI_102_Body";
  static NI_104_Body = "NI_104_Body";
  static NI_111_Body = "NI_111_Body";
  static NI_113_Body = "NI_113_Body";
  static NI_114_Body = "NI_114_Body";
  static NI_168_Body = "NI_168_Body";
  static NI_170_Body = "NI_170_Body";

  static NI_150_Body = "NI_150_Body";
  static NI_153_Body = "NI_153_Body";
  static NI_154_Body = "NI_154_Body";
  static NI_155_Body = "NI_155_Body";
  static NI_161_Body = "NI_161_Body";
  static NI_700_Body = "NI_700_Body";
  static NI_701_Body = "NI_701_Body";
  static NI_703_Body = "NI_703_Body";
  static NI_704_Body = "NI_704_Body";

  //About your absence
  static NI_610_Body = "NI_610_Body";
  static NI_611_Body = "NI_611_Body";
  static NI_507B_Body = "NI_507B_Body";
  static NI_507C_Body = "NI_507C_Body";
  static NI_563B_Body = "NI_563B_Body";
  static NI_563C_Body = "NI_563C_Body";
  static NI_705_Body = "NI_705_Body";
  static NI_707B_Body = "NI_707B_Body";
  static NI_707A_Body = "NI_707A_Body";
  static NI_744A_Body = "NI_744A_Body";
  static NI_708_Body = "NI_708_Body";
  static NI_709_Body = "NI_709_Body";
  static NI_710_Body = "NI_710_Body";
  static NI_711_Body = "NI_711_Body";
  static NI_712_Body = "NI_712_Body";
  static NI_713_Body = "NI_713_Body";
  static NI_714_Body = "NI_714_Body";
  static NI_715_Body = "NI_715_Body";
  static NI_716_Body = "NI_716_Body";
  static NI_717_Body = "NI_717_Body";
  static NI_718_Body = "NI_718_Body";
  static NI_719_Body = "NI_719_Body";
  static NI_720_Body = "NI_720_Body";
  static NI_721_Body = "NI_721_Body";
  static NI_722_Body = "NI_722_Body";
  static NI_726_Body = "NI_726_Body";
  static NI_727_Body = "NI_727_Body";
  static NI_728_Body = "NI_728_Body";
  static NI_729_Body = "NI_729_Body";
  static NI_730_Body = "NI_730_Body";
  static NI_731_Body = "NI_731_Body";
  static NI_732_Body = "NI_732_Body";
  static NI_734_Body = "NI_734_Body";
  static NI_735_Body = "NI_735_Body";
  static NI_736_Body = "NI_736_Body";
  static NI_737_Body = "NI_737_Body";
  static NI_738_Body = "NI_738_Body";
  static NI_740_Body = "NI_740_Body";
  static NI_742_Body = "NI_742_Body";
  static complicationsDropDownValue = [
    "NI_717_Body",
    "NI_718_Body",
    "NI_719_Body",
    "NI_720_Body",
    "NI_721_Body",
    "NI_722_Body",
  ];
  static NI_844_Body = "NI_844_Body";
  static NI_851_Body = "NI_851_Body";
  static NI_852_Body = "NI_852_Body";
  static NI_846_Body = "NI_846_Body";
  static NI_847_Body = "NI_847_Body";
  static NI_850_Body = "NI_850_Body";
  static NI_845_Body = "NI_845_Body";
  static NI_828_Body = "NI_828_Body";
  static NI_829_Body = "NI_829_Body";
  static NI_830_Body = "NI_830_Body";
  static NI_831_Body = "NI_831_Body";
  static NI_832_Body = "NI_832_Body";
  static NI_833_Body = "NI_833_Body";
  static NI_834_Body = "NI_834_Body";
  static NI_870_Body = "NI_870_Body";
  static NI_871_Body = "NI_871_Body";
  static NI_872_Body = "NI_872_Body";
  static NI_839_Body = "NI_839_Body";
  static NI_842_Body = "NI_842_Body";
  static NI_841_Body = "NI_841_Body";
  static NI_843_Body = "NI_843_Body";
  static NI_862_Body = "NI_862_Body";
  static NI_920_Body = "NI_920_Body";
  static NI_921_Body = "NI_921_Body";
  static NI_920A_Body = "NI_920A_Body";
  static NI_920B_Body = "NI_920B_Body";
  static WORK_ARRAY = ["NI_921A_Body", "NI_921B_Body", "NI_921C_Body"];
  static NI_921A_Body = "NI_921A_Body";
  static NI_921C_Body = "NI_921C_Body";
  static NI_921B_Body = "NI_921B_Body";
  static WORK_SUB_LIST1 = [
    "NI_922A_Body",
    "NI_922B_Body",
    "NI_922C_Body",
    "NI_922D_Body",
    "NI_922E_Body",
  ];
  static WORK_SUB_LIST2 = ["NI_923A_Body", "NI_923B_Body", "NI_923C_Body", "NI_923D_Body"];
  static WORK_SUB_LIST3 = ["NI_924A_Body", "NI_924B_Body", "NI_924C_Body"];
  static NI_922_Body = "NI_922_Body";
  static NI_923_Body = "NI_923_Body";
  static NI_924_Body = "NI_924_Body";
  static NI_925_Body = "NI_925_Body";
  static SITTING_LIST = ["NI_925A_Body", "NI_925B_Body", "NI_925C_Body"];
  static NI_926_Body = "NI_926_Body";
  static NI_927_Body = "NI_927_Body";
  static NI_952_Body = "NI_952_Body";
  static NI_952A_Body = "NI_952A_Body";
  static NI_952AA_Body = "NI_952AA_Body";
  static NI_952AB_Body = "NI_952AB_Body";
  static NI_952B_Body = "NI_952B_Body";
  static NI_952C_Body = "NI_952C_Body";
  static NI_952D_Body = "NI_952D_Body";
  static NI_952E_Body = "NI_952E_Body";
  static NI_952F_Body = "NI_952F_Body";
  static NI_952G_Body = "NI_952G_Body";
  static NI_926B_Body = "NI_926B_Body";
  static NI_926A_Body = "NI_926A_Body";
  static SUB_JOB_REQUIRE = ["NI_927A_Body", "NI_927B_Body"];
  static NI_835_Body = "NI_835_Body";
  static NI_612_Body = "NI_612_Body";
  static NI_613_Body = "NI_613_Body";
  static NI_614_Body = "NI_614_Body";
  static NI_615_Body = "NI_615_Body";
  static NI_616_Body = "NI_616_Body";

  //about your delivery recently delivered
  static NI_744_Body = "NI_744_Body";
  static NI_747_Body = "NI_747_Body";
  static NI_750_Body = "NI_750_Body";
  static NI_751_Body = "NI_751_Body";
  static NI_752_Body = "NI_752_Body";
  static NI_753_Body = "NI_753_Body";
  static NI_754_Body = "NI_754_Body";
  static NI_755_Body = "NI_755_Body";
  static NI_756_Body = "NI_756_Body";
  static NI_757_Body = "NI_757_Body";
  static NI_758_Body = "NI_758_Body";
  static NI_760_Body = "NI_760_Body";
  static NI_762_Body = "NI_762_Body";
  static NI_861_Body = "NI_861_Body";
  static deliveryOptionsDropDownValue = ["NI_750_Body", "NI_751_Body"];

  // about your delivery
  static NI_882_Body = "NI_882_Body";
  static NI_883_Body = "NI_883_Body";
  //Bonding page
  static NI_810_Body = "NI_810_Body";
  static NI_811_Body = "NI_811_Body";
  static NI_812_Body = "NI_812_Body";
  static NI_813_Body = "NI_813_Body";
  static NI_814_Body = "NI_814_Body";
  static NI_815_Body = "NI_815_Body";
  static NI_816_Body = "NI_816_Body";
  static NI_817_Body = "NI_817_Body";
  static NI_818_Body = "NI_818_Body";
  static NI_822_Body = "NI_822_Body";
  static NI_823_Body = "NI_823_Body";
  static NI_824_Body = "NI_824_Body";
  static NI_827_Body = "NI_827_Body";
  static NI_825_Body = "NI_825_Body";
  static NI_8855_Body = "NI_8855_Body";
  static NI_8866_Body = "NI_8866_Body";
  static NI_8867_Body = "NI_8867_Body";
  static NI_8868_Body = "NI_8868_Body";
  static NI_8869_Body = "NI_8869_Body";
  static NI_8870_Body = "NI_8870_Body";
  static NI_8871_Body = "NI_8871_Body";
  static NI_8873_Body = "NI_8873_Body";
  static NI_8874_Body = "NI_8874_Body";
  static NI_8875_Body = "NI_8875_Body";
  static NI_8893_Body = "NI_8893_Body";
  static NI_8876_Body = "NI_8876_Body";
  static NI_8877_Body = "NI_8877_Body";
  static NI_8878_Body = "NI_8878_Body";
  static NI_8879_Body = "NI_8879_Body";
  static NI_8881_Body = "NI_8881_Body";
  static NI_8882_Body = "NI_8882_Body";
  static NI_8883_Body = "NI_8883_Body";
  static NI_8884_Body = "NI_8884_Body";
  static NI_8885_Body = "NI_8885_Body";
  static NI_8886_Body = "NI_8886_Body";
  static NI_8889_Body = "NI_8889_Body";
  static NI_8890_Body = "NI_8890_Body";
  static NI_8890A_Body = "NI_8890A_Body";
  static NI_8890B_Body = "NI_8890B_Body";
  static NI_8890C_Body = "NI_8890C_Body";
  static NI_8890D_Body = "NI_8890D_Body";
  static NI_948_Body = "NI_948_Body";

  /**BP-Bullet points array for intake things you will need bullet points section */
  static RECENTLY_DELIVERED_NO_LEAVE_BP_ARRAY = ["NI_014_Body", "NI_015_Body", "NI_018_Body"];
  static STILL_PREGNANT_BP_NO_LEAVE_ARRAY = ["NI_014_Body", "NI_015_Body", "NI_016_Body"];
  static STILL_PREGNANT_BP_WITH_LEAVE_ARRAY = [
    "NI_014_Body",
    "NI_015_Body",
    "NI_016_Body",
    "NI_017_Body",
  ];
  static RECENTLY_DELIVERED_BP_WITH_LEAVE_ARRAY = [
    "NI_014_Body",
    "NI_015_Body",
    "NI_017_Body",
    "NI_018_Body",
  ];
  static SOMETHING_UNEXPECTED_BP_HAPPENED_ARRAY = [
    "NI_021_Body",
    "NI_022_Body",
    "NI_023_Body",
    "NI_024_Body",
  ];
  /**Announcement Body owcs id Array */
  static ANNOUNCEMENTBODY_STILL_PREGNANT_WITH_LEAVE = ["NI_010_Body", "NI_011_Body"];
  static ANNOUNCEMENTBODY_STILL_PREGNANT_WITH_ONLY_LOA = ["NI_010_Body", "NI_011B_Body"];
  static ANNOUNCEMENTBODY_STILL_PREGNANT_NO_LEAVE = ["NI_009_Body", "NI_011A_Body"];
  static ANNOUNCEMENTBODY_DELIVERED_NO_LEAVE = ["NI_005_Body", "NI_007A_Body"];
  static ANNOUNCEMENTBODY_DELIVERED_WITH_LEAVE = ["NI_006_Body", "NI_007_Body"];
  static ANNOUNCEMENTBODY_DELIVERED_WITH_LEAVE_ONLY_LOA = ["NI_006_Body", "NI_007B_Body"];
  static ANNOUNCEMENTBODY_SOMETHING_UNEXPECTED = ["NI_019_Body", "NI_020_Body"];
  /**Edit Info Component */
  static NI_131_Body = "NI_131_Body";
  static NI_132_Body = "NI_132_Body";
  static NI_157_Body = "NI_157_Body";
  static NI_157a_Body = "NI_157a_Body";
  static NI_134_Body = "NI_134_Body";
  static NI_136_Body = "NI_136_Body";
  static NI_158_Body = "NI_158_Body";
  static NI_138_Body = "NI_138_Body";
  static NI_159_Body = "NI_159_Body";
  static NI_140_Body = "NI_140_Body";
  static NI_160_Body = "NI_160_Body";
  static NI_142_Body = "NI_142_Body";
  static NI_144_Body = "NI_144_Body";
  static NI_146_Body = "NI_146_Body";
  static NI_148_Body = "NI_148_Body";
  static NI_171_Body = "NI_171_Body";
  static NI_171A_Body = "NI_171A_Body";
  static NI_171B_Body = "NI_171B_Body";
  static NI_172_Body = "NI_172_Body";
  static NI_162_Body = "NI_162_Body";
  static NI_163_Body = "NI_163_Body";

  static NI_116_Body = "NI_116_Body";
  static NI_117_Body = "NI_117_Body";
  static NI_119_Body = "NI_119_Body";
  static NI_121_Body = "NI_121_Body";
  static NI_123_Body = "NI_123_Body";
  static NI_125_Body = "NI_125_Body";
  static NI_127_Body = "NI_127_Body";
  static NI_129_Body = "NI_129_Body";

  static SANLS1_14V1_Body = "SANLS1_14V1_Body";
  static SANLS1_14V2_Body = "SANLS1_14V2_Body";
  static SANLS1_14V2B = "SANLS1_14V2B";
  static SANLS1_14V2B2 = "SANLS1_14V2B2";
  static SANLS1_14V1B = "SANLS1_14V1B";
  static SANLS1_14V1B2 = "SANLS1_14V1B2";

  //Cancel Model
  static NI_165A_Body = "NI_165A_Body";
  static NI_165B_Body = "NI_165B_Body";
  static NI_165C_Body = "NI_165C_Body";
  static NI_165D_Body = "NI_165D_Body";

  //Cancel Model
  static NI_779_Body = "NI_779_Body";
  static NI_780_Body = "NI_780_Body";
  static NI_781_Body = "NI_781_Body";
  static NI_782_Body = "NI_782_Body";

  // about section
  static NI_500_Body = "NI_500_Body";
  static NI_501_Body = "NI_501_Body";
  static NI_503_Body = "NI_503_Body";
  static NI_504_Body = "NI_504_Body";

  static NI_601_Body = "NI_601_Body";
  static NI_603_Body = "NI_603_Body";
  static NI_604_Body = "NI_604_Body";
  static NI_605_Body = "NI_605_Body";
  static NI_606_Body = "NI_606_Body";
  static NI_602_Body = "NI_602_Body";
  static NI_617_Body = "NI_617_Body";
  static NI_618_Body = "NI_618_Body";
  static NI_619_Body = "NI_619_Body";
  static NI_620_Body = "NI_620_Body";
  static NI_621_Body = "NI_621_Body";
  static NI_622_Body = "NI_622_Body";
  static NI_623_Body = "NI_623_Body";
  static NI_624_Body = "NI_624_Body";

  static NI_607_Body = "NI_607_Body";
  static NI_608_Body = "NI_608_Body";
  static NI_609_Body = "NI_609_Body";

  static NI_253_Body = "NI_253_Body";
  static NI_251_Body = "NI_251_Body";
  static NI_250_Body = "NI_250_Body";

  //healthcare Provider
  static NI_255_Body = "NI_255_Body";
  static NI_235_Body = "NI_235_Body";
  static NI_236_Body = "NI_236_Body";
  static NI_260_Body = "NI_260_Body";
  static NI_263_Body = "NI_263_Body";
  static NI_256_Body = "NI_256_Body";
  static NI_259_Body = "NI_259_Body";
  static NI_264_Body = "NI_264_Body";
  static NI_267_Body = "NI_267_Body";
  static NI_268_Body = "NI_268_Body";
  static NI_271_Body = "NI_271_Body";
  static NI_272_Body = "NI_272_Body";
  static NI_275_Body = "NI_275_Body";
  static NI_276_Body = "NI_276_Body";
  static NI_278_Body = "NI_278_Body";
  static NI_284_Body = "NI_284_Body";
  static NI_286_Body = "NI_286_Body";
  static NI_769_Body = "NI_769_Body";
  static NI_277_Body = "NI_277_Body";
  static NI_285_Body = "NI_285_Body";
  static NI_804A_Body = "NI_804A_Body";
  static NI_321_Body = "NI_321_Body";
  static NI_322_Body = "NI_322_Body";
  static NI_322A_Body = "NI_322A_Body";
  static NI_334_Body = "NI_334_Body";
  static NI_319_Body = "NI_319_Body";
  static NI_783_Body = "NI_783_Body";
  static NI_784_Body = "NI_784_Body";
  static NI_786_Body = "NI_786_Body";

  //About your absence
  static NI_542_Body = "NI_542_Body";
  static NI_545_Body = "NI_545_Body";
  static NI_546_Body = "NI_546_Body";
  static NI_547_Body = "NI_547_Body";
  static NI_548_Body = "NI_548_Body";
  static NI_549_Body = "NI_549_Body";
  static NI_550_Body = "NI_550_Body";
  static NI_563_Body = "NI_563_Body";
  static NI_511_Body = "NI_511_Body";
  static NI_512_Body = "NI_512_Body";
  static NI_515_Body = "NI_515_Body";
  static NI_516_Body = "NI_516_Body";
  static NI_517_Body = "NI_517_Body";
  static NI_518_Body = "NI_518_Body";
  static NI_519_Body = "NI_519_Body";
  static NI_770_Body = "NI_770_Body";
  static NI_771_Body = "NI_771_Body";
  static NI_772_Body = "NI_772_Body";
  static NI_773_Body = "NI_773_Body";
  static NI_774_Body = "NI_774_Body";
  static NI_777_Body = "NI_777_Body";
  static NI_778_Body = "NI_778_Body";
  static NI_776_Body = "NI_776_Body";
  static NI_836_Body = "NI_836_Body";
  static NI_837_Body = "NI_837_Body";
  static NI_838_Body = "NI_838_Body";

  static NI_551_Body = "NI_551_Body";
  static NI_556_Body = "NI_556_Body";
  static NI_857_Body = "NI_857_Body";
  static NI_858_Body = "NI_858_Body";
  static NI_859_Body = "NI_859_Body";
  static NI_860_Body = "NI_860_Body";
  static INTAKE_CHECKBOX_ARRAY = [
    "NI_552_Body",
    "NI_553_Body",
    "NI_554_Body",
    "NI_555_Body",
    "NI_556_Body",
    "NI_557_Body",
  ];
  static INTAKE_CHECKBOX_ARRAY_LIST = [
    "NI_552_Body",
    "NI_553_Body",
    "NI_554_Body",
    "NI_556_Body",
    "NI_557_Body",
  ];
  static NI_559_Body = "NI_559_Body";
  static NI_557_Body = "NI_557_Body";
  static NI_559A_Body = "NI_559A_Body";
  static NI_764_Body = "NI_764_Body";
  static NI_560_Body = "NI_560_Body";
  static NI_561_Body = "NI_561_Body";
  static NI_562_Body = "NI_562_Body";
  static NI_562A_Body = "NI_562A_Body";
  static ESTIMATE_REASON_ARRAY = ["NI_848_Body", "NI_849_Body"];
  // add healtlcare form
  static NI_287_Body = "NI_287_Body";
  static NI_288_Body = "NI_288_Body";
  static NI_290_Body = "NI_290_Body";
  static NI_291_Body = "NI_291_Body";
  static NI_293_Body = "NI_293_Body";
  static NI_294_Body = "NI_294_Body";
  static NI_296_Body = "NI_296_Body";
  static NI_297_Body = "NI_297_Body";
  static NI_299_Body = "NI_299_Body";
  static NI_300_Body = "NI_300_Body";
  static NI_302_Body = "NI_302_Body";
  static NI_303_Body = "NI_303_Body";
  static NI_305_Body = "NI_305_Body";
  static NI_306_Body = "NI_306_Body";
  static NI_307_Body = "NI_307_Body";
  static NI_309_Body = "NI_309_Body";
  static NI_311_Body = "NI_311_Body";
  static NI_313_Body = "NI_313_Body";
  static NI_316_Body = "NI_316_Body";
  static NI_318_Body = "NI_318_Body";
  //about your absence future
  static NI_538A_Body = "NI_538A_Body";
  static NI_538_Body = "NI_538_Body";
  static NI_530_Body = "NI_530_Body";
  static NI_523_Body = "NI_523_Body";
  static NI_524_Body = "NI_524_Body";
  static NI_522_Body = "NI_522_Body";
  static NI_826_Body = "NI_826_Body";
  static NI_508_Body = "NI_508_Body";
  static NI_507_Body = "NI_507_Body";
  static NI_539_Body = "NI_539_Body";
  static NI_541A_Body = "NI_541A_Body";
  static NI_540_Body = "NI_540_Body";
  static NI_541_Body = "NI_541_Body";
  static NI_254_Body = "NI_254_Body";
  static NI_853_Body = "NI_853_Body";
  static NI_854_Body = "NI_854_Body";
  static NI_855_Body = "NI_855_Body";
  static NI_856_Body = "NI_856_Body";
  static NI_917A_Body = "NI_917A_Body";
  static NI_917B_Body = "NI_917B_Body";
  static NI_917C_Body = "NI_917C_Body";
  static NI_917D_Body = "NI_917D_Body";
  static NI_917E_Body = "NI_917E_Body";
  static NI_918_Body = "NI_918_Body";
  static NI_919_Body = "NI_919_Body";
  static NI_917_Body = "NI_917_Body";
  static PFL_CHECKBOX_OPTIONS = [
    "NI_917A_Body",
    "NI_917B_Body",
    "NI_917C_Body",
    "NI_917D_Body",
    "NI_917E_Body",
  ];
  //Employment Questions
  static NI_903_Body = "NI_903_Body";
  static NI_904_Body = "NI_904_Body";
  static NI_905A_Body = "NI_905A_Body";
  static NI_905B_Body = "NI_905B_Body";
  static NI_884_Body = "NI_884_Body";
  static NI_885_Body = "NI_885_Body";
  static NI_886_Body = "NI_886_Body";
  static NI_887_Body = "NI_887_Body";
  static NI_887A_Body = "NI_887A_Body";
  static NI_887B_Body = "NI_887B_Body";
  static NI_906_Body = "NI_906_Body";
  static CLAIM_DROPDOWN = [
    "NI_906B_Body",
    "NI_906A_Body",
    "NI_906C_Body",
    "NI_906D_Body",
    "NI_906E_Body",
    "NI_906F_Body",
  ];
  static NI_907_Body = "NI_907_Body";
  static NI_908_Body = "NI_908_Body";
  static NI_909_Body = "NI_909_Body";
  static NI_912_Body = "NI_912_Body";
  static NI_910_Body = "NI_910_Body";
  static NI_911_Body = "NI_911_Body";
  static NI_913_Body = "NI_913_Body";
  static NI_914_Body = "NI_914_Body";
  static NI_915_Body = "NI_915_Body";

  //notification Preferences
  static NI_793_Body = "NI_793_Body";
  static NI_794_Body = "NI_794_Body";
  static NI_795_Body = "NI_795_Body";
  static NI_797A_Body = "NI_797A_Body";
  static NI_801_Body = "NI_801_Body";
  static NI_803_Body = "NI_803_Body";
  static NI_796_Body = "NI_796_Body";
  static NI_797_Body = "NI_797_Body";
  static NI_787_Body = "NI_787_Body";
  static NI_787A_Body = "NI_787A_Body";
  static NI_789_Body = "NI_789_Body";
  static NI_792_Body = "NI_792_Body";
  static NI_790_Body = "NI_790_Body";
  static NI_799_Body = "NI_799_Body";
  static NI_800_Body = "NI_800_Body";
  static NI_802_Body = "NI_802_Body";
  static NI_805_Body = "NI_805_Body";
  static NI_806_Body = "NI_806_Body";
  static NI_807_Body = "NI_807_Body";
  static NI_808_Body = "NI_808_Body";
  static NI_809_Body = "NI_809_Body";
  static NI_865_Body = "NI_865_Body";
  static NI_866_Body = "NI_866_Body";
  static NI_867_Body = "NI_867_Body";
  static NI_868_Body = "NI_868_Body";
  static NI_869_Body = "NI_869_Body";
  static NI_873_Body = "NI_873_Body";
  static NI_874_Body = "NI_874_Body";
  static NI_875_Body = "NI_875_Body";
  static NI_876_Body = "NI_876_Body";
  static NI_878_Body = "NI_878_Body";
  static NI_879_Body = "NI_879_Body";
  static NI_881_Body = "NI_881_Body";
  static IE_003_Body = "IE_003_Body";
  static IE_004_Body = "IE_004_Body";
  static IE_005_Body = "IE_005_Body";
  static IE_007_Body = "IE_007_Body";
  static IE_008_Body = "IE_008_Body";
  static IE_009_Body = "IE_009_Body";
  static IE_010_Body = "IE_010_Body";
  static IE_011_Body = "IE_011_Body";
  static IE_012_Body = "IE_012_Body";
  static IE_013_Body = "IE_013_Body";
  static IE_016_Body = "IE_016_Body";
  static IE_017_Body = "IE_017_Body";
  static IE_018_Body = "IE_018_Body";
  static IE_019_Body = "IE_019_Body";
  static IE_020_Body = "IE_020_Body";
  static IE_022_Body = "IE_022_Body";
  static IE_024_Body = "IE_024_Body";
  static IE_025_Body = "IE_025_Body";
  static IE_026_Body = "IE_026_Body";
  static IE_027_Body = "IE_027_Body";
  static IE_028_Body = "IE_028_Body";
  static IE_029_Body = "IE_029_Body";
  static IE_030_Body = "IE_030_Body";
  static IE_031_Body = "IE_031_Body";
  static IE_032_Body = "IE_032_Body";
  static IE_033_Body = "IE_033_Body";
  static IE_034_Body = "IE_034_Body";
  static IE_035_Body = "IE_035_Body";
  static IE_043_Body = "IE_043_Body";
  static IE_048A_Body = "IE_048A_Body";
  static IE_065_Body = "IE_065_Body";
  static IE_065A_Body = "IE_065A_Body";
  static IE_065B_Body = "IE_065B_Body";
  static IE_066_Body = "IE_066_Body";
  static IE_066A_Body = "IE_066A_Body";
  static IE_066B_Body = "IE_066B_Body";
  static IE_067_Body = "IE_067_Body";
  static IE_067A_Body = "IE_067A_Body";
  static IE_067B_Body = "IE_067B_Body";
  static IE_069_Body = "IE_069_Body";
  static IE_030A_Body = "IE_030A_Body";
  static IE_070_Body = "IE_070_Body";
  static IE_071_Body = "IE_071_Body";
  static IE_072_Body = "IE_072_Body";
  static IE_073_Body = "IE_073_Body";
  static IE_074_Body = "IE_074_Body";
  static IE_075_Body = "IE_075_Body";

  static ESTIMATE_ARRAY = ["IE_033_Body", "IE_034_Body", "IE_035_Body"];
  static IE_039_Body = "IE_039_Body";
  static IE_040_Body = "IE_040_Body";
  static IE_041_Body = "IE_041_Body";
  static IE_042_Body = "IE_042_Body";
  static IE_036_Body = "IE_036_Body";
  static IE_044_Body = "IE_044_Body";
  static IE_045_Body = "IE_045_Body";
  static IE_046_Body = "IE_046_Body";
  static IE_047_Body = "IE_047_Body";
  static IE_048_Body = "IE_048_Body";
  static IE_049_Body = "IE_049_Body";
  static IE_050_Body = "IE_050_Body";
  static IE_051_Body = "IE_051_Body";
  static IE_052_Body = "IE_052_Body";
  static IE_053_Body = "IE_053_Body";
  static IE_037_Body = "IE_037_Body";
  static IE_068_Body = "IE_068_Body";
  static IE_068A_Body = "IE_068A_Body";
  static IE_068B_Body = "IE_068B_Body";
  static IE_068C_Body = "IE_068C_Body";
  static IE_069A_Body = "IE_069A_Body";
  static IE_069B_Body = "IE_069B_Body";
  static IE_069C_Body = "IE_069C_Body";
  static IE_054_Body = "IE_054_Body";
  static IE_055_Body = "IE_055_Body";
  static IE_056_Body = "IE_056_Body";
  static ESTIMATE_RETURNTOWORK_ARRAY = ["IE_055_Body", "IE_056_Body"];
  static IE_057_Body = "IE_057_Body";
  static IE_058_Body = "IE_058_Body";
  static IE_059_Body = "IE_059_Body";
  static IE_060_Body = "IE_060_Body";
  static IE_061_Body = "IE_061_Body";
  static IE_062_Body = "IE_062_Body";
  static IE_063_Body = "IE_063_Body";
  static IE_064_Body = "IE_064_Body";
  static IE_076_Body = "IE_076_Body";
  static IE_076A_Body = "IE_076A_Body";
  static IE_076B_Body = "IE_076B_Body";
  static IE_076C_Body = "IE_076C_Body";
  static IE_076D_Body = "IE_076D_Body";
  static IE_076E_Body = "IE_076E_Body";
  static IE_076F_Body = "IE_076F_Body";
  static IE_076G_Body = "IE_076G_Body";
  static IE_076H_Body = "IE_076H_Body";
  static IE_076I_Body = "IE_076I_Body";
  static IE_077_Body = "IE_077_Body";
  static IE_077A_Body = "IE_077A_Body";
  static IE_077B_Body = "IE_077B_Body";
  static IE_078_Body = "IE_078_Body";
  static IE_078A_Body = "IE_078A_Body";
  static IE_079_Body = "IE_079_Body";
  static IE_079A_Body = "IE_079A_Body";
  static IE_079B_Body = "IE_079B_Body";
  static IE_079C_Body = "IE_079C_Body";
  static IE_079D_Body = "IE_079D_Body";
  static IE_079E_Body = "IE_079E_Body";
  static IE_079F_Body = "IE_079F_Body";
  static IE_082_Body = "IE_082_Body";
  static IE_083_Body = "IE_083_Body";
  static IE_084_Body = "IE_084_Body";
  static IE_084A_Body = "IE_084A_Body";
  static IE_085_Body = "IE_085_Body";
  static IE_086_Body = "IE_086_Body";
  static IE_087_Body = "IE_087_Body";
  static IE_088_Body = "IE_088_Body";
  static IE_089_Body = "IE_089_Body";
  static IE_090_Body = "IE_090_Body";
  static IE_091_Body = "IE_091_Body";
  static IE_092_Body = "IE_092_Body";
  static IE_093_Body = "IE_093_Body";
  static IE_094_Body = "IE_094_Body";
  static IE_095_Body = "IE_095_Body";
  static IE_096_Body = "IE_096_Body";
  static IE_097_Body = "IE_097_Body";
  static IE_098_Body = "IE_098_Body";
  static IE_099_Body = "IE_099_Body";
  static IE_102_Body = "IE_102_Body";
  static IE_079G_Body = "IE_079G_Body";
  static IE_079H_Body = "IE_079H_Body";
  static IE_079I_Body = "IE_079I_Body";
  static IE_079J_Body = "IE_079J_Body";
  static IE_079K_Body = "IE_079K_Body";
  static IE_079L_Body = "IE_079L_Body";
  static IE_100_Body = "IE_100_Body";
  static IE_101_Body = "IE_101_Body";
  static IE_103_Body = "IE_103_Body";
  static IE_104_Body = "IE_104_Body";
  static IE_105_Body = "IE_105_Body";
  static IE_106_Body = "IE_106_Body";
  static IE_115_Body = "IE_115_Body";
  static IE_114_Body = "IE_114_Body";
  static IE_134_Body = "IE_134_Body";
  static IE_135_Body = "IE_135_Body";
  static IE_107_Body = "IE_107_Body";
  static IE_108_Body = "IE_108_Body";
  static IE_109_Body = "IE_109_Body";
  static IE_110_Body = "IE_110_Body";
  static IE_111_Body = "IE_111_Body";
  static IE_118_Body = "IE_118_Body";
  static IE_119_Body = "IE_119_Body";
  static IE_112_Body = "IE_112_Body";
  static IE_113_Body = "IE_113_Body";
  static IE_116_Body = "IE_116_Body";
  static IE_117_Body = "IE_117_Body";
  static IE_127_Body = "IE_127_Body";
  static IE_128_Body = "IE_128_Body";
  static IE_129_Body = "IE_129_Body";
  static IE_131_Body = "IE_131_Body";
  static IE_132_Body = "IE_132_Body";
  static IE_133_Body = "IE_133_Body";
  static IE_136_Body = "IE_136_Body";
  static IE_137_Body = "IE_137_Body";
  static IE_138_Body = "IE_138_Body";
  static IE_142_Body = "IE_142_Body";
  static IE_146_Body = "IE_146_Body";
  static IE_145_Body = "IE_145_Body";
  static IE_130_Body = "IE_130_Body";
  static IE1_130_Body = "IE1_130_Body";
  static IE_130A_Body = "IE_130A_Body";
  static IE_151_Body = "IE_151_Body";
  static IE_152_Body = "IE_152_Body";
  static IE_153_Body = "IE_153_Body";
  static IE_154_Body = "IE_154_Body";
  static IE_155_Body = "IE_155_Body";
  static IE_143_Body = "IE_143_Body";
  static IE_144_Body = "IE_144_Body";
  static IE_147_Body = "IE_147_Body";
  static IE_148_Body = "IE_148_Body";
  static IE_149_Body = "IE_149_Body";
  static IE_150_Body = "IE_150_Body";
  static IE_156_Body = "IE_156_Body";
  static IE_139_Body = "IE_139_Body";
  static IE_140_Body = "IE_140_Body";
  static IE_158_Body = "IE_158_Body";
  static IE_159_Body = "IE_159_Body";
  static IE_157_Body = "IE_157_Body";
  // intake confirmation
  static NI_888_Body = "NI_888_Body";
  static NI_889_Body = "NI_889_Body";
  static NI_890_Body = "NI_890_Body";
  static NI_891_Body = "NI_891_Body";
  static NI_892_Body = "NI_892_Body";
  static NI_893_Body = "NI_893_Body";
  static NI_893A_Body = "NI_893A_Body";
  static NI_894_Body = "NI_894_Body";
  static NI_895_Body = "NI_895_Body";
  static NI_896_Body = "NI_896_Body";
  static NI_897_Body = "NI_897_Body";
  static NI_898_Body = "NI_898_Body";
  static NI_899_Body = "NI_899_Body";
  static IE_038_Body = "IE_038_Body";
  static NI_916_Body = "NI_916_Body";
  static NI_900_Body = "NI_900_Body";
  static NI_901_Body = "NI_901_Body";
  static NI_902_Body = "NI_902_Body";
  static NI_937_Body = "NI_937_Body";
  static NI_938_Body = "NI_938_Body";
  static NI_931_Body = "NI_931_Body";
  static NI_931A_Body = "NI_931A_Body";
  static NI_932_Body = "NI_932_Body";
  static NI_932A_Body = "NI_932A_Body";
  static NI_933_Body = "NI_933_Body";
  static NI_933A_Body = "NI_933A_Body";
  static NI_934_Body = "NI_934_Body";
  static NI_934A_Body = "NI_934A_Body";
  static NI_935_Body = "NI_935_Body";
  static NI_935A_Body = "NI_935A_Body";
  static NI_936_Body = "NI_936_Body";
  static NI_928_Body = "NI_928_Body";
  static NI_928A_Body = "NI_928A_Body";
  static NI_928B_Body = "NI_928B_Body";
  static NI_929_Body = "NI_929_Body";
  static NI_929A_Body = "NI_929A_Body";
  static NI_929B_Body = "NI_929B_Body";
  static NI_930_Body = "NI_930_Body";
  static NI_930A_Body = "NI_930A_Body";
  static NI_930B_Body = "NI_930B_Body";
  static NI_942A_Body = "NI_942A_Body";
  static NI_942B_Body = "NI_942B_Body";
  static NI_942C_Body = "NI_942C_Body";
  static NI_942D_Body = "NI_942D_Body";
  static NI_942E_Body = "NI_942E_Body";

  //custom error
  static NI_944_Body = "NI_944_Body";
  static NI_945_Body = "NI_945_Body";
  static NI_946_Body = "NI_946_Body";
  static NI_947_Body = "NI_947_Body";
  static NI_946A_Body = "NI_946A_Body";

  //STD Eligibility
  static NI_953_Body = "NI_953_Body";
  static NI_953A_Body = "NI_953A_Body";

  static NI_950_Body = "NI_950_Body";
  static NI_951_Body = "NI_951_Body";
  static NI_951A_Body = "NI_951A_Body";
  static NI_951B_Body = "NI_951B_Body";
  static NI_951C_Body = "NI_951C_Body";
  static NI_8891_Body = "NI_8891_Body";
  static NI_8892_Body = "NI_8892_Body";
}
export class YES_NO_OBJECT {
  static YES = "1001";
  static NO = "1002";
  static yesNoObject = [
    {
      name: "Yes",
      value: YES_NO_OBJECT.YES,
    },
    {
      name: "No",
      value: YES_NO_OBJECT.NO,
    },
  ];
}

export class YES_NO_UNKNOWN_OBJECT {
  static YES = "2001";
  static NO = "2002";
  static UNKNOWN = "2003";
}

export class LookUp_JSON_CONSTANTS {
  static ANSWER = "answer";
  static QUESTION = "question";
  static FREEFORM = "freeform";
}

export interface IHukRadioYesNoOptions {
  name: string;
  value: string;
  checked: boolean;
}
export interface IHukCheckBoxChecked {
  name: string;
  value: boolean;
}
export interface IHukAccordian {
  weekNumber: number;
  days: IHukAccordianDays[];
  accordionState: boolean;
  accordionTotalHours: number;
  accordionTotalMinutes: number;
  accordionScheduledOTHours?: number;
  accordionScheduledOTMinutes?: number;
  accordionActualOTHours?: number;
  accordionActualOTMins?: number;
  accordionTotalMissedMinutes: number;
  showHoursAndMinutesLable: boolean;
  timeReportingLabel?: boolean;
  accordionTotalHoursAndMinutes: string;
  totalScheduledMinutes: number;
  accordionWorkScheduleMins?: number;
  accordionTotalWorkedHour?: number;
  accordionTotalWorkedMinute?: number;
  accordionTotalMissedHour?: number;
  accordionTotalMissedMinute?: number;
  accordionMissedHoursAndMinutes: string;
  scheduleMinutesArr: Array<number>;
}
export interface IHukAccordianDays {
  date: Date;
  isNonWorkDay?: boolean;
  isPartialWorkDay?: boolean;
  dateFormat?: IDayAndMonthFormat;
  weekNumber: number;
  isLessThanCurrentdate: boolean;
  scheduledMinutes: number;
  requestedMinutes: number;
  WorkHoursMins: string;
  index: number;
  showMandatoryQuesOnCheckBoxClick: boolean;
  selectedOption: null | string;
  showScheduledMandatoryOverTimeQues?: IHukShowMandatoryQues;
  nonWorkDayScheduledRadioOptions: IHukRadioYesNoOptions[];
  hourList: IHukDropDownOptions[];
  minuteList: IHukDropDownOptions[];
  resetDropdown?: boolean;
  requestedTimeOff?: boolean;
  hours?: number;
  minutes?: number;
  requestedTimeMissedHour?: number;
  requestedTimeMissedMinute?: number;
  requestedTimeWorkedHour?: number;
  requestedTimeWorkedMinute?: number;
  showTotalScheduledMins?: boolean;
  showHourLabel?: boolean;
  showMinuteLabel?: boolean;
  displayScheduledOTSection?: boolean;
  selectedRequestedTimeOffOption?: string;
  hideTimeWorkingQues?: boolean;
  alreadyRequestModalHours?: number;
  alreadyRequestModalMins?: number;
  alreadyRequestModalDynamicHoursLabel?: string;
  displayActualOTSection?: boolean;
  scheduledOTHoursFormControl?: string;
  scheduledOTMinsFormControl?: string;
  actualOTHoursFormControl?: string;
  actualOTMinsFormControl?: string;
  scheduledOTHours?: number;
  scheduledOTMinutes?: number;
  actualOTHours?: number;
  actualOTMins?: number;
  maxScheduleHours?: number;
  maxScheduleMinutes?: number;
  scheduledHoursList?: IMinutesHoursListObject[];
  scheduledMinsList?: IMinutesHoursListObject[];
  actualHoursList?: IMinutesHoursListObject[];
  actualMinsList?: IMinutesHoursListObject[];
  nonWorkdayHourList?: IHukDropDownOptions[];
  nonWorkdayMinuteList?: IHukDropDownOptions[];
  resetNonWorkdayDropdown?: boolean;
  isMaxRequestedTimeOff?: boolean;
  partialTimeRequestedTimeOff?: boolean;
  showMaxTimeOffErrorMsg?: boolean;
  previousValue?: string;
  previousValueAct?: string;
  isFullDay?: boolean;
  displayExistingOTSection?: boolean;
  existingOvertimeContent?: IExistingOvertimeContent;
  defaultHoursWorkedAndMissedToZero?: boolean;
}

export interface IHukAccordianWeekDays {
  weekNumber: number;
  formattedDate: string;
}

export interface IHukShowMandatoryQues {
  showMandatoryQues: boolean;
}
