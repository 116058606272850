import {catchError, map} from 'rxjs/operators';
import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {env} from '@env/environment-loader';
import {ResponseErrorHandlerService} from './response-error-handler.service';
import {StorageManagementService} from './storage-management.service';
import {AppLabelConstants} from '../constants/appLabel.constants';

@Injectable()
export class EventManagementServiceService {

  constructor(private httpClient: HttpClient,
              private errorHandler: ResponseErrorHandlerService,
              private sessionManagementService: StorageManagementService
  ) {}

  callEventManagementService(reqTypeValue, depositMethod, phoneNumber?) {
    const appData = JSON.parse(this.sessionManagementService.retrieveSessionObject('appData')).appData;
    const casesId = appData.userData.caseId;
    const srcSystemCdCall = (appData.userData.insuredId && appData.userData.insuredId.length > 0) ? '1' : '2';
    const getInsuredId = ((appData.userData.insuredId && appData.userData.insuredId.length > 0) ? appData.userData.insuredId
                      : appData.userData.eligibilityId);
    const header = {
      headers: new HttpHeaders()
        .set(AppLabelConstants.CONTENT_TYPE_KEY, AppLabelConstants.APPLICATION_KEY)
    };

    let requestParameter;

    if ( reqTypeValue === '99' && phoneNumber ) {
      const onlyDigitsNumber = phoneNumber.replace(/\D/g, '');
      requestParameter = {
        reqType: reqTypeValue,
        caseId: casesId,
        insuredId : getInsuredId,
        srcSystemCd : srcSystemCdCall,
        directDepositMethod : depositMethod,
        phoneNumber : onlyDigitsNumber
      }
    } else {
      requestParameter = {
        reqType: reqTypeValue,
        caseId: casesId,
        insuredId : getInsuredId,
        srcSystemCd : srcSystemCdCall,
        directDepositMethod : depositMethod,
      }
    }

      return this.httpClient.post(env.gbCxrEmails.mailEventServiceUrl,
        requestParameter, header).pipe(
      map((eventManagement) => {
        if (!this.errorHandler.isServiceResponseHasError(eventManagement)) {
          return eventManagement; // actual response
        } else {
          throw eventManagement;
        }
      }), catchError((error: any) => this.errorHandler.handleError(error)), );

  }

  triggerProdSupportEmail(auditTaskRequest) {
    //format request data for email
    const data = JSON.stringify(auditTaskRequest).replace(/{"/g, '\n\t\"')
      .replace(/,"/g, '\n\t\"')
      .replace(/}/g, '\n')
      .replace(/"/g, '')
      .replace(/:/g, '\t\t: ')
      .replace('TransactionSuccess\t\t:', 'TransactionSuccess\t:')
      .replace('Cvv2ResponseCode\t\t:', 'Cvv2ResponseCode\t:');
    //format request data for email
    const recipientEmailId = env.envName.toLowerCase() === AppLabelConstants.PROD_ENV.toLowerCase() ?
                             AppLabelConstants.RECIPIENT_EMAIL_ADDRESS_PROD : AppLabelConstants.RECIPIENT_EMAIL_ADDRESS_NON_PROD;
    const requestParameters = {
      sender:     AppLabelConstants.SENDER_EMAIL_ADDRESS,
      recipient:  recipientEmailId,
      subject:    AppLabelConstants.EMAIL_SUBJECT,
      body:       AppLabelConstants.EMAIL_BODY + data,
      personId:  auditTaskRequest.PersonId,
      claimNumber: auditTaskRequest.ClaimNumber
    };

    return this.httpClient.post(env.gbCxrEmails.triggerProdSupportEmail, requestParameters,
      {
        headers: new HttpHeaders().set(AppLabelConstants.CONTENT_TYPE_KEY, AppLabelConstants.APPLICATION_KEY)
      }).pipe(
          map((response: Response) => {
            if (!this.errorHandler.isServiceResponseHasError(response)) {
              return response;
            } else {
              throw response;
            }
          }), catchError((error: any) => this.errorHandler.handleError(error)),
      );
  }
}
