
import {catchError, map} from 'rxjs/operators';
import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {ResponseErrorHandlerService} from './response-error-handler.service';
import {env} from '../../../environments/environment-loader';
import {AppLabelConstants} from '../constants/appLabel.constants';

@Injectable()
export class LeaveFormLibraryService {

  constructor(private httpClient: HttpClient,
              private errorHandler: ResponseErrorHandlerService) {

  }

  /*Service Name: getLeaveFormService
 * Method name: getLeaveFormService()
 * Purpose: Bellow method is used to call service call data from mock or main services as per conditions
 * Created Date: 22-02-2018
 *
 * */

  getLeaveFormService() {
    const header = {
      headers: new HttpHeaders()
        .set(AppLabelConstants.CONTENT_TYPE_KEY, AppLabelConstants.APPLICATION_KEY)
    }

      return this.httpClient.get(env.gbContent.leaveLibraryurl, header).pipe(map((leaveLibraryresponase) => {
        if (!this.errorHandler.isServiceResponseHasError(leaveLibraryresponase)) { // Error Handling Service
          return leaveLibraryresponase; // actual response
        } else {
          throw leaveLibraryresponase;
        }
      }), catchError((error: any) => this.errorHandler.handleError(error)), );

  }
}
