import {Directive, Input} from '@angular/core';
import {AbstractControl, NG_VALIDATORS, Validator, ValidatorFn} from '@angular/forms';

@Directive({
  selector: '[validateMinLength]',
  providers: [{provide: NG_VALIDATORS, useExisting: ValidateMinLength, multi: true}]
})
export class ValidateMinLength implements Validator {
  @Input() minReqLength: number;
  private pattern = new RegExp('^[0-9]{10}$');
  validate(control: AbstractControl) {
    if ( control.value && control.dirty &&
      ( control.value.length < this.minReqLength || !this.pattern.test(control.value) )
    ) {
      //console.log("error in min length");
      return {validateMinLength: true};
    }
    return null;
  }


  /* Added as an example for using this with reactive forms */
  validateByValue(value: number): ValidatorFn {

    return (control: AbstractControl): {[key: string]: any} => {
      if ( control.value && control.dirty &&
        ( control.value.length < value || !this.pattern.test(control.value) )
      ) {
        //console.log("error in min length");
        return {validateMinLength: true};
      }
      return null;
    }
  }
}
