
import {catchError, map} from 'rxjs/operators';
import {Injectable} from '@angular/core';
import {BankDetails} from '../models/bankDetails.model';


import {Observable} from 'rxjs';
import {env} from '../../../environments/environment-loader';


import {ResponseErrorHandlerService} from '../services/response-error-handler.service';
import {ProvisionResponse} from '../models/Provision.model';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {StorageManagementService} from './storage-management.service';
import {ClaimantPaymentInfo} from '../models/Payment.model';
import {SessionConstants} from '../constants/session.constants';
import {AppLabelConstants} from '../constants/appLabel.constants';


@Injectable()
export class DepositService {

  bank: BankDetails;
  bankReference: BankDetails[];
  isUserBackToEnrollDeposit: boolean = false;

  constructor(private errorHandler: ResponseErrorHandlerService,
              private sessionManagementService: StorageManagementService,
              private httpClient: HttpClient) {
  }

  getIsUserBackToEnrollDeposit(): boolean {
    return this.isUserBackToEnrollDeposit;
  }

  setIsUserBackToEnrollDeposit(isUserBackToEnrollDeposit: boolean) {
    this.isUserBackToEnrollDeposit = isUserBackToEnrollDeposit;
  }



  /***********************
   * Date : 09/Oct/17
   * Author :Ac12296
   * Purpose : To delete banking information when user with 'insuredId' chooses 'Mail my payments instead'
   * Modified By: _
   * Modified date : _
   * Modification Purpose: _
   */
  deleteBankingInformation(insuredID: string): Observable<any> {
      const request = {insuredId: insuredID};
      return this.httpClient.post(env.gbClaims.deleteBankingInfoURL, request,
        {
          headers: new HttpHeaders()
            .set(AppLabelConstants.CONTENT_TYPE_KEY, AppLabelConstants.APPLICATION_KEY)
        }).pipe(
        map(
        (response: Response) => {
          if (!this.errorHandler.isServiceResponseHasError(response)) {
            return response;
          } else {
            throw response;
          }
        }
      ), catchError((error: any) => this.errorHandler.handleError(error)), );
  }

  /***********************
   * Date : _
   * Author : Darshana Saha
   * Purpose : _
   * Modified By: _
   * Modified date : _
   * Modification Purpose: _
   */
  AddBankingInformation(personIDs, insuredID, externalAbaNum, externalAccountNum, externalAccountNameValue, externalAccountTypeValue, authorizedUsersValue, paystubPreference): Observable<any> {
    const fullyInsuredClaimEventIds: string [] = this.getFullyInsuredClaimEventIds();
    const request: string = JSON.stringify(
        {
          personIds: personIDs,
          insuredId: insuredID,
          externalAbaNumber: externalAbaNum,
          externalAccountNumber: externalAccountNum,
          externalAccountName: externalAccountNameValue,
          externalAccountType: externalAccountTypeValue,
          authorizedUsers: authorizedUsersValue,
          claimEventIds: fullyInsuredClaimEventIds ? fullyInsuredClaimEventIds : [],
          suppressNotifyChecked : paystubPreference
        }
      );
      return this.httpClient.post(env.gbORCHService.directDepositURL, request,
        {
          headers: new HttpHeaders()
            .set(AppLabelConstants.CONTENT_TYPE_KEY, AppLabelConstants.APPLICATION_KEY)
        }).pipe(
        map(
        (response: Response) => {
          if (!this.errorHandler.isServiceResponseHasError(response)) {
            return response;
          } else {
            throw response;
          }
        }
      ), catchError((error: any) => this.errorHandler.handleErrorForSubmit(error)), );
  }

  /***********************
   * Date : _
   * Author :Prital Lole
   * Purpose : _
   * Modified By: Chaitanya
   * Modified date : 17/10/2018
   * Modification Purpose: Added a new model in Observable GetBankingResponse
   */


  getBankDatailService(insuredId: string, personIds: string[]): Observable<BankDetails> {
      //console.log('getting user details');
      let personIDs = '';
      let insuredID = '';

      if (insuredId) {
        insuredID = '&insuredId=' + insuredId;
      }

      if (personIds && personIds.length > 0) {
        personIds.forEach((personId: string) => {
          personIDs = personIDs + '&personId=' + personId;
        });
      }

      return this.httpClient.get(env.gbORCHService.directDepositURL +  insuredID + personIDs ,
        {
          headers: new HttpHeaders()
            .set(AppLabelConstants.CONTENT_TYPE_KEY, AppLabelConstants.APPLICATION_KEY)
        }).pipe(
        map(
        (response: BankDetails) => {
          if (!this.errorHandler.isServiceResponseHasError(response)) {
            return response;
          } else {
            throw response;
          }
        }
      ), catchError((error: any) => this.errorHandler.handleError(error)), );

  }

  /***********************
   * Date : _
   * Author : Prital Lole
   * Purpose : _
   * Modified By: _
   * Modified date : _
   * Modification Purpose: _
   */
  getModifyBankingInformation(personIDs, insuredID, externalAbaNum, externalAccountNum, externalAccountNameValue, externalAccountTypeValue, authorizedUsersValue, suppressNotifyCheckedValue): Observable<any> {
    const fullyInsuredClaimEventIds: string [] = this.getFullyInsuredClaimEventIds();
    const request: string = JSON.stringify(
        {
          personIds: personIDs,
          insuredId: insuredID,
          externalAbaNumber: externalAbaNum,
          externalAccountNumber: externalAccountNum,
          externalAccountName: externalAccountNameValue,
          externalAccountType: externalAccountTypeValue,
          authorizedUsers: authorizedUsersValue,
          claimEventIds : fullyInsuredClaimEventIds ? fullyInsuredClaimEventIds : [],
          suppressNotifyChecked: suppressNotifyCheckedValue
        });
      return this.httpClient.post(env.gbORCHService.directDepositURL , request,
        {
          headers: new HttpHeaders()
            .set(AppLabelConstants.CONTENT_TYPE_KEY, AppLabelConstants.APPLICATION_KEY)
        }).pipe(
        map(
        (response: Response) => {
          if (!this.errorHandler.isServiceResponseHasError(response)) {
            return response;
          } else {
            throw response;
          }
        }
      ), catchError((error: any) => this.errorHandler.handleErrorForSubmit(error)), );
  }

  getBankValidation(routingno): Observable<any> {
      const request: string = JSON.stringify(
        {
          userId: 'null',
          sessionIdentifier: 'null',
          bankAbaNumber: routingno
        }
      );

      return this.httpClient.post(env.gbBankValidation.getValidateBankAbaURL, request,
        {
          headers: new HttpHeaders()
            .set(AppLabelConstants.CONTENT_TYPE_KEY, AppLabelConstants.APPLICATION_KEY)
        }).pipe(
        map(
        (response: Response) => {
          if (!this.errorHandler.isServiceResponseHasError(response)) {
            return response;
          } else {
            throw response;
          }
        }
      ), catchError((error: any) => this.errorHandler.handleError(error)), );

  }

  getPolicyAdminProvisions(caseID: number, provisionId: number): Observable<ProvisionResponse> {

      return this.httpClient
        .get<ProvisionResponse>(env.gbPolicy.getPolicyAdminProvisionsURLFirst + caseID.toString() + env.gbPolicy.getPolicyAdminProvisionsURLLSecond + provisionId.toString(),
          {
            headers: new HttpHeaders()
              .set(AppLabelConstants.CONTENT_TYPE_KEY, AppLabelConstants.APPLICATION_KEY)
          }).pipe(
        map(
          (provisionResponse: ProvisionResponse) => {
            if (!this.errorHandler.isServiceResponseHasError(provisionResponse)) {
              //console.log("From Service:: Requested data available");
              return provisionResponse;
            } else {
              // console.log("From Service:: Service returned error with 200");
              throw provisionResponse;
            }
          }
        ), catchError((error: any) =>
          //console.log("Inside Catch Handler");
           this.errorHandler.handleError(error)
        ), );

  }
  getPolicyAdminOptions(caseID: number, provisionId: number): Observable<ProvisionResponse> {

    return this.httpClient
      .get<ProvisionResponse>(env.gbPolicy.getPolicyAdminOptionsURLFirst + caseID.toString() + env.gbPolicy.getPolicyAdminOptionsURLLSecond + provisionId.toString(),
        {
          headers: new HttpHeaders()
            .set(AppLabelConstants.CONTENT_TYPE_KEY, AppLabelConstants.APPLICATION_KEY)
        }).pipe(
      map(
        (provisionResponse: ProvisionResponse) => {
          if (!this.errorHandler.isServiceResponseHasError(provisionResponse)) {
            //console.log("From Service:: Requested data available");
            return provisionResponse;
          } else {
            // console.log("From Service:: Service returned error with 200");
            throw provisionResponse;
          }
        }
      ), catchError((error: any) =>
        //console.log("Inside Catch Handler");
         this.errorHandler.handleError(error)
      ), );

}

  getEftPref() {
    return this.httpClient.get(env.gbTHAWDBService.getEftPrefUrl,
    {
        headers: new HttpHeaders()
          .set(AppLabelConstants.CONTENT_TYPE_KEY, AppLabelConstants.APPLICATION_KEY)
      }).pipe(
      map(
      (response: Response) => {
        if (!this.errorHandler.isServiceResponseHasError(response)) {
          return response;
        } else {
          throw response;
        }
      }
    ), catchError((error: any) => this.errorHandler.handleError(error)), );
  }

  updateEftPref() {
      const request: string = JSON.stringify(
        {
          preference: 'enroll_direct_deposit'
        }
      );
      return this.httpClient.post(env.gbTHAWDBService.getEftPrefUrl, request,
        {
          headers: new HttpHeaders()
            .set(AppLabelConstants.CONTENT_TYPE_KEY, AppLabelConstants.APPLICATION_KEY)
        }).pipe(
        map(
        (response: Response) => {
          if (!this.errorHandler.isServiceResponseHasError(response)) {
            return response;
          } else {
            throw response;
          }
        }
      ), catchError((error: any) => this.errorHandler.handleError(error)), );

  }

  clearEnrollDepositRelatedData() {
    this.sessionManagementService.removeAttrFromSessionData(SessionConstants.ROUTING_NO);
    this.sessionManagementService.removeAttrFromSessionData(SessionConstants.BANK_NAME);
    this.sessionManagementService.removeAttrFromSessionData(SessionConstants.ACCT_NO);
    this.sessionManagementService.removeAttrFromSessionData(SessionConstants.ACCT_TYPE);
    this.sessionManagementService.removeAttrFromSessionData(SessionConstants.CHECKED_NAME);
    this.sessionManagementService.removeAttrFromSessionData(SessionConstants.PAYSTUB);
  }

  getClaimantPaymentInfo(): ClaimantPaymentInfo {
    const claimantPaymentInfoVar: ClaimantPaymentInfo = this.sessionManagementService.getSessionData(SessionConstants.CLAIMANT_PAYMENT_INFO_LG) ?
      this.sessionManagementService.getSessionData(SessionConstants.CLAIMANT_PAYMENT_INFO_LG) : null;

    return claimantPaymentInfoVar;
  }

  public getFullyInsuredClaimEventIds(): string[] {
    let claimantPaymentInfo: ClaimantPaymentInfo = this.getClaimantPaymentInfo();
    if (claimantPaymentInfo !== null) {
      claimantPaymentInfo = new ClaimantPaymentInfo(claimantPaymentInfo.claimsPaymentInfo);
      return claimantPaymentInfo ? claimantPaymentInfo.getFullyInsuredClaimEventIds() : [];
    }
    return [];
  }

}


