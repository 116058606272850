/**
 * Created by AC12212 on 1/13/2018.
 */

import {Directive} from '@angular/core';
import {AbstractControl, NG_VALIDATORS, Validator} from '@angular/forms';

@Directive({
  selector: '[ValidateTwoDigitsRequired]',
  providers: [{provide: NG_VALIDATORS, useExisting: ValidateTwoDigitsRequiredPattern, multi: true}]
})
export class ValidateTwoDigitsRequiredPattern implements Validator {
  validate(control: AbstractControl) {
    if (control.value) {
      if (control.value.length > 2) {
        return {ValidateTwoDigitsRequired: true};
      } else if (control.value === '00' || control.value === '0') {
        return {validateZeroErrMsg: true};
      }
    }
    return null;
  }
}
