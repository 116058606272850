import {AfterViewInit, Component, OnInit} from '@angular/core';
import {Router} from '@angular/router';
import {ContentService} from '../../../../shared/services/content.service';
import {StorageManagementService} from '../../../../shared/services/storage-management.service';
import {LeaveDynatraceAction} from '../../../../shared/utils/leaveDynatraceAction';
import {AppConstants} from '../../../../shared/constants/app.constants';
import {AppLabelConstants} from '../../../../shared/constants/appLabel.constants';
import {SessionConstants} from '../../../../shared/constants/session.constants';
import { SelfLinkingService } from 'app/shared/services/self-linking.service';

@Component({
  selector: 'app-successful-registration',
  templateUrl: './successful-registration.component.html',
  styleUrls: ['./successful-registration.component.scss']
})
export class SuccessfulRegistrationComponent extends LeaveDynatraceAction implements OnInit, AfterViewInit {
  public appConstant = AppConstants;
  public appLabelConstant = AppLabelConstants;
  public pageName: string;
  public path;
  private pageId;
  private pageId1;
  private owcsContent;
  public sub_title = '';
  public  paragraph_1 = '';
  private pulseID: string;
  private eligibilityId: number;
  public personId = '';
  constructor(private router: Router,
              private contentService: ContentService,
              private storageManagementService: StorageManagementService,
              private selfLinkingService: SelfLinkingService) {
    super(storageManagementService);
  }

  ngOnInit() {
    this.pulseID = this.storageManagementService.getSessionData(SessionConstants.PULSE_ID);
    this.eligibilityId = this.storageManagementService.getSessionData(SessionConstants.ELIGIBILITY_ID);
    this.personId = this.selfLinkingService.personId;
    //OWCS Integration
    this.pageId = this.contentService.getPageId(AppConstants.CONFIRM_INSURED_PAGE_ID);
    if (this.pageId) {
      this.populateContent(this.pageId);
    } else {
      this.contentService.getSiteMap().subscribe(
        val => {
          if (val) {
            const siteMapResponse = val;
            this.storageManagementService.setSessionData(SessionConstants.SITE_MAP_DATA, siteMapResponse);
            this.pageId1 = this.contentService.getPageId(AppConstants.CONFIRM_INSURED_PAGE_ID);
            this.populateContent(this.pageId1);
          }
        }, (error) => {
        });
    }

    if (this.pulseID) {
      this.pageName = AppLabelConstants.UNDERWRITING_TITLE;
    } else if (this.eligibilityId) {
      this.pageName = AppLabelConstants.RECORD_TITLE;
    } else {
      this.pageName = AppLabelConstants.INSURED_TITLE;
    }
  }
  ngAfterViewInit() {
    super.ngAfterViewInit();
  }
  onLogout() {
    this.router.navigate(['/']);
  }

  populateContent(pageId) {
    this.contentService.getOWCSResponse(pageId, AppConstants.CONFIRM_INSURED_OWCS).subscribe(
      OWCSResponse => {
        if (OWCSResponse) {
          this.owcsContent = OWCSResponse;
          if (this.owcsContent.data.Confirm_Insured_ID_13_Body) {
            this.sub_title = this.owcsContent.data.Confirm_Insured_ID_13_Body.value;
          }
          if (this.owcsContent.data.Confirm_Insured_ID_14_Body) {
            this.paragraph_1 = this.owcsContent.data.Confirm_Insured_ID_14_Body.value;
          }
        }
      }, (error) => {
        //console.error(error);
      });
  }
}
