
import {catchError, map} from 'rxjs/operators';
import {Injectable} from '@angular/core';

import {UpdateProfileRequest} from '../models/updateProfile.model';
import {Observable} from 'rxjs';
import {env} from '../../../environments/environment-loader';
import {ResponseErrorHandlerService} from './response-error-handler.service';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {AppLabelConstants} from '../constants/appLabel.constants';

@Injectable()
export class SaveProfiledataService {

  constructor(
              private errorHandler: ResponseErrorHandlerService,
              private httpClient: HttpClient) { }

  updateProfile(req: UpdateProfileRequest): Observable<boolean> {
    const request: string = JSON.stringify(req);

      return this.httpClient.post(env.gbORCHService.updateProfileServiceURL, request,
        {
          headers: new HttpHeaders()
            .set(AppLabelConstants.CONTENT_TYPE_KEY, AppLabelConstants.APPLICATION_KEY)
        }).pipe(
        map(
        (response: boolean) => {
          if (!this.errorHandler.isServiceResponseHasError(response)) {
            return response;
          } else {
            throw response;
          }
        }
      ), catchError((error: any) => this.errorHandler.globalErrorHandler(error)), );

  }


  /***********************
   * Date : 10/23/17
   * Auther :Ac12212
   * Purpose :To Update Emailaddr in MUDI service
   */
  updateEmailAddr(partyrefId, email) {

    const request: string = JSON.stringify({
      partyRefId: partyrefId,
      emailAddress: email,
    });

    return this.httpClient.post(env.gbMudi.UpdateEmailAddrURL, request,
      {
        headers: new HttpHeaders()
          .set(AppLabelConstants.CONTENT_TYPE_KEY, AppLabelConstants.APPLICATION_KEY)
      }).pipe(
      map(
      (response: Response) => {
        if (!this.errorHandler.isServiceResponseHasError(response)) {
          return response;
        } else {
          throw response;
        }
      }
    ), catchError((error: any) => this.errorHandler.handleErrorForSubmit(error)), );

  }

}
