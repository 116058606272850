/**
 * Created by AC12256 on 2/12/2018.
 */

import {MockRecentlyInfo} from './mock-recentlyInfoReq';

export const mockInfoResolved = [
  {
    mInfoCode: '01',
    mInfoDesc: 'Employee',
    mInfoReqInfo: [MockRecentlyInfo[0], MockRecentlyInfo[1]]
  },
  {
    mInfoCode: '02',
    mInfoDesc: 'Employer',
    mInfoReqInfo: [MockRecentlyInfo[1], MockRecentlyInfo[0]]
  },
  {
    mInfoCode: '03',
    mInfoDesc: 'Physician',
    mInfoReqInfo: [MockRecentlyInfo[1], MockRecentlyInfo[0]]
  }];
