/**
 * Created by AC12256 on 2/10/2018.
 */

import {PROVISIONLEAVE} from './mock-provisionLeave';

export const POLICYLEAVE = {
  errorList: null,
  leaveResponseList: [PROVISIONLEAVE[0]]
};
