import {AfterViewInit, Component, OnInit} from '@angular/core';
import {TermsAndConditions} from '../../shared/services/termsAndConditions.service';
import {LeaveDynatraceAction} from '../../shared/utils/leaveDynatraceAction';
import {StorageManagementService} from '../../shared/services/storage-management.service';
import {AppLabelConstants} from '../../shared/constants/appLabel.constants';

@Component({
  selector: 'app-terms-cond-popup',
  templateUrl: './terms-cond-popup.component.html',
  styleUrls: ['./terms-cond-popup.component.scss'],
  providers: [TermsAndConditions]
})
export class TermsCondPopupComponent extends LeaveDynatraceAction implements OnInit, AfterViewInit {

  pageLabel = AppLabelConstants.TnC;
  close = AppLabelConstants.CLOSE;
  paras: string[] = [];


  constructor(private termsAndConditionsService: TermsAndConditions,
              private storageManagementService: StorageManagementService) {
    super(storageManagementService);
    //this.paras = termsAndConditionsService.getContent().paras; //This is fake data
  }

  ngOnInit() {
  }
  ngAfterViewInit() {
    super.ngAfterViewInit();
  }
  clickClose() {
    window.close();
  }

}
