export class RaeSessionConstants {
  /** Interactive Request Extension */
  static STEP_ONE = 'stepOne';
  static STEP_TWO = 'stepTwo';
  static GETTING_STARTED_CONTENT = 'gettingStartedData';
  static RADIO_BUTTON_EXACT_DATA = 'radioButtonExactDate';
  static RADIO_BUTTON_NO_BTW = 'radioButtonNoBTW';
  static WEEKS_OR_DAYS = 'weeksOrDays';
  static BACK_TO_WORK = 'backToWork';
  static RAE_SESSION_DATA = 'raeSessionData';
  static STEP_ONE_JSON_CLOB = 'stepOneJSONClob';
  static STEP_TWO_JSON_CLOB = 'stepTwoJSONClob';
  static STEP_THREE_JSON_CLOB = 'stepThreeJSONClob';
  static STEP_FOUR_JSON_CLOB = 'stepFourJSONClob';
  static STEP_FIVE_JSON_CLOB = 'stepFiveJSONClob';
  static SPECIALTY_LIST = 'specialtyList';
  static STATE_LIST = 'stateList';
  static URL_IRE_HEALTH_CARE_PROVIDER = '/ire/rae/health-care-provider';
  static ADD_PROVIDER_BOOLEAN = 'addProviderBoolean';
  static ADDITIONAL_PROVIDER_DETAILS = 'additionalProviderDetails';
  static GETTING_STARTED_SAVED_DATA = 'gettingStartedSavedData';
  static DIAGNOSIS_SAVED_DATA = 'diagnosisSavedData';
  static YOUR_PROGRESS_BACKTOWORK_SAVED_DATA = 'yourProgressBackToWorkSavedData';
  static PHYSICAL_ABILITIES_SAVED_DATA = 'physicalAbilitiesSavedData';
  static PROGESS_BAR = 'progressBar';
  static RAE_DIAGNOSIS_REASON_FLOWS = 'raeDiagnosisReasonFlows';
}
