
import {catchError, map} from 'rxjs/operators';
import {Injectable} from '@angular/core';

import {Observable, BehaviorSubject} from 'rxjs';



import {env} from '../../../environments/environment-loader';
import {ResponseErrorHandlerService} from './response-error-handler.service';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {AppLabelConstants} from '../constants/appLabel.constants';


@Injectable()
export class ConfirmSurgeryServiceService {
  multipleBirthSelected = new BehaviorSubject(false);
  constructor(private errorHandler: ResponseErrorHandlerService,
              private httpClient: HttpClient) { }

  upDateClaimActivity(request): Observable<any> {
    return this.httpClient.post(env.gbClaims.updateClaimActivityURL, request,
      {
        headers: new HttpHeaders()
          .set(AppLabelConstants.CONTENT_TYPE_KEY, AppLabelConstants.APPLICATION_KEY)
      }).pipe(
      map(
      (response: Response) => {
        if (!this.errorHandler.isServiceResponseHasError(response)) {
          return response;
        } else {
          throw response;
        }
      }
    ), catchError((error: any) => this.errorHandler.handleErrorForSubmit(error)), );
    }

}

