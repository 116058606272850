import {Component, EventEmitter, OnInit, Output} from '@angular/core';
import {ModalService} from '../../shared/services/modal.service';
import {MessageRibbonService} from '../../shared/services/message-ribbon.service';
import {AppConstants} from '../../shared/constants/app.constants';
import {SessionConstants} from '../../shared/constants/session.constants';
import {AppLabelConstants} from '../../shared/constants/appLabel.constants';
import {ErrorConstants} from '../../shared/constants/error.constants';
import { StorageManagementService } from '@app/shared/services/storage-management.service';


@Component({
  selector: 'app-popup-comm-pref-with-mobile',
  templateUrl: './popup-comm-pref-with-mobile.component.html',
  styleUrls: ['./popup-comm-pref-with-mobile.component.scss'],
})
export class PopupCommPrefWithMobileComponent implements OnInit {
  public appLabelConstants = AppLabelConstants;
  private mobile;
  private email;
  private updateProfileRequest: any;
  private updateProfileResponse: any;
  private profileData: any = {};
  @Output() private ifChangeDone = new EventEmitter<boolean>();
  @Output()  termsNotAgreed = false;
  @Output()  userDecision  = new EventEmitter<boolean>();

  constructor(private modalService: ModalService,
              private messageRibbon: MessageRibbonService,
              private storageManagementService: StorageManagementService) {
  }

  ngOnInit() {
    if ((sessionStorage.getItem(SessionConstants.TERMS_NOT_AGREED)) === AppConstants.TRUE) {
      this.termsNotAgreed = true;
    } else {
      this.termsNotAgreed = false;
    }

    this.mobile = this.storageManagementService.getAPPSessionData(SessionConstants.MOBILE_NO);
    this.email = this.storageManagementService.getAPPSessionData(SessionConstants.EMAIL);
  }

  clickClose() {
    this.userDecision.emit(false);
    this.modalService.closeModal(AppLabelConstants.SAVE_MODAL);
    this.modalService.closeModal(AppLabelConstants.SAVE_NO_MOB_MODAL);
    this.termsNotAgreed = false;
    const termsNotAgreed: string = this.termsNotAgreed.toString();
    sessionStorage.setItem(SessionConstants.TERMS_NOT_AGREED, termsNotAgreed);
  }

  onclickterms() {
    window.open(AppConstants.TEXT_TCS);
  }

  saveChanges() {
    this.userDecision.emit(true);
    this.modalService.closeModal(AppLabelConstants.SAVE_MODAL);
    this.modalService.closeModal(AppLabelConstants.SAVE_NO_MOB_MODAL);
    this.termsNotAgreed = false;
    const termsNotAgreed: string = this.termsNotAgreed.toString();
    sessionStorage.setItem(SessionConstants.TERMS_NOT_AGREED, termsNotAgreed);
  }

  toggleTerms() {
    this.termsNotAgreed = !this.termsNotAgreed;
  }

  showRibbonSuccess() {
    this.messageRibbon.addMessage(AppLabelConstants.SUCCESS_SM, ErrorConstants.UR_CHANGES_HAVE_BEEN_SAVED, null, AppConstants.TRUE);
  }
}
