import { Component, EventEmitter, HostListener, Input, OnInit, Output } from '@angular/core';
import { Location } from '@angular/common';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { ReferenceHelperService } from '../../../../shared/services/reference-helper.service';
import { ModalService } from '../../../../shared/services/modal.service';
import { AppLabelConstants } from '../../../../shared/constants/appLabel.constants';
import { AppLeaveConstants } from '../../../leave-details-flow/leave-details.constants';
import { AppLeaveIntakeConstants } from '../../../leave-claim-intake-flow/leave-intake.constants';
import { StorageManagementService } from '../../../../shared/services/storage-management.service';
import { SessionConstants } from '../../../../shared/constants/session.constants';
import { AppConstants } from '../../../../shared/constants/app.constants';
import { RelatedClaims } from '@shared/models/relatedClaims.model';
import { RAE_ROUTES } from '@app/cxr/modules/interactive-request-extension/shared/constants/rae-routes.constants';
import { AppScheduleCallConstants } from '@app/cxr/schedule-appointment/schedule-call.constants';
import { TaxFormConstants } from '@app/cxr/tax-form/tax-form.constants';
import { CustomEventService } from '@app/shared/services/tealium/custom-event.service';
import { OverpaymentLabelsConstants } from '@app/cxr/overpayment/shared/constants/overpayment-labels.constants';
import { INTAKE_ROUTES } from '@app/cxr/modules/intake/shared/constants/Intake-routing.constants';
import { BondingIntakeSessionConstants, IntakeSessionConstants } from '@app/cxr/modules/intake/shared/constants/intakeSession.constants';
import { INTAKE_PROGRESS_BAR_STEP_ID } from '@app/cxr/modules/intake/shared/constants/intake-progress-bar-constants';


@Component({
  selector: 'heading-navigation',
  templateUrl: 'heading-navigation.component.html',
  styleUrls: ['heading-navigation.component.scss'],
})
export class HeadingNavigationComponent implements OnInit {

  @Input() pageTitle: string;
  @Input() routeLocation: string;
  @Input() pageSubTitle: string;
  @Input() disableBackButton: boolean = false; /** Pass true to this Input() parameter to hide Back Button*/
  @Input() set ireCurrentRoute(data) {
    if (data) {
      this.currentRoute = data;
    }
  }
  onPaymentPage: boolean = false;
  analyticsLabel: string = '';
  bondingSessionConst = BondingIntakeSessionConstants;
  public taxFormConstants = TaxFormConstants;
  public isForcedCaseSelectPage = false;
  public navigationStack: RelatedClaims[] = [];
  public currentRoute;
  public appLabelConstants = AppLabelConstants;
  public appLeaveConstants = AppLeaveConstants;
  public appIntakeConstants = AppLeaveIntakeConstants;
  private subscription: Subscription;
  maternityLOA: boolean;
  flightCrew: boolean;
 @Input() isDisabled: boolean = false;
  constructor(private location: Location,
    private referenceHelper: ReferenceHelperService,
    private router: Router,
    private modalService: ModalService,
    private storageManagementService: StorageManagementService,
    private customEventService: CustomEventService
  ) {
    this.subscription = this.referenceHelper.reference.subscribe
      (val => {
        this.isForcedCaseSelectPage = (val.get('isForcedEmployer') === 'true');
      });
    this.currentRoute = router.url;
  }

  ngOnInit() {
    this.maternityLOA=this.storageManagementService.getSessionData(SessionConstants.IS_LOA_PRODUCT_ACTIVE);
    this.onPaymentPage = (this.router.url === AppLabelConstants.MAKE_PAYMENT) || (this.router.url === AppLabelConstants.SELECT_PAYMENT_AMOUNT) || (this.router.url === AppLabelConstants.MORE_LETTERS_LOCATION) || (this.router.url === AppLabelConstants.PAYMENTS_YOU_OWE) || (this.router.url === AppLabelConstants.ALL_PAYMENTS_LINK) ? true : false;
    if ((this.router.url === AppLabelConstants.SELECT_PAYMENT_AMOUNT) || (this.router.url === AppLabelConstants.MAKE_PAYMENT) || (this.router.url === AppLabelConstants.PAYMENTS_YOU_OWE) || (this.router.url === AppLabelConstants.SELECT_PAYMENT_AMOUNT)) {
      this.analyticsLabel = AppLabelConstants.PAYMENT_YOU_OWE_LABEL;
    } else if (this.router.url === AppLabelConstants.ALL_PAYMENTS_LINK) {
      this.analyticsLabel = AppLabelConstants.PAYMENTS;
    } else if (this.router.url === AppLabelConstants.MORE_LETTERS_LOCATION) {
      this.analyticsLabel = AppLabelConstants.LETTERS_LABEL;
    }
  }

  checkBackButton(): void{
    if(window.location.toString().indexOf("intake")!==-1){
      this.customEventService.trackWatchNowButton( AppLabelConstants.BACK, AppLabelConstants.SITE_BACK);
    }
  }

  addAnalyticsEvent() {
    if (this.onPaymentPage) {
      this.customEventService.customEvent(OverpaymentLabelsConstants.ANALYTICS_BACK, OverpaymentLabelsConstants.ANALYTICS_PAGE_TAG, OverpaymentLabelsConstants.ANALYTICS_BUTTON_CLICK, OverpaymentLabelsConstants.ANALYTICS_MODEL_CLOSE, this.analyticsLabel);
    }
    this.router.navigate([this.routeLocation])
  }
  /* Else condition covers display of Modal in request Review Page */
  goToBackPage() {
    if (this.onPaymentPage) {
      this.customEventService.customEvent(OverpaymentLabelsConstants.ANALYTICS_BACK, OverpaymentLabelsConstants.ANALYTICS_PAGE_TAG, OverpaymentLabelsConstants.ANALYTICS_BUTTON_CLICK, OverpaymentLabelsConstants.ANALYTICS_MODEL_CLOSE, this.analyticsLabel);
    }

    if (this.currentRoute.includes(AppLeaveConstants.URL_REQUEST_EXTENSION_LEAVE) || this.currentRoute.includes(AppConstants.URL_REQUEST_EXTN_THAA) || this.currentRoute.includes(AppConstants.URL_CLAIM_RTW) || this.currentRoute === AppLabelConstants.REQUEST_REVIEW_URL || this.currentRoute.includes(AppLabelConstants.REQUEST_TIME_OFF) || this.currentRoute.includes(this.currentRoute.includes(AppLeaveConstants.URL_REQUEST_EXTENSION_LEAVE)) || this.currentRoute.includes(AppLeaveConstants.URL_LEAVE_RTW) || this.currentRoute.includes(AppLeaveConstants.URL_ADA_UPDATE_ACCOMMODATION)) {
      this.storageManagementService.removeAttrFromSessionData(AppLabelConstants.SHOW_INTAKE);
      this.modalService.openModal(AppLabelConstants.CANCEL_MODAL);
      sessionStorage.removeItem('newDisabilityDate');
    } else if (this.router.url === AppLabelConstants.COMMUNICATION_PREFERENCE) {
      this.modalService.openModal(AppLabelConstants.CANCEL_MODAL);
    } else if (this.currentRoute.includes(AppLabelConstants.LEAVE_SPECIFIC_QUE_URL) || this.currentRoute.includes(AppLabelConstants.REGULAR_WORK_SCHEDULE_EDIT) || this.currentRoute.includes(AppLeaveIntakeConstants.ADA_INTAKE_FLOW)) {
      this.modalService.openModal(this.appIntakeConstants.CANCEL_MODAL_INTAK);
    } else if ((this.currentRoute.includes(RAE_ROUTES.GETTING_STARTED_STEP))) {
      this.router.navigate([RAE_ROUTES.RAE_FLOW_THINGS])
    } else if ((this.currentRoute.includes(RAE_ROUTES.DIAGNOSIS))) {
      this.router.navigate([RAE_ROUTES.GETTING_STARTED_STEP])
    } else if ((this.currentRoute.includes(RAE_ROUTES.YOUR_PROGRESS))) {
      this.router.navigate([RAE_ROUTES.DIAGNOSIS])
    } else if ((this.currentRoute.includes(RAE_ROUTES.PHYSICAL_ABILITIES))) {
      this.router.navigate([RAE_ROUTES.YOUR_PROGRESS])
    } else if ((this.currentRoute.includes(RAE_ROUTES.HEALTH_CARE_PROVIDER_STEP))) {
      this.router.navigate([RAE_ROUTES.PHYSICAL_ABILITIES])
    } else if (this.currentRoute === RAE_ROUTES.RAE_FLOW_THINGS) {
      this.router.navigate([AppScheduleCallConstants.CLAIM_DETAILS_PAGE])
    } else if (this.currentRoute === this.taxFormConstants.TAX_FORMS) {
      this.storageManagementService.setSessionData(this.taxFormConstants.FROM_TAX_FORM, false);
      this.router.navigate([AppLabelConstants.URL_HOME])
    } else if (this.router.url === INTAKE_ROUTES.ABOUT_YOUR_ABSENCE || (this.router.url === INTAKE_ROUTES.ABOUT_YOUR_ABSENCE_LOA)) {
      if (this.maternityLOA) {
        const flightCrew = this.storageManagementService.getIntakeSessionData(BondingIntakeSessionConstants.FLIGHT_CREW);
        if (flightCrew) {
          this.router.navigate([INTAKE_ROUTES.HEALTHCARE_PROVIDER_LOA]);
        } else {
          this.router.navigate([INTAKE_ROUTES.WORK_SCHEDULE_LOA]);
        }
      } else {
        this.router.navigate([INTAKE_ROUTES.HEALTHCARE_PROVIDER]);
      }
    } else if (this.router.url === INTAKE_ROUTES.WORK_SCHEDULE_LOA && this.maternityLOA) {
      this.router.navigate([INTAKE_ROUTES.HEALTHCARE_PROVIDER_LOA]);
    }else if (this.router.url === INTAKE_ROUTES.WORK_SCHEDULE_EDIT_LOA && this.maternityLOA) {
      this.router.navigate([INTAKE_ROUTES.WORK_SCHEDULE_LOA]);
    }else if (this.router.url === INTAKE_ROUTES.ABOUT_YOUR_DELIVERY || this.router.url === INTAKE_ROUTES.ABOUT_YOUR_DELIVERY_LOA) {
      if(this.maternityLOA){
        this.router.navigate([INTAKE_ROUTES.ABOUT_YOUR_ABSENCE_LOA]);
      } else {
        this.router.navigate([INTAKE_ROUTES.ABOUT_YOUR_ABSENCE]);
      }
    } else if (this.storageManagementService.getIntakeSessionData(this.bondingSessionConst.BONDING_FLOW, this.bondingSessionConst.INTAKE_TYPE_BONDING)) {
      const bondingFlowApplicable = this.storageManagementService.getIntakeSessionData(this.bondingSessionConst.BONDING_FLOW, this.bondingSessionConst.INTAKE_TYPE_BONDING);
      const extensionBonding = this.storageManagementService.getIntakeSessionData(BondingIntakeSessionConstants.EXTENSION_BONDING, BondingIntakeSessionConstants.INTAKE_TYPE_BONDING);
      const transitionBonding = this.storageManagementService.getIntakeSessionData(BondingIntakeSessionConstants.TRANSITION_TO_BONDING, BondingIntakeSessionConstants.INTAKE_TYPE_BONDING);
      const flightCrew = this.storageManagementService.getIntakeSessionData(BondingIntakeSessionConstants.FLIGHT_CREW);
      if (bondingFlowApplicable && (!extensionBonding && !transitionBonding)) {
        if (this.router.url === INTAKE_ROUTES.TAKE_LEAVE) {
          if (flightCrew) {
            this.router.navigate([INTAKE_ROUTES.CONTACT_INFO_STEP]);
          } else {
            this.router.navigate([INTAKE_ROUTES.WORK_SCHEDULE]);
          }
        } else if (this.router.url === INTAKE_ROUTES.WORK_SCHEDULE) {
          this.router.navigate([INTAKE_ROUTES.CONTACT_INFO_STEP]);
        } else if (this.router.url === INTAKE_ROUTES.WORKSCHEDULE_EDIT) {
          this.router.navigate([INTAKE_ROUTES.WORK_SCHEDULE]);
        } else if (this.router.url === INTAKE_ROUTES.CONTACT_INFO_STEP) {
          this.router.navigate([INTAKE_ROUTES.INTAKE_THINGS_YOU_WILL_NEED_CLEAR]);
        } else if (this.router.url === INTAKE_ROUTES.INTAKE_THINGS_YOU_WILL_NEED_CLEAR) {
          this.router.navigate([INTAKE_ROUTES.FDA_PAGE]);
        } else if (this.router.url === INTAKE_ROUTES.FDA_PAGE) {
          this.router.navigate([INTAKE_ROUTES.INTAKE_HOME]);
        } else {
          this.location.back();
        }
      } else if ((bondingFlowApplicable && extensionBonding) && !transitionBonding) {
        if (flightCrew && ((this.router.url === INTAKE_ROUTES.TAKE_LEAVE) || (this.router.url === INTAKE_ROUTES.TAKE_LEAVE_EXTENSION))) {
          this.router.navigate([INTAKE_ROUTES.INTAKE_THINGS_YOU_WILL_NEED_CLEAR]);
        } else if (this.router.url === INTAKE_ROUTES.TAKE_LEAVE) {
          this.router.navigate([INTAKE_ROUTES.WORK_SCHEDULE]);
        } else if(this.router.url === INTAKE_ROUTES.TAKE_LEAVE_EXTENSION) {
          this.router.navigate([INTAKE_ROUTES.WORK_SCHEDULE_EXTENSION]);
        } else if ((this.router.url === INTAKE_ROUTES.WORK_SCHEDULE) || (this.router.url === INTAKE_ROUTES.WORK_SCHEDULE_EXTENSION)) {
          this.router.navigate([INTAKE_ROUTES.INTAKE_THINGS_YOU_WILL_NEED_CLEAR]);
        } else if (this.router.url === INTAKE_ROUTES.INTAKE_THINGS_YOU_WILL_NEED_CLEAR) {
          this.router.navigate([INTAKE_ROUTES.FDA_PAGE]);
        } else if (this.router.url === INTAKE_ROUTES.FDA_PAGE) {
          this.router.navigate([INTAKE_ROUTES.INTAKE_HOME]);
        } else {
          this.location.back();
        }
      } else if ((bondingFlowApplicable && transitionBonding) && !extensionBonding) {
        if (flightCrew && this.router.url === INTAKE_ROUTES.TAKE_LEAVE) {
          this.router.navigate([INTAKE_ROUTES.INTAKE_THINGS_YOU_WILL_NEED_CLEAR]);
        } else if (this.router.url === INTAKE_ROUTES.TAKE_LEAVE) {
          this.router.navigate([INTAKE_ROUTES.WORK_SCHEDULE]);
        } else if (this.router.url === INTAKE_ROUTES.WORK_SCHEDULE) {
          this.router.navigate([INTAKE_ROUTES.INTAKE_THINGS_YOU_WILL_NEED_CLEAR]);
        } else if (this.router.url === INTAKE_ROUTES.INTAKE_THINGS_YOU_WILL_NEED_CLEAR) {
          this.router.navigate([INTAKE_ROUTES.FDA_PAGE]);
        } else if (this.router.url === INTAKE_ROUTES.FDA_PAGE) {
          this.router.navigate([INTAKE_ROUTES.INTAKE_HOME]);
        } else {
          this.location.back();
        }
      }
    } else if (this.router.url === INTAKE_ROUTES.RELEASE_RECORDS_AUTH || this.router.url === INTAKE_ROUTES.RELEASE_RECORDS_AUTH_LOA  ) {
      if(this.maternityLOA){
        this.router.navigate([INTAKE_ROUTES.HEALTHCARE_PROVIDER_LOA]);
      } else {
        this.router.navigate([INTAKE_ROUTES.HEALTHCARE_PROVIDER]);
      }

    } else if (this.router.url === INTAKE_ROUTES.HEALTHCARE_PROVIDER || this.router.url === INTAKE_ROUTES.HEALTHCARE_PROVIDER_LOA ) {
      if(this.maternityLOA){
        this.router.navigate([INTAKE_ROUTES.CONTACT_INFO_STEP_LOA]);
      } else{
        this.router.navigate([INTAKE_ROUTES.CONTACT_INFO_STEP]);
      }
    } else if (this.router.url === INTAKE_ROUTES.NOTIFICATION_PREFERENCES || this.router.url === INTAKE_ROUTES.NOTIFICATION_PREFERENCES_LOA) {
      if (this.maternityLOA) {
        this.router.navigate([INTAKE_ROUTES.EMPLOYMENT_DETAILS_LOA]);
      } else {
        this.router.navigate([INTAKE_ROUTES.EMPLOYMENT_DETAILS]);
      }
    } else if (this.router.url === INTAKE_ROUTES.EMPLOYMENT_DETAILS || this.router.url === INTAKE_ROUTES.EMPLOYMENT_DETAILS_LOA) {
      if (this.maternityLOA) {
        this.router.navigate([INTAKE_ROUTES.ABOUT_YOUR_DELIVERY_LOA]);
      } else {
        this.router.navigate([INTAKE_ROUTES.ABOUT_YOUR_DELIVERY]);
      }
    } else if (this.router.url === INTAKE_ROUTES.CONTACT_INFO_STEP|| this.router.url === INTAKE_ROUTES.CONTACT_INFO_STEP_LOA) {
      if(this.maternityLOA){
        this.router.navigate([INTAKE_ROUTES.INTAKE_THINGS_YOU_WILL_NEED_LOA]);
      } else{
        this.router.navigate([INTAKE_ROUTES.INTAKE_THINGS_YOU_WILL_NEED_CLEAR]);
      }
    }  else if(this.router.url === INTAKE_ROUTES.HEALTHCARE_PROVIDER_INFO_STEP|| this.router.url === INTAKE_ROUTES.HEALTHCARE_PROVIDER_INFO_STEP_LOA ){
      let provider= this.storageManagementService.getIntakeSessionData(IntakeSessionConstants.NEW_HEALTHCARE_PROVIDER);;
      if(this.maternityLOA){
        if (provider && provider?.providerDetails?.length >= 1) {
          this.router.navigate([INTAKE_ROUTES.HEALTHCARE_PROVIDER]);
        } else if(provider && provider?.providerDetails?.length < 1){
          this.router.navigate([INTAKE_ROUTES.CONTACT_INFO_STEP_LOA]);
        } else{
          this.router.navigate([INTAKE_ROUTES.CONTACT_INFO_STEP_LOA]);
        }
      } else {
        if (provider && provider?.providerDetails?.length >= 1) {
          this.router.navigate([INTAKE_ROUTES.HEALTHCARE_PROVIDER]);
        } else if(provider && provider?.providerDetails?.length < 1){
          this.router.navigate([INTAKE_ROUTES.CONTACT_INFO_STEP]);
        } else{
          this.router.navigate([INTAKE_ROUTES.CONTACT_INFO_STEP]);
        }
      }
    } else if (this.router.url === INTAKE_ROUTES.INTAKE_THINGS_YOU_WILL_NEED_CLEAR || this.router.url === INTAKE_ROUTES.INTAKE_THINGS_YOU_WILL_NEED_LOA) {
      this.router.navigate([INTAKE_ROUTES.FDA_PAGE]);
    } else if (this.router.url === INTAKE_ROUTES.FDA_PAGE) {
      if (this.storageManagementService.retrieveSessionObject(IntakeSessionConstants.INTAKE_SESSION_DATA)) {
        this.storageManagementService.removeSessionObject(IntakeSessionConstants.INTAKE_SESSION_DATA);
      }
      if(this.storageManagementService.getSessionData(SessionConstants.IS_WORK_SCHEDULE_SUBMITTED) && this.maternityLOA){
        this.storageManagementService.removeAttrFromSessionData(SessionConstants.IS_WORK_SCHEDULE_SUBMITTED);
      }
      if(this.storageManagementService.getSessionData(SessionConstants.REGULAR_WORK_SCHEDULE_SET) && this.maternityLOA){
        this.storageManagementService.removeAttrFromIntakeSessionData(SessionConstants.REGULAR_WORK_SCHEDULE_SET);
      }
      this.router.navigate([INTAKE_ROUTES.INTAKE_HOME]);
    } else {
      if (this.storageManagementService.getSessionData('previousRoute') === AppConstants.URL_CLAIM_RTW) {
        this.router.navigate([AppConstants.URL_CLAIM_DETAILS])
      } else if (this.currentRoute.includes(AppLeaveConstants.CLAIM_SUBMIT_NOT_ALLOW)) {
        this.router.navigate([AppConstants.ROUTE_TO_HOME])
      } else if (this.storageManagementService.getSessionData(SessionConstants.SWITCH_CLAIMS)) {
        this.navigationStack = this.storageManagementService.getSessionData(SessionConstants.NAVIGATION_STACK);
        const switchClaim = this.navigationStack.pop();
        this.storageManagementService.setSessionData(SessionConstants.NAVIGATION_STACK, this.navigationStack);
        const currentClaim = switchClaim ? switchClaim : '';
        if (currentClaim) {
          this.storageManagementService.setSessionObject(SessionConstants.CUR_CLAIM, currentClaim);
          window.location.reload();
        }
        if (this.navigationStack.length === 0) {
          const flagSwitch = false;
          this.storageManagementService.setSessionData(SessionConstants.SWITCH_CLAIMS, flagSwitch);
        }
      } else {
        this.location.back();
      }
    }
  }
}
