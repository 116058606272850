
import {throwError as observableThrowError} from 'rxjs/internal/observable/throwError';
import {Observable} from 'rxjs';

import {catchError, map} from 'rxjs/operators';
import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {ResponseErrorHandlerService} from './response-error-handler.service';
import {env} from '../../../environments/environment-loader';

import {AppLeaveConst} from '../constants/app.constants';
import {AppLabelConstants} from '../constants/appLabel.constants';

@Injectable()
export class StartNewLeaveService {
public claimType;
  constructor( private errorHandler: ResponseErrorHandlerService,
               private httpClient: HttpClient) { }

  getClaimMobileIntake(request, personID: string): Observable<any> {
    switch (request.Reason_KeyId) {
      case '1':
        this.claimType = AppLeaveConst.EEOWN_ILLNESS;
        break;
      case '2':
        this.claimType = AppLeaveConst.MATERNITY;
        break;
      case '3':
        this.claimType =  AppLeaveConst.CARE_FAMILY_MENBER;
        break;
      case '4':
        this.claimType =  AppLeaveConst.BONDING;
        break;
      case '5':
        this.claimType =  AppLeaveConst.OTHER;
        break;
      case '15':
        this.claimType = AppLeaveConst.ADA_ACCOMMODATION;
        break;
      default:
        this.claimType =  '';
    }
    const req: string = JSON.stringify({
      ClaimType: this.claimType,
      personId: personID
    });
    return this.httpClient.post(env.gbLeaves.claimIntake, req,
      {
        headers: new HttpHeaders()
          .set(AppLabelConstants.CONTENT_TYPE_KEY, AppLabelConstants.APPLICATION_KEY)
      }).pipe(
      map(
        (response: Response) => {
          if (!this.errorHandler.isServiceResponseHasError(response)) {
            return response;
          } else {
            throw response;
          }
        }
      ), catchError((error: any) =>
        //console.log("Inside Catch Handler");
         this.errorHandler.handleError(error) ), );

  }

  postLeaveIntake(request, personID: string, scriptInstanceId): Observable<any> {
    const req: string = JSON.stringify({
      Answers: request,
      personId: personID,
      ScriptInstanceId: scriptInstanceId
    });
    return this.httpClient.post(env.gbLeaves.claimIntake, req,
      {
        headers: new HttpHeaders()
          .set(AppLabelConstants.CONTENT_TYPE_KEY, AppLabelConstants.APPLICATION_KEY)
      }).pipe(
      map(
        (response: Response) => {
          if (!this.errorHandler.isServiceResponseHasError(response)) {
            return response;
          } else {
            throw response;
          }
        }
      ), catchError((error: any) => this.errorHandler.handleErrorForSubmit(error)), );
  }
  private handleError(error: Response) {
    return observableThrowError(error);
  }

}
