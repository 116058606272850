
export class INTAKE_PROGRESS_BAR_STEP_ID {
  static STEP1 = 1;
  static STEP2 = 2;
  static STEP3 = 3;
  static STEP4 = 4;
  static STEP5 = 5;
  static STEP6 = 6;
  static STEP7 = 7;
}
export class ICONS {
  static icon_schedule = './assets/images/icons/getting-started-progressbar-icon.svg';
  static icon_ada_accommodation = './assets/images/icons/your-abilities-progressbar-icon.svg';
  static icon_icon_stethoscope = './assets/images/icons/healthcare-provider-progressbar-icon.svg';
  static icon_check_mark = './assets/images/icons/success-progressbar-icon.svg';
  static icon_contact = './assets/images/icons/Contact_Info_Active.svg';
  static icon_bell = './assets/images/icons/Notifications_Active.svg';
  static icon_employment = './assets/images/icons/Employment_Progress_Bar_Active.svg'
}

export class Active_ICONS {
  static icon_schedule = './assets/images/icons/Getting_Started_Active_Icon.svg';
  static icon_ada_accommodation = './assets/images/icons/Your_Abilities_Active_Icon.svg';
  static icon_icon_stethoscope = './assets/images/icons/Healthcare_Provider_Active_Icon.svg';
  static icon_check_mark = './assets/images/icons/Success_Active_Icon.svg';
  static icon_contact = './assets/images/icons/Contact_Info_Active.svg';
  static icon_bell = './assets/images/icons/Notifications_Active.svg';
  static icon_work_schedule = './assets/images/icons/work-schedule-active-icon.svg';
  static icon_clipboard = './assets/images/icons/employer-specific-questions-active-icon.svg';
 static icon_employment = './assets/images/icons/Employment_Progress_Bar_Active.svg'
}

export class In_Active_ICONS {
  static icon_schedule = './assets/images/icons/Getting_Started_InActive_Icon.svg';
  static icon_ada_accommodation = './assets/images/icons/Your_Abilities_InActive_icon.svg';
  static icon_icon_stethoscope = './assets/images/icons/Healthcare_Provider_InActive_Icon.svg';
  static icon_check_mark = './assets/images/icons/Success_InActive_Icon.svg';
  static icon_contact = './assets/images/icons/Contact_Info_Not_Active.svg';
  static icon_bell = './assets/images/icons/Notifications_Not_Active.svg';
  static icon_work_schedule = './assets/images/icons/work-schedule-inactive-icon.svg';
  static icon_clipboard = './assets/images/icons/employer-specific-questions-inactive-icon.svg';
  static  icon_employment = './assets/images/icons/Employment_Progress_Bar_Inactive.svg'
}

export enum STEPPER_PAGES {
  CONTACT_INFO, HEALTHCARE_PROVIDER_INFO, ABOUT_YOUR_ABSENCE, ABOUT_YOUR_DELIVERY, EMPLOYMENT_QUESTIONS, NOTIFICATION_PREFERENCES,WORK_SCHEDULE,
  TAKING_LEAVE, ABOUT_YOUR_BONDING
}

export class PROGRESS_BAR_STEPS {
  static DYNAMIC_STEP_NUMBER = '[dynamic number]';
  static EXTENSION_TO_BONDING_MAX_STEP = '2';
  static FLIGHT_CREW_TRANSITION_TO_BONDING_MAX_STEP = '2';
  static TRANSITION_TO_BONDING_MAX_STEP = '3';
  static FLIGHT_CREW_MAX_STEP = '5';
  static FULL_BONDING_MAX_STEP = '6';
  static LOA_ACTIVE_MAX_STEP = '7';
  static LOA_Active_WITH_FLIGHT_CREW_MATERNITY = '6';
}
