export class OverpaymentIconConstants {
  static DENIED_CIRCLE = '../assets/images/icons/deniedCircle.svg';
  static CLOSE_HARTFORD_GRAY_ICON = '/assets/images/icons/close-hartfordgray.svg';
  static LIGHT_BLUE_DOWN_CARET = '/assets/images/icons/light-blue-caret-down.svg';
  static DOLLAR_BILLS_ANIMATED = '/assets/images/icons/dollar-bills-animated.svg';
  static TOOLTIP_ICON = './assets/images/icons/Information.svg';
  static CREDIT_CARD_ICON_ANIMATED = '/assets/images/icons/credit-card-animated.svg';
  static WHITE_X_CLOSE_SVG = './assets/images/icons/white-x close.svg';
  static PRINT_RECEIPT_ICON = 'assets/images/icons/print-icon.png';
  static PAYMENT_SUCCESS_ICON = 'assets/images/icons/animated-payment-success-icon.svg';
  static HARTFORD_LOGO = './assets/images/logos/hig-logo-small.jpg';
  static PAYMENT_FAILURE_ICON_ANIMATED = 'assets/images/icons/animated-failure-icon.svg';
  static DOWNLOAD_ICON = 'assets/images/icons/light-blue-pdf-icon.svg';

}
