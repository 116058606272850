export class OverpaymentRouteConstants {
  static PAYMENTS_YOU_OWE = '/overPayment/paymentsYouOwe';
  static SELECT_PAYMENT_AMOUNT = '/overPayment/selectPaymentAmount';
  static MAKE_PAYMENT = '/overPayment/makePayment';
  static REVIEW_DOCUMENT = '/reviewDocument';
  static CLAIM_DETAILS_URL = '/claimDetails';
  static PAYMENT_SUBMIT_CONFIRMATION = '/overPayment/paymentConfirmation';
  static HOME_URL = '/home';
  static MORE_LETTERS_LOCATION = '/moreLetters';
}
