import {GbBase} from './gb-base';

export class GbCxrRules extends GbBase {

  getUrls() {
    return {
      prdRules_URL: this.getBaseUrl() + 'gbrules/postAuthProduct?' + this.getClientId('auth'),
      prdRules_URL_Claim_Overview: this.getBaseUrl() + 'gbrules/claimsOverview?' + this.getClientId('auth'),
      //BlazeFAQ-from OWCS
      faqRules_URL: this.getBaseUrl() + 'gbrules/faq?' + this.getClientId('auth') + '&pageId=',
      faqRules_unauth_URL: this.getBaseUrl() + 'uws/gbrules/faq?' + this.getClientId('auth') + '&pageId=',
    }
  }
}
