import { IDropDownOptions } from '@app/cxr/common/atoms/dropdowns/select-dropdown/select-dropdown.model';
import { IHukDropDownOptions } from './absence-section.model';
import { IntakeCheckBox } from './reasonList.model';

export interface IHoursMinsObject {
  hour: string,
  minute: string,
  unit: string
}


export interface IHukHoursMinsObject {
  hour: string,
  minute: string,
  unit: string
}

export interface IHukHourMinsOnlyObject {
  hour: string,
  minute: string
}

export interface IIntermittentDateObject {
  appointmentAbsQues: boolean,
  flareUpAbsQues: boolean,
  intermittentAbsenceType: string,
  futureAbsenceReason: IntakeCheckBox[],
  intermittentLeaveEnd: string,
  mandatoryOvertime: string,
  hourMinDropDown?: IHukHoursMinsObject;
  overtimeWorkHourMinDropDown?: IHukHourMinsOnlyObject;
  continueWorkingPartialDay?: string;
  absenceStartTimeHourMinDropDown?: IHukHoursMinsObject;
  partialDayMissedTimeHourMinDropDown?: IHukHoursMinsObject;
  isValidData: boolean,
  dateInterLeaveEnd: string,
  dateEstimateILED: string,
  isEstimateILED: string
}

export interface ReducedScheduleDataObject {
  fullScheduleRet: string;
  returnToWorkForFullSchedule: string;
  canEstimateDateRadio: string;
  estimateDate: string;
  timeIAmWorkingDropDown?: IHukHoursMinsObject;
  isValidData: boolean;
  newReducedSchedule: string;
  reducedScheduleTimeAmount: string;
}

export interface IHoursMinsDropdownContinuous {
  completeMinsList: IDropDownOptions[] | [];
  limitedMinsList: IDropDownOptions[] | [];
}

export interface IHukHoursMinsDropdownContinuous {
  completeMinsList: IHukDropDownOptions[] | [];
  limitedMinsList: IHukDropDownOptions[] | [];
}

export interface IHoursMinutesDropdownSectionContent {
  questionText: string;
  hoursLabel: string;
  minutesLabel: string;
  hoursFormControl: string;
  minsFormControl: string;
  hoursSelectOptions: IHukDropDownOptions[];
  minsSectionOptions: IHukDropDownOptions[];
  isPreviousPartial?: boolean;
  maxHours?: number;
  maxMinutes?: number;
  display?: boolean;
  type?: DROPDOWN_TYPE;
  dropdownConfigType?: DROPDOWN_CONFIG_TYPE
}

export interface IHukHoursMinutesDropdownSection {
  questionText: string;
  hoursLabel: string;
  minutesLabel: string;
  hoursFormControl: string;
  minsFormControl: string;
  hoursSelectOptions: IHukDropDownOptions[];
  minsSectionOptions: IHukDropDownOptions[];
}

export enum DROPDOWN_TYPE {Scheduled, Actual}
//extended mean multiple dropdowns and basic mean two dropdowns
export enum DROPDOWN_CONFIG_TYPE {Aligned, Stacked}
