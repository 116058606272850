import {Product} from '../shared/models/product.model';

/**
 * Created by FA81445 on 7/31/2017.
 */

export const PRODUCTS: Product[] = [
  {
    id: 1,
    prdKey: 'Prd_1',
    name: 'Maternity', //Maternity withiout Leave
    description: '',
    disclosure : '',
    benefitsOfferBy : '',
    icon: 'maternity',
    location: '/productInfo',
    covType: 'none',
    covCode: 'Leave',
    isVoluntary : false,
    prd_key: 'CO_66'

  },
  {
    id: 2,
    prdKey: 'Prd_2',
    name: 'Planned Surgery',
    description: '',
    disclosure : '',
    benefitsOfferBy : '',
    icon: 'surgery',
    location: '/productInfo',
    covType: 'none',
    covCode: 'MWH',
    isVoluntary : false,
    prd_key: 'CO_32'
  },
  {
    id: 3,
    prdKey: 'Prd_3',
    name: 'Short Term Disability',
    description: '',
    disclosure : '',
    benefitsOfferBy : '',
    icon: 'STD',
    location: '/productInfo',
    covType: 'NST',
    covCode: 'STD',
    isVoluntary : false,
    prd_key: 'CO_34'

  },
  {
    id: 4,
    prdKey: 'Prd_4',
    name: 'Leave Of Absence',
    description: '',
    disclosure : '',
    benefitsOfferBy : '',
    icon: 'Leave',
    location: '/productInfo',
    covType: 'STAT',
    covCode: 'PFL',
    isVoluntary : false,
    prd_key: 'CO_36'
  },
  {
    id: 5,
    prdKey: 'Prd_5',
    name: 'California Paid Family Leave',
    description:  '',
    disclosure : '',
    benefitsOfferBy : '',
    icon: 'CA_PFML',
    location: '/productInfo',
    covType: 'STAT',
    covCode: 'PFL',
    isVoluntary : false,
    prd_key: 'CO_38'
  },
  {
    id: 6,
    prdKey: 'Prd_6',
    name: 'Long Term Disability',
    description:  '',
    disclosure : '',
    benefitsOfferBy : '',
    icon: 'LTD',
    location: '/productInfo',
    covType: 'ABIL',
    covCode: 'LTD',
    isVoluntary : false,
    prd_key: 'CO_40'
  },
  {
    id: 7,
    prdKey: 'Prd_7',
    name: 'Accident',
    description:  '',
    disclosure : '',
    benefitsOfferBy : '',
    icon: 'accident',
    location: '/productInfo',
    covType: 'VOL',
    covCode: 'ACC',
    isVoluntary : true,
    prd_key: 'CO_44'
  },
  {
    id: 8,
    prdKey: 'Prd_8',
    name: 'Critical Illness',
    description:  '',
    disclosure : '',
    benefitsOfferBy : '',
    icon: 'criticalillness',
    location: '/productInfo',
    covType: 'VOL',
    covCode: 'CI',
    isVoluntary : true,
    prd_key: 'CO_42'
  },
  {
    id: 9,
    prdKey: 'Prd_9',
    name: 'Hospital Indemnity',
    description: '',
    disclosure : '',
    benefitsOfferBy : '',
    icon: 'hospitalindemnity',
    location: '/productInfo',
    covType: 'VOL',
    covCode: 'HI',
    isVoluntary : true,
    prd_key: 'CO_46'
  },
  {
    id: 10,
    prdKey: 'Prd_10',
    name: 'Maternity', //Maternity with Leave
    description: '',
    disclosure : '',
    benefitsOfferBy : '',
    icon: 'maternity',
    location: '/productInfo',
    covType: 'none',
    covCode: 'Leave',
    isVoluntary : true,
    prd_key: 'CO_30'
  },
  {
    id: 11,
    prdKey: 'Prd_11',
    name: 'ADA Accommodation',
    description: '',
    disclosure : '',
    benefitsOfferBy : '',
    icon: 'accommodation',
    location: '/productInfo',
    covType: 'none',
    covCode: 'none',
    isVoluntary : false,
    prd_key: 'CO_515'
  }
];

export const SuccessorProducts = {response: [
  {
    ruleId: 'RID_106_c',
    uiFieldId: 'CO_40',
    displayInd: 'true'
  },
  {
    ruleId: 'RID_114_b',
    uiFieldId: 'CO_30',
    displayInd: 'true'
  }
]}

export const PostLoginProducts = {claimOverviewResponseList: [
  {
    ruleId: 'RID_099',
    uiFieldId: 'CO_66',
    displayInd: 'true'
  },
  {
    ruleId: 'RID_095',
    uiFieldId: 'CO_40',

    displayInd: 'true'
  },
  {
    ruleId: 'RID_099',
    uiFieldId: 'CO_30',
    displayInd: 'true'
  },
  {
    ruleId: 'RID_103',
    uiFieldId: 'CO_46',
    displayInd: 'true'
  },
  {
    ruleId: 'RID_104',
    uiFieldId: 'CO_42',
    displayInd: 'true'
  }
]}
