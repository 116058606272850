
import {catchError, map} from 'rxjs/operators';
import {Injectable} from '@angular/core';


import {Observable, Subject} from 'rxjs';
import {env} from '../../../environments/environment-loader';


import {ResponseErrorHandlerService} from './response-error-handler.service';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {AppLabelConstants} from '../constants/appLabel.constants';

@Injectable()
export class ContactsService {
  isDisabilityContactCompleted = new Subject();
  isClaimRepNeedToDisplay: boolean = false;
  constructor( private errorHandler: ResponseErrorHandlerService,
               private httpClient: HttpClient) { }

  getDisabilityClaimRelatedContact(insuredID: string, caseID: string): Observable<any> {
    const request: string = JSON.stringify(
      {
        insuredId: insuredID,
        caseId: caseID
      }
    );

    return this.httpClient.post(env.gbClaims.getGetClaimOfficePhoneNumbersURL, request,
      {
        headers: new HttpHeaders()
          .set(AppLabelConstants.CONTENT_TYPE_KEY, AppLabelConstants.APPLICATION_KEY)
      }).pipe(
      map(
        (response: Response) => {
          if (!this.errorHandler.isServiceResponseHasError(response)) {
            return response;
          } else {
            throw response;
          }
        }
      ),   catchError((error: any) => this.errorHandler.handleError(error)), );
  }

  getIsClaimRepNeedToDisplay(): boolean {
    return this.isClaimRepNeedToDisplay;
  }
  setIsClaimRepNeedToDisplay(val: boolean ) {
    this.isClaimRepNeedToDisplay =  val;
  }
}
