/**
 * Created by AC12326 on 12/7/2017.
 */
export const PolicyAdminCoverageMockContent = {
  errorList: [],
  coveragePlansList: [
    {
      caseId: '00001',
      coverageCategoryCode: 'LIFE',
      coverageCategoryDescription: 'LIFE',
      coverageTypeCode: 'BTRM',
      coverageTypeDescription: 'BASIC TERM',
      coveragePlanDescription: 'LIFE BTRM 1',
      coveragePlanNumber: 1,
      coveragePlanEffectiveDate: '1993-01-01',
      coveragePlanTerminationDate: null,
      fundingMethodCode: 'ASO'


    },
    {
      caseId: '00001',
      coverageCategoryCode: 'HI',
      coverageCategoryDescription: 'HI',
      coverageTypeCode: 'VOL',
      coverageTypeDescription: 'VOLUNTARY',
      coveragePlanDescription: 'LIFE SUPP 1',
      coveragePlanNumber: 2,
      coveragePlanEffectiveDate: '1993-01-01',
      coveragePlanTerminationDate: null,
      fundingMethodCode: 'FI'
    },
    {
      caseId: '00001',
      coverageCategoryCode: 'CI',
      coverageCategoryDescription: 'CI',
      coverageTypeCode: 'BDEP',
      coverageTypeDescription: 'BASIC DEPENDENT',
      coveragePlanDescription: 'LIFE BDEP 1',
      coveragePlanNumber: 3,
      coveragePlanEffectiveDate: '1993-01-01',
      coveragePlanTerminationDate: '2012-07-01',
      fundingMethodCode: 'FI'
    },
    {
      caseId: '00001',
      coverageCategoryCode: 'ACC',
      coverageCategoryDescription: 'ACC',
      coverageTypeCode: 'BDEP',
      coverageTypeDescription: 'BASIC DEPENDENT',
      coveragePlanDescription: 'LIFE BDEP 1',
      coveragePlanNumber: 3,
      coveragePlanEffectiveDate: '1993-01-01',
      coveragePlanTerminationDate: '2012-07-01',
      fundingMethodCode: 'ASO'
    }]
}
