import {Component, EventEmitter, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {ModalService} from '../../../../../shared/services/modal.service';
import {Subscription} from 'rxjs/index';

@Component({
  selector: 'combine-sliding-modal',
  templateUrl: './combine-sliding-modal.component.html',
  styleUrls: ['./combine-sliding-modal.component.scss']
})
export class CombineSlidingModalComponent implements OnInit, OnDestroy{
  private subscriptions: Subscription[] = [];
  @Output() onCloseButton = new EventEmitter<MouseEvent>();
  @Input() modalName: string;
  @Input() modalTitle: string;
  @Input() combineModelId: string;
  showModal: boolean = false;

  constructor(private modalService: ModalService) {
  }

  ngOnInit(): void {
   this.subscriptions.push(this.modalService.modalClosed$.subscribe(
      modalName => {
        if (this.modalName === modalName) {
          this.setModalState(false);
        }
      }),
    this.modalService.modalOpened$.subscribe(
      modalName => {
        if (this.modalName === modalName) {
          this.setModalState(true);
        }
      }));
  }

  ngOnDestroy(): void {
    this.modalService.clear();
    this.subscriptions.forEach(subscription => subscription.unsubscribe());
  }

  setModalState(state: boolean): void {
    if (state) {
      this.showModal = state;
      document.body.classList.add('modal-open');
      document.body.style.overflow = "hidden";
    } else {
      this.showModal = state;
      document.body.classList.remove('modal-open');
      document.body.style.overflow = "";
    }
  }

  onCloseModal(event: MouseEvent): void {
    this.onCloseButton.emit(event);
    this.modalService.closeModal(this.modalService.getActiveModal());
  }
}
