import {NgModule} from '@angular/core';
import {RouterModule} from '@angular/router';
import {FormsModule} from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';

// Used to share LinkDirective and UI tagging across multiple components
@NgModule({
  imports: [
    RouterModule,
    HttpClientModule,
    FormsModule
  ],
  declarations: [
    /*LinkDirective*/
  ],
  providers: [


  ],
  exports: [
    /*LinkDirective*/
  ]
})
export class TealiumModule {
}

