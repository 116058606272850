/**
 * Created by AC12396 on 9/19/2019.
 */

export class ErrorConstants {
  static CONTACT_US_LICK = ' <a href="/contactUs"><u>contact us</u></a>.';
  static COMPANY_ACCEPTANCE_DATE = 'You’ve picked a date that we aren’t covering for your employer. You may need to report your absence to another company instead. If you’re not sure, check with your employer.';
  static REQUEST_ALREADY_EXIST = 'You already submitted an absence for that date. If you chose the wrong date by mistake, please change it. Or if you need to update the absence you already submitted, please' + ErrorConstants.CONTACT_US_LICK;
  static DURATION_EXCEED = 'Please review the hours for your request. They don’t match your normal work schedule. If you made a mistake, please change it. If the information is correct, please' + ErrorConstants.CONTACT_US_LICK;
  static NON_WORKDAY_HOURS = 'You’ve entered more hours than you’re scheduled to work. Please verify that the hours you entered are right before you continue.';
  static NOT_IN_WORKSCHEDULE = 'Is that date right? That’s not a normally scheduled workday for you. If you chose it by mistake, please change it. If the date is correct, please' + ErrorConstants.CONTACT_US_LICK;
  static ERROR = 'error';
  static UNABLE_SUBMIT_CLAIM_ONLINE = 'Unable to Submit Claims Online';
  static DESKTOP_BROWSER_RECOMM = 'Desktop Browser Recommendation';
  static PRELOGIN_LET_CHAT = 'Pre Login Lets Chat';
  static ERROR_MSG_SOMETHNG_WENT_WRONG = 'Sorry, looks like something went wrong on our side!';
  static ERROR_MESSAGE_1 = 'Error';
  static ERR_CODE_50001 = 50001;
  static ERR_CODE_50002 = 50002;
  static ERR_CODE_50003 = 50003;
  static FUNDING_METHOD = 'FI';
  static LAST_DAY_OF_WPRK = 'lastDayOfWork';
  static LAST_DAY_WORK_MUST_BE_ON_OR_BEFORE = 'Your last date worked must be on or before your disability date.';
  static HOURS_WORKED = 'hoursWorked';
  static PLEASE_ENTER_UR_HRS_WRKD = 'Please enter your hours worked.';
  static NEW_DISABILITY_DATE = 'newDisabilityDate';
  static ENTER_VALID_DATE = 'Please enter a valid date.';
  static ENTER_VALID_EMAIL = 'Email Address is required.';
  static ENTER_VALID_PHONE = 'We need your mobile number to continue.';
  static CHOOSE_DATE_ONORBEFORE_TODAY = 'Please choose a date on or before today.';
  static CHOOSE_DATE_BEFORE_DELIVERY = 'Your last day at work must be on or before your delivery date';
  static ERROR_CHILD_NAME = 'Please tell us your baby\'s name';
  static LAST_DAY_WORK_FIRST = 'Please Select Last Day Work First';
  static PLEASE_SELECT_EACH_DAY = 'Please make a selection for each day';
  static LDW_GREATER_DATE_DELIVERY = 'LDW Is Greater Than The Date Of Delivery.';
  static SELECT_HOURS = 'Please Select Hours';
  static DATE_AFTER_FIRST_ENTERED = 'This date should come after the first one you entered.';
  static SELECT_DELIVERY_TYPE = 'Please select your delivery type.';
  static LDW_GRATER_DATE_DELIVERY = 'Last Day Work Is Greater Than The Date Of Delivery';
  static DATE_OF_BIRTH = 'dateOfBirth';
  static LAST_WORK_DATE = 'lastWorkDate';
  static DELIVERY_SECTION = 'deliverySection';
  static LAST_DAY_WORK = 'lastDayWorked';
  static DELIVERY_TYPE = 'deliveryType';
  static APPROVED_THRU_MUST_BE_GREATER = 'Please enter a date greater than the approved through date.';
  static RTW_MUST_BE_GREATER = 'Your return to work date shouldn\'t be before your approved-through date.';
  static RTW_NOT_EXTENSION_ERR = 'Your return to work date shouldn\'t be the day your extension starts.';
  static PLEASE_TELL_BABIES_NAME = 'Please tell us your babies\' names';
  static REASON_STOP_WORK = 'Please enter a reason';
  static WEEKLY_SCHEDULE_ERR = 'Please let us know when you started using this schedule.';
  static MULTIWEEK_SCHEDULE_ERR = 'Please enter the first day in this schedule.';
  static ROTATING_SCHEDULE_ERR_1 = 'Please enter the first day of your rotating schedule.';
  static ROTATING_SCHEDULE_ERR_2 = 'Please let us know how long your rotation is.';
  static RTW_BEFORE_CLAIM_START_ERR = 'Please check the dates you entered. The date you chose for your return to work is before your claim\'s start date.';
  /** CONFIRM SURGERY START */
  static NEW_SURGERY_DATE = 'newSurgeryDate';
  /** CONFIRM SURGERY END */

  /**  EMPLOYEE-BENEFIT-REGISTRATION*/
  static TECHNICAL_DIFFICULTY_MSG = 'Looks like we\'re experiencing some technical difficulties. You can try again later, or call us at 1-877-778-1383';
  static ERROR_GET_USER = 'error getUser';
  static SYS_DOWN_ERROR_IN_LOGIN = 'SysDown Service Error in Login:: ';
  /**  EMPLOYEE-BENEFIT-REGISTRATION*/

  /**  NOTIFICATION_REGISTRATION*/
  static UR_CHANGES_HAVE_BEEN_SAVED = 'Your changes have been saved';
  static ERROR_CRREATING_NEW_USER_PROFILE = 'Error While creating new User Profile';
  static FAILED_TRANSACTION_MSG_RIBBON = 'We’re Sorry. Something went wrong on our end, and parts of this page might not be available. We’re working to fix the problem. You can try refreshing your browser, or call 1-877-778-1383 M-F 8AM-8PM ET if the problem persists.';
  static WARNING_SM = 'warning';

  /**  NOTIFICATION_REGISTRATION*/
  /** CLAIM DOCUMENT START */
  static ADD_MESSAGE_CONTENT = 'It looks like our system found a virus. Please remove the file(s) before you complete your upload.';
  /** CLAIM DOCUMENT END */

  static ERROR_GETTING_SITEMAP_PRE_LOGIN = 'Error Getting Site Map Res PreLogin';
  static ERROR_GETTING_SITEMAP_POST_LOGIN = 'Error Getting Site Map Res PostLogin';
  static ERROR_PRODUCT_LIST_OWCS_POST_LOGIN = 'Error Product List OWCS Content Post Login';
  static ERROR_WHILE_FILTERING_PROD = 'Error while filtering the product';

  /** UPDATED-TCS-ALERT-MODAL*/
  static UPDATES_TCS_WARNING_MSG = 'We’re Sorry. Something went wrong on our end, and parts of this page might not be available. ' +
    'We’re working to fix the problem. You can try refreshing your browser, ' +
    'or call 1-877-778-1383 M-F 8AM-8PM ET if the problem persists.';
  static SERVER_IS_DOWN_MSG = 'Server Is down please try after sometime';
  static UPDATED_TCS = 'updateTCS';
  static THE_TERMS_OF_SERVICE_MSG = 'The Terms of Service must be accepted before submitting this transaction.';
  /** UPDATED-TCS-ALERT-MODAL*/

  static APPROVED_THROUGH_DATE = 'Please enter a date greater than the approved through date.';

  static SOMETHING_WENT_ON_OUR_END_MSG = 'We’re Sorry. Something went wrong on our end, and parts of this page might not be available. ' +
    'We’re working to fix the problem. You can try refreshing your browser, ' +
    'or call 1-877-778-1383 M-F 8AM-8PM ET if the problem persists.';

  static PROTECT_UR_PERSONAL_INFO_MSG = `Protect your personal information with a strong password. For security reasons, your password can't include uniquely identifying information (like your name or email address).`;
  static IT_LOOKS_LIKE_U_JUST_RESETE_UR_PASSWORD_MSG = `It looks like you just reset your password. If you want to change it again, you can do so in 30 minutes. Remember, your new password can’t be the same as your last 2 passwords.`;
  static UR_NEW_PASSWORD_CANT_BE_SAME_AS_LAST_2 = `Your new password can't be the same as your last 2 passwords.`;

  static WE_LOGGED_OUT_U_AFTER_15_MINS_INACTIVITY_MSG = 'We logged you out after 15 minutes of inactivity to help keep your account secure. If you weren’t done, log back in below.';
  static U_HAVE_SUCCESSFULLY_LOGGED_OUT = 'You have successfully logged out';
 static ECOSIGN_FORM_ERR_MESSAGE = 'It looks like that’s not working right now. You can try again later, or you can download the form to fill out and upload instead.';
 static DOWNLOAD_FORM_ERR_MESSAGE = 'It looks like forms aren’t available right now. You can try again later, or Contact Us (use the Claims/Leave of Absence Support number) to have the form emailed to you.';
   static SOMETHING_WENT_ON_OUR_END_MSG_OPTION_5_NUMBER = 'We’re sorry. Something went wrong on our end, and parts of this page might not be available. ' +
    'We’re working to fix the problem. You can try refreshing your browser, ' +
    'or call 1-877-778-1383 Option 5 M-F 8AM-8PM ET if the problem persists.';

  static SOMETHING_WENT_THAA_DANGER_MSG_HEADER = 'It looks like our servers aren’t responding';
  static SOMETHING_WENT_THAA_DANGER_MSG = 'Give it a minute or two and try submitting again. Your session will time out after 15 minutes of inactivity. If that happens, you’ll have to start over.';


  static IT_LOOKS_LIKE_U_ALREADY_REQUESTED_AN_EXTENSION_FOR_THIS_DT_MSG = 'It looks like you already requested an extension for this date. We got your first request, and we’re working on it! Once we make a decision, you’ll see the update on your timeline.';
  static INTAKE_ERROR = 'intakeError';
  static PLEASE_SELECT = 'Please make a selection';
  static TWO_DIGIT = 'Please enter a two-digit number';
  static LAST_VISIT = 'Please select a date on or before today';
  static NEXT_VISIT = 'Please select a date on or after today';
  static PLEASE_ENTER_LAST_DAY_WORK = 'Please enter your last day at work';
  static PLEASE_ENTER_ABSENT_DAY = 'Please enter the first day you were absent from work';
  static PLEASE_ENTER_REASON = 'Please enter a reason for your extension request';
  static PLEASE_ENTER_REASON_GAP = 'Please enter a reason for the gap in your last day at work and first day absent';
  static PLEASE_ENTER_RTW = 'Please enter the date you plan to go back to work.';
  static RTW_BEFORE_EXTENSION = 'Please check the dates you entered. The date you chose for your return to work is earlier than the extension date you requested.';
  static PLEASE_ENTER_DOC_NAME = 'Please enter your doctor\'s name';
  static PLEASE_ENTER_DOC_PHONE = 'Please enter your doctor\'s phone number';
  static NUMERIC_PHONE_NUMBER = 'The phone number should be 10 numeric digits';
  static NUMERIC_FAX_NUMBER = 'The fax number should be 10 numeric digits';
  static PLEASE_ENTER_DOC_FAX = 'Please enter your doctor\'s fax number';
  static PLEASE_DISCHARGE_GREATER_ADMITTED = 'Please enter a date on or after your admitted date';
  static NO_EMAIL_ID = 'Without an email address, you may miss out on important claim notifications.';

  // file upload button errors constants
  static MAXIMUM_FILE_SIZE_EXCEEDED_ERROR = 'One or more of your files is too big to upload. Please make sure each file is 10MB or smaller.';
  static PROBLEM_SELECTING_FILE_ERROR = 'Something went wrong with this file. Please try removing it and selecting it again.';
  static INVALID_FILE_FORMAT_ERROR = 'We can\'t accept this file type. Please use one of these formats: DOC, PDF, JPG, RTF, XLS, PPT, BMP, TXT, GIF, TIF, or TIFF';
  static UNSUPPORTED_MICROSOFT_FILE_FORMAT = 'We don\'t accept Microsoft file formats. Please choose a different type of file.';
  static MALICIOUS_CONTENT_ERROR = 'virus';
  static INVALID_TXT_FILE_MSG = 'We don\'t accept txt file formats. Please choose a different type of file.';
  static INVALID_RTF_FILE_MSG = 'We don\'t accept rtf file formats. Please choose a different type of file.';
  static MALICIOUS_CONTENT_ERROR_MSG = 'Our system found what might be a virus in this file. Please remove the file before completing your upload.';
  // file upload button errors constants
}
