
import {catchError, map} from 'rxjs/operators';
/**
 * Created by AC12396 on 12/17/2018.
 */

import {Injectable} from '@angular/core';


import {Observable, Subject} from 'rxjs';
import {env} from '../../../environments/environment-loader';


import {ResponseErrorHandlerService} from './response-error-handler.service';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {AppLabelConstants} from '../constants/appLabel.constants';
import {StorageManagementService} from './storage-management.service';
import {SessionConstants} from '../constants/session.constants';

@Injectable()
export class ClaimRepresentativeService {
  isDisabilityContactCompleted = new Subject();

  constructor( private errorHandler: ResponseErrorHandlerService,
               private storageManagementService: StorageManagementService,
               private httpClient: HttpClient) { }

  getClaimRepresentativeDetails(personId: string, claimId: string): Observable<any> {

    return this.httpClient.get(env.thaaWKABPortalService.getClaimRepresentativeDetailsURL + '&personid=' + personId + '&claimid=' + claimId,
      {
        headers: new HttpHeaders()
          .set(AppLabelConstants.CONTENT_TYPE_KEY, AppLabelConstants.APPLICATION_KEY)
      }).pipe(
      map(
        (response: Response) => {
          if (!this.errorHandler.isServiceResponseHasError(response)) {
            if (response) {
              const claimHandlerObj = response;
              this.storageManagementService.setSessionData(SessionConstants.CLAIM_REP_DETAILS, claimHandlerObj);
            }
            return response;
          } else {
            throw response;
          }
        }
      ), catchError((error: any) => this.errorHandler.handleError(error)), );

  }

  getLeaveRepresentativeDetails(personId: string, leaveId: string): Observable<any> {

    return this.httpClient.get(env.thaaWKABPortalService.getLeaveRepresentativeDetailsURL + '&personid=' + personId + '&leaveId=' + leaveId,
      {
        headers: new HttpHeaders()
          .set(AppLabelConstants.CONTENT_TYPE_KEY, AppLabelConstants.APPLICATION_KEY)
      }).pipe(
      map(
        (response: Response) => {
          if (!this.errorHandler.isServiceResponseHasError(response)) {
            if (response) {
              const leaveHandlerObj = response;
              this.storageManagementService.setSessionData(SessionConstants.CLAIM_REP_DETAILS, leaveHandlerObj);
            }
            return response;
          } else {
            throw response;
          }
        }
      ), catchError((error: any) => this.errorHandler.handleError(error)), );

  }
}

