
import {of, Observable, Subject} from 'rxjs';

import {catchError, map, takeUntil} from 'rxjs/operators';
import {Injectable} from '@angular/core';


import {env} from '../../../environments/environment-loader';


import {User} from '../models/user.model';
import {ResponseErrorHandlerService} from './response-error-handler.service';
import {HttpClient, HttpErrorResponse, HttpHeaders} from '@angular/common/http';
import {MissingInfo, MissingInfoFromAll, MissingInfoFromAllThaa, MissingReqInfo} from '../models/missing-info.model';
import {RelatedClaims} from '../models/relatedClaims.model';
import {
  MissingInfoCode, UpdateReceivedDateRequest, UpdateReceivedDateRequestAA,
  UpdateReceivedDateResponse, UpdateReceivedDateResponseAA
} from '../models/updateReceived.model';
import {ClaimHistory} from '../models/claimHistory.model';
import {AppConstants} from '../constants/app.constants';
import {AppLabelConstants} from '../constants/appLabel.constants';
import {AppLeaveIntakeConstants} from '@app/cxr/leave-claim-intake-flow/leave-intake.constants';
import {AetnaApsFormsService} from '@shared/services/aetna-aps-forms.service';
import { StorageManagementService } from '@app/shared/services/storage-management.service';
import { SessionConstants } from '@app/shared/constants/session.constants';
import { EnableExtensionResponse } from '../models/enableExtension.model';
import {IOverpayment, IOverpaymentResponse, IOverpaymentCardResponse, IOverpaymentCardDetails} from '@app/cxr/overpayment/shared/models/overpayment.model';


@Injectable()
export class ClaimServiceService {
  private missingInfoClaimArray: MissingInfoFromAll = new MissingInfoFromAll();
  private missingInfoClaimArrayThaa: MissingInfoFromAllThaa = new MissingInfoFromAllThaa();
  public isHistoryClaim: boolean = false;
  private _destroy$: Subject<void> = new Subject();

  constructor(private errorHandler: ResponseErrorHandlerService,
              private httpClient: HttpClient,
              private aetnaApsFormsService: AetnaApsFormsService,
              private storeManagementService: StorageManagementService) {
  }
  /** This function takes out Linked Claims from relatedClaimList Object**/
  /** Approach : List of List is created in which claim Id and LinkedId with not null values are grouped as Set.
   *  relatedClaims is the List of individual claims grouped with Linked claims. and groupedClaims is the List of relatedClaims
   *  **/
  public createListAAClaims(aaClaims) {
    const groupedClaims = [];
    const tempAAClaims = aaClaims;
    for (let i = 0; i < tempAAClaims.length; i++) {
      const relatedClaims = [];
      relatedClaims.push(tempAAClaims[i].claimEventId);
      tempAAClaims[i].relatedClaims[0].relatedClaimList.forEach(el => {
        Object.keys(el).forEach(key => {
          if (el[key] !== null) {
            relatedClaims.push(el[key]);
          }
        });
      });
      groupedClaims.push(relatedClaims);
    }
    return groupedClaims;
  }
  /** This function creates group out of the List with related Claim id's  and splice tempGroupedClaims with id's
   *  already grouped **/
  public groupAndSortAAClaims(aaClaims) {
    const groupedAAClaims = [];
    const tempGroupedClaims = this.createListAAClaims(aaClaims);
    while (tempGroupedClaims.length > 0) {
      const visitedClaims = new Set();
      const visitedClaimsList = [];
      tempGroupedClaims[0].forEach(c1 => visitedClaims.add(c1));
      for (let k = 0; k < tempGroupedClaims.length;) {
        let recordFound = false;
        for (let l = 0; l < tempGroupedClaims[k].length; l++) {
          if (visitedClaims.has(tempGroupedClaims[k][l])) {
            tempGroupedClaims[k].forEach(c3 => visitedClaims.add(c3));
            recordFound = true;
            break;
          }
        }
        if (recordFound) {
          tempGroupedClaims.splice(k, 1);
          k = 0;
        } else {
          k++;
        }
      }
      /** Fetching claim Object corresponding visitedClaims elements **/
      visitedClaims.forEach(v1 => aaClaims.find(v2 => {
        if (v2.claimEventId === v1) {
          visitedClaimsList.push(v2);
        }
      }));
      groupedAAClaims.push(visitedClaimsList);
    }
    this.sortGroupedRelatedByType(groupedAAClaims);
    this.sortCoverageCategoryById(groupedAAClaims);
    return groupedAAClaims;
  }
  public sortCoverageCategoryById(groupedClaims) {
    for (let i = 0; i < groupedClaims.length; i++) {
      groupedClaims[i].sort((a, b) => a.coverageCategory === b.coverageCategory ? a.claimEventId - b.claimEventId : a );
    }
    return groupedClaims;
  }
  public sortBenefitsForDisplay(benefitsVal) {
    const nonRelated = benefitsVal[0];
    const leaves = benefitsVal[2];
    let groupedRelated = [];
    let concurrentNonRelatedBenefits = [];
    const related = [];
    const groupedAA =  [];
    benefitsVal[1].filter(claims => claims.sourceSystemId === AppConstants.SOURCE_SYS_ID_DCS ? related.push(claims) : groupedAA.push(claims));
    const groupedAAClaims = ((groupedAA && groupedAA.length > 0) ? this.groupAndSortAAClaims(groupedAA) : []);
    if (groupedAAClaims && groupedAAClaims.length > 0 && leaves && leaves.length > 0) {
      groupedAAClaims.forEach(groupedAAClaim => {
        this.addAAConcurrentLeavesToRelated(groupedAAClaim, leaves);
      });
    }
    if (related && related.length > 0) {
      const relatedSortedByDate = this.sortByDate(related);
      groupedRelated = this.defineClaimGroups(relatedSortedByDate);
      this.sortGroupedRelatedByType(groupedRelated);
      if (leaves && leaves.length > 0) {
        for (let i = 0; i < groupedRelated.length; i++) {
          this.addConcurrentLeavesToRelated(groupedRelated[i], leaves);
        }
      }
    }
    if (nonRelated && nonRelated.length > 0 && leaves && leaves.length > 0) {
      concurrentNonRelatedBenefits = this.addConcurrentLeavesToNonRelated(nonRelated, leaves);
    }


    const allSorted = [...groupedRelated, ...groupedAAClaims, ...concurrentNonRelatedBenefits, ...nonRelated, ...leaves];
    this.sortByDate(allSorted);
    if (!allSorted[0]) {
      return;
    }
    return allSorted;
  }

  public addConcurrentLeavesToNonRelated(nonRelated, leaves) {
    const concurrentNonRelatedBenefits = [];
    for ( let i = 0; i < nonRelated.length; i++ ) {
      const claim = nonRelated[i];
      if (claim.hasOwnProperty('concurrentLeaveIDs') && claim.concurrentLeaveIDs && claim.concurrentLeaveIDs.length > 0) {
        for ( let j = 0; j < claim.concurrentLeaveIDs.length; j++ ) {
          const concurrentLeave = leaves.find(el => el.leaveId === claim.concurrentLeaveIDs[j]);
          if (concurrentLeave) {
            const concurrentLeaveIndex = leaves.findIndex(el => el.leaveId === claim.concurrentLeaveIDs[j]);
            concurrentNonRelatedBenefits.push([claim, concurrentLeave]);
            nonRelated.splice(i, 1);
            i--;
            leaves.splice(concurrentLeaveIndex, 1);
          } else if (this.isHistoryClaim && concurrentNonRelatedBenefits.length > 0) {
            for (let k = 0; k < concurrentNonRelatedBenefits.length; k++) {
               const leaveIndex = concurrentNonRelatedBenefits[k].findIndex(e2 => e2.leaveId === claim.concurrentLeaveIDs[j]);
               if (leaveIndex >= 0) {
                 concurrentNonRelatedBenefits[k].push(claim);
                 nonRelated.splice(i, 1);
                 i--;
               }
            }
          }
        }
      }
    }
    return concurrentNonRelatedBenefits;
  }

  public addConcurrentLeavesToRelated(group, leaves) {
    const leavesToAdd = [];
    for (let i = 0; i < group.length; i++) {
      const claim = group[i];
      if (claim.hasOwnProperty('concurrentLeaveIDs') && claim.concurrentLeaveIDs && claim.concurrentLeaveIDs.length > 0) {
        for (let j = 0; j < claim.concurrentLeaveIDs.length; j++) {
          const concurrentLeave = leaves.find(el => el.leaveId === claim.concurrentLeaveIDs[j]);
          if (concurrentLeave) {
            const concurrentLeaveIndex = leaves.findIndex(el => el.leaveId === claim.concurrentLeaveIDs[j]);
            leavesToAdd.push(concurrentLeave);
            leaves.splice(concurrentLeaveIndex, 1);
          }
        }
      }
    }
    leavesToAdd.sort((a, b) => parseInt(a.leaveId, 10) - parseInt(b.leaveId, 10));
    leavesToAdd.forEach((el) => {
      group.push(el);
    });
  }
  /** Add leave concurrent to claim and UI display just after the particular claim **/
  public addAAConcurrentLeavesToRelated(group, leaves) {
    for (let i = 0; i < group.length;) {
      const claim = group[i];
      let leaveFound = false;
      if (claim.hasOwnProperty('concurrentLeaveIDs') && claim.concurrentLeaveIDs.length > 0) {
        for (let j = 0; j < claim.concurrentLeaveIDs.length; j++) {
          const concurrentLeave = leaves.find(el => el.leaveId === claim.concurrentLeaveIDs[j]);
          leaves.find(el => {
            if (el.leaveId === claim.concurrentLeaveIDs[j]) {
              leaveFound = true;
              group.splice(i + 1, 0, el);
            }
          });
          if (concurrentLeave) {
            const concurrentLeaveIndex = leaves.findIndex(e2 => e2.leaveId === claim.concurrentLeaveIDs[j]);
            leaves.splice(concurrentLeaveIndex, 1);
          }
        }
      } if (leaveFound) {
        i = i + 2;
      } else {
        i++;
      }
    }
  }

  public sortGroupedRelatedByType(groupedRelated) {
    const sortingValues = {
      LTD: 10,
      LIFE: 9,
      PW : 9,
      PFL: 8,
      WD: 4,
      NST: 4,
      SUPP: 3,
      STAT: 2,
    };
    for (let i = 0; i < groupedRelated.length; i++) {
      groupedRelated[i].sort((a, b) => {
        const aCategoryValue = sortingValues[a.coverageCategory] ? sortingValues[a.coverageCategory] : 0;
        const bCategoryValue = sortingValues[b.coverageCategory] ? sortingValues[b.coverageCategory] : 0;
        const aTypeValue = a.coverageType && sortingValues[a.coverageType] ? sortingValues[a.coverageType] : 0;
        const bTypeValue = b.coverageType && sortingValues[b.coverageType] ? sortingValues[b.coverageType] : 0;
        return (bCategoryValue + bTypeValue) - (aCategoryValue + aTypeValue);
      });
    }
  }

  public defineClaimGroups(claimsToGroup, groups = []) {
    const date = new Date(claimsToGroup[0].disabilityDate.valueOf());
    date.setDate(date.getDate() - 5);
    const grouped = [];
    const notGrouped = [];
    for (let i = 0; i < claimsToGroup.length; i++) {
      const el = claimsToGroup[i];
      if ( new Date(el.disabilityDate) > date ) {
        //claim is within 5 days of the most recent
        grouped.push(el);
      } else {
        //claim is outside of 5 days
        notGrouped.push(el);
      }
    }
    groups.push(grouped);
    if (notGrouped.length > 0) {
      return this.defineClaimGroups(notGrouped, groups);
    }
    return groups;
  }

  public defineHistoryClaimGroups(claimsToGroup, groups = []) {
    const date = new Date(claimsToGroup[0].disabilityDate);
    const grouped = [];
    const notGrouped = [];
    for (let i = 0; i < claimsToGroup.length; i++) {
      const el = claimsToGroup[i];
      if ( new Date(el.disabilityDate).getTime() === date.getTime() ) {
        grouped.push(el);
      } else {
        notGrouped.push(el);
      }
    }
    groups.push(grouped);
    if (notGrouped.length > 0) {
      return this.defineHistoryClaimGroups(notGrouped, groups);
    }
    return groups;
  }

  public sortByDate(benefits) {
    benefits.sort( function(benefitOne, benefitTwo) {
      if ( benefitOne instanceof Array) {
        benefitOne = benefitOne[0];
      }
      if ( benefitTwo instanceof Array) {
        benefitTwo = benefitTwo[0];
      }
      const dateOne = benefitOne.hasOwnProperty('disabilityDate') ? benefitOne.disabilityDate : benefitOne.leaveStartDate;
      const dateTwo = benefitTwo.hasOwnProperty('disabilityDate') ? benefitTwo.disabilityDate : benefitTwo.leaveStartDate;
      const dateDiff = new Date(dateTwo).getTime() - new Date(dateOne).getTime();
      if ( dateDiff === 0  && benefitOne.hasOwnProperty('leaveId') && benefitTwo.hasOwnProperty('leaveId') ) {
        return parseInt(benefitOne.leaveId, 10) - parseInt(benefitTwo.leaveId, 10);
      }
      return dateDiff;
    });
    return benefits;
  }

  public separateArraysInSingleArray(groups) {
    const singleGroupedArray = [];
    for (let j = 0; j < groups.length; j++) {
      if (groups[j].length > 0) {
        for (let k = 0; k < groups[j].length; k++) {
          singleGroupedArray.push(groups[j][k]);
        }
      }
    }
    return singleGroupedArray;
  }

  public sortHistoryForDisplay(benefits) {
    const disabilityClaims = benefits[0];
    const leaves = benefits[1];
    let concurrentHistoryBenefits = [];
    let historyClaims = [];
    if (disabilityClaims && disabilityClaims.length > 0) {
      const disabilitySortedByDate = this.sortByDate(disabilityClaims);
      historyClaims = this.defineHistoryClaimGroups(disabilitySortedByDate);
      this.sortGroupedRelatedByType(historyClaims);
      historyClaims = this.separateArraysInSingleArray(historyClaims);
      if (historyClaims && historyClaims.length > 0 && leaves && leaves.length > 0) {
        this.isHistoryClaim = true;
        concurrentHistoryBenefits = this.addConcurrentLeavesToNonRelated(historyClaims, leaves);
      }
    }
    const allSorted = [ ...historyClaims, ...concurrentHistoryBenefits,  ...leaves];
    this.sortByDate(allSorted);
    this.isHistoryClaim = false;
    return allSorted;
  }

  public getClaimDetails(user: User): Observable<any> {
    let insuredID = '';
    let personIds = '';

    if (user && user.insuredId) {
      insuredID = '&insuredId=' + user.insuredId;
    }

    if (user && user.personIds && user.personIds.length > 0) {
      user.personIds.forEach((personId: string) => {
        personIds = personIds + '&personId=' + personId;
      });
    }

    return this.httpClient.get(env.gbORCHService.getClaims + insuredID + personIds,
      {
        headers: new HttpHeaders()
          .set(AppLabelConstants.CONTENT_TYPE_KEY, AppLabelConstants.APPLICATION_KEY)
      }).pipe(
      map(
        (response: Response) => {
          if (!this.errorHandler.getClaimsError(response)) {
            return response;
          } else {
            throw response;
          }
        }
      ), catchError((error: any) => this.errorHandler.handleError(error)), );

  }
  public isClaimsHistoryApplicable(historyClaims: ClaimHistory): boolean {
    let enteredInErrorIndex = -1;
    //filtering leaves to hide history button in case of all history leaves having leaveStatus cancelled
    const filteredLeaves = (historyClaims && historyClaims.leaveClaims && historyClaims.leaveClaims.length > 0) ? historyClaims.leaveClaims.filter((leave) => leave.leaveStatus.toUpperCase() !== AppConstants.CANCELLED_LG) : null;
    let doesHistoricalClaimsExist = false;

    if ((historyClaims && historyClaims.disabilityClaims && historyClaims.disabilityClaims.length > 0) || (filteredLeaves && filteredLeaves.length > 0 )) {
      doesHistoricalClaimsExist = true;
      enteredInErrorIndex = historyClaims.disabilityClaims.findIndex((historyClaim: RelatedClaims) => historyClaim.reason && historyClaim.reason.toUpperCase() === AppConstants.ENTERED_IN_ERROR);
    }
    return (enteredInErrorIndex === -1 && doesHistoricalClaimsExist);
  }

  public documentFilter(currentClaim: RelatedClaims) {
    if (currentClaim.missingInfo !== null && currentClaim.sourceSystemId === AppConstants.SOURCE_SYS_ID_DCS) {
      const missingInfoData: MissingInfo[] = currentClaim.missingInfo;
      const listOfMissingInfoDataFromEmployee: MissingInfo[] = missingInfoData.filter((missingInfoByEmployee: MissingInfo) => missingInfoByEmployee.mInfoCode === AppConstants.EMPLOYEE_CD_01);
      this.missingInfoClaimArray.missingInfoDataFromEmployee = listOfMissingInfoDataFromEmployee && listOfMissingInfoDataFromEmployee.length === 1 ? listOfMissingInfoDataFromEmployee[0] : null;
      const listOfMissingInfoDataFromEmployer: MissingInfo[] = missingInfoData.filter((missingInfoByEmployer: MissingInfo) => missingInfoByEmployer.mInfoCode === AppConstants.EMPLOYER_CD_02);
      this.missingInfoClaimArray.missingInfoDataFromEmployer = listOfMissingInfoDataFromEmployer && listOfMissingInfoDataFromEmployer.length === 1 ? listOfMissingInfoDataFromEmployer[0] : null;
      const listOfMissingInfoDataFromDoctor: MissingInfo[] = missingInfoData.filter((missingInfoByDoctor: MissingInfo) => missingInfoByDoctor.mInfoCode === AppConstants.PHYSICIAN_CD_03);
      this.missingInfoClaimArray.missingInfoDataFromDoctor = listOfMissingInfoDataFromDoctor && listOfMissingInfoDataFromDoctor.length === 1 ? listOfMissingInfoDataFromDoctor[0] : null;
    }
    return this.missingInfoClaimArray;
  }

  public documentFilterThaa(currentClaim: RelatedClaims) {

    if ((currentClaim.missingInfo !== null) && (currentClaim.sourceSystemId === AppConstants.SOURCE_SYS_ID_AA)) {
      const missingInfoData = currentClaim.missingInfo;
      const listOfMissingInfoDataFromEmployee = missingInfoData.filter((missingInfoByEmployee: MissingInfo) => missingInfoByEmployee.mInfoCode === AppConstants.EMPLOYEE_CD_10023366);
        this.missingInfoClaimArrayThaa.missingInfoDataFromEmployee = listOfMissingInfoDataFromEmployee && listOfMissingInfoDataFromEmployee.length ? listOfMissingInfoDataFromEmployee : null;
        const listOfMissingInfoDataFromEmployer = missingInfoData.filter((missingInfoByEmployer: MissingInfo) => missingInfoByEmployer.mInfoCode === AppConstants.EMPLOYER_CD_10023364);
        this.missingInfoClaimArrayThaa.missingInfoDataFromEmployer = listOfMissingInfoDataFromEmployer && listOfMissingInfoDataFromEmployer.length  ? listOfMissingInfoDataFromEmployer : null;
        const listOfMissingInfoDataFromDoctor = missingInfoData.filter((missingInfoByDoctor: MissingInfo) => missingInfoByDoctor.mInfoCode === AppConstants.PHYSICIAN_CD_10023362);
        this.missingInfoClaimArrayThaa.missingInfoDataFromDoctor = listOfMissingInfoDataFromDoctor && listOfMissingInfoDataFromDoctor.length  ? listOfMissingInfoDataFromDoctor : null;
      return this.missingInfoClaimArrayThaa;
    }
  }

  public documentFilterThaaLeave(currentClaim: RelatedClaims) {

    if ((currentClaim.missingInfo !== null && currentClaim.personId && currentClaim.personId.length > 0)) {
      const missingInfoData = currentClaim.missingInfo;
      const listOfMissingInfoDataFromEmployee = missingInfoData.filter((missingInfoByEmployee: MissingInfo) => missingInfoByEmployee.mInfoCode === AppConstants.EMPLOYEE_CD_10023366);
      this.missingInfoClaimArrayThaa.missingInfoDataFromEmployee = listOfMissingInfoDataFromEmployee && listOfMissingInfoDataFromEmployee.length ? listOfMissingInfoDataFromEmployee : null;
      const listOfMissingInfoDataFromEmployer = missingInfoData.filter((missingInfoByEmployer: MissingInfo) => missingInfoByEmployer.mInfoCode === AppConstants.EMPLOYER_CD_10023364);
      this.missingInfoClaimArrayThaa.missingInfoDataFromEmployer = listOfMissingInfoDataFromEmployer && listOfMissingInfoDataFromEmployer.length ? listOfMissingInfoDataFromEmployer : null;
      const listOfMissingInfoDataFromDoctor = missingInfoData.filter((missingInfoByDoctor: MissingInfo) => missingInfoByDoctor.mInfoCode === AppConstants.PHYSICIAN_CD_10023362);
      this.missingInfoClaimArrayThaa.missingInfoDataFromDoctor = listOfMissingInfoDataFromDoctor && listOfMissingInfoDataFromDoctor.length  ? listOfMissingInfoDataFromDoctor : null;
      return this.missingInfoClaimArrayThaa;
    }
  }


  public updateReceivedDate(claimEventId: string, receivedDate: string, missingInfoRqstCodeList: string[]): Observable<UpdateReceivedDateResponse> {
    const request: UpdateReceivedDateRequest = new UpdateReceivedDateRequest();
    request.claimEventId = claimEventId;
    request.receivedDate = receivedDate;
    if (missingInfoRqstCodeList.length > 0) {
      request.missingInfoCodeList = [];
      missingInfoRqstCodeList.forEach(
        (missingInfoRqstCode: string) => {
          const missingInfoCode: MissingInfoCode = new MissingInfoCode();
          missingInfoCode.missingInfoRqstCode = missingInfoRqstCode;
          request.missingInfoCodeList.push(missingInfoCode);
        }
      );
    }


    return this.httpClient.post(env.gbClaims.updateReceivedDate, JSON.stringify(request),
      {
        headers: new HttpHeaders()
          .set(AppLabelConstants.CONTENT_TYPE_KEY, AppLabelConstants.APPLICATION_KEY)
      }).pipe(
      map(
        (response: UpdateReceivedDateResponse) => {
          if (!this.errorHandler.isServiceResponseHasError(response)) {
            return response;
          } else {
            throw response;
          }
        }
      ), catchError((error: any) => this.errorHandler.handleError(error)), );
  }

  public updateReceivedMIAA(personId: string, missingInfoRqstCodeList: string[], responseFromXML: string): Observable<UpdateReceivedDateResponseAA> {
    const request: UpdateReceivedDateRequestAA = new UpdateReceivedDateRequestAA();
    request.personId = personId;
    request.docUploadStatus = responseFromXML;
    if (missingInfoRqstCodeList.length > 0) {
      request.alertInstanceId = [];
      missingInfoRqstCodeList.forEach(
        (missingInfoRqstCode: string) => {
          const missingInfoCode: MissingInfoCode = new MissingInfoCode();
          missingInfoCode.missingInfoRqstCode = missingInfoRqstCode;
          request.alertInstanceId.push(missingInfoCode);
        }
      );
    }


    return this.httpClient.post(env.thaaWKABPortalService.thaaUpdateMI, JSON.stringify(request),
      {
        headers: new HttpHeaders()
          .set(AppLabelConstants.CONTENT_TYPE_KEY, AppLabelConstants.APPLICATION_KEY)
      }).pipe(
      map(
        (response: UpdateReceivedDateResponseAA) => {
          if (!this.errorHandler.isServiceResponseHasError(response)) {
            return response;
          } else {
            throw response;
          }
        }
      ), catchError((error: any) => this.errorHandler.handleError(error)), );
  }

  public getClaimEventIdsList(nonRelatedClaims: RelatedClaims[], relatedClaims: RelatedClaims[]): string[] {
    const claimEventIdList: string[] = [];
    for (let i = 0; i < nonRelatedClaims.length; i++) {
      claimEventIdList.push(nonRelatedClaims[i].claimEventId);
    }
    for (let i = 0; i < relatedClaims.length; i++) {
      claimEventIdList.push(relatedClaims[i].claimEventId);
    }
    return claimEventIdList;
  }

  public populateUserClaims(relatedClaims, nonRelatedClaims, insuredLeaves = []): RelatedClaims[] {
    const userClaims: RelatedClaims[] = [...nonRelatedClaims, ...relatedClaims, ...insuredLeaves];
    return userClaims;
  }

  public fetchClaimDetails(personId: string, claimId: string) {
    return this.httpClient.get(env.thaaWKABPortalService.thaaClaimsDetailURL + '&personId=' + personId + '&claimId=' + claimId,
      {
        headers: new HttpHeaders()
          .set(AppLabelConstants.CONTENT_TYPE_KEY, AppLabelConstants.APPLICATION_KEY)
      }).pipe(map(
        (response: Response) => {
          if (!this.errorHandler.isServiceResponseHasError(response)) {
            return response;
          } else {
            throw response;
          }
        }
      ), catchError((error: any) => this.errorHandler.handleErrorThaa(error)), );
  }

  public getClaimReportedReturnToWork(personId: string, claimId: string) {
    return this.httpClient.get(env.thaaWKABPortalService.thaaClaimReportedReturnToWork + '&personId=' + personId + '&claimId=' + claimId,
      {
        headers: new HttpHeaders()
          .set(AppLabelConstants.CONTENT_TYPE_KEY, AppLabelConstants.APPLICATION_KEY)
      }).pipe(map(
      (response: Response) => {
        if (!this.errorHandler.isServiceResponseHasError(response)) {
          return response;
        } else {
          throw response;
        }
      }
    ), catchError((error: any) => this.errorHandler.handleErrorThaa(error)), );
  }

  public getClaimReportedNewExtension(personId: string): Observable<EnableExtensionResponse> {
    const extensionObj = this.storeManagementService.getSessionData(SessionConstants.FT_20211009_F12304_REQUEST_AN_EXTENSION_TOGGLE);
    if (extensionObj) {
        return of(extensionObj);
     } else {
      return this.httpClient.get(env.thaaWKABPortalService.thaaNewExtension + '&personId=' + personId,
      {
        headers: new HttpHeaders()
          .set(AppLabelConstants.CONTENT_TYPE_KEY, AppLabelConstants.APPLICATION_KEY)
      }).pipe(map(
        (response: EnableExtensionResponse) => {
          if (!this.errorHandler.isServiceResponseHasError(response)) {
            this.storeManagementService.setSessionData(SessionConstants.FT_20211009_F12304_REQUEST_AN_EXTENSION_TOGGLE, response);
            return response;
          } else {
            throw response;
          }
        }
      ), catchError((error: any) => this.errorHandler.handleErrorThaa(error)), );
    }
  }

  public getProductFormsToggle(user: User) {
    const personIds = user.personIds[0];
    return this.httpClient.get(env.thaaWKABPortalService.productFormsToggle + '&personId=' + personIds,
      {
        headers: new HttpHeaders()
          .set(AppLabelConstants.CONTENT_TYPE_KEY, AppLabelConstants.APPLICATION_KEY)
      }).pipe(map(
      (response: Response) => {
        if (!this.errorHandler.isServiceResponseHasError(response)) {
          return response;
        } else {
          throw response;
        }
      }
    ), catchError((error: any) => this.errorHandler.handleErrorThaa(error)), );


  }

  /*
  * api to determine if user is eligible for overpayments based on the "IsOverpaymentsEligible" flag returned by the api
  * @param personId
  * */
  public getOverpaymentEligibility(personId) {
    return this.httpClient.get(env.thaaWKABPortalService.overpaymentEligibility + '&personId=' + personId,
      {
        headers: new HttpHeaders()
          .set(AppLabelConstants.CONTENT_TYPE_KEY, AppLabelConstants.APPLICATION_KEY)
      }).pipe(map(
      (response: Response) => {
        if (!this.errorHandler.isServiceResponseHasError(response)) {
          return response;
        } else {
          throw response;
        }
      }
    ), catchError((error: any) => this.errorHandler.handleErrorThaa(error)), );


  }

  public getOverpayments(ids) {
    const wasOverpaymentSubmitted = this.storeManagementService.getSessionData(SessionConstants.PAYMENT_CONFIRMATION_OWCS);
    const overPayments: IOverpayment = this.storeManagementService.getSessionData(SessionConstants.OVERPAYMENTS);
    if (overPayments && !wasOverpaymentSubmitted) {
      return of(overPayments);
    } else {
      let personIds = '';

      if (ids && ids.length > 0) {
        ids.forEach((personId: string) => {
          personIds = personIds + '&personId=' + personId;
        });
      }
      return this.httpClient.get(env.thaaWKABPortalService.overpayments + personIds,
        {
          headers: new HttpHeaders()
            .set(AppLabelConstants.CONTENT_TYPE_KEY, AppLabelConstants.APPLICATION_KEY)
        }).pipe(map(
        (response: IOverpayment) => {
          if (!this.errorHandler.isServiceResponseHasError(response)) {
            this.storeManagementService.setSessionData(SessionConstants.OVERPAYMENTS, response);
            return response;
          } else {
            throw response;
          }
        }
      ), catchError((error: any) => this.errorHandler.handleErrorThaa(error)), );
    }
  }

  public getLibraryForms(user: User) {
    let personIds = ''

    if (user && user.personIds && user.personIds.length > 0) {
      user.personIds.forEach((personId: string) => {
        personIds = personIds + '&personId=' + personId;
      });
    }
    return this.httpClient.get(env.thaaWKABPortalService.getLibraryForms + personIds,
      {
        headers: new HttpHeaders()
          .set(AppLabelConstants.CONTENT_TYPE_KEY, AppLabelConstants.APPLICATION_KEY)
      }).pipe(map(
      (response: Response) => {
        if (!this.errorHandler.isServiceResponseHasError(response)) {
          return response;
        } else {
          throw response;
        }
      }
    ), catchError((error: any) => this.errorHandler.handleErrorThaa(error)), );
  }

  public getProductForm(personId: string, link: string) {
    return this.httpClient.get(env.thaaWKABPortalService.downloadProductForm + '&personId=' + personId + '&link=' + link,
      {
        headers: new HttpHeaders()
          .set(AppLabelConstants.CONTENT_TYPE_KEY, AppLabelConstants.APPLICATION_KEY)
      }).pipe(map(
      (response: Response) => {
        if (!this.errorHandler.isServiceResponseHasError(response)) {
          return response;
        } else {
          throw response;
        }
      }
    ), catchError((error: any) => this.errorHandler.handleErrorThaa(error)), );
  }

  /*
  * fetch paystub pdf base64 code from api, convert base64 output and open pdf in new window
  * common function to fetch, download and open pdf for all pages where download paystub is applicable
  * @param: request - PaymentEobBlob
  * */
  downloadPaystubPdf(request) {
    this.downloadPaystub(request).pipe(takeUntil(this._destroy$)).subscribe(res => {
      if (res && res['LetterBytes'] && res['responseCode'] === AppLeaveIntakeConstants.SUCCESS_CODE_001) {
        this.aetnaApsFormsService.downloadPaystubPDF(request.eobNum, res['LetterBytes']);
      }
    }, (error) => {
      this.errorHandler.handleErrorForSubmit(error);
    });
  }

  /*
  * fetch base64 code from requested paystub
  * @param: request - PaymentEobBlob
  * */
  public downloadPaystub(request) {
    return this.httpClient.post(env.thaaWKABPortalService.downloadPaystub, request,
      {
        headers: new HttpHeaders()
          .set(AppLabelConstants.CONTENT_TYPE_KEY, AppLabelConstants.APPLICATION_KEY)
      }).pipe(map(
      (response: Response) => {
        if (!this.errorHandler.isServiceResponseHasError(response)) {
          return response;
        } else {
          throw response;
        }
      }
    ), catchError((error: any) => this.errorHandler.handleErrorThaa(error)), );
  }

  /*
  * request to fetch Tempus iFrame
  * @param request: { personId, claimNumber, productType, paymentAmountApproved }
  * */
  public fetchIFrameForPayment(request): Observable<IOverpaymentResponse> {
    return this.httpClient.post(env.thaaWKABPortalService.fetchTempusIFrame, request,
      {
        headers: new HttpHeaders()
          .set(AppLabelConstants.CONTENT_TYPE_KEY, AppLabelConstants.APPLICATION_KEY)
      }
    ).pipe(
      map(
      (response: IOverpaymentResponse) => {
          if (!this.errorHandler.isServiceResponseHasError(response)) {
            return response;
          } else {
            throw response;
          }
        }
      ), catchError((error: any) => {
        if (error instanceof HttpErrorResponse) {
          return;
        }
        return this.errorHandler.handleErrorThaa(error);
      }),
    );
  }

/*
  * Submit API for Payment Confirmation
  * @param request: { PersonId, ClaimNumber, TransectionSuccess, CardNumber,
  *                   CardType, Cvv2ResponseCode, Amount, AuthCode }
  * */
  public paymentConfirmationSubmit(auditTaskRequest: IOverpaymentCardDetails): Observable<IOverpaymentCardResponse> {
    return this.httpClient.post(env.thaaWKABPortalService.paymentConfirmationSubmit, auditTaskRequest,
      {
        headers: new HttpHeaders()
          .set(AppLabelConstants.CONTENT_TYPE_KEY, AppLabelConstants.APPLICATION_KEY)
      }
    ).pipe(
      map(
      (response: IOverpaymentCardResponse) => {
          if (!this.errorHandler.isServiceResponseHasError(response)) {
            return response;
          } else {
            throw response;
          }
        }
      ), catchError((error: any) => this.errorHandler.handleErrorThaa(error)),
    );
  }
}

