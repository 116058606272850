import { Injectable } from '@angular/core';
import { ResponseErrorHandlerService } from '@shared/services/response-error-handler.service';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { env } from '@env/environment-loader';
import { AppLabelConstants } from '@shared/constants/appLabel.constants';
import { Observable, of, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { IntakeSessionConstants } from '@app/cxr/modules/intake/shared/constants/intakeSession.constants';
import { StorageManagementService } from '@shared/services/storage-management.service';
import { IAddHealthcareData, IHealthProviderDetail, IHealthProviderInfo, IHealthProviderList, IInsuranceCarrierList, IProviderAPIResponse, IProviderDetails, IProviderInfo, IProviderList, IProviderRequest } from '@app/cxr/modules/intake/shared/models/health-provider.model';
import { IntakeLabelConstants } from '../constants/intake-label.constants';
import { TitleCasePipe } from '@angular/common';
import { INTAKE_ROUTES } from '../constants/Intake-routing.constants';
import { Router } from '@angular/router';


@Injectable()
export class HealthProviderService {
  intakeLabelConst = IntakeLabelConstants;
  constructor(private errorHandler: ResponseErrorHandlerService,
    private storeManagementService: StorageManagementService,
    private httpClient: HttpClient,
    public titleCasePipe: TitleCasePipe,
    private router: Router) { }
  public getHealthProviderDetails(healthProviderInfo: IHealthProviderInfo): Observable<IHealthProviderList> {
    if (healthProviderInfo.personId && healthProviderInfo.firstName && healthProviderInfo.lastName && healthProviderInfo.city && healthProviderInfo.location) {
      const { personId, firstName, lastName, city, location } = healthProviderInfo;
      let httpUrl = `${env.thaaWKABPortalService.getNPIDetails}&personId=${personId}&firstName=${firstName}&lastName=${lastName}&city=${city}&location=${location}`;
      if (healthProviderInfo.zipCode) {
        httpUrl = `${httpUrl}&zipCode=${healthProviderInfo.zipCode}`
      }
      return this.httpClient.get(httpUrl,
        {
          headers: new HttpHeaders()
            .set(AppLabelConstants.CONTENT_TYPE_KEY, AppLabelConstants.APPLICATION_KEY)
        }).pipe(map(
          (response: IHealthProviderList) => {
            if (!this.errorHandler.isServiceResponseHasError(response)) {
              const healthProviderDetails: IHealthProviderList = Object.assign({});
              healthProviderDetails.GetDetail = response.GetDetail;
              healthProviderDetails.count = response.count;
              healthProviderDetails.responseCode = response.responseCode;
              this.storeManagementService.setIntakeSessionData(IntakeSessionConstants.HEALTH_PROVIDER_DETAILS, healthProviderDetails);
              return healthProviderDetails;
            } else {
              throw response;
            }
          }
        ), catchError((error: any) => this.handleError(error)));
    }
  }
  public getInsuranceCarrierApiDetails(personId: string): Observable<IInsuranceCarrierList> {
    const insuranceCarrierList = this.storeManagementService.getIntakeSessionData(IntakeSessionConstants.INSURANCE_CARRIER_LIST);
    if (insuranceCarrierList) {
      return of(insuranceCarrierList);
    } else {
      if (personId) {
        return this.httpClient.get(env.thaaWKABPortalService.intakeHealthcareInsuranceCarrier + '&personId=' + personId,
          {
            headers: new HttpHeaders()
              .set(AppLabelConstants.CONTENT_TYPE_KEY, AppLabelConstants.APPLICATION_KEY)
          }).pipe(map(
            (response: IInsuranceCarrierList) => {
              if (!this.errorHandler.isServiceResponseHasError(response)) {
                this.storeManagementService.setIntakeSessionData(IntakeSessionConstants.INSURANCE_CARRIER_LIST, response);
                return response;
              } else {
                throw response;
              }
            }
          ), catchError((error: any) => this.handleError(error)) , );
      }
    }
  }
  handleError(error: Response) {
    return throwError(error);
  }

   /**
    * store selected/ added healthcare provider to session to display in healthcare provider summary page
    *
    * @param healthProviderRawValues  - provider object from add healthcare provider page / selected provider from healthcare provider info page
    *               - selected state location abbrievated value
    */
   setHealthcareSessionData(healthProviderRawValues: IAddHealthcareData): void {
    let healthcaredataList: IProviderDetails = Object.assign({});
    healthcaredataList.providerDetails = [];
    if (this.storeManagementService.getIntakeSessionData(IntakeSessionConstants.NEW_HEALTHCARE_PROVIDER )) {
      healthcaredataList = this.storeManagementService.getIntakeSessionData(IntakeSessionConstants.NEW_HEALTHCARE_PROVIDER);
    }

    const addHealthCareData: IAddHealthcareData = {
      number      : healthProviderRawValues.number ? healthProviderRawValues.number : null,
      firstName   : healthProviderRawValues.firstName ? this.titleCasePipe.transform(healthProviderRawValues.firstName.trim()) : '',
      lastName    : healthProviderRawValues.lastName ? this.titleCasePipe.transform(healthProviderRawValues.lastName.trim()) : '',
      telephoneNumber : healthProviderRawValues.telephoneNumber ? healthProviderRawValues.telephoneNumber.trim() : '',
      faxNumber   : healthProviderRawValues.faxNumber ? healthProviderRawValues.faxNumber.trim() : '',
      address1    : healthProviderRawValues.address1 ? this.titleCasePipe.transform(healthProviderRawValues.address1.trim()) : '',
      address2    : healthProviderRawValues.address2 ? this.titleCasePipe.transform(healthProviderRawValues.address2.trim()) : '',
      postalCode     : healthProviderRawValues.postalCode,
      city        : healthProviderRawValues.city ? this.titleCasePipe.transform(healthProviderRawValues.city.trim()) : '',
      state       : healthProviderRawValues.state ? healthProviderRawValues.state.toUpperCase() : '',
      speciality  : healthProviderRawValues.speciality ? healthProviderRawValues.speciality.trim() : '',
      email       : healthProviderRawValues.email ? healthProviderRawValues.email.trim() : '',
      Active_IND  : this.intakeLabelConst.NO
    }
    healthcaredataList.providerDetails.push(addHealthCareData);
    let healthcareWithFax: IProviderDetails = Object.assign({});
    healthcareWithFax.providerDetails = [];
    if (this.storeManagementService.getIntakeSessionData(IntakeSessionConstants.HEALTHCARE_WITH_FAX )) {
      healthcareWithFax = this.storeManagementService.getIntakeSessionData(IntakeSessionConstants.HEALTHCARE_WITH_FAX);
    }
    healthcareWithFax.providerDetails.push(addHealthCareData);

    this.removeAttrFromSession();
    this.storeManagementService.setIntakeSessionData(IntakeSessionConstants.NEW_HEALTHCARE_PROVIDER, healthcaredataList);
    this.storeManagementService.setIntakeSessionData(IntakeSessionConstants.HEALTHCARE_WITH_FAX, healthcareWithFax);

  }
  removeAttrFromSession(): void {
   if (this.storeManagementService.getIntakeSessionData(IntakeSessionConstants.NAVIGATE_WITHOUT_ADD_PROVIDER)) {
      this.storeManagementService.removeAttrFromIntakeSessionData(IntakeSessionConstants.NAVIGATE_WITHOUT_ADD_PROVIDER);
     }
  }

  public fetchProviderInfo(request: IProviderRequest): Observable<IProviderAPIResponse> {
    return this.httpClient.post(env.thaaWKABPortalService.fetchProviderInfo, request,
      {
        headers: new HttpHeaders()
          .set(AppLabelConstants.CONTENT_TYPE_KEY, AppLabelConstants.APPLICATION_KEY)
      }).pipe(map(
      (response: IProviderAPIResponse) => {
        if (!this.errorHandler.isServiceResponseHasError(response)) {
          return response;
        } else {
          throw response;
        }
      }
    ), catchError((error: any) => this.errorHandle(error)), );
  }
  errorHandle(error: Response) {
    this.router.navigate([INTAKE_ROUTES.RELEASE_RECORDS_AUTH]);
    return throwError(error);
  }
  getProviderRequest(providerData: IProviderDetails): IProviderList {
    let providerRequest: IProviderList = Object.assign({});
    providerRequest.providerInfo = [];
    providerData.providerDetails.forEach(provider=>{
      providerRequest.providerInfo.push({
        npiId: provider.number? Number(provider.number): 0,
        firstName: provider.firstName,
        lastName: provider.lastName,
        phoneNumber: provider.telephoneNumber? provider.telephoneNumber.replace(/\D+/g, ''): '',
        fax: provider.faxNumber? provider.faxNumber.replace(/\D+/g, ''): '',
        address: provider.address1,
        addressLineTwo: provider.address2,
        zip: provider.postalCode,
        city: provider.city,
        statecode: provider.state,
        specialityId: provider.speciality? Number(provider.speciality): 0,
        email: provider.email? provider.email: ""
      });
    });
    return providerRequest;
  }

}
