import {Component, OnInit, Input, OnChanges, SimpleChanges, Output, EventEmitter, OnDestroy, AfterViewInit} from '@angular/core';
import {AppLeaveIntakeConstants} from '../leave-intake.constants';
import {FormGroup, FormControl, Validators, AbstractControl} from '@angular/forms';
import {StorageManagementService} from '../../../shared/services/storage-management.service';
import {ModalService} from '../../../shared/services/modal.service';
import { CustomEventService } from 'app/shared/services/tealium/custom-event.service';
import {Router} from '@angular/router';

/**
 * Reduce Schedule Grid Component
 */
@Component({
  selector: 'app-reduce-schedule-grid',
  templateUrl: './reduce-schedule-grid.component.html',
  styleUrls: ['./reduce-schedule-grid.component.scss']
})
export class ReduceScheduleGridComponent implements OnInit, AfterViewInit, OnChanges, OnDestroy {
  @Input() public fda: string;
  @Input() public lda: string;
  @Input() public showPrefillQue: boolean;
  @Input() workSchedule;
  @Input() checkLoading;
  @Output() reduceOutput = new EventEmitter();
  @Output() reducedData = new EventEmitter();
  @Output() radioValueExtEmit = new EventEmitter();
  @Output() preFilHourMinAnswer = new EventEmitter();
  public reduceScheduleForm: FormGroup;
  public workScheduledates = new Array();
  public preFillHourMin = new Array();
  public appConstant = AppLeaveIntakeConstants;
  public dateVal;
  public monthVal;
  public accordWeek_date = new Array();
  public scheduledWorkHoursOptions = [];
  public scheduledWorkMinutesOptions = [];
  private gridSubscriber;
  public prefillConfirmVal = [];
  public showPlannedWork: boolean = false;
  public showWarningAtSucessPage = false;
  public disableMinPrefill: boolean = false;
  public prefillRequired: boolean = false;
  public prefillError: boolean = false;
  public prefillQuesChecked: boolean = false;
  public nonWorkDays: string;
  public btnEventId: string = '';
  public addingWorkTime: boolean = false;
  /**
   * @param storageManagementService
   * @param modalService
   */
  constructor(private storageManagementService: StorageManagementService,
              private modalService: ModalService, private customEventService: CustomEventService,
              private router: Router) {}

  ngOnInit() {
    if ( this.fda && this.fda.length > 0 && this.lda && this.lda.length > 0) {
      this.getDateRange(this.fda, this.lda, this.workSchedule);
    }
    for (let i = 0; i <= 24; i++) {
      this.scheduledWorkHoursOptions[i] =  i === 1 ?  `${i}` + ' hour' :  `${i}` + ' hours';
    }
    for (let i = 0; i < 60; i++) {
      this.scheduledWorkMinutesOptions[i] = (`${i}` + ' min');
    }
    this.reduceScheduleForm.addControl('prefillQues', new FormControl());
    this.reduceScheduleForm.addControl('prefillMins', new FormControl(''));
    this.reduceScheduleForm.addControl('prefillHours', new FormControl(''));
    // this.reduceScheduleForm.addControl('isWorkDay', new FormControl(''));
    this.gridSubscriber = this.storageManagementService.gridValidation.subscribe(event => {
      if (event) {
        this.validateReducedSchedule();
      }
    });
    this.prefillConfirmVal = [AppLeaveIntakeConstants.YES_LABEL, AppLeaveIntakeConstants.NO_LABEL];
  }
  ngAfterViewInit() {
    if (sessionStorage.getItem('workScheduledates')) {
      this.workScheduledates = JSON.parse(sessionStorage.getItem('workScheduledates'));
    }
    this.prefillValues();
  }

  prefillValues() {
    const reduceValues = sessionStorage.getItem('reduceScheduleForm');
    if (reduceValues) {
      this.applyReduceValues(this.reduceScheduleForm, JSON.parse(reduceValues));
      this.selectedVal(this.reduceScheduleForm.controls['prefillQues'].value, 'prefillQues');
      this.setRadioVal(this.reduceScheduleForm.controls['prefillQues'].value);
    }
    setTimeout(() => {
      this.reduceScheduleForm.valueChanges.subscribe(reduceScheduleForm => {
        sessionStorage.setItem('reduceScheduleForm', JSON.stringify(reduceScheduleForm))
      })
    }, 0 );
  }
  applyReduceValues(group, reduceValues) {
    Object.keys(reduceValues).forEach(key => {
      const formControl = <FormControl>group.controls[key];

      if (formControl instanceof FormGroup) {
        this.applyReduceValues(formControl, reduceValues[key]);
      } else {
        if (formControl) {
          formControl.setValue(reduceValues[key]);
        }
      }
    });
  }
  /**
   * To get first day of absence and last day of absence on calendar change
   *
   * @param changes
   */
  ngOnChanges(changes: SimpleChanges) {
    if (changes['fda'] && changes['fda'].currentValue && changes['lda'] && changes['lda'].currentValue) {
      if ( this.fda && this.fda.length > 0 && this.lda && this.lda.length > 0) {
        this.getDateRange(this.fda, this.lda, this.workSchedule);
      }
    }
  }

  /**
   * needed if child gets re-created (eg on some model changes)
   * note that subsequent subscriptions on the same subject will fail
   * so the parent has to re-create parentSubject on changes
   */
  ngOnDestroy() {
    this.gridSubscriber.unsubscribe();
    this.storageManagementService.removeAttrFromSessionData('preserveModalData');
  }

  /**
   * to create different weeks adn push the dates as per the week of the month
   *
   * @param startDate
   * @param EndDate
   * @param workScheduleData
   * @returns
   */
  getDateRange(startDate, EndDate, workScheduleData) {
    this.workScheduledates = [];
    this.accordWeek_date = [];
    const start_dateVal = this.fda;
    const end_dateVal = this.lda;
    const start_date = new Date(startDate);
    const end_date     = new Date(EndDate);
    const getTimeDiff = Math.abs(start_date.getTime() - end_date.getTime());
    const date_range = this.fda === this.lda ? 1 : Math.ceil(getTimeDiff / (1000 * 3600 * 24));
    const weekday = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];
    const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
    let accordWeekStartDateValue = start_dateVal;
    let accordWeekEndDateValue = end_dateVal;
    let rsd_date;
    let scheduleHoursValue;
    let isWorkDayValue;
    let isHolidayValue;
    let isShutdownValue;
    let isConsecutiveShutdownValue;
    this.reduceScheduleForm = new FormGroup({
      ['reducedWorkHours00']: new FormControl('')
    });
    const workscheduleResponse = workScheduleData;
    for (let i = 0; i < date_range; i++) {
      if (start_date.getDate() < 10) {
        this.dateVal = ('0' + start_date.getDate());
      } else {
        this.dateVal = start_date.getDate();
      }
      if ((start_date.getMonth() + 1) <= 9) {
        this.monthVal = ('0' + (start_date.getMonth() + 1));
      } else {
        this.monthVal = start_date.getMonth() + 1;
      }
      const dateVal = this.monthVal + '/' + this.dateVal + '/' + start_date.getFullYear();
      scheduleHoursValue = { hours : 0, min : 0};
      isWorkDayValue = true;
      let averageHours = 0;
      let averageMins = 0;
      let totalWorkDays = 0;
      if (workscheduleResponse && workscheduleResponse.LoadWorkScheduleList && workscheduleResponse.LoadWorkScheduleList.length > 0) {
        for (let j = 0; j < workscheduleResponse.LoadWorkScheduleList.length; j++) {
          if (workscheduleResponse.LoadWorkScheduleList[j].IsWorkDay && !workscheduleResponse.LoadWorkScheduleList[j].IsHoliday) {
            averageHours = averageHours + parseInt((workscheduleResponse.LoadWorkScheduleList[j].ScheduledMinutes / 60).toString(), 10);
            averageMins = averageMins + (workscheduleResponse.LoadWorkScheduleList[j].ScheduledMinutes % 60);
            totalWorkDays = totalWorkDays + 1;
          }
        }
        averageHours = averageHours / totalWorkDays;
        averageMins = averageMins / totalWorkDays;
      }
      if (workscheduleResponse && workscheduleResponse.LoadWorkScheduleList && workscheduleResponse.LoadWorkScheduleList.length > 0) {
        for (let k = 0; k < workscheduleResponse.LoadWorkScheduleList.length; k++) {
          const workSchedule = workscheduleResponse.LoadWorkScheduleList[k];
          if (new Date(dateVal).getTime() === new Date(workSchedule.Day).getTime()) {
            isWorkDayValue = workSchedule.IsWorkDay;
            isHolidayValue = workSchedule.IsHoliday;
            scheduleHoursValue = {
                hours: isWorkDayValue && !isHolidayValue ? parseInt((workSchedule.ScheduledMinutes / 60).toString(), 10) : averageHours,
                min: isWorkDayValue && !isHolidayValue ? (workSchedule.ScheduledMinutes % 60) : averageMins,
              };
            isShutdownValue = workSchedule.IsShutdown;
            isConsecutiveShutdownValue = workSchedule.IsConsecutiveShutdown;
            break;
          } else {
            scheduleHoursValue = { hours : 0, min : 0};
          }
        }
      }

      if (start_date.getDay() === 0) {
        this.accordWeek_date = [];
        accordWeekStartDateValue = this.monthVal + '/' + this.dateVal + '/' + start_date.getFullYear();
        rsd_date = {
          dispDate:  weekday[start_date.getDay()] + ' ' + months[start_date.getMonth()] + ' ' + this.dateVal +  ', ' + start_date.getFullYear(),
          scheduleHours: scheduleHoursValue,
          hourNotWorked: '0 hours',
          hourWorked: '00',
          hourWorkedInMin: '00',
          exceededTimeSelected: false,
          erroMsg: AppLeaveIntakeConstants.PLEASE_ANS,
          errorOccured: false,
          isWorkDay: isWorkDayValue,
          isHoliday: isHolidayValue,
          isShutdown: isShutdownValue,
          isConsecutiveShutdown: isConsecutiveShutdownValue,
          isNonWorkDay: false,
          dateValue: (start_date.getMonth() + 1 + '/' + this.dateVal + '/' + start_date.getFullYear())
        };
        this.accordWeek_date.push(rsd_date);
        start_date.setDate(start_date.getDate() + 1);
      } else if (start_date.getDay() === 6) {
        accordWeekEndDateValue = this.monthVal + '/' + this.dateVal + '/' + start_date.getFullYear();
        rsd_date = {
          dispDate:  weekday[start_date.getDay()] + ' ' + months[start_date.getMonth()] + ' ' + this.dateVal +  ', ' + start_date.getFullYear(),
          scheduleHours: scheduleHoursValue,
          hourNotWorked: '0 hours',
          hourWorked: '00',
          hourWorkedInMin: '00',
          exceededTimeSelected: false,
          erroMsg: AppLeaveIntakeConstants.PLEASE_ANS,
          errorOccured: false,
          isWorkDay: isWorkDayValue,
          isHoliday: isHolidayValue,
          isShutdown: isShutdownValue,
          isConsecutiveShutdown: isConsecutiveShutdownValue,
          isNonWorkDay: false,
          dateValue: (start_date.getMonth() + 1 + '/' + this.dateVal + '/' + start_date.getFullYear())
        };
        this.accordWeek_date.push(rsd_date);
        this.workScheduledates.push({
          accordWeekStartDate: accordWeekStartDateValue,
          accordWeekEndDate: accordWeekEndDateValue,
          plannedWork: '0 hours',
          accordWeek_date: this.accordWeek_date,
          accordian_state: (this.workScheduledates && this.workScheduledates.length === 0) ? true : false
        });
        start_date.setDate(start_date.getDate() + 1);
        if (i === date_range - 1) {
          this.accordWeek_date = [];
        }
      } else {
        rsd_date = {
          dispDate: weekday[start_date.getDay()] + ' ' + months[start_date.getMonth()] + ' ' + this.dateVal +  ', ' + start_date.getFullYear(),
          scheduleHours: scheduleHoursValue,
          hourNotWorked: '0 hours',
          hourWorked: '00',
          hourWorkedInMin: '00',
          exceededTimeSelected: false,
          erroMsg: AppLeaveIntakeConstants.PLEASE_ANS,
          errorOccured: false,
          isWorkDay: isWorkDayValue,
          isHoliday: isHolidayValue,
          isShutdown: isShutdownValue,
          isConsecutiveShutdown: isConsecutiveShutdownValue,
          isNonWorkDay: false,
          dateValue: (start_date.getMonth() + 1 + '/' + this.dateVal + '/' + start_date.getFullYear())
        };
        this.accordWeek_date.push(rsd_date);
        start_date.setDate(start_date.getDate() + 1);
      }
    }
    if (this.accordWeek_date && this.accordWeek_date.length > 0) {
      this.checkforSingleweek(this.accordWeek_date);
    }
    if (this.workScheduledates) {
      for (let k = 0; k < this.workScheduledates.length; k++) {
        for (let l = 0; l < this.workScheduledates[k].accordWeek_date.length; l++) {
          const controlIdHrs = 'reducedWorkHours' + k + l;
          const controlIdMins = 'reducedWorkMin' + k + l;
          const controlIsWorkDay = 'isWorkDay' + k + l;
          const controlIsHoliday = 'isHoliday' + k + l;
          const controlIsShutdown = 'isShutdown' + k + l;
          const controlIsConsecutiveShutdown = 'isConsecutiveShutdown' + k + l;
          const isWorkDayVal = this.workScheduledates[k].accordWeek_date[l].isWorkDay;
          const isHolidayVal = this.workScheduledates[k].accordWeek_date[l].isHoliday;
          const isConsecutiveShutdownVal = this.workScheduledates[k].accordWeek_date[l].isConsecutiveShutdown;
          const isShutdownVal = this.workScheduledates[k].accordWeek_date[l].isShutdown;


          this.createFormControle(controlIdHrs, controlIdMins, controlIsWorkDay, isWorkDayVal, controlIsHoliday, isHolidayVal, controlIsShutdown, isShutdownVal, controlIsConsecutiveShutdown, isConsecutiveShutdownVal );
        }
      }
    }
    return this.workScheduledates;
  }

  /**
   * this will check if blur event occured or not on Radio btns
   *
   * @param event
   */
  radioBlur(event) {
    // check if user is on radio button when he/she use tab to move on Radio buttons
    if ((!this.reduceScheduleForm.controls['prefillQues'].valid || this.reduceScheduleForm.controls['prefillQues'].value === '') && event === 'blur') {
      this.reduceScheduleForm.controls['prefillQues'].setValue('');
      this.reduceScheduleForm.controls['prefillQues'].markAsTouched();
    } else {
      this.reduceScheduleForm.controls['prefillQues'].markAsUntouched();
    }
  }

  selectedVal(event, quesId) {
    this.reduceScheduleForm.controls[quesId].setValue(event);
  };

  /**
   * check if selection is Yes or No
   *
   * @param radioVal
   */
  setRadioVal(radioVal) {
    this.reduceScheduleForm.controls['prefillQues'].setValue(radioVal);
    if (radioVal === 'Yes') {
      this.disableMinPrefill = false;
      if (this.showPopup() && this.prefillQuesChecked ) {
        this.modalService.openModal('prefillModal');
      } else {
        this.showPlannedWork = true;
        if (this.addingWorkTime) {
          this.addWorkTime('', '', '', '');
        }
      }
    } else {
      this.showPlannedWork = false;
      this.prefillRequired = false;
      this.reduceScheduleForm.controls['prefillMins'].reset('');
      this.reduceScheduleForm.controls['prefillHours'].reset('');
      this.reduceScheduleForm.controls['prefillMins'].setErrors(null);
      this.reduceScheduleForm.controls['prefillHours'].setErrors(null);
      if (this.addingWorkTime) {
        this.addWorkTime('', '', '', '');
      }
    }
    this.prefillQuesChecked = true;
    this.btnEventId = this.appConstant.PREFILL_QUE_TEXT;
    this.customEventService.trackRadioButton(this.btnEventId, radioVal);
    this.addingWorkTime = true;
    this.radioValueExtEmit.emit(radioVal);
  }

  /**
   * Call on focus to remove error when user is on prefill mins or hours dropdown
   */
  removePrefillError() {
    this.prefillRequired = false;
  }

  /**
   * called on blur of prefill dropdowns to check if error is there or not
   */
  getErrorForPrefill() {
    this.prefillRequired = this.prefillError;
  }

  /**
   * called on click of dropdown to get the values and update grid hours-mins
   *
   * @param dropVal
   * @param formControl
   */
  getPrefillValue(dropVal, formControl) {
    const eventVal = dropVal.trim();
    this.reduceScheduleForm.controls[formControl].setValue(eventVal);
    let mins = (this.reduceScheduleForm.controls['prefillMins'].value !== '') ? this.reduceScheduleForm.controls['prefillMins'].value.split(' ', 2)[0] : '';
    const hrs = (this.reduceScheduleForm.controls['prefillHours'].value !== '') ? this.reduceScheduleForm.controls['prefillHours'].value.split(' ', 2)[0] : '';
    if (hrs === '24') {
      this.disableMinPrefill = true;
      this.reduceScheduleForm.controls['prefillMins'].setValue('0 min');
      mins = '0';
    } else {
      if (this.disableMinPrefill) {
        this.reduceScheduleForm.controls['prefillMins'].setValue('min');
        mins = 'min';
      }
      this.disableMinPrefill = false;
    }
    if (mins && mins.length > 0 && mins !== 'min' && hrs && hrs.length > 0 && hrs !== 'hours') {
      this.prefillError = false;
      this.addWorkTime(this.reduceScheduleForm.controls['prefillHours'].value, this.reduceScheduleForm.controls['prefillMins'].value, hrs, mins);
      this.preFillHourMin.push(hrs);
      this.preFillHourMin.push(mins);
      this.preFilHourMinAnswer.emit(this.preFillHourMin);
      this.preFillHourMin = [];
    } else {
      this.prefillError = true;
      this.addWorkTime('', '', '', '');
    }
  }

  /**
   * called when user has selected some value in prefill to updated hours - mins in grid
   *
   * @param hours
   * @param minute
   * @param hrsVal
   * @param minVal
   */
  addWorkTime(hours, minute, hrsVal, minVal) {
    Object.keys(this.reduceScheduleForm.controls).forEach(key => {
      if (key.includes('reducedWorkHours')) {
        this.reduceScheduleForm.get(key).reset(hours);
      } else if (key.includes('reducedWorkMin')) {
        this.reduceScheduleForm.get(key).reset(minute);
      }
      if (((key.includes('isWorkDay') && !this.reduceScheduleForm.controls[key].value) ||
          ((key.includes('isHoliday') || key.includes('isShutdown') || key.includes('isConsecutiveshutdown'))
            && this.reduceScheduleForm.controls[key].value)) && hours !== '' && minute !== '') {
        const resetHours = '0 hours';
        const resetMin = '0 min';
        let keyId = key.slice(9); //key.substring(key.length - 2);
        if (key.includes('isShutdown')) {
          keyId = key.slice(10);
        } else if (key.includes('isConsecutiveshutdown')) {
          keyId = key.slice(21);
        }
        const reducedWorkHoursKey = 'reducedWorkHours' + keyId;
        const reducedWorkMinKey = 'reducedWorkMin' + keyId;
        this.reduceScheduleForm.controls[reducedWorkHoursKey].setValue(resetHours);
        this.reduceScheduleForm.controls[reducedWorkMinKey].setValue(resetMin);
      }
    });
    this.updateHoursNotWorked(hrsVal, minVal);
  }

  /**
   * this will sun up all the hours and minutes to get planned hours for accordion
   *
   * @param hrs
   * @param min
   */
  updateHoursNotWorked(hrs, min) {
    this.workScheduledates.forEach((i) => {
      let plannedWorkHrs = 0;
      i.accordWeek_date.forEach((j) => {
        if (j.isWorkDay && !j.isHoliday && !j.isShutdown && !j.isConsecutiveShutdown) {
          if (hrs !== '' && min !== '') {
            const timeTaken = (Number(hrs) * 60) + Number(min);
            plannedWorkHrs += timeTaken;
            const schTime = j.scheduleHours;
            const scheduleTimeMin = Number(schTime.hours) * 60 + Number (schTime.min);
            const timeDiff = scheduleTimeMin - timeTaken;
            j.minutesNotWorked = timeDiff;
            j.isRequired = false;
            if (timeDiff >= 0) {
              if (timeDiff > 0 && timeDiff < 60) {
                j.hourNotWorked = '0 hours ' + timeDiff + ' min';
                j.exceededTimeSelected = false;
              } else {
                const remainingMins =  timeDiff % 60;
                const remainingHrs = (timeDiff - remainingMins) / 60;
                j.hourNotWorked = (remainingHrs !== 1 ? remainingHrs.toString() + ' hours ' : remainingHrs.toString() + ' hour ') + (remainingMins > 0 ? remainingMins + ' min' : '');
                j.exceededTimeSelected = false;
              }
            } else if (timeDiff < 0) {
              j.hourNotWorked = '0 hours';
              j.exceededTimeSelected = true;
            }
            j.hourWorked = ((((Number(hrs) && Number(hrs) < 9)) ? ('0' + Number(hrs)) : Number(hrs)) + ':' + ((Number(min) && Number(min) < 9) ? ('0' + Number(min)) : Number(min))) ;
            j.hourWorkedInMin = timeTaken
          } else {
            j.minutesNotWorked = 0;
            j.hourNotWorked = '0 hours';
            j.exceededTimeSelected = false;
            j.erroMsg = AppLeaveIntakeConstants.PLEASE_ANS;
            j.isRequired = false;
            j.hourWorked = '00:00';
            j.hourWorkedInMin = '00';
          }
        } else {
          j.hourNotWorked = '0 hours';
          j.exceededTimeSelected = false;
        }
        if ((j.isHoliday || j.isShutdown || j.isConsecutiveShutdown) && (hrs !== '' && min !== '') && (j.scheduleHours.hours !== 0 || j.scheduleHours.min !== 0)) {
          j.isNonWorkDay = true;
        } else {
          j.isNonWorkDay = false;
        }
        if (j.isHoliday) {
          j.hourNotWorked = '0 hours';
        }
      });
      // this will update planned work hours
      if (plannedWorkHrs >= 0) {
        if (plannedWorkHrs > 0 && plannedWorkHrs < 60) {
          i.plannedWork = '0 hours ' + plannedWorkHrs + ' min';
        } else {
          const remainingMins =  plannedWorkHrs % 60;
          const remainingHrs = (plannedWorkHrs - remainingMins) / 60;
          i.plannedWork = (remainingHrs !== 1 ? remainingHrs.toString() + ' hours ' : remainingHrs.toString() + ' hour ') + (remainingMins > 0 ? remainingMins + ' min' : '');
        }
      } else {
        i.plannedWork = '0 hours';
      }
    });
    const workScheduledates: string = JSON.stringify(this.workScheduledates)
    sessionStorage.setItem('workScheduledates', workScheduledates);
  }
  /**
   * to create control fields for hours and minutes
   *
   * @param controlId1
   * @param controlId2
   */
  createFormControle(controlId1, controlId2, controleId3, isWorkDayVal, controlId4, isHolidayVal, controlId5, isShutdown, controlId6, isConsecutiveShutdown) {
    this.reduceScheduleForm.addControl(controlId1, new FormControl(''));
    this.reduceScheduleForm.addControl(controlId2, new FormControl(''));
    this.reduceScheduleForm.addControl(controleId3, new FormControl(''));
    this.reduceScheduleForm.addControl(controlId4, new FormControl(''));
    this.reduceScheduleForm.addControl(controlId5, new FormControl(''));
    this.reduceScheduleForm.addControl(controlId6, new FormControl(''));
    if (controlId1 && controlId2) {
      this.reduceScheduleForm.controls[controlId1].setErrors({required: true});
      this.reduceScheduleForm.controls[controlId2].setErrors({required: true});
    }
    if (isWorkDayVal) {
      this.reduceScheduleForm.controls[controleId3].setValue(isWorkDayVal);
    } else {
      this.reduceScheduleForm.controls[controleId3].setValue(false);
    }
    this.reduceScheduleForm.controls[controlId4].setValue(isHolidayVal);
    this.reduceScheduleForm.controls[controlId5].setValue(isShutdown);
    this.reduceScheduleForm.controls[controlId6].setValue(isConsecutiveShutdown);
  }

  /**
   * when there is only single week this will push the week to the main array
   *
   * @param remaingDays
   */
  checkforSingleweek(remaingDays) {
    if (remaingDays && remaingDays.length <= 6) {
      const sMonth = new Date(remaingDays[0].dispDate).getMonth() + 1 ;
      const eMonth =  new Date(remaingDays[remaingDays.length - 1].dispDate).getMonth() + 1 ;
      this.workScheduledates.push({
        accordWeekStartDate: ( sMonth < 10 ? '0' + sMonth : sMonth ) + '/' + (new Date(remaingDays[0].dispDate).getDate() < 10 ? '0' + new Date(remaingDays[0].dispDate).getDate() : new Date(remaingDays[0].dispDate).getDate()) + '/' + new Date(remaingDays[0].dispDate).getFullYear(),
        accordWeekEndDate:  ( eMonth < 10 ? '0' + eMonth : eMonth ) + '/' + (new Date(remaingDays[remaingDays.length - 1].dispDate).getDate() < 10 ? '0' + new Date(remaingDays[remaingDays.length - 1].dispDate).getDate() : new Date(remaingDays[remaingDays.length - 1].dispDate).getDate()) + '/' + new Date(remaingDays[remaingDays.length - 1].dispDate).getFullYear(),
        accordWeek_date: remaingDays,
        plannedWork: '0 hours',
        accordian_state: false
      });
    }
  }

  /**
   * Called on focus of dropdowns of grid to remove error when on same field
   *
   * @param i
   * @param j
   */
  removeError(i, j) {
    this.workScheduledates[i].accordWeek_date[j].isRequired = false;
  }

  /**
   * called on blur of grid dropdowns to show errors
   *
   * @param i
   * @param j
   */
  getDropdownError(i, j) {
    this.workScheduledates[i].accordWeek_date[j].isRequired = this.workScheduledates[i].accordWeek_date[j].errorOccured;
    if (this.workScheduledates[i].accordWeek_date[j].isRequired || ((!this.reduceScheduleForm.controls['reducedWorkHours' + i + j].valid && this.reduceScheduleForm.controls['reducedWorkHours' + i + j].touched)
        && (!this.reduceScheduleForm.controls['reducedWorkMin' + i + j].valid && this.reduceScheduleForm.controls['reducedWorkMin' + i + j].touched))) {
      this.customEventService.trackFormValidationError(this.appConstant.PLEASE_ANS);
    }
    // sessionStorage.setItem('workScheduledates', JSON.stringify(this.workScheduledates));
  }
  /**
   * to get the dropdown value, calculate time not worked, show error message when not selected any time
   *
   * @param event
   * @param i
   * @param j
   * @param formControl
   */
  getDropdownValue(event, i, j, formControl) {
    const eventVal = ((event.trim() && (event.trim() === 'hours' || event.trim() === 'min')) ? '' : event.trim());
    this.reduceScheduleForm.controls[formControl].setValue(eventVal);
    let mins = (this.reduceScheduleForm.controls['reducedWorkMin' + i + j].value !== '' && this.reduceScheduleForm.controls['reducedWorkMin' + i + j].value !== 'min') ? this.reduceScheduleForm.controls['reducedWorkMin' + i + j].value.split(' ', 2)[0] : '';
    const hrs = (this.reduceScheduleForm.controls['reducedWorkHours' + i + j].value !== '' && this.reduceScheduleForm.controls['reducedWorkHours' + i + j].value !== 'hours') ? this.reduceScheduleForm.controls['reducedWorkHours' + i + j].value.split(' ', 2)[0] : '';
    if (hrs === '24' && formControl === 'reducedWorkHours' + i + j) {
      this.workScheduledates[i].accordWeek_date[j].disableMinutes = true;
      this.reduceScheduleForm.controls['reducedWorkMin' + i + j].setValue('0 min');
      mins = '0';
    } else {
      if (this.workScheduledates[i].accordWeek_date[j].disableMinutes) {
        this.reduceScheduleForm.controls['reducedWorkMin' + i + j].setValue('min');
        mins = 'min';
      }
      this.workScheduledates[i].accordWeek_date[j].disableMinutes = false;
    }
    this.workScheduledates[i].accordWeek_date[j].hourNotWorked = '0 hours';
    this.workScheduledates[i].accordWeek_date[j].exceededTimeSelected = false;
    if (mins && mins.length > 0 && mins !== 'min' && hrs && hrs.length > 0 && hrs !== 'hours') {
      this.workScheduledates[i].accordWeek_date[j].errorOccured = false;
      const timeTaken = (Number(hrs) * 60) + Number(mins);
      const schTime = (this.workScheduledates[i].accordWeek_date[j].scheduleHours);
      const scheduleTimeMin = Number(schTime.hours) * 60 + Number (schTime.min);
      const timeDiff = scheduleTimeMin - timeTaken;
      this.workScheduledates[i].accordWeek_date[j].minutesNotWorked = timeDiff;
      if (timeDiff >= 0) {
        if (timeDiff > 0 && timeDiff < 60) {
          this.workScheduledates[i].accordWeek_date[j].hourNotWorked = '0 hours ' + timeDiff + ' min';
          this.workScheduledates[i].accordWeek_date[j].exceededTimeSelected = false;
        } else {
          const remainingMins =  timeDiff % 60;
          const remainingHrs = (timeDiff - remainingMins) / 60;
          this.workScheduledates[i].accordWeek_date[j].hourNotWorked = (remainingHrs !== 1 ? remainingHrs.toString() + ' hours ' : remainingHrs.toString() + ' hour ') + (remainingMins > 0 ? remainingMins + ' min' : '');
          this.workScheduledates[i].accordWeek_date[j].exceededTimeSelected = false;
        }
      } else if (timeDiff < 0) {
        this.workScheduledates[i].accordWeek_date[j].hourNotWorked = '0 hours';
        this.workScheduledates[i].accordWeek_date[j].exceededTimeSelected = true;
      }
      if ((this.workScheduledates[i].accordWeek_date[j].isHoliday || this.workScheduledates[i].accordWeek_date[j].isShutdown || this.workScheduledates[i].accordWeek_date[j].isConsecutiveShutdown || !this.workScheduledates[i].accordWeek_date[j].isWorkDay)) {
        if ((hrs >= '0' && mins >= '0')) {
          this.workScheduledates[i].accordWeek_date[j].isNonWorkDay = false;
        } else {
          this.workScheduledates[i].accordWeek_date[j].isNonWorkDay = true;
        }
      }
      this.workScheduledates[i].accordWeek_date[j].hourWorkedInMin = timeTaken;
      this.calculatePlannedWork(i, j);
    }  else {
      this.workScheduledates[i].accordWeek_date[j].errorOccured = true;
    }
    if (this.workScheduledates[i].accordWeek_date[j].isRequired || ((!this.reduceScheduleForm.controls['reducedWorkHours' + i + j].valid && this.reduceScheduleForm.controls['reducedWorkHours' + i + j].touched)
        && (!this.reduceScheduleForm.controls['reducedWorkMin' + i + j].valid && this.reduceScheduleForm.controls['reducedWorkMin' + i + j].touched))) {
      this.customEventService.trackFormValidationError(this.appConstant.PLEASE_ANS);
    }
    if (this.workScheduledates[i].accordWeek_date[j].exceededTimeSelected || this.workScheduledates[i].accordWeek_date[j].isNonWorkDay) {
      this.customEventService.trackBannerMsg(this.appConstant.WARNING_BANNER, this.appConstant.REDUCED_SCHEDULE , this.appConstant.CHECK_PROCEED);
    }
    const workScheduledates: string = JSON.stringify(this.workScheduledates)
    sessionStorage.setItem('workScheduledates', workScheduledates);
  }

  /**
   * Here this function will calculate the total planned work for the week, where there is change in any one of the planned work dropdown
   *
   * @param i
   * @param j
   */
  calculatePlannedWork(i, j) {
    let timeWorked = 0;
    for (let k = 0; k < this.workScheduledates[i].accordWeek_date.length; k++) {
      const mins = (this.reduceScheduleForm.controls['reducedWorkMin' + i + k].value !== '' || this.reduceScheduleForm.controls['reducedWorkMin' + i + k].value !== 'min') ? (this.reduceScheduleForm.controls['reducedWorkMin' + i + k].value.split(' ', 2)[0]) : 0;
      const hrs = (this.reduceScheduleForm.controls['reducedWorkHours' + i + k].value !== '' || this.reduceScheduleForm.controls['reducedWorkHours' + i + k].value !== 'hours') ? (this.reduceScheduleForm.controls['reducedWorkHours' + i + k].value.split(' ', 2)[0]) : 0;
      timeWorked += (Number(hrs > 0) ? Number(hrs) * 60 + Number(mins) : Number(mins));
    };
    if (timeWorked >= 0) {
      if (timeWorked > 0 && timeWorked < 60) {
        this.workScheduledates[i].plannedWork = '0 hours ' + timeWorked + ' min';
      } else {
        const remainingMins =  timeWorked % 60;
        const remainingHrs = (timeWorked - remainingMins) / 60;
        this.workScheduledates[i].plannedWork = (remainingHrs !== 1 ? remainingHrs.toString() + ' hours ' : remainingHrs.toString() + ' hour ') + (remainingMins > 0 ? remainingMins + ' min' : '');
      }
    } else {
      this.workScheduledates[i].plannedWork = '0 hours';
    }
  }
  /**
   * to append 0 if value is less than 10
   *
   * @param mins
   * @returns
   */
  getMinutesValue(mins) {
    if (mins < 10) {
      return ('0' + mins.toString());
    } else {
      return mins.toString();
    }
  }

  /**
   * To validate form fields and mark red if they are invalid
   */
  validateReducedSchedule() {
    if (this.workScheduledates && this.workScheduledates.length > 0) {
      this.markFieldsTouched();
      if (this.reduceScheduleForm.status.toLowerCase() === this.appConstant.VALID_LABEL) {
        this.reducedData.emit(this.workScheduledates);
        this.reduceOutput.emit(this.getHoursNotWork());
      } else {
        this.reduceOutput.emit('');
        //this.reducedData.emit(this.workScheduledates);
      }
    }
  }

  /**
   * this will check if any field is not filled or invalid
   */
  markFieldsTouched() {
    let index = 0;
    let accordianId = '';
    Object.keys(this.reduceScheduleForm.controls).forEach(key => {
      this.reduceScheduleForm.get(key).markAsTouched();
      if (!this.reduceScheduleForm.get(key).valid && this.workScheduledates[key.charAt(key.length - 2)]) {
        if (accordianId === '') {
          accordianId = 'accordian' + key.charAt(key.length - 2);
        }
        if (!this.router.url.includes('leaveSpecificQues')) {
          this.workScheduledates[key.charAt(key.length - 2)].accordian_state = true;
        }
      }
      index++;
    });
    //this code will scroll to the first error element for reduced grid when the user clicks submit
    if (this.showPrefillQue && !this.router.url.includes('leaveSpecificQues')) {
      if (this.reduceScheduleForm.controls['prefillQues'].value === '' ||
        this.reduceScheduleForm.controls['prefillQues'].value === null || this.reduceScheduleForm.controls['prefillHours'].value === '' ||
        this.reduceScheduleForm.controls['prefillMins'].value === '' || this.reduceScheduleForm.controls['prefillHours'].value === 'hours' ||
        this.reduceScheduleForm.controls['prefillMins'].value === 'min') {
        const el = document.getElementById('prefillQues');
        if (el) {
          el.scrollIntoView()
        }
      } else if (accordianId !== '') {
        const e2 = document.getElementById(accordianId);
        if (e2) {
          e2.scrollIntoView()
        }
      }
    }
  }

  toggleAccordian(accordianId) {
    //console.log(accordianId);
    this.workScheduledates[accordianId].accordian_state = !this.workScheduledates[accordianId].accordian_state;
  }

  /**
   * this is to make output string off hours not worked separted with pipe '|' symbol
   *
   * @returns
   */
  getHoursNotWork() {
    const defaultVal = '00:00';
    const output = {missedHours: '',
      nonWorkDays: '',
      hoursWorked: '',
      hoursWorkedInMin: '',
      hoursWorkPerDays: ''};

    this.workScheduledates.forEach((i) => {
      i.accordWeek_date.forEach((j) => {
        if (output.missedHours === '') {
          output.missedHours += ((j.minutesNotWorked && j.minutesNotWorked > 0) ? j.minutesNotWorked : '00')
        } else {
          output.missedHours += '_' + ((j.minutesNotWorked && j.minutesNotWorked > 0) ? j.minutesNotWorked : '00') ;
        }

        if (output.hoursWorked === '') {
          output.hoursWorked +=  ((j.hourWorked && j.hourWorked !== '00') ? j.hourWorked : '00')
        } else {
          output.hoursWorked += '_' + ((j.hourWorked && j.hourWorked !== '00') ? j.hourWorked : '00') ;
        }

        if (output.hoursWorkedInMin === '') {
          output.hoursWorkedInMin += ((j.hourWorkedInMin && j.hourWorkedInMin > 0) ? j.hourWorkedInMin : '00')
        } else {
          output.hoursWorkedInMin += '_' + ((j.hourWorkedInMin && j.hourWorkedInMin > 0) ? j.hourWorkedInMin : '00') ;
        }

        if (j.exceededTimeSelected) {
          this.showWarningAtSucessPage = true;
        }
        if (j.isNonWorkDay) {
          output.nonWorkDays += output.nonWorkDays === '' ? j.dateValue : (',' + j.dateValue);
        }
      });
    });
    output.hoursWorkPerDays = defaultVal + '$' + output.missedHours + '_$' + output.hoursWorkedInMin;
    this.storageManagementService.setSessionData(AppLeaveIntakeConstants.SHOW_WARNING_MESSAGE, this.showWarningAtSucessPage);
    return output;
  }
  /**
   * This function is called when user clicked on continue button on pop up
   */
  continueClicked() {
    this.addWorkTime('', '', '', '');
    this.showPlannedWork = true;
    this.reduceScheduleForm.controls['prefillQues'].setValue(AppLeaveIntakeConstants.YES_LABEL);
    this.modalService.closeModal('prefillModal');
  }
  /**
   * This function is called when user clicked on cancel button on pop up
   */
  cancelClicked() {
    this.showPlannedWork = false;
    this.reduceScheduleForm.controls['prefillQues'].reset();
    this.reduceScheduleForm.controls['prefillQues'].setValue(AppLeaveIntakeConstants.NO_LABEL);
    this.modalService.closeModal('prefillModal');
  }
  /**
   * This function is called when user clicked on yes to check is there any values filled in grid
   */
  showPopup() {
    let flag = false;
    Object.keys(this.reduceScheduleForm.controls).forEach(key => {
      const value = this.reduceScheduleForm.controls[key].value;
      if ((key.includes('reducedWorkHours') && (value !== '' && value !== 'hours')) ||
        (key.includes('reducedWorkMin') && (value !== '' && value !== 'min'))) {
        flag = true;
      }
    });
    return flag;
  }
}
