export const MOCK_CLAIM_ADDITIONAL_DETAILS = {
  claimDetails: {
    approvedStartDate: '02/02/2021',
    approvedThroughDate: '02/12/2021',
    benefitBeginDate: '02/02/2021',
    benefitDuration: '70 Days',
    benefitEndDate: '04/12/2021',
    certDetailsList: [],
    certRequestList: [],
    changeInDisabilityDefinitionDate: null,
    claimNumber: 1841483,
    claimStatus: 'Approved',
    claimStatusReason: 'Administrative',
    daysApproved: '11',
    disabilityDate: '02/02/2021',
    eliminationPeriod: '0 Days',
    lastDayWorked: '02/01/2021',
    leaveContinuity: 'Intermittent',
    maximumBenefitEndDate: '04/12/2021',
    overpaymentBalance: null,
    product: '6005',
    workRelatedIndicator: '',
    workStatus: 'Not At Work',
  },
  responseCode: '001',
  responseReason: 'success'
};
