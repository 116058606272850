import {Component, Input, OnInit} from '@angular/core';
import {FormControl} from '@angular/forms';
import {ElementControlFormWrapperComponent} from '../../sub-atomic/element-control-form-wrapper/element-control-form-wrapper.component';

@Component({
  selector: 'primary-form-checkbox',
  templateUrl: 'primary-form-checkbox.component.html',
  styleUrls: ['primary-form-checkbox.component.scss']
})
export class PrimaryFormCheckboxComponent extends ElementControlFormWrapperComponent implements OnInit  {

  @Input() checkboxText: string;
  @Input() inputId: string;
  @Input() checkboxDisabled: boolean;

  @Input() controls: FormControl = new FormControl();
  ngOnInit() {}

}
