import {AfterViewInit, Component, Input, OnDestroy, OnInit} from '@angular/core';
import {absenceDetailModel, LeaveListModel} from '../../shared/models/gbLeave.model'
import {Router} from '@angular/router';
import {StorageManagementService} from '../../shared/services/storage-management.service';
import {LeaveDynatraceAction} from '../../shared/utils/leaveDynatraceAction';
import {TitleCasePipe} from '@angular/common';
import {AppLabelConstants} from '../../shared/constants/appLabel.constants';
import {SessionConstants} from '../../shared/constants/session.constants';
import {AppConstants, AppLeaveConst} from '../../shared/constants/app.constants';
import {ContentService} from '../../shared/services/content.service';
import {ProductService} from '../../shared/services/product.service';
import {LeaveReasonCapitalizedPipe} from '../../shared/pipes/leave-reason-capitalized.pipe';
import {LeaveDetailsService} from '../../shared/services/leave-details.service';
import {take, takeUntil} from 'rxjs/operators';
import {AppLeaveIntakeConstants} from '@app/cxr/leave-claim-intake-flow/leave-intake.constants';
import {AppLeaveConstants} from '@app/cxr/leave-details-flow/leave-details.constants';
import {Subscription} from "rxjs";
import {IfetchCompanyParm} from "@app/cxr/modules/intake/shared/models/intake-content.model";
import {Intake_Constants} from "@app/cxr/modules/intake/shared/constants/intake.constants";
import {IsparmEnabledService} from "@app/cxr/modules/intake/shared/services/isparm-enabled.service";

/**
 * Leave Card Component
 */
@Component({
  selector: 'app-leave-card',
  templateUrl: './leave-card.component.html',
  styleUrls: ['./leave-card.component.scss']
})
export class LeaveCardComponent extends LeaveDynatraceAction implements OnInit, AfterViewInit, OnDestroy {
  @Input() leave: LeaveListModel;
  @Input() messageHeader;
  @Input() messageBody;
  @Input() leavecardId?;
  @Input() leaveCalcFT: boolean;
  public claimCLosedMsg;
   pageId: string;
   owscContent;
   section_title = '';
   extension_Status_Message = '';
   extension_Status_Icon_Text = '';
   infoLabel = '';
   message_1_Label = '';
   gotoMissingInfo_Button = '';
   header_3 = '';
   message_3 = '';
   header_2 = '';
   message_2 = '';
  public pageId2;
  statusIcon: string;
  leaveStatus: string;
  leaveDates = {
    startDate: '',
    dateLabel: '',
    leaveReason: '',
    leaveSubReason: '',
    isCareFor: false,
    leaveReasonList : [],
  };
  public certDates = [];
  public earliestCertStartDate;
  public minDate;
  public AppLabelConst = {...AppLabelConstants, ...AppLeaveIntakeConstants};
  public AppLeaveConst = AppLeaveConst;
  public AppConstants = AppConstants;
  public subReason: any = '';
  public numAccomodations: any = '';
  public oneReasonList: any = '';
  public unspecifyAcc: any = '';
  public leaveReasonCapitalized: LeaveReasonCapitalizedPipe = new LeaveReasonCapitalizedPipe();
  public isBiggerIDLength: boolean;
  isMissingInfoDisabled: boolean = false;
  navigateToOldCalc: boolean = false;
  companyParmSubscription: Subscription;
  companyParm: IfetchCompanyParm;

  /**
   * @param router
   * @param storageManagementService
   * @param titlecasePipe
   */
  constructor(private router: Router,
              private storageManagementService: StorageManagementService,
              private isParmEnabled: IsparmEnabledService,
              private contentService: ContentService,
              private productService: ProductService,
              private titlecasePipe: TitleCasePipe,
              private leaveDetailsService: LeaveDetailsService) {
    super(storageManagementService);
  }

  ngOnInit() {
    this.isMissingInfoDisabled = this.storageManagementService.getSessionData(AppConstants.IS_MISSING_INFO_DISABLED);
    this.isBiggerIDLength = (this.leave && this.leave.leaveId && this.leave.leaveId.length > this.AppConstants.ID_LENGTH_LIMIT) ? true : false;
    this.leaveStatusIcon();
    this.getIsParmEnabledDetails();
    this.leaveReasonWithName();
    this.integrateOWCS();
    this.setupBenefitsData();
    if (this.leave) {
      if ( this.leave.leaveType && (this.leave.leaveType.toLowerCase() === AppLeaveConst.ADA_TYPE.toLowerCase())) {
        this.triggerTimelineToggle(this.leave);
        this.fetchEarliestCertStartDate();
      } else {
        this.leaveDates.startDate = this.leave.leaveRequestStartDate;
      }
    }
  }

  getIsParmEnabledDetails(): void {

    if(this.leave && this.leave?.personId){
      this.companyParmSubscription = this.isParmEnabled?.getIsParmEnabledDetails(this.leave?.personId, Intake_Constants.LOA_CALC_PARM)?.subscribe(data=>{
        if(data){
          this.companyParm = data;
          this.storageManagementService.setSessionData(SessionConstants.IS_LOA_CALC, this.companyParm.companyParmsValue);
          this.navigateToOldCalc = this.leaveCalcFT && !this.companyParm.companyParmsValue;
        }
      }, (error) => {
        console.log( AppConstants.ERROR + error);
      });
    }

  }

  ngAfterViewInit() {
    super.ngAfterViewInit();
  }

  triggerTimelineToggle(leave) {
    const leaveDataValid = (leave && leave.leaveType && leave.leaveId && leave.personId);
    const timeline = this.storageManagementService.getSessionData(this.AppLabelConst.DISPLAY_ADA_TIMELINE);
    const timelineTriggered = this.storageManagementService.getSessionData(this.AppLabelConst.ADA_TIMELINE_TRIGGERED);

    if (leaveDataValid && leave.leaveType.toLowerCase() === AppLeaveConst.ADA_TYPE.toLowerCase() && !timeline && !timelineTriggered) {
      this.storageManagementService.setSessionData(this.AppLabelConst.ADA_TIMELINE_TRIGGERED, true);

      this.leaveDetailsService.getAdaTimeline(leave.personId).pipe(take(1)).subscribe(data => {
        if (data && data['responseCode'] === this.AppLabelConst.SUCCESS_CODE_001) {
          this.storageManagementService.setSessionData(this.AppLabelConst.DISPLAY_ADA_TIMELINE, data['adaTimeline']);
        }
      });
    }
  }

  fetchEarliestCertStartDate() {
    if (this.leave && this.leave.certRequestList && this.leave.certRequestList.length > 0) {
      for (const requests of this.leave.certRequestList) {
          this.certDates.push( requests.certPeriodStartDate );
      }
    }
    this.earliestCertStartDate = this.certDates && this.certDates.length > 0 ? this.certDates[0] : '';
    if (this.certDates.length > 0) {
      for (let i = 0; i < this.certDates.length; i++) {
        if (this.certDates[i] && this.certDates[i].trim() !== '' && this.certDates[i].trim() !== this.AppLabelConst.UNKNOWN_CHECK && this.certDates[i] <= this.earliestCertStartDate) {
          this.earliestCertStartDate = this.certDates[i];
        }
      }
      this.leaveDates.startDate = this.earliestCertStartDate;
    }  else if (this.certDates.length === 0 ) {
      this.leaveDates.startDate = this.leave.leaveRequestStartDate;
    }
  }
  /**
   * Navigate user to leave detail page
   *
   * @param location
   * @param clickedLeaveData
   * @param leaveDates
   */
  navigateTo(location, clickedLeaveData, leaveDates) {
    const previousLeaveId = this.storageManagementService.getSessionData(this.AppLabelConst.ADA_TIMELINE_LEAVE_ID);
    const showAdaTimeline = this.storageManagementService.getSessionData(this.AppLabelConst.DISPLAY_ADA_TIMELINE);
    const isADA = clickedLeaveData.leaveType.toLowerCase() === AppLeaveConst.ADA_TYPE.toLowerCase();
    const leaveDataValid = clickedLeaveData && clickedLeaveData.personId && clickedLeaveData.leaveId;
    this.leaveDetailsService.isTimeLineDetailsSet.next(false);
    if (isADA && showAdaTimeline && leaveDataValid && (!previousLeaveId || previousLeaveId !== clickedLeaveData.leaveId)) {
      this.leaveDetailsService.getAdaTimelineDetails(clickedLeaveData.personId, clickedLeaveData.leaveId).pipe(take(1)).subscribe(response => {
        if (response && response['responseCode'] === AppLeaveConstants.SUCCESS_CODE_001) {
          const data = response;
          this.storageManagementService.setSessionData(this.AppLabelConst.ADA_TIMELINE_DETAILS, response);
          this.storageManagementService.setSessionData(this.AppLabelConst.ADA_TIMELINE_LEAVE_ID, clickedLeaveData.leaveId);
          this.leaveDetailsService.isTimeLineDetailsSet.next(true);
        }
      });
    }

    this.storageManagementService.setSessionData(SessionConstants.CURR_LEAVE, clickedLeaveData);
    this.storageManagementService.setSessionData(SessionConstants.LEAVE_DATES, leaveDates);
    this.router.navigate([location]);
  }
  /**
   * To get status icon of leave to show on claim overview page
   */
  leaveStatusIcon() {
    switch (this.leave.leaveStatus.toLowerCase()) {
      case AppLeaveConst.LEAVE_OPEN:
        this.statusIcon = AppLabelConstants.GREEN_CHECK_ICON;
        this.leaveStatus = (this.leave.leaveType && this.leave.leaveType.includes(AppLeaveConst.ADA)) ? this.titlecasePipe.transform(AppLeaveConst.LEAVE_OPEN) : AppLeaveConst.LEAVE_STATUS_OPEN;
        break;
      case AppLeaveConst.LEAVE_CLOSED:
        this.statusIcon = AppLeaveConst.NULLCHECK;
        this.leaveStatus = (this.leave.leaveType && this.leave.leaveType.includes(AppLeaveConst.ADA)) ? this.titlecasePipe.transform(AppLeaveConst.LEAVE_CLOSED) : AppLeaveConst.Leave_STATUS_CLOSED;
        break;
      case AppLeaveConst.LEAVE_CANCELLED:
        this.statusIcon = AppLeaveConst.NULLCHECK;
        this.leaveStatus = AppLeaveConst.LEAVE_STATUS_CANCELLED;
        break;
      default:
        this.statusIcon = '';
        this.leaveStatus = '';
    }
    if (this.leave.missingInfo && this.leave.missingInfo.length > 0) {
      this.statusIcon = AppLabelConstants.ORANGE_ALERT_ICON;
    } else if (this.statusIcon !== AppLeaveConst.NULLCHECK) {
      this.statusIcon = AppLabelConstants.GREEN_CHECK_ICON;
    }
  }
  /**
   * this function give leave reason, leave sub-reason, first name and last name to show on claim overview page
   */
  leaveReasonWithName() {
    if ( this.leave.leaveType.includes(AppLeaveConst.ADA)) {
      this.getAccomodationType();
    } else {
    if (this.leave.leaveReason && this.leave.leaveReason.length > 0 &&
      (this.leave.leaveReason.toLowerCase().trim() === AppLeaveConst.CARE_OF_FAMILY_MEMEBR ||
        this.leave.leaveReason.toLowerCase().trim() === AppConstants.BOND_WITH_NEW_CHILD ||
        this.leave.leaveReason.toLowerCase().trim() === AppConstants.BOND_WITH_A_NEW_CHILD)) {
  this.leave.leaveReason = this.leaveReasonCapitalized.transform(this.leave.leaveReason);
    }
    this.transformName();
    if ( this.leave.leaveReason &&
        (this.leave.leaveReason.toLowerCase().trim() === AppLeaveConst.CARE_OF_FAMILY_MEMEBR) ||
        (this.leave.leaveReason.toLowerCase().trim() === AppConstants.BOND_WITH_A_NEW_CHILD)
    ) {
      this.leaveDates.leaveSubReason = ((this.leave.relationship && this.leave.leaveFirstName) &&
      (this.leave.leaveFirstName !== this.leave.insuredFirstName ||
        this.leave.leaveLastName !== this.leave.insuredLastName)) ?
        this.leave.relationship.trim() + ', ' + this.leave.leaveFirstName :
        this.leave.relationship;
      this.leaveDates.leaveReason = this.leave.leaveReason;
      this.leaveDates.isCareFor = true;
    } else {
      this.leave.leaveReason = ((this.leave.leaveFirstName !== this.leave.insuredFirstName ||
        this.leave.leaveLastName !== this.leave.insuredLastName) &&
        (this.leave.leaveFirstName  && this.leave.leaveLastName && this.leave.leaveReason )) ?
        this.leave.leaveReason.trim() + ', ' + this.leave.leaveFirstName :
        this.leave.leaveReason;
      this.leaveDates.leaveReason = this.leave.leaveReason;
      this.leaveDates.isCareFor = false;
    }
    }
    if (this.leave.leaveType.toLowerCase() !== AppLeaveConst.ADA_TYPE.toLowerCase()) {
          if ( this.leave.leaveReason && this.leave.leaveReason.length > 0 && this.leaveDates.leaveSubReason && this.leaveDates.leaveSubReason.length > 0) {
            this.subReason = this.leaveDates.leaveSubReason;
          }
    }
  }
  /**
   * To transform leave first name and leave last name in first letter as upper case and remaining in lower case
   */
  transformName() {
    this.leave.leaveFirstName = this.leave.leaveFirstName ?
      this.titlecasePipe.transform(this.leave.leaveFirstName) : this.leave.leaveFirstName;
    this.leave.leaveLastName = this.leave.leaveLastName ?
      this.titlecasePipe.transform(this.leave.leaveLastName) : this.leave.leaveLastName;
    this.leave.insuredFirstName = this.leave.insuredFirstName ?
      this.titlecasePipe.transform(this.leave.insuredFirstName) : this.leave.insuredFirstName;
    this.leave.insuredLastName = this.leave.insuredLastName ?
      this.titlecasePipe.transform(this.leave.insuredLastName) : this.leave.insuredLastName;
  }
  /**
   * To show accommodation description on overview page for ADA leave
   * removing leave subreason (At Work /At Not Work) for ADA claim as per US104662
   */
  getAccomodationType() {
   // this.leave.absenceDetails = this.leave.absenceDetails.filter((absenceDetail: absenceDetailModel) => absenceDetail.statusId === AppLeaveConst.APPROVED_ID || absenceDetail.statusId === AppLeaveConst.PENDED_ID || absenceDetail.statusId === AppLeaveConst.DENIED_ID );
    this.leaveDates.isCareFor = false;
    if (this.leave) {
      const leaveAbsenceDesc = this.leave.accommodationDescription;
      const leaveAbsenceSubCat = this.leave.accommodationSubCategory;
      if (leaveAbsenceDesc && leaveAbsenceDesc.length > 0) {
        for (let i = 0; i < leaveAbsenceDesc.length; i++) {
          if (leaveAbsenceDesc[i] && leaveAbsenceDesc[i].trim().length > 0) {
            if (leaveAbsenceSubCat[i] && leaveAbsenceSubCat[i].trim().length > 0) {
              this.leaveDates.leaveReasonList.push(leaveAbsenceDesc[i] + ': ' + leaveAbsenceSubCat[i]);
            } else {
              this.leaveDates.leaveReasonList.push(leaveAbsenceDesc[i]);
            }
          } else {
            this.leaveDates.leaveReasonList.push(this.AppLeaveConst.ADASubReason);
          }
        }
      }
    }
    this.leave.leaveReason = '';
    this.leaveDates.leaveReason = this.leave.leaveReason;
    if (this.leave && this.leave.leaveType && this.leave.leaveType.toLowerCase() === AppLeaveConst.ADA_TYPE.toLowerCase()) {
      const reasonListExists = this.leaveDates && this.leaveDates.leaveReasonList;
      if (reasonListExists && this.leaveDates.leaveReasonList.length > 1) {
        this.numAccomodations = this.leaveDates.leaveReasonList.length + '    ' + AppLeaveConst.NUM_ACCOMMODATIONS;
      }
      if (reasonListExists && this.leaveDates.leaveReasonList.length === 1) {
        this.oneReasonList = this.leaveDates.leaveReasonList[0];
      } else if (reasonListExists && this.leaveDates.leaveReasonList.length === 0) {
        this.unspecifyAcc = this.AppLeaveConst.ADASubReason;
      }
    }
  }

  trackByReason(index: number, reason: any) {
          return  reason;
  }
  setupBenefitsData() {
    // this.userClaims = [this.currentClaim, this.currentClaimRelated];

    this.pageId2 = this.contentService.getPageId(AppConstants.CLAIM_DETAILS);
    if (this.pageId2) {
      this.populateContent(this.pageId);
    } else {
      //calling the sites service again
      this.contentService.getSiteMap().subscribe(
        val => {
          if (val) {
            const siteMapResponse = val;
            this.storageManagementService.setSessionData(SessionConstants.SITE_MAP_DATA, siteMapResponse);
            this.pageId2 = this.contentService.getPageId(AppConstants.CLAIM_DETAILS);
            this.populateContent(this.pageId2);
          }
        }, (error) => {
        });
    }
  }

  populateContent(pageId) {
    if (this.storageManagementService.getSessionData(SessionConstants.OWCS_CONTENT)) {
      this.fetchOWCSContentFromSession();
    } else {
      this.fetchOWCSContentFromService();
    }
  }

  fetchOWCSContentFromSession() {
    this.owscContent = this.storageManagementService.getSessionData(SessionConstants.OWCS_CONTENT);
    if (this.owscContent && this.owscContent.CD_103_Body) {
      this.claimCLosedMsg = this.owscContent.CD_103_Body.value
    }
  }

  fetchOWCSContentFromService() {
    this.pageId = this.contentService.getPageId(AppConstants.CLAIM_DETAILS);
    if(this.pageId) {
      this.contentService.getOWCSResponse(this.pageId, AppConstants.CLAIM_DETAILS).subscribe(
        val => {
          if (val) {
            this.owscContent = val;
            if (this.owscContent && this.owscContent.data) {

              if (this.owscContent.data.CD_103_Body) {
                this.claimCLosedMsg = this.owscContent.data.CD_103_Body.value
              }

              this.storageManagementService.setSessionData(SessionConstants.OWCS_CONTENT, this.owscContent.data);
            }
          }
        });
    }
  }

  integrateOWCS() {
    if(this.storageManagementService.getSessionData(SessionConstants.CLAIM_OVERVIEW_OWCS)){
      this.owscContent = this.storageManagementService.getSessionData(SessionConstants.CLAIM_OVERVIEW_OWCS)
      this.populateOWCSContent()
    } else {
      this.pageId = this.contentService.getPageId(SessionConstants.CLAIM_OVERVIEW);
      if (this.pageId) {
        this.contentService.getOWCSResponse(this.pageId, SessionConstants.CLAIM_OVERVIEW_U).subscribe(
          val => {
            if (val) {
              this.owscContent = val;
              this.populateOWCSContent();
            }
          }, (error) => {
            console.error(error);
          });
      }
    }
  }

  populateOWCSContent() {
    if (this.owscContent && this.owscContent.data) {
      if (this.owscContent.data.CO_01_Label) {
        this.section_title = this.owscContent.data.CO_01_Label.value;
      }
      if (this.owscContent.data.CO_58_Label) {
        this.extension_Status_Message = this.owscContent.data.CO_58_Label.value;
      }
      if (this.owscContent.data.CO_60_Label) {
        this.extension_Status_Icon_Text = this.owscContent.data.CO_60_Label.value;
      }
      //Missing Info Labels
      if (this.owscContent.data.CO_47_Body) {
        this.infoLabel = this.owscContent.data.CO_47_Body.value;
      }
      if (this.owscContent.data.CO_48_Body) {
        this.message_1_Label = this.owscContent.data.CO_48_Body.value;
      }
      if (this.owscContent.data.CO_50_Body) {
        this.gotoMissingInfo_Button = this.owscContent.data.CO_50_Body.value;
      }
      if (this.owscContent.data.CO_53_Body) {
        this.header_3 = this.owscContent.data.CO_53_Body.value;
      }
      if (this.owscContent.data.CO_54_Body) {
        this.message_3 = this.owscContent.data.CO_54_Body.value;
      }
      if (this.owscContent.data.CO_51_Body) {
        this.header_2 = this.owscContent.data.CO_51_Body.value;
      }
      if (this.owscContent.data.CO_52_Body) {
        this.message_2 = this.owscContent.data.CO_52_Body.value;
      }
      //Used this variable to display product disclosure post login
      if (this.owscContent.data.CO_67_Body) {
        this.productService.productDisclosurePostLogin.next(this.owscContent.data.CO_67_Body.value)
      }
    }
  }

  ngOnDestroy() {
    if(this.companyParmSubscription){
      this.companyParmSubscription.unsubscribe();
    }
  }

}

