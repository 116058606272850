/**
 * Created by AC12396 on 1/22/2019.
 */
export class AAClaimPaymentResponse {
  displayPaystub: boolean;
  pageInfo: PageInfo;
  paymentList: PaymentList[];
  futurePaymentList: FuturePaymentList[];
  responseCode: string;
  responseReason: string;
}

export class PageInfo {
  totalResults: string;
  offSet: string;
  itemsOnPage: string;
  firstItemOnPage: string;
  lastItemOnPage: string;
  paging: Paging[];
}

export class Paging {
  offSet: string;
  name: string;
  current: string;
}

export class PaymentList {
  checkDate: string;
  payPeriodFrom: string;
  payPeriodThrough: string;
  netPayAmount: string;
  deliveryMethod: string;
  payeeName: string;
  payeeAddress1: string;
  payeeAddress2: string;
  payeeAddress3: string;
  payeeCity: string;
  payeeState: string;
  payeeZip: string;
  claimNum: string;
  checkNum: string;
  numDaysPaid: string;
  totalWithholdings: string;
  totalGrossBenefit: string;
  totalTaxes: string;
  totalDeductions: string;
  listItemIndex: number;
  eobNum: string;
  ssn: string;
  emplId: string;
  company: string;
  paygroup: string;
  payEndDate: string;
  offcycle: string;
  pageNum: string;
  lineNum: string;
  sepchk: string;
  offsets: Offsets[];
  taxes: TaxDeductionBenefit[];
  beforeTaxDeductions: TaxDeductionBenefit[];
  afterTaxDeductions: TaxDeductionBenefit[];
  totalYTDTaxes: string;
  totalCurrentTaxes: string;
  totalCurrAfterTaxDeducations: string;
  totalYTDAfterTaxDeducations: string;
  totalCurrBeforeTaxDeducations: string;
  totalYTDBeforeTaxDeducations: string;
  totalOffsets: string;
  federalTaxGrossCurr: string;
  grossPayMinusTaxesCurr: string;
  federalTaxGrossYTD: string;
  grossPayMinusTaxesYTD: string;
  accountNumber: string;
}

export class FuturePaymentList {
  checkDate: string;
  payPeriodFrom: string;
  payPeriodThrough: string;
  netPayAmount: string;
  deliveryMethod: string;
  payeeName: string;
  payeeAddress1: string;
  payeeAddress2: string;
  payeeAddress3: string;
  payeeCity: string;
  payeeState: string;
  payeeZip: string;
  claimNum: string;
  checkNum: string;
  numDaysPaid: string;
  totalWithholdings: string;
  totalGrossBenefit: string;
  totalTaxes: string;
  totalDeductions: string;
  listItemIndex: number;
  eobNum: string;
  ssn: string;
  emplId: string;
  company: string;
  paygroup: string;
  payEndDate: string;
  offcycle: string;
  pageNum: string;
  lineNum: string;
  sepchk: string;
  offsets: Offsets[];
  taxes: TaxDeductionBenefit[];
  beforeTaxDeductions: TaxDeductionBenefit[];
  afterTaxDeductions: TaxDeductionBenefit[];
  totalYTDTaxes: string;
  totalCurrentTaxes: string;
  totalCurrAfterTaxDeducations: string;
  totalYTDAfterTaxDeducations: string;
  totalCurrBeforeTaxDeducations: string;
  totalYTDBeforeTaxDeducations: string;
  totalOffsets: string;
  federalTaxGrossCurr: string;
  grossPayMinusTaxesCurr: string;
  federalTaxGrossYTD: string;
  grossPayMinusTaxesYTD: string;
  accountNumber: string;
}


export class TaxDeductionBenefit {
  Description: string;
  Current: string;
  YTD: string;
}


export class Offsets {
  Amount: string;
  Description: string;
  PayPeriod: string;
}


