
import {catchError, map} from 'rxjs/operators';
import {Injectable} from '@angular/core';


import {env} from '../../../environments/environment-loader';
import {ResponseErrorHandlerService} from '../services/response-error-handler.service';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {StorageManagementService} from './storage-management.service';
import {AppLabelConstants} from '../constants/appLabel.constants';

@Injectable()
export class ResetPasswordService {
  constructor(private errorHandler: ResponseErrorHandlerService,
              private httpClient: HttpClient,
              private storageManagement: StorageManagementService) { }


  /**
   * @returns
   */
  getTokenId() {
    const profileId = this.storageManagement.getUserDataProfileId();
    return this.httpClient.get(env.gbCiamCommonUtil.getUuidURL ,
     // '&_queryFilter=eeprofilekey+eq+' + profileId,
        {
          headers: new HttpHeaders()
            .set('Content-Type', 'application/json')

        }).pipe(
        map(
        (response: Response) => {
          if (!this.errorHandler.isServiceResponseHasError(response)) {
            return response;
          } else {
            throw response;
          }
        }
      ), catchError((error: any) => this.errorHandler.handleError(error)), );
  }

  /**
   * @returns {Observable<any | any>}
   */
  // resetPassword(emailAddr: string, currentpwd: string, uuId: string, confirmPwd: string) {
  //
  //     const request: string = JSON.stringify(
  //       [{
  //         'operation': 'replace',
  //         'field': 'password',
  //         'value': confirmPwd
  //       }]
  //     );
  //
  //      return this.httpClient.post(env.gbCiamCommonUtil.updateUserPaswordURL.replace('{uuId}', uuId), request,
  //
  //
  //        {
  //         headers: new HttpHeaders()
  //           .set(AppLabelConstants.CONTENT_TYPE_KEY, AppLabelConstants.APPLICATION_KEY)
  //           .set('X-HTTP-Method-Override', 'PATCH' )
  //           .set('X-OpenIDM-Reauth-Password', currentpwd)
  //           .set('X-Requested-With', 'XMLHttpRequest')
  //
  //        }).pipe(
  //       map(
  //       (response: Response) => {
  //         if (!this.errorHandler.isServiceResponseHasError(response)) {
  //           return response;
  //         } else {
  //           throw response;
  //         }
  //       }
  //     ), catchError((error: any) => {
  //         return this.errorHandler.handleErrorForSubmit(error); }), );
  //   }

}
