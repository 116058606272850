import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { AppLeaveIntakeConstants } from "@app/cxr/leave-claim-intake-flow/leave-intake.constants";
import { IntakeSessionConstants } from "@app/cxr/modules/intake/shared/constants/intakeSession.constants";
import {
  IBaseResponse,
  ICustomerSpecific,
  ILateReportingForIntermittent,
  IPrimaryRelationship,
  IRelatedPregnancyResponse,
  ISecondaryRelationship,
  ReasonContinuityResponse,
} from "@app/cxr/modules/intake/shared/models/intake-content.model";
import { IntakeWorkSchedule } from "@app/cxr/modules/intake/shared/models/intake-work-schedule.model";
import {
  IEmpWorkScheduleList,
  ITHAAWorkScheduleDetails,
  ITHAAWorkScheduleDetailsList,
  ITHHAEmployeeWorkSchedule,
  IWorkScheduleSubmitRequest,
} from "@app/cxr/modules/intake/shared/models/work-schedule-details.model";
import { IntakeUtilitiesService } from "@app/cxr/modules/intake/shared/services/intake-utilities.service";
import { ResponseErrorHandlerService } from "@app/shared/services/response-error-handler.service";
import { env } from "@env/environment-loader";
import { AppLabelConstants } from "@shared/constants/appLabel.constants";
import { SessionConstants } from "@shared/constants/session.constants";
import { StorageManagementService } from "@shared/services/storage-management.service";
import { Observable } from "rxjs";
import { BehaviorSubject } from "rxjs/internal/BehaviorSubject";
import { catchError, map } from "rxjs/operators";

@Injectable()
export class BondingIntakeService {
  wasCustomerSpecificApiCalled: boolean = false;
  wasWorkScheduleEdited: boolean = false;
  fetchSchedule: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  constructor(
    private errorHandler: ResponseErrorHandlerService,
    private httpClient: HttpClient,
    private storageManagementService: StorageManagementService,
    private intakeUtilitiesService: IntakeUtilitiesService,
  ) {}

  getBondingRelationshipInfoList(
    personIdValue: string,
    reasonIdValue,
  ): Observable<IPrimaryRelationship> {
    return this.baseHttpGetRequest(
      `${env.thaaWKABPortalService.getRelationshipList}&personId=${personIdValue}&reasonId=${reasonIdValue}`,
      true,
    );
  }

  getSecondaryRelationshipInfoList(
    personIdValue: string,
    reasonIdValue: string,
    relationshipIdValue: string,
  ): Observable<ISecondaryRelationship> {
    return this.baseHttpGetRequest(
      `${env.thaaWKABPortalService.getSecondaryRelationshipList}&personId=${personIdValue}&reasonId=${reasonIdValue}&relationshipId=${relationshipIdValue}`,
      true,
    );
  }

  getCustomerSpecific(
    personId: string,
    reasonId?: number,
    relationshipId?: number,
    isForSpouseToggle: boolean = false,
  ): Observable<ICustomerSpecific> {
    const uri = isForSpouseToggle
      ? `&personId=${personId}&reasonId=${reasonId}&relationshipId=${relationshipId}`
      : `&personId=${personId}`;
    return this.baseHttpGetRequest(`${env.thaaWKABPortalService.getCustomerSpecific}${uri}`, true);
  }

  getRelatedPregnancyClaim(
    personId: string,
    FDA: string,
    bondingLeaveId?: number,
  ): Observable<IRelatedPregnancyResponse> {
    const uri = bondingLeaveId ? `&bondingLeaveId=${bondingLeaveId}` : "";
    return this.baseHttpGetRequest(
      `${env.thaaWKABPortalService.getRelatedPregnancyClaim}&personId=${personId}&FDA=${FDA}${uri}`,
      true,
    );
  }

  fetchReasonContinuity(
    personId: string,
    reasonId: number,
    relationshipId: number,
  ): Observable<ReasonContinuityResponse> {
    return this.baseHttpGetRequest(
      `${env.thaaWKABPortalService.getReasonContinuity}&personId=${personId}&reasonId=${reasonId}&relationshipId=${relationshipId}`,
      false,
    );
  }

  lateReportingForSelectedFda(personId: string): Observable<ILateReportingForIntermittent> {
    return this.baseHttpGetRequest(
      `${env.thaaWKABPortalService.lateReportingForIntermittent}&personId=${personId}`,
      false,
    );
  }

  /*
   * get work schedule for start date to 6 months from today
   * */
  getWorkSchedule(startDate: string, storedEndDate?: string): Observable<IntakeWorkSchedule> {
    //calculate end date 6 months from today
    let calculateEndDate;
    if (!storedEndDate) {
      calculateEndDate = new Date();
      calculateEndDate.setMonth(calculateEndDate.getMonth() + 7);
    } else {
      calculateEndDate = new Date(storedEndDate);
    }

    let endDate = "";
    if (calculateEndDate.getMonth() + 1 === 12) {
      endDate = `1/1/${calculateEndDate.getFullYear() + 1}`;
    } else {
      endDate = `${calculateEndDate.getMonth() + 1}/1/${calculateEndDate.getFullYear()}`;
    }

    const personId = this.storageManagementService?.getAppData(SessionConstants.USER_DATA)
      ?.personIds[0];
    return this.baseHttpGetRequest(
      `${env.gbLeaves.workScheduleApi}&personId=${personId}&DOA=${startDate}&endDate=${endDate}`,
      false,
    );
  }

  /*
   * update work schedule map obj stored in utilities file with the latest work schedule data
   * */
  updateWorkSchedule(): void {
    if (this.wasWorkScheduleEdited) {
      const startDate = this.storageManagementService?.getSessionData(
        SessionConstants.WORK_SCHEDULE_REGULAR,
      )?.empWorkSchedulesList[0]?.workScheduleEffectiveDate;
      const storedWorkSchedule: IntakeWorkSchedule =
        this.storageManagementService?.getIntakeSessionData(IntakeSessionConstants.WORK_SCHEDULE);
      let endDate;
      if (storedWorkSchedule?.LoadWorkScheduleList?.length > 0) {
        endDate =
          storedWorkSchedule?.LoadWorkScheduleList[
            storedWorkSchedule?.LoadWorkScheduleList?.length - 1
          ].Day;
      }
      this.getWorkSchedule(startDate, endDate).subscribe((data) => {
        if (data && data.LoadWorkScheduleList?.length > 0) {
          this.wasWorkScheduleEdited = false;
          let index = storedWorkSchedule?.LoadWorkScheduleList?.findIndex(
            (day) => day?.Day === data?.LoadWorkScheduleList[0]?.Day,
          );
          data.LoadWorkScheduleList.forEach((day) => {
            this.intakeUtilitiesService.workScheduleList[day.Day] = day;
            if (index >= 0 && storedWorkSchedule?.LoadWorkScheduleList[index]?.ScheduledMinutes) {
              storedWorkSchedule.LoadWorkScheduleList[index].ScheduledMinutes =
                this.intakeUtilitiesService.workScheduleList[day.Day]?.ScheduledMinutes;
              index++;
            }
          });
          this.storageManagementService?.setIntakeSessionData(
            IntakeSessionConstants.WORK_SCHEDULE,
            storedWorkSchedule,
          );
          this.fetchSchedule.next(true);
        }
      });
    }
  }

  submitWorkSchedule(confirmedWorkSchedule: boolean): Observable<IBaseResponse> {
    const workScheduleSessionData = this.storageManagementService.getSessionData(
      SessionConstants.WORK_SCHEDULE_REGULAR,
    );
    const workSchedule: IEmpWorkScheduleList = workScheduleSessionData?.empWorkSchedulesList[0];
    const personId = this.storageManagementService?.getAppData(SessionConstants.USER_DATA)
      ?.personIds[0];
    if (workSchedule && personId) {
      let daysOn = 0;
      let daysOff = 0;
      if (
        workSchedule.thaaWorkSchduleType.toLowerCase() ===
        AppLeaveIntakeConstants.ROTATING.toLowerCase()
      ) {
        daysOff = workSchedule.employeeWorkSchedules[0]?.weekDetails?.filter(
          (day) => day.workMinutes === 0,
        )?.length;
        daysOn = workSchedule.employeeWorkSchedules[0]?.weekDetails?.length - daysOff;
      }

      const workScheduleSubmitRequest: IWorkScheduleSubmitRequest = {
        confirmedWorkSchedule: confirmedWorkSchedule.toString(),
        personId,
        thaaEmployeeWorkScheduleDetails: [
          {
            workScheduleId: workSchedule?.workScheduleId,
            workScheduleEffectiveDate: this.generateTimestamp(
              confirmedWorkSchedule,
              workSchedule.workScheduleEffectiveDate,
            ),
            daysInRotation: workSchedule.daysInRotation,
            daysON: daysOn,
            daysOff,
            thaaWorkScheduleType: workSchedule.thaaWorkSchduleType,
            thaaWorkScheduleDetailsList: this.fetchTHAAWorkScheduleDetailsList(
              workSchedule?.employeeWorkSchedules,
            ),
          },
        ],
      };
      return this.baseHttpPostRequest<IWorkScheduleSubmitRequest, IBaseResponse>(
        env.thaaWKABPortalService.submitWorkSchedule,
        workScheduleSubmitRequest,
        true,
      );
    }
  }

  /*
   * generate timestamp for inout date if user is creating a new work schedule
   * if the user is confirming the previous work schedule then return the input date as is without a timestamp
   * */
  generateTimestamp(confirmedWorkSchedule: boolean, inputDate: string): string {
    if (confirmedWorkSchedule) {
      return inputDate;
    }
    return `${inputDate} ${new Date().toLocaleString("en-US", { hour: "2-digit", minute: "2-digit", second: "2-digit", hour12: true })}`;
  }

  fetchTHAAWorkScheduleDetailsList(weekDetails): ITHAAWorkScheduleDetailsList[] {
    let workScheduleDetailsList: ITHAAWorkScheduleDetailsList[] = [];
    weekDetails.forEach((week) => {
      week?.weekDetails?.forEach((day) => {
        const weekNumber = day.weekNumber % 7;
        workScheduleDetailsList.push({
          dayNumber: day.dayNumber,
          weekNumber: weekNumber === 0 ? 1 : weekNumber,
          workMinutes: day.workMinutes,
        });
      });
    });

    return workScheduleDetailsList;
  }

  baseHttpGetRequest(apiUrlAndParameters: string, handleSubmissionFailure: boolean): any {
    return this.httpClient
      .get(apiUrlAndParameters, {
        headers: new HttpHeaders().set(
          AppLabelConstants.CONTENT_TYPE_KEY,
          AppLabelConstants.APPLICATION_KEY,
        ),
      })
      .pipe(
        map((response) => {
          if (!this.errorHandler.isServiceResponseHasError(response)) {
            return response;
          } else {
            throw response;
          }
        }),
        catchError((error: any) =>
          handleSubmissionFailure
            ? this.errorHandler.handleErrorForSubmit(error)
            : this.errorHandler.handleErrorThaa(error),
        ),
      );
  }

  /*
   * T: Post Request's request object data type
   * K: Post request's response object data type
   * */
  baseHttpPostRequest<T, K>(
    apiUrl: string,
    requestBody: T,
    isGlobalError?: boolean,
  ): Observable<K> {
    return this.httpClient
      .post<K>(apiUrl, requestBody, {
        headers: new HttpHeaders().set(
          AppLabelConstants.CONTENT_TYPE_KEY,
          AppLabelConstants.APPLICATION_KEY,
        ),
      })
      .pipe(
        map((response: K) => {
          if (!this.errorHandler.isServiceResponseHasError(response)) {
            return response;
          } else {
            throw response;
          }
        }),
        catchError((error: any) =>
          isGlobalError
            ? this.errorHandler.globalErrorHandler(error)
            : this.errorHandler.handleErrorForSubmit(error),
        ),
      );
  }
  getTHAAWorkScheduleDetails(): ITHHAEmployeeWorkSchedule {
    let isMultiWeek = false;
    const workScheduleSessionData = this.storageManagementService.getSessionData(
      SessionConstants.WORK_SCHEDULE_REGULAR,
    );
    const workSchedule: IEmpWorkScheduleList = workScheduleSessionData?.empWorkSchedulesList[0];
    if (workSchedule) {
      let daysOn = 0;
      let daysOff = 0;
      if (
        workSchedule.thaaWorkSchduleType.toLowerCase() ===
        AppLeaveIntakeConstants.ROTATING.toLowerCase()
      ) {
        daysOff = workSchedule.employeeWorkSchedules[0]?.weekDetails?.filter(
          (day) => day.workMinutes === 0,
        )?.length;
        daysOn = workSchedule.employeeWorkSchedules[0]?.weekDetails?.length - daysOff;
      }
      const THAAWorkScheduleDetails = [];
      if (
        workSchedule.thaaWorkSchduleType === "Weekly" ||
        workSchedule.thaaWorkSchduleType === "Rotating"
      ) {
        isMultiWeek = true;
        const thaaWorkScheduleDetailsList = this.fetchTHAAWorkScheduleDetailsList(
          workSchedule?.employeeWorkSchedules,
        );
        thaaWorkScheduleDetailsList.forEach((ele) => {
          const workScheduleDetails: ITHAAWorkScheduleDetails = {
            day_num: ele.dayNumber,
            workMinutes: ele.workMinutes,
          };
          THAAWorkScheduleDetails.push(workScheduleDetails);
        });
      } else {
        isMultiWeek = false;
        const multiWeek = [];
        const days = this.fetchTHAAWorkScheduleDetailsList(workSchedule.employeeWorkSchedules);
        multiWeek.push(days);
        multiWeek.forEach((ele) => {
          ele.forEach((data) => {
            const workScheduleDetails: ITHAAWorkScheduleDetails = {
              day_num: data.dayNumber,
              workMinutes: data.workMinutes,
              WeekNumber: data.weekNumber,
            };
            THAAWorkScheduleDetails.push(workScheduleDetails);
          });
        });
      }
      const THHAEmployeeWorkSchedule: ITHHAEmployeeWorkSchedule = {
        EmployeeID: "",
        EffectiveDate: workSchedule.workScheduleEffectiveDate,
        DaysInRotation: workSchedule.daysInRotation,
        DaysON: daysOn,
        DaysOff: daysOff,
        THAAWorkSchduelType: isMultiWeek ? workSchedule.thaaWorkSchduleType : "Weekly",
        THAAWorkScheduleDetails,
      };
      return THHAEmployeeWorkSchedule;
    }
  }
}
