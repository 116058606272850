/* The objective of this service is to be the generic
service for handling simple tasks that would otherwise
create too many single use case services.
*
*/

import {Injectable} from '@angular/core';
import {Subject} from 'rxjs';

@Injectable()
export class ReferenceHelperService {

  private referenceSource: Subject<Map<string, string>> = new Subject();
  reference = this.referenceSource.asObservable();
  private referenceSourceMap = new Map();

  setReferenceOption(sec: string, val: string) {
    this.referenceSourceMap.set(sec, val);
    this.referenceSource.next(this.referenceSourceMap);
 }

}
