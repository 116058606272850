
/**
 * Created by AC12394 on 8/29/2017.
 */

import {Injectable} from '@angular/core';

@Injectable()
export class TermsAndConditions {
  getContent() {
    return [{}]; //TermsAndConditionsContent;
  }

}

