import { AfterViewInit, Directive } from '@angular/core';
import {StorageManagementService} from '../services/storage-management.service';

/*
* US14494 - Performance Monitoring Requirement
* Author: DB
* Added: 02/21/2018
 */
@Directive()
export abstract class LeaveDynatraceAction implements AfterViewInit {

  private storage;

  constructor(
    storageManagementService: StorageManagementService
  ) {
    this.storage = storageManagementService;
  }
  ngAfterViewInit() {
    const action = this.storage.retrieveSessionObject('dynatraceAction');
    if ( window.hasOwnProperty('dT_') && window.hasOwnProperty('dynaTrace') ) {
      dynaTrace.leaveAction(action);
    } else {
      this.simulateLeaveAction(action);
    }

  }

  simulateLeaveAction(action) {
    //console.log('Dynatrace simulation: left Dynatrace Action: ', action)
  }
}
