import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'convertToDoubleDigits'
})
export class ConvertToDoubleDigitsPipe implements PipeTransform {

  /*
  * convert input value from single digit to 2 digits if its not in 2 digits already
  * @param currentValue: single digit input which will be converted into 2 digits
  * eg. input : 8, output : 08
  * eg. input : 10, output : 10 - no change since input value is >= 10
  * */
  transform(currentValue: number): string {
    if (currentValue < 10) {
      return '0' + currentValue.toString();
    }
    return currentValue.toString();
  }

}
