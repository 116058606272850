import {AfterViewInit, Component, OnInit} from '@angular/core';
import {Router} from '@angular/router';
import {ModalService} from '../../../shared/services/modal.service';
import {AbstractControl, FormBuilder, FormControl, FormGroup} from '@angular/forms';
import {AetnaRequestExtensionService} from '../../../shared/services/aetna-request-extension.service';
import {PersonService} from '../../../shared/services/people.service';
import {ContentService} from '../../../shared/services/content.service';
import {CustomEventService} from '../../../shared/services/tealium/custom-event.service';
import {ErrorMessagingService} from '../../../shared/services/error-messaging.service';
import {StorageManagementService} from '../../../shared/services/storage-management.service';
import {LeaveDynatraceAction} from '../../../shared/utils/leaveDynatraceAction';
import {ResponseErrorHandlerService} from '../../../shared/services/response-error-handler.service';
import {AppLabelConstants} from '../../../shared/constants/appLabel.constants';
import {SessionConstants} from '../../../shared/constants/session.constants';
import {AppConstants} from '../../../shared/constants/app.constants';
import {DatePipe} from '@angular/common';
import {MessageRibbonService} from '../../../shared/services/message-ribbon.service';



@Component({
  selector: 'app-interactive-request-extension-thaa',
  templateUrl: 'interactive-request-extension-thaa.component.html',
  styleUrls: ['interactive-request-extension-thaa.component.scss']
})
export class InteractiveRequestExtensionThaaComponent extends LeaveDynatraceAction implements OnInit, AfterViewInit {
  public appConstant = AppConstants;
  public appLabelConstants = AppLabelConstants;
  public welcomeMessage = AppLabelConstants.INTERACTIVE_REQUEST_AN_EXT_THAA;
  public extensionRequestFormThaa: FormGroup;
  public isPFLExtension: boolean = false;
  public thingsToKnowBoolean: boolean = true;
  public exact_Radial: string = AppLabelConstants.EXACT_DATE;
  public estimateRadial: string = AppLabelConstants.ESTIMATE_DATE;
  public currentClaim;
  public owcsContent;
  public pageId;
  public extensionMsg = AppLabelConstants.EXTENSION_MESSAGE;
  public approveThroughDate;
  public currentApprovedDate = AppLabelConstants.APPROVED_THROUGH_DATE;
  public radioValueVar;
  public previousUrl;
  public AppConstants = AppConstants;
  public exactdate;
  public estimate;
  public thingsToKnow;
  public thingsToKnowBulletOne;
  public thingsToKnowBulletTwo;
  public thingsToKnowBulletThree;
  public thingsToKnowBulletFour;
  public isEstimateDate: boolean = false;
  public isExactDate: boolean = false;
  public isRadioValueEmpty: boolean = false;
  public isExtensionValueEmpty: boolean = false;
  public isTwoDigit: boolean = false;
  lengthExtension = [];
  day = [];
  public lengthLabel;

  constructor(private router: Router,
              private _formBuilder: FormBuilder,
              private modalService: ModalService,
              private personService: PersonService,
              private _responseErrorHandlerService: ResponseErrorHandlerService,
              private contentService: ContentService,
              private messageRibbonService: MessageRibbonService,
              private customEventService: CustomEventService,
              private errorList: ErrorMessagingService,
              private datepipe: DatePipe,
              private storageManagementService: StorageManagementService, ) {
    super(storageManagementService);
  }


  ngOnInit() {
    this.setextensionRequestFormThaa();
    //Current approved-through date
    this.approveThroughDate =  JSON.parse(sessionStorage.getItem(SessionConstants.AUTHORIZED_END_DATE)) ? JSON.parse(sessionStorage.getItem(SessionConstants.AUTHORIZED_END_DATE))['authorizedEndDate'] : '';
    this.lengthExtension = [AppConstants.WEEKS, AppConstants.DAYS];

  }

  setextensionRequestFormThaa() {
    this.extensionRequestFormThaa = new FormGroup({
      radioValue: new FormControl(),
      days: new FormControl(),
      weeks: new FormControl()
    });
    this.thingsToKnowBoolean = true;
    this.previousUrl = this.storageManagementService.getSessionData(SessionConstants.PREVIOUS_URL);
    if (this.storageManagementService.getSessionData(SessionConstants.CUR_CLAIM)) {
      this.currentClaim = this.storageManagementService.getSessionData(SessionConstants.CUR_CLAIM);
      if (this.currentClaim.coverageCategory && (this.currentClaim.coverageCategory.toLowerCase() === this.appConstant.PFL.toLowerCase())) {
        this.isPFLExtension = true;
      }
    }
    this.populateContent();
  }

  btnSubmit() {
    this.thingsToKnowBoolean = false;
  }
  onRadioClick(event, radioText) {
    if (event) {
      event.preventDefault();
    }
    const claims = this.storageManagementService.getAppData(SessionConstants.USER_CLAIMS);
    this.radioValueVar = sessionStorage.getItem(SessionConstants.RADIO_VALUE);

    if (radioText === this.exact_Radial) {
      this.extensionRequestFormThaa.controls['radioValue'].setValue('exactDate');
      this.extensionRequestFormThaa.value.days = null;
      this.extensionRequestFormThaa.controls['days'].setValue('');
      this.isEstimateDate = false;
      this.isExactDate = true;
      this.isEstimateDate = false;
      this.isRadioValueEmpty = false;
    } else if (radioText === this.estimateRadial) {
      this.extensionRequestFormThaa.controls['radioValue'].setValue('estimateDate');
      this.extensionRequestFormThaa.value.days = null;
      this.extensionRequestFormThaa.get('days').setValue('');
      this.extensionRequestFormThaa.controls['days'].markAsTouched();
      this.extensionRequestFormThaa.controls['days'].markAsDirty();
      this.radioValueVar = 'estimateDate';
      this.tagErrorDays(this.extensionRequestFormThaa.controls['days'].value, this.extensionRequestFormThaa.controls['days'].errors);
      this.isEstimateDate = true;
      this.isExactDate = false;
      this.isRadioValueEmpty = false;
      }
}

tagErrorDays(formValue, error) {
  if (formValue === '') {
    error = this.extensionRequestFormThaa.controls['days'].setErrors({days: true});
  }
}

changeExtensionDate(value) {
  const selectedValue = (this.extensionRequestFormThaa.value.days) ? this.extensionRequestFormThaa.value.days : value;
  if (!selectedValue) {
    this.isExtensionValueEmpty = true;
  } else if (this.isTwoDigit) {
    this.isTwoDigit = true;
  } else {
    this.isExtensionValueEmpty = false;
    this.isTwoDigit = false;
  }
  const estimatedate = this.approveThroughDate;
 }

  clickCancel() {
    if (this.previousUrl) {
      this.previousUrl = this.storageManagementService.getSessionData(SessionConstants.PREVIOUS_URL);
    }
    this.modalService.openModal(AppLabelConstants.CANCEL_MODAL);
  }
  preventDecimal(event) {
    if (event.key === '.') {
      event.preventDefault();
    }
  };

  populateContent() {
    if (this.storageManagementService.getSessionData(SessionConstants.IEXT_OWCS_CONTENT)) {
      this.owcsContent = this.storageManagementService.getSessionData(SessionConstants.IEXT_OWCS_CONTENT);
      this.populateOWCSPageContent()
    } else {
      this.pageId = this.contentService.getPageId(AppLabelConstants.INTERACTIVE_REQUEST_EXTENSION);
      this.contentService.getOWCSResponse(this.pageId, AppLabelConstants.INTERACTIVE_REQUEST_EXTENSION_UN).subscribe(
        val => {
          if (val) {
            this.owcsContent = val.data;
            this.storageManagementService.setSessionData(SessionConstants.IEXT_OWCS_CONTENT, this.owcsContent);
            this.populateOWCSPageContent();
          }
        }, (error) => {
          //console.error(error);
        });
    }

  }

  getOWCSContentSession(label) {
    if (this.owcsContent && this.owcsContent[label]) {
      return this.owcsContent[label].value;
    }
  }
  populateOWCSPageContent() {
    this.thingsToKnow = this.getOWCSContentSession('IEXT_003_Body');
    if (this.thingsToKnow) {
      this.thingsToKnow = this.getOWCSContentSession('IEXT_003_Body').replace(this.appConstant.REGEX_EXP, '');
    }
    this.thingsToKnowBulletOne = this.getOWCSContentSession('IEXT_004_Body');
    if (this.thingsToKnowBulletOne) {
      this.thingsToKnowBulletOne = this.getOWCSContentSession('IEXT_004_Body').replace(this.appConstant.REGEX_EXP, '');
    }
    this.thingsToKnowBulletTwo = this.getOWCSContentSession('IEXT_005_Body');
    if (this.thingsToKnowBulletTwo) {
      this.thingsToKnowBulletTwo = this.getOWCSContentSession('IEXT_005_Body').replace(this.appConstant.REGEX_EXP, '')
    }
    this.thingsToKnowBulletThree = this.getOWCSContentSession('IEXT_006_Body');
    if (this.thingsToKnowBulletThree) {
      this.thingsToKnowBulletThree = this.getOWCSContentSession('IEXT_006_Body').replace(this.appConstant.REGEX_EXP, '');
    }
    this.thingsToKnowBulletFour = this.getOWCSContentSession('IEXT_007_Body');
    if (this.thingsToKnowBulletFour) {
      this.thingsToKnowBulletFour = this.getOWCSContentSession('IEXT_007_Body').replace(this.appConstant.REGEX_EXP, '');
    }
    this.lengthLabel = this.getOWCSContentSession('IEXT_019_Body');
    if (this.lengthLabel) {
      this.lengthLabel = this.getOWCSContentSession('IEXT_019_Body').replace(this.appConstant.REGEX_EXP, '');
    }
  }
}
