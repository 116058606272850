
import {catchError, map} from 'rxjs/operators';
///<reference path="../../../../node_modules/@angular/common/http/src/client.d.ts"/>
import {Injectable} from '@angular/core';
import {LetterListResponse} from '../models/letterList.model';
import {Observable} from 'rxjs';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {env} from '../../../environments/environment-loader';
import {ResponseErrorHandlerService} from './response-error-handler.service';

import {AppLabelConstants} from '../constants/appLabel.constants';

@Injectable()
export class LetterListService {
  public letterList: LetterListResponse;


  constructor( private httpClient: HttpClient,
               private errorHandler: ResponseErrorHandlerService) {
  }

getLettersList(personId: string, claimId: string, routpath: string): Observable<any> {
  const endpoint = (routpath === '/claimDetails' ? env.thaaWKABPortalService.getLettersListURL + '&personid=' + personId + '&claimid=' + claimId : env.thaaWKABPortalService.getLettersListLeaveURL + '&personid=' + personId + '&leaveId=' + claimId);
    return this.httpClient.get(endpoint,
      {
        headers: new HttpHeaders()
          .set(AppLabelConstants.CONTENT_TYPE_KEY, AppLabelConstants.APPLICATION_KEY)
      }).pipe(
      map(
        (response: Response) => {
          if (!this.errorHandler.isServiceResponseHasError(response)) {
            return response;
          } else {
            throw response;
          }
        }),
      catchError((error: any) => this.errorHandler.handleError(error)), );
  }
}
