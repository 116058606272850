
import {throwError as observableThrowError} from 'rxjs/internal/observable/throwError';
import {Observable, Subject} from 'rxjs';

import {catchError, map} from 'rxjs/operators';
/**
 * Created by AC12396 on 1/22/2019.
 */
import {Injectable} from '@angular/core';


import {env} from '../../../environments/environment-loader';


import {ResponseErrorHandlerService} from './response-error-handler.service';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {AppLabelConstants} from '../constants/appLabel.constants';
import {StorageManagementService} from '@shared/services/storage-management.service';
import {of} from 'rxjs/internal/observable/of';

@Injectable()
export class AetnaPaymentsService {
  isDisabilityContactCompleted = new Subject();

  constructor( private errorHandler: ResponseErrorHandlerService,
               private storageManagementService: StorageManagementService,
               private httpClient: HttpClient) { }

  getAetnaDisabilityClaimPayments(personId: string, claimId: string): Observable<any> {
    let Payments: any = [];
    Payments = this.storageManagementService.getSessionData(AppLabelConstants.PAYMENTS_LIST) ? this.storageManagementService.getSessionData(AppLabelConstants.PAYMENTS_LIST) : [];
    let currentPayment;
    if (Payments) {
      Payments.forEach( data => {
        if (data && data.claimId === claimId) {
          currentPayment = data;
        }
      })
    }
    if (currentPayment && currentPayment.claimId === claimId) {
      delete currentPayment['claimId'];
      return of(currentPayment);
    } else {
      return this.httpClient.get(env.thaaWKABPortalService.getClaimPaymentsURL + '&personId=' + personId + '&claimId=' + claimId,
        {
          headers: new HttpHeaders()
            .set(AppLabelConstants.CONTENT_TYPE_KEY, AppLabelConstants.APPLICATION_KEY)
        }).pipe(
        map(
          (response: Response) => {
            if (!this.errorHandler.isServiceResponseHasError(response)) {
              response['claimId'] = claimId;
              const TotalPayments = this.storageManagementService.getSessionData(AppLabelConstants.PAYMENTS_LIST) ? this.storageManagementService.getSessionData(AppLabelConstants.PAYMENTS_LIST) : [];
              TotalPayments.push(response);
              this.storageManagementService.setSessionData(AppLabelConstants.PAYMENTS_LIST, TotalPayments);
              return response;
            } else {
              throw response;
            }
          }
        ), catchError((error: any) => this.errorHandler.handleError(error)), );
    }

  }

  private handleError(error: Response) {
    return observableThrowError(error);
  }
}
