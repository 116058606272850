import {GbBase} from './gb-base';

export class GbEconsent extends GbBase {

  getUrls() {
    return {

      getUserProfileURL:  this.getBaseUrl() + 'gb-econsent-service/econsent/v1/getProfile?' + this.getClientId('auth'),
      //updateProfileServiceURL :  this.getBaseUrl() + 'gb-econsent-service/econsent/v1/updateProfile?' + this.getClientId('auth'),
      addProfileServiceURL :  this.getBaseUrl() + 'gb-econsent-service/econsent/v1/addProfile?' + this.getClientId('auth')

    }
  }
}

