import {GbBase} from './gb-base';

export class Documentum extends GbBase {

  getUrls() {

    return {
      //Documentum
      documentumURL_search  : this.getBaseUrl() + 'gb/ecm/rest/gb/documents/search?version=1&' + this.getThaaClientId('auth'), //Proxy DP connection
      documentumURL_view    : this.getBaseUrl() + 'gb/ecm/rest/gb/documents/view?version=1&' + this.getThaaClientId('auth'),
      uploadToDocumentum    : this.getBaseUrl() + 'gb/ecm/eaupload?' + this.getThaaClientId('auth'), //45
    }
  }
}
