import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
  name: 'jsonArray'
})
export class JsonArrayPipe implements PipeTransform {

  transform(obj: any, args?: any): any {
      return Object.keys(obj).map(key => `${key} | ${obj[key]}`);
    }
  }


