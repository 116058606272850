export const eventOptionsValue = [

      {
        question: 'What event are you filing for?',
        text: 'I\'m having a baby',
        subOptions:          [
          {
            question: 'Are you the pregnant mother?',
            text: 'Yes',
            subOptions:                [
              {
                question: 'What kind of time off do you need?',
                text: 'Continuous time off',
                recommendation:                      {
                  imageUrl: '/assets/images/icons/STD.svg',
                  title: 'It looks like a Short Term Disability is right for you.',
                  description: '<p>Click below to start filing your claim. Please be aware that you\'ll be taken to a different website to file.<\/p><p>You may be enrolled in other group benefits through your employer\'s plan with The Hartford.  If you have questions about the full range of what\'s available to you, contact your employer or human resources administrator.<\/p>',
                  sourceSystemUrl: '/mobile/protected/reportClaim/policyDetailsPrompt.xhtml?covtype=STD',
                  sourceSystemUrlUnAuth: '/mobile/pages/reportClaim/policyDetails.xhtml?covtype=STD&policyNumber=634016',
                  type: 'STD'
                }
              },
              {
                question: 'What kind of time off do you need?',
                text: 'Continuous time off forth select',
                subOptions: [
                  {
                    question: 'What kind of time off do you need?',
                    text: 'Continuous time off forth select more',
                    recommendation:                      {
                      imageUrl: '/assets/images/icons/STD.svg',
                      title: 'It looks like a Short Term Disability is right for you.',
                      description: '<p>Click below to start filing your claim. Please be aware that you\'ll be taken to a different website to file.<\/p><p>You may be enrolled in other group benefits through your employer\'s plan with The Hartford.  If you have questions about the full range of what\'s available to you, contact your employer or human resources administrator.<\/p>',
                      sourceSystemUrl: '/mobile/protected/reportClaim/policyDetailsPrompt.xhtml?covtype=STD',
                      sourceSystemUrlUnAuth: '/mobile/pages/reportClaim/policyDetails.xhtml?covtype=STD&policyNumber=634016',
                      type: 'STD'
                    }
                  }
                ]
              },
              {
                question: 'What kind of time off do you need?',
                text: 'Reduced worked schedule',
                recommendation:                      {
                  imageUrl: '/assets/images/icons/STD.svg',
                  title: 'It looks like a Short Term Disability is right for you.',
                  description: '<p>Click below to start filing your claim. Please be aware that you\'ll be taken to a different website to file.<\/p><p>You may be enrolled in other group benefits through your employer\'s plan with The Hartford.  If you have questions about the full range of what\'s available to you, contact your employer or human resources administrator.<\/p>',
                  sourceSystemUrl: '/mobile/protected/reportClaim/policyDetailsPrompt.xhtml?covtype=STD',
                  sourceSystemUrlUnAuth: '/mobile/pages/reportClaim/policyDetails.xhtml?covtype=STD&policyNumber=634016',
                  type: 'STD'
                }
              },
              {
                question: 'What kind of time off do you need?',
                text: 'Occasional days and hours off',
                recommendation:                      {
                  imageUrl: '/assets/images/icons/Leave.svg',
                  title: 'It looks like a Leave of Absence is right for you.',
                  description: '<p>Click below to start filing your claim. Please be aware that you\'ll be taken to a different website to file.<\/p><p>You may be enrolled in other group benefits through your employer\'s plan with The Hartford.  If you have questions about the full range of what\'s available to you, contact your employer or human resources administrator.<\/p>',
                  sourceSystemUrl: '/sso/SSO?SPEntityID=LeaveManagement&TARGET=https://test1.sit-ssleavemgt.thehartford.com/router.ashx?cmd=sso',
                  registerUrl: '/register',
                  type: 'LEAVE'
                }
              }
            ]
          },
          {
            question: 'Are you the pregnant mother?',
            text: 'No',
            subOptions:                [
              {
                question: 'Do you work in New York?',
                text: 'Yes',
                recommendation:                      {
                  imageUrl: '/assets/images/icons/NYPFL.svg',
                  title: 'It looks like New York Paid Family Leave is right for you.',
                  description: '<p>Start your claim by downloading the appropriate form below. See the form for details on how to return by mail, fax, or email.<\/p><p>You may be enrolled in other group benefits through your employer\'s plan with The Hartford. If you have questions about the full range of what\'s available to you, contact your employer or human resources administrator.<\/p>',
                  links:                         [
                    {
                      displayName: 'NY Paid Family Leave Bond Application',
                      url: '/cm/files/NY_Paid_Family_Leave_Bond_Application.pdf'
                    },
                    {
                      displayName: 'NY PFL Care Family Member Application',
                      url: '/cm/files/NY_PFL_Care_Family_Member_Application.pdf'
                    },
                    {
                      displayName: 'NY PFL Qual Military Event Application',
                      url: '/cm/files/NY_PFL_Qual_Military_Event_Application.pdf'
                    }
                  ],
                  type: 'NYPFL_PAPER'
                }
              },
              {
                question: 'Do you work in New York?',
                text: 'No',
                recommendation:                      {
                  imageUrl: '/assets/images/icons/Leave.svg',
                  title: 'It looks like a Leave of Absence is right for you.',
                  description: '<p>Click below to start filing your claim. Please be aware that you\'ll be taken to a different website to file.<\/p><p>You may be enrolled in other group benefits through your employer\'s plan with The Hartford.  If you have questions about the full range of what\'s available to you, contact your employer or human resources administrator.<\/p>',
                  sourceSystemUrl: '/sso/SSO?SPEntityID=LeaveManagement&TARGET=https://test1.sit-ssleavemgt.thehartford.com/router.ashx?cmd=sso',
                  registerUrl: '/register',
                  type: 'LEAVE'
                }
              }
            ]
          }
        ]
      },
      {
        question: 'What event are you filing for?',
        text: 'I\'m having planned surgery',
        recommendation:          {
          imageUrl: '/assets/images/icons/STD.svg',
          title: 'It looks like a Short Term Disability is right for you.',
          description: '<p>Click below to start filing your claim. Please be aware that you\'ll be taken to a different website to file.<\/p><p>You may be enrolled in other group benefits through your employer\'s plan with The Hartford.  If you have questions about the full range of what\'s available to you, contact your employer or human resources administrator.<\/p>',
          sourceSystemUrl: '/mobile/protected/reportClaim/policyDetailsPrompt.xhtml?covtype=STD',
          sourceSystemUrlUnAuth: '/mobile/pages/reportClaim/policyDetails.xhtml?covtype=STD&policyNumber=634016',
          type: 'STD'
        }
      },
      {
        question: 'What event are you filing for?',
        text: 'My own health condition',
        subOptions:          [
          {
            question: 'What kind of time off do you need?',
            text: 'Continuous time off',
            recommendation:                {
              imageUrl: '/assets/images/icons/STD.svg',
              title: 'It looks like a Short Term Disability is right for you.',
              description: '<p>Click below to start filing your claim. Please be aware that you\'ll be taken to a different website to file.<\/p><p>You may be enrolled in other group benefits through your employer\'s plan with The Hartford.  If you have questions about the full range of what\'s available to you, contact your employer or human resources administrator.<\/p>',
              sourceSystemUrl: '/mobile/protected/reportClaim/policyDetailsPrompt.xhtml?covtype=STD',
              sourceSystemUrlUnAuth: '/mobile/pages/reportClaim/policyDetails.xhtml?covtype=STD&policyNumber=634016',
              type: 'STD'
            }
          },
          {
            question: 'What kind of time off do you need?',
            text: 'Reduced worked schedule',
            recommendation:                {
              imageUrl: '/assets/images/icons/STD.svg',
              title: 'It looks like a Short Term Disability is right for you.',
              description: '<p>Click below to start filing your claim. Please be aware that you\'ll be taken to a different website to file.<\/p><p>You may be enrolled in other group benefits through your employer\'s plan with The Hartford.  If you have questions about the full range of what\'s available to you, contact your employer or human resources administrator.<\/p>',
              sourceSystemUrl: '/mobile/protected/reportClaim/policyDetailsPrompt.xhtml?covtype=STD',
              sourceSystemUrlUnAuth: '/mobile/pages/reportClaim/policyDetails.xhtml?covtype=STD&policyNumber=634016',
              type: 'STD'
            }
          },
          {
            question: 'What kind of time off do you need?',
            text: 'Occasional days and hours off',
            recommendation:                {
              imageUrl: '/assets/images/icons/Leave.svg',
              title: 'It looks like a Leave of Absence is right for you.',
              description: '<p>Click below to start filing your claim. Please be aware that you\'ll be taken to a different website to file.<\/p><p>You may be enrolled in other group benefits through your employer\'s plan with The Hartford.  If you have questions about the full range of what\'s available to you, contact your employer or human resources administrator.<\/p>',
              sourceSystemUrl: '/sso/SSO?SPEntityID=LeaveManagement&TARGET=https://test1.sit-ssleavemgt.thehartford.com/router.ashx?cmd=sso',
              registerUrl: '/register',
              type: 'LEAVE'
            }
          }
        ]
      },
      {
        question: 'What event are you filing for?',
        text: 'Out of work to care for family member',
        subOptions:          [
          {
            question: 'Do you work in New York?',
            text: 'Yes',
            recommendation:                {
              imageUrl: '/assets/images/icons/NYPFL.svg',
              title: 'It looks like New York Paid Family Leave is right for you.',
              description: '<p>Start your claim by downloading the appropriate form below. See the form for details on how to return by mail, fax, or email.<\/p><p>You may be enrolled in other group benefits through your employer\'s plan with The Hartford. If you have questions about the full range of what\'s available to you, contact your employer or human resources administrator.<\/p>',
              links:                   [
                {
                  displayName: 'NY Paid Family Leave Bond Application',
                  url: '/cm/files/NY_Paid_Family_Leave_Bond_Application.pdf'
                },
                {
                  displayName: 'NY PFL Care Family Member Application',
                  url: '/cm/files/NY_PFL_Care_Family_Member_Application.pdf'
                },
                {
                  displayName: 'NY PFL Qual Military Event Application',
                  url: '/cm/files/NY_PFL_Qual_Military_Event_Application.pdf'
                }
              ],
              type: 'NYPFL_PAPER'
            }
          },
          {
            question: 'Do you work in New York?',
            text: 'No',
            recommendation:                {
              imageUrl: '/assets/images/icons/Leave.svg',
              title: 'It looks like a Leave of Absence is right for you.',
              description: '<p>Click below to start filing your claim. Please be aware that you\'ll be taken to a different website to file.<\/p><p>You may be enrolled in other group benefits through your employer\'s plan with The Hartford.  If you have questions about the full range of what\'s available to you, contact your employer or human resources administrator.<\/p>',
              sourceSystemUrl: '/sso/SSO?SPEntityID=LeaveManagement&TARGET=https://test1.sit-ssleavemgt.thehartford.com/router.ashx?cmd=sso',
              registerUrl: '/register',
              type: 'LEAVE'
            }
          }
        ]
      },
      {
        question: 'What event are you filing for?',
        text: 'Long term disability',
        recommendation:          {
          imageUrl: '/assets/images/icons/LTD.svg',
          title: 'It looks like a Long Term Disability is right for you.',
          description: '<p>Click below to start filing your claim. Please be aware that you\'ll be taken to a different website to file.<\/p><p>If you already have an active Short-Term Disability claim with The Hartford, you don\'t have to file separately for Long-Term Disability. Contact your claims representative for more information.<\/p><p>You may be enrolled in other group benefits through your employer\'s plan with The Hartford. If you have questions about the full range of what\'s available to you, contact your employer or human resources administrator.<\/p>',
          sourceSystemUrl: '/thaw/pages/startaclaim/ltdclaimdetails1.html?covtype=LTD',
          sourceSystemUrlUnAuth: '/thaw/pages/startaclaim/ltdclaimdetails1.html?covtype=LTD&policyNumber=634016',
          type: 'LTD'
        }
      },
      {
        question: 'What event are you filing for?',
        text: 'I don\'t see the option I\'m looking for',
        recommendation:          {
          imageUrl: '/assets/images/icons/phone.svg',
          title: 'Let\'s chat instead.',
          description: '<p>Sometimes it can be tough to figure out exactly what benefit you need. Call us at 1-800-549-6514 for help finding the right claim for you.<\/p>',
          type: 'LETSCHAT'
        }
      },
      {
        question: 'What event are you filing for?',
        text: 'I don\'t need help, let me pick',
        subOptions:          [
          {
            question: 'Okay. What are you filing for?',
            text: 'Short Term Disability',
            recommendation:                {
              imageUrl: '/assets/images/icons/STD.svg',
              title: 'It looks like a Short Term Disability is right for you.',
              description: '<p>Click below to start filing your claim. Please be aware that you\'ll be taken to a different website to file.<\/p><p>You may be enrolled in other group benefits through your employer\'s plan with The Hartford.  If you have questions about the full range of what\'s available to you, contact your employer or human resources administrator.<\/p>',
              sourceSystemUrl: '/mobile/protected/reportClaim/policyDetailsPrompt.xhtml?covtype=STD',
              sourceSystemUrlUnAuth: '/mobile/pages/reportClaim/policyDetails.xhtml?covtype=STD&policyNumber=634016',
              type: 'STD'
            }
          },
          {
            question: 'Okay. What are you filing for?',
            text: 'New York Paid Family Leave',
            recommendation:                {
              imageUrl: '/assets/images/icons/NYPFL.svg',
              title: 'It looks like New York Paid Family Leave is right for you.',
              description: '<p>Start your claim by downloading the appropriate form below. See the form for details on how to return by mail, fax, or email.<\/p><p>You may be enrolled in other group benefits through your employer\'s plan with The Hartford. If you have questions about the full range of what\'s available to you, contact your employer or human resources administrator.<\/p>',
              links:                   [
                {
                  displayName: 'NY Paid Family Leave Bond Application',
                  url: '/cm/files/NY_Paid_Family_Leave_Bond_Application.pdf'
                },
                {
                  displayName: 'NY PFL Care Family Member Application',
                  url: '/cm/files/NY_PFL_Care_Family_Member_Application.pdf'
                },
                {
                  displayName: 'NY PFL Qual Military Event Application',
                  url: '/cm/files/NY_PFL_Qual_Military_Event_Application.pdf'
                }
              ],
              type: 'NYPFL_PAPER'
            }
          },
          {
            question: 'Okay. What are you filing for?',
            text: 'Report missed work hours',
            recommendation:                {
              imageUrl: '/assets/images/icons/Leave.svg',
              title: 'It looks like a Leave of Absence is right for you.',
              description: '<p>Click below to start filing your claim. Please be aware that you\'ll be taken to a different website to file.<\/p><p>You may be enrolled in other group benefits through your employer\'s plan with The Hartford.  If you have questions about the full range of what\'s available to you, contact your employer or human resources administrator.<\/p>',
              sourceSystemUrl: '/sso/SSO?SPEntityID=LeaveManagement&TARGET=https://test1.sit-ssleavemgt.thehartford.com/router.ashx?cmd=sso',
              registerUrl: '/register',
              type: 'LEAVE'
            }
          },
          {
            question: 'Okay. What are you filing for?',
            text: 'Submit a new leave of absence request',
            recommendation:                {
              imageUrl: '/assets/images/icons/Leave.svg',
              title: 'It looks like a Leave of Absence is right for you.',
              description: '<p>Click below to start filing your claim. Please be aware that you\'ll be taken to a different website to file.<\/p><p>You may be enrolled in other group benefits through your employer\'s plan with The Hartford.  If you have questions about the full range of what\'s available to you, contact your employer or human resources administrator.<\/p>',
              sourceSystemUrl: '/sso/SSO?SPEntityID=LeaveManagement&TARGET=https://test1.sit-ssleavemgt.thehartford.com/router.ashx?cmd=sso',
              registerUrl: '/register',
              type: 'LEAVE'
            }
          },
          {
            question: 'Okay. What are you filing for?',
            text: 'Long term disability',
            recommendation:                {
              imageUrl: '/assets/images/icons/LTD.svg',
              title: 'It looks like a Long Term Disability is right for you.',
              description: '<p>Click below to start filing your claim. Please be aware that you\'ll be taken to a different website to file.<\/p><p>If you already have an active Short-Term Disability claim with The Hartford, you don\'t have to file separately for Long-Term Disability. Contact your claims representative for more information.<\/p><p>You may be enrolled in other group benefits through your employer\'s plan with The Hartford. If you have questions about the full range of what\'s available to you, contact your employer or human resources administrator.<\/p>',
              sourceSystemUrl: '/thaw/pages/startaclaim/ltdclaimdetails1.html?covtype=LTD',
              sourceSystemUrlUnAuth: '/thaw/pages/startaclaim/ltdclaimdetails1.html?covtype=LTD&policyNumber=634016',
              type: 'LTD'
            }
          },
          {
            question: 'Okay. What are you filing for?',
            text: 'I don\'t see the option I\'m looking for',
            recommendation:                {
              imageUrl: '/assets/images/icons/phone.svg',
              title: 'Let\'s chat instead.',
              description: '<p>Sometimes it can be tough to figure out exactly what benefit you need. Call us at 1-800-549-6514 for help finding the right claim for you.<\/p>',
              type: 'LETSCHAT'
            }
          }
        ]
      }


];
export const Policies = {
  //id: '123456',  removed because of congtract - manish - 2/11/2019
  eventOptions: eventOptionsValue,
  aaSupportedIndicator: true
};
