import { HttpClient, HttpHeaders } from '@angular/common/http';
import { ErrorHandler, Injectable, Injector } from '@angular/core';
import { Observable } from 'rxjs';
import { map, take } from 'rxjs/operators';
import { env } from '@env/environment-loader';
import { AppLabelConstants } from '@app/shared/constants/appLabel.constants';
import { StorageManagementService } from '@shared/services/storage-management.service';
import { SessionConstants } from '@app/shared/constants/session.constants';
import { ILogRequestModel, ILogResponseModel } from '@app/shared/models/log.model';
import { Router } from '@angular/router';
import {AppConstants} from "@shared/constants/app.constants";


@Injectable()
export class ErrorLoggingService extends ErrorHandler {

    constructor(private httpClient: HttpClient,
        private storageManagementService: StorageManagementService,
        private injector: Injector) {
        super();
    }

    handleError(error: Error): void {
        const router = this.injector.get(Router);
        let personId: string = JSON.parse(this.storageManagementService.retrieveSessionObject(SessionConstants.APP_DATA))?.appData?.activeUserId;
        if (!personId) {
          personId = this.storageManagementService.getAppData(SessionConstants.USER_DATA) ?
          this.storageManagementService.getAppData(SessionConstants.USER_DATA)?.personIds[0] : null;
        }
        if(personId) {
          let errMessage = "";
          if(error && error.message) {
            errMessage = error.message;
          } else {
            errMessage = AppLabelConstants.UNKNOWN_ERROR;
          }
          errMessage += (error?.name?.toLowerCase() === 'HttpErrorResponse'.toLowerCase()) ? ' - API Error' : ' - UI Error';

          // do not invoke log call in local for any failures
          const envName = env.envName;
          if(envName !== AppConstants.LOCAL && envName !== AppConstants.LOCAL_QA ) {
            this.logError(personId, errMessage, AppLabelConstants.LOG_LEVEL_ERROR, router?.url)
              .pipe(take(1))
              .subscribe();
          }
        }
        super.handleError(error);
    }

    logError(userId: string, logTextValue: string, loggingLevelValue: string, url: string = ""): Observable<ILogResponseModel> {
        const logRequest: ILogRequestModel = {
            userId,
            logText: logTextValue,
            loggingLevel: loggingLevelValue,
            loggingType: url,
            sessionIdentifier: "",
            profileId: "",
          }
        return this.httpClient.post(env.gbLog.getSessionUrl, JSON.stringify(logRequest),
          {
            headers: new HttpHeaders()
              .set(AppLabelConstants.CONTENT_TYPE_KEY, AppLabelConstants.APPLICATION_KEY)
          }).pipe(
          map((response: ILogResponseModel) => response
          ));
      }
}
