import {Component, ElementRef, Input, OnInit, ViewChild} from '@angular/core';
import {ElementControlComponent} from '../../../sub-atomic/element-control/element-control.component';
import {FormGroup} from '@angular/forms';

@Component({
  selector: 'secondary-text-input',
  templateUrl: 'secondary-text-input.component.html',
  styleUrls: ['secondary-text-input.component.scss']
})
export class SecondaryTextInputComponent extends ElementControlComponent implements OnInit {


  @Input() inputId: string;
  @Input() group: FormGroup;
  @Input() inputValue = '';

  @Input() inputString: string;
  @Input() inputPlaceholder: string;
  @Input() inputType: string;
  @Input() max: number;
  @Input() maxlength: string;
  @Input() minlength: string;
  @Input() labelName: string;
  @Input() readOnlyCondition: boolean;
  @Input() inputSize?: string;
  @Input() ariaLabel: string;
  @Input() preRenderingLoad = false;

  @ViewChild('input', {static: false}) nativeElem: ElementRef;

  private passwordState = false;
  ngOnInit() {
    if (this.inputType === 'password') {
      this.passwordState = true;
    }
  }

  togglePassword() {
    if (this.inputValue) {
      this.nativeElem.nativeElement.setAttribute('type', 'text');
    }
  }
  getMouseUp() {
    if (this.inputValue) {
      this.nativeElem.nativeElement.setAttribute('type', 'password');
    }
  }

  getMask() {
    if (this.inputType === 'tel') {
      return {
        mask: ['(', /[1-9]/, /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/],
        guide: true,
        placeholderChar: '_',
        keepCharPositions: true
      }
    }
  }
}

