import {GbCiamBase} from './gb-ciam-base';

export class GbCiamOrch extends GbCiamBase {

  getUrls() {
    return {

    }
  }
}

