import {Component, Input, OnInit} from '@angular/core';
import {StorageManagementService} from '../../../../shared/services/storage-management.service';
import {AppLabelConstants} from '../../../../shared/constants/appLabel.constants';

@Component({
  selector: 'app-secondary-message-ribbon',
  templateUrl: './secondary-message-ribbon.component.html',
  styleUrls: ['./secondary-message-ribbon.component.scss']
})
export class SecondaryMessageRibbonComponent implements OnInit {
  @Input() messageType: string = AppLabelConstants.WARNING_SM;
  @Input() messageHeader: string = AppLabelConstants.SORRY;
  @Input() messageContent = AppLabelConstants.SOMETHING_WRONG_WITH_REQ;
  @Input() labelWeight = AppLabelConstants.BOLD;
  @Input() labelColor = '';
  @Input() labelSize = AppLabelConstants.LARGER;
  public showRibbon;
  public appLabelConstants = AppLabelConstants;

  constructor( private StorageService: StorageManagementService) { }

  ngOnInit() {
    this.StorageService.toggleMsgRibbon.subscribe((val) => {
      this.showRibbon = val;
    })
  }
  hideRibbon() {
    this.StorageService.toggleMsgRibbon.next(false);
  }
}
