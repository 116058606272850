export const ZIP_CODE = {
    locations: [
        {
          city: 'KETCHIKAN',
          stateLocation: 'AK',
          stateName: 'Alaska'
        }
      ],
      responseCode: '001',
      responseReason: 'success'
}
