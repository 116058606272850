import {AfterViewInit, Component, OnInit} from '@angular/core';
import {Security} from '../../shared/models/security.model';
import {ContentService} from '../../shared/services/content.service';
import {LeaveDynatraceAction} from '../../shared/utils/leaveDynatraceAction';
import {StorageManagementService} from '../../shared/services/storage-management.service';
import {AppConstants} from '../../shared/constants/app.constants';


@Component({
  selector: 'app-security-statement',
  templateUrl: 'security-statement.component.html',
  styleUrls: ['security-statement.component.scss']
})
export class SecurityStatementComponent extends LeaveDynatraceAction implements OnInit, AfterViewInit {

  public securityObj: Security;
  private pageId = '';
  private owscContent;
  public securityStmtContent = '';
  constructor(private contentService: ContentService,
              private storageManagementService: StorageManagementService) {
    super(storageManagementService);
  }

  ngOnInit() {
    //OWCS service Integration
    this.pageId = this.contentService.getPageId(AppConstants.SECURITY_STATEMENT);
    if (this.pageId) {
      this.contentService.getOWCSResponse(this.pageId, AppConstants.SECURITY_STATEMENT_U).subscribe(
          val => {
          if (val) {
            this.owscContent = val;
            if (this.owscContent && this.owscContent.data) {
              if (this.owscContent.data.SS_01_Body) {
                this.securityStmtContent = this.owscContent.data.SS_01_Body.value;
              }
            }
          }
        }, (error) => {//console.error(error);
      });
    }
  }

  ngAfterViewInit() {
    super.ngAfterViewInit();
  }
  /*onPrint(){
    window.print();
  }*/
}
