/**
 * Created by AC12214 on 9/19/2017.
 */

export const BANKDETAILS = {
    eftId: '9001449187',
    beneficiaryID: null,
    externalABANumber: '322078930',
    externalAccountNumber: '132451235856',
    externalAccountName: 'LOZZZZZDSER KIZZZZSDE',
    externalAccountType: 'Saving',
    modelEffectiveDate: '07/31/2018',
    dailyAmount: '100000',
    totalAmount: '99999999',
    statusIndicator: null,
    processorID: '235469',
    approverProcessorID: '235469',
    modelTerminationDate: null,
    processDate: '07/31/2018',
    lastUsedDate: null,
    modelExpirationDate: null,
    bankName: 'PRIORITY ONE CREDIT UNION',
    authorizedUsers: 'Test',
    confirmEft: false,
    statusMessage: 'successful',
    statusCode: '0',
    suppressNotifyChecked: '1'
  };
