export const ResetPasswordResp = {
  _id: '8c29bbc7-724b-41e9-b5d9-cf4824f8709f',
  _rev: '8',
  userName: 'Mirudhu.Bhashini@thehartford.com',
  givenName: 'Mirudhu',
  sn: 'Bhashini',
  mail: 'Mirudhu.Bhashini@thehartford.com',
  telephoneNumber: '01234587',
  idpData: null,
  accountStatus: 'active',
  lastChanged: {date: '2018-01-23T07:36:38.773Z'},
  effectiveRoles: [],
  effectiveAssignments: [],
  preferences:    {
    updates: false,
    marketing: false
  }
};



