
export const MOCK_OVERPAYMENTS = {
      overpaymentInfoList: [
        {
          ClaimId: '131284',
          OverpaymentDueAmount: '$2800.00',
          overPaymentHistory: [
            {
              date: '04/01/2022',
              amount: '$150.00'
            },
            {
              date: '03/01/2022',
              amount: '$160.00'
            },
            {
              date: '02/01/2022',
              amount: '$120.00'
            },
            {
              date: '01/01/2022',
              amount: '$190.56'
            }
          ],
          selectedAmount: '150.00'
        },
        {
          ClaimId: '1567005',
          OverpaymentDueAmount: '$700.12',
          overPaymentHistory: [
            {
              date: '05/01/2022',
              amount: '$128.75'
            },
            {
              date: '02/01/2022',
              amount: '$177.93'
            }
          ]
        },
        {
          ClaimId: '100028',
          OverpaymentDueAmount: '$240.00',
          overPaymentHistory: []
        },
        {
          ClaimId: '1568988',
          OverpaymentDueAmount: '$20.56',
          overPaymentHistory: [
            {
              date: '07/01/2022',
              amount: '$12.40'
            }
          ]
        }
      ],
  responseCode: '001',
  responseReason: 'Success'
};
