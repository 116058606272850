import { Injectable } from '@angular/core';
import { SessionConstants } from '../constants/session.constants';
import { AppConstants } from '../constants/app.constants';
import { StorageManagementService } from './storage-management.service';
import { Faq } from '../models/faq.model';
import { FuturepaymentInfoList } from '../models/futurePayments.model';
import { RelatedClaims } from '../models/relatedClaims.model';

@Injectable()
export class InternalFaqService {
  private currentClaim: RelatedClaims;
  private futurePayments: FuturepaymentInfoList;
  private faqToReturn = [];
  private additionalFaqQuestions = [
    { QID: 'QID_250', rules: [this.isWD], priority: 1 },
    { QID: 'QID_251', rules: [this.isWDOrPFL, this.applicMinPayment] },
    { QID: 'QID_252', rules: [this.isWDOrPFL, this.applicMaxPayment] },
    { QID: 'QID_255', rules: [this.isWDOrPFL, this.below100NontaxablePercent] },
    { QID: 'QID_256', rules: [this.isWD, this.below100NontaxablePercent, this.fiFundingMethod] },
    { QID: 'QID_257', rules: [this.isPFL, this.below100NontaxablePercent, this.fiFundingMethod] },
    { QID: 'QID_267', rules: [this.isWD], priority: 2 },
    { QID: 'QID_267', rules: [this.isPFL], priority: 1 }
  ];

  constructor(
    private storageService: StorageManagementService) {}

  public getQuestionsThatNeedToBeIncluded() {
    const claimSession = JSON.parse(
      this.storageService.retrieveSessionObject(SessionConstants.CUR_CLAIM)
    );
    if (claimSession) {
      this.currentClaim = claimSession.curClaim;
      if (this.currentClaim !== null) {
        this.futurePayments = this.getFuturePaymentsInfo();
        if (this.futurePayments) {
          this.faqToReturn = [];
          this.additionalFaqQuestions.forEach((question) => {
            if (question.rules.every((e) => e.call(this)) && this.faqToReturn.map(e => e.QID).indexOf(question.QID) === -1) {
              this.faqToReturn.push(question);
            }
          });
        }
      }
    }
  }

  public addAdditionalQuestions(faqs: Faq[]) {
    //Remove/Insert FAQs into list of outgoing FAQs
    if (this.futurePayments !== null && this.faqToReturn.length > 0) {
      faqs = faqs.filter(e => this.faqToReturn.map(i => i.QID).indexOf(e.id) !== -1 );
      this.faqToReturn.forEach(question => {
        const index = faqs.map((k) => k.id).indexOf(question.QID);
        if (question.priority && index !== question.priority - 1) {
          this.moveQuestion(faqs, index, question.priority - 1);
        }
      });
    }

    return faqs;
  }

  private isPFL() {
    return this.currentClaim.coverageCategory === AppConstants.PFL;
  }

  private isWD() {
    return this.currentClaim.coverageCategory === AppConstants.WD;
  }

  private isWDOrPFL() {
    const someVal = this.currentClaim.coverageCategory === AppConstants.WD ||
    this.currentClaim.coverageCategory === AppConstants.PFL;
    return (
      this.currentClaim.coverageCategory === AppConstants.WD ||
      this.currentClaim.coverageCategory === AppConstants.PFL
    );
  }

  private applicMinPayment() {
    return this.futurePayments.payments.some((e) => e.isMinPayment);
  }

  private applicMaxPayment() {
    return this.futurePayments.payments.some((e) => e.isMaxPayment);
  }

  private below100NontaxablePercent() {
    return this.futurePayments.nonTaxablePct < 100;
  }

  private fiFundingMethod() {
    return this.currentClaim.fundingMethod === AppConstants.FI;
  }

  private getFuturePaymentsInfo(): FuturepaymentInfoList {
    const sessionData = this.storageService.retrieveSessionObject(SessionConstants.FUTURE_PAYMENTS);
    if (sessionData) {
      return JSON.parse(sessionData).futurePayments;
    }
  }

  moveQuestion(arr, old_index, new_index) {
    if (new_index >= arr.length) {
      let k = new_index - arr.length + 1;
      while (k--) {
        arr.push(undefined);
      }
    }
    arr.splice(new_index, 0, arr.splice(old_index, 1)[0]);
  }
}
