/**
 * Created by AC12326 on 12/7/2017.
 */

export const MudiCoverageMockContent = {
  coverageResponseList:
    [{
      partyRefId: '00001',
      coverageCategoryCd: 'ACC',
      coverageCategoryDesc: 'Accident',
      coverageTypeCode: 'BTRM',
    },
      {
        partyRefId: '00001',
        coverageCategoryCd: 'CI',
        coverageCategoryDesc: 'Critical Illness',
        coverageTypeCode: 'BTRM',
      },
      {
        partyRefId: '00002',
        coverageCategoryCd: 'CI',
        coverageCategoryDesc: 'Critical Illness',
        coverageTypeCode: 'BTRM',
      },
      {
        partyRefId: '00002',
        coverageCategoryCd: 'LTD',
        coverageCategoryDesc: 'Long Term Disability',
        coverageTypeCode: 'SDEP',

      },
      {
        partyRefId: '00002',
        coverageCategoryCd: 'STD',
        coverageCategoryDesc: 'Short Term Disability',
        coverageTypeCode: 'ABIL',
      }
    ]
}
