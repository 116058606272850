
import {throwError as observableThrowError} from 'rxjs/internal/observable/throwError';
import {of, Observable, Subject} from 'rxjs';

import {catchError, map} from 'rxjs/operators';
import { GbCiamCommonUtil } from './../../../environments/urlFactory/gb-ciam-common-util';
/*
 Service class for handing the "what" of session/locally stored objects.
 Connects to another service for the saving/retrieving/removing function.
 */
import {Injectable} from '@angular/core';
import {StorageManagementService} from './storage-management.service';
import {ActivatedRouteSnapshot, Router, RouterStateSnapshot} from '@angular/router';
import {env} from '../../../environments/environment-loader';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {ResponseErrorHandlerService} from './response-error-handler.service';
import {MessageRibbonService} from './message-ribbon.service';
import {TokenUtility} from '../utils/tokenUtility';
import {ReferenceHelperService} from './reference-helper.service';
import {SessionConstants} from '../constants/session.constants';
import {AppConstants} from '../constants/app.constants';
import {AppLabelConstants} from '../constants/appLabel.constants';
import {ErrorConstants} from '../constants/error.constants';
import {ModalService} from './modal.service';
import {OverpaymentSessionConstants} from '@app/cxr/overpayment/shared/constants/overpayment-session.constants';

@Injectable()
export class AuthStateService {
  public sfdUrl =  env.gbCiamCommonUtil.SfdAccountUrl + '/navigation/logout' ;
  public landingPage_URL = encodeURI(window.location.origin);
  private loginState: boolean = false;
  private WarnState: boolean = true;
  inactiveOpen: boolean = true;
  inactiveOpenChange: Subject<boolean> = new Subject<boolean>();


  private currentModal: string;
  private currentOpenedModal: string;


  private modalClosed = new Subject();
  private modalOpenedSource = new Subject();


  modalClosed$ = this.modalClosed.asObservable();
  modalOpened$ = this.modalOpenedSource.asObservable();

  private sessionTimeoutModal = AppLabelConstants.SESSION_TIME_OUT_MODAL;


  constructor(private sessionManagementService: StorageManagementService,
              private router: Router,
              public httpClient: HttpClient,
              private messageRibbonService: MessageRibbonService,
              private modalService: ModalService,
              private referenceHelper: ReferenceHelperService,
              private errorHandler: ResponseErrorHandlerService) {
  }

  closeModal(modalName: string) {
    this.modalClosed.next(modalName);
    if ( this.modalService.flagOpen === true) {
      this.modalService.openModal(this.currentOpenedModal);
    }
  }

  openModal(modalName: string) {
    this.currentModal = modalName;
    this.modalOpenedSource.next(modalName);
  }

  getActiveModal() {
    return this.currentModal;
  }

  closeActiveModal() {
    this.closeModal(this.currentModal);
  }


  /** === Login Setter ===
   * The purpose of this is to assign the base login state identifier
   * Set to private so only auth state can assign it and it is only
   * accessible through teh get method.
   */

  logIn() {
    this.sessionManagementService.setSessionObject(SessionConstants.LOGIN_STATE, true);
    this.referenceHelper.setReferenceOption(SessionConstants.LOGIN_STATE, AppConstants.TRUE);
    TokenUtility.setCurrentFrToken();
  };

  /** === Getter method for login state === */
  getLoginState() {
    const curState = JSON.parse(this.sessionManagementService.retrieveSessionObject('loginState'));
    return (curState && TokenUtility.doRequiredTokensExist());
    //return TokenUtility.doRequiredTokensExist();
  };


  /** === Logout ===
   * This is the all encompasing logout function. This wll:
   * 1. Eliminate all the necessary tokens
   * 2. Clear the Session Storage
   * 3. Log the user out of the CIAM session
   * 4. Route the user back to login
   * 5. Display a temporary modal indicating success
   * */
  warnout( ) {
    /* Step 3 calling the forge rock loggout.*/
    /*const impersonateState = this.sessionManagementService.getSessionData(SessionConstants.INTERNAL_ADMIN);
    if ((env.proxyState || env.envName !== AppConstants.LOCAL) && !(impersonateState)) {*/
    if ( this.modalService.flagOpen === true) {
      this.currentOpenedModal = this.modalService.getActiveModal();
      this.modalService.closeModal(this.modalService.getActiveModal());
      this.modalService.flagOpen = true;
      //this.presentTimeoutWarnMessage();
    }
    this.inactiveOpen = !this.inactiveOpen;
    this.getActiveOpen();
    this.openModal(this.sessionTimeoutModal);
    this.WarnState = true;
  }

  getActiveOpen() {
    this.inactiveOpenChange.next(this.inactiveOpen);
  }

   logout( isAuto: boolean = false ) {
     const hideOverpaymentAlert = this.sessionManagementService.getSessionData(OverpaymentSessionConstants.HIDE_OVERPAYMENT_ALERT);
     if (isAuto === false) {
      if (hideOverpaymentAlert) {
        this.sessionManagementService.setSessionData(OverpaymentSessionConstants.HIDE_OVERPAYMENT_ALERT_ON_LOGOUT, 'true');
      }
    }

    const pathName = this.sessionManagementService.getSessionData('previousRoute'); // "/myAccount/profile"
    const completeUrl = this.landingPage_URL + pathName;
    sessionStorage.clear();
    window.location.href = env.gbCiamCommonUtil.SfdAccountUrl + '/navigation/logout';
    if (isAuto) {
      window.location.href = this.setUrlParameter(this.sfdUrl, 'goto', completeUrl);
    }
  }
  setUrlParameter(url, key, value) {
    const baseUrl = url.split('?')[0];
    const urlQueryString = '?' + url.split('?')[1];
    const newParam = key + '=' + value;
    const  params = '?' + newParam;
    return baseUrl + params;
  }
  getSessionIdentifier(userIdValue, logTextValue, loggingLevelValue): Observable<any> {

    const request: string = JSON.stringify({
        userId: userIdValue,
        logText: logTextValue,
        loggingLevel: loggingLevelValue
      }
    );
    return this.httpClient.post(
      this.getLoginState() ?
        env.gbLog.getSessionUrl : env.gbLog.getSessionUrlUnauth, request,
      {
        headers: new HttpHeaders()
          .set(AppLabelConstants.CONTENT_TYPE_KEY, AppLabelConstants.APPLICATION_KEY)
      }).pipe(
      map((response: Response) => {
          if (!this.errorHandler.isServiceResponseHasError(response)) {
            return response;
          } else {
            throw response;
          }
        }
      ), catchError((error: any) => this.errorHandler.handleError(error)), );
  }
  /* This needs to be able to not cause any sort of loops*/
  canActivate(route: ActivatedRouteSnapshot,
              state: RouterStateSnapshot): any {
    /**
     * Storing the channel id for future use incase the url changes to remove it
     */
    if (route.queryParams[SessionConstants.CHANNEL_ID]) {
      this.sessionManagementService.setSessionData(SessionConstants.CHANNEL_ID, route.queryParams[SessionConstants.CHANNEL_ID]);

      /**
       * In this block we check for the impersonation & SSO channel Ids. If they are found we have to do a bit of cleanup on the app.
       * This helps when the user decides to close the tab without logging out
       */
      /*if(route.queryParams['channelId'] === '1') {
        TokenUtility.removeMyBenefitsToken();
      } else*/ if ((route.queryParams[SessionConstants.CHANNEL_ID] === AppConstants.CHANNEL_ID_3 || route.queryParams[SessionConstants.CHANNEL_ID] === AppConstants.CHANNEL_ID_4) &&  route.queryParams[SessionConstants.AUTH_TOKEN]) {
        sessionStorage.clear();
        //TokenUtility.removeMyBenefitsToken();
        this.sessionManagementService.setSessionObjectNoJSON(SessionConstants.AUTH_TOKEN, route.queryParams[SessionConstants.AUTH_TOKEN]);
      }
    }

    /* Quick check to see if the current login is invalid. Logs the User out in this event*/
    //if(!TokenUtility.getExistingFrTokenState()){
    //this.logout(true);
    //return false;
    //}

    /**
     * Proceeding forward
     return this.getCurrentAuthToken(route.queryParams['channelId']).map(
     data => {
        if (data) {
          this.logIn();
        }
        return data;
      }
     ); */

    if (this.getExistingForgeRockToken(document.cookie, 'EESession' )) {
      const getAuthorize = this.getExistingForgeRockToken(document.cookie, 'EESession' );
      if (getAuthorize) {
        this.logIn();
      }
      return true;
    }
  }
  getExistingForgeRockToken(cookie, key) {
    let bodyFrToken; let frToken;
    if (cookie) {
      bodyFrToken = cookie.split(';').find(x => x.includes(key));
      if (bodyFrToken) {
        frToken = bodyFrToken.split('=')[1];
      }
    }
    return frToken;
  }
  canActivateChild(route: ActivatedRouteSnapshot,
                   state: RouterStateSnapshot) {

    const frToken = TokenUtility.getExistingFrToken();
    const eeToken = this.getExistingEEToken(null);

    return true;
  }

  getExistingEEToken(inboundChannelId?, forceReset?) {
    if (forceReset === true) {
      return null;
    }
    if (inboundChannelId && parseInt(inboundChannelId, 10) === 3) {
      this.logIn();
      return null;
    }
    let bodyEeToken; let EeToken;
    if (document.cookie) {
      bodyEeToken = (document.cookie.split(';').find(x => x.includes('EESession')));
      if (bodyEeToken) {
        EeToken = bodyEeToken.split('=')[1];
      }
    }
    return EeToken;
  }
  // if(TokenUtility.FR_TOKEN_LATEST !== TokenUtility.getExistingFrToken()){
  //   return null;
  // }
  // return TokenUtility.getExistingMyBenefitsToken();


  forceAuthorizationTokenReset(): Observable<boolean> {
    TokenUtility.removeMyBenefitsToken();
    return this.getCurrentAuthToken(
      (this.sessionManagementService.getSessionData(SessionConstants.CHANNEL_ID) ? this.sessionManagementService.getSessionData(SessionConstants.CHANNEL_ID) : 0),
      true);
  }

  getCurrentAuthToken(inboundChannelId?, forceReset?): Observable<boolean> {
    if ( env.mocking ) {
      return of( true );
    } else {
      if (((env.proxyState && (env.envName === AppConstants.LOCAL || env.envName === AppConstants.LOCAL_QA)) || (env.envName !== AppConstants.LOCAL && env.envName !== AppConstants.LOCAL_QA)) && this.getExistingEEToken(inboundChannelId, forceReset) == null) {
        const selectedAuthorization = '';
        switch (parseInt(inboundChannelId, 10)) {
          //  case 1: selectedAuthorization = env.gbAuthorization.authorizationSSO; break;
          // case 3: selectedAuthorization = env.gbAuthorization.authorizationImpersonate; break;
          // case 4: selectedAuthorization = env.gbAuthorization.authorizationImpersonate; break;
          // default: selectedAuthorization = env.gbAuthorization.authorization; break;
        }
        if (parseInt(inboundChannelId, 10) === 3 || parseInt(inboundChannelId, 10) === 4) {
          this.sessionManagementService.setSessionData(SessionConstants.INTERNAL_ADMIN, true);
        }
        return this.httpClient
          .get(selectedAuthorization,
            {
              headers: new HttpHeaders().set(AppLabelConstants.CONTENT_TYPE_KEY, AppLabelConstants.APPLICATION_KEY)
            }
          ).pipe(map((res: CrToken) => {
            // if (env.envName === 'local' || (env.envName === 'qa' && env.proxyState)) {
            //    document.cookie = 'EESession=' + res.token_type + ' ' + res.access_token + '; path=/;';
            //  } else {
            //    document.cookie = 'EESession=' + res.token_type + ' ' + res.access_token + '; domain=.thehartford.com; path=/;';
            //  }
            TokenUtility.addMyBenefitsToken(res.token_type + ' ' + res.access_token);
            if (res.partyRefId && res.newRegistrationInd && res.partyRefId.length > 0 && res.newRegistrationInd.length > 0) {
              this.sessionManagementService.setSessionData(SessionConstants.SSO_PARTY_REF_ID, res.partyRefId);
              this.sessionManagementService.setSessionData(SessionConstants.SSO_NEW_REG_IND, res.newRegistrationInd);
            }
            return true;
          }), catchError(
            error => {
              this.errorHandler.handleError(error, true);
              return of(false);
            }), );
        /**        FIX for Back Button Navigation not working for /home
         *         If the environment is local(just temporarily for development) OR we already have crToken in session storage we can navigate to home
         *         Scenario : Once user logs in and navigates to Profile or Claim Details Page from home and comes back to /home this guard is activated again
         *         but this guard returns Observable of false and we never navigate back to home i.e Claims Overview Page
         *         Please review below condition if this is as per the requirement
         */
      } else if ((this.getExistingEEToken(null) != null) || (env.envName === AppConstants.LOCAL || env.envName === AppConstants.LOCAL_QA)) {
        return of(true);
      } else {
        return of(false);
      }
    }
  }

  public getSessionInfo(): Observable<any> {
    return this.httpClient.post(env.gbCiamCommonUtil.SessionInfoApiURL,
      {
        headers: new HttpHeaders()
          .set(AppLabelConstants.CONTENT_TYPE_KEY, AppLabelConstants.APPLICATION_KEY)
      }).pipe(map(
      (response) => {
        if (!this.errorHandler.isServiceResponseHasError(response)) {
          return response;
        } else {
          throw response;
        }
      }
    ), catchError((error) => this.errorHandler.handleErrorThaa(error)), );
  }

  private handleError(error: Response) {
    return observableThrowError(error);
  }
}

interface CrToken extends Response {
  access_token: string;
  expires_in: number;
  token_type: string;
  partyRefId: string;
  newRegistrationInd: string;
}
