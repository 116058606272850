import {Component, OnInit} from '@angular/core';
import {ContentService} from '../../../shared/services/content.service';
import {AuthStateService} from '../../../shared/services/auth-state.service';
import {StorageManagementService} from '../../../shared/services/storage-management.service';
import {Router} from '@angular/router';
import {SelfLinkingService} from '../../../shared/services/self-linking.service';
import {AppLabelConstants} from '../../../shared/constants/appLabel.constants';
import {AppConstants} from '../../../shared/constants/app.constants';
import {SessionConstants} from '../../../shared/constants/session.constants';

@Component({
  selector: 'app-leave-case',
  templateUrl: './leave-case.component.html',
  styleUrls: ['./leave-case.component.scss']
})
export class LeaveCaseComponent implements OnInit {

  public label1 = '';
  public label2 = '';
  public label3 = '';
  public appLabelConstant = AppLabelConstants;
  private pageId: string;
  private owscContent;
  private isELGweak = false;
  private eligibilityID: number;
  private jsonBOM;

  constructor(private contentService: ContentService,
              private router: Router,
              private authStateService: AuthStateService,
              private storageManagementService: StorageManagementService,
              private selfLinkingService: SelfLinkingService) { }

  ngOnInit() {

    //this.sessionIdentifier=this.storageManagementService.getSessionIden();
    this.jsonBOM = JSON.parse(this.storageManagementService.retrieveSessionObject(SessionConstants.APP_DATA)).appData;

    if (this.jsonBOM.userData.sourcePartyDetailsList) {
      for (let i = 0; i < this.jsonBOM.userData.sourcePartyDetailsList.length; i++) {
        if (this.jsonBOM.userData.sourcePartyDetailsList[i].srcSystemCd === AppConstants.SRC_SYS_CD_2) { //DCS Eligibility
          this.isELGweak = false;
          if (this.jsonBOM.userData.sourcePartyDetailsList[i].linkTypeCd === AppConstants.LINK_TYPE_CD_2) { //weak DCS Eligibility for self linking
            this.isELGweak = true;
            this.storageManagementService.setSessionData(SessionConstants.ELIGIBILITY_ID, this.jsonBOM.userData.sourcePartyDetailsList[i].srcSysId.toString());
            this.eligibilityID = this.jsonBOM.userData.sourcePartyDetailsList[i].srcSysId;
          }
        }
      }
    }
      if (this.eligibilityID) {
        this.isELGweak = true;
      } else {
        this.isELGweak = false;
      }


    //OWCS service Integration
    if (this.authStateService.getLoginState() === true) {
      this.pageId = this.contentService.getPageId(AppConstants.CLAIM_OVERVIEW);
      if (this.pageId) {
        this.populateContent(this.pageId);

      } else {
        //calling the sites service again
        this.contentService.getSiteMap().subscribe(
          val => {
            if (val) {
              const siteMapResponse = val;
              this.storageManagementService.setSessionData(SessionConstants.SITE_MAP_DATA, siteMapResponse);
              this.pageId = this.contentService.getPageId(AppConstants.CLAIM_OVERVIEW);
              this.populateContent(this.pageId);
            }
          }, (error) => {
            console.log('Error:' + error);
        });
      }
    }
  }

  onClickLeave() {
    if (this.isELGweak) {
      this.router.navigate([AppLabelConstants.URL_CONFIRM_ELG]);
    } else {  // navigation to REED
      if (this.storageManagementService.getAppData(SessionConstants.LEAVE_SSO_URL)) {
        let leaveList = this.storageManagementService.getAppData(SessionConstants.LEAVE_SSO_URL);
        leaveList = leaveList.filter((res) => (res.claimEventId === this.jsonBOM.caseData.defaultCaseId));
        if (leaveList.length > 0) {
          window.open(leaveList[0].url);
        }
    } else if (this.jsonBOM && this.jsonBOM.caseData.defaultCaseId) {
        const defaultCaseId = this.jsonBOM.caseData.defaultCaseId;
        if (defaultCaseId) {
          // leave sso service integration for REED  url
          this.selfLinkingService.getLeaveSSO(defaultCaseId).subscribe(
            (val: any) => {
              if (val) {
                /*console.log(val);*/
                const reedURL = val.url;
                window.open(reedURL);  // navigation to REED URL in new window
              }
            },
            (error) => {
              console.log('Error:' + error);
            }
          )
        }
      }
    }
  }

 /* navToreed(){
    window.open(sessionStorage.getItem('reedSSOURL'));
  }*/

  populateContent(pageId) {
    if(this.pageId) {
      this.contentService.getOWCSResponse(this.pageId, AppConstants.CLAIM_OVERVIEW_U).subscribe(
        val => {
          if (val) {
            this.owscContent = val;
            if (this.owscContent && this.owscContent.data) {
              if (this.owscContent.data.CO_77_Body) {
                this.label1 = this.owscContent.data.CO_77_Body.value;
              }
              if (this.owscContent.data.CO_78_Body) {
                this.label2 = this.owscContent.data.CO_78_Body.value;
              }
              if (this.owscContent.data.CO_79_Body) {
                this.label3 = this.owscContent.data.CO_79_Body.value;
              }
            }
          }
        },
        (error) => {
          console.error(error);
        });
    }

  }
}
