import {Component, Input, OnInit} from '@angular/core';


@Component({
  selector: 'bubble-container',
  templateUrl: 'bubble-container.component.html',
  styleUrls: ['bubble-container.component.scss'],
})
export class BubbleContainerComponent implements OnInit {
  @Input() pending: false;
  @Input() wide: false;
  @Input() medium: false;

  public currentUser;
  constructor(
  ) {}


  ngOnInit(): void {
  }

}
