import {AfterViewInit, Component, OnInit, Output} from '@angular/core';
import {Router} from '@angular/router';
import {ModalService} from '../../shared/services/modal.service';
import {User} from '../../shared/models/user.model';
import {StorageManagementService} from '../../shared/services/storage-management.service';
import {env} from '../../../environments/environment-loader';
import {LeaveDynatraceAction} from '../../shared/utils/leaveDynatraceAction';
import {ResponseErrorHandlerService} from '../../shared/services/response-error-handler.service';
import {MessageRibbonService} from '../../shared/services/message-ribbon.service';
import {ReplaySubject} from 'rxjs';
import {TokenUtility} from '../../shared/utils/tokenUtility';
import {ContentService} from '../../shared/services/content.service';
import {AppLabelConstants} from '../../shared/constants/appLabel.constants';
import {SessionConstants} from '../../shared/constants/session.constants';
import {AppConstants} from '../../shared/constants/app.constants';
import {DomSanitizer, SafeValue} from "@angular/platform-browser";


@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['login.component.scss']
})
export class LoginComponent extends LeaveDynatraceAction implements OnInit, AfterViewInit {

  /* Properties of the login component.
   * Stores Relative information to deal with authenticating
   * a user. */
  public appLabelConstants = AppLabelConstants;
  public people: any;
  public peopleNames: any;
  textTCOutdated: any = [];
  public insuredId;
  public partyrefId;
  public caseId;
  public user: User;                    //The user who is in the application
  public appData: any = {};
  @Output() activeIndicator: string;
  @Output() mockIndicator: boolean;
  private getSysDownMsg;
  private loginSysDownKey: string = '';
  public isRegisterLinkDisabled = false;
  public landingPage_URL = window.location.host;
  public sfd_login_url = env.gbCiamCommonUtil.SfdAccountUrl;
  landingUrl: SafeValue;
  isBeginNewClaimDisabled: boolean = false;
  public isRecaptchaDisabled: boolean = false;
  constructor(private router: Router,
              private sanitizer: DomSanitizer,
              private modalService: ModalService,
              private storageManagementService: StorageManagementService,
              private errorHandlerService: ResponseErrorHandlerService,
              private contentService: ContentService,
              private messageRibbon: MessageRibbonService

  ) {
    super(storageManagementService);
  }

  ngOnInit(): void {
    const origin = window.location.origin;
    this.landingUrl = this.sanitizer.bypassSecurityTrustUrl(origin);
    this.initializeObjects();
    this.storageManagementService.setSessionData(SessionConstants.CONTINUE_CLICKED, false);
    this.storageManagementService.isFeaturesTurnOnOffDataSetInSession = new ReplaySubject<boolean>(1);
    this.contentLoad();
    if (this.storageManagementService.getSessionData(AppConstants.IS_PRE_AUTH_INTAKE_DISABLED)) {
      this.isBeginNewClaimDisabled = this.storageManagementService.getSessionData(AppConstants.IS_PRE_AUTH_INTAKE_DISABLED);
    }
    if (this.storageManagementService.getSessionData(AppConstants.IS_RECAPTCHA_DISABLED)) {
      this.isRecaptchaDisabled = this.storageManagementService.getSessionData(AppConstants.IS_RECAPTCHA_DISABLED);
    }
  }

  PreAuthNewClaimFeatureToggle(){

    this.errorHandlerService.getSysDownTime(false, AppConstants.IS_PRE_AUTH_INTAKE_DISABLED).subscribe(
      val => {
        if (val.message && val.message.length > 0) {
          this.isBeginNewClaimDisabled = val.message[0].value.toLowerCase().indexOf(AppConstants.TRUE) !== -1  ? true : false;
        }
          this.storageManagementService.setSessionData(AppConstants.IS_PRE_AUTH_INTAKE_DISABLED, this.isBeginNewClaimDisabled);
      }
    );


  }

  //Retrieve a boolean response from OWCS for a specific key
  recaptchaFeatureToggle() {

    this.errorHandlerService.getSysDownTime(false, AppConstants.IS_RECAPTCHA_DISABLED).subscribe(
      val => {
        if (val.message && val.message.length > 0) {
          this.isRecaptchaDisabled = val.message[0].value.toLowerCase().indexOf(AppConstants.TRUE) !== -1 ? true : false;
        }
        this.storageManagementService.setSessionData(AppConstants.IS_RECAPTCHA_DISABLED, this.isRecaptchaDisabled);
      }
    );


  }
  ngAfterViewInit() {
    super.ngAfterViewInit();
  }

  // this function is used to initialise all the session objects on every login
  initializeObjects() {
    this.appData = {
      initialDt : new Date()
    };

    this.appData.currentPage = this.appData.currentPage || AppConstants.PRELOGIN;
    this.appData.userData = this.appData.userData || {};
    this.appData.paymentDetails = this.appData.paymentDetails || {};
    this.appData.provisions = this.appData.provisions || {};
    this.appData.coveragesMudi = this.appData.coveragesMudi || {};
    this.appData.profileDetails = this.appData.profileDetails || {};
    this.appData.userClaims = this.appData.userClaims || {};
    this.appData.coveragePlans = this.appData.coveragePlans || {};
    this.appData.leaveService = this.appData.leaveService || {};
    this.appData.claimPayments = this.appData.claimPayments || {};
    this.appData.bankingDetails = this.appData.bankingDetails || {};
    this.appData.reloadClaims = this.appData.reloadClaims || false;
   this.appData.loginStatus = this.appData.loginStatus || false;

    this.storageManagementService.setSessionObject(SessionConstants.APP_DATA, this.appData);
  }

  /*
   * Purpose:
   * This function will be used to start a claim on the CXR Login flow
   * */
  reportAClaim() {
    this.router.navigate([AppLabelConstants.URL_START_NEW_CLAIM]);
  }

  /* Display a System alert message */
  //TODO: Need to validate the usage of the function
  systemAlert() {
    this.modalService.openModal(AppLabelConstants.SYSTEM_ALERT_MODAL);
  }


  startNewClaim() {
    this.router.navigate([AppLabelConstants.URL_CLAIM_WIZARD]);
  }

  /*
   * Purpose:
   * To land on CIAM Login flow
   * */
   landToLogin() {
     TokenUtility.removeMyBenefitsToken();
    // const gotoUrl = encodeURIComponent('?goto=');
    window.location.href = env.gbCiamCommonUtil.CIAMLoginURL + '&goto=' + encodeURIComponent(this.sfd_login_url) + encodeURIComponent('?goto=') + encodeURIComponent(this.landingUrl.toString());
  }
  //landToLogin() {
   // TokenUtility.removeMyBenefitsToken();
   // window.location.href = env.gbCiamCommonUtil.CIAMLoginURL;
  //}

  /*
   * Purpose:
   * To land on CIAM Registration flow
   * */
  landToApp() {
    TokenUtility.removeMyBenefitsToken();
    window.location.href = env.gbCiamCommonUtil.CIAMRegisterURL  + '&goto=' + encodeURIComponent(this.sfd_login_url);
  }

  /* In order to better load the content, there is an explicit call here if it does not already exist.*/
  contentLoad() {
    if (!this.storageManagementService.getSessionData(SessionConstants.SITE_MAP_DATA)) {
      this.contentService.getSiteMap().subscribe(
        val => {
          if (val) {
            this.storageManagementService.setSessionData(SessionConstants.SITE_MAP_DATA, val);
            this.contentService.isSiteMapReady.next(true);
            this.systemMessagesLoad();
            if (this.storageManagementService.getSessionData(AppConstants.IS_PRE_AUTH_INTAKE_DISABLED) === undefined) {
              this.PreAuthNewClaimFeatureToggle();
            } else {
              this.isBeginNewClaimDisabled = this.storageManagementService.getSessionData(AppConstants.IS_PRE_AUTH_INTAKE_DISABLED);
            }
            if (this.storageManagementService.getSessionData(AppConstants.IS_RECAPTCHA_DISABLED) === undefined) {
              this.recaptchaFeatureToggle();
            } else {
              this.isRecaptchaDisabled = this.storageManagementService.getSessionData(AppConstants.IS_RECAPTCHA_DISABLED);
            }
          }
        }
      );
    }
  }

  /* There are several system messages obtained from our content management system. These can only be acquired after we are certain
  * the content has loaded appropriately*/
  systemMessagesLoad() {
    this.errorHandlerService.getSysDownTime(false, AppConstants.IS_REG_DISABLED).subscribe(
      val => {
        if (val.message && val.message.length > 0) {
          this.isRegisterLinkDisabled = val.message[0].value.toLowerCase().indexOf(AppConstants.TRUE) !== -1  ? true : false;
        }
      }
    );

    this.errorHandlerService.getSysDownTime(false, AppConstants.LOGIN).subscribe(
      val => {
        //   console.log('SysDown Res in Login:', val);
        this.getSysDownMsg = val;
        if (this.getSysDownMsg && this.getSysDownMsg.message &&  this.getSysDownMsg.message.length > 0) {
          const messageType = this.getSysDownMsg.message[0].message_type.toString().toLowerCase();
          const messageText = this.getSysDownMsg.message[0].value;
          const messageCloseType = (this.getSysDownMsg.message[0].message_close_type.toString().toLowerCase() === AppConstants.AUTO) ? AppConstants.TRUE : null;
          const messageHeader = (messageType === AppLabelConstants.ERROR_SM) ? AppLabelConstants.DANGER_LG :
            (messageType === AppLabelConstants.WARNING_SM ? AppLabelConstants.WARNING_LG : (messageType === AppLabelConstants.SUCCESS_SM ? AppLabelConstants.SUCCESS_LG : AppLabelConstants.INFORMATION_LG));
          this.loginSysDownKey =   this.messageRibbon.addMessage(messageType, messageText, messageHeader, messageCloseType);
          this.errorHandlerService.setSysDownKeyLogin(this.loginSysDownKey);
        } else {
          //console.log(ErrorConstants.SYS_DOWN_ERROR_IN_LOGIN + val);
        }
      }, (error) => {
       // console.log(ErrorConstants.SYS_DOWN_ERROR_IN_LOGIN + error);
      }
    );
  }
}
