import {Component, Input} from '@angular/core';
import {Subscription} from 'rxjs';
import {ReferenceHelperService} from '../../../../../shared/services/reference-helper.service';
import {StorageManagementService} from '../../../../../shared/services/storage-management.service';

@Component({
  selector: 'info-bar',
  templateUrl: 'info-bar.component.html',
  styleUrls: ['info-bar.component.scss']
})
export class InfoBarComponent  {
  @Input() templateText = 'This is PlaceHolder text for the informational section to be placed here';
  @Input() templateTitle: string = '';
  public isClaimServiceHasError: boolean;
  subscription: Subscription;

  constructor(private referenceHelper: ReferenceHelperService, private storageManagementService: StorageManagementService) {
    this.subscription = this.referenceHelper.reference.subscribe
    (val => {
      this.templateTitle = val.get('sectionTitle');
      this.templateText = val.get('sectionInfo');
    });
  }

}

