import { Component, Input, OnInit } from '@angular/core';
import { FormControlName, FormGroup } from '@angular/forms';
import { TimeOffConstants } from '@app/cxr/leave-details-flow/leave-request-time-off/time-off.constants';
import { IntakeUtilitiesService } from '@app/cxr/modules/intake/shared/services/intake-utilities.service';
@Component({
  selector: 'primary-text-area',
  templateUrl: './primary-text-area.component.html',
  styleUrls: ['./primary-text-area.component.scss']
})
export class PrimaryTextAreaComponent implements OnInit {
  @Input() textareaPlaceHolder: string = '';
  @Input() textareaId: string;
  @Input() minValue: number;
  @Input() maxValue: number;
  @Input() primaryTextArea: string;
  @Input() charLeft: number;
  @Input() customErrorMssg?: boolean = false;
  @Input() formControlName: string;
  @Input() formName?: FormGroup = new FormGroup({});
  @Input() ariaLabel?: string ="text area";
  timeOffConstants = TimeOffConstants;
  error: boolean = false;

  constructor(private utilities: IntakeUtilitiesService) { }

  ngOnInit() {
  }
  validateTextArea($event) {
    this.primaryTextArea = $event.target.value;
    this.charLeft = this.maxValue - this.primaryTextArea.length;
    if ( this.customErrorMssg) {
      if (this.formName && this.formName?.controls[this.formControlName]?.touched && this.formName.controls[this.formControlName]?.valid) {
        this.error = false;
       } else if (this.formName && this.formName?.controls[this.formControlName]?.touched) {
         this.error = true;
       }
    }
  }
  validateTexttrimArea($event) {
    this.primaryTextArea = $event.target.value.trim();
    this.charLeft = this.maxValue - this.primaryTextArea.length;
  }
  checkError(): void {
   if (this.customErrorMssg && this.formName) {
    const value = this.utilities.replaceHtmlTags(this.formName?.controls[this.formControlName]?.value)?.trim();
    this.formName?.controls[this.formControlName]?.setValue(value);
    this.formName?.controls[this.formControlName]?.updateValueAndValidity();
    this.charLeft = this.maxValue - value?.length;
    if ((this.formName && !this.formName?.controls[this.formControlName]?.touched)  && this.formName.controls[this.formControlName]?.invalid
      && this.formName.controls[this.formControlName]?.errors || this.formName?.controls[this.formControlName]?.value === '' ) {
      this.error = true;
   } else {
     this.error = false;
   }
  }
  }
}
