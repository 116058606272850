/**
 * Created by AC12485 on 12/10/2018.
 */
import {LetterListResponse} from '../shared/models/letterList.model';

export const MOCK_LETTERS_LIST: LetterListResponse = {
  responseCode: '001',
  responseReason: 'success',
  letters: [{
    letterId: '16335589',
    createDate: '03/12/2019',
    claimNumber: '1567005',
    letterName: 'Contact Letter',
    previewEnabled: 'true'
  },
    {
      claimNumber: '1567005',
      createDate: '09/26/2017',
      letterId: '12010113',
      letterName: 'Update Medical Authorization Form',
      previewEnabled: 'true'
    },
    {
      claimNumber: '1567005',
      createDate: '09/26/2017',
      letterId: '12010113',
      letterName: 'Update Medical Authorization Form',
      previewEnabled: 'true'
    },
    {
      claimNumber: '1567005',
      createDate: '09/26/2017',
      letterId: '12010113',
      letterName: 'Update Medical Authorization Form',
      previewEnabled: 'true'
    },
    {
      claimNumber: '131284',
      createDate: '09/26/2017',
      letterId: '12010113',
      letterName: 'Update Medical Authorization Form',
      previewEnabled: 'true'
    },
    {
      claimNumber: '131284',
      createDate: '08/26/2017',
      letterId: '11958462',
      letterName: 'Update Medical Authorization Form',
      previewEnabled: 'true'
    },
    {
      claimNumber: '131284',
      createDate: '08/26/2017',
      letterId: '11958462',
      letterName: 'Update Medical Authorization Form',
      previewEnabled: 'true'
    },
    {
      claimNumber: '131284',
      createDate: '08/26/2017',
      letterId: '11958462',
      letterName: 'Update Medical Authorization Form',
      previewEnabled: 'true'
    },
    {
      claimNumber: '131284',
      createDate: '07/26/2017',
      letterId: '11883393',
      letterName: 'Update Medical Authorization Form',
      previewEnabled: 'true'
    },
    {
      claimNumber: '131284',
      createDate: '06/24/2017',
      letterId: '11830083',
      letterName: 'Update Medical Authorization Form',
      previewEnabled: 'true'
    },
    {
      claimNumber: '131284',
      createDate: '06/23/2017',
      letterId: '11827848',
      letterName: 'Electronic Fund Transfer Advisory Letter',
      previewEnabled: 'true'
    }],
  pageInfo: {
    totalResults: '4',
    offSet: '0',
    itemsOnPage: '1',
    firstItemOnPage: '1',
    lastItemOnPage: '1',
    paging: [{
      offSet: '0',
      name: '1',
      current: 'true'
    }]
  }
};


