import {AfterViewInit, Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {ModalService} from '../../shared/services/modal.service';
import {StorageManagementService} from '../../shared/services/storage-management.service';
import {LeaveDynatraceAction} from '../../shared/utils/leaveDynatraceAction';
import {AppLabelConstants} from '../../shared/constants/appLabel.constants';
import {AppConstants} from '../../shared/constants/app.constants';

@Component({
  selector: 'app-cancel-popup',
  templateUrl: './cancel-popup.component.html',
  styleUrls: ['./cancel-popup.component.scss']
})
export class CancelPopupComponent extends LeaveDynatraceAction implements OnInit, AfterViewInit {

  @Input() statusEmailId;
  @Input() paymentEmailId;
  @Input() statusTextId;
  @Input() paymentTextId;
  @Output() private ifChangeDone = new EventEmitter<boolean>();
  @Output() private userDecision  = new EventEmitter<boolean>();
  public appLabelConstant = AppLabelConstants;
  public  AppConstants = AppConstants;

  constructor( private modalService: ModalService,
              private storageManagementService: StorageManagementService, ) {
    super(storageManagementService);
  }

  ngOnInit() {
  }

  ngAfterViewInit() {
    super.ngAfterViewInit();
  }

  clickExit() {
    this.ifChangeDone.emit(true);
    this.userDecision.emit(false);
    this.modalService.closeModal(this.appLabelConstant.CANCEL_MODAL);

  }

  clickClose() {
    this.modalService.closeActiveModal();
  }

}
