export const MOCK_ADA_TIMELINE_DETAILS = {
  accommodationDetails: null,
  certificationDetails: [
    {
      certificates: [
        {
          certId: '1075969',
          certStatus: 'Not Accommodated',
          certSubStatus: 'Your employer has denied your accommodation request. See the letter in Your Documents for more information.',
          certPeriodStartDate: '6/1/2021',
          certPeriodEndDate: '6/6/2021',
          isPerm: false,
          certCore_Intermit_Freq: '',
          certCore_Intermit_Freq_Unit: '',
          certFuture_Intermit_Freq_Unit_NBR: '0',
          certCore_Intermit_Dur: '',
          certCore_Intermit_Dur_Unit: '',
          certFrequency_AMN_Flag: 'F',
          certDuration_AMN_Flag: 'F',
          reducedWorkHours: ''
        }
      ],
      continuity: 'Continuous',
      accommodationRequest: 'Not Accommodated',
      accommodationType: null,
      accommodationSubType: null,
      accommodationReason: null,
      accommodationUpdate: true
    },
    {
      certificates: [
        {
          certId: '1075970',
          certStatus: 'Pending Accommodation Certification',
          certSubStatus: 'Your employer has denied your accommodation request. See the letter in Your Documents for more information.',
          certPeriodStartDate: '5/1/2021',
          certPeriodEndDate: '5/6/2021',
          isPerm: false,
          certCore_Intermit_Freq: '',
          certCore_Intermit_Freq_Unit: '',
          certFuture_Intermit_Freq_Unit_NBR: '0',
          certCore_Intermit_Dur: '',
          certCore_Intermit_Dur_Unit: '',
          certFrequency_AMN_Flag: 'F',
          certDuration_AMN_Flag: 'F',
          reducedWorkHours: ''
        }
      ],
      continuity: 'Continuous',
      accommodationRequest: 'Not Accommodated',
      accommodationType: 'Leave',
      accommodationSubType: 'Intermittent time off for flareups and appointments',
      accommodationReason: null,
      accommodationUpdate: true
    },
    {
      certificates: [
        {
          certId: '1075969',
          certStatus: 'Accommodation Certification Review',
          certSubStatus: 'Your employer has denied your accommodation request. See the letter in Your Documents for more information.',
          certPeriodStartDate: '4/1/2021',
          certPeriodEndDate: '4/6/2021',
          isPerm: false,
          certCore_Intermit_Freq: '',
          certCore_Intermit_Freq_Unit: '',
          certFuture_Intermit_Freq_Unit_NBR: '0',
          certCore_Intermit_Dur: '',
          certCore_Intermit_Dur_Unit: '',
          certFrequency_AMN_Flag: 'F',
          certDuration_AMN_Flag: 'F',
          reducedWorkHours: ''
        }
      ],
      continuity: 'Continuous',
      accommodationRequest: 'Not Accommodated',
      accommodationType: 'Leave',
      accommodationSubType: 'Continuos Leave',
      accommodationReason: null,
      accommodationUpdate: true
    },
    {
      certificates: [
        {
          certId: '1075969',
          certStatus: 'Pending Employer Review',
          certSubStatus: 'Your employer has denied your accommodation request. See the letter in Your Documents for more information.',
          certPeriodStartDate: '3/1/2021',
          certPeriodEndDate: '3/6/2021',
          isPerm: false,
          certCore_Intermit_Freq: '',
          certCore_Intermit_Freq_Unit: '',
          certFuture_Intermit_Freq_Unit_NBR: '0',
          certCore_Intermit_Dur: '',
          certCore_Intermit_Dur_Unit: '',
          certFrequency_AMN_Flag: 'F',
          certDuration_AMN_Flag: 'F',
          reducedWorkHours: ''
        }
      ],
      continuity: 'Continuous',
      accommodationRequest: 'Not Accommodated',
      accommodationType: 'Leave',
      accommodationSubType: null,
      accommodationReason: null,
      accommodationUpdate: true
    }
  ],
  responseCode: '001',
  responseReason: 'success'
};
