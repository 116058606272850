import { Injectable } from '@angular/core';
import { ResponseErrorHandlerService } from '@shared/services/response-error-handler.service';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { env } from '@env/environment-loader';
import { AppLabelConstants } from '@shared/constants/appLabel.constants';
import { Observable } from 'rxjs';
import { catchError, filter, map, repeat, retryWhen, take, tap } from 'rxjs/operators';
import { IClaimSubmitByValRequest, IClaimSubmitRequest, IClaimSubmitResponse } from '@app/cxr/modules/intake/shared/models/intake-content.model';
import { throwError as observableThrowError } from 'rxjs/internal/observable/throwError';

@Injectable()
export class ClaimSubmitService {
  constructor(private errorHandler: ResponseErrorHandlerService,
    private httpClient: HttpClient) { }
  isErrorAgain: boolean = false;
  public submitIntakeClaim(claimSubmitRequest: IClaimSubmitRequest): Observable<IClaimSubmitResponse> {
    this.isErrorAgain = false;
    return this.httpClient.post(env.thaaWKABPortalService.submitIntakeClaim, claimSubmitRequest,
    {
      headers: new HttpHeaders()
        .set(AppLabelConstants.CONTENT_TYPE_KEY, AppLabelConstants.APPLICATION_KEY)
    }).pipe(
      repeat(),
      filter((response: IClaimSubmitResponse) => this.allowAPICall(response)),
      take(1),
      map(
      (response: IClaimSubmitResponse) => {
        if (!this.errorHandler.isServiceResponseHasError(response)) {
          return response;
        } else {
          throw response;
        }
      }),
      retryWhen(errors => errors.pipe(
        tap(error => {
          if(error && error?.error?.responseCode === '999' && !this.isErrorAgain) {
            this.isErrorAgain = true;
          } else {
            this.isErrorAgain = true;
            throw error
          }
        })
      )),
      catchError((error: any) => observableThrowError(error))
    )
  }

  allowAPICall(response: IClaimSubmitResponse): boolean {
    if(response && response.responseCode === '999' && !this.isErrorAgain) {
      this.isErrorAgain = true;
      return false
    } else {
      this.isErrorAgain = true;
      return true
    }
  }

  public submitIntakeClaimByValue(claimSubmitRequest: IClaimSubmitByValRequest): Observable<IClaimSubmitResponse> {
    this.isErrorAgain = false;
    return this.httpClient.post(env.thaaWKABPortalService.submitIntakeClaimByValue, claimSubmitRequest,
    {
      headers: new HttpHeaders()
        .set(AppLabelConstants.CONTENT_TYPE_KEY, AppLabelConstants.APPLICATION_KEY)
    }).pipe(
      repeat(),
      filter((response: IClaimSubmitResponse) => this.allowAPICall(response)),
      take(1),
      map(
      (response: IClaimSubmitResponse) => {
        if (!this.errorHandler.isServiceResponseHasError(response)) {
          return response;
        } else {
          throw response;
        }
      }),
      retryWhen(errors => errors.pipe(
        tap(error => {
          if(error && error?.error?.responseCode === '999' && !this.isErrorAgain) {
            this.isErrorAgain = true;
          } else {
            this.isErrorAgain = true;
            throw error
          }
        })
      )),
      catchError((error: any) => observableThrowError(error))
    )
  }
}
