import {Component, Input} from '@angular/core';
import {CustomPageLabelComponent} from '../../../sub-atomic/custom-page-label/custom-page-label.component';
import {SafeResourceUrl} from '@angular/platform-browser';

@Component({
  selector: 'secondary-page-label',
  templateUrl: 'secondary-page-label.component.html',
  styleUrls: ['secondary-page-label.component.scss']
})
export class SecondaryPageLabelComponent extends CustomPageLabelComponent {

  @Input() iconURL: string;
  @Input() elementId: string;
  @Input() higLabel: string | SafeResourceUrl;
  @Input() errorCondition: boolean;
  @Input() focusCondition: boolean;
  @Input() faIcon: string;
  @Input() optional: boolean = false;

}
