/**
 * Created by AC12256 on 2/9/2018.
 */
import {LEAVEDETAILS} from './mock-leaveDetails';
import {ClaimsLeaveResponse} from '../shared/models/LeaveMappings.model';

export const LEAVEREED: ClaimsLeaveResponse = {
    leaveDetailsList: LEAVEDETAILS
  }

