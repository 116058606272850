import {Component, OnInit, Input} from '@angular/core';
import {Router} from '@angular/router';
import {ModalService} from '../../../shared/services/modal.service';
import {AppConstants} from '../../../shared/constants/app.constants';
import {AppLabelConstants} from '../../../shared/constants/appLabel.constants';

@Component({
  selector: 'app-surgery-cancel-modal',
  templateUrl: 'surgery-cancel-modal.component.html',
  styleUrls: ['surgery-cancel-modal.component.scss']
})
export class SurgeryCancelModalComponent implements OnInit {
  @Input() routLink: string;
  public appConstant = AppConstants;
  public appLabelConstant = AppLabelConstants;

  constructor(private modalService: ModalService, private router: Router) { }

  ngOnInit() {
  }
  clickCancel() {
    this.modalService.closeModal(AppConstants.SURGERY_CANCEL_MODAL);
  }
  exit() {
    const navrout = (this.routLink ? this.routLink : AppConstants.ROUTE_TO_HOME);
    this.router.navigate([navrout]);
  }
}
