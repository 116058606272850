export const PLAN_BALANCES = {
  balances: [
  {
    benefitType: 'California Family Rights Act (CFRA)',
    benefitTypeDescription: '',
    benefitFromDate: '8/1/2019',
    benefitThroughDate: '8/2/2018',
    totalBenefitAmount: '12 weeks',
    benefitTimeUsed: '1.23 weeks',
    futureTimeApproved: '0.24 weeks',
    remainingTimeAvailable: '11 weeks',
    regulationScopeName: 'State'
  },
  {
    benefitType: 'Takeda Company FMLA',
    benefitTypeDescription: 'Except as explained below, you have a right under the FMLA for up to 12 weeks of unpaid leave in a 12-month period for the care of a spouse, child, or parent, domestic partner, or for your own serious illness. Your health benefits will be maintained during any period of approved unpaid leave under the same conditions as if you continued to work, and are subject to your continued employee contributions. You may be reinstated to the same or an equivalent job with the same pay, benefits, and terms and conditions of employment on your return from leave.',
    benefitFromDate: '8/1/2019',
    benefitThroughDate: '8/2/2018',
    totalBenefitAmount: '12 weeks',
    benefitTimeUsed: '0.956 weeks',
    futureTimeApproved: '0.203 weeks',
    remainingTimeAvailable: '11 weeks',
    regulationScopeName: 'Company'
  },
    {
      benefitType: 'New York Family Rights Act',
      benefitTypeDescription: '',
      benefitFromDate: '8/1/2019',
      benefitThroughDate: '8/2/2018',
      totalBenefitAmount: '12 weeks',
      benefitTimeUsed: '0.924 weeks',
      futureTimeApproved: '0.2 weeks',
      remainingTimeAvailable: '11 weeks',
      regulationScopeName: 'State'
    },
    {
      benefitType: 'Federal Family and Medical Leave Act (FMLA)',
      benefitTypeDescription: 'Federal Family and Medical Leave Act (FMLA) except as explained below, eligible employees have a right to leave under the FMLA for up to 12 weeks of unpaid leave in a 12-month period if:  they are unable to perform the functions of their job due to their own serious health condition; for the care of their spouse, son, daughter or parent with a serious health condition; or for birth and care of their newborn child; placement of a son or daughter with the employee for adoption or foster care; because of a qualifying exigency arising out of the employee\'s spouse, son, daughter or parent on active duty or call to order to active duty in support of a contingency operation; or 26 weeks of unpaid leave to care for a covered servicemember with a serious injury or illness, if the employee is the spouse, son, daughter, parent or next of kin of the servicemember.',
      benefitFromDate: '8/1/2019',
      benefitThroughDate: '8/2/2018',
      totalBenefitAmount: '12 weeks',
      benefitTimeUsed: '0.91 weeks',
      futureTimeApproved: '0.00 weeks',
      remainingTimeAvailable: '11 weeks',
      regulationScopeName: 'Federal'
    },
    {
      benefitType: 'Accenture Company FMLA',
      benefitTypeDescription: 'Except as explained below, you have a right under the FMLA for up to 12 weeks of unpaid leave in a 12-month period for the care of a spouse, child, or parent, domestic partner, or for your own serious illness. Your health benefits will be maintained during any period of approved unpaid leave under the same conditions as if you continued to work, and are subject to your continued employee contributions. You may be reinstated to the same or an equivalent job with the same pay, benefits, and terms and conditions of employment on your return from leave.',
      benefitFromDate: '8/1/2019',
      benefitThroughDate: '8/2/2018',
      totalBenefitAmount: '12 weeks',
      benefitTimeUsed: '0.905 weeks',
      futureTimeApproved: '0.2 weeks',
      remainingTimeAvailable: '11 weeks',
      regulationScopeName: 'Company'
    },
    {
      benefitType: 'Alaska Family Rights Act',
      benefitTypeDescription: '',
      benefitFromDate: '8/1/2019',
      benefitThroughDate: '8/2/2018',
      totalBenefitAmount: '12 weeks',
      benefitTimeUsed: '0.9 weeks',
      futureTimeApproved: '0.2 weeks',
      remainingTimeAvailable: '11 weeks',
      regulationScopeName: 'State'
    },
    {
      benefitType: 'New Alaska Family Rights Act',
      benefitTypeDescription: '',
      benefitFromDate: '8/1/2019',
      benefitThroughDate: '8/2/2018',
      totalBenefitAmount: '12 weeks',
      benefitTimeUsed: '0.9 weeks',
      futureTimeApproved: '0.2 weeks',
      remainingTimeAvailable: '11 weeks',
      regulationScopeName: 'State'
    },
    {
      benefitType: 'Takedb Company FMLA',
      benefitTypeDescription: 'Except as explained below, you have a right under the FMLA for up to 12 weeks of unpaid leave in a 12-month period for the care of a spouse, child, or parent, domestic partner, or for your own serious illness. Your health benefits will be maintained during any period of approved unpaid leave under the same conditions as if you continued to work, and are subject to your continued employee contributions. You may be reinstated to the same or an equivalent job with the same pay, benefits, and terms and conditions of employment on your return from leave.',
      benefitFromDate: '8/1/2019',
      benefitThroughDate: '8/2/2018',
      totalBenefitAmount: '12 weeks',
      benefitTimeUsed: '0.9 weeks',
      futureTimeApproved: '0.2 weeks',
      remainingTimeAvailable: '11 weeks',
      regulationScopeName: 'Company'
    }
],
  responseCode: '001',
  responseReason: 'success'
};
