export const MOCK_ICD_CPT_LIST = {
  icdCptList: [
    {
      icdCode: 'I25.10',
      icdDescription: 'Atherosclerotic Heart Disease (Blocked Coronary Arteries) ( I25.1 )',
      cptInfo: [
        {
          cptCode: '33945',
          cptDescription: ' Heart Transplant  (33945 )',
          procedureDateInfo: [
            '02/16/02022', '02/15/02022', '02/14/02022'
          ]
        }  ,
        {
          cptCode: '33945',
          cptDescription: ' Heart Transplant  (33945 )',
          procedureDateInfo: [
            '02/16/02022'
          ]
        }       ]
    },
    {
      icdCode: 'I27.10',
      icdDescription: 'Atherosclerotic',
      cptInfo: [


      ]
    },
    {
      icdCode: 'I25.10',
      icdDescription: 'Atherosclerotic Heart Disease (Blocked Coronary Arteries) ( I25.1 )',
      cptInfo: [
        {
          cptCode: '33945',
          cptDescription: ' Heart Transplant  (33945 )',
          procedureDateInfo: [
            '02/16/02022'
          ]
        }        ]
    },
    {
      icdCode: 'I25.10',
      icdDescription: 'Atherosclerotic Heart Disease (Blocked Coronary Arteries) ( I25.1 )',
      cptInfo: [
        {
          cptCode: '33945',
          cptDescription: ' Heart Transplant  (33945 )',
          procedureDateInfo: [
            '02/16/02022'
          ]
        }        ]
    },
    {
      icdCode: 'I25.10',
      icdDescription: 'Atherosclerotic Heart Disease (Blocked Coronary Arteries) ( I25.1 )',
      cptInfo: [
             ]
    },
  ],
  responseCode: '001',
  responseReason: 'Success'
}
