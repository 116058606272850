/**
 * Created by AC12212 on 8/7/2017.
 */
import {Injectable} from '@angular/core';
import {StorageManagementService} from './storage-management.service';
import {Feature} from '../models/Feature';
import {SessionConstants} from '../constants/session.constants';


@Injectable()
export class DynamicFeatureDownService {

  constructor(private storageManagementService: StorageManagementService) {}
  /* We will have the content service do the retrieval for us. We will have this service use the response
  * that will be stored in the session.
  * */
  isFeatureTurnedOff(featureInQuestion: string): boolean {
    /* Multi Steps
    * 1) Filter response from content service
    * 2) If found -> run time check
    * 3) If not found -> pass with a false
    * */

    const features: Feature[] = this.storageManagementService.getFeaturesData();
    let filteredFeatures: Feature[] = [];
    if (features && features.length > 0) {
      filteredFeatures = features.filter((feature: Feature) => feature.title === featureInQuestion);
      if (filteredFeatures && filteredFeatures.length === 1) {
        if (this.isFeatureWithinBlockedWindow(filteredFeatures[0].start_date, filteredFeatures[0].end_date)) {
          this.storageManagementService.setSessionData(SessionConstants.SYS_DOWN_FEATURE, filteredFeatures[0]);
          return true;
        }
      }
    }
    return false;
  }

  isFeatureWithinBlockedWindow(startDate:  string, endDate: string): boolean {
    /*
    * 1)
    * */
    //taken curr date as EST time so that browser timezone is not considered
    const curDateObj1 = new Date();
    const curDateObj = new Date(curDateObj1);
    const startDateObj = new Date(new Date(this.dateConversion(startDate)));
    const endDateObj = new Date(new Date(this.dateConversion(endDate)));

    let estOffset = 300 * 60000;
    if (curDateObj.getMonth() >= 2 && curDateObj.getMonth() < 10) {
      estOffset = 240 * 60000;
    }

    const curTime   = curDateObj    && this.isValidDate(curDateObj)   ? curDateObj.getTime() : null;
    const startTime = startDateObj  && this.isValidDate(startDateObj) ? (startDateObj.getTime() + estOffset) : null;
    const endTime   = endDateObj    && this.isValidDate(endDateObj)   ? (endDateObj.getTime() + estOffset) : null;

    return (curTime > startTime && curTime < endTime);
  }

  dateConversion(dateString: string): Date {
    const splitInBound = dateString.split(' ');
    const calendarSplit = splitInBound[0].split('-');
    const timeSplit     = splitInBound[1].split(':');
    return new Date(Date.UTC(
      parseInt(calendarSplit[0], 10 ),
     (parseInt(calendarSplit[1], 10 ) - 1),
      parseInt(calendarSplit[2], 10 ),
      parseInt(timeSplit[0], 10 ),
      parseInt(timeSplit[1], 10  ),
      parseInt(timeSplit[2], 10   )));
  }

  isValidDate(d) {
    return (d instanceof Date);
  }
  /** To decide do user is applicable to use feature */
  isFeatureNeedToSuppress(feature: string): boolean {
    const CIIFeatureData = this.storageManagementService.getSessionData(SessionConstants.CII_FEATURE_DATA);
   if (CIIFeatureData &&  CIIFeatureData[feature] === 0 ) {
     return  false ;
   }
   return true;
  }
}
