import {Component, Input, OnInit} from '@angular/core';

@Component({
  selector: 'material-input',
  templateUrl: 'primary-input.component.html',
  styleUrls: ['primary-input.component.scss']
})
export class PrimaryInputComponent implements OnInit {

  @Input() inputString: string;


  constructor() {}
  ngOnInit() {}

}
