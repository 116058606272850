import {ClaimDocument, ClaimDocumentSearchResponse} from '../shared/models/claim-document.model';

/**
 * Created by AC12323 on 9/27/2017.
 */

export const documentsclaim: ClaimDocument[] = [
  {
    DocumentCreationDate: '04/13/2016',
    SourceCreationDate: '04/12/2016',
    directory: 'Sample-Img.png',
    path: './assets/documents/Sample-Img.png',
    size: 748544,
    DocumentType: 'image/png',
    DocumentFileName: 'Sample Image.png',
    DocumentId: '0001',
    documentControlNumber: '0001',
    HIGDocumentIdentifier : '23'
  },
  {
    DocumentCreationDate: '07/20/2017',
    SourceCreationDate: '08/12/2017',
    directory: 'Sample-PDF.pdf',
    path: './assets/documents/Sample-PDF.pdf',
    size: 748544,
    DocumentType: 'application/pdf',
    DocumentFileName: 'Sample PDF.pdf',
    DocumentId: '0002',
    documentControlNumber: '0002',
    HIGDocumentIdentifier : '123'
  },
  {
    DocumentCreationDate: '02/09/2017',
    SourceCreationDate: '03/08/2017',
    directory: 'Sample-IMG-two.img',
    path: './assets/documents/Sample-IMG-two.img',
    size: 748544,
    DocumentType: 'image/png',
    DocumentFileName: 'Sample IMG two.img',
    DocumentId: '0003',
    documentControlNumber: '0003',
    HIGDocumentIdentifier : '456'
  },
  {
    DocumentCreationDate: '07/20/2017',
    SourceCreationDate: '06/12/2017',
    directory: 'Sample-PDF-two.pdf',
    path: './assets/documents/Sample-PDF-two.pdf',
    size: 748544,
    DocumentType: 'application/pdf',
    DocumentFileName: 'Sample PDF Two.pdf',
    DocumentId: '0004',
    documentControlNumber: '0004',
    HIGDocumentIdentifier : '345'
  }
];

export const DocSuccessXMLResponse = `<?xml version="1.0"?>

<soap:Envelope
xmlns:soap="http://www.w3.org/2003/05/soap-envelope/"
soap:encodingStyle="http://www.w3.org/2003/05/soap-encoding">

  <soap:Body xmlns="http://www.example.org/stock">
    <Status>Success</Status>
  </soap:Body>
</soap:Envelope>`;


export const MOCK_DOCUMENT_SEARCH_RESPONSE: ClaimDocumentSearchResponse = {
  documents: documentsclaim
}
