
import {of} from 'rxjs';

import {catchError, map} from 'rxjs/operators';
import { Injectable } from '@angular/core';
import {Observable} from '../../../../node_modules/rxjs';
import {ResponseErrorHandlerService} from './response-error-handler.service';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {AppLabelConstants} from '../constants/appLabel.constants';
import {env} from '../../../environments/environment-loader';

import {ISpecialityResponse, ISpecialtyList} from '@shared/models/specialtyList.model';
import {StorageManagementService} from '@shared/services/storage-management.service';
import {RaeSessionConstants} from '@shared/constants/raeSession.constants';

@Injectable()
export class SpecialtyService {

  raeSessionConstants = RaeSessionConstants;

  constructor(private errorHandler: ResponseErrorHandlerService,
              private storeManagementService: StorageManagementService,
              private httpClient: HttpClient) {
  }

  getSpecialty(personId: string,isKeyNeeded: boolean = false): Observable<ISpecialtyList[]> {
      const specialtyList = this.storeManagementService.getRAESessionData(this.raeSessionConstants.SPECIALTY_LIST);
      if (specialtyList) {
        return of(specialtyList);
      } else {
        if (personId) {
          let specialtyURI: string = env.thaaWKABPortalService.specialtyList;
          if(isKeyNeeded) {
            specialtyURI = env.thaaWKABPortalService.specialtyWithKey;
          }
          return this.httpClient.get(specialtyURI + '&personid=' + personId,
            {
              headers: new HttpHeaders()
                .set(AppLabelConstants.CONTENT_TYPE_KEY, AppLabelConstants.APPLICATION_KEY)
            }).pipe(
            map(
              (response: ISpecialityResponse) => {
                if (!this.errorHandler.isServiceResponseHasError(response)) {
                  this.storeManagementService.setRAESessionData(this.raeSessionConstants.SPECIALTY_LIST, response.specialtyList);
                  return response.specialtyList;
                } else {
                  throw response;
                }
              }
            ), catchError((error: any) => this.errorHandler.handleError(error)), );
        } else {
          return this.errorHandler.handleError('Doctor Specialty API failed because a personId was not available');
        }
      }
  }
}
