/**
 * Created by Chaitanya on 8/7/2017.
 */
import {AfterViewInit, Component, HostListener, OnInit} from '@angular/core';
import {Faq, FAQAsset, FAQResponse , Feedback} from '../../../../shared/models/faq.model';
import {FaqService} from '../../../../shared/services/faqs.service';
import {QuestionStackNode} from './question-stack';
import {ActivatedRoute} from '@angular/router';
import {CustomEventService} from '../../../../shared/services/tealium/custom-event.service'
import {LeaveDynatraceAction} from '../../../../shared/utils/leaveDynatraceAction';
import {StorageManagementService} from '../../../../shared/services/storage-management.service';
import {AppLabelConstants} from '../../../../shared/constants/appLabel.constants';
import {BlazeService} from '../../../../shared/services/blazeRule.service';
import {FaqCommonService} from '../../../../shared/services/faqCommon.service'
import { SessionConstants } from '@app/shared/constants/session.constants';
import { ContentService } from '@app/shared/services/content.service';
@Component({
  selector : 'app-faq',
  templateUrl: 'faq.component.html',
  styleUrls : ['faq.component.scss'],
})
export class FaqComponent  extends LeaveDynatraceAction implements OnInit, AfterViewInit {
  public appLabelConstants = AppLabelConstants;
  prevPage: string;
  FAQs: Faq[] = [];
  questionId: string;
  question: string;
  pageLabel = AppLabelConstants.COMMON_QUE;
  feedbackQuestion = AppLabelConstants.WAS_THIS_ANS_HELPFUL;
  questionStack: QuestionStackNode = new QuestionStackNode();
  top = 0;
  state = false;
  constructor(private faqService: FaqService,
              private activatedRoute: ActivatedRoute,
              private customEventService: CustomEventService,
              private storageManagementService: StorageManagementService,
              private blazeRulesService: BlazeService,
              private faqCommonService: FaqCommonService,
              private contentService: ContentService
  ) {
    super(storageManagementService);
  }

  @HostListener('keyup.enter', ['$event'])
  onKeyUpEnter(event) {
    event.preventDefault();
    event.target.click();
  }

  ngOnInit(): void {
    this.blazeRulesService.getFAQBlazeRule().subscribe(
      (faqResponse: FAQResponse) => {
        if (!this.storageManagementService.getSessionData(SessionConstants.FAQ)) {
        this.FAQs = this.faqCommonService.blazeSuccess(faqResponse);
        this.storageManagementService.setSessionData(SessionConstants.FAQ, this.FAQs);
        } else {
        this.FAQs = this.storageManagementService.getSessionData(SessionConstants.FAQ);
        }
        this.FAQs.forEach(value => {
          value.answer = value.answer.replace('<ul>', ''). replace('</ul>', '')
        })
      }, this.faqCommonService.blazeError
    );
    this.questionId = this.activatedRoute.snapshot.paramMap.get('id');
    this.prevPage = this.faqService.getPrevPage();
    if (this.questionId) {
      this.questionStack.id = this.questionId;
      this.questionStack.state = true;
      this.top++;
    }
  }

  ngAfterViewInit() {
    super.ngAfterViewInit();
  }

  accordion(que: string, question: string) {
    this.questionId = que;
    this.question = question;
    if (this.top !== 0) {
      if (this.questionId !== this.questionStack.id) {
        this.questionStack.state = true;
        this.questionStack.id = this.questionId;
      } else {
        this.questionStack.state = this.questionStack.state !== true;
      }
    } else {
      this.questionStack.state = true;
      this.questionStack.id = this.questionId;
      this.top++;
    }
    if (this.questionStack.state === true) {
      this.customEventService.faqTracking(this.question);
    }
  }

  faqClicked(answer_QID_185) {
    this.customEventService.linkClick(answer_QID_185, '')
  }

  onThumbsUpClick(faq: Faq) {
    const feedback: Feedback = new Feedback();
    feedback.if_thumbs_up_clicked = true;
    this.FAQs.filter(
      (faqParam: Faq) => (faqParam.id === faq.id)
    )[0].feedback = feedback;
  }

  onThumbsDownClick(faq: FAQAsset) {
    const feedback: Feedback = new Feedback();
    feedback.if_thumbs_down_clicked = true;
    this.FAQs.filter(
      (faqParam: Faq) => (faqParam.id === faq.id)
    )[0].feedback = feedback;
  }

}

