import {Component, OnInit} from '@angular/core';
import {CustomPageLabelComponent} from '../../../sub-atomic/custom-page-label/custom-page-label.component';

@Component({
  selector: 'form-page-label',
  templateUrl: './form-page-label.component.html',
  styleUrls: ['./form-page-label.component.scss']
})
export class FormPageLabelComponent extends CustomPageLabelComponent implements OnInit {

  ngOnInit() {
  }

}
