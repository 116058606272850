/**
 * Created by AC12394 on 8/29/2017.
 */

export const TermsAndConditionsContent = {
  paras: [
    `Lorem ipsum dolor sit amet, amet cras faucibus eu, laoreet sed mauris sed. Nam eget ut, risus commodo urna praesent maecenas massa, adipiscing fames, leo quam ac. Porttitor id condimentum quisque tellus risus viverra, nibh a nam magna eros proin. Fringilla nam nisl nec felis. Ac donec donec maecenas sed, qui vitae lorem nibh consectetuer eu, exercitation sed eu libero, tempor interdum pulvinar platea mauris sed tellus. Nibh mauris et, ad elementum, maecenas donec sapien egestas placerat. Eget odio volutpat morbi dis, sodales iaculis nunc non eget. Ac eget suscipit elit, vehicula tellus. Ac nisl leo, mollis molestie adipiscing arcu tempus. Semper dolor cras lectus, sem sed id mauris, et a lobortis pellentesque mus sed morbi. At tristique in lectus, mauris velit nibh egestas cubilia nunc, vel at, aenean ligula pede lobortis. Et wisi mattis maecenas ut integer mattis, felis condimentum adipiscing penatibus, wisi blandit felis praesent mauris, risus lacinia tincidunt wisi duis massa.`,
    `Aliquam wisi porta lectus sem, velit mus urna, egestas ullamcorper proin amet. Elementum elementum proin interdum tortor dolor. Pellentesque massa cras adipiscing sed. Augue vel, libero sapien ut sed urna cras. Nibh sollicitudin porttitor sapien suspendisse vitae sed, ut nunc magna iaculis ante tempor sed, ut vehicula eros commodo nisl gravida nam, ut sed aliquam tellus diam, nec consequat. Nulla pellentesque, mauris pede varius orci, at donec quam cras risus ante, in sit tincidunt, mi dolor sociosqu id. Sed nec non suspendisse. Tortor nulla elit pretium malesuada, vestibulum et sem metus wisi, enim dictum nam ligula non eu mattis.`,
    `Velit et ut velit nec, netus tristique vel laoreet, massa proin auctor convallis nunc, adipiscing erat, eu interdum orci semper wisi. Felis montes non risus, et tincidunt vulputate velit molestie, fusce quam dignissim lectus. Aliquet metus morbi vivamus, egestas lacinia praesent tempor, quis phasellus, tristique enim sit lectus, erat mi adipiscing nonummy. Fusce eget tristique ut augue. Cursus wisi ac ligula in nec duis. Dignissim vel erat cras, eget lacinia neque erat felis praesent. Vestibulum quam a, lectus duis blandit suspendisse, purus lorem sed, egestas ridiculus tellus consectetuer. Aliquam orci tortor nunc justo. Sed venenatis laoreet morbi dapibus officia amet, urna sapien, rutrum lobortis iusto non accumsan viverra. Id odio blandit non sed, massa amet, nonummy tortor leo quis. Id consectetuer, fringilla eget neque odio in ipsum. Felis mauris, rhoncus pharetra rutrum gravida, odio odio metus duis, dolores mi dolor vulputate augue.`,
    `In arcu. Convallis tincidunt senectus et dictum vivamus ut, maecenas erat vestibulum et elementum donec, velit natoque in in. In enim fusce, sit tempus mollis, suspendisse donec dolor. Faucibus blandit vehicula aenean. Ultricies in nibh vehicula, lectus velit sapien in sed faucibus risus, velit quisque. Non magnis in volutpat nec morbi. Explicabo cursus massa, habitant suscipit, ullamcorper ut hendrerit. In consequat quia est quam, sed sit nonummy, quis interdum. Libero eros per morbi eget tristique. Non orci mollis lacinia bibendum justo, tempor sodales potenti augue, purus condimentum id ligula nulla varius gravida, consequat dignissim viverra amet ut, rhoncus ac venenatis tempus. Eu quis nunc sed scelerisque vitae suspendisse, leo incididunt suspendisse donec a at, sed elementum condimentum in imperdiet gravida, enim accumsan in. Quisque cras rhoncus eu lectus ultricies, ante magna fusce in tristique sit, a dignissim et molestie est facilisis, quisque eu nibh quis non sed lectus.
`
  ],
  siteWideParas: [
    `You must agree to this Electronic Transactions Terms of Service and Paperless Delivery of Communications Terms and Conditions (collectively the “Agreement”) in order to enter into an electronic transaction; submit documents electronically, including the electronic submission of a claim, on this website; and to receive communications from The Hartford, its affiliated companies and/or third party service providers (collectively the “Company”) electronically. Please read this Agreement carefully.`,
    `<br>`,
    `<b>ELECTRONIC TRANSACTIONS TERMS OF SERVICE</b>`,
    `<br>`,
    `The Company provides materials and services located on its websites (“Website(s)” or “Site(s)”) to its users, which includes, but is not limited to, insureds, prospective insureds, claimants, and producers (“Website Users”), conditioned upon and subject to acceptance of this Agreement. By using and/or accessing the Websites or their services, you, as a Website User, acknowledge that you have read, understand, and agree to be legally bound by this Agreement. You will be asked to confirm your acceptance of this Agreement as directed on this Website`,
    `<br>`,
    `<b>1. Disclosures & Consent to Electronic Transactions / Electronic Signature</b>`,
    `a. By entering the Websites and agreeing to be bound by this Agreement, you are providing your affirmative consent to the use of an electronic signature to submit a claim, application, or other document in electronic form. You understand and agree that the Company will rely on your electronic signature to process the document you submit.`,

    `b. Once you have given consent for an electronic transaction, you may withdraw your consent only if the Company has not taken action in reliance on your consent. To update your contact information or to withdraw your consent to an electronic transaction, you may contact the Company by calling the number under Contact Us on thehartford.com. If the electronic transaction involves the submission of a claim, you may also contact your claim office. There is no fee charged for such requests. However, your withdrawal of consent shall not diminish the legal effectiveness or enforcement of any transaction agreed to prior to your withdrawal of consent. If the electronic transaction involves the submission of a claim and you withdraw consent after your claim has been submitted, your electronically submitted claim will still remain in effect unless the Company receives notice to withdraw or cancel the claim submission. `,
    `<br>`,
    `<b>2. Additional Disclosures</b>`,
    `a. You acknowledge that you understand that you are not obligated to enter into transactions electronically and that you have a right to alternative methods of submission, such as by paper or telephone. By entering the Websites and agreeing to be bound by this Agreement, you affirmatively consent to conduct transactions in electronic form. If you wish to use an alternative method of submission, please contact the Company. There is no charge to you for alternative methods of submission.`,
    `b. You agree that your electronic signature authorizes the Company:

    (1)   to process the information you submitted at your request and any future transactions that may be needed in relation to your submission. The Company will rely on your electronic signature as authorization to process the document submitted through the Websites.

    (2)   to communicate with you by mail, telephone or electronically by sending to you communications including, but not limited to, any and all types of electronic communication by email, fax, mail and telephone.`,
    `<br>`,
    `<b>3. Additional Disclosures specific to Claims Submissions</b>`,
    `a. When you have successfully entered and submitted your claim information on the Website confirmation page, you will be given the opportunity to print a paper copy of your claim submission free of charge. Please print a paper copy of the confirmation page verifying your claim information for your records. You will not be able to access your confirmation page or the claim information you entered on the Website after you submit it. You may, however, check the status of your claim through the Website after you submit it.`,
    `b. You understand and agree that to access the Website to view the status of your claim at any time you will need the following hardware and software: Microsoft Windows or Macintosh compatible computer, Internet access, an Internet Browser (Internet Explorer version 5.5 or higher or Netscape 7.0 or higher). After you submit your claim through the Website, you understand and agree that you will not have access to your electronic confirmation page or the claim information you submitted. You may print a confirmation of your claim at the time of submission.`,
    `<br>`,
    `<b>4. Miscellaneous Terms and Conditions</b>`,
    `a. The Company provides information and services on its Websites, and all layouts, materials, designs, and images on the Websites are copyrighted or proprietary to the Company. As a condition of your use of the Websites and their services, you agree that you will not use the contents of the Websites in any other website or in a network computer environment. All uses of the Websites apart from your educational, informational and enrollment purposes are strictly prohibited.`,
    `b. In addition, as a further condition of your use of the Websites and their services, you agree that you will not use the Websites or services to infringe the intellectual property rights of the Company in any way; use the Websites or services to modify or manipulate the Websites or services or any of the Company’s hardware or software to invade the privacy of, or obtain private information concerning any Website User of the Company, or to erase or damage any information contained on the computer or any user connected to the Websites or the services, or to reveal any portion of the Websites or the services. Furthermore, you agree that you will not use the Websites or services to introduce viruses, worms, Trojan horses or other destructive or harmful codes, and you agree that you will take every precaution not to introduce these harmful codes into the Websites and services.`,
    `c. You agree that the materials and services on the Websites are provided “as is” and for informational purposes only. You understand that the Company makes no representations or warranties that the materials are suitable for your needs, are complete, timely, reliable, or are free from errors, inaccuracies or typographical mistakes. The information contained on the Websites was believed to be accurate at the time it was placed on the Websites. The Company periodically updates the information on the Websites, but disclaims any responsibility to do so. Therefore, you understand and agree that the Company takes no responsibility for the timeliness, accuracy or applicability of the information at the time it may be accessed and that the Company disclaims all warranties, express or implied, including, but not limited to, implied warranties of merchantability of fitness for a particular purpose or non-infringement of other’s rights.`,
    `d. You understand and agree that the Company is not engaged in rendering legal, tax, insurance benefits or any other advice through the Websites or services. Your insurance needs are highly individual, and the Company does not represent itself as giving financial advice or advice on your insurance needs through the Websites and services. You understand and agree that you should consult your own attorney and financial advisor(s) for advice in these areas.`,
    `e. You understand and agree that the information on the Company’s insurance products as described in the Websites is not complete and does not change or affect the insurance policies as actually issued. You understand that only the insurance policy issued to the policyholder can fully describe all of the provisions, terms, conditions, limitations and exclusions of your coverage. In the event of any difference between the information on the Websites and services, the benefit highlights, and the insurance policy, the terms of the insurance policy apply. For insurance, complete coverage information is found in the Certificate of Insurance.`,
    `f. If the electronic transaction involves the submission of a claim, you understand and agree that your claim for benefits will not be valid if you are not eligible for benefits in accordance with the terms of the policy.`,
    `g. If the electronic transaction involves the submission of a claim and you choose not to accept the terms of this Agreement, your claim will not be submitted and will not be valid. You may return to the Website to submit a claim at any time within the applicable time frames for submitting a claim as outlined in the policy.`,
    `h. You acknowledge that you have received and read The Hartford’s Privacy Policy and Legal Notice.`,
    `<br>`,
    `1The Hartford® is The Hartford Financial Services Group, Inc. and its subsidiaries.`,
    `<br>`,
    `<div><b> PAPERLESS DELIVERY OF COMMUNICATIONS TERMS AND CONDITIONS </b></div>`,
    `<br>`,
    `1. By consenting to receive communications from the Company electronically, you are agreeing that documents and official notices that we are required to send to you may be sent only in electronic form and no longer be sent in paper form. You agree these paperless communications are the legal equivalent of officially required communications relating to your policy or claim which you would otherwise receive in paper form. You agree to receive such mailings and communications electronically. These communications may include policy declarations or claim documents and related forms, cancellation or nonrenewal notices, insurance ID cards, legally required notices, and other official correspondence as these communications become available for electronic delivery. You agree that your consent to receive mailings and communications electronically remains in effect after a policy modification or renewal. Not all documents are currently available for electronic delivery. Those that are not available will continue to be sent to you by U.S. mail. As new documents become available for electronic delivery, the Company may send them electronically based on your consent preference choices.`,
    `2. You may at any time, request that we resume communications through the delivery of paper documents. You will not be charged a fee for this request. You may make such request by contacting our Call Center or by visiting Contact Us to obtain the correct contact information.  Upon receipt of your request to receive communications in a paper form, the change is typically effective during the policy term, within 10 business days.`,
    `<div> 3. You agree to provide us with your current email address so we can send you notices via email and notify you that documents are available for your review. You also agree to update your account and notify us of any change in your email address. You can make such a change by visiting our website and changing the preferences on your account or by contacting our Call Center via phone at the number listed in the <a  [routerLink]="['/contactUs']">Contact US</a> link of thehartford.com. You agree to be responsible for the consequences of your failure to provide us with your current email address, such as late fees or as otherwise provided by law. In the event of your failure to maintain a valid email address and any resulting inability to deliver official communications to you via email, we will presume that you have withdrawn consent to receive communications by electronic means and will begin to send these communications to you in paper form during the policy term until we obtain a corrected email address.</div>`,
    `<div> 4. You may request a paper copy of an official notice sent to you, or of your policy documents. There is no fee to request such copies. You may make such request by contacting our Call Center via phone at the number listed in the <a  class="profile-link" [routerLink]="['/contactUs']">Contact US</a> link on thehartford.com.</div>`,

    `<div> 5. SYSTEM REQUIREMENTS: You acknowledge and agree that you have sufficient access to a privately owned computer and email system (as opposed to one with limited access, such as those housed in public libraries) that will: Permit you to access, view, and print the communications we will send; permit you to receive emails that contain hyperlinks to websites; and permit you to access websites. The following system requirements are necessary for you to receive and view these communications: You must have one of the two most recent versions of Adobe Reader. Download the current version of Adobe Reader by either clicking the <a  [routerLink]="['/']">Get Adobe Reader.</a> graphic on the Viewing Test screen within the enrollment process or download it from the Adobe website at <a  [routerLink]="['/www.adobe.com']">www.adobe.com.</a> Online documents are supported on the two most recent versions of Microsoft Internet Explorer, Mozilla Firefox, and Google Chrome.</div>`

  ],
  TextTCParas: [
    `Terms and Conditions
     This service provides text (SMS/MMS) messages from The Hartford to any customer who provides his/her cell phone number with the intent to receive employee benefits information. In order to participate, the customer must have a mobile telephone number with an SMS supported carrier and be employed by a Hartford employee benefits plan or policyholder. Consent to receive text messages is not a condition of purchase`,
    `1. The Hartford does not charge you to send text messages to you. However, your carrier’s standard data and messaging rates may apply to your entry or submission message, our confirmation and all subsequent SMS messages. Any applicable charges will be billed by and payable to your mobile service provider.`,
    `2. By providing your mobile number, you consent to receive text messages from us according to the program frequency. You may terminate this service at any time by texting ‘STOP’ to 56420`,
    `3. By subscribing or otherwise using the service, you acknowledge and agree that we will have the right to change and/or terminate the service at any time, with or without cause and/or advance notice. In no event will this service extend beyond the later of the end of your benefit enrollment period or the end of your claim for benefits.`,
    `4. The Hartford is not liable for any delays in the receipt of any SMS messages as delivery is subject to effective transmission from your mobile service provider. SMS message services are provided on an ‘as is’ basis and we make no warranty, express or implied, and all warranties are hereby disclaimed`,
    `5. Data obtained from you in connection with this SMS service may include your cell phone number, your carrier’s name, and the date time and content of your messages, in addition to any other information that you provide. We may use this information to contact you and to provide the services you request from us.  `,
    `6. If you have questions regarding privacy, please read our Privacy Policy at www.thehartford.com/customer-privacy-notice
         Contact Us:
         The Hartford
         One Hartford Plaza
         Hartford, Conn. 06155
         1 (800) 549-6514`,
    `AT&T, Sprint, T-Mobile®, Verizon Wireless, Boost, Cricket, MetroPCS, U.S. Cellular, Virgin Mobile, ACS Wireless, Appalachian Wireless, Bluegrass Cellular, Carolina West Wireless, Cellcom, C-Spire Wireless (formerly Cellsouth), Cellular One of East Central Illinois, Cincinnati Bell Wireless, Cross (dba Sprocket), Duet IP, Element Mobile, EpicTouch, GCI Communications, Golden State, Hawkeye (Chat Mobility), Hawkeye (NW Missouri Cellular), Illinois Valley Cellular, Immix (Keystone Wireless / PC Management), Inland Cellular, iWireless, Mobi PCS (Coral Wireless LLC), Mosaic, MTPCS / Cellular One (Cellone Nation), Nex-Tech Wireless, nTelos, Panhandle Telecommunications, Peoples Wireless, Pioneer, Plateau, Revol Wireless, Rina - Custer, Rina - All West, Rina - Cambridge Telecom Coop, Rina - Eagle Valley Comm, Rina - Farmers Mutual Telephone Co, Rina - Nucla Nutria Telephone Co, Rina - Silver Star, Rina - South Central Comm, Rina - Syringa, Rina - UBET, Rina - Manti, South Canaan / CellularOne of NEPA, Thumb Cellular, Union Wireless, United, Viaero Wireless, West Central Wireless, Leaco, Nemont/Sagebrush.
      T-Mobile is not liable for delayed or undelivered messages`,
    `The Hartford® is underwriting companies Hartford Life and Accident Insurance Company and Hartford Life Insurance Company.`
  ]
};


export const TNCResponses = [{
  partyRefId: '957181',
  statusCode: '0',
  statusMsg: 'SUCCESS'
}, {
  partyRefId: '00002',
  res: {statusCode: '1', statusMsg: 'SUCCESS'}
}];
