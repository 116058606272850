import {Component, OnInit} from '@angular/core';

@Component({
  selector: 'app-leave-balance-section',
  templateUrl: './leave-balance-section.component.html',
  styleUrls: ['./leave-balance-section.component.scss']
})
export class LeaveBalanceSectionComponent implements OnInit {
  planSubtitle: any;
  subheader: string;
  messageText: string;
  helpText: string;
  companyLeave: any[];
  tipsLink: string;
  public barChartOptions = {
    fillColors : ['#018852', '#3A5A78', '#CCCCCC'],
  };
  public leaveBalance: any[] = [
    {
      planName: 'Active Plans',
      exhaustPlans: false,
      company: [
        {
          name: 'Family Medical Leave Act',
          subheader: 'Job Protected',
          available: `${10}h available`,
          review: `${2}h under review`,
          used: `${100}h used`,
          chartData: {
            available: 10,
            underReview: 2,
            used: 100
          }
        },
        {
          name: 'Connecticut State Leave',
          subheader: 'Job Protected',
          available: `${10}h available`,
          review: null,
          used: `${47}h used`,
          chartData: {
            available: 10,
            underReview: 0,
            used: 47
          }
        }
      ]
    },
    {
      planName: 'Upcoming Plans',
      exhaustPlans: true,
      company: [
        {name: 'Company ABC Leave', subheader: null, available: `${32}h ${15}m available`, review: null, used: null,
          chartData: {
            available: 32,
            underReview: 0,
            used: 0
          }
        }
      ]
    },
    {
      planName: 'Exhausted Plans',
      exhaustPlans: true,
      company: [
        {name: 'Company ABC Special Leave', subheader: null, available: `${0}h available`, review: null, used: null,
          chartData: {
            available: 0,
            underReview: 0,
            used: 0
          }}
      ]
    }
  ];

  constructor() {
  }

  ngOnInit() {
  }

  displayPlanSubtitle(plan) {
    switch (plan) {
      case 'Active Plans':
        this.planSubtitle = `${10}h available`;
        this.messageText = 'These plans are concurrent.';
        this.helpText = '*These balances may be impacted by other leaves';
        this.tipsLink = 'What are concurrent leave plans?';
        this.companyLeave = this.leaveBalance[0].company;
        break;
      case 'Upcoming Plans':
        this.planSubtitle = `${32}h ${15}m available`;
        this.messageText = 'These plans won\'t be available until you exhaust your active plans.';
        this.helpText = '*These balances may be impacted by other leaves';
        this.tipsLink = null;
        this.companyLeave = this.leaveBalance[1].company;
        break;
      case 'Exhausted Plans':
        this.planSubtitle = `${0}h available`;
        this.messageText = 'These are the plans you have used up.';
        this.helpText = '*These balances may be impacted by other leaves';
        this.tipsLink = null;
        this.companyLeave = this.leaveBalance[2].company;
        break;
      default:
        this.planSubtitle = '';
    }
    return this.planSubtitle;
  }

}
