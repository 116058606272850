/**
 * Created by FA81445 on 7/31/2017.
 */
import {User} from '../shared/models/user.model';

export const USERS: User[] = [
  {
    addressLine1: 'Address Line 1',
    addressLine2: 'Address Line 2',
    birthDt: null,
    caseId: '1173313',
    city: 'NEW YORK',
    countryCode: '1',
    emailAddr: 'ferdinand.aliaj@thehartford.com',
    emailAddrLastUpdtDt: '2020-05-11',
    empId: null,
    errorList: null,
    firstname: 'Mock-User',
    foreignProvinceCode: null,
    lastLoginDt: '2018-05-29',
    lastName: 'One',
    partyRefId: '1330345',
    processDt: '2017-07-07',
    profileId: '1',
    provinceCode: null,
    regTypeCd: 1,
    sessionIdentifier: null,
    sourcePartyDetailsList: [
      {
        activeCase: true,
        empEffDt: '2018-02-14',
        empId: null,
        empVersionId: 6605336,
        linkTypeCd: 1,
        partyId: 2926335,
        srcPartyName: 'THAA',
        srcSysId: '7067097',
        srcSystemCd: 8,
        stateCode: 'MA',
        workStateCd: 0
      },
    /*  {
        empEffDt: '2018-02-14',
        empId: null,
        empVersionId: 6605336,
        linkTypeCd: 1,
        partyId: 2074722,
        srcPartyName: 'THAA',
        srcSysId: '6253013',
        srcSystemCd: 3,
        stateCode: 'MA',
        workStateCd: 0
      }*/
    ],
    ssn: null,
    stateCode: '37',
    statusIndicator: 'Y',
    termsAndCondAcceptDt: '2018-04-18',
    termsAndCondEffectiveDt: '2013-05-13',
    termsAndConditionsExpiredFlag: 0,
    userId: null,
    zipCd: '10001',
    srcSysId: '9003249515',
    insuredId: '9003249515',
    claimEventId: '00000000',
    eligibilityId: '00000000',
    claims: null,
    notePrefSetInd: 'T',
    noteTnCVersnId: 'T',
    personIds: []

}

];
