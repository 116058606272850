
import {catchError, map} from 'rxjs/operators';
import {Injectable} from '@angular/core';
import {ResponseErrorHandlerService} from './response-error-handler.service';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {Observable} from 'rxjs';
import {env} from '../../../environments/environment-loader';

import {AppLabelConstants} from '../constants/appLabel.constants';


@Injectable()
export class AetnaClaimActivityService {

  constructor(private errorHandler: ResponseErrorHandlerService,
              private httpClient: HttpClient) {}

  getClaimActivities(personId: string, claimId: string): Observable<any> {

    return this.httpClient.get(env.thaaWKABPortalService.getAAClaimActivityURL + '&personid=' + personId + '&claimId=' + claimId,
      {
        headers: new HttpHeaders()
          .set(AppLabelConstants.CONTENT_TYPE_KEY, AppLabelConstants.APPLICATION_KEY)
      }).pipe(
      map(
        (response: Response) => {
          if (!this.errorHandler.isServiceResponseHasError(response)) {
            return response;
          } else {
            throw response;
          }
        }
      ),   catchError((error: any) => this.errorHandler.handleError(error)), );

  }

}
