
import {catchError, map} from 'rxjs/operators';
/**
 * Created by AC12212 on 2/24/2018.
 */

import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {ResponseErrorHandlerService} from './response-error-handler.service';
import {env} from '../../../environments/environment-loader';
import {Observable} from 'rxjs';

import {AppLabelConstants} from '../constants/appLabel.constants';


@Injectable()
export class ssoRegistrationService {

  constructor(private httpClient: HttpClient,
              private errorHandler: ResponseErrorHandlerService) {}

  ssoRegistration(caseID, employeeID, ssnValue, emailID): Observable<any> {
//Pending: to add optional parameters of request
      const request: string = JSON.stringify(
        {
          caseId: caseID,
          employeeId: employeeID,
          ssn: ssnValue,
          email: emailID
        }
      );

    return this.httpClient.post(env.gbSsoRegistration.ssoRegistrationURL, request,
      {
        headers: new HttpHeaders()
          .set(AppLabelConstants.CONTENT_TYPE_KEY, AppLabelConstants.APPLICATION_KEY),
      }).pipe(
      map(
        (response: Response) => {
          if (!this.errorHandler.isServiceResponseHasError(response)) {
            return response;
          } else {
            throw response;
          }
        }
      ), catchError((error: any) => this.errorHandler.handleError(error)), );

}



addProfile(req: any): Observable<any> {
    const request: string = JSON.stringify(req);

    return this.httpClient.post(env.gbEconsent.addProfileServiceURL, request,
      {
        headers: new HttpHeaders()
          .set(AppLabelConstants.CONTENT_TYPE_KEY, AppLabelConstants.APPLICATION_KEY),
      }).pipe(
      map(
        (response: Response) => {
          if (!this.errorHandler.isServiceResponseHasError(response)) {
            return response;
          } else {
            throw response;
          }
        }
      ), catchError((error: any) => this.errorHandler.handleError(error)), );

}
}
