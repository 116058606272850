
import {map, catchError} from 'rxjs/operators';
import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';


import {env} from '../../../environments/environment-loader';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {ResponseErrorHandlerService} from './response-error-handler.service';
import {PHARecordsAvailability} from '../models/pha.model';
import {PHAApplication} from '../models/pha-application.model';
import {AppLabelConstants} from '../constants/appLabel.constants';

/**
 * Created by AC12323 on 1/16/2018.
 */


@Injectable()
export class phaOverviewService {
  private applicationData: PHAApplication;
  constructor(private httpClient: HttpClient,
              private errorHandler: ResponseErrorHandlerService
  ) {

  }

  /*phaoverviewServices
  * Method name:  getPhaOverview()
  * purpose: Bellow method is used to call service if services is not avaliable then call data from mock services
  * Created Date: 08-02-2017
  *
  * */

  public setApplicationData(applicationData: PHAApplication) {
    this.applicationData = applicationData;
  }

  public getApplicationData() {
    return this.applicationData;
  }

  getPhaOverview(phaRequestParaMeter) {
    const header = {
      headers: new HttpHeaders()
        .set(AppLabelConstants.CONTENT_TYPE_KEY, AppLabelConstants.APPLICATION_KEY)
    }
      return this.httpClient.post(env.gbPha.phaOverviewURL, phaRequestParaMeter, header).pipe(
      map((phaOverViewresponase) => {
        if (!this.errorHandler.isServiceResponseHasError(phaOverViewresponase)) {
          return phaOverViewresponase; // actual response
        } else {
          throw phaOverViewresponase;
        }
      }), catchError((error: any) => this.errorHandler.handleError(error)), );

  }

  getPhaDetails(caseID: string, insuredID: string, applicationID: string, applicantID: string, coverageCategoryCD: string, coverageTypeCD: string ) {
      const header = {
        headers: new HttpHeaders()
          .set(AppLabelConstants.CONTENT_TYPE_KEY, AppLabelConstants.APPLICATION_KEY)
      };

      const methodBody = JSON.stringify(
        {
          caseId: caseID,
          insuredId: insuredID,
          applicationId: applicationID,
          applicantId: applicantID,
          coverageCategoryCd: coverageCategoryCD,
          coverageTypeCd: coverageTypeCD,
        }
      );

      return this.httpClient.post(env.gbPha.phaDetailsURL, methodBody, header).pipe(
      map((phaDetails) => {
        console.log(phaDetails);
        if (!this.errorHandler.isServiceResponseHasError(phaDetails)) {
          return phaDetails; // actual response
        } else {
          throw phaDetails;
        }
      }), catchError((error: any) => this.errorHandler.handleError(error)), );

  }

  isPulseRecordPresent(caseId, partyRefId: string): Observable<PHARecordsAvailability> {

      return this.httpClient.get<PHARecordsAvailability>(env.gbMudi.phaAvailabilityURL + '&caseId=' + caseId + '&partyRefId=' + partyRefId,
        {
          headers: new HttpHeaders()
            .set(AppLabelConstants.CONTENT_TYPE_KEY, AppLabelConstants.APPLICATION_KEY)
        }).pipe(
        map(
          (response: PHARecordsAvailability) => {
            if (!this.errorHandler.isServiceResponseHasError(response)) {
              return response;
            } else {
              throw response;
            }
          }
        ), catchError((error: any) => this.errorHandler.handleError(error)), );


  }

  searchForLetter( letterID ) {
    const request = JSON.stringify(
      {
        retrievalType: 'gbMU',
        page: 1,
        itemsPerPage: 100,
        sortAttribute : 'SourceCreationDate',
        sortExpression : 'DESC',
        letterId: letterID,
        searchAttributes: [
          {
            attribute: 'letterId',
            value: letterID
          },
          {
            attribute: 'DocumentSourceSystemName',
            value: 'ALL'
          }
        ],
        searchResultAttributes: [
          'HIGDocumentIdentifier',
          'DocumentType',
          'DocumentCreationDate',
          'SourceCreationDate',
          'DocumentId',
          'DocumentFileName'
        ]
      }
    );
    return this.httpClient.post((env.documentum.documentumURL_search), request,
      {
        headers: new HttpHeaders()
          .set(AppLabelConstants.CONTENT_TYPE_KEY, AppLabelConstants.APPLICATION_KEY)
      });
  }

  viewLetter(letterID, docId) {
    const request = JSON.stringify(
      {
        retrievalType: 'gbMU',
        letterId: letterID,
        searchAttributes: [
          {
            attribute: 'DocumentId',
            value: docId
          },
          {
            attribute: 'letterId',
            value: letterID
          }
        ]
      }
    );
    return this.httpClient.post((env.documentum.documentumURL_view), request,
      {
        headers: new HttpHeaders().set(AppLabelConstants.CONTENT_TYPE_KEY, AppLabelConstants.APPLICATION_KEY),
        responseType: 'arraybuffer'
      });
  }
}
