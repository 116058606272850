import { ChangeDetectorRef, Component, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { EventEmitter } from '@angular/core';
import { User } from '@shared/models/user.model';
import { StorageManagementService } from '@shared/services/storage-management.service';
import { ContentService } from '@shared/services/content.service';
import { DateLabelValueComponent } from '../datelabel-datevalue/date-label-value/date-label-value.component';
import { ProductService } from '@shared/services/product.service';
import { AppConstants } from '@shared/constants/app.constants';
import { AppLabelConstants } from '@shared/constants/appLabel.constants';
import { SessionConstants } from '@shared/constants/session.constants';
import { TitleCasePipe } from '@angular/common';
import { LeaveReasonCapitalizedPipe } from '@shared/pipes/leave-reason-capitalized.pipe';
import { FuturePaymentExistServiceService } from '@shared/services/future-payment-exist-service.service';
import { futurePaymentExist, IFuturePaymentsExistList } from '@shared/models/futurePaymentsExist.model';
import { AAClaimPaymentResponse } from '@shared/models/aetnaPayments.model';
import { AetnaPaymentsService } from '@shared/services/aetnaPayments.service';
import { PaymentsHistoryService } from '@shared/services/claim-payment-history.service';
import { ClaimPaymentRequest, ItotalPayments } from '@app/shared/models/Payment.model';
import { Subscription } from 'rxjs';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';

@Component({
  selector: 'claim-card',
  templateUrl: './claim-card.component.html',
  styleUrls: ['./claim-card.component.scss'],
})

export class ClaimCardComponent implements OnInit, OnDestroy {
  @Input() currentClaimRelated;
  @Input() currentClaim;
  @Input() claim;
  @Input() claimcardId?;
  @Input() showContainer: string;
  @Input() preRendering = 'skeleton-container';
  @Output() claimsWithFuturePayments: EventEmitter<string> = new EventEmitter();
  @Output() futurePaymentClaims: EventEmitter<IFuturePaymentsExistList> = new EventEmitter();
  @ViewChild(DateLabelValueComponent, { static: false }) DateLabelValue: DateLabelValueComponent;
  public appConstant = AppConstants;
  public appLabelConstant = AppLabelConstants;
  currentUser: User;
  public userClaims: any[] = [];
  showProgressIcon = true;
  orangeIconMissingInfo = true;
  deliveryDateLable: string;
  public surgerySection;
  public surgeryDateLabel: string;
  public futurePaymentArry = [];
  returnThanks;
  authorizedEndDate;
  public owcsContent;
  url: string;
  detailPage: string;
  disabilityDate: string;
  babyReport: string;
  returnhome: string;
  public cliamHistory;
  pageId: string;
  public pageId2;
  owscContent;
  section_title = '';
  public firstFuture = '';
  public secondFuture = '';
  extension_Status_Message = '';
  futurePaymentmsgText: string = '';
  futurePaymentmsgLink: string | SafeResourceUrl;
  extension_Status_Icon_Text = '';
  infoLabel = '';
  message_1_Label = '';
  gotoMissingInfo_Button = '';
  header_3 = '';
  message_3 = '';
  header_2 = '';
  message_2 = '';
  disability_Message = '';
  disability_Message1 = '';
  surgery_Message = '';
  maternity_Action_Message = '';
  public isLeaveRequestDisplay = false;
  surgeryClaimDisplay = false;
  maternityClaimDisplay = false;
  deliveryTwoWeekCheck = false;
  public futurePayment = false;
  thereIsPayment = false;
  servicedate;
  lastDayWork;
  serviceDateDisability;
  public claimCLosedMsg;
  public extensionStatusCode;
  hasPayments: boolean = false;
  claimPaymentRequest: ClaimPaymentRequest = new ClaimPaymentRequest();
  // public curPayments;
  public extensionSubStatusCode;
  public extensionUIAdjStatusIcon: boolean = false;
  public extensionUIMissStatusIcon: boolean = false;
  public missingInfoClaimThaa: boolean = false;
  public pflClaimReason = {
    leaveReason: '',
    leaveSubReason: ''
  };
  public isAA: boolean = false;
  public isBondChild: boolean = false;
  public leaveReasonCapitalized: LeaveReasonCapitalizedPipe = new LeaveReasonCapitalizedPipe();
  public futurePayResponse: futurePaymentExist = new futurePaymentExist();
  public showFuturePaymentLink: boolean = false;
  aaPayments: AAClaimPaymentResponse = new AAClaimPaymentResponse();
  futurePaymentSubscription;
  aaPaymentSubscription;
  DCSpaymentSubscription: Subscription;
  isBiggerIDLength: boolean;
  futurepaymentexistInfo: IFuturePaymentsExistList[] = [];
  isPaymentDisabled: boolean = false;
  isMissingInfoDisabled: boolean = false;
  constructor(private router: Router,
    private storageManagementService: StorageManagementService,
    private aetnaPaymentsService: AetnaPaymentsService,
    private contentService: ContentService,
    private paymentHistoryService: PaymentsHistoryService,
    private futurePaymentExistServiceService: FuturePaymentExistServiceService,
    private productService: ProductService,
    private changeDetector: ChangeDetectorRef,
    private sanitizer: DomSanitizer,
    private titlecasePipe: TitleCasePipe) {
  }

  ngOnInit() {
    this.isPaymentDisabled = this.storageManagementService.getSessionData(AppConstants.IS_PAYMENT_DISABLED);
    this.isMissingInfoDisabled = this.storageManagementService.getSessionData(AppConstants.IS_MISSING_INFO_DISABLED);
    this.isBiggerIDLength = (this.claim && this.claim.claimEventId && this.claim.claimEventId.length > this.appConstant.ID_LENGTH_LIMIT) ? true : false;
    this.getAAPaymentInfo();
    if (this.storageManagementService.getSessionData(SessionConstants.IS_LEAVE_REQUEST_DISPLAY)) {
      this.isLeaveRequestDisplay = this.storageManagementService.getSessionData(SessionConstants.IS_LEAVE_REQUEST_DISPLAY);
    }
    this.returnhome = sessionStorage.getItem(SessionConstants.RETURN_HOME);
    this.returnThanks = sessionStorage.getItem(SessionConstants.RETURN_THANKS);
    this.surgerySection = sessionStorage.getItem(SessionConstants.SURGERY_DIV);
    this.surgeryDateLabel = AppLabelConstants.SURGERY_DATE_LABEL;
    this.deliveryDateLable = AppLabelConstants.DELIVERY_DATE_LABEL;
    this.detailPage = sessionStorage.getItem(SessionConstants.DETAIL_PAGE);
    this.disabilityDate = sessionStorage.getItem(SessionConstants.DISABILITY_DATE);
    this.babyReport = sessionStorage.getItem(SessionConstants.BABY_REPORT);
    this.hideProgressIconChange();
    sessionStorage.setItem(SessionConstants.CLAIM_VIEW, SessionConstants.CLAIM_VIEW);
    sessionStorage.removeItem(SessionConstants.CLAIM_DETAILS);
    sessionStorage.removeItem(SessionConstants.NEW_DATE);
    sessionStorage.removeItem(SessionConstants.RETURN_THANKS);
    this.cliamHistory = sessionStorage.getItem(SessionConstants.CLAIM_HISTORY);
    this.integrateOWCS();
    this.setupBenefitsData();
    if (this.claim.coverageCategory === AppConstants.PFL) {
      this.extensionStatusesForPFL();
    } else {
      this.extensionStatuses();
    }
    this.missingInfThaaCheck(this.claim);
    if (this.claim.sourceSystemId === AppConstants.SOURCE_SYS_ID_AA && this.claim.coverageCategory === AppConstants.PFL) {
      this.isAA = true;
      this.claimReasonWithName();
    }
  }

  //call for AA claim payments

  getAAPaymentInfo() {
    this.showFuturePaymentLink = false;
    this.hasPayments = false;
    const isThereRecentPayments = this.paymentHistoryService.getClaimPaymentInfo(this.claim.claimEventId);
    if (isThereRecentPayments && isThereRecentPayments.claimEventId === this.claim.claimEventId) {
      if (isThereRecentPayments && isThereRecentPayments.payments && isThereRecentPayments.payments.length > 0) {
        this.hasPayments = true;
      } else {
        this.hasPayments = false;
      }
      this.getFuturePaymentInfo();
    } else {
      if (this.claim.sourceSystemId === AppConstants.SOURCE_SYS_ID_DCS) {
        this.callGetPaymentHistory();
      } else if (this.claim.sourceSystemId === AppConstants.SOURCE_SYS_ID_AA) {
        this.callGetAetnaPaymentHistory();
      }
    }
  }
  callGetPaymentHistory() {
    const claimantDetails = this.storageManagementService.getAppData(SessionConstants.USER_DATA);
    this.claimPaymentRequest.insuredId = claimantDetails.srcSysId;
    this.claimPaymentRequest.claimEventId = this.claim.claimEventId;
    this.DCSpaymentSubscription = this.paymentHistoryService.getClaimPaymentHistory(this.claimPaymentRequest).subscribe(
      val => {
        if (val) {
          const claimPaymentHistoryResponse = val;
          this.storageManagementService.setAppData(SessionConstants.CLAIM_PAYMENTS, claimPaymentHistoryResponse);
          const claimType = this.paymentHistoryService.dataExists(this.claim.claimType);
          const claimSubType = this.paymentHistoryService.dataExists(this.claim.claimSubType);
          if (claimPaymentHistoryResponse.claimPaymentInfo) {
            this.hasPayments = true;
            const payment = claimPaymentHistoryResponse.claimPaymentInfo;
            this.paymentHistoryService.setClaimPaymentInfo(this.claim.claimEventId, payment);
            payment.forEach(paymentMade => {
              this.paymentHistoryService.paymentsSortedByIssueDate(
                this.claim.claimEventId, claimType, claimSubType, AppConstants.SOURCE_SYS_ID_DCS, '', paymentMade, null, this.claimPaymentRequest.insuredId
              );
            });
          } else {
            this.hasPayments = false;
            this.paymentHistoryService.setClaimPaymentInfo(this.claim.claimEventId, []);
          }
          this.getFuturePaymentInfo();
          this.paymentHistoryService.setClaimPaymentDetails(this.claim.claimEventId, claimPaymentHistoryResponse, AppConstants.SOURCE_SYS_ID_DCS);
          const totalPaymentsData = this.storageManagementService.getSessionData(AppLabelConstants.PAYMENTS_LIST_DCS);
          let totalPayementClaimResponseArr = [];
          if (totalPaymentsData) {
            totalPayementClaimResponseArr = totalPaymentsData
          } else {
            totalPayementClaimResponseArr = [];
          }
          const totalPayementClaimResponse: ItotalPayments = {
            claimEventId: this.claim.claimEventId,
            totalPaymentResponseData: claimPaymentHistoryResponse
          }
          if (!totalPayementClaimResponseArr.some(element => element.claimEventId === this.claim.claimEventId)) {
            totalPayementClaimResponseArr.push(totalPayementClaimResponse)
          }
          this.storageManagementService.setSessionData(AppLabelConstants.PAYMENTS_LIST_DCS, totalPayementClaimResponseArr);
        }
      }, (error) => {
        const totalPaymentsData = this.storageManagementService.getSessionData(AppLabelConstants.PAYMENTS_LIST_DCS);
        let totalPayementClaimResponseArr = [];
        if (totalPaymentsData) {
          totalPayementClaimResponseArr = totalPaymentsData
        } else {
          totalPayementClaimResponseArr = [];
        }
        const totalPayementClaimResponse: ItotalPayments = {
          claimEventId: this.claim.claimEventId,
          totalPaymentResponseData: error
        }
        if (!totalPayementClaimResponseArr.some(element => element.claimEventId === this.claim.claimEventId)) {
        totalPayementClaimResponseArr.push(totalPayementClaimResponse)
        }
        this.storageManagementService.setSessionData(AppLabelConstants.PAYMENTS_LIST_DCS, totalPayementClaimResponseArr);
      }
    );
  }
  callGetAetnaPaymentHistory() {
    this.aaPaymentSubscription = this.aetnaPaymentsService.getAetnaDisabilityClaimPayments(this.claim.personId, this.claim.claimEventId).subscribe(
      val => {
        if (val) {
          const aaClaimPaymentResponse = val;
          if (aaClaimPaymentResponse && aaClaimPaymentResponse.paymentList && aaClaimPaymentResponse.paymentList.length > 0) {
            const claimType = this.paymentHistoryService.dataExists(this.claim.claimType);
            const claimSubType = this.paymentHistoryService.dataExists(this.claim.claimSubType);
            this.paymentHistoryService.updateClaimPaymentSession(aaClaimPaymentResponse, this.claim.claimEventId, this.claim.personId, claimType, claimSubType);
            this.storageManagementService.setSessionData(SessionConstants.DISPLAY_PAY_STUB, aaClaimPaymentResponse.displayPaystub);
            this.hasPayments = true;
          } else {
            this.hasPayments = false;
            this.paymentHistoryService.setClaimPaymentInfo(this.claim.claimEventId, []);
          }
          this.getFuturePaymentInfo();
        }
      });
  }
  showHideFuturePaymentsLink() {
    this.showFuturePaymentLink = false;
    let leaveSubType = 'leaveSubType';
    if (this.claim.leaveSubType) {
      leaveSubType = this.claim.leaveSubType;
    }
    if (this.futurePayment && this.claim.coverageType !== this.appConstant.LTD && this.claim.fundingMethod === this.appConstant.FI
      && this.claim.claimOnlineStatusCode === this.appConstant.COS_CLAIM_APPROVED_CD_04 &&
      ((this.claim.coverageCategory.toLowerCase() === this.appConstant.PFL.toLowerCase() || this.claim.coverageCategory.toLowerCase() === this.appConstant.WD.toLowerCase())
        && leaveSubType.toLowerCase() !== this.appConstant.INTERMITTENT.toLowerCase())) {
      this.showFuturePaymentLink = true;
    } else {
      this.showFuturePaymentLink = false;
    }
  }


  /**
   * To fetch relationship, insured first name and display in this form 'Relationship, Name'
   */
  claimReasonWithName() {
    if (this.claim.leaveReason && this.claim.leaveReason.length > 0) {
      this.claim.leaveReason = this.leaveReasonCapitalized.transform(this.claim.leaveReason);
    }
    this.transformName();
    if (this.claim.leaveReason && this.claim.leaveReason.toLowerCase().trim() === AppConstants.BOND_WITH_NEW_CHILD) {
      this.isBondChild = true;
      this.pflClaimReason.leaveReason = ((this.claim.leaveFirstName !== this.claim.insuredFirstName ||
        this.claim.leaveLastName !== this.claim.insuredLastName) &&
        (this.claim.leaveFirstName && this.claim.leaveLastName && this.claim.leaveReason)) ?
        this.claim.leaveReason.trim() + ', ' + this.claim.leaveFirstName :
        this.claim.leaveReason;
    } else {
      this.isBondChild = false;
      this.pflClaimReason.leaveSubReason = ((this.claim.relationship && this.claim.leaveFirstName) &&
        (this.claim.leaveFirstName !== this.claim.insuredFirstName ||
          this.claim.leaveLastName !== this.claim.insuredLastName)) ?
        this.claim.relationship.trim() + ', ' + this.claim.leaveFirstName : this.claim.relationship;
    }
  }

  /**
   * To transform insured first name's first letter as upper case and remaining in lower case
   */
  transformName() {
    this.claim.leaveFirstName = this.claim.leaveFirstName ?
      this.titlecasePipe.transform(this.claim.leaveFirstName) : this.claim.leaveFirstName;
    this.claim.leaveLastName = this.claim.leaveLastName ?
      this.titlecasePipe.transform(this.claim.leaveLastName) : this.claim.leaveLastName;
    this.claim.insuredFirstName = this.claim.insuredFirstName ?
      this.titlecasePipe.transform(this.claim.insuredFirstName) : this.claim.insuredFirstName;
    this.claim.insuredLastName = this.claim.insuredLastName ?
      this.titlecasePipe.transform(this.claim.insuredLastName) : this.claim.insuredLastName;
  }

  extensionStatuses() {
    this.extensionStatusCode = this.claim.extensionStatusCode;
    this.extensionSubStatusCode = this.claim.extensionSubStatusCode;
    const coverageTypeList = [AppConstants.NST, AppConstants.STAT, AppConstants.SUPP, AppConstants.CORE, AppConstants.BUYUP];
    const extensionServiceData = [this.claim.coverageCategory, this.extensionStatusCode, this.extensionSubStatusCode, this.claim.rtwStatusCode];
    const extensionStatusCodes = [[AppConstants.WD, AppConstants.EXTENSION_UNDER_REVIEW_CD_1, AppConstants.EXT_MISSING_INFO_CD_1, AppConstants.RTWS_CD_42003],
    [AppConstants.WD, AppConstants.EXTENSION_UNDER_REVIEW_CD_1, AppConstants.EXT_IN_ADJUDICATION_CD_2, AppConstants.RTWS_CD_42003],
    [AppConstants.WD, AppConstants.EXTENSION_UNDER_REVIEW_CD_1, AppConstants.EXT_IN_ADJUDICATION_CD_2, AppConstants.RTWS_CD_42002],
    [AppConstants.WD, AppConstants.EXTENSION_UNDER_REVIEW_CD_1, AppConstants.EXT_MISSING_INFO_CD_1, AppConstants.RTWS_CD_42002],
    [AppConstants.WD, AppConstants.EXTENSION_CLOSED_CD_4, AppConstants.EXT_EXTENSION_MISSING_INFO_CD_8, AppConstants.RTWS_CD_42003],
    [AppConstants.WD, AppConstants.EXTENSION_CLOSED_CD_4, AppConstants.EXT_EXTENSION_MISSING_INFO_CD_8, AppConstants.RTWS_CD_42002]];
    return ((coverageTypeList.indexOf(this.claim.coverageType) > -1) ?
      (JSON.stringify(extensionServiceData) === JSON.stringify(extensionStatusCodes[0]) || JSON.stringify(extensionServiceData) === JSON.stringify(extensionStatusCodes[3]) || JSON.stringify(extensionServiceData) === JSON.stringify(extensionStatusCodes[4])
        || JSON.stringify(extensionServiceData) === JSON.stringify(extensionStatusCodes[5])) ?
        (this.claim.extensionUIMissStatusIcon = true) :
        (JSON.stringify(extensionServiceData) === JSON.stringify(extensionStatusCodes[1]) || JSON.stringify(extensionServiceData) === JSON.stringify(extensionStatusCodes[2])) ?
          (this.claim.extensionUIAdjStatusIcon = true) : false : false);
  }

  extensionStatusesForPFL() {
    this.extensionStatusCode = this.claim.extensionStatusCode;
    this.extensionSubStatusCode = this.claim.extensionSubStatusCode;
    //  const coverageTypeList = [AppConstants.NST, AppConstants.STAT, AppConstants.SUPP, AppConstants.CORE, AppConstants.BUYUP, AppConstants.PFL];
    const extensionServiceData = [this.claim.coverageCategory, this.extensionStatusCode, this.extensionSubStatusCode, this.claim.rtwStatusCode];
    const extensionStatusCodes = [[AppConstants.PFL, AppConstants.EXTENSION_UNDER_REVIEW_CD_1, AppConstants.EXT_MISSING_INFO_CD_1, AppConstants.RTWS_CD_42003],
    [AppConstants.PFL, AppConstants.EXTENSION_UNDER_REVIEW_CD_1, AppConstants.EXT_IN_ADJUDICATION_CD_2, AppConstants.RTWS_CD_42003],
    [AppConstants.PFL, AppConstants.EXTENSION_UNDER_REVIEW_CD_1, AppConstants.EXT_IN_ADJUDICATION_CD_2, AppConstants.RTWS_CD_42002],
    [AppConstants.PFL, AppConstants.EXTENSION_UNDER_REVIEW_CD_1, AppConstants.EXT_MISSING_INFO_CD_1, AppConstants.RTWS_CD_42002],
    [AppConstants.PFL, AppConstants.EXTENSION_CLOSED_CD_4, AppConstants.EXT_EXTENSION_MISSING_INFO_CD_8, AppConstants.RTWS_CD_42003],
    [AppConstants.PFL, AppConstants.EXTENSION_CLOSED_CD_4, AppConstants.EXT_EXTENSION_MISSING_INFO_CD_8, AppConstants.RTWS_CD_42002],
    ];
    return ((JSON.stringify(extensionServiceData) === JSON.stringify(extensionStatusCodes[0]) || JSON.stringify(extensionServiceData) === JSON.stringify(extensionStatusCodes[3]) || JSON.stringify(extensionServiceData) === JSON.stringify(extensionStatusCodes[4])
      || JSON.stringify(extensionServiceData) === JSON.stringify(extensionStatusCodes[5])) ?
      (this.claim.extensionUIMissStatusIcon = true) :
      (JSON.stringify(extensionServiceData) === JSON.stringify(extensionStatusCodes[1]) || JSON.stringify(extensionServiceData) === JSON.stringify(extensionStatusCodes[2])) ?
        (this.claim.extensionUIAdjStatusIcon = true) : false);
  }
  setupBenefitsData() {
    this.userClaims = [this.currentClaim, this.currentClaimRelated];

    this.pageId2 = this.contentService.getPageId(AppConstants.CLAIM_DETAILS);
    if (this.pageId2) {
      this.populateContent(this.pageId);
    } else {
      //calling the sites service again
      this.contentService.getSiteMap().subscribe(
        val => {
          if (val) {
            const siteMapResponse = val;
            this.storageManagementService.setSessionData(SessionConstants.SITE_MAP_DATA, siteMapResponse);
            this.pageId2 = this.contentService.getPageId(AppConstants.CLAIM_DETAILS);
            this.populateContent(this.pageId2);
          }
        }, (error) => {
        });
    }
  }

  populateContent(pageId) {
    if (this.storageManagementService.getSessionData(SessionConstants.OWCS_CONTENT)) {
      this.fetchOWCSContentFromSession();
    } else {
      this.fetchOWCSContentFromService();
    }
  }

  navToFuturePayments(curClaim, authorizedEndDate, claimEventId) {
    curClaim = this.claim;
    this.storageManagementService.removeSessionObject(SessionConstants.FUTURE_PAYMENTS);
    this.storageManagementService.setSessionObject(SessionConstants.CUR_CLAIM, curClaim);
    this.storageManagementService.setSessionObject(SessionConstants.AUTHORIZED_END_DATE, authorizedEndDate);
    this.storageManagementService.removeAttrFromSessionData(SessionConstants.FAQ);
    this.DateLabelValue.claimEventId(claimEventId);
    this.router.navigate([AppLabelConstants.LINK_TO_SEE_MORE_FUTURE_PAYMENTS]);
  }


  fetchOWCSContentFromSession() {
    this.owscContent = this.storageManagementService.getSessionData(SessionConstants.OWCS_CONTENT);
    if (this.owscContent && this.owscContent.CD_103_Body) {
      this.claimCLosedMsg = this.owscContent.CD_103_Body.value
    }
  }


  getFuturePaymentInfo() {
    this.futurePayment = false;
    let leaveSubType = 'leaveSubType';
    if (this.claim.leaveSubType) {
      leaveSubType = this.claim.leaveSubType;
    }
    if (this.claim.sourceSystemId === AppConstants.SOURCE_SYS_ID_AA && this.claim.personId && this.claim.claimEventId && this.claim.coverageType !== AppConstants.FLEX) {
      this.futurePaymentSubscription = this.futurePaymentExistServiceService.getFuturePaymentsExist(this.claim.personId, this.claim.claimEventId).subscribe(
        val => {
          if (val) {
            this.futurePayResponse = val;
            if (JSON.parse(this.futurePayResponse.isFuturePaymentApplicable)) {
              if (this.claim.coverageCategory.toLowerCase() !== this.appConstant.LTD.toLowerCase()
                && leaveSubType.toLowerCase() !== this.appConstant.INTERMITTENT.toLowerCase()) {
                this.claimsWithFuturePayments.emit(this.claim.claimEventId);
              }
              this.futurePayment = true;
              this.showHideFuturePaymentsLink()
            }
            const claimsFP: IFuturePaymentsExistList = {
              claimEventId: this.claim.claimEventId,
              value: this.futurePayment.toString()
            };
            this.futurePaymentClaims.emit(claimsFP);
          }
        });
    }
  }

  ngOnDestroy() {
    if (this.futurePaymentSubscription) {
      this.futurePaymentSubscription.unsubscribe();
    }
    if (this.aaPaymentSubscription) {
      this.aaPaymentSubscription.unsubscribe();
    }
    if (this.DCSpaymentSubscription) {
      this.DCSpaymentSubscription.unsubscribe();
    }
  }


  fetchOWCSContentFromService() {
    this.pageId = this.contentService.getPageId(AppConstants.CLAIM_DETAILS);
    if (this.pageId) {
      this.contentService.getOWCSResponse(this.pageId, AppConstants.CLAIM_DETAILS).subscribe(
        val => {
          if (val) {
            this.owscContent = val;
            if (this.owscContent && this.owscContent.data) {

              if (this.owscContent.data.CD_103_Body) {
                this.claimCLosedMsg = this.owscContent.data.CD_103_Body.value
              }

              this.storageManagementService.setSessionData(SessionConstants.OWCS_CONTENT, this.owscContent.data);
            }
          }
        });
    }
  }

  integrateOWCS() {
    if(this.storageManagementService.getSessionData(SessionConstants.CLAIM_OVERVIEW_OWCS)){
      this.owscContent = this.storageManagementService.getSessionData(SessionConstants.CLAIM_OVERVIEW_OWCS)
      this.populateOWCSContent()
    } else {
      this.pageId = this.contentService.getPageId(SessionConstants.CLAIM_OVERVIEW);
      if (this.pageId) {
        this.contentService.getOWCSResponse(this.pageId, SessionConstants.CLAIM_OVERVIEW_U).subscribe(
          val => {
            if (val) {
              this.storageManagementService.setSessionData(SessionConstants.CLAIM_OVERVIEW_OWCS, val);
              this.owscContent = val;
              this.populateOWCSContent()
            }
          }, (error) => {
            console.error(error);
          });
      }
    }
  }

  populateOWCSContent() {
    if (this.owscContent && this.owscContent.data) {
      if (this.owscContent.data.CO_01_Label) {
        this.section_title = this.owscContent.data.CO_01_Label.value;
      }
      if (this.owscContent.data.CO_58_Label) {
        this.extension_Status_Message = this.owscContent.data.CO_58_Label.value;
      }
      if (this.owscContent.data.CO_60_Label) {
        this.extension_Status_Icon_Text = this.owscContent.data.CO_60_Label.value;
      }
      //Missing Info Labels
      if (this.owscContent.data.CO_47_Body) {
        this.infoLabel = this.owscContent.data.CO_47_Body.value;
      }
      if (this.owscContent.data.CO_48_Body) {
        this.message_1_Label = this.owscContent.data.CO_48_Body.value;
      }
      if (this.owscContent.data.CO_50_Body) {
        this.gotoMissingInfo_Button = this.owscContent.data.CO_50_Body.value;
      }
      if (this.owscContent.data.CO_53_Body) {
        this.header_3 = this.owscContent.data.CO_53_Body.value;
      }
      if (this.owscContent.data.CO_54_Body) {
        this.message_3 = this.owscContent.data.CO_54_Body.value;
      }
      if (this.owscContent.data.CO_51_Body) {
        this.header_2 = this.owscContent.data.CO_51_Body.value;
      }
      if (this.owscContent.data.CO_52_Body) {
        this.message_2 = this.owscContent.data.CO_52_Body.value;
      }
      if (this.owscContent.data.CO_513_Body) {
        this.futurePaymentmsgText = this.owscContent.data.CO_513_Body.value;
      }
      if (this.owscContent.data.CO_513A_Body) {
        this.futurePaymentmsgLink = this.sanitizer.bypassSecurityTrustHtml(this.owscContent.data.CO_513A_Body.value);
      }
      //for confirm disability
      if (this.owscContent.data.CO_55A_Body) {
        this.disability_Message1 = this.owscContent.data.CO_55A_Body.value;
      }
      if (this.owscContent.data.CO_56_Body && this.claim.coverageCategory !== AppConstants.PFL) {
        this.disability_Message = this.owscContent.data.CO_56_Body.value;
      } else if (this.owscContent.data.CO_56B_Body) {
        this.disability_Message = this.owscContent.data.CO_56B_Body.value;
      }
      //for confirm surgery model
      if (this.owscContent.data.CO_55_Body) {
        this.surgery_Message = this.owscContent.data.CO_55_Body.value;
      }
      //for maternity model
      if (this.owscContent && this.owscContent.data && this.owscContent.data.CO_08_Body) {
        this.maternity_Action_Message = this.owscContent.data.CO_08_Body.value;
      }

      //Used this variable to display product label post login
      if (this.owscContent.data.CO_28_Body) {
        this.productService.productLabelPostLogin.next(this.owscContent.data.CO_28_Body.value)
      }
      //Used this variable to display product disclosure post login
      if (this.owscContent.data.CO_67_Body) {
        this.productService.productDisclosurePostLogin.next(this.owscContent.data.CO_67_Body.value)
      }
    }
  }


  //icon two week check
  maternityTwoWeekCheck(claim) {
    let timeDiff = null;
    let claimDate;
    let estimatedDeliveryDate;
    if (claim.estimatedDeliveryDate) {
      estimatedDeliveryDate = claim.estimatedDeliveryDate;
      claimDate = estimatedDeliveryDate.replace(/^(\d{1,2}\/)(\d{1,2}\/)(\d{4})$/, '$2$1$3');
      const currentdate = new Date();
      this.servicedate = new Date(claimDate.split('/')[2], claimDate.split('/')[1] - 1, claimDate.split('/')[0]);
      timeDiff = currentdate.getTime() - this.servicedate.getTime();
    }
    const diffDays = timeDiff / (1000 * 3600 * 24);
    if (diffDays > -14) {
      this.deliveryTwoWeekCheck = true;
    } else {
      this.deliveryTwoWeekCheck = false;
    }
    claim.orangeIconMissingInfo = false;
    claim.showProgressIcon = true;
  }

  confirmSurgerytriggerUpdate(claim) {
    if (claim.estimatedSurgeryDate) {
      const startDate = new Date(); // system date
      const endDate = new Date(claim.estimatedSurgeryDate);
      if (startDate <= endDate) { // check estimate date is greater than system date or not
        const numOfDates = this.confirmSurgerytriggerLogicFilter(startDate, endDate);

        // This function is used to check day diff between system date and estimate surgery date
        if (numOfDates <= 4) { // Check date diff
          claim.orangeIconMissingInfo = true;
          claim.showProgressIcon = false;
        } else {
          claim.orangeIconMissingInfo = false;
          claim.showProgressIcon = true;
        }
      } else {
        claim.orangeIconMissingInfo = true; // show yes no options
        claim.showProgressIcon = false;
      }
    }
  }

  confirmSurgerytriggerLogicFilter(startDate, endDate) {
    let count = 0;
    const curDate = startDate;
    while (curDate <= endDate) {
      const dayOfWeek = curDate.getDay();
      if (!((dayOfWeek === 6) || (dayOfWeek === 0))) {
        count++;
      }
      curDate.setDate(curDate.getDate() + 1);
    }
    return count;
  }

  disabilityIcon(claim) {
    let diffDays = 0;
    if (claim.disabilityDate) {
      this.lastDayWork = claim.sourceSystemId === 'AA' ? claim.lastDayWork : claim.disabilityDate;
      this.lastDayWork = this.lastDayWork.replace(/^(\d{1,2}\/)(\d{1,2}\/)(\d{4})$/, '$2$1$3');
      const currentDate = new Date();
      this.serviceDateDisability = new Date(this.lastDayWork.split('/')[2], this.lastDayWork.split('/')[1] - 1, this.lastDayWork.split('/')[0]);
      const timeDiff = currentDate.getTime() - this.serviceDateDisability.getTime();
      diffDays = timeDiff / (1000 * 3600 * 24);
    }
    if (diffDays >= 0) {
      claim.orangeIconMissingInfo = true;
      claim.showProgressIcon = false;
    } else {
      claim.orangeIconMissingInfo = false;
      claim.showProgressIcon = true;
    }
  }

  // timeline two week changes
  maternityCheckForTimelineDisplay(claim) {
    let timeDiff = null;
    let claimDate;
    let estimatedDeliveryDate;
    if (claim.estimatedDeliveryDate) {
      estimatedDeliveryDate = claim.estimatedDeliveryDate;
      claimDate = estimatedDeliveryDate.replace(/^(\d{1,2}\/)(\d{1,2}\/)(\d{4})$/, '$2$1$3');
      const currentdate = new Date();
      this.servicedate = new Date(claimDate.split('/')[2], claimDate.split('/')[1] - 1, claimDate.split('/')[0]);
      timeDiff = currentdate.getTime() - this.servicedate.getTime();
    }
    const diffDays = timeDiff / (1000 * 3600 * 24);
    if (diffDays > -14) {
      this.deliveryTwoWeekCheck = true;
    } else {
      this.deliveryTwoWeekCheck = false;
    }
  }


  hideProgressIconChange() {

    if (this.claim.asmIndicator === AppConstants.ASMINDICATOR_M) {
      if ((this.claim.claimOnlineStatusCode === AppConstants.COS_AWAITING_DELIVERY_CD_01 && this.claim.claimOnlineSubStatusCode === AppConstants.COSS_AWAITING_DELIVERY_CD_01)
        || (this.claim.claimOnlineStatusCode === AppConstants.COS_UNDER_REVIEW_BY_CLAIM_HANDLER_CD_08 && this.claim.claimOnlineSubStatusCode === AppConstants.COSS_MISSING_INFORMATION_CD_13)
        || (this.claim.claimOnlineStatusCode === AppConstants.COS_CLAIM_CLOSED_CD_05 && this.claim.claimOnlineSubStatusCode === AppConstants.COSS_MISSING_INFORMATION_CD_09)) {
        this.claim.orangeIconMissingInfo = true;
        this.claim.showProgressIcon = false
      } else if ((this.claim.claimOnlineStatusCode === AppConstants.COS_UNDER_REVIEW_BY_CLAIM_HANDLER_CD_08
        && this.claim.claimOnlineSubStatusCode === AppConstants.COSS_IN_ADJUDICATION_CD_12
        && this.claim.babyDeathIndicator === null && this.claim.confirmedDeliveryDate === null)
        || (this.claim.claimOnlineStatusCode === AppConstants.COS_CLAIM_APPROVED_CD_04 && this.claim.babyDeathIndicator === null && this.claim.confirmedDeliveryDate === null)) {
        this.maternityTwoWeekCheck(this.claim);
      } else if ((this.claim.claimOnlineStatusCode === AppConstants.COS_CLAIM_APPROVED_CD_04 && (this.claim.claimOnlineSubStatusCode === AppConstants.COSS_BENEFIT_PAYMENTS_COMPLETE_CD_04 || this.claim.claimOnlineSubStatusCode === AppConstants.COSS_PAYMENTS_IN_PROGRESS_CD_06)
        && this.claim.babyDeathIndicator === null && this.claim.confirmedDeliveryDate !== null)) {
        this.maternityTwoWeekCheck(this.claim);
      } else if ((this.claim.claimOnlineStatusCode === AppConstants.COS_UNDER_REVIEW_BY_CLAIM_HANDLER_CD_08
        && this.claim.claimOnlineSubStatusCode === AppConstants.COSS_IN_ADJUDICATION_CD_12
        && this.claim.babyDeathIndicator !== null && this.claim.confirmedDeliveryDate === null)) {
        this.claim.orangeIconMissingInfo = false;
        this.claim.showProgressIcon = true;
      } else if (this.claim.confirmedDeliveryDate) {
        this.claim.orangeIconMissingInfo = false;
        this.claim.showProgressIcon = true;
      }
    } else if (this.claim.confirmedDeliveryDate) {
      this.claim.orangeIconMissingInfo = false;
      this.claim.showProgressIcon = true;
    }

    if ((this.claim.asmIndicator === AppConstants.ASMINDICATOR_A || this.claim.asmIndicator === AppConstants.ASMINDICATOR_S) && this.claim.estimatedSurgeryDate) {
      if ((this.claim.claimOnlineStatusCode === AppConstants.COS_UNDER_REVIEW_BY_CLAIM_HANDLER_CD_08 && this.claim.claimOnlineSubStatusCode === AppConstants.COSS_MISSING_INFORMATION_CD_13)
        || (this.claim.claimOnlineStatusCode === AppConstants.COS_CLAIM_CLOSED_CD_05 && this.claim.claimOnlineSubStatusCode === AppConstants.COSS_MISSING_INFORMATION_CD_09)) {
        this.claim.orangeIconMissingInfo = true;
        this.claim.showProgressIcon = false
      } else if (((this.claim.claimOnlineStatusCode === AppConstants.COS_AWAITING_SURGERY_DETAILS_CD_03 && this.claim.claimOnlineSubStatusCode === AppConstants.COSS_AWAITING_SURGERY_DETAILS_CD_03)
        || (this.claim.claimOnlineStatusCode === AppConstants.COS_UNDER_REVIEW_BY_CLAIM_HANDLER_CD_08 && this.claim.claimOnlineSubStatusCode === AppConstants.COSS_IN_ADJUDICATION_CD_12)
        || (this.claim.claimOnlineStatusCode === AppConstants.COS_CLAIM_APPROVED_CD_04))
        && this.claim.confirmedSurgeryDate === null) {
        this.confirmSurgerytriggerUpdate(this.claim);
      } else if (this.claim.confirmedSurgeryDate) {
        this.claim.orangeIconMissingInfo = false;
        this.claim.showProgressIcon = true;
      }
    } else if (this.claim.confirmedSurgeryDate) {
      this.claim.orangeIconMissingInfo = false;
      this.claim.showProgressIcon = true;
    }
    /*RULES (claim.asmIndicator === 'p') added for NYPFL claims*/
    if ((this.claim.asmIndicator === AppConstants.ASMINDICATOR_A || this.claim.asmIndicator === AppConstants.ASMINDICATOR_S || this.claim.asmIndicator === AppConstants.ASMINDICATOR_P) && this.claim.estimatedSurgeryDate === null) {
      if ((this.claim.claimOnlineStatusCode === AppConstants.COS_AWAITING_DISABILITY_CD_02 && this.claim.claimOnlineSubStatusCode === AppConstants.COSS_AWAITING_DISABILITY_CD_02)
        || (this.claim.claimOnlineStatusCode === AppConstants.COSS_MISSING_INFORMATION_CD_09 && this.claim.claimOnlineSubStatusCode === AppConstants.COSS_AWAITING_PAID_FAMILY_LEAVE_CD_14)) {
        this.disabilityIcon(this.claim);
      } else if ((this.claim.claimOnlineStatusCode === AppConstants.COS_UNDER_REVIEW_BY_CLAIM_HANDLER_CD_08 && this.claim.claimOnlineSubStatusCode === AppConstants.COSS_MISSING_INFORMATION_CD_13)
        || (this.claim.claimOnlineStatusCode === AppConstants.COS_CLAIM_CLOSED_CD_05 && this.claim.claimOnlineSubStatusCode === AppConstants.COSS_MISSING_INFORMATION_CD_09)
        || (this.claim.claimOnlineStatusCode === AppConstants.COS_AWAITING_DISABILITY_CD_02 && this.claim.claimOnlineSubStatusCode === AppConstants.COSS_AWAITING_DISABILITY_CD_02)
        || (this.claim.claimOnlineStatusCode === AppConstants.COSS_MISSING_INFORMATION_CD_09 && this.claim.claimOnlineSubStatusCode === AppConstants.COSS_AWAITING_PAID_FAMILY_LEAVE_CD_14)) {
        this.claim.orangeIconMissingInfo = true;
        this.claim.showProgressIcon = false;
      } else {
        this.claim.orangeIconMissingInfo = false;
        this.claim.showProgressIcon = true;
      }
    }

    //Commented since rules for NYPFL is yet to be finalized.
    /*RULES (claim.asmIndicator === 'p') added for NYPFL claims start*/
    /*if (claim.asmIndicator === 'P'  && claim.estimatedSurgeryDate === null) {
      this.disabilityIcon(claim);
    } else if (claim.asmIndicator === 'P'  && claim.estimatedSurgeryDate === null) {
      claim.orangeIconMissingInfo = true;
      claim.showProgressIcon = false;
    } else {
      claim.orangeIconMissingInfo = false;
      claim.showProgressIcon = true;
    }*/
    /*RULES (claim.asmIndicator === 'p') added for NYPFL claims end*/


    this.claim.LTDmissingInfo = false;
    if (this.claim.claimOnlineStatus && this.claim.claimOnlineStatus.toLowerCase() === AppConstants.COS_CLAIM_CLOSED && this.claim.claimOnlineSubStatus && this.claim.claimOnlineSubStatus.toLowerCase() === AppConstants.COSS_CLAIM_CLOSED) {
      this.claim.showProgressIcon = false;
    }
    if (this.claim.claimOnlineStatusCode === AppConstants.COSS_PAYMENTS_NOT_YET_STARTED_CD_07 && this.claim.claimOnlineSubStatusCode === AppConstants.COSS_CLAIM_RECEIVED_CD_11) {
      this.claim.showProgressIcon = true;
      this.claim.orangeIconMissingInfo = false;
    }

    if ((this.claim.claimOnlineStatus && this.claim.claimOnlineStatus.toLowerCase() === AppConstants.COS_CLAIM_CLOSED && this.claim.claimOnlineSubStatus && this.claim.claimOnlineSubStatus.toLowerCase() === AppConstants.COSS_MISSING_INFORMATION_09)) {
      this.claim.orangeIconMissingInfo = true;
      this.claim.showProgressIcon = false;
      this.claim.missingInfoclaim = true;
    }
    if ((this.claim.coverageCategory && this.claim.coverageCategory.toUpperCase() === AppConstants.LTD) && this.claim.claimStatus && this.claim.claimStatus.toLowerCase() === AppConstants.CS_PENDING && this.claim.missingInfo != null) {
      this.claim.LTDmissingInfo = true;
    }
    if (this.claim.sourceSystemId === 'AA' && (this.claim.missingInfo && this.claim.missingInfo.length)) {
      this.claim.orangeIconMissingInfo = true;
      this.claim.showProgressIcon = false;
    }
    if (this.claim.coverageCategory.toUpperCase() === AppConstants.LTD && this.claim.missingInfo && this.claim.missingInfo.length > 0) {
      this.claim.orangeIconMissingInfo = true;
    }
  }


  /*to continue missing information flow*/
  navigateTo(location, curClaim, claimEventId, authorizedEndDate, claimOfficeId) {
    this.storageManagementService.setSessionObject(SessionConstants.CUR_CLAIM, curClaim);
    this.storageManagementService.setSessionObject(SessionConstants.CLAIM_EVENT_ID, claimEventId);
    this.storageManagementService.setSessionObject(SessionConstants.AUTHORIZED_END_DATE, authorizedEndDate);
    this.storageManagementService.setSessionObject(SessionConstants.CLAIM_OFFICE_ID, claimOfficeId);
    this.storageManagementService.removeSessionObject(SessionConstants.FUTURE_PAYMENTS);
    sessionStorage.removeItem(SessionConstants.UI_RULE_LABEL);
    sessionStorage.removeItem(SessionConstants.UI_RULE_DATE);
    sessionStorage.removeItem(SessionConstants.CLAIM_HISTORY_PAGE);
    sessionStorage.removeItem(SessionConstants.MISSING_INF_DIV);
    //this method is called from date label component using viewchild event in angular 4.3
    this.DateLabelValue.claimEventId(claimEventId);
    this.router.navigate(['/' + location]);
  }

  removeAfterFiveDays(claim: any): boolean {
    this.authorizedEndDate = claim.authorizedEndDate;
    if (this.authorizedEndDate) {
      const startDate = new Date(this.authorizedEndDate); // system date
      const endDate = new Date();
      if (startDate < endDate) { // check estimate date is greater than system date or not
        const numOfDates = this.afterFiveDaysFilter(startDate, endDate);
        // This function is used to check day diff between system date and estimate surgery date
        if (numOfDates <= 5) { // Check date diff
          return true;
        }
      }
    }
  }

  afterFiveDaysFilter(startDate, endDate) {
    let count = 0;
    const curDate = startDate;
    while (curDate <= endDate) {
      const dayOfWeek = curDate.getDay();
      if (!((dayOfWeek === 6) || (dayOfWeek === 0))) {
        count++;
      }
      curDate.setDate(curDate.getDate() + 1);
    }
    return count;
  }

  /*
  unused function, look at home.ts for an actual implementation
    isPromptIndicatorDisplay() {
      if (this.userClaims) {
        for (const claims of this.userClaims) {
          for (const claim of claims) {
            if (claim.coverageCategory === 'WD' || claim.coverageCategory === 'LTD') {
              this.isPromptDisplay = true;
            }
          }
        }
      }
    }
  */
  surgeryClaimCheck(claim: any): boolean {
    if (claim) {
      if ((claim.asmIndicator === AppConstants.ASMINDICATOR_S || claim.asmIndicator === AppConstants.ASMINDICATOR_A) && claim.estimatedSurgeryDate !== null) {
        if (claim.claimOnlineStatusCode === AppConstants.COS_AWAITING_SURGERY_DETAILS_CD_03) {
          return (this.surgeryClaimDisplay = true);
        } else if (claim.claimOnlineStatus && claim.claimOnlineStatus.toLowerCase() === AppConstants.COS_UNDER_REVIEW_BY_CLAIM_HANDLER && claim.claimOnlineSubStatus && claim.claimOnlineSubStatus.toLowerCase() === AppConstants.COSS_MISSING_INFORMATION_09) {
          if (claim.confirmedSurgeryDate === null && claim.confirmedLastDayWorkDate) {
            return (this.surgeryClaimDisplay = true);
          } else {
            return (this.surgeryClaimDisplay = false);
          }

        } else if (claim.claimOnlineStatus && claim.claimOnlineStatus.toLowerCase() === AppConstants.COS_UNDER_REVIEW_BY_CLAIM_HANDLER && claim.claimOnlineSubStatus && claim.claimOnlineSubStatus.toLowerCase() === AppConstants.COSS_IN_ADJUDICATION
          && claim.confirmedLastDayWorkDate && !claim.confirmedSurgeryDate) {
          return (this.surgeryClaimDisplay = true);
        } else if (claim.claimOnlineStatus && claim.claimOnlineStatus.toLowerCase() === AppConstants.COS_CLAIM_APPROVED
          && claim.confirmedLastDayWorkDate && !claim.confirmedSurgeryDate) {
          return (this.surgeryClaimDisplay = true);
        } else if (claim.claimOnlineStatus && claim.claimOnlineStatus.toLowerCase() === AppConstants.COS_CLAIM_CLOSED && claim.claimOnlineSubStatus && claim.claimOnlineSubStatus.toLowerCase() === AppConstants.COSS_CLAIM_CLOSED
          && claim.confirmedLastDayWorkDate && !claim.confirmedSurgeryDate) {
          return (this.surgeryClaimDisplay = true);
        } else if (claim.claimOnlineStatusCode === AppConstants.COS_CLAIM_CLOSED_CD_05 && claim.claimOnlineSubStatusCode === AppConstants.COSS_MISSING_INFORMATION_CD_09
          && claim.confirmedLastDayWorkDate && !claim.confirmedSurgeryDate) {
          return (this.surgeryClaimDisplay = true);
        }
      }
    }
  }

  maternityClaimCheck(claim: any): boolean {
    if (claim) {
      this.maternityCheckForTimelineDisplay(claim);
      if (claim.asmIndicator === AppConstants.ASMINDICATOR_M && claim.confirmedDeliveryDate === null && claim.babyDeathIndicator === null) {
        if (claim.claimOnlineStatus && claim.claimOnlineStatus.toLowerCase() === AppConstants.COS_AWAITING_DELIVERY || claim.claimOnlineStatus && claim.claimOnlineStatus.toLowerCase() === AppConstants.COS_AWAITING_DELIVERY) {

          return (this.maternityClaimDisplay = true);
        } else if (claim.claimOnlineStatus && claim.claimOnlineStatus.toLowerCase() === AppConstants.COS_UNDER_REVIEW_BY_CLAIM_HANDLER && claim.claimOnlineSubStatus && claim.claimOnlineSubStatus.toLowerCase() === AppConstants.COSS_MISSING_INFORMATION_09
          && claim.confirmedLastDayWorkDate && !claim.confirmedDeliveryDate && !claim.babyDeathIndicator && this.deliveryTwoWeekCheck === true) {
          return (this.maternityClaimDisplay = true);
        } else if (claim.claimOnlineStatus && claim.claimOnlineStatus.toLowerCase() === AppConstants.COS_UNDER_REVIEW_BY_CLAIM_HANDLER && claim.claimOnlineSubStatus && claim.claimOnlineSubStatus.toLowerCase() === AppConstants.COSS_IN_ADJUDICATION
          && claim.confirmedLastDayWorkDate && !claim.confirmedDeliveryDate && !claim.babyDeathIndicator && this.deliveryTwoWeekCheck === true) {
          return (this.maternityClaimDisplay = true);
        } else if (claim.claimOnlineStatus && claim.claimOnlineStatus.toLowerCase() === AppConstants.COS_CLAIM_APPROVED
          && claim.confirmedLastDayWorkDate && !claim.confirmedDeliveryDate && !claim.babyDeathIndicator && this.deliveryTwoWeekCheck === true) {
          return (this.maternityClaimDisplay = true);
        } else if (claim.claimOnlineStatus && claim.claimOnlineStatus.toLowerCase() === AppConstants.COS_CLAIM_CLOSED && claim.claimOnlineSubStatus && claim.claimOnlineSubStatus.toLowerCase() === AppConstants.COSS_CLAIM_CLOSED
          && claim.confirmedLastDayWorkDate && !claim.confirmedDeliveryDate && !claim.babyDeathIndicator && this.deliveryTwoWeekCheck === true) {
          /*if(!claim.babyDeathIndicator){
           return (this.maternityClaimDisplay = true);
           }*/
          return (this.maternityClaimDisplay = true);
        } else if (claim.claimOnlineStatusCode === AppConstants.COS_CLAIM_CLOSED_CD_05 && claim.claimOnlineSubStatusCode === AppConstants.COSS_MISSING_INFORMATION_CD_09
          && claim.confirmedLastDayWorkDate && !claim.confirmedDeliveryDate && !claim.babyDeathIndicator) {
          return (this.maternityClaimDisplay = true);
        }
      }
    }
  }


  missingInfThaaCheck(claim: any): boolean {
    if ((claim.claimOnlineStatusCode === AppConstants.COS_CLAIM_CLOSED_CD_05 && claim.claimOnlineSubStatusCode === AppConstants.COSS_CLAIM_CLOSED_CD_08) || (claim.claimOnlineStatusCode === AppConstants.COS_CLAIM_DENIED_CD_06)) {
      return this.missingInfoClaimThaa = true;
    }
  }
  addSeparationLine(claim) {
    let addLine = false;
    addLine = this.surgeryClaimCheck(claim) || this.maternityClaimCheck(claim) || claim.claimOnlineStatusCode === this.appConstant.COS_AWAITING_DISABILITY_CD_02 || this.claim.claimOnlineStatusCode === this.appConstant.COSS_MISSING_INFORMATION_CD_09;
    return addLine;
  }
}
