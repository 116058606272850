import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {PaymentsHistoryService} from '../../../../shared/services/claim-payment-history.service';
import {StorageManagementService} from '../../../../shared/services/storage-management.service';
import {RelatedClaims} from '../../../../shared/models/relatedClaims.model';
import {
  ClaimPaymentInfo,
  ClaimPaymentRequest,
  ClaimPaymentResponse,
  ItotalPayments,
  Payment
} from '../../../../shared/models/Payment.model';
import {Router} from '@angular/router';
import {AAClaimPaymentResponse} from '../../../../shared/models/aetnaPayments.model';
import {AetnaPaymentsService} from '../../../../shared/services/aetnaPayments.service';
import {AppConstants} from '../../../../shared/constants/app.constants';
import {SessionConstants} from '../../../../shared/constants/session.constants';
import {AppLabelConstants} from '../../../../shared/constants/appLabel.constants';

@Component({
  selector: 'next-payment-block',
  templateUrl: 'next-payment-block.component.html',
  styleUrls: ['next-payment-block.component.scss']
})
export class NextPaymentBlockComponent implements OnInit, OnDestroy {

  @Input() curClaim: RelatedClaims;
  claimantDetails;
  insuredId: string;
  claimEventId: string;
  ifPaymentSectionToBeShown = false;
  claimPaymentRequest: ClaimPaymentRequest = new ClaimPaymentRequest();
  claimPaymentHistoryResponse: ClaimPaymentResponse = new ClaimPaymentResponse();
  claimPaymentInfo: ClaimPaymentInfo;
  payments: Payment[] = [];
  sourceSystemId: string;
  aaClaimPaymentResponse: AAClaimPaymentResponse = new AAClaimPaymentResponse();
  aaPaymentSubscription;
  public appConstants = AppConstants;
  public appLabelConstants = AppLabelConstants;
  public currentRoute;

  constructor(
              private paymentHistoryService: PaymentsHistoryService,
              private aetnaPaymentsService: AetnaPaymentsService,
              private storageManagementService: StorageManagementService,
              private router: Router) {
    this.currentRoute = this.router.url;
  }

  ngOnInit() {
    this.claimantDetails = this.storageManagementService.getAppData(SessionConstants.USER_DATA);
    this.insuredId = this.claimantDetails.srcSysId;
    this.claimEventId = this.curClaim.claimEventId;
    this.sourceSystemId = this.curClaim.sourceSystemId;
    this.claimPaymentInfo = this.paymentHistoryService.getClaimPaymentInfo(this.claimEventId);
    if (this.claimPaymentInfo && this.claimPaymentInfo.payments && this.claimPaymentInfo.payments.length >= 0 ) {
      this.subscribeToClaimPaymentInfo();
    } else {
      setTimeout(() => {
        this.subscribeToClaimPaymentInfo();
      }, 3000)
    }
  }

  callGetPaymentHistory() {
    this.claimPaymentRequest.insuredId = this.insuredId;
    this.claimPaymentRequest.claimEventId = this.claimEventId;

    this.paymentHistoryService.getClaimPaymentHistory(this.claimPaymentRequest).subscribe(
      val => {
        this.claimPaymentHistoryResponse = val;
        this.storageManagementService.setAppData(SessionConstants.CLAIM_PAYMENTS , val);
        this.payments = this.claimPaymentHistoryResponse.claimPaymentInfo;
        this.paymentHistoryService.setClaimPaymentInfo(this.claimEventId, this.payments);
        const claimType = this.paymentHistoryService.dataExists(this.curClaim.claimType);
        const claimSubType = this.paymentHistoryService.dataExists(this.curClaim.claimSubType);
        this.payments.forEach(payment => {
          this.paymentHistoryService.paymentsSortedByIssueDate(
            this.claimEventId, claimType, claimSubType, AppConstants.SOURCE_SYS_ID_DCS, '', payment, null, this.claimPaymentRequest.insuredId
          );
        });
        this.paymentHistoryService.setClaimPaymentDetails(this.claimEventId, this.claimPaymentHistoryResponse, AppConstants.SOURCE_SYS_ID_DCS);
        const totalPaymentsData = this.storageManagementService.getSessionData(AppLabelConstants.PAYMENTS_LIST_DCS);
        let totalPayementClaimResponseArr = [];
        if (totalPaymentsData) {
          totalPayementClaimResponseArr = totalPaymentsData
        } else {
          totalPayementClaimResponseArr = [];
        }
        const totalPayementClaimResponse: ItotalPayments = {
          claimEventId: this.claimEventId,
          totalPaymentResponseData: this.claimPaymentHistoryResponse
        }
        if (!totalPayementClaimResponseArr.some(element => element.claimEventId === this.claimEventId)) {
          totalPayementClaimResponseArr.push(totalPayementClaimResponse)
        }
        this.storageManagementService.setSessionData(AppLabelConstants.PAYMENTS_LIST_DCS, totalPayementClaimResponseArr);
      }, (error) => {
        const totalPaymentsData = this.storageManagementService.getSessionData(AppLabelConstants.PAYMENTS_LIST_DCS);
        let totalPayementClaimResponseArr = [];
        if (totalPaymentsData) {
          totalPayementClaimResponseArr = totalPaymentsData
        } else {
          totalPayementClaimResponseArr = [];
        }
        const totalPayementClaimResponse: ItotalPayments = {
          claimEventId: this.claimEventId,
          totalPaymentResponseData: error
        }
        if (!totalPayementClaimResponseArr.some(element => element.claimEventId === this.claimEventId)) {
        totalPayementClaimResponseArr.push(totalPayementClaimResponse)
        }
        this.storageManagementService.setSessionData(AppLabelConstants.PAYMENTS_LIST_DCS, totalPayementClaimResponseArr);
      }
    );
  }
  callGetAetnaPaymentHistory() {
    this.aaPaymentSubscription = this.aetnaPaymentsService.getAetnaDisabilityClaimPayments(this.curClaim.personId, this.curClaim.claimEventId).subscribe(
      val => {
        if (val) {
          this.aaClaimPaymentResponse = val;
          this.payments = [];
          if (this.aaClaimPaymentResponse && this.aaClaimPaymentResponse.paymentList && this.aaClaimPaymentResponse.paymentList.length > 0) {
            const paymentsResponse = this.aaClaimPaymentResponse;
            const claimType = this.paymentHistoryService.dataExists(this.curClaim.claimType);
            const claimSubType = this.paymentHistoryService.dataExists(this.curClaim.claimSubType);
            const transformedPayments = this.paymentHistoryService.transformPayments(
              paymentsResponse, this.curClaim.claimEventId, this.curClaim.personId, claimType, claimSubType
            );
            this.payments = transformedPayments;
            this.storageManagementService.setSessionData(SessionConstants.DISPLAY_PAY_STUB, this.aaClaimPaymentResponse.displayPaystub);
          }
          this.storageManagementService.setSessionData(SessionConstants.AA_DISABILITY_CLAIM_PAYMENT_RESPONSE, this.aaClaimPaymentResponse);
          this.paymentHistoryService.setClaimPaymentInfo(this.claimEventId, this.payments);
        }
      }
    );
  }
  subscribeToClaimPaymentInfo() {
    this.paymentHistoryService.isClaimantPaymentInfoSetInSession.subscribe(
      (ifClaimantPaymentInfoSet: boolean) => {
        if (ifClaimantPaymentInfoSet) {
          this.claimPaymentInfo = this.paymentHistoryService.getClaimPaymentInfo(this.claimEventId);
          if (this.claimPaymentInfo && this.claimPaymentInfo.isClaimFullyInsured && this.claimPaymentInfo.payments && this.claimPaymentInfo.payments.length === 0) {
            this.ifPaymentSectionToBeShown = this.claimPaymentInfo.isClaimFullyInsured;
            if (this.sourceSystemId === AppConstants.SOURCE_SYS_ID_DCS) {
              this.callGetPaymentHistory();
            } else if (this.sourceSystemId === AppConstants.SOURCE_SYS_ID_AA) {
              this.callGetAetnaPaymentHistory();
            }
          } else if (this.claimPaymentInfo && this.claimPaymentInfo.isClaimFullyInsured && this.claimPaymentInfo.payments && this.claimPaymentInfo.payments.length > 0) {
            this.ifPaymentSectionToBeShown = this.claimPaymentInfo.isClaimFullyInsured;
            this.payments = this.claimPaymentInfo.payments;
          }
        }
      }
    );
  }
  ngOnDestroy() {
    if (this.aaPaymentSubscription) {
      this.aaPaymentSubscription.unsubscribe();
    }
  }
}
