import {GbBase} from './gb-base';

export class GbClaims extends GbBase {

  getUrls() {
    return {
      getClaimPaymentsHistory:          this.getBaseUrl() + 'gb-claims-service/claims/v1/claimPayments?'            + this.getClientId('auth'),
      getSinglePaymentDetails:          this.getBaseUrl() + 'gb-claims-service/claims/v1/claimPaymentDetails?'      + this.getClientId('auth'),
      getPayeeDetails:                  this.getBaseUrl() + 'gb-claims-service/claims/v1/payeeDetails?'             + this.getClientId('auth'),
      getPayeeType:                     this.getBaseUrl() + 'gb-claims-service/claims/v1/payeeType?'                + this.getClientId('auth'),
      deleteBankingInfoURL:             this.getBaseUrl() + 'gb-claims-service/claims/v1/DeleteBankingInformation?' + this.getClientId('auth'),
      addBankingInformation:            this.getBaseUrl() + 'gb-claims-service/claims/v1/AddBankingInformation?'    + this.getClientId('auth'),
      getClaimHandlerInfo:              this.getBaseUrl() + 'gb-claims-service/claims/v1/claimHandlers?'            + this.getClientId('auth'),
      getDoesInsuredHaveClaimsURL:      this.getBaseUrl() + 'gb-claims-service/claims/v1/DoesInsuredHaveClaims?'    + this.getClientId('auth'),
      getBankingInformationURL:         this.getBaseUrl() + 'gb-claims-service/claims/v1/GetBankingInformation?'    + this.getClientId('auth'),
      modifyBankingInformationURL:      this.getBaseUrl() + 'gb-claims-service/claims/v1/ModifyBankingInformation?' + this.getClientId('auth'),
      requestExtension :                this.getBaseUrl() + 'gb-claims-service/claims/v1/approvedThoughDate?'       + this.getClientId('auth'),
      getIsNotificationURL:             this.getBaseUrl() + 'gb-claims-service/claims/v1/IsNotification?'           + this.getClientId('auth'),
      getAddressURL :                   this.getBaseUrl() + 'gb-claims-service/claims/v1/GetAddress?'               + this.getClientId('auth') + '&insuredId=',
      updateAddressURL :                this.getBaseUrl() + 'gb-claims-service/claims/v1/UpdateAddress?'            + this.getClientId('auth'),
      getCountryURL :                   this.getBaseUrl() + 'gb-claims-service/claims/v1/country?'                  + this.getClientId('auth'),
      getProvinceURL :                  this.getBaseUrl() + 'gb-claims-service/claims/v1/province?'                 + this.getClientId('auth'),
      getGetClaimOfficePhoneNumbersURL: this.getBaseUrl() + 'gb-claims-service/claims/v1/claimOfficePhoneNumbers?'  + this.getClientId('auth'),

      //Missing Ifor Recenly Resolved
      recentlyResolvedURL: this.getBaseUrl() + 'gb-claims-service/claims/v1/recentlyResolvedItem?' + this.getClientId('auth'),

      updateClaimActivityURL : this.getBaseUrl() + 'gb-claims-service/claims/v1/UpdateClaimActivity?' + this.getClientId('auth'),
      getclaimActivityDetailURL : this.getBaseUrl() + 'gb-claims-service/claims/v1/claimActivityDetail?' + this.getClientId('auth'),

      //Concurrent Leave
      getConcurrentLeavesURL: this.getBaseUrl() + 'gb-claims-service/claims/v1/leaves?' + this.getClientId('auth'),
      getLeaveSSOURL: this.getBaseUrl() + 'gb-claims-service/claims/v1/leaveSsoUrl?' + this.getClientId('auth'),

      claimsURL: 'http://int-api.thehartford.com/gb-claims-service/claims/v1/' + this.getClientId('auth'),
      claimsActivity: 'GetClaimActivity?' + this.getClientId('auth'),
      updateReceivedDate : this.getBaseUrl() + 'gb-claims-service/claims/v1/receivedDate?' + this.getClientId('auth'),
       getClaims: this.getBaseUrl() + 'gb-orch-service/claims/v1/getClaims?' + this.getClientId('auth'),
    };
  }
}
