
import { catchError, map } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { Observable, of } from '../../../../node_modules/rxjs';
import { ResponseErrorHandlerService } from './response-error-handler.service';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { AppLabelConstants } from '../constants/appLabel.constants';
import { env } from '../../../environments/environment-loader';
import { StorageManagementService } from '@shared/services/storage-management.service';
import { SessionConstants } from '../constants/session.constants';
import { IFuturePayementClaimResponse } from '../models/futurePayments.model';

@Injectable()
export class FuturePaymentServiceService {

  constructor(private errorHandler: ResponseErrorHandlerService,
    private storeManagementService: StorageManagementService,
    private httpClient: HttpClient) { }

  getFuturePayments(personId: string, claimID: string): Observable<any> {
    const futurePaymentData = this.storeManagementService.getSessionData(SessionConstants.CLAIM_FUTURE_PAYMENT_DATA);
    let futurePaymentArray = [];
    if (futurePaymentData && futurePaymentData.length > 0) {
      futurePaymentArray = futurePaymentData.filter(data => data.claimId === claimID);
    }
    if (futurePaymentArray && futurePaymentArray.length > 0) {
      if (futurePaymentArray[0].claimId === claimID) {
        return of(futurePaymentArray[0].futurePaymentResponseData);
      }
    } else {
      return this.httpClient.get(env.thaaWKABPortalService.getFuturePaymentURL + '&personid=' + personId + '&claimId=' + claimID,
        {
          headers: new HttpHeaders()
            .set(AppLabelConstants.CONTENT_TYPE_KEY, AppLabelConstants.APPLICATION_KEY)
        }).pipe(
          map(
            (response: Response) => {
              if (!this.errorHandler.isServiceResponseHasError(response)) {
                const futurePaymentGetData = this.storeManagementService.getSessionData(SessionConstants.CLAIM_FUTURE_PAYMENT_DATA);
                let futurePayementClaimResponseArr = [];
                if (futurePaymentGetData) {
                  futurePayementClaimResponseArr = futurePaymentGetData
                } else {
                  futurePayementClaimResponseArr = [];
                }
                const futurePayementClaimResponse: IFuturePayementClaimResponse = {
                  claimId: claimID,
                  futurePaymentResponseData: response
                };
                futurePayementClaimResponseArr.push(futurePayementClaimResponse);
                this.storeManagementService.setSessionData(SessionConstants.CLAIM_FUTURE_PAYMENT_DATA, futurePayementClaimResponseArr);
                return response;
              } else {
                throw response;
              }
            }
          ), catchError((error: any) => this.errorHandler.handleError(error)));
    }
  }
}
