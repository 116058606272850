import { Observable, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { ResponseErrorHandlerService } from '@shared/services/response-error-handler.service';
import { env } from '@env/environment-loader';
import { StorageManagementService } from '@shared/services/storage-management.service';
import { Router } from '@angular/router';
import { IPrefillDetails } from '../models/work-schedule-details.model';


@Injectable({
    providedIn: 'root'
})
export class PrefillLogicService {
    selectedData: Map<string, any> = new Map<string, any>();
    constructor(private errorHandler: ResponseErrorHandlerService,
        private httpClient: HttpClient,
        private storageManagementService: StorageManagementService,
        private router: Router) {
    }

    saveData(pageName: string, selectedObj: IPrefillDetails): void {
        this.selectedData.set(pageName, selectedObj)
    }
    getData(pageName: string) {
        return this.selectedData.get(pageName)
    }

    resetData(): void {
        this.selectedData.clear()
    }
}
