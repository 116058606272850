import {Component, OnInit} from '@angular/core';
import {Router} from '@angular/router';
import {AppConstants} from '../../shared/constants/app.constants';
import {SessionConstants} from '@shared/constants/session.constants';
import {User} from '@shared/models/user.model';
import {StorageManagementService} from '@shared/services/storage-management.service';
import {ClaimServiceService} from '@shared/services/claim-service.service';

@Component({
  selector: 'app-leave-form-library-widget',
  templateUrl: './leave-forms-library-widget.component.html',
  styleUrls: ['./leave-forms-library-widget.component.scss']
})
export class FormLibraryWidgetComponent implements OnInit {

  public formLibraryLabel = AppConstants.FORM_LIBRARY;
  public seeMoreforms = AppConstants.SEE_FORMS;
  public formLibrarypara = AppConstants.LEAVE_FORM_MSG;
  public currentUser: User;
  public appData: any = {};
  public toggleInfo = false;
  constructor(private router: Router, private storageManagementService: StorageManagementService, private claimService: ClaimServiceService) { }

  ngOnInit() {
  }

  routeLeaveForm() {
    this.appData = JSON.parse(this.storageManagementService.retrieveSessionObject(SessionConstants.APP_DATA)).appData;
    this.toggleInfo = this.storageManagementService.getAppData(SessionConstants.PRODUCT_FORM_TOGGLE);
    this.currentUser = this.appData.userData;
    if (this.toggleInfo) {
      this.claimService.getLibraryForms(this.currentUser).subscribe(res => {
        if (res) {
          this.storageManagementService.setAppData(SessionConstants.PRODUCT_FORMS_LIST, res['formsList']);
          this.router.navigateByUrl(AppConstants.PRODUCT_FORM_URL);
        }
      });

    } else {
      this.router.navigateByUrl(AppConstants.LEAVE_FORM_URL);
    }
  }
}
