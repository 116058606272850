import {GbBase} from './gb-base';

export class GbPolicy extends GbBase {

  getUrls() {
    return {
      getPolicyAdminAsoClaimServiceURLFirst:  this.getBaseUrl() + 'gb-policy-service/policy/v1/caseInfo/asoClaimService?caseId=',
      getPolicyAdminLeaveServiceURLFirst :  this.getBaseUrl() + 'gb-policy-service/policy/v1/caseInfo/leaveService?' + this.getClientId('auth'),
      getPolicyAdminCoveragePlanURLFirst :  this.getBaseUrl() + 'gb-policy-service/policy/v1/caseInfo/coveragePlans?' + this.getClientId('auth'),


      getPolicyAdminProvisionsURLFirst :  this.getBaseUrl() + 'gb-policy-service/policy/v1/caseInfo/provisions?' + this.getClientId('auth') + '&caseId=',
      getPolicyAdminProvisionsURLLSecond :  '&provisionId=',
      getPolicyAdminOptionsURLFirst: this.getBaseUrl() + 'gb-policy-service/policy/v1/caseInfo/options?' + this.getClientId('auth') + '&caseId=',
      getPolicyAdminOptionsURLLSecond :  '&provisionId=',
      claimWizardOptionsURL: `${this.getBaseUrl()}gb-policy-service/policy/v1/caseInfo/claimWizard?${this.getClientId('auth')}&`
    }
  }
}

