import {AfterViewInit, Component, OnInit} from '@angular/core';
import {ContentService} from '../../../shared/services/content.service';
import {StorageManagementService} from '../../../shared/services/storage-management.service';
import {LeaveDynatraceAction} from '../../../shared/utils/leaveDynatraceAction';
import {AppLabelConstants} from '../../../shared/constants/appLabel.constants';
import {AppConstants} from '../../../shared/constants/app.constants';

@Component({
  selector: 'app-site-wide-tcs',
  templateUrl: 'site-wide-tcs.component.html',
  styleUrls: ['site-wide-tcs.component.scss']
})
export class SiteWideTCsComponent extends LeaveDynatraceAction implements OnInit, AfterViewInit {

  pageLabel = AppLabelConstants.IMP_PLEASE_READ_AGREEMENT_CAREFULLY;
  close = AppLabelConstants.CLOSE;
  print = AppLabelConstants.PRINT;
  //siteWideParas: string[] = [];
  private pageId: string;
  private owscContent;
  public message = '';

  constructor(private contentService: ContentService,
              private storageManagementService: StorageManagementService) {
    super(storageManagementService);
    //this.siteWideParas = termsAndConditionsService.getContent().siteWideParas;
  }

  ngOnInit() {
    //OWCS service Integration
    this.pageId = this.contentService.getPageId(AppConstants.SITE_WIDE_TNC);
    if (this.pageId) {
      this.contentService.getOWCSResponse(this.pageId, AppConstants.SITE_WIDE_TNC_U).subscribe(
        val => {
          if (val) {
            this.owscContent = val;
            if (this.owscContent && this.owscContent.data) {
              if (this.owscContent.data.SWT_C_01_Body) {
                this.message = this.owscContent.data.SWT_C_01_Body.value;
              }
            }
          }
        });
    }
  }

  ngAfterViewInit() {
    super.ngAfterViewInit();
  }
  /*clickPrint()
  {
    window.print();
  }*/

}
