/**
 * Created by AC12410 on 10/12/2017.
 */

import {bodyText} from './bodyText.model';

export class Security {
  status: any;
  data: {
    id: string,
    name: string,
    bodytext: bodyText[],
  }

}
