import {IntermittentAbsenceResponse} from '../shared/models/intermittentAbsences.model';

export const MOCK_ABSENCE_LIST_OVERVIEW: IntermittentAbsenceResponse = {
  leaveList: [{
    leaveId: '1608776',
    absenceList: [
      {
        dateOfAbsence: '05/05/2020',
        creationDate: '05/05/2020',
        creationTime: '9:10:06 PM',
        intervalHours: 8,
        intervalMins: 20,
        leaveType: 'reduced schedule',
        reason: 'Flare Up',
        status: 'Approved',
        span: 'Full Requested Day',
        denialReason: '',
        workScheduleStart: '9:00 AM',
        workScheduleEnd: '5:00 PM',
        eligibilityStatus: 'Eligible',
        isOutOfWorkSchedule: true,
        requestId: '123456',
        overAllStatus: 'Approved',
        checkingInProgress: false,
        isEligible: true,
        startTime: '',
        regulationTypeName: 'Federal Family and Medical Leave Act (FMLA)'
      }, {
        dateOfAbsence: '05/05/2020',
        creationDate: '05/05/2020',
        creationTime: '9:10:06 PM',
        intervalHours: 8,
        intervalMins: 10,
        leaveType: 'Intermittent',
        reason: 'Flare Up',
        status: 'pended',
        span: 'Full Requested Day',
        denialReason: '',
        workScheduleStart: '9:00 AM',
        workScheduleEnd: '5:00 PM',
        eligibilityStatus: 'Eligible',
        isOutOfWorkSchedule: true,
        requestId: '123456',
        overAllStatus: 'Approved',
        checkingInProgress: false,
        isEligible: true,
        startTime: '',
        regulationTypeName: 'Federal Family and Medical Leave Act (FMLA)'
      }, {
        dateOfAbsence: '05/05/2020',
        creationDate: '05/05/2020',
        creationTime: '6:33:48 AM',
        intervalHours: 5,
        intervalMins: 0,
        leaveType: 'Intermittent',
        reason: 'Flare Up',
        status: 'PENDED',
        span: 'Partial Requested Day',
        denialReason: '',
        workScheduleStart: '9:00 AM',
        workScheduleEnd: '5:00 PM',
        eligibilityStatus: 'Eligible',
        isOutOfWorkSchedule: false,
        requestId: '123458',
        overAllStatus: 'Pended',
        checkingInProgress: false,
        isEligible: true,
        startTime: '09:00 AM',
        regulationTypeName: 'Federal Family and Medical Leave Act (FMLA)'
      }, {
        dateOfAbsence: '05/05/2020',
        creationDate: '05/05/2020',
        creationTime: '9:10:08 PM',
        intervalHours: 4,
        intervalMins: 0,
        leaveType: 'Intermittent',
        reason: 'Flare Up',
        status: 'Approved',
        span: 'Partial Requested Day',
        denialReason: '',
        workScheduleStart: '9:00 AM',
        workScheduleEnd: '5:00 PM',
        eligibilityStatus: 'Eligible',
        isOutOfWorkSchedule: true,
        requestId: '123456',
        overAllStatus: 'Approved',
        checkingInProgress: false,
        isEligible: false,
        startTime: '09:00 AM',
        regulationTypeName: 'Federal Family and Medical Leave Act (FMLA)'
      }, {
        dateOfAbsence: '05/05/2020',
        creationDate: '5/2/2020',
        creationTime: '9:10:08 PM',
        intervalHours: 2,
        intervalMins: 0,
        leaveType: 'Intermittent',
        reason: 'Flare Up',
        status: 'pended',
        span: 'Partial Requested Day',
        denialReason: '',
        workScheduleStart: '9:00 AM',
        workScheduleEnd: '5:00 PM',
        eligibilityStatus: 'Not Eligible',
        isOutOfWorkSchedule: false,
        requestId: '123956',
        overAllStatus: 'pended',
        checkingInProgress: true,
        isEligible: false,
        startTime: '09:00 AM',
        regulationTypeName: 'Federal Family and Medical Leave Act (FMLA)'
      }, {
        dateOfAbsence: '05/05/2020',
        creationDate: '05/05/2020',
        creationTime: '9:10:08 PM',
        intervalHours: 2,
        intervalMins: 0,
        leaveType: 'Intermittent',
        reason: 'leave plan is exhausted',
        status: 'denied',
        span: 'Partial Requested Day',
        denialReason: 'leave plan is exhausted',
        workScheduleStart: '9:00 AM',
        workScheduleEnd: '5:00 PM',
        eligibilityStatus: 'Verify',
        isOutOfWorkSchedule: true,
        requestId: '123456',
        overAllStatus: 'denied',
        checkingInProgress: false,
        isEligible: false,
        startTime: '09:00 AM',
        regulationTypeName: 'Federal Family and Medical Leave Act (FMLA)'
      }, {
        dateOfAbsence: '05/05/2020',
        creationDate: '05/05/2020',
        creationTime: '9:15:12 PM',
        intervalHours: 7,
        intervalMins: 0,
        leaveType: 'Intermittent',
        reason: 'Flare Up',
        status: 'denied',
        span: 'Partial Requested Day',
        denialReason: '',
        workScheduleStart: '9:00 AM',
        workScheduleEnd: '5:00 PM',
        eligibilityStatus: 'Not Eligible',
        isOutOfWorkSchedule: true,
        requestId: '123457',
        overAllStatus: 'Denied',
        checkingInProgress: false,
        isEligible: false,
        startTime: '09:00 AM',
        regulationTypeName: 'Federal Family and Medical Leave Act (FMLA)'
      },
      {
        dateOfAbsence: '05/05/2020',
        creationDate: '05/05/2020',
        creationTime: '9:15:12 PM',
        intervalHours: 7,
        intervalMins: 0,
        leaveType: 'Intermittent',
        reason: 'Flare Up',
        status: 'denied',
        span: 'Partial Requested Day',
        denialReason: '',
        workScheduleStart: '9:00 AM',
        workScheduleEnd: '5:00 PM',
        eligibilityStatus: 'Verify',
        isOutOfWorkSchedule: true,
        requestId: '123456',
        overAllStatus: 'denied',
        checkingInProgress: false,
        isEligible: false,
        startTime: '09:00 AM',
        regulationTypeName: 'Federal Family and Medical Leave Act (FMLA)'
      }, {
        dateOfAbsence: '5/9/2020',
        creationDate: '4/25/2020',
        creationTime: '6:26:34 AM',
        intervalHours: 8,
        intervalMins: 0,
        leaveType: 'continuous',
        reason: 'Flare Up',
        status: 'Denied',
        span: 'Full Requested Day',
        denialReason: 'The Hartford will manage your future leaves, but this request was denied by the prior administrator. You can contact them for more information.',
        workScheduleStart: '9:00 AM',
        workScheduleEnd: '5:00 PM',
        eligibilityStatus: 'Eligible',
        isOutOfWorkSchedule: true,
        requestId: '123445',
        overAllStatus: 'Denied',
        checkingInProgress: false,
        isEligible: false,
        startTime: '09:00 AM',
        regulationTypeName: 'Federal Family and Medical Leave Act (FMLA)'
      }]
  },
    {
      leaveId: '200004',
      absenceList: [{
        dateOfAbsence: '5/14/2020',
        creationDate: '5/14/2020',
        creationTime: '9:10:07 PM',
        intervalHours: 8,
        intervalMins: 0,
        leaveType: 'Intermittent',
        reason: 'Flare Up',
        status: 'Approved',
        span: 'Full Requested Day',
        denialReason: '',
        workScheduleStart: '9:00 AM',
        workScheduleEnd: '5:00 PM',
        eligibilityStatus: 'Eligible',
        isOutOfWorkSchedule: true,
        requestId: '123459',
        overAllStatus: 'Approved',
        checkingInProgress: false,
        isEligible: false,
        startTime: '09:00 AM',
        regulationTypeName: 'Federal Family and Medical Leave Act (FMLA)'
      }, {
        dateOfAbsence: '5/14/2020',
        creationDate: '5/14/2020',
        creationTime: '9:10:07 PM',
        intervalHours: 8,
        intervalMins: 0,
        leaveType: 'Intermittent',
        reason: 'Flare Up',
        status: 'Approved',
        span: 'Full Requested Day',
        denialReason: '',
        workScheduleStart: '9:00 AM',
        workScheduleEnd: '5:00 PM',
        eligibilityStatus: 'Not Eligible',
        isOutOfWorkSchedule: true,
        requestId: '123459',
        overAllStatus: 'Approved',
        checkingInProgress: false,
        isEligible: false,
        startTime: '09:00 AM',
        regulationTypeName: 'Federal Family and Medical Leave Act (FMLA)'
      }]
    },
    {
      leaveId: '200012',
      absenceList: [{
        dateOfAbsence: '6/4/2020',
        creationDate: '4/30/2020',
        creationTime: '9:10:06 PM',
        intervalHours: 8,
        intervalMins: 0,
        leaveType: 'Continuous',
        reason: '',
        status: 'PENDED',
        span: 'Full Requested Day',
        denialReason: '',
        workScheduleStart: '9:00 AM',
        workScheduleEnd: '5:00 PM',
        eligibilityStatus: 'Eligible',
        isOutOfWorkSchedule: true,
        requestId: '123412',
        overAllStatus: 'pended',
        checkingInProgress: false,
        isEligible: true,
        startTime: '09:00 AM',
        regulationTypeName: 'Federal Family and Medical Leave Act (FMLA)'
      }, {
        dateOfAbsence: '6/4/2020',
        creationDate: '4/30/2020',
        creationTime: '9:10:06 PM',
        intervalHours: 8,
        intervalMins: 20,
        leaveType: 'Continuous',
        reason: '',
        status: 'PENDED',
        span: 'Full Requested Day',
        denialReason: '',
        workScheduleStart: '9:00 AM',
        workScheduleEnd: '5:00 PM',
        eligibilityStatus: 'Eligible',
        isOutOfWorkSchedule: false,
        requestId: '123412',
        overAllStatus: 'pended',
        checkingInProgress: false,
        isEligible: true,
        startTime: '09:00 AM',
        regulationTypeName: 'Federal Family and Medical Leave Act (FMLA)'
      }, {
        dateOfAbsence: '6/4/2020',
        creationDate: '4/30/2020',
        creationTime: '9:10:06 PM',
        intervalHours: 8,
        intervalMins: 0,
        leaveType: 'Continuous',
        reason: '',
        status: 'PENDED',
        span: 'Full Requested Day',
        denialReason: '',
        workScheduleStart: '9:00 AM',
        workScheduleEnd: '5:00 PM',
        eligibilityStatus: 'Undetermined',
        isOutOfWorkSchedule: false,
        requestId: '123412',
        overAllStatus: 'pended',
        checkingInProgress: false,
        isEligible: true,
        startTime: '09:00 AM',
        regulationTypeName: 'Federal Family and Medical Leave Act (FMLA)'
      }, {
        dateOfAbsence: '6/4/2020',
        creationDate: '4/30/2020',
        creationTime: '9:10:06 PM',
        intervalHours: 8,
        intervalMins: 0,
        leaveType: 'Continuous',
        reason: '',
        status: 'PENDED',
        span: 'Full Requested Day',
        denialReason: '',
        workScheduleStart: '9:00 AM',
        workScheduleEnd: '5:00 PM',
        eligibilityStatus: 'Undetermined',
        isOutOfWorkSchedule: true,
        requestId: '123412',
        overAllStatus: 'pended',
        checkingInProgress: false,
        isEligible: true,
        startTime: '09:00 AM',
        regulationTypeName: 'Federal Family and Medical Leave Act (FMLA)'
      }, {
        dateOfAbsence: '6/5/2020',
        creationDate: '4/30/2020',
        creationTime: '9:10:06 PM',
        intervalHours: 8,
        intervalMins: 0,
        leaveType: 'Continuous',
        reason: '',
        status: 'PENDED',
        span: 'Full Requested Day',
        denialReason: '',
        workScheduleStart: '9:00 AM',
        workScheduleEnd: '5:00 PM',
        eligibilityStatus: 'Eligible',
        isOutOfWorkSchedule: true,
        requestId: '123412',
        overAllStatus: 'pended',
        checkingInProgress: false,
        isEligible: true,
        startTime: '09:00 AM',
        regulationTypeName: 'Federal Family and Medical Leave Act (FMLA)'
      }, {
        dateOfAbsence: '6/5/2020',
        creationDate: '4/30/2020',
        creationTime: '9:10:06 PM',
        intervalHours: 8,
        intervalMins: 0,
        leaveType: 'Continuous',
        reason: '',
        status: 'PENDED',
        span: 'Full Requested Day',
        denialReason: '',
        workScheduleStart: '9:00 AM',
        workScheduleEnd: '5:00 PM',
        eligibilityStatus: 'Eligible',
        isOutOfWorkSchedule: false,
        requestId: '123312',
        overAllStatus: 'pended',
        checkingInProgress: false,
        isEligible: true,
        startTime: '09:00 AM',
        regulationTypeName: 'Federal Family and Medical Leave Act (FMLA)'
      }, {
        dateOfAbsence: '6/5/2020',
        creationDate: '4/30/2020',
        creationTime: '9:10:06 PM',
        intervalHours: 8,
        intervalMins: 0,
        leaveType: 'Continuous',
        reason: '',
        status: 'PENDED',
        span: 'Full Requested Day',
        denialReason: '',
        workScheduleStart: '9:00 AM',
        workScheduleEnd: '5:00 PM',
        eligibilityStatus: 'Undetermined',
        isOutOfWorkSchedule: true,
        requestId: '123312',
        overAllStatus: 'pended',
        checkingInProgress: false,
        isEligible: true,
        startTime: '09:00 AM',
        regulationTypeName: 'Federal Family and Medical Leave Act (FMLA)'
      }, {
        dateOfAbsence: '6/5/2020',
        creationDate: '4/30/2020',
        creationTime: '9:10:06 PM',
        intervalHours: 8,
        intervalMins: 0,
        leaveType: 'Continuous',
        reason: '',
        status: 'PENDED',
        span: 'Full Requested Day',
        denialReason: '',
        workScheduleStart: '9:00 AM',
        workScheduleEnd: '5:00 PM',
        eligibilityStatus: 'Undetermined',
        isOutOfWorkSchedule: true,
        requestId: '123312',
        overAllStatus: 'pended',
        checkingInProgress: false,
        isEligible: true,
        startTime: '09:00 AM',
        regulationTypeName: 'Federal Family and Medical Leave Act (FMLA)'
      }, {
        dateOfAbsence: '6/6/2020',
        creationDate: '4/30/2020',
        creationTime: '9:10:06 PM',
        intervalHours: 8,
        intervalMins: 0,
        leaveType: 'Continuous',
        reason: '',
        status: 'PENDED',
        span: 'Full Requested Day',
        denialReason: '',
        workScheduleStart: '9:00 AM',
        workScheduleEnd: '5:00 PM',
        eligibilityStatus: 'Eligible',
        isOutOfWorkSchedule: false,
        requestId: '123512',
        overAllStatus: 'pended',
        checkingInProgress: false,
        isEligible: true,
        startTime: '09:00 AM',
        regulationTypeName: 'Federal Family and Medical Leave Act (FMLA)'
      }, {
        dateOfAbsence: '6/6/2020',
        creationDate: '4/30/2020',
        creationTime: '9:10:06 PM',
        intervalHours: 8,
        intervalMins: 0,
        leaveType: 'Continuous',
        reason: '',
        status: 'PENDED',
        span: 'Full Requested Day',
        denialReason: '',
        workScheduleStart: '9:00 AM',
        workScheduleEnd: '5:00 PM',
        eligibilityStatus: 'Eligible',
        isOutOfWorkSchedule: true,
        requestId: '123512',
        overAllStatus: 'pended',
        checkingInProgress: false,
        isEligible: true,
        startTime: '09:00 AM',
        regulationTypeName: 'Federal Family and Medical Leave Act (FMLA)'
      }, {
        dateOfAbsence: '6/6/2020',
        creationDate: '4/30/2020',
        creationTime: '9:10:06 PM',
        intervalHours: 8,
        intervalMins: 0,
        leaveType: 'Continuous',
        reason: '',
        status: 'PENDED',
        span: 'Full Requested Day',
        denialReason: '',
        workScheduleStart: '9:00 AM',
        workScheduleEnd: '5:00 PM',
        eligibilityStatus: 'Undetermined',
        isOutOfWorkSchedule: false,
        requestId: '123512',
        overAllStatus: 'pended',
        checkingInProgress: false,
        isEligible: true,
        startTime: '09:00 AM',
        regulationTypeName: 'Federal Family and Medical Leave Act (FMLA)'
      }, {
        dateOfAbsence: '6/6/2020',
        creationDate: '4/30/2020',
        creationTime: '9:10:06 PM',
        intervalHours: 8,
        intervalMins: 0,
        leaveType: 'Continuous',
        reason: '',
        status: 'PENDED',
        span: 'Full Requested Day',
        denialReason: '',
        workScheduleStart: '9:00 AM',
        workScheduleEnd: '5:00 PM',
        eligibilityStatus: 'Undetermined',
        isOutOfWorkSchedule: true,
        requestId: '123512',
        overAllStatus: 'pended',
        checkingInProgress: false,
        isEligible: true,
        startTime: '09:00 AM',
        regulationTypeName: 'Federal Family and Medical Leave Act (FMLA)'
      }, {
        dateOfAbsence: '6/7/2020',
        creationDate: '4/30/2020',
        creationTime: '9:10:06 PM',
        intervalHours: 8,
        intervalMins: 0,
        leaveType: 'Continuous',
        reason: '',
        status: 'PENDED',
        span: 'Full Requested Day',
        denialReason: '',
        workScheduleStart: '9:00 AM',
        workScheduleEnd: '5:00 PM',
        eligibilityStatus: 'Eligible',
        isOutOfWorkSchedule: true,
        requestId: '124512',
        overAllStatus: 'pended',
        checkingInProgress: false,
        isEligible: true,
        startTime: '09:00 AM',
        regulationTypeName: 'Federal Family and Medical Leave Act (FMLA)'
      }, {
        dateOfAbsence: '6/7/2020',
        creationDate: '4/30/2020',
        creationTime: '9:10:06 PM',
        intervalHours: 8,
        intervalMins: 0,
        leaveType: 'Continuous',
        reason: '',
        status: 'PENDED',
        span: 'Full Requested Day',
        denialReason: '',
        workScheduleStart: '9:00 AM',
        workScheduleEnd: '5:00 PM',
        eligibilityStatus: 'Eligible',
        isOutOfWorkSchedule: true,
        requestId: '124512',
        overAllStatus: 'pended',
        checkingInProgress: false,
        isEligible: true,
        startTime: '09:00 AM',
        regulationTypeName: 'Federal Family and Medical Leave Act (FMLA)'
      }, {
        dateOfAbsence: '6/7/2020',
        creationDate: '4/30/2020',
        creationTime: '9:10:06 PM',
        intervalHours: 8,
        intervalMins: 0,
        leaveType: 'Continuous',
        reason: '',
        status: 'PENDED',
        span: 'Full Requested Day',
        denialReason: '',
        workScheduleStart: '9:00 AM',
        workScheduleEnd: '5:00 PM',
        eligibilityStatus: 'Undetermined',
        isOutOfWorkSchedule: true,
        requestId: '124512',
        overAllStatus: 'pended',
        checkingInProgress: false,
        isEligible: true,
        startTime: '09:00 AM',
        regulationTypeName: 'Federal Family and Medical Leave Act (FMLA)'
      }, {
        dateOfAbsence: '6/7/2020',
        creationDate: '4/30/2020',
        creationTime: '9:10:06 PM',
        intervalHours: 8,
        intervalMins: 0,
        leaveType: 'Continuous',
        reason: '',
        status: 'PENDED',
        span: 'Full Requested Day',
        denialReason: '',
        workScheduleStart: '9:00 AM',
        workScheduleEnd: '5:00 PM',
        eligibilityStatus: 'Undetermined',
        isOutOfWorkSchedule: true,
        requestId: '124512',
        overAllStatus: 'pended',
        checkingInProgress: false,
        isEligible: true,
        startTime: '09:00 AM',
        regulationTypeName: 'Federal Family and Medical Leave Act (FMLA)'
      }, {
        dateOfAbsence: '6/10/2020',
        creationDate: '4/30/2020',
        creationTime: '9:10:06 PM',
        intervalHours: 8,
        intervalMins: 0,
        leaveType: 'Continuous',
        reason: '',
        status: 'PENDED',
        span: 'Full Requested Day',
        denialReason: '',
        workScheduleStart: '9:00 AM',
        workScheduleEnd: '5:00 PM',
        eligibilityStatus: 'Eligible',
        isOutOfWorkSchedule: true,
        requestId: '124513',
        overAllStatus: 'pended',
        checkingInProgress: false,
        isEligible: true,
        startTime: '09:00 AM',
        regulationTypeName: 'Federal Family and Medical Leave Act (FMLA)'
      }, {
        dateOfAbsence: '6/10/2020',
        creationDate: '4/30/2020',
        creationTime: '9:10:06 PM',
        intervalHours: 8,
        intervalMins: 0,
        leaveType: 'Continuous',
        reason: '',
        status: 'PENDED',
        span: 'Full Requested Day',
        denialReason: '',
        workScheduleStart: '9:00 AM',
        workScheduleEnd: '5:00 PM',
        eligibilityStatus: 'Eligible',
        isOutOfWorkSchedule: true,
        requestId: '124513',
        overAllStatus: 'pended',
        checkingInProgress: false,
        isEligible: true,
        startTime: '09:00 AM',
        regulationTypeName: 'Federal Family and Medical Leave Act (FMLA)'
      }, {
        dateOfAbsence: '6/10/2020',
        creationDate: '4/30/2020',
        creationTime: '9:10:06 PM',
        intervalHours: 8,
        intervalMins: 0,
        leaveType: 'Continuous',
        reason: '',
        status: 'PENDED',
        span: 'Full Requested Day',
        denialReason: '',
        workScheduleStart: '9:00 AM',
        workScheduleEnd: '5:00 PM',
        eligibilityStatus: 'Undetermined',
        isOutOfWorkSchedule: true,
        requestId: '124513',
        overAllStatus: 'pended',
        checkingInProgress: false,
        isEligible: true,
        startTime: '09:00 AM',
        regulationTypeName: 'Federal Family and Medical Leave Act (FMLA)'
      }, {
        dateOfAbsence: '6/10/2020',
        creationDate: '4/30/2020',
        creationTime: '9:10:06 PM',
        intervalHours: 8,
        intervalMins: 0,
        leaveType: 'Continuous',
        reason: '',
        status: 'PENDED',
        span: 'Full Requested Day',
        denialReason: '',
        workScheduleStart: '9:00 AM',
        workScheduleEnd: '5:00 PM',
        eligibilityStatus: 'Undetermined',
        isOutOfWorkSchedule: true,
        requestId: '124513',
        overAllStatus: 'pended',
        checkingInProgress: false,
        isEligible: true,
        startTime: '09:00 AM',
        regulationTypeName: 'Federal Family and Medical Leave Act (FMLA)'
      }, {
        dateOfAbsence: '6/11/2020',
        creationDate: '4/30/2020',
        creationTime: '9:10:06 PM',
        intervalHours: 8,
        intervalMins: 0,
        leaveType: 'Continuous',
        reason: '',
        status: 'PENDED',
        span: 'Full Requested Day',
        denialReason: '',
        workScheduleStart: '9:00 AM',
        workScheduleEnd: '5:00 PM',
        eligibilityStatus: 'Eligible',
        isOutOfWorkSchedule: true,
        requestId: '124513',
        overAllStatus: 'pended',
        checkingInProgress: false,
        isEligible: true,
        startTime: '09:00 AM',
        regulationTypeName: 'Federal Family and Medical Leave Act (FMLA)'
      }, {
        dateOfAbsence: '6/11/2020',
        creationDate: '4/30/2020',
        creationTime: '9:10:06 PM',
        intervalHours: 8,
        intervalMins: 0,
        leaveType: 'Continuous',
        reason: '',
        status: 'PENDED',
        span: 'Full Requested Day',
        denialReason: '',
        workScheduleStart: '9:00 AM',
        workScheduleEnd: '5:00 PM',
        eligibilityStatus: 'Eligible',
        isOutOfWorkSchedule: true,
        requestId: '124513',
        overAllStatus: 'pended',
        checkingInProgress: false,
        isEligible: true,
        startTime: '09:00 AM',
        regulationTypeName: 'Federal Family and Medical Leave Act (FMLA)'
      }, {
        dateOfAbsence: '6/11/2020',
        creationDate: '4/30/2020',
        creationTime: '9:10:06 PM',
        intervalHours: 8,
        intervalMins: 0,
        leaveType: 'Continuous',
        reason: '',
        status: 'PENDED',
        span: 'Full Requested Day',
        denialReason: '',
        workScheduleStart: '9:00 AM',
        workScheduleEnd: '5:00 PM',
        eligibilityStatus: 'Undetermined',
        isOutOfWorkSchedule: true,
        requestId: '124513',
        overAllStatus: 'pended',
        checkingInProgress: false,
        isEligible: true,
        startTime: '09:00 AM',
        regulationTypeName: 'Federal Family and Medical Leave Act (FMLA)'
      }, {
        dateOfAbsence: '6/11/2020',
        creationDate: '4/30/2020',
        creationTime: '9:10:06 PM',
        intervalHours: 8,
        intervalMins: 0,
        leaveType: 'Continuous',
        reason: '',
        status: 'PENDED',
        span: 'Full Requested Day',
        denialReason: '',
        workScheduleStart: '9:00 AM',
        workScheduleEnd: '5:00 PM',
        eligibilityStatus: 'Undetermined',
        isOutOfWorkSchedule: true,
        requestId: '124513',
        overAllStatus: 'pended',
        checkingInProgress: false,
        isEligible: true,
        startTime: '09:00 AM',
        regulationTypeName: 'Federal Family and Medical Leave Act (FMLA)'
      }, {
        dateOfAbsence: '6/12/2020',
        creationDate: '4/30/2020',
        creationTime: '9:10:06 PM',
        intervalHours: 8,
        intervalMins: 0,
        leaveType: 'Continuous',
        reason: '',
        status: 'PENDED',
        span: 'Full Requested Day',
        denialReason: '',
        workScheduleStart: '9:00 AM',
        workScheduleEnd: '5:00 PM',
        eligibilityStatus: 'Eligible',
        isOutOfWorkSchedule: false,
        requestId: '124513',
        overAllStatus: 'pended',
        checkingInProgress: false,
        isEligible: true,
        startTime: '09:00 AM',
        regulationTypeName: 'Federal Family and Medical Leave Act (FMLA)'
      }, {
        dateOfAbsence: '6/12/2020',
        creationDate: '4/30/2020',
        creationTime: '9:10:06 PM',
        intervalHours: 8,
        intervalMins: 0,
        leaveType: 'Continuous',
        reason: '',
        status: 'PENDED',
        span: 'Full Requested Day',
        denialReason: '',
        workScheduleStart: '9:00 AM',
        workScheduleEnd: '5:00 PM',
        eligibilityStatus: 'Eligible',
        isOutOfWorkSchedule: true,
        requestId: '124513',
        overAllStatus: 'pended',
        checkingInProgress: false,
        isEligible: true,
        startTime: '09:00 AM',
        regulationTypeName: 'Federal Family and Medical Leave Act (FMLA)'
      }, {
        dateOfAbsence: '6/12/2020',
        creationDate: '4/30/2020',
        creationTime: '9:10:06 PM',
        intervalHours: 8,
        intervalMins: 0,
        leaveType: 'Continuous',
        reason: '',
        status: 'PENDED',
        span: 'Full Requested Day',
        denialReason: '',
        workScheduleStart: '9:00 AM',
        workScheduleEnd: '5:00 PM',
        eligibilityStatus: 'Undetermined',
        isOutOfWorkSchedule: false,
        requestId: '124513',
        overAllStatus: 'pended',
        checkingInProgress: false,
        isEligible: true,
        startTime: '09:00 AM',
        regulationTypeName: 'Federal Family and Medical Leave Act (FMLA)'
      },
        //   {
        //   'dateOfAbsence': '6/12/2020',
        //   'creationDate': '4/30/2020',
        //   'creationTime': '9:10:06 PM',
        //   'intervalHours': 8,
        //   'intervalMins': 0,
        //   'leaveType': 'Continuous',
        //   'reason': '',
        //   'status': 'PENDED',
        //   'span': 'Full Requested Day',
        //   'denialReason': '',
        //   'workScheduleStart': '9:00 AM',
        //   'workScheduleEnd': '5:00 PM',
        //   'eligibilityStatus': 'Undetermined',
        //   'isOutOfWorkSchedule': true,
        //   'requestId': '124513'
        // },
        //   {
        //   'dateOfAbsence': '6/13/2020',
        //   'creationDate': '4/30/2020',
        //   'creationTime': '9:10:06 PM',
        //   'intervalHours': 8,
        //   'intervalMins': 0,
        //   'leaveType': 'Continuous',
        //   'reason': '',
        //   'status': 'PENDED',
        //   'span': 'Full Requested Day',
        //   'denialReason': '',
        //   'workScheduleStart': '9:00 AM',
        //   'workScheduleEnd': '5:00 PM',
        //   'eligibilityStatus': 'Eligible',
        //   'isOutOfWorkSchedule': false,
        //   'requestId': '124513'
        // },
        //   {
        //   'dateOfAbsence': '6/13/2020',
        //   'creationDate': '4/30/2020',
        //   'creationTime': '9:10:06 PM',
        //   'intervalHours': 8,
        //   'intervalMins': 0,
        //   'leaveType': 'Continuous',
        //   'reason': '',
        //   'status': 'PENDED',
        //   'span': 'Full Requested Day',
        //   'denialReason': '',
        //   'workScheduleStart': '9:00 AM',
        //   'workScheduleEnd': '5:00 PM',
        //   'eligibilityStatus': 'Eligible',
        //   'isOutOfWorkSchedule': true,
        //   'requestId': '124513'
        // },
        //   {
        //   'dateOfAbsence': '6/13/2020',
        //   'creationDate': '4/30/2020',
        //   'creationTime': '9:10:06 PM',
        //   'intervalHours': 8,
        //   'intervalMins': 0,
        //   'leaveType': 'Continuous',
        //   'reason': '',
        //   'status': 'PENDED',
        //   'span': 'Full Requested Day',
        //   'denialReason': '',
        //   'workScheduleStart': '9:00 AM',
        //   'workScheduleEnd': '5:00 PM',
        //   'eligibilityStatus': 'Undetermined',
        //   'isOutOfWorkSchedule': true,
        //   'requestId': '124513'
        // },
        //   {
        //   'dateOfAbsence': '6/13/2020',
        //   'creationDate': '4/30/2020',
        //   'creationTime': '9:10:06 PM',
        //   'intervalHours': 8,
        //   'intervalMins': 0,
        //   'leaveType': 'Continuous',
        //   'reason': '',
        //   'status': 'PENDED',
        //   'span': 'Full Requested Day',
        //   'denialReason': '',
        //   'workScheduleStart': '9:00 AM',
        //   'workScheduleEnd': '5:00 PM',
        //   'eligibilityStatus': 'Undetermined',
        //   'isOutOfWorkSchedule': true,
        //   'requestId': '124513'
        // },
        //   {
        //   'dateOfAbsence': '6/14/2020',
        //   'creationDate': '4/30/2020',
        //   'creationTime': '9:10:06 PM',
        //   'intervalHours': 8,
        //   'intervalMins': 0,
        //   'leaveType': 'Continuous',
        //   'reason': '',
        //   'status': 'PENDED',
        //   'span': 'Full Requested Day',
        //   'denialReason': '',
        //   'workScheduleStart': '9:00 AM',
        //   'workScheduleEnd': '5:00 PM',
        //   'eligibilityStatus': 'Eligible',
        //   'isOutOfWorkSchedule': true,
        //   'requestId': '124513'
        // },
        //   {
        //   'dateOfAbsence': '6/14/2020',
        //   'creationDate': '4/30/2020',
        //   'creationTime': '9:10:06 PM',
        //   'intervalHours': 8,
        //   'intervalMins': 0,
        //   'leaveType': 'Continuous',
        //   'reason': '',
        //   'status': 'PENDED',
        //   'span': 'Full Requested Day',
        //   'denialReason': '',
        //   'workScheduleStart': '9:00 AM',
        //   'workScheduleEnd': '5:00 PM',
        //   'eligibilityStatus': 'Eligible',
        //   'isOutOfWorkSchedule': true,
        //   'requestId': '124513'
        // },
        //   {
        //   'dateOfAbsence': '6/14/2020',
        //   'creationDate': '4/30/2020',
        //   'creationTime': '9:10:06 PM',
        //   'intervalHours': 8,
        //   'intervalMins': 0,
        //   'leaveType': 'Continuous',
        //   'reason': '',
        //   'status': 'PENDED',
        //   'span': 'Full Requested Day',
        //   'denialReason': '',
        //   'workScheduleStart': '9:00 AM',
        //   'workScheduleEnd': '5:00 PM',
        //   'eligibilityStatus': 'Undetermined',
        //   'isOutOfWorkSchedule': true,
        //   'requestId': '124513'
        // },
        //   {
        //   'dateOfAbsence': '6/14/2020',
        //   'creationDate': '4/30/2020',
        //   'creationTime': '9:10:06 PM',
        //   'intervalHours': 8,
        //   'intervalMins': 0,
        //   'leaveType': 'Continuous',
        //   'reason': '',
        //   'status': 'PENDED',
        //   'span': 'Full Requested Day',
        //   'denialReason': '',
        //   'workScheduleStart': '9:00 AM',
        //   'workScheduleEnd': '5:00 PM',
        //   'eligibilityStatus': 'Undetermined',
        //   'isOutOfWorkSchedule': true,
        //   'requestId': '124513'
        // },
        //   {
        //   'dateOfAbsence': '6/17/2020',
        //   'creationDate': '4/30/2020',
        //   'creationTime': '9:10:06 PM',
        //   'intervalHours': 8,
        //   'intervalMins': 0,
        //   'leaveType': 'Continuous',
        //   'reason': '',
        //   'status': 'PENDED',
        //   'span': 'Full Requested Day',
        //   'denialReason': '',
        //   'workScheduleStart': '9:00 AM',
        //   'workScheduleEnd': '5:00 PM',
        //   'eligibilityStatus': 'Eligible',
        //   'isOutOfWorkSchedule': true,
        //   'requestId': '124514'
        // },
        //   {
        //   'dateOfAbsence': '6/17/2020',
        //   'creationDate': '4/30/2020',
        //   'creationTime': '9:10:06 PM',
        //   'intervalHours': 8,
        //   'intervalMins': 0,
        //   'leaveType': 'Continuous',
        //   'reason': '',
        //   'status': 'PENDED',
        //   'span': 'Full Requested Day',
        //   'denialReason': '',
        //   'workScheduleStart': '9:00 AM',
        //   'workScheduleEnd': '5:00 PM',
        //   'eligibilityStatus': 'Eligible',
        //   'isOutOfWorkSchedule': true,
        //   'requestId': '124514'
        // },
        //   {
        //   'dateOfAbsence': '6/17/2020',
        //   'creationDate': '4/30/2020',
        //   'creationTime': '9:10:06 PM',
        //   'intervalHours': 8,
        //   'intervalMins': 0,
        //   'leaveType': 'Continuous',
        //   'reason': '',
        //   'status': 'PENDED',
        //   'span': 'Full Requested Day',
        //   'denialReason': '',
        //   'workScheduleStart': '9:00 AM',
        //   'workScheduleEnd': '5:00 PM',
        //   'eligibilityStatus': 'Undetermined',
        //   'isOutOfWorkSchedule': true,
        //   'requestId': '124514'
        // },
        //   {
        //   'dateOfAbsence': '6/17/2020',
        //   'creationDate': '4/30/2020',
        //   'creationTime': '9:10:06 PM',
        //   'intervalHours': 8,
        //   'intervalMins': 0,
        //   'leaveType': 'Continuous',
        //   'reason': '',
        //   'status': 'PENDED',
        //   'span': 'Full Requested Day',
        //   'denialReason': '',
        //   'workScheduleStart': '9:00 AM',
        //   'workScheduleEnd': '5:00 PM',
        //   'eligibilityStatus': 'Undetermined',
        //   'isOutOfWorkSchedule': true,
        //   'requestId': '124514'
        // },
        //   {
        //   'dateOfAbsence': '6/18/2020',
        //   'creationDate': '4/30/2020',
        //   'creationTime': '9:10:06 PM',
        //   'intervalHours': 8,
        //   'intervalMins': 0,
        //   'leaveType': 'Continuous',
        //   'reason': '',
        //   'status': 'PENDED',
        //   'span': 'Full Requested Day',
        //   'denialReason': '',
        //   'workScheduleStart': '9:00 AM',
        //   'workScheduleEnd': '5:00 PM',
        //   'eligibilityStatus': 'Eligible',
        //   'isOutOfWorkSchedule': false,
        //   'requestId': '124514'
        // },
        //   {
        //   'dateOfAbsence': '6/18/2020',
        //   'creationDate': '4/30/2020',
        //   'creationTime': '9:10:06 PM',
        //   'intervalHours': 8,
        //   'intervalMins': 0,
        //   'leaveType': 'Continuous',
        //   'reason': '',
        //   'status': 'PENDED',
        //   'span': 'Full Requested Day',
        //   'denialReason': '',
        //   'workScheduleStart': '9:00 AM',
        //   'workScheduleEnd': '5:00 PM',
        //   'eligibilityStatus': 'Eligible',
        //   'isOutOfWorkSchedule': false,
        //   'requestId': '124514'
        // },
        //   {
        //   'dateOfAbsence': '6/18/2020',
        //   'creationDate': '4/30/2020',
        //   'creationTime': '9:10:06 PM',
        //   'intervalHours': 8,
        //   'intervalMins': 0,
        //   'leaveType': 'Continuous',
        //   'reason': '',
        //   'status': 'PENDED',
        //   'span': 'Full Requested Day',
        //   'denialReason': '',
        //   'workScheduleStart': '9:00 AM',
        //   'workScheduleEnd': '5:00 PM',
        //   'eligibilityStatus': 'Undetermined',
        //   'isOutOfWorkSchedule': true,
        //   'requestId': '124514'
        // },
        //   {
        //   'dateOfAbsence': '6/18/2020',
        //   'creationDate': '4/30/2020',
        //   'creationTime': '9:10:06 PM',
        //   'intervalHours': 8,
        //   'intervalMins': 0,
        //   'leaveType': 'Continuous',
        //   'reason': '',
        //   'status': 'PENDED',
        //   'span': 'Full Requested Day',
        //   'denialReason': '',
        //   'workScheduleStart': '9:00 AM',
        //   'workScheduleEnd': '5:00 PM',
        //   'eligibilityStatus': 'Undetermined',
        //   'isOutOfWorkSchedule': false,
        //   'requestId': '124514'
        // },
        //   {
        //   'dateOfAbsence': '6/19/2020',
        //   'creationDate': '4/30/2020',
        //   'creationTime': '9:10:06 PM',
        //   'intervalHours': 8,
        //   'intervalMins': 0,
        //   'leaveType': 'Continuous',
        //   'reason': '',
        //   'status': 'PENDED',
        //   'span': 'Full Requested Day',
        //   'denialReason': '',
        //   'workScheduleStart': '9:00 AM',
        //   'workScheduleEnd': '5:00 PM',
        //   'eligibilityStatus': 'Eligible',
        //   'isOutOfWorkSchedule': true,
        //   'requestId': '124514'
        // },
        //   {
        //   'dateOfAbsence': '6/19/2020',
        //   'creationDate': '4/30/2020',
        //   'creationTime': '9:10:06 PM',
        //   'intervalHours': 8,
        //   'intervalMins': 0,
        //   'leaveType': 'Continuous',
        //   'reason': '',
        //   'status': 'PENDED',
        //   'span': 'Full Requested Day',
        //   'denialReason': '',
        //   'workScheduleStart': '9:00 AM',
        //   'workScheduleEnd': '5:00 PM',
        //   'eligibilityStatus': 'Eligible',
        //   'isOutOfWorkSchedule': false,
        //   'requestId': '124514'
        // },
        //   {
        //   'dateOfAbsence': '6/19/2020',
        //   'creationDate': '4/30/2020',
        //   'creationTime': '9:10:06 PM',
        //   'intervalHours': 8,
        //   'intervalMins': 0,
        //   'leaveType': 'Continuous',
        //   'reason': '',
        //   'status': 'PENDED',
        //   'span': 'Full Requested Day',
        //   'denialReason': '',
        //   'workScheduleStart': '9:00 AM',
        //   'workScheduleEnd': '5:00 PM',
        //   'eligibilityStatus': 'Undetermined',
        //   'isOutOfWorkSchedule': true,
        //   'requestId': '124514'
        // },
        //   {
        //   'dateOfAbsence': '6/19/2020',
        //   'creationDate': '4/30/2020',
        //   'creationTime': '9:10:06 PM',
        //   'intervalHours': 8,
        //   'intervalMins': 0,
        //   'leaveType': 'Continuous',
        //   'reason': '',
        //   'status': 'PENDED',
        //   'span': 'Full Requested Day',
        //   'denialReason': '',
        //   'workScheduleStart': '9:00 AM',
        //   'workScheduleEnd': '5:00 PM',
        //   'eligibilityStatus': 'Undetermined',
        //   'isOutOfWorkSchedule': true,
        //   'requestId': '124514'
        // },
        //   {
        //   'dateOfAbsence': '6/20/2020',
        //   'creationDate': '4/30/2020',
        //   'creationTime': '9:10:06 PM',
        //   'intervalHours': 8,
        //   'intervalMins': 0,
        //   'leaveType': 'Continuous',
        //   'reason': '',
        //   'status': 'PENDED',
        //   'span': 'Full Requested Day',
        //   'denialReason': '',
        //   'workScheduleStart': '9:00 AM',
        //   'workScheduleEnd': '5:00 PM',
        //   'eligibilityStatus': 'Eligible',
        //   'isOutOfWorkSchedule': true,
        //   'requestId': '124514'
        // },
        //   {
        //   'dateOfAbsence': '6/20/2020',
        //   'creationDate': '4/30/2020',
        //   'creationTime': '9:10:06 PM',
        //   'intervalHours': 8,
        //   'intervalMins': 0,
        //   'leaveType': 'Continuous',
        //   'reason': '',
        //   'status': 'PENDED',
        //   'span': 'Full Requested Day',
        //   'denialReason': '',
        //   'workScheduleStart': '9:00 AM',
        //   'workScheduleEnd': '5:00 PM',
        //   'eligibilityStatus': 'Eligible',
        //   'isOutOfWorkSchedule': false,
        //   'requestId': '124514'
        // },
        //   {
        //   'dateOfAbsence': '6/20/2020',
        //   'creationDate': '4/30/2020',
        //   'creationTime': '9:10:06 PM',
        //   'intervalHours': 8,
        //   'intervalMins': 0,
        //   'leaveType': 'Continuous',
        //   'reason': '',
        //   'status': 'PENDED',
        //   'span': 'Full Requested Day',
        //   'denialReason': '',
        //   'workScheduleStart': '9:00 AM',
        //   'workScheduleEnd': '5:00 PM',
        //   'eligibilityStatus': 'Undetermined',
        //   'isOutOfWorkSchedule': true,
        //   'requestId': '124514'
        // },
        //   {
        //   'dateOfAbsence': '6/20/2020',
        //   'creationDate': '4/30/2020',
        //   'creationTime': '9:10:06 PM',
        //   'intervalHours': 8,
        //   'intervalMins': 0,
        //   'leaveType': 'Continuous',
        //   'reason': '',
        //   'status': 'PENDED',
        //   'span': 'Full Requested Day',
        //   'denialReason': '',
        //   'workScheduleStart': '9:00 AM',
        //   'workScheduleEnd': '5:00 PM',
        //   'eligibilityStatus': 'Undetermined',
        //   'isOutOfWorkSchedule': true,
        //   'requestId': '124514'
        // },
        //   {
        //   'dateOfAbsence': '6/21/2020',
        //   'creationDate': '4/30/2020',
        //   'creationTime': '9:10:06 PM',
        //   'intervalHours': 8,
        //   'intervalMins': 0,
        //   'leaveType': 'Continuous',
        //   'reason': '',
        //   'status': 'PENDED',
        //   'span': 'Full Requested Day',
        //   'denialReason': '',
        //   'workScheduleStart': '9:00 AM',
        //   'workScheduleEnd': '5:00 PM',
        //   'eligibilityStatus': 'Eligible',
        //   'isOutOfWorkSchedule': true,
        //   'requestId': '124514'
        // },
        //   {
        //   'dateOfAbsence': '6/21/2020',
        //   'creationDate': '4/30/2020',
        //   'creationTime': '9:10:06 PM',
        //   'intervalHours': 8,
        //   'intervalMins': 0,
        //   'leaveType': 'Continuous',
        //   'reason': '',
        //   'status': 'PENDED',
        //   'span': 'Full Requested Day',
        //   'denialReason': '',
        //   'workScheduleStart': '9:00 AM',
        //   'workScheduleEnd': '5:00 PM',
        //   'eligibilityStatus': 'Eligible',
        //   'isOutOfWorkSchedule': true,
        //   'requestId': '124514'
        // },
        //   {
        //   'dateOfAbsence': '6/21/2020',
        //   'creationDate': '4/30/2020',
        //   'creationTime': '9:10:06 PM',
        //   'intervalHours': 8,
        //   'intervalMins': 0,
        //   'leaveType': 'Continuous',
        //   'reason': '',
        //   'status': 'PENDED',
        //   'span': 'Full Requested Day',
        //   'denialReason': '',
        //   'workScheduleStart': '9:00 AM',
        //   'workScheduleEnd': '5:00 PM',
        //   'eligibilityStatus': 'Undetermined',
        //   'isOutOfWorkSchedule': true,
        //   'requestId': '124514'
        // },
        //   {
        //   'dateOfAbsence': '6/21/2020',
        //   'creationDate': '4/30/2020',
        //   'creationTime': '9:10:06 PM',
        //   'intervalHours': 8,
        //   'intervalMins': 0,
        //   'leaveType': 'Continuous',
        //   'reason': '',
        //   'status': 'PENDED',
        //   'span': 'Full Requested Day',
        //   'denialReason': '',
        //   'workScheduleStart': '9:00 AM',
        //   'workScheduleEnd': '5:00 PM',
        //   'eligibilityStatus': 'Undetermined',
        //   'isOutOfWorkSchedule': true,
        //   'requestId': '124514'
        // }, {
        //   'dateOfAbsence': '6/24/2020',
        //   'creationDate': '4/30/2020',
        //   'creationTime': '9:10:06 PM',
        //   'intervalHours': 8,
        //   'intervalMins': 0,
        //   'leaveType': 'Continuous',
        //   'reason': '',
        //   'status': 'PENDED',
        //   'span': 'Full Requested Day',
        //   'denialReason': '',
        //   'workScheduleStart': '9:00 AM',
        //   'workScheduleEnd': '5:00 PM',
        //   'eligibilityStatus': 'Eligible',
        //   'isOutOfWorkSchedule': true,
        //   'requestId': '124514'
        // }, {
        //   'dateOfAbsence': '6/24/2020',
        //   'creationDate': '4/30/2020',
        //   'creationTime': '9:10:06 PM',
        //   'intervalHours': 8,
        //   'intervalMins': 0,
        //   'leaveType': 'Continuous',
        //   'reason': '',
        //   'status': 'PENDED',
        //   'span': 'Full Requested Day',
        //   'denialReason': '',
        //   'workScheduleStart': '9:00 AM',
        //   'workScheduleEnd': '5:00 PM',
        //   'eligibilityStatus': 'Eligible',
        //   'isOutOfWorkSchedule': true,
        //   'requestId': '124514'
        // }, {
        //   'dateOfAbsence': '6/24/2020',
        //   'creationDate': '4/30/2020',
        //   'creationTime': '9:10:06 PM',
        //   'intervalHours': 8,
        //   'intervalMins': 0,
        //   'leaveType': 'Continuous',
        //   'reason': '',
        //   'status': 'PENDED',
        //   'span': 'Full Requested Day',
        //   'denialReason': '',
        //   'workScheduleStart': '9:00 AM',
        //   'workScheduleEnd': '5:00 PM',
        //   'eligibilityStatus': 'Undetermined',
        //   'isOutOfWorkSchedule': true,
        //   'requestId': '124514'
        // }, {
        //   'dateOfAbsence': '6/24/2020',
        //   'creationDate': '4/30/2020',
        //   'creationTime': '9:10:06 PM',
        //   'intervalHours': 8,
        //   'intervalMins': 0,
        //   'leaveType': 'Continuous',
        //   'reason': '',
        //   'status': 'PENDED',
        //   'span': 'Full Requested Day',
        //   'denialReason': '',
        //   'workScheduleStart': '9:00 AM',
        //   'workScheduleEnd': '5:00 PM',
        //   'eligibilityStatus': 'Undetermined',
        //   'isOutOfWorkSchedule': true,
        //   'requestId': '124514'
        // }, {
        //   'dateOfAbsence': '6/25/2020',
        //   'creationDate': '4/30/2020',
        //   'creationTime': '9:10:06 PM',
        //   'intervalHours': 8,
        //   'intervalMins': 0,
        //   'leaveType': 'Continuous',
        //   'reason': '',
        //   'status': 'PENDED',
        //   'span': 'Full Requested Day',
        //   'denialReason': '',
        //   'workScheduleStart': '9:00 AM',
        //   'workScheduleEnd': '5:00 PM',
        //   'eligibilityStatus': 'Eligible',
        //   'isOutOfWorkSchedule': false,
        //   'requestId': '124514'
        // }, {
        //   'dateOfAbsence': '6/25/2020',
        //   'creationDate': '4/30/2020',
        //   'creationTime': '9:10:06 PM',
        //   'intervalHours': 8,
        //   'intervalMins': 0,
        //   'leaveType': 'Continuous',
        //   'reason': '',
        //   'status': 'PENDED',
        //   'span': 'Full Requested Day',
        //   'denialReason': '',
        //   'workScheduleStart': '9:00 AM',
        //   'workScheduleEnd': '5:00 PM',
        //   'eligibilityStatus': 'Eligible',
        //   'isOutOfWorkSchedule': false,
        //   'requestId': '124514'
        // }, {
        //   'dateOfAbsence': '6/25/2020',
        //   'creationDate': '4/30/2020',
        //   'creationTime': '9:10:06 PM',
        //   'intervalHours': 8,
        //   'intervalMins': 0,
        //   'leaveType': 'Continuous',
        //   'reason': '',
        //   'status': 'PENDED',
        //   'span': 'Full Requested Day',
        //   'denialReason': '',
        //   'workScheduleStart': '9:00 AM',
        //   'workScheduleEnd': '5:00 PM',
        //   'eligibilityStatus': 'Undetermined',
        //   'isOutOfWorkSchedule': true,
        //   'requestId': '124514'
        // }, {
        //   'dateOfAbsence': '6/25/2020',
        //   'creationDate': '4/30/2020',
        //   'creationTime': '9:10:06 PM',
        //   'intervalHours': 8,
        //   'intervalMins': 0,
        //   'leaveType': 'Continuous',
        //   'reason': '',
        //   'status': 'PENDED',
        //   'span': 'Full Requested Day',
        //   'denialReason': '',
        //   'workScheduleStart': '9:00 AM',
        //   'workScheduleEnd': '5:00 PM',
        //   'eligibilityStatus': 'Undetermined',
        //   'isOutOfWorkSchedule': true,
        //   'requestId': '124514'
        // }, {
        //   'dateOfAbsence': '6/26/2020',
        //   'creationDate': '4/30/2020',
        //   'creationTime': '9:10:06 PM',
        //   'intervalHours': 8,
        //   'intervalMins': 0,
        //   'leaveType': 'Continuous',
        //   'reason': '',
        //   'status': 'PENDED',
        //   'span': 'Full Requested Day',
        //   'denialReason': '',
        //   'workScheduleStart': '9:00 AM',
        //   'workScheduleEnd': '5:00 PM',
        //   'eligibilityStatus': 'Eligible',
        //   'isOutOfWorkSchedule': true,
        //   'requestId': '124514'
        // }, {
        //   'dateOfAbsence': '6/26/2020',
        //   'creationDate': '4/30/2020',
        //   'creationTime': '9:10:06 PM',
        //   'intervalHours': 8,
        //   'intervalMins': 0,
        //   'leaveType': 'Continuous',
        //   'reason': '',
        //   'status': 'PENDED',
        //   'span': 'Full Requested Day',
        //   'denialReason': '',
        //   'workScheduleStart': '9:00 AM',
        //   'workScheduleEnd': '5:00 PM',
        //   'eligibilityStatus': 'Eligible',
        //   'isOutOfWorkSchedule': true,
        //   'requestId': '124514'
        // }, {
        //   'dateOfAbsence': '6/26/2020',
        //   'creationDate': '4/30/2020',
        //   'creationTime': '9:10:06 PM',
        //   'intervalHours': 8,
        //   'intervalMins': 0,
        //   'leaveType': 'Continuous',
        //   'reason': '',
        //   'status': 'PENDED',
        //   'span': 'Full Requested Day',
        //   'denialReason': '',
        //   'workScheduleStart': '9:00 AM',
        //   'workScheduleEnd': '5:00 PM',
        //   'eligibilityStatus': 'Undetermined',
        //   'isOutOfWorkSchedule': true,
        //   'requestId': '124514'
        // }, {
        //   'dateOfAbsence': '6/26/2020',
        //   'creationDate': '4/30/2020',
        //   'creationTime': '9:10:06 PM',
        //   'intervalHours': 8,
        //   'intervalMins': 0,
        //   'leaveType': 'Continuous',
        //   'reason': '',
        //   'status': 'PENDED',
        //   'span': 'Full Requested Day',
        //   'denialReason': '',
        //   'workScheduleStart': '9:00 AM',
        //   'workScheduleEnd': '5:00 PM',
        //   'eligibilityStatus': 'Undetermined',
        //   'isOutOfWorkSchedule': true,
        //   'requestId': '124514'
        // },
        //   {
        //   'dateOfAbsence': '6/28/2020',
        //   'creationDate': '7/11/2020',
        //   'creationTime': '9:15:28 PM',
        //   'intervalHours': 8,
        //   'intervalMins': 0,
        //   'leaveType': 'Continuous',
        //   'reason': '',
        //   'status': 'Approved',
        //   'span': 'Full Requested Day',
        //   'denialReason': '',
        //   'workScheduleStart': '9:00 AM',
        //   'workScheduleEnd': '5:00 PM',
        //   'eligibilityStatus': 'Eligible',
        //     'isOutOfWorkSchedule': true,
        //     'requestId': '124514'
        // }, {
        //   'dateOfAbsence': '6/28/2020',
        //   'creationDate': '7/11/2020',
        //   'creationTime': '9:15:28 PM',
        //   'intervalHours': 8,
        //   'intervalMins': 0,
        //   'leaveType': 'Continuous',
        //   'reason': '',
        //   'status': 'Approved',
        //   'span': 'Full Requested Day',
        //   'denialReason': '',
        //   'workScheduleStart': '9:00 AM',
        //   'workScheduleEnd': '5:00 PM',
        //   'eligibilityStatus': 'Eligible',
        //     'isOutOfWorkSchedule': false,
        //     'requestId': '124514'
        // }, {
        //   'dateOfAbsence': '7/1/2020',
        //   'creationDate': '7/11/2020',
        //   'creationTime': '9:15:28 PM',
        //   'intervalHours': 8,
        //   'intervalMins': 0,
        //   'leaveType': 'Continuous',
        //   'reason': '',
        //   'status': 'denied',
        //   'span': 'Full Requested Day',
        //   'denialReason': '',
        //   'workScheduleStart': '9:00 AM',
        //   'workScheduleEnd': '5:00 PM',
        //   'eligibilityStatus': 'Eligible',
        //     'isOutOfWorkSchedule': false,
        //     'requestId': '124514'
        // }, {
        //   'dateOfAbsence': '7/1/2020',
        //   'creationDate': '7/11/2020',
        //   'creationTime': '9:15:28 PM',
        //   'intervalHours': 8,
        //   'intervalMins': 0,
        //   'leaveType': 'Continuous',
        //   'reason': '',
        //   'status': 'Approved',
        //   'span': 'Full Requested Day',
        //   'denialReason': '',
        //   'workScheduleStart': '9:00 AM',
        //   'workScheduleEnd': '5:00 PM',
        //   'eligibilityStatus': 'Eligible',
        //     'isOutOfWorkSchedule': false,
        //     'requestId': '124514'
        // }, {
        //   'dateOfAbsence': '7/2/2020',
        //   'creationDate': '7/11/2020',
        //   'creationTime': '9:15:28 PM',
        //   'intervalHours': 8,
        //   'intervalMins': 0,
        //   'leaveType': 'Continuous',
        //   'reason': '',
        //   'status': 'Approved',
        //   'span': 'Full Requested Day',
        //   'denialReason': '',
        //   'workScheduleStart': '9:00 AM',
        //   'workScheduleEnd': '5:00 PM',
        //   'eligibilityStatus': 'Eligible',
        //     'isOutOfWorkSchedule': false,
        //     'requestId': '124514'
        // }, {
        //   'dateOfAbsence': '7/2/2020',
        //   'creationDate': '7/11/2020',
        //   'creationTime': '9:15:28 PM',
        //   'intervalHours': 8,
        //   'intervalMins': 0,
        //   'leaveType': 'Continuous',
        //   'reason': '',
        //   'status': 'pended',
        //   'span': 'Full Requested Day',
        //   'denialReason': '',
        //   'workScheduleStart': '9:00 AM',
        //   'workScheduleEnd': '5:00 PM',
        //   'eligibilityStatus': 'Eligible',
        //     'isOutOfWorkSchedule': false,
        //     'requestId': '124514'
        // }, {
        //   'dateOfAbsence': '7/3/2020',
        //   'creationDate': '7/11/2020',
        //   'creationTime': '9:15:28 PM',
        //   'intervalHours': 8,
        //   'intervalMins': 0,
        //   'leaveType': 'Continuous',
        //   'reason': '',
        //   'status': 'Approved',
        //   'span': 'Full Requested Day',
        //   'denialReason': '',
        //   'workScheduleStart': '9:00 AM',
        //   'workScheduleEnd': '5:00 PM',
        //   'eligibilityStatus': 'Eligible',
        //     'isOutOfWorkSchedule': false,
        //     'requestId': '124514'
        // },
        //   {
        //   'dateOfAbsence': '7/8/2020',
        //   'creationDate': '7/11/2020',
        //   'creationTime': '9:15:28 PM',
        //   'intervalHours': 8,
        //   'intervalMins': 0,
        //   'leaveType': 'Continuous',
        //   'reason': '',
        //   'status': 'Approved',
        //   'span': 'Full Requested Day',
        //   'denialReason': '',
        //   'workScheduleStart': '9:00 AM',
        //   'workScheduleEnd': '5:00 PM',
        //   'eligibilityStatus': 'Eligible',
        //     'isOutOfWorkSchedule': false,
        //     'requestId': '124514'
        // }, {
        //   'dateOfAbsence': '7/9/2020',
        //   'creationDate': '7/11/2020',
        //   'creationTime': '9:15:28 PM',
        //   'intervalHours': 8,
        //   'intervalMins': 0,
        //   'leaveType': 'Continuous',
        //   'reason': '',
        //   'status': 'denied',
        //   'span': 'Full Requested Day',
        //   'denialReason': '',
        //   'workScheduleStart': '9:00 AM',
        //   'workScheduleEnd': '5:00 PM',
        //   'eligibilityStatus': 'Eligible',
        //     'isOutOfWorkSchedule': false,
        //     'requestId': '124514'
        // }, {
        //   'dateOfAbsence': '7/9/2020',
        //   'creationDate': '7/11/2020',
        //   'creationTime': '9:15:28 PM',
        //   'intervalHours': 8,
        //   'intervalMins': 0,
        //   'leaveType': 'Continuous',
        //   'reason': '',
        //   'status': 'pended',
        //   'span': 'Full Requested Day',
        //   'denialReason': '',
        //   'workScheduleStart': '9:00 AM',
        //   'workScheduleEnd': '5:00 PM',
        //   'eligibilityStatus': 'Eligible',
        //     'isOutOfWorkSchedule': false,
        //     'requestId': '124514'
        // }, {
        //   'dateOfAbsence': '7/10/2020',
        //   'creationDate': '7/11/2020',
        //   'creationTime': '9:15:28 PM',
        //   'intervalHours': 8,
        //   'intervalMins': 0,
        //   'leaveType': 'Continuous',
        //   'reason': '',
        //   'status': 'Approved',
        //   'span': 'Full Requested Day',
        //   'denialReason': '',
        //   'workScheduleStart': '9:00 AM',
        //   'workScheduleEnd': '5:00 PM',
        //   'eligibilityStatus': 'Eligible',
        //     'isOutOfWorkSchedule': false,
        //     'requestId': '124514'
        // }, {
        //   'dateOfAbsence': '7/10/2020',
        //   'creationDate': '7/11/2020',
        //   'creationTime': '9:15:28 PM',
        //   'intervalHours': 8,
        //   'intervalMins': 0,
        //   'leaveType': 'Continuous',
        //   'reason': '',
        //   'status': 'denied',
        //   'span': 'Full Requested Day',
        //   'denialReason': '',
        //   'workScheduleStart': '9:00 AM',
        //   'workScheduleEnd': '5:00 PM',
        //   'eligibilityStatus': 'Eligible',
        //     'isOutOfWorkSchedule': false,
        //     'requestId': '124514'
        // },
        //   {
        //     'dateOfAbsence': '7/10/2020',
        //     'creationDate': '7/11/2020',
        //     'creationTime': '9:15:28 PM',
        //     'intervalHours': 8,
        //     'intervalMins': 0,
        //     'leaveType': 'Continuous',
        //     'reason': '',
        //     'status': 'pended',
        //     'span': 'Full Requested Day',
        //     'denialReason': '',
        //     'workScheduleStart': '9:00 AM',
        //     'workScheduleEnd': '5:00 PM',
        //     'eligibilityStatus': 'Eligible',
        //     'isOutOfWorkSchedule': false,
        //     'requestId': '124514'
        //  },
        // {
        //   'dateOfAbsence': '7/11/2020',
        //   'creationDate': '7/11/2020',
        //   'creationTime': '9:15:28 PM',
        //   'intervalHours': 8,
        //   'intervalMins': 0,
        //   'leaveType': 'Continuous',
        //   'reason': '',
        //   'status': 'Approved',
        //   'span': 'Full Requested Day',
        //   'denialReason': '',
        //   'workScheduleStart': '9:00 AM',
        //   'workScheduleEnd': '5:00 PM',
        //   'eligibilityStatus': 'Eligible'
        // }, {
        //   'dateOfAbsence': '7/11/2020',
        //   'creationDate': '7/11/2020',
        //   'creationTime': '9:15:28 PM',
        //   'intervalHours': 8,
        //   'intervalMins': 0,
        //   'leaveType': 'Continuous',
        //   'reason': '',
        //   'status': 'Approved',
        //   'span': 'Full Requested Day',
        //   'denialReason': '',
        //   'workScheduleStart': '9:00 AM',
        //   'workScheduleEnd': '5:00 PM',
        //   'eligibilityStatus': 'Eligible'
        // }, {
        //   'dateOfAbsence': '7/12/2020',
        //   'creationDate': '7/11/2020',
        //   'creationTime': '9:15:28 PM',
        //   'intervalHours': 8,
        //   'intervalMins': 0,
        //   'leaveType': 'Continuous',
        //   'reason': '',
        //   'status': 'Approved',
        //   'span': 'Full Requested Day',
        //   'denialReason': '',
        //   'workScheduleStart': '9:00 AM',
        //   'workScheduleEnd': '5:00 PM',
        //   'eligibilityStatus': 'Eligible'
        // }, {
        //   'dateOfAbsence': '7/12/2020',
        //   'creationDate': '7/11/2020',
        //   'creationTime': '9:15:28 PM',
        //   'intervalHours': 8,
        //   'intervalMins': 0,
        //   'leaveType': 'Continuous',
        //   'reason': '',
        //   'status': 'Approved',
        //   'span': 'Full Requested Day',
        //   'denialReason': '',
        //   'workScheduleStart': '9:00 AM',
        //   'workScheduleEnd': '5:00 PM',
        //   'eligibilityStatus': 'Eligible'
        // }, {
        //   'dateOfAbsence': '7/15/2020',
        //   'creationDate': '7/11/2020',
        //   'creationTime': '9:15:28 PM',
        //   'intervalHours': 8,
        //   'intervalMins': 0,
        //   'leaveType': 'Continuous',
        //   'reason': '',
        //   'status': 'Approved',
        //   'span': 'Full Requested Day',
        //   'denialReason': '',
        //   'workScheduleStart': '9:00 AM',
        //   'workScheduleEnd': '5:00 PM',
        //   'eligibilityStatus': 'Eligible'
        // }, {
        //   'dateOfAbsence': '7/15/2020',
        //   'creationDate': '7/11/2020',
        //   'creationTime': '9:15:28 PM',
        //   'intervalHours': 8,
        //   'intervalMins': 0,
        //   'leaveType': 'Continuous',
        //   'reason': '',
        //   'status': 'Approved',
        //   'span': 'Full Requested Day',
        //   'denialReason': '',
        //   'workScheduleStart': '9:00 AM',
        //   'workScheduleEnd': '5:00 PM',
        //   'eligibilityStatus': 'Eligible'
        // }, {
        //   'dateOfAbsence': '7/16/2020',
        //   'creationDate': '7/11/2020',
        //   'creationTime': '9:15:28 PM',
        //   'intervalHours': 8,
        //   'intervalMins': 0,
        //   'leaveType': 'Continuous',
        //   'reason': '',
        //   'status': 'Approved',
        //   'span': 'Full Requested Day',
        //   'denialReason': '',
        //   'workScheduleStart': '9:00 AM',
        //   'workScheduleEnd': '5:00 PM',
        //   'eligibilityStatus': 'Eligible'
        // }, {
        //   'dateOfAbsence': '7/16/2020',
        //   'creationDate': '7/11/2020',
        //   'creationTime': '9:15:28 PM',
        //   'intervalHours': 8,
        //   'intervalMins': 0,
        //   'leaveType': 'Continuous',
        //   'reason': '',
        //   'status': 'Approved',
        //   'span': 'Full Requested Day',
        //   'denialReason': '',
        //   'workScheduleStart': '9:00 AM',
        //   'workScheduleEnd': '5:00 PM',
        //   'eligibilityStatus': 'Eligible'
        // }, {
        //   'dateOfAbsence': '7/17/2020',
        //   'creationDate': '7/11/2020',
        //   'creationTime': '9:15:28 PM',
        //   'intervalHours': 8,
        //   'intervalMins': 0,
        //   'leaveType': 'Continuous',
        //   'reason': '',
        //   'status': 'Approved',
        //   'span': 'Full Requested Day',
        //   'denialReason': '',
        //   'workScheduleStart': '9:00 AM',
        //   'workScheduleEnd': '5:00 PM',
        //   'eligibilityStatus': 'Eligible'
        // }, {
        //   'dateOfAbsence': '7/17/2020',
        //   'creationDate': '7/11/2020',
        //   'creationTime': '9:15:28 PM',
        //   'intervalHours': 8,
        //   'intervalMins': 0,
        //   'leaveType': 'Continuous',
        //   'reason': '',
        //   'status': 'Approved',
        //   'span': 'Full Requested Day',
        //   'denialReason': '',
        //   'workScheduleStart': '9:00 AM',
        //   'workScheduleEnd': '5:00 PM',
        //   'eligibilityStatus': 'Eligible'
        // }, {
        //   'dateOfAbsence': '7/18/2020',
        //   'creationDate': '7/11/2020',
        //   'creationTime': '9:15:28 PM',
        //   'intervalHours': 8,
        //   'intervalMins': 0,
        //   'leaveType': 'Continuous',
        //   'reason': '',
        //   'status': 'Approved',
        //   'span': 'Full Requested Day',
        //   'denialReason': '',
        //   'workScheduleStart': '9:00 AM',
        //   'workScheduleEnd': '5:00 PM',
        //   'eligibilityStatus': 'Eligible'
        // }, {
        //   'dateOfAbsence': '7/18/2020',
        //   'creationDate': '7/11/2020',
        //   'creationTime': '9:15:28 PM',
        //   'intervalHours': 8,
        //   'intervalMins': 0,
        //   'leaveType': 'Continuous',
        //   'reason': '',
        //   'status': 'Approved',
        //   'span': 'Full Requested Day',
        //   'denialReason': '',
        //   'workScheduleStart': '9:00 AM',
        //   'workScheduleEnd': '5:00 PM',
        //   'eligibilityStatus': 'Eligible'
        // }, {
        //   'dateOfAbsence': '7/19/2020',
        //   'creationDate': '7/11/2020',
        //   'creationTime': '9:15:28 PM',
        //   'intervalHours': 8,
        //   'intervalMins': 0,
        //   'leaveType': 'Continuous',
        //   'reason': '',
        //   'status': 'Approved',
        //   'span': 'Full Requested Day',
        //   'denialReason': '',
        //   'workScheduleStart': '9:00 AM',
        //   'workScheduleEnd': '5:00 PM',
        //   'eligibilityStatus': 'Eligible'
        // }, {
        //   'dateOfAbsence': '7/19/2020',
        //   'creationDate': '7/11/2020',
        //   'creationTime': '9:15:28 PM',
        //   'intervalHours': 8,
        //   'intervalMins': 0,
        //   'leaveType': 'Continuous',
        //   'reason': '',
        //   'status': 'Approved',
        //   'span': 'Full Requested Day',
        //   'denialReason': '',
        //   'workScheduleStart': '9:00 AM',
        //   'workScheduleEnd': '5:00 PM',
        //   'eligibilityStatus': 'Eligible'
        // }, {
        //   'dateOfAbsence': '7/22/2020',
        //   'creationDate': '7/11/2020',
        //   'creationTime': '9:15:28 PM',
        //   'intervalHours': 8,
        //   'intervalMins': 0,
        //   'leaveType': 'Continuous',
        //   'reason': '',
        //   'status': 'Approved',
        //   'span': 'Full Requested Day',
        //   'denialReason': '',
        //   'workScheduleStart': '9:00 AM',
        //   'workScheduleEnd': '5:00 PM',
        //   'eligibilityStatus': 'Eligible'
        // }, {
        //   'dateOfAbsence': '7/22/2020',
        //   'creationDate': '7/11/2020',
        //   'creationTime': '9:15:28 PM',
        //   'intervalHours': 8,
        //   'intervalMins': 0,
        //   'leaveType': 'Continuous',
        //   'reason': '',
        //   'status': 'Approved',
        //   'span': 'Full Requested Day',
        //   'denialReason': '',
        //   'workScheduleStart': '9:00 AM',
        //   'workScheduleEnd': '5:00 PM',
        //   'eligibilityStatus': 'Eligible'
        // }, {
        //   'dateOfAbsence': '7/23/2020',
        //   'creationDate': '7/11/2020',
        //   'creationTime': '9:15:28 PM',
        //   'intervalHours': 8,
        //   'intervalMins': 0,
        //   'leaveType': 'Continuous',
        //   'reason': '',
        //   'status': 'Approved',
        //   'span': 'Full Requested Day',
        //   'denialReason': '',
        //   'workScheduleStart': '9:00 AM',
        //   'workScheduleEnd': '5:00 PM',
        //   'eligibilityStatus': 'Eligible'
        // }, {
        //   'dateOfAbsence': '7/23/2020',
        //   'creationDate': '7/11/2020',
        //   'creationTime': '9:15:28 PM',
        //   'intervalHours': 8,
        //   'intervalMins': 0,
        //   'leaveType': 'Continuous',
        //   'reason': '',
        //   'status': 'Approved',
        //   'span': 'Full Requested Day',
        //   'denialReason': '',
        //   'workScheduleStart': '9:00 AM',
        //   'workScheduleEnd': '5:00 PM',
        //   'eligibilityStatus': 'Eligible'
        // }, {
        //   'dateOfAbsence': '7/24/2020',
        //   'creationDate': '7/11/2020',
        //   'creationTime': '9:15:28 PM',
        //   'intervalHours': 8,
        //   'intervalMins': 0,
        //   'leaveType': 'Continuous',
        //   'reason': '',
        //   'status': 'Approved',
        //   'span': 'Full Requested Day',
        //   'denialReason': '',
        //   'workScheduleStart': '9:00 AM',
        //   'workScheduleEnd': '5:00 PM',
        //   'eligibilityStatus': 'Eligible'
        // }, {
        //   'dateOfAbsence': '7/24/2020',
        //   'creationDate': '7/11/2020',
        //   'creationTime': '9:15:28 PM',
        //   'intervalHours': 8,
        //   'intervalMins': 0,
        //   'leaveType': 'Continuous',
        //   'reason': '',
        //   'status': 'Approved',
        //   'span': 'Full Requested Day',
        //   'denialReason': '',
        //   'workScheduleStart': '9:00 AM',
        //   'workScheduleEnd': '5:00 PM',
        //   'eligibilityStatus': 'Eligible'
        // }, {
        //   'dateOfAbsence': '7/25/2020',
        //   'creationDate': '7/11/2020',
        //   'creationTime': '9:15:28 PM',
        //   'intervalHours': 8,
        //   'intervalMins': 0,
        //   'leaveType': 'Continuous',
        //   'reason': '',
        //   'status': 'Approved',
        //   'span': 'Full Requested Day',
        //   'denialReason': '',
        //   'workScheduleStart': '9:00 AM',
        //   'workScheduleEnd': '5:00 PM',
        //   'eligibilityStatus': 'Eligible'
        // }, {
        //   'dateOfAbsence': '7/25/2020',
        //   'creationDate': '7/11/2020',
        //   'creationTime': '9:15:28 PM',
        //   'intervalHours': 8,
        //   'intervalMins': 0,
        //   'leaveType': 'Continuous',
        //   'reason': '',
        //   'status': 'Approved',
        //   'span': 'Full Requested Day',
        //   'denialReason': '',
        //   'workScheduleStart': '9:00 AM',
        //   'workScheduleEnd': '5:00 PM',
        //   'eligibilityStatus': 'Eligible'
        // }, {
        //   'dateOfAbsence': '7/26/2020',
        //   'creationDate': '7/11/2020',
        //   'creationTime': '9:15:28 PM',
        //   'intervalHours': 8,
        //   'intervalMins': 0,
        //   'leaveType': 'Continuous',
        //   'reason': '',
        //   'status': 'Approved',
        //   'span': 'Full Requested Day',
        //   'denialReason': '',
        //   'workScheduleStart': '9:00 AM',
        //   'workScheduleEnd': '5:00 PM',
        //   'eligibilityStatus': 'Eligible'
        // }, {
        //   'dateOfAbsence': '7/26/2020',
        //   'creationDate': '7/11/2020',
        //   'creationTime': '9:15:28 PM',
        //   'intervalHours': 8,
        //   'intervalMins': 0,
        //   'leaveType': 'Continuous',
        //   'reason': '',
        //   'status': 'Approved',
        //   'span': 'Full Requested Day',
        //   'denialReason': '',
        //   'workScheduleStart': '9:00 AM',
        //   'workScheduleEnd': '5:00 PM',
        //   'eligibilityStatus': 'Eligible'
        // }, {
        //   'dateOfAbsence': '7/29/2020',
        //   'creationDate': '7/11/2020',
        //   'creationTime': '9:15:28 PM',
        //   'intervalHours': 8,
        //   'intervalMins': 0,
        //   'leaveType': 'Continuous',
        //   'reason': '',
        //   'status': 'Approved',
        //   'span': 'Full Requested Day',
        //   'denialReason': '',
        //   'workScheduleStart': '9:00 AM',
        //   'workScheduleEnd': '5:00 PM',
        //   'eligibilityStatus': 'Eligible'
        // }, {
        //   'dateOfAbsence': '7/29/2020',
        //   'creationDate': '7/11/2020',
        //   'creationTime': '9:15:28 PM',
        //   'intervalHours': 8,
        //   'intervalMins': 0,
        //   'leaveType': 'Continuous',
        //   'reason': '',
        //   'status': 'Approved',
        //   'span': 'Full Requested Day',
        //   'denialReason': '',
        //   'workScheduleStart': '9:00 AM',
        //   'workScheduleEnd': '5:00 PM',
        //   'eligibilityStatus': 'Eligible'
        // }, {
        //   'dateOfAbsence': '7/30/2020',
        //   'creationDate': '7/11/2020',
        //   'creationTime': '9:15:28 PM',
        //   'intervalHours': 8,
        //   'intervalMins': 0,
        //   'leaveType': 'Continuous',
        //   'reason': '',
        //   'status': 'Approved',
        //   'span': 'Full Requested Day',
        //   'denialReason': '',
        //   'workScheduleStart': '9:00 AM',
        //   'workScheduleEnd': '5:00 PM',
        //   'eligibilityStatus': 'Eligible'
        // }, {
        //   'dateOfAbsence': '7/30/2020',
        //   'creationDate': '7/11/2020',
        //   'creationTime': '9:15:28 PM',
        //   'intervalHours': 8,
        //   'intervalMins': 0,
        //   'leaveType': 'Continuous',
        //   'reason': '',
        //   'status': 'Approved',
        //   'span': 'Full Requested Day',
        //   'denialReason': '',
        //   'workScheduleStart': '9:00 AM',
        //   'workScheduleEnd': '5:00 PM',
        //   'eligibilityStatus': 'Eligible'
        // }, {
        //   'dateOfAbsence': '7/31/2020',
        //   'creationDate': '7/11/2020',
        //   'creationTime': '9:15:28 PM',
        //   'intervalHours': 8,
        //   'intervalMins': 0,
        //   'leaveType': 'Continuous',
        //   'reason': '',
        //   'status': 'Approved',
        //   'span': 'Full Requested Day',
        //   'denialReason': '',
        //   'workScheduleStart': '9:00 AM',
        //   'workScheduleEnd': '5:00 PM',
        //   'eligibilityStatus': 'Eligible'
        // }, {
        //   'dateOfAbsence': '7/31/2020',
        //   'creationDate': '7/11/2020',
        //   'creationTime': '9:15:28 PM',
        //   'intervalHours': 8,
        //   'intervalMins': 0,
        //   'leaveType': 'Continuous',
        //   'reason': '',
        //   'status': 'Approved',
        //   'span': 'Full Requested Day',
        //   'denialReason': '',
        //   'workScheduleStart': '9:00 AM',
        //   'workScheduleEnd': '5:00 PM',
        //   'eligibilityStatus': 'Eligible'
        // },
        //   {
        //   'dateOfAbsence': '8/1/2020',
        //   'creationDate': '7/11/2020',
        //   'creationTime': '9:15:28 PM',
        //   'intervalHours': 8,
        //   'intervalMins': 0,
        //   'leaveType': 'Continuous',
        //   'reason': '',
        //   'status': 'Approved',
        //   'span': 'Full Requested Day',
        //   'denialReason': '',
        //   'workScheduleStart': '9:00 AM',
        //   'workScheduleEnd': '5:00 PM',
        //   'eligibilityStatus': 'Eligible',
        //     'isOutOfWorkSchedule': false,
        //     'requestId': '124518'
        // }, {
        //   'dateOfAbsence': '8/1/2020',
        //   'creationDate': '7/11/2020',
        //   'creationTime': '9:15:28 PM',
        //   'intervalHours': 8,
        //   'intervalMins': 0,
        //   'leaveType': 'Continuous',
        //   'reason': '',
        //   'status': 'Approved',
        //   'span': 'Full Requested Day',
        //   'denialReason': '',
        //   'workScheduleStart': '9:00 AM',
        //   'workScheduleEnd': '5:00 PM',
        //   'eligibilityStatus': 'Eligible',
        //     'isOutOfWorkSchedule': false,
        //     'requestId': '124518'
        // }, {
        //   'dateOfAbsence': '8/2/2020',
        //   'creationDate': '7/11/2020',
        //   'creationTime': '9:15:28 PM',
        //   'intervalHours': 8,
        //   'intervalMins': 10,
        //   'leaveType': 'Continuous',
        //   'reason': '',
        //   'status': 'Approved',
        //   'span': 'Full Requested Day',
        //   'denialReason': '',
        //   'workScheduleStart': '9:00 AM',
        //   'workScheduleEnd': '5:00 PM',
        //   'eligibilityStatus': 'Eligible',
        //     'isOutOfWorkSchedule': false,
        //     'requestId': '124518'
        // }, {
        //   'dateOfAbsence': '8/2/2020',
        //   'creationDate': '7/11/2020',
        //   'creationTime': '9:15:28 PM',
        //   'intervalHours': 8,
        //   'intervalMins': 5,
        //   'leaveType': 'Continuous',
        //   'reason': '',
        //   'status': 'Approved',
        //   'span': 'Full Requested Day',
        //   'denialReason': '',
        //   'workScheduleStart': '9:00 AM',
        //   'workScheduleEnd': '5:00 PM',
        //   'eligibilityStatus': 'Eligible',
        //     'isOutOfWorkSchedule': false,
        //     'requestId': '124518'
        // }, {
        //   'dateOfAbsence': '8/5/2020',
        //   'creationDate': '7/11/2020',
        //   'creationTime': '9:15:28 PM',
        //   'intervalHours': 8,
        //   'intervalMins': 0,
        //   'leaveType': 'Continuous',
        //   'reason': '',
        //   'status': 'Approved',
        //   'span': 'Full Requested Day',
        //   'denialReason': '',
        //   'workScheduleStart': '9:00 AM',
        //   'workScheduleEnd': '5:00 PM',
        //   'eligibilityStatus': 'Eligible',
        //     'isOutOfWorkSchedule': false,
        //     'requestId': '124518'
        // }, {
        //   'dateOfAbsence': '8/5/2020',
        //   'creationDate': '7/11/2020',
        //   'creationTime': '9:15:28 PM',
        //   'intervalHours': 8,
        //   'intervalMins': 0,
        //   'leaveType': 'Continuous',
        //   'reason': '',
        //   'status': 'Approved',
        //   'span': 'Full Requested Day',
        //   'denialReason': '',
        //   'workScheduleStart': '9:00 AM',
        //   'workScheduleEnd': '5:00 PM',
        //   'eligibilityStatus': 'Eligible',
        //     'isOutOfWorkSchedule': false,
        //     'requestId': '124518'
        // }, {
        //   'dateOfAbsence': '8/6/2020',
        //   'creationDate': '7/11/2020',
        //   'creationTime': '9:15:28 PM',
        //   'intervalHours': 8,
        //   'intervalMins': 0,
        //   'leaveType': 'Continuous',
        //   'reason': '',
        //   'status': 'Approved',
        //   'span': 'Full Requested Day',
        //   'denialReason': '',
        //   'workScheduleStart': '9:00 AM',
        //   'workScheduleEnd': '5:00 PM',
        //   'eligibilityStatus': 'Eligible',
        //     'isOutOfWorkSchedule': false,
        //     'requestId': '124518'
        // }, {
        //   'dateOfAbsence': '8/6/2020',
        //   'creationDate': '7/11/2020',
        //   'creationTime': '9:15:28 PM',
        //   'intervalHours': 8,
        //   'intervalMins': 0,
        //   'leaveType': 'Continuous',
        //   'reason': '',
        //   'status': 'Approved',
        //   'span': 'Full Requested Day',
        //   'denialReason': '',
        //   'workScheduleStart': '9:00 AM',
        //   'workScheduleEnd': '5:00 PM',
        //   'eligibilityStatus': 'Eligible',
        //     'isOutOfWorkSchedule': false,
        //     'requestId': '124518'
        // }, {
        //   'dateOfAbsence': '8/7/2020',
        //   'creationDate': '7/11/2020',
        //   'creationTime': '9:15:28 PM',
        //   'intervalHours': 8,
        //   'intervalMins': 0,
        //   'leaveType': 'Continuous',
        //   'reason': '',
        //   'status': 'Approved',
        //   'span': 'Full Requested Day',
        //   'denialReason': '',
        //   'workScheduleStart': '9:00 AM',
        //   'workScheduleEnd': '5:00 PM',
        //   'eligibilityStatus': 'Eligible',
        //     'isOutOfWorkSchedule': false,
        //     'requestId': '124518'
        // }, {
        //   'dateOfAbsence': '8/7/2020',
        //   'creationDate': '7/11/2020',
        //   'creationTime': '9:15:28 PM',
        //   'intervalHours': 8,
        //   'intervalMins': 0,
        //   'leaveType': 'Continuous',
        //   'reason': '',
        //   'status': 'Approved',
        //   'span': 'Full Requested Day',
        //   'denialReason': '',
        //   'workScheduleStart': '9:00 AM',
        //   'workScheduleEnd': '5:00 PM',
        //   'eligibilityStatus': 'Eligible',
        //     'isOutOfWorkSchedule': false,
        //     'requestId': '124518'
        // }, {
        //   'dateOfAbsence': '8/8/2020',
        //   'creationDate': '7/11/2020',
        //   'creationTime': '9:15:28 PM',
        //   'intervalHours': 8,
        //   'intervalMins': 0,
        //   'leaveType': 'Continuous',
        //   'reason': '',
        //   'status': 'Approved',
        //   'span': 'Full Requested Day',
        //   'denialReason': '',
        //   'workScheduleStart': '9:00 AM',
        //   'workScheduleEnd': '5:00 PM',
        //   'eligibilityStatus': 'Eligible',
        //     'isOutOfWorkSchedule': false,
        //     'requestId': '124518'
        // }, {
        //   'dateOfAbsence': '8/8/2020',
        //   'creationDate': '7/11/2020',
        //   'creationTime': '9:15:28 PM',
        //   'intervalHours': 8,
        //   'intervalMins': 0,
        //   'leaveType': 'Continuous',
        //   'reason': '',
        //   'status': 'Approved',
        //   'span': 'Full Requested Day',
        //   'denialReason': '',
        //   'workScheduleStart': '9:00 AM',
        //   'workScheduleEnd': '5:00 PM',
        //   'eligibilityStatus': 'Eligible',
        //     'isOutOfWorkSchedule': false,
        //     'requestId': '124518'
        // }, {
        //   'dateOfAbsence': '8/9/2020',
        //   'creationDate': '7/11/2020',
        //   'creationTime': '9:15:28 PM',
        //   'intervalHours': 8,
        //   'intervalMins': 0,
        //   'leaveType': 'Continuous',
        //   'reason': '',
        //   'status': 'Approved',
        //   'span': 'Full Requested Day',
        //   'denialReason': '',
        //   'workScheduleStart': '9:00 AM',
        //   'workScheduleEnd': '5:00 PM',
        //   'eligibilityStatus': 'Eligible',
        //     'isOutOfWorkSchedule': false,
        //     'requestId': '124518'
        // }, {
        //   'dateOfAbsence': '8/9/2020',
        //   'creationDate': '7/11/2020',
        //   'creationTime': '9:15:28 PM',
        //   'intervalHours': 8,
        //   'intervalMins': 0,
        //   'leaveType': 'Continuous',
        //   'reason': '',
        //   'status': 'Approved',
        //   'span': 'Full Requested Day',
        //   'denialReason': '',
        //   'workScheduleStart': '9:00 AM',
        //   'workScheduleEnd': '5:00 PM',
        //   'eligibilityStatus': 'Eligible',
        //     'isOutOfWorkSchedule': false,
        //     'requestId': '124518'
        // }, {
        //   'dateOfAbsence': '8/12/2020',
        //   'creationDate': '7/11/2020',
        //   'creationTime': '9:15:28 PM',
        //   'intervalHours': 8,
        //   'intervalMins': 0,
        //   'leaveType': 'Continuous',
        //   'reason': '',
        //   'status': 'Approved',
        //   'span': 'Full Requested Day',
        //   'denialReason': '',
        //   'workScheduleStart': '9:00 AM',
        //   'workScheduleEnd': '5:00 PM',
        //   'eligibilityStatus': 'Eligible',
        //     'isOutOfWorkSchedule': false,
        //     'requestId': '124518'
        // }, {
        //   'dateOfAbsence': '8/12/2020',
        //   'creationDate': '7/11/2020',
        //   'creationTime': '9:15:28 PM',
        //   'intervalHours': 8,
        //   'intervalMins': 0,
        //   'leaveType': 'Continuous',
        //   'reason': '',
        //   'status': 'Approved',
        //   'span': 'Full Requested Day',
        //   'denialReason': '',
        //   'workScheduleStart': '9:00 AM',
        //   'workScheduleEnd': '5:00 PM',
        //   'eligibilityStatus': 'Eligible',
        //     'isOutOfWorkSchedule': false,
        //     'requestId': '124518'
        // }, {
        //   'dateOfAbsence': '8/13/2020',
        //   'creationDate': '7/11/2020',
        //   'creationTime': '9:15:28 PM',
        //   'intervalHours': 8,
        //   'intervalMins': 0,
        //   'leaveType': 'Continuous',
        //   'reason': '',
        //   'status': 'Approved',
        //   'span': 'Full Requested Day',
        //   'denialReason': '',
        //   'workScheduleStart': '9:00 AM',
        //   'workScheduleEnd': '5:00 PM',
        //   'eligibilityStatus': 'Eligible',
        //     'isOutOfWorkSchedule': false,
        //     'requestId': '124518'
        // }, {
        //   'dateOfAbsence': '8/13/2020',
        //   'creationDate': '7/11/2020',
        //   'creationTime': '9:15:28 PM',
        //   'intervalHours': 8,
        //   'intervalMins': 0,
        //   'leaveType': 'Continuous',
        //   'reason': '',
        //   'status': 'Approved',
        //   'span': 'Full Requested Day',
        //   'denialReason': '',
        //   'workScheduleStart': '9:00 AM',
        //   'workScheduleEnd': '5:00 PM',
        //   'eligibilityStatus': 'Eligible',
        //     'isOutOfWorkSchedule': false,
        //     'requestId': '124518'
        // }, {
        //   'dateOfAbsence': '8/20/2020',
        //   'creationDate': '4/25/2020',
        //   'creationTime': '7:54:58 AM',
        //   'intervalHours': 8,
        //   'intervalMins': 0,
        //   'leaveType': 'Continuous',
        //   'reason': '',
        //   'status': 'Approved',
        //   'span': 'Full Requested Day',
        //   'denialReason': '',
        //   'workScheduleStart': '9:00 AM',
        //   'workScheduleEnd': '5:00 PM',
        //   'eligibilityStatus': 'Eligible',
        //     'isOutOfWorkSchedule': false,
        //     'requestId': '124518'
        // }, {
        //   'dateOfAbsence': '8/20/2020',
        //   'creationDate': '4/25/2020',
        //   'creationTime': '7:54:58 AM',
        //   'intervalHours': 8,
        //   'intervalMins': 0,
        //   'leaveType': 'Continuous',
        //   'reason': '',
        //   'status': 'Approved',
        //   'span': 'Full Requested Day',
        //   'denialReason': '',
        //   'workScheduleStart': '9:00 AM',
        //   'workScheduleEnd': '5:00 PM',
        //   'eligibilityStatus': 'Eligible',
        //     'isOutOfWorkSchedule': false,
        //     'requestId': '124518'
        // }, {
        //   'dateOfAbsence': '8/21/2020',
        //   'creationDate': '4/25/2020',
        //   'creationTime': '7:54:58 AM',
        //   'intervalHours': 8,
        //   'intervalMins': 0,
        //   'leaveType': 'Continuous',
        //   'reason': '',
        //   'status': 'Approved',
        //   'span': 'Full Requested Day',
        //   'denialReason': '',
        //   'workScheduleStart': '9:00 AM',
        //   'workScheduleEnd': '5:00 PM',
        //   'eligibilityStatus': 'Eligible',
        //     'isOutOfWorkSchedule': false,
        //     'requestId': '124518'
        // },
        //   {
        //   'dateOfAbsence': '8/21/2020',
        //   'creationDate': '4/25/2020',
        //   'creationTime': '7:54:58 AM',
        //   'intervalHours': 8,
        //   'intervalMins': 0,
        //   'leaveType': 'Continuous',
        //   'reason': '',
        //   'status': 'Approved',
        //   'span': 'Full Requested Day',
        //   'denialReason': '',
        //   'workScheduleStart': '9:00 AM',
        //   'workScheduleEnd': '5:00 PM',
        //   'eligibilityStatus': 'Eligible',
        //     'isOutOfWorkSchedule': false,
        //     'requestId': '124518'
        // }
      ]
    },
    {
      leaveId: '200001',
      absenceList: [{
        dateOfAbsence: '8/27/2020',
        creationDate: '4/25/2020',
        creationTime: '11:05:04 AM',
        intervalHours: 6,
        intervalMins: 0,
        leaveType: 'Reduced Schedule',
        reason: '',
        status: 'Approved',
        span: 'Partial Requested Day',
        denialReason: '',
        workScheduleStart: '9:00 AM',
        workScheduleEnd: '5:00 PM',
        eligibilityStatus: 'Undetermined',
        isOutOfWorkSchedule: false,
        requestId: '1245001',
        overAllStatus: 'Approved',
        checkingInProgress: true,
        isEligible: false,
        startTime: '09:00 AM',
        regulationTypeName: 'Federal Family and Medical Leave Act (FMLA)'
      }, {
        dateOfAbsence: '8/27/2020',
        creationDate: '4/25/2020',
        creationTime: '11:05:04 AM',
        intervalHours: 6,
        intervalMins: 0,
        leaveType: 'Reduced Schedule',
        reason: '',
        status: 'Inactive',
        span: 'Partial Requested Day',
        denialReason: '',
        workScheduleStart: '9:00 AM',
        workScheduleEnd: '5:00 PM',
        eligibilityStatus: 'Eligible',
        isOutOfWorkSchedule: false,
        requestId: '1245001',
        overAllStatus: 'Approved',
        checkingInProgress: true,
        isEligible: false,
        startTime: '09:00 AM',
        regulationTypeName: 'Federal Family and Medical Leave Act (FMLA)'
      }, {
        dateOfAbsence: '8/29/2020',
        creationDate: '4/25/2020',
        creationTime: '11:08:10 AM',
        intervalHours: 7,
        intervalMins: 0,
        leaveType: 'Reduced Schedule',
        reason: '',
        status: 'Inactive',
        span: 'Partial Requested Day',
        denialReason: '',
        workScheduleStart: '9:00 AM',
        workScheduleEnd: '5:00 PM',
        eligibilityStatus: 'Eligible',
        isOutOfWorkSchedule: true,
        requestId: '1245001',
        overAllStatus: 'Approved',
        checkingInProgress: true,
        isEligible: false,
        startTime: '09:00 AM',
        regulationTypeName: 'Federal Family and Medical Leave Act (FMLA)'
      }]
    },
    {
      leaveId: '100011',
      absenceList: [{
        dateOfAbsence: '9/3/2020',
        creationDate: '4/26/2020',
        creationTime: '5:42:38 AM',
        intervalHours: 1,
        intervalMins: 0,
        leaveType: 'Intermittent',
        reason: '',
        status: 'Approved',
        span: 'Partial Requested Day',
        denialReason: '',
        workScheduleStart: '9:00 AM',
        workScheduleEnd: '5:00 PM',
        eligibilityStatus: 'Eligible',
        isOutOfWorkSchedule: false,
        requestId: '124511',
        overAllStatus: 'Approved',
        checkingInProgress: false,
        isEligible: false,
        startTime: '09:00 AM',
        regulationTypeName: 'Federal Family and Medical Leave Act (FMLA)'
      }, {
        dateOfAbsence: '9/9/2020',
        creationDate: '4/26/2020',
        creationTime: '5:44:34 AM',
        intervalHours: 6,
        intervalMins: 0,
        leaveType: 'Reduced Schedule',
        reason: '',
        status: 'Inactive',
        span: 'Partial Requested Day',
        denialReason: '',
        workScheduleStart: '9:00 AM',
        workScheduleEnd: '5:00 PM',
        eligibilityStatus: 'Eligible',
        isOutOfWorkSchedule: false,
        requestId: '124511',
        overAllStatus: 'Approved',
        checkingInProgress: false,
        isEligible: false,
        startTime: '09:00 AM',
        regulationTypeName: 'Federal Family and Medical Leave Act (FMLA)'
      }]
    },
    {
      leaveId: '200002',
      absenceList: [{
        dateOfAbsence: '9/12/2020',
        creationDate: '4/26/2020',
        creationTime: '6:00:41 AM',
        intervalHours: 6,
        intervalMins: 0,
        leaveType: 'Reduced Schedule',
        reason: '',
        status: 'Approved',
        span: 'Partial Requested Day',
        denialReason: '',
        workScheduleStart: '9:00 AM',
        workScheduleEnd: '5:00 PM',
        eligibilityStatus: 'Eligible',
        isOutOfWorkSchedule: true,
        requestId: '124502',
        overAllStatus: 'Approved',
        checkingInProgress: false,
        isEligible: false,
        startTime: '09:00 AM',
        regulationTypeName: 'Federal Family and Medical Leave Act (FMLA)'
      }, {
        dateOfAbsence: '9/12/2020',
        creationDate: '4/26/2020',
        creationTime: '6:00:41 AM',
        intervalHours: 6,
        intervalMins: 0,
        leaveType: 'Reduced Schedule',
        reason: '',
        status: 'Inactive',
        span: 'Partial Requested Day',
        denialReason: '',
        workScheduleStart: '9:00 AM',
        workScheduleEnd: '5:00 PM',
        eligibilityStatus: 'Eligible',
        isOutOfWorkSchedule: false,
        requestId: '124502',
        overAllStatus: 'Approved',
        checkingInProgress: false,
        isEligible: false,
        startTime: '09:00 AM',
        regulationTypeName: 'Federal Family and Medical Leave Act (FMLA)'
      }, {
        dateOfAbsence: '9/19/2020',
        creationDate: '4/26/2020',
        creationTime: '6:02:04 AM',
        intervalHours: 5,
        intervalMins: 0,
        leaveType: 'Reduced Schedule',
        reason: '',
        status: 'Pended',
        span: 'Partial Requested Day',
        denialReason: '',
        workScheduleStart: '9:00 AM',
        workScheduleEnd: '5:00 PM',
        eligibilityStatus: 'Eligible',
        isOutOfWorkSchedule: false,
        requestId: '124502',
        overAllStatus: 'Approved',
        checkingInProgress: false,
        isEligible: false,
        startTime: '09:00 AM',
        regulationTypeName: 'Federal Family and Medical Leave Act (FMLA)'
      }]
    },
    {
      leaveId: '200003',
      absenceList: [{
        dateOfAbsence: '9/12/2020',
        creationDate: '4/26/2020',
        creationTime: '6:00:41 AM',
        intervalHours: 6,
        intervalMins: 10,
        leaveType: 'Reduced Schedule',
        reason: 'denied by employer',
        status: 'Denied',
        span: 'Partial Requested Day',
        denialReason: 'denied by employer',
        workScheduleStart: '9:00 AM',
        workScheduleEnd: '5:00 PM',
        eligibilityStatus: 'Verify',
        isOutOfWorkSchedule: true,
        requestId: '124503',
        overAllStatus: 'Denied',
        checkingInProgress: true,
        isEligible: false,
        startTime: '09:00 AM',
        regulationTypeName: 'Federal Family and Medical Leave Act (FMLA)'
      }, {
        dateOfAbsence: '9/12/2020',
        creationDate: '4/26/2020',
        creationTime: '6:00:41 AM',
        intervalHours: 6,
        intervalMins: 0,
        leaveType: 'Reduced Schedule',
        reason: '',
        status: 'Inactive',
        span: 'Partial Requested Day',
        denialReason: '',
        workScheduleStart: '9:00 AM',
        workScheduleEnd: '5:00 PM',
        eligibilityStatus: 'Eligible',
        isOutOfWorkSchedule: false,
        requestId: '124503',
        overAllStatus: 'Approved',
        checkingInProgress: true,
        isEligible: false,
        startTime: '09:00 AM',
        regulationTypeName: 'Federal Family and Medical Leave Act (FMLA)'
      }, {
        dateOfAbsence: '9/19/2020',
        creationDate: '4/26/2020',
        creationTime: '6:02:04 AM',
        intervalHours: 5,
        intervalMins: 0,
        leaveType: 'Reduced Schedule',
        reason: '',
        status: 'Inactive',
        span: 'Partial Requested Day',
        denialReason: '',
        workScheduleStart: '9:00 AM',
        workScheduleEnd: '5:00 PM',
        eligibilityStatus: 'Eligible',
        isOutOfWorkSchedule: false,
        requestId: '124503',
        overAllStatus: 'Approved',
        checkingInProgress: true,
        isEligible: false,
        startTime: '09:00 AM',
        regulationTypeName: 'Federal Family and Medical Leave Act (FMLA)'
      }]
    },
    {
      leaveId: '200003',
      absenceList: [{
        dateOfAbsence: '9/12/2020',
        creationDate: '4/26/2020',
        creationTime: '6:00:41 AM',
        intervalHours: 6,
        intervalMins: 0,
        leaveType: 'Reduced Schedule',
        reason: '',
        status: 'Approved',
        span: 'Partial Requested Day',
        denialReason: '',
        workScheduleStart: '9:00 AM',
        workScheduleEnd: '5:00 PM',
        eligibilityStatus: 'Eligible',
        isOutOfWorkSchedule: false,
        requestId: '124301',
        overAllStatus: 'Approved',
        checkingInProgress: false,
        isEligible: false,
        startTime: '09:00 AM',
        regulationTypeName: 'Federal Family and Medical Leave Act (FMLA)'
      }, {
        dateOfAbsence: '9/12/2020',
        creationDate: '4/26/2020',
        creationTime: '6:00:41 AM',
        intervalHours: 6,
        intervalMins: 0,
        leaveType: 'Reduced Schedule',
        reason: '',
        status: 'Inactive',
        span: 'Partial Requested Day',
        denialReason: '',
        workScheduleStart: '9:00 AM',
        workScheduleEnd: '5:00 PM',
        eligibilityStatus: 'Eligible',
        isOutOfWorkSchedule: false,
        requestId: '124301',
        overAllStatus: 'Approved',
        checkingInProgress: false,
        isEligible: false,
        startTime: '09:00 AM',
        regulationTypeName: 'Federal Family and Medical Leave Act (FMLA)'
      }, {
        dateOfAbsence: '9/19/2020',
        creationDate: '4/26/2020',
        creationTime: '6:02:04 AM',
        intervalHours: 5,
        intervalMins: 0,
        leaveType: 'Reduced Schedule',
        reason: '',
        status: 'Inactive',
        span: 'Partial Requested Day',
        denialReason: '',
        workScheduleStart: '9:00 AM',
        workScheduleEnd: '5:00 PM',
        eligibilityStatus: 'Eligible',
        isOutOfWorkSchedule: false,
        requestId: '124301',
        overAllStatus: 'Approved',
        checkingInProgress: false,
        isEligible: false,
        startTime: '09:00 AM',
        regulationTypeName: 'Federal Family and Medical Leave Act (FMLA)'
      }]
    },
    {
      leaveId: '200003',
      absenceList: [{
        dateOfAbsence: '9/12/2020',
        creationDate: '4/26/2020',
        creationTime: '6:00:41 AM',
        intervalHours: 6,
        intervalMins: 0,
        leaveType: 'Reduced Schedule',
        reason: '',
        status: 'Approved',
        span: 'Partial Requested Day',
        denialReason: '',
        workScheduleStart: '9:00 AM',
        workScheduleEnd: '5:00 PM',
        eligibilityStatus: 'Undetermined',
        isOutOfWorkSchedule: false,
        requestId: '124381',
        overAllStatus: 'Approved',
        checkingInProgress: true,
        isEligible: false,
        startTime: '09:00 AM',
        regulationTypeName: 'Federal Family and Medical Leave Act (FMLA)'
      }, {
        dateOfAbsence: '9/12/2020',
        creationDate: '4/26/2020',
        creationTime: '6:00:41 AM',
        intervalHours: 6,
        intervalMins: 0,
        leaveType: 'Reduced Schedule',
        reason: '',
        status: 'Inactive',
        span: 'Partial Requested Day',
        denialReason: '',
        workScheduleStart: '9:00 AM',
        workScheduleEnd: '5:00 PM',
        eligibilityStatus: 'Eligible',
        isOutOfWorkSchedule: false,
        requestId: '124381',
        overAllStatus: 'Approved',
        checkingInProgress: true,
        isEligible: false,
        startTime: '09:00 AM',
        regulationTypeName: 'Federal Family and Medical Leave Act (FMLA)'
      }, {
        dateOfAbsence: '9/19/2020',
        creationDate: '4/26/2020',
        creationTime: '6:02:04 AM',
        intervalHours: 5,
        intervalMins: 0,
        leaveType: 'Reduced Schedule',
        reason: '',
        status: 'Inactive',
        span: 'Partial Requested Day',
        denialReason: '',
        workScheduleStart: '9:00 AM',
        workScheduleEnd: '5:00 PM',
        eligibilityStatus: 'Eligible',
        isOutOfWorkSchedule: false,
        requestId: '124381',
        overAllStatus: 'Approved',
        checkingInProgress: true,
        isEligible: false,
        startTime: '09:00 AM',
        regulationTypeName: 'Federal Family and Medical Leave Act (FMLA)'
      }]
    },
    {
      leaveId: '100082',
      absenceList: [{
        dateOfAbsence: '9/12/2020',
        creationDate: '4/26/2020',
        creationTime: '6:00:41 AM',
        intervalHours: 6,
        intervalMins: 0,
        leaveType: 'Reduced Schedule',
        reason: '',
        status: 'pended',
        span: 'Partial Requested Day',
        denialReason: '',
        workScheduleStart: '9:00 AM',
        workScheduleEnd: '5:00 PM',
        eligibilityStatus: 'Not Eligible',
        isOutOfWorkSchedule: false,
        requestId: '124382',
        overAllStatus: 'pended',
        checkingInProgress: true,
        isEligible: false,
        startTime: '09:00 AM',
        regulationTypeName: 'Federal Family and Medical Leave Act (FMLA)'
      }, {
        dateOfAbsence: '9/12/2020',
        creationDate: '4/26/2020',
        creationTime: '6:00:41 AM',
        intervalHours: 6,
        intervalMins: 0,
        leaveType: 'Reduced Schedule',
        reason: '',
        status: 'Inactive',
        span: 'Partial Requested Day',
        denialReason: '',
        workScheduleStart: '9:00 AM',
        workScheduleEnd: '5:00 PM',
        eligibilityStatus: 'Eligible',
        isOutOfWorkSchedule: false,
        requestId: '124382',
        overAllStatus: 'pended',
        checkingInProgress: true,
        isEligible: false,
        startTime: '09:00 AM',
        regulationTypeName: 'Federal Family and Medical Leave Act (FMLA)'
      }, {
        dateOfAbsence: '9/19/2020',
        creationDate: '4/26/2020',
        creationTime: '6:02:04 AM',
        intervalHours: 5,
        intervalMins: 0,
        leaveType: 'Reduced Schedule',
        reason: '',
        status: 'Inactive',
        span: 'Partial Requested Day',
        denialReason: '',
        workScheduleStart: '9:00 AM',
        workScheduleEnd: '5:00 PM',
        eligibilityStatus: 'Eligible',
        isOutOfWorkSchedule: false,
        requestId: '124382',
        overAllStatus: 'pended',
        checkingInProgress: true,
        isEligible: false,
        startTime: '09:00 AM',
        regulationTypeName: 'Federal Family and Medical Leave Act (FMLA)'
      }]
    },
    {
      leaveId: '100083',
      absenceList: [{
        dateOfAbsence: '9/12/2020',
        creationDate: '4/26/2020',
        creationTime: '6:00:41 AM',
        intervalHours: 6,
        intervalMins: 0,
        leaveType: 'Reduced Schedule',
        reason: '',
        status: 'Approved',
        span: 'Partial Requested Day',
        denialReason: '',
        workScheduleStart: '9:00 AM',
        workScheduleEnd: '5:00 PM',
        eligibilityStatus: 'Eligible',
        isOutOfWorkSchedule: false,
        requestId: '124383',
        overAllStatus: 'Approved',
        checkingInProgress: false,
        isEligible: false,
        startTime: '09:00 AM',
        regulationTypeName: 'Federal Family and Medical Leave Act (FMLA)'
      }, {
        dateOfAbsence: '9/12/2020',
        creationDate: '4/26/2020',
        creationTime: '6:00:41 AM',
        intervalHours: 6,
        intervalMins: 0,
        leaveType: 'Reduced Schedule',
        reason: '',
        status: 'Inactive',
        span: 'Partial Requested Day',
        denialReason: '',
        workScheduleStart: '9:00 AM',
        workScheduleEnd: '5:00 PM',
        eligibilityStatus: 'Eligible',
        isOutOfWorkSchedule: false,
        requestId: '124383',
        overAllStatus: 'Approved',
        checkingInProgress: false,
        isEligible: false,
        startTime: '09:00 AM',
        regulationTypeName: 'Federal Family and Medical Leave Act (FMLA)'
      }, {
        dateOfAbsence: '9/19/2020',
        creationDate: '4/26/2020',
        creationTime: '6:02:04 AM',
        intervalHours: 5,
        intervalMins: 0,
        leaveType: 'Reduced Schedule',
        reason: '',
        status: 'Inactive',
        span: 'Partial Requested Day',
        denialReason: '',
        workScheduleStart: '9:00 AM',
        workScheduleEnd: '5:00 PM',
        eligibilityStatus: 'Eligible',
        isOutOfWorkSchedule: false,
        requestId: '124383',
        overAllStatus: 'Approved',
        checkingInProgress: false,
        isEligible: false,
        startTime: '09:00 AM',
        regulationTypeName: 'Federal Family and Medical Leave Act (FMLA)'
      }]
    },
    {
      leaveId: '100084',
      absenceList: [{
        dateOfAbsence: '9/12/2020',
        creationDate: '4/26/2020',
        creationTime: '6:00:41 AM',
        intervalHours: 6,
        intervalMins: 0,
        leaveType: 'Reduced Schedule',
        reason: '',
        status: 'Approved',
        span: 'Partial Requested Day',
        denialReason: '',
        workScheduleStart: '9:00 AM',
        workScheduleEnd: '5:00 PM',
        eligibilityStatus: 'Eligible',
        isOutOfWorkSchedule: false,
        requestId: '124384',
        overAllStatus: 'Approved',
        checkingInProgress: false,
        isEligible: false,
        startTime: '09:00 AM',
        regulationTypeName: 'Federal Family and Medical Leave Act (FMLA)'
      }, {
        dateOfAbsence: '9/12/2020',
        creationDate: '4/26/2020',
        creationTime: '6:00:41 AM',
        intervalHours: 6,
        intervalMins: 0,
        leaveType: 'Reduced Schedule',
        reason: '',
        status: 'Inactive',
        span: 'Partial Requested Day',
        denialReason: '',
        workScheduleStart: '9:00 AM',
        workScheduleEnd: '5:00 PM',
        eligibilityStatus: 'Eligible',
        isOutOfWorkSchedule: false,
        requestId: '124384',
        overAllStatus: 'Approved',
        checkingInProgress: false,
        isEligible: false,
        startTime: '09:00 AM',
        regulationTypeName: 'Federal Family and Medical Leave Act (FMLA)'
      }, {
        dateOfAbsence: '9/19/2020',
        creationDate: '4/26/2020',
        creationTime: '6:02:04 AM',
        intervalHours: 5,
        intervalMins: 0,
        leaveType: 'Reduced Schedule',
        reason: '',
        status: 'Inactive',
        span: 'Partial Requested Day',
        denialReason: '',
        workScheduleStart: '9:00 AM',
        workScheduleEnd: '5:00 PM',
        eligibilityStatus: 'Eligible',
        isOutOfWorkSchedule: true,
        requestId: '124384',
        overAllStatus: 'Approved',
        checkingInProgress: false,
        isEligible: false,
        startTime: '09:00 AM',
        regulationTypeName: 'Federal Family and Medical Leave Act (FMLA)'
      }]
    }
  ],
  responseCode: '001',
  responseReason: 'success'
};

export const MOCK_COMPANY_DETAILS = {
  companyClaimAcceptanceDate: '12/20/2020',
  responseCode: '001',
  responseReason: 'success'
};

export const MOCK_NTERMITTENT_LEAVE_SCHEDULE = {
  SummaryTimeList: [
    // {
    //   'SrNo': 2,
    //   'TransactionNumber': '0',
    //   'DatesofAbsence': '5/29/2020',
    //   'Hours': '6',
    //   'Minutes': '4',
    //   'AbsenceType': '  ',
    //   'response': 'Failed - Date is already used.',
    //   'responseCode': '002'
    // },
    // {
    //   'SrNo': 1,
    //   'TransactionNumber': '0',
    //   'DatesofAbsence': '6/12/2020',
    //   'Hours': '9',
    //   'Minutes': '23',
    //   'AbsenceType': ' ',
    //   'response': 'Failed - An unknown error occurred while retrieving data. -> RequestTime',
    //   'responseCode': '002'
    // },
    // {
    //   'SrNo': 4,
    //   'TransactionNumber': '0',
    //   'DatesofAbsence': '6/17/2020',
    //   'Hours': '9',
    //   'Minutes': '0',
    //   'AbsenceType': ' ',
    //   'response': 'Failed - An unknown error occurred while retrieving data. -> RequestTime',
    //   'responseCode': '002'
    // },
    {
      SrNo: 5,
      TransactionNumber: '1337322',
      DatesofAbsence: '12/5/2020',
      Hours: '3',
      Minutes: '10',
      AbsenceType: ' ',
      response: 'Success',
      responseCode: '001'
    }
    // {
    //   'SrNo': 3,
    //   'TransactionNumber': '1337323',
    //   'DatesofAbsence': '6/20/2020',
    //   'Hours': '3',
    //   'Minutes': '0',
    //   'AbsenceType': ' ',
    //   'response': 'Success',
    //   'responseCode': '001'
    // }
  ],
  responseCode: '001',
  responseReason: 'success'
};

export const MOCK_WORK_SCHEDULE = {
  LoadWorkScheduleList:    [
    {
      Day: '11/23/2020',
      IsConsecutiveShutdown: false,
      IsHoliday: false,
      IsShutdown: false,
      IsWorkDay: true,
      ScheduledMinutes: 480,
      RequestedMinutes: 0,
      IsFullDay: true

    },
    {
      Day: '11/24/2020',
      IsConsecutiveShutdown: false,
      IsHoliday: false,
      IsShutdown: false,
      IsWorkDay: true,
      ScheduledMinutes: 480,
      IsFullDay: true

    },
    {
      Day: '7/25/2020',
      IsConsecutiveShutdown: false,
      IsHoliday: false,
      IsShutdown: false,
      IsWorkDay: false,
      ScheduledMinutes: 0,
      RequestedMinutes: 0
    },
    {
      Day: '7/26/2020',
      IsConsecutiveShutdown: false,
      IsHoliday: true,
      IsShutdown: false,
      IsWorkDay: false,
      ScheduledMinutes: 0,
      RequestedMinutes: 0
    },
    {
      Day: '11/27/2020',
      IsConsecutiveShutdown: false,
      IsHoliday: false,
      IsShutdown: true,
      IsWorkDay: true,
      ScheduledMinutes: 480,
      RequestedMinutes: 0,

    },
    {
      Day: '11/28/2020',
      IsConsecutiveShutdown: false,
      IsHoliday: false,
      IsShutdown: false,
      IsWorkDay: true,
      ScheduledMinutes: 480,
      RequestedMinutes: 600
    },
    {
      Day: '8/7/2020',
      IsConsecutiveShutdown: false,
      IsHoliday: false,
      IsShutdown: false,
      IsWorkDay: true,
      ScheduledMinutes: 480,
      RequestedMinutes: 600
    },
    {
      Day: '02/03/2021',
      IsConsecutiveShutdown: false,
      IsHoliday: true,
      IsShutdown: false,
      IsWorkDay: false,
      ScheduledMinutes: 0,
      RequestedMinutes: 0
    },
  ],
  LoadDisabilityClaimList: [
    {
      FDA: '08/03/2022',
      PRTW: '11/01/2022'
    }
    ],
  responseCode: '001',
  responseReason: 'success'
};

