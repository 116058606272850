import {Claim} from './claim.model';
import {Coverage} from './coverage.model';

// import {paymentMethods} from "./paymentMethods.model";
/**
 * Created by FA81445 on 7/31/2017.
 */
export class Person {
  id: number;
  srcSysId: string; /** Getting srcSysId from the MUDI Service and not srcSysID **/
  name: string;
  lastName: string;
  employer: string;
  birthDt: string;
  partyRefId: string;
  userId: string;
  sessionIdentifier: string;
  insuredId: string;
  businessGroupID: string;
  paymentMethod: string;
  paymentType: string;
  coverages: Coverage[];
  higEmployee: boolean;
  claims: Claim[];
  firstname: string;
  isEnrolled: boolean;
  termsAndCondAcceptDt: string;
  termsAndCondEffectiveDt: string;
}


