/**
 * Created by AC12394 on 3/13/2018.
 */

export class UpdateReceivedDateResponse {
  errorList: any;
  statusCode: string;
  statusMsg: string;
}

export class UpdateReceivedDateRequest {
  claimEventId: string;
  receivedDate: string;
  missingInfoCodeList: MissingInfoCode[];
}
export class UpdateReceivedDateRequestAA {
  personId: string;
  alertInstanceId: MissingInfoCode[];
  docUploadStatus: string;
}

export class UpdateReceivedDateResponseAA {
  statusCode: string;
  statusMessage: string;
  form: string;
  responseCode: string;
  responseReason: string;
}

export class MissingInfoCode {
  missingInfoRqstCode: string;
}
