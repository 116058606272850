/**
 * Created by AC12396 on 9/19/2019.
 */

export class SessionConstants {
 /** for leaves */
 static  CURR_LEAVE = 'currLeave';
 static LEAVE_DATES  = 'leaveDates';
 static LEAVE_DETAILS = 'leaveDetails';
 static CLAIM_DOCS_RETURN_LINK = 'claimDocsReturnLink';
 static INTERMITTENT_ABSENCES = 'intermittentAbsences';
 static SORTED_BENEFITS = 'sortedBenefits';
 static CLAIM_HANDLER_INFO = 'claimHanlderInfo';
 static STANDALONE_LEAVE = 'standaloneLeave'
 static CURRENT_PAGE = 'currentPage';
 static CURRENT_MONTH_REQUESTS  = 'currentMonthRequests';
 static APP_DATA = 'appData';
 static LEAVE_DETAIL_LIST = 'leaveDetailsList';
 static RTW_CERTLIST = 'rtwCertList';
 static CURR_LEAVE_PLAN = 'currLeavePlan';
 static LEAVE_PLAN_BALANCE = 'leavePlanBalances';
 static INTERMITTENT_IN_PROGRESS = 'intermittentInProgress';
 static COMPANY_ACCEPTANCE = 'companyClaimAcceptanceDate';
 static INTERMITTENT_SUMMARY_LIST = 'intermittentSummaryTimeList';
 static ABSENCE_TYPE = 'absenceType';
 static ABSENCE_REASON = 'absenceReason';
 static QUE_RESPONSE = 'questionResponse';
 static PRODUCT_NAVIGATION = 'productNavigation';
 static CLAIM_INTAKE_RESPONSE = 'claim_intake_response';
 static REGULAR_WORK_SCHEDULE = 'regularWorkSchedule';
 static REGULAR_WORK_SCHEDULE_SET = 'regularWorkScheduleSet';
 static CLAIM_INTAKE_REQUEST = 'claimIntakeRequest';
 static REGULAR_WORK_SCHEDULE_TYPE = 'regularWorkScheduleType';
 static WORK_SCHEDULE_REGULAR = 'workScheduleRegular';
 static IS_WORK_SCHEDULE_SUBMITTED = 'isWorkScheduleSubmitted';
 static CLAIM_ID = 'claimId';
 static LEAVE_ID = 'leaveId';
 static SELECTED_DATE = 'selectedDate';
 static SELECTED_TIME_VALUE = 'selectedTimeSlotsValue';
 static PREVIOUS_CALL_DATE = 'previousCallScheduledData';
 static SCHEDULED_CALL_DETAILS = 'scheduledCallDetails';
 static DEFAULT_TIME_SLOT = 'defaultTimeSlot';
 static APPOINTMENT_DATE = 'appointmentDate';
 static RESET_SLOT = 'resetSlot';
 static PREVIOUS_CLAIM_ID = 'previousClaimId';
 static APPOINTMENT_TIME = 'appointmentTime';
 static SCHEDULE_STATUS = 'scheduleStatus';
 static  RELOADCLAIMS = 'reloadClaims';
 static  COVERAGE_MUDI = 'coveragesMudi';
 static  COVERAGE_PLANS = 'coveragePlans';
 static LEAVE_SERVICE = 'leaveService';
 static CLAIM_PAYMENTS = 'claimPayments';
 static PROVISIONS = 'provisions';
 static PROVISIONSOPTIONS = 'provisionsoptions';
 static PROVISIONSOPTIONSIDONE = '1';
 static PROVISIONSID = '942';
 static PROVISIONSOPTIONSID = '2';
 static PROFILE_DATA_CIAM = 'profileDataCIAM';
 static PROFILE_DETAILS = 'profileDetails';
 static PERSON_IDS_IN_MUDI = 'personIdsInMudi';
 static BANKING_DETAILS = 'bankingDetails';
 static EDIT_BANKING_DETAILS = 'editbankingDetails';
 static USER_DATA = 'userData';
 static ACTIVE_USER_ID = 'activeUserId';
 static FORCED_CASE_SELECT_FLAG = 'forcedCaseSelectFlag';
 static LOGIN_STATUS = 'loginStatus';
 static CASE_DATA = 'caseData';
 static USER_CLAIMS = 'userClaims';
 static  CII_FEATURE_DATA = 'CIIFeatureData';
 static  CII_FEATURE_DATA_FOR_ACTIVE_PERSON_ID = 'CIIFeatureDataForActivePersonId';
 static EXPECTED_LEAVE_START_DATE = 'expectedLeaveStartDate';
 static ADA_HANDLER_PERSONID = 'adaHandlerPersonId';
 static CERT_DETAILS = 'certdetails';
 static DIRECT_DEPOSIT_PAYSTUB_FLAG = 'directDepositePayStubFlag';
 static PAYSTUB_PREFERENCE = 'payStubPreference';
 static IS_THAA_USER = 'isTHAAUser'
 static ADA_REQUEST_DETAILS_LIST = 'adaRequestDetailsList';
 static SET_ABSENT_RADIO_KEY_ID = 'setAbsentRadioKeyId';
 static IS_CLAIMANT_FEMALE = 'isClaimantFemale';
 static PERSON_ID = 'personId';
 static Employee_Status = 'employeeStatus';
 static FMLI_STATUS = 'fmliStatus';
 static IS_LOA_CALC = 'isLOACalculator';

  /** for leaves ends here */
  static MOCKING = 'mocking';
  static ALLOW_THAA_USER = 'allowTHAA';
  static CUR_CLAIM = 'curClaim';
  static AUTHORIZED_END_DATE = 'authorizedEndDate';
  static UI_RULE_LABEL = 'uiruleLabel';
  static UI_RULE_DATE = 'uiruleDate';
  static SITE_MAP_DATA = 'siteMapData';
  static CLAIM_DETAILS = 'claimDetails';
  static RETURN_HOME = 'returnhome';
  static RETURN_THANKS = 'returnThanks';
  static DETAIL_PAGE = 'DetailPage';
  static BABY_REPORT = 'babyReport';
  static NEW_DATE = 'NewDate';
  static CLAIM_VIEW = 'ClaimView';
  static EXTENSION_DATE = 'Extensiondate';
  static EXTENSION_DATE_S = 'Extension date';
  static  ESTIMATED_EXTENSION_DATE = 'Estimated Extension date';
  static CLAIM_EVENT_ID = 'claimEventId';
  static IS_LEAVE_REQUEST_DISPLAY = 'isLeaveRequestDisplay';
  static PREVIOUS_URL = 'previousUrl';
  static USER_STATE_CODE = 'userStateCode';
  static FAQ = 'faq';
  static SWITCH_CLAIMS = 'switchClaims';
  static NAVIGATION_STACK = 'navigationStack';
  static PREV_URL_REGULAR_WORKSCHEDULE = 'previousUrlRegularWorkschedule';
  static OWCS_CONTENT = 'OWCSContent';
  static YOUR_VIDEOS_OWCS_CONTENT = 'YourVideosOWCSContent';
  static PSV_INSTANCE_ID = 'PSVInstanceId';
  static APPROVED_DATE = 'Approveddate';
  static CLAIM_OFFICE_ID = 'claimOfficeId';
  static IDE_ELEMENT = 'ideElement';
  static CLAIMANT_PAYMENT_INFO = 'claimantPaymentInfo';
  static CLAIM_HISTORY_PAGE = 'claimHistoryPage';
  static CLAIM_HISTORY = 'claimHistory';
  static LOGIN_STATE = 'loginState';
  static WIZARD_ERROR = 'Wizard_error';
  static SURGERY_DIV = 'surgeryDiv';
  static LEAVE_SSO_URL = 'leaveSSOURL';
  static DISABILITY_DATE = 'DisabilityDate';
  static MISSING_INF_DIV = 'missingInfDiv';
  static CLAIM_REP_NAME = 'claimRepresentativeName';
  static DISABILLITY_CLAIM_CONTACT = 'disabilityClaimContact';
  static IS_CUR_PAGE_CLAIM_DETAILS = 'isCurPageClaimDetails';
  static CLAIM_HANDLER_DISPLAY = 'claimHandlerDisplay';
  static IS_CLAIM_REP_INFO_SET = 'isClaimRepInfoSet';
  static DIS_CLAIM_OFC_CONTACTS = 'disabilityClaimOfficeContacts';
  static CLAIM_REP_DETAILS = 'claimRepresentativeDetails';
  static CLAIMS_OVERVIEW_OWCS = 'Claims_Overview_Owcs';
  static CLAIM_OVERVIEW = 'Claims Overview';
  static CLAIM_OVERVIEW_U = 'Claims_Overview';
  static SYS_DOWN_FEATURE = 'sysDownFeature';
  static WIZARD_OPTIONS = 'Wizard_options';
  static COBRANDING_DATA = 'coBrandingData';
  static CLAIM_OVERVIEW_OWCS = 'Claims_Overview_Owcs';
  static IS_EMPLOYEE_HAS_VOL_PRD = 'isEmployeeHasVoluntaryProduct';
  static IS_LOA_PRODUCT_ACTIVE = 'isLOAProductActive';
  static IS_CLAIM_HISTORY_EXIST = 'isClaimHistoryExist';
  static AETENA_CONTACT_ALT_DISP_LINE = 'aetnaContactAltDisplayLines';
  static AETENA_CONTACT_DETAILS = 'aetnaContactData'
  static YOUR_PAYMENT_DETAILS = 'Your Payment Details';
  static TIME_ZONE = 'timeZone';
  static FILTER_TIME_SLOT = 'filterTimeSlotObject';
  static CLAIMANT_CONTACT = 'claimantContactDetailsMob';
  static SURGERY_NO_SELECTED = 'surgeryNoSelected';
  static CLAIM_MOBILE = 'mobileNumber';
  static CLAIM_EXTENSION = 'extension';
  static CLAIM_MORE_DETAILS = 'claimMoreDetails';
  static STD_ELIGIBILITY = 'stdEligibility';
  static STD = 'IsSTDEligible';

  /** CLAIM PAYMENTS START */
  static AA_DISABILITY_CLAIM_PAYMENT_RESPONSE = 'AADisabilityClaimPaymentResponse';
  static PAYMENT_ID = 'paymentId';
  static PAYMENT_DETAILS = 'paymentDetails';
  static PAYMENT_HISTORY = 'paymentHistory';
  static DISPLAY_PAY_STUB = 'displayPaystub';
  /** CLAIM PAYMENTS END */
  static MISSING_ITEMS = 'missingItems';
  static MISSING_INFO_THAA = 'missingInfoThaa';
  static MISSING_INFO_THAA_LEAVE = 'missingInfoThaaLeave';
  static CLAIM_OVERVIEW_OWCS_CONTENT = 'ClaimsOverviewOWCSContent';
  static AETNA_CLAIMS_CONTACT = 'aetnaClaimsContact';
  static COV_CATEGORY = 'coverageCategory';
  static CLAIM_TYPE = 'claimType';
  static CLAIM_STATUS = 'claimStatus';
  static CLAIM_EVENT_ID_FOR_DISABILITY = 'claimEventIdForDisability';
  static CLAIM_ESD_FOR_DIS = 'claimESDForDisability';
  static CLAIM_STATUS_DIS = 'claimStatusDisability';
  static PERSON_ID_FOR_DIS_DATE = 'personIdForDisabilityDate';
  static SRC_SYS_CD_FOR_DIS = 'srcSysCdForDisability';
  static CLAIMDETAILS = 'ClaimDetails';
  static GET_FIRST_VAL = 'getFirstValue';
  static IS_DIS_LDW = 'isDisabilityLDW';
  static OWCS_CONTENTS = 'OWCSContents';
  static GET_FIRST_VALUE = 'getFirstValue';
  static CLAIM_EVENTID_DELIVERY = 'claimEventIdForDelivery';
  static CLAIM_STATUS_DELIVERY = 'claimStatusForDelivery';
  static SRC_SYS_ID_DELIVERY = 'sourceSystemIdForDelivery';
  static BABY_DEATH_IND_DELIVERY = 'babyDeathIndicatorForDelivery';
  static PERSON_ID_DELIVERY = 'personIdForDelivery';
  static CONFIRMED_LAST_DAY_WORK_DATE = 'confirmedLastDayWorkDate';
  static RADIO_VALUE = 'radiovalue';
  static SHOW_PROGRESS_ICON = 'showProgressIcon';
  static ORANGE_ICON_MISS_INFO = 'orangeIconMissingInfo';
  static ESTIMATED_DELIVERY_DATE = 'estimateDeliveryDate';

  /** CONFIRM SURGERY START */
  static CONFIRM_SURGERY_PAGE = 'confirmSurgeryPage';
  static SELECTED_CURRENT_CLAIM = 'selectedCurrentClaim';
  static CONFIRM_SURGERY = 'confirmSurgery';
  static IS_LDW_POPULATED = 'isLDWPopulated';
  static NEW_SURGERY_DATE = 'New Surgery Date';
  static IS_LDW_PRESENT = 'isLDWPresent';
  static NEW_DIS_DATE = 'newDisabilityDate';
  /** CONFIRM SURGERY END */

  /** DIRECT DEPOSIT START */
  static HIDE_CONFIRM_EFT_PROMPT = 'hideConfirmEFTPrompt';
  static BANK_DETAILS = 'bankDetails';
  static SAVE_CHECK = 'saveCheck';
  /** DIRECT DEPOSIT END */
  /*home comp start*/
  static CLAIM_OVERVIEW_INHOME = 'claimOverview';
  static PRODUCT_NAV = 'productNavigation';
  static SORTED_LEAVE_PLANS = 'sortedleavePlans';
  static DEFAULT_CASE = 'defaultCase';
  static SOURCE_SYSTEM_CD = 'sourceSystemCd';
  static CONTINUE_CLICKED = 'ContinueClicked';
  static APP_LEAVE_INFO = 'AppLeaveInfo';
  static ELIGIBILITY_ID = 'eligibilityID';
  static IS_EL_WEAK = 'isELGweak';
  static TEXT_TC_OUTDATED = 'textTCOutdated';
  static DOC_OUTDATED = 'docOutdated';
  static ACTIVE_INDICATOR = 'ActiveIndicator';
  static SELF_LINKING_PATH = 'selfLinkingPath';
  static PULSE_ID = 'pulseID';
  //static IS_NOTIFICATION_DATA_SETSESSION = 'isNotificationsDataSetInSession';
  static OWCS_ERR_MSG_CONTENT = 'OWCSErrorMsgContent';
  static OWCS_ERR_MSG_CONTENTFP = 'OWCSErrorMsgContentFP';
/*home comp end*/

  /**  EMPLOYEE-BENEFIT-REGISTRATION*/
  static SSO_NEW_REG_IND = 'ssoNewRegInd';
  /**  EMPLOYEE-BENEFIT-REGISTRATION*/

  /**  RECENTLY_RESOLVED_ITEMS*/
  static RECENTLY_RESOLVED_DCS = 'recentlyResolvedDCS';
  static RECENTLY_RESOLVED_AETNA = 'recentlyResolvedAetna';
  static RECENTLY_RESOLVED_LEAVE = 'recentlyResolvedLeave';
  /**  RECENTLY_RESOLVED_ITEMS*/

  /**  NOTIFICATION_REGISTRATION*/
  static CHANNEL_ID = 'channelId';
  static IS_NOTIFICATION_REG = 'isNotificationRegistered';
  static TERMS_NOT_AGREED = 'termsNotAgreed';
  static USER_PROFILE_EMAIL = 'userProfileEmail';
  static USER_PROFILE_MOBILE = 'userProfileMobile';
  static IS_NOTIFICATION_DATA_SET_IN_SESSION = 'isNotificationsDataSetInSession';
  /**  NOTIFICATION_REGISTRATION*/
  /** CLAIM DOCUMENT START */
  static PATH_TO_CLAIM_DETAILS = 'pathNavtoClaimDetails';
  static DOC_PATH = 'docpath';
  static PATH = 'path';
  static MISSING_INFO_CODES = 'missingInfoCodes';
  static DOCUMENT_LIST = 'DocumentList';
  /** CLAIM DOCUMENT END */

  /**  POPUP_COMM_PREF*/
  static MOBILE_NO = 'mobileno';
  static EMAIL = 'email';
  /**  POPUP_COMM_PREF*/

  /**  PRODUCT_LIST_POST_LOGIN*/
  static USER_PRODUCT = 'userProduct';
  /**  PRODUCT_LIST_POST_LOGIN*/

  /*profile comp start*/
  static OWSC_CONTENT_ABT_YOU = 'owscContentAboutYou';
  static CUR_USER = 'curUser';
  static CIAM_SESSION = 'CIAMSession';
  /*profile comp end*/

  /** REQUEST EXTENSION START */
  static UPDATE_DATE = 'updateddate';
  static REQUEST_EXTENSION_DATE = 'requestedExtensionDate';
  static ESTIMATE = 'estimate';
  static EXTENSION_LENGTH = 'extensionlength';
  /** REQUEST EXTENSION END */
  /** PHA OVERVIEW DETAILS START */
  static IS_OVERVIEW_LOADED = 'isOverviewLoaded';
  static PHA_OVERVIEW_DATA = 'phaOverViewData';
  /** PHA OVERVIEW DETAILS END */

  static ROUTING_NO = 'routingno';
  static BANK_NAME = 'bankname';
  static ACCT_NO = 'acctno';
  static ACCT_TYPE = 'accttype';
  static CHECKED_NAME = 'checkedname';
  static IS_AUTH_EXIST = 'isAuthExist';
  static IS_FORM_DIRTY = 'isFormDirty';
  static HIDE_DIRECT_DEPOSIT_PROMPT = 'hideDirectDepositPrompt';
  static IS_BANKING_DETAILS_EXIST = 'isBankingDetailsExist';
  static GO_BACK_FROM_PROFILE_TO = 'goBackFromProfileTo';
  static MOBILE_NOT_PRESENT = 'mobileNotPresent';
  static PAYSTUB = 'payStubChecked';

  /**  SERVICES*/
  static INTERNAL_ADMIN = 'internalAdmin';
  static AUTH_TOKEN = 'authToken';
  static SSO_PARTY_REF_ID = 'ssoPartyRefId';
  static CLAIMANT_PAYMENT_INFO_LG = 'ClaimantPaymentInfo';
  static IS_CLAIMANT_PAYMENT_INFO_STE_IN_SESSION = 'isClaimantPaymentInoSetInSession';
  static IS_HISTORICAL_CLAIMANT_PAYMENT_INFO_STE_IN_SESSION = 'isHistoricalClaimantPaymentInoSetInSession';
  static IS_DEFAULT_PROVISIONS_SET = 'isDefaultProvisionsSet';
  static SELECTED_PATRY_REF = 'selectedPrtyRef';
  static IS_CLAIM_SERVICE_CALLED = 'isClaimsServiceCalled';
  static IS_CONTACT_SERVICE_CALLED = 'isContactServiceCalled';
  static IS_THAA_DP_ERROR_OCCURED = 'isTHAADPErrorOccured';
  static NOTIFICATION_DETAILS = 'notificationDetails';
  static SESSION_DATA = 'sessionData';
  static FEATURES = 'features';
  static TOGGLE_LIST = 'toggleList';
  static ALL_CLAIM_PAYMENT_DETAILS = 'allClaimPaymentDetails';
  static ALL_CLAIM_PAYMENTS_SORTED_BY_ISSUE_DATE = 'allClaimPaymentsSortedByIssueDate';
  static PAYMENTS_TRIGGERED_FOR_CLAIMS = 'paymentsTriggeredForClaims';
  /**  SERVICES*/
  static IMPERSONATION_TYPE = 'impersonationType';
  static THAA_PREFERENCE = 'thaaPrefrence';
  /*Regular work schedule  start*/
  static REGULAR_WORK_SCHEDULE_OWCS_CONTENT = 'regularWorkScheduleOWCSContent';
  static EDIT_REGULAR_WORK_SCHEDULE_OWCS_CONTENT = 'editRegWorkSchOWCSContent';
  /*Regular work schedule  end*/
  /*edit regular work schdeule start*/
  static SAVE_CHANGES_CLICKED_RWS = 'saveChangesClicked';
  static  PAYLOAD_RWS = 'workScheduleRegPayload';
  /*edit regular work schedule end*/
  static  LEAVE_RTW_OWCS_CONTENT = 'leaveRTWOWCSContent';
  static CLAIM_RTW_OWCS_CONTENT = 'claimRTWOWCSContent';
  static RTW_REDUCED_SCHEDULE_DATE = 'rtwReducedScheduleDate';
  static RTW_REDUCED_SCHEDULE = 'rtwReducedSchedule';
  static IS_HOME_VISITED = 'isHomeVisited';
  static CLAIM_RTW_EXTENSION_DATE = 'claimRTWExtensionDate';
  static UPDATE_ACCOMMODATION_OWCS_CONTENT = 'updateAccommodationOWCSContent';
  static UPDATE_ACCOMMODATION_CONFIRM_OWCS_CONTENT = 'updateAccommodationConfirmOWCSContent';

  /**Future Payments*/
  static FUTURE_PAYMENTS = 'futurePayments';
  static BENEFITS_BREAKDOWN = 'Benefits Breakdown';
  static BB_OWCS_CONTENT = 'benefitBreakDownOWCSContent';
  static IS_FUTURE_PAYMENT_EXIST_LIST = 'isFuturePaymentExistList';
  static CLAIM_FUTURE_PAYMENT_DATA = 'claimFuturePaymentData';

  /**Interactive Request Extension*/
  static INTERACTIVE_REQUEST_EXTENSION = 'interactiveExtension';
  static IEXT_OWCS_CONTENT = 'interactiveRequestExtensionOWCSContent';
  static GETTING_STARTED_CONTENT = 'gettingStartedData';
  static YOUR_PROGRESS_1A_YES = 'yourProgress1AYes';
  static RAE_STEP_DATA = 'raeStepData';

  static RELATED_CLAIMS = 'relatedClaims';
  static HANDLER_DETAILS = 'handlerDetails';
  static FLAT = ' flat';

  /** ADA udpate **/
  static CONFIRM_END_DATE = 'confirmEndDateValue';
  static EXTEND_END_DATE = 'extendEndDateValue';
  static ACCOMMODATION_NEW_TYPE_VALUE = 'accommodationTypeNew';
  static ACCOMMODATION_SAME_TYPE_VALUE = 'accommodationTypeSame';

  static ADA_INTAKE_OWCS_CONTENT = 'adaIntakeOWCSContent';
  static ADA_PRODUCT_PROVISION = 'adaProduct';
  static THAA_ENHANCED_ADA = 'thaaEnhancedAda';
  static THAA_FOUNDAMENTAL_ADA = 'thaafoundational';
  static THAA_FOUNDATIONAL_ADA_MOBILE = 'thaafoundationaladamobile';
  static FOUNDATIONAL_ADA_CONTACT = 'thaaFoundationalAdaContact';
  static THAA_ENHANCED_ADA_LEAVE = 'thaaEnhancedAdaLeave';
  static CLAIMS_WITH_FUTURE_PAYMENTS = 'claimsWithFuturePayments';

  /**Product Forms */
  static PRODUCT_FORM_TOGGLE = 'productForms';
  static PRODUCT_FORMS_LIST = 'formsList';
  //Feature Toggle for Request an extension(RAE)
  static FT_20211009_F12304_REQUEST_AN_EXTENSION_TOGGLE = 'isNewExtensionEnabled';
  static IS_OVERPAYMENT_ELIGIBLE = 'isOverpaymentEligible';
  static OVERPAYMENTS = 'overPayments';
  static PAYMENT_CONFIRMATION_OWCS = 'paymentConfirmationOwcs';
  static OVERPAYMENT_CLAIM_DETAILS = 'OvepaymentClaimDetails'
  //for custom error
  static NI_OWCS_CONTENT = 'NewIntakeOwcsContent';
  static IS_FDA_A_NON_WORKDAY = 'isFDAANonWorkDay';
  static AETNA_MOBILE_NUM = "aetnaMobileNum"
}
