import {GbBase} from './gb-base';

export class GbTealium extends GbBase {

  getUrls() {
    return {
      TEALIUM_UTAG_URI: 'https://tags.tiqcdn.com/utag/',
    }
  }
}
