import {
  Component, EventEmitter, Input, OnChanges, OnInit, QueryList, SimpleChange, SimpleChanges,
  ViewChildren
} from '@angular/core';
import {ModalService} from '../../../shared/services/modal.service';
import {MissingInfoDoc} from '../../../shared/models/missing-info.model';
import {StorageManagementService} from '../../../shared/services/storage-management.service';
import {AppConstants} from '../../../shared/constants/app.constants';
import {AppLabelConstants} from '../../../shared/constants/appLabel.constants';
import {SessionConstants} from '../../../shared/constants/session.constants';


@Component({
  selector: 'app-missing-document-upload-modal',
  templateUrl: './missing-document-upload-modal.component.html',
  styleUrls: ['./missing-document-upload-modal.component.scss']
})
export class MissingDocumentUploadModalComponent implements OnInit, OnChanges {
  @Input()  missingDocsRequired: MissingInfoDoc[] = [];
  @Input() clearCheckBox = false;
  @ViewChildren('removeCheck') removeChecks: QueryList<any>;
  public appConstant = AppConstants;
  public appLabelConstant = AppLabelConstants;
  public missingInfoDocuments: MissingInfoDoc[] = [];
  private missingDocCodes: string[] = [];
  isFileUploadDisabled: boolean = false;


  constructor(private modalService: ModalService,
              private storageService: StorageManagementService) {
  }

  ngOnInit() {
    this.isFileUploadDisabled = this.storageService.getSessionData(AppConstants.IS_UPLOAD_DISABLED);

    this.missingInfoDocuments = this.missingDocsRequired;
  }
  ngOnChanges(changes: SimpleChanges) {
    if (this.removeChecks) {
      if (changes['clearCheckBox']) {
        this.removeChecks.toArray().forEach((item) => {
          item.checkboxState = false;
        });
      }
    }
  }
  clickCancel() {
    this.removeChecks.toArray().forEach((item) => {
     item.checkboxState = false;
    });
    this.modalService.closeActiveModal();
    this.modalService.closeModal(AppLabelConstants.MISSING_DOCUMENT_MODAL);
  }

  toggleOnSession(theDocCode, chked) {
    if (chked.checkboxState === AppLabelConstants.CHECKED) {
      this.missingDocCodes.push(theDocCode);
    } else {
      this.missingDocCodes.splice(this.missingDocCodes.indexOf(theDocCode), 1);
    }
    this.storageService.setSessionObject(SessionConstants.MISSING_INFO_CODES, this.missingDocCodes);
  }

}
