import { Injectable } from '@angular/core';
import { EnvironmentLoader } from 'environments/environment-loader';

interface Scripts {
  name: string;
  src: string;
}

interface Css {
  name: string;
  src: string;
}

const psvCdnUrl = EnvironmentLoader.getEnvironmentFile().psvCdnUrl;
export const PsvScripts: Scripts[] = [
  { name: 'psv-player-audioMB-v1', src: psvCdnUrl + 'psv-player-audioMB-v1.min.js' },
  { name: 'videojs-overlay', src: psvCdnUrl + 'assets/videojs-overlay.min.js' },
  { name: 'videojs-vr', src: psvCdnUrl + 'assets/videojs-vr.min.js' },
  { name: 'videojs.hotkeys', src: psvCdnUrl + 'assets/videojs.hotkeys.min.js' },
  { name: 'psv-polly-tts', src: psvCdnUrl + 'psv-polly-tts.min.js' },
  { name: 'videojs-transcript', src: psvCdnUrl + 'assets/videojs-transcript.min.js' },
  { name: 'psv-google-analytics', src: psvCdnUrl + 'psv-google-analyticsMB.min.js' }

];

export const PsvStylesheets: Css[] = [
  { name: 'psv-player-audio', src: psvCdnUrl + 'psv-player-audio-v2.min.css' }


];
@Injectable()
export class ScriptService {

  private scripts: any = {};
  private stylesheets: any = {};

  constructor() {
    PsvScripts.forEach((script: any) => {
      this.scripts[script.name] = {
        loaded: false,
        src: script.src
      };
    });

    PsvStylesheets.forEach((css: any) => {
      this.stylesheets[css.name] = {
        loaded: false,
        src: css.src
      };
    });
  }

  loadJSCSS() {
    return this.loadStylesheet('psv-player-audio').then(reponse_1 =>
      this.loadScript('psv-player-audioMB-v1').then(response_2 =>
        this.loadScript('videojs.hotkeys').then(response_3 =>
          this.loadScript('videojs-overlay').then(response_4 =>
            this.loadScript('videojs-transcript').then(response_5 =>
              this.loadScript('videojs-vr').then(reponse_6 =>
                this.loadScript('psv-polly-tts').then(response_7 =>
                  this.loadScript('psv-google-analytics').then(response_8 =>
                  this.loadGoogleAnalyticsScripts(EnvironmentLoader.getEnvironmentFile().psvGoogleAnalyticKey)
                  )
                )
              )
            )
          )
        )
      )
    );

  }

  loadGoogleAnalyticsScripts(psvGoogleAnalyticKey: string) {
    return new Promise((resolve, reject) => {
    const gmgrScript = document.createElement('script');
    gmgrScript.src = 'https://www.googletagmanager.com/gtag/js?id=' + psvGoogleAnalyticKey;
    gmgrScript.async = true;
    document.getElementsByTagName('head')[0].appendChild(gmgrScript);
    //for gtag
    const gtagScript = document.createElement('script');
    gtagScript.innerHTML = 'window.dataLayer = window.dataLayer || [];  function gtag() { dataLayer.push(arguments); } gtag(\'js\', new Date()); gtag(\'config\',\'' + psvGoogleAnalyticKey + '\', { \'custom_map\': { \'dimension1\': \'PartialOrCompleteWatch\',\'dimension2\': \'CallToAction\',\'dimension3\': \'Claim Id\',\'dimension4\': \'Video Watched Time\',\'dimension5\': \'Email Communication Time\',\'dimension6\': \'Video Watch Percentage\',\'dimension7\': \'source\',\'dimension8\': \'Video Name\'}});'
    document.getElementsByTagName('head')[0].appendChild(gtagScript);
    resolve({loaded: true});
  })

  }
  loadStylesheet(name: string) {
    return new Promise((resolve, reject) => {
      // resolve if already loaded

      if (this.stylesheets[name].loaded) {
        resolve({ stylesheet: name, loaded: true, status: 'Already Loaded' });
      } else {
        // load stylesheets
        const link = document.createElement('link');
        link.rel = 'stylesheet';
        link.type = 'text/css';
        link.href = this.stylesheets[name].src;
        link.media = 'all';
        link.onload = () => {
          this.stylesheets[name].loaded = true;
          resolve({ stylesheet: name, loaded: true, status: 'Loaded' });
        };
        link.onerror = (error: any) => resolve({ stylesheet: name, loaded: false, status: 'Loaded' });
        document.getElementsByTagName('head')[0].appendChild(link);
      }
    });
  }

  loadScript(name: string) {
    return new Promise((resolve, reject) => {
      // resolve if already loaded
      if (this.scripts[name].loaded) {
        resolve({ script: name, loaded: true, status: 'Already Loaded' });
      } else {
        // load script
        const script = document.createElement('script');
        script.type = 'text/javascript';
        script.src = this.scripts[name].src;
        script.onload = () => {
          this.scripts[name].loaded = true;
          resolve({ script: name, loaded: true, status: 'Loaded' });
        };
        script.onerror = (error: any) => resolve({ script: name, loaded: false, status: 'Loaded' });
        document.getElementsByTagName('head')[0].appendChild(script);
      }
    });
  }
}

