
import {catchError, map} from 'rxjs/operators';
import {Injectable} from '@angular/core';


import {env} from '../../../environments/environment-loader';


import {ResponseErrorHandlerService} from './response-error-handler.service';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {AppConstants} from '../constants/app.constants';
import {AppLabelConstants} from '../constants/appLabel.constants';
import {
  MissingInfoAlertInfo, MissingInfoFromAllThaa,
  MissingReqInfoThaa, MissingInfoListThaa
} from '../models/missingInfoThaa.model';


@Injectable()
export class MissingInfoThaaService {
  private missingInfoClaimArray: MissingInfoFromAllThaa = new MissingInfoFromAllThaa();
  constructor(private errorHandler: ResponseErrorHandlerService,
              private httpClient: HttpClient) {
  }

  public documentFilterThaa(missingInfoDetailsListThaa: MissingInfoListThaa[]) {
      const missingInfoData: MissingInfoListThaa[] = missingInfoDetailsListThaa;
      const listOfMissingInfoDataFromEmployee: MissingInfoListThaa[] = ((missingInfoData && missingInfoData.length > 0) ? (missingInfoData.filter((missingInfoByEmployee: MissingInfoListThaa) => missingInfoByEmployee.mInfoCode === AppConstants.EMPLOYEE_CD_10023366)) : []);
      this.missingInfoClaimArray.missingInfoDataFromEmployee = listOfMissingInfoDataFromEmployee && listOfMissingInfoDataFromEmployee.length === 1 ? listOfMissingInfoDataFromEmployee[0] : null;
      const listOfMissingInfoDataFromEmployer: MissingInfoListThaa[] = ((missingInfoData && missingInfoData.length > 0) ? (missingInfoData.filter((missingInfoByEmployer: MissingInfoListThaa) => missingInfoByEmployer.mInfoCode === AppConstants.EMPLOYER_CD_10023364)) : []);
      this.missingInfoClaimArray.missingInfoDataFromEmployer = listOfMissingInfoDataFromEmployer && listOfMissingInfoDataFromEmployer.length === 1 ? listOfMissingInfoDataFromEmployer[0] : null;
      const listOfMissingInfoDataFromDoctor: MissingInfoListThaa[] = ((missingInfoData && missingInfoData.length > 0) ? (missingInfoData.filter((missingInfoByDoctor: MissingInfoListThaa) => missingInfoByDoctor.mInfoCode === AppConstants.PHYSICIAN_CD_10023362)) : []);
      this.missingInfoClaimArray.missingInfoDataFromDoctor = listOfMissingInfoDataFromDoctor && listOfMissingInfoDataFromDoctor.length === 1 ? listOfMissingInfoDataFromDoctor[0] : null;
      return this.missingInfoClaimArray;
  }

 /* public updateReceivedDate(claimEventId: string, receivedDate: string, missingInfoRqstCodeList: string[]): Observable<UpdateReceivedDateResponse> {
    const request: UpdateReceivedDateRequest = new UpdateReceivedDateRequest();
    request.claimEventId = claimEventId;
    request.receivedDate = receivedDate;
    if (missingInfoRqstCodeList.length > 0) {
      request.missingInfoCodeList = [];
      missingInfoRqstCodeList.forEach(
        (missingInfoRqstCode: string) => {
          const missingInfoCode: MissingInfoCode = new MissingInfoCode();
          missingInfoCode.missingInfoRqstCode = missingInfoRqstCode;
          request.missingInfoCodeList.push(missingInfoCode);
        }
      );
    }


    return this.httpClient.post(env.gbClaims.updateReceivedDate, JSON.stringify(request),
      {
        headers: new HttpHeaders()
          .set(AppLabelConstants.CONTENT_TYPE_KEY, AppLabelConstants.APPLICATION_KEY)
      })
      .map(
        (response: Response) => {
          if (!this.errorHandler.isServiceResponseHasError(response)) {
            return response;
          } else {
            throw response;
          }
        }
      ).catch((error: any) => {
        return this.errorHandler.handleError(error);
      });
  }*/

  public retrieveMissingInfo(claimId: string, personId: string) {
    // const request = '&personId=' + personId + '&claimId=' + claimId;
    return this.httpClient.get(env.thaaWKABPortalService.thaaMissingInfoURL + '&claimId=' + claimId  + '&personId=' + personId,
      {
        headers: new HttpHeaders()
          .set(AppLabelConstants.CONTENT_TYPE_KEY, AppLabelConstants.APPLICATION_KEY)
      }).pipe(
      map(
        (response: Response) => {
          if (!this.errorHandler.isServiceResponseHasError(response)) {
            return response;
          } else {
            throw response;
          }
        }
      ), catchError((error: any) => this.errorHandler.handleError(error)), );
  }

  public getEsignForm(personId: string , mInfoReqInfoCd: string) {
    const request = '&personId=' + personId + '&alertInstanceId=' + mInfoReqInfoCd;
    return this.httpClient.get(env.thaaWKABPortalService.thaaGetEsignFormURL +  request,
      {
        headers: new HttpHeaders()
          .set(AppLabelConstants.CONTENT_TYPE_KEY, AppLabelConstants.APPLICATION_KEY)
      }).pipe(
      map(
        (response: Response) => {
          if (!this.errorHandler.isServiceResponseHasError(response)) {
            return response;
          } else {
            throw response;
          }
        }
      ), catchError((error: any) => this.errorHandler.handleEcoSignErrorForSubmit(error)), );
  }

  public getDownLoadMissInfoForm(personId: string , mInfoReqInfoCd: string , attachmentInstanceId: string) {
    const request = '&personId=' + personId + '&alertInstanceId=' + mInfoReqInfoCd + '&attachmentInstanceId=' + attachmentInstanceId;
    return this.httpClient.get(env.thaaWKABPortalService.thaaMissInfoDownloadFormURL +  request,
      {
        headers: new HttpHeaders()
          .set(AppLabelConstants.CONTENT_TYPE_KEY, AppLabelConstants.APPLICATION_KEY)
      }).pipe(
      map(
        (response: Response) => {
          if (!this.errorHandler.isServiceResponseHasError(response)) {
            return response;
          } else {
            throw response;
          }
        }
      ), catchError((error: any) => this.errorHandler.handleDownloadFormErrorForSubmit(error)), );
  }
}

