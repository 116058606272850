import {Component, Input} from '@angular/core';

@Component({
  selector: 'time-selection-button',
  templateUrl: 'time-selection-button.component.html',
  styleUrls: ['time-selection-button.component.scss']
})
export class TimeSelectionButtonComponent {

  @Input() buttonText: string;
  @Input() buttonSize: string;
  @Input() className: string;
  @Input() btnId: string;
  @Input() buttonDisabled = false;
  public status: boolean = true;

  buttonClicked(event) {
    this.status = !this.status;
  }
}
