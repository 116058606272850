/**
 * Created by AC12394 on 9/12/2017.
 */

import {BankDetails} from './bankDetails.model';

/**
 * For getClaimPaymentDetails DCS Service
 */

export class ClaimPaymentRequest {
  insuredId: string;
  claimEventId: string;
}

export class ClaimPaymentResponse {
  errorList: any;                         /*don't know the exact data type of errorList hence any*/
  claimPaymentInfo: Payment[];
}

export class Payment {
  checkAmt: number;
  checkNbr: string;
  eftAccountNumber: string[];
  electronicTransfer: string;
  endDate: string;
  payee: string;
  payeeId: string;
  paymentDate: string;
  paymentID: string;
  paymentTypeCode: string;
  startDate: string;
  eobBlob: PaymentEobBlob;
}

export class PaymentEobBlob {
  personId: string;
  claimId: string;
  eobNum: string;
  company: string;
  paygroup: string;
  payEndDate: string;
  offcycle: string;
  pageNum: string;
  lineNum: string;
  sepchk: string;
}

/**
 * For getClaimPaymentDetails DCS Service
 */

export class ClaimPaymentDetailsRequest {
  paymentId: string;
  claimEventId: string;
}


export class ClaimPaymentDetailsResponse {
  errorList: any;                         /*don't know the exact data type of errorList hence any*/
  claimPaymentDetailRes: PaymentDetail[] = [];
}

export class PaymentDetail {
  amountsEqualTotalPay: boolean;
  amountsNotEqualTotalPay: boolean;
  assignedPayment: number;
  totalDeductions: number;
  claimAdjAmountNegative: boolean;
  claimAdjAmount: number;
  grossAmt: number;
  netPayment: number;
  offsetTotal: number;
  benefitDeductions: Offset[] = [];
  offsets: Offset[] = [];
  taxDeductions: Offset[] = [];
}

export class Offset {
  deductionAmount: number;
  negativeDeductionAmount: number;
  description: string;
}

/**
 * For getPayeeDetails DCS Service
 */

export class PayeeDetailsRequest {
  insuredId: string;
  claimEventId: string;
  paymentDate: string;
  paymentTypeCode: string;
  payeeId: string;
}


export class PayeeDetailsResponse {
  errorList: any;                         /*don't know the exact data type of errorList hence any*/
  claimPayeeDetailsResList: PayeeDetails[] = [];
}

export class PayeeDetails {
  bankName: string;
  eft: boolean;
  payeeName: string;
  address1: string;
  address2: string;
  city: string;
  state: string;
  zipCd: string;
  accountNumber: string;
}

export class PayeeTypeResponse {
  errorList: any[];
  payeeTypeDetailsList: PayeeType[];
}

export class PayeeType {
  claimEventId: string;
  payeeType: string;
}

export class PayeeTypeRequest {
  claimEventIds: string[];
}


export class ClaimPaymentInfo {
  claimEventId: string;
  sourceSystemId: string;
  caseId: string;
  fundingMethodCode: string;
  isClaimFullyInsured: boolean;

  coverageCategory: string;
  claimStatusCode: string;
  claimOnlineStatusCode: string;
  claimOnlineSubStatusCode: string;

  payments: Payment[];
  payeeType: string;
  isDefaultCase: boolean;
  isHistoricalClaim: boolean;

  constructor() {
    this.payeeType = '';
    this.isClaimFullyInsured = false;
    this.isDefaultCase = false;
    this.payments = [];
  }
}

export class PaymentSchedule {
  monthName: string;
  checkIssueDate: string;
  eftDate: string;
  checkIssueDateSemi: string;
  eftDateSemi: string;
}

export class ClaimantPaymentInfo {
  isPaymentApplicable: boolean;
  isBankDetailsExist: boolean;
  isDefaultProvisionsSet: boolean;
  claimsPaymentInfo: ClaimPaymentInfo[];
  casesInfo: CaseInfo[];
  bankDetails: BankDetails;

  constructor(claimsPaymentInfo: ClaimPaymentInfo[]) {
    this.claimsPaymentInfo = claimsPaymentInfo;
    this.isPaymentApplicable = false;
    this.isBankDetailsExist = false;
  }

  public checkIfPaymentApplicable() {
    if (this.claimsPaymentInfo && this.claimsPaymentInfo.length > 0) {
      const filteredFullyInsuredClaims: ClaimPaymentInfo[] = this.claimsPaymentInfo.filter((claimPaymentInfo: ClaimPaymentInfo) => claimPaymentInfo.isClaimFullyInsured && !claimPaymentInfo.isHistoricalClaim);
      this.isPaymentApplicable = (filteredFullyInsuredClaims && (filteredFullyInsuredClaims.length > 0));
    }
  }

  public getFullyInsuredClaimEventIds(): string[] {
    if (this.claimsPaymentInfo && this.claimsPaymentInfo.length > 0) {
      const filteredFullyInsuredClaims: ClaimPaymentInfo[] = this.claimsPaymentInfo.filter((claimPaymentInfo: ClaimPaymentInfo) => claimPaymentInfo.isClaimFullyInsured && !claimPaymentInfo.isHistoricalClaim);
      if (filteredFullyInsuredClaims && filteredFullyInsuredClaims.length > 0) {
        return filteredFullyInsuredClaims.map((claimPaymentInfo: ClaimPaymentInfo) => claimPaymentInfo.claimEventId);
      }
    }
    return [];
  }
}

export class CaseInfo {
  caseId: string;
  isDefault: boolean;
  toBeCalledForId: string;
  isCaseFullyInsured: boolean;

  constructor() {
    this.isCaseFullyInsured = false;
  }
}

export class ClaimToCaseInfoMapping {
  claimPaymentInfo: ClaimPaymentInfo;
  casesInfo: CaseInfo[];

  constructor(claimPaymentInfo: ClaimPaymentInfo, casesInfo: CaseInfo[]) {
    this.claimPaymentInfo = claimPaymentInfo;
    this.casesInfo = casesInfo;
  }
}

export interface ItotalPayments {
  claimEventId: string,
  totalPaymentResponseData: ClaimPaymentResponse
}
