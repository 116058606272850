
import { catchError, map } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { env } from '../../../environments/environment-loader';
import { ResponseErrorHandlerService } from './response-error-handler.service';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { AppLabelConstants } from '../constants/appLabel.constants';
import { StorageManagementService } from './storage-management.service';
import { Observable, of } from 'rxjs';
import { IFMLIStatus } from '../models/employeeStatus.model';
import { SessionConstants } from '../constants/session.constants';

@Injectable()
export class FMLIStatusService {
  constructor(private errorHandler: ResponseErrorHandlerService,
              private httpClient: HttpClient,
              private storeManagementService: StorageManagementService) {}
  public getFMLIStatus(personId: string): Observable<IFMLIStatus>{
    const fmliStatus = this.storeManagementService.getSessionData(SessionConstants.FMLI_STATUS);
    if (fmliStatus) {
      return of(fmliStatus);
    } else {
    return this.httpClient.get(env.thaaWKABPortalService.fmliStatus + '&personId=' + personId,
      {
        headers: new HttpHeaders()
          .set(AppLabelConstants.CONTENT_TYPE_KEY, AppLabelConstants.APPLICATION_KEY)
      }).pipe(
      map(
      (response: IFMLIStatus) => {
        if (!this.errorHandler.isServiceResponseHasError(response)) {
          this.storeManagementService.setSessionData( SessionConstants.FMLI_STATUS, response);
          return response;
        } else {
          throw response;
        }
      }
    ),
    catchError((error: any) => this.errorHandler.handleError(error)), );
    }
  }
}
