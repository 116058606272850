import {Component, Input, OnInit} from '@angular/core';
import {ButtonComponent} from '../../../sub-atomic/button/button.component';

@Component({
  selector: 'secondary-button',
  templateUrl: 'secondary-button.component.html',
  styleUrls: ['secondary-button.component.scss']
})
export class SecondaryButtonComponent extends ButtonComponent  implements OnInit {

  @Input() buttonText: string;
  @Input() btnId: string;
  @Input() buttonColor = 'regular';
  @Input() buttonLoading = 'done';
  @Input() hukStyleSecondaryButton: boolean = false;
  private currentEv;

  constructor() {
    super();
  }

  ngOnInit() {

  }
  buttonClicked(event) {
    if (event.target.dataset.disabled === 'true') {
      event.preventDefault();
      event.stopImmediatePropagation();
      event.stopPropagation();
    }
    this.currentEv = event;
  }
  reset() {
    if (this.currentEv) {
      this.currentEv.target.dataset.disabled = false;
    }
    this.buttonLoading = 'done';
  }


}
