import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {FormControl} from '@angular/forms';
import {ElementControlFormWrapperComponent} from '../../../sub-atomic/element-control-form-wrapper/element-control-form-wrapper.component';
import {AppLabelConstants} from '../../../../../shared/constants/appLabel.constants';

@Component({
  selector: 'tertiary-dropdown',
  templateUrl: 'tertiary-dropdown.component.html',
  styleUrls: ['tertiary-dropdown.component.scss']
})
export class TertiaryDropdownComponent extends ElementControlFormWrapperComponent  implements OnInit {
  @Input() dropdownList = [];
  @Input() inputId: string;
  @Input() disabledDropdown = false;
  @Input() selectedOption: any;
  @Input() readOnlyCondition: boolean;
  @Input() ariaLabel: string;
  @Input() displayValue: string;
  @Input() requiredValue: string;
  @Input() requestValue: string;
  @Input() name: string;
  @Input() controls: FormControl = new FormControl();
  @Input() title: string = '';
  @Output () selectedValue: EventEmitter<string> =  new EventEmitter<string>();
  public errorMsg = 'Please Select Value';
  public deviceWidth;
  public displayLength = null;
  public appLabelConstants = AppLabelConstants;

  ngOnInit() {
    //this can be modified to handle from other component by adding Input
    this.deviceWidth = window.innerWidth;
      if ( 414 >= this.deviceWidth && this.deviceWidth > 375 ) {
      this.displayLength = 40;
    } else if (375 >= this.deviceWidth && this.deviceWidth > 360) {
      this.displayLength = 33;
    } else if (360 >= this.deviceWidth && this.deviceWidth > 320) {
      this.displayLength = 31;
    } else if (320 >= this.deviceWidth) {
      this.displayLength = 26;
    } else {
        this.displayLength = null;
      }
  }
}
