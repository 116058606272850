/**
 * Created by AC12396 on 12/13/2018.
 */

export const AETNA_CLAIMS_CONTACTS = {
  aetnaContactDetails: {
    heading: 'Aetna',
    phone: '866-442-7695',
    fax: '866-667-1987',
    hours: '8AM - 9PM EST',
    URL: 'www.aetnadisability.com'
  },
  aetnaContactAltDisplayLines: [
    'The Boeing Leave Service Center',
    'Phone: (866) 473-2016',
    'Fax: (888) 329-4093',
    'Hearing-impaired callers should use the relay service offered through their telephone service provider.',
    'Hours of Telephonic Operation:',
    'Monday - Friday 5 a.m. to 5 p.m. Pacific'
  ],
  responseCode: '001',
  responseReason: 'success'
}
