
import {map} from 'rxjs/operators';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AppLabelConstants } from 'app/shared/constants/appLabel.constants';
import { env } from 'environments/environment-loader';

declare let psv: any;
declare let psvPlugins: any;

@Injectable()
export class PsvPageService {

    psvJsondata: any;

    constructor(private httpClient: HttpClient) { }
    getPsvDataThroughDP(token: string): Promise<any> {
        return this.httpClient.get(env.thaaWKABPortalService.thaaPsvDataUrl + '&access_token=' + token,
            {
                headers: new HttpHeaders()
                    .set(AppLabelConstants.CONTENT_TYPE_KEY, AppLabelConstants.APPLICATION_KEY)
            }).pipe(
            map( (response) => response))
            .toPromise();

    }

    getTtsData(url, requestBody, token) {
        return this.httpClient.post(env.thaaWKABPortalService.thaaPsvTtsUrl + '&access_token=' + token,
            JSON.parse(requestBody.body) , { headers: new HttpHeaders()
        .set(AppLabelConstants.CONTENT_TYPE_KEY, AppLabelConstants.APPLICATION_KEY)}

        ).pipe(map(response => response)).toPromise();
    }
    LoadPsvPlayer(userData, videoSource, psvMetadataFilePath, analyticParams, psvUseCaseName, token) {

        const ttsOptions = {


ttsCallbackFunction: (url, reqBody) => this.getTtsData(url, reqBody, token)

        };

        psv.setPSVMetaAsJS( AppLabelConstants.PSV_PLAYER_ID, videoSource, psvMetadataFilePath, userData, ttsOptions)
            .then(function(val) {
                psvPlugins.initGoogleAnalytics(analyticParams.ClaimId, analyticParams.EmailCommDate, AppLabelConstants.PSV_GA_SOURCE, psvUseCaseName, AppLabelConstants.PSV_PLAYER_ID);
            });
    }
}

