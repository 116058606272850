import {Component, Input, OnInit} from '@angular/core';
import {AppConstants} from '../../../shared/constants/app.constants';

@Component({
  selector: 'app-claimstatusheader',
  templateUrl: 'claimstatusheader.component.html',
  styleUrls: ['claimstatusheader.component.scss']
})
export class ClaimstatusheaderComponent implements OnInit {
  @Input() claimOnlineStatus;
  @Input() coverageCategory;
  @Input() claimType;
  @Input() claimSubType;
  @Input() coverageType;
  public message;
  constructor() { }

  ngOnInit() {

    if (this.claimOnlineStatus === AppConstants.CLAIM_DENIED && this.coverageCategory.toUpperCase() !== AppConstants.LTD && this.coverageCategory.toUpperCase() !== AppConstants.LIFE) {
      this.message = this.claimSubType;
    } else if (this.claimOnlineStatus !== AppConstants.CLAIM_DENIED && this.coverageCategory.toUpperCase() !== AppConstants.LIFE) {
      this.message = this.claimSubType;
    } else if (this.coverageCategory.toUpperCase() === AppConstants.LTD) {
      this.message = this.claimSubType;
    } else if (this.coverageCategory.toUpperCase() === AppConstants.LIFE) {
      switch (this.coverageType) {
        case this.coverageType = AppConstants.BTRM:
          this.message = AppConstants.PREMIUM_WAIVER_BTL;
          break;

        case this.coverageType = AppConstants.BDEP:
          this.message = AppConstants.PREMIUM_WAIVER_BDL;
          break;

        case this.coverageType = AppConstants.SDEP:
          this.message =  AppConstants.PREMIUM_WAIVER_SDL;
          break;

        case this.coverageType = AppConstants.STRM:
          this.message =  AppConstants.PREMIUN_WAIVER_SL;
          break;

        case this.coverageType = AppConstants.VOL:
          this.message =  AppConstants.PREMIUM_WAIVER_VOL;
          break;

        default:
          this.message =  this.claimSubType;
      }
    }
  }

}
