import {GbBase} from './gb-base';

export class GbAuthorization extends GbBase {
  getUrls() {
    return {
      authorization: this.getBaseUrl() + 'authorize?' + this.getClientId('auth') + '&responsetype=token',
      authorizationSSO: this.getBaseUrl() + 'authorize?' + this.getClientId('auth') + '&responsetype=ssotoken',
      authorizationImpersonate: this.getBaseUrl() + 'authorize?' + this.getClientId('auth') + '&responsetype=internaltoken',

      claimWizardOptionsURL: this.getBaseUrl() + `uws/gb-registration-service/registration/v1/claimWizard?` + this.getClientId('auth') + '&',
    };
  }
}
