import {ChangeDetectionStrategy, Component, EventEmitter, Input, Output} from '@angular/core';
import {AppConstants} from '@shared/constants/app.constants';
import {AppLabelConstants} from '@shared/constants/appLabel.constants';

@Component({
  selector: 'app-single-payment-details',
  templateUrl: './single-payment-details.component.html',
  styleUrls: ['./single-payment-details.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SinglePaymentDetailsComponent {
  @Output() prevPaymentOutput = new EventEmitter();
  @Output() nextPaymentOutput = new EventEmitter();
  @Output() trackSinglePaymentLinkClickOutput = new EventEmitter();
  @Output() fetchPaystubOutput = new EventEmitter();
  appConstant = AppConstants;
  appLabelConstant = AppLabelConstants;
  paymentDetails;
  payments = [];
  curIndex: number = 0;
  paymentDate: string = '';
  startDate: string = '';
  endDate: string = '';
  formattedAddress: string = '';
  displayPayStubIcon: boolean = false;
  descriptionPaymentDetails: string = '';
  paymentDetailsHeading: string = '';
  paymentDetailsDescription: string = '';
  informationalMessage: string = '';
  allClaimPayments: boolean = false;

  constructor() { }

  @Input() set paymentDetailsInput(data) {
    if (data) {
      this.paymentDetails = data.paymentDetails;
      this.payments = data.payments;
      this.curIndex = data.curIndex;
      this.paymentDate = data.paymentDate;
      this.startDate = data.startDate;
      this.endDate = data.endDate;
      this.formattedAddress = data.formattedAddress;
      this.displayPayStubIcon = data.displayPayStubIcon;
      this.descriptionPaymentDetails = data.descriptionPaymentDetails;
      this.paymentDetailsHeading = data.paymentDetailsHeading;
      this.paymentDetailsDescription = data.paymentDetailsDescription;
      this.informationalMessage = data.informationalMessage;
      this.allClaimPayments = data.allClaimPayments
    }
  }

  dataExists(data) {
    return data ? data : '';
  }

  prevPayment(curIndex) {
    this.prevPaymentOutput.emit(curIndex);
  }

  trackSinglePaymentLinkClick(id) {
    this.trackSinglePaymentLinkClickOutput.emit(id);
  }

  nextPayment(curIndex) {
    this.nextPaymentOutput.emit(curIndex);
  }

  fetchPaystub(curIndex) {
    this.fetchPaystubOutput.emit(curIndex);
  }
}
