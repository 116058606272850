import {AfterContentChecked, Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {AppLeaveIntakeConstants} from '@app/cxr/leave-claim-intake-flow/leave-intake.constants';
import {AppLabelConstants} from '@shared/constants/appLabel.constants';
import {IMultiDropdownInput, IMultiDropdownInputTitles} from '@shared/models/multi-dropdown.model';
import { INTAKE_OWCS_IDS } from '@app/cxr/modules/intake/shared/JSON/intake-owcs.constants';
import { IMinutesHoursListObject } from '@app/cxr/modules/intake/shared/models/intake-work-schedule.model';
import { IHukHoursMinsObject } from '@app/cxr/modules/intake/shared/models/hours-mins-object.model';

@Component({
  selector: 'app-huk-hour-min-unit-multi-dropdown',
  templateUrl: './huk-hour-min-unit-multi-dropdown.component.html',
  styleUrls: ['./huk-hour-min-unit-multi-dropdown.component.scss']
})
export class HukHourMinUnitMultiDropdownComponent implements OnInit, AfterContentChecked {
    @Input() timeLabel: string;
    @Input() absenceSectionBoolean: boolean = false;
    @Input() required: boolean = false;
    @Input() invalidTime: boolean = false;
    @Input() disableTime: boolean = false;
    @Input() partialContinuousBoolean: boolean = false;
    @Input() selectedTime: IHukHoursMinsObject;
    @Input() requiredText;
    @Input() errorMessage: string = AppLeaveIntakeConstants.PLEASE_ANS;
    @Input() disableDefaultvalue?: boolean;
    @Input() hourDefaultValue: string = AppLabelConstants.HH_LABEL;
    @Input() minDefaultValue: string = AppLabelConstants.MM_LABEL;
    @Input() amPmDefaultValue: string = AppLabelConstants.AM_TEXT;
    @Input() multiDropdownIds: IMultiDropdownInput = {
        labelId: 'ques-input-margin',
        hourId: 'hour',
        minId: 'minute',
        unitId: 'unit'
    };
    @Input() multiDropdownTitles: IMultiDropdownInputTitles;
    @Input() owcsContentMap: Map<string, string> = new Map();
    @Output() hourMinUnitValue = new EventEmitter();
    public leaveIntakeConstant = AppLeaveIntakeConstants;
    public appLabelConstant = AppLabelConstants;
    public minuteOptions: IMinutesHoursListObject[] = [];
    public hourOptions: IMinutesHoursListObject[] = [];
    public unitOptions: IMinutesHoursListObject[] = [];
    public hourLabel = this.appLabelConstant.HH_HOUR_TEXT;
    public minuteLabel = this.appLabelConstant.MM_MIN_TEXT;
    public unitLabel = this.appLabelConstant.UNIT_TEXT;
    @Input() hourMinUnitForm: FormGroup;
    @Input() showTimeMissedMultiDropdown: boolean;
    @Input() resetTimeMissedDropdowns: boolean;
    @Input() updateTextLabel: boolean = false;
    public showError: boolean = false;
    hoursTextLabel: string;
    unitsTextLabel: string;
    minutesTextLabel: string;

    constructor() { }

    ngOnInit() {
        this.hoursTextLabel = this.owcsContentMap?.get(INTAKE_OWCS_IDS.IE_083_Body);
        this.minutesTextLabel = this.owcsContentMap?.get(INTAKE_OWCS_IDS.IE_084_Body);
        this.unitsTextLabel = this.updateTextLabel ? this.owcsContentMap?.get(INTAKE_OWCS_IDS.IE_030A_Body) : this.owcsContentMap?.get(INTAKE_OWCS_IDS.IE_084A_Body);
        this.createAndSetTimeForm();
        this.hourMinUnitForm?.valueChanges?.subscribe(change => this.absenceSectionValidityEmitter());
    }

    ngAfterContentChecked(): void {
        if (this.resetTimeMissedDropdowns) {
          this.hourMinUnitForm.controls['hour']?.setValue(null);
          this.hourMinUnitForm.controls['hour'].markAsPristine()
          this.hourMinUnitForm.controls['hour'].setErrors({invalid : true});

          this.hourMinUnitForm.controls['minute']?.setValue(null);
          this.hourMinUnitForm.controls['minute'].markAsPristine();
          this.hourMinUnitForm.controls['minute'].setErrors({invalid : true});

          this.hourMinUnitForm.controls['unit']?.setValue(null);
          this.hourMinUnitForm.controls['unit']?.markAsPristine();
          this.hourMinUnitForm.controls['unit'].setErrors({invalid : true});

          this.resetTimeMissedDropdowns = false;
        }
    }

    absenceSectionValidityEmitter() {
        if (this.hourMinUnitForm.valid) {
            this.hourMinUnitValue.emit(this.selectedTime);
        }
    }

    createAndSetTimeForm() {
        if (!this.selectedTime) {
            this.selectedTime = {
                'hour': '',
                'minute': '',
                'unit': ''
            };
        }

        const formControls = {};
        for (const field of Object.keys(this.selectedTime)) {
            formControls[field] = new FormControl(this.selectedTime[field], Validators.required);
        }
        this.hourMinUnitForm = new FormGroup(formControls);
        this.unitOptions.push(
            {
                name: this.appLabelConstant.AM_TEXT,
                value: this.appLabelConstant.AM_TEXT
            },
            {
                name: this.appLabelConstant.PM_TEXT,
                value: this.appLabelConstant.PM_TEXT
            }
        )
        for (let i = 0; i < 60; i++) {
            const option: string = ('0' + i).slice(-2);
            this.minuteOptions.push(
                {
                    name: option,
                    value: option
                });
        }
        for (let i = 1; i < 13; i++) {
            const option: string = ('0' + i).slice(-2);
            this.hourOptions.push(
                {
                    name: option,
                    value: option
                });
        }
    }

    /**
     * function to populate the hour field in the 'selectedTime' object
     *
     * @param hourValue
     */
    setHoursSelected(hourValue: string): void {
        this.hourMinUnitForm.controls['hour'].setValue(hourValue)
        const selectedHour = (this.hourMinUnitForm.controls['hour'].value) ? this.hourMinUnitForm.controls[this.hourLabel].value : this.hourDefaultValue;
        if (selectedHour) {
            this.selectedTime['hour'] = selectedHour.trim();
        }
    }

    /**
     * function to populate the minute field in the 'selectedTime' object
     *
     * @param minuteValue
     */
    setMinutesSelected(minuteValue: string): void {
        this.hourMinUnitForm.controls['minute'].setValue(minuteValue)
        const selectedMinute = (this.hourMinUnitForm.controls['minute'].value) ? this.hourMinUnitForm.controls[this.minuteLabel].value : this.minDefaultValue;
        if (selectedMinute) {
            this.selectedTime['minute'] = selectedMinute.trim();
        }
    }

    /**
     * function to populate the unit field in the 'selectedTime' object
     *
     * @param unitValue
     */
    setUnitSelected(unitValue: string): void {
        this.hourMinUnitForm.controls['unit'].setValue(unitValue)
        const selectedUnit = (this.hourMinUnitForm.controls['unit'].value) ? this.hourMinUnitForm.controls[this.unitLabel].value : unitValue;
        if (selectedUnit) {
            this.selectedTime['unit'] = selectedUnit.trim();
        }
    }
}
