import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';

@Component({
  selector: 'primary-radio',
  templateUrl: 'primary-radio.component.html',
  styleUrls: ['primary-radio.component.scss']
})
export class PrimaryRadioComponent implements OnInit {

  @Input() radioText: string;  //RadioText input is used to display label with radio button
  @Input() id: string;        //Id input is used to avoid duplication of custome radio button check
   // Note Id  is madinatory to pass asn input feild
  @Input() radiovalue: string; // Used to pass array value to the emit event
  @Input() radioClass; // Used to pass array value to the emit event
  @Input() radioBtnHeight; // Used to adjust the default height of radio btn
  @Input() radioDisable: boolean = false; // Used to Disable radio btn
  @Output () radiobtnvalue: EventEmitter<string> =  new EventEmitter<string>();
  @Input () labelWeight = 'bold';
  @Input() checked: boolean;
  @Input() name: string;
  @Input() defaultValue: string;
  @Input() labelColor;
  @Input() radioError;
  @Input () labelSize = 'smaller';
  @Output() radioEventType: EventEmitter<string> =  new EventEmitter<string>();
  constructor() {}
  ngOnInit() {}

  emit() {
    sessionStorage.setItem('radiovalue', this.radiovalue);
    this.radiobtnvalue.emit(this.radiovalue);
  }
  onBlur() {
    this.radioEventType.emit('blur');
   }
   onfocus() {
    this.radioEventType.emit('focus');
   }
  onChecked(e: Event) {
    const checkbox = e.target as HTMLInputElement;

  }
}
