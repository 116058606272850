import {Injectable} from '@angular/core';
import {Title} from '@angular/platform-browser';
import {AppLabelConstants} from '../constants/appLabel.constants';

@Injectable()
export class MyBenefitsTitleService {

  constructor(public titleService: Title) {
  }

  public setTitle(newTitle: string) {

   this.titleService.setTitle(newTitle + AppLabelConstants.EMPLOYEE_BENEFITS_THE_HARTFORD);
  }
}

